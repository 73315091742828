import { Action } from '@ngrx/store';

import { AppHttpResponse } from 'shared';
import {
  CreateEmployeeLoanResponse,
  CreateEmployeeManagementLoanInput,
  CreatePayGovServiceRequestInput,
  EmployeeLoan,
  EmployeeLoanSettlement,
  GovServiceRequest,
  UpdateEmployeeLoanInput,
} from 'hr';
import {
  CreatePayrollPaymentInput,
  CreatePayrollPaymentRefundInput,
  PayPayrollInput,
  Payroll,
  PayrollPayment,
  PayrollPaymentRefund,
} from 'finances/models';

/**
 * The finances-module store hr action types.
 */
export enum HrActionType {
  SEARCH_EMPLOYEE_LOANS = '[Finances] [HR] Search Employee Loans',
  SEARCH_EMPLOYEE_LOANS_FAIL = '[Finances] [HR] Search Employee Loans Fail',
  SEARCH_EMPLOYEE_LOANS_SUCCESS = '[Finances] [HR] Search Employee Loans Success',

  SEARCH_EMPLOYEE_LOAN_SETTLEMENTS = '[Finances] [HR] Search Employee Loan Settlements',
  SEARCH_EMPLOYEE_LOAN_SETTLEMENTS_FAIL = '[Finances] [HR] Search Employee Loan Settlements Fail',
  SEARCH_EMPLOYEE_LOAN_SETTLEMENTS_SUCCESS = '[Finances] [HR] Search Employee Loan Settlements Success',

  SEARCH_PAYROLLS = '[Finances] [HR] Search Payrolls',
  SEARCH_PAYROLLS_FAIL = '[Finances] [HR] Search Payrolls Fail',
  SEARCH_PAYROLLS_SUCCESS = '[Finances] [HR] Search Payrolls Success',

  SEARCH_PAID_PAYROLLS = '[Finances] [HR] Search Paid Payrolls',
  SEARCH_PAID_PAYROLLS_FAIL = '[Finances] [HR] Search Paid Payrolls Fail',
  SEARCH_PAID_PAYROLLS_SUCCESS = '[Finances] [HR] Search Paid Payrolls Success',

  SEARCH_PAYROLL_PAYMENTS = '[Finances] [HR] Search Payroll Payments',
  SEARCH_PAYROLL_PAYMENTS_FAIL = '[Finances] [HR] Search Payroll Payments Fail',
  SEARCH_PAYROLL_PAYMENTS_SUCCESS = '[Finances] [HR] Search Payroll Payments Success',

  SEARCH_PAYROLL_PAYMENT_REFUNDS = '[Finances] [HR] Search Payroll Payment Refunds',
  SEARCH_PAYROLL_PAYMENT_REFUNDS_FAIL = '[Finances] [HR] Search Payroll Payment Refunds Fail',
  SEARCH_PAYROLL_PAYMENT_REFUNDS_SUCCESS = '[Finances] [HR] Search Payroll Payment Refunds Success',

  CREATE_EMPLOYEE_LOAN = '[Finances] [HR] Create Employee Loan',
  CREATE_EMPLOYEE_LOAN_FAIL = '[Finances] [HR] Create Employee Loan Fail',
  CREATE_EMPLOYEE_LOAN_SUCCESS = '[Finances] [HR] Create Employee Loan Success',

  UPDATE_EMPLOYEE_LOAN = '[Finances] [HR] Update Employee Loan',
  UPDATE_EMPLOYEE_LOAN_FAIL = '[Finances] [HR] Update Employee Loan Fail',
  UPDATE_EMPLOYEE_LOAN_SUCCESS = '[Finances] [HR] Update Employee Loan Success',

  CREATE_PAYROLL_PAYMENT = '[Finances] [HR] Create Payroll Payment',
  CREATE_PAYROLL_PAYMENT_FAIL = '[Finances] [HR] Create Payroll Payment Fail',
  CREATE_PAYROLL_PAYMENT_SUCCESS = '[Finances] [HR] Create Payroll Payment Success',

  CREATE_PAYROLL_PAYMENT_REFUND = '[Finances] [HR] Create Payroll Payment Refund',
  CREATE_PAYROLL_PAYMENT_REFUND_FAIL = '[Finances] [HR] Create Payroll Payment Refund Fail',
  CREATE_PAYROLL_PAYMENT_REFUND_SUCCESS = '[Finances] [HR] Create Payroll Payment Refund Success',

  PAY_PAYROLL = '[Finances] [HR] pay Payroll',
  PAY_PAYROLL_FAIL = '[Finances] [HR] pay Payroll Fail',
  PAY_PAYROLL_SUCCESS = '[Finances] [HR] pay Payroll Success',

  DELETE_PAYROLL = '[Finances] [HR] Delete Payroll',
  DELETE_PAYROLL_FAIL = '[Finances] [HR] Delete Payroll Fail',
  DELETE_PAYROLL_SUCCESS = '[Finances] [HR] Delete Payroll Success',

  FIND_PAYROLL_PAYMENT = '[Finances] [HR] Find Payroll Payment',
  FIND_PAYROLL_PAYMENT_FAIL = '[Finances] [HR] Find Payroll Payment Fail',
  FIND_PAYROLL_PAYMENT_SUCCESS = '[Finances] [HR] Find Payroll Payment Success',

  FIND_PAYROLL_PAYMENT_REFUND = '[Finances] [HR] Find Payroll Payment Refund',
  FIND_PAYROLL_PAYMENT_REFUND_FAIL = '[Finances] [HR] Find Payroll Payment Refund Fail',
  FIND_PAYROLL_PAYMENT_REFUND_SUCCESS = '[Finances] [HR] Find Payroll Payment Refund Success',

  SEARCH_GOV_SERVICE_REQUESTS = '[Finances] [HR] Search Gov Service Requests',
  SEARCH_GOV_SERVICE_REQUESTS_FAIL = '[Finances] [HR] Search Gov Service Requests Fail',
  SEARCH_GOV_SERVICE_REQUESTS_SUCCESS = '[Finances] [HR] Search Gov Service Requests Success',

  FIND_GOV_SERVICE_REQUEST = '[Finances] [HR] Find Gov Service Request',
  FIND_GOV_SERVICE_REQUEST_FAIL = '[Finances] [HR] Find Gov Service Request Fail',
  FIND_GOV_SERVICE_REQUEST_SUCCESS = '[Finances] [HR] Find Gov Service Request Success',

  CREATE_PAY_GOV_SERVICE_REQUEST = '[Finances] [HR] Create Pay Gov Service Request',
  CREATE_PAY_GOV_SERVICE_REQUEST_FAIL = '[Finances] [HR] Create Pay Gov Service Request Fail',
  CREATE_PAY_GOV_SERVICE_REQUEST_SUCCESS = '[Finances] [HR] Create Pay Gov Service Request Success',
}

/**
 * Represents a store employee loans search action.
 */
export class SearchEmployeeLoans implements Action {
  /**
   * The type of the action.
   */
  readonly type = HrActionType.SEARCH_EMPLOYEE_LOANS;

  /**
   * Fires a new employee loans search action.
   * @param payload the search parameters,
   * if omitted, all employee loans will be loaded.
   */
  constructor(
    public payload?: {
      employees: number[];
      departments: number[];
      locations: number[];
      loanPaymentTypes: [];
      fromDate: Date;
      toDate: Date;
      page: number;
    }
  ) {}
}

/**
 * Represents a store employee loans search fail action.
 */
export class SearchEmployeeLoansFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = HrActionType.SEARCH_EMPLOYEE_LOANS_FAIL;

  /**
   * Fires a new employee loans search fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store employee loans search success action.
 */
export class SearchEmployeeLoansSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = HrActionType.SEARCH_EMPLOYEE_LOANS_SUCCESS;

  /**
   * Fires a new employee loans search success action.
   * @param payload An object contains the list of employee loans that matches the search criteria
   * in addition to the pagination info.
   */
  constructor(public payload: AppHttpResponse<EmployeeLoan[]>) {}
}

/**
 * Represents a store employee loan settlements search action.
 */
export class SearchEmployeeLoanSettlements implements Action {
  /**
   * The type of the action.
   */
  readonly type = HrActionType.SEARCH_EMPLOYEE_LOAN_SETTLEMENTS;

  /**
   * Fires a new employee loan settlements search action.
   * @param payload the search parameters,
   * if omitted, all employee loan settlements will be loaded.
   */
  constructor(
    public payload?: {
      employees: number[];
      departments: number[];
      locations: number[];
      fromDate: Date;
      toDate: Date;
      page: number;
    }
  ) {}
}

/**
 * Represents a store employee loan settlements search fail action.
 */
export class SearchEmployeeLoanSettlementsFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = HrActionType.SEARCH_EMPLOYEE_LOAN_SETTLEMENTS_FAIL;

  /**
   * Fires a new employee loan settlements search fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store employee loan settlements search success action.
 */
export class SearchEmployeeLoanSettlementsSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = HrActionType.SEARCH_EMPLOYEE_LOAN_SETTLEMENTS_SUCCESS;

  /**
   * Fires a new employee loan settlements search success action.
   * @param payload An object contains the list of employee loans that matches the search criteria
   * in addition to the pagination info.
   */
  constructor(public payload: AppHttpResponse<EmployeeLoanSettlement[]>) {}
}

/**
 * Represents a store payrolls search action.
 */
export class SearchPayrolls implements Action {
  /**
   * The type of the action.
   */
  readonly type = HrActionType.SEARCH_PAYROLLS;

  /**
   * Fires a new payrolls search action.
   * @param payload the search parameters,
   * if omitted, all payrolls will be loaded.
   */
  constructor(
    public payload?: {
      employees: number[];
      departments: number[];
      locations: number[];
      payrollStatuses: number[];
      fromDate: Date;
      toDate: Date;
      page: number;
    }
  ) {}
}

/**
 * Represents a store paid payrolls search fail action.
 */
export class SearchPayrollsFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = HrActionType.SEARCH_PAYROLLS_FAIL;

  /**
   * Fires a new payrolls search fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store payrolls search success action.
 */
export class SearchPayrollsSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = HrActionType.SEARCH_PAYROLLS_SUCCESS;

  /**
   * Fires a new payrolls search success action.
   * @param payload An object contains the list of payrolls that matches the search criteria
   * in addition to the pagination info.
   */
  constructor(public payload: AppHttpResponse<Payroll[]>) {}
}

/**
 * Represents a store paid payrolls search action.
 */
export class SearchPaidPayrolls implements Action {
  /**
   * The type of the action.
   */
  readonly type = HrActionType.SEARCH_PAID_PAYROLLS;

  /**
   * Fires a new paid payrolls search action.
   * @param payload the search parameters,
   * if omitted, all paid payrolls will be loaded.
   */
  constructor(
    public payload?: {
      employees: number[];
      departments: number[];
      locations: number[];
      fromDate: Date;
      toDate: Date;
      page: number;
    }
  ) {}
}

/**
 * Represents a store paid payrolls search fail action.
 */
export class SearchPaidPayrollsFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = HrActionType.SEARCH_PAID_PAYROLLS_FAIL;

  /**
   * Fires a new paid payrolls search fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store paid payrolls search success action.
 */
export class SearchPaidPayrollsSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = HrActionType.SEARCH_PAID_PAYROLLS_SUCCESS;

  /**
   * Fires a new paid payrolls search success action.
   * @param payload An object contains the list of paid payrolls that matches the search criteria
   * in addition to the pagination info.
   */
  constructor(public payload: AppHttpResponse<Payroll[]>) {}
}

/**
 * Represents a store payroll payments search action.
 */
export class SearchPayrollPayments implements Action {
  /**
   * The type of the action.
   */
  readonly type = HrActionType.SEARCH_PAYROLL_PAYMENTS;

  /**
   * Fires a new payroll payments search action.
   * @param payload the search parameters,
   * if omitted, all payroll payments will be loaded.
   */
  constructor(
    public payload?: {
      employeePaymentMethods: number[];
      fromDate: Date;
      toDate: Date;
      page: number;
    }
  ) {}
}

/**
 * Represents a store payroll payments search fail action.
 */
export class SearchPayrollPaymentsFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = HrActionType.SEARCH_PAYROLL_PAYMENTS_FAIL;

  /**
   * Fires a new payroll payments search fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store payroll payments search success action.
 */
export class SearchPayrollPaymentsSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = HrActionType.SEARCH_PAYROLL_PAYMENTS_SUCCESS;

  /**
   * Fires a new payroll payments search success action.
   * @param payload An object contains the list of payroll payments that matches the search criteria
   * in addition to the pagination info.
   */
  constructor(public payload: AppHttpResponse<PayrollPayment[]>) {}
}

/**
 * Represents a store payroll payment refunds search action.
 */
export class SearchPayrollPaymentRefunds implements Action {
  /**
   * The type of the action.
   */
  readonly type = HrActionType.SEARCH_PAYROLL_PAYMENT_REFUNDS;

  /**
   * Fires a new payroll payment refunds search action.
   * @param payload the search parameters,
   * if omitted, all payroll payment refunds will be loaded.
   */
  constructor(
    public payload?: {
      employeePaymentMethods: number[];
      fromDate: Date;
      toDate: Date;
      page: number;
    }
  ) {}
}

/**
 * Represents a store payroll payment refunds search fail action.
 */
export class SearchPayrollPaymentRefundsFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = HrActionType.SEARCH_PAYROLL_PAYMENT_REFUNDS_FAIL;

  /**
   * Fires a new payroll payment refunds search fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store payroll payment refunds search success action.
 */
export class SearchPayrollPaymentRefundsSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = HrActionType.SEARCH_PAYROLL_PAYMENT_REFUNDS_SUCCESS;

  /**
   * Fires a new payroll payment refunds search success action.
   * @param payload An object contains the list of payroll payments that matches the search criteria
   * in addition to the pagination info.
   */
  constructor(public payload: AppHttpResponse<PayrollPaymentRefund[]>) {}
}

/**
 * Represents a store employee loan create action.
 */
export class CreateEmployeeLoan implements Action {
  /**
   * The type of the action.
   */
  readonly type = HrActionType.CREATE_EMPLOYEE_LOAN;

  /**
   * Fires a new employee loan create action.
   * @param payload the new employee loan data-model.
   */
  constructor(public payload: CreateEmployeeManagementLoanInput) {}
}

/**
 * Represents a store employee loan create-fail action.
 */
export class CreateEmployeeLoanFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = HrActionType.CREATE_EMPLOYEE_LOAN_FAIL;

  /**
   * Fires a new employee loan create-fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store employee loan create-success action.
 */
export class CreateEmployeeLoanSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = HrActionType.CREATE_EMPLOYEE_LOAN_SUCCESS;

  /**
   * Fires a new employee loan create-success action.
   * @param payload The created employee.
   */
  constructor(public payload: AppHttpResponse<CreateEmployeeLoanResponse>) {}
}

/**
 * Represents a store employee loan update action.
 */
export class UpdateEmployeeLoan implements Action {
  /**
   * The type of the action.
   */
  readonly type = HrActionType.UPDATE_EMPLOYEE_LOAN;

  /**
   * Fires a new employee loan update action.
   * @param payload the new employee loan data-model.
   */
  constructor(public payload: UpdateEmployeeLoanInput) {}
}

/**
 * Represents a store employee loan update-fail action.
 */
export class UpdateEmployeeLoanFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = HrActionType.UPDATE_EMPLOYEE_LOAN_FAIL;

  /**
   * Fires a new employee loan update-fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store employee loan update-success action.
 */
export class UpdateEmployeeLoanSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = HrActionType.UPDATE_EMPLOYEE_LOAN_SUCCESS;

  /**
   * Fires a new employee loan update-success action.
   * @param payload The updated employee.
   */
  constructor(public payload: AppHttpResponse<EmployeeLoan>) {}
}

/**
 * Represents a store payroll payment create action.
 */
export class CreatePayrollPayment implements Action {
  /**
   * The type of the action.
   */
  readonly type = HrActionType.CREATE_PAYROLL_PAYMENT;

  /**
   * Fires a new payroll payment create action.
   * @param payload the new prepare payrolls data-model.
   */
  constructor(public payload: CreatePayrollPaymentInput) {}
}

/**
 * Represents a store payroll payment create-fail action.
 */
export class CreatePayrollPaymentFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = HrActionType.CREATE_PAYROLL_PAYMENT_FAIL;

  /**
   * Fires a new payroll payment create-fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store payroll payment create-success action.
 */
export class CreatePayrollPaymentSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = HrActionType.CREATE_PAYROLL_PAYMENT_SUCCESS;

  /**
   * Fires a new payroll payment create-success action.
   * @param payload The updated pay payroll.
   */
  constructor(public payload: AppHttpResponse<PayrollPayment>) {}
}

/**
 * Represents a store payroll payment refund create action.
 */
export class CreatePayrollPaymentRefund implements Action {
  /**
   * The type of the action.
   */
  readonly type = HrActionType.CREATE_PAYROLL_PAYMENT_REFUND;

  /**
   * Fires a new payroll payment refund create action.
   * @param payload the new prepare payroll refunds data-model.
   */
  constructor(public payload: CreatePayrollPaymentRefundInput) {}
}

/**
 * Represents a store payroll payment create-fail action.
 */
export class CreatePayrollPaymentRefundFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = HrActionType.CREATE_PAYROLL_PAYMENT_REFUND_FAIL;

  /**
   * Fires a new payroll payment refund create-fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store payroll payment create-success action.
 */
export class CreatePayrollPaymentRefundSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = HrActionType.CREATE_PAYROLL_PAYMENT_REFUND_SUCCESS;

  /**
   * Fires a new payroll payment refund create-success action.
   * @param payload The updated pay payroll.
   */
  constructor(public payload: AppHttpResponse<PayrollPaymentRefund>) {}
}

/**
 * Represents a store pay payroll action.
 */
export class PayPayroll implements Action {
  /**
   * The type of the action.
   */
  readonly type = HrActionType.PAY_PAYROLL;

  /**
   * Fires a new payroll pay action.
   * @param payload the new pay payroll data-model.
   */
  constructor(public payload: PayPayrollInput) {}
}

/**
 * Represents a store payroll in-review-fail action.
 */
export class PayPayrollFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = HrActionType.PAY_PAYROLL_FAIL;
  /**
   * Fires a new payroll in-review-fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store payroll in-review-success action.
 */
export class PayPayrollSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = HrActionType.PAY_PAYROLL_SUCCESS;

  /**
   * Fires a new payroll in-review-success action.
   * @param payload The payed employee.
   */
  constructor(public payload: AppHttpResponse<Payroll[]>) {}
}

/**
 * Represents a store payroll delete action.
 */
export class DeletePayroll implements Action {
  /**
   * The type of the action.
   */
  readonly type = HrActionType.DELETE_PAYROLL;

  /**
   * Fires a new payroll delete action.
   * @param payload The payroll's id.
   */
  constructor(public payload: number) {}
}

/**
 * Represents a store payroll delete-fail action.
 */
export class DeletePayrollFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = HrActionType.DELETE_PAYROLL_FAIL;

  /**
   * Fires a new payroll delete-fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store payroll delete-success action.
 */
export class DeletePayrollSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = HrActionType.DELETE_PAYROLL_SUCCESS;

  /**
   * Fires a new payroll delete-success action.
   * @param payload The deleted payroll.
   */
  constructor(public payload: AppHttpResponse<Payroll>) {}
}

/**
 * Represents a store find-payroll payment action.
 */
export class FindPayrollPayment implements Action {
  /**
   * The type of the action.
   */
  readonly type = HrActionType.FIND_PAYROLL_PAYMENT;

  /**
   * Fires a new find payroll payment action.
   * @param payload the id of the payroll payment.
   */
  constructor(public payload: number) {}
}

/**
 * Represents a store find-payroll payment-fail action.
 */
export class FindPayrollPaymentFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = HrActionType.FIND_PAYROLL_PAYMENT_FAIL;

  /**
   * Fires a new find-payroll payment-fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store find payroll payment-success action.
 */
export class FindPayrollPaymentSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = HrActionType.FIND_PAYROLL_PAYMENT_SUCCESS;

  /**
   * Fires a new find-payroll payment-success action.
   * @param payload The loaded payroll payment.
   */
  constructor(public payload: AppHttpResponse<PayrollPayment>) {}
}

/**
 * Represents a store find-payroll payment refund action.
 */
export class FindPayrollPaymentRefund implements Action {
  /**
   * The type of the action.
   */
  readonly type = HrActionType.FIND_PAYROLL_PAYMENT_REFUND;

  /**
   * Fires a new find payroll payment refund action.
   * @param payload the id of the payroll payment refund.
   */
  constructor(public payload: number) {}
}

/**
 * Represents a store find-payroll payment refund-fail action.
 */
export class FindPayrollPaymentRefundFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = HrActionType.FIND_PAYROLL_PAYMENT_REFUND_FAIL;

  /**
   * Fires a new find-payroll payment-refund fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store find payroll payment refund-success action.
 */
export class FindPayrollPaymentRefundSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = HrActionType.FIND_PAYROLL_PAYMENT_REFUND_SUCCESS;

  /**
   * Fires a new find-payroll payment refund-success action.
   * @param payload The loaded payroll payment refund.
   */
  constructor(public payload: AppHttpResponse<PayrollPaymentRefund>) {}
}

/**
 * Represents a store gov service requests search action.
 */
export class SearchGovServiceRequests implements Action {
  /**
   * The type of the action.
   */
  readonly type = HrActionType.SEARCH_GOV_SERVICE_REQUESTS;

  /**
   * Fires a new service requests search action.
   * @param payload the search parameters,
   * if omitted, all gov service requests will be loaded.
   */
  constructor(
    public payload?: { govServices: number[]; statuses: number[]; fromDate: Date; toDate: Date; page: number }
  ) {}
}

/**
 * Represents a store gov service requests  search fail action.
 */
export class SearchGovServiceRequestsFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = HrActionType.SEARCH_GOV_SERVICE_REQUESTS_FAIL;

  /**
   * Fires a new gov service requests search fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store search success action.
 */
export class SearchGovServiceRequestsSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = HrActionType.SEARCH_GOV_SERVICE_REQUESTS_SUCCESS;

  /**
   * Fires a new gov service requests search success action.
   * @param payload An object contains the list of gov service requests that matches the search criteria
   * in addition to the pagination info.
   */
  constructor(public payload: AppHttpResponse<GovServiceRequest[]>) {}
}

/**
 * Represents a store find gov service action.
 */
export class FindGovServiceRequest implements Action {
  /**
   * The type of the action.
   */
  readonly type = HrActionType.FIND_GOV_SERVICE_REQUEST;

  /**
   * Fires a new find gov service action.
   * @param payload the id of the gov service.
   */
  constructor(public payload: number) {}
}

/**
 * Represents a store find gov service fail action.
 */
export class FindGovServiceRequestFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = HrActionType.FIND_GOV_SERVICE_REQUEST_FAIL;

  /**
   * Fires a new find gov service fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store find gov service success action.
 */
export class FindGovServiceRequestSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = HrActionType.FIND_GOV_SERVICE_REQUEST_SUCCESS;

  /**
   * Fires a new find gov service success action.
   * @param payload The loaded gov service.
   */
  constructor(public payload: AppHttpResponse<GovServiceRequest>) {}
}

/**
 * Represents a store gov service create action.
 */
export class CreatePayGovServiceRequest implements Action {
  /** The type of the action. */
  readonly type = HrActionType.CREATE_PAY_GOV_SERVICE_REQUEST;

  /**
   * Fires a new gov service create action.
   * @param payload the new gov service data-model.
   */
  constructor(public payload: CreatePayGovServiceRequestInput) {}
}

/**
 * Represents a store gov service create fail action.
 */
export class CreatePayGovServiceRequestFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = HrActionType.CREATE_PAY_GOV_SERVICE_REQUEST_FAIL;

  /**
   * Fires a new gov service create fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store gov service create success action.
 */
export class CreatePayGovServiceRequestSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = HrActionType.CREATE_PAY_GOV_SERVICE_REQUEST_SUCCESS;

  /**
   * Fires a new gov service create success action.
   * @param payload The created gov service.
   */
  constructor(public payload: AppHttpResponse<GovServiceRequest>) {}
}

/**
 * finances-module hr action types.
 */
export type HrActions =
  | SearchEmployeeLoans
  | SearchEmployeeLoansFail
  | SearchEmployeeLoansSuccess
  | SearchEmployeeLoanSettlements
  | SearchEmployeeLoanSettlementsFail
  | SearchEmployeeLoanSettlementsSuccess
  | SearchPayrolls
  | SearchPayrollsFail
  | SearchPayrollsSuccess
  | SearchPaidPayrolls
  | SearchPaidPayrollsFail
  | SearchPaidPayrollsSuccess
  | SearchPayrollPayments
  | SearchPayrollPaymentsFail
  | SearchPayrollPaymentsSuccess
  | SearchPayrollPaymentRefunds
  | SearchPayrollPaymentRefundsFail
  | SearchPayrollPaymentRefundsSuccess
  | CreateEmployeeLoan
  | CreateEmployeeLoanFail
  | CreateEmployeeLoanSuccess
  | CreatePayrollPayment
  | CreatePayrollPaymentFail
  | CreatePayrollPaymentSuccess
  | CreatePayrollPaymentRefund
  | CreatePayrollPaymentRefundFail
  | CreatePayrollPaymentRefundSuccess
  | PayPayroll
  | PayPayrollFail
  | PayPayrollSuccess
  | UpdateEmployeeLoan
  | UpdateEmployeeLoanFail
  | UpdateEmployeeLoanSuccess
  | DeletePayroll
  | DeletePayrollFail
  | DeletePayrollSuccess
  | FindPayrollPayment
  | FindPayrollPaymentFail
  | FindPayrollPaymentSuccess
  | FindPayrollPaymentRefund
  | FindPayrollPaymentRefundFail
  | FindPayrollPaymentRefundSuccess
  | SearchGovServiceRequests
  | SearchGovServiceRequestsFail
  | SearchGovServiceRequestsSuccess
  | FindGovServiceRequest
  | FindGovServiceRequestFail
  | FindGovServiceRequestSuccess
  | CreatePayGovServiceRequest
  | CreatePayGovServiceRequestFail
  | CreatePayGovServiceRequestSuccess;
