<!-- page header starts -->
<app-page-header [info]="pageInfo"></app-page-header>
<!-- page header ends -->

<!-- customer file pages start -->
<div class="card">
  <div class="card-content">
    <div class="px-3 pt-3" *ngIf="customer$ | async; let customer">
      <!-- customer customer dockable status starts -->
      <app-dockable-status
        [status]="customer.isActive"
        [activeText]="'SALES.CUSTOMERS.CUSTOMER_DATA.CUSTOMER_IS_ACTIVE' | translate"
        [notActiveText]="'SALES.CUSTOMERS.CUSTOMER_DATA.CUSTOMER_BLOCKED' | translate"
      >
      </app-dockable-status>
      <!-- customer customer dockable status ends -->

      <ul
        class="nav-tabs"
        ngbNav
        #nav="ngbNav"
        [(activeId)]="activePage"
        (activeIdChange)="selectedPageChanged($event)"
        [destroyOnHide]="false"
      >
        <li ngbNavItem="info">
          <a ngbNavLink>
            <i class="fa fa-id-badge" [class.success]="activePage == 'info'"></i>
            {{ 'SALES.CUSTOMERS.CUSTOMER_DATA_TITLE' | translate }}
          </a>
          <ng-template ngbNavContent>
            <app-customer-info [customer]="customer"></app-customer-info>
          </ng-template>
        </li>
        <li ngbNavItem="summary">
          <a ngbNavLink>
            <i class="fa fa-bar-chart" [class.success]="activePage == 'summary'"></i>
            {{ 'SALES.CUSTOMERS.CUSTOMER_SUMMARY' | translate }} ({{ customer.balance | toDecimal }})
          </a>
          <ng-template ngbNavContent>
            <app-customer-summary [customerId]="customer.id" [customer]="customer"></app-customer-summary>
          </ng-template>
        </li>
        <li ngbNavItem="assets">
          <a ngbNavLink>
            <i class="fa fa-file-text" [class.success]="activePage == 'assets'"></i>
            {{ 'SALES.CUSTOMER_ASSETS.CUSTOMER_ASSET_DATA.CUSTOMER_ASSETS' | translate }}
          </a>
          <ng-template ngbNavContent>
            <app-customer-assets [customerId]="customer.id"></app-customer-assets>
          </ng-template>
        </li>
        <li ngbNavItem="engagements">
          <a ngbNavLink>
            <i class="fa fa-file-text" [class.success]="activePage == 'engagements'"></i>
            {{ 'SALES.CUSTOMERS.CUSTOMER_ENGAGEMENTS' | translate }}
          </a>
          <ng-template ngbNavContent>
            <app-customer-engagements [customerId]="customer.id"></app-customer-engagements>
          </ng-template>
        </li>
        <li ngbNavItem="invoices">
          <a ngbNavLink>
            <i class="fa fa-book" [class.success]="activePage == 'invoices'"></i>
            {{ 'SALES.CUSTOMERS.CUSTOMER_INVOICES' | translate }}
          </a>
          <ng-template ngbNavContent>
            <app-customer-invoices [customerId]="customer.id"></app-customer-invoices>
          </ng-template>
        </li>
        <li ngbNavItem="unpaid-invoices">
          <a ngbNavLink>
            <i class="fa fa-warning" [class.success]="activePage == 'unpaid-invoices'"></i>
            {{ 'SALES.CUSTOMERS.UNPAID_INVOICES' | translate }}
          </a>
          <ng-template ngbNavContent>
            <app-customer-unpaid-invoices [customerId]="customer.id"></app-customer-unpaid-invoices>
          </ng-template>
        </li>
      </ul>
      <div [ngbNavOutlet]="nav" class="mt-2"></div>
    </div>
  </div>
</div>
<!-- customer file pages end -->
