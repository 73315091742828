import { Component, Input } from '@angular/core';
import { FormControl, FormArray } from '@angular/forms';

/**
 * A custom form control for multi files upload and preview.
 */
@Component({
  selector: 'app-files-upload',
  templateUrl: './files-upload.component.html',
  styles: [],
})
export class FilesUploadComponent {
  /**
   * Gets or sets the name form-array in its parent form.
   */
  @Input() formArrayName: string;

  /***
   * Sets the form array that will hold the list of uploaded files.
   */
  @Input() filesForm: FormArray = new FormArray([]);

  /**
   * Creates & adds a new file form-control.
   */
  add() {
    this.filesForm.push(new FormControl());
  }

  /**
   * Remove all of the list of files.
   *
   * It only adds one file for quick start.
   */
  reset() {
    this.filesForm.clear();
    this.add();
  }
}
