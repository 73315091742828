<div class="row">
  <div class="col-md-12">
    <!-- journal excluded asset details starts -->
    <div class="card-header mb-3">
      <h4 class="card-title">{{ 'FINANCES.JOURNALS.JOURNAL_DATA.EXCLUDED_ASSETS_DETAILS' | translate }}</h4>
    </div>
    <div class="card-content">
      <div class="px-3">
        <div class="row">
          <div class="form-group col-md-12">
            <label for="assetId">{{ 'FINANCES.JOURNALS.JOURNAL_DATA.ASSET_DESCRIPTION' | translate }}</label>
            <input
              type="text"
              id="assetId"
              class="form-control round"
              [value]="excludedAsset.asset?.description"
              readonly
            />
          </div>
          <div class="form-group col-md-6">
            <label for="assetCategory">{{ 'FINANCES.JOURNALS.JOURNAL_DATA.ASSET_CATEGORY_NAME' | translate }}</label>
            <input
              type="text"
              id="assetCategory"
              class="form-control round"
              [value]="
                excludedAsset.asset?.assetCategory.name | localize: excludedAsset.asset?.assetCategory.nameEn | async
              "
              readonly
            />
          </div>
          <div class="form-group col-md-6">
            <label for="assetYearsOfUse">{{ 'FINANCES.JOURNALS.JOURNAL_DATA.ASSET_YEARS_OF_USES' | translate }}</label>
            <input
              type="text"
              id="assetYearsOfUse"
              class="form-control round"
              [value]="
                excludedAsset.asset?.assetYearsOfUse.name
                  | localize: excludedAsset.asset?.assetYearsOfUse.nameEn
                  | async
              "
              readonly
            />
          </div>
          <div class="form-group col-md-6">
            <label for="tradeMark">{{ 'FINANCES.JOURNALS.JOURNAL_DATA.TRADE_MARK' | translate }}</label>
            <input
              type="text"
              id="tradeMark"
              class="form-control round"
              [value]="excludedAsset.asset?.tradeMark"
              readonly
            />
          </div>
          <div class="form-group col-md-6">
            <label for="barcode">{{ 'FINANCES.JOURNALS.JOURNAL_DATA.BARCODE' | translate }}</label>
            <input
              type="text"
              id="barcode"
              class="form-control round"
              [value]="excludedAsset.asset?.barcode"
              readonly
            />
          </div>
          <div class="form-group col-md-6">
            <label for="bookValue">{{ 'FINANCES.JOURNALS.JOURNAL_DATA.BOOK_VALUE' | translate }}</label>
            <input
              type="text"
              id="bookValue"
              class="form-control round"
              [value]="excludedAsset.asset?.bookValue | toDecimal"
              readonly
            />
          </div>
          <div class="form-group col-md-6">
            <label for="depreciationValue">{{ 'FINANCES.JOURNALS.JOURNAL_DATA.DEPRECIATION_VALUE' | translate }}</label>
            <input
              type="text"
              id="depreciationValue"
              class="form-control round"
              [value]="excludedAsset.asset?.depreciationValue | toDecimal"
              readonly
            />
          </div>
          <div class="form-group col-md-6">
            <label for="locationId">{{ 'FINANCES.JOURNALS.JOURNAL_DATA.LOCATION' | translate }}</label>
            <input
              type="text"
              id="locationId"
              class="form-control round"
              [value]="excludedAsset.asset?.location.name | localize: excludedAsset.asset?.location.nameEn | async"
              readonly
            />
          </div>
          <div class="form-group col-md-12">
            <label for="assetExclusionReasonId">{{
              'FINANCES.JOURNALS.JOURNAL_DATA.EXCLUSION_REASON' | translate
            }}</label>
            <input
              type="text"
              id="assetExclusionReasonId"
              class="form-control round"
              [value]="
                excludedAsset.assetExclusionReason?.name | localize: excludedAsset.assetExclusionReason?.nameEn | async
              "
              readonly
            />
          </div>
        </div>
      </div>
      <!-- journal excluded asset details ends -->
    </div>
  </div>
</div>
