import { Action } from '@ngrx/store';

import { CustomerLogType } from 'lookups/models';
import { AppHttpResponse } from 'shared';

/**
 * The Lookups-Module store customer log type action types.
 */
export enum CustomerLogTypeActionType {
  GET_ALL_CUSTOMER_LOG_TYPE = '[Lookups] [CustomerLogType] Get All Customer Log Types',
  GET_ALL_CUSTOMER_LOG_TYPE_FAIL = '[Lookups] [CustomerLogType] Get All Customer Log Types Fail',
  GET_ALL_CUSTOMER_LOG_TYPE_SUCCESS = '[Lookups] [CustomerLogType] Get All Customer Log Types Success',
}

/**
 * Represents a store customer log type action.
 */
export class GetAllCustomerLogType implements Action {
  /**
   * The type of the action.
   */
  readonly type = CustomerLogTypeActionType.GET_ALL_CUSTOMER_LOG_TYPE;

  /**
   * Fires a new customer log type action.
   */
  constructor() {}
}

/**
 * Represents a store customer log type fail action.
 */
export class GetAllCustomerLogTypeFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = CustomerLogTypeActionType.GET_ALL_CUSTOMER_LOG_TYPE_FAIL;

  /**
   * Fires a new customer log type fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store customer log type success action.
 */
export class GetAllCustomerLogTypeSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = CustomerLogTypeActionType.GET_ALL_CUSTOMER_LOG_TYPE_SUCCESS;

  /**
   * Fires a customer log type success action.
   * @param payload An object contains the list of customer log types.
   */
  constructor(public payload: AppHttpResponse<CustomerLogType[]>) {}
}

/**
 * Lookups-module customer log type action types.
 */
export type CustomerLogTypeActions = GetAllCustomerLogType | GetAllCustomerLogTypeFail | GetAllCustomerLogTypeSuccess;
