import { createSelector } from '@ngrx/store';

import { selectLookupsState } from 'lookups/store/selectors/feature.selectors';
import { LookupsState, PayrollStatusState } from 'lookups/store/states';

/**
 * Gets the payroll status state.
 */
const selectPayrollState = createSelector(selectLookupsState, (state: LookupsState) => state.payrollStatuses);

/**
 * Gets the list of loaded payroll statuses.
 */
export const getPayrollStatuses = createSelector(selectPayrollState, (state: PayrollStatusState) => state.data);

/**
 * Gets the error that occurred in the state.
 */
export const getPayrollStatusesError = createSelector(selectPayrollState, (state: PayrollStatusState) => state.error);

/**
 * Determines if there is a running load process.
 */
export const getPayrollStatusesIsLoading = createSelector(
  selectPayrollState,
  (state: PayrollStatusState) => state.isLoading
);

/**
 * Determines if the last load process has been ended successfully.
 */
export const getPayrollStatusesIsLoaded = createSelector(
  selectPayrollState,
  (state: PayrollStatusState) => state.isLoaded
);
