import { Action } from '@ngrx/store';

import { ManagerialLevel } from 'lookups/models';
import { AppHttpResponse } from 'shared';

/**
 * The Lookups-Module store  managerial level action types.
 */
export enum ManagerialLevelActionType {
  GET_ALL_MANAGERIAL_LEVELS = '[Lookups] [ManagerialLevel] Get All Managerial Levels',
  GET_ALL_MANAGERIAL_LEVELS_FAIL = '[Lookups] [ManagerialLevel] Get All Managerial Levels Fail',
  GET_ALL_MANAGERIAL_LEVELS_SUCCESS = '[Lookups] [ManagerialLevel] Get All Managerial Levels Success',
}

/**
 * Represents a store managerial level action.
 */
export class GetAllManagerialLevels implements Action {
  /**
   * The type of the action.
   */
  readonly type = ManagerialLevelActionType.GET_ALL_MANAGERIAL_LEVELS;

  /**
   * Fires a new  managerial level action.
   */
  constructor() {}
}

/**
 * Represents a store managerial level fail action.
 */
export class GetAllManagerialLevelsFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = ManagerialLevelActionType.GET_ALL_MANAGERIAL_LEVELS_FAIL;

  /**
   * Fires a new  managerial level fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store  managerial level success action.
 */
export class GetAllManagerialLevelsSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = ManagerialLevelActionType.GET_ALL_MANAGERIAL_LEVELS_SUCCESS;

  /**
   * Fires a  managerial level success action.
   * @param payload An object contains the list of managerial level.
   */
  constructor(public payload: AppHttpResponse<ManagerialLevel[]>) {}
}

/**
 * Lookups-module managerial level action types.
 */
export type ManagerialLevelActions =
  | GetAllManagerialLevels
  | GetAllManagerialLevelsFail
  | GetAllManagerialLevelsSuccess;
