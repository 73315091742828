import { findIndex } from 'lodash';
import { OrdersDeliveryActions, OrdersDeliveryActionType } from 'sales/store/actions';
import { OrdersDeliveryState } from 'sales/store/states';

/**
 * The initial state from which the state starts.
 */
const initialState: OrdersDeliveryState = {
  data: [],
  error: null,
  selectedOrderDelivery: null,
  isSearchCompleted: false,
  isSearching: false,
  isDelivering: false,
  isDeliveryCompleted: false,
};

/**
 * The reducer function that is called in each action dispatch against the state.
 * @param state The current state.
 * @param action The action that will affect the state.
 */
export function ordersDeliveryReducer(
  state: OrdersDeliveryState = initialState,
  action: OrdersDeliveryActions
): OrdersDeliveryState {
  switch (action.type) {
    //#region SEARCH_ORDERS_DELIVERY

    case OrdersDeliveryActionType.SEARCH_ORDERS_DELIVERY: {
      return {
        ...state,
        error: null,
        isSearching: true,
        isSearchCompleted: false,
      };
    }

    case OrdersDeliveryActionType.SEARCH_ORDERS_DELIVERY_FAIL: {
      return {
        ...state,
        error: action.payload,
        isSearching: false,
        isSearchCompleted: false,
      };
    }

    case OrdersDeliveryActionType.SEARCH_ORDERS_DELIVERY_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        error: null,
        isSearching: false,
        isSearchCompleted: true,
      };
    }

    //#endregion SEARCH_ORDERS_DELIVERY

    //#region DELIVERY_ORDER

    case OrdersDeliveryActionType.DELIVERY_ORDER: {
      return {
        ...state,
        error: null,
        isDelivering: true,
        isDeliveryCompleted: false,
      };
    }

    case OrdersDeliveryActionType.DELIVERY_ORDER_FAIL: {
      return {
        ...state,
        error: action.payload,
        isDelivering: false,
        isDeliveryCompleted: false,
      };
    }

    case OrdersDeliveryActionType.DELIVERY_ORDER_SUCCESS: {
      let saleInvoices = [...state.data];
      const saleInvoiceIndex = findIndex(saleInvoices, (saleInvoice) => saleInvoice.id === action.payload.data.id);

      /* If invoice was found, update it. */
      if (saleInvoiceIndex >= 0) {
        saleInvoices[saleInvoiceIndex] = action.payload.data;
      } else {
        /* else, insert it to the beginning of the array. */
        saleInvoices = [action.payload.data, ...saleInvoices];
      }

      return {
        ...state,
        data: saleInvoices,
        selectedOrderDelivery: action.payload.data,
        error: null,
        isDelivering: false,
        isDeliveryCompleted: true,
      };
    }

    //#endregion DELIVERY_ORDER

    default:
      return state;
  }
}
