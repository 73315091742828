import { Action } from '@ngrx/store';

import { LoanSettlementType } from 'lookups/models';
import { AppHttpResponse } from 'shared';

/**
 * The Lookups-Module store loan settlement type action types.
 */
export enum LoanSettlementTypeActionType {
  GET_ALL_LOAN_SETTLEMENT_TYPES = '[Lookups] [LoanSettlementType] Get All Loan Settlement Types',
  GET_ALL_LOAN_SETTLEMENT_TYPES_FAIL = '[Lookups] [LoanSettlementType] Get All Loan Settlement Types Fail',
  GET_ALL_LOAN_SETTLEMENT_TYPES_SUCCESS = '[Lookups] [LoanSettlementType] Get All Loan Settlement Types Success',
}

/**
 * Represents a store loan settlement type action.
 */
export class GetAllLoanSettlementType implements Action {
  /**
   * The type of the action.
   */
  readonly type = LoanSettlementTypeActionType.GET_ALL_LOAN_SETTLEMENT_TYPES;

  /**
   * Fires a new loan settlement type action.
   */
  constructor() {}
}

/**
 * Represents a store loan settlement type fail action.
 */
export class GetAllLoanSettlementTypeFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = LoanSettlementTypeActionType.GET_ALL_LOAN_SETTLEMENT_TYPES_FAIL;

  /**
   * Fires a new loan settlement type fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store loan settlement type success action.
 */
export class GetAllLoanSettlementTypeSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = LoanSettlementTypeActionType.GET_ALL_LOAN_SETTLEMENT_TYPES_SUCCESS;

  /**
   * Fires a loan settlement type success action.
   * @param payload An object contains the list of loan settlement Types.
   */
  constructor(public payload: AppHttpResponse<LoanSettlementType[]>) {}
}

/**
 * Lookups-module loan settlement type action types.
 */
export type LoanSettlementTypeActions =
  | GetAllLoanSettlementType
  | GetAllLoanSettlementTypeFail
  | GetAllLoanSettlementTypeSuccess;
