import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';

import { Observable } from 'rxjs';
import { Store, select } from '@ngrx/store';

import * as fromStoresStore from 'stores/store';
import { PageInfo } from 'shared';
import { Product, ProductStock, Stock } from 'stores/models';

@Component({
  selector: 'app-product-stock-query',
  templateUrl: './product-stock-query.component.html',
  styles: [],
})
export class ProductStockQueryComponent implements OnInit {
  /**
   * Gets or sets the information about the current page.
   */
  pageInfo: PageInfo = {
    title: 'STORES.PRODUCTS.PRODUCT_STOCK_QUERY_PAGE_TITLE',
    icon: 'fa fa-info',
  };

  /**
   * Shows or hide the products list.
   */
  ProductListVisibility = false;

  /**
   * Shows storable products list.
   */
  searchForStorableProducts = false;

  /**
   * The list of active products.
   */
  products: Product[] = [];

  /**
   * The list of products stock.
   */
  productStock$: Observable<ProductStock>;

  /**
   * The search form.
   */
  searchForm: FormGroup;

  /**
   * @param storesStore$ The stores-store module.
   */
  constructor(private storesStore$: Store<fromStoresStore.StoresState>) {}

  ngOnInit(): void {
    this.init();
  }

  /**
   * Initialize component data.
   */
  init() {
    this.initForm();

    /**
     * Load data.
     */
    this.productStock$ = this.storesStore$.pipe(select(fromStoresStore.getSelectedProductStock));
  }

  /**
   * Initialize form and add validators.
   */
  initForm() {
    this.searchForm = new FormGroup({
      productId: new FormControl(''),
    });
  }

  /**
   * Handles search parameters change.
   */
  search(event?: KeyboardEvent) {
    if (event && event.key === 'Enter') {
      event.preventDefault();
      return;
    } else if (!this.searchForm.get('productId').value) {
      return;
    }

    this.applyFiltersAndSearch();
  }

  /**
   * Applies the search filters and dispatches a search action.
   */
  applyFiltersAndSearch() {
    this.storesStore$.dispatch(
      new fromStoresStore.GetAllProductsStockByProductId({
        productId: this.searchForm.get('productId').value,
      })
    );
  }

  /**
   * Enables trackBy feature for *ngFor utility to track items
   * depending on a comparer to reduce DOM change.
   * If the item is already exist then no HTML will change, only bounded values.
   * @param index The index of the item.
   * @param product The item to determine if it was changed or not.
   */
  trackItems(index: number, product: Product) {
    return product ? product.id : undefined;
  }

  /**
   * Calculates the total quantity of the product's stocks.
   * @param stocks The stocks of the product.
   */
  getProductTotalStock(stocks: Stock[]): number {
    return stocks.map((stock) => stock.quantity).reduce((a, b) => a + b, 0);
  }

  /**
   * Calculates the total value of the product's stocks.
   * @param stocks The stocks of the product.
   */
  getProductTotalStockValue(stocks: Stock[]): number {
    return stocks.map((stock) => stock.total).reduce((a, b) => a + b, 0);
  }

  /**
   * Adds the newly selected product from the products search list.
   * @param product The newly selected product to be added.
   */
  selectProduct([product]: Product[]) {
    if (product) {
      this.products = [product];
      this.searchForm.patchValue({ productId: product.itemCode });
      this.applyFiltersAndSearch();
    }
  }
}
