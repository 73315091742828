import { Injectable } from '@angular/core';

import { of } from 'rxjs';
import { switchMap, map, catchError } from 'rxjs/operators';
import { Effect, ofType, Actions } from '@ngrx/effects';

import { LookupsService } from 'lookups/services';
import { AssetCategoryActionType, GetAllAssetCategoryFail, GetAllAssetCategorySuccess } from 'lookups/store/actions';

@Injectable()
export class AssetCategoryEffects {
  constructor(private actions$: Actions, private lookupsService: LookupsService) {}

  /* ========================= GET_ALL_ASSET_CATEGORY =================================== */
  @Effect()
  getAllAssetCategories$ = this.actions$.pipe(
    ofType(AssetCategoryActionType.GET_ALL_ASSET_CATEGORY),
    switchMap(() =>
      this.lookupsService.getAllAssetCategories().pipe(
        map((response) => new GetAllAssetCategorySuccess(response)),
        catchError((error) => of(new GetAllAssetCategoryFail(error)))
      )
    )
  );
}
