import { FormControl, ValidatorFn } from '@angular/forms';

/**
 * Validates that the FormControl value is greater than `zero`.
 */
export function positiveNumber(): ValidatorFn {
  return (control: FormControl): { [key: string]: any } | null => {
    const isValid = !!control.value && !isNaN(control.value) && +control.value > 0;
    return isValid ? null : { invalidValue: { value: 'Value must be greater than zero' } };
  };
}
