import { Injectable } from '@angular/core';

import { switchMap, map, catchError, tap, debounceTime } from 'rxjs/operators';
import { of } from 'rxjs';
import { Effect, ofType, Actions } from '@ngrx/effects';

import { NotificationService, StatusCode, PAGINATION, TranslationService } from 'shared';
import { BankAccountsService } from 'finances/services';
import {
  BankAccountsActionType,
  SearchBankAccounts,
  SearchBankAccountsFail,
  SearchBankAccountsSuccess,
  SearchBankAccountLogs,
  SearchBankAccountLogsSuccess,
  SearchBankAccountLogsFail,
  CreateBankAccount,
  CreateBankAccountSuccess,
  CreateBankAccountFail,
  CreateBankAccountLog,
  CreateBankAccountLogSuccess,
  CreateBankAccountLogFail,
  UpdateBankAccount,
  UpdateBankAccountSuccess,
  UpdateBankAccountFail,
  BlockBankAccount,
  BlockBankAccountSuccess,
  BlockBankAccountFail,
  ActivateBankAccount,
  ActivateBankAccountSuccess,
  ActivateBankAccountFail,
  DeleteBankAccount,
  DeleteBankAccountSuccess,
  DeleteBankAccountFail,
  FindBankAccounts,
  FindBankAccountsSuccess,
  FindBankAccountsFail,
  CreateBankAccountLogDepositByEmployeeFail,
  CreateBankAccountLogDepositByEmployeeSuccess,
  CreateBankAccountLogDepositByEmployee,
  CreateBankAccountLogByWithdrawFromAccount,
  CreateBankAccountLogByWithdrawFromAccountSuccess,
  CreateBankAccountLogByWithdrawFromAccountFail,
  CreateBankAccountLogMoneyTransfer,
  CreateBankAccountLogMoneyTransferSuccess,
  CreateBankAccountLogMoneyTransferFail,
  CreateBankAccountLogCashReceipt,
  CreateBankAccountLogCashReceiptSuccess,
  CreateBankAccountLogCashReceiptFail,
  CreateBankAccountLogSettlementDeed,
  CreateBankAccountLogSettlementDeedSuccess,
  CreateBankAccountLogSettlementDeedFail
} from 'finances/store/actions';

@Injectable()
export class BankAccountsEffects {
  constructor(
    private actions$: Actions,
    private bankAccountsService: BankAccountsService,
    private notificationService: NotificationService,
    private translationService: TranslationService
  ) {}

  /* ========================= SEARCH_BANK_ACCOUNTS =================================== */
  @Effect()
  search$ = this.actions$.pipe(
    ofType(BankAccountsActionType.SEARCH_BANK_ACCOUNTS),
    debounceTime(300),
    switchMap(({ payload }: SearchBankAccounts) =>
      this.bankAccountsService.search(payload?.name ?? '', payload.banks, payload.page, PAGINATION.BankAccounts).pipe(
        map((response) => new SearchBankAccountsSuccess(response)),
        catchError((error) => of(new SearchBankAccountsFail(error)))
      )
    )
  );

  /* ========================= SEARCH_BANK_ACCOUNT_LOGS =================================== */
  @Effect()
  searchBankAccountLogs$ = this.actions$.pipe(
    ofType(BankAccountsActionType.SEARCH_BANK_ACCOUNT_LOGS),
    switchMap(({ payload }: SearchBankAccountLogs) => {
      return this.bankAccountsService
        .searchBankAccountLogs(
          payload.bankAccountId,
          payload.fromDate,
          payload.toDate,
          payload.page,
          PAGINATION.BankAccountLogs
        )
        .pipe(
          map((response: any) => new SearchBankAccountLogsSuccess(response)),
          catchError((error) => of(new SearchBankAccountLogsFail(error)))
        );
    })
  );

  /* ========================= FIND_BANK_ACCOUNT =================================== */
  @Effect()
  find$ = this.actions$.pipe(
    ofType(BankAccountsActionType.FIND_BANK_ACCOUNT),
    switchMap((action: FindBankAccounts) =>
      this.bankAccountsService.findById(action.payload).pipe(
        map((response) => new FindBankAccountsSuccess(response)),
        catchError((error) => of(new FindBankAccountsFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  findFail$ = this.actions$.pipe(
    ofType(BankAccountsActionType.FIND_BANK_ACCOUNT_FAIL),
    tap((action: FindBankAccountsFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate('FINANCES.BANK_ACCOUNTS.BANK_ACCOUNT_NOT_FOUND')
        );
      }
    })
  );

  /* ========================= CREATE_BANK_ACCOUNT =================================== */
  @Effect()
  create$ = this.actions$.pipe(
    ofType(BankAccountsActionType.CREATE_BANK_ACCOUNT),
    switchMap((action: CreateBankAccount) =>
      this.bankAccountsService.create(action.payload).pipe(
        map((response) => new CreateBankAccountSuccess(response)),
        catchError((error) => of(new CreateBankAccountFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  createSuccess$ = this.actions$.pipe(
    ofType(BankAccountsActionType.CREATE_BANK_ACCOUNT_SUCCESS),
    tap((action: CreateBankAccountSuccess) => {
      this.notificationService.success(this.translationService.translate('FINANCES.BANK_ACCOUNTS.BANK_ACCOUNT_ADDED'));
    })
  );

  @Effect({ dispatch: false })
  createFail$ = this.actions$.pipe(
    ofType(BankAccountsActionType.CREATE_BANK_ACCOUNT_FAIL),
    tap((action: CreateBankAccountFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );

  /* ========================= CREATE_BANK_ACCOUNT_LOG =================================== */
  @Effect()
  createLog$ = this.actions$.pipe(
    ofType(BankAccountsActionType.CREATE_BANK_ACCOUNT_LOG),
    switchMap((action: CreateBankAccountLog) =>
      this.bankAccountsService.createBankAccountLog(action.payload).pipe(
        map((response) => new CreateBankAccountLogSuccess(response)),
        catchError((error) => of(new CreateBankAccountLogFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  createLogSuccess$ = this.actions$.pipe(
    ofType(BankAccountsActionType.CREATE_BANK_ACCOUNT_LOG_SUCCESS),
    tap((action: CreateBankAccountLogSuccess) => {
      this.notificationService.success(
        this.translationService.translate('FINANCES.BANK_ACCOUNTS.BANK_ACCOUNT_LOG_ADDED')
      );
    })
  );

  @Effect({ dispatch: false })
  createLogFail$ = this.actions$.pipe(
    ofType(BankAccountsActionType.CREATE_BANK_ACCOUNT_LOG_FAIL),
    tap((action: CreateBankAccountLogFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );

  /* ========================= CREATE_BANK_ACCOUNT_LOG_DEPOSIT_BY_EMPLOYEE =================================== */
  @Effect()
  createLogDepositByEmployee$ = this.actions$.pipe(
    ofType(BankAccountsActionType.CREATE_BANK_ACCOUNT_LOG_DEPOSIT_BY_EMPLOYEE),
    switchMap((action: CreateBankAccountLogDepositByEmployee) =>
      this.bankAccountsService.createBankAccountDepositByEmployee(action.payload).pipe(
        map((response) => new CreateBankAccountLogDepositByEmployeeSuccess(response)),
        catchError((error) => of(new CreateBankAccountLogDepositByEmployeeFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  createLogDepositByEmployeeSuccess$ = this.actions$.pipe(
    ofType(BankAccountsActionType.CREATE_BANK_ACCOUNT_LOG_DEPOSIT_BY_EMPLOYEE_SUCCESS),
    tap((action: CreateBankAccountLogDepositByEmployeeSuccess) => {
      this.notificationService.success(
        this.translationService.translate('FINANCES.BANK_ACCOUNTS.BANK_ACCOUNT_LOG_BY_EMPLOYEE_ADDED')
      );
    })
  );

  @Effect({ dispatch: false })
  createLogDepositByEmployeeFail$ = this.actions$.pipe(
    ofType(BankAccountsActionType.CREATE_BANK_ACCOUNT_LOG_DEPOSIT_BY_EMPLOYEE_FAIL),
    tap((action: CreateBankAccountLogDepositByEmployeeFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );

  /* ========================= CREATE_BANK_ACCOUNT_BY_WITHDRAW_FROM_ACCOUNT =================================== */
  @Effect()
  createByWithdrawFromAccount$ = this.actions$.pipe(
    ofType(BankAccountsActionType.CREATE_BANK_ACCOUNT_LOG_BY_WITHDRAW_FROM_ACCOUNT),
    switchMap((action: CreateBankAccountLogByWithdrawFromAccount) =>
      this.bankAccountsService.createBankAccountByWithdrawFromAccount(action.payload).pipe(
        map((response) => new CreateBankAccountLogByWithdrawFromAccountSuccess(response)),
        catchError((error) => of(new CreateBankAccountLogByWithdrawFromAccountFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  createByWithdrawFromAccountSuccess$ = this.actions$.pipe(
    ofType(BankAccountsActionType.CREATE_BANK_ACCOUNT_LOG_BY_WITHDRAW_FROM_ACCOUNT_SUCCESS),
    tap((action: CreateBankAccountLogByWithdrawFromAccountSuccess) => {
      this.notificationService.success(
        this.translationService.translate('FINANCES.BANK_ACCOUNTS.BANK_ACCOUNT_LOG_WITHDRAW_ADDED')
      );
    })
  );
  @Effect({ dispatch: false })
  createByWithdrawFromAccountFail$ = this.actions$.pipe(
    ofType(BankAccountsActionType.CREATE_BANK_ACCOUNT_LOG_BY_WITHDRAW_FROM_ACCOUNT_FAIL),
    tap((action: CreateBankAccountLogByWithdrawFromAccountFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );

  /* ========================= CREATE_BANK_ACCOUNT_LOG_MONEY_TRANSFER =================================== */
  @Effect()
  createMoneyTransfer$ = this.actions$.pipe(
    ofType(BankAccountsActionType.CREATE_BANK_ACCOUNT_LOG_MONEY_TRANSFER),
    switchMap((action: CreateBankAccountLogMoneyTransfer) =>
      this.bankAccountsService.createBankAccountMoneyTransfer(action.payload).pipe(
        map((response) => new CreateBankAccountLogMoneyTransferSuccess(response)),
        catchError((error) => of(new CreateBankAccountLogMoneyTransferFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  createMoneyTransferSuccess$ = this.actions$.pipe(
    ofType(BankAccountsActionType.CREATE_BANK_ACCOUNT_LOG_MONEY_TRANSFER_SUCCESS),
    tap((action: CreateBankAccountLogMoneyTransferSuccess) => {
      this.notificationService.success(
        this.translationService.translate('FINANCES.BANK_ACCOUNTS.MONEY_TRANSFER_ADDED')
      );
    })
  );

  @Effect({ dispatch: false })
  createMoneyTransferFail$ = this.actions$.pipe(
    ofType(BankAccountsActionType.CREATE_BANK_ACCOUNT_LOG_MONEY_TRANSFER_FAIL),
    tap((action: CreateBankAccountLogMoneyTransferFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );

  /* ========================= CREATE_BANK_ACCOUNT_LOG_CASH_RECEIPT =================================== */
  @Effect()
  createCashReceipt$ = this.actions$.pipe(
    ofType(BankAccountsActionType.CREATE_BANK_ACCOUNT_LOG_CASH_RECEIPT),
    switchMap((action: CreateBankAccountLogCashReceipt) =>
      this.bankAccountsService.createBankAccountCashReceipt(action.payload).pipe(
        map((response) => new CreateBankAccountLogCashReceiptSuccess(response)),
        catchError((error) => of(new CreateBankAccountLogCashReceiptFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  createCashReceiptSuccess$ = this.actions$.pipe(
    ofType(BankAccountsActionType.CREATE_BANK_ACCOUNT_LOG_CASH_RECEIPT_SUCCESS),
    tap((action: CreateBankAccountLogCashReceiptSuccess) => {
      this.notificationService.success(
        this.translationService.translate('FINANCES.BANK_ACCOUNTS.CASH_RECEIPT_ADDED')
      );
    })
  );

  @Effect({ dispatch: false })
  createCashReceiptFail$ = this.actions$.pipe(
    ofType(BankAccountsActionType.CREATE_BANK_ACCOUNT_LOG_CASH_RECEIPT_FAIL),
    tap((action: CreateBankAccountLogCashReceiptFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );

  /* ========================= CREATE_BANK_ACCOUNT_LOG_SETTLEMENT_DEED =================================== */
  @Effect()
  createSettlementDeed$ = this.actions$.pipe(
    ofType(BankAccountsActionType.CREATE_BANK_ACCOUNT_LOG_SETTLEMENT_DEED),
    switchMap((action: CreateBankAccountLogSettlementDeed) =>
      this.bankAccountsService.createBankAccountSettlementDeed(action.payload).pipe(
        map((response) => new CreateBankAccountLogSettlementDeedSuccess(response)),
        catchError((error) => of(new CreateBankAccountLogSettlementDeedFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  createSettlementDeedSuccess$ = this.actions$.pipe(
    ofType(BankAccountsActionType.CREATE_BANK_ACCOUNT_LOG_SETTLEMENT_DEED_SUCCESS),
    tap((action: CreateBankAccountLogSettlementDeedSuccess) => {
      this.notificationService.success(
        this.translationService.translate('FINANCES.BANK_ACCOUNTS.SETTLEMENT_DEED_ADDED')
      );
    })
  );

  @Effect({ dispatch: false })
  createSettlementDeedFail$ = this.actions$.pipe(
    ofType(BankAccountsActionType.CREATE_BANK_ACCOUNT_LOG_SETTLEMENT_DEED_FAIL),
    tap((action: CreateBankAccountLogFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );

  /* ========================= UPDATE_BANK_ACCOUNT =================================== */
  @Effect()
  update$ = this.actions$.pipe(
    ofType(BankAccountsActionType.UPDATE_BANK_ACCOUNT),
    switchMap((action: UpdateBankAccount) =>
      this.bankAccountsService.update(action.payload).pipe(
        map((response) => new UpdateBankAccountSuccess(response)),
        catchError((error) => of(new UpdateBankAccountFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  updateSuccess$ = this.actions$.pipe(
    ofType(BankAccountsActionType.UPDATE_BANK_ACCOUNT_SUCCESS),
    tap((action: UpdateBankAccountSuccess) => {
      this.notificationService.success(
        this.translationService.translate('FINANCES.BANK_ACCOUNTS.BANK_ACCOUNT_UPDATED')
      );
    })
  );

  @Effect({ dispatch: false })
  updateFail$ = this.actions$.pipe(
    ofType(BankAccountsActionType.UPDATE_BANK_ACCOUNT_FAIL),
    tap((action: UpdateBankAccountFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate('FINANCES.BANK_ACCOUNTS.BANK_ACCOUNT_NOT_FOUND')
        );
      } else if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );

  /* ========================= BLOCK_BANK_ACCOUNT =================================== */
  @Effect()
  block$ = this.actions$.pipe(
    ofType(BankAccountsActionType.BLOCK_BANK_ACCOUNT),
    switchMap((action: BlockBankAccount) =>
      this.bankAccountsService.block(action.payload).pipe(
        map((response) => new BlockBankAccountSuccess(response)),
        catchError((error) => of(new BlockBankAccountFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  blockSuccess$ = this.actions$.pipe(
    ofType(BankAccountsActionType.BLOCK_BANK_ACCOUNT_SUCCESS),
    tap((action: BlockBankAccountSuccess) => {
      this.notificationService.success(
        this.translationService.translate('FINANCES.BANK_ACCOUNTS.BANK_ACCOUNT_BLOCKED')
      );
    })
  );

  @Effect({ dispatch: false })
  blockFail$ = this.actions$.pipe(
    ofType(BankAccountsActionType.BLOCK_BANK_ACCOUNT_FAIL),
    tap((action: BlockBankAccountFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate('FINANCES.BANK_ACCOUNTS.BANK_ACCOUNT_NOT_FOUND')
        );
      } else if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );

  /* ========================= ACTIVATE_BANK_ACCOUNT =================================== */
  @Effect()
  activate$ = this.actions$.pipe(
    ofType(BankAccountsActionType.ACTIVATE_BANK_ACCOUNT),
    switchMap((action: ActivateBankAccount) =>
      this.bankAccountsService.activate(action.payload).pipe(
        map((response) => new ActivateBankAccountSuccess(response)),
        catchError((error) => of(new ActivateBankAccountFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  activateSuccess$ = this.actions$.pipe(
    ofType(BankAccountsActionType.ACTIVATE_BANK_ACCOUNT_SUCCESS),
    tap((action: ActivateBankAccountSuccess) => {
      this.notificationService.success(
        this.translationService.translate('FINANCES.BANK_ACCOUNTS.BANK_ACCOUNT_ACTIVATED')
      );
    })
  );

  @Effect({ dispatch: false })
  activateFail$ = this.actions$.pipe(
    ofType(BankAccountsActionType.ACTIVATE_BANK_ACCOUNT_FAIL),
    tap((action: ActivateBankAccountFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate('FINANCES.BANK_ACCOUNTS.BANK_ACCOUNT_NOT_FOUND')
        );
      } else if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );

  /* ========================= DELETE_BANK_ACCOUNT =================================== */
  @Effect()
  delete$ = this.actions$.pipe(
    ofType(BankAccountsActionType.DELETE_BANK_ACCOUNT),
    switchMap((action: DeleteBankAccount) =>
      this.bankAccountsService.delete(action.payload).pipe(
        map((response) => new DeleteBankAccountSuccess(response)),
        catchError((error) => of(new DeleteBankAccountFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  deleteSuccess$ = this.actions$.pipe(
    ofType(BankAccountsActionType.DELETE_BANK_ACCOUNT_SUCCESS),
    tap((action: DeleteBankAccountSuccess) => {
      this.notificationService.success(
        this.translationService.translate('FINANCES.BANK_ACCOUNTS.BANK_ACCOUNT_DELETED')
      );
    })
  );

  @Effect({ dispatch: false })
  deleteFail$ = this.actions$.pipe(
    ofType(BankAccountsActionType.DELETE_BANK_ACCOUNT_FAIL),
    tap((action: DeleteBankAccountFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate('FINANCES.BANK_ACCOUNTS.BANK_ACCOUNT_NOT_FOUND')
        );
      } else if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );
}
