import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

import { Observable, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import * as fromSecurityStore from 'security/store';
import * as fromAuthStore from 'auth/store';
import { PageInfo } from 'shared';
import { Claims, User } from 'security/models';

/**
 * The update user component pages.
 */
enum PAGES {
  claims = 'claims',
  'update-user' = 'update-user',
  credentials = 'credentials',
  locations = 'locations',
  'bank-accounts' = 'bank-accounts',
  'cost-centers' = 'cost-centers',
}

@Component({
  selector: 'app-update-user-data',
  templateUrl: './update-user.component.html',
  styles: [],
})
export class UpdateUserComponent implements OnInit, OnDestroy {
  /**
   * Gets or sets the information about the current page.
   */
  pageInfo: PageInfo = {
    title: 'SECURITY.USERS.UPDATE_USER_DATA_TITLE',
    icon: 'fa fa-edit',
  };

  /**
   * The system supported user claims.
   */
  Claims = Claims;

  /**
   * Gets or sets the id of the current edited user.
   */
  userId: number;

  /**
   * The current logged in user id.
   */
  currentLoggedInUserId: number;

  /**
   * The current edited user.
   */
  user$: Observable<User>;

  /**
   * Gets or sets the selected page.
   * @default 'update-user'
   */
  activePage: PAGES = PAGES['update-user'];

  /**
   * The set of subscriptions on this components,
   * these subscriptions must be unsubscribed before this component got destroyed.
   */
  subscriptions = new Subscription();

  /**
   * @param modalService The modal service.
   * @param route The activate route service.
   * @param locationService The location service.
   * @param printService The print service.
   * @param translationService The translation service.
   * @param purchasesStore$ the purchases-store module.
   */

  constructor(
    private modalService: NgbModal,
    private route: ActivatedRoute,
    private locationService: Location,
    private authStore$: Store<fromAuthStore.AuthState>,
    private securityStore$: Store<fromSecurityStore.SecurityState>
  ) {}

  ngOnInit(): void {
    this.init();
  }

  /**
   * Unsubscribes for component data.
   */
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  /**
   * Initialize component data.
   */
  init() {
    /* Load the selected user. */
    this.userId = +this.route.snapshot.params.userId;
    this.securityStore$.dispatch(new fromSecurityStore.FindUser(this.userId));

    /* Load the selected user. */
    this.user$ = this.securityStore$.pipe(
      select(fromSecurityStore.getSelectedUser),
      tap((user) => {
        if (user) {
          /* Update page info. */
          if (user.organizationId) {
            this.pageInfo = {
              ...this.pageInfo,
              subTitle: `(${user.name}) ${user.organization.name}`,
            };
          } else {
            this.pageInfo = {
              ...this.pageInfo,
              subTitle: `(${user.name})`,
            };
          }
        }
      })
    );

    /** Load current logged in user data. */
    this.subscriptions.add(
      this.authStore$
        .pipe(
          select(fromAuthStore.getSessionUser),
          tap((user: User) => {
            if (user) {
              if (user.organizationId && this.userId === user.id) {
                this.activePage = PAGES.locations;
              } else if (user.organizationId) {
                this.activePage = PAGES.claims;
              }
              this.currentLoggedInUserId = user.id;
            }
          })
        )
        .subscribe()
    );

    /** Select the user desired page. */
    this.activePage = PAGES[this.route.snapshot.fragment] ?? this.activePage;
    this.selectedPageChanged(this.activePage);
  }

  /**
   * Updates the browser url according to the selected page.
   * @param page The newly selected page.
   */
  selectedPageChanged(page: PAGES) {
    this.locationService.replaceState(`${this.locationService.path()}#${PAGES[page]}`);
  }

  /**
   * Opens the modal of the given templateRef.
   * @param modalRef The modal templateRef to be opened.
   */
  openModal(modalRef) {
    this.modalService.open(modalRef);
  }
}
