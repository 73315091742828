import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location as AngularLocation } from '@angular/common';
import { FormControl, FormGroup } from '@angular/forms';

import { Observable, Subscription } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { Color } from 'ng2-charts';
import { ChartOptions } from 'chart.js';
import { skip, tap } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import * as fromStatisticsStore from 'statistics/store';
import * as fromSettingsStore from 'settings/store';
import { TranslationService, NotificationMessage, NotificationService, ToDecimalPipe, ToNumberPipe } from 'shared';
import * as fromAuthStore from 'auth/store';
import { User } from 'security/models';
import { Location } from 'stores/models';
import { StatisticsChartData, StatisticsData } from 'statistics/models';

/**
 * The update dashboard pages.
 */
enum PAGES {
  statistics = 'statistics',
  sales = 'sales',
  'points-of-sale' = 'points-of-sale',
  purchases = 'purchases',
  stores = 'stores',
  finances = 'finances',
  users = 'users',
}
@Component({
  selector: 'app-statistics',
  templateUrl: './statistics.component.html',
  styles: [],
})
export class StatisticsComponent implements OnInit, OnDestroy {
  /**
   * Gets or sets the selected page.
   * @default 'details'
   */
  activePage: PAGES = PAGES.statistics;

  /**
   * The search modal template reference.
   */
  @ViewChild('searchModalRef') searchModalRef: ElementRef<any>;

  /**
   * The close modal template reference.
   */
  @ViewChild('closeModalRef') closeModalRef: ElementRef<any>;

  /**
   * The loaded statistics.
   */
  statistics$: Observable<StatisticsData>;

  /**
   * The list of selected locations.
   */
  locations: Location[] = [];

  /**
   * Sets the public configuration of chart.
   */
  lineChartColors: Color[] = [
    {
      borderColor: '#80679E',
      backgroundColor: 'rgb(128, 103, 158, 0.4)',
    },
  ];

  lineChartColorsForHourlySales: Color[] = [
    {
      borderColor: '#80679E',
      backgroundColor: 'rgb(191, 180, 205, 0.7)',
    },
  ];

  lineChartColorsForTop: Color[] = [
    {
      borderColor: 'white',
      backgroundColor: ['#80679E', '#9C86AF', '#B0A2C2', '#CEC2D6', '#DEDEDE'],
    },
  ];

  lineChartLegend = false;

  lineChartType = 'line';

  lineChartTypeForHourlySales = 'bar';

  lineChartTypeForTop = 'pie';

  chartsOptions: ChartOptions;

  pieChartsOptions: ChartOptions;

  /**
   * Shows or hide the locations list.
   */
  locationsListVisibility = false;

  /**
   * The loaded statistics charts.
   */
  statisticsCharts: StatisticsChartData[];

  /**
   * The current logged in user.
   */
  currentLoggedInUser: User;

  /**
   * The active day button.
   */
  isDayButton = true;

  /**
   * The active week button.
   */
  isWeekButton = false;

  /**
   * * The active month button.
   */
  isMonthButton = false;

  /** The initial `date` value for search. */
  initialDate = new Date();

  /**
   * The search form.
   */
  searchForm: FormGroup;

  /**
   * Gets or sets the current selected language.
   */
  get currentLang() {
    return this.translationService.language;
  }

  /**
   * Gets or sets the ngb-dropdown or ngb-tooltip position based on the current user display language.
   */
  get ngbPosition(): string {
    return this.currentLang === 'ar' ? 'bottom-right' : 'bottom-left';
  }

  /**
   * The set of subscriptions on this components,
   * these subscriptions must be unsubscribed before this component got destroyed.
   */
  subscriptions = new Subscription();

  /**
   * @param modalService The modal service.
   * @param statisticsStore$ The statistics-module store.
   * @param translationService: The translation service.
   * @param route The route service.
   * @param locationService The location service.
   * @param authStore$ The auth-store.
   * @param notificationService The notification service.
   */
  constructor(
    private modalService: NgbModal,
    private statisticsStore$: Store<fromStatisticsStore.StatisticsMainState>,
    private settingsStore$: Store<fromSettingsStore.SettingsState>,
    private translationService: TranslationService,
    private route: ActivatedRoute,
    private locationService: AngularLocation,
    private authStore$: Store<fromAuthStore.AuthState>,
    private notificationService: NotificationService
  ) {}

  ngOnInit(): void {
    this.init();
  }

  /**
   * Destroy component data
   */
  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  /**
   * Initialize component data.
   */
  init() {
    this.initForm();

    /** Load organization settings. */
    this.subscriptions.add(
      this.settingsStore$
        .pipe(
          select(fromSettingsStore.getOrganizationsSettings),
          skip(1),
          tap((organizationSetting) => {
            const workDayStartsAtHour = organizationSetting?.workDayStartsAt.substring(0, 2);
            const currentHour = this.initialDate.getHours();
            if (currentHour < parseFloat(workDayStartsAtHour)) {
              this.initialDate.setDate(this.initialDate.getDate() - 1);
              const date = {
                year: this.initialDate.getFullYear(),
                month: this.initialDate.getMonth() + 1,
                day: this.initialDate.getDate(),
              };
              this.searchForm.patchValue({ date });
            }
            this.applyFiltersAndSearch('isDay');
          })
        )
        .subscribe()
    );

    /** Load data. */
    let isManualSearchTriggeredBeforeForStatistics = false;
    this.statistics$ = this.statisticsStore$.pipe(
      select(fromStatisticsStore.getStatistics),
      tap((statistics) => {
        if (!isManualSearchTriggeredBeforeForStatistics && !Object.keys(statistics).length) {
          isManualSearchTriggeredBeforeForStatistics = true;
        }
      })
    );

    this.subscriptions.add(
      this.statisticsStore$
        .pipe(
          select(fromStatisticsStore.getStatisticsCharts),
          tap((statisticsChart) => {
            if (statisticsChart) {
              this.statisticsCharts = JSON.parse(JSON.stringify(statisticsChart));
            }
          })
        )
        .subscribe()
    );

    /** Load current logged in user data. */
    this.subscriptions.add(
      this.authStore$
        .pipe(
          select(fromAuthStore.getSessionUser),
          tap((user: User) => {
            if (user) {
              this.currentLoggedInUser = user;
            }
          })
        )
        .subscribe()
    );

    /** Charts options. */
    this.chartsOptions = {
      scales: {
        yAxes: [
          {
            ticks: {
              callback: (dataLabel) => {
                return new ToNumberPipe('en-US').transform(dataLabel);
              },
            },
          },
        ],
      },
      tooltips: {
        callbacks: {
          label(tooltipItem) {
            if (tooltipItem.yLabel.toString().includes('.')) {
              return new ToDecimalPipe('en-US').transform(tooltipItem.yLabel);
            } else {
              return new ToNumberPipe('en-US').transform(tooltipItem.yLabel);
            }
          },
        },
      },
    };

    /** Pie charts options. */
    this.pieChartsOptions = {
      tooltips: {
        callbacks: {
          label(tooltipItem, data) {
            if (data.datasets[0].data[tooltipItem.index].toString().includes('.')) {
              return new ToDecimalPipe('en-US').transform(data.datasets[0].data[tooltipItem.index]);
            } else {
              return new ToNumberPipe('en-US').transform(data.datasets[0].data[tooltipItem.index]);
            }
          },
        },
      },
    };

    /** Select the user desired page. */
    this.activePage = PAGES[this.route.snapshot.fragment] ?? this.activePage;
    this.selectedPageChanged(this.activePage);
  }

  /**
   * Initialize form and add validators.
   */
  initForm() {
    this.searchForm = new FormGroup({
      isDay: new FormControl(false),
      isWeek: new FormControl(false),
      isMonth: new FormControl(false),
      date: new FormControl({
        year: this.initialDate.getFullYear(),
        month: this.initialDate.getMonth() + 1,
        day: this.initialDate.getDate(),
      }),
      locations: new FormControl([]),
    });
  }

  /**
   * Updates the browser url according to the selected page.
   * @param page The newly selected page.
   */
  selectedPageChanged(page: PAGES) {
    this.locationService.replaceState(`${this.locationService.path()}#${PAGES[page]}`);
  }

  /**
   * Applies the search filters and dispatches a search action.
   * @param filterType The filter type of charts, default `isDay`.
   */
  applyFiltersAndSearch(filterType) {
    const { date } = this.searchForm.value;
    if (filterType === 'isWeek') {
      this.searchForm.value.isWeek = true;
      this.searchForm.value.isMonth = false;
      this.searchForm.value.isDay = false;
      this.isWeekButton = true;
      this.isDayButton = false;
      this.isMonthButton = false;
    } else if (filterType === 'isMonth') {
      this.searchForm.value.isWeek = false;
      this.searchForm.value.isMonth = true;
      this.searchForm.value.isDay = false;
      this.isWeekButton = false;
      this.isDayButton = false;
      this.isMonthButton = true;
    } else {
      this.searchForm.value.isWeek = false;
      this.searchForm.value.isMonth = false;
      this.searchForm.value.isDay = true;
      this.isDayButton = true;
      this.isWeekButton = false;
      this.isMonthButton = false;
    }

    const errorMessage = new NotificationMessage();
    if (this.searchForm.get('date').value === null || this.searchForm.get('date').invalid) {
      errorMessage.title = this.translationService.translate('CORE.DASHBOARD.DASHBOARD_DATA_VALIDATION.DATE_ERROR');
      errorMessage.body = [this.translationService.translate('CORE.DASHBOARD.DASHBOARD_DATA_VALIDATION.DATE_ERROR')];
      return this.notificationService.warningWithTitle(errorMessage);
    }

    this.statisticsStore$.dispatch(
      new fromStatisticsStore.LoadStatisticsCharts({
        day: date.day,
        month: date.month - 1,
        year: date.year,
        isDay: this.searchForm.value.isDay,
        isWeek: this.searchForm.value.isWeek,
        isMonth: this.searchForm.value.isMonth,
        locations: this.searchForm.get('locations').value,
      })
    );

    this.statisticsStore$.dispatch(
      new fromStatisticsStore.LoadStatistics({
        day: date.day,
        month: date.month - 1,
        year: date.year,
        isDay: this.searchForm.value.isDay,
        isWeek: this.searchForm.value.isWeek,
        isMonth: this.searchForm.value.isMonth,
        locations: this.searchForm.get('locations').value,
      })
    );
  }

  /**
   * stop select month temporary.
   */
  suspendService() {
    this.isDayButton = false;
    this.isWeekButton = false;
    this.isMonthButton = true;
    return this.notificationService.warning(this.translationService.translate('CORE.DASHBOARD.MONTH_WARNING'));
  }

  /**
   * Submits the form.
   */
  submit() {
    if (this.isDayButton) {
      this.applyFiltersAndSearch('isDay');
    } else if (this.isWeekButton) {
      this.applyFiltersAndSearch('isWeek');
    } else {
      this.applyFiltersAndSearch('isMonth');
    }
    this.closeModal(this.searchModalRef);
  }

  /**
   * Shows search location modal.
   */
  searchLocations() {
    this.openModal(this.searchModalRef);
  }

  /**
   * Adds the newly selected locations the locations search list.
   * @param locations The list of newly selected locations to be added.
   */
  selectLocations(locations: Location[]) {
    const selectedIds: number[] = this.searchForm.get('locations').value;
    this.locations = [...this.locations.filter((location) => selectedIds.includes(location.id)), ...locations];
    this.searchForm.patchValue({ locations: this.locations.map((location) => location.id) });
  }

  /**
   * Opens the modal of the given templateRef.
   * @param modalRef The modal templateRef to be opened.
   */
  openModal(modalRef) {
    this.modalService.open(modalRef);
  }

  /**
   * Closes the currently opened modal.
   * @param modalRef The modal templateRef to be closed.
   */
  closeModal(modalRef) {
    this.modalService.dismissAll(modalRef);
  }
}
