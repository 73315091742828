import * as fromPettyCash from 'finances/components/petty-cash';
import * as fromPettyCashRefundRequest from 'finances/components/petty-cash-refund-requests';
import * as fromPettyCashRefundRequestApprovals from 'finances/components/petty-cash-refund-requests-approvals';
import * as fromPettyCashRefund from 'finances/components/petty-cash-refunds';
import * as fromPettyCashReduction from 'finances/components/petty-cash-reductions';
import * as fromCostCenter from 'finances/components/cost-centers';
import * as fromAccounts from 'finances/components/accounts';
import * as fromJournals from 'finances/components/journals';
import * as fromBanks from 'finances/components/banks';
import * as fromBankAccounts from 'finances/components/bank-accounts';
import * as fromFinancialPeriods from 'finances/components/financial-periods';
import * as fromCurrencies from 'finances/components/currencies';
import * as fromCurrencyExchangeRate from 'finances/components/currency-exchange-rates';
import * as fromHr from 'finances/components/hr';
import * as fromFinanceConfigurations from 'finances/components/finance-configurations';

export * from 'finances/components/petty-cash';
export * from 'finances/components/petty-cash-refund-requests';
export * from 'finances/components/petty-cash-refund-requests-approvals';
export * from 'finances/components/petty-cash-refunds';
export * from 'finances/components/petty-cash-reductions';
export * from 'finances/components/cost-centers';
export * from 'finances/components/accounts';
export * from 'finances/components/journals';
export * from 'finances/components/banks';
export * from 'finances/components/bank-accounts';
export * from 'finances/components/financial-periods';
export * from 'finances/components/currencies';
export * from 'finances/components/currency-exchange-rates';
export * from 'finances/components/hr';
export * from 'finances/components/finance-configurations';

/**
 * The set of components registered on this module.
 */
export const COMPONENTS: any[] = [
  ...fromPettyCash.COMPONENTS,
  ...fromPettyCashRefund.COMPONENTS,
  ...fromPettyCashReduction.COMPONENTS,
  ...fromPettyCashRefundRequest.COMPONENTS,
  ...fromPettyCashRefundRequestApprovals.COMPONENTS,
  ...fromCostCenter.COMPONENTS,
  ...fromAccounts.COMPONENTS,
  ...fromJournals.COMPONENTS,
  ...fromBanks.COMPONENTS,
  ...fromBankAccounts.COMPONENTS,
  ...fromFinancialPeriods.COMPONENTS,
  ...fromCurrencies.COMPONENTS,
  ...fromCurrencyExchangeRate.COMPONENTS,
  ...fromHr.COMPONENTS,
  ...fromFinanceConfigurations.COMPONENTS,
];
