
    import { IdentityType } from 'lookups/models';

    /**
     * Represents the IdentityTypes state.
     */
    export class IdentityTypeState {
      /**
       * The list of IdentityTypes.
       */
      public data: IdentityType [];
    
      /**
       * An error that may resulting during processing some actions.
       */
      public error: any;
    
      /**
       * Determines if there is a running load process.
       */
      public isLoading: boolean;
    
      /**
       * Determines if the last load process has been ended successfully.
       */
      public isLoaded: boolean;
    }

