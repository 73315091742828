import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { AppHttpResponse } from 'shared';
import { OrganizationSetting, UpdateOrganizationSettingInput } from 'settings/models';

/**
 * The organization settings services includes functionality to get and update a organization settings.
 */

@Injectable()
export class OrganizationSettingsService {
  /**
   * The relative route for the organization settings.
   *
   * No leading or trailing slashes required.
   */
  private organizationSettingsApi = 'organizations/settings';
  private organizationApi = 'organizations';

  constructor(private http: HttpClient) {}

  /**
   * Gets the organization settings.
   */
  public getOrganizationSettings(): Observable<AppHttpResponse<OrganizationSetting>> {
    return this.http.get<AppHttpResponse<OrganizationSetting>>(`${this.organizationSettingsApi}`);
  }

  /**
   * Updates an existing organization settings data using the provided data.
   * @param data The updated organization settings data.
   */
  public updateOrganizationSettings(
    data: UpdateOrganizationSettingInput
  ): Observable<AppHttpResponse<OrganizationSetting>> {
    return this.http.put<AppHttpResponse<OrganizationSetting>>(`${this.organizationSettingsApi}`, data);
  }

  /**
   * Updates an existing product's photo.
   * @param id The id of the product.
   * @param logo The new organization settings logo.
   */
  public updateLogo(logo: File): Observable<AppHttpResponse<OrganizationSetting>> {
    const formData: any = new FormData();
    formData.append('logo', logo, logo.name);

    return this.http.put<AppHttpResponse<OrganizationSetting>>(`${this.organizationApi}/logo`, formData);
  }

  /**
   * Deletes the organization logo by given product id.
   * @param id The id of the product.
   */
  public deleteLogo(): Observable<AppHttpResponse<OrganizationSetting>> {
    return this.http.delete<AppHttpResponse<OrganizationSetting>>(`${this.organizationApi}/logo`);
  }

  /**
   * Updates an existing settings's  header image.
   * @param invoiceHeaderFilename The new setting's  header image.
   */
  public updateImageHeader(invoiceHeaderFilename: File): Observable<AppHttpResponse<OrganizationSetting>> {
    const formData: any = new FormData();
    formData.append('invoiceHeaderFilename', invoiceHeaderFilename, invoiceHeaderFilename.name);

    return this.http.put<AppHttpResponse<OrganizationSetting>>(
      `${this.organizationApi}/invoice-header-image`,
      formData
    );
  }

  /**
   * Updates an existing setting's  footer image.
   * @param invoiceFooterFilename The new setting's  footer image.
   */
  public updateImageFooter(invoiceFooterFilename: File): Observable<AppHttpResponse<OrganizationSetting>> {
    const formData: any = new FormData();
    formData.append('invoiceFooterFilename', invoiceFooterFilename, invoiceFooterFilename.name);

    return this.http.put<AppHttpResponse<OrganizationSetting>>(
      `${this.organizationApi}/invoice-footer-image`,
      formData
    );
  }

  /**
   * Deletes the settings  header image by given settings id.
   * @param id The id of the settings.
   */
  public deleteImageHeader(): Observable<AppHttpResponse<OrganizationSetting>> {
    return this.http.delete<AppHttpResponse<OrganizationSetting>>(`${this.organizationApi}/invoice-header-image`);
  }

  /**
   * Deletes the setting  footer image by given setting id.
   * @param id The id of the setting.
   */
  public deleteImageFooter(): Observable<AppHttpResponse<OrganizationSetting>> {
    return this.http.delete<AppHttpResponse<OrganizationSetting>>(`${this.organizationApi}/invoice-footer-image`);
  }
}
