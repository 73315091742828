import { PaginationInfo } from 'shared/models';
import { Sector } from 'stores/models';

/**
 * Represents the sectors state.
 */
export class SectorsState {
  /**
   * The list of sectors.
   */
  data: Sector[];

  /**
   * The pagination info for the sectors.
   */
  paginationInfo: PaginationInfo;

  /**
   * An error that may resulting during processing some actions.
   */
  error: any;

  /**
   * Determines if there is a running search process.
   */
  isSearching: boolean;

  /**
   * Determines if the last search process has been ended successfully.
   */
  isSearchCompleted: boolean;
}
