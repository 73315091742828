import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';

import { AppHttpResponse } from 'shared';
import { Region } from 'stores/models';

/**
 * The regions services includes functionality to search for an region.
 */
@Injectable()
export class RegionsService {
  /**
   * The relative route for the regions.
   *
   * No leading or trailing slashes required.
   */
  private regionsApi = 'stores/regions';

  constructor(private http: HttpClient) {}

  /**
   * Searches in the regions by name.
   * @param name The name of the region.
   * @param page The current pagination page number.
   * @param pageSize The maximum number of regions allowed per one pagination page.
   */
  public search(name: string, page: number, pageSize: number): Observable<AppHttpResponse<Region[]>> {
    const params = new HttpParams().set('name', name).set('page', page.toString()).set('pageSize', pageSize.toString());
    return this.http.get<AppHttpResponse<Region[]>>(`${this.regionsApi}`, { params });
  }
}
