<!-- payroll gosi payroll elements modal starts -->
<ng-template #modalRef let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title text-primary" id="modal-basic-title">
      {{ 'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.GOSI_PAYROLL_ELEMENTS' | translate }}
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="d(); closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="col-md-12">
      <div class="table-responsive">
        <table class="table table-sm text-center">
          <thead>
            <tr>
              <th scope="col"><i class="fa fa-list-ol"></i></th>
              <th scope="col">
                {{ 'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.PAYROLL_ELEMENT' | translate }}
              </th>
              <th scope="col">
                {{ 'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.ELEMENT_CLASSIFICATION' | translate }}
              </th>
              <th scope="col">
                {{ 'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.PAYROLL_ELEMENT_TYPE' | translate }}
              </th>
              <th scope="col">
                {{ 'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.VALUE_TITLE' | translate }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              *ngFor="let payrollGosiPayrollElement of payrollGosiPayrollElements; trackBy: trackItems; let i = index"
            >
              <th scope="row">{{ i + 1 }}</th>
              <td>
                {{
                  payrollGosiPayrollElement.payrollElement.name
                    | localize: payrollGosiPayrollElement.payrollElement.nameEn
                    | async
                }}
              </td>
              <td>
                {{
                  payrollGosiPayrollElement.payrollElement.payrollElementClassification?.name
                    | localize: payrollGosiPayrollElement.payrollElement.payrollElementClassification?.nameEn
                    | async
                }}
              </td>
              <td>
                {{
                  payrollGosiPayrollElement.payrollElement.payrollElementType.name
                    | localize: payrollGosiPayrollElement.payrollElement.payrollElementType.nameEn
                    | async
                }}
                <a
                  class="info p-0 mx-2"
                  (click)="
                    selectedPayrollElement = payrollGosiPayrollElement.payrollElement;
                    payrollElementKey = payrollGosiPayrollElement.payrollElement.payrollElementType.key;
                    payrollElementFormulasListVisibility = true
                  "
                  [ngbTooltip]="'HR.EMPLOYEES.PAYROLL_ELEMENTS.PAYROLL_ELEMENT_FORMULAS' | translate"
                >
                  <i class="fa fa-info font-medium-3 mr-2"></i>
                </a>
              </td>
              <td>{{ payrollGosiPayrollElement.value | toDecimal }}</td>
            </tr>
          </tbody>
          <tfoot class="tfoot-light">
            <tr>
              <td [attr.colspan]="5">
                <i class="fa fa-bookmark c-primary"></i>
                <span>
                  {{ payrollGosiPayrollElements.length | toNumber }}
                  {{ 'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.GOSI_PAYROLL_ELEMENT' | translate }}
                </span>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="modal-actions">
      <button type="button" class="btn btn-outline-dark round" (click)="c(); closeModal()">
        <i class="fa fa-times"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.CLOSE' | translate }}</span>
      </button>
    </div>
  </div>
</ng-template>
<!-- payroll gosi payroll elements modal ends -->

<!-- payroll element formulas modal starts -->
<payroll-element-formulas
  [payrollElement]="selectedPayrollElement"
  [payrollElementTypeKey]="payrollElementKey"
  [(visible)]="payrollElementFormulasListVisibility"
>
</payroll-element-formulas>
<!-- payroll element formulas modal ends -->

<!-- no items template starts -->
<ng-template #noItems>
  <div class="col-md-12 alert alert-warning mt-3 text-center" role="alert">
    {{ 'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.GOSI_PAYROLL_ELEMENTS_NOT_FOUND' | translate }}
  </div>
</ng-template>
<!-- no items template ends -->
