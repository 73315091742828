import { createSelector } from '@ngrx/store';

import { selectLookupsState } from 'lookups/store/selectors/feature.selectors';
import { LookupsState, MaritalStatusState } from 'lookups/store/states';

/**
 * Gets the marital status state.
 */
const selectMaritalStatuses = createSelector(selectLookupsState, (state: LookupsState) => state.maritalStatuses);

/**
 * Gets the list of loaded marital statuses.
 */
export const getMaritalStatuses = createSelector(selectMaritalStatuses, (state: MaritalStatusState) => state.data);

/**
 * Gets the error that occurred in the state.
 */
export const getMaritalStatusesError = createSelector(
  selectMaritalStatuses,
  (state: MaritalStatusState) => state.error
);

/**
 * Determines if there is a running load process.
 */
export const getMaritalStatusesIsLoading = createSelector(
  selectMaritalStatuses,
  (state: MaritalStatusState) => state.isLoading
);

/**
 * Determines if the last load process has been ended successfully.
 */
export const getMaritalStatusesIsLoaded = createSelector(
  selectMaritalStatuses,
  (state: MaritalStatusState) => state.isLoaded
);
