import { Action } from '@ngrx/store';

import { AppHttpResponse } from 'shared';
import { CreatePosDeviceInput, PosDevice, UpdatePosDeviceInput } from 'sales/models';

/**
 * The stores-module store pos device action types.
 */
export enum PosDeviceActionType {
  SEARCH_POS_DEVICES = '[Sales] [POSDevice] Search POS Device',
  SEARCH_POS_DEVICES_FAIL = '[Sales] [POSDevice] Search POS Device Fail',
  SEARCH_POS_DEVICES_SUCCESS = '[Sales] [POSDevice] Search POS Device Success',

  FIND_POS_DEVICE = '[Sales] [POSDevice] Find POS Device',
  FIND_POS_DEVICE_FAIL = '[Sales] [POSDevice] Find POS Device Fail',
  FIND_POS_DEVICE_SUCCESS = '[Sales] [POSDevice] Find POS Device Success',

  CREATE_POS_DEVICE = '[Sales] [POSDevice] Create POS Device',
  CREATE_POS_DEVICE_FAIL = '[Sales] [POSDevice] Create POS Device Fail',
  CREATE_POS_DEVICE_SUCCESS = '[Sales] [POSDevice] Create POS Device Success',

  UPDATE_POS_DEVICE = '[Sales] [POSDevice] Update POS Device',
  UPDATE_POS_DEVICE_FAIL = '[Sales] [POSDevice] Update POS Device Fail',
  UPDATE_POS_DEVICE_SUCCESS = '[Sales] [POSDevice] Update POS Device Success',

  DELETE_POS_DEVICE = '[Sales] [POSDevice] Delete POS Device',
  DELETE_POS_DEVICE_FAIL = '[Sales] [POSDevice] Delete POS Device Fail',
  DELETE_POS_DEVICE_SUCCESS = '[Sales] [POSDevice] Delete POS Device Success',
}

/**
 * Represents a store Pos device search action.
 */
export class SearchPosDevices implements Action {
  /**
   * The type of the action.
   */
  readonly type = PosDeviceActionType.SEARCH_POS_DEVICES;

  /**
   * Fires a new pos device search action.
   * @param payload the search parameters,
   * if omitted, all pos device will be loaded.
   */
  constructor(
    public payload: {
      name: string;
      locations: number[];
      page: number;
    }
  ) {}
}

/**
 * Represents a store pos device search fail action.
 */
export class SearchPosDevicesFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = PosDeviceActionType.SEARCH_POS_DEVICES_FAIL;

  /**
   * Fires a new pos device search fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store search success action.
 */
export class SearchPosDevicesSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = PosDeviceActionType.SEARCH_POS_DEVICES_SUCCESS;

  /**
   * Fires a new pos device search success action.
   * @param payload An object contains the list of pos device that matches the search criteria
   * in addition to the pagination info.
   */
  constructor(public payload: AppHttpResponse<PosDevice[]>) {}
}

/**
 * Represents a store find pos device action.
 */
export class FindPosDevice implements Action {
  /**
   * The type of the action.
   */
  readonly type = PosDeviceActionType.FIND_POS_DEVICE;

  /**
   * Fires a new find pos device action.
   * @param payload the id of the pos device.
   */
  constructor(public payload: number) {}
}

/**
 * Represents a store find pos device fail action.
 */
export class FindPosDeviceFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = PosDeviceActionType.FIND_POS_DEVICE_FAIL;

  /**
   * Fires a new find pos device fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store find pos device success action.
 */
export class FindPosDeviceSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = PosDeviceActionType.FIND_POS_DEVICE_SUCCESS;

  /**
   * Fires a new find pos device success action.
   * @param payload The loaded pos device.
   */
  constructor(public payload: AppHttpResponse<PosDevice>) {}
}

/**
 * Represents a store pos device create action.
 */
export class CreatePosDevice implements Action {
  /** The type of the action. */
  readonly type = PosDeviceActionType.CREATE_POS_DEVICE;

  /**
   * Fires a new pos device create action.
   * @param payload the new pos device data-model.
   */
  constructor(public payload: CreatePosDeviceInput) {}
}

/**
 * Represents a store pos device create fail action.
 */
export class CreatePosDeviceFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = PosDeviceActionType.CREATE_POS_DEVICE_FAIL;

  /**
   * Fires a new pos device create fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store pos device create success action.
 */
export class CreatePosDeviceSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = PosDeviceActionType.CREATE_POS_DEVICE_SUCCESS;

  /**
   * Fires a new pos device create success action.
   * @param payload The created pos device.
   */
  constructor(public payload: AppHttpResponse<PosDevice>) {}
}

/**
 * Represents a store pos device update action.
 */
export class UpdatePosDevice implements Action {
  /**
   * The type of the action.
   */
  readonly type = PosDeviceActionType.UPDATE_POS_DEVICE;

  /**
   * Fires a new pos device update action.
   * @param payload The  pos device updated data.
   */
  constructor(public payload: UpdatePosDeviceInput) {}
}

/**
 * Represents a store pos device update fail action.
 */
export class UpdatePosDeviceFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = PosDeviceActionType.UPDATE_POS_DEVICE_FAIL;

  /**
   * Fires a new pos device update fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store pos device update success action.
 */
export class UpdatePosDeviceSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = PosDeviceActionType.UPDATE_POS_DEVICE_SUCCESS;

  /**
   * Fires a new pos device update-success action.
   * @param payload The updated pos device.
   */
  constructor(public payload: AppHttpResponse<PosDevice>) {}
}

/**
 * Represents a store pos device delete action.
 */
export class DeletePosDevice implements Action {
  /**
   * The type of the action.
   */
  readonly type = PosDeviceActionType.DELETE_POS_DEVICE;

  /**
   * Fires a new pos device delete action.
   * @param payload The pos device id.
   */
  constructor(public payload: number) {}
}

/**
 * Represents a store pos device delete fail action.
 */
export class DeletePosDeviceFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = PosDeviceActionType.DELETE_POS_DEVICE_FAIL;

  /**
   * Fires a new pos device delete fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store pos device delete success action.
 */
export class DeletePosDeviceSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = PosDeviceActionType.DELETE_POS_DEVICE_SUCCESS;

  /**
   * Fires a new pos device delete success action.
   * @param payload The deleted pos device.
   */
  constructor(public payload: AppHttpResponse<PosDevice>) {}
}

/**
 * stores-module pos device action types.
 */
export type PosDeviceActions =
  | SearchPosDevices
  | SearchPosDevicesFail
  | SearchPosDevicesSuccess
  | FindPosDevice
  | FindPosDeviceFail
  | FindPosDeviceSuccess
  | CreatePosDevice
  | CreatePosDeviceFail
  | CreatePosDeviceSuccess
  | UpdatePosDevice
  | UpdatePosDeviceFail
  | UpdatePosDeviceSuccess
  | DeletePosDevice
  | DeletePosDeviceFail
  | DeletePosDeviceSuccess;
