import { Action } from '@ngrx/store';

import { TimeZone } from 'lookups/models';
import { AppHttpResponse } from 'shared';

/**
 * The Lookups-Module store  time zone action types.
 */
export enum TimeZoneActionType {
  GET_ALL_TIME_ZONE = '[Lookups] [TimeZone] Get All Time Zones',
  GET_ALL_TIME_ZONE_FAIL = '[Lookups] [TimeZone] Get All Time Zones Fail',
  GET_ALL_TIME_ZONE_SUCCESS = '[Lookups] [TimeZone] Get All Time Zones Success',
}

/**
 * Represents a store  time zone action.
 */
export class GetAllTimeZone implements Action {
  /**
   * The type of the action.
   */
  readonly type = TimeZoneActionType.GET_ALL_TIME_ZONE;

  /**
   * Fires a new  time zone action.
   */
  constructor() {}
}

/**
 * Represents a store  time zone fail action.
 */
export class GetAllTimeZoneFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = TimeZoneActionType.GET_ALL_TIME_ZONE_FAIL;

  /**
   * Fires a new  time zone fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store  time zone success action.
 */
export class GetAllTimeZoneSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = TimeZoneActionType.GET_ALL_TIME_ZONE_SUCCESS;

  /**
   * Fires a  time zone success action.
   * @param payload An object contains the list of  time zones.
   */
  constructor(public payload: AppHttpResponse<TimeZone[]>) {}
}

/**
 * Lookups-module  time zone action types.
 */
export type TimeZoneActions = GetAllTimeZone
| GetAllTimeZoneFail
| GetAllTimeZoneSuccess;
