import { createSelector } from '@ngrx/store';

import { selectLookupsState } from 'lookups/store/selectors/feature.selectors';
import { LookupsState, CustomerPaymentLogTypeState } from 'lookups/store/states';

/**
 * Gets the customer payment log type state.
 */
const selectCustomerPaymentLogTypes = createSelector(
  selectLookupsState,
  (state: LookupsState) => state.customerPaymentLogTypes
);

/**
 * Gets the list of loaded customer payment log types.
 */
export const getCustomerPaymentLogTypes = createSelector(
  selectCustomerPaymentLogTypes,
  (state: CustomerPaymentLogTypeState) => state.data
);

/**
 * Gets the list of loaded customer payment log types including advance payments.
 */
export const getAllCustomerPaymentLogTypes = createSelector(
  selectCustomerPaymentLogTypes,
  (state: CustomerPaymentLogTypeState) => state.allData
);

/**
 * Gets the error that occurred in the state.
 */
export const getCustomerPaymentLogTypesError = createSelector(
  selectCustomerPaymentLogTypes,
  (state: CustomerPaymentLogTypeState) => state.error
);

/**
 * Determines if there is a running load process.
 */
export const getCustomerPaymentLogTypesIsLoading = createSelector(
  selectCustomerPaymentLogTypes,
  (state: CustomerPaymentLogTypeState) => state.isLoading
);

/**
 * Determines if the last load process has been ended successfully.
 */
export const getCustomerPaymentLogTypesIsLoaded = createSelector(
  selectCustomerPaymentLogTypes,
  (state: CustomerPaymentLogTypeState) => state.isLoaded
);
