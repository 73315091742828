import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';

import * as fromAuthStore from 'auth/store';
import { PageInfo, NotificationService, NotificationMessage, TranslationService } from 'shared';
import { User } from 'security/models';

@Component({
  selector: 'app-update-profile',
  templateUrl: './update-profile.component.html',
  styles: [],
})
export class UpdateProfileComponent implements OnInit {
  /**
   * The personals confirm modal template reference.
   */
  @ViewChild('personalsConfirmModalRef') personalsConfirmModalRef: ElementRef<any>;

  /**
   * The credentials confirm modal template reference.
   */
  @ViewChild('credentialsConfirmModalRef') credentialsConfirmModalRef: ElementRef<any>;

  /**
   * The api key confirm modal template reference.
   */
  @ViewChild('apiKeyConfirmModalRef') apiKeyConfirmModalRef: ElementRef<any>;

  /**
   * Gets or sets the information about the current page.
   */
  pageInfo: PageInfo = {
    title: 'SECURITY.USERS.UPDATE_PROFILE_PAGE_TITLE',
    icon: 'fa fa-user',
  };

  /**
   * The current logged in user.
   */
  user$: Observable<User>;

  /**
   * Indicates whether there is a update-user-profile-info/credentials process is running or not.
   */
  isUpdating$: Observable<boolean>;

  /**
   * Indicates whether there is a update-api key process is running or not.
   */
  isApiKeyUpdating$: Observable<boolean>;

  /**
   * The current logged in user.
   */
  currentLoggedInUser: User;

  /**
   * The update user personals form.
   */
  personalsForm: FormGroup;

  /**
   * The update api key form.
   */
  apiKeyForm: FormGroup;

  /**
   * The update user credentials form.
   */
  credentialsForm: FormGroup;

  /**
   * The set of subscriptions on this components,
   * these subscriptions must be unsubscribed before this component got destroyed.
   */
  subscriptions = new Subscription();

  /**
   * @param modalService The modal service.
   * @param notificationService The notification service.
   * @param translationService The translation service.
   * @param authStore$ The auth-module store.
   */
  constructor(
    private modalService: NgbModal,
    private notificationService: NotificationService,
    private translationService: TranslationService,
    private authStore$: Store<fromAuthStore.AuthState>
  ) {}

  ngOnInit(): void {
    this.init();
  }

  /**
   * Destroy component data
   */
  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  /**
   * Initialize component data.
   */
  init() {
    this.initForm();

    /** Load current logged in user data. */
    this.user$ = this.authStore$.pipe(
      select(fromAuthStore.getSessionUser),
      tap((user: User) => {
        /* Reset forms data after user's data gets updated. */
        this.personalsForm.setValue({ name: user.name });
        this.personalsForm.markAsUntouched();

        this.credentialsForm.reset();
        this.credentialsForm.markAsUntouched();
      })
    );

    /** Load current logged in user data. */
    this.subscriptions.add(
      this.authStore$
        .pipe(
          select(fromAuthStore.getSessionUser),
          tap((user: User) => {
            if (user) {
              this.currentLoggedInUser = user;
            }
          })
        )
        .subscribe()
    );

    /* Subscribe to get api key whenever it changed. */

    this.subscriptions.add(
      this.authStore$
        .pipe(
          select(fromAuthStore.getApiKey),
          tap((apiKey) => {
            if (apiKey) {
              this.apiKeyForm.setValue({ apiKey: apiKey.apiKey });
            }
          })
        )
        .subscribe()
    );

    this.authStore$.dispatch(new fromAuthStore.GetApiKey());

    /**
     * Set data.
     */
    this.isUpdating$ = this.authStore$.pipe(select(fromAuthStore.getSessionUpdatingProfile));
    this.isApiKeyUpdating$ = this.authStore$.pipe(select(fromAuthStore.getSelectedApiKeyUpdating));
  }

  /**
   * Initialize form and add validators.
   */
  initForm() {
    this.personalsForm = new FormGroup({
      name: new FormControl('', [Validators.required, Validators.minLength(1), Validators.maxLength(50)]),
    });

    this.apiKeyForm = new FormGroup({
      apiKey: new FormControl(''),
    });

    this.credentialsForm = new FormGroup({
      username: new FormControl('', [Validators.required, Validators.minLength(4), Validators.maxLength(50)]),
      oldPassword: new FormControl('', Validators.required),
      newPassword: new FormControl('', [Validators.required, Validators.minLength(8), Validators.maxLength(50)]),
    });
  }

  /**
   * Submits the personals form.
   */
  submitPersonals() {
    if (this.personalsForm.invalid) {
      const errorMessage = new NotificationMessage();

      if (this.personalsForm.get('name').invalid) {
        errorMessage.title = this.translationService.translate('SECURITY.USERS.USER_DATA_VALIDATION.USER_NAME_ERROR');
        errorMessage.body = [
          this.translationService.translate('SECURITY.USERS.USER_DATA_VALIDATION.USER_NAME_IS_REQUIRED'),
          this.translationService.translate('SECURITY.USERS.USER_DATA_VALIDATION.USER_NAME_LENGTH_ERROR'),
        ];
      }

      this.personalsForm.markAllAsTouched();
      return this.notificationService.warningWithTitle(errorMessage);
    } else {
      this.openModal(this.personalsConfirmModalRef);
    }
  }

  /**
   * Confirms the the personals form submit.
   */
  confirmPersonals() {
    this.authStore$.dispatch(new fromAuthStore.UpdateUserProfileInfo(this.personalsForm.value));
  }

  /**
   * Submits the credentials form.
   */
  submitCredentials() {
    if (this.credentialsForm.invalid) {
      const errorMessage = new NotificationMessage();

      if (this.credentialsForm.get('username').invalid) {
        errorMessage.title = this.translationService.translate('SECURITY.USERS.USER_DATA_VALIDATION.USERNAME_ERROR');
        errorMessage.body = [
          this.translationService.translate('SECURITY.USERS.USER_DATA_VALIDATION.USERNAME_IS_REQUIRED'),
          this.translationService.translate('SECURITY.USERS.USER_DATA_VALIDATION.USERNAME_LENGTH_ERROR'),
        ];
      } else if (this.credentialsForm.get('oldPassword').invalid) {
        errorMessage.title = this.translationService.translate(
          'SECURITY.USERS.USER_DATA_VALIDATION.OLD_PASSWORD_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate('SECURITY.USERS.USER_DATA_VALIDATION.OLD_PASSWORD_IS_REQUIRED'),
        ];
      } else if (this.credentialsForm.get('newPassword').invalid) {
        errorMessage.title = this.translationService.translate(
          'SECURITY.USERS.USER_DATA_VALIDATION.NEW_PASSWORD_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate('SECURITY.USERS.USER_DATA_VALIDATION.NEW_PASSWORD_IS_REQUIRED'),
          this.translationService.translate('SECURITY.USERS.USER_DATA_VALIDATION.NEW_PASSWORD_LENGTH_ERROR'),
        ];
      }

      this.credentialsForm.markAllAsTouched();
      return this.notificationService.warningWithTitle(errorMessage);
    } else {
      this.openModal(this.credentialsConfirmModalRef);
    }
  }

  /**
   * Confirms the the credentials form submit.
   */
  confirmCredentials() {
    this.authStore$.dispatch(new fromAuthStore.UpdateUserProfileCredentials(this.credentialsForm.value));
  }

  /**
   * Submits the api key.
   */
  submitApiKey() {
    this.openModal(this.apiKeyConfirmModalRef);
  }

  /**
   * Confirms the the api key.
   */
  confirmApiKey() {
    this.authStore$.dispatch(new fromAuthStore.UpdateApiKey());
  }

  /**
   * Opens the modal of the given templateRef.
   * @param modalRef The modal templateRef to be opened.
   */
  openModal(modalRef) {
    this.modalService.open(modalRef);
  }
}
