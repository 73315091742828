import { TaxesService } from 'settings/services/taxes.service';
import { OrganizationSettingsService } from 'settings/services/organization-settings.service';

export * from 'settings/services/taxes.service';
export * from 'settings/services/organization-settings.service';

/**
 * The set of services registered on this module.
 */
export const SERVICES: any[] = [TaxesService, OrganizationSettingsService];
