import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromCoreStore from 'core/store';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss'],
})
export class NotFoundComponent implements OnInit {
  /**
   * @param coreStore$ The core-Module store.
   */
  constructor(private coreStore$: Store<fromCoreStore.AppState>) {}

  ngOnInit() {
    this.init();
  }

  /** Initialize component data. */
  init() {
    /* Set the current page info {title, icon} */
    this.coreStore$.dispatch(new fromCoreStore.ChangePageInfo({ title: '404' }));
  }
}
