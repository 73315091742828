import { Injectable } from '@angular/core';

import * as XLSX from 'xlsx';

/**
 * The export service that includes functionality to export any `HTMLElement` as Excel file.
 */
@Injectable()
export class ExportToolsService {
  constructor() {}

  /**
   * Exports the given `HTMLElement` as Excel file.
   * @param element The `HTMLElement` to be exported as Excel.
   * @param outputFileName The name of the exported file.
   */
  exportToExcel(element: HTMLElement, outputFileName: string) {
    const workSheet: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);
    const workBook: XLSX.WorkBook = XLSX.utils.book_new();
    if (!workBook.Workbook) {
      workBook.Workbook = {};
    }
    if (!workBook.Workbook.Views) {
      workBook.Workbook.Views = [];
    }
    if (!workBook.Workbook.Views[0]) {
      workBook.Workbook.Views[0] = {};
    }
    workBook.Workbook.Views[0].RTL = true;
    const workSheetColumns = [];
    const workSheetRange = XLSX.utils.decode_range(workSheet['!ref']);
    const columnsNumber = workSheetRange.e.c;
    for (let i = 0; i < columnsNumber; i++) {
      workSheetColumns.push({ width: 15 });
    }
    workSheet['!cols'] = workSheetColumns;
    XLSX.utils.book_append_sheet(workBook, workSheet, 'Sheet1');
    XLSX.writeFile(workBook, `${outputFileName}.xlsx`);
  }
}
