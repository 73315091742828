import { Component, Input } from '@angular/core';

import { PayrollPayment } from 'hr/models';

@Component({
  selector: 'app-journal-payroll-payment-details',
  templateUrl: './journal-payroll-payment-details.component.html',
  styles: [],
})
export class JournalPayrollPaymentDetailsComponent {
  /**
   * Sets the current viewed journal payroll payment.
   */
  @Input() payrollPayment: PayrollPayment;
}
