import { Action } from '@ngrx/store';

import { AppHttpResponse } from 'shared';
import { ProjectSubTask } from 'projects-management/models';

/**
 * The AppSearch-module store sub tasks action types.
 */
export enum SubTasksActionType {
  SEARCH_SUB_TASKS = '[AppSearch] [SubTasks] Search Sub Task',
  SEARCH_SUB_TASKS_FAIL = '[AppSearch] [SubTasks] Search Sub Task Fail',
  SEARCH_SUB_TASKS_SUCCESS = '[AppSearch] [SubTasks] Search Sub Task Success',
}

/**
 * Represents a store sub tasks search action.
 */
export class SearchSubTasks implements Action {
  /**
   * The type of the action.
   */
  readonly type = SubTasksActionType.SEARCH_SUB_TASKS;

  /**
   * Fires a new sub tasks search action.
   * @param payload the search parameters,
   * if omitted, all sub tasks will be loaded.
   */
  constructor(public payload?: { description: string; projects: number[]; page: number }) {}
}

/**
 * Represents a store sub tasks search fail action.
 */
export class SearchSubTasksFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = SubTasksActionType.SEARCH_SUB_TASKS_FAIL;

  /**
   * Fires a new sub tasks search fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store search success action.
 */
export class SearchSubTasksSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = SubTasksActionType.SEARCH_SUB_TASKS_SUCCESS;

  /**
   * Fires a new sub tasks search success action.
   * @param payload An object contains the list of sub tasks that matches the search criteria
   * in addition to the pagination info.
   */
  constructor(public payload: AppHttpResponse<ProjectSubTask[]>) {}
}

/**
 * hr-module sub tasks action types.
 */
export type SubTasksActions = SearchSubTasks | SearchSubTasksFail | SearchSubTasksSuccess;
