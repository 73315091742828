import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';

import { Observable, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

import * as fromAppSearchStore from 'app-search/store';
import * as fromAuthStore from 'auth/store';
import { NotificationService, PaginationInfo, TranslationService } from 'shared';
import { PosDevice } from 'sales/models';

/**
 * pos device search form wrapped in modal.
 */
@Component({
  selector: 'pos-devices-search',
  templateUrl: './pos-devices-search.component.html',
  styles: [],
})
export class PosDevicesSearchComponent implements OnInit {
  /**
   * The modal template reference.
   */
  @ViewChild('modalRef') modalRef: ElementRef<any>;

  /**
   * Indicates whether the modal will automatically close after select the first pos device.
   *
   * Be default it will not be closed.
   */
  @Input() closeOnSelect = false;

  /**
   * Indicates whether the pos device displayed on the list should be all active.
   *
   * Be default it will not be closed.
   */
  @Input() onlyActive = false;

  /**
   * Sets the visibility of this component modal.
   *
   * Handles visibility change in two-data-binding approach.
   */
  @Input() set visible(isShown: boolean) {
    if (isShown && this.modalRef) {
      this.openModal(this.modalRef);
    } else {
      this.closeModal();
    }

    this.visibleChange.emit(isShown);
  }

  /**
   * Outputs a value that indicates whether the modal is shown or not.
   *
   * Handles visibility change in two-data-binding approach.
   */
  @Output() visibleChange = new EventEmitter<boolean>();

  /** Outputs the selected pos device. */
  @Output() posDevice = new EventEmitter<PosDevice[]>();

  /**
   * The list of pos devices.
   */
  posDevices$: Observable<PosDevice[]>;

  /**
   * The pagination info.
   */
  paginationInfo$: Observable<PaginationInfo>;

  /**
   * Indicates whether there is a search process is running or not.
   */
  isSearching$: Observable<boolean>;

  /**
   * The search form.
   */
  searchForm: FormGroup;

  /**
   * The ngb-modal reference to use it when modal needed to be closed.
   */
  modal: NgbModalRef;

  /**
   * The set of subscriptions on this components,
   * these subscriptions must be unsubscribed before this component got destroyed.
   */
  subscriptions = new Subscription();

  /**
   * @param modalService The modal service.
   * @param notificationService The notification service.
   * @param translationService The translation service.
   * @param appSearchStore$ The app-search-store module.
   * @param authStore$ The auth-store.
   */
  constructor(
    private modalService: NgbModal,
    private notificationService: NotificationService,
    private translationService: TranslationService,
    private appSearchStore$: Store<fromAppSearchStore.AppSearchState>,
    private authStore$: Store<fromAuthStore.AuthState>
  ) {}

  ngOnInit(): void {
    this.init();
  }

  /**
   * Destroy component data
   */
  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  /**
   * Initialize component data.
   */
  init() {
    this.initForm();
    this.applyFiltersAndSearch();

    /**
     * Load data.
     */
    this.isSearching$ = this.appSearchStore$.pipe(select(fromAppSearchStore.getPosDevicesSearching));

    this.posDevices$ = this.appSearchStore$.pipe(select(fromAppSearchStore.getPosDevices));

    this.paginationInfo$ = this.appSearchStore$.pipe(select(fromAppSearchStore.getPosDevicesPaginationInfo));
  }

  /**
   * Initialize form and add validators.
   */
  initForm() {
    this.searchForm = new FormGroup({
      name: new FormControl(''),
      locations: new FormControl([]),
      page: new FormControl(1),
    });
  }

  /**
   * Handles search parameters change.
   */
  search(event?: KeyboardEvent) {
    if (event && event.key === 'Enter') {
      event.preventDefault();
      return;
    }

    this.applyFiltersAndSearch();
  }

  /**
   * Handles pagination page-changed event.
   * @param page The current selected page number.
   */
  pageChanged(page: number) {
    /** Update pagination page. */
    this.searchForm.patchValue({ page });

    /** Get selected page items. */
    this.applyFiltersAndSearch(page);
  }

  /**
   * Applies the search filters and dispatches a search action.
   * @param page The current page number, default to `1`.
   */
  applyFiltersAndSearch(page: number = 1) {
    this.appSearchStore$.dispatch(
      new fromAppSearchStore.SearchPosDevices({
        name: this.searchForm.get('name').value,
        locations: [],
        page: this.searchForm.get('page').value,
      })
    );
  }

  /**
   * Enables trackBy feature for *ngFor utility to track items
   * depending on a comparer to reduce DOM change.
   * If the item is already exist then no HTML will change, only bounded values.
   * @param index The index of the item.
   * @param posDevice The item to determine if it was changed or not.
   */
  trackItems(index: number, posDevice: PosDevice) {
    return posDevice ? posDevice.id : undefined;
  }

  /**
   * Opens the modal of the given templateRef.
   * @param modalRef The modal templateRef to be opened.
   */
  openModal(modalRef) {
    /**
     * Open the modal & listen for user press ESC key.
     */
    this.modal = this.modalService.open(modalRef, { size: 'lg' });
    this.modal.result.then(null, () => this.closeModal());
  }

  /**
   * Closes the currently opened modal.
   */
  closeModal() {
    this.modal?.dismiss();
    this.posDevice.emit([]);
    this.visibleChange.emit(false);
  }

  /**
   * Handles pos device selected event.
   * @param pos devices The list of pos devices to notify selected.
   */
  selectPosDevice(...posDevice: PosDevice[]) {
    this.notificationService.successInstant(
      this.translationService.translate('SALES.POS_DEVICES.NOTIFICATION_MESSAGE.POS_DEVICE_SELECTED')
    );

    this.posDevice.emit(posDevice);
    if (this.closeOnSelect) {
      this.closeModal();
    }
  }
  /**
   * Sets default pos device for current logged-in user.
   */
  selectDefaultPosDevice(userPosDeviceId: number) {
    this.authStore$.dispatch(new fromAuthStore.UpdateUserDefaultPosDevice(userPosDeviceId));

    this.subscriptions.add(
      this.authStore$
        .pipe(
          select(fromAuthStore.getSessionUserDefaultPosDeviceUpdateCompleted),
          tap((complete) => {
            if (complete === true) {
              this.closeModal();
            }
          })
        )
        .subscribe()
    );
  }
}
