import { CreateProjectComponent } from 'projects-management/components/projects/create-project/create-project.component';
import { ProjectDetailsComponent } from 'projects-management/components/projects/project-details/project-details.component';
import { ProjectPlanningResourcesComponent } from 'projects-management/components/projects/project-planning-resources/project-planning-resources.component';
import { ProjectsListComponent } from 'projects-management/components/projects/projects-list/projects-list.component';
import { ProjectsSearchComponent } from 'projects-management/components/projects/projects-search/projects-search.component';
import { ProjectResourcesComponent } from 'projects-management/components/projects/project-resources/project-resources.component';
import { ProjectInfoComponent } from 'projects-management/components/projects/project-info/project-info.component';
import { UpdateProjectPlanningResourcesComponent } from 'projects-management/components/projects/update-project-planning-resources/update-project-planning-resources.component';
import { ProjectStatusLogComponent } from 'projects-management/components/projects/project-status-log/project-status-log.component';
import { ProjectPlansListComponent } from 'projects-management/components/projects/project-plans-list/project-plans-list.component';
import { ProjectMainTaskComponent } from 'projects-management/components/projects/project-main-task/project-main-task.component';
import { ProjectSubTaskComponent } from 'projects-management/components/projects/project-sub-task/project-sub-task.component';
import { ProjectPlanDetailsComponent } from 'projects-management/components/projects/project-plan-details/project-plan-details.component';
import { ProjectLogsComponent } from 'projects-management/components/projects/project-logs/project-logs.component';
import { ProjectPlanComponent } from 'projects-management/components/projects/project-plan/project-plan.component';
import { ProjectMainTasksSearchComponent } from 'projects-management/components/projects/project-main-tasks-search/project-main-tasks-search.component';
import { ProjectSubTasksSearchComponent } from 'projects-management/components/projects/project-sub-tasks-search/project-sub-tasks-search.component';
import { ProjectResourcePayrollsComponent } from 'projects-management/components/projects/project-resource-payrolls/project-resource-payrolls.component';
import { ViewProjectResourceComponent } from 'projects-management/components/projects/view-project-resource/view-project-resource.component';
import { ProjectRevenuesComponent } from 'projects-management/components/projects/project-revenues/project-revenues.component';
import { ProjectRevenueInvoiceDetailsComponent } from 'projects-management/components/projects/project-revenue-invoice-details/project-revenue-invoice-details.component';
import { RevenueDetailsComponent } from 'projects-management/components/projects/revenue-details/revenue-details.component';
import { ProjectRevenueSaleReturnDetailsComponent } from 'projects-management/components/projects/project-revenue-sale-return-details/project-revenue-sale-return-details.component';
import { ProjectResourceOutgoingStockDetailsComponent } from 'projects-management/components/projects/project-resource-outgoing-stock-details/project-resource-outgoing-stock-details.component';
import { ProjectResourceAssetDepreciationDetailsComponent } from 'projects-management/components/projects/project-resource-asset-depreciation-details/project-resource-asset-depreciation-details.component';
import { ProjectResourcePurchaseReturnDetailsComponent } from 'projects-management/components/projects/project-resource-purchase-return-details/project-resource-purchase-return-details.component';
import { ProjectResourcePurchaseInvoiceDetailsComponent } from 'projects-management/components/projects/project-resource-purchase-invoice-details/project-resource-purchase-invoice-details.component';

export * from 'projects-management/components/projects/create-project/create-project.component';
export * from 'projects-management/components/projects/project-details/project-details.component';
export * from 'projects-management/components/projects/project-planning-resources/project-planning-resources.component';
export * from 'projects-management/components/projects/projects-list/projects-list.component';
export * from 'projects-management/components/projects/projects-search/projects-search.component';
export * from 'projects-management/components/projects/project-resources/project-resources.component';
export * from 'projects-management/components/projects/project-info/project-info.component';
export * from 'projects-management/components/projects/update-project-planning-resources/update-project-planning-resources.component';
export * from 'projects-management/components/projects/project-status-log/project-status-log.component';
export * from 'projects-management/components/projects/project-plans-list/project-plans-list.component';
export * from 'projects-management/components/projects/project-main-task/project-main-task.component';
export * from 'projects-management/components/projects/project-sub-task/project-sub-task.component';
export * from 'projects-management/components/projects/project-plan-details/project-plan-details.component';
export * from 'projects-management/components/projects/project-logs/project-logs.component';
export * from 'projects-management/components/projects/project-plan/project-plan.component';
export * from 'projects-management/components/projects/project-main-tasks-search/project-main-tasks-search.component';
export * from 'projects-management/components/projects/project-sub-tasks-search/project-sub-tasks-search.component';
export * from 'projects-management/components/projects/project-resource-payrolls/project-resource-payrolls.component';
export * from 'projects-management/components/projects/view-project-resource/view-project-resource.component';
export * from 'projects-management/components/projects/project-revenues/project-revenues.component';
export * from 'projects-management/components/projects/project-revenue-invoice-details/project-revenue-invoice-details.component';
export * from 'projects-management/components/projects/revenue-details/revenue-details.component';
export * from 'projects-management/components/projects/project-revenue-sale-return-details/project-revenue-sale-return-details.component';
export * from 'projects-management/components/projects/project-resource-outgoing-stock-details/project-resource-outgoing-stock-details.component';
export * from 'projects-management/components/projects/project-resource-asset-depreciation-details/project-resource-asset-depreciation-details.component';
export * from 'projects-management/components/projects/project-resource-purchase-return-details/project-resource-purchase-return-details.component';
export * from 'projects-management/components/projects/project-resource-purchase-invoice-details/project-resource-purchase-invoice-details.component';

/**
 * The set of components registered on this module.
 */
export const COMPONENTS: any[] = [
  CreateProjectComponent,
  ProjectDetailsComponent,
  ProjectPlanningResourcesComponent,
  UpdateProjectPlanningResourcesComponent,
  ProjectsListComponent,
  ProjectsSearchComponent,
  ProjectResourcesComponent,
  ProjectInfoComponent,
  ProjectStatusLogComponent,
  ProjectPlansListComponent,
  ProjectMainTaskComponent,
  ProjectSubTaskComponent,
  ProjectPlanDetailsComponent,
  ProjectLogsComponent,
  ProjectPlanComponent,
  ProjectMainTasksSearchComponent,
  ProjectSubTasksSearchComponent,
  ProjectResourcePayrollsComponent,
  ViewProjectResourceComponent,
  ProjectRevenuesComponent,
  ProjectRevenueInvoiceDetailsComponent,
  RevenueDetailsComponent,
  ProjectRevenueSaleReturnDetailsComponent,
  ProjectResourceOutgoingStockDetailsComponent,
  ProjectResourceAssetDepreciationDetailsComponent,
  ProjectResourcePurchaseReturnDetailsComponent,
  ProjectResourcePurchaseInvoiceDetailsComponent,
];
