import { createSelector } from '@ngrx/store';

import { LocationsState, AppSearchState } from 'app-search/store/states';
import { selectAppSearchState } from 'app-search/store/selectors/feature.selectors';

/**
 * Gets the locations state.
 */
const selectLocationState = createSelector(selectAppSearchState, (state: AppSearchState) => state.locations);

/**
 * Gets the list of loaded locations.
 */
export const getLocations = createSelector(selectLocationState, (state: LocationsState) => state.data);

/**
 * Gets the locations pagination info.
 */
export const getLocationsPaginationInfo = createSelector(
  selectLocationState,
  (state: LocationsState) => state.paginationInfo
);

/**
 * Gets the error that occurred in the state.
 */
export const getLocationsError = createSelector(selectLocationState, (state: LocationsState) => state.error);

/**
 * Determines if there is a running search process.
 */
export const getLocationsSearching = createSelector(selectLocationState, (state: LocationsState) => state.isSearching);

/**
 * Determines if the last search process has been ended successfully.
 */
export const getLocationsSearchCompleted = createSelector(
  selectLocationState,
  (state: LocationsState) => state.isSearchCompleted
);
