import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from 'shared';
import { SecurityRoutingModule } from 'security/security-routing.module';
import { OrganizationsModule } from 'organizations';

import * as fromContainers from 'security/containers';
import * as fromComponents from 'security/components';
import * as fromServices from 'security/services';

/* Ngrx */
import * as fromStore from 'security/store';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoresModule } from 'stores';
import { FinancesModule } from 'finances';

@NgModule({
  declarations: [...fromContainers.CONTAINERS, ...fromComponents.COMPONENTS],
  imports: [
    CommonModule,
    SharedModule,
    SecurityRoutingModule,
    StoresModule,
    FinancesModule,
    OrganizationsModule,
    StoreModule.forFeature('security', fromStore.reducers),
    EffectsModule.forFeature(fromStore.effects),
  ],
  exports: [fromComponents.OrganizationUsersSearchComponent],
  providers: [...fromServices.SERVICES],
})
export class SecurityModule {}
