import { Action } from '@ngrx/store';

import { AppHttpResponse } from 'shared';
import { Currency, CreateCurrencyInput, UpdateCurrencyInput } from 'finances/models';

/**
 * The finances-module store currencies action types.
 */
export enum CurrenciesActionType {
  SEARCH_CURRENCIES = '[Finances] [Currencies] Search Currency',
  SEARCH_CURRENCIES_FAIL = '[Finances] [Currencies] Search Currency Fail',
  SEARCH_CURRENCIES_SUCCESS = '[Finances] [Currencies] Search Currency Success',

  FIND_CURRENCY = '[Finances] [Currencies] Find Currency',
  FIND_CURRENCY_FAIL = '[Finances] [Currencies] Find Currency Fail',
  FIND_CURRENCY_SUCCESS = '[Finances] [Currencies] Find Currency Success',

  FIND_PRIMARY_CURRENCY = '[Finances] [Currencies] Find Primary Currency',
  FIND_PRIMARY_CURRENCY_FAIL = '[Finances] [Currencies] Find Primary Currency Fail',
  FIND_PRIMARY_CURRENCY_SUCCESS = '[Finances] [Currencies] Find Primary Currency Success',

  CREATE_CURRENCY = '[Finances] [Currencies] Create Currency',
  CREATE_CURRENCY_FAIL = '[Finances] [Currencies] Create Currency Fail',
  CREATE_CURRENCY_SUCCESS = '[Finances] [Currencies] Create Currency Success',

  UPDATE_CURRENCY = '[Finances] [Currencies] Update Currency',
  UPDATE_CURRENCY_FAIL = '[Finances] [Currencies] Update Currency Fail',
  UPDATE_CURRENCY_SUCCESS = '[Finances] [Currencies] Update Currency Success',

  DELETE_CURRENCY = '[Finances] [Currencies] Delete Currency',
  DELETE_CURRENCY_FAIL = '[Finances] [Currencies] Delete Currency Fail',
  DELETE_CURRENCY_SUCCESS = '[Finances] [Currencies] Delete Currency Success',
}

/**
 * Represents a store currencies search action.
 */
export class SearchCurrencies implements Action {
  /**
   * The type of the action.
   */
  readonly type = CurrenciesActionType.SEARCH_CURRENCIES;

  /**
   * Fires a new currencies search action.
   * @param payload the search parameters,
   * if omitted, all currencies will be loaded.
   */
  constructor(public payload?: { name: string; page: number }) {}
}

/**
 * Represents a store currencies search fail action.
 */
export class SearchCurrenciesFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = CurrenciesActionType.SEARCH_CURRENCIES_FAIL;

  /**
   * Fires a new currencies search fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store search success action.
 */
export class SearchCurrenciesSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = CurrenciesActionType.SEARCH_CURRENCIES_SUCCESS;

  /**
   * Fires a new currencies search success action.
   * @param payload An object contains the list of currencies that matches the search criteria
   * in addition to the pagination info.
   */
  constructor(public payload: AppHttpResponse<Currency[]>) {}
}

/**
 * Represents a store find currency action.
 */
export class FindCurrency implements Action {
  /**
   * The type of the action.
   */
  readonly type = CurrenciesActionType.FIND_CURRENCY;

  /**
   * Fires a new find currency action.
   * @param payload the id of the currency.
   */
  constructor(public payload: number) {}
}

/**
 * Represents a store find currency fail action.
 */
export class FindCurrencyFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = CurrenciesActionType.FIND_CURRENCY_FAIL;

  /**
   * Fires a new find currency fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store find currency success action.
 */
export class FindCurrencySuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = CurrenciesActionType.FIND_CURRENCY_SUCCESS;

  /**
   * Fires a new find currency success action.
   * @param payload The loaded currency.
   */
  constructor(public payload: AppHttpResponse<Currency>) {}
}

/**
 * Represents a store find primary currency action.
 */
export class FindPrimaryCurrency implements Action {
  /**
   * The type of the action.
   */
  readonly type = CurrenciesActionType.FIND_PRIMARY_CURRENCY;

  /**
   * Fires a new find primary currency action.
   */
  constructor() {}
}

/**
 * Represents a store find primary currency fail action.
 */
export class FindPrimaryCurrencyFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = CurrenciesActionType.FIND_PRIMARY_CURRENCY_FAIL;

  /**
   * Fires a new find primary currency fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store find primary currency success action.
 */
export class FindPrimaryCurrencySuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = CurrenciesActionType.FIND_PRIMARY_CURRENCY_SUCCESS;

  /**
   * Fires a new find primary currency success action.
   * @param payload The loaded primary currency.
   */
  constructor(public payload: AppHttpResponse<Currency>) {}
}

/**
 * Represents a store currency create action.
 */
export class CreateCurrency implements Action {
  /** The type of the action. */
  readonly type = CurrenciesActionType.CREATE_CURRENCY;

  /**
   * Fires a new currency create action.
   * @param payload the new currency data-model.
   */
  constructor(public payload: CreateCurrencyInput) {}
}

/**
 * Represents a store currency create fail action.
 */
export class CreateCurrencyFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = CurrenciesActionType.CREATE_CURRENCY_FAIL;

  /**
   * Fires a new currency create fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store currency create success action.
 */
export class CreateCurrencySuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = CurrenciesActionType.CREATE_CURRENCY_SUCCESS;

  /**
   * Fires a new currency create success action.
   * @param payload The created currency.
   */
  constructor(public payload: AppHttpResponse<Currency>) {}
}

/**
 * Represents a store currency update action.
 */
export class UpdateCurrency implements Action {
  /**
   * The type of the action.
   */
  readonly type = CurrenciesActionType.UPDATE_CURRENCY;

  /**
   * Fires a new currency update action.
   * @param payload The currency's updated data.
   */
  constructor(public payload: UpdateCurrencyInput) {}
}

/**
 * Represents a store currency update fail action.
 */
export class UpdateCurrencyFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = CurrenciesActionType.UPDATE_CURRENCY_FAIL;

  /**
   * Fires a new currency update fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store currency update success action.
 */
export class UpdateCurrencySuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = CurrenciesActionType.UPDATE_CURRENCY_SUCCESS;

  /**
   * Fires a new currency update-success action.
   * @param payload The updated currency.
   */
  constructor(public payload: AppHttpResponse<Currency>) {}
}

/**
 * Represents a store currency delete action.
 */
export class DeleteCurrency implements Action {
  /**
   * The type of the action.
   */
  readonly type = CurrenciesActionType.DELETE_CURRENCY;

  /**
   * Fires a new currency delete action.
   * @param payload The currency's id.
   */
  constructor(public payload: number) {}
}

/**
 * Represents a store currencies delete fail action.
 */
export class DeleteCurrencyFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = CurrenciesActionType.DELETE_CURRENCY_FAIL;

  /**
   * Fires a new currency delete fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store currency delete success action.
 */
export class DeleteCurrencySuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = CurrenciesActionType.DELETE_CURRENCY_SUCCESS;

  /**
   * Fires a new currency delete success action.
   * @param payload The deleted currency.
   */
  constructor(public payload: AppHttpResponse<Currency>) {}
}

/**
 * finances-module currencies action types.
 */
export type CurrenciesActions =
  | SearchCurrencies
  | SearchCurrenciesFail
  | SearchCurrenciesSuccess
  | FindCurrency
  | FindCurrencyFail
  | FindCurrencySuccess
  | FindPrimaryCurrency
  | FindPrimaryCurrencyFail
  | FindPrimaryCurrencySuccess
  | CreateCurrency
  | CreateCurrencyFail
  | CreateCurrencySuccess
  | UpdateCurrency
  | UpdateCurrencyFail
  | UpdateCurrencySuccess
  | DeleteCurrency
  | DeleteCurrencyFail
  | DeleteCurrencySuccess;
