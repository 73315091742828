import { LoginComponent } from 'auth/components/login/login.component';
import { UserPreferencesComponent } from 'auth/components/user-preferences/user-preferences.component';
import { UserCostCentersListComponent } from 'auth/components/user-cost-centers-list/user-cost-centers-list.component';
import { UserLocationsListComponent } from 'auth/components/user-locations-list/user-locations-list.component';
import { UserCashAccountsListComponent } from 'auth/components/user-cash-accounts-list/user-cash-accounts-list.component';
import { UserElectronicAccountsListComponent } from 'auth/components/user-electronic-accounts-list/user-electronic-accounts-list.component';
import { UserPosDevicesListComponent } from 'auth/components/user-pos-devices-list/user-pos-devices-list.component';

export * from 'auth/components/login/login.component';
export * from 'auth/components/user-preferences/user-preferences.component';
export * from 'auth/components/user-cost-centers-list/user-cost-centers-list.component';
export * from 'auth/components/user-locations-list/user-locations-list.component';
export * from 'auth/components/user-cash-accounts-list/user-cash-accounts-list.component';
export * from 'auth/components/user-electronic-accounts-list/user-electronic-accounts-list.component';
export * from 'auth/components/user-pos-devices-list/user-pos-devices-list.component';

/**
 * The set of components registered on this module.
 */
export const COMPONENTS: any[] = [
  LoginComponent,
  UserPreferencesComponent,
  UserLocationsListComponent,
  UserCostCentersListComponent,
  UserCashAccountsListComponent,
  UserElectronicAccountsListComponent,
  UserPosDevicesListComponent,
];
