import { createSelector } from '@ngrx/store';

import { selectFinancesState } from 'finances/store/selectors/feature.selectors';
import { FinancesState, PettyCashRefundState } from 'finances/store/states';

/* Selectors */

/**
 * Gets the petty cash refund state.
 */
const selectPettyCashRefundState = createSelector(selectFinancesState, (state: FinancesState) => state.pettyCashRefund);

/**
 * Gets the list of loaded petty cash refund.
 */
export const getPettyCashRefund = createSelector(
  selectPettyCashRefundState,
  (state: PettyCashRefundState) => state.data
);

/**
 * Gets the petty cash refund pagination info.
 */
export const getPettyCashRefundPaginationInfo = createSelector(
  selectPettyCashRefundState,
  (state: PettyCashRefundState) => state.paginationInfo
);

/**
 * Gets the error that occurred in the state.
 */
export const getPettyCashRefundError = createSelector(
  selectPettyCashRefundState,
  (state: PettyCashRefundState) => state.error
);

/**
 * Determines if there is a running search process.
 */
export const getPettyCashRefundSearching = createSelector(
  selectPettyCashRefundState,
  (state: PettyCashRefundState) => state.isSearching
);

/**
 * Determines if the last search process has been ended successfully.
 */
export const getPettyCashRefundSearchCompleted = createSelector(
  selectPettyCashRefundState,
  (state: PettyCashRefundState) => state.isSearchCompleted
);

/**
 * Gets the selected petty cash refund.
 */
export const getSelectedPettyCashRefund = createSelector(
  selectPettyCashRefundState,
  (state: PettyCashRefundState) => state.selectedPettyCashRefund
);

/**
 * Determines if there is a running find-petty-cash-refund process.
 */
export const getPettyCashRefundFinding = createSelector(
  selectPettyCashRefundState,
  (state: PettyCashRefundState) => state.isFinding
);

/**
 * Determines if the last find-petty-cash-refund process has been ended successfully.
 */
export const getPettyCashRefundFindCompleted = createSelector(
  selectPettyCashRefundState,
  (state: PettyCashRefundState) => state.isFindCompleted
);

/**
 * Determines if there is a running create process.
 */
export const getSelectedPettyCashRefundCreating = createSelector(
  selectPettyCashRefundState,
  (state: PettyCashRefundState) => state.isCreating
);

/**
 * Determines if the last create process has been ended successfully.
 */
export const getSelectedPettyCashRefundCreateCompleted = createSelector(
  selectPettyCashRefundState,
  (state: PettyCashRefundState) => state.isCreateCompleted
);
