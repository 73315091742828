import { createSelector } from '@ngrx/store';

import { selectLookupsState } from 'lookups/store/selectors/feature.selectors';
import { LookupsState, NoticePeriodState } from 'lookups/store/states';

/**
 * Gets the notice period state.
 */
const selectNoticePeriods = createSelector(selectLookupsState, (state: LookupsState) => state.noticePeriods);

/**
 * Gets the list of loaded notice periods.
 */
export const getNoticePeriods = createSelector(selectNoticePeriods, (state: NoticePeriodState) => state.data);

/**
 * Gets the error that occurred in the state.
 */
export const getNoticePeriodsError = createSelector(selectNoticePeriods, (state: NoticePeriodState) => state.error);

/**
 * Determines if there is a running load process.
 */
export const getNoticePeriodsIsLoading = createSelector(
  selectNoticePeriods,
  (state: NoticePeriodState) => state.isLoading
);

/**
 * Determines if the last load process has been ended successfully.
 */
export const getNoticePeriodsIsLoaded = createSelector(
  selectNoticePeriods,
  (state: NoticePeriodState) => state.isLoaded
);
