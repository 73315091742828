import { Pipe, PipeTransform } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';

import * as fromAuthStore from 'auth/store';

/**
 * Localizes the given set of values depending on the current logged-in user language.
 * In the following order (Arabic value, English value as `args`)
 */
@Pipe({
  name: 'localize',
})
export class LocalizePipe implements PipeTransform {
  /**
   * @param authStore$ The auth-Store module.
   */
  constructor(private authStore$: Store<fromAuthStore.AuthState>) {}

  transform(value: any, args?: any): Observable<any> {
    return this.authStore$.pipe(
      select(fromAuthStore.getSessionUserLanguage),
      map((language) => (language.key === 'ar' ? value : args))
    );
  }
}
