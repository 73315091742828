import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';

import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { PageInfo, PaginationInfo } from 'shared';
import * as fromOrganizationsStore from 'organizations/store';
import { Organization } from 'organizations/models';
import { Claims } from 'security/models';

@Component({
  selector: 'app-organizations-list',
  templateUrl: './organizations-list.component.html',
  styles: [],
})
export class OrganizationsListComponent implements OnInit {
  /**
   * Gets or sets the information about the current page.
   */
  pageInfo: PageInfo = {
    title: 'ORGANIZATIONS.ORGANIZATIONS',
    icon: 'fa fa-institution',
  };

  /**
   * The system supported user claims.
   */
  Claims = Claims;

  /**
   * The list of organizations.
   */
  organizations$: Observable<Organization[]>;

  /**
   * The pagination info.
   */
  paginationInfo$: Observable<PaginationInfo>;

  /**
   * Indicates whether there is a block-organization process is running or not.
   */
  isBlocking$: Observable<boolean>;

  /**
   * Indicates whether there is a activate-organization process is running or not.
   */
  isActivating$: Observable<boolean>;

  /**
   * Indicates whether there is a delete-organization process is running or not.
   */
  isDeleting$: Observable<boolean>;

  /**
   * The search form.
   */
  searchForm: FormGroup;

  /**
   * Gets or sets the currently selected organization for block, activate and delete.
   */
  selectedOrganization: Organization;

  /**
   * @param modalService The modal service.
   * @param organizationsStore$ The organizations store.
   */
  constructor(
    private modalService: NgbModal,
    private organizationsStore$: Store<fromOrganizationsStore.OrganizationsState>
  ) {}

  ngOnInit(): void {
    this.init();
  }

  /**
   * Initialize component data.
   */
  init() {
    this.initForm();

    /**
     * Loads data.
     */
    this.isActivating$ = this.organizationsStore$.pipe(
      select(fromOrganizationsStore.getSelectedOrganizationActivating)
    );
    this.isBlocking$ = this.organizationsStore$.pipe(select(fromOrganizationsStore.getSelectedOrganizationBlocking));
    this.isDeleting$ = this.organizationsStore$.pipe(select(fromOrganizationsStore.getSelectedOrganizationDeleting));

    let isManualSearchTriggeredBeforeForOrganizations = false;
    this.organizations$ = this.organizationsStore$.pipe(
      select(fromOrganizationsStore.getOrganizations),
      tap((organizations) => {
        if (!isManualSearchTriggeredBeforeForOrganizations && !organizations.length) {
          isManualSearchTriggeredBeforeForOrganizations = true;
          this.search();
        }
      })
    );

    this.paginationInfo$ = this.organizationsStore$.pipe(select(fromOrganizationsStore.getOrganizationsPaginationInfo));
  }

  /**
   * Initialize form and add validators.
   */
  initForm() {
    this.searchForm = new FormGroup({
      name: new FormControl(''),
      page: new FormControl(1),
    });
  }

  /**
   * Handles search parameters change.
   */
  search(event?: KeyboardEvent) {
    if (event && event.key === 'Enter') {
      event.preventDefault();
      return;
    }

    this.organizationsStore$.dispatch(
      new fromOrganizationsStore.SearchOrganizations({
        name: this.searchForm.get('name').value,
        page: 1,
      })
    );
  }

  /**
   * Handles pagination page-changed event.
   * @param page The current selected page number.
   */
  pageChanged(page: number) {
    /** Update pagination page. */
    this.searchForm.patchValue({ page });

    /** Get selected page items. */
    this.organizationsStore$.dispatch(
      new fromOrganizationsStore.SearchOrganizations({
        name: this.searchForm.get('name').value,
        page: this.searchForm.get('page').value,
      })
    );
  }

  /**
   * Enables trackBy feature for *ngFor utility to track items
   * depending on a comparer to reduce DOM change.
   * If the item is already exist then no HTML will change, only bounded values.
   * @param index The index of the item.
   * @param organization The item to determine if it was changed or not.
   */
  trackItems(index: number, organization: Organization) {
    return organization ? organization.id : undefined;
  }

  /**
   * Blocks the organization with the given id.
   * @param id The id of the organization to be blocked.
   */
  block(id: number) {
    this.organizationsStore$.dispatch(new fromOrganizationsStore.BlockOrganization(id));
  }

  /**
   * Activates the organization with the given id.
   * @param id The id of the organization to be activated.
   */
  activate(id: number) {
    this.organizationsStore$.dispatch(new fromOrganizationsStore.ActivateOrganization(id));
  }

  /**
   * Deletes the organization with the given id.
   * @param id The id of the organization to be deleted.
   */
  delete(id: number) {
    this.organizationsStore$.dispatch(new fromOrganizationsStore.DeleteOrganization(id));
  }

  /**
   * Opens the modal of the given templateRef.
   * @param modalRef The modal templateRef to be opened.
   */
  openModal(modalRef) {
    this.modalService.open(modalRef);
  }
}
