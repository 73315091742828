import { findIndex, remove } from 'lodash';

import { LocationsActionType, LocationsActions } from 'stores/store/actions';
import { LocationState } from 'stores/store/states';

/**
 * The initial state from which the state starts.
 */
const initialState: LocationState = {
  data: [],
  organizationLocationsData: [],
  paginationInfo: {
    total: 0,
    page: 1,
    pageSize: 0,
    count: 0,
  },
  organizationLocationsPaginationInfo: {
    total: 0,
    page: 1,
    pageSize: 0,
    count: 0,
  },
  error: null,
  isSearching: false,
  isSearchCompleted: false,
  isOrganizationLocationsSearching: false,
  isOrganizationLocationsSearchCompleted: false,
  selectedLocation: null,
  isFinding: false,
  isFindCompleted: false,
  isCreating: false,
  isCreateCompleted: false,
  isUpdating: false,
  isUpdateCompleted: false,
  isDeleting: false,
  isDeleteCompleted: false,
};

/**
 * The reducer function that is called in each action dispatch against the state.
 * @param state The current state.
 * @param action The action that will affect the state.
 */
export function locationsReducer(state: LocationState = initialState, action: LocationsActions): LocationState {
  switch (action.type) {
    //#region SEARCH_LOCATIONS

    case LocationsActionType.SEARCH_LOCATIONS: {
      return {
        ...state,
        isSearching: true,
        isSearchCompleted: false,
        error: null,
      };
    }

    case LocationsActionType.SEARCH_LOCATIONS_FAIL: {
      return {
        ...state,
        error: action.payload,
        isSearching: false,
        isSearchCompleted: false,
      };
    }

    case LocationsActionType.SEARCH_LOCATIONS_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        paginationInfo: {
          ...action.payload.meta,
        },
        error: null,
        isSearching: false,
        isSearchCompleted: true,
      };
    }

    //#endregion SEARCH_LOCATIONS

    //#region SEARCH_ORGANIZATION_LOCATIONS

    case LocationsActionType.SEARCH_ORGANIZATION_LOCATIONS: {
      return {
        ...state,
        isOrganizationLocationsSearching: true,
        isOrganizationLocationsSearchCompleted: false,
        error: null,
      };
    }

    case LocationsActionType.SEARCH_ORGANIZATION_LOCATIONS_FAIL: {
      return {
        ...state,
        error: action.payload,
        isOrganizationLocationsSearching: false,
        isOrganizationLocationsSearchCompleted: false,
      };
    }

    case LocationsActionType.SEARCH_ORGANIZATION_LOCATIONS_SUCCESS: {
      return {
        ...state,
        organizationLocationsData: action.payload.data,
        organizationLocationsPaginationInfo: {
          ...action.payload.meta,
        },
        error: null,
        isOrganizationLocationsSearching: false,
        isOrganizationLocationsSearchCompleted: true,
      };
    }

    //#endregion SEARCH_ORGANIZATION_LOCATIONS

    //#region FIND_LOCATION

    case LocationsActionType.FIND_LOCATION: {
      return {
        ...state,
        selectedLocation: null,
        error: null,
        isFinding: true,
        isFindCompleted: false,
      };
    }

    case LocationsActionType.FIND_LOCATION_FAIL: {
      return {
        ...state,
        error: action.payload,
        isFinding: false,
        isFindCompleted: false,
      };
    }

    case LocationsActionType.FIND_LOCATION_SUCCESS: {
      let locations = [...state.data];
      const locationIndex = findIndex(locations, (location) => location.id === action.payload.data.id);

      /* If location was found, update it. */
      if (locationIndex >= 0) {
        locations[locationIndex] = action.payload.data;
      } else {
        /* else, insert it to the beginning of the array. */
        locations = [action.payload.data, ...locations];
      }

      return {
        ...state,
        data: locations,
        selectedLocation: action.payload.data,
        error: null,
        isFinding: false,
        isFindCompleted: true,
      };
    }

    //#endregion FIND_LOCATION

    //#region CREATE_LOCATION

    case LocationsActionType.CREATE_LOCATION: {
      return {
        ...state,
        isCreating: true,
        isCreateCompleted: false,
        error: null,
      };
    }

    case LocationsActionType.CREATE_LOCATION_FAIL: {
      return {
        ...state,
        error: action.payload,
        isCreating: false,
        isCreateCompleted: false,
      };
    }

    case LocationsActionType.CREATE_LOCATION_SUCCESS: {
      return {
        ...state,
        data: [action.payload.data, ...state.data],
        selectedLocation: action.payload.data,
        error: null,
        isCreating: false,
        isCreateCompleted: true,
      };
    }

    //#endregion CREATE_LOCATION

    //#region UPDATE_LOCATION

    case LocationsActionType.UPDATE_LOCATION:
    case LocationsActionType.UPDATE_PRIMARY_LOGO:
    case LocationsActionType.UPDATE_SECONDARY_LOGO:
    case LocationsActionType.DELETE_PRIMARY_LOGO:
    case LocationsActionType.DELETE_SECONDARY_LOGO:
    case LocationsActionType.UPDATE_IMAGE_HEADER:
    case LocationsActionType.DELETE_IMAGE_HEADER:
    case LocationsActionType.UPDATE_IMAGE_FOOTER:
    case LocationsActionType.DELETE_IMAGE_FOOTER: {
      return {
        ...state,
        isUpdating: true,
        isUpdateCompleted: false,
        error: null,
      };
    }

    case LocationsActionType.UPDATE_LOCATION_FAIL: {
      return {
        ...state,
        error: action.payload,
        isUpdating: false,
        isUpdateCompleted: false,
      };
    }

    case LocationsActionType.UPDATE_LOCATION_SUCCESS: {
      let locations = [...state.data];
      const locationIndex = findIndex(locations, (location) => location.id === action.payload.data.id);

      /* If location was found, update it. */
      if (locationIndex >= 0) {
        locations[locationIndex] = action.payload.data;
      } else {
        /* else, insert it to the beginning of the array. */
        locations = [action.payload.data, ...locations];
      }

      return {
        ...state,
        data: locations,
        selectedLocation: action.payload.data,
        error: null,
        isUpdating: false,
        isUpdateCompleted: true,
      };
    }

    //#endregion UPDATE_LOCATION

    //#region DELETE_LOCATION

    case LocationsActionType.DELETE_LOCATION: {
      return {
        ...state,
        isDeleting: true,
        isDeleteCompleted: false,
        error: null,
      };
    }

    case LocationsActionType.DELETE_LOCATION_FAIL: {
      return {
        ...state,
        error: action.payload,
        isDeleting: false,
        isDeleteCompleted: false,
      };
    }

    case LocationsActionType.DELETE_LOCATION_SUCCESS: {
      const locations = [...state.data];

      /* Remove the deleted location from the array. */
      remove(locations, (location) => location.id === action.payload.data.id);

      return {
        ...state,
        data: locations,
        selectedLocation: action.payload.data,
        error: null,
        isDeleting: false,
        isDeleteCompleted: true,
      };
    }

    //#endregion DELETE_LOCATION

    default:
      return state;
  }
}
