import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';

import { AppHttpResponse } from 'shared';
import { PettyCashReduction, CreatePettyCashReductionInput } from 'finances/models';

/**
 * The petty cash reduction services includes functionality to create, search and findById a petty cash reduction.
 */

@Injectable()
export class PettyCashReductionsService {
  /**
   * The relative route for the petty cash reductions.
   *
   * No leading or trailing slashes required.
   */
  private pettyCashReductionsApi = 'finances/pettyCash/reductions';

  constructor(private http: HttpClient) {}

  /**
   * Creates a new petty cash reduction from the provided data.
   * @param data The new petty cash reduction data.
   */
  public create(data: CreatePettyCashReductionInput): Observable<AppHttpResponse<PettyCashReduction>> {
    const formData: any = new FormData();
    formData.append('pettyCashId', data.pettyCashId);
    formData.append('bankAccountId', data.bankAccountId);
    formData.append('value', data.value);
    formData.append('transactionDate', data.transactionDate);
    formData.append('notes', data.notes);

    /**
     * Append journal lines to the form data.
     */
    for (let index = 0; index < data.lines.length; index++) {
      formData.append(`lines[${index}][accountId]`, data.lines[index].accountId);
      formData.append(`lines[${index}][credit]`, data.lines[index].credit);
      formData.append(`lines[${index}][debit]`, data.lines[index].debit);
      formData.append(`lines[${index}][costCenterId]`, data.lines[index].costCenterId);
      formData.append(`lines[${index}][notes]`, data.lines[index].notes);
    }

    return this.http.post<AppHttpResponse<PettyCashReduction>>(`${this.pettyCashReductionsApi}`, data);
  }

  /**
   * Searches in the petty cash reduction by pettyCash or fromDate or toDate.
   * @param pettyCash The pettyCash of the petty cash reduction.
   * @param fromDate The fromDate of the petty cash reduction.
   * @param toDate The toDate of the petty cash reduction.
   * @param page The current pagination page number.
   * @param pageSize The maximum number of petty cash reduction allowed per one pagination page.
   */
  public search(
    pettyCash: number[],
    fromDate: Date,
    toDate: Date,
    page: number,
    pageSize: number
  ): Observable<AppHttpResponse<PettyCashReduction[]>> {
    const params = new HttpParams()
      .set('pettyCash', pettyCash.join(','))
      .set('fromDate', fromDate.toISOString())
      .set('toDate', toDate.toISOString())
      .set('page', page.toString())
      .set('pageSize', pageSize.toString());
    return this.http.get<AppHttpResponse<PettyCashReduction[]>>(`${this.pettyCashReductionsApi}`, { params });
  }

  /**
   * Finds the petty cash reduction with the given id.
   * @param id The id of the petty cash reduction.
   */
  public findById(id: number): Observable<AppHttpResponse<PettyCashReduction>> {
    return this.http.get<AppHttpResponse<PettyCashReduction>>(`${this.pettyCashReductionsApi}/${id}`);
  }
}
