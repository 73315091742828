import { Action } from '@ngrx/store';

import { EmployeePaymentMethod } from 'lookups/models';
import { AppHttpResponse } from 'shared';

/**
 * The Lookups-Module store employee payment method action types.
 */
export enum EmployeePaymentMethodActionType {
  GET_ALL_EMPLOYEE_PAYMENT_METHODS = '[Lookups] [EmployeePaymentMethod] Get All Employee Payment Methods',
  GET_ALL_EMPLOYEE_PAYMENT_METHODS_FAIL = '[Lookups] [EmployeePaymentMethod] Get All Employee Payment Methods Fail',
  GET_ALL_EMPLOYEE_PAYMENT_METHODS_SUCCESS = '[Lookups] [EmployeePaymentMethod] Get All Employee Payment Methods Success',
}

/**
 * Represents a store employee payment method action.
 */
export class GetAllEmployeePaymentMethods implements Action {
  /**
   * The type of the action.
   */
  readonly type = EmployeePaymentMethodActionType.GET_ALL_EMPLOYEE_PAYMENT_METHODS;

  /**
   * Fires a new employee payment method action.
   */
  constructor() {}
}

/**
 * Represents a store employee payment method fail action.
 */
export class GetAllEmployeePaymentMethodsFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = EmployeePaymentMethodActionType.GET_ALL_EMPLOYEE_PAYMENT_METHODS_FAIL;

  /**
   * Fires a new employee payment method fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store employee payment method success action.
 */
export class GetAllEmployeePaymentMethodsSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = EmployeePaymentMethodActionType.GET_ALL_EMPLOYEE_PAYMENT_METHODS_SUCCESS;

  /**
   * Fires a employee payment method success action.
   * @param payload An object contains the list of employee payment methods .
   */
  constructor(public payload: AppHttpResponse<EmployeePaymentMethod[]>) {}
}

/**
 * Lookups-module employee payment method action types.
 */
export type EmployeePaymentMethodActions =
  | GetAllEmployeePaymentMethods
  | GetAllEmployeePaymentMethodsFail
  | GetAllEmployeePaymentMethodsSuccess;
