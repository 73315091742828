import { createSelector } from '@ngrx/store';

import { selectLookupsState } from 'lookups/store/selectors/feature.selectors';
import { LookupsState, VariantCategoryState } from 'lookups/store/states';

/**
 * Gets the variantCategory state.
 */
const selectVariantCategories = createSelector(selectLookupsState, (state: LookupsState) => state.variantCategories);

/**
 * Gets the list of loaded variantCategories.
 */
export const getVariantCategories = createSelector(
  selectVariantCategories,
  (state: VariantCategoryState) => state.data
);

/**
 * Gets the error that occurred in the state.
 */
export const getVariantCategoriesError = createSelector(
  selectVariantCategories,
  (state: VariantCategoryState) => state.error
);

/**
 * Determines if there is a running load process.
 */
export const getVariantCategoriesIsLoading = createSelector(
  selectVariantCategories,
  (state: VariantCategoryState) => state.isLoading
);

/**
 * Determines if the last load process has been ended successfully.
 */
export const getVariantCategoriesIsLoaded = createSelector(
  selectVariantCategories,
  (state: VariantCategoryState) => state.isLoaded
);
