<div [formGroup]="formGroup">
  <ng-select
    class="round"
    [formControlName]="controlName"
    searchable="true"
    [placeholder]="'SALES.INVOICES.POS.POS_DATA.SELECT_PRODUCT_SUB_PRODUCT' | translate"
    [notFoundText]="'SHARED.SEARCH.SEARCH_NOT_FOUND' | translate"
    dropdownPosition="bottom"
    (open)="changeDropdownStatus(true)"
    (close)="changeDropdownStatus(false)"
  >
    <ng-option *ngFor="let subProduct of subProducts" [value]="subProduct.id">
      <span class="badge badge-secondary mx-1" *ngFor="let subProductVariant of subProduct.subProductVariants">
        {{ subProductVariant.variant.name | localize: subProductVariant.variant.nameEn | async }}
      </span>
    </ng-option>
  </ng-select>
</div>
