import { EmployeeProvisionLink, GovService } from 'finances/models';
import { PaginationInfo } from 'shared';

/**
 * Represents the finance configurations state.
 */
export class FinanceConfigurationsState {
  /**
   * The list of employee provision links.
   */
  employeeProvisionLinks: EmployeeProvisionLink[];

  /**
   * The list of gov services.
   */
  govServices: GovService[];

  /**
   * The pagination info .
   */
  paginationInfo: PaginationInfo;

  /**
   * The pagination info for the gov service .
   */
  govServicesPaginationInfo: PaginationInfo;

  /**
   * An error that may resulting during processing some actions.
   */
  public error: any;

  /**
   * Determines if there is a running get employee provision links process.
   */
  isEmployeeProvisionLinksGetting: boolean;

  /**
   * Determines if the last get employee provision links process has been ended successfully.
   */
  isEmployeeProvisionLinksGetCompleted: boolean;

  /**
   * Determines if there is a running update employee provision links process.
   */
  isEmployeeProvisionLinksUpdating: boolean;

  /**
   * Determines if the last update employee provision links process has been ended successfully.
   */
  isEmployeeProvisionLinksUpdateCompleted: boolean;

  /**
   * Determines if there is a running get gov services process.
   */
  isGovServicesGetting: boolean;

  /**
   * Determines if the last get gov services process has been ended successfully.
   */
  isGovServicesGetCompleted: boolean;

  /**
   * Determines if there is a running update gov services process.
   */
  isGovServicesUpdating: boolean;

  /**
   * Determines if the last update gov services process has been ended successfully.
   */
  isGovServicesUpdateCompleted: boolean;
}
