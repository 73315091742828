import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';

import { AppHttpResponse } from 'shared';
import { CreateUnitOfMeasureClassInput, UnitOfMeasureClass, UpdateUnitOfMeasureClassInput } from 'stores/models';

/**
 * The unit of measure class services includes functionality to create, search, findById, update and delete for a unit of measure class.
 */
@Injectable()
export class UnitOfMeasureClassesService {
  /**
   * The relative route for the unit of measure classes.
   *
   * No leading or trailing slashes require.
   */
  private unitOfMeasureClassesApi = 'stores/unit-of-measure-classes';

  constructor(private http: HttpClient) {}

  /**
   * Creates a new unit of measure class from the provided data.
   * @param data The new unit of measure class data.
   */
  public create(data: CreateUnitOfMeasureClassInput): Observable<AppHttpResponse<UnitOfMeasureClass>> {
    return this.http.post<AppHttpResponse<UnitOfMeasureClass>>(`${this.unitOfMeasureClassesApi}`, data);
  }

  /**
   * Searches in the unit of measure class by fromDate and toDate.
   * @param name of the unit of measure class.
   * @param page The current pagination page number.
   * @param pageSize The maximum number of unit of measure class allowed per one pagination page.
   */
  public search(name: string, page: number, pageSize: number): Observable<AppHttpResponse<UnitOfMeasureClass[]>> {
    const params = new HttpParams().set('name', name).set('page', page.toString()).set('pageSize', pageSize.toString());
    return this.http.get<AppHttpResponse<UnitOfMeasureClass[]>>(`${this.unitOfMeasureClassesApi}`, { params });
  }

  /**
   * Finds the unit of measure class with the given id.
   * @param id The id of the unit of measure class.
   */
  public findById(id: number): Observable<AppHttpResponse<UnitOfMeasureClass>> {
    return this.http.get<AppHttpResponse<UnitOfMeasureClass>>(`${this.unitOfMeasureClassesApi}/${id}`);
  }

  /**
   * Updates an existing unit of measure class data using the provided data.
   * @param data The updated unit of measure class data.
   */
  public update(data: UpdateUnitOfMeasureClassInput): Observable<AppHttpResponse<UnitOfMeasureClass>> {
    return this.http.put<AppHttpResponse<UnitOfMeasureClass>>(`${this.unitOfMeasureClassesApi}`, data);
  }

  /**
   * Deletes the unit of measure class by given id.
   * @param id The id of the unit of measure class.
   */
  public delete(id: number): Observable<AppHttpResponse<UnitOfMeasureClass>> {
    return this.http.delete<AppHttpResponse<UnitOfMeasureClass>>(`${this.unitOfMeasureClassesApi}/${id}`);
  }
}
