<form [formGroup]="projectDetailsForm">
  <div class="row">
    <div class="form-group col-md-6">
      <label for="code"> {{ 'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.CODE' | translate }}</label>
      <input
        type="text"
        id="code"
        class="form-control round"
        [placeholder]="'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.CODE_PLACEHOLDER' | translate"
        maxlength="50"
        formControlName="code"
      />
    </div>
    <div class="form-group col-md-6">
      <label for="description">
        {{ 'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.ARABIC_PROJECT_DESCRIPTION' | translate }}</label
      >
      <input
        type="text"
        id="description"
        class="form-control round"
        [placeholder]="'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.ARABIC_PROJECT_DESCRIPTION_PLACEHOLDER' | translate"
        maxlength="200"
        formControlName="description"
      />
    </div>
    <div class="form-group col-md-6">
      <label for="descriptionEn">
        {{ 'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.ENGLISH_PROJECT_DESCRIPTION' | translate }}</label
      >
      <input
        type="text"
        id="descriptionEn"
        class="form-control round"
        [placeholder]="'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.ENGLISH_PROJECT_DESCRIPTION_PLACEHOLDER' | translate"
        maxlength="200"
        formControlName="descriptionEn"
      />
    </div>
    <div class="form-group col-md-6">
      <label for="customerId">{{ 'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.CUSTOMER' | translate }}</label>
      <ng-select
        class="round"
        formControlName="customerId"
        labelForId="customerId"
        searchable="true"
        [placeholder]="'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.CUSTOMER_PLACEHOLDER' | translate"
        [notFoundText]="'SHARED.SEARCH.SEARCH_NOT_FOUND' | translate"
        (open)="customersListVisibility = true"
      >
        <ng-option *ngFor="let customer of customers" [value]="customer.id">
          {{ customer.name | localize: customer.nameEn | async }}
        </ng-option>
      </ng-select>
    </div>
    <div class="form-group col-md-6">
      <label for="locationId">{{ 'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.LOCATION' | translate }}</label>
      <ng-select
        class="round"
        formControlName="locationId"
        labelForId="locationId"
        searchable="true"
        [placeholder]="'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.LOCATION_PLACEHOLDER' | translate"
        [notFoundText]="'SHARED.SEARCH.SEARCH_NOT_FOUND' | translate"
        (open)="locationsListVisibility = true"
      >
        <ng-option *ngFor="let location of locations" [value]="location.id">
          {{ location.name | localize: location.nameEn | async }}
        </ng-option>
      </ng-select>
    </div>
    <div class="form-group col-md-6">
      <label for="projectStatusId">
        {{ 'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.STATUS' | translate }}
      </label>
      <ng-select
        class="round"
        formControlName="projectStatusId"
        labelForId="projectStatusId"
        searchable="true"
        autocomplete="off"
        [placeholder]="'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.STATUS_PLACEHOLDER' | translate"
        [notFoundText]="'SHARED.SEARCH.SEARCH_NOT_FOUND' | translate"
      >
        <ng-option *ngFor="let projectStatus of projectStatuses$ | async" [value]="projectStatus?.id">
          {{ projectStatus?.name | localize: projectStatus?.nameEn | async }}
        </ng-option>
      </ng-select>
    </div>

    <div class="form-group col-md-6">
      <label for="startDate">
        {{ 'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.START_DATE' | translate }}
      </label>
      <div class="input-group round">
        <input
          class="form-control"
          id="startDate"
          name="startDate"
          formControlName="startDate"
          [placeholder]="'SHARED.SEARCH.DATE_PLACEHOLDER' | translate"
          ngbDatepicker
          #startDate="ngbDatepicker"
        />
        <div class="input-group-append">
          <span
            class="input-group-text gradient-light-blue-indigo"
            style="cursor: pointer;"
            (click)="startDate.toggle()"
          >
            <i class="fa fa-calendar"></i>
          </span>
        </div>
      </div>
    </div>
    <div class="form-group col-md-6">
      <label for="endDate">
        {{ 'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.END_DATE' | translate }}
      </label>
      <div class="input-group round">
        <input
          class="form-control"
          id="endDate"
          name="endDate"
          formControlName="endDate"
          [placeholder]="'SHARED.SEARCH.DATE_PLACEHOLDER' | translate"
          ngbDatepicker
          #endDate="ngbDatepicker"
        />
        <div class="input-group-append">
          <span class="input-group-text gradient-light-blue-indigo" style="cursor: pointer;" (click)="endDate.toggle()">
            <i class="fa fa-calendar"></i>
          </span>
        </div>
      </div>
    </div>
    <div class="form-group col-md-6">
      <label for="laborBudget"> {{ 'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.LABOR_BUDGET' | translate }}</label>
      <input
        type="text"
        id="laborBudget"
        mask="separator.2"
        thousandSeparator=","
        minLength="0"
        class="form-control round"
        [placeholder]="'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.LABOR_BUDGET_PLACEHOLDER' | translate"
        formControlName="laborBudget"
      />
    </div>
    <div class="form-group col-md-6">
      <label for="equipmentBudget">
        {{ 'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.EQUIPMENT_BUDGET' | translate }}</label
      >
      <input
        type="text"
        id="equipmentBudget"
        mask="separator.2"
        thousandSeparator=","
        minLength="0"
        class="form-control round"
        [placeholder]="'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.EQUIPMENT_BUDGET_PLACEHOLDER' | translate"
        formControlName="equipmentBudget"
      />
    </div>
    <div class="form-group col-md-6">
      <label for="laborOvertimeBudget">
        {{ 'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.LABOR_OVERTIME_BUDGET' | translate }}</label
      >
      <input
        type="text"
        id="laborOvertimeBudget"
        mask="separator.2"
        thousandSeparator=","
        minLength="0"
        class="form-control round"
        [placeholder]="'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.LABOR_OVERTIME_BUDGET_PLACEHOLDER' | translate"
        formControlName="laborOvertimeBudget"
      />
    </div>
    <div class="form-group col-md-6">
      <label for="revenueBudget"> {{ 'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.REVENUE_BUDGET' | translate }}</label>
      <input
        type="text"
        id="revenueBudget"
        mask="separator.2"
        thousandSeparator=","
        minLength="0"
        class="form-control round"
        [placeholder]="'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.REVENUE_BUDGET_PLACEHOLDER' | translate"
        formControlName="revenueBudget"
      />
    </div>
    <div class="form-group col-md-6">
      <input type="checkbox" class="cursor-pointer" formControlName="allowExceedBudget" />
      <span class="ml-2 mt-0 pt-0">{{
        'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.ALLOW_EXCEED_BUDGET' | translate
      }}</span>
    </div>
  </div>
</form>

<!-- customers search starts -->
<app-customers-search
  onlyActive="true"
  closeOnSelect="true"
  (customers)="selectCustomer($event)"
  [(visible)]="customersListVisibility"
>
</app-customers-search>
<!-- customers search ends -->

<!-- locations search starts -->
<locations-search
  onlyActive="true"
  closeOnSelect="true"
  (locations)="selectLocation($event)"
  [(visible)]="locationsListVisibility"
>
</locations-search>
<!-- locations search ends -->
