<!-- project logs list starts -->
<div class="row">
  <div class="col-md-12">
    <div class="card-content">
      <div class="px-3">
        <div class="row">
          <div class="col-md-12">
            <form class="row" [formGroup]="searchForm" (ngSubmit)="search()">
              <div class="form-group col">
                <label for="projectLogTypes">
                  {{ 'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.PROJECT_LOG_TYPES' | translate }}
                </label>
                <ng-select
                  class="round"
                  formControlName="projectLogTypes"
                  labelForId="projectLogTypes"
                  searchable="true"
                  autocomplete="off"
                  [multiple]="true"
                  [placeholder]="'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.PROJECT_LOG_TYPES_PLACEHOLDER' | translate"
                  [notFoundText]="'SHARED.SEARCH.SEARCH_NOT_FOUND' | translate"
                >
                  <ng-option *ngFor="let projectLogType of projectLogTypes$ | async" [value]="projectLogType?.id">
                    {{ projectLogType?.name | localize: projectLogType?.nameEn | async }}
                  </ng-option>
                </ng-select>
              </div>
              <div class="form-group col mb-0">
                <label for="fromDate">{{ 'SHARED.SEARCH.FROM_DATE' | translate }}</label>
                <div class="input-group round">
                  <input
                    class="form-control"
                    id="fromDate"
                    name="fromDate"
                    formControlName="fromDate"
                    [placeholder]="'SHARED.SEARCH.DATE_PLACEHOLDER' | translate"
                    ngbDatepicker
                    #fromDate="ngbDatepicker"
                  />
                  <div class="input-group-append">
                    <span
                      class="input-group-text gradient-light-blue-indigo"
                      style="cursor: pointer;"
                      (click)="fromDate.toggle()"
                    >
                      <i class="fa fa-calendar"></i>
                    </span>
                  </div>
                </div>
              </div>
              <div class="form-group col mb-0">
                <label for="toDate">{{ 'SHARED.SEARCH.TO_DATE' | translate }}</label>
                <div class="input-group round">
                  <input
                    class="form-control"
                    id="toDate"
                    name="toDate"
                    formControlName="toDate"
                    [placeholder]="'SHARED.SEARCH.DATE_PLACEHOLDER' | translate"
                    ngbDatepicker
                    #toDate="ngbDatepicker"
                  />
                  <div class="input-group-append">
                    <span
                      class="input-group-text gradient-light-blue-indigo"
                      style="cursor: pointer;"
                      (click)="toDate.toggle()"
                    >
                      <i class="fa fa-calendar"></i>
                    </span>
                  </div>
                </div>
              </div>
              <div class="col-md-auto form-group d-flex">
                <button
                  type="submit"
                  class="btn btn-raised btn-primary align-self-end round mx-1 mb-0"
                  [disabled]="isSearching$ | async"
                >
                  <i class="fa fa-search"></i>
                </button>
                <app-export-tools
                  *ngIf="(projectLogs$ | async)?.length"
                  class="d-flex align-self-end mx-1"
                  targetElementId="project-plan-logs-table-export-target-element"
                  [outputFileName]="'SHARED.EXPORT_TABLES.PROJECT_LOGS_TABLE' | translate"
                ></app-export-tools>
              </div>
            </form>
          </div>
          <div class="col-md-12" *ngIf="(projectLogs$ | async)?.length; else noItems">
            <div class="row">
              <div class="col-md-12 alert alert-light text-center" role="alert">
                {{ 'SHARED.SEARCH.SEARCH_RESULTS' | translate }} ({{ (projectLogs$ | async).length }})
                {{ 'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.PROJECT_LOG' | translate }}
              </div>
              <div class="col-md-12">
                <div class="table-responsive">
                  <table class="table text-center" id="project-plan-logs-table-export-target-element">
                    <thead>
                      <tr>
                        <th scope="col"><i class="fa fa-list-ol"></i></th>
                        <th scope="col">
                          {{ 'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.PROJECT' | translate }}
                        </th>
                        <th scope="col">
                          {{ 'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.PROJECT_LOG_TYPE' | translate }}
                        </th>
                        <th scope="col">
                          {{ 'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.SOURCE' | translate }}
                        </th>
                        <th scope="col">
                          {{ 'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.CREATED_AT' | translate }}
                        </th>
                        <th scope="col">
                          {{ 'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.USER' | translate }}
                        </th>
                        <th scope="col">
                          {{ 'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.TOOLS' | translate }}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let projectLog of projectLogs$ | async; trackBy: trackItems; let i = index">
                        <th scope="row">{{ i + 1 }}</th>
                        <td>
                          {{ projectLog.project.description | localize: projectLog.project.descriptionEn | async }}
                        </td>
                        <td>
                          {{ projectLog.projectLogType.name | localize: projectLog.projectLogType.nameEn | async }}
                        </td>
                        <td>
                          <span *ngIf="projectLog.projectLogType.id === projectLogTypeForCreatedPlanId">
                            {{ 'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.PLAN_TITLE' | translate }}
                          </span>
                          <span *ngIf="projectLog.projectMainTask">{{
                            projectLog.projectMainTask.name | localize: projectLog.projectMainTask.nameEn | async
                          }}</span>
                          <span *ngIf="projectLog.projectSubTask">
                            {{ projectLog.projectSubTask.name | localize: projectLog.projectSubTask.nameEn | async }}
                          </span>
                        </td>
                        <td>
                          <span [ngbTooltip]="projectLog.createdAt | date: 'time'">
                            {{ projectLog.createdAt | date }}
                          </span>
                        </td>
                        <td>{{ projectLog.user.name | localize: projectLog.user.nameEn | async }}</td>
                        <td>
                          <a
                            *ngIf="projectLog.projectMainTask"
                            class="info p-0"
                            (click)="
                              selectedProjectMainTask = projectLog.projectMainTask;
                              openModal(infoProjectMainTaskModalRef)
                            "
                            [ngbTooltip]="
                              'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.PROJECT_MAIN_TASK_DISPLAY' | translate
                            "
                          >
                            <i class="fa fa-info font-medium-3 mr-2"></i>
                          </a>
                          <a
                            *ngIf="projectLog.projectSubTask"
                            class="info p-0"
                            (click)="
                              selectedProjectSubTask = projectLog.projectSubTask; openModal(infoProjectSubTaskModalRef)
                            "
                            [ngbTooltip]="
                              'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.PROJECT_SUB_TASK_DISPLAY' | translate
                            "
                          >
                            <i class="fa fa-info font-medium-3 mr-2"></i>
                          </a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- project logs list ends -->

<!-- no items template starts -->
<ng-template #noItems>
  <div class="col-md-12 alert alert-warning mt-3 text-center" role="alert">
    {{ 'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.SEARCH_PROJECT_LOGS_RESULTS_NOT_FOUND' | translate }}
  </div>
</ng-template>
<!-- no items template ends -->

<!-- info project main task modal starts -->
<ng-template #infoProjectMainTaskModalRef let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title text-primary" id="modal-basic-title">
      {{ 'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.PROJECT_MAIN_TASK_DATA_TITLE' | translate }}
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="d()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-12">
        <div class="row">
          <div class="form-group col-md-6">
            <label for="name"> {{ 'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.MAIN_TASK_NAME' | translate }}</label>
            <input
              type="text"
              id="name"
              class="form-control round"
              readonly
              [value]="selectedProjectMainTask.name | localize: selectedProjectMainTask.nameEn | async"
            />
          </div>
          <div class="form-group col-md-6">
            <label for="budget"> {{ 'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.BUDGET' | translate }}</label>
            <input
              type="text"
              id="budget"
              thousandSeparator=","
              class="form-control round"
              readonly
              [value]="selectedProjectMainTask.budget | number: '1.2-2'"
            />
          </div>
          <div class="form-group col-md-6">
            <label for="startDate">
              {{ 'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.START_DATE' | translate }}
            </label>
            <input
              type="text"
              id="startDate"
              class="form-control round"
              [value]="selectedProjectMainTask.startDate | date: 'date'"
              readonly
            />
          </div>
          <div class="form-group col-md-6">
            <label for="endDate">
              {{ 'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.END_DATE' | translate }}
            </label>
            <input
              type="text"
              id="endDate"
              class="form-control round"
              readonly
              [value]="selectedProjectMainTask.endDate | date: 'date'"
            />
          </div>
          <div class="form-group col-md-12">
            <label for="description">
              {{ 'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.MAIN_TASK_DESCRIPTION' | translate }}</label
            >
            <textarea
              minlength="1"
              maxlength="500"
              rows="2"
              class="form-control round"
              id="description"
              readonly
              [value]="selectedProjectMainTask.description | localize: selectedProjectMainTask.descriptionEn | async"
            ></textarea>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="modal-actions">
      <button type="button" class="btn btn-outline-dark round" (click)="c()">
        <i class="fa fa-times"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.CANCEL' | translate }}</span>
      </button>
    </div>
  </div>
</ng-template>
<!-- info project main task modal ends -->

<!-- info project sub task modal starts -->
<ng-template #infoProjectSubTaskModalRef let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title text-primary" id="modal-basic-title">
      {{ 'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.PROJECT_SUB_TASK_DATA_TITLE' | translate }}
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="d()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-12">
        <div class="row">
          <div class="form-group col-md-6">
            <label for="name"> {{ 'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.SUB_TASK_NAME' | translate }}</label>
            <input
              type="text"
              id="name"
              class="form-control round"
              readonly
              [value]="selectedProjectSubTask.name | localize: selectedProjectSubTask.nameEn | async"
            />
          </div>
          <div class="form-group col-md-6">
            <label for="budget"> {{ 'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.BUDGET' | translate }}</label>
            <input
              type="text"
              id="budget"
              thousandSeparator=","
              class="form-control round"
              readonly
              [value]="selectedProjectSubTask.budget | number: '1.2-2'"
            />
          </div>
          <div class="form-group col-md-6">
            <label for="startDate">
              {{ 'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.START_DATE' | translate }}
            </label>
            <input
              type="text"
              id="startDate"
              class="form-control round"
              readonly
              [value]="selectedProjectSubTask.startDate | date: 'date'"
            />
          </div>
          <div class="form-group col-md-6">
            <label for="endDate">
              {{ 'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.END_DATE' | translate }}
            </label>
            <input
              type="text"
              id="endDate"
              class="form-control round"
              readonly
              [value]="selectedProjectSubTask.endDate | date: 'date'"
            />
          </div>

          <div class="form-group col-md-12">
            <label for="description">
              {{ 'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.SUB_TASK_DESCRIPTION' | translate }}</label
            >
            <textarea
              minlength="1"
              maxlength="500"
              rows="2"
              class="form-control round"
              id="description"
              readonly
              [value]="selectedProjectSubTask.description | localize: selectedProjectSubTask.descriptionEn | async"
            ></textarea>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="modal-actions">
      <button type="button" class="btn btn-outline-dark round" (click)="c()">
        <i class="fa fa-times"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.CANCEL' | translate }}</span>
      </button>
    </div>
  </div>
</ng-template>
<!-- info project sub task modal ends -->

<!-- project logs ends -->
