import { ActionReducerMap } from '@ngrx/store';

import { projectsReducer } from 'projects-management/store/reducers/projects.reducer';
import { projectResourcesReducer } from 'projects-management/store/reducers/project-resources.reducer';
import { ProjectsManagementState } from 'projects-management/store/states';

/**
 * The ProjectsManagement-Module store reducers.
 */
export const reducers: ActionReducerMap<ProjectsManagementState> = {
  projects: projectsReducer,
  projectResources: projectResourcesReducer,
};
