/**
 * The set of system supported project resource type sources.
 */
export enum ProjectResourceTypeSources {
  PAYROLL = 'PAYROLL',
  OUTGOING_STOCK = 'OUTGOING_STOCK',
  ASSET_DEPRECIATION = 'ASSET_DEPRECIATION',
  PURCHASES = 'PURCHASES',
  PURCHASE_RETURNS = 'PURCHASE_RETURNS',
}
