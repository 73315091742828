import { Quotation } from 'crm/models';
import { PaginationInfo } from 'shared/models';

/**
 * Represents the quotations state.
 */
export class QuotationsState {
  /**
   * The list of quotations.
   */
  public data: Quotation[];

  /**
   * The pagination info for the quotations.
   */
  public paginationInfo: PaginationInfo;

  /**
   * An error that may resulting during processing some actions.
   */
  public error: any;

  /**
   * Determines if there is a running search process.
   */
  public isSearching: boolean;

  /**
   * Determines if the last search process has been ended successfully.
   */
  public isSearchCompleted: boolean;

  /**
   * The current selected quotation.
   */
  public selectedQuotation: Quotation;

  /**
   * Determines if there is a running find-quotation process.
   */
  public isFinding: boolean;

  /**
   * Determines if the last find-quotation process has been ended successfully.
   */
  public isFindCompleted: boolean;

  /**
   * Determines if there is a running create process.
   */
  public isCreating: boolean;

  /**
   * Determines if the last create process has been ended successfully.
   */
  public isCreateCompleted: boolean;

  /**
   * Determines if there is a running reject process.
   */
  public isRejecting: boolean;

  /**
   * Determines if the last reject process has been ended successfully.
   */
  public isRejectCompleted: boolean;
}
