
    import { AssetExclusionReason } from 'lookups/models';

    /**
     * Represents the AssetExclusionReasons state.
     */
    export class AssetExclusionReasonState {
      /**
       * The list of AssetExclusionReasons.
       */
      public data: AssetExclusionReason [];
    
      /**
       * An error that may resulting during processing some actions.
       */
      public error: any;
    
      /**
       * Determines if there is a running load process.
       */
      public isLoading: boolean;
    
      /**
       * Determines if the last load process has been ended successfully.
       */
      public isLoaded: boolean;
    }

