<ng-container [formGroup]="lineGroup">
  <th scope="row">{{ index + 1 }}</th>
  <td>{{ lineGroup.value.pettyCashRefundRequestLine.description }}</td>
  <td>
    {{
      lineGroup.value.pettyCashRefundRequestLine.pettyCashRefundRequestType.name
        | localize: lineGroup.value.pettyCashRefundRequestLine.pettyCashRefundRequestType.nameEn
        | async
    }}
  </td>
  <td>
    {{ lineGroup.value.pettyCashRefundRequestLine.vendorName }}
  </td>
  <td>{{ lineGroup.value.pettyCashRefundRequestLine.value | toDecimal }}</td>
  <td>{{ lineGroup.value.pettyCashRefundRequestLine.tax }}</td>
  <td class="min-width-9">
    <input
      type="text"
      id="lineTotal"
      name="lineTotal"
      class="form-control round"
      [value]="(lineGroup.value.pettyCashRefundRequestLine.total | toDecimal) || '0.0'"
      readonly
    />
  </td>
  <td>{{ lineGroup.value.pettyCashRefundRequestLine.invoiceDate | date }}</td>
  <td>
    <a
      class="info p-0"
      (click)="selectedLine = lineGroup.value.pettyCashRefundRequestLine; openModal(viewAttachmentsModalRef)"
      [ngbTooltip]="
        'FINANCES.PETTY_CASH_REFUND_REQUESTS.PETTY_CASH_REFUND_REQUEST_DATA.DISPLAY_ATTACHMENTS' | translate
      "
    >
      <i class="fa fa-paperclip font-medium-3 mr-2"></i
      ><span *ngIf="lineGroup.value.pettyCashRefundRequestLine.pettyCashRefundRequestLineAttachments.length">{{
        lineGroup.value.pettyCashRefundRequestLine.pettyCashRefundRequestLineAttachments.length
      }}</span>
    </a>
  </td>
  <td>
    <a class="danger p-0" [ngbTooltip]="'SHARED.CONFIRM.DELETE' | translate" (click)="remove()">
      <i class="fa fa-close font-medium-3 mr-2"></i>
    </a>
  </td>
</ng-container>

<!-- view attachments modal starts -->
<ng-template #viewAttachmentsModalRef let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title text-info" id="modal-basic-title">
      {{
        'FINANCES.PETTY_CASH_REFUND_REQUEST_APPROVALS.PETTY_CASH_REFUND_REQUEST_APPROVAL_DATA.ATTACHMENTS' | translate
      }}
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="d()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="card-content">
        <div class="px-3">
          <div class="row">
            <div class="form-group col-md-12">
              <!-- request attachments starts -->
              <div class="card-content">
                <div class="px-3">
                  <app-files-viewer
                    *ngIf="selectedLine.pettyCashRefundRequestLineAttachments?.length; else noAttachments"
                    [files]="getAttachmentsUrls(selectedLine.pettyCashRefundRequestLineAttachments)"
                  ></app-files-viewer>
                </div>
              </div>
              <!-- request attachments ends -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="modal-actions">
      <button type="button" class="btn btn-raised btn-primary round" (click)="c()" ngbAutoFocus>
        <i class="fa fa-times"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.CLOSE' | translate }}</span>
      </button>
    </div>
  </div>
</ng-template>

<!-- no attachments template starts -->
<ng-template #noAttachments>
  <div class="col-md-12 alert alert-warning mt-3 text-center" role="alert">
    {{ 'FINANCES.PETTY_CASH_REFUND_REQUESTS.PETTY_CASH_REFUND_REQUEST_DATA.ATTACHMENTS_NOT_FOUND' | translate }}
  </div>
</ng-template>
<!-- no attachments template ends -->
