import { AuthorizePipe } from 'shared/pipes/authorize.pipe';
import { DateTimePipe } from 'shared/pipes/date-time.pipe';
import { CurrencyFormatPipe } from 'shared/pipes/currency-format.pipe';
import { LocalizePipe } from 'shared/pipes/localize.pipe';
import { ToNumberPipe } from 'shared/pipes/to-number.pipe';
import { ToDecimalPipe } from 'shared/pipes/to-decimal.pipe';
import { SanitizeUrlPipe } from 'shared/pipes/sanitize-url.pipe';

export * from 'shared/pipes/authorize.pipe';
export * from 'shared/pipes/date-time.pipe';
export * from 'shared/pipes/currency-format.pipe';
export * from 'shared/pipes/localize.pipe';
export * from 'shared/pipes/to-number.pipe';
export * from 'shared/pipes/to-decimal.pipe';
export * from 'shared/pipes/sanitize-url.pipe';

/**
 * The set of pipes registered on this module.
 */
export const PIPES: any[] = [
  AuthorizePipe,
  DateTimePipe,
  CurrencyFormatPipe,
  LocalizePipe,
  ToNumberPipe,
  ToDecimalPipe,
  SanitizeUrlPipe,
];
