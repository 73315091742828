import { createSelector } from '@ngrx/store';

import { selectLookupsState } from 'lookups/store/selectors/feature.selectors';
import { LookupsState, VendorLogTypeState } from 'lookups/store/states';

/**
 * Gets the vendor log type state.
 */
const selectVendorLogTypes = createSelector(selectLookupsState, (state: LookupsState) => state.vendorLogTypes);

/**
 * Gets the list of loaded vendor log types.
 */
export const getVendorLogTypes = createSelector(selectVendorLogTypes, (state: VendorLogTypeState) => state.data);

/**
 * Gets the error that occurred in the state.
 */
export const getVendorLogTypesError = createSelector(selectVendorLogTypes, (state: VendorLogTypeState) => state.error);

/**
 * Determines if there is a running load process.
 */
export const getVendorLogTypesIsLoading = createSelector(
  selectVendorLogTypes,
  (state: VendorLogTypeState) => state.isLoading
);

/**
 * Determines if the last load process has been ended successfully.
 */
export const getVendorLogTypesIsLoaded = createSelector(
  selectVendorLogTypes,
  (state: VendorLogTypeState) => state.isLoaded
);
