import { Component, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { APP_CONSTANTS } from 'shared';

@Component({
  selector: '[app-product-ingredients-form-item]',
  templateUrl: './product-ingredients-form-item.component.html',
  styles: [],
})
export class ProductIngredientsFormItemComponent {
  /**
   * Sets the product ingredient index in the ingredients-form.
   */
  @Input() index: number;

  /**
   * Sets the product ingredient form-group.
   */
  @Input() formGroup: FormGroup;

  /**
   * Emits a value when the user wants to remove current product ingredient from the list.
   */
  @Output() removeChange = new EventEmitter<any>();

  /**
   * The decimal mask.
   */
  readonly DECIMAL_MASK = APP_CONSTANTS.numeric.decimal.mask;

  /**
   * Remove the product ingredient from the list.
   */
  remove() {
    this.removeChange.emit(null);
  }
}
