import { Action } from '@ngrx/store';

import { VisaType } from 'lookups/models';
import { AppHttpResponse } from 'shared';

/**
 * The Lookups-Module store visa type action types.
 */
export enum VisaTypeActionType {
  GET_ALL_VISA_TYPES = '[Lookups] [VisaType] Get All Visa Types',
  GET_ALL_VISA_TYPES_FAIL = '[Lookups] [VisaType] Get All Visa Types Fail',
  GET_ALL_VISA_TYPES_SUCCESS = '[Lookups] [VisaType] Get All Visa Types Success',
}

/**
 * Represents a store visa type action.
 */
export class GetAllVisaTypes implements Action {
  /**
   * The type of the action.
   */
  readonly type = VisaTypeActionType.GET_ALL_VISA_TYPES;

  /**
   * Fires a new visa type action.
   */
  constructor() {}
}

/**
 * Represents a store visa type fail action.
 */
export class GetAllVisaTypesFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = VisaTypeActionType.GET_ALL_VISA_TYPES_FAIL;

  /**
   * Fires a new visa type fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store visa type success action.
 */
export class GetAllVisaTypesSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = VisaTypeActionType.GET_ALL_VISA_TYPES_SUCCESS;

  /**
   * Fires a visa type success action.
   * @param payload An object contains the list of visa types.
   */
  constructor(public payload: AppHttpResponse<VisaType[]>) {}
}

/**
 * Lookups-module visa type action types.
 */
export type VisaTypeActions = GetAllVisaTypes | GetAllVisaTypesFail | GetAllVisaTypesSuccess;
