import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';

import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

import { NotificationService, PaginationInfo, TranslationService } from 'shared';
import * as fromAppSearchStore from 'app-search/store';
import * as fromLookupsStore from 'lookups/store';
import { Department } from 'hr/models';
import { DepartmentCategory, DepartmentType } from 'lookups/models';

@Component({
  selector: 'departments-search',
  templateUrl: './departments-search.component.html',
  styles: [],
})
export class DepartmentsSearchComponent implements OnInit {
  /**
   * Indicates whether the modal will automatically close after select the first department.
   *
   * Be default it will not be closed.
   */
  @Input() closeOnSelect = false;

  /**
   * Indicates whether the departments displayed on the list should be all active.
   *
   * Be default it will not be closed.
   */
  @Input() onlyActive = false;

  /**
   * Sets the visibility of this component modal.
   *
   * Handles visibility change in two-data-binding approach.
   */
  @Input() set visible(isShown: boolean) {
    if (isShown && this.modalRef) {
      this.openModal(this.modalRef);
    } else {
      this.closeModal();
    }

    this.visibleChange.emit(isShown);
  }

  /**
   * Outputs a value that indicates whether the modal is shown or not.
   *
   * Handles visibility change in two-data-binding approach.
   */
  @Output() visibleChange = new EventEmitter<boolean>();

  /** Outputs the selected departments. */
  @Output() departments = new EventEmitter<Department[]>();

  /**
   * The modal template reference.
   */
  @ViewChild('modalRef') modalRef: ElementRef<any>;

  /**
   * Indicates whether there is a search process is running or not.
   */
  isSearching$: Observable<boolean>;

  /**
   * The list of departments.
   */
  departments$: Observable<Department[]>;

  /**
   * The list of departments.
   */
  departmentsList: Department[];

  /**
   * The list of department types.
   */
  departmentTypes$: Observable<DepartmentType[]>;

  /**
   * The list of department categories.
   */
  departmentCategories$: Observable<DepartmentCategory[]>;

  /**
   * The pagination info.
   */
  paginationInfo$: Observable<PaginationInfo>;

  /**
   * The search form.
   */
  searchForm: FormGroup;

  /**
   * The ngb-modal reference to use it when modal needed to be closed.
   */
  modal: NgbModalRef;

  /**
   *
   * @param modalService The modal service.
   * @param notificationService The notification service.
   * @param appSearchStore$ The app search store.
   * @param translationService The translation service.
   * @param lookupsStore$ the lookups-store module.
   */
  constructor(
    private modalService: NgbModal,
    private notificationService: NotificationService,
    private appSearchStore$: Store<fromAppSearchStore.AppSearchState>,
    private translationService: TranslationService,
    private lookupsStore$: Store<fromLookupsStore.LookupsState>
  ) {}

  ngOnInit(): void {
    this.init();
  }

  /**
   * Initialize component data.
   */
  init() {
    this.initForm();

    /**
     * Load data.
     */
    this.isSearching$ = this.appSearchStore$.pipe(select(fromAppSearchStore.getDepartmentsSearching));

    let isManualSearchTriggeredBeforeDepartments = false;
    this.departments$ = this.appSearchStore$.pipe(
      select(fromAppSearchStore.getDepartments),
      tap((departments) => {
        if (departments) {
          this.departmentsList = departments;
        }
        if (!isManualSearchTriggeredBeforeDepartments && !departments.length) {
          isManualSearchTriggeredBeforeDepartments = true;
          this.search();
        }
      })
    );

    let isManualSearchTriggeredBeforeForDepartmentTypes = false;
    this.departmentTypes$ = this.lookupsStore$.pipe(
      select(fromLookupsStore.getDepartmentTypes),
      tap((types) => {
        if (!isManualSearchTriggeredBeforeForDepartmentTypes && !types.length) {
          isManualSearchTriggeredBeforeForDepartmentTypes = true;
          this.lookupsStore$.dispatch(new fromLookupsStore.GetAllDepartmentType());
        }
      })
    );

    let isManualSearchTriggeredBeforeForDepartmentCategories = false;
    this.departmentCategories$ = this.lookupsStore$.pipe(
      select(fromLookupsStore.getDepartmentCategories),
      tap((categories) => {
        if (!isManualSearchTriggeredBeforeForDepartmentCategories && !categories.length) {
          isManualSearchTriggeredBeforeForDepartmentCategories = true;
          this.lookupsStore$.dispatch(new fromLookupsStore.GetAllDepartmentCategory());
        }
      })
    );

    this.paginationInfo$ = this.appSearchStore$.pipe(select(fromAppSearchStore.getDepartmentsPaginationInfo));
  }

  /**
   * Initialize form and add validators.
   */
  initForm() {
    this.searchForm = new FormGroup({
      name: new FormControl(''),
      departmentTypes: new FormControl([]),
      departmentCategories: new FormControl([]),
      page: new FormControl(1),
    });
  }

  /**
   * Handles search parameters change.
   */
  search(event?: KeyboardEvent) {
    if (event && event.key === 'Enter') {
      event.preventDefault();
      return;
    }

    this.applyFiltersAndSearch();
  }

  /**
   * Handles pagination page-changed event.
   * @param page The current selected page number.
   */
  pageChanged(page: number) {
    /** Update pagination page. */
    this.searchForm.patchValue({ page });

    this.applyFiltersAndSearch(page);
  }

  /**
   * Enables trackBy feature for *ngFor utility to track items
   * depending on a comparer to reduce DOM change.
   * If the item is already exist then no HTML will change, only bounded values.
   * @param item The item to determine if it was changed or not.
   */
  trackItems(item: Department) {
    return item ? item.id : undefined;
  }

  /**
   * Handles department selected event.
   * @param departments The list of departments to notify selected.
   */
  selectDepartment(departments: Department[]) {
    this.notificationService.successInstant(
      this.translationService.translate('HR.DEPARTMENTS.NOTIFICATION_MESSAGE.DEPARTMENT_SELECTED')
    );
    this.departments.emit(departments);
    if (this.closeOnSelect) {
      this.closeModal();
    }
  }

  /**
   * Applies the search filters and dispatches a search action.
   * @param page The current page number, default to `1`.
   */
  applyFiltersAndSearch(page: number = 1) {
    const { name, departmentTypes, departmentCategories } = this.searchForm.value;

    this.appSearchStore$.dispatch(
      new fromAppSearchStore.SearchDepartments({
        name,
        departmentTypes,
        departmentCategories,
        page,
      })
    );
  }

  /**
   * Opens the modal of the given templateRef.
   * @param modalRef The modal templateRef to be opened.
   */
  openModal(modalRef) {
    /**
     * Open the modal & listen for user press ESC key.
     */
    this.modal = this.modalService.open(modalRef, { size: 'lg' });
    this.modal.result.then(null, () => this.closeModal());
  }

  /**
   * Closes the currently opened modal.
   */
  closeModal() {
    this.modal?.dismiss();
    this.departments.emit([]);
    this.visibleChange.emit(false);
  }
}
