import { createSelector } from '@ngrx/store';

import { SalesState, SaleReturnsState } from 'sales/store/states';
import { selectSalesState } from 'sales/store/selectors/feature.selectors';

/**
 * Gets the saleReturns state.
 */
const selectSaleReturnsState = createSelector(selectSalesState, (state: SalesState) => state.saleReturns);

/**
 * Gets the list of loaded saleReturns.
 */
export const getSaleReturns = createSelector(selectSaleReturnsState, (state: SaleReturnsState) => state.data);

/**
 * Gets the saleReturns pagination info.
 */
export const getSaleReturnsPaginationInfo = createSelector(
  selectSaleReturnsState,
  (state: SaleReturnsState) => state.paginationInfo
);

/**
 * Gets the error that occurred in the state.
 */
export const getSaleReturnsError = createSelector(selectSaleReturnsState, (state: SaleReturnsState) => state.error);

/**
 * Determines if there is a running search process.
 */
export const getSaleReturnsSearching = createSelector(
  selectSaleReturnsState,
  (state: SaleReturnsState) => state.isSearching
);

/**
 * Determines if the last search process has been ended successfully.
 */
export const getSaleReturnsSearchCompleted = createSelector(
  selectSaleReturnsState,
  (state: SaleReturnsState) => state.isSearchCompleted
);

/**
 * Gets the selected saleReturn.
 */
export const getSelectedSaleReturn = createSelector(
  selectSaleReturnsState,
  (state: SaleReturnsState) => state.selectedSaleReturn
);

/**
 * Determines if there is a running find-saleReturns process.
 */
export const getSaleReturnsFinding = createSelector(
  selectSaleReturnsState,
  (state: SaleReturnsState) => state.isFinding
);

/**
 * Determines if the last find-saleReturns process has been ended successfully.
 */
export const getSaleReturnsFindCompleted = createSelector(
  selectSaleReturnsState,
  (state: SaleReturnsState) => state.isFindCompleted
);

/**
 * Determines if there is a running create process.
 */
export const getSelectedSaleReturnCreating = createSelector(
  selectSaleReturnsState,
  (state: SaleReturnsState) => state.isCreating
);

/**
 * Determines if the last create process has been ended successfully.
 */
export const getSelectedSaleReturnCreateCompleted = createSelector(
  selectSaleReturnsState,
  (state: SaleReturnsState) => state.isCreateCompleted
);
