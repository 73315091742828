import { Injectable } from '@angular/core';

import { of } from 'rxjs';
import { switchMap, map, catchError, tap } from 'rxjs/operators';
import { Effect, ofType, Actions } from '@ngrx/effects';

import { NotificationService, StatusCode, PAGINATION, TranslationService } from 'shared';
import { UnitsOfMeasureService } from 'stores/services';
import {
  CreateUnitOfMeasure,
  CreateUnitOfMeasureFail,
  CreateUnitOfMeasureSuccess,
  DeleteUnitOfMeasure,
  DeleteUnitOfMeasureFail,
  DeleteUnitOfMeasureSuccess,
  FindUnitOfMeasure,
  FindUnitOfMeasureFail,
  FindUnitOfMeasureSuccess,
  GetAllUnitsOfMeasure,
  GetAllUnitsOfMeasureFail,
  GetAllUnitsOfMeasureSuccess,
  SearchUnitsOfMeasure,
  SearchUnitsOfMeasureFail,
  SearchUnitsOfMeasureSuccess,
  UnitsOfMeasureActionType,
  UpdateUnitOfMeasure,
  UpdateUnitOfMeasureFail,
  UpdateUnitOfMeasureSuccess,
} from 'stores/store/actions';

@Injectable()
export class UnitsOfMeasureEffects {
  constructor(
    private actions$: Actions,
    private unitsOfMeasureService: UnitsOfMeasureService,
    private notificationService: NotificationService,
    private translationService: TranslationService
  ) {}

  /* ========================= SEARCH_UNITS_OF_MEASURE =================================== */
  @Effect()
  search$ = this.actions$.pipe(
    ofType(UnitsOfMeasureActionType.SEARCH_UNITS_OF_MEASURE),
    switchMap(({ payload }: SearchUnitsOfMeasure) =>
      this.unitsOfMeasureService
        .search(payload.name, payload.unitOfMeasureClasses, payload.page, PAGINATION.UnitOfMeasure)
        .pipe(
          map((response) => new SearchUnitsOfMeasureSuccess(response)),
          catchError((error) => of(new SearchUnitsOfMeasureFail(error)))
        )
    )
  );

  /* ========================= GET_ALL_UNITS_OF_MEASURE =================================== */
  @Effect()
  findAll$ = this.actions$.pipe(
    ofType(UnitsOfMeasureActionType.GET_ALL_UNITS_OF_MEASURE),
    switchMap(() =>
      this.unitsOfMeasureService.getAll().pipe(
        map((response) => new GetAllUnitsOfMeasureSuccess(response)),
        catchError((error) => of(new GetAllUnitsOfMeasureFail(error)))
      )
    )
  );

  /* ========================= FIND_UNIT_OF_MEASURE =================================== */
  @Effect()
  find$ = this.actions$.pipe(
    ofType(UnitsOfMeasureActionType.FIND_UNIT_OF_MEASURE),
    switchMap((action: FindUnitOfMeasure) =>
      this.unitsOfMeasureService.findById(action.payload).pipe(
        map((response) => new FindUnitOfMeasureSuccess(response)),
        catchError((error) => of(new FindUnitOfMeasureFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  findFail$ = this.actions$.pipe(
    ofType(UnitsOfMeasureActionType.FIND_UNIT_OF_MEASURE_FAIL),
    tap((action: FindUnitOfMeasureFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate('STORES.UNITS_OF_MEASURE.UNIT_OF_MEASURE_NOT_FOUND')
        );
      }
    })
  );

  /* ========================= CREATE_UNIT_OF_MEASURE =================================== */
  @Effect()
  create$ = this.actions$.pipe(
    ofType(UnitsOfMeasureActionType.CREATE_UNIT_OF_MEASURE),
    switchMap((action: CreateUnitOfMeasure) =>
      this.unitsOfMeasureService.create(action.payload).pipe(
        map((response) => new CreateUnitOfMeasureSuccess(response)),
        catchError((error) => of(new CreateUnitOfMeasureFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  createSuccess$ = this.actions$.pipe(
    ofType(UnitsOfMeasureActionType.CREATE_UNIT_OF_MEASURE_SUCCESS),
    tap((action: CreateUnitOfMeasureSuccess) => {
      this.notificationService.success(
        this.translationService.translate('STORES.UNITS_OF_MEASURE.UNIT_OF_MEASURE_ADDED')
      );
    })
  );

  @Effect({ dispatch: false })
  createFail$ = this.actions$.pipe(
    ofType(UnitsOfMeasureActionType.CREATE_UNIT_OF_MEASURE_FAIL),
    tap((action: CreateUnitOfMeasureFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );

  /* ========================= UPDATE_UNIT_OF_MEASURE =================================== */
  @Effect()
  update$ = this.actions$.pipe(
    ofType(UnitsOfMeasureActionType.UPDATE_UNIT_OF_MEASURE),
    switchMap((action: UpdateUnitOfMeasure) =>
      this.unitsOfMeasureService.update(action.payload).pipe(
        map((response) => new UpdateUnitOfMeasureSuccess(response)),
        catchError((error) => of(new UpdateUnitOfMeasureFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  updateSuccess$ = this.actions$.pipe(
    ofType(UnitsOfMeasureActionType.UPDATE_UNIT_OF_MEASURE_SUCCESS),
    tap((action: UpdateUnitOfMeasureSuccess) => {
      this.notificationService.success(
        this.translationService.translate('STORES.UNITS_OF_MEASURE.UNIT_OF_MEASURE_UPDATED')
      );
    })
  );

  @Effect({ dispatch: false })
  updateFail$ = this.actions$.pipe(
    ofType(UnitsOfMeasureActionType.UPDATE_UNIT_OF_MEASURE_FAIL),
    tap((action: UpdateUnitOfMeasureFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate('STORES.UNITS_OF_MEASURE.UNIT_OF_MEASURE_NOT_FOUND')
        );
      } else if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );

  /* ========================= DELETE_UNIT_OF_MEASURE =================================== */
  @Effect()
  delete$ = this.actions$.pipe(
    ofType(UnitsOfMeasureActionType.DELETE_UNIT_OF_MEASURE),
    switchMap((action: DeleteUnitOfMeasure) =>
      this.unitsOfMeasureService.delete(action.payload).pipe(
        map((response) => new DeleteUnitOfMeasureSuccess(response)),
        catchError((error) => of(new DeleteUnitOfMeasureFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  deleteSuccess$ = this.actions$.pipe(
    ofType(UnitsOfMeasureActionType.DELETE_UNIT_OF_MEASURE_SUCCESS),
    tap((action: DeleteUnitOfMeasureSuccess) => {
      this.notificationService.success(
        this.translationService.translate('STORES.UNITS_OF_MEASURE.UNIT_OF_MEASURE_DELETED')
      );
    })
  );

  @Effect({ dispatch: false })
  deleteFail$ = this.actions$.pipe(
    ofType(UnitsOfMeasureActionType.DELETE_UNIT_OF_MEASURE_FAIL),
    tap((action: DeleteUnitOfMeasureFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate('STORES.UNITS_OF_MEASURE.UNIT_OF_MEASURE_NOT_FOUND')
        );
      } else if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );
}
