import { Action } from '@ngrx/store';

import { AppHttpResponse } from 'shared';
import { Bank } from 'finances/models';

/**
 * The AppSearch-module store Banks action types.
 */
export enum BanksActionType {
  SEARCH_BANKS = '[AppSearch] [Banks] Search Bank',
  SEARCH_BANKS_FAIL = '[AppSearch] [Banks] Search Bank Fail',
  SEARCH_BANKS_SUCCESS = '[AppSearch] [Banks] Search Bank Success',
}

/**
 * Represents a store Banks search action.
 */
export class SearchBanks implements Action {
  /**
   * The type of the action.
   */
  readonly type = BanksActionType.SEARCH_BANKS;

  /**
   * Fires a new banks search action.
   * @param payload the search parameters,
   * if omitted, all banks will be loaded.
   */
  constructor(public payload?: { name: string; page: number }) {}
}

/**
 * Represents a store Banks search fail action.
 */
export class SearchBanksFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = BanksActionType.SEARCH_BANKS_FAIL;

  /**
   * Fires a new Banks search fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store search success action.
 */
export class SearchBanksSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = BanksActionType.SEARCH_BANKS_SUCCESS;

  /**
   * Fires a new banks search success action.
   * @param payload An object contains the list of banks that matches the search criteria
   * in addition to the pagination info.
   */
  constructor(public payload: AppHttpResponse<Bank[]>) {}
}

/**
 * finances-module banks action types.
 */
export type BanksActions = SearchBanks | SearchBanksFail | SearchBanksSuccess;
