/**
 * Represents a customer log type.
 */
export class CustomerLogType {
  /**
   * Gets or sets the id of the customer log type.
   */
  id: number;

  /**
   * Gets or sets the name of the customer log type.
   */
  name: string;

  /**
   * Gets or sets the English name of the customer log type.
   */
  nameEn: string;

  /**
   * Gets or sets the key of the customer log type.
   */
  key: string;

  /**
   * Indicates the type of the transaction whether debit or credit.
   */
  isDebit: boolean;

  /**
   * Indicates the type of the transaction whether debit or credit.
   */
  isCredit: boolean;
}
