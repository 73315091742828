import { Injectable } from '@angular/core';

import { of } from 'rxjs';
import { switchMap, map, catchError } from 'rxjs/operators';
import { Effect, ofType, Actions } from '@ngrx/effects';

import { LookupsService } from 'lookups/services';
import {
  ManagerialLevelActionType,
  GetAllManagerialLevelsFail,
  GetAllManagerialLevelsSuccess,
} from 'lookups/store/actions';

@Injectable()
export class ManagerialLevelEffects {
  constructor(private actions$: Actions, private lookupsService: LookupsService) {}

  /* ========================= GET_ALL_MANAGERIAL_LEVELS =================================== */
  @Effect()
  getAllManagerialLevels$ = this.actions$.pipe(
    ofType(ManagerialLevelActionType.GET_ALL_MANAGERIAL_LEVELS),
    switchMap(() =>
      this.lookupsService.getAllManagerialLevels().pipe(
        map((response) => new GetAllManagerialLevelsSuccess(response)),
        catchError((error) => of(new GetAllManagerialLevelsFail(error)))
      )
    )
  );
}
