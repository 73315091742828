import { NoticePeriod } from 'lookups/models';

/**
 * Represents the notice periods state.
 */
export class NoticePeriodState {
  /**
   * The list of notice periods.
   */
  public data: NoticePeriod[];

  /**
   * An error that may resulting during processing some actions.
   */
  public error: any;

  /**
   * Determines if there is a running load process.
   */
  public isLoading: boolean;

  /**
   * Determines if the last load process has been ended successfully.
   */
  public isLoaded: boolean;
}
