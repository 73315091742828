import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';

import { AppHttpResponse } from 'shared';
import { ProjectSubTask } from 'projects-management/models';

/**
 * The sub task services includes functionality to search for sub tasks.
 */
@Injectable()
export class SubTasksService {
  /**
   * The relative route for the banks.
   *
   * No leading or trailing slashes required.
   */
  private subTasksApi = 'projects-management/projects/sub-tasks';
  constructor(private http: HttpClient) {}

  /**
   * Searches in the sub tasks by name.
   * @param description The description of the sub task.
   * @param projects The projects of the account.
   * @param page The current pagination page number.
   * @param pageSize The maximum number of sub tasks allowed per one pagination page.
   */
  public search(
    description: string,
    projects: number[],
    page: number,
    pageSize: number
  ): Observable<AppHttpResponse<ProjectSubTask[]>> {
    const params = new HttpParams()
      .set('description', description)
      .set('projects', projects.join(','))
      .set('page', page.toString())
      .set('pageSize', pageSize.toString());
    return this.http.get<AppHttpResponse<ProjectSubTask[]>>(`${this.subTasksApi}`, { params });
  }
}
