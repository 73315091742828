import { PaginationInfo } from 'shared/models';
import { Bank } from 'finances/models';

/**
 * Represents the banks state.
 */
export class BanksState {
  /**
   * The list of banks.
   */
  data: Bank[];

  /**
   * The pagination info for the banks.
   */
  paginationInfo: PaginationInfo;

  /**
   * An error that may resulting during processing some actions.
   */
  error: any;

  /**
   * Determines if there is a running search process.
   */
  isSearching: boolean;

  /**
   * Determines if the last search process has been ended successfully.
   */
  isSearchCompleted: boolean;
}
