import { Component, Input } from '@angular/core';

import { Decimal } from 'decimal.js';

import { IncomingStockProduct, IncomingStock } from 'stocks/models';

@Component({
  selector: 'app-journal-incoming-stock-details',
  templateUrl: './journal-incoming-stock-details.component.html',
  styles: [],
})
export class JournalIncomingStockDetailsComponent {
  /**
   * Sets the current viewed journal incoming stock.
   */
  @Input() incomingStock: IncomingStock;

  /**
   * Gets the total quantity of incoming stock products.
   */
  productsQuantity(incomingStockProducts: IncomingStockProduct[]): number {
    return Decimal.sum(0, ...incomingStockProducts.map((product) => product.quantity ?? 0)).toNumber();
  }
}
