<!-- unpaid purchase invoices search modal starts -->
<ng-template #modalRef let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title text-primary" id="modal-basic-title">
      {{ 'SALES.INVOICES.SALES_INVOICES_UNPAID_LIST' | translate }}
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="d(); closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <!-- unpaid purchase invoices list starts -->
    <div class="row">
      <div class="col-md-12">
        <form class="row" [formGroup]="searchForm" (ngSubmit)="search()">
          <div class="form-group col-md-4">
            <label for="locations">{{ 'SALES.INVOICES.SALE_INVOICE_DATA.SALE_INVOICE_LOCATIONS' | translate }}</label>
            <ng-select
              class="round"
              formControlName="locations"
              [multiple]="true"
              [closeOnSelect]="false"
              labelForId="locations"
              searchable="true"
              [placeholder]="'SALES.INVOICES.SALE_INVOICE_DATA.SALE_INVOICE_LOCATIONS_PLACEHOLDER' | translate"
              [notFoundText]="'SHARED.SEARCH.SEARCH_NOT_FOUND' | translate"
              (open)="locationsListVisibility = true"
            >
              <ng-option *ngFor="let location of locations" [value]="location.id">
                {{ location.name | localize: location.nameEn | async }}
              </ng-option>
            </ng-select>
          </div>
          <div *ngIf="!exclusiveCustomerId" class="form-group col-md-4">
            <label for="customers">{{ 'SALES.INVOICES.SALE_INVOICE_DATA.CUSTOMERS' | translate }}</label>
            <ng-select
              class="round"
              formControlName="customers"
              [multiple]="true"
              [closeOnSelect]="false"
              labelForId="customers"
              searchable="true"
              [placeholder]="'SALES.INVOICES.SALE_INVOICE_DATA.CUSTOMERS_PLACEHOLDER' | translate"
              [notFoundText]="'SHARED.SEARCH.SEARCH_NOT_FOUND' | translate"
              (open)="customersListVisibility = true"
            >
              <ng-option *ngFor="let customer of customers" [value]="customer.id">
                {{ customer.name | localize: customer.nameEn | async }}
              </ng-option>
            </ng-select>
          </div>
          <div class="form-group col-md-4">
            <label for="invoiceNum">{{ 'SALES.INVOICES.SALE_INVOICE_DATA.INVOICE_NUMBER' | translate }}</label>
            <input
              type="number"
              id="invoiceNum"
              name="invoiceNum"
              class="form-control round"
              [placeholder]="'SALES.INVOICES.SALE_INVOICE_DATA.INVOICE_NUMBER_PLACEHOLDER' | translate"
              formControlName="invoiceNum"
            />
          </div>
          <div class="col-md-auto d-flex">
            <button
              type="submit"
              class="btn btn-raised btn-primary round align-self-center mb-0"
              [disabled]="isSearching$ | async"
            >
              <i class="fa fa-search"></i>
            </button>
          </div>
        </form>
      </div>
      <div class="col-md-12" *ngIf="(unpaidInvoices$ | async)?.length; else noItems">
        <div class="row">
          <div class="col-md-12 alert alert-light text-center" role="alert">
            {{ 'SHARED.SEARCH.SEARCH_RESULTS' | translate }} ({{ (paginationInfo$ | async).total | toNumber }})
            {{ 'SALES.INVOICES.SALE_INVOICE_DATA.UNPAID_INVOICE' | translate }}
          </div>
          <div class="col-md-12">
            <div class="table-responsive">
              <table class="table text-center">
                <thead>
                  <tr>
                    <th class="fit-width" *ngIf="!closeOnSelect">
                      <a
                        class="primary p-0"
                        (click)="selectUnpaidInvoice(unpaidInvoicesList)"
                        [ngbTooltip]="'SHARED.SEARCH.SELECT_ALL' | translate"
                      >
                        <i class="fa fa-check-circle font-medium-3"></i>
                      </a>
                    </th>
                    <th class="fit-width" *ngIf="closeOnSelect">
                      <i class="fa fa-gear font-medium-3"></i>
                    </th>
                    <th scope="col">
                      <i class="fa fa-barcode"></i
                      ><span class="mx-1">{{ 'SALES.INVOICES.SALE_INVOICE_DATA.INVOICE_NUMBER' | translate }}</span>
                    </th>
                    <th scope="col">{{ 'SALES.INVOICES.SALE_INVOICE_DATA.CUSTOMER' | translate }}</th>
                    <th scope="col">{{ 'SALES.INVOICES.SALE_INVOICE_DATA.INVOICE_VALUE' | translate }}</th>
                    <th scope="col">{{ 'SALES.INVOICES.SALE_INVOICE_DATA.PAID' | translate }}</th>
                    <th scope="col">{{ 'SALES.INVOICES.SALE_INVOICE_DATA.REMAINING' | translate }}</th>
                    <th scope="col">{{ 'SALES.INVOICES.SALE_INVOICE_DATA.INVOICE_DATE' | translate }}</th>
                    <th scope="col">{{ 'SHARED.SEARCH.CREATED_AT' | translate }}</th>
                    <th scope="col">{{ 'SALES.INVOICES.SALE_INVOICE_DATA.USER' | translate }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let unpaidInvoice of unpaidInvoices$ | async; trackBy: trackItems">
                    <td>
                      <a
                        class="primary p-0"
                        (click)="selectUnpaidInvoice([unpaidInvoice])"
                        [ngbTooltip]="'SALES.INVOICES.SALE_INVOICE_DATA.INVOICE_SELECT' | translate"
                      >
                        <i class="fa fa-check-circle-o font-medium-3"></i>
                      </a>
                    </td>
                    <th scope="row">{{ unpaidInvoice.invoiceNum }}</th>
                    <td>{{ unpaidInvoice.customer.name | localize: unpaidInvoice.customer.nameEn | async }}</td>
                    <td>
                      <span class="badge bg-success round">
                        {{ unpaidInvoice.net | toDecimal }}
                      </span>
                    </td>
                    <td>
                      <span class="badge bg-warning round">
                        {{ unpaidInvoice.paid | toDecimal }}
                      </span>
                    </td>
                    <td>
                      <span class="badge bg-danger round">
                        {{ unpaidInvoice.remaining | toDecimal }}
                      </span>
                    </td>
                    <td>
                      <span [ngbTooltip]="unpaidInvoice.transactionDate | date: 'time'">
                        {{ unpaidInvoice.transactionDate | date }}
                      </span>
                    </td>
                    <td>
                      <span [ngbTooltip]="unpaidInvoice.createdAt | date: 'time'">
                        {{ unpaidInvoice.createdAt | date }}
                      </span>
                    </td>
                    <td>{{ unpaidInvoice.user.name }}</td>
                  </tr>
                </tbody>
              </table>
              <div class="d-flex justify-content-between p-2">
                <ngb-pagination
                  *ngIf="paginationInfo$ | async; let pagination"
                  maxSize="5"
                  [collectionSize]="pagination.total"
                  [pageSize]="pagination.pageSize"
                  [page]="pagination.page"
                  (pageChange)="pageChanged($event)"
                  boundaryLinks="true"
                >
                  <ng-template ngbPaginationFirst>{{ 'SHARED.PAGINATION.PAGINATION_FIRST' | translate }}</ng-template>
                  <ng-template ngbPaginationPrevious>{{
                    'SHARED.PAGINATION.PAGINATION_PREVIOUS' | translate
                  }}</ng-template>
                  <ng-template ngbPaginationNext>{{ 'SHARED.PAGINATION.PAGINATION_NEXT' | translate }}</ng-template>
                  <ng-template ngbPaginationLast>{{ 'SHARED.PAGINATION.PAGINATION_LAST' | translate }}</ng-template>
                </ngb-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- unpaid purchase invoices list ends -->
  </div>
  <div class="modal-footer">
    <div class="modal-actions">
      <button type="button" class="btn btn-outline-dark round" (click)="c(); closeModal()">
        <i class="fa fa-times"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.CLOSE' | translate }}</span>
      </button>
    </div>
  </div>
</ng-template>
<!-- unpaid purchase invoices search modal ends -->

<!-- no items template starts -->
<ng-template #noItems>
  <div class="col-md-12 alert alert-warning mt-3 text-center" role="alert">
    {{ 'SALES.INVOICES.SEARCH_INVOICE_UNPAID_NOT_FOUND' | translate }}
  </div>
</ng-template>
<!-- no items template ends -->

<!-- locations search starts -->
<app-locations-search [(visible)]="locationsListVisibility" (locations)="selectLocations($event)">
</app-locations-search>
<!-- locations search ends -->

<!-- customers search starts -->
<app-customers-search (customers)="selectCustomers($event)" [(visible)]="customersListVisibility">
</app-customers-search>
<!-- customers search ends -->
