import { Injectable } from '@angular/core';

import { of } from 'rxjs';
import { switchMap, map, catchError, tap, debounceTime } from 'rxjs/operators';
import { Effect, ofType, Actions } from '@ngrx/effects';

import { NotificationService, StatusCode, PAGINATION, TranslationService } from 'shared';
import { LocationsService } from 'stores/services';
import {
  LocationsActionType,
  SearchLocations,
  SearchLocationsFail,
  SearchLocationsSuccess,
  SearchOrganizationLocations,
  SearchOrganizationLocationsFail,
  SearchOrganizationLocationsSuccess,
  CreateLocation,
  CreateLocationSuccess,
  CreateLocationFail,
  UpdateLocation,
  UpdateLocationSuccess,
  UpdateLocationFail,
  DeleteLocation,
  DeleteLocationSuccess,
  DeleteLocationFail,
  FindLocation,
  FindLocationSuccess,
  FindLocationFail,
  UpdatePrimaryLogo,
  DeletePrimaryLogo,
  UpdateSecondaryLogo,
  DeleteSecondaryLogo,
  UpdateImageFooter,
  UpdateImageHeader,
  DeleteImageHeader,
  DeleteImageFooter,
} from 'stores/store/actions';

@Injectable()
export class LocationsEffects {
  constructor(
    private actions$: Actions,
    private locationsService: LocationsService,
    private notificationService: NotificationService,

    private translationService: TranslationService
  ) {}

  /* ========================= SEARCH_LOCATIONS =================================== */
  @Effect()
  search$ = this.actions$.pipe(
    ofType(LocationsActionType.SEARCH_LOCATIONS),
    debounceTime(300),
    switchMap(({ payload }: SearchLocations) =>
      this.locationsService
        .search(
          payload?.name ?? '',
          payload.regions,
          payload.sectors,
          payload.locationTypes,
          payload.page,
          PAGINATION.Locations
        )
        .pipe(
          map((response) => new SearchLocationsSuccess(response)),
          catchError((error) => of(new SearchLocationsFail(error)))
        )
    )
  );

  /* ========================= SEARCH_ORGANIZATION_LOCATIONS =================================== */
  @Effect()
  organizationLocationsSearch$ = this.actions$.pipe(
    ofType(LocationsActionType.SEARCH_ORGANIZATION_LOCATIONS),
    debounceTime(300),
    switchMap(({ payload }: SearchOrganizationLocations) =>
      this.locationsService
        .organizationLocationsSearch(
          payload?.name ?? '',
          payload.regions,
          payload.sectors,
          payload.locationTypes,
          payload.page,
          PAGINATION.OrganizationLocations
        )
        .pipe(
          map((response) => new SearchOrganizationLocationsSuccess(response)),
          catchError((error) => of(new SearchOrganizationLocationsFail(error)))
        )
    )
  );

  /* ========================= FIND_LOCATION =================================== */
  @Effect()
  find$ = this.actions$.pipe(
    ofType(LocationsActionType.FIND_LOCATION),
    switchMap((action: FindLocation) =>
      this.locationsService.findById(action.payload).pipe(
        map((response) => new FindLocationSuccess(response)),
        catchError((error) => of(new FindLocationFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  findFail$ = this.actions$.pipe(
    ofType(LocationsActionType.FIND_LOCATION_FAIL),
    tap((action: FindLocationFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(this.translationService.translate('STORES.LOCATIONS.LOCATION_NOT_FOUND'));
      }
    })
  );

  /* ========================= CREATE_LOCATION =================================== */
  @Effect()
  create$ = this.actions$.pipe(
    ofType(LocationsActionType.CREATE_LOCATION),
    switchMap((action: CreateLocation) =>
      this.locationsService.create(action.payload).pipe(
        map((response) => new CreateLocationSuccess(response)),
        catchError((error) => of(new CreateLocationFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  createSuccess$ = this.actions$.pipe(
    ofType(LocationsActionType.CREATE_LOCATION_SUCCESS),
    tap((action: CreateLocationSuccess) => {
      this.notificationService.success(this.translationService.translate('STORES.LOCATIONS.LOCATION_ADDED'));
    })
  );

  @Effect({ dispatch: false })
  createFail$ = this.actions$.pipe(
    ofType(LocationsActionType.CREATE_LOCATION_FAIL),
    tap((action: CreateLocationFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );

  /* ========================= UPDATE_LOCATION =================================== */
  @Effect()
  update$ = this.actions$.pipe(
    ofType(LocationsActionType.UPDATE_LOCATION),
    switchMap((action: UpdateLocation) =>
      this.locationsService.update(action.payload).pipe(
        map((response) => new UpdateLocationSuccess(response)),
        catchError((error) => of(new UpdateLocationFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  updateSuccess$ = this.actions$.pipe(
    ofType(LocationsActionType.UPDATE_LOCATION_SUCCESS),
    tap((action: UpdateLocationSuccess) => {
      this.notificationService.success(this.translationService.translate('STORES.LOCATIONS.LOCATION_UPDATED'));
    })
  );

  @Effect({ dispatch: false })
  updateFail$ = this.actions$.pipe(
    ofType(LocationsActionType.UPDATE_LOCATION_FAIL),
    tap((action: UpdateLocationFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(this.translationService.translate('STORES.LOCATIONS.LOCATION_NOT_FOUND'));
      } else if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );

  @Effect()
  updatePrimaryLogo$ = this.actions$.pipe(
    ofType(LocationsActionType.UPDATE_PRIMARY_LOGO),
    switchMap((action: UpdatePrimaryLogo) =>
      this.locationsService.updatePrimaryLogo(action.payload.id, action.payload.primaryLogo).pipe(
        map((response) => new UpdateLocationSuccess(response)),
        catchError((error) => of(new UpdateLocationFail(error)))
      )
    )
  );

  @Effect()
  updateImageHeader$ = this.actions$.pipe(
    ofType(LocationsActionType.UPDATE_IMAGE_HEADER),
    switchMap((action: UpdateImageHeader) =>
      this.locationsService.updateImageHeader(action.payload.id, action.payload.invoiceHeaderFilename).pipe(
        map((response) => new UpdateLocationSuccess(response)),
        catchError((error) => of(new UpdateLocationFail(error)))
      )
    )
  );

  @Effect()
  updateImageFooter$ = this.actions$.pipe(
    ofType(LocationsActionType.UPDATE_IMAGE_FOOTER),
    switchMap((action: UpdateImageFooter) =>
      this.locationsService.updateImageFooter(action.payload.id, action.payload.invoiceFooterFilename).pipe(
        map((response) => new UpdateLocationSuccess(response)),
        catchError((error) => of(new UpdateLocationFail(error)))
      )
    )
  );

  @Effect()
  deletePrimaryLogo$ = this.actions$.pipe(
    ofType(LocationsActionType.DELETE_PRIMARY_LOGO),
    switchMap((action: DeletePrimaryLogo) =>
      this.locationsService.deletePrimaryLogo(action.payload).pipe(
        map((response) => new UpdateLocationSuccess(response)),
        catchError((error) => of(new UpdateLocationFail(error)))
      )
    )
  );

  @Effect()
  deleteImageHeader$ = this.actions$.pipe(
    ofType(LocationsActionType.DELETE_IMAGE_HEADER),
    switchMap((action: DeleteImageHeader) =>
      this.locationsService.deleteImageHeader(action.payload).pipe(
        map((response) => new UpdateLocationSuccess(response)),
        catchError((error) => of(new UpdateLocationFail(error)))
      )
    )
  );

  @Effect()
  deleteImageFooter$ = this.actions$.pipe(
    ofType(LocationsActionType.DELETE_IMAGE_FOOTER),
    switchMap((action: DeleteImageFooter) =>
      this.locationsService.deleteImageFooter(action.payload).pipe(
        map((response) => new UpdateLocationSuccess(response)),
        catchError((error) => of(new UpdateLocationFail(error)))
      )
    )
  );

  @Effect()
  updateSecondaryLogo$ = this.actions$.pipe(
    ofType(LocationsActionType.UPDATE_SECONDARY_LOGO),
    switchMap((action: UpdateSecondaryLogo) =>
      this.locationsService.updateSecondaryLogo(action.payload.id, action.payload.secondaryLogo).pipe(
        map((response) => new UpdateLocationSuccess(response)),
        catchError((error) => of(new UpdateLocationFail(error)))
      )
    )
  );

  @Effect()
  deleteSecondaryLogo$ = this.actions$.pipe(
    ofType(LocationsActionType.DELETE_SECONDARY_LOGO),
    switchMap((action: DeleteSecondaryLogo) =>
      this.locationsService.deleteSecondaryLogo(action.payload).pipe(
        map((response) => new UpdateLocationSuccess(response)),
        catchError((error) => of(new UpdateLocationFail(error)))
      )
    )
  );

  /* ========================= DELETE_LOCATION =================================== */
  @Effect()
  delete$ = this.actions$.pipe(
    ofType(LocationsActionType.DELETE_LOCATION),
    switchMap((action: DeleteLocation) =>
      this.locationsService.delete(action.payload).pipe(
        map((response) => new DeleteLocationSuccess(response)),
        catchError((error) => of(new DeleteLocationFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  deleteSuccess$ = this.actions$.pipe(
    ofType(LocationsActionType.DELETE_LOCATION_SUCCESS),
    tap((action: DeleteLocationSuccess) => {
      this.notificationService.success(this.translationService.translate('STORES.LOCATIONS.LOCATION_DELETED'));
    })
  );

  @Effect({ dispatch: false })
  deleteFail$ = this.actions$.pipe(
    ofType(LocationsActionType.DELETE_LOCATION_FAIL),
    tap((action: DeleteLocationFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(this.translationService.translate('STORES.LOCATIONS.LOCATION_NOT_FOUND'));
      } else if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );
}
