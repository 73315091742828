<div [formGroup]="assetsForm.parent" class="row">
  <div class="col-md-12">
    <button
      type="button"
      class="btn btn-sm btn-raised btn-primary round"
      [ngbTooltip]="'SALES.CUSTOMER_ASSETS.CREATE_CUSTOMER_ASSET' | translate"
      placement="top-right"
      (click)="addAsset()"
    >
      <i class="fa fa-plus"></i>
    </button>
    <button
      type="button"
      class="btn btn-sm btn-raised btn-danger round mx-2"
      [ngbTooltip]="'SALES.CUSTOMER_ASSETS.DELETE_CUSTOMER_ASSET' | translate"
      (click)="resetAssets()"
    >
      <i class="fa fa-trash"></i>
    </button>
  </div>
  <div class="col-md-12">
    <div class="table-responsive">
      <table class="table text-center">
        <thead>
          <tr>
            <th scope="col"><i class="fa fa-list-ol"></i></th>
            <th scope="col">
              {{ 'SALES.CUSTOMER_ASSETS.CUSTOMER_ASSET_DATA.CUSTOMER_ASSET_DESCRIPTION' | translate }}
            </th>
            <th scope="col">{{ 'SALES.CUSTOMER_ASSETS.CUSTOMER_ASSET_DATA.CUSTOMER_ASSET_TYPE' | translate }}</th>
            <th scope="col">{{ 'SALES.CUSTOMER_ASSETS.CUSTOMER_ASSET_DATA.CUSTOMER_SERIAL_NUMBER' | translate }}</th>
            <th scope="col">{{ 'SALES.CUSTOMER_ASSETS.CUSTOMER_ASSET_DATA.ATTACHMENTS' | translate }}</th>
            <th scope="col">{{ 'SHARED.TOOLS' | translate }}</th>
          </tr>
        </thead>
        <tbody formArrayName="assets">
          <tr
            app-customer-asset-form-item
            *ngFor="let item of assetsForm.controls; let i = index"
            [assetGroup]="item"
            [index]="i"
            (removeChange)="remove(i)"
          ></tr>
        </tbody>
        <tfoot class="tfoot-light">
          <tr>
            <td [attr.colspan]="9">
              <i class="fa fa-bookmark c-primary"></i>
              <span>
                {{ assetsForm.controls.length | toNumber }}
                {{ 'SALES.CUSTOMER_ASSETS.CUSTOMER_ASSET_DATA.CUSTOMER_ASSET' | translate }}
              </span>
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  </div>
</div>

<!-- confirm delete all assets modal starts -->
<ng-template #deleteModalRef let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title text-danger" id="modal-basic-title">
      {{ 'SALES.CUSTOMER_ASSETS.DELETE_ALL_ASSETS_TITLE' | translate }}
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="d()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p class="text-danger">{{ 'SALES.CUSTOMER_ASSETS.DELETE_ALL_ASSETS_MESSAGE' | translate }}</p>
  </div>
  <div class="modal-footer">
    <div class="modal-actions">
      <button type="button" class="btn btn-raised btn-danger round" (click)="c(); resetAssets()" ngbAutofocus>
        <i class="fa fa-check"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.YES' | translate }}</span>
      </button>
      <button type="button" class="btn btn-outline-dark round" (click)="c()">
        <i class="fa fa-times"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.CANCEL' | translate }}</span>
      </button>
    </div>
  </div>
</ng-template>
<!-- confirm delete all assets modal ends -->
