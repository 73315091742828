import { Action } from '@ngrx/store';

import { ProjectLogType } from 'lookups/models';
import { AppHttpResponse } from 'shared';

/**
 * The Lookups-Module store  project log types action types.
 */
export enum ProjectLogTypeActionType {
  GET_ALL_PROJECT_LOG_TYPES = '[Lookups] [ProjectLogType] Get All Project Log Types',
  GET_ALL_PROJECT_LOG_TYPES_FAIL = '[Lookups] [ProjectLogType] Get All Project Log Types Fail',
  GET_ALL_PROJECT_LOG_TYPES_SUCCESS = '[Lookups] [ProjectLogType] Get All Project Log Types Success',
}

/**
 * Represents a store  project log type action.
 */
export class GetAllProjectLogTypes implements Action {
  /**
   * The type of the action.
   */
  readonly type = ProjectLogTypeActionType.GET_ALL_PROJECT_LOG_TYPES;

  /**
   * Fires a new  project log type action.
   */
  constructor() {}
}

/**
 * Represents a store  project log type fail action.
 */
export class GetAllProjectLogTypesFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = ProjectLogTypeActionType.GET_ALL_PROJECT_LOG_TYPES_FAIL;

  /**
   * Fires a new  project log type fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store  project log type success action.
 */
export class GetAllProjectLogTypesSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = ProjectLogTypeActionType.GET_ALL_PROJECT_LOG_TYPES_SUCCESS;

  /**
   * Fires a  project log type success action.
   * @param payload An object contains the list of  Cities.
   */
  constructor(public payload: AppHttpResponse<ProjectLogType[]>) {}
}

/**
 * Lookups-module  project log type action types.
 */
export type ProjectLogTypeActions = GetAllProjectLogTypes | GetAllProjectLogTypesFail | GetAllProjectLogTypesSuccess;
