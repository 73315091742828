<!-- project plans list starts -->
<div class="row">
  <div class="col-md-12">
    <div class="card-content">
      <div class="px-3">
        <div class="row">
          <app-project-plan-details [project]="project$ | async"></app-project-plan-details>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- project plans list ends -->
