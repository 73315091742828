import { createSelector } from '@ngrx/store';

import { BankAccountState, FinancesState } from 'finances/store/states';
import { selectFinancesState } from 'finances/store/selectors/feature.selectors';

/* Selectors */

/**
 * Gets the bank accounts state.
 */
const selectBankAccountState = createSelector(selectFinancesState, (state: FinancesState) => state.bankAccounts);

/**
 * Gets the list of loaded bank accounts.
 */
export const getBankAccounts = createSelector(selectBankAccountState, (state: BankAccountState) => state.bankAccounts);

/**
 * Gets the list of loaded bank account logs.
 */
export const getBankAccountLogs = createSelector(
  selectBankAccountState,
  (state: BankAccountState) => state.bankAccountLogsData
);

/**
 * Gets the list of loaded bank account logs cash receipt.
 */
export const getBankAccountLogsCashReceipt = createSelector(
  selectBankAccountState,
  (state: BankAccountState) => state.bankAccountLogsCashReceiptData
);

/**
 * Gets the bank accounts pagination info.
 */
export const getBankAccountsPaginationInfo = createSelector(
  selectBankAccountState,
  (state: BankAccountState) => state.paginationInfo
);

/**
 * Gets the bank account logs pagination info.
 */
export const getBankAccountLogsPaginationInfo = createSelector(
  selectBankAccountState,
  (state: BankAccountState) => state.bankAccountLogsPaginationInfo
);

/**
 * Gets the error that occurred in the state.
 */
export const getBankAccountsError = createSelector(selectBankAccountState, (state: BankAccountState) => state.error);

/**
 * Determines if there is a running search process.
 */
export const getBankAccountsSearching = createSelector(
  selectBankAccountState,
  (state: BankAccountState) => state.isSearching
);

/**
 * Determines if the last search process has been ended successfully.
 */
export const getBankAccountsSearchCompleted = createSelector(
  selectBankAccountState,
  (state: BankAccountState) => state.isSearchCompleted
);

/**
 * Determines if there is a running search-bank-account-logs process.
 */
export const getBankAccountLogsSearching = createSelector(
  selectBankAccountState,
  (state: BankAccountState) => state.isSearchingBankAccountLogs
);

/**
 * Determines if the last search-bank-account-logs process has been ended successfully.
 */
export const getBankAccountLogsSearchCompleted = createSelector(
  selectBankAccountState,
  (state: BankAccountState) => state.isSearchBankAccountLogsCompleted
);

/**
 * Gets the selected bank account.
 */
export const getSelectedBankAccount = createSelector(
  selectBankAccountState,
  (state: BankAccountState) => state.selectedBankAccount
);

/**
 * Gets the selected bank account log.
 */
export const getSelectedBankAccountLog = createSelector(
  selectBankAccountState,
  (state: BankAccountState) => state.selectedBankAccountLog
);

/**
 * Gets the selected bank account log cash receipt.
 */
export const getSelectedBankAccountLogCashReceipt = createSelector(
  selectBankAccountState,
  (state: BankAccountState) => state.selectedBankAccountLogCashReceipt
);

/**
 * Determines if there is a running find-bank account process.
 */
export const getBankAccountsFinding = createSelector(
  selectBankAccountState,
  (state: BankAccountState) => state.isFinding
);

/**
 * Determines if the last find-bank account process has been ended successfully.
 */
export const getBankAccountsFindCompleted = createSelector(
  selectBankAccountState,
  (state: BankAccountState) => state.isFindCompleted
);

/**
 * Determines if there is a running create process.
 */
export const getSelectedBankAccountCreating = createSelector(
  selectBankAccountState,
  (state: BankAccountState) => state.isCreating
);

/**
 * Determines if the last create process has been ended successfully.
 */
export const getSelectedBankAccountCreateCompleted = createSelector(
  selectBankAccountState,
  (state: BankAccountState) => state.isCreateCompleted
);

/**
 * Determines if there is a running create bank account log process.
 */
export const getSelectedBankAccountLogCreating = createSelector(
  selectBankAccountState,
  (state: BankAccountState) => state.isBankAccountLogCreating
);

/**
 * Determines if the last create bank account log process has been ended successfully.
 */
export const getSelectedBankAccountLogCreateCompleted = createSelector(
  selectBankAccountState,
  (state: BankAccountState) => state.isSearchBankAccountLogsCompleted
);

/**
 * Determines if there is a running create bank account log deposit by employee process.
 */
export const getSelectedBankAccountLogDepositByEmployeeCreating = createSelector(
  selectBankAccountState,
  (state: BankAccountState) => state.isBankAccountLogDepositByEmployeeCreating
);

/**
 * Determines if the last create bank account log deposit by employee process has been ended successfully.
 */
export const getSelectedBankAccountLogDepositByEmployeeCreateCompleted = createSelector(
  selectBankAccountState,
  (state: BankAccountState) => state.isBankAccountLogDepositByEmployeeCreateCompleted
);

/**
 * Determines if there is a running create bank account by withdraw from account process.
 */
export const getSelectedBankAccountLogByWithdrawFromAccountCreating = createSelector(
  selectBankAccountState,
  (state: BankAccountState) => state.isBankAccountLogByWithdrawFromAccountCreating
);

/**
 * Determines if the last create bank account by withdraw from account process has been ended successfully.
 */
export const getSelectedBankAccountLogByWithdrawFromAccountCreateCompleted = createSelector(
  selectBankAccountState,
  (state: BankAccountState) => state.isBankAccountLogByWithdrawFromAccountCreateCompleted
);

/**
 * Determines if there is a running update process.
 */
export const getSelectedBankAccountUpdating = createSelector(
  selectBankAccountState,
  (state: BankAccountState) => state.isUpdating
);

/**
 * Determines if the last update process has been ended successfully.
 */
export const getSelectedBankAccountUpdateCompleted = createSelector(
  selectBankAccountState,
  (state: BankAccountState) => state.isUpdateCompleted
);

/**
 * Determines if there is a running block process.
 */
export const getSelectedBankAccountBlocking = createSelector(
  selectBankAccountState,
  (state: BankAccountState) => state.isBlocking
);

/**
 * Determines if the last block process has been ended successfully.
 */
export const getSelectedBankAccountBlockCompleted = createSelector(
  selectBankAccountState,
  (state: BankAccountState) => state.isBlockCompleted
);

/**
 * Determines if there is a running activate process.
 */
export const getSelectedBankAccountActivating = createSelector(
  selectBankAccountState,
  (state: BankAccountState) => state.isActivating
);

/**
 * Determines if the last activate process has been ended successfully.
 */
export const getSelectedBankAccountActivateCompleted = createSelector(
  selectBankAccountState,
  (state: BankAccountState) => state.isActivateCompleted
);

/**
 * Determines if there is a running delete process.
 */
export const getSelectedBankAccountDeleting = createSelector(
  selectBankAccountState,
  (state: BankAccountState) => state.isDeleting
);

/**
 * Determines if the last delete process has been ended successfully.
 */
export const getSelectedBankAccountDeleteCompleted = createSelector(
  selectBankAccountState,
  (state: BankAccountState) => state.isDeleteCompleted
);

/**
 * Determines if there is a running create bank account money transfer process.
 */
export const getSelectedBankAccountLogMoneyTransferCreating = createSelector(
  selectBankAccountState,
  (state: BankAccountState) => state.isBankAccountLogMoneyTransferCreating
);

/**
 * Determines if the last create bank account money transfer process has been ended successfully.
 */
export const getSelectedBankAccountLogMoneyTransferCreateCompleted = createSelector(
  selectBankAccountState,
  (state: BankAccountState) => state.isBankAccountLogMoneyTransferCreateCompleted
);

/**
 * Determines if there is a running create bank account cash receipt process.
 */
export const getSelectedBankAccountLogCashReceiptCreating = createSelector(
  selectBankAccountState,
  (state: BankAccountState) => state.isBankAccountLogCashReceiptCreating
);

/**
 * Determines if the last create bank account cash receipt process has been ended successfully.
 */
export const getSelectedBankAccountLogCashReceiptCreateCompleted = createSelector(
  selectBankAccountState,
  (state: BankAccountState) => state.isBankAccountLogCashReceiptCreateCompleted
);

/**
 * Determines if there is a running create bank account settlement deed process.
 */
export const getSelectedBankAccountLogSettlementDeedCreating = createSelector(
  selectBankAccountState,
  (state: BankAccountState) => state.isBankAccountLogSettlementDeedCreating
);

/**
 * Determines if the last create bank account settlement deed process has been ended successfully.
 */
export const getSelectedBankAccountLogSettlementDeedCreateCompleted = createSelector(
  selectBankAccountState,
  (state: BankAccountState) => state.isBankAccountLogSettlementDeedCreateCompleted
);
