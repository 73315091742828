<!-- products search modal starts -->
<ng-template #modalRef let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title text-primary" id="modal-basic-title">{{ 'STORES.PRODUCTS.PRODUCTS' | translate }}</h4>
    <button type="button" class="close" aria-label="Close" (click)="d(); closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-md-12">
        <div class="row">
          <div class="col-md-12">
            <ul class="nav-tabs" ngbNav #nav="ngbNav" [(activeId)]="activePage" [destroyOnHide]="false">
              <li ngbNavItem="products">
                <a ngbNavLink>
                  <i class="fa fa-file-text" [class.success]="activePage == 'products'"></i>
                  {{ 'STORES.PRODUCTS.PRODUCTS_SEARCH' | translate }}
                </a>
                <ng-template ngbNavContent>
                  <div class="row">
                    <div class="col-md-12">
                      <form class="row" [formGroup]="searchForm" (ngSubmit)="search()">
                        <div class="form-group col">
                          <label for="description"
                            >{{ 'STORES.PRODUCTS.PRODUCT_DATA.PRODUCT_DESCRIPTION' | translate }}
                          </label>
                          <input
                            type="text"
                            id="description"
                            class="form-control round"
                            [placeholder]="'STORES.PRODUCTS.PRODUCT_DATA.PRODUCT_DESCRIPTION_PLACEHOLDER' | translate"
                            formControlName="description"
                            tabindex="0"
                            (input)="search($event)"
                            ngbAutofocus
                          />
                        </div>
                        <div class="form-group col">
                          <label for="classes">{{ 'STORES.PRODUCTS.PRODUCT_DATA.PRODUCTS_CLASSES' | translate }}</label>
                          <ng-select
                            class="round"
                            formControlName="classes"
                            [multiple]="true"
                            [closeOnSelect]="false"
                            labelForId="classes"
                            searchable="true"
                            [placeholder]="'STORES.PRODUCTS.PRODUCT_DATA.PRODUCTS_CLASSES_PLACEHOLDER' | translate"
                            [notFoundText]="'SHARED.SEARCH.SEARCH_NOT_FOUND' | translate"
                            (open)="classesListVisibility = true"
                          >
                            <ng-option *ngFor="let class of classes" [value]="class.id">
                              {{ class.name | localize: class.nameEn | async }}
                            </ng-option>
                          </ng-select>
                        </div>
                        <div class="form-group col">
                          <label for="locations">{{ 'STORES.PRODUCTS.PRODUCT_DATA.LOCATIONS' | translate }}</label>
                          <ng-select
                            class="round"
                            formControlName="locations"
                            [multiple]="true"
                            [closeOnSelect]="false"
                            labelForId="locations"
                            searchable="true"
                            [placeholder]="'STORES.PRODUCTS.PRODUCT_DATA.LOCATIONS_PLACEHOLDER' | translate"
                            [notFoundText]="'SHARED.SEARCH.SEARCH_NOT_FOUND' | translate"
                            (open)="locationsListVisibility = true"
                          >
                            <ng-option *ngFor="let location of locations" [value]="location.id">
                              {{ location.name | localize: location.nameEn | async }}
                            </ng-option>
                          </ng-select>
                        </div>
                        <div class="col-md-auto form-group d-flex">
                          <button
                            type="submit"
                            class="btn btn-raised btn-primary align-self-end round mx-1 mb-0"
                            [disabled]="isSearching$ | async"
                          >
                            <i class="fa fa-search"></i>
                          </button>
                          <button
                            *ngIf="[Claims.CREATE_PRODUCT] | authorize | async"
                            type="button"
                            class="btn btn-raised btn-success align-self-end round mx-1 mb-0"
                            (click)="createProductModalVisibility = true"
                          >
                            <i class="fa fa-plus"></i>
                            <span class="mx-1"> {{ 'SHARED.CONFIRM.ADD' | translate }} </span>
                          </button>
                        </div>
                      </form>
                    </div>
                    <div class="col-md-12" *ngIf="(products$ | async)?.length; else noItems">
                      <div class="row">
                        <div class="col-md-12 alert alert-light text-center" role="alert">
                          {{ 'SHARED.SEARCH.SEARCH_RESULTS' | translate }} ({{
                            (paginationInfo$ | async).total | toNumber
                          }})
                          {{ 'STORES.PRODUCTS.PRODUCT' | translate }}
                        </div>
                        <div class="col-md-12">
                          <div class="table-responsive">
                            <table class="table text-center">
                              <thead>
                                <tr>
                                  <th class="fit-width" *ngIf="!closeOnSelect">
                                    <a
                                      class="primary p-0"
                                      (click)="selectProduct(productsList)"
                                      [ngbTooltip]="'SHARED.SEARCH.SELECT_ALL' | translate"
                                    >
                                      <i class="fa fa-check-circle font-medium-3"></i>
                                    </a>
                                  </th>
                                  <th class="fit-width" *ngIf="closeOnSelect">
                                    <i class="fa fa-gear font-medium-3"></i>
                                  </th>
                                  <th scope="col">
                                    <i class="fa fa-barcode"></i
                                    ><span class="mx-1">{{
                                      'STORES.PRODUCTS.PRODUCT_DATA.ITEM_CODE' | translate
                                    }}</span>
                                  </th>
                                  <th scope="col">{{ 'STORES.PRODUCTS.PRODUCT_DATA.THE_PRODUCT' | translate }}</th>
                                  <th scope="col">
                                    <i class="fa fa-barcode"></i
                                    ><span class="mx-1">
                                      {{ 'STORES.PRODUCTS.PRODUCT_DATA.PRODUCT_BARCODE' | translate }}</span
                                    >
                                  </th>
                                  <th scope="col" *ngIf="showPurchasePrice">
                                    {{ 'STORES.PRODUCTS.PRODUCT_DATA.PURCHASE_PRICE' | translate }}
                                  </th>
                                  <th scope="col" *ngIf="showSalePrice">
                                    {{ 'STORES.PRODUCTS.PRODUCT_DATA.SALE_PRICE' | translate }}
                                  </th>
                                  <th scope="col">{{ 'STORES.PRODUCTS.PRODUCT_DATA.TAX' | translate }}</th>
                                  <th scope="col">{{ 'STORES.PRODUCTS.PRODUCT_DATA.PRODUCT_CLASS' | translate }}</th>
                                  <th scope="col">
                                    {{ 'STORES.PRODUCTS.PRODUCT_DATA.UNIT_OF_MEASURE' | translate }}
                                  </th>
                                  <th scope="col">{{ 'STORES.PRODUCTS.PRODUCT_DATA.HAS_EXPIRE_DATE' | translate }}</th>
                                  <th scope="col">{{ 'STORES.PRODUCTS.PRODUCT_DATA.SERVICE' | translate }}</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr *ngFor="let product of products$ | async; trackBy: trackItems">
                                  <td>
                                    <a
                                      class="primary p-0"
                                      (click)="selectProduct([product])"
                                      [ngbTooltip]="'STORES.PRODUCTS.PRODUCT_DATA.PRODUCT_SELECT' | translate"
                                    >
                                      <i class="fa fa-check-circle-o font-medium-3"></i>
                                    </a>
                                  </td>
                                  <td>{{ product.itemCode }}</td>
                                  <td>{{ product.description | localize: product.descriptionEn | async }}</td>
                                  <td>{{ product.barcode }}</td>
                                  <td *ngIf="showPurchasePrice">{{ product.purchasePrice | toDecimal }}</td>
                                  <td *ngIf="showSalePrice">{{ product.salePrice | toDecimal }}</td>
                                  <td>
                                    {{ product.tax }}
                                    <i class="fa fa-percent mr-1"></i>
                                  </td>
                                  <td>
                                    {{ product.productClass.name | localize: product.productClass.nameEn | async }}
                                  </td>
                                  <td>
                                    {{
                                      product.unitOfMeasure.unitOfMeasureClass.name
                                        | localize: product.unitOfMeasure.unitOfMeasureClass.nameEn
                                        | async
                                    }}
                                    - {{ product.unitOfMeasure.name | localize: product.unitOfMeasure.nameEn | async }}
                                  </td>
                                  <td>
                                    <app-status
                                      *ngIf="product.hasExpireDate"
                                      [status]="product.hasExpireDate"
                                      [activeText]="
                                        'STORES.PRODUCTS.PRODUCT_DATA.HAS_EXPIRE_DATE_PLACEHOLDER' | translate
                                      "
                                      [notActiveText]="'STORES.PRODUCTS.PRODUCT_DATA.NOT_EXPIRE_DATE' | translate"
                                    >
                                    </app-status>
                                  </td>
                                  <td>
                                    <app-status
                                      *ngIf="product.isService"
                                      [status]="product.isService"
                                      [activeText]="'STORES.PRODUCTS.PRODUCT_DATA.IS_SERVICE' | translate"
                                      [notActiveText]="'STORES.PRODUCTS.PRODUCT_DATA.HAS_STOCK' | translate"
                                    >
                                    </app-status>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                            <div class="d-flex justify-content-between p-2">
                              <ngb-pagination
                                *ngIf="paginationInfo$ | async; let pagination"
                                maxSize="5"
                                [collectionSize]="pagination.total"
                                [pageSize]="pagination.pageSize"
                                [page]="pagination.page"
                                (pageChange)="pageChanged($event)"
                                boundaryLinks="true"
                              >
                                <ng-template ngbPaginationFirst>{{
                                  'SHARED.PAGINATION.PAGINATION_FIRST' | translate
                                }}</ng-template>
                                <ng-template ngbPaginationPrevious>{{
                                  'SHARED.PAGINATION.PAGINATION_PREVIOUS' | translate
                                }}</ng-template>
                                <ng-template ngbPaginationNext>{{
                                  'SHARED.PAGINATION.PAGINATION_NEXT' | translate
                                }}</ng-template>
                                <ng-template ngbPaginationLast>{{
                                  'SHARED.PAGINATION.PAGINATION_LAST' | translate
                                }}</ng-template>
                              </ngb-pagination>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </li>
              <li *ngIf="!showProductStockQuery" ngbNavItem="products-stock">
                <a ngbNavLink>
                  <i class="fa fa-info" [class.success]="activePage == 'products-stock'"></i>
                  {{ 'STORES.PRODUCTS.PRODUCT_STOCK_QUERY_PAGE_TITLE' | translate }}
                </a>
                <ng-template ngbNavContent>
                  <div class="row">
                    <div class="col-md-12">
                      <form class="row" [formGroup]="getStockForm" (ngSubmit)="getStock()">
                        <div class="form-group col">
                          <input
                            type="text"
                            class="form-control round"
                            [placeholder]="
                              'STORES.PRODUCTS.PRODUCT_DATA.PRODUCT_DESCRIPTION_SEARCH_PLACEHOLDER' | translate
                            "
                            formControlName="productId"
                            min="0"
                            tabindex="0"
                            ngbAutofocus
                          />
                        </div>
                        <div class="form-group col-md-auto">
                          <button type="submit" class="btn btn-raised btn-primary round">
                            <i class="fa fa-search"></i>
                          </button>
                        </div>
                      </form>
                    </div>
                    <div class="col-md-12" *ngIf="productStock$ | async; else noStockItems">
                      <div class="row" *ngIf="productStock$ | async; let productStock">
                        <div class="col-md-12">
                          <div class="table-responsive">
                            <table class="table text-center">
                              <thead>
                                <tr>
                                  <th scope="col">
                                    {{ 'STORES.PRODUCTS.PRODUCT_DATA.THE_PRODUCT' | translate }}
                                  </th>
                                  <th scope="col">
                                    {{ 'STORES.PRODUCTS.PRODUCT_DATA.PRODUCT_TOTAL' | translate }}
                                  </th>
                                  <th scope="col">{{ 'STORES.PRODUCTS.PRODUCT_DATA.UNIT_OF_MEASURE' | translate }}</th>
                                  <th scope="col">
                                    {{ 'STORES.PRODUCTS.PRODUCT_DATA.VALUE_TOTAL' | translate }}
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr *ngIf="productStock$ | async; let productStock">
                                  <th>
                                    {{
                                      productStock.product.description
                                        | localize: productStock.product.descriptionEn
                                        | async
                                    }}
                                  </th>
                                  <td>{{ getProductTotalStock(productStock.stocks) | toDecimal }}</td>
                                  <td>
                                    {{
                                      productStock.product.unitOfMeasure.name
                                        | localize: productStock.product.unitOfMeasure.nameEn
                                        | async
                                    }}
                                  </td>
                                  <th>{{ getProductTotalStockValue(productStock.stocks) | toDecimal }}</th>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <div class="col-md-12 alert alert-light text-center" role="alert">
                          <span class="font-weight-bold">{{ 'STORES.PRODUCTS.PRODUCT_DATA.BALANCE' | translate }}</span>
                        </div>
                        <div class="col-md-12">
                          <div class="table-responsive">
                            <table class="table text-center">
                              <thead>
                                <tr>
                                  <th scope="col">{{ 'STORES.PRODUCTS.PRODUCT_DATA.LOCATION_ADDRESS' | translate }}</th>
                                  <th *ngIf="productStock.product.hasSubProducts" scope="col">
                                    {{ 'STORES.PRODUCTS.PRODUCT_DATA.THE_SUB_PRODUCT' | translate }}
                                  </th>
                                  <th scope="col">{{ 'STORES.PRODUCTS.PRODUCT_DATA.QUANTITY' | translate }}</th>
                                  <th scope="col">{{ 'STORES.PRODUCTS.PRODUCT_DATA.UNIT_OF_MEASURE' | translate }}</th>
                                  <th scope="col">{{ 'STORES.PRODUCTS.PRODUCT_DATA.VALUE' | translate }}</th>
                                  <th scope="col">{{ 'STORES.PRODUCTS.PRODUCT_DATA.VALUE_TOTAL' | translate }}</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr *ngFor="let stock of productStock.stocks">
                                  <th scope="row">
                                    {{ stock.location.name | localize: stock.location.nameEn | async }}
                                  </th>
                                  <td *ngIf="productStock.product.hasSubProducts">
                                    <div *ngIf="stock.subProduct" class="mt-1 d-block">
                                      <app-sub-product-info [subProduct]="stock.subProduct"></app-sub-product-info>
                                    </div>
                                  </td>
                                  <td>{{ stock.quantity | toDecimal }}</td>
                                  <td>
                                    {{
                                      productStock.product.unitOfMeasure.name
                                        | localize: productStock.product.unitOfMeasure.nameEn
                                        | async
                                    }}
                                  </td>
                                  <td>{{ stock.value | toDecimal }}</td>
                                  <td>{{ stock.total | toDecimal }}</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </li>
            </ul>
            <div [ngbNavOutlet]="nav" class="mt-2"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="modal-actions">
      <button type="button" class="btn btn-outline-dark round" (click)="c(); closeModal()">
        <i class="fa fa-times"></i>
        <span class="mx-1"> {{ 'SHARED.CONFIRM.CLOSE' | translate }} </span>
      </button>
    </div>
  </div>
</ng-template>
<!-- products search modal ends -->

<!-- no items template starts -->
<ng-template #noItems>
  <div class="col-md-12 alert alert-warning mt-3 text-center" role="alert">
    {{ 'STORES.PRODUCTS.NO_SEARCH_RESULT_PRODUCT' | translate }}
  </div>
</ng-template>
<!-- no items template ends -->

<!-- no items template starts -->
<ng-template #noStockItems>
  <div class="col-md-12 alert alert-warning mt-3 text-center" role="alert">
    {{ 'STORES.PRODUCTS.NO_SEARCH_RESULT' | translate }}
  </div>
</ng-template>
<!-- no items template ends -->

<!-- create product in modal starts -->
<app-create-product-in-modal [(visible)]="createProductModalVisibility"></app-create-product-in-modal>
<!-- create product in modal ends -->

<!-- product classes search starts -->
<app-product-classes-search (productClasses)="selectClasses($event)" [(visible)]="classesListVisibility">
</app-product-classes-search>
<!-- product classes search ends -->

<!-- locations search starts -->
<app-locations-search [(visible)]="locationsListVisibility" (locations)="selectLocations($event)">
</app-locations-search>
<!-- locations search ends -->
