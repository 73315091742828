<ng-container [formGroup]="journalLineGroup">
  <th scope="row">{{ index + 1 }}</th>
  <td *ngIf="journalLineGroup.value?.disableAccountChange">
    {{ journalLineGroup.value?.account.description }}
  </td>
  <td *ngIf="!journalLineGroup.value?.disableAccountChange">
    <ng-select
      class="round"
      formControlName="accountId"
      labelForId="accountId"
      searchable="true"
      [placeholder]="'FINANCES.JOURNALS.JOURNAL_DATA.ACCOUNT_PLACEHOLDER' | translate"
      [notFoundText]="'SHARED.SEARCH.SEARCH_NOT_FOUND' | translate"
      (open)="accountsListVisibility = true"
    >
      <ng-option *ngFor="let account of accountsList" [value]="account.id">
        {{ account.description }}
      </ng-option>
    </ng-select>
  </td>
  <td class="min-width-9" *ngIf="journalLineGroup.value?.disableDebitAndCreditValueChange">
    {{ journalLineGroup.value?.debit | toDecimal }}
  </td>
  <td class="min-width-9" *ngIf="!journalLineGroup.value?.disableDebitAndCreditValueChange">
    <input
      id="debit"
      type="text"
      [mask]="DECIMAL_MASK"
      thousandSeparator=","
      minLength="0"
      autocomplete="off"
      class="form-control round"
      [placeholder]="'FINANCES.JOURNALS.JOURNAL_DATA.DEBIT_PLACEHOLDER' | translate"
      formControlName="debit"
    />
  </td>
  <td class="min-width-9" *ngIf="journalLineGroup.value?.disableDebitAndCreditValueChange">
    {{ journalLineGroup.value?.credit | toDecimal }}
  </td>
  <td class="min-width-9" *ngIf="!journalLineGroup.value?.disableDebitAndCreditValueChange">
    <input
      id="credit"
      type="text"
      [mask]="DECIMAL_MASK"
      thousandSeparator=","
      minLength="0"
      autocomplete="off"
      class="form-control round"
      [placeholder]="'FINANCES.JOURNALS.JOURNAL_DATA.CREDIT_PLACEHOLDER' | translate"
      formControlName="credit"
    />
  </td>
  <td *ngIf="!isGeneralCostCenter">
    <ng-select
      class="round"
      formControlName="costCenterId"
      labelForId="costCenterId"
      searchable="true"
      [placeholder]="'FINANCES.JOURNALS.JOURNAL_DATA.COST_CENTER_PLACEHOLDER' | translate"
      [notFoundText]="'SHARED.SEARCH.SEARCH_NOT_FOUND' | translate"
      (open)="costCentersListVisibility = true"
    >
      <ng-option *ngFor="let costCenter of costCentersList" [value]="costCenter.id">
        {{ costCenter.description | localize: costCenter.descriptionEn | async }}
      </ng-option>
    </ng-select>
  </td>
  <td class="min-width-9" *ngIf="isGeneralCostCenter">
    <input
      id="costCenterId"
      type="text"
      readonly
      autocomplete="off"
      class="form-control round"
      [placeholder]="'FINANCES.JOURNALS.JOURNAL_DATA.COST_CENTER_PLACEHOLDER' | translate"
      [value]="generalCostCenterData?.description | localize: generalCostCenterData?.descriptionEn | async"
    />
  </td>
  <td class="min-width-10" *ngIf="showSaleInvoiceNumberColumn">
    {{ journalLineGroup.value.saleInvoiceId }}
  </td>
  <td class="min-width-10" *ngIf="showPurchaseInvoiceNumberColumn">
    {{ journalLineGroup.value.purchaseInvoiceId }}
  </td>
  <td class="min-width-15" *ngIf="journalLineGroup.value?.disableNotesChange">
    {{ journalLineGroup.value.notes }}
  </td>
  <td class="min-width-15" *ngIf="!journalLineGroup.value?.disableNotesChange">
    <textarea
      type="text"
      autocomplete="off"
      class="form-control round"
      [placeholder]="'FINANCES.JOURNALS.JOURNAL_DATA.NOTES_PLACEHOLDER' | translate"
      maxlength="200"
      formControlName="notes"
      rows="1"
    >
    </textarea>
  </td>
  <td *ngIf="allowRemoveLine">
    <a class="danger p-0" [ngbTooltip]="'SHARED.CONFIRM.DELETE' | translate" (click)="remove()">
      <i class="fa fa-close font-medium-3 mr-2"></i>
    </a>
  </td>
</ng-container>

<!-- accounts search starts -->
<app-accounts-search
  [closeOnSelect]="true"
  [onlyNonParent]="true"
  (accounts)="selectAccount($event)"
  [(visible)]="accountsListVisibility"
  [restrictedPrimaryAccountKey]="journalLineGroup.value?.restrictedPrimaryAccountKey"
>
</app-accounts-search>
<!-- accounts search ends -->

<!-- cost centers search starts -->
<app-cost-centers-search
  [closeOnSelect]="true"
  [(visible)]="costCentersListVisibility"
  (costCenters)="selectCostCenters($event)"
>
</app-cost-centers-search>
<!-- cost centers search ends -->
