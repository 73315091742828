import { Action } from '@ngrx/store';

import { Project } from 'projects-management/models';
import { AppHttpResponse } from 'shared';

/**
 * The AppSearch-Module store projects action types.
 */
export enum ProjectsActionType {
  SEARCH_PROJECTS = '[AppSearch] [Projects] Search Projects',
  SEARCH_PROJECTS_FAIL = '[AppSearch] [Projects] Search Projects Fail',
  SEARCH_PROJECTS_SUCCESS = '[AppSearch] [Projects] Search Projects Success',
}

/**
 * Represents a store projects search action.
 */
export class SearchProjects implements Action {
  /**
   * The type of the action.
   */
  readonly type = ProjectsActionType.SEARCH_PROJECTS;

  /**
   * Fires a new projects search action.
   * @param payload the search parameters,
   * if omitted, all projects will be loaded.
   */
  constructor(
    public payload?: {
      description: string;
      locations: number[];
      projectStatuses: number[];
      customers: number[];
      page: number;
    }
  ) {}
}

/**
 * Represents a store projects search-fail action.
 */
export class SearchProjectsFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = ProjectsActionType.SEARCH_PROJECTS_FAIL;

  /**
   * Fires a new projects search-fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store search-success action.
 */
export class SearchProjectsSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = ProjectsActionType.SEARCH_PROJECTS_SUCCESS;

  /**
   * Fires a new projects search-success action.
   * @param payload An object contains the list of projects that matches the search criteria
   * in addition to the pagination info.
   */
  constructor(public payload: AppHttpResponse<Project[]>) {}
}

/**
 * Stores-Module projects action types.
 */
export type ProjectsActions = SearchProjects | SearchProjectsFail | SearchProjectsSuccess;
