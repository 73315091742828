import { Component, Input } from '@angular/core';

import { Decimal } from 'decimal.js';

import { PurchaseInvoice, PurchaseInvoiceProduct } from 'purchases/models';

@Component({
  selector: 'app-journal-purchase-invoice-details',
  templateUrl: './journal-purchase-invoice-details.component.html',
  styles: [],
})
export class JournalPurchaseInvoiceDetailsComponent {
  /**
   * Sets the current viewed journal purchase invoice.
   */
  @Input() purchaseInvoice: PurchaseInvoice;

  /**
   * Gets the total quantity of invoice products.
   */
  productsQuantity(purchaseInvoiceProducts: PurchaseInvoiceProduct[]): number {
    return Decimal.sum(0, ...purchaseInvoiceProducts.map((product) => product.quantity ?? 0)).toNumber();
  }
}
