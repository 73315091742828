<ng-container [formGroup]="lineGroup">
  <th scope="row">{{ index + 1 }}</th>
  <td class="min-width-20">
    <textarea
      name="description"
      autocomplete="off"
      class="form-control round"
      [placeholder]="
        'FINANCES.PETTY_CASH_REFUND_REQUESTS.PETTY_CASH_REFUND_REQUEST_DATA.DESCRIPTION_PLACEHOLDER' | translate
      "
      maxlength="200"
      formControlName="description"
    ></textarea>
  </td>
  <td class="min-width-9">
    <ng-select
      class="round"
      formControlName="pettyCashRefundRequestTypeId"
      searchable="true"
      [placeholder]="
        'FINANCES.PETTY_CASH_REFUND_REQUESTS.PETTY_CASH_REFUND_REQUEST_DATA.PETTY_CASH_REFUND_REQUEST_TYPE_PLACEHOLDER'
          | translate
      "
      [notFoundText]="'SHARED.SEARCH.SEARCH_NOT_FOUND' | translate"
      dropdownPosition="bottom"
      (open)="dropDownIsOpened.emit(true)"
      (close)="dropDownIsOpened.emit(false)"
    >
      <ng-option *ngFor="let type of types" [value]="type.id">
        {{ type.name | localize: type.nameEn | async }}
      </ng-option>
    </ng-select>
  </td>
  <td class="min-width-9">
    <input
      type="text"
      id="vendorName"
      class="form-control round"
      [placeholder]="
        'FINANCES.PETTY_CASH_REFUND_REQUESTS.PETTY_CASH_REFUND_REQUEST_DATA.VENDOR_PLACEHOLDER' | translate
      "
      maxlength="50"
      formControlName="vendorName"
    />
  </td>
  <td class="min-width-9">
    <input
      type="text"
      thousandSeparator=","
      minLength="0"
      maxLength="100"
      id="value"
      class="form-control round"
      [placeholder]="
        'FINANCES.PETTY_CASH_REFUND_REQUESTS.PETTY_CASH_REFUND_REQUEST_DATA.INVOICE_VALUE_PLACEHOLDER' | translate
      "
      formControlName="value"
    />
  </td>
  <td class="min-width-9">
    <ng-select
      class="round"
      formControlName="tax"
      searchable="true"
      [placeholder]="'FINANCES.PETTY_CASH_REFUND_REQUESTS.PETTY_CASH_REFUND_REQUEST_DATA.TAX_PLACEHOLDER' | translate"
      [notFoundText]="'SHARED.SEARCH.SEARCH_NOT_FOUND' | translate"
      dropdownPosition="bottom"
      (open)="dropDownIsOpened.emit(true)"
      (close)="dropDownIsOpened.emit(false)"
    >
      <ng-option *ngFor="let tax of taxes" [value]="tax.value">
        {{ tax.name | localize: tax.nameEn | async }}
      </ng-option>
    </ng-select>
  </td>
  <td class="min-width-9">
    <input
      type="text"
      id="lineTotal"
      name="lineTotal"
      class="form-control round"
      [value]="(lineTotal | toDecimal) || '0.0'"
      readonly
    />
  </td>
  <td class="min-width-11">
    <div class="input-group round">
      <input
        class="form-control"
        formControlName="invoiceDate"
        [placeholder]="'SHARED.SEARCH.DATE_PLACEHOLDER' | translate"
        placement="bottom"
        ngbDatepicker
        #invoiceDate="ngbDatepicker"
        (closed)="dropDownIsOpened.emit(false)"
      />
      <div class="input-group-append">
        <span
          class="input-group-text gradient-light-blue-indigo"
          style="cursor: pointer;"
          (click)="dropDownIsOpened.emit(true); invoiceDate.toggle()"
        >
          <i class="fa fa-calendar"></i>
        </span>
      </div>
    </div>
  </td>
  <td>
    <a
      class="info p-0"
      (click)="openModal(addAttachmentsModalRef)"
      [ngbTooltip]="'FINANCES.PETTY_CASH_REFUND_REQUESTS.PETTY_CASH_REFUND_REQUEST_DATA.ADD_ATTACHMENTS' | translate"
    >
      <i class="fa fa-paperclip font-medium-3 mr-2"></i>
      <span *ngIf="attachmentsForm.controls.length">{{ attachmentsForm.controls.length }}</span>
    </a>
  </td>
  <td>
    <a class="danger p-0" [ngbTooltip]="'SHARED.CONFIRM.DELETE' | translate" (click)="remove()">
      <i class="fa fa-close font-medium-3 mr-2"></i>
    </a>
  </td>
</ng-container>

<!-- add attachments modal starts -->
<ng-template #addAttachmentsModalRef [formGroup]="lineGroup" let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title text-info" id="modal-basic-title">
      {{ 'FINANCES.PETTY_CASH_REFUND_REQUESTS.PETTY_CASH_REFUND_REQUEST_DATA.ATTACHMENTS' | translate }}
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="d()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="card-content">
        <div class="px-3">
          <div class="row">
            <div class="form-group col-md-12">
              <!-- request attachments starts -->
              <div class="card-content">
                <div class="px-3">
                  <app-files-upload formArrayName="attachments" [filesForm]="attachmentsForm"></app-files-upload>
                </div>
              </div>
              <!-- request attachments ends -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="modal-actions">
      <button type="button" class="btn btn-raised btn-primary round" (click)="c()" ngbAutoFocus>
        <i class="fa fa-times"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.CLOSE' | translate }}</span>
      </button>
    </div>
  </div>
</ng-template>
<!-- add attachments modal ends -->
