import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription, Observable } from 'rxjs';
import { skip, tap } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { Decimal } from 'decimal.js';

import * as fromSettingsStore from 'settings/store';
import * as fromPrintingStore from 'printing/store';
import * as fromSalesStore from 'sales/store';
import * as fromAuthStore from 'auth/store';
import {
  PageInfo,
  NotificationMessage,
  CustomValidators,
  NotificationService,
  TranslationService,
  PrintService,
} from 'shared';
import { Customer, CreateSalesInvoiceProductInput, SaleInvoice } from 'sales/models';
import { Tax } from 'settings/models';
import { Location, ProductsFormItem } from 'stores/models';
import { CanComponentDeactivate } from 'auth/models';
import { ProductsFormUtil } from 'stores';

@Component({
  selector: 'app-create-opening-balance-invoice',
  templateUrl: './create-opening-balance-invoice.component.html',
  styles: [],
})
export class CreateOpeningBalanceInvoiceComponent implements OnInit, OnDestroy, CanComponentDeactivate {
  /**
   * The confirm opening balance sales invoice modal template reference.
   */
  @ViewChild('confirmSaleInvoiceModalRef') confirmSaleInvoiceModalRef: ElementRef<any>;

  /**
   * The notes modal template reference.
   */
  @ViewChild('notesModalRef') notesModalRef: ElementRef<any>;

  /**
   * The reset modal template reference.
   */
  @ViewChild('resetModalRef') resetModalRef: ElementRef<any>;

  /**
   * Gets or sets the information about the current page.
   */
  pageInfo: PageInfo = {
    title: 'SALES.INVOICES.CREATE_OPENING_BALANCE_INVOICE_PAGE_TITLE',
    icon: 'fa fa-cart-plus',
    subTitle: 'SALES.INVOICES.OPINING_BALANCE_INVOICE_SUB_TITLE',
    subTitleNeedsTranslation: true,
  };

  /**
   * Sets the id of the current invoice.
   */
  invoiceId: number;

  /**
   * Shows or hide the locations list.
   */
  locationsListVisibility = false;

  /**
   * The list of active locations.
   */
  locations: Location[] = [];

  /**
   * The list of active customers.
   */
  customers: Customer[] = [];

  /**
   * The list of taxes.
   */
  taxes$: Observable<Tax[]>;

  /**
   * Gets the sales -invoice.
   */
  salesInvoice: Observable<SaleInvoice>;

  /**
   * The create opening-balance sales invoice form.
   */
  form: FormGroup;

  /**
   * Indicates whether there is a create-invoice process is running or not.
   */
  isCreating$: Observable<boolean>;

  /**
   * Add new product form items to the products form.
   */
  newProducts: ProductsFormItem[];

  /**
   * Shows or hide the customers list.
   */
  customersListVisibility = false;

  /**
   * The set of subscriptions on this components,
   * these subscriptions must be unsubscribed before this component got destroyed.
   */
  subscriptions = new Subscription();

  /**
   * Gets the products form-array.
   */
  get productsForm(): FormArray {
    return this.form?.controls.products as FormArray;
  }

  /**
   * Gets the attachments form-array.
   */
  get attachmentsForm(): FormArray {
    return this.form?.controls.attachments as FormArray;
  }

  /**
   * Gets the products total without discount and tax.
   */
  get productsTotalWithoutDiscountAndTax(): number {
    return ProductsFormUtil.productsTotalWithoutDiscountAndTax(this.productsForm.controls.map((item) => item.value));
  }

  /**
   * Gets the sum of discount values.
   */
  get productsDiscount(): number {
    return ProductsFormUtil.productsDiscount(this.productsForm.controls.map((item) => item.value));
  }

  /**
   * Gets the sum of selected products taxes.
   */
  get productsTax(): number {
    return ProductsFormUtil.productsTax(this.productsForm.controls.map((item) => item.value));
  }

  /**
   * Gets net value of the invoice.
   */
  get invoiceNet(): number {
    return ProductsFormUtil.invoiceNet(this.productsForm.controls.map((item) => item.value));
  }

  constructor(
    private modalService: NgbModal,
    private notificationService: NotificationService,
    private translationService: TranslationService,
    private printService: PrintService,
    private printingStore$: Store<fromPrintingStore.PrintingState>,
    private salesStore$: Store<fromSalesStore.SalesState>,
    private settingsStore$: Store<fromSettingsStore.SettingsState>,
    private authStore$: Store<fromAuthStore.AuthState>
  ) {}

  ngOnInit(): void {
    this.init();
  }

  /**
   * Destroy component data
   */
  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  /**
   * Initialize component data.
   */
  init() {
    this.initForm();

    /**
     * Print the transaction once it is ready.
     */
    this.subscriptions.add(
      this.printingStore$
        .pipe(
          select(fromPrintingStore.getSaleInvoicePrint),
          skip(1),
          tap((report) => {
            if (report) {
              this.printService.printPDF(report.pdfUrl, (error, request) => {
                this.notificationService.error(this.translationService.translate('REPORTS.FAILED_TO_PRINT'));
              });
            }
          })
        )
        .subscribe()
    );

    /**
     * Subscribe to the selected sale-invoice created to print the newly created invoice once its created.
     */
    this.subscriptions.add(
      this.salesStore$
        .pipe(
          select(fromSalesStore.getSelectedSaleInvoice),
          skip(1),
          tap((invoice) => {
            if (invoice) {
              this.print(invoice.id);
              this.invoiceId = invoice.id;
            }
          })
        )
        .subscribe()
    );

    /**
     * reset form on creation complete
     */
    this.subscriptions.add(
      this.salesStore$
        .pipe(
          select(fromSalesStore.getSelectedSaleInvoiceCreateCompleted),
          skip(1),
          tap((created) => {
            if (created) {
              this.form.markAsUntouched();
              this.form.disable();
            }
          })
        )
        .subscribe()
    );

    /* Subscribe to selected location to select it whenever it changed. */
    this.subscriptions.add(
      this.authStore$
        .pipe(
          select(fromAuthStore.getSessionUserDefaultLocation),
          tap((location) => {
            if (location) {
              this.selectLocation([location]);
            }
          })
        )
        .subscribe()
    );

    /** Loads data. */
    this.isCreating$ = this.salesStore$.pipe(select(fromSalesStore.getSelectedSaleInvoiceCreating));

    let isManualSearchTriggeredBeforeForTaxes = false;
    this.taxes$ = this.settingsStore$.pipe(
      select(fromSettingsStore.getTaxes),
      tap((taxes) => {
        if (!isManualSearchTriggeredBeforeForTaxes && !taxes.length) {
          isManualSearchTriggeredBeforeForTaxes = true;
          this.settingsStore$.dispatch(new fromSettingsStore.SearchTaxes({ name: '', page: 1 }));
        }
      })
    );
  }

  /**
   * Initialize form and add validators.
   */
  initForm() {
    /*
     * opening-balance sales invoice form
     */
    this.form = new FormGroup({
      locationId: new FormControl(null, Validators.required),
      customerId: new FormControl(null, Validators.required),
      products: new FormArray([], CustomValidators.arrayItems(1)),
      transactionDate: new FormControl(null),
      dateOfSupply: new FormControl(null),
      attachments: new FormArray([]),
      notes: new FormControl('', [Validators.minLength(0), Validators.maxLength(200)]),
    });
    this.addBlankAttachment();
  }

  /**
   * Submits the form.
   */
  submit() {
    /**
     * opening-balance sales invoice form.
     */
    const errorMessage = new NotificationMessage();

    if (this.form.invalid) {
      if (this.form.get('locationId').invalid) {
        errorMessage.title = this.translationService.translate(
          'SALES.INVOICES.SALE_INVOICE_DATA_VALIDATION.SALE_INVOICE_LOCATION_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate(
            'SALES.INVOICES.SALE_INVOICE_DATA_VALIDATION.SALE_INVOICE_LOCATION_IS_REQUIRED'
          ),
        ];
      } else if (this.form.get('customerId').invalid) {
        errorMessage.title = this.translationService.translate(
          'SALES.INVOICES.SALE_INVOICE_DATA_VALIDATION.CUSTOMER_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate('SALES.INVOICES.SALE_INVOICE_DATA_VALIDATION.CUSTOMER_IS_REQUIRED'),
        ];
      } else if (this.form.get('products').invalid) {
        /**
         * Check if products count = 0.
         */
        if (!this.productsForm.controls.length) {
          errorMessage.title = this.translationService.translate(
            'SALES.INVOICES.SALE_INVOICE_DATA_VALIDATION.PRODUCTS_ERROR'
          );
          errorMessage.body = [
            this.translationService.translate('SALES.INVOICES.SALE_INVOICE_DATA_VALIDATION.PRODUCTS_LENGTH_ERROR'),
          ];
        } else {
          /**
           * Check if some of products has errors.
           */
          for (let index = 0; index < this.productsForm.controls.length; index++) {
            const item = this.productsForm.controls[index];

            if (item.valid) {
              continue;
            }

            errorMessage.title = this.translationService.translate(
              'SALES.INVOICES.SALE_INVOICE_DATA_VALIDATION.PRODUCT_NUMBER_ERROR',
              { productNumber: index + 1 }
            );
            errorMessage.body = [];

            if (item.get('description').invalid) {
              errorMessage.body.push(
                this.translationService.translate('SALES.INVOICES.SALE_INVOICE_DATA_VALIDATION.DESCRIPTION_IS_REQUIRED')
              );
            }

            if (item.get('subProductId').invalid) {
              errorMessage.body.push(
                this.translationService.translate('SALES.INVOICES.SALE_INVOICE_DATA_VALIDATION.SUB_PRODUCT_ERROR')
              );
            }

            if (item.get('quantity').invalid) {
              errorMessage.body.push(
                this.translationService.translate('SALES.INVOICES.SALE_INVOICE_DATA_VALIDATION.PRODUCT_QUANTITY_ERROR')
              );
            }

            if (item.get('unitOfMeasureId').invalid) {
              errorMessage.body.push(
                this.translationService.translate(
                  'SALES.INVOICES.SALE_INVOICE_DATA_VALIDATION.UNIT_OF_MEASURE_IS_REQUIRED'
                )
              );
            }

            if (item.get('value').invalid) {
              errorMessage.body.push(
                this.translationService.translate('SALES.INVOICES.SALE_INVOICE_DATA_VALIDATION.PRODUCT_VALUE_ERROR')
              );
            }

            if (item.get('tax').invalid) {
              errorMessage.body.push(
                this.translationService.translate('SALES.INVOICES.SALE_INVOICE_DATA_VALIDATION.TAX_ERROR')
              );
            }
            if (item.get('discount').invalid) {
              errorMessage.title = this.translationService.translate(
                'SALES.INVOICES.SALE_INVOICE_DATA_VALIDATION.DISCOUNT_INPUT_ERROR'
              );
              errorMessage.body = [
                this.translationService.translate(
                  'SALES.INVOICES.SALE_INVOICE_DATA_VALIDATION.DISCOUNT_INPUT_LENGTH_ERROR'
                ),
              ];
            }

            if (item.get('discountPercent').invalid) {
              errorMessage.title = this.translationService.translate(
                'SALES.INVOICES.SALE_INVOICE_DATA_VALIDATION.DISCOUNT_PERCENT_INPUT_ERROR'
              );
              errorMessage.body = [
                this.translationService.translate(
                  'SALES.INVOICES.SALE_INVOICE_DATA_VALIDATION.DISCOUNT_PERCENT_INPUT_LENGTH_ERROR'
                ),
              ];
            }

            break;
          }
        }
      }

      const products: ProductsFormItem[] = this.productsForm.value;

      if (products.some((product) => new Decimal(ProductsFormUtil.productValueAfterDiscount(product)).lt(0))) {
        errorMessage.title = this.translationService.translate(
          'SALES.INVOICES.SALE_INVOICE_DATA_VALIDATION.DISCOUNT_VALUE_TITLE_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate('SALES.INVOICES.SALE_INVOICE_DATA_VALIDATION.DISCOUNT_IS_MORE_THAN_VALUE'),
        ];

        this.form.markAllAsTouched();
        return this.notificationService.warningWithTitle(errorMessage);
      } else if (this.form.get('notes').invalid) {
        errorMessage.title = this.translationService.translate(
          'SALES.INVOICES.SALE_INVOICE_DATA_VALIDATION.NOTES_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate('SALES.INVOICES.SALE_INVOICE_DATA_VALIDATION.NOTES_LENGTH_FROM_ZERO_ERROR'),
        ];
      }

      this.form.markAllAsTouched();
      return this.notificationService.warningWithTitle(errorMessage);
    }

    this.openModal(this.confirmSaleInvoiceModalRef);
  }

  /**
   * Confirms the form submit.
   */
  confirm() {
    let fromDateFormatted;
    let dateOfSupplyFormatted;

    const { transactionDate, dateOfSupply, locationId, customerId, notes } = this.form.value;

    if (transactionDate) {
      fromDateFormatted = new Date(transactionDate.year, transactionDate.month - 1, transactionDate.day);
      fromDateFormatted.setHours(new Date().getHours(), new Date().getMinutes());
    }
    if (dateOfSupply) {
      dateOfSupplyFormatted = new Date(dateOfSupply.year, dateOfSupply.month - 1, dateOfSupply.day);
      dateOfSupplyFormatted.setHours(new Date().getHours(), new Date().getMinutes());
    }

    /**
     * The list of products in the products form.
     */
    const products = this.productsForm.value.map((item: ProductsFormItem) => {
      const inputProduct: CreateSalesInvoiceProductInput = {
        productId: item.isStorable || item.isService ? item.product.id : null,
        subProductId: item.subProductId,
        description: item.isStorable || item.isService ? null : item.description,
        quantity: item.quantity,
        unitOfMeasureId: item.unitOfMeasureId,
        value: item.value,
        discount: item.discount,
        discountPercent: item.discountPercent,
        tax: item.tax,
        notes: item.notes,
        locationId: item.isStorable || item.isService ? item.locationId : null,
      };

      return inputProduct;
    });

    this.salesStore$.dispatch(
      new fromSalesStore.CreateOpeningBalanceInvoice({
        locationId,
        customerId,
        products,
        notes,
        attachments: this.attachmentsForm.value.filter((file) => !!file),
        transactionDate: fromDateFormatted,
        dateOfSupply: dateOfSupplyFormatted,
      })
    );
  }

  /**
   * Open the notes popup.
   */
  openNotes() {
    this.openModal(this.notesModalRef);
  }

  /**
   * Opens the modal of the given templateRef.
   * @param modalRef The modal templateRef to be opened.
   */
  openModal(modalRef) {
    this.modalService.open(modalRef);
  }

  /**
   * Selects the newly selected customer from the customers search list.
   * @param customers The list of newly selected customers to select the only one in the list.
   */
  selectCustomer([customer]: Customer[]) {
    if (customer) {
      this.customers = [customer];
      this.form.patchValue({ customerId: customer.id });
    }
  }

  /**
   * Open confirm reset invoice modal .
   */
  openResetModal() {
    this.openModal(this.resetModalRef);
  }

  /**
   * Resets all form data.
   * It only adds one service for quick start.
   */
  resetForm() {
    this.form.enable();
    this.form.reset();
    this.productsForm.clear();
    this.attachmentsForm.clear();
    this.addBlankAttachment();
  }

  /**
   * Adds a blank attachment form-control to the attachments form for quick start..
   */
  addBlankAttachment() {
    this.attachmentsForm.push(new FormControl());
  }

  /**
   * Confirms leaving the page before routing.
   */
  confirmDeactivate() {
    if (this.form.dirty === true) {
      /**
       * Message to warn the user before leaving the detailed form.
       */
      return confirm(this.translationService.translate('SHARED.CONFIRM.LEAVING'));
    }
    return true;
  }

  /**
   * Prints the current displayed sale invoice.
   */
  print(invoiceId: number): void {
    this.printingStore$.dispatch(
      new fromPrintingStore.GetSaleInvoicePrint({
        saleInvoiceId: invoiceId,
      })
    );
  }

  /**
   * Selects the newly selected location from the locations search list.
   * @param locations The list of newly selected locations to select the only one in the list.
   */
  selectLocation([location]: Location[]) {
    if (location) {
      this.locations = [location];
      this.form.patchValue({ locationId: location.id });
    }
  }
}
