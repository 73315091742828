import { findIndex } from 'lodash';

import { QuotationsActionType, QuotationsActions } from 'crm/store/actions';
import { QuotationsState } from 'crm/store/states';

/**
 * The initial state from which the state starts.
 */
const initialState: QuotationsState = {
  data: [],
  paginationInfo: {
    total: 0,
    page: 1,
    pageSize: 0,
    count: 0,
  },
  error: null,
  isSearching: false,
  isSearchCompleted: false,
  selectedQuotation: null,
  isFinding: false,
  isFindCompleted: false,
  isCreating: false,
  isCreateCompleted: false,
  isRejecting: false,
  isRejectCompleted: false,
};

/**
 * The reducer function that is called in each action dispatch against the state.
 * @param state The current state.
 * @param action The action that will affect the state.
 */
export function quotationsReducer(state: QuotationsState = initialState, action: QuotationsActions): QuotationsState {
  switch (action.type) {
    //#region SEARCH_QUOTATIONS

    case QuotationsActionType.SEARCH_QUOTATIONS: {
      return {
        ...state,
        error: null,
        isSearching: true,
        isSearchCompleted: false,
      };
    }

    case QuotationsActionType.SEARCH_QUOTATIONS_FAIL: {
      return {
        ...state,
        error: action.payload,
        isSearching: false,
        isSearchCompleted: false,
      };
    }

    case QuotationsActionType.SEARCH_QUOTATIONS_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        paginationInfo: {
          ...action.payload.meta,
        },
        error: null,
        isSearching: false,
        isSearchCompleted: true,
      };
    }

    //#endregion SEARCH_QUOTATIONS

    //#region FIND_QUOTATION

    case QuotationsActionType.FIND_QUOTATION: {
      return {
        ...state,
        selectedQuotation: null,
        error: null,
        isFinding: true,
        isFindCompleted: false,
      };
    }

    case QuotationsActionType.FIND_QUOTATION_FAIL: {
      return {
        ...state,
        error: action.payload,
        isFinding: false,
        isFindCompleted: false,
      };
    }

    case QuotationsActionType.FIND_QUOTATION_SUCCESS: {
      let quotations = [...state.data];
      const quotationIndex = findIndex(quotations, (quotation) => quotation.id === action.payload.data.id);

      /* If quotation was found, update it. */
      if (quotationIndex >= 0) {
        quotations[quotationIndex] = action.payload.data;
      } else {
        /* else, insert it to the beginning of the array. */
        quotations = [action.payload.data, ...quotations];
      }

      return {
        ...state,
        data: quotations,
        selectedQuotation: action.payload.data,
        error: null,
        isFinding: false,
        isFindCompleted: true,
      };
    }

    //#endregion FIND_QUOTATION

    //#region CREATE_QUOTATION

    case QuotationsActionType.CREATE_QUOTATION: {
      return {
        ...state,
        error: null,
        isCreating: true,
        isCreateCompleted: false,
      };
    }

    case QuotationsActionType.CREATE_QUOTATION_FAIL: {
      return {
        ...state,
        error: action.payload,
        isCreating: false,
        isCreateCompleted: false,
      };
    }

    case QuotationsActionType.CREATE_QUOTATION_SUCCESS: {
      return {
        ...state,
        data: [action.payload.data, ...state.data],
        selectedQuotation: action.payload.data,
        error: null,
        isCreating: false,
        isCreateCompleted: true,
      };
    }

    //#region REJECT_QUOTATION

    case QuotationsActionType.REJECT_QUOTATION: {
      return {
        ...state,
        error: null,
        isRejecting: true,
        isRejectCompleted: false,
      };
    }

    case QuotationsActionType.REJECT_QUOTATION_FAIL: {
      return {
        ...state,
        error: action.payload.data,
        isRejecting: false,
        isRejectCompleted: false,
      };
    }

    case QuotationsActionType.REJECT_QUOTATION_SUCCESS: {
      let myShifts = [...state.data];
      const myShiftIndex = findIndex(myShifts, (myShift) => myShift.id === action.payload.data.id);

      /* If myShift was found, update it. */
      if (myShiftIndex >= 0) {
        myShifts[myShiftIndex] = action.payload.data;
      } else {
        /* else, insert it to the beginning of the array. */
        myShifts = [action.payload.data, ...myShifts];
      }

      return {
        ...state,
        data: myShifts,
        selectedQuotation: action.payload.data,
        error: null,
        isRejecting: false,
        isRejectCompleted: true,
      };
    }

    //#endregion REJECT_QUOTATION

    //#endregion CREATE_QUOTATION

    default:
      return state;
  }
}
