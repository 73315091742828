/**
 * The system supported user claims.
 */
export enum Claims {
  /**
   * A show organization statistics.
   */
  SHOW_ORGANIZATION_STATISTICS = 'SHOW_ORGANIZATION_STATISTICS',

  /**
   * A create organization with no organization.
   */
  CREATE_ORGANIZATION = 'CREATE_ORGANIZATION',

  /**
   * An update organization with no organization.
   */
  UPDATE_ORGANIZATION = 'UPDATE_ORGANIZATION',

  /**
   * A delete organization with no organization.
   */
  DELETE_ORGANIZATION = 'DELETE_ORGANIZATION',

  /**
   * A block organization with no organization.
   */
  BLOCK_ORGANIZATION = 'BLOCK_ORGANIZATION',

  /**
   * An activate organization with no organization.
   */
  ACTIVATE_ORGANIZATION = 'ACTIVATE_ORGANIZATION',
  /**
   * A create user with no organization.
   */
  CREATE_USER = 'CREATE_USER',

  /**
   * A create organization user with no organization.
   */
  CREATE_ORGANIZATION_USER = 'CREATE_ORGANIZATION_USER',

  /**
   * An update user with no organization.
   */
  UPDATE_USER = 'UPDATE_USER',

  /**
   * A block user with no organization.
   */
  BLOCK_USER = 'BLOCK_USER',

  /**
   * An activate user with no organization.
   */
  ACTIVATE_USER = 'ACTIVATE_USER',

  /**
   * A create tax with no organization.
   */
  CREATE_TAX = 'CREATE_TAX',

  /**
   * An update tax with no organization.
   */
  UPDATE_TAX = 'UPDATE_TAX',

  /**
   * A delete tax with no organization.
   */
  DELETE_TAX = 'DELETE_TAX',

  /**
   * A create location for a organization.
   */
  CREATE_LOCATION = 'CREATE_LOCATION',

  /**
   * An update location for a organization.
   */
  UPDATE_LOCATION = 'UPDATE_LOCATION',

  /**
   * A delete location for a organization.
   */
  DELETE_LOCATION = 'DELETE_LOCATION',

  /**
   * A create product class for a organization.
   */
  CREATE_PRODUCT_CLASS = 'CREATE_PRODUCT_CLASS',

  /**
   * An update product class for a organization.
   */
  UPDATE_PRODUCT_CLASS = 'UPDATE_PRODUCT_CLASS',

  /**
   * A delete product class for a organization.
   */
  DELETE_PRODUCT_CLASS = 'DELETE_PRODUCT_CLASS',

  /**
   * A create product for a organization.
   */
  CREATE_PRODUCT = 'CREATE_PRODUCT',

  /**
   * An update product for a organization.
   */
  UPDATE_PRODUCT = 'UPDATE_PRODUCT',

  /**
   * A delete product for a organization.
   */
  DELETE_PRODUCT = 'DELETE_PRODUCT',

  /**
   * A create incoming stock for a organization.
   */
  CREATE_INCOMING_STOCK = 'CREATE_INCOMING_STOCK',

  /**
   * A create outgoing stock for a organization.
   */
  CREATE_OUTGOING_STOCK = 'CREATE_OUTGOING_STOCK',

  /**
   * A create transfer stock for a organization.
   */
  CREATE_TRANSFER_STOCK = 'CREATE_TRANSFER_STOCK',

  /**
   * A create vendor for a organization.
   */
  CREATE_VENDOR = 'CREATE_VENDOR',

  /**
   * An update vendor for a organization.
   */
  UPDATE_VENDOR = 'UPDATE_VENDOR',

  /**
   * A delete vendor for a organization.
   */
  DELETE_VENDOR = 'DELETE_VENDOR',

  /**
   * A block vendor for a organization.
   */
  BLOCK_VENDOR = 'BLOCK_VENDOR',

  /**
   * An activate vendor for a organization.
   */
  ACTIVATE_VENDOR = 'ACTIVATE_VENDOR',

  /**
   * A create purchase request for a organization.
   */
  CREATE_PURCHASE_REQUEST = 'CREATE_PURCHASE_REQUEST',

  /**
   * A approve purchase request for a organization.
   */
  APPROVE_PURCHASE_REQUEST = 'APPROVE_PURCHASE_REQUEST',

  /**
   * A cancel purchase request for a organization.
   */
  CANCEL_PURCHASE_REQUEST = 'CANCEL_PURCHASE_REQUEST',

  /**
   * A create purchase order for a organization.
   */
  CREATE_PURCHASE_ORDER = 'CREATE_PURCHASE_ORDER',

  /**
   * A cancel purchase order for a organization.
   */
  CANCEL_PURCHASE_ORDER = 'CANCEL_PURCHASE_ORDER',

  /**
   * A create customer for a organization.
   */
  CREATE_CUSTOMER = 'CREATE_CUSTOMER',

  /**
   * An update customer for a organization.
   */
  UPDATE_CUSTOMER = 'UPDATE_CUSTOMER',

  /**
   * A delete customer for a organization.
   */
  DELETE_CUSTOMER = 'DELETE_CUSTOMER',

  /**
   * A block customer for a organization.
   */
  BLOCK_CUSTOMER = 'BLOCK_CUSTOMER',

  /**
   * An activate customer for a organization.
   */
  ACTIVATE_CUSTOMER = 'ACTIVATE_CUSTOMER',

  /**
   * A create customer engagement for a organization.
   */
  CREATE_CUSTOMER_ENGAGEMENT = 'CREATE_CUSTOMER_ENGAGEMENT',

  /**
   * An update customer engagement for a organization.
   */
  UPDATE_CUSTOMER_ENGAGEMENT = 'UPDATE_CUSTOMER_ENGAGEMENT',

  /**
   * A delete customer engagement for a organization.
   */
  DELETE_CUSTOMER_ENGAGEMENT = 'DELETE_CUSTOMER_ENGAGEMENT',

  /**
   * A create sale invoice for a organization.
   */
  CREATE_SALE_INVOICE = 'CREATE_SALE_INVOICE',

  /**
   * A create maintenance sale invoice for a organization.
   */
  CREATE_MAINTENANCE_SALE_INVOICE = 'CREATE_MAINTENANCE_SALE_INVOICE',

  /**
   * A create customer opening balance sale invoice for a organization.
   */
  CREATE_CUSTOMER_OPENING_BALANCE_INVOICE = 'CREATE_CUSTOMER_OPENING_BALANCE_INVOICE',

  /**
   * A create point of sale invoice for a organization.
   */
  CREATE_POINT_OF_SALE_INVOICE = 'CREATE_POINT_OF_SALE_INVOICE',

  /**
   * A create purchase invoice for a organization.
   */
  CREATE_PURCHASE_INVOICE = 'CREATE_PURCHASE_INVOICE',

  /**
   * A create vendor opening balance invoice for a organization.
   */
  CREATE_VENDOR_OPENING_BALANCE_INVOICE = 'CREATE_VENDOR_OPENING_BALANCE_INVOICE',

  /**
   * A edit purchase invoice for a organization.
   */
  EDIT_PURCHASE_INVOICE = 'EDIT_PURCHASE_INVOICE',

  /**
   * A create discount on sale invoice for a organization.
   */
  CREATE_DISCOUNT_ON_SALE_INVOICE = 'CREATE_DISCOUNT_ON_SALE_INVOICE',

  /**
   * A create customer payment for a organization.
   */
  CREATE_CUSTOMER_PAYMENT = 'CREATE_CUSTOMER_PAYMENT',

  /**
   * A create cost center for a organization.
   */
  CREATE_COST_CENTER = 'CREATE_COST_CENTER',

  /**
   * An update cost center for a organization.
   */
  UPDATE_COST_CENTER = 'UPDATE_COST_CENTER',

  /**
   * A delete cost center for a organization.
   */
  DELETE_COST_CENTER = 'DELETE_COST_CENTER',

  /**
   * A create petty cash for a organization.
   */
  CREATE_PETTY_CASH = 'CREATE_PETTY_CASH',

  /**
   * An update petty cash for a organization.
   */
  UPDATE_PETTY_CASH = 'UPDATE_PETTY_CASH',

  /**
   * A delete petty cash for a organization.
   */
  DELETE_PETTY_CASH = 'DELETE_PETTY_CASH',

  /**
   * A block petty cash for a organization.
   */
  BLOCK_PETTY_CASH = 'BLOCK_PETTY_CASH',

  /**
   * An activate petty cash for a organization.
   */
  ACTIVATE_PETTY_CASH = 'ACTIVATE_PETTY_CASH',

  /**
   * A create petty cash refund request for a organization.
   */
  CREATE_PETTY_CASH_REFUND_REQUEST = 'CREATE_PETTY_CASH_REFUND_REQUEST',

  /**
   * A create petty cash refund for a organization.
   */
  CREATE_PETTY_CASH_REFUND = 'CREATE_PETTY_CASH_REFUND',

  /**
   * A create petty cash reduction for a organization.
   */
  CREATE_PETTY_CASH_REDUCTION = 'CREATE_PETTY_CASH_REDUCTION',

  /**
   * A create account for a organization.
   */
  CREATE_ACCOUNT = 'CREATE_ACCOUNT',

  /**
   * An update account for a organization.
   */
  UPDATE_ACCOUNT = 'UPDATE_ACCOUNT',

  /**
   * A delete account for a organization.
   */
  DELETE_ACCOUNT = 'DELETE_ACCOUNT',

  /**
   * A create journal for a organization.
   */
  CREATE_JOURNAL = 'CREATE_JOURNAL',

  /**
   * A create asset for a organization.
   */
  CREATE_ASSET = 'CREATE_ASSET',

  /**
   * An update asset for a organization.
   */
  UPDATE_ASSET = 'UPDATE_ASSET',

  /**
   * An delete asset for a organization.
   */
  DELETE_ASSET = 'DELETE_ASSET',

  /**
   * A block asset for a organization.
   */
  BLOCK_ASSET = 'BLOCK_ASSET',

  /**
   * An activate asset for a organization.
   */
  ACTIVATE_ASSET = 'ACTIVATE_ASSET',

  /**
   * A create excluded asset for a organization.
   */
  CREATE_EXCLUDED_ASSET = 'CREATE_EXCLUDED_ASSET',

  /**
   * An update user claims for a organization.
   */
  UPDATE_ORGANIZATION_USER_CLAIMS = 'UPDATE_ORGANIZATION_USER_CLAIMS',

  /**
   * An activate organization user with no organization.
   */
  ACTIVATE_ORGANIZATION_USER = 'ACTIVATE_ORGANIZATION_USER',

  /**
   * An Block organization user with no organization.
   */
  BLOCK_ORGANIZATION_USER = 'BLOCK_ORGANIZATION_USER',

  /**
   * An update organization setting for a organization.
   */
  UPDATE_ORGANIZATION_SETTING = 'UPDATE_ORGANIZATION_SETTING',

  /**
   * A create purchase return for a organization.
   */
  CREATE_PURCHASE_RETURN = 'CREATE_PURCHASE_RETURN',

  /**
   * A create sale return for a organization.
   */
  CREATE_SALE_RETURN = 'CREATE_SALE_RETURN',

  /**
   * A create vendor payment for a organization.
   */
  CREATE_VENDOR_PAYMENT = 'CREATE_VENDOR_PAYMENT',

  /**
   * A delete bank for a organization.
   */
  DELETE_BANK = 'DELETE_BANK',

  /**
   * A update bank for a organization.
   */
  UPDATE_BANK = 'UPDATE_BANK',

  /**
   * A create bank for a organization.
   */
  CREATE_BANK = 'CREATE_BANK',

  /**
   * A create salary closing for a organization.
   */
  CREATE_SALARY_CLOSING = 'CREATE_SALARY_CLOSING',

  /**
   * A create bank account.
   */
  CREATE_BANK_ACCOUNT = 'CREATE_BANK_ACCOUNT',

  /**
   * A update bank account.
   */
  UPDATE_BANK_ACCOUNT = 'UPDATE_BANK_ACCOUNT',

  /**
   * A delete bank account.
   */
  DELETE_BANK_ACCOUNT = 'DELETE_BANK_ACCOUNT',

  /**
   * A block bank account.
   */
  BLOCK_BANK_ACCOUNT = 'BLOCK_BANK_ACCOUNT',

  /**
   * An activate bank account.
   */
  ACTIVATE_BANK_ACCOUNT = 'ACTIVATE_BANK_ACCOUNT',

  /**
   * A reset user credentials.
   */
  RESET_USER_CREDENTIALS = 'RESET_USER_CREDENTIALS',

  /**
   * A reset organization user credentials.
   */
  RESET_ORGANIZATION_USER_CREDENTIALS = 'RESET_ORGANIZATION_USER_CREDENTIALS',

  /**
   * A start shift for casher.
   */
  START_SHIFT_FOR_CASHER = 'START_SHIFT_FOR_CASHER',

  /**
   * An end shift for casher.
   */
  END_SHIFT_FOR_CASHER = 'END_SHIFT_FOR_CASHER',

  /**
   * A delete shift for casher.
   */
  DELETE_CASHER_SHIFT = 'DELETE_CASHER_SHIFT',

  /**
   * A create bank account transaction.
   */
  CREATE_BANK_ACCOUNT_TRANSACTION = 'CREATE_BANK_ACCOUNT_TRANSACTION',

  /**
   * A create asset sale invoice.
   */
  CREATE_ASSET_SALE_INVOICE = 'CREATE_ASSET_SALE_INVOICE',

  /**
   * A open financial period.
   */
  OPEN_FINANCIAL_PERIOD = 'OPEN_FINANCIAL_PERIOD',

  /**
   * A pause financial period.
   */
  PAUSE_FINANCIAL_PERIOD = 'PAUSE_FINANCIAL_PERIOD',

  /**
   * A close financial period.
   */
  CLOSE_FINANCIAL_PERIOD = 'CLOSE_FINANCIAL_PERIOD',

  /**
   * A create currency for a organization.
   */
  CREATE_CURRENCY = 'CREATE_CURRENCY',

  /**
   * A update currency for a organization.
   */
  UPDATE_CURRENCY = 'UPDATE_CURRENCY',

  /**
   * A delete currency for a organization.
   */
  DELETE_CURRENCY = 'DELETE_CURRENCY',

  /**
   * A create currency exchange rate for a organization.
   */
  CREATE_CURRENCY_EXCHANGE_RATE = 'CREATE_CURRENCY_EXCHANGE_RATE',

  /**
   * A update currency exchange rate for a organization.
   */
  UPDATE_CURRENCY_EXCHANGE_RATE = 'UPDATE_CURRENCY_EXCHANGE_RATE',

  /**
   * A delete currency exchange rate for a organization.
   */
  DELETE_CURRENCY_EXCHANGE_RATE = 'DELETE_CURRENCY_EXCHANGE_RATE',

  /**
   * Create unit of measure class rate for a organization.
   */
  CREATE_UNIT_OF_MEASURE_CLASS = 'CREATE_UNIT_OF_MEASURE_CLASS',

  /**
   * Update unit of measure class for a organization.
   */
  UPDATE_UNIT_OF_MEASURE_CLASS = 'UPDATE_UNIT_OF_MEASURE_CLASS',

  /**
   * Delete unit of measure class for a organization.
   */
  DELETE_UNIT_OF_MEASURE_CLASS = 'DELETE_UNIT_OF_MEASURE_CLASS',

  /**
   * Create unit of measure  rate for a organization.
   */
  CREATE_UNIT_OF_MEASURE = 'CREATE_UNIT_OF_MEASURE',

  /**
   * Update unit of measure  for a organization.
   */
  UPDATE_UNIT_OF_MEASURE = 'UPDATE_UNIT_OF_MEASURE',

  /**
   * Delete unit of measure  for a organization.
   */
  DELETE_UNIT_OF_MEASURE = 'DELETE_UNIT_OF_MEASURE',

  /**
   * A create variant for a organization.
   */
  CREATE_VARIANT = 'CREATE_VARIANT',

  /**
   * An update variant for a organization.
   */
  UPDATE_VARIANT = 'UPDATE_VARIANT',

  /**
   * A delete variant for a organization.
   */
  DELETE_VARIANT = 'DELETE_VARIANT',

  /**
   * A approve petty cash refund request.
   */
  APPROVE_PETTY_CASH_REFUND_REQUEST = 'APPROVE_PETTY_CASH_REFUND_REQUEST',

  /**
   * Show on all stores reports.
   */
  STORES_REPORTS = 'STORES_REPORTS',

  /**
   * Show on all purchase invoices ,orders,requests and returns reports.
   */
  PURCHASES_REPORTS = 'PURCHASES_REPORTS',

  /**
   * Show on all reports that related to  purchase  analysis.
   */
  PURCHASES_ANALYSIS_REPORTS = 'PURCHASES_ANALYSIS_REPORTS',

  /**
   * Show on vendor debits reports.
   */
  VENDORS_DEBITS_REPORTS = 'VENDORS_DEBITS_REPORTS',

  /**
   * Show on vendors summary reports.
   */
  VENDORS_SUMMARY_REPORTS = 'VENDORS_SUMMARY_REPORTS',

  /**
   * Show on all sales invoices, maintenance stage and returns reports.
   */
  SALES_REPORTS = 'SALES_REPORTS',

  /**
   * Show on all reports that related to  sales  analysis.
   */
  SALES_ANALYSIS_REPORTS = 'SALES_ANALYSIS_REPORTS',

  /**
   * Show on product class sale reports.
   */
  PRODUCT_CLASSES_SALE_REPORTS = 'PRODUCT_CLASSES_SALE_REPORTS',

  /**
   * Show on customers debits reports.
   */
  CUSTOMERS_DEBITS_REPORTS = 'CUSTOMERS_DEBITS_REPORTS',

  /**
   * Show on customers summary reports.
   */
  CUSTOMERS_SUMMARY_REPORTS = 'CUSTOMERS_SUMMARY_REPORTS',

  /**
   * Show on gross profit reports.
   */
  GROSS_PROFIT_REPORTS = 'GROSS_PROFIT_REPORTS',

  /**
   * Show on trial balance reports.
   */
  TRIAL_BALANCE_REPORTS = 'TRIAL_BALANCE_REPORTS',

  /**
   * Show on taxes reports.
   */
  TAXES_REPORTS = 'TAXES_REPORTS',

  /**
   * Show on balance sheet reports.
   */
  BALANCE_SHEET_REPORTS = 'BALANCE_SHEET_REPORTS',

  /**
   * Show on income statement reports.
   */
  INCOME_STATEMENT_REPORTS = 'INCOME_STATEMENT_REPORTS',

  /**
   * Show on bank account summary reports.
   */
  BANK_ACCOUNTS_REPORTS = 'BANK_ACCOUNTS_REPORTS',

  /**
   * Show on journals reports.
   */
  JOURNALS_REPORTS = 'JOURNALS_REPORTS',

  /**
   * Show on account analysis reports.
   */
  ACCOUNT_ANALYSIS_REPORTS = 'ACCOUNT_ANALYSIS_REPORTS',

  /**
   * An update user tasks.
   */
  UPDATE_USER_TASKS = 'UPDATE_USER_TASKS',

  /**
   * Orders monitoring.
   */
  ORDERS_MONITORING = 'ORDERS_MONITORING',

  /**
   * Orders delivering.
   */
  ORDERS_DELIVERING = 'ORDERS_DELIVERING',

  /**
   * Start new shift.
   */
  START_NEW_SHIFT = 'START_NEW_SHIFT',

  /**
   * A create board.
   */
  CREATE_BOARD = 'CREATE_BOARD',

  /**
   * An update board.
   */
  UPDATE_BOARD = 'UPDATE_BOARD',

  /**
   * A delete board.
   */
  DELETE_BOARD = 'DELETE_BOARD',

  /**
   * Update user locations.
   */
  UPDATE_ORGANIZATION_USER_LOCATIONS = 'UPDATE_ORGANIZATION_USER_LOCATIONS',

  /**
   * Update user bank accounts.
   */
  UPDATE_ORGANIZATION_USER_BANK_ACCOUNTS = 'UPDATE_ORGANIZATION_USER_BANK_ACCOUNTS',

  /**
   * Update user cost centers.
   */
  UPDATE_ORGANIZATION_USER_COST_CENTERS = 'UPDATE_ORGANIZATION_USER_COST_CENTERS',

  /**
   * Create pos device.
   */
  CREATE_POS_DEVICE = 'CREATE_POS_DEVICE',

  /**
   * Update pos device.
   */
  UPDATE_POS_DEVICE = 'UPDATE_POS_DEVICE',

  /**
   * Delete pos device.
   */
  DELETE_POS_DEVICE = 'DELETE_POS_DEVICE',

  /**
   * Create lead.
   */
  CREATE_LEAD = 'CREATE_LEAD',

  /**
   * Update lead.
   */
  UPDATE_LEAD = 'UPDATE_LEAD',

  /**
   * Delete lead.
   */
  DELETE_LEAD = 'DELETE_LEAD',

  /**
   * Create employee.
   */
  CREATE_EMPLOYEE = 'CREATE_EMPLOYEE',

  /**
   * Create project.
   */
  CREATE_PROJECT = 'CREATE_PROJECT',

  /**
   * Update project.
   */
  UPDATE_PROJECT = 'UPDATE_PROJECT',

  /**
   * Delete project.
   */
  DELETE_PROJECT = 'DELETE_PROJECT',

  /**
   * Update finance configurations.
   */
  UPDATE_FINANCE_CONFIGURATIONS = 'UPDATE_FINANCE_CONFIGURATIONS',

  /**
   * print shift report.
   */
  PRINT_SHIFT_REPORT = 'PRINT_SHIFT_REPORT',
}
