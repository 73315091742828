import { Action } from '@ngrx/store';

import { PettyCashRefundRequestType } from 'lookups/models';
import { AppHttpResponse } from 'shared';

/**
 * The Lookups-Module store  petty cash refund request type action types.
 */
export enum PettyCashRefundRequestTypeActionType {
  GET_ALL_PETTY_CASH_REFUND_REQUEST_TYPE = '[Lookups] [PettyCashRefundRequestType] Get All Petty Cash Refund Request Types',
  GET_ALL_PETTY_CASH_REFUND_REQUEST_TYPE_FAIL = '[Lookups] [PettyCashRefundRequestType] Get All Petty Cash Refund Request Types Fail',
  GET_ALL_PETTY_CASH_REFUND_REQUEST_TYPE_SUCCESS = '[Lookups] [PettyCashRefundRequestType] Get All Petty Cash Refund Request Types Success',
}

/**
 * Represents a store  petty cash refund request type action.
 */
export class GetAllPettyCashRefundRequestType implements Action {
  /**
   * The type of the action.
   */
  readonly type = PettyCashRefundRequestTypeActionType.GET_ALL_PETTY_CASH_REFUND_REQUEST_TYPE;

  /**
   * Fires a new  petty cash refund request type action.
   */
  constructor() {}
}

/**
 * Represents a store  petty cash refund request type fail action.
 */
export class GetAllPettyCashRefundRequestTypeFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = PettyCashRefundRequestTypeActionType.GET_ALL_PETTY_CASH_REFUND_REQUEST_TYPE_FAIL;

  /**
   * Fires a new  petty cash refund request type fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store  petty cash refund request type success action.
 */
export class GetAllPettyCashRefundRequestTypeSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = PettyCashRefundRequestTypeActionType.GET_ALL_PETTY_CASH_REFUND_REQUEST_TYPE_SUCCESS;

  /**
   * Fires a  petty cash refund request type success action.
   * @param payload An object contains the list of petty cash refund request types.
   */
  constructor(public payload: AppHttpResponse<PettyCashRefundRequestType[]>) {}
}

/**
 * Lookups-module  petty cash refund request type action types.
 */
export type PettyCashRefundRequestTypeActions =
  | GetAllPettyCashRefundRequestType
  | GetAllPettyCashRefundRequestTypeFail
  | GetAllPettyCashRefundRequestTypeSuccess;
