import { EmployeesProvisionLinkFormComponent } from 'finances/components/finance-configurations/employees-provision-link-form/employees-provision-link-form.component';
import { EmployeesProvisionLinkFormItemComponent } from 'finances/components/finance-configurations/employees-provision-link-form-item/employees-provision-link-form-item.component';
import { EmployeesProvisionLinksComponent } from 'finances/components/finance-configurations/employees-provision-links/employees-provision-links.component';
import { FinanceConfigurationsComponent } from 'finances/components/finance-configurations/finance-configurations/finance-configurations.component';
import { GovServicesFormItemComponent } from 'finances/components/finance-configurations/gov-services-form-item/gov-services-form-item.component';
import { GovServicesFormComponent } from 'finances/components/finance-configurations/gov-services-form/gov-services-form.component';
import { GovServicesComponent } from 'finances/components/finance-configurations/gov-services/gov-services.component';

export * from 'finances/components/finance-configurations/employees-provision-link-form/employees-provision-link-form.component';
export * from 'finances/components/finance-configurations/employees-provision-link-form-item/employees-provision-link-form-item.component';
export * from 'finances/components/finance-configurations/employees-provision-links/employees-provision-links.component';
export * from 'finances/components/finance-configurations/finance-configurations/finance-configurations.component';
export * from 'finances/components/finance-configurations/gov-services-form-item/gov-services-form-item.component';
export * from 'finances/components/finance-configurations/gov-services-form/gov-services-form.component';
export * from 'finances/components/finance-configurations/gov-services/gov-services.component';

/**
 * The set of components registered on this sub-module.
 */
export const COMPONENTS: any[] = [
  EmployeesProvisionLinkFormComponent,
  EmployeesProvisionLinkFormItemComponent,
  EmployeesProvisionLinksComponent,
  FinanceConfigurationsComponent,
  GovServicesFormItemComponent,
  GovServicesFormComponent,
  GovServicesComponent,
];
