import { Component, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { SubProduct } from 'stores/models';

@Component({
  selector: 'app-product-sub-products-form',
  templateUrl: './product-sub-products-form.component.html',
  styles: [],
})
export class ProductSubProductsFormComponent {
  /**
   * Sets the parent form-group of this form-control.
   */
  @Input() formGroup: FormGroup;

  /**
   * Sets the name of the form-control.
   * @default 'subProductId'
   */
  @Input() controlName = 'subProductId';

  /**
   * disable subProduct select.
   * @default 'false'
   */
  @Input() isDisabled = false;

  /**
   * Indicates that a drop-down field in the form is opened and therefore the table height should be expanded.
   */
  @Input() isDropDownOpened: boolean;

  /**
   * Indicates that a drop-down field in the form is opened and therefore the table height should be expanded.
   */
  @Output() isDropDownOpenedChange = new EventEmitter<boolean>();

  /**
   * Gets the list of sub-products for the current product.
   */
  get subProducts(): SubProduct[] {
    return this.formGroup?.value.product?.subProducts ?? [];
  }

  /**
   * Gets the selected sub-product.
   */
  get selectedSubProduct(): SubProduct {
    return this.subProducts?.find((subProduct) => subProduct.id === this.formGroup?.get(this.controlName).value);
  }

  /**
   * Selects the given sub-product id.
   * @param subProductId The id of the selected sub-product.
   */
  selectSubProduct(subProductId: number): void {
    this.formGroup.patchValue({ [this.controlName]: subProductId });
  }

  /**
   * Updates the dropdown status.
   * @param status The status to be updated.
   */
  changeDropdownStatus(status: boolean): void {
    this.isDropDownOpenedChange.emit(status);
  }
}
