import { Action } from '@ngrx/store';

import { AnnualLeave } from 'lookups/models';
import { AppHttpResponse } from 'shared';

/**
 * The Lookups-Module store annual leave action types.
 */
export enum AnnualLeaveActionType {
  GET_ALL_ANNUAL_LEAVES = '[Lookups] [AnnualLeave] Get All Annual Leaves',
  GET_ALL_ANNUAL_LEAVES_FAIL = '[Lookups] [AnnualLeave] Get All Annual Leaves Fail',
  GET_ALL_ANNUAL_LEAVES_SUCCESS = '[Lookups] [AnnualLeave] Get All Annual Leaves Success',
}

/**
 * Represents a store annual leave action.
 */
export class GetAllAnnualLeaves implements Action {
  /**
   * The type of the action.
   */
  readonly type = AnnualLeaveActionType.GET_ALL_ANNUAL_LEAVES;

  /**
   * Fires a new annual leave action.
   */
  constructor() {}
}

/**
 * Represents a store annual leave fail action.
 */
export class GetAllAnnualLeavesFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = AnnualLeaveActionType.GET_ALL_ANNUAL_LEAVES_FAIL;

  /**
   * Fires a new annual leave fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store annual leave success action.
 */
export class GetAllAnnualLeavesSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = AnnualLeaveActionType.GET_ALL_ANNUAL_LEAVES_SUCCESS;

  /**
   * Fires a annual leave success action.
   * @param payload An object contains the list of annual leaves.
   */
  constructor(public payload: AppHttpResponse<AnnualLeave[]>) {}
}

/**
 * Lookups-module annual leave action types.
 */
export type AnnualLeaveActions = GetAllAnnualLeaves | GetAllAnnualLeavesFail | GetAllAnnualLeavesSuccess;
