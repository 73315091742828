/**
 * The set of system supported vendor log types.
 */
export enum VendorLogTypes {
  /**
   * The payment bank transfer log type.
   */
  PAYMENT_BANK_TRANSFER = 'PAYMENT_BANK_TRANSFER',

  /**
   * The payment cheque log type.
   */
  PAYMENT_CHEQUE = 'PAYMENT_CHEQUE',

  /**
   * The payment vendor by employee log type.
   */
  PAYMENT_BY_EMPLOYEE = 'PAYMENT_BY_EMPLOYEE',

  /**
   * The payment by owner log type.
   */
  PAYMENT_BY_OWNER = 'PAYMENT_BY_OWNER',

  /**
   * The payment cash log type.
   */
  PAYMENT_CASH = 'PAYMENT_CASH',

  /**
   * The payment cash log type.
   */
  ADVANCE_PAYMENT_CASH = 'ADVANCE_PAYMENT_CASH',

  /**
   * The payment cheque log type.
   */
  ADVANCE_PAYMENT_CHEQUE = 'ADVANCE_PAYMENT_CHEQUE',

  /**
   * The payment bank transfer log type.
   */

  ADVANCE_PAYMENT_BANK_TRANSFER = 'ADVANCE_PAYMENT_BANK_TRANSFER',

  /**
   * The payment electronic log type.
   */

  ADVANCE_PAYMENT_ELECTRONIC = 'ADVANCE_PAYMENT_ELECTRONIC',
}
