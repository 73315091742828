import { Action } from '@ngrx/store';

import { ParkedSaleInvoice } from 'sales/models';

/**
 * The Sales-Module parked sale invoices action types.
 */
export enum ParkedSaleInvoicesActionType {
  ADD_PARKED_SALE_INVOICE = '[Sales] [ParkedSaleInvoices] Add Parked Sale Invoice',
  REMOVE_PARKED_SALE_INVOICE = '[Sales] [ParkedSaleInvoices] Remove Parked Sale Invoice',
}

/**
 * Represents a store parked sale invoice add action.
 */
export class AddParkedSaleInvoice implements Action {
  /** The type Of the action. */
  readonly type = ParkedSaleInvoicesActionType.ADD_PARKED_SALE_INVOICE;

  /**
   * Fires a new parked sale invoice create action.
   * @param payload the new parked sale invoice data-model.
   */
  constructor(public payload: ParkedSaleInvoice) {}
}

/**
 * Represents a store parked sale invoice delete action.
 */
export class RemoveParkedSaleInvoice implements Action {
  /**
   * The type of the action.
   */
  readonly type = ParkedSaleInvoicesActionType.REMOVE_PARKED_SALE_INVOICE;

  /**
   * Fires a new parked sale invoice remove action.
   * @param payload The parked sale invoice's id.
   */
  constructor(public payload: string) {}
}

/**
 * Sales-module parked sale invoices action types.
 */
export type ParkedSaleInvoicesActions = AddParkedSaleInvoice | RemoveParkedSaleInvoice;
