import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';

import { AppHttpResponse } from 'shared';
import { CreateProductClassInput, ProductClass, UpdateProductClassInput } from 'stores/models';

/**
 * The product classes services includes functionality to search, findById, create, update and delete a product class.
 */
@Injectable()
export class ProductClassesService {
  /**
   * The relative route for the product classes.
   *
   * No leading or trailing slashes required.
   */
  private productClassesApi = 'stores/products/classes';

  constructor(private http: HttpClient) {}

  /**
   * Searches in the product classes by name.
   * @param name The name of the product class.
   * @param locations The locations of the product class.
   * @param page The current pagination page number.
   * @param pageSize The maximum number of product classes allowed per one pagination page.
   */
  public search(
    name: string,
    locations: number[],
    page: number,
    pageSize: number
  ): Observable<AppHttpResponse<ProductClass[]>> {
    const params = new HttpParams()
      .set('name', name)
      .set('locations', locations.join(','))
      .set('page', page.toString())
      .set('pageSize', pageSize.toString());
    return this.http.get<AppHttpResponse<ProductClass[]>>(`${this.productClassesApi}`, { params });
  }

  /**
   * Searches in the product classes by name, without pagination.
   * @param name The name of the product class.
   */
  public searchWithoutPagination(name: string, locations: number[]): Observable<AppHttpResponse<ProductClass[]>> {
    const params = new HttpParams().set('name', name).set('locations', locations.join(','));
    return this.http.get<AppHttpResponse<ProductClass[]>>(`${this.productClassesApi}/no-pagination`, { params });
  }

  /**
   * Searches in the product classes for sales screen.
   */
  public searchProductClassesForSalesScreen(defaultLocation: number[]): Observable<AppHttpResponse<ProductClass[]>> {
    const params = new HttpParams().set('locations', defaultLocation.join(','));
    return this.http.get<AppHttpResponse<ProductClass[]>>(`${this.productClassesApi}/classes-for-sales-screen`, {
      params,
    });
  }

  /**
   * Finds the product class with the given id.
   * @param id The id of the product class.
   */
  public findById(id: number): Observable<AppHttpResponse<ProductClass>> {
    return this.http.get<AppHttpResponse<ProductClass>>(`${this.productClassesApi}/${id}`);
  }

  /**
   * Creates a new product class from the provided data.
   * @param data The new product class data.
   */
  public create(data: CreateProductClassInput): Observable<AppHttpResponse<ProductClass>> {
    const formData: any = new FormData();
    formData.append('name', data.name);
    formData.append('nameEn', data.nameEn);
    formData.append('showProductClassInSalesScreen', data.showProductClassInSalesScreen);

    /**
     * Append locations to the form data.
     */
    for (let index = 0; index < data.locations.length; index++) {
      const locationId = data.locations[index];
      formData.append(`locations[${index}]`, locationId);
    }

    if (data.photo) {
      formData.append('photo', data.photo, data.photo.name);
    }

    return this.http.post<AppHttpResponse<ProductClass>>(`${this.productClassesApi}`, formData);
  }

  /**
   * Updates an existing product class data using the provided data.
   * @param data The updated product class data.
   */
  public update(data: UpdateProductClassInput): Observable<AppHttpResponse<ProductClass>> {
    return this.http.put<AppHttpResponse<ProductClass>>(`${this.productClassesApi}`, data);
  }

  /**
   * Deletes the product class by given id.
   * @param id The id of the product class.
   */
  public delete(id: number): Observable<AppHttpResponse<ProductClass>> {
    return this.http.delete<AppHttpResponse<ProductClass>>(`${this.productClassesApi}/${id}`);
  }

  /**
   * Updates an existing product class's photo.
   * @param id The id of the product class.
   * @param photo The new product class's photo.
   */
  public updatePhoto(id: number, photo: File): Observable<AppHttpResponse<ProductClass>> {
    const formData: any = new FormData();
    formData.append('photo', photo, photo.name);

    return this.http.put<AppHttpResponse<ProductClass>>(`${this.productClassesApi}/photo/${id}`, formData);
  }

  /**
   * Deletes the product class photo by given product id.
   * @param id The id of the product class.
   */
  public deletePhoto(id: number): Observable<AppHttpResponse<ProductClass>> {
    return this.http.delete<AppHttpResponse<ProductClass>>(`${this.productClassesApi}/photo/${id}`);
  }
}
