import { CustomersService } from 'sales/services/customers.service';
import { CustomerEngagementsService } from 'sales/services/customer-engagements.service';
import { CustomerAssetsService } from 'sales/services/customer-assets.service';
import { SaleInvoicesService } from 'sales/services/sales-invoices.service';
import { SaleReturnsService } from 'sales/services/sale-returns.service';
import { ShiftsService } from 'sales/services/shifts.service';
import { PendingPosInvoicesService } from 'sales/services/pending-pos-invoices.service';
import { OrdersDeliveryService } from 'sales/services/orders-delivery.service';
import { BoardsService } from 'sales/services/boards.service';
import { PosDevicesService } from 'sales/services/pos-devices.service';
import { PosPrintersService } from 'sales/services/pos-printers.service';
import { PosPrinterAdjustmentService } from 'sales/services/pos-printer-adjustments.service';

export * from 'sales/services/customers.service';
export * from 'sales/services/customer-engagements.service';
export * from 'sales/services/customer-assets.service';
export * from 'sales/services/sales-invoices.service';
export * from 'sales/services/sale-returns.service';
export * from 'sales/services/shifts.service';
export * from 'sales/services/pending-pos-invoices.service';
export * from 'sales/services/orders-delivery.service';
export * from 'sales/services/boards.service';
export * from 'sales/services/pos-devices.service';
export * from 'sales/services/pos-printers.service';
export * from 'sales/services/pos-printer-adjustments.service';

/**
 * The set of services registered on this module.
 */
export const SERVICES: any[] = [
  CustomersService,
  CustomerEngagementsService,
  CustomerAssetsService,
  SaleInvoicesService,
  SaleReturnsService,
  ShiftsService,
  PendingPosInvoicesService,
  OrdersDeliveryService,
  BoardsService,
  PosDevicesService,
  PosPrintersService,
  PosPrinterAdjustmentService,
];
