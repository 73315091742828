import { createSelector } from '@ngrx/store';

import { selectLookupsState } from 'lookups/store/selectors/feature.selectors';
import { LookupsState, AssignmentStatusState } from 'lookups/store/states';

/**
 * Gets the assignment status state.
 */
const selectAssignmentStatuses = createSelector(selectLookupsState, (state: LookupsState) => state.assignmentStatuses);

/**
 * Gets the list of assignment statuses loaded .
 */
export const getAssignmentStatuses = createSelector(
  selectAssignmentStatuses,
  (state: AssignmentStatusState) => state.data
);

/**
 * Gets the error that occurred in the state.
 */
export const getAssignmentStatusesError = createSelector(
  selectAssignmentStatuses,
  (state: AssignmentStatusState) => state.error
);

/**
 * Determines if there is a running load process.
 */
export const getAssignmentStatusesIsLoading = createSelector(
  selectAssignmentStatuses,
  (state: AssignmentStatusState) => state.isLoading
);

/**
 * Determines if the last load process has been ended successfully.
 */
export const getAssignmentStatusesIsLoaded = createSelector(
  selectAssignmentStatuses,
  (state: AssignmentStatusState) => state.isLoaded
);
