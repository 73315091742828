import { createSelector } from '@ngrx/store';

import { selectLookupsState } from 'lookups/store/selectors/feature.selectors';
import { LookupsState, OvertimeRateState } from 'lookups/store/states';

/**
 * Gets the overtime rates state.
 */
const selectOvertimeRates = createSelector(selectLookupsState, (state: LookupsState) => state.overtimeRates);

/**
 * Gets the list of loaded overtime rates.
 */
export const getOvertimeRates = createSelector(selectOvertimeRates, (state: OvertimeRateState) => state.data);

/**
 * Gets the error that occurred in the state.
 */
export const getOvertimeRatesError = createSelector(selectOvertimeRates, (state: OvertimeRateState) => state.error);

/**
 * Determines if there is a running load process.
 */
export const getOvertimeRatesIsLoading = createSelector(
  selectOvertimeRates,
  (state: OvertimeRateState) => state.isLoading
);

/**
 * Determines if the last load process has been ended successfully.
 */
export const getOvertimeRatesIsLoaded = createSelector(
  selectOvertimeRates,
  (state: OvertimeRateState) => state.isLoaded
);
