import { createSelector } from '@ngrx/store';

import { selectLookupsState } from 'lookups/store/selectors/feature.selectors';
import { LookupsState, PayrollElementNatureState } from 'lookups/store/states';

/**
 * Gets the payroll element nature state.
 */
const selectPayrollElementNatures = createSelector(
  selectLookupsState,
  (state: LookupsState) => state.payrollElementNatures
);

/**
 * Gets the list of loaded payroll element natures.
 */
export const getPayrollElementNatures = createSelector(
  selectPayrollElementNatures,
  (state: PayrollElementNatureState) => state.data
);

/**
 * Gets the error that occurred in the state.
 */
export const getPayrollElementNaturesError = createSelector(
  selectPayrollElementNatures,
  (state: PayrollElementNatureState) => state.error
);

/**
 * Determines if there is a running load process.
 */
export const getPayrollElementNaturesIsLoading = createSelector(
  selectPayrollElementNatures,
  (state: PayrollElementNatureState) => state.isLoading
);

/**
 * Determines if the last load process has been ended successfully.
 */
export const getPayrollElementNaturesIsLoaded = createSelector(
  selectPayrollElementNatures,
  (state: PayrollElementNatureState) => state.isLoaded
);
