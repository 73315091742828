import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';

import { Observable } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { tap } from 'rxjs/operators';

import * as fromLookupsStore from 'lookups/store';
import * as fromStoresStore from 'stores/store';
import { PageInfo, PaginationInfo } from 'shared';
import { Location, Region, Sector } from 'stores/models';
import { Claims } from 'security/models';
import { LocationType } from 'lookups/models';

@Component({
  selector: 'app-locations-list',
  templateUrl: './locations-list.component.html',
  styles: [],
})
export class LocationsListComponent implements OnInit {
  /**
   * Gets or sets the information about the current page.
   */
  pageInfo: PageInfo = {
    title: 'STORES.LOCATIONS.LOCATIONS',
    icon: 'icon-location-pin',
  };

  /**
   * Shows or hide the regions list.
   */
  regionsListVisibility = false;

  /**
   * Shows or hide the sectors list.
   */
  sectorsListVisibility = false;

  /**
   * The system supported user claims.
   */
  Claims = Claims;

  /**
   * The list of selected regions.
   */
  regions: Region[] = [];

  /**
   * The list of selected sectors.
   */
  sectors: Sector[] = [];

  /**
   * Gets or sets the currently selected location for delete.
   */
  selectedLocation: Location;

  /**
   * The list of locations.
   */
  locations$: Observable<Location[]>;

  /**
   * The list of location types.
   */
  locationTypes$: Observable<LocationType[]>;

  /**
   * The pagination info.
   */
  paginationInfo$: Observable<PaginationInfo>;

  /**
   * Indicates whether there is a search process is running or not.
   */
  isSearching$: Observable<boolean>;

  /**
   * Indicates whether there is a delete-location process is running or not.
   */
  isDeleting$: Observable<boolean>;

  /**
   * The search form.
   */
  searchForm: FormGroup;

  /**
   * @param modalService The modal service.
   * @param storesStore$ The stores-store module.
   * @param lookupsStore$ the lookups-store module.
   */
  constructor(
    private modalService: NgbModal,
    private storesStore$: Store<fromStoresStore.StoresState>,
    private lookupsStore$: Store<fromLookupsStore.LookupsState>
  ) {}
  ngOnInit(): void {
    this.init();
  }

  /**
   * Initialize component data.
   */
  init() {
    this.initForm();

    /**
     * Load data.
     */
    this.isDeleting$ = this.storesStore$.pipe(select(fromStoresStore.getSelectedLocationDeleting));
    this.isSearching$ = this.storesStore$.pipe(select(fromStoresStore.getLocationsSearching));

    let isManualSearchTriggeredBeforeForLocations = false;
    this.locations$ = this.storesStore$.pipe(
      select(fromStoresStore.getLocations),
      tap((locations) => {
        if (!isManualSearchTriggeredBeforeForLocations && !locations.length) {
          isManualSearchTriggeredBeforeForLocations = true;
          this.search();
        }
      })
    );

    let isManualSearchTriggeredBeforeForTypes = false;
    this.locationTypes$ = this.lookupsStore$.pipe(
      select(fromLookupsStore.getLocationTypes),
      tap((types) => {
        if (!isManualSearchTriggeredBeforeForTypes && !types.length) {
          isManualSearchTriggeredBeforeForTypes = true;
          this.lookupsStore$.dispatch(new fromLookupsStore.GetAllLocationType());
        }
      })
    );

    this.paginationInfo$ = this.storesStore$.pipe(select(fromStoresStore.getLocationsPaginationInfo));
  }

  /**
   * Initialize form and add validators.
   */
  initForm() {
    this.searchForm = new FormGroup({
      name: new FormControl(''),
      regions: new FormControl([]),
      sectors: new FormControl([]),
      locationTypes: new FormControl([]),
      page: new FormControl(1),
    });
  }

  /**
   * Handles search parameters change.
   */
  search(event?: KeyboardEvent) {
    if (event && event.key === 'Enter') {
      event.preventDefault();
      return;
    }

    this.storesStore$.dispatch(
      new fromStoresStore.SearchLocations({
        name: this.searchForm.get('name').value,
        regions: this.searchForm.get('regions')?.value,
        sectors: this.searchForm.get('sectors')?.value,
        locationTypes: this.searchForm.get('locationTypes')?.value,
        page: 1,
      })
    );
  }

  /**
   * Handles pagination page-changed event.
   * @param page The current selected page number.
   */
  pageChanged(page: number) {
    /** Update pagination page. */
    this.searchForm.patchValue({ page });

    /** Get selected page items. */
    this.storesStore$.dispatch(
      new fromStoresStore.SearchLocations({
        name: this.searchForm.get('name').value,
        regions: this.searchForm.get('regions')?.value,
        sectors: this.searchForm.get('sectors')?.value,
        locationTypes: this.searchForm.get('locationTypes').value,
        page: this.searchForm.get('page').value,
      })
    );
  }

  /**
   * Enables trackBy feature for *ngFor utility to track items
   * depending on a comparer to reduce DOM change.
   * If the item is already exist then no HTML will change, only bounded values.
   * @param index The index of the item.
   * @param location The item to determine if it was changed or not.
   */
  trackItems(index: number, location: Location) {
    return location ? location.id : undefined;
  }

  /**
   * Deletes the location with the given id.
   * @param id The id of the location to be deleted.
   */
  delete(id: number) {
    this.storesStore$.dispatch(new fromStoresStore.DeleteLocation(id));
  }

  /**
   * Adds the newly selected regions the regions search list.
   * @param regions The list of newly selected regions to be added.
   */
  selectRegion(regions: Region[]) {
    const selectedIds: number[] = this.searchForm.get('regions').value;
    this.regions = [...this.regions.filter((region) => selectedIds.includes(region.id)), ...regions];
    this.searchForm.patchValue({ regions: this.regions.map((region) => region.id) });
  }

  /**
   * Adds the newly selected sectors the sectors search list.
   * @param sectors The list of newly selected sectors to be added.
   */
  selectSector(sectors: Sector[]) {
    const selectedIds: number[] = this.searchForm.get('sectors').value;
    this.sectors = [...this.sectors.filter((sector) => selectedIds.includes(sector.id)), ...sectors];
    this.searchForm.patchValue({ sectors: this.sectors.map((sector) => sector.id) });
  }

  /**
   * Opens the modal of the given templateRef.
   * @param modalRef The modal templateRef to be opened.
   */
  openModal(modalRef) {
    this.modalService.open(modalRef);
  }
}
