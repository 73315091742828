import { Component, OnDestroy, OnInit } from '@angular/core';

import { Observable, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import * as fromAuthStore from 'auth/store';
import { CostCenter } from 'finances/models';

@Component({
  selector: 'app-user-cost-centers-list',
  templateUrl: './user-cost-centers-list.component.html',
  styles: [],
})
export class UserCostCentersListComponent implements OnInit, OnDestroy {
  /**
   * The current logged-in user's default cost center id.
   */
  userDefaultCostCenterId: number;

  /**
   * The list of user cost centers.
   */
  userCostCenters$: Observable<CostCenter[]>;

  /**
   * Indicates whether there is a update process is running or not.
   */
  isUpdating$: Observable<boolean>;

  /**
   * The set of subscriptions on this components,
   * these subscriptions must be unsubscribed before this component got destroyed.
   */
  subscriptions = new Subscription();

  /**
   * @param modalService The modal service.
   * @param authStore$ The auth-store.
   */
  constructor(private modalService: NgbModal, private authStore$: Store<fromAuthStore.AuthState>) {}

  ngOnInit(): void {
    this.init();
  }

  /**
   * Destroy component data
   */
  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  /**
   * Initialize component data.
   */
  init() {
    /**
     * Load data.
     */
    this.isUpdating$ = this.authStore$.pipe(select(fromAuthStore.getSessionUserDefaultCostCenterUpdating));
    this.userCostCenters$ = this.authStore$.pipe(select(fromAuthStore.getUserCostCenters));

    this.subscriptions.add(
      this.authStore$
        .pipe(
          select(fromAuthStore.getSessionUserDefaultCostCenter),
          tap((costCenter) => {
            this.userDefaultCostCenterId = costCenter?.id;
          })
        )
        .subscribe()
    );

    this.authStore$.dispatch(new fromAuthStore.GetUserCostCenters());
  }

  /**
   * Sets default cost center for current logged-in user.
   */
  selectDefaultCostCenter(userCostCenterId: number) {
    this.authStore$.dispatch(new fromAuthStore.UpdateUserDefaultCostCenter(userCostCenterId));
  }

  /**
   * Enables trackBy feature for *ngFor utility to track elements
   * depending on a comparer to reduce DOM change.
   * If the element is already exist then no HTML will change, only bounded values.
   * @param userCostCenter The user cost center to determine if it was changed or not.
   */
  trackItems(userCostCenter: CostCenter) {
    return userCostCenter ? userCostCenter.id : undefined;
  }

  /**
   * Opens the modal of the given templateRef.
   * @param modalRef The modal templateRef to be opened.
   */
  openModal(modalRef) {
    this.modalService.open(modalRef);
  }
}
