import { ProjectsState } from 'projects-management/store/states/projects.state';
import { ProjectResourcesState } from 'projects-management/store/states/project-resources.state';

/**
 * Represents the projects-management-module state.
 */
export class ProjectsManagementState {
  /**
   * Represents the projects state.
   */
  public projects: ProjectsState;

  /**
   * Represents the project resources state.
   */
  public projectResources: ProjectResourcesState;
}
