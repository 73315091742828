import { PageState } from 'core/store/states/page.state';

/**
 * Represents the status of the app.
 */
export class AppStatusState {
  /**
   * Indicates wither if there is an http-request running.
   */
  public isLoading: boolean;

  /**
   * Gets or sets the count of requests running now and required app loader to be shown.
   */
  public loadingCounter: number;

  /**
   * Gets or sets the information about the current page.
   */
  public page: PageState;
}
