<!-- product sales discounts list starts -->
<div class="row">
  <div class="col-md-12">
    <div class="card">
      <div class="card-content">
        <div class="px-3">
          <div class="row">
            <div class="col-md-12">
              <form class="row" [formGroup]="searchForm" (ngSubmit)="search()">
                <div class="form-group col">
                  <label for="description">{{ 'STORES.PRODUCTS.PRODUCT_DATA.PRODUCT_DESCRIPTION' | translate }}</label>
                  <input
                    type="text"
                    id="description"
                    class="form-control round"
                    [placeholder]="'STORES.PRODUCTS.PRODUCT_DATA.PRODUCT_DESCRIPTION_PLACEHOLDER' | translate"
                    formControlName="description"
                    (input)="search($event)"
                  />
                </div>
                <div class="form-group col">
                  <label for="classes">{{ 'STORES.PRODUCTS.PRODUCT_DATA.PRODUCT_CLASS' | translate }}</label>
                  <ng-select
                    class="round"
                    formControlName="classes"
                    [multiple]="true"
                    [closeOnSelect]="false"
                    labelForId="classes"
                    searchable="true"
                    [placeholder]="'STORES.PRODUCTS.PRODUCT_DATA.PRODUCT_CLASS_PLACEHOLDER' | translate"
                    [notFoundText]="'SHARED.SEARCH.SEARCH_NOT_FOUND' | translate"
                    (open)="classesListVisibility = true"
                  >
                    <ng-option *ngFor="let productClass of classes" [value]="productClass.id">
                      {{ productClass.name | localize: productClass.nameEn | async }}
                    </ng-option>
                  </ng-select>
                </div>
                <div class="form-group col">
                  <label for="locations">{{ 'STORES.PRODUCTS.PRODUCT_DATA.LOCATIONS' | translate }}</label>
                  <ng-select
                    class="round"
                    formControlName="locations"
                    [multiple]="true"
                    [closeOnSelect]="false"
                    labelForId="locations"
                    searchable="true"
                    [placeholder]="'STORES.PRODUCTS.PRODUCT_DATA.LOCATIONS_PLACEHOLDER' | translate"
                    [notFoundText]="'SHARED.SEARCH.SEARCH_NOT_FOUND' | translate"
                    (open)="locationsListVisibility = true"
                  >
                    <ng-option *ngFor="let location of locations" [value]="location.id">
                      {{ location.name | localize: location.nameEn | async }}
                    </ng-option>
                  </ng-select>
                </div>
                <div class="col-md-auto form-group d-flex">
                  <button
                    type="submit"
                    class="btn btn-raised btn-primary align-self-end round mx-1 mb-0"
                    [disabled]="isSearching$ | async"
                  >
                    <i class="fa fa-search"></i>
                  </button>
                  <app-export-tools
                    *ngIf="(productSalesDiscounts$ | async)?.length"
                    class="d-flex align-self-end mx-1"
                    targetElementId="product-sales-discounts-table-export-target-element"
                    [outputFileName]="'SHARED.EXPORT_TABLES.PRODUCT_SALES_DISCOUNTS_TABLE' | translate"
                  ></app-export-tools>
                </div>
              </form>
            </div>
            <div class="col-md-12" *ngIf="(productSalesDiscounts$ | async)?.length; else noItems">
              <div class="row">
                <div class="col-md-12 alert alert-light text-center" role="alert">
                  {{ 'SHARED.SEARCH.SEARCH_RESULTS' | translate }} ({{ (paginationInfo$ | async).total | toNumber }})
                  {{ 'STORES.PRODUCTS.PRODUCT' | translate }}
                </div>

                <div class="col-md-12">
                  <div class="table-responsive">
                    <table class="table text-center" id="product-sales-discounts-table-export-target-element">
                      <thead>
                        <tr>
                          <th scope="col">
                            <i class="fa fa-barcode"></i
                            ><span class="mx-1"> {{ 'STORES.PRODUCTS.PRODUCT_DATA.ITEM_CODE' | translate }}</span>
                          </th>
                          <th scope="col">{{ 'STORES.PRODUCTS.PRODUCT_DATA.THE_PRODUCT' | translate }}</th>
                          <th scope="col">
                            {{ 'STORES.PRODUCTS.PRODUCT_DATA.PRODUCT_CLASS' | translate }}
                          </th>
                          <th scope="col">
                            {{ 'STORES.PRODUCTS.PRODUCT_DATA.LOCATION_INPUT' | translate }}
                          </th>
                          <th scope="col">{{ 'STORES.PRODUCTS.PRODUCT_DATA.SALE_PRICE' | translate }}</th>
                          <th scope="col">{{ 'STORES.PRODUCTS.PRODUCT_DATA.DISCOUNT' | translate }}</th>
                          <th scope="col">{{ 'STORES.PRODUCTS.PRODUCT_DATA.DISCOUNT_PERCENT' | translate }}</th>
                          <th scope="col">{{ 'SHARED.TOOLS' | translate }}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let productDiscount of productSalesDiscounts$ | async; trackBy: trackItems">
                          <td>{{ productDiscount.product.itemCode }}</td>
                          <td>
                            {{
                              productDiscount.product.description
                                | localize: productDiscount.product.descriptionEn
                                | async
                            }}
                          </td>
                          <td>
                            {{
                              productDiscount.product.productClass.name
                                | localize: productDiscount.product.productClass.nameEn
                                | async
                            }}
                          </td>
                          <td>
                            {{ productDiscount.location.name | localize: productDiscount.location.nameEn | async }}
                          </td>
                          <td>{{ productDiscount.product.salePrice | toDecimal }}</td>
                          <td>
                            {{ productDiscount.discount | toDecimal }}
                          </td>
                          <td>{{ productDiscount.discountPercent | toDecimal }} %</td>
                          <td>
                            <a
                              *ngIf="[Claims.UPDATE_PRODUCT] | authorize | async"
                              class="info p-0"
                              [routerLink]="['/stores/products/update', productDiscount.productId]"
                              fragment="sales-discounts"
                              [ngbTooltip]="'STORES.PRODUCTS.PRODUCT_DATA.PRODUCT_UPDATE' | translate"
                            >
                              <i class="fa fa-edit font-medium-3 mr-2"></i>
                            </a>
                            <a
                              *ngIf="[Claims.UPDATE_PRODUCT] | authorize | async"
                              class="danger p-0"
                              [ngbTooltip]="'STORES.PRODUCTS.PRODUCT_DATA.PRODUCT_DELETE' | translate"
                              (click)="selectedProductDiscount = productDiscount; openModal(deleteModalRef)"
                            >
                              <i class="fa fa-close font-medium-3 mr-2"></i>
                            </a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div class="d-flex justify-content-between p-2">
                      <ngb-pagination
                        *ngIf="paginationInfo$ | async; let pagination"
                        maxSize="5"
                        [collectionSize]="pagination.total"
                        [pageSize]="pagination.pageSize"
                        [page]="pagination.page"
                        (pageChange)="pageChanged($event)"
                        boundaryLinks="true"
                      >
                        <ng-template ngbPaginationFirst>{{
                          'SHARED.PAGINATION.PAGINATION_FIRST' | translate
                        }}</ng-template>
                        <ng-template ngbPaginationPrevious>{{
                          'SHARED.PAGINATION.PAGINATION_PREVIOUS' | translate
                        }}</ng-template>
                        <ng-template ngbPaginationNext>{{
                          'SHARED.PAGINATION.PAGINATION_NEXT' | translate
                        }}</ng-template>
                        <ng-template ngbPaginationLast>{{
                          'SHARED.PAGINATION.PAGINATION_LAST' | translate
                        }}</ng-template>
                      </ngb-pagination>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- product sales discounts list ends -->

<!-- no items template starts -->
<ng-template #noItems>
  <div class="col-md-12 alert alert-warning mt-3 text-center" role="alert">
    {{ 'STORES.PRODUCTS.SEARCH_RESULT_PRODUCT_SALES_DISCOUNTS_NOT_FOUND' | translate }}
  </div>
</ng-template>
<!-- no items template ends -->

<!-- delete product discount modal starts -->
<ng-template #deleteModalRef let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title text-danger" id="modal-basic-title">
      {{ 'STORES.PRODUCTS.CONFIRM_DELETE_PRODUCT_SALES_DISCOUNTS_TITLE' | translate }}
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="d()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-12">
        <p class="text-danger">{{ 'STORES.PRODUCTS.CONFIRM_DELETE_PRODUCT_SALES_DISCOUNTS_MESSAGE' | translate }}</p>
      </div>
      <div class="form-group col-6">
        <label for="selectedProductDiscount.product.description">{{
          'STORES.PRODUCTS.PRODUCT_DATA.PRODUCT_NAME' | translate
        }}</label>
        <input
          type="text"
          id="selectedProductDiscount.product.description"
          class="form-control round"
          [placeholder]="'STORES.PRODUCTS.PRODUCT_DATA.PRODUCT_NAME_PLACEHOLDER' | translate"
          readonly
          [value]="
            selectedProductDiscount.product.description
              | localize: selectedProductDiscount.product.descriptionEn
              | async
          "
        />
      </div>
      <div class="form-group col-6">
        <label for="selectedProductDiscount.product.productClass.name">{{
          'STORES.PRODUCTS.PRODUCT_DATA.PRODUCT_CLASS' | translate
        }}</label>
        <input
          type="text"
          id="selectedProductDiscount.product.productClass.name"
          class="form-control round"
          [placeholder]="'STORES.PRODUCTS.PRODUCT_DATA.PRODUCT_CLASS' | translate"
          readonly
          [value]="
            selectedProductDiscount.product.productClass.name
              | localize: selectedProductDiscount.product.productClass.nameEn
              | async
          "
        />
      </div>
      <div class="form-group col-6">
        <label for="selectedProductDiscount.discount">{{ 'STORES.PRODUCTS.PRODUCT_DATA.DISCOUNT' | translate }}</label>
        <input
          type="number"
          id="selectedProductDiscount.discount"
          class="form-control round"
          [placeholder]="'STORES.PRODUCTS.PRODUCT_DATA.DISCOUNT_PLACEHOLDER' | translate"
          readonly
          [value]="selectedProductDiscount.discount"
        />
      </div>
      <div class="form-group col-6">
        <label for="selectedProductDiscount.discountPercent">{{
          'STORES.PRODUCTS.PRODUCT_DATA.DISCOUNT_PERCENT' | translate
        }}</label>
        <input
          type="number"
          id="selectedProductDiscount.discountPercent"
          class="form-control round"
          [placeholder]="'STORES.PRODUCTS.PRODUCT_DATA.DISCOUNT_PERCENT_PLACEHOLDER' | translate"
          readonly
          [value]="selectedProductDiscount.discountPercent"
        />
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="modal-actions">
      <button
        type="button"
        class="btn btn-raised btn-danger round"
        (click)="c(); delete(selectedProductDiscount.id)"
        ngbAutofocus
        [disabled]="isDeleting$ | async"
      >
        <i class="fa fa-check"></i>
        <span class="mx-1"> {{ 'SHARED.CONFIRM.DELETE' | translate }} </span>
      </button>
      <button type="button" class="btn btn-outline-dark round" (click)="c()">
        <i class="fa fa-times"></i>
        <span class="mx-1"> {{ 'SHARED.CONFIRM.CANCEL' | translate }} </span>
      </button>
    </div>
  </div>
</ng-template>
<!-- delete product discount modal ends -->

<!-- product classes search starts -->
<app-product-classes-search (productClasses)="selectClasses($event)" [(visible)]="classesListVisibility">
</app-product-classes-search>
<!-- product classes search ends -->

<!-- locations search starts -->
<app-locations-search [(visible)]="locationsListVisibility" (locations)="selectLocations($event)">
</app-locations-search>
<!-- locations search ends -->
