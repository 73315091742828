import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import * as fromComponents from 'projects-management/components';
import * as fromContainers from 'projects-management/containers';
import { Claims } from 'security/models';
import { ConfirmationGuard } from 'auth/guards';

const routes: Routes = [
  //projects routes.
  {
    path: 'projects/create',
    component: fromComponents.CreateProjectComponent,
    canDeactivate: [ConfirmationGuard],
    data: {
      claims: [Claims.CREATE_PROJECT, Claims.UPDATE_PROJECT, Claims.DELETE_PROJECT],
    },
  },
  {
    path: 'projects',
    component: fromComponents.ProjectsListComponent,
    data: {
      claims: [Claims.CREATE_PROJECT, Claims.UPDATE_PROJECT, Claims.DELETE_PROJECT],
    },
  },
  {
    path: 'projects/files/:projectId',
    component: fromContainers.ProjectFileComponent,
    data: {
      claims: [Claims.CREATE_PROJECT, Claims.UPDATE_PROJECT, Claims.DELETE_PROJECT],
    },
  },
  {
    path: 'projects/project-resources/files/:projectResourceId',
    component: fromContainers.ProjectResourcesFileComponent,
    data: {
      claims: [Claims.CREATE_PROJECT, Claims.UPDATE_PROJECT, Claims.DELETE_PROJECT],
    },
  },
  {
    path: 'projects/revenues/view/:revenueId',
    component: fromContainers.ViewRevenueComponent,
    data: {},
  },
  {
    path: 'projects/revenues',
    component: fromComponents.ProjectRevenuesComponent,
    data: {},
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ProjectsManagementRoutingModule {}
