import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { FormArray, FormGroup, FormControl } from '@angular/forms';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { ProductClassFormItem, ProductClass } from 'stores/models';

@Component({
  selector: 'app-product-classes-form',
  templateUrl: './product-classes-form.component.html',
  styles: [],
})
export class ProductClassesFormComponent {
  /**
   * The confirm modal template reference.
   */
  @ViewChild('deleteModalRef') deleteModalRef: ElementRef<any>;

  /**
   * Sets the product classes form-array.
   */
  @Input() formArray: FormArray;

  /**
   * Sets the name form-array in its parent form.
   * @default 'productClasses'
   */
  @Input() formArrayName = 'productClasses';

  /**
   * Adds a list of new product class form items to the product class form.
   * @param items The list of product class form items to be added to the product class form.
   */
  @Input() set newProductClasses(items: ProductClassFormItem[]) {
    if (!items?.length) {
      return;
    }

    items.forEach((item) => this.createProductClassFormGroup(item));
  }

  /**
   * Sets a value indicates whether if the user should specify a product class or not.
   * @default false
   */
  @Input() isProductClassRequired = false;

  /**
   * Shows or hides the product class list.
   */
  productClassesListVisibility = false;

  /**
   * Indicates whether if we can add product class or not.
   */
  @Input() allowAddProductClass = true;

  /**
   * Indicates whether if we can clear product class or not.
   * @default `true`.
   */
  @Input() allowClearProductClasses = true;

  /**
   * @param modalService The modal service.
   */
  constructor(private modalService: NgbModal) {}

  /**
   * Creates & adds a new product class form-group with validations.
   * @param product class The product class details to be bounded to the product class, If omitted a blank product class will be created.
   */
  addProductClass() {
    this.productClassesListVisibility = true;
  }

  /**
   * Open confirm delete all product class modal .
   */
  openDeleteModal() {
    this.openModal(this.deleteModalRef);
  }

  /**
   * Opens the modal of the given templateRef.
   * @param modalRef The modal templateRef to be opened.
   */
  openModal(modalRef) {
    this.modalService.open(modalRef);
  }

  /**
   * Remove the product class at the given index from the product class form.
   * @param index The index of the product class form group.
   */
  removeProductClass(index: number) {
    this.formArray.removeAt(index);
  }

  /**
   * Remove all of the list of product classes.
   *
   * It only adds one product class for quick start.
   */
  resetProductClasses() {
    this.formArray.clear();
  }

  /**
   * Handles the product class select event.
   * @param product class The newly selected product class.
   */
  selectProductClass(productClasses: ProductClass[]) {
    if (productClasses) {
      productClasses.map((productClass) =>
        this.createProductClassFormGroup(this.createProductClassFormItemFromProductClass(productClass))
      );
    }
  }

  /**
   * Creates and returns a new product class form item from a given product class.
   * @param product class The product class to be used to create the product class form item.
   */
  createProductClassFormItemFromProductClass(productClass: ProductClass): ProductClassFormItem {
    return {
      productClassId: productClass.id,
      productClass,
    };
  }

  /**
   * Create a new product class form group from the provided product class form item.
   * @param product class The product class form item that contains data about the new product class.
   */
  createProductClassFormGroup(productClass: ProductClassFormItem) {
    if (this.isProductClassExists(productClass.productClass.id)) {
      return;
    }

    const formGroup = new FormGroup({
      productClassId: new FormControl(productClass?.productClassId ?? null),
      productClass: new FormControl(productClass?.productClass),
    });
    this.formArray.push(formGroup);
  }

  /**
   * Indicates whether if the given product class id is already exists in the form array or not.
   * @param productClassId The id of the product class.
   * @returns `boolean`.
   */
  isProductClassExists(productClassId: number): boolean {
    return (this.formArray.value as ProductClassFormItem[]).some(
      (productClassItem) => productClassItem.productClass.id === productClassId
    );
  }
}
