import { createSelector } from '@ngrx/store';

import { RegionsState, StoresState } from 'stores/store/states';
import { selectStoresState } from 'stores/store/selectors/feature.selectors';

/**
 * Gets the regions state.
 */
const selectRegionsState = createSelector(selectStoresState, (state: StoresState) => state.regions);

/**
 * Gets the list of loaded regions.
 */
export const getRegions = createSelector(selectRegionsState, (state: RegionsState) => state.data);

/**
 * Gets the regions pagination info.
 */
export const getRegionsPaginationInfo = createSelector(
  selectRegionsState,
  (state: RegionsState) => state.paginationInfo
);

/**
 * Gets the error that occurred in the state.
 */
export const getRegionsError = createSelector(selectRegionsState, (state: RegionsState) => state.error);

/**
 * Determines if there is a running search process.
 */
export const getRegionsSearching = createSelector(selectRegionsState, (state: RegionsState) => state.isSearching);

/**
 * Determines if the last search process has been ended successfully.
 */
export const getRegionsSearchCompleted = createSelector(
  selectRegionsState,
  (state: RegionsState) => state.isSearchCompleted
);

/**
 * Gets the selected region.
 */
export const getSelectedRegion = createSelector(selectRegionsState, (state: RegionsState) => state.selectedRegion);

/**
 * Determines if there is a running find region process.
 */
export const getRegionFinding = createSelector(selectRegionsState, (state: RegionsState) => state.isFinding);

/**
 * Determines if the last find region process has been ended successfully.
 */
export const getRegionFindCompleted = createSelector(
  selectRegionsState,
  (state: RegionsState) => state.isFindCompleted
);

/**
 * Determines if there is a running create process.
 */
export const getSelectedRegionCreating = createSelector(selectRegionsState, (state: RegionsState) => state.isCreating);

/**
 * Determines if the last create process has been ended successfully.
 */
export const getSelectedRegionCreateCompleted = createSelector(
  selectRegionsState,
  (state: RegionsState) => state.isCreateCompleted
);

/**
 * Determines if there is a running update process.
 */
export const getSelectedRegionUpdating = createSelector(selectRegionsState, (state: RegionsState) => state.isUpdating);

/**
 * Determines if the last update process has been ended successfully.
 */
export const getSelectedRegionUpdateCompleted = createSelector(
  selectRegionsState,
  (state: RegionsState) => state.isUpdateCompleted
);

/**
 * Determines if there is a running delete process.
 */
export const getSelectedRegionDeleting = createSelector(selectRegionsState, (state: RegionsState) => state.isDeleting);

/**
 * Determines if the last delete process has been ended successfully.
 */
export const getSelectedRegionDeleteCompleted = createSelector(
  selectRegionsState,
  (state: RegionsState) => state.isDeleteCompleted
);
