<!-- units of measure search modal starts -->
<ng-template #modalRef let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title text-primary" id="modal-basic-title">
      {{ 'STORES.UNITS_OF_MEASURE.UNIT_OF_MEASURE_LIST' | translate }}
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="d(); closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <!-- units of measure list starts -->
    <div class="row">
      <div class="col-md-12">
        <form class="row" [formGroup]="searchForm" (ngSubmit)="search()">
          <div class="form-group col">
            <label for="name">{{ 'STORES.UNITS_OF_MEASURE.UNIT_OF_MEASURE_NAME_SEARCH' | translate }}</label>
            <input
              type="text"
              id="name"
              class="form-control round"
              [placeholder]="'STORES.UNITS_OF_MEASURE.UNIT_OF_MEASURE_NAME_PLACEHOLDER' | translate"
              formControlName="name"
              tabindex="0"
              (input)="search($event)"
              ngbAutofocus
            />
          </div>
          <div class="form-group col">
            <label for="unitOfMeasureClasses">{{ 'STORES.UNITS_OF_MEASURE.UNIT_OF_MEASURE_CLASS' | translate }}</label>
            <ng-select
              class="round"
              formControlName="unitOfMeasureClasses"
              [multiple]="true"
              [closeOnSelect]="false"
              labelForId="unitOfMeasureClasses"
              searchable="true"
              [placeholder]="'STORES.UNITS_OF_MEASURE.UNIT_OF_MEASURE_CLASS_PLACEHOLDER' | translate"
              [notFoundText]="'SHARED.SEARCH.SEARCH_NOT_FOUND' | translate"
              (open)="unitOfMeasureClassesListVisibility = true"
            >
              <ng-option *ngFor="let unitOfMeasureClass of unitOfMeasureClasses" [value]="unitOfMeasureClass.id">
                {{ unitOfMeasureClass.name | localize: unitOfMeasureClass.nameEn | async }}
              </ng-option>
            </ng-select>
          </div>
          <div class="col-md-auto form-group d-flex">
            <button
              type="submit"
              class="btn btn-raised btn-primary align-self-end round mx-1 mb-0"
              [disabled]="isSearching$ | async"
            >
              <i class="fa fa-search"></i>
            </button>
          </div>
        </form>
      </div>
      <div class="col-md-12" *ngIf="(unitsOfMeasure$ | async)?.length; else noItems">
        <div class="row">
          <div class="col-md-12 alert alert-light text-center" role="alert">
            {{ 'SHARED.SEARCH.SEARCH_RESULTS' | translate }} ({{ (paginationInfo$ | async).total | toNumber }})
            {{ 'STORES.UNITS_OF_MEASURE.UNIT_OF_MEASURE' | translate }}
          </div>
          <div class="col-md-12">
            <div class="table-responsive">
              <table class="table text-center">
                <thead>
                  <tr>
                    <th class="fit-width" *ngIf="!closeOnSelect">
                      <a
                        class="primary p-0"
                        (click)="selectUnitOfMeasure(unitsOfMeasureList)"
                        [ngbTooltip]="'SHARED.SEARCH.SELECT_ALL' | translate"
                      >
                        <i class="fa fa-check-circle font-medium-3"></i>
                      </a>
                    </th>
                    <th class="fit-width" *ngIf="closeOnSelect">
                      <i class="fa fa-gear font-medium-3"></i>
                    </th>
                    <th scope="col"><i class="fa fa-list-ol"></i></th>
                    <th scope="col">{{ 'STORES.UNITS_OF_MEASURE.UNIT_OF_MEASURE' | translate }}</th>
                    <th scope="col">{{ 'STORES.UNITS_OF_MEASURE.UNIT_OF_MEASURE_CLASS' | translate }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let unitOfMeasure of unitsOfMeasure$ | async; trackBy: trackItems; let i = index">
                    <td class="fit-width">
                      <a
                        class="primary p-0"
                        (click)="selectUnitOfMeasure([unitOfMeasure])"
                        [ngbTooltip]="'STORES.UNITS_OF_MEASURE.TOOL_TIP_SELECT' | translate"
                      >
                        <i class="fa fa-check-circle-o font-medium-3"></i>
                      </a>
                    </td>
                    <th scope="row">{{ i + 1 }}</th>
                    <td>{{ unitOfMeasure.name | localize: unitOfMeasure.nameEn | async }}</td>
                    <td>
                      {{
                        unitOfMeasure.unitOfMeasureClass.name
                          | localize: unitOfMeasure.unitOfMeasureClass.nameEn
                          | async
                      }}
                    </td>
                  </tr>
                </tbody>
              </table>
              <div class="d-flex justify-content-between p-2">
                <ngb-pagination
                  *ngIf="paginationInfo$ | async; let pagination"
                  maxSize="5"
                  [collectionSize]="pagination.total"
                  [pageSize]="pagination.pageSize"
                  [page]="pagination.page"
                  (pageChange)="pageChanged($event)"
                  boundaryLinks="true"
                >
                  <ng-template ngbPaginationFirst>{{ 'SHARED.PAGINATION.PAGINATION_FIRST' | translate }}</ng-template>
                  <ng-template ngbPaginationPrevious>{{
                    'SHARED.PAGINATION.PAGINATION_PREVIOUS' | translate
                  }}</ng-template>
                  <ng-template ngbPaginationNext>{{ 'SHARED.PAGINATION.PAGINATION_NEXT' | translate }}</ng-template>
                  <ng-template ngbPaginationLast>{{ 'SHARED.PAGINATION.PAGINATION_LAST' | translate }}</ng-template>
                </ngb-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- units of measure list ends -->
  </div>
  <div class="modal-footer">
    <div class="modal-actions">
      <button type="button" class="btn btn-outline-dark round" (click)="c(); closeModal()">
        <i class="fa fa-times"></i>
        <span class="mx-1"> {{ 'SHARED.CONFIRM.CLOSE' | translate }} </span>
      </button>
    </div>
  </div>
</ng-template>
<!-- units of measure search modal ends -->

<!-- no items template starts -->
<ng-template #noItems>
  <div class="col-md-12 alert alert-warning mt-3 text-center" role="alert">
    {{ 'STORES.UNITS_OF_MEASURE.NO_SEARCH_RESULT_UNIT_OF_MEASURE' | translate }}
  </div>
</ng-template>
<!-- no items template ends -->

<!-- unit of measure classes search starts -->
<app-unit-of-measure-classes-search
  onlyActive="true"
  (unitOfMeasureClasses)="selectUnitOfMeasureClasses($event)"
  [(visible)]="unitOfMeasureClassesListVisibility"
>
</app-unit-of-measure-classes-search>
<!-- unit of measure classes search ends -->
