<!-- page header starts -->
<app-page-header [info]="pageInfo"></app-page-header>
<!-- page header ends -->

<!-- create bank account log form starts -->
<div class="card">
  <div class="card-content">
    <div class="px-3 pt-3">
      <form (ngSubmit)="submit()">
        <div class="row">
          <div class="col-md-12">
            <ul class="nav-tabs" ngbNav #nav="ngbNav" [(activeId)]="activePage" [destroyOnHide]="false">
              <li ngbNavItem="details">
                <a ngbNavLink>
                  <i class="fa fa-file-text" [class.success]="activePage == 'details'"></i>
                  {{ 'FINANCES.BANK_ACCOUNTS.BANK_ACCOUNT_WITHDRAW_FROM-ACCOUNT_DETAILS' | translate }}
                </a>
                <!-- create bank account log starts -->
                <ng-template ngbNavContent>
                  <div class="row">
                    <div class="col-md-12">
                      <form [formGroup]="form">
                        <div class="card">
                          <div class="card-header mb-3">
                            <h4 class="card-title">
                              {{ 'FINANCES.BANK_ACCOUNTS.BANK_ACCOUNT_WITHDRAW_FROM-ACCOUNT_DATA' | translate }}
                            </h4>
                          </div>
                          <!-- bank account log details starts -->
                          <div class="card-content">
                            <div class="px-3">
                              <div class="row">
                                <div class="form-group col-md-6">
                                  <label for="bankAccountName">{{
                                    'FINANCES.BANK_ACCOUNTS.BANK_ACCOUNT_DATA.THE_BANK_ACCOUNT' | translate
                                  }}</label>
                                  <input
                                    type="text"
                                    id="bankAccountName"
                                    readonly
                                    class="form-control round"
                                    [value]="bankAccountName"
                                    [placeholder]="
                                      'FINANCES.BANK_ACCOUNTS.BANK_ACCOUNT_DATA.BANK_ACCOUNT_PLACEHOLDER' | translate
                                    "
                                  />
                                </div>
                                <div class="form-group col-md-6">
                                  <label for="currencyName">{{
                                    'FINANCES.BANK_ACCOUNTS.BANK_ACCOUNT_DATA.CURRENCY_NAME' | translate
                                  }}</label>
                                  <input
                                    type="text"
                                    id="currencyName"
                                    readonly
                                    class="form-control round"
                                    [value]="currencyName"
                                    [placeholder]="
                                      'FINANCES.BANK_ACCOUNTS.BANK_ACCOUNT_DATA.CURRENCY_NAME_PLACEHOLDER' | translate
                                    "
                                  />
                                </div>
                                <div class="form-group col-md-6">
                                  <label for="value">{{
                                    'FINANCES.BANK_ACCOUNTS.BANK_ACCOUNT_DATA.VALUE_WITHDRAW' | translate
                                  }}</label>
                                  <input
                                    type="text"
                                    [mask]="DECIMAL_MASK"
                                    thousandSeparator=","
                                    minLength="0"
                                    id="value"
                                    class="form-control round"
                                    [placeholder]="
                                      'FINANCES.BANK_ACCOUNTS.BANK_ACCOUNT_DATA.VALUE_WITHDRAW_PLACEHOLDER' | translate
                                    "
                                    formControlName="value"
                                  />
                                </div>
                                <div class="form-group col-md-6">
                                  <label for="extraDetails">{{
                                    'FINANCES.BANK_ACCOUNTS.BANK_ACCOUNT_DATA.EMPLOYEE_DATA' | translate
                                  }}</label>
                                  <textarea
                                    minlength="1"
                                    maxlength="100"
                                    rows="1"
                                    class="form-control round"
                                    [placeholder]="
                                      'FINANCES.BANK_ACCOUNTS.BANK_ACCOUNT_DATA.EMPLOYEE_DATA_PLACEHOLDER' | translate
                                    "
                                    id="extraDetails"
                                    formControlName="extraDetails"
                                  ></textarea>
                                </div>
                                <div class="form-group col-md-6">
                                  <label for="transactionDate">{{
                                    'FINANCES.BANK_ACCOUNTS.BANK_ACCOUNT_DATA.BANK_ACCOUNT_WITHDRAW_DATE' | translate
                                  }}</label>
                                  <div class="input-group round">
                                    <input
                                      class="form-control"
                                      id="transactionDate"
                                      name="transactionDate"
                                      formControlName="transactionDate"
                                      [placeholder]="'SHARED.SEARCH.DATE_PLACEHOLDER' | translate"
                                      ngbDatepicker
                                      #transactionDate="ngbDatepicker"
                                    />
                                    <div class="input-group-append">
                                      <span
                                        class="input-group-text gradient-light-blue-indigo"
                                        style="cursor: pointer;"
                                        (click)="transactionDate.toggle()"
                                      >
                                        <i class="fa fa-calendar"></i>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <div class="form-group col-md-12">
                                  <label for="notes">{{
                                    'FINANCES.BANK_ACCOUNTS.BANK_ACCOUNT_DATA.NOTES' | translate
                                  }}</label>
                                  <textarea
                                    id="notes"
                                    class="form-control round"
                                    [placeholder]="
                                      'FINANCES.BANK_ACCOUNTS.BANK_ACCOUNT_DATA.NOTES_PLACEHOLDER' | translate
                                    "
                                    rows="1"
                                    minlength="0"
                                    maxlength="200"
                                    formControlName="notes"
                                  ></textarea>
                                </div>
                              </div>
                            </div>
                          </div>
                          <!-- bank account log details ends -->

                          <!-- bank account log attachments starts -->
                          <div class="card-header mb-3 pt-1">
                            <h4 class="card-title">
                              {{ 'FINANCES.BANK_ACCOUNTS.BANK_ACCOUNT_DATA.ATTACHMENTS' | translate }}
                            </h4>
                          </div>
                          <div class="card-content">
                            <div class="px-3">
                              <app-files-upload
                                formArrayName="attachments"
                                [filesForm]="attachmentsForm"
                              ></app-files-upload>
                            </div>
                          </div>
                          <!-- bank account log attachments ends -->
                        </div>
                      </form>
                    </div>
                  </div>
                </ng-template>
                <!-- create bank account log ends -->
              </li>

              <li ngbNavItem="journal">
                <a ngbNavLink>
                  <i class="fa fa-calendar" [class.success]="activePage == 'journal'"></i>
                  {{ 'SHARED.JOURNAL_DATA.JOURNAL' | translate }}
                </a>
                <!-- create journal form starts -->
                <ng-template ngbNavContent>
                  <div class="row">
                    <div class="col-md-12">
                      <form [formGroup]="form">
                        <div class="card">
                          <!-- journal starts -->
                          <!-- journal lines details starts -->
                          <div class="card-header mb-3">
                            <h4 class="card-title">{{ 'SHARED.JOURNAL_DATA.JOURNAL_LINES' | translate }}</h4>
                          </div>
                          <div class="card-content">
                            <div class="px-3">
                              <app-journal-line-form
                                [journalLinesForm]="journalLinesForm"
                                [isNotesRequired]="false"
                                [allowAddLine]="false"
                                [allowClearLines]="false"
                                [allowRemoveLine]="false"
                                [initialJournalLines]="initialJournalLinesSubject.asObservable() | async"
                              ></app-journal-line-form>
                            </div>
                          </div>
                          <!-- journal ends -->
                        </div>
                      </form>
                    </div>
                  </div>
                </ng-template>
                <!-- create journal form ends -->
              </li>
            </ul>
            <div [ngbNavOutlet]="nav" class="mt-2"></div>
          </div>
        </div>

        <!-- bank account log tools starts -->
        <div class="row">
          <div class="form-group col-12 d-flex justify-content-between flex-column flex-sm-row mt-2">
            <button type="submit" class="btn btn-raised btn-success round" [disabled]="isCreating$ | async">
              <i class="fa fa-check"></i>
              <span class="mx-1">{{ 'SHARED.CONFIRM.SAVE' | translate }}</span>
            </button>
            <button
              type="button"
              class="btn btn-raised btn-danger round"
              (click)="openResetModal()"
              [disabled]="isCreating$ | async"
            >
              <i class="fa fa-trash"></i>
              <span class="mx-1">{{ 'FINANCES.BANK_ACCOUNTS.BANK_ACCOUNT_DATA.RESET_DATA' | translate }}</span>
            </button>
            <a class="btn btn-dark round" [routerLink]="['/finances/bank-accounts/files', bankAccountId]">
              <i class="fa fa-undo"></i>
              <span class="mx-1">{{
                'FINANCES.BANK_ACCOUNTS.BANK_ACCOUNT_DATA.BANK_ACCOUNTS_SUMMARY_RETURN' | translate
              }}</span>
            </a>
          </div>
        </div>
        <!-- bank account log tools ends -->
      </form>
    </div>
  </div>
</div>
<!-- create bank account log form ends -->

<!-- confirm modal starts -->
<ng-template #confirmModalRef let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
      {{ 'FINANCES.BANK_ACCOUNTS.CONFIRM_CREATE_BANK_ACCOUNT_WITHDRAW_TITLE' | translate }}
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="d()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p class="text-success">
      {{ 'FINANCES.BANK_ACCOUNTS.CONFIRM_CREATE_BANK_ACCOUNT_WITHDRAW_MESSAGE' | translate }}
    </p>
  </div>
  <div class="modal-footer">
    <div class="modal-actions">
      <button type="button" class="btn btn-raised btn-success round" (click)="c(); confirm()" ngbAutofocus>
        <i class="fa fa-check"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.SAVE' | translate }}</span>
      </button>
      <button type="button" class="btn btn-outline-dark round" (click)="c()">
        <i class="fa fa-times"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.CANCEL' | translate }}</span>
      </button>
    </div>
  </div>
</ng-template>
<!-- confirm modal ends -->

<!-- confirm reset modal starts -->
<ng-template #resetModalRef let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title text-danger" id="modal-basic-title">
      {{ 'FINANCES.BANK_ACCOUNTS.CONFIRM_RESET_BANK_ACCOUNT_WITHDRAW_FROM_ACCOUNT_TITLE' | translate }}
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="d()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p class="text-danger">
      {{ 'FINANCES.BANK_ACCOUNTS.CONFIRM_RESET_BANK_ACCOUNT_WITHDRAW_FROM_ACCOUNT_TITLE_MESSAGE' | translate }}
    </p>
  </div>
  <div class="modal-footer">
    <div class="modal-actions">
      <button type="button" class="btn btn-raised btn-danger round" (click)="c(); resetForm()" ngbAutofocus>
        <i class="fa fa-check"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.YES' | translate }}</span>
      </button>
      <button type="button" class="btn btn-outline-dark round" (click)="c()">
        <i class="fa fa-times"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.CANCEL' | translate }}</span>
      </button>
    </div>
  </div>
</ng-template>
<!-- confirm reset modal ends -->
