import { Injectable } from '@angular/core';

import { of } from 'rxjs';
import { switchMap, map, catchError, debounceTime } from 'rxjs/operators';
import { Effect, ofType, Actions } from '@ngrx/effects';

import { PAGINATION } from 'shared';
import { SectorsService } from 'app-search/services';
import { SectorsActionType, SearchSectors, SearchSectorsFail, SearchSectorsSuccess } from 'app-search/store/actions';

@Injectable()
export class SectorsEffects {
  constructor(private actions$: Actions, private SectorsService: SectorsService) {}

  /* ========================= SEARCH_SECTORS =================================== */
  @Effect()
  search$ = this.actions$.pipe(
    ofType(SectorsActionType.SEARCH_SECTORS),
    debounceTime(300),
    switchMap(({ payload }: SearchSectors) =>
      this.SectorsService.search(payload?.name ?? '', payload.page, PAGINATION.Sectors).pipe(
        map((response) => new SearchSectorsSuccess(response)),
        catchError((error) => of(new SearchSectorsFail(error)))
      )
    )
  );
}
