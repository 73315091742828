import { Injectable } from '@angular/core';

import { v4 as uuid } from 'uuid';

import { Jwt } from 'auth/models';
import { ProductsFormOptions } from 'stores/models';
import { environment } from '../../../environments/environment';

/**
 * Contains the functionality for manipulating local-storage.
 */
@Injectable()
export class StorageService {
  /**
   * Gets the device id.
   * @returns The device id.
   */
  get deviceId(): string {
    return localStorage.getItem('deviceId');
  }

  /**
   * Saves the given json web token to the local-storage.
   * @param jwt The json web token object to be serialized and saved to local-storage.
   * @param persistToken Indicates whether the token-cookie will be expired when the user closes
   * the application or will stay in the local-storage for a time.
   */
  setJwt(jwt: Jwt, persistToken: boolean) {
    /**
     * The time in which the token will be removed from the local-storage.
     */
    const tokenAge = new Date(Date.now());

    const expireTime = tokenAge.setDate(tokenAge.getDate() + environment.accessTokenExpiration); // After e.g. 30 days from now.
    localStorage.setItem('jwtExpireTime', expireTime.toString());
    localStorage.setItem('jwt', JSON.stringify(jwt));
    this.checkExpiry(persistToken, parseInt(localStorage.getItem('jwtExpireTime')));
  }

  /**
   * check if the  json web token in the local-storage expire or no.
   */
  checkExpiry(persistToken: boolean, expireTime: number) {
    const currentDate = Date.now();
    if (persistToken && currentDate >= expireTime) {
      localStorage.removeItem('jwt');
      localStorage.removeItem('jwtExpireTime');
    }
  }

  /**
   * Returns the saved json web token in the local-storage if it exists.
   */
  getJwt(): Jwt {
    return this.isJwtExists() ? JSON.parse(localStorage.getItem('jwt')) : null;
  }

  /**
   * Determines if the json web token exists in the local-storage or not.
   */
  isJwtExists(): boolean {
    if (!localStorage.getItem('jwt')) {
      return false;
    }
    return true;
  }

  /**
   * Deletes the json web token saved in the local-storage.
   */
  deleteJwt() {
    localStorage.removeItem('jwt');
    localStorage.removeItem('jwtExpireTime');
  }

  /**
   * Saves the given redirect-url to the local-storage.
   * @param redirectUrl The url to redirect after authenticate the user.
   */
  setRedirectUrl(redirectUrl: string) {
    localStorage.setItem('redirectUrl', JSON.stringify(encodeURIComponent(redirectUrl)));
  }

  /**
   * Returns the saved redirect-url in the local-storage if it exists.
   */
  getRedirectUrl() {
    return decodeURIComponent(JSON.parse(localStorage.getItem('redirectUrl')));
  }

  /**
   * Determines if the redirect-url exists in the local-storage or not.
   */
  redirectUrlExists() {
    if (!localStorage.getItem('redirectUrl')) {
      return false;
    }
    return true;
  }

  /**
   * Deletes the redirect-url saved in the local-storage.
   */
  deleteRedirectUrl() {
    return localStorage.removeItem('redirectUrl');
  }

  /**
   * Sets the products form options.
   */
  setProductsFormOptions(options: ProductsFormOptions) {
    localStorage.setItem('products-form-options', JSON.stringify(options));
  }

  /**
   * Get the products form options.
   */
  getProductsFormOptions(): ProductsFormOptions | null {
    if (localStorage.getItem('products-form-options')) {
      return JSON.parse(localStorage.getItem('products-form-options'));
    }
    return null;
  }

  /**
   * Sets the device id if it not exists.
   */
  setDeviceId(): void {
    if (!this.deviceId) {
      localStorage.setItem('deviceId', uuid());
    }
  }
}
