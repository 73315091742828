import { ParkedSaleInvoice } from 'sales/models';

/**
 * Represents the parked sale invoices state.
 */
export class ParkedSaleInvoicesState {
  /**
   * The list of parked sale invoices.
   */
  public data: ParkedSaleInvoice[];
}
