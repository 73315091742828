import { Pipe, PipeTransform } from '@angular/core';
import { DecimalPipe } from '@angular/common';

import { APP_CONSTANTS } from 'shared/models';

/**
 * Transforms the given number into an integer format with thousands separator but without decimal fractions.
 */
@Pipe({
  name: 'toNumber',
})
export class ToNumberPipe extends DecimalPipe implements PipeTransform {
  transform(value: any): any {
    return super.transform(value, APP_CONSTANTS.numeric.integer.format);
  }
}
