import { AppSearchState } from 'app-search/store/states';
import { ActionReducerMap } from '@ngrx/store';
import { customersReducer } from 'app-search/store/reducers/customers.reducer';
import { usersReducer } from 'app-search/store/reducers/users.reducer';
import { vendorsReducer } from 'app-search/store/reducers/vendors.reducer';
import { posDevicesReducer } from 'app-search/store/reducers/pos-devices.reducer';
import { employeesReducer } from 'app-search/store/reducers/employees.reducer';
import { locationsReducer } from 'app-search/store/reducers/locations.reducer';
import { departmentsReducer } from 'app-search/store/reducers/departments.reducer';
import { bankAccountsReducer } from 'app-search/store/reducers/bank-accounts.reducer';
import { banksReducer } from 'app-search/store/reducers/banks.reducer';
import { subTasksReducer } from 'app-search/store/reducers/sub-tasks.reducer';
import { projectsReducer } from 'app-search/store/reducers/projects.reducer';
import { govServicesReducer } from 'app-search/store/reducers/gov-services.reducer';
import { regionsReducer } from 'stores/store/reducers/regions.reducer';
import { sectorsReducer } from 'stores/store/reducers/sectors.reducer';

/**
 * The App-Search-Module store reducers.
 */
export const reducers: ActionReducerMap<AppSearchState> = {
  customers: customersReducer,
  users: usersReducer,
  vendors: vendorsReducer,
  posDevices: posDevicesReducer,
  employees: employeesReducer,
  locations: locationsReducer,
  departments: departmentsReducer,
  bankAccounts: bankAccountsReducer,
  banks: banksReducer,
  subTasks: subTasksReducer,
  projects: projectsReducer,
  govServices: govServicesReducer,
  regions: regionsReducer,
  sectors: sectorsReducer,
};
