<!-- page header starts -->
<app-page-header [info]="pageInfo"></app-page-header>
<!-- page header ends -->

<!-- create petty cash refund requests approval form starts -->
<div class="card">
  <div class="card-content">
    <div class="px-3 pt-3">
      <form (ngSubmit)="submit()">
        <div class="row">
          <div class="col-md-12">
            <ul class="nav-tabs" ngbNav #nav="ngbNav" [(activeId)]="activePage" [destroyOnHide]="false">
              <li ngbNavItem="details">
                <a ngbNavLink>
                  <i class="fa fa-file-text" [class.success]="activePage == 'details'"></i>
                  {{
                    'FINANCES.PETTY_CASH_REFUND_REQUEST_APPROVALS.PETTY_CASH_REFUND_REQUEST_APPROVAL_DATA_TITLE'
                      | translate
                  }}
                </a>
                <!-- create petty cash refund requests approval starts -->
                <ng-template ngbNavContent>
                  <div class="row">
                    <div class="col-md-12">
                      <form [formGroup]="form">
                        <div class="card">
                          <!-- petty cash refund requests approval details starts -->
                          <div class="card-header mb-3">
                            <h4 class="card-title">
                              {{
                                'FINANCES.PETTY_CASH_REFUND_REQUEST_APPROVALS.PETTY_CASH_REFUND_REQUEST_APPROVAL_DATA_TITLE'
                                  | translate
                              }}
                            </h4>
                          </div>
                          <div class="card-content">
                            <div class="px-3">
                              <div class="row">
                                <div class="form-group col-md-6">
                                  <label for="pettyCashRefundRequestId">{{
                                    'FINANCES.PETTY_CASH_REFUND_REQUEST_APPROVALS.PETTY_CASH_REFUND_REQUEST_APPROVAL_DATA.PETTY_CASH_REFUND_REQUEST'
                                      | translate
                                  }}</label>
                                  <ng-select
                                    class="round"
                                    formControlName="pettyCashRefundRequestId"
                                    labelForId="pettyCashRefundRequestId"
                                    searchable="true"
                                    [placeholder]="
                                      'FINANCES.PETTY_CASH_REFUND_REQUEST_APPROVALS.PETTY_CASH_REFUND_REQUEST_APPROVAL_DATA.PETTY_CASH_REFUND_REQUEST_PLACEHOLDER'
                                        | translate
                                    "
                                    [notFoundText]="'SHARED.SEARCH.SEARCH_NOT_FOUND' | translate"
                                    (open)="pettyCashRefundRequestListVisibility = true"
                                  >
                                    <ng-option *ngFor="let request of pettyCashRefundRequests" [value]="request.id">
                                      <span>{{ request.pettyCash.description }}</span>
                                      <i class="fa fa-usd c-primary mx-1"></i>
                                      <span class="mx-1">{{ request.pettyCash.value | toDecimal }}</span>
                                      <i class="fa fa-lock c-primary mx-1"></i>
                                      <span class="mx-1">{{
                                        request.pettyCash.maxAllowedPaymentValue | toDecimal
                                      }}</span>
                                    </ng-option>
                                  </ng-select>
                                </div>
                                <div class="form-group col-md-6">
                                  <label for="transactionDate">{{
                                    'FINANCES.PETTY_CASH_REFUND_REQUEST_APPROVALS.PETTY_CASH_REFUND_REQUEST_APPROVAL_DATA.APPROVAL_DATE'
                                      | translate
                                  }}</label>
                                  <div class="input-group round">
                                    <input
                                      class="form-control"
                                      id="transactionDate"
                                      name="transactionDate"
                                      formControlName="transactionDate"
                                      [placeholder]="'SHARED.SEARCH.DATE_PLACEHOLDER' | translate"
                                      ngbDatepicker
                                      #transactionDate="ngbDatepicker"
                                    />
                                    <div class="input-group-append">
                                      <span
                                        class="input-group-text gradient-light-blue-indigo"
                                        style="cursor: pointer;"
                                        (click)="transactionDate.toggle()"
                                      >
                                        <i class="fa fa-calendar"></i>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <div class="form-group col-md-12">
                                  <label for="notes">{{
                                    'FINANCES.PETTY_CASH_REFUND_REQUEST_APPROVALS.PETTY_CASH_REFUND_REQUEST_APPROVAL_DATA.NOTES'
                                      | translate
                                  }}</label>
                                  <textarea
                                    id="notes"
                                    class="form-control round"
                                    [placeholder]="
                                      'FINANCES.PETTY_CASH_REFUND_REQUEST_APPROVALS.PETTY_CASH_REFUND_REQUEST_APPROVAL_DATA.NOTES_PLACEHOLDER'
                                        | translate
                                    "
                                    rows="1"
                                    minlength="0"
                                    maxlength="200"
                                    formControlName="notes"
                                  ></textarea>
                                </div>
                              </div>
                            </div>
                          </div>
                          <!-- petty cash refund requests approval details ends -->

                          <!-- petty cash refund requests approval  details starts -->
                          <div class="card-header mb-3 pt-1">
                            <h4 class="card-title">
                              {{
                                'FINANCES.PETTY_CASH_REFUND_REQUEST_APPROVALS.PETTY_CASH_REFUND_REQUEST_APPROVAL_DATA.PETTY_CASH_REFUND_REQUEST_APPROVALS'
                                  | translate
                              }}
                            </h4>
                          </div>
                          <div class="card-content">
                            <div class="px-3">
                              <app-petty-cash-refund-request-approval-form
                                [linesForm]="pettyCashRefundRequestApprovalLinesForm"
                                formArrayName="pettyCashRefundRequestApprovalLines"
                                [newLines]="newPettyCashRefundRequestApprovalLines"
                              ></app-petty-cash-refund-request-approval-form>
                            </div>
                          </div>
                          <!-- petty cash refund requests approval  details ends -->

                          <!-- request summary starts -->
                          <div class="card-header mb-3 pt-3">
                            <h4 class="card-title">
                              {{
                                'FINANCES.PETTY_CASH_REFUND_REQUEST_APPROVALS.PETTY_CASH_REFUND_REQUEST_APPROVAL_DATA.PETTY_CASH_REFUND_ACCOUNT'
                                  | translate
                              }}
                            </h4>
                          </div>
                          <div class="card-content">
                            <div class="px-3">
                              <div class="row">
                                <div class="col-md-12">
                                  <div class="row">
                                    <div class="form-group col-md-4">
                                      <label for="requestApprovalLinesTotal">{{
                                        'FINANCES.PETTY_CASH_REFUND_REQUEST_APPROVALS.PETTY_CASH_REFUND_REQUEST_APPROVAL_DATA.REFUNDS_TOTAL'
                                          | translate
                                      }}</label>
                                      <input
                                        type="text"
                                        id="requestApprovalLinesTotal"
                                        name="requestApprovalLinesTotal"
                                        class="form-control round"
                                        [value]="(requestApprovalLinesTotal | toDecimal) || '0.0'"
                                        readonly
                                      />
                                    </div>
                                    <div class="form-group col-md-4">
                                      <label for="requestApprovalLinesTax">{{
                                        'FINANCES.PETTY_CASH_REFUND_REQUEST_APPROVALS.PETTY_CASH_REFUND_REQUEST_APPROVAL_DATA.TAX_TOTAL'
                                          | translate
                                      }}</label>
                                      <input
                                        type="text"
                                        id="requestApprovalLinesTax"
                                        name="requestApprovalLinesTax"
                                        class="form-control round"
                                        [value]="(requestApprovalLinesTax | toDecimal) || '0.0'"
                                        readonly
                                      />
                                    </div>
                                    <div class="form-group col-md-4">
                                      <label
                                        for="requestApprovalTotal"
                                        class="font-weight-bold"
                                        style="color: #212529;"
                                      >
                                        <i class="fa fa-star"></i>
                                        <span class="mx-1">{{
                                          'FINANCES.PETTY_CASH_REFUND_REQUEST_APPROVALS.PETTY_CASH_REFUND_REQUEST_APPROVAL_DATA.PETTY_CASH_REFUND_TOTAL'
                                            | translate
                                        }}</span>
                                      </label>
                                      <input
                                        type="text"
                                        id="requestApprovalTotal"
                                        name="requestApprovalTotal"
                                        class="form-control round"
                                        [value]="(requestApprovalTotal | toDecimal) || '0.0'"
                                        readonly
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <!-- request summary ends -->

                          <!-- petty cash refund requests approval attachments starts -->
                          <div class="card-header mb-3 pt-1">
                            <h4 class="card-title">
                              {{
                                'FINANCES.PETTY_CASH_REFUND_REQUEST_APPROVALS.PETTY_CASH_REFUND_REQUEST_APPROVAL_DATA.ATTACHMENTS'
                                  | translate
                              }}
                            </h4>
                          </div>
                          <div class="card-content">
                            <div class="px-3">
                              <app-files-upload
                                formArrayName="attachments"
                                [filesForm]="attachmentsForm"
                              ></app-files-upload>
                            </div>
                          </div>
                          <!-- petty cash refund requests approval attachments ends -->
                        </div>
                      </form>
                    </div>
                  </div>
                </ng-template>
                <!-- create petty cash refund requests approval ends -->
              </li>
              <li ngbNavItem="journal">
                <a ngbNavLink>
                  <i class="fa fa-calendar" [class.success]="activePage == 'journal'"></i>
                  {{ 'SHARED.JOURNAL_DATA.JOURNAL' | translate }}
                </a>

                <!-- create journal form starts -->
                <ng-template ngbNavContent>
                  <div class="row">
                    <div class="col-md-12">
                      <form [formGroup]="form">
                        <div class="card">
                          <!-- journal starts -->
                          <!-- journal lines details starts -->
                          <div class="card-header mb-3">
                            <h4 class="card-title">{{ 'SHARED.JOURNAL_DATA.JOURNAL_LINES' | translate }}</h4>
                          </div>
                          <div class="card-content">
                            <div class="px-3">
                              <app-journal-line-form
                                [journalLinesForm]="journalLinesForm"
                                [isNotesRequired]="false"
                                [allowAddLine]="false"
                                [allowClearLines]="false"
                                [allowRemoveLine]="false"
                                [initialJournalLines]="initialJournalLinesSubject.asObservable() | async"
                              ></app-journal-line-form>
                            </div>
                          </div>
                          <!-- journal ends -->
                        </div>
                      </form>
                    </div>
                  </div>
                </ng-template>
                <!-- create journal form ends -->
              </li>
            </ul>
            <div [ngbNavOutlet]="nav" class="mt-2"></div>
          </div>
        </div>
        <!-- petty cash refund requests approval tools starts -->
        <div class="row mt-2">
          <div class="form-group col-12 d-flex justify-content-between flex-column flex-sm-row mt-2">
            <button type="submit" class="btn btn-raised btn-success round" [disabled]="isCreating$ | async">
              <i class="fa fa-check"></i>
              <span class="mx-1">{{ 'SHARED.CONFIRM.SAVE' | translate }}</span>
            </button>
            <a class="btn btn-dark round" routerLink="/finances/petty-cash-refund-request-approvals">
              <i class="fa fa-undo"></i>
              <span class="mx-1">{{
                'FINANCES.PETTY_CASH_REFUND_REQUEST_APPROVALS.PETTY_CASH_REFUND_REQUEST_APPROVAL_DATA.PETTY_CASH_REFUND_REQUEST_APPROVALS_RETURN'
                  | translate
              }}</span>
            </a>
          </div>
        </div>
        <!-- petty cash refund requests approval tools ends -->
      </form>
    </div>
  </div>
</div>
<!-- create petty cash refund requests approval form ends -->

<!-- confirm modal starts -->
<ng-template #confirmModalRef let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
      {{
        'FINANCES.PETTY_CASH_REFUND_REQUEST_APPROVALS.CONFIRM_CREATE_PETTY_CASH_REFUND_REQUEST_APPROVAL_TITLE'
          | translate
      }}
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="d()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p class="text-success">
      {{
        'FINANCES.PETTY_CASH_REFUND_REQUEST_APPROVALS.CONFIRM_CREATE_PETTY_CASH_REFUND_REQUEST_APPROVAL_MESSAGE'
          | translate
      }}
    </p>
  </div>
  <div class="modal-footer">
    <div class="modal-actions">
      <button type="button" class="btn btn-raised btn-success round" (click)="c(); confirm()" ngbAutofocus>
        <i class="fa fa-check"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.SAVE' | translate }}</span>
      </button>
      <button type="button" class="btn btn-outline-dark round" (click)="c()">
        <i class="fa fa-times"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.CANCEL' | translate }}</span>
      </button>
    </div>
  </div>
</ng-template>
<!-- confirm modal ends -->

<!-- petty cash refund requests search starts -->
<app-petty-cash-refund-requests-search
  [(visible)]="pettyCashRefundRequestListVisibility"
  [closeOnSelect]="true"
  (pettyCashRefundRequest)="selectPettyCashRefundRequest($event)"
>
</app-petty-cash-refund-requests-search>
<!-- petty cash refund requests search ends -->
