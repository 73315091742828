import { LanguagesState } from 'lookups/store/states';

/**
 * The initial state from which the state starts.
 */
const initialState: LanguagesState = {
  data: [
    {
      id: 1,
      name: 'العربية',
      nameEn: 'Arabic',
      key: 'ar',
    },
    {
      id: 2,
      name: 'الإنجليزية',
      nameEn: 'English',
      key: 'en',
    },
  ],
};

/**
 * The reducer function that is called in each action dispatch against the state.
 * @param state The current state.
 * @param action The action that will affect the state.
 */
export function languagesReducer(state: LanguagesState = initialState, action: any): LanguagesState {
  switch (action.type) {
    default:
      return state;
  }
}
