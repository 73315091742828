import { CreatePettyCashReductionComponent } from 'finances/components/petty-cash-reductions/create-petty-cash-reduction/create-petty-cash-reduction.component';
import { PettyCashReductionsListComponent } from 'finances/components/petty-cash-reductions/petty-cash-reductions-list/petty-cash-reductions-list.component';

export * from 'finances/components/petty-cash-reductions/create-petty-cash-reduction/create-petty-cash-reduction.component';
export * from 'finances/components/petty-cash-reductions/petty-cash-reductions-list/petty-cash-reductions-list.component';

/**
 * The set of components registered on this sub-module.
 */
export const COMPONENTS: any[] = [CreatePettyCashReductionComponent, PettyCashReductionsListComponent];
