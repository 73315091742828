import { MaritalStatus } from 'lookups/models';

/**
 * Represents the marital status state.
 */
export class MaritalStatusState {
  /**
   * The list of marital statuses.
   */
  public data: MaritalStatus[];

  /**
   * An error that may resulting during processing some actions.
   */
  public error: any;

  /**
   * Determines if there is a running load process.
   */
  public isLoading: boolean;

  /**
   * Determines if the last load process has been ended successfully.
   */
  public isLoaded: boolean;
}
