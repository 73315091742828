import { Injectable } from '@angular/core';

import { of } from 'rxjs';
import { switchMap, map, catchError, debounceTime } from 'rxjs/operators';
import { Effect, ofType, Actions } from '@ngrx/effects';

import { PAGINATION } from 'shared';
import { LocationsService } from 'app-search/services';
import {
  LocationsActionType,
  SearchLocations,
  SearchLocationsFail,
  SearchLocationsSuccess,
} from 'app-search/store/actions';

@Injectable()
export class LocationsEffects {
  constructor(private actions$: Actions, private locationsService: LocationsService) {}

  /* ========================= SEARCH_LOCATIONS =================================== */
  @Effect()
  search$ = this.actions$.pipe(
    ofType(LocationsActionType.SEARCH_LOCATIONS),
    debounceTime(300),
    switchMap(({ payload }: SearchLocations) =>
      this.locationsService
        .search(
          payload?.name ?? '',
          payload.regions,
          payload.sectors,
          payload.locationTypes,
          payload.page,
          PAGINATION.Locations
        )
        .pipe(
          map((response) => new SearchLocationsSuccess(response)),
          catchError((error) => of(new SearchLocationsFail(error)))
        )
    )
  );
}
