import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';

import { AppHttpResponse } from 'shared';
import { StatisticsChartData, StatisticsData } from 'statistics/models';

/**
 * The statistics services includes functionality to get statistics.
 */
@Injectable()
export class StatisticsService {
  /**
   * The relative route for the statistics.
   * No leading or trailing slashes required.
   */
  private statisticsApi = 'statistics';

  constructor(private http: HttpClient) {}

  /**
   * get the statistics.
   */
  public get(
    day: number,
    month: number,
    year: number,
    isDay: boolean,
    isWeek: boolean,
    isMonth: boolean,
    locations: number[]
  ): Observable<AppHttpResponse<StatisticsData>> {
    const params = new HttpParams()
      .set('day', day.toString())
      .set('month', month.toString())
      .set('year', year.toString())
      .set('isDay', isDay.toString())
      .set('isWeek', isWeek.toString())
      .set('isMonth', isMonth.toString())
      .set('locations', locations.join(','));
    return this.http.get<AppHttpResponse<StatisticsData>>(`${this.statisticsApi}`, { params });
  }

  /**
   * get the statistics charts.
   */
  public getStatisticsCharts(
    day: number,
    month: number,
    year: number,
    isDay: boolean,
    isWeek: boolean,
    isMonth: boolean,
    locations: number[]
  ): Observable<AppHttpResponse<StatisticsChartData[]>> {
    const params = new HttpParams()
      .set('day', day.toString())
      .set('month', month.toString())
      .set('year', year.toString())
      .set('isDay', isDay.toString())
      .set('isWeek', isWeek.toString())
      .set('isMonth', isMonth.toString())
      .set('locations', locations.join(','));
    return this.http.get<AppHttpResponse<StatisticsChartData[]>>(`${this.statisticsApi}/charts-statistics`, { params });
  }
}
