<div class="touch-keyboard row mx-auto mb-2">
  <div class="touch-btn-group">
    <button type="button" class="btn btn-raised btn-secondary touch-btn" (click)="insertText('1')">1</button>
    <button type="button" class="btn btn-raised btn-secondary touch-btn" (click)="insertText('2')">2</button>
    <button type="button" class="btn btn-raised btn-secondary touch-btn" (click)="insertText('3')">3</button>
    <button type="button" class="btn btn-raised btn-secondary touch-btn" (click)="addValue(10)">+10</button>
  </div>
  <div class="touch-btn-group">
    <button type="button" class="btn btn-raised btn-secondary touch-btn" (click)="insertText('4')">4</button>
    <button type="button" class="btn btn-raised btn-secondary touch-btn" (click)="insertText('5')">5</button>
    <button type="button" class="btn btn-raised btn-secondary touch-btn" (click)="insertText('6')">6</button>
    <button type="button" class="btn btn-raised btn-secondary touch-btn" (click)="addValue(20)">+20</button>
  </div>
  <div class="touch-btn-group">
    <button type="button" class="btn btn-raised btn-secondary touch-btn" (click)="insertText('7')">7</button>
    <button type="button" class="btn btn-raised btn-secondary touch-btn" (click)="insertText('8')">8</button>
    <button type="button" class="btn btn-raised btn-secondary touch-btn" (click)="insertText('9')">9</button>
    <button type="button" class="btn btn-raised btn-secondary touch-btn" (click)="addValue(30)">+30</button>
  </div>
  <div class="touch-btn-group">
    <button type="button" class="btn btn-raised btn-secondary touch-btn" (click)="insertText('0')">0</button>
    <button type="button" class="btn btn-raised btn-secondary touch-btn font-weight-bold" (click)="insertText('.')">
      .
    </button>
    <button type="button" class="btn btn-raised btn-secondary touch-btn" (click)="clearText()">
      <i class="fa fa-close"></i>
    </button>
    <button type="button" class="btn btn-raised btn-secondary touch-btn" (click)="addValue(50)">+50</button>
  </div>
</div>
