<!-- overtimes modal starts -->
<ng-template #modalRef let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title text-primary" id="modal-basic-title">
      {{ 'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.OVERTIMES' | translate }}
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="d(); closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="col-md-12">
      <div class="table-responsive">
        <table class="table table-sm text-center">
          <thead>
            <tr>
              <th scope="col"><i class="fa fa-list-ol"></i></th>
              <th scope="col">{{ 'HR.OVERTIMES.OVERTIME_DATA.HOURS_COUNT' | translate }}</th>
              <th scope="col">{{ 'HR.OVERTIMES.OVERTIME_DATA.OVERTIME_RATE' | translate }}</th>
              <th scope="col">
                {{ 'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.VALUE_TITLE' | translate }}
              </th>
              <th scope="col">{{ 'HR.OVERTIMES.OVERTIME_DATA.TRANSACTION_DATE' | translate }}</th>
              <th scope="col">
                {{ 'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.NOTES' | translate }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let payrollOvertime of payrollOvertimes; trackBy: trackItems; let i = index">
              <th scope="row">{{ i + 1 }}</th>
              <td>{{ payrollOvertime.employeeOvertime.hoursCount | toDecimal }}</td>
              <td>
                {{
                  payrollOvertime.employeeOvertime.overtimeRate.name
                    | localize: payrollOvertime.employeeOvertime.overtimeRate.nameEn
                    | async
                }}
              </td>

              <td>{{ payrollOvertime.value | toDecimal }}</td>
              <td class="fit-width">
                <span [ngbTooltip]="payrollOvertime.employeeOvertime.transactionDate | date: 'time'">
                  {{ payrollOvertime.employeeOvertime.transactionDate | date }}
                </span>
              </td>
              <td>{{ payrollOvertime.employeeOvertime.notes }}</td>
            </tr>
          </tbody>
          <tfoot class="tfoot-light">
            <tr>
              <td [attr.colspan]="6">
                <i class="fa fa-bookmark c-primary"></i>
                <span>
                  {{ payrollOvertimes.length | toNumber }}
                  {{ 'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.OVERTIME' | translate }}
                </span>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="modal-actions">
      <button type="button" class="btn btn-outline-dark round" (click)="c(); closeModal()">
        <i class="fa fa-times"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.CLOSE' | translate }}</span>
      </button>
    </div>
  </div>
</ng-template>
<!-- overtimes modal ends -->

<!-- no items template starts -->
<ng-template #noItems>
  <div class="col-md-12 alert alert-warning mt-3 text-center" role="alert">
    {{ 'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.OVERTIMES_NOT_FOUND' | translate }}
  </div>
</ng-template>
<!-- no items template ends -->
