import { Injectable } from '@angular/core';

import { of } from 'rxjs';
import { switchMap, map, catchError } from 'rxjs/operators';
import { Effect, ofType, Actions } from '@ngrx/effects';

import { LookupsService } from 'lookups/services';
import {
  EmployeeDeductionTypeActionType,
  GetAllEmployeeDeductionTypesFail,
  GetAllEmployeeDeductionTypesSuccess,
} from 'lookups/store/actions';

@Injectable()
export class EmployeeDeductionTypeEffects {
  constructor(private actions$: Actions, private lookupsService: LookupsService) {}

  /* ========================= GET_ALL_EMPLOYEE_DEDUCTION_TYPES =================================== */
  @Effect()
  getAllEmployeeDeductionTypes$ = this.actions$.pipe(
    ofType(EmployeeDeductionTypeActionType.GET_ALL_EMPLOYEE_DEDUCTION_TYPES),
    switchMap(() =>
      this.lookupsService.getAllEmployeeDeductionTypes().pipe(
        map((response) => new GetAllEmployeeDeductionTypesSuccess(response)),
        catchError((error) => of(new GetAllEmployeeDeductionTypesFail(error)))
      )
    )
  );
}
