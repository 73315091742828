import { PaginationInfo } from 'shared/models';
import { Product, ProductSalesDiscount, ProductStock } from 'stores/models';

/**
 * Represents the products state.
 */
export class ProductState {
  /**
   * The list of products.
   */
  public data: Product[];

  /**
   * The list of product sales discounts.
   */
  public productSalesDiscountsData: ProductSalesDiscount[];

  /**
   * The number of product discount .
   */
  public discountData: number;

  /**
   * The list of products for sales screen.
   */
  public productsForSalesScreen: Product[];

  /**
   * The pagination info for the products.
   */
  public paginationInfo: PaginationInfo;

  /**
   * The pagination info for the product sales discounts.
   */
  public paginationProductSalesDiscountsInfo: PaginationInfo;

  /**
   * An error that may resulting during processing some actions.
   */
  public error: any;

  /**
   * Determines if there is a running search process.
   */
  public isSearching: boolean;

  /**
   * Determines if the last search process has been ended successfully.
   */
  public isSearchCompleted: boolean;

  /**
   * Determines if there is a running search product sales discounts process.
   */
  public isProductSalesDiscountsSearching: boolean;

  /**
   * Determines if the last search products sales discounts process has been ended successfully.
   */
  public isProductSalesDiscountsSearchCompleted: boolean;

  /**
   * Determines if there is a running search products for sales screen process.
   */
  public isProductsForSalesScreenSearching: boolean;

  /**
   * Determines if the last search products for sales screen process has been ended successfully.
   */
  public isProductsForSalesScreenSearchCompleted: boolean;

  /**
   * The current selected product.
   */
  public selectedProduct: Product;

  /**
   * The current selected product sales discount.
   */
  public selectedProductSalesDiscount: ProductSalesDiscount;

  /**
   * Determines if there is a running find-product process.
   */
  public isFinding: boolean;

  /**
   * Determines if the last find-product process has been ended successfully.
   */
  public isFindCompleted: boolean;

  /**
   * The current selected product founded by code or barcode.
   */
  public selectedProductByCodeOrBarcode: Product;

  /**
   * Determines if there is a running find-product-by-code-or-barcode process.
   */
  public isFindingByCodeOrBarcode: boolean;

  /**
   * Determines if the last find-product-by-code-or-barcode process has been ended successfully.
   */
  public isFindByCodeOrBarcodeCompleted: boolean;

  /**
   * Determines if there is a running create process.
   */
  public isCreating: boolean;

  /**
   * Determines if the last create process has been ended successfully.
   */
  public isCreateCompleted: boolean;

  /**
   * Determines if there is a running update process.
   */
  public isUpdating: boolean;

  /**
   * Determines if the last update process has been ended successfully.
   */
  public isUpdateCompleted: boolean;

  /**
   * Determines if there is a running product discounts create process.
   */
  public isProductDiscountsCreating: boolean;

  /**
   * Determines if the last product discounts create process has been ended successfully.
   */
  public isProductDiscountsCreateCompleted: boolean;

  /**
   * Determines if there is a running delete process.
   */
  public isDeleting: boolean;

  /**
   * Determines if the last delete process has been ended successfully.
   */
  public isDeleteCompleted: boolean;

  /**
   * The product stocks.
   */
  public productStock: ProductStock;

  /**
   * Determines if there is a running load process.
   */
  public isProductStockLoading: boolean;

  /**
   * Determines if the last load process has been ended successfully.
   */
  public isProductStockLoaded: boolean;

  /**
   * Determines if there is a running update product sales discount process.
   */
  public isProductSalesDiscountUpdating: boolean;

  /**
   * Determines if the last update product sales discount process has been ended successfully.
   */
  public isProductSalesDiscountUpdateCompleted: boolean;

  /**
   * Determines if there is a running delete product sales discount process.
   */
  public isProductSalesDiscountDeleting: boolean;

  /**
   * Determines if the last delete product sales discount process has been ended successfully.
   */
  public isProductSalesDiscountDeleteCompleted: boolean;

  /**
   * Determines if there is a running update product unit of measure rate process.
   */
  public isProductUnitOfMeasureRateUpdating: boolean;

  /**
   * Determines if the last update product unit of measure rate process has been ended successfully.
   */
  public isProductUnitOfMeasureRateUpdateCompleted: boolean;

  /**
   * Determines if there is a running delete product unit of measure rate process.
   */
  public isProductUnitOfMeasureRateDeleting: boolean;

  /**
   * Determines if the last delete product unit of measure rate process has been ended successfully.
   */
  public isProductUnitOfMeasureRateDeleteCompleted: boolean;
}
