import { Component, OnInit, Input, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import { Observable, Subscription } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store, select } from '@ngrx/store';
import { skip, tap } from 'rxjs/operators';

import { NotificationService, NotificationMessage, TranslationService, APP_CONSTANTS } from 'shared';
import { CustomerEngagement, CustomerEngagementValueChangeLog } from 'sales/models';
import { CustomerEngagementType } from 'lookups/models';
import * as fromSalesStore from 'sales/store';
import * as fromLookupsStore from 'lookups/store';
import { Claims } from 'security/models';

@Component({
  selector: 'app-customer-engagements',
  templateUrl: './customer-engagements.component.html',
  styles: [],
})
export class CustomerEngagementsComponent implements OnInit, OnDestroy {
  /**
   * The create modal template reference.
   */
  @ViewChild('createModalRef') createModalRef: ElementRef<any>;

  /**
   * The update modal template reference.
   */
  @ViewChild('updateModalRef') updateModalRef: ElementRef<any>;

  /**
   * The delete modal template reference.
   */
  @ViewChild('deleteModalRef') deleteModalRef: ElementRef<any>;

  /**
   * The value change logs modal template reference.
   */
  @ViewChild('valueChangeLogsModalRef') valueChangeLogsModalRef: ElementRef<any>;

  /**
   * The decimal mask.
   */
  readonly DECIMAL_MASK = APP_CONSTANTS.numeric.decimal.mask;

  /**
   * Sets the currently customer id.
   */
  @Input() customerId: number;

  /**
   * The system supported user claims.
   */
  Claims = Claims;

  /**
   * The list of loaded customer engagements.
   */
  engagements$: Observable<CustomerEngagement[]>;

  /**
   * The set of customer engagement types.
   */
  engagementTypes$: Observable<CustomerEngagementType[]>;

  /**
   * The create customer engagement form.
   */
  createForm: FormGroup;

  /**
   * The update customer engagement form.
   */
  updateForm: FormGroup;

  /**
   * The delete customer engagement form.
   */
  deleteForm: FormGroup;

  /**
   * The list of selected engagement's value change logs.
   */
  customerEngagementValueChangeLogs: CustomerEngagementValueChangeLog[];

  /**
   * Indicates whether there is a create customer engagement process is running or not.
   */
  isCustomerEngagementCreating$: Observable<boolean>;

  /**
   * Indicates whether there is a update customer engagement process is running or not.
   */
  isCustomerEngagementUpdating$: Observable<boolean>;

  /**
   * Indicates whether there is a delete customer engagement process is running or not.
   */
  isCustomerEngagementDeleting$: Observable<boolean>;

  /**
   * The set of subscriptions on this components,
   * these subscriptions must be unsubscribed before this component got destroyed.
   */
  subscriptions = new Subscription();

  /**
   * Gets or sets the current selected language.
   */
  get currentLang() {
    return this.translationService.language;
  }

  /**
   * @param modalService The modal service.
   * @param notificationService The notification service.
   * @param translationService: The translation service.
   * @param salesStore$ The sales-module store.
   * @param lookupsStore$ The lookups-module store.
   */
  constructor(
    private modalService: NgbModal,
    private notificationService: NotificationService,
    private translationService: TranslationService,
    private salesStore$: Store<fromSalesStore.SalesState>,
    private lookupsStore$: Store<fromLookupsStore.LookupsState>
  ) {}

  ngOnInit(): void {
    this.init();
  }

  /**
   * Destroy component data
   */
  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  /**
   * Initialize component data.
   */
  init() {
    this.initForms();

    /**
     * reset on customer engagement creation complete
     */
    this.subscriptions.add(
      this.salesStore$
        .pipe(
          select(fromSalesStore.getSelectedCustomerEngagementCreateCompleted),
          skip(1),
          tap((created) => {
            if (created) {
              this.initForms();
              this.createForm.markAsUntouched();
            }
          })
        )
        .subscribe()
    );

    /**
     * reset on customer engagement update completed
     */
    this.subscriptions.add(
      this.salesStore$
        .pipe(
          select(fromSalesStore.getSelectedCustomerEngagementUpdateCompleted),
          skip(1),
          tap((updated) => {
            if (updated) {
              this.initForms();
              this.updateForm.markAsUntouched();
              this.closeModal();
            }
          })
        )
        .subscribe()
    );

    /**
     * reset on customer engagement delete completed
     */
    this.subscriptions.add(
      this.salesStore$
        .pipe(
          select(fromSalesStore.getSelectedCustomerEngagementDeleteCompleted),
          skip(1),
          tap((deleted) => {
            if (deleted) {
              this.initForms();
              this.deleteForm.markAsUntouched();
              this.closeModal();
            }
          })
        )
        .subscribe()
    );

    /**
     * Load data.
     */
    this.isCustomerEngagementCreating$ = this.salesStore$.pipe(
      select(fromSalesStore.getSelectedCustomerEngagementCreating)
    );
    this.isCustomerEngagementUpdating$ = this.salesStore$.pipe(
      select(fromSalesStore.getSelectedCustomerEngagementUpdating)
    );
    this.isCustomerEngagementDeleting$ = this.salesStore$.pipe(
      select(fromSalesStore.getSelectedCustomerEngagementDeleting)
    );
    this.engagements$ = this.salesStore$.pipe(select(fromSalesStore.getCustomerEngagements));

    let isManualSearchTriggeredBeforeForTypes = false;
    this.engagementTypes$ = this.lookupsStore$.pipe(
      select(fromLookupsStore.getCustomerEngagementTypes),
      tap((types) => {
        if (!isManualSearchTriggeredBeforeForTypes && !types.length) {
          isManualSearchTriggeredBeforeForTypes = true;
          this.lookupsStore$.dispatch(new fromLookupsStore.GetAllCustomerEngagementType());
        }
      })
    );

    this.search();
  }

  /**
   * Initialize forms and add validators.
   */
  initForms() {
    this.createForm = new FormGroup({
      typeId: new FormControl(null, Validators.required),
      value: new FormControl('', [, Validators.required, Validators.minLength(0)]),
      notes: new FormControl('', [Validators.minLength(0), Validators.maxLength(200)]),
    });

    this.updateForm = new FormGroup({
      id: new FormControl(null, Validators.required),
      typeName: new FormControl(null),
      value: new FormControl('', Validators.minLength(0)),
      notes: new FormControl('', [Validators.minLength(0), Validators.maxLength(200)]),
      isActive: new FormControl(false, Validators.required),
    });

    this.deleteForm = new FormGroup({
      id: new FormControl(null, Validators.required),
      typeName: new FormControl(null),
      value: new FormControl(''),
      notes: new FormControl(''),
      isActive: new FormControl({ value: false, disabled: true }),
    });

    /**
     * Dynamically set create-form validations based on the selected value.
     */
    this.subscriptions.add(this.createForm.controls.value.valueChanges.subscribe(() => this.setCreateValidations()));

    /**
     * Dynamically set update-form validations based on the selected value.
     */
    this.subscriptions.add(this.updateForm.controls.value.valueChanges.subscribe(() => this.setUpdateValidations()));
  }

  /**
   * Sets the conditional validation for the create-form based on the selected values.
   */
  setCreateValidations() {
    const value = parseInt(this.createForm.get('value').value ?? '0');

    if (value > 0) {
    } else if (value === 0) {
    }
  }

  /**
   * Sets the conditional validation for the update-form based on the selected values.
   */
  setUpdateValidations() {
    const value = parseInt(this.updateForm.get('value').value ?? '0');

    if (value > 0) {
    } else if (value === 0) {
    }
  }

  /**
   * Loads the current selected customer engagements.
   */
  search() {
    this.salesStore$.dispatch(
      new fromSalesStore.SearchCustomerEngagements({
        customers: [this.customerId],
        customerEngagementTypes: [],
        page: 1,
      })
    );
  }

  /**
   * Enables trackBy feature for *ngFor utility to track items
   * depending on a comparer to reduce DOM change.
   * If the item is already exist then no HTML will change, only bounded values.
   * @param index The index of the item.
   * @param engagement The item to determine if it was changed or not.
   */
  trackItems(index: number, engagement: CustomerEngagement) {
    return engagement ? engagement.id : undefined;
  }

  /**
   * Shows create new engagement modal.
   */
  create() {
    this.createForm.reset({ notes: '' });
    this.openModal(this.createModalRef);
  }

  /**
   * Creates a new customer engagement only if the filled data passed validations.
   */
  confirmCreate() {
    if (this.createForm.invalid) {
      const errorMessage = new NotificationMessage();

      if (this.createForm.get('typeId').invalid) {
        errorMessage.title = this.translationService.translate(
          'SALES.CUSTOMER_ENGAGEMENTS.CUSTOMER_ENGAGEMENT_DATA_VALIDATION.CUSTOMER_ENGAGEMENT_TYPE_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate(
            'SALES.CUSTOMER_ENGAGEMENTS.CUSTOMER_ENGAGEMENT_DATA_VALIDATION.CUSTOMER_ENGAGEMENT_TYPE_IS_REQUIRED'
          ),
        ];
      } else if (this.createForm.get('value').invalid) {
        errorMessage.title = this.translationService.translate(
          'SALES.CUSTOMER_ENGAGEMENTS.CUSTOMER_ENGAGEMENT_DATA_VALIDATION.CUSTOMER_ENGAGEMENT_VALUE_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate(
            'SALES.CUSTOMER_ENGAGEMENTS.CUSTOMER_ENGAGEMENT_DATA_VALIDATION.CUSTOMER_ENGAGEMENT_VALUE_LENGTH_ERROR'
          ),
        ];
      } else if (this.createForm.get('notes').invalid) {
        errorMessage.title = this.translationService.translate(
          'SALES.CUSTOMER_ENGAGEMENTS.CUSTOMER_ENGAGEMENT_DATA_VALIDATION.NOTES_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate(
            'SALES.CUSTOMER_ENGAGEMENTS.CUSTOMER_ENGAGEMENT_DATA_VALIDATION.NOTES_LENGTH_ERROR'
          ),
        ];
      }

      this.createForm.markAllAsTouched();
      return this.notificationService.warningWithTitle(errorMessage);
    } else {
      const { typeId, value, notes } = this.createForm.value;
      this.salesStore$.dispatch(
        new fromSalesStore.CreateCustomerEngagement({
          customerEngagementTypeId: typeId,
          value,
          customerId: this.customerId,
          notes,
        })
      );
    }
  }

  /**
   * Shows an update engagement modal for the given engagement.
   * @param engagement The engagement to be updated.
   */
  update(engagement: CustomerEngagement) {
    this.updateForm.reset({
      ...engagement,
      typeName:
        this.currentLang === 'en' ? engagement.customerEngagementType.nameEn : engagement.customerEngagementType.name,
    });
    this.openModal(this.updateModalRef);
  }

  /**
   * Updates the currently edited engagement only if the filled data passed validations.
   */
  confirmUpdate() {
    if (this.updateForm.invalid) {
      const errorMessage = new NotificationMessage();

      if (this.updateForm.get('id').invalid) {
        errorMessage.title = this.translationService.translate(
          'SALES.CUSTOMER_ENGAGEMENTS.NOTIFICATION_MESSAGE.SELECT_CUSTOMER_ENGAGEMENT'
        );
        errorMessage.body = [];
      } else if (this.updateForm.get('value').invalid) {
        errorMessage.title = this.translationService.translate(
          'SALES.CUSTOMER_ENGAGEMENTS.CUSTOMER_ENGAGEMENT_DATA_VALIDATION.CUSTOMER_ENGAGEMENT_VALUE_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate(
            'SALES.CUSTOMER_ENGAGEMENTS.CUSTOMER_ENGAGEMENT_DATA_VALIDATION.CUSTOMER_ENGAGEMENT_VALUE_LENGTH_ERROR'
          ),
        ];
      } else if (this.updateForm.get('notes').invalid) {
        errorMessage.title = this.translationService.translate(
          'SALES.CUSTOMER_ENGAGEMENTS.CUSTOMER_ENGAGEMENT_DATA_VALIDATION.NOTES_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate(
            'SALES.CUSTOMER_ENGAGEMENTS.CUSTOMER_ENGAGEMENT_DATA_VALIDATION.NOTES_LENGTH_ERROR'
          ),
        ];
      }

      this.updateForm.markAllAsTouched();
      return this.notificationService.warningWithTitle(errorMessage);
    } else {
      this.salesStore$.dispatch(new fromSalesStore.UpdateCustomerEngagement(this.updateForm.value));
    }
  }

  /**
   * Shows an delete engagement modal for the given engagement.
   * @param engagement The engagement to be deleted.
   */
  delete(engagement: CustomerEngagement) {
    this.deleteForm.reset({
      ...engagement,
      typeName:
        this.currentLang === 'en' ? engagement.customerEngagementType.nameEn : engagement.customerEngagementType.name,
      notes: engagement.notes,
    });
    this.openModal(this.deleteModalRef);
  }

  /**
   * Deletes the currently selected engagement.
   */
  confirmDelete() {
    if (this.deleteForm.invalid) {
      this.deleteForm.markAllAsTouched();
      return this.notificationService.warning(
        this.translationService.translate(
          'SALES.CUSTOMER_ENGAGEMENTS.CUSTOMER_ENGAGEMENT_DATA_VALIDATION.CUSTOMER_ENGAGEMENT_ERROR'
        )
      );
    } else {
      this.salesStore$.dispatch(new fromSalesStore.DeleteCustomerEngagement(this.deleteForm.value?.id));
    }
  }

  /**
   * Shows a value change logs engagement modal for the given engagement value change logs.
   * @param logs The engagement to be viewed.
   */
  viewValueChangeLogs(logs: CustomerEngagementValueChangeLog[]) {
    this.customerEngagementValueChangeLogs = logs;
    this.openModal(this.valueChangeLogsModalRef, 'lg');
  }

  /**
   * Opens the modal of the given templateRef.
   * @param modalRef The modal templateRef to be opened.
   * @param modalSize An optional modal size, default is medium.
   */
  openModal(modalRef, modalSize?: string) {
    this.modalService.open(modalRef, { size: modalSize });
  }

  /**
   * Closes the currently opened modal.
   */
  closeModal() {
    this.modalService.dismissAll();
  }
}
