import { Params } from '@angular/router';

/**
 * Represents the state of the router.
 */
export class RouterStateUrl {
  /**
   * The url for the current navigation route.
   */
  public url: string;

  /**
   * The params included in the url.
   */
  public params: Params;

  /**
   * The query params included in the url.
   */
  public queryParams: Params;
}
