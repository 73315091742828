import { Component, ElementRef, ViewChild, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { orderBy } from 'lodash';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { PageInfo, TranslationService } from 'shared';
import * as fromFinancesStore from 'finances/store';
import { GovServiceRequest, GovServiceRequestAttachment, GovServiceRequestEmployee } from 'hr/models';

@Component({
  selector: 'gov-service-request-details',
  templateUrl: './gov-service-request-details.component.html',
  styles: [],
})
export class GovServiceRequestDetailsComponent implements OnInit {
  /**
   * The confirm view attachments modal template reference.
   */
  @ViewChild('viewAttachmentsModalRef') viewAttachmentsModalRef: ElementRef<any>;

  /**
   * Gets or sets the information about the current page.
   */
  pageInfo: PageInfo = {
    title: 'HR.GOV_SERVICE_REQUESTS.GOV_SERVICE_REQUEST_DETAILS',
    icon: 'fa fa-money',
  };

  /**
   * Sets the gov service request id.
   */
  govServiceRequestId: number;

  /**
   * Get selected gov service request.
   */
  selectedGovServiceRequest: GovServiceRequest;

  /**
   * The current viewed gov service request.
   */
  govServiceRequest$: Observable<GovServiceRequest>;

  constructor(
    private modalService: NgbModal,
    private route: ActivatedRoute,
    private financesStore$: Store<fromFinancesStore.FinancesState>,
    private translationService: TranslationService
  ) {}

  ngOnInit(): void {
    this.init();
  }

  /**
   * Initialize component data.
   */
  init() {
    /* Load the selected gov service request line. */
    this.govServiceRequestId = +this.route.snapshot.params.govServiceRequestId;

    /* Load the selected gov service request line. */
    this.govServiceRequest$ = this.financesStore$.pipe(
      select(fromFinancesStore.getSelectedGovServiceRequest),
      tap((govServiceRequest) => {
        if (govServiceRequest) {
          /* Update page info. */
          this.pageInfo = {
            ...this.pageInfo,
            subTitle: this.translationService.translate('HR.GOV_SERVICE_REQUESTS.GOV_SERVICE_REQUEST_NUMBER', {
              govServiceRequestNumber: govServiceRequest.id,
            }),
          };
        }
      })
    );

    this.financesStore$.dispatch(new fromFinancesStore.FindGovServiceRequest(this.govServiceRequestId));
  }

  /**
   * Maps and returns the set of gov service request refund request line attachments into a set of urls.
   * @param attachments The gov service request refund request line attachments to be mapped.
   */
  getAttachmentsUrls(attachments: GovServiceRequestAttachment[]): string[] {
    return attachments ? attachments.map((attachment) => attachment.url) : [];
  }

  /**
   * Opens the modal of the given templateRef.
   * @param modalRef The modal templateRef to be opened.
   */
  openModal(modalRef) {
    this.modalService.open(modalRef);
  }

  /**
   * Orders the given list according to employee person number.
   * @param data The data to be ordered.
   * @returns The order list.
   */
  orderEmployees(data: GovServiceRequestEmployee[]): GovServiceRequestEmployee[] {
    return orderBy(data, (item) => item.employee?.employeeAssignmentDetail.personNumber);
  }
}
