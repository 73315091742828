import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';

import { Observable } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { tap } from 'rxjs/operators';

import { PageInfo, PaginationInfo } from 'shared';
import * as fromFinancesStore from 'finances/store';
import { Currency } from 'finances/models';
import { Claims } from 'security/models';


@Component({
  selector: 'app-currency-list',
  templateUrl: './currency-list.component.html',
  styles: [],
})
export class CurrencyListComponent implements OnInit {
  /**
   * Gets or sets the information about the current page.
   */
  pageInfo: PageInfo = {
    title: 'FINANCES.CURRENCIES.CURRENCY_DATA.CURRENCIES',
    icon: 'fa fa-money',
  };

  /**
   * The system supported user claims.
   */
  Claims = Claims;

  /**
   * The list of currency.
   */
  currency$: Observable<Currency[]>;

  /**
   * Indicates whether there is a delete-currency process is running or not.
   */
  isDeleting$: Observable<boolean>;

  /**
   * The pagination info.
   */
  paginationInfo$: Observable<PaginationInfo>;

  /**
   * The search form.
   */
  searchForm: FormGroup;

  /**
   * Gets or sets the currently selected currency for delete.
   */
  selectedCurrency: Currency;

  /**
   * @param modalService The modal service.
   * @param financesStore$ The finances store.
   */
  constructor(private modalService: NgbModal, private financesStore$: Store<fromFinancesStore.FinancesState>) {}

  ngOnInit(): void {
    this.init();
  }

  /**
   * Initialize component data.
   */
  init() {
    this.initForm();

    /**
     * Load data.
     */
    this.isDeleting$ = this.financesStore$.pipe(select(fromFinancesStore.getSelectedCurrencyDeleting));

    let isManualSearchTriggeredBeforeForCurrency = false;
    this.currency$ = this.financesStore$.pipe(
      select(fromFinancesStore.getCurrencies),
      tap((currencies) => {
        if (!isManualSearchTriggeredBeforeForCurrency && !currencies.length) {
          isManualSearchTriggeredBeforeForCurrency = true;
          this.search();
        }
      })
    );

    this.paginationInfo$ = this.financesStore$.pipe(select(fromFinancesStore.getCurrenciesPaginationInfo));
  }

  /**
   * Initialize form and add validators.
   */
  initForm() {
    this.searchForm = new FormGroup({
      name: new FormControl(''),
      page: new FormControl(1),
    });
  }

  /**
   * Handles search parameters change.
   */
  search(event?: KeyboardEvent) {
    if (event && event.key === 'Enter') {
      event.preventDefault();
      return;
    }

    this.applyFiltersAndSearch();
  }

  /**
   * Handles pagination page-changed event.
   * @param page The current selected page number.
   */
  pageChanged(page: number) {
    /** Update pagination page. */
    this.searchForm.patchValue({ page });

    this.applyFiltersAndSearch(page);
  }

  /**
   * Enables trackBy feature for *ngFor utility to track elements
   * depending on a comparer to reduce DOM change.
   * If the element is already exist then no HTML will change, only bounded values.
   * @param element The element to determine if it was changed or not.
   */
  trackElements(element: Currency) {
    return element ? element.id : undefined;
  }

  applyFiltersAndSearch(page: number = 1) {
    const { name } = this.searchForm.value;
    this.financesStore$.dispatch(
      new fromFinancesStore.SearchCurrencies({
        name,
        page,
      })
    );
  }

  /**
   * Deletes the currency with the given id.
   * @param currencyId The id of the currency to be deleted.
   */
  delete(currencyId: number) {
    this.financesStore$.dispatch(new fromFinancesStore.DeleteCurrency(currencyId));
  }

  /**
   * Opens the modal of the given templateRef.
   * @param modalRef The modal templateRef to be opened.
   */
  openModal(modalRef) {
    this.modalService.open(modalRef);
  }
}
