<form [formGroup]="projectSubTaskForm">
  <div class="px-3">
    <div class="row">
      <div class="form-group col-md-6">
        <label for="name"> {{ 'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.SUB_TASK_NAME_ARABIC' | translate }}</label>
        <input
          type="text"
          id="name"
          class="form-control round"
          [placeholder]="'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.SUB_TASK_NAME_ARABIC_PLACEHOLDER' | translate"
          maxlength="50"
          formControlName="name"
        />
      </div>
      <div class="form-group col-md-6">
        <label for="nameEn"> {{ 'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.SUB_TASK_NAME_ENGLISH' | translate }}</label>
        <input
          type="text"
          id="nameEn"
          class="form-control round"
          [placeholder]="'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.SUB_TASK_NAME_ENGLISH_PLACEHOLDER' | translate"
          maxlength="50"
          formControlName="nameEn"
        />
      </div>
      <div class="form-group col-md-6">
        <label for="startDate">
          {{ 'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.START_DATE' | translate }}
        </label>
        <div class="input-group round">
          <input
            class="form-control"
            id="startDate"
            name="startDate"
            formControlName="startDate"
            [placeholder]="'SHARED.SEARCH.DATE_PLACEHOLDER' | translate"
            ngbDatepicker
            #startDate="ngbDatepicker"
          />
          <div class="input-group-append">
            <span
              class="input-group-text gradient-light-blue-indigo"
              style="cursor: pointer;"
              (click)="startDate.toggle()"
            >
              <i class="fa fa-calendar"></i>
            </span>
          </div>
        </div>
      </div>
      <div class="form-group col-md-6">
        <label for="endDate">
          {{ 'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.END_DATE' | translate }}
        </label>
        <div class="input-group round">
          <input
            class="form-control"
            id="endDate"
            name="endDate"
            formControlName="endDate"
            [placeholder]="'SHARED.SEARCH.DATE_PLACEHOLDER' | translate"
            ngbDatepicker
            #endDate="ngbDatepicker"
          />
          <div class="input-group-append">
            <span
              class="input-group-text gradient-light-blue-indigo"
              style="cursor: pointer;"
              (click)="endDate.toggle()"
            >
              <i class="fa fa-calendar"></i>
            </span>
          </div>
        </div>
      </div>
      <div class="form-group col-md-12">
        <label for="budget"> {{ 'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.BUDGET' | translate }}</label>
        <input
          type="text"
          id="budget"
          mask="separator.2"
          thousandSeparator=","
          minLength="0"
          class="form-control round"
          [placeholder]="'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.BUDGET_PLACEHOLDER' | translate"
          formControlName="budget"
        />
      </div>
      <div class="form-group col-md-6" *ngIf="isUpdated">
        <label for="cost"> {{ 'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.COST' | translate }}</label>
        <input
          type="text"
          id="cost"
          readonly
          class="form-control round"
          [placeholder]="'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.COST_PLACEHOLDER' | translate"
          [value]="this.cost | toDecimal"
        />
      </div>
      <div class="form-group col-md-6" *ngIf="isUpdated">
        <label for="remainingBudget">
          {{ 'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.REMAINING_BUDGET' | translate }}</label
        >
        <input
          type="text"
          id="remainingBudget"
          readonly
          class="form-control round"
          [placeholder]="'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.REMAINING_BUDGET_PLACEHOLDER' | translate"
          [value]="this.remainingBudget | toDecimal"
        />
      </div>
      <div class="form-group col-md-12">
        <label for="description">
          {{ 'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.SUB_TASK_DESCRIPTION_ARABIC' | translate }}</label
        >
        <textarea
          minlength="0"
          maxlength="500"
          rows="2"
          class="form-control round"
          [placeholder]="
            'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.SUB_TASK_DESCRIPTION_ARABIC_PLACEHOLDER' | translate
          "
          id="description"
          formControlName="description"
        ></textarea>
      </div>
      <div class="form-group col-md-12">
        <label for="descriptionEn">
          {{ 'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.SUB_TASK_DESCRIPTION_ENGLISH' | translate }}</label
        >
        <textarea
          minlength="0"
          maxlength="500"
          rows="2"
          class="form-control round"
          [placeholder]="
            'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.SUB_TASK_DESCRIPTION_ENGLISH_PLACEHOLDER' | translate
          "
          id="descriptionEn"
          formControlName="descriptionEn"
        ></textarea>
      </div>
    </div>
  </div>
</form>
