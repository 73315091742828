<!-- all employee payrolls list starts -->

<div class="col-md-12" *ngIf="payrollData?.length; else noItems">
  <div class="row">
    <div class="col-md-12 alert alert-light text-center" role="alert" *ngIf="paginationInfo$ | async">
      {{ 'SHARED.SEARCH.SEARCH_RESULTS' | translate }} ({{ (paginationInfo$ | async).total | toNumber }})
      {{ 'HR.EMPLOYEES.PAYROLL' | translate }}
    </div>
    <div class="col-md-12">
      <div class="table-responsive">
        <table class="table text-center" [id]="targetElementId">
          <thead>
            <tr>
              <th class="fit-width" scope="col"><i class="fa fa-list-ol"></i></th>
              <th *ngIf="isEmployeeManagement" class="fit-width" scope="col">
                {{ 'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.EMPLOYEE_NUMBER' | translate }}
              </th>
              <th *ngIf="isEmployeeManagement" class="fit-width" scope="col">
                {{ 'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.EMPLOYEE' | translate }}
              </th>
              <th *ngIf="isEmployeeManagement" class="fit-width" scope="col">
                {{ 'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.POSITION' | translate }}
              </th>
              <th *ngIf="isEmployeeManagement" class="fit-width" scope="col">
                {{ 'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.DEPARTMENT' | translate }}
              </th>
              <th *ngIf="isEmployeeManagement" class="fit-width" scope="col">
                {{ 'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.LOCATION' | translate }}
              </th>
              <th class="fit-width" scope="col">
                {{ 'HR.EMPLOYEES.EMPLOYEE_DATA.EMPLOYEE_STATUS' | translate }}
              </th>
              <th class="fit-width" scope="col">
                {{ 'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.MONTH' | translate }}
              </th>
              <th class="fit-width" scope="col">
                {{ 'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.NET' | translate }}
              </th>
              <th class="fit-width" scope="col">
                {{ 'HR.EMPLOYEES.EMPLOYEE_DATA.SALARY' | translate }}
              </th>
              <th class="fit-width" scope="col">
                {{ 'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.SALARY_FOR_GOSI' | translate }}
              </th>
              <th class="fit-width" scope="col">
                {{ 'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.EMPLOYEE_GOSI' | translate }}
              </th>
              <th class="fit-width" scope="col">
                {{ 'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.EMPLOYER_GOSI' | translate }}
              </th>
              <th class="fit-width" scope="col">
                {{ 'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.OUT_KAFALA' | translate }}
              </th>
              <th class="fit-width" scope="col">
                {{ 'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.SPONSOR' | translate }}
              </th>
              <th class="fit-width" scope="col">
                {{ 'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.LOAN_SETTLEMENTS' | translate }}
              </th>
              <th class="fit-width" scope="col">
                {{ 'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.OTHER_EARNINGS' | translate }}
              </th>
              <th class="fit-width" scope="col">
                {{ 'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.DEDUCTIONS' | translate }}
              </th>
              <th class="fit-width" scope="col">
                {{ 'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.OVERTIMES' | translate }}
              </th>
              <th class="fit-width" scope="col">
                {{ 'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.LOCATIONS_COSTS' | translate }}
              </th>
              <th class="fit-width" scope="col">
                {{ 'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.PROJECTS_COSTS' | translate }}
              </th>
              <th class="fit-width" scope="col">
                {{ 'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.PROVISIONS' | translate }}
              </th>
              <th class="fit-width" scope="col">
                {{ 'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.GOV_SERVICES' | translate }}
              </th>
              <th class="fit-width" scope="col">
                {{ 'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.UNPAID_LEAVES' | translate }}
              </th>
              <th class="fit-width" scope="col">
                {{ 'HR.EMPLOYEES.EMPLOYEE_DATA.TRANSACTION_DATE' | translate }}
              </th>
              <th class="fit-width" scope="col">{{ 'SHARED.SEARCH.CREATED_AT' | translate }}</th>
              <th class="fit-width" scope="col">{{ 'SHARED.TOOLS' | translate }}</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let employeePayroll of payrollData; trackBy: trackItems; let i = index">
              <th class="fit-width" scope="row">{{ i + 1 }}</th>
              <th *ngIf="isEmployeeManagement" class="fit-width" scope="row">
                {{ employeePayroll?.employee?.employeeAssignmentDetail.personNumber }}
              </th>
              <td *ngIf="isEmployeeManagement" class="fit-width">
                {{
                  employeePayroll?.employee?.employeePersonalDetail.fullName
                    | localize: employeePayroll?.employee?.employeePersonalDetail.fullNameEn
                    | async
                }}
              </td>
              <td *ngIf="isEmployeeManagement" class="fit-width">
                {{
                  employeePayroll?.employee?.employeeAssignmentDetail.position.name
                    | localize: employeePayroll?.employee?.employeeAssignmentDetail.position.nameEn
                    | async
                }}
              </td>
              <td *ngIf="isEmployeeManagement" class="fit-width">
                {{
                  employeePayroll?.employee?.employeeAssignmentDetail.department?.name
                    | localize: employeePayroll?.employee?.employeeAssignmentDetail.department?.nameEn
                    | async
                }}
              </td>
              <td *ngIf="isEmployeeManagement" class="fit-width">
                {{
                  employeePayroll?.employee?.employeeAssignmentDetail.location?.name
                    | localize: employeePayroll?.employee?.employeeAssignmentDetail.location?.nameEn
                    | async
                }}
              </td>
              <td class="fit-width">
                {{ employeePayroll.payrollStatus.name | localize: employeePayroll.payrollStatus.nameEn | async }}
              </td>
              <td class="fit-width">
                {{ employeePayroll.payrollDate }}
              </td>
              <td class="fit-width">
                <span
                  [ngClass]="{
                    'text-danger': employeePayroll.net < 0
                  }"
                >
                  {{ employeePayroll.net | toDecimal }}
                </span>
              </td>
              <td class="fit-width">
                <span class="d-block"
                  >{{ employeePayroll.salary | toDecimal }}
                  <a
                    *ngIf="employeePayroll.payrollRecurringElements.length"
                    class="info px-1"
                    (click)="
                      selectedPayrollElement = employeePayroll.payrollRecurringElements;
                      payrollRecurringElementsListVisibility = true
                    "
                    [ngbTooltip]="
                      'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.PAYROLL_RECURRING_ELEMENTS_INFO_DISPLAY'
                        | translate
                    "
                  >
                    <i class="fa fa-info font-medium-3"></i> </a
                ></span>
                <span class="text-danger" *ngIf="employeePayroll.workDays">
                  ({{ employeePayroll.workDays }})
                  {{ 'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.DAY' | translate }}
                </span>
              </td>
              <td class="fit-width">
                {{ employeePayroll.salaryForGosi | toDecimal }}
                <a
                  *ngIf="employeePayroll.payrollGosiPayrollElements.length"
                  class="info px-2"
                  (click)="
                    selectedPayrollGosiPayrollElements = employeePayroll.payrollGosiPayrollElements;
                    gosiPayrollElementsListVisibility = true
                  "
                  [ngbTooltip]="
                    'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.GOSI_PAYROLL_ELEMENTS_INFO_DISPLAY' | translate
                  "
                >
                  <i class="fa fa-info font-medium-3 mr-2"></i>
                </a>
              </td>
              <td class="fit-width">
                {{ employeePayroll.employeeGosi | toDecimal }}
              </td>
              <td class="fit-width">
                {{ employeePayroll.employerGosi | toDecimal }}
              </td>
              <td class="fit-width">
                <app-status
                  [status]="true"
                  [activeText]="'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.OUT_KAFALA' | translate"
                  *ngIf="employeePayroll.isOutKafala"
                >
                </app-status>
              </td>
              <td class="fit-width">
                {{ employeePayroll.employeeSponsor?.name | localize: employeePayroll.employeeSponsor?.nameEn | async }}
              </td>
              <td class="fit-width">
                <span>{{ employeePayroll.loanSettlement | toDecimal }}</span>
                <a
                  *ngIf="employeePayroll.payrollLoanSettlements.length"
                  class="info px-2"
                  (click)="
                    selectedPayrollLoanSettlements = employeePayroll.payrollLoanSettlements;
                    payrollLoanSettlementsListVisibility = true
                  "
                  [ngbTooltip]="'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.LOANS_INFO_DISPLAY' | translate"
                >
                  <i class="fa fa-info font-medium-3 mr-2"></i>
                </a>
              </td>
              <td class="fit-width">
                <span>{{ employeePayroll.otherEarnings | toDecimal }}</span>
                <a
                  *ngIf="employeePayroll.payrollOtherEarnings.length"
                  class="info px-2"
                  (click)="
                    selectedPayrollOtherEarnings = employeePayroll.payrollOtherEarnings;
                    payrollOtherEarningsListVisibility = true
                  "
                  [ngbTooltip]="
                    'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.OTHER_EARNINGS_INFO_DISPLAY' | translate
                  "
                >
                  <i class="fa fa-info font-medium-3 mr-2"></i>
                </a>
              </td>
              <td class="fit-width">
                <span>{{ employeePayroll.deductions | toDecimal }}</span>
                <a
                  *ngIf="employeePayroll.payrollDeductions.length"
                  class="info px-2"
                  (click)="
                    selectedPayrollDeductions = employeePayroll.payrollDeductions;
                    payrollDeductionsListVisibility = true
                  "
                  [ngbTooltip]="'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.DEDUCTIONS_INFO_DISPLAY' | translate"
                >
                  <i class="fa fa-info font-medium-3 mr-2"></i>
                </a>
              </td>
              <td class="fit-width">
                <span>{{ employeePayroll.overtime | toDecimal }}</span>
                <a
                  *ngIf="employeePayroll.payrollOvertimes.length"
                  class="info px-2"
                  (click)="
                    selectedPayrollOvertimes = employeePayroll.payrollOvertimes; payrollOvertimesListVisibility = true
                  "
                  [ngbTooltip]="'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.OVER_TIMES_INFO_DISPLAY' | translate"
                >
                  <i class="fa fa-info font-medium-3 mr-2"></i>
                </a>
              </td>
              <td class="fit-width">
                <a
                  *ngIf="employeePayroll.payrollLocationCosts.length"
                  class="info px-2"
                  (click)="
                    selectedPayrollLocationCosts = employeePayroll.payrollLocationCosts;
                    payrollLocationsCostsListVisibility = true
                  "
                  [ngbTooltip]="
                    'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.LOCATIONS_COSTS_INFO_DISPLAY' | translate
                  "
                >
                  <i class="fa fa-info font-medium-3 mr-2"></i>
                </a>
              </td>
              <td class="fit-width">
                <a
                  *ngIf="employeePayroll.payrollProjectCosts.length"
                  class="info px-2"
                  (click)="
                    selectedPayrollProjectCosts = employeePayroll.payrollProjectCosts;
                    payrollProjectsCostsListVisibility = true
                  "
                  [ngbTooltip]="
                    'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.PROJECTS_COSTS_INFO_DISPLAY' | translate
                  "
                >
                  <i class="fa fa-info font-medium-3 mr-2"></i>
                </a>
              </td>
              <td class="fit-width">
                <a
                  *ngIf="employeePayroll.payrollProvisions.length"
                  class="info px-2"
                  (click)="
                    selectedPayrollProvisions = employeePayroll.payrollProvisions;
                    payrollProvisionsListVisibility = true
                  "
                  [ngbTooltip]="'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.PROVISIONS_INFO_DISPLAY' | translate"
                >
                  <i class="fa fa-info font-medium-3 mr-2"></i>
                </a>
              </td>
              <td class="fit-width">
                <a
                  *ngIf="employeePayroll.payrollGovServices.length"
                  class="info px-2"
                  (click)="
                    selectedPayrollGovServices = employeePayroll.payrollGovServices;
                    payrollGovServicesListVisibility = true
                  "
                  [ngbTooltip]="
                    'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.GOV_SERVICES_INFO_DISPLAY' | translate
                  "
                >
                  <i class="fa fa-info font-medium-3 mr-2"></i>
                </a>
              </td>
              <td class="fit-width">
                <a
                  *ngIf="employeePayroll.payrollUnpaidLeaves.length"
                  class="info px-2"
                  (click)="
                    selectedPayrollUnpaidLeaves = employeePayroll.payrollUnpaidLeaves;
                    payrollUnpaidLeavesListVisibility = true
                  "
                  [ngbTooltip]="
                    'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.UNPAID_LEAVES_INFO_DISPLAY' | translate
                  "
                >
                  <i class="fa fa-info font-medium-3 mr-2"></i>
                </a>
              </td>
              <td class="fit-width">
                <span [ngbTooltip]="employeePayroll.transactionDate | date: 'time'">
                  {{ employeePayroll.transactionDate | date }}
                </span>
              </td>
              <td class="fit-width">
                <span [ngbTooltip]="employeePayroll.createdAt | date: 'time'">
                  {{ employeePayroll.createdAt | date }}
                </span>
              </td>
              <td class="fit-width">
                <a
                  *ngIf="!isEmployeeManagement"
                  class="info p-0"
                  (click)="selectedPayroll = employeePayroll; payrollInfoVisibility = true"
                  [ngbTooltip]="'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.INFO_DISPLAY' | translate"
                >
                  <i class="fa fa-info font-medium-3 mr-2"></i>
                </a>
                <a
                  *ngIf="isEmployeeManagement"
                  class="info p-0"
                  (click)="selectedPayroll = employeePayroll; managementPayrollInfoVisibility = true"
                  [ngbTooltip]="'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.INFO_DISPLAY' | translate"
                >
                  <i class="fa fa-info font-medium-3 mr-2"></i>
                </a>
                <a
                  *ngIf="isEmployeeManagement && displayEmployeeFile"
                  class="info p-0"
                  [routerLink]="['/hr/employees/files', employeePayroll?.employeeId]"
                  fragment="payrolls"
                  [ngbTooltip]="'HR.EMPLOYEES.EMPLOYEE_DATA.EMPLOYEE_FILE' | translate"
                >
                  <i class="fa fa-folder font-medium-3 mr-2"></i>
                </a>
                <a
                  *ngIf="
                    displayDeleteButton && employeePayroll.payrollStatus.key === payrollStatus.IN_REVIEW && !isDeleting
                  "
                  class="danger p-0"
                  [ngbTooltip]="'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.TOOL_TIP_DELETE' | translate"
                  (click)="selectedEmployeePayroll = employeePayroll; openModal(deleteModalRef)"
                >
                  <i class="fa fa-close font-medium-3 mr-2"></i>
                </a>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="d-flex justify-content-between p-2" *ngIf="paginationInfo$ | async">
          <ngb-pagination
            *ngIf="paginationInfo$ | async; let pagination"
            maxSize="5"
            [collectionSize]="pagination.total"
            [pageSize]="pagination.pageSize"
            [page]="pagination.page"
            (pageChange)="pageChanged($event)"
            boundaryLinks="true"
          >
            <ng-template ngbPaginationFirst>{{ 'SHARED.PAGINATION.PAGINATION_FIRST' | translate }}</ng-template>
            <ng-template ngbPaginationPrevious>{{ 'SHARED.PAGINATION.PAGINATION_PREVIOUS' | translate }}</ng-template>
            <ng-template ngbPaginationNext>{{ 'SHARED.PAGINATION.PAGINATION_NEXT' | translate }}</ng-template>
            <ng-template ngbPaginationLast>{{ 'SHARED.PAGINATION.PAGINATION_LAST' | translate }}</ng-template>
          </ngb-pagination>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- employee payrolls list ends -->

<!-- no items template starts -->
<ng-template #noItems>
  <div class="col-md-12 alert alert-warning text-center mt-2" role="alert">
    {{ 'HR.EMPLOYEES.EMPLOYEE_DATA.SEARCH_PAYROLLS_NOT_FOUND' | translate }}
  </div>
</ng-template>
<!-- no items template ends -->

<!-- delete employee payroll modal starts -->
<ng-template #deleteModalRef let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title text-danger" id="modal-basic-title">
      {{ 'HR.EMPLOYEES.EMPLOYEE_DATA.CONFIRM_DELETE_PAYROLL_TITLE' | translate }}
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="d()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-12">
        <p class="text-danger">{{ 'HR.EMPLOYEES.EMPLOYEE_DATA.CONFIRM_DELETE_PAYROLL_MESSAGE' | translate }}</p>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="modal-actions">
      <button
        type="button"
        class="btn btn-raised btn-danger round"
        (click)="c(); deletePayroll(selectedEmployeePayroll.id)"
        ngbAutofocus
        [disabled]="isDeleting"
      >
        <i class="fa fa-check"></i>
        <span class="mx-1"> {{ 'SHARED.CONFIRM.DELETE' | translate }} </span>
      </button>
      <button type="button" class="btn btn-outline-dark round" (click)="c()">
        <i class="fa fa-times"></i>
        <span class="mx-1"> {{ 'SHARED.CONFIRM.CANCEL' | translate }} </span>
      </button>
    </div>
  </div>
</ng-template>
<!-- delete employee payroll modal ends -->

<!-- payroll modal starts -->

<!-- payroll recurring element modal starts -->
<app-payroll-recurring-elements-info
  [payrollRecurringElements]="selectedPayrollElement"
  [(visible)]="payrollRecurringElementsListVisibility"
></app-payroll-recurring-elements-info>
<!-- payroll recurring element modal ends -->

<!-- gosi payroll modal starts -->
<app-payroll-gosi-payroll-elements-info
  [payrollGosiPayrollElements]="selectedPayrollGosiPayrollElements"
  [(visible)]="gosiPayrollElementsListVisibility"
></app-payroll-gosi-payroll-elements-info>
<!-- gosi payroll modal ends -->

<!-- payroll loan settlement modal starts -->
<app-payroll-loan-settlements-info
  [payrollLoanSettlements]="selectedPayrollLoanSettlements"
  [(visible)]="payrollLoanSettlementsListVisibility"
></app-payroll-loan-settlements-info>
<!-- payroll modal ends -->

<!-- payroll other earning modal starts -->
<app-payroll-other-earnings-info
  [payrollOtherEarnings]="selectedPayrollOtherEarnings"
  [(visible)]="payrollOtherEarningsListVisibility"
></app-payroll-other-earnings-info>
<!-- payroll other earning modal ends -->

<!-- payroll deduction modal starts -->
<app-payroll-deductions-info
  [payrollDeductions]="selectedPayrollDeductions"
  [(visible)]="payrollDeductionsListVisibility"
></app-payroll-deductions-info>
<!-- payroll deduction modal ends -->

<!-- payroll overtimes modal starts -->
<app-payroll-overtimes-info
  [payrollOvertimes]="selectedPayrollOvertimes"
  [(visible)]="payrollOvertimesListVisibility"
></app-payroll-overtimes-info>
<!-- payroll overtimes modal ends -->

<!-- payroll locations costs modal starts -->
<app-payroll-locations-costs-info
  [payrollLocationCosts]="selectedPayrollLocationCosts"
  [(visible)]="payrollLocationsCostsListVisibility"
></app-payroll-locations-costs-info>
<!-- payroll locations costs modal ends -->

<!-- payroll info starts -->
<app-payroll-info
  [payroll]="selectedPayroll"
  [isEmployee]="true"
  [(visible)]="payrollInfoVisibility"
></app-payroll-info>
<!-- payroll info ends -->

<!-- payroll info starts -->
<app-payroll-info [payroll]="selectedPayroll" [(visible)]="managementPayrollInfoVisibility"></app-payroll-info>
<!-- payroll info ends -->

<!-- payroll element formulas modal starts -->
<payroll-element-formulas
  [payrollElement]="selectedPayrollElement"
  [payrollElementTypeKey]="payrollElementKey"
  [(visible)]="payrollElementFormulasListVisibility"
>
</payroll-element-formulas>
<!-- payroll element formulas modal ends -->

<!-- payroll projects costs modal starts -->
<app-payroll-projects-costs-info
  [payrollProjectCosts]="selectedPayrollProjectCosts"
  [(visible)]="payrollProjectsCostsListVisibility"
></app-payroll-projects-costs-info>
<!-- payroll projects costs modal ends -->

<!-- payroll provisions modal starts -->
<app-payroll-provisions-info
  [payrollProvisions]="selectedPayrollProvisions"
  [(visible)]="payrollProvisionsListVisibility"
></app-payroll-provisions-info>
<!-- payroll provisions modal ends -->

<!-- payroll gov-services modal starts -->
<app-payroll-gov-services-info
  [payrollGovServices]="selectedPayrollGovServices"
  [(visible)]="payrollGovServicesListVisibility"
></app-payroll-gov-services-info>
<!-- payroll gov-services modal ends -->

<!-- payroll unpaid leaves modal starts -->
<app-payroll-unpaid-leaves-info
  [payrollUnpaidLeaves]="selectedPayrollUnpaidLeaves"
  [(visible)]="payrollUnpaidLeavesListVisibility"
></app-payroll-unpaid-leaves-info>
<!-- payroll unpaid leaves modal ends -->
