<div class="row">
  <div class="col-md-12">
    <div class="card" *ngIf="journal">
      <!-- journal details starts -->
      <div class="card-header mb-3">
        <h4 class="card-title">{{ 'FINANCES.JOURNALS.JOURNAL_DATA.JOURNAL_DATA' | translate }}</h4>
      </div>
      <div class="card-content">
        <div class="px-3">
          <div class="row">
            <div class="form-group col-md-4">
              <label for="journalNum">{{ 'FINANCES.JOURNALS.JOURNAL_DATA.JOURNAL_NUMBER_LABEL' | translate }}</label>
              <input type="text" id="journalNum" class="form-control round" [value]="journal.journalNum" readonly />
            </div>
            <div class="form-group col-md-4">
              <label for="journalType">{{ 'FINANCES.JOURNALS.JOURNAL_DATA.JOURNAL_TYPE' | translate }}</label>
              <input
                type="text"
                id="journalType"
                class="form-control round"
                [value]="journal.journalType.name | localize: journal.journalType.nameEn | async"
                readonly
              />
            </div>
            <div class="form-group col-md-4">
              <label for="journalTransactionDate">{{
                'FINANCES.JOURNALS.JOURNAL_DATA.JOURNAL_DATE' | translate
              }}</label>
              <input
                type="text"
                id="journalTransactionDate"
                class="form-control round"
                [value]="journal.transactionDate | date: 'datetime'"
                readonly
              />
            </div>
            <div class="form-group col-md-12">
              <label for="notes">{{ 'FINANCES.JOURNALS.JOURNAL_DATA.NOTES' | translate }}</label>
              <textarea
                id="notes"
                class="form-control round"
                [innerText]="journal.notes"
                [placeholder]="'FINANCES.JOURNALS.JOURNAL_DATA.NOTES_NOT_FOUND' | translate"
                readonly
              ></textarea>
            </div>
          </div>
        </div>
      </div>
      <!-- journal details ends -->

      <!-- journal lines details starts -->
      <div class="card-header mb-3 pt-2">
        <h4 class="card-title">{{ 'FINANCES.JOURNALS.JOURNAL_DATA.JOURNAL_LINES' | translate }}</h4>
      </div>
      <div class="card-content">
        <div class="px-3">
          <div class="row">
            <div class="col-md-12">
              <div class="table-responsive">
                <table class="table text-center">
                  <thead>
                    <tr>
                      <th scope="col"><i class="fa fa-list-ol"></i></th>
                      <th scope="col">
                        <i class="fa fa-map-signs"></i>
                        {{ 'FINANCES.JOURNALS.JOURNAL_DATA.ACCOUNT' | translate }}
                      </th>
                      <th scope="col">
                        <i class="fa fa-usd"></i>
                        {{ 'FINANCES.JOURNALS.JOURNAL_DATA.DEBIT' | translate }}
                      </th>
                      <th scope="col">
                        <i class="fa fa-usd"></i>
                        {{ 'FINANCES.JOURNALS.JOURNAL_DATA.CREDIT' | translate }}
                      </th>
                      <th scope="col">
                        {{ 'FINANCES.JOURNALS.JOURNAL_DATA.COST_CENTER' | translate }}
                      </th>
                      <th scope="col">
                        <i class="fa fa-pencil"></i>
                        {{ 'FINANCES.JOURNALS.JOURNAL_DATA.NOTES' | translate }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let line of journal.journalLines; let i = index">
                      <th scope="row">{{ i + 1 }}</th>
                      <td>{{ line.account.description }}</td>
                      <td>{{ line.debit | toDecimal }}</td>
                      <td>{{ line.credit | toDecimal }}</td>
                      <td>{{ line.costCenter.description | localize: line.costCenter.descriptionEn | async }}</td>
                      <td style="white-space: pre-line;">{{ line.notes }}</td>
                    </tr>
                  </tbody>
                  <tfoot class="tfoot-light">
                    <tr>
                      <td [attr.colspan]="6">
                        <i class="fa fa-bookmark c-primary"></i>
                        <span>
                          {{ journal.journalLines.length }}
                          {{ 'FINANCES.JOURNALS.JOURNAL_DATA.JOURNAL_LINE' | translate }}</span
                        >
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- journal lines details ends -->
    </div>
  </div>
</div>
