import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';

import { Observable, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';

import * as fromSalesStore from 'sales/store';
import * as fromAuthStore from 'auth/store';
import { NotificationService, PageInfo, PaginationInfo, TranslationService } from 'shared';
import { Customer, SaleReturn } from 'sales/models';
import { Claims } from 'security/models';
import { Location } from 'stores/models';

@Component({
  selector: 'app-sale-returns-list',
  templateUrl: './sale-returns-list.component.html',
  styles: [],
})
export class SaleReturnsListComponent implements OnInit, OnDestroy {
  /**
   * Gets or sets the information about the current page.
   */
  pageInfo: PageInfo = {
    title: 'SALES.RETURNS.SALE_RETURN',
    icon: 'fa fa-cart-plus',
  };

  /**
   * The list of selected customers.
   */
  customers: Customer[] = [];

  /**
   * Shows or hide the customers list.
   */
  customersListVisibility = false;

  /**
   * Shows or hide the locations list.
   */
  locationsListVisibility = false;

  /** The initial `fromDate` value for search. */
  initialFromDate = new Date();

  /** The initial `toDate` value for search.
   * It's the current day of month.
   */
  initialToDate = new Date();

  /**
   * The system supported user claims.
   */
  Claims = Claims;

  /**
   * The list of selected locations.
   */
  locations: Location[] = [];

  /**
   * The list of sale returns.
   */
  saleReturns$: Observable<SaleReturn[]>;

  /**
   * The pagination info.
   */
  paginationInfo$: Observable<PaginationInfo>;

  /**
   * Indicates whether there is a search process is running or not.
   */
  isSearching$: Observable<boolean>;

  /**
   * The search form.
   */
  searchForm: FormGroup;

  /**
   * The set of subscriptions on this components,
   * these subscriptions must be unsubscribed before this component got destroyed.
   */
  subscriptions = new Subscription();

  /**
   * @param notificationService The notification service.
   * @param translationService The translation service.
   * @param salesStore$ The sales-store module.
   * @param authStore$ the auth-store module.
   */
  constructor(
    private notificationService: NotificationService,
    private translationService: TranslationService,
    private salesStore$: Store<fromSalesStore.SalesState>,
    private authStore$: Store<fromAuthStore.AuthState>
  ) {}

  ngOnInit(): void {
    this.init();
  }

  /**
   * Destroy component data
   */
  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  /**
   * Initialize component data.
   */
  init() {
    this.initForm();

    /**
     * Load data.
     */
    this.isSearching$ = this.salesStore$.pipe(select(fromSalesStore.getSaleReturnsSearching));

    let isManualSearchTriggeredBeforeForReturns = false;
    this.saleReturns$ = this.salesStore$.pipe(
      select(fromSalesStore.getSaleReturns),
      tap((returns) => {
        if (!isManualSearchTriggeredBeforeForReturns && !returns.length) {
          isManualSearchTriggeredBeforeForReturns = true;
          this.search();
        }
      })
    );

    /* Subscribe to selected location to select it whenever it changed. */
    this.subscriptions.add(
      this.authStore$
        .pipe(
          select(fromAuthStore.getSessionUserDefaultLocation),
          tap((location) => {
            if (location && !this.searchForm.value.locations.includes(location.id)) {
              this.selectLocations([location]);
            }
          })
        )
        .subscribe()
    );

    this.paginationInfo$ = this.salesStore$.pipe(select(fromSalesStore.getSaleReturnsPaginationInfo));
  }

  /**
   * Initialize form and add validators.
   */
  initForm() {
    this.searchForm = new FormGroup({
      customers: new FormControl([]),
      locations: new FormControl([]),
      fromDate: new FormControl({
        year: this.initialFromDate.getFullYear(),
        month: this.initialFromDate.getMonth() + 1,
        day: this.initialFromDate.getDate(),
      }),
      toDate: new FormControl({
        year: this.initialToDate.getFullYear(),
        month: this.initialToDate.getMonth() + 1,
        day: this.initialToDate.getDate(),
      }),
      page: new FormControl(1),
      invoiceNum: new FormControl(''),
      saleReturnNum: new FormControl(''),
    });
  }

  /**
   * Handles search parameters change.
   */
  search(event?: KeyboardEvent) {
    if (event && event.key === 'Enter') {
      event.preventDefault();
      return;
    }

    this.applyFiltersAndSearch();
  }

  /**
   * Handles pagination page-changed event.
   * @param page The current selected page number.
   */
  pageChanged(page: number) {
    /** Update pagination page. */
    this.searchForm.patchValue({ page });

    this.applyFiltersAndSearch(page);
  }

  /**
   * Applies the search filters and dispatches a search action.
   * @param page The current page number, default to `1`.
   */
  applyFiltersAndSearch(page: number = 1) {
    if (!this.searchForm.get('fromDate')?.value || !this.searchForm.get('toDate')?.value) {
      return this.notificationService.warning(
        this.translationService.translate('SALES.RETURNS.NOTIFICATION_MESSAGE.SELECT_DATE')
      );
    }

    const { locations, customers, invoiceNum, saleReturnNum, fromDate, toDate } = this.searchForm.value;
    const fromDateFormatted = new Date(fromDate.year, fromDate.month - 1, fromDate.day);
    fromDateFormatted.setHours(0, 0, 0, 0);

    const toDateFormatted = new Date(toDate.year, toDate.month - 1, toDate.day);
    toDateFormatted.setHours(23, 59, 59, 0);

    this.salesStore$.dispatch(
      new fromSalesStore.SearchSaleReturns({
        locations,
        customers,
        saleReturnNum,
        invoiceNum,
        fromDate: fromDateFormatted,
        toDate: toDateFormatted,
        page,
      })
    );
  }

  /**
   * Enables trackBy feature for *ngFor utility to track items
   * depending on a comparer to reduce DOM change.
   * If the item is already exist then no HTML will change, only bounded values.
   * @param index The index of the item.
   * @param saleReturn The item to determine if it was changed or not.
   */
  trackItems(index: number, saleReturn: SaleReturn) {
    return saleReturn ? saleReturn.id : undefined;
  }

  /**
   * Adds the newly selected customer the customers search list.
   * @param customers The list of newly selected customers to be added.
   */
  selectCustomers(customers: Customer[]) {
    const selectedIds: number[] = this.searchForm.get('customers').value;
    this.customers = [...this.customers.filter((customer) => selectedIds.includes(customer.id)), ...customers];
    this.searchForm.patchValue({ customers: this.customers.map((customer) => customer.id) });
  }

  /**
   * Adds the newly selected locations the locations search list.
   * @param locations The list of newly selected locations to be added.
   */
  selectLocations(locations: Location[]) {
    const selectedIds: number[] = this.searchForm.get('locations').value;
    this.locations = [...this.locations.filter((location) => selectedIds.includes(location.id)), ...locations];
    this.searchForm.patchValue({ locations: this.locations.map((location) => location.id) });
  }
}
