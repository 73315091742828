<ng-template #modalRef let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title text-primary" id="modal-basic-title">
      {{ 'FINANCES.HR.PAID_PAYROLLS.PAID_PAYROLLS_DATA.PAID_PAYROLL_LIST' | translate }}
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="d(); closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <!-- paid payrolls list starts -->
  <div class="modal-body">
    <div class="row">
      <div class="col-md-12">
        <form class="row" [formGroup]="searchForm" (ngSubmit)="searchPayrolls()">
          <div class="form-group col-md-4">
            <label for="employees">{{ 'FINANCES.HR.PAID_PAYROLLS.PAID_PAYROLLS_DATA.EMPLOYEES' | translate }}</label>
            <ng-select
              class="round"
              formControlName="employees"
              [multiple]="true"
              [closeOnSelect]="false"
              labelForId="employees"
              searchable="true"
              [placeholder]="'FINANCES.HR.PAID_PAYROLLS.PAID_PAYROLLS_DATA.EMPLOYEES_PLACEHOLDER' | translate"
              [notFoundText]="'SHARED.SEARCH.SEARCH_NOT_FOUND' | translate"
              (open)="employeesListVisibility = true"
            >
              <ng-option *ngFor="let employee of employees" [value]="employee.id">
                {{
                  employee.employeePersonalDetail.fullName
                    | localize: employee.employeePersonalDetail.fullNameEn
                    | async
                }}
              </ng-option>
            </ng-select>
          </div>
          <div class="form-group col-md-4">
            <label for="departments">{{
              'FINANCES.HR.PAID_PAYROLLS.PAID_PAYROLLS_DATA.DEPARTMENTS' | translate
            }}</label>
            <ng-select
              class="round"
              formControlName="departments"
              [multiple]="true"
              [closeOnSelect]="false"
              labelForId="departments"
              searchable="true"
              [placeholder]="'FINANCES.HR.PAID_PAYROLLS.PAID_PAYROLLS_DATA.DEPARTMENTS_PLACEHOLDER' | translate"
              [notFoundText]="'SHARED.SEARCH.SEARCH_NOT_FOUND' | translate"
              (open)="departmentsListVisibility = true"
            >
              <ng-option *ngFor="let department of departments" [value]="department.id">
                {{ department.name | localize: department.nameEn | async }}
              </ng-option>
            </ng-select>
          </div>
          <div class="form-group col-md-4">
            <label for="locations">{{ 'FINANCES.HR.PAID_PAYROLLS.PAID_PAYROLLS_DATA.LOCATIONS' | translate }}</label>
            <ng-select
              class="round"
              formControlName="locations"
              [multiple]="true"
              [closeOnSelect]="false"
              labelForId="locations"
              searchable="true"
              [placeholder]="'FINANCES.HR.PAID_PAYROLLS.PAID_PAYROLLS_DATA.LOCATIONS_PLACEHOLDER' | translate"
              [notFoundText]="'SHARED.SEARCH.SEARCH_NOT_FOUND' | translate"
              (open)="locationsListVisibility = true"
            >
              <ng-option *ngFor="let location of locations" [value]="location.id">
                {{ location.name | localize: location.nameEn | async }}
              </ng-option>
            </ng-select>
          </div>
          <div class="col-md-8">
            <div class="row">
              <div class="form-group col-12 col-sm-6 mb-0">
                <label for="fromDate">{{ 'SHARED.SEARCH.FROM_DATE' | translate }}</label>
                <div class="input-group round">
                  <input
                    class="form-control"
                    id="fromDate"
                    name="fromDate"
                    formControlName="fromDate"
                    [placeholder]="'SHARED.SEARCH.DATE_PLACEHOLDER' | translate"
                    ngbDatepicker
                    #fromDate="ngbDatepicker"
                  />
                  <div class="input-group-append">
                    <span
                      class="input-group-text gradient-light-blue-indigo"
                      style="cursor: pointer;"
                      (click)="fromDate.toggle()"
                    >
                      <i class="fa fa-calendar"></i>
                    </span>
                  </div>
                </div>
              </div>
              <div class="form-group col-12 col-sm-6 mb-0">
                <label for="toDate">{{ 'SHARED.SEARCH.TO_DATE' | translate }}</label>
                <div class="input-group round">
                  <input
                    class="form-control"
                    id="toDate"
                    name="toDate"
                    formControlName="toDate"
                    [placeholder]="'SHARED.SEARCH.DATE_PLACEHOLDER' | translate"
                    ngbDatepicker
                    #toDate="ngbDatepicker"
                  />
                  <div class="input-group-append">
                    <span
                      class="input-group-text gradient-light-blue-indigo"
                      style="cursor: pointer;"
                      (click)="toDate.toggle()"
                    >
                      <i class="fa fa-calendar"></i>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-auto form-group d-flex">
            <button
              type="submit"
              class="btn btn-raised btn-primary align-self-end round mx-1 mb-0"
              [disabled]="isSearching$ | async"
            >
              <i class="fa fa-search"></i>
            </button>
            <app-export-tools
              *ngIf="(paidPayrolls$ | async)?.length"
              class="d-flex align-self-end mx-1"
              targetElementId="paid-payrolls-table-export-target-element"
              [outputFileName]="'SHARED.EXPORT_TABLES.PAYROLLS_TABLE' | translate"
            ></app-export-tools>
          </div>
        </form>
      </div>
      <div class="col-md-12" *ngIf="(paidPayrolls$ | async)?.length; else noItems">
        <div class="row">
          <div class="col-md-12 alert alert-light text-center" role="alert">
            {{ 'SHARED.SEARCH.SEARCH_RESULTS' | translate }} ({{ (paginationInfo$ | async).total | toNumber }})
            {{ 'FINANCES.HR.PAID_PAYROLLS.PAID_PAYROLLS_DATA.PAID_PAYROLL' | translate }}
          </div>
          <div class="col-md-12">
            <div class="table-responsive">
              <table class="table text-center">
                <thead>
                  <tr>
                    <th class="fit-width" *ngIf="!closeOnSelect">
                      <a
                        class="primary p-0"
                        (click)="selectPaidPayroll(paidPayrollsList)"
                        [ngbTooltip]="'SHARED.SEARCH.SELECT_ALL' | translate"
                      >
                        <i class="fa fa-check-circle font-medium-3"></i>
                      </a>
                    </th>
                    <th class="fit-width" *ngIf="closeOnSelect">
                      <i class="fa fa-gear font-medium-3"></i>
                    </th>
                    <th class="fit-width" scope="col"><i class="fa fa-list-ol"></i></th>
                    <th class="fit-width" scope="col">
                      {{ 'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.EMPLOYEE_NUMBER' | translate }}
                    </th>
                    <th class="fit-width" scope="col">
                      {{ 'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.EMPLOYEE' | translate }}
                    </th>
                    <th class="fit-width" scope="col">
                      {{ 'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.POSITION' | translate }}
                    </th>
                    <th class="fit-width" scope="col">
                      {{ 'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.DEPARTMENT' | translate }}
                    </th>
                    <th class="fit-width" scope="col">
                      {{ 'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.LOCATION' | translate }}
                    </th>
                    <th class="fit-width" scope="col">
                      {{ 'HR.EMPLOYEES.EMPLOYEE_DATA.EMPLOYEE_STATUS' | translate }}
                    </th>
                    <th class="fit-width" scope="col">
                      {{ 'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.NET' | translate }}
                    </th>
                    <th class="fit-width" scope="col">
                      {{ 'HR.EMPLOYEES.EMPLOYEE_DATA.SALARY' | translate }}
                    </th>
                    <th class="fit-width" scope="col">
                      {{ 'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.SALARY_FOR_GOSI' | translate }}
                    </th>
                    <th class="fit-width" scope="col">
                      {{ 'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.EMPLOYEE_GOSI' | translate }}
                    </th>
                    <th class="fit-width" scope="col">
                      {{ 'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.EMPLOYER_GOSI' | translate }}
                    </th>
                    <th class="fit-width" scope="col">
                      {{ 'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.OUT_KAFALA' | translate }}
                    </th>
                    <th class="fit-width" scope="col">
                      {{ 'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.SPONSOR' | translate }}
                    </th>
                    <th class="fit-width" scope="col">
                      {{ 'HR.EMPLOYEES.EMPLOYEE_DATA.TRANSACTION_DATE' | translate }}
                    </th>
                    <th class="fit-width" scope="col">{{ 'SHARED.SEARCH.CREATED_AT' | translate }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let paidPayroll of paidPayrolls$ | async; trackBy: trackItems; let i = index">
                    <td>
                      <a
                        class="primary p-0"
                        (click)="selectPaidPayroll([paidPayroll])"
                        [ngbTooltip]="'FINANCES.HR.PAID_PAYROLLS.PAID_PAYROLLS_DATA.SELECT_PAID_PAYROLL' | translate"
                      >
                        <i class="fa fa-check-circle-o font-medium-3"></i>
                      </a>
                    </td>
                    <th class="fit-width" scope="row">{{ i + 1 }}</th>
                    <th class="fit-width" scope="row">
                      {{ paidPayroll?.employee?.employeeAssignmentDetail.personNumber }}
                    </th>
                    <td class="fit-width">
                      {{
                        paidPayroll?.employee?.employeePersonalDetail.fullName
                          | localize: paidPayroll?.employee?.employeePersonalDetail.fullNameEn
                          | async
                      }}
                    </td>
                    <td class="fit-width">
                      {{
                        paidPayroll?.employee?.employeeAssignmentDetail.position.name
                          | localize: paidPayroll?.employee?.employeeAssignmentDetail.position.nameEn
                          | async
                      }}
                    </td>
                    <td class="fit-width">
                      {{
                        paidPayroll?.employee?.employeeAssignmentDetail.department?.name
                          | localize: paidPayroll?.employee?.employeeAssignmentDetail.department?.nameEn
                          | async
                      }}
                    </td>
                    <td class="fit-width">
                      {{
                        paidPayroll?.employee?.employeeAssignmentDetail.location?.name
                          | localize: paidPayroll?.employee?.employeeAssignmentDetail.location?.nameEn
                          | async
                      }}
                    </td>
                    <td class="fit-width">
                      {{ paidPayroll.payrollStatus.name | localize: paidPayroll.payrollStatus.nameEn | async }}
                    </td>
                    <td class="fit-width">
                      <span
                        [ngClass]="{
                          'text-danger': paidPayroll.net < 0
                        }"
                      >
                        {{ paidPayroll.net | toDecimal }}
                      </span>
                    </td>
                    <td class="fit-width">
                      <span class="d-block">{{ paidPayroll.salary | toDecimal }} </span>
                      <span class="text-danger" *ngIf="paidPayroll.workDays">
                        ({{ paidPayroll.workDays }})
                        {{ 'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.DAY' | translate }}
                      </span>
                    </td>
                    <td class="fit-width">
                      {{ paidPayroll.salaryForGosi | toDecimal }}
                    </td>
                    <td class="fit-width">
                      {{ paidPayroll.employeeGosi | toDecimal }}
                    </td>
                    <td class="fit-width">
                      {{ paidPayroll.employerGosi | toDecimal }}
                    </td>
                    <td class="fit-width">
                      <app-status
                        [status]="true"
                        [activeText]="'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.OUT_KAFALA' | translate"
                        *ngIf="paidPayroll.isOutKafala"
                      >
                      </app-status>
                    </td>
                    <td class="fit-width">
                      {{ paidPayroll.employeeSponsor?.name | localize: paidPayroll.employeeSponsor?.nameEn | async }}
                    </td>
                    <td class="fit-width">
                      <span [ngbTooltip]="paidPayroll.transactionDate | date: 'time'">
                        {{ paidPayroll.transactionDate | date }}
                      </span>
                    </td>
                    <td class="fit-width">
                      <span [ngbTooltip]="paidPayroll.createdAt | date: 'time'">
                        {{ paidPayroll.createdAt | date }}
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div class="d-flex justify-content-between p-2">
                <ngb-pagination
                  *ngIf="paginationInfo$ | async; let pagination"
                  maxSize="5"
                  [collectionSize]="pagination.total"
                  [pageSize]="pagination.pageSize"
                  [page]="pagination.page"
                  (pageChange)="pageChanged($event)"
                  boundaryLinks="true"
                >
                  <ng-template ngbPaginationFirst>{{ 'SHARED.PAGINATION.PAGINATION_FIRST' | translate }}</ng-template>
                  <ng-template ngbPaginationFirst>{{
                    'SHARED.PAGINATION.PAGINATION_PREVIOUS' | translate
                  }}</ng-template>
                  <ng-template ngbPaginationFirst>{{ 'SHARED.PAGINATION.PAGINATION_NEXT' | translate }}</ng-template>
                  <ng-template ngbPaginationFirst>{{ 'SHARED.PAGINATION.PAGINATION_LAST' | translate }}</ng-template>
                </ngb-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <div class="modal-actions">
      <button type="button" class="btn btn-outline-dark round" (click)="c(); closeModal()">
        <i class="fa fa-times"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.CLOSE' | translate }}</span>
      </button>
    </div>
  </div>
</ng-template>
<!-- paid payrolls list ends -->

<!-- no items template starts -->
<ng-template #noItems>
  <div class="col-md-12 alert alert-warning mt-3 text-center" role="alert">
    {{ 'FINANCES.HR.PAID_PAYROLLS.PAID_PAYROLLS_DATA.SEARCH_PAID_PAYROLLS_NOT_FOUND' | translate }}
  </div>
</ng-template>
<!-- no items template ends -->

<!-- employees search starts -->
<employees-search [(visible)]="employeesListVisibility" (employees)="selectEmployees($event)"> </employees-search>
<!-- employees search ends -->

<!-- departments search starts -->
<departments-search [(visible)]="departmentsListVisibility" (departments)="selectDepartments($event)">
</departments-search>
<!-- departments search ends -->

<!-- locations search starts -->
<locations-search [(visible)]="locationsListVisibility" (locations)="selectLocations($event)"> </locations-search>
<!-- locations search ends -->
