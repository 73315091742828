import { createSelector } from '@ngrx/store';

import { selectLookupsState } from 'lookups/store/selectors/feature.selectors';
import { LookupsState, AssetYearsOfUseState } from 'lookups/store/states';

/**
 * Gets the assetYearsOfUse state.
 */
const selectAssetYearsOfUses = createSelector(selectLookupsState, (state: LookupsState) => state.assetYearsOfUses);

/**
 * Gets the list of loaded assetYearsOfUses.
 */
export const getAssetYearsOfUses = createSelector(selectAssetYearsOfUses, (state: AssetYearsOfUseState) => state.data);

/**
 * Gets the error that occurred in the state.
 */
export const getAssetYearsOfUsesError = createSelector(
  selectAssetYearsOfUses,
  (state: AssetYearsOfUseState) => state.error
);

/**
 * Determines if there is a running load process.
 */
export const getAssetYearsOfUsesIsLoading = createSelector(
  selectAssetYearsOfUses,
  (state: AssetYearsOfUseState) => state.isLoading
);

/**
 * Determines if the last load process has been ended successfully.
 */
export const getAssetYearsOfUsesIsLoaded = createSelector(
  selectAssetYearsOfUses,
  (state: AssetYearsOfUseState) => state.isLoaded
);
