import { Action } from '@ngrx/store';

import { DeductionType } from 'lookups/models';
import { AppHttpResponse } from 'shared';

/**
 * The Lookups-Module store deduction type action types.
 */
export enum DeductionTypeActionType {
  GET_ALL_DEDUCTION_TYPES = '[Lookups] [DeductionType] Get All Deduction Types',
  GET_ALL_DEDUCTION_TYPES_FAIL = '[Lookups] [DeductionType] Get All Deduction Types Fail',
  GET_ALL_DEDUCTION_TYPES_SUCCESS = '[Lookups] [DeductionType] Get All Deduction Types Success',
}

/**
 * Represents a store deduction types action.
 */
export class GetAllDeductionTypes implements Action {
  /**
   * The type of the action.
   */
  readonly type = DeductionTypeActionType.GET_ALL_DEDUCTION_TYPES;

  /**
   * Fires a new deduction types action.
   */
  constructor() {}
}

/**
 * Represents a store deduction types fail action.
 */
export class GetAllDeductionTypesFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = DeductionTypeActionType.GET_ALL_DEDUCTION_TYPES_FAIL;

  /**
   * Fires a new deduction types fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store deduction types success action.
 */
export class GetAllDeductionTypesSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = DeductionTypeActionType.GET_ALL_DEDUCTION_TYPES_SUCCESS;

  /**
   * Fires a deduction types success action.
   * @param payload An object contains the list of deduction types.
   */
  constructor(public payload: AppHttpResponse<DeductionType[]>) {}
}

/**
 * Lookups-module deduction type action types.
 */
export type DeductionTypeActions = GetAllDeductionTypes | GetAllDeductionTypesFail | GetAllDeductionTypesSuccess;
