import * as fromUsers from 'security/components/users';

export * from 'security/components/users';

/**
 * The set of components registered on this module.
 */
export const COMPONENTS: any[] = [
  ...fromUsers.COMPONENTS,
];
