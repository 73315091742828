import * as fromLocations from 'stores/components/locations';
import * as fromProducts from 'stores/components/products';
import * as fromProductClasses from 'stores/components/product-classes';
import * as fromUnitOfMeasureClasses from 'stores/components/unit-of-measure-classes';
import * as fromUnitsOfMeasure from 'stores/components/units-of-measure';
import * as fromVariants from 'stores/components/variants';
import * as fromRegions from 'stores/components/regions';
import * as fromSectors from 'stores/components/sectors';

export * from 'stores/components/locations';
export * from 'stores/components/products';
export * from 'stores/components/product-classes';
export * from 'stores/components/unit-of-measure-classes';
export * from 'stores/components/units-of-measure';
export * from 'stores/components/variants';
export * from 'stores/components/regions';
export * from 'stores/components/sectors';

/**
 * The set of components registered on this module.
 */
export const COMPONENTS: any[] = [
  ...fromLocations.COMPONENTS,
  ...fromProducts.COMPONENTS,
  ...fromProductClasses.COMPONENTS,
  ...fromUnitOfMeasureClasses.COMPONENTS,
  ...fromUnitsOfMeasure.COMPONENTS,
  ...fromVariants.COMPONENTS,
  ...fromRegions.COMPONENTS,
  ...fromSectors.COMPONENTS,
];
