import { Decimal } from 'decimal.js';

import { ProductsFormItem } from 'stores/models';

/**
 * Provides the ability to calculate any invoice product items in an efficient way.
 */
export class ProductsFormUtil {
  /**
   * Returns the net discount value that is applied to each one unit of the product.
   * @param item The product item.
   * @returns the net discount value that is applied to each one unit of the product.
   */
  private static discountPerProduct(item: ProductsFormItem): number {
    const value = new Decimal(item.value ? item.value : '0');
    const discountValue = new Decimal(item.discount ? item.discount : '0');
    const discountPercent = new Decimal(item.discountPercent ? item.discountPercent : '0');

    const discountPerProduct = discountValue.plus(value.mul(discountPercent.div(100)));

    return discountPerProduct.toNumber();
  }

  /**
   * Returns the value of the product item after applying discounts, without tax or quantity.
   * @param item The product item.
   * @returns Net item value after discount.
   */
  public static productValueAfterDiscount(item: ProductsFormItem): number {
    const value = new Decimal(item.value ? item.value : '0');

    const discountPerProduct = new Decimal(this.discountPerProduct(item));
    const totalPerProduct = value.minus(discountPerProduct);

    return totalPerProduct.toNumber();
  }

  /**
   * Returns the total value of the product item after applying discounts and quantities, without tax.
   * @param item The product item.
   * @returns total item value after discount.
   */
  public static productTotalWithoutTax(item: ProductsFormItem): number {
    const quantity = new Decimal(item.quantity ? item.quantity : '0');

    const totalPerProduct = new Decimal(this.productValueAfterDiscount(item));
    const total = totalPerProduct.mul(quantity);

    return total.toNumber();
  }

  /**
   * Returns the total value of the products items after applying discounts and quantities for each product, without tax.
   * @param items The list of product item.
   * @returns total items value after discount.
   */
  public static productsTotalWithoutTax(items: ProductsFormItem[]): number {
    const summary = items.map((item) => this.productTotalWithoutTax(item));
    const total = Decimal.sum(...(summary.length ? summary : [0]));

    return total.toNumber();
  }

  /**
   * Returns the product total without discount and tax.
   * @param item The product item.
   * @returns product total without discount and tax.
   */
  public static productTotalWithoutDiscountAndTax(item: ProductsFormItem): number {
    const value = new Decimal(item.value ? item.value : '0');
    const quantity = new Decimal(item.quantity ? item.quantity : '0');

    const total = value.mul(quantity);

    return total.toNumber();
  }

  /**
   * Returns the products total without discount and tax.
   * @param items The list of product item.
   * @returns products total without discount and tax.
   */
  public static productsTotalWithoutDiscountAndTax(items: ProductsFormItem[]): number {
    const summary = items.map((item) => this.productTotalWithoutDiscountAndTax(item));
    const total = Decimal.sum(...(summary.length ? summary : [0]));

    return total.toNumber();
  }

  /**
   * Returns the tax of the product item after applying discounts and quantities.
   * @param item The product item.
   * @returns total item tax after discount.
   */
  public static productTax(item: ProductsFormItem): number {
    const quantity = new Decimal(item.quantity ? item.quantity : '0');
    const taxPercent = new Decimal(item.tax ? item.tax : '0');

    const totalPerProduct = new Decimal(this.productValueAfterDiscount(item));
    const totalTax = totalPerProduct.mul(taxPercent.div(100)).mul(quantity);

    return totalTax.toNumber();
  }

  /**
   * Returns the total tax of the products item after applying discounts and quantities for each product.
   * @param items The list of product item.
   * @returns total items tax after discount.
   */
  public static productsTax(items: ProductsFormItem[]): number {
    const summary = items.map((item) => this.productTax(item));
    const totalTax = Decimal.sum(...(summary.length ? summary : [0]));

    return totalTax.toNumber();
  }

  /**
   * Returns the total discount of the product.
   * @param item The product item.
   * @returns the total discount of the product.
   */
  public static productDiscount(item: ProductsFormItem): number {
    const quantity = new Decimal(item.quantity ? item.quantity : '0');

    const discountPerProduct = new Decimal(this.discountPerProduct(item));
    const totalDiscount = discountPerProduct.mul(quantity);

    return totalDiscount.toNumber();
  }

  /**
   * Returns the total discount for all products.
   * @param items The list of product item.
   * @returns total products discount.
   */
  public static productsDiscount(items: ProductsFormItem[]): number {
    const summary = items.map((item) => this.productDiscount(item));

    return Decimal.sum(0, ...summary).toNumber();
  }

  /**
   * Returns the total quantity for the given list of product items.
   * @param items The list of product item.
   * @returns total items quantity.
   */
  public static productsQuantity(items: ProductsFormItem[]): number {
    const summary = items.map((item) => (item.quantity ? item.quantity : '0'));
    const quantities = Decimal.sum(...(summary.length ? summary : [0]));

    return quantities.toNumber();
  }

  /**
   * Returns the net value of the invoice after applying discounts and taxes for all products.
   * @param items The list of products in the invoice.
   * @param round Whether the value will be rounded by zero or not, default is `false`.
   * @returns The net value of the invoice.
   */
  public static invoiceNet(items: ProductsFormItem[]): number {
    const net = Decimal.sum(
      this.productsTotalWithoutDiscountAndTax(items),
      -this.productsDiscount(items),
      this.productsTax(items)
    );

    return net.toNumber();
  }
}
