import { CostCentersEffects } from 'finances/store/effects/cost-centers.effects';
import { PettyCashEffects } from 'finances/store/effects/petty-cash.effects';
import { PettyCashRefundRequestsEffects } from 'finances/store/effects/petty-cash-refund-requests.effects';
import { PettyCashRefundEffects } from 'finances/store/effects/petty-cash-refund.effects';
import { PettyCashReductionEffects } from 'finances/store/effects/petty-cash-reduction.effects';
import { AccountsEffects } from 'finances/store/effects/accounts.effects';
import { BanksEffects } from 'finances/store/effects/banks.effects';
import { SystemAccountsEffects } from 'finances/store/effects/system-accounts.effects';
import { JournalsEffects } from 'finances/store/effects/journals.effects';
import { BankAccountsEffects } from 'finances/store/effects/bank-accounts.effects';
import { PettyCashRefundRequestApprovalsEffects } from 'finances/store/effects/petty-cash-refund-request-approvals.effects';
import { FinancialPeriodsEffects } from 'finances/store/effects/financial-periods.effects';
import { CurrenciesEffects } from 'finances/store/effects/currencies.effects';
import { CurrencyExchangeRatesEffects } from 'finances/store/effects/currency-exchange-rates.effects';
import { HrEffects } from 'finances/store/effects/hr.effects';
import { JournalAssociationEffects } from 'finances/store/effects/journal-association.effects';
import { FinanceConfigurationsEffects } from 'finances/store/effects/finance-configurations.effects';

/**
 * The Finances-Module store effects.
 */
export const effects: any[] = [
  CostCentersEffects,
  PettyCashEffects,
  PettyCashRefundRequestsEffects,
  PettyCashRefundEffects,
  PettyCashReductionEffects,
  AccountsEffects,
  BanksEffects,
  SystemAccountsEffects,
  JournalsEffects,
  BankAccountsEffects,
  PettyCashRefundRequestApprovalsEffects,
  FinancialPeriodsEffects,
  CurrenciesEffects,
  CurrencyExchangeRatesEffects,
  HrEffects,
  JournalAssociationEffects,
  FinanceConfigurationsEffects,
];
