import { Component, Input } from '@angular/core';
import { FormArray, FormGroup, FormControl, Validators } from '@angular/forms';

import { DateTime } from 'luxon';

import { APP_CONSTANTS, CustomValidators, DateUtil } from 'shared';
import { GovServiceRequestEmployeeFormItem } from 'hr/models';

@Component({
  selector: 'gov-service-request-employees-form',
  templateUrl: './gov-service-request-employees-form.component.html',
  styles: [],
})
export class GovServiceRequestEmployeesFormComponent {
  /**
   * Sets the gov service request employees for array.
   */
  @Input() employeesForm: FormArray;

  /**
   * Sets the name form-array in its parent form.
   * @default 'employees'
   */
  @Input() formArrayName = 'employees';

  /**
   * Adds a list of new gov service request employee form items to the gov service request employees form.
   * @param items The list of gov service request employee form items to be added to the gov service request employees form.
   */
  @Input() set newEmployeeItems(items: GovServiceRequestEmployeeFormItem[]) {
    if (!items?.length) {
      return;
    }

    items.forEach((item) => this.createFormGroupFromItem(item));
  }

  /**
   * The decimal mask.
   */
  readonly DECIMAL_MASK = APP_CONSTANTS.numeric.decimal.mask;

  /**
   * Create a new gov service request employee form group from the provided gov service request employee form item.
   * @param item The gov service request employee form item that contains data about the new gov service request employee.
   */
  createFormGroupFromItem(item: GovServiceRequestEmployeeFormItem) {
    const formGroup = new FormGroup({
      employeeId: new FormControl(item.employeeId),
      employee: new FormControl(item.employee),
      serviceValue: new FormControl(item.serviceValue, [Validators.required, CustomValidators.gt(0)]),
      penaltyValue: new FormControl(item.penaltyValue, [CustomValidators.gte(0)]),
      fromDate: new FormControl(item.fromDate ? { ...item.fromDate } : null, [Validators.required]),
      toDate: new FormControl(item.toDate ? { ...item.toDate } : null, [Validators.required]),
      notes: new FormControl(item.notes),
    });

    this.employeesForm.push(formGroup);
  }

  /**
   * Converts the given date form to date only.
   *
   * @param date — The date to be converted.
   *
   * @returns — A date with format 'year-month-day'.
   */
  toDateOnly(date: { year: number; month: number; day: number }): string {
    return DateUtil.toDateOnly(DateTime.fromObject(date).toJSDate());
  }
}
