import { PaginationInfo } from 'shared/models';
import { Tax } from 'settings/models';

/**
 * Represents the taxes state.
 */
export class TaxesState {
  /**
   * The list of taxes.
   */
  public data: Tax[];

  /**
   * The pagination info for the taxes.
   */
  public paginationInfo: PaginationInfo;

  /**
   * An error that may resulting during processing some actions.
   */
  public error: any;

  /**
   * Determines if there is a running search process.
   */
  public isSearching: boolean;

  /**
   * Determines if the last search process has been ended successfully.
   */
  public isSearchCompleted: boolean;

  /**
   * The current selected tax.
   */
  public selectedTax: Tax;

  /**
   * Determines if there is a running find-tax process.
   */
  public isFinding: boolean;

  /**
   * Determines if the last find-tax process has been ended successfully.
   */
  public isFindCompleted: boolean;

  /**
   * The default tax.
   */
  public defaultTax: Tax;

  /**
   * Determines if there is a running find-default-tax process.
   */
  public isDefaultTaxFinding: boolean;

  /**
   * Determines if the last find-default-tax process has been ended successfully.
   */
  public isDefaultTaxFindCompleted: boolean;

  /**
   * Determines if there is a running create process.
   */
  public isCreating: boolean;

  /**
   * Determines if the last create process has been ended successfully.
   */
  public isCreateCompleted: boolean;

  /**
   * Determines if there is a running update process.
   */
  public isUpdating: boolean;

  /**
   * Determines if the last update process has been ended successfully.
   */
  public isUpdateCompleted: boolean;

  /**
   * Determines if there is a running delete process.
   */
  public isDeleting: boolean;

  /**
   * Determines if the last delete process has been ended successfully.
   */
  public isDeleteCompleted: boolean;
}
