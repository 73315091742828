import { Component, ViewChild, ElementRef, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms';

import { Observable, Subscription } from 'rxjs';
import { skip, tap } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

import * as fromLookupsStore from 'lookups/store';
import * as fromFinancesStore from 'finances/store';
import {
  CustomValidators,
  NotificationMessage,
  NotificationService,
  PaginationInfo,
  TranslationService,
  DateUtil,
  APP_CONSTANTS,
} from 'shared';
import { EmployeeLoanAttachment, Employee, Department, EmployeeLoan } from 'hr/models';
import { Location } from 'stores/models';
import { Claims } from 'security/models';
import { LoanPaymentType, LoanPaymentTypes } from 'lookups/models';
import { BankAccount } from 'finances/models';

@Component({
  selector: 'app-loans',
  templateUrl: './loans.component.html',
  styles: [],
})
export class LoansComponent implements OnInit, OnDestroy {
  /**
   * The create modal template reference.
   */
  @ViewChild('createModalRef') createModalRef: ElementRef<any>;

  /**
   * The create modal template reference.
   */
  @ViewChild('infoEmployeeLoanModalRef') infoEmployeeLoanModalRef: ElementRef<any>;

  /**
   * The update modal template reference.
   */
  @ViewChild('updateModalRef') updateModalRef: ElementRef<any>;

  /**
   * The view journal modal template reference.
   */
  @ViewChild('viewJournalModalRef') viewJournalModalRef: ElementRef<any>;

  /**
   * The decimal mask.
   */
  readonly DECIMAL_MASK = APP_CONSTANTS.numeric.decimal.mask;

  /**
   * The list of selected employees.
   */
  employees: Employee[] = [];

  /**
   * The list of selected departments.
   */
  departments: Department[] = [];

  /**
   * The list of selected locations.
   */
  locations: Location[] = [];

  /**
   * The list of selected bank account.
   */
  bankAccounts: BankAccount[] = [];

  /**
   * Shows or hide the bank accounts list.
   */
  bankAccountsListVisibility = false;

  /**
   * Shows or hide the employees list.
   */
  employeesListVisibility = false;

  /**
   * Shows or hide the departments list.
   */
  departmentsListVisibility = false;

  /**
   * Shows or hide the locations list.
   */
  locationsListVisibility = false;

  /**
   * The system supported user claims.
   */
  Claims = Claims;

  /**
   * The list of loans.
   */
  loans$: Observable<EmployeeLoan[]>;

  /**
   * Gets or sets the currently selected loan for view attachments.
   */
  selectedLoan: EmployeeLoan;

  /** The initial `transactionDate` value for search.
   * It's the current day of month.
   */
  initialSettlementStartDate = new Date();

  /**
   * Indicates whether there is a create employee loan process is running or not.
   */
  isCreating$: Observable<boolean>;

  /**
   * Indicates whether there is a search process is running or not.
   */
  isSearching$: Observable<boolean>;

  /**
   * Indicates whether there is an update process is running or not.
   */
  isUpdating$: Observable<boolean>;

  /**
   * The create employee loan form.
   */
  createForm: FormGroup;

  /**
   * The update employee loan form.
   */
  updateForm: FormGroup;

  /**
   * The list of loan payment types.
   */
  loanPaymentTypes$: Observable<LoanPaymentType[]>;

  /**
   * The pagination info.
   */
  paginationInfo$: Observable<PaginationInfo>;

  /**
   * The ngb-modal reference to use it when modal needed to be closed.
   */
  modal: NgbModalRef;

  /**
   * The search form.
   */
  searchForm: FormGroup;

  /** The initial `fromDate` value for search. */
  initialFromDate = new Date();

  /** The initial `toDate` value for search.
   * It's the current day of month.
   */
  initialToDate = new Date();

  /** The initial `transactionDate` value for search.
   * It's the current day of month.
   */
  initialTransactionDate = new Date();

  /**
   * Shows or hide the employee list.
   */
  employeeListVisibility = false;

  /**
   * Shows or hide the employee list.
   */
  updateEmployeeListVisibility = false;

  /**
   * Gets or sets the id of the loan payment type for `BANK`.
   */
  loanPaymentTypeIdForBank: number;

  /**
   * Gets or sets the id of the loan payment type for `CASH`.
   */
  loanPaymentTypeIdForCash: number;

  /**
   * The set of subscriptions on this components,
   * these subscriptions must be unsubscribed before this component got destroyed.
   */
  subscriptions = new Subscription();

  /**
   * Gets the attachments form-array.
   */
  get attachmentsForm(): FormArray {
    return this.createForm?.controls.attachments as FormArray;
  }

  /**
   * @param modalService The modal service.
   * @param lookupsStore$ the lookups-store module.
   * @param financesStore$ the finances-store module.
   * @param translationService The translation service.
   * @param notificationService The notification service.
   */
  constructor(
    private modalService: NgbModal,
    private lookupsStore$: Store<fromLookupsStore.LookupsState>,
    private financesStore$: Store<fromFinancesStore.FinancesState>,
    private translationService: TranslationService,
    private notificationService: NotificationService
  ) {}

  ngOnInit(): void {
    this.init();
  }

  /**
   * Destroy component data
   */
  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  /**
   * Initialize component data.
   */
  init() {
    /** Initialize form. */
    this.initForm();

    /**
     * Reset form controls when new loan is created.
     */
    this.subscriptions.add(
      this.financesStore$
        .pipe(
          select(fromFinancesStore.getSelectedEmployeeLoanCreateCompleted),
          skip(1),
          tap((isCreated) => {
            if (isCreated) {
              this.initForm();
              this.createForm.markAsUntouched();
              this.closeModal();
            }
          })
        )
        .subscribe()
    );

    /**
     * Close form controls when loan is updated.
     */
    this.subscriptions.add(
      this.financesStore$
        .pipe(
          select(fromFinancesStore.getSelectedEmployeeLoanUpdateCompleted),
          skip(1),
          tap((isUpdated) => {
            if (isUpdated) {
              this.closeModal();
            }
          })
        )
        .subscribe()
    );

    /**
     * Set data.
     */
    this.isSearching$ = this.financesStore$.pipe(select(fromFinancesStore.getEmployeeLoansSearching));
    this.paginationInfo$ = this.financesStore$.pipe(select(fromFinancesStore.getEmployeeLoansPaginationInfo));
    this.isCreating$ = this.financesStore$.pipe(select(fromFinancesStore.getSelectedEmployeeLoanCreating));

    this.loans$ = this.financesStore$.pipe(select(fromFinancesStore.getEmployeeLoans));

    let isManualSearchTriggeredBeforeForLoanPaymentTypes = false;
    this.loanPaymentTypes$ = this.lookupsStore$.pipe(
      select(fromLookupsStore.getLoanPaymentTypes),
      tap((loanPaymentTypes) => {
        if (!isManualSearchTriggeredBeforeForLoanPaymentTypes && !loanPaymentTypes.length) {
          isManualSearchTriggeredBeforeForLoanPaymentTypes = true;
          this.lookupsStore$.dispatch(new fromLookupsStore.GetAllLoanPaymentType());
        } else if (loanPaymentTypes?.length) {
          this.loanPaymentTypeIdForBank = loanPaymentTypes?.find(
            (loanPaymentType) => loanPaymentType.key === LoanPaymentTypes.BANK
          )?.id;

          this.loanPaymentTypeIdForCash = loanPaymentTypes?.find(
            (loanPaymentType) => loanPaymentType.key === LoanPaymentTypes.CASH
          )?.id;
        }
      })
    );
  }

  /**
   * Initialize form and add validators.
   */
  initForm() {
    const loanPaymentTypeIdFormControl = new FormControl(null, Validators.required);
    const bankFeeFormControl = new FormControl(null);
    const bankFeeTaxValueFormControl = new FormControl(null);
    const valueFormControl = new FormControl('', [Validators.required, CustomValidators.gt(0)]);

    this.searchForm = new FormGroup({
      employees: new FormControl([]),
      departments: new FormControl([]),
      locations: new FormControl([]),
      loanPaymentTypes: new FormControl([]),
      fromDate: new FormControl({
        year: this.initialFromDate.getFullYear(),
        month: this.initialFromDate.getMonth() + 1,
        day: this.initialFromDate.getDate(),
      }),
      toDate: new FormControl({
        year: this.initialToDate.getFullYear(),
        month: this.initialToDate.getMonth() + 1,
        day: this.initialToDate.getDate(),
      }),
      page: new FormControl(1),
    });

    this.createForm = new FormGroup({
      employeeId: new FormControl(null, [Validators.required]),
      loanPaymentTypeId: loanPaymentTypeIdFormControl,
      bankFee: bankFeeFormControl,
      bankFeeTaxValue: bankFeeTaxValueFormControl,
      bankAccountId: new FormControl(null, Validators.required),
      value: valueFormControl,
      settlementValue: new FormControl('', [Validators.required, CustomValidators.gt(0)]),
      notes: new FormControl('', [Validators.required, Validators.minLength(1), Validators.maxLength(500)]),
      enableSettlement: new FormControl(true),
      transactionDate: new FormControl(null),
      settlementStartDate: new FormControl(
        {
          year: this.initialSettlementStartDate.getFullYear(),
          month: this.initialSettlementStartDate.getMonth() + 1,
          day: this.initialSettlementStartDate.getDate(),
        },
        Validators.required
      ),
      attachments: new FormArray([]),
    });

    this.updateForm = new FormGroup({
      settlementValue: new FormControl('', [Validators.required, CustomValidators.gt(0)]),
      enableSettlement: new FormControl(false),
      settlementStartDate: new FormControl(
        {
          year: this.initialSettlementStartDate.getFullYear(),
          month: this.initialSettlementStartDate.getMonth() + 1,
          day: this.initialSettlementStartDate.getDate(),
        },
        Validators.required
      ),
    });

    this.addBlankAttachment();

    this.subscriptions.add(
      loanPaymentTypeIdFormControl.valueChanges.subscribe(() => {
        this.onLoanPaymentTypeChange();
      })
    );
  }

  /**
   * And Sets the conditional validation for the form based on the selected loan payment type id.
   */
  onLoanPaymentTypeChange() {
    /**
     * The id of the current selected loan payment type.
     */
    const loanPaymentTypeId = parseInt(this.createForm.controls.loanPaymentTypeId.value ?? '0');

    /**
     * Check if the loan payment type is payment cash, payment bank.
     */
    if (loanPaymentTypeId === this.loanPaymentTypeIdForBank) {
      this.createForm.controls.bankFee.setValidators([Validators.required, CustomValidators.gte(0)]);
      this.createForm.controls.bankFeeTaxValue.setValidators([Validators.required, CustomValidators.gte(0)]);
    } else if (loanPaymentTypeId === this.loanPaymentTypeIdForCash) {
      this.createForm.controls.bankFee.clearValidators();
      this.createForm.controls.bankFee.setValue(null);
      this.createForm.controls.bankFeeTaxValue.clearValidators();
      this.createForm.controls.bankFeeTaxValue.setValue(null);
    }
    this.createForm.controls.bankFee.updateValueAndValidity();
    this.createForm.controls.bankFeeTaxValue.updateValueAndValidity();
  }

  /**
   * Opens the create modal.
   */
  create() {
    this.initForm();
    this.openModal(this.createModalRef);
  }

  /**
   * Shows an update loan modal for the given loan.
   * @param loan The loan to be updated.
   */
  update(loan: EmployeeLoan) {
    this.updateForm.reset({
      ...loan,
    });

    this.selectedLoan = loan;
    /** Update form data. */
    if (this.selectedLoan) {
      this.updateForm.patchValue({
        ...this.selectedLoan,
        settlementStartDate: DateUtil.toDatePickerDate(this.selectedLoan.settlementStartDate),
      });
    } else {
      this.updateForm.reset();
    }
    this.openModal(this.updateModalRef);
  }

  /**
   * Submits the form.
   */
  submit() {
    const errorMessage = new NotificationMessage();
    const loanPaymentTypeId = parseInt(this.createForm.controls.loanPaymentTypeId.value ?? '0');

    if (this.createForm.invalid) {
      if (this.createForm.get('employeeId').invalid) {
        errorMessage.title = this.translationService.translate('FINANCES.HR.HR_DATA_VALIDATION.EMPLOYEE_ERROR');
        errorMessage.body = [this.translationService.translate('FINANCES.HR.HR_DATA_VALIDATION.EMPLOYEE_IS_REQUIRED')];
      } else if (this.createForm.get('loanPaymentTypeId').invalid) {
        errorMessage.title = this.translationService.translate('FINANCES.HR.HR_DATA_VALIDATION.LOAN_TYPE_ERROR');
        errorMessage.body = [this.translationService.translate('FINANCES.HR.HR_DATA_VALIDATION.LOAN_TYPE_IS_REQUIRED')];
      } else if (this.createForm.get('bankAccountId').invalid) {
        if (loanPaymentTypeId === this.loanPaymentTypeIdForCash) {
          errorMessage.title = this.translationService.translate('FINANCES.HR.HR_DATA_VALIDATION.CASH_ACCOUNT_ERROR');
          errorMessage.body = [
            this.translationService.translate('FINANCES.HR.HR_DATA_VALIDATION.CASH_ACCOUNT_IS_REQUIRED'),
          ];
        } else {
          errorMessage.title = this.translationService.translate('FINANCES.HR.HR_DATA_VALIDATION.BANK_ACCOUNT_ERROR');
          errorMessage.body = [
            this.translationService.translate('FINANCES.HR.HR_DATA_VALIDATION.BANK_ACCOUNT_IS_REQUIRED'),
          ];
        }
      } else if (this.createForm.get('bankFee').invalid) {
        errorMessage.title = this.translationService.translate('FINANCES.HR.HR_DATA_VALIDATION.BANK_FEE_ERROR');
        errorMessage.body = [this.translationService.translate('FINANCES.HR.HR_DATA_VALIDATION.BANK_FEE_LENGTH_ERROR')];
      } else if (this.createForm.get('bankFeeTaxValue').invalid) {
        errorMessage.title = this.translationService.translate(
          'FINANCES.HR.HR_DATA_VALIDATION.BANK_FEE_TAX_VALUE_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate('FINANCES.HR.HR_DATA_VALIDATION.BANK_FEE_TAX_VALUE_LENGTH_ERROR'),
        ];
      } else if (this.createForm.get('value').invalid) {
        errorMessage.title = this.translationService.translate('FINANCES.HR.HR_DATA_VALIDATION.LOAN_VALUE_ERROR');
        errorMessage.body = [
          this.translationService.translate('FINANCES.HR.HR_DATA_VALIDATION.LOAN_VALUE_IS_REQUIRED'),
          this.translationService.translate('FINANCES.HR.HR_DATA_VALIDATION.LOAN_VALUE_LENGTH_ERROR'),
        ];
      } else if (this.createForm.get('settlementValue').invalid) {
        errorMessage.title = this.translationService.translate('FINANCES.HR.HR_DATA_VALIDATION.SETTLEMENT_VALUE_ERROR');
        errorMessage.body = [
          this.translationService.translate('FINANCES.HR.HR_DATA_VALIDATION.SETTLEMENT_VALUE_IS_REQUIRED'),
          this.translationService.translate('FINANCES.HR.HR_DATA_VALIDATION.SETTLEMENT_VALUE_LENGTH_ERROR'),
        ];
      } else if (this.createForm.get('settlementStartDate').invalid) {
        errorMessage.title = this.translationService.translate(
          'FINANCES.HR.HR_DATA_VALIDATION.SETTLEMENT_START_DATE_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate('FINANCES.HR.HR_DATA_VALIDATION.SETTLEMENT_START_DATE_IS_REQUIRED'),
        ];
      } else if (this.createForm.get('notes').invalid) {
        errorMessage.title = this.translationService.translate('FINANCES.HR.HR_DATA_VALIDATION.NOTES_ERROR');
        errorMessage.body = [
          this.translationService.translate('FINANCES.HR.HR_DATA_VALIDATION.NOTES_IS_REQUIRED'),
          this.translationService.translate('FINANCES.HR.HR_DATA_VALIDATION.NOTES_LENGTH_ERROR'),
        ];
      }

      this.createForm.markAllAsTouched();
      return this.notificationService.warningWithTitle(errorMessage);
    }

    this.confirmCreate();
  }

  /**
   * Updates the currently edited loan only if the filled data passed validations.
   */
  confirmUpdate() {
    if (this.updateForm.invalid) {
      const errorMessage = new NotificationMessage();
      if (this.updateForm.get('settlementValue').invalid) {
        errorMessage.title = this.translationService.translate('FINANCES.HR.HR_DATA_VALIDATION.SETTLEMENT_VALUE_ERROR');
        errorMessage.body = [
          this.translationService.translate('FINANCES.HR.HR_DATA_VALIDATION.SETTLEMENT_VALUE_IS_REQUIRED'),
          this.translationService.translate('FINANCES.HR.HR_DATA_VALIDATION.SETTLEMENT_VALUE_LENGTH_ERROR'),
        ];
      } else if (this.updateForm.get('settlementStartDate').invalid) {
        errorMessage.title = this.translationService.translate(
          'FINANCES.HR.HR_DATA_VALIDATION.SETTLEMENT_START_DATE_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate('FINANCES.HR.HR_DATA_VALIDATION.SETTLEMENT_START_DATE_IS_REQUIRED'),
        ];
      }

      this.updateForm.markAllAsTouched();
      return this.notificationService.warningWithTitle(errorMessage);
    } else {
      let settlementStartDateFormatted;

      const { settlementStartDate } = this.updateForm.value;
      if (settlementStartDate && settlementStartDate.year) {
        settlementStartDateFormatted = new Date(
          settlementStartDate.year,
          settlementStartDate.month - 1,
          settlementStartDate.day
        );
        settlementStartDateFormatted.setHours(0, 0, 0);
      }
      this.financesStore$.dispatch(
        new fromFinancesStore.UpdateEmployeeLoan({
          ...this.updateForm.value,
          employeeLoanId: this.selectedLoan.id,
          settlementStartDate: settlementStartDateFormatted,
        })
      );
    }
  }

  /**
   * Handles search parameters change.
   */
  search(event?: KeyboardEvent) {
    if (event && event.key === 'Enter') {
      event.preventDefault();
      return;
    }

    this.applyFiltersAndSearch();
  }

  /**
   * Handles pagination page-changed event.
   * @param page The current selected page number.
   */
  pageChanged(page: number) {
    /** Update pagination page. */
    this.searchForm.patchValue({ page });

    this.applyFiltersAndSearch(page);
  }

  /**
   * Applies the search filters and dispatches a search action.
   * @param page The current page number, default to `1`.
   */
  applyFiltersAndSearch(page: number = 1) {
    if (!this.searchForm.get('fromDate')?.value || !this.searchForm.get('toDate')?.value) {
      return this.notificationService.warning(
        this.translationService.translate('HR.DEDUCTIONS.NOTIFICATION_MESSAGE.SELECT_DATE')
      );
    }

    const { employees, departments, locations, loanPaymentTypes, fromDate, toDate } = this.searchForm.value;
    const fromDateFormatted = new Date(fromDate.year, fromDate.month - 1, fromDate.day);
    fromDateFormatted.setHours(0, 0, 0, 0);

    const toDateFormatted = new Date(toDate.year, toDate.month - 1, toDate.day);
    toDateFormatted.setHours(23, 59, 59, 0);

    this.financesStore$.dispatch(
      new fromFinancesStore.SearchEmployeeLoans({
        employees,
        departments,
        locations,
        loanPaymentTypes,
        fromDate: fromDateFormatted,
        toDate: toDateFormatted,
        page,
      })
    );
  }

  /**
   * Creates a new list of loans only if the filled data passed validations.
   */
  confirmCreate() {
    let transactionDateFormatted;
    let settlementStartDateFormatted;

    const { transactionDate, settlementStartDate } = this.createForm.value;

    if (transactionDate && transactionDate.year) {
      transactionDateFormatted = new Date(transactionDate.year, transactionDate.month - 1, transactionDate.day);
      transactionDateFormatted.setHours(new Date().getHours(), new Date().getMinutes());
      this.createForm.value.transactionDate = transactionDateFormatted;
    }

    if (settlementStartDate && settlementStartDate.year) {
      settlementStartDateFormatted = DateUtil.toDateOnly(
        new Date(settlementStartDate.year, settlementStartDate.month - 1, settlementStartDate.day)
      );
    }

    this.financesStore$.dispatch(
      new fromFinancesStore.CreateEmployeeLoan({
        employeeId: this.createForm.value.employeeId,
        bankFee: this.createForm.value.bankFee,
        bankFeeTaxValue: this.createForm.value.bankFeeTaxValue,
        bankAccountId: this.createForm.value.bankAccountId,
        loanPaymentTypeId: this.createForm.value.loanPaymentTypeId,
        value: this.createForm.value.value,
        settlementValue: this.createForm.value.settlementValue,
        transactionDate: transactionDateFormatted,
        settlementStartDate: settlementStartDateFormatted,
        enableSettlement: this.createForm.value.enableSettlement,
        notes: this.createForm.value.notes,
        attachments: this.attachmentsForm.value.filter((file) => !!file),
      })
    );
  }

  /**
   * Enables trackBy feature for *ngFor utility to track items
   * depending on a comparer to reduce DOM change.
   * If the item is already exist then no HTML will change, only bounded values.
   * @param index The index of the item.
   * @param loan The item to determine if it was changed or not.
   */
  trackItems(index: number, loan: EmployeeLoan) {
    return loan ? loan.id : undefined;
  }

  /**
   * Selects the newly selected bank account from the bank accounts search list.
   * @param bankAccounts The list of newly selected bank accounts to select the only one in the list.
   */
  selectBankAccount([bankAccount]: BankAccount[]) {
    if (bankAccount) {
      this.bankAccounts = [bankAccount];
      this.createForm.patchValue({ bankAccountId: bankAccount.id });
    }
  }

  /**
   * Adds the newly selected employees the employees search list.
   * @param employees The list of newly selected employees to be added.
   */
  selectEmployees(employees: Employee[]) {
    const selectedIds: number[] = this.searchForm.get('employees').value;
    this.employees = [...this.employees.filter((employee) => selectedIds.includes(employee.id)), ...employees];
    this.searchForm.patchValue({ employees: this.employees.map((employee) => employee.id) });
  }

  /**
   * Adds the newly selected departments the departments search list.
   * @param departments The list of newly selected departments to be added.
   */
  selectDepartments(departments: Department[]) {
    const selectedIds: number[] = this.searchForm.get('departments').value;
    this.departments = [
      ...this.departments.filter((department) => selectedIds.includes(department.id)),
      ...departments,
    ];
    this.searchForm.patchValue({ departments: this.departments.map((department) => department.id) });
  }

  /**
   * Adds the newly selected locations the locations search list.
   * @param locations The list of newly selected locations to be added.
   */
  selectLocations(locations: Location[]) {
    const selectedIds: number[] = this.searchForm.get('locations').value;
    this.locations = [...this.locations.filter((location) => selectedIds.includes(location.id)), ...locations];
    this.searchForm.patchValue({ locations: this.locations.map((location) => location.id) });
  }

  /**
   * Selects the newly selected employee from the employee search list.
   * @param employee The list of newly selected employee to select the only one in the list.
   */
  selectEmployee([employee]: Employee[]) {
    if (employee) {
      this.employees = [employee];
      this.createForm.patchValue({ employeeId: employee.id });
    }
  }

  /**
   * Opens the modal of the given templateRef.
   * @param modalRef The modal templateRef to be opened.
   */
  openModal(modalRef) {
    this.modalService.open(modalRef, { size: 'xl' });
  }

  /**
   * Closes the currently opened modal.
   */
  closeModal() {
    this.modalService.dismissAll();
    this.createForm.reset();
    this.attachmentsForm.clear();
    this.addBlankAttachment();
  }

  /**
   * Maps and returns the set of loan attachments into a set of urls.
   * @param attachments The loan attachments to be mapped.
   */
  getAttachmentsUrls(attachments: EmployeeLoanAttachment[]): string[] {
    return attachments ? attachments.map((attachment) => attachment.url) : [];
  }

  /**
   * Adds a blank attachment form-control to the attachments form for quick start..
   */
  addBlankAttachment() {
    this.attachmentsForm.push(new FormControl());
  }

  /**
   * Opens the modal of the given templateRef.
   * @param modalRef The modal templateRef to be opened.
   */
  openInfoModal(modalRef) {
    /**
     * Open the modal & listen for user press ESC key.
     */
    this.modal = this.modalService.open(modalRef, { size: 'lg' });
    this.modal.result.then(null, () => this.closeModal());
  }
}
