import { Injectable } from '@angular/core';

import { of } from 'rxjs';
import { switchMap, map, catchError, tap, debounceTime } from 'rxjs/operators';
import { Effect, ofType, Actions } from '@ngrx/effects';

import { NotificationService, StatusCode, PAGINATION, TranslationService } from 'shared';
import { BanksService } from 'finances/services';
import {
  BanksActionType,
  CreateBank,
  CreateBankFail,
  CreateBankSuccess,
  DeleteBank,
  DeleteBankFail,
  DeleteBankSuccess,
  FindBank,
  FindBankFail,
  FindBankSuccess,
  SearchBanks,
  SearchBanksFail,
  SearchBanksSuccess,
  UpdateBank,
  UpdateBankFail,
  UpdateBankSuccess,
} from 'finances/store/actions';

@Injectable()
export class BanksEffects {
  constructor(
    private actions$: Actions,
    private BanksService: BanksService,
    private notificationService: NotificationService,
    private translationService: TranslationService
  ) {}

  /* ========================= SEARCH_BANKS =================================== */
  @Effect()
  search$ = this.actions$.pipe(
    ofType(BanksActionType.SEARCH_BANKS),
    debounceTime(300),
    switchMap(({ payload }: SearchBanks) =>
      this.BanksService.search(payload?.name ?? '', payload.page, PAGINATION.Banks).pipe(
        map((response) => new SearchBanksSuccess(response)),
        catchError((error) => of(new SearchBanksFail(error)))
      )
    )
  );

  /* ========================= FIND_BANK =================================== */
  @Effect()
  find$ = this.actions$.pipe(
    ofType(BanksActionType.FIND_BANK),
    switchMap((action: FindBank) =>
      this.BanksService.findById(action.payload).pipe(
        map((response) => new FindBankSuccess(response)),
        catchError((error) => of(new FindBankFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  findFail$ = this.actions$.pipe(
    ofType(BanksActionType.FIND_BANK_FAIL),
    tap((action: FindBankFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(this.translationService.translate('FINANCES.BANKS.BANK_NOT_FOUND'));
      }
    })
  );

  /* ========================= CREATE_BANK =================================== */
  @Effect()
  create$ = this.actions$.pipe(
    ofType(BanksActionType.CREATE_BANK),
    switchMap((action: CreateBank) =>
      this.BanksService.create(action.payload).pipe(
        map((response) => new CreateBankSuccess(response)),
        catchError((error) => of(new CreateBankFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  createSuccess$ = this.actions$.pipe(
    ofType(BanksActionType.CREATE_BANK_SUCCESS),
    tap((action: CreateBankSuccess) => {
      this.notificationService.success(this.translationService.translate('FINANCES.BANKS.BANK_ADDED'));
    })
  );

  @Effect({ dispatch: false })
  createFail$ = this.actions$.pipe(
    ofType(BanksActionType.CREATE_BANK_FAIL),
    tap((action: CreateBankFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );

  /* ========================= UPDATE_BANK =================================== */
  @Effect()
  update$ = this.actions$.pipe(
    ofType(BanksActionType.UPDATE_BANK),
    switchMap((action: UpdateBank) =>
      this.BanksService.update(action.payload).pipe(
        map((response) => new UpdateBankSuccess(response)),
        catchError((error) => of(new UpdateBankFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  updateSuccess$ = this.actions$.pipe(
    ofType(BanksActionType.UPDATE_BANK_SUCCESS),
    tap((action: UpdateBankSuccess) => {
      this.notificationService.success(this.translationService.translate('FINANCES.BANKS.BANK_UPDATED'));
    })
  );

  @Effect({ dispatch: false })
  updateFail$ = this.actions$.pipe(
    ofType(BanksActionType.UPDATE_BANK_FAIL),
    tap((action: UpdateBankFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(this.translationService.translate('FINANCES.BANKS.BANK_NOT_FOUND'));
      } else if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );

  /* ========================= DELETE_BANK =================================== */
  @Effect()
  delete$ = this.actions$.pipe(
    ofType(BanksActionType.DELETE_BANK),
    switchMap((action: DeleteBank) =>
      this.BanksService.delete(action.payload).pipe(
        map((response) => new DeleteBankSuccess(response)),
        catchError((error) => of(new DeleteBankFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  deleteSuccess$ = this.actions$.pipe(
    ofType(BanksActionType.DELETE_BANK_SUCCESS),
    tap((action: DeleteBankSuccess) => {
      this.notificationService.success(this.translationService.translate('FINANCES.BANKS.BANK_DELETED'));
    })
  );

  @Effect({ dispatch: false })
  deleteFail$ = this.actions$.pipe(
    ofType(BanksActionType.DELETE_BANK_FAIL),
    tap((action: DeleteBankFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(this.translationService.translate('FINANCES.BANKS.BANK_NOT_FOUND'));
      } else if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );
}
