import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';

import { AppHttpResponse } from 'shared/models';

/**
 * The finances reports services.
 */
@Injectable()
export class FinancesReportsService {
  /**
   * The relative route for the finances reports.
   *
   * No leading or trailing slashes required.
   */
  private financesReportsApi = 'reporting/finances';

  constructor(private http: HttpClient) {}

  /**
   * Gets the trial balance matching the provided query params.
   * @param accounts The array of the  ids of accounts.
   * @param costCenters The array of the  ids of cost centers.
   * @param fromDate The fromDate of the trial balance.
   * @param toDate The toDate of the trial balance.
   */
  public getTrialBalanceReport(
    accounts: number[],
    costCenters: number[],
    fromDate: Date,
    toDate: Date
  ): Observable<AppHttpResponse<string>> {
    const params = new HttpParams()
      .set('accounts', accounts?.join(','))
      .set('costCenters', costCenters?.join(','))
      .set('fromDate', fromDate?.toISOString())
      .set('toDate', toDate?.toISOString());

    return this.http.get<AppHttpResponse<string>>(`${this.financesReportsApi}/trial-balance-report`, { params });
  }

  /**
   * Gets the taxes matching the provided query params.
   * @param costCenters The array of the  ids of cost centers.
   * @param fromDate The fromDate of the trial balance.
   * @param toDate The toDate of the trial balance.
   */
  public getTaxesReport(costCenters: number[], fromDate: Date, toDate: Date): Observable<AppHttpResponse<string>> {
    const params = new HttpParams()
      .set('costCenters', costCenters?.join(','))
      .set('fromDate', fromDate?.toISOString())
      .set('toDate', toDate?.toISOString());

    return this.http.get<AppHttpResponse<string>>(`${this.financesReportsApi}/taxes-report`, { params });
  }

  /**
   * Gets the balance sheet report matching the provided query params.
   */
  public getBalanceSheetReport(): Observable<AppHttpResponse<string>> {
    return this.http.get<AppHttpResponse<string>>(`${this.financesReportsApi}/balance-sheet-report`);
  }

  /**
   * Gets the income statement matching the provided query params.
   * @param costCenters The array of the  ids of cost centers.
   * @param fromDate The fromDate of the trial balance.
   * @param toDate The toDate of the trial balance.
   */
  public getIncomeStatementReport(
    costCenters: number[],
    fromDate: Date,
    toDate: Date
  ): Observable<AppHttpResponse<string>> {
    const params = new HttpParams()
      .set('costCenters', costCenters?.join(','))
      .set('fromDate', fromDate?.toISOString())
      .set('toDate', toDate?.toISOString());

    return this.http.get<AppHttpResponse<string>>(`${this.financesReportsApi}/income-statement-report`, { params });
  }

  /**
   * Gets the journals matching the provided query params.
   * @param journalTypes The journal types of the journal.
   * @param fromDate The fromDate of the journal.
   * @param toDate The toDate of the journal.
   */
  public getJournalsReport(journalTypes: number[], fromDate: Date, toDate: Date): Observable<AppHttpResponse<string>> {
    const params = new HttpParams()
      .set('types', journalTypes?.join(','))
      .set('fromDate', fromDate?.toISOString())
      .set('toDate', toDate?.toISOString());

    return this.http.get<AppHttpResponse<string>>(`${this.financesReportsApi}/journals-report`, { params });
  }

  /**
   * Gets the account analysis matching the provided query params.
   * @param accountId The id of the account.
   * @param costCenters The array of the  ids of cost centers.
   * @param fromDate The fromDate of the account.
   * @param toDate The toDate of the account.
   */
  public getAccountAnalysisReport(
    accountId: number,
    costCenters: number[],
    fromDate: Date,
    toDate: Date
  ): Observable<AppHttpResponse<string>> {
    const params = new HttpParams()
      .set('accountId', accountId?.toString())
      .set('costCenters', costCenters?.join(','))
      .set('fromDate', fromDate?.toISOString())
      .set('toDate', toDate?.toISOString());

    return this.http.get<AppHttpResponse<string>>(`${this.financesReportsApi}/account-analysis-report`, { params });
  }

  /**
   * Gets the bank account summary matching the provided query params.
   * @param fromDate The fromDate.
   * @param toDate The toDate.
   */
  public getBankAccountSummaryReport(
    bankAccountId: string,
    fromDate: Date,
    toDate: Date
  ): Observable<AppHttpResponse<string>> {
    const params = new HttpParams()
      .set('fromDate', fromDate?.toISOString())
      .set('toDate', toDate?.toISOString())
      .set('bankAccountId', bankAccountId);

    return this.http.get<AppHttpResponse<string>>(`${this.financesReportsApi}/bank-account-summary-report`, { params });
  }
}
