import { findIndex } from 'lodash';

import { journalAssociationActions, JournalAssociationActionType } from 'finances/store/actions';
import { JournalAssociationState } from 'finances/store/states';

/**
 * The initial state from which the state starts.
 */
const initialState: JournalAssociationState = {
  vendorLogData: null,
  purchaseInvoiceData: null,
  purchaseReturnData: null,
  customerLogData: null,
  saleInvoiceData: null,
  saleReturnData: null,
  transferStockData: null,
  incomingStockData: null,
  outgoingStockData: null,
  pettyCashRefundData: null,
  pettyCashRefundRequestData: null,
  assetData: null,
  bankAccountLogData: null,
  assetDepreciationData: null,
  assetSaleInvoiceData: null,
  excludedAssetData: null,
  accrualPayrollData: null,
  payrollPaymentRefundData: null,
  govServiceRequestsData: null,
  payrollPaymentData: null,
  employeeLoanData: null,
  error: null,
  isFindingVendorLog: false,
  isFindVendorLogCompleted: false,
  isFindingPurchaseInvoice: false,
  isFindPurchaseInvoiceCompleted: false,
  isFindingPurchaseReturn: false,
  isFindPurchaseReturnCompleted: false,
  isFindingCustomerLog: false,
  isFindCustomerLogCompleted: false,
  isFindingSaleInvoice: false,
  isFindSaleInvoiceCompleted: false,
  isFindingSaleReturn: false,
  isFindSaleReturnCompleted: false,
  isFindingTransferStock: false,
  isFindTransferStockCompleted: false,
  isFindingIncomingStock: false,
  isFindIncomingStockCompleted: false,
  isFindingOutgoingStock: false,
  isFindOutgoingStockCompleted: false,
  isFindingPettyCashRefundRequest: false,
  isFindPettyCashRefundRequestCompleted: false,
  isFindingPettyCashRefund: false,
  isFindPettyCashRefundCompleted: false,
  isFindingAsset: false,
  isFindAssetCompleted: false,
  isFindingBankAccountLog: false,
  isFindBankAccountLogCompleted: false,
  isFindingAssetDepreciation: false,
  isFindAssetDepreciationCompleted: false,
  isFindingAssetSaleInvoice: false,
  isFindAssetSaleInvoiceCompleted: false,
  isFindingExcludedAsset: false,
  isFindExcludedAssetCompleted: false,
  isFindingAccrualPayroll: false,
  isFindAccrualPayrollCompleted: false,
  isFindingPayrollPaymentRefund: false,
  isFindPayrollPaymentRefundCompleted: false,
  isFindingGovServiceRequest: false,
  isFindGovServiceRequestCompleted: false,
  isFindingPayrollPayment: false,
  isFindPayrollPaymentCompleted: false,
  isFindingEmployeeLoan: false,
  isFindEmployeeLoanCompleted: false,
};

/**
 * The reducer function that is called in each action dispatch against the state.
 * @param state The current state.
 * @param action The action that will affect the state.
 */
export function journalAssociationReducer(
  state: JournalAssociationState = initialState,
  action: journalAssociationActions
): JournalAssociationState {
  switch (action.type) {
    //#region FIND_JOURNAL_ASSOCIATION_VENDOR_LOG

    case JournalAssociationActionType.FIND_JOURNAL_ASSOCIATION_VENDOR_LOG: {
      return {
        ...state,
        vendorLogData: null,
        error: null,
        isFindingVendorLog: true,
        isFindVendorLogCompleted: false,
      };
    }

    case JournalAssociationActionType.FIND_JOURNAL_ASSOCIATION_VENDOR_LOG_FAIL: {
      return {
        ...state,
        error: action.payload,
        isFindingVendorLog: false,
        isFindVendorLogCompleted: false,
      };
    }

    case JournalAssociationActionType.FIND_JOURNAL_ASSOCIATION_VENDOR_LOG_SUCCESS: {
      return {
        ...state,
        vendorLogData: action.payload.data,
        error: null,
        isFindingVendorLog: false,
        isFindVendorLogCompleted: true,
      };
    }

    //#endregion FIND_JOURNAL_ASSOCIATION_VENDOR_LOG

    //#region FIND_JOURNAL_ASSOCIATION_ACCRUAL_PAYROLL

    case JournalAssociationActionType.FIND_JOURNAL_ASSOCIATION_ACCRUAL_PAYROLL: {
      return {
        ...state,
        accrualPayrollData: null,
        error: null,
        isFindingAccrualPayroll: true,
        isFindAccrualPayrollCompleted: false,
      };
    }

    case JournalAssociationActionType.FIND_JOURNAL_ASSOCIATION_ACCRUAL_PAYROLL_FAIL: {
      return {
        ...state,
        error: action.payload,
        isFindingAccrualPayroll: false,
        isFindAccrualPayrollCompleted: false,
      };
    }

    case JournalAssociationActionType.FIND_JOURNAL_ASSOCIATION_ACCRUAL_PAYROLL_SUCCESS: {
      return {
        ...state,
        accrualPayrollData: action.payload.data,
        error: null,
        isFindingAccrualPayroll: false,
        isFindAccrualPayrollCompleted: true,
      };
    }

    //#endregion FIND_JOURNAL_ASSOCIATION_ACCRUAL_PAYROLL

    //#region FIND_JOURNAL_ASSOCIATION_PAYROLL_PAYMENT_REFUND

    case JournalAssociationActionType.FIND_JOURNAL_ASSOCIATION_PAYROLL_PAYMENT_REFUND: {
      return {
        ...state,
        payrollPaymentRefundData: null,
        error: null,
        isFindingPayrollPaymentRefund: true,
        isFindPayrollPaymentRefundCompleted: false,
      };
    }

    case JournalAssociationActionType.FIND_JOURNAL_ASSOCIATION_PAYROLL_PAYMENT_REFUND_FAIL: {
      return {
        ...state,
        error: action.payload,
        isFindingPayrollPaymentRefund: false,
        isFindPayrollPaymentRefundCompleted: false,
      };
    }

    case JournalAssociationActionType.FIND_JOURNAL_ASSOCIATION_PAYROLL_PAYMENT_REFUND_SUCCESS: {
      return {
        ...state,
        payrollPaymentRefundData: action.payload.data,
        error: null,
        isFindingPayrollPaymentRefund: false,
        isFindPayrollPaymentRefundCompleted: true,
      };
    }

    //#endregion FIND_JOURNAL_ASSOCIATION_PAYROLL_PAYMENT_REFUND

    //#region FIND_JOURNAL_ASSOCIATION_GOV_SERVICE_REQUEST

    case JournalAssociationActionType.FIND_JOURNAL_ASSOCIATION_GOV_SERVICE_REQUEST: {
      return {
        ...state,
        govServiceRequestsData: null,
        error: null,
        isFindingGovServiceRequest: true,
        isFindGovServiceRequestCompleted: false,
      };
    }

    case JournalAssociationActionType.FIND_JOURNAL_ASSOCIATION_GOV_SERVICE_REQUEST_FAIL: {
      return {
        ...state,
        error: action.payload,
        isFindingGovServiceRequest: false,
        isFindGovServiceRequestCompleted: false,
      };
    }

    case JournalAssociationActionType.FIND_JOURNAL_ASSOCIATION_GOV_SERVICE_REQUEST_SUCCESS: {
      return {
        ...state,
        govServiceRequestsData: action.payload.data,
        error: null,
        isFindingGovServiceRequest: false,
        isFindGovServiceRequestCompleted: true,
      };
    }

    //#endregion FIND_JOURNAL_ASSOCIATION_GOV_SERVICE_REQUEST

    //#region FIND_JOURNAL_ASSOCIATION_ASSET

    case JournalAssociationActionType.FIND_JOURNAL_ASSOCIATION_ASSET: {
      return {
        ...state,
        assetData: null,
        error: null,
        isFindingAsset: true,
        isFindAssetCompleted: false,
      };
    }

    case JournalAssociationActionType.FIND_JOURNAL_ASSOCIATION_ASSET_FAIL: {
      return {
        ...state,
        error: action.payload,
        isFindingAsset: false,
        isFindAssetCompleted: false,
      };
    }

    case JournalAssociationActionType.FIND_JOURNAL_ASSOCIATION_ASSET_SUCCESS: {
      return {
        ...state,
        assetData: action.payload.data,
        error: null,
        isFindingAsset: false,
        isFindAssetCompleted: true,
      };
    }

    //#endregion FIND_JOURNAL_ASSOCIATION_ASSET

    //#region FIND_JOURNAL_ASSOCIATION_ACCRUAL_PAYROLL

    case JournalAssociationActionType.FIND_JOURNAL_ASSOCIATION_ACCRUAL_PAYROLL: {
      return {
        ...state,
        accrualPayrollData: null,
        error: null,
        isFindingAccrualPayroll: true,
        isFindAccrualPayrollCompleted: false,
      };
    }

    case JournalAssociationActionType.FIND_JOURNAL_ASSOCIATION_ACCRUAL_PAYROLL_FAIL: {
      return {
        ...state,
        error: action.payload,
        isFindingAccrualPayroll: false,
        isFindAccrualPayrollCompleted: false,
      };
    }

    case JournalAssociationActionType.FIND_JOURNAL_ASSOCIATION_ACCRUAL_PAYROLL_SUCCESS: {
      return {
        ...state,
        accrualPayrollData: action.payload.data,
        error: null,
        isFindingAccrualPayroll: false,
        isFindAccrualPayrollCompleted: true,
      };
    }

    //#endregion FIND_JOURNAL_ASSOCIATION_ACCRUAL_PAYROLL

    //#region FIND_JOURNAL_ASSOCIATION_ASSET

    case JournalAssociationActionType.FIND_JOURNAL_ASSOCIATION_ASSET: {
      return {
        ...state,
        assetData: null,
        error: null,
        isFindingAsset: true,
        isFindAssetCompleted: false,
      };
    }

    case JournalAssociationActionType.FIND_JOURNAL_ASSOCIATION_ASSET_FAIL: {
      return {
        ...state,
        error: action.payload,
        isFindingAsset: false,
        isFindAssetCompleted: false,
      };
    }

    case JournalAssociationActionType.FIND_JOURNAL_ASSOCIATION_ASSET_SUCCESS: {
      return {
        ...state,
        assetData: action.payload.data,
        error: null,
        isFindingAsset: false,
        isFindAssetCompleted: true,
      };
    }

    //#endregion FIND_JOURNAL_ASSOCIATION_ASSET

    //#region FIND_JOURNAL_ASSOCIATION_PURCHASE_INVOICE

    case JournalAssociationActionType.FIND_JOURNAL_ASSOCIATION_PURCHASE_INVOICE: {
      return {
        ...state,
        purchaseInvoiceData: null,
        error: null,
        isFindingPurchaseInvoice: true,
        isFindPurchaseInvoiceCompleted: false,
      };
    }

    case JournalAssociationActionType.FIND_JOURNAL_ASSOCIATION_PURCHASE_INVOICE_FAIL: {
      return {
        ...state,
        error: action.payload,
        isFindingPurchaseInvoice: false,
        isFindPurchaseInvoiceCompleted: false,
      };
    }

    case JournalAssociationActionType.FIND_JOURNAL_ASSOCIATION_PURCHASE_INVOICE_SUCCESS: {
      return {
        ...state,
        purchaseInvoiceData: action.payload.data,
        error: null,
        isFindingPurchaseInvoice: false,
        isFindPurchaseInvoiceCompleted: true,
      };
    }

    //#endregion FIND_JOURNAL_ASSOCIATION_PURCHASE_INVOICE

    //#region FIND_JOURNAL_ASSOCIATION_PURCHASE_RETURN

    case JournalAssociationActionType.FIND_JOURNAL_ASSOCIATION_PURCHASE_RETURN: {
      return {
        ...state,
        purchaseReturnData: null,
        error: null,
        isFindingPurchaseReturn: true,
        isFindPurchaseReturnCompleted: false,
      };
    }

    case JournalAssociationActionType.FIND_JOURNAL_ASSOCIATION_PURCHASE_RETURN_FAIL: {
      return {
        ...state,
        error: action.payload,
        isFindingPurchaseReturn: false,
        isFindPurchaseReturnCompleted: false,
      };
    }

    case JournalAssociationActionType.FIND_JOURNAL_ASSOCIATION_PURCHASE_RETURN_SUCCESS: {
      return {
        ...state,
        purchaseReturnData: action.payload.data,
        error: null,
        isFindingPurchaseReturn: false,
        isFindPurchaseReturnCompleted: true,
      };
    }

    //#endregion FIND_JOURNAL_ASSOCIATION_PURCHASE_RETURN

    //#region FIND_JOURNAL_ASSOCIATION_CUSTOMER_LOG

    case JournalAssociationActionType.FIND_JOURNAL_ASSOCIATION_CUSTOMER_LOG: {
      return {
        ...state,
        customerLogData: null,
        error: null,
        isFindingCustomerLog: true,
        isFindCustomerLogCompleted: false,
      };
    }

    case JournalAssociationActionType.FIND_JOURNAL_ASSOCIATION_CUSTOMER_LOG_FAIL: {
      return {
        ...state,
        error: action.payload,
        isFindingCustomerLog: false,
        isFindCustomerLogCompleted: false,
      };
    }

    case JournalAssociationActionType.FIND_JOURNAL_ASSOCIATION_CUSTOMER_LOG_SUCCESS: {
      return {
        ...state,
        customerLogData: action.payload.data,
        error: null,
        isFindingCustomerLog: false,
        isFindCustomerLogCompleted: true,
      };
    }

    //#endregion FIND_JOURNAL_ASSOCIATION_CUSTOMER_LOG

    //#region FIND_JOURNAL_ASSOCIATION_SALE_INVOICE

    case JournalAssociationActionType.FIND_JOURNAL_ASSOCIATION_SALE_INVOICE: {
      return {
        ...state,
        saleInvoiceData: null,
        error: null,
        isFindingSaleInvoice: true,
        isFindSaleInvoiceCompleted: false,
      };
    }

    case JournalAssociationActionType.FIND_JOURNAL_ASSOCIATION_SALE_INVOICE_FAIL: {
      return {
        ...state,
        error: action.payload,
        isFindingSaleInvoice: false,
        isFindSaleInvoiceCompleted: false,
      };
    }

    case JournalAssociationActionType.FIND_JOURNAL_ASSOCIATION_SALE_INVOICE_SUCCESS: {
      return {
        ...state,
        saleInvoiceData: action.payload.data,
        error: null,
        isFindingSaleInvoice: false,
        isFindSaleInvoiceCompleted: true,
      };
    }

    //#endregion FIND_JOURNAL_ASSOCIATION_SALE_INVOICE

    //#region FIND_JOURNAL_ASSOCIATION_SALE_RETURN

    case JournalAssociationActionType.FIND_JOURNAL_ASSOCIATION_SALE_RETURN: {
      return {
        ...state,
        saleReturnData: null,
        error: null,
        isFindingSaleReturn: true,
        isFindSaleReturnCompleted: false,
      };
    }

    case JournalAssociationActionType.FIND_JOURNAL_ASSOCIATION_SALE_RETURN_FAIL: {
      return {
        ...state,
        error: action.payload,
        isFindingSaleReturn: false,
        isFindSaleReturnCompleted: false,
      };
    }

    case JournalAssociationActionType.FIND_JOURNAL_ASSOCIATION_SALE_RETURN_SUCCESS: {
      return {
        ...state,
        saleReturnData: action.payload.data,
        error: null,
        isFindingSaleReturn: false,
        isFindSaleReturnCompleted: true,
      };
    }

    //#endregion FIND_JOURNAL_ASSOCIATION_SALE_RETURN

    //#region FIND_JOURNAL_ASSOCIATION_TRANSFER_STOCK

    case JournalAssociationActionType.FIND_JOURNAL_ASSOCIATION_TRANSFER_STOCK: {
      return {
        ...state,
        transferStockData: null,
        error: null,
        isFindingTransferStock: true,
        isFindTransferStockCompleted: false,
      };
    }

    case JournalAssociationActionType.FIND_JOURNAL_ASSOCIATION_TRANSFER_STOCK_FAIL: {
      return {
        ...state,
        error: action.payload,
        isFindingTransferStock: false,
        isFindTransferStockCompleted: false,
      };
    }

    case JournalAssociationActionType.FIND_JOURNAL_ASSOCIATION_TRANSFER_STOCK_SUCCESS: {
      return {
        ...state,
        transferStockData: action.payload.data,
        error: null,
        isFindingTransferStock: false,
        isFindTransferStockCompleted: true,
      };
    }

    //#endregion FIND_JOURNAL_ASSOCIATION_TRANSFER_STOCK

    //#region FIND_JOURNAL_ASSOCIATION_INCOMING_STOCK

    case JournalAssociationActionType.FIND_JOURNAL_ASSOCIATION_INCOMING_STOCK: {
      return {
        ...state,
        incomingStockData: null,
        error: null,
        isFindingIncomingStock: true,
        isFindIncomingStockCompleted: false,
      };
    }

    case JournalAssociationActionType.FIND_JOURNAL_ASSOCIATION_INCOMING_STOCK_FAIL: {
      return {
        ...state,
        error: action.payload,
        isFindingIncomingStock: false,
        isFindIncomingStockCompleted: false,
      };
    }

    case JournalAssociationActionType.FIND_JOURNAL_ASSOCIATION_INCOMING_STOCK_SUCCESS: {
      return {
        ...state,
        incomingStockData: action.payload.data,
        error: null,
        isFindingIncomingStock: false,
        isFindIncomingStockCompleted: true,
      };
    }

    //#endregion FIND_JOURNAL_ASSOCIATION_INCOMING__STOCK

    //#region FIND_JOURNAL_ASSOCIATION_OUTGOING_STOCK

    case JournalAssociationActionType.FIND_JOURNAL_ASSOCIATION_OUTGOING_STOCK: {
      return {
        ...state,
        outgoingStockData: null,
        error: null,
        isFindingOutgoingStock: true,
        isFindOutgoingStockCompleted: false,
      };
    }

    case JournalAssociationActionType.FIND_JOURNAL_ASSOCIATION_OUTGOING_STOCK_FAIL: {
      return {
        ...state,
        error: action.payload,
        isFindingOutgoingStock: false,
        isFindOutgoingStockCompleted: false,
      };
    }

    case JournalAssociationActionType.FIND_JOURNAL_ASSOCIATION_OUTGOING_STOCK_SUCCESS: {
      return {
        ...state,
        outgoingStockData: action.payload.data,
        error: null,
        isFindingOutgoingStock: false,
        isFindOutgoingStockCompleted: true,
      };
    }

    //#endregion FIND_JOURNAL_ASSOCIATION_OUTGOING__STOCK

    //#region FIND_JOURNAL_ASSOCIATION_PETTY_CASH_REFUND

    case JournalAssociationActionType.FIND_JOURNAL_ASSOCIATION_PETTY_CASH_REFUND: {
      return {
        ...state,
        pettyCashRefundData: null,
        error: null,
        isFindingPettyCashRefund: true,
        isFindPettyCashRefundCompleted: false,
      };
    }

    case JournalAssociationActionType.FIND_JOURNAL_ASSOCIATION_PETTY_CASH_REFUND_FAIL: {
      return {
        ...state,
        error: action.payload,
        isFindingPettyCashRefund: false,
        isFindPettyCashRefundCompleted: false,
      };
    }

    case JournalAssociationActionType.FIND_JOURNAL_ASSOCIATION_PETTY_CASH_REFUND_SUCCESS: {
      return {
        ...state,
        pettyCashRefundData: action.payload.data,
        error: null,
        isFindingPettyCashRefund: false,
        isFindPettyCashRefundCompleted: true,
      };
    }

    //#endregion FIND_JOURNAL_ASSOCIATION_PETTY_CASH_REFUND

    //#region FIND_JOURNAL_ASSOCIATION_PETTY_CASH_REFUND_REQUEST

    case JournalAssociationActionType.FIND_JOURNAL_ASSOCIATION_PETTY_CASH_REFUND_REQUEST: {
      return {
        ...state,
        pettyCashRefundRequestData: null,
        error: null,
        isFindingPettyCashRefundRequest: true,
        isFindPettyCashRefundRequestCompleted: false,
      };
    }

    case JournalAssociationActionType.FIND_JOURNAL_ASSOCIATION_PETTY_CASH_REFUND_REQUEST_FAIL: {
      return {
        ...state,
        error: action.payload,
        isFindingPettyCashRefundRequest: false,
        isFindPettyCashRefundRequestCompleted: false,
      };
    }

    case JournalAssociationActionType.FIND_JOURNAL_ASSOCIATION_PETTY_CASH_REFUND_REQUEST_SUCCESS: {
      return {
        ...state,
        pettyCashRefundRequestData: action.payload.data,
        error: null,
        isFindingPettyCashRefundRequest: false,
        isFindPettyCashRefundRequestCompleted: true,
      };
    }

    //#endregion FIND_JOURNAL_ASSOCIATION_PETTY_CASH_REFUND_REQUEST

    //#region FIND_JOURNAL_ASSOCIATION_PETTY_BANK_ACCOUNT_LOG

    case JournalAssociationActionType.FIND_JOURNAL_ASSOCIATION_BANK_ACCOUNT_LOG: {
      return {
        ...state,
        bankAccountLogData: null,
        error: null,
        isFindingBankAccountLog: true,
        isFindBankAccountLogCompleted: false,
      };
    }

    case JournalAssociationActionType.FIND_JOURNAL_ASSOCIATION_BANK_ACCOUNT_LOG_FAIL: {
      return {
        ...state,
        error: action.payload,
        isFindingBankAccountLog: false,
        isFindBankAccountLogCompleted: false,
      };
    }

    case JournalAssociationActionType.FIND_JOURNAL_ASSOCIATION_BANK_ACCOUNT_LOG_SUCCESS: {
      return {
        ...state,
        bankAccountLogData: action.payload.data,
        error: null,
        isFindingBankAccountLog: false,
        isFindBankAccountLogCompleted: true,
      };
    }

    //#endregion FIND_JOURNAL_ASSOCIATION_BANK_ACCOUNT_LOG

    //#region FIND_JOURNAL_ASSOCIATION_ASSET_DEPRECIATION

    case JournalAssociationActionType.FIND_JOURNAL_ASSOCIATION_ASSET_DEPRECIATION: {
      return {
        ...state,
        assetDepreciationData: null,
        error: null,
        isFindingAssetDepreciation: true,
        isFindAssetDepreciationCompleted: false,
      };
    }

    case JournalAssociationActionType.FIND_JOURNAL_ASSOCIATION_ASSET_DEPRECIATION_FAIL: {
      return {
        ...state,
        error: action.payload,
        isFindingAssetDepreciation: false,
        isFindAssetDepreciationCompleted: false,
      };
    }

    case JournalAssociationActionType.FIND_JOURNAL_ASSOCIATION_ASSET_DEPRECIATION_SUCCESS: {
      return {
        ...state,
        assetDepreciationData: action.payload.data,
        error: null,
        isFindingAssetDepreciation: false,
        isFindAssetDepreciationCompleted: true,
      };
    }

    //#endregion FIND_JOURNAL_ASSOCIATION_ASSET_DEPRECIATION

    //#region FIND_JOURNAL_ASSOCIATION_ASSET_SALE_INVOICE

    case JournalAssociationActionType.FIND_JOURNAL_ASSOCIATION_ASSET_SALE_INVOICE: {
      return {
        ...state,
        assetSaleInvoiceData: null,
        error: null,
        isFindingAssetSaleInvoice: true,
        isFindAssetSaleInvoiceCompleted: false,
      };
    }

    case JournalAssociationActionType.FIND_JOURNAL_ASSOCIATION_ASSET_SALE_INVOICE_FAIL: {
      return {
        ...state,
        error: action.payload,
        isFindingAssetSaleInvoice: false,
        isFindAssetSaleInvoiceCompleted: false,
      };
    }

    case JournalAssociationActionType.FIND_JOURNAL_ASSOCIATION_ASSET_SALE_INVOICE_SUCCESS: {
      return {
        ...state,
        assetSaleInvoiceData: action.payload.data,
        error: null,
        isFindingAssetSaleInvoice: false,
        isFindAssetSaleInvoiceCompleted: true,
      };
    }

    //#endregion FIND_JOURNAL_ASSOCIATION_ASSET_SALE_INVOICE

    //#region FIND_JOURNAL_ASSOCIATION_EXCLUDED_ASSET

    case JournalAssociationActionType.FIND_JOURNAL_ASSOCIATION_EXCLUDED_ASSET: {
      return {
        ...state,
        excludedAssetData: null,
        error: null,
        isFindingExcludedAsset: true,
        isFindExcludedAssetCompleted: false,
      };
    }

    case JournalAssociationActionType.FIND_JOURNAL_ASSOCIATION_EXCLUDED_ASSET_FAIL: {
      return {
        ...state,
        error: action.payload,
        isFindingExcludedAsset: false,
        isFindExcludedAssetCompleted: false,
      };
    }

    case JournalAssociationActionType.FIND_JOURNAL_ASSOCIATION_EXCLUDED_ASSET_SUCCESS: {
      return {
        ...state,
        excludedAssetData: action.payload.data,
        error: null,
        isFindingExcludedAsset: false,
        isFindExcludedAssetCompleted: true,
      };
    }

    //#endregion FIND_JOURNAL_ASSOCIATION_EXCLUDED_ASSET

    //#region FIND_JOURNAL_ASSOCIATION_PAYROLL_PAYMENT

    case JournalAssociationActionType.FIND_JOURNAL_ASSOCIATION_PAYROLL_PAYMENT: {
      return {
        ...state,
        payrollPaymentData: null,
        error: null,
        isFindingPayrollPayment: true,
        isFindPayrollPaymentCompleted: false,
      };
    }

    case JournalAssociationActionType.FIND_JOURNAL_ASSOCIATION_PAYROLL_PAYMENT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isFindingPayrollPayment: false,
        isFindPayrollPaymentCompleted: false,
      };
    }

    case JournalAssociationActionType.FIND_JOURNAL_ASSOCIATION_PAYROLL_PAYMENT_SUCCESS: {
      return {
        ...state,
        payrollPaymentData: action.payload.data,
        error: null,
        isFindingPayrollPayment: false,
        isFindPayrollPaymentCompleted: true,
      };
    }

    //#endregion FIND_JOURNAL_ASSOCIATION_PAYROLL_PAYMENT

    //#region FIND_JOURNAL_ASSOCIATION_EMPLOYEE_LOAN

    case JournalAssociationActionType.FIND_JOURNAL_ASSOCIATION_EMPLOYEE_LOAN: {
      return {
        ...state,
        employeeLoanData: null,
        error: null,
        isFindingEmployeeLoan: true,
        isFindEmployeeLoanCompleted: false,
      };
    }

    case JournalAssociationActionType.FIND_JOURNAL_ASSOCIATION_EMPLOYEE_LOAN_FAIL: {
      return {
        ...state,
        error: action.payload,
        isFindingEmployeeLoan: false,
        isFindEmployeeLoanCompleted: false,
      };
    }

    case JournalAssociationActionType.FIND_JOURNAL_ASSOCIATION_EMPLOYEE_LOAN_SUCCESS: {
      return {
        ...state,
        employeeLoanData: action.payload.data,
        error: null,
        isFindingEmployeeLoan: false,
        isFindEmployeeLoanCompleted: true,
      };
    }

    //#endregion FIND_JOURNAL_ASSOCIATION_EMPLOYEE_LOAN

    default:
      return state;
  }
}
