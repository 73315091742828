import { Injectable } from '@angular/core';

import { of } from 'rxjs';
import { switchMap, map, catchError, debounceTime } from 'rxjs/operators';
import { Effect, ofType, Actions } from '@ngrx/effects';

import { PAGINATION } from 'shared';
import { VendorsService } from 'app-search/services';
import { VendorsActionType, SearchVendors, SearchVendorsFail, SearchVendorsSuccess } from 'app-search/store/actions';

@Injectable()
export class VendorsEffects {
  constructor(private actions$: Actions, private vendorsService: VendorsService) {}

  /* ========================= SEARCH_VENDORS =================================== */
  @Effect()
  search$ = this.actions$.pipe(
    ofType(VendorsActionType.SEARCH_VENDORS),
    debounceTime(300),
    switchMap((action: SearchVendors) =>
      this.vendorsService.search(action.payload?.name ?? '', action.payload.page, PAGINATION.Vendors).pipe(
        map((response) => new SearchVendorsSuccess(response)),
        catchError((error) => of(new SearchVendorsFail(error)))
      )
    )
  );
}
