import { createSelector } from '@ngrx/store';

import { AppSearchState, PosDevicesState } from 'app-search/store/states';
import { selectAppSearchState } from 'app-search/store/selectors/feature.selectors';

/**
 * Gets the pos device state.
 */
const selectPosDevicesState = createSelector(selectAppSearchState, (state: AppSearchState) => state.posDevices);

/**
 * Gets the list of loaded pos device.
 */
export const getPosDevices = createSelector(selectPosDevicesState, (state: PosDevicesState) => state.data);

/**
 * Gets the pos device pagination info.
 */
export const getPosDevicesPaginationInfo = createSelector(
  selectPosDevicesState,
  (state: PosDevicesState) => state.paginationInfo
);

/**
 * Gets the error that occurred in the state.
 */
export const getPosDevicesError = createSelector(selectPosDevicesState, (state: PosDevicesState) => state.error);

/**
 * Determines if there is a running search process.
 */
export const getPosDevicesSearching = createSelector(
  selectPosDevicesState,
  (state: PosDevicesState) => state.isSearching
);

/**
 * Determines if the last search process has been ended successfully.
 */
export const getPosDevicesSearchCompleted = createSelector(
  selectPosDevicesState,
  (state: PosDevicesState) => state.isSearchCompleted
);
