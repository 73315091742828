import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';

import { Observable, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import * as fromStoresStore from 'stores/store';
import * as fromAuthStore from 'auth/store';
import { PageInfo, PaginationInfo } from 'shared';
import { Product, ProductClass } from 'stores/models';
import { Location } from 'stores/models';
import { Claims } from 'security/models';

@Component({
  selector: 'app-products-list',
  templateUrl: './products-list.component.html',
  styles: [],
})
export class ProductsListComponent implements OnInit, OnDestroy {
  /**
   * Gets or sets the information about the current page.
   */
  pageInfo: PageInfo = {
    title: 'STORES.PRODUCTS.PRODUCTS',
    icon: 'fa fa-cubes',
  };

  /**
   * Shows or hide the product classes list.
   */
  classesListVisibility = false;

  /**
   * Shows or hide the locations list.
   */
  locationsListVisibility = false;

  /**
   * The list of selected locations.
   */
  locations: Location[] = [];

  /**
   * The system supported user claims.
   */
  Claims = Claims;

  /**
   * Gets or sets the currently selected product for delete.
   */
  selectedProduct: Product;

  /**
   * The list of active product classes.
   */
  classes: ProductClass[] = [];

  /**
   * The list of products.
   */
  products$: Observable<Product[]>;

  /**
   * The pagination info.
   */
  paginationInfo$: Observable<PaginationInfo>;

  /**
   * Indicates whether there is a search process is running or not.
   */
  isSearching$: Observable<boolean>;

  /**
   * Indicates whether there is a delete-product process is running or not.
   */
  isDeleting$: Observable<boolean>;

  /**
   * The search form.
   */
  searchForm: FormGroup;

  /**
   * The set of subscriptions on this components,
   * these subscriptions must be unsubscribed before this component got destroyed.
   */
  subscriptions = new Subscription();

  /**
   * @param modalService The modal service.
   * @param storesStore$ The stores-store module.
   * @param authStore$ The auth-store module.
   */
  constructor(
    private modalService: NgbModal,
    private storesStore$: Store<fromStoresStore.StoresState>,
    private authStore$: Store<fromAuthStore.AuthState>
  ) {}

  ngOnInit(): void {
    this.init();
  }

  /**
   * Destroy component data
   */
  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  /**
   * Initialize component data.
   */
  init() {
    this.initForm();

    /**
     * Load data.
     */
    this.isDeleting$ = this.storesStore$.pipe(select(fromStoresStore.getSelectedProductDeleting));
    this.isSearching$ = this.storesStore$.pipe(select(fromStoresStore.getProductsSearching));

    let isManualSearchTriggeredBeforeForProducts = false;
    this.products$ = this.storesStore$.pipe(
      select(fromStoresStore.getProducts),
      tap((products) => {
        if (!isManualSearchTriggeredBeforeForProducts && !products.length) {
          isManualSearchTriggeredBeforeForProducts = true;
          this.search();
        }
      })
    );

    /* Subscribe to selected location to select it whenever it changed. */
    this.subscriptions.add(
      this.authStore$
        .pipe(
          select(fromAuthStore.getSessionUserDefaultLocation),
          tap((location) => {
            if (location && !this.searchForm.value.locations.includes(location.id)) {
              this.selectLocations([location]);
            }
          })
        )
        .subscribe()
    );

    this.paginationInfo$ = this.storesStore$.pipe(select(fromStoresStore.getProductsPaginationInfo));
  }

  /**
   * Initialize form and add validators.
   */
  initForm() {
    this.searchForm = new FormGroup({
      description: new FormControl(''),
      classes: new FormControl([]),
      locations: new FormControl([]),
      page: new FormControl(1),
    });
  }

  /**
   * Handles search parameters change.
   */
  search(event?: KeyboardEvent) {
    if (event && event.key === 'Enter') {
      event.preventDefault();
      return;
    }

    this.storesStore$.dispatch(
      new fromStoresStore.SearchProducts({
        description: this.searchForm.get('description').value,
        classes: this.searchForm.get('classes').value,
        locations: this.searchForm.get('locations').value,
        searchForStorableProducts: false,
        searchForServiceProducts: false,
        searchForAllProductTypes: true,
        page: 1,
      })
    );
  }

  /**
   * Handles pagination page-changed event.
   * @param page The current selected page number.
   */
  pageChanged(page: number) {
    /** Update pagination page. */
    this.searchForm.patchValue({ page });

    /** Get selected page items. */
    this.storesStore$.dispatch(
      new fromStoresStore.SearchProducts({
        description: this.searchForm.get('description').value,
        classes: this.searchForm.get('classes').value,
        locations: this.searchForm.get('locations').value,
        searchForStorableProducts: false,
        searchForServiceProducts: false,
        searchForAllProductTypes: true,
        page: this.searchForm.get('page').value,
      })
    );
  }

  /**
   * Enables trackBy feature for *ngFor utility to track items
   * depending on a comparer to reduce DOM change.
   * If the item is already exist then no HTML will change, only bounded values.
   * @param index The index of the item.
   * @param product The item to determine if it was changed or not.
   */
  trackItems(index: number, product: Product) {
    return product ? product.id : undefined;
  }

  /**
   * Deletes the product with the given id.
   * @param id The id of the product to be deleted.
   */
  delete(id: number) {
    this.storesStore$.dispatch(new fromStoresStore.DeleteProduct(id));
  }

  /**
   * Opens the modal of the given templateRef.
   * @param modalRef The modal templateRef to be opened.
   */
  openModal(modalRef) {
    this.modalService.open(modalRef);
  }

  /**
   * Adds the newly selected product classes the product classes search list.
   * @param classes The list of newly selected classes to be added.
   */
  selectClasses(classes: ProductClass[]) {
    const selectedIds: number[] = this.searchForm.get('classes').value;
    this.classes = [...this.classes.filter((productClass) => selectedIds.includes(productClass.id)), ...classes];
    this.searchForm.patchValue({ classes: this.classes.map((productClass) => productClass.id) });
  }

  /**
   * Adds the newly selected locations the locations search list.
   * @param locations The list of newly selected locations to be added.
   */
  selectLocations(locations: Location[]) {
    const selectedIds: number[] = this.searchForm.get('locations').value;
    this.locations = [...this.locations.filter((location) => selectedIds.includes(location.id)), ...locations];
    this.searchForm.patchValue({ locations: this.locations.map((location) => location.id) });
  }
}
