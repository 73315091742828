<!-- resource details starts -->
<div class="row">
  <div class="col-md-12">
    <!-- asset depreciations details starts -->
    <div class="card-header mb-3 pt-2">
      <h4 class="card-title">{{ 'ASSETS.ASSETS_DEPRECIATIONS' | translate }}</h4>
    </div>
    <div class="card-content">
      <div class="px-3">
        <div class="row">
          <div class="col-md-12">
            <div class="table-responsive">
              <table class="table text-center">
                <thead>
                  <tr>
                    <th scope="col" class="fit-width"><i class="fa fa-list-ol"></i></th>
                    <th scope="col" class="fit-width">{{ 'ASSETS.ASSET_DATA.ASSET_DESCRIPTION' | translate }}</th>
                    <th scope="col" class="fit-width">
                      {{ 'ASSETS.ASSET_DATA.DEPRECIATION_VALUE_TITLE' | translate }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let projectResourceLine of projectResourceAssetDepreciationLines$ | async; let i = index">
                    <th scope="row" class="fit-width">{{ i + 1 }}</th>
                    <td class="fit-width">
                      {{ projectResourceLine.assetDepreciation.asset.description }}
                    </td>
                    <td class="fit-width">{{ projectResourceLine.assetDepreciation.bookValueAfter | toDecimal }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- asset depreciations details ends -->
  </div>
</div>
<!-- resource details ends -->
