import { Action } from '@ngrx/store';

import { DepartmentType } from 'lookups/models';
import { AppHttpResponse } from 'shared';

/**
 * The Lookups-Module store department type action types.
 */
export enum DepartmentTypeActionType {
  GET_ALL_DEPARTMENT_TYPES = '[Lookups] [DepartmentType] Get All Department Types',
  GET_ALL_DEPARTMENT_TYPES_FAIL = '[Lookups] [DepartmentType] Get All Department Types Fail',
  GET_ALL_DEPARTMENT_TYPES_SUCCESS = '[Lookups] [DepartmentType] Get All Department Types Success',
}

/**
 * Represents a store department  type action.
 */
export class GetAllDepartmentType implements Action {
  /**
   * The type of the action.
   */
  readonly type = DepartmentTypeActionType.GET_ALL_DEPARTMENT_TYPES;

  /**
   * Fires a new department  type action.
   */
  constructor() {}
}

/**
 * Represents a store department  type fail action.
 */
export class GetAllDepartmentTypeFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = DepartmentTypeActionType.GET_ALL_DEPARTMENT_TYPES_FAIL;

  /**
   * Fires a new Department  type fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store department  type success action.
 */
export class GetAllDepartmentTypeSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = DepartmentTypeActionType.GET_ALL_DEPARTMENT_TYPES_SUCCESS;

  /**
   * Fires a department  type success action.
   * @param payload An object contains the list of department  types.
   */
  constructor(public payload: AppHttpResponse<DepartmentType[]>) {}
}

/**
 * Lookups-module Department  type action types.
 */
export type DepartmentTypeActions = GetAllDepartmentType | GetAllDepartmentTypeFail | GetAllDepartmentTypeSuccess;
