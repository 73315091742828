import { Decimal } from 'decimal.js';

import { ProductsFormItem } from 'stores/models';

/**
 * Provides the ability to calculate any invoice product items (POS) in an efficient way.
 */
export class ProductsFormPOSUtil {
  /**
   * Returns the total quantity for the given list of product items.
   * @param items The list of product item.
   * @returns total items quantity.
   */
  public static productsQuantity(items: ProductsFormItem[]): number {
    const summary = items.map((item) => (item.quantity ? item.quantity : '0'));
    const quantities = Decimal.sum(0, ...(summary.length ? summary : [0]));

    return quantities.toNumber();
  }

  /**
   * Returns the product total before applying discounts or tax.
   * @param item The item.
   * @returns The total of the product before applying discounts or tax.
   */
  public static productTotal(item: ProductsFormItem): number {
    const originalValue = this.productOriginalValue(item);
    const quantity = new Decimal(item.quantity);

    return originalValue.mul(quantity).toNumber();
  }

  /**
   * Returns the products total before applying discounts or tax.
   * @param items The list of items.
   * @returns The total of the products before applying discounts or tax.
   */
  public static productsTotal(items: ProductsFormItem[]): number {
    return Decimal.sum(0, ...items.map((item) => this.productTotal(item))).toNumber();
  }

  /**
   * Returns the total discount value that is applied to each one unit of the product.
   * @param item The product item.
   * @returns the total discount value that is applied to each one unit of the product.
   */
  public static productDiscount(item: ProductsFormItem): number {
    const originalValue = this.productOriginalValue(item);

    const discountValue = new Decimal(item.discount ?? 0);
    const discountPercent = new Decimal(item.discountPercent ?? 0);

    const discountPerProduct = discountValue.plus(originalValue.mul(discountPercent.div(100)).toDP(2));

    return discountPerProduct.toNumber();
  }

  /**
   * Returns the total discounts that is applied on all products.
   * @param items The list of items.
   * @returns the total discounts value.
   */
  public static productsDiscount(items: ProductsFormItem[]): number {
    return Decimal.sum(0, ...items.map((item) => Decimal.mul(this.productDiscount(item), item.quantity))).toNumber();
  }

  /**
   * Returns the tax applied on the product.
   * @param item The item.
   * @returns The tax of the product.
   */
  public static productTax(item: ProductsFormItem): number {
    const valueWithTax = new Decimal(item.value);
    const taxPercent = new Decimal(item.tax);
    const originalValue = this.productOriginalValue(item);
    const productDiscount = this.productDiscount(item);

    /**
     * Incase of no discounts make take the advantage of the price including VAT.
     */
    if (productDiscount === 0) {
      return valueWithTax.minus(originalValue).toNumber();
    }

    return originalValue.minus(productDiscount).mul(taxPercent.div(100)).toDP(2).toNumber();
  }

  /**
   * Returns the total tax applied on all products.
   * @param item The item.
   * @returns The total tax of all products.
   */
  public static productsTax(items: ProductsFormItem[]): number {
    return Decimal.sum(0, ...items.map((item) => Decimal.mul(this.productTax(item), item.quantity))).toNumber();
  }

  /**
   * Returns the net value of the invoice after applying discounts and taxes for all products.
   * @param items The list of products in the invoice.
   * @param round Whether the value will be rounded by zero or not, default is `false`.
   * @returns The net value of the invoice.
   */
  public static invoiceNet(items: ProductsFormItem[]): number {
    return Decimal.sum(this.productsTotal(items), -this.productsDiscount(items), this.productsTax(items)).toNumber();
  }

  /**
   * Returns the original value of the product before applying discounts or tax.
   * @param item The item.
   * @returns The original value of the product.
   */
  private static productOriginalValue(item: ProductsFormItem): Decimal {
    const valueWithTax = new Decimal(item.value);
    const taxPercent = new Decimal(item.tax);

    return this.truncate(valueWithTax.div(Decimal.add(1, taxPercent.div(100))));
  }

  /**
   * Safely truncates the given value without rounding.
   * @param value The value to be truncated.
   * @returns The truncated value.
   */
  private static truncate(value: number | Decimal): Decimal {
    /**
     * The needed number of digits.
     */
    const digits = 2;

    return Decimal.div(Math.trunc(Decimal.mul(value, Math.pow(10, digits)).toNumber()), Math.pow(10, digits));
  }
}
