import { Component, Input } from '@angular/core';

import { Observable } from 'rxjs';

import { ProjectResourceLine } from 'projects-management/models';
import { PageInfo } from 'shared';

@Component({
  selector: 'app-project-resource-payrolls',
  templateUrl: './project-resource-payrolls.component.html',
  styles: [],
})
export class ProjectResourcePayrollsComponent {
  /**
   * Gets or sets the information about the current page.
   */
  pageInfo: PageInfo = {
    title: 'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.PAYROLLS_DATA_TITLE',
    icon: 'fa fa-group',
  };

  /**
   * The list of project resource lines.
   */
  @Input() projectResourceLines$: Observable<ProjectResourceLine[]>;
}
