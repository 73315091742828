import { Injectable } from '@angular/core';

import { switchMap, map, catchError, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { Effect, ofType, Actions } from '@ngrx/effects';

import { NotificationService, TranslationService } from 'shared/services';
import { StatusCode } from 'shared/models';
import { PurchasesReportsService } from 'reports/services';
import {
  PurchasesReportsActionType,
  GetTopReturnedPurchaseProductsReport,
  GetTopReturnedPurchaseProductsReportFail,
  GetTopReturnedPurchaseProductsReportReceived,
  GetTopReturnedPurchaseProductsReportSuccess,
  GetPurchaseInvoicesReport,
  GetPurchaseInvoicesReportReceived,
  GetPurchaseInvoicesReportFail,
  GetPurchaseInvoicesReportSuccess,
  GetPurchaseOrdersReport,
  GetPurchaseOrdersReportReceived,
  GetPurchaseOrdersReportFail,
  GetPurchaseOrdersReportSuccess,
  GetPurchaseRequestsReport,
  GetPurchaseRequestsReportReceived,
  GetPurchaseRequestsReportFail,
  GetPurchaseRequestsReportSuccess,
  GetPurchaseReturnsReport,
  GetPurchaseReturnsReportFail,
  GetPurchaseReturnsReportReceived,
  GetPurchaseReturnsReportSuccess,
  GetTopPurchasedProductsReport,
  GetTopPurchasedProductsReportFail,
  GetTopPurchasedProductsReportReceived,
  GetTopPurchasedProductsReportSuccess,
  GetVendorSummaryReport,
  GetVendorSummaryReportFail,
  GetVendorSummaryReportReceived,
  GetVendorSummaryReportSuccess,
  GetVendorsDebtsReportFail,
  GetVendorsDebtsReportReceived,
  GetVendorsDebtsReportSuccess,
  GetWorstPurchasedProductsReport,
  GetWorstPurchasedProductsReportFail,
  GetWorstPurchasedProductsReportReceived,
  GetWorstPurchasedProductsReportSuccess,
  GetWorstReturnedPurchaseProductsReport,
  GetWorstReturnedPurchaseProductsReportFail,
  GetWorstReturnedPurchaseProductsReportReceived,
  GetWorstReturnedPurchaseProductsReportSuccess,
} from 'reports/store/actions';

@Injectable()
export class PurchasesReportsEffects {
  constructor(
    private actions$: Actions,
    private reportsService: PurchasesReportsService,
    private notificationService: NotificationService,
    private translationService: TranslationService
  ) {}

  /* ========================= GET_TOP_RETURNED_PURCHASE_PRODUCTS_REPORT =================================== */
  @Effect()
  mostReturnedPurchaseProducts$ = this.actions$.pipe(
    ofType(PurchasesReportsActionType.GET_TOP_RETURNED_PURCHASE_PRODUCTS_REPORT),
    switchMap(({ payload }: GetTopReturnedPurchaseProductsReport) =>
      this.reportsService
        .getMostReturnedPurchaseProductsReport(payload.locations, payload.fromDate, payload.toDate, payload.count)
        .pipe(
          map((response) => new GetTopReturnedPurchaseProductsReportReceived(response)),
          catchError((error) => of(new GetTopReturnedPurchaseProductsReportFail(error)))
        )
    )
  );

  @Effect({ dispatch: false })
  mostReturnedPurchaseProductsReceived$ = this.actions$.pipe(
    ofType(PurchasesReportsActionType.GET_TOP_RETURNED_PURCHASE_PRODUCTS_REPORT_RECEIVED),
    tap(({ payload: { data } }: GetTopReturnedPurchaseProductsReportReceived) => {
      this.notificationService.info(data);
    })
  );

  @Effect({ dispatch: false })
  mostReturnedPurchaseProductsSuccess$ = this.actions$.pipe(
    ofType(PurchasesReportsActionType.GET_TOP_RETURNED_PURCHASE_PRODUCTS_REPORT_SUCCESS),
    tap((action: GetTopReturnedPurchaseProductsReportSuccess) => {
      this.notificationService.success(
        this.translationService.translate('REPORTS.NOTIFICATION_MESSAGE.REPORT_IS_READY')
      );
    })
  );

  @Effect({ dispatch: false })
  mostReturnedPurchaseProductsFail$ = this.actions$.pipe(
    ofType(PurchasesReportsActionType.GET_TOP_RETURNED_PURCHASE_PRODUCTS_REPORT_FAIL),
    tap((action: GetTopReturnedPurchaseProductsReportFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );

  /* ========================= GET_PURCHASE_INVOICES_REPORT =================================== */
  @Effect()
  purchaseInvoices$ = this.actions$.pipe(
    ofType(PurchasesReportsActionType.GET_PURCHASE_INVOICES_REPORT),
    switchMap(({ payload }: GetPurchaseInvoicesReport) =>
      this.reportsService
        .getPurchaseInvoicesReport(
          payload.vendors,
          payload.locations,
          payload.paymentDurations,
          payload.purchaseInvoiceTypes,
          payload.fromDate,
          payload.toDate,
          payload.displayProductsInReport
        )
        .pipe(
          map((response) => new GetPurchaseInvoicesReportReceived(response)),
          catchError((error) => of(new GetPurchaseInvoicesReportFail(error)))
        )
    )
  );

  @Effect({ dispatch: false })
  purchaseInvoicesReceived$ = this.actions$.pipe(
    ofType(PurchasesReportsActionType.GET_PURCHASE_INVOICES_REPORT_RECEIVED),
    tap(({ payload: { data } }: GetPurchaseInvoicesReportReceived) => {
      this.notificationService.info(data);
    })
  );

  @Effect({ dispatch: false })
  purchaseInvoicesSuccess$ = this.actions$.pipe(
    ofType(PurchasesReportsActionType.GET_PURCHASE_INVOICES_REPORT_SUCCESS),
    tap((action: GetPurchaseInvoicesReportSuccess) => {
      this.notificationService.success(
        this.translationService.translate('REPORTS.NOTIFICATION_MESSAGE.REPORT_IS_READY')
      );
    })
  );

  @Effect({ dispatch: false })
  purchaseInvoicesFail$ = this.actions$.pipe(
    ofType(PurchasesReportsActionType.GET_PURCHASE_INVOICES_REPORT_FAIL),
    tap((action: GetPurchaseInvoicesReportFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );

  /* ========================= GET_PURCHASE_ORDERS_REPORT =================================== */
  @Effect()
  purchaseOrders$ = this.actions$.pipe(
    ofType(PurchasesReportsActionType.GET_PURCHASE_ORDERS_REPORT),
    switchMap(({ payload }: GetPurchaseOrdersReport) =>
      this.reportsService
        .getPurchaseOrdersReport(
          payload.vendors,
          payload.purchaseOrderTypes,
          payload.fromDate,
          payload.toDate,
          payload.displayProductsInReport
        )
        .pipe(
          map((response) => new GetPurchaseOrdersReportReceived(response)),
          catchError((error) => of(new GetPurchaseOrdersReportFail(error)))
        )
    )
  );

  @Effect({ dispatch: false })
  purchaseOrdersReceived$ = this.actions$.pipe(
    ofType(PurchasesReportsActionType.GET_PURCHASE_ORDERS_REPORT_RECEIVED),
    tap(({ payload: { data } }: GetPurchaseOrdersReportReceived) => {
      this.notificationService.info(data);
    })
  );

  @Effect({ dispatch: false })
  purchaseOrdersSuccess$ = this.actions$.pipe(
    ofType(PurchasesReportsActionType.GET_PURCHASE_ORDERS_REPORT_SUCCESS),
    tap((action: GetPurchaseOrdersReportSuccess) => {
      this.notificationService.success(
        this.translationService.translate('REPORTS.NOTIFICATION_MESSAGE.REPORT_IS_READY')
      );
    })
  );

  @Effect({ dispatch: false })
  purchaseOrdersFail$ = this.actions$.pipe(
    ofType(PurchasesReportsActionType.GET_PURCHASE_ORDERS_REPORT_FAIL),
    tap((action: GetPurchaseOrdersReportFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );

  /* ========================= GET_PURCHASE_REQUESTS_REPORT =================================== */
  @Effect()
  purchaseRequests$ = this.actions$.pipe(
    ofType(PurchasesReportsActionType.GET_PURCHASE_REQUESTS_REPORT),
    switchMap(({ payload }: GetPurchaseRequestsReport) =>
      this.reportsService
        .getPurchaseRequestsReport(
          payload.purchaseRequestTypes,
          payload.fromDate,
          payload.toDate,
          payload.displayProductsInReport
        )
        .pipe(
          map((response) => new GetPurchaseRequestsReportReceived(response)),
          catchError((error) => of(new GetPurchaseRequestsReportFail(error)))
        )
    )
  );

  @Effect({ dispatch: false })
  purchaseRequestsReceived$ = this.actions$.pipe(
    ofType(PurchasesReportsActionType.GET_PURCHASE_REQUESTS_REPORT_RECEIVED),
    tap(({ payload: { data } }: GetPurchaseRequestsReportReceived) => {
      this.notificationService.info(data);
    })
  );

  @Effect({ dispatch: false })
  purchaseRequestsSuccess$ = this.actions$.pipe(
    ofType(PurchasesReportsActionType.GET_PURCHASE_REQUESTS_REPORT_SUCCESS),
    tap((action: GetPurchaseRequestsReportSuccess) => {
      this.notificationService.success(
        this.translationService.translate('REPORTS.NOTIFICATION_MESSAGE.REPORT_IS_READY')
      );
    })
  );

  @Effect({ dispatch: false })
  purchaseRequestsFail$ = this.actions$.pipe(
    ofType(PurchasesReportsActionType.GET_PURCHASE_REQUESTS_REPORT_FAIL),
    tap((action: GetPurchaseRequestsReportFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );

  /* ========================= GET_PURCHASE_RETURNS_REPORT =================================== */
  @Effect()
  purchaseReturns$ = this.actions$.pipe(
    ofType(PurchasesReportsActionType.GET_PURCHASE_RETURNS_REPORT),
    switchMap(({ payload }: GetPurchaseReturnsReport) =>
      this.reportsService
        .getPurchaseReturnsReport(
          payload.locations,
          payload.vendors,
          payload.fromDate,
          payload.toDate,
          payload.displayProductsInReport
        )
        .pipe(
          map((response) => new GetPurchaseReturnsReportReceived(response)),
          catchError((error) => of(new GetPurchaseReturnsReportFail(error)))
        )
    )
  );

  @Effect({ dispatch: false })
  purchaseReturnsReceived$ = this.actions$.pipe(
    ofType(PurchasesReportsActionType.GET_PURCHASE_RETURNS_REPORT_RECEIVED),
    tap(({ payload: { data } }: GetPurchaseReturnsReportReceived) => {
      this.notificationService.info(data);
    })
  );

  @Effect({ dispatch: false })
  purchaseReturnsSuccess$ = this.actions$.pipe(
    ofType(PurchasesReportsActionType.GET_PURCHASE_RETURNS_REPORT_SUCCESS),
    tap((action: GetPurchaseReturnsReportSuccess) => {
      this.notificationService.success(
        this.translationService.translate('REPORTS.NOTIFICATION_MESSAGE.REPORT_IS_READY')
      );
    })
  );

  @Effect({ dispatch: false })
  purchaseReturnsFail$ = this.actions$.pipe(
    ofType(PurchasesReportsActionType.GET_PURCHASE_RETURNS_REPORT_FAIL),
    tap((action: GetPurchaseReturnsReportFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );

  /* ========================= GET_TOP_PURCHASE_PRODUCTS_REPORT =================================== */
  @Effect()
  topPurchaseProducts$ = this.actions$.pipe(
    ofType(PurchasesReportsActionType.GET_TOP_PURCHASED_PRODUCTS_REPORT),
    switchMap(({ payload }: GetTopPurchasedProductsReport) =>
      this.reportsService
        .getTopPurchasedProductsReport(payload.locations, payload.fromDate, payload.toDate, payload.count)
        .pipe(
          map((response) => new GetTopPurchasedProductsReportReceived(response)),
          catchError((error) => of(new GetTopPurchasedProductsReportFail(error)))
        )
    )
  );

  @Effect({ dispatch: false })
  topPurchaseProductsReceived$ = this.actions$.pipe(
    ofType(PurchasesReportsActionType.GET_TOP_PURCHASED_PRODUCTS_REPORT_RECEIVED),
    tap(({ payload: { data } }: GetTopPurchasedProductsReportReceived) => {
      this.notificationService.info(data);
    })
  );

  @Effect({ dispatch: false })
  topPurchaseProductsSuccess$ = this.actions$.pipe(
    ofType(PurchasesReportsActionType.GET_TOP_PURCHASED_PRODUCTS_REPORT_SUCCESS),
    tap((action: GetTopPurchasedProductsReportSuccess) => {
      this.notificationService.success(
        this.translationService.translate('REPORTS.NOTIFICATION_MESSAGE.REPORT_IS_READY')
      );
    })
  );

  @Effect({ dispatch: false })
  topPurchaseProductsFail$ = this.actions$.pipe(
    ofType(PurchasesReportsActionType.GET_TOP_PURCHASED_PRODUCTS_REPORT_FAIL),
    tap((action: GetTopPurchasedProductsReportFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );

  /* ========================= GET_VENDOR_SUMMARY_REPORT =================================== */
  @Effect()
  vendorSummary$ = this.actions$.pipe(
    ofType(PurchasesReportsActionType.GET_VENDOR_SUMMARY_REPORT),
    switchMap(({ payload }: GetVendorSummaryReport) =>
      this.reportsService.getVendorSummaryReport(payload.vendorId, payload.fromDate, payload.toDate).pipe(
        map((response) => new GetVendorSummaryReportReceived(response)),
        catchError((error) => of(new GetVendorSummaryReportFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  vendorSummaryReceived$ = this.actions$.pipe(
    ofType(PurchasesReportsActionType.GET_VENDOR_SUMMARY_REPORT_RECEIVED),
    tap(({ payload: { data } }: GetVendorSummaryReportReceived) => {
      this.notificationService.info(data);
    })
  );

  @Effect({ dispatch: false })
  vendorSummarySuccess$ = this.actions$.pipe(
    ofType(PurchasesReportsActionType.GET_VENDOR_SUMMARY_REPORT_SUCCESS),
    tap((action: GetVendorSummaryReportSuccess) => {
      this.notificationService.success(
        this.translationService.translate('REPORTS.NOTIFICATION_MESSAGE.REPORT_IS_READY')
      );
    })
  );

  @Effect({ dispatch: false })
  vendorSummaryFail$ = this.actions$.pipe(
    ofType(PurchasesReportsActionType.GET_VENDOR_SUMMARY_REPORT_FAIL),
    tap((action: GetVendorSummaryReportFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );

  /* ========================= GET_VENDORS_DEBTS_REPORT =================================== */
  @Effect()
  vendorsDebits$ = this.actions$.pipe(
    ofType(PurchasesReportsActionType.GET_VENDORS_DEBTS_REPORT),
    switchMap(() =>
      this.reportsService.getVendorsDebtsReport().pipe(
        map((response) => new GetVendorsDebtsReportReceived(response)),
        catchError((error) => of(new GetVendorsDebtsReportFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  vendorsDebitsReceived$ = this.actions$.pipe(
    ofType(PurchasesReportsActionType.GET_VENDORS_DEBTS_REPORT_RECEIVED),
    tap(({ payload: { data } }: GetVendorsDebtsReportReceived) => {
      this.notificationService.info(data);
    })
  );

  @Effect({ dispatch: false })
  vendorsDebitsSuccess$ = this.actions$.pipe(
    ofType(PurchasesReportsActionType.GET_VENDORS_DEBTS_REPORT_SUCCESS),
    tap((action: GetVendorsDebtsReportSuccess) => {
      this.notificationService.success(
        this.translationService.translate('REPORTS.NOTIFICATION_MESSAGE.REPORT_IS_READY')
      );
    })
  );

  @Effect({ dispatch: false })
  vendorsDebitsFail$ = this.actions$.pipe(
    ofType(PurchasesReportsActionType.GET_VENDORS_DEBTS_REPORT_FAIL),
    tap((action: GetVendorsDebtsReportFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );

  /* ========================= GET_WORST_PURCHASED_PRODUCTS_REPORT =================================== */
  @Effect()
  worstPurchaseProducts$ = this.actions$.pipe(
    ofType(PurchasesReportsActionType.GET_WORST_PURCHASED_PRODUCTS_REPORT),
    switchMap(({ payload }: GetWorstPurchasedProductsReport) =>
      this.reportsService
        .getWorstPurchasedProductsReport(payload.locations, payload.fromDate, payload.toDate, payload.count)
        .pipe(
          map((response) => new GetWorstPurchasedProductsReportReceived(response)),
          catchError((error) => of(new GetWorstPurchasedProductsReportFail(error)))
        )
    )
  );

  @Effect({ dispatch: false })
  worstPurchaseProductsReceived$ = this.actions$.pipe(
    ofType(PurchasesReportsActionType.GET_WORST_PURCHASED_PRODUCTS_REPORT_RECEIVED),
    tap(({ payload: { data } }: GetWorstPurchasedProductsReportReceived) => {
      this.notificationService.info(data);
    })
  );

  @Effect({ dispatch: false })
  worstPurchaseProductsSuccess$ = this.actions$.pipe(
    ofType(PurchasesReportsActionType.GET_WORST_PURCHASED_PRODUCTS_REPORT_SUCCESS),
    tap((action: GetWorstPurchasedProductsReportSuccess) => {
      this.notificationService.success(
        this.translationService.translate('REPORTS.NOTIFICATION_MESSAGE.REPORT_IS_READY')
      );
    })
  );

  @Effect({ dispatch: false })
  worstPurchaseProductsFail$ = this.actions$.pipe(
    ofType(PurchasesReportsActionType.GET_WORST_PURCHASED_PRODUCTS_REPORT_FAIL),
    tap((action: GetWorstPurchasedProductsReportFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );

  /* ========================= GET_WORST_RETURNED_PURCHASE_PRODUCTS_REPORT =================================== */
  @Effect()
  worstReturnedPurchaseProducts$ = this.actions$.pipe(
    ofType(PurchasesReportsActionType.GET_WORST_RETURNED_PURCHASE_PRODUCTS_REPORT),
    switchMap(({ payload }: GetWorstReturnedPurchaseProductsReport) =>
      this.reportsService
        .getWorstReturnedPurchaseProductsReport(payload.locations, payload.fromDate, payload.toDate, payload.count)
        .pipe(
          map((response) => new GetWorstReturnedPurchaseProductsReportReceived(response)),
          catchError((error) => of(new GetWorstReturnedPurchaseProductsReportFail(error)))
        )
    )
  );

  @Effect({ dispatch: false })
  worstReturnedPurchaseProductsReceived$ = this.actions$.pipe(
    ofType(PurchasesReportsActionType.GET_WORST_RETURNED_PURCHASE_PRODUCTS_REPORT_RECEIVED),
    tap(({ payload: { data } }: GetWorstReturnedPurchaseProductsReportReceived) => {
      this.notificationService.info(data);
    })
  );

  @Effect({ dispatch: false })
  worstReturnedPurchaseProductsSuccess$ = this.actions$.pipe(
    ofType(PurchasesReportsActionType.GET_WORST_RETURNED_PURCHASE_PRODUCTS_REPORT_SUCCESS),
    tap((action: GetWorstReturnedPurchaseProductsReportSuccess) => {
      this.notificationService.success(
        this.translationService.translate('REPORTS.NOTIFICATION_MESSAGE.REPORT_IS_READY')
      );
    })
  );

  @Effect({ dispatch: false })
  worstReturnedPurchaseProductsFail$ = this.actions$.pipe(
    ofType(PurchasesReportsActionType.GET_WORST_RETURNED_PURCHASE_PRODUCTS_REPORT_FAIL),
    tap((action: GetWorstReturnedPurchaseProductsReportFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );
}
