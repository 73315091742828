import { Injectable } from '@angular/core';

import { of } from 'rxjs';
import { switchMap, map, catchError } from 'rxjs/operators';
import { Effect, ofType, Actions } from '@ngrx/effects';

import { LookupsService } from 'lookups/services';
import {
  ProjectResourceTypeSourceActionType,
  GetAllProjectResourceTypeSourcesFail,
  GetAllProjectResourceTypeSourcesSuccess,
} from 'lookups/store/actions';

@Injectable()
export class ProjectResourceTypeSourceEffects {
  constructor(private actions$: Actions, private lookupsService: LookupsService) {}

  /* ========================= GET_ALL_PROJECT_RESOURCE_TYPE_SOURCES =================================== */
  @Effect()
  getAllProjectResourceTypeSource$ = this.actions$.pipe(
    ofType(ProjectResourceTypeSourceActionType.GET_ALL_PROJECT_RESOURCE_TYPE_SOURCES),
    switchMap(() =>
      this.lookupsService.getAllProjectResourceTypeSources().pipe(
        map((response) => new GetAllProjectResourceTypeSourcesSuccess(response)),
        catchError((error) => of(new GetAllProjectResourceTypeSourcesFail(error)))
      )
    )
  );
}
