import { createSelector } from '@ngrx/store';

import { selectLookupsState } from 'lookups/store/selectors/feature.selectors';
import { LookupsState, PrintPaperSizeState } from 'lookups/store/states';

/**
 * Gets the printPaperSize state.
 */
const selectPrintPaperSizes = createSelector(selectLookupsState, (state: LookupsState) => state.printPaperSizes);

/**
 * Gets the list of loaded printPaperSizes.
 */
export const getPrintPaperSizes = createSelector(selectPrintPaperSizes, (state: PrintPaperSizeState) => state.data);

/**
 * Gets the error that occurred in the state.
 */
export const getPrintPaperSizesError = createSelector(
  selectPrintPaperSizes,
  (state: PrintPaperSizeState) => state.error
);

/**
 * Determines if there is a running load process.
 */
export const getPrintPaperSizesIsLoading = createSelector(
  selectPrintPaperSizes,
  (state: PrintPaperSizeState) => state.isLoading
);

/**
 * Determines if the last load process has been ended successfully.
 */
export const getPrintPaperSizesIsLoaded = createSelector(
  selectPrintPaperSizes,
  (state: PrintPaperSizeState) => state.isLoaded
);
