import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { v4 as uuid } from 'uuid';
import { Observable } from 'rxjs';

import { AppHttpResponse } from 'shared';
import {
  Customer,
  CustomerLog,
  UpdateCustomerInput,
  CreateCustomerInput,
  CreateCustomerPaymentsInput,
  CreateCustomerAdvancePaymentInput,
  CreateCustomerPaymentByAdvancePaymentInput,
  CreateCustomerRefundInput,
} from 'sales/models';

/**
 * The customers services includes functionality to create, search, searchCustomerLog, findById, update, delete, block, activate ,
 * create payment and cancel log for a customer.
 */
@Injectable()
export class CustomersService {
  /**
   * The relative route for the customers.
   *
   * No leading or trailing slashes required.
   */
  private customersApi = 'sales/customers';

  constructor(private http: HttpClient) {}

  /**
   * Creates a new customer from the provided data.
   * @param data The new customer data.
   */
  public create(data: CreateCustomerInput): Observable<AppHttpResponse<Customer>> {
    const formData: any = new FormData();
    formData.append('name', data.name);
    formData.append('nameEn', data.nameEn);
    formData.append('cityId', data.cityId);
    formData.append('locationId', data.locationId);
    formData.append('customerCategoryId', data.customerCategoryId);
    formData.append('vatNumber', data.vatNumber);
    formData.append('commercialRegistrationNo', data.commercialRegistrationNo);
    formData.append('buildingNo', data.buildingNo);
    formData.append('streetName', data.streetName);
    formData.append('district', data.district);
    formData.append('country', data.country);
    formData.append('postalCode', data.postalCode);
    formData.append('additionalNo', data.additionalNo);
    formData.append('otherBuyerId', data.otherBuyerId);
    formData.append('moreInfo', data.moreInfo);
    formData.append('representorName', data.representorName);
    formData.append('representorMobile', data.representorMobile);
    formData.append('representorPhone', data.representorPhone);
    formData.append('representorEmail', data.representorEmail);

    /**
     * Append lines to the form data.
     */
    for (let index = 0; index < data.customerAssets?.length; index++) {
      /**
       * Gets the uuid for this line.
       * It's a workaround to upload multiple files per line.
       */
      const id = uuid();

      formData.append(`customerAssets[${index}][uuid]`, id);
      formData.append(`customerAssets[${index}][description]`, data.customerAssets[index].description);
      formData.append(`customerAssets[${index}][type]`, data.customerAssets[index].type);
      formData.append(`customerAssets[${index}][serialNumber]`, data.customerAssets[index].serialNumber);

      /**
       * Append attachments to the form data.
       */
      data.customerAssets[index].attachments.forEach(
        (attachment) => attachment && formData.append(id, attachment, attachment.name)
      );
    }

    /**
     * Append lines to the form data.
     */
    for (let index = 0; index < data.customerEngagements?.length; index++) {
      /**
       * Gets the uuid for this line.
       * It's a workaround to upload multiple files per line.
       */

      formData.append(
        `customerEngagements[${index}][customerEngagementTypeId]`,
        data.customerEngagements[index].customerEngagementTypeId
      );
      formData.append(`customerEngagements[${index}][value]`, data.customerEngagements[index].value);
      formData.append(`customerEngagements[${index}][notes]`, data.customerEngagements[index].notes);
    }

    return this.http.post<AppHttpResponse<Customer>>(`${this.customersApi}`, formData);
  }

  /**
   * Creates a new payment from the provided data.
   * @param data The new customer payment data.
   */
  public createPayment(data: CreateCustomerPaymentsInput): Observable<AppHttpResponse<CustomerLog>> {
    const formData: any = new FormData();
    formData.append('transactionDate', data.transactionDate?.toISOString());
    formData.append('customerLogTypeId', data.customerLogTypeId);
    formData.append('bankAccountId', data.bankAccountId);
    formData.append('extraDetails', data.extraDetails);
    formData.append('notes', data.notes);

    /**
     * Append payments to the form data.
     */
    for (let index = 0; index < data.payments.length; index++) {
      formData.append(`payments[${index}][saleInvoiceId]`, data.payments[index].saleInvoiceId);
      formData.append(`payments[${index}][value]`, data.payments[index].value);
    }

    /**
     * Append journal lines to the form data.
     */
    for (let index = 0; index < data.lines.length; index++) {
      formData.append(`lines[${index}][accountId]`, data.lines[index].accountId);
      formData.append(`lines[${index}][credit]`, data.lines[index].credit);
      formData.append(`lines[${index}][debit]`, data.lines[index].debit);
      formData.append(`lines[${index}][costCenterId]`, data.lines[index].costCenterId);
      formData.append(`lines[${index}][notes]`, data.lines[index].notes);
    }

    /**
     * Append attachments to the form data.
     */
    data.attachments.forEach((attachment) => formData.append(`attachments[]`, attachment, attachment.name));

    return this.http.post<AppHttpResponse<CustomerLog>>(`${this.customersApi}/logs/payments`, formData);
  }

  /**
   * Creates a new payment by advance payment from the provided data.
   * @param data The new customer payment by advance payment data.
   */
  public createPaymentByAdvancePayment(
    data: CreateCustomerPaymentByAdvancePaymentInput
  ): Observable<AppHttpResponse<CustomerLog>> {
    const formData: any = new FormData();
    formData.append('transactionDate', data.transactionDate?.toISOString());
    formData.append('customerId', data.customerId);
    formData.append('customerLogId', data.customerLogId);
    formData.append('notes', data.notes);

    /**
     * Append payments to the form data.
     */
    for (let index = 0; index < data.payments.length; index++) {
      formData.append(`payments[${index}][saleInvoiceId]`, data.payments[index].saleInvoiceId);
      formData.append(`payments[${index}][value]`, data.payments[index].value);
    }

    /**
     * Append attachments to the form data.
     */
    data.attachments.forEach((attachment) => formData.append(`attachments[]`, attachment, attachment.name));

    return this.http.post<AppHttpResponse<CustomerLog>>(
      `${this.customersApi}/logs/payment-by-advance-payment`,
      formData
    );
  }

  /**
   * Creates a new advance payment from the provided data.
   * @param data The new customer advance payment data.
   */
  public createAdvancePayment(data: CreateCustomerAdvancePaymentInput): Observable<AppHttpResponse<CustomerLog>> {
    const formData: any = new FormData();
    formData.append('transactionDate', data.transactionDate?.toISOString());
    formData.append('customerId', data.customerId);
    formData.append('customerLogTypeId', data.customerLogTypeId);
    formData.append('bankAccountId', data.bankAccountId);
    formData.append('extraDetails', data.extraDetails);
    formData.append('value', data.value);
    formData.append('notes', data.notes);

    /**
     * Append journal lines to the form data.
     */
    for (let index = 0; index < data.lines.length; index++) {
      formData.append(`lines[${index}][accountId]`, data.lines[index].accountId);
      formData.append(`lines[${index}][credit]`, data.lines[index].credit);
      formData.append(`lines[${index}][debit]`, data.lines[index].debit);
      formData.append(`lines[${index}][costCenterId]`, data.lines[index].costCenterId);
      formData.append(`lines[${index}][notes]`, data.lines[index].notes);
    }

    /**
     * Append attachments to the form data.
     */
    data.attachments.forEach((attachment) => formData.append(`attachments[]`, attachment, attachment.name));

    return this.http.post<AppHttpResponse<CustomerLog>>(`${this.customersApi}/logs/advance-payment`, formData);
  }

  /**
   * Creates a new refund from the provided data.
   * @param data The new customer refund data.
   */
  public createRefund(data: CreateCustomerRefundInput): Observable<AppHttpResponse<CustomerLog>> {
    return this.http.post<AppHttpResponse<CustomerLog>>(`${this.customersApi}/logs/refund`, data);
  }

  /**
   * Searches in the customers by name.
   * @param name The name of the customer.
   * @param page The current pagination page number.
   * @param pageSize The maximum number of customers allowed per one pagination page.
   */
  public search(name: string, page: number, pageSize: number): Observable<AppHttpResponse<Customer[]>> {
    const params = new HttpParams().set('name', name).set('page', page.toString()).set('pageSize', pageSize.toString());
    return this.http.get<AppHttpResponse<Customer[]>>(`${this.customersApi}`, { params });
  }

  /**
   * Searches in the customer log by customerId.
   * @param customerId The id of the customer of the customer log.
   * @param fromDate The fromDate of the customer log.
   * @param toDate The toDate of the customer log.
   * @param page The current pagination page number.
   * @param pageSize The maximum number of customer log allowed per one pagination page.
   */
  public searchCustomerLogs(
    customerId: number,
    fromDate: Date,
    toDate: Date,
    page: number,
    pageSize: number
  ): Observable<AppHttpResponse<CustomerLog[]>> {
    const params = new HttpParams()
      .set('customerId', customerId.toString())
      .set('fromDate', fromDate?.toISOString())
      .set('toDate', toDate?.toISOString())
      .set('page', page.toString())
      .set('pageSize', pageSize.toString());
    return this.http.get<AppHttpResponse<CustomerLog[]>>(`${this.customersApi}/logs`, { params });
  }

  /**
   * Searches the customers payments.
   * @param customers The customers of the payments.
   * @param customerLogTypes The customer log types of the payments.
   * @param fromDate The fromDate of the customer log.
   * @param toDate The toDate of the customer log.
   * @param page The current pagination page number.
   * @param pageSize The maximum number of customer log allowed per one pagination page.
   */
  public searchCustomersPayments(
    customers: number[],
    customerLogTypes: number[],
    fromDate: Date,
    toDate: Date,
    page: number,
    pageSize: number
  ): Observable<AppHttpResponse<CustomerLog[]>> {
    const params = new HttpParams()
      .set('customers', customers.join(','))
      .set('customerLogTypes', customerLogTypes.join(','))
      .set('fromDate', fromDate?.toISOString())
      .set('toDate', toDate?.toISOString())
      .set('page', page.toString())
      .set('pageSize', pageSize.toString());
    return this.http.get<AppHttpResponse<CustomerLog[]>>(`${this.customersApi}/logs/payments`, { params });
  }

  /**
   * Searches the customers advance payments.
   * @param customerId The customerId of the payments.
   * @param fromDate The fromDate of the customer log.
   * @param toDate The toDate of the customer log.
   * @param page The current pagination page number.
   * @param pageSize The maximum number of customer log allowed per one pagination page.
   */
  public searchCustomerAdvancePayments(
    customerId: number,
    fromDate: Date,
    toDate: Date,
    page: number,
    pageSize: number
  ): Observable<AppHttpResponse<CustomerLog[]>> {
    const params = new HttpParams()
      .set('customerId', customerId.toString())
      .set('fromDate', fromDate?.toISOString())
      .set('toDate', toDate?.toISOString())
      .set('page', page.toString())
      .set('pageSize', pageSize.toString());
    return this.http.get<AppHttpResponse<CustomerLog[]>>(`${this.customersApi}/logs/advance-payments`, { params });
  }

  /**
   * Finds the customer with the given id.
   * @param id The id of the customer.
   */
  public findById(id: number): Observable<AppHttpResponse<Customer>> {
    return this.http.get<AppHttpResponse<Customer>>(`${this.customersApi}/${id}`);
  }

  /**
   * Updates an existing customer data using the provided data.
   * @param data The updated customer data.
   */
  public update(data: UpdateCustomerInput): Observable<AppHttpResponse<Customer>> {
    return this.http.put<AppHttpResponse<Customer>>(`${this.customersApi}`, data);
  }

  /**
   * Activates the customer by given id.
   * @param id The id of the customer.
   */
  public activate(id: number): Observable<AppHttpResponse<Customer>> {
    return this.http.put<AppHttpResponse<Customer>>(`${this.customersApi}/activate/${id}`, null);
  }

  /**
   * Blocks the customer by given id.
   * @param id The id of the customer.
   */
  public block(id: number): Observable<AppHttpResponse<Customer>> {
    return this.http.put<AppHttpResponse<Customer>>(`${this.customersApi}/block/${id}`, null);
  }

  /**
   * Deletes the customer by given id.
   * @param id The id of the customer.
   */
  public delete(id: number): Observable<AppHttpResponse<Customer>> {
    return this.http.delete<AppHttpResponse<Customer>>(`${this.customersApi}/${id}`);
  }

  /**
   * Cancels the customer log by given id.
   * @param id The id of the customer log .
   */
  public cancel(
    id: number
  ): Observable<AppHttpResponse<{ log: CustomerLog; cancelLog: CustomerLog; customer: Customer }>> {
    return this.http.put<AppHttpResponse<{ log: CustomerLog; cancelLog: CustomerLog; customer: Customer }>>(
      `${this.customersApi}/logs/cancel/${id}`,
      null
    );
  }
}
