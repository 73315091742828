import { Action } from '@ngrx/store';

import { Country } from 'lookups/models';
import { AppHttpResponse } from 'shared';

/**
 * The Lookups-Module store country action types.
 */
export enum CountryActionType {
  GET_ALL_COUNTRIES = '[Lookups] [Country] Get All Countries',
  GET_ALL_COUNTRIES_FAIL = '[Lookups] [Country] Get All Countries Fail',
  GET_ALL_COUNTRIES_SUCCESS = '[Lookups] [Country] Get All Countries Success',
}

/**
 * Represents a store country action.
 */
export class GetAllCountries implements Action {
  /**
   * The type of the action.
   */
  readonly type = CountryActionType.GET_ALL_COUNTRIES;

  /**
   * Fires a new country action.
   */
  constructor() {}
}

/**
 * Represents a store country fail action.
 */
export class GetAllCountriesFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = CountryActionType.GET_ALL_COUNTRIES_FAIL;

  /**
   * Fires a new country fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store country success action.
 */
export class GetAllCountriesSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = CountryActionType.GET_ALL_COUNTRIES_SUCCESS;

  /**
   * Fires a country success action.
   * @param payload An object contains the list of countries.
   */
  constructor(public payload: AppHttpResponse<Country[]>) {}
}

/**
 * Lookups-module country action types.
 */
export type CountryActions = GetAllCountries | GetAllCountriesFail | GetAllCountriesSuccess;
