import { Injectable } from '@angular/core';

import { of } from 'rxjs';
import { switchMap, map, catchError } from 'rxjs/operators';
import { Effect, ofType, Actions } from '@ngrx/effects';

import { LookupsService } from 'lookups/services';
import {
  DepartmentCategoryActionType,
  GetAllDepartmentCategoryFail,
  GetAllDepartmentCategorySuccess,
} from 'lookups/store/actions';

@Injectable()
export class DepartmentCategoryEffects {
  constructor(private actions$: Actions, private lookupsService: LookupsService) {}

  /* ========================= GET_ALL_DEPARTMENT_TYPE =================================== */
  @Effect()
  getAllDepartmentCategories$ = this.actions$.pipe(
    ofType(DepartmentCategoryActionType.GET_ALL_DEPARTMENT_CATEGORIES),
    switchMap(() =>
      this.lookupsService.getAllDepartmentCategories().pipe(
        map((response) => new GetAllDepartmentCategorySuccess(response)),
        catchError((error) => of(new GetAllDepartmentCategoryFail(error)))
      )
    )
  );
}
