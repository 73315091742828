import { Pipe, PipeTransform } from '@angular/core';

import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';

import * as fromAuthStore from 'auth/store';
import { Claim } from 'security/models';

/**
 * Authorize pipe that makes sure that the user has the permission to show
 * a specified data, link or perform an action according to some claims.
 */
@Pipe({
  name: 'authorize',
})
export class AuthorizePipe implements PipeTransform {
  /**
   * @param authStore$ The auth-Store module.
   */
  constructor(private authStore$: Store<fromAuthStore.AuthState>) {}

  /**
   * Check if the user has the permission to do/show some data or actions according to some claims.
   * @param claims The claims that user must has one of them.
   */
  transform(claims: string[]): Observable<boolean> {
    /* Check if there is no claims passed. */
    if (!claims || !claims.length) {
      return of(true);
    }

    return this.authStore$.pipe(
      select(fromAuthStore.getSessionUserClaims),
      map((userClaims: Claim[]) =>
        claims.some((claimKey) => userClaims.map((userClaim) => userClaim.key).includes(claimKey))
      )
    );
  }
}
