import { Component, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { APP_CONSTANTS } from 'shared/models';
import { Account, CostCenter } from 'finances/models';

@Component({
  selector: '[app-journal-line-form-item]',
  templateUrl: './journal-line-form-item.component.html',
  styles: [],
})
export class JournalLineFormItemComponent {
  /**
   * Sets the journal line index in the lines-form.
   */
  @Input() index: number;

  /**
   * Indicates whether if the general cost center display or not.
   * @default `false`.
   */
  @Input() generalCostCenterData = null;

  /**
   * Indicates whether if the general cost center display or not.
   * @default `false`.
   */
  @Input() isGeneralCostCenter = false;

  /**
   * Sets the journal line form-group.
   */
  @Input() journalLineGroup: FormGroup;

  /**
   * Indicates whether if we can remove line or not.
   * @default `true`.
   */
  @Input() allowRemoveLine = true;

  /**
   * Indicates whether the sale invoice number column should be visible or not.
   * @default `false`.
   */
  @Input() showSaleInvoiceNumberColumn = false;

  /**
   * Indicates whether the purchase invoice number column should be visible or not.
   * @default `false`.
   */
  @Input() showPurchaseInvoiceNumberColumn = false;

  /**
   * Sets the default cost-center in the organization.
   */
  @Input() defaultCostCenter: CostCenter;

  /**
   * Emits a value indicates that a drop-down field in the form is opened and therefore the table height should be expanded.
   */
  @Output() dropDownIsOpened = new EventEmitter<boolean>(false);

  /**
   * Emits a value when the user wants to remove current journal from the list.
   */
  @Output() removeChange = new EventEmitter<any>();

  /**
   * The decimal mask.
   */
  readonly DECIMAL_MASK = APP_CONSTANTS.numeric.decimal.mask;

  /**
   * The list of accounts.
   */
  accounts: Account[] = [];

  /**
   * The list of selected cost centers.
   */
  costCenters: CostCenter[] = [];

  /**
   * Shows or hide the accounts list.
   */
  accountsListVisibility = false;

  /**
   * Shows or hide the cost centers list.
   */
  costCentersListVisibility = false;

  /**
   * Returns the list of accounts that should be displayed in the accounts select list.
   */
  get accountsList(): Account[] {
    return this.accounts?.length
      ? this.accounts
      : this.journalLineGroup?.value?.account
      ? [this.journalLineGroup.value.account]
      : [];
  }

  /**
   * Returns the list of cost centers that should be displayed in the cost centers select list.
   */
  get costCentersList(): CostCenter[] {
    const list: CostCenter[] = [];

    if (this.costCenters?.length) {
      list.push(...this.costCenters);
    }
    if (this.journalLineGroup?.value?.costCenter) {
      list.push(this.journalLineGroup?.value?.costCenter);
    }
    if (this.defaultCostCenter) {
      list.push(this.defaultCostCenter);
    }

    return list;
  }

  /**
   * Remove the journal lines from the list.
   */
  remove() {
    this.removeChange.emit(null);
  }

  /**
   * Selects the newly selected accounts from the accounts search list.
   * @param accounts The list of newly selected accounts to select the only one in the list.
   */
  selectAccount([account]: Account[]) {
    if (account) {
      this.accounts = [account];
      this.journalLineGroup.patchValue({ accountId: account.id });
    }
  }

  /**
   * Adds the newly selected cost centers the cost centers search list.
   * @param costCenters The list of newly selected cost centers to be added.
   */
  selectCostCenters([costCenter]: CostCenter[]) {
    if (costCenter) {
      this.costCenters = [costCenter];
      this.journalLineGroup.patchValue({ costCenterId: costCenter.id });
    }
  }
}
