import { Injectable } from '@angular/core';

import { of } from 'rxjs';
import { switchMap, map, catchError, tap } from 'rxjs/operators';
import { Effect, ofType, Actions } from '@ngrx/effects';

import { NotificationService, StatusCode, PAGINATION, TranslationService } from 'shared';
import { PosDevicesService } from 'sales/services';
import {
  CreatePosDevice,
  CreatePosDeviceFail,
  CreatePosDeviceSuccess,
  DeletePosDevice,
  DeletePosDeviceFail,
  DeletePosDeviceSuccess,
  FindPosDevice,
  FindPosDeviceFail,
  FindPosDeviceSuccess,
  SearchPosDevices,
  SearchPosDevicesFail,
  SearchPosDevicesSuccess,
  PosDeviceActionType,
  UpdatePosDevice,
  UpdatePosDeviceFail,
  UpdatePosDeviceSuccess,
} from 'sales/store/actions';

@Injectable()
export class PosDevicesEffects {
  constructor(
    private actions$: Actions,
    private posDevicesService: PosDevicesService,
    private notificationService: NotificationService,
    private translationService: TranslationService
  ) {}

  /* ========================= SEARCH_POS_DEVICES =================================== */
  @Effect()
  search$ = this.actions$.pipe(
    ofType(PosDeviceActionType.SEARCH_POS_DEVICES),
    switchMap(({ payload }: SearchPosDevices) =>
      this.posDevicesService.search(payload.name, payload.locations, payload.page, PAGINATION.PosDevices).pipe(
        map((response) => new SearchPosDevicesSuccess(response)),
        catchError((error) => of(new SearchPosDevicesFail(error)))
      )
    )
  );

  /* ========================= FIND_POS_DEVICE =================================== */
  @Effect()
  find$ = this.actions$.pipe(
    ofType(PosDeviceActionType.FIND_POS_DEVICE),
    switchMap((action: FindPosDevice) =>
      this.posDevicesService.findById(action.payload).pipe(
        map((response) => new FindPosDeviceSuccess(response)),
        catchError((error) => of(new FindPosDeviceFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  findFail$ = this.actions$.pipe(
    ofType(PosDeviceActionType.FIND_POS_DEVICE_FAIL),
    tap((action: FindPosDeviceFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(this.translationService.translate('SALES.POS_DEVICES.POS_DEVICE_NOT_FOUND'));
      }
    })
  );

  /* ========================= CREATE_POS_DEVICE =================================== */
  @Effect()
  create$ = this.actions$.pipe(
    ofType(PosDeviceActionType.CREATE_POS_DEVICE),
    switchMap((action: CreatePosDevice) =>
      this.posDevicesService.create(action.payload).pipe(
        map((response) => new CreatePosDeviceSuccess(response)),
        catchError((error) => of(new CreatePosDeviceFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  createSuccess$ = this.actions$.pipe(
    ofType(PosDeviceActionType.CREATE_POS_DEVICE_SUCCESS),
    tap((action: CreatePosDeviceSuccess) => {
      this.notificationService.success(this.translationService.translate('SALES.POS_DEVICES.POS_DEVICE_ADDED'));
    })
  );

  @Effect({ dispatch: false })
  createFail$ = this.actions$.pipe(
    ofType(PosDeviceActionType.CREATE_POS_DEVICE_FAIL),
    tap((action: CreatePosDeviceFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );

  /* ========================= UPDATE_POS_DEVICE =================================== */
  @Effect()
  update$ = this.actions$.pipe(
    ofType(PosDeviceActionType.UPDATE_POS_DEVICE),
    switchMap((action: UpdatePosDevice) =>
      this.posDevicesService.update(action.payload).pipe(
        map((response) => new UpdatePosDeviceSuccess(response)),
        catchError((error) => of(new UpdatePosDeviceFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  updateSuccess$ = this.actions$.pipe(
    ofType(PosDeviceActionType.UPDATE_POS_DEVICE_SUCCESS),
    tap((action: UpdatePosDeviceSuccess) => {
      this.notificationService.success(this.translationService.translate('SALES.POS_DEVICES.POS_DEVICE_UPDATED'));
    })
  );

  @Effect({ dispatch: false })
  updateFail$ = this.actions$.pipe(
    ofType(PosDeviceActionType.UPDATE_POS_DEVICE_FAIL),
    tap((action: UpdatePosDeviceFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(this.translationService.translate('SALES.POS_DEVICES.POS_DEVICE_NOT_FOUND'));
      } else if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );

  /* ========================= DELETE_POS_DEVICE =================================== */
  @Effect()
  delete$ = this.actions$.pipe(
    ofType(PosDeviceActionType.DELETE_POS_DEVICE),
    switchMap((action: DeletePosDevice) =>
      this.posDevicesService.delete(action.payload).pipe(
        map((response) => new DeletePosDeviceSuccess(response)),
        catchError((error) => of(new DeletePosDeviceFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  deleteSuccess$ = this.actions$.pipe(
    ofType(PosDeviceActionType.DELETE_POS_DEVICE_SUCCESS),
    tap((action: DeletePosDeviceSuccess) => {
      this.notificationService.success(this.translationService.translate('SALES.POS_DEVICES.POS_DEVICE_DELETED'));
    })
  );

  @Effect({ dispatch: false })
  deleteFail$ = this.actions$.pipe(
    ofType(PosDeviceActionType.DELETE_POS_DEVICE_FAIL),
    tap((action: DeletePosDeviceFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(this.translationService.translate('SALES.POS_DEVICES.POS_DEVICE_NOT_FOUND'));
      } else if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );
}
