import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';

import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { skip, tap } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Decimal } from 'decimal.js';

import { PageInfo, NotificationService, NotificationMessage, CustomValidators, TranslationService } from 'shared';
import * as fromFinancesStore from 'finances/store';
import { Account, BankAccount, CreateJournalLineInput, JournalLineFormItem, PettyCash } from 'finances/models';
import { CanComponentDeactivate } from 'auth/models';

/**
 * The petty cash reduction pages.
 */
enum PAGES {
  details = 'details',
  journal = 'journal',
}

@Component({
  selector: 'app-create-petty-cash-reduction',
  templateUrl: './create-petty-cash-reduction.component.html',
  styles: [],
})
export class CreatePettyCashReductionComponent implements OnInit, OnDestroy, CanComponentDeactivate {
  /**
   * The confirm modal template reference.
   */
  @ViewChild('confirmModalRef') confirmModalRef: ElementRef<any>;

  /**
   * Gets or sets the information about the current page.
   */
  pageInfo: PageInfo = {
    title: 'FINANCES.PETTY_CASH_REDUCTION.CREATE_PETTY_CASH_REDUCTION_PAGE_TITLE',
    icon: 'fa fa-plus',
  };

  /**
   * Gets or sets the selected page.
   * @default 'details'
   */
  activePage: PAGES = PAGES.details;

  /**
   * The list of petty cash.
   */
  pettyCash$: Observable<PettyCash[]>;

  /**
   * Indicates whether there is a create-petty-cash-reduction process is running or not.
   */
  isCreating$: Observable<boolean>;

  /**
   * The list of selected petty cash.
   */
  pettyCash: PettyCash[] = [];

  /**
   * The list of bank accounts.
   */
  bankAccounts: BankAccount[] = [];

  /**
   * The list of petty-cash payment types.
   */
  paymentTypes = [
    { key: 'CASH', name: 'من حساب النقدية', nameEn: 'From the cash account' },
    { key: 'BANK', name: 'من حساب البنوك', nameEn: 'From the banks account' },
  ];

  /**
   * Shows or hide the petty cash list.
   */
  pettyCashListVisibility = false;

  /**
   * Shows or hide the bank accounts list.
   */
  bankAccountsListVisibility = false;

  /**
   * Gets the petty cash account.
   */
  pettyCashAccount: Account;

  /**
   * Gets the cash in banks account.
   */
  cashInBanksAccount: Account;

  /**
   * Gets the cash in hand account.
   */
  cashInHandAccount: Account;

  /**
   * The create petty cash reduction form.
   */
  form: FormGroup;

  /**
   * Sets the initial journal lines should be added to the journals form.
   * @param journal lines An object contains `count` of initial blank journal lines.
   * @param journal lines A single journal line details to be added to the form.
   * @param journal lines A list of journal lines details to be added to the form.
   */
  initialJournalLinesSubject: BehaviorSubject<
    { count: number } | JournalLineFormItem | JournalLineFormItem[]
  > = new BehaviorSubject(undefined);

  /**
   * The set of subscriptions on this components,
   * these subscriptions must be unsubscribed before this component got destroyed.
   */
  subscriptions = new Subscription();

  /**
   * Gets the journal-lines form-array.
   */
  get journalLinesForm(): FormArray {
    return this.form?.controls.lines as FormArray;
  }

  /**
   * @param modalService The modal service.
   * @param route The activated route.
   * @param locationService The location service.
   * @param notificationService The notification service.
   * @param financesStore$ The finances store.
   * @param translationService The translation service.
   */
  constructor(
    private modalService: NgbModal,
    private route: ActivatedRoute,
    private locationService: Location,
    private notificationService: NotificationService,
    private financesStore$: Store<fromFinancesStore.FinancesState>,
    private translationService: TranslationService
  ) {}

  ngOnInit(): void {
    this.init();
  }

  /**
   * Destroy component data
   */
  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  /**
   * Initialize component data.
   */
  init() {
    this.initForm();
    this.initJournalLines();

    /**
     * Reset form controls when new petty cash reduction is created.
     */
    this.subscriptions.add(
      this.financesStore$
        .pipe(
          select(fromFinancesStore.getSelectedPettyCashReductionCreateCompleted),
          skip(1),
          tap((isCreated) => {
            if (isCreated) {
              this.resetForm();
            }
          })
        )
        .subscribe()
    );

    /**
     * Load data.
     */
    this.isCreating$ = this.financesStore$.pipe(select(fromFinancesStore.getSelectedPettyCashReductionCreating));
    this.pettyCash$ = this.financesStore$.pipe(select(fromFinancesStore.getPettyCash));

    /** Select the user desired page. */
    this.activePage = PAGES[this.route.snapshot.fragment] ?? this.activePage;
    this.selectedPageChanged(this.activePage);
  }

  /**
   * Initialize form and add validators.
   */
  initForm() {
    const valueFormControl = new FormControl('', [Validators.required, CustomValidators.gt(0)]);
    const paymentTypeFormControl = new FormControl(null, Validators.required);

    this.form = new FormGroup({
      pettyCashId: new FormControl(null, Validators.required),
      paymentType: paymentTypeFormControl,
      bankAccountId: new FormControl(null, Validators.required),
      value: valueFormControl,
      transactionDate: new FormControl(null),
      notes: new FormControl('', [Validators.minLength(0), Validators.maxLength(200)]),
      lines: new FormArray([], CustomValidators.arrayItems(2)),
    });

    /**
     * Subscription when selected payment type & value of petty cash reduction changes.
     */
    this.subscriptions.add(paymentTypeFormControl.valueChanges.subscribe(() => this.onPaymentTypeOrValueChanges()));
    this.subscriptions.add(valueFormControl.valueChanges.subscribe(() => this.onPaymentTypeOrValueChanges()));

    this.setBlankJournalsCount();
  }

  /**
   * Initialize the journal data.
   */
  initJournalLines() {
    /**
     * Fetch system accounts then fill the journal line form with its lines.
     */

    let isManualSearchTriggeredBeforeForPettyCashAccount = false;
    this.subscriptions.add(
      /**
       * Petty Cash Account.
       */
      this.financesStore$
        .pipe(
          select(fromFinancesStore.getPettyCashAccount),
          tap((account) => {
            this.pettyCashAccount = account;
            if (account) {
              this.onPaymentTypeOrValueChanges();
            } else if (!account && !isManualSearchTriggeredBeforeForPettyCashAccount) {
              isManualSearchTriggeredBeforeForPettyCashAccount = true;
              this.financesStore$.dispatch(new fromFinancesStore.FindPettyCashAccount());
            }
          })
        )
        .subscribe()
    );

    let isManualSearchTriggeredBeforeForCashInBanksAccount = false;
    this.subscriptions.add(
      /**
       * Bank in banks Account.
       */
      this.financesStore$
        .pipe(
          select(fromFinancesStore.getCashInBanksAccount),
          tap((account) => {
            this.cashInBanksAccount = account;
            if (account) {
              this.onPaymentTypeOrValueChanges();
            } else if (!account && !isManualSearchTriggeredBeforeForCashInBanksAccount) {
              isManualSearchTriggeredBeforeForCashInBanksAccount = true;
              this.financesStore$.dispatch(new fromFinancesStore.FindCashInBanksAccount());
            }
          })
        )
        .subscribe()
    );

    let isManualSearchTriggeredBeforeForCashInHandAccount = false;
    this.subscriptions.add(
      /**
       * Cash in hand Account.
       */
      this.financesStore$
        .pipe(
          select(fromFinancesStore.getCashInHandAccount),
          tap((account) => {
            this.cashInHandAccount = account;
            if (account) {
              this.onPaymentTypeOrValueChanges();
            } else if (!account && !isManualSearchTriggeredBeforeForCashInHandAccount) {
              isManualSearchTriggeredBeforeForCashInHandAccount = true;
              this.financesStore$.dispatch(new fromFinancesStore.FindCashInHandAccount());
            }
          })
        )
        .subscribe()
    );
  }

  /**
   * Sets the count of the journal lines should be added to the journals form for quick start..
   */
  setBlankJournalsCount() {
    this.initialJournalLinesSubject.next({ count: 0 });
  }

  /**
   * Resets journal lines data.
   */
  resetJournalLines() {
    this.journalLinesForm.clear();
  }

  /**
   * Handles the change in the selected payment type or petty cash reduction value.
   */
  onPaymentTypeOrValueChanges() {
    const paymentType = this.form.controls.paymentType.value;
    const value = this.form.controls.value.value ?? 0;

    /**
     * Set Journal Lines based on the selected payment type and reduction value.
     */
    this.resetJournalLines();

    /**
     * The list of journal lines to be added to the form.
     */
    const lines: JournalLineFormItem[] = [];

    /**
     * Add line for cash in hand Account.
     */
    if (paymentType === 'CASH' && this.cashInHandAccount) {
      lines.push({
        accountId: this.cashInHandAccount.id,
        account: this.cashInHandAccount,
        costCenterId: null,
        debit: value,
        credit: 0,
        notes: '',
        disableAccountChange: true,
        disableDebitAndCreditValueChange: true,
      });
    }

    /**
     * Add line for cash in banks Account.
     */
    if (paymentType === 'BANK' && this.cashInBanksAccount) {
      lines.push({
        accountId: this.cashInBanksAccount.id,
        account: this.cashInBanksAccount,
        costCenterId: null,
        debit: value,
        credit: 0,
        notes: '',
        disableAccountChange: true,
        disableDebitAndCreditValueChange: true,
      });
    }

    /**
     * Add line for petty cash Account.
     */
    if (this.pettyCashAccount) {
      lines.push({
        accountId: this.pettyCashAccount.id,
        account: this.pettyCashAccount,
        costCenterId: null,
        debit: 0,
        credit: value,
        notes: '',
        disableAccountChange: true,
        disableDebitAndCreditValueChange: true,
      });
    }

    this.initialJournalLinesSubject.next(lines);
  }

  /**
   * Submits the form.
   */
  submit() {
    const errorMessage = new NotificationMessage();

    if (this.form.invalid) {
      if (this.form.get('pettyCashId').invalid) {
        errorMessage.title = this.translationService.translate(
          'FINANCES.PETTY_CASH_REDUCTION.PETTY_CASH_REDUCTION_DATA_VALIDATION.PETTY_CASH_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate(
            'FINANCES.PETTY_CASH_REDUCTION.PETTY_CASH_REDUCTION_DATA_VALIDATION.PETTY_CASH_IS_REQUIRED'
          ),
        ];
        this.activePage = PAGES.details;
      } else if (this.form.get('paymentType').invalid) {
        errorMessage.title = this.translationService.translate(
          'FINANCES.PETTY_CASH_REDUCTION.PETTY_CASH_REDUCTION_DATA_VALIDATION.PAYMENT_TYPE_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate(
            'FINANCES.PETTY_CASH_REDUCTION.PETTY_CASH_REDUCTION_DATA_VALIDATION.PAYMENT_TYPE_IS_REQUIRED'
          ),
        ];
        this.activePage = PAGES.details;
      } else if (this.form.get('bankAccountId').invalid) {
        errorMessage.title = this.translationService.translate(
          'FINANCES.PETTY_CASH_REDUCTION.PETTY_CASH_REDUCTION_DATA_VALIDATION.BANK_ACCOUNT_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate(
            'FINANCES.PETTY_CASH_REDUCTION.PETTY_CASH_REDUCTION_DATA_VALIDATION.BANK_ACCOUNT_IS_REQUIRED'
          ),
        ];
        this.activePage = PAGES.details;
      } else if (this.form.get('value').invalid) {
        errorMessage.title = this.translationService.translate(
          'FINANCES.PETTY_CASH_REDUCTION.PETTY_CASH_REDUCTION_DATA_VALIDATION.VALUE_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate(
            'FINANCES.PETTY_CASH_REDUCTION.PETTY_CASH_REDUCTION_DATA_VALIDATION.VALUE_IS_REQUIRED'
          ),
          this.translationService.translate(
            'FINANCES.PETTY_CASH_REDUCTION.PETTY_CASH_REDUCTION_DATA_VALIDATION.VALUE_LENGTH_ERROR'
          ),
        ];
        this.activePage = PAGES.details;
      } else if (this.form.get('notes').invalid) {
        errorMessage.title = this.translationService.translate(
          'FINANCES.PETTY_CASH_REDUCTION.PETTY_CASH_REDUCTION_DATA_VALIDATION.NOTES_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate(
            'FINANCES.PETTY_CASH_REDUCTION.PETTY_CASH_REDUCTION_DATA_VALIDATION.NOTES_LENGTH_ERROR'
          ),
        ];
        this.activePage = PAGES.details;
      } else if (this.form.get('lines').invalid) {
        /**
         * Check if journal lines count < 2.
         */
        if (this.journalLinesForm.controls.length < 2) {
          errorMessage.title = this.translationService.translate(
            'FINANCES.PETTY_CASH_REDUCTION.PETTY_CASH_REDUCTION_DATA_VALIDATION.JOURNALS_LINES_ERROR'
          );
          errorMessage.body = [
            this.translationService.translate(
              'FINANCES.PETTY_CASH_REDUCTION.PETTY_CASH_REDUCTION_DATA_VALIDATION.JOURNALS_LINES_COUNT_ERROR'
            ),
          ];
        } else {
          /**
           * Check if some of journals has errors.
           */
          for (let index = 0; index < this.journalLinesForm.controls.length; index++) {
            const journal = this.journalLinesForm.controls[index];

            if (journal.valid) {
              continue;
            }
            errorMessage.title = this.translationService.translate(
              'FINANCES.PETTY_CASH_REDUCTION.PETTY_CASH_REDUCTION_DATA_VALIDATION.JOURNAL_LINE_NUMBER_ERROR',
              {
                journalNumber: index + 1,
              }
            );
            errorMessage.body = [];

            if (journal.get('accountId').invalid) {
              errorMessage.body.push(
                this.translationService.translate(
                  'FINANCES.PETTY_CASH_REDUCTION.PETTY_CASH_REDUCTION_DATA_VALIDATION.ACCOUNT_ERROR'
                )
              );
              errorMessage.body.push(
                this.translationService.translate(
                  'FINANCES.PETTY_CASH_REDUCTION.PETTY_CASH_REDUCTION_DATA_VALIDATION.ACCOUNT_IS_REQUIRED'
                )
              );
            }

            if (journal.get('credit').invalid) {
              errorMessage.body.push(
                this.translationService.translate(
                  'FINANCES.PETTY_CASH_REDUCTION.PETTY_CASH_REDUCTION_DATA_VALIDATION.CREDIT_IS_REQUIRED'
                )
              );
            }

            if (journal.get('debit').invalid) {
              errorMessage.body.push(
                this.translationService.translate(
                  'FINANCES.PETTY_CASH_REDUCTION.PETTY_CASH_REDUCTION_DATA_VALIDATION.DEBIT_IS_REQUIRED'
                )
              );
            }

            if (journal.get('costCenterId').invalid) {
              errorMessage.body.push(
                this.translationService.translate(
                  'FINANCES.PETTY_CASH_REDUCTION.PETTY_CASH_REDUCTION_DATA_VALIDATION.COST_CENTER_ERROR'
                )
              );
              errorMessage.body.push(
                this.translationService.translate(
                  'FINANCES.PETTY_CASH_REDUCTION.PETTY_CASH_REDUCTION_DATA_VALIDATION.COST_CENTER_IS_REQUIRED'
                )
              );
            }

            if (journal.get('notes').invalid) {
              errorMessage.body.push(
                this.translationService.translate(
                  'FINANCES.PETTY_CASH_REDUCTION.PETTY_CASH_REDUCTION_DATA_VALIDATION.NOTES_ERROR'
                )
              );
              errorMessage.body.push(
                this.translationService.translate(
                  'FINANCES.PETTY_CASH_REDUCTION.PETTY_CASH_REDUCTION_DATA_VALIDATION.NOTES_IS_REQUIRED'
                )
              );
              errorMessage.body.push(
                this.translationService.translate(
                  'FINANCES.PETTY_CASH_REDUCTION.PETTY_CASH_REDUCTION_DATA_VALIDATION.NOTES_LENGTH_ERROR'
                )
              );
            }

            break;
          }
        }

        this.activePage = PAGES.journal;
      }

      this.form.markAllAsTouched();
      return this.notificationService.warningWithTitle(errorMessage);
    }
    const lines: CreateJournalLineInput[] = this.journalLinesForm.value;

    if (
      lines.some((line) => line.debit === 0 && line.credit === 0) ||
      lines.some((line) => line.debit !== 0 && line.credit !== 0)
    ) {
      errorMessage.title = this.translationService.translate(
        'FINANCES.PETTY_CASH_REDUCTION.PETTY_CASH_REDUCTION_DATA_VALIDATION.JOURNALS_LINES_TITLE_ERROR'
      );
      errorMessage.body = [
        this.translationService.translate(
          'FINANCES.PETTY_CASH_REDUCTION.PETTY_CASH_REDUCTION_DATA_VALIDATION.DEBIT_CREDIT_ERROR'
        ),
        this.translationService.translate(
          'FINANCES.PETTY_CASH_REDUCTION.PETTY_CASH_REDUCTION_DATA_VALIDATION.JOURNALS_LINES_TYPE_ERROR'
        ),
      ];
      this.activePage = PAGES.journal;

      this.form.markAllAsTouched();
      return this.notificationService.warningWithTitle(errorMessage);
    } else if (
      !Decimal.sum(0, ...lines.filter((line) => !!line.debit).map((line) => line.debit)).eq(
        Decimal.sum(0, ...lines.filter((line) => !!line.credit).map((line) => line.credit))
      )
    ) {
      errorMessage.title = this.translationService.translate(
        'FINANCES.PETTY_CASH_REDUCTION.PETTY_CASH_REDUCTION_DATA_VALIDATION.JOURNALS_LINES_TOTAL_ERROR'
      );
      errorMessage.body = [
        this.translationService.translate(
          'FINANCES.PETTY_CASH_REDUCTION.PETTY_CASH_REDUCTION_DATA_VALIDATION.JOURNALS_LINES_TOTAL_VALUE_ERROR'
        ),
      ];
      this.activePage = PAGES.journal;

      this.form.markAllAsTouched();
      return this.notificationService.warningWithTitle(errorMessage);
    }

    this.openModal(this.confirmModalRef);
  }

  /**
   * Confirms the form submit.
   */
  confirm() {
    let transactionDateFormatted = null;
    const { transactionDate } = this.form.value;

    if (transactionDate) {
      transactionDateFormatted = new Date(transactionDate.year, transactionDate.month - 1, transactionDate.day);
      transactionDateFormatted.setHours(new Date().getHours(), new Date().getMinutes());
    }
    /**
     * The list of lines in the lines form.
     */
    const lines = this.journalLinesForm.value
      .filter((line: JournalLineFormItem) => line.credit > 0 || line.debit > 0)
      .map((line) => {
        const inputLine: CreateJournalLineInput = {
          accountId: line.accountId,
          debit: line.debit,
          credit: line.credit,
          costCenterId: line.costCenterId,
          notes: line.notes,
        };

        return inputLine;
      });

    this.financesStore$.dispatch(
      new fromFinancesStore.CreatePettyCashReduction({
        pettyCashId: this.form.value.pettyCashId,
        bankAccountId: this.form.value.bankAccountId,
        value: this.form.value.value,
        transactionDate: transactionDateFormatted,
        notes: this.form.value.notes,
        lines,
      })
    );
  }

  /**
   * Opens the modal of the given templateRef.
   * @param modalRef The modal templateRef to be opened.
   */
  openModal(modalRef) {
    this.modalService.open(modalRef);
  }

  /**
   * Selects the newly selected petty cash from the petty cash search list.
   * @param pettyCashes The list of newly selected petty cash to select the only one in the list.
   */
  selectPettyCash([petty]: PettyCash[]) {
    if (petty) {
      this.pettyCash = [petty];
      this.form.patchValue({ pettyCashId: petty.id });
    }
  }

  /**
   * Selects the newly selected bank account from the bank accounts search list.
   * @param bankAccounts The list of newly selected bank accounts to select the only one in the list.
   */
  selectBankAccount([bankAccount]: BankAccount[]) {
    if (bankAccount) {
      this.bankAccounts = [bankAccount];
      this.form.patchValue({ bankAccountId: bankAccount.id });
    }
  }

  /**
   * Resets all form data.
   *
   * It only adds one product for quick start.
   */
  resetForm() {
    this.initForm();
    this.resetJournalLines();
    this.form.markAsUntouched();
  }

  /**
   * Updates the browser url according to the selected page.
   * @param page The newly selected page.
   */
  selectedPageChanged(page: PAGES) {
    this.locationService.replaceState(`${this.locationService.path()}#${PAGES[page]}`);
  }

  /**
   * Confirms leaving the page before routing.
   */
  confirmDeactivate() {
    if (this.form.dirty === true) {
      /**
       * Message to warn the user before leaving the detailed form.
       */
      return confirm(this.translationService.translate('SHARED.CONFIRM.LEAVING'));
    }
    return true;
  }
}
