import { Action } from '@ngrx/store';

import { AnnualLeaveLogType } from 'lookups/models';
import { AppHttpResponse } from 'shared';

/**
 * The Lookups-Module store annual leave log type action types.
 */
export enum AnnualLeaveLogTypeActionType {
  GET_ALL_ANNUAL_LEAVE_LOG_TYPES = '[Lookups] [AnnualLeaveLogType] Get All Annual Leave Log Types',
  GET_ALL_ANNUAL_LEAVE_LOG_TYPES_FAIL = '[Lookups] [AnnualLeaveLogType] Get All Annual Leave Log Types Fail',
  GET_ALL_ANNUAL_LEAVE_LOG_TYPES_SUCCESS = '[Lookups] [AnnualLeaveLogType] Get All Annual Leave Log Types Success',
}

/**
 * Represents a store annual leave log type action.
 */
export class GetAllAnnualLeaveLogTypes implements Action {
  /**
   * The type of the action.
   */
  readonly type = AnnualLeaveLogTypeActionType.GET_ALL_ANNUAL_LEAVE_LOG_TYPES;

  /**
   * Fires a new annual leave log type action.
   */
  constructor() {}
}

/**
 * Represents a store annual leave log type fail action.
 */
export class GetAllAnnualLeaveLogTypesFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = AnnualLeaveLogTypeActionType.GET_ALL_ANNUAL_LEAVE_LOG_TYPES_FAIL;

  /**
   * Fires a new annual leave log type fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store annual leave log type success action.
 */
export class GetAllAnnualLeaveLogTypesSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = AnnualLeaveLogTypeActionType.GET_ALL_ANNUAL_LEAVE_LOG_TYPES_SUCCESS;

  /**
   * Fires a annual leave log type success action.
   * @param payload An object contains the list of annual leave log types.
   */
  constructor(public payload: AppHttpResponse<AnnualLeaveLogType[]>) {}
}

/**
 * Lookups-module annual leave log type action types.
 */
export type AnnualLeaveLogTypeActions =
  | GetAllAnnualLeaveLogTypes
  | GetAllAnnualLeaveLogTypesFail
  | GetAllAnnualLeaveLogTypesSuccess;
