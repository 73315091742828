import { Component, Input, OnDestroy } from '@angular/core';
import { FormArray, FormGroup, FormControl, Validators } from '@angular/forms';

import { Subscription } from 'rxjs';

import { CustomValidators } from 'shared';
import { PettyCashRefundRequestLineFormItem } from 'finances/models';
import { PettyCashRefundRequestType } from 'lookups/models';
import { Tax } from 'settings/models';

@Component({
  selector: 'app-petty-cash-refund-requests-form',
  templateUrl: './petty-cash-refund-requests-form.component.html',
  styles: [],
})
export class PettyCashRefundRequestsFormComponent implements OnDestroy {
  /**
   * Sets the lines form-array.
   */
  @Input() linesForm: FormArray;

  /**
   * Sets the name form-array in its parent form.
   * @default 'lines'
   */
  @Input() formArrayName = 'lines';

  /**
   * Adds a list of new request form items to the requests form.
   * @param items The list of request form items to be added to the requests form.
   */
  @Input() set newPettyCashRefundRequests(items: PettyCashRefundRequestLineFormItem[]) {
    if (!items?.length) {
      return;
    }

    items.forEach((item) => this.addLine(item));
  }

  /**
   * Sets the list of types.
   */
  @Input() types: PettyCashRefundRequestType[];

  /**
   * Sets the id of the debit notification refund request type.
   */
  @Input() debitNotificationPettyCashRefundRequestTypeId: number;

  /**
   * Sets the list of taxes.
   */
  @Input() taxes: Tax[];

  /**
   * Sets the default tax on the system.
   */
  @Input() defaultTax: Tax;

  /**
   * Indicates whether if we can add line or not.
   */
  @Input() allowAddLine = true;

  /**
   * Indicates whether if we can clear lines or not.
   * @default `true`.
   */
  @Input() allowClearLines = true;

  /**
   * Sets a value indicates whether if the tax can be changed by the user.
   * @default true
   */
  @Input() allowChangeTax = true;

  /**
   * Sets the initial lines in the lines form.
   * @param lines An object contains `count` of initial blank lines.
   * @param lines A single lines details to be added to the form.
   * @param lines A list of lines details to be added to the form.
   */
  @Input() set initialLines(
    lines: { count: number } | PettyCashRefundRequestLineFormItem | PettyCashRefundRequestLineFormItem[]
  ) {
    if (Object.getOwnPropertyNames(lines).includes('count')) {
      for (let index = 0; index < (lines as { count: number }).count; index++) {
        this.addLine();
      }
    } else if (lines instanceof Array) {
      lines.forEach((line) => this.addLine(line));
    } else if (typeof lines === 'object') {
      this.addLine(lines as PettyCashRefundRequestLineFormItem);
    }
  }

  /**
   * Indicates that a drop-down field in the form is opened and therefore the table height should be expanded.
   */
  dropDownIsOpened: boolean;

  /**
   * The set of subscriptions on this components,
   * these subscriptions must be unsubscribed before this component got destroyed.
   */
  subscriptions = new Subscription();

  /**
   * Destroy component data
   */
  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  /**
   * Creates & adds a new line form-group with validations.
   * @param line The line details to be bounded to the line, If omitted a blank line will be created.
   */
  addLine(line?: PettyCashRefundRequestLineFormItem) {
    const today = new Date();

    const lineFormGroup = new FormGroup({
      description: new FormControl(line?.description ?? '', [
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(200),
      ]),
      pettyCashRefundRequestTypeId: new FormControl(line?.pettyCashRefundRequestTypeId, Validators.required),

      vendorName: new FormControl(line?.vendorName ?? '', [
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(50),
      ]),
      value: new FormControl(line?.value ?? 0.0, [Validators.required, CustomValidators.gt(0)]),
      tax: new FormControl(this.defaultTax ? this.defaultTax.value : line?.tax, [
        Validators.required,
        CustomValidators.gte(0),
      ]),
      invoiceDate: new FormControl(
        line?.invoiceDate ?? {
          year: today.getFullYear(),
          month: today.getMonth() + 1,
          day: today.getDate(),
        },
        Validators.required
      ),
      attachments: new FormArray([]),
    });

    this.linesForm.push(lineFormGroup);
  }

  /**
   * Remove the line at the given index from the list.
   * @param index The index of the line to be removed.
   */
  removeLine(index: number) {
    this.linesForm.removeAt(index);
  }

  /**
   * Remove all of the list of lines.
   *
   * It only adds one line for quick start.
   */
  resetLines() {
    this.linesForm.clear();
    this.addLine();
  }
}
