/**
 * The set of system supported assignment statuses.
 */
export enum AssignmentStatuses {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  TERMINATED = 'TERMINATED',
  RUN_AWAY = 'RUN_AWAY',
  IN_ANNUAL_LEAVE = 'IN_ANNUAL_LEAVE',
  IN_SICK_LEAVE = 'IN_SICK_LEAVE',
  IN_PAID_LEAVE = 'IN_PAID_LEAVE',
  IN_UNPAID_LEAVE = 'IN_UNPAID_LEAVE',
}
