import { Component, Input, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';

/**
 * The project plan pages.
 */
enum PAGES {
  'plan' = 'plan',
  'project-log' = 'project-log',
}

@Component({
  selector: 'app-project-plan',
  templateUrl: './project-plan.component.html',
  styles: [],
})
export class ProjectPlanComponent implements OnInit {
  /**
   * Sets the project id.
   */
  @Input() projectId: number;

  /**
   * Gets or sets the selected page.
   * @default 'plan'
   */

  activePage: PAGES = PAGES.plan;

  /**
   * @param locationService The location service.
   * @param route The activated route.
   **/
  constructor(private locationService: Location, private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.init();
  }

  /**
   * Initialize component data.
   */
  init() {
    /** Select the user desired page. */
    this.activePage = PAGES[this.route.snapshot.fragment] ?? this.activePage;
    this.selectedPageChanged(this.activePage);
  }

  /**
   * Updates the browser url according to the selected page.
   * @param page The newly selected page.
   */
  selectedPageChanged(page: PAGES) {
    this.locationService.replaceState(`${this.locationService.path()}#${PAGES[page]}`);
  }
}
