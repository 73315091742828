import { Injectable } from '@angular/core';

import { of } from 'rxjs';
import { switchMap, map, catchError } from 'rxjs/operators';
import { Effect, ofType, Actions } from '@ngrx/effects';

import { LookupsService } from 'lookups/services';
import {
  PurchaseOrderTypeActionType,
  GetAllPurchaseOrderTypeFail,
  GetAllPurchaseOrderTypeSuccess,
} from 'lookups/store/actions';

@Injectable()
export class PurchaseOrderTypeEffects {
  constructor(private actions$: Actions, private lookupsService: LookupsService) {}

  /* ========================= GET_ALL_PURCHASE_ORDER_TYPE =================================== */
  @Effect()
  getAllPurchaseOrderTypes$ = this.actions$.pipe(
    ofType(PurchaseOrderTypeActionType.GET_ALL_PURCHASE_ORDER_TYPE),
    switchMap(() =>
      this.lookupsService.getAllPurchaseOrderTypes().pipe(
        map((response) => new GetAllPurchaseOrderTypeSuccess(response)),
        catchError((error) => of(new GetAllPurchaseOrderTypeFail(error)))
      )
    )
  );
}
