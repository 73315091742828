import { WebsocketService } from 'websocket/services/websocket.service';
import { WebsocketEventsService } from 'websocket/services/websocket-events.service';

export * from 'websocket/services/websocket.service';
export * from 'websocket/services/websocket-events.service';

/**
 * The set of services registered on this module.
 */
export const SERVICES: any[] = [WebsocketService, WebsocketEventsService];
