import { createSelector } from '@ngrx/store';

import { selectLookupsState } from 'lookups/store/selectors/feature.selectors';
import { LookupsState, PrimaryAccountsState } from 'lookups/store/states';

/**
 * Gets the primary accounts state.
 */
const selectPrimaryAccounts = createSelector(selectLookupsState, (state: LookupsState) => state.primaryAccounts);

/**
 * Gets the list of loaded primary accounts.
 */
export const getPrimaryAccounts = createSelector(selectPrimaryAccounts, (state: PrimaryAccountsState) => state.data);

/**
 * Gets the error that occurred in the state.
 */
export const getPrimaryAccountsError = createSelector(
  selectPrimaryAccounts,
  (state: PrimaryAccountsState) => state.error
);

/**
 * Determines if there is a running load process.
 */
export const getPrimaryAccountsIsLoading = createSelector(
  selectPrimaryAccounts,
  (state: PrimaryAccountsState) => state.isLoading
);

/**
 * Determines if the last load process has been ended successfully.
 */
export const getPrimaryAccountsIsLoaded = createSelector(
  selectPrimaryAccounts,
  (state: PrimaryAccountsState) => state.isLoaded
);
