import { StatisticsComponent } from 'statistics/components/statistics/statistics.component';
import { SystemStatisticsComponent } from 'statistics/components/system-statistics/system-statistics.component';

export * from 'statistics/components/statistics/statistics.component';
export * from 'statistics/components/system-statistics/system-statistics.component';

/**
 * The set of components registered on this module.
 */
export const COMPONENTS: any[] = [StatisticsComponent, SystemStatisticsComponent];
