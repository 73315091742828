import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import { Observable, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { PageInfo, NotificationService, NotificationMessage, CustomValidators, TranslationService } from 'shared';
import * as fromFinancesStore from 'finances/store';
import * as fromLookupsStore from 'lookups/store';
import { PettyCashType, PettyCashSpecification } from 'lookups/models';
import { PettyCash } from 'finances/models';

@Component({
  selector: 'app-update-petty-cash',
  templateUrl: './update-petty-cash.component.html',
  styles: [],
})
export class UpdatePettyCashComponent implements OnInit, OnDestroy {
  /**
   * The confirm modal template reference.
   */
  @ViewChild('confirmModalRef') confirmModalRef: ElementRef<any>;

  /**
   * Gets or sets the information about the current page.
   */
  pageInfo: PageInfo = {
    title: 'FINANCES.PETTY_CASH.UPDATE_PETTY_CASH_PAGE_TITLE',
    icon: 'fa fa-edit',
  };

  /**
   * Gets or sets the id of the current edited petty cash.
   */
  pettyCashId: number;

  /**
   * The current edited petty cash.
   */
  pettyCash$: Observable<PettyCash>;

  /**
   * The list of petty cash types.
   */
  pettyCashTypes$: Observable<PettyCashType[]>;

  /**
   * The list of petty cash specifications.
   */
  pettyCashSpecifications$: Observable<PettyCashSpecification[]>;

  /**
   * Indicates whether there is a update-payroll-element process is running or not.
   */
  isUpdating$: Observable<boolean>;

  /**
   * The update petty cash form.
   */
  form: FormGroup;

  /**
   * The set of subscriptions on this components,
   * these subscriptions must be unsubscribed before this component got destroyed.
   */
  subscriptions = new Subscription();

  /**
   * @param route The activated route.
   * @param modalService The modal service.
   * @param notificationService The notification service.
   * @param financesStore$ The finances store.
   * @param lookupsStore$ The lookups store.
   * @param translationService The translation service.
   */
  constructor(
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private notificationService: NotificationService,
    private financesStore$: Store<fromFinancesStore.FinancesState>,
    private lookupsStore$: Store<fromLookupsStore.LookupsState>,
    private translationService: TranslationService
  ) {}

  ngOnInit(): void {
    this.init();
  }

  /**
   * Destroy component data
   */
  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  /**
   * Initialize component data.
   */
  init() {
    this.initForm();

    /**
     * Load data.
     */
    this.isUpdating$ = this.financesStore$.pipe(select(fromFinancesStore.getSelectedPettyCashUpdating));

    let isManualSearchTriggeredBeforeForPettyCashTypes = false;
    this.pettyCashTypes$ = this.lookupsStore$.pipe(
      select(fromLookupsStore.getPettyCashTypes),
      tap((pettyCashTypes) => {
        if (!isManualSearchTriggeredBeforeForPettyCashTypes && !pettyCashTypes.length) {
          isManualSearchTriggeredBeforeForPettyCashTypes = true;
          this.lookupsStore$.dispatch(new fromLookupsStore.GetAllPettyCashType());
        }
      })
    );

    let isManualSearchTriggeredBeforeForPettyCashSpecifications = false;
    this.pettyCashSpecifications$ = this.lookupsStore$.pipe(
      select(fromLookupsStore.getPettyCashSpecifications),
      tap((pettyCashSpecifications) => {
        if (!isManualSearchTriggeredBeforeForPettyCashSpecifications && !pettyCashSpecifications.length) {
          isManualSearchTriggeredBeforeForPettyCashSpecifications = true;
          this.lookupsStore$.dispatch(new fromLookupsStore.GetAllPettyCashSpecification());
        }
      })
    );

    /** Subscribe to route to get the petty cash id whenever it changed. */
    const routeSubscription = this.route.paramMap
      .pipe(
        tap((params) => {
          this.pettyCashId = +params.get('pettyCashId');
          this.financesStore$.dispatch(new fromFinancesStore.FindPettyCash(this.pettyCashId));
        })
      )
      .subscribe();
    this.subscriptions.add(routeSubscription);

    /* Load the selected petty cash. */
    this.pettyCash$ = this.financesStore$.pipe(
      select(fromFinancesStore.getSelectedPettyCash),
      tap((pettyCash) => {
        if (pettyCash) {
          this.pageInfo = {
            ...this.pageInfo,
            subTitle: `${pettyCash.description}`,
          };
          this.form.patchValue({ ...pettyCash });
          this.form.markAsPristine();
        }
      })
    );
  }

  /**
   * Initialize form and add validators.
   */
  initForm() {
    this.form = new FormGroup({
      description: new FormControl('', [Validators.required, Validators.minLength(1), Validators.maxLength(100)]),
      pettyCashTypeId: new FormControl(null, Validators.required),
      value: new FormControl('' /* , [Validators.required, CustomValidators.gt(0)] */),
      maxAllowedPaymentValue: new FormControl('', [Validators.required, CustomValidators.gt(0)]),
      maxAllowedRefundValue: new FormControl('', [Validators.required, CustomValidators.gt(0)]),
      pettyCashSpecificationId: new FormControl(null, Validators.required),
    });
  }

  /**
   * Submits the form.
   */
  submit() {
    if (this.form.invalid) {
      const errorMessage = new NotificationMessage();

      if (this.form.get('description').invalid) {
        errorMessage.title = this.translationService.translate(
          'FINANCES.PETTY_CASH.PETTY_CASH_DATA_VALIDATION.PETTY_CASH_DESCRIPTION_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate(
            'FINANCES.PETTY_CASH.PETTY_CASH_DATA_VALIDATION.PETTY_CASH_DESCRIPTION_IS_REQUIRED'
          ),
          this.translationService.translate(
            'FINANCES.PETTY_CASH.PETTY_CASH_DATA_VALIDATION.PETTY_CASH_DESCRIPTION_LENGTH_ERROR'
          ),
        ];
      } else if (this.form.get('pettyCashTypeId').invalid) {
        errorMessage.title = this.translationService.translate(
          'FINANCES.PETTY_CASH.PETTY_CASH_DATA_VALIDATION.PETTY_CASH_TYPE_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate(
            'FINANCES.PETTY_CASH.PETTY_CASH_DATA_VALIDATION.PETTY_CASH_TYPE_IS_REQUIRED'
          ),
        ];
      } else if (this.form.get('maxAllowedPaymentValue').invalid) {
        errorMessage.title = this.translationService.translate(
          'FINANCES.PETTY_CASH.PETTY_CASH_DATA_VALIDATION.MAX_PAYMENT_VALUE_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate(
            'FINANCES.PETTY_CASH.PETTY_CASH_DATA_VALIDATION.MAX_PAYMENT_VALUE_IS_REQUIRED'
          ),
          this.translationService.translate(
            'FINANCES.PETTY_CASH.PETTY_CASH_DATA_VALIDATION.MAX_PAYMENT_VALUE_LENGTH_ERROR'
          ),
        ];
      } else if (this.form.get('maxAllowedRefundValue').invalid) {
        errorMessage.title = this.translationService.translate(
          'FINANCES.PETTY_CASH.PETTY_CASH_DATA_VALIDATION.MAX_REFUND_VALUE_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate(
            'FINANCES.PETTY_CASH.PETTY_CASH_DATA_VALIDATION.MAX_REFUND_VALUE_IS_REQUIRED'
          ),
          this.translationService.translate(
            'FINANCES.PETTY_CASH.PETTY_CASH_DATA_VALIDATION.MAX_REFUND_VALUE_LENGTH_ERROR'
          ),
        ];
      } else if (this.form.get('pettyCashSpecificationId').invalid) {
        errorMessage.title = this.translationService.translate(
          'FINANCES.PETTY_CASH.PETTY_CASH_DATA_VALIDATION.PETTY_CASH_SPECIFICATION_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate(
            'FINANCES.PETTY_CASH.PETTY_CASH_DATA_VALIDATION.PETTY_CASH_SPECIFICATION_IS_REQUIRED'
          ),
        ];
      }

      this.form.markAllAsTouched();
      return this.notificationService.warningWithTitle(errorMessage);
    } else {
      this.openModal(this.confirmModalRef);
    }
  }

  /**
   * Confirms the form submit.
   */
  confirm() {
    this.financesStore$.dispatch(
      new fromFinancesStore.UpdatePettyCash({
        id: this.pettyCashId,
        ...this.form.value,
      })
    );
  }

  /**
   * Opens the modal of the given templateRef.
   * @param modalRef The modal templateRef to be opened.
   */
  openModal(modalRef) {
    this.modalService.open(modalRef);
  }
}
