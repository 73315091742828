import { createSelector } from '@ngrx/store';

import { JournalsState, FinancesState } from 'finances/store/states';
import { selectFinancesState } from 'finances/store/selectors/feature.selectors';

/**
 * Gets the journals state.
 */
const selectJournalState = createSelector(selectFinancesState, (state: FinancesState) => state.journals);

/**
 * Gets the list of loaded journals.
 */
export const getJournals = createSelector(selectJournalState, (state: JournalsState) => state.data);

/**
 * Gets the journals pagination info.
 */
export const getJournalsPaginationInfo = createSelector(
  selectJournalState,
  (state: JournalsState) => state.paginationInfo
);

/**
 * Gets the error that occurred in the state.
 */
export const getJournalsError = createSelector(selectJournalState, (state: JournalsState) => state.error);

/**
 * Determines if there is a running search process.
 */
export const getJournalsSearching = createSelector(selectJournalState, (state: JournalsState) => state.isSearching);

/**
 * Determines if the last search process has been ended successfully.
 */
export const getJournalsSearchCompleted = createSelector(
  selectJournalState,
  (state: JournalsState) => state.isSearchCompleted
);

/**
 * Gets the selected journal.
 */
export const getSelectedJournal = createSelector(selectJournalState, (state: JournalsState) => state.selectedJournal);

/**
 * Determines if there is a running find journal process.
 */
export const getJournalsFinding = createSelector(selectJournalState, (state: JournalsState) => state.isFinding);

/**
 * Determines if the last find journal process has been ended successfully.
 */
export const getJournalsFindCompleted = createSelector(
  selectJournalState,
  (state: JournalsState) => state.isFindCompleted
);

/**
 * Determines if there is a running create process.
 */
export const getSelectedJournalCreating = createSelector(
  selectJournalState,
  (state: JournalsState) => state.isCreating
);

/**
 * Determines if the last create process has been ended successfully.
 */
export const getSelectedJournalCreateCompleted = createSelector(
  selectJournalState,
  (state: JournalsState) => state.isCreateCompleted
);
