<!-- page header starts -->
<app-page-header [info]="pageInfo"></app-page-header>
<!-- page header ends -->

<!-- create pos device form starts -->
<div class="row">
  <div class="col-md-12">
    <form [formGroup]="form" (ngSubmit)="submit()">
      <div class="card">
        <div class="card-header mb-3">
          <h4 class="card-title">
            {{ 'SALES.POS_DEVICES.POS_DEVICE_DATA_TITLE' | translate }}
          </h4>
        </div>
        <div class="card-content">
          <div class="px-3">
            <div class="row">
              <div class="form-group col-md-6">
                <label for="name">{{ 'SALES.POS_DEVICES.ARABIC_POS_DEVICE_NAME' | translate }}</label>
                <input
                  type="text"
                  id="name"
                  class="form-control round"
                  [placeholder]="'SALES.POS_DEVICES.ARABIC_POS_DEVICE_NAME_PLACEHOLDER' | translate"
                  maxlength="50"
                  formControlName="name"
                />
              </div>
              <div class="form-group col-md-6">
                <label for="nameEn">{{ 'SALES.POS_DEVICES.ENGLISH_POS_DEVICE_NAME' | translate }}</label>
                <input
                  type="text"
                  id="nameEn"
                  class="form-control round"
                  [placeholder]="'SALES.POS_DEVICES.ENGLISH_POS_DEVICE_NAME_PLACEHOLDER' | translate"
                  maxlength="50"
                  formControlName="nameEn"
                />
              </div>
              <div class="form-group col-md-12">
                <label for="locationId">{{ 'SALES.POS_DEVICES.LOCATION' | translate }}</label>
                <ng-select
                  class="round"
                  formControlName="locationId"
                  [closeOnSelect]="false"
                  labelForId="locationId"
                  searchable="true"
                  [placeholder]="'SALES.POS_DEVICES.LOCATION_PLACEHOLDER' | translate"
                  [notFoundText]="'SHARED.SEARCH.SEARCH_NOT_FOUND' | translate"
                  (open)="locationsListVisibility = true"
                >
                  <ng-option *ngFor="let location of locations" [value]="location.id">
                    {{ location.name | localize: location.nameEn | async }}
                  </ng-option>
                </ng-select>
              </div>
            </div>

            <div class="row">
              <div class="form-group col-12 d-flex justify-content-between flex-column flex-sm-row mt-2">
                <button type="submit" class="btn btn-raised btn-success round" [disabled]="isCreating$ | async">
                  <i class="fa fa-check"></i>
                  <span class="mx-1">{{ 'SHARED.CONFIRM.SAVE' | translate }}</span>
                </button>
                <a class="btn btn-dark round" routerLink="/sales/pos-home" fragment="pos-devices">
                  <i class="fa fa-undo"></i>
                  <span class="mx-1">{{ 'SALES.POS_DEVICES.POS_DEVICE_RETURN' | translate }}</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
<!-- create pos device class form ends -->

<!-- confirm modal starts -->
<ng-template #confirmModalRef let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
      {{ 'SALES.POS_DEVICES.CONFIRM_CREATE_POS_DEVICE_TITLE' | translate }}
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="d()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p class="text-success">
      {{ 'SALES.POS_DEVICES.CONFIRM_CREATE_POS_DEVICE_MESSAGE' | translate }}
    </p>
  </div>
  <div class="modal-footer">
    <div class="modal-actions">
      <button type="button" class="btn btn-raised btn-success round" (click)="c(); confirm()" ngbAutofocus>
        <i class="fa fa-check"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.SAVE' | translate }}</span>
      </button>
      <button type="button" class="btn btn-outline-dark round" (click)="c()">
        <i class="fa fa-times"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.CANCEL' | translate }}</span>
      </button>
    </div>
  </div>
</ng-template>
<!-- confirm modal ends -->

<!-- locations search starts -->
<app-locations-search [closeOnSelect]="true" (locations)="selectLocation($event)" [(visible)]="locationsListVisibility">
</app-locations-search>
<!-- locations search ends -->
