<!-- organization users search modal starts -->
<ng-template #modalRef let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title text-primary" id="modal-basic-title">{{ 'SECURITY.USERS.USERS_LIST' | translate }}</h4>
    <button type="button" class="close" aria-label="Close" (click)="d(); closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <!-- organization users list starts -->
    <div class="row">
      <div class="col-md-12">
        <form class="row" [formGroup]="searchForm" (ngSubmit)="search()">
          <div class="form-group col">
            <input
              type="text"
              class="form-control round"
              [placeholder]="'SECURITY.USERS.USER_DATA.USER_NAME_SEARCH_PLACEHOLDER' | translate"
              formControlName="name"
              (input)="search($event)"
            />
          </div>
          <div class="form-group col-md-auto">
            <button type="submit" class="btn btn-raised btn-primary round" [disabled]="isSearching$ | async">
              <i class="fa fa-search"></i>
            </button>
            <a
              *ngIf="[Claims.CREATE_ORGANIZATION_USER] | authorize | async"
              routerLink="/security/organization-users/create"
              class="btn btn-raised btn-success round mx-1"
            >
              <i class="fa fa-plus"></i>
              <span class="mx-1">{{ 'SHARED.CONFIRM.ADD' | translate }}</span>
            </a>
          </div>
        </form>
      </div>
      <div class="col-md-12" *ngIf="(organizationUsers$ | async)?.length; else noItems">
        <div class="row">
          <div class="col-md-12 alert alert-light text-center" role="alert">
            {{ 'SECURITY.USERS.ORGANIZATION_USERS' | translate }} ({{ (paginationInfo$ | async).total | toNumber }})
            {{ 'SECURITY.USERS.USER' | translate }}
          </div>
          <div class="col-md-12">
            <div class="table-responsive">
              <table class="table text-center">
                <thead>
                  <tr>
                    <th class="fit-width" *ngIf="!closeOnSelect">
                      <a
                        class="primary p-0"
                        (click)="selectUser(organizationUsersList)"
                        [ngbTooltip]="'SHARED.SEARCH.SELECT_ALL' | translate"
                      >
                        <i class="fa fa-check-circle font-medium-3"></i>
                      </a>
                    </th>
                    <th class="fit-width" *ngIf="closeOnSelect">
                      <i class="fa fa-gear font-medium-3"></i>
                    </th>
                    <th scope="col"><i class="fa fa-list-ol"></i></th>
                    <th scope="col">{{ 'SECURITY.USERS.USER_DATA.USER_NAME' | translate }}</th>
                    <th scope="col">{{ 'SECURITY.USERS.USER_DATA.USER_ORGANIZATION_NAME' | translate }}</th>
                    <th scope="col">{{ 'SECURITY.USERS.USER_DATA.USER_POINT_OF_SALE' | translate }}</th>
                    <th scope="col">{{ 'SECURITY.USERS.USER_DATA.USER_STATUS' | translate }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let user of organizationUsers$ | async; trackBy: trackItems; let i = index">
                    <td>
                      <a
                        class="primary p-0"
                        (click)="selectUser([user])"
                        [ngbTooltip]="'SECURITY.USERS.USER_DATA.USER_SELECTED' | translate"
                      >
                        <i class="fa fa-check-circle-o font-medium-3"></i>
                      </a>
                    </td>
                    <th scope="row">{{ i + 1 }}</th>
                    <th scope="row">{{ user.name }}</th>
                    <td>{{ user.organization?.name }}</td>
                    <td>
                      {{
                        user.UserPreferences?.defaultLocation.name
                          | localize: user.UserPreferences?.defaultLocation.nameEn
                          | async
                      }}
                    </td>
                    <td>
                      <app-status
                        [status]="user.isActive"
                        [activeText]="'SECURITY.USERS.USER_DATA.ACTIVE_STATUS' | translate"
                        [notActiveText]="'SECURITY.USERS.USER_DATA.NOT_ACTIVE_STATUS' | translate"
                      >
                      </app-status>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div class="d-flex justify-content-between p-2">
                <ngb-pagination
                  *ngIf="paginationInfo$ | async; let pagination"
                  maxSize="5"
                  [collectionSize]="pagination.total"
                  [pageSize]="pagination.pageSize"
                  [page]="pagination.page"
                  (pageChange)="pageChanged($event)"
                  boundaryLinks="true"
                >
                  <ng-template ngbPaginationFirst>{{ 'SHARED.PAGINATION.PAGINATION_FIRST' | translate }}</ng-template>
                  <ng-template ngbPaginationPrevious>{{
                    'SHARED.PAGINATION.PAGINATION_PREVIOUS' | translate
                  }}</ng-template>
                  <ng-template ngbPaginationNext>{{ 'SHARED.PAGINATION.PAGINATION_NEXT' | translate }}</ng-template>
                  <ng-template ngbPaginationLast>{{ 'SHARED.PAGINATION.PAGINATION_LAST' | translate }}</ng-template>
                </ngb-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- organization users list ends -->
  </div>
  <div class="modal-footer">
    <div class="modal-actions">
      <button type="button" class="btn btn-outline-dark round" (click)="c(); closeModal()">
        <i class="fa fa-times"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.CLOSE' | translate }}</span>
      </button>
    </div>
  </div>
</ng-template>
<!-- organization users search modal ends -->

<!-- no items template starts -->
<ng-template #noItems>
  <div class="col-md-12 alert alert-warning mt-3 text-center" role="alert">
    {{ 'SECURITY.USERS.SEARCH_RESULT_NOT_FOUND' | translate }}
    <a
      *ngIf="[Claims.CREATE_ORGANIZATION_USER] | authorize | async"
      routerLink="/security/organization-users/create"
      class="btn btn-raised btn-success round"
    >
      <i class="fa fa-plus"></i>
      <span class="mx-1">{{ 'SHARED.CONFIRM.ADD' | translate }}</span>
    </a>
  </div>
</ng-template>
<!-- no items template ends -->
