import { ActionReducerMap } from '@ngrx/store';
import { LookupsState } from 'lookups/store/states';
import { assetCategoryReducer } from 'lookups/store/reducers/asset-category.reducers';
import { assetTypeReducer } from 'lookups/store/reducers/asset-type.reducers';
import { assetExclusionReasonReducer } from 'lookups/store/reducers/asset-exclusion-reason.reducers';
import { assetYearsOfUseReducer } from 'lookups/store/reducers/asset-years-of-use.reducers';
import { customerEngagementTypeReducer } from 'lookups/store/reducers/customer-engagement-type.reducers';
import { identityTypeReducer } from 'lookups/store/reducers/identity-type.reducers';
import { nationalityReducer } from 'lookups/store/reducers/nationality.reducers';
import { paymentDurationReducer } from 'lookups/store/reducers/payment-duration.reducers';
import { pettyCashSpecificationReducer } from 'lookups/store/reducers/petty-cash-specification.reducers';
import { pettyCashTypeReducer } from 'lookups/store/reducers/petty-cash-type.reducers';
import { pettyCashRefundRequestTypeReducer } from 'lookups/store/reducers/petty-cash-refund-request-type.reducers';
import { purchaseOrderReceiveTypeReducer } from 'lookups/store/reducers/purchase-order-receive-type.reducers';
import { purchaseOrderTypeReducer } from 'lookups/store/reducers/purchase-order-type.reducers';
import { purchaseRequestReceiveTypeReducer } from 'lookups/store/reducers/purchase-request-receive-type.reducers';
import { purchaseRequestTypeReducer } from 'lookups/store/reducers/purchase-request-type.reducers';
import { customerCategoryReducer } from 'lookups/store/reducers/customer-category.reducers';
import { primaryAccountsReducer } from 'lookups/store/reducers/primary-accounts.reducers';
import { outgoingStockTypeReducer } from 'lookups/store/reducers/outgoing-stock-type.reducers';
import { incomingStockTypeReducer } from 'lookups/store/reducers/incoming-stock-type.reducers';
import { cityReducer } from 'lookups/store/reducers/city.reducers';
import { journalTypeReducer } from 'lookups/store/reducers/journal-type.reducers';
import { customerLogTypeReducer } from 'lookups/store/reducers/customer-log-type.reducers';
import { vendorLogTypeReducer } from 'lookups/store/reducers/vendor-log-type.reducers';
import { vendorPaymentLogTypeReducer } from 'lookups/store/reducers/vendor-payment-log-type.reducers';
import { vendorAdvancePaymentLogTypeReducer } from 'lookups/store/reducers/vendor-advance-payment-log-type.reducers';
import { customerPaymentLogTypeReducer } from 'lookups/store/reducers/customer-payment-log-type.reducers';
import { customerAdvancePaymentLogTypeReducer } from 'lookups/store/reducers/customer-advance-payment-log-type.reducers';
import { locationTypeReducer } from 'lookups/store/reducers/location-type.reducers';
import { bankAccountLogTypeReducer } from 'lookups/store/reducers/bank-account-log-type.reducers';
import { languagesReducer } from 'lookups/store/reducers/languages.reducers';
import { maintenanceStageReducer } from 'lookups/store/reducers/maintenance-stage.reducers';
import { saleInvoiceTypeReducer } from 'lookups/store/reducers/sale-invoice-type.reducers';
import { saleInvoiceOrderTypeReducer } from 'lookups/store/reducers/sale-invoice-order-type.reducers';
import { purchaseInvoiceTypeReducer } from 'lookups/store/reducers/purchase-invoice-type.reducers';
import { variantCategoryReducer } from 'lookups/store/reducers/variant-category.reducers';
import { timeZoneReducer } from 'lookups/store/reducers/time-zone.reducers';
import { PrintPaperSizeReducer } from 'lookups/store/reducers/print-paper-size.reducers';
import { CountryReducer } from 'lookups/store/reducers/country.reducers';
import { PhoneTypeReducer } from 'lookups/store/reducers/phone-type.reducers';
import { EmailTypeReducer } from 'lookups/store/reducers/email-type.reducers';
import { MeridiemIndicatorReducer } from 'lookups/store/reducers/meridiem-indicator.reducers';
import { PersonTitleReducer } from 'lookups/store/reducers/person-title.reducers';
import { GenderReducer } from 'lookups/store/reducers/gender.reducers';
import { ContractDurationReducer } from 'lookups/store/reducers/contract-duration.reducers';
import { WorkTypeReducer } from 'lookups/store/reducers/work-type.reducers';
import { ContractTypeReducer } from 'lookups/store/reducers/contract-type.reducers';
import { ProbationPeriodReducer } from 'lookups/store/reducers/probation-period.reducers';
import { NoticePeriodReducer } from 'lookups/store/reducers/notice-period.reducers';
import { nationalIdentifierTypeReducer } from 'lookups/store/reducers/national-identifier-type.reducers';
import { maritalStatusReducer } from 'lookups/store/reducers/marital-status.reducers';
import { religionReducer } from 'lookups/store/reducers/religion.reducers';
import { educationLevelReducer } from 'lookups/store/reducers/education-level.reducers';
import { visaTypeReducer } from 'lookups/store/reducers/visa-type.reducers';
import { managerialLevelReducer } from 'lookups/store/reducers/managerial-level.reducers';
import { departmentTypeReducer } from 'lookups/store/reducers/department-type.reducers';
import { departmentCategoryReducer } from 'lookups/store/reducers/department-category.reducers';
import { dependentTypeReducer } from 'lookups/store/reducers/dependent-type.reducers';
import { leadStatusReducer } from 'lookups/store/reducers/lead-status.reducers';
import { positionTypeReducer } from 'lookups/store/reducers/position-type.reducers';
import { assignmentStatusReducer } from 'lookups/store/reducers/assignment-status.reducers';
import { employeePaymentMethodReducer } from 'lookups/store/reducers/employee-payment-method.reducers';
import { payrollElementClassificationReducer } from 'lookups/store/reducers/payroll-element-classification.reducers';
import { payrollElementTypeReducer } from 'lookups/store/reducers/payroll-element-type.reducers';
import { overtimeRateReducer } from 'lookups/store/reducers/overtime-rate.reducers';
import { quotationStatusReducer } from 'lookups/store/reducers/quotation-status.reducers';
import { annualLeaveReducer } from 'lookups/store/reducers/annual-leave.reducers';
import { sickLeaveTypeReducer } from 'lookups/store/reducers/sick-leave-type.reducers';
import { loanSettlementTypeReducer } from 'lookups/store/reducers/loan-settlement-type.reducers';
import { deductionTypeReducer } from 'lookups/store/reducers/deduction-type.reducers';
import { otherEarningTypeReducer } from 'lookups/store/reducers/other-earning-type.reducers';
import { payrollElementNatureReducer } from 'lookups/store/reducers/payroll-element-nature.reducers';
import { employeeDeductionTypeReducer } from 'lookups/store/reducers/employee-deduction-type.reducers';
import { leaveTypeReducer } from 'lookups/store/reducers/leave-type.reducers';
import { GosiRegTypeReducer } from 'lookups/store/reducers/gosi-reg-type.reducers';
import { gosiCoverageTypeReducer } from 'lookups/store/reducers/gosi-coverage-type.reducers';
import { endOfServiceReasonReducer } from 'lookups/store/reducers/end-of-service-reason.reducers';
import { annualLeaveLogTypeReducer } from 'lookups/store/reducers/annual-leave-log-type.reducers';
import { projectResourceTypeReducer } from 'lookups/store/reducers/project-resource-type.reducers';
import { projectResourceTypeSourceReducer } from 'lookups/store/reducers/project-resource-type-source.reducers';
import { projectStatusReducer } from 'lookups/store/reducers/project-status.reducers';
import { projectLogTypeReducer } from 'lookups/store/reducers/project-log-type.reducers';
import { projectRevenueTypeReducer } from 'lookups/store/reducers/project-revenue-type.reducers';
import { payrollStatusReducer } from 'lookups/store/reducers/payroll-status.reducers';
import { posPrinterAdjustmentTypeReducer } from 'lookups/store/reducers/pos-printer-adjustment-type.reducers';
import { loanPaymentTypeReducer } from 'lookups/store/reducers/loan-payment-type.reducers';
import { govServiceRequestStatusesReducer } from 'lookups/store/reducers/gov-service-request-statuses.reducers';
import { govServiceRequestPaymentMethodsReducer } from 'lookups/store/reducers/gov-service-request-payment-methods.reducers';

/**
 * The Lookups-Module store reducers.
 */
export const reducers: ActionReducerMap<LookupsState> = {
  assetCategories: assetCategoryReducer,
  assetTypes: assetTypeReducer,
  assetExclusionReasons: assetExclusionReasonReducer,
  assetYearsOfUses: assetYearsOfUseReducer,
  customerEngagementTypes: customerEngagementTypeReducer,
  identityTypes: identityTypeReducer,
  nationalities: nationalityReducer,
  paymentDurations: paymentDurationReducer,
  pettyCashSpecifications: pettyCashSpecificationReducer,
  pettyCashTypes: pettyCashTypeReducer,
  pettyCashRefundRequestTypes: pettyCashRefundRequestTypeReducer,
  purchaseOrderReceiveTypes: purchaseOrderReceiveTypeReducer,
  purchaseOrderTypes: purchaseOrderTypeReducer,
  purchaseRequestReceiveTypes: purchaseRequestReceiveTypeReducer,
  purchaseRequestTypes: purchaseRequestTypeReducer,
  customerCategories: customerCategoryReducer,
  primaryAccounts: primaryAccountsReducer,
  outgoingStockTypes: outgoingStockTypeReducer,
  incomingStockTypes: incomingStockTypeReducer,
  cities: cityReducer,
  journalTypes: journalTypeReducer,
  customerLogTypes: customerLogTypeReducer,
  vendorLogTypes: vendorLogTypeReducer,
  vendorPaymentLogTypes: vendorPaymentLogTypeReducer,
  vendorAdvancePaymentLogTypes: vendorAdvancePaymentLogTypeReducer,
  customerPaymentLogTypes: customerPaymentLogTypeReducer,
  customerAdvancePaymentLogTypes: customerAdvancePaymentLogTypeReducer,
  locationTypes: locationTypeReducer,
  bankAccountLogTypes: bankAccountLogTypeReducer,
  languages: languagesReducer,
  saleInvoiceTypes: saleInvoiceTypeReducer,
  saleInvoiceOrderTypes: saleInvoiceOrderTypeReducer,
  purchaseInvoiceTypes: purchaseInvoiceTypeReducer,
  maintenanceStages: maintenanceStageReducer,
  variantCategories: variantCategoryReducer,
  timeZones: timeZoneReducer,
  printPaperSizes: PrintPaperSizeReducer,
  countries: CountryReducer,
  phoneTypes: PhoneTypeReducer,
  emailTypes: EmailTypeReducer,
  meridiemIndicators: MeridiemIndicatorReducer,
  personTitles: PersonTitleReducer,
  genders: GenderReducer,
  contractDurations: ContractDurationReducer,
  workTypes: WorkTypeReducer,
  contractTypes: ContractTypeReducer,
  probationPeriods: ProbationPeriodReducer,
  noticePeriods: NoticePeriodReducer,
  nationalIdentifierTypes: nationalIdentifierTypeReducer,
  maritalStatuses: maritalStatusReducer,
  religions: religionReducer,
  educationLevels: educationLevelReducer,
  visaTypes: visaTypeReducer,
  dependentTypes: dependentTypeReducer,
  leadStatuses: leadStatusReducer,
  departmentTypes: departmentTypeReducer,
  departmentCategories: departmentCategoryReducer,
  managerialLevels: managerialLevelReducer,
  positionTypes: positionTypeReducer,
  assignmentStatuses: assignmentStatusReducer,
  employeePaymentMethods: employeePaymentMethodReducer,
  payrollElementClassifications: payrollElementClassificationReducer,
  payrollElementTypes: payrollElementTypeReducer,
  overtimeRates: overtimeRateReducer,
  quotationStatuses: quotationStatusReducer,
  annualLeaves: annualLeaveReducer,
  sickLeaveTypes: sickLeaveTypeReducer,
  loanSettlementTypes: loanSettlementTypeReducer,
  deductionTypes: deductionTypeReducer,
  otherEarningTypes: otherEarningTypeReducer,
  payrollElementNatures: payrollElementNatureReducer,
  employeeDeductionTypes: employeeDeductionTypeReducer,
  leaveTypes: leaveTypeReducer,
  gosiRegTypes: GosiRegTypeReducer,
  gosiCoverageTypes: gosiCoverageTypeReducer,
  endOfServiceReasons: endOfServiceReasonReducer,
  annualLeaveLogTypes: annualLeaveLogTypeReducer,
  projectResourceTypes: projectResourceTypeReducer,
  projectResourceTypeSources: projectResourceTypeSourceReducer,
  projectStatuses: projectStatusReducer,
  projectLogTypes: projectLogTypeReducer,
  projectRevenueTypes: projectRevenueTypeReducer,
  payrollStatuses: payrollStatusReducer,
  posPrinterAdjustmentTypes: posPrinterAdjustmentTypeReducer,
  loanPaymentTypes: loanPaymentTypeReducer,
  govServiceRequestStatuses: govServiceRequestStatusesReducer,
  govServiceRequestPaymentMethods: govServiceRequestPaymentMethodsReducer,
};
