import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-file-viewer',
  templateUrl: './file-viewer.component.html',
  styles: [],
})
export class FileViewerComponent {
  /**
   * Sets the url of file.
   */
  @Input() url: string;

  /**
   * Sets the id of file.
   */
  @Input() id: number;

  /**
   * Indicates whether the file is able to be deleted or not.
   */
  @Input() allowDelete: boolean;

  /**
   * Indicates whether the file is able to be updated or not.
   */
  @Input() allowUpdate: boolean;

  /**
   * Outputs the id of the file to be deleted.
   */
  @Output() delete = new EventEmitter<number>();

  /**
   * Outputs the file to be updated.
   */
  @Output() update = new EventEmitter<string>();

  /**
   * Determines if the current selected file is an image.
   */
  get isImage(): boolean {
    const tokens = this.url?.split('/');
    return tokens[tokens.length - 1 ?? 0]?.search(/\.(jpg|jpeg|png|gif|btm)/i) > 0;
  }

  /**
   * Determines if the current selected file is a pdf file.
   */
  get isPdf(): boolean {
    const tokens = this.url?.split('/');
    return tokens[tokens.length - 1 ?? 0]?.search(/\.(pdf)/i) > 0;
  }

  /**
   * Determines if the current selected file is a word file.
   */
  get isMSWord(): boolean {
    const tokens = this.url?.split('/');
    return tokens[tokens.length - 1 ?? 0]?.search(/\.(docx|doc|docm|dot|dotm|dotx)/i) > 0;
  }

  /**
   * Determines if the current selected file is a excel file.
   */
  get isMSExcel(): boolean {
    const tokens = this.url?.split('/');
    return tokens[tokens.length - 1 ?? 0]?.search(/\.(csv|xla|xlam|xls|xlsb|xlsm|xlsx)/i) > 0;
  }

  /**
   * Determines if the current selected file is a text file.
   */
  get isText(): boolean {
    const tokens = this.url?.split('/');
    return tokens[tokens.length - 1 ?? 0]?.search(/\.(txt)/i) > 0;
  }

  /**
   * Determines if the current selected file is an archive file.
   */
  get isArchive(): boolean {
    const tokens = this.url?.split('/');
    return tokens[tokens.length - 1 ?? 0]?.search(/\.(rar|zip)/i) > 0;
  }

  /**
   * Determines if the current selected file is an unknown to us file.
   */
  get isUnknownType(): boolean {
    return (
      this.url && !this.isImage && !this.isPdf && !this.isMSWord && !this.isMSExcel && !this.isText && !this.isArchive
    );
  }

  get fileName(): string {
    const tokens = this.url?.split('/');
    return tokens[tokens.length - 1 ?? 0];
  }

  /**
   * Deletes the current file.
   */
  deleteFile() {
    this.delete.emit(this.id);
  }

  /**
   * Update the current file.
   */
  updateFile() {
    this.update.emit(this.url);
  }
}
