import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';

import { Observable, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Store, select } from '@ngrx/store';

import * as fromSalesStore from 'sales/store';
import * as fromAuthStore from 'auth/store';
import { NotificationService, PaginationInfo, TranslationService } from 'shared';
import { Shift } from 'sales/models';
import { User } from 'security/models';
import { Location } from 'stores/models';

/**
 * A Shift search form wrapped in modal.
 */
@Component({
  selector: 'app-shifts-search',
  templateUrl: './shifts-search.component.html',
  styles: [],
})
export class ShiftsSearchComponent implements OnInit, OnDestroy {
  /**
   * The modal template reference.
   */
  @ViewChild('modalRef') modalRef: ElementRef<any>;

  /**
   * Indicates whether the modal will automatically close after select the first shift.
   *
   * Be default it will not be closed.
   */
  @Input() closeOnSelect = false;

  /**
   * Indicates whether the shifts displayed on the list should be all active.
   *
   * Be default it will not be closed.
   */
  @Input() onlyActive = false;

  /**
   * Sets the visibility of this component modal.
   *
   * Handles visibility change in two-data-binding approach.
   */
  @Input() set visible(isShown: boolean) {
    if (isShown && this.modalRef) {
      this.openModal(this.modalRef);
    } else {
      this.closeModal();
    }

    this.visibleChange.emit(isShown);
  }

  /**
   * Outputs a value that indicates whether the modal is shown or not.
   *
   * Handles visibility change in two-data-binding approach.
   */
  @Output() visibleChange = new EventEmitter<boolean>();

  /** Outputs the selected shifts. */
  @Output() shifts = new EventEmitter<Shift[]>();

  /**
   * Shows or hide the list users modal.
   */
  usersListVisibility = false;

  /**
   * Shows or hide the locations list.
   */
  locationsListVisibility = false;

  /**
   * The list of selected locations.
   */
  locations: Location[] = [];

  /**
   * The current logged in user id.
   */
  currentLoggedInUserId: number;

  /**
   * The list of shifts.
   */
  shifts$: Observable<Shift[]>;

  /**
   * The list of shifts.
   */
  shiftsList: Shift[];

  /**
   * The list of selected organization users.
   */
  organizationUsers: User[] = [];

  /** The initial `fromDate` value for search. */
  initialFromDate = new Date();

  /** The initial `toDate` value for search.
   * It's the current day of month.
   */
  initialToDate = new Date();

  /**
   * The pagination info.
   */
  paginationInfo$: Observable<PaginationInfo>;

  /**
   * Indicates whether there is a search process is running or not.
   */
  isSearching$: Observable<boolean>;

  /**
   * The search form.
   */
  searchForm: FormGroup;

  /**
   * The set of subscriptions on this components,
   * these subscriptions must be unsubscribed before this component got destroyed.
   */
  subscriptions = new Subscription();

  /**
   * The ngb-modal reference to use it when modal needed to be closed.
   */
  modal: NgbModalRef;

  /**
   * @param modalService The modal service.
   * @param notificationService The notification service.
   * @param translationService The translation service.
   * @param authStore$ The auth-module store.
   */
  constructor(
    private modalService: NgbModal,
    private notificationService: NotificationService,
    private salesStore$: Store<fromSalesStore.SalesState>,
    private translationService: TranslationService,
    private authStore$: Store<fromAuthStore.AuthState>
  ) {}

  ngOnInit(): void {
    this.init();
  }

  /**
   * Destroy component data
   */
  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  /**
   * Initialize component data.
   */
  init() {
    this.initForm();

    /** Load current logged in user data. */
    this.subscriptions.add(
      this.authStore$
        .pipe(
          select(fromAuthStore.getSessionUser),
          tap((user: User) => {
            this.currentLoggedInUserId = user.id;
          })
        )
        .subscribe()
    );

    /**
     * Loads data.
     */
    let isManualSearchTriggeredBeforeForShifts = false;
    this.shifts$ = this.salesStore$.pipe(
      select(fromSalesStore.getShifts),
      tap((shifts) => {
        if (shifts) {
          this.shiftsList = shifts;
        }
        if (!isManualSearchTriggeredBeforeForShifts && !shifts.length) {
          isManualSearchTriggeredBeforeForShifts = true;
          this.search();
        }
      })
    );

    /* Subscribe to selected location to select it whenever it changed. */
    this.subscriptions.add(
      this.authStore$
        .pipe(
          select(fromAuthStore.getSessionUserDefaultLocation),
          tap((location) => {
            if (location && !this.searchForm.value.locations.includes(location.id)) {
              this.selectLocations([location]);
            }
          })
        )
        .subscribe()
    );

    this.paginationInfo$ = this.salesStore$.pipe(select(fromSalesStore.getMyShiftsPaginationInfo));
    this.isSearching$ = this.salesStore$.pipe(select(fromSalesStore.getMyShiftsSearching));
  }

  /**
   * Initialize form and add validators.
   */
  initForm() {
    this.searchForm = new FormGroup({
      users: new FormControl([]),
      locations: new FormControl([]),
      fromDate: new FormControl({
        year: this.initialFromDate.getFullYear(),
        month: this.initialFromDate.getMonth() + 1,
        day: this.initialFromDate.getDate(),
      }),
      toDate: new FormControl({
        year: this.initialToDate.getFullYear(),
        month: this.initialToDate.getMonth() + 1,
        day: this.initialToDate.getDate(),
      }),
      page: new FormControl(1),
    });
  }

  /**
   * Handles search parameters change.
   */
  search(event?: KeyboardEvent) {
    if (event && event.key === 'Enter') {
      event.preventDefault();
      return;
    }

    this.applyFiltersAndSearch();
  }

  /**
   * Handles pagination page-changed event.
   * @param page The current selected page number.
   */
  pageChanged(page: number) {
    /** Update pagination page. */
    this.searchForm.patchValue({ page });

    /** Get selected page items. */
    this.applyFiltersAndSearch(page);
  }

  /**
   * Applies the search filters and dispatches a search action.
   * @param page The current page number, default to `1`.
   */
  applyFiltersAndSearch(page: number = 1) {
    if (!this.searchForm.get('fromDate')?.value || !this.searchForm.get('toDate')?.value) {
      return this.notificationService.warning(
        this.translationService.translate('SALES.SHIFTS.NOTIFICATION_MESSAGE.DATE_SELECT')
      );
    }

    const { fromDate, toDate, users, locations } = this.searchForm.value;

    const fromDateFormatted = new Date(fromDate.year, fromDate.month - 1, fromDate.day);
    fromDateFormatted.setHours(0, 0, 0, 0);

    const toDateFormatted = new Date(toDate.year, toDate.month - 1, toDate.day);
    toDateFormatted.setHours(23, 59, 59, 0);

    this.salesStore$.dispatch(
      new fromSalesStore.SearchShifts({
        users,
        locations,
        fromDate: fromDateFormatted,
        toDate: toDateFormatted,
        page,
      })
    );
  }

  /**
   * Enables trackBy feature for *ngFor utility to track items
   * depending on a comparer to reduce DOM change.
   * If the item is already exist then no HTML will change, only bounded values.
   * @param index The index of the item.
   * @param shift The item to determine if it was changed or not.
   */
  trackItems(index: number, shift: Shift) {
    return shift ? shift.id : undefined;
  }

  /**
   * Adds the newly selected users the user search list.
   * @param users The list of newly selected organization users to be added.
   */
  selectUsers(users: User[]) {
    const selectedIds: number[] = this.searchForm.get('users').value;
    this.organizationUsers = [...this.organizationUsers.filter((user) => selectedIds.includes(user.id)), ...users];
    this.searchForm.patchValue({ users: this.organizationUsers.map((user) => user.id) });
  }

  /**
   * Adds the newly selected locations the locations search list.
   * @param locations The list of newly selected locations to be added.
   */
  selectLocations(locations: Location[]) {
    const selectedIds: number[] = this.searchForm.get('locations').value;
    this.locations = [...this.locations.filter((location) => selectedIds.includes(location.id)), ...locations];
    this.searchForm.patchValue({ locations: this.locations.map((location) => location.id) });
  }

  /**
   * Opens the modal of the given templateRef.
   * @param modalRef The modal templateRef to be opened.
   */
  openModal(modalRef) {
    /**
     * Open the modal & listen for user press ESC key.
     */
    this.modal = this.modalService.open(modalRef, { size: 'lg' });
    this.modal.result.then(null, () => this.closeModal());
  }

  /**
   * Closes the currently opened modal.
   */
  closeModal() {
    this.modal?.dismiss();
    this.shifts.emit([]);
    this.visibleChange.emit(false);
  }

  /**
   * Handles shifts selected event.
   * @param shifts The list of shifts to notify selected.
   */
  selectShift(shifts: Shift[]) {
    this.notificationService.successInstant(
      this.translationService.translate('SALES.SHIFTS.NOTIFICATION_MESSAGE.SHIFT_SELECTED')
    );
    this.shifts.emit(shifts);
    if (this.closeOnSelect) {
      this.closeModal();
    }
  }
}
