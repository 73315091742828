import { Component, Input, ViewChild, ElementRef } from '@angular/core';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';

import * as fromSalesStore from 'sales/store';
import { SaleInvoicePayment, SaleInvoice, CustomerLogAttachment } from 'sales/models';
import { Claims } from 'security/models';

@Component({
  selector: 'app-sale-invoice-payment-history',
  templateUrl: './sale-invoice-payment-history.component.html',
  styles: [],
})
export class SaleInvoicePaymentHistoryComponent {
  /**
   * The create modal template reference.
   */
  @ViewChild('createModalRef') createModalRef: ElementRef<any>;

  /**
   * The view payment attachments modal template reference.
   */
  @ViewChild('viewAttachmentsModalRef') viewAttachmentsModalRef: ElementRef<any>;

  /**
   * Sets the id of the current viewed invoice.
   */
  @Input() invoiceId: number;

  /**
   * Sets the current viewed invoice.
   */
  @Input() invoice: SaleInvoice;

  /**
   * The system supported user claims.
   */
  Claims = Claims;

  /**
   * The selected sale invoice payment;
   */
  selectedPayment: SaleInvoicePayment;

  /**
   * The set of subscriptions on this components,
   * these subscriptions must be unsubscribed before this component got destroyed.
   */
  subscriptions = new Subscription();

  /**
   * @param modalService The modal service.
   * @param salesStore$ Sales-module store.
   */
  constructor(private modalService: NgbModal, private salesStore$: Store<fromSalesStore.SalesState>) {}

  /**
   * Loads the current selected invoice payment history.
   */
  search() {
    this.salesStore$.dispatch(new fromSalesStore.FindSaleInvoice(this.invoiceId));
  }

  /**
   * Enables trackBy feature for *ngFor utility to track items
   * depending on a comparer to reduce DOM change.
   * If the item is already exist then no HTML will change, only bounded values.
   * @param index The index of the item.
   * @param payment The item to determine if it was changed or not.
   */
  trackItems(index: number, payment: SaleInvoicePayment) {
    return payment ? payment.id : undefined;
  }

  /**
   * Views the selected payment attachments.
   * @param payment The payment to view its attachments.
   */
  viewAttachments(payment: SaleInvoicePayment) {
    this.selectedPayment = payment;
    this.openModal(this.viewAttachmentsModalRef);
  }

  /**
   * Maps and returns the set of sale invoice payment attachments into a set of urls.
   * @param attachments The sale invoice payment attachments to be mapped.
   */
  getAttachmentsUrls(attachments: CustomerLogAttachment[]): string[] {
    return attachments ? attachments.map((attachment) => attachment.url) : [];
  }

  /**
   * Opens the modal of the given templateRef.
   * @param modalRef The modal templateRef to be opened.
   */
  openModal(modalRef) {
    this.modalService.open(modalRef, { size: 'xl' });
  }

  /**
   * Closes the currently opened modal.
   */
  closeModal() {
    this.modalService.dismissAll();
  }
}
