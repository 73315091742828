/**
 * Represents a customer engagement type.
 */
export class CustomerEngagementType {
  /**
   * Gets or sets the id of the customer engagement type.
   */
  id: number;

  /**
   * Gets or sets the name of the customer engagement type.
   */
  name: string;

  /**
   * Gets or sets the English name of the customer engagement type.
   */
  nameEn: string;
}
