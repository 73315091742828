// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { EnvironmentConfig } from './environment-config.model';
import { environment as originalEnvironment } from './environment.original';

export const environment: EnvironmentConfig = {
  /**
   * Copy all environment settings from the original environment object.
   * Then override any specific settings.
   */
  ...originalEnvironment,
  allowDeveloperMode: true,
  production: false,
  // apiUrl: 'https://app.soultech.ae',
  // reportingServiceApiUrl: 'http://report.soultech.ae',
  // websocketUrl: 'wss://dev-ws.erp.soultech.ae',
  apiUrl: 'https://app.soultech.ae',
  reportingServiceApiUrl: 'http://report.soultech.ae',
  websocketUrl: 'wss://dev-ws.erp.soultech.ae',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
