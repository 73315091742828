import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { SharedModule } from 'shared';
import { SettingsRoutingModule } from 'settings/settings-routing.module';

import * as fromComponents from 'settings/components';
import * as fromServices from 'settings/services';
import * as fromStore from 'settings/store';

@NgModule({
  declarations: [...fromComponents.COMPONENTS],
  imports: [
    CommonModule,
    SharedModule,
    SettingsRoutingModule,
    StoreModule.forFeature('settings', fromStore.reducers),
    EffectsModule.forFeature(fromStore.effects),
  ],
  exports: [],
  providers: [...fromServices.SERVICES],
})
export class SettingsModule {
  /**
   * Register all of services belong to settings module into the module that calls this `forRoot()` method.
   */
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: SettingsModule,
      providers: [...fromServices.SERVICES],
    };
  }
}
