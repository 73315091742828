import { Action } from '@ngrx/store';

import { PettyCashType } from 'lookups/models';
import { AppHttpResponse } from 'shared';

/**
 * The Lookups-Module store  petty cash type action types.
 */
export enum PettyCashTypeActionType {
  GET_ALL_PETTY_CASH_TYPE = '[Lookups] [PettyCashType] Get All Petty Cash Types',
  GET_ALL_PETTY_CASH_TYPE_FAIL = '[Lookups] [PettyCashType] Get All Petty Cash Types Fail',
  GET_ALL_PETTY_CASH_TYPE_SUCCESS = '[Lookups] [PettyCashType] Get All Petty Cash Types Success',
}

/**
 * Represents a store  petty cash type action.
 */
export class GetAllPettyCashType implements Action {
  /**
   * The type of the action.
   */
  readonly type = PettyCashTypeActionType.GET_ALL_PETTY_CASH_TYPE;

  /**
   * Fires a new  petty cash type action.
   */
  constructor() {}
}

/**
 * Represents a store  petty cash type fail action.
 */
export class GetAllPettyCashTypeFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = PettyCashTypeActionType.GET_ALL_PETTY_CASH_TYPE_FAIL;

  /**
   * Fires a new  petty cash type fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store  petty cash type success action.
 */
export class GetAllPettyCashTypeSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = PettyCashTypeActionType.GET_ALL_PETTY_CASH_TYPE_SUCCESS;

  /**
   * Fires a  petty cash type success action.
   * @param payload An object contains the list of  petty cash types.
   */
  constructor(public payload: AppHttpResponse<PettyCashType[]>) {}
}

/**
 * Lookups-module  petty cash type action types.
 */
export type PettyCashTypeActions = GetAllPettyCashType | GetAllPettyCashTypeFail | GetAllPettyCashTypeSuccess;
