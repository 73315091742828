import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipe } from '@angular/common';

import { APP_CONSTANTS } from 'shared/models';

/**
 * Adds custom formats for currency.
 */
@Pipe({
  name: 'currency',
})
export class CurrencyFormatPipe extends CurrencyPipe implements PipeTransform {
  transform(value: any, ...args: any[]): any {
    return super.transform(
      value,
      APP_CONSTANTS.currency.code,
      APP_CONSTANTS.currency.symbol,
      APP_CONSTANTS.currency.digitsFormat,
      APP_CONSTANTS.currency.locale
    );
  }
}
