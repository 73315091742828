import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-files-viewer',
  templateUrl: './files-viewer.component.html',
  styles: [],
})
export class FilesViewerComponent {
  /**
   * Sets the list of files urls.
   */
  @Input() files: string[] = [];

  /**
   * Sets the list of files ids.
   */
  @Input() ids: number[] = [];

  /**
   * Indicates whether the file is able to be deleted or not.
   */
  @Input() allowDelete: boolean;

  /**
   * Outputs the id of the file to be deleted.
   */
  @Output() delete = new EventEmitter<number>();

  /**
   * Deletes the file of the given id.
   * @param id The id of the file to be deleted.
   */
  deleteFile(id: number) {
    this.delete.emit(id);
  }
}
