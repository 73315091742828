import { ProjectsService } from 'projects-management/services/projects.service';
import { ProjectResourcesService } from 'projects-management/services/project-resources.service';

export * from 'projects-management/services/projects.service';
export * from 'projects-management/services/project-resources.service';

/**
 * The set of services registered on this module.
 */
export const SERVICES: any[] = [ProjectsService, ProjectResourcesService];
