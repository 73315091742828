import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';

import { AppHttpResponse } from 'shared';
import { Vendor } from 'purchases/models';

/**
 * The vendors services includes functionality to search vendors.
 */

@Injectable()
export class VendorsService {
  /**
   * The relative route for the vendors.
   *
   * No leading or trailing slashes required.
   */
  private vendorsApi = 'purchases/vendors';

  constructor(private http: HttpClient) {}

  /**
   * Searches in the vendors by name.
   * @param name The name of the vendor.
   * @param page The current pagination page number.
   * @param pageSize The maximum number of vendors allowed per one pagination page.
   */
  public search(name: string, page: number, pageSize: number): Observable<AppHttpResponse<Vendor[]>> {
    const params = new HttpParams().set('name', name).set('page', page.toString()).set('pageSize', pageSize.toString());
    return this.http.get<AppHttpResponse<Vendor[]>>(`${this.vendorsApi}`, { params });
  }
}
