import { of } from 'rxjs';
import { switchMap, map, catchError, tap, debounceTime } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Effect, ofType, Actions } from '@ngrx/effects';

import { NotificationService, StatusCode, PAGINATION, TranslationService } from 'shared';
import { TaxesService } from 'settings/services';
import {
  TaxesActionType,
  SearchTaxes,
  SearchTaxesFail,
  SearchTaxesSuccess,
  CreateTax,
  CreateTaxSuccess,
  CreateTaxFail,
  UpdateTax,
  UpdateTaxSuccess,
  UpdateTaxFail,
  DeleteTax,
  DeleteTaxSuccess,
  DeleteTaxFail,
  FindTax,
  FindTaxSuccess,
  FindTaxFail,
  FindDefaultTaxSuccess,
  FindDefaultTaxFail,
  FindDefaultTax,
} from 'settings/store/actions';

@Injectable()
export class TaxesEffects {
  constructor(
    private actions$: Actions,
    private taxesService: TaxesService,
    private notificationService: NotificationService,
    private translationService: TranslationService
  ) {}

  /* ========================= SEARCH_TAXES =================================== */
  @Effect()
  search$ = this.actions$.pipe(
    ofType(TaxesActionType.SEARCH_TAXES),
    debounceTime(300),
    switchMap(({ payload }: SearchTaxes) =>
      this.taxesService.search(payload?.name ?? '', payload.page, PAGINATION.Taxes).pipe(
        map((response) => new SearchTaxesSuccess(response)),
        catchError((error) => of(new SearchTaxesFail(error)))
      )
    )
  );

  /* ========================= FIND_TAX =================================== */
  @Effect()
  find$ = this.actions$.pipe(
    ofType(TaxesActionType.FIND_TAX),
    switchMap((action: FindTax) =>
      this.taxesService.findById(action.payload).pipe(
        map((response) => new FindTaxSuccess(response)),
        catchError((error) => of(new FindTaxFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  findFail$ = this.actions$.pipe(
    ofType(TaxesActionType.FIND_TAX_FAIL),
    tap((action: FindTaxFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(this.translationService.translate('SETTINGS.TAXES.TAX_NOT_FOUND'));
      }
    })
  );

  /* ========================= FIND_DEFAULT_TAX =================================== */
  @Effect()
  findDefaultTax$ = this.actions$.pipe(
    ofType(TaxesActionType.FIND_DEFAULT_TAX),
    switchMap((action: FindDefaultTax) =>
      this.taxesService.findDefaultTax().pipe(
        map((response) => new FindDefaultTaxSuccess(response)),
        catchError((error) => of(new FindDefaultTaxFail(error)))
      )
    )
  );

  /* ========================= CREATE_TAX =================================== */
  @Effect()
  create$ = this.actions$.pipe(
    ofType(TaxesActionType.CREATE_TAX),
    switchMap((action: CreateTax) =>
      this.taxesService.create(action.payload).pipe(
        map((response) => new CreateTaxSuccess(response)),
        catchError((error) => of(new CreateTaxFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  createSuccess$ = this.actions$.pipe(
    ofType(TaxesActionType.CREATE_TAX_SUCCESS),
    tap((action: CreateTaxSuccess) => {
      this.notificationService.success(this.translationService.translate('SETTINGS.TAXES.TAX_ADDED'));
    })
  );

  @Effect({ dispatch: false })
  createFail$ = this.actions$.pipe(
    ofType(TaxesActionType.CREATE_TAX_FAIL),
    tap((action: CreateTaxFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );

  /* ========================= UPDATE_TAX =================================== */
  @Effect()
  update$ = this.actions$.pipe(
    ofType(TaxesActionType.UPDATE_TAX),
    switchMap((action: UpdateTax) =>
      this.taxesService.update(action.payload).pipe(
        map((response) => new UpdateTaxSuccess(response)),
        catchError((error) => of(new UpdateTaxFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  updateSuccess$ = this.actions$.pipe(
    ofType(TaxesActionType.UPDATE_TAX_SUCCESS),
    tap((action: UpdateTaxSuccess) => {
      this.notificationService.success(this.translationService.translate('SETTINGS.TAXES.TAX_UPDATED'));
    })
  );

  @Effect({ dispatch: false })
  updateFail$ = this.actions$.pipe(
    ofType(TaxesActionType.UPDATE_TAX_FAIL),
    tap((action: UpdateTaxFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(this.translationService.translate('SETTINGS.TAXES.TAX_NOT_FOUND'));
      } else if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );

  /* ========================= DELETE_TAX =================================== */
  @Effect()
  delete$ = this.actions$.pipe(
    ofType(TaxesActionType.DELETE_TAX),
    switchMap((action: DeleteTax) =>
      this.taxesService.delete(action.payload).pipe(
        map((response) => new DeleteTaxSuccess(response)),
        catchError((error) => of(new DeleteTaxFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  deleteSuccess$ = this.actions$.pipe(
    ofType(TaxesActionType.DELETE_TAX_SUCCESS),
    tap((action: DeleteTaxSuccess) => {
      this.notificationService.success(this.translationService.translate('SETTINGS.TAXES.TAX_DELETED'));
    })
  );

  @Effect({ dispatch: false })
  deleteFail$ = this.actions$.pipe(
    ofType(TaxesActionType.DELETE_TAX_FAIL),
    tap((action: DeleteTaxFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(this.translationService.translate('SETTINGS.TAXES.TAX_NOT_FOUND'));
      } else if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );
}
