import { Component, Input, OnDestroy, OnInit } from '@angular/core';

import { Observable, Subscription } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { skip, tap } from 'rxjs/operators';

import { NotificationService, PrintService, TranslationService } from 'shared';
import { Journal } from 'finances/models';
import { PrintPaperSize } from 'lookups/models';
import * as fromPrintingStore from 'printing/store';
import * as fromLookupsStore from 'lookups/store';

@Component({
  selector: 'app-view-journal',
  templateUrl: './view-journal.component.html',
  styles: [],
})
export class ViewJournalComponent implements OnInit, OnDestroy {
  /**
   * Sets the current viewed journal.
   */
  @Input() journal: Journal;

  /**
   * The set of system supported print paper sizes.
   */
  printPaperSizes$: Observable<PrintPaperSize[]>;

  /**
   * The set of subscriptions on this components,
   * these subscriptions must be unsubscribed before this component got destroyed.
   */
  subscriptions = new Subscription();

  /**
   * @param printService The print service.
   */
  constructor(
    private printService: PrintService,
    private printingStore$: Store<fromPrintingStore.PrintingState>,
    private notificationService: NotificationService,
    private translationService: TranslationService,
    private lookupsStore$: Store<fromLookupsStore.LookupsState>
  ) {}

  ngOnInit(): void {
    this.init();
  }

  /**
   * Destroy component data
   */
  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  /**
   * Initialize component data.
   */
  init() {
    /**
     * Print the transaction once it is ready.
     */
    this.subscriptions.add(
      this.printingStore$
        .pipe(
          select(fromPrintingStore.getJournalPrint),
          skip(1),
          tap((report) => {
            if (report) {
              this.printService.printPDF(report.pdfUrl, (error, request) => {
                this.notificationService.error(this.translationService.translate('REPORTS.FAILED_TO_PRINT'));
              });
            }
          })
        )
        .subscribe()
    );

    let isManualSearchTriggeredBeforeForPrintPaperSizes = false;
    this.printPaperSizes$ = this.lookupsStore$.pipe(
      select(fromLookupsStore.getPrintPaperSizes),
      tap((printPaperSizes) => {
        if (!isManualSearchTriggeredBeforeForPrintPaperSizes && !printPaperSizes.length) {
          isManualSearchTriggeredBeforeForPrintPaperSizes = true;
          this.lookupsStore$.dispatch(new fromLookupsStore.GetAllPrintPaperSize());
        }
      })
    );
  }

  /**
   * Prints the current displayed transaction.
   * @param paperSize The paper size to be printed.
   */
  print(paperSize: string) {
    this.printingStore$.dispatch(
      new fromPrintingStore.GetJournalPrint({
        journalId: this.journal.id,
        paperSize,
      })
    );
  }
}
