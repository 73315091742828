import { Component, Input, forwardRef, HostBinding } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

/**
 * A custom form control for file upload and preview.
 */
@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FileUploadComponent),
      multi: true,
    },
  ],
})
export class FileUploadComponent implements ControlValueAccessor {
  /**
   * Gets or sets the id of the file input.
   * @internal
   */
  private internalId = '';

  /**
   * Gets or sets a safe resource url for the current file that this control holds.
   */
  previewUrl: SafeResourceUrl;

  /**
   * Indicates whether the control is enabled or disabled.
   * @default false enabled
   */
  isDisabled = false;

  /**
   * Gets or sets the id property set to this component host element.
   */
  @HostBinding('attr.id') externalId = '';

  /**
   * Gets or sets the id of the file input.
   * @example
   * <app-file-upload [id]="logo"></<app-file-upload>
   *
   * <!-- will output -->
   *
   * <input type="file" id="logo" />
   */
  @Input() set id(value: string) {
    this.internalId = value;
    this.externalId = null;
  }

  get id() {
    return this.internalId;
  }

  /**
   * Sets the current value that this control holds.
   */
  @Input() value: File;

  /**
   * Gets or sets the current file that this control holds.
   */
  get file(): File {
    return this.value;
  }

  set file(val) {
    this.value = val;
    this.onChange(val);
    this.onTouched();
  }

  /**
   * Determines if the current selected file is an image.
   */
  get isImage(): boolean {
    return this.file?.type.toLowerCase().includes('image');
  }

  /**
   * Determines if the current selected file is a pdf file.
   */
  get isPdf(): boolean {
    return this.file?.type.toLowerCase() === 'application/pdf';
  }

  /**
   * Determines if the current selected file is a word file.
   */
  get isMSWord(): boolean {
    return this.file?.type.toLowerCase().includes('word');
  }

  /**
   * Determines if the current selected file is a excel file.
   */
  get isMSExcel(): boolean {
    return this.file?.type.toLowerCase().includes('spreadsheet');
  }

  /**
   * Determines if the current selected file is a text file.
   */
  get isText(): boolean {
    return this.file?.type.toLowerCase() === 'text/plain';
  }

  /**
   * Determines if the current selected file is an archive file.
   */
  get isArchive(): boolean {
    return this.file?.type.toLowerCase().includes('rar') || this.file?.type.toLowerCase().includes('zip');
  }

  /**
   * Determines if the current selected file is an unknown to us file.
   */
  get isUnknownType(): boolean {
    return (
      this.file && !this.isImage && !this.isPdf && !this.isMSWord && !this.isMSExcel && !this.isText && !this.isArchive
    );
  }

  /**
   * @param domSanitizer The dom sanitizer service.
   */
  constructor(private domSanitizer: DomSanitizer) {}

  /**
   * The on control value change callback.
   */
  onChange: any = () => {};

  /**
   * The on control touched callback.
   */
  onTouched: any = () => {};

  /**
   * @description
   * Writes a new value to the element.
   *
   * This method is called by the forms API to write to the view when programmatic
   * changes from model to view are requested.
   *
   * @param value The new value for the element
   */
  writeValue(value: any): void {
    this.value = value;
  }

  /**
   * @description
   * Registers a callback function that is called when the control's value
   * changes in the UI.
   *
   * This method is called by the forms API on initialization to update the form
   * model when values propagate from the view to the model.
   *
   * When implementing the `registerOnChange` method in your own value accessor,
   * save the given function so your class calls it at the appropriate time.
   *
   * @param fn The callback function to register
   */
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  /**
   * @description
   * Registers a callback function is called by the forms API on initialization
   * to update the form model on blur.
   *
   * When implementing `registerOnTouched` in your own value accessor, save the given
   * function so your class calls it when the control should be considered
   * blurred or "touched".
   *
   * @param fn The callback function to register
   */
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  /**
   * @description
   * Function that is called by the forms API when the control status changes to
   * or from 'DISABLED'. Depending on the status, it enables or disables the
   * appropriate DOM element.
   *
   * @param isDisabled The disabled status to set on the element
   */
  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  /**
   * Opens file browser.
   * @param input The file input to be opened.
   * @summary The file input value is empty each time to allow select the same file again if it has been modified.
   */
  openBrowse(input: HTMLInputElement) {
    input.value = '';
    input.click();
  }

  /**
   * Handles selected file change event.
   * @param file The newly selected file to be uploaded.
   */
  onSelectedFileChanged(file: File) {
    if (!file) {
      return;
    }

    /**
     * Update control value.
     */
    this.file = file;

    /** Update preview url. */

    /**
     * The reader used to read selected file.
     */
    const reader = new FileReader();

    reader.onload = () => {
      /* Sanitize the file url. */
      this.previewUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(reader.result as string);
    };
    reader.readAsDataURL(file);
  }
}
