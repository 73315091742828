import { UsersService } from 'security/services/users.service';
import { ClaimsService } from 'security/services/claims.service';

export * from 'security/services/users.service';
export * from 'security/services/claims.service';

/**
 * The set of services registered on this module.
 */
export const SERVICES: any[] = [UsersService,ClaimsService ];
