import { Injectable } from '@angular/core';

import { of } from 'rxjs';
import { switchMap, map, catchError } from 'rxjs/operators';
import { Effect, ofType, Actions } from '@ngrx/effects';

import { LookupsService } from 'lookups/services';
import { JournalTypeActionType, GetAllJournalTypeFail, GetAllJournalTypeSuccess } from 'lookups/store/actions';

@Injectable()
export class JournalTypeEffects {
  constructor(private actions$: Actions, private lookupsService: LookupsService) {}

  /* ========================= GET_ALL_JOURNAL_TYPE =================================== */
  @Effect()
  getAllJournalTypes$ = this.actions$.pipe(
    ofType(JournalTypeActionType.GET_ALL_JOURNAL_TYPES),
    switchMap(() =>
      this.lookupsService.getAllJournalTypes().pipe(
        map((response) => new GetAllJournalTypeSuccess(response)),
        catchError((error) => of(new GetAllJournalTypeFail(error)))
      )
    )
  );
}
