import { FormControl, ValidatorFn } from '@angular/forms';

/**
 * Validates that the FormControl value -which is supposed to be an array-
 * has at least the given count of items.
 * @param minItemsCount The minimum items count allowed in the array.
 */
export function arrayItems(minItemsCount: number): ValidatorFn {
  return (control: FormControl): { [key: string]: any } | null => {
    const isValid =
      control.value && Array.isArray(control.value) && (control.value as Array<any>).length >= minItemsCount;
    return isValid ? null : { minItemsCount: { value: `Minimum items count should be ${minItemsCount}` } };
  };
}
