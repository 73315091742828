import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { FormGroup, FormArray, FormControl, Validators } from '@angular/forms';

import { Observable, BehaviorSubject, Subscription } from 'rxjs';
import { skip, tap } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Decimal } from 'decimal.js';

import { PageInfo, NotificationService, NotificationMessage, CustomValidators, TranslationService } from 'shared';
import * as fromFinancesStore from 'finances/store';
import { Account, CreateJournalLineInput, JournalLineFormItem } from 'finances/models';

@Component({
  selector: 'app-create-journal',
  templateUrl: './create-journal.component.html',
  styles: [],
})
export class CreateJournalComponent implements OnInit, OnDestroy {
  /**
   * The confirm modal template reference.
   */
  @ViewChild('confirmModalRef') confirmModalRef: ElementRef<any>;

  /**
   * Gets or sets the information about the current page.
   */
  pageInfo: PageInfo = {
    title: 'FINANCES.JOURNALS.CREATE_JOURNAL_PAGE_TITLE',
    icon: 'fa fa-user-plus',
  };

  /**
   * Indicates whether there is a create-request process is running or not.
   */
  isCreating$: Observable<boolean>;

  /**
   * The list of accounts.
   */
  accounts$: Observable<Account[]>;

  /**
   * The create employee form.
   */
  form: FormGroup;

  /**
   * The set of subscriptions on this components,
   * these subscriptions must be unsubscribed before this component got destroyed.
   */
  subscriptions = new Subscription();

  /**
   * Sets the initial journal lines should be added to the journals form.
   * @param journal lines An object contains `count` of initial blank journal lines.
   * @param journal lines A single journal line details to be added to the form.
   * @param journal lines A list of journal lines details to be added to the form.
   */
  initialJournalLinesSubject: BehaviorSubject<
    { count: number } | JournalLineFormItem | JournalLineFormItem[]
  > = new BehaviorSubject(undefined);

  /**
   * Gets the journal-lines form-array.
   */
  get journalLinesForm(): FormArray {
    return this.form?.controls.lines as FormArray;
  }

  /**
   * @param modalService The modal service.
   * @param notificationService The notification service.
   * @param financesStore$ The finance store.
   * @param translationService The translation service.
   */
  constructor(
    private modalService: NgbModal,
    private notificationService: NotificationService,
    private financesStore$: Store<fromFinancesStore.FinancesState>,
    private translationService: TranslationService
  ) {}

  ngOnInit(): void {
    this.init();
  }

  /**
   * Destroy component data
   */
  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  /**
   * Initialize component data.
   */
  init() {
    this.initForm();

    /**
     * Load data.
     */
    this.accounts$ = this.financesStore$.pipe(select(fromFinancesStore.getAccounts));
    this.isCreating$ = this.financesStore$.pipe(select(fromFinancesStore.getSelectedJournalCreating));

    /**
     * Reset form controls when new journal is created.
     */
    this.subscriptions.add(
      this.financesStore$
        .pipe(
          select(fromFinancesStore.getSelectedJournalCreateCompleted),
          skip(1),
          tap((isCreated) => {
            if (isCreated) {
              this.resetForm();
            }
          })
        )
        .subscribe()
    );
  }

  /**
   * Initialize form and add validators.
   */
  initForm() {
    this.form = new FormGroup({
      notes: new FormControl('', [Validators.minLength(0), Validators.maxLength(200)]),
      transactionDate: new FormControl(null),
      lines: new FormArray([], CustomValidators.arrayItems(2)),
    });
    this.setBlankJournalsCount();
  }

  /**
   * Sets the count of the journal lines should be added to the journals form for quick start..
   */
  setBlankJournalsCount() {
    this.initialJournalLinesSubject.next({ count: 2 });
  }

  /**
   * Submits the form.
   */
  submit() {
    const errorMessage = new NotificationMessage();

    if (this.form.invalid) {
      if (this.form.get('notes').invalid) {
        errorMessage.title = this.translationService.translate('FINANCES.JOURNALS.JOURNAL_DATA_VALIDATION.NOTES_ERROR');
        errorMessage.body = [
          this.translationService.translate('FINANCES.JOURNALS.JOURNAL_DATA_VALIDATION.NOTES_ERROR_IS_REQUIRED'),
          this.translationService.translate('FINANCES.JOURNALS.JOURNAL_DATA_VALIDATION.NOTES_ERROR_LENGTH_ERROR'),
        ];
      } else if (this.form.get('lines').invalid) {
        /**
         * Check if journal lines count < 2.
         */
        if (this.journalLinesForm.controls.length < 2) {
          errorMessage.title = this.translationService.translate(
            'FINANCES.JOURNALS.JOURNAL_DATA_VALIDATION.JOURNALS_LINES_ERROR'
          );
          errorMessage.body = [
            this.translationService.translate('FINANCES.JOURNALS.JOURNAL_DATA_VALIDATION.JOURNALS_LINES_COUNT_ERROR'),
          ];
        } else {
          /**
           * Check if some of journals has errors.
           */
          for (let index = 0; index < this.journalLinesForm.controls.length; index++) {
            const journal = this.journalLinesForm.controls[index];

            if (journal.valid) {
              continue;
            }

            errorMessage.title = this.translationService.translate(
              'FINANCES.JOURNALS.JOURNAL_DATA_VALIDATION.JOURNAL_LINE_NUMBER_ERROR',
              {
                journalNumber: index + 1,
              }
            );
            errorMessage.body = [];

            if (journal.get('accountId').invalid) {
              errorMessage.body.push(
                this.translationService.translate('FINANCES.JOURNALS.JOURNAL_DATA_VALIDATION.ACCOUNT_ERROR')
              );
              errorMessage.body.push(
                this.translationService.translate('FINANCES.JOURNALS.JOURNAL_DATA_VALIDATION.ACCOUNT_IS_REQUIRED')
              );
            }

            if (journal.get('credit').invalid) {
              errorMessage.body.push(
                this.translationService.translate('FINANCES.JOURNALS.JOURNAL_DATA_VALIDATION.CREDIT_IS_REQUIRED')
              );
            }

            if (journal.get('debit').invalid) {
              errorMessage.body.push(
                this.translationService.translate('FINANCES.JOURNALS.JOURNAL_DATA_VALIDATION.DEBIT_IS_REQUIRED')
              );
            }

            if (journal.get('costCenterId').invalid) {
              errorMessage.body.push(
                this.translationService.translate('FINANCES.JOURNALS.JOURNAL_DATA_VALIDATION.COST_CENTER_ERROR')
              );
              errorMessage.body.push(
                this.translationService.translate('FINANCES.JOURNALS.JOURNAL_DATA_VALIDATION.COST_CENTER_IS_REQUIRED')
              );
            }

            if (journal.get('notes').invalid) {
              errorMessage.body.push(
                this.translationService.translate('FINANCES.JOURNALS.JOURNAL_DATA_VALIDATION.NOTES_ERROR')
              );
              errorMessage.body.push(
                this.translationService.translate('FINANCES.JOURNALS.JOURNAL_DATA_VALIDATION.NOTES_IS_REQUIRED')
              );
              errorMessage.body.push(
                this.translationService.translate('FINANCES.JOURNALS.JOURNAL_DATA_VALIDATION.NOTES_LENGTH_ERROR')
              );
            }

            break;
          }
        }
      }

      this.form.markAllAsTouched();
      return this.notificationService.warningWithTitle(errorMessage);
    }

    const lines: CreateJournalLineInput[] = this.journalLinesForm.value;

    if (
      lines.some((line) => line.debit === 0 && line.credit === 0) ||
      lines.some((line) => line.debit !== 0 && line.credit !== 0)
    ) {
      errorMessage.title = this.translationService.translate(
        'FINANCES.JOURNALS.JOURNAL_DATA_VALIDATION.JOURNALS_LINES_TITLE_ERROR'
      );
      errorMessage.body = [
        this.translationService.translate('FINANCES.JOURNALS.JOURNAL_DATA_VALIDATION.DEBIT_CREDIT_ERROR'),
        this.translationService.translate('FINANCES.JOURNALS.JOURNAL_DATA_VALIDATION.JOURNALS_LINES_TYPE_ERROR'),
      ];

      this.form.markAllAsTouched();
      return this.notificationService.warningWithTitle(errorMessage);
    } else if (
      !Decimal.sum(0, ...lines.filter((line) => !!line.debit).map((line) => line.debit)).eq(
        Decimal.sum(0, ...lines.filter((line) => !!line.credit).map((line) => line.credit))
      )
    ) {
      errorMessage.title = this.translationService.translate(
        'FINANCES.JOURNALS.JOURNAL_DATA_VALIDATION.JOURNALS_LINES_TITLE_ERROR'
      );
      errorMessage.body = [
        this.translationService.translate('FINANCES.JOURNALS.JOURNAL_DATA_VALIDATION.JOURNALS_LINES_TOTAL_ERROR'),
        this.translationService.translate('FINANCES.JOURNALS.JOURNAL_DATA_VALIDATION.JOURNALS_LINES_TOTAL_VALUE_ERROR'),
      ];

      this.form.markAllAsTouched();
      return this.notificationService.warningWithTitle(errorMessage);
    } else {
      this.openModal(this.confirmModalRef);
    }
  }

  /**
   * Confirms the form submit.
   */
  confirm() {
    let fromDateFormatted = null;
    const { transactionDate } = this.form.value;

    if (transactionDate) {
      fromDateFormatted = new Date(transactionDate.year, transactionDate.month - 1, transactionDate.day);
      fromDateFormatted.setHours(new Date().getHours(), new Date().getMinutes());
    }
    /**
     * The list of lines in the lines form.
     */
    const lines = this.journalLinesForm.value.map((line: JournalLineFormItem) => {
      const inputLine: CreateJournalLineInput = {
        accountId: line.accountId,
        debit: line.debit,
        credit: line.credit,
        costCenterId: line.costCenterId,
        notes: line.notes,
      };

      return inputLine;
    });

    this.financesStore$.dispatch(
      new fromFinancesStore.CreateJournal({
        notes: this.form.value.notes,
        transactionDate: fromDateFormatted,
        lines,
      })
    );
  }

  /**
   * Opens the modal of the given templateRef.
   * @param modalRef The modal templateRef to be opened.
   */
  openModal(modalRef) {
    this.modalService.open(modalRef);
  }

  /**
   * Resets all form data.
   */
  resetForm() {
    this.initForm();
  }
}
