import { Injectable } from '@angular/core';

import { switchMap, map, catchError, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { Effect, ofType, Actions } from '@ngrx/effects';

import { NotificationService, TranslationService } from 'shared/services';
import { StatusCode } from 'shared/models';
import { StoresReportsService } from 'reports/services';
import {
  StoresReportsActionType,
  GetProductsListReport,
  GetProductsListReportFail,
  GetProductsListReportReceived,
  GetProductsListReportSuccess,
  GetProductMovementReport,
  GetProductMovementReportReceived,
  GetProductMovementReportFail,
  GetProductMovementReportSuccess,
  GetIncomingStockReport,
  GetIncomingStockReportReceived,
  GetIncomingStockReportFail,
  GetIncomingStockReportSuccess,
  GetOutgoingStockReport,
  GetOutgoingStockReportReceived,
  GetOutgoingStockReportFail,
  GetOutgoingStockReportSuccess,
  GetTransferStockReport,
  GetTransferStockReportFail,
  GetTransferStockReportReceived,
  GetTransferStockReportSuccess,
  GetStockInventoryReport,
  GetStockInventoryReportFail,
  GetStockInventoryReportReceived,
  GetStockInventoryReportSuccess,
  GetStockMonitoringReport,
  GetStockMonitoringReportFail,
  GetStockMonitoringReportReceived,
  GetStockMonitoringReportSuccess,
  GetUnhandledSalesStockReport,
  GetUnhandledSalesStockReportFail,
  GetUnhandledSalesStockReportReceived,
  GetUnhandledSalesStockReportSuccess,
} from 'reports/store/actions';

@Injectable()
export class StoresReportsEffects {
  constructor(
    private actions$: Actions,
    private reportsService: StoresReportsService,
    private notificationService: NotificationService,
    private translationService: TranslationService
  ) {}

  /* ========================= GET_PRODUCTS_LIST_REPORT =================================== */
  @Effect()
  productsList$ = this.actions$.pipe(
    ofType(StoresReportsActionType.GET_PRODUCTS_LIST_REPORT),
    switchMap(({ payload }: GetProductsListReport) =>
      this.reportsService
        .getProductsListReport(
          payload.classes,
          payload.locations,
          payload.searchByPurchasePrice,
          payload.minPurchasePrice,
          payload.maxPurchasePrice,
          payload.searchBySalePrice,
          payload.minSalePrice,
          payload.maxSalePrice
        )
        .pipe(
          map((response) => new GetProductsListReportReceived(response)),
          catchError((error) => of(new GetProductsListReportFail(error)))
        )
    )
  );

  @Effect({ dispatch: false })
  productsListReceived$ = this.actions$.pipe(
    ofType(StoresReportsActionType.GET_PRODUCTS_LIST_REPORT_RECEIVED),
    tap(({ payload: { data } }: GetProductsListReportReceived) => {
      this.notificationService.info(data);
    })
  );

  @Effect({ dispatch: false })
  productsListSuccess$ = this.actions$.pipe(
    ofType(StoresReportsActionType.GET_PRODUCTS_LIST_REPORT_SUCCESS),
    tap((action: GetProductsListReportSuccess) => {
      this.notificationService.success(
        this.translationService.translate('REPORTS.NOTIFICATION_MESSAGE.REPORT_IS_READY')
      );
    })
  );

  @Effect({ dispatch: false })
  productsListFail$ = this.actions$.pipe(
    ofType(StoresReportsActionType.GET_PRODUCTS_LIST_REPORT_FAIL),
    tap((action: GetProductsListReportFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );

  /* ========================= GET_PRODUCT_MOVEMENT_REPORT =================================== */
  @Effect()
  productMovement$ = this.actions$.pipe(
    ofType(StoresReportsActionType.GET_PRODUCT_MOVEMENT_REPORT),
    switchMap(({ payload }: GetProductMovementReport) =>
      this.reportsService
        .getProductMovementReport(
          payload.productId,
          payload.locations,
          payload.fromDate,
          payload.toDate,
          payload.timeSerial,
          payload.descriptive
        )
        .pipe(
          map((response) => new GetProductMovementReportReceived(response)),
          catchError((error) => of(new GetProductMovementReportFail(error)))
        )
    )
  );

  @Effect({ dispatch: false })
  productMovementReceived$ = this.actions$.pipe(
    ofType(StoresReportsActionType.GET_PRODUCT_MOVEMENT_REPORT_RECEIVED),
    tap(({ payload: { data } }: GetProductMovementReportReceived) => {
      this.notificationService.info(data);
    })
  );

  @Effect({ dispatch: false })
  productMovementSuccess$ = this.actions$.pipe(
    ofType(StoresReportsActionType.GET_PRODUCT_MOVEMENT_REPORT_SUCCESS),
    tap((action: GetProductMovementReportSuccess) => {
      this.notificationService.success(
        this.translationService.translate('REPORTS.NOTIFICATION_MESSAGE.REPORT_IS_READY')
      );
    })
  );

  @Effect({ dispatch: false })
  productMovementFail$ = this.actions$.pipe(
    ofType(StoresReportsActionType.GET_PRODUCT_MOVEMENT_REPORT_FAIL),
    tap((action: GetProductMovementReportFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );

  /* ========================= GET_INCOMING_STOCK_REPORT =================================== */
  @Effect()
  incomingStock$ = this.actions$.pipe(
    ofType(StoresReportsActionType.GET_INCOMING_STOCK_REPORT),
    switchMap(({ payload }: GetIncomingStockReport) =>
      this.reportsService
        .getIncomingStockReport(
          payload.locations,
          payload.types,
          payload.displayProducts,
          payload.fromDate,
          payload.toDate
        )
        .pipe(
          map((response) => new GetIncomingStockReportReceived(response)),
          catchError((error) => of(new GetIncomingStockReportFail(error)))
        )
    )
  );

  @Effect({ dispatch: false })
  incomingStockReceived$ = this.actions$.pipe(
    ofType(StoresReportsActionType.GET_INCOMING_STOCK_REPORT_RECEIVED),
    tap(({ payload: { data } }: GetIncomingStockReportReceived) => {
      this.notificationService.info(data);
    })
  );

  @Effect({ dispatch: false })
  incomingStockSuccess$ = this.actions$.pipe(
    ofType(StoresReportsActionType.GET_INCOMING_STOCK_REPORT_SUCCESS),
    tap((action: GetIncomingStockReportSuccess) => {
      this.notificationService.success(
        this.translationService.translate('REPORTS.NOTIFICATION_MESSAGE.REPORT_IS_READY')
      );
    })
  );

  @Effect({ dispatch: false })
  incomingStockFail$ = this.actions$.pipe(
    ofType(StoresReportsActionType.GET_INCOMING_STOCK_REPORT_FAIL),
    tap((action: GetIncomingStockReportFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );

  /* ========================= GET_OUTGOING_STOCK_REPORT =================================== */
  @Effect()
  outgoingStock$ = this.actions$.pipe(
    ofType(StoresReportsActionType.GET_OUTGOING_STOCK_REPORT),
    switchMap(({ payload }: GetOutgoingStockReport) =>
      this.reportsService
        .getOutgoingStockReport(
          payload.locations,
          payload.types,
          payload.displayProducts,
          payload.fromDate,
          payload.toDate
        )
        .pipe(
          map((response) => new GetOutgoingStockReportReceived(response)),
          catchError((error) => of(new GetOutgoingStockReportFail(error)))
        )
    )
  );

  @Effect({ dispatch: false })
  outgoingStockReceived$ = this.actions$.pipe(
    ofType(StoresReportsActionType.GET_OUTGOING_STOCK_REPORT_RECEIVED),
    tap(({ payload: { data } }: GetOutgoingStockReportReceived) => {
      this.notificationService.info(data);
    })
  );

  @Effect({ dispatch: false })
  outgoingStockSuccess$ = this.actions$.pipe(
    ofType(StoresReportsActionType.GET_OUTGOING_STOCK_REPORT_SUCCESS),
    tap((action: GetOutgoingStockReportSuccess) => {
      this.notificationService.success(
        this.translationService.translate('REPORTS.NOTIFICATION_MESSAGE.REPORT_IS_READY')
      );
    })
  );

  @Effect({ dispatch: false })
  outgoingStockFail$ = this.actions$.pipe(
    ofType(StoresReportsActionType.GET_OUTGOING_STOCK_REPORT_FAIL),
    tap((action: GetOutgoingStockReportFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );

  /* ========================= GET_TRANSFER_STOCK_REPORT =================================== */
  @Effect()
  transferStock$ = this.actions$.pipe(
    ofType(StoresReportsActionType.GET_TRANSFER_STOCK_REPORT),
    switchMap(({ payload }: GetTransferStockReport) =>
      this.reportsService
        .getTransferStockReport(
          payload.fromLocations,
          payload.toLocations,
          payload.displayProducts,
          payload.fromDate,
          payload.toDate
        )
        .pipe(
          map((response) => new GetTransferStockReportReceived(response)),
          catchError((error) => of(new GetTransferStockReportFail(error)))
        )
    )
  );

  @Effect({ dispatch: false })
  transferStockReceived$ = this.actions$.pipe(
    ofType(StoresReportsActionType.GET_TRANSFER_STOCK_REPORT_RECEIVED),
    tap(({ payload: { data } }: GetTransferStockReportReceived) => {
      this.notificationService.info(data);
    })
  );

  @Effect({ dispatch: false })
  transferStockSuccess$ = this.actions$.pipe(
    ofType(StoresReportsActionType.GET_TRANSFER_STOCK_REPORT_SUCCESS),
    tap((action: GetTransferStockReportSuccess) => {
      this.notificationService.success(
        this.translationService.translate('REPORTS.NOTIFICATION_MESSAGE.REPORT_IS_READY')
      );
    })
  );

  @Effect({ dispatch: false })
  transferStockFail$ = this.actions$.pipe(
    ofType(StoresReportsActionType.GET_TRANSFER_STOCK_REPORT_FAIL),
    tap((action: GetTransferStockReportFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );

  /* ========================= GET_STOCK_INVENTORY_REPORT =================================== */
  @Effect()
  stockInventory$ = this.actions$.pipe(
    ofType(StoresReportsActionType.GET_STOCK_INVENTORY_REPORT),
    switchMap(({ payload }: GetStockInventoryReport) =>
      this.reportsService
        .getStockInventoryReport(
          payload.locationId,
          payload.classes,
          payload.products,
          payload.productsWithAllStocks,
          payload.productsWithStock,
          payload.productsWithNoStock,
          payload.productsWithNegativeStock
        )
        .pipe(
          map((response) => new GetStockInventoryReportReceived(response)),
          catchError((error) => of(new GetStockInventoryReportFail(error)))
        )
    )
  );

  @Effect({ dispatch: false })
  stockInventoryReceived$ = this.actions$.pipe(
    ofType(StoresReportsActionType.GET_STOCK_INVENTORY_REPORT_RECEIVED),
    tap(({ payload: { data } }: GetStockInventoryReportReceived) => {
      this.notificationService.info(data);
    })
  );

  @Effect({ dispatch: false })
  stockInventorySuccess$ = this.actions$.pipe(
    ofType(StoresReportsActionType.GET_STOCK_INVENTORY_REPORT_SUCCESS),
    tap((action: GetStockInventoryReportSuccess) => {
      this.notificationService.success(
        this.translationService.translate('REPORTS.NOTIFICATION_MESSAGE.REPORT_IS_READY')
      );
    })
  );

  @Effect({ dispatch: false })
  stockInventoryFail$ = this.actions$.pipe(
    ofType(StoresReportsActionType.GET_STOCK_INVENTORY_REPORT_FAIL),
    tap((action: GetStockInventoryReportFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );

  /* ========================= GET_STOCK_MONITORING_REPORT =================================== */
  @Effect()
  stockMonitoring$ = this.actions$.pipe(
    ofType(StoresReportsActionType.GET_STOCK_MONITORING_REPORT),
    switchMap(({ payload }: GetStockMonitoringReport) =>
      this.reportsService
        .getStockMonitoringReport(payload.locationId, payload.products, payload.fromDate, payload.toDate)
        .pipe(
          map((response) => new GetStockMonitoringReportReceived(response)),
          catchError((error) => of(new GetStockMonitoringReportFail(error)))
        )
    )
  );

  @Effect({ dispatch: false })
  stockMonitoringReceived$ = this.actions$.pipe(
    ofType(StoresReportsActionType.GET_STOCK_MONITORING_REPORT_RECEIVED),
    tap(({ payload: { data } }: GetStockMonitoringReportReceived) => {
      this.notificationService.info(data);
    })
  );

  @Effect({ dispatch: false })
  stockMonitoringSuccess$ = this.actions$.pipe(
    ofType(StoresReportsActionType.GET_STOCK_MONITORING_REPORT_SUCCESS),
    tap((action: GetStockMonitoringReportSuccess) => {
      this.notificationService.success(
        this.translationService.translate('REPORTS.NOTIFICATION_MESSAGE.REPORT_IS_READY')
      );
    })
  );

  @Effect({ dispatch: false })
  stockMonitoringFail$ = this.actions$.pipe(
    ofType(StoresReportsActionType.GET_STOCK_MONITORING_REPORT_FAIL),
    tap((action: GetStockMonitoringReportFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );

  /* ========================= GET_UNHANDLED_SALES_STOCK_REPORT =================================== */
  @Effect()
  unhandledSalesStock$ = this.actions$.pipe(
    ofType(StoresReportsActionType.GET_UNHANDLED_SALES_STOCK_REPORT),
    switchMap(({ payload }: GetUnhandledSalesStockReport) =>
      this.reportsService
        .getUnhandledSalesStockReport(payload.locationId, payload.products, payload.fromDate, payload.toDate)
        .pipe(
          map((response) => new GetUnhandledSalesStockReportReceived(response)),
          catchError((error) => of(new GetUnhandledSalesStockReportFail(error)))
        )
    )
  );

  @Effect({ dispatch: false })
  unhandledSalesStockReceived$ = this.actions$.pipe(
    ofType(StoresReportsActionType.GET_UNHANDLED_SALES_STOCK_REPORT_RECEIVED),
    tap(({ payload: { data } }: GetUnhandledSalesStockReportReceived) => {
      this.notificationService.info(data);
    })
  );

  @Effect({ dispatch: false })
  unhandledSalesStockSuccess$ = this.actions$.pipe(
    ofType(StoresReportsActionType.GET_UNHANDLED_SALES_STOCK_REPORT_SUCCESS),
    tap((action: GetUnhandledSalesStockReportSuccess) => {
      this.notificationService.success(
        this.translationService.translate('REPORTS.NOTIFICATION_MESSAGE.REPORT_IS_READY')
      );
    })
  );

  @Effect({ dispatch: false })
  unhandledSalesStockFail$ = this.actions$.pipe(
    ofType(StoresReportsActionType.GET_UNHANDLED_SALES_STOCK_REPORT_FAIL),
    tap((action: GetUnhandledSalesStockReportFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );
}
