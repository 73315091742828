<!-- page header starts -->
<app-page-header [info]="pageInfo"></app-page-header>
<!-- page header ends -->

<!-- bank account file pages start -->

<div class="card">
  <div class="card-content">
    <div class="px-3 pt-3" *ngIf="bankAccount$ | async; let bankAccount">
      <ul
        class="nav-tabs"
        ngbNav
        #nav="ngbNav"
        [(activeId)]="activePage"
        (activeIdChange)="selectedPageChanged($event)"
        [destroyOnHide]="false"
      >
        <li ngbNavItem="info">
          <a ngbNavLink>
            <i class="fa fa-id-badge" [class.success]="activePage == 'info'"></i>
            {{ 'FINANCES.BANK_ACCOUNTS.BANK_ACCOUNT_DATA_TITLE' | translate }}
          </a>
          <ng-template ngbNavContent>
            <app-bank-account-info [bankAccount]="bankAccount"></app-bank-account-info>
          </ng-template>
        </li>
        <li ngbNavItem="summary">
          <a ngbNavLink>
            <i class="fa fa-bar-chart" [class.success]="activePage == 'summary'"></i>
            {{ 'FINANCES.BANK_ACCOUNTS.BANK_ACCOUNT_SUMMARY' | translate }}({{ bankAccount.balance | toDecimal }})
          </a>
          <ng-template ngbNavContent>
            <app-bank-account-summary
              [bankAccountId]="bankAccount.id"
              [bankAccount]="bankAccount"
            ></app-bank-account-summary>
          </ng-template>
        </li>
      </ul>
      <div [ngbNavOutlet]="nav" class="mt-2"></div>
    </div>
  </div>
</div>

<!-- bank account file pages end -->
