import { Injectable } from '@angular/core';

import { of } from 'rxjs';
import { switchMap, map, catchError } from 'rxjs/operators';
import { Effect, ofType, Actions } from '@ngrx/effects';

import { LookupsService } from 'lookups/services';
import {
  AssignmentStatusActionType,
  GetAllAssignmentStatusesFail,
  GetAllAssignmentStatusesSuccess,
} from 'lookups/store/actions';

@Injectable()
export class AssignmentStatusEffects {
  constructor(private actions$: Actions, private lookupsService: LookupsService) {}

  /* ========================= GET_ALL_ASSIGNMENT_STATUSES =================================== */
  @Effect()
  getAllAssignmentStatuses$ = this.actions$.pipe(
    ofType(AssignmentStatusActionType.GET_ALL_ASSIGNMENT_STATUSES),

    switchMap(() =>
      this.lookupsService.getAllAssignmentStatuses().pipe(
        map((response) => new GetAllAssignmentStatusesSuccess(response)),
        catchError((error) => of(new GetAllAssignmentStatusesFail(error)))
      )
    )
  );
}
