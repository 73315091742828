import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { FormArray, FormGroup, FormControl, Validators } from '@angular/forms';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';

import { APP_CONSTANTS, CustomValidators } from 'shared';
import { Location, ProductSalesDiscount, ProductSalesDiscountItem } from 'stores/models';

@Component({
  selector: 'app-product-sales-discounts-form',
  templateUrl: './product-sales-discounts-form.component.html',
  styles: [],
})
export class ProductSalesDiscountsFormComponent {
  /**
   * The confirm modal template reference.
   */
  @ViewChild('deleteModalRef') deleteModalRef: ElementRef<any>;

  /**
   * Sets the product sales discounts for array.
   */
  @Input() productSalesDiscountsForm: FormArray;

  /**
   * Sets the name form-array in its parent form.
   * @default 'productSalesDiscounts'
   */
  @Input() formArrayName = 'productSalesDiscounts';

  /**
   * Adds a list of new product sales discount form items to the product sales discounts form.
   * @param items The list of product sales discount form items to be added to the product sales discounts form.
   */
  @Input() set newProductSalesDiscounts(items: ProductSalesDiscountItem[]) {
    if (!items?.length) {
      return;
    }

    items.forEach((item) => this.createProductSalesDiscountFormGroup(item));
  }

  /**
   * Sets a value indicates whether if the user should specify a product sales discount or not.
   * @default false
   */
  @Input() isProductSalesDiscountRequired = false;

  /**
   * Shows or hide the locations list.
   */
  locationsListVisibility = false;

  /**
   * Indicates whether if we can add product sales discount or not.
   *  @default `true`.
   */
  @Input() allowAddProductSalesDiscount = true;

  /**
   * Indicates whether if we can clear product sales discount or not.
   * @default `true`.
   */
  @Input() allowClearProductSalesDiscounts = true;

  /**
   * The decimal mask.
   */
  readonly DECIMAL_MASK = APP_CONSTANTS.numeric.decimal.mask;

  /**
   * The list of active product sales discounts.
   */
  productSalesDiscounts: ProductSalesDiscount[] = [];

  /**
   * The employee product sales discounts form.
   */
  productSalesDiscountsFormGroup: FormGroup;

  /**
   * The set of subscriptions on this components,
   * these subscriptions must be unsubscribed before this component got destroyed.
   */
  subscriptions = new Subscription();

  /**
   * @param modalService The modal service.
   */
  constructor(private modalService: NgbModal) {}

  /**
   * Creates & adds a new product sales discount form-group with validations.
   */
  addProductSalesDiscount() {
    this.locationsListVisibility = true;
  }

  /**
   * Open confirm delete all product sales discounts modal .
   */
  openDeleteModal() {
    this.openModal(this.deleteModalRef);
  }

  /**
   * Opens the modal of the given templateRef.
   * @param modalRef The modal templateRef to be opened.
   */
  openModal(modalRef) {
    this.modalService.open(modalRef);
  }

  /**
   * Remove the product sales discount at the given index from the product sales discounts form.
   * @param index The index of the product sales discount form group.
   */
  removeProductSalesDiscount(index: number) {
    this.productSalesDiscountsForm.removeAt(index);
  }

  /**
   * Remove all of the list of product sales discounts.
   *
   * It only adds one product sales discount for quick start.
   */
  resetProductSalesDiscounts() {
    this.productSalesDiscountsForm.clear();
  }

  /**
   * Handles the product sales discount select event.
   * @param location The newly selected locations.
   */
  selectLocations(locations: Location[]) {
    if (locations) {
      locations.map((location) =>
        this.createProductSalesDiscountFormGroup(
          this.createProductSalesDiscountFormItemFromProductSalesDiscount(location)
        )
      );
    }
  }

  /**
   * Creates and returns a new product sales discount form item from a given product sales discount.
   * @param productSalesDiscount The product sales discount to be used to create the product sales discount form item.
   */
  createProductSalesDiscountFormItemFromProductSalesDiscount(location: Location): ProductSalesDiscountItem {
    return {
      locationId: location.id,
      location,
      discount: null,
      discountPercent: null,
    };
  }

  /**
   * Create a new product sales discount form group from the provided product sales discount form item.
   * @param productSalesDiscount The product sales discount form item that contains data about the new product sales discount.
   */
  createProductSalesDiscountFormGroup(productSalesDiscount: ProductSalesDiscountItem) {
    if (this.isLocationExists(productSalesDiscount.location.id)) {
      return;
    }

    const formGroup = new FormGroup({
      locationId: new FormControl(productSalesDiscount?.locationId ?? null),
      location: new FormControl(productSalesDiscount.location),
      discount: new FormControl(0, [Validators.required, CustomValidators.gte(0)]),
      discountPercent: new FormControl(0, [Validators.required, CustomValidators.gte(0)]),
    });

    this.productSalesDiscountsForm.push(formGroup);
  }

  /**
   * Indicates whether if the given location id is already exists in the form array or not.
   * @param locationId The id of the location.
   * @returns `boolean`.
   */
  isLocationExists(locationId: number): boolean {
    return (this.productSalesDiscountsForm.value as ProductSalesDiscountItem[]).some(
      (productSalesDiscountItem) => productSalesDiscountItem.locationId === locationId
    );
  }

  /**
   * Remove the employee product sales discounts at the given index from the list.
   * @param index The index of the employee product sales discounts to be removed.
   */
  remove(index: number) {
    this.productSalesDiscountsForm.removeAt(index);
  }
}
