import { Injectable } from '@angular/core';

import { of } from 'rxjs';
import { switchMap, map, catchError, tap } from 'rxjs/operators';
import { Effect, ofType, Actions } from '@ngrx/effects';

import { NotificationService, StatusCode, PAGINATION, TranslationService } from 'shared';
import { CurrencyExchangeRatesService } from 'finances/services';
import {
  CurrencyExchangeRatesActionType,
  CreateCurrencyExchangeRate,
  CreateCurrencyExchangeRateFail,
  CreateCurrencyExchangeRateSuccess,
  DeleteCurrencyExchangeRate,
  DeleteCurrencyExchangeRateFail,
  DeleteCurrencyExchangeRateSuccess,
  FindCurrencyExchangeRate,
  FindCurrencyExchangeRateFail,
  FindCurrencyExchangeRateSuccess,
  SearchCurrencyExchangeRates,
  SearchCurrencyExchangeRatesFail,
  SearchCurrencyExchangeRatesSuccess,
  UpdateCurrencyExchangeRate,
  UpdateCurrencyExchangeRateFail,
  UpdateCurrencyExchangeRateSuccess,
} from 'finances/store/actions';

@Injectable()
export class CurrencyExchangeRatesEffects {
  constructor(
    private actions$: Actions,
    private currencyExchangeRateService: CurrencyExchangeRatesService,
    private notificationService: NotificationService,
    private translationService: TranslationService
  ) {}

  /* ========================= SEARCH_CURRENCY_EXCHANGE_RATE =================================== */
  @Effect()
  search$ = this.actions$.pipe(
    ofType(CurrencyExchangeRatesActionType.SEARCH_CURRENCY_EXCHANGE_RATES),
    switchMap(({ payload }: SearchCurrencyExchangeRates) =>
      this.currencyExchangeRateService
        .search(
          payload.primaryCurrencies,
          payload.secondaryCurrencies,
          payload.fromDate,
          payload.toDate,
          payload.page,
          PAGINATION.CurrencyExchangeRate
        )
        .pipe(
          map((response) => new SearchCurrencyExchangeRatesSuccess(response)),
          catchError((error) => of(new SearchCurrencyExchangeRatesFail(error)))
        )
    )
  );

  /* ========================= FIND_CURRENCY_EXCHANGE_RATE =================================== */
  @Effect()
  find$ = this.actions$.pipe(
    ofType(CurrencyExchangeRatesActionType.FIND_CURRENCY_EXCHANGE_RATE),
    switchMap((action: FindCurrencyExchangeRate) =>
      this.currencyExchangeRateService.findById(action.payload).pipe(
        map((response) => new FindCurrencyExchangeRateSuccess(response)),
        catchError((error) => of(new FindCurrencyExchangeRateFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  findFail$ = this.actions$.pipe(
    ofType(CurrencyExchangeRatesActionType.FIND_CURRENCY_EXCHANGE_RATE_FAIL),
    tap((action: FindCurrencyExchangeRateFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate('FINANCES.CURRENCY_EXCHANGE_RATE.CURRENCY_EXCHANGE_RATE_NOT_FOUND')
        );
      }
    })
  );

  /* ========================= CREATE_CURRENCY_EXCHANGE_RATE =================================== */
  @Effect()
  create$ = this.actions$.pipe(
    ofType(CurrencyExchangeRatesActionType.CREATE_CURRENCY_EXCHANGE_RATE),
    switchMap((action: CreateCurrencyExchangeRate) =>
      this.currencyExchangeRateService.create(action.payload).pipe(
        map((response) => new CreateCurrencyExchangeRateSuccess(response)),
        catchError((error) => of(new CreateCurrencyExchangeRateFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  createSuccess$ = this.actions$.pipe(
    ofType(CurrencyExchangeRatesActionType.CREATE_CURRENCY_EXCHANGE_RATE_SUCCESS),
    tap((action: CreateCurrencyExchangeRateSuccess) => {
      this.notificationService.success(
        this.translationService.translate('FINANCES.CURRENCY_EXCHANGE_RATE.CURRENCY_EXCHANGE_RATE_ADDED')
      );
    })
  );

  @Effect({ dispatch: false })
  createFail$ = this.actions$.pipe(
    ofType(CurrencyExchangeRatesActionType.CREATE_CURRENCY_EXCHANGE_RATE_FAIL),
    tap((action: CreateCurrencyExchangeRateFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );

  /* ========================= UPDATE_CURRENCY_EXCHANGE_RATE =================================== */
  @Effect()
  update$ = this.actions$.pipe(
    ofType(CurrencyExchangeRatesActionType.UPDATE_CURRENCY_EXCHANGE_RATE),
    switchMap((action: UpdateCurrencyExchangeRate) =>
      this.currencyExchangeRateService.update(action.payload).pipe(
        map((response) => new UpdateCurrencyExchangeRateSuccess(response)),
        catchError((error) => of(new UpdateCurrencyExchangeRateFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  updateSuccess$ = this.actions$.pipe(
    ofType(CurrencyExchangeRatesActionType.UPDATE_CURRENCY_EXCHANGE_RATE_SUCCESS),
    tap((action: UpdateCurrencyExchangeRateSuccess) => {
      this.notificationService.success(
        this.translationService.translate('FINANCES.CURRENCY_EXCHANGE_RATE.CURRENCY_EXCHANGE_RATE_UPDATED')
      );
    })
  );

  @Effect({ dispatch: false })
  updateFail$ = this.actions$.pipe(
    ofType(CurrencyExchangeRatesActionType.UPDATE_CURRENCY_EXCHANGE_RATE_FAIL),
    tap((action: UpdateCurrencyExchangeRateFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate('FINANCES.CURRENCY_EXCHANGE_RATE.CURRENCY_EXCHANGE_RATE_NOT_FOUND')
        );
      } else if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );

  /* ========================= DELETE_CURRENCY_EXCHANGE_RATE =================================== */
  @Effect()
  delete$ = this.actions$.pipe(
    ofType(CurrencyExchangeRatesActionType.DELETE_CURRENCY_EXCHANGE_RATE),
    switchMap((action: DeleteCurrencyExchangeRate) =>
      this.currencyExchangeRateService.delete(action.payload).pipe(
        map((response) => new DeleteCurrencyExchangeRateSuccess(response)),
        catchError((error) => of(new DeleteCurrencyExchangeRateFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  deleteSuccess$ = this.actions$.pipe(
    ofType(CurrencyExchangeRatesActionType.DELETE_CURRENCY_EXCHANGE_RATE_SUCCESS),
    tap((action: DeleteCurrencyExchangeRateSuccess) => {
      this.notificationService.success(
        this.translationService.translate('FINANCES.CURRENCY_EXCHANGE_RATE.CURRENCY_EXCHANGE_RATE_DELETED')
      );
    })
  );

  @Effect({ dispatch: false })
  deleteFail$ = this.actions$.pipe(
    ofType(CurrencyExchangeRatesActionType.DELETE_CURRENCY_EXCHANGE_RATE_FAIL),
    tap((action: DeleteCurrencyExchangeRateFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate('FINANCES.CURRENCY_EXCHANGE_RATE.CURRENCY_EXCHANGE_RATE_NOT_FOUND')
        );
      } else if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );
}
