import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms';

import { Store, select } from '@ngrx/store';
import { Subscription, Observable } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { skip, tap } from 'rxjs/operators';

import { PageInfo, NotificationService, NotificationMessage, TranslationService, CustomValidators } from 'shared';
import * as fromSalesStore from 'sales/store';
import * as fromLookupsStore from 'lookups/store';
import { PosPrinter } from 'sales/models';
import { PosPrinterAdjustmentTypes } from 'lookups/models';

@Component({
  selector: 'app-create-casher-printer',
  templateUrl: './create-casher-printer.component.html',
  styles: [],
})
export class CreateCasherPrinterComponent implements OnInit, OnDestroy {
  /**
   * The confirm modal template reference.
   */
  @ViewChild('confirmModalRef') confirmModalRef: ElementRef<any>;

  /**
   * Gets or sets the information about the current page.
   */
  pageInfo: PageInfo = {
    title: 'SALES.CASHER_PRINTERS.CREATE_CASHER_PRINTER_TITLE',
    icon: 'fa fa-plus',
  };

  /**
   * Indicates whether there is a create casher printer process is running or not.
   */
  isCreating$: Observable<boolean>;

  /**
   * The create casher printer form.
   */
  form: FormGroup;

  /**
   * Shows or hide the casher printers list.
   */
  posPrintersListVisibility = false;

  /**
   * The list of selected pos devices.
   */
  posPrinters: PosPrinter[] = [];

  /**
   * Gets or sets the id of the pos printer adjustment for `CASHER_PRINTER`.
   */
  posPrinterAdjustmentIdForCasher: number;

  /**
   * The set of subscriptions on this components,
   * these subscriptions must be unsubscribed before this component got destroyed.
   */
  subscriptions = new Subscription();

  /**
   * @param modalService The modal service.
   * @param notificationService The notification service.
   * @param salesStore$  The stores store.
   * @param translationService The translation service.
   * @param lookupsStore$ The lookups-module store.
   * @param authStore$ the auth-store module.
   */
  constructor(
    private modalService: NgbModal,
    private notificationService: NotificationService,
    private salesStore$: Store<fromSalesStore.SalesState>,
    private lookupsStore$: Store<fromLookupsStore.LookupsState>,
    private translationService: TranslationService
  ) {}

  ngOnInit(): void {
    this.init();
  }

  /**
   * Destroy component data
   */
  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  /**
   * Initialize component data.
   */
  init() {
    this.initForm();

    let isManualSearchTriggeredBeforeForPosPrinterAdjustmentTypes = false;
    this.subscriptions.add(
      this.lookupsStore$
        .pipe(
          select(fromLookupsStore.getPosPrinterAdjustmentTypes),
          tap((posPrinterAdjustments) => {
            if (!isManualSearchTriggeredBeforeForPosPrinterAdjustmentTypes && !posPrinterAdjustments.length) {
              isManualSearchTriggeredBeforeForPosPrinterAdjustmentTypes = true;
              this.lookupsStore$.dispatch(new fromLookupsStore.GetAllPosPrinterAdjustmentTypes());
            } else if (posPrinterAdjustments?.length) {
              this.posPrinterAdjustmentIdForCasher = posPrinterAdjustments?.find(
                (posPrinterAdjustment) => posPrinterAdjustment.key === PosPrinterAdjustmentTypes.CASHIER_PRINTER
              )?.id;
            }
          })
        )
        .subscribe()
    );

    /**
     * Reset form controls when new casher printer is created.
     */
    this.subscriptions.add(
      this.salesStore$
        .pipe(
          select(fromSalesStore.getSelectedCasherPrinterCreateCompleted),
          skip(1),
          tap((isCreated) => {
            if (isCreated) {
              this.initForm();
            }
          })
        )
        .subscribe()
    );

    /**
     * Load data.
     */
    this.isCreating$ = this.salesStore$.pipe(select(fromSalesStore.getSelectedCasherPrinterCreating));
  }

  /**
   * Initialize form and add validators.
   */
  initForm() {
    this.form = new FormGroup({
      posPrinterId: new FormControl(null, [Validators.required]),
    });
  }

  /**
   * Submits the form.
   */
  submit() {
    if (this.form.invalid) {
      const errorMessage = new NotificationMessage();

      if (this.form.get('posPrinterId').invalid) {
        errorMessage.title = this.translationService.translate(
          'SALES.CASHER_PRINTERS.CASHER_PRINTER_DATA_VALIDATION.CASHER_PRINTER_ID_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate(
            'SALES.CASHER_PRINTERS.CASHER_PRINTER_DATA_VALIDATION.CASHER_PRINTER_ID_IS_REQUIRED'
          ),
        ];
      }
      this.form.markAllAsTouched();
      return this.notificationService.warningWithTitle(errorMessage);
    } else {
      this.openModal(this.confirmModalRef);
    }
  }

  /**
   * Confirms the form submit.
   */
  confirm() {
    this.salesStore$.dispatch(
      new fromSalesStore.CreateCasherPrinter({
        ...this.form.value,
        productClasses: [],
        posPrinterAdjustmentTypeId: this.posPrinterAdjustmentIdForCasher,
        orderTypes: [],
      })
    );
  }

  /**
   * Opens the modal of the given templateRef.
   * @param modalRef The modal templateRef to be opened.
   */
  openModal(modalRef) {
    this.modalService.open(modalRef);
  }

  /**
   * Selects the newly selected pos printer from the pos printer search list.
   * @param posPrinter The list of newly selected pos printer to select the only one in the list.
   */
  selectPosPrinter([posPrinter]: PosPrinter[]) {
    if (posPrinter) {
      this.posPrinters = [posPrinter];
      this.form.patchValue({ posPrinterId: posPrinter.id });
    }
  }
}
