<!-- currency exchange rate search modal starts -->
<ng-template #modalRef let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title text-primary" id="modal-basic-title">
      {{ 'FINANCES.CURRENCY_EXCHANGE_RATE.CURRENCY_EXCHANGE_RATE_LIST' | translate }}
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="d(); closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <!-- currency exchange rate list starts -->
    <div class="row">
      <div class="col-md-12">
        <form class="row" [formGroup]="searchForm" (ngSubmit)="search()">
          <div class="form-group col">
            <label for="primaryCurrencies">{{
              'FINANCES.CURRENCY_EXCHANGE_RATE.CURRENCY_EXCHANGE_RATE_DATA.PRIMARY_CURRENCY' | translate
            }}</label>
            <ng-select
              class="round"
              formControlName="primaryCurrencies"
              [multiple]="true"
              [closeOnSelect]="false"
              labelForId="primaryCurrencies"
              searchable="true"
              [placeholder]="
                'FINANCES.CURRENCY_EXCHANGE_RATE.CURRENCY_EXCHANGE_RATE_DATA.SEARCH_PRIMARY_CURRENCY_PLACEHOLDER'
                  | translate
              "
              [notFoundText]="'SHARED.SEARCH.SEARCH_NOT_FOUND' | translate"
              (open)="primaryCurrencyListVisibility = true"
            >
              <ng-option *ngFor="let currency of primaryCurrencies" [value]="currency.id">
                {{ currency.name | localize: currency.nameEn | async }}
              </ng-option>
            </ng-select>
          </div>
          <div class="form-group col">
            <label for="secondaryCurrencies">{{
              'FINANCES.CURRENCY_EXCHANGE_RATE.CURRENCY_EXCHANGE_RATE_DATA.SECONDARY_CURRENCY' | translate
            }}</label>
            <ng-select
              class="round"
              formControlName="secondaryCurrencies"
              [multiple]="true"
              [closeOnSelect]="false"
              labelForId="secondaryCurrencies"
              searchable="true"
              [placeholder]="
                'FINANCES.CURRENCY_EXCHANGE_RATE.CURRENCY_EXCHANGE_RATE_DATA.SECONDARY_CURRENCY_PLACEHOLDER' | translate
              "
              [notFoundText]="'SHARED.SEARCH.SEARCH_NOT_FOUND' | translate"
              (open)="secondaryCurrencyListVisibility = true"
            >
              <ng-option *ngFor="let currency of secondaryCurrencies" [value]="currency.id">
                {{ currency.name | localize: currency.nameEn | async }}
              </ng-option>
            </ng-select>
          </div>
          <div class="form-group col">
            <div class="row">
              <div class="form-group col-12 col-sm-6 mb-0">
                <label for="fromDate">{{ 'SHARED.SEARCH.FROM_DATE' | translate }}</label>
                <div class="input-group round">
                  <input
                    class="form-control"
                    id="fromDate"
                    name="fromDate"
                    formControlName="fromDate"
                    [placeholder]="'SHARED.SEARCH.DATE_PLACEHOLDER' | translate"
                    ngbDatepicker
                    #fromDate="ngbDatepicker"
                  />
                  <div class="input-group-append">
                    <span
                      class="input-group-text gradient-light-blue-indigo"
                      style="cursor: pointer;"
                      (click)="fromDate.toggle()"
                    >
                      <i class="fa fa-calendar"></i>
                    </span>
                  </div>
                </div>
              </div>
              <div class="form-group col-12 col-sm-6 mb-0">
                <label for="toDate">{{ 'SHARED.SEARCH.TO_DATE' | translate }}</label>
                <div class="input-group round">
                  <input
                    class="form-control"
                    id="toDate"
                    name="toDate"
                    formControlName="toDate"
                    [placeholder]="'SHARED.SEARCH.DATE_PLACEHOLDER' | translate"
                    ngbDatepicker
                    #toDate="ngbDatepicker"
                  />
                  <div class="input-group-append">
                    <span
                      class="input-group-text gradient-light-blue-indigo"
                      style="cursor: pointer;"
                      (click)="toDate.toggle()"
                    >
                      <i class="fa fa-calendar"></i>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-auto form-group d-flex">
            <button
              type="submit"
              class="btn btn-raised btn-primary align-self-end round mx-1 mb-0"
              [disabled]="isSearching$ | async"
            >
              <i class="fa fa-search"></i>
            </button>
          </div>
        </form>
      </div>
      <div class="col-md-12" *ngIf="(currencyExchangeRates$ | async)?.length; else noItems">
        <div class="row">
          <div class="col-md-12 alert alert-light text-center" role="alert">
            {{ 'SHARED.SEARCH.SEARCH_RESULTS' | translate }} ({{ (paginationInfo$ | async).total | toNumber }})
            {{ 'FINANCES.CURRENCY_EXCHANGE_RATE.CURRENCY_EXCHANGE_RATE_DATA.CURRENCY' | translate }}
          </div>
          <div class="col-md-12">
            <div class="table-responsive">
              <table class="table text-center">
                <thead>
                  <tr>
                    <th class="fit-width" *ngIf="!closeOnSelect">
                      <a
                        class="primary p-0"
                        (click)="selectCurrencyExchangeRate(currencyExchangeRatesList)"
                        [ngbTooltip]="'SHARED.SEARCH.SELECT_ALL' | translate"
                      >
                        <i class="fa fa-check-circle font-medium-3"></i>
                      </a>
                    </th>
                    <th class="fit-width" *ngIf="closeOnSelect">
                      <i class="fa fa-gear font-medium-3"></i>
                    </th>
                    <th scope="col"><i class="fa fa-list-ol"></i></th>
                    <th scope="col">
                      {{ 'FINANCES.CURRENCY_EXCHANGE_RATE.CURRENCY_EXCHANGE_RATE_DATA.PRIMARY_CURRENCY' | translate }}
                    </th>
                    <th scope="col">
                      {{ 'FINANCES.CURRENCY_EXCHANGE_RATE.CURRENCY_EXCHANGE_RATE_DATA.SECONDARY_CURRENCY' | translate }}
                    </th>
                    <th scope="col">
                      {{ 'FINANCES.CURRENCY_EXCHANGE_RATE.CURRENCY_EXCHANGE_RATE_DATA.RATE' | translate }}
                    </th>
                    <th scope="col">
                      {{ 'FINANCES.CURRENCY_EXCHANGE_RATE.CURRENCY_EXCHANGE_RATE_DATA.START_DATE' | translate }}
                    </th>
                    <th scope="col">
                      {{ 'FINANCES.CURRENCY_EXCHANGE_RATE.CURRENCY_EXCHANGE_RATE_DATA.END_DATE' | translate }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    *ngFor="
                      let currencyExchangeRate of currencyExchangeRates$ | async;
                      trackBy: trackItems;
                      let i = index
                    "
                  >
                    <td>
                      <a
                        class="primary p-0"
                        (click)="selectCurrencyExchangeRate([currencyExchangeRate])"
                        [ngbTooltip]="
                          'FINANCES.CURRENCY_EXCHANGE_RATE.CURRENCY_EXCHANGE_RATE_DATA.SELECT_CURRENCY_EXCHANGE_RATE'
                            | translate
                        "
                      >
                        <i class="fa fa-check-circle-o font-medium-3"></i>
                      </a>
                    </td>
                    <th scope="row">{{ i + 1 }}</th>
                    <td>
                      {{
                        currencyExchangeRate.primaryCurrency.name
                          | localize: currencyExchangeRate.primaryCurrency.nameEn
                          | async
                      }}
                    </td>
                    <td>
                      {{
                        currencyExchangeRate.secondaryCurrency.name
                          | localize: currencyExchangeRate.secondaryCurrency.nameEn
                          | async
                      }}
                    </td>
                    <td>{{ currencyExchangeRate.rate | toDecimal }}</td>
                    <td>
                      <span [ngbTooltip]="currencyExchangeRate.startDate | date: 'time'">
                        {{ currencyExchangeRate.startDate | date }}</span
                      >
                    </td>

                    <td>
                      <span [ngbTooltip]="currencyExchangeRate.endDate | date: 'time'">
                        {{ currencyExchangeRate.endDate | date }}</span
                      >
                    </td>
                  </tr>
                </tbody>
              </table>
              <div class="d-flex justify-content-between p-2">
                <ngb-pagination
                  *ngIf="paginationInfo$ | async; let pagination"
                  maxSize="5"
                  [collectionSize]="pagination.total"
                  [pageSize]="pagination.pageSize"
                  [page]="pagination.page"
                  (pageChange)="pageChanged($event)"
                  boundaryLinks="true"
                >
                  <ng-template ngbPaginationFirst>{{ 'SHARED.PAGINATION.PAGINATION_FIRST' | translate }}</ng-template>
                  <ng-template ngbPaginationFirst>{{
                    'SHARED.PAGINATION.PAGINATION_PREVIOUS' | translate
                  }}</ng-template>
                  <ng-template ngbPaginationFirst>{{ 'SHARED.PAGINATION.PAGINATION_NEXT' | translate }}</ng-template>
                  <ng-template ngbPaginationFirst>{{ 'SHARED.PAGINATION.PAGINATION_LAST' | translate }}</ng-template>
                </ngb-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- currency exchange rate list ends -->
  </div>
  <div class="modal-footer">
    <div class="modal-actions">
      <button type="button" class="btn btn-outline-dark round" (click)="c(); closeModal()">
        <i class="fa fa-times"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.CLOSE' | translate }}</span>
      </button>
    </div>
  </div>
</ng-template>
<!-- currency exchange rate search modal ends -->

<!-- no items template starts -->
<ng-template #noItems>
  <div class="col-md-12 alert alert-warning mt-3 text-center" role="alert">
    {{ 'FINANCES.CURRENCY_EXCHANGE_RATE.SEARCH_CURRENCY_EXCHANGE_RATE_WITHOUT_ADD_NOT_FOUND' | translate }}
  </div>
</ng-template>
<!-- no items template ends -->

<!-- primary currencies search starts -->
<app-currencies-search [(visible)]="primaryCurrencyListVisibility" (currencies)="selectPrimaryCurrencies($event)">
</app-currencies-search>
<!-- primary currencies search ends -->

<!-- secondary currencies search starts -->
<app-currencies-search [(visible)]="secondaryCurrencyListVisibility" (currencies)="selectSecondaryCurrencies($event)">
</app-currencies-search>
<!-- secondary currencies search ends -->
