import { CreateOrganizationComponent } from 'organizations/components/create-organization/create-organization.component';
import { UpdateOrganizationComponent } from 'organizations/components/update-organization/update-organization.component';
import { OrganizationsListComponent } from 'organizations/components/organizations-list/organizations-list.component';
import { OrganizationsSearchComponent } from 'organizations/components/organizations-search/organizations-search.component';

export * from 'organizations/components/create-organization/create-organization.component';
export * from 'organizations/components/update-organization/update-organization.component';
export * from 'organizations/components/organizations-list/organizations-list.component';
export * from 'organizations/components/organizations-search/organizations-search.component';

/**
 * The set of components registered on this module.
 */
export const COMPONENTS: any[] = [
  CreateOrganizationComponent,
  UpdateOrganizationComponent,
  OrganizationsListComponent,
  OrganizationsSearchComponent,
];
