import { Action } from '@ngrx/store';

import { CustomerCategory } from 'lookups/models';
import { AppHttpResponse } from 'shared';

/**
 * The Lookups-Module store  customer category action types.
 */
export enum CustomerCategoryActionType {
  GET_ALL_CUSTOMER_CATEGORY = '[Lookups] [CustomerCategory] Get All Customer Categories',
  GET_ALL_CUSTOMER_CATEGORY_FAIL = '[Lookups] [CustomerCategory] Get All Customer Categories Fail',
  GET_ALL_CUSTOMER_CATEGORY_SUCCESS = '[Lookups] [CustomerCategory] Get All Customer Categories Success',
}

/**
 * Represents a store  customer category action.
 */
export class GetAllCustomerCategory implements Action {
  /**
   * The type of the action.
   */
  readonly type = CustomerCategoryActionType.GET_ALL_CUSTOMER_CATEGORY;

  /**
   * Fires a new  customer category action.
   */
  constructor() {}
}

/**
 * Represents a store  customer category fail action.
 */
export class GetAllCustomerCategoryFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = CustomerCategoryActionType.GET_ALL_CUSTOMER_CATEGORY_FAIL;

  /**
   * Fires a new  customer category fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store  customer category success action.
 */
export class GetAllCustomerCategorySuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = CustomerCategoryActionType.GET_ALL_CUSTOMER_CATEGORY_SUCCESS;

  /**
   * Fires a customer category success action.
   * @param payload An object contains the list of customer categories.
   */
  constructor(public payload: AppHttpResponse<CustomerCategory[]>) {}
}

/**
 * Lookups-module  customer category action types.
 */
export type CustomerCategoryActions =
  | GetAllCustomerCategory
  | GetAllCustomerCategoryFail
  | GetAllCustomerCategorySuccess;
