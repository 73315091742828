<!-- page header starts -->
<app-page-header [info]="pageInfo"></app-page-header>
<!-- page header ends -->

<!-- update sector form starts -->
<div class="row">
  <div class="col-md-12">
    <form [formGroup]="form" (ngSubmit)="submit()" *ngIf="sector$ | async">
      <div class="card">
        <div class="card-header mb-3">
          <h4 class="card-title">
            {{ 'STORES.SECTORS.SECTOR_DATA_TITLE' | translate }}
          </h4>
        </div>
        <div class="card-content">
          <div class="px-3">
            <div class="row">
              <div class="form-group col-md-6">
                <label for="name">{{ 'STORES.SECTORS.ARABIC_SECTOR_NAME' | translate }}</label>
                <input
                  type="text"
                  id="name"
                  class="form-control round"
                  [placeholder]="'STORES.SECTORS.ARABIC_SECTOR_NAME_PLACEHOLDER' | translate"
                  maxlength="100"
                  formControlName="name"
                />
              </div>
              <div class="form-group col-md-6">
                <label for="nameEn">{{ 'STORES.SECTORS.ENGLISH_SECTOR_NAME' | translate }}</label>
                <input
                  type="text"
                  id="nameEn"
                  class="form-control round"
                  [placeholder]="'STORES.SECTORS.ENGLISH_SECTOR_NAME_PLACEHOLDER' | translate"
                  maxlength="100"
                  formControlName="nameEn"
                />
              </div>
            </div>

            <div class="row">
              <div class="form-group col-12 d-flex justify-content-between flex-column flex-sm-row mt-2">
                <button type="submit" class="btn btn-raised btn-success round" [disabled]="isUpdating$ | async">
                  <i class="fa fa-check"></i>
                  <span class="mx-1">{{ 'SHARED.CONFIRM.SAVE' | translate }}</span>
                </button>
                <a class="btn btn-dark round" routerLink="/stores/sectors">
                  <i class="fa fa-undo"></i>
                  <span class="mx-1">{{ 'STORES.SECTORS.SECTOR_RETURN' | translate }}</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
<!-- update sector form ends -->

<!-- confirm modal starts -->
<ng-template #confirmModalRef let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
      {{ 'STORES.SECTORS.CONFIRM_UPDATE_SECTOR_TITLE' | translate }}
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="d()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p class="text-success">
      {{ 'STORES.SECTORS.CONFIRM_UPDATE_SECTOR_MESSAGE' | translate }}
    </p>
  </div>
  <div class="modal-footer">
    <div class="modal-actions">
      <button type="button" class="btn btn-raised btn-success round" (click)="c(); confirm()" ngbAutofocus>
        <i class="fa fa-check"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.SAVE' | translate }}</span>
      </button>
      <button type="button" class="btn btn-outline-dark round" (click)="c()">
        <i class="fa fa-times"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.CANCEL' | translate }}</span>
      </button>
    </div>
  </div>
</ng-template>
<!-- confirm modal ends -->
