<!-- main tasks search modal starts -->
<ng-template #modalRef let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title text-primary" id="modal-basic-title">
      {{ 'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_MAIN_TASKS_LIST' | translate }}
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="d(); closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <!-- main tasks list starts -->
    <div class="row">
      <div class="col-md-12">
        <form class="row" [formGroup]="searchForm" (ngSubmit)="search()">
          <div class="form-group col">
            <input
              id="description"
              type="text"
              class="form-control round"
              [placeholder]="
                'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.PROJECT_MAIN_TASK_DESCRIPTION_SEARCH_PLACEHOLDER' | translate
              "
              formControlName="description"
              (input)="search($event)"
            />
          </div>
          <div class="form-group col-md-auto">
            <button
              type="button"
              class="btn btn-raised btn-primary round"
              [disabled]="isSearching$ | async"
              (click)="initForm(); search()"
            >
              <i class="fa fa-refresh"></i>
            </button>
          </div>
        </form>
      </div>
      <div class="col-md-12" *ngIf="(mainTasks$ | async)?.length; else noItems">
        <div class="row">
          <div class="col-md-12 alert alert-light text-center" role="alert">
            {{ 'SHARED.SEARCH.SEARCH_RESULTS' | translate }} ({{ (paginationInfo$ | async).total | number }})
            {{ 'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.MAIN_TASK' | translate }}
          </div>
          <div class="col-md-12">
            <div class="table-responsive">
              <table class="table text-center">
                <thead>
                  <tr>
                    <th class="fit-width" *ngIf="!closeOnSelect">
                      <a
                        class="primary p-0"
                        (click)="selectMainTask(mainTasksList)"
                        [ngbTooltip]="'SHARED.SEARCH.SELECT_ALL' | translate"
                      >
                        <i class="fa fa-check-circle font-medium-3"></i>
                      </a>
                    </th>
                    <th class="fit-width" *ngIf="closeOnSelect">
                      <i class="fa fa-gear font-medium-3"></i>
                    </th>
                    <th scope="col" class="fit-width"><i class="fa fa-list-ol"></i></th>
                    <th scope="col" class="fit-width">
                      {{ 'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.MAIN_TASK' | translate }}
                    </th>
                    <th scope="col" class="fit-width">
                      {{ 'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.BUDGET' | translate }}
                    </th>
                    <th scope="col" class="fit-width">
                      {{ 'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.COST' | translate }}
                    </th>
                    <th scope="col" class="fit-width">
                      {{ 'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.REMAINING_BUDGET' | translate }}
                    </th>
                    <th scope="col" class="fit-width">
                      {{ 'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.START_DATE' | translate }}
                    </th>
                    <th scope="col" class="fit-width">
                      {{ 'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.END_DATE' | translate }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let mainTask of mainTasks$ | async; trackBy: trackItems; let i = index">
                    <td class="fit-width">
                      <a
                        class="primary p-0"
                        (click)="selectMainTask([mainTask])"
                        [ngbTooltip]="'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.SELECT_PROJECT_MAIN_TASK' | translate"
                      >
                        <i class="fa fa-check-circle-o font-medium-3"></i>
                      </a>
                    </td>
                    <th scope="row" class="fit-width">{{ i + 1 }}</th>
                    <td class="fit-width">{{ mainTask.name | localize: mainTask.nameEn | async }}</td>
                    <td class="fit-width">
                      <span class="badge bg-info round"> {{ mainTask.budget | number: '1.2-2' }}</span>
                    </td>
                    <td class="fit-width">
                      <span class="badge bg-warning round">{{ mainTask.cost | number: '1.2-2' }}</span>
                    </td>
                    <td class="fit-width">
                      <span class="badge bg-danger round">{{ mainTask.remainingBudget | number: '1.2-2' }}</span>
                    </td>
                    <td class="fit-width">
                      <span [ngbTooltip]="mainTask.startDate | date: 'time'"> {{ mainTask.startDate | date }}</span>
                    </td>
                    <td class="fit-width">
                      <span [ngbTooltip]="mainTask.endDate | date: 'time'"> {{ mainTask.endDate | date }}</span>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div class="d-flex justify-content-between p-2">
                <ngb-pagination
                  *ngIf="paginationInfo$ | async; let pagination"
                  maxSize="5"
                  [collectionSize]="pagination.total"
                  [pageSize]="pagination.pageSize"
                  [page]="pagination.page"
                  (pageChange)="pageChanged($event)"
                  boundaryLinks="true"
                >
                  <ng-template ngbPaginationFirst>{{ 'SHARED.PAGINATION.PAGINATION_FIRST' | translate }}</ng-template>
                  <ng-template ngbPaginationFirst>{{
                    'SHARED.PAGINATION.PAGINATION_PREVIOUS' | translate
                  }}</ng-template>
                  <ng-template ngbPaginationFirst>{{ 'SHARED.PAGINATION.PAGINATION_NEXT' | translate }}</ng-template>
                  <ng-template ngbPaginationFirst>{{ 'SHARED.PAGINATION.PAGINATION_LAST' | translate }}</ng-template>
                </ngb-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- main tasks list ends -->
  </div>
  <div class="modal-footer">
    <div class="modal-actions">
      <button type="button" class="btn btn-outline-dark round" (click)="c(); closeModal()">
        <i class="fa fa-times"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.CLOSE' | translate }}</span>
      </button>
    </div>
  </div>
</ng-template>
<!-- main tasks search modal ends -->

<!-- no items template starts -->
<ng-template #noItems>
  <div class="col-md-12 alert alert-warning mt-3 text-center" role="alert">
    {{ 'PROJECTS_MANAGEMENT.PROJECTS.SEARCH_PROJECT_MAIN_TASKS_WITHOUT_ADD_NOT_FOUND' | translate }}
  </div>
</ng-template>
<!-- no items template ends -->
