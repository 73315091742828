import { Action } from '@ngrx/store';

import { AppHttpResponse } from 'shared';
import { Sector } from 'stores/models';

/**
 * The AppSearch-module store regions action types.
 */
export enum SectorsActionType {
  SEARCH_SECTORS = '[AppSearch] [Sectors] Search Sectors',
  SEARCH_SECTORS_FAIL = '[AppSearch] [Sectors] Search Sectors Fail',
  SEARCH_SECTORS_SUCCESS = '[AppSearch] [Sectors] Search Sectors Success',
}

/**
 * Represents a store regions search action.
 */
export class SearchSectors implements Action {
  /**
   * The type of the action.
   */
  readonly type = SectorsActionType.SEARCH_SECTORS;

  /**
   * Fires a new regions search action.
   * @param payload the search parameters,
   * if omitted, all regions will be loaded.
   */
  constructor(
    public payload?: {
      name: string;
      page: number;
    }
  ) {}
}

/**
 * Represents a store regions search-fail action.
 */
export class SearchSectorsFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = SectorsActionType.SEARCH_SECTORS_FAIL;

  /**
   * Fires a new regions search-fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store search-success action.
 */
export class SearchSectorsSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = SectorsActionType.SEARCH_SECTORS_SUCCESS;

  /**
   * Fires a new regions search-success action.
   * @param payload An object contains the list of regions that matches the search criteria
   * in addition to the pagination info.
   */
  constructor(public payload: AppHttpResponse<Sector[]>) {}
}

/**
 * finances-module regions action types.
 */
export type SectorsActions = SearchSectors | SearchSectorsFail | SearchSectorsSuccess;
