<div [formGroup]="parentControl" class="input-group round">
  <input
    class="form-control"
    [formControlName]="controlName"
    [placeholder]="placeholder"
    placement="bottom"
    ngbDatepicker
    #ngbDatepickerInput="ngbDatepicker"
    (closed)="isDropDownOpened.emit(false)"
  />
  <div class="input-group-append">
    <span
      class="input-group-text gradient-light-blue-indigo"
      style="cursor: pointer;"
      (click)="isDropDownOpened.emit(true); ngbDatepickerInput.toggle()"
    >
      <i class="fa fa-calendar"></i>
    </span>
  </div>
</div>
