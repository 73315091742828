import { Action } from '@ngrx/store';

import { AppHttpResponse } from 'shared';
import { ClaimGroup } from 'security/models';

/**
 * The security-module store claims action types.
 */
export enum ClaimsActionType {
  GET_CLAIMS_GROUPS = '[Security] [Claims] Get Claims Groups',
  GET_CLAIMS_GROUPS_FAIL = '[Security] [Claims] Get Claims Groups Fail',
  GET_CLAIMS_GROUPS_SUCCESS = '[Security] [Claims] Get Claims Groups Success',

  GET_CURRENT_LOGGED_IN_USER_CLAIMS_GROUPS = '[Security] [Claims] Get Current Logged In User Claims Groups',
  GET_CURRENT_LOGGED_IN_USER_CLAIMS_GROUPS_FAIL = '[Security] [Claims] Get Current Logged In User Claims Groups Fail',
  GET_CURRENT_LOGGED_IN_USER_CLAIMS_GROUPS_SUCCESS = '[Security] [Claims] Get Current Logged In User Claims Groups Success',
}

/**
 * Represents a store get-claims-groups action.
 */
export class GetClaimsGroups implements Action {
  /**
   * The type of the action.
   */
  readonly type = ClaimsActionType.GET_CLAIMS_GROUPS;

  /**
   * Fires a new  claims groups action.
   * if omitted, all groups will be loaded.
   */
  constructor() {}
}

/**
 * Represents a store claims get-claims-groups-fail action.
 */
export class GetClaimsGroupsFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = ClaimsActionType.GET_CLAIMS_GROUPS_FAIL;

  /**
   * Fires a new get-claims-groups-fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store get-claims-groups-success action.
 */
export class GetClaimsGroupsSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = ClaimsActionType.GET_CLAIMS_GROUPS_SUCCESS;

  /**
   * Fires a new get-claims-groups-success action.
   * @param payload An object contains the list of claims that matches the search criteria
   * in addition to the pagination info.
   */
  constructor(public payload: AppHttpResponse<ClaimGroup[]>) {}
}

/**
 * Represents a store get-current-logged-in-user-claims-groups action.
 */
export class GetCurrentLoggedInUserClaimsGroups implements Action {
  /**
   * The type of the action.
   */
  readonly type = ClaimsActionType.GET_CURRENT_LOGGED_IN_USER_CLAIMS_GROUPS;

  /**
   * Fires a new  claims groups action.
   * if omitted, all groups will be loaded.
   */
  constructor() {}
}

/**
 * Represents a store claims get-current-logged-in-user-claims-groups-fail action.
 */
export class GetCurrentLoggedInUserClaimsGroupsFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = ClaimsActionType.GET_CURRENT_LOGGED_IN_USER_CLAIMS_GROUPS_FAIL;

  /**
   * Fires a new get-current-logged-in-user-claims-groups-fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store get-current-logged-in-user-claims-groups-success action.
 */
export class GetCurrentLoggedInUserClaimsGroupsSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = ClaimsActionType.GET_CURRENT_LOGGED_IN_USER_CLAIMS_GROUPS_SUCCESS;

  /**
   * Fires a new get-current-logged-in-user-claims-groups-success action.
   * @param payload An object contains the list of get-current-logged-in-user-claims-groups that matches the search criteria
   * in addition to the pagination info.
   */
  constructor(public payload: AppHttpResponse<ClaimGroup[]>) {}
}

/**
 * Security-module claims action types.
 */
export type ClaimsActions =
  | GetClaimsGroups
  | GetClaimsGroupsFail
  | GetClaimsGroupsSuccess
  | GetCurrentLoggedInUserClaimsGroups
  | GetCurrentLoggedInUserClaimsGroupsFail
  | GetCurrentLoggedInUserClaimsGroupsSuccess;
