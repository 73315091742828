import { Action } from '@ngrx/store';

import { AppHttpResponse } from 'shared';
import { Account } from 'finances/models';

/**
 * The finances-module store accounts action types.
 */
export enum SystemAccountsActionType {
  FIND_ACCOUNT_RECEIVABLE = '[Finances] [SystemAccounts] Find Account Receivable',
  FIND_ACCOUNT_RECEIVABLE_FAIL = '[Finances] [SystemAccounts] Find Account Receivable Fail',
  FIND_ACCOUNT_RECEIVABLE_SUCCESS = '[Finances] [SystemAccounts] Find Account Receivable Success',

  FIND_REVENUE = '[Finances] [SystemAccounts] Find Revenue',
  FIND_REVENUE_FAIL = '[Finances] [SystemAccounts] Find Revenue Fail',
  FIND_REVENUE_SUCCESS = '[Finances] [SystemAccounts] Find Revenue Success',

  FIND_TAX = '[Finances] [SystemAccounts] Find Tax',
  FIND_TAX_FAIL = '[Finances] [SystemAccounts] Find Tax Fail',
  FIND_TAX_SUCCESS = '[Finances] [SystemAccounts] Find Tax Success',

  FIND_MATERIAL = '[Finances] [SystemAccounts] Find Material Account',
  FIND_MATERIAL_FAIL = '[Finances] [SystemAccounts] Find Material Account Fail',
  FIND_MATERIAL_SUCCESS = '[Finances] [SystemAccounts] Find Material Account Success',

  FIND_INVENTORY_CLEARING = '[Finances] [SystemAccounts] Find Inventory Clearing Account',
  FIND_INVENTORY_CLEARING_FAIL = '[Finances] [SystemAccounts] Find Inventory Clearing Account Fail',
  FIND_INVENTORY_CLEARING_SUCCESS = '[Finances] [SystemAccounts] Find  Clearing Account Success',

  FIND_ACCOUNTS_PAYABLES_CLEARING = '[Finances] [SystemAccounts] Find Accounts Payables Clearing Account',
  FIND_ACCOUNTS_PAYABLES_CLEARING_FAIL = '[Finances] [SystemAccounts] Find Accounts Payables Clearing Account Fail',
  FIND_ACCOUNTS_PAYABLES_CLEARING_SUCCESS = '[Finances] [SystemAccounts] Find Accounts Payables Clearing Account Success',

  FIND_PETTY_CASH_CLEARING = '[Finances] [SystemAccounts] Find Petty Cash Clearing Account ',
  FIND_PETTY_CASH_CLEARING_FAIL = '[Finances] [SystemAccounts] Find Petty Cash Clearing Account  Fail',
  FIND_PETTY_CASH_CLEARING_SUCCESS = '[Finances] [SystemAccounts] Find Petty Cash Clearing Account  Success',

  FIND_LIABILITY = '[Finances] [SystemAccounts] Find Liability Account ',
  FIND_LIABILITY_FAIL = '[Finances] [SystemAccounts] Find Liability Account  Fail',
  FIND_LIABILITY_SUCCESS = '[Finances] [SystemAccounts] Find Liability Account  Success',

  FIND_BANK_ACCOUNT = '[Finances] [SystemAccounts] Find Bank Account ',
  FIND_BANK_ACCOUNT_FAIL = '[Finances] [SystemAccounts] Find Bank Account  Fail',
  FIND_BANK_ACCOUNT_SUCCESS = '[Finances] [SystemAccounts] Find Bank Account  Success',

  FIND_CASH_ACCOUNT = '[Finances] [SystemAccounts] Find Cash Account ',
  FIND_CASH_ACCOUNT_FAIL = '[Finances] [SystemAccounts] Find Cash Account  Fail',
  FIND_CASH_ACCOUNT_SUCCESS = '[Finances] [SystemAccounts] Find Cash Account  Success',

  FIND_CHEQUES_UNDER_COLLECTION = '[Finances] [SystemAccounts] Find Cheques Under Collection Account ',
  FIND_CHEQUES_UNDER_COLLECTION_FAIL = '[Finances] [SystemAccounts] Find Cheques Under Collection Account  Fail',
  FIND_CHEQUES_UNDER_COLLECTION_SUCCESS = '[Finances] [SystemAccounts] Find Cheques Under Collection Account  Success',

  FIND_PAYMENTS_UNDER_COLLECTION = '[Finances] [SystemAccounts] Find Payments Under Collection Account ',
  FIND_PAYMENTS_UNDER_COLLECTION_FAIL = '[Finances] [SystemAccounts] Find Payments Under Collection Account  Fail',
  FIND_PAYMENTS_UNDER_COLLECTION_SUCCESS = '[Finances] [SystemAccounts] Find Payments Under Collection Account  Success',

  FIND_POINT_OF_SALE_CASH = '[Finances] [SystemAccounts] Find Point Of Sale Cash Account ',
  FIND_POINT_OF_SALE_CASH_FAIL = '[Finances] [SystemAccounts] Find Point Of Sale Cash Account  Fail',
  FIND_POINT_OF_SALE_CASH_SUCCESS = '[Finances] [SystemAccounts] Find Point Of Sale Cash Account  Success',

  FIND_COGS = '[Finances] [SystemAccounts] Find COGS Account ',
  FIND_COGS_FAIL = '[Finances] [SystemAccounts] Find COGS Account  Fail',
  FIND_COGS_SUCCESS = '[Finances] [SystemAccounts] Find COGS Account  Success',

  FIND_PROFIT_LOSS = '[Finances] [SystemAccounts] Find Profit Loss Account ',
  FIND_PROFIT_LOSS_FAIL = '[Finances] [SystemAccounts] Find Profit Loss Account  Fail',
  FIND_PROFIT_LOSS_SUCCESS = '[Finances] [SystemAccounts] Find Profit Loss Account  Success',

  FIND_PENDING_CHEQUE = '[Finances] [SystemAccounts] Find Pending Cheque Account ',
  FIND_PENDING_CHEQUE_FAIL = '[Finances] [SystemAccounts] Find Pending Cheque Account  Fail',
  FIND_PENDING_CHEQUE_SUCCESS = '[Finances] [SystemAccounts] Find Pending Cheque Account  Success',

  FIND_PAYMENTS_UNDER_PAYMENT = '[Finances] [SystemAccounts] Find Payments Under Payment Account ',
  FIND_PAYMENTS_UNDER_PAYMENT_FAIL = '[Finances] [SystemAccounts] Find Payments Under Payment Account  Fail',
  FIND_PAYMENTS_UNDER_PAYMENT_SUCCESS = '[Finances] [SystemAccounts] Find Payments Under Payment Account  Success',

  FIND_ADVANCE_EXPENSES_PETTY_CASH = '[Finances] [SystemAccounts] Find Advance Expenses Petty Cash Account ',
  FIND_ADVANCE_EXPENSES_PETTY_CASH_FAIL = '[Finances] [SystemAccounts] Find Advance Expenses Petty Cash Account  Fail',
  FIND_ADVANCE_EXPENSES_PETTY_CASH_SUCCESS = '[Finances] [SystemAccounts] Find Advance Expenses Petty Cash Account  Success',

  FIND_OWNERS_CURRENT_ACCOUNT = '[Finances] [SystemAccounts] Find Owners Current Account ',
  FIND_OWNERS_CURRENT_ACCOUNT_FAIL = '[Finances] [SystemAccounts] Find Owners Current Account  Fail',
  FIND_OWNERS_CURRENT_ACCOUNT_SUCCESS = '[Finances] [SystemAccounts] Find Owners Current Account  Success',

  FIND_ASSET_BROKERAGE_ACCOUNT = '[Finances] [SystemAccounts] Find AssetBrokerage Account ',
  FIND_ASSET_BROKERAGE_ACCOUNT_FAIL = '[Finances] [SystemAccounts] Find AssetBrokerage Account  Fail',
  FIND_ASSET_BROKERAGE_ACCOUNT_SUCCESS = '[Finances] [SystemAccounts] Find AssetBrokerage Account  Success',

  FIND_DESKTOP_DEVICES_ACCOUNT = '[Finances] [SystemAccounts] Find Desktop Devices Account ',
  FIND_DESKTOP_DEVICES_ACCOUNT_FAIL = '[Finances] [SystemAccounts] Find Desktop Devices Account  Fail',
  FIND_DESKTOP_DEVICES_ACCOUNT_SUCCESS = '[Finances] [SystemAccounts] Find Desktop Devices Account  Success',

  FIND_IMPROVEMENTS_TO_RENTED_BUILDINGS_ACCOUNT = '[Finances] [SystemAccounts] Find ImprovementsToRentedBuildings Account ',
  FIND_IMPROVEMENTS_TO_RENTED_BUILDINGS_ACCOUNT_FAIL = '[Finances] [SystemAccounts] Find ImprovementsToRentedBuildings Account  Fail',
  FIND_IMPROVEMENTS_TO_RENTED_BUILDINGS_ACCOUNT_SUCCESS = '[Finances] [SystemAccounts] Find ImprovementsToRentedBuildings Account  Success',

  FIND_MACHINES_AND_EQUIPMENTS_ACCOUNT = '[Finances] [SystemAccounts] Find MachinesAndEquipments Account ',
  FIND_MACHINES_AND_EQUIPMENTS_ACCOUNT_FAIL = '[Finances] [SystemAccounts] Find MachinesAndEquipments Account  Fail',
  FIND_MACHINES_AND_EQUIPMENTS_ACCOUNT_SUCCESS = '[Finances] [SystemAccounts] Find MachinesAndEquipments Account  Success',

  FIND_SALARIES_PAYABLE_ACCOUNT = '[Finances] [SystemAccounts] Find Salaries Payable Account ',
  FIND_SALARIES_PAYABLE_ACCOUNT_FAIL = '[Finances] [SystemAccounts] Find Salaries Payable Account  Fail',
  FIND_SALARIES_PAYABLE_ACCOUNT_SUCCESS = '[Finances] [SystemAccounts] Find Salaries Payable Account  Success',

  /* &&&&&&&&&&&&&&&&&&&&&&&&&& NEW SYSTEMS ACCOUNTS &&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&& */

  FIND_CASH_IN_HAND_ACCOUNT = '[Finances] [SystemAccounts] Find Cash In Hand Account ',
  FIND_CASH_IN_HAND_ACCOUNT_FAIL = '[Finances] [SystemAccounts] Find Cash In Hand Account Fail',
  FIND_CASH_IN_HAND_ACCOUNT_SUCCESS = '[Finances] [SystemAccounts] Find Cash In Hand Account Success',

  FIND_CASH_IN_BANKS_ACCOUNT = '[Finances] [SystemAccounts] Find Cash In Bank Account ',
  FIND_CASH_IN_BANKS_ACCOUNT_FAIL = '[Finances] [SystemAccounts] Find Cash In Bank Account Fail',
  FIND_CASH_IN_BANKS_ACCOUNT_SUCCESS = '[Finances] [SystemAccounts] Find Cash In Bank Account Success',

  FIND_CHECKS_UNDER_COLLECTION_ACCOUNT = '[Finances] [SystemAccounts] Find Checks Under Collection Account ',
  FIND_CHECKS_UNDER_COLLECTION_ACCOUNT_FAIL = '[Finances] [SystemAccounts] Find Checks Under Collection Account Fail',
  FIND_CHECKS_UNDER_COLLECTION_ACCOUNT_SUCCESS = '[Finances] [SystemAccounts] Find Checks Under Collection Account Success',

  FIND_CASH_INVESTMENTS_LESS_THAN_90_DAYS_ACCOUNT = '[Finances] [SystemAccounts] Find Cash Investments Less Than 90 Days Account ',
  FIND_CASH_INVESTMENTS_LESS_THAN_90_DAYS_ACCOUNT_FAIL = '[Finances] [SystemAccounts] Find Cash Investments Less Than 90 Days Account Fail',
  FIND_CASH_INVESTMENTS_LESS_THAN_90_DAYS_ACCOUNT_SUCCESS = '[Finances] [SystemAccounts] Find Cash Investments Less Than 90 Days Account Success',

  FIND_RESTRICTED_CASH_IN_BANKS_ACCOUNT = '[Finances] [SystemAccounts] Find Restricted Cash In Bank Account ',
  FIND_RESTRICTED_CASH_IN_BANKS_ACCOUNT_FAIL = '[Finances] [SystemAccounts] Find Restricted Cash In Bank Account Fail',
  FIND_RESTRICTED_CASH_IN_BANKS_ACCOUNT_SUCCESS = '[Finances] [SystemAccounts] Find Restricted Cash In Bank Account Success',

  FIND_SHORT_TERM_DEPOSITS_ACCOUNT = '[Finances] [SystemAccounts] Find Short Term Deposits Account ',
  FIND_SHORT_TERM_DEPOSITS_ACCOUNT_FAIL = '[Finances] [SystemAccounts] Find Short Term Deposits Account Fail',
  FIND_SHORT_TERM_DEPOSITS_ACCOUNT_SUCCESS = '[Finances] [SystemAccounts] Find Short Term Deposits Account Success',

  FIND_CASH_INVESTMENTS_FOR_TRADING_ACCOUNT = '[Finances] [SystemAccounts] Find Cash Investments For Trading Account ',
  FIND_CASH_INVESTMENTS_FOR_TRADING_ACCOUNT_FAIL = '[Finances] [SystemAccounts] Find Cash Investments For Trading Account Fail',
  FIND_CASH_INVESTMENTS_FOR_TRADING_ACCOUNT_SUCCESS = '[Finances] [SystemAccounts] Find Cash Investments For Trading Account Success',

  FIND_TRADE_DEBTORS_ACCOUNT = '[Finances] [SystemAccounts] Find Trade Debtors Account ',
  FIND_TRADE_DEBTORS_ACCOUNT_FAIL = '[Finances] [SystemAccounts] Find Trade Debtors Account Fail',
  FIND_TRADE_DEBTORS_ACCOUNT_SUCCESS = '[Finances] [SystemAccounts] Find Trade Debtors Account Success',

  FIND_ALLOWANCE_FOR_DOUBTFUL_DEBTS_ACCOUNT = '[Finances] [SystemAccounts] Find Allowance For Doubtful Debts Account ',
  FIND_ALLOWANCE_FOR_DOUBTFUL_DEBTS_ACCOUNT_FAIL = '[Finances] [SystemAccounts] Find Allowance For Doubtful Debts Account Fail',
  FIND_ALLOWANCE_FOR_DOUBTFUL_DEBTS_ACCOUNT_SUCCESS = '[Finances] [SystemAccounts] Find Allowance For Doubtful Debts Account Success',

  FIND_RELATED_PARTIES_ACCOUNT = '[Finances] [SystemAccounts] Find Related Parties Account ',
  FIND_RELATED_PARTIES_ACCOUNT_FAIL = '[Finances] [SystemAccounts] Find Related Parties Account Fail',
  FIND_RELATED_PARTIES_ACCOUNT_SUCCESS = '[Finances] [SystemAccounts] Find Related Parties Account Success',

  FIND_ADVANCES_OF_STAFF_ACCOUNT = '[Finances] [SystemAccounts] Find Advances Of Staff Account ',
  FIND_ADVANCES_OF_STAFF_ACCOUNT_FAIL = '[Finances] [SystemAccounts] Find Advances Of Staff Account Fail',
  FIND_ADVANCES_OF_STAFF_ACCOUNT_SUCCESS = '[Finances] [SystemAccounts] Find Advances Of Staff Account Success',

  FIND_LETTERS_OF_GUARANTEE_ACCOUNT = '[Finances] [SystemAccounts] Find Letters Of Guarantee Account ',
  FIND_LETTERS_OF_GUARANTEE_ACCOUNT_FAIL = '[Finances] [SystemAccounts] Find Letters Of Guarantee Account Fail',
  FIND_LETTERS_OF_GUARANTEE_ACCOUNT_SUCCESS = '[Finances] [SystemAccounts] Find Letters Of Guarantee Account Success',

  FIND_DOCUMENTARY_CREDITS_ACCOUNT = '[Finances] [SystemAccounts] Find Documentary Credits Account ',
  FIND_DOCUMENTARY_CREDITS_ACCOUNT_FAIL = '[Finances] [SystemAccounts] Find Documentary Credits Account Fail',
  FIND_DOCUMENTARY_CREDITS_ACCOUNT_SUCCESS = '[Finances] [SystemAccounts] Find Documentary Credits Account Success',

  FIND_PETTY_CASH_ACCOUNT = '[Finances] [SystemAccounts] Find Petty Cash Account ',
  FIND_PETTY_CASH_ACCOUNT_FAIL = '[Finances] [SystemAccounts] Find Petty Cash Account Fail',
  FIND_PETTY_CASH_ACCOUNT_SUCCESS = '[Finances] [SystemAccounts] Find Petty Cash Account Success',

  FIND_WELL_EXECUTED_DETENTION_ACCOUNT = '[Finances] [SystemAccounts] Find Well Executed Detention Account ',
  FIND_WELL_EXECUTED_DETENTION_ACCOUNT_FAIL = '[Finances] [SystemAccounts] Find Well Executed Detention Account Fail',
  FIND_WELL_EXECUTED_DETENTION_ACCOUNT_SUCCESS = '[Finances] [SystemAccounts] Find Well Executed Detention Account Success',

  FIND_ACCRUED_REVENUE_ACCOUNT = '[Finances] [SystemAccounts] Find Accrued Revenue Account ',
  FIND_ACCRUED_REVENUE_ACCOUNT_FAIL = '[Finances] [SystemAccounts] Find Accrued Revenue Account Fail',
  FIND_ACCRUED_REVENUE_ACCOUNT_SUCCESS = '[Finances] [SystemAccounts] Find Accrued Revenue Account Success',

  FIND_WORK_IN_PROGRESS_UNBILLED_REVENUE_ACCOUNT = '[Finances] [SystemAccounts] Find Work In Progress Unbilled Revenue Account ',
  FIND_WORK_IN_PROGRESS_UNBILLED_REVENUE_ACCOUNT_FAIL = '[Finances] [SystemAccounts] Find Work In Progress Unbilled Revenue Account Fail',
  FIND_WORK_IN_PROGRESS_UNBILLED_REVENUE_ACCOUNT_SUCCESS = '[Finances] [SystemAccounts] Find Work In Progress Unbilled Revenue Account Success',

  FIND_VALUE_ADDED_TAX_DEBIT_ACCOUNT = '[Finances] [SystemAccounts] Find Value Added Tax Debit Account ',
  FIND_VALUE_ADDED_TAX_DEBIT_ACCOUNT_FAIL = '[Finances] [SystemAccounts] Find Value Added Tax Debit Account Fail',
  FIND_VALUE_ADDED_TAX_DEBIT_ACCOUNT_SUCCESS = '[Finances] [SystemAccounts] Find Value Added Tax Debit Account Success',

  FIND_MISCELLANEOUS_DEBTORS_ACCOUNT = '[Finances] [SystemAccounts] Find Miscellaneous Debtors Account ',
  FIND_MISCELLANEOUS_DEBTORS_ACCOUNT_FAIL = '[Finances] [SystemAccounts] Find Miscellaneous Debtors Account Fail',
  FIND_MISCELLANEOUS_DEBTORS_ACCOUNT_SUCCESS = '[Finances] [SystemAccounts] Find Miscellaneous Debtors Account Success',

  FIND_PREPAID_EXPENSES_ACCOUNT = '[Finances] [SystemAccounts] Find Prepaid Expenses Account ',
  FIND_PREPAID_EXPENSES_ACCOUNT_FAIL = '[Finances] [SystemAccounts] Find Prepaid Expenses Account Fail',
  FIND_PREPAID_EXPENSES_ACCOUNT_SUCCESS = '[Finances] [SystemAccounts] Find Prepaid Expenses Account Success',

  FIND_ADVANCE_PAYMENTS_TO_VENDORS_AND_CONTRACTORS_ACCOUNT = '[Finances] [SystemAccounts] Find Advance Payments To Vendors And Contractors Account ',
  FIND_ADVANCE_PAYMENTS_TO_VENDORS_AND_CONTRACTORS_ACCOUNT_FAIL = '[Finances] [SystemAccounts] Find Advance Payments To Vendors And Contractors Account Fail',
  FIND_ADVANCE_PAYMENTS_TO_VENDORS_AND_CONTRACTORS_ACCOUNT_SUCCESS = '[Finances] [SystemAccounts] Find Advance Payments To Vendors And Contractors Account Success',

  FIND_FINANCING_CHARGES_FOR_LEASE_CONTRACTS_ACCOUNT = '[Finances] [SystemAccounts] Find Financing Charges For Lease Contracts Account ',
  FIND_FINANCING_CHARGES_FOR_LEASE_CONTRACTS_ACCOUNT_FAIL = '[Finances] [SystemAccounts] Find Financing Charges For Lease Contracts Account Fail',
  FIND_FINANCING_CHARGES_FOR_LEASE_CONTRACTS_ACCOUNT_SUCCESS = '[Finances] [SystemAccounts] Find Financing Charges For Lease Contracts Account Success',

  FIND_FINANCING_CHARGES_FOR_BANK_LOANS_AND_CREDIT_FACILITIES_ACCOUNT = '[Finances] [SystemAccounts] Find Financing Charges For Bank Loans And Credit Facilities Account ',
  FIND_FINANCING_CHARGES_FOR_BANK_LOANS_AND_CREDIT_FACILITIES_ACCOUNT_FAIL = '[Finances] [SystemAccounts] Find Financing Charges For Bank Loans And Credit Facilities Account Fail',
  FIND_FINANCING_CHARGES_FOR_BANK_LOANS_AND_CREDIT_FACILITIES_ACCOUNT_SUCCESS = '[Finances] [SystemAccounts] Find Financing Charges For Bank Loans And Credit Facilities Account Success',

  FIND_GOODS_AND_MATERIALS_STOCK_ACCOUNT = '[Finances] [SystemAccounts] Find Goods And Materials Stock Account ',
  FIND_GOODS_AND_MATERIALS_STOCK_ACCOUNT_FAIL = '[Finances] [SystemAccounts] Find Goods And Materials Stock Account Fail',
  FIND_GOODS_AND_MATERIALS_STOCK_ACCOUNT_SUCCESS = '[Finances] [SystemAccounts] Find Goods And Materials Stock Account Success',

  FIND_STOCK_IN_PROCESS_ACCOUNT = '[Finances] [SystemAccounts] Find Stock In Process Account ',
  FIND_STOCK_IN_PROCESS_ACCOUNT_FAIL = '[Finances] [SystemAccounts] Find Stock In Process Account Fail',
  FIND_STOCK_IN_PROCESS_ACCOUNT_SUCCESS = '[Finances] [SystemAccounts] Find Stock In Process Account Success',

  FIND_COMPLETED_STOCK_ACCOUNT = '[Finances] [SystemAccounts] Find Completed Stock Account ',
  FIND_COMPLETED_STOCK_ACCOUNT_FAIL = '[Finances] [SystemAccounts] Find Completed Stock Account Fail',
  FIND_COMPLETED_STOCK_ACCOUNT_SUCCESS = '[Finances] [SystemAccounts] Find Completed Stock Account Success',

  FIND_PROVISION_FOR_DAMAGED_OR_OBSOLETE_INVENTORY_ACCOUNT = '[Finances] [SystemAccounts] Find Provision For Damaged Or Obsolete Inventory Account ',
  FIND_PROVISION_FOR_DAMAGED_OR_OBSOLETE_INVENTORY_ACCOUNT_FAIL = '[Finances] [SystemAccounts] Find Provision For Damaged Or Obsolete Inventory Account Fail',
  FIND_PROVISION_FOR_DAMAGED_OR_OBSOLETE_INVENTORY_ACCOUNT_SUCCESS = '[Finances] [SystemAccounts] Find Provision For Damaged Or Obsolete Inventory Account Success',

  FIND_INVESTMENTS_AVAILABLE_FOR_SALE_OR_HELD_TO_MATURITY_ACCOUNT = '[Finances] [SystemAccounts] Find Investments Available For Sale Or Held To Maturity Account ',
  FIND_INVESTMENTS_AVAILABLE_FOR_SALE_OR_HELD_TO_MATURITY_ACCOUNT_FAIL = '[Finances] [SystemAccounts] Find Investments Available For Sale Or Held To Maturity Account Fail',
  FIND_INVESTMENTS_AVAILABLE_FOR_SALE_OR_HELD_TO_MATURITY_ACCOUNT_SUCCESS = '[Finances] [SystemAccounts] Find Investments Available For Sale Or Held To Maturity Account Success',

  FIND_LONG_TERM_DEPOSITS_ACCOUNT = '[Finances] [SystemAccounts] Find Long Term Deposits Account ',
  FIND_LONG_TERM_DEPOSITS_ACCOUNT_FAIL = '[Finances] [SystemAccounts] Find Long Term Deposits Account Fail',
  FIND_LONG_TERM_DEPOSITS_ACCOUNT_SUCCESS = '[Finances] [SystemAccounts] Find Long Term Deposits Account Success',

  FIND_FINANCE_CHARGES_FOR_LEASE_CONTRACTS_NON_CURRENT_ACCOUNT = '[Finances] [SystemAccounts] Find Finance Charges For Lease Contracts Non Current Account ',
  FIND_FINANCE_CHARGES_FOR_LEASE_CONTRACTS_NON_CURRENT_ACCOUNT_FAIL = '[Finances] [SystemAccounts] Find Finance Charges For Lease Contracts Non Current Account Fail',
  FIND_FINANCE_CHARGES_FOR_LEASE_CONTRACTS_NON_CURRENT_ACCOUNT_SUCCESS = '[Finances] [SystemAccounts] Find Finance Charges For Lease Contracts Non Current Account Success',

  FIND_FINANCING_CHARGES_FOR_LOANS_AND_CREDIT_FACILITIES_NON_CURRENT_ACCOUNT = '[Finances] [SystemAccounts] Find Financing Charges For Loans And Credit Facilities Non Current Account ',
  FIND_FINANCING_CHARGES_FOR_LOANS_AND_CREDIT_FACILITIES_NON_CURRENT_ACCOUNT_FAIL = '[Finances] [SystemAccounts] Find Financing Charges For Loans And Credit Facilities Non Current Account Fail',
  FIND_FINANCING_CHARGES_FOR_LOANS_AND_CREDIT_FACILITIES_NON_CURRENT_ACCOUNT_SUCCESS = '[Finances] [SystemAccounts] Find Financing Charges For Loans And Credit Facilities Non Current Account Success',

  FIND_PROJECTS_UNDER_IMPLEMENTATION_ACCOUNT = '[Finances] [SystemAccounts] Find Projects Under Implementation Account ',
  FIND_PROJECTS_UNDER_IMPLEMENTATION_ACCOUNT_FAIL = '[Finances] [SystemAccounts] Find Projects Under Implementation Account Fail',
  FIND_PROJECTS_UNDER_IMPLEMENTATION_ACCOUNT_SUCCESS = '[Finances] [SystemAccounts] Find Projects Under Implementation Account Success',

  FIND_LANDS_ACCOUNT = '[Finances] [SystemAccounts] Find Lands Account ',
  FIND_LANDS_ACCOUNT_FAIL = '[Finances] [SystemAccounts] Find Lands Account  Fail',
  FIND_LANDS_ACCOUNT_SUCCESS = '[Finances] [SystemAccounts] Find Lands Account  Success',

  FIND_BUILDINGS_ACCOUNT = '[Finances] [SystemAccounts] Find Buildings Account ',
  FIND_BUILDINGS_ACCOUNT_FAIL = '[Finances] [SystemAccounts] Find Buildings Account  Fail',
  FIND_BUILDINGS_ACCOUNT_SUCCESS = '[Finances] [SystemAccounts] Find Buildings Account  Success',

  FIND_FURNITURE_ACCOUNT = '[Finances] [SystemAccounts] Find Furniture Account ',
  FIND_FURNITURE_ACCOUNT_FAIL = '[Finances] [SystemAccounts] Find Furniture Account  Fail',
  FIND_FURNITURE_ACCOUNT_SUCCESS = '[Finances] [SystemAccounts] Find Furniture Account  Success',

  FIND_DEVICES_ACCOUNT = '[Finances] [SystemAccounts] Find Devices Account ',
  FIND_DEVICES_ACCOUNT_FAIL = '[Finances] [SystemAccounts] Find Devices Account Fail',
  FIND_DEVICES_ACCOUNT_SUCCESS = '[Finances] [SystemAccounts] Find Devices Account Success',

  FIND_COMPUTERS_ACCOUNT = '[Finances] [SystemAccounts] Find Computers Account ',
  FIND_COMPUTERS_ACCOUNT_FAIL = '[Finances] [SystemAccounts] Find Computers Account  Fail',
  FIND_COMPUTERS_ACCOUNT_SUCCESS = '[Finances] [SystemAccounts] Find Computers Account  Success',

  FIND_EQUIPMENTS_ACCOUNT = '[Finances] [SystemAccounts] Find Equipments Account ',
  FIND_EQUIPMENTS_ACCOUNT_FAIL = '[Finances] [SystemAccounts] Find Equipments Account Fail',
  FIND_EQUIPMENTS_ACCOUNT_SUCCESS = '[Finances] [SystemAccounts] Find Equipments Account Success',

  FIND_CARS_ACCOUNT = '[Finances] [SystemAccounts] Find Cars Account ',
  FIND_CARS_ACCOUNT_FAIL = '[Finances] [SystemAccounts] Find Cars Account  Fail',
  FIND_CARS_ACCOUNT_SUCCESS = '[Finances] [SystemAccounts] Find Cars Account  Success',

  FIND_ASSETS_UNDER_FINANCE_LEASES_ACCOUNT = '[Finances] [SystemAccounts] Find Assets Under Finance Leases Account ',
  FIND_ASSETS_UNDER_FINANCE_LEASES_ACCOUNT_FAIL = '[Finances] [SystemAccounts] Find Assets Under Finance Leases Account Fail',
  FIND_ASSETS_UNDER_FINANCE_LEASES_ACCOUNT_SUCCESS = '[Finances] [SystemAccounts] Find Assets Under Finance Leases Account Success',

  FIND_BUILDING_CONSUMPTION_COMPLEX_ACCOUNT = '[Finances] [SystemAccounts] Find Building Consumption Complex Account ',
  FIND_BUILDING_CONSUMPTION_COMPLEX_ACCOUNT_FAIL = '[Finances] [SystemAccounts] Find Building Consumption Complex Account Fail',
  FIND_BUILDING_CONSUMPTION_COMPLEX_ACCOUNT_SUCCESS = '[Finances] [SystemAccounts] Find Building Consumption Complex Account Success',

  FIND_FURNITURE_CONSUMPTION_COMPLEX_ACCOUNT = '[Finances] [SystemAccounts] Find Furniture Consumption Complex Account ',
  FIND_FURNITURE_CONSUMPTION_COMPLEX_ACCOUNT_FAIL = '[Finances] [SystemAccounts] Find Furniture Consumption Complex Account Fail',
  FIND_FURNITURE_CONSUMPTION_COMPLEX_ACCOUNT_SUCCESS = '[Finances] [SystemAccounts] Find Furniture Consumption Complex Account Success',

  FIND_DEVICES_CONSUMPTION_COMPLEX_ACCOUNT = '[Finances] [SystemAccounts] Find Devices Consumption Complex Account ',
  FIND_DEVICES_CONSUMPTION_COMPLEX_ACCOUNT_FAIL = '[Finances] [SystemAccounts] Find Devices Consumption Complex Account Fail',
  FIND_DEVICES_CONSUMPTION_COMPLEX_ACCOUNT_SUCCESS = '[Finances] [SystemAccounts] Find Devices Consumption Complex Account Success',
  FIND_COMPUTERS_CONSUMPTION_COMPLEX_ACCOUNT = '[Finances] [SystemAccounts] Find Computers Consumption Complex Account ',
  FIND_COMPUTERS_CONSUMPTION_COMPLEX_ACCOUNT_FAIL = '[Finances] [SystemAccounts] Find Computers Consumption Complex Account Fail',
  FIND_COMPUTERS_CONSUMPTION_COMPLEX_ACCOUNT_SUCCESS = '[Finances] [SystemAccounts] Find Computers Consumption Complex Account Success',

  FIND_EQUIPMENTS_CONSUMPTION_COMPLEX_ACCOUNT = '[Finances] [SystemAccounts] Find Equipments Consumption Complex Account ',
  FIND_EQUIPMENTS_CONSUMPTION_COMPLEX_ACCOUNT_FAIL = '[Finances] [SystemAccounts] Find Equipments Consumption Complex Account Fail',
  FIND_EQUIPMENTS_CONSUMPTION_COMPLEX_ACCOUNT_SUCCESS = '[Finances] [SystemAccounts] Find Equipments Consumption Complex Account Success',

  FIND_CARS_CONSUMPTION_COMPLEX_ACCOUNT = '[Finances] [SystemAccounts] Find Cars Consumption Complex Account ',
  FIND_CARS_CONSUMPTION_COMPLEX_ACCOUNT_FAIL = '[Finances] [SystemAccounts] Find Cars Consumption Complex Account Fail',
  FIND_CARS_CONSUMPTION_COMPLEX_ACCOUNT_SUCCESS = '[Finances] [SystemAccounts] Find Cars Consumption Complex Account Success',

  FIND_POOLED_DEPRECIATION_OF_ASSETS_UNDER_FINANCE_LEASE_CONTRACTS_ACCOUNT = '[Finances] [SystemAccounts] Find Pooled Depreciation Of Assets Under Finance Lease Contracts Account ',
  FIND_POOLED_DEPRECIATION_OF_ASSETS_UNDER_FINANCE_LEASE_CONTRACTS_ACCOUNT_FAIL = '[Finances] [SystemAccounts] Find Pooled Depreciation Of Assets Under Finance Lease Contracts Account Fail',
  FIND_POOLED_DEPRECIATION_OF_ASSETS_UNDER_FINANCE_LEASE_CONTRACTS_ACCOUNT_SUCCESS = '[Finances] [SystemAccounts] Find Pooled Depreciation Of Assets Under Finance Lease Contracts Account Success',

  FIND_FAME_ACCOUNT = '[Finances] [SystemAccounts] Find Fame Account ',
  FIND_FAME_ACCOUNT_FAIL = '[Finances] [SystemAccounts] Find Fame Account Fail',
  FIND_FAME_ACCOUNT_SUCCESS = '[Finances] [SystemAccounts] Find Fame Account Success',

  FIND_SOFTWARE_AND_SYSTEMS_ACCOUNT = '[Finances] [SystemAccounts] Find Software And Systems Account ',
  FIND_SOFTWARE_AND_SYSTEMS_ACCOUNT_FAIL = '[Finances] [SystemAccounts] Find Software And Systems Account Fail',
  FIND_SOFTWARE_AND_SYSTEMS_ACCOUNT_SUCCESS = '[Finances] [SystemAccounts] Find Software And Systems Account Success',

  FIND_PATENTS_AND_TRADEMARKS_ACCOUNT = '[Finances] [SystemAccounts] Find Patents And Trademarks Account ',
  FIND_PATENTS_AND_TRADEMARKS_ACCOUNT_FAIL = '[Finances] [SystemAccounts] Find Patents And Trademarks Account Fail',
  FIND_PATENTS_AND_TRADEMARKS_ACCOUNT_SUCCESS = '[Finances] [SystemAccounts] Find Patents And Trademarks Account Success',

  FIND_FAME_EXTINGUISHING_COMPLEX_ACCOUNT = '[Finances] [SystemAccounts] Find Fame Extinguishing Complex Account ',
  FIND_FAME_EXTINGUISHING_COMPLEX_ACCOUNT_FAIL = '[Finances] [SystemAccounts] Find Fame Extinguishing Complex Account Fail',
  FIND_FAME_EXTINGUISHING_COMPLEX_ACCOUNT_SUCCESS = '[Finances] [SystemAccounts] Find Fame Extinguishing Complex Account Success',

  FIND_SOFTWARE_AND_HARDWARE_EXTINGUISHING_COMPLEX_ACCOUNT = '[Finances] [SystemAccounts] Find Software And Hardware Extinguishing Complex Account ',
  FIND_SOFTWARE_AND_HARDWARE_EXTINGUISHING_COMPLEX_ACCOUNT_FAIL = '[Finances] [SystemAccounts] Find Software And Hardware Extinguishing Complex Account Fail',
  FIND_SOFTWARE_AND_HARDWARE_EXTINGUISHING_COMPLEX_ACCOUNT_SUCCESS = '[Finances] [SystemAccounts] Find Software And Hardware Extinguishing Complex Account Success',

  FIND_PATENT_AND_TRADEMARK_EXTINGUISHING_COMPLEX_ACCOUNT = '[Finances] [SystemAccounts] Find Patent And Trademark Extinguishing Complex Account ',
  FIND_PATENT_AND_TRADEMARK_EXTINGUISHING_COMPLEX_ACCOUNT_FAIL = '[Finances] [SystemAccounts] Find Patent And Trademark Extinguishing Complex Account Fail',
  FIND_PATENT_AND_TRADEMARK_EXTINGUISHING_COMPLEX_ACCOUNT_SUCCESS = '[Finances] [SystemAccounts] Find Patent And Trademark Extinguishing Complex Account Success',

  FIND_REAL_ESTATE_INVESTMENT_LANDS_ACCOUNT = '[Finances] [SystemAccounts] Find Real Estate Investment Lands Account ',
  FIND_REAL_ESTATE_INVESTMENT_LANDS_ACCOUNT_FAIL = '[Finances] [SystemAccounts] Find Real Estate Investment Lands Account Fail',
  FIND_REAL_ESTATE_INVESTMENT_LANDS_ACCOUNT_SUCCESS = '[Finances] [SystemAccounts] Find Real Estate Investment Lands Account Success',

  FIND_REAL_ESTATE_INVESTMENT_BUILDINGS_ACCOUNT = '[Finances] [SystemAccounts] Find Real Estate Investment Buildings Account ',
  FIND_REAL_ESTATE_INVESTMENT_BUILDINGS_ACCOUNT_FAIL = '[Finances] [SystemAccounts] Find Real Estate Investment Buildings Account Fail',
  FIND_REAL_ESTATE_INVESTMENT_BUILDINGS_ACCOUNT_SUCCESS = '[Finances] [SystemAccounts] Find Real Estate Investment Buildings Account Success',

  FIND_REAL_ESTATE_INVESTMENT_BUILDING_CONSUMPTION_COMPLEX_ACCOUNT = '[Finances] [SystemAccounts] Find Real Estate Investment Building Consumption Complex Account ',
  FIND_REAL_ESTATE_INVESTMENT_BUILDING_CONSUMPTION_COMPLEX_ACCOUNT_FAIL = '[Finances] [SystemAccounts] Find Real Estate Investment Building Consumption Complex Account Fail',
  FIND_REAL_ESTATE_INVESTMENT_BUILDING_CONSUMPTION_COMPLEX_ACCOUNT_SUCCESS = '[Finances] [SystemAccounts] Find Real Estate Investment Building Consumption Complex Account Success',

  FIND_INVESTMENTS_IN_SUBSIDIARIES_ACCOUNT = '[Finances] [SystemAccounts] Find Investments In Subsidiaries Account ',
  FIND_INVESTMENTS_IN_SUBSIDIARIES_ACCOUNT_FAIL = '[Finances] [SystemAccounts] Find Investments In Subsidiaries Account Fail',
  FIND_INVESTMENTS_IN_SUBSIDIARIES_ACCOUNT_SUCCESS = '[Finances] [SystemAccounts] Find Investments In Subsidiaries Account Success',

  FIND_INVESTMENTS_IN_ASSOCIATES_ACCOUNT = '[Finances] [SystemAccounts] Find Investments In Associates Account ',
  FIND_INVESTMENTS_IN_ASSOCIATES_ACCOUNT_FAIL = '[Finances] [SystemAccounts] Find Investments In Associates Account Fail',
  FIND_INVESTMENTS_IN_ASSOCIATES_ACCOUNT_SUCCESS = '[Finances] [SystemAccounts] Find Investments In Associates Account Success',

  FIND_INVESTMENTS_IN_JOINT_VENTURES_ACCOUNT = '[Finances] [SystemAccounts] Find Investments In Joint Ventures Account ',
  FIND_INVESTMENTS_IN_JOINT_VENTURES_ACCOUNT_FAIL = '[Finances] [SystemAccounts] Find Investments In Joint Ventures Account Fail',
  FIND_INVESTMENTS_IN_JOINT_VENTURES_ACCOUNT_SUCCESS = '[Finances] [SystemAccounts] Find Investments In Joint Ventures Account Success',

  FIND_LOAN_INSTALLMENTS_THE_CURRENT_PART_ACCOUNT = '[Finances] [SystemAccounts] Find Loan Installments The Current Part Account ',
  FIND_LOAN_INSTALLMENTS_THE_CURRENT_PART_ACCOUNT_FAIL = '[Finances] [SystemAccounts] Find Loan Installments The Current Part Account Fail',
  FIND_LOAN_INSTALLMENTS_THE_CURRENT_PART_ACCOUNT_SUCCESS = '[Finances] [SystemAccounts] Find Loan Installments The Current Part Account Success',

  FIND_CURRENT_CONTRACTS_FOR_RENT_THE_CURRENT_PART_ACCOUNT = '[Finances] [SystemAccounts] Find Current Contracts For Rent The Current Part Account ',
  FIND_CURRENT_CONTRACTS_FOR_RENT_THE_CURRENT_PART_ACCOUNT_FAIL = '[Finances] [SystemAccounts] Find Current Contracts For Rent The Current Part Account Fail',
  FIND_CURRENT_CONTRACTS_FOR_RENT_THE_CURRENT_PART_ACCOUNT_SUCCESS = '[Finances] [SystemAccounts] Find Current Contracts For Rent The Current Part Account Success',

  FIND_TRADE_CREDITORS_ACCOUNT = '[Finances] [SystemAccounts] Find Trade Creditors Account ',
  FIND_TRADE_CREDITORS_ACCOUNT_FAIL = '[Finances] [SystemAccounts] Find Trade Creditors Account Fail',
  FIND_TRADE_CREDITORS_ACCOUNT_SUCCESS = '[Finances] [SystemAccounts] Find Trade Creditors Account Success',

  FIND_VENDORS_BROKER_ACCOUNT = '[Finances] [SystemAccounts] Find Vendors Broker Account ',
  FIND_VENDORS_BROKER_ACCOUNT_FAIL = '[Finances] [SystemAccounts] Find Vendors Broker Account Fail',
  FIND_VENDORS_BROKER_ACCOUNT_SUCCESS = '[Finances] [SystemAccounts] Find Vendors Broker Account Success',

  FIND_CREDIT_FACILITIES_OVERDRAFT_ACCOUNT = '[Finances] [SystemAccounts] Find Credit Facilities Overdraft Account ',
  FIND_CREDIT_FACILITIES_OVERDRAFT_ACCOUNT_FAIL = '[Finances] [SystemAccounts] Find Credit Facilities Overdraft Account Fail',
  FIND_CREDIT_FACILITIES_OVERDRAFT_ACCOUNT_SUCCESS = '[Finances] [SystemAccounts] Find Credit Facilities Overdraft Account Success',

  FIND_CREDITORS_WITHHOLDING_BALANCES_ACCOUNT = '[Finances] [SystemAccounts] Find Creditors Withholding Balances Account ',
  FIND_CREDITORS_WITHHOLDING_BALANCES_ACCOUNT_FAIL = '[Finances] [SystemAccounts] Find Creditors Withholding Balances Account Fail',
  FIND_CREDITORS_WITHHOLDING_BALANCES_ACCOUNT_SUCCESS = '[Finances] [SystemAccounts] Find Creditors Withholding Balances Account Success',

  FIND_PAYABLE_EXPENSES_ACCOUNT = '[Finances] [SystemAccounts] Find Payable Expenses Account ',
  FIND_PAYABLE_EXPENSES_ACCOUNT_FAIL = '[Finances] [SystemAccounts] Find Payable Expenses Account Fail',
  FIND_PAYABLE_EXPENSES_ACCOUNT_SUCCESS = '[Finances] [SystemAccounts] Find Payable Expenses Account Success',

  FIND_EMPLOYEES_ENTITLEMENTS_SALARIES_ACCOUNT = '[Finances] [SystemAccounts] Find Employees Entitlements Salaries Account ',
  FIND_EMPLOYEES_ENTITLEMENTS_SALARIES_ACCOUNT_FAIL = '[Finances] [SystemAccounts] Find Employees Entitlements Salaries Account Fail',
  FIND_EMPLOYEES_ENTITLEMENTS_SALARIES_ACCOUNT_SUCCESS = '[Finances] [SystemAccounts] Find Employees Entitlements Salaries Account Success',

  FIND_EMPLOYEES_ENTITLEMENTS_BENEFITS_ACCOUNT = '[Finances] [SystemAccounts] Find Employees Entitlements Benefits Account ',
  FIND_EMPLOYEES_ENTITLEMENTS_BENEFITS_ACCOUNT_FAIL = '[Finances] [SystemAccounts] Find Employees Entitlements Benefits Account Fail',
  FIND_EMPLOYEES_ENTITLEMENTS_BENEFITS_ACCOUNT_SUCCESS = '[Finances] [SystemAccounts] Find Employees Entitlements Benefits Account Success',

  FIND_EMPLOYEES_ENTITLEMENTS_TRAVELING_TICKETS_ACCOUNT = '[Finances] [SystemAccounts] Find Employees Entitlements Traveling Tickets Account ',
  FIND_EMPLOYEES_ENTITLEMENTS_TRAVELING_TICKETS_ACCOUNT_FAIL = '[Finances] [SystemAccounts] Find Employees Entitlements Traveling Tickets Account Fail',
  FIND_EMPLOYEES_ENTITLEMENTS_TRAVELING_TICKETS_ACCOUNT_SUCCESS = '[Finances] [SystemAccounts] Find Employees Entitlements Traveling Tickets Account Success',

  FIND_ADVANCE_REVENUE_ACCOUNT = '[Finances] [SystemAccounts] Find Advance Revenue Account ',
  FIND_ADVANCE_REVENUE_ACCOUNT_FAIL = '[Finances] [SystemAccounts] Find Advance Revenue Account Fail',
  FIND_ADVANCE_REVENUE_ACCOUNT_SUCCESS = '[Finances] [SystemAccounts] Find Advance Revenue Account Success',

  FIND_VALUE_ADDED_TAX_CREDIT_ACCOUNT = '[Finances] [SystemAccounts] Find Value Added Tax Credit Account ',
  FIND_VALUE_ADDED_TAX_CREDIT_ACCOUNT_FAIL = '[Finances] [SystemAccounts] Find Value Added Tax Credit Account Fail',
  FIND_VALUE_ADDED_TAX_CREDIT_ACCOUNT_SUCCESS = '[Finances] [SystemAccounts] Find Value Added Tax Credit Account Success',

  FIND_RECEIVABLES_STAFF_ACCOUNT = '[Finances] [SystemAccounts] Find Receivables Staff Account ',
  FIND_RECEIVABLES_STAFF_ACCOUNT_FAIL = '[Finances] [SystemAccounts] Find Receivables Staff Account Fail',
  FIND_RECEIVABLES_STAFF_ACCOUNT_SUCCESS = '[Finances] [SystemAccounts] Find Receivables Staff Account Success',

  FIND_OTHER_CURRENT_LIABILITIES_RELATED_PARTIES_ACCOUNT = '[Finances] [SystemAccounts] Find Other Current Liabilities Related Parties Account ',
  FIND_OTHER_CURRENT_LIABILITIES_RELATED_PARTIES_ACCOUNT_FAIL = '[Finances] [SystemAccounts] Find Other Current Liabilities Related Parties Account Fail',
  FIND_OTHER_CURRENT_LIABILITIES_RELATED_PARTIES_ACCOUNT_SUCCESS = '[Finances] [SystemAccounts] Find Other Current Liabilities Related Parties Account Success',

  FIND_MISCELLANEOUS_CREDITORS_ACCOUNT = '[Finances] [SystemAccounts] Find Miscellaneous Creditors Account ',
  FIND_MISCELLANEOUS_CREDITORS_ACCOUNT_FAIL = '[Finances] [SystemAccounts] Find Miscellaneous Creditors Account Fail',
  FIND_MISCELLANEOUS_CREDITORS_ACCOUNT_SUCCESS = '[Finances] [SystemAccounts] Find Miscellaneous Creditors Account Success',

  FIND_DIVIDEND_PAYABLE_ACCOUNT = '[Finances] [SystemAccounts] Find Dividend Payable Account ',
  FIND_DIVIDEND_PAYABLE_ACCOUNT_FAIL = '[Finances] [SystemAccounts] Find Dividend Payable Account Fail',
  FIND_DIVIDEND_PAYABLE_ACCOUNT_SUCCESS = '[Finances] [SystemAccounts] Find Dividend Payable Account Success',

  FIND_ZAKAT_PROVISION_ACCOUNT = '[Finances] [SystemAccounts] Find Zakat Provision Account ',
  FIND_ZAKAT_PROVISION_ACCOUNT_FAIL = '[Finances] [SystemAccounts] Find Zakat Provision Account Fail',
  FIND_ZAKAT_PROVISION_ACCOUNT_SUCCESS = '[Finances] [SystemAccounts] Find Zakat Provision Account Success',

  FIND_INCOME_TAX_PROVISION_ACCOUNT = '[Finances] [SystemAccounts] Find Income Tax Provision Account ',
  FIND_INCOME_TAX_PROVISION_ACCOUNT_FAIL = '[Finances] [SystemAccounts] Find Income Tax Provision Account Fail',
  FIND_INCOME_TAX_PROVISION_ACCOUNT_SUCCESS = '[Finances] [SystemAccounts] Find Income Tax Provision Account Success',

  FIND_PROVISION_FOR_WITHHOLDING_TAX_ACCOUNT = '[Finances] [SystemAccounts] Find Provision For Withholding Tax Account ',
  FIND_PROVISION_FOR_WITHHOLDING_TAX_ACCOUNT_FAIL = '[Finances] [SystemAccounts] Find Provision For Withholding Tax Account Fail',
  FIND_PROVISION_FOR_WITHHOLDING_TAX_ACCOUNT_SUCCESS = '[Finances] [SystemAccounts] Find Provision For Withholding Tax Account Success',

  FIND_BANK_LOANS_ACCOUNT = '[Finances] [SystemAccounts] Find Bank Loans Account ',
  FIND_BANK_LOANS_ACCOUNT_FAIL = '[Finances] [SystemAccounts] Find Bank Loans Account Fail',
  FIND_BANK_LOANS_ACCOUNT_SUCCESS = '[Finances] [SystemAccounts] Find Bank Loans Account Success',

  FIND_LEASE_LIABILITIES_NON_CURRENT_PORTION_ACCOUNT = '[Finances] [SystemAccounts] Find Lease Liabilities Non Current Portion Account ',
  FIND_LEASE_LIABILITIES_NON_CURRENT_PORTION_ACCOUNT_FAIL = '[Finances] [SystemAccounts] Find Lease Liabilities Non Current Portion Account Fail',
  FIND_LEASE_LIABILITIES_NON_CURRENT_PORTION_ACCOUNT_SUCCESS = '[Finances] [SystemAccounts] Find Lease Liabilities Non Current Portion Account Success',

  FIND_PROVISION_FOR_END_OF_SEVERANCE_PAY_ACCOUNT = '[Finances] [SystemAccounts] Find Provision For End Of Severance Pay Account ',
  FIND_PROVISION_FOR_END_OF_SEVERANCE_PAY_ACCOUNT_FAIL = '[Finances] [SystemAccounts] Find Provision For End Of Severance Pay Account Fail',
  FIND_PROVISION_FOR_END_OF_SEVERANCE_PAY_ACCOUNT_SUCCESS = '[Finances] [SystemAccounts] Find Provision For End Of Severance Pay Account Success',

  FIND_CAPITAL_ACCOUNT = '[Finances] [SystemAccounts] Find Capital Account ',
  FIND_CAPITAL_ACCOUNT_FAIL = '[Finances] [SystemAccounts] Find Capital Account Fail',
  FIND_CAPITAL_ACCOUNT_SUCCESS = '[Finances] [SystemAccounts] Find Capital Account Success',

  FIND_REGULAR_RESERVE_ACCOUNT = '[Finances] [SystemAccounts] Find Regular Reserve Account ',
  FIND_REGULAR_RESERVE_ACCOUNT_FAIL = '[Finances] [SystemAccounts] Find Regular Reserve Account Fail',
  FIND_REGULAR_RESERVE_ACCOUNT_SUCCESS = '[Finances] [SystemAccounts] Find Regular Reserve Account Success',

  FIND_GENERAL_RESERVE_ACCOUNT = '[Finances] [SystemAccounts] Find General Reserve Account ',
  FIND_GENERAL_RESERVE_ACCOUNT_FAIL = '[Finances] [SystemAccounts] Find General Reserve Account Fail',
  FIND_GENERAL_RESERVE_ACCOUNT_SUCCESS = '[Finances] [SystemAccounts] Find General Reserve Account Success',

  FIND_RETAINED_EARNINGS_ACCOUNT = '[Finances] [SystemAccounts] Find Retained Earnings Account ',
  FIND_RETAINED_EARNINGS_ACCOUNT_FAIL = '[Finances] [SystemAccounts] Find Retained Earnings Account Fail',
  FIND_RETAINED_EARNINGS_ACCOUNT_SUCCESS = '[Finances] [SystemAccounts] Find Retained Earnings Account Success',

  FIND_PROFIT_AND_LOSS_BROKER_ACCOUNT = '[Finances] [SystemAccounts] Find Profit And Loss Broker Account',
  FIND_PROFIT_AND_LOSS_BROKER_ACCOUNT_FAIL = '[Finances] [SystemAccounts] Find Profit And Loss Broker Account Fail',
  FIND_PROFIT_AND_LOSS_BROKER_ACCOUNT_SUCCESS = '[Finances] [SystemAccounts] Find Profit And Loss Broker Account Success',

  FIND_ADDITIONAL_CAPITAL_ACCOUNT = '[Finances] [SystemAccounts] Find Additional Capital Account ',
  FIND_ADDITIONAL_CAPITAL_ACCOUNT_FAIL = '[Finances] [SystemAccounts] Find Additional Capital Account Fail',
  FIND_ADDITIONAL_CAPITAL_ACCOUNT_SUCCESS = '[Finances] [SystemAccounts] Find Additional Capital Account Success',

  FIND_MAIN_ACTIVITIES_SALES_REVENUE_ACCOUNT = '[Finances] [SystemAccounts] Find Main Activities Sales Revenue Account ',
  FIND_MAIN_ACTIVITIES_SALES_REVENUE_ACCOUNT_FAIL = '[Finances] [SystemAccounts] Find Main Activities Sales Revenue Account Fail',
  FIND_MAIN_ACTIVITIES_SALES_REVENUE_ACCOUNT_SUCCESS = '[Finances] [SystemAccounts] Find Main Activities Sales Revenue Account Success',

  FIND_GAIN_LOSS_ON_DISPOSAL_OF_PROPERTY_ACCOUNT = '[Finances] [SystemAccounts] Find Gain Loss On Disposal Of Property Account ',
  FIND_GAIN_LOSS_ON_DISPOSAL_OF_PROPERTY_ACCOUNT_FAIL = '[Finances] [SystemAccounts] Find Gain Loss On Disposal Of Property Account Fail',
  FIND_GAIN_LOSS_ON_DISPOSAL_OF_PROPERTY_ACCOUNT_SUCCESS = '[Finances] [SystemAccounts] Find Gain Loss On Disposal Of Property Account Success',

  FIND_GAIN_LOSS_ON_INVESTMENT_VALUATION_ACCOUNT = '[Finances] [SystemAccounts] Find Gain Loss On Investment Valuation Account ',
  FIND_GAIN_LOSS_ON_INVESTMENT_VALUATION_ACCOUNT_FAIL = '[Finances] [SystemAccounts] Find Gain Loss On Investment Valuation Account Fail',
  FIND_GAIN_LOSS_ON_INVESTMENT_VALUATION_ACCOUNT_SUCCESS = '[Finances] [SystemAccounts] Find Gain Loss On Investment Valuation Account Success',

  FIND_SUPPORT_REVENUE_ACCOUNT = '[Finances] [SystemAccounts] Find Support Revenue Account ',
  FIND_SUPPORT_REVENUE_ACCOUNT_FAIL = '[Finances] [SystemAccounts] Find Support Revenue Account Fail',
  FIND_SUPPORT_REVENUE_ACCOUNT_SUCCESS = '[Finances] [SystemAccounts] Find Support Revenue Account Success',

  FIND_OTHER_MISCELLANEOUS_INCOME_ACCOUNT = '[Finances] [SystemAccounts] Find Other Miscellaneous Income Account ',
  FIND_OTHER_MISCELLANEOUS_INCOME_ACCOUNT_FAIL = '[Finances] [SystemAccounts] Find Other Miscellaneous Income Account Fail',
  FIND_OTHER_MISCELLANEOUS_INCOME_ACCOUNT_SUCCESS = '[Finances] [SystemAccounts] Find Other Miscellaneous Income Account Success',

  FIND_USED_OF_MATERIAL_ACCOUNT = '[Finances] [SystemAccounts] Find Used Of Material Account ',
  FIND_USED_OF_MATERIAL_ACCOUNT_FAIL = '[Finances] [SystemAccounts] Find Used Of Material Account Fail',
  FIND_USED_OF_MATERIAL_ACCOUNT_SUCCESS = '[Finances] [SystemAccounts] Find Used Of Material Account Success',

  FIND_NON_INVENTORY_MATERIAL_COSTS_ACCOUNT = '[Finances] [SystemAccounts] Find Non Inventory Material Costs Account ',
  FIND_NON_INVENTORY_MATERIAL_COSTS_ACCOUNT_FAIL = '[Finances] [SystemAccounts] Find Non Inventory Material Costs Account Fail',
  FIND_NON_INVENTORY_MATERIAL_COSTS_ACCOUNT_SUCCESS = '[Finances] [SystemAccounts] Find Non Inventory Material Costs Account Success',

  FIND_RENT_ACCOUNT = '[Finances] [SystemAccounts] Find Rent Account ',
  FIND_RENT_ACCOUNT_FAIL = '[Finances] [SystemAccounts] Find Rent Account Fail',
  FIND_RENT_ACCOUNT_SUCCESS = '[Finances] [SystemAccounts] Find Rent Account Success',

  FIND_LOCAL_LABOR_ACCOUNT = '[Finances] [SystemAccounts] Find Local Labor Account ',
  FIND_LOCAL_LABOR_ACCOUNT_FAIL = '[Finances] [SystemAccounts] Find Local Labor Account Fail',
  FIND_LOCAL_LABOR_ACCOUNT_SUCCESS = '[Finances] [SystemAccounts] Find Local Labor Account Success',

  FIND_SALARIES_AND_WAGES_EMPLOYEE_PROTECTION_ACCOUNT = '[Finances] [SystemAccounts] Find Salaries And Wages Employee Protection Account ',
  FIND_SALARIES_AND_WAGES_EMPLOYEE_PROTECTION_ACCOUNT_FAIL = '[Finances] [SystemAccounts] Find Salaries And Wages Employee Protection Account Fail',
  FIND_SALARIES_AND_WAGES_EMPLOYEE_PROTECTION_ACCOUNT_SUCCESS = '[Finances] [SystemAccounts] Find Salaries And Wages Employee Protection Account Success',

  FIND_BENEFIT_TO_EMPLOYEES_ALL_COSTS_EXCEPT_WAGE_PROTECTION_ELEMENTS_ACCOUNT = '[Finances] [SystemAccounts] Find Benefit To Employees All Costs Except Wage Protection Elements Account ',
  FIND_BENEFIT_TO_EMPLOYEES_ALL_COSTS_EXCEPT_WAGE_PROTECTION_ELEMENTS_ACCOUNT_FAIL = '[Finances] [SystemAccounts] Find Benefit To Employees All Costs Except Wage Protection Elements Account Fail',
  FIND_BENEFIT_TO_EMPLOYEES_ALL_COSTS_EXCEPT_WAGE_PROTECTION_ELEMENTS_ACCOUNT_SUCCESS = '[Finances] [SystemAccounts] Find Benefit To Employees All Costs Except Wage Protection Elements Account Success',

  FIND_WORK_MISSIONS_AND_OVERTIME_ACCOUNT = '[Finances] [SystemAccounts] Find Work Missions And Overtime Account ',
  FIND_WORK_MISSIONS_AND_OVERTIME_ACCOUNT_FAIL = '[Finances] [SystemAccounts] Find Work Missions And Overtime Account Fail',
  FIND_WORK_MISSIONS_AND_OVERTIME_ACCOUNT_SUCCESS = '[Finances] [SystemAccounts] Find Work Missions And Overtime Account Success',

  FIND_REPAIR_AND_MAINTENANCE_ACCOUNT = '[Finances] [SystemAccounts] Find Repair And Maintenance Account ',
  FIND_REPAIR_AND_MAINTENANCE_ACCOUNT_FAIL = '[Finances] [SystemAccounts] Find Repair And Maintenance Account Fail',
  FIND_REPAIR_AND_MAINTENANCE_ACCOUNT_SUCCESS = '[Finances] [SystemAccounts] Find Repair And Maintenance Account Success',

  FIND_GOVERNMENT_FEES_AND_SUBSCRIPTIONS_ACCOUNT = '[Finances] [SystemAccounts] Find Government Fees And Subscriptions Account ',
  FIND_GOVERNMENT_FEES_AND_SUBSCRIPTIONS_ACCOUNT_FAIL = '[Finances] [SystemAccounts] Find Government Fees And Subscriptions Account Fail',
  FIND_GOVERNMENT_FEES_AND_SUBSCRIPTIONS_ACCOUNT_SUCCESS = '[Finances] [SystemAccounts] Find Government Fees And Subscriptions Account Success',

  FIND_THE_COST_OF_PUBLIC_SERVICES_ACCOUNT = '[Finances] [SystemAccounts] Find Cost Of Public Services Account ',
  FIND_THE_COST_OF_PUBLIC_SERVICES_ACCOUNT_FAIL = '[Finances] [SystemAccounts] Find Cost Of Public Services Account Fail',
  FIND_THE_COST_OF_PUBLIC_SERVICES_ACCOUNT_SUCCESS = '[Finances] [SystemAccounts] Find Cost Of Public Services Account Success',

  FIND_HOSPITALITY_AND_CLEANLINESS_ACCOUNT = '[Finances] [SystemAccounts] Find Hospitality And Cleanliness Account ',
  FIND_HOSPITALITY_AND_CLEANLINESS_ACCOUNT_FAIL = '[Finances] [SystemAccounts] Find Hospitality And Cleanliness Account Fail',
  FIND_HOSPITALITY_AND_CLEANLINESS_ACCOUNT_SUCCESS = '[Finances] [SystemAccounts] Find Hospitality And Cleanliness Account Success',

  FIND_OFFICE_CONSUMABLES_ACCOUNT = '[Finances] [SystemAccounts] Find Office Consumables Account ',
  FIND_OFFICE_CONSUMABLES_ACCOUNT_FAIL = '[Finances] [SystemAccounts] Find Office Consumables Account Fail',
  FIND_OFFICE_CONSUMABLES_ACCOUNT_SUCCESS = '[Finances] [SystemAccounts] Find Office Consumables Account Success',

  FIND_FINANCE_CHARGES_LOANS_AND_BANK_FACILITIES_ACCOUNT = '[Finances] [SystemAccounts] Find Finance Charges Loans And Bank Facilities Account ',
  FIND_FINANCE_CHARGES_LOANS_AND_BANK_FACILITIES_ACCOUNT_FAIL = '[Finances] [SystemAccounts] Find Finance Charges Loans And Bank Facilities Account Fail',
  FIND_FINANCE_CHARGES_LOANS_AND_BANK_FACILITIES_ACCOUNT_SUCCESS = '[Finances] [SystemAccounts] Find Finance Charges Loans And Bank Facilities Account Success',

  FIND_FINANCE_CHARGES_LEASE_CONTRACTS_ACCOUNT = '[Finances] [SystemAccounts] Find Finance Charges Lease Contracts Account ',
  FIND_FINANCE_CHARGES_LEASE_CONTRACTS_ACCOUNT_FAIL = '[Finances] [SystemAccounts] Find Finance Charges Lease Contracts Account Fail',
  FIND_FINANCE_CHARGES_LEASE_CONTRACTS_ACCOUNT_SUCCESS = '[Finances] [SystemAccounts] Find Finance Charges Lease Contracts Account Success',

  FIND_COMMISSIONS_ACCOUNT = '[Finances] [SystemAccounts] Find Commissions Account ',
  FIND_COMMISSIONS_ACCOUNT_FAIL = '[Finances] [SystemAccounts] Find Commissions Account Fail',
  FIND_COMMISSIONS_ACCOUNT_SUCCESS = '[Finances] [SystemAccounts] Find Commissions Account Success',

  FIND_BANK_EXPENSES_ACCOUNT = '[Finances] [SystemAccounts] Find Bank Expenses Account ',
  FIND_BANK_EXPENSES_ACCOUNT_FAIL = '[Finances] [SystemAccounts] Find Bank Expenses Account Fail',
  FIND_BANK_EXPENSES_ACCOUNT_SUCCESS = '[Finances] [SystemAccounts] Find Bank Expenses Account Success',

  FIND_COST_OF_REVENUE_AND_GENERAL_EXPENSES_OTHERS_ACCOUNT = '[Finances] [SystemAccounts] Find Cost Of Revenue And General Expenses Others Account ',
  FIND_COST_OF_REVENUE_AND_GENERAL_EXPENSES_OTHERS_ACCOUNT_FAIL = '[Finances] [SystemAccounts] Find Cost Of Revenue And General Expenses Others Account Fail',
  FIND_COST_OF_REVENUE_AND_GENERAL_EXPENSES_OTHERS_ACCOUNT_SUCCESS = '[Finances] [SystemAccounts] Find Cost Of Revenue And General Expenses Others Account Success',

  FIND_BUILDINGS_DEPRECIATION_EXPENSES_ACCOUNT = '[Finances] [SystemAccounts] Find Building Depreciation Express Account ',
  FIND_BUILDINGS_DEPRECIATION_EXPENSES_ACCOUNT_FAIL = '[Finances] [SystemAccounts] Find Building Depreciation Express Account Fail',
  FIND_BUILDINGS_DEPRECIATION_EXPENSES_ACCOUNT_SUCCESS = '[Finances] [SystemAccounts] Find Building Depreciation Express Account Success',

  FIND_FURNITURE_DEPRECIATION_EXPENSES_ACCOUNT = '[Finances] [SystemAccounts] Find Furniture Depreciation Express Account ',
  FIND_FURNITURE_DEPRECIATION_EXPENSES_ACCOUNT_FAIL = '[Finances] [SystemAccounts] Find Furniture Depreciation Express Account Fail',
  FIND_FURNITURE_DEPRECIATION_EXPENSES_ACCOUNT_SUCCESS = '[Finances] [SystemAccounts] Find Furniture Depreciation Express Account Success',

  FIND_DEVICES_DEPRECIATION_EXPENSES_ACCOUNT = '[Finances] [SystemAccounts] Find Devices Depreciation Express Account ',
  FIND_DEVICES_DEPRECIATION_EXPENSES_ACCOUNT_FAIL = '[Finances] [SystemAccounts] Find Devices Depreciation Express Account Fail',
  FIND_DEVICES_DEPRECIATION_EXPENSES_ACCOUNT_SUCCESS = '[Finances] [SystemAccounts] Find Devices Depreciation Express Account Success',
  FIND_COMPUTERS_DEPRECIATION_EXPENSES_ACCOUNT = '[Finances] [SystemAccounts] Find Computers Depreciation Express Account ',
  FIND_COMPUTERS_DEPRECIATION_EXPENSES_ACCOUNT_FAIL = '[Finances] [SystemAccounts] Find Computers Depreciation Express Account Fail',
  FIND_COMPUTERS_DEPRECIATION_EXPENSES_ACCOUNT_SUCCESS = '[Finances] [SystemAccounts] Find Computers Depreciation Express Account Success',

  FIND_EQUIPMENTS_DEPRECIATION_EXPENSES_ACCOUNT = '[Finances] [SystemAccounts] Find Equipments Depreciation Express Account ',
  FIND_EQUIPMENTS_DEPRECIATION_EXPENSES_ACCOUNT_FAIL = '[Finances] [SystemAccounts] Find Equipments Depreciation Express Account Fail',
  FIND_EQUIPMENTS_DEPRECIATION_EXPENSES_ACCOUNT_SUCCESS = '[Finances] [SystemAccounts] Find Equipments Depreciation Express Account Success',

  FIND_CARS_DEPRECIATION_EXPENSES_ACCOUNT = '[Finances] [SystemAccounts] Find Cars Depreciation Express Account ',
  FIND_CARS_DEPRECIATION_EXPENSES_ACCOUNT_FAIL = '[Finances] [SystemAccounts] Find Cars Depreciation Express Account Fail',
  FIND_CARS_DEPRECIATION_EXPENSES_ACCOUNT_SUCCESS = '[Finances] [SystemAccounts] Find Cars Depreciation Express Account Success',

  FIND_ASSETS_UNDER_FINANCE_LEASES_DEPRECIATION_EXPENSES_ACCOUNT = '[Finances] [SystemAccounts] Find Assets Under Finance Leases Depreciation Expenses Account ',
  FIND_ASSETS_UNDER_FINANCE_LEASES_DEPRECIATION_EXPENSES_ACCOUNT_FAIL = '[Finances] [SystemAccounts] Find Assets Under Finance Leases Depreciation Expenses Account Fail',
  FIND_ASSETS_UNDER_FINANCE_LEASES_DEPRECIATION_EXPENSES_ACCOUNT_SUCCESS = '[Finances] [SystemAccounts] Find Assets Under Finance Leases Depreciation Expenses Account Success',

  FIND_FAME_DEPRECIATION_EXPENSES_ACCOUNT = '[Finances] [SystemAccounts] Find Fame Depreciation Expenses Account ',
  FIND_FAME_DEPRECIATION_EXPENSES_ACCOUNT_FAIL = '[Finances] [SystemAccounts] Find Fame Depreciation Expenses Account Fail',
  FIND_FAME_DEPRECIATION_EXPENSES_ACCOUNT_SUCCESS = '[Finances] [SystemAccounts] Find Fame Depreciation Expenses Account Success',

  FIND_SOFTWARE_AND_SYSTEMS_DEPRECIATION_EXPENSES_ACCOUNT = '[Finances] [SystemAccounts] Find Software And Systems Depreciation Expenses Account ',
  FIND_SOFTWARE_AND_SYSTEMS_DEPRECIATION_EXPENSES_ACCOUNT_FAIL = '[Finances] [SystemAccounts] Find Software And Systems Depreciation Expenses Account Fail',
  FIND_SOFTWARE_AND_SYSTEMS_DEPRECIATION_EXPENSES_ACCOUNT_SUCCESS = '[Finances] [SystemAccounts] Find Software And Systems Depreciation Expenses Account Success',

  FIND_PATENTS_AND_TRADEMARKS_DEPRECIATION_EXPENSES_ACCOUNT = '[Finances] [SystemAccounts] Find Software And Systems Depreciation Expenses Account ',
  FIND_PATENTS_AND_TRADEMARKS_DEPRECIATION_EXPENSES_ACCOUNT_FAIL = '[Finances] [SystemAccounts] Find Software And Systems Depreciation Expenses Account Fail',
  FIND_PATENTS_AND_TRADEMARKS_DEPRECIATION_EXPENSES_ACCOUNT_SUCCESS = '[Finances] [SystemAccounts] Find Software And Systems Depreciation Expenses Account Success',

  FIND_REAL_ESTATE_INVESTMENT_BUILDINGS_DEPRECIATION_EXPENSES_ACCOUNT = '[Finances] [SystemAccounts] Find Real Estate Investment Buildings Depreciation Expenses Account ',
  FIND_REAL_ESTATE_INVESTMENT_BUILDINGS_DEPRECIATION_EXPENSES_ACCOUNT_FAIL = '[Finances] [SystemAccounts] Find Real Estate Investment Buildings Depreciation Expenses Account Fail',
  FIND_REAL_ESTATE_INVESTMENT_BUILDINGS_DEPRECIATION_EXPENSES_ACCOUNT_SUCCESS = '[Finances] [SystemAccounts] Find Real Estate Investment Buildings Depreciation Expenses Account Success',

  FIND_ASSETS_BROKER_ACCOUNT = '[Finances] [SystemAccounts] Find Assets Broker Account ',
  FIND_ASSETS_BROKER_ACCOUNT_FAIL = '[Finances] [SystemAccounts] Find Assets Broker Account Fail',
  FIND_ASSETS_BROKER_ACCOUNT_SUCCESS = '[Finances] [SystemAccounts] Find Assets Broker Account Success',

  FIND_CURRENCY_ROUNDING_DIFFERENCE_ACCOUNT = '[Finances] [SystemAccounts] Find Currency rounding Difference Account ',
  FIND_CURRENCY_ROUNDING_DIFFERENCE_ACCOUNT_FAIL = '[Finances] [SystemAccounts] Find Currency rounding Difference Account Fail',
  FIND_CURRENCY_ROUNDING_DIFFERENCE_ACCOUNT_SUCCESS = '[Finances] [SystemAccounts] Find Currency rounding Difference Account Success',

  FIND_GOSI_ACCRUAL_ACCOUNT = '[Finances] [SystemAccounts] Find Gosi Accrual Account ',
  FIND_GOSI_ACCRUAL_ACCOUNT_FAIL = '[Finances] [SystemAccounts] Find Gosi Accrual Account Fail',
  FIND_GOSI_ACCRUAL_ACCOUNT_SUCCESS = '[Finances] [SystemAccounts] Find Gosi Accrual Account Success',

  FIND_GOSI_EXPENSES_ACCOUNT = '[Finances] [SystemAccounts] Find Gosi Expenses Account ',
  FIND_GOSI_EXPENSES_ACCOUNT_FAIL = '[Finances] [SystemAccounts] Find Gosi Expenses Account Fail',
  FIND_GOSI_EXPENSES_ACCOUNT_SUCCESS = '[Finances] [SystemAccounts] Find Gosi Expenses Account Success',
}

/**
 * Represents a store find account receivable action.
 */
export class FindAccountReceivableAccount implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_ACCOUNT_RECEIVABLE;

  /**
   * Fires a new find account receivable action.
   */
  constructor() {}
}

/**
 * Represents a store find account receivable fail action.
 */
export class FindAccountReceivableAccountFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_ACCOUNT_RECEIVABLE_FAIL;

  /**
   * Fires a new find account receivable fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store find account receivable success action.
 */
export class FindAccountReceivableAccountSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_ACCOUNT_RECEIVABLE_SUCCESS;

  /**
   * Fires a new find account receivable success action.
   * @param payload The loaded account receivable.
   */
  constructor(public payload: AppHttpResponse<Account>) {}
}

/**
 * Represents a store find revenue action.
 */
export class FindRevenueAccount implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_REVENUE;

  /**
   * Fires a new find revenue action.
   */
  constructor() {}
}

/**
 * Represents a store find revenue fail action.
 */
export class FindRevenueAccountFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_REVENUE_FAIL;

  /**
   * Fires a new find revenue fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store find revenue success action.
 */
export class FindRevenueAccountSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_REVENUE_SUCCESS;

  /**
   * Fires a new find revenue success action.
   * @param payload The loaded revenue.
   */
  constructor(public payload: AppHttpResponse<Account>) {}
}

/**
 * Represents a store find tax action.
 */
export class FindTaxAccount implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_TAX;

  /**
   * Fires a new find tax action.
   */
  constructor() {}
}

/**
 * Represents a store find tax fail action.
 */
export class FindTaxAccountFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_TAX_FAIL;

  /**
   * Fires a new find tax fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store find tax success action.
 */
export class FindTaxAccountSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_TAX_SUCCESS;

  /**
   * Fires a new find tax success action.
   * @param payload The loaded tax.
   */
  constructor(public payload: AppHttpResponse<Account>) {}
}

/**
 * Represents a store find material account action.
 */
export class FindMaterialAccount implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_MATERIAL;

  /**
   * Fires a new find material account action.
   */
  constructor() {}
}

/**
 * Represents a store find material account fail action.
 */
export class FindMaterialAccountFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_MATERIAL_FAIL;

  /**
   * Fires a new find material account fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store find material account success action.
 */
export class FindMaterialAccountSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_MATERIAL_SUCCESS;

  /**
   * Fires a new find material account success action.
   * @param payload The loaded material account.
   */
  constructor(public payload: AppHttpResponse<Account>) {}
}

/**
 * Represents a store find inventory clearing account action.
 */
export class FindInventoryClearingAccount implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_INVENTORY_CLEARING;

  /**
   * Fires a new find inventory clearing account action.
   */
  constructor() {}
}

/**
 * Represents a store find inventory clearing account fail action.
 */
export class FindInventoryClearingAccountFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_INVENTORY_CLEARING_FAIL;

  /**
   * Fires a new find inventory clearing account fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store find inventory clearing account success action.
 */
export class FindInventoryClearingAccountSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_INVENTORY_CLEARING_SUCCESS;

  /**
   * Fires a new find inventory clearing account success action.
   * @param payload The loaded inventory clearing account.
   */
  constructor(public payload: AppHttpResponse<Account>) {}
}

/**
 * Represents a store find accounts payables clearing account action.
 */
export class FindAccountsPayablesClearingAccount implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_ACCOUNTS_PAYABLES_CLEARING;

  /**
   * Fires a new find accounts payables clearing account action.
   */
  constructor() {}
}

/**
 * Represents a store find accounts payables clearing account fail action.
 */
export class FindAccountsPayablesClearingAccountFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_ACCOUNTS_PAYABLES_CLEARING_FAIL;

  /**
   * Fires a new find accounts payables clearing account fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store find accounts payables clearing account success action.
 */
export class FindAccountsPayablesClearingAccountSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_ACCOUNTS_PAYABLES_CLEARING_SUCCESS;

  /**
   * Fires a new find accounts payables clearing account success action.
   * @param payload The loaded accounts payables clearing account.
   */
  constructor(public payload: AppHttpResponse<Account>) {}
}

/**
 * Represents a store find petty cash clearing account action.
 */
export class FindPettyCashClearingAccount implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_PETTY_CASH_CLEARING;

  /**
   * Fires a new find petty cash clearing account action.
   */
  constructor() {}
}

/**
 * Represents a store find petty cash clearing account fail action.
 */
export class FindPettyCashClearingAccountFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_PETTY_CASH_CLEARING_FAIL;

  /**
   * Fires a new find petty cash clearing account fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store find petty cash clearing account success action.
 */
export class FindPettyCashClearingAccountSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_PETTY_CASH_CLEARING_SUCCESS;

  /**
   * Fires a new find petty cash clearing account success action.
   * @param payload The loaded petty cash clearing account.
   */
  constructor(public payload: AppHttpResponse<Account>) {}
}

/**
 * Represents a store find liability account action.
 */
export class FindLiabilityAccount implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_LIABILITY;

  /**
   * Fires a new find liability account action.
   */
  constructor() {}
}

/**
 * Represents a store find liability account fail action.
 */
export class FindLiabilityAccountFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_LIABILITY_FAIL;

  /**
   * Fires a new find liability account fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store find liability account success action.
 */
export class FindLiabilityAccountSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_LIABILITY_SUCCESS;

  /**
   * Fires a new find liability account success action.
   * @param payload The loaded liability account.
   */
  constructor(public payload: AppHttpResponse<Account>) {}
}

/**
 * Represents a store find COGS account action.
 */
export class FindCOGSAccount implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_COGS;

  /**
   * Fires a new find COGS account action.
   */
  constructor() {}
}

/**
 * Represents a store find bank account action.
 */
export class FindBankAccount implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_BANK_ACCOUNT;

  /**
   * Fires a new find bank account action.
   */
  constructor() {}
}

/**
 * Represents a store find COGS account fail action.
 */
export class FindCOGSAccountFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_COGS_FAIL;

  /**
   * Fires a new find COGS account fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store find COGS account success action.
 */
export class FindCOGSAccountSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_COGS_SUCCESS;

  /**
   * Fires a new find COGS account success action.
   * @param payload The loaded COGS account.
   */
  constructor(public payload: AppHttpResponse<Account>) {}
}
/**
 * Represents a store find profit loss account action.
 */
export class FindProfitLossAccount implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_PROFIT_LOSS;

  /**
   * Fires a new find profit loss account action.
   */
  constructor() {}
}

/**
 * Represents a store find profit loss account fail action.
 */
export class FindProfitLossAccountFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_PROFIT_LOSS_FAIL;

  /**
   * Fires a new find profit loss account fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store find profit loss account success action.
 */
export class FindProfitLossAccountSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_PROFIT_LOSS_SUCCESS;

  /**
   * Fires a new find profit loss account success action.
   * @param payload The loaded profit loss account.
   */
  constructor(public payload: AppHttpResponse<Account>) {}
}

/**
 * Represents a store find bank account fail action.
 */
export class FindBankAccountFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_BANK_ACCOUNT_FAIL;

  /**
   * Fires a new find bank account fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store find bank account success action.
 */
export class FindBankAccountSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_BANK_ACCOUNT_SUCCESS;

  /**
   * Fires a new find bank account success action.
   * @param payload The loaded bank account.
   */
  constructor(public payload: AppHttpResponse<Account>) {}
}

/**
 * Represents a store find cash account action.
 */
export class FindCashAccount implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_CASH_ACCOUNT;

  /**
   * Fires a new find cash account action.
   */
  constructor() {}
}

/**
 * Represents a store find cash account fail action.
 */
export class FindCashAccountFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_CASH_ACCOUNT_FAIL;

  /**
   * Fires a new find cash account fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store find cash account success action.
 */
export class FindCashAccountSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_CASH_ACCOUNT_SUCCESS;

  /**
   * Fires a new find cash account success action.
   * @param payload The loaded cash account.
   */
  constructor(public payload: AppHttpResponse<Account>) {}
}

/**
 * Represents a store find cheques under collection account action.
 */
export class FindChequesUnderCollectionAccount implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_CHEQUES_UNDER_COLLECTION;

  /**
   * Fires a new find cheques under collection account action.
   */
  constructor() {}
}

/**
 * Represents a store find cheques under collection account fail action.
 */
export class FindChequesUnderCollectionAccountFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_CHEQUES_UNDER_COLLECTION_FAIL;

  /**
   * Fires a new find cheques under collection account fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store find cheques under collection account success action.
 */
export class FindChequesUnderCollectionAccountSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_CHEQUES_UNDER_COLLECTION_SUCCESS;

  /**
   * Fires a new find cheques under collection account success action.
   * @param payload The loaded cheques under collection account.
   */
  constructor(public payload: AppHttpResponse<Account>) {}
}

/**
 * Represents a store find payments under collection account action.
 */
export class FindPaymentsUnderCollectionAccount implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_PAYMENTS_UNDER_COLLECTION;

  /**
   * Fires a new find payments under collection account action.
   */
  constructor() {}
}

/**
 * Represents a store find payments under collection account fail action.
 */
export class FindPaymentsUnderCollectionAccountFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_PAYMENTS_UNDER_COLLECTION_FAIL;

  /**
   * Fires a new find payments under collection account fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store find payments under collection account success action.
 */
export class FindPaymentsUnderCollectionAccountSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_PAYMENTS_UNDER_COLLECTION_SUCCESS;

  /**
   * Fires a new find payments under collection account success action.
   * @param payload The loaded payments under collection account.
   */
  constructor(public payload: AppHttpResponse<Account>) {}
}

/**
 * Represents a store find point of sale cash account action.
 */
export class FindPointOfSaleCashAccount implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_POINT_OF_SALE_CASH;

  /**
   * Fires a new find point of sale cash account action.
   */
  constructor() {}
}

/**
 * Represents a store find point of sale cash account fail action.
 */
export class FindPointOfSaleCashAccountFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_POINT_OF_SALE_CASH_FAIL;

  /**
   * Fires a new find point of sale cash account fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store find point of sale cash account success action.
 */
export class FindPointOfSaleCashAccountSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_POINT_OF_SALE_CASH_SUCCESS;

  /**
   * Fires a new find point of sale cash account success action.
   * @param payload The loaded point of sale cash account.
   */
  constructor(public payload: AppHttpResponse<Account>) {}
}

/**
 * Represents a store find pending cheque account action.
 */
export class FindPendingChequeAccount implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_PENDING_CHEQUE;

  /**
   * Fires a new find pending cheque account action.
   */
  constructor() {}
}

/**
 * Represents a store find pending cheque account fail action.
 */
export class FindPendingChequeAccountFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_PENDING_CHEQUE_FAIL;

  /**
   * Fires a new find pending cheque account fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store find pending cheque account success action.
 */
export class FindPendingChequeAccountSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_PENDING_CHEQUE_SUCCESS;

  /**
   * Fires a new find pending cheque account success action.
   * @param payload The loaded pending cheque account.
   */
  constructor(public payload: AppHttpResponse<Account>) {}
}

/**
 * Represents a store find payments under payment account action.
 */
export class FindPaymentsUnderPaymentAccount implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_PAYMENTS_UNDER_PAYMENT;

  /**
   * Fires a new find payments under payment account action.
   */
  constructor() {}
}

/**
 * Represents a store find payments under payment account fail action.
 */
export class FindPaymentsUnderPaymentAccountFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_PAYMENTS_UNDER_PAYMENT_FAIL;

  /**
   * Fires a new find payments under payment account fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store find payments under payment account success action.
 */
export class FindPaymentsUnderPaymentAccountSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_PAYMENTS_UNDER_PAYMENT_SUCCESS;

  /**
   * Fires a new find payments under payment account success action.
   * @param payload The loaded payments under payment account.
   */
  constructor(public payload: AppHttpResponse<Account>) {}
}

/**
 * Represents a store find advance expenses petty cash account action.
 */
export class FindAdvanceExpensesPettyCashAccount implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_ADVANCE_EXPENSES_PETTY_CASH;

  /**
   * Fires a new find advance expenses petty cash account action.
   */
  constructor() {}
}

/**
 * Represents a store find advance expenses petty cash account fail action.
 */
export class FindAdvanceExpensesPettyCashAccountFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_ADVANCE_EXPENSES_PETTY_CASH_FAIL;

  /**
   * Fires a new find advance expenses petty cash account fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store find advance expenses petty cash account success action.
 */
export class FindAdvanceExpensesPettyCashAccountSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_ADVANCE_EXPENSES_PETTY_CASH_SUCCESS;

  /**
   * Fires a new find advance expenses petty cash account success action.
   * @param payload The loaded advance expenses petty cash account.
   */
  constructor(public payload: AppHttpResponse<Account>) {}
}

/**
 * Represents a store find owners current account action.
 */
export class FindOwnersCurrentAccount implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_OWNERS_CURRENT_ACCOUNT;

  /**
   * Fires a new find owners current account action.
   */
  constructor() {}
}

/**
 * Represents a store find owners current account fail action.
 */
export class FindOwnersCurrentAccountFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_OWNERS_CURRENT_ACCOUNT_FAIL;

  /**
   * Fires a new find owners current account fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store find owners current account success action.
 */
export class FindOwnersCurrentAccountSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_OWNERS_CURRENT_ACCOUNT_SUCCESS;

  /**
   * Fires a new find owners current account success action.
   * @param payload The loaded owners current account.
   */
  constructor(public payload: AppHttpResponse<Account>) {}
}

/**
 * Represents a store find improvementsToRentedBuildings account success action.
 */
export class FindImprovementsToRentedBuildingsAccountSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_IMPROVEMENTS_TO_RENTED_BUILDINGS_ACCOUNT_SUCCESS;

  /**
   * Fires a new find improvementsToRentedBuildings account success action.
   * @param payload The loaded improvementsToRentedBuildings account.
   */
  constructor(public payload: AppHttpResponse<Account>) {}
}
/**
 * Represents a store find improvementsToRentedBuildings account action.
 */
export class FindImprovementsToRentedBuildingsAccount implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_IMPROVEMENTS_TO_RENTED_BUILDINGS_ACCOUNT;

  /**
   * Fires a new find improvementsToRentedBuildings account action.
   */
  constructor() {}
}

/**
 * Represents a store find improvementsToRentedBuildings account fail action.
 */
export class FindImprovementsToRentedBuildingsAccountFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_IMPROVEMENTS_TO_RENTED_BUILDINGS_ACCOUNT_FAIL;

  /**
   * Fires a new find improvementsToRentedBuildings account fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store find machinesAndEquipments account success action.
 */
export class FindMachinesAndEquipmentsAccountSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_MACHINES_AND_EQUIPMENTS_ACCOUNT_SUCCESS;

  /**
   * Fires a new find machinesAndEquipments account success action.
   * @param payload The loaded machinesAndEquipments account.
   */
  constructor(public payload: AppHttpResponse<Account>) {}
}
/**
 * Represents a store find machinesAndEquipments account action.
 */
export class FindMachinesAndEquipmentsAccount implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_MACHINES_AND_EQUIPMENTS_ACCOUNT;

  /**
   * Fires a new find machinesAndEquipments account action.
   */
  constructor() {}
}

/**
 * Represents a store find lands account fail action.
 */
export class FindMachinesAndEquipmentsAccountFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_MACHINES_AND_EQUIPMENTS_ACCOUNT_FAIL;

  /**
   * Fires a new find machinesAndEquipments account fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store find desktopDevices account success action.
 */
export class FindDesktopDevicesAccountSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_DESKTOP_DEVICES_ACCOUNT_SUCCESS;

  /**
   * Fires a new find desktopDevices account success action.
   * @param payload The loaded desktopDevices account.
   */
  constructor(public payload: AppHttpResponse<Account>) {}
}

/**
 * Represents a store find desktopDevices account action.
 */
export class FindDesktopDevicesAccount implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_DESKTOP_DEVICES_ACCOUNT;

  /**
   * Fires a new find desktopDevices account action.
   */
  constructor() {}
}

/**
 * Represents a store find desktopDevices account fail action.
 */
export class FindDesktopDevicesAccountFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_DESKTOP_DEVICES_ACCOUNT_FAIL;

  /**
   * Fires a new find desktopDevices account fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store find assetBrokerage account success action.
 */
export class FindAssetBrokerageAccountSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_ASSET_BROKERAGE_ACCOUNT_SUCCESS;

  /**
   * Fires a new find assetBrokerage account success action.
   * @param payload The loaded assetBrokerage account.
   */
  constructor(public payload: AppHttpResponse<Account>) {}
}

/**
 * Represents a store find assetBrokerage account action.
 */
export class FindAssetBrokerageAccount implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_ASSET_BROKERAGE_ACCOUNT;

  /**
   * Fires a new find assetBrokerage account action.
   */
  constructor() {}
}

/**
 * Represents a store find assetBrokerage account fail action.
 */
export class FindAssetBrokerageAccountFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_ASSET_BROKERAGE_ACCOUNT_FAIL;

  /**
   * Fires a new find lands account fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store find salaries payable account action.
 */
export class FindSalariesPayableAccount implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_SALARIES_PAYABLE_ACCOUNT;

  /**
   * Fires a new find salaries payable account action.
   */
  constructor() {}
}

/**
 * Represents a store find salaries payable account success action.
 */
export class FindSalariesPayableAccountSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_SALARIES_PAYABLE_ACCOUNT_SUCCESS;

  /**
   * Fires a new find salaries payable account success action.
   * @param payload The loaded salaries payable account.
   */
  constructor(public payload: AppHttpResponse<Account>) {}
}

/**
 * Represents a store find salaries payable account fail action.
 */
export class FindSalariesPayableAccountFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_SALARIES_PAYABLE_ACCOUNT_FAIL;

  /**
   * Fires a new find lands account fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/* &&&&&&&&&&&&&&&&&&&&&&&&&& NEW SYSTEMS ACCOUNTS &&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&& */

/**
 * Represents a store find cash in hand account action.
 */
export class FindCashInHandAccount implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_CASH_IN_HAND_ACCOUNT;

  /**
   * Fires a new find cash in hand account action.
   */
  constructor() {}
}

/**
 * Represents a store find cash in hand account fail action.
 */
export class FindCashInHandAccountFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_CASH_IN_HAND_ACCOUNT_FAIL;

  /**
   * Fires a new find cash in hand account fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store find cash in hand account success action.
 */
export class FindCashInHandAccountSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_CASH_IN_HAND_ACCOUNT_SUCCESS;

  /**
   * Fires a new find cash in hand account success action.
   * @param payload The loaded cash in hand account.
   */
  constructor(public payload: AppHttpResponse<Account>) {}
}

/**
 * Represents a store find cash in banks account action.
 */
export class FindCashInBanksAccount implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_CASH_IN_BANKS_ACCOUNT;

  /**
   * Fires a new find cash in banks account action.
   */
  constructor() {}
}

/**
 * Represents a store find cash in banks account fail action.
 */
export class FindCashInBanksAccountFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_CASH_IN_BANKS_ACCOUNT_FAIL;

  /**
   * Fires a new find cash in banks account fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store find cash in banks account success action.
 */
export class FindCashInBanksAccountSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_CASH_IN_BANKS_ACCOUNT_SUCCESS;

  /**
   * Fires a new find cash in banks account success action.
   * @param payload The loaded cash in banks account.
   */
  constructor(public payload: AppHttpResponse<Account>) {}
}

/**
 * Represents a store find checks under collection account action.
 */
export class FindChecksUnderCollectionAccount implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_CHECKS_UNDER_COLLECTION_ACCOUNT;

  /**
   * Fires a new find checks under collection account action.
   */
  constructor() {}
}

/**
 * Represents a store find checks under collection account fail action.
 */
export class FindChecksUnderCollectionAccountFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_CHECKS_UNDER_COLLECTION_ACCOUNT_FAIL;

  /**
   * Fires a new find checks under collection account fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store find checks under collection account success action.
 */
export class FindChecksUnderCollectionAccountSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_CHECKS_UNDER_COLLECTION_ACCOUNT_SUCCESS;

  /**
   * Fires a new find checks under collection account success action.
   * @param payload The loaded checks under collection account.
   */
  constructor(public payload: AppHttpResponse<Account>) {}
}

/**
 * Represents a store find cash investments less than 90 days account action.
 */
export class FindCashInvestmentsLessThan90DaysAccount implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_CASH_INVESTMENTS_LESS_THAN_90_DAYS_ACCOUNT;

  /**
   * Fires a new find cash investments less than 90 days account action.
   */
  constructor() {}
}

/**
 * Represents a store find cash investments less than 90 days account fail action.
 */
export class FindCashInvestmentsLessThan90DaysAccountFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_CASH_INVESTMENTS_LESS_THAN_90_DAYS_ACCOUNT_FAIL;

  /**
   * Fires a new find cash investments less than 90 days account fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store find cash investments less than 90 days account success action.
 */
export class FindCashInvestmentsLessThan90DaysAccountSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_CASH_INVESTMENTS_LESS_THAN_90_DAYS_ACCOUNT_SUCCESS;

  /**
   * Fires a new find cash investments less than 90 days account success action.
   * @param payload The loaded cash investments less than 90 days account.
   */
  constructor(public payload: AppHttpResponse<Account>) {}
}

/**
 * Represents a store find restricted cash in bank account action.
 */
export class FindRestrictedCashInBanksAccount implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_RESTRICTED_CASH_IN_BANKS_ACCOUNT;

  /**
   * Fires a new find restricted cash in bank account action.
   */
  constructor() {}
}

/**
 * Represents a store find restricted cash in bank account fail action.
 */
export class FindRestrictedCashInBanksAccountFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_RESTRICTED_CASH_IN_BANKS_ACCOUNT_FAIL;

  /**
   * Fires a new find restricted cash in bank account fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store find restricted cash in bank account success action.
 */
export class FindRestrictedCashInBanksAccountSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_RESTRICTED_CASH_IN_BANKS_ACCOUNT_SUCCESS;

  /**
   * Fires a new find restricted cash in bank account success action.
   * @param payload The loaded restricted cash in bank account.
   */
  constructor(public payload: AppHttpResponse<Account>) {}
}

/**
 * Represents a store find short term deposits account action.
 */
export class FindShortTermDepositsAccount implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_SHORT_TERM_DEPOSITS_ACCOUNT;

  /**
   * Fires a new find short term deposits account action.
   */
  constructor() {}
}

/**
 * Represents a store find short term deposits account fail action.
 */
export class FindShortTermDepositsAccountFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_SHORT_TERM_DEPOSITS_ACCOUNT_FAIL;

  /**
   * Fires a new find short term deposits account fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store find short term deposits account success action.
 */
export class FindShortTermDepositsAccountSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_SHORT_TERM_DEPOSITS_ACCOUNT_SUCCESS;

  /**
   * Fires a new find short term deposits account success action.
   * @param payload The loaded short term deposits account.
   */
  constructor(public payload: AppHttpResponse<Account>) {}
}

/**
 * Represents a store find cash investments for trading account action.
 */
export class FindCashInvestmentsForTradingAccount implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_CASH_INVESTMENTS_FOR_TRADING_ACCOUNT;

  /**
   * Fires a new find cash investments for trading account action.
   */
  constructor() {}
}

/**
 * Represents a store find cash investments for trading account fail action.
 */
export class FindCashInvestmentsForTradingAccountFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_CASH_INVESTMENTS_FOR_TRADING_ACCOUNT_FAIL;

  /**
   * Fires a new find cash investments for trading account fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store find cash investments for trading account success action.
 */
export class FindCashInvestmentsForTradingAccountSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_CASH_INVESTMENTS_FOR_TRADING_ACCOUNT_SUCCESS;

  /**
   * Fires a new find cash investments for trading account success action.
   * @param payload The loaded cash investments for trading account.
   */
  constructor(public payload: AppHttpResponse<Account>) {}
}

/**
 * Represents a store find trade debtors account action.
 */
export class FindTradeDebtorsAccount implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_TRADE_DEBTORS_ACCOUNT;

  /**
   * Fires a new find trade debtors account action.
   */
  constructor() {}
}

/**
 * Represents a store find trade debtors account fail action.
 */
export class FindTradeDebtorsAccountFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_TRADE_DEBTORS_ACCOUNT_FAIL;

  /**
   * Fires a new find trade debtors account fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store find trade debtors account success action.
 */
export class FindTradeDebtorsAccountSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_TRADE_DEBTORS_ACCOUNT_SUCCESS;

  /**
   * Fires a new find trade debtors account success action.
   * @param payload The loaded trade debtors account.
   */
  constructor(public payload: AppHttpResponse<Account>) {}
}

/**
 * Represents a store find allowance for doubtful debts account action.
 */
export class FindAllowanceForDoubtfulDebtsAccount implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_ALLOWANCE_FOR_DOUBTFUL_DEBTS_ACCOUNT;

  /**
   * Fires a new find allowance for doubtful debts account action.
   */
  constructor() {}
}

/**
 * Represents a store find allowance for doubtful debts account fail action.
 */
export class FindAllowanceForDoubtfulDebtsAccountFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_ALLOWANCE_FOR_DOUBTFUL_DEBTS_ACCOUNT_FAIL;

  /**
   * Fires a new find allowance for doubtful debts account fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store find trade debtors account success action.
 */
export class FindAllowanceForDoubtfulDebtsAccountSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_ALLOWANCE_FOR_DOUBTFUL_DEBTS_ACCOUNT_SUCCESS;

  /**
   * Fires a new find allowance for doubtful debts account success action.
   * @param payload The loaded allowance for doubtful debts account.
   */
  constructor(public payload: AppHttpResponse<Account>) {}
}

/**
 * Represents a store find related parties account action.
 */
export class FindRelatedPartiesAccount implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_RELATED_PARTIES_ACCOUNT;

  /**
   * Fires a new find related parties account action.
   */
  constructor() {}
}

/**
 * Represents a store find related parties account fail action.
 */
export class FindRelatedPartiesAccountFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_RELATED_PARTIES_ACCOUNT_FAIL;

  /**
   * Fires a new find related parties account fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store find related parties account success action.
 */
export class FindRelatedPartiesAccountSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_RELATED_PARTIES_ACCOUNT_SUCCESS;

  /**
   * Fires a new find related parties account success action.
   * @param payload The loaded related parties account.
   */
  constructor(public payload: AppHttpResponse<Account>) {}
}

/**
 * Represents a store find advances of staff account action.
 */
export class FindAdvancesOfStaffAccount implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_ADVANCES_OF_STAFF_ACCOUNT;

  /**
   * Fires a new find advances of staff account action.
   */
  constructor() {}
}

/**
 * Represents a store find advances of staff account fail action.
 */
export class FindAdvancesOfStaffAccountFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_ADVANCES_OF_STAFF_ACCOUNT_FAIL;

  /**
   * Fires a new find advances of staff account fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store find advances of staff account success action.
 */
export class FindAdvancesOfStaffAccountSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_ADVANCES_OF_STAFF_ACCOUNT_SUCCESS;

  /**
   * Fires a new find advances of staff account success action.
   * @param payload The loaded advances of staff account.
   */
  constructor(public payload: AppHttpResponse<Account>) {}
}

/**
 * Represents a store find letters of guarantee account action.
 */
export class FindLettersOfGuaranteeAccount implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_LETTERS_OF_GUARANTEE_ACCOUNT;

  /**
   * Fires a new find letters of guarantee account action.
   */
  constructor() {}
}

/**
 * Represents a store find letters of guarantee account fail action.
 */
export class FindLettersOfGuaranteeAccountFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_LETTERS_OF_GUARANTEE_ACCOUNT_FAIL;

  /**
   * Fires a new find letters of guarantee account fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store find letters of guarantee account success action.
 */
export class FindLettersOfGuaranteeAccountSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_LETTERS_OF_GUARANTEE_ACCOUNT_SUCCESS;

  /**
   * Fires a new find letters of guarantee account success action.
   * @param payload The loaded letters of guarantee account.
   */
  constructor(public payload: AppHttpResponse<Account>) {}
}

/**
 * Represents a store find documentary credits account action.
 */
export class FindDocumentaryCreditsAccount implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_DOCUMENTARY_CREDITS_ACCOUNT;

  /**
   * Fires a new find documentary credits account action.
   */
  constructor() {}
}

/**
 * Represents a store find documentary credits account fail action.
 */
export class FindDocumentaryCreditsAccountFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_DOCUMENTARY_CREDITS_ACCOUNT_FAIL;

  /**
   * Fires a new find documentary credits account fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store find documentary credits account success action.
 */
export class FindDocumentaryCreditsAccountSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_DOCUMENTARY_CREDITS_ACCOUNT_SUCCESS;

  /**
   * Fires a new find documentary credits account success action.
   * @param payload The loaded documentary credits account.
   */
  constructor(public payload: AppHttpResponse<Account>) {}
}

/**
 * Represents a store find petty cash account action.
 */
export class FindPettyCashAccount implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_PETTY_CASH_ACCOUNT;

  /**
   * Fires a new find petty cash account action.
   */
  constructor() {}
}

/**
 * Represents a store find petty cash account fail action.
 */
export class FindPettyCashAccountFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_PETTY_CASH_ACCOUNT_FAIL;

  /**
   * Fires a new find petty cash account fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store find petty cash account success action.
 */
export class FindPettyCashAccountSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_PETTY_CASH_ACCOUNT_SUCCESS;

  /**
   * Fires a new find petty cash account success action.
   * @param payload The loaded petty cash account.
   */
  constructor(public payload: AppHttpResponse<Account>) {}
}

/**
 * Represents a store find well executed detention account action.
 */
export class FindWellExecutedDetentionAccount implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_WELL_EXECUTED_DETENTION_ACCOUNT;

  /**
   * Fires a new find well executed detention account action.
   */
  constructor() {}
}

/**
 * Represents a store find well executed detention account fail action.
 */
export class FindWellExecutedDetentionAccountFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_WELL_EXECUTED_DETENTION_ACCOUNT_FAIL;

  /**
   * Fires a new find well executed detention account fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store find well executed detention account success action.
 */
export class FindWellExecutedDetentionAccountSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_WELL_EXECUTED_DETENTION_ACCOUNT_SUCCESS;

  /**
   * Fires a new find well executed detention account success action.
   * @param payload The loaded well executed detention account.
   */
  constructor(public payload: AppHttpResponse<Account>) {}
}

/**
 * Represents a store find accrued revenue account action.
 */
export class FindAccruedRevenueAccount implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_ACCRUED_REVENUE_ACCOUNT;

  /**
   * Fires a new find accrued revenue account action.
   */
  constructor() {}
}

/**
 * Represents a store find accrued revenue account fail action.
 */
export class FindAccruedRevenueAccountFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_ACCRUED_REVENUE_ACCOUNT_FAIL;

  /**
   * Fires a new find accrued revenue account fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store find accrued revenue account success action.
 */
export class FindAccruedRevenueAccountSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_ACCRUED_REVENUE_ACCOUNT_SUCCESS;

  /**
   * Fires a new find accrued revenue account success action.
   * @param payload The loaded accrued revenue account.
   */
  constructor(public payload: AppHttpResponse<Account>) {}
}

/**
 * Represents a store find work in progress unbilled revenue account action.
 */
export class FindWorkInProgressUnbilledRevenueAccount implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_WORK_IN_PROGRESS_UNBILLED_REVENUE_ACCOUNT;

  /**
   * Fires a new find work in progress unbilled revenue account action.
   */
  constructor() {}
}

/**
 * Represents a store find work in progress unbilled revenue account fail action.
 */
export class FindWorkInProgressUnbilledRevenueAccountFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_WORK_IN_PROGRESS_UNBILLED_REVENUE_ACCOUNT_FAIL;

  /**
   * Fires a new find work in progress unbilled revenue account fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store find work in progress unbilled revenue account success action.
 */
export class FindWorkInProgressUnbilledRevenueAccountSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_WORK_IN_PROGRESS_UNBILLED_REVENUE_ACCOUNT_SUCCESS;

  /**
   * Fires a new find work in progress unbilled revenue account success action.
   * @param payload The loaded work in progress unbilled revenue account.
   */
  constructor(public payload: AppHttpResponse<Account>) {}
}

/**
 * Represents a store find value added tax debit account action.
 */
export class FindValueAddedTaxDebitAccount implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_VALUE_ADDED_TAX_DEBIT_ACCOUNT;

  /**
   * Fires a new find value added tax debit account action.
   */
  constructor() {}
}

/**
 * Represents a store find value added tax debit account fail action.
 */
export class FindValueAddedTaxDebitAccountFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_VALUE_ADDED_TAX_DEBIT_ACCOUNT_FAIL;

  /**
   * Fires a new find value added tax debit account fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store find value added tax debit account success action.
 */
export class FindValueAddedTaxDebitAccountSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_VALUE_ADDED_TAX_DEBIT_ACCOUNT_SUCCESS;

  /**
   * Fires a new find value added tax debit account success action.
   * @param payload The loaded value added tax debit account.
   */
  constructor(public payload: AppHttpResponse<Account>) {}
}

/**
 * Represents a store find miscellaneous debtors account action.
 */
export class FindMiscellaneousDebtorsAccount implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_MISCELLANEOUS_DEBTORS_ACCOUNT;

  /**
   * Fires a new find miscellaneous debtors account action.
   */
  constructor() {}
}

/**
 * Represents a store find miscellaneous debtors account fail action.
 */
export class FindMiscellaneousDebtorsAccountFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_MISCELLANEOUS_DEBTORS_ACCOUNT_FAIL;

  /**
   * Fires a new find miscellaneous debtors account fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store find miscellaneous debtors account success action.
 */
export class FindMiscellaneousDebtorsAccountSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_MISCELLANEOUS_DEBTORS_ACCOUNT_SUCCESS;

  /**
   * Fires a new find miscellaneous debtors account success action.
   * @param payload The loaded miscellaneous debtors account.
   */
  constructor(public payload: AppHttpResponse<Account>) {}
}

/**
 * Represents a store find prepaid expenses account action.
 */
export class FindPrepaidExpensesAccount implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_PREPAID_EXPENSES_ACCOUNT;

  /**
   * Fires a new find prepaid expenses account action.
   */
  constructor() {}
}

/**
 * Represents a store find prepaid expenses account fail action.
 */
export class FindPrepaidExpensesAccountFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_PREPAID_EXPENSES_ACCOUNT_FAIL;

  /**
   * Fires a new find prepaid expenses account fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store find prepaid expenses account success action.
 */
export class FindPrepaidExpensesAccountSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_PREPAID_EXPENSES_ACCOUNT_SUCCESS;

  /**
   * Fires a new find prepaid expenses account success action.
   * @param payload The loaded prepaid expenses account.
   */
  constructor(public payload: AppHttpResponse<Account>) {}
}

/**
 * Represents a store find advance payments to vendors and contractors account action.
 */
export class FindAdvancePaymentsToVendorsAndContractorsAccount implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_ADVANCE_PAYMENTS_TO_VENDORS_AND_CONTRACTORS_ACCOUNT;

  /**
   * Fires a new find advance payments to vendors and contractors account action.
   */
  constructor() {}
}

/**
 * Represents a store find advance payments to vendors and contractors account fail action.
 */
export class FindAdvancePaymentsToVendorsAndContractorsAccountFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_ADVANCE_PAYMENTS_TO_VENDORS_AND_CONTRACTORS_ACCOUNT_FAIL;

  /**
   * Fires a new find advance payments to vendors and contractors account fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store find advance payments to vendors and contractors account success action.
 */
export class FindAdvancePaymentsToVendorsAndContractorsAccountSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_ADVANCE_PAYMENTS_TO_VENDORS_AND_CONTRACTORS_ACCOUNT_SUCCESS;

  /**
   * Fires a new find advance payments to vendors and contractors account success action.
   * @param payload The loaded advance payments to vendors and contractors account.
   */
  constructor(public payload: AppHttpResponse<Account>) {}
}

/**
 * Represents a store find financing charges for lease contracts account action.
 */
export class FindFinancingChargesForLeaseContractsAccount implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_FINANCING_CHARGES_FOR_LEASE_CONTRACTS_ACCOUNT;

  /**
   * Fires a new find financing charges for lease contracts account action.
   */
  constructor() {}
}

/**
 * Represents a store find financing charges for lease contracts account fail action.
 */
export class FindFinancingChargesForLeaseContractsAccountFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_FINANCING_CHARGES_FOR_LEASE_CONTRACTS_ACCOUNT_FAIL;

  /**
   * Fires a new find financing charges for lease contracts account fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store find financing charges for lease contracts account success action.
 */
export class FindFinancingChargesForLeaseContractsAccountSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_FINANCING_CHARGES_FOR_LEASE_CONTRACTS_ACCOUNT_SUCCESS;

  /**
   * Fires a new find financing charges for lease contracts account success action.
   * @param payload The loaded financing charges for lease contracts account.
   */
  constructor(public payload: AppHttpResponse<Account>) {}
}

/**
 * Represents a store find financing charges for bank loans and credit facilities account action.
 */
export class FindFinancingChargesForBankLoansAndCreditFacilitiesAccount implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_FINANCING_CHARGES_FOR_BANK_LOANS_AND_CREDIT_FACILITIES_ACCOUNT;

  /**
   * Fires a new find financing charges for bank loans and credit facilities account action.
   */
  constructor() {}
}

/**
 * Represents a store find financing charges for bank loans and credit facilities account fail action.
 */
export class FindFinancingChargesForBankLoansAndCreditFacilitiesAccountFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_FINANCING_CHARGES_FOR_BANK_LOANS_AND_CREDIT_FACILITIES_ACCOUNT_FAIL;

  /**
   * Fires a new find financing charges for bank loans and credit facilities account fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store find financing charges for bank loans and credit facilities account success action.
 */
export class FindFinancingChargesForBankLoansAndCreditFacilitiesAccountSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_FINANCING_CHARGES_FOR_BANK_LOANS_AND_CREDIT_FACILITIES_ACCOUNT_SUCCESS;

  /**
   * Fires a new find financing charges for bank loans and credit facilities account success action.
   * @param payload The loaded financing charges for bank loans and credit facilities account.
   */
  constructor(public payload: AppHttpResponse<Account>) {}
}

/**
 * Represents a store find goods and materials stock account action.
 */
export class FindGoodsAndMaterialsStockAccount implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_GOODS_AND_MATERIALS_STOCK_ACCOUNT;

  /**
   * Fires a new find goods and materials stock account action.
   */
  constructor() {}
}

/**
 * Represents a store find goods and materials stock account fail action.
 */
export class FindGoodsAndMaterialsStockAccountFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_GOODS_AND_MATERIALS_STOCK_ACCOUNT_FAIL;

  /**
   * Fires a new find goods and materials stock account fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store find goods and materials stock account success action.
 */
export class FindGoodsAndMaterialsStockAccountSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_GOODS_AND_MATERIALS_STOCK_ACCOUNT_SUCCESS;

  /**
   * Fires a new find goods and materials stock account success action.
   * @param payload The loaded goods and materials stock account.
   */
  constructor(public payload: AppHttpResponse<Account>) {}
}

/**
 * Represents a store find stock in process account action.
 */
export class FindStockInProcessAccount implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_STOCK_IN_PROCESS_ACCOUNT;

  /**
   * Fires a new find stock in process account action.
   */
  constructor() {}
}

/**
 * Represents a store find stock in process account fail action.
 */
export class FindStockInProcessAccountFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_STOCK_IN_PROCESS_ACCOUNT_FAIL;

  /**
   * Fires a new find stock in process account fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store find stock in process account success action.
 */
export class FindStockInProcessAccountSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_STOCK_IN_PROCESS_ACCOUNT_SUCCESS;

  /**
   * Fires a new find stock in process account success action.
   * @param payload The loaded stock in process account.
   */
  constructor(public payload: AppHttpResponse<Account>) {}
}

/**
 * Represents a store find completed stock account action.
 */
export class FindCompletedStockAccount implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_COMPLETED_STOCK_ACCOUNT;

  /**
   * Fires a new find completed stock account action.
   */
  constructor() {}
}

/**
 * Represents a store find completed stock account fail action.
 */
export class FindCompletedStockAccountFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_COMPLETED_STOCK_ACCOUNT_FAIL;

  /**
   * Fires a new find completed stock account fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store find completed stock account success action.
 */
export class FindCompletedStockAccountSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_COMPLETED_STOCK_ACCOUNT_SUCCESS;

  /**
   * Fires a new find completed stock account success action.
   * @param payload The loaded completed stock account.
   */
  constructor(public payload: AppHttpResponse<Account>) {}
}

/**
 * Represents a store find provision for damaged or obsolete inventory account action.
 */
export class FindProvisionForDamagedOrObsoleteInventoryAccount implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_PROVISION_FOR_DAMAGED_OR_OBSOLETE_INVENTORY_ACCOUNT;

  /**
   * Fires a new find provision for damaged or obsolete inventory account action.
   */
  constructor() {}
}

/**
 * Represents a store find provision for damaged or obsolete inventory account fail action.
 */
export class FindProvisionForDamagedOrObsoleteInventoryAccountFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_PROVISION_FOR_DAMAGED_OR_OBSOLETE_INVENTORY_ACCOUNT_FAIL;

  /**
   * Fires a new find provision for damaged or obsolete inventory account fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store find provision for damaged or obsolete inventory account success action.
 */
export class FindProvisionForDamagedOrObsoleteInventoryAccountSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_PROVISION_FOR_DAMAGED_OR_OBSOLETE_INVENTORY_ACCOUNT_SUCCESS;

  /**
   * Fires a new find provision for damaged or obsolete inventory account success action.
   * @param payload The loaded provision for damaged or obsolete inventory account.
   */
  constructor(public payload: AppHttpResponse<Account>) {}
}

/**
 * Represents a store find investments available for sale or held to maturity account action.
 */
export class FindInvestmentsAvailableForSaleOrHeldToMaturityAccount implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_INVESTMENTS_AVAILABLE_FOR_SALE_OR_HELD_TO_MATURITY_ACCOUNT;

  /**
   * Fires a new find investments available for sale or held to maturity account action.
   */
  constructor() {}
}

/**
 * Represents a store find investments available for sale or held to maturity account fail action.
 */
export class FindInvestmentsAvailableForSaleOrHeldToMaturityAccountFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_INVESTMENTS_AVAILABLE_FOR_SALE_OR_HELD_TO_MATURITY_ACCOUNT_FAIL;

  /**
   * Fires a new find investments available for sale or held to maturity account fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store find investments available for sale or held to maturity account success action.
 */
export class FindInvestmentsAvailableForSaleOrHeldToMaturityAccountSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_INVESTMENTS_AVAILABLE_FOR_SALE_OR_HELD_TO_MATURITY_ACCOUNT_SUCCESS;

  /**
   * Fires a new find investments available for sale or held to maturity account success action.
   * @param payload The loaded investments available for sale or held to maturity account.
   */
  constructor(public payload: AppHttpResponse<Account>) {}
}

/**
 * Represents a store find long term deposits account action.
 */
export class FindLongTermDepositsAccount implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_LONG_TERM_DEPOSITS_ACCOUNT;

  /**
   * Fires a new find long term deposits account action.
   */
  constructor() {}
}

/**
 * Represents a store find long term deposits account fail action.
 */
export class FindLongTermDepositsAccountFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_LONG_TERM_DEPOSITS_ACCOUNT_FAIL;

  /**
   * Fires a new find long term deposits account fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store find long term deposits account success action.
 */
export class FindLongTermDepositsAccountSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_LONG_TERM_DEPOSITS_ACCOUNT_SUCCESS;

  /**
   * Fires a new find long term deposits account success action.
   * @param payload The loaded long term deposits account.
   */
  constructor(public payload: AppHttpResponse<Account>) {}
}

/**
 * Represents a store find finance charges for lease contracts non current account action.
 */
export class FindFinanceChargesForLeaseContractsNonCurrentAccount implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_FINANCE_CHARGES_FOR_LEASE_CONTRACTS_NON_CURRENT_ACCOUNT;

  /**
   * Fires a new find finance charges for lease contracts non current account action.
   */
  constructor() {}
}

/**
 * Represents a store find finance charges for lease contracts non current account fail action.
 */
export class FindFinanceChargesForLeaseContractsNonCurrentAccountFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_FINANCE_CHARGES_FOR_LEASE_CONTRACTS_NON_CURRENT_ACCOUNT_FAIL;

  /**
   * Fires a new find finance charges for lease contracts non current account fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store find finance charges for lease contracts non current account success action.
 */
export class FindFinanceChargesForLeaseContractsNonCurrentAccountSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_FINANCE_CHARGES_FOR_LEASE_CONTRACTS_NON_CURRENT_ACCOUNT_SUCCESS;

  /**
   * Fires a new find finance charges for lease contracts non current account success action.
   * @param payload The loaded finance charges for lease contracts non current account.
   */
  constructor(public payload: AppHttpResponse<Account>) {}
}

/**
 * Represents a store find financing charges for loans and credit facilities non current account action.
 */
export class FindFinancingChargesForLoansAndCreditFacilitiesNonCurrentAccount implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_FINANCING_CHARGES_FOR_LOANS_AND_CREDIT_FACILITIES_NON_CURRENT_ACCOUNT;

  /**
   * Fires a new find financing charges for loans and credit facilities non current account action.
   */
  constructor() {}
}

/**
 * Represents a store find financing charges for loans and credit facilities non current account fail action.
 */
export class FindFinancingChargesForLoansAndCreditFacilitiesNonCurrentAccountFail implements Action {
  /**
   * The type of the action.
   */
  readonly type =
    SystemAccountsActionType.FIND_FINANCING_CHARGES_FOR_LOANS_AND_CREDIT_FACILITIES_NON_CURRENT_ACCOUNT_FAIL;

  /**
   * Fires a new find financing charges for loans and credit facilities non current account fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store find financing charges for loans and credit facilities non current account success action.
 */
export class FindFinancingChargesForLoansAndCreditFacilitiesNonCurrentAccountSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type =
    SystemAccountsActionType.FIND_FINANCING_CHARGES_FOR_LOANS_AND_CREDIT_FACILITIES_NON_CURRENT_ACCOUNT_SUCCESS;

  /**
   * Fires a new find financing charges for loans and credit facilities non current account success action.
   * @param payload The loaded financing charges for loans and credit facilities non current account.
   */
  constructor(public payload: AppHttpResponse<Account>) {}
}

/**
 * Represents a store find projects under implementation account action.
 */
export class FindProjectsUnderImplementationAccount implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_PROJECTS_UNDER_IMPLEMENTATION_ACCOUNT;

  /**
   * Fires a new find projects under implementation account action.
   */
  constructor() {}
}

/**
 * Represents a store find projects under implementation account fail action.
 */
export class FindProjectsUnderImplementationAccountFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_PROJECTS_UNDER_IMPLEMENTATION_ACCOUNT_FAIL;

  /**
   * Fires a new find projects under implementation account fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store find projects under implementation account success action.
 */
export class FindProjectsUnderImplementationAccountSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_PROJECTS_UNDER_IMPLEMENTATION_ACCOUNT_SUCCESS;

  /**
   * Fires a new find projects under implementation account success action.
   * @param payload The loaded projects under implementation account.
   */
  constructor(public payload: AppHttpResponse<Account>) {}
}

/**
 * Represents a store find lands account action.
 */
export class FindLandsAccount implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_LANDS_ACCOUNT;

  /**
   * Fires a new find lands account action.
   */
  constructor() {}
}

/**
 * Represents a store find lands account fail action.
 */
export class FindLandsAccountFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_LANDS_ACCOUNT_FAIL;

  /**
   * Fires a new find lands account fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store find lands account success action.
 */
export class FindLandsAccountSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_LANDS_ACCOUNT_SUCCESS;

  /**
   * Fires a new find lands account success action.
   * @param payload The loaded lands account.
   */
  constructor(public payload: AppHttpResponse<Account>) {}
}

/**
 * Represents a store find buildings account action.
 */
export class FindBuildingsAccount implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_BUILDINGS_ACCOUNT;

  /**
   * Fires a new find buildings account action.
   */
  constructor() {}
}

/**
 * Represents a store find buildings account fail action.
 */
export class FindBuildingsAccountFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_BUILDINGS_ACCOUNT_FAIL;

  /**
   * Fires a new find buildings account fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}
/**
 * Represents a store find buildings account success action.
 */
export class FindBuildingsAccountSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_BUILDINGS_ACCOUNT_SUCCESS;

  /**
   * Fires a new find buildings account success action.
   * @param payload The loaded buildings account.
   */
  constructor(public payload: AppHttpResponse<Account>) {}
}

/**
 * Represents a store find furniture account action.
 */
export class FindFurnitureAccount implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_FURNITURE_ACCOUNT;

  /**
   * Fires a new find furniture account action.
   */
  constructor() {}
}

/**
 * Represents a store find furniture account fail action.
 */
export class FindFurnitureAccountFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_FURNITURE_ACCOUNT_FAIL;

  /**
   * Fires a new find furniture account fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store find furniture account success action.
 */
export class FindFurnitureAccountSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_FURNITURE_ACCOUNT_SUCCESS;

  /**
   * Fires a new find furniture account success action.
   * @param payload The loaded furniture account.
   */
  constructor(public payload: AppHttpResponse<Account>) {}
}

/**
 * Represents a store find devices account action.
 */
export class FindDevicesAccount implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_DEVICES_ACCOUNT;

  /**
   * Fires a new find devices account action.
   */
  constructor() {}
}

/**
 * Represents a store find devices account fail action.
 */
export class FindDevicesAccountFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_DEVICES_ACCOUNT_FAIL;

  /**
   * Fires a new find devices account fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store find devices account success action.
 */
export class FindDevicesAccountSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_DEVICES_ACCOUNT_SUCCESS;

  /**
   * Fires a new find devices account success action.
   * @param payload The loaded devices account.
   */
  constructor(public payload: AppHttpResponse<Account>) {}
}

/**
 * Represents a store find computers account action.
 */
export class FindComputersAccount implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_COMPUTERS_ACCOUNT;

  /**
   * Fires a new find computers account action.
   */
  constructor() {}
}

/**
 * Represents a store find computers account fail action.
 */
export class FindComputersAccountFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_COMPUTERS_ACCOUNT_FAIL;

  /**
   * Fires a new find computers account fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store find computers account success action.
 */
export class FindComputersAccountSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_COMPUTERS_ACCOUNT_SUCCESS;

  /**
   * Fires a new find computers account success action.
   * @param payload The loaded lands account.
   */
  constructor(public payload: AppHttpResponse<Account>) {}
}

/**
 * Represents a store find equipments account action.
 */
export class FindEquipmentsAccount implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_EQUIPMENTS_ACCOUNT;

  /**
   * Fires a new find equipments account action.
   */
  constructor() {}
}

/**
 * Represents a store find equipments account fail action.
 */
export class FindEquipmentsAccountFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_EQUIPMENTS_ACCOUNT_FAIL;

  /**
   * Fires a new find equipments account fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store find equipments account success action.
 */
export class FindEquipmentsAccountSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_EQUIPMENTS_ACCOUNT_SUCCESS;

  /**
   * Fires a new find equipments account success action.
   * @param payload The loaded equipments account.
   */
  constructor(public payload: AppHttpResponse<Account>) {}
}

/**
 * Represents a store find cars account action.
 */
export class FindCarsAccount implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_CARS_ACCOUNT;

  /**
   * Fires a new find owners current account action.
   */
  constructor() {}
}

/**
 * Represents a store find cars account fail action.
 */
export class FindCarsAccountFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_CARS_ACCOUNT_FAIL;

  /**
   * Fires a new find cars account fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store find cars account success action.
 */
export class FindCarsAccountSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_CARS_ACCOUNT_SUCCESS;

  /**
   * Fires a new find cars account success action.
   * @param payload The loaded owners current account.
   */
  constructor(public payload: AppHttpResponse<Account>) {}
}

/**
 * Represents a store find assets under finance leases account action.
 */
export class FindAssetsUnderFinanceLeasesAccount implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_ASSETS_UNDER_FINANCE_LEASES_ACCOUNT;

  /**
   * Fires a new find assets under finance leases account action.
   */
  constructor() {}
}

/**
 * Represents a store find assets under finance leases account fail action.
 */
export class FindAssetsUnderFinanceLeasesAccountFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_ASSETS_UNDER_FINANCE_LEASES_ACCOUNT_FAIL;

  /**
   * Fires a new find assets under finance leases account fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store find assets under finance leases account success action.
 */
export class FindAssetsUnderFinanceLeasesAccountSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_ASSETS_UNDER_FINANCE_LEASES_ACCOUNT_SUCCESS;

  /**
   * Fires a new find assets under finance leases account success action.
   * @param payload The loaded assets under finance leases account.
   */
  constructor(public payload: AppHttpResponse<Account>) {}
}

/**
 * Represents a store find building consumption complex account action.
 */
export class FindBuildingConsumptionComplexAccount implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_BUILDING_CONSUMPTION_COMPLEX_ACCOUNT;

  /**
   * Fires a new find building consumption complex account action.
   */
  constructor() {}
}

/**
 * Represents a store find building consumption complex account fail action.
 */
export class FindBuildingConsumptionComplexAccountFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_BUILDING_CONSUMPTION_COMPLEX_ACCOUNT_FAIL;

  /**
   * Fires a new find building consumption complex account fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store find building consumption complex account success action.
 */
export class FindBuildingConsumptionComplexAccountSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_BUILDING_CONSUMPTION_COMPLEX_ACCOUNT_SUCCESS;

  /**
   * Fires a new find building consumption complex account success action.
   * @param payload The loaded building consumption complex account.
   */
  constructor(public payload: AppHttpResponse<Account>) {}
}

/**
 * Represents a store find furniture consumption complex account action.
 */
export class FindFurnitureConsumptionComplexAccount implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_FURNITURE_CONSUMPTION_COMPLEX_ACCOUNT;

  /**
   * Fires a new find furniture consumption complex account action.
   */
  constructor() {}
}

/**
 * Represents a store find furniture consumption complex account fail action.
 */
export class FindFurnitureConsumptionComplexAccountFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_FURNITURE_CONSUMPTION_COMPLEX_ACCOUNT_FAIL;

  /**
   * Fires a new find furniture consumption complex account fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store find furniture consumption complex account success action.
 */
export class FindFurnitureConsumptionComplexAccountSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_FURNITURE_CONSUMPTION_COMPLEX_ACCOUNT_SUCCESS;

  /**
   * Fires a new find furniture consumption complex account success action.
   * @param payload The loaded furniture consumption complex account.
   */
  constructor(public payload: AppHttpResponse<Account>) {}
}

/**
 * Represents a store find devices consumption complex account action.
 */
export class FindDevicesConsumptionComplexAccount implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_DEVICES_CONSUMPTION_COMPLEX_ACCOUNT;

  /**
   * Fires a new find devices consumption complex account action.
   */
  constructor() {}
}

/**
 * Represents a store find devices consumption complex account fail action.
 */
export class FindDevicesConsumptionComplexAccountFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_DEVICES_CONSUMPTION_COMPLEX_ACCOUNT_FAIL;

  /**
   * Fires a new find furniture consumption complex account fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store find consumption complex account success action.
 */
export class FindDevicesConsumptionComplexAccountSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_DEVICES_CONSUMPTION_COMPLEX_ACCOUNT_SUCCESS;

  /**
   * Fires a new find devices consumption complex account success action.
   * @param payload The loaded devices consumption complex account.
   */
  constructor(public payload: AppHttpResponse<Account>) {}
}

/**
 * Represents a store find computers consumption complex account action.
 */
export class FindComputersConsumptionComplexAccount implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_COMPUTERS_CONSUMPTION_COMPLEX_ACCOUNT;

  /**
   * Fires a new find computers consumption complex account action.
   */
  constructor() {}
}

/**
 * Represents a store find computers consumption complex account fail action.
 */
export class FindComputersConsumptionComplexAccountFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_COMPUTERS_CONSUMPTION_COMPLEX_ACCOUNT_FAIL;

  /**
   * Fires a new find computers consumption complex account fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store find computers consumption complex account success action.
 */
export class FindComputersConsumptionComplexAccountSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_COMPUTERS_CONSUMPTION_COMPLEX_ACCOUNT_SUCCESS;

  /**
   * Fires a new find computers  consumption complex account success action.
   * @param payload The loaded computers  consumption complex account.
   */
  constructor(public payload: AppHttpResponse<Account>) {}
}

/**
 * Represents a store find equipments consumption complex account action.
 */
export class FindEquipmentsConsumptionComplexAccount implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_EQUIPMENTS_CONSUMPTION_COMPLEX_ACCOUNT;

  /**
   * Fires a new find equipments consumption complex account action.
   */
  constructor() {}
}

/**
 * Represents a store find equipments consumption complex account fail action.
 */
export class FindEquipmentsConsumptionComplexAccountFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_EQUIPMENTS_CONSUMPTION_COMPLEX_ACCOUNT_FAIL;

  /**
   * Fires a new find equipments consumption complex account fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store find equipments consumption complex account success action.
 */
export class FindEquipmentsConsumptionComplexAccountSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_EQUIPMENTS_CONSUMPTION_COMPLEX_ACCOUNT_SUCCESS;

  /**
   * Fires a new find equipments consumption complex account success action.
   * @param payload The loaded equipments consumption complex account.
   */
  constructor(public payload: AppHttpResponse<Account>) {}
}

/**
 * Represents a store find cars consumption complex account action.
 */
export class FindCarsConsumptionComplexAccount implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_CARS_CONSUMPTION_COMPLEX_ACCOUNT;

  /**
   * Fires a new find cars consumption complex account action.
   */
  constructor() {}
}

/**
 * Represents a store find cars consumption complex account fail action.
 */
export class FindCarsConsumptionComplexAccountFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_CARS_CONSUMPTION_COMPLEX_ACCOUNT_FAIL;

  /**
   * Fires a new find cars consumption complex account fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store find cars consumption complex account success action.
 */
export class FindCarsConsumptionComplexAccountSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_CARS_CONSUMPTION_COMPLEX_ACCOUNT_SUCCESS;

  /**
   * Fires a new find cars consumption complex account success action.
   * @param payload The loaded cars consumption complex account.
   */
  constructor(public payload: AppHttpResponse<Account>) {}
}

/**
 * Represents a store find pooled depreciation of assets under finance lease contracts account action.
 */
export class FindPooledDepreciationOfAssetsUnderFinanceLeaseContractsAccount implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_POOLED_DEPRECIATION_OF_ASSETS_UNDER_FINANCE_LEASE_CONTRACTS_ACCOUNT;

  /**
   * Fires a new find pooled depreciation of assets under finance lease contracts account action.
   */
  constructor() {}
}

/**
 * Represents a store find pooled depreciation of assets under finance lease contracts account fail action.
 */
export class FindPooledDepreciationOfAssetsUnderFinanceLeaseContractsAccountFail implements Action {
  /**
   * The type of the action.
   */
  readonly type =
    SystemAccountsActionType.FIND_POOLED_DEPRECIATION_OF_ASSETS_UNDER_FINANCE_LEASE_CONTRACTS_ACCOUNT_FAIL;

  /**
   * Fires a new find pooled depreciation of assets under finance lease contracts account fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store find pooled depreciation of assets under finance lease contracts account success action.
 */
export class FindPooledDepreciationOfAssetsUnderFinanceLeaseContractsAccountSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type =
    SystemAccountsActionType.FIND_POOLED_DEPRECIATION_OF_ASSETS_UNDER_FINANCE_LEASE_CONTRACTS_ACCOUNT_SUCCESS;

  /**
   * Fires a new find pooled depreciation of assets under finance lease contracts account success action.
   * @param payload The loaded pooled depreciation of assets under finance lease contracts account.
   */
  constructor(public payload: AppHttpResponse<Account>) {}
}

/**
 * Represents a store find fame account action.
 */
export class FindFameAccount implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_FAME_ACCOUNT;

  /**
   * Fires a new find fame account action.
   */
  constructor() {}
}

/**
 * Represents a store find fame account fail action.
 */
export class FindFameAccountFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_FAME_ACCOUNT_FAIL;

  /**
   * Fires a new find fame account fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store find fame account success action.
 */
export class FindFameAccountSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_FAME_ACCOUNT_SUCCESS;

  /**
   * Fires a new find fame account success action.
   * @param payload The loaded fame account.
   */
  constructor(public payload: AppHttpResponse<Account>) {}
}

/**
 * Represents a store find software and systems account action.
 */
export class FindSoftwareAndSystemsAccount implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_SOFTWARE_AND_SYSTEMS_ACCOUNT;

  /**
   * Fires a new find software and systems account action.
   */
  constructor() {}
}

/**
 * Represents a store find software and systems account fail action.
 */
export class FindSoftwareAndSystemsAccountFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_SOFTWARE_AND_SYSTEMS_ACCOUNT_FAIL;

  /**
   * Fires a new find software and systems account fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store find software and systems account success action.
 */
export class FindSoftwareAndSystemsAccountSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_SOFTWARE_AND_SYSTEMS_ACCOUNT_SUCCESS;

  /**
   * Fires a new find software and systems account success action.
   * @param payload The loaded software and systems account.
   */
  constructor(public payload: AppHttpResponse<Account>) {}
}

/**
 * Represents a store find patents and trademarks account action.
 */
export class FindPatentsAndTrademarksAccount implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_PATENTS_AND_TRADEMARKS_ACCOUNT;

  /**
   * Fires a new find patents and trademarks account action.
   */
  constructor() {}
}

/**
 * Represents a store find patents and trademarks account fail action.
 */
export class FindPatentsAndTrademarksAccountFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_PATENTS_AND_TRADEMARKS_ACCOUNT_FAIL;

  /**
   * Fires a new find patents and trademarks account fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store find patents and trademarks account success action.
 */
export class FindPatentsAndTrademarksAccountSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_PATENTS_AND_TRADEMARKS_ACCOUNT_SUCCESS;

  /**
   * Fires a new find patents and trademarks account success action.
   * @param payload The loaded patents and trademarks account.
   */
  constructor(public payload: AppHttpResponse<Account>) {}
}

/**
 * Represents a store find fame extinguishing complex account action.
 */
export class FindFameExtinguishingComplexAccount implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_FAME_EXTINGUISHING_COMPLEX_ACCOUNT;

  /**
   * Fires a new find fame extinguishing complex account action.
   */
  constructor() {}
}

/**
 * Represents a store find fame extinguishing complex account fail action.
 */
export class FindFameExtinguishingComplexAccountFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_FAME_EXTINGUISHING_COMPLEX_ACCOUNT_FAIL;

  /**
   * Fires a new find fame extinguishing complex account fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store find fame extinguishing complex account success action.
 */
export class FindFameExtinguishingComplexAccountSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_FAME_EXTINGUISHING_COMPLEX_ACCOUNT_SUCCESS;

  /**
   * Fires a new find fame extinguishing complex account success action.
   * @param payload The loaded fame extinguishing complex account.
   */
  constructor(public payload: AppHttpResponse<Account>) {}
}

/**
 * Represents a store find software and hardware extinguishing complex account action.
 */
export class FindSoftwareAndHardwareExtinguishingComplexAccount implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_SOFTWARE_AND_HARDWARE_EXTINGUISHING_COMPLEX_ACCOUNT;

  /**
   * Fires a new find software and hardware extinguishing complex account action.
   */
  constructor() {}
}

/**
 * Represents a store find software and hardware extinguishing complex account fail action.
 */
export class FindSoftwareAndHardwareExtinguishingComplexAccountFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_SOFTWARE_AND_HARDWARE_EXTINGUISHING_COMPLEX_ACCOUNT_FAIL;

  /**
   * Fires a new find software and hardware extinguishing complex account fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store find software and hardware extinguishing complex account success action.
 */
export class FindSoftwareAndHardwareExtinguishingComplexAccountSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_SOFTWARE_AND_HARDWARE_EXTINGUISHING_COMPLEX_ACCOUNT_SUCCESS;

  /**
   * Fires a new find software and hardware extinguishing complex account success action.
   * @param payload The loaded software and hardware extinguishing complex account.
   */
  constructor(public payload: AppHttpResponse<Account>) {}
}

/**
 * Represents a store find patent and trademark extinguishing complex account action.
 */
export class FindPatentAndTrademarkExtinguishingComplexAccount implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_PATENT_AND_TRADEMARK_EXTINGUISHING_COMPLEX_ACCOUNT;

  /**
   * Fires a new find patent and trademark extinguishing complex account action.
   */
  constructor() {}
}

/**
 * Represents a store find patent and trademark extinguishing complex account fail action.
 */
export class FindPatentAndTrademarkExtinguishingComplexAccountFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_PATENT_AND_TRADEMARK_EXTINGUISHING_COMPLEX_ACCOUNT_FAIL;

  /**
   * Fires a new find patent and trademark extinguishing complex account fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store find patent and trademark extinguishing complex account success action.
 */
export class FindPatentAndTrademarkExtinguishingComplexAccountSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_PATENT_AND_TRADEMARK_EXTINGUISHING_COMPLEX_ACCOUNT_SUCCESS;

  /**
   * Fires a new find patent and trademark extinguishing complex account success action.
   * @param payload The loaded patent and trademark extinguishing complex account.
   */
  constructor(public payload: AppHttpResponse<Account>) {}
}

/**
 * Represents a store find real estate investment lands account action.
 */
export class FindRealEstateInvestmentLandsAccount implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_REAL_ESTATE_INVESTMENT_LANDS_ACCOUNT;

  /**
   * Fires a new find real estate investment lands account action.
   */
  constructor() {}
}

/**
 * Represents a store find real estate investment lands account fail action.
 */
export class FindRealEstateInvestmentLandsAccountFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_REAL_ESTATE_INVESTMENT_LANDS_ACCOUNT_FAIL;

  /**
   * Fires a new find real estate investment lands account fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store find real estate investment lands account success action.
 */
export class FindRealEstateInvestmentLandsAccountSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_REAL_ESTATE_INVESTMENT_LANDS_ACCOUNT_SUCCESS;

  /**
   * Fires a new find real estate investment lands account success action.
   * @param payload The loaded real estate investment lands account.
   */
  constructor(public payload: AppHttpResponse<Account>) {}
}

/**
 * Represents a store find real estate investment buildings account action.
 */
export class FindRealEstateInvestmentBuildingsAccount implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_REAL_ESTATE_INVESTMENT_BUILDINGS_ACCOUNT;

  /**
   * Fires a new find real estate investment buildings account action.
   */
  constructor() {}
}

/**
 * Represents a store find real estate investment buildings account fail action.
 */
export class FindRealEstateInvestmentBuildingsAccountFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_REAL_ESTATE_INVESTMENT_BUILDINGS_ACCOUNT_FAIL;

  /**
   * Fires a new find real estate investment buildings account fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store find real estate investment buildings account success action.
 */
export class FindRealEstateInvestmentBuildingsAccountSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_REAL_ESTATE_INVESTMENT_BUILDINGS_ACCOUNT_SUCCESS;

  /**
   * Fires a new find real estate investment buildings account success action.
   * @param payload The loaded real estate investment buildings account.
   */
  constructor(public payload: AppHttpResponse<Account>) {}
}

/**
 * Represents a store find real estate investment building consumption complex account action.
 */
export class FindRealEstateInvestmentBuildingConsumptionComplexAccount implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_REAL_ESTATE_INVESTMENT_BUILDING_CONSUMPTION_COMPLEX_ACCOUNT;

  /**
   * Fires a new find real estate investment building consumption complex account action.
   */
  constructor() {}
}

/**
 * Represents a store find real estate investment building consumption complex account fail action.
 */
export class FindRealEstateInvestmentBuildingConsumptionComplexAccountFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_REAL_ESTATE_INVESTMENT_BUILDING_CONSUMPTION_COMPLEX_ACCOUNT_FAIL;

  /**
   * Fires a new find real estate investment building consumption complex account fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store find real estate investment building consumption complex account success action.
 */
export class FindRealEstateInvestmentBuildingConsumptionComplexAccountSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_REAL_ESTATE_INVESTMENT_BUILDING_CONSUMPTION_COMPLEX_ACCOUNT_SUCCESS;

  /**
   * Fires a new find real estate investment building consumption complex account success action.
   * @param payload The loaded real estate investment building consumption complex account.
   */
  constructor(public payload: AppHttpResponse<Account>) {}
}

/**
 * Represents a store find investments in subsidiaries account action.
 */
export class FindInvestmentsInSubsidiariesAccount implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_INVESTMENTS_IN_SUBSIDIARIES_ACCOUNT;

  /**
   * Fires a new find investments in subsidiaries account action.
   */
  constructor() {}
}

/**
 * Represents a store find investments in subsidiaries account fail action.
 */
export class FindInvestmentsInSubsidiariesAccountFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_INVESTMENTS_IN_SUBSIDIARIES_ACCOUNT_FAIL;

  /**
   * Fires a new find investments in subsidiaries account fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store find investments in subsidiaries account success action.
 */
export class FindInvestmentsInSubsidiariesAccountSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_INVESTMENTS_IN_SUBSIDIARIES_ACCOUNT_SUCCESS;

  /**
   * Fires a new find investments in subsidiaries account success action.
   * @param payload The loaded investments in subsidiaries account.
   */
  constructor(public payload: AppHttpResponse<Account>) {}
}

/**
 * Represents a store find investments in associates account action.
 */
export class FindInvestmentsInAssociatesAccount implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_INVESTMENTS_IN_ASSOCIATES_ACCOUNT;

  /**
   * Fires a new find investments in associates account action.
   */
  constructor() {}
}

/**
 * Represents a store find investments in associates account fail action.
 */
export class FindInvestmentsInAssociatesAccountFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_INVESTMENTS_IN_ASSOCIATES_ACCOUNT_FAIL;

  /**
   * Fires a new find investments in associates account fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store find investments in associates account success action.
 */
export class FindInvestmentsInAssociatesAccountSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_INVESTMENTS_IN_ASSOCIATES_ACCOUNT_SUCCESS;

  /**
   * Fires a new find investments in associates account success action.
   * @param payload The loaded investments in associates account.
   */
  constructor(public payload: AppHttpResponse<Account>) {}
}

/**
 * Represents a store find investments in joint ventures account action.
 */
export class FindInvestmentsInJointVenturesAccount implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_INVESTMENTS_IN_JOINT_VENTURES_ACCOUNT;

  /**
   * Fires a new find investments in joint ventures account action.
   */
  constructor() {}
}

/**
 * Represents a store find investments in joint ventures account fail action.
 */
export class FindInvestmentsInJointVenturesAccountFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_INVESTMENTS_IN_JOINT_VENTURES_ACCOUNT_FAIL;

  /**
   * Fires a new find investments in joint ventures account fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store find investments in joint ventures account success action.
 */
export class FindInvestmentsInJointVenturesAccountSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_INVESTMENTS_IN_JOINT_VENTURES_ACCOUNT_SUCCESS;

  /**
   * Fires a new find investments in joint ventures account success action.
   * @param payload The loaded investments in joint ventures account.
   */
  constructor(public payload: AppHttpResponse<Account>) {}
}

/**
 * Represents a store find loan installments the current part account action.
 */
export class FindLoanInstallmentsTheCurrentPartAccount implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_LOAN_INSTALLMENTS_THE_CURRENT_PART_ACCOUNT;

  /**
   * Fires a new find loan installments the current part account action.
   */
  constructor() {}
}

/**
 * Represents a store find loan installments the current part account fail action.
 */
export class FindLoanInstallmentsTheCurrentPartAccountFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_LOAN_INSTALLMENTS_THE_CURRENT_PART_ACCOUNT_FAIL;

  /**
   * Fires a new find loan installments the current part account fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store find loan installments the current part account success action.
 */
export class FindLoanInstallmentsTheCurrentPartAccountSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_LOAN_INSTALLMENTS_THE_CURRENT_PART_ACCOUNT_SUCCESS;

  /**
   * Fires a new find loan installments the current part account success action.
   * @param payload The loaded loan installments the current part account.
   */
  constructor(public payload: AppHttpResponse<Account>) {}
}

/**
 * Represents a store find current contracts for rent the current part account action.
 */
export class FindCurrentContractsForRentTheCurrentPartAccount implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_CURRENT_CONTRACTS_FOR_RENT_THE_CURRENT_PART_ACCOUNT;

  /**
   * Fires a new find current contracts for rent the current part account action.
   */
  constructor() {}
}

/**
 * Represents a store find current contracts for rent the current part account fail action.
 */
export class FindCurrentContractsForRentTheCurrentPartAccountFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_CURRENT_CONTRACTS_FOR_RENT_THE_CURRENT_PART_ACCOUNT_FAIL;

  /**
   * Fires a new find current contracts for rent the current part account fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store find current contracts for rent the current part account success action.
 */
export class FindCurrentContractsForRentTheCurrentPartAccountSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_CURRENT_CONTRACTS_FOR_RENT_THE_CURRENT_PART_ACCOUNT_SUCCESS;

  /**
   * Fires a new find current contracts for rent the current part account success action.
   * @param payload The loaded current contracts for rent the current part account.
   */
  constructor(public payload: AppHttpResponse<Account>) {}
}

/**
 * Represents a store find trade creditors account action.
 */
export class FindTradeCreditorsAccount implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_TRADE_CREDITORS_ACCOUNT;

  /**
   * Fires a new find trade creditors account action.
   */
  constructor() {}
}

/**
 * Represents a store find trade creditors account fail action.
 */
export class FindTradeCreditorsAccountFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_TRADE_CREDITORS_ACCOUNT_FAIL;

  /**
   * Fires a new find trade creditors account fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store find trade creditors account success action.
 */
export class FindTradeCreditorsAccountSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_TRADE_CREDITORS_ACCOUNT_SUCCESS;

  /**
   * Fires a new find trade creditors account success action.
   * @param payload The loaded trade creditors account.
   */
  constructor(public payload: AppHttpResponse<Account>) {}
}

/**
 * Represents a store find vendors broker account action.
 */
export class FindVendorsBrokerAccount implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_VENDORS_BROKER_ACCOUNT;

  /**
   * Fires a new find vendors broker account action.
   */
  constructor() {}
}

/**
 * Represents a store find vendors broker account fail action.
 */
export class FindVendorsBrokerAccountFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_VENDORS_BROKER_ACCOUNT_FAIL;

  /**
   * Fires a new find vendors broker account fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store find vendors broker account success action.
 */
export class FindVendorsBrokerAccountSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_VENDORS_BROKER_ACCOUNT_SUCCESS;

  /**
   * Fires a new find vendors broker account success action.
   * @param payload The loaded vendors broker account.
   */
  constructor(public payload: AppHttpResponse<Account>) {}
}

/**
 * Represents a store find credit facilities overdraft account action.
 */
export class FindCreditFacilitiesOverdraftAccount implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_CREDIT_FACILITIES_OVERDRAFT_ACCOUNT;

  /**
   * Fires a new find credit facilities overdraft account action.
   */
  constructor() {}
}

/**
 * Represents a store find credit facilities overdraft account fail action.
 */
export class FindCreditFacilitiesOverdraftAccountFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_CREDIT_FACILITIES_OVERDRAFT_ACCOUNT_FAIL;

  /**
   * Fires a new find credit facilities overdraft account fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store find credit facilities overdraft account success action.
 */
export class FindCreditFacilitiesOverdraftAccountSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_CREDIT_FACILITIES_OVERDRAFT_ACCOUNT_SUCCESS;

  /**
   * Fires a new find credit facilities overdraft account success action.
   * @param payload The loaded credit facilities overdraft account.
   */
  constructor(public payload: AppHttpResponse<Account>) {}
}

/**
 * Represents a store find creditors withholding balances account action.
 */
export class FindCreditorsWithholdingBalancesAccount implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_CREDITORS_WITHHOLDING_BALANCES_ACCOUNT;

  /**
   * Fires a new find creditors withholding balances account action.
   */
  constructor() {}
}

/**
 * Represents a store find creditors withholding balances account fail action.
 */
export class FindCreditorsWithholdingBalancesAccountFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_CREDITORS_WITHHOLDING_BALANCES_ACCOUNT_FAIL;

  /**
   * Fires a new find creditors withholding balances account fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store find creditors withholding balances account success action.
 */
export class FindCreditorsWithholdingBalancesAccountSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_CREDITORS_WITHHOLDING_BALANCES_ACCOUNT_SUCCESS;

  /**
   * Fires a new find creditors withholding balances account success action.
   * @param payload The loaded creditors withholding balances account.
   */
  constructor(public payload: AppHttpResponse<Account>) {}
}

/**
 * Represents a store find payable expenses account action.
 */
export class FindPayableExpensesAccount implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_PAYABLE_EXPENSES_ACCOUNT;

  /**
   * Fires a new find payable expenses account action.
   */
  constructor() {}
}

/**
 * Represents a store find payable expenses account fail action.
 */
export class FindPayableExpensesAccountFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_PAYABLE_EXPENSES_ACCOUNT_FAIL;

  /**
   * Fires a new find payable expenses account fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store find payable expenses account success action.
 */
export class FindPayableExpensesAccountSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_PAYABLE_EXPENSES_ACCOUNT_SUCCESS;

  /**
   * Fires a new find payable expenses account success action.
   * @param payload The loaded payable expenses account.
   */
  constructor(public payload: AppHttpResponse<Account>) {}
}

/**
 * Represents a store find employees entitlements salaries account action.
 */
export class FindEmployeesEntitlementsSalariesAccount implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_EMPLOYEES_ENTITLEMENTS_SALARIES_ACCOUNT;

  /**
   * Fires a new find employees entitlements salaries account action.
   */
  constructor() {}
}

/**
 * Represents a store find employees entitlements salaries account fail action.
 */
export class FindEmployeesEntitlementsSalariesAccountFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_EMPLOYEES_ENTITLEMENTS_SALARIES_ACCOUNT_FAIL;

  /**
   * Fires a new find employees entitlements salaries account fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store find employees entitlements salaries account success action.
 */
export class FindEmployeesEntitlementsSalariesAccountSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_EMPLOYEES_ENTITLEMENTS_SALARIES_ACCOUNT_SUCCESS;

  /**
   * Fires a new find employees entitlements salaries account success action.
   * @param payload The loaded employees entitlements salaries account.
   */
  constructor(public payload: AppHttpResponse<Account>) {}
}

/**
 * Represents a store find employees entitlements benefits account action.
 */
export class FindEmployeesEntitlementsBenefitsAccount implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_EMPLOYEES_ENTITLEMENTS_BENEFITS_ACCOUNT;

  /**
   * Fires a new find employees entitlements benefits account action.
   */
  constructor() {}
}

/**
 * Represents a store find employees entitlements benefits account fail action.
 */
export class FindEmployeesEntitlementsBenefitsAccountFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_EMPLOYEES_ENTITLEMENTS_BENEFITS_ACCOUNT_FAIL;

  /**
   * Fires a new find employees entitlements benefits account fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store find employees entitlements benefits account success action.
 */
export class FindEmployeesEntitlementsBenefitsAccountSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_EMPLOYEES_ENTITLEMENTS_BENEFITS_ACCOUNT_SUCCESS;

  /**
   * Fires a new find employees entitlements benefits account success action.
   * @param payload The loaded employees entitlements benefits account.
   */
  constructor(public payload: AppHttpResponse<Account>) {}
}

/**
 * Represents a store find employees entitlements traveling tickets account action.
 */
export class FindEmployeesEntitlementsTravelingTicketsAccount implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_EMPLOYEES_ENTITLEMENTS_TRAVELING_TICKETS_ACCOUNT;

  /**
   * Fires a new find employees entitlements traveling tickets account action.
   */
  constructor() {}
}

/**
 * Represents a store find employees entitlements traveling tickets account fail action.
 */
export class FindEmployeesEntitlementsTravelingTicketsAccountFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_EMPLOYEES_ENTITLEMENTS_TRAVELING_TICKETS_ACCOUNT_FAIL;

  /**
   * Fires a new find employees entitlements traveling tickets account fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store find employees entitlements traveling tickets account success action.
 */
export class FindEmployeesEntitlementsTravelingTicketsAccountSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_EMPLOYEES_ENTITLEMENTS_TRAVELING_TICKETS_ACCOUNT_SUCCESS;

  /**
   * Fires a new find employees entitlements traveling tickets account success action.
   * @param payload The loaded employees entitlements traveling tickets account.
   */
  constructor(public payload: AppHttpResponse<Account>) {}
}

/**
 * Represents a store find advance revenue account action.
 */
export class FindAdvanceRevenueAccount implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_ADVANCE_REVENUE_ACCOUNT;

  /**
   * Fires a new find advance revenue account action.
   */
  constructor() {}
}

/**
 * Represents a store find advance revenue account fail action.
 */
export class FindAdvanceRevenueAccountFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_ADVANCE_REVENUE_ACCOUNT_FAIL;

  /**
   * Fires a new find advance revenue account fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store find advance revenue account success action.
 */
export class FindAdvanceRevenueAccountSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_ADVANCE_REVENUE_ACCOUNT_SUCCESS;

  /**
   * Fires a new find advance revenue account success action.
   * @param payload The loaded advance revenue account.
   */
  constructor(public payload: AppHttpResponse<Account>) {}
}

/**
 * Represents a store find value added tax credit account action.
 */
export class FindValueAddedTaxCreditAccount implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_VALUE_ADDED_TAX_CREDIT_ACCOUNT;

  /**
   * Fires a new find value added tax credit account action.
   */
  constructor() {}
}

/**
 * Represents a store find value added tax credit account fail action.
 */
export class FindValueAddedTaxCreditAccountFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_VALUE_ADDED_TAX_CREDIT_ACCOUNT_FAIL;

  /**
   * Fires a new find value added tax credit account fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store find value added tax credit account success action.
 */
export class FindValueAddedTaxCreditAccountSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_VALUE_ADDED_TAX_CREDIT_ACCOUNT_SUCCESS;

  /**
   * Fires a new find value added tax credit account success action.
   * @param payload The loaded value added tax credit account.
   */
  constructor(public payload: AppHttpResponse<Account>) {}
}

/**
 * Represents a store find receivables staff account action.
 */
export class FindReceivablesStaffAccount implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_RECEIVABLES_STAFF_ACCOUNT;

  /**
   * Fires a new find receivables staff account action.
   */
  constructor() {}
}

/**
 * Represents a store find receivables staff account fail action.
 */
export class FindReceivablesStaffAccountFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_RECEIVABLES_STAFF_ACCOUNT_FAIL;

  /**
   * Fires a new find receivables staff account fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store find receivables staff account success action.
 */
export class FindReceivablesStaffAccountSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_RECEIVABLES_STAFF_ACCOUNT_SUCCESS;

  /**
   * Fires a new find receivables staff account success action.
   * @param payload The loaded receivables staff account.
   */
  constructor(public payload: AppHttpResponse<Account>) {}
}

/**
 * Represents a store find other current liabilities related parties account action.
 */
export class FindOtherCurrentLiabilitiesRelatedPartiesAccount implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_OTHER_CURRENT_LIABILITIES_RELATED_PARTIES_ACCOUNT;

  /**
   * Fires a new find other current liabilities related parties account action.
   */
  constructor() {}
}

/**
 * Represents a store find other current liabilities related parties account fail action.
 */
export class FindOtherCurrentLiabilitiesRelatedPartiesAccountFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_OTHER_CURRENT_LIABILITIES_RELATED_PARTIES_ACCOUNT_FAIL;

  /**
   * Fires a new find other current liabilities related parties account fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store find other current liabilities related parties account success action.
 */
export class FindOtherCurrentLiabilitiesRelatedPartiesAccountSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_OTHER_CURRENT_LIABILITIES_RELATED_PARTIES_ACCOUNT_SUCCESS;

  /**
   * Fires a new find other current liabilities related parties account success action.
   * @param payload The loaded other current liabilities related parties account.
   */
  constructor(public payload: AppHttpResponse<Account>) {}
}

/**
 * Represents a store find miscellaneous creditors account action.
 */
export class FindMiscellaneousCreditorsAccount implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_MISCELLANEOUS_CREDITORS_ACCOUNT;

  /**
   * Fires a new find miscellaneous creditors account action.
   */
  constructor() {}
}

/**
 * Represents a store find miscellaneous creditors account fail action.
 */
export class FindMiscellaneousCreditorsAccountFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_MISCELLANEOUS_CREDITORS_ACCOUNT_FAIL;

  /**
   * Fires a new find miscellaneous creditors account fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store find miscellaneous creditors account success action.
 */
export class FindMiscellaneousCreditorsAccountSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_MISCELLANEOUS_CREDITORS_ACCOUNT_SUCCESS;

  /**
   * Fires a new find miscellaneous creditors account success action.
   * @param payload The loaded miscellaneous creditors account.
   */
  constructor(public payload: AppHttpResponse<Account>) {}
}

/**
 * Represents a store find dividend payable account action.
 */
export class FindDividendPayableAccount implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_DIVIDEND_PAYABLE_ACCOUNT;

  /**
   * Fires a new find dividend payable account action.
   */
  constructor() {}
}

/**
 * Represents a store find dividend payable account fail action.
 */
export class FindDividendPayableAccountFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_DIVIDEND_PAYABLE_ACCOUNT_FAIL;

  /**
   * Fires a new find dividend payable account fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store find dividend payable account success action.
 */
export class FindDividendPayableAccountSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_DIVIDEND_PAYABLE_ACCOUNT_SUCCESS;

  /**
   * Fires a new find dividend payable account success action.
   * @param payload The loaded dividend payable account.
   */
  constructor(public payload: AppHttpResponse<Account>) {}
}

/**
 * Represents a store find zakat provision account action.
 */
export class FindZakatProvisionAccount implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_ZAKAT_PROVISION_ACCOUNT;

  /**
   * Fires a new find zakat provision account action.
   */
  constructor() {}
}

/**
 * Represents a store find zakat provision account fail action.
 */
export class FindZakatProvisionAccountFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_ZAKAT_PROVISION_ACCOUNT_FAIL;

  /**
   * Fires a new find zakat provision account fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store find zakat provision account success action.
 */
export class FindZakatProvisionAccountSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_ZAKAT_PROVISION_ACCOUNT_SUCCESS;

  /**
   * Fires a new find zakat provision account success action.
   * @param payload The loaded zakat provision account.
   */
  constructor(public payload: AppHttpResponse<Account>) {}
}

/**
 * Represents a store find income tax provision account action.
 */
export class FindIncomeTaxProvisionAccount implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_INCOME_TAX_PROVISION_ACCOUNT;

  /**
   * Fires a new find income tax provision account action.
   */
  constructor() {}
}

/**
 * Represents a store find income tax provision account fail action.
 */
export class FindIncomeTaxProvisionAccountFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_INCOME_TAX_PROVISION_ACCOUNT_FAIL;

  /**
   * Fires a new find income tax provision account fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store find income tax provision account success action.
 */
export class FindIncomeTaxProvisionAccountSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_INCOME_TAX_PROVISION_ACCOUNT_SUCCESS;

  /**
   * Fires a new find income tax provision account success action.
   * @param payload The loaded income tax provision account.
   */
  constructor(public payload: AppHttpResponse<Account>) {}
}

/**
 * Represents a store find provision for withholding tax account action.
 */
export class FindProvisionForWithholdingTaxAccount implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_PROVISION_FOR_WITHHOLDING_TAX_ACCOUNT;

  /**
   * Fires a new find provision for withholding tax account action.
   */
  constructor() {}
}

/**
 * Represents a store find provision for withholding tax account fail action.
 */
export class FindProvisionForWithholdingTaxAccountFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_PROVISION_FOR_WITHHOLDING_TAX_ACCOUNT_FAIL;

  /**
   * Fires a new find provision for withholding tax account fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store find provision for withholding tax account success action.
 */
export class FindProvisionForWithholdingTaxAccountSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_PROVISION_FOR_WITHHOLDING_TAX_ACCOUNT_SUCCESS;

  /**
   * Fires a new find provision for withholding tax account success action.
   * @param payload The loaded provision for withholding tax account.
   */
  constructor(public payload: AppHttpResponse<Account>) {}
}

/**
 * Represents a store find bank loans account action.
 */
export class FindBankLoansAccount implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_BANK_LOANS_ACCOUNT;

  /**
   * Fires a new find bank loans account action.
   */
  constructor() {}
}

/**
 * Represents a store find bank loans account fail action.
 */
export class FindBankLoansAccountFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_BANK_LOANS_ACCOUNT_FAIL;

  /**
   * Fires a new find bank loans account fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store find bank loans account success action.
 */
export class FindBankLoansAccountSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_BANK_LOANS_ACCOUNT_SUCCESS;

  /**
   * Fires a new find bank loans account success action.
   * @param payload The loaded bank loans account.
   */
  constructor(public payload: AppHttpResponse<Account>) {}
}

/**
 * Represents a store find lease liabilities non current portion account action.
 */
export class FindLeaseLiabilitiesNonCurrentPortionAccount implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_LEASE_LIABILITIES_NON_CURRENT_PORTION_ACCOUNT;

  /**
   * Fires a new find lease liabilities non current portion account action.
   */
  constructor() {}
}

/**
 * Represents a store find lease liabilities non current portion account fail action.
 */
export class FindLeaseLiabilitiesNonCurrentPortionAccountFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_LEASE_LIABILITIES_NON_CURRENT_PORTION_ACCOUNT_FAIL;

  /**
   * Fires a new find lease liabilities non current portion account fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store find lease liabilities non current portion account success action.
 */
export class FindLeaseLiabilitiesNonCurrentPortionAccountSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_LEASE_LIABILITIES_NON_CURRENT_PORTION_ACCOUNT_SUCCESS;

  /**
   * Fires a new find lease liabilities non current portion account success action.
   * @param payload The loaded lease liabilities non current portion account.
   */
  constructor(public payload: AppHttpResponse<Account>) {}
}

/**
 * Represents a store find provision for end of severance pay account action.
 */
export class FindProvisionForEndOfSeverancePayAccount implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_PROVISION_FOR_END_OF_SEVERANCE_PAY_ACCOUNT;

  /**
   * Fires a new find provision for end of severance pay account action.
   */
  constructor() {}
}

/**
 * Represents a store find provision for end of severance pay account fail action.
 */
export class FindProvisionForEndOfSeverancePayAccountFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_PROVISION_FOR_END_OF_SEVERANCE_PAY_ACCOUNT_FAIL;

  /**
   * Fires a new find provision for end of severance pay account fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store find provision for end of severance pay account success action.
 */
export class FindProvisionForEndOfSeverancePayAccountSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_PROVISION_FOR_END_OF_SEVERANCE_PAY_ACCOUNT_SUCCESS;

  /**
   * Fires a new find provision for end of severance pay account success action.
   * @param payload The loaded provision for end of severance pay account.
   */
  constructor(public payload: AppHttpResponse<Account>) {}
}

/**
 * Represents a store find capital account action.
 */
export class FindCapitalAccount implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_CAPITAL_ACCOUNT;

  /**
   * Fires a new find capital account action.
   */
  constructor() {}
}

/**
 * Represents a store find capital account fail action.
 */
export class FindCapitalAccountFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_CAPITAL_ACCOUNT_FAIL;

  /**
   * Fires a new find capital account fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store find capital account success action.
 */
export class FindCapitalAccountSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_CAPITAL_ACCOUNT_SUCCESS;

  /**
   * Fires a new find capital account success action.
   * @param payload The loaded capital account.
   */
  constructor(public payload: AppHttpResponse<Account>) {}
}

/**
 * Represents a store find regular reserve account action.
 */
export class FindRegularReserveAccount implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_REGULAR_RESERVE_ACCOUNT;

  /**
   * Fires a new find regular reserve account action.
   */
  constructor() {}
}

/**
 * Represents a store find regular reserve account fail action.
 */
export class FindRegularReserveAccountFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_REGULAR_RESERVE_ACCOUNT_FAIL;

  /**
   * Fires a new find regular reserve account fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store find regular reserve account success action.
 */
export class FindRegularReserveAccountSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_REGULAR_RESERVE_ACCOUNT_SUCCESS;

  /**
   * Fires a new find regular reserve account success action.
   * @param payload The loaded regular reserve account.
   */
  constructor(public payload: AppHttpResponse<Account>) {}
}

/**
 * Represents a store find general reserve account action.
 */
export class FindGeneralReserveAccount implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_GENERAL_RESERVE_ACCOUNT;

  /**
   * Fires a new find general reserve account action.
   */
  constructor() {}
}

/**
 * Represents a store find general reserve account fail action.
 */
export class FindGeneralReserveAccountFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_GENERAL_RESERVE_ACCOUNT_FAIL;

  /**
   * Fires a new find general reserve account fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store find general reserve account success action.
 */
export class FindGeneralReserveAccountSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_GENERAL_RESERVE_ACCOUNT_SUCCESS;

  /**
   * Fires a new find general reserve account success action.
   * @param payload The loaded general reserve account.
   */
  constructor(public payload: AppHttpResponse<Account>) {}
}

/**
 * Represents a store find retained earnings account action.
 */
export class FindRetainedEarningsAccount implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_RETAINED_EARNINGS_ACCOUNT;

  /**
   * Fires a new find retained earnings account action.
   */
  constructor() {}
}

/**
 * Represents a store find retained earnings account fail action.
 */
export class FindRetainedEarningsAccountFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_RETAINED_EARNINGS_ACCOUNT_FAIL;

  /**
   * Fires a new find retained earnings account fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store find retained earnings account success action.
 */
export class FindRetainedEarningsAccountSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_RETAINED_EARNINGS_ACCOUNT_SUCCESS;

  /**
   * Fires a new find retained earnings account success action.
   * @param payload The loaded retained earnings account.
   */
  constructor(public payload: AppHttpResponse<Account>) {}
}

/**
 * Represents a store find profit and loss broker account action.
 */
export class FindProfitAndLossBrokerAccount implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_PROFIT_AND_LOSS_BROKER_ACCOUNT;

  /**
   * Fires a new find profit and loss broker account action.
   */
  constructor() {}
}

/**
 * Represents a store find profit and loss broker account fail action.
 */
export class FindProfitAndLossBrokerAccountFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_PROFIT_AND_LOSS_BROKER_ACCOUNT_FAIL;

  /**
   * Fires a new find profit and loss broker account fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store find profit and loss broker account success action.
 */
export class FindProfitAndLossBrokerAccountSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_PROFIT_AND_LOSS_BROKER_ACCOUNT_SUCCESS;

  /**
   * Fires a new find profit and loss broker account success action.
   * @param payload The loaded profit and loss broker account.
   */
  constructor(public payload: AppHttpResponse<Account>) {}
}

/**
 * Represents a store find additional capital account action.
 */
export class FindAdditionalCapitalAccount implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_ADDITIONAL_CAPITAL_ACCOUNT;

  /**
   * Fires a new find additional capital account action.
   */
  constructor() {}
}

/**
 * Represents a store find additional capital account fail action.
 */
export class FindAdditionalCapitalAccountFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_ADDITIONAL_CAPITAL_ACCOUNT_FAIL;

  /**
   * Fires a new find additional capital account fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store find additional capital account success action.
 */
export class FindAdditionalCapitalAccountSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_ADDITIONAL_CAPITAL_ACCOUNT_SUCCESS;

  /**
   * Fires a new find additional capital account success action.
   * @param payload The loaded additional capital account.
   */
  constructor(public payload: AppHttpResponse<Account>) {}
}

/**
 * Represents a store find main activities sales revenue account action.
 */
export class FindMainActivitiesSalesRevenueAccount implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_MAIN_ACTIVITIES_SALES_REVENUE_ACCOUNT;

  /**
   * Fires a new find main activities sales revenue account action.
   */
  constructor() {}
}

/**
 * Represents a store find main activities sales revenue account fail action.
 */
export class FindMainActivitiesSalesRevenueAccountFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_MAIN_ACTIVITIES_SALES_REVENUE_ACCOUNT_FAIL;

  /**
   * Fires a new find main activities sales revenue account fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store find main activities sales revenuea account success action.
 */
export class FindMainActivitiesSalesRevenueAccountSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_MAIN_ACTIVITIES_SALES_REVENUE_ACCOUNT_SUCCESS;

  /**
   * Fires a new find main activities sales revenue account success action.
   * @param payload The loaded main activities sales revenue account.
   */
  constructor(public payload: AppHttpResponse<Account>) {}
}

/**
 * Represents a store find gain loss on disposal of property account action.
 */
export class FindGainLossOnDisposalOfPropertyAccount implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_GAIN_LOSS_ON_DISPOSAL_OF_PROPERTY_ACCOUNT;

  /**
   * Fires a new find gain loss on disposal of property account action.
   */
  constructor() {}
}

/**
 * Represents a store find gain loss on disposal of property account fail action.
 */
export class FindGainLossOnDisposalOfPropertyAccountFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_GAIN_LOSS_ON_DISPOSAL_OF_PROPERTY_ACCOUNT_FAIL;

  /**
   * Fires a new find gain loss on disposal of property account fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store find gain loss on disposal of property account success action.
 */
export class FindGainLossOnDisposalOfPropertyAccountSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_GAIN_LOSS_ON_DISPOSAL_OF_PROPERTY_ACCOUNT_SUCCESS;

  /**
   * Fires a new find gain loss on disposal of property account success action.
   * @param payload The loaded gain loss on disposal of property account.
   */
  constructor(public payload: AppHttpResponse<Account>) {}
}

/**
 * Represents a store find gain loss on investment valuation account action.
 */
export class FindGainLossOnInvestmentValuationAccount implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_GAIN_LOSS_ON_INVESTMENT_VALUATION_ACCOUNT;

  /**
   * Fires a new find gain loss on investment valuation account action.
   */
  constructor() {}
}

/**
 * Represents a store find gain loss on investment valuation account fail action.
 */
export class FindGainLossOnInvestmentValuationAccountFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_GAIN_LOSS_ON_INVESTMENT_VALUATION_ACCOUNT_FAIL;

  /**
   * Fires a new find gain loss on investment valuation account fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store find gain loss on investment valuation account success action.
 */
export class FindGainLossOnInvestmentValuationAccountSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_GAIN_LOSS_ON_INVESTMENT_VALUATION_ACCOUNT_SUCCESS;

  /**
   * Fires a new find gain loss on investment valuation account success action.
   * @param payload The loaded gain loss on investment valuation account.
   */
  constructor(public payload: AppHttpResponse<Account>) {}
}

/**
 * Represents a store find support revenue account action.
 */
export class FindSupportRevenueAccount implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_SUPPORT_REVENUE_ACCOUNT;

  /**
   * Fires a new find support revenue account action.
   */
  constructor() {}
}

/**
 * Represents a store find support revenue account fail action.
 */
export class FindSupportRevenueAccountFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_SUPPORT_REVENUE_ACCOUNT_FAIL;

  /**
   * Fires a new find support revenue account fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store find support revenue account success action.
 */
export class FindSupportRevenueAccountSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_SUPPORT_REVENUE_ACCOUNT_SUCCESS;

  /**
   * Fires a new find support revenue account success action.
   * @param payload The loaded support revenue account.
   */
  constructor(public payload: AppHttpResponse<Account>) {}
}

/**
 * Represents a store find other miscellaneous income account action.
 */
export class FindOtherMiscellaneousIncomeAccount implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_OTHER_MISCELLANEOUS_INCOME_ACCOUNT;

  /**
   * Fires a new find other miscellaneous income account action.
   */
  constructor() {}
}

/**
 * Represents a store find other miscellaneous income account fail action.
 */
export class FindOtherMiscellaneousIncomeAccountFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_OTHER_MISCELLANEOUS_INCOME_ACCOUNT_FAIL;

  /**
   * Fires a new find other miscellaneous income account fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store find other miscellaneous income account success action.
 */
export class FindOtherMiscellaneousIncomeAccountSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_OTHER_MISCELLANEOUS_INCOME_ACCOUNT_SUCCESS;

  /**
   * Fires a new find other miscellaneous income account success action.
   * @param payload The loaded other miscellaneous income account.
   */
  constructor(public payload: AppHttpResponse<Account>) {}
}

/**
 * Represents a store find used of material account action.
 */
export class FindUsedOfMaterialAccount implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_USED_OF_MATERIAL_ACCOUNT;

  /**
   * Fires a new find used of material account action.
   */
  constructor() {}
}

/**
 * Represents a store find used of material account fail action.
 */
export class FindUsedOfMaterialAccountFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_USED_OF_MATERIAL_ACCOUNT_FAIL;

  /**
   * Fires a new find used of material account fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store find used of material account success action.
 */
export class FindUsedOfMaterialAccountSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_USED_OF_MATERIAL_ACCOUNT_SUCCESS;

  /**
   * Fires a new find used of material account success action.
   * @param payload The loaded used of material account.
   */
  constructor(public payload: AppHttpResponse<Account>) {}
}

/**
 * Represents a store find non inventory material costs account action.
 */
export class FindNonInventoryMaterialCostsAccount implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_NON_INVENTORY_MATERIAL_COSTS_ACCOUNT;

  /**
   * Fires a new find non inventory material costs account action.
   */
  constructor() {}
}

/**
 * Represents a store find non inventory material costs account fail action.
 */
export class FindNonInventoryMaterialCostsAccountFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_NON_INVENTORY_MATERIAL_COSTS_ACCOUNT_FAIL;

  /**
   * Fires a new find non inventory material costs account fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store find non inventory material costs account success action.
 */
export class FindNonInventoryMaterialCostsAccountSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_NON_INVENTORY_MATERIAL_COSTS_ACCOUNT_SUCCESS;

  /**
   * Fires a new find non inventory material costs account success action.
   * @param payload The loaded non inventory material costs account.
   */
  constructor(public payload: AppHttpResponse<Account>) {}
}

/**
 * Represents a store find rent account action.
 */
export class FindRentAccount implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_RENT_ACCOUNT;

  /**
   * Fires a new find rent account action.
   */
  constructor() {}
}

/**
 * Represents a store find rent account fail action.
 */
export class FindRentAccountFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_RENT_ACCOUNT_FAIL;

  /**
   * Fires a new find rent account fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store find rent account success action.
 */
export class FindRentAccountSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_RENT_ACCOUNT_SUCCESS;

  /**
   * Fires a new find rent account success action.
   * @param payload The loaded rent costs account.
   */
  constructor(public payload: AppHttpResponse<Account>) {}
}

/**
 * Represents a store find local labor account action.
 */
export class FindLocalLaborAccount implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_LOCAL_LABOR_ACCOUNT;

  /**
   * Fires a new find local labor account action.
   */
  constructor() {}
}

/**
 * Represents a store find local labor account fail action.
 */
export class FindLocalLaborAccountFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_LOCAL_LABOR_ACCOUNT_FAIL;

  /**
   * Fires a new find local labor account fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store find local labor account success action.
 */
export class FindLocalLaborAccountSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_LOCAL_LABOR_ACCOUNT_SUCCESS;

  /**
   * Fires a new find local labor account success action.
   * @param payload The loaded local labor costs account.
   */
  constructor(public payload: AppHttpResponse<Account>) {}
}

/**
 * Represents a store find salaries and wages employee protection account action.
 */
export class FindSalariesAndWagesEmployeeProtectionAccount implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_SALARIES_AND_WAGES_EMPLOYEE_PROTECTION_ACCOUNT;

  /**
   * Fires a new find salaries and wages employee protection account action.
   */
  constructor() {}
}

/**
 * Represents a store find salaries and wages employee protection account fail action.
 */
export class FindSalariesAndWagesEmployeeProtectionAccountFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_SALARIES_AND_WAGES_EMPLOYEE_PROTECTION_ACCOUNT_FAIL;

  /**
   * Fires a new find salaries and wages employee protection account fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store find salaries and wages employee protection account success action.
 */
export class FindSalariesAndWagesEmployeeProtectionAccountSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_SALARIES_AND_WAGES_EMPLOYEE_PROTECTION_ACCOUNT_SUCCESS;

  /**
   * Fires a new find salaries and wages employee protection account success action.
   * @param payload The loaded salaries and wages employee protection costs account.
   */
  constructor(public payload: AppHttpResponse<Account>) {}
}

/**
 * Represents a store find benefit to employees all costs except wage protection elements account action.
 */
export class FindBenefitToEmployeesAllCostsExceptWageProtectionElementsAccount implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_BENEFIT_TO_EMPLOYEES_ALL_COSTS_EXCEPT_WAGE_PROTECTION_ELEMENTS_ACCOUNT;

  /**
   * Fires a new find benefit to employees all costs except wage protection elements account action.
   */
  constructor() {}
}

/**
 * Represents a store find benefit to employees all costs except wage protection elements account fail action.
 */
export class FindBenefitToEmployeesAllCostsExceptWageProtectionElementsAccountFail implements Action {
  /**
   * The type of the action.
   */
  readonly type =
    SystemAccountsActionType.FIND_BENEFIT_TO_EMPLOYEES_ALL_COSTS_EXCEPT_WAGE_PROTECTION_ELEMENTS_ACCOUNT_FAIL;

  /**
   * Fires a new find benefit to employees all costs except wage protection elements account fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store find benefit to employees all costs except wage protection elements account success action.
 */
export class FindBenefitToEmployeesAllCostsExceptWageProtectionElementsAccountSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type =
    SystemAccountsActionType.FIND_BENEFIT_TO_EMPLOYEES_ALL_COSTS_EXCEPT_WAGE_PROTECTION_ELEMENTS_ACCOUNT_SUCCESS;

  /**
   * Fires a new find benefit to employees all costs except wage protection elements account success action.
   * @param payload The loaded benefit to employees all costs except wage protection elements account.
   */
  constructor(public payload: AppHttpResponse<Account>) {}
}

/**
 * Represents a store find work missions and overtime account action.
 */
export class FindWorkMissionsAndOvertimeAccount implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_WORK_MISSIONS_AND_OVERTIME_ACCOUNT;

  /**
   * Fires a new find work missions and overtime account action.
   */
  constructor() {}
}

/**
 * Represents a store find work missions and overtime account fail action.
 */
export class FindWorkMissionsAndOvertimeAccountFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_WORK_MISSIONS_AND_OVERTIME_ACCOUNT_FAIL;

  /**
   * Fires a new find work missions and overtime account fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store find work missions and overtime account success action.
 */
export class FindWorkMissionsAndOvertimeAccountSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_WORK_MISSIONS_AND_OVERTIME_ACCOUNT_SUCCESS;

  /**
   * Fires a new find work missions and overtime account success action.
   * @param payload The loaded work missions and overtime account.
   */
  constructor(public payload: AppHttpResponse<Account>) {}
}

/**
 * Represents a store find repair and maintenance account action.
 */
export class FindRepairAndMaintenanceAccount implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_REPAIR_AND_MAINTENANCE_ACCOUNT;

  /**
   * Fires a new find repair and maintenance account action.
   */
  constructor() {}
}

/**
 * Represents a store find repair and maintenance account fail action.
 */
export class FindRepairAndMaintenanceAccountFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_REPAIR_AND_MAINTENANCE_ACCOUNT_FAIL;

  /**
   * Fires a new find repair and maintenance account fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store find repair and maintenance account success action.
 */
export class FindRepairAndMaintenanceAccountSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_REPAIR_AND_MAINTENANCE_ACCOUNT_SUCCESS;

  /**
   * Fires a new find repair and maintenance account success action.
   * @param payload The loaded repair and maintenance account.
   */
  constructor(public payload: AppHttpResponse<Account>) {}
}

/**
 * Represents a store find government fees and subscriptions account action.
 */
export class FindGovernmentFeesAndSubscriptionsAccount implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_GOVERNMENT_FEES_AND_SUBSCRIPTIONS_ACCOUNT;

  /**
   * Fires a new find government fees and subscriptions account action.
   */
  constructor() {}
}

/**
 * Represents a store find government fees and subscriptions account fail action.
 */
export class FindGovernmentFeesAndSubscriptionsAccountFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_GOVERNMENT_FEES_AND_SUBSCRIPTIONS_ACCOUNT_FAIL;

  /**
   * Fires a new find government fees and subscriptions account fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store find government fees and subscriptions account success action.
 */
export class FindGovernmentFeesAndSubscriptionsAccountSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_GOVERNMENT_FEES_AND_SUBSCRIPTIONS_ACCOUNT_SUCCESS;

  /**
   * Fires a new find government fees and subscriptions account success action.
   * @param payload The loaded government fees and subscriptions account.
   */
  constructor(public payload: AppHttpResponse<Account>) {}
}

/**
 * Represents a store find cost of public services account action.
 */
export class FindTheCostOfPublicServicesAccount implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_THE_COST_OF_PUBLIC_SERVICES_ACCOUNT;

  /**
   * Fires a new find cost of public services account action.
   */
  constructor() {}
}

/**
 * Represents a store find cost of public services account fail action.
 */
export class FindTheCostOfPublicServicesAccountFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_THE_COST_OF_PUBLIC_SERVICES_ACCOUNT_FAIL;

  /**
   * Fires a new find cost of public services account fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store find cost of public services account success action.
 */
export class FindTheCostOfPublicServicesAccountSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_THE_COST_OF_PUBLIC_SERVICES_ACCOUNT_SUCCESS;

  /**
   * Fires a new find cost of public services account success action.
   * @param payload The loaded cost of public services account.
   */
  constructor(public payload: AppHttpResponse<Account>) {}
}

/**
 * Represents a store find hospitality and cleanliness account action.
 */
export class FindHospitalityAndCleanlinessAccount implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_HOSPITALITY_AND_CLEANLINESS_ACCOUNT;

  /**
   * Fires a new find hospitality and cleanliness account action.
   */
  constructor() {}
}

/**
 * Represents a store find hospitality and cleanliness account fail action.
 */
export class FindHospitalityAndCleanlinessAccountFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_HOSPITALITY_AND_CLEANLINESS_ACCOUNT_FAIL;

  /**
   * Fires a new find hospitality and cleanliness account fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store find hospitality and cleanliness account success action.
 */
export class FindHospitalityAndCleanlinessAccountSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_HOSPITALITY_AND_CLEANLINESS_ACCOUNT_SUCCESS;

  /**
   * Fires a new find hospitality and cleanliness account success action.
   * @param payload The loaded hospitality and cleanliness account.
   */
  constructor(public payload: AppHttpResponse<Account>) {}
}

/**
 * Represents a store find office consumables account action.
 */
export class FindOfficeConsumablesAccount implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_OFFICE_CONSUMABLES_ACCOUNT;

  /**
   * Fires a new find office consumables account action.
   */
  constructor() {}
}

/**
 * Represents a store find office consumables account fail action.
 */
export class FindOfficeConsumablesAccountFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_OFFICE_CONSUMABLES_ACCOUNT_FAIL;

  /**
   * Fires a new find office consumables account fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store find office consumables account success action.
 */
export class FindOfficeConsumablesAccountSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_OFFICE_CONSUMABLES_ACCOUNT_SUCCESS;

  /**
   * Fires a new find office consumables account success action.
   * @param payload The loaded office consumables account.
   */
  constructor(public payload: AppHttpResponse<Account>) {}
}

/**
 * Represents a store find finance charges loans and bank facilities account action.
 */
export class FindFinanceChargesLoansAndBankFacilitiesAccount implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_FINANCE_CHARGES_LOANS_AND_BANK_FACILITIES_ACCOUNT;

  /**
   * Fires a new find finance charges loans and bank facilities account action.
   */
  constructor() {}
}

/**
 * Represents a store find finance charges loans and bank facilities account fail action.
 */
export class FindFinanceChargesLoansAndBankFacilitiesAccountFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_FINANCE_CHARGES_LOANS_AND_BANK_FACILITIES_ACCOUNT_FAIL;

  /**
   * Fires a new find finance charges loans and bank facilities account fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store find finance charges loans and bank facilities account success action.
 */
export class FindFinanceChargesLoansAndBankFacilitiesAccountSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_FINANCE_CHARGES_LOANS_AND_BANK_FACILITIES_ACCOUNT_SUCCESS;

  /**
   * Fires a new find finance charges loans and bank facilities account success action.
   * @param payload The loaded finance charges loans and bank facilities account.
   */
  constructor(public payload: AppHttpResponse<Account>) {}
}

/**
 * Represents a store find finance charges lease contracts account action.
 */
export class FindFinanceChargesLeaseContractsAccount implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_FINANCE_CHARGES_LEASE_CONTRACTS_ACCOUNT;

  /**
   * Fires a new find finance charges lease contracts account action.
   */
  constructor() {}
}

/**
 * Represents a store find finance charges lease contracts account fail action.
 */
export class FindFinanceChargesLeaseContractsAccountFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_FINANCE_CHARGES_LEASE_CONTRACTS_ACCOUNT_FAIL;

  /**
   * Fires a new find finance charges lease contracts account fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store find finance charges lease contracts account success action.
 */
export class FindFinanceChargesLeaseContractsAccountSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_FINANCE_CHARGES_LEASE_CONTRACTS_ACCOUNT_SUCCESS;

  /**
   * Fires a new find finance charges lease contracts account success action.
   * @param payload The loaded finance charges lease contracts account.
   */
  constructor(public payload: AppHttpResponse<Account>) {}
}

/**
 * Represents a store find commissions account action.
 */
export class FindCommissionsAccount implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_COMMISSIONS_ACCOUNT;

  /**
   * Fires a new find commissions account action.
   */
  constructor() {}
}

/**
 * Represents a store find commissions account fail action.
 */
export class FindCommissionsAccountFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_COMMISSIONS_ACCOUNT_FAIL;

  /**
   * Fires a new find commissions account fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store find commissions account success action.
 */
export class FindCommissionsAccountSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_COMMISSIONS_ACCOUNT_SUCCESS;

  /**
   * Fires a new find commissions account success action.
   * @param payload The loaded commissions account.
   */
  constructor(public payload: AppHttpResponse<Account>) {}
}

/**
 * Represents a store find bank expenses account action.
 */
export class FindBankExpensesAccount implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_BANK_EXPENSES_ACCOUNT;

  /**
   * Fires a new find bank expenses account action.
   */
  constructor() {}
}

/**
 * Represents a store find bank expenses account fail action.
 */
export class FindBankExpensesAccountFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_BANK_EXPENSES_ACCOUNT_FAIL;

  /**
   * Fires a new find bank expenses account fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store find bank expenses account success action.
 */
export class FindBankExpensesAccountSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_BANK_EXPENSES_ACCOUNT_SUCCESS;

  /**
   * Fires a new find bank expenses account success action.
   * @param payload The loaded bank expenses account.
   */
  constructor(public payload: AppHttpResponse<Account>) {}
}

/**
 * Represents a store find cost of revenue and general expenses others account action.
 */
export class FindCostOfRevenueAndGeneralExpensesOthersAccount implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_COST_OF_REVENUE_AND_GENERAL_EXPENSES_OTHERS_ACCOUNT;

  /**
   * Fires a new find cost of revenue and general expenses others account action.
   */
  constructor() {}
}

/**
 * Represents a store find cost of revenue and general expenses others account fail action.
 */
export class FindCostOfRevenueAndGeneralExpensesOthersAccountFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_COST_OF_REVENUE_AND_GENERAL_EXPENSES_OTHERS_ACCOUNT_FAIL;

  /**
   * Fires a new find cost of revenue and general expenses others account fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store find cost of revenue and general expenses others account success action.
 */
export class FindCostOfRevenueAndGeneralExpensesOthersAccountSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_COST_OF_REVENUE_AND_GENERAL_EXPENSES_OTHERS_ACCOUNT_SUCCESS;

  /**
   * Fires a new find cost of revenue and general expenses others account success action.
   * @param payload The loaded cost of revenue and general expenses others account.
   */
  constructor(public payload: AppHttpResponse<Account>) {}
}

/**
 * Represents a store find building depreciation express account action.
 */
export class FindBuildingDepreciationExpressAccount implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_BUILDINGS_DEPRECIATION_EXPENSES_ACCOUNT;

  /**
   * Fires a new find building depreciation express account action.
   */
  constructor() {}
}

/**
 * Represents a store find building depreciation express account fail action.
 */
export class FindBuildingDepreciationExpressAccountFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_BUILDINGS_DEPRECIATION_EXPENSES_ACCOUNT_FAIL;

  /**
   * Fires a new find building depreciation express account fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store find building depreciation express account success action.
 */
export class FindBuildingDepreciationExpressAccountSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_BUILDINGS_DEPRECIATION_EXPENSES_ACCOUNT_SUCCESS;

  /**
   * Fires a new find building depreciation express account success action.
   * @param payload The loaded building depreciation express account.
   */
  constructor(public payload: AppHttpResponse<Account>) {}
}

/**
 * Represents a store find furniture depreciation express account action.
 */
export class FindFurnitureDepreciationExpressAccount implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_FURNITURE_DEPRECIATION_EXPENSES_ACCOUNT;

  /**
   * Fires a new find furniture depreciation express account action.
   */
  constructor() {}
}

/**
 * Represents a store find furniture depreciation express account fail action.
 */
export class FindFurnitureDepreciationExpressAccountFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_FURNITURE_DEPRECIATION_EXPENSES_ACCOUNT_FAIL;

  /**
   * Fires a new find furniture depreciation express account fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store find furniture depreciation express account success action.
 */
export class FindFurnitureDepreciationExpressAccountSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_FURNITURE_DEPRECIATION_EXPENSES_ACCOUNT_SUCCESS;

  /**
   * Fires a new find furniture depreciation express account success action.
   * @param payload The loaded furniture depreciation express account.
   */
  constructor(public payload: AppHttpResponse<Account>) {}
}

/**
 * Represents a store find devices depreciation express account action.
 */
export class FindDevicesDepreciationExpressAccount implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_DEVICES_DEPRECIATION_EXPENSES_ACCOUNT;

  /**
   * Fires a new find devices depreciation express account action.
   */
  constructor() {}
}

/**
 * Represents a store find devices depreciation express account fail action.
 */
export class FindDevicesDepreciationExpressAccountFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_DEVICES_DEPRECIATION_EXPENSES_ACCOUNT_FAIL;

  /**
   * Fires a new find devices depreciation express account fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store find devices depreciation express account success action.
 */
export class FindDevicesDepreciationExpressAccountSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_DEVICES_DEPRECIATION_EXPENSES_ACCOUNT_SUCCESS;

  /**
   * Fires a new find devices depreciation express account success action.
   * @param payload The loaded devices depreciation express account.
   */
  constructor(public payload: AppHttpResponse<Account>) {}
}
/**
 * Represents a store find computers depreciation express account action.
 */
export class FindComputersDepreciationExpressAccount implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_COMPUTERS_DEPRECIATION_EXPENSES_ACCOUNT;

  /**
   * Fires a new find computers depreciation express account action.
   */
  constructor() {}
}

/**
 * Represents a store find computers depreciation express account fail action.
 */
export class FindComputersDepreciationExpressAccountFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_COMPUTERS_DEPRECIATION_EXPENSES_ACCOUNT_FAIL;

  /**
   * Fires a new find computers depreciation express account fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store find computers depreciation express account success action.
 */
export class FindComputersDepreciationExpressAccountSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_COMPUTERS_DEPRECIATION_EXPENSES_ACCOUNT_SUCCESS;

  /**
   * Fires a new find computers depreciation express account success action.
   * @param payload The loaded computers depreciation express account.
   */
  constructor(public payload: AppHttpResponse<Account>) {}
}

/**
 * Represents a store find equipments depreciation express account action.
 */
export class FindEquipmentsDepreciationExpressAccount implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_EQUIPMENTS_DEPRECIATION_EXPENSES_ACCOUNT;

  /**
   * Fires a new find equipments depreciation express account action.
   */
  constructor() {}
}

/**
 * Represents a store find equipments depreciation express account fail action.
 */
export class FindEquipmentsDepreciationExpressAccountFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_EQUIPMENTS_DEPRECIATION_EXPENSES_ACCOUNT_FAIL;

  /**
   * Fires a new find equipments depreciation express account fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store find equipments depreciation express account success action.
 */
export class FindEquipmentsDepreciationExpressAccountSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_EQUIPMENTS_DEPRECIATION_EXPENSES_ACCOUNT_SUCCESS;

  /**
   * Fires a new find equipments depreciation express account success action.
   * @param payload The loaded equipments depreciation express account.
   */
  constructor(public payload: AppHttpResponse<Account>) {}
}

/**
 * Represents a store find cars depreciation express account action.
 */
export class FindCarsDepreciationExpressAccount implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_CARS_DEPRECIATION_EXPENSES_ACCOUNT;

  /**
   * Fires a new find cars depreciation express account action.
   */
  constructor() {}
}

/**
 * Represents a store find cars depreciation express account fail action.
 */
export class FindCarsDepreciationExpressAccountFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_CARS_DEPRECIATION_EXPENSES_ACCOUNT_FAIL;

  /**
   * Fires a new find cars depreciation express account fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store find cars depreciation express account success action.
 */
export class FindCarsDepreciationExpressAccountSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_CARS_DEPRECIATION_EXPENSES_ACCOUNT_SUCCESS;

  /**
   * Fires a new find cars depreciation express account success action.
   * @param payload The loaded cars depreciation express account.
   */
  constructor(public payload: AppHttpResponse<Account>) {}
}

/**
 * Represents a store find assets under finance leases depreciation expenses account action.
 */
export class FindAssetsUnderFinanceLeasesDepreciationExpensesAccount implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_ASSETS_UNDER_FINANCE_LEASES_DEPRECIATION_EXPENSES_ACCOUNT;

  /**
   * Fires a new find assets under finance leases depreciation expenses account action.
   */
  constructor() {}
}

/**
 * Represents a store find assets under finance leases depreciation expenses account fail action.
 */
export class FindAssetsUnderFinanceLeasesDepreciationExpensesAccountFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_ASSETS_UNDER_FINANCE_LEASES_DEPRECIATION_EXPENSES_ACCOUNT_FAIL;

  /**
   * Fires a new find assets under finance leases depreciation expenses account fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store find assets under finance leases depreciation expenses account success action.
 */
export class FindAssetsUnderFinanceLeasesDepreciationExpensesAccountSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_ASSETS_UNDER_FINANCE_LEASES_DEPRECIATION_EXPENSES_ACCOUNT_SUCCESS;

  /**
   * Fires a new find assets under finance leases depreciation expenses account success action.
   * @param payload The loaded assets under finance leases depreciation expenses account.
   */
  constructor(public payload: AppHttpResponse<Account>) {}
}

/**
 * Represents a store find fame depreciation expenses account action.
 */
export class FindFameDepreciationExpensesAccount implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_FAME_DEPRECIATION_EXPENSES_ACCOUNT;

  /**
   * Fires a new find fame depreciation expenses account action.
   */
  constructor() {}
}

/**
 * Represents a store find fame depreciation expenses account fail action.
 */
export class FindFameDepreciationExpensesAccountFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_FAME_DEPRECIATION_EXPENSES_ACCOUNT_FAIL;

  /**
   * Fires a new find fame depreciation expenses account fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store find fame depreciation expenses account success action.
 */
export class FindFameDepreciationExpensesAccountSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_FAME_DEPRECIATION_EXPENSES_ACCOUNT_SUCCESS;

  /**
   * Fires a new find fame depreciation expenses account success action.
   * @param payload The loaded fame depreciation expenses account.
   */
  constructor(public payload: AppHttpResponse<Account>) {}
}

/**
 * Represents a store find software and systems depreciation expenses account action.
 */
export class FindSoftwareAndSystemsDepreciationExpensesAccount implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_SOFTWARE_AND_SYSTEMS_DEPRECIATION_EXPENSES_ACCOUNT;

  /**
   * Fires a new find software and systems depreciation expenses account action.
   */
  constructor() {}
}

/**
 * Represents a store find software and systems depreciation expenses account fail action.
 */
export class FindSoftwareAndSystemsDepreciationExpensesAccountFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_SOFTWARE_AND_SYSTEMS_DEPRECIATION_EXPENSES_ACCOUNT_FAIL;

  /**
   * Fires a new find software and systems depreciation expenses account fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store find software and systems depreciation expenses account success action.
 */
export class FindSoftwareAndSystemsDepreciationExpensesAccountSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_SOFTWARE_AND_SYSTEMS_DEPRECIATION_EXPENSES_ACCOUNT_SUCCESS;

  /**
   * Fires a new find software and systems depreciation expenses account success action.
   * @param payload The loaded software and systems depreciation expenses account.
   */
  constructor(public payload: AppHttpResponse<Account>) {}
}

/**
 * Represents a store find patents and trademarks depreciation expenses account action.
 */
export class FindPatentsAndTrademarksDepreciationExpensesAccount implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_PATENTS_AND_TRADEMARKS_DEPRECIATION_EXPENSES_ACCOUNT;

  /**
   * Fires a new find patents and trademarks depreciation expenses account action.
   */
  constructor() {}
}

/**
 * Represents a store find patents and trademarks depreciation expenses account fail action.
 */
export class FindPatentsAndTrademarksDepreciationExpensesAccountFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_PATENTS_AND_TRADEMARKS_DEPRECIATION_EXPENSES_ACCOUNT_FAIL;

  /**
   * Fires a new find patents and trademarks depreciation expenses account fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store find patents and trademarks depreciation expenses account success action.
 */
export class FindPatentsAndTrademarksDepreciationExpensesAccountSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_PATENTS_AND_TRADEMARKS_DEPRECIATION_EXPENSES_ACCOUNT_SUCCESS;

  /**
   * Fires a new find patents and trademarks depreciation expenses account success action.
   * @param payload The loaded patents and trademarks depreciation expenses account.
   */
  constructor(public payload: AppHttpResponse<Account>) {}
}

/**
 * Represents a store find real estate investment buildings depreciation expenses account action.
 */
export class FindRealEstateInvestmentBuildingsDepreciationExpensesAccount implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_REAL_ESTATE_INVESTMENT_BUILDINGS_DEPRECIATION_EXPENSES_ACCOUNT;

  /**
   * Fires a new find real estate investment buildings depreciation expenses account action.
   */
  constructor() {}
}

/**
 * Represents a store find real estate investment buildings depreciation expenses account fail action.
 */
export class FindRealEstateInvestmentBuildingsDepreciationExpensesAccountFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_REAL_ESTATE_INVESTMENT_BUILDINGS_DEPRECIATION_EXPENSES_ACCOUNT_FAIL;

  /**
   * Fires a new find real estate investment buildings depreciation expenses account fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store find real estate investment buildings depreciation expenses account success action.
 */
export class FindRealEstateInvestmentBuildingsDepreciationExpensesAccountSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_REAL_ESTATE_INVESTMENT_BUILDINGS_DEPRECIATION_EXPENSES_ACCOUNT_SUCCESS;

  /**
   * Fires a new find real estate investment buildings depreciation expenses account success action.
   * @param payload The loaded real estate investment buildings depreciation expenses account.
   */
  constructor(public payload: AppHttpResponse<Account>) {}
}

/**
 * Represents a store find assets broker account action.
 */
export class FindAssetsBrokerAccount implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_ASSETS_BROKER_ACCOUNT;

  /**
   * Fires a new find assets broker account action.
   */
  constructor() {}
}

/**
 * Represents a store find assets broker account fail action.
 */
export class FindAssetsBrokerAccountFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_ASSETS_BROKER_ACCOUNT_FAIL;

  /**
   * Fires a new find assets broker account fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store find assets broker account success action.
 */
export class FindAssetsBrokerAccountSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_ASSETS_BROKER_ACCOUNT_SUCCESS;

  /**
   * Fires a new find assets broker account success action.
   * @param payload The loaded assets broker account.
   */
  constructor(public payload: AppHttpResponse<Account>) {}
}

/**
 * Represents a store find currency rounding difference account action.
 */
export class FindCurrencyRoundingDifferenceAccount implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_CURRENCY_ROUNDING_DIFFERENCE_ACCOUNT;

  /**
   * Fires a new find currency rounding difference account action.
   */
  constructor() {}
}

/**
 * Represents a store find currency rounding difference account fail action.
 */
export class FindCurrencyRoundingDifferenceAccountFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_CURRENCY_ROUNDING_DIFFERENCE_ACCOUNT_FAIL;

  /**
   * Fires a new find currency rounding difference account fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store find currency rounding difference account success action.
 */
export class FindCurrencyRoundingDifferenceAccountSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_CURRENCY_ROUNDING_DIFFERENCE_ACCOUNT_SUCCESS;

  /**
   * Fires a new find currency rounding difference account success action.
   * @param payload The loaded currency rounding difference account.
   */
  constructor(public payload: AppHttpResponse<Account>) {}
}

/**
 * Represents a store find gosi accrual account action.
 */
export class FindGosiAccrualAccount implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_GOSI_ACCRUAL_ACCOUNT;

  /**
   * Fires a new find Gosi accrual account action.
   */
  constructor() {}
}

/**
 * Represents a store find gosi accrual account fail action.
 */
export class FindGosiAccrualAccountFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_GOSI_ACCRUAL_ACCOUNT_FAIL;

  /**
   * Fires a new find gosi accrual account fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store find gosi accrual account success action.
 */
export class FindGosiAccrualAccountSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_GOSI_ACCRUAL_ACCOUNT_SUCCESS;

  /**
   * Fires a new find gosi accrual account success action.
   * @param payload The loaded gosi accrual account account.
   */
  constructor(public payload: AppHttpResponse<Account>) {}
}

/**
 * Represents a store find gosi expenses account action.
 */
export class FindGosiExpensesAccount implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_GOSI_EXPENSES_ACCOUNT;

  /**
   * Fires a new find Gosi expenses account action.
   */
  constructor() {}
}

/**
 * Represents a store find gosi expenses account fail action.
 */
export class FindGosiExpensesAccountFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_GOSI_EXPENSES_ACCOUNT_FAIL;

  /**
   * Fires a new find gosi expenses account fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store find gosi expenses account success action.
 */
export class FindGosiExpensesAccountSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = SystemAccountsActionType.FIND_GOSI_EXPENSES_ACCOUNT_SUCCESS;

  /**
   * Fires a new find gosi expenses account success action.
   * @param payload The loaded gosi expenses account account.
   */
  constructor(public payload: AppHttpResponse<Account>) {}
}

/**
 * finances-module system accounts action types.
 */
export type SystemAccountsActions =
  | FindAccountReceivableAccount
  | FindAccountReceivableAccountFail
  | FindAccountReceivableAccountSuccess
  | FindRevenueAccount
  | FindRevenueAccountFail
  | FindRevenueAccountSuccess
  | FindTaxAccount
  | FindTaxAccountFail
  | FindTaxAccountSuccess
  | FindMaterialAccount
  | FindMaterialAccountFail
  | FindMaterialAccountSuccess
  | FindInventoryClearingAccount
  | FindInventoryClearingAccountFail
  | FindInventoryClearingAccountSuccess
  | FindAccountsPayablesClearingAccount
  | FindAccountsPayablesClearingAccountFail
  | FindAccountsPayablesClearingAccountSuccess
  | FindPettyCashClearingAccount
  | FindPettyCashClearingAccountFail
  | FindPettyCashClearingAccountSuccess
  | FindLiabilityAccount
  | FindLiabilityAccountFail
  | FindLiabilityAccountSuccess
  | FindBankAccount
  | FindBankAccountFail
  | FindBankAccountSuccess
  | FindCashAccount
  | FindCashAccountFail
  | FindCashAccountSuccess
  | FindChequesUnderCollectionAccount
  | FindChequesUnderCollectionAccountFail
  | FindChequesUnderCollectionAccountSuccess
  | FindPaymentsUnderCollectionAccount
  | FindPaymentsUnderCollectionAccountFail
  | FindPaymentsUnderCollectionAccountSuccess
  | FindPointOfSaleCashAccount
  | FindPointOfSaleCashAccountFail
  | FindPointOfSaleCashAccountSuccess
  | FindCOGSAccount
  | FindCOGSAccountFail
  | FindCOGSAccountSuccess
  | FindProfitLossAccount
  | FindProfitLossAccountFail
  | FindProfitLossAccountSuccess
  | FindPendingChequeAccount
  | FindPendingChequeAccountFail
  | FindPendingChequeAccountSuccess
  | FindPaymentsUnderPaymentAccount
  | FindPaymentsUnderPaymentAccountFail
  | FindPaymentsUnderPaymentAccountSuccess
  | FindAdvanceExpensesPettyCashAccount
  | FindAdvanceExpensesPettyCashAccountFail
  | FindAdvanceExpensesPettyCashAccountSuccess
  | FindOwnersCurrentAccount
  | FindOwnersCurrentAccountFail
  | FindOwnersCurrentAccountSuccess
  | FindLandsAccount
  | FindLandsAccountFail
  | FindLandsAccountSuccess
  | FindDesktopDevicesAccount
  | FindDesktopDevicesAccountFail
  | FindDesktopDevicesAccountSuccess
  | FindMachinesAndEquipmentsAccount
  | FindMachinesAndEquipmentsAccountFail
  | FindMachinesAndEquipmentsAccountSuccess
  | FindImprovementsToRentedBuildingsAccount
  | FindImprovementsToRentedBuildingsAccountFail
  | FindImprovementsToRentedBuildingsAccountSuccess
  | FindFurnitureAccount
  | FindFurnitureAccountFail
  | FindFurnitureAccountFail
  | FindFurnitureAccountSuccess
  | FindSalariesPayableAccount
  | FindSalariesPayableAccountFail
  | FindSalariesPayableAccountSuccess

  /* &&&&&&&&&&&&&&&&&&&&&&&&&& NEW SYSTEMS ACCOUNTS &&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&& */
  | FindCashInHandAccount
  | FindCashInHandAccountFail
  | FindCashInHandAccountSuccess
  | FindCashInBanksAccount
  | FindCashInBanksAccountFail
  | FindCashInBanksAccountSuccess
  | FindChecksUnderCollectionAccount
  | FindChecksUnderCollectionAccountFail
  | FindChecksUnderCollectionAccountSuccess
  | FindCashInvestmentsLessThan90DaysAccount
  | FindCashInvestmentsLessThan90DaysAccountFail
  | FindCashInvestmentsLessThan90DaysAccountSuccess
  | FindRestrictedCashInBanksAccount
  | FindRestrictedCashInBanksAccountFail
  | FindRestrictedCashInBanksAccountSuccess
  | FindShortTermDepositsAccount
  | FindShortTermDepositsAccountFail
  | FindShortTermDepositsAccountSuccess
  | FindCashInvestmentsForTradingAccount
  | FindCashInvestmentsForTradingAccountFail
  | FindCashInvestmentsForTradingAccountSuccess
  | FindTradeCreditorsAccount
  | FindTradeCreditorsAccountFail
  | FindTradeCreditorsAccountSuccess
  | FindVendorsBrokerAccount
  | FindVendorsBrokerAccountFail
  | FindVendorsBrokerAccountSuccess
  | FindTradeDebtorsAccount
  | FindTradeDebtorsAccountFail
  | FindTradeDebtorsAccountSuccess
  | FindAllowanceForDoubtfulDebtsAccount
  | FindAllowanceForDoubtfulDebtsAccountFail
  | FindAllowanceForDoubtfulDebtsAccountSuccess
  | FindRelatedPartiesAccount
  | FindRelatedPartiesAccountFail
  | FindRelatedPartiesAccountSuccess
  | FindAdvancesOfStaffAccount
  | FindAdvancesOfStaffAccountFail
  | FindAdvancesOfStaffAccountSuccess
  | FindLettersOfGuaranteeAccount
  | FindLettersOfGuaranteeAccountFail
  | FindLettersOfGuaranteeAccountSuccess
  | FindDocumentaryCreditsAccount
  | FindDocumentaryCreditsAccountFail
  | FindDocumentaryCreditsAccountSuccess
  | FindPettyCashAccount
  | FindPettyCashAccountFail
  | FindPettyCashAccountSuccess
  | FindValueAddedTaxCreditAccount
  | FindValueAddedTaxCreditAccountFail
  | FindValueAddedTaxCreditAccountSuccess
  | FindValueAddedTaxDebitAccount
  | FindValueAddedTaxDebitAccountFail
  | FindValueAddedTaxDebitAccountSuccess
  | FindMiscellaneousCreditorsAccount
  | FindMiscellaneousCreditorsAccountFail
  | FindMiscellaneousCreditorsAccountSuccess
  | FindMiscellaneousDebtorsAccount
  | FindMiscellaneousDebtorsAccountFail
  | FindMiscellaneousDebtorsAccountSuccess
  | FindPrepaidExpensesAccount
  | FindPrepaidExpensesAccountFail
  | FindPrepaidExpensesAccountSuccess
  | FindAdvancePaymentsToVendorsAndContractorsAccount
  | FindAdvancePaymentsToVendorsAndContractorsAccountFail
  | FindAdvancePaymentsToVendorsAndContractorsAccountSuccess
  | FindFinancingChargesForBankLoansAndCreditFacilitiesAccount
  | FindFinancingChargesForBankLoansAndCreditFacilitiesAccountFail
  | FindFinancingChargesForBankLoansAndCreditFacilitiesAccountSuccess
  | FindFinancingChargesForLeaseContractsAccount
  | FindFinancingChargesForLeaseContractsAccountFail
  | FindFinancingChargesForLeaseContractsAccountSuccess
  | FindFinancingChargesForBankLoansAndCreditFacilitiesAccount
  | FindFinancingChargesForBankLoansAndCreditFacilitiesAccountFail
  | FindFinancingChargesForBankLoansAndCreditFacilitiesAccountSuccess
  | FindGoodsAndMaterialsStockAccount
  | FindGoodsAndMaterialsStockAccountFail
  | FindGoodsAndMaterialsStockAccountSuccess
  | FindStockInProcessAccount
  | FindStockInProcessAccountFail
  | FindStockInProcessAccountSuccess
  | FindCompletedStockAccount
  | FindCompletedStockAccountFail
  | FindCompletedStockAccountSuccess
  | FindProvisionForDamagedOrObsoleteInventoryAccount
  | FindProvisionForDamagedOrObsoleteInventoryAccountFail
  | FindProvisionForDamagedOrObsoleteInventoryAccountSuccess
  | FindInvestmentsAvailableForSaleOrHeldToMaturityAccount
  | FindInvestmentsAvailableForSaleOrHeldToMaturityAccountFail
  | FindInvestmentsAvailableForSaleOrHeldToMaturityAccountSuccess
  | FindLongTermDepositsAccount
  | FindLongTermDepositsAccountFail
  | FindLongTermDepositsAccountSuccess
  | FindFinanceChargesForLeaseContractsNonCurrentAccount
  | FindFinanceChargesForLeaseContractsNonCurrentAccountFail
  | FindFinanceChargesForLeaseContractsNonCurrentAccountSuccess
  | FindFinancingChargesForLoansAndCreditFacilitiesNonCurrentAccount
  | FindFinancingChargesForLoansAndCreditFacilitiesNonCurrentAccountFail
  | FindFinancingChargesForLoansAndCreditFacilitiesNonCurrentAccountSuccess
  | FindProjectsUnderImplementationAccount
  | FindProjectsUnderImplementationAccountFail
  | FindProjectsUnderImplementationAccountSuccess
  | FindCarsAccount
  | FindCarsAccountFail
  | FindCarsAccountSuccess
  | FindDevicesAccount
  | FindDevicesAccountFail
  | FindDevicesAccountSuccess
  | FindComputersAccount
  | FindComputersAccountFail
  | FindComputersAccountSuccess
  | FindEquipmentsAccount
  | FindEquipmentsAccountFail
  | FindEquipmentsAccountSuccess
  | FindAssetsUnderFinanceLeasesAccount
  | FindAssetsUnderFinanceLeasesAccountFail
  | FindAssetsUnderFinanceLeasesAccountSuccess
  | FindBuildingConsumptionComplexAccount
  | FindBuildingConsumptionComplexAccountFail
  | FindBuildingConsumptionComplexAccountSuccess
  | FindFurnitureConsumptionComplexAccount
  | FindFurnitureConsumptionComplexAccountFail
  | FindFurnitureConsumptionComplexAccountSuccess
  | FindDevicesConsumptionComplexAccount
  | FindDevicesConsumptionComplexAccountFail
  | FindDevicesConsumptionComplexAccountSuccess
  | FindComputersConsumptionComplexAccount
  | FindComputersConsumptionComplexAccountFail
  | FindComputersConsumptionComplexAccountSuccess
  | FindEquipmentsConsumptionComplexAccount
  | FindEquipmentsConsumptionComplexAccountFail
  | FindEquipmentsConsumptionComplexAccountSuccess
  | FindCarsConsumptionComplexAccount
  | FindCarsConsumptionComplexAccountFail
  | FindCarsConsumptionComplexAccountSuccess
  | FindPooledDepreciationOfAssetsUnderFinanceLeaseContractsAccount
  | FindPooledDepreciationOfAssetsUnderFinanceLeaseContractsAccountFail
  | FindPooledDepreciationOfAssetsUnderFinanceLeaseContractsAccountSuccess
  | FindFameAccount
  | FindFameAccountFail
  | FindFameAccountSuccess
  | FindSoftwareAndSystemsAccount
  | FindSoftwareAndSystemsAccountFail
  | FindSoftwareAndSystemsAccountSuccess
  | FindFameExtinguishingComplexAccount
  | FindFameExtinguishingComplexAccountFail
  | FindFameExtinguishingComplexAccountSuccess
  | FindSoftwareAndHardwareExtinguishingComplexAccount
  | FindSoftwareAndHardwareExtinguishingComplexAccountFail
  | FindSoftwareAndHardwareExtinguishingComplexAccountSuccess
  | FindPatentsAndTrademarksAccount
  | FindPatentsAndTrademarksAccountFail
  | FindPatentsAndTrademarksAccountSuccess
  | FindPatentAndTrademarkExtinguishingComplexAccount
  | FindPatentAndTrademarkExtinguishingComplexAccountFail
  | FindPatentAndTrademarkExtinguishingComplexAccountSuccess
  | FindRealEstateInvestmentBuildingConsumptionComplexAccount
  | FindRealEstateInvestmentBuildingConsumptionComplexAccountFail
  | FindRealEstateInvestmentBuildingConsumptionComplexAccountSuccess
  | FindRealEstateInvestmentLandsAccount
  | FindRealEstateInvestmentLandsAccountFail
  | FindRealEstateInvestmentLandsAccountSuccess
  | FindRealEstateInvestmentBuildingsAccount
  | FindRealEstateInvestmentBuildingsAccountFail
  | FindRealEstateInvestmentBuildingsAccountSuccess
  | FindInvestmentsInSubsidiariesAccount
  | FindInvestmentsInSubsidiariesAccountFail
  | FindInvestmentsInSubsidiariesAccountSuccess
  | FindInvestmentsInAssociatesAccount
  | FindInvestmentsInAssociatesAccountFail
  | FindInvestmentsInAssociatesAccountSuccess
  | FindInvestmentsInJointVenturesAccount
  | FindInvestmentsInJointVenturesAccountFail
  | FindInvestmentsInJointVenturesAccountSuccess
  | FindLoanInstallmentsTheCurrentPartAccount
  | FindLoanInstallmentsTheCurrentPartAccountFail
  | FindLoanInstallmentsTheCurrentPartAccountSuccess
  | FindCurrentContractsForRentTheCurrentPartAccount
  | FindCurrentContractsForRentTheCurrentPartAccountFail
  | FindCurrentContractsForRentTheCurrentPartAccountSuccess
  | FindCreditFacilitiesOverdraftAccount
  | FindCreditFacilitiesOverdraftAccountFail
  | FindCreditFacilitiesOverdraftAccountSuccess
  | FindCreditorsWithholdingBalancesAccount
  | FindCreditorsWithholdingBalancesAccountFail
  | FindCreditorsWithholdingBalancesAccountSuccess
  | FindPayableExpensesAccount
  | FindPayableExpensesAccountFail
  | FindPayableExpensesAccountSuccess
  | FindEmployeesEntitlementsSalariesAccount
  | FindEmployeesEntitlementsSalariesAccountFail
  | FindEmployeesEntitlementsSalariesAccountSuccess
  | FindEmployeesEntitlementsBenefitsAccount
  | FindEmployeesEntitlementsBenefitsAccountFail
  | FindEmployeesEntitlementsBenefitsAccountSuccess
  | FindEmployeesEntitlementsTravelingTicketsAccount
  | FindEmployeesEntitlementsTravelingTicketsAccountFail
  | FindEmployeesEntitlementsTravelingTicketsAccountSuccess
  | FindAdvanceRevenueAccount
  | FindAdvanceRevenueAccountFail
  | FindAdvanceRevenueAccountSuccess
  | FindReceivablesStaffAccount
  | FindReceivablesStaffAccountFail
  | FindReceivablesStaffAccountSuccess
  | FindOtherCurrentLiabilitiesRelatedPartiesAccount
  | FindOtherCurrentLiabilitiesRelatedPartiesAccountFail
  | FindOtherCurrentLiabilitiesRelatedPartiesAccountSuccess
  | FindDividendPayableAccount
  | FindDividendPayableAccountFail
  | FindDividendPayableAccountSuccess
  | FindZakatProvisionAccount
  | FindZakatProvisionAccountFail
  | FindZakatProvisionAccountSuccess
  | FindIncomeTaxProvisionAccount
  | FindIncomeTaxProvisionAccountFail
  | FindIncomeTaxProvisionAccountSuccess
  | FindProvisionForWithholdingTaxAccount
  | FindProvisionForWithholdingTaxAccountFail
  | FindProvisionForWithholdingTaxAccountSuccess
  | FindLeaseLiabilitiesNonCurrentPortionAccount
  | FindLeaseLiabilitiesNonCurrentPortionAccountFail
  | FindLeaseLiabilitiesNonCurrentPortionAccountSuccess
  | FindProvisionForEndOfSeverancePayAccount
  | FindProvisionForEndOfSeverancePayAccountFail
  | FindProvisionForEndOfSeverancePayAccountSuccess
  | FindWorkInProgressUnbilledRevenueAccount
  | FindWorkInProgressUnbilledRevenueAccountFail
  | FindWorkInProgressUnbilledRevenueAccountSuccess
  | FindRegularReserveAccount
  | FindRegularReserveAccountFail
  | FindRegularReserveAccountSuccess
  | FindGeneralReserveAccount
  | FindGeneralReserveAccountFail
  | FindGeneralReserveAccountSuccess
  | FindRetainedEarningsAccount
  | FindRetainedEarningsAccountFail
  | FindRetainedEarningsAccountSuccess
  | FindAdditionalCapitalAccount
  | FindAdditionalCapitalAccountFail
  | FindAdditionalCapitalAccountSuccess
  | FindMainActivitiesSalesRevenueAccount
  | FindMainActivitiesSalesRevenueAccountFail
  | FindMainActivitiesSalesRevenueAccountSuccess
  | FindGainLossOnDisposalOfPropertyAccount
  | FindGainLossOnDisposalOfPropertyAccountFail
  | FindGainLossOnDisposalOfPropertyAccountSuccess
  | FindGainLossOnInvestmentValuationAccount
  | FindGainLossOnInvestmentValuationAccountFail
  | FindGainLossOnInvestmentValuationAccountSuccess
  | FindSupportRevenueAccount
  | FindSupportRevenueAccountFail
  | FindSupportRevenueAccountSuccess
  | FindOtherMiscellaneousIncomeAccount
  | FindOtherMiscellaneousIncomeAccountFail
  | FindOtherMiscellaneousIncomeAccountSuccess
  | FindUsedOfMaterialAccount
  | FindUsedOfMaterialAccountFail
  | FindUsedOfMaterialAccountSuccess
  | FindNonInventoryMaterialCostsAccount
  | FindNonInventoryMaterialCostsAccountFail
  | FindNonInventoryMaterialCostsAccountSuccess
  | FindRentAccount
  | FindRentAccountFail
  | FindRentAccountSuccess
  | FindLocalLaborAccount
  | FindLocalLaborAccountFail
  | FindLocalLaborAccountSuccess
  | FindSalariesAndWagesEmployeeProtectionAccount
  | FindSalariesAndWagesEmployeeProtectionAccountFail
  | FindSalariesAndWagesEmployeeProtectionAccountSuccess
  | FindWorkMissionsAndOvertimeAccount
  | FindWorkMissionsAndOvertimeAccountFail
  | FindWorkMissionsAndOvertimeAccountSuccess
  | FindRepairAndMaintenanceAccount
  | FindRepairAndMaintenanceAccountFail
  | FindRepairAndMaintenanceAccountSuccess
  | FindGovernmentFeesAndSubscriptionsAccount
  | FindGovernmentFeesAndSubscriptionsAccountFail
  | FindGovernmentFeesAndSubscriptionsAccountSuccess
  | FindTheCostOfPublicServicesAccount
  | FindTheCostOfPublicServicesAccountFail
  | FindTheCostOfPublicServicesAccountSuccess
  | FindHospitalityAndCleanlinessAccount
  | FindHospitalityAndCleanlinessAccountFail
  | FindHospitalityAndCleanlinessAccountSuccess
  | FindOfficeConsumablesAccount
  | FindOfficeConsumablesAccountFail
  | FindOfficeConsumablesAccountSuccess
  | FindFinanceChargesLoansAndBankFacilitiesAccount
  | FindFinanceChargesLoansAndBankFacilitiesAccountFail
  | FindFinanceChargesLoansAndBankFacilitiesAccountSuccess
  | FindFinanceChargesLeaseContractsAccount
  | FindFinanceChargesLeaseContractsAccountFail
  | FindFinanceChargesLeaseContractsAccountSuccess
  | FindCommissionsAccount
  | FindCommissionsAccountFail
  | FindCommissionsAccountSuccess
  | FindCostOfRevenueAndGeneralExpensesOthersAccount
  | FindCostOfRevenueAndGeneralExpensesOthersAccountFail
  | FindCostOfRevenueAndGeneralExpensesOthersAccountSuccess
  | FindAccruedRevenueAccount
  | FindAccruedRevenueAccountFail
  | FindAccruedRevenueAccountSuccess
  | FindCapitalAccount
  | FindCapitalAccountFail
  | FindCapitalAccountSuccess
  | FindBankExpensesAccount
  | FindBankExpensesAccountFail
  | FindBankExpensesAccountSuccess
  | FindBankLoansAccount
  | FindBankLoansAccountFail
  | FindBankLoansAccountSuccess
  | FindBuildingsAccount
  | FindBuildingsAccountFail
  | FindBuildingsAccountSuccess
  | FindWellExecutedDetentionAccount
  | FindWellExecutedDetentionAccountFail
  | FindWellExecutedDetentionAccountSuccess
  | FindProfitAndLossBrokerAccount
  | FindProfitAndLossBrokerAccountFail
  | FindProfitAndLossBrokerAccountSuccess
  | FindAssetBrokerageAccount
  | FindAssetBrokerageAccountFail
  | FindAssetBrokerageAccountSuccess
  | FindBenefitToEmployeesAllCostsExceptWageProtectionElementsAccount
  | FindBenefitToEmployeesAllCostsExceptWageProtectionElementsAccountFail
  | FindBenefitToEmployeesAllCostsExceptWageProtectionElementsAccountSuccess
  | FindBuildingDepreciationExpressAccount
  | FindBuildingDepreciationExpressAccountFail
  | FindBuildingDepreciationExpressAccountSuccess
  | FindFurnitureDepreciationExpressAccount
  | FindFurnitureDepreciationExpressAccountFail
  | FindFurnitureDepreciationExpressAccountSuccess
  | FindAssetsUnderFinanceLeasesDepreciationExpensesAccount
  | FindAssetsUnderFinanceLeasesDepreciationExpensesAccountFail
  | FindAssetsUnderFinanceLeasesDepreciationExpensesAccountSuccess
  | FindPatentsAndTrademarksDepreciationExpensesAccount
  | FindPatentsAndTrademarksDepreciationExpensesAccountFail
  | FindPatentsAndTrademarksDepreciationExpensesAccountSuccess
  | FindDevicesDepreciationExpressAccount
  | FindDevicesDepreciationExpressAccountFail
  | FindDevicesDepreciationExpressAccountSuccess
  | FindComputersDepreciationExpressAccount
  | FindComputersDepreciationExpressAccountFail
  | FindComputersDepreciationExpressAccountSuccess
  | FindEquipmentsDepreciationExpressAccount
  | FindEquipmentsDepreciationExpressAccountFail
  | FindEquipmentsDepreciationExpressAccountSuccess
  | FindCarsDepreciationExpressAccount
  | FindCarsDepreciationExpressAccountFail
  | FindCarsDepreciationExpressAccountSuccess
  | FindFameDepreciationExpensesAccount
  | FindFameDepreciationExpensesAccountFail
  | FindFameDepreciationExpensesAccountSuccess
  | FindSoftwareAndSystemsDepreciationExpensesAccount
  | FindSoftwareAndSystemsDepreciationExpensesAccountFail
  | FindSoftwareAndSystemsDepreciationExpensesAccountSuccess
  | FindRealEstateInvestmentBuildingsDepreciationExpensesAccount
  | FindRealEstateInvestmentBuildingsDepreciationExpensesAccountFail
  | FindRealEstateInvestmentBuildingsDepreciationExpensesAccountSuccess
  | FindAssetsBrokerAccount
  | FindAssetsBrokerAccountFail
  | FindAssetsBrokerAccountSuccess
  | FindCurrencyRoundingDifferenceAccount
  | FindCurrencyRoundingDifferenceAccountFail
  | FindCurrencyRoundingDifferenceAccountSuccess
  | FindGosiAccrualAccount
  | FindGosiAccrualAccountFail
  | FindGosiAccrualAccountSuccess
  | FindGosiExpensesAccount
  | FindGosiExpensesAccountFail
  | FindGosiExpensesAccountSuccess;
