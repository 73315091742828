import { Injectable, SecurityContext } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import * as printJS from 'print-js';
import { environment } from '../../../environments/environment';

/**
 * The print services includes functionality to print.
 */
@Injectable()
export class PrintService {
  /**
   * The relative route for the sales reports.
   * The relative route for the hr reports.
   * The relative route for the purchase reports.
   * The relative route for the stores reports.
   * The relative route for the finance reports.
   */
  private salesReportsApi = 'reporting/sales';
  private purchasesReportsApi = 'reporting/purchases';

  private storesReportsApi = 'reporting/stores';
  private financeReportsApi = 'reporting/finances';

  private crmReportsApi = 'reporting/crm';

  constructor(private http: HttpClient, private sanitizer: DomSanitizer) {}

  /**
   * The method that print invoices from the api.
   */
  printHtml(html: string) {
    printJS({
      printable: html,
      type: 'raw-html',
      css: [
        `${environment.reportingServiceApiUrl}/assets/css/main.css`,
        'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css',
        'https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css',
      ],
    });
  }

  /**
   * Prints the PDF file from the given url.
   * @param pdfUrl The url of the PDF file.
   */
  printPDF(pdfUrl: string, onError?: (error, request: XMLHttpRequest) => void): void {
    printJS({
      printable: `${pdfUrl}?printable=true`,
      type: 'pdf',
      onError,
    });
  }

  /**
   * Gets the sale invoice matching the provided query id.
   * @param id The id of the sale invoice.
   */
  public printSaleInvoiceById(id: number, printPageSize: string): Observable<SafeHtml> {
    const params = new HttpParams().set('printPageSize', printPageSize);
    return this.http.get(`${this.salesReportsApi}/sale-invoice-print/${id}`, { responseType: 'text', params }).pipe(
      // map((value) => this.sanitizer.sanitize(SecurityContext.HTML, this.sanitizer.bypassSecurityTrustHtml(value))),
      tap((html) => {
        this.printHtml(html as string);
      })
    );
  }

  /**
   * Gets the quotation matching the provided query id.
   * @param id The id of the quotation.
   */
  public printQuotationById(id: number, printPageSize: string): Observable<SafeHtml> {
    const params = new HttpParams().set('printPageSize', printPageSize);
    return this.http.get(`${this.crmReportsApi}/quotation-print/${id}`, { responseType: 'text', params }).pipe(
      tap((html) => {
        this.printHtml(html as string);
      })
    );
  }

  /**
   * Gets the order delivery matching the provided query id.
   * @param id The id of the order.
   */
  public printOrderDeliveryById(id: number): Observable<SafeHtml> {
    return this.http
      .get(`${this.salesReportsApi}/pending-pos-invoice-print-for-kitchen/${id}`, { responseType: 'text' })
      .pipe(
        map((value) => this.sanitizer.sanitize(SecurityContext.HTML, this.sanitizer.bypassSecurityTrustHtml(value))),
        tap((html) => {
          this.printHtml(html as string);
        })
      );
  }

  /**
   * Gets the pending pos invoice matching the provided query id.
   * @param id The id of the sale invoice.
   * @param products The product  ids of the sale invoice.
   */
  public printPendingPosInvoiceById(id: number, products: number[]): Observable<SafeHtml> {
    const params = new HttpParams().set('products', products.join(','));
    return this.http
      .get(`${this.salesReportsApi}/pending-pos-invoice-print-for-sub-kitchen/${id}`, {
        responseType: 'text',
        params,
      })
      .pipe(
        map((value) => this.sanitizer.sanitize(SecurityContext.HTML, this.sanitizer.bypassSecurityTrustHtml(value))),
        tap((html) => {
          this.printHtml(html as string);
        })
      );
  }

  /**
   * Gets the point of sale invoice matching the provided query id.
   * @param id The id of the point of sale invoice.
   */
  public printPosInvoiceById(id: number, printPageSize: string): Observable<SafeHtml> {
    const params = new HttpParams().set('printPageSize', printPageSize);
    return this.http.get(`${this.salesReportsApi}/sale-invoice-print/${id}`, { responseType: 'text', params }).pipe(
      map((value) => this.sanitizer.sanitize(SecurityContext.HTML, this.sanitizer.bypassSecurityTrustHtml(value))),
      tap((html) => {
        this.printHtml(html as string);
      })
    );
  }

  /**
   * Gets the purchase invoice matching the provided query id.
   * @param id The id of the purchase invoice.
   * @param printPageSize The print page size of the purchase invoice.
   * @param displayInForeignCurrency The display in foreign currency of the purchase invoice.
   */
  public printPurchaseInvoiceById(
    id: number,
    printPageSize: string,
    displayInForeignCurrency: boolean
  ): Observable<SafeHtml> {
    const params = new HttpParams()
      .set('printPageSize', printPageSize)
      .set('displayInForeignCurrency', displayInForeignCurrency.toString());

    return this.http
      .get(`${this.purchasesReportsApi}/purchase-invoice-print/${id}`, { responseType: 'text', params })
      .pipe(
        map((value) => this.sanitizer.sanitize(SecurityContext.HTML, this.sanitizer.bypassSecurityTrustHtml(value))),
        tap((html) => {
          this.printHtml(html as string);
        })
      );
  }

  /**
   * Gets the purchase order matching the provided query id.
   * @param id The id of the purchase order.
   * @param printPageSize The print page size of the purchase order.
   * @param displayInForeignCurrency The display in foreign currency of the purchase order.
   */
  public printPurchaseOrderById(
    id: number,
    printPageSize: string,
    displayInForeignCurrency: boolean
  ): Observable<SafeHtml> {
    const params = new HttpParams()
      .set('printPageSize', printPageSize)
      .set('displayInForeignCurrency', displayInForeignCurrency.toString());
    return this.http
      .get(`${this.purchasesReportsApi}/purchase-order-print/${id}`, { responseType: 'text', params })
      .pipe(
        map((value) => this.sanitizer.sanitize(SecurityContext.HTML, this.sanitizer.bypassSecurityTrustHtml(value))),
        tap((html) => {
          this.printHtml(html as string);
        })
      );
  }

  /**
   * Gets the purchase request matching the provided query id.
   * @param id The id of the purchase request.
   */
  public printPurchaseRequestById(id: number, printPageSize: string): Observable<SafeHtml> {
    const params = new HttpParams().set('printPageSize', printPageSize);

    return this.http
      .get(`${this.purchasesReportsApi}/purchase-request-print/${id}`, { responseType: 'text', params })
      .pipe(
        map((value) => this.sanitizer.sanitize(SecurityContext.HTML, this.sanitizer.bypassSecurityTrustHtml(value))),
        tap((html) => {
          this.printHtml(html as string);
        })
      );
  }

  /**
   * Gets the purchase return matching the provided query id.
   * @param id The id of the purchase return.
   */
  public printPurchaseReturnById(id: number, printPageSize: string): Observable<SafeHtml> {
    const params = new HttpParams().set('printPageSize', printPageSize);
    return this.http
      .get(`${this.purchasesReportsApi}/purchase-return-print/${id}`, { responseType: 'text', params })
      .pipe(
        map((value) => this.sanitizer.sanitize(SecurityContext.HTML, this.sanitizer.bypassSecurityTrustHtml(value))),
        tap((html) => {
          this.printHtml(html as string);
        })
      );
  }

  /**
   * Gets the sale return matching the provided query id.
   * @param id The id of the sale return.
   */
  public printSaleReturnById(id: number, printPageSize: string): Observable<SafeHtml> {
    const params = new HttpParams().set('printPageSize', printPageSize);
    return this.http.get(`${this.salesReportsApi}/sale-return-print/${id}`, { responseType: 'text', params }).pipe(
      map((value) => this.sanitizer.sanitize(SecurityContext.HTML, this.sanitizer.bypassSecurityTrustHtml(value))),
      tap((html) => {
        this.printHtml(html as string);
      })
    );
  }

  /**
   * Gets the incoming stock matching the provided query id.
   * @param id The id of the incoming stock.
   */
  public printIncomingStockById(id: number, printPageSize: string): Observable<SafeHtml> {
    const params = new HttpParams().set('printPageSize', printPageSize);
    return this.http.get(`${this.storesReportsApi}/incoming-stock-print/${id}`, { responseType: 'text', params }).pipe(
      map((value) => this.sanitizer.sanitize(SecurityContext.HTML, this.sanitizer.bypassSecurityTrustHtml(value))),
      tap((html) => {
        this.printHtml(html as string);
      })
    );
  }

  /**
   * Gets the outgoing stock matching the provided query id.
   * @param id The id of the outgoing stock.
   */
  public printOutGoingStockById(id: number, printPageSize: string): Observable<SafeHtml> {
    const params = new HttpParams().set('printPageSize', printPageSize);
    return this.http.get(`${this.storesReportsApi}/outgoing-stock-print/${id}`, { responseType: 'text', params }).pipe(
      map((value) => this.sanitizer.sanitize(SecurityContext.HTML, this.sanitizer.bypassSecurityTrustHtml(value))),
      tap((html) => {
        this.printHtml(html as string);
      })
    );
  }

  /**
   * Gets the transfer stock matching the provided query id.
   * @param id The id of the transfer stock.
   */
  public printTransferStockById(id: number, printPageSize: string): Observable<SafeHtml> {
    const params = new HttpParams().set('printPageSize', printPageSize);
    return this.http.get(`${this.storesReportsApi}/transfer-stock-print/${id}`, { responseType: 'text', params }).pipe(
      map((value) => this.sanitizer.sanitize(SecurityContext.HTML, this.sanitizer.bypassSecurityTrustHtml(value))),
      tap((html) => {
        this.printHtml(html as string);
      })
    );
  }

  /**
   * Gets the journal matching the provided query id.
   * @param id The id of the journal.
   */
  public printJournalById(id: number, printPageSize: string): Observable<SafeHtml> {
    const params = new HttpParams().set('printPageSize', printPageSize);
    return this.http.get(`${this.financeReportsApi}/journal-print/${id}`, { responseType: 'text', params }).pipe(
      tap((html) => {
        this.printHtml(html as string);
      })
    );
  }
}
