import { Component, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { APP_CONSTANTS } from 'shared';
import { Variant } from 'stores/models';

@Component({
  selector: '[app-sub-products-form-item]',
  templateUrl: './sub-products-form-item.component.html',
  styles: [],
})
export class SubProductsFormItemComponent {
  /**
   * Sets the sub-product index in the sub-products-form.
   */
  @Input() index: number;

  /**
   * Sets the sub-product form-group.
   */
  @Input() formGroup: FormGroup;

  /**
   * Emits a value when the user wants to remove current sub-product from the list.
   */
  @Output() removeChange = new EventEmitter<any>();

  /**
   * The decimal mask.
   */
  readonly DECIMAL_MASK = APP_CONSTANTS.numeric.decimal.mask;

  /**
   * The list of variants.
   */
  variants: Variant[] = [];

  /**
   * Shows or hide the variants list.
   */
  variantsListVisibility = false;

  /**
   * Gets the list of variants that are used in the ng-select.
   */
  get variantsList(): Variant[] {
    return !this.formGroup?.value.variantsList?.length ? this.variants : this.formGroup?.value.variantsList;
  }

  /**
   * @param modalService The modal service.
   */
  constructor(private modalService: NgbModal) {}

  /**
   * Remove the sub-product from the list.
   */
  remove() {
    this.removeChange.emit(null);
  }

  /**
   * Adds the newly selected variants the variants list.
   * @param variants The list of newly selected variants to be added.
   */
  selectVariants(variants: Variant[]) {
    const selectedIds: number[] = this.formGroup.get('subProductVariants').value;
    this.variants.push(...(this.formGroup.value.variantsList ?? []));

    /**
     * Check for duplicates and make sure each variant is selected only once.
     */
    variants.forEach((variant) => {
      if (!selectedIds.includes(variant.id)) {
        this.variants.push(variant);
        selectedIds.push(variant.id);
      }
    });

    this.formGroup.patchValue({ variantsList: [] });
    this.formGroup.patchValue({ subProductVariants: this.variants.map((variant) => variant.id) });
  }

  /**
   * Opens the modal of the given templateRef.
   * @param modalRef The modal templateRef to be opened.
   */
  openModal(modalRef) {
    this.modalService.open(modalRef);
  }
}
