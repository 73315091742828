import { StoresState } from 'stores/store/states';
import { ActionReducerMap } from '@ngrx/store';
import { productsReducer } from 'stores/store/reducers/products.reducer';
import { locationsReducer } from 'stores/store/reducers/locations.reducer';
import { productClassesReducer } from 'stores/store/reducers/product-classes.reducer';
import { unitOfMeasureClassesReducer } from 'stores/store/reducers/unit-of-measure-classes.reducer';
import { unitsOfMeasureReducer } from 'stores/store/reducers/units-of-measure.reducer';
import { variantsReducer } from 'stores/store/reducers/variants.reducer';
import { regionsReducer } from 'stores/store/reducers/regions.reducer';
import { sectorsReducer } from 'stores/store/reducers/sectors.reducer';

/**
 * The Stores-Module store reducers.
 */
export const reducers: ActionReducerMap<StoresState> = {
  products: productsReducer,
  locations: locationsReducer,
  productClasses: productClassesReducer,
  unitOfMeasureClasses: unitOfMeasureClassesReducer,
  unitsOfMeasure: unitsOfMeasureReducer,
  variants: variantsReducer,
  regions: regionsReducer,
  sectors: sectorsReducer,
};
