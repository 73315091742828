import { Injectable } from '@angular/core';

import { Effect, ofType, Actions } from '@ngrx/effects';
import { switchMap, map, catchError, tap } from 'rxjs/operators';
import { of } from 'rxjs';

import { NotificationService, StatusCode, PAGINATION, TranslationService } from 'shared';
import { SaleInvoicesService } from 'sales/services';
import {
  SaleInvoicesActionType,
  SearchSaleInvoices,
  SearchSaleInvoicesFail,
  SearchSaleInvoicesSuccess,
  CreateRegularSaleInvoice,
  CreateSaleInvoiceSuccess,
  CreateSaleInvoiceFail,
  FindSaleInvoice,
  FindSaleInvoiceSuccess,
  FindSaleInvoiceFail,
  SearchUnpaidSaleInvoices,
  SearchUnpaidSaleInvoicesSuccess,
  SearchUnpaidSaleInvoicesFail,
  CreatePointOfSaleInvoice,
  CreateOpeningBalanceInvoice,
  CreateMaintenanceInvoice,
  ChangeSaleInvoiceMaintenanceStage,
  ChangeSaleInvoiceMaintenanceStageSuccess,
  ChangeSaleInvoiceMaintenanceStageFail,
} from 'sales/store/actions';

@Injectable()
export class SalesInvoicesEffects {
  constructor(
    private actions$: Actions,
    private salesInvoicesService: SaleInvoicesService,
    private notificationService: NotificationService,
    private translationService: TranslationService
  ) {}

  /* ========================= SEARCH_SALE_INVOICES =================================== */
  @Effect()
  search$ = this.actions$.pipe(
    ofType(SaleInvoicesActionType.SEARCH_SALE_INVOICES),
    switchMap(({ payload }: SearchSaleInvoices) => {
      return this.salesInvoicesService
        .search(
          payload.invoiceNum,
          payload.orderNum,
          payload.customers,
          payload.locations,
          payload.saleInvoiceTypes,
          payload.saleInvoiceOrderTypes,
          payload.fromDate,
          payload.toDate,
          payload.page,
          PAGINATION.SaleInvoices
        )
        .pipe(
          map((response) => new SearchSaleInvoicesSuccess(response)),
          catchError((error) => of(new SearchSaleInvoicesFail(error)))
        );
    })
  );

  /* ========================= SEARCH_UNPAID_SALE_INVOICES =================================== */
  @Effect()
  searchUnpaid$ = this.actions$.pipe(
    ofType(SaleInvoicesActionType.SEARCH_UNPAID_SALE_INVOICES),
    switchMap(({ payload }: SearchUnpaidSaleInvoices) => {
      return this.salesInvoicesService
        .searchUnpaid(payload.invoiceNum, payload.locations, payload.customers, payload.page, PAGINATION.SaleInvoices)
        .pipe(
          map((response) => new SearchUnpaidSaleInvoicesSuccess(response)),
          catchError((error) => of(new SearchUnpaidSaleInvoicesFail(error)))
        );
    })
  );

  /* ========================= FIND_SALE_INVOICE =================================== */
  @Effect()
  find$ = this.actions$.pipe(
    ofType(SaleInvoicesActionType.FIND_SALE_INVOICE),
    switchMap((action: FindSaleInvoice) =>
      this.salesInvoicesService.findById(action.payload).pipe(
        map((response) => new FindSaleInvoiceSuccess(response)),
        catchError((error) => of(new FindSaleInvoiceFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  findFail$ = this.actions$.pipe(
    ofType(SaleInvoicesActionType.FIND_SALE_INVOICE_FAIL),
    tap((action: FindSaleInvoiceFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(this.translationService.translate('SALES.INVOICES.SALE_INVOICE_NOT_FOUND'));
      }
    })
  );

  /* ========================= CREATE_SALE_INVOICE =================================== */
  @Effect()
  create$ = this.actions$.pipe(
    ofType(SaleInvoicesActionType.CREATE_REGULAR_SALE_INVOICE),
    switchMap((action: CreateRegularSaleInvoice) =>
      this.salesInvoicesService.createRegular(action.payload).pipe(
        map((response) => new CreateSaleInvoiceSuccess(response)),
        catchError((error) => of(new CreateSaleInvoiceFail(error)))
      )
    )
  );

  @Effect()
  createOpeningBalance$ = this.actions$.pipe(
    ofType(SaleInvoicesActionType.CREATE_OPENING_BALANCE_INVOICE),
    switchMap((action: CreateOpeningBalanceInvoice) =>
      this.salesInvoicesService.createOpeningBalance(action.payload).pipe(
        map((response) => new CreateSaleInvoiceSuccess(response)),
        catchError((error) => of(new CreateSaleInvoiceFail(error)))
      )
    )
  );

  @Effect()
  createMaintenanceInvoice$ = this.actions$.pipe(
    ofType(SaleInvoicesActionType.CREATE_MAINTENANCE_INVOICE),
    switchMap((action: CreateMaintenanceInvoice) =>
      this.salesInvoicesService.createMaintenanceInvoice(action.payload).pipe(
        map((response) => new CreateSaleInvoiceSuccess(response)),
        catchError((error) => of(new CreateSaleInvoiceFail(error)))
      )
    )
  );

  @Effect()
  createPointOfSale$ = this.actions$.pipe(
    ofType(SaleInvoicesActionType.CREATE_POS_INVOICE),
    switchMap((action: CreatePointOfSaleInvoice) =>
      this.salesInvoicesService.createFromPOS(action.payload).pipe(
        map((response) => new CreateSaleInvoiceSuccess(response)),
        catchError((error) => of(new CreateSaleInvoiceFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  createSuccess$ = this.actions$.pipe(
    ofType(SaleInvoicesActionType.CREATE_SALE_INVOICE_SUCCESS),
    tap((action: CreateSaleInvoiceSuccess) => {
      this.notificationService.success(
        action.payload.data.orderNum
          ? this.translationService.translate('SALES.INVOICES.POS_INVOICE_ADDED', {
              orderNumber: action.payload.data.orderNum,
            })
          : this.translationService.translate('SALES.INVOICES.SALE_INVOICE_ADDED', {
              invoiceNumber: action.payload.data.invoiceNum,
            })
      );
    })
  );

  @Effect({ dispatch: false })
  createFail$ = this.actions$.pipe(
    ofType(SaleInvoicesActionType.CREATE_SALE_INVOICE_FAIL),
    tap((action: CreateSaleInvoiceFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );

  /* ========================= CHANGE_SALE_INVOICE_MAINTENANCE_STAGE =================================== */
  @Effect()
  changeMaintenanceStage$ = this.actions$.pipe(
    ofType(SaleInvoicesActionType.CHANGE_SALE_INVOICE_MAINTENANCE_STAGE),
    switchMap((action: ChangeSaleInvoiceMaintenanceStage) =>
      this.salesInvoicesService.changeMaintenanceStage(action.payload.id, action.payload.stage).pipe(
        map((response) => new ChangeSaleInvoiceMaintenanceStageSuccess(response)),
        catchError((error) => of(new ChangeSaleInvoiceMaintenanceStageFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  changeMaintenanceStageSuccess$ = this.actions$.pipe(
    ofType(SaleInvoicesActionType.CHANGE_SALE_INVOICE_MAINTENANCE_STAGE_SUCCESS),
    tap((action: ChangeSaleInvoiceMaintenanceStageSuccess) => {
      this.notificationService.success(
        this.translationService.translate('SALES.INVOICES.SALE_INVOICE_MAINTENANCE_STAGE_CHANGED')
      );
    })
  );

  @Effect({ dispatch: false })
  changeMaintenanceStageFail$ = this.actions$.pipe(
    ofType(SaleInvoicesActionType.CHANGE_SALE_INVOICE_MAINTENANCE_STAGE_FAIL),
    tap((action: ChangeSaleInvoiceMaintenanceStageFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(this.translationService.translate('SALES.INVOICES.SALE_INVOICE_NOT_FOUND'));
      } else if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );
}
