import { Injectable } from '@angular/core';

import { switchMap, map, catchError, debounceTime } from 'rxjs/operators';
import { of } from 'rxjs';
import { Effect, ofType, Actions } from '@ngrx/effects';

import { PAGINATION } from 'shared';
import { BankAccountsService } from 'app-search/services';
import {
  BankAccountsActionType,
  SearchBankAccounts,
  SearchBankAccountsFail,
  SearchBankAccountsSuccess,
} from 'app-search/store/actions';

@Injectable()
export class BankAccountsEffects {
  constructor(private actions$: Actions, private bankAccountsService: BankAccountsService) {}

  /* ========================= SEARCH_BANK_ACCOUNTS =================================== */
  @Effect()
  search$ = this.actions$.pipe(
    ofType(BankAccountsActionType.SEARCH_BANK_ACCOUNTS),
    debounceTime(300),
    switchMap(({ payload }: SearchBankAccounts) =>
      this.bankAccountsService.search(payload?.name ?? '', payload.banks, payload.page, PAGINATION.BankAccounts).pipe(
        map((response) => new SearchBankAccountsSuccess(response)),
        catchError((error) => of(new SearchBankAccountsFail(error)))
      )
    )
  );
}
