import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';

import { Observable, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

import * as fromStoresStore from 'stores/store';
import * as fromAuthStore from 'auth/store';
import { NotificationService, PaginationInfo, TranslationService } from 'shared';
import { Product, Stock, ProductStock, ProductClass } from 'stores/models';
import { Location } from 'stores/models';
import { Claims } from 'security/models';

/**
 * The view outgoing stock component pages.
 */
enum PAGES {
  products = 'products',
  'products-stock' = 'products-stock',
}

/**
 * A Products search form wrapped in modal.
 */
@Component({
  selector: 'app-products-search',
  templateUrl: './products-search.component.html',
  styles: [],
})
export class ProductsSearchComponent implements OnInit, OnDestroy {
  /**
   * The modal template reference.
   */
  @ViewChild('modalRef') modalRef: ElementRef<any>;

  /**
   * Indicates whether the modal will automatically close after select the first product.
   *
   * Be default it will not be closed.
   */
  @Input() closeOnSelect = false;

  /**
   * Indicates whether the products displayed on the list should be all active.
   *
   * Be default it will not be closed.
   */
  @Input() onlyActive = false;

  /**
   * Indicates whether the product purchase price will be visible to the user or not.
   */
  @Input() showPurchasePrice = false;

  /**
   * Indicates whether the product sale price will be visible to the user or not.
   */
  @Input() showSalePrice = false;

  /**
   * Indicates whether the product stock will be visible to the user or not.
   */
  @Input() showProductStockQuery = false;

  /**
   * Sets the visibility of this component modal.
   *
   * Handles visibility change in two-data-binding approach.
   */
  @Input() set visible(isShown: boolean) {
    if (isShown && this.modalRef) {
      this.openModal(this.modalRef);
    } else {
      this.closeModal();
    }

    this.visibleChange.emit(isShown);
  }

  /**
   * Identifies the type of the products that will appear in the search result.
   */
  @Input() set searchForStorableProducts(isActive: boolean) {
    if (isActive) {
      this.searchForm?.patchValue({
        searchForStorableProducts: true,
        searchForServiceProducts: false,
        searchForAllProductTypes: false,
      });
      this.search();
    }
  }

  /**
   * Identifies the type of the products that will appear in the search result.
   */
  @Input() set searchForServiceProducts(isActive: boolean) {
    if (isActive) {
      this.searchForm?.patchValue({
        searchForStorableProducts: false,
        searchForServiceProducts: true,
        searchForAllProductTypes: false,
      });
      this.search();
    }
  }

  /**
   * Identifies the type of the products that will appear in the search result.
   */
  @Input() set searchForAllProductTypes(isActive: boolean) {
    if (isActive) {
      this.searchForm?.patchValue({
        searchForStorableProducts: false,
        searchForServiceProducts: false,
        searchForAllProductTypes: true,
      });
      this.search();
    }
  }

  /**
   * Outputs a value that indicates whether the modal is shown or not.
   *
   * Handles visibility change in two-data-binding approach.
   */
  @Output() visibleChange = new EventEmitter<boolean>();

  /** Outputs the selected products. */
  @Output() products = new EventEmitter<Product[]>();

  /**
   * Shows or hide the create product modal.
   */
  createProductModalVisibility = false;

  /**
   * Shows or hide the locations list.
   */
  locationsListVisibility = false;

  /**
   * The list of selected locations.
   */
  locations: Location[] = [];

  /**
   * The system supported user claims.
   */
  Claims = Claims;

  /**
   * Shows or hide the product classes list.
   */
  classesListVisibility = false;

  /**
   * The id of the product.
   */
  productId: number;

  /**
   * The list of active product classes.
   */
  classes: ProductClass[] = [];

  /**
   * The list of products.
   */
  products$: Observable<Product[]>;

  /**
   * The list of products.
   */
  productsList: Product[];

  /**
   * The list of products stock.
   */
  productStock$: Observable<ProductStock>;

  /**
   * The pagination info.
   */
  paginationInfo$: Observable<PaginationInfo>;

  /**
   * Indicates whether there is a search process is running or not.
   */
  isSearching$: Observable<boolean>;

  /**
   * The search form.
   */
  searchForm: FormGroup;

  /**
   * The get stock form.
   */
  getStockForm: FormGroup;

  /**
   * The ngb-modal reference to use it when modal needed to be closed.
   */
  modal: NgbModalRef;

  /**
   * Gets or sets the selected page.
   * @default 'products'
   */
  activePage: PAGES = PAGES.products;

  /**
   * The set of subscriptions on this components,
   * these subscriptions must be unsubscribed before this component got destroyed.
   */
  subscriptions = new Subscription();

  /**
   * @param modalService The modal service.
   * @param notificationService The notification service.
   * @param translationService The translation service.
   * @param storesStore$ The stores-store module.
   * @param authStore$ The auth-store module.
   */
  constructor(
    private modalService: NgbModal,
    private notificationService: NotificationService,
    private translationService: TranslationService,
    private storesStore$: Store<fromStoresStore.StoresState>,
    private authStore$: Store<fromAuthStore.AuthState>
  ) {}

  ngOnInit(): void {
    this.init();
  }

  /**
   * Destroy component data
   */
  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  /**
   * Initialize component data.
   */
  init() {
    /**
     * Load data.
     */
    this.initForm();
    this.initGetStockForm();

    this.isSearching$ = this.storesStore$.pipe(select(fromStoresStore.getProductsSearching));

    let isManualSearchTriggeredBeforeForProducts = false;
    this.products$ = this.storesStore$.pipe(
      select(fromStoresStore.getProducts),
      tap((products) => {
        if (products) {
          this.productsList = products;
        }
        if (!isManualSearchTriggeredBeforeForProducts && !products.length) {
          isManualSearchTriggeredBeforeForProducts = true;
          this.search();
        }
      })
    );

    /* Subscribe to selected location to select it whenever it changed. */
    this.subscriptions.add(
      this.authStore$
        .pipe(
          select(fromAuthStore.getSessionUserDefaultLocation),
          tap((location) => {
            if (location && !this.searchForm.value.locations.includes(location.id)) {
              this.selectLocations([location]);
            }
          })
        )
        .subscribe()
    );

    this.productStock$ = this.storesStore$.pipe(select(fromStoresStore.getSelectedProductStock));
    this.paginationInfo$ = this.storesStore$.pipe(select(fromStoresStore.getProductsPaginationInfo));
  }

  /**
   * Initialize form and add validators.
   */
  initForm() {
    this.searchForm = new FormGroup({
      description: new FormControl(''),
      classes: new FormControl([]),
      locations: new FormControl([]),
      searchForStorableProducts: new FormControl(false),
      searchForServiceProducts: new FormControl(false),
      searchForAllProductTypes: new FormControl(false),
      page: new FormControl(1),
    });
  }

  /**
   * Initialize form and add validators.
   */
  initGetStockForm() {
    this.getStockForm = new FormGroup({
      productId: new FormControl(''),
    });
  }

  /**
   * Handles search parameters change.
   */
  search(event?: KeyboardEvent) {
    if (event && event.key === 'Enter') {
      event.preventDefault();
      return;
    }

    this.applyFiltersAndSearch();
  }

  /**
   * Handles pagination page-changed event.
   * @param page The current selected page number.
   */
  pageChanged(page: number) {
    /** Update pagination page. */
    this.searchForm.patchValue({ page });

    /** Get selected page items. */
    this.applyFiltersAndSearch(page);
  }

  /**
   * Applies the search filters and dispatches a search action.
   * @param page The current page number, default to `1`.
   */
  applyFiltersAndSearch(page: number = 1) {
    if (!this.searchForm) {
      return;
    }

    const {
      description,
      classes,
      locations,
      searchForStorableProducts,
      searchForServiceProducts,
      searchForAllProductTypes,
    } = this.searchForm.value;

    this.storesStore$.dispatch(
      new fromStoresStore.SearchProducts({
        description: description.trim(),
        classes,
        locations,
        searchForStorableProducts,
        searchForServiceProducts,
        searchForAllProductTypes,
        page,
      })
    );
  }

  /**
   * Enables trackBy feature for *ngFor utility to track items
   * depending on a comparer to reduce DOM change.
   * If the item is already exist then no HTML will change, only bounded values.
   * @param index The index of the item.
   * @param product The item to determine if it was changed or not.
   */
  trackItems(index: number, product: Product) {
    return product ? product.id : undefined;
  }

  /**
   * Opens the modal of the given templateRef.
   * @param modalRef The modal templateRef to be opened.
   */
  openModal(modalRef) {
    /**
     * Open the modal & listen for user press ESC key.
     */
    this.modal = this.modalService.open(modalRef, { size: 'xl' });
    this.modal.result.then(null, () => this.closeModal());
  }

  /**
   * Closes the currently opened modal.
   */
  closeModal() {
    this.modal?.dismiss();
    this.products.emit([]);
    this.visibleChange.emit(false);
  }

  /**
   * Calculates the total quantity of the product's stocks.
   * @param stocks The stocks of the product.
   */
  getProductTotalStock(stocks: Stock[]): number {
    return stocks.map((stock) => stock.quantity).reduce((a, b) => a + b, 0);
  }

  /**
   * Handles get parameters change.
   */
  getStock(event?: KeyboardEvent) {
    if (event && event.key === 'Enter') {
      event.preventDefault();
      return;
    } else if (!this.getStockForm.get('productId').value) {
      return;
    }

    this.storesStore$.dispatch(
      new fromStoresStore.GetAllProductsStockByProductId({
        productId: this.getStockForm.get('productId').value,
      })
    );
  }

  /**
   * Calculates the total value of the product's stocks.
   * @param stocks The stocks of the product.
   */
  getProductTotalStockValue(stocks: Stock[]): number {
    return stocks.map((stock) => stock.total).reduce((a, b) => a + b, 0);
  }

  /**
   * Adds the newly selected locations the product classes search list.
   * @param classes The list of newly selected classes to be added.
   */
  selectClasses(classes: ProductClass[]) {
    const selectedIds: number[] = this.searchForm.get('classes').value;
    this.classes = [...this.classes.filter((productClass) => selectedIds.includes(productClass.id)), ...classes];
    this.searchForm.patchValue({ classes: this.classes.map((productClass) => productClass.id) });
  }

  /**
   * Handles product selected event.
   * @param products The list of products to notify selected.
   */
  selectProduct(products: Product[]) {
    this.notificationService.successInstant(
      this.translationService.translate('STORES.PRODUCTS.NOTIFICATION_MESSAGE.PRODUCT_SELECTED')
    );
    this.products.emit(products);
    if (this.closeOnSelect) {
      this.closeModal();
    }
  }

  /**
   * Adds the newly selected locations the locations search list.
   * @param locations The list of newly selected locations to be added.
   */
  selectLocations(locations: Location[]) {
    const selectedIds: number[] = this.searchForm.get('locations').value;
    this.locations = [...this.locations.filter((location) => selectedIds.includes(location.id)), ...locations];
    this.searchForm.patchValue({ locations: this.locations.map((location) => location.id) });
  }
}
