import { SubTasksActionType, SubTasksActions } from 'app-search/store/actions';
import { SubTasksState } from 'app-search/store/states';

/**
 * The initial state from which the state starts.
 */
const initialState: SubTasksState = {
  data: [],
  paginationInfo: {
    total: 0,
    page: 1,
    pageSize: 0,
    count: 0,
  },
  error: null,
  isSearching: false,
  isSearchCompleted: false,
};

/**
 * The reducer function that is called in each action dispatch against the state.
 * @param state The current state.
 * @param action The action that will affect the state.
 */
export function subTasksReducer(state: SubTasksState = initialState, action: SubTasksActions): SubTasksState {
  switch (action.type) {
    //#region SEARCH_SUB_TASKS

    case SubTasksActionType.SEARCH_SUB_TASKS: {
      return {
        ...state,
        isSearching: true,
        isSearchCompleted: false,
        error: null,
      };
    }

    case SubTasksActionType.SEARCH_SUB_TASKS_FAIL: {
      return {
        ...state,
        error: action.payload,
        isSearching: false,
        isSearchCompleted: false,
      };
    }

    case SubTasksActionType.SEARCH_SUB_TASKS_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        paginationInfo: {
          ...action.payload.meta,
        },
        error: null,
        isSearching: false,
        isSearchCompleted: true,
      };
    }

    // #end region SEARCH_SUB_TASKS

    default:
      return state;
  }
}
