import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';

import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

import { NotificationService, PaginationInfo, TranslationService } from 'shared';
import * as fromAppSearchStore from 'app-search/store';
import { Bank } from 'finances/models';

@Component({
  selector: 'banks-search',
  templateUrl: './banks-search.component.html',
  styles: [],
})
export class BanksSearchComponent implements OnInit {
  /**
   * Indicates whether the modal will automatically close after select the first bank.
   *
   * Be default it will not be closed.
   */
  @Input() closeOnSelect = false;

  /**
   * Indicates whether the banks displayed on the list should be all active.
   *
   * Be default it will not be closed.
   */
  @Input() onlyActive = false;

  /**
   * Sets the visibility of this component modal.
   *
   * Handles visibility change in two-data-binding approach.
   */
  @Input() set visible(isShown: boolean) {
    if (isShown && this.modalRef) {
      this.openModal(this.modalRef);
    } else {
      this.closeModal();
    }

    this.visibleChange.emit(isShown);
  }

  /**
   * Outputs a value that indicates whether the modal is shown or not.
   *
   * Handles visibility change in two-data-binding approach.
   */
  @Output() visibleChange = new EventEmitter<boolean>();

  /** Outputs the selected banks. */
  @Output() banks = new EventEmitter<Bank[]>();

  /**
   * The modal template reference.
   */
  @ViewChild('modalRef') modalRef: ElementRef<any>;

  /**
   * Indicates whether there is a search process is running or not.
   */
  isSearching$: Observable<boolean>;

  /**
   * The list of banks.
   */
  banks$: Observable<Bank[]>;

  /**
   * The list of banks.
   */
  banksList: Bank[];

  /**
   * The pagination info.
   */
  paginationInfo$: Observable<PaginationInfo>;

  /**
   * The search form.
   */
  searchForm: FormGroup;

  /**
   * The ngb-modal reference to use it when modal needed to be closed.
   */
  modal: NgbModalRef;

  /**
   *
   * @param modalService The modal service.
   * @param notificationService The notification service.
   * @param appSearchStore$ The app search store.
   * @param translationService The translation service.
   */
  constructor(
    private modalService: NgbModal,
    private notificationService: NotificationService,
    private appSearchStore$: Store<fromAppSearchStore.AppSearchState>,
    private translationService: TranslationService
  ) {}

  ngOnInit(): void {
    this.init();
  }

  /**
   * Initialize component data.
   */
  init() {
    this.initForm();

    /**
     * Load data.
     */
    let isManualSearchTriggeredBeforeBanks = false;
    this.banks$ = this.appSearchStore$.pipe(
      select(fromAppSearchStore.getBanks),
      tap((banks) => {
        if (banks) {
          this.banksList = banks;
        }
        if (!isManualSearchTriggeredBeforeBanks && !banks.length) {
          isManualSearchTriggeredBeforeBanks = true;
          this.search();
        }
      })
    );
    this.isSearching$ = this.appSearchStore$.pipe(select(fromAppSearchStore.getBanksSearching));
    this.paginationInfo$ = this.appSearchStore$.pipe(select(fromAppSearchStore.getBanksPaginationInfo));
  }

  /**
   * Initialize form and add validators.
   */
  initForm() {
    this.searchForm = new FormGroup({
      name: new FormControl(''),
      page: new FormControl(1),
    });
  }

  /**
   * Handles search parameters change.
   */
  search(event?: KeyboardEvent) {
    if (event && event.key === 'Enter') {
      event.preventDefault();
      return;
    }

    this.applyFiltersAndSearch();
  }

  /**
   * Handles pagination page-changed event.
   * @param page The current selected page number.
   */
  pageChanged(page: number) {
    /** Update pagination page. */
    this.searchForm.patchValue({ page });

    this.applyFiltersAndSearch(page);
  }

  /**
   * Enables trackBy feature for *ngFor utility to track items
   * depending on a comparer to reduce DOM change.
   * If the item is already exist then no HTML will change, only bounded values.
   * @param item The item to determine if it was changed or not.
   */
  trackItems(item: Bank) {
    return item ? item.id : undefined;
  }

  /**
   * Handles bank selected event.
   * @param banks The list of banks to notify selected.
   */
  selectBank(banks: Bank[]) {
    this.notificationService.successInstant(
      this.translationService.translate('FINANCES.BANKS.NOTIFICATION_MESSAGE.BANK_SELECTED')
    );
    this.banks.emit(banks);
    if (this.closeOnSelect) {
      this.closeModal();
    }
  }

  applyFiltersAndSearch(page: number = 1) {
    const { name } = this.searchForm.value;

    this.appSearchStore$.dispatch(
      new fromAppSearchStore.SearchBanks({
        name,
        page,
      })
    );
  }

  /**
   * Opens the modal of the given templateRef.
   * @param modalRef The modal templateRef to be opened.
   */
  openModal(modalRef) {
    /**
     * Open the modal & listen for user press ESC key.
     */
    this.modal = this.modalService.open(modalRef, { size: 'lg' });
    this.modal.result.then(null, () => this.closeModal());
  }

  /**
   * Closes the currently opened modal.
   */
  closeModal() {
    this.modal?.dismiss();
    this.banks.emit([]);
    this.visibleChange.emit(false);
  }
}
