import { Component, ViewChild, ElementRef, Input, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import { Observable, Subscription } from 'rxjs';
import { skip, tap } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import * as fromStoresStore from 'stores/store';
import { ProductUnitOfMeasureRateItem, Location } from 'stores/models';
import { APP_CONSTANTS, CustomValidators, NotificationMessage, NotificationService, TranslationService } from 'shared';
import { UnitOfMeasure } from 'stores/models';

@Component({
  selector: 'app-update-product-unit-of-measure-rate',
  templateUrl: './update-product-unit-of-measure-rate.component.html',
  styles: [],
})
export class UpdateProductUnitOfMeasureRatesComponent implements OnInit, OnDestroy {
  /**
   * The update modal template reference.
   */
  @ViewChild('updateModalRef') updateModalRef: ElementRef<any>;

  /**
   * The delete confirm modal template reference.
   */
  @ViewChild('deleteConfirmModalRef') deleteConfirmModalRef: ElementRef<any>;

  /**
   * The decimal mask.
   */
  readonly DECIMAL_MASK = APP_CONSTANTS.numeric.decimal.mask;

  /**
   * Sets the product id.
   */
  @Input() productId: number;

  /**
   * Sets the currently edited product unit of measure rates.
   */
  @Input() productUnitOfMeasureRates: ProductUnitOfMeasureRateItem[];

  /**
   * Shows or hide the unit of measure list in search.
   */
  unitsOfMeasureListVisibility = false;

  /**
   * Gets or sets the id of the current selected product unit of measure rate.
   */
  selectedProductUnitOfMeasureRateId: number;

  /**
   * The list of unit of measure.
   */
  unitsOfMeasure: UnitOfMeasure[] = [];

  /**
   * Indicates whether there is an update product unit of measure rates process is running or not.
   */
  isUpdating$: Observable<boolean>;

  /**
   * Indicates whether there is a delete product unit of measure rate process is running or not.
   */
  isDeleting$: Observable<boolean>;

  /**
   * The update product unit of measure rates form.
   */
  updateForm: FormGroup;

  /**
   * The set of subscriptions on this components,
   * these subscriptions must be unsubscribed before this component got destroyed.
   */
  subscriptions = new Subscription();

  /**
   * @param modalService The modal service.
   * @param storesStore$ The stores-store.
   * @param translationService The translation service.
   * @param notificationService The notification service.
   */
  constructor(
    private modalService: NgbModal,
    private storesStore$: Store<fromStoresStore.StoresState>,
    private translationService: TranslationService,
    private notificationService: NotificationService
  ) {}

  ngOnInit(): void {
    this.init();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  /**
   * Initialize component data.
   */
  init() {
    /** Initialize form. */
    this.initForm();

    /**
     * Closes modal when product unit of measure rate is updated.
     */
    this.subscriptions.add(
      this.storesStore$
        .pipe(
          select(fromStoresStore.getSelectedProductUnitOfMeasureRateUpdateCompleted),
          skip(1),
          tap((isUpdated) => {
            if (isUpdated) {
              this.closeModal();
            }
          })
        )
        .subscribe()
    );

    /**
     * Set data.
     */
    this.isUpdating$ = this.storesStore$.pipe(select(fromStoresStore.getSelectedProductUnitOfMeasureRateUpdating));
  }

  /**
   * Initialize form and add validators.
   */
  initForm() {
    this.updateForm = new FormGroup({
      productId: new FormControl(null),
      unitOfMeasureId: new FormControl(null, [Validators.required]),
      rate: new FormControl('', [Validators.required, CustomValidators.gt(0)]),
    });
  }

  /**
   * Opens the update modal.
   */
  update() {
    this.initForm();
    this.openModal(this.updateModalRef);
  }

  /**
   * Submits the form.
   */
  submit() {
    const errorMessage = new NotificationMessage();

    if (this.updateForm.invalid) {
      if (this.updateForm.get('unitOfMeasureId').invalid) {
        errorMessage.title = this.translationService.translate(
          'STORES.PRODUCTS.PRODUCT_DATA_VALIDATION.FROM_UNIT_OF_MEASURE_RATE_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate('STORES.PRODUCTS.PRODUCT_DATA_VALIDATION.UNIT_OF_MEASURE_RATE_IS_REQUIRED'),
        ];
      } else if (this.updateForm.get('rate').invalid) {
        errorMessage.title = this.translationService.translate('STORES.PRODUCTS.PRODUCT_DATA_VALIDATION.RATE_ERROR');
        errorMessage.body = [
          this.translationService.translate('STORES.PRODUCTS.PRODUCT_DATA_VALIDATION.RATE_IS_REQUIRED'),
        ];
      }
      this.updateForm.markAllAsTouched();
      return this.notificationService.warningWithTitle(errorMessage);
    }
    this.confirmUpdate();
  }

  /**
   * Updates a new list of product unit of measure rates only if the filled data passed validations.
   */
  confirmUpdate() {
    this.storesStore$.dispatch(
      new fromStoresStore.UpdateProductUnitOfMeasureRate({
        productId: this.productId,
        unitOfMeasureId: this.updateForm.value.unitOfMeasureId,
        rate: this.updateForm.value.rate,
      })
    );
  }

  /**
   * Deletes the file for the given it.
   * @param id The id of the file to be deleted.
   */
  deleteProductUnitOfMeasureRate(id: number) {
    this.selectedProductUnitOfMeasureRateId = id;

    this.openModal(this.deleteConfirmModalRef);
  }

  /**
   * Deletes the current selected file.
   */
  deleteConfirm() {
    this.storesStore$.dispatch(
      new fromStoresStore.DeleteProductUnitOfMeasureRate(this.selectedProductUnitOfMeasureRateId)
    );
  }

  /**
   * Opens the modal of the given templateRef.
   * @param modalRef The modal templateRef to be opened.
   */
  openModal(modalRef) {
    this.modalService.open(modalRef);
  }

  /**
   * Closes the currently opened modal.
   */
  closeModal() {
    this.modalService.dismissAll();
  }

  /**
   * Adds the newly selected unit of measure the unit of measure search list.
   * @param unit of measure The list of newly selected unit of measure to be added.
   */
  selectUnitOfMeasure([unitOfMeasure]: UnitOfMeasure[]) {
    if (unitOfMeasure) {
      this.unitsOfMeasure = [unitOfMeasure];
      this.updateForm.patchValue({ unitOfMeasureId: unitOfMeasure.id });
    }
  }
}
