import { StatisticsChartData, StatisticsData } from 'statistics/models';

/**
 * Represents the statistics state.
 */
export class StatisticsState {
  /**
   * The statistics data.
   */
  public data: StatisticsData;

  /**
   * The statistics charts data.
   */
  public statisticsCharts: StatisticsChartData[];

  /**
   * An error that may resulting during processing some actions.
   */
  public error: any;

  /**
   * Determines if there is a running load process.
   */
  public isLoading: boolean;

  /**
   * Determines if the last load process has been ended successfully.
   */
  public isLoadCompleted: boolean;

  /**
   * Determines if there is a running load charts process.
   */
  public isChartsLoading: boolean;

  /**
   * Determines if the last load charts process has been ended successfully.
   */
  public isChartsLoadCompleted: boolean;
}
