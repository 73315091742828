import { ProductsService } from 'stores/services/products.service';
import { LocationsService } from 'stores/services/locations.service';
import { ProductClassesService } from 'stores/services/product-classes.service';
import { UnitOfMeasureClassesService } from 'stores/services/unit-of-measure-classes.service';
import { UnitsOfMeasureService } from 'stores/services/units-of-measure.service';
import { VariantsService } from 'stores/services/variants.service';
import { RegionsService } from 'stores/services/regions.service';
import { SectorsService } from 'stores/services/sectors.service';

export * from 'stores/services/products.service';
export * from 'stores/services/locations.service';
export * from 'stores/services/product-classes.service';
export * from 'stores/services/unit-of-measure-classes.service';
export * from 'stores/services/units-of-measure.service';
export * from 'stores/services/variants.service';
export * from 'stores/services/regions.service';
export * from 'stores/services/sectors.service';

/**
 * The set of services registered on this module.
 */
export const SERVICES: any[] = [
  ProductsService,
  LocationsService,
  ProductClassesService,
  UnitOfMeasureClassesService,
  UnitsOfMeasureService,
  VariantsService,
  RegionsService,
  SectorsService,
];
