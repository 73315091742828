import { Action } from '@ngrx/store';

import { CustomerEngagement, UpdateCustomerEngagementInput, CreateCustomerEngagementInput } from 'sales/models';
import { AppHttpResponse } from 'shared';

/**
 * The Sales-Module store customerEngagements action types.
 */
export enum CustomerEngagementsActionType {
  SEARCH_CUSTOMER_ENGAGEMENTS = '[Sales] [CustomerEngagements] Search Customer Engagements',
  SEARCH_CUSTOMER_ENGAGEMENTS_FAIL = '[Sales] [CustomerEngagements] Search Customer Engagements Fail',
  SEARCH_CUSTOMER_ENGAGEMENTS_SUCCESS = '[Sales] [CustomerEngagements] Search Customer Engagements Success',

  FIND_CUSTOMER_ENGAGEMENT = '[Sales] [CustomerEngagements] Find Customer Engagement',
  FIND_CUSTOMER_ENGAGEMENT_FAIL = '[Sales] [CustomerEngagements] Find Customer Engagement Fail',
  FIND_CUSTOMER_ENGAGEMENT_SUCCESS = '[Sales] [CustomerEngagements] Find Customer Engagement Success',

  CREATE_CUSTOMER_ENGAGEMENT = '[Sales] [CustomerEngagements] Create Customer Engagement',
  CREATE_CUSTOMER_ENGAGEMENT_FAIL = '[Sales] [CustomerEngagements] Create Customer Engagement Fail',
  CREATE_CUSTOMER_ENGAGEMENT_SUCCESS = '[Sales] [CustomerEngagements] Create Customer Engagement Success',

  UPDATE_CUSTOMER_ENGAGEMENT = '[Sales] [CustomerEngagements] Update Customer Engagement',
  UPDATE_CUSTOMER_ENGAGEMENT_FAIL = '[Sales] [CustomerEngagements] Update Customer Engagement Fail',
  UPDATE_CUSTOMER_ENGAGEMENT_SUCCESS = '[Sales] [CustomerEngagements] Update Customer Engagement Success',

  DELETE_CUSTOMER_ENGAGEMENT = '[Sales] [CustomerEngagements] Delete Customer Engagement',
  DELETE_CUSTOMER_ENGAGEMENT_FAIL = '[Sales] [CustomerEngagements] Delete Customer Engagement Fail',
  DELETE_CUSTOMER_ENGAGEMENT_SUCCESS = '[Sales] [CustomerEngagements] Delete Customer Engagement Success',
}

/**
 * Represents a store customer engagements search action.
 */
export class SearchCustomerEngagements implements Action {
  /**
   * The type of the action.
   */
  readonly type = CustomerEngagementsActionType.SEARCH_CUSTOMER_ENGAGEMENTS;

  /**
   * Fires a new customer engagements search action.
   * @param payload the search parameters,
   * if omitted, all customer engagements will be loaded.
   */
  constructor(
    public payload?: {
      customers: number[];
      customerEngagementTypes: number[];
      page: number;
    }
  ) {}
}

/**
 * Represents a store customer engagements search-fail action.
 */
export class SearchCustomerEngagementsFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = CustomerEngagementsActionType.SEARCH_CUSTOMER_ENGAGEMENTS_FAIL;

  /**
   * Fires a new customer engagements search-fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store search-success action.
 */
export class SearchCustomerEngagementsSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = CustomerEngagementsActionType.SEARCH_CUSTOMER_ENGAGEMENTS_SUCCESS;

  /**
   * Fires a new customer engagements search-success action.
   * @param payload An object contains the list of customer engagements that matches the search criteria
   * in addition to the pagination info.
   */
  constructor(public payload: AppHttpResponse<CustomerEngagement[]>) {}
}

/**
 * Represents a store find-customerEngagement action.
 */
export class FindCustomerEngagement implements Action {
  /**
   * The type of the action.
   */
  readonly type = CustomerEngagementsActionType.FIND_CUSTOMER_ENGAGEMENT;

  /**
   * Fires a new find customer engagement action.
   * @param payload the id of the customerEngagement.
   */
  constructor(public payload: number) {}
}

/**
 * Represents a store find-customer engagement-fail action.
 */
export class FindCustomerEngagementFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = CustomerEngagementsActionType.FIND_CUSTOMER_ENGAGEMENT_FAIL;

  /**
   * Fires a new find-customer engagement-fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store find customer engagement-success action.
 */
export class FindCustomerEngagementSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = CustomerEngagementsActionType.FIND_CUSTOMER_ENGAGEMENT_SUCCESS;

  /**
   * Fires a new find-customer engagement-success action.
   * @param payload The loaded customerE engagement.
   */
  constructor(public payload: AppHttpResponse<CustomerEngagement>) {}
}

/**
 * Represents a store customer engagement create action.
 */
export class CreateCustomerEngagement implements Action {
  /** The type of the action. */
  readonly type = CustomerEngagementsActionType.CREATE_CUSTOMER_ENGAGEMENT;

  /**
   * Fires a new customer engagement create action.
   * @param payload the new customer engagement data-model.
   */
  constructor(public payload: CreateCustomerEngagementInput) {}
}

/**
 * Represents a store customer engagement create-fail action.
 */
export class CreateCustomerEngagementFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = CustomerEngagementsActionType.CREATE_CUSTOMER_ENGAGEMENT_FAIL;

  /**
   * Fires a new customer engagement create-fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store customer engagement create-success action.
 */
export class CreateCustomerEngagementSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = CustomerEngagementsActionType.CREATE_CUSTOMER_ENGAGEMENT_SUCCESS;

  /**
   * Fires a new customer engagement create-success action.
   * @param payload The created customerEngagement.
   */
  constructor(public payload: AppHttpResponse<CustomerEngagement>) {}
}

/**
 * Represents a store customer engagement delete action.
 */
export class DeleteCustomerEngagement implements Action {
  /**
   * The type of the action.
   */
  readonly type = CustomerEngagementsActionType.DELETE_CUSTOMER_ENGAGEMENT;

  /**
   * Fires a new customer engagement delete action.
   * @param payload The customer engagement's id.
   */
  constructor(public payload: number) {}
}

/**
 * Represents a store customer engagement delete-fail action.
 */
export class DeleteCustomerEngagementFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = CustomerEngagementsActionType.DELETE_CUSTOMER_ENGAGEMENT_FAIL;

  /**
   * Fires a new customer engagement delete-fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store customer engagement delete-success action.
 */
export class DeleteCustomerEngagementSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = CustomerEngagementsActionType.DELETE_CUSTOMER_ENGAGEMENT_SUCCESS;

  /**
   * Fires a new customer engagement delete-success action.
   * @param payload The deleted customer engagement.
   */
  constructor(public payload: AppHttpResponse<CustomerEngagement>) {}
}

/**
 * Represents a store customer engagement update action.
 */
export class UpdateCustomerEngagement implements Action {
  /**
   * The type of the action.
   */
  readonly type = CustomerEngagementsActionType.UPDATE_CUSTOMER_ENGAGEMENT;

  /**
   * Fires a new customer engagement update action.
   * @param payload The customer engagement's updated data.
   */
  constructor(public payload: UpdateCustomerEngagementInput) {}
}

/**
 * Represents a store customer engagement update-fail action.
 */
export class UpdateCustomerEngagementFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = CustomerEngagementsActionType.UPDATE_CUSTOMER_ENGAGEMENT_FAIL;

  /**
   * Fires a new customer engagement update-fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store customer engagement update-success action.
 */
export class UpdateCustomerEngagementSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = CustomerEngagementsActionType.UPDATE_CUSTOMER_ENGAGEMENT_SUCCESS;

  /**
   * Fires a new customer engagement update-success action.
   * @param payload The updated customerEngagement.
   */
  constructor(public payload: AppHttpResponse<CustomerEngagement>) {}
}

/**
 * Sales-module customer engagements action types.
 */
export type CustomerEngagementsActions =
  | SearchCustomerEngagements
  | SearchCustomerEngagementsFail
  | SearchCustomerEngagementsSuccess
  | FindCustomerEngagement
  | FindCustomerEngagementFail
  | FindCustomerEngagementSuccess
  | CreateCustomerEngagement
  | CreateCustomerEngagementFail
  | CreateCustomerEngagementSuccess
  | UpdateCustomerEngagement
  | UpdateCustomerEngagementFail
  | UpdateCustomerEngagementSuccess
  | DeleteCustomerEngagement
  | DeleteCustomerEngagementFail
  | DeleteCustomerEngagementSuccess;
