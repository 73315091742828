import { createSelector } from '@ngrx/store';

import { SalesState, CustomersState } from 'sales/store/states';
import { selectSalesState } from 'sales/store/selectors/feature.selectors';

/**
 * Gets the customers state.
 */
const selectCustomersState = createSelector(selectSalesState, (state: SalesState) => state.customers);

/**
 * Gets the list of loaded customers.
 */
export const getCustomers = createSelector(selectCustomersState, (state: CustomersState) => state.data);

/**
 * Gets the list of loaded customer logs.
 */
export const getCustomerLogs = createSelector(selectCustomersState, (state: CustomersState) => state.customerLogsData);

/**
 * Gets the list of loaded customers payments.
 */
export const getCustomersPayments = createSelector(
  selectCustomersState,
  (state: CustomersState) => state.customersPaymentsData
);

/**
 * Gets the customers pagination info.
 */
export const getCustomersPaginationInfo = createSelector(
  selectCustomersState,
  (state: CustomersState) => state.paginationInfo
);

/**
 * Gets the customer logs pagination info.
 */
export const getCustomerLogsPaginationInfo = createSelector(
  selectCustomersState,
  (state: CustomersState) => state.customerLogsPaginationInfo
);

/**
 * Gets the customers payments pagination info.
 */
export const getCustomersPaymentsPaginationInfo = createSelector(
  selectCustomersState,
  (state: CustomersState) => state.customersPaymentsPaginationInfo
);

/**
 * Gets the list of loaded customer advance payments.
 */
export const getCustomerAdvancePayments = createSelector(
  selectCustomersState,
  (state: CustomersState) => state.customerAdvancePaymentsData
);
/**
 * Gets the customers payments pagination info.
 */
export const getCustomerAdvancePaymentsPaginationInfo = createSelector(
  selectCustomersState,
  (state: CustomersState) => state.customerAdvancePaymentsPaginationInfo
);

/**
 * Gets the error that occurred in the state.
 */
export const getCustomersError = createSelector(selectCustomersState, (state: CustomersState) => state.error);

/**
 * Determines if there is a running search process.
 */
export const getCustomersSearching = createSelector(selectCustomersState, (state: CustomersState) => state.isSearching);

/**
 * Determines if the last search process has been ended successfully.
 */
export const getCustomersSearchCompleted = createSelector(
  selectCustomersState,
  (state: CustomersState) => state.isSearchCompleted
);

/**
 * Determines if there is a running search-customer-logs process.
 */
export const getCustomerLogsSearching = createSelector(
  selectCustomersState,
  (state: CustomersState) => state.isSearchingCustomerLogs
);

/**
 * Determines if the last search-customer-logs process has been ended successfully.
 */
export const getCustomerLogsSearchCompleted = createSelector(
  selectCustomersState,
  (state: CustomersState) => state.isSearchCustomerLogsCompleted
);

/**
 * Determines if there is a running search-customers-payments process.
 */
export const getCustomersPaymentsSearching = createSelector(
  selectCustomersState,
  (state: CustomersState) => state.isSearchingCustomersPayments
);

/**
 * Determines if the last search-customers-payments process has been ended successfully.
 */
export const getCustomersPaymentsSearchCompleted = createSelector(
  selectCustomersState,
  (state: CustomersState) => state.isSearchCustomersPaymentsCompleted
);

/**
 * Determines if there is a running search-customer-advance-payments process.
 */
export const getCustomerAdvancePaymentsSearching = createSelector(
  selectCustomersState,
  (state: CustomersState) => state.isSearchingCustomerAdvancePayments
);

/**
 * Determines if the last search-customer-advance-payments process has been ended successfully.
 */
export const getCustomerAdvancePaymentsSearchCompleted = createSelector(
  selectCustomersState,
  (state: CustomersState) => state.isSearchCustomerAdvancePaymentsCompleted
);

/**
 * Gets the selected customer.
 */
export const getSelectedCustomer = createSelector(
  selectCustomersState,
  (state: CustomersState) => state.selectedCustomer
);

/**
 * Gets the selected customer log.
 */
export const getSelectedCustomerLog = createSelector(
  selectCustomersState,
  (state: CustomersState) => state.selectedCustomerLog
);

/**
 * Determines if there is a running find-customer process.
 */
export const getCustomersFinding = createSelector(selectCustomersState, (state: CustomersState) => state.isFinding);

/**
 * Determines if the last find-customer process has been ended successfully.
 */
export const getCustomersFindCompleted = createSelector(
  selectCustomersState,
  (state: CustomersState) => state.isFindCompleted
);

/**
 * Determines if there is a running create process.
 */
export const getSelectedCustomerCreating = createSelector(
  selectCustomersState,
  (state: CustomersState) => state.isCreating
);

/**
 * Determines if the last create process has been ended successfully.
 */
export const getSelectedCustomerCreateCompleted = createSelector(
  selectCustomersState,
  (state: CustomersState) => state.isCreateCompleted
);

/**
 * Determines if there is a running create customer payment process.
 */
export const getCustomerPaymentsCreating = createSelector(
  selectCustomersState,
  (state: CustomersState) => state.isCreatingPayments
);

/**
 * Determines if the last create customer payment process has been ended successfully.
 */
export const getCustomerPaymentsCreateCompleted = createSelector(
  selectCustomersState,
  (state: CustomersState) => state.isCreatePaymentsCompleted
);

/**
 * Determines if there is a running create customer advance payment process.
 */
export const getCustomerAdvancePaymentCreating = createSelector(
  selectCustomersState,
  (state: CustomersState) => state.isCreatingAdvancePayment
);

/**
 * Determines if the last create customer advance payment process has been ended successfully.
 */
export const getCustomerAdvancePaymentCreateCompleted = createSelector(
  selectCustomersState,
  (state: CustomersState) => state.isCreateAdvancePaymentCompleted
);

/**
 * Determines if there is a running create customer advance payment process.
 */
export const getCustomerPaymentByAdvancePaymentCreating = createSelector(
  selectCustomersState,
  (state: CustomersState) => state.isCreatingPaymentByAdvancePayment
);

/**
 * Determines if the last create customer advance payment process has been ended successfully.
 */
export const getCustomerPaymentByAdvancePaymentCreateCompleted = createSelector(
  selectCustomersState,
  (state: CustomersState) => state.isCreatePaymentByAdvancePaymentCompleted
);

/**
 * Determines if there is a running create customer refund process.
 */
export const getCustomerRefundCreating = createSelector(
  selectCustomersState,
  (state: CustomersState) => state.isRefundCreating
);

/**
 * Determines if the last create customer refund process has been ended successfully.
 */
export const getCustomerRefundCreateCompleted = createSelector(
  selectCustomersState,
  (state: CustomersState) => state.isCreateRefundCompleted
);

/**
 * Determines if there is a running update process.
 */
export const getSelectedCustomerUpdating = createSelector(
  selectCustomersState,
  (state: CustomersState) => state.isUpdating
);

/**
 * Determines if the last update process has been ended successfully.
 */
export const getSelectedCustomerUpdateCompleted = createSelector(
  selectCustomersState,
  (state: CustomersState) => state.isUpdateCompleted
);

/**
 * Determines if there is a running block process.
 */
export const getSelectedCustomerBlocking = createSelector(
  selectCustomersState,
  (state: CustomersState) => state.isBlocking
);

/**
 * Determines if the last block process has been ended successfully.
 */
export const getSelectedCustomerBlockCompleted = createSelector(
  selectCustomersState,
  (state: CustomersState) => state.isBlockCompleted
);

/**
 * Determines if there is a running activate process.
 */
export const getSelectedCustomerActivating = createSelector(
  selectCustomersState,
  (state: CustomersState) => state.isActivating
);

/**
 * Determines if the last activate process has been ended successfully.
 */
export const getSelectedCustomerActivateCompleted = createSelector(
  selectCustomersState,
  (state: CustomersState) => state.isActivateCompleted
);

/**
 * Determines if there is a running delete process.
 */
export const getSelectedCustomerDeleting = createSelector(
  selectCustomersState,
  (state: CustomersState) => state.isDeleting
);

/**
 * Determines if the last delete process has been ended successfully.
 */
export const getSelectedCustomerDeleteCompleted = createSelector(
  selectCustomersState,
  (state: CustomersState) => state.isDeleteCompleted
);

/**
 * Determines if there is a running cancel process.
 */
export const getSelectedCustomerLogCanceling = createSelector(
  selectCustomersState,
  (state: CustomersState) => state.isCancelingLog
);

/**
 * Determines if the last cancel process has been ended successfully.
 */
export const getSelectedCustomerLogCancelCompleted = createSelector(
  selectCustomersState,
  (state: CustomersState) => state.isCancelLogCompleted
);
