<!-- loan settlements modal starts -->
<ng-template #modalRef let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title text-primary" id="modal-basic-title">
      {{ 'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.LOAN_SETTLEMENTS' | translate }}
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="d(); closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="col-md-12">
      <div class="table-responsive">
        <table class="table table-sm text-center">
          <thead>
            <tr>
              <th scope="col"><i class="fa fa-list-ol"></i></th>
              <th scope="col">
                {{ 'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.VALUE_TITLE' | translate }}
              </th>
              <th scope="col">
                {{ 'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.LOAN_VALUE_TITLE' | translate }}
              </th>
              <th scope="col">
                {{ 'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.SETTLEMENT_VALUE' | translate }}
              </th>
              <th scope="col">{{ 'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.PAID' | translate }}</th>
              <th scope="col" *ngIf="!isPrepare">
                {{ 'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.REMAINING' | translate }}
              </th>
              <th scope="col">
                {{ 'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.SETTLEMENT_START_DATE' | translate }}
              </th>
              <th scope="col">
                {{ 'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.TRANSACTION_DATE' | translate }}
              </th>
              <th scope="col">
                {{ 'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.COUNTING_STATUS' | translate }}
              </th>
              <th scope="col">
                {{ 'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.NOTES' | translate }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let payrollLoanSettlement of payrollLoanSettlements; trackBy: trackItems; let i = index">
              <th scope="row">{{ i + 1 }}</th>
              <td>
                <span class="badge bg-info round">
                  {{ payrollLoanSettlement.value | toDecimal }}
                </span>
              </td>
              <td>
                <span class="badge bg-info round">
                  {{ payrollLoanSettlement.employeeLoan.value | toDecimal }}
                </span>
              </td>
              <td>
                <span class="badge bg-info round">
                  {{ payrollLoanSettlement.employeeLoan.settlementValue | toDecimal }}
                </span>
              </td>
              <td>
                <span class="badge bg-warning round">
                  {{ payrollLoanSettlement.employeeLoan.paid | toDecimal }}
                </span>
              </td>
              <td *ngIf="!isPrepare">
                <span class="badge bg-danger round">
                  {{ payrollLoanSettlement.employeeLoan.remaining | toDecimal }}
                </span>
              </td>
              <td class="fit-width">
                <span [ngbTooltip]="payrollLoanSettlement.employeeLoan.settlementStartDate | date: 'time'">
                  {{ payrollLoanSettlement.employeeLoan.settlementStartDate | date }}</span
                >
              </td>
              <td class="fit-width">
                <span [ngbTooltip]="payrollLoanSettlement.employeeLoan.transactionDate | date: 'time'">
                  {{ payrollLoanSettlement.employeeLoan.transactionDate | date }}
                </span>
              </td>
              <td>
                <app-status
                  [status]="payrollLoanSettlement.employeeLoan.enableSettlement"
                  [activeText]="'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.ENABLED_SETTLEMENT' | translate"
                  [notActiveText]="
                    'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.NOT_ENABLED_SETTLEMENT' | translate
                  "
                >
                </app-status>
              </td>
              <td>
                {{ payrollLoanSettlement.employeeLoan.notes }}
              </td>
            </tr>
          </tbody>
          <tfoot class="tfoot-light">
            <tr>
              <td [attr.colspan]="10">
                <i class="fa fa-bookmark c-primary"></i>
                <span>
                  {{ payrollLoanSettlements.length | toNumber }}
                  {{ 'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.LOAN_SETTLEMENT' | translate }}
                </span>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="modal-actions">
      <button type="button" class="btn btn-outline-dark round" (click)="c(); closeModal()">
        <i class="fa fa-times"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.CLOSE' | translate }}</span>
      </button>
    </div>
  </div>
</ng-template>
<!-- loan settlements modal ends -->

<!-- no items template starts -->
<ng-template #noItems>
  <div class="col-md-12 alert alert-warning mt-3 text-center" role="alert">
    {{ 'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.LOAN_SETTLEMENTS_NOT_FOUND' | translate }}
  </div>
</ng-template>
<!-- no items template ends -->
