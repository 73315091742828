import { SaleReturn } from 'sales/models';
import { PaginationInfo } from 'shared/models';

/**
 * Represents the saleReturns state.
 */
export class SaleReturnsState {
  /**
   * The list of saleReturns.
   */
  public data: SaleReturn[];

  /**
   * The pagination info for the saleReturns.
   */
  public paginationInfo: PaginationInfo;

  /**
   * An error that may resulting during processing some actions.
   */
  public error: any;

  /**
   * Determines if there is a running search process.
   */
  public isSearching: boolean;

  /**
   * Determines if the last search process has been ended successfully.
   */
  public isSearchCompleted: boolean;

  /**
   * The current selected saleReturns.
   */
  public selectedSaleReturn: SaleReturn;

  /**
   * Determines if there is a running find-saleReturns process.
   */
  public isFinding: boolean;

  /**
   * Determines if the last find-saleReturns process has been ended successfully.
   */
  public isFindCompleted: boolean;

  /**
   * Determines if there is a running create process.
   */
  public isCreating: boolean;

  /**
   * Determines if the last create process has been ended successfully.
   */
  public isCreateCompleted: boolean;
}
