<div class="row mb-1">
  <div class="col-md-auto">
    <button
      type="button"
      class="btn btn-sm btn-raised btn-primary round"
      [ngbTooltip]="'SALES.CUSTOMER_ENGAGEMENTS.CREATE_CUSTOMER_ENGAGEMENT' | translate"
      placement="top-right"
      (click)="add()"
    >
      <i class="fa fa-plus"></i>
    </button>
    <button
      type="button"
      class="btn btn-sm btn-raised btn-danger round mx-2"
      [ngbTooltip]="'SALES.CUSTOMER_ENGAGEMENTS.DELETE_CUSTOMER_ENGAGEMENT' | translate"
      (click)="openDeleteModal()"
    >
      <i class="fa fa-trash"></i>
    </button>
  </div>
</div>
<div class="row" [formGroup]="engagementsForm.parent">
  <div class="col-md-12">
    <div class="table-responsive" [ngStyle]="{ 'padding-bottom': isDropDownOpened ? '8rem' : 'unset' }">
      <table class="table text-center">
        <thead>
          <tr>
            <th scope="col"><i class="fa fa-list-ol"></i></th>
            <th scope="col">
              {{ 'SALES.CUSTOMER_ENGAGEMENTS.CUSTOMER_ENGAGEMENT_DATA.CUSTOMER_ENGAGEMENT_TYPE' | translate }}
            </th>
            <th scope="col">
              {{ 'SALES.CUSTOMER_ENGAGEMENTS.CUSTOMER_ENGAGEMENT_DATA.CUSTOMER_ENGAGEMENT_VALUE' | translate }}
            </th>
            <th scope="col">{{ 'SALES.CUSTOMER_ENGAGEMENTS.CUSTOMER_ENGAGEMENT_DATA.NOTES' | translate }}</th>
            <th scope="col">{{ 'SHARED.TOOLS' | translate }}</th>
          </tr>
        </thead>
        <tbody formArrayName="engagements">
          <tr [formGroup]="engagementGroup" *ngFor="let engagementGroup of engagementsForm.controls; let i = index">
            <th scope="row">{{ i + 1 }}</th>
            <td>
              <ng-select
                class="round"
                formControlName="customerEngagementTypeId"
                labelForId="customerEngagementTypeId"
                searchable="true"
                [placeholder]="
                  'SALES.CUSTOMER_ENGAGEMENTS.CUSTOMER_ENGAGEMENT_DATA.CUSTOMER_ENGAGEMENT_TYPE_PLACEHOLDER' | translate
                "
                [notFoundText]="'SHARED.SEARCH.SEARCH_NOT_FOUND' | translate"
                dropdownPosition="bottom"
                (open)="isDropDownOpened = true"
                (close)="isDropDownOpened = false"
              >
                <ng-option *ngFor="let type of engagementTypes$ | async" [value]="type.id">
                  {{ type.name | localize: type.nameEn | async }}
                </ng-option>
              </ng-select>
            </td>
            <td>
              <input
                type="text"
                [mask]="DECIMAL_MASK"
                thousandSeparator=","
                minLength="0"
                id="value"
                class="form-control round"
                [placeholder]="
                  'SALES.CUSTOMER_ENGAGEMENTS.CUSTOMER_ENGAGEMENT_DATA.CUSTOMER_ENGAGEMENT_VALUE_PLACEHOLDER'
                    | translate
                "
                formControlName="value"
              />
            </td>
            <td>
              <textarea
                id="notes"
                class="form-control round"
                [placeholder]="'SALES.CUSTOMER_ENGAGEMENTS.CUSTOMER_ENGAGEMENT_DATA.NOTES_PLACEHOLDER' | translate"
                minlength="0"
                maxlength="200"
                formControlName="notes"
                rows="1"
              ></textarea>
            </td>
            <td>
              <a class="danger p-0" [ngbTooltip]="'SHARED.CONFIRM.DELETE' | translate" (click)="remove(i)">
                <i class="fa fa-close font-medium-3 mr-2"></i>
              </a>
            </td>
          </tr>
        </tbody>
        <tfoot class="tfoot-light">
          <tr>
            <td [attr.colspan]="9">
              <i class="fa fa-bookmark c-primary"></i>
              <span>
                {{ engagementsForm.controls.length | toNumber }}
                {{ 'SALES.CUSTOMER_ASSETS.CUSTOMER_ASSET_DATA.CUSTOMER_ENGAGEMENT' | translate }}
              </span>
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  </div>
</div>

<!-- confirm delete all engagements modal starts -->
<ng-template #deleteModalRef let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title text-danger" id="modal-basic-title">
      {{ 'SALES.CUSTOMER_ENGAGEMENTS.DELETE_ALL_ENGAGEMENTS_TITLE' | translate }}
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="d()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p class="text-danger">{{ 'SALES.CUSTOMER_ENGAGEMENTS.DELETE_ALL_ENGAGEMENTS_MESSAGE' | translate }}</p>
  </div>
  <div class="modal-footer">
    <div class="modal-actions">
      <button type="button" class="btn btn-raised btn-danger round" (click)="c(); reset()" ngbAutofocus>
        <i class="fa fa-check"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.YES' | translate }}</span>
      </button>
      <button type="button" class="btn btn-outline-dark round" (click)="c()">
        <i class="fa fa-times"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.CANCEL' | translate }}</span>
      </button>
    </div>
  </div>
</ng-template>
<!-- confirm delete all engagements modal ends -->
