import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormControl, FormArray } from '@angular/forms';

import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

import { NotificationService, PaginationInfo, TranslationService } from 'shared';
import * as fromFinancesStore from 'finances/store';
import { Bank, BankAccount } from 'finances/models';

@Component({
  selector: 'app-bank-accounts-search',
  templateUrl: './bank-accounts-search.component.html',
  styles: [],
})
export class BankAccountsSearchComponent implements OnInit {
  /**
   * Indicates whether the modal will automatically close after select the first bank.
   *
   * Be default it will not be closed.
   */
  @Input() closeOnSelect = false;

  /**
   * Indicates whether the banks displayed on the list should be all active.
   *
   * Be default it will not be closed.
   */
  @Input() onlyActive = false;

  /**
   * Sets the visibility of this component modal.
   *
   * Handles visibility change in two-data-binding approach.
   */
  @Input() set visible(isShown: boolean) {
    if (isShown && this.modalRef) {
      this.openModal(this.modalRef);
    } else {
      this.closeModal();
    }

    this.visibleChange.emit(isShown);
  }

  /**
   * Sets the bank accounts form-array.
   */
  @Input() bankAccountsForm: FormArray;

  /**
   * Outputs a value that indicates whether the modal is shown or not.
   *
   * Handles visibility change in two-data-binding approach.
   */
  @Output() visibleChange = new EventEmitter<boolean>();

  /** Outputs the selected bank accounts. */
  @Output() bankAccounts = new EventEmitter<BankAccount[]>();

  /**
   * The modal template reference.
   */
  @ViewChild('modalRef') modalRef: ElementRef<any>;

  /**
   * The list of banks.
   */
  bankAccounts$: Observable<BankAccount[]>;

  /**
   * The list of banks.
   */
  bankAccountsList: BankAccount[];

  /**
   * The pagination info.
   */
  paginationInfo$: Observable<PaginationInfo>;

  /**
   * Indicates whether there is a search process is running or not.
   */
  isSearching$: Observable<boolean>;

  /**
   * Shows or hide the banks list.
   */
  banksListVisibility = false;

  /**
   * The list of selected banks.
   */
  banks: Bank[] = [];

  /**
   * The search form.
   */
  searchForm: FormGroup;

  /**
   * The ngb-modal reference to use it when modal needed to be closed.
   */
  modal: NgbModalRef;

  /**
   *
   * @param modalService The modal service.
   * @param notificationService The notification service.
   * @param financesStore$ The finances store.
   * @param translationService The translation service.
   */
  constructor(
    private modalService: NgbModal,
    private notificationService: NotificationService,
    private financesStore$: Store<fromFinancesStore.FinancesState>,
    private translationService: TranslationService
  ) {}

  ngOnInit(): void {
    this.init();
  }

  /**
   * Initialize component data.
   */
  init() {
    this.initForm();

    this.isSearching$ = this.financesStore$.pipe(select(fromFinancesStore.getBankAccountsSearching));

    let isManualSearchTriggeredBeforeForBankAccounts = false;
    this.bankAccounts$ = this.financesStore$.pipe(
      select(fromFinancesStore.getBankAccounts),
      tap((bankAccounts) => {
        if (bankAccounts) {
          this.bankAccountsList = bankAccounts;
        }
        if (!isManualSearchTriggeredBeforeForBankAccounts && !bankAccounts.length) {
          isManualSearchTriggeredBeforeForBankAccounts = true;
          this.search();
        }
      })
    );

    this.paginationInfo$ = this.financesStore$.pipe(select(fromFinancesStore.getBankAccountsPaginationInfo));
  }

  /**
   * Initialize form and add validators.
   */
  initForm() {
    this.searchForm = new FormGroup({
      name: new FormControl(''),
      banks: new FormControl([]),
      page: new FormControl(1),
    });
  }

  /**
   * Handles search parameters change.
   */
  search(event?: KeyboardEvent) {
    if (event && event.key === 'Enter') {
      event.preventDefault();
      return;
    }

    this.financesStore$.dispatch(
      new fromFinancesStore.SearchBankAccounts({
        name: this.searchForm.get('name').value,
        banks: this.searchForm.get('banks').value,
        page: 1,
      })
    );
  }

  /**
   * Handles pagination page-changed event.
   * @param page The current selected page number.
   */
  pageChanged(page: number) {
    /** Update pagination page. */
    this.searchForm.patchValue({ page });

    /** Get selected page items. */
    this.financesStore$.dispatch(
      new fromFinancesStore.SearchBankAccounts({
        name: this.searchForm.get('name').value,
        banks: this.searchForm.get('banks').value,
        page: this.searchForm.get('page').value,
      })
    );
  }

  /**
   * Enables trackBy feature for *ngFor utility to track items
   * depending on a comparer to reduce DOM change.
   * If the item is already exist then no HTML will change, only bounded values.
   * @param item The item to determine if it was changed or not.
   */
  trackItems(index: number, bankAccount: BankAccount) {
    return bankAccount ? bankAccount.id : undefined;
  }

  /**
   * Handles bank account selected event.
   * @param bankAccounts The list of banks to notify selected.
   */
  selectBankAccount(bankAccounts: BankAccount[]) {
    if (this.bankAccountsForm) {
      for (let index = 0; index < this.bankAccountsForm.length; index++) {
        if (this.bankAccountsForm.value[index].bankAccountId === bankAccounts[0].id) {
          if (this.closeOnSelect) {
            /**
             * Message to warn the user before selected bank account again.
             */
            this.notificationService.warning(
              this.translationService.translate(
                'FINANCES.BANK_ACCOUNTS.NOTIFICATION_MESSAGE.SELECTED_BANK_ACCOUNT_AGAIN'
              )
            );
            this.closeModal();
            return;
          }
          return;
        }
      }
    }

    this.notificationService.successInstant(
      this.translationService.translate('FINANCES.BANK_ACCOUNTS.NOTIFICATION_MESSAGE.BANK_ACCOUNT_SELECTED')
    );

    this.bankAccounts.emit(bankAccounts);
    if (this.closeOnSelect) {
      this.closeModal();
    }
  }

  /**
   * Opens the modal of the given templateRef.
   * @param modalRef The modal templateRef to be opened.
   */
  openModal(modalRef) {
    /**
     * Open the modal & listen for user press ESC key.
     */
    this.modal = this.modalService.open(modalRef, { size: 'xl' });
    this.modal.result.then(null, () => this.closeModal());
  }

  /**
   * Closes the currently opened modal.
   */
  closeModal() {
    this.modal?.dismiss();
    this.bankAccounts.emit([]);
    this.visibleChange.emit(false);
  }

  /**
   * Adds the newly selected banks the banks search list.
   * @param banks The list of newly selected banks to be added.
   */
  selectBanks(banks: Bank[]) {
    const selectedIds: number[] = this.searchForm.get('banks').value;
    this.banks = [...this.banks.filter((bank) => selectedIds.includes(bank.id)), ...banks];
    this.searchForm.patchValue({ banks: this.banks.map((bank) => bank.id) });
  }
}
