<!-- payroll modal starts -->
<ng-template #modalRef let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title text-primary" id="modal-basic-title">
      {{ 'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.INFO_PAYROLL_TITLE' | translate }}
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="d()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-md-12">
        <div class="row">
          <div class="form-group col-6" *ngIf="!isEmployee">
            <label for="employeeNumber">{{
              'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.EMPLOYEE_NUMBER' | translate
            }}</label>
            <input
              type="text"
              id="employeeNumber"
              class="form-control round"
              readonly
              [value]="payroll.employee.employeeAssignmentDetail?.personNumber"
            />
          </div>
          <div class="form-group col-6" *ngIf="!(isEmployee || isPrepare)">
            <label for="employee">{{ 'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.EMPLOYEE' | translate }}</label>
            <input
              type="text"
              id="employee"
              class="form-control round"
              readonly
              [value]="
                payroll.employee.employeePersonalDetail.fullName
                  | localize: payroll.employee.employeePersonalDetail.fullNameEn
                  | async
              "
            />
          </div>
          <div class="form-group col-6" *ngIf="!isEmployee">
            <label for="position">{{ 'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.POSITION' | translate }}</label>
            <input
              type="text"
              id="position"
              class="form-control round"
              readonly
              [value]="
                payroll.employee.employeeAssignmentDetail.position.name
                  | localize: payroll.employee.employeeAssignmentDetail.position.nameEn
                  | async
              "
            />
          </div>
          <div class="form-group col-6" *ngIf="!isEmployee">
            <label for="department">{{
              'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.DEPARTMENT' | translate
            }}</label>
            <input
              type="text"
              id="department"
              class="form-control round"
              readonly
              [value]="
                payroll.employee.employeeAssignmentDetail.department?.name
                  | localize: payroll.employee.employeeAssignmentDetail.department?.nameEn
                  | async
              "
            />
          </div>
          <div class="form-group col-6">
            <label for="month">{{ 'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.MONTH' | translate }}</label>
            <input type="text" id="month" class="form-control round" readonly [value]="payroll.payrollDate" />
          </div>
          <div class="form-group col-6" *ngIf="payroll.workDays">
            <label for="workDays">{{
              'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.DAYS_COUNT' | translate
            }}</label>
            <input type="text" id="workDays" class="form-control round" readonly [value]="payroll.workDays" />
          </div>

          <div class="form-group col-6">
            <label for="net">{{ 'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.NET' | translate }}</label>
            <input type="text" id="net" class="form-control round" readonly [value]="payroll.net | toDecimal" />
          </div>
          <div class="form-group col-6">
            <label for="salary">{{ 'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.SALARY' | translate }}</label>
            <input type="text" id="salary" class="form-control round" readonly [value]="payroll.salary | toDecimal" />
          </div>

          <div class="form-group col-6">
            <label for="salaryForGosi">{{
              'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.SALARY_FOR_GOSI' | translate
            }}</label>
            <input
              type="text"
              id="salaryForGosi"
              class="form-control round"
              readonly
              [value]="payroll.salaryForGosi | toDecimal"
            />
          </div>
          <div class="form-group col-6">
            <label for="employeeGosi">{{
              'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.EMPLOYEE_GOSI' | translate
            }}</label>
            <input
              type="text"
              id="employeeGosi"
              class="form-control round"
              readonly
              [value]="payroll.employeeGosi | toDecimal"
            />
          </div>
          <div class="form-group col-6">
            <label for="employerGosi">{{
              'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.EMPLOYER_GOSI' | translate
            }}</label>
            <input
              type="text"
              id="employerGosi"
              class="form-control round"
              readonly
              [value]="payroll.employerGosi | toDecimal"
            />
          </div>
          <div class="form-group col-6">
            <label for="employerGosi">{{
              'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.SPONSOR' | translate
            }}</label>
            <input
              type="text"
              id="employerGosi"
              class="form-control round"
              readonly
              [value]="payroll.employeeSponsor?.name | localize: payroll.employeeSponsor?.nameEn | async"
            />
          </div>
          <div class="form-group col-6">
            <label for="loanSettlement">{{
              'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.LOAN_SETTLEMENTS' | translate
            }}</label>
            <input
              type="text"
              id="loanSettlement"
              class="form-control round"
              readonly
              [value]="payroll.loanSettlement | toDecimal"
            />
          </div>
          <div class="form-group col-6">
            <label for="otherEarnings">{{
              'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.OTHER_EARNINGS' | translate
            }}</label>
            <input
              type="text"
              id="otherEarnings"
              class="form-control round"
              readonly
              [value]="payroll.otherEarnings | toDecimal"
            />
          </div>
          <div class="form-group col-6">
            <label for="deductions">{{
              'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.DEDUCTIONS' | translate
            }}</label>
            <input
              type="text"
              id="deductions"
              class="form-control round"
              readonly
              [value]="payroll.deductions | toDecimal"
            />
          </div>
          <div class="form-group" [ngClass]="payroll.workDays || isPrepare ? 'col-6' : 'col-12'">
            <label for="overtime">{{
              'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.OVERTIMES' | translate
            }}</label>
            <input
              type="text"
              id="overtime"
              class="form-control round"
              readonly
              [value]="payroll.overtime | toDecimal"
            />
          </div>
          <div class="form-group col-6" *ngIf="inReview">
            <label for="payrollStatus">{{
              'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.STATUS' | translate
            }}</label>
            <input
              type="text"
              id="payrollStatus"
              class="form-control round"
              readonly
              [value]="payroll.payrollStatus?.name | localize: payroll.payrollStatus?.nameEn | async"
            />
          </div>
          <div class="form-group col-6" *ngIf="inReview">
            <label for="createdAt">{{ 'SHARED.SEARCH.CREATED_AT' | translate }}</label>
            <input
              type="text"
              id="createdAt"
              class="form-control round"
              readonly
              [value]="payroll.createdAt | date: 'datetime'"
            />
          </div>
          <div class="form-group" [ngClass]="payroll.workDays ? 'col-6' : 'col-12'" *ngIf="inReview">
            <label for="transactionDate">{{
              'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.TRANSACTION_DATE' | translate
            }}</label>
            <input
              type="text"
              id="transactionDate"
              class="form-control round"
              readonly
              [value]="payroll.transactionDate | date: 'datetime'"
            />
          </div>
          <div class="form-group col-md-12">
            <label for="notes"> {{ 'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.NOTES' | translate }}</label>
            <textarea
              id="notes"
              class="form-control round"
              [innerText]="payroll.notes"
              [placeholder]="'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.NOTES_NOT_FOUND' | translate"
              readonly
            ></textarea>
          </div>
        </div>
      </div>

      <div class="col-md-12" *ngIf="payroll.payrollLocationCosts.length">
        <div class="row">
          <div class="col-md-12 alert alert-light text-center" role="alert">
            <span>{{ 'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.LOCATIONS_COSTS' | translate }}</span>
          </div>
          <div class="col-md-12">
            <div class="table-responsive">
              <table class="table table-sm text-center">
                <thead>
                  <tr>
                    <th scope="col" class="fit-width"><i class="fa fa-list-ol"></i></th>
                    <th scope="col" class="fit-width">
                      {{ 'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.LOCATION' | translate }}
                    </th>
                    <th scope="col" class="fit-width">
                      {{ 'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.DAYS_COUNT' | translate }}
                    </th>
                    <th scope="col" class="fit-width">
                      {{ 'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.NET' | translate }}
                    </th>
                    <th scope="col" class="fit-width">
                      {{ 'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.SALARY' | translate }}
                    </th>
                    <th scope="col" class="fit-width">
                      {{ 'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.OTHER_EARNINGS' | translate }}
                    </th>
                    <th scope="col" class="fit-width">
                      {{ 'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.DEDUCTIONS' | translate }}
                    </th>
                    <th scope="col" class="fit-width">
                      {{ 'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.OVERTIMES' | translate }}
                    </th>
                    <th scope="col" class="fit-width">
                      {{ 'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.EMPLOYEE_GOSI' | translate }}
                    </th>
                    <th scope="col" class="fit-width">
                      {{ 'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.EMPLOYER_GOSI' | translate }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let payrollLocationCost of payroll.payrollLocationCosts; let i = index">
                    <th scope="row">{{ i + 1 }}</th>
                    <td>
                      {{ payrollLocationCost.location.name | localize: payrollLocationCost.location.nameEn | async }}
                    </td>
                    <td>{{ payrollLocationCost.daysCount | toNumber }}</td>
                    <td>
                      <span
                        [ngClass]="{
                          'text-danger': payrollLocationCost.net < 0
                        }"
                      >
                        {{ payrollLocationCost.net | toDecimal }}
                      </span>
                    </td>
                    <td>{{ payrollLocationCost.grossSalary | toDecimal }}</td>
                    <td>{{ payrollLocationCost.otherEarnings | toDecimal }}</td>
                    <td>{{ payrollLocationCost.deductions | toDecimal }}</td>
                    <td>{{ payrollLocationCost.overtimes | toDecimal }}</td>
                    <td>{{ payrollLocationCost.employeeGosi | toDecimal }}</td>
                    <td>{{ payrollLocationCost.employerGosi | toDecimal }}</td>
                  </tr>
                </tbody>
                <tfoot class="tfoot-light">
                  <tr>
                    <td [attr.colspan]="10">
                      <i class="fa fa-bookmark c-primary"></i>
                      <span>
                        {{ payroll.payrollRecurringElements.length | toNumber }}
                        {{ 'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.LOCATION_COST' | translate }}
                      </span>
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-12" *ngIf="payroll?.payrollProjectCosts?.length">
        <div class="row">
          <div class="col-md-12 alert alert-light text-center" role="alert">
            <span>{{ 'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.PROJECTS_COSTS' | translate }}</span>
          </div>
          <div class="col-md-12">
            <div class="table-responsive">
              <table class="table table-sm text-center">
                <thead>
                  <tr>
                    <th scope="col" class="fit-width"><i class="fa fa-list-ol"></i></th>
                    <th scope="col" class="fit-width">
                      {{ 'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.PROJECT' | translate }}
                    </th>
                    <th scope="col" class="fit-width">
                      {{ 'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.DAYS_COUNT' | translate }}
                    </th>
                    <th scope="col" class="fit-width">
                      {{ 'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.NET' | translate }}
                    </th>
                    <th scope="col" class="fit-width">
                      {{ 'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.SALARY' | translate }}
                    </th>
                    <th scope="col" class="fit-width">
                      {{ 'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.OTHER_EARNINGS' | translate }}
                    </th>
                    <th scope="col" class="fit-width">
                      {{ 'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.DEDUCTIONS' | translate }}
                    </th>
                    <th scope="col" class="fit-width">
                      {{ 'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.OVERTIMES' | translate }}
                    </th>
                    <th scope="col" class="fit-width">
                      {{ 'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.EMPLOYEE_GOSI' | translate }}
                    </th>
                    <th scope="col" class="fit-width">
                      {{ 'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.EMPLOYER_GOSI' | translate }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let payrollProjectCost of payroll.payrollProjectCosts; let i = index">
                    <th scope="row">{{ i + 1 }}</th>
                    <td>
                      {{
                        payrollProjectCost.project.description
                          | localize: payrollProjectCost.project.descriptionEn
                          | async
                      }}
                    </td>
                    <td>{{ payrollProjectCost.daysCount | toNumber }}</td>
                    <td>
                      <span
                        [ngClass]="{
                          'text-danger': payrollProjectCost.net < 0
                        }"
                      >
                        {{ payrollProjectCost.net | toDecimal }}
                      </span>
                    </td>
                    <td>{{ payrollProjectCost.grossSalary | toDecimal }}</td>
                    <td>{{ payrollProjectCost.otherEarnings | toDecimal }}</td>
                    <td>{{ payrollProjectCost.deductions | toDecimal }}</td>
                    <td>{{ payrollProjectCost.overtimes | toDecimal }}</td>
                    <td>{{ payrollProjectCost.employeeGosi | toDecimal }}</td>
                    <td>{{ payrollProjectCost.employerGosi | toDecimal }}</td>
                  </tr>
                </tbody>
                <tfoot class="tfoot-light">
                  <tr>
                    <td [attr.colspan]="10">
                      <i class="fa fa-bookmark c-primary"></i>
                      <span>
                        {{ payroll.payrollRecurringElements.length | toNumber }}
                        {{ 'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.PROJECT_COST' | translate }}
                      </span>
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-12" *ngIf="payroll.payrollProvisions.length">
        <div class="row">
          <div class="col-md-12 alert alert-light text-center" role="alert">
            <span>{{ 'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.PROVISIONS' | translate }}</span>
          </div>
          <div class="col-md-12">
            <div class="table-responsive">
              <table class="table table-sm text-center">
                <thead>
                  <tr>
                    <th scope="col" class="fit-width"><i class="fa fa-list-ol"></i></th>
                    <th scope="col" class="fit-width">
                      {{ 'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.PROVISION' | translate }}
                    </th>
                    <th scope="col" class="fit-width">
                      {{ 'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.DAYS_COUNT' | translate }}
                    </th>
                    <th scope="col" class="fit-width">
                      {{ 'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.PERSONS_COUNT' | translate }}
                    </th>
                    <th scope="col" class="fit-width">
                      {{ 'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.VALUE_TITLE' | translate }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let payrollProvision of payroll.payrollProvisions; let i = index">
                    <th scope="row">{{ i + 1 }}</th>
                    <td>
                      {{ payrollProvision.provision.name | localize: payrollProvision.provision.nameEn | async }}
                    </td>
                    <td>{{ payrollProvision.daysCount | toDecimal }}</td>
                    <td>{{ payrollProvision.personsCount | toDecimal }}</td>
                    <td>{{ payrollProvision.value | toDecimal }}</td>
                  </tr>
                </tbody>
                <tfoot class="tfoot-light">
                  <tr>
                    <td [attr.colspan]="10">
                      <i class="fa fa-bookmark c-primary"></i>
                      <span>
                        {{ payroll.payrollProvisions.length | toNumber }}
                        {{ 'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.PAYROLL_PROVISION' | translate }}
                      </span>
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-12" *ngIf="payroll.payrollGovServices.length">
        <div class="row">
          <div class="col-md-12 alert alert-light text-center" role="alert">
            <span>{{ 'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.GOV_SERVICES' | translate }}</span>
          </div>
          <div class="col-md-12">
            <div class="table-responsive">
              <table class="table table-sm text-center">
                <thead>
                  <tr>
                    <th scope="col" class="fit-width"><i class="fa fa-list-ol"></i></th>
                    <th scope="col" class="fit-width">
                      {{ 'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.GOV_SERVICE' | translate }}
                    </th>
                    <th scope="col" class="fit-width">
                      {{ 'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.DAYS_COUNT' | translate }}
                    </th>
                    <th scope="col" class="fit-width">
                      {{ 'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.VALUE_TITLE' | translate }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let payrollGovService of payroll.payrollGovServices; let i = index">
                    <th scope="row">{{ i + 1 }}</th>
                    <td>
                      {{ payrollGovService.govService.name | localize: payrollGovService.govService.nameEn | async }}
                    </td>
                    <td>{{ payrollGovService.daysCount | toDecimal }}</td>
                    <td>{{ payrollGovService.value | toDecimal }}</td>
                  </tr>
                </tbody>
                <tfoot class="tfoot-light">
                  <tr>
                    <td [attr.colspan]="10">
                      <i class="fa fa-bookmark c-primary"></i>
                      <span>
                        {{ payroll.payrollGovServices.length | toNumber }}
                        {{ 'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.PAYROLL_GOV_SERVICE' | translate }}
                      </span>
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-12" *ngIf="payroll.payrollUnpaidLeaves.length">
        <div class="row">
          <div class="col-md-12 alert alert-light text-center" role="alert">
            <span>{{ 'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.UNPAID_LEAVES' | translate }}</span>
          </div>
          <div class="col-md-12">
            <div class="table-responsive">
              <table class="table table-sm text-center">
                <thead>
                  <tr>
                    <th scope="col" class="fit-width"><i class="fa fa-list-ol"></i></th>
                    <th scope="col" class="fit-width">
                      {{ 'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.START_DATE' | translate }}
                    </th>
                    <th scope="col" class="fit-width">
                      {{ 'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.END_DATE' | translate }}
                    </th>
                    <th scope="col" class="fit-width">
                      {{ 'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.DAYS_COUNT' | translate }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let payrollUnpaidLeave of payroll.payrollUnpaidLeaves; let i = index">
                    <th scope="row">{{ i + 1 }}</th>
                    <td class="fit-width">
                      <span [ngbTooltip]="payrollUnpaidLeave.startDate | date: 'time'">
                        {{ payrollUnpaidLeave.startDate | date }}</span
                      >
                    </td>
                    <td class="fit-width">
                      <span [ngbTooltip]="payrollUnpaidLeave.endDate | date: 'time'">
                        {{ payrollUnpaidLeave.endDate | date }}</span
                      >
                    </td>
                    <td>{{ payrollUnpaidLeave.daysCount | toDecimal }}</td>
                  </tr>
                </tbody>
                <tfoot class="tfoot-light">
                  <tr>
                    <td [attr.colspan]="10">
                      <i class="fa fa-bookmark c-primary"></i>
                      <span>
                        {{ payroll.payrollUnpaidLeaves.length | toNumber }}
                        {{ 'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.UNPAID_LEAVE' | translate }}
                      </span>
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-12" *ngIf="payroll?.payrollRecurringElements.length">
        <div class="row">
          <div class="col-md-12 alert alert-light text-center" role="alert">
            <span>{{
              'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.RECURRING_PAYROLL_ELEMENTS' | translate
            }}</span>
          </div>
          <div class="col-md-12">
            <div class="table-responsive">
              <table class="table table-sm text-center">
                <thead>
                  <tr>
                    <th scope="col"><i class="fa fa-list-ol"></i></th>
                    <th scope="col">
                      {{ 'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.PAYROLL_ELEMENT' | translate }}
                    </th>
                    <th scope="col">
                      {{ 'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.ELEMENT_CLASSIFICATION' | translate }}
                    </th>
                    <th scope="col">
                      {{ 'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.PAYROLL_ELEMENT_TYPE' | translate }}
                    </th>
                    <th scope="col">
                      {{ 'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.VALUE_TITLE' | translate }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let payrollRecurringElement of payroll?.payrollRecurringElements; let i = index">
                    <th scope="row">{{ i + 1 }}</th>
                    <td>
                      {{
                        payrollRecurringElement.payrollElement.name
                          | localize: payrollRecurringElement.payrollElement.nameEn
                          | async
                      }}
                    </td>
                    <td>
                      {{
                        payrollRecurringElement.payrollElement.payrollElementClassification?.name
                          | localize: payrollRecurringElement.payrollElement.payrollElementClassification?.nameEn
                          | async
                      }}
                    </td>
                    <td>
                      {{
                        payrollRecurringElement.payrollElement.payrollElementType.name
                          | localize: payrollRecurringElement.payrollElement.payrollElementType.nameEn
                          | async
                      }}
                      <a
                        class="info p-0 mx-2"
                        (click)="
                          selectedPayrollElement = payrollRecurringElement.payrollElement;
                          payrollElementKey = payrollRecurringElement.payrollElement.payrollElementType.key;
                          payrollElementFormulasListVisibility = true
                        "
                        [ngbTooltip]="'HR.EMPLOYEES.PAYROLL_ELEMENTS.PAYROLL_ELEMENT_FORMULAS' | translate"
                      >
                        <i class="fa fa-info font-medium-3 mr-2"></i>
                      </a>
                    </td>
                    <td>{{ payrollRecurringElement.value | toDecimal }}</td>
                  </tr>
                </tbody>
                <tfoot class="tfoot-light">
                  <tr>
                    <td [attr.colspan]="5">
                      <i class="fa fa-bookmark c-primary"></i>
                      <span>
                        {{ payroll.payrollRecurringElements.length | toNumber }}
                        {{ 'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.RECURRING_PAYROLL_ELEMENT' | translate }}
                      </span>
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-12" *ngIf="payroll?.payrollGosiPayrollElements.length">
        <div class="row">
          <div class="col-md-12 alert alert-light text-center" role="alert">
            <span>{{
              'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.GOSI_PAYROLL_ELEMENTS_TITLE' | translate
            }}</span>
          </div>
          <div class="col-md-12">
            <div class="table-responsive">
              <table class="table table-sm text-center">
                <thead>
                  <tr>
                    <th scope="col"><i class="fa fa-list-ol"></i></th>
                    <th scope="col">
                      {{ 'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.PAYROLL_ELEMENT' | translate }}
                    </th>
                    <th scope="col">
                      {{ 'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.ELEMENT_CLASSIFICATION' | translate }}
                    </th>
                    <th scope="col">
                      {{ 'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.PAYROLL_ELEMENT_TYPE' | translate }}
                    </th>
                    <th scope="col">
                      {{ 'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.VALUE_TITLE' | translate }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let payrollGosiPayrollElement of payroll?.payrollGosiPayrollElements; let i = index">
                    <th scope="row">{{ i + 1 }}</th>
                    <td>
                      {{
                        payrollGosiPayrollElement.payrollElement.name
                          | localize: payrollGosiPayrollElement.payrollElement.nameEn
                          | async
                      }}
                    </td>
                    <td>
                      {{
                        payrollGosiPayrollElement.payrollElement.payrollElementClassification?.name
                          | localize: payrollGosiPayrollElement.payrollElement.payrollElementClassification?.nameEn
                          | async
                      }}
                    </td>
                    <td>
                      {{
                        payrollGosiPayrollElement.payrollElement.payrollElementType.name
                          | localize: payrollGosiPayrollElement.payrollElement.payrollElementType.nameEn
                          | async
                      }}
                      <a
                        class="info p-0 mx-2"
                        (click)="
                          selectedPayrollElement = payrollGosiPayrollElement.payrollElement;
                          payrollElementKey = payrollGosiPayrollElement.payrollElement.payrollElementType.key;
                          payrollElementFormulasListVisibility = true
                        "
                        [ngbTooltip]="'HR.EMPLOYEES.PAYROLL_ELEMENTS.PAYROLL_ELEMENT_FORMULAS' | translate"
                      >
                        <i class="fa fa-info font-medium-3 mr-2"></i>
                      </a>
                    </td>
                    <td>{{ payrollGosiPayrollElement.value | toDecimal }}</td>
                  </tr>
                </tbody>
                <tfoot class="tfoot-light">
                  <tr>
                    <td [attr.colspan]="5">
                      <i class="fa fa-bookmark c-primary"></i>
                      <span>
                        {{ payroll.payrollRecurringElements.length | toNumber }}
                        {{ 'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.RECURRING_PAYROLL_ELEMENT' | translate }}
                      </span>
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-12" *ngIf="payroll.payrollLoanSettlements.length">
        <div class="row">
          <div class="col-md-12 alert alert-light text-center" role="alert">
            <span>{{ 'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.LOAN_SETTLEMENTS' | translate }}</span>
          </div>
          <div class="col-md-12">
            <div class="table-responsive">
              <table class="table table-sm text-center">
                <thead>
                  <tr>
                    <th scope="col"><i class="fa fa-list-ol"></i></th>
                    <th scope="col">
                      {{ 'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.VALUE_TITLE' | translate }}
                    </th>
                    <th scope="col">
                      {{ 'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.LOAN_VALUE_TITLE' | translate }}
                    </th>
                    <th scope="col">
                      {{ 'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.SETTLEMENT_VALUE' | translate }}
                    </th>
                    <th scope="col">{{ 'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.PAID' | translate }}</th>
                    <th scope="col" *ngIf="!isPrepare">
                      {{ 'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.REMAINING' | translate }}
                    </th>
                    <th scope="col">
                      {{ 'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.SETTLEMENT_START_DATE' | translate }}
                    </th>
                    <th scope="col">
                      {{ 'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.TRANSACTION_DATE' | translate }}
                    </th>
                    <th scope="col">
                      {{ 'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.COUNTING_STATUS' | translate }}
                    </th>
                    <th scope="col">
                      {{ 'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.NOTES' | translate }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let payrollLoanSettlement of payroll.payrollLoanSettlements; let i = index">
                    <th scope="row">{{ i + 1 }}</th>
                    <td>
                      <span class="badge bg-info round">
                        {{ payrollLoanSettlement.value | toDecimal }}
                      </span>
                    </td>
                    <td>
                      <span class="badge bg-info round">
                        {{ payrollLoanSettlement.employeeLoan.value | toDecimal }}
                      </span>
                    </td>
                    <td>
                      <span class="badge bg-info round">
                        {{ payrollLoanSettlement.employeeLoan.settlementValue | toDecimal }}
                      </span>
                    </td>
                    <td>
                      <span class="badge bg-warning round">
                        {{ payrollLoanSettlement.employeeLoan.paid | toDecimal }}
                      </span>
                    </td>
                    <td *ngIf="!isPrepare">
                      <span class="badge bg-danger round">
                        {{ payrollLoanSettlement.employeeLoan.remaining | toDecimal }}
                      </span>
                    </td>
                    <td class="fit-width">
                      <span [ngbTooltip]="payrollLoanSettlement.employeeLoan.settlementStartDate | date: 'time'">
                        {{ payrollLoanSettlement.employeeLoan.settlementStartDate | date }}</span
                      >
                    </td>
                    <td class="fit-width">
                      <span [ngbTooltip]="payrollLoanSettlement.employeeLoan.transactionDate | date: 'time'">
                        {{ payrollLoanSettlement.employeeLoan.transactionDate | date }}
                      </span>
                    </td>
                    <td>
                      <app-status
                        [status]="payrollLoanSettlement.employeeLoan.enableSettlement"
                        [activeText]="
                          'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.ENABLED_SETTLEMENT' | translate
                        "
                        [notActiveText]="
                          'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.NOT_ENABLED_SETTLEMENT' | translate
                        "
                      >
                      </app-status>
                    </td>
                    <td>
                      {{ payrollLoanSettlement.employeeLoan.notes }}
                    </td>
                  </tr>
                </tbody>
                <tfoot class="tfoot-light">
                  <tr>
                    <td [attr.colspan]="10">
                      <i class="fa fa-bookmark c-primary"></i>
                      <span>
                        {{ payroll.payrollLoanSettlements.length | toNumber }}
                        {{ 'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.LOAN_SETTLEMENT' | translate }}
                      </span>
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-12" *ngIf="payroll.payrollOvertimes.length">
        <div class="row">
          <div class="col-md-12 alert alert-light text-center mb-0" role="alert">
            <span>{{ 'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.OVERTIMES' | translate }}</span>
          </div>
          <div class="col-md-12">
            <div class="table-responsive">
              <table class="table table-sm text-center">
                <thead>
                  <tr>
                    <th scope="col"><i class="fa fa-list-ol"></i></th>
                    <th scope="col">{{ 'HR.OVERTIMES.OVERTIME_DATA.HOURS_COUNT' | translate }}</th>
                    <th scope="col">{{ 'HR.OVERTIMES.OVERTIME_DATA.OVERTIME_RATE' | translate }}</th>
                    <th scope="col">
                      {{ 'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.VALUE_TITLE' | translate }}
                    </th>
                    <th scope="col">{{ 'HR.OVERTIMES.OVERTIME_DATA.TRANSACTION_DATE' | translate }}</th>
                    <th scope="col">
                      {{ 'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.NOTES' | translate }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let payrollOvertime of payroll.payrollOvertimes; let i = index">
                    <th scope="row">{{ i + 1 }}</th>
                    <td>{{ payrollOvertime.employeeOvertime.hoursCount | toDecimal }}</td>
                    <td>
                      {{
                        payrollOvertime.employeeOvertime.overtimeRate.name
                          | localize: payrollOvertime.employeeOvertime.overtimeRate.nameEn
                          | async
                      }}
                    </td>

                    <td>{{ payrollOvertime.value | toDecimal }}</td>
                    <td class="fit-width">
                      <span [ngbTooltip]="payrollOvertime.employeeOvertime.transactionDate | date: 'time'">
                        {{ payrollOvertime.employeeOvertime.transactionDate | date }}
                      </span>
                    </td>
                    <td>{{ payrollOvertime.employeeOvertime.notes }}</td>
                  </tr>
                </tbody>
                <tfoot class="tfoot-light">
                  <tr>
                    <td [attr.colspan]="6">
                      <i class="fa fa-bookmark c-primary"></i>
                      <span>
                        {{ payroll.payrollOvertimes.length | toNumber }}
                        {{ 'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.OVERTIME' | translate }}
                      </span>
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-12" *ngIf="payroll.payrollOtherEarnings.length">
        <div class="row">
          <div class="col-md-12 alert alert-light text-center mb-0" role="alert">
            <span>{{ 'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.OTHER_EARNINGS' | translate }}</span>
          </div>
          <div class="col-md-12">
            <div class="table-responsive">
              <table class="table table-sm text-center">
                <thead>
                  <tr>
                    <th scope="col"><i class="fa fa-list-ol"></i></th>
                    <th scope="col">
                      {{ 'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.PAYROLL_ELEMENT' | translate }}
                    </th>
                    <th scope="col">
                      {{ 'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.VALUE_OR_COUNT' | translate }}
                    </th>
                    <th scope="col">{{ 'HR.OTHER_EARNINGS.OTHER_EARNING_DATA.TRANSACTION_DATE' | translate }}</th>
                    <th scope="col">{{ 'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.NOTES' | translate }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let payrollOtherEarning of payroll.payrollOtherEarnings; let i = index">
                    <th scope="row">{{ i + 1 }}</th>
                    <td>
                      {{
                        payrollOtherEarning.employeeOtherEarning.payrollElement.name
                          | localize: payrollOtherEarning.employeeOtherEarning.payrollElement.nameEn
                          | async
                      }}
                    </td>
                    <td>{{ payrollOtherEarning.value | toDecimal }}</td>
                    <td class="fit-width">
                      <span [ngbTooltip]="payrollOtherEarning.employeeOtherEarning.transactionDate | date: 'time'">
                        {{ payrollOtherEarning.employeeOtherEarning.transactionDate | date }}
                      </span>
                    </td>
                    <td>{{ payrollOtherEarning.employeeOtherEarning.notes }}</td>
                  </tr>
                </tbody>
                <tfoot class="tfoot-light">
                  <tr>
                    <td [attr.colspan]="5">
                      <i class="fa fa-bookmark c-primary"></i>
                      <span>
                        {{ payroll.payrollOtherEarnings.length | toNumber }}
                        {{ 'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.OTHER_EARNING' | translate }}
                      </span>
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-12" *ngIf="payroll.payrollDeductions.length">
        <div class="row">
          <div class="col-md-12 alert alert-light text-center mb-0" role="alert">
            <span>{{ 'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.DEDUCTIONS' | translate }}</span>
          </div>
          <div class="col-md-12">
            <div class="table-responsive">
              <table class="table table-sm text-center">
                <thead>
                  <tr>
                    <th scope="col"><i class="fa fa-list-ol"></i></th>
                    <th scope="col">{{ 'HR.DEDUCTIONS.DEDUCTION_DATA.DEDUCTION_TYPE' | translate }}</th>
                    <th scope="col">{{ 'HR.DEDUCTIONS.DEDUCTION_DATA.VALUE_OR_COUNT' | translate }}</th>
                    <th scope="col">
                      {{ 'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.VALUE_TITLE' | translate }}
                    </th>
                    <th scope="col">{{ 'HR.DEDUCTIONS.DEDUCTION_DATA.TRANSACTION_DATE' | translate }}</th>

                    <th scope="col">
                      {{ 'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.NOTES' | translate }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let payrollDeduction of payroll.payrollDeductions; let i = index">
                    <th scope="row">{{ i + 1 }}</th>
                    <td>
                      {{
                        payrollDeduction.employeeDeduction.employeeDeductionType.name
                          | localize: payrollDeduction.employeeDeduction.employeeDeductionType.nameEn
                          | async
                      }}
                    </td>
                    <td>{{ payrollDeduction.employeeDeduction.valueOrCount | toDecimal }}</td>

                    <td>{{ payrollDeduction.value | toDecimal }}</td>
                    <td class="fit-width">
                      <span [ngbTooltip]="payrollDeduction.employeeDeduction.transactionDate | date: 'time'">
                        {{ payrollDeduction.employeeDeduction.transactionDate | date }}
                      </span>
                    </td>
                    <td>{{ payrollDeduction.employeeDeduction.notes }}</td>
                  </tr>
                </tbody>
                <tfoot class="tfoot-light">
                  <tr>
                    <td [attr.colspan]="6">
                      <i class="fa fa-bookmark c-primary"></i>
                      <span>
                        {{ payroll.payrollDeductions.length | toNumber }}
                        {{ 'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.DEDUCTION' | translate }}
                      </span>
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-12" *ngIf="payroll.payrollLogs">
        <div class="row">
          <div class="col-md-12 alert alert-light text-center mb-0" role="alert">
            <span>{{ 'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.PAYROLLS_LOGS' | translate }}</span>
          </div>
          <div class="col-md-12">
            <div class="table-responsive">
              <table class="table table-sm text-center">
                <thead>
                  <tr>
                    <th scope="col"><i class="fa fa-list-ol"></i></th>
                    <th scope="col">
                      {{ 'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.PAYROLL_STATUS' | translate }}
                    </th>
                    <th scope="col">{{ 'SHARED.SEARCH.CREATED_AT' | translate }}</th>
                    <th scope="col">
                      {{ 'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.USER_TITLE' | translate }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let payrollLog of orderPayrollLogs(payroll.payrollLogs); let i = index">
                    <th scope="row">{{ i + 1 }}</th>
                    <td>{{ payrollLog.payrollStatus.name | localize: payrollLog.payrollStatus.nameEn | async }}</td>
                    <td>
                      <span [ngbTooltip]="payrollLog.createdAt | date: 'time'">
                        {{ payrollLog.createdAt | date }}
                      </span>
                    </td>
                    <td>{{ payrollLog.user.name }}</td>
                  </tr>
                </tbody>
                <tfoot class="tfoot-light">
                  <tr>
                    <td [attr.colspan]="4">
                      <i class="fa fa-bookmark c-primary"></i>
                      <span>
                        {{ payroll.payrollLogs.length | toNumber }}
                        {{ 'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.PAYROLLS_LOG' | translate }}
                      </span>
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="payroll.payrollAttachments?.length" class="form-group col-md-12">
        <label>{{ 'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.ATTACHMENTS' | translate }}</label>
        <app-files-viewer [files]="getAttachmentsUrls(payroll.payrollAttachments)"></app-files-viewer>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="modal-actions">
      <button type="button" class="btn btn-outline-dark round" (click)="d()">
        <i class="fa fa-times"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.CANCEL' | translate }}</span>
      </button>
    </div>
  </div>
</ng-template>
<!-- payroll modal ends -->

<!-- payroll element formulas modal starts -->
<payroll-element-formulas
  [payrollElement]="selectedPayrollElement"
  [payrollElementTypeKey]="payrollElementKey"
  [(visible)]="payrollElementFormulasListVisibility"
>
</payroll-element-formulas>
<!-- payroll element formulas modal ends -->

<!-- no attachments template starts -->
<ng-template #noAttachments>
  <div class="col-md-12 alert alert-warning mt-3 text-center" role="alert">
    {{ 'HR.DEDUCTIONS.ATTACHMENTS_NOT_FOUND' | translate }}
  </div>
</ng-template>
<!-- no attachments template ends -->
