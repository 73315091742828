import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';

import { Observable } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

import { NotificationService, PaginationInfo, TranslationService } from 'shared';
import * as fromAppSearchStore from 'app-search/store';
import { Project, ProjectSubTask } from 'projects-management/models';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'project-sub-tasks-search',
  templateUrl: './project-sub-tasks-search.component.html',
  styles: [],
})
export class ProjectSubTasksSearchComponent implements OnInit {
  /**
   * Indicates whether the modal will automatically close after select the first sub task.
   *
   * Be default it will not be closed.
   */
  @Input() closeOnSelect = false;

  /**
   * Indicates whether the sub tasks displayed on the list should be all active.
   *
   * Be default it will not be closed.
   */
  @Input() onlyActive = false;

  /**
   * Sets the project id.
   */
  @Input() projectId: number;

  /**
   * Sets the visibility of this component modal.
   *
   * Handles visibility change in two-data-binding approach.
   */
  @Input() set visible(isShown: boolean) {
    if (isShown && this.modalRef) {
      this.openModal(this.modalRef);
    } else {
      this.closeModal();
    }

    this.visibleChange.emit(isShown);
  }

  /**
   * Outputs a value that indicates whether the modal is shown or not.
   *
   * Handles visibility change in two-data-binding approach.
   */
  @Output() visibleChange = new EventEmitter<boolean>();

  /** Outputs the selected sub tasks. */
  @Output() subTasks = new EventEmitter<ProjectSubTask[]>();

  /**
   * The modal template reference.
   */
  @ViewChild('modalRef') modalRef: ElementRef<any>;

  /**
   * The list of sub tasks.
   */
  subTasks$: Observable<ProjectSubTask[]>;

  /**
   * The list of sub tasks.
   */
  subTasksList: ProjectSubTask[];

  /**
   * The pagination info.
   */
  paginationInfo$: Observable<PaginationInfo>;

  /**
   * Indicates whether there is a search process is running or not.
   */
  isSearching$: Observable<boolean>;

  /**
   * The search form.
   */
  searchForm: FormGroup;

  /**
   * The list of selected projects.
   */
  projects: Project[] = [];

  /**
   * Shows or hide the projects list.
   */
  projectsListVisibility = false;

  /**
   * The ngb-modal reference to use it when modal needed to be closed.
   */
  modal: NgbModalRef;

  /**
   * @param modalService The modal service.
   * @param notificationService The notification service.
   * @param appSearchStore$ The app search store.
   * @param translationService The translation service.
   */
  constructor(
    private modalService: NgbModal,
    private notificationService: NotificationService,
    private appSearchStore$: Store<fromAppSearchStore.AppSearchState>,
    private translationService: TranslationService
  ) {}

  ngOnInit(): void {
    this.init();
  }

  /**
   * Initialize component data.
   */
  init() {
    this.initForm();

    /**
     * Load data.
     */
    this.isSearching$ = this.appSearchStore$.pipe(select(fromAppSearchStore.getSubTasksSearching));

    let isManualSearchTriggeredBeforeSubTasks = false;
    this.subTasks$ = this.appSearchStore$.pipe(
      select(fromAppSearchStore.getSubTasks),
      tap((subTasks) => {
        if (subTasks) {
          this.subTasksList = subTasks;
        }
        if (!isManualSearchTriggeredBeforeSubTasks && !subTasks.length) {
          isManualSearchTriggeredBeforeSubTasks = true;
          this.search();
        }
      })
    );

    this.paginationInfo$ = this.appSearchStore$.pipe(select(fromAppSearchStore.getSubTasksPaginationInfo));
  }

  /**
   * Initialize form and add validators.
   */
  initForm() {
    this.searchForm = new FormGroup({
      description: new FormControl(''),
      projects: new FormControl([]),
      page: new FormControl(1),
    });
  }

  /**
   * Handles search parameters change.
   */
  search(event?: KeyboardEvent) {
    if (event && event.key === 'Enter') {
      event.preventDefault();
      return;
    }

    this.applyFiltersAndSearch();
  }

  /**
   * Handles pagination page-changed event.
   * @param page The current selected page number.
   */
  pageChanged(page: number) {
    /** Update pagination page. */
    this.searchForm.patchValue({ page });

    this.applyFiltersAndSearch(page);
  }

  /**
   * Enables trackBy feature for *ngFor utility to track items
   * depending on a comparer to reduce DOM change.
   * If the item is already exist then no HTML will change, only bounded values.
   * @param item The item to determine if it was changed or not.
   */
  trackItems(item: ProjectSubTask) {
    return item ? item.id : undefined;
  }

  /**
   * Handles sub task selected event.
   * @param subTasks The list of sub tasks to notify selected.
   */
  selectSubTask(subTasks: ProjectSubTask[]) {
    this.notificationService.successInstant(
      this.translationService.translate('PROJECTS_MANAGEMENT.PROJECTS.NOTIFICATION_MESSAGE.PROJECT_SUB_TASK_SELECTED')
    );
    this.subTasks.emit(subTasks);
    if (this.closeOnSelect) {
      this.closeModal();
    }
  }

  /**
   * Applies the search filters and dispatches a search action.
   * @param page The current page number, default to `1`.
   */
  applyFiltersAndSearch(page: number = 1) {
    const { description } = this.searchForm.value;

    this.appSearchStore$.dispatch(
      new fromAppSearchStore.SearchSubTasks({
        description,
        projects: this.searchForm.get('projects').value,
        page,
      })
    );
  }

  /**
   * Opens the modal of the given templateRef.
   * @param modalRef The modal templateRef to be opened.
   */
  openModal(modalRef) {
    /**
     * Open the modal & listen for user press ESC key.
     */
    this.modal = this.modalService.open(modalRef, { size: 'xl' });
    this.modal.result.then(null, () => this.closeModal());
  }

  /**
   * Closes the currently opened modal.
   */
  closeModal() {
    this.modal?.dismiss();
    this.subTasks.emit([]);
    this.visibleChange.emit(false);
  }

  /**
   * Adds the newly selected projects the projects search list.
   * @param projects The list of newly selected projects to be added.
   */
  selectProjects(projects: Project[]) {
    const selectedIds: number[] = this.searchForm.get('projects').value;
    this.projects = [...this.projects.filter((project) => selectedIds.includes(project.id)), ...projects];
    this.searchForm.patchValue({ projects: this.projects.map((project) => project.id) });
  }
}
