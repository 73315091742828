import { Component, Input } from '@angular/core';

import { AssetSaleInvoice } from 'assets/models';

@Component({
  selector: 'app-journal-asset-sale-invoice-details',
  templateUrl: './journal-asset-sale-invoice-details.component.html',
  styles: [],
})
export class JournalAssetSaleInvoiceDetailsComponent {
  /**
   * Sets the current journal asset sale invoice details.
   */
  @Input() assetSaleInvoice: AssetSaleInvoice;
}
