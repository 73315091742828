import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { Observable, Subscription } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import * as fromProjectsManagementStore from 'projects-management/store';
import { Project } from 'projects-management/models';

@Component({
  selector: 'app-project-plans-list',
  templateUrl: './project-plans-list.component.html',
  styles: [],
})
export class ProjectPlansListComponent implements OnInit, OnDestroy {
  /**
   * Sets the project id.
   */
  @Input() projectId: number;

  /**
   * Gets or sets the currently selected project.
   */
  selectedProject: Project;

  /**
   * Get the project .
   */
  project$: Observable<Project>;

  /**
   * Gets or sets the id of the current selected file.
   */
  selectedProjectId: number;

  /**
   * The create project plans form.
   */
  createForm: FormGroup;

  /**
   * The set of subscriptions on this components,
   * these subscriptions must be unsubscribed before this component got destroyed.
   */
  subscriptions = new Subscription();

  /**
   * @param modalService The modal service.
   * @param projectsManagementStore$ The projects management-store.
   * @param translationService The translation service.
   * @param notificationService The notification service.
   */
  constructor(
    private modalService: NgbModal,
    private projectsManagementStore$: Store<fromProjectsManagementStore.ProjectsManagementState>
  ) {}

  ngOnInit(): void {
    this.init();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  /**
   * Initialize component data.
   */
  init() {
    this.project$ = this.projectsManagementStore$.pipe(select(fromProjectsManagementStore.getSelectedProject));
  }

  /**
   * Enables trackBy feature for *ngFor utility to track elements
   * depending on a comparer to reduce DOM change.
   * If the element is already exist then no HTML will change, only bounded values.
   * @param element The element to determine if it was changed or not.
   */
  trackElements(element: Project) {
    return element ? element.id : undefined;
  }

  /**
   * Opens the modal of the given templateRef.
   * @param modalRef The modal templateRef to be opened.
   */
  openModal(modalRef) {
    this.modalService.open(modalRef, { size: 'xl' });
  }

  /**
   * Closes the currently opened modal.
   */
  closeModal() {
    this.modalService.dismissAll();
  }
}
