import { remove } from 'lodash';

import { PrintsActionType, PrintsActions } from 'printing/store/actions';
import {
  StoresReportsActionType,
  FinancesReportsActionType,
  PurchasesReportsActionType,
  SalesReportsActionType,
  StoresReportsActions,
  FinancesReportsActions,
  PurchasesReportsActions,
  SalesReportsActions,
  UserReportsActionType,
  UserReportsActions,
} from 'reports/store/actions';
import { UserReportsState } from 'reports/store/states';

/**
 * The initial state from which the state starts.
 */
const initialState: UserReportsState = {
  data: [],
  error: null,
  isLoading: false,
  isLoadCompleted: false,
  isDeleting: false,
  isDeleteCompleted: false,
};

/**
 * The reducer function that is called in each action dispatch against the state.
 * @param state The current state.
 * @param action The action that will affect the state.
 */
export function userReportsReducer(
  state: UserReportsState = initialState,
  action:
    | UserReportsActions
    | StoresReportsActions
    | FinancesReportsActions
    | PurchasesReportsActions
    | SalesReportsActions
    | PrintsActions
): UserReportsState {
  switch (action.type) {
    //#region GET_MY_REPORTS

    case UserReportsActionType.GET_MY_REPORTS: {
      return {
        ...state,
        isLoading: true,
        isLoadCompleted: false,
        error: null,
      };
    }

    case UserReportsActionType.GET_MY_REPORTS_FAIL: {
      return {
        ...state,
        error: action.payload,
        isLoading: false,
        isLoadCompleted: false,
      };
    }

    case UserReportsActionType.GET_MY_REPORTS_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        error: null,
        isLoading: false,
        isLoadCompleted: true,
      };
    }

    //#endregion GET_MY_REPORTS

    //#region DELETE_USER_REPORT

    case UserReportsActionType.DELETE_USER_REPORT: {
      return {
        ...state,
        isDeleting: true,
        isDeleteCompleted: false,
        error: null,
      };
    }

    case UserReportsActionType.DELETE_USER_REPORT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isDeleting: false,
        isDeleteCompleted: false,
      };
    }

    case UserReportsActionType.DELETE_USER_REPORT_SUCCESS: {
      const reports = [...state.data];

      /* Remove the deleted report from the array. */
      remove(reports, (report) => report.id === action.payload.data.id);

      return {
        ...state,
        data: reports,
        error: null,
        isDeleting: false,
        isDeleteCompleted: true,
      };
    }
    //#endregion DELETE_USER_REPORT

    //#region REPORTS_SUCCESS

    case StoresReportsActionType.GET_PRODUCTS_LIST_REPORT_SUCCESS:
    case StoresReportsActionType.GET_PRODUCT_MOVEMENT_REPORT_SUCCESS:
    case StoresReportsActionType.GET_INCOMING_STOCK_REPORT_SUCCESS:
    case StoresReportsActionType.GET_OUTGOING_STOCK_REPORT_SUCCESS:
    case StoresReportsActionType.GET_TRANSFER_STOCK_REPORT_SUCCESS:
    case StoresReportsActionType.GET_STOCK_INVENTORY_REPORT_SUCCESS:
    case FinancesReportsActionType.GET_ACCOUNT_ANALYSIS_REPORT_SUCCESS:
    case FinancesReportsActionType.GET_BALANCE_SHEET_REPORT_SUCCESS:
    case FinancesReportsActionType.GET_BALANCE_SHEET_REPORT_SUCCESS:
    case FinancesReportsActionType.GET_BANK_ACCOUNT_SUMMARY_REPORT_SUCCESS:
    case FinancesReportsActionType.GET_INCOME_STATEMENT_REPORT_SUCCESS:
    case FinancesReportsActionType.GET_JOURNALS_REPORT_SUCCESS:
    case FinancesReportsActionType.GET_TAXES_REPORT_SUCCESS:
    case FinancesReportsActionType.GET_TRIAL_BALANCE_REPORT_SUCCESS:
    case PurchasesReportsActionType.GET_TOP_RETURNED_PURCHASE_PRODUCTS_REPORT_SUCCESS:
    case PurchasesReportsActionType.GET_PURCHASE_INVOICES_REPORT_SUCCESS:
    case PurchasesReportsActionType.GET_PURCHASE_ORDERS_REPORT_SUCCESS:
    case PurchasesReportsActionType.GET_PURCHASE_REQUESTS_REPORT_SUCCESS:
    case PurchasesReportsActionType.GET_PURCHASE_RETURNS_REPORT_SUCCESS:
    case PurchasesReportsActionType.GET_TOP_PURCHASED_PRODUCTS_REPORT_SUCCESS:
    case PurchasesReportsActionType.GET_VENDOR_SUMMARY_REPORT_SUCCESS:
    case PurchasesReportsActionType.GET_VENDORS_DEBTS_REPORT_SUCCESS:
    case PurchasesReportsActionType.GET_WORST_PURCHASED_PRODUCTS_REPORT_SUCCESS:
    case PurchasesReportsActionType.GET_WORST_RETURNED_PURCHASE_PRODUCTS_REPORT_SUCCESS:
    case SalesReportsActionType.GET_CUSTOMER_SUMMARY_REPORT_SUCCESS:
    case SalesReportsActionType.GET_CUSTOMERS_DEBTS_REPORT_SUCCESS:
    case SalesReportsActionType.GET_CUSTOMERS_PAYMENTS_REPORT_SUCCESS:
    case SalesReportsActionType.GET_CUSTOMERS_POINTS_REPORT_SUCCESS:
    case SalesReportsActionType.GET_GROSS_PROFIT_REPORT_SUCCESS:
    case SalesReportsActionType.GET_MAINTENANCE_STAGE_INVOICE_REPORT_SUCCESS:
    case SalesReportsActionType.GET_TOP_RETURNED_SALES_PRODUCTS_REPORT_SUCCESS:
    case SalesReportsActionType.GET_NET_SALES_REPORT_SUCCESS:
    case SalesReportsActionType.GET_PRODUCT_SALES_REPORT_SUCCESS:
    case SalesReportsActionType.GET_PRODUCT_SALES_RETURNS_REPORT_SUCCESS:
    case SalesReportsActionType.GET_PRODUCT_CLASSES_SALES_REPORT_SUCCESS:
    case SalesReportsActionType.GET_PRODUCTS_SALES_REPORT_SUCCESS:
    case SalesReportsActionType.GET_PRODUCTS_SALES_RETURNS_REPORT_SUCCESS:
    case SalesReportsActionType.GET_SALES_INVOICES_REPORT_SUCCESS:
    case SalesReportsActionType.GET_SALES_RETURNS_REPORT_SUCCESS:
    case SalesReportsActionType.GET_SALES_SUMMARY_REPORT_SUCCESS:
    case SalesReportsActionType.GET_SHIFT_SUMMARY_REPORT_SUCCESS:
    case SalesReportsActionType.GET_TOP_CUSTOMERS_REPORT_SUCCESS:
    case SalesReportsActionType.GET_TOP_SELLING_PRODUCTS_REPORT_SUCCESS:
    case SalesReportsActionType.GET_WORST_CUSTOMERS_REPORT_SUCCESS:
    case SalesReportsActionType.GET_SALES_PERSON_REPORT_SUCCESS:
    case SalesReportsActionType.GET_WORST_RETURNED_SALES_PRODUCTS_REPORT_SUCCESS:
    case SalesReportsActionType.GET_WORST_SELLING_PRODUCTS_REPORT_SUCCESS:

    case PrintsActionType.GET_INCOMING_STOCK_PRINT_SUCCESS:
    case PrintsActionType.GET_OUTGOING_STOCK_PRINT_SUCCESS:
    case PrintsActionType.GET_TRANSFER_STOCK_PRINT_SUCCESS:
    case PrintsActionType.GET_PURCHASE_REQUEST_PRINT_SUCCESS:
    case PrintsActionType.GET_PURCHASE_ORDER_PRINT_SUCCESS:
    case PrintsActionType.GET_PURCHASE_INVOICE_PRINT_SUCCESS:
    case PrintsActionType.GET_PURCHASE_RETURN_PRINT_SUCCESS:
    case PrintsActionType.GET_SALE_INVOICE_PRINT_SUCCESS:
    case PrintsActionType.GET_SALE_RETURN_PRINT_SUCCESS:
    case PrintsActionType.GET_SALES_QUOTATION_PRINT_SUCCESS: {
      return {
        ...state,
        data: [action.payload.data, ...state.data],
      };
    }

    //#endregion REPORTS_SUCCESS

    default:
      return state;
  }
}
