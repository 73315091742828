import { createSelector } from '@ngrx/store';

import { selectFinancesState } from 'finances/store/selectors/feature.selectors';
import { FinancesState, PettyCashState } from 'finances/store/states';

/* Selectors */

/**
 * Gets the petty Cash state.
 */
const selectPettyCashState = createSelector(selectFinancesState, (state: FinancesState) => state.pettyCash);

/**
 * Gets the list of loaded petty Cash.
 */
export const getPettyCash = createSelector(selectPettyCashState, (state: PettyCashState) => state.data);

/**
 * Gets the petty Cash pagination info.
 */
export const getPettyCashPaginationInfo = createSelector(
  selectPettyCashState,
  (state: PettyCashState) => state.paginationInfo
);

/**
 * Gets the error that occurred in the state.
 */
export const getPettyCashError = createSelector(selectPettyCashState, (state: PettyCashState) => state.error);

/**
 * Determines if there is a running search process.
 */
export const getPettyCashSearching = createSelector(selectPettyCashState, (state: PettyCashState) => state.isSearching);

/**
 * Determines if the last search process has been ended successfully.
 */
export const getPettyCashSearchCompleted = createSelector(
  selectPettyCashState,
  (state: PettyCashState) => state.isSearchCompleted
);

/**
 * Gets the selected petty cash.
 */
export const getSelectedPettyCash = createSelector(
  selectPettyCashState,
  (state: PettyCashState) => state.selectedPettyCash
);

/**
 * Determines if there is a running find-petty-cash process.
 */
export const getPettyCashFinding = createSelector(selectPettyCashState, (state: PettyCashState) => state.isFinding);

/**
 * Determines if the last find-petty-cash process has been ended successfully.
 */
export const getPettyCashFindCompleted = createSelector(
  selectPettyCashState,
  (state: PettyCashState) => state.isFindCompleted
);

/**
 * Determines if there is a running create process.
 */
export const getSelectedPettyCashCreating = createSelector(
  selectPettyCashState,
  (state: PettyCashState) => state.isCreating
);

/**
 * Determines if the last create process has been ended successfully.
 */
export const getSelectedPettyCashCreateCompleted = createSelector(
  selectPettyCashState,
  (state: PettyCashState) => state.isCreateCompleted
);

/**
 * Determines if there is a running update process.
 */
export const getSelectedPettyCashUpdating = createSelector(
  selectPettyCashState,
  (state: PettyCashState) => state.isUpdating
);

/**
 * Determines if the last update process has been ended successfully.
 */
export const getSelectedPettyCashUpdateCompleted = createSelector(
  selectPettyCashState,
  (state: PettyCashState) => state.isUpdateCompleted
);

/**
 * Determines if there is a running block process.
 */
export const getSelectedPettyCashBlocking = createSelector(
  selectPettyCashState,
  (state: PettyCashState) => state.isBlocking
);

/**
 * Determines if the last block process has been ended successfully.
 */
export const getSelectedPettyCashBlockCompleted = createSelector(
  selectPettyCashState,
  (state: PettyCashState) => state.isBlockCompleted
);

/**
 * Determines if there is a running activate process.
 */
export const getSelectedPettyCashActivating = createSelector(
  selectPettyCashState,
  (state: PettyCashState) => state.isActivating
);

/**
 * Determines if the last activate process has been ended successfully.
 */
export const getSelectedPettyCashActivateCompleted = createSelector(
  selectPettyCashState,
  (state: PettyCashState) => state.isActivateCompleted
);

/**
 * Determines if there is a running delete process.
 */
export const getSelectedPettyCashDeleting = createSelector(
  selectPettyCashState,
  (state: PettyCashState) => state.isDeleting
);

/**
 * Determines if the last delete process has been ended successfully.
 */
export const getSelectedPettyCashDeleteCompleted = createSelector(
  selectPettyCashState,
  (state: PettyCashState) => state.isDeleteCompleted
);
