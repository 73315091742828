import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';

import { AppHttpResponse } from 'shared';
import { Location } from 'stores/models';

/**
 * The locations services includes functionality to search a location.
 */
@Injectable()
export class LocationsService {
  /**
   * The relative route for the locations.
   *
   * No leading or trailing slashes required.
   */
  private locationsApi = 'stores/locations';

  constructor(private http: HttpClient) {}

  /**
   * Searches in the locations by name.
   * @param name The name of the location.
   * @param regions The regions of the location.
   * @param sectors The sectors of the location.
   * @param locationTypes The location types of the location.
   * @param page The current pagination page number.
   * @param pageSize The maximum number of locations allowed per one pagination page.
   */
  public search(
    name: string,
    regions: number[],
    sectors: number[],
    locationTypes: number[],
    page: number,
    pageSize: number
  ): Observable<AppHttpResponse<Location[]>> {
    const params = new HttpParams()
      .set('name', name)
      .set('regions', regions.join(','))
      .set('sectors', sectors.join(','))
      .set('locationTypes', locationTypes.join(','))
      .set('page', page.toString())
      .set('pageSize', pageSize.toString());
    return this.http.get<AppHttpResponse<Location[]>>(`${this.locationsApi}`, { params });
  }
}
