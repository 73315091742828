import { Injectable } from '@angular/core';

import { Effect, ofType, Actions } from '@ngrx/effects';
import { switchMap, map, catchError, tap, debounceTime } from 'rxjs/operators';
import { of } from 'rxjs';

import { NotificationService, StatusCode, PAGINATION, TranslationService } from 'shared';
import { CustomerAssetsService } from 'sales/services';
import {
  CustomerAssetsActionType,
  CreateCustomerAsset,
  CreateCustomerAssetSuccess,
  CreateCustomerAssetFail,
  DeleteCustomerAsset,
  DeleteCustomerAssetSuccess,
  DeleteCustomerAssetFail,
  FindCustomerAsset,
  FindCustomerAssetSuccess,
  FindCustomerAssetFail,
  UpdateCustomerAsset,
  UpdateCustomerAssetFail,
  UpdateCustomerAssetSuccess,
  SearchCustomersAssets,
  SearchCustomersAssetsSuccess,
  SearchCustomersAssetsFail,
} from 'sales/store/actions';

@Injectable()
export class CustomerAssetsEffects {
  constructor(
    private actions$: Actions,
    private customerAssetsService: CustomerAssetsService,
    private notificationService: NotificationService,
    private translationService: TranslationService
  ) {}

   /* ========================= SEARCH_CUSTOMERS_ASSETS =================================== */
   @Effect()
   search$ = this.actions$.pipe(
     ofType(CustomerAssetsActionType.SEARCH_CUSTOMERS_ASSETS),
     debounceTime(300),
     switchMap(({ payload }: SearchCustomersAssets) => {
       return this.customerAssetsService
         .searchCustomersAssets(payload.customers, payload.page, PAGINATION.CustomersAssets)
         .pipe(
           map((response) => new SearchCustomersAssetsSuccess(response)),
           catchError((error) => of(new SearchCustomersAssetsFail(error)))
         );
     })
   );

  /* ========================= FIND_CUSTOMER_ASSET =================================== */
  @Effect()
  find$ = this.actions$.pipe(
    ofType(CustomerAssetsActionType.FIND_CUSTOMER_ASSET),
    switchMap((action: FindCustomerAsset) =>
      this.customerAssetsService.findById(action.payload).pipe(
        map((response) => new FindCustomerAssetSuccess(response)),
        catchError((error) => of(new FindCustomerAssetFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  findFail$ = this.actions$.pipe(
    ofType(CustomerAssetsActionType.FIND_CUSTOMER_ASSET_FAIL),
    tap((action: FindCustomerAssetFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate('SALES.CUSTOMER_ASSETS.CUSTOMER_ASSET_NOT_FOUND')
        );
      }
    })
  );

  /* ========================= CREATE_CUSTOMER_ASSET =================================== */
  @Effect()
  create$ = this.actions$.pipe(
    ofType(CustomerAssetsActionType.CREATE_CUSTOMER_ASSET),
    switchMap((action: CreateCustomerAsset) =>
      this.customerAssetsService.create(action.payload).pipe(
        map((response) => new CreateCustomerAssetSuccess(response)),
        catchError((error) => of(new CreateCustomerAssetFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  createSuccess$ = this.actions$.pipe(
    ofType(CustomerAssetsActionType.CREATE_CUSTOMER_ASSET_SUCCESS),
    tap((action: CreateCustomerAssetSuccess) => {
      this.notificationService.success(this.translationService.translate('SALES.CUSTOMER_ASSETS.CUSTOMER_ASSET_ADDED'));
    })
  );

  @Effect({ dispatch: false })
  createFail$ = this.actions$.pipe(
    ofType(CustomerAssetsActionType.CREATE_CUSTOMER_ASSET_FAIL),
    tap((action: CreateCustomerAssetFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );

  /* ========================= DELETE_CUSTOMER_ASSET =================================== */
  @Effect()
  delete$ = this.actions$.pipe(
    ofType(CustomerAssetsActionType.DELETE_CUSTOMER_ASSET),
    switchMap((action: DeleteCustomerAsset) =>
      this.customerAssetsService.delete(action.payload).pipe(
        map((response) => new DeleteCustomerAssetSuccess(response)),
        catchError((error) => of(new DeleteCustomerAssetFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  deleteSuccess$ = this.actions$.pipe(
    ofType(CustomerAssetsActionType.DELETE_CUSTOMER_ASSET_SUCCESS),
    tap((action: DeleteCustomerAssetSuccess) => {
      this.notificationService.success(
        this.translationService.translate('SALES.CUSTOMER_ASSETS.CUSTOMER_ASSET_DELETED')
      );
    })
  );

  @Effect({ dispatch: false })
  deleteFail$ = this.actions$.pipe(
    ofType(CustomerAssetsActionType.DELETE_CUSTOMER_ASSET_FAIL),
    tap((action: DeleteCustomerAssetFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate('SALES.CUSTOMER_ASSETS.CUSTOMER_ASSET_NOT_FOUND')
        );
      } else if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );

  /* ========================= UPDATE_CUSTOMER_ASSET =================================== */
  @Effect()
  update$ = this.actions$.pipe(
    ofType(CustomerAssetsActionType.UPDATE_CUSTOMER_ASSET),
    switchMap((action: UpdateCustomerAsset) =>
      this.customerAssetsService.update(action.payload).pipe(
        map((response) => new UpdateCustomerAssetSuccess(response)),
        catchError((error) => of(new UpdateCustomerAssetFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  updateSuccess$ = this.actions$.pipe(
    ofType(CustomerAssetsActionType.UPDATE_CUSTOMER_ASSET_SUCCESS),
    tap((action: UpdateCustomerAssetSuccess) => {
      this.notificationService.success(
        this.translationService.translate('SALES.CUSTOMER_ASSETS.CUSTOMER_ASSET_UPDATED')
      );
    })
  );

  @Effect({ dispatch: false })
  updateFail$ = this.actions$.pipe(
    ofType(CustomerAssetsActionType.UPDATE_CUSTOMER_ASSET_FAIL),
    tap((action: UpdateCustomerAssetFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate('SALES.CUSTOMER_ASSETS.CUSTOMER_ASSET_NOT_FOUND')
        );
      } else if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );
}
