import { Injectable } from '@angular/core';

import { of } from 'rxjs';
import { switchMap, map, catchError, debounceTime } from 'rxjs/operators';
import { Effect, ofType, Actions } from '@ngrx/effects';

import { PAGINATION } from 'shared';
import { CustomersService } from 'app-search/services';
import {
  CustomersActionType,
  SearchCustomers,
  SearchCustomersFail,
  SearchCustomersSuccess,
} from 'app-search/store/actions';

@Injectable()
export class CustomersEffects {
  constructor(private actions$: Actions, private customersService: CustomersService) {}

  /* ========================= SEARCH_CUSTOMERS =================================== */
  @Effect()
  search$ = this.actions$.pipe(
    ofType(CustomersActionType.SEARCH_CUSTOMERS),
    debounceTime(300),
    switchMap((action: SearchCustomers) =>
      this.customersService.search(action.payload?.name ?? '', action.payload.page, PAGINATION.Customers).pipe(
        map((response) => new SearchCustomersSuccess(response)),
        catchError((error) => of(new SearchCustomersFail(error)))
      )
    )
  );
}
