import { PurchaseRequestReceiveType } from 'lookups/models';

/**
 * Represents the PurchaseRequestReceiveTypes state.
 */
export class PurchaseRequestReceiveTypeState {
  /**
   * The list of PurchaseRequestReceiveTypes.
   */
  public data: PurchaseRequestReceiveType[];

  /**
   * An error that may resulting during processing some actions.
   */
  public error: any;

  /**
   * Determines if there is a running load process.
   */
  public isLoading: boolean;

  /**
   * Determines if the last load process has been ended successfully.
   */
  public isLoaded: boolean;
}
