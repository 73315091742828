import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';

import { AppHttpResponse } from 'shared';
import { CreateJournalInput, Journal } from 'finances/models';

/**
 * The journals services includes functionality to create, search and findById for an journal.
 */
@Injectable()
export class JournalsService {
  /**
   * The relative route for the journals.
   *
   * No leading or trailing slashes required.
   */
  private journalsApi = 'finances/journals';

  constructor(private http: HttpClient) {}

  /**
   * Creates a new journal from the provided data.
   * @param data The new journal data.
   */
  public create(data: CreateJournalInput): Observable<AppHttpResponse<Journal>> {
    return this.http.post<AppHttpResponse<Journal>>(`${this.journalsApi}`, data);
  }

  /**
   * Searches in the journals by accounts ,fromDate and toDate.
   * @param accounts The accounts of the journal.
   * @param journalNum The reference number of the journal.
   * @param fromDate The date of the journal search starts from.
   * @param toDate The date of the journal search ends to.
   * @param page The current pagination page number.
   * @param pageSize The maximum number of journals allowed per one pagination page.
   */
  public search(
    journalTypes: number[],
    journalNum: number,
    fromDate: Date,
    toDate: Date,
    page: number,
    pageSize: number
  ): Observable<AppHttpResponse<Journal[]>> {
    const params = new HttpParams()
      .set('journalTypes', journalTypes.join(','))
      .set('journalNum', journalNum?.toString() ?? '')
      .set('fromDate', fromDate?.toISOString())
      .set('toDate', toDate?.toISOString())
      .set('page', page.toString())
      .set('pageSize', pageSize.toString());
    return this.http.get<AppHttpResponse<Journal[]>>(`${this.journalsApi}`, { params });
  }

  /**
   * Finds the journal with the given id.
   * @param id The id of the journal.
   */
  public findById(id: number): Observable<AppHttpResponse<Journal>> {
    return this.http.get<AppHttpResponse<Journal>>(`${this.journalsApi}/${id}`);
  }
}
