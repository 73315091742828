<div class="row" *ngIf="vendorLog">
  <div class="col-md-12">
    <!-- journal vendor log details starts -->
    <div class="card-header mb-3">
      <h4 class="card-title">{{ 'FINANCES.JOURNALS.JOURNAL_DATA.VENDOR_DATA' | translate }}</h4>
    </div>
    <div class="card-content">
      <div class="px-3">
        <div class="row">
          <div class="form-group col-md-6">
            <label for="name">{{ 'FINANCES.JOURNALS.JOURNAL_DATA.VENDOR_NAME' | translate }}</label>
            <input
              type="text"
              id="name"
              class="form-control round"
              [value]="vendorLog.vendor.name | localize: vendorLog.vendor.nameEn | async"
              readonly
            />
          </div>
          <div class="form-group col-md-6">
            <label for="vendorLogType"> {{ 'FINANCES.JOURNALS.JOURNAL_DATA.LOG_TYPE' | translate }}</label>
            <input
              type="text"
              id="vendorLogType"
              class="form-control round"
              [value]="vendorLog.vendorLogType.name | localize: vendorLog.vendorLogType.nameEn | async"
              readonly
            />
          </div>
          <div class="form-group col-md-6">
            <label for="value">{{ 'FINANCES.JOURNALS.JOURNAL_DATA.VALUE' | translate }}</label>
            <input type="text" id="value" class="form-control round" [value]="vendorLog.value" readonly />
          </div>
          <div class="form-group col-md-6">
            <label for="transactionDate">{{ 'FINANCES.JOURNALS.JOURNAL_DATA.JOURNAL_DATE' | translate }}</label>
            <input
              type="text"
              id="transactionDate"
              class="form-control round"
              [value]="vendorLog.transactionDate | date: 'datetime'"
              readonly
            />
          </div>
          <div class="form-group col-md-6">
            <label for="createdAt">{{ 'SHARED.SEARCH.CREATED_AT' | translate }}</label>
            <input
              type="text"
              id="createdAt"
              class="form-control round"
              [value]="vendorLog.createdAt | date: 'datetime'"
              readonly
            />
          </div>
          <div class="form-group col-md-6">
            <label for="user">{{ 'FINANCES.JOURNALS.JOURNAL_DATA.USER_CREATE' | translate }}</label>
            <input type="text" id="user" class="form-control round" [value]="vendorLog.user.name" readonly />
          </div>
          <div class="form-group col-md-12">
            <label for="notes">{{ 'FINANCES.JOURNALS.JOURNAL_DATA.NOTES' | translate }}</label>
            <textarea
              id="notes"
              class="form-control round"
              [innerText]="vendorLog.notes"
              [placeholder]="'FINANCES.JOURNALS.JOURNAL_DATA.NOTES_NOT_FOUND' | translate"
              readonly
            ></textarea>
          </div>
        </div>
      </div>
    </div>
    <!-- journal vendor log details ends -->
  </div>
</div>
