import { createSelector } from '@ngrx/store';

import { selectLookupsState } from 'lookups/store/selectors/feature.selectors';
import { LookupsState, LoanSettlementTypeState } from 'lookups/store/states';

/**
 * Gets the loan settlement type state.
 */
const selectLoanSettlementTypes = createSelector(
  selectLookupsState,
  (state: LookupsState) => state.loanSettlementTypes
);

/**
 * Gets the list of loaded loan settlement types.
 */
export const getLoanSettlementTypes = createSelector(
  selectLoanSettlementTypes,
  (state: LoanSettlementTypeState) => state.data
);

/**
 * Gets the error that occurred in the state.
 */
export const getLoanSettlementTypesError = createSelector(
  selectLoanSettlementTypes,
  (state: LoanSettlementTypeState) => state.error
);

/**
 * Determines if there is a running load process.
 */
export const getLoanSettlementTypesIsLoading = createSelector(
  selectLoanSettlementTypes,
  (state: LoanSettlementTypeState) => state.isLoading
);

/**
 * Determines if the last load process has been ended successfully.
 */
export const getLoanSettlementTypesIsLoaded = createSelector(
  selectLoanSettlementTypes,
  (state: LoanSettlementTypeState) => state.isLoaded
);
