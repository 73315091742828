<form [formGroup]="form" class="pos">
  <div class="card">
    <div class="card-content">
      <div class="px-3 pt-3">
        <div class="row">
          <div class="col-12 col-md-6">
            <app-point-of-sale-invoice-products-search
              (products)="newProducts = $event"
            ></app-point-of-sale-invoice-products-search>
          </div>
          <div class="col-12 col-md-6">
            <div class="row mb-1">
              <div class="col-12">
                <app-point-of-sale-invoice-products-form
                  [formArray]="productsForm"
                  [newProducts]="newProducts"
                  [newProductItems]="newProductItems"
                  [selectedLocationId]="defaultLocationId"
                  [(touchKeyboardConfig)]="productsFormTouchKeyboardConfig"
                ></app-point-of-sale-invoice-products-form>
              </div>

              <div class="col-12 col-md-auto">
                <app-touch-keyboard [config]="productsFormTouchKeyboardConfig"></app-touch-keyboard>
              </div>
              <div class="col">
                <div class="col d-flex justify-content-start" *ngIf="form.value.customerId">
                  <p class="font-weight-bold">
                    {{ 'SALES.INVOICES.SALE_INVOICE_DATA.CUSTOMER_POINTS' | translate }} ({{
                      selectedCustomer?.points
                    }})
                  </p>
                  <div class="form-group col-auto mb-0">
                    <button
                      type="button"
                      class="btn btn-raised btn-primary round"
                      [ngbTooltip]="'SALES.INVOICES.SALE_INVOICE_DATA.UPDATE_POINTS' | translate"
                      (click)="updateCustomerPoints()"
                    >
                      <i class="fa fa-refresh"></i>
                    </button>
                    <button
                      type="button"
                      class="btn btn-raised btn-primary round mx-1"
                      (click)="openExchangePoints(exchangePointsModalRef)"
                    >
                      <span>{{ 'SALES.INVOICES.SALE_INVOICE_DATA.EXCHANGE_POINTS' | translate }}</span>
                    </button>
                  </div>
                </div>
                <div class="col">
                  <div class="tools">
                    <span class="mx-1 mb-2">
                      <ng-select
                        class="round orderType"
                        formControlName="saleInvoiceOrderTypeId"
                        labelForId="saleInvoiceOrderTypeId"
                        searchable="true"
                        [placeholder]="
                          'SALES.INVOICES.SALE_INVOICE_DATA.SALE_INVOICE_ORDER_TYPE_PLACEHOLDER' | translate
                        "
                        [notFoundText]="'SHARED.SEARCH.SEARCH_NOT_FOUND' | translate"
                      >
                        <ng-option
                          *ngFor="let saleInvoiceOrderType of saleInvoiceOrderTypes$ | async"
                          [value]="saleInvoiceOrderType.id"
                        >
                          {{ saleInvoiceOrderType.name | localize: saleInvoiceOrderType.nameEn | async }}
                        </ng-option>
                      </ng-select>
                    </span>
                    <span
                      class="mx-1 mb-2"
                      *ngIf="
                        this.form?.value?.saleInvoiceOrderTypeId === dineInOrderTypeId ||
                        this.form?.value?.saleInvoiceOrderTypeId === familyDineInOrderTypeId
                      "
                    >
                      <ng-select
                        class="round customer"
                        formControlName="boardId"
                        searchable="true"
                        [placeholder]="'SALES.INVOICES.POS.POS_DATA.BOARDS_PLACEHOLDER' | translate"
                        [notFoundText]="'SHARED.SEARCH.SEARCH_NOT_FOUND' | translate"
                        (open)="boardsListVisibility = true"
                      >
                        <ng-option *ngFor="let board of boards" [value]="board.id">
                          {{ board.description }}
                        </ng-option>
                      </ng-select>
                    </span>
                    <span class="mx-1 mb-2">
                      <ng-select
                        class="round customer"
                        formControlName="customerId"
                        searchable="true"
                        [placeholder]="'SALES.INVOICES.POS.POS_DATA.CUSTOMER_PLACEHOLDER' | translate"
                        [notFoundText]="'SHARED.SEARCH.SEARCH_NOT_FOUND' | translate"
                        (open)="customersListVisibility = true"
                      >
                        <ng-option *ngFor="let customer of customers" [value]="customer.id">
                          {{ customer.name | localize: customer.nameEn | async }}
                        </ng-option>
                      </ng-select>
                    </span>
                    <button type="button" class="mx-1 btn btn-raised btn-success round" (click)="openPayments()">
                      <i class="fa fa-usd"></i>
                      <span>{{ 'SALES.INVOICES.POS.POS_DATA.PAYMENT' | translate }}</span>
                    </button>
                    <button
                      *ngIf="newlyCreatedInvoiceId"
                      type="button"
                      class="mx-1 btn btn-raised btn-success round"
                      [routerLink]="['/sales/invoices/view', newlyCreatedInvoiceId]"
                    >
                      <i class="fa fa-eye"></i>
                      <span>{{ 'SALES.INVOICES.POS.POS_DATA.VIEW_LAST_INVOICE' | translate }}</span>
                    </button>
                    <button type="button" class="mx-1 btn btn-raised btn-warning round" (click)="parkInvoice()">
                      <i class="fa fa-pause"></i>
                      <span>{{ 'SALES.INVOICES.POS.POS_DATA.PARK' | translate }}</span>
                    </button>
                    <button
                      type="button"
                      class="mx-1 btn btn-raised btn-warning round"
                      (click)="openParkedSaleInvoices()"
                    >
                      <i class="fa fa-pause"></i>
                      <span
                        >{{ 'SALES.INVOICES.POS.PARKED_INVOICES_TITLE' | translate }} ({{
                          (parkedSaleInvoices$ | async).length
                        }})</span
                      >
                    </button>
                    <button type="button" class="mx-1 btn btn-raised btn-danger round" (click)="openReset()">
                      <i class="fa fa-trash"></i>
                      <span>{{ 'SHARED.CONFIRM.CANCEL' | translate }}</span>
                    </button>
                    <button
                      type="button"
                      class="mx-1 btn btn-raised btn-success round"
                      *ngIf="[Claims.CREATE_SALE_RETURN] | authorize | async"
                      routerLink="/sales/returns/create"
                    >
                      <i class="fa fa-plus"></i>
                      <span>{{ 'SALES.INVOICES.POS.POS_DATA.SALES_RETURN' | translate }}</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>

<!-- payments modal starts -->
<ng-template #paymentsModalRef let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title">
      {{ 'SALES.INVOICES.POS.POS_DATA.INVOICE_PAYMENT' | translate }}
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="d()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form [formGroup]="form">
      <div class="row d-flex flex-row justify-content-between">
        <div class="col-md-6">
          <div class="row">
            <div class="form-group col-12">
              <label for="cashValue" class="text-success">
                <i class="fa fa-money"></i>
                <span class="mx-1">{{ 'SALES.INVOICES.POS.POS_DATA.CASH_PAYMENT' | translate }}</span>
              </label>
              <input
                id="cashValue"
                type="text"
                autocomplete="off"
                [mask]="DECIMAL_MASK"
                thousandSeparator=","
                name="cashValue"
                class="form-control round"
                [placeholder]="'SALES.INVOICES.POS.POS_DATA.CASH_PAYMENT_PLACEHOLDER' | translate"
                formControlName="cashValue"
                ngbAutofocus
                appTouchKeyboardTarget
                [touchKeyboardConfig]="paymentsTouchKeyboardConfig"
              />
            </div>
            <div class="form-group col-12">
              <label for="electronicValue" class="text-primary">
                <i class="fa fa-cc-visa"></i>
                <span class="mx-1">{{ 'SALES.INVOICES.POS.POS_DATA.VISA_PAYMENT' | translate }}</span>
              </label>
              <input
                type="text"
                autocomplete="off"
                [mask]="DECIMAL_MASK"
                thousandSeparator=","
                name="electronicValue"
                class="form-control round"
                [placeholder]="'SALES.INVOICES.POS.POS_DATA.VISA_PAYMENT_PLACEHOLDER' | translate"
                formControlName="electronicValue"
                appTouchKeyboardTarget
                [touchKeyboardConfig]="paymentsTouchKeyboardConfig"
              />
            </div>
            <div class="col-12">
              <div class="d-flex justify-content-between">
                <label class="text-success">
                  <span>{{ 'SALES.INVOICES.POS.POS_DATA.INVOICE_VALUE' | translate }}</span>
                  <span class="mx-1">{{ invoiceNet | toDecimal }}</span>
                </label>
                <label class="text-success">
                  <i class="fa fa-check"></i>
                  <span class="mx-1">{{ 'SALES.INVOICES.POS.POS_DATA.PAID' | translate }}</span>
                  <span class="mx-1">{{ totalPayments | toDecimal }}</span>
                </label>
                <label class="text-danger">
                  <i class="fa fa-close"></i>
                  <span class="mx-1">{{ 'SALES.INVOICES.POS.POS_DATA.REMAINING' | translate }}</span>
                  <span class="mx-1">{{ invoiceNet - totalPayments | toDecimal }}</span>
                </label>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="row d-flex flex-row justify-content-between">
            <div class="col-md-6">
              <app-touch-keyboard [config]="paymentsTouchKeyboardConfig"></app-touch-keyboard>
            </div>
            <div class="col-md-6">
              <div class="d-flex flex-column">
                <button type="button" class="btn btn-raised btn-success round" (click)="payCash()">
                  <i class="fa fa-money"></i>
                  <span class="mx-1">{{ 'SALES.INVOICES.POS.POS_DATA.COMPLETE_CASH_PAYMENT' | translate }}</span>
                </button>
                <button type="button" class="btn btn-raised btn-primary round" (click)="payVisa()">
                  <i class="fa fa-cc-visa"></i>
                  <span class="mx-1">{{ 'SALES.INVOICES.POS.POS_DATA.COMPLETE_VISA_PAYMENT' | translate }}</span>
                </button>
                <button
                  type="button"
                  class="btn btn-raised btn-success round"
                  (click)="submit()"
                  [disabled]="isCreating$ | async"
                >
                  <i class="fa fa-file-text"></i>
                  <span class="mx-1">{{ 'SALES.INVOICES.POS.POS_DATA.RECEIPT' | translate }}</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <div class="modal-actions">
      <button type="button" class="btn btn-outline-dark round" (click)="c()">
        <i class="fa fa-times"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.CLOSE' | translate }}</span>
      </button>
    </div>
  </div>
</ng-template>
<!-- payments modal ends -->

<!-- parked sale invoices modal starts -->
<ng-template #parkedSaleInvoicesModalRef let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title text-primary">
      {{ 'SALES.INVOICES.POS.PARKED_INVOICES_TITLE' | translate }}
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="d()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-12">
        <div class="table-responsive">
          <table class="table text-center">
            <thead>
              <tr>
                <th scope="col"><i class="fa fa-list-ol"></i></th>
                <th scope="col">
                  <i class="fa fa-user"></i>
                  {{ 'SALES.INVOICES.POS.POS_DATA.CUSTOMER' | translate }}
                </th>
                <th scope="col">
                  <i class="fa fa-usd"></i>
                  {{ 'SALES.INVOICES.POS.POS_DATA.PRODUCTS_TOTAL' | translate }}
                </th>
                <th scope="col">
                  <i class="fa fa-minus-circle"></i>
                  {{ 'SALES.INVOICES.POS.POS_DATA.DISCOUNT_TOTAL' | translate }}
                </th>
                <th scope="col">
                  <i class="fa fa-percent"></i>
                  {{ 'SALES.INVOICES.POS.POS_DATA.TAX' | translate }}
                </th>
                <th scope="col">
                  <i class="fa fa-usd"></i>
                  {{ 'SALES.INVOICES.POS.POS_DATA.VALUE' | translate }}
                </th>
                <th scope="col">
                  <i class="fa fa-cogs"></i>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let invoice of parkedSaleInvoices$ | async; let index = index">
                <th scope="row">{{ index + 1 }}</th>
                <td>{{ invoice.customer?.name | localize: invoice.customer?.nameEn | async }}</td>
                <td>
                  {{ invoice.productsTotal | toDecimal }}
                </td>
                <td>{{ invoice.discount | toDecimal }}</td>
                <td>{{ invoice.tax | toDecimal }}</td>
                <td>{{ invoice.net | toDecimal }}</td>
                <td>
                  <a
                    class="primary p-0"
                    [ngbTooltip]="'SALES.INVOICES.POS.POS_DATA.SELECT_INVOICE' | translate"
                    (click)="selectParkedSaleInvoice(invoice); c()"
                  >
                    <i class="fa fa-folder-open-o font-medium-3 mr-2"></i>
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="modal-actions">
      <button type="button" class="btn btn-outline-dark round" (click)="c()">
        <i class="fa fa-times"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.CANCEL' | translate }}</span>
      </button>
    </div>
  </div>
</ng-template>
<!-- parked sale invoices modal ends -->

<!-- confirm reset invoice modal starts -->
<ng-template #resetModalRef let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title text-danger" id="modal-basic-title">
      {{ 'SALES.INVOICES.POS.CONFIRM_RESET_INVOICE_TITLE' | translate }}
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="d()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p class="text-danger">{{ 'SALES.INVOICES.POS.CONFIRM_RESET_INVOICE_MESSAGE' | translate }}</p>
  </div>
  <div class="modal-footer">
    <div class="modal-actions">
      <button type="button" class="btn btn-raised btn-danger round" (click)="c(); reset()" ngbAutofocus>
        <i class="fa fa-check"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.YES' | translate }}</span>
      </button>
      <button type="button" class="btn btn-outline-dark round" (click)="c()">
        <i class="fa fa-times"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.CANCEL' | translate }}</span>
      </button>
    </div>
  </div>
</ng-template>
<!-- confirm reset modal ends -->

<!-- confirm exchange points modal starts -->
<ng-template #exchangePointsModalRef let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
      {{ 'SALES.INVOICES.POS.CONFIRM_EXCHANGE_POINTS_TITLE' | translate }}
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="d()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-md-12">
        <form [formGroup]="exchangePointsForm">
          <div class="row">
            <div class="form-group col-md-12">
              <label for="currentPointsCount">{{
                'SALES.INVOICES.POS.POS_DATA.CURRENT_POINTS_COUNT' | translate
              }}</label>
              <input
                type="text"
                [mask]="DECIMAL_MASK"
                thousandSeparator=","
                minLength="0"
                id="currentPointsCount"
                class="form-control round"
                formControlName="currentPointsCount"
                readonly
                [placeholder]="'SALES.INVOICES.POS.POS_DATA.CURRENT_POINTS_COUNT_PLACEHOLDER' | translate"
              />
            </div>
            <div class="form-group col-md-12">
              <label for="exchangePoints">{{ 'SALES.INVOICES.POS.POS_DATA.EXCHANGE_POINTS' | translate }}</label>
              <input
                type="text"
                [mask]="DECIMAL_MASK"
                thousandSeparator=","
                minLength="0"
                id="exchangePoints"
                class="form-control round"
                formControlName="exchangePoints"
                (input)="getValueOfExchangePoints($event.target.value)"
                [placeholder]="'SALES.INVOICES.POS.POS_DATA.EXCHANGE_POINTS_PLACEHOLDER' | translate"
              />
            </div>
            <div class="form-group col-md-12">
              <label for="pointsValue">{{ 'SALES.INVOICES.POS.POS_DATA.POINTS_VALUE' | translate }}</label>
              <input
                type="text"
                [mask]="DECIMAL_MASK"
                thousandSeparator=","
                minLength="0"
                id="pointsValue"
                class="form-control round"
                formControlName="pointsValue"
                readonly
                [placeholder]="'SALES.INVOICES.POS.POS_DATA.POINTS_VALUE_PLACEHOLDER' | translate"
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="modal-actions">
      <button type="button" class="btn btn-raised btn-success round" (click)="confirmExchangePoints()" ngbAutofocus>
        <i class="fa fa-check"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.EXCHANGE' | translate }}</span>
      </button>
      <button type="button" class="btn btn-outline-dark round" (click)="c()">
        <i class="fa fa-times"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.CANCEL' | translate }}</span>
      </button>
    </div>
  </div>
</ng-template>
<!-- confirm exchange points modal ends -->

<!-- customers search starts -->
<app-customers-search
  onlyActive="true"
  closeOnSelect="true"
  isPointOfSaleInvoice="true"
  (customers)="selectCustomer($event)"
  [(visible)]="customersListVisibility"
>
</app-customers-search>
<!-- customers search ends -->

<!-- boards search starts -->
<app-boards-search
  onlyActive="true"
  closeOnSelect="true"
  isPointOfSaleInvoice="true"
  (boards)="selectBoard($event)"
  [(visible)]="boardsListVisibility"
>
</app-boards-search>
<!-- boards search ends -->
