import { Injectable } from '@angular/core';

import { of } from 'rxjs';
import { switchMap, map, catchError, tap } from 'rxjs/operators';
import { Effect, ofType, Actions } from '@ngrx/effects';

import { NotificationService, StatusCode, PAGINATION, TranslationService } from 'shared';
import { PettyCashReductionsService } from 'finances/services';
import {
  SearchPettyCashReduction,
  SearchPettyCashReductionSuccess,
  SearchPettyCashReductionFail,
  FindPettyCashReduction,
  FindPettyCashReductionSuccess,
  FindPettyCashReductionFail,
  CreatePettyCashReductionSuccess,
  CreatePettyCashReductionFail,
  CreatePettyCashReduction,
  PettyCashReductionActionType,
} from 'finances/store/actions';

@Injectable()
export class PettyCashReductionEffects {
  constructor(
    private actions$: Actions,
    private pettyCashReductionService: PettyCashReductionsService,
    private notificationService: NotificationService,
    private translationService: TranslationService
  ) {}

  /* ========================= SEARCH_PETTY_CASH_REDUCTION =================================== */
  @Effect()
  search$ = this.actions$.pipe(
    ofType(PettyCashReductionActionType.SEARCH_PETTY_CASH_REDUCTION),
    switchMap(({ payload }: SearchPettyCashReduction) =>
      this.pettyCashReductionService
        .search(payload?.pettyCash, payload.fromDate, payload.toDate, payload.page, PAGINATION.PettyCashReduction)
        .pipe(
          map((response) => new SearchPettyCashReductionSuccess(response)),
          catchError((error) => of(new SearchPettyCashReductionFail(error)))
        )
    )
  );

  /* ========================= FIND_PETTY_CASH_REDUCTION =================================== */
  @Effect()
  find$ = this.actions$.pipe(
    ofType(PettyCashReductionActionType.FIND_PETTY_CASH_REDUCTION),
    switchMap((action: FindPettyCashReduction) =>
      this.pettyCashReductionService.findById(action.payload).pipe(
        map((response) => new FindPettyCashReductionSuccess(response)),
        catchError((error) => of(new FindPettyCashReductionFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  findFail$ = this.actions$.pipe(
    ofType(PettyCashReductionActionType.FIND_PETTY_CASH_REDUCTION_FAIL),
    tap((action: FindPettyCashReductionFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.translationService.translate('FINANCES.PETTY_CASH_REDUCTION.PETTY_CASH_REDUCTION_NOT_FOUND');
      }
    })
  );

  /* ========================= CREATE_PETTY_CASH_REDUCTION =================================== */
  @Effect()
  create$ = this.actions$.pipe(
    ofType(PettyCashReductionActionType.CREATE_PETTY_CASH_REDUCTION),
    switchMap((action: CreatePettyCashReduction) =>
      this.pettyCashReductionService.create(action.payload).pipe(
        map((response) => new CreatePettyCashReductionSuccess(response)),
        catchError((error) => of(new CreatePettyCashReductionFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  createSuccess$ = this.actions$.pipe(
    ofType(PettyCashReductionActionType.CREATE_PETTY_CASH_REDUCTION_SUCCESS),
    tap((action: CreatePettyCashReductionSuccess) => {
      this.notificationService.success(
        this.translationService.translate('FINANCES.PETTY_CASH_REDUCTION.PETTY_CASH_REDUCTION_ADDED')
      );
    })
  );

  @Effect({ dispatch: false })
  createFail$ = this.actions$.pipe(
    ofType(PettyCashReductionActionType.CREATE_PETTY_CASH_REDUCTION_FAIL),
    tap((action: CreatePettyCashReductionFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );
}
