/**
 * The set of system supported project log types.
 */
export enum ProjectLogTypes {
  PLAN_CREATED = 'PLAN_CREATED',

  MAIN_TASK_CREATED = 'MAIN_TASK_CREATED',

  MAIN_TASK_UPDATED = 'MAIN_TASK_UPDATED',

  MAIN_TASK_DELETED = 'MAIN_TASK_DELETED',

  SUB_TASK_CREATED = 'SUB_TASK_CREATED',

  SUB_TASK_UPDATED = 'SUB_TASK_UPDATED',

  SUB_TASK_DELETED = 'SUB_TASK_DELETED',
}
