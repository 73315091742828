import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';

import {
  CreateEmployeeLoanResponse,
  CreateEmployeeManagementLoanInput,
  CreatePayGovServiceRequestInput,
  EmployeeLoan,
  EmployeeLoanSettlement,
  GovServiceRequest,
  UpdateLoanInput,
} from 'hr/models';
import { AppHttpResponse } from 'shared';
import {
  CreatePayrollPaymentInput,
  CreatePayrollPaymentRefundInput,
  PayPayrollInput,
  Payroll,
  PayrollPayment,
  PayrollPaymentRefund,
} from 'finances/models';

/**
 * The hr services includes functionality to create, search and update for an loan.
 */
@Injectable()
export class HrService {
  /**
   * The relative route for the employee loans.
   *
   * No leading or trailing slashes required.
   */
  private employeeLoansApi = 'hr/employee-loans';

  /**
   * The relative route for the employee loan settlements.
   *
   * No leading or trailing slashes required.
   */
  private employeeLoanSettlementsApi = 'hr/loan-settlements';

  /**
   * The relative route for the payrolls.
   *
   * No leading or trailing slashes required.
   */
  private employeePayrollsApi = 'hr/payrolls';

  /**
   * The relative route for the gov service requests.
   *
   * No leading or trailing slashes required.
   */
  private govServiceRequestsApi = 'hr/gov-service-requests';

  constructor(private http: HttpClient) {}

  /**
   * Searches in the loans by employees, departments, locations, fromDate and toDate.
   * @param employees The employees of the loans.
   * @param departments The departments of the loans.
   * @param locations The locations of the loans.
   * @param loanPaymentTypes The loan payment type of the loans.
   * @param fromDate The fromDate of the loans.
   * @param toDate The toDate of the loans.
   * @param page The current pagination page number.
   * @param pageSize The maximum number of loans allowed per one pagination page.
   */
  public searchEmployeeLoans(
    employees: number[],
    departments: number[],
    locations: number[],
    loanPaymentTypes: number[],
    fromDate: Date,
    toDate: Date,
    page: number,
    pageSize: number
  ): Observable<AppHttpResponse<EmployeeLoan[]>> {
    const params = new HttpParams()
      .set('employees', employees.join(','))
      .set('departments', departments.join(','))
      .set('locations', locations.join(','))
      .set('loanPaymentTypes', loanPaymentTypes.join(','))
      .set('fromDate', fromDate.toISOString())
      .set('toDate', toDate.toISOString())
      .set('page', page.toString())
      .set('pageSize', pageSize.toString());
    return this.http.get<AppHttpResponse<EmployeeLoan[]>>(`${this.employeeLoansApi}`, { params });
  }

  /**
   * Searches in the loan settlements of the employee by from date and to date.
   * @param employees The employees of the loan settlements.
   * @param departments The departments of the loan settlements.
   * @param locations The locations of the loan settlements.
   * @param fromDate The fromDate of the loan settlements.
   * @param toDate The toDate of the loan settlements.
   * @param page The current pagination page number.
   * @param pageSize The maximum number of loan settlements allowed per one pagination page.
   */
  public searchEmployeeLoanSettlements(
    employees: number[],
    departments: number[],
    locations: number[],
    fromDate: Date,
    toDate: Date,
    page: number,
    pageSize: number
  ): Observable<AppHttpResponse<EmployeeLoanSettlement[]>> {
    const params = new HttpParams()
      .set('employees', employees.join(','))
      .set('departments', departments.join(','))
      .set('locations', locations.join(','))
      .set('fromDate', fromDate.toISOString())
      .set('toDate', toDate.toISOString())
      .set('page', page.toString())
      .set('pageSize', pageSize.toString());
    return this.http.get<AppHttpResponse<EmployeeLoanSettlement[]>>(`${this.employeeLoanSettlementsApi}`, {
      params,
    });
  }

  /**
   * Searches in the payrolls by employees, departments, locations, payroll statuses,fromDate and toDate.
   * @param employees The employees of the payroll.
   * @param departments The departments of the payroll.
   * @param locations The locations of the payroll.
   * @param payrollStatuses The statuses of the payroll.
   * @param fromDate The fromDate of the payroll.
   * @param toDate The toDate of the payroll.
   * @param page The current pagination page number.
   * @param pageSize The maximum number of payrolls allowed per one pagination page.
   */
  public searchPayrolls(
    employees: number[],
    departments: number[],
    locations: number[],
    payrollStatuses: number[],
    fromDate: Date,
    toDate: Date,
    page: number,
    pageSize: number
  ): Observable<AppHttpResponse<Payroll[]>> {
    const params = new HttpParams()
      .set('employees', employees.join(','))
      .set('departments', departments.join(','))
      .set('locations', locations.join(','))
      .set('payrollStatuses', payrollStatuses.join(','))
      .set('fromDate', fromDate.toISOString())
      .set('toDate', toDate.toISOString())
      .set('page', page.toString())
      .set('pageSize', pageSize.toString());
    return this.http.get<AppHttpResponse<Payroll[]>>(`${this.employeePayrollsApi}`, { params });
  }

  /**
   * Searches in the paid payrolls by employees, departments, locations, fromDate and toDate.
   * @param employees The employees of the payroll.
   * @param departments The departments of the payroll.
   * @param locations The locations of the payroll.
   * @param fromDate The fromDate of the payroll.
   * @param toDate The toDate of the payroll.
   * @param page The current pagination page number.
   * @param pageSize The maximum number of payrolls allowed per one pagination page.
   */
  public searchPaidPayrolls(
    employees: number[],
    departments: number[],
    locations: number[],
    fromDate: Date,
    toDate: Date,
    page: number,
    pageSize: number
  ): Observable<AppHttpResponse<Payroll[]>> {
    const params = new HttpParams()
      .set('employees', employees.join(','))
      .set('departments', departments.join(','))
      .set('locations', locations.join(','))
      .set('fromDate', fromDate.toISOString())
      .set('toDate', toDate.toISOString())
      .set('page', page.toString())
      .set('pageSize', pageSize.toString());
    return this.http.get<AppHttpResponse<Payroll[]>>(`${this.employeePayrollsApi}/paid`, { params });
  }

  /**
   * Searches in the payroll payments by employeePayrollMethods, fromDate and toDate.
   * @param employeePaymentMethods the employee payment methods of the payroll payments .
   * @param fromDate The fromDate of the payroll.
   * @param toDate The toDate of the payroll.
   * @param page The current pagination page number.
   * @param pageSize The maximum number of payrolls allowed per one pagination page.
   */
  public searchPayrollPayments(
    employeePaymentMethods: number[],
    fromDate: Date,
    toDate: Date,
    page: number,
    pageSize: number
  ): Observable<AppHttpResponse<PayrollPayment[]>> {
    const params = new HttpParams()
      .set('employeePaymentMethods', employeePaymentMethods.join(','))
      .set('fromDate', fromDate.toISOString())
      .set('toDate', toDate.toISOString())
      .set('page', page.toString())
      .set('pageSize', pageSize.toString());
    return this.http.get<AppHttpResponse<PayrollPayment[]>>(`${this.employeePayrollsApi}/payroll-payments`, { params });
  }

  /**
   * Searches in the payroll payment refunds by employeePayrollMethods, fromDate and toDate.
   * @param employeePaymentMethods the employee payment methods of the payroll payment refunds .
   * @param fromDate The fromDate of the payroll.
   * @param toDate The toDate of the payroll.
   * @param page The current pagination page number.
   * @param pageSize The maximum number of payrolls allowed per one pagination page.
   */
  public searchPayrollPaymentRefunds(
    employeePaymentMethods: number[],
    fromDate: Date,
    toDate: Date,
    page: number,
    pageSize: number
  ): Observable<AppHttpResponse<PayrollPaymentRefund[]>> {
    const params = new HttpParams()
      .set('employeePaymentMethods', employeePaymentMethods.join(','))
      .set('fromDate', fromDate.toISOString())
      .set('toDate', toDate.toISOString())
      .set('page', page.toString())
      .set('pageSize', pageSize.toString());
    return this.http.get<AppHttpResponse<PayrollPaymentRefund[]>>(
      `${this.employeePayrollsApi}/payroll-payment-refunds`,
      {
        params,
      }
    );
  }

  /**
   * Creates a new employee  loan from the provided data.
   * @param data The new employee loan data.
   */
  public createEmployeeLoan(
    data: CreateEmployeeManagementLoanInput
  ): Observable<AppHttpResponse<CreateEmployeeLoanResponse>> {
    const formData: any = new FormData();
    formData.append('employeeId', data.employeeId);
    formData.append('value', data.value);
    formData.append('bankFee', data.bankFee);
    formData.append('bankFeeTaxValue', data.bankFeeTaxValue);
    formData.append('bankAccountId', data.bankAccountId);
    formData.append('loanPaymentTypeId', data.loanPaymentTypeId);
    formData.append('settlementValue', data.settlementValue);
    formData.append('transactionDate', data.transactionDate?.toISOString());
    formData.append('settlementStartDate', data.settlementStartDate);
    formData.append('enableSettlement', data.enableSettlement);
    formData.append('notes', data.notes);

    /**
     * Append attachments to the form data.
     */
    data.attachments.forEach((attachment) => formData.append(`attachments[]`, attachment, attachment.name));

    return this.http.post<AppHttpResponse<CreateEmployeeLoanResponse>>(`${this.employeeLoansApi}`, formData);
  }

  /**
   * Prepares pay payroll from the provided data.
   * @param data The in pay payroll data.
   */
  public preparePayPayroll(data: PayPayrollInput): Observable<AppHttpResponse<Payroll[]>> {
    return this.http.post<AppHttpResponse<Payroll[]>>(`${this.employeePayrollsApi}/prepare-pay-payrolls`, data);
  }

  /**
   * Creates a new in pay payrolls from the provided data.
   * @param data The new  in pay payrolls data.
   */
  public createPayrollPayment(data: CreatePayrollPaymentInput): Observable<AppHttpResponse<PayrollPayment>> {
    const formData: any = new FormData();
    formData.append('bankFee', data.bankFee);
    formData.append('bankFeeTaxValue', data.bankFeeTaxValue);
    formData.append('bankAccountId', data.bankAccountId);
    formData.append('employeePaymentMethodId', data.employeePaymentMethodId);
    formData.append('transactionDate', data.transactionDate?.toISOString());
    formData.append('notes', data.notes);

    /**
     * Append payrolls to the form data.
     */
    for (let index = 0; index < data.payrolls.length; index++) {
      formData.append(`payrolls[${index}]`, data.payrolls[index]);
    }

    /**
     * Append attachments to the form data.
     */
    data.attachments.forEach((attachment) => formData.append(`attachments[]`, attachment, attachment.name));

    return this.http.post<AppHttpResponse<PayrollPayment>>(`${this.employeePayrollsApi}/create-pay-payrolls`, formData);
  }

  /**
   * Creates a new in payroll payment refunds from the provided data.
   * @param data The new  in pay payrolls data.
   */
  public createPayrollPaymentRefund(
    data: CreatePayrollPaymentRefundInput
  ): Observable<AppHttpResponse<PayrollPaymentRefund>> {
    const formData: any = new FormData();
    formData.append('bankAccountId', data.bankAccountId);
    formData.append('bankFee', data.bankFee);
    formData.append('bankFeeTaxValue', data.bankFeeTaxValue);
    formData.append('employeePaymentMethodId', data.employeePaymentMethodId);
    formData.append('transactionDate', data.transactionDate?.toISOString());
    formData.append('notes', data.notes);

    /**
     * Append payrolls to the form data.
     */
    for (let index = 0; index < data.payrolls.length; index++) {
      formData.append(`payrolls[${index}]`, data.payrolls[index]);
    }

    /**
     * Append attachments to the form data.
     */
    data.attachments.forEach((attachment) => formData.append(`attachments[]`, attachment, attachment.name));

    return this.http.post<AppHttpResponse<PayrollPaymentRefund>>(
      `${this.employeePayrollsApi}/create-refund-payrolls`,
      formData
    );
  }

  /**
   * Updates an existing loan data using the provided data.
   * @param data The updated loan data.
   */
  public updateEmployeeLoan(data: UpdateLoanInput): Observable<AppHttpResponse<EmployeeLoan>> {
    return this.http.put<AppHttpResponse<EmployeeLoan>>(`${this.employeeLoansApi}`, data);
  }

  /**
   * Deletes the payroll by given id.
   * @param payrollId The id of the payroll.
   */
  public deletePayroll(payrollId: number): Observable<AppHttpResponse<Payroll>> {
    return this.http.delete<AppHttpResponse<Payroll>>(`${this.employeePayrollsApi}/${payrollId}`);
  }

  /**
   * Finds the payroll payment with the given id.
   * @param id The id of the payroll payment.
   */
  public findPayrollPaymentById(payrollPaymentId: number): Observable<AppHttpResponse<PayrollPayment>> {
    return this.http.get<AppHttpResponse<PayrollPayment>>(
      `${this.employeePayrollsApi}/payroll-payments/${payrollPaymentId}`
    );
  }

  /**
   * Finds the payroll payment refund with the given id.
   * @param payrollPaymentRefundId The id of the payroll payment refund.
   */
  public findPayrollPaymentRefundById(
    payrollPaymentRefundId: number
  ): Observable<AppHttpResponse<PayrollPaymentRefund>> {
    return this.http.get<AppHttpResponse<PayrollPaymentRefund>>(
      `${this.employeePayrollsApi}/payroll-payment-refunds/${payrollPaymentRefundId}`
    );
  }

  /**
   * Creates pay for an existing gov service request data using the provided data.
   * @param data The updated gov service request data.
   */
  public createPayGovServiceRequest(
    data: CreatePayGovServiceRequestInput
  ): Observable<AppHttpResponse<GovServiceRequest>> {
    const formData: any = new FormData();
    formData.append('govServiceRequestPaymentMethodId', data.govServiceRequestPaymentMethodId);
    formData.append('bankAccountId', data.bankAccountId);
    formData.append('bankFee', data.bankFee);
    formData.append('bankFeeTaxValue', data.bankFeeTaxValue);
    formData.append('costCenterId', data.costCenterId);
    formData.append('govServiceRequestId', data.govServiceRequestId);
    formData.append('version', data.version);
    formData.append('transactionDate', data.transactionDate?.toISOString());
    formData.append('govServiceId', data.govServiceId);
    formData.append('notes', data.notes);

    /**
     * Append gov service request employees to the form data.
     */
    for (let index = 0; index < data.govServiceRequestEmployees.length; index++) {
      const govServiceRequestEmployee = data.govServiceRequestEmployees[index];

      formData.append(`govServiceRequestEmployees[${index}][employeeId]`, govServiceRequestEmployee.employeeId);
      formData.append(`govServiceRequestEmployees[${index}][serviceValue]`, govServiceRequestEmployee.serviceValue);
      formData.append(`govServiceRequestEmployees[${index}][penaltyValue]`, govServiceRequestEmployee.penaltyValue);
      formData.append(`govServiceRequestEmployees[${index}][fromDate]`, govServiceRequestEmployee.fromDate);
      formData.append(`govServiceRequestEmployees[${index}][toDate]`, govServiceRequestEmployee.toDate);
      formData.append(`govServiceRequestEmployees[${index}][notes]`, govServiceRequestEmployee.notes);
    }

    /**
     * Append attachments to the form data.
     */
    data.attachments.forEach((attachment) => formData.append(`attachments[]`, attachment, attachment.name));

    return this.http.post<AppHttpResponse<GovServiceRequest>>(`${this.govServiceRequestsApi}/pay`, formData);
  }

  /**
   * Searches in the service requests.
   * @param page The current pagination page number.
   * @param pageSize The maximum number of services allowed per one pagination page.
   */
  public searchGovServiceRequests(
    govServices: number[],
    statuses: number[],
    fromDate: Date,
    toDate: Date,
    page: number,
    pageSize: number
  ): Observable<AppHttpResponse<GovServiceRequest[]>> {
    const params = new HttpParams()
      .set('govServices', govServices.join(','))
      .set('statuses', statuses.join(','))
      .set('fromDate', fromDate.toISOString())
      .set('toDate', toDate.toISOString())
      .set('page', page.toString())
      .set('pageSize', pageSize.toString());
    return this.http.get<AppHttpResponse<GovServiceRequest[]>>(`${this.govServiceRequestsApi}`, { params });
  }

  /**
   * Finds the gov service request with the given id.
   * @param govServiceRequestId The id of the gov service request.
   */
  public findGovServiceRequestById(govServiceRequestId: number): Observable<AppHttpResponse<GovServiceRequest>> {
    return this.http.get<AppHttpResponse<GovServiceRequest>>(`${this.govServiceRequestsApi}/${govServiceRequestId}`);
  }
}
