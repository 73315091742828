import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Location as AngularLocation } from '@angular/common';

import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { skip, tap } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import * as fromStoresStore from 'stores/store';
import * as fromSettingsStore from 'settings/store';
import {
  PageInfo,
  NotificationService,
  NotificationMessage,
  CustomValidators,
  TranslationService,
  APP_CONSTANTS,
} from 'shared';
import {
  Product,
  ProductClass,
  UnitOfMeasure,
  SubProduct,
  SubProductsFormItem,
  SubProductInput,
  ProductIngredientsFormItem,
  ProductIngredientInput,
  IngredientProducts,
  Location,
  LocationFormItem,
  ProductLocation,
} from 'stores/models';
import { Tax } from 'settings/models';

/**
 * The product component pages.
 */
enum PAGES {
  'product-details' = 'product-details',
  'other-details' = 'other-details',
  'sales-discounts' = 'sales-discounts',
  'unit-of-measure-rates' = 'unit-of-measure-rates',
}

@Component({
  selector: 'app-update-product',
  templateUrl: './update-product.component.html',
  styles: [],
})
export class UpdateProductComponent implements OnInit, OnDestroy {
  /**
   * The confirm modal template reference.
   */
  @ViewChild('confirmModalRef') confirmModalRef: ElementRef<any>;

  /**
   * The confirm delete photo modal template reference.
   */
  @ViewChild('confirmDeletePhotoModalRef') confirmDeletePhotoModalRef: ElementRef<any>;

  /**
   * The confirm update photo modal template reference.
   */
  @ViewChild('confirmUpdatePhotoModalRef') confirmUpdatePhotoModalRef: ElementRef<any>;

  /**
   * The decimal mask.
   */
  readonly DECIMAL_MASK = APP_CONSTANTS.numeric.decimal.mask;

  /**
   * Gets or sets the information about the current page.
   */
  pageInfo: PageInfo = {
    title: 'STORES.PRODUCTS.CONFIRM_UPDATE_PRODUCT_TITLE',
    icon: 'fa fa-edit',
  };

  /**
   * Shows or hide the product classes list.
   */
  productClassesListVisibility = false;

  /**
   * Shows or hide the product units of measure list.
   */
  productUnitsOfMeasureListVisibility = false;

  /**
   * Gets or sets the id of the current edited product.
   */
  productId: number;

  /**
   * The list of active product classes.
   */
  productClasses: ProductClass[] = [];

  /**
   * The list of active product units of measure.
   */
  productUnitsOfMeasure: UnitOfMeasure[] = [];

  /**
   * The list of active locations.
   */
  locations: Location[] = [];

  /**
   * The current edited product.
   */
  product$: Observable<Product>;

  /**
   * The list of taxes.
   */
  taxes$: Observable<Tax[]>;

  /**
   * Indicates whether there is a update-product process is running or not.
   */
  isUpdating$: Observable<boolean>;

  /**
   * The update product form.
   */
  form: FormGroup;

  /**
   * The isService value.
   */
  isService: boolean;

  /**
   * The update product photo form.
   */
  updatePhotoForm: FormGroup;

  /**
   * The description of the current product.
   */
  productDescription: string;

  /**
   * Sets the initial sub-products.
   */
  initialSubProducts: SubProduct[] = [];

  /**
   * Sets the initial ingredient-products.
   */
  initialProductIngredients: IngredientProducts[] = [];

  /**
   * Sets the initial locations should be added to the locations form.
   * @param locations An object contains `count` of initial blank locations.
   * @param locations A single service details to be added to the form.
   * @param locations A list of locations details to be added to the form.
   */
  initialLocationsSubject: BehaviorSubject<
    { count: number } | LocationFormItem | LocationFormItem[]
  > = new BehaviorSubject(undefined);

  /**
   * Gets or sets the selected page.
   * @default 'product-details'
   */
  activePage: PAGES = PAGES['product-details'];

  /**
   * Used to add new location form items to the locations form.
   */
  newLocations: LocationFormItem[];

  /**
   * The set of subscriptions on this components,
   * these subscriptions must be unsubscribed before this component got destroyed.
   */
  subscriptions = new Subscription();

  /**
   * Gets or sets the current selected language.
   */
  get currentLang() {
    return this.translationService.language;
  }

  /**
   * Gets the product variants form-array.
   */
  get subProductsForm(): FormArray {
    return this.form?.controls.subProducts as FormArray;
  }

  /**
   * Gets the product variants form-array.
   */
  get ingredientsForm(): FormArray {
    return this.form?.controls.ingredients as FormArray;
  }

  /**
   * Gets the locations form-array.
   */
  get locationsForm(): FormArray {
    return this.form?.controls.locations as FormArray;
  }

  /**
   * @param route The activate route service.
   * @param modalService The modal service.
   * @param notificationService The notification service.
   * @param translationService The translation service.
   * @param settingsStore$ the  settings-store module.
   * @param storesStore$ The stores-store module.
   * @param locationService The location service.
   */
  constructor(
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private notificationService: NotificationService,
    private translationService: TranslationService,
    private settingsStore$: Store<fromSettingsStore.SettingsState>,
    private storesStore$: Store<fromStoresStore.StoresState>,
    private locationService: AngularLocation
  ) {}

  ngOnInit(): void {
    this.init();
  }

  /**
   * Destroy component data
   */
  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  /**
   * Initialize component data.
   */
  init() {
    this.initForm();

    /** Subscribe to route to get the product id whenever it changed. */
    const routeSubscription = this.route.paramMap
      .pipe(
        tap((params) => {
          this.productId = +params.get('productId');
          this.storesStore$.dispatch(new fromStoresStore.FindProduct(this.productId));
        })
      )
      .subscribe();
    this.subscriptions.add(routeSubscription);

    /* Load the selected product. */
    this.product$ = this.storesStore$.pipe(
      select(fromStoresStore.getSelectedProduct),
      tap((product) => {
        if (product) {
          this.productDescription = this.currentLang === 'en' ? product.descriptionEn : product.description;

          /* Update page info. */
          this.pageInfo = {
            ...this.pageInfo,
            subTitle: `(${this.productDescription})`,
          };

          this.productClasses = [product.productClass];
          this.productUnitsOfMeasure = [product.unitOfMeasure];

          this.form.patchValue({ ...product, isService: product.isService });
          this.isService = product.isService;
          this.subProductsForm.clear();
          this.initialSubProducts = product.subProducts;
          this.initialProductIngredients = product.ingredientProducts;

          this.locationsForm.clear();

          this.newLocations = product.productLocations.map((location: ProductLocation) => ({
            locationId: location.locationId,
            location: location.location,
          }));
          this.form.markAsPristine();
        }
      })
    );

    /**
     * set is service value after update
     */
    this.subscriptions.add(
      this.storesStore$
        .pipe(
          select(fromStoresStore.getSelectedProductUpdateCompleted),
          skip(1),
          tap((updated) => {
            if (updated) {
              setTimeout(() => {
                if (this.isService) {
                  this.form.controls.isService.setValue(true);
                  this.form.controls.isService.updateValueAndValidity();
                }
              }, 10);
            }
          })
        )
        .subscribe()
    );

    /**
     * Load data.
     */
    this.isUpdating$ = this.storesStore$.pipe(select(fromStoresStore.getSelectedProductUpdating));

    let isManualSearchTriggeredBeforeForTaxes = false;
    this.taxes$ = this.settingsStore$.pipe(
      select(fromSettingsStore.getTaxes),
      tap((taxes) => {
        if (!isManualSearchTriggeredBeforeForTaxes && !taxes.length) {
          isManualSearchTriggeredBeforeForTaxes = true;
          this.settingsStore$.dispatch(new fromSettingsStore.SearchTaxes({ name: '', page: 1 }));
        }
      })
    );

    /** Select the user desired page. */
    this.activePage = PAGES[this.route.snapshot.fragment] ?? this.activePage;
    this.selectedPageChanged(this.activePage);
  }

  /**
   * Initialize form and add validators.
   */
  initForm() {
    const hasIngredientsFormControl = new FormControl(false);
    const hasSubProductsFormControl = new FormControl(false);
    const subProductHasIngredientsFormControl = new FormControl(false);
    const subProductsForm = new FormArray([], CustomValidators.arrayItems(1));
    const ingredientsForm = new FormArray([], CustomValidators.arrayItems(1));

    this.form = new FormGroup({
      description: new FormControl('', [Validators.required, Validators.minLength(1), Validators.maxLength(100)]),
      descriptionEn: new FormControl('', [Validators.required, Validators.minLength(1), Validators.maxLength(100)]),
      barcode: new FormControl('', [Validators.minLength(0), Validators.maxLength(50)]),
      itemCode: new FormControl('', [Validators.required, Validators.minLength(0), Validators.maxLength(50)]),
      purchasePrice: new FormControl('', [Validators.min(0)]),
      salePrice: new FormControl('', [Validators.min(0)]),
      tax: new FormControl(null, [Validators.required, CustomValidators.range([0, 100])]),
      hasIngredients: hasIngredientsFormControl,
      hasSubProducts: hasSubProductsFormControl,
      subProductHasIngredients: subProductHasIngredientsFormControl,
      showProductInSalesScreen: new FormControl(false),
      subProducts: subProductsForm,
      ingredients: ingredientsForm,
      hasExpireDate: new FormControl(false),
      isService: new FormControl(true),
      hasSerialNumber: new FormControl(false),
      productClassId: new FormControl(null, Validators.required),
      unitOfMeasureId: new FormControl(null, Validators.required),
      locations: new FormArray([], CustomValidators.arrayItems(1)),
      photoFilename: new FormControl(null),
    });

    this.updatePhotoForm = new FormGroup({
      photo: new FormControl(null),
    });

    /**
     * Enable or disable the values that depends on has ingredients when value changes.
     * Enable or disable the values that depends on has sub products when value changes.
     * Enable or disable the values that depends on has sub product ingredients when value changes.
     */
    this.subscriptions.add(hasIngredientsFormControl.valueChanges.subscribe(() => this.onHasIngredientsChange()));
    this.subscriptions.add(hasSubProductsFormControl.valueChanges.subscribe(() => this.onHasSubProductsChange()));
    this.subscriptions.add(
      subProductHasIngredientsFormControl.valueChanges.subscribe(() => this.onHasSubProductIngredientsChange())
    );

    /**
     * Initialize on has ingredients change.
     * Initialize on has sub products change.
     */
    this.onHasIngredientsChange();
    this.onHasSubProductsChange();
    setTimeout(() => {
      if (this.isService) {
        this.form.controls.isService.setValue(true);
        this.form.controls.isService.updateValueAndValidity();
      }
    }, 1000);
  }

  /**
   * Submits the form.
   */
  submit() {
    if (this.form.invalid) {
      const errorMessage = new NotificationMessage();

      if (this.form.get('description').invalid) {
        errorMessage.title = this.translationService.translate(
          'STORES.PRODUCTS.PRODUCT_DATA_VALIDATION.ARABIC_PRODUCT_DESCRIPTION_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate('STORES.PRODUCTS.PRODUCT_DATA_VALIDATION.PRODUCT_DESCRIPTION_IS_REQUIRED'),
          this.translationService.translate('STORES.PRODUCTS.PRODUCT_DATA_VALIDATION.PRODUCT_DESCRIPTION_LENGTH_ERROR'),
        ];
        this.activePage = PAGES['product-details'];
      } else if (this.form.get('descriptionEn').invalid) {
        errorMessage.title = this.translationService.translate(
          'STORES.PRODUCTS.PRODUCT_DATA_VALIDATION.ENGLISH_PRODUCT_DESCRIPTION_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate('STORES.PRODUCTS.PRODUCT_DATA_VALIDATION.PRODUCT_DESCRIPTION_IS_REQUIRED'),
          this.translationService.translate('STORES.PRODUCTS.PRODUCT_DATA_VALIDATION.PRODUCT_DESCRIPTION_LENGTH_ERROR'),
        ];
        this.activePage = PAGES['product-details'];
      } else if (this.form.get('barcode').invalid) {
        errorMessage.title = this.translationService.translate(
          'STORES.PRODUCTS.PRODUCT_DATA_VALIDATION.PRODUCT_BARCODE_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate('STORES.PRODUCTS.PRODUCT_DATA_VALIDATION.PRODUCT_BARCODE_LENGTH_ERROR'),
        ];
        this.activePage = PAGES['product-details'];
      } else if (this.form.get('itemCode').invalid) {
        errorMessage.title = this.translationService.translate(
          'STORES.PRODUCTS.PRODUCT_DATA_VALIDATION.PRODUCT_ITEM_CODE_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate('STORES.PRODUCTS.PRODUCT_DATA_VALIDATION.PRODUCT_ITEM_CODE_IS_REQUIRED'),
          this.translationService.translate('STORES.PRODUCTS.PRODUCT_DATA_VALIDATION.PRODUCT_ITEM_CODE_LENGTH_ERROR'),
        ];
        this.activePage = PAGES['product-details'];
      } else if (this.form.get('purchasePrice').invalid) {
        errorMessage.title = this.translationService.translate(
          'STORES.PRODUCTS.PRODUCT_DATA_VALIDATION.PRODUCT_PURCHASE_PRICE_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate(
            'STORES.PRODUCTS.PRODUCT_DATA_VALIDATION.PRODUCT_PURCHASE_PRICE_IS_REQUIRED'
          ),
          this.translationService.translate(
            'STORES.PRODUCTS.PRODUCT_DATA_VALIDATION.PRODUCT_PURCHASE_PRICE_LENGTH_ERROR'
          ),
        ];
        this.activePage = PAGES['product-details'];
      } else if (this.form.get('salePrice').invalid) {
        errorMessage.title = this.translationService.translate(
          'STORES.PRODUCTS.PRODUCT_DATA_VALIDATION.PRODUCT_SALE_PRICE_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate('STORES.PRODUCTS.PRODUCT_DATA_VALIDATION.PRODUCT_SALE_PRICE_LENGTH_ERROR'),
        ];
        this.activePage = PAGES['product-details'];
      } else if (this.form.get('tax').invalid) {
        errorMessage.title = this.translationService.translate(
          'STORES.PRODUCTS.PRODUCT_DATA_VALIDATION.PRODUCT_TAX_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate('STORES.PRODUCTS.PRODUCT_DATA_VALIDATION.PRODUCT_TAX_IS_REQUIRED'),
          this.translationService.translate('STORES.PRODUCTS.PRODUCT_DATA_VALIDATION.PRODUCT_TAX_LENGTH_ERROR'),
        ];
        this.activePage = PAGES['product-details'];
      } else if (this.form.get('productClassId').invalid) {
        errorMessage.title = this.translationService.translate(
          'STORES.PRODUCTS.PRODUCT_DATA_VALIDATION.PRODUCT_CLASS_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate('STORES.PRODUCTS.PRODUCT_DATA_VALIDATION.PRODUCT_CLASS_IS_REQUIRED'),
        ];
        this.activePage = PAGES['product-details'];
      } else if (this.form.get('unitOfMeasureId').invalid) {
        errorMessage.title = this.translationService.translate(
          'STORES.PRODUCTS.PRODUCT_DATA_VALIDATION.UNIT_OF_MEASURE_IS_REQUIRED'
        );
        errorMessage.body = [
          this.translationService.translate('STORES.PRODUCTS.PRODUCT_DATA_VALIDATION.UNIT_OF_MEASURE_IS_REQUIRED'),
        ];
        this.activePage = PAGES['product-details'];
      } else if (this.form.get('subProducts').invalid) {
        /**
         * Check if subProducts count = 0.
         */
        if (!this.subProductsForm.controls.length) {
          errorMessage.title = this.translationService.translate(
            'STORES.PRODUCTS.PRODUCT_DATA_VALIDATION.SUB_PRODUCTS_ERROR'
          );
          errorMessage.body = [
            this.translationService.translate('STORES.PRODUCTS.PRODUCT_DATA_VALIDATION.SUB_PRODUCTS_COUNT_ERROR'),
          ];
        } else {
          /**
           * Check if some of subProduct has errors.
           */
          for (let index = 0; index < this.subProductsForm.controls.length; index++) {
            const subProductFormItem = this.subProductsForm.controls[index] as FormArray;

            if (subProductFormItem.valid) {
              continue;
            }

            errorMessage.title = this.translationService.translate(
              'STORES.PRODUCTS.PRODUCT_DATA_VALIDATION.SUB_PRODUCT_NUMBER',
              {
                productVariant: index + 1,
              }
            );
            errorMessage.body = [];

            if (subProductFormItem.get('subProductVariants').invalid) {
              errorMessage.body.push(
                this.translationService.translate(
                  'STORES.PRODUCTS.PRODUCT_DATA_VALIDATION.PRODUCT_VARIANTS_IS_REQUIRED'
                )
              );
            }

            if (subProductFormItem.get('purchasePrice').invalid) {
              errorMessage.body.push(
                this.translationService.translate(
                  'STORES.PRODUCTS.PRODUCT_DATA_VALIDATION.PRODUCT_PURCHASE_PRICE_LENGTH_ERROR'
                )
              );
            }

            if (subProductFormItem.get('salePrice').invalid) {
              errorMessage.body.push(
                this.translationService.translate(
                  'STORES.PRODUCTS.PRODUCT_DATA_VALIDATION.PRODUCT_SALE_PRICE_LENGTH_ERROR'
                )
              );
            }

            if (subProductFormItem.get('ingredients').invalid) {
              errorMessage.body.push(
                this.translationService.translate('STORES.PRODUCTS.PRODUCT_DATA_VALIDATION.PRODUCT_INGREDIENT_ERROR')
              );

              /**
               * Check if some of product ingredient has errors.
               */
              const key = 'controls';
              const ingredients = subProductFormItem.get('ingredients')[key];
              for (let ingredientsIndex = 0; ingredientsIndex < ingredients.length; ingredientsIndex++) {
                const productIngredient = ingredients[ingredientsIndex];

                if (productIngredient.valid) {
                  continue;
                }

                errorMessage.title = this.translationService.translate(
                  'STORES.PRODUCTS.PRODUCT_DATA_VALIDATION.PRODUCT_INGREDIENT',
                  { productIngredient: ingredientsIndex + 1 }
                );
                errorMessage.body = [];

                if (productIngredient.get('ingredientId').invalid) {
                  errorMessage.body.push(
                    this.translationService.translate(
                      'STORES.PRODUCTS.PRODUCT_DATA_VALIDATION.INGREDIENT_PRODUCT_ERROR'
                    ),
                    this.translationService.translate(
                      'STORES.PRODUCTS.PRODUCT_DATA_VALIDATION.INGREDIENT_PRODUCT_IS_REQUIRED'
                    )
                  );
                }

                if (productIngredient.get('quantity').invalid) {
                  errorMessage.body.push(
                    this.translationService.translate('STORES.PRODUCTS.PRODUCT_DATA_VALIDATION.QUANTITY_IS_REQUIRED')
                  );
                }

                break;
              }
            }
            this.activePage = PAGES['product-details'];

            break;
          }
        }
      } else if (this.form.get('ingredients').invalid) {
        /**
         * Check if ingredients count = 0.
         */
        if (!this.ingredientsForm.controls.length) {
          errorMessage.title = this.translationService.translate(
            'STORES.PRODUCTS.PRODUCT_DATA_VALIDATION.PRODUCT_INGREDIENT_ERROR'
          );
          errorMessage.body = [
            this.translationService.translate(
              'STORES.PRODUCTS.PRODUCT_DATA_VALIDATION.PRODUCT_INGREDIENTS_COUNT_ERROR'
            ),
          ];
        } else {
          /**
           * Check if some of ingredients has errors.
           */
          for (let index = 0; index < this.ingredientsForm.controls.length; index++) {
            const ingredientsFormItem = this.ingredientsForm.controls[index] as FormArray;

            if (ingredientsFormItem.valid) {
              continue;
            }

            errorMessage.title = this.translationService.translate(
              'STORES.PRODUCTS.PRODUCT_DATA_VALIDATION.PRODUCT_INGREDIENT',
              {
                productIngredient: index + 1,
              }
            );
            errorMessage.body = [];

            if (ingredientsFormItem.get('ingredientId').invalid) {
              errorMessage.body.push(
                this.translationService.translate('STORES.PRODUCTS.PRODUCT_DATA_VALIDATION.INGREDIENT_PRODUCT_ERROR'),
                this.translationService.translate(
                  'STORES.PRODUCTS.PRODUCT_DATA_VALIDATION.INGREDIENT_PRODUCT_IS_REQUIRED'
                )
              );
            }
            if (ingredientsFormItem.get('quantity').invalid) {
              errorMessage.body.push(
                this.translationService.translate('STORES.PRODUCTS.PRODUCT_DATA_VALIDATION.QUANTITY_IS_REQUIRED')
              );
            }
          }
        }
      } else if (this.form.get('locations').invalid) {
        /**
         * Check if lines count = 0.
         */
        if (!this.locationsForm.controls.length) {
          errorMessage.title = this.translationService.translate(
            'STORES.PRODUCTS.PRODUCT_DATA_VALIDATION.LOCATIONS_ERROR'
          );
          errorMessage.body = [
            this.translationService.translate('STORES.PRODUCTS.PRODUCT_DATA_VALIDATION.LOCATIONS_LENGTH_ERROR'),
          ];
        } else {
          /**
           * Check if some of locations has errors.
           */
          for (let index = 0; index < this.locationsForm.controls.length; index++) {
            const location = this.locationsForm.controls[index];

            if (location.valid) {
              continue;
            }

            errorMessage.title = this.translationService.translate(
              'STORES.PRODUCTS.PRODUCT_DATA_VALIDATION.LOCATIONS_NUMBER_ERROR',
              {
                locationNumber: index + 1,
              }
            );
            errorMessage.body = [];

            if (location.get('locationId').invalid) {
              errorMessage.body.push(
                this.translationService.translate('STORES.PRODUCTS.PRODUCT_DATA_VALIDATION.LOCATIONS_IS_REQUIRED')
              );
            }

            break;
          }
        }
        this.activePage = PAGES['product-details'];
      }

      this.form.markAllAsTouched();
      return this.notificationService.warningWithTitle(errorMessage);
    } else {
      this.openModal(this.confirmModalRef);
    }
  }

  /**
   * Confirms the form submit.
   */

  confirm() {
    /**
     * The list of sub products in the lines form.
     */
    const subProductsItems: SubProductsFormItem[] = this.form.value.hasSubProducts ? this.form.value.subProducts : [];
    const subProducts: SubProductInput[] = subProductsItems.map((item) => {
      const subProductIngredientsItems: ProductIngredientsFormItem[] = this.form.value.subProductHasIngredients
        ? item.subProductIngredients
        : [];

      const subProductIngredients: ProductIngredientInput[] = subProductIngredientsItems.map((ingredientItem) => {
        const ingredientInput: ProductIngredientInput = {
          ingredientId: ingredientItem.ingredientId,
          quantity: ingredientItem.quantity,
        };

        return ingredientInput;
      });

      const subProduct: SubProductInput = {
        id: item.id,
        subProductVariants: item.subProductVariants,
        purchasePrice: item.purchasePrice,
        salePrice: item.salePrice,
        subProductIngredients,
        isNew: item.isNew,
      };

      return subProduct;
    });

    /**
     * The list of ingredients in the lines form.
     */

    const productIngredientsItems: ProductIngredientsFormItem[] = this.form.value.hasIngredients
      ? this.form.value.ingredients
      : [];
    const productIngredients: ProductIngredientInput[] = productIngredientsItems.map((ingredientItem) => {
      const ingredientInput: ProductIngredientInput = {
        ingredientId: ingredientItem.product.id,
        quantity: ingredientItem.quantity,
      };

      return ingredientInput;
    });

    /**
     * The list of locations in the locations form.
     */

    const locations = this.locationsForm.value.map((item: LocationFormItem) => item.locationId);

    this.storesStore$.dispatch(
      new fromStoresStore.UpdateProduct({
        id: this.productId,
        ...this.form.value,

        isService:
          this.form.value.hasIngredients || this.form.value.subProductHasIngredients ? true : this.form.value.isService,
        photoFilename: this.form.value.photoFilename ?? null,
        purchasePrice: this.form.value.purchasePrice ?? 0,
        salePrice: this.form.value.salePrice ?? 0,
        hasIngredients: this.form.value.hasIngredients ?? false,
        productIngredients,
        subProducts,
        locations,
      })
    );
  }

  /**
   * Opens the modal of the given templateRef.
   * @param modalRef The modal templateRef to be opened.
   */
  openModal(modalRef) {
    this.modalService.open(modalRef);
  }

  /**
   * Selects the newly selected product class from the product classes search list.
   * @param productClasses The list of newly selected product classes to select the only one in the list.
   */
  selectProductClass([productClass]: ProductClass[]) {
    if (productClass) {
      this.productClasses = [productClass];
      this.form.patchValue({ productClassId: productClass.id });
    }
  }

  /**
   * Selects the newly selected product units of measure from the product units of measure search list.
   * @param productUnitsOfMeasure The list of newly selected product units of measure to select the only one in the list.
   */
  selectProductUnitsOfMeasure([productUnitOfMeasure]: UnitOfMeasure[]) {
    if (productUnitOfMeasure) {
      this.productUnitsOfMeasure = [productUnitOfMeasure];
      this.form.patchValue({ unitOfMeasureId: productUnitOfMeasure.id });
    }
  }

  /**
   * Handles the `hasIngredients` change event.
   */
  onHasIngredientsChange() {
    if (this.form.controls.hasIngredients.value) {
      this.ingredientsForm.setValidators([Validators.required, CustomValidators.arrayItems(1)]);
      this.form.controls.isService.setValue(true);
      this.form.controls.isService.disable();
    } else if (!this.form.controls.hasIngredients.value) {
      this.ingredientsForm.clearValidators();
      this.form.controls.isService.setValue(false);
      this.form.controls.isService.enable();
    }

    this.form.controls.isService.updateValueAndValidity();
    this.ingredientsForm.clear();
    this.ingredientsForm.updateValueAndValidity();
  }

  /**
   * Handles the `hasSubProducts` change event.
   */
  onHasSubProductsChange() {
    if (this.form.controls.hasSubProducts.value) {
      this.subProductsForm.setValidators([Validators.required, CustomValidators.arrayItems(1)]);
      this.form.controls.hasIngredients.disable();
    } else {
      this.subProductsForm.clearValidators();
      this.form.controls.subProductHasIngredients.setValue(false);
      this.form.controls.hasIngredients.enable();
      this.onHasSubProductIngredientsChange();
    }

    this.subProductsForm.clear();
    this.subProductsForm.updateValueAndValidity();
  }

  /**
   * Handles the `subProductsHasIngredients` change event.
   */
  onHasSubProductIngredientsChange() {
    if (this.form.controls.subProductHasIngredients.value) {
      this.form.controls.isService.setValue(true);
      this.form.controls.isService.disable();
    } else if (
      !this.form.controls.subProductHasIngredients.value &&
      this.form.controls.hasIngredients.value &&
      this.form.controls.isService.value
    ) {
      this.onHasIngredientsChange();
    } else {
      this.form.controls.isService.setValue(false);
      this.form.controls.isService.enable();
    }
    this.form.controls.isService.updateValueAndValidity();
  }

  /**
   * Deletes the current product photo.
   */
  deletePhoto() {
    this.openModal(this.confirmDeletePhotoModalRef);
  }

  /**
   * Updates the current product photo.
   */
  updatePhoto() {
    this.openModal(this.confirmUpdatePhotoModalRef);
  }

  /**
   * Confirms & deletes the current product photo.
   */
  deletePhotoConfirm() {
    this.storesStore$.dispatch(new fromStoresStore.DeleteProductPhoto(this.productId));
  }

  /**
   * Confirms updates the current product photo.
   */
  updatePhotoConfirm() {
    this.storesStore$.dispatch(
      new fromStoresStore.UpdateProductPhoto({ id: this.productId, photo: this.updatePhotoForm.value.photo })
    );
    this.updatePhotoForm.reset();
  }

  /**
   * Updates the browser url according to the selected page.
   * @param page The newly selected page.
   */
  selectedPageChanged(page: PAGES) {
    this.locationService.replaceState(`${this.locationService.path()}#${PAGES[page]}`);
  }
}
