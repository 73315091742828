import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { ProjectMainTask } from 'projects-management/models';
import { DateUtil } from 'shared';

@Component({
  selector: 'app-project-main-task',
  templateUrl: './project-main-task.component.html',
  styles: [],
})
export class ProjectMainTaskComponent {
  /**
   * Sets the project main task form form-group.
   */
  @Input() projectMainTaskForm: FormGroup;

  /**
   * Sets the project main task values.
   */
  @Input() isUpdated: boolean;

  /**
   * Gets the cost value.
   */
  cost: number;

  /**
   * Gets the budget value.
   */
  budget: number;

  /**
   * Gets the remaining budget value.
   */
  remainingBudget: number;

  /**
   * Sets the currently edited project main task.
   */
  @Input() set projectMainTask(value: ProjectMainTask) {
    this.projectMainTaskObj = value;

    /** Update form data. */
    if (value) {
      const startDate = DateUtil.toDatePickerDate(value.startDate);
      const endDate = DateUtil.toDatePickerDate(value.endDate);
      this.cost = value.cost;
      this.budget = value.budget;
      this.remainingBudget = value.remainingBudget;

      this.projectMainTaskForm.patchValue({ ...value, startDate, endDate });
    } else {
      this.projectMainTaskForm.reset();
    }
  }

  /**
   * Gets or sets the currently edited project main task.
   */
  projectMainTaskObj: ProjectMainTask;
}
