import { TaxesState } from 'settings/store/states/taxes.state';
import { OrganizationSettingsState } from 'settings/store/states/organization-settings.state';

/**
 * Represents the settings-module state.
 */
export class SettingsState {
  /**
   * Represents the taxes state.
   */
  public taxes: TaxesState;

  /**
   * Represents the organization settings state.
   */
  public organizationSettings: OrganizationSettingsState;
}
