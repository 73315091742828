import { Action } from '@ngrx/store';

import { AppHttpResponse } from 'shared';
import { Department } from 'hr/models';

/**
 * The AppSearch-module store Departments action types.
 */
export enum DepartmentsActionType {
  SEARCH_DEPARTMENTS = '[AppSearch] [Departments] Search Department',
  SEARCH_DEPARTMENTS_FAIL = '[AppSearch] [Departments] Search Department Fail',
  SEARCH_DEPARTMENTS_SUCCESS = '[AppSearch] [Departments] Search Department Success',
}

/**
 * Represents a store Departments search action.
 */
export class SearchDepartments implements Action {
  /**
   * The type of the action.
   */
  readonly type = DepartmentsActionType.SEARCH_DEPARTMENTS;

  /**
   * Fires a new departments search action.
   * @param payload the search parameters,
   * if omitted, all departments will be loaded.
   */
  constructor(
    public payload?: { name: string; departmentTypes: number[]; departmentCategories: number[]; page: number }
  ) {}
}

/**
 * Represents a store Departments search fail action.
 */
export class SearchDepartmentsFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = DepartmentsActionType.SEARCH_DEPARTMENTS_FAIL;

  /**
   * Fires a new Departments search fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store search success action.
 */
export class SearchDepartmentsSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = DepartmentsActionType.SEARCH_DEPARTMENTS_SUCCESS;

  /**
   * Fires a new departments search success action.
   * @param payload An object contains the list of departments that matches the search criteria
   * in addition to the pagination info.
   */
  constructor(public payload: AppHttpResponse<Department[]>) {}
}

/**
 * hr-module departments action types.
 */
export type DepartmentsActions = SearchDepartments | SearchDepartmentsFail | SearchDepartmentsSuccess;
