import { Component, Input } from '@angular/core';

import { Decimal } from 'decimal.js';

import { PurchaseReturn, PurchaseReturnProduct } from 'purchases/models';

@Component({
  selector: 'app-journal-purchase-return-details',
  templateUrl: './journal-purchase-return-details.component.html',
  styles: [],
})
export class JournalPurchaseReturnDetailsComponent {
  /**
   * Sets the current viewed journal purchase returns.
   */
  @Input() purchaseReturn: PurchaseReturn;

  /**
   * Gets the total quantity of purchase returns.
   */
  productsQuantity(purchaseReturnProducts: PurchaseReturnProduct[]): number {
    return Decimal.sum(0, ...purchaseReturnProducts.map((product) => product.quantity ?? 0)).toNumber();
  }
}
