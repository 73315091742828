<div class="row">
  <div class="col-md-12">
    <!-- journal asset sale invoice details starts -->
    <div class="card-header mb-3">
      <h4 class="card-title">{{ 'FINANCES.JOURNALS.JOURNAL_DATA.ASSET_SALE_INVOICE_DETAILS' | translate }}</h4>
    </div>
    <div class="card-content">
      <div class="px-3">
        <div class="row">
          <div class="form-group col-md-12">
            <label for="assetId">{{ 'FINANCES.JOURNALS.JOURNAL_DATA.ASSET_DESCRIPTION' | translate }}</label>
            <input
              type="text"
              id="assetId"
              class="form-control round"
              [value]="assetSaleInvoice.asset?.description"
              readonly
            />
          </div>
          <div class="form-group col-md-6">
            <label for="customerName">{{ 'FINANCES.JOURNALS.JOURNAL_DATA.CUSTOMER_NAME' | translate }}</label>
            <input
              type="text"
              id="customerName"
              class="form-control round"
              [value]="assetSaleInvoice.customerName"
              readonly
            />
          </div>
          <div class="form-group col-md-6">
            <label for="customerLogTypeId">{{ 'FINANCES.JOURNALS.JOURNAL_DATA.LOG_TYPE' | translate }}</label>
            <input
              type="text"
              id="customerLogTypeId"
              class="form-control round"
              [value]="
                assetSaleInvoice.customerLogType?.name | localize: assetSaleInvoice.customerLogType?.nameEn | async
              "
              readonly
            />
          </div>
          <div class="form-group col-md-6">
            <label for="value">{{ 'FINANCES.JOURNALS.JOURNAL_DATA.VALUE' | translate }}</label>
            <input type="text" id="value" class="form-control round" [value]="assetSaleInvoice.value" readonly />
          </div>
          <div class="form-group col-md-6">
            <label for="transactionDate">{{ 'FINANCES.JOURNALS.JOURNAL_DATA.JOURNAL_DATE' | translate }}</label>
            <input
              type="text"
              id="transactionDate"
              class="form-control round"
              [value]="assetSaleInvoice.transactionDate | date: 'datetime'"
              readonly
            />
          </div>
          <div class="form-group col-md-6">
            <label for="createdAt">{{ 'SHARED.SEARCH.CREATED_AT' | translate }}</label>
            <input
              type="text"
              id="createdAt"
              class="form-control round"
              [value]="assetSaleInvoice.createdAt | date: 'datetime'"
              readonly
            />
          </div>
          <div class="form-group col-md-12">
            <label for="notes">{{ 'FINANCES.JOURNALS.JOURNAL_DATA.NOTES' | translate }}</label>
            <textarea
              id="notes"
              class="form-control round"
              [innerText]="assetSaleInvoice.notes"
              [placeholder]="'FINANCES.JOURNALS.JOURNAL_DATA.NOTES_NOT_FOUND' | translate"
              readonly
            ></textarea>
          </div>
        </div>
      </div>
      <!-- journal asset sale invoice details ends -->
    </div>
  </div>
</div>
