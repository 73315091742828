import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormGroup, FormControl } from '@angular/forms';

import { EmployeeProvisionLink, EmployeeProvisionLinkItem } from 'finances/models';

@Component({
  selector: 'app-employees-provision-link-form',
  templateUrl: './employees-provision-link-form.component.html',
  styles: [],
})
export class EmployeesProvisionLinkFormComponent implements OnInit {
  /**
   * Sets the employee provision links form-array.
   */
  @Input() employeeProvisionLinksForm: FormArray;

  /**
   * Sets the initial employee provision in the form.
   * @param  links An object contains `count` of initial blank employee provision links.
   * @param  links A single employee provision link details to be added to the form.
   * @param  links A list of employee provision links details to be added to the form.
   */
  @Input() set initialEmployeeProvisionLinks(
    links: { count: number } | EmployeeProvisionLinkItem | EmployeeProvisionLinkItem[]
  ) {
    if (Object.getOwnPropertyNames(links).includes('count')) {
      for (let index = 0; index < (links as { count: number }).count; index++) {
        this.addEmployeeProvisionLink();
      }
    } else if (links instanceof Array) {
      links.forEach((link) => this.addEmployeeProvisionLink(link));
    } else if (typeof links === 'object') {
      this.addEmployeeProvisionLink(links as EmployeeProvisionLinkItem);
    }
  }

  /**
   * Gets or sets the initial select list employee provision link
   * which will be passed to the child form-controls to allow render employee provision link description.
   */
  initialSelectEmployeeProvisionLinks: EmployeeProvisionLink[] = [];

  /**
   * Indicates that a drop-down field in the form is opened and therefore the table height should be expanded.
   */
  dropDownIsOpened: boolean;

  ngOnInit() {
    this.init();
  }

  /**
   * Initialize component data.
   */
  init() {}

  /**
   * Creates & adds a new employee provision link form-group with validations.
   * @param  link The employee provision link details to be bounded to the employee provision , If omitted a blank journal will be created.
   */
  addEmployeeProvisionLink(link?: EmployeeProvisionLinkItem) {
    const group = new FormGroup({
      accountId: new FormControl(link?.accountId),
      account: new FormControl(link?.account),
      employeeProvisionLinkId: new FormControl(link?.employeeProvisionLinkId),
      employeeProvisionLink: new FormControl(link?.employeeProvisionLink),
    });

    this.employeeProvisionLinksForm.push(group);
  }
}
