import { VendorLogType } from 'lookups/models';

/**
 * Represents the vendor advance payment log types state.
 */
export class VendorAdvancePaymentLogTypeState {
  /**
   * The list of vendor advance payment log types.
   */
  public data: VendorLogType[];

  /**
   * An error that may resulting during processing some actions.
   */
  public error: any;

  /**
   * Determines if there is a running load process.
   */
  public isLoading: boolean;

  /**
   * Determines if the last load process has been ended successfully.
   */
  public isLoaded: boolean;
}
