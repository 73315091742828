import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';

import { AppHttpResponse } from 'shared';
import { CreateVariantInput, Variant, UpdateVariantInput } from 'stores/models';

/**
 * The variants services includes functionality to search, findById, create, update and delete a variant.
 */
@Injectable()
export class VariantsService {
  /**
   * The relative route for the variants.
   *
   * No leading or trailing slashes required.
   */
  private variantsApi = 'stores/variants';

  constructor(private http: HttpClient) {}

  /**
   * Searches in the variants by name.
   * @param name The name of the variant.
   * @param categories The categories of the variant.
   * @param page The current pagination page number.
   * @param pageSize The maximum number of variants allowed per one pagination page.
   */
  public search(
    name: string,
    categories: number[],
    page: number,
    pageSize: number
  ): Observable<AppHttpResponse<Variant[]>> {
    const params = new HttpParams()
      .set('name', name)
      .set('categories', categories.join(','))
      .set('page', page.toString())
      .set('pageSize', pageSize.toString());
    return this.http.get<AppHttpResponse<Variant[]>>(`${this.variantsApi}`, { params });
  }

  /**
   * Finds the variant with the given id.
   * @param id The id of the variant.
   */
  public findById(id: number): Observable<AppHttpResponse<Variant>> {
    return this.http.get<AppHttpResponse<Variant>>(`${this.variantsApi}/${id}`);
  }

  /**
   * Creates a new variant from the provided data.
   * @param data The new variant data.
   */
  public create(data: CreateVariantInput): Observable<AppHttpResponse<Variant>> {
    return this.http.post<AppHttpResponse<Variant>>(`${this.variantsApi}`, data);
  }

  /**
   * Updates an existing variant data using the provided data.
   * @param data The updated variant data.
   */
  public update(data: UpdateVariantInput): Observable<AppHttpResponse<Variant>> {
    return this.http.put<AppHttpResponse<Variant>>(`${this.variantsApi}`, data);
  }

  /**
   * Deletes the variant by given id.
   * @param id The id of the variant.
   */
  public delete(id: number): Observable<AppHttpResponse<Variant>> {
    return this.http.delete<AppHttpResponse<Variant>>(`${this.variantsApi}/${id}`);
  }
}
