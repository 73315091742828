import { Component, Input, OnDestroy } from '@angular/core';
import { FormArray, FormGroup, FormControl } from '@angular/forms';

import { Subscription } from 'rxjs';

import { PettyCashRefundRequestApprovalLineFormItem } from 'finances/models';

@Component({
  selector: 'app-petty-cash-refund-request-approval-form',
  templateUrl: './petty-cash-refund-request-approval-form.component.html',
  styles: [],
})
export class PettyCashRefundRequestApprovalFormComponent implements OnDestroy {
  /**
   * Sets the lines form-array.
   */
  @Input() linesForm: FormArray;

  /**
   * Sets the name form-array in its parent form.
   * @default 'lines'
   */
  @Input() formArrayName = 'lines';

  /**
   * Adds a list of new request form items to the requests form.
   * @param items The list of request form items to be added to the requests form.
   */
  @Input() set newLines(items: PettyCashRefundRequestApprovalLineFormItem[]) {
    if (!items?.length) {
      return;
    }

    items.forEach((item) => this.addLine(item));
  }

  /**
   * The set of subscriptions on this components,
   * these subscriptions must be unsubscribed before this component got destroyed.
   */
  subscriptions = new Subscription();

  /**
   * Destroy component data
   */
  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  /**
   * Creates & adds a new line form-group with validations.
   * @param line The line details to be bounded to the line, If omitted a blank line will be created.
   */
  addLine(line: PettyCashRefundRequestApprovalLineFormItem) {
    const lineFormGroup = new FormGroup({
      pettyCashRefundRequestLine: new FormControl(line.pettyCashRefundRequestLine),
    });

    this.linesForm.push(lineFormGroup);
  }

  /**
   * Remove the line at the given index from the list.
   * @param index The index of the line to be removed.
   */
  removeLine(index: number) {
    this.linesForm.removeAt(index);
  }

  /**
   * Remove all of the list of lines.
   *
   * It only adds one line for quick start.
   */
  resetLines() {
    this.linesForm.clear();
  }
}
