import { ActionReducerMap } from '@ngrx/store';

import { organizationsReducer } from 'organizations/store/reducers/organizations.reducer';
import { OrganizationsState } from 'organizations/store/states';

/**
 * The Organizations-Module store reducers.
 */
export const reducers: ActionReducerMap<OrganizationsState> = {
  organizations: organizationsReducer,
};
