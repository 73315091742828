import { Action } from '@ngrx/store';

import { PayrollStatus } from 'lookups/models';
import { AppHttpResponse } from 'shared';

/**
 * The Lookups-Module store payroll status action types.
 */
export enum PayrollStatusActionType {
  GET_ALL_PAYROLL_STATUSES = '[Lookups] [PayrollStatus] Get All Payroll Statuses',
  GET_ALL_PAYROLL_STATUSES_FAIL = '[Lookups] [PayrollStatus] Get All Payroll Statuses Fail',
  GET_ALL_PAYROLL_STATUSES_SUCCESS = '[Lookups] [PayrollStatus] Get All Payroll Statuses Success',
}

/**
 * Represents a store  payroll status action.
 */
export class GetAllPayrollStatuses implements Action {
  /**
   * The type of the action.
   */
  readonly type = PayrollStatusActionType.GET_ALL_PAYROLL_STATUSES;

  /**
   * Fires a new  payroll status action.
   */
  constructor() {}
}

/**
 * Represents a store  payroll status fail action.
 */
export class GetAllPayrollStatusesFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = PayrollStatusActionType.GET_ALL_PAYROLL_STATUSES_FAIL;

  /**
   * Fires a new  payroll status fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store  payroll status success action.
 */
export class GetAllPayrollStatusesSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = PayrollStatusActionType.GET_ALL_PAYROLL_STATUSES_SUCCESS;

  /**
   * Fires a  payroll status success action.
   * @param payload An object contains the list of  payroll statuses.
   */
  constructor(public payload: AppHttpResponse<PayrollStatus[]>) {}
}

/**
 * Lookups-module  payroll status action types.
 */
export type PayrollStatusActions = GetAllPayrollStatuses | GetAllPayrollStatusesFail | GetAllPayrollStatusesSuccess;
