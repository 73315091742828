import { Injectable } from '@angular/core';

import { of } from 'rxjs';
import { switchMap, map, catchError } from 'rxjs/operators';
import { Effect, ofType, Actions } from '@ngrx/effects';

import { LookupsService } from 'lookups/services';
import {
  GosiCoverageTypeActionType,
  GetAllGosiCoverageTypeFail,
  GetAllGosiCoverageTypeSuccess,
} from 'lookups/store/actions';

@Injectable()
export class GosiCoverageTypeEffects {
  constructor(private actions$: Actions, private lookupsService: LookupsService) {}

  /* ========================= GET_ALL_GOSI_COVERAGE_TYPES =================================== */
  @Effect()
  getAllGosiCoverageTypes$ = this.actions$.pipe(
    ofType(GosiCoverageTypeActionType.GET_ALL_GOSI_COVERAGE_TYPES),
    switchMap(() =>
      this.lookupsService.getAllGosiCoverageTypes().pipe(
        map((response) => new GetAllGosiCoverageTypeSuccess(response)),
        catchError((error) => of(new GetAllGosiCoverageTypeFail(error)))
      )
    )
  );
}
