import { Vendor } from 'purchases/models';
import { PaginationInfo } from 'shared/models';

/**
 * Represents the vendors state.
 */
export class VendorsState {
  /**
   * The list of vendors.
   */
  public data: Vendor[];

  /**
   * The pagination info for the vendors.
   */
  public paginationInfo: PaginationInfo;

  /**
   * An error that may resulting during processing some actions.
   */
  public error: any;

  /**
   * Determines if there is a running search process.
   */
  public isSearching: boolean;

  /**
   * Determines if the last search process has been ended successfully.
   */
  public isSearchCompleted: boolean;
}
