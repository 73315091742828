import { Component, OnInit, OnDestroy } from '@angular/core';

import { Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';

import * as fromAuthStore from 'auth/store';
import { Claims, User } from 'security/models';
import { PageInfo } from 'shared';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styles: [],
})
export class DashboardComponent implements OnInit, OnDestroy {
  /**
   * Gets or sets the information about the current page.
   */
  pageInfo: PageInfo = {
    title: 'CORE.DASHBOARD.HOME_PAGE_TITLE',
    icon: 'fa fa-home',
  };

  /**
   * The system supported user claims.
   */
  Claims = Claims;

  /**
   * The current logged in user.
   */
  currentLoggedInUser: User;

  /**
   * The set of subscriptions on this components,
   * these subscriptions must be unsubscribed before this component got destroyed.
   */
  subscriptions = new Subscription();

  /**
   * @param authStore$ The authStore-module store.
   */
  constructor(private authStore$: Store<fromAuthStore.AuthState>) {}

  ngOnInit(): void {
    /** Load current logged in user data. */
    this.subscriptions.add(
      this.authStore$
        .pipe(
          select(fromAuthStore.getSessionUser),
          tap((user: User) => {
            if (user) {
              this.currentLoggedInUser = user;
            }
          })
        )
        .subscribe()
    );
  }

  /**
   * Destroy component data
   */
  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
