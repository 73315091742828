import { Action } from '@ngrx/store';

import { PayrollElementNature } from 'lookups/models';
import { AppHttpResponse } from 'shared';

/**
 * The Lookups-Module store payroll element nature action types.
 */
export enum PayrollElementNatureActionType {
  GET_ALL_PAYROLL_ELEMENT_NATURES = '[Lookups] [PayrollElementNature] Get All Payroll Element Natures',
  GET_ALL_PAYROLL_ELEMENT_NATURES_FAIL = '[Lookups] [PayrollElementNature] Get All Payroll Element Natures Fail',
  GET_ALL_PAYROLL_ELEMENT_NATURES_SUCCESS = '[Lookups] [PayrollElementNature] Get All Payroll Element Natures Success',
}

/**
 * Represents a store payroll element nature action.
 */
export class GetAllPayrollElementNatures implements Action {
  /**
   * The type of the action.
   */
  readonly type = PayrollElementNatureActionType.GET_ALL_PAYROLL_ELEMENT_NATURES;

  /**
   * Fires a new payroll element nature action.
   */
  constructor() {}
}

/**
 * Represents a store payroll element nature fail action.
 */
export class GetAllPayrollElementNaturesFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = PayrollElementNatureActionType.GET_ALL_PAYROLL_ELEMENT_NATURES_FAIL;

  /**
   * Fires a new payroll element nature fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store payroll element nature success action.
 */
export class GetAllPayrollElementNaturesSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = PayrollElementNatureActionType.GET_ALL_PAYROLL_ELEMENT_NATURES_SUCCESS;

  /**
   * Fires a payroll element nature success action.
   * @param payload An object contains the list of payroll element natures.
   */
  constructor(public payload: AppHttpResponse<PayrollElementNature[]>) {}
}

/**
 * Lookups-module payroll element nature action types.
 */
export type PayrollElementNatureActions =
  | GetAllPayrollElementNatures
  | GetAllPayrollElementNaturesFail
  | GetAllPayrollElementNaturesSuccess;
