import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';

import { AppHttpResponse } from 'shared';
import { CostCenter, CreateCostCenterInput, UpdateCostCenterInput } from 'finances/models';

/**
 * The cost centers services includes functionality to create, search, findById,update and delete for a cost center.
 */
@Injectable()
export class CostCentersService {
  /**
   * The relative route for the cost centers.
   *
   * No leading or trailing slashes required.
   */
  private costCentersApi = 'finances/cost-centers';

  constructor(private http: HttpClient) {}

  /**
   * Creates a new cost center from the provided data.
   * @param data The new cost center data.
   */
  public create(data: CreateCostCenterInput): Observable<AppHttpResponse<CostCenter>> {
    return this.http.post<AppHttpResponse<CostCenter>>(`${this.costCentersApi}`, data);
  }

  /**
   * Searches in the cost centers by description.
   * @param description The description of the cost center.
   * @param page The current pagination page number.
   * @param pageSize The maximum number of cost centers allowed per one pagination page.
   */
  public search(description: string, page: number, pageSize: number): Observable<AppHttpResponse<CostCenter[]>> {
    const params = new HttpParams()
      .set('description', description)
      .set('page', page.toString())
      .set('pageSize', pageSize.toString());
    return this.http.get<AppHttpResponse<CostCenter[]>>(`${this.costCentersApi}`, { params });
  }

  /**
   * Gets the general cost center.
   */
  public getGeneralCostCenter(): Observable<AppHttpResponse<CostCenter>> {
    return this.http.get<AppHttpResponse<CostCenter>>(`${this.costCentersApi}/general`);
  }

  /**
   * Finds the cost center with the given id.
   * @param id The id of the cost center.
   */
  public findById(id: number): Observable<AppHttpResponse<CostCenter>> {
    return this.http.get<AppHttpResponse<CostCenter>>(`${this.costCentersApi}/${id}`);
  }

  /**
   * Updates an existing cost center data using the provided data.
   * @param data The updated cost center data.
   */
  public update(data: UpdateCostCenterInput): Observable<AppHttpResponse<CostCenter>> {
    return this.http.put<AppHttpResponse<CostCenter>>(`${this.costCentersApi}`, data);
  }

  /**
   * Deletes the cost center by given id.
   * @param id The id of the cost center.
   */
  public delete(id: number): Observable<AppHttpResponse<CostCenter>> {
    return this.http.delete<AppHttpResponse<CostCenter>>(`${this.costCentersApi}/${id}`);
  }
}
