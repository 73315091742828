<!-- page header starts -->
<app-page-header [info]="pageInfo"></app-page-header>
<!-- page header ends -->

<!-- update location pages starts -->
<div class="card">
  <div class="card-content">
    <div class="px-3 pt-3" *ngIf="location$ | async; let location">
      <form (ngSubmit)="submit()" [formGroup]="form">
        <div class="row">
          <div class="col-md-12">
            <ul
              class="nav-tabs"
              ngbNav
              #nav="ngbNav"
              [(activeId)]="activePage"
              (activeIdChange)="selectedPageChanged($event)"
              [destroyOnHide]="false"
            >
              <li ngbNavItem="location">
                <a ngbNavLink>
                  <i class="icon-location-pin" [class.success]="activePage == 'location'"></i>
                  {{ 'STORES.LOCATIONS.LOCATION_DATA_TITLE' | translate }}
                </a>
                <ng-template ngbNavContent>
                  <!-- update location form starts -->
                  <div class="row">
                    <div class="col-md-12">
                      <div class="card">
                        <div class="card-header mb-3 pt-0">
                          <h4 class="card-title">{{ 'STORES.LOCATIONS.LOCATION_DATA_TITLE' | translate }}</h4>
                        </div>
                        <div class="card-content">
                          <div class="px-3">
                            <div class="row">
                              <div class="form-group col-md-12">
                                <label for="code">{{
                                  'STORES.LOCATIONS.LOCATION_DATA.LOCATION_CODE' | translate
                                }}</label>
                                <input
                                  type="text"
                                  id="code"
                                  class="form-control round"
                                  [placeholder]="'STORES.LOCATIONS.LOCATION_DATA.LOCATION_CODE_PLACEHOLDER' | translate"
                                  maxlength="50"
                                  formControlName="code"
                                />
                              </div>
                              <div class="form-group col-md-6">
                                <label for="name">{{
                                  'STORES.LOCATIONS.LOCATION_DATA.ARABIC_LOCATION_NAME' | translate
                                }}</label>
                                <input
                                  type="text"
                                  id="name"
                                  class="form-control round"
                                  [placeholder]="
                                    'STORES.LOCATIONS.LOCATION_DATA.ARABIC_LOCATION_NAME_PLACEHOLDER' | translate
                                  "
                                  maxlength="50"
                                  minlength="1"
                                  formControlName="name"
                                />
                              </div>
                              <div class="form-group col-md-6">
                                <label for="nameEn">{{
                                  'STORES.LOCATIONS.LOCATION_DATA.ENGLISH_LOCATION_NAME' | translate
                                }}</label>
                                <input
                                  type="text"
                                  id="nameEn"
                                  class="form-control round"
                                  [placeholder]="
                                    'STORES.LOCATIONS.LOCATION_DATA.ENGLISH_LOCATION_NAME_PLACEHOLDER' | translate
                                  "
                                  maxlength="50"
                                  minlength="1"
                                  formControlName="nameEn"
                                />
                              </div>
                              <div class="form-group col-md-6">
                                <label for="regionId">{{
                                  'STORES.LOCATIONS.LOCATION_DATA.THE_REGION' | translate
                                }}</label>
                                <ng-select
                                  class="round"
                                  formControlName="regionId"
                                  labelForId="regionId"
                                  searchable="true"
                                  [placeholder]="'STORES.LOCATIONS.LOCATION_DATA.THE_REGION_PLACEHOLDER' | translate"
                                  [notFoundText]="'SHARED.SEARCH.SEARCH_NOT_FOUND' | translate"
                                  (open)="regionsListVisibility = true"
                                >
                                  <ng-option *ngFor="let region of regions" [value]="region.id">
                                    {{ region.name | localize: region.nameEn | async }}
                                  </ng-option>
                                </ng-select>
                              </div>

                              <div class="form-group col-md-6">
                                <label for="sectorId">{{
                                  'STORES.LOCATIONS.LOCATION_DATA.THE_SECTOR' | translate
                                }}</label>
                                <ng-select
                                  class="round"
                                  formControlName="sectorId"
                                  labelForId="sectorId"
                                  searchable="true"
                                  [placeholder]="'STORES.LOCATIONS.LOCATION_DATA.THE_SECTOR_PLACEHOLDER' | translate"
                                  [notFoundText]="'SHARED.SEARCH.SEARCH_NOT_FOUND' | translate"
                                  (open)="sectorsListVisibility = true"
                                >
                                  <ng-option *ngFor="let sector of sectors" [value]="sector.id">
                                    {{ sector.name | localize: sector.nameEn | async }}
                                  </ng-option>
                                </ng-select>
                              </div>
                              <div class="form-group col-md-6">
                                <label for="locationTypeId">{{
                                  'STORES.LOCATIONS.LOCATION_DATA.LOCATION_TYPE' | translate
                                }}</label>
                                <ng-select
                                  class="round"
                                  formControlName="locationTypeId"
                                  labelForId="locationTypeId"
                                  [placeholder]="'STORES.LOCATIONS.LOCATION_DATA.LOCATION_TYPE_PLACEHOLDER' | translate"
                                  [notFoundText]="'SHARED.SEARCH.SEARCH_NOT_FOUND' | translate"
                                >
                                  <ng-option
                                    *ngFor="let locationType of locationTypes$ | async"
                                    [value]="locationType.id"
                                  >
                                    {{ locationType.name | localize: locationType.nameEn | async }}
                                  </ng-option>
                                </ng-select>
                              </div>
                              <div class="form-group col-md-6">
                                <label for="representorName">{{
                                  'STORES.LOCATIONS.LOCATION_DATA.RESPONSIBLE_NAME' | translate
                                }}</label>
                                <input
                                  type="text"
                                  id="representorName"
                                  class="form-control round"
                                  [placeholder]="
                                    'STORES.LOCATIONS.LOCATION_DATA.RESPONSIBLE_NAME_PLACEHOLDER' | translate
                                  "
                                  maxlength="50"
                                  minlength="1"
                                  formControlName="representorName"
                                />
                              </div>
                              <div class="form-group col-md-6">
                                <label for="representorMobile">{{
                                  'STORES.LOCATIONS.LOCATION_DATA.RESPONSIBLE_MOBILE' | translate
                                }}</label>
                                <input
                                  type="text"
                                  [patterns]="phonePattern"
                                  mask="{{ phoneMask }}"
                                  id="representorMobile"
                                  class="form-control round"
                                  [placeholder]="
                                    'STORES.LOCATIONS.LOCATION_DATA.RESPONSIBLE_MOBILE_PLACEHOLDER' | translate
                                  "
                                  formControlName="representorMobile"
                                />
                              </div>
                              <div class="form-group col-md-6">
                                <label for="costCenterId">{{
                                  'STORES.LOCATIONS.LOCATION_DATA.THE_COST_CENTER' | translate
                                }}</label>
                                <ng-select
                                  class="round"
                                  formControlName="costCenterId"
                                  labelForId="costCenterId"
                                  searchable="true"
                                  [placeholder]="'STORES.LOCATIONS.LOCATION_DATA.COST_CENTER_PLACEHOLDER' | translate"
                                  [notFoundText]="'SHARED.SEARCH.SEARCH_NOT_FOUND' | translate"
                                  (open)="costCentersListVisibility = true"
                                >
                                  <ng-option *ngFor="let costCenter of costCenters" [value]="costCenter.id">
                                    {{ costCenter.description | localize: costCenter.descriptionEn | async }}
                                  </ng-option>
                                </ng-select>
                              </div>
                              <div class="form-group col-md-6">
                                <label for="vatNumber">{{
                                  'STORES.LOCATIONS.LOCATION_DATA.VAT_NUMBER' | translate
                                }}</label>
                                <input
                                  type="text"
                                  id="vatNumber"
                                  class="form-control round"
                                  formControlName="vatNumber"
                                  [placeholder]="'STORES.LOCATIONS.LOCATION_DATA.VAT_NUMBER_PLACEHOLDER' | translate"
                                />
                              </div>
                              <div class="form-group col-md-6">
                                <label for="orgName">{{ 'STORES.LOCATIONS.LOCATION_DATA.ORG_NAME' | translate }}</label>
                                <input
                                  type="text"
                                  id="orgName"
                                  class="form-control round"
                                  [placeholder]="'STORES.LOCATIONS.LOCATION_DATA.ORG_NAME_PLACEHOLDER' | translate"
                                  maxlength="50"
                                  formControlName="orgName"
                                />
                              </div>
                              <div class="form-group col-md-6">
                                <input type="checkbox" class="cursor-pointer" formControlName="enableStockLogs" />
                                <span class="ml-2 mt-0 pt-0">{{
                                  'STORES.LOCATIONS.LOCATION_DATA.STOCK_MONITORING' | translate
                                }}</span>
                              </div>
                              <div class="form-group col-md-6">
                                <input
                                  type="checkbox"
                                  class="cursor-pointer"
                                  formControlName="autoCreateOutgoingStockForSaleInvoices"
                                />
                                <span class="ml-2 mt-0 pt-0">{{
                                  'STORES.LOCATIONS.LOCATION_DATA.AUTO_CREATE_OUTGOING_STOCK_FOR_SALE_INVOICES'
                                    | translate
                                }}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="card">
                        <div class="card-header mb-3 pt-0">
                          <h4 class="card-title">
                            <i class="fa fa-address-card"></i>
                            <span class="mx-1">{{ 'STORES.LOCATIONS.LOCATION_ADDRESS_TITLE' | translate }}</span>
                          </h4>
                        </div>
                        <div class="card-content">
                          <div class="px-3">
                            <div class="row">
                              <div class="form-group col-md-6">
                                <label for="buildingNo">{{
                                  'STORES.LOCATIONS.LOCATION_DATA.BUILDING_NO' | translate
                                }}</label>
                                <input
                                  type="text"
                                  id="buildingNo"
                                  class="form-control round"
                                  formControlName="buildingNo"
                                  [placeholder]="'STORES.LOCATIONS.LOCATION_DATA.BUILDING_NO' | translate"
                                />
                              </div>
                              <div class="form-group col-md-6">
                                <label for="streetName">{{
                                  'STORES.LOCATIONS.LOCATION_DATA.STREET_NAME' | translate
                                }}</label>
                                <input
                                  type="text"
                                  id="streetName"
                                  class="form-control round"
                                  formControlName="streetName"
                                  [placeholder]="'STORES.LOCATIONS.LOCATION_DATA.STREET_NAME' | translate"
                                />
                              </div>
                              <div class="form-group col-md-6">
                                <label for="district">{{
                                  'STORES.LOCATIONS.LOCATION_DATA.DISTRICT' | translate
                                }}</label>
                                <input
                                  type="text"
                                  id="district"
                                  class="form-control round"
                                  formControlName="district"
                                  [placeholder]="'STORES.LOCATIONS.LOCATION_DATA.DISTRICT' | translate"
                                />
                              </div>
                              <div class="form-group col-md-6">
                                <label for="select-id">{{ 'STORES.LOCATIONS.LOCATION_DATA.CITY' | translate }}</label>
                                <ng-select
                                  class="round"
                                  formControlName="cityId"
                                  labelForId="select-id"
                                  searchable="true"
                                  autocomplete="off"
                                  [placeholder]="'STORES.LOCATIONS.LOCATION_DATA.CITY_PLACEHOLDER' | translate"
                                  [notFoundText]="'SHARED.SEARCH.SEARCH_NOT_FOUND' | translate"
                                >
                                  <ng-option *ngFor="let city of cities$ | async" [value]="city.id">
                                    {{ city.name | localize: city.nameEn | async }}
                                  </ng-option>
                                </ng-select>
                              </div>
                              <div class="form-group col-md-6">
                                <label for="country">{{ 'STORES.LOCATIONS.LOCATION_DATA.COUNTRY' | translate }}</label>
                                <input
                                  type="text"
                                  id="country"
                                  class="form-control round"
                                  formControlName="country"
                                  autocomplete="off"
                                  [placeholder]="'STORES.LOCATIONS.LOCATION_DATA.COUNTRY' | translate"
                                />
                              </div>
                              <div class="form-group col-md-6">
                                <label for="postalCode">{{
                                  'STORES.LOCATIONS.LOCATION_DATA.POSTAL_CODE' | translate
                                }}</label>
                                <input
                                  type="text"
                                  id="postalCode"
                                  class="form-control round"
                                  formControlName="postalCode"
                                  [placeholder]="'STORES.LOCATIONS.LOCATION_DATA.POSTAL_CODE' | translate"
                                />
                              </div>
                              <div class="form-group col-md-6">
                                <label for="additionalNo">{{
                                  'STORES.LOCATIONS.LOCATION_DATA.ADDITIONAL_NO' | translate
                                }}</label>
                                <input
                                  type="text"
                                  id="additionalNo"
                                  class="form-control round"
                                  formControlName="additionalNo"
                                  [placeholder]="'STORES.LOCATIONS.LOCATION_DATA.ADDITIONAL_NO' | translate"
                                />
                              </div>
                              <div class="form-group col-md-6">
                                <label for="otherSellerId">{{
                                  'STORES.LOCATIONS.LOCATION_DATA.OTHER_SELLER_ID' | translate
                                }}</label>
                                <input
                                  type="text"
                                  id="otherSellerId"
                                  class="form-control round"
                                  formControlName="otherSellerId"
                                  [placeholder]="'STORES.LOCATIONS.LOCATION_DATA.OTHER_SELLER_ID' | translate"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="card">
                        <div class="card-header mb-3 pt-0">
                          <h4 class="card-title">
                            <i class="fa fa-desktop"></i>
                            <span class="mx-1">{{ 'STORES.LOCATIONS.PRINT_SETTINGS' | translate }}</span>
                          </h4>
                        </div>
                        <div class="card-content">
                          <div class="px-3">
                            <div class="row">
                              <div class="form-group col-md-6">
                                <label for="saleInvoicePrintPaperSizeId">{{
                                  'STORES.LOCATIONS.LOCATION_DATA.SALE_INVOICES_PRINT_PAPER_SIZE' | translate
                                }}</label>
                                <ng-select
                                  class="round"
                                  formControlName="saleInvoicePrintPaperSizeId"
                                  labelForId="saleInvoicePrintPaperSizeId"
                                  searchable="true"
                                  [closeOnSelect]="true"
                                  [placeholder]="
                                    'STORES.LOCATIONS.LOCATION_DATA.SALE_INVOICES_PRINT_PAPER_SIZE_PLACEHOLDER'
                                      | translate
                                  "
                                  [notFoundText]="'SHARED.SEARCH.SEARCH_NOT_FOUND' | translate"
                                >
                                  <ng-option
                                    *ngFor="let printPaperSize of printPaperSizes$ | async"
                                    [value]="printPaperSize.id"
                                  >
                                    {{ printPaperSize.name | localize: printPaperSize.nameEn | async }}
                                  </ng-option>
                                </ng-select>
                              </div>
                              <div class="form-group col-md-6">
                                <label for="saleReturnPrintPaperSizeId">{{
                                  'STORES.LOCATIONS.LOCATION_DATA.SALE_RETURNS_PRINT_PAPER_SIZE' | translate
                                }}</label>
                                <ng-select
                                  class="round"
                                  formControlName="saleReturnPrintPaperSizeId"
                                  labelForId="saleReturnPrintPaperSizeId"
                                  searchable="true"
                                  [closeOnSelect]="true"
                                  [placeholder]="
                                    'STORES.LOCATIONS.LOCATION_DATA.SALE_RETURNS_PRINT_PAPER_SIZE_PLACEHOLDER'
                                      | translate
                                  "
                                  [notFoundText]="'SHARED.SEARCH.SEARCH_NOT_FOUND' | translate"
                                >
                                  <ng-option
                                    *ngFor="let printPaperSize of printPaperSizes$ | async"
                                    [value]="printPaperSize.id"
                                  >
                                    {{ printPaperSize.name | localize: printPaperSize.nameEn | async }}
                                  </ng-option>
                                </ng-select>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-md-6">
                                <div class="row">
                                  <div class="form-group col-md-12">
                                    <input type="checkbox" formControlName="showLogoInPrintSaleInvoices" />
                                    <span class="ml-2 mt-0 pt-0">{{
                                      'STORES.LOCATIONS.LOCATION_DATA.SHOW_LOGO_IN_PRINT_SALES_INVOICES' | translate
                                    }}</span>
                                  </div>
                                  <div class="form-group col-md-12">
                                    <input type="checkbox" formControlName="showDisplayNameInPrintSaleInvoices" />
                                    <span class="ml-2 mt-0 pt-0">{{
                                      'STORES.LOCATIONS.LOCATION_DATA.SHOW_DISPLAY_NAME_IN_PRINT_SALES_INVOICES'
                                        | translate
                                    }}</span>
                                  </div>
                                  <div class="form-group col-md-12">
                                    <input type="checkbox" formControlName="showNotesInPrintSaleInvoices" />
                                    <span class="ml-2 mt-0 pt-0">{{
                                      'STORES.LOCATIONS.LOCATION_DATA.SHOW_NOTES_IN_PRINT_SALES_INVOICES' | translate
                                    }}</span>
                                  </div>
                                  <div class="form-group col-md-12">
                                    <input type="checkbox" formControlName="showProductNotesInPrintSaleInvoices" />
                                    <span class="ml-2 mt-0 pt-0">{{
                                      'STORES.LOCATIONS.LOCATION_DATA.SHOW_PRODUCTS_NOTES_IN_PRINT_SALES_INVOICES'
                                        | translate
                                    }}</span>
                                  </div>
                                  <div class="form-group col-md-12">
                                    <input type="checkbox" formControlName="showProductItemCodeInPrintSaleInvoices" />
                                    <span class="ml-2 mt-0 pt-0">{{
                                      'STORES.LOCATIONS.LOCATION_DATA.SHOW_PRODUCT_CODE_IN_PRINT_SALES_INVOICES'
                                        | translate
                                    }}</span>
                                  </div>
                                  <div class="form-group col-md-12">
                                    <input type="checkbox" formControlName="showLogoInPrintSalesReturn" />
                                    <span class="ml-2 mt-0 pt-0">{{
                                      'STORES.LOCATIONS.LOCATION_DATA.SHOW_LOGO_IN_PRINT_SALES_RETURN' | translate
                                    }}</span>
                                  </div>
                                  <div class="form-group col-md-12">
                                    <input type="checkbox" formControlName="showDisplayNameInPrintSalesReturn" />
                                    <span class="ml-2 mt-0 pt-0">{{
                                      'STORES.LOCATIONS.LOCATION_DATA.SHOW_DISPLAY_NAME_IN_PRINT_SALES_RETURN'
                                        | translate
                                    }}</span>
                                  </div>
                                </div>
                              </div>
                              <div class="col-md-6">
                                <div class="row">
                                  <div class="form-group col-md-12">
                                    <input type="checkbox" formControlName="showLogoInPrintPurchaseInvoices" />
                                    <span class="ml-2 mt-0 pt-0">{{
                                      'STORES.LOCATIONS.LOCATION_DATA.SHOW_LOGO_IN_PRINT_PURCHASES_INVOICES' | translate
                                    }}</span>
                                  </div>
                                  <div class="form-group col-md-12">
                                    <input type="checkbox" formControlName="showDisplayNameInPrintPurchaseInvoices" />
                                    <span class="ml-2 mt-0 pt-0">{{
                                      'STORES.LOCATIONS.LOCATION_DATA.SHOW_DISPLAY_NAME_IN_PRINT_PURCHASES_INVOICES'
                                        | translate
                                    }}</span>
                                  </div>
                                  <div class="form-group col-md-12">
                                    <input type="checkbox" formControlName="showNotesInPrintPurchaseInvoices" />
                                    <span class="ml-2 mt-0 pt-0">{{
                                      'STORES.LOCATIONS.LOCATION_DATA.SHOW_NOTES_IN_PRINT_PURCHASES_INVOICES'
                                        | translate
                                    }}</span>
                                  </div>
                                  <div class="form-group col-md-12">
                                    <input type="checkbox" formControlName="showProductNotesInPrintPurchaseInvoices" />
                                    <span class="ml-2 mt-0 pt-0">{{
                                      'STORES.LOCATIONS.LOCATION_DATA.SHOW_PRODUCTS_NOTES_IN_PRINT_PURCHASES_INVOICES'
                                        | translate
                                    }}</span>
                                  </div>
                                  <div class="form-group col-md-12">
                                    <input
                                      type="checkbox"
                                      formControlName="showProductItemCodeInPrintPurchaseInvoices"
                                    />
                                    <span class="ml-2 mt-0 pt-0">{{
                                      'STORES.LOCATIONS.LOCATION_DATA.SHOW_PRODUCT_CODE_IN_PRINT_PURCHASES_INVOICES'
                                        | translate
                                    }}</span>
                                  </div>
                                  <div class="form-group col-md-12">
                                    <input type="checkbox" formControlName="showLogoInPrintPurchasesReturn" />
                                    <span class="ml-2 mt-0 pt-0">{{
                                      'STORES.LOCATIONS.LOCATION_DATA.SHOW_LOGO_IN_PRINT_PURCHASES_RETURN' | translate
                                    }}</span>
                                  </div>
                                  <div class="form-group col-md-12">
                                    <input type="checkbox" formControlName="showDisplayNameInPrintPurchasesReturn" />
                                    <span class="ml-2 mt-0 pt-0">{{
                                      'STORES.LOCATIONS.LOCATION_DATA.SHOW_DISPLAY_NAME_IN_PRINT_PURCHASES_RETURN'
                                        | translate
                                    }}</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="card">
                        <div class="card-header mb-3 pt-0">
                          <h4 class="card-title">
                            <i class="fa fa-file-image-o"></i>
                            <span class="mx-1">{{ 'STORES.LOCATIONS.LOGOS_TITLE' | translate }}</span>
                          </h4>
                        </div>
                        <div class="card-content">
                          <div class="row">
                            <div *ngIf="location.primaryLogoUrl" class="form-group col-md-6 mt-3">
                              <app-file-viewer
                                [url]="location.primaryLogoUrl"
                                [allowDelete]="true"
                                [allowUpdate]="true"
                                (delete)="deleteLogo(confirmDeletePrimaryLogoRef)"
                                (update)="updateLogo(confirmUpdatePrimaryLogoRef)"
                              ></app-file-viewer>
                            </div>
                            <div *ngIf="!location.primaryLogoUrl" class="form-group col-md-6">
                              <button
                                type="button"
                                class="btn btn-raised btn-success my-1 round"
                                (click)="updateLogo(confirmUpdatePrimaryLogoRef)"
                                [disabled]="isUpdating$ | async"
                              >
                                <i class="fa fa-edit font-medium-3"></i>
                                <span class="mx-1">{{
                                  'STORES.LOCATIONS.CONFIRM_ADD_PRIMARY_LOGO_TITLE' | translate
                                }}</span>
                              </button>
                            </div>
                            <div *ngIf="location.secondaryLogoUrl" class="form-group col-md-6 mt-3">
                              <app-file-viewer
                                [url]="location.secondaryLogoUrl"
                                [allowDelete]="true"
                                [allowUpdate]="true"
                                (delete)="deleteLogo(confirmDeleteSecondaryLogoRef)"
                                (update)="updateLogo(confirmUpdateSecondaryLogoRef)"
                              ></app-file-viewer>
                            </div>
                            <div *ngIf="!location.secondaryLogoUrl" class="form-group col-md-6">
                              <button
                                type="button"
                                class="btn btn-raised btn-success my-1 round"
                                (click)="updateLogo(confirmUpdateSecondaryLogoRef)"
                                [disabled]="isUpdating$ | async"
                              >
                                <i class="fa fa-edit font-medium-3"></i>
                                <span class="mx-1">{{
                                  'STORES.LOCATIONS.CONFIRM_ADD_SECONDARY_LOGO_TITLE' | translate
                                }}</span>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="card">
                        <div class="card-header mb-3 pt-0">
                          <h4 class="card-title">
                            <i class="fa fa-image"></i>
                            <span class="mx-1">{{ 'STORES.LOCATIONS.IMAGES_TITLE' | translate }}</span>
                          </h4>
                        </div>
                        <div class="card-content">
                          <div class="row">
                            <div class="col-md-6">
                              <div class="form-group col-md-12">
                                <div class="row">
                                  <div class="col-md-auto">
                                    <input type="checkbox" formControlName="showInvoiceHeader" />
                                    <span class="ml-2 mt-0 pt-0">{{
                                      'STORES.LOCATIONS.LOCATION_DATA.SHOW_INVOICE_HEADER' | translate
                                    }}</span>
                                  </div>
                                  <div class="form-group col-md-auto" *ngIf="form.value.showInvoiceHeader">
                                    <ng-select
                                      class="round"
                                      formControlName="headerHeight"
                                      labelForId="headerHeight"
                                      searchable="false"
                                      [placeholder]="
                                        'STORES.LOCATIONS.LOCATION_DATA.HEADER_HEIGHT_PLACEHOLDER' | translate
                                      "
                                    >
                                      <ng-option *ngFor="let item of [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]" [value]="item">
                                        {{ item }}
                                      </ng-option>
                                    </ng-select>
                                  </div>
                                </div>
                              </div>
                              <div *ngIf="location.invoiceHeaderUrl" class="form-group col-md-6 mt-3">
                                <app-file-viewer
                                  [url]="location.invoiceHeaderUrl"
                                  [allowDelete]="true"
                                  [allowUpdate]="true"
                                  (delete)="deleteLogo(confirmDeleteInvoiceHeaderImageModalRef)"
                                  (update)="updateLogo(confirmUpdateImageHeaderRef)"
                                ></app-file-viewer>
                              </div>
                              <div *ngIf="!location.invoiceHeaderUrl" class="form-group col-md-6">
                                <button
                                  type="button"
                                  class="btn btn-raised btn-success my-1 round"
                                  (click)="updateLogo(confirmUpdateImageHeaderRef)"
                                  [disabled]="isUpdating$ | async"
                                >
                                  <i class="fa fa-edit font-medium-3"></i>
                                  <span class="mx-1">{{
                                    'STORES.LOCATIONS.CONFIRM_ADD_IMAGE_HEADER_TITLE' | translate
                                  }}</span>
                                </button>
                              </div>
                            </div>
                            <div class="col-md-6">
                              <div class="form-group col-md-12">
                                <div class="row">
                                  <div class="col-md-auto">
                                    <input type="checkbox" formControlName="showInvoiceFooter" />
                                    <span class="ml-2 mt-0 pt-0">{{
                                      'STORES.LOCATIONS.LOCATION_DATA.SHOW_INVOICE_FOOTER' | translate
                                    }}</span>
                                  </div>
                                  <div class="form-group col-md-auto" *ngIf="form.value.showInvoiceFooter">
                                    <ng-select
                                      class="round"
                                      formControlName="footerHeight"
                                      labelForId="footerHeight"
                                      searchable="false"
                                      [placeholder]="
                                        'STORES.LOCATIONS.LOCATION_DATA.FOOTER_HEIGHT_PLACEHOLDER' | translate
                                      "
                                    >
                                      <ng-option *ngFor="let item of footerHeights" [value]="item">
                                        {{ item }}
                                      </ng-option>
                                    </ng-select>
                                  </div>
                                </div>
                              </div>
                              <div *ngIf="location.invoiceFooterUrl" class="form-group col-md-6 mt-3">
                                <app-file-viewer
                                  [url]="location.invoiceFooterUrl"
                                  [allowDelete]="true"
                                  [allowUpdate]="true"
                                  (delete)="deleteLogo(confirmDeleteInvoiceFooterImageModalRef)"
                                  (update)="updateLogo(confirmUpdateImageFooterRef)"
                                ></app-file-viewer>
                              </div>
                              <div *ngIf="!location.invoiceFooterUrl" class="form-group col-md-6">
                                <button
                                  type="button"
                                  class="btn btn-raised btn-success my-1 round"
                                  (click)="updateLogo(confirmUpdateImageFooterRef)"
                                  [disabled]="isUpdating$ | async"
                                >
                                  <i class="fa fa-edit font-medium-3"></i>
                                  <span class="mx-1">{{
                                    'STORES.LOCATIONS.CONFIRM_ADD_IMAGE_FOOTER_TITLE' | translate
                                  }}</span>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- update location form ends -->
                </ng-template>
              </li>
              <li ngbNavItem="point-of-sale">
                <a ngbNavLink>
                  <i class="icon-energy" [class.success]="activePage == 'point-of-sale'"></i>
                  {{ 'STORES.LOCATIONS.POINT_OF_SALE_DATA_TITLE' | translate }}
                </a>
                <ng-template ngbNavContent>
                  <!-- update point of sale form starts -->
                  <div class="row">
                    <div class="col-md-12">
                      <div class="card">
                        <div class="card-header mb-3 pt-0">
                          <h4 class="card-title">
                            <span class="mx-1">{{ 'STORES.LOCATIONS.POINT_OF_SALE_DATA_TITLE' | translate }}</span>
                          </h4>
                        </div>
                        <div class="card-content">
                          <div class="px-3">
                            <div class="row">
                              <div class="form-group col-md-12">
                                <label for="posDescription">{{
                                  'STORES.LOCATIONS.LOCATION_DATA.DESCRIPTION' | translate
                                }}</label>
                                <textarea
                                  id="posDescription"
                                  class="form-control round"
                                  [placeholder]="'STORES.LOCATIONS.LOCATION_DATA.DESCRIPTION_PLACEHOLDER' | translate"
                                  rows="2"
                                  minlength="1"
                                  maxlength="200"
                                  formControlName="posDescription"
                                ></textarea>
                              </div>
                            </div>
                            <div class="row">
                              <div class="form-group col-md-auto">
                                <input type="checkbox" class="cursor-pointer" formControlName="isAutoDelivery" />
                                <span class="ml-2 mt-0 pt-0">{{
                                  'STORES.LOCATIONS.LOCATION_DATA.AUTO_DELIVERY' | translate
                                }}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="card">
                        <div class="card-header mb-3 pt-0">
                          <h4 class="card-title">
                            <i class="fa fa-file-text"></i>
                            <span class="mx-1">{{ 'STORES.LOCATIONS.POINT_OF_SALE_PRINT_TITLE' | translate }}</span>
                          </h4>
                        </div>
                        <div class="card-content">
                          <div class="px-3">
                            <div class="row">
                              <div class="form-group col-md-6">
                                <label for="primaryLogoText">{{
                                  'STORES.LOCATIONS.LOCATION_DATA.PRIMARY_LOGO_TEXT' | translate
                                }}</label>
                                <textarea
                                  id="primaryLogoText"
                                  class="form-control round"
                                  [placeholder]="
                                    'STORES.LOCATIONS.LOCATION_DATA.PRIMARY_LOGO_TEXT_PLACEHOLDER' | translate
                                  "
                                  rows="2"
                                  minlength="1"
                                  maxlength="200"
                                  formControlName="primaryLogoText"
                                ></textarea>
                              </div>
                              <div class="form-group col-md-6">
                                <label for="secondaryLogoText">{{
                                  'STORES.LOCATIONS.LOCATION_DATA.SECONDARY_LOGO_TEXT' | translate
                                }}</label>
                                <textarea
                                  id="secondaryLogoText"
                                  class="form-control round"
                                  [placeholder]="
                                    'STORES.LOCATIONS.LOCATION_DATA.SECONDARY_LOGO_TEXT_PLACEHOLDER' | translate
                                  "
                                  rows="2"
                                  minlength="1"
                                  maxlength="200"
                                  formControlName="secondaryLogoText"
                                ></textarea>
                              </div>
                              <div class="form-group col-md-6">
                                <label for="footerText">{{
                                  'STORES.LOCATIONS.LOCATION_DATA.FOOTER_TEXT' | translate
                                }}</label>
                                <textarea
                                  id="footerText"
                                  class="form-control round"
                                  [placeholder]="'STORES.LOCATIONS.LOCATION_DATA.FOOTER_TEXT_PLACEHOLDER' | translate"
                                  rows="2"
                                  minlength="1"
                                  maxlength="200"
                                  formControlName="footerText"
                                ></textarea>
                              </div>
                              <div class="form-group col-md-6">
                                <label for="printPaperSizeId">{{
                                  'STORES.LOCATIONS.LOCATION_DATA.PRINT_PAPER_SIZE' | translate
                                }}</label>
                                <ng-select
                                  class="round"
                                  formControlName="printPaperSizeId"
                                  labelForId="printPaperSizeId"
                                  searchable="true"
                                  [closeOnSelect]="true"
                                  [placeholder]="
                                    'STORES.LOCATIONS.LOCATION_DATA.PRINT_PAPER_SIZE_PLACEHOLDER' | translate
                                  "
                                  [notFoundText]="'SHARED.SEARCH.SEARCH_NOT_FOUND' | translate"
                                >
                                  <ng-option
                                    *ngFor="let printPaperSize of printPaperSizes$ | async"
                                    [value]="printPaperSize.id"
                                  >
                                    {{ printPaperSize.name | localize: printPaperSize.nameEn | async }}
                                  </ng-option>
                                </ng-select>
                              </div>
                              <div class="form-group col-md-12">
                                <div class="form-group col-md-auto">
                                  <input
                                    type="checkbox"
                                    class="cursor-pointer"
                                    formControlName="automaticallyShowPrintDialogForNewSaleInvoice"
                                  />
                                  <span class="ml-2 mt-0 pt-0">{{
                                    'STORES.LOCATIONS.LOCATION_DATA.AUTO_SHOW_PRINT_DIALOG_FOR_NEW_SALE_INVOICE'
                                      | translate
                                  }}</span>
                                </div>
                                <div class="form-group col-md-auto">
                                  <input type="checkbox" formControlName="allowSaleProductsWithoutStockFromPOS" />
                                  <span class="ml-2 mt-0 pt-0">{{
                                    'STORES.LOCATIONS.LOCATION_DATA.ALLOW_SALE_PRODUCTS_WITHOUT_STOCK_FROM_POS'
                                      | translate
                                  }}</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- update point of sale form ends -->
                </ng-template>
              </li>
            </ul>
            <div [ngbNavOutlet]="nav" class="mt-2"></div>
          </div>
        </div>
        <div class="row mt-2">
          <div class="form-group col-12 d-flex justify-content-between flex-column flex-sm-row mt-2">
            <button type="submit" class="btn btn-raised btn-success round" [disabled]="isUpdating$ | async">
              <i class="fa fa-check"></i>
              <span class="mx-1"> {{ 'SHARED.CONFIRM.SAVE' | translate }} </span>
            </button>
            <a class="btn btn-dark round" routerLink="/stores/locations">
              <i class="fa fa-undo"></i>
              <span class="mx-1">{{ 'STORES.LOCATIONS.LOCATION_DATA.RETURN_LOCATIONS' | translate }}</span>
            </a>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
<!-- update location pages ends -->

<!-- confirm modal starts -->
<ng-template #confirmModalRef let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
      {{ 'STORES.LOCATIONS.CONFIRM_UPDATE_LOCATION_TITLE' | translate }}
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="d()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p class="text-success">{{ 'STORES.LOCATIONS.CONFIRM_UPDATE_LOCATION_MESSAGE' | translate }}</p>
  </div>
  <div class="modal-footer">
    <div class="modal-actions">
      <button type="button" class="btn btn-raised btn-success round" (click)="c(); confirm()" ngbAutofocus>
        <i class="fa fa-check"></i>
        <span class="mx-1"> {{ 'SHARED.CONFIRM.SAVE' | translate }} </span>
      </button>
      <button type="button" class="btn btn-outline-dark round" (click)="c()">
        <i class="fa fa-times"></i>
        <span class="mx-1"> {{ 'SHARED.CONFIRM.CANCEL' | translate }} </span>
      </button>
    </div>
  </div>
</ng-template>
<!-- confirm modal ends -->

<!-- confirm delete primary logo modal starts -->
<ng-template #confirmDeletePrimaryLogoRef let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title text-warning" id="modal-basic-title">
      {{ 'STORES.LOCATIONS.CONFIRM_DELETE_PRIMARY_LOGO_TITLE' | translate }}
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="d()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p class="text-warning">{{ 'STORES.LOCATIONS.CONFIRM_DELETE_PRIMARY_LOGO_MESSAGE' | translate }}</p>
  </div>
  <div class="modal-footer">
    <div class="modal-actions">
      <button
        type="button"
        class="btn btn-raised btn-warning round"
        (click)="c(); deletePrimaryLogoConfirm()"
        ngbAutofocus
      >
        <i class="fa fa-trash"></i>
        <span class="mx-1"> {{ 'SHARED.CONFIRM.DELETE' | translate }} </span>
      </button>
      <button type="button" class="btn btn-outline-dark round" (click)="c()">
        <i class="fa fa-times"></i>
        <span class="mx-1"> {{ 'SHARED.CONFIRM.CANCEL' | translate }} </span>
      </button>
    </div>
  </div>
</ng-template>
<!-- confirm delete primary logo modal ends -->

<!-- confirm update primary logo modal starts -->
<ng-template #confirmUpdatePrimaryLogoRef let-c="close" let-d="dismiss">
  <form [formGroup]="updateLogoForm">
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">
        {{ 'STORES.LOCATIONS.CONFIRM_UPDATE_PRIMARY_LOGO_TITLE' | translate }}
      </h4>
      <button type="button" class="close" aria-label="Close" (click)="d()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="row">
        <div class="form-group col-md-12">
          <app-file-upload formControlName="logo"></app-file-upload>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <div class="modal-actions">
        <button
          type="button"
          class="btn btn-raised btn-success round"
          (click)="c(); updatePrimaryLogoConfirm()"
          [disabled]="isUpdating$ | async"
          ngbAutofocus
        >
          <i class="fa fa-edit"></i>
          <span class="mx-1"> {{ 'SHARED.CONFIRM.SAVE' | translate }} </span>
        </button>
        <button type="button" class="btn btn-outline-dark round" (click)="c()">
          <i class="fa fa-times"></i>
          <span class="mx-1"> {{ 'SHARED.CONFIRM.CANCEL' | translate }} </span>
        </button>
      </div>
    </div>
  </form>
</ng-template>
<!-- confirm update primary logo modal ends -->

<!-- confirm delete secondary logo modal starts -->
<ng-template #confirmDeleteSecondaryLogoRef let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title text-warning" id="modal-basic-title">
      {{ 'STORES.LOCATIONS.CONFIRM_DELETE_SECONDARY_LOGO_TITLE' | translate }}
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="d()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p class="text-warning">{{ 'STORES.LOCATIONS.CONFIRM_DELETE_SECONDARY_LOGO_MESSAGE' | translate }}</p>
  </div>
  <div class="modal-footer">
    <div class="modal-actions">
      <button
        type="button"
        class="btn btn-raised btn-warning round"
        (click)="c(); deleteSecondaryLogoConfirm()"
        ngbAutofocus
      >
        <i class="fa fa-trash"></i>
        <span class="mx-1"> {{ 'SHARED.CONFIRM.DELETE' | translate }} </span>
      </button>
      <button type="button" class="btn btn-outline-dark round" (click)="c()">
        <i class="fa fa-times"></i>
        <span class="mx-1"> {{ 'SHARED.CONFIRM.CANCEL' | translate }} </span>
      </button>
    </div>
  </div>
</ng-template>
<!-- confirm delete secondary logo modal ends -->

<!-- confirm update image header modal starts -->
<ng-template #confirmUpdateImageHeaderRef let-c="close" let-d="dismiss">
  <form [formGroup]="updateHeaderForm">
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">
        {{ 'STORES.LOCATIONS.CONFIRM_ADD_IMAGE_HEADER_TITLE' | translate }}
      </h4>
      <button type="button" class="close" aria-label="Close" (click)="d()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="row">
        <div class="form-group col-md-12">
          <app-file-upload formControlName="invoiceHeaderFilename"></app-file-upload>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <div class="modal-actions">
        <button
          type="button"
          class="btn btn-raised btn-success round"
          (click)="c(); updateImageHeaderConfirm()"
          [disabled]="isUpdating$ | async"
          ngbAutofocus
        >
          <i class="fa fa-edit"></i>
          <span class="mx-1"> {{ 'SHARED.CONFIRM.SAVE' | translate }} </span>
        </button>
        <button type="button" class="btn btn-outline-dark round" (click)="c()">
          <i class="fa fa-times"></i>
          <span class="mx-1"> {{ 'SHARED.CONFIRM.CANCEL' | translate }} </span>
        </button>
      </div>
    </div>
  </form>
</ng-template>
<!-- confirm update image header modal ends -->

<!-- confirm update image footer modal starts -->
<ng-template #confirmUpdateImageFooterRef let-c="close" let-d="dismiss">
  <form [formGroup]="updateFooterForm">
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">
        {{ 'STORES.LOCATIONS.CONFIRM_ADD_IMAGE_FOOTER_TITLE' | translate }}
      </h4>
      <button type="button" class="close" aria-label="Close" (click)="d()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="row">
        <div class="form-group col-md-12">
          <app-file-upload formControlName="invoiceFooterFilename"></app-file-upload>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <div class="modal-actions">
        <button
          type="button"
          class="btn btn-raised btn-success round"
          (click)="c(); updateImageFooterConfirm()"
          [disabled]="isUpdating$ | async"
          ngbAutofocus
        >
          <i class="fa fa-edit"></i>
          <span class="mx-1"> {{ 'SHARED.CONFIRM.SAVE' | translate }} </span>
        </button>
        <button type="button" class="btn btn-outline-dark round" (click)="c()">
          <i class="fa fa-times"></i>
          <span class="mx-1"> {{ 'SHARED.CONFIRM.CANCEL' | translate }} </span>
        </button>
      </div>
    </div>
  </form>
</ng-template>
<!-- confirm update image footer modal ends -->

<!-- confirm update secondary logo modal starts -->
<ng-template #confirmUpdateSecondaryLogoRef let-c="close" let-d="dismiss">
  <form [formGroup]="updateLogoForm">
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">
        {{ 'STORES.LOCATIONS.CONFIRM_UPDATE_SECONDARY_LOGO_TITLE' | translate }}
      </h4>
      <button type="button" class="close" aria-label="Close" (click)="d()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="row">
        <div class="form-group col-md-12">
          <app-file-upload formControlName="logo"></app-file-upload>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <div class="modal-actions">
        <button
          type="button"
          class="btn btn-raised btn-success round"
          (click)="c(); updateSecondaryLogoConfirm()"
          [disabled]="isUpdating$ | async"
          ngbAutofocus
        >
          <i class="fa fa-edit"></i>
          <span class="mx-1"> {{ 'SHARED.CONFIRM.SAVE' | translate }} </span>
        </button>
        <button type="button" class="btn btn-outline-dark round" (click)="c()">
          <i class="fa fa-times"></i>
          <span class="mx-1"> {{ 'SHARED.CONFIRM.CANCEL' | translate }} </span>
        </button>
      </div>
    </div>
  </form>
</ng-template>
<!-- confirm update primary logo modal ends -->

<!-- cost centers search starts -->
<app-cost-centers-search
  onlyActive="true"
  closeOnSelect="true"
  (costCenters)="selectCostCenter($event)"
  [(visible)]="costCentersListVisibility"
>
</app-cost-centers-search>
<!-- cost centers search ends -->

<!-- confirm delete invoice footer image modal starts -->
<ng-template #confirmDeleteInvoiceFooterImageModalRef let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title text-warning" id="modal-basic-title">
      {{ 'STORES.LOCATIONS.CONFIRM_DELETE_INVOICE_FOOTER_IMAGE_TITLE' | translate }}
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="d()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p class="text-warning">
      {{ 'STORES.LOCATIONS.CONFIRM_DELETE_INVOICE_FOOTER_IMAGE_MESSAGE' | translate }}
    </p>
  </div>
  <div class="modal-footer">
    <div class="modal-actions">
      <button
        type="button"
        class="btn btn-raised btn-warning round"
        (click)="c(); deleteImageFooterConfirm()"
        ngbAutofocus
      >
        <i class="fa fa-trash"></i>
        <span class="mx-1"> {{ 'SHARED.CONFIRM.DELETE' | translate }} </span>
      </button>
      <button type="button" class="btn btn-outline-dark round" (click)="c()">
        <i class="fa fa-times"></i>
        <span class="mx-1"> {{ 'SHARED.CONFIRM.CANCEL' | translate }} </span>
      </button>
    </div>
  </div>
</ng-template>
<!-- confirm delete invoice footer image modal ends -->

<!-- confirm delete invoice footer image modal starts -->
<ng-template #confirmDeleteInvoiceHeaderImageModalRef let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title text-warning" id="modal-basic-title">
      {{ 'STORES.LOCATIONS.CONFIRM_DELETE_INVOICE_HEADER_IMAGE_TITLE' | translate }}
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="d()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p class="text-warning">
      {{ 'STORES.LOCATIONS.CONFIRM_DELETE_INVOICE_HEADER_IMAGE_MESSAGE' | translate }}
    </p>
  </div>
  <div class="modal-footer">
    <div class="modal-actions">
      <button
        type="button"
        class="btn btn-raised btn-warning round"
        (click)="c(); deleteImageHeaderConfirm()"
        ngbAutofocus
      >
        <i class="fa fa-trash"></i>
        <span class="mx-1"> {{ 'SHARED.CONFIRM.DELETE' | translate }} </span>
      </button>
      <button type="button" class="btn btn-outline-dark round" (click)="c()">
        <i class="fa fa-times"></i>
        <span class="mx-1"> {{ 'SHARED.CONFIRM.CANCEL' | translate }} </span>
      </button>
    </div>
  </div>
</ng-template>
<!-- confirm delete invoice footer image modal ends -->

<!-- regions search starts -->
<app-regions-search
  onlyActive="true"
  closeOnSelect="true"
  (regions)="selectRegion($event)"
  [(visible)]="regionsListVisibility"
>
</app-regions-search>
<!-- regions search ends -->

<!-- sectors search starts -->
<app-sectors-search
  onlyActive="true"
  closeOnSelect="true"
  (sectors)="selectSector($event)"
  [(visible)]="sectorsListVisibility"
>
</app-sectors-search>
<!-- sectors search ends -->
