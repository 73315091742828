/**
 * The set of system supported leave types.
 */
export enum LeaveTypes {
  /**
   * The annual leave type of leave types.
   */
  ANNUAL_LEAVE = 'ANNUAL_LEAVE',

  /**
   * The sick leave type of leave types.
   */
  SICK_LEAVE = 'SICK_LEAVE',

  /**
   * The paid leave type of leave types.
   */
  PAID_LEAVE = 'PAID_LEAVE',

  /**
   * The unpaid leave type of leave types.
   */
  UNPAID_LEAVE = 'UNPAID_LEAVE',
}
