import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';

import { Observable, Subscription } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { tap } from 'rxjs/operators';

import { PageInfo, PaginationInfo } from 'shared';
import * as fromFinancesStore from 'finances/store';
import { Currency, CurrencyExchangeRate } from 'finances/models';
import { Claims } from 'security/models';

@Component({
  selector: 'app-currency-exchange-rates-list',
  templateUrl: './currency-exchange-rates-list.component.html',
  styles: [],
})
export class CurrencyExchangeRatesListComponent implements OnInit, OnDestroy {
  /**
   * Gets or sets the information about the current page.
   */
  pageInfo: PageInfo = {
    title: 'FINANCES.CURRENCY_EXCHANGE_RATE.CURRENCY_EXCHANGE_RATE_DATA.CURRENCY_EXCHANGE_RATE',
    icon: 'fa fa-money',
  };

  /**
   * The system supported user claims.
   */
  Claims = Claims;

  /**
   * Shows or hide the currency list in search.
   */
  primaryCurrencyListVisibility = false;

  /**
   * Shows or hide the currency list in search.
   */
  secondaryCurrencyListVisibility = false;

  /**
   * The list of selected currencies.
   */
  primaryCurrencies: Currency[] = [];

  /**
   * The list of selected currencies.
   */
  secondaryCurrencies: Currency[] = [];

  /**
   * The list of currency exchange rate.
   */
  currencyExchangeRates$: Observable<CurrencyExchangeRate[]>;

  /**
   * Indicates whether there is a delete-currency-exchange-rate process is running or not.
   */
  isDeleting$: Observable<boolean>;

  /**
   * The pagination info.
   */
  paginationInfo$: Observable<PaginationInfo>;

  /**
   * Indicates whether there is a search process is running or not.
   */
  isSearching$: Observable<boolean>;

  /** The initial `fromDate` value for search. */
  initialFromDate = new Date();

  /** The initial `toDate` value for search.
   * It's the current day of month.
   */
  initialToDate = new Date();

  /**
   * The search form.
   */
  searchForm: FormGroup;

  /**
   * The set of subscriptions on this components,
   * these subscriptions must be unsubscribed before this component got destroyed.
   */
  subscriptions = new Subscription();

  /**
   * Gets or sets the currently selected currency exchange rate for delete.
   */
  selectedCurrencyExchangeRate: CurrencyExchangeRate;

  /**
   * @param modalService The modal service.
   * @param financesStore$ The finances store.
   */
  constructor(private modalService: NgbModal, private financesStore$: Store<fromFinancesStore.FinancesState>) {}
  ngOnInit(): void {
    this.init();
  }

  /**
   * Destroy component data
   */
  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  /**
   * Initialize component data.
   */
  init() {
    this.initForm();

    /**
     * Load data.
     */
    this.isDeleting$ = this.financesStore$.pipe(select(fromFinancesStore.getSelectedCurrencyExchangeRateDeleting));
    this.isSearching$ = this.financesStore$.pipe(select(fromFinancesStore.getCurrenciesSearching));

    let isManualSearchTriggeredBeforeForCurrencies = false;
    this.subscriptions.add(
      this.financesStore$
        .pipe(
          select(fromFinancesStore.getCurrencies),
          tap((currencies) => {
            if (!isManualSearchTriggeredBeforeForCurrencies && !currencies.length) {
              isManualSearchTriggeredBeforeForCurrencies = true;
              this.searchCurrencies();
            }
          })
        )
        .subscribe()
    );

    let isManualSearchTriggeredBeforeForCurrencyExchangeRates = false;
    this.currencyExchangeRates$ = this.financesStore$.pipe(
      select(fromFinancesStore.getCurrencyExchangeRates),
      tap((currencyExchangeRates) => {
        if (!isManualSearchTriggeredBeforeForCurrencyExchangeRates && !currencyExchangeRates.length) {
          isManualSearchTriggeredBeforeForCurrencyExchangeRates = true;
          this.search();
        }
      })
    );

    this.paginationInfo$ = this.financesStore$.pipe(select(fromFinancesStore.getCurrencyExchangeRatesPaginationInfo));
  }

  /**
   * Initialize form and add validators.
   */
  initForm() {
    this.searchForm = new FormGroup({
      primaryCurrencies: new FormControl([]),
      secondaryCurrencies: new FormControl([]),
      fromDate: new FormControl({
        year: this.initialFromDate.getFullYear(),
        month: this.initialFromDate.getMonth() + 1,
        day: this.initialFromDate.getDate(),
      }),
      toDate: new FormControl({
        year: this.initialToDate.getFullYear(),
        month: this.initialToDate.getMonth() + 1,
        day: this.initialToDate.getDate(),
      }),
      page: new FormControl(1),
    });
  }

  /**
   * Handles search parameters change.
   */
  search(event?: KeyboardEvent) {
    if (event && event.key === 'Enter') {
      event.preventDefault();
      return;
    }

    this.applyFiltersAndSearch();
  }

  /**
   * Handles pagination page-changed event.
   * @param page The current selected page number.
   */
  pageChanged(page: number) {
    /** Update pagination page. */
    this.searchForm.patchValue({ page });

    this.applyFiltersAndSearch(page);
  }

  /**
   * Enables trackBy feature for *ngFor utility to track elements
   * depending on a comparer to reduce DOM change.
   * If the element is already exist then no HTML will change, only bounded values.
   * @param element The element to determine if it was changed or not.
   */
  trackElements(element: CurrencyExchangeRate) {
    return element ? element.id : undefined;
  }

  applyFiltersAndSearch(page: number = 1) {
    const { primaryCurrencies, secondaryCurrencies, fromDate, toDate } = this.searchForm.value;

    const fromDateFormatted = new Date(fromDate.year, fromDate.month - 1, fromDate.day);
    fromDateFormatted.setHours(0, 0, 0, 0);

    const toDateFormatted = new Date(toDate.year, toDate.month - 1, toDate.day);
    toDateFormatted.setHours(23, 59, 59, 0);

    this.financesStore$.dispatch(
      new fromFinancesStore.SearchCurrencyExchangeRates({
        primaryCurrencies,
        secondaryCurrencies,
        fromDate: fromDateFormatted,
        toDate: toDateFormatted,
        page,
      })
    );
  }

  /**
   * Deletes the currency with the given id.
   * @param currencyExchangeRateId The id of the currency to be deleted.
   */
  delete(currencyExchangeRateId: number) {
    this.financesStore$.dispatch(new fromFinancesStore.DeleteCurrencyExchangeRate(currencyExchangeRateId));
  }

  /**
   * Opens the modal of the given templateRef.
   * @param modalRef The modal templateRef to be opened.
   */
  openModal(modalRef) {
    this.modalService.open(modalRef);
  }

  /**
   * Adds the newly selected primaryCurrencies the primaryCurrencies search list.
   * @param primaryCurrencies The list of newly selected primaryCurrencies to be added.
   */
  selectPrimaryCurrencies(primaryCurrencies: Currency[]) {
    const selectedIds: number[] = this.searchForm.get('primaryCurrencies').value;
    this.primaryCurrencies = [
      ...this.primaryCurrencies.filter((currency) => selectedIds.includes(currency.id)),
      ...primaryCurrencies,
    ];
    this.searchForm.patchValue({ primaryCurrencies: this.primaryCurrencies.map((currency) => currency.id) });
  }

  /**
   * Adds the newly selected secondaryCurrencies the secondaryCurrencies search list.
   * @param secondaryCurrencies The list of newly selected secondaryCurrencies to be added.
   */
  selectSecondaryCurrencies(secondaryCurrencies: Currency[]) {
    const selectedIds: number[] = this.searchForm.get('secondaryCurrencies').value;
    this.secondaryCurrencies = [
      ...this.secondaryCurrencies.filter((currency) => selectedIds.includes(currency.id)),
      ...secondaryCurrencies,
    ];
    this.searchForm.patchValue({ secondaryCurrencies: this.secondaryCurrencies.map((currency) => currency.id) });
  }

  /**
   * search in currencies.
   */
  searchCurrencies() {
    this.financesStore$.dispatch(
      new fromFinancesStore.SearchCurrencies({
        name: '',
        page: 1,
      })
    );
  }
}
