import { findIndex } from 'lodash';

import { UsersActionType, UsersActions } from 'security/store/actions';
import { UsersState } from 'security/store/states';

/**
 * The initial state from which the state starts.
 */
const initialState: UsersState = {
  users: [],
  userClaims: [],
  userLocations: [],
  userBankAccounts: [],
  userCostCenters: [],
  paginationInfo: {
    total: 0,
    page: 1,
    pageSize: 0,
    count: 0,
  },
  error: null,
  isSearching: false,
  isSearchCompleted: false,
  isGetOrganizationUsersCompleted: false,
  isGettingOrganizationUsers: false,
  isGetUserLocationsCompleted: false,
  isGettingUserLocations: false,
  isGetUserBankAccountsCompleted: false,
  isGettingUserBankAccounts: false,
  isGetUserCostCentersCompleted: false,
  isGettingUserCostCenters: false,
  selectedUser: null,
  selectedOrganizationUser: null,
  selectedUserClaim: null,
  isFinding: false,
  isFindCompleted: false,
  isCreating: false,
  isCreateCompleted: false,
  isUpdating: false,
  isUpdateCompleted: false,
  isBlocking: false,
  isBlockCompleted: false,
  isActivating: false,
  isActivateCompleted: false,
  isUserClaimsUpdating: false,
  isUserClaimsUpdateCompleted: false,
  isUserLocationUpdating: false,
  isUserLocationUpdateCompleted: false,
  isUserBankAccountUpdating: false,
  isUserBankAccountUpdateCompleted: false,
  isUserCostCenterUpdating: false,
  isUserCostCenterUpdateCompleted: false,
  isOrganizationUserCreating: false,
  isCreateOrganizationUserCompleted: false,
};

/**
 * The reducer function that is called in each action dispatch against the state.
 * @param state The current state.
 * @param action The action that will affect the state.
 */
export function usersReducer(state: UsersState = initialState, action: UsersActions): UsersState {
  switch (action.type) {
    //#region SEARCH_USERS

    case UsersActionType.SEARCH_USERS: {
      return {
        ...state,
        isSearching: true,
        isSearchCompleted: false,
        error: null,
      };
    }

    case UsersActionType.SEARCH_USERS_FAIL: {
      return {
        ...state,
        error: action.payload,
        isSearching: false,
        isSearchCompleted: false,
      };
    }

    case UsersActionType.SEARCH_USERS_SUCCESS: {
      return {
        ...state,
        users: action.payload.data,
        paginationInfo: {
          ...action.payload.meta,
        },
        error: null,
        isSearching: false,
        isSearchCompleted: true,
      };
    }

    // #end region SEARCH_USERS

    //#region GET_ORGANIZATION_USERS

    case UsersActionType.GET_ORGANIZATION_USERS: {
      return {
        ...state,
        isGetOrganizationUsersCompleted: false,
        isGettingOrganizationUsers: true,
        error: null,
      };
    }

    case UsersActionType.GET_ORGANIZATION_USERS_FAIL: {
      return {
        ...state,
        error: action.payload,
        isGetOrganizationUsersCompleted: false,
        isGettingOrganizationUsers: false,
      };
    }

    case UsersActionType.GET_ORGANIZATION_USERS_SUCCESS: {
      return {
        ...state,
        users: action.payload.data,
        paginationInfo: {
          ...action.payload.meta,
        },
        error: null,
        isGetOrganizationUsersCompleted: true,
        isGettingOrganizationUsers: false,
      };
    }

    // #end region GET_ORGANIZATION_USERS

    //#region FIND_USER

    case UsersActionType.FIND_USER: {
      return {
        ...state,
        selectedUser: null,
        error: null,
        isFinding: true,
        isFindCompleted: false,
      };
    }

    case UsersActionType.FIND_USER_FAIL: {
      return {
        ...state,
        error: action.payload,
        isFinding: false,
        isFindCompleted: false,
      };
    }

    case UsersActionType.FIND_USER_SUCCESS: {
      let users = [...state.users];
      const userIndex = findIndex(users, (user) => user.id === action.payload.id);

      /* If user was found, update it. */
      if (userIndex >= 0) {
        users[userIndex] = action.payload;
      } else {
        /* else, insert it to the beginning of the array. */
        users = [action.payload, ...users];
      }

      return {
        ...state,
        users,
        selectedUser: action.payload,
        error: null,
        isFinding: false,
        isFindCompleted: true,
      };
    }

    //#endregion FIND_USER

    //#region CREATE_USER

    case UsersActionType.CREATE_USER: {
      return {
        ...state,
        isCreating: true,
        isCreateCompleted: false,
        error: null,
      };
    }

    case UsersActionType.CREATE_USER_FAIL: {
      return {
        ...state,
        error: action.payload,
        isCreating: false,
        isCreateCompleted: false,
      };
    }

    case UsersActionType.CREATE_USER_SUCCESS: {
      return {
        ...state,
        users: [action.payload.data, ...state.users],
        selectedUser: action.payload.data,
        error: null,
        isCreating: false,
        isCreateCompleted: true,
      };
    }

    //#endregion CREATE_USER

    //#region UPDATE_USER

    case UsersActionType.UPDATE_USER: {
      return {
        ...state,
        isUpdating: true,
        isUpdateCompleted: false,
        error: null,
      };
    }

    case UsersActionType.UPDATE_USER_FAIL: {
      return {
        ...state,
        error: action.payload,
        isUpdating: false,
        isUpdateCompleted: false,
      };
    }

    case UsersActionType.UPDATE_USER_SUCCESS: {
      let users = [...state.users];
      const userIndex = findIndex(users, (user) => user.id === action.payload.data.id);

      /* If user was found, update it. */
      if (userIndex >= 0) {
        users[userIndex] = action.payload.data;
      } else {
        /* else, insert it to the beginning of the array. */
        users = [action.payload.data, ...users];
      }

      return {
        ...state,
        users,
        selectedUser: action.payload.data,
        error: null,
        isUpdating: false,
        isUpdateCompleted: true,
      };
    }

    //#endregion UPDATE_USER

    //#region BLOCK_USER

    case UsersActionType.BLOCK_USER: {
      return {
        ...state,
        isBlocking: true,
        isBlockCompleted: false,
        error: null,
      };
    }

    case UsersActionType.BLOCK_USER_FAIL: {
      return {
        ...state,
        error: action.payload,
        isBlocking: false,
        isBlockCompleted: false,
      };
    }

    case UsersActionType.BLOCK_USER_SUCCESS: {
      let users = [...state.users];
      const userIndex = findIndex(users, (user) => user.id === action.payload.id);

      /* If user was found, update it. */
      if (userIndex >= 0) {
        users[userIndex] = action.payload;
      } else {
        /* else, insert it to the beginning of the array. */
        users = [action.payload, ...users];
      }

      return {
        ...state,
        users,
        selectedUser: action.payload,
        error: null,
        isBlocking: false,
        isBlockCompleted: true,
      };
    }

    //#endregion BLOCK_USER

    //#region ACTIVATE_USER

    case UsersActionType.ACTIVATE_USER: {
      return {
        ...state,
        isActivating: true,
        isActivateCompleted: false,
        error: null,
      };
    }

    case UsersActionType.ACTIVATE_USER_FAIL: {
      return {
        ...state,
        error: action.payload,
        isActivating: false,
        isActivateCompleted: false,
      };
    }

    case UsersActionType.ACTIVATE_USER_SUCCESS: {
      let users = [...state.users];
      const userIndex = findIndex(users, (user) => user.id === action.payload.id);

      /* If user was found, update it. */
      if (userIndex >= 0) {
        users[userIndex] = action.payload;
      } else {
        /* else, insert it to the beginning of the array. */
        users = [action.payload, ...users];
      }

      return {
        ...state,
        users,
        selectedUser: action.payload,
        error: null,
        isActivating: false,
        isActivateCompleted: true,
      };
    }

    //#endregion ACTIVATE_USER

    //#region UPDATE_USER_CLAIMS

    case UsersActionType.UPDATE_ORGANIZATION_USER_CLAIMS: {
      return {
        ...state,
        isUserClaimsUpdating: true,
        isUserClaimsUpdateCompleted: false,
        error: null,
      };
    }

    case UsersActionType.UPDATE_ORGANIZATION_USER_CLAIMS_FAIL: {
      return {
        ...state,
        error: action.payload,
        isUserClaimsUpdating: false,
        isUserClaimsUpdateCompleted: false,
      };
    }

    case UsersActionType.UPDATE_ORGANIZATION_USER_CLAIMS_SUCCESS: {
      return {
        ...state,
        selectedUser: action.payload.data,
        error: null,
        isUserClaimsUpdating: false,
        isUserClaimsUpdateCompleted: true,
      };
    }

    //#endregion UPDATE_USER_CLAIMS

    //#region UPDATE_USER_LOCATIONS

    case UsersActionType.UPDATE_USER_LOCATIONS: {
      return {
        ...state,
        isUserLocationUpdating: true,
        isUserLocationUpdateCompleted: false,
        error: null,
      };
    }

    case UsersActionType.UPDATE_USER_LOCATIONS_FAIL: {
      return {
        ...state,
        error: action.payload,
        isUserLocationUpdating: false,
        isUserLocationUpdateCompleted: false,
      };
    }

    case UsersActionType.UPDATE_USER_LOCATIONS_SUCCESS: {
      return {
        ...state,
        selectedUser: action.payload.data,
        error: null,
        isUserLocationUpdating: false,
        isUserLocationUpdateCompleted: true,
      };
    }

    //#endregion UPDATE_USER_LOCATIONS

    //#region UPDATE_USER_BANK_ACCOUNTS

    case UsersActionType.UPDATE_USER_BANK_ACCOUNTS: {
      return {
        ...state,
        isUserBankAccountUpdating: true,
        isUserBankAccountUpdateCompleted: false,
        error: null,
      };
    }

    case UsersActionType.UPDATE_USER_BANK_ACCOUNTS_FAIL: {
      return {
        ...state,
        error: action.payload,
        isUserBankAccountUpdating: false,
        isUserBankAccountUpdateCompleted: false,
      };
    }

    case UsersActionType.UPDATE_USER_BANK_ACCOUNTS_SUCCESS: {
      return {
        ...state,
        selectedUser: action.payload.data,
        error: null,
        isUserBankAccountUpdating: false,
        isUserBankAccountUpdateCompleted: true,
      };
    }

    //#endregion UPDATE_USER_BANK_ACCOUNTS

    //#region UPDATE_USER_COST_CENTERS

    case UsersActionType.UPDATE_USER_COST_CENTERS: {
      return {
        ...state,
        isUserCostCenterUpdating: true,
        isUserCostCenterUpdateCompleted: false,
        error: null,
      };
    }

    case UsersActionType.UPDATE_USER_COST_CENTERS_FAIL: {
      return {
        ...state,
        error: action.payload,
        isUserCostCenterUpdating: false,
        isUserCostCenterUpdateCompleted: false,
      };
    }

    case UsersActionType.UPDATE_USER_COST_CENTERS_SUCCESS: {
      return {
        ...state,
        selectedUser: action.payload.data,
        error: null,
        isUserCostCenterUpdating: false,
        isUserCostCenterUpdateCompleted: true,
      };
    }

    //#endregion UPDATE_USER_COST_CENTERS

    //#region RESET_USERS_CREDENTIALS

    case UsersActionType.RESET_USERS_CREDENTIALS: {
      return {
        ...state,
        isUpdating: true,
        isUpdateCompleted: false,
        error: null,
      };
    }

    case UsersActionType.RESET_USERS_CREDENTIALS_FAIL: {
      return {
        ...state,
        error: action.payload,
        isUpdating: false,
        isUpdateCompleted: false,
      };
    }

    case UsersActionType.RESET_USERS_CREDENTIALS_SUCCESS: {
      let users = [...state.users];
      const userIndex = findIndex(users, (user) => user.id === action.payload.data.id);

      /* If user was found, update it. */
      if (userIndex >= 0) {
        users[userIndex] = action.payload.data;
      } else {
        /* else, insert it to the beginning of the array. */
        users = [action.payload.data, ...users];
      }

      return {
        ...state,
        users,
        selectedUser: action.payload.data,
        error: null,
        isUpdating: false,
        isUpdateCompleted: true,
      };
    }

    //#endregion UPDATE_USER

    //#region CREATE_ORGANIZATION_USER

    case UsersActionType.CREATE_ORGANIZATION_USER: {
      return {
        ...state,
        isCreating: true,
        isCreateCompleted: false,
        error: null,
      };
    }

    case UsersActionType.CREATE_ORGANIZATION_USER_FAIL: {
      return {
        ...state,
        error: action.payload,
        isCreating: false,
        isCreateCompleted: false,
      };
    }

    case UsersActionType.CREATE_ORGANIZATION_USER_SUCCESS: {
      return {
        ...state,
        users: [action.payload.data, ...state.users],
        selectedOrganizationUser: action.payload.data,
        error: null,
        isCreating: false,
        isCreateCompleted: true,
      };
    }

    //#endregion CREATE_ORGANIZATION_USER

    //#region GET_USER_LOCATIONS

    case UsersActionType.GET_USER_LOCATIONS: {
      return {
        ...state,
        isGetUserLocationsCompleted: false,
        isGettingUserLocations: true,
        error: null,
      };
    }

    case UsersActionType.GET_USER_LOCATIONS_FAIL: {
      return {
        ...state,
        error: action.payload,
        isGetUserLocationsCompleted: false,
        isGettingUserLocations: false,
      };
    }

    case UsersActionType.GET_USER_LOCATIONS_SUCCESS: {
      return {
        ...state,
        userLocations: action.payload.data,
        error: null,
        isGetUserLocationsCompleted: true,
        isGettingUserLocations: false,
      };
    }

    // #end region GET_USER_LOCATIONS

    //#region GET_USER_BANK_ACCOUNTS

    case UsersActionType.GET_USER_BANK_ACCOUNTS: {
      return {
        ...state,
        isGetUserBankAccountsCompleted: false,
        isGettingUserBankAccounts: true,
        error: null,
      };
    }

    case UsersActionType.GET_USER_BANK_ACCOUNTS_FAIL: {
      return {
        ...state,
        error: action.payload,
        isGetUserBankAccountsCompleted: false,
        isGettingUserBankAccounts: false,
      };
    }

    case UsersActionType.GET_USER_BANK_ACCOUNTS_SUCCESS: {
      return {
        ...state,
        userBankAccounts: action.payload.data,
        error: null,
        isGetUserBankAccountsCompleted: true,
        isGettingUserBankAccounts: false,
      };
    }

    // #end region GET_USER_BANK_ACCOUNTS

    //#region GET_USER_COST_CENTERS

    case UsersActionType.GET_USER_COST_CENTERS: {
      return {
        ...state,
        isGetUserCostCentersCompleted: false,
        isGettingUserCostCenters: true,
        error: null,
      };
    }

    case UsersActionType.GET_USER_COST_CENTERS_FAIL: {
      return {
        ...state,
        error: action.payload,
        isGetUserCostCentersCompleted: false,
        isGettingUserCostCenters: false,
      };
    }

    case UsersActionType.GET_USER_COST_CENTERS_SUCCESS: {
      return {
        ...state,
        userCostCenters: action.payload.data,
        error: null,
        isGetUserCostCentersCompleted: true,
        isGettingUserCostCenters: false,
      };
    }

    // #end region GET_USER_COST_CENTERS

    default:
      return state;
  }
}
