import { createSelector } from '@ngrx/store';

import { SalesState, CustomerEngagementsState } from 'sales/store/states';
import { selectSalesState } from 'sales/store/selectors/feature.selectors';

/**
 * Gets the customer engagements state.
 */
const selectCustomerEngagementsState = createSelector(
  selectSalesState,
  (state: SalesState) => state.customerEngagements
);

/**
 * Gets the list of loaded customer engagements.
 */
export const getCustomerEngagements = createSelector(
  selectCustomerEngagementsState,
  (state: CustomerEngagementsState) => state.data
);

/**
 * Gets the customer engagements pagination info.
 */
export const getCustomerEngagementsPaginationInfo = createSelector(
  selectCustomerEngagementsState,
  (state: CustomerEngagementsState) => state.paginationInfo
);

/**
 * Gets the error that occurred in the state.
 */
export const getCustomerEngagementsError = createSelector(
  selectCustomerEngagementsState,
  (state: CustomerEngagementsState) => state.error
);

/**
 * Determines if there is a running search process.
 */
export const getCustomerEngagementsSearching = createSelector(
  selectCustomerEngagementsState,
  (state: CustomerEngagementsState) => state.isSearching
);

/**
 * Determines if the last search process has been ended successfully.
 */
export const getCustomerEngagementsSearchCompleted = createSelector(
  selectCustomerEngagementsState,
  (state: CustomerEngagementsState) => state.isSearchCompleted
);

/**
 * Gets the selected customer engagements.
 */
export const getSelectedCustomerEngagement = createSelector(
  selectCustomerEngagementsState,
  (state: CustomerEngagementsState) => state.selectedCustomerEngagement
);

/**
 * Determines if there is a running find-customer-engagements process.
 */
export const getCustomerEngagementsFinding = createSelector(
  selectCustomerEngagementsState,
  (state: CustomerEngagementsState) => state.isFinding
);

/**
 * Determines if the last find-customer-engagements process has been ended successfully.
 */
export const getCustomerEngagementsFindCompleted = createSelector(
  selectCustomerEngagementsState,
  (state: CustomerEngagementsState) => state.isFindCompleted
);

/**
 * Determines if there is a running create process.
 */
export const getSelectedCustomerEngagementCreating = createSelector(
  selectCustomerEngagementsState,
  (state: CustomerEngagementsState) => state.isCreating
);

/**
 * Determines if the last create process has been ended successfully.
 */
export const getSelectedCustomerEngagementCreateCompleted = createSelector(
  selectCustomerEngagementsState,
  (state: CustomerEngagementsState) => state.isCreateCompleted
);

/**
 * Determines if there is a running update customer engagement process.
 */
export const getSelectedCustomerEngagementUpdating = createSelector(
  selectCustomerEngagementsState,
  (state: CustomerEngagementsState) => state.isUpdating
);

/**
 * Determines if the last update customer engagement process has been ended successfully.
 */
export const getSelectedCustomerEngagementUpdateCompleted = createSelector(
  selectCustomerEngagementsState,
  (state: CustomerEngagementsState) => state.isUpdateCompleted
);

/**
 * Determines if there is a running customer engagement delete process.
 */
export const getSelectedCustomerEngagementDeleting = createSelector(
  selectCustomerEngagementsState,
  (state: CustomerEngagementsState) => state.isDeleting
);

/**
 * Determines if the last delete customer engagement process has been ended successfully.
 */
export const getSelectedCustomerEngagementDeleteCompleted = createSelector(
  selectCustomerEngagementsState,
  (state: CustomerEngagementsState) => state.isDeleteCompleted
);

/**
 * Determines if there is a running get customer engagements process.
 */
export const getCustomerEngagementsGetting = createSelector(
  selectCustomerEngagementsState,
  (state: CustomerEngagementsState) => state.isGetting
);

/**
 * Determines if the last get customer engagements process has been ended successfully.
 */
export const getCustomerEngagementsGetCompleted = createSelector(
  selectCustomerEngagementsState,
  (state: CustomerEngagementsState) => state.isGetCompleted
);
