import { PaginationInfo } from 'shared/models';
import { Organization } from 'organizations/models';

/**
 * Represents the organizations state.
 */
export class OrganizationState {
  /**
   * The list of organizations.
   */
  public organizations: Organization[];

  /**
   * The pagination info for the organizations.
   */
  public paginationInfo: PaginationInfo;

  /**
   * An error that may resulting during processing some actions.
   */
  public error: any;

  /**
   * Determines if there is a running search process.
   */
  public isSearching: boolean;

  /**
   * Determines if the last search process has been ended successfully.
   */
  public isSearchCompleted: boolean;

  /**
   * The current selected organization.
   */
  public selectedOrganization: Organization;

  /**
   * Determines if there is a running find-organization process.
   */
  public isFinding: boolean;

  /**
   * Determines if the last find-organization process has been ended successfully.
   */
  public isFindCompleted: boolean;

  /**
   * Determines if there is a running create process.
   */
  public isCreating: boolean;

  /**
   * Determines if the last create process has been ended successfully.
   */
  public isCreateCompleted: boolean;

  /**
   * Determines if there is a running update process.
   */
  public isUpdating: boolean;

  /**
   * Determines if the last update process has been ended successfully.
   */
  public isUpdateCompleted: boolean;

  /**
   * Determines if there is a running block process.
   */
  public isBlocking: boolean;

  /**
   * Determines if the last block process has been ended successfully.
   */
  public isBlockCompleted: boolean;

  /**
   * Determines if there is a running activate process.
   */
  public isActivating: boolean;

  /**
   * Determines if the last activate process has been ended successfully.
   */
  public isActivateCompleted: boolean;

  /**
   * Determines if there is a running delete process.
   */
  public isDeleting: boolean;

  /**
   * Determines if the last delete process has been ended successfully.
   */
  public isDeleteCompleted: boolean;
}
