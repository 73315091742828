import { createSelector } from '@ngrx/store';

import { selectLookupsState } from 'lookups/store/selectors/feature.selectors';
import { LookupsState, PurchaseOrderReceiveTypeState } from 'lookups/store/states';

/**
 * Gets the purchaseOrderReceiveType state.
 */
const selectPurchaseOrderReceiveTypes = createSelector(
  selectLookupsState,
  (state: LookupsState) => state.purchaseOrderReceiveTypes
);

/**
 * Gets the list of loaded purchaseOrderReceiveTypes.
 */
export const getPurchaseOrderReceiveTypes = createSelector(
  selectPurchaseOrderReceiveTypes,
  (state: PurchaseOrderReceiveTypeState) => state.data
);

/**
 * Gets the error that occurred in the state.
 */
export const getPurchaseOrderReceiveTypesError = createSelector(
  selectPurchaseOrderReceiveTypes,
  (state: PurchaseOrderReceiveTypeState) => state.error
);

/**
 * Determines if there is a running load process.
 */
export const getPurchaseOrderReceiveTypesIsLoading = createSelector(
  selectPurchaseOrderReceiveTypes,
  (state: PurchaseOrderReceiveTypeState) => state.isLoading
);

/**
 * Determines if the last load process has been ended successfully.
 */
export const getPurchaseOrderReceiveTypesIsLoaded = createSelector(
  selectPurchaseOrderReceiveTypes,
  (state: PurchaseOrderReceiveTypeState) => state.isLoaded
);
