import { createSelector } from '@ngrx/store';

import { BankAccountState, AppSearchState } from 'app-search/store/states';
import { selectAppSearchState } from 'app-search/store/selectors/feature.selectors';

/* Selectors */

/**
 * Gets the bank accounts state.
 */
const selectBankAccountState = createSelector(selectAppSearchState, (state: AppSearchState) => state.bankAccounts);

/**
 * Gets the list of loaded bank accounts.
 */
export const getBankAccounts = createSelector(selectBankAccountState, (state: BankAccountState) => state.bankAccounts);

/**
 * Gets the bank accounts pagination info.
 */
export const getBankAccountsPaginationInfo = createSelector(
  selectBankAccountState,
  (state: BankAccountState) => state.paginationInfo
);

/**
 * Gets the error that occurred in the state.
 */
export const getBankAccountsError = createSelector(selectBankAccountState, (state: BankAccountState) => state.error);

/**
 * Determines if there is a running search process.
 */
export const getBankAccountsSearching = createSelector(
  selectBankAccountState,
  (state: BankAccountState) => state.isSearching
);

/**
 * Determines if the last search process has been ended successfully.
 */
export const getBankAccountsSearchCompleted = createSelector(
  selectBankAccountState,
  (state: BankAccountState) => state.isSearchCompleted
);
