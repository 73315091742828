import { createSelector } from '@ngrx/store';

import { selectLookupsState } from 'lookups/store/selectors/feature.selectors';
import { LookupsState, PositionTypeState } from 'lookups/store/states';

/**
 * Gets the positionType state.
 */
const selectPositionTypes = createSelector(selectLookupsState, (state: LookupsState) => state.positionTypes);

/**
 * Gets the list of loaded positionTypes.
 */
export const getPositionTypes = createSelector(selectPositionTypes, (state: PositionTypeState) => state.data);

/**
 * Gets the error that occurred in the state.
 */
export const getPositionTypesError = createSelector(selectPositionTypes, (state: PositionTypeState) => state.error);

/**
 * Determines if there is a running load process.
 */
export const getPositionTypesIsLoading = createSelector(
  selectPositionTypes,
  (state: PositionTypeState) => state.isLoading
);

/**
 * Determines if the last load process has been ended successfully.
 */
export const getPositionTypesIsLoaded = createSelector(
  selectPositionTypes,
  (state: PositionTypeState) => state.isLoaded
);
