import { Injectable } from '@angular/core';

import { of } from 'rxjs';
import { switchMap, map, catchError } from 'rxjs/operators';
import { Effect, ofType, Actions } from '@ngrx/effects';

import { LookupsService } from 'lookups/services';
import { LeadStatusActionType, GetAllLeadStatusesFail, GetAllLeadStatusesSuccess } from 'lookups/store/actions';

@Injectable()
export class LeadStatusEffects {
  constructor(private actions$: Actions, private lookupsService: LookupsService) {}

  /* ========================= GET_ALL_LEAD_STATUSES =================================== */
  @Effect()
  getAllLeadStatuses$ = this.actions$.pipe(
    ofType(LeadStatusActionType.GET_ALL_LEAD_STATUSES),
    switchMap(() =>
      this.lookupsService.getAllLeadStatuses().pipe(
        map((response) => new GetAllLeadStatusesSuccess(response)),
        catchError((error) => of(new GetAllLeadStatusesFail(error)))
      )
    )
  );
}
