/**
 * Represents a system supported payroll element classifications.
 */
export enum PayrollElementClassifications {
  /**
   * The earning value of payroll element classifications.
   */
  EARNING = 'EARNING',

  /**
   * The deduction value of payroll element classifications.
   */
  DEDUCTION = 'DEDUCTION',

  /**
   * The information value of payroll element classifications.
   */
  INFORMATION = 'INFORMATION',
}
