import { ActionReducerMap } from '@ngrx/store';

import { printsReducer } from 'printing/store/reducers/prints.reducer';
import { PrintingState } from 'printing/store/states';

/**
 * The Printing-Module store reducers.
 */
export const reducers: ActionReducerMap<PrintingState> = {
  prints: printsReducer,
};
