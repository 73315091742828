import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Location as AngularLocation } from '@angular/common';

import { Subscription, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import * as fromStoresStore from 'stores/store';
import * as fromLookupsStore from 'lookups/store';
import { PageInfo, NotificationService, NotificationMessage, TranslationService, APP_CONSTANTS } from 'shared';
import { City, LocationType, PrintPaperSize } from 'lookups/models';
import { CostCenter } from 'finances/models';
import { CanComponentDeactivate } from 'auth';
import { Region, Sector } from 'stores/models';

/**
 * The create location component pages.
 */
enum PAGES {
  location = 'location',
  'point-of-sale' = 'point-of-sale',
}

@Component({
  selector: 'app-create-location',
  templateUrl: './create-location.component.html',
  styles: [],
})
export class CreateLocationComponent implements OnInit, OnDestroy, CanComponentDeactivate {
  /**
   * The confirm modal template reference.
   */
  @ViewChild('confirmModalRef') confirmModalRef: ElementRef<any>;

  /**
   * Gets or sets the information about the current page.
   */
  pageInfo: PageInfo = {
    title: 'STORES.LOCATIONS.NEW_LOCATION_ADD',
    icon: 'fa fa-plus',
  };

  /**
   * Add mask to phone and mobile number.
   */
  public phonePattern = APP_CONSTANTS.phone.pattern;
  public phoneMask = APP_CONSTANTS.phone.mask;

  /**
   * Shows or hide the cost centers list.
   */
  costCentersListVisibility = false;

  /**
   * Shows or hide the regions list.
   */
  regionsListVisibility = false;

  /**
   * Shows or hide the sectors list.
   */
  sectorsListVisibility = false;

  /**
   * Get or set header heights.
   */
  headerHeights = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  /**
   * Get or set footer heights.
   */
  footerHeights = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  /**
   * The list of selected costCenters.
   */
  costCenters: CostCenter[] = [];

  /**
   * The list of selected regions.
   */
  regions: Region[] = [];

  /**
   * The list of selected sectors.
   */
  sectors: Sector[] = [];

  /**
   * The list of location types.
   */
  locationTypes$: Observable<LocationType[]>;

  /**
   * The set of system supported print paper sizes.
   */
  printPaperSizes$: Observable<PrintPaperSize[]>;

  /**
   * The list of cities.
   */
  cities$: Observable<City[]>;

  /**
   * Indicates whether there is a create-location process is running or not.
   */
  isCreating$: Observable<boolean>;

  /**
   * The create location form.
   */
  form: FormGroup;

  /**
   * The set of subscriptions on this components,
   * these subscriptions must be unsubscribed before this component got destroyed.
   */
  subscriptions = new Subscription();

  /**
   * Gets or sets the selected page.
   * @default 'location'
   */
  activePage: PAGES = PAGES.location;

  /**
   * @param modalService The modal service.
   * @param notificationService The notification service.
   * @param translationService The translation service.
   * @param locationService The location service.
   * @param storesStore$ The stores-store module.
   * @param lookupsStore$ the lookups-store module.
   */
  constructor(
    private modalService: NgbModal,
    private notificationService: NotificationService,
    private translationService: TranslationService,
    private locationService: AngularLocation,
    private storesStore$: Store<fromStoresStore.StoresState>,
    private lookupsStore$: Store<fromLookupsStore.LookupsState>
  ) {}
  ngOnInit(): void {
    this.init();
  }

  /**
   * Destroy component data
   */
  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  /**
   * Initialize component data.
   */
  init() {
    this.initForm();

    /**
     * Load data.
     */
    this.isCreating$ = this.storesStore$.pipe(select(fromStoresStore.getSelectedLocationCreating));

    let isManualSearchTriggeredBeforeForTypes = false;
    this.locationTypes$ = this.lookupsStore$.pipe(
      select(fromLookupsStore.getLocationTypes),
      tap((types) => {
        if (!isManualSearchTriggeredBeforeForTypes && !types.length) {
          isManualSearchTriggeredBeforeForTypes = true;
          this.lookupsStore$.dispatch(new fromLookupsStore.GetAllLocationType());
        }
      })
    );

    let isManualSearchTriggeredBeforeForCities = false;
    this.cities$ = this.lookupsStore$.pipe(
      select(fromLookupsStore.getCities),
      tap((cities) => {
        if (!isManualSearchTriggeredBeforeForCities && !cities.length) {
          isManualSearchTriggeredBeforeForCities = true;
          this.lookupsStore$.dispatch(new fromLookupsStore.GetAllCities());
        }
      })
    );

    let isManualSearchTriggeredBeforeForPrintPaperSizes = false;
    this.printPaperSizes$ = this.lookupsStore$.pipe(
      select(fromLookupsStore.getPrintPaperSizes),
      tap((printPaperSizes) => {
        if (!isManualSearchTriggeredBeforeForPrintPaperSizes && !printPaperSizes.length) {
          isManualSearchTriggeredBeforeForPrintPaperSizes = true;
          this.lookupsStore$.dispatch(new fromLookupsStore.GetAllPrintPaperSize());
        }
      })
    );
  }

  /**
   * Initialize form and add validators.
   */
  initForm() {
    const showInvoiceHeaderFormControl = new FormControl(false);
    const showInvoiceFooterFormControl = new FormControl(false);
    this.form = new FormGroup({
      code: new FormControl('', [Validators.required, Validators.minLength(1), Validators.maxLength(50)]),
      name: new FormControl('', [Validators.required, Validators.minLength(1), Validators.maxLength(50)]),
      nameEn: new FormControl('', [Validators.required, Validators.minLength(1), Validators.maxLength(50)]),
      regionId: new FormControl(null),
      sectorId: new FormControl(null),
      locationTypeId: new FormControl(null, Validators.required),
      representorName: new FormControl('', [Validators.required, Validators.minLength(1), Validators.maxLength(50)]),
      representorMobile: new FormControl('', [Validators.required, Validators.minLength(1), Validators.maxLength(11)]),
      costCenterId: new FormControl(null, Validators.required),
      enableStockLogs: new FormControl(false),
      autoCreateOutgoingStockForSaleInvoices: new FormControl(true),
      posDescription: new FormControl('', [Validators.minLength(0), Validators.maxLength(200)]),
      printPaperSizeId: new FormControl(null),
      saleInvoicePrintPaperSizeId: new FormControl(null, Validators.required),
      saleReturnPrintPaperSizeId: new FormControl(null, Validators.required),
      showLogoInPrintSaleInvoices: new FormControl(false),
      showDisplayNameInPrintSaleInvoices: new FormControl(false),
      showNotesInPrintSaleInvoices: new FormControl(false),
      showProductNotesInPrintSaleInvoices: new FormControl(false),
      showProductItemCodeInPrintSaleInvoices: new FormControl(false),
      showDisplayNameInPrintSalesReturn: new FormControl(false),
      showLogoInPrintSalesReturn: new FormControl(false),
      showLogoInPrintPurchaseInvoices: new FormControl(false),
      showDisplayNameInPrintPurchaseInvoices: new FormControl(false),
      showNotesInPrintPurchaseInvoices: new FormControl(false),
      showProductNotesInPrintPurchaseInvoices: new FormControl(false),
      showProductItemCodeInPrintPurchaseInvoices: new FormControl(false),
      showLogoInPrintPurchasesReturn: new FormControl(false),
      showDisplayNameInPrintPurchasesReturn: new FormControl(false),
      showInvoiceHeader: showInvoiceHeaderFormControl,
      showInvoiceFooter: showInvoiceFooterFormControl,
      headerHeight: new FormControl(null),
      footerHeight: new FormControl(null),
      buildingNo: new FormControl('', [Validators.minLength(0), Validators.maxLength(50)]),
      streetName: new FormControl('', [Validators.minLength(0), Validators.maxLength(50)]),
      district: new FormControl('', [Validators.minLength(0), Validators.maxLength(50)]),
      cityId: new FormControl(null, Validators.required),
      country: new FormControl('', [Validators.minLength(0), Validators.maxLength(50)]),
      postalCode: new FormControl('', [Validators.minLength(0), Validators.maxLength(50)]),
      additionalNo: new FormControl('', [Validators.minLength(0), Validators.maxLength(50)]),
      workDayStartsAt: new FormControl(''),
      workDayEndsAt: new FormControl(''),
      vatNumber: new FormControl('', [Validators.minLength(0), Validators.maxLength(50)]),
      otherSellerId: new FormControl('', [Validators.minLength(0), Validators.maxLength(50)]),
      allowSaleProductsWithoutStockFromPOS: new FormControl(true),
      isAutoDelivery: new FormControl(true),
      automaticallyShowPrintDialogForNewSaleInvoice: new FormControl(false),
      primaryLogoText: new FormControl(''),
      secondaryLogoText: new FormControl(''),
      footerText: new FormControl(''),
      orgName: new FormControl('', [Validators.minLength(0), Validators.maxLength(50)]),
      primaryLogo: new FormControl(null),
      secondaryLogo: new FormControl(null),
      invoiceHeaderFilename: new FormControl(null),
      invoiceFooterFilename: new FormControl(null),
    });

    /**
     * Subscription when show invoice header  form changes.
     */
    this.subscriptions.add(showInvoiceHeaderFormControl.valueChanges.subscribe(() => this.onInvoiceHeaderChange()));

    /**
     * Subscription when show invoice footer  form changes.
     */
    this.subscriptions.add(showInvoiceFooterFormControl.valueChanges.subscribe(() => this.onInvoiceFooterChange()));
  }

  /**
   * Submits the form.
   */
  submit() {
    if (this.form.invalid) {
      const errorMessage = new NotificationMessage();

      if (this.form.get('code').invalid) {
        errorMessage.title = this.translationService.translate(
          'STORES.LOCATIONS.LOCATION_DATA_VALIDATION.LOCATION_CODE_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate('STORES.LOCATIONS.LOCATION_DATA_VALIDATION.LOCATION_CODE_IS_REQUIRED'),
          this.translationService.translate('STORES.LOCATIONS.LOCATION_DATA_VALIDATION.LOCATION_CODE_LENGTH_ERROR'),
        ];
        this.activePage = PAGES.location;
      } else if (this.form.get('name').invalid) {
        errorMessage.title = this.translationService.translate(
          'STORES.LOCATIONS.LOCATION_DATA_VALIDATION.ARABIC_LOCATION_NAME_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate('STORES.LOCATIONS.LOCATION_DATA_VALIDATION.LOCATION_NAME_IS_REQUIRED'),
          this.translationService.translate('STORES.LOCATIONS.LOCATION_DATA_VALIDATION.LOCATION_NAME_LENGTH_ERROR'),
        ];
        this.activePage = PAGES.location;
      } else if (this.form.get('nameEn').invalid) {
        errorMessage.title = this.translationService.translate(
          'STORES.LOCATIONS.LOCATION_DATA_VALIDATION.ENGLISH_LOCATION_NAME_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate('STORES.LOCATIONS.LOCATION_DATA_VALIDATION.LOCATION_NAME_IS_REQUIRED'),
          this.translationService.translate('STORES.LOCATIONS.LOCATION_DATA_VALIDATION.LOCATION_NAME_LENGTH_ERROR'),
        ];
        this.activePage = PAGES.location;
      } else if (this.form.get('locationTypeId').invalid) {
        errorMessage.title = this.translationService.translate(
          'STORES.LOCATIONS.LOCATION_DATA_VALIDATION.LOCATION_TYPE_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate('STORES.LOCATIONS.LOCATION_DATA_VALIDATION.LOCATION_TYPE_IS_REQUIRED'),
        ];
        this.activePage = PAGES.location;
      } else if (this.form.get('representorName').invalid) {
        errorMessage.title = this.translationService.translate(
          'STORES.LOCATIONS.LOCATION_DATA_VALIDATION.LOCATION_RESPONSIBLE_NAME_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate(
            'STORES.LOCATIONS.LOCATION_DATA_VALIDATION.LOCATION_RESPONSIBLE_NAME_IS_REQUIRED'
          ),
          this.translationService.translate(
            'STORES.LOCATIONS.LOCATION_DATA_VALIDATION.LOCATION_RESPONSIBLE_NAME_LENGTH_ERROR'
          ),
        ];
        this.activePage = PAGES.location;
      } else if (this.form.get('representorMobile').invalid) {
        errorMessage.title = this.translationService.translate(
          'STORES.LOCATIONS.LOCATION_DATA_VALIDATION.LOCATION_RESPONSIBLE_MOBILE_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate(
            'STORES.LOCATIONS.LOCATION_DATA_VALIDATION.LOCATION_RESPONSIBLE_MOBILE_IS_REQUIRED'
          ),
          this.translationService.translate(
            'STORES.LOCATIONS.LOCATION_DATA_VALIDATION.LOCATION_RESPONSIBLE_MOBILE_LENGTH_ERROR'
          ),
        ];
        this.activePage = PAGES.location;
      } else if (this.form.get('costCenterId').invalid) {
        errorMessage.title = this.translationService.translate(
          'STORES.LOCATIONS.LOCATION_DATA_VALIDATION.COST_CENTER_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate('STORES.LOCATIONS.LOCATION_DATA_VALIDATION.COST_CENTER_IS_REQUIRED'),
        ];
        this.activePage = PAGES.location;
      } else if (this.form.get('cityId').invalid) {
        errorMessage.title = this.translationService.translate(
          'STORES.LOCATIONS.LOCATION_DATA_VALIDATION.CITY_NAME_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate('STORES.LOCATIONS.LOCATION_DATA_VALIDATION.CITY_NAME_IS_REQUIRED'),
        ];
      } else if (this.form.get('saleInvoicePrintPaperSizeId').invalid) {
        errorMessage.title = this.translationService.translate(
          'STORES.LOCATIONS.LOCATION_DATA_VALIDATION.SALE_INVOICES_PRINT_PAPER_SIZE_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate(
            'STORES.LOCATIONS.LOCATION_DATA_VALIDATION.SALE_INVOICES_PRINT_PAPER_SIZE_IS_REQUIRED'
          ),
        ];
      } else if (this.form.get('saleReturnPrintPaperSizeId').invalid) {
        errorMessage.title = this.translationService.translate(
          'STORES.LOCATIONS.LOCATION_DATA_VALIDATION.SALE_RETURNS_PRINT_PAPER_SIZE_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate(
            'STORES.LOCATIONS.LOCATION_DATA_VALIDATION.SALE_RETURNS_PRINT_PAPER_SIZE_IS_REQUIRED'
          ),
        ];
      } else if (this.form.get('headerHeight').invalid) {
        errorMessage.title = this.translationService.translate(
          'STORES.LOCATIONS.LOCATION_DATA_VALIDATION.HEADER_HEIGHT_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate('STORES.LOCATIONS.LOCATION_DATA_VALIDATION.HEADER_HEIGHT_IS_REQUIRED'),
        ];
      } else if (this.form.get('footerHeight').invalid) {
        errorMessage.title = this.translationService.translate(
          'STORES.LOCATIONS.LOCATION_DATA_VALIDATION.FOOTER_HEIGHT_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate('STORES.LOCATIONS.LOCATION_DATA_VALIDATION.FOOTER_HEIGHT_IS_REQUIRED'),
        ];
      }

      this.form.markAllAsTouched();
      return this.notificationService.warningWithTitle(errorMessage);
    } else {
      this.openModal(this.confirmModalRef);
    }
  }

  /**
   * Dynamically set the header hight when select show invoice header.
   */
  onInvoiceHeaderChange() {
    const showInvoiceHeader = this.form.controls.showInvoiceHeader.value;
    if (showInvoiceHeader === true) {
      this.form.controls.headerHeight.setValidators([Validators.required]);
    } else {
      this.form.controls.headerHeight.setValue(null);
      this.form.controls.headerHeight.clearValidators();
    }
    this.form.controls.headerHeight.updateValueAndValidity();
  }

  /**
   * Dynamically set the footer hight when select show invoice header.
   */
  onInvoiceFooterChange() {
    const showInvoiceFooter = this.form.controls.showInvoiceFooter.value;
    if (showInvoiceFooter === true) {
      this.form.controls.footerHeight.setValidators([Validators.required]);
    } else {
      this.form.controls.footerHeight.setValue(null);
      this.form.controls.footerHeight.clearValidators();
    }
    this.form.controls.footerHeight.updateValueAndValidity();
  }

  /**
   * Confirms the form submit.
   */
  confirm() {
    this.storesStore$.dispatch(
      new fromStoresStore.CreateLocation({
        ...this.form.value,
        regionId: this.form.value.regionId ?? null,
        sectorId: this.form.value.sectorId ?? null,
        costCenterId: this.form.value.costCenterId,
        primaryLogoText: this.form.value.primaryLogoText ?? '',
        secondaryLogoText: this.form.value.secondaryLogoText ?? '',
        footerText: this.form.value.footerText ?? '',
        vatNumber: this.form.value.vatNumber ?? '',
        primaryLogo: this.form.value.primaryLogo ?? null,
        secondaryLogo: this.form.value.secondaryLogo ?? null,
        invoiceHeaderFilename: this.form.value.invoiceHeaderFilename ?? null,
        invoiceFooterFilename: this.form.value.invoiceFooterFilename ?? null,
        headerHeight: this.form.value.showInvoiceHeader ? this.form.value.headerHeight : null,
        footerHeight: this.form.value.showInvoiceFooter ? this.form.value.footerHeight : null,
      })
    );
  }

  /**
   * Opens the modal of the given templateRef.
   * @param modalRef The modal templateRef to be opened.
   */
  openModal(modalRef) {
    this.modalService.open(modalRef);
  }

  /**
   * Selects the newly selected cost center from the costCenters search list.
   * @param costCenters The list of newly selected costCenters to select the only one in the list.
   */
  selectCostCenter([costCenter]: CostCenter[]) {
    if (costCenter) {
      this.costCenters = [costCenter];
      this.form.patchValue({ costCenterId: costCenter.id });
    }
  }

  /**
   * Selects the newly selected region from the regions search list.
   * @param regions The list of newly selected regions to select the only one in the list.
   */
  selectRegion([region]: Region[]) {
    if (region) {
      this.regions = [region];
      this.form.patchValue({ regionId: region.id });
    }
  }

  /**
   * Selects the newly selected sector from the sectors search list.
   * @param sectors The list of newly selected sectors to select the only one in the list.
   */
  selectSector([sector]: Sector[]) {
    if (sector) {
      this.sectors = [sector];
      this.form.patchValue({ sectorId: sector.id });
    }
  }

  /**
   * Confirms leaving the page before routing.
   */
  confirmDeactivate() {
    if (this.form.dirty === true) {
      /**
       * Message to warn the user before leaving the detailed form.
       */
      return confirm(this.translationService.translate('SHARED.CONFIRM.LEAVING'));
    }
    return true;
  }

  /**
   * Updates the browser url according to the selected page.
   * @param page The newly selected page.
   */
  selectedPageChanged(page: PAGES) {
    this.locationService.replaceState(`${this.locationService.path()}#${PAGES[page]}`);
  }
}
