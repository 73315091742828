/**
 * Provides utilities to handle the hard part of JavaScript which is related to date.
 */
export class DateUtil {
  /**
   * Converts the given `date` form string to year, month and day.
   * @param date The date to be converted.
   * @returns A date with format 'year-month-day'.
   */
  public static toDatePickerDate(date) {
    if (date) {
      const localDate = new Date(Date.parse(date));
      localDate.setHours(0, 0, 0, 0);

      const obj = { year: localDate.getFullYear(), month: localDate.getMonth() + 1, day: localDate.getDate() };
      return obj;
    }
  }

  /**
   * Converts the given `date` form to date only.
   * @param date The date to be converted.
   * @returns A date with format 'year-month-day'.
   */
  public static toDateOnly(date) {
    if (date) {
      let month = '' + (date.getMonth() + 1);
      let day = '' + date.getDate();

      if (month.length < 2) {
        month = '0' + month;
      }
      if (day.length < 2) {
        day = '0' + day;
      }

      return [date.getFullYear(), month, day].join('-');
    }
  }
}
