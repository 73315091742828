import { Action } from '@ngrx/store';

import { PurchaseOrderReceiveType } from 'lookups/models';
import { AppHttpResponse } from 'shared';

/**
 * The Lookups-Module store  purchase order receive type action types.
 */
export enum PurchaseOrderReceiveTypeActionType {
  GET_ALL_PURCHASE_ORDER_RECEIVE_TYPE = '[Lookups] [PurchaseOrderReceiveType] Get All Purchase Order Receive Types',
  GET_ALL_PURCHASE_ORDER_RECEIVE_TYPE_FAIL = '[Lookups] [PurchaseOrderReceiveType] Get All Purchase Order Receive Types Fail',
  GET_ALL_PURCHASE_ORDER_RECEIVE_TYPE_SUCCESS = '[Lookups] [PurchaseOrderReceiveType] Get All Purchase Order Receive Types Success',
}

/**
 * Represents a store  purchase order receive type action.
 */
export class GetAllPurchaseOrderReceiveType implements Action {
  /**
   * The type of the action.
   */
  readonly type = PurchaseOrderReceiveTypeActionType.GET_ALL_PURCHASE_ORDER_RECEIVE_TYPE;

  /**
   * Fires a new  purchase order receive type action.
   */
  constructor() {}
}

/**
 * Represents a store  purchase order receive type fail action.
 */
export class GetAllPurchaseOrderReceiveTypeFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = PurchaseOrderReceiveTypeActionType.GET_ALL_PURCHASE_ORDER_RECEIVE_TYPE_FAIL;

  /**
   * Fires a new  purchase order receive type fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store  purchase order receive type success action.
 */
export class GetAllPurchaseOrderReceiveTypeSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = PurchaseOrderReceiveTypeActionType.GET_ALL_PURCHASE_ORDER_RECEIVE_TYPE_SUCCESS;

  /**
   * Fires a  purchase order receive type success action.
   * @param payload An object contains the list of  purchase order receive types.
   */
  constructor(public payload: AppHttpResponse<PurchaseOrderReceiveType[]>) {}
}

/**
 * Lookups-module  purchase order receive type action types.
 */
export type PurchaseOrderReceiveTypeActions =
  | GetAllPurchaseOrderReceiveType
  | GetAllPurchaseOrderReceiveTypeFail
  | GetAllPurchaseOrderReceiveTypeSuccess;
