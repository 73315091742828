import { StatisticsActionType, StatisticsActions } from 'statistics/store/actions';
import { StatisticsState } from 'statistics/store/states';

/**
 * The initial state from which the state starts.
 */
const initialState: StatisticsState = {
  data: {},
  statisticsCharts: [],
  error: null,
  isLoading: false,
  isLoadCompleted: false,
  isChartsLoading: false,
  isChartsLoadCompleted: false,
};

/**
 * The reducer function that is called in each action dispatch against the state.
 * @param state The current state.
 * @param action The action that will affect the state.
 */
export function StatisticsReducer(state: StatisticsState = initialState, action: StatisticsActions): StatisticsState {
  switch (action.type) {
    //#region LOAD_STATISTICS

    case StatisticsActionType.LOAD_STATISTICS: {
      return {
        ...state,
        isLoading: true,
        isLoadCompleted: false,
        error: null,
      };
    }

    case StatisticsActionType.LOAD_STATISTICS_FAIL: {
      return {
        ...state,
        error: action.payload,
        isLoading: false,
        isLoadCompleted: false,
      };
    }

    case StatisticsActionType.LOAD_STATISTICS_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        error: null,
        isLoading: false,
        isLoadCompleted: true,
      };
    }

    //#endregion LOAD_STATISTICS

    //#region LOAD_STATISTICS_CHARTS

    case StatisticsActionType.LOAD_STATISTICS_CHARTS: {
      return {
        ...state,
        isChartsLoading: true,
        isChartsLoadCompleted: false,
        error: null,
      };
    }

    case StatisticsActionType.LOAD_STATISTICS_CHARTS_FAIL: {
      return {
        ...state,
        error: action.payload,
        isChartsLoading: false,
        isChartsLoadCompleted: false,
      };
    }

    case StatisticsActionType.LOAD_STATISTICS_CHARTS_SUCCESS: {
      return {
        ...state,
        statisticsCharts: action.payload.data,
        error: null,
        isChartsLoading: false,
        isChartsLoadCompleted: true,
      };
    }

    //#endregion LOAD_STATISTICS_CHARTS

    default:
      return state;
  }
}
