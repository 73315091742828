import { Injectable } from '@angular/core';

import { of } from 'rxjs';
import { switchMap, map, catchError, tap } from 'rxjs/operators';
import { Effect, ofType, Actions } from '@ngrx/effects';

import { NotificationService, StatusCode, PAGINATION, TranslationService } from 'shared';
import { HrService } from 'finances/services';
import {
  CreateEmployeeLoan,
  CreateEmployeeLoanFail,
  CreateEmployeeLoanSuccess,
  HrActionType,
  SearchEmployeeLoans,
  SearchEmployeeLoanSettlements,
  SearchEmployeeLoanSettlementsFail,
  SearchEmployeeLoanSettlementsSuccess,
  SearchEmployeeLoansFail,
  SearchEmployeeLoansSuccess,
  UpdateEmployeeLoan,
  UpdateEmployeeLoanFail,
  UpdateEmployeeLoanSuccess,
  SearchPayrolls,
  SearchPayrollsFail,
  SearchPayrollsSuccess,
  SearchPaidPayrolls,
  SearchPaidPayrollsFail,
  SearchPaidPayrollsSuccess,
  PayPayroll,
  PayPayrollFail,
  PayPayrollSuccess,
  DeletePayroll,
  DeletePayrollFail,
  DeletePayrollSuccess,
  FindPayrollPayment,
  FindPayrollPaymentSuccess,
  FindPayrollPaymentFail,
  CreatePayrollPayment,
  CreatePayrollPaymentSuccess,
  CreatePayrollPaymentFail,
  SearchPayrollPayments,
  SearchPayrollPaymentsSuccess,
  SearchPayrollPaymentsFail,
  SearchPayrollPaymentRefunds,
  SearchPayrollPaymentRefundsSuccess,
  SearchPayrollPaymentRefundsFail,
  CreatePayrollPaymentRefund,
  CreatePayrollPaymentRefundSuccess,
  CreatePayrollPaymentRefundFail,
  FindPayrollPaymentRefund,
  FindPayrollPaymentRefundSuccess,
  FindPayrollPaymentRefundFail,
  SearchGovServiceRequests,
  SearchGovServiceRequestsFail,
  SearchGovServiceRequestsSuccess,
  FindGovServiceRequest,
  FindGovServiceRequestFail,
  FindGovServiceRequestSuccess,
  CreatePayGovServiceRequest,
  CreatePayGovServiceRequestFail,
  CreatePayGovServiceRequestSuccess,
} from 'finances/store/actions';

@Injectable()
export class HrEffects {
  constructor(
    private actions$: Actions,
    private hrService: HrService,
    private notificationService: NotificationService,
    private translationService: TranslationService
  ) {}

  /* ========================= SEARCH_EMPLOYEE_LOANS =================================== */
  @Effect()
  employeeLoansSearch$ = this.actions$.pipe(
    ofType(HrActionType.SEARCH_EMPLOYEE_LOANS),
    switchMap(({ payload }: SearchEmployeeLoans) =>
      this.hrService
        .searchEmployeeLoans(
          payload.employees,
          payload.departments,
          payload.locations,
          payload.loanPaymentTypes,
          payload.fromDate,
          payload.toDate,
          payload.page,
          PAGINATION.EmployeeLoans
        )
        .pipe(
          map((response) => new SearchEmployeeLoansSuccess(response)),
          catchError((error) => of(new SearchEmployeeLoansFail(error)))
        )
    )
  );

  /* ========================= SEARCH_EMPLOYEE_LOANS_SETTLEMENTS =================================== */
  @Effect()
  employeeLoanSettlementsSearch$ = this.actions$.pipe(
    ofType(HrActionType.SEARCH_EMPLOYEE_LOAN_SETTLEMENTS),
    switchMap(({ payload }: SearchEmployeeLoanSettlements) =>
      this.hrService
        .searchEmployeeLoanSettlements(
          payload.employees,
          payload.departments,
          payload.locations,
          payload.fromDate,
          payload.toDate,
          payload.page,
          PAGINATION.EmployeeLoanSettlements
        )
        .pipe(
          map((response) => new SearchEmployeeLoanSettlementsSuccess(response)),
          catchError((error) => of(new SearchEmployeeLoanSettlementsFail(error)))
        )
    )
  );

  /* ========================= SEARCH_PAYROLLS =================================== */
  @Effect()
  paidPayrollsSearch$ = this.actions$.pipe(
    ofType(HrActionType.SEARCH_PAYROLLS),
    switchMap(({ payload }: SearchPayrolls) =>
      this.hrService
        .searchPayrolls(
          payload?.employees,
          payload?.departments,
          payload?.locations,
          payload?.payrollStatuses,
          payload.fromDate,
          payload.toDate,
          payload.page,
          PAGINATION.payrolls
        )
        .pipe(
          map((response) => new SearchPayrollsSuccess(response)),
          catchError((error) => of(new SearchPayrollsFail(error)))
        )
    )
  );

  /* ========================= SEARCH_PAID_PAYROLLS =================================== */
  @Effect()
  payPayrollsSearch$ = this.actions$.pipe(
    ofType(HrActionType.SEARCH_PAID_PAYROLLS),
    switchMap(({ payload }: SearchPaidPayrolls) =>
      this.hrService
        .searchPaidPayrolls(
          payload?.employees,
          payload?.departments,
          payload?.locations,
          payload.fromDate,
          payload.toDate,
          payload.page,
          PAGINATION.paidPayrolls
        )
        .pipe(
          map((response) => new SearchPaidPayrollsSuccess(response)),
          catchError((error) => of(new SearchPaidPayrollsFail(error)))
        )
    )
  );

  /* ========================= SEARCH_PAYROLL_PAYMENTS =================================== */
  @Effect()
  payrollPaymentsSearch$ = this.actions$.pipe(
    ofType(HrActionType.SEARCH_PAYROLL_PAYMENTS),
    switchMap(({ payload }: SearchPayrollPayments) =>
      this.hrService
        .searchPayrollPayments(
          payload?.employeePaymentMethods,
          payload.fromDate,
          payload.toDate,
          payload.page,
          PAGINATION.payrollPayments
        )
        .pipe(
          map((response) => new SearchPayrollPaymentsSuccess(response)),
          catchError((error) => of(new SearchPayrollPaymentsFail(error)))
        )
    )
  );

  /* ========================= SEARCH_PAYROLL_PAYMENT_REFUNDS =================================== */
  @Effect()
  payrollPaymentRefundsSearch$ = this.actions$.pipe(
    ofType(HrActionType.SEARCH_PAYROLL_PAYMENT_REFUNDS),
    switchMap(({ payload }: SearchPayrollPaymentRefunds) =>
      this.hrService
        .searchPayrollPaymentRefunds(
          payload?.employeePaymentMethods,
          payload.fromDate,
          payload.toDate,
          payload.page,
          PAGINATION.payrollPaymentRefunds
        )
        .pipe(
          map((response) => new SearchPayrollPaymentRefundsSuccess(response)),
          catchError((error) => of(new SearchPayrollPaymentRefundsFail(error)))
        )
    )
  );

  /* ========================= CREATE_EMPLOYEE_LOAN =================================== */
  @Effect()
  createEmployeeLoan$ = this.actions$.pipe(
    ofType(HrActionType.CREATE_EMPLOYEE_LOAN),
    switchMap((action: CreateEmployeeLoan) =>
      this.hrService.createEmployeeLoan(action.payload).pipe(
        map((response) => new CreateEmployeeLoanSuccess(response)),
        catchError((error) => of(new CreateEmployeeLoanFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  createEmployeeLoanSuccess$ = this.actions$.pipe(
    ofType(HrActionType.CREATE_EMPLOYEE_LOAN_SUCCESS),
    tap((action: CreateEmployeeLoanSuccess) => {
      this.notificationService.success(this.translationService.translate('HR.EMPLOYEES.LOAN_ADDED'));
    })
  );

  @Effect({ dispatch: false })
  createEmployeeLoanFail$ = this.actions$.pipe(
    ofType(HrActionType.CREATE_EMPLOYEE_LOAN_FAIL),
    tap((action: CreateEmployeeLoanFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );

  /* ========================= UPDATE_EMPLOYEE_LOAN =================================== */
  @Effect()
  updateEmployeeLoan$ = this.actions$.pipe(
    ofType(HrActionType.UPDATE_EMPLOYEE_LOAN),
    switchMap((action: UpdateEmployeeLoan) =>
      this.hrService.updateEmployeeLoan(action.payload).pipe(
        map((response) => new UpdateEmployeeLoanSuccess(response)),
        catchError((error) => of(new UpdateEmployeeLoanFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  updateEmployeeLoanSuccess$ = this.actions$.pipe(
    ofType(HrActionType.UPDATE_EMPLOYEE_LOAN_SUCCESS),
    tap((action: UpdateEmployeeLoanSuccess) => {
      this.notificationService.success(this.translationService.translate('HR.EMPLOYEES.LOAN_UPDATED'));
    })
  );

  @Effect({ dispatch: false })
  updateEmployeeLoanFail$ = this.actions$.pipe(
    ofType(HrActionType.UPDATE_EMPLOYEE_LOAN_FAIL),
    tap((action: UpdateEmployeeLoanFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );

  /* ========================= PREPARE_PAY_PAYROLL =================================== */
  @Effect()
  payPayroll$ = this.actions$.pipe(
    ofType(HrActionType.PAY_PAYROLL),
    switchMap((action: PayPayroll) =>
      this.hrService.preparePayPayroll(action.payload).pipe(
        map((response) => new PayPayrollSuccess(response)),
        catchError((error) => of(new PayPayrollFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  payPayrollSuccess$ = this.actions$.pipe(
    ofType(HrActionType.PAY_PAYROLL_SUCCESS),
    tap((action: PayPayrollSuccess) => {
      this.notificationService.success(
        this.translationService.translate(
          'FINANCES.HR.PAID_PAYROLLS.PAID_PAYROLLS_DATA_VALIDATION.PREPARE_PAYROLL_ADDED'
        )
      );
    })
  );

  @Effect({ dispatch: false })
  payPayrollFail$ = this.actions$.pipe(
    ofType(HrActionType.PAY_PAYROLL_FAIL),
    tap((action: PayPayrollFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );

  /* ========================= CREATE_PAYROLL_PAYMENT =================================== */
  @Effect()
  createPayrollPayment$ = this.actions$.pipe(
    ofType(HrActionType.CREATE_PAYROLL_PAYMENT),
    switchMap((action: CreatePayrollPayment) =>
      this.hrService.createPayrollPayment(action.payload).pipe(
        map((response) => new CreatePayrollPaymentSuccess(response)),
        catchError((error) => of(new CreatePayrollPaymentFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  createPayrollPaymentSuccess$ = this.actions$.pipe(
    ofType(HrActionType.CREATE_PAYROLL_PAYMENT_SUCCESS),
    tap((action: CreatePayrollPaymentSuccess) => {
      this.notificationService.success(
        this.translationService.translate('FINANCES.HR.PAID_PAYROLLS.PAID_PAYROLLS_DATA_VALIDATION.PAY_PAYROLL_ADDED')
      );
    })
  );

  @Effect({ dispatch: false })
  createPayrollPaymentFail$ = this.actions$.pipe(
    ofType(HrActionType.CREATE_PAYROLL_PAYMENT_FAIL),
    tap((action: CreatePayrollPaymentFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );

  /* ========================= CREATE_PAYROLL_PAYMENT_REFUND =================================== */
  @Effect()
  createPayrollPaymentRefund$ = this.actions$.pipe(
    ofType(HrActionType.CREATE_PAYROLL_PAYMENT_REFUND),
    switchMap((action: CreatePayrollPaymentRefund) =>
      this.hrService.createPayrollPaymentRefund(action.payload).pipe(
        map((response) => new CreatePayrollPaymentRefundSuccess(response)),
        catchError((error) => of(new CreatePayrollPaymentRefundFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  createPayrollPaymentRefundSuccess$ = this.actions$.pipe(
    ofType(HrActionType.CREATE_PAYROLL_PAYMENT_REFUND_SUCCESS),
    tap((action: CreatePayrollPaymentRefundSuccess) => {
      this.notificationService.success(
        this.translationService.translate(
          'FINANCES.HR.PAYROLL_PAYMENTS.PAYROLL_PAYMENTS_DATA_VALIDATION.PAYROLL_PAYMENT_REFUNDS_ADDED'
        )
      );
    })
  );

  @Effect({ dispatch: false })
  createPayrollPaymentRefundFail$ = this.actions$.pipe(
    ofType(HrActionType.CREATE_PAYROLL_PAYMENT_REFUND_FAIL),
    tap((action: CreatePayrollPaymentRefundFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );

  /* ========================= DELETE_PAYROLL=================================== */
  @Effect()
  deletePayroll$ = this.actions$.pipe(
    ofType(HrActionType.DELETE_PAYROLL),
    switchMap((action: DeletePayroll) =>
      this.hrService.deletePayroll(action.payload).pipe(
        map((response) => new DeletePayrollSuccess(response)),
        catchError((error) => of(new DeletePayrollFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  deletePayrollSuccess$ = this.actions$.pipe(
    ofType(HrActionType.DELETE_PAYROLL_SUCCESS),
    tap((action: DeletePayrollSuccess) => {
      this.notificationService.success(this.translationService.translate('HR.EMPLOYEES_MANAGEMENT.PAYROLL_DELETED'));
    })
  );

  @Effect({ dispatch: false })
  deletePayrollFail$ = this.actions$.pipe(
    ofType(HrActionType.DELETE_PAYROLL_FAIL),
    tap((action: DeletePayrollFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(this.translationService.translate('HR.EMPLOYEES_MANAGEMENT.PAYROLL_NOT_FOUND'));
      } else if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );

  /* ========================= FIND_PAYROLL_PAYMENT =================================== */
  @Effect()
  findPayrollPayment$ = this.actions$.pipe(
    ofType(HrActionType.FIND_PAYROLL_PAYMENT),
    switchMap((action: FindPayrollPayment) =>
      this.hrService.findPayrollPaymentById(action.payload).pipe(
        map((response) => new FindPayrollPaymentSuccess(response)),
        catchError((error) => of(new FindPayrollPaymentFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  findPayrollPaymentFail$ = this.actions$.pipe(
    ofType(HrActionType.FIND_PAYROLL_PAYMENT_FAIL),
    tap((action: FindPayrollPaymentFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate('FINANCES.HR.PAID_PAYROLLS.PAYROLL_PAYMENT_NOT_FOUND')
        );
      }
    })
  );

  /* ========================= FIND_PAYROLL_PAYMENT_REFUND =================================== */
  @Effect()
  findPayrollPaymentRefund$ = this.actions$.pipe(
    ofType(HrActionType.FIND_PAYROLL_PAYMENT_REFUND),
    switchMap((action: FindPayrollPaymentRefund) =>
      this.hrService.findPayrollPaymentRefundById(action.payload).pipe(
        map((response) => new FindPayrollPaymentRefundSuccess(response)),
        catchError((error) => of(new FindPayrollPaymentRefundFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  findPayrollPaymentRefundFail$ = this.actions$.pipe(
    ofType(HrActionType.FIND_PAYROLL_PAYMENT_REFUND_FAIL),
    tap((action: FindPayrollPaymentRefundFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate(
            'FINANCES.HR.PAYROLL_PAYMENTS.PAYROLL_PAYMENTS_DATA_VALIDATION.PAYROLL_PAYMENT_REFUND_NOT_FOUND'
          )
        );
      }
    })
  );

  /* ========================= SEARCH_GOV_SERVICE_REQUESTS =================================== */
  @Effect()
  searchGovServiceRequests$ = this.actions$.pipe(
    ofType(HrActionType.SEARCH_GOV_SERVICE_REQUESTS),
    switchMap(({ payload }: SearchGovServiceRequests) =>
      this.hrService
        .searchGovServiceRequests(
          payload.govServices,
          payload.statuses,
          payload.fromDate,
          payload.toDate,
          payload.page,
          PAGINATION.GovServiceRequests
        )
        .pipe(
          map((response) => new SearchGovServiceRequestsSuccess(response)),
          catchError((error) => of(new SearchGovServiceRequestsFail(error)))
        )
    )
  );

  /* ========================= FIND_GOV_SERVICE_REQUEST =================================== */
  @Effect()
  findGovServiceRequest$ = this.actions$.pipe(
    ofType(HrActionType.FIND_GOV_SERVICE_REQUEST),
    switchMap((action: FindGovServiceRequest) =>
      this.hrService.findGovServiceRequestById(action.payload).pipe(
        map((response) => new FindGovServiceRequestSuccess(response)),
        catchError((error) => of(new FindGovServiceRequestFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  findGovServiceRequestFail$ = this.actions$.pipe(
    ofType(HrActionType.FIND_GOV_SERVICE_REQUEST_FAIL),
    tap((action: FindGovServiceRequestFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate('HR.GOV_SERVICE_REQUESTS.SERVICE_REQUEST_NOT_FOUND')
        );
      }
    })
  );

  /* ========================= CREATE_PAY_GOV_SERVICE_REQUEST =================================== */
  @Effect()
  createPayGovServiceRequest$ = this.actions$.pipe(
    ofType(HrActionType.CREATE_PAY_GOV_SERVICE_REQUEST),
    switchMap((action: CreatePayGovServiceRequest) =>
      this.hrService.createPayGovServiceRequest(action.payload).pipe(
        map((response) => new CreatePayGovServiceRequestSuccess(response)),
        catchError((error) => of(new CreatePayGovServiceRequestFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  createPayGovServiceRequestSuccess$ = this.actions$.pipe(
    ofType(HrActionType.CREATE_PAY_GOV_SERVICE_REQUEST_SUCCESS),
    tap((action: CreatePayGovServiceRequestSuccess) => {
      this.notificationService.success(
        this.translationService.translate('HR.GOV_SERVICE_REQUESTS.SERVICE_REQUEST_ADDED')
      );
    })
  );

  @Effect({ dispatch: false })
  createPayGovServiceRequestFail$ = this.actions$.pipe(
    ofType(HrActionType.CREATE_PAY_GOV_SERVICE_REQUEST_FAIL),
    tap((action: CreatePayGovServiceRequestFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );
}
