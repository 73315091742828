<!-- gov service requests list starts -->
<div class="row">
  <div class="col-md-12">
    <div class="card-content">
      <div class="px-3">
        <div class="row">
          <div class="col-md-12">
            <form class="row" [formGroup]="searchForm" (ngSubmit)="search()">
              <div class="form-group col-md-6">
                <label for="govServices">
                  {{ 'HR.GOV_SERVICE_REQUESTS.GOV_SERVICES' | translate }}
                </label>
                <ng-select
                  class="round"
                  formControlName="govServices"
                  [multiple]="true"
                  [closeOnSelect]="false"
                  labelForId="govServices"
                  searchable="true"
                  [placeholder]="'HR.GOV_SERVICE_REQUESTS.GOV_SERVICES_PLACEHOLDER' | translate"
                  [notFoundText]="'SHARED.SEARCH.SEARCH_NOT_FOUND' | translate"
                  (open)="govServicesListVisibility = true"
                >
                  <ng-option *ngFor="let govService of govServices" [value]="govService.id">
                    {{ govService.name | localize: govService.nameEn | async }}
                  </ng-option>
                </ng-select>
              </div>
              <div class="form-group col-md-6">
                <label for="statuses">
                  {{ 'HR.GOV_SERVICE_REQUESTS.GOV_SERVICE_REQUEST_STATUSES' | translate }}
                </label>
                <ng-select
                  class="round"
                  formControlName="statuses"
                  [multiple]="true"
                  [closeOnSelect]="false"
                  labelForId="statuses"
                  searchable="true"
                  [placeholder]="'HR.GOV_SERVICE_REQUESTS.GOV_SERVICE_REQUEST_STATUSES_PLACEHOLDER' | translate"
                  [notFoundText]="'SHARED.SEARCH.SEARCH_NOT_FOUND' | translate"
                >
                  <ng-option *ngFor="let item of requestStatuses$ | async" [value]="item.id">
                    {{ item.name | localize: item.nameEn | async }}
                  </ng-option>
                </ng-select>
              </div>
              <div class="form-group col-md-3 col-sm-6 mb-0">
                <label for="fromDate">{{ 'SHARED.SEARCH.FROM_DATE' | translate }}</label>
                <div class="input-group round">
                  <input
                    class="form-control"
                    id="fromDate"
                    name="fromDate"
                    formControlName="fromDate"
                    [placeholder]="'SHARED.SEARCH.DATE_PLACEHOLDER' | translate"
                    ngbDatepicker
                    #fromDate="ngbDatepicker"
                  />
                  <div class="input-group-append">
                    <span
                      class="input-group-text gradient-light-blue-indigo"
                      style="cursor: pointer;"
                      (click)="fromDate.toggle()"
                    >
                      <i class="fa fa-calendar"></i>
                    </span>
                  </div>
                </div>
              </div>
              <div class="form-group col-md-3 col-sm-6 mb-0">
                <label for="toDate">{{ 'SHARED.SEARCH.TO_DATE' | translate }}</label>
                <div class="input-group round">
                  <input
                    class="form-control"
                    id="toDate"
                    name="toDate"
                    formControlName="toDate"
                    [placeholder]="'SHARED.SEARCH.DATE_PLACEHOLDER' | translate"
                    ngbDatepicker
                    #toDate="ngbDatepicker"
                  />
                  <div class="input-group-append">
                    <span
                      class="input-group-text gradient-light-blue-indigo"
                      style="cursor: pointer;"
                      (click)="toDate.toggle()"
                    >
                      <i class="fa fa-calendar"></i>
                    </span>
                  </div>
                </div>
              </div>
              <div class="col-md-auto form-group d-flex">
                <button
                  type="submit"
                  class="btn btn-raised btn-primary align-self-end round mx-1 mb-0"
                  [disabled]="isSearching$ | async"
                >
                  <i class="fa fa-search"></i>
                </button>
                <app-export-tools
                  *ngIf="(govServiceRequests$ | async)?.length"
                  class="d-flex align-self-end mx-1"
                  targetElementId="gov-service-requests-table-export-target-element"
                  [outputFileName]="'SHARED.EXPORT_TABLES.GOV_SERVICE_REQUEST_TABLE' | translate"
                ></app-export-tools>
              </div>
            </form>
          </div>
          <div class="col-md-12" *ngIf="(govServiceRequests$ | async)?.length; else noItems">
            <div class="row">
              <div class="col-md-12 alert alert-light text-center" role="alert">
                {{ 'SHARED.SEARCH.SEARCH_RESULTS' | translate }} ({{ (paginationInfo$ | async).total | toNumber }})
                {{ 'HR.GOV_SERVICE_REQUESTS.GOV_SERVICE_REQUEST_DATA.GOV_SERVICE_REQUEST' | translate }}
              </div>
              <div class="col-md-12">
                <div class="table-responsive">
                  <table class="table text-center" id="gov-service-requests-table-export-target-element">
                    <thead>
                      <tr>
                        <th scope="col"><i class="fa fa-list-ol"></i></th>
                        <th scope="col">
                          {{ 'HR.GOV_SERVICE_REQUESTS.GOV_SERVICE_REQUEST_DATA.GOV_SERVICE' | translate }}
                        </th>
                        <th scope="col">{{ 'HR.GOV_SERVICE_REQUESTS.GOV_SERVICE_REQUEST_DATA.VALUE' | translate }}</th>
                        <th scope="col">{{ 'HR.GOV_SERVICE_REQUESTS.GOV_SERVICE_REQUEST_DATA.STATUS' | translate }}</th>
                        <th scope="col">
                          {{ 'HR.GOV_SERVICE_REQUESTS.GOV_SERVICE_REQUEST_DATA.TRANSACTION_DATE' | translate }}
                        </th>
                        <th scope="col">
                          {{ 'HR.GOV_SERVICE_REQUESTS.GOV_SERVICE_REQUEST_DATA.CREATED_AT' | translate }}
                        </th>
                        <th scope="col">{{ 'SHARED.TOOLS' | translate }}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        *ngFor="
                          let govServiceRequest of govServiceRequests$ | async;
                          trackBy: trackElements;
                          let i = index
                        "
                      >
                        <th scope="row">{{ i + 1 }}</th>
                        <td>
                          {{
                            govServiceRequest.govService.name | localize: govServiceRequest.govService.nameEn | async
                          }}
                        </td>
                        <td>
                          {{ govServiceRequest.value | toDecimal }}
                        </td>
                        <td>
                          {{
                            govServiceRequest.govServiceRequestStatus.name
                              | localize: govServiceRequest.govServiceRequestStatus.nameEn
                              | async
                          }}
                        </td>
                        <td>
                          <span [ngbTooltip]="govServiceRequest.createTransactionDate | date: 'time'">
                            {{ govServiceRequest.createTransactionDate | date }}
                          </span>
                        </td>
                        <td>
                          <span [ngbTooltip]="govServiceRequest.createdAt | date: 'time'">
                            {{ govServiceRequest.createdAt | date }}
                          </span>
                        </td>
                        <td>
                          <a
                            class="info p-0"
                            [routerLink]="['/finances/hr/governmental-service-requests/view', govServiceRequest.id]"
                            [ngbTooltip]="
                              'HR.GOV_SERVICE_REQUESTS.GOV_SERVICE_REQUEST_DATA.DISPLAY_DETAILS_GOV_SERVICE_REQUEST'
                                | translate
                            "
                          >
                            <i class="fa fa-eye font-medium-3 mr-2"></i>
                          </a>

                          <ng-container *ngIf="govServiceRequest.govServiceRequestStatus.key !== 'PAID'">
                            <a
                              class="success p-0"
                              [routerLink]="['/finances/hr/governmental-service-requests/pay', govServiceRequest.id]"
                              [ngbTooltip]="
                                'HR.GOV_SERVICE_REQUESTS.GOV_SERVICE_REQUEST_DATA.PAY_GOV_SERVICE_REQUEST' | translate
                              "
                            >
                              <i class="fa fa-usd font-medium-3 mr-2"></i>
                            </a>
                          </ng-container>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div class="d-flex justify-content-between p-2">
                    <ngb-pagination
                      *ngIf="paginationInfo$ | async; let pagination"
                      maxSize="5"
                      [collectionSize]="pagination.total"
                      [pageSize]="pagination.pageSize"
                      [page]="pagination.page"
                      (pageChange)="pageChanged($event)"
                      boundaryLinks="true"
                    >
                      <ng-template ngbPaginationFirst>{{
                        'SHARED.PAGINATION.PAGINATION_FIRST' | translate
                      }}</ng-template>
                      <ng-template ngbPaginationFirst>{{
                        'SHARED.PAGINATION.PAGINATION_PREVIOUS' | translate
                      }}</ng-template>
                      <ng-template ngbPaginationFirst>{{
                        'SHARED.PAGINATION.PAGINATION_NEXT' | translate
                      }}</ng-template>
                      <ng-template ngbPaginationFirst>{{
                        'SHARED.PAGINATION.PAGINATION_LAST' | translate
                      }}</ng-template>
                    </ngb-pagination>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- gov service requests list ends -->

<!-- no items template starts -->
<ng-template #noItems>
  <div class="col-md-12 alert alert-warning mt-3 text-center" role="alert">
    {{ 'HR.GOV_SERVICE_REQUESTS.SEARCH_GOV_SERVICE_REQUESTS_NOT_FOUND' | translate }}
  </div>
</ng-template>
<!-- no items template ends -->

<!-- gov services search starts -->
<gov-services-search [(visible)]="govServicesListVisibility" (govServices)="selectGovServices($event)">
</gov-services-search>
<!-- gov services search ends -->
