import { MenuItem } from 'core/models/menu/menu-item.model';
import { Claims } from 'security/models';
import { environment } from '../../../../environments/environment';

/**
 * The sidebar menu items.
 */
export const MENU_ITEMS: MenuItem[] = [
  {
    route: '/dashboard',
    title: `CORE.MENU_ITEMS.DASHBOARD`,
    icon: 'fa fa-home',
    class: '',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    subMenu: [],
    claims: [],
  },
  {
    route: '/organizations',
    title: 'CORE.MENU_ITEMS.ORGANIZATIONS',
    icon: 'fa fa-institution',
    class: '',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    subMenu: [],
    claims: [
      Claims.CREATE_ORGANIZATION,
      Claims.UPDATE_ORGANIZATION,
      Claims.DELETE_ORGANIZATION,
      Claims.BLOCK_ORGANIZATION,
      Claims.ACTIVATE_ORGANIZATION,
    ],
  },
  {
    route: '',
    title: 'CORE.MENU_ITEMS.SETTINGS',
    icon: 'fa fa-cog',
    class: 'has-sub',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    subMenu: [
      {
        route: '/settings/taxes',
        title: 'CORE.MENU_ITEMS.TAXES',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        subMenu: [],
        claims: [Claims.CREATE_TAX, Claims.UPDATE_TAX, Claims.DELETE_TAX],
      },
    ],
    claims: [Claims.CREATE_TAX, Claims.UPDATE_TAX, Claims.DELETE_TAX],
  },
  {
    route: '',
    title: 'CORE.MENU_ITEMS.STOCKS',
    icon: 'fa fa-cube',
    class: 'has-sub',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    subMenu: [
      {
        route: '/stores/regions',
        title: 'CORE.MENU_ITEMS.REGIONS',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        subMenu: [],
        claims: [Claims.CREATE_LOCATION, Claims.UPDATE_LOCATION, Claims.DELETE_LOCATION],
      },
      {
        route: '/stores/sectors',
        title: 'CORE.MENU_ITEMS.SECTORS',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        subMenu: [],
        claims: [Claims.CREATE_LOCATION, Claims.UPDATE_LOCATION, Claims.DELETE_LOCATION],
      },
      {
        route: '/stores/locations',
        title: 'CORE.MENU_ITEMS.LOCATIONS',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        subMenu: [],
        claims: [Claims.CREATE_LOCATION, Claims.UPDATE_LOCATION, Claims.DELETE_LOCATION],
      },
      {
        route: '/stores/unit-of-measure-classes',
        title: 'CORE.MENU_ITEMS.UNIT_OF_MEASURE_CLASSES',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        subMenu: [],
        claims: [
          Claims.CREATE_UNIT_OF_MEASURE_CLASS,
          Claims.UPDATE_UNIT_OF_MEASURE_CLASS,
          Claims.DELETE_UNIT_OF_MEASURE_CLASS,
        ],
      },
      {
        route: '/stores/units-of-measure',
        title: 'CORE.MENU_ITEMS.UNITS_OF_MEASURE',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        subMenu: [],
        claims: [Claims.CREATE_UNIT_OF_MEASURE, Claims.UPDATE_UNIT_OF_MEASURE, Claims.DELETE_UNIT_OF_MEASURE],
      },
      {
        route: '/stores/variants',
        title: 'CORE.MENU_ITEMS.PRODUCT_VARIANTS',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        subMenu: [],
        claims: [Claims.CREATE_VARIANT, Claims.UPDATE_VARIANT, Claims.DELETE_VARIANT],
      },
      {
        route: '/stores/product-classes',
        title: 'CORE.MENU_ITEMS.PRODUCTS_CLASSES',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        subMenu: [],
        claims: [Claims.CREATE_PRODUCT_CLASS, Claims.UPDATE_PRODUCT_CLASS, Claims.DELETE_PRODUCT_CLASS],
      },

      {
        route: '/stores/products',
        title: 'CORE.MENU_ITEMS.PRODUCTS',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        subMenu: [],
        claims: [Claims.CREATE_PRODUCT, Claims.UPDATE_PRODUCT, Claims.DELETE_PRODUCT],
      },
      {
        route: '/stores/discounts',
        title: 'CORE.MENU_ITEMS.DISCOUNTS',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        subMenu: [],
        claims: [Claims.UPDATE_PRODUCT],
      },
      {
        route: '/stocks/incoming-stocks',
        title: 'CORE.MENU_ITEMS.INCOMING_STOCK',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        subMenu: [],
        claims: [Claims.CREATE_INCOMING_STOCK],
      },
      {
        route: '/stocks/outgoing-stocks',
        title: 'CORE.MENU_ITEMS.OUTGOING_STOCK',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        subMenu: [],
        claims: [Claims.CREATE_OUTGOING_STOCK],
      },
      {
        route: '/stocks/transfer-stocks',
        title: 'CORE.MENU_ITEMS.TRANSFER_STOCK',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        subMenu: [],
        claims: [Claims.CREATE_TRANSFER_STOCK],
      },
      {
        route: '/stores/product-stock-query',
        title: 'CORE.MENU_ITEMS.PRODUCT_STOCK_QUERY',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        subMenu: [],
        claims: [],
      },
    ],
    claims: [
      Claims.CREATE_LOCATION,
      Claims.UPDATE_LOCATION,
      Claims.DELETE_LOCATION,
      Claims.CREATE_PRODUCT_CLASS,
      Claims.UPDATE_PRODUCT_CLASS,
      Claims.DELETE_PRODUCT_CLASS,
      Claims.CREATE_PRODUCT,
      Claims.UPDATE_PRODUCT,
      Claims.DELETE_PRODUCT,
      Claims.CREATE_INCOMING_STOCK,
      Claims.CREATE_OUTGOING_STOCK,
      Claims.CREATE_TRANSFER_STOCK,
      Claims.CREATE_UNIT_OF_MEASURE_CLASS,
      Claims.UPDATE_UNIT_OF_MEASURE_CLASS,
      Claims.DELETE_UNIT_OF_MEASURE_CLASS,
      Claims.CREATE_UNIT_OF_MEASURE,
      Claims.UPDATE_UNIT_OF_MEASURE,
      Claims.DELETE_UNIT_OF_MEASURE,
      Claims.CREATE_VARIANT,
      Claims.UPDATE_VARIANT,
      Claims.DELETE_VARIANT,
    ],
  },
  {
    route: '',
    title: 'CORE.MENU_ITEMS.PURCHASES',
    icon: 'fa fa-cart-plus',
    class: 'has-sub',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    subMenu: [
      {
        route: '/purchases/vendors',
        title: 'CORE.MENU_ITEMS.VENDORS',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        subMenu: [],
        claims: [
          Claims.CREATE_VENDOR,
          Claims.UPDATE_VENDOR,
          Claims.DELETE_VENDOR,
          Claims.BLOCK_VENDOR,
          Claims.ACTIVATE_VENDOR,
          Claims.CREATE_VENDOR_PAYMENT,
        ],
      },
      {
        route: '/purchases/requests',
        title: 'CORE.MENU_ITEMS.PURCHASE_REQUESTS',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        subMenu: [],
        claims: [Claims.CREATE_PURCHASE_REQUEST, Claims.APPROVE_PURCHASE_REQUEST, Claims.CANCEL_PURCHASE_REQUEST],
      },
      {
        route: '/purchases/orders',
        title: 'CORE.MENU_ITEMS.PURCHASE_ORDERS',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        subMenu: [],
        claims: [Claims.CREATE_PURCHASE_ORDER, Claims.CANCEL_PURCHASE_ORDER],
      },
      {
        route: '/purchases/returns',
        title: 'CORE.MENU_ITEMS.PURCHASE_RETURNS',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        subMenu: [],
        claims: [Claims.CREATE_PURCHASE_RETURN],
      },
    ],
    claims: [
      Claims.CREATE_VENDOR,
      Claims.UPDATE_VENDOR,
      Claims.DELETE_VENDOR,
      Claims.BLOCK_VENDOR,
      Claims.ACTIVATE_VENDOR,
      Claims.CREATE_VENDOR_PAYMENT,
      Claims.CREATE_PURCHASE_REQUEST,
      Claims.APPROVE_PURCHASE_REQUEST,
      Claims.CANCEL_PURCHASE_REQUEST,
      Claims.CREATE_PURCHASE_ORDER,
      Claims.CANCEL_PURCHASE_ORDER,
    ],
  },
  {
    route: '',
    title: 'CORE.MENU_ITEMS.CRM',
    icon: 'fa fa-gear',
    class: 'has-sub',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    subMenu: [
      {
        route: '/crm/leads',
        title: 'CORE.MENU_ITEMS.LEADS',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        subMenu: [],
        claims: [Claims.CREATE_LEAD, Claims.UPDATE_LEAD, Claims.DELETE_LEAD],
      },
      {
        route: '/crm/quotations',
        title: 'CORE.MENU_ITEMS.QUOTATIONS',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        subMenu: [],
        claims: [Claims.CREATE_LEAD, Claims.UPDATE_LEAD, Claims.DELETE_LEAD],
      },
    ],
    claims: [Claims.CREATE_LEAD, Claims.UPDATE_LEAD, Claims.DELETE_LEAD],
  },
  {
    route: '',
    title: 'CORE.MENU_ITEMS.SALES',
    icon: 'fa fa-opencart',
    class: 'has-sub',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    subMenu: [
      {
        route: '/sales/customers',
        title: 'CORE.MENU_ITEMS.CUSTOMERS',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        subMenu: [],
        claims: [
          Claims.CREATE_CUSTOMER,
          Claims.UPDATE_CUSTOMER,
          Claims.DELETE_CUSTOMER,
          Claims.BLOCK_CUSTOMER,
          Claims.ACTIVATE_CUSTOMER,
          Claims.CREATE_CUSTOMER_ENGAGEMENT,
          Claims.UPDATE_CUSTOMER_ENGAGEMENT,
          Claims.DELETE_CUSTOMER_ENGAGEMENT,
          Claims.CREATE_CUSTOMER_PAYMENT,
        ],
      },
      {
        route: '/sales/customer-engagements',
        title: 'CORE.MENU_ITEMS.CUSTOMER_ENGAGEMENTS',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        subMenu: [],
        claims: [
          Claims.CREATE_CUSTOMER_ENGAGEMENT,
          Claims.UPDATE_CUSTOMER_ENGAGEMENT,
          Claims.DELETE_CUSTOMER_ENGAGEMENT,
        ],
      },
      {
        route: '/sales/customer-assets',
        title: 'CORE.MENU_ITEMS.CUSTOMER_ASSETS',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        subMenu: [],
        claims: [Claims.UPDATE_CUSTOMER],
      },
      {
        route: '/sales/invoices',
        title: 'CORE.MENU_ITEMS.SALES_INVOICES',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        subMenu: [],
        claims: [
          Claims.CREATE_SALE_INVOICE,
          Claims.CREATE_MAINTENANCE_SALE_INVOICE,
          Claims.CREATE_CUSTOMER_OPENING_BALANCE_INVOICE,
          Claims.CREATE_POINT_OF_SALE_INVOICE,
        ],
      },
      {
        route: '/sales/orders-monitoring',
        title: 'CORE.MENU_ITEMS.ORDERS_MONITORING',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        subMenu: [],
        claims: [Claims.ORDERS_MONITORING],
      },
      {
        route: '/sales/orders-delivery',
        title: 'CORE.MENU_ITEMS.ORDERS_DELIVERY',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        subMenu: [],
        claims: [Claims.ORDERS_DELIVERING],
      },
      {
        route: '/sales/returns',
        title: 'CORE.MENU_ITEMS.SALES_RETURNS',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        subMenu: [],
        claims: [Claims.CREATE_SALE_RETURN],
      },
      {
        route: '/sales/pos-home',
        title: 'CORE.MENU_ITEMS.POS',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        subMenu: [],
        claims: [Claims.CREATE_POS_DEVICE, Claims.UPDATE_POS_DEVICE, Claims.DELETE_POS_DEVICE],
      },
      {
        route: '/sales/boards',
        title: 'CORE.MENU_ITEMS.BOARDS',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        subMenu: [],
        claims: [Claims.CREATE_BOARD, Claims.UPDATE_BOARD, Claims.DELETE_BOARD],
      },
      {
        route: '/sales/my-shifts',
        title: 'CORE.MENU_ITEMS.MY_SHIFTS',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        subMenu: [],
        claims: [Claims.START_NEW_SHIFT],
      },
      {
        route: '/sales/shifts',
        title: 'CORE.MENU_ITEMS.ALL_SHIFTS',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        subMenu: [],
        claims: [Claims.START_SHIFT_FOR_CASHER, Claims.END_SHIFT_FOR_CASHER, Claims.DELETE_CASHER_SHIFT],
      },
    ],
    claims: [
      Claims.CREATE_CUSTOMER,
      Claims.UPDATE_CUSTOMER,
      Claims.DELETE_CUSTOMER,
      Claims.BLOCK_CUSTOMER,
      Claims.ACTIVATE_CUSTOMER,
      Claims.CREATE_CUSTOMER_ENGAGEMENT,
      Claims.UPDATE_CUSTOMER_ENGAGEMENT,
      Claims.DELETE_CUSTOMER_ENGAGEMENT,
      Claims.CREATE_SALE_INVOICE,
      Claims.CREATE_MAINTENANCE_SALE_INVOICE,
      Claims.CREATE_CUSTOMER_OPENING_BALANCE_INVOICE,
      Claims.CREATE_POINT_OF_SALE_INVOICE,
      Claims.CREATE_SALE_RETURN,
      Claims.CREATE_CUSTOMER_PAYMENT,
      Claims.ORDERS_MONITORING,
      Claims.ORDERS_DELIVERING,
      Claims.START_NEW_SHIFT,
      Claims.CREATE_BOARD,
      Claims.UPDATE_BOARD,
      Claims.DELETE_BOARD,
      Claims.CREATE_POS_DEVICE,
      Claims.UPDATE_POS_DEVICE,
      Claims.DELETE_POS_DEVICE,
    ],
  },

  {
    route: '',
    title: 'CORE.MENU_ITEMS.HR',
    icon: 'fa fa-group',
    class: 'has-sub',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    subMenu: [
      {
        route: '/hr/employees-management',
        title: 'CORE.MENU_ITEMS.EMPLOYEES_MANAGEMENT',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        subMenu: [],
        claims: [],
      },
      {
        route: '/hr/governmental-relations',
        title: 'CORE.MENU_ITEMS.GOV_RELATIONS',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        subMenu: [],
        claims: [],
      },
      {
        route: '/hr/employees',
        title: 'CORE.MENU_ITEMS.EMPLOYEES',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        subMenu: [],
        claims: [Claims.CREATE_EMPLOYEE],
      },
      {
        route: '/hr/employee-sponsors',
        title: 'CORE.MENU_ITEMS.EMPLOYEE_SPONSORS',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        subMenu: [],
        claims: [Claims.CREATE_EMPLOYEE],
      },
      {
        route: '/hr/payroll-elements',
        title: 'CORE.MENU_ITEMS.PAYROLL_ELEMENTS',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        subMenu: [],
        claims: [],
      },
      {
        route: '/hr/departments',
        title: 'CORE.MENU_ITEMS.DEPARTMENTS',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        subMenu: [],
        claims: [],
      },
      {
        route: '/hr/grades',
        title: 'CORE.MENU_ITEMS.GRADES',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        subMenu: [],
        claims: [],
      },
      {
        route: '/hr/grade-steps',
        title: 'CORE.MENU_ITEMS.GRADE_STEPS',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        subMenu: [],
        claims: [],
      },
      {
        route: '/hr/job-families',
        title: 'CORE.MENU_ITEMS.JOB_FAMILIES',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        subMenu: [],
        claims: [],
      },
      {
        route: '/hr/jobs',
        title: 'CORE.MENU_ITEMS.JOBS',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        subMenu: [],
        claims: [],
      },
      {
        route: '/hr/positions',
        title: 'CORE.MENU_ITEMS.POSITIONS',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        subMenu: [],
        claims: [],
      },
      {
        route: '/hr/configurations',
        title: 'CORE.MENU_ITEMS.HR_CONFIGURATIONS',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        subMenu: [],
        claims: [],
      },
    ],
    claims: [Claims.CREATE_EMPLOYEE],
  },

  {
    route: '',
    title: 'CORE.MENU_ITEMS.PROJECTS_MANAGEMENT',
    icon: 'fa fa-cog',
    class: 'has-sub',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    subMenu: [
      {
        route: '/projects-management/projects',
        title: 'CORE.MENU_ITEMS.PROJECTS',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        subMenu: [],
        claims: [Claims.CREATE_PROJECT, Claims.UPDATE_PROJECT, Claims.DELETE_PROJECT],
      },
    ],
    claims: [Claims.CREATE_PROJECT, Claims.UPDATE_PROJECT, Claims.DELETE_PROJECT],
  },
  {
    route: '',
    title: 'CORE.MENU_ITEMS.FINANCES',
    icon: 'fa fa-usd',
    class: 'has-sub',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    subMenu: [
      {
        route: '/purchases/invoices',
        title: 'CORE.MENU_ITEMS.PURCHASE_INVOICES',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        subMenu: [],
        claims: [Claims.CREATE_PURCHASE_INVOICE, Claims.CREATE_VENDOR_OPENING_BALANCE_INVOICE],
      },
      {
        route: '/purchases/vendors/payments',
        title: 'CORE.MENU_ITEMS.VENDORS_PAYMENT',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        subMenu: [],
        claims: [Claims.CREATE_VENDOR_PAYMENT],
      },
      {
        route: '/sales/customers/payments',
        title: 'CORE.MENU_ITEMS.CUSTOMERS_PAYMENT',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        subMenu: [],
        claims: [Claims.CREATE_CUSTOMER_PAYMENT],
      },
      {
        route: '/finances/hr',
        title: 'CORE.MENU_ITEMS.HR',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        subMenu: [],
        claims: [],
      },
      {
        route: '/finances/cost-centers',
        title: 'CORE.MENU_ITEMS.COST_CENTERS',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        subMenu: [],
        claims: [Claims.CREATE_COST_CENTER, Claims.UPDATE_COST_CENTER, Claims.DELETE_COST_CENTER],
      },
      {
        route: '/finances/petty-cash',
        title: 'CORE.MENU_ITEMS.PETTY_CASH',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        subMenu: [],
        claims: [
          Claims.CREATE_PETTY_CASH,
          Claims.UPDATE_PETTY_CASH,
          Claims.DELETE_PETTY_CASH,
          Claims.BLOCK_PETTY_CASH,
          Claims.ACTIVATE_PETTY_CASH,
        ],
      },
      {
        route: '/finances/petty-cash-refund-requests',
        title: 'CORE.MENU_ITEMS.PETTY_CASH_REFUND_REQUESTS',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        subMenu: [],
        claims: [Claims.CREATE_PETTY_CASH_REFUND_REQUEST],
      },
      {
        route: '/finances/petty-cash-refund-request-approvals',
        title: 'CORE.MENU_ITEMS.PETTY_CASH_REFUND_REQUEST_APPROVALS',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        subMenu: [],
        claims: [Claims.APPROVE_PETTY_CASH_REFUND_REQUEST],
      },
      {
        route: '/finances/petty-cash-refunds',
        title: 'CORE.MENU_ITEMS.PETTY_CASH_REFUND',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        subMenu: [],
        claims: [Claims.CREATE_PETTY_CASH_REFUND],
      },
      {
        route: '/finances/petty-cash-reductions',
        title: 'CORE.MENU_ITEMS.PETTY_CASH_REDUCTION',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        subMenu: [],
        claims: [Claims.CREATE_PETTY_CASH_REDUCTION],
      },
      {
        route: '/finances/accounts',
        title: 'CORE.MENU_ITEMS.ACCOUNTS',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        subMenu: [],
        claims: [Claims.CREATE_ACCOUNT, Claims.UPDATE_ACCOUNT, Claims.DELETE_ACCOUNT],
      },
      {
        route: '/finances/banks',
        title: 'CORE.MENU_ITEMS.BANKS',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        subMenu: [],
        claims: [Claims.CREATE_BANK, Claims.UPDATE_BANK, Claims.DELETE_BANK],
      },
      {
        route: '/finances/bank-accounts',
        title: 'CORE.MENU_ITEMS.BANK_ACCOUNTS',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        subMenu: [],
        claims: [Claims.CREATE_BANK_ACCOUNT, Claims.DELETE_BANK_ACCOUNT],
      },
      {
        route: '/finances/journals',
        title: 'CORE.MENU_ITEMS.JOURNALS',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        subMenu: [],
        claims: [Claims.CREATE_JOURNAL],
      },
      {
        route: '/finances/financial-periods',
        title: 'CORE.MENU_ITEMS.FINANCIAL_PERIODS',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        subMenu: [],
        claims: [Claims.OPEN_FINANCIAL_PERIOD, Claims.PAUSE_FINANCIAL_PERIOD, Claims.CLOSE_FINANCIAL_PERIOD],
      },
      {
        route: '/finances/currencies',
        title: 'CORE.MENU_ITEMS.CURRENCIES',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        subMenu: [],
        claims: [Claims.CREATE_CURRENCY, Claims.UPDATE_CURRENCY, Claims.DELETE_CURRENCY],
      },
      {
        route: '/finances/currency-exchange-rates',
        title: 'CORE.MENU_ITEMS.CURRENCY_EXCHANGE_RATE',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        subMenu: [],
        claims: [
          Claims.CREATE_CURRENCY_EXCHANGE_RATE,
          Claims.UPDATE_CURRENCY_EXCHANGE_RATE,
          Claims.DELETE_CURRENCY_EXCHANGE_RATE,
        ],
      },
      {
        route: '/finances/finance-configurations',
        title: 'CORE.MENU_ITEMS.FINANCE_CONFIGURATIONS',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        subMenu: [],
        claims: [Claims.UPDATE_FINANCE_CONFIGURATIONS],
      },
    ],
    claims: [
      Claims.CREATE_PURCHASE_INVOICE,
      Claims.CREATE_VENDOR_OPENING_BALANCE_INVOICE,
      Claims.CREATE_VENDOR_PAYMENT,
      Claims.CREATE_CUSTOMER_PAYMENT,
      Claims.CREATE_COST_CENTER,
      Claims.UPDATE_COST_CENTER,
      Claims.DELETE_COST_CENTER,
      Claims.CREATE_PETTY_CASH,
      Claims.UPDATE_PETTY_CASH,
      Claims.DELETE_PETTY_CASH,
      Claims.BLOCK_PETTY_CASH,
      Claims.ACTIVATE_PETTY_CASH,
      Claims.CREATE_PETTY_CASH_REFUND_REQUEST,
      Claims.CREATE_PETTY_CASH_REFUND,
      Claims.CREATE_PETTY_CASH_REDUCTION,
      Claims.CREATE_ACCOUNT,
      Claims.UPDATE_ACCOUNT,
      Claims.DELETE_ACCOUNT,
      Claims.CREATE_JOURNAL,
      Claims.CREATE_BANK,
      Claims.UPDATE_BANK,
      Claims.DELETE_BANK,
      Claims.CREATE_CURRENCY,
      Claims.UPDATE_CURRENCY,
      Claims.DELETE_CURRENCY,
      Claims.CREATE_CURRENCY_EXCHANGE_RATE,
      Claims.UPDATE_CURRENCY_EXCHANGE_RATE,
      Claims.DELETE_CURRENCY_EXCHANGE_RATE,
      Claims.APPROVE_PETTY_CASH_REFUND_REQUEST,
      Claims.UPDATE_FINANCE_CONFIGURATIONS,
    ],
  },
  {
    route: '',
    title: 'CORE.MENU_ITEMS.FIXED_ASSETS',
    icon: 'fa fa-building',
    class: 'has-sub',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    subMenu: [
      {
        route: '/assets/assets',
        title: 'CORE.MENU_ITEMS.ASSETS',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        subMenu: [],
        claims: [Claims.CREATE_ASSET, Claims.UPDATE_ASSET, Claims.BLOCK_ASSET, Claims.ACTIVATE_ASSET],
      },
      {
        route: '/assets/assets-processing',
        title: 'CORE.MENU_ITEMS.ASSETS_PROCESSING',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        subMenu: [],
        claims: [Claims.CREATE_ASSET, Claims.UPDATE_ASSET, Claims.BLOCK_ASSET, Claims.ACTIVATE_ASSET],
      },
      {
        route: '/assets/asset-depreciations',
        title: 'CORE.MENU_ITEMS.ASSETS_DEPRECIATIONS',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        subMenu: [],
        claims: [Claims.CREATE_ASSET],
      },
      {
        route: '/assets/excluded-assets',
        title: 'CORE.MENU_ITEMS.EXCLUDED_ASSETS',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        subMenu: [],
        claims: [Claims.CREATE_EXCLUDED_ASSET],
      },
      {
        route: '/assets/sale-invoices',
        title: 'CORE.MENU_ITEMS.ASSET_SALE_INVOICES',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        subMenu: [],
        claims: [Claims.CREATE_ASSET_SALE_INVOICE],
      },
    ],
    claims: [
      Claims.CREATE_ASSET,
      Claims.UPDATE_ASSET,
      Claims.BLOCK_ASSET,
      Claims.ACTIVATE_ASSET,
      Claims.CREATE_EXCLUDED_ASSET,
      Claims.CREATE_ASSET_SALE_INVOICE,
    ],
  },
  {
    route: '/reports',
    title: 'CORE.MENU_ITEMS.REPORTS',
    icon: 'fa fa-file-text',
    class: '',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    subMenu: [],
    claims: [],
  },
  {
    route: '',
    title: 'CORE.MENU_ITEMS.SAFETY',
    icon: 'fa fa-shield',
    class: 'has-sub',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    subMenu: [
      {
        route: '/security/users',
        title: 'CORE.MENU_ITEMS.USERS_AND_PERMISSIONS',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        subMenu: [],
        claims: [Claims.CREATE_USER, Claims.UPDATE_USER, Claims.BLOCK_USER, Claims.ACTIVATE_USER],
      },
    ],
    claims: [Claims.CREATE_USER, Claims.UPDATE_USER, Claims.BLOCK_USER, Claims.ACTIVATE_USER],
  },
  {
    route: '',
    title: 'CORE.MENU_ITEMS.ACCOUNT_SETTINGS',
    icon: 'fa fa-cogs',
    class: 'has-sub',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    subMenu: [
      {
        route: '/security/organization-users',
        title: 'CORE.MENU_ITEMS.USERS',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        subMenu: [],
        claims: [
          Claims.CREATE_ORGANIZATION_USER,
          Claims.UPDATE_ORGANIZATION_USER_CLAIMS,
          Claims.ACTIVATE_ORGANIZATION_USER,
          Claims.BLOCK_ORGANIZATION_USER,
        ],
      },
      {
        route: '/settings/organization-settings',
        title: 'CORE.MENU_ITEMS.ORGANIZATION_SETTINGS',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        subMenu: [],
        claims: [Claims.UPDATE_ORGANIZATION_SETTING],
      },
    ],
    claims: [
      Claims.CREATE_ORGANIZATION_USER,
      Claims.UPDATE_ORGANIZATION_USER_CLAIMS,
      Claims.ACTIVATE_ORGANIZATION_USER,
      Claims.BLOCK_ORGANIZATION_USER,
      Claims.UPDATE_ORGANIZATION_SETTING,
    ],
  },
  ...(environment.allowDeveloperMode
    ? [
        {
          route: '',
          title: 'CORE.MENU_ITEMS.DEVELOPERS',
          icon: 'fa fa-users',
          class: 'has-sub',
          badge: '',
          badgeClass: '',
          isExternalLink: false,
          subMenu: [
            {
              route: '/developers/developers-settings',
              title: 'CORE.MENU_ITEMS.DEVELOPERS_SETTINGS',
              icon: '',
              class: '',
              badge: '',
              badgeClass: '',
              isExternalLink: false,
              subMenu: [],
              claims: [],
            },
          ],
          claims: [],
        },
      ]
    : []),
];
