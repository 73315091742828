import { SessionActionType, SessionActions } from 'auth/store/actions';
import { SessionState } from 'auth/store/states/session.state';

/**
 * The initial state from which the state starts.
 */
const initialState: SessionState = {
  user: null,
  userLocations: [],
  userCostCenters: [],
  userBankAccounts: [],
  apiKey: null,
  error: null,
  isLogging: false,
  isLoggedIn: false,
  isFetchingUserData: false,
  isRefreshingToken: false,
  isTokenRefreshed: false,
  isRefreshingUserData: false,
  isUpdatingProfile: false,
  isUpdatingUserLanguage: false,
  rememberUser: false,
  isGetUserLocationsCompleted: false,
  isGettingUserLocations: false,
  isGetUserBankAccountsCompleted: false,
  isGettingUserBankAccounts: false,
  isGetUserCostCentersCompleted: false,
  isGettingUserCostCenters: false,
  isUserDefaultLocationUpdating: false,
  isUserDefaultLocationUpdateCompleted: false,
  isUserDefaultCostCenterUpdating: false,
  istUserDefaultCostCenterUpdateCompleted: false,
  isUserDefaultCashAccountUpdating: false,
  isUserDefaultCashAccountUpdateCompleted: false,
  isUserDefaultElectronicAccountUpdating: false,
  isUserDefaultElectronicAccountUpdateCompleted: false,
  isUserDefaultPosDeviceUpdating: false,
  isUserDefaultPosDeviceUpdateCompleted: false,
  isGetApiKeyCompleted: false,
  isGettingApiKey: false,
  isApiKeyUpdating: false,
  isApiKeyUpdateCompleted: false,
};

/**
 * The reducer function that is called in each action dispatch against the state.
 * @param state The current state.
 * @param action The action that will affect the state.
 */
export function sessionReducer(state: SessionState = initialState, action: SessionActions): SessionState {
  switch (action.type) {
    //#region LOGIN

    case SessionActionType.LOGIN: {
      return {
        ...state,
        isLoggedIn: false,
        isLogging: true,
        user: null,
      };
    }

    case SessionActionType.LOGIN_FAIL: {
      return {
        ...state,
        error: action.payload,
        isLogging: false,
        isLoggedIn: false,
      };
    }

    case SessionActionType.LOGIN_SUCCESS: {
      return {
        ...state,
        error: null,
        isLogging: false,
        isLoggedIn: true,
        user: action.payload.response.user,
        rememberUser: action.payload.rememberUser,
      };
    }

    //#endregion LOGIN

    //#region FETCH_USER_DATA

    case SessionActionType.FETCH_USER_DATA: {
      return {
        ...state,
        isFetchingUserData: true,
      };
    }
    case SessionActionType.FETCH_USER_DATA_FAIL: {
      return {
        ...state,
        error: action.payload,
        isFetchingUserData: false,
      };
    }
    case SessionActionType.FETCH_USER_DATA_SUCCESS: {
      return {
        ...state,
        user: action.payload,
        error: null,
        isLoggedIn: true,
        isFetchingUserData: false,
      };
    }
    //#endregion FETCH_USER_DATA

    //#region REFRESH_TOKEN

    case SessionActionType.REFRESH_TOKEN: {
      return {
        ...state,
        isRefreshingToken: true,
        isTokenRefreshed: false,
      };
    }

    case SessionActionType.REFRESH_TOKEN_FAIL: {
      return {
        ...state,
        error: action.payload,
        isRefreshingToken: false,
        isTokenRefreshed: false,
      };
    }

    case SessionActionType.REFRESH_TOKEN_SUCCESS: {
      return {
        ...state,
        error: null,
        isRefreshingToken: false,
        isTokenRefreshed: true,
        user: action.payload.response.user,
      };
    }

    //#endregion REFRESH_TOKEN

    //#region UPDATE_USER_PROFILE

    case SessionActionType.UPDATE_USER_PROFILE_INFO: {
      return {
        ...state,
        isUpdatingProfile: true,
      };
    }
    case SessionActionType.UPDATE_USER_PROFILE_INFO_FAIL: {
      return {
        ...state,
        error: action.payload,
        isUpdatingProfile: false,
      };
    }
    case SessionActionType.UPDATE_USER_PROFILE_INFO_SUCCESS: {
      return {
        ...state,
        user: action.payload,
        error: null,
        isUpdatingProfile: false,
      };
    }
    //#endregion UPDATE_USER_PROFILE

    //#region UPDATE_USER_CREDENTIALS

    case SessionActionType.UPDATE_USER_PROFILE_CREDENTIALS: {
      return {
        ...state,
        isUpdatingProfile: true,
      };
    }
    case SessionActionType.UPDATE_USER_PROFILE_CREDENTIALS_FAIL: {
      return {
        ...state,
        error: action.payload,
        isUpdatingProfile: false,
      };
    }
    case SessionActionType.UPDATE_USER_PROFILE_CREDENTIALS_SUCCESS: {
      return {
        ...state,
        user: action.payload,
        error: null,
        isUpdatingProfile: false,
      };
    }
    //#endregion UPDATE_USER_CREDENTIALS

    case SessionActionType.LOGOUT: {
      return {
        ...state,
        user: null,
        error: null,
        isLogging: false,
        isFetchingUserData: false,
        isLoggedIn: false,
      };
    }

    //#region GET_USER_LOCATIONS

    case SessionActionType.GET_USER_LOCATIONS: {
      return {
        ...state,
        isGetUserLocationsCompleted: false,
        isGettingUserLocations: true,
        error: null,
      };
    }

    case SessionActionType.GET_USER_LOCATIONS_FAIL: {
      return {
        ...state,
        error: action.payload,
        isGetUserLocationsCompleted: false,
        isGettingUserLocations: false,
      };
    }

    case SessionActionType.GET_USER_LOCATIONS_SUCCESS: {
      return {
        ...state,
        userLocations: action.payload.data,
        error: null,
        isGetUserLocationsCompleted: true,
        isGettingUserLocations: false,
      };
    }

    // #end region GET_USER_LOCATIONS

    //#region GET_USER_BANK_ACCOUNTS

    case SessionActionType.GET_USER_BANK_ACCOUNTS: {
      return {
        ...state,
        isGetUserBankAccountsCompleted: false,
        isGettingUserBankAccounts: true,
        error: null,
      };
    }

    case SessionActionType.GET_USER_BANK_ACCOUNTS_FAIL: {
      return {
        ...state,
        error: action.payload,
        isGetUserBankAccountsCompleted: false,
        isGettingUserBankAccounts: false,
      };
    }

    case SessionActionType.GET_USER_BANK_ACCOUNTS_SUCCESS: {
      return {
        ...state,
        userBankAccounts: action.payload.data,
        error: null,
        isGetUserBankAccountsCompleted: true,
        isGettingUserBankAccounts: false,
      };
    }

    // #end region GET_USER_BANK_ACCOUNTS

    //#region GET_USER_COST_CENTERS

    case SessionActionType.GET_USER_COST_CENTERS: {
      return {
        ...state,
        isGetUserCostCentersCompleted: false,
        isGettingUserCostCenters: true,
        error: null,
      };
    }

    case SessionActionType.GET_USER_COST_CENTERS_FAIL: {
      return {
        ...state,
        error: action.payload,
        isGetUserCostCentersCompleted: false,
        isGettingUserCostCenters: false,
      };
    }

    case SessionActionType.GET_USER_COST_CENTERS_SUCCESS: {
      return {
        ...state,
        userCostCenters: action.payload.data,
        error: null,
        isGetUserCostCentersCompleted: true,
        isGettingUserCostCenters: false,
      };
    }

    // #end region GET_USER_COST_CENTERS

    //#region UPDATE_USER_LANGUAGE

    case SessionActionType.UPDATE_USER_LANGUAGE: {
      return {
        ...state,
        isUpdatingUserLanguage: true,
      };
    }
    case SessionActionType.UPDATE_USER_LANGUAGE_FAIL: {
      return {
        ...state,
        error: action.payload,
        isUpdatingUserLanguage: false,
      };
    }
    case SessionActionType.UPDATE_USER_LANGUAGE_SUCCESS: {
      return {
        ...state,
        user: action.payload,
        error: null,
        isUpdatingUserLanguage: false,
      };
    }
    //#endregion UPDATE_USER_LANGUAGE

    //#region UPDATE_DEFAULT_USER_LOCATION

    case SessionActionType.UPDATE_USER_DEFAULT_LOCATION: {
      return {
        ...state,
        error: null,
        isUserDefaultLocationUpdating: true,
        isUserDefaultLocationUpdateCompleted: false,
      };
    }

    case SessionActionType.UPDATE_USER_DEFAULT_LOCATION_FAIL: {
      return {
        ...state,
        error: action.payload,
        isUserDefaultLocationUpdating: false,
        isUserDefaultLocationUpdateCompleted: false,
      };
    }

    case SessionActionType.UPDATE_USER_DEFAULT_LOCATION_SUCCESS: {
      return {
        ...state,
        user: action.payload.data,
        error: null,
        isUserDefaultLocationUpdating: false,
        isUserDefaultLocationUpdateCompleted: true,
      };
    }

    //#endregion UPDATE_DEFAULT_USER_LOCATION

    //#region UPDATE_DEFAULT_USER_COST_CENTER

    case SessionActionType.UPDATE_USER_DEFAULT_COST_CENTER: {
      return {
        ...state,
        isUserDefaultCostCenterUpdating: true,
        istUserDefaultCostCenterUpdateCompleted: false,
        error: null,
      };
    }

    case SessionActionType.UPDATE_USER_DEFAULT_COST_CENTER_FAIL: {
      return {
        ...state,
        error: action.payload,
        isUserDefaultCostCenterUpdating: false,
        istUserDefaultCostCenterUpdateCompleted: false,
      };
    }

    case SessionActionType.UPDATE_USER_DEFAULT_COST_CENTER_SUCCESS: {
      return {
        ...state,
        user: action.payload.data,
        error: null,
        isUserDefaultCostCenterUpdating: false,
        istUserDefaultCostCenterUpdateCompleted: true,
      };
    }

    //#endregion UPDATE_DEFAULT_USER_COST_CENTER

    //#region UPDATE_DEFAULT_USER_CASH_ACCOUNT

    case SessionActionType.UPDATE_USER_DEFAULT_CASH_ACCOUNT: {
      return {
        ...state,
        error: null,
        isUserDefaultCashAccountUpdating: true,
        isUserDefaultCashAccountUpdateCompleted: false,
      };
    }

    case SessionActionType.UPDATE_USER_DEFAULT_CASH_ACCOUNT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isUserDefaultCashAccountUpdating: false,
        isUserDefaultCashAccountUpdateCompleted: false,
      };
    }

    case SessionActionType.UPDATE_USER_DEFAULT_CASH_ACCOUNT_SUCCESS: {
      return {
        ...state,
        user: action.payload.data,
        error: null,
        isUserDefaultCashAccountUpdating: false,
        isUserDefaultCashAccountUpdateCompleted: true,
      };
    }

    //#endregion UPDATE_DEFAULT_USER_CASH_ACCOUNT

    //#region UPDATE_DEFAULT_USER_ELECTRONIC_ACCOUNT

    case SessionActionType.UPDATE_USER_DEFAULT_ELECTRONIC_ACCOUNT: {
      return {
        ...state,
        error: null,
        isUserDefaultElectronicAccountUpdating: true,
        isUserDefaultElectronicAccountUpdateCompleted: false,
      };
    }

    case SessionActionType.UPDATE_USER_DEFAULT_ELECTRONIC_ACCOUNT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isUserDefaultElectronicAccountUpdating: false,
        isUserDefaultElectronicAccountUpdateCompleted: false,
      };
    }

    case SessionActionType.UPDATE_USER_DEFAULT_ELECTRONIC_ACCOUNT_SUCCESS: {
      return {
        ...state,
        user: action.payload.data,
        error: null,
        isUserDefaultElectronicAccountUpdating: false,
        isUserDefaultElectronicAccountUpdateCompleted: true,
      };
    }

    //#endregion UPDATE_DEFAULT_USER_ELECTRONIC_ACCOUNT

    //#region UPDATE_DEFAULT_USER_POS_DEVICE

    case SessionActionType.UPDATE_USER_DEFAULT_POS_DEVICE: {
      return {
        ...state,
        error: null,
        isUserDefaultPosDeviceUpdating: true,
        isUserDefaultPosDeviceUpdateCompleted: false,
      };
    }

    case SessionActionType.UPDATE_USER_DEFAULT_POS_DEVICE_FAIL: {
      return {
        ...state,
        error: action.payload,
        isUserDefaultPosDeviceUpdating: false,
        isUserDefaultPosDeviceUpdateCompleted: false,
      };
    }

    case SessionActionType.UPDATE_USER_DEFAULT_POS_DEVICE_SUCCESS: {
      return {
        ...state,
        user: action.payload.data,
        error: null,
        isUserDefaultPosDeviceUpdating: false,
        isUserDefaultPosDeviceUpdateCompleted: true,
      };
    }

    //#endregion UPDATE_DEFAULT_USER_POS_DEVICE

    //#region GET_API_KEY

    case SessionActionType.GET_API_KEY: {
      return {
        ...state,
        isGetApiKeyCompleted: false,
        isGettingApiKey: true,
        error: null,
      };
    }

    case SessionActionType.GET_API_KEY_FAIL: {
      return {
        ...state,
        error: action.payload,
        isGetApiKeyCompleted: false,
        isGettingApiKey: false,
      };
    }

    case SessionActionType.GET_API_KEY_SUCCESS: {
      return {
        ...state,
        apiKey: action.payload.data,
        error: null,
        isGetApiKeyCompleted: true,
        isGettingApiKey: false,
      };
    }

    // #end region GET_API_KEY

    //#region UPDATE_API_KEY

    case SessionActionType.UPDATE_API_KEY: {
      return {
        ...state,
        isApiKeyUpdating: true,
        isApiKeyUpdateCompleted: false,
        error: null,
      };
    }

    case SessionActionType.UPDATE_API_KEY_FAIL: {
      return {
        ...state,
        error: action.payload,
        isApiKeyUpdating: false,
        isApiKeyUpdateCompleted: false,
      };
    }

    case SessionActionType.UPDATE_API_KEY_SUCCESS: {
      return {
        ...state,
        apiKey: action.payload.data,
        error: null,
        isApiKeyUpdating: false,
        isApiKeyUpdateCompleted: true,
      };
    }

    //#endregion UPDATE_API_KEY

    default:
      return state;
  }
}
