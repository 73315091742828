import { Injectable } from '@angular/core';

import { of } from 'rxjs';
import { switchMap, map, catchError } from 'rxjs/operators';
import { Effect, ofType, Actions } from '@ngrx/effects';

import { LookupsService } from 'lookups/services';
import {
  ProjectRevenueTypeActionType,
  GetAllProjectRevenueTypesFail,
  GetAllProjectRevenueTypesSuccess,
} from 'lookups/store/actions';

@Injectable()
export class ProjectRevenueTypeEffects {
  constructor(private actions$: Actions, private lookupsService: LookupsService) {}

  /* ========================= GET_ALL_PROJECT_REVENUE_TYPES =================================== */
  @Effect()
  getAllProjectRevenueTypes$ = this.actions$.pipe(
    ofType(ProjectRevenueTypeActionType.GET_ALL_PROJECT_REVENUE_TYPES),
    switchMap(() =>
      this.lookupsService.getAllProjectRevenueTypes().pipe(
        map((response) => new GetAllProjectRevenueTypesSuccess(response)),
        catchError((error) => of(new GetAllProjectRevenueTypesFail(error)))
      )
    )
  );
}
