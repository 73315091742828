import { Injectable } from '@angular/core';

import { of } from 'rxjs';
import { switchMap, map, catchError } from 'rxjs/operators';
import { Effect, ofType, Actions } from '@ngrx/effects';

import { LookupsService } from 'lookups/services';
import {
  QuotationStatusActionType,
  GetAllQuotationStatusesFail,
  GetAllQuotationStatusesSuccess,
} from 'lookups/store/actions';

@Injectable()
export class QuotationStatusEffects {
  constructor(private actions$: Actions, private lookupsService: LookupsService) {}

  /* ========================= GET_ALL_QUOTATION_STATUSES =================================== */
  @Effect()
  getAllQuotationStatuses$ = this.actions$.pipe(
    ofType(QuotationStatusActionType.GET_ALL_QUOTATION_STATUSES),
    switchMap(() =>
      this.lookupsService.getAllQuotationStatuses().pipe(
        map((response) => new GetAllQuotationStatusesSuccess(response)),
        catchError((error) => of(new GetAllQuotationStatusesFail(error)))
      )
    )
  );
}
