import { Injectable } from '@angular/core';

import { of } from 'rxjs';
import { switchMap, map, catchError } from 'rxjs/operators';
import { Effect, ofType, Actions } from '@ngrx/effects';

import { LookupsService } from 'lookups/services';
import {
  CustomerAdvancePaymentLogTypeActionType,
  GetAllCustomerAdvancePaymentLogTypeFail,
  GetAllCustomerAdvancePaymentLogTypeSuccess,
} from 'lookups/store/actions';

@Injectable()
export class CustomerAdvancePaymentLogTypeEffects {
  constructor(private actions$: Actions, private lookupsService: LookupsService) {}

  /* ========================= GET_ALL_CUSTOMER_ADVANCE_PAYMENT_LOG_TYPES =================================== */
  @Effect()
  GetAllCustomerAdvancePaymentLogTypes$ = this.actions$.pipe(
    ofType(CustomerAdvancePaymentLogTypeActionType.GET_ALL_CUSTOMER_ADVANCE_PAYMENT_LOG_TYPES),
    switchMap(() =>
      this.lookupsService.getAllCustomerAdvancePaymentLogTypes().pipe(
        map((response) => new GetAllCustomerAdvancePaymentLogTypeSuccess(response)),
        catchError((error) => of(new GetAllCustomerAdvancePaymentLogTypeFail(error)))
      )
    )
  );
}
