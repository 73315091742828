<!-- page header starts -->
<app-page-header [info]="pageInfo"></app-page-header>
<!-- page header ends -->

<!-- create product discount form starts -->
<div class="card">
  <div class="card-content">
    <div class="px-3 pt-3">
      <div class="row">
        <div class="col-md-12">
          <ul
            class="nav-tabs"
            ngbNav
            #nav="ngbNav"
            [(activeId)]="activePage"
            (activeIdChange)="selectedPageChanged($event)"
            [destroyOnHide]="false"
          >
            <li ngbNavItem="products-discounts">
              <a ngbNavLink>
                <i class="fa fa-percent" [class.success]="activePage == 'products-discounts'"></i>
                {{ 'STORES.PRODUCTS.PRODUCTS_DISCOUNTS' | translate }}
              </a>

              <ng-template ngbNavContent>
                <app-product-sales-discounts-list></app-product-sales-discounts-list>
              </ng-template>
            </li>
            <li ngbNavItem="apply-products-discounts">
              <a ngbNavLink>
                <i class="fa fa-cubes" [class.success]="activePage == 'apply-products-discounts'"></i>
                {{ 'STORES.PRODUCTS.APPLY_ON_PRODUCTS' | translate }}
              </a>

              <ng-template ngbNavContent>
                <form (ngSubmit)="submit()" [formGroup]="form">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="card">
                        <!-- products starts -->
                        <div class="card-content">
                          <div class="px-3">
                            <div class="row">
                              <div class="form-group col-md-6">
                                <label for="productDiscountType">{{ 'STORES.PRODUCTS.APPLY_ON' | translate }}</label>
                                <ng-select
                                  class="round"
                                  formControlName="productDiscountType"
                                  labelForId="productDiscountType"
                                  searchable="true"
                                  [placeholder]="'STORES.PRODUCTS.APPLY_ON_PLACEHOLDER' | translate"
                                  [notFoundText]="'SHARED.SEARCH.SEARCH_NOT_FOUND' | translate"
                                >
                                  <ng-option *ngFor="let type of ProductDiscountTypes" [value]="type.id">
                                    {{ type.name | localize: type.nameEn | async }}
                                  </ng-option>
                                </ng-select>
                              </div>
                              <div
                                class="form-group col-md-6"
                                *ngIf="form.value.productDiscountType === productDiscountTypeForClassesId"
                              >
                                <label for="classes">{{ 'STORES.PRODUCTS.PRODUCTS_CLASSES' | translate }}</label>
                                <ng-select
                                  class="round"
                                  formControlName="classes"
                                  [multiple]="true"
                                  [closeOnSelect]="false"
                                  labelForId="classes"
                                  searchable="true"
                                  [placeholder]="'STORES.PRODUCTS.PRODUCTS_CLASSES_PLACEHOLDER' | translate"
                                  [notFoundText]="'SHARED.SEARCH.SEARCH_NOT_FOUND' | translate"
                                  (open)="classesListVisibility = true"
                                >
                                  <ng-option *ngFor="let productClass of classes" [value]="productClass.id">
                                    {{ productClass.name | localize: productClass.nameEn | async }}
                                  </ng-option>
                                </ng-select>
                              </div>
                              <div
                                class="form-group col-md-6"
                                *ngIf="form.value.productDiscountType === productDiscountTypeForProductsId"
                              >
                                <label for="products">{{ 'STORES.PRODUCTS.PRODUCTS' | translate }}</label>
                                <ng-select
                                  class="round"
                                  formControlName="products"
                                  [multiple]="true"
                                  [closeOnSelect]="false"
                                  labelForId="products"
                                  searchable="true"
                                  [placeholder]="'STORES.PRODUCTS.PRODUCT_DATA.PRODUCTS_PLACEHOLDER' | translate"
                                  [notFoundText]="'SHARED.SEARCH.SEARCH_NOT_FOUND' | translate"
                                  (open)="productsListVisibility = true"
                                >
                                  <ng-option *ngFor="let product of products" [value]="product.id">
                                    {{ product.description | localize: product.descriptionEn | async }}
                                  </ng-option>
                                </ng-select>
                              </div>
                              <div
                                class="form-group col-md-6"
                                [ngClass]="form.value.productDiscountType ? 'col-md-12' : 'col-md-6'"
                              >
                                <label for="locationId">
                                  {{ 'STORES.PRODUCTS.PRODUCT_DATA.LOCATION_INPUT' | translate }}
                                </label>
                                <ng-select
                                  class="round"
                                  formControlName="locationId"
                                  labelForId="locationId"
                                  searchable="true"
                                  [placeholder]="'STORES.PRODUCTS.PRODUCT_DATA.LOCATION_INPUT_PLACEHOLDER' | translate"
                                  [notFoundText]="'SHARED.SEARCH.SEARCH_NOT_FOUND' | translate"
                                  (open)="locationsListVisibility = true"
                                >
                                  <ng-option *ngFor="let location of locations" [value]="location.id">
                                    {{ location.name | localize: location.nameEn | async }}
                                  </ng-option>
                                </ng-select>
                              </div>
                              <div class="form-group col-md-6">
                                <label for="discount">{{ 'STORES.PRODUCTS.PRODUCT_DATA.DISCOUNT' | translate }}</label>
                                <input
                                  type="text"
                                  [mask]="DECIMAL_MASK"
                                  thousandSeparator=","
                                  minLength="0"
                                  id="discount"
                                  class="form-control round"
                                  [placeholder]="'STORES.PRODUCTS.PRODUCT_DATA.DISCOUNT_PLACEHOLDER' | translate"
                                  formControlName="discount"
                                />
                              </div>
                              <div class="form-group col-md-6">
                                <label for="discountPercent">{{
                                  'STORES.PRODUCTS.PRODUCT_DATA.DISCOUNT_PERCENT' | translate
                                }}</label>
                                <input
                                  type="text"
                                  [mask]="DECIMAL_MASK"
                                  thousandSeparator=","
                                  minLength="0"
                                  id="discountPercent"
                                  class="form-control round"
                                  [placeholder]="
                                    'STORES.PRODUCTS.PRODUCT_DATA.DISCOUNT_PERCENT_PLACEHOLDER' | translate
                                  "
                                  formControlName="discountPercent"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row mt-2">
                    <div class="form-group col-12 d-flex justify-content-between flex-column flex-sm-row mt-2">
                      <button type="submit" class="btn btn-raised btn-success round">
                        <i class="fa fa-check"></i>
                        <span class="mx-1">{{ 'SHARED.CONFIRM.DISCOUNTS' | translate }}</span>
                      </button>
                      <a class="btn btn-dark round" routerLink="/stores/products">
                        <i class="fa fa-undo"></i>
                        <span class="mx-1">{{ 'STORES.PRODUCTS.PRODUCTS_RETURN' | translate }}</span>
                      </a>
                    </div>
                  </div>
                </form>
              </ng-template>
            </li>
          </ul>
          <div [ngbNavOutlet]="nav" class="mt-2"></div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- discounts form ends -->

<!-- confirm modal starts -->
<ng-template #confirmModalRef let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
      {{ 'STORES.PRODUCTS.CONFIRM_CREATE_PRODUCT_DISCOUNT_TITLE' | translate }}
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="d()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p class="text-success">{{ 'STORES.PRODUCTS.CONFIRM_CREATE_PRODUCT_DISCOUNT_MESSAGE' | translate }}</p>
  </div>
  <div class="modal-footer">
    <div class="modal-actions">
      <button type="button" class="btn btn-raised btn-success round" (click)="c(); confirm()" ngbAutofocus>
        <i class="fa fa-check"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.SAVE' | translate }}</span>
      </button>
      <button type="button" class="btn btn-outline-dark round" (click)="c()">
        <i class="fa fa-times"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.CANCEL' | translate }}</span>
      </button>
    </div>
  </div>
</ng-template>
<!-- confirm modal ends -->

<!-- product classes search starts -->
<app-product-classes-search (productClasses)="selectClasses($event)" [(visible)]="classesListVisibility">
</app-product-classes-search>
<!-- product classes search ends -->

<!-- products search starts -->
<app-products-search (products)="selectProducts($event)" [(visible)]="productsListVisibility"> </app-products-search>
<!-- products search ends -->

<!-- locations search starts -->
<app-locations-search
  onlyActive="true"
  closeOnSelect="true"
  (locations)="selectLocation($event)"
  [(visible)]="locationsListVisibility"
>
</app-locations-search>
<!-- locations search ends -->
