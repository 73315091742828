import { Action } from '@ngrx/store';

import { AppHttpResponse } from 'shared';
import { BankAccountLog, PayrollPaymentRefund, PettyCashRefund, PettyCashRefundRequest } from 'finances/models';
import { PurchaseInvoice, PurchaseReturn, VendorLog } from 'purchases/models';
import { CustomerLog, SaleInvoice, SaleReturn } from 'sales/models';
import { IncomingStock, OutgoingStock, TransferStock } from 'stocks/models';
import { Asset, AssetDepreciation, AssetSaleInvoice, ExcludedAsset } from 'assets/models';
import { AccrualPayroll, EmployeeLoan, PayrollPayment, GovServiceRequest } from 'hr/models';

/**
 * The finances-module store journal association action types.
 */
export enum JournalAssociationActionType {
  FIND_JOURNAL_ASSOCIATION_VENDOR_LOG = '[Finances] [JournalAssociation] Find Journal Association Vendor Log',
  FIND_JOURNAL_ASSOCIATION_VENDOR_LOG_FAIL = '[Finances] [JournalAssociation] Find Journal Association Vendor Log Fail',
  FIND_JOURNAL_ASSOCIATION_VENDOR_LOG_SUCCESS = '[Finances] [JournalAssociation] Find Journal Association Vendor Log Success',

  FIND_JOURNAL_ASSOCIATION_PURCHASE_INVOICE = '[Finances] [JournalAssociation] Find Journal Association Purchase Invoice',
  FIND_JOURNAL_ASSOCIATION_PURCHASE_INVOICE_FAIL = '[Finances] [JournalAssociation] Find Journal Association Purchase Invoice Fail',
  FIND_JOURNAL_ASSOCIATION_PURCHASE_INVOICE_SUCCESS = '[Finances] [JournalAssociation] Find Journal Association Purchase Invoice Success',

  FIND_JOURNAL_ASSOCIATION_PURCHASE_RETURN = '[Finances] [JournalAssociation] Find Journal Association Purchase Return',
  FIND_JOURNAL_ASSOCIATION_PURCHASE_RETURN_FAIL = '[Finances] [JournalAssociation] Find Journal Association Purchase Return Fail',
  FIND_JOURNAL_ASSOCIATION_PURCHASE_RETURN_SUCCESS = '[Finances] [JournalAssociation] Find Journal Association Purchase Return Success',

  FIND_JOURNAL_ASSOCIATION_CUSTOMER_LOG = '[Finances] [JournalAssociation] Find Journal Association Customer Log',
  FIND_JOURNAL_ASSOCIATION_CUSTOMER_LOG_FAIL = '[Finances] [JournalAssociation] Find Journal Association Customer Log Fail',
  FIND_JOURNAL_ASSOCIATION_CUSTOMER_LOG_SUCCESS = '[Finances] [JournalAssociation] Find Journal Association Customer Log Success',

  FIND_JOURNAL_ASSOCIATION_SALE_INVOICE = '[Finances] [JournalAssociation] Find Journal Association Sale Invoice',
  FIND_JOURNAL_ASSOCIATION_SALE_INVOICE_FAIL = '[Finances] [JournalAssociation] Find Journal Association Sale Invoice Fail',
  FIND_JOURNAL_ASSOCIATION_SALE_INVOICE_SUCCESS = '[Finances] [JournalAssociation] Find Journal Association Sale Invoice Success',

  FIND_JOURNAL_ASSOCIATION_SALE_RETURN = '[Finances] [JournalAssociation] Find Journal Association Sale Return',
  FIND_JOURNAL_ASSOCIATION_SALE_RETURN_FAIL = '[Finances] [JournalAssociation] Find Journal Association Sale Return Fail',
  FIND_JOURNAL_ASSOCIATION_SALE_RETURN_SUCCESS = '[Finances] [JournalAssociation] Find Journal Association Sale Return Success',

  FIND_JOURNAL_ASSOCIATION_TRANSFER_STOCK = '[Finances] [JournalAssociation] Find Journal Association Transfer Stock',
  FIND_JOURNAL_ASSOCIATION_TRANSFER_STOCK_FAIL = '[Finances] [JournalAssociation] Find Journal Association Transfer Stock Fail',
  FIND_JOURNAL_ASSOCIATION_TRANSFER_STOCK_SUCCESS = '[Finances] [JournalAssociation] Find Journal Association Transfer Stock Success',

  FIND_JOURNAL_ASSOCIATION_INCOMING_STOCK = '[Finances] [JournalAssociation] Find Journal Association Incoming Stock',
  FIND_JOURNAL_ASSOCIATION_INCOMING_STOCK_FAIL = '[Finances] [JournalAssociation] Find Journal Association Incoming Stock Fail',
  FIND_JOURNAL_ASSOCIATION_INCOMING_STOCK_SUCCESS = '[Finances] [JournalAssociation] Find Journal Association Incoming Stock Success',

  FIND_JOURNAL_ASSOCIATION_OUTGOING_STOCK = '[Finances] [JournalAssociation] Find Journal Association Outgoing Stock',
  FIND_JOURNAL_ASSOCIATION_OUTGOING_STOCK_FAIL = '[Finances] [JournalAssociation] Find Journal Association Outgoing Stock Fail',
  FIND_JOURNAL_ASSOCIATION_OUTGOING_STOCK_SUCCESS = '[Finances] [JournalAssociation] Find Journal Association Outgoing Stock Success',

  FIND_JOURNAL_ASSOCIATION_PETTY_CASH_REFUND = '[Finances] [JournalAssociation] Find Journal Association Petty Cash Refund',
  FIND_JOURNAL_ASSOCIATION_PETTY_CASH_REFUND_FAIL = '[Finances] [JournalAssociation] Find Journal Association Petty Cash Refund Fail',
  FIND_JOURNAL_ASSOCIATION_PETTY_CASH_REFUND_SUCCESS = '[Finances] [JournalAssociation] Find Journal Association Petty Cash Refund Success',

  FIND_JOURNAL_ASSOCIATION_PETTY_CASH_REFUND_REQUEST = '[Finances] [JournalAssociation] Find Journal Association Petty Cash Refund Request',
  FIND_JOURNAL_ASSOCIATION_PETTY_CASH_REFUND_REQUEST_FAIL = '[Finances] [JournalAssociation] Find Journal Association Petty Cash Refund Request Fail',
  FIND_JOURNAL_ASSOCIATION_PETTY_CASH_REFUND_REQUEST_SUCCESS = '[Finances] [JournalAssociation] Find Journal Association Petty Cash Refund Request Success',

  FIND_JOURNAL_ASSOCIATION_ASSET = '[Finances] [JournalAssociation] Find Journal Association Asset',
  FIND_JOURNAL_ASSOCIATION_ASSET_FAIL = '[Finances] [JournalAssociation] Find Journal Association Asset Fail',
  FIND_JOURNAL_ASSOCIATION_ASSET_SUCCESS = '[Finances] [JournalAssociation] Find Journal Association Asset Success',

  FIND_JOURNAL_ASSOCIATION_BANK_ACCOUNT_LOG = '[Finances] [JournalAssociation] Find Journal Association Bank Account Log',
  FIND_JOURNAL_ASSOCIATION_BANK_ACCOUNT_LOG_FAIL = '[Finances] [JournalAssociation] Find Journal Association Bank Account Log Fail',
  FIND_JOURNAL_ASSOCIATION_BANK_ACCOUNT_LOG_SUCCESS = '[Finances] [JournalAssociation] Find Journal Association Bank Account Log Success',

  FIND_JOURNAL_ASSOCIATION_ASSET_DEPRECIATION = '[Finances] [JournalAssociation] Find Journal Association Asset Depreciation',
  FIND_JOURNAL_ASSOCIATION_ASSET_DEPRECIATION_FAIL = '[Finances] [JournalAssociation] Find Journal Association Asset Depreciation Fail',
  FIND_JOURNAL_ASSOCIATION_ASSET_DEPRECIATION_SUCCESS = '[Finances] [JournalAssociation] Find Journal Association Asset Depreciation Success',

  FIND_JOURNAL_ASSOCIATION_ASSET_SALE_INVOICE = '[Finances] [JournalAssociation] Find Journal Association Asset Sale Invoice',
  FIND_JOURNAL_ASSOCIATION_ASSET_SALE_INVOICE_FAIL = '[Finances] [JournalAssociation] Find Journal Association Asset Sale Invoice Fail',
  FIND_JOURNAL_ASSOCIATION_ASSET_SALE_INVOICE_SUCCESS = '[Finances] [JournalAssociation] Find Journal Association Asset Sale Invoice Success',

  FIND_JOURNAL_ASSOCIATION_EXCLUDED_ASSET = '[Finances] [JournalAssociation] Find Journal Association Excluded Asset',
  FIND_JOURNAL_ASSOCIATION_EXCLUDED_ASSET_FAIL = '[Finances] [JournalAssociation] Find Journal Association Excluded Asset Fail',
  FIND_JOURNAL_ASSOCIATION_EXCLUDED_ASSET_SUCCESS = '[Finances] [JournalAssociation] Find Journal Association Excluded Asset Success',

  FIND_JOURNAL_ASSOCIATION_ACCRUAL_PAYROLL = '[Finances] [JournalAssociation] Find Journal Association Accrual Payroll',
  FIND_JOURNAL_ASSOCIATION_ACCRUAL_PAYROLL_FAIL = '[Finances] [JournalAssociation] Find Journal Association Accrual Payroll Fail',
  FIND_JOURNAL_ASSOCIATION_ACCRUAL_PAYROLL_SUCCESS = '[Finances] [JournalAssociation] Find Journal Association Accrual Payroll Success',

  FIND_JOURNAL_ASSOCIATION_PAYROLL_PAYMENT_REFUND = '[Finances] [JournalAssociation] Find Journal Association Payroll Payment Refund',
  FIND_JOURNAL_ASSOCIATION_PAYROLL_PAYMENT_REFUND_FAIL = '[Finances] [JournalAssociation] Find Journal Association Payroll Payment Refund Fail',
  FIND_JOURNAL_ASSOCIATION_PAYROLL_PAYMENT_REFUND_SUCCESS = '[Finances] [JournalAssociation] Find Journal Association Payroll Payment Refund Success',

  FIND_JOURNAL_ASSOCIATION_PAYROLL_PAYMENT = '[Finances] [JournalAssociation] Find Journal Association Payroll Payment',
  FIND_JOURNAL_ASSOCIATION_PAYROLL_PAYMENT_FAIL = '[Finances] [JournalAssociation] Find Journal Association Payroll Payment Fail',
  FIND_JOURNAL_ASSOCIATION_PAYROLL_PAYMENT_SUCCESS = '[Finances] [JournalAssociation] Find Journal Association Payroll Payment Success',

  FIND_JOURNAL_ASSOCIATION_EMPLOYEE_LOAN = '[Finances] [JournalAssociation] Find Journal Association Employee Loan',
  FIND_JOURNAL_ASSOCIATION_EMPLOYEE_LOAN_FAIL = '[Finances] [JournalAssociation] Find Journal Association Employee Loan Fail',
  FIND_JOURNAL_ASSOCIATION_EMPLOYEE_LOAN_SUCCESS = '[Finances] [JournalAssociation] Find Journal Association Employee Loan Success',

  FIND_JOURNAL_ASSOCIATION_GOV_SERVICE_REQUEST = '[Finances] [JournalAssociation] Find Journal Association Gov Service Request',
  FIND_JOURNAL_ASSOCIATION_GOV_SERVICE_REQUEST_FAIL = '[Finances] [JournalAssociation] Find Journal Association Gov Service Request Fail',
  FIND_JOURNAL_ASSOCIATION_GOV_SERVICE_REQUEST_SUCCESS = '[Finances] [JournalAssociation] Find Journal Association Gov Service Request Success',
}

/**
 * Represents a store Find Journal Association vendor log action.
 */
export class FindJournalAssociationVendorLog implements Action {
  /**
   * The type of the action.
   */
  readonly type = JournalAssociationActionType.FIND_JOURNAL_ASSOCIATION_VENDOR_LOG;

  /**
   * Fires a new Find Journal Association vendor log action.
   * @param payload the id of the vendor log.
   */
  constructor(public payload: number) {}
}

/**
 * Represents a store Find Journal Association vendor log fail action.
 */
export class FindJournalAssociationVendorLogFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = JournalAssociationActionType.FIND_JOURNAL_ASSOCIATION_VENDOR_LOG_FAIL;

  /**
   * Fires a new Find Journal Association vendor log fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store Find Journal Association vendor log success action.
 */
export class FindJournalAssociationVendorLogSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = JournalAssociationActionType.FIND_JOURNAL_ASSOCIATION_VENDOR_LOG_SUCCESS;

  /**
   * Fires a new Find Journal Association vendor log success action.
   * @param payload The loaded vendor log.
   */
  constructor(public payload: AppHttpResponse<VendorLog>) {}
}

/**
 * Represents a store Find Journal Association accrual payroll action.
 */
export class FindJournalAssociationAccrualPayroll implements Action {
  /**
   * The type of the action.
   */
  readonly type = JournalAssociationActionType.FIND_JOURNAL_ASSOCIATION_ACCRUAL_PAYROLL;

  /**
   * Fires a new Find Journal Association accrual payroll action.
   * @param payload the id of the accrual payroll.
   */
  constructor(public payload: number) {}
}

/**
 * Represents a store Find Journal Association accrual payroll fail action.
 */
export class FindJournalAssociationAccrualPayrollFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = JournalAssociationActionType.FIND_JOURNAL_ASSOCIATION_ACCRUAL_PAYROLL_FAIL;

  /**
   * Fires a new Find Journal Association accrual payroll fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store Find Journal Association accrual payroll success action.
 */
export class FindJournalAssociationAccrualPayrollSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = JournalAssociationActionType.FIND_JOURNAL_ASSOCIATION_ACCRUAL_PAYROLL_SUCCESS;

  /**
   * Fires a new Find Journal Association accrual payroll success action.
   * @param payload The loaded accrual payroll.
   */
  constructor(public payload: AppHttpResponse<AccrualPayroll>) {}
}

/**
 * Represents a store Find Journal Association payroll payment refund action.
 */
export class FindJournalAssociationPayrollPaymentRefund implements Action {
  /**
   * The type of the action.
   */
  readonly type = JournalAssociationActionType.FIND_JOURNAL_ASSOCIATION_PAYROLL_PAYMENT_REFUND;

  /**
   * Fires a new Find Journal Association payroll payment refund action.
   * @param payload the id of the payroll payment refund.
   */
  constructor(public payload: number) {}
}

/**
 * Represents a store Find Journal Association payroll payment refund fail action.
 */
export class FindJournalAssociationPayrollPaymentRefundFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = JournalAssociationActionType.FIND_JOURNAL_ASSOCIATION_PAYROLL_PAYMENT_REFUND_FAIL;

  /**
   * Fires a new Find Journal Association payroll payment refund fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store Find Journal Association payroll payment refund success action.
 */
export class FindJournalAssociationPayrollPaymentRefundSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = JournalAssociationActionType.FIND_JOURNAL_ASSOCIATION_PAYROLL_PAYMENT_REFUND_SUCCESS;

  /**
   * Fires a new Find Journal Association payroll payment refund success action.
   * @param payload The loaded payroll payment refund.
   */
  constructor(public payload: AppHttpResponse<PayrollPaymentRefund>) {}
}

/**
 * Represents a store Find Journal Association asset action.
 */
export class FindJournalAssociationAsset implements Action {
  /**
   * The type of the action.
   */
  readonly type = JournalAssociationActionType.FIND_JOURNAL_ASSOCIATION_ASSET;

  /**
   * Fires a new Find Journal Association asset action.
   * @param payload the id of the asset.
   */
  constructor(public payload: number) {}
}

/**
 * Represents a store Find Journal Association asset fail action.
 */
export class FindJournalAssociationAssetFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = JournalAssociationActionType.FIND_JOURNAL_ASSOCIATION_ASSET_FAIL;

  /**
   * Fires a new Find Journal Association asset fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store Find Journal Association asset success action.
 */
export class FindJournalAssociationAssetSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = JournalAssociationActionType.FIND_JOURNAL_ASSOCIATION_ASSET_SUCCESS;

  /**
   * Fires a new Find Journal Association asset success action.
   * @param payload The loaded asset.
   */
  constructor(public payload: AppHttpResponse<Asset>) {}
}

/**
 * Represents a store Find Journal Association purchase invoice action.
 */
export class FindJournalAssociationPurchaseInvoice implements Action {
  /**
   * The type of the action.
   */
  readonly type = JournalAssociationActionType.FIND_JOURNAL_ASSOCIATION_PURCHASE_INVOICE;

  /**
   * Fires a new Find Journal Association purchase invoice action.
   * @param payload the id of the purchase invoice.
   */
  constructor(public payload: number) {}
}

/**
 * Represents a store Find Journal Association purchase invoice fail action.
 */
export class FindJournalAssociationPurchaseInvoiceFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = JournalAssociationActionType.FIND_JOURNAL_ASSOCIATION_PURCHASE_INVOICE_FAIL;

  /**
   * Fires a new Find Journal Association purchase invoice fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store Find Journal Association purchase invoice success action.
 */
export class FindJournalAssociationPurchaseInvoiceSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = JournalAssociationActionType.FIND_JOURNAL_ASSOCIATION_PURCHASE_INVOICE_SUCCESS;

  /**
   * Fires a new Find Journal Association purchase invoice success action.
   * @param payload The loaded purchase invoice.
   */
  constructor(public payload: AppHttpResponse<PurchaseInvoice>) {}
}

/**
 * Represents a store Find Journal Association purchase return action.
 */
export class FindJournalAssociationPurchaseReturn implements Action {
  /**
   * The type of the action.
   */
  readonly type = JournalAssociationActionType.FIND_JOURNAL_ASSOCIATION_PURCHASE_RETURN;

  /**
   * Fires a new Find Journal Association purchase return action.
   * @param payload the id of the purchase return.
   */
  constructor(public payload: number) {}
}

/**
 * Represents a store Find Journal Association purchase return fail action.
 */
export class FindJournalAssociationPurchaseReturnFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = JournalAssociationActionType.FIND_JOURNAL_ASSOCIATION_PURCHASE_RETURN_FAIL;

  /**
   * Fires a new Find Journal Association purchase return fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store Find Journal Association purchase Return success action.
 */
export class FindJournalAssociationPurchaseReturnSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = JournalAssociationActionType.FIND_JOURNAL_ASSOCIATION_PURCHASE_RETURN_SUCCESS;

  /**
   * Fires a new Find Journal Association purchase return success action.
   * @param payload The loaded purchase return.
   */
  constructor(public payload: AppHttpResponse<PurchaseReturn>) {}
}

/**
 * Represents a store Find Journal Association customer log action.
 */
export class FindJournalAssociationCustomerLog implements Action {
  /**
   * The type of the action.
   */
  readonly type = JournalAssociationActionType.FIND_JOURNAL_ASSOCIATION_CUSTOMER_LOG;

  /**
   * Fires a new Find Journal Association customer log action.
   * @param payload the id of the customer log.
   */
  constructor(public payload: number) {}
}

/**
 * Represents a store Find Journal Association customer log fail action.
 */
export class FindJournalAssociationCustomerLogFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = JournalAssociationActionType.FIND_JOURNAL_ASSOCIATION_CUSTOMER_LOG_FAIL;

  /**
   * Fires a new Find Journal Association customer log fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store Find Journal Association customer log success action.
 */
export class FindJournalAssociationCustomerLogSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = JournalAssociationActionType.FIND_JOURNAL_ASSOCIATION_CUSTOMER_LOG_SUCCESS;

  /**
   * Fires a new Find Journal Association customer log success action.
   * @param payload The loaded customer log.
   */
  constructor(public payload: AppHttpResponse<CustomerLog>) {}
}

/**
 * Represents a store Find Journal Association sale invoice action.
 */
export class FindJournalAssociationSaleInvoice implements Action {
  /**
   * The type of the action.
   */
  readonly type = JournalAssociationActionType.FIND_JOURNAL_ASSOCIATION_SALE_INVOICE;

  /**
   * Fires a new Find Journal Association sale invoice action.
   * @param payload the id of the sale invoice.
   */
  constructor(public payload: number) {}
}

/**
 * Represents a store Find Journal Association sale invoice fail action.
 */
export class FindJournalAssociationSaleInvoiceFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = JournalAssociationActionType.FIND_JOURNAL_ASSOCIATION_SALE_INVOICE_FAIL;

  /**
   * Fires a new Find Journal Association sale invoice fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store Find Journal Association sale invoice success action.
 */
export class FindJournalAssociationSaleInvoiceSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = JournalAssociationActionType.FIND_JOURNAL_ASSOCIATION_SALE_INVOICE_SUCCESS;

  /**
   * Fires a new Find Journal Association sale invoice success action.
   * @param payload The loaded sale invoice.
   */
  constructor(public payload: AppHttpResponse<SaleInvoice>) {}
}

/**
 * Represents a store Find Journal Association sale return action.
 */
export class FindJournalAssociationSaleReturn implements Action {
  /**
   * The type of the action.
   */
  readonly type = JournalAssociationActionType.FIND_JOURNAL_ASSOCIATION_SALE_RETURN;

  /**
   * Fires a new Find Journal Association sale return action.
   * @param payload the id of the sale return.
   */
  constructor(public payload: number) {}
}

/**
 * Represents a store Find Journal Association sale return fail action.
 */
export class FindJournalAssociationSaleReturnFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = JournalAssociationActionType.FIND_JOURNAL_ASSOCIATION_SALE_RETURN_FAIL;

  /**
   * Fires a new Find Journal Association sale return fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store Find Journal Association sale return success action.
 */
export class FindJournalAssociationSaleReturnSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = JournalAssociationActionType.FIND_JOURNAL_ASSOCIATION_SALE_RETURN_SUCCESS;

  /**
   * Fires a new Find Journal Association sale return success action.
   * @param payload The loaded sale return.
   */
  constructor(public payload: AppHttpResponse<SaleReturn>) {}
}

/**
 * Represents a store Find Journal Association transfer stock action.
 */
export class FindJournalAssociationTransferStock implements Action {
  /**
   * The type of the action.
   */
  readonly type = JournalAssociationActionType.FIND_JOURNAL_ASSOCIATION_TRANSFER_STOCK;

  /**
   * Fires a new Find Journal Association transfer stock action.
   * @param payload the id of the transfer stock.
   */
  constructor(public payload: number) {}
}

/**
 * Represents a store Find Journal Association transfer stock fail action.
 */
export class FindJournalAssociationTransferStockFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = JournalAssociationActionType.FIND_JOURNAL_ASSOCIATION_TRANSFER_STOCK_FAIL;

  /**
   * Fires a new Find Journal Association transfer stock fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store Find Journal Association transfer stock success action.
 */
export class FindJournalAssociationTransferStockSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = JournalAssociationActionType.FIND_JOURNAL_ASSOCIATION_TRANSFER_STOCK_SUCCESS;

  /**
   * Fires a new Find Journal Association transfer stock success action.
   * @param payload The loaded transfer stock.
   */
  constructor(public payload: AppHttpResponse<TransferStock>) {}
}

/**
 * Represents a store Find Journal Association incoming stock action.
 */
export class FindJournalAssociationIncomingStock implements Action {
  /**
   * The type of the action.
   */
  readonly type = JournalAssociationActionType.FIND_JOURNAL_ASSOCIATION_INCOMING_STOCK;

  /**
   * Fires a new Find Journal Association incoming stock action.
   * @param payload the id of the incoming stock.
   */
  constructor(public payload: number) {}
}

/**
 * Represents a store Find Journal Association incoming stock fail action.
 */
export class FindJournalAssociationIncomingStockFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = JournalAssociationActionType.FIND_JOURNAL_ASSOCIATION_INCOMING_STOCK_FAIL;

  /**
   * Fires a new Find Journal Association incoming stock fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store Find Journal Association incoming stock success action.
 */
export class FindJournalAssociationIncomingStockSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = JournalAssociationActionType.FIND_JOURNAL_ASSOCIATION_INCOMING_STOCK_SUCCESS;

  /**
   * Fires a new Find Journal Association incoming stock success action.
   * @param payload The loaded incoming stock.
   */
  constructor(public payload: AppHttpResponse<IncomingStock>) {}
}

/**
 * Represents a store Find Journal Association outgoing stock action.
 */
export class FindJournalAssociationOutgoingStock implements Action {
  /**
   * The type of the action.
   */
  readonly type = JournalAssociationActionType.FIND_JOURNAL_ASSOCIATION_OUTGOING_STOCK;

  /**
   * Fires a new Find Journal Association outgoing stock action.
   * @param payload the id of the outgoing stock.
   */
  constructor(public payload: number) {}
}

/**
 * Represents a store Find Journal Association outgoing stock fail action.
 */
export class FindJournalAssociationOutgoingStockFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = JournalAssociationActionType.FIND_JOURNAL_ASSOCIATION_OUTGOING_STOCK_FAIL;

  /**
   * Fires a new Find Journal Association outgoing stock fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store Find Journal Association outgoing stock success action.
 */
export class FindJournalAssociationOutgoingStockSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = JournalAssociationActionType.FIND_JOURNAL_ASSOCIATION_OUTGOING_STOCK_SUCCESS;

  /**
   * Fires a new Find Journal Association outgoing stock success action.
   * @param payload The loaded outgoing stock.
   */
  constructor(public payload: AppHttpResponse<OutgoingStock>) {}
}

/**
 * Represents a store Find Journal Association petty cash refund action.
 */
export class FindJournalAssociationPettyCashRefund implements Action {
  /**
   * The type of the action.
   */
  readonly type = JournalAssociationActionType.FIND_JOURNAL_ASSOCIATION_PETTY_CASH_REFUND;

  /**
   * Fires a new Find Journal Association petty cash refund action.
   * @param payload the id of the petty cash refund.
   */
  constructor(public payload: number) {}
}

/**
 * Represents a store Find Journal Association petty cash refund fail action.
 */
export class FindJournalAssociationPettyCashRefundFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = JournalAssociationActionType.FIND_JOURNAL_ASSOCIATION_PETTY_CASH_REFUND_FAIL;

  /**
   * Fires a new Find Journal Association petty cash refund fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store Find Journal Association petty cash refund success action.
 */
export class FindJournalAssociationPettyCashRefundSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = JournalAssociationActionType.FIND_JOURNAL_ASSOCIATION_PETTY_CASH_REFUND_SUCCESS;

  /**
   * Fires a new Find Journal Association petty cash refund success action.
   * @param payload The loaded petty cash refund .
   */
  constructor(public payload: AppHttpResponse<PettyCashRefund>) {}
}

/**
 * Represents a store Find Journal Association petty cash refund request action.
 */
export class FindJournalAssociationPettyCashRefundRequest implements Action {
  /**
   * The type of the action.
   */
  readonly type = JournalAssociationActionType.FIND_JOURNAL_ASSOCIATION_PETTY_CASH_REFUND_REQUEST;

  /**
   * Fires a new Find Journal Association petty cash refund request action.
   * @param payload the id of the petty cash refund request.
   */
  constructor(public payload: number) {}
}

/**
 * Represents a store Find Journal Association petty cash refund request fail action.
 */
export class FindJournalAssociationPettyCashRefundRequestFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = JournalAssociationActionType.FIND_JOURNAL_ASSOCIATION_PETTY_CASH_REFUND_REQUEST_FAIL;

  /**
   * Fires a new Find Journal Association petty cash refund request fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store Find Journal Association petty cash refund request success action.
 */
export class FindJournalAssociationPettyCashRefundRequestSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = JournalAssociationActionType.FIND_JOURNAL_ASSOCIATION_PETTY_CASH_REFUND_REQUEST_SUCCESS;

  /**
   * Fires a new Find Journal Association petty cash refund request success action.
   * @param payload The loaded petty cash refund request.
   */
  constructor(public payload: AppHttpResponse<PettyCashRefundRequest>) {}
}

/**
 * Represents a store Find Journal Association bank account log action.
 */
export class FindJournalAssociationBankAccountLog implements Action {
  /**
   * The type of the action.
   */
  readonly type = JournalAssociationActionType.FIND_JOURNAL_ASSOCIATION_BANK_ACCOUNT_LOG;

  /**
   * Fires a new Find Journal Association bank account log action.
   * @param payload the id of the bank account log.
   */
  constructor(public payload: number) {}
}

/**
 * Represents a store Find Journal Association bank account log fail action.
 */
export class FindJournalAssociationBankAccountLogFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = JournalAssociationActionType.FIND_JOURNAL_ASSOCIATION_BANK_ACCOUNT_LOG_FAIL;

  /**
   * Fires a new Find Journal Association bank account log fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store Find Journal Association bank account log success action.
 */
export class FindJournalAssociationBankAccountLogSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = JournalAssociationActionType.FIND_JOURNAL_ASSOCIATION_BANK_ACCOUNT_LOG_SUCCESS;

  /**
   * Fires a new Find Journal Association bank account log success action.
   * @param payload The loaded bank account log.
   */
  constructor(public payload: AppHttpResponse<BankAccountLog>) {}
}

/**
 * Represents a store Find Journal Association asset depreciation action.
 */
export class FindJournalAssociationAssetDepreciation implements Action {
  /**
   * The type of the action.
   */
  readonly type = JournalAssociationActionType.FIND_JOURNAL_ASSOCIATION_ASSET_DEPRECIATION;

  /**
   * Fires a new Find Journal Association asset depreciation action.
   * @param payload the id of the asset depreciation.
   */
  constructor(public payload: number) {}
}

/**
 * Represents a store Find Journal Association asset depreciation fail action.
 */
export class FindJournalAssociationAssetDepreciationFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = JournalAssociationActionType.FIND_JOURNAL_ASSOCIATION_ASSET_DEPRECIATION_FAIL;

  /**
   * Fires a new Find Journal Association asset depreciation fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store Find Journal Association asset depreciation success action.
 */
export class FindJournalAssociationAssetDepreciationSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = JournalAssociationActionType.FIND_JOURNAL_ASSOCIATION_ASSET_DEPRECIATION_SUCCESS;

  /**
   * Fires a new Find Journal Association asset depreciation success action.
   * @param payload The loaded asset depreciation.
   */
  constructor(public payload: AppHttpResponse<AssetDepreciation>) {}
}

/**
 * Represents a store Find Journal Association asset sale invoice action.
 */
export class FindJournalAssociationAssetSaleInvoice implements Action {
  /**
   * The type of the action.
   */
  readonly type = JournalAssociationActionType.FIND_JOURNAL_ASSOCIATION_ASSET_SALE_INVOICE;

  /**
   * Fires a new Find Journal Association asset sale invoice action.
   * @param payload the id of the asset sale invoice.
   */
  constructor(public payload: number) {}
}

/**
 * Represents a store Find Journal Association asset sale invoice fail action.
 */
export class FindJournalAssociationAssetSaleInvoiceFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = JournalAssociationActionType.FIND_JOURNAL_ASSOCIATION_ASSET_SALE_INVOICE_FAIL;

  /**
   * Fires a new Find Journal Association asset sale invoice fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store Find Journal Association asset sale invoice success action.
 */
export class FindJournalAssociationAssetSaleInvoiceSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = JournalAssociationActionType.FIND_JOURNAL_ASSOCIATION_ASSET_SALE_INVOICE_SUCCESS;

  /**
   * Fires a new Find Journal Association asset sale invoice success action.
   * @param payload The loaded asset sale invoice.
   */
  constructor(public payload: AppHttpResponse<AssetSaleInvoice>) {}
}

/**
 * Represents a store Find Journal Association excluded asset action.
 */
export class FindJournalAssociationExcludedAsset implements Action {
  /**
   * The type of the action.
   */
  readonly type = JournalAssociationActionType.FIND_JOURNAL_ASSOCIATION_EXCLUDED_ASSET;

  /**
   * Fires a new Find Journal Association excluded asset action.
   * @param payload the id of the excluded asset.
   */
  constructor(public payload: number) {}
}

/**
 * Represents a store Find Journal Association excluded asset fail action.
 */
export class FindJournalAssociationExcludedAssetFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = JournalAssociationActionType.FIND_JOURNAL_ASSOCIATION_EXCLUDED_ASSET_FAIL;

  /**
   * Fires a new Find Journal Association excluded asset fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store Find Journal Association excluded asset success action.
 */
export class FindJournalAssociationExcludedAssetSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = JournalAssociationActionType.FIND_JOURNAL_ASSOCIATION_EXCLUDED_ASSET_SUCCESS;

  /**
   * Fires a new Find Journal Association excluded asset success action.
   * @param payload The loaded asset sale invoice.
   */
  constructor(public payload: AppHttpResponse<ExcludedAsset>) {}
}

/**
 * Represents a store Find Journal Association payroll payment action.
 */
export class FindJournalAssociationPayrollPayment implements Action {
  /**
   * The type of the action.
   */
  readonly type = JournalAssociationActionType.FIND_JOURNAL_ASSOCIATION_PAYROLL_PAYMENT;

  /**
   * Fires a new Find Journal Association payroll payment action.
   * @param payload the id of the payroll payment.
   */
  constructor(public payload: number) {}
}

/**
 * Represents a store Find Journal Association payroll payment fail action.
 */
export class FindJournalAssociationPayrollPaymentFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = JournalAssociationActionType.FIND_JOURNAL_ASSOCIATION_PAYROLL_PAYMENT_FAIL;

  /**
   * Fires a new Find Journal Association payroll payment fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store Find Journal Association payroll payment success action.
 */
export class FindJournalAssociationPayrollPaymentSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = JournalAssociationActionType.FIND_JOURNAL_ASSOCIATION_PAYROLL_PAYMENT_SUCCESS;

  /**
   * Fires a new Find Journal Association payroll payment success action.
   * @param payload The loaded asset sale invoice.
   */
  constructor(public payload: AppHttpResponse<PayrollPayment>) {}
}

/**
 * Represents a store Find Journal Association employee loan action.
 */
export class FindJournalAssociationEmployeeLoan implements Action {
  /**
   * The type of the action.
   */
  readonly type = JournalAssociationActionType.FIND_JOURNAL_ASSOCIATION_EMPLOYEE_LOAN;

  /**
   * Fires a new Find Journal Association employee loan action.
   * @param payload the id of the employee loan.
   */
  constructor(public payload: number) {}
}

/**
 * Represents a store Find Journal Association employee loan fail action.
 */
export class FindJournalAssociationEmployeeLoanFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = JournalAssociationActionType.FIND_JOURNAL_ASSOCIATION_EMPLOYEE_LOAN_FAIL;

  /**
   * Fires a new Find Journal Association employee loan fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store Find Journal Association employee loan success action.
 */
export class FindJournalAssociationEmployeeLoanSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = JournalAssociationActionType.FIND_JOURNAL_ASSOCIATION_EMPLOYEE_LOAN_SUCCESS;

  /**
   * Fires a new Find Journal Association employee loan success action.
   * @param payload The loaded employee loan.
   */
  constructor(public payload: AppHttpResponse<EmployeeLoan>) {}
}

/**
 * Represents a store Find Journal Association gov service request action.
 */
export class FindJournalAssociationGovServiceRequest implements Action {
  /**
   * The type of the action.
   */
  readonly type = JournalAssociationActionType.FIND_JOURNAL_ASSOCIATION_GOV_SERVICE_REQUEST;

  /**
   * Fires a new Find Journal Association gov service request action.
   * @param payload the id of the gov service request.
   */
  constructor(public payload: number) {}
}

/**
 * Represents a store Find Journal Association gov service request fail action.
 */
export class FindJournalAssociationGovServiceRequestFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = JournalAssociationActionType.FIND_JOURNAL_ASSOCIATION_GOV_SERVICE_REQUEST_FAIL;

  /**
   * Fires a new Find Journal Association gov service request fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store Find Journal Association gov service request success action.
 */
export class FindJournalAssociationGovServiceRequestSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = JournalAssociationActionType.FIND_JOURNAL_ASSOCIATION_GOV_SERVICE_REQUEST_SUCCESS;

  /**
   * Fires a new Find Journal Association gov service request success action.
   * @param payload The loaded gov service request.
   */
  constructor(public payload: AppHttpResponse<GovServiceRequest>) {}
}

/*
/**
 * finances-module journal association action types.
 */
export type journalAssociationActions =
  | FindJournalAssociationVendorLog
  | FindJournalAssociationVendorLogFail
  | FindJournalAssociationVendorLogSuccess
  | FindJournalAssociationAccrualPayroll
  | FindJournalAssociationAccrualPayrollFail
  | FindJournalAssociationAccrualPayrollSuccess
  | FindJournalAssociationPayrollPaymentRefund
  | FindJournalAssociationPayrollPaymentRefundFail
  | FindJournalAssociationPayrollPaymentRefundSuccess
  | FindJournalAssociationAsset
  | FindJournalAssociationAssetFail
  | FindJournalAssociationAssetSuccess
  | FindJournalAssociationPurchaseInvoice
  | FindJournalAssociationPurchaseInvoiceFail
  | FindJournalAssociationPurchaseInvoiceSuccess
  | FindJournalAssociationPurchaseReturn
  | FindJournalAssociationPurchaseReturnFail
  | FindJournalAssociationPurchaseReturnSuccess
  | FindJournalAssociationCustomerLog
  | FindJournalAssociationCustomerLogFail
  | FindJournalAssociationCustomerLogSuccess
  | FindJournalAssociationSaleInvoice
  | FindJournalAssociationSaleInvoiceFail
  | FindJournalAssociationSaleInvoiceSuccess
  | FindJournalAssociationSaleReturn
  | FindJournalAssociationSaleReturnFail
  | FindJournalAssociationSaleReturnSuccess
  | FindJournalAssociationTransferStock
  | FindJournalAssociationTransferStockFail
  | FindJournalAssociationTransferStockSuccess
  | FindJournalAssociationIncomingStock
  | FindJournalAssociationIncomingStockFail
  | FindJournalAssociationIncomingStockSuccess
  | FindJournalAssociationOutgoingStock
  | FindJournalAssociationOutgoingStockFail
  | FindJournalAssociationOutgoingStockSuccess
  | FindJournalAssociationPettyCashRefund
  | FindJournalAssociationPettyCashRefundFail
  | FindJournalAssociationPettyCashRefundSuccess
  | FindJournalAssociationPettyCashRefundRequest
  | FindJournalAssociationPettyCashRefundRequestFail
  | FindJournalAssociationPettyCashRefundRequestSuccess
  | FindJournalAssociationBankAccountLog
  | FindJournalAssociationBankAccountLogFail
  | FindJournalAssociationBankAccountLogSuccess
  | FindJournalAssociationAssetDepreciation
  | FindJournalAssociationAssetDepreciationFail
  | FindJournalAssociationAssetDepreciationSuccess
  | FindJournalAssociationAssetSaleInvoice
  | FindJournalAssociationAssetSaleInvoiceFail
  | FindJournalAssociationAssetSaleInvoiceSuccess
  | FindJournalAssociationExcludedAsset
  | FindJournalAssociationExcludedAssetFail
  | FindJournalAssociationExcludedAssetSuccess
  | FindJournalAssociationPayrollPayment
  | FindJournalAssociationPayrollPaymentFail
  | FindJournalAssociationPayrollPaymentSuccess
  | FindJournalAssociationEmployeeLoan
  | FindJournalAssociationEmployeeLoanFail
  | FindJournalAssociationEmployeeLoanSuccess
  | FindJournalAssociationGovServiceRequest
  | FindJournalAssociationGovServiceRequestFail
  | FindJournalAssociationGovServiceRequestSuccess;
