import { Component, ViewChild, ElementRef, Input, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms';

import { Observable, Subscription } from 'rxjs';
import { skip, tap } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

import * as fromProjectsManagementStore from 'projects-management/store';
import * as fromLookupsStore from 'lookups/store';
import { NotificationMessage, NotificationService, TranslationService } from 'shared';
import { ProjectPlanningResourceFormItem } from 'projects-management/models';
import { ProjectResourceType } from 'lookups/models';

@Component({
  selector: 'app-update-project-planning-resources',
  templateUrl: './update-project-planning-resources.component.html',
  styles: [],
})
export class UpdateProjectPlanningResourcesComponent implements OnInit, OnDestroy {
  /**
   * The create modal template reference.
   */
  @ViewChild('createModalRef') createModalRef: ElementRef<any>;

  /**
   * The delete confirm modal template reference.
   */
  @ViewChild('deleteConfirmModalRef') deleteConfirmModalRef: ElementRef<any>;

  /**
   * Sets the project id.
   */
  @Input() projectId: number;

  /**
   * Sets the currently edited project's resources.
   */
  @Input() projectPlanningResources: ProjectPlanningResourceFormItem[];

  /**
   * Sets the planning-resources form-array.
   */
  @Input() formArray: FormArray;

  /**
   * Gets or sets the id of the current selected file.
   */
  selectedResourceId: number;

  /**
   * The set of resource types.
   */
  resourceTypes$: Observable<ProjectResourceType[]>;

  /**
   * Indicates whether there is a create project  planning resource process is running or not.
   */
  isCreating$: Observable<boolean>;

  /**
   * Indicates whether there is a delete project  planning resource process is running or not.
   */
  isDeleting$: Observable<boolean>;

  /**
   * The create project  planning resource form.
   */
  createForm: FormGroup;

  /**
   * The ngb-modal reference to use it when modal needed to be closed.
   */
  modal: NgbModalRef;

  /**
   * The set of subscriptions on this components,
   * these subscriptions must be unsubscribed before this component got destroyed.
   */
  subscriptions = new Subscription();

  /**
   * @param modalService The modal service.
   * @param projectsManagementStore$ The hr-store.
   * @param lookupsStore$ The lookups-store.
   * @param translationService The translation service.
   * @param notificationService The notification service.
   */
  constructor(
    private modalService: NgbModal,
    private projectsManagementStore$: Store<fromProjectsManagementStore.ProjectsManagementState>,
    private lookupsStore$: Store<fromLookupsStore.LookupsState>,
    private translationService: TranslationService,
    private notificationService: NotificationService
  ) {}

  ngOnInit(): void {
    this.init();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  /**
   * Initialize component data.
   */
  init() {
    /** Initialize form. */
    this.initForm();

    /**
     * rest form on creation complete
     */
    this.subscriptions.add(
      this.projectsManagementStore$
        .pipe(
          select(fromProjectsManagementStore.getSelectedProjectPlanningResourceCreateCompleted),
          skip(1),
          tap((update) => {
            if (update) {
              this.initForm();
              this.createForm.markAsUntouched();
            }
          })
        )
        .subscribe()
    );

    /** Loads data. */

    let isManualSearchTriggeredBeforeForResourceTypes = false;
    this.resourceTypes$ = this.lookupsStore$.pipe(
      select(fromLookupsStore.getProjectResourceTypes),
      tap((resourceTypes) => {
        if (!isManualSearchTriggeredBeforeForResourceTypes && !resourceTypes.length) {
          isManualSearchTriggeredBeforeForResourceTypes = true;
          this.lookupsStore$.dispatch(new fromLookupsStore.GetAllProjectResourceTypes());
        }
      })
    );

    /**
     * Reset form controls when new project  planning resources is created.
     */
    this.subscriptions.add(
      this.projectsManagementStore$
        .pipe(
          select(fromProjectsManagementStore.getSelectedProjectPlanningResourceCreateCompleted),
          skip(1),
          tap((isCreated) => {
            if (isCreated) {
              this.closeModal();
            }
          })
        )
        .subscribe()
    );

    /**
     * Set data.
     */
    this.isCreating$ = this.projectsManagementStore$.pipe(
      select(fromProjectsManagementStore.getSelectedProjectPlanningResourceCreating)
    );
  }

  /**
   * Initialize form and add validators.
   */
  initForm() {
    this.createForm = new FormGroup({
      resourceTypeId: new FormControl(null, Validators.required),
    });
  }

  /**
   * Opens the create modal.
   */
  create() {
    this.initForm();
    this.openModal(this.createModalRef, 'lg');
  }

  /**
   * Submits the form.
   */
  submit() {
    const errorMessage = new NotificationMessage();

    if (this.createForm.invalid) {
      if (this.createForm.get('resourceTypeId').invalid) {
        errorMessage.title = this.translationService.translate(
          'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA_VALIDATION.RESOURCE_TYPE_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate(
            'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA_VALIDATION.RESOURCE_TYPE_IS_REQUIRED'
          ),
        ];
      }

      this.createForm.markAllAsTouched();
      return this.notificationService.warningWithTitle(errorMessage);
    }
    this.confirmCreate();
  }

  /**
   * Creates a new list of project  planning resources only if the filled data passed validations.
   */
  confirmCreate() {
    this.projectsManagementStore$.dispatch(
      new fromProjectsManagementStore.CreateProjectPlanningResource({
        projectId: this.projectId,
        projectResourceTypeId: this.createForm.value.resourceTypeId,
      })
    );
  }

  /**
   * Deletes the file for the given it.
   * @param id The id of the file to be deleted.
   */
  deleteResource(id: number) {
    this.selectedResourceId = id;

    this.openModal(this.deleteConfirmModalRef);
  }

  /**
   * Deletes the current selected file.
   */
  deleteConfirm() {
    this.projectsManagementStore$.dispatch(
      new fromProjectsManagementStore.DeleteProjectPlanningResource(this.selectedResourceId)
    );
  }

  /**
   * Opens the modal of the given templateRef.
   * @param modalRef The modal templateRef to be opened.
   */
  openModal(modalRef, size = '') {
    /**
     * Open the modal & listen for user press ESC key.
     */
    this.modal = this.modalService.open(modalRef, { size });
    this.modal.result.then(null, () => this.closeModal());
  }

  /**
   * Closes the currently opened modal.
   */
  closeModal() {
    this.modalService.dismissAll();
  }
}
