import { Action } from '@ngrx/store';

import { AppHttpResponse } from 'shared';
import { StatisticsChartData, StatisticsData } from 'statistics/models';

/**
 * The Statistics-Module store statistics action types.
 */
export enum StatisticsActionType {
  LOAD_STATISTICS = '[Statistics] Load Statistics',
  LOAD_STATISTICS_FAIL = '[Statistics] Load Statistics Fail',
  LOAD_STATISTICS_SUCCESS = '[Statistics] Load Statistics Success',

  LOAD_STATISTICS_CHARTS = '[Statistics] Load Charts Statistics',
  LOAD_STATISTICS_CHARTS_FAIL = '[Statistics] Load Charts Statistics Fail',
  LOAD_STATISTICS_CHARTS_SUCCESS = '[Statistics] Load Charts Statistics Success',
}

/**
 * Represents a store statistics load action.
 */
export class LoadStatistics implements Action {
  /**
   * The type of the action.
   */
  readonly type = StatisticsActionType.LOAD_STATISTICS;

  /**
   * Fires a new statistics load action.
   * if omitted, all statistics will be loaded.
   */
  constructor(
    public payload?: {
      day: number;
      month: number;
      year: number;
      isDay: boolean;
      isWeek: boolean;
      isMonth: boolean;
      locations: number[];
    }
  ) {}
}

/**
 * Represents a store statistics load-fail action.
 */
export class LoadStatisticsFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = StatisticsActionType.LOAD_STATISTICS_FAIL;

  /**
   * Fires a new statistics load-fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store statistics load-success action.
 */
export class LoadStatisticsSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = StatisticsActionType.LOAD_STATISTICS_SUCCESS;

  /**
   * Fires a new statistics load-success action.
   * @param payload An object contains the list of statistics that matches the search criteria
   * in addition to the pagination info.
   */
  constructor(public payload: AppHttpResponse<StatisticsData>) {}
}

/**
 * Represents a store statistics charts load action.
 */
export class LoadStatisticsCharts implements Action {
  /**
   * The type of the action.
   */
  readonly type = StatisticsActionType.LOAD_STATISTICS_CHARTS;

  /**
   * Fires a new statistics charts load action.
   * if omitted, all statistics charts will be loaded.
   */
  constructor(
    public payload?: {
      day: number;
      month: number;
      year: number;
      isDay: boolean;
      isWeek: boolean;
      isMonth: boolean;
      locations: number[];
    }
  ) {}
}

/**
 * Represents a store statistics charts load-fail action.
 */
export class LoadStatisticsChartsFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = StatisticsActionType.LOAD_STATISTICS_CHARTS_FAIL;

  /**
   * Fires a new statistics charts load-fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store statistics charts load-success action.
 */
export class LoadStatisticsChartsSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = StatisticsActionType.LOAD_STATISTICS_CHARTS_SUCCESS;

  /**
   * Fires a new statistics charts load-success action.
   * @param payload An object contains the list of statistics charts that matches the search criteria
   * in addition to the pagination info.
   */
  constructor(public payload: AppHttpResponse<StatisticsChartData[]>) {}
}

/**
 * Statistics-module statistics action types.
 */
export type StatisticsActions =
  | LoadStatistics
  | LoadStatisticsFail
  | LoadStatisticsSuccess
  | LoadStatisticsCharts
  | LoadStatisticsChartsFail
  | LoadStatisticsChartsSuccess;
