import { Action } from '@ngrx/store';

import { EducationLevel } from 'lookups/models';
import { AppHttpResponse } from 'shared';

/**
 * The Lookups-Module store education level action types.
 */
export enum EducationLevelActionType {
  GET_ALL_EDUCATION_LEVELS = '[Lookups] [EducationLevel] Get All Education Levels',
  GET_ALL_EDUCATION_LEVELS_FAIL = '[Lookups] [EducationLevel] Get All Education Levels Fail',
  GET_ALL_EDUCATION_LEVELS_SUCCESS = '[Lookups] [EducationLevel] Get All Education Levels Success',
}

/**
 * Represents a store Education Level action.
 */
export class GetAllEducationLevels implements Action {
  /**
   * The type of the action.
   */
  readonly type = EducationLevelActionType.GET_ALL_EDUCATION_LEVELS;

  /**
   * Fires a new education level action.
   */
  constructor() {}
}

/**
 * Represents a store education level fail action.
 */
export class GetAllEducationLevelsFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = EducationLevelActionType.GET_ALL_EDUCATION_LEVELS_FAIL;

  /**
   * Fires a new education level fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store education level success action.
 */
export class GetAllEducationLevelsSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = EducationLevelActionType.GET_ALL_EDUCATION_LEVELS_SUCCESS;

  /**
   * Fires a education level success action.
   * @param payload An object contains the list of education levels.
   */
  constructor(public payload: AppHttpResponse<EducationLevel[]>) {}
}

/**
 * Lookups-module education level action types.
 */
export type EducationLevelActions = GetAllEducationLevels | GetAllEducationLevelsFail | GetAllEducationLevelsSuccess;
