<ng-container [formGroup]="formGroup">
  <th scope="row">{{ index + 1 }}</th>
  <td class="min-width-9">
    <ng-select
      class="round"
      formControlName="subProductVariants"
      [multiple]="true"
      [closeOnSelect]="false"
      labelForId="subProductVariants"
      searchable="true"
      [placeholder]="'STORES.PRODUCTS.PRODUCT_DATA.PRODUCT_VARIANTS_PLACEHOLDER' | translate"
      [notFoundText]="'SHARED.SEARCH.SEARCH_NOT_FOUND' | translate"
      (open)="variantsListVisibility = true"
    >
      <ng-option *ngFor="let variant of variantsList" [value]="variant.id">
        {{ variant.name | localize: variant.nameEn | async }}
      </ng-option>
    </ng-select>
  </td>
  <td class="min-width-9">
    <input
      type="text"
      [mask]="DECIMAL_MASK"
      thousandSeparator=","
      minLength="0"
      maxLength="100"
      id="purchasePrice"
      class="form-control round"
      [placeholder]="'STORES.PRODUCTS.PRODUCT_DATA.PURCHASE_PRICE_PLACEHOLDER' | translate"
      formControlName="purchasePrice"
    />
  </td>
  <td class="min-width-9">
    <input
      type="text"
      [mask]="DECIMAL_MASK"
      thousandSeparator=","
      minLength="0"
      maxLength="100"
      id="salePrice"
      class="form-control round"
      [placeholder]="'STORES.PRODUCTS.PRODUCT_DATA.SALE_PRICE_PLACEHOLDER' | translate"
      formControlName="salePrice"
    />
  </td>

  <td>
    <a class="danger p-0" [ngbTooltip]="'SHARED.CONFIRM.DELETE' | translate" (click)="remove()">
      <i class="fa fa-close font-medium-3 mr-2"></i>
    </a>
  </td>
</ng-container>

<!-- variants search starts -->
<app-variants-search [(visible)]="variantsListVisibility" (variants)="selectVariants($event)"> </app-variants-search>
<!-- variants search ends -->
