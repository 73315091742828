import { Component, Output, EventEmitter, OnInit, Renderer2, OnDestroy } from '@angular/core';
import { select, Store } from '@ngrx/store';

import { Observable, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';

import { Language } from 'lookups/models';
import * as fromLookupsStore from 'lookups/store';
import * as fromAuthStore from 'auth/store';
import * as fromSettingsStore from 'settings/store';
import { TranslationService } from 'shared';
import { OrganizationSetting } from 'settings/models';
import { User } from 'security/models';

import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
})
export class NavbarComponent implements OnInit, OnDestroy {
  /**
   * Emits a new value whenever the navbar collapse change.
   */
  @Output() toggleHideSidebar = new EventEmitter<any>();
   appSidebar = document.getElementsByClassName('app-sidebar')[0];


  /**
   * Shows or hide the user preferences modal.
   */
  userPreferencesModalVisibility = false;

  /**
   * The list of languages
   */
  languages$: Observable<Language[]>;

  /**
   * The organization settings.
   */
  organizationSettings$: Observable<OrganizationSetting>;

  /**
   * The current log-in user.
   */
  currentUser: User;
  isSidebarHidden: boolean=false;

  /**
   * Gets or sets a css class that identifies the current screen mode either full-screen or normal-screen.
   */
  fullScreenModeClass: 'ft-maximize' | 'ft-minimize' = 'ft-maximize';

  layoutModeClass:'fa fa-moon-o' | 'fa fa-sun-o'='fa fa-moon-o';
  /**
   * Gets or sets a value indicates whether the navbar is collapsed or not.
   */
  isCollapsed = true;

  /**
   * Gets or sets the current selected language.
   */
  get currentLang() {
    return this.translationService.language;
  }

  /**
   * Gets or sets the ngb-dropdown position based on the current user display language.
   */
  get ngbDropdownPosition(): string {
    return this.currentLang === 'ar' ? 'bottom-left' : 'bottom-right';
  }

  /**
   * The set of subscriptions on this components,
   * these subscriptions must be unsubscribed before this component got destroyed.
   */
  subscriptions = new Subscription();

  /**
   * @param renderer The Renderer2 service.
   * @param storageService The storage service.
   * @param translationService The translation service.
   * @param lookupsStore$ The lookups-store module.
   * @param authStore$ The auth-Module store.
   * @param settingsStore$ The settings-Module store.
   * @param modalService The modal service.
   */
  constructor(
    private renderer: Renderer2,
    private translationService: TranslationService,
    private lookupsStore$: Store<fromLookupsStore.LookupsState>,
    private authStore$: Store<fromAuthStore.AuthState>,
    private settingsStore$: Store<fromSettingsStore.SettingsState>
  ) {}

  /**
   * Gets or sets a value indicates whether the dark mode is active  or not.
   */
  isDarkMode = true;

  ngOnInit() {
    this.init();
  }

  /**
   * Destroy component data
   */
  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  /**
   * Initialize component data.
   */
  init() {
    /**
     * Set data.
     */
    this.languages$ = this.lookupsStore$.pipe(select(fromLookupsStore.getLanguages));
    this.organizationSettings$ = this.settingsStore$.pipe(select(fromSettingsStore.getOrganizationsSettings));

    let isOrgSettingsLoadedBefore = false;

    /** Load current logged in user data. */
    this.subscriptions.add(
      this.authStore$
        .pipe(
          select(fromAuthStore.getSessionUser),
          tap((user: User) => {
            if (user) {
              this.currentUser = user;

              if (user.organizationId && !isOrgSettingsLoadedBefore) {
                /**
                 * Fetch the organization settings.
                 */
                this.settingsStore$.dispatch(new fromSettingsStore.GetOrganizationSettings());

                isOrgSettingsLoadedBefore = true;
              }
            }
          })
        )
        .subscribe()
    );

    this.applyLayoutStyle();
  }

  /**
   * Applies the layout styles.
   */
  themColorFlag:Boolean=true;
  applyLayoutStyle(): void {
    environment.layoutVariants.forEach((variant) => this.renderer.addClass(document.body, variant));
    //this.renderer.addClass(document.body, environment.layoutBgColor);
    if(this.themColorFlag){
      //this.renderer.removeClass(document.body, 'layout-transparent');
      this.renderer.removeClass(document.body, 'layout-light');
      this.renderer.addClass(document.body, 'layout-dark');
      this.layoutModeClass='fa fa-sun-o';
      this.isDarkMode=true
    }else {
     // this.renderer.removeClass(document.body, 'layout-transparent');
      this.renderer.removeClass(document.body, 'layout-dark');
      this.renderer.addClass(document.body, 'layout-light');
      this.layoutModeClass='fa fa-moon-o';
      this.isDarkMode=false
    }
    this.themColorFlag=!this.themColorFlag;
    console.log(this.themColorFlag);

    if (this.appSidebar.classList.contains('hide-sidebar')) {
      this.isSidebarHidden=true;

    }else{
      this.isSidebarHidden=false;
    }
  }

  /**
   * Changes the full-screen mode.
   */
  toggleFullScreen() {
    if (this.fullScreenModeClass === 'ft-maximize') {
      this.fullScreenModeClass = 'ft-minimize';
    } else {
      this.fullScreenModeClass = 'ft-maximize';
    }
  }

  /**
   * Changes the navbar collapse state.
   */
  toggleSidebar() {

    const appSidebar = document.getElementsByClassName('app-sidebar')[0];
    if (appSidebar.classList.contains('hide-sidebar')) {
      this.toggleHideSidebar.emit(false);
      this.isSidebarHidden=false;

    } else {
      this.toggleHideSidebar.emit(true);
      this.isSidebarHidden=true;
    }
  }

  toggleSidebarSm(){
    const appSidebar = document.getElementsByClassName('app-sidebar')[0];
    if (appSidebar.classList.contains('hide-sidebar')) {
      this.toggleHideSidebar.emit(false);
      this.isSidebarHidden=true;

    } else {
      this.toggleHideSidebar.emit(true);
      this.isSidebarHidden=true;
    }
  }

  /**
   * Changes the language state.
   */
  changeLanguage(lang: string) {
    this.authStore$.dispatch(new fromAuthStore.UpdateUserLanguage(lang));
  }

  /**
   * Log user out and redirects to the login page if needed.
   */
  logout() {
    this.authStore$.dispatch(new fromAuthStore.Logout(true));
  }
}
