import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subscription } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { tap } from 'rxjs/operators';

import { NotificationService, PaginationInfo, TranslationService } from 'shared';
import * as fromProjectsManagementStore from 'projects-management/store';
import * as fromLookupsStore from 'lookups/store';
import { ProjectRevenueType } from 'lookups/models';
import { ProjectRevenue } from 'projects-management/models';

@Component({
  selector: 'app-project-revenues',
  templateUrl: './project-revenues.component.html',
  styles: [],
})
export class ProjectRevenuesComponent implements OnInit, OnDestroy {
  /**
   * Sets the currently project id.
   */
  @Input() projectId: number;

  /**
   * The list of project revenue types.
   */
  projectRevenueTypes$: Observable<ProjectRevenueType[]>;

  /**
   * The list of loaded project revenues.
   */
  projectRevenues$: Observable<ProjectRevenue[]>;

  /**
   * The pagination info.
   */
  paginationInfo$: Observable<PaginationInfo>;

  /**
   * Indicates whether there is a search process is running or not.
   */
  isSearching$: Observable<boolean>;

  /**
   * The search form.
   */
  searchForm: FormGroup;

  /**
   * Gets or sets the currently selected project revenue.
   */

  selectedProjectRevenue: ProjectRevenue;

  /**
   *  The initial `fromDate` value for search.
   */
  initialFromDate = new Date();

  /** The initial `toDate` value for search.
   * It's the current day of month.
   */
  initialToDate = new Date();

  /**
   * The set of subscriptions on this components,
   * these subscriptions must be unsubscribed before this component got destroyed.
   */
  subscriptions = new Subscription();

  /**
   * The ngb-modal reference to use it when modal needed to be closed.
   */
  modal: NgbModalRef;

  /**
   * @param modalService The modal service.
   * @param notificationService The notification service.
   * @param translationService: The translation service.
   * @param projectsManagementStore$ the  projects-management-store module.
   * @param lookupsStore$ the lookups-store module.
   */
  constructor(
    private modalService: NgbModal,
    private notificationService: NotificationService,
    private translationService: TranslationService,
    private projectsManagementStore$: Store<fromProjectsManagementStore.ProjectsManagementState>,
    private lookupsStore$: Store<fromLookupsStore.LookupsState>
  ) {}

  ngOnInit(): void {
    this.init();
  }

  /**
   * Destroy component data
   */
  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  /**
   * Initialize component data.
   */
  init() {
    this.initForms();

    /**
     * Loads data.
     */
    let isManualSearchTriggeredBeforeForProjectRevenueTypes = false;
    this.projectRevenueTypes$ = this.lookupsStore$.pipe(
      select(fromLookupsStore.getProjectRevenueTypes),
      tap((projectRevenueTypes) => {
        if (!isManualSearchTriggeredBeforeForProjectRevenueTypes && !projectRevenueTypes.length) {
          isManualSearchTriggeredBeforeForProjectRevenueTypes = true;
          this.lookupsStore$.dispatch(new fromLookupsStore.GetAllProjectRevenueTypes());
        }
      })
    );

    this.isSearching$ = this.projectsManagementStore$.pipe(
      select(fromProjectsManagementStore.getProjectRevenuesSearching)
    );
    this.projectRevenues$ = this.projectsManagementStore$.pipe(select(fromProjectsManagementStore.getProjectRevenues));
    this.paginationInfo$ = this.projectsManagementStore$.pipe(
      select(fromProjectsManagementStore.getProjectRevenuesPaginationInfo)
    );

    this.search();
  }

  /**
   * Initialize forms and add validators.
   */
  initForms() {
    this.searchForm = new FormGroup({
      projectRevenueTypes: new FormControl([]),
      fromDate: new FormControl({
        year: this.initialFromDate.getFullYear(),
        month: this.initialFromDate.getMonth() + 1,
        day: this.initialFromDate.getDate(),
      }),
      toDate: new FormControl({
        year: this.initialToDate.getFullYear(),
        month: this.initialToDate.getMonth() + 1,
        day: this.initialToDate.getDate(),
      }),
      page: new FormControl(1),
    });
  }

  /**
   * Loads the current selected project revenue.
   */
  search() {
    this.applyFiltersAndSearch();
  }

  /**
   * Handles pagination page-changed event.
   * @param page The current selected page number.
   */
  pageChanged(page: number) {
    /** Update pagination page. */
    this.searchForm.patchValue({ page });

    this.applyFiltersAndSearch(page);
  }

  /**
   * Applies the search filters and dispatches a search action.
   * @param page The current page number, default to `1`.
   */
  applyFiltersAndSearch(page: number = 1) {
    if (!this.searchForm.get('fromDate')?.value || !this.searchForm.get('toDate')?.value) {
      return this.notificationService.warning(
        this.translationService.translate('PROJECTS_MANAGEMENT.PROJECTS.NOTIFICATION_MESSAGE.SELECT_DATE')
      );
    }

    const { fromDate, toDate, projectRevenueTypes } = this.searchForm.value;

    const fromDateFormatted = new Date(fromDate.year, fromDate.month - 1, fromDate.day);
    fromDateFormatted.setHours(0, 0, 0, 0);

    const toDateFormatted = new Date(toDate.year, toDate.month - 1, toDate.day);
    toDateFormatted.setHours(23, 59, 59, 0);

    this.projectsManagementStore$.dispatch(
      new fromProjectsManagementStore.SearchProjectRevenues({
        projectId: this.projectId,
        projectRevenueTypes,
        fromDate: fromDateFormatted,
        toDate: toDateFormatted,
        page,
      })
    );
  }

  /**
   * Enables trackBy feature for *ngFor utility to track items
   * depending on a comparer to reduce DOM change.
   * If the item is already exist then no HTML will change, only bounded values.
   * @param index The index of the item.
   * @param projectRevenue The item to determine if it was changed or not.
   */
  trackItems(index: number, projectRevenue: ProjectRevenue) {
    return projectRevenue ? projectRevenue.id : undefined;
  }

  /**
   * Opens the modal of the given templateRef.
   * @param modalRef The modal templateRef to be opened.
   */
  openModal(modalRef) {
    this.modalService.open(modalRef, { size: 'xl' });
  }
}
