import { Component, Input } from '@angular/core';
import { FormArray, FormGroup, FormControl, Validators } from '@angular/forms';

import { CustomValidators, NotificationService, TranslationService } from 'shared';
import { ProductIngredientsFormItem, Product, IngredientProducts } from 'stores/models';

@Component({
  selector: '[app-sub-product-ingredients-form]',
  templateUrl: './sub-product-ingredients-form.component.html',
  styles: [],
})
export class SubProductIngredientsFormComponent {
  /**
   * Sets the product ingredients form-array.
   */
  @Input() formArray: FormArray;

  /**
   * Sets the name form-array in its parent form.
   * @default 'subProductIngredients'
   */
  @Input() formArrayName = 'subProductIngredients';

  /**
   * Sets the initial productIngredients.
   */
  @Input() set initialProductIngredients(productIngredients: IngredientProducts[]) {
    if (!productIngredients?.length) {
      return;
    }

    productIngredients.forEach((productIngredient) => {
      this.addOldProductIngredient(productIngredient);
    });
  }

  /**
   * Shows or hide the product list.
   */
  ProductsListVisibility = false;

  constructor(private notificationService: NotificationService, private translationService: TranslationService) {}

  /**
   * Adds the newly selected locations the products search list.
   * @param products The list of newly selected products to be added.
   */
  selectProduct([subProductIngredient]: Product[]) {
    if (subProductIngredient) {
      if (
        (this.formArray.value as ProductIngredientsFormItem[]).some(
          (item) => item.subProductIngredient.id === subProductIngredient.id
        )
      ) {
        return this.notificationService.warning(
          this.translationService.translate('STORES.PRODUCTS.NOTIFICATION_MESSAGE.PRODUCT_ALREADY_EXIST')
        );
      }

      this.addIngredient({ ingredientId: subProductIngredient.id, subProductIngredient, quantity: 0 });
    }
  }

  /**
   * Creates & adds a new product ingredient form-group with validations.
   * @param ingredientProduct The product ingredient details to be
   *  bounded to the product ingredient, If omitted a blank product ingredient will be created.
   */
  addIngredient(ingredientProduct: ProductIngredientsFormItem) {
    const ingredientProductFormGroup = new FormGroup({
      ingredientId: new FormControl(ingredientProduct.subProductIngredient.id),
      subProductIngredient: new FormControl(ingredientProduct.subProductIngredient),
      quantity: new FormControl(ingredientProduct.quantity, [Validators.required, CustomValidators.gt(0)]),
    });

    this.formArray.push(ingredientProductFormGroup);
  }

  /**
   * Creates & adds a new product-ingredient form-group for an existing product-ingredient with validations.
   * @param productIngredient The product-ingredient to be added to the form-array.
   */
  addOldProductIngredient(productIngredient: IngredientProducts) {
    const productIngredientFormGroup = new FormGroup({
      id: new FormControl(productIngredient.id),
      ingredientId: new FormControl(productIngredient.subProductIngredientId),
      subProductIngredient: new FormControl(productIngredient.subProductIngredient),
      quantity: new FormControl(productIngredient.quantity, [Validators.required, CustomValidators.gt(0)]),
      isNew: new FormControl(false),
    });

    this.formArray.push(productIngredientFormGroup);
  }

  /**
   * Remove the product ingredient at the given index from the list.
   * @param index The index of the product ingredient to be removed.
   */
  removeIngredient(index: number) {
    this.formArray.removeAt(index);
  }

  /**
   * Remove all of the list of commercial registration Numbers.
   *
   * It only adds one product ingredient for quick start.
   */
  resetIngredients() {
    this.formArray.clear();
  }
}
