import { Report } from 'reports/models';

/**
 * Represents the finances reports state.
 */
export class FinancesReportsState {
  /**
   * The loaded report.
   */
  public accountAnalysis: Report;

  /**
   * The loaded report.
   */
  public balanceSheet: Report;

  /**
   * The loaded report.
   */
  public bankAccountSummary: Report;

  /**
   * loaded report.
   */
  public incomeStatement: Report;

  /**
   * The loaded report.
   */
  public journals: Report;

  /**
   * The loaded report.
   */
  public taxes: Report;

  /**
   * The loaded report.
   */
  public trialBalance: Report;

  /**
   * An error that may resulting during processing some actions.
   */
  public error: any;

  /**
   * Determines if there is a running load process.
   */
  public isLoadingAccountAnalysis: boolean;

  /**
   * Determines if the last load process has been ended successfully.
   */
  public isAccountAnalysisLoadCompleted: boolean;

  /**
   * Determines if there is a running load process.
   */
  public isLoadingBalanceSheet: boolean;

  /**
   * Determines if the last load process has been ended successfully.
   */
  public isBalanceSheetLoadCompleted: boolean;

  /**
   * Determines if there is a running load process.
   */
  public isLoadingBankAccountSummary: boolean;

  /**
   * Determines if the last load process has been ended successfully.
   */
  public isBankAccountSummaryLoadCompleted: boolean;

  /**
   * Determines if there is a running load process.
   */
  public isLoadingJournals: boolean;

  /**
   * Determines if the last load process has been ended successfully.
   */
  public isJournalsLoadCompleted: boolean;

  /**
   * Determines if there is a running load process.
   */
  public isLoadingTaxes: boolean;

  /**
   * Determines if the last load process has been ended successfully.
   */
  public isTaxesLoadCompleted: boolean;

  /**
   * Determines if there is a running load process.
   */
  public isLoadingTrialBalance: boolean;

  /**
   * Determines if the last load process has been ended successfully.
   */
  public isTrialBalanceLoadCompleted: boolean;

  /**
   * Determines if there is a running load process.
   */
  public isLoadingIncomeStatement: boolean;

  /**
   * Determines if the last load process has been ended successfully.
   */
  public isIncomeStatementLoadCompleted: boolean;
}
