import { UserReportsState } from 'reports/store/states/user-reports.state';
import { StoresReportsState } from 'reports/store/states/stores.state';
import { FinancesReportsState } from 'reports/store/states/finances.state';
import { PurchasesReportsState } from 'reports/store/states/purchases.state';
import { SalesReportsState } from 'reports/store/states/sales.state';

/**
 * Represents the reports-module state.
 */
export class ReportsState {
  /**
   * Represents the user reports state.
   */
  public userReports: UserReportsState;

  /**
   * Represents the stores reports state.
   */
  public stores: StoresReportsState;

  /**
   * Represents the finances reports state.
   */
  public finances: FinancesReportsState;

  /**
   * Represents the purchases reports state.
   */
  public purchases: PurchasesReportsState;

  /**
   * Represents the sales reports state.
   */
  public sales: SalesReportsState;
}
