import { Component } from '@angular/core';
import { Location } from '@angular/common';

import { PageInfo } from 'shared';
import { Claims } from 'security/models';

/**
 * The bank account file pages.
 */
enum PAGES {
  'governmental-service-requests' = 'governmental-service-requests',
  loans = 'loans',
  payrolls = 'payrolls',
  'paid-payrolls' = 'paid-payrolls',
  'payroll-payments' = 'payroll-payments',
  'payroll-payment-refunds' = 'payroll-payment-refunds',
}

@Component({
  selector: 'app-hr-container',
  templateUrl: './hr-container.component.html',
  styles: [],
})
export class HrContainerComponent {
  /**
   * Gets or sets the information about the current page.
   */
  pageInfo: PageInfo = {
    title: 'FINANCES.HR.HR_TITLE',
    icon: 'fa fa-group',
  };

  /**
   * The system supported user claims.
   */
  Claims = Claims;

  /**
   * Gets or sets the selected page.
   * @default 'governmental-service-requests'
   */
  activePage: PAGES = PAGES['governmental-service-requests'];

  /**
   * @param locationService the location service.
   */
  constructor(private locationService: Location) {}

  /**
   * Updates the browser url according to the selected page.
   * @param page The newly selected page.
   */
  selectedPageChanged(page: PAGES) {
    this.locationService.replaceState(`${this.locationService.path()}#${PAGES[page]}`);
  }
}
