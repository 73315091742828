import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';

import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

import * as fromAppSearchStore from 'app-search/store';
import { PaginationInfo, NotificationService, TranslationService } from 'shared';
import { Region } from 'stores/models';

@Component({
  selector: 'regions-search',
  templateUrl: './regions-search.component.html',
  styles: [],
})
export class RegionsSearchComponent implements OnInit {
  /**
   * The modal template reference.
   */
  @ViewChild('modalRef') modalRef: ElementRef<any>;

  /**
   * Indicates whether the modal will automatically close after select the first region.
   *
   * Be default it will not be closed.
   */
  @Input() closeOnSelect = false;

  /**
   * Indicates whether the regions displayed on the list should be all active.
   *
   * Be default it will not be closed.
   */
  @Input() onlyActive = false;

  /**
   * Sets the visibility of this component modal.
   *
   * Handles visibility change in two-data-binding approach.
   */
  @Input() set visible(isShown: boolean) {
    if (isShown && this.modalRef) {
      this.openModal(this.modalRef);
    } else {
      this.closeModal();
    }

    this.visibleChange.emit(isShown);
  }

  /**
   * Outputs a value that indicates whether the modal is shown or not.
   *
   * Handles visibility change in two-data-binding approach.
   */
  @Output() visibleChange = new EventEmitter<boolean>();

  /** Outputs the selected regions. */
  @Output() regions = new EventEmitter<Region[]>();

  /**
   * Indicates whether there is a search process is running or not.
   */
  isSearching$: Observable<boolean>;

  /**
   * Shows or hide the create region modal.
   */
  createRegionsModalVisibility = false;

  /**
   * The list of regions.
   */
  regions$: Observable<Region[]>;

  /**
   * The list of regions.
   */
  regionsList: Region[];

  /**
   * The pagination info.
   */
  paginationInfo$: Observable<PaginationInfo>;

  /**
   * The search form.
   */
  searchForm: FormGroup;

  /**
   * The ngb-modal reference to use it when modal needed to be closed.
   */
  modal: NgbModalRef;

  /**
   * @param modalService The modal service.
   * @param notificationService The notification service.
   * @param translationService The translation service.
   * @param appSearchStore$ The app search store.
   */
  constructor(
    private modalService: NgbModal,
    private notificationService: NotificationService,
    private translationService: TranslationService,
    private appSearchStore$: Store<fromAppSearchStore.AppSearchState>
  ) {}

  ngOnInit(): void {
    this.init();
  }

  /**
   * Initialize component data.
   */
  init() {
    this.initForm();

    /**
     * Load data.
     */
    let isManualSearchTriggeredBeforeForClasses = false;
    this.regions$ = this.appSearchStore$.pipe(
      select(fromAppSearchStore.getRegions),
      tap((regions) => {
        if (regions) {
          this.regionsList = regions;
        }
        if (!isManualSearchTriggeredBeforeForClasses && !regions.length) {
          isManualSearchTriggeredBeforeForClasses = true;
          this.search();
        }
      })
    );

    this.isSearching$ = this.appSearchStore$.pipe(select(fromAppSearchStore.getRegionsSearching));

    this.paginationInfo$ = this.appSearchStore$.pipe(select(fromAppSearchStore.getRegionsPaginationInfo));
  }

  /**
   * Initialize form and add validators.
   */
  initForm() {
    this.searchForm = new FormGroup({
      name: new FormControl(''),
      page: new FormControl(1),
    });
  }

  /**
   * Handles search parameters change.
   */
  search(event?: KeyboardEvent) {
    if (event && event.key === 'Enter') {
      event.preventDefault();
      return;
    }

    this.appSearchStore$.dispatch(
      new fromAppSearchStore.SearchRegions({
        name: this.searchForm.get('name').value,
        page: 1,
      })
    );
  }

  /**
   * Handles pagination page-changed event.
   * @param page The current selected page number.
   */
  pageChanged(page: number) {
    /** Update pagination page. */
    this.searchForm.patchValue({ page });

    /** Get selected page items. */
    this.appSearchStore$.dispatch(
      new fromAppSearchStore.SearchRegions({
        name: this.searchForm.get('name').value,
        page: this.searchForm.get('page').value,
      })
    );
  }

  /**
   * Enables trackBy feature for *ngFor utility to track items
   * depending on a comparer to reduce DOM change.
   * If the item is already exist then no HTML will change, only bounded values.
   * @param index The index of the item.
   * @param unit of measure The item to determine if it was changed or not.
   */
  trackItems(index: number, region: Region) {
    return region ? region.id : undefined;
  }

  /**
   * Opens the modal of the given templateRef.
   * @param modalRef The modal templateRef to be opened.
   */
  openModal(modalRef) {
    /**
     * Open the modal & listen for user press ESC key.
     */
    this.modal = this.modalService.open(modalRef, { size: 'lg' });
    this.modal.result.then(null, () => this.closeModal());
  }

  /**
   * Closes the currently opened modal.
   */
  closeModal() {
    this.modal?.dismiss();
    this.regions.emit([]);
    this.visibleChange.emit(false);
  }

  /**
   * Handles region selected event.
   * @param region The list of region to notify selected.
   */
  selectRegion(region: Region[]) {
    this.notificationService.successInstant(this.translationService.translate('STORES.REGIONS.REGION_SELECTED'));
    this.regions.emit(region);
    if (this.closeOnSelect) {
      this.closeModal();
    }
  }
}
