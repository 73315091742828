import { Action } from '@ngrx/store';

import { NationalIdentifierType } from 'lookups/models';
import { AppHttpResponse } from 'shared';

/**
 * The Lookups-Module store national identifier type action types.
 */
export enum NationalIdentifierTypeActionType {
  GET_ALL_NATIONAL_IDENTIFIER_TYPES = '[Lookups] [NationalIdentifierType] Get All National Identifier Types',
  GET_ALL_NATIONAL_IDENTIFIER_TYPES_FAIL = '[Lookups] [NationalIdentifierType] Get All National Identifier Types Fail',
  GET_ALL_NATIONAL_IDENTIFIER_TYPES_SUCCESS = '[Lookups] [NationalIdentifierType] Get All National Identifier Types Success',
}

/**
 * Represents a store national identifier type action.
 */
export class GetAllNationalIdentifierTypes implements Action {
  /**
   * The type of the action.
   */
  readonly type = NationalIdentifierTypeActionType.GET_ALL_NATIONAL_IDENTIFIER_TYPES;

  /**
   * Fires a new national identifier type action.
   */
  constructor() {}
}

/**
 * Represents a store national identifier type fail action.
 */
export class GetAllNationalIdentifierTypesFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = NationalIdentifierTypeActionType.GET_ALL_NATIONAL_IDENTIFIER_TYPES_FAIL;

  /**
   * Fires a new national identifier type fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store national identifier type success action.
 */
export class GetAllNationalIdentifierTypesSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = NationalIdentifierTypeActionType.GET_ALL_NATIONAL_IDENTIFIER_TYPES_SUCCESS;

  /**
   * Fires a national identifier type success action.
   * @param payload An object contains the list of national identifier Types.
   */
  constructor(public payload: AppHttpResponse<NationalIdentifierType[]>) {}
}

/**
 * Lookups-module national identifier type action types.
 */
export type NationalIdentifierTypeActions =
  | GetAllNationalIdentifierTypes
  | GetAllNationalIdentifierTypesFail
  | GetAllNationalIdentifierTypesSuccess;
