import { Injectable } from '@angular/core';

import { of } from 'rxjs';
import { switchMap, map, catchError, tap } from 'rxjs/operators';
import { Effect, ofType, Actions } from '@ngrx/effects';

import { NotificationService, StatusCode, TranslationService } from 'shared';
import { ProjectResourcesService } from 'projects-management/services';
import {
  ProjectResourcesActionType,
  FindProjectResourceLines,
  FindProjectResourceLinesFail,
  FindProjectResourceLinesSuccess,
  FindProjectResourcePayrollLines,
  FindProjectResourcePayrollLinesSuccess,
  FindProjectResourcePayrollLinesFail,
  FindProjectResourceOutgoingStockLinesSuccess,
  FindProjectResourceOutgoingStockLines,
  FindProjectResourceOutgoingStockLinesFail,
  FindProjectResourcePurchaseInvoiceLines,
  FindProjectResourcePurchaseInvoiceLinesSuccess,
  FindProjectResourcePurchaseInvoiceLinesFail,
  FindProjectResourcePurchaseReturnLines,
  FindProjectResourcePurchaseReturnLinesSuccess,
  FindProjectResourcePurchaseReturnLinesFail,
  FindProjectResourceAssetDepreciationLines,
  FindProjectResourceAssetDepreciationLinesSuccess,
  FindProjectResourceAssetDepreciationLinesFail,
} from 'projects-management/store/actions';

@Injectable()
export class ProjectResourcesEffects {
  constructor(
    private actions$: Actions,
    private projectResourcesService: ProjectResourcesService,
    private notificationService: NotificationService,
    private translationService: TranslationService
  ) {}

  /* ========================= FIND_PROJECT_RESOURCE_LINES =================================== */
  @Effect()
  find$ = this.actions$.pipe(
    ofType(ProjectResourcesActionType.FIND_PROJECT_RESOURCE_LINES),
    switchMap((action: FindProjectResourceLines) =>
      this.projectResourcesService.findById(action.payload).pipe(
        map((response) => new FindProjectResourceLinesSuccess(response)),
        catchError((error) => of(new FindProjectResourceLinesFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  findFail$ = this.actions$.pipe(
    ofType(ProjectResourcesActionType.FIND_PROJECT_RESOURCE_LINES_FAIL),
    tap((action: FindProjectResourceLinesFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate('PROJECTS_MANAGEMENT.PROJECTS.PROJECT_NOT_FOUND')
        );
      }
    })
  );

  /* ========================= FIND_PROJECT_RESOURCE_PAYROLL_LINES =================================== */
  @Effect()
  findPayroll$ = this.actions$.pipe(
    ofType(ProjectResourcesActionType.FIND_PROJECT_RESOURCE_PAYROLL_LINES),
    switchMap((action: FindProjectResourcePayrollLines) =>
      this.projectResourcesService.findProjectResourcePayrollLinesById(action.payload).pipe(
        map((response) => new FindProjectResourcePayrollLinesSuccess(response)),
        catchError((error) => of(new FindProjectResourcePayrollLinesFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  findPayrollFail$ = this.actions$.pipe(
    ofType(ProjectResourcesActionType.FIND_PROJECT_RESOURCE_PAYROLL_LINES_FAIL),
    tap((action: FindProjectResourcePayrollLinesFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate('PROJECTS_MANAGEMENT.PROJECTS.PROJECT_PAYROLL_NOT_FOUND')
        );
      }
    })
  );

  /* ========================= FIND_PROJECT_RESOURCE_OUTGOING_STOCK_LINES =================================== */
  @Effect()
  findOutgoingStock$ = this.actions$.pipe(
    ofType(ProjectResourcesActionType.FIND_PROJECT_RESOURCE_OUTGOING_STOCK_LINES),
    switchMap((action: FindProjectResourceOutgoingStockLines) =>
      this.projectResourcesService.findProjectResourceOutgoingStockLinesById(action.payload).pipe(
        map((response) => new FindProjectResourceOutgoingStockLinesSuccess(response)),
        catchError((error) => of(new FindProjectResourceOutgoingStockLinesFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  findOutgoingStockFail$ = this.actions$.pipe(
    ofType(ProjectResourcesActionType.FIND_PROJECT_RESOURCE_OUTGOING_STOCK_LINES_FAIL),
    tap((action: FindProjectResourceOutgoingStockLinesFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate('PROJECTS_MANAGEMENT.PROJECTS.PROJECT_OUTGOING_STOCK_NOT_FOUND')
        );
      }
    })
  );

  /* ========================= FIND_PROJECT_RESOURCE_PURCHASE_INVOICE_LINES =================================== */
  @Effect()
  findPurchaseInvoice$ = this.actions$.pipe(
    ofType(ProjectResourcesActionType.FIND_PROJECT_RESOURCE_PURCHASE_INVOICE_LINES),
    switchMap((action: FindProjectResourcePurchaseInvoiceLines) =>
      this.projectResourcesService.findProjectResourcePurchaseInvoiceLinesById(action.payload).pipe(
        map((response) => new FindProjectResourcePurchaseInvoiceLinesSuccess(response)),
        catchError((error) => of(new FindProjectResourcePurchaseInvoiceLinesFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  findPurchaseInvoiceFail$ = this.actions$.pipe(
    ofType(ProjectResourcesActionType.FIND_PROJECT_RESOURCE_PURCHASE_INVOICE_LINES_FAIL),
    tap((action: FindProjectResourcePurchaseInvoiceLinesFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate('PROJECTS_MANAGEMENT.PROJECTS.PROJECT_PURCHASE_INVOICE_NOT_FOUND')
        );
      }
    })
  );

  /* ========================= FIND_PROJECT_RESOURCE_PURCHASE_RETURN_LINES =================================== */
  @Effect()
  findPurchaseReturn$ = this.actions$.pipe(
    ofType(ProjectResourcesActionType.FIND_PROJECT_RESOURCE_PURCHASE_RETURN_LINES),
    switchMap((action: FindProjectResourcePurchaseReturnLines) =>
      this.projectResourcesService.findProjectResourcePurchaseReturnLinesById(action.payload).pipe(
        map((response) => new FindProjectResourcePurchaseReturnLinesSuccess(response)),
        catchError((error) => of(new FindProjectResourcePurchaseReturnLinesFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  findPurchaseReturnFail$ = this.actions$.pipe(
    ofType(ProjectResourcesActionType.FIND_PROJECT_RESOURCE_PURCHASE_RETURN_LINES_FAIL),
    tap((action: FindProjectResourcePurchaseReturnLinesFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate('PROJECTS_MANAGEMENT.PROJECTS.PROJECT_PURCHASE_RETURN_NOT_FOUND')
        );
      }
    })
  );

  /* ========================= FIND_PROJECT_RESOURCE_ASSET_LINES =================================== */
  @Effect()
  findAssetDepreciation$ = this.actions$.pipe(
    ofType(ProjectResourcesActionType.FIND_PROJECT_RESOURCE_ASSET_DEPRECIATION_LINES),
    switchMap((action: FindProjectResourceAssetDepreciationLines) =>
      this.projectResourcesService.findProjectResourceAssetDepreciationLinesById(action.payload).pipe(
        map((response) => new FindProjectResourceAssetDepreciationLinesSuccess(response)),
        catchError((error) => of(new FindProjectResourceAssetDepreciationLinesFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  findAssetDepreciationFail$ = this.actions$.pipe(
    ofType(ProjectResourcesActionType.FIND_PROJECT_RESOURCE_ASSET_DEPRECIATION_LINES_FAIL),
    tap((action: FindProjectResourceAssetDepreciationLinesFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate('PROJECTS_MANAGEMENT.PROJECTS.PROJECT_ASSET_NOT_FOUND')
        );
      }
    })
  );
}
