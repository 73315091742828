import { createSelector } from '@ngrx/store';

import { selectLookupsState } from 'lookups/store/selectors/feature.selectors';
import { LookupsState, ReligionState } from 'lookups/store/states';

/**
 * Gets the religion state.
 */
const selectReligions = createSelector(selectLookupsState, (state: LookupsState) => state.religions);

/**
 * Gets the list of loaded religions.
 */
export const getReligions = createSelector(selectReligions, (state: ReligionState) => state.data);

/**
 * Gets the error that occurred in the state.
 */
export const getReligionsError = createSelector(selectReligions, (state: ReligionState) => state.error);

/**
 * Determines if there is a running load process.
 */
export const getReligionsIsLoading = createSelector(selectReligions, (state: ReligionState) => state.isLoading);

/**
 * Determines if the last load process has been ended successfully.
 */
export const getReligionsIsLoaded = createSelector(selectReligions, (state: ReligionState) => state.isLoaded);
