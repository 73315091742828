import { createSelector } from '@ngrx/store';

import { selectLookupsState } from 'lookups/store/selectors/feature.selectors';
import { LookupsState, CustomerAdvancePaymentLogTypeState } from 'lookups/store/states';

/**
 * Gets the customer advance payment log type state.
 */
const selectCustomerAdvancePaymentLogTypes = createSelector(
  selectLookupsState,
  (state: LookupsState) => state.customerAdvancePaymentLogTypes
);

/**
 * Gets the list of loaded customer advance payment log types.
 */
export const getCustomerAdvancePaymentLogTypes = createSelector(
  selectCustomerAdvancePaymentLogTypes,
  (state: CustomerAdvancePaymentLogTypeState) => state.data
);

/**
 * Gets the error that occurred in the state.
 */
export const getCustomerAdvancePaymentLogTypesError = createSelector(
  selectCustomerAdvancePaymentLogTypes,
  (state: CustomerAdvancePaymentLogTypeState) => state.error
);

/**
 * Determines if there is a running load process.
 */
export const getCustomerAdvancePaymentLogTypesIsLoading = createSelector(
  selectCustomerAdvancePaymentLogTypes,
  (state: CustomerAdvancePaymentLogTypeState) => state.isLoading
);

/**
 * Determines if the last load process has been ended successfully.
 */
export const getCustomerAdvancePaymentLogTypesIsLoaded = createSelector(
  selectCustomerAdvancePaymentLogTypes,
  (state: CustomerAdvancePaymentLogTypeState) => state.isLoaded
);
