import { createSelector } from '@ngrx/store';

import { selectLookupsState } from 'lookups/store/selectors/feature.selectors';
import { IncomingStockTypeState, LookupsState } from 'lookups/store/states';

/**
 * Gets the incoming stock types state.
 */
const selectIncomingStockTypes = createSelector(selectLookupsState, (state: LookupsState) => state.incomingStockTypes);

/**
 * Gets the list of loaded incoming stock types.
 */
export const getIncomingStockTypes = createSelector(
  selectIncomingStockTypes,
  (state: IncomingStockTypeState) => state.data
);

/**
 * Gets the error that occurred in the state.
 */
export const getIncomingStockTypesError = createSelector(
  selectIncomingStockTypes,
  (state: IncomingStockTypeState) => state.error
);

/**
 * Determines if there is a running load process.
 */
export const getIncomingStockTypesIsLoading = createSelector(
  selectIncomingStockTypes,
  (state: IncomingStockTypeState) => state.isLoading
);

/**
 * Determines if the last load process has been ended successfully.
 */
export const getIncomingStockTypesIsLoaded = createSelector(
  selectIncomingStockTypes,
  (state: IncomingStockTypeState) => state.isLoaded
);
