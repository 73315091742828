import { Component, Input } from '@angular/core';

import { Decimal } from 'decimal.js';

import { TransferStockProduct, TransferStock } from 'stocks/models';

@Component({
  selector: 'app-journal-transfer-stock-details',
  templateUrl: './journal-transfer-stock-details.component.html',
  styles: [],
})
export class JournalTransferStockDetailsComponent {
  /**
   * Sets the current viewed journal transfer stock.
   */
  @Input() transferStock: TransferStock;

  /**
   * Gets the total quantity of transfer stock products.
   */
  productsQuantity(transferStockProducts: TransferStockProduct[]): number {
    return Decimal.sum(0, ...transferStockProducts.map((product) => product.quantity ?? 0)).toNumber();
  }
}
