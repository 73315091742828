import { StoresReportsActionType, StoresReportsActions } from 'reports/store/actions';
import { StoresReportsState } from 'reports/store/states';

/**
 * The initial state from which the state starts.
 */
const initialState: StoresReportsState = {
  productsList: null,
  productMovement: null,
  incomingStock: null,
  outgoingStock: null,
  transferStock: null,
  stockInventory: null,
  stockMonitoring: null,
  unhandledSalesStock: null,
  error: null,
  isLoadingProductsList: false,
  isProductsListLoadCompleted: false,
  isLoadingProductMovement: false,
  isProductMovementLoadCompleted: false,
  isLoadingIncomingStock: false,
  isIncomingStockLoadCompleted: false,
  isLoadingOutgoingStock: false,
  isOutgoingStockLoadCompleted: false,
  isLoadingTransferStock: false,
  isTransferStockLoadCompleted: false,
  isLoadingStockInventory: false,
  isStockInventoryLoadCompleted: false,
  isLoadingStockMonitoring: false,
  isStockMonitoringLoadCompleted: false,
  isLoadingUnhandledSalesStock: false,
  isUnhandledSalesStockLoadCompleted: false,
};

/**
 * The reducer function that is called in each action dispatch against the state.
 * @param state The current state.
 * @param action The action that will affect the state.
 */
export function storesReportsReducer(
  state: StoresReportsState = initialState,
  action: StoresReportsActions
): StoresReportsState {
  switch (action.type) {
    //#region GET_PRODUCTS_LIST_REPORT

    case StoresReportsActionType.GET_PRODUCTS_LIST_REPORT: {
      return {
        ...state,
        productsList: null,
        isLoadingProductsList: true,
        isProductsListLoadCompleted: false,
        error: null,
      };
    }

    case StoresReportsActionType.GET_PRODUCTS_LIST_REPORT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isLoadingProductsList: false,
        isProductsListLoadCompleted: false,
      };
    }

    case StoresReportsActionType.GET_PRODUCTS_LIST_REPORT_RECEIVED: {
      return {
        ...state,
        error: null,
        isLoadingProductsList: false,
        isProductsListLoadCompleted: true,
      };
    }

    case StoresReportsActionType.GET_PRODUCTS_LIST_REPORT_SUCCESS: {
      return {
        ...state,
        productsList: action.payload.data,
        error: null,
        isLoadingProductsList: false,
        isProductsListLoadCompleted: true,
      };
    }

    case StoresReportsActionType.PRODUCTS_LIST_REPORT_VIEW: {
      return {
        ...state,
        productsList: action.payload,
        error: null,
        isLoadingProductsList: false,
        isProductsListLoadCompleted: true,
      };
    }

    //#endregion PRODUCTS_LIST

    //#region GET_PRODUCT_MOVEMENT_REPORT

    case StoresReportsActionType.GET_PRODUCT_MOVEMENT_REPORT: {
      return {
        ...state,
        productMovement: null,
        isLoadingProductMovement: true,
        isProductMovementLoadCompleted: false,
        error: null,
      };
    }

    case StoresReportsActionType.GET_PRODUCT_MOVEMENT_REPORT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isLoadingProductMovement: false,
        isProductMovementLoadCompleted: false,
      };
    }

    case StoresReportsActionType.GET_PRODUCT_MOVEMENT_REPORT_RECEIVED: {
      return {
        ...state,
        error: null,
        isLoadingProductMovement: false,
        isProductMovementLoadCompleted: true,
      };
    }

    case StoresReportsActionType.GET_PRODUCT_MOVEMENT_REPORT_SUCCESS: {
      return {
        ...state,
        productMovement: action.payload.data,
        error: null,
        isLoadingProductMovement: false,
        isProductMovementLoadCompleted: true,
      };
    }

    case StoresReportsActionType.PRODUCT_MOVEMENT_REPORT_VIEW: {
      return {
        ...state,
        productMovement: action.payload,
        error: null,
        isLoadingProductMovement: false,
        isProductMovementLoadCompleted: true,
      };
    }

    //#endregion PRODUCT_MOVEMENT

    //#region GET_INCOMING_STOCK_REPORT

    case StoresReportsActionType.GET_INCOMING_STOCK_REPORT: {
      return {
        ...state,
        incomingStock: null,
        isLoadingIncomingStock: true,
        isIncomingStockLoadCompleted: false,
        error: null,
      };
    }

    case StoresReportsActionType.GET_INCOMING_STOCK_REPORT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isLoadingIncomingStock: false,
        isIncomingStockLoadCompleted: false,
      };
    }

    case StoresReportsActionType.GET_INCOMING_STOCK_REPORT_RECEIVED: {
      return {
        ...state,
        error: null,
        isLoadingIncomingStock: false,
        isIncomingStockLoadCompleted: true,
      };
    }

    case StoresReportsActionType.GET_INCOMING_STOCK_REPORT_SUCCESS: {
      return {
        ...state,
        incomingStock: action.payload.data,
        error: null,
        isLoadingIncomingStock: false,
        isIncomingStockLoadCompleted: true,
      };
    }

    case StoresReportsActionType.INCOMING_STOCK_REPORT_VIEW: {
      return {
        ...state,
        incomingStock: action.payload,
        error: null,
        isLoadingIncomingStock: false,
        isIncomingStockLoadCompleted: true,
      };
    }

    //#endregion INCOMING_STOCK

    //#region GET_OUTGOING_STOCK_REPORT

    case StoresReportsActionType.GET_OUTGOING_STOCK_REPORT: {
      return {
        ...state,
        outgoingStock: null,
        isLoadingOutgoingStock: true,
        isOutgoingStockLoadCompleted: false,
        error: null,
      };
    }

    case StoresReportsActionType.GET_OUTGOING_STOCK_REPORT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isLoadingOutgoingStock: false,
        isOutgoingStockLoadCompleted: false,
      };
    }

    case StoresReportsActionType.GET_OUTGOING_STOCK_REPORT_RECEIVED: {
      return {
        ...state,
        error: null,
        isLoadingOutgoingStock: false,
        isOutgoingStockLoadCompleted: true,
      };
    }

    case StoresReportsActionType.GET_OUTGOING_STOCK_REPORT_SUCCESS: {
      return {
        ...state,
        outgoingStock: action.payload.data,
        error: null,
        isLoadingOutgoingStock: false,
        isOutgoingStockLoadCompleted: true,
      };
    }

    case StoresReportsActionType.OUTGOING_STOCK_REPORT_VIEW: {
      return {
        ...state,
        outgoingStock: action.payload,
        error: null,
        isLoadingOutgoingStock: false,
        isOutgoingStockLoadCompleted: true,
      };
    }

    //#endregion OUTGOING_STOCK

    //#region GET_TRANSFER_STOCK_REPORT

    case StoresReportsActionType.GET_TRANSFER_STOCK_REPORT: {
      return {
        ...state,
        transferStock: null,
        isLoadingTransferStock: true,
        isTransferStockLoadCompleted: false,
        error: null,
      };
    }

    case StoresReportsActionType.GET_TRANSFER_STOCK_REPORT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isLoadingTransferStock: false,
        isTransferStockLoadCompleted: false,
      };
    }

    case StoresReportsActionType.GET_TRANSFER_STOCK_REPORT_RECEIVED: {
      return {
        ...state,
        error: null,
        isLoadingTransferStock: false,
        isTransferStockLoadCompleted: true,
      };
    }

    case StoresReportsActionType.GET_TRANSFER_STOCK_REPORT_SUCCESS: {
      return {
        ...state,
        transferStock: action.payload.data,
        error: null,
        isLoadingTransferStock: false,
        isTransferStockLoadCompleted: true,
      };
    }

    case StoresReportsActionType.TRANSFER_STOCK_REPORT_VIEW: {
      return {
        ...state,
        transferStock: action.payload,
        error: null,
        isLoadingTransferStock: false,
        isTransferStockLoadCompleted: true,
      };
    }

    //#endregion TRANSFER_STOCK

    //#region GET_STOCK_INVENTORY_REPORT

    case StoresReportsActionType.GET_STOCK_INVENTORY_REPORT: {
      return {
        ...state,
        stockInventory: null,
        isLoadingStockInventory: true,
        isStockInventoryLoadCompleted: false,
        error: null,
      };
    }

    case StoresReportsActionType.GET_STOCK_INVENTORY_REPORT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isLoadingStockInventory: false,
        isStockInventoryLoadCompleted: false,
      };
    }

    case StoresReportsActionType.GET_STOCK_INVENTORY_REPORT_RECEIVED: {
      return {
        ...state,
        error: null,
        isLoadingStockInventory: false,
        isStockInventoryLoadCompleted: true,
      };
    }

    case StoresReportsActionType.GET_STOCK_INVENTORY_REPORT_SUCCESS: {
      return {
        ...state,
        stockInventory: action.payload.data,
        error: null,
        isLoadingStockInventory: false,
        isStockInventoryLoadCompleted: true,
      };
    }

    case StoresReportsActionType.STOCK_INVENTORY_REPORT_VIEW: {
      return {
        ...state,
        stockInventory: action.payload,
        error: null,
        isLoadingStockInventory: false,
        isStockInventoryLoadCompleted: true,
      };
    }

    //#endregion STOCK_INVENTORY

    //#region GET_STOCK_MONITORING_REPORT

    case StoresReportsActionType.GET_STOCK_MONITORING_REPORT: {
      return {
        ...state,
        stockMonitoring: null,
        isLoadingStockMonitoring: true,
        isStockMonitoringLoadCompleted: false,
        error: null,
      };
    }

    case StoresReportsActionType.GET_STOCK_MONITORING_REPORT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isLoadingStockMonitoring: false,
        isStockMonitoringLoadCompleted: false,
      };
    }

    case StoresReportsActionType.GET_STOCK_MONITORING_REPORT_RECEIVED: {
      return {
        ...state,
        error: null,
        isLoadingStockMonitoring: false,
        isStockMonitoringLoadCompleted: true,
      };
    }

    case StoresReportsActionType.GET_STOCK_MONITORING_REPORT_SUCCESS: {
      return {
        ...state,
        stockMonitoring: action.payload.data,
        error: null,
        isLoadingStockMonitoring: false,
        isStockMonitoringLoadCompleted: true,
      };
    }

    case StoresReportsActionType.STOCK_MONITORING_REPORT_VIEW: {
      return {
        ...state,
        stockMonitoring: action.payload,
        error: null,
        isLoadingStockMonitoring: false,
        isStockMonitoringLoadCompleted: true,
      };
    }

    //#endregion STOCK_MONITORING

    //#region GET_UNHANDLED_SALES_STOCK_REPORT

    case StoresReportsActionType.GET_UNHANDLED_SALES_STOCK_REPORT: {
      return {
        ...state,
        unhandledSalesStock: null,
        isLoadingUnhandledSalesStock: true,
        isUnhandledSalesStockLoadCompleted: false,
        error: null,
      };
    }

    case StoresReportsActionType.GET_UNHANDLED_SALES_STOCK_REPORT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isLoadingUnhandledSalesStock: false,
        isUnhandledSalesStockLoadCompleted: false,
      };
    }

    case StoresReportsActionType.GET_UNHANDLED_SALES_STOCK_REPORT_RECEIVED: {
      return {
        ...state,
        error: null,
        isLoadingUnhandledSalesStock: false,
        isUnhandledSalesStockLoadCompleted: true,
      };
    }

    case StoresReportsActionType.GET_UNHANDLED_SALES_STOCK_REPORT_SUCCESS: {
      return {
        ...state,
        unhandledSalesStock: action.payload.data,
        error: null,
        isLoadingUnhandledSalesStock: false,
        isUnhandledSalesStockLoadCompleted: true,
      };
    }

    case StoresReportsActionType.UNHANDLED_SALES_STOCK_REPORT_VIEW: {
      return {
        ...state,
        unhandledSalesStock: action.payload,
        error: null,
        isLoadingUnhandledSalesStock: false,
        isUnhandledSalesStockLoadCompleted: true,
      };
    }

    //#endregion UNHANDLED_SALES_STOCK

    default:
      return state;
  }
}
