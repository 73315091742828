import { Component, Input } from '@angular/core';

import { BankAccountLog } from 'finances/models';

@Component({
  selector: 'app-journal-bank-account-log-details',
  templateUrl: './journal-bank-account-log-details.component.html',
  styles: [],
})
export class JournalBankAccountLogDetailsComponent {
  /**
   * Sets the current journal bank account log details.
   */
  @Input() bankAccountLog: BankAccountLog;
}
