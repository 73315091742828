import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';

import { Observable } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { tap } from 'rxjs/operators';

import { PageInfo, PaginationInfo } from 'shared';
import * as fromFinancesStore from 'finances/store';
import { CostCenter } from 'finances/models';
import { Claims } from 'security/models';


@Component({
  selector: 'app-cost-center-list',
  templateUrl: './cost-center-list.component.html',
  styles: [],
})
export class CostCenterListComponent implements OnInit {
  /**
   * Gets or sets the information about the current page.
   */
  pageInfo: PageInfo = {
    title: 'FINANCES.COST_CENTERS.COST_CENTER_DATA.COST_CENTERS',
    icon: 'icon-vector',
  };

  /**
   * The system supported user claims.
   */
  Claims = Claims;

  /**
   * The list of cost center.
   */
  costCenter$: Observable<CostCenter[]>;

  /**
   * Indicates whether there is a delete-payroll-element process is running or not.
   */
  isDeleting$: Observable<boolean>;

  /**
   * The pagination info.
   */
  paginationInfo$: Observable<PaginationInfo>;

  /**
   * The search form.
   */
  searchForm: FormGroup;

  /**
   * Gets or sets the currently selected cost center for delete.
   */
  selectedCostCenter: CostCenter;

  /**
   * @param modalService The modal service.
   * @param financesStore$ The finances store.
   */
  constructor(private modalService: NgbModal, private financesStore$: Store<fromFinancesStore.FinancesState>) {}

  ngOnInit(): void {
    this.init();
  }

  /**
   * Initialize component data.
   */
  init() {
    this.initForm();

    /**
     * Load data.
     */
     this.isDeleting$ = this.financesStore$.pipe(select(fromFinancesStore.getSelectedCostCenterDeleting));

     let isManualSearchTriggeredBeforeForCostCenter = false;
    this.costCenter$ = this.financesStore$.pipe(
      select(fromFinancesStore.getCostCenters),
      tap((costCenter)=>{
        if (!isManualSearchTriggeredBeforeForCostCenter && !costCenter.length) {
          isManualSearchTriggeredBeforeForCostCenter = true;
          this.search();
        }
      })
    );

    this.paginationInfo$ = this.financesStore$.pipe(select(fromFinancesStore.getCostCentersPaginationInfo));
  }

  /**
   * Initialize form and add validators.
   */
  initForm() {
    this.searchForm = new FormGroup({
      description: new FormControl(''),
      page: new FormControl(1),
    });
  }

  /**
   * Handles search parameters change.
   */
  search(event?: KeyboardEvent) {
    if (event && event.key === 'Enter') {
      event.preventDefault();
      return;
    }

    this.applyFiltersAndSearch();
  }

  /**
   * Handles pagination page-changed event.
   * @param page The current selected page number.
   */
  pageChanged(page: number) {
    /** Update pagination page. */
    this.searchForm.patchValue({ page });

    this.applyFiltersAndSearch(page);
  }

  /**
   * Enables trackBy feature for *ngFor utility to track elements
   * depending on a comparer to reduce DOM change.
   * If the element is already exist then no HTML will change, only bounded values.
   * @param element The element to determine if it was changed or not.
   */
  trackElements(element: CostCenter) {
    return element ? element.id : undefined;
  }

  applyFiltersAndSearch(page: number = 1) {
    const { description } = this.searchForm.value;
    this.financesStore$.dispatch(
      new fromFinancesStore.SearchCostCenters({
        description,
        page,
      })
    );
  }

  /**
   * Deletes the cost center with the given id.
   * @param costCenterId The id of the cost center to be deleted.
   */
  delete(costCenterId: number) {
    this.financesStore$.dispatch(new fromFinancesStore.DeleteCostCenter(costCenterId));
  }

  /**
   * Opens the modal of the given templateRef.
   * @param modalRef The modal templateRef to be opened.
   */
  openModal(modalRef) {
    this.modalService.open(modalRef);
  }
}
