import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';

import { AppHttpResponse } from 'shared';
import { CreateRegionInput, Region, UpdateRegionInput } from 'stores/models';

/**
 * The region services includes functionality to create, search, findById, update and delete for a region.
 */
@Injectable()
export class RegionsService {
  /**
   * The relative route for the regions.
   *
   * No leading or trailing slashes require.
   */
  private regionsApi = 'stores/regions';

  constructor(private http: HttpClient) {}

  /**
   * Creates a new region from the provided data.
   * @param data The new region data.
   */
  public create(data: CreateRegionInput): Observable<AppHttpResponse<Region>> {
    return this.http.post<AppHttpResponse<Region>>(`${this.regionsApi}`, data);
  }

  /**
   * Searches in the region by fromDate and toDate.
   * @param name of the region.
   * @param page The current pagination page number.
   * @param pageSize The maximum number of region allowed per one pagination page.
   */
  public search(name: string, page: number, pageSize: number): Observable<AppHttpResponse<Region[]>> {
    const params = new HttpParams().set('name', name).set('page', page.toString()).set('pageSize', pageSize.toString());
    return this.http.get<AppHttpResponse<Region[]>>(`${this.regionsApi}`, { params });
  }

  /**
   * Finds the region with the given id.
   * @param id The id of the region.
   */
  public findById(id: number): Observable<AppHttpResponse<Region>> {
    return this.http.get<AppHttpResponse<Region>>(`${this.regionsApi}/${id}`);
  }

  /**
   * Updates an existing region data using the provided data.
   * @param data The updated region data.
   */
  public update(data: UpdateRegionInput): Observable<AppHttpResponse<Region>> {
    return this.http.put<AppHttpResponse<Region>>(`${this.regionsApi}`, data);
  }

  /**
   * Deletes the region by given id.
   * @param id The id of the region.
   */
  public delete(id: number): Observable<AppHttpResponse<Region>> {
    return this.http.delete<AppHttpResponse<Region>>(`${this.regionsApi}/${id}`);
  }
}
