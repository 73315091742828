import { Action } from '@ngrx/store';

import { GovServiceRequestPaymentMethod } from 'lookups/models';
import { AppHttpResponse } from 'shared';

/**
 * The Lookups-Module store gov service request payment methods action types.
 */
export enum GovServiceRequestPaymentMethodActionType {
  GET_ALL_GOV_SERVICE_REQUEST_PAYMENT_METHODS = '[Lookups] [GovServiceRequestPaymentMethod] Get All Gov Service Request Payment Methods',
  GET_ALL_GOV_SERVICE_REQUEST_PAYMENT_METHODS_FAIL = '[Lookups] [GovServiceRequestPaymentMethod] Get All Gov Service Request Payment Methods Fail',
  GET_ALL_GOV_SERVICE_REQUEST_PAYMENT_METHODS_SUCCESS = '[Lookups] [GovServiceRequestPaymentMethod] Get All Gov Service Request Payment Methods Success',
}

/**
 * Represents a store gov service request payment methods action.
 */
export class GetAllGovServiceRequestPaymentMethods implements Action {
  /**
   * The type of the action.
   */
  readonly type = GovServiceRequestPaymentMethodActionType.GET_ALL_GOV_SERVICE_REQUEST_PAYMENT_METHODS;

  /**
   * Fires a new gov service request payment methods action.
   */
  constructor() {}
}

/**
 * Represents a store gov service request payment methods fail action.
 */
export class GetAllGovServiceRequestPaymentMethodsFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = GovServiceRequestPaymentMethodActionType.GET_ALL_GOV_SERVICE_REQUEST_PAYMENT_METHODS_FAIL;

  /**
   * Fires a new gov service request payment methods fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store gov service request payment methods success action.
 */
export class GetAllGovServiceRequestPaymentMethodsSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = GovServiceRequestPaymentMethodActionType.GET_ALL_GOV_SERVICE_REQUEST_PAYMENT_METHODS_SUCCESS;

  /**
   * Fires a gov service request payment methods success action.
   * @param payload An object contains the list of gov service request payment methods.
   */
  constructor(public payload: AppHttpResponse<GovServiceRequestPaymentMethod[]>) {}
}

/**
 * Lookups-module gov service request payment methods action types.
 */
export type GovServiceRequestPaymentMethodActions =
  | GetAllGovServiceRequestPaymentMethods
  | GetAllGovServiceRequestPaymentMethodsFail
  | GetAllGovServiceRequestPaymentMethodsSuccess;
