import { SystemAccountsActions, SystemAccountsActionType } from 'finances/store/actions';
import { SystemAccountsState } from 'finances/store/states';

/**
 * The initial state from which the state starts.
 */
const initialState: SystemAccountsState = {
  error: null,
  accountReceivableAccount: null,
  revenueAccount: null,
  taxAccount: null,
  materialAccount: null,
  inventoryClearingAccount: null,
  accountsPayablesClearingAccount: null,
  pettyCashClearingAccount: null,
  liabilityAccount: null,
  bankAccount: null,
  cashAccount: null,
  chequesUnderCollectionAccount: null,
  paymentsUnderCollectionAccount: null,
  pointOfSaleCashAccount: null,
  COGSAccount: null,
  profitLossAccount: null,
  pendingChequeAccount: null,
  paymentsUnderPaymentAccount: null,
  advanceExpensesPettyCashAccount: null,
  ownersCurrentAccount: null,
  assetBrokerageAccount: null,
  desktopDevicesAccount: null,
  machinesAndEquipmentsAccount: null,
  improvementsToRentedBuildingsAccount: null,
  salariesPayableAccount: null,
  isFindingAccountReceivable: false,
  isFindAccountReceivableCompleted: false,
  isFindingRevenue: false,
  isFindRevenueCompleted: false,
  isFindingTax: false,
  isFindTaxCompleted: false,
  isFindingMaterialAccount: false,
  isFindMaterialAccountCompleted: false,
  isFindingInventoryClearingAccount: false,
  isFindInventoryClearingAccountCompleted: false,
  isFindingAccountsPayablesClearingAccount: false,
  isFindAccountsPayablesClearingAccountCompleted: false,
  isFindingPettyCashClearingAccount: false,
  isFindPettyCashClearingAccountCompleted: false,
  isFindingLiabilityAccount: false,
  isFindLiabilityAccountCompleted: false,
  isFindingBankAccount: false,
  isFindBankAccountCompleted: false,
  isFindingCashAccount: false,
  isFindCashAccountCompleted: false,
  isFindingChequesUnderCollectionAccount: false,
  isFindChequesUnderCollectionAccountCompleted: false,
  isFindingPaymentsUnderCollectionAccount: false,
  isFindPaymentsUnderCollectionAccountCompleted: false,
  isFindingPointOfSaleCashAccount: false,
  isFindPointOfSaleCashAccountCompleted: false,
  isFindingCOGSAccount: false,
  isFindCOGSAccountCompleted: false,
  isFindingProfitLossAccount: false,
  isFindProfitLossAccountCompleted: false,
  isFindingPendingChequeAccount: false,
  isFindPendingChequeAccountCompleted: false,
  isFindingPaymentsUnderPaymentAccount: false,
  isFindPaymentsUnderPaymentAccountCompleted: false,
  isFindingAdvanceExpensesPettyCashAccount: false,
  isFindAdvanceExpensesPettyCashAccountCompleted: false,
  isFindingOwnersCurrentAccount: false,
  isFindOwnersCurrentAccountCompleted: false,
  isFindingImprovementsToRentedBuildingsAccount: false,
  isFindImprovementsToRentedBuildingsAccountCompleted: false,
  isFindingMachinesAndEquipmentsAccount: false,
  isFindMachinesAndEquipmentsAccountCompleted: false,
  isFindingDesktopDevicesAccount: false,
  isFindDesktopDevicesAccountCompleted: false,
  isFindingAssetBrokerageAccount: false,
  isFindAssetBrokerageAccountCompleted: false,
  isFindingSalariesPayableAccount: false,
  isFindSalariesPayableAccountCompleted: false,

  /* &&&&&&&&&&&&&&&&&&&&&&&&&& NEW SYSTEMS ACCOUNTS &&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&& */
  cashInHandAccount: null,
  isFindingCashInHandAccount: false,
  isFindCashInHandAccountCompleted: false,
  cashInBanksAccount: null,
  isFindingCashInBanksAccount: false,
  isFindCashInBanksAccountCompleted: false,
  checksUnderCollectionAccount: null,
  isFindingChecksUnderCollectionAccount: false,
  isFindChecksUnderCollectionAccountCompleted: false,
  cashInvestmentsLessThan90DaysAccount: null,
  isFindingCashInvestmentsLessThan90DaysAccount: false,
  isFindCashInvestmentsLessThan90DaysAccountCompleted: false,
  restrictedCashInBanksAccount: null,
  isFindingRestrictedCashInBanksAccount: false,
  isFindRestrictedCashInBanksAccountCompleted: false,
  shortTermDepositsAccount: null,
  isFindingShortTermDepositsAccount: false,
  isFindShortTermDepositsAccountCompleted: false,
  cashInvestmentsForTradingAccount: null,
  isFindingCashInvestmentsForTradingAccount: false,
  isFindCashInvestmentsForTradingAccountCompleted: false,
  tradeDebtorsAccount: null,
  isFindingTradeDebtorsAccount: false,
  isFindTradeDebtorsAccountCompleted: false,
  allowanceForDoubtfulDebtsAccount: null,
  isFindingAllowanceForDoubtfulDebtsAccount: false,
  isFindAllowanceForDoubtfulDebtsAccountCompleted: false,
  relatedPartiesAccount: null,
  isFindingRelatedPartiesAccount: false,
  isFindRelatedPartiesAccountCompleted: false,
  advancesOfStaffAccount: null,
  isFindingAdvancesOfStaffAccount: false,
  isFindAdvancesOfStaffAccountCompleted: false,
  lettersOfGuaranteeAccount: null,
  isFindingLettersOfGuaranteeAccount: false,
  isFindLettersOfGuaranteeAccountCompleted: false,
  documentaryCreditsAccount: null,
  isFindingDocumentaryCreditsAccount: false,
  isFindDocumentaryCreditsAccountCompleted: false,
  pettyCashAccount: null,
  isFindingPettyCashAccount: false,
  isFindPettyCashAccountCompleted: false,
  wellExecutedDetentionAccount: null,
  isFindingWellExecutedDetentionAccount: false,
  isFindWellExecutedDetentionAccountCompleted: false,
  accruedRevenueAccount: null,
  isFindingAccruedRevenueAccount: false,
  isFindAccruedRevenueAccountCompleted: false,
  workInProgressUnbilledRevenueAccount: null,
  isFindingWorkInProgressUnbilledRevenueAccount: false,
  isFindWorkInProgressUnbilledRevenueAccountCompleted: false,
  valueAddedTaxDebitAccount: null,
  isFindingValueAddedTaxDebitAccount: false,
  isFindValueAddedTaxDebitAccountCompleted: false,
  miscellaneousDebtorsAccount: null,
  isFindingMiscellaneousDebtorsAccount: false,
  isFindMiscellaneousDebtorsAccountCompleted: false,
  prepaidExpensesAccount: null,
  isFindingPrepaidExpensesAccount: false,
  isFindPrepaidExpensesAccountCompleted: false,
  advancePaymentsToVendorsAndContractorsAccount: null,
  isFindingAdvancePaymentsToVendorsAndContractorsAccount: false,
  isFindAdvancePaymentsToVendorsAndContractorsAccountCompleted: false,
  financingChargesForLeaseContractsAccount: null,
  isFindingFinancingChargesForLeaseContractsAccount: false,
  isFindFinancingChargesForLeaseContractsAccountCompleted: false,
  financingChargesForBankLoansAndCreditFacilitiesAccount: null,
  isFindingFinancingChargesForBankLoansAndCreditFacilitiesAccount: false,
  isFindFinancingChargesForBankLoansAndCreditFacilitiesAccountCompleted: false,
  goodsAndMaterialsStockAccount: null,
  isFindingGoodsAndMaterialsStockAccount: false,
  isFindGoodsAndMaterialsStockAccountCompleted: false,
  stockInProcessAccount: null,
  isFindingStockInProcessAccount: false,
  isFindStockInProcessAccountCompleted: false,
  completedStockAccount: null,
  isFindingCompletedStockAccount: false,
  isFindCompletedStockAccountCompleted: false,
  provisionForDamagedOrObsoleteInventoryAccount: null,
  isFindingProvisionForDamagedOrObsoleteInventoryAccount: false,
  isFindProvisionForDamagedOrObsoleteInventoryAccountCompleted: false,
  investmentsAvailableForSaleOrHeldToMaturityAccount: null,
  isFindingInvestmentsAvailableForSaleOrHeldToMaturityAccount: false,
  isFindInvestmentsAvailableForSaleOrHeldToMaturityAccountCompleted: false,
  longTermDepositsAccount: null,
  isFindingLongTermDepositsAccount: false,
  isFindLongTermDepositsAccountCompleted: false,
  financeChargesForLeaseContractsNonCurrentAccount: null,
  isFindingFinanceChargesForLeaseContractsNonCurrentAccount: false,
  isFindFinanceChargesForLeaseContractsNonCurrentAccountCompleted: false,
  financingChargesForLoansAndCreditFacilitiesNonCurrentAccount: null,
  isFindingFinancingChargesForLoansAndCreditFacilitiesNonCurrentAccount: false,
  isFindFinancingChargesForLoansAndCreditFacilitiesNonCurrentAccountCompleted: false,
  projectsUnderImplementationAccount: null,
  isFindingProjectsUnderImplementationAccount: false,
  isFindProjectsUnderImplementationAccountCompleted: false,
  landsAccount: null,
  isFindingLandsAccount: false,
  isFindLandsAccountCompleted: false,
  buildingsAccount: null,
  isFindingBuildingsAccount: false,
  isFindBuildingsAccountCompleted: false,
  furnitureAccount: null,
  isFindingFurnitureAccount: false,
  isFindFurnitureAccountCompleted: false,
  devicesAccount: null,
  isFindingDevicesAccount: false,
  isFindDevicesAccountCompleted: false,
  computersAccount: null,
  isFindingComputersAccount: false,
  isFindComputersAccountCompleted: false,
  equipmentsAccount: null,
  isFindingEquipmentsAccount: false,
  isFindEquipmentsAccountCompleted: false,
  carsAccount: null,
  isFindingCarsAccount: false,
  isFindCarsAccountCompleted: false,
  assetsUnderFinanceLeasesAccount: null,
  isFindingAssetsUnderFinanceLeasesAccount: false,
  isFindAssetsUnderFinanceLeasesAccountCompleted: false,
  buildingConsumptionComplexAccount: null,
  isFindingBuildingConsumptionComplexAccount: false,
  isFindBuildingConsumptionComplexAccountCompleted: false,
  furnitureConsumptionComplexAccount: null,
  isFindingFurnitureConsumptionComplexAccount: false,
  isFindFurnitureConsumptionComplexAccountCompleted: false,
  devicesConsumptionComplexAccount: null,
  isFindingDevicesConsumptionComplexAccount: false,
  isFindDevicesConsumptionComplexAccountCompleted: false,
  computersConsumptionComplexAccount: null,
  isFindingComputersConsumptionComplexAccount: false,
  isFindComputersConsumptionComplexAccountCompleted: false,
  equipmentsConsumptionComplexAccount: null,
  isFindingEquipmentsConsumptionComplexAccount: false,
  isFindEquipmentsConsumptionComplexAccountCompleted: false,
  carsConsumptionComplexAccount: null,
  isFindingCarsConsumptionComplexAccount: false,
  isFindCarsConsumptionComplexAccountCompleted: false,
  pooledDepreciationOfAssetsUnderFinanceLeaseContractsAccount: null,
  isFindingPooledDepreciationOfAssetsUnderFinanceLeaseContractsAccount: false,
  isFindPooledDepreciationOfAssetsUnderFinanceLeaseContractsAccountCompleted: false,
  fameAccount: null,
  isFindingFameAccount: false,
  isFindFameAccountCompleted: false,
  softwareAndSystemsAccount: null,
  isFindingSoftwareAndSystemsAccount: false,
  isFindSoftwareAndSystemsAccountCompleted: false,
  patentsAndTrademarksAccount: null,
  isFindingPatentsAndTrademarksAccount: false,
  isFindPatentsAndTrademarksAccountCompleted: false,
  fameExtinguishingComplexAccount: null,
  isFindingFameExtinguishingComplexAccount: false,
  isFindFameExtinguishingComplexAccountCompleted: false,
  softwareAndHardwareExtinguishingComplexAccount: null,
  isFindingSoftwareAndHardwareExtinguishingComplexAccount: false,
  isFindSoftwareAndHardwareExtinguishingComplexAccountCompleted: false,
  patentAndTrademarkExtinguishingComplexAccount: null,
  isFindingPatentAndTrademarkExtinguishingComplexAccount: false,
  isFindPatentAndTrademarkExtinguishingComplexAccountCompleted: false,
  realEstateInvestmentLandsAccount: null,
  isFindingRealEstateInvestmentLandsAccount: false,
  isFindRealEstateInvestmentLandsAccountCompleted: false,
  realEstateInvestmentBuildingsAccount: null,
  isFindingRealEstateInvestmentBuildingsAccount: false,
  isFindRealEstateInvestmentBuildingsAccountCompleted: false,
  realEstateInvestmentBuildingConsumptionComplexAccount: null,
  isFindingRealEstateInvestmentBuildingConsumptionComplexAccount: false,
  isFindRealEstateInvestmentBuildingConsumptionComplexAccountCompleted: false,
  investmentsInSubsidiariesAccount: null,
  isFindingInvestmentsInSubsidiariesAccount: false,
  isFindInvestmentsInSubsidiariesAccountCompleted: false,
  investmentsInAssociatesAccount: null,
  isFindingInvestmentsInAssociatesAccount: false,
  isFindInvestmentsInAssociatesAccountCompleted: false,
  investmentsInJointVenturesAccount: null,
  isFindingInvestmentsInJointVenturesAccount: false,
  isFindInvestmentsInJointVenturesAccountCompleted: false,
  loanInstallmentsTheCurrentPartAccount: null,
  isFindingLoanInstallmentsTheCurrentPartAccount: false,
  isFindLoanInstallmentsTheCurrentPartAccountCompleted: false,
  currentContractsForRentTheCurrentPartAccount: null,
  isFindingCurrentContractsForRentTheCurrentPartAccount: false,
  isFindCurrentContractsForRentTheCurrentPartAccountCompleted: false,
  tradeCreditorsAccount: null,
  isFindingTradeCreditorsAccount: false,
  isFindTradeCreditorsAccountCompleted: false,
  vendorsBrokerAccount: null,
  isFindingVendorsBrokerAccount: false,
  isFindVendorsBrokerAccountCompleted: false,
  creditFacilitiesOverdraftAccount: null,
  isFindingCreditFacilitiesOverdraftAccount: false,
  isFindCreditFacilitiesOverdraftAccountCompleted: false,
  creditorsWithholdingBalancesAccount: null,
  isFindingCreditorsWithholdingBalancesAccount: false,
  isFindCreditorsWithholdingBalancesAccountCompleted: false,
  payableExpensesAccount: null,
  isFindingPayableExpensesAccount: false,
  isFindPayableExpensesAccountCompleted: false,
  employeesEntitlementsSalariesAccount: null,
  isFindingEmployeesEntitlementsSalariesAccount: false,
  isFindEmployeesEntitlementsSalariesAccountCompleted: false,
  employeesEntitlementsBenefitsAccount: null,
  isFindingEmployeesEntitlementsBenefitsAccount: false,
  isFindEmployeesEntitlementsBenefitsAccountCompleted: false,
  employeesEntitlementsTravelingTicketsAccount: null,
  isFindingEmployeesEntitlementsTravelingTicketsAccount: false,
  isFindEmployeesEntitlementsTravelingTicketsAccountCompleted: false,
  advanceRevenueAccount: null,
  isFindingAdvanceRevenueAccount: false,
  isFindAdvanceRevenueAccountCompleted: false,
  valueAddedTaxCreditAccount: null,
  isFindingValueAddedTaxCreditAccount: false,
  isFindValueAddedTaxCreditAccountCompleted: false,
  receivablesStaffAccount: null,
  isFindingReceivablesStaffAccount: false,
  isFindReceivablesStaffAccountCompleted: false,
  otherCurrentLiabilitiesRelatedPartiesAccount: null,
  isFindingOtherCurrentLiabilitiesRelatedPartiesAccount: false,
  isFindOtherCurrentLiabilitiesRelatedPartiesAccountCompleted: false,
  miscellaneousCreditorsAccount: null,
  isFindingMiscellaneousCreditorsAccount: false,
  isFindMiscellaneousCreditorsAccountCompleted: false,
  dividendPayableAccount: null,
  isFindingDividendPayableAccount: false,
  isFindDividendPayableAccountCompleted: false,
  zakatProvisionAccount: null,
  isFindingZakatProvisionAccount: false,
  isFindZakatProvisionAccountCompleted: false,
  incomeTaxProvisionAccount: null,
  isFindingIncomeTaxProvisionAccount: false,
  isFindIncomeTaxProvisionAccountCompleted: false,
  provisionForWithholdingTaxAccount: null,
  isFindingProvisionForWithholdingTaxAccount: false,
  isFindProvisionForWithholdingTaxAccountCompleted: false,
  bankLoansAccount: null,
  isFindingBankLoansAccount: false,
  isFindBankLoansAccountCompleted: false,
  leaseLiabilitiesNonCurrentPortionAccount: null,
  isFindingLeaseLiabilitiesNonCurrentPortionAccount: false,
  isFindLeaseLiabilitiesNonCurrentPortionAccountCompleted: false,
  provisionForEndOfSeverancePayAccount: null,
  isFindingProvisionForEndOfSeverancePayAccount: false,
  isFindProvisionForEndOfSeverancePayAccountCompleted: false,
  capitalAccount: null,
  isFindingCapitalAccount: false,
  isFindCapitalAccountCompleted: false,
  regularReserveAccount: null,
  isFindingRegularReserveAccount: false,
  isFindRegularReserveAccountCompleted: false,
  generalReserveAccount: null,
  isFindingGeneralReserveAccount: false,
  isFindGeneralReserveAccountCompleted: false,
  retainedEarningsAccount: null,
  isFindingRetainedEarningsAccount: false,
  isFindRetainedEarningsAccountCompleted: false,
  profitAndLossBrokerAccount: null,
  isFindingProfitAndLossBrokerAccount: false,
  isFindProfitAndLossBrokerAccountCompleted: false,
  additionalCapitalAccount: null,
  isFindingAdditionalCapitalAccount: false,
  isFindAdditionalCapitalAccountCompleted: false,
  mainActivitiesSalesRevenueAccount: null,
  isFindingMainActivitiesSalesRevenueAccount: false,
  isFindMainActivitiesSalesRevenueAccountCompleted: false,
  gainLossOnDisposalOfPropertyAccount: null,
  isFindingGainLossOnDisposalOfPropertyAccount: false,
  isFindGainLossOnDisposalOfPropertyAccountCompleted: false,
  gainLossOnInvestmentValuationAccount: null,
  isFindingGainLossOnInvestmentValuationAccount: false,
  isFindGainLossOnInvestmentValuationAccountCompleted: false,
  supportRevenueAccount: null,
  isFindingSupportRevenueAccount: false,
  isFindSupportRevenueAccountCompleted: false,
  otherMiscellaneousIncomeAccount: null,
  isFindingOtherMiscellaneousIncomeAccount: false,
  isFindOtherMiscellaneousIncomeAccountCompleted: false,
  usedOfMaterialAccount: null,
  isFindingUsedOfMaterialAccount: false,
  isFindUsedOfMaterialAccountCompleted: false,
  nonInventoryMaterialCostsAccount: null,
  isFindingNonInventoryMaterialCostsAccount: false,
  isFindNonInventoryMaterialCostsAccountCompleted: false,
  rentAccount: null,
  isFindingRentAccount: false,
  isFindRentAccountCompleted: false,
  localLaborAccount: null,
  isFindingLocalLaborAccount: false,
  isFindLocalLaborAccountCompleted: false,
  salariesAndWagesEmployeeProtectionAccount: null,
  isFindingSalariesAndWagesEmployeeProtectionAccount: false,
  isFindSalariesAndWagesEmployeeProtectionAccountCompleted: false,
  benefitToEmployeesAllCostsExceptWageProtectionElementsAccount: null,
  isFindingBenefitToEmployeesAllCostsExceptWageProtectionElementsAccount: false,
  isFindBenefitToEmployeesAllCostsExceptWageProtectionElementsAccountCompleted: false,
  workMissionsAndOvertimeAccount: null,
  isFindingWorkMissionsAndOvertimeAccount: false,
  isFindWorkMissionsAndOvertimeAccountCompleted: false,
  repairAndMaintenanceAccount: null,
  isFindingRepairAndMaintenanceAccount: false,
  isFindRepairAndMaintenanceAccountCompleted: false,
  governmentFeesAndSubscriptionsAccount: null,
  isFindingGovernmentFeesAndSubscriptionsAccount: false,
  isFindGovernmentFeesAndSubscriptionsAccountCompleted: false,
  theCostOfPublicServicesAccount: null,
  isFindingTheCostOfPublicServicesAccount: false,
  isFindTheCostOfPublicServicesAccountCompleted: false,
  hospitalityAndCleanlinessAccount: null,
  officeConsumablesAccount: null,
  isFindingHospitalityAndCleanlinessAccount: false,
  isFindHospitalityAndCleanlinessAccountCompleted: false,
  isFindingOfficeConsumablesAccount: false,
  isFindOfficeConsumablesAccountCompleted: false,
  financeChargesLoansAndBankFacilitiesAccount: null,
  isFindingFinanceChargesLoansAndBankFacilitiesAccount: false,
  isFindFinanceChargesLoansAndBankFacilitiesAccountCompleted: false,
  financeChargesLeaseContractsAccount: null,
  isFindingFinanceChargesLeaseContractsAccount: false,
  isFindFinanceChargesLeaseContractsAccountCompleted: false,
  commissionsAccount: null,
  isFindingCommissionsAccount: false,
  isFindCommissionsAccountCompleted: false,
  bankExpensesAccount: null,
  isFindingBankExpensesAccount: false,
  isFindBankExpensesAccountCompleted: false,
  costOfRevenueAndGeneralExpensesOthersAccount: null,
  isFindingCostOfRevenueAndGeneralExpensesOthersAccount: false,
  isFindCostOfRevenueAndGeneralExpensesOthersAccountCompleted: false,
  buildingDepreciationExpressAccount: null,
  isFindingBuildingDepreciationExpressAccount: false,
  isFindBuildingDepreciationExpressAccountCompleted: false,
  furnitureDepreciationExpressAccount: null,
  isFindingFurnitureDepreciationExpressAccount: false,
  isFindFurnitureDepreciationExpressAccountCompleted: false,
  devicesDepreciationExpressAccount: null,
  isFindingDevicesDepreciationExpressAccount: false,
  isFindDevicesDepreciationExpressAccountCompleted: false,
  computersDepreciationExpressAccount: null,
  isFindingComputersDepreciationExpressAccount: false,
  isFindComputersDepreciationExpressAccountCompleted: false,
  equipmentsDepreciationExpressAccount: null,
  isFindingEquipmentsDepreciationExpressAccount: false,
  isFindEquipmentsDepreciationExpressAccountCompleted: false,
  carsDepreciationExpressAccount: null,
  isFindingCarsDepreciationExpressAccount: false,
  isFindCarsDepreciationExpressAccountCompleted: false,
  assetsUnderFinanceLeasesDepreciationExpensesAccount: null,
  isFindingAssetsUnderFinanceLeasesDepreciationExpensesAccount: false,
  isFindAssetsUnderFinanceLeasesDepreciationExpensesAccountCompleted: false,
  fameDepreciationExpensesAccount: null,
  isFindingFameDepreciationExpensesAccount: false,
  isFindFameDepreciationExpensesAccountCompleted: false,
  softwareAndSystemsDepreciationExpensesAccount: null,
  isFindingSoftwareAndSystemsDepreciationExpensesAccount: false,
  isFindSoftwareAndSystemsDepreciationExpensesAccountCompleted: false,
  patentsAndTrademarksDepreciationExpensesAccount: null,
  isFindingPatentsAndTrademarksDepreciationExpensesAccount: false,
  isFindPatentsAndTrademarksDepreciationExpensesAccountCompleted: false,
  realEstateInvestmentBuildingsDepreciationExpensesAccount: null,
  isFindingRealEstateInvestmentBuildingsDepreciationExpensesAccount: false,
  isFindRealEstateInvestmentBuildingsDepreciationExpensesAccountCompleted: false,
  assetsBrokerAccount: null,
  isFindingAssetsBrokerAccount: false,
  isFindAssetsBrokerAccountCompleted: false,
  currencyRoundingDifferenceAccount: null,
  isFindingCurrencyRoundingDifferenceAccount: false,
  isFindCurrencyRoundingDifferenceAccountCompleted: false,
  gosiAccrualAccount: null,
  isFindingGosiAccrualAccount: false,
  isFindGosiAccrualAccountCompleted: false,
  gosiExpensesAccount: null,
  isFindingGosiExpensesAccount: false,
  isFindGosiExpensesAccountCompleted: false,
};

/**
 * The reducer function that is called in each action dispatch against the state.
 * @param state The current state.
 * @param action The action that will affect the state.
 */
export function systemAccountsReducer(
  state: SystemAccountsState = initialState,
  action: SystemAccountsActions
): SystemAccountsState {
  switch (action.type) {
    //#region FIND_ACCOUNT_RECEIVABLE

    case SystemAccountsActionType.FIND_ACCOUNT_RECEIVABLE: {
      return {
        ...state,
        accountReceivableAccount: null,
        error: null,
        isFindingAccountReceivable: true,
        isFindAccountReceivableCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_ACCOUNT_RECEIVABLE_FAIL: {
      return {
        ...state,
        error: action.payload,
        isFindingAccountReceivable: false,
        isFindAccountReceivableCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_ACCOUNT_RECEIVABLE_SUCCESS: {
      return {
        ...state,
        accountReceivableAccount: action.payload.data,
        error: null,
        isFindingAccountReceivable: false,
        isFindAccountReceivableCompleted: true,
      };
    }

    //#endregion FIND_ACCOUNT_RECEIVABLE

    //#region FIND_REVENUE

    case SystemAccountsActionType.FIND_REVENUE: {
      return {
        ...state,
        revenueAccount: null,
        error: null,
        isFindingRevenue: true,
        isFindRevenueCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_REVENUE_FAIL: {
      return {
        ...state,
        error: action.payload,
        isFindingRevenue: false,
        isFindRevenueCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_REVENUE_SUCCESS: {
      return {
        ...state,
        revenueAccount: action.payload.data,
        error: null,
        isFindingRevenue: false,
        isFindRevenueCompleted: true,
      };
    }

    //#endregion FIND_REVENUE

    //#region FIND_TAX

    case SystemAccountsActionType.FIND_TAX: {
      return {
        ...state,
        taxAccount: null,
        error: null,
        isFindingTax: true,
        isFindTaxCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_TAX_FAIL: {
      return {
        ...state,
        error: action.payload,
        isFindingTax: false,
        isFindTaxCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_TAX_SUCCESS: {
      return {
        ...state,
        taxAccount: action.payload.data,
        error: null,
        isFindingTax: false,
        isFindTaxCompleted: true,
      };
    }

    //#endregion FIND_TAX

    //#region FIND_MATERIAL

    case SystemAccountsActionType.FIND_MATERIAL: {
      return {
        ...state,
        materialAccount: null,
        error: null,
        isFindingMaterialAccount: true,
        isFindMaterialAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_MATERIAL_FAIL: {
      return {
        ...state,
        error: action.payload,
        isFindingMaterialAccount: false,
        isFindMaterialAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_MATERIAL_SUCCESS: {
      return {
        ...state,
        materialAccount: action.payload.data,
        error: null,
        isFindingMaterialAccount: false,
        isFindMaterialAccountCompleted: true,
      };
    }

    //#endregion FIND_MATERIAL

    //#region FIND_INVENTORY_CLEARING

    case SystemAccountsActionType.FIND_INVENTORY_CLEARING: {
      return {
        ...state,
        inventoryClearingAccount: null,
        error: null,
        isFindingInventoryClearingAccount: true,
        isFindInventoryClearingAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_INVENTORY_CLEARING_FAIL: {
      return {
        ...state,
        error: action.payload,
        isFindingInventoryClearingAccount: false,
        isFindInventoryClearingAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_INVENTORY_CLEARING_SUCCESS: {
      return {
        ...state,
        inventoryClearingAccount: action.payload.data,
        error: null,
        isFindingInventoryClearingAccount: false,
        isFindInventoryClearingAccountCompleted: true,
      };
    }

    //#endregion FIND_INVENTORY_CLEARING

    //#region FIND_ACCOUNTS_PAYABLES_CLEARING

    case SystemAccountsActionType.FIND_ACCOUNTS_PAYABLES_CLEARING: {
      return {
        ...state,
        accountsPayablesClearingAccount: null,
        error: null,
        isFindingAccountsPayablesClearingAccount: true,
        isFindAccountsPayablesClearingAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_ACCOUNTS_PAYABLES_CLEARING_FAIL: {
      return {
        ...state,
        error: action.payload,
        isFindingAccountsPayablesClearingAccount: false,
        isFindAccountsPayablesClearingAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_ACCOUNTS_PAYABLES_CLEARING_SUCCESS: {
      return {
        ...state,
        accountsPayablesClearingAccount: action.payload.data,
        error: null,
        isFindingAccountsPayablesClearingAccount: false,
        isFindAccountsPayablesClearingAccountCompleted: true,
      };
    }

    //#endregion FIND_ACCOUNTS_PAYABLES_CLEARING

    //#region FIND_PETTY_CASH_CLEARING

    case SystemAccountsActionType.FIND_PETTY_CASH_CLEARING: {
      return {
        ...state,
        pettyCashClearingAccount: null,
        error: null,
        isFindingPettyCashClearingAccount: true,
        isFindPettyCashClearingAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_PETTY_CASH_CLEARING_FAIL: {
      return {
        ...state,
        error: action.payload,
        isFindingPettyCashClearingAccount: false,
        isFindPettyCashClearingAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_PETTY_CASH_CLEARING_SUCCESS: {
      return {
        ...state,
        pettyCashClearingAccount: action.payload.data,
        error: null,
        isFindingPettyCashClearingAccount: false,
        isFindPettyCashClearingAccountCompleted: true,
      };
    }

    //#endregion FIND_PETTY_CASH_CLEARING

    //#region FIND_LIABILITY

    case SystemAccountsActionType.FIND_LIABILITY: {
      return {
        ...state,
        liabilityAccount: null,
        error: null,
        isFindingLiabilityAccount: true,
        isFindLiabilityAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_LIABILITY_FAIL: {
      return {
        ...state,
        error: action.payload,
        isFindingLiabilityAccount: false,
        isFindLiabilityAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_LIABILITY_SUCCESS: {
      return {
        ...state,
        liabilityAccount: action.payload.data,
        error: null,
        isFindingLiabilityAccount: false,
        isFindLiabilityAccountCompleted: true,
      };
    }

    //#endregion FIND_LIABILITY

    //#region FIND_COGS

    case SystemAccountsActionType.FIND_COGS: {
      return {
        ...state,
        COGSAccount: null,
        error: null,
        isFindingCOGSAccount: true,
        isFindCOGSAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_COGS_FAIL: {
      return {
        ...state,
        error: action.payload,
        isFindingCOGSAccount: false,
        isFindCOGSAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_COGS_SUCCESS: {
      return {
        ...state,
        COGSAccount: action.payload.data,
        error: null,
        isFindingCOGSAccount: false,
        isFindCOGSAccountCompleted: true,
      };
    }

    //#endregion FIND_COGS

    //#region FIND_PROFIT_LOSS

    case SystemAccountsActionType.FIND_PROFIT_LOSS: {
      return {
        ...state,
        profitLossAccount: null,
        error: null,
        isFindingProfitLossAccount: true,
        isFindProfitLossAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_PROFIT_LOSS_FAIL: {
      return {
        ...state,
        error: action.payload,
        isFindingProfitLossAccount: false,
        isFindProfitLossAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_PROFIT_LOSS_SUCCESS: {
      return {
        ...state,
        profitLossAccount: action.payload.data,
        error: null,
        isFindingProfitLossAccount: false,
        isFindProfitLossAccountCompleted: true,
      };
    }

    //#endregion FIND_PROFIT_LOSS

    //#region FIND_BANK_ACCOUNT

    case SystemAccountsActionType.FIND_BANK_ACCOUNT: {
      return {
        ...state,
        bankAccount: null,
        error: null,
        isFindingBankAccount: true,
        isFindBankAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_BANK_ACCOUNT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isFindingBankAccount: false,
        isFindBankAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_BANK_ACCOUNT_SUCCESS: {
      return {
        ...state,
        bankAccount: action.payload.data,
        error: null,
        isFindingBankAccount: false,
        isFindBankAccountCompleted: true,
      };
    }

    //#endregion FIND_BANK_ACCOUNT

    //#region FIND_CASH_ACCOUNT

    case SystemAccountsActionType.FIND_CASH_ACCOUNT: {
      return {
        ...state,
        cashAccount: null,
        error: null,
        isFindingCashAccount: true,
        isFindCashAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_CASH_ACCOUNT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isFindingCashAccount: false,
        isFindCashAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_CASH_ACCOUNT_SUCCESS: {
      return {
        ...state,
        cashAccount: action.payload.data,
        error: null,
        isFindingCashAccount: false,
        isFindCashAccountCompleted: true,
      };
    }

    //#endregion FIND_CASH_ACCOUNT

    //#region FIND_CHEQUES_UNDER_COLLECTION

    case SystemAccountsActionType.FIND_CHEQUES_UNDER_COLLECTION: {
      return {
        ...state,
        chequesUnderCollectionAccount: null,
        error: null,
        isFindingChequesUnderCollectionAccount: true,
        isFindChequesUnderCollectionAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_CHEQUES_UNDER_COLLECTION_FAIL: {
      return {
        ...state,
        error: action.payload,
        isFindingChequesUnderCollectionAccount: false,
        isFindChequesUnderCollectionAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_CHEQUES_UNDER_COLLECTION_SUCCESS: {
      return {
        ...state,
        chequesUnderCollectionAccount: action.payload.data,
        error: null,
        isFindingChequesUnderCollectionAccount: false,
        isFindChequesUnderCollectionAccountCompleted: true,
      };
    }

    //#endregion FIND_CHEQUES_UNDER_COLLECTION

    //#region FIND_PAYMENTS_UNDER_COLLECTION

    case SystemAccountsActionType.FIND_PAYMENTS_UNDER_COLLECTION: {
      return {
        ...state,
        paymentsUnderCollectionAccount: null,
        error: null,
        isFindingPaymentsUnderCollectionAccount: true,
        isFindPaymentsUnderCollectionAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_PAYMENTS_UNDER_COLLECTION_FAIL: {
      return {
        ...state,
        error: action.payload,
        isFindingPaymentsUnderCollectionAccount: false,
        isFindPaymentsUnderCollectionAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_PAYMENTS_UNDER_COLLECTION_SUCCESS: {
      return {
        ...state,
        paymentsUnderCollectionAccount: action.payload.data,
        error: null,
        isFindingPaymentsUnderCollectionAccount: false,
        isFindPaymentsUnderCollectionAccountCompleted: true,
      };
    }

    //#endregion FIND_PAYMENTS_UNDER_COLLECTION

    //#region FIND_POINT_OF_SALE_CASH

    case SystemAccountsActionType.FIND_POINT_OF_SALE_CASH: {
      return {
        ...state,
        pointOfSaleCashAccount: null,
        error: null,
        isFindingPointOfSaleCashAccount: true,
        isFindPointOfSaleCashAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_POINT_OF_SALE_CASH_FAIL: {
      return {
        ...state,
        error: action.payload,
        isFindingPointOfSaleCashAccount: false,
        isFindPointOfSaleCashAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_POINT_OF_SALE_CASH_SUCCESS: {
      return {
        ...state,
        pointOfSaleCashAccount: action.payload.data,
        error: null,
        isFindingPointOfSaleCashAccount: false,
        isFindPointOfSaleCashAccountCompleted: true,
      };
    }

    //#endregion FIND_POINT_OF_SALE_CASH

    //#region FIND_PENDING_CHEQUE

    case SystemAccountsActionType.FIND_PENDING_CHEQUE: {
      return {
        ...state,
        pendingChequeAccount: null,
        error: null,
        isFindingPendingChequeAccount: true,
        isFindPendingChequeAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_PENDING_CHEQUE_FAIL: {
      return {
        ...state,
        error: action.payload,
        isFindingPendingChequeAccount: false,
        isFindPendingChequeAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_PENDING_CHEQUE_SUCCESS: {
      return {
        ...state,
        pendingChequeAccount: action.payload.data,
        error: null,
        isFindingPendingChequeAccount: false,
        isFindPendingChequeAccountCompleted: true,
      };
    }

    //#endregion FIND_PENDING_CHEQUE

    //#region FIND_PAYMENTS_UNDER_PAYMENT

    case SystemAccountsActionType.FIND_PAYMENTS_UNDER_PAYMENT: {
      return {
        ...state,
        paymentsUnderPaymentAccount: null,
        error: null,
        isFindingPaymentsUnderPaymentAccount: true,
        isFindPaymentsUnderPaymentAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_PAYMENTS_UNDER_PAYMENT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isFindingPaymentsUnderPaymentAccount: false,
        isFindPaymentsUnderPaymentAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_PAYMENTS_UNDER_PAYMENT_SUCCESS: {
      return {
        ...state,
        paymentsUnderPaymentAccount: action.payload.data,
        error: null,
        isFindingPaymentsUnderPaymentAccount: false,
        isFindPaymentsUnderPaymentAccountCompleted: true,
      };
    }

    //#endregion FIND_PAYMENTS_UNDER_PAYMENT

    //#region FIND_ADVANCE_EXPENSES_PETTY_CASH

    case SystemAccountsActionType.FIND_ADVANCE_EXPENSES_PETTY_CASH: {
      return {
        ...state,
        advanceExpensesPettyCashAccount: null,
        error: null,
        isFindingAdvanceExpensesPettyCashAccount: true,
        isFindAdvanceExpensesPettyCashAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_ADVANCE_EXPENSES_PETTY_CASH_FAIL: {
      return {
        ...state,
        error: action.payload,
        isFindingAdvanceExpensesPettyCashAccount: false,
        isFindAdvanceExpensesPettyCashAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_ADVANCE_EXPENSES_PETTY_CASH_SUCCESS: {
      return {
        ...state,
        advanceExpensesPettyCashAccount: action.payload.data,
        error: null,
        isFindingAdvanceExpensesPettyCashAccount: false,
        isFindAdvanceExpensesPettyCashAccountCompleted: true,
      };
    }

    //#endregion FIND_ADVANCE_EXPENSES_PETTY_CASH

    //#region FIND_OWNERS_CURRENT_ACCOUNT

    case SystemAccountsActionType.FIND_OWNERS_CURRENT_ACCOUNT: {
      return {
        ...state,
        ownersCurrentAccount: null,
        error: null,
        isFindingOwnersCurrentAccount: true,
        isFindOwnersCurrentAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_OWNERS_CURRENT_ACCOUNT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isFindingOwnersCurrentAccount: false,
        isFindOwnersCurrentAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_OWNERS_CURRENT_ACCOUNT_SUCCESS: {
      return {
        ...state,
        ownersCurrentAccount: action.payload.data,
        error: null,
        isFindingOwnersCurrentAccount: false,
        isFindOwnersCurrentAccountCompleted: true,
      };
    }

    //#endregion FIND_OWNERS_CURRENT_ACCOUNT

    //#region FIND_IMPROVEMENTS_TO_RENTED_BUILDINGS_ACCOUNT

    case SystemAccountsActionType.FIND_IMPROVEMENTS_TO_RENTED_BUILDINGS_ACCOUNT: {
      return {
        ...state,
        improvementsToRentedBuildingsAccount: null,
        error: null,
        isFindingImprovementsToRentedBuildingsAccount: true,
        isFindImprovementsToRentedBuildingsAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_IMPROVEMENTS_TO_RENTED_BUILDINGS_ACCOUNT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isFindingImprovementsToRentedBuildingsAccount: false,
        isFindImprovementsToRentedBuildingsAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_IMPROVEMENTS_TO_RENTED_BUILDINGS_ACCOUNT_SUCCESS: {
      return {
        ...state,
        improvementsToRentedBuildingsAccount: action.payload.data,
        error: null,
        isFindingImprovementsToRentedBuildingsAccount: false,
        isFindImprovementsToRentedBuildingsAccountCompleted: true,
      };
    }

    //#endregion FIND_IMPROVEMENTS_TO_RENTED_BUILDINGS_ACCOUNT

    //#region FIND_MACHINES_AND_EQUIPMENTS_ACCOUNT

    case SystemAccountsActionType.FIND_MACHINES_AND_EQUIPMENTS_ACCOUNT: {
      return {
        ...state,
        machinesAndEquipmentsAccount: null,
        error: null,
        isFindingMachinesAndEquipmentsAccount: true,
        isFindMachinesAndEquipmentsAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_MACHINES_AND_EQUIPMENTS_ACCOUNT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isFindingMachinesAndEquipmentsAccount: false,
        isFindMachinesAndEquipmentsAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_MACHINES_AND_EQUIPMENTS_ACCOUNT_SUCCESS: {
      return {
        ...state,
        machinesAndEquipmentsAccount: action.payload.data,
        error: null,
        isFindingMachinesAndEquipmentsAccount: false,
        isFindMachinesAndEquipmentsAccountCompleted: true,
      };
    }

    //#endregion FIND_MACHINES_AND_EQUIPMENTS_ACCOUNT

    //#region FIND_DESKTOP_DEVICES_ACCOUNT

    case SystemAccountsActionType.FIND_DESKTOP_DEVICES_ACCOUNT: {
      return {
        ...state,
        desktopDevicesAccount: null,
        error: null,
        isFindingDesktopDevicesAccount: true,
        isFindDesktopDevicesAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_DESKTOP_DEVICES_ACCOUNT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isFindingDesktopDevicesAccount: false,
        isFindDesktopDevicesAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_DESKTOP_DEVICES_ACCOUNT_SUCCESS: {
      return {
        ...state,
        desktopDevicesAccount: action.payload.data,
        error: null,
        isFindingDesktopDevicesAccount: false,
        isFindDesktopDevicesAccountCompleted: true,
      };
    }

    //#endregion FIND_DESKTOP_DEVICES_ACCOUNT

    //#region FIND_ASSET_BROKERAGE_ACCOUNT

    case SystemAccountsActionType.FIND_ASSET_BROKERAGE_ACCOUNT: {
      return {
        ...state,
        assetBrokerageAccount: null,
        error: null,
        isFindingAssetBrokerageAccount: true,
        isFindAssetBrokerageAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_ASSET_BROKERAGE_ACCOUNT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isFindingAssetBrokerageAccount: false,
        isFindAssetBrokerageAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_ASSET_BROKERAGE_ACCOUNT_SUCCESS: {
      return {
        ...state,
        assetBrokerageAccount: action.payload.data,
        error: null,
        isFindingAssetBrokerageAccount: false,
        isFindAssetBrokerageAccountCompleted: true,
      };
    }

    //#endregion FIND_ASSET_BROKERAGE_ACCOUNT

    //#region FIND_SALARIES_PAYABLE_ACCOUNT

    case SystemAccountsActionType.FIND_SALARIES_PAYABLE_ACCOUNT: {
      return {
        ...state,
        salariesPayableAccount: null,
        error: null,
        isFindingSalariesPayableAccount: true,
        isFindSalariesPayableAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_SALARIES_PAYABLE_ACCOUNT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isFindingSalariesPayableAccount: false,
        isFindSalariesPayableAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_SALARIES_PAYABLE_ACCOUNT_SUCCESS: {
      return {
        ...state,
        salariesPayableAccount: action.payload.data,
        error: null,
        isFindingSalariesPayableAccount: false,
        isFindSalariesPayableAccountCompleted: true,
      };
    }

    //#endregion FIND_SALARIES_PAYABLE_ACCOUNT

    /* &&&&&&&&&&&&&&&&&&&&&&&&&& NEW SYSTEMS ACCOUNTS &&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&& */

    //#region FIND_CASH_IN_HAND_ACCOUNT

    case SystemAccountsActionType.FIND_CASH_IN_HAND_ACCOUNT: {
      return {
        ...state,
        cashInHandAccount: null,
        error: null,
        isFindingCashInHandAccount: true,
        isFindCashInHandAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_CASH_IN_HAND_ACCOUNT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isFindingCashInHandAccount: false,
        isFindCashInHandAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_CASH_IN_HAND_ACCOUNT_SUCCESS: {
      return {
        ...state,
        cashInHandAccount: action.payload.data,
        error: null,
        isFindingCashInHandAccount: false,
        isFindCashInHandAccountCompleted: true,
      };
    }

    //#endregion FIND_CASH_IN_HAND_ACCOUNT

    //#region FIND_CASH_IN_BANKS_ACCOUNT

    case SystemAccountsActionType.FIND_CASH_IN_BANKS_ACCOUNT: {
      return {
        ...state,
        cashInBanksAccount: null,
        error: null,
        isFindingCashInBanksAccount: true,
        isFindCashInBanksAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_CASH_IN_BANKS_ACCOUNT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isFindingCashInBanksAccount: false,
        isFindCashInBanksAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_CASH_IN_BANKS_ACCOUNT_SUCCESS: {
      return {
        ...state,
        cashInBanksAccount: action.payload.data,
        error: null,
        isFindingCashInBanksAccount: false,
        isFindCashInBanksAccountCompleted: true,
      };
    }

    //#endregion FIND_CASH_IN_BANKS_ACCOUNT

    //#region FIND_CHECKS_UNDER_COLLECTION_ACCOUNT

    case SystemAccountsActionType.FIND_CHECKS_UNDER_COLLECTION_ACCOUNT: {
      return {
        ...state,
        checksUnderCollectionAccount: null,
        error: null,
        isFindingChecksUnderCollectionAccount: true,
        isFindChecksUnderCollectionAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_CHECKS_UNDER_COLLECTION_ACCOUNT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isFindingChecksUnderCollectionAccount: false,
        isFindChecksUnderCollectionAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_CHECKS_UNDER_COLLECTION_ACCOUNT_SUCCESS: {
      return {
        ...state,
        checksUnderCollectionAccount: action.payload.data,
        error: null,
        isFindingChecksUnderCollectionAccount: false,
        isFindChecksUnderCollectionAccountCompleted: true,
      };
    }

    //#endregion FIND_CHECKS_UNDER_COLLECTION_ACCOUNT

    //#region FIND_CASH_INVESTMENTS_LESS_THAN_90_DAYS_ACCOUNT

    case SystemAccountsActionType.FIND_CASH_INVESTMENTS_LESS_THAN_90_DAYS_ACCOUNT: {
      return {
        ...state,
        cashInvestmentsLessThan90DaysAccount: null,
        error: null,
        isFindingCashInvestmentsLessThan90DaysAccount: true,
        isFindCashInvestmentsLessThan90DaysAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_CASH_INVESTMENTS_LESS_THAN_90_DAYS_ACCOUNT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isFindingCashInvestmentsLessThan90DaysAccount: false,
        isFindCashInvestmentsLessThan90DaysAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_CASH_INVESTMENTS_LESS_THAN_90_DAYS_ACCOUNT_SUCCESS: {
      return {
        ...state,
        cashInvestmentsLessThan90DaysAccount: action.payload.data,
        error: null,
        isFindingCashInvestmentsLessThan90DaysAccount: false,
        isFindCashInvestmentsLessThan90DaysAccountCompleted: true,
      };
    }

    //#endregion FIND_CASH_INVESTMENTS_LESS_THAN_90_DAYS_ACCOUNT

    //#region FIND_RESTRICTED_CASH_IN_BANKS_ACCOUNT

    case SystemAccountsActionType.FIND_RESTRICTED_CASH_IN_BANKS_ACCOUNT: {
      return {
        ...state,
        restrictedCashInBanksAccount: null,
        error: null,
        isFindingRestrictedCashInBanksAccount: true,
        isFindRestrictedCashInBanksAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_RESTRICTED_CASH_IN_BANKS_ACCOUNT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isFindingRestrictedCashInBanksAccount: false,
        isFindRestrictedCashInBanksAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_RESTRICTED_CASH_IN_BANKS_ACCOUNT_SUCCESS: {
      return {
        ...state,
        restrictedCashInBanksAccount: action.payload.data,
        error: null,
        isFindingRestrictedCashInBanksAccount: false,
        isFindRestrictedCashInBanksAccountCompleted: true,
      };
    }

    //#endregion FIND_RESTRICTED_CASH_IN_BANKS_ACCOUNT

    //#region FIND_SHORT_TERM_DEPOSITS_ACCOUNT

    case SystemAccountsActionType.FIND_SHORT_TERM_DEPOSITS_ACCOUNT: {
      return {
        ...state,
        shortTermDepositsAccount: null,
        error: null,
        isFindingShortTermDepositsAccount: true,
        isFindShortTermDepositsAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_SHORT_TERM_DEPOSITS_ACCOUNT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isFindingShortTermDepositsAccount: false,
        isFindShortTermDepositsAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_SHORT_TERM_DEPOSITS_ACCOUNT_SUCCESS: {
      return {
        ...state,
        shortTermDepositsAccount: action.payload.data,
        error: null,
        isFindingShortTermDepositsAccount: false,
        isFindShortTermDepositsAccountCompleted: true,
      };
    }

    //#endregion FIND_SHORT_TERM_DEPOSITS_ACCOUNT

    //#region FIND_CASH_INVESTMENTS_FOR_TRADING_ACCOUNT

    case SystemAccountsActionType.FIND_CASH_INVESTMENTS_FOR_TRADING_ACCOUNT: {
      return {
        ...state,
        cashInvestmentsForTradingAccount: null,
        error: null,
        isFindingCashInvestmentsForTradingAccount: true,
        isFindCashInvestmentsForTradingAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_CASH_INVESTMENTS_FOR_TRADING_ACCOUNT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isFindingCashInvestmentsForTradingAccount: false,
        isFindCashInvestmentsForTradingAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_CASH_INVESTMENTS_FOR_TRADING_ACCOUNT_SUCCESS: {
      return {
        ...state,
        cashInvestmentsForTradingAccount: action.payload.data,
        error: null,
        isFindingCashInvestmentsForTradingAccount: false,
        isFindCashInvestmentsForTradingAccountCompleted: true,
      };
    }

    //#endregion FIND_SHORT_TERM_DEPOSITS_ACCOUNT

    //#region FIND_TRADE_DEBTORS_ACCOUNT

    case SystemAccountsActionType.FIND_TRADE_DEBTORS_ACCOUNT: {
      return {
        ...state,
        tradeDebtorsAccount: null,
        error: null,
        isFindingTradeDebtorsAccount: true,
        isFindTradeDebtorsAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_TRADE_DEBTORS_ACCOUNT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isFindingTradeDebtorsAccount: false,
        isFindTradeDebtorsAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_TRADE_DEBTORS_ACCOUNT_SUCCESS: {
      return {
        ...state,
        tradeDebtorsAccount: action.payload.data,
        error: null,
        isFindingTradeDebtorsAccount: false,
        isFindTradeDebtorsAccountCompleted: true,
      };
    }

    //#endregion FIND_TRADE_DEBTORS_ACCOUNT

    //#region FIND_ALLOWANCE_FOR_DOUBTFUL_DEBTS_ACCOUNT

    case SystemAccountsActionType.FIND_ALLOWANCE_FOR_DOUBTFUL_DEBTS_ACCOUNT: {
      return {
        ...state,
        allowanceForDoubtfulDebtsAccount: null,
        error: null,
        isFindingAllowanceForDoubtfulDebtsAccount: true,
        isFindAllowanceForDoubtfulDebtsAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_ALLOWANCE_FOR_DOUBTFUL_DEBTS_ACCOUNT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isFindingAllowanceForDoubtfulDebtsAccount: false,
        isFindAllowanceForDoubtfulDebtsAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_ALLOWANCE_FOR_DOUBTFUL_DEBTS_ACCOUNT_SUCCESS: {
      return {
        ...state,
        allowanceForDoubtfulDebtsAccount: action.payload.data,
        error: null,
        isFindingAllowanceForDoubtfulDebtsAccount: false,
        isFindAllowanceForDoubtfulDebtsAccountCompleted: true,
      };
    }

    //#endregion FIND_ALLOWANCE_FOR_DOUBTFUL_DEBTS_ACCOUNT

    //#region FIND_RELATED_PARTIES_ACCOUNT

    case SystemAccountsActionType.FIND_RELATED_PARTIES_ACCOUNT: {
      return {
        ...state,
        relatedPartiesAccount: null,
        error: null,
        isFindingRelatedPartiesAccount: true,
        isFindRelatedPartiesAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_RELATED_PARTIES_ACCOUNT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isFindingRelatedPartiesAccount: false,
        isFindRelatedPartiesAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_RELATED_PARTIES_ACCOUNT_SUCCESS: {
      return {
        ...state,
        relatedPartiesAccount: action.payload.data,
        error: null,
        isFindingRelatedPartiesAccount: false,
        isFindRelatedPartiesAccountCompleted: true,
      };
    }

    //#endregion FIND_RELATED_PARTIES_ACCOUNT

    //#region FIND_ADVANCES_OF_STAFF_ACCOUNT

    case SystemAccountsActionType.FIND_ADVANCES_OF_STAFF_ACCOUNT: {
      return {
        ...state,
        advancesOfStaffAccount: null,
        error: null,
        isFindingAdvancesOfStaffAccount: true,
        isFindAdvancesOfStaffAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_ADVANCES_OF_STAFF_ACCOUNT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isFindingAdvancesOfStaffAccount: false,
        isFindAdvancesOfStaffAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_ADVANCES_OF_STAFF_ACCOUNT_SUCCESS: {
      return {
        ...state,
        advancesOfStaffAccount: action.payload.data,
        error: null,
        isFindingAdvancesOfStaffAccount: false,
        isFindAdvancesOfStaffAccountCompleted: true,
      };
    }

    //#endregion FIND_ADVANCES_OF_STAFF_ACCOUNT

    //#region FIND_LETTERS_OF_GUARANTEE_ACCOUNT

    case SystemAccountsActionType.FIND_LETTERS_OF_GUARANTEE_ACCOUNT: {
      return {
        ...state,
        lettersOfGuaranteeAccount: null,
        error: null,
        isFindingLettersOfGuaranteeAccount: true,
        isFindLettersOfGuaranteeAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_LETTERS_OF_GUARANTEE_ACCOUNT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isFindingLettersOfGuaranteeAccount: false,
        isFindLettersOfGuaranteeAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_LETTERS_OF_GUARANTEE_ACCOUNT_SUCCESS: {
      return {
        ...state,
        lettersOfGuaranteeAccount: action.payload.data,
        error: null,
        isFindingLettersOfGuaranteeAccount: false,
        isFindLettersOfGuaranteeAccountCompleted: true,
      };
    }

    //#endregion FIND_ADVANCES_OF_STAFF_ACCOUNT

    //#region FIND_DOCUMENTARY_CREDITS_ACCOUNT

    case SystemAccountsActionType.FIND_DOCUMENTARY_CREDITS_ACCOUNT: {
      return {
        ...state,
        documentaryCreditsAccount: null,
        error: null,
        isFindingDocumentaryCreditsAccount: true,
        isFindDocumentaryCreditsAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_DOCUMENTARY_CREDITS_ACCOUNT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isFindingDocumentaryCreditsAccount: false,
        isFindDocumentaryCreditsAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_DOCUMENTARY_CREDITS_ACCOUNT_SUCCESS: {
      return {
        ...state,
        documentaryCreditsAccount: action.payload.data,
        error: null,
        isFindingDocumentaryCreditsAccount: false,
        isFindDocumentaryCreditsAccountCompleted: true,
      };
    }

    //#endregion FIND_DOCUMENTARY_CREDITS_ACCOUNT

    //#region FIND_PETTY_CASH_ACCOUNT

    case SystemAccountsActionType.FIND_PETTY_CASH_ACCOUNT: {
      return {
        ...state,
        pettyCashAccount: null,
        error: null,
        isFindingPettyCashAccount: true,
        isFindPettyCashAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_PETTY_CASH_ACCOUNT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isFindingPettyCashAccount: false,
        isFindPettyCashAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_PETTY_CASH_ACCOUNT_SUCCESS: {
      return {
        ...state,
        pettyCashAccount: action.payload.data,
        error: null,
        isFindingPettyCashAccount: false,
        isFindPettyCashAccountCompleted: true,
      };
    }

    //#endregion FIND_PETTY_CASH_ACCOUNT

    //#region FIND_WELL_EXECUTED_DETENTION_ACCOUNT

    case SystemAccountsActionType.FIND_WELL_EXECUTED_DETENTION_ACCOUNT: {
      return {
        ...state,
        wellExecutedDetentionAccount: null,
        error: null,
        isFindingWellExecutedDetentionAccount: true,
        isFindWellExecutedDetentionAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_WELL_EXECUTED_DETENTION_ACCOUNT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isFindingWellExecutedDetentionAccount: false,
        isFindWellExecutedDetentionAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_WELL_EXECUTED_DETENTION_ACCOUNT_SUCCESS: {
      return {
        ...state,
        wellExecutedDetentionAccount: action.payload.data,
        error: null,
        isFindingWellExecutedDetentionAccount: false,
        isFindWellExecutedDetentionAccountCompleted: true,
      };
    }

    //#endregion FIND_WELL_EXECUTED_DETENTION_ACCOUNT

    //#region FIND_ACCRUED_REVENUE_ACCOUNT

    case SystemAccountsActionType.FIND_ACCRUED_REVENUE_ACCOUNT: {
      return {
        ...state,
        accruedRevenueAccount: null,
        error: null,
        isFindingAccruedRevenueAccount: true,
        isFindAccruedRevenueAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_ACCRUED_REVENUE_ACCOUNT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isFindingAccruedRevenueAccount: false,
        isFindAccruedRevenueAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_ACCRUED_REVENUE_ACCOUNT_SUCCESS: {
      return {
        ...state,
        accruedRevenueAccount: action.payload.data,
        error: null,
        isFindingAccruedRevenueAccount: false,
        isFindAccruedRevenueAccountCompleted: true,
      };
    }

    //#endregion FIND_ACCRUED_REVENUE_ACCOUNT

    //#region FIND_WORK_IN_PROGRESS_UNBILLED_REVENUE_ACCOUNT

    case SystemAccountsActionType.FIND_WORK_IN_PROGRESS_UNBILLED_REVENUE_ACCOUNT: {
      return {
        ...state,
        workInProgressUnbilledRevenueAccount: null,
        error: null,
        isFindingWorkInProgressUnbilledRevenueAccount: true,
        isFindWorkInProgressUnbilledRevenueAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_WORK_IN_PROGRESS_UNBILLED_REVENUE_ACCOUNT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isFindingWorkInProgressUnbilledRevenueAccount: false,
        isFindWorkInProgressUnbilledRevenueAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_WORK_IN_PROGRESS_UNBILLED_REVENUE_ACCOUNT_SUCCESS: {
      return {
        ...state,
        workInProgressUnbilledRevenueAccount: action.payload.data,
        error: null,
        isFindingWorkInProgressUnbilledRevenueAccount: false,
        isFindWorkInProgressUnbilledRevenueAccountCompleted: true,
      };
    }

    //#endregion FIND_WORK_IN_PROGRESS_UNBILLED_REVENUE_ACCOUNT

    //#region FIND_VALUE_ADDED_TAX_DEBIT_ACCOUNT

    case SystemAccountsActionType.FIND_VALUE_ADDED_TAX_DEBIT_ACCOUNT: {
      return {
        ...state,
        valueAddedTaxDebitAccount: null,
        error: null,
        isFindingValueAddedTaxDebitAccount: true,
        isFindValueAddedTaxDebitAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_VALUE_ADDED_TAX_DEBIT_ACCOUNT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isFindingValueAddedTaxDebitAccount: false,
        isFindValueAddedTaxDebitAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_VALUE_ADDED_TAX_DEBIT_ACCOUNT_SUCCESS: {
      return {
        ...state,
        valueAddedTaxDebitAccount: action.payload.data,
        error: null,
        isFindingValueAddedTaxDebitAccount: false,
        isFindValueAddedTaxDebitAccountCompleted: true,
      };
    }

    //#endregion FIND_VALUE_ADDED_TAX_DEBIT_ACCOUNT

    //#region FIND_MISCELLANEOUS_DEBTORS_ACCOUNT

    case SystemAccountsActionType.FIND_MISCELLANEOUS_DEBTORS_ACCOUNT: {
      return {
        ...state,
        miscellaneousDebtorsAccount: null,
        error: null,
        isFindingMiscellaneousDebtorsAccount: true,
        isFindMiscellaneousDebtorsAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_MISCELLANEOUS_DEBTORS_ACCOUNT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isFindingMiscellaneousDebtorsAccount: false,
        isFindMiscellaneousDebtorsAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_MISCELLANEOUS_DEBTORS_ACCOUNT_SUCCESS: {
      return {
        ...state,
        miscellaneousDebtorsAccount: action.payload.data,
        error: null,
        isFindingMiscellaneousDebtorsAccount: false,
        isFindMiscellaneousDebtorsAccountCompleted: true,
      };
    }

    //#endregion FIND_MISCELLANEOUS_DEBTORS_ACCOUNT

    //#region FIND_PREPAID_EXPENSES_ACCOUNT

    case SystemAccountsActionType.FIND_PREPAID_EXPENSES_ACCOUNT: {
      return {
        ...state,
        prepaidExpensesAccount: null,
        error: null,
        isFindingPrepaidExpensesAccount: true,
        isFindPrepaidExpensesAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_PREPAID_EXPENSES_ACCOUNT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isFindingPrepaidExpensesAccount: false,
        isFindPrepaidExpensesAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_PREPAID_EXPENSES_ACCOUNT_SUCCESS: {
      return {
        ...state,
        prepaidExpensesAccount: action.payload.data,
        error: null,
        isFindingPrepaidExpensesAccount: false,
        isFindPrepaidExpensesAccountCompleted: true,
      };
    }

    //#endregion FIND_PREPAID_EXPENSES_ACCOUNT

    //#region FIND_ADVANCE_PAYMENTS_TO_VENDORS_AND_CONTRACTORS_ACCOUNT

    case SystemAccountsActionType.FIND_ADVANCE_PAYMENTS_TO_VENDORS_AND_CONTRACTORS_ACCOUNT: {
      return {
        ...state,
        advancePaymentsToVendorsAndContractorsAccount: null,
        error: null,
        isFindingAdvancePaymentsToVendorsAndContractorsAccount: true,
        isFindAdvancePaymentsToVendorsAndContractorsAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_ADVANCE_PAYMENTS_TO_VENDORS_AND_CONTRACTORS_ACCOUNT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isFindingAdvancePaymentsToVendorsAndContractorsAccount: false,
        isFindAdvancePaymentsToVendorsAndContractorsAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_ADVANCE_PAYMENTS_TO_VENDORS_AND_CONTRACTORS_ACCOUNT_SUCCESS: {
      return {
        ...state,
        advancePaymentsToVendorsAndContractorsAccount: action.payload.data,
        error: null,
        isFindingAdvancePaymentsToVendorsAndContractorsAccount: false,
        isFindAdvancePaymentsToVendorsAndContractorsAccountCompleted: true,
      };
    }

    //#endregion FIND_ADVANCE_PAYMENTS_TO_VENDORS_AND_CONTRACTORS_ACCOUNT

    //#region FIND_FINANCING_CHARGES_FOR_LEASE_CONTRACTS_ACCOUNT

    case SystemAccountsActionType.FIND_FINANCING_CHARGES_FOR_LEASE_CONTRACTS_ACCOUNT: {
      return {
        ...state,
        financingChargesForLeaseContractsAccount: null,
        error: null,
        isFindingFinancingChargesForLeaseContractsAccount: true,
        isFindFinancingChargesForLeaseContractsAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_FINANCING_CHARGES_FOR_LEASE_CONTRACTS_ACCOUNT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isFindingFinancingChargesForLeaseContractsAccount: false,
        isFindFinancingChargesForLeaseContractsAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_FINANCING_CHARGES_FOR_LEASE_CONTRACTS_ACCOUNT_SUCCESS: {
      return {
        ...state,
        financingChargesForLeaseContractsAccount: action.payload.data,
        error: null,
        isFindingFinancingChargesForLeaseContractsAccount: false,
        isFindFinancingChargesForLeaseContractsAccountCompleted: true,
      };
    }

    //#endregion FIND_FINANCING_CHARGES_FOR_LEASE_CONTRACTS_ACCOUNT

    //#region FIND_FINANCING_CHARGES_FOR_BANK_LOANS_AND_CREDIT_FACILITIES_ACCOUNT

    case SystemAccountsActionType.FIND_FINANCING_CHARGES_FOR_BANK_LOANS_AND_CREDIT_FACILITIES_ACCOUNT: {
      return {
        ...state,
        financingChargesForBankLoansAndCreditFacilitiesAccount: null,
        error: null,
        isFindingFinancingChargesForBankLoansAndCreditFacilitiesAccount: true,
        isFindFinancingChargesForBankLoansAndCreditFacilitiesAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_FINANCING_CHARGES_FOR_BANK_LOANS_AND_CREDIT_FACILITIES_ACCOUNT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isFindingFinancingChargesForBankLoansAndCreditFacilitiesAccount: false,
        isFindFinancingChargesForBankLoansAndCreditFacilitiesAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_FINANCING_CHARGES_FOR_BANK_LOANS_AND_CREDIT_FACILITIES_ACCOUNT_SUCCESS: {
      return {
        ...state,
        financingChargesForBankLoansAndCreditFacilitiesAccount: action.payload.data,
        error: null,
        isFindingFinancingChargesForBankLoansAndCreditFacilitiesAccount: false,
        isFindFinancingChargesForBankLoansAndCreditFacilitiesAccountCompleted: true,
      };
    }

    //#endregion FIND_FINANCING_CHARGES_FOR_BANK_LOANS_AND_CREDIT_FACILITIES_ACCOUNT

    //#region FIND_GOODS_AND_MATERIALS_STOCK_ACCOUNT

    case SystemAccountsActionType.FIND_GOODS_AND_MATERIALS_STOCK_ACCOUNT: {
      return {
        ...state,
        goodsAndMaterialsStockAccount: null,
        error: null,
        isFindingGoodsAndMaterialsStockAccount: true,
        isFindGoodsAndMaterialsStockAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_GOODS_AND_MATERIALS_STOCK_ACCOUNT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isFindingGoodsAndMaterialsStockAccount: false,
        isFindGoodsAndMaterialsStockAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_GOODS_AND_MATERIALS_STOCK_ACCOUNT_SUCCESS: {
      return {
        ...state,
        goodsAndMaterialsStockAccount: action.payload.data,
        error: null,
        isFindingGoodsAndMaterialsStockAccount: false,
        isFindGoodsAndMaterialsStockAccountCompleted: true,
      };
    }

    //#endregion FIND_GOODS_AND_MATERIALS_STOCK_ACCOUNT

    //#region FIND_STOCK_IN_PROCESS_ACCOUNT

    case SystemAccountsActionType.FIND_STOCK_IN_PROCESS_ACCOUNT: {
      return {
        ...state,
        stockInProcessAccount: null,
        error: null,
        isFindingStockInProcessAccount: true,
        isFindStockInProcessAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_STOCK_IN_PROCESS_ACCOUNT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isFindingStockInProcessAccount: false,
        isFindStockInProcessAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_STOCK_IN_PROCESS_ACCOUNT_SUCCESS: {
      return {
        ...state,
        stockInProcessAccount: action.payload.data,
        error: null,
        isFindingStockInProcessAccount: false,
        isFindStockInProcessAccountCompleted: true,
      };
    }

    //#endregion FIND_STOCK_IN_PROCESS_ACCOUNT

    //#region FIND_COMPLETED_STOCK_ACCOUNT

    case SystemAccountsActionType.FIND_COMPLETED_STOCK_ACCOUNT: {
      return {
        ...state,
        completedStockAccount: null,
        error: null,
        isFindingCompletedStockAccount: true,
        isFindCompletedStockAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_COMPLETED_STOCK_ACCOUNT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isFindingCompletedStockAccount: false,
        isFindCompletedStockAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_COMPLETED_STOCK_ACCOUNT_SUCCESS: {
      return {
        ...state,
        completedStockAccount: action.payload.data,
        error: null,
        isFindingCompletedStockAccount: false,
        isFindCompletedStockAccountCompleted: true,
      };
    }

    //#endregion FIND_COMPLETED_STOCK_ACCOUNT

    //#region FIND_PROVISION_FOR_DAMAGED_OR_OBSOLETE_INVENTORY_ACCOUNT

    case SystemAccountsActionType.FIND_PROVISION_FOR_DAMAGED_OR_OBSOLETE_INVENTORY_ACCOUNT: {
      return {
        ...state,
        provisionForDamagedOrObsoleteInventoryAccount: null,
        error: null,
        isFindingProvisionForDamagedOrObsoleteInventoryAccount: true,
        isFindProvisionForDamagedOrObsoleteInventoryAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_PROVISION_FOR_DAMAGED_OR_OBSOLETE_INVENTORY_ACCOUNT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isFindingProvisionForDamagedOrObsoleteInventoryAccount: false,
        isFindProvisionForDamagedOrObsoleteInventoryAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_PROVISION_FOR_DAMAGED_OR_OBSOLETE_INVENTORY_ACCOUNT_SUCCESS: {
      return {
        ...state,
        provisionForDamagedOrObsoleteInventoryAccount: action.payload.data,
        error: null,
        isFindingProvisionForDamagedOrObsoleteInventoryAccount: false,
        isFindProvisionForDamagedOrObsoleteInventoryAccountCompleted: true,
      };
    }

    //#endregion FIND_PROVISION_FOR_DAMAGED_OR_OBSOLETE_INVENTORY_ACCOUNT

    //#region FIND_INVESTMENTS_AVAILABLE_FOR_SALE_OR_HELD_TO_MATURITY_ACCOUNT

    case SystemAccountsActionType.FIND_INVESTMENTS_AVAILABLE_FOR_SALE_OR_HELD_TO_MATURITY_ACCOUNT: {
      return {
        ...state,
        investmentsAvailableForSaleOrHeldToMaturityAccount: null,
        error: null,
        isFindingInvestmentsAvailableForSaleOrHeldToMaturityAccount: true,
        isFindInvestmentsAvailableForSaleOrHeldToMaturityAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_INVESTMENTS_AVAILABLE_FOR_SALE_OR_HELD_TO_MATURITY_ACCOUNT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isFindingInvestmentsAvailableForSaleOrHeldToMaturityAccount: false,
        isFindInvestmentsAvailableForSaleOrHeldToMaturityAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_INVESTMENTS_AVAILABLE_FOR_SALE_OR_HELD_TO_MATURITY_ACCOUNT_SUCCESS: {
      return {
        ...state,
        investmentsAvailableForSaleOrHeldToMaturityAccount: action.payload.data,
        error: null,
        isFindingInvestmentsAvailableForSaleOrHeldToMaturityAccount: false,
        isFindInvestmentsAvailableForSaleOrHeldToMaturityAccountCompleted: true,
      };
    }

    //#endregion FIND_INVESTMENTS_AVAILABLE_FOR_SALE_OR_HELD_TO_MATURITY_ACCOUNT

    //#region FIND_LONG_TERM_DEPOSITS_ACCOUNT

    case SystemAccountsActionType.FIND_LONG_TERM_DEPOSITS_ACCOUNT: {
      return {
        ...state,
        longTermDepositsAccount: null,
        error: null,
        isFindingLongTermDepositsAccount: true,
        isFindLongTermDepositsAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_LONG_TERM_DEPOSITS_ACCOUNT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isFindingLongTermDepositsAccount: false,
        isFindLongTermDepositsAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_LONG_TERM_DEPOSITS_ACCOUNT_SUCCESS: {
      return {
        ...state,
        longTermDepositsAccount: action.payload.data,
        error: null,
        isFindingLongTermDepositsAccount: false,
        isFindLongTermDepositsAccountCompleted: true,
      };
    }

    //#endregion FIND_LONG_TERM_DEPOSITS_ACCOUNT

    //#region FIND_FINANCE_CHARGES_FOR_LEASE_CONTRACTS_NON_CURRENT_ACCOUNT

    case SystemAccountsActionType.FIND_FINANCE_CHARGES_FOR_LEASE_CONTRACTS_NON_CURRENT_ACCOUNT: {
      return {
        ...state,
        financeChargesForLeaseContractsNonCurrentAccount: null,
        error: null,
        isFindingFinanceChargesForLeaseContractsNonCurrentAccount: true,
        isFindFinanceChargesForLeaseContractsNonCurrentAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_FINANCE_CHARGES_FOR_LEASE_CONTRACTS_NON_CURRENT_ACCOUNT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isFindingFinanceChargesForLeaseContractsNonCurrentAccount: false,
        isFindFinanceChargesForLeaseContractsNonCurrentAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_FINANCE_CHARGES_FOR_LEASE_CONTRACTS_NON_CURRENT_ACCOUNT_SUCCESS: {
      return {
        ...state,
        financeChargesForLeaseContractsNonCurrentAccount: action.payload.data,
        error: null,
        isFindingFinanceChargesForLeaseContractsNonCurrentAccount: false,
        isFindFinanceChargesForLeaseContractsNonCurrentAccountCompleted: true,
      };
    }

    //#endregion FIND_FINANCE_CHARGES_FOR_LEASE_CONTRACTS_NON_CURRENT_ACCOUNT

    //#region FIND_FINANCING_CHARGES_FOR_LOANS_AND_CREDIT_FACILITIES_NON_CURRENT_ACCOUNT

    case SystemAccountsActionType.FIND_FINANCING_CHARGES_FOR_LOANS_AND_CREDIT_FACILITIES_NON_CURRENT_ACCOUNT: {
      return {
        ...state,
        financingChargesForLoansAndCreditFacilitiesNonCurrentAccount: null,
        error: null,
        isFindingFinancingChargesForLoansAndCreditFacilitiesNonCurrentAccount: true,
        isFindFinancingChargesForLoansAndCreditFacilitiesNonCurrentAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_FINANCING_CHARGES_FOR_LOANS_AND_CREDIT_FACILITIES_NON_CURRENT_ACCOUNT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isFindingFinancingChargesForLoansAndCreditFacilitiesNonCurrentAccount: false,
        isFindFinancingChargesForLoansAndCreditFacilitiesNonCurrentAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_FINANCING_CHARGES_FOR_LOANS_AND_CREDIT_FACILITIES_NON_CURRENT_ACCOUNT_SUCCESS: {
      return {
        ...state,
        financingChargesForLoansAndCreditFacilitiesNonCurrentAccount: action.payload.data,
        error: null,
        isFindingFinancingChargesForLoansAndCreditFacilitiesNonCurrentAccount: false,
        isFindFinancingChargesForLoansAndCreditFacilitiesNonCurrentAccountCompleted: true,
      };
    }

    //#endregion FIND_FINANCING_CHARGES_FOR_LOANS_AND_CREDIT_FACILITIES_NON_CURRENT_ACCOUNT

    //#region FIND_PROJECTS_UNDER_IMPLEMENTATION_ACCOUNT

    case SystemAccountsActionType.FIND_PROJECTS_UNDER_IMPLEMENTATION_ACCOUNT: {
      return {
        ...state,
        projectsUnderImplementationAccount: null,
        error: null,
        isFindingProjectsUnderImplementationAccount: true,
        isFindProjectsUnderImplementationAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_PROJECTS_UNDER_IMPLEMENTATION_ACCOUNT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isFindingProjectsUnderImplementationAccount: false,
        isFindProjectsUnderImplementationAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_PROJECTS_UNDER_IMPLEMENTATION_ACCOUNT_SUCCESS: {
      return {
        ...state,
        projectsUnderImplementationAccount: action.payload.data,
        error: null,
        isFindingProjectsUnderImplementationAccount: false,
        isFindProjectsUnderImplementationAccountCompleted: true,
      };
    }

    //#endregion FIND_PROJECTS_UNDER_IMPLEMENTATION_ACCOUNT

    //#region FIND_BUILDINGS_ACCOUNT

    case SystemAccountsActionType.FIND_BUILDINGS_ACCOUNT: {
      return {
        ...state,
        buildingsAccount: null,
        error: null,
        isFindingBuildingsAccount: true,
        isFindBuildingsAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_BUILDINGS_ACCOUNT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isFindingBuildingsAccount: false,
        isFindBuildingsAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_BUILDINGS_ACCOUNT_SUCCESS: {
      return {
        ...state,
        buildingsAccount: action.payload.data,
        error: null,
        isFindingBuildingsAccount: false,
        isFindBuildingsAccountCompleted: true,
      };
    }

    //#endregion FIND_BUILDINGS_ACCOUNT

    //#region FIND_LANDS_ACCOUNT

    case SystemAccountsActionType.FIND_LANDS_ACCOUNT: {
      return {
        ...state,
        landsAccount: null,
        error: null,
        isFindingLandsAccount: true,
        isFindLandsAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_LANDS_ACCOUNT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isFindingLandsAccount: false,
        isFindCarsAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_LANDS_ACCOUNT_SUCCESS: {
      return {
        ...state,
        landsAccount: action.payload.data,
        error: null,
        isFindingLandsAccount: false,
        isFindLandsAccountCompleted: true,
      };
    }

    //#endregion FIND_LANDS_ACCOUNT

    //#region FIND_FURNITURE_ACCOUNT

    case SystemAccountsActionType.FIND_FURNITURE_ACCOUNT: {
      return {
        ...state,
        furnitureAccount: null,
        error: null,
        isFindingFurnitureAccount: true,
        isFindFurnitureAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_FURNITURE_ACCOUNT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isFindingFurnitureAccount: false,
        isFindFurnitureAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_FURNITURE_ACCOUNT_SUCCESS: {
      return {
        ...state,
        furnitureAccount: action.payload.data,
        error: null,
        isFindingFurnitureAccount: false,
        isFindFurnitureAccountCompleted: true,
      };
    }

    //#endregion FIND_FURNITURE_ACCOUNT

    //#region FIND_DEVICES_ACCOUNT

    case SystemAccountsActionType.FIND_DEVICES_ACCOUNT: {
      return {
        ...state,
        devicesAccount: null,
        error: null,
        isFindingDevicesAccount: true,
        isFindDevicesAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_DEVICES_ACCOUNT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isFindingDevicesAccount: false,
        isFindDevicesAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_DEVICES_ACCOUNT_SUCCESS: {
      return {
        ...state,
        devicesAccount: action.payload.data,
        error: null,
        isFindingDevicesAccount: false,
        isFindDevicesAccountCompleted: true,
      };
    }

    //#endregion FIND_DEVICES_ACCOUNT

    //#region FIND_COMPUTERS_ACCOUNT

    case SystemAccountsActionType.FIND_COMPUTERS_ACCOUNT: {
      return {
        ...state,
        computersAccount: null,
        error: null,
        isFindingComputersAccount: true,
        isFindComputersAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_COMPUTERS_ACCOUNT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isFindingComputersAccount: false,
        isFindComputersAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_COMPUTERS_ACCOUNT_SUCCESS: {
      return {
        ...state,
        computersAccount: action.payload.data,
        error: null,
        isFindingComputersAccount: false,
        isFindComputersAccountCompleted: true,
      };
    }

    //#endregion FIND_COMPUTERS_ACCOUNT

    //#region FIND_EQUIPMENTS_ACCOUNT

    case SystemAccountsActionType.FIND_EQUIPMENTS_ACCOUNT: {
      return {
        ...state,
        equipmentsAccount: null,
        error: null,
        isFindingEquipmentsAccount: true,
        isFindEquipmentsAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_EQUIPMENTS_ACCOUNT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isFindingEquipmentsAccount: false,
        isFindEquipmentsAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_EQUIPMENTS_ACCOUNT_SUCCESS: {
      return {
        ...state,
        equipmentsAccount: action.payload.data,
        error: null,
        isFindingEquipmentsAccount: false,
        isFindEquipmentsAccountCompleted: true,
      };
    }

    //#endregion FIND_EQUIPMENTS_ACCOUNT

    //#region FIND_CARS_ACCOUNT

    case SystemAccountsActionType.FIND_CARS_ACCOUNT: {
      return {
        ...state,
        carsAccount: null,
        error: null,
        isFindingCarsAccount: true,
        isFindCarsAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_CARS_ACCOUNT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isFindingCarsAccount: false,
        isFindCarsAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_CARS_ACCOUNT_SUCCESS: {
      return {
        ...state,
        carsAccount: action.payload.data,
        error: null,
        isFindingCarsAccount: false,
        isFindCarsAccountCompleted: true,
      };
    }

    //#endregion FIND_CARS_ACCOUNT

    //#region FIND_ASSETS_UNDER_FINANCE_LEASES_ACCOUNT

    case SystemAccountsActionType.FIND_ASSETS_UNDER_FINANCE_LEASES_ACCOUNT: {
      return {
        ...state,
        assetsUnderFinanceLeasesAccount: null,
        error: null,
        isFindingAssetsUnderFinanceLeasesAccount: true,
        isFindAssetsUnderFinanceLeasesAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_ASSETS_UNDER_FINANCE_LEASES_ACCOUNT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isFindingAssetsUnderFinanceLeasesAccount: false,
        isFindAssetsUnderFinanceLeasesAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_ASSETS_UNDER_FINANCE_LEASES_ACCOUNT_SUCCESS: {
      return {
        ...state,
        assetsUnderFinanceLeasesAccount: action.payload.data,
        error: null,
        isFindingAssetsUnderFinanceLeasesAccount: false,
        isFindAssetsUnderFinanceLeasesAccountCompleted: true,
      };
    }

    //#endregion FIND_ASSETS_UNDER_FINANCE_LEASES_ACCOUNT

    //#region FIND_BUILDING_CONSUMPTION_COMPLEX_ACCOUNT

    case SystemAccountsActionType.FIND_BUILDING_CONSUMPTION_COMPLEX_ACCOUNT: {
      return {
        ...state,
        buildingConsumptionComplexAccount: null,
        error: null,
        isFindingBuildingConsumptionComplexAccount: true,
        isFindBuildingConsumptionComplexAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_BUILDING_CONSUMPTION_COMPLEX_ACCOUNT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isFindingBuildingConsumptionComplexAccount: false,
        isFindBuildingConsumptionComplexAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_BUILDING_CONSUMPTION_COMPLEX_ACCOUNT_SUCCESS: {
      return {
        ...state,
        buildingConsumptionComplexAccount: action.payload.data,
        error: null,
        isFindingBuildingConsumptionComplexAccount: false,
        isFindBuildingConsumptionComplexAccountCompleted: true,
      };
    }

    //#endregion FIND_BUILDING_CONSUMPTION_COMPLEX_ACCOUNT

    //#region FIND_FURNITURE_CONSUMPTION_COMPLEX_ACCOUNT

    case SystemAccountsActionType.FIND_FURNITURE_CONSUMPTION_COMPLEX_ACCOUNT: {
      return {
        ...state,
        furnitureConsumptionComplexAccount: null,
        error: null,
        isFindingFurnitureConsumptionComplexAccount: true,
        isFindFurnitureConsumptionComplexAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_FURNITURE_CONSUMPTION_COMPLEX_ACCOUNT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isFindingFurnitureConsumptionComplexAccount: false,
        isFindFurnitureConsumptionComplexAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_FURNITURE_CONSUMPTION_COMPLEX_ACCOUNT_SUCCESS: {
      return {
        ...state,
        furnitureConsumptionComplexAccount: action.payload.data,
        error: null,
        isFindingFurnitureConsumptionComplexAccount: false,
        isFindFurnitureConsumptionComplexAccountCompleted: true,
      };
    }

    //#endregion FIND_FURNITURE_CONSUMPTION_COMPLEX_ACCOUNT

    //#region FIND_DEVICES_CONSUMPTION_COMPLEX_ACCOUNT

    case SystemAccountsActionType.FIND_DEVICES_CONSUMPTION_COMPLEX_ACCOUNT: {
      return {
        ...state,
        devicesConsumptionComplexAccount: null,
        error: null,
        isFindingDevicesConsumptionComplexAccount: true,
        isFindDevicesConsumptionComplexAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_DEVICES_CONSUMPTION_COMPLEX_ACCOUNT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isFindingDevicesConsumptionComplexAccount: false,
        isFindDevicesConsumptionComplexAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_DEVICES_CONSUMPTION_COMPLEX_ACCOUNT_SUCCESS: {
      return {
        ...state,
        devicesConsumptionComplexAccount: action.payload.data,
        error: null,
        isFindingDevicesConsumptionComplexAccount: false,
        isFindDevicesConsumptionComplexAccountCompleted: true,
      };
    }

    //#endregion FIND_DEVICES_CONSUMPTION_COMPLEX_ACCOUNT

    //#region FIND_COMPUTERS_CONSUMPTION_COMPLEX_ACCOUNT

    case SystemAccountsActionType.FIND_COMPUTERS_CONSUMPTION_COMPLEX_ACCOUNT: {
      return {
        ...state,
        computersConsumptionComplexAccount: null,
        error: null,
        isFindingComputersConsumptionComplexAccount: true,
        isFindComputersConsumptionComplexAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_COMPUTERS_CONSUMPTION_COMPLEX_ACCOUNT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isFindingComputersConsumptionComplexAccount: false,
        isFindComputersConsumptionComplexAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_COMPUTERS_CONSUMPTION_COMPLEX_ACCOUNT_SUCCESS: {
      return {
        ...state,
        computersConsumptionComplexAccount: action.payload.data,
        error: null,
        isFindingComputersConsumptionComplexAccount: false,
        isFindComputersConsumptionComplexAccountCompleted: true,
      };
    }

    //#endregion FIND_COMPUTERS_CONSUMPTION_COMPLEX_ACCOUNT

    //#region FIND_EQUIPMENTS_CONSUMPTION_COMPLEX_ACCOUNT

    case SystemAccountsActionType.FIND_EQUIPMENTS_CONSUMPTION_COMPLEX_ACCOUNT: {
      return {
        ...state,
        equipmentsConsumptionComplexAccount: null,
        error: null,
        isFindingEquipmentsConsumptionComplexAccount: true,
        isFindEquipmentsConsumptionComplexAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_EQUIPMENTS_CONSUMPTION_COMPLEX_ACCOUNT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isFindingEquipmentsConsumptionComplexAccount: false,
        isFindEquipmentsConsumptionComplexAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_EQUIPMENTS_CONSUMPTION_COMPLEX_ACCOUNT_SUCCESS: {
      return {
        ...state,
        equipmentsConsumptionComplexAccount: action.payload.data,
        error: null,
        isFindingEquipmentsConsumptionComplexAccount: false,
        isFindEquipmentsConsumptionComplexAccountCompleted: true,
      };
    }

    //#endregion FIND_EQUIPMENTS_CONSUMPTION_COMPLEX_ACCOUNT

    //#region FIND_CARS_CONSUMPTION_COMPLEX_ACCOUNT

    case SystemAccountsActionType.FIND_CARS_CONSUMPTION_COMPLEX_ACCOUNT: {
      return {
        ...state,
        carsConsumptionComplexAccount: null,
        error: null,
        isFindingCarsConsumptionComplexAccount: true,
        isFindCarsConsumptionComplexAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_CARS_CONSUMPTION_COMPLEX_ACCOUNT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isFindingCarsConsumptionComplexAccount: false,
        isFindCarsConsumptionComplexAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_CARS_CONSUMPTION_COMPLEX_ACCOUNT_SUCCESS: {
      return {
        ...state,
        carsConsumptionComplexAccount: action.payload.data,
        error: null,
        isFindingCarsConsumptionComplexAccount: false,
        isFindCarsConsumptionComplexAccountCompleted: true,
      };
    }

    //#endregion FIND_CARS_CONSUMPTION_COMPLEX_ACCOUNT

    //#region FIND_POOLED_DEPRECIATION_OF_ASSETS_UNDER_FINANCE_LEASE_CONTRACTS_ACCOUNT

    case SystemAccountsActionType.FIND_POOLED_DEPRECIATION_OF_ASSETS_UNDER_FINANCE_LEASE_CONTRACTS_ACCOUNT: {
      return {
        ...state,
        pooledDepreciationOfAssetsUnderFinanceLeaseContractsAccount: null,
        error: null,
        isFindingPooledDepreciationOfAssetsUnderFinanceLeaseContractsAccount: true,
        isFindPooledDepreciationOfAssetsUnderFinanceLeaseContractsAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_POOLED_DEPRECIATION_OF_ASSETS_UNDER_FINANCE_LEASE_CONTRACTS_ACCOUNT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isFindingPooledDepreciationOfAssetsUnderFinanceLeaseContractsAccount: false,
        isFindPooledDepreciationOfAssetsUnderFinanceLeaseContractsAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_POOLED_DEPRECIATION_OF_ASSETS_UNDER_FINANCE_LEASE_CONTRACTS_ACCOUNT_SUCCESS: {
      return {
        ...state,
        pooledDepreciationOfAssetsUnderFinanceLeaseContractsAccount: action.payload.data,
        error: null,
        isFindingPooledDepreciationOfAssetsUnderFinanceLeaseContractsAccount: false,
        isFindPooledDepreciationOfAssetsUnderFinanceLeaseContractsAccountCompleted: true,
      };
    }

    //#endregion  FIND_POOLED_DEPRECIATION_OF_ASSETS_UNDER_FINANCE_LEASE_CONTRACTS_ACCOUNT

    //#region FIND_FAME_ACCOUNT

    case SystemAccountsActionType.FIND_FAME_ACCOUNT: {
      return {
        ...state,
        fameAccount: null,
        error: null,
        isFindingFameAccount: true,
        isFindFameAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_FAME_ACCOUNT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isFindingFameAccount: false,
        isFindFameAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_FAME_ACCOUNT_SUCCESS: {
      return {
        ...state,
        fameAccount: action.payload.data,
        error: null,
        isFindingFameAccount: false,
        isFindFameAccountCompleted: true,
      };
    }

    //#endregion FIND_FAME_ACCOUNT

    //#region FIND_SOFTWARE_AND_SYSTEMS_ACCOUNT

    case SystemAccountsActionType.FIND_SOFTWARE_AND_SYSTEMS_ACCOUNT: {
      return {
        ...state,
        softwareAndSystemsAccount: null,
        error: null,
        isFindingSoftwareAndSystemsAccount: true,
        isFindSoftwareAndSystemsAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_SOFTWARE_AND_SYSTEMS_ACCOUNT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isFindingSoftwareAndSystemsAccount: false,
        isFindSoftwareAndSystemsAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_SOFTWARE_AND_SYSTEMS_ACCOUNT_SUCCESS: {
      return {
        ...state,
        softwareAndSystemsAccount: action.payload.data,
        error: null,
        isFindingSoftwareAndSystemsAccount: false,
        isFindSoftwareAndSystemsAccountCompleted: true,
      };
    }

    //#endregion FIND_SOFTWARE_AND_SYSTEMS_ACCOUNT

    //#region FIND_PATENTS_AND_TRADEMARKS_ACCOUNT

    case SystemAccountsActionType.FIND_PATENTS_AND_TRADEMARKS_ACCOUNT: {
      return {
        ...state,
        patentsAndTrademarksAccount: null,
        error: null,
        isFindingPatentsAndTrademarksAccount: true,
        isFindPatentsAndTrademarksAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_PATENTS_AND_TRADEMARKS_ACCOUNT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isFindingPatentsAndTrademarksAccount: false,
        isFindPatentsAndTrademarksAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_PATENTS_AND_TRADEMARKS_ACCOUNT_SUCCESS: {
      return {
        ...state,
        patentsAndTrademarksAccount: action.payload.data,
        error: null,
        isFindingPatentsAndTrademarksAccount: false,
        isFindPatentsAndTrademarksAccountCompleted: true,
      };
    }

    //#endregion FIND_PATENTS_AND_TRADEMARKS_ACCOUNT

    //#region FIND_FAME_EXTINGUISHING_COMPLEX_ACCOUNT

    case SystemAccountsActionType.FIND_FAME_EXTINGUISHING_COMPLEX_ACCOUNT: {
      return {
        ...state,
        fameExtinguishingComplexAccount: null,
        error: null,
        isFindingFameExtinguishingComplexAccount: true,
        isFindFameExtinguishingComplexAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_FAME_EXTINGUISHING_COMPLEX_ACCOUNT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isFindingFameExtinguishingComplexAccount: false,
        isFindFameExtinguishingComplexAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_FAME_EXTINGUISHING_COMPLEX_ACCOUNT_SUCCESS: {
      return {
        ...state,
        fameExtinguishingComplexAccount: action.payload.data,
        error: null,
        isFindingFameExtinguishingComplexAccount: false,
        isFindFameExtinguishingComplexAccountCompleted: true,
      };
    }

    //#endregion FIND_FAME_EXTINGUISHING_COMPLEX_ACCOUNT

    //#region FIND_SOFTWARE_AND_HARDWARE_EXTINGUISHING_COMPLEX_ACCOUNT

    case SystemAccountsActionType.FIND_SOFTWARE_AND_HARDWARE_EXTINGUISHING_COMPLEX_ACCOUNT: {
      return {
        ...state,
        softwareAndHardwareExtinguishingComplexAccount: null,
        error: null,
        isFindingSoftwareAndHardwareExtinguishingComplexAccount: true,
        isFindSoftwareAndHardwareExtinguishingComplexAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_SOFTWARE_AND_HARDWARE_EXTINGUISHING_COMPLEX_ACCOUNT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isFindingSoftwareAndHardwareExtinguishingComplexAccount: false,
        isFindSoftwareAndHardwareExtinguishingComplexAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_SOFTWARE_AND_HARDWARE_EXTINGUISHING_COMPLEX_ACCOUNT_SUCCESS: {
      return {
        ...state,
        softwareAndHardwareExtinguishingComplexAccount: action.payload.data,
        error: null,
        isFindingSoftwareAndHardwareExtinguishingComplexAccount: false,
        isFindSoftwareAndHardwareExtinguishingComplexAccountCompleted: true,
      };
    }

    //#endregion FIND_SOFTWARE_AND_HARDWARE_EXTINGUISHING_COMPLEX_ACCOUNT

    //#region FIND_PATENT_AND_TRADEMARK_EXTINGUISHING_COMPLEX_ACCOUNT

    case SystemAccountsActionType.FIND_PATENT_AND_TRADEMARK_EXTINGUISHING_COMPLEX_ACCOUNT: {
      return {
        ...state,
        patentAndTrademarkExtinguishingComplexAccount: null,
        error: null,
        isFindingPatentAndTrademarkExtinguishingComplexAccount: true,
        isFindPatentAndTrademarkExtinguishingComplexAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_PATENT_AND_TRADEMARK_EXTINGUISHING_COMPLEX_ACCOUNT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isFindingPatentAndTrademarkExtinguishingComplexAccount: false,
        isFindPatentAndTrademarkExtinguishingComplexAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_PATENT_AND_TRADEMARK_EXTINGUISHING_COMPLEX_ACCOUNT_SUCCESS: {
      return {
        ...state,
        patentAndTrademarkExtinguishingComplexAccount: action.payload.data,
        error: null,
        isFindingPatentAndTrademarkExtinguishingComplexAccount: false,
        isFindPatentAndTrademarkExtinguishingComplexAccountCompleted: true,
      };
    }

    //#endregion FIND_PATENT_AND_TRADEMARK_EXTINGUISHING_COMPLEX_ACCOUNT

    //#region FIND_REAL_ESTATE_INVESTMENT_LANDS_ACCOUNT

    case SystemAccountsActionType.FIND_REAL_ESTATE_INVESTMENT_LANDS_ACCOUNT: {
      return {
        ...state,
        realEstateInvestmentLandsAccount: null,
        error: null,
        isFindingRealEstateInvestmentLandsAccount: true,
        isFindRealEstateInvestmentLandsAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_REAL_ESTATE_INVESTMENT_LANDS_ACCOUNT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isFindingRealEstateInvestmentLandsAccount: false,
        isFindRealEstateInvestmentLandsAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_REAL_ESTATE_INVESTMENT_LANDS_ACCOUNT_SUCCESS: {
      return {
        ...state,
        realEstateInvestmentLandsAccount: action.payload.data,
        error: null,
        isFindingRealEstateInvestmentLandsAccount: false,
        isFindRealEstateInvestmentLandsAccountCompleted: true,
      };
    }

    //#endregion FIND_REAL_ESTATE_INVESTMENT_LANDS_ACCOUNT

    //#region FIND_REAL_ESTATE_INVESTMENT_BUILDINGS_ACCOUNT

    case SystemAccountsActionType.FIND_REAL_ESTATE_INVESTMENT_BUILDINGS_ACCOUNT: {
      return {
        ...state,
        realEstateInvestmentBuildingsAccount: null,
        error: null,
        isFindingRealEstateInvestmentBuildingsAccount: true,
        isFindRealEstateInvestmentBuildingsAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_REAL_ESTATE_INVESTMENT_BUILDINGS_ACCOUNT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isFindingRealEstateInvestmentBuildingsAccount: false,
        isFindRealEstateInvestmentBuildingsAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_REAL_ESTATE_INVESTMENT_BUILDINGS_ACCOUNT_SUCCESS: {
      return {
        ...state,
        realEstateInvestmentBuildingsAccount: action.payload.data,
        error: null,
        isFindingRealEstateInvestmentBuildingsAccount: false,
        isFindRealEstateInvestmentBuildingsAccountCompleted: true,
      };
    }

    //#endregion FIND_REAL_ESTATE_INVESTMENT_BUILDINGS_ACCOUNT

    //#region FIND_REAL_ESTATE_INVESTMENT_BUILDING_CONSUMPTION_COMPLEX_ACCOUNT

    case SystemAccountsActionType.FIND_REAL_ESTATE_INVESTMENT_BUILDING_CONSUMPTION_COMPLEX_ACCOUNT: {
      return {
        ...state,
        realEstateInvestmentBuildingConsumptionComplexAccount: null,
        error: null,
        isFindingRealEstateInvestmentBuildingConsumptionComplexAccount: true,
        isFindRealEstateInvestmentBuildingConsumptionComplexAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_REAL_ESTATE_INVESTMENT_BUILDING_CONSUMPTION_COMPLEX_ACCOUNT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isFindingRealEstateInvestmentBuildingConsumptionComplexAccount: false,
        isFindRealEstateInvestmentBuildingConsumptionComplexAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_REAL_ESTATE_INVESTMENT_BUILDING_CONSUMPTION_COMPLEX_ACCOUNT_SUCCESS: {
      return {
        ...state,
        realEstateInvestmentBuildingConsumptionComplexAccount: action.payload.data,
        error: null,
        isFindingRealEstateInvestmentBuildingConsumptionComplexAccount: false,
        isFindRealEstateInvestmentBuildingConsumptionComplexAccountCompleted: true,
      };
    }

    //#endregion FIND_REAL_ESTATE_INVESTMENT_BUILDING_CONSUMPTION_COMPLEX_ACCOUNT

    //#region FIND_INVESTMENTS_IN_SUBSIDIARIES_ACCOUNT

    case SystemAccountsActionType.FIND_INVESTMENTS_IN_SUBSIDIARIES_ACCOUNT: {
      return {
        ...state,
        investmentsInSubsidiariesAccount: null,
        error: null,
        isFindingInvestmentsInSubsidiariesAccount: true,
        isFindInvestmentsInSubsidiariesAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_INVESTMENTS_IN_SUBSIDIARIES_ACCOUNT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isFindingInvestmentsInSubsidiariesAccount: false,
        isFindInvestmentsInSubsidiariesAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_INVESTMENTS_IN_SUBSIDIARIES_ACCOUNT_SUCCESS: {
      return {
        ...state,
        investmentsInSubsidiariesAccount: action.payload.data,
        error: null,
        isFindingInvestmentsInSubsidiariesAccount: false,
        isFindInvestmentsInSubsidiariesAccountCompleted: true,
      };
    }

    //#endregion FIND_INVESTMENTS_IN_SUBSIDIARIES_ACCOUNT

    //#region FIND_INVESTMENTS_IN_ASSOCIATES_ACCOUNT

    case SystemAccountsActionType.FIND_INVESTMENTS_IN_ASSOCIATES_ACCOUNT: {
      return {
        ...state,
        investmentsInAssociatesAccount: null,
        error: null,
        isFindingInvestmentsInAssociatesAccount: true,
        isFindInvestmentsInAssociatesAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_INVESTMENTS_IN_ASSOCIATES_ACCOUNT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isFindingInvestmentsInAssociatesAccount: false,
        isFindInvestmentsInAssociatesAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_INVESTMENTS_IN_ASSOCIATES_ACCOUNT_SUCCESS: {
      return {
        ...state,
        investmentsInAssociatesAccount: action.payload.data,
        error: null,
        isFindingInvestmentsInAssociatesAccount: false,
        isFindInvestmentsInAssociatesAccountCompleted: true,
      };
    }

    //#endregion FIND_INVESTMENTS_IN_ASSOCIATES_ACCOUNT

    //#region FIND_INVESTMENTS_IN_JOINT_VENTURES_ACCOUNT

    case SystemAccountsActionType.FIND_INVESTMENTS_IN_JOINT_VENTURES_ACCOUNT: {
      return {
        ...state,
        investmentsInJointVenturesAccount: null,
        error: null,
        isFindingInvestmentsInJointVenturesAccount: true,
        isFindInvestmentsInJointVenturesAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_INVESTMENTS_IN_JOINT_VENTURES_ACCOUNT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isFindingInvestmentsInJointVenturesAccount: false,
        isFindInvestmentsInJointVenturesAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_INVESTMENTS_IN_JOINT_VENTURES_ACCOUNT_SUCCESS: {
      return {
        ...state,
        investmentsInJointVenturesAccount: action.payload.data,
        error: null,
        isFindingInvestmentsInJointVenturesAccount: false,
        isFindInvestmentsInJointVenturesAccountCompleted: true,
      };
    }

    //#endregion FIND_INVESTMENTS_IN_JOINT_VENTURES_ACCOUNT

    //#region FIND_LOAN_INSTALLMENTS_THE_CURRENT_PART_ACCOUNT

    case SystemAccountsActionType.FIND_LOAN_INSTALLMENTS_THE_CURRENT_PART_ACCOUNT: {
      return {
        ...state,
        loanInstallmentsTheCurrentPartAccount: null,
        error: null,
        isFindingLoanInstallmentsTheCurrentPartAccount: true,
        isFindLoanInstallmentsTheCurrentPartAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_LOAN_INSTALLMENTS_THE_CURRENT_PART_ACCOUNT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isFindingLoanInstallmentsTheCurrentPartAccount: false,
        isFindLoanInstallmentsTheCurrentPartAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_LOAN_INSTALLMENTS_THE_CURRENT_PART_ACCOUNT_SUCCESS: {
      return {
        ...state,
        loanInstallmentsTheCurrentPartAccount: action.payload.data,
        error: null,
        isFindingLoanInstallmentsTheCurrentPartAccount: false,
        isFindLoanInstallmentsTheCurrentPartAccountCompleted: true,
      };
    }

    //#endregion FIND_LOAN_INSTALLMENTS_THE_CURRENT_PART_ACCOUNT

    //#region FIND_CURRENT_CONTRACTS_FOR_RENT_THE_CURRENT_PART_ACCOUNT

    case SystemAccountsActionType.FIND_CURRENT_CONTRACTS_FOR_RENT_THE_CURRENT_PART_ACCOUNT: {
      return {
        ...state,
        currentContractsForRentTheCurrentPartAccount: null,
        error: null,
        isFindingCurrentContractsForRentTheCurrentPartAccount: true,
        isFindCurrentContractsForRentTheCurrentPartAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_CURRENT_CONTRACTS_FOR_RENT_THE_CURRENT_PART_ACCOUNT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isFindingCurrentContractsForRentTheCurrentPartAccount: false,
        isFindCurrentContractsForRentTheCurrentPartAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_CURRENT_CONTRACTS_FOR_RENT_THE_CURRENT_PART_ACCOUNT_SUCCESS: {
      return {
        ...state,
        currentContractsForRentTheCurrentPartAccount: action.payload.data,
        error: null,
        isFindingCurrentContractsForRentTheCurrentPartAccount: false,
        isFindCurrentContractsForRentTheCurrentPartAccountCompleted: true,
      };
    }

    //#endregion FIND_CURRENT_CONTRACTS_FOR_RENT_THE_CURRENT_PART_ACCOUNT

    //#region FIND_TRADE_CREDITORS_ACCOUNT

    case SystemAccountsActionType.FIND_TRADE_CREDITORS_ACCOUNT: {
      return {
        ...state,
        tradeCreditorsAccount: null,
        error: null,
        isFindingTradeCreditorsAccount: true,
        isFindTradeCreditorsAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_TRADE_CREDITORS_ACCOUNT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isFindingTradeCreditorsAccount: false,
        isFindTradeCreditorsAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_TRADE_CREDITORS_ACCOUNT_SUCCESS: {
      return {
        ...state,
        tradeCreditorsAccount: action.payload.data,
        error: null,
        isFindingTradeCreditorsAccount: false,
        isFindTradeCreditorsAccountCompleted: true,
      };
    }

    //#endregion FIND_TRADE_CREDITORS_ACCOUNT

    //#region FIND_VENDORS_BROKER_ACCOUNT

    case SystemAccountsActionType.FIND_VENDORS_BROKER_ACCOUNT: {
      return {
        ...state,
        vendorsBrokerAccount: null,
        error: null,
        isFindingVendorsBrokerAccount: true,
        isFindVendorsBrokerAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_VENDORS_BROKER_ACCOUNT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isFindingVendorsBrokerAccount: false,
        isFindVendorsBrokerAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_VENDORS_BROKER_ACCOUNT_SUCCESS: {
      return {
        ...state,
        vendorsBrokerAccount: action.payload.data,
        error: null,
        isFindingVendorsBrokerAccount: false,
        isFindVendorsBrokerAccountCompleted: true,
      };
    }

    //#endregion FIND_VENDORS_BROKER_ACCOUNT

    //#region FIND_CREDIT_FACILITIES_OVERDRAFT_ACCOUNT

    case SystemAccountsActionType.FIND_CREDIT_FACILITIES_OVERDRAFT_ACCOUNT: {
      return {
        ...state,
        creditFacilitiesOverdraftAccount: null,
        error: null,
        isFindingCreditFacilitiesOverdraftAccount: true,
        isFindCreditFacilitiesOverdraftAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_CREDIT_FACILITIES_OVERDRAFT_ACCOUNT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isFindingCreditFacilitiesOverdraftAccount: false,
        isFindCreditFacilitiesOverdraftAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_CREDIT_FACILITIES_OVERDRAFT_ACCOUNT_SUCCESS: {
      return {
        ...state,
        creditFacilitiesOverdraftAccount: action.payload.data,
        error: null,
        isFindingCreditFacilitiesOverdraftAccount: false,
        isFindCreditFacilitiesOverdraftAccountCompleted: true,
      };
    }

    //#endregion FIND_CREDIT_FACILITIES_OVERDRAFT_ACCOUNT

    //#region FIND_CREDITORS_WITHHOLDING_BALANCES_ACCOUNT

    case SystemAccountsActionType.FIND_CREDITORS_WITHHOLDING_BALANCES_ACCOUNT: {
      return {
        ...state,
        creditorsWithholdingBalancesAccount: null,
        error: null,
        isFindingCreditorsWithholdingBalancesAccount: true,
        isFindCreditorsWithholdingBalancesAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_CREDITORS_WITHHOLDING_BALANCES_ACCOUNT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isFindingCreditorsWithholdingBalancesAccount: false,
        isFindCreditorsWithholdingBalancesAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_CREDITORS_WITHHOLDING_BALANCES_ACCOUNT_SUCCESS: {
      return {
        ...state,
        creditorsWithholdingBalancesAccount: action.payload.data,
        error: null,
        isFindingCreditorsWithholdingBalancesAccount: false,
        isFindCreditorsWithholdingBalancesAccountCompleted: true,
      };
    }

    //#endregion FIND_CREDITORS_WITHHOLDING_BALANCES_ACCOUNT

    //#region FIND_PAYABLE_EXPENSES_ACCOUNT

    case SystemAccountsActionType.FIND_PAYABLE_EXPENSES_ACCOUNT: {
      return {
        ...state,
        payableExpensesAccount: null,
        error: null,
        isFindingPayableExpensesAccount: true,
        isFindPayableExpensesAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_PAYABLE_EXPENSES_ACCOUNT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isFindingPayableExpensesAccount: false,
        isFindPayableExpensesAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_PAYABLE_EXPENSES_ACCOUNT_SUCCESS: {
      return {
        ...state,
        payableExpensesAccount: action.payload.data,
        error: null,
        isFindingPayableExpensesAccount: false,
        isFindPayableExpensesAccountCompleted: true,
      };
    }

    //#endregion FIND_PAYABLE_EXPENSES_ACCOUNT

    //#region FIND_EMPLOYEES_ENTITLEMENTS_SALARIES_ACCOUNT

    case SystemAccountsActionType.FIND_EMPLOYEES_ENTITLEMENTS_SALARIES_ACCOUNT: {
      return {
        ...state,
        employeesEntitlementsSalariesAccount: null,
        error: null,
        isFindingEmployeesEntitlementsSalariesAccount: true,
        isFindEmployeesEntitlementsSalariesAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_EMPLOYEES_ENTITLEMENTS_SALARIES_ACCOUNT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isFindingEmployeesEntitlementsSalariesAccount: false,
        isFindEmployeesEntitlementsSalariesAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_EMPLOYEES_ENTITLEMENTS_SALARIES_ACCOUNT_SUCCESS: {
      return {
        ...state,
        employeesEntitlementsSalariesAccount: action.payload.data,
        error: null,
        isFindingEmployeesEntitlementsSalariesAccount: false,
        isFindEmployeesEntitlementsSalariesAccountCompleted: true,
      };
    }

    //#endregion FIND_EMPLOYEES_ENTITLEMENTS_SALARIES_ACCOUNT

    //#region FIND_EMPLOYEES_ENTITLEMENTS_BENEFITS_ACCOUNT

    case SystemAccountsActionType.FIND_EMPLOYEES_ENTITLEMENTS_BENEFITS_ACCOUNT: {
      return {
        ...state,
        employeesEntitlementsBenefitsAccount: null,
        error: null,
        isFindingEmployeesEntitlementsBenefitsAccount: true,
        isFindEmployeesEntitlementsBenefitsAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_EMPLOYEES_ENTITLEMENTS_BENEFITS_ACCOUNT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isFindingEmployeesEntitlementsBenefitsAccount: false,
        isFindEmployeesEntitlementsBenefitsAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_EMPLOYEES_ENTITLEMENTS_BENEFITS_ACCOUNT_SUCCESS: {
      return {
        ...state,
        employeesEntitlementsBenefitsAccount: action.payload.data,
        error: null,
        isFindingEmployeesEntitlementsBenefitsAccount: false,
        isFindEmployeesEntitlementsBenefitsAccountCompleted: true,
      };
    }

    //#endregion FIND_EMPLOYEES_ENTITLEMENTS_BENEFITS_ACCOUNT

    //#region FIND_EMPLOYEES_ENTITLEMENTS_TRAVELING_TICKETS_ACCOUNT

    case SystemAccountsActionType.FIND_EMPLOYEES_ENTITLEMENTS_TRAVELING_TICKETS_ACCOUNT: {
      return {
        ...state,
        employeesEntitlementsTravelingTicketsAccount: null,
        error: null,
        isFindingEmployeesEntitlementsTravelingTicketsAccount: true,
        isFindEmployeesEntitlementsTravelingTicketsAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_EMPLOYEES_ENTITLEMENTS_TRAVELING_TICKETS_ACCOUNT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isFindingEmployeesEntitlementsTravelingTicketsAccount: false,
        isFindEmployeesEntitlementsTravelingTicketsAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_EMPLOYEES_ENTITLEMENTS_TRAVELING_TICKETS_ACCOUNT_SUCCESS: {
      return {
        ...state,
        employeesEntitlementsTravelingTicketsAccount: action.payload.data,
        error: null,
        isFindingEmployeesEntitlementsTravelingTicketsAccount: false,
        isFindEmployeesEntitlementsTravelingTicketsAccountCompleted: true,
      };
    }

    //#endregion FIND_EMPLOYEES_ENTITLEMENTS_TRAVELING_TICKETS_ACCOUNT

    //#region FIND_ADVANCE_REVENUE_ACCOUNT

    case SystemAccountsActionType.FIND_ADVANCE_REVENUE_ACCOUNT: {
      return {
        ...state,
        advanceRevenueAccount: null,
        error: null,
        isFindingAdvanceRevenueAccount: true,
        isFindAdvanceRevenueAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_ADVANCE_REVENUE_ACCOUNT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isFindingAdvanceRevenueAccount: false,
        isFindAdvanceRevenueAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_ADVANCE_REVENUE_ACCOUNT_SUCCESS: {
      return {
        ...state,
        advanceRevenueAccount: action.payload.data,
        error: null,
        isFindingAdvanceRevenueAccount: false,
        isFindAdvanceRevenueAccountCompleted: true,
      };
    }

    //#endregion FIND_ADVANCE_REVENUE_ACCOUNT

    //#region FIND_VALUE_ADDED_TAX_CREDIT_ACCOUNT

    case SystemAccountsActionType.FIND_VALUE_ADDED_TAX_CREDIT_ACCOUNT: {
      return {
        ...state,
        valueAddedTaxCreditAccount: null,
        error: null,
        isFindingValueAddedTaxCreditAccount: true,
        isFindValueAddedTaxCreditAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_VALUE_ADDED_TAX_CREDIT_ACCOUNT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isFindingValueAddedTaxCreditAccount: false,
        isFindValueAddedTaxCreditAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_VALUE_ADDED_TAX_CREDIT_ACCOUNT_SUCCESS: {
      return {
        ...state,
        valueAddedTaxCreditAccount: action.payload.data,
        error: null,
        isFindingValueAddedTaxCreditAccount: false,
        isFindValueAddedTaxCreditAccountCompleted: true,
      };
    }

    //#endregion FIND_VALUE_ADDED_TAX_CREDIT_ACCOUNT

    //#region FIND_RECEIVABLES_STAFF_ACCOUNT

    case SystemAccountsActionType.FIND_RECEIVABLES_STAFF_ACCOUNT: {
      return {
        ...state,
        receivablesStaffAccount: null,
        error: null,
        isFindingReceivablesStaffAccount: true,
        isFindReceivablesStaffAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_RECEIVABLES_STAFF_ACCOUNT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isFindingReceivablesStaffAccount: false,
        isFindReceivablesStaffAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_RECEIVABLES_STAFF_ACCOUNT_SUCCESS: {
      return {
        ...state,
        receivablesStaffAccount: action.payload.data,
        error: null,
        isFindingReceivablesStaffAccount: false,
        isFindReceivablesStaffAccountCompleted: true,
      };
    }

    //#endregion FIND_RECEIVABLES_STAFF_ACCOUNT

    //#region FIND_OTHER_CURRENT_LIABILITIES_RELATED_PARTIES_ACCOUNT

    case SystemAccountsActionType.FIND_OTHER_CURRENT_LIABILITIES_RELATED_PARTIES_ACCOUNT: {
      return {
        ...state,
        otherCurrentLiabilitiesRelatedPartiesAccount: null,
        error: null,
        isFindingOtherCurrentLiabilitiesRelatedPartiesAccount: true,
        isFindOtherCurrentLiabilitiesRelatedPartiesAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_OTHER_CURRENT_LIABILITIES_RELATED_PARTIES_ACCOUNT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isFindingOtherCurrentLiabilitiesRelatedPartiesAccount: false,
        isFindOtherCurrentLiabilitiesRelatedPartiesAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_OTHER_CURRENT_LIABILITIES_RELATED_PARTIES_ACCOUNT_SUCCESS: {
      return {
        ...state,
        otherCurrentLiabilitiesRelatedPartiesAccount: action.payload.data,
        error: null,
        isFindingOtherCurrentLiabilitiesRelatedPartiesAccount: false,
        isFindOtherCurrentLiabilitiesRelatedPartiesAccountCompleted: true,
      };
    }

    //#endregion FIND_OTHER_CURRENT_LIABILITIES_RELATED_PARTIES_ACCOUNT

    //#region FIND_MISCELLANEOUS_CREDITORS_ACCOUNT

    case SystemAccountsActionType.FIND_MISCELLANEOUS_CREDITORS_ACCOUNT: {
      return {
        ...state,
        miscellaneousCreditorsAccount: null,
        error: null,
        isFindingMiscellaneousCreditorsAccount: true,
        isFindMiscellaneousCreditorsAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_MISCELLANEOUS_CREDITORS_ACCOUNT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isFindingMiscellaneousCreditorsAccount: false,
        isFindMiscellaneousCreditorsAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_MISCELLANEOUS_CREDITORS_ACCOUNT_SUCCESS: {
      return {
        ...state,
        miscellaneousCreditorsAccount: action.payload.data,
        error: null,
        isFindingMiscellaneousCreditorsAccount: false,
        isFindMiscellaneousCreditorsAccountCompleted: true,
      };
    }

    //#endregion FIND_MISCELLANEOUS_CREDITORS_ACCOUNT

    //#region FIND_DIVIDEND_PAYABLE_ACCOUNT

    case SystemAccountsActionType.FIND_DIVIDEND_PAYABLE_ACCOUNT: {
      return {
        ...state,
        dividendPayableAccount: null,
        error: null,
        isFindingDividendPayableAccount: true,
        isFindDividendPayableAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_DIVIDEND_PAYABLE_ACCOUNT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isFindingDividendPayableAccount: false,
        isFindDividendPayableAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_DIVIDEND_PAYABLE_ACCOUNT_SUCCESS: {
      return {
        ...state,
        dividendPayableAccount: action.payload.data,
        error: null,
        isFindingDividendPayableAccount: false,
        isFindDividendPayableAccountCompleted: true,
      };
    }

    //#endregion FIND_DIVIDEND_PAYABLE_ACCOUNT

    //#region FIND_ZAKAT_PROVISION_ACCOUNT

    case SystemAccountsActionType.FIND_ZAKAT_PROVISION_ACCOUNT: {
      return {
        ...state,
        zakatProvisionAccount: null,
        error: null,
        isFindingZakatProvisionAccount: true,
        isFindZakatProvisionAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_ZAKAT_PROVISION_ACCOUNT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isFindingZakatProvisionAccount: false,
        isFindZakatProvisionAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_ZAKAT_PROVISION_ACCOUNT_SUCCESS: {
      return {
        ...state,
        zakatProvisionAccount: action.payload.data,
        error: null,
        isFindingZakatProvisionAccount: false,
        isFindZakatProvisionAccountCompleted: true,
      };
    }

    //#endregion FIND_ZAKAT_PROVISION_ACCOUNT

    //#region FIND_INCOME_TAX_PROVISION_ACCOUNT

    case SystemAccountsActionType.FIND_INCOME_TAX_PROVISION_ACCOUNT: {
      return {
        ...state,
        incomeTaxProvisionAccount: null,
        error: null,
        isFindingIncomeTaxProvisionAccount: true,
        isFindIncomeTaxProvisionAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_INCOME_TAX_PROVISION_ACCOUNT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isFindingIncomeTaxProvisionAccount: false,
        isFindIncomeTaxProvisionAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_INCOME_TAX_PROVISION_ACCOUNT_SUCCESS: {
      return {
        ...state,
        incomeTaxProvisionAccount: action.payload.data,
        error: null,
        isFindingIncomeTaxProvisionAccount: false,
        isFindIncomeTaxProvisionAccountCompleted: true,
      };
    }

    //#endregion FIND_INCOME_TAX_PROVISION_ACCOUNT

    //#region FIND_PROVISION_FOR_WITHHOLDING_TAX_ACCOUNT

    case SystemAccountsActionType.FIND_PROVISION_FOR_WITHHOLDING_TAX_ACCOUNT: {
      return {
        ...state,
        provisionForWithholdingTaxAccount: null,
        error: null,
        isFindingProvisionForWithholdingTaxAccount: true,
        isFindProvisionForWithholdingTaxAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_PROVISION_FOR_WITHHOLDING_TAX_ACCOUNT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isFindingProvisionForWithholdingTaxAccount: false,
        isFindProvisionForWithholdingTaxAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_PROVISION_FOR_WITHHOLDING_TAX_ACCOUNT_SUCCESS: {
      return {
        ...state,
        provisionForWithholdingTaxAccount: action.payload.data,
        error: null,
        isFindingProvisionForWithholdingTaxAccount: false,
        isFindProvisionForWithholdingTaxAccountCompleted: true,
      };
    }

    //#endregion FIND_PROVISION_FOR_WITHHOLDING_TAX_ACCOUNT

    //#region FIND_BANK_LOANS_ACCOUNT

    case SystemAccountsActionType.FIND_BANK_LOANS_ACCOUNT: {
      return {
        ...state,
        bankLoansAccount: null,
        error: null,
        isFindingBankLoansAccount: true,
        isFindBankLoansAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_BANK_LOANS_ACCOUNT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isFindingBankLoansAccount: false,
        isFindBankLoansAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_BANK_LOANS_ACCOUNT_SUCCESS: {
      return {
        ...state,
        bankLoansAccount: action.payload.data,
        error: null,
        isFindingBankLoansAccount: false,
        isFindBankLoansAccountCompleted: true,
      };
    }

    //#endregion FIND_BANK_LOANS_ACCOUNT

    //#region FIND_LEASE_LIABILITIES_NON_CURRENT_PORTION_ACCOUNT

    case SystemAccountsActionType.FIND_LEASE_LIABILITIES_NON_CURRENT_PORTION_ACCOUNT: {
      return {
        ...state,
        leaseLiabilitiesNonCurrentPortionAccount: null,
        error: null,
        isFindingLeaseLiabilitiesNonCurrentPortionAccount: true,
        isFindLeaseLiabilitiesNonCurrentPortionAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_LEASE_LIABILITIES_NON_CURRENT_PORTION_ACCOUNT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isFindingLeaseLiabilitiesNonCurrentPortionAccount: false,
        isFindLeaseLiabilitiesNonCurrentPortionAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_LEASE_LIABILITIES_NON_CURRENT_PORTION_ACCOUNT_SUCCESS: {
      return {
        ...state,
        leaseLiabilitiesNonCurrentPortionAccount: action.payload.data,
        error: null,
        isFindingLeaseLiabilitiesNonCurrentPortionAccount: false,
        isFindLeaseLiabilitiesNonCurrentPortionAccountCompleted: true,
      };
    }

    //#endregion FIND_LEASE_LIABILITIES_NON_CURRENT_PORTION_ACCOUNT

    //#region FIND_PROVISION_FOR_END_OF_SEVERANCE_PAY_ACCOUNT

    case SystemAccountsActionType.FIND_PROVISION_FOR_END_OF_SEVERANCE_PAY_ACCOUNT: {
      return {
        ...state,
        provisionForEndOfSeverancePayAccount: null,
        error: null,
        isFindingProvisionForEndOfSeverancePayAccount: true,
        isFindProvisionForEndOfSeverancePayAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_PROVISION_FOR_END_OF_SEVERANCE_PAY_ACCOUNT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isFindingProvisionForEndOfSeverancePayAccount: false,
        isFindProvisionForEndOfSeverancePayAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_PROVISION_FOR_END_OF_SEVERANCE_PAY_ACCOUNT_SUCCESS: {
      return {
        ...state,
        provisionForEndOfSeverancePayAccount: action.payload.data,
        error: null,
        isFindingProvisionForEndOfSeverancePayAccount: false,
        isFindProvisionForEndOfSeverancePayAccountCompleted: true,
      };
    }

    //#endregion FIND_PROVISION_FOR_END_OF_SEVERANCE_PAY_ACCOUNT

    //#region FIND_CAPITAL_ACCOUNT

    case SystemAccountsActionType.FIND_CAPITAL_ACCOUNT: {
      return {
        ...state,
        capitalAccount: null,
        error: null,
        isFindingCapitalAccount: true,
        isFindCapitalAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_CAPITAL_ACCOUNT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isFindingCapitalAccount: false,
        isFindCapitalAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_CAPITAL_ACCOUNT_SUCCESS: {
      return {
        ...state,
        capitalAccount: action.payload.data,
        error: null,
        isFindingCapitalAccount: false,
        isFindCapitalAccountCompleted: true,
      };
    }

    //#endregion FIND_CAPITAL_ACCOUNT

    //#region FIND_REGULAR_RESERVE_ACCOUNT

    case SystemAccountsActionType.FIND_REGULAR_RESERVE_ACCOUNT: {
      return {
        ...state,
        regularReserveAccount: null,
        error: null,
        isFindingRegularReserveAccount: true,
        isFindRegularReserveAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_REGULAR_RESERVE_ACCOUNT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isFindingRegularReserveAccount: false,
        isFindRegularReserveAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_REGULAR_RESERVE_ACCOUNT_SUCCESS: {
      return {
        ...state,
        regularReserveAccount: action.payload.data,
        error: null,
        isFindingRegularReserveAccount: false,
        isFindRegularReserveAccountCompleted: true,
      };
    }

    //#endregion FIND_REGULAR_RESERVE_ACCOUNT

    //#region FIND_GENERAL_RESERVE_ACCOUNT

    case SystemAccountsActionType.FIND_GENERAL_RESERVE_ACCOUNT: {
      return {
        ...state,
        generalReserveAccount: null,
        error: null,
        isFindingGeneralReserveAccount: true,
        isFindGeneralReserveAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_GENERAL_RESERVE_ACCOUNT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isFindingGeneralReserveAccount: false,
        isFindGeneralReserveAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_GENERAL_RESERVE_ACCOUNT_SUCCESS: {
      return {
        ...state,
        generalReserveAccount: action.payload.data,
        error: null,
        isFindingGeneralReserveAccount: false,
        isFindGeneralReserveAccountCompleted: true,
      };
    }

    //#endregion FIND_GENERAL_RESERVE_ACCOUNT

    //#region FIND_RETAINED_EARNINGS_ACCOUNT

    case SystemAccountsActionType.FIND_RETAINED_EARNINGS_ACCOUNT: {
      return {
        ...state,
        retainedEarningsAccount: null,
        error: null,
        isFindingRetainedEarningsAccount: true,
        isFindRetainedEarningsAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_RETAINED_EARNINGS_ACCOUNT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isFindingRetainedEarningsAccount: false,
        isFindRetainedEarningsAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_RETAINED_EARNINGS_ACCOUNT_SUCCESS: {
      return {
        ...state,
        retainedEarningsAccount: action.payload.data,
        error: null,
        isFindingRetainedEarningsAccount: false,
        isFindRetainedEarningsAccountCompleted: true,
      };
    }

    //#endregion FIND_RETAINED_EARNINGS_ACCOUNT

    //#region FIND_PROFIT_AND_LOSS_BROKER_ACCOUNT

    case SystemAccountsActionType.FIND_PROFIT_AND_LOSS_BROKER_ACCOUNT: {
      return {
        ...state,
        profitAndLossBrokerAccount: null,
        error: null,
        isFindingProfitAndLossBrokerAccount: true,
        isFindProfitAndLossBrokerAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_PROFIT_AND_LOSS_BROKER_ACCOUNT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isFindingProfitAndLossBrokerAccount: false,
        isFindProfitAndLossBrokerAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_PROFIT_AND_LOSS_BROKER_ACCOUNT_SUCCESS: {
      return {
        ...state,
        profitAndLossBrokerAccount: action.payload.data,
        error: null,
        isFindingProfitAndLossBrokerAccount: false,
        isFindProfitAndLossBrokerAccountCompleted: true,
      };
    }

    //#endregion FIND_PROFIT_AND_LOSS_BROKER_ACCOUNT

    //#region FIND_ADDITIONAL_CAPITAL_ACCOUNT

    case SystemAccountsActionType.FIND_ADDITIONAL_CAPITAL_ACCOUNT: {
      return {
        ...state,
        additionalCapitalAccount: null,
        error: null,
        isFindingAdditionalCapitalAccount: true,
        isFindAdditionalCapitalAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_ADDITIONAL_CAPITAL_ACCOUNT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isFindingAdditionalCapitalAccount: false,
        isFindAdditionalCapitalAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_ADDITIONAL_CAPITAL_ACCOUNT_SUCCESS: {
      return {
        ...state,
        additionalCapitalAccount: action.payload.data,
        error: null,
        isFindingAdditionalCapitalAccount: false,
        isFindAdditionalCapitalAccountCompleted: true,
      };
    }

    //#endregion FIND_ADDITIONAL_CAPITAL_ACCOUNT

    //#region FIND_MAIN_ACTIVITIES_SALES_REVENUE_ACCOUNT

    case SystemAccountsActionType.FIND_MAIN_ACTIVITIES_SALES_REVENUE_ACCOUNT: {
      return {
        ...state,
        mainActivitiesSalesRevenueAccount: null,
        error: null,
        isFindingMainActivitiesSalesRevenueAccount: true,
        isFindMainActivitiesSalesRevenueAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_MAIN_ACTIVITIES_SALES_REVENUE_ACCOUNT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isFindingMainActivitiesSalesRevenueAccount: false,
        isFindMainActivitiesSalesRevenueAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_MAIN_ACTIVITIES_SALES_REVENUE_ACCOUNT_SUCCESS: {
      return {
        ...state,
        mainActivitiesSalesRevenueAccount: action.payload.data,
        error: null,
        isFindingMainActivitiesSalesRevenueAccount: false,
        isFindMainActivitiesSalesRevenueAccountCompleted: true,
      };
    }

    //#endregion FIND_MAIN_ACTIVITIES_SALES_REVENUE_ACCOUNT

    //#region FIND_GAIN_LOSS_ON_DISPOSAL_OF_PROPERTY_ACCOUNT

    case SystemAccountsActionType.FIND_GAIN_LOSS_ON_DISPOSAL_OF_PROPERTY_ACCOUNT: {
      return {
        ...state,
        gainLossOnDisposalOfPropertyAccount: null,
        error: null,
        isFindingGainLossOnDisposalOfPropertyAccount: true,
        isFindGainLossOnDisposalOfPropertyAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_GAIN_LOSS_ON_DISPOSAL_OF_PROPERTY_ACCOUNT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isFindingGainLossOnDisposalOfPropertyAccount: false,
        isFindGainLossOnDisposalOfPropertyAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_GAIN_LOSS_ON_DISPOSAL_OF_PROPERTY_ACCOUNT_SUCCESS: {
      return {
        ...state,
        gainLossOnDisposalOfPropertyAccount: action.payload.data,
        error: null,
        isFindingGainLossOnDisposalOfPropertyAccount: false,
        isFindGainLossOnDisposalOfPropertyAccountCompleted: true,
      };
    }

    //#endregion FIND_GAIN_LOSS_ON_DISPOSAL_OF_PROPERTY_ACCOUNT

    //#region FIND_GAIN_LOSS_ON_INVESTMENT_VALUATION_ACCOUNT

    case SystemAccountsActionType.FIND_GAIN_LOSS_ON_INVESTMENT_VALUATION_ACCOUNT: {
      return {
        ...state,
        gainLossOnInvestmentValuationAccount: null,
        error: null,
        isFindingGainLossOnInvestmentValuationAccount: true,
        isFindGainLossOnInvestmentValuationAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_GAIN_LOSS_ON_INVESTMENT_VALUATION_ACCOUNT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isFindingGainLossOnInvestmentValuationAccount: false,
        isFindGainLossOnInvestmentValuationAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_GAIN_LOSS_ON_INVESTMENT_VALUATION_ACCOUNT_SUCCESS: {
      return {
        ...state,
        gainLossOnInvestmentValuationAccount: action.payload.data,
        error: null,
        isFindingGainLossOnInvestmentValuationAccount: false,
        isFindGainLossOnInvestmentValuationAccountCompleted: true,
      };
    }

    //#endregion FIND_GAIN_LOSS_ON_INVESTMENT_VALUATION_ACCOUNT

    //#region FIND_SUPPORT_REVENUE_ACCOUNT

    case SystemAccountsActionType.FIND_SUPPORT_REVENUE_ACCOUNT: {
      return {
        ...state,
        supportRevenueAccount: null,
        error: null,
        isFindingSupportRevenueAccount: true,
        isFindSupportRevenueAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_SUPPORT_REVENUE_ACCOUNT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isFindingSupportRevenueAccount: false,
        isFindSupportRevenueAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_SUPPORT_REVENUE_ACCOUNT_SUCCESS: {
      return {
        ...state,
        supportRevenueAccount: action.payload.data,
        error: null,
        isFindingSupportRevenueAccount: false,
        isFindSupportRevenueAccountCompleted: true,
      };
    }

    //#endregion FIND_SUPPORT_REVENUE_ACCOUNT

    //#region FIND_OTHER_MISCELLANEOUS_INCOME_ACCOUNT

    case SystemAccountsActionType.FIND_OTHER_MISCELLANEOUS_INCOME_ACCOUNT: {
      return {
        ...state,
        otherMiscellaneousIncomeAccount: null,
        error: null,
        isFindingOtherMiscellaneousIncomeAccount: true,
        isFindOtherMiscellaneousIncomeAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_OTHER_MISCELLANEOUS_INCOME_ACCOUNT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isFindingOtherMiscellaneousIncomeAccount: false,
        isFindOtherMiscellaneousIncomeAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_OTHER_MISCELLANEOUS_INCOME_ACCOUNT_SUCCESS: {
      return {
        ...state,
        otherMiscellaneousIncomeAccount: action.payload.data,
        error: null,
        isFindingOtherMiscellaneousIncomeAccount: false,
        isFindOtherMiscellaneousIncomeAccountCompleted: true,
      };
    }

    //#endregion FIND_OTHER_MISCELLANEOUS_INCOME_ACCOUNT

    //#region FIND_USED_OF_MATERIAL_ACCOUNT

    case SystemAccountsActionType.FIND_USED_OF_MATERIAL_ACCOUNT: {
      return {
        ...state,
        usedOfMaterialAccount: null,
        error: null,
        isFindingUsedOfMaterialAccount: true,
        isFindUsedOfMaterialAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_USED_OF_MATERIAL_ACCOUNT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isFindingUsedOfMaterialAccount: false,
        isFindUsedOfMaterialAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_USED_OF_MATERIAL_ACCOUNT_SUCCESS: {
      return {
        ...state,
        usedOfMaterialAccount: action.payload.data,
        error: null,
        isFindingUsedOfMaterialAccount: false,
        isFindUsedOfMaterialAccountCompleted: true,
      };
    }

    //#endregion FIND_USED_OF_MATERIAL_ACCOUNT

    //#region FIND_NON_INVENTORY_MATERIAL_COSTS_ACCOUNT

    case SystemAccountsActionType.FIND_NON_INVENTORY_MATERIAL_COSTS_ACCOUNT: {
      return {
        ...state,
        nonInventoryMaterialCostsAccount: null,
        error: null,
        isFindingNonInventoryMaterialCostsAccount: true,
        isFindNonInventoryMaterialCostsAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_NON_INVENTORY_MATERIAL_COSTS_ACCOUNT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isFindingNonInventoryMaterialCostsAccount: false,
        isFindNonInventoryMaterialCostsAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_NON_INVENTORY_MATERIAL_COSTS_ACCOUNT_SUCCESS: {
      return {
        ...state,
        nonInventoryMaterialCostsAccount: action.payload.data,
        error: null,
        isFindingNonInventoryMaterialCostsAccount: false,
        isFindNonInventoryMaterialCostsAccountCompleted: true,
      };
    }

    //#endregion FIND_NON_INVENTORY_MATERIAL_COSTS_ACCOUNT

    //#region FIND_RENT_ACCOUNT

    case SystemAccountsActionType.FIND_RENT_ACCOUNT: {
      return {
        ...state,
        rentAccount: null,
        error: null,
        isFindingRentAccount: true,
        isFindRentAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_RENT_ACCOUNT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isFindingRentAccount: false,
        isFindRentAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_RENT_ACCOUNT_SUCCESS: {
      return {
        ...state,
        rentAccount: action.payload.data,
        error: null,
        isFindingRentAccount: false,
        isFindRentAccountCompleted: true,
      };
    }

    //#endregion FIND_RENT_ACCOUNT

    //#region FIND_LOCAL_LABOR_ACCOUNT

    case SystemAccountsActionType.FIND_LOCAL_LABOR_ACCOUNT: {
      return {
        ...state,
        localLaborAccount: null,
        error: null,
        isFindingLocalLaborAccount: true,
        isFindLocalLaborAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_LOCAL_LABOR_ACCOUNT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isFindingLocalLaborAccount: false,
        isFindLocalLaborAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_LOCAL_LABOR_ACCOUNT_SUCCESS: {
      return {
        ...state,
        localLaborAccount: action.payload.data,
        error: null,
        isFindingLocalLaborAccount: false,
        isFindLocalLaborAccountCompleted: true,
      };
    }

    //#endregion FIND_LOCAL_LABOR_ACCOUNT

    //#region FIND_SALARIES_AND_WAGES_EMPLOYEE_PROTECTION_ACCOUNT

    case SystemAccountsActionType.FIND_SALARIES_AND_WAGES_EMPLOYEE_PROTECTION_ACCOUNT: {
      return {
        ...state,
        salariesAndWagesEmployeeProtectionAccount: null,
        error: null,
        isFindingSalariesAndWagesEmployeeProtectionAccount: true,
        isFindSalariesAndWagesEmployeeProtectionAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_SALARIES_AND_WAGES_EMPLOYEE_PROTECTION_ACCOUNT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isFindingSalariesAndWagesEmployeeProtectionAccount: false,
        isFindSalariesAndWagesEmployeeProtectionAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_SALARIES_AND_WAGES_EMPLOYEE_PROTECTION_ACCOUNT_SUCCESS: {
      return {
        ...state,
        salariesAndWagesEmployeeProtectionAccount: action.payload.data,
        error: null,
        isFindingSalariesAndWagesEmployeeProtectionAccount: false,
        isFindSalariesAndWagesEmployeeProtectionAccountCompleted: true,
      };
    }

    //#endregion FIND_SALARIES_AND_WAGES_EMPLOYEE_PROTECTION_ACCOUNT

    //#region FIND_BENEFIT_TO_EMPLOYEES_ALL_COSTS_EXCEPT_WAGE_PROTECTION_ELEMENTS_ACCOUNT

    case SystemAccountsActionType.FIND_BENEFIT_TO_EMPLOYEES_ALL_COSTS_EXCEPT_WAGE_PROTECTION_ELEMENTS_ACCOUNT: {
      return {
        ...state,
        benefitToEmployeesAllCostsExceptWageProtectionElementsAccount: null,
        error: null,
        isFindingBenefitToEmployeesAllCostsExceptWageProtectionElementsAccount: true,
        isFindBenefitToEmployeesAllCostsExceptWageProtectionElementsAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_BENEFIT_TO_EMPLOYEES_ALL_COSTS_EXCEPT_WAGE_PROTECTION_ELEMENTS_ACCOUNT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isFindingBenefitToEmployeesAllCostsExceptWageProtectionElementsAccount: false,
        isFindBenefitToEmployeesAllCostsExceptWageProtectionElementsAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_BENEFIT_TO_EMPLOYEES_ALL_COSTS_EXCEPT_WAGE_PROTECTION_ELEMENTS_ACCOUNT_SUCCESS: {
      return {
        ...state,
        benefitToEmployeesAllCostsExceptWageProtectionElementsAccount: action.payload.data,
        error: null,
        isFindingBenefitToEmployeesAllCostsExceptWageProtectionElementsAccount: false,
        isFindBenefitToEmployeesAllCostsExceptWageProtectionElementsAccountCompleted: true,
      };
    }

    //#endregion FIND_BENEFIT_TO_EMPLOYEES_ALL_COSTS_EXCEPT_WAGE_PROTECTION_ELEMENTS_ACCOUNT

    //#region FIND_WORK_MISSIONS_AND_OVERTIME_ACCOUNT

    case SystemAccountsActionType.FIND_WORK_MISSIONS_AND_OVERTIME_ACCOUNT: {
      return {
        ...state,
        workMissionsAndOvertimeAccount: null,
        error: null,
        isFindingWorkMissionsAndOvertimeAccount: true,
        isFindWorkMissionsAndOvertimeAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_WORK_MISSIONS_AND_OVERTIME_ACCOUNT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isFindingWorkMissionsAndOvertimeAccount: false,
        isFindWorkMissionsAndOvertimeAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_WORK_MISSIONS_AND_OVERTIME_ACCOUNT_SUCCESS: {
      return {
        ...state,
        workMissionsAndOvertimeAccount: action.payload.data,
        error: null,
        isFindingWorkMissionsAndOvertimeAccount: false,
        isFindWorkMissionsAndOvertimeAccountCompleted: true,
      };
    }

    //#endregion FIND_WORK_MISSIONS_AND_OVERTIME_ACCOUNT

    //#region FIND_REPAIR_AND_MAINTENANCE_ACCOUNT

    case SystemAccountsActionType.FIND_REPAIR_AND_MAINTENANCE_ACCOUNT: {
      return {
        ...state,
        repairAndMaintenanceAccount: null,
        error: null,
        isFindingRepairAndMaintenanceAccount: true,
        isFindRepairAndMaintenanceAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_REPAIR_AND_MAINTENANCE_ACCOUNT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isFindingRepairAndMaintenanceAccount: false,
        isFindRepairAndMaintenanceAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_REPAIR_AND_MAINTENANCE_ACCOUNT_SUCCESS: {
      return {
        ...state,
        repairAndMaintenanceAccount: action.payload.data,
        error: null,
        isFindingRepairAndMaintenanceAccount: false,
        isFindRepairAndMaintenanceAccountCompleted: true,
      };
    }

    //#endregion FIND_REPAIR_AND_MAINTENANCE_ACCOUNT

    //#region FIND_GOVERNMENT_FEES_AND_SUBSCRIPTIONS_ACCOUNT

    case SystemAccountsActionType.FIND_GOVERNMENT_FEES_AND_SUBSCRIPTIONS_ACCOUNT: {
      return {
        ...state,
        governmentFeesAndSubscriptionsAccount: null,
        error: null,
        isFindingGovernmentFeesAndSubscriptionsAccount: true,
        isFindGovernmentFeesAndSubscriptionsAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_GOVERNMENT_FEES_AND_SUBSCRIPTIONS_ACCOUNT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isFindingGovernmentFeesAndSubscriptionsAccount: false,
        isFindGovernmentFeesAndSubscriptionsAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_GOVERNMENT_FEES_AND_SUBSCRIPTIONS_ACCOUNT_SUCCESS: {
      return {
        ...state,
        governmentFeesAndSubscriptionsAccount: action.payload.data,
        error: null,
        isFindingGovernmentFeesAndSubscriptionsAccount: false,
        isFindGovernmentFeesAndSubscriptionsAccountCompleted: true,
      };
    }

    //#endregion FIND_GOVERNMENT_FEES_AND_SUBSCRIPTIONS_ACCOUNT

    //#region FIND_THE_COST_OF_PUBLIC_SERVICES_ACCOUNT

    case SystemAccountsActionType.FIND_THE_COST_OF_PUBLIC_SERVICES_ACCOUNT: {
      return {
        ...state,
        theCostOfPublicServicesAccount: null,
        error: null,
        isFindingTheCostOfPublicServicesAccount: true,
        isFindTheCostOfPublicServicesAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_THE_COST_OF_PUBLIC_SERVICES_ACCOUNT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isFindingTheCostOfPublicServicesAccount: false,
        isFindTheCostOfPublicServicesAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_THE_COST_OF_PUBLIC_SERVICES_ACCOUNT_SUCCESS: {
      return {
        ...state,
        theCostOfPublicServicesAccount: action.payload.data,
        error: null,
        isFindingTheCostOfPublicServicesAccount: false,
        isFindTheCostOfPublicServicesAccountCompleted: true,
      };
    }

    //#endregion FIND_THE_COST_OF_PUBLIC_SERVICES_ACCOUNT

    //#region FIND_HOSPITALITY_AND_CLEANLINESS_ACCOUNT

    case SystemAccountsActionType.FIND_HOSPITALITY_AND_CLEANLINESS_ACCOUNT: {
      return {
        ...state,
        hospitalityAndCleanlinessAccount: null,
        error: null,
        isFindingHospitalityAndCleanlinessAccount: true,
        isFindHospitalityAndCleanlinessAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_HOSPITALITY_AND_CLEANLINESS_ACCOUNT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isFindingHospitalityAndCleanlinessAccount: false,
        isFindHospitalityAndCleanlinessAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_HOSPITALITY_AND_CLEANLINESS_ACCOUNT_SUCCESS: {
      return {
        ...state,
        hospitalityAndCleanlinessAccount: action.payload.data,
        error: null,
        isFindingHospitalityAndCleanlinessAccount: false,
        isFindHospitalityAndCleanlinessAccountCompleted: true,
      };
    }

    //#endregion FIND_HOSPITALITY_AND_CLEANLINESS_ACCOUNT

    //#region FIND_OFFICE_CONSUMABLES_ACCOUNT

    case SystemAccountsActionType.FIND_OFFICE_CONSUMABLES_ACCOUNT: {
      return {
        ...state,
        officeConsumablesAccount: null,
        error: null,
        isFindingOfficeConsumablesAccount: true,
        isFindOfficeConsumablesAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_OFFICE_CONSUMABLES_ACCOUNT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isFindingOfficeConsumablesAccount: false,
        isFindOfficeConsumablesAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_OFFICE_CONSUMABLES_ACCOUNT_SUCCESS: {
      return {
        ...state,
        officeConsumablesAccount: action.payload.data,
        error: null,
        isFindingOfficeConsumablesAccount: false,
        isFindOfficeConsumablesAccountCompleted: true,
      };
    }

    //#endregion FIND_OFFICE_CONSUMABLES_ACCOUNT

    //#region FIND_FINANCE_CHARGES_LOANS_AND_BANK_FACILITIES_ACCOUNT

    case SystemAccountsActionType.FIND_FINANCE_CHARGES_LOANS_AND_BANK_FACILITIES_ACCOUNT: {
      return {
        ...state,
        financeChargesLoansAndBankFacilitiesAccount: null,
        error: null,
        isFindingFinanceChargesLoansAndBankFacilitiesAccount: true,
        isFindFinanceChargesLoansAndBankFacilitiesAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_FINANCE_CHARGES_LOANS_AND_BANK_FACILITIES_ACCOUNT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isFindingFinanceChargesLoansAndBankFacilitiesAccount: false,
        isFindFinanceChargesLoansAndBankFacilitiesAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_FINANCE_CHARGES_LOANS_AND_BANK_FACILITIES_ACCOUNT_SUCCESS: {
      return {
        ...state,
        financeChargesLoansAndBankFacilitiesAccount: action.payload.data,
        error: null,
        isFindingFinanceChargesLoansAndBankFacilitiesAccount: false,
        isFindFinanceChargesLoansAndBankFacilitiesAccountCompleted: true,
      };
    }

    //#endregion FIND_FINANCE_CHARGES_LOANS_AND_BANK_FACILITIES_ACCOUNT

    //#region FIND_FINANCE_CHARGES_LEASE_CONTRACTS_ACCOUNT

    case SystemAccountsActionType.FIND_FINANCE_CHARGES_LEASE_CONTRACTS_ACCOUNT: {
      return {
        ...state,
        financeChargesLeaseContractsAccount: null,
        error: null,
        isFindingFinanceChargesLeaseContractsAccount: true,
        isFindFinanceChargesLeaseContractsAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_FINANCE_CHARGES_LEASE_CONTRACTS_ACCOUNT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isFindingFinanceChargesLeaseContractsAccount: false,
        isFindFinanceChargesLeaseContractsAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_FINANCE_CHARGES_LEASE_CONTRACTS_ACCOUNT_SUCCESS: {
      return {
        ...state,
        financeChargesLeaseContractsAccount: action.payload.data,
        error: null,
        isFindingFinanceChargesLeaseContractsAccount: false,
        isFindFinanceChargesLeaseContractsAccountCompleted: true,
      };
    }

    //#endregion FIND_FINANCE_CHARGES_LEASE_CONTRACTS_ACCOUNT

    //#region FIND_COMMISSIONS_ACCOUNT

    case SystemAccountsActionType.FIND_COMMISSIONS_ACCOUNT: {
      return {
        ...state,
        commissionsAccount: null,
        error: null,
        isFindingCommissionsAccount: true,
        isFindCommissionsAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_COMMISSIONS_ACCOUNT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isFindingCommissionsAccount: false,
        isFindCommissionsAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_COMMISSIONS_ACCOUNT_SUCCESS: {
      return {
        ...state,
        commissionsAccount: action.payload.data,
        error: null,
        isFindingCommissionsAccount: false,
        isFindCommissionsAccountCompleted: true,
      };
    }

    //#endregion FIND_COMMISSIONS_ACCOUNT

    //#region FIND_BANK_EXPENSES_ACCOUNT

    case SystemAccountsActionType.FIND_BANK_EXPENSES_ACCOUNT: {
      return {
        ...state,
        bankExpensesAccount: null,
        error: null,
        isFindingBankExpensesAccount: true,
        isFindBankExpensesAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_BANK_EXPENSES_ACCOUNT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isFindingBankExpensesAccount: false,
        isFindBankExpensesAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_BANK_EXPENSES_ACCOUNT_SUCCESS: {
      return {
        ...state,
        bankExpensesAccount: action.payload.data,
        error: null,
        isFindingBankExpensesAccount: false,
        isFindBankExpensesAccountCompleted: true,
      };
    }

    //#endregion FIND_BANK_EXPENSES_ACCOUNT

    //#region FIND_COST_OF_REVENUE_AND_GENERAL_EXPENSES_OTHERS_ACCOUNT

    case SystemAccountsActionType.FIND_COST_OF_REVENUE_AND_GENERAL_EXPENSES_OTHERS_ACCOUNT: {
      return {
        ...state,
        costOfRevenueAndGeneralExpensesOthersAccount: null,
        error: null,
        isFindingCostOfRevenueAndGeneralExpensesOthersAccount: true,
        isFindCostOfRevenueAndGeneralExpensesOthersAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_COST_OF_REVENUE_AND_GENERAL_EXPENSES_OTHERS_ACCOUNT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isFindingCostOfRevenueAndGeneralExpensesOthersAccount: false,
        isFindCostOfRevenueAndGeneralExpensesOthersAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_COST_OF_REVENUE_AND_GENERAL_EXPENSES_OTHERS_ACCOUNT_SUCCESS: {
      return {
        ...state,
        costOfRevenueAndGeneralExpensesOthersAccount: action.payload.data,
        error: null,
        isFindingCostOfRevenueAndGeneralExpensesOthersAccount: false,
        isFindCostOfRevenueAndGeneralExpensesOthersAccountCompleted: true,
      };
    }

    //#endregion FIND_COST_OF_REVENUE_AND_GENERAL_EXPENSES_OTHERS_ACCOUNT

    //#region FIND_BUILDINGS_DEPRECIATION_EXPENSES_ACCOUNT

    case SystemAccountsActionType.FIND_BUILDINGS_DEPRECIATION_EXPENSES_ACCOUNT: {
      return {
        ...state,
        buildingDepreciationExpressAccount: null,
        error: null,
        isFindingBuildingDepreciationExpressAccount: true,
        isFindBuildingDepreciationExpressAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_BUILDINGS_DEPRECIATION_EXPENSES_ACCOUNT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isFindingBuildingDepreciationExpressAccount: false,
        isFindBuildingDepreciationExpressAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_BUILDINGS_DEPRECIATION_EXPENSES_ACCOUNT_SUCCESS: {
      return {
        ...state,
        buildingDepreciationExpressAccount: action.payload.data,
        error: null,
        isFindingBuildingDepreciationExpressAccount: false,
        isFindBuildingDepreciationExpressAccountCompleted: true,
      };
    }

    //#endregion FIND_BUILDINGS_DEPRECIATION_EXPENSES_ACCOUNT

    //#region FIND_FURNITURE_DEPRECIATION_EXPENSES_ACCOUNT

    case SystemAccountsActionType.FIND_FURNITURE_DEPRECIATION_EXPENSES_ACCOUNT: {
      return {
        ...state,
        furnitureDepreciationExpressAccount: null,
        error: null,
        isFindingFurnitureDepreciationExpressAccount: true,
        isFindFurnitureDepreciationExpressAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_FURNITURE_DEPRECIATION_EXPENSES_ACCOUNT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isFindingFurnitureDepreciationExpressAccount: false,
        isFindFurnitureDepreciationExpressAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_FURNITURE_DEPRECIATION_EXPENSES_ACCOUNT_SUCCESS: {
      return {
        ...state,
        furnitureDepreciationExpressAccount: action.payload.data,
        error: null,
        isFindingFurnitureDepreciationExpressAccount: false,
        isFindFurnitureDepreciationExpressAccountCompleted: true,
      };
    }

    //#endregion FIND_FURNITURE_DEPRECIATION_EXPENSES_ACCOUNT

    //#region FIND_DEVICES_DEPRECIATION_EXPENSES_ACCOUNT

    case SystemAccountsActionType.FIND_DEVICES_DEPRECIATION_EXPENSES_ACCOUNT: {
      return {
        ...state,
        devicesDepreciationExpressAccount: null,
        error: null,
        isFindingDevicesDepreciationExpressAccount: true,
        isFindDevicesDepreciationExpressAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_DEVICES_DEPRECIATION_EXPENSES_ACCOUNT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isFindingDevicesDepreciationExpressAccount: false,
        isFindDevicesDepreciationExpressAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_DEVICES_DEPRECIATION_EXPENSES_ACCOUNT_SUCCESS: {
      return {
        ...state,
        devicesDepreciationExpressAccount: action.payload.data,
        error: null,
        isFindingDevicesDepreciationExpressAccount: false,
        isFindDevicesDepreciationExpressAccountCompleted: true,
      };
    }

    //#endregion FIND_DEVICES_DEPRECIATION_EXPENSES_ACCOUNT

    //#region FIND_COMPUTERS_DEPRECIATION_EXPENSES_ACCOUNT

    case SystemAccountsActionType.FIND_COMPUTERS_DEPRECIATION_EXPENSES_ACCOUNT: {
      return {
        ...state,
        computersDepreciationExpressAccount: null,
        error: null,
        isFindingComputersDepreciationExpressAccount: true,
        isFindComputersDepreciationExpressAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_COMPUTERS_DEPRECIATION_EXPENSES_ACCOUNT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isFindingDevicesDepreciationExpressAccount: false,
        isFindDevicesDepreciationExpressAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_COMPUTERS_DEPRECIATION_EXPENSES_ACCOUNT_SUCCESS: {
      return {
        ...state,
        computersDepreciationExpressAccount: action.payload.data,
        error: null,
        isFindingComputersDepreciationExpressAccount: false,
        isFindComputersDepreciationExpressAccountCompleted: true,
      };
    }

    //#endregion FIND_COMPUTERS_DEPRECIATION_EXPENSES_ACCOUNT

    //#region FIND_EQUIPMENTS_DEPRECIATION_EXPENSES_ACCOUNT

    case SystemAccountsActionType.FIND_EQUIPMENTS_DEPRECIATION_EXPENSES_ACCOUNT: {
      return {
        ...state,
        equipmentsDepreciationExpressAccount: null,
        error: null,
        isFindingEquipmentsDepreciationExpressAccount: true,
        isFindEquipmentsDepreciationExpressAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_EQUIPMENTS_DEPRECIATION_EXPENSES_ACCOUNT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isFindingEquipmentsDepreciationExpressAccount: false,
        isFindEquipmentsDepreciationExpressAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_EQUIPMENTS_DEPRECIATION_EXPENSES_ACCOUNT_SUCCESS: {
      return {
        ...state,
        equipmentsDepreciationExpressAccount: action.payload.data,
        error: null,
        isFindingEquipmentsDepreciationExpressAccount: false,
        isFindEquipmentsDepreciationExpressAccountCompleted: true,
      };
    }

    //#endregion FIND_EQUIPMENTS_DEPRECIATION_EXPENSES_ACCOUNT

    //#region FIND_CARS_DEPRECIATION_EXPENSES_ACCOUNT

    case SystemAccountsActionType.FIND_CARS_DEPRECIATION_EXPENSES_ACCOUNT: {
      return {
        ...state,
        carsDepreciationExpressAccount: null,
        error: null,
        isFindingCarsDepreciationExpressAccount: true,
        isFindCarsDepreciationExpressAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_CARS_DEPRECIATION_EXPENSES_ACCOUNT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isFindingCarsDepreciationExpressAccount: false,
        isFindCarsDepreciationExpressAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_CARS_DEPRECIATION_EXPENSES_ACCOUNT_SUCCESS: {
      return {
        ...state,
        carsDepreciationExpressAccount: action.payload.data,
        error: null,
        isFindingCarsDepreciationExpressAccount: false,
        isFindCarsDepreciationExpressAccountCompleted: true,
      };
    }

    //#endregion FIND_CARS_DEPRECIATION_EXPENSES_ACCOUNT

    //#region FIND_ASSETS_UNDER_FINANCE_LEASES_DEPRECIATION_EXPENSES_ACCOUNT

    case SystemAccountsActionType.FIND_ASSETS_UNDER_FINANCE_LEASES_DEPRECIATION_EXPENSES_ACCOUNT: {
      return {
        ...state,
        assetsUnderFinanceLeasesDepreciationExpensesAccount: null,
        error: null,
        isFindingAssetsUnderFinanceLeasesDepreciationExpensesAccount: true,
        isFindAssetsUnderFinanceLeasesDepreciationExpensesAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_ASSETS_UNDER_FINANCE_LEASES_DEPRECIATION_EXPENSES_ACCOUNT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isFindingAssetsUnderFinanceLeasesDepreciationExpensesAccount: false,
        isFindAssetsUnderFinanceLeasesDepreciationExpensesAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_ASSETS_UNDER_FINANCE_LEASES_DEPRECIATION_EXPENSES_ACCOUNT_SUCCESS: {
      return {
        ...state,
        assetsUnderFinanceLeasesDepreciationExpensesAccount: action.payload.data,
        error: null,
        isFindingAssetsUnderFinanceLeasesDepreciationExpensesAccount: false,
        isFindAssetsUnderFinanceLeasesDepreciationExpensesAccountCompleted: true,
      };
    }

    //#endregion FIND_ASSETS_UNDER_FINANCE_LEASES_DEPRECIATION_EXPENSES_ACCOUNT

    //#region FIND_FAME_DEPRECIATION_EXPENSES_ACCOUNT

    case SystemAccountsActionType.FIND_FAME_DEPRECIATION_EXPENSES_ACCOUNT: {
      return {
        ...state,
        fameDepreciationExpensesAccount: null,
        error: null,
        isFindingFameDepreciationExpensesAccount: true,
        isFindFameDepreciationExpensesAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_FAME_DEPRECIATION_EXPENSES_ACCOUNT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isFindingFameDepreciationExpensesAccount: false,
        isFindFameDepreciationExpensesAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_FAME_DEPRECIATION_EXPENSES_ACCOUNT_SUCCESS: {
      return {
        ...state,
        fameDepreciationExpensesAccount: action.payload.data,
        error: null,
        isFindingFameDepreciationExpensesAccount: false,
        isFindFameDepreciationExpensesAccountCompleted: true,
      };
    }

    //#endregion FIND_FAME_DEPRECIATION_EXPENSES_ACCOUNT

    //#region FIND_SOFTWARE_AND_SYSTEMS_DEPRECIATION_EXPENSES_ACCOUNT

    case SystemAccountsActionType.FIND_SOFTWARE_AND_SYSTEMS_DEPRECIATION_EXPENSES_ACCOUNT: {
      return {
        ...state,
        softwareAndSystemsDepreciationExpensesAccount: null,
        error: null,
        isFindingSoftwareAndSystemsDepreciationExpensesAccount: true,
        isFindSoftwareAndSystemsDepreciationExpensesAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_SOFTWARE_AND_SYSTEMS_DEPRECIATION_EXPENSES_ACCOUNT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isFindingSoftwareAndSystemsDepreciationExpensesAccount: false,
        isFindSoftwareAndSystemsDepreciationExpensesAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_SOFTWARE_AND_SYSTEMS_DEPRECIATION_EXPENSES_ACCOUNT_SUCCESS: {
      return {
        ...state,
        softwareAndSystemsDepreciationExpensesAccount: action.payload.data,
        error: null,
        isFindingSoftwareAndSystemsDepreciationExpensesAccount: false,
        isFindSoftwareAndSystemsDepreciationExpensesAccountCompleted: true,
      };
    }

    //#endregion FIND_SOFTWARE_AND_SYSTEMS_DEPRECIATION_EXPENSES_ACCOUNT

    //#region FIND_PATENTS_AND_TRADEMARKS_DEPRECIATION_EXPENSES_ACCOUNT

    case SystemAccountsActionType.FIND_PATENTS_AND_TRADEMARKS_DEPRECIATION_EXPENSES_ACCOUNT: {
      return {
        ...state,
        patentsAndTrademarksDepreciationExpensesAccount: null,
        error: null,
        isFindingPatentsAndTrademarksDepreciationExpensesAccount: true,
        isFindPatentsAndTrademarksDepreciationExpensesAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_PATENTS_AND_TRADEMARKS_DEPRECIATION_EXPENSES_ACCOUNT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isFindingPatentsAndTrademarksDepreciationExpensesAccount: false,
        isFindPatentsAndTrademarksDepreciationExpensesAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_PATENTS_AND_TRADEMARKS_DEPRECIATION_EXPENSES_ACCOUNT_SUCCESS: {
      return {
        ...state,
        patentsAndTrademarksDepreciationExpensesAccount: action.payload.data,
        error: null,
        isFindingPatentsAndTrademarksDepreciationExpensesAccount: false,
        isFindPatentsAndTrademarksDepreciationExpensesAccountCompleted: true,
      };
    }

    //#endregion FIND_PATENTS_AND_TRADEMARKS_DEPRECIATION_EXPENSES_ACCOUNT

    //#region FIND_REAL_ESTATE_INVESTMENT_BUILDINGS_DEPRECIATION_EXPENSES_ACCOUNT

    case SystemAccountsActionType.FIND_REAL_ESTATE_INVESTMENT_BUILDINGS_DEPRECIATION_EXPENSES_ACCOUNT: {
      return {
        ...state,
        realEstateInvestmentBuildingsDepreciationExpensesAccount: null,
        error: null,
        isFindingRealEstateInvestmentBuildingsDepreciationExpensesAccount: true,
        isFindRealEstateInvestmentBuildingsDepreciationExpensesAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_REAL_ESTATE_INVESTMENT_BUILDINGS_DEPRECIATION_EXPENSES_ACCOUNT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isFindingRealEstateInvestmentBuildingsDepreciationExpensesAccount: false,
        isFindRealEstateInvestmentBuildingsDepreciationExpensesAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_REAL_ESTATE_INVESTMENT_BUILDINGS_DEPRECIATION_EXPENSES_ACCOUNT_SUCCESS: {
      return {
        ...state,
        realEstateInvestmentBuildingsDepreciationExpensesAccount: action.payload.data,
        error: null,
        isFindingRealEstateInvestmentBuildingsDepreciationExpensesAccount: false,
        isFindRealEstateInvestmentBuildingsDepreciationExpensesAccountCompleted: true,
      };
    }

    //#endregion FIND_REAL_ESTATE_INVESTMENT_BUILDINGS_DEPRECIATION_EXPENSES_ACCOUNT

    //#region FIND_ASSETS_BROKER_ACCOUNT

    case SystemAccountsActionType.FIND_ASSETS_BROKER_ACCOUNT: {
      return {
        ...state,
        assetsBrokerAccount: null,
        error: null,
        isFindingAssetsBrokerAccount: true,
        isFindAssetsBrokerAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_ASSETS_BROKER_ACCOUNT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isFindingAssetsBrokerAccount: false,
        isFindAssetsBrokerAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_ASSETS_BROKER_ACCOUNT_SUCCESS: {
      return {
        ...state,
        assetsBrokerAccount: action.payload.data,
        error: null,
        isFindingAssetsBrokerAccount: false,
        isFindAssetsBrokerAccountCompleted: true,
      };
    }

    //#endregion FIND_ASSETS_BROKER_ACCOUNT

    //#region FIND_CURRENCY_ROUNDING_DIFFERENCE_ACCOUNT

    case SystemAccountsActionType.FIND_CURRENCY_ROUNDING_DIFFERENCE_ACCOUNT: {
      return {
        ...state,
        currencyRoundingDifferenceAccount: null,
        error: null,
        isFindingCurrencyRoundingDifferenceAccount: true,
        isFindCurrencyRoundingDifferenceAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_CURRENCY_ROUNDING_DIFFERENCE_ACCOUNT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isFindingCurrencyRoundingDifferenceAccount: false,
        isFindCurrencyRoundingDifferenceAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_CURRENCY_ROUNDING_DIFFERENCE_ACCOUNT_SUCCESS: {
      return {
        ...state,
        currencyRoundingDifferenceAccount: action.payload.data,
        error: null,
        isFindingCurrencyRoundingDifferenceAccount: false,
        isFindCurrencyRoundingDifferenceAccountCompleted: true,
      };
    }

    //#endregion FIND_CURRENCY_ROUNDING_DIFFERENCE_ACCOUNT

    //#region FIND_GOSI_ACCRUAL_ACCOUNT

    case SystemAccountsActionType.FIND_GOSI_ACCRUAL_ACCOUNT: {
      return {
        ...state,
        gosiAccrualAccount: null,
        error: null,
        isFindingGosiAccrualAccount: true,
        isFindGosiAccrualAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_GOSI_ACCRUAL_ACCOUNT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isFindingGosiAccrualAccount: false,
        isFindGosiAccrualAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_GOSI_ACCRUAL_ACCOUNT_SUCCESS: {
      return {
        ...state,
        gosiAccrualAccount: action.payload.data,
        error: null,
        isFindingGosiAccrualAccount: false,
        isFindGosiAccrualAccountCompleted: true,
      };
    }

    //#endregion FIND_GOSI_ACCRUAL_ACCOUNT

    //#region FIND_GOSI_EXPENSES_ACCOUNT

    case SystemAccountsActionType.FIND_GOSI_EXPENSES_ACCOUNT: {
      return {
        ...state,
        gosiExpensesAccount: null,
        error: null,
        isFindingGosiExpensesAccount: true,
        isFindGosiExpensesAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_GOSI_EXPENSES_ACCOUNT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isFindingGosiExpensesAccount: false,
        isFindGosiExpensesAccountCompleted: false,
      };
    }

    case SystemAccountsActionType.FIND_GOSI_EXPENSES_ACCOUNT_SUCCESS: {
      return {
        ...state,
        gosiExpensesAccount: action.payload.data,
        error: null,
        isFindingGosiExpensesAccount: false,
        isFindGosiExpensesAccountCompleted: true,
      };
    }

    //#endregion FIND_GOSI_EXPENSES_ACCOUNT

    default:
      return state;
  }
}
