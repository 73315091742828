import { createSelector } from '@ngrx/store';

import { PosPrintersState, SalesState } from 'sales/store/states';
import { selectSalesState } from 'sales/store/selectors/feature.selectors';

/**
 * Gets the pos printer state.
 */
const selectPosPrintersState = createSelector(selectSalesState, (state: SalesState) => state.posPrinters);

/**
 * Gets the list of loaded pos printer.
 */
export const getPosPrinters = createSelector(selectPosPrintersState, (state: PosPrintersState) => state.data);

/**
 * Gets the pos printer pagination info.
 */
export const getPosPrintersPaginationInfo = createSelector(
  selectPosPrintersState,
  (state: PosPrintersState) => state.paginationInfo
);

/**
 * Gets the error that occurred in the state.
 */
export const getPosPrintersError = createSelector(selectPosPrintersState, (state: PosPrintersState) => state.error);

/**
 * Determines if there is a running search process.
 */
export const getPosPrintersSearching = createSelector(
  selectPosPrintersState,
  (state: PosPrintersState) => state.isSearching
);

/**
 * Determines if the last search process has been ended successfully.
 */
export const getPosPrintersSearchCompleted = createSelector(
  selectPosPrintersState,
  (state: PosPrintersState) => state.isSearchCompleted
);

/**
 * Gets the selected pos printer.
 */
export const getSelectedPosPrinter = createSelector(
  selectPosPrintersState,
  (state: PosPrintersState) => state.selectedPosPrinter
);

/**
 * Determines if there is a running find pos printer process.
 */
export const getPosPrinterFinding = createSelector(
  selectPosPrintersState,
  (state: PosPrintersState) => state.isFinding
);

/**
 * Determines if the last find pos printer process has been ended successfully.
 */
export const getPosPrinterFindCompleted = createSelector(
  selectPosPrintersState,
  (state: PosPrintersState) => state.isFindCompleted
);

/**
 * Determines if there is a running create process.
 */
export const getSelectedPosPrinterCreating = createSelector(
  selectPosPrintersState,
  (state: PosPrintersState) => state.isCreating
);

/**
 * Determines if the last create process has been ended successfully.
 */
export const getSelectedPosPrinterCreateCompleted = createSelector(
  selectPosPrintersState,
  (state: PosPrintersState) => state.isCreateCompleted
);

/**
 * Determines if there is a running update process.
 */
export const getSelectedPosPrinterUpdating = createSelector(
  selectPosPrintersState,
  (state: PosPrintersState) => state.isUpdating
);

/**
 * Determines if the last update process has been ended successfully.
 */
export const getSelectedPosPrinterUpdateCompleted = createSelector(
  selectPosPrintersState,
  (state: PosPrintersState) => state.isUpdateCompleted
);

/**
 * Determines if there is a running delete process.
 */
export const getSelectedPosPrinterDeleting = createSelector(
  selectPosPrintersState,
  (state: PosPrintersState) => state.isDeleting
);

/**
 * Determines if the last delete process has been ended successfully.
 */
export const getSelectedPosPrinterDeleteCompleted = createSelector(
  selectPosPrintersState,
  (state: PosPrintersState) => state.isDeleteCompleted
);
