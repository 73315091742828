import { ProductsEffects } from 'stores/store/effects/products.effects';
import { LocationsEffects } from 'stores/store/effects/location.effects';
import { ProductClassesEffects } from 'stores/store/effects/product-classes.effects';
import { UnitOfMeasureClassesEffects } from 'stores/store/effects/unit-of-measure-classes.effects';
import { UnitsOfMeasureEffects } from 'stores/store/effects/units-of-measure.effects';
import { VariantsEffects } from 'stores/store/effects/variants.effects';
import { RegionsEffects } from 'stores/store/effects/regions.effects';
import { SectorsEffects } from 'stores/store/effects/sectors.effects';

/**
 * The Stores-Module store effects.
 */
export const effects: any[] = [
  ProductsEffects,
  LocationsEffects,
  ProductClassesEffects,
  UnitOfMeasureClassesEffects,
  UnitsOfMeasureEffects,
  VariantsEffects,
  RegionsEffects,
  SectorsEffects,
];
