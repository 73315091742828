import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import * as fromContainers from 'sales/containers';
import * as fromComponents from 'sales/components';
import { Claims } from 'security/models';
import { ConfirmationGuard } from 'auth/guards';

const routes: Routes = [
  // customers-routes
  {
    path: 'customers/create',
    component: fromComponents.CreateCustomerComponent,
    data: { claims: [Claims.CREATE_CUSTOMER] },
  },
  {
    path: 'customers/files/:customerId',
    component: fromContainers.CustomerFileComponent,
    data: {
      claims: [
        Claims.UPDATE_CUSTOMER,
        Claims.CREATE_CUSTOMER_ENGAGEMENT,
        Claims.UPDATE_CUSTOMER_ENGAGEMENT,
        Claims.DELETE_CUSTOMER_ENGAGEMENT,
        Claims.CREATE_CUSTOMER_PAYMENT,
      ],
    },
  },
  {
    path: 'customers/payments/create',
    component: fromComponents.CreateCustomerPaymentsComponent,
    data: {
      claims: [Claims.CREATE_CUSTOMER_PAYMENT],
    },
    canDeactivate: [ConfirmationGuard],
  },
  {
    path: 'customers/payment-by-advance-payment/create',
    component: fromComponents.CreatePaymentByAdvancePaymentComponent,
    data: {
      claims: [Claims.CREATE_CUSTOMER_PAYMENT],
    },
    canDeactivate: [ConfirmationGuard],
  },
  {
    path: 'customers/advance-payment/create',
    component: fromComponents.CreateCustomerAdvancePaymentComponent,
    data: {
      claims: [Claims.CREATE_CUSTOMER_PAYMENT],
    },
    canDeactivate: [ConfirmationGuard],
  },
  {
    path: 'customers/refund/create',
    component: fromComponents.CreateCustomerRefundComponent,
    data: {
      claims: [Claims.CREATE_CUSTOMER_PAYMENT],
    },
    canDeactivate: [ConfirmationGuard],
  },
  {
    path: 'customers/payments',
    component: fromComponents.CustomersPaymentsListComponent,
    data: {
      claims: [Claims.CREATE_CUSTOMER_PAYMENT],
    },
  },
  {
    path: 'customers',
    component: fromComponents.CustomersListComponent,
    data: {
      claims: [
        Claims.CREATE_CUSTOMER,
        Claims.UPDATE_CUSTOMER,
        Claims.DELETE_CUSTOMER,
        Claims.BLOCK_CUSTOMER,
        Claims.ACTIVATE_CUSTOMER,
        Claims.CREATE_CUSTOMER_ENGAGEMENT,
        Claims.UPDATE_CUSTOMER_ENGAGEMENT,
        Claims.DELETE_CUSTOMER_ENGAGEMENT,
        Claims.CREATE_CUSTOMER_PAYMENT,
      ],
    },
  },

  // invoices-routes
  {
    path: 'invoices/create-point-of-sale-invoice',
    component: fromComponents.CreatePointOfSaleInvoiceComponent,
    data: { claims: [Claims.CREATE_POINT_OF_SALE_INVOICE] },
    canDeactivate: [ConfirmationGuard],
  },
  {
    path: 'invoices/create-regular-invoice/:quotationId',
    component: fromComponents.CreateRegularSaleInvoiceComponent,
    data: { claims: [Claims.CREATE_SALE_INVOICE] },
    canDeactivate: [ConfirmationGuard],
  },
  {
    path: 'invoices/create-regular-invoice',
    component: fromComponents.CreateRegularSaleInvoiceComponent,
    data: { claims: [Claims.CREATE_SALE_INVOICE] },
    canDeactivate: [ConfirmationGuard],
  },
  {
    path: 'invoices/create-opening-balance-invoice',
    component: fromComponents.CreateOpeningBalanceInvoiceComponent,
    data: { claims: [Claims.CREATE_CUSTOMER_OPENING_BALANCE_INVOICE] },
    canDeactivate: [ConfirmationGuard],
  },
  {
    path: 'invoices/create-maintenance-invoice',
    component: fromComponents.CreateMaintenanceInvoiceComponent,
    data: { claims: [Claims.CREATE_MAINTENANCE_SALE_INVOICE] },
    canDeactivate: [ConfirmationGuard],
  },
  {
    path: 'invoices/view/:invoiceId',
    component: fromContainers.ViewSaleInvoiceComponent,
    data: {
      claims: [
        Claims.CREATE_SALE_INVOICE,
        Claims.CREATE_MAINTENANCE_SALE_INVOICE,
        Claims.CREATE_CUSTOMER_OPENING_BALANCE_INVOICE,
        Claims.CREATE_POINT_OF_SALE_INVOICE,
      ],
    },
  },
  {
    path: 'invoices',
    component: fromComponents.SaleInvoicesListComponent,
    data: {
      claims: [
        Claims.CREATE_SALE_INVOICE,
        Claims.CREATE_MAINTENANCE_SALE_INVOICE,
        Claims.CREATE_CUSTOMER_OPENING_BALANCE_INVOICE,
        Claims.CREATE_POINT_OF_SALE_INVOICE,
      ],
    },
  },

  // customer-engagements-routes
  {
    path: 'customer-engagements',
    component: fromComponents.CustomerEngagementsListComponent,
    data: {
      claims: [Claims.CREATE_CUSTOMER_ENGAGEMENT, Claims.UPDATE_CUSTOMER_ENGAGEMENT, Claims.DELETE_CUSTOMER_ENGAGEMENT],
    },
  },
  // customer-assets-routes
  {
    path: 'customer-assets',
    component: fromComponents.CustomerAssetsListComponent,
    data: {
      claims: [Claims.UPDATE_CUSTOMER],
    },
  },

  // sale-returns routing
  {
    path: 'returns/create',
    component: fromComponents.CreateSaleReturnComponent,
    data: { claims: [Claims.CREATE_SALE_RETURN] },
    canDeactivate: [ConfirmationGuard],
  },
  {
    path: 'returns',
    component: fromComponents.SaleReturnsListComponent,
    data: { claims: [Claims.CREATE_SALE_RETURN] },
  },
  {
    path: 'returns/view/:saleReturnId',
    component: fromContainers.ViewSaleReturnComponent,
    data: {
      claims: [Claims.CREATE_SALE_RETURN],
    },
  },

  // shifts routing
  {
    path: 'my-shifts',
    component: fromComponents.MyShiftsListComponent,
    data: { claims: [Claims.START_NEW_SHIFT] },
  },
  {
    path: 'shifts',
    component: fromComponents.ShiftsListComponent,
    data: { claims: [Claims.START_SHIFT_FOR_CASHER, Claims.END_SHIFT_FOR_CASHER, Claims.DELETE_CASHER_SHIFT] },
  },

  // pending pos invoices routing
  {
    path: 'orders-monitoring',
    component: fromComponents.OrdersMonitoringComponent,
    data: { claims: [Claims.ORDERS_MONITORING] },
  },

  // orders delivery routing
  {
    path: 'orders-delivery',
    component: fromComponents.OrdersDeliveryListComponent,
    data: { claims: [Claims.ORDERS_DELIVERING] },
  },

  // boards routing
  {
    path: 'boards/create',
    component: fromComponents.CreateBoardComponent,
    data: { claims: [Claims.CREATE_BOARD] },
  },
  {
    path: 'boards',
    component: fromComponents.BoardsListComponent,
    data: { claims: [Claims.CREATE_BOARD, Claims.UPDATE_BOARD, Claims.DELETE_BOARD] },
  },
  {
    path: 'boards/update/:boardId',
    component: fromComponents.UpdateBoardComponent,
    data: {
      claims: [Claims.UPDATE_BOARD],
    },
  },

  // pos-devices-routes
  {
    path: 'pos-devices/create',
    component: fromComponents.CreatePosDeviceComponent,
    data: {
      claims: [Claims.CREATE_POS_DEVICE],
    },
  },
  {
    path: 'pos-devices/update/:posDeviceId',
    component: fromComponents.UpdatePosDeviceComponent,
    data: {
      claims: [Claims.UPDATE_POS_DEVICE],
    },
  },
  {
    path: 'pos-home',
    component: fromContainers.PosHomeComponent,
    data: {
      claims: [Claims.CREATE_POS_DEVICE, Claims.UPDATE_POS_DEVICE, Claims.DELETE_POS_DEVICE],
    },
  },

  // pos-printers-routes
  {
    path: 'pos-printers/create',
    component: fromComponents.CreatePosPrinterComponent,
    data: {
      claims: [Claims.CREATE_POS_DEVICE],
    },
  },
  {
    path: 'pos-printers/update/:posPrinterId',
    component: fromComponents.UpdatePosPrinterComponent,
    data: {
      claims: [Claims.UPDATE_POS_DEVICE],
    },
  },

  // kitchen-printers-routes
  {
    path: 'kitchen-printers/create',
    component: fromComponents.CreateKitchenPrinterComponent,
    data: {
      claims: [Claims.CREATE_POS_DEVICE],
    },
  },
  {
    path: 'kitchen-printers/update/:kitchenPrinterId',
    component: fromComponents.UpdateKitchenPrinterComponent,
    data: {
      claims: [Claims.UPDATE_POS_DEVICE],
    },
  },

  // casher-printers-routes
  {
    path: 'casher-printers/create',
    component: fromComponents.CreateCasherPrinterComponent,
    data: {
      claims: [Claims.CREATE_POS_DEVICE],
    },
  },
  {
    path: 'casher-printers/update/:casherPrinterId',
    component: fromComponents.UpdateCasherPrinterComponent,
    data: {
      claims: [Claims.UPDATE_POS_DEVICE],
    },
  },

  // order-delivery-printers-routes
  {
    path: 'order-delivery-printers/create',
    component: fromComponents.CreateOrderDeliveryPrinterComponent,
    data: {
      claims: [Claims.CREATE_POS_DEVICE],
    },
  },
  {
    path: 'order-delivery-printers/update/:orderDeliveryPrinterId',
    component: fromComponents.UpdateOrderDeliveryPrinterComponent,
    data: {
      claims: [Claims.UPDATE_POS_DEVICE],
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SalesRoutingModule {}
