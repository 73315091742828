import { Injectable } from '@angular/core';
import { Effect, ofType, Actions } from '@ngrx/effects';

import { of } from 'rxjs';
import { switchMap, map, catchError, tap } from 'rxjs/operators';

import { NotificationService, StatusCode, TranslationService } from 'shared';
import { FinanceConfigurationsService } from 'finances/services';
import {
  FinanceConfigurationsActionType,
  GetEmployeeProvisionLinksFail,
  GetEmployeeProvisionLinksSuccess,
  UpdateEmployeeProvisionLinks,
  UpdateEmployeeProvisionLinksFail,
  UpdateEmployeeProvisionLinksSuccess,
  GetGovServicesFail,
  GetGovServicesSuccess,
  UpdateGovServices,
  UpdateGovServicesFail,
  UpdateGovServicesSuccess,
} from 'finances/store/actions';

@Injectable()
export class FinanceConfigurationsEffects {
  constructor(
    private actions$: Actions,
    private financeConfigurationsService: FinanceConfigurationsService,
    private notificationService: NotificationService,
    private translationService: TranslationService
  ) {}

  /* ========================= GET_EMPLOYEE_PROVISION_LINKS =================================== */
  @Effect()
  getEmployeeProvisionLinks$ = this.actions$.pipe(
    ofType(FinanceConfigurationsActionType.GET_EMPLOYEE_PROVISION_LINKS),
    switchMap(() =>
      this.financeConfigurationsService.getEmployeeProvisionLinks().pipe(
        map((response) => new GetEmployeeProvisionLinksSuccess(response)),
        catchError((error) => of(new GetEmployeeProvisionLinksFail(error)))
      )
    )
  );

  /* ========================= UPDATE_EMPLOYEE_PROVISION_LINKS =================================== */
  @Effect()
  updateEmployeeProvisionLink$ = this.actions$.pipe(
    ofType(FinanceConfigurationsActionType.UPDATE_EMPLOYEE_PROVISION_LINKS),
    switchMap((action: UpdateEmployeeProvisionLinks) =>
      this.financeConfigurationsService.updateEmployeeProvisionLinks(action.payload).pipe(
        map((response) => new UpdateEmployeeProvisionLinksSuccess(response)),
        catchError((error) => of(new UpdateEmployeeProvisionLinksFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  updateEmployeeProvisionLinkSuccess$ = this.actions$.pipe(
    ofType(FinanceConfigurationsActionType.UPDATE_EMPLOYEE_PROVISION_LINKS_SUCCESS),
    tap((action: UpdateEmployeeProvisionLinksSuccess) => {
      this.notificationService.success(
        this.translationService.translate('FINANCES.FINANCE_CONFIGURATIONS.EMPLOYEE_PROVISION_LINKS_UPDATED')
      );
    })
  );

  @Effect({ dispatch: false })
  updateEmployeeProvisionLinkFail$ = this.actions$.pipe(
    ofType(FinanceConfigurationsActionType.UPDATE_EMPLOYEE_PROVISION_LINKS_FAIL),
    tap((action: UpdateEmployeeProvisionLinksFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate('FINANCES.FINANCE_CONFIGURATIONS.EMPLOYEE_PROVISION_LINKS_NOT_FOUND')
        );
      } else if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );

  /* ========================= GET_GOV_SERVICES =================================== */
  @Effect()
  getGovServices$ = this.actions$.pipe(
    ofType(FinanceConfigurationsActionType.GET_GOV_SERVICES),
    switchMap(() =>
      this.financeConfigurationsService.getGovServices().pipe(
        map((response) => new GetGovServicesSuccess(response)),
        catchError((error) => of(new GetGovServicesFail(error)))
      )
    )
  );

  /* ========================= UPDATE_GOV_SERVICES =================================== */
  @Effect()
  updateGovServices$ = this.actions$.pipe(
    ofType(FinanceConfigurationsActionType.UPDATE_GOV_SERVICES),
    switchMap((action: UpdateGovServices) =>
      this.financeConfigurationsService.updateGovServices(action.payload).pipe(
        map((response) => new UpdateGovServicesSuccess(response)),
        catchError((error) => of(new UpdateGovServicesFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  updateGovServicesSuccess$ = this.actions$.pipe(
    ofType(FinanceConfigurationsActionType.UPDATE_GOV_SERVICES_SUCCESS),
    tap((action: UpdateGovServicesSuccess) => {
      this.notificationService.success(
        this.translationService.translate('FINANCES.FINANCE_CONFIGURATIONS.GOV_SERVICES_UPDATED')
      );
    })
  );

  @Effect({ dispatch: false })
  updateGovServicesFail$ = this.actions$.pipe(
    ofType(FinanceConfigurationsActionType.UPDATE_GOV_SERVICES_FAIL),
    tap((action: UpdateGovServicesFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate('FINANCES.FINANCE_CONFIGURATIONS.GOV_SERVICES_NOT_FOUND')
        );
      } else if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );
}
