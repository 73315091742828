import { Injectable } from '@angular/core';

import { of } from 'rxjs';
import { switchMap, map, catchError, tap, debounceTime } from 'rxjs/operators';
import { Effect, ofType, Actions } from '@ngrx/effects';

import { NotificationService, StatusCode, PAGINATION, TranslationService } from 'shared';
import { CostCentersService } from 'finances/services';
import {
  CostCentersActionType,
  CreateCostCenter,
  CreateCostCenterFail,
  CreateCostCenterSuccess,
  DeleteCostCenter,
  DeleteCostCenterFail,
  DeleteCostCenterSuccess,
  FindCostCenter,
  FindCostCenterFail,
  FindCostCenterSuccess,
  GetGeneralCostCenter,
  GetGeneralCostCenterFail,
  GetGeneralCostCenterSuccess,
  SearchCostCenters,
  SearchCostCentersFail,
  SearchCostCentersSuccess,
  UpdateCostCenter,
  UpdateCostCenterFail,
  UpdateCostCenterSuccess,
} from 'finances/store/actions';

@Injectable()
export class CostCentersEffects {
  constructor(
    private actions$: Actions,
    private costCentersService: CostCentersService,
    private notificationService: NotificationService,
    private translationService: TranslationService
  ) {}

  /* ========================= SEARCH_COST_CENTERS =================================== */
  @Effect()
  search$ = this.actions$.pipe(
    ofType(CostCentersActionType.SEARCH_COST_CENTERS),
    debounceTime(300),
    switchMap(({ payload }: SearchCostCenters) =>
      this.costCentersService.search(payload?.description ?? '', payload.page, PAGINATION.CostCenters).pipe(
        map((response) => new SearchCostCentersSuccess(response)),
        catchError((error) => of(new SearchCostCentersFail(error)))
      )
    )
  );

  /* ========================= GET_GENERAL_COST_CENTER =================================== */
  @Effect()
  getGeneralCostCenter$ = this.actions$.pipe(
    ofType(CostCentersActionType.GET_GENERAL_COST_CENTER),
    debounceTime(300),
    switchMap((action: GetGeneralCostCenter) =>
      this.costCentersService.getGeneralCostCenter().pipe(
        map((response) => new GetGeneralCostCenterSuccess(response)),
        catchError((error) => of(new GetGeneralCostCenterFail(error)))
      )
    )
  );

  /* ========================= FIND_COST_CENTER =================================== */
  @Effect()
  find$ = this.actions$.pipe(
    ofType(CostCentersActionType.FIND_COST_CENTER),
    switchMap((action: FindCostCenter) =>
      this.costCentersService.findById(action.payload).pipe(
        map((response) => new FindCostCenterSuccess(response)),
        catchError((error) => of(new FindCostCenterFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  findFail$ = this.actions$.pipe(
    ofType(CostCentersActionType.FIND_COST_CENTER_FAIL),
    tap((action: FindCostCenterFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate('FINANCES.COST_CENTERS.COST_CENTER_NOT_FOUND')
        );
      }
    })
  );

  /* ========================= CREATE_COST_CENTER =================================== */
  @Effect()
  create$ = this.actions$.pipe(
    ofType(CostCentersActionType.CREATE_COST_CENTER),
    switchMap((action: CreateCostCenter) =>
      this.costCentersService.create(action.payload).pipe(
        map((response) => new CreateCostCenterSuccess(response)),
        catchError((error) => of(new CreateCostCenterFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  createSuccess$ = this.actions$.pipe(
    ofType(CostCentersActionType.CREATE_COST_CENTER_SUCCESS),
    tap((action: CreateCostCenterSuccess) => {
      this.notificationService.success(this.translationService.translate('FINANCES.COST_CENTERS.COST_CENTER_ADDED'));
    })
  );

  @Effect({ dispatch: false })
  createFail$ = this.actions$.pipe(
    ofType(CostCentersActionType.CREATE_COST_CENTER_FAIL),
    tap((action: CreateCostCenterFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );

  /* ========================= UPDATE_COST_CENTER =================================== */
  @Effect()
  update$ = this.actions$.pipe(
    ofType(CostCentersActionType.UPDATE_COST_CENTER),
    switchMap((action: UpdateCostCenter) =>
      this.costCentersService.update(action.payload).pipe(
        map((response) => new UpdateCostCenterSuccess(response)),
        catchError((error) => of(new UpdateCostCenterFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  updateSuccess$ = this.actions$.pipe(
    ofType(CostCentersActionType.UPDATE_COST_CENTER_SUCCESS),
    tap((action: UpdateCostCenterSuccess) => {
      this.notificationService.success(this.translationService.translate('FINANCES.COST_CENTERS.COST_CENTER_UPDATED'));
    })
  );

  @Effect({ dispatch: false })
  updateFail$ = this.actions$.pipe(
    ofType(CostCentersActionType.UPDATE_COST_CENTER_FAIL),
    tap((action: UpdateCostCenterFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate('FINANCES.COST_CENTERS.COST_CENTER_NOT_FOUND')
        );
      } else if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );

  /* ========================= DELETE_COST_CENTER =================================== */
  @Effect()
  delete$ = this.actions$.pipe(
    ofType(CostCentersActionType.DELETE_COST_CENTER),
    switchMap((action: DeleteCostCenter) =>
      this.costCentersService.delete(action.payload).pipe(
        map((response) => new DeleteCostCenterSuccess(response)),
        catchError((error) => of(new DeleteCostCenterFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  deleteSuccess$ = this.actions$.pipe(
    ofType(CostCentersActionType.DELETE_COST_CENTER_SUCCESS),
    tap((action: DeleteCostCenterSuccess) => {
      this.notificationService.success(this.translationService.translate('FINANCES.COST_CENTERS.COST_CENTER_DELETED'));
    })
  );

  @Effect({ dispatch: false })
  deleteFail$ = this.actions$.pipe(
    ofType(CostCentersActionType.DELETE_COST_CENTER_FAIL),
    tap((action: DeleteCostCenterFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate('FINANCES.COST_CENTERS.COST_CENTER_NOT_FOUND')
        );
      } else if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );
}
