import { Component, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';

import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

import {
  Payroll,
  PayrollAttachment,
  PayrollDeduction,
  PayrollElement,
  PayrollLoanSettlement,
  PayrollLocationCost,
  PayrollOtherEarning,
  PayrollOvertime,
  PayrollProjectCost,
  PayrollProvision,
  PayrollUnpaidLeave,
} from 'hr/models';
import { Claims } from 'security/models';

@Component({
  selector: '[app-pay-payroll-form-item]',
  templateUrl: './pay-payroll-form-item.component.html',
  styles: [],
})
export class PayPayrollFormItemComponent {
  /**
   * The info payroll modal template reference.
   */
  @ViewChild('infoPayrollModalRef') infoPayrollModalRef: ElementRef<any>;

  /**
   * The info payroll loan settlement modal template reference.
   */
  @ViewChild('infoPayrollLoanSettlementModalRef') infoPayrollLoanSettlementModalRef: ElementRef<any>;

  /**
   * The info payroll other earning modal template reference.
   */
  @ViewChild('infoPayrollOtherEarningModalRef') infoPayrollOtherEarningModalRef: ElementRef<any>;

  /**
   * The info payroll deductions modal template reference.
   */
  @ViewChild('infoPayrollDeductionModalRef') infoPayrollDeductionModalRef: ElementRef<any>;

  /**
   * The info payroll overtimes modal template reference.
   */
  @ViewChild('infoPayrollOvertimeModalRef') infoPayrollOvertimeModalRef: ElementRef<any>;

  /**
   * The info payroll gosi payroll elements modal template reference.
   */
  @ViewChild('infoGosiPayrollElementModalRef') infoGosiPayrollElementModalRef: ElementRef<any>;

  /**
   * Emits a value when the user wants to remove current payroll from the list.
   */
  @Output() removeChange = new EventEmitter<any>();

  /**
   * Sets the payroll index in the payrolls-form.
   */
  @Input() index: number;

  /**
   * Sets the in review in the payrolls false.
   */
  @Input() inReview: false;

  /**
   * Sets the in employee in the payrolls false.
   */
  @Input() isEmployee: false;

  /**
   * Sets the payroll form-group.
   */
  @Input() payrollGroup: FormGroup;

  /**
   * Emits a value indicates that a drop-down field in the form is opened and therefore the table height should be expanded.
   */
  @Output() dropDownIsOpened = new EventEmitter<boolean>(false);

  /**
   * The system supported user claims.
   */
  Claims = Claims;

  /**
   * Shows or hide the payroll info modal.
   */
  payrollInfoVisibility = false;

  /**
   * Shows or hide the payroll gosi payroll elements list modal.
   */
  gosiPayrollElementsListVisibility = false;

  /**
   * Shows or hide the payroll recurring elements list modal.
   */
  payrollRecurringElementsListVisibility = false;

  /**
   * Shows or hide the payroll loan settlements list modal.
   */
  payrollLoanSettlementsListVisibility = false;

  /**
   * Shows or hide the payroll other earnings list modal.
   */
  payrollOtherEarningsListVisibility = false;

  /**
   * Shows or hide the payroll deductions list modal.
   */
  payrollDeductionsListVisibility = false;

  /**
   * Shows or hide the payroll over time list modal.
   */
  payrollOvertimesListVisibility = false;

  /**
   * Shows or hide the payroll locations costs list modal.
   */
  payrollLocationsCostsListVisibility = false;

  /**
   * Shows or hide the payroll projects costs list modal.
   */
  payrollProjectsCostsListVisibility = false;

  /**
   * Get selected payroll.
   */
  selectedPayroll: Payroll;

  /**
   * Gets the list of selected payroll element for current payroll element.
   */
  selectedPayrollElement: PayrollElement[];

  /**
   * Gets the list of selected payroll loan settlement for current payroll element.
   */
  selectedPayrollLoanSettlements: PayrollLoanSettlement[];

  /**
   * Gets the list of selected gosi payroll for current payroll element.
   */
  selectedPayrollGosiPayrollElements: PayrollElement[];

  /**
   * Gets the list of selected payroll Other earnings for current payroll element.
   */
  selectedPayrollOtherEarnings: PayrollOtherEarning[];

  /**
   * Gets the list of selected payroll deductions for current payroll element.
   */
  selectedPayrollDeductions: PayrollDeduction[];

  /**
   * Gets the list of selected payroll overtimes for current payroll element.
   */
  selectedPayrollOvertimes: PayrollOvertime[];

  /**
   * Gets the list of selected payroll locations costs for current payroll.
   */
  selectedPayrollLocationCosts: PayrollLocationCost[];

  /**
   * Gets the list of selected payroll provisions for current payroll.
   */
  selectedPayrollProvisions: PayrollProvision[];

  /**
   * Gets the list of selected payroll unpaid leaves for current payroll.
   */
  selectedPayrollUnpaidLeaves: PayrollUnpaidLeave[];

  /**
   * Gets the list of selected payroll projects costs for current payroll.
   */
  selectedPayrollProjectCosts: PayrollProjectCost[];

  /**
   * Gets the payroll element key for current payroll element.
   */
  payrollElementKey: string;

  /**
   * Shows or hide the payroll element formulas list.
   */
  payrollElementFormulasListVisibility = false;

  /**
   * Shows or hide the payroll provisions list.
   */
  payrollProvisionsListVisibility = false;

  /**
   * Shows or hide the payroll unpaid leaves list.
   */
  payrollUnpaidLeavesListVisibility = false;

  /**
   * The ngb-modal reference to use it when modal needed to be closed.
   */
  modal: NgbModalRef;

  /**
   * Gets the attachments as form-array.
   */
  get attachmentsForm(): FormArray {
    return this.payrollGroup?.controls.attachments as FormArray;
  }

  /**
   * @param modalService The modal service.
   */
  constructor(private modalService: NgbModal) {}

  /**
   * Adds a blank attachment form-control to the attachments form for quick start.
   */
  addBlankAttachment() {
    this.attachmentsForm.push(new FormControl());
  }

  /**
   * Maps and returns the set of payroll attachments into a set of urls.
   * @param attachments The payroll attachments to be mapped.
   */
  getAttachmentsUrls(attachments: PayrollAttachment[]): string[] {
    return attachments ? attachments.map((attachment) => attachment.url) : [];
  }

  /**
   * Opens the modal of the given templateRef.
   * @param modalRef The modal templateRef to be opened.
   */
  openModal(modalRef) {
    this.modalService.open(modalRef);
  }

  /**
   * Opens the modal of the given templateRef.
   * @param modalRef The modal templateRef to be opened.
   */
  openInfoModal(modalRef) {
    /**
     * Open the modal & listen for user press ESC key.
     */
    this.modal = this.modalService.open(modalRef, { size: 'xl' });
    this.modal.result.then(null, () => this.closeModal());
  }

  /**
   * Remove the payroll from the list.
   */
  remove() {
    this.removeChange.emit(null);
  }

  /**
   * Closes the currently opened modal.
   */
  closeModal() {
    this.modalService.dismissAll();
  }
}
