import { MeridiemIndicatorActionType, MeridiemIndicatorActions } from 'lookups/store/actions';
import { MeridiemIndicatorState } from 'lookups/store/states';

/**
 * The initial state from which the state starts.
 */
const initialState: MeridiemIndicatorState = {
  data: [],
  error: null,
  isLoaded: false,
  isLoading: false,
};

/**
 * The reducer function that is called in each action dispatch against the state.
 * @param state The current state.
 * @param action The action that will affect the state.
 */
export function MeridiemIndicatorReducer(
  state: MeridiemIndicatorState = initialState,
  action: MeridiemIndicatorActions
): MeridiemIndicatorState {
  switch (action.type) {
    case MeridiemIndicatorActionType.GET_ALL_MERIDIEM_INDICATORS: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case MeridiemIndicatorActionType.GET_ALL_MERIDIEM_INDICATORS_FAIL: {
      return {
        ...state,
        error: action.payload,
        isLoading: false,
        isLoaded: false,
      };
    }

    case MeridiemIndicatorActionType.GET_ALL_MERIDIEM_INDICATORS_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        isLoading: false,
        isLoaded: true,
      };
    }

    default:
      return state;
  }
}
