import { CreateUnitOfMeasureComponent } from 'stores/components/units-of-measure/create-unit-of-measure/create-unit-of-measure.component';
import { UpdateUnitOfMeasureComponent } from 'stores/components/units-of-measure/update-unit-of-measure/update-unit-of-measure.component';
import { UnitsOfMeasureListComponent } from 'stores/components/units-of-measure/units-of-measure-list/units-of-measure-list.component';
import { UnitsOfMeasureSearchComponent } from 'stores/components/units-of-measure/units-of-measure-search/units-of-measure-search.component';

export * from 'stores/components/units-of-measure/create-unit-of-measure/create-unit-of-measure.component';
export * from 'stores/components/units-of-measure/update-unit-of-measure/update-unit-of-measure.component';
export * from 'stores/components/units-of-measure/units-of-measure-list/units-of-measure-list.component';
export * from 'stores/components/units-of-measure/units-of-measure-search/units-of-measure-search.component';

/**
 * The set of components registered on this sub-module.
 */
export const COMPONENTS: any[] = [
  CreateUnitOfMeasureComponent,
  UpdateUnitOfMeasureComponent,
  UnitsOfMeasureListComponent,
  UnitsOfMeasureSearchComponent,
];
