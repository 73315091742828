import { Customer } from 'sales/models';
import { PaginationInfo } from 'shared/models';

/**
 * Represents the customers state.
 */
export class CustomersState {
  /**
   * The list of customers.
   */
  public data: Customer[];

  /**
   * The pagination info for the customers.
   */
  public paginationInfo: PaginationInfo;

  /**
   * An error that may resulting during processing some actions.
   */
  public error: any;

  /**
   * Determines if there is a running search process.
   */
  public isSearching: boolean;

  /**
   * Determines if the last search process has been ended successfully.
   */
  public isSearchCompleted: boolean;
}
