<!-- page header starts -->
<app-page-header [info]="pageInfo"></app-page-header>
<!-- page header ends -->

<!-- pos devices list starts -->
<div class="row">
  <div class="col-md-12">
    <div class="card">
      <div class="card-content">
        <div class="px-3 pt-3">
          <div class="row">
            <div class="col-md-12">
              <ul
                class="nav-tabs"
                ngbNav
                #nav="ngbNav"
                [(activeId)]="activePage"
                (activeIdChange)="selectedPageChanged($event)"
                [destroyOnHide]="false"
              >
                <li ngbNavItem="pos-devices">
                  <a ngbNavLink>
                    <i class="fa fa-desktop" [class.success]="activePage == 'pos-devices'"></i>
                    {{ 'SALES.POS_PRINTERS.POS_DEVICES' | translate }}
                  </a>
                  <ng-template ngbNavContent> <app-pos-devices-list></app-pos-devices-list> </ng-template>
                </li>

                <li ngbNavItem="pos-printers">
                  <a ngbNavLink>
                    <i class="fa fa-print" [class.success]="activePage == 'pos-printers'"></i>
                    {{ 'SALES.POS_PRINTERS.POS_PRINTERS' | translate }}
                  </a>
                  <ng-template ngbNavContent>
                    <app-pos-printers-list></app-pos-printers-list>
                  </ng-template>
                </li>
                <li ngbNavItem="casher-printers">
                  <a ngbNavLink>
                    <i class="fa fa-dollar" [class.success]="activePage == 'casher-printers'"></i>
                    {{ 'SALES.POS_PRINTERS.POS_CASHER_PRINTERS' | translate }}
                  </a>
                  <ng-template ngbNavContent>
                    <app-casher-printers-list></app-casher-printers-list>
                  </ng-template>
                </li>
                <li ngbNavItem="order-delivery-printers">
                  <a ngbNavLink>
                    <i class="fa fa-motorcycle" [class.success]="activePage == 'order-delivery-printers'"></i>
                    {{ 'SALES.POS_PRINTERS.POS_ORDERS_PRINTERS' | translate }}
                  </a>
                  <ng-template ngbNavContent>
                    <app-order-delivery-printers-list></app-order-delivery-printers-list>
                  </ng-template>
                </li>
                <li ngbNavItem="kitchen-printers">
                  <a ngbNavLink>
                    <i class="fa fa-fire" [class.success]="activePage == 'kitchen-printers'"></i>
                    {{ 'SALES.POS_PRINTERS.POS_KITCHEN_PRINTERS' | translate }}
                  </a>
                  <ng-template ngbNavContent>
                    <app-kitchen-printers-list></app-kitchen-printers-list>
                  </ng-template>
                </li>
              </ul>
              <div [ngbNavOutlet]="nav" class="mt-2"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- pos devices list ends -->
