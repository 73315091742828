import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { FormArray, FormGroup, FormControl } from '@angular/forms';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { LocationFormItem, Location } from 'stores/models';

@Component({
  selector: 'app-locations-form',
  templateUrl: './locations-form.component.html',
  styles: [],
})
export class LocationsFormComponent {
  /**
   * The confirm modal template reference.
   */
  @ViewChild('deleteModalRef') deleteModalRef: ElementRef<any>;

  /**
   * Sets the bank-accounts form-array.
   */
  @Input() formArray: FormArray;

  /**
   * Sets the name form-array in its parent form.
   * @default 'locations'
   */
  @Input() formArrayName = 'locations';

  /**
   * Adds a list of new bank account form items to the bank accounts form.
   * @param items The list of bank account form items to be added to the bank accounts form.
   */
  @Input() set newLocations(items: LocationFormItem[]) {
    if (!items?.length) {
      return;
    }

    items.forEach((item) => this.createLocationFormGroup(item));
  }

  /**
   * Sets a value indicates whether if the user should specify a location or not.
   * @default false
   */
  @Input() isLocationRequired = false;

  /**
   * Shows or hides the bank accounts list.
   */
  locationsListVisibility = false;

  /**
   * Indicates whether if we can add location or not.
   */
  @Input() allowAddLocation = true;

  /**
   * Indicates whether if we can clear locations or not.
   * @default `true`.
   */
  @Input() allowClearLocations = true;

  /**
   * @param modalService The modal service.
   */
  constructor(private modalService: NgbModal) {}

  /**
   * Creates & adds a new bank-account form-group with validations.
   * @param location The bank-account details to be bounded to the bank-account, If omitted a blank bank-account will be created.
   */
  addLocation() {
    this.locationsListVisibility = true;
  }

  /**
   * Open confirm delete all bank accounts modal .
   */
  openDeleteModal() {
    this.openModal(this.deleteModalRef);
  }

  /**
   * Opens the modal of the given templateRef.
   * @param modalRef The modal templateRef to be opened.
   */
  openModal(modalRef) {
    this.modalService.open(modalRef);
  }

  /**
   * Remove the bank-account at the given index from the bank accounts form.
   * @param index The index of the bank-account form group.
   */
  removeLocation(index: number) {
    this.formArray.removeAt(index);
  }

  /**
   * Remove all of the list of bank-accounts.
   *
   * It only adds one bank-account for quick start.
   */
  resetLocations() {
    this.formArray.clear();
  }

  /**
   * Handles the invoices select event.
   * @param invoices The newly selected invoices.
   */
  selectLocation(locations: Location[]) {
    if (locations) {
      locations.map((location) => this.createLocationFormGroup(this.createLocationFormItemFromLocation(location)));
    }
  }

  /**
   * Creates and returns a new bank account form item from a given bank account.
   * @param location The bank account to be used to create the bank account form item.
   */
  createLocationFormItemFromLocation(location: Location): LocationFormItem {
    return {
      locationId: location.id,
      location: location,
    };
  }

  /**
   * Create a new bank account form group from the provided bank account form item.
   * @param location The bank account form item that contains data about the new bank account.
   */
  createLocationFormGroup(location: LocationFormItem) {
    if (this.isLocationExists(location.location.id)) {
      return;
    }

    const formGroup = new FormGroup({
      locationId: new FormControl(location?.locationId ?? null),
      location: new FormControl(location?.location),
    });
    this.formArray.push(formGroup);
  }

  /**
   * Indicates whether if the given bank account id is already exists in the form array or not.
   * @param locationId The id of the bank account.
   * @returns `boolean`.
   */
  isLocationExists(locationId: number): boolean {
    return (this.formArray.value as LocationFormItem[]).some((locationItem) => locationItem.location.id === locationId);
  }
}
