import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, FormArray, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { skip, tap } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Decimal } from 'decimal.js';

import { CanComponentDeactivate } from 'auth/models';
import * as fromFinancesStore from 'finances/store';
import {
  APP_CONSTANTS,
  CustomValidators,
  NotificationMessage,
  NotificationService,
  PageInfo,
  TranslationService,
} from 'shared';
import { CreateJournalLineInput, JournalLineFormItem, Account, BankAccount } from 'finances/models';

/**
 * The payment pages.
 */
enum PAGES {
  details = 'details',
  journal = 'journal',
}

@Component({
  selector: 'app-create-bank-account-money-transfer',
  templateUrl: './create-bank-account-money-transfer.component.html',
  styles: [],
})
export class CreateBankAccountMoneyTransferComponent implements OnInit, OnDestroy, CanComponentDeactivate {
  /**
   * The confirm modal template reference.
   */
  @ViewChild('confirmModalRef') confirmModalRef: ElementRef<any>;

  /**
   * The reset modal template reference.
   */
  @ViewChild('resetModalRef') resetModalRef: ElementRef<any>;

  /**
   * The decimal mask.
   */
  readonly DECIMAL_MASK = APP_CONSTANTS.numeric.decimal.mask;

  /**
   * Gets or sets the information about the current page.
   */
  pageInfo: PageInfo = {
    title: 'FINANCES.BANK_ACCOUNTS.MONEY_TRANSFER_PAGE_TITLE',
    icon: 'fa fa-plus',
  };

  /**
   * Gets or sets the selected page.
   * @default 'details'
   */
  activePage: PAGES = PAGES.details;

  /**
   * The list of bank accounts transfer from.
   */
  fromBankAccounts: BankAccount[] = [];

  /**
   * The list of bank accounts transfer to.
   */
  toBankAccounts: BankAccount[] = [];

  /**
   * The list of transfer types.
   */
  transferTypes = [
    { key: 'CASH_TRANSFER', name: 'تحويل نقدي', nameEn: 'Cash transfer' },
    { key: 'BANK_TRANSFER', name: 'تحويل بنكي', nameEn: 'Bank transfer' },
  ];

  /**
   * Shows or hide the bank accounts list.
   */
  fromBankAccountsListVisibility = false;

  /**
   * Shows or hide the bank accounts list.
   */
  toBankAccountsListVisibility = false;

  /**
   * The id of the bank account.
   */
  bankAccountId: number;

  /**
   * Indicates whether there is a create payment process is running or not.
   */
  isCreating$: Observable<boolean>;

  /**
   * Gets the cash in banks account.
   */
  cashInBanksAccount: Account;

  /**
   * Gets the cash in hand account.
   */
  cashInHandAccount: Account;

  /**
   * Gets the bank expenses account.
   */
  bankExpensesAccount: Account;

  /**
   * Gets the value added tax debit account.
   */
  valueAddedTaxDebitAccount: Account;

  /**
   * The name of the bank account.
   */
  bankAccountName: string;

  /**
   * The create customer payments form.
   */
  form: FormGroup;

  /**
   * The set of subscriptions on this components,
   * these subscriptions must be unsubscribed before this component got destroyed.
   */
  subscriptions = new Subscription();

  /**
   * Sets the initial journal lines should be added to the journals form.
   * @param journal lines An object contains `count` of initial blank journal lines.
   * @param journal lines A single journal line details to be added to the form.
   * @param journal lines A list of journal lines details to be added to the form.
   */
  initialJournalLinesSubject: BehaviorSubject<
    { count: number } | JournalLineFormItem | JournalLineFormItem[]
  > = new BehaviorSubject(undefined);

  /**
   * Gets the journal-lines form-array.
   */
  get journalLinesForm(): FormArray {
    return this.form?.controls.lines as FormArray;
  }

  /**
   * Gets or sets the current selected language.
   */
  get currentLang() {
    return this.translationService.language;
  }

  /**
   * @param modalService The modal service.
   * @param notificationService the notification service.
   * @param financesStore$ the finances-store module.
   * @param translationService the translation service.
   * @param route The activated route.
   */
  constructor(
    private modalService: NgbModal,
    private notificationService: NotificationService,
    private financesStore$: Store<fromFinancesStore.FinancesState>,
    private translationService: TranslationService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.init();
  }

  /**
   * Destroy component data
   */
  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  /**
   * Initialize component data.
   */
  init() {
    this.initForm();
    this.initJournalData();

    /**
     * Load data.
     */
    this.isCreating$ = this.financesStore$.pipe(
      select(fromFinancesStore.getSelectedBankAccountLogMoneyTransferCreating)
    );

    /**
     * Reset form controls when new  bank account money transfer is created.
     */
    this.subscriptions.add(
      this.financesStore$
        .pipe(
          select(fromFinancesStore.getSelectedBankAccountLogMoneyTransferCreateCompleted),
          skip(1),
          tap((isCreated) => {
            if (isCreated) {
              this.initForm();
            }
          })
        )
        .subscribe()
    );

    /**
     * Fetch query params params.
     */
    if (this.route.snapshot.queryParamMap.has('bankAccountId')) {
      this.bankAccountId = +this.route.snapshot.queryParams.bankAccountId;
    }

    /**
     * Select the bank account currency.
     */
    this.subscriptions.add(
      this.financesStore$
        .pipe(
          select(fromFinancesStore.getSelectedBankAccount),
          tap((bankAccount) => {
            if (bankAccount) {
              this.bankAccountName = this.currentLang === 'en' ? bankAccount.nameEn : bankAccount.name;

              /* Update page info. */
              this.pageInfo = {
                ...this.pageInfo,
                subTitle: `(${this.bankAccountName})`,
              };
            }
          })
        )
        .subscribe()
    );
  }

  /**
   * Initialize forms and add validators.
   */
  initForm() {
    const valueFormControl = new FormControl('', [Validators.required, CustomValidators.gt(0)]);
    const bankFeeFormControl = new FormControl(null);
    const bankFeeTaxValueFormControl = new FormControl(null);
    const transferTypeFormControl = new FormControl(null, Validators.required);
    this.form = new FormGroup({
      fromBankAccountId: new FormControl(null, Validators.required),
      toBankAccountId: new FormControl(null, Validators.required),
      value: valueFormControl,
      bankFee: bankFeeFormControl,
      bankFeeTaxValue: bankFeeTaxValueFormControl,
      transferType: transferTypeFormControl,
      transactionDate: new FormControl(null),
      lines: new FormArray([], CustomValidators.arrayItems(2)),
    });

    /**
     * Subscription when selected transfer type & value of bank account money transfer changes.
     */
    this.subscriptions.add(transferTypeFormControl.valueChanges.subscribe(() => this.onTransferTypeOrValueChanges()));
    this.subscriptions.add(transferTypeFormControl.valueChanges.subscribe(() => this.onTransferTypeChange()));
    this.subscriptions.add(valueFormControl.valueChanges.subscribe(() => this.onTransferTypeOrValueChanges()));
    this.subscriptions.add(bankFeeFormControl.valueChanges.subscribe(() => this.onTransferTypeOrValueChanges()));

    this.subscriptions.add(
      bankFeeTaxValueFormControl.valueChanges.subscribe(() => this.onTransferTypeOrValueChanges())
    );

    this.setBlankJournalsCount();
  }

  /**
   * Initialize the journal data.
   */
  initJournalData() {
    /**
     * Fetch system accounts then fill the journal line form with its lines.
     */

    let isManualSearchTriggeredBeforeForCashInBanksAccount = false;
    this.subscriptions.add(
      /**
       * Account Cash In Bank Account.
       */
      this.financesStore$
        .pipe(
          select(fromFinancesStore.getCashInBanksAccount),
          tap((account) => {
            this.cashInBanksAccount = account;
            if (account) {
              this.onTransferTypeOrValueChanges();
            } else if (!account && !isManualSearchTriggeredBeforeForCashInBanksAccount) {
              isManualSearchTriggeredBeforeForCashInBanksAccount = true;
              this.financesStore$.dispatch(new fromFinancesStore.FindCashInBanksAccount());
            }
          })
        )
        .subscribe()
    );

    let isManualSearchTriggeredBeforeForCashInHandAccount = false;
    this.subscriptions.add(
      /**
       * cash in banks Account.
       */
      this.financesStore$
        .pipe(
          select(fromFinancesStore.getCashInHandAccount),
          tap((account) => {
            this.cashInHandAccount = account;
            if (account) {
              this.onTransferTypeOrValueChanges();
            } else if (!account && !isManualSearchTriggeredBeforeForCashInHandAccount) {
              isManualSearchTriggeredBeforeForCashInHandAccount = true;
              this.financesStore$.dispatch(new fromFinancesStore.FindCashInHandAccount());
            }
          })
        )
        .subscribe()
    );

    let isManualSearchTriggeredBeforeForBankExpensesAccount = false;
    this.subscriptions.add(
      /**
       * bank expenses Account.
       */
      this.financesStore$
        .pipe(
          select(fromFinancesStore.getBankExpensesAccount),
          tap((account) => {
            this.bankExpensesAccount = account;
            if (account) {
              this.onTransferTypeOrValueChanges();
            } else if (!account && !isManualSearchTriggeredBeforeForBankExpensesAccount) {
              isManualSearchTriggeredBeforeForBankExpensesAccount = true;
              this.financesStore$.dispatch(new fromFinancesStore.FindBankExpensesAccount());
            }
          })
        )
        .subscribe()
    );

    let isManualSearchTriggeredBeforeForValueAddedTaxDebitAccount = false;
    this.subscriptions.add(
      /**
       * value added tax debit Account.
       */
      this.financesStore$
        .pipe(
          select(fromFinancesStore.getValueAddedTaxDebitAccount),
          tap((account) => {
            this.valueAddedTaxDebitAccount = account;
            if (account) {
              this.onTransferTypeOrValueChanges();
            } else if (!account && !isManualSearchTriggeredBeforeForValueAddedTaxDebitAccount) {
              isManualSearchTriggeredBeforeForValueAddedTaxDebitAccount = true;
              this.financesStore$.dispatch(new fromFinancesStore.FindValueAddedTaxDebitAccount());
            }
          })
        )
        .subscribe()
    );
  }

  /**
   * Handles the change on `form.lines` form array or any form (group or control) within it.
   */
  onTransferTypeOrValueChanges() {
    const value = this.form.controls.value.value ?? 0;
    const bankFee = this.form.controls.bankFee.value ?? 0;
    const bankFeeTaxValue = this.form.controls.bankFeeTaxValue.value ?? 0;
    const totalValue = Decimal.sum(parseFloat(value), parseFloat(bankFee), parseFloat(bankFeeTaxValue) ?? 0).toNumber();
    const transferType = this.form.controls.transferType.value;

    /**
     * Set Journal Lines based on the entered refund request lines data.
     */
    this.resetJournalLines();

    /**
     * The list of journal lines to be added to the form.
     */
    const lines: JournalLineFormItem[] = [];

    /**
     * Add line for cash in hand Account.
     */
    if (transferType === 'CASH_TRANSFER' && this.cashInHandAccount) {
      lines.push({
        accountId: this.cashInHandAccount.id,
        account: this.cashInHandAccount,
        costCenterId: null,
        debit: value,
        credit: 0,
        notes: '',
        disableAccountChange: true,
        disableDebitAndCreditValueChange: true,
      });
      lines.push({
        accountId: this.cashInHandAccount.id,
        account: this.cashInHandAccount,
        costCenterId: null,
        debit: 0,
        credit: value,
        notes: '',
        disableAccountChange: true,
        disableDebitAndCreditValueChange: true,
      });
    }

    /**
     * Add line for cash in banks Account.
     */
    if (
      transferType === 'BANK_TRANSFER' &&
      this.cashInBanksAccount &&
      this.bankExpensesAccount &&
      this.valueAddedTaxDebitAccount
    ) {
      lines.push({
        accountId: this.cashInBanksAccount.id,
        account: this.cashInBanksAccount,
        costCenterId: null,
        debit: value,
        credit: 0,
        notes: '',
        disableAccountChange: true,
        disableDebitAndCreditValueChange: true,
      });
      /**
       * Add line for bank expenses account.
       */

      if (bankFee > 0) {
        lines.push({
          accountId: this.bankExpensesAccount.id,
          account: this.bankExpensesAccount,
          costCenterId: null,
          debit: bankFee,
          credit: 0,
          notes: '',
          disableAccountChange: true,
          disableDebitAndCreditValueChange: true,
        });
      }

      /**
       * Add line for bank fee tax value account.
       */
      if (bankFeeTaxValue > 0) {
        lines.push({
          accountId: this.valueAddedTaxDebitAccount.id,
          account: this.valueAddedTaxDebitAccount,
          costCenterId: null,
          debit: bankFeeTaxValue,
          credit: 0,
          notes: '',
          disableAccountChange: true,
          disableDebitAndCreditValueChange: true,
        });
      }

      lines.push({
        accountId: this.cashInBanksAccount.id,
        account: this.cashInBanksAccount,
        costCenterId: null,
        debit: 0,
        credit: totalValue,
        notes: '',
        disableAccountChange: true,
        disableDebitAndCreditValueChange: true,
      });
    }

    this.initialJournalLinesSubject.next(lines);
  }

  /**
   * Sets the conditional validation for the form based on the selected transfer type id.
   */
  onTransferTypeChange() {
    const transferType = this.form.controls.transferType.value;

    if (transferType === 'BANK_TRANSFER') {
      this.form.controls.bankFee.setValidators([Validators.required, CustomValidators.gte(0)]);
      this.form.controls.bankFeeTaxValue.setValidators([Validators.required, CustomValidators.gte(0)]);
    } else {
      this.form.controls.bankFee.clearValidators();
      this.form.controls.bankFee.setValue(null);
      this.form.controls.bankFeeTaxValue.clearValidators();
      this.form.controls.bankFeeTaxValue.setValue(null);
    }
    this.form.controls.bankFee.updateValueAndValidity();
    this.form.controls.bankFeeTaxValue.updateValueAndValidity();
  }

  /**
   * Sets the count of the journal lines should be added to the journals form for quick start..
   */
  setBlankJournalsCount() {
    this.initialJournalLinesSubject.next({ count: 0 });
  }

  /**
   * Resets journal lines data.
   */
  resetJournalLines() {
    this.journalLinesForm.clear();
  }

  /**
   * Opens the modal of the given templateRef.
   * @param modalRef The modal templateRef to be opened.
   */
  openModal(modalRef) {
    this.modalService.open(modalRef);
  }

  /**
   * Open confirm reset invoice modal .
   */
  openResetModal() {
    this.openModal(this.resetModalRef);
  }

  /**
   * Resets all form data.
   *
   * It only adds one product for quick start.
   */
  resetForm() {
    this.form.reset({ transactionDate: {} });
    this.resetJournalLines();
  }

  /**
   * Submits the form.
   */
  submit() {
    let errorMessage = new NotificationMessage();

    if (this.form.invalid) {
      if (this.form.get('fromBankAccountId').invalid) {
        errorMessage.title = this.translationService.translate(
          'FINANCES.BANK_ACCOUNTS.BANK_ACCOUNT_DATA_VALIDATION.FROM_BANK_ACCOUNT_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate(
            'FINANCES.BANK_ACCOUNTS.BANK_ACCOUNT_DATA_VALIDATION.BANK_ACCOUNT_IS_REQUIRED'
          ),
        ];
        this.activePage = PAGES.details;
      } else if (this.form.get('toBankAccountId').invalid) {
        errorMessage.title = this.translationService.translate(
          'FINANCES.BANK_ACCOUNTS.BANK_ACCOUNT_DATA_VALIDATION.TO_BANK_ACCOUNT_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate(
            'FINANCES.BANK_ACCOUNTS.BANK_ACCOUNT_DATA_VALIDATION.BANK_ACCOUNT_IS_REQUIRED'
          ),
        ];
        this.activePage = PAGES.details;
      } else if (this.form.get('transferType').invalid) {
        errorMessage.title = this.translationService.translate(
          'FINANCES.BANK_ACCOUNTS.BANK_ACCOUNT_DATA_VALIDATION.TRANSFER_TYPE_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate(
            'FINANCES.BANK_ACCOUNTS.BANK_ACCOUNT_DATA_VALIDATION.TRANSFER_TYPE_IS_REQUIRED'
          ),
        ];
        this.activePage = PAGES.details;
      } else if (this.form.get('value').invalid) {
        errorMessage.title = this.translationService.translate(
          'FINANCES.BANK_ACCOUNTS.BANK_ACCOUNT_DATA_VALIDATION.TRANSFER_VALUE_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate(
            'FINANCES.BANK_ACCOUNTS.BANK_ACCOUNT_DATA_VALIDATION.TRANSFER_VALUE_IS_REQUIRED'
          ),
          this.translationService.translate(
            'FINANCES.BANK_ACCOUNTS.BANK_ACCOUNT_DATA_VALIDATION.TRANSFER_VALUE_LENGTH_ERROR'
          ),
        ];
        this.activePage = PAGES.details;
      } else if (this.form.get('bankFee').invalid) {
        errorMessage.title = this.translationService.translate(
          'FINANCES.BANK_ACCOUNTS.BANK_ACCOUNT_DATA_VALIDATION.BANK_FEE_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate(
            'FINANCES.BANK_ACCOUNTS.BANK_ACCOUNT_DATA_VALIDATION.BANK_FEE_LENGTH_ERROR'
          ),
        ];
        this.activePage = PAGES.details;
      } else if (this.form.get('bankFeeTaxValue').invalid) {
        errorMessage.title = this.translationService.translate(
          'FINANCES.BANK_ACCOUNTS.BANK_ACCOUNT_DATA_VALIDATION.BANK_FEE_TAX_VALUE_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate(
            'FINANCES.BANK_ACCOUNTS.BANK_ACCOUNT_DATA_VALIDATION.BANK_FEE_TAX_VALUE_LENGTH_ERROR'
          ),
        ];
        this.activePage = PAGES.details;
      } else if (this.form.get('lines').invalid) {
        /**
         * Check if journal lines count < 2.
         */
        if (this.journalLinesForm.controls.length < 2) {
          errorMessage.title = this.translationService.translate(
            'FINANCES.BANK_ACCOUNTS.BANK_ACCOUNT_DATA_VALIDATION.JOURNALS_LINES_ERROR'
          );
          errorMessage.body = [
            this.translationService.translate(
              'FINANCES.BANK_ACCOUNTS.BANK_ACCOUNT_DATA_VALIDATION.JOURNALS_LINES_COUNT_ERROR'
            ),
          ];
        } else {
          /**
           * Check if some of journals has errors.
           */
          for (let index = 0; index < this.journalLinesForm.controls.length; index++) {
            const journal = this.journalLinesForm.controls[index];

            if (journal.valid) {
              continue;
            }

            errorMessage.title = this.translationService.translate(
              'FINANCES.BANK_ACCOUNTS.BANK_ACCOUNT_DATA_VALIDATION.JOURNAL_LINE_NUMBER_ERROR',
              {
                journalNumber: index + 1,
              }
            );
            errorMessage.body = [];

            if (journal.get('accountId').invalid) {
              errorMessage.body.push(
                this.translationService.translate('FINANCES.BANK_ACCOUNTS.BANK_ACCOUNT_DATA_VALIDATION.ACCOUNT_ERROR')
              );
              errorMessage.body.push(
                this.translationService.translate(
                  'FINANCES.BANK_ACCOUNTS.BANK_ACCOUNT_DATA_VALIDATION.ACCOUNT_IS_REQUIRED'
                )
              );
            }

            if (journal.get('credit').invalid) {
              errorMessage.body.push(
                this.translationService.translate(
                  'FINANCES.BANK_ACCOUNTS.BANK_ACCOUNT_DATA_VALIDATION.CREDIT_IS_REQUIRED'
                )
              );
            }

            if (journal.get('debit').invalid) {
              errorMessage.body.push(
                this.translationService.translate(
                  'FINANCES.BANK_ACCOUNTS.BANK_ACCOUNT_DATA_VALIDATION.DEBIT_IS_REQUIRED'
                )
              );
            }

            if (journal.get('costCenterId').invalid) {
              errorMessage.body.push(
                this.translationService.translate(
                  'FINANCES.BANK_ACCOUNTS.BANK_ACCOUNT_DATA_VALIDATION.COST_CENTER_ERROR'
                )
              );
              errorMessage.body.push(
                this.translationService.translate(
                  'FINANCES.BANK_ACCOUNTS.BANK_ACCOUNT_DATA_VALIDATION.COST_CENTER_IS_REQUIRED'
                )
              );
            }

            if (journal.get('notes').invalid) {
              errorMessage.body.push(
                this.translationService.translate('FINANCES.BANK_ACCOUNTS.BANK_ACCOUNT_DATA_VALIDATION.NOTES_ERROR')
              );
              errorMessage.body.push(
                this.translationService.translate(
                  'FINANCES.BANK_ACCOUNTS.BANK_ACCOUNT_DATA_VALIDATION.NOTES_IS_REQUIRED'
                )
              );
              errorMessage.body.push(
                this.translationService.translate(
                  'FINANCES.BANK_ACCOUNTS.BANK_ACCOUNT_DATA_VALIDATION.NOTES_REQUIRED_LENGTH_ERROR'
                )
              );
            }

            break;
          }
        }

        this.activePage = PAGES.journal;
      }

      this.form.markAllAsTouched();
      return this.notificationService.warningWithTitle(errorMessage);
    }

    errorMessage = new NotificationMessage();
    const lines: JournalLineFormItem[] = this.journalLinesForm.value;

    if (
      lines.some((line) => line.debit === 0 && line.credit === 0) ||
      lines.some((line) => line.debit !== 0 && line.credit !== 0)
    ) {
      errorMessage.title = this.translationService.translate(
        'FINANCES.BANK_ACCOUNTS.BANK_ACCOUNT_DATA_VALIDATION.JOURNALS_LINES_TITLE_ERROR'
      );
      errorMessage.body = [
        this.translationService.translate('FINANCES.BANK_ACCOUNTS.BANK_ACCOUNT_DATA_VALIDATION.DEBIT_CREDIT_ERROR'),
        this.translationService.translate(
          'FINANCES.BANK_ACCOUNTS.BANK_ACCOUNT_DATA_VALIDATION.JOURNALS_LINES_TYPE_ERROR'
        ),
      ];
      this.activePage = PAGES.journal;

      this.form.markAllAsTouched();
      return this.notificationService.warningWithTitle(errorMessage);
    } else if (
      !Decimal.sum(0, ...lines.filter((line) => !!line.debit).map((line) => line.debit)).eq(
        Decimal.sum(0, ...lines.filter((line) => !!line.credit).map((line) => line.credit))
      )
    ) {
      errorMessage.title = this.translationService.translate(
        'FINANCES.BANK_ACCOUNTS.BANK_ACCOUNT_DATA_VALIDATION.JOURNALS_LINES_TOTAL_ERROR'
      );
      errorMessage.body = [
        this.translationService.translate(
          'FINANCES.BANK_ACCOUNTS.BANK_ACCOUNT_DATA_VALIDATION.JOURNALS_LINES_TOTAL_VALUE_ERROR'
        ),
      ];
      this.activePage = PAGES.journal;

      this.form.markAllAsTouched();
      return this.notificationService.warningWithTitle(errorMessage);
    }

    this.openModal(this.confirmModalRef);
  }

  /**
   * Confirms the form submit.
   */
  confirm() {
    let fromDateFormatted;
    const { transactionDate } = this.form.value;

    if (transactionDate) {
      fromDateFormatted = new Date(transactionDate.year, transactionDate.month - 1, transactionDate.day);
      fromDateFormatted.setHours(new Date().getHours(), new Date().getMinutes());
    }

    /**
     * The list of lines in the lines form.
     */
    const lines = this.journalLinesForm.value
      .filter((line: JournalLineFormItem) => line.credit > 0 || line.debit > 0)
      .map((line) => {
        const inputLine: CreateJournalLineInput = {
          accountId: line.accountId,
          debit: line.debit,
          credit: line.credit,
          costCenterId: line.costCenterId,
          notes: line.notes,
        };

        return inputLine;
      });

    this.financesStore$.dispatch(
      new fromFinancesStore.CreateBankAccountLogMoneyTransfer({
        bankAccountId: this.bankAccountId,
        fromBankAccountId: this.form.value.fromBankAccountId,
        toBankAccountId: this.form.value.toBankAccountId,
        value: this.form.value.value,
        bankFee: this.form.value.bankFee,
        bankFeeTaxValue: this.form.value.bankFeeTaxValue,
        transactionDate: fromDateFormatted ? fromDateFormatted : null,
        lines,
      })
    );
  }

  /**
   * Selects the newly selected bank account from the bank accounts search list.
   * @param bankAccounts The list of newly selected bank accounts to select the only one in the list.
   */
  selectFromBankAccount([fromBankAccount]: BankAccount[]) {
    if (fromBankAccount) {
      this.fromBankAccounts = [fromBankAccount];
      this.form.patchValue({ fromBankAccountId: fromBankAccount.id });
    }
  }

  /**
   * Selects the newly selected bank account from the bank accounts search list.
   * @param bankAccounts The list of newly selected bank accounts to select the only one in the list.
   */
  selectToBankAccount([toBankAccount]: BankAccount[]) {
    if (toBankAccount) {
      this.toBankAccounts = [toBankAccount];
      this.form.patchValue({ toBankAccountId: toBankAccount.id });
    }
  }

  /**
   * Confirms leaving the page before routing.
   */
  confirmDeactivate() {
    if (this.form.dirty === true) {
      /**
       * Message to warn the user before leaving the detailed form.
       */
      return confirm(this.translationService.translate('SHARED.CONFIRM.LEAVING'));
    }
    return true;
  }
}
