import { createSelector } from '@ngrx/store';

import { selectLookupsState } from 'lookups/store/selectors/feature.selectors';
import { LookupsState, PettyCashTypeState } from 'lookups/store/states';

/**
 * Gets the pettyCashType state.
 */
const selectPettyCashTypes = createSelector(selectLookupsState, (state: LookupsState) => state.pettyCashTypes);

/**
 * Gets the list of loaded pettyCashTypes.
 */
export const getPettyCashTypes = createSelector(selectPettyCashTypes, (state: PettyCashTypeState) => state.data);

/**
 * Gets the error that occurred in the state.
 */
export const getPettyCashTypesError = createSelector(selectPettyCashTypes, (state: PettyCashTypeState) => state.error);

/**
 * Determines if there is a running load process.
 */
export const getPettyCashTypesIsLoading = createSelector(
  selectPettyCashTypes,
  (state: PettyCashTypeState) => state.isLoading
);

/**
 * Determines if the last load process has been ended successfully.
 */
export const getPettyCashTypesIsLoaded = createSelector(
  selectPettyCashTypes,
  (state: PettyCashTypeState) => state.isLoaded
);
