import { LoanPaymentType } from 'lookups/models';

/**
 * Represents the loan payment types state.
 */
export class LoanPaymentTypeState {
  /**
   * The list of loan payment types.
   */
  public data: LoanPaymentType[];

  /**
   * An error that may resulting during processing some actions.
   */
  public error: any;

  /**
   * Determines if there is a running load process.
   */
  public isLoading: boolean;

  /**
   * Determines if the last load process has been ended successfully.
   */
  public isLoaded: boolean;
}
