import { findIndex, remove } from 'lodash';

import { ProductClassesActions, ProductClassesActionType } from 'stores/store/actions';
import { ProductClassState } from 'stores/store/states';

/**
 * The initial state from which the state starts.
 */
const initialState: ProductClassState = {
  data: [],
  productClassesForSalesScreen: [],
  paginationInfo: {
    total: 0,
    page: 1,
    pageSize: 0,
    count: 0,
  },
  error: null,
  isSearching: false,
  isSearchCompleted: false,
  isProductClassesForSalesScreenSearching: false,
  isProductClassesForSalesScreenSearchCompleted: false,
  selectedProductClass: null,
  isFinding: false,
  isFindCompleted: false,
  isCreating: false,
  isCreateCompleted: false,
  isUpdating: false,
  isUpdateCompleted: false,
  isDeleting: false,
  isDeleteCompleted: false,
};

/**
 * The reducer function that is called in each action dispatch against the state.
 * @param state The current state.
 * @param action The action that will affect the state.
 */
export function productClassesReducer(
  state: ProductClassState = initialState,
  action: ProductClassesActions
): ProductClassState {
  switch (action.type) {
    //#region SEARCH_PRODUCT_CLASSES

    case ProductClassesActionType.SEARCH_PRODUCT_CLASSES:
    case ProductClassesActionType.SEARCH_PRODUCT_CLASSES_WITHOUT_PAGINATION: {
      return {
        ...state,
        isSearching: true,
        isSearchCompleted: false,
        error: null,
      };
    }

    case ProductClassesActionType.SEARCH_PRODUCT_CLASSES_FAIL: {
      return {
        ...state,
        error: action.payload,
        isSearching: false,
        isSearchCompleted: false,
      };
    }

    case ProductClassesActionType.SEARCH_PRODUCT_CLASSES_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        paginationInfo: {
          ...action.payload.meta,
        },
        error: null,
        isSearching: false,
        isSearchCompleted: true,
      };
    }

    //#endregion SEARCH_PRODUCT_CLASSES

    //#region SEARCH_PRODUCT_CLASSES_FOR_SALES_SCREEN

    case ProductClassesActionType.SEARCH_PRODUCT_CLASSES_FOR_SALES_SCREEN: {
      return {
        ...state,
        isProductClassesForSalesScreenSearching: true,
        isProductClassesForSalesScreenSearchCompleted: false,
        error: null,
      };
    }

    case ProductClassesActionType.SEARCH_PRODUCT_CLASSES_FOR_SALES_SCREEN_FAIL: {
      return {
        ...state,
        error: action.payload,
        isProductClassesForSalesScreenSearching: false,
        isProductClassesForSalesScreenSearchCompleted: false,
      };
    }

    case ProductClassesActionType.SEARCH_PRODUCT_CLASSES_FOR_SALES_SCREEN_SUCCESS: {
      return {
        ...state,
        productClassesForSalesScreen: action.payload.data,
        error: null,
        isProductClassesForSalesScreenSearching: false,
        isProductClassesForSalesScreenSearchCompleted: true,
      };
    }

    //#endregion SEARCH_PRODUCT_CLASSES_FOR_SALES_SCREEN

    //#region FIND_PRODUCT_CLASS

    case ProductClassesActionType.FIND_PRODUCT_CLASS: {
      return {
        ...state,
        selectedProductClass: null,
        error: null,
        isFinding: true,
        isFindCompleted: false,
      };
    }

    case ProductClassesActionType.FIND_PRODUCT_CLASS_FAIL: {
      return {
        ...state,
        error: action.payload,
        isFinding: false,
        isFindCompleted: false,
      };
    }

    case ProductClassesActionType.FIND_PRODUCT_CLASS_SUCCESS: {
      let productClasses = [...state.data];
      const productClassIndex = findIndex(productClasses, (productClass) => productClass.id === action.payload.data.id);

      /* If product class was found, update it. */
      if (productClassIndex >= 0) {
        productClasses[productClassIndex] = action.payload.data;
      } else {
        /* else, insert it to the beginning of the array. */
        productClasses = [action.payload.data, ...productClasses];
      }

      return {
        ...state,
        data: productClasses,
        selectedProductClass: action.payload.data,
        error: null,
        isFinding: false,
        isFindCompleted: true,
      };
    }

    //#endregion FIND_PRODUCT_CLASS

    //#region CREATE_PRODUCT_CLASS

    case ProductClassesActionType.CREATE_PRODUCT_CLASS: {
      return {
        ...state,
        isCreating: true,
        isCreateCompleted: false,
        error: null,
      };
    }

    case ProductClassesActionType.CREATE_PRODUCT_CLASS_FAIL: {
      return {
        ...state,
        error: action.payload,
        isCreating: false,
        isCreateCompleted: false,
      };
    }

    case ProductClassesActionType.CREATE_PRODUCT_CLASS_SUCCESS: {
      return {
        ...state,
        data: [action.payload.data, ...state.data],
        selectedProductClass: action.payload.data,
        error: null,
        isCreating: false,
        isCreateCompleted: true,
      };
    }

    //#endregion CREATE_PRODUCT_CLASS

    //#region UPDATE_PRODUCT_CLASS

    case ProductClassesActionType.UPDATE_PRODUCT_CLASS:
    case ProductClassesActionType.UPDATE_PRODUCT_CLASS_PHOTO:
    case ProductClassesActionType.DELETE_PRODUCT_CLASS_PHOTO: {
      return {
        ...state,
        isUpdating: true,
        isUpdateCompleted: false,
        error: null,
      };
    }

    case ProductClassesActionType.UPDATE_PRODUCT_CLASS_FAIL: {
      return {
        ...state,
        error: action.payload,
        isUpdating: false,
        isUpdateCompleted: false,
      };
    }

    case ProductClassesActionType.UPDATE_PRODUCT_CLASS_SUCCESS: {
      let productClasses = [...state.data];
      const productClassIndex = findIndex(productClasses, (productClass) => productClass.id === action.payload.data.id);

      /* If product class was found, update it. */
      if (productClassIndex >= 0) {
        productClasses[productClassIndex] = action.payload.data;
      } else {
        /* else, insert it to the beginning of the array. */
        productClasses = [action.payload.data, ...productClasses];
      }

      return {
        ...state,
        data: productClasses,
        selectedProductClass: action.payload.data,
        error: null,
        isUpdating: false,
        isUpdateCompleted: true,
      };
    }

    //#endregion UPDATE_PRODUCT_CLASS

    //#region DELETE_PRODUCT_CLASS

    case ProductClassesActionType.DELETE_PRODUCT_CLASS: {
      return {
        ...state,
        isDeleting: true,
        isDeleteCompleted: false,
        error: null,
      };
    }

    case ProductClassesActionType.DELETE_PRODUCT_CLASS_FAIL: {
      return {
        ...state,
        error: action.payload,
        isDeleting: false,
        isDeleteCompleted: false,
      };
    }

    case ProductClassesActionType.DELETE_PRODUCT_CLASS_SUCCESS: {
      const productClasses = [...state.data];

      /* Remove the deleted product class from the array. */
      remove(productClasses, (productClass) => productClass.id === action.payload.data.id);

      return {
        ...state,
        data: productClasses,
        selectedProductClass: action.payload.data,
        error: null,
        isDeleting: false,
        isDeleteCompleted: true,
      };
    }

    //#endregion DELETE_PRODUCT_CLASS

    default:
      return state;
  }
}
