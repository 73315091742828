import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';

import { AppHttpResponse } from 'shared';
import { PettyCashRefund, CreatePettyCashRefundInput } from 'finances/models';

/**
 * The petty cash refund services includes functionality to create, search and findById a petty cash refund.
 */

@Injectable()
export class PettyCashRefundsService {
  /**
   * The relative route for the petty cash refunds.
   *
   * No leading or trailing slashes required.
   */
  private pettyCashRefundsApi = 'finances/pettyCash/refunds';

  constructor(private http: HttpClient) {}

  /**
   * Creates a new petty cash refund from the provided data.
   * @param data The new petty cash refund data.
   */
  public create(data: CreatePettyCashRefundInput): Observable<AppHttpResponse<PettyCashRefund>> {
    const formData: any = new FormData();
    formData.append('pettyCashId', data.pettyCashId);
    formData.append('value', data.value);
    formData.append('bankFee', data.bankFee);
    formData.append('bankFeeTaxValue', data.bankFeeTaxValue);
    formData.append('bankAccountId', data.bankAccountId);
    formData.append('transactionDate', data.transactionDate);
    formData.append('notes', data.notes);

    /**
     * Append journal lines to the form data.
     */
    for (let index = 0; index < data.lines.length; index++) {
      formData.append(`lines[${index}][accountId]`, data.lines[index].accountId);
      formData.append(`lines[${index}][credit]`, data.lines[index].credit);
      formData.append(`lines[${index}][debit]`, data.lines[index].debit);
      formData.append(`lines[${index}][costCenterId]`, data.lines[index].costCenterId);
      formData.append(`lines[${index}][notes]`, data.lines[index].notes);
    }

    return this.http.post<AppHttpResponse<PettyCashRefund>>(`${this.pettyCashRefundsApi}`, data);
  }

  /**
   * Searches in the petty cash refund by pettyCash or fromDate or toDate.
   * @param pettyCash The pettyCash of the petty cash refund.
   * @param fromDate The fromDate of the petty cash refund.
   * @param toDate The toDate of the petty cash refund.
   * @param page The current pagination page number.
   * @param pageSize The maximum number of petty cash refund allowed per one pagination page.
   */
  public search(
    pettyCash: number[],
    fromDate: Date,
    toDate: Date,
    page: number,
    pageSize: number
  ): Observable<AppHttpResponse<PettyCashRefund[]>> {
    const params = new HttpParams()
      .set('pettyCash', pettyCash.join(','))
      .set('fromDate', fromDate.toISOString())
      .set('toDate', toDate.toISOString())
      .set('page', page.toString())
      .set('pageSize', pageSize.toString());
    return this.http.get<AppHttpResponse<PettyCashRefund[]>>(`${this.pettyCashRefundsApi}`, { params });
  }

  /**
   * Finds the petty cash refund with the given id.
   * @param id The id of the petty cash refund.
   */
  public findById(id: number): Observable<AppHttpResponse<PettyCashRefund>> {
    return this.http.get<AppHttpResponse<PettyCashRefund>>(`${this.pettyCashRefundsApi}/${id}`);
  }
}
