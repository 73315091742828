import { arrayItems } from 'shared/validators/array-items.validator';
import { positiveNumber } from 'shared/validators/positive-number.validator';
import { CustomValidators as NGXCustomValidators } from 'ngx-custom-validators';

/** Custom validators for AbstractControls. */
export const CustomValidators = {
  /**
   * Validates that the FormControl value -which is supposed to be an array-
   * has at least the given count of items.
   * @param minItemsCount The minimum items count allowed in the array.
   */
  arrayItems,
  /**
   * Validates that the FormControl value is greater than `zero`.
   */
  positiveNumber,

  ...NGXCustomValidators,
};
