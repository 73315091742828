import { Injectable } from '@angular/core';

import { of } from 'rxjs';
import { switchMap, map, catchError, tap } from 'rxjs/operators';
import { Effect, ofType, Actions } from '@ngrx/effects';

import { NotificationService, StatusCode, PAGINATION, TranslationService } from 'shared';
import { PettyCashRefundsService } from 'finances/services';
import {
  SearchPettyCashRefund,
  SearchPettyCashRefundSuccess,
  SearchPettyCashRefundFail,
  FindPettyCashRefund,
  FindPettyCashRefundSuccess,
  FindPettyCashRefundFail,
  CreatePettyCashRefundSuccess,
  CreatePettyCashRefundFail,
  CreatePettyCashRefund,
  PettyCashRefundActionType,
} from 'finances/store/actions';

@Injectable()
export class PettyCashRefundEffects {
  constructor(
    private actions$: Actions,
    private pettyCashRefundService: PettyCashRefundsService,
    private notificationService: NotificationService,
    private translationService: TranslationService
  ) {}

  /* ========================= SEARCH_PETTY_CASH_REFUND =================================== */
  @Effect()
  search$ = this.actions$.pipe(
    ofType(PettyCashRefundActionType.SEARCH_PETTY_CASH_REFUND),
    switchMap(({ payload }: SearchPettyCashRefund) =>
      this.pettyCashRefundService
        .search(payload?.pettyCash, payload.fromDate, payload.toDate, payload.page, PAGINATION.PettyCashRefund)
        .pipe(
          map((response) => new SearchPettyCashRefundSuccess(response)),
          catchError((error) => of(new SearchPettyCashRefundFail(error)))
        )
    )
  );

  /* ========================= FIND_PETTY_CASH_REFUND =================================== */
  @Effect()
  find$ = this.actions$.pipe(
    ofType(PettyCashRefundActionType.FIND_PETTY_CASH_REFUND),
    switchMap((action: FindPettyCashRefund) =>
      this.pettyCashRefundService.findById(action.payload).pipe(
        map((response) => new FindPettyCashRefundSuccess(response)),
        catchError((error) => of(new FindPettyCashRefundFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  findFail$ = this.actions$.pipe(
    ofType(PettyCashRefundActionType.FIND_PETTY_CASH_REFUND_FAIL),
    tap((action: FindPettyCashRefundFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.translationService.translate('FINANCES.PETTY_CASH_REFUND.PETTY_CASH_REFUND_NOT_FOUND');
      }
    })
  );

  /* ========================= CREATE_PETTY_CASH_REFUND =================================== */
  @Effect()
  create$ = this.actions$.pipe(
    ofType(PettyCashRefundActionType.CREATE_PETTY_CASH_REFUND),
    switchMap((action: CreatePettyCashRefund) =>
      this.pettyCashRefundService.create(action.payload).pipe(
        map((response) => new CreatePettyCashRefundSuccess(response)),
        catchError((error) => of(new CreatePettyCashRefundFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  createSuccess$ = this.actions$.pipe(
    ofType(PettyCashRefundActionType.CREATE_PETTY_CASH_REFUND_SUCCESS),
    tap((action: CreatePettyCashRefundSuccess) => {
      this.notificationService.success(
        this.translationService.translate('FINANCES.PETTY_CASH_REFUND.PETTY_CASH_REFUND_ADDED')
      );
    })
  );

  @Effect({ dispatch: false })
  createFail$ = this.actions$.pipe(
    ofType(PettyCashRefundActionType.CREATE_PETTY_CASH_REFUND_FAIL),
    tap((action: CreatePettyCashRefundFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );
}
