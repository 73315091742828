import { createSelector } from '@ngrx/store';

import { CostCentersState, FinancesState } from 'finances/store/states';
import { selectFinancesState } from 'finances/store/selectors/feature.selectors';

/**
 * Gets the cost centers state.
 */
const selectCostCenterState = createSelector(selectFinancesState, (state: FinancesState) => state.costCenters);

/**
 * Gets the list of loaded cost centers.
 */
export const getCostCenters = createSelector(selectCostCenterState, (state: CostCentersState) => state.data);

/**
 * Gets the loaded general cost center.
 */
export const getGeneralCostCenter = createSelector(
  selectCostCenterState,
  (state: CostCentersState) => state.generalCostCenterData
);

/**
 * Gets the cost centers pagination info.
 */
export const getCostCentersPaginationInfo = createSelector(
  selectCostCenterState,
  (state: CostCentersState) => state.paginationInfo
);

/**
 * Gets the error that occurred in the state.
 */
export const getCostCentersError = createSelector(selectCostCenterState, (state: CostCentersState) => state.error);

/**
 * Determines if there is a running search process.
 */
export const getCostCentersSearching = createSelector(
  selectCostCenterState,
  (state: CostCentersState) => state.isSearching
);

/**
 * Determines if the last search process has been ended successfully.
 */
export const getCostCentersSearchCompleted = createSelector(
  selectCostCenterState,
  (state: CostCentersState) => state.isSearchCompleted
);

/**
 * Determines if there is a running get process.
 */
export const gettingGeneralCostCenter = createSelector(
  selectCostCenterState,
  (state: CostCentersState) => state.isGettingGeneralCostCenter
);

/**
 * Determines if the last get process has been ended successfully.
 */
export const getGeneralCostCenterCompleted = createSelector(
  selectCostCenterState,
  (state: CostCentersState) => state.isGetGeneralCostCenterCompleted
);

/**
 * Gets the selected cost center.
 */
export const getSelectedCostCenter = createSelector(
  selectCostCenterState,
  (state: CostCentersState) => state.selectedCostCenter
);

/**
 * Determines if there is a running find cost center process.
 */
export const getCostCentersFinding = createSelector(
  selectCostCenterState,
  (state: CostCentersState) => state.isFinding
);

/**
 * Determines if the last find cost center process has been ended successfully.
 */
export const getCostCentersFindCompleted = createSelector(
  selectCostCenterState,
  (state: CostCentersState) => state.isFindCompleted
);

/**
 * Determines if there is a running create process.
 */
export const getSelectedCostCenterCreating = createSelector(
  selectCostCenterState,
  (state: CostCentersState) => state.isCreating
);

/**
 * Determines if the last create process has been ended successfully.
 */
export const getSelectedCostCenterCreateCompleted = createSelector(
  selectCostCenterState,
  (state: CostCentersState) => state.isCreateCompleted
);

/**
 * Determines if there is a running update process.
 */
export const getSelectedCostCenterUpdating = createSelector(
  selectCostCenterState,
  (state: CostCentersState) => state.isUpdating
);

/**
 * Determines if the last update process has been ended successfully.
 */
export const getSelectedCostCenterUpdateCompleted = createSelector(
  selectCostCenterState,
  (state: CostCentersState) => state.isUpdateCompleted
);

/**
 * Determines if there is a running delete process.
 */
export const getSelectedCostCenterDeleting = createSelector(
  selectCostCenterState,
  (state: CostCentersState) => state.isDeleting
);

/**
 * Determines if the last delete process has been ended successfully.
 */
export const getSelectedCostCenterDeleteCompleted = createSelector(
  selectCostCenterState,
  (state: CostCentersState) => state.isDeleteCompleted
);
