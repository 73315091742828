import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';

import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

import { NotificationService, PaginationInfo, TranslationService } from 'shared';
import * as fromProjectsManagementStore from 'projects-management/store';
import * as fromLookupsStore from 'lookups/store';
import { Project } from 'projects-management/models';
import { Customer } from 'sales/models';
import { Location } from 'stores/models';
import { ProjectStatus } from 'lookups';

@Component({
  selector: 'app-projects-search',
  templateUrl: './projects-search.component.html',
  styles: [],
})
export class ProjectsSearchComponent implements OnInit {
  /**
   * Indicates whether the modal will automatically close after select the first project.
   *
   * Be default it will not be closed.
   */
  @Input() closeOnSelect = false;

  /**
   * Indicates whether the projects displayed on the list should be all active.
   *
   * Be default it will not be closed.
   */
  @Input() onlyActive = false;

  /**
   * Sets the visibility of this component modal.
   *
   * Handles visibility change in two-data-binding approach.
   */
  @Input() set visible(isShown: boolean) {
    if (isShown && this.modalRef) {
      this.openModal(this.modalRef);
    } else {
      this.closeModal();
    }

    this.visibleChange.emit(isShown);
  }

  /**
   * Outputs a value that indicates whether the modal is shown or not.
   *
   * Handles visibility change in two-data-binding approach.
   */
  @Output() visibleChange = new EventEmitter<boolean>();

  /** Outputs the selected projects. */
  @Output() projects = new EventEmitter<Project[]>();

  /**
   * The modal template reference.
   */
  @ViewChild('modalRef') modalRef: ElementRef<any>;

  /**
   * The list of projects.
   */
  projects$: Observable<Project[]>;

  /**
   * The list of projects.
   */
  projectsList: Project[];

  /**
   * The set of system supported project statuses.
   */
  projectStatuses$: Observable<ProjectStatus[]>;

  /**
   * The pagination info.
   */
  paginationInfo$: Observable<PaginationInfo>;

  /**
   * Indicates whether there is a search process is running or not.
   */
  isSearching$: Observable<boolean>;

  /**
   * The search form.
   */
  searchForm: FormGroup;

  /**
   * The list of selected customers.
   */
  customers: Customer[] = [];

  /**
   * Shows or hide the customers list.
   */
  customersListVisibility = false;

  /**
   * Shows or hide the locations list.
   */
  locationsListVisibility = false;

  /**
   * The list of selected locations.
   */
  locations: Location[] = [];

  /**
   * The ngb-modal reference to use it when modal needed to be closed.
   */
  modal: NgbModalRef;

  /**
   * @param modalService The modal service.
   * @param notificationService The notification service.
   * @param projectsManagementStore$ The projectsManagement store.
   * @param translationService The translation service.
   * @param lookupsStore$ the lookups-store module.
   */
  constructor(
    private modalService: NgbModal,
    private notificationService: NotificationService,
    private projectsManagementStore$: Store<fromProjectsManagementStore.ProjectsManagementState>,
    private translationService: TranslationService,
    private lookupsStore$: Store<fromLookupsStore.LookupsState>
  ) {}

  ngOnInit(): void {
    this.init();
  }

  /**
   * Initialize component data.
   */
  init() {
    this.initForm();

    /**
     * Load data.
     */
    this.isSearching$ = this.projectsManagementStore$.pipe(select(fromProjectsManagementStore.getProjectsSearching));

    let isManualSearchTriggeredBeforeProjects = false;
    this.projects$ = this.projectsManagementStore$.pipe(
      select(fromProjectsManagementStore.getProjects),
      tap((projects) => {
        if (projects) {
          this.projectsList = projects;
        }
        if (!isManualSearchTriggeredBeforeProjects && !projects.length) {
          isManualSearchTriggeredBeforeProjects = true;
          this.search();
        }
      })
    );

    let isManualSearchTriggeredBeforeForProjectStatuses = false;
    this.projectStatuses$ = this.lookupsStore$.pipe(
      select(fromLookupsStore.getProjectStatuses),
      tap((projectStatuses) => {
        if (!isManualSearchTriggeredBeforeForProjectStatuses && !projectStatuses.length) {
          isManualSearchTriggeredBeforeForProjectStatuses = true;
          this.lookupsStore$.dispatch(new fromLookupsStore.GetAllProjectStatuses());
        }
      })
    );

    this.paginationInfo$ = this.projectsManagementStore$.pipe(
      select(fromProjectsManagementStore.getProjectsPaginationInfo)
    );
  }

  /**
   * Initialize form and add validators.
   */
  initForm() {
    this.searchForm = new FormGroup({
      description: new FormControl(''),
      locations: new FormControl([]),
      projectStatuses: new FormControl([]),
      customers: new FormControl([]),
      page: new FormControl(1),
    });
  }

  /**
   * Handles search parameters change.
   */
  search(event?: KeyboardEvent) {
    if (event && event.key === 'Enter') {
      event.preventDefault();
      return;
    }

    this.applyFiltersAndSearch();
  }

  /**
   * Handles pagination page-changed event.
   * @param page The current selected page number.
   */
  pageChanged(page: number) {
    /** Update pagination page. */
    this.searchForm.patchValue({ page });

    this.applyFiltersAndSearch(page);
  }

  /**
   * Enables trackBy feature for *ngFor utility to track items
   * depending on a comparer to reduce DOM change.
   * If the item is already exist then no HTML will change, only bounded values.
   * @param item The item to determine if it was changed or not.
   */
  trackItems(item: Project) {
    return item ? item.id : undefined;
  }

  /**
   * Handles project selected event.
   * @param projects The list of projects to notify selected.
   */
  selectProject(projects: Project[]) {
    this.notificationService.successInstant(
      this.translationService.translate('PROJECTS_MANAGEMENT.PROJECTS.NOTIFICATION_MESSAGE.PROJECT_SELECTED')
    );
    this.projects.emit(projects);
    if (this.closeOnSelect) {
      this.closeModal();
    }
  }

  /**
   * Applies the search filters and dispatches a search action.
   * @param page The current page number, default to `1`.
   */
  applyFiltersAndSearch(page: number = 1) {
    const { description, locations, projectStatuses, customers } = this.searchForm.value;

    this.projectsManagementStore$.dispatch(
      new fromProjectsManagementStore.SearchProjects({
        description,
        locations,
        projectStatuses,
        customers,
        page,
      })
    );
  }

  /**
   * Adds the newly selected customers the customers search list.
   * @param customers The list of newly selected customers to be added.
   */
  selectCustomers(customers: Customer[]) {
    const selectedIds: number[] = this.searchForm.get('customers').value;
    this.customers = [...this.customers.filter((customer) => selectedIds.includes(customer.id)), ...customers];
    this.searchForm.patchValue({ customers: this.customers.map((customer) => customer.id) });
  }

  /**
   * Adds the newly selected locations the locations search list.
   * @param locations The list of newly selected locations to be added.
   */
  selectLocations(locations: Location[]) {
    const selectedIds: number[] = this.searchForm.get('locations').value;
    this.locations = [...this.locations.filter((location) => selectedIds.includes(location.id)), ...locations];
    this.searchForm.patchValue({ locations: this.locations.map((location) => location.id) });
  }

  /**
   * Opens the modal of the given templateRef.
   * @param modalRef The modal templateRef to be opened.
   */
  openModal(modalRef) {
    /**
     * Open the modal & listen for user press ESC key.
     */
    this.modal = this.modalService.open(modalRef, { size: 'lg' });
    this.modal.result.then(null, () => this.closeModal());
  }

  /**
   * Closes the currently opened modal.
   */
  closeModal() {
    this.modal?.dismiss();
    this.projects.emit([]);
    this.visibleChange.emit(false);
  }
}
