<div [formGroup]="payrollsForm.parent" class="row">
  <div class="col-md-12 form-group d-flex">
    <app-export-tools
      *ngIf="payrollsForm.controls.length"
      class="d-flex align-self-end mx-1"
      targetElementId="payrolls-table-export-target-element"
      [outputFileName]="'SHARED.EXPORT_TABLES.PAYROLLS' | translate"
    ></app-export-tools>
  </div>
  <div class="col-md-12">
    <div class="table-responsive" [ngStyle]="{ 'padding-bottom': dropDownIsOpened ? '8rem' : 'unset' }">
      <table class="table text-center" id="payrolls-table-export-target-element">
        <thead>
          <tr>
            <th scope="col">
              <i class="fa fa-list-ol"></i>
            </th>
            <th class="fit-width" scope="col" *ngIf="!isEmployee">
              {{ 'FINANCES.HR.PAID_PAYROLLS.PAID_PAYROLLS_DATA.EMPLOYEE_NUMBER' | translate }}
            </th>
            <th class="fit-width" scope="col" *ngIf="!isEmployee">
              {{ 'FINANCES.HR.PAID_PAYROLLS.PAID_PAYROLLS_DATA.EMPLOYEE' | translate }}
            </th>
            <th class="fit-width" scope="col" *ngIf="!isEmployee">
              {{ 'FINANCES.HR.PAID_PAYROLLS.PAID_PAYROLLS_DATA.POSITION' | translate }}
            </th>
            <th class="fit-width" scope="col" *ngIf="!isEmployee">
              {{ 'FINANCES.HR.PAID_PAYROLLS.PAID_PAYROLLS_DATA.DEPARTMENT' | translate }}
            </th>
            <th class="fit-width" scope="col" *ngIf="!isEmployee">
              {{ 'FINANCES.HR.PAID_PAYROLLS.PAID_PAYROLLS_DATA.LOCATION' | translate }}
            </th>
            <th class="fit-width" scope="col">
              {{ 'FINANCES.HR.PAID_PAYROLLS.PAID_PAYROLLS_DATA.NET' | translate }}
            </th>
            <th class="fit-width" scope="col">
              {{ 'FINANCES.HR.PAID_PAYROLLS.PAID_PAYROLLS_DATA.THE_SALARY' | translate }}
            </th>
            <th class="fit-width" scope="col">
              {{ 'FINANCES.HR.PAID_PAYROLLS.PAID_PAYROLLS_DATA.SALARY_FOR_GOSI' | translate }}
            </th>
            <th class="fit-width" scope="col">
              {{ 'FINANCES.HR.PAID_PAYROLLS.PAID_PAYROLLS_DATA.EMPLOYEE_GOSI' | translate }}
            </th>
            <th class="fit-width" scope="col">
              {{ 'FINANCES.HR.PAID_PAYROLLS.PAID_PAYROLLS_DATA.EMPLOYER_GOSI' | translate }}
            </th>
            <th class="fit-width" scope="col">
              {{ 'FINANCES.HR.PAID_PAYROLLS.PAID_PAYROLLS_DATA.SPONSOR_GOSI' | translate }}
            </th>
            <th class="fit-width" scope="col">
              {{ 'FINANCES.HR.PAID_PAYROLLS.PAID_PAYROLLS_DATA.LOAN_SETTLEMENTS' | translate }}
            </th>
            <th class="fit-width" scope="col">
              {{ 'FINANCES.HR.PAID_PAYROLLS.PAID_PAYROLLS_DATA.OTHER_EARNINGS' | translate }}
            </th>
            <th class="fit-width" scope="col">
              {{ 'FINANCES.HR.PAID_PAYROLLS.PAID_PAYROLLS_DATA.DEDUCTIONS' | translate }}
            </th>
            <th class="fit-width" scope="col">
              {{ 'FINANCES.HR.PAID_PAYROLLS.PAID_PAYROLLS_DATA.OVERTIMES' | translate }}
            </th>
            <th class="fit-width" scope="col">
              {{ 'FINANCES.HR.PAID_PAYROLLS.PAID_PAYROLLS_DATA.LOCATIONS_COSTS' | translate }}
            </th>
            <th class="fit-width" scope="col">
              {{ 'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.PROJECTS_COSTS' | translate }}
            </th>
            <th class="fit-width" scope="col">
              {{ 'FINANCES.HR.PAID_PAYROLLS.PAID_PAYROLLS_DATA.PROVISIONS' | translate }}
            </th>
            <th class="fit-width" scope="col">
              {{ 'FINANCES.HR.PAID_PAYROLLS.PAID_PAYROLLS_DATA.UNPAID_LEAVES' | translate }}
            </th>
            <th class="fit-width" scope="col">
              {{ 'FINANCES.HR.PAID_PAYROLLS.PAID_PAYROLLS_DATA.TRANSACTION_DATE' | translate }}
            </th>
            <th class="fit-width" scope="col">
              {{ 'FINANCES.HR.PAID_PAYROLLS.PAID_PAYROLLS_DATA.NOTES' | translate }}
            </th>
            <th class="fit-width" scope="col">{{ 'SHARED.TOOLS' | translate }}</th>
          </tr>
        </thead>
        <tbody formArrayName="payrolls">
          <tr
            app-pay-payroll-form-item
            *ngFor="let item of payrollsForm.controls; let i = index"
            [payrollGroup]="item"
            [index]="i"
            [inReview]="inReview"
            [isEmployee]="isEmployee"
            (removeChange)="removeLine(i)"
            (dropDownIsOpened)="dropDownIsOpened = $event"
          ></tr>
        </tbody>
        <tfoot class="tfoot-light">
          <tr>
            <td [attr.colspan]="21">
              <i class="fa fa-bookmark c-primary"></i>
              <span>
                {{ payrollsForm.controls.length | toNumber }}
                {{ 'FINANCES.HR.PAID_PAYROLLS.PAID_PAYROLLS_DATA.PAYROLL' | translate }}
              </span>
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  </div>
</div>
