import { TaxesListComponent } from 'settings/components/taxes/taxes-list/taxes-list.component';
import { CreateTaxComponent } from 'settings/components/taxes/create-tax/create-tax.component';
import { UpdateTaxComponent } from 'settings/components/taxes/update-tax/update-tax.component';

export * from 'settings/components/taxes/taxes-list/taxes-list.component';
export * from 'settings/components/taxes/create-tax/create-tax.component';
export * from 'settings/components/taxes/update-tax/update-tax.component';

/**
 * The set of components registered on this module.
 */
export const COMPONENTS: any[] = [TaxesListComponent, CreateTaxComponent, UpdateTaxComponent];
