import { Injectable } from '@angular/core';

import { of } from 'rxjs';
import { switchMap, map, catchError, tap } from 'rxjs/operators';
import { Effect, ofType, Actions } from '@ngrx/effects';

import { NotificationService, StatusCode, TranslationService } from 'shared';
import { JournalAssociationService } from 'finances/services/journal-association.service';
import {
  JournalAssociationActionType,
  FindJournalAssociationVendorLog,
  FindJournalAssociationVendorLogFail,
  FindJournalAssociationVendorLogSuccess,
  FindJournalAssociationPurchaseInvoiceSuccess,
  FindJournalAssociationPurchaseInvoiceFail,
  FindJournalAssociationPurchaseInvoice,
  FindJournalAssociationPurchaseReturn,
  FindJournalAssociationPurchaseReturnSuccess,
  FindJournalAssociationPurchaseReturnFail,
  FindJournalAssociationCustomerLogSuccess,
  FindJournalAssociationCustomerLogFail,
  FindJournalAssociationCustomerLog,
  FindJournalAssociationSaleInvoice,
  FindJournalAssociationSaleInvoiceSuccess,
  FindJournalAssociationSaleInvoiceFail,
  FindJournalAssociationSaleReturnSuccess,
  FindJournalAssociationSaleReturn,
  FindJournalAssociationSaleReturnFail,
  FindJournalAssociationTransferStockSuccess,
  FindJournalAssociationTransferStockFail,
  FindJournalAssociationTransferStock,
  FindJournalAssociationIncomingStockSuccess,
  FindJournalAssociationIncomingStockFail,
  FindJournalAssociationIncomingStock,
  FindJournalAssociationOutgoingStock,
  FindJournalAssociationOutgoingStockSuccess,
  FindJournalAssociationOutgoingStockFail,
  FindJournalAssociationPettyCashRefund,
  FindJournalAssociationPettyCashRefundSuccess,
  FindJournalAssociationPettyCashRefundFail,
  FindJournalAssociationPettyCashRefundRequest,
  FindJournalAssociationPettyCashRefundRequestSuccess,
  FindJournalAssociationPettyCashRefundRequestFail,
  FindJournalAssociationAssetSuccess,
  FindJournalAssociationAsset,
  FindJournalAssociationAssetFail,
  FindJournalAssociationBankAccountLog,
  FindJournalAssociationBankAccountLogSuccess,
  FindJournalAssociationBankAccountLogFail,
  FindJournalAssociationAssetDepreciationSuccess,
  FindJournalAssociationAssetDepreciationFail,
  FindJournalAssociationAssetDepreciation,
  FindJournalAssociationAssetSaleInvoiceSuccess,
  FindJournalAssociationAssetSaleInvoice,
  FindJournalAssociationAssetSaleInvoiceFail,
  FindJournalAssociationExcludedAsset,
  FindJournalAssociationExcludedAssetSuccess,
  FindJournalAssociationExcludedAssetFail,
  FindJournalAssociationAccrualPayrollSuccess,
  FindJournalAssociationAccrualPayrollFail,
  FindJournalAssociationAccrualPayroll,
  FindJournalAssociationPayrollPaymentRefundSuccess,
  FindJournalAssociationPayrollPaymentRefundFail,
  FindJournalAssociationPayrollPaymentRefund,
  FindJournalAssociationPayrollPayment,
  FindJournalAssociationPayrollPaymentSuccess,
  FindJournalAssociationPayrollPaymentFail,
  FindJournalAssociationEmployeeLoan,
  FindJournalAssociationEmployeeLoanSuccess,
  FindJournalAssociationEmployeeLoanFail,
  FindJournalAssociationGovServiceRequestSuccess,
  FindJournalAssociationGovServiceRequestFail,
  FindJournalAssociationGovServiceRequest,
} from 'finances/store/actions';

@Injectable()
export class JournalAssociationEffects {
  constructor(
    private actions$: Actions,
    private journalAssociationService: JournalAssociationService,
    private notificationService: NotificationService,
    private translationService: TranslationService
  ) {}

  /* ========================= FIND_JOURNAL_ASSOCIATION_VENDOR_LOG =================================== */
  @Effect()
  findJournalAssociationVendorLog$ = this.actions$.pipe(
    ofType(JournalAssociationActionType.FIND_JOURNAL_ASSOCIATION_VENDOR_LOG),
    switchMap((action: FindJournalAssociationVendorLog) =>
      this.journalAssociationService.findJournalAssociationVendorLog(action.payload).pipe(
        map((response) => new FindJournalAssociationVendorLogSuccess(response)),
        catchError((error) => of(new FindJournalAssociationVendorLogFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  findJournalAssociationVendorLogFail$ = this.actions$.pipe(
    ofType(JournalAssociationActionType.FIND_JOURNAL_ASSOCIATION_VENDOR_LOG_FAIL),
    tap((action: FindJournalAssociationVendorLogFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(this.translationService.translate('FINANCES.JOURNALS.VENDOR_LOG_NOT_FOUND'));
      }
    })
  );

  /* ========================= FIND_JOURNAL_ASSOCIATION_PURCHASE_INVOICE =================================== */
  @Effect()
  findJournalAssociationPurchaseInvoice$ = this.actions$.pipe(
    ofType(JournalAssociationActionType.FIND_JOURNAL_ASSOCIATION_PURCHASE_INVOICE),
    switchMap((action: FindJournalAssociationPurchaseInvoice) =>
      this.journalAssociationService.findJournalAssociationPurchaseInvoice(action.payload).pipe(
        map((response) => new FindJournalAssociationPurchaseInvoiceSuccess(response)),
        catchError((error) => of(new FindJournalAssociationPurchaseInvoiceFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  findJournalAssociationPurchaseInvoiceFail$ = this.actions$.pipe(
    ofType(JournalAssociationActionType.FIND_JOURNAL_ASSOCIATION_PURCHASE_INVOICE_FAIL),
    tap((action: FindJournalAssociationPurchaseInvoiceFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate('FINANCES.JOURNALS.PURCHASE_INVOICE_NOT_FOUND')
        );
      }
    })
  );

  /* ========================= FIND_JOURNAL_ASSOCIATION_PURCHASE_RETURN =================================== */
  @Effect()
  findJournalAssociationPurchaseReturn$ = this.actions$.pipe(
    ofType(JournalAssociationActionType.FIND_JOURNAL_ASSOCIATION_PURCHASE_RETURN),
    switchMap((action: FindJournalAssociationPurchaseReturn) =>
      this.journalAssociationService.findJournalAssociationPurchaseReturn(action.payload).pipe(
        map((response) => new FindJournalAssociationPurchaseReturnSuccess(response)),
        catchError((error) => of(new FindJournalAssociationPurchaseReturnFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  findJournalAssociationPurchaseReturnFail$ = this.actions$.pipe(
    ofType(JournalAssociationActionType.FIND_JOURNAL_ASSOCIATION_PURCHASE_RETURN_FAIL),
    tap((action: FindJournalAssociationPurchaseReturnFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate('FINANCES.JOURNALS.PURCHASE_RETURN_NOT_FOUND')
        );
      }
    })
  );

  /* ========================= FIND_JOURNAL_ASSOCIATION_CUSTOMER_LOG =================================== */
  @Effect()
  findJournalAssociationCustomerLog$ = this.actions$.pipe(
    ofType(JournalAssociationActionType.FIND_JOURNAL_ASSOCIATION_CUSTOMER_LOG),
    switchMap((action: FindJournalAssociationCustomerLog) =>
      this.journalAssociationService.findJournalAssociationCustomerLog(action.payload).pipe(
        map((response) => new FindJournalAssociationCustomerLogSuccess(response)),
        catchError((error) => of(new FindJournalAssociationCustomerLogFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  findJournalAssociationCustomerLogFail$ = this.actions$.pipe(
    ofType(JournalAssociationActionType.FIND_JOURNAL_ASSOCIATION_CUSTOMER_LOG_FAIL),
    tap((action: FindJournalAssociationCustomerLogFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(this.translationService.translate('FINANCES.JOURNALS.CUSTOMER_LOG_NOT_FOUND'));
      }
    })
  );

  /* ========================= FIND_JOURNAL_ASSOCIATION_SALE_INVOICE =================================== */
  @Effect()
  findJournalAssociationSaleInvoice$ = this.actions$.pipe(
    ofType(JournalAssociationActionType.FIND_JOURNAL_ASSOCIATION_SALE_INVOICE),
    switchMap((action: FindJournalAssociationSaleInvoice) =>
      this.journalAssociationService.findJournalAssociationSaleInvoice(action.payload).pipe(
        map((response) => new FindJournalAssociationSaleInvoiceSuccess(response)),
        catchError((error) => of(new FindJournalAssociationSaleInvoiceFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  findJournalAssociationSaleInvoiceFail$ = this.actions$.pipe(
    ofType(JournalAssociationActionType.FIND_JOURNAL_ASSOCIATION_SALE_INVOICE_FAIL),
    tap((action: FindJournalAssociationCustomerLogFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(this.translationService.translate('FINANCES.JOURNALS.SALE_INVOICE_NOT_FOUND'));
      }
    })
  );

  /* ========================= FIND_JOURNAL_ASSOCIATION_SALE_RETURN =================================== */
  @Effect()
  findJournalAssociationSaleReturn$ = this.actions$.pipe(
    ofType(JournalAssociationActionType.FIND_JOURNAL_ASSOCIATION_SALE_RETURN),
    switchMap((action: FindJournalAssociationSaleReturn) =>
      this.journalAssociationService.findJournalAssociationSaleReturn(action.payload).pipe(
        map((response) => new FindJournalAssociationSaleReturnSuccess(response)),
        catchError((error) => of(new FindJournalAssociationSaleReturnFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  findJournalAssociationSaleReturnFail$ = this.actions$.pipe(
    ofType(JournalAssociationActionType.FIND_JOURNAL_ASSOCIATION_SALE_RETURN_FAIL),
    tap((action: FindJournalAssociationCustomerLogFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(this.translationService.translate('FINANCES.JOURNALS.SALE_RETURN_NOT_FOUND'));
      }
    })
  );

  /* ========================= FIND_JOURNAL_ASSOCIATION_TRANSFER_STOCK =================================== */
  @Effect()
  findJournalAssociationTransferStock$ = this.actions$.pipe(
    ofType(JournalAssociationActionType.FIND_JOURNAL_ASSOCIATION_TRANSFER_STOCK),
    switchMap((action: FindJournalAssociationTransferStock) =>
      this.journalAssociationService.findJournalAssociationTransferStock(action.payload).pipe(
        map((response) => new FindJournalAssociationTransferStockSuccess(response)),
        catchError((error) => of(new FindJournalAssociationTransferStockFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  findJournalAssociationTransferStockFail$ = this.actions$.pipe(
    ofType(JournalAssociationActionType.FIND_JOURNAL_ASSOCIATION_TRANSFER_STOCK_FAIL),
    tap((action: FindJournalAssociationCustomerLogFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(this.translationService.translate('FINANCES.JOURNALS.TRANSFER_STOCK_NOT_FOUND'));
      }
    })
  );

  /* ========================= FIND_JOURNAL_ASSOCIATION_INCOMING_STOCK =================================== */
  @Effect()
  findJournalAssociationIncomingStock$ = this.actions$.pipe(
    ofType(JournalAssociationActionType.FIND_JOURNAL_ASSOCIATION_INCOMING_STOCK),
    switchMap((action: FindJournalAssociationIncomingStock) =>
      this.journalAssociationService.findJournalAssociationIncomingStock(action.payload).pipe(
        map((response) => new FindJournalAssociationIncomingStockSuccess(response)),
        catchError((error) => of(new FindJournalAssociationIncomingStockFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  findJournalAssociationIncomingStockFail$ = this.actions$.pipe(
    ofType(JournalAssociationActionType.FIND_JOURNAL_ASSOCIATION_INCOMING_STOCK_FAIL),
    tap((action: FindJournalAssociationCustomerLogFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(this.translationService.translate('FINANCES.JOURNALS.INCOMING_STOCK_NOT_FOUND'));
      }
    })
  );

  /* ========================= FIND_JOURNAL_ASSOCIATION_OUTGOING_STOCK =================================== */
  @Effect()
  findJournalAssociationOutgoingStock$ = this.actions$.pipe(
    ofType(JournalAssociationActionType.FIND_JOURNAL_ASSOCIATION_OUTGOING_STOCK),
    switchMap((action: FindJournalAssociationOutgoingStock) =>
      this.journalAssociationService.findJournalAssociationOutgoingStock(action.payload).pipe(
        map((response) => new FindJournalAssociationOutgoingStockSuccess(response)),
        catchError((error) => of(new FindJournalAssociationOutgoingStockFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  findJournalAssociationOutgoingStockFail$ = this.actions$.pipe(
    ofType(JournalAssociationActionType.FIND_JOURNAL_ASSOCIATION_OUTGOING_STOCK_FAIL),
    tap((action: FindJournalAssociationCustomerLogFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(this.translationService.translate('FINANCES.JOURNALS.OUTGOING_STOCK_NOT_FOUND'));
      }
    })
  );

  /* ========================= FIND_JOURNAL_ASSOCIATION_PETTY_CASH_REFUND =================================== */
  @Effect()
  findJournalAssociationPettyCashRefund$ = this.actions$.pipe(
    ofType(JournalAssociationActionType.FIND_JOURNAL_ASSOCIATION_PETTY_CASH_REFUND),
    switchMap((action: FindJournalAssociationPettyCashRefund) =>
      this.journalAssociationService.findJournalAssociationPettyCashRefund(action.payload).pipe(
        map((response) => new FindJournalAssociationPettyCashRefundSuccess(response)),
        catchError((error) => of(new FindJournalAssociationPettyCashRefundFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  findJournalAssociationPettyCashRefundFail$ = this.actions$.pipe(
    ofType(JournalAssociationActionType.FIND_JOURNAL_ASSOCIATION_PETTY_CASH_REFUND_FAIL),
    tap((action: FindJournalAssociationCustomerLogFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate('FINANCES.JOURNALS.PETTY_CASH_REFUND_NOT_FOUND')
        );
      }
    })
  );
  /* ========================= FIND_JOURNAL_ASSOCIATION_PETTY_CASH_REFUND_REQUEST =================================== */
  @Effect()
  findJournalAssociationPettyCashRefundRequest$ = this.actions$.pipe(
    ofType(JournalAssociationActionType.FIND_JOURNAL_ASSOCIATION_PETTY_CASH_REFUND_REQUEST),
    switchMap((action: FindJournalAssociationPettyCashRefundRequest) =>
      this.journalAssociationService.findJournalAssociationPettyCashRefundRequest(action.payload).pipe(
        map((response) => new FindJournalAssociationPettyCashRefundRequestSuccess(response)),
        catchError((error) => of(new FindJournalAssociationPettyCashRefundRequestFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  findJournalAssociationPettyCashRefundRequestFail$ = this.actions$.pipe(
    ofType(JournalAssociationActionType.FIND_JOURNAL_ASSOCIATION_PETTY_CASH_REFUND_REQUEST_FAIL),
    tap((action: FindJournalAssociationCustomerLogFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate('FINANCES.JOURNALS.PETTY_CASH_REFUND_REQUEST_NOT_FOUND')
        );
      }
    })
  );

  /* ========================= FIND_JOURNAL_ASSOCIATION_ASSET =================================== */
  @Effect()
  findJournalAssociationAsset$ = this.actions$.pipe(
    ofType(JournalAssociationActionType.FIND_JOURNAL_ASSOCIATION_ASSET),
    switchMap((action: FindJournalAssociationAsset) =>
      this.journalAssociationService.findJournalAssociationAsset(action.payload).pipe(
        map((response) => new FindJournalAssociationAssetSuccess(response)),
        catchError((error) => of(new FindJournalAssociationAssetFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  findJournalAssociationAssetFail$ = this.actions$.pipe(
    ofType(JournalAssociationActionType.FIND_JOURNAL_ASSOCIATION_ASSET_FAIL),
    tap((action: FindJournalAssociationCustomerLogFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(this.translationService.translate('FINANCES.JOURNALS.ASSET_NOT_FOUND'));
      }
    })
  );

  /* ========================= FIND_JOURNAL_ASSOCIATION_BANK_ACCOUNT_LOG =================================== */
  @Effect()
  findJournalAssociationBankAccountLog$ = this.actions$.pipe(
    ofType(JournalAssociationActionType.FIND_JOURNAL_ASSOCIATION_BANK_ACCOUNT_LOG),
    switchMap((action: FindJournalAssociationBankAccountLog) =>
      this.journalAssociationService.findJournalAssociationBankAccountLog(action.payload).pipe(
        map((response) => new FindJournalAssociationBankAccountLogSuccess(response)),
        catchError((error) => of(new FindJournalAssociationBankAccountLogFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  findJournalAssociationBankAccountLogFail$ = this.actions$.pipe(
    ofType(JournalAssociationActionType.FIND_JOURNAL_ASSOCIATION_BANK_ACCOUNT_LOG_FAIL),
    tap((action: FindJournalAssociationCustomerLogFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate('FINANCES.JOURNALS.BANK_ACCOUNT_LOG_NOT_FOUND')
        );
      }
    })
  );

  /* ========================= FIND_JOURNAL_ASSOCIATION_ASSET_DEPRECIATION =================================== */
  @Effect()
  findJournalAssociationAssetDepreciation$ = this.actions$.pipe(
    ofType(JournalAssociationActionType.FIND_JOURNAL_ASSOCIATION_ASSET_DEPRECIATION),
    switchMap((action: FindJournalAssociationAssetDepreciation) =>
      this.journalAssociationService.findJournalAssociationAssetDepreciation(action.payload).pipe(
        map((response) => new FindJournalAssociationAssetDepreciationSuccess(response)),
        catchError((error) => of(new FindJournalAssociationAssetDepreciationFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  findJournalAssociationAssetDepreciationFail$ = this.actions$.pipe(
    ofType(JournalAssociationActionType.FIND_JOURNAL_ASSOCIATION_ASSET_DEPRECIATION_FAIL),
    tap((action: FindJournalAssociationCustomerLogFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate('FINANCES.JOURNALS.ASSET_DEPRECIATION_NOT_FOUND')
        );
      }
    })
  );

  /* ========================= FIND_JOURNAL_ASSOCIATION_ASSET_SALE_INVOICE =================================== */
  @Effect()
  findJournalAssociationAssetSaleInvoice$ = this.actions$.pipe(
    ofType(JournalAssociationActionType.FIND_JOURNAL_ASSOCIATION_ASSET_SALE_INVOICE),
    switchMap((action: FindJournalAssociationAssetSaleInvoice) =>
      this.journalAssociationService.findJournalAssociationAssetSaleInvoice(action.payload).pipe(
        map((response) => new FindJournalAssociationAssetSaleInvoiceSuccess(response)),
        catchError((error) => of(new FindJournalAssociationAssetSaleInvoiceFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  findJournalAssociationAssetSaleInvoiceFail$ = this.actions$.pipe(
    ofType(JournalAssociationActionType.FIND_JOURNAL_ASSOCIATION_ASSET_SALE_INVOICE_FAIL),
    tap((action: FindJournalAssociationCustomerLogFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate('FINANCES.JOURNALS.ASSET_SALE_INVOICE_NOT_FOUND')
        );
      }
    })
  );
  /* ========================= FIND_JOURNAL_ASSOCIATION_EXCLUDED_ASSET =================================== */
  @Effect()
  findJournalAssociationExcludedAsset$ = this.actions$.pipe(
    ofType(JournalAssociationActionType.FIND_JOURNAL_ASSOCIATION_EXCLUDED_ASSET),
    switchMap((action: FindJournalAssociationExcludedAsset) =>
      this.journalAssociationService.findJournalAssociationExcludedAsset(action.payload).pipe(
        map((response) => new FindJournalAssociationExcludedAssetSuccess(response)),
        catchError((error) => of(new FindJournalAssociationExcludedAssetFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  findJournalAssociationExcludedAssetFail$ = this.actions$.pipe(
    ofType(JournalAssociationActionType.FIND_JOURNAL_ASSOCIATION_EXCLUDED_ASSET_FAIL),
    tap((action: FindJournalAssociationCustomerLogFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(this.translationService.translate('FINANCES.JOURNALS.EXCLUDED_ASSET_NOT_FOUND'));
      }
    })
  );

  /* ========================= FIND_JOURNAL_ASSOCIATION_PAYROLL_PAYMENT =================================== */
  @Effect()
  findJournalAssociationPayrollPayment$ = this.actions$.pipe(
    ofType(JournalAssociationActionType.FIND_JOURNAL_ASSOCIATION_PAYROLL_PAYMENT),
    switchMap((action: FindJournalAssociationPayrollPayment) =>
      this.journalAssociationService.findJournalAssociationPayrollPayment(action.payload).pipe(
        map((response) => new FindJournalAssociationPayrollPaymentSuccess(response)),
        catchError((error) => of(new FindJournalAssociationPayrollPaymentFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  findJournalAssociationPayrollPaymentFail$ = this.actions$.pipe(
    ofType(JournalAssociationActionType.FIND_JOURNAL_ASSOCIATION_PAYROLL_PAYMENT_FAIL),
    tap((action: FindJournalAssociationCustomerLogFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate('FINANCES.JOURNALS.PAYROLL_PAYMENT_NOT_FOUND')
        );
      }
    })
  );

  /* ========================= FIND_JOURNAL_ASSOCIATION_ACCRUAL_PAYROLL =================================== */
  @Effect()
  findJournalAssociationAccrualPayroll$ = this.actions$.pipe(
    ofType(JournalAssociationActionType.FIND_JOURNAL_ASSOCIATION_ACCRUAL_PAYROLL),
    switchMap((action: FindJournalAssociationAccrualPayroll) =>
      this.journalAssociationService.findJournalAssociationAccrualPayroll(action.payload).pipe(
        map((response) => new FindJournalAssociationAccrualPayrollSuccess(response)),
        catchError((error) => of(new FindJournalAssociationAccrualPayrollFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  findJournalAssociationAccrualPayrollFail$ = this.actions$.pipe(
    ofType(JournalAssociationActionType.FIND_JOURNAL_ASSOCIATION_ACCRUAL_PAYROLL_FAIL),
    tap((action: FindJournalAssociationAccrualPayrollFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate('FINANCES.JOURNALS.ACCRUAL_PAYROLL_NOT_FOUND')
        );
      }
    })
  );

  /* ========================= FIND_JOURNAL_ASSOCIATION_PAYROLL_PAYMENT_REFUND =================================== */
  @Effect()
  findJournalAssociationPayrollPaymentRefund$ = this.actions$.pipe(
    ofType(JournalAssociationActionType.FIND_JOURNAL_ASSOCIATION_PAYROLL_PAYMENT_REFUND),
    switchMap((action: FindJournalAssociationPayrollPaymentRefund) =>
      this.journalAssociationService.findJournalAssociationPayrollPaymentRefund(action.payload).pipe(
        map((response) => new FindJournalAssociationPayrollPaymentRefundSuccess(response)),
        catchError((error) => of(new FindJournalAssociationPayrollPaymentRefundFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  findJournalAssociationPayrollPaymentRefundFail$ = this.actions$.pipe(
    ofType(JournalAssociationActionType.FIND_JOURNAL_ASSOCIATION_PAYROLL_PAYMENT_REFUND_FAIL),
    tap((action: FindJournalAssociationPayrollPaymentRefundFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate('FINANCES.JOURNALS.PAYROLL_PAYMENT_REFUND_NOT_FOUND')
        );
      }
    })
  );

  /* ========================= FIND_JOURNAL_ASSOCIATION_GOV_SERVICE_REQUEST =================================== */
  @Effect()
  findJournalAssociationGovServiceRequest$ = this.actions$.pipe(
    ofType(JournalAssociationActionType.FIND_JOURNAL_ASSOCIATION_GOV_SERVICE_REQUEST),
    switchMap((action: FindJournalAssociationGovServiceRequest) =>
      this.journalAssociationService.findJournalAssociationGovServiceRequest(action.payload).pipe(
        map((response) => new FindJournalAssociationGovServiceRequestSuccess(response)),
        catchError((error) => of(new FindJournalAssociationGovServiceRequestFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  findJournalAssociationGovServiceRequestFail$ = this.actions$.pipe(
    ofType(JournalAssociationActionType.FIND_JOURNAL_ASSOCIATION_GOV_SERVICE_REQUEST_FAIL),
    tap((action: FindJournalAssociationGovServiceRequestFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate('FINANCES.JOURNALS.GOV_SERVICE_REQUEST_NOT_FOUND')
        );
      }
    })
  );

  /* ========================= FIND_JOURNAL_ASSOCIATION_EMPLOYEE_LOAN =================================== */
  @Effect()
  findJournalAssociationEmployeeLoan$ = this.actions$.pipe(
    ofType(JournalAssociationActionType.FIND_JOURNAL_ASSOCIATION_EMPLOYEE_LOAN),
    switchMap((action: FindJournalAssociationEmployeeLoan) =>
      this.journalAssociationService.findJournalAssociationEmployeeLoan(action.payload).pipe(
        map((response) => new FindJournalAssociationEmployeeLoanSuccess(response)),
        catchError((error) => of(new FindJournalAssociationEmployeeLoanFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  findJournalAssociationEmployeeLoanFail$ = this.actions$.pipe(
    ofType(JournalAssociationActionType.FIND_JOURNAL_ASSOCIATION_EMPLOYEE_LOAN_FAIL),
    tap((action: FindJournalAssociationCustomerLogFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(this.translationService.translate('FINANCES.JOURNALS.EMPLOYEE_LOAN_NOT_FOUND'));
      }
    })
  );
}
