import { Action } from '@ngrx/store';

import { LocationType } from 'lookups/models';
import { AppHttpResponse } from 'shared';

/**
 * The Lookups-Module store location  type action types.
 */
export enum LocationTypeActionType {
  GET_ALL_LOCATION_TYPES = '[Lookups] [LocationType] Get All Location Types',
  GET_ALL_LOCATION_TYPES_FAIL = '[Lookups] [LocationType] Get All Location Types Fail',
  GET_ALL_LOCATION_TYPES_SUCCESS = '[Lookups] [LocationType] Get All Location Types Success',
}

/**
 * Represents a store location  type action.
 */
export class GetAllLocationType implements Action {
  /**
   * The type of the action.
   */
  readonly type = LocationTypeActionType.GET_ALL_LOCATION_TYPES;

  /**
   * Fires a new location  type action.
   */
  constructor() {}
}

/**
 * Represents a store location  type fail action.
 */
export class GetAllLocationTypeFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = LocationTypeActionType.GET_ALL_LOCATION_TYPES_FAIL;

  /**
   * Fires a new Location  type fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store location  type success action.
 */
export class GetAllLocationTypeSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = LocationTypeActionType.GET_ALL_LOCATION_TYPES_SUCCESS;

  /**
   * Fires a location  type success action.
   * @param payload An object contains the list of location  types.
   */
  constructor(public payload: AppHttpResponse<LocationType[]>) {}
}

/**
 * Lookups-module Location  type action types.
 */
export type LocationTypeActions = GetAllLocationType | GetAllLocationTypeFail | GetAllLocationTypeSuccess;
