import { Component, ViewChild, ElementRef, Input, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import { Observable } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store, select } from '@ngrx/store';

import { NotificationService, NotificationMessage, CustomValidators, TranslationService } from 'shared';
import * as fromProjectsManagementStore from 'projects-management/store';
import { Project } from 'projects-management/models';
import { Customer } from 'sales/models';
import { Location } from 'stores/models';
import { ProjectStatus } from 'lookups/models';

@Component({
  selector: 'app-project-info',
  templateUrl: './project-info.component.html',
  styles: [],
})
export class ProjectInfoComponent implements OnInit {
  /**
   * The confirm modal template reference.
   */
  @ViewChild('confirmModalRef') confirmModalRef: ElementRef<any>;

  /**
   * Sets the currently edited project.
   */
  @Input() set project(value: Project) {
    this.projectObj = value;

    /** First of all initialize form if it hasn't initialized yet. */
    if (!this.form) {
      this.initForm();
    }

    /** Update form data. */
    if (value) {
      this.form.patchValue({
        ...value,
        startDate: this.toDate(value.startDate),
        endDate: this.toDate(value.endDate),
        projectStatusId: value.projectStatusId,
      });

      this.locations = [value.location];
      this.customers = [value.customer];
    } else {
      this.form.reset();
    }
  }

  /**
   * The list of project status.
   */
  @Input() projectStatuses: ProjectStatus[] = [];

  /**
   * Gets or sets the currently edited project.
   */
  projectObj: Project;

  /**
   * Shows or hide the location list.
   */
  locationsListVisibility = false;

  /**
   * Shows or hide the customers list.
   */
  customersListVisibility = false;

  /**
   * The list of active customers.
   */
  customers: Customer[] = [];

  /**
   * The list of selected locations.
   */
  locations: Location[] = [];

  /**
   * Indicates whether there is a update-project-info process is running or not.
   */
  isUpdating$: Observable<boolean>;

  /**
   * The create project form.
   */
  form: FormGroup;

  /**
   * @param modalService The modal service.
   * @param notificationService the notification service.
   * @param projectManagementStore$ the projects-management-store module.
   * @param translationService The translation service.
   */
  constructor(
    private modalService: NgbModal,
    private notificationService: NotificationService,
    private projectsManagementStore$: Store<fromProjectsManagementStore.ProjectsManagementState>,
    private translationService: TranslationService
  ) {}

  ngOnInit(): void {
    this.init();
  }

  /**
   * Initialize component data.
   */
  init() {
    /**
     * Load data.
     */
    this.isUpdating$ = this.projectsManagementStore$.pipe(
      select(fromProjectsManagementStore.getSelectedProjectUpdating)
    );
  }

  /**
   * Initialize form and add validators.
   */
  initForm() {
    this.form = new FormGroup({
      code: new FormControl('', [Validators.required, Validators.minLength(1), Validators.maxLength(50)]),
      description: new FormControl('', [Validators.required, Validators.minLength(1), Validators.maxLength(50)]),
      descriptionEn: new FormControl('', [Validators.required, Validators.minLength(1), Validators.maxLength(50)]),
      allowExceedBudget: new FormControl(false),
      customerId: new FormControl(null, Validators.required),
      projectStatusId: new FormControl(null),
      laborBudget: new FormControl(null, Validators.required),
      laborOvertimeBudget: new FormControl(null, Validators.required),
      equipmentBudget: new FormControl(null, Validators.required),
      revenueBudget: new FormControl(null, Validators.required),
      locationId: new FormControl(null, Validators.required),
      startDate: new FormControl(null),
      endDate: new FormControl(null),
    });

    this.form.controls.projectStatusId.disable();
  }

  /**
   * Submits the form.
   */
  submit() {
    if (this.form.invalid) {
      const errorMessage = new NotificationMessage();
      if (this.form.get('code').invalid) {
        errorMessage.title = this.translationService.translate(
          'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA_VALIDATION.PROJECT_DETAILS_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate('PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA_VALIDATION.CODE_ERROR'),
          this.translationService.translate('PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA_VALIDATION.CODE_IS_REQUIRED'),
          this.translationService.translate('PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA_VALIDATION.CODE_LENGTH_ERROR'),
        ];
      } else if (this.form.get('description').invalid) {
        errorMessage.title = this.translationService.translate(
          'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA_VALIDATION.PROJECT_DETAILS_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate(
            'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA_VALIDATION.ARABIC_PROJECT_DESCRIPTION_ERROR'
          ),
          this.translationService.translate(
            'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA_VALIDATION.PROJECT_DESCRIPTION_IS_REQUIRED'
          ),
          this.translationService.translate(
            'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA_VALIDATION.PROJECT_DESCRIPTION_LENGTH_ERROR'
          ),
        ];
      } else if (this.form.get('descriptionEn').invalid) {
        errorMessage.title = this.translationService.translate(
          'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA_VALIDATION.PROJECT_DETAILS_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate(
            'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA_VALIDATION.ENGLISH_PROJECT_DESCRIPTION_ERROR'
          ),
          this.translationService.translate(
            'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA_VALIDATION.PROJECT_DESCRIPTION_IS_REQUIRED'
          ),
          this.translationService.translate(
            'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA_VALIDATION.PROJECT_DESCRIPTION_LENGTH_ERROR'
          ),
        ];
      } else if (this.form.get('customerId').invalid) {
        errorMessage.title = this.translationService.translate(
          'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA_VALIDATION.PROJECT_DETAILS_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate('PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA_VALIDATION.CUSTOMER_ERROR'),
          this.translationService.translate(
            'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA_VALIDATION.CUSTOMER_IS_REQUIRED'
          ),
        ];
      } else if (this.form.get('locationId').invalid) {
        errorMessage.title = this.translationService.translate(
          'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA_VALIDATION.PROJECT_DETAILS_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate('PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA_VALIDATION.LOCATION_ERROR'),
          this.translationService.translate(
            'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA_VALIDATION.LOCATION_IS_REQUIRED'
          ),
        ];
      } else if (this.form.get('startDate').invalid) {
        errorMessage.title = this.translationService.translate(
          'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA_VALIDATION.PROJECT_DETAILS_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate('PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA_VALIDATION.START_DATE_ERROR'),
          this.translationService.translate(
            'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA_VALIDATION.START_DATE_IS_REQUIRED'
          ),
        ];
      } else if (this.form.get('endDate').invalid) {
        errorMessage.title = this.translationService.translate(
          'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA_VALIDATION.PROJECT_DETAILS_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate('PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA_VALIDATION.END_DATE_ERROR'),
          this.translationService.translate(
            'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA_VALIDATION.END_DATE_IS_REQUIRED'
          ),
        ];
      } else if (this.form.get('laborBudget').invalid) {
        errorMessage.title = this.translationService.translate(
          'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA_VALIDATION.LABOR_BUDGET_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate(
            'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA_VALIDATION.LABOR_BUDGET_IS_REQUIRED'
          ),
        ];
      } else if (this.form.get('laborOvertimeBudget').invalid) {
        errorMessage.title = this.translationService.translate(
          'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA_VALIDATION.LABOR_OVERTIME_BUDGET_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate(
            'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA_VALIDATION.LABOR_OVERTIME_BUDGET_IS_REQUIRED'
          ),
        ];
      } else if (this.form.get('equipmentBudget').invalid) {
        errorMessage.title = this.translationService.translate(
          'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA_VALIDATION.EQUIPMENT_BUDGET_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate(
            'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA_VALIDATION.EQUIPMENT_BUDGET_IS_REQUIRED'
          ),
        ];
      } else if (this.form.get('revenueBudget').invalid) {
        errorMessage.title = this.translationService.translate(
          'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA_VALIDATION.REVENUE_BUDGET_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate(
            'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA_VALIDATION.REVENUE_BUDGET_IS_REQUIRED'
          ),
        ];
      }

      this.form.markAllAsTouched();
      return this.notificationService.warningWithTitle(errorMessage);
    } else {
      this.openModal(this.confirmModalRef);
    }
  }

  /**
   * Confirms the the form submit.
   */
  confirm() {
    let startDateFormatted;
    let endDateFormatted;
    const { startDate, endDate } = this.form.value;

    if (startDate && startDate.year) {
      startDateFormatted = new Date(startDate.year, startDate.month - 1, startDate.day);
      startDateFormatted.setHours(new Date().getHours(), new Date().getMinutes());
      this.form.value.startDate = startDateFormatted;
    }
    if (endDate && endDate.year) {
      endDateFormatted = new Date(endDate.year, endDate.month - 1, endDate.day);
      endDateFormatted.setHours(new Date().getHours(), new Date().getMinutes());
      this.form.value.endDate = endDateFormatted;
    }

    this.projectsManagementStore$.dispatch(
      new fromProjectsManagementStore.UpdateProject({
        ...this.form.value,
        projectId: this.projectObj.id,
        locationId: this.form.value.locationId,
        customerId: this.form.value.customerId,
        revenueBudget: this.form.value.revenueBudget,
        laborBudget: this.form.value.laborBudget,
        laborOvertimeBudget: this.form.value.laborOvertimeBudget,
        equipmentBudget: this.form.value.equipmentBudget,
        startDate: this.form.value.startDate ? this.form.value.startDate : null,
        endDate: this.form.value.endDate ? this.form.value.endDate : null,
      })
    );
  }

  /**
   * Opens the modal of the given templateRef.
   * @param modalRef The modal templateRef to be opened.
   */
  openModal(modalRef) {
    this.modalService.open(modalRef);
  }

  /**
   * Selects the newly selected customer from the customers search list.
   * @param customers The list of newly selected customers to select the only one in the list.
   */
  selectCustomer([customer]: Customer[]) {
    if (customer) {
      this.customers = [customer];
      this.form.patchValue({ customerId: customer.id });
    }
  }

  /**
   * Selects the newly selected location from the locations search list.
   * @param locations The list of newly selected locations to select the only one in the list.
   */
  selectLocation([location]: Location[]) {
    if (location) {
      this.locations = [location];
      this.form.patchValue({ locationId: location.id });
    }
  }

  /**
   * convert date form string to year,month and day
   * @param date The date to be converted.
   */
  toDate(date) {
    if (date) {
      const [year, month, day] = date.split('-');
      const obj = { year: parseInt(year), month: parseInt(month), day: parseInt(day.split(' ')[0].trim()) };
      return obj;
    }
  }
}
