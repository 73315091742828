<app-page-header [info]="pageInfo"></app-page-header>

<!--System statistics cards for admin Starts-->
<div *ngIf="!currentLoggedInUser.organizationId">
  <app-system-statistics></app-system-statistics>
</div>
<!--System statistics cards for admin End-->

<!--Statistics cards for user Starts-->
<div *ngIf="[Claims.SHOW_ORGANIZATION_STATISTICS] | authorize | async">
  <app-statistics></app-statistics>
</div>
<!--Statistics cards for user End-->
