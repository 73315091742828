import { Injectable } from '@angular/core';

import { of } from 'rxjs';
import { switchMap, map, catchError } from 'rxjs/operators';
import { Effect, ofType, Actions } from '@ngrx/effects';

import { LookupsService } from 'lookups/services';
import { OvertimeRateActionType, GetAllOvertimeRatesFail, GetAllOvertimeRatesSuccess } from 'lookups/store/actions';

@Injectable()
export class OvertimeRateEffects {
  constructor(private actions$: Actions, private lookupsService: LookupsService) {}

  /* ========================= GET_ALL_OVERTIME_RATES =================================== */
  @Effect()
  getAllOvertimeRates$ = this.actions$.pipe(
    ofType(OvertimeRateActionType.GET_ALL_OVERTIME_RATES),
    switchMap(() =>
      this.lookupsService.getAllOvertimeRates().pipe(
        map((response) => new GetAllOvertimeRatesSuccess(response)),
        catchError((error) => of(new GetAllOvertimeRatesFail(error)))
      )
    )
  );
}
