<ng-template #modalRef let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title text-primary" id="modal-basic-title">
      {{ 'SALES.CUSTOMERS.CONFIRM_CREATE_CUSTOMER_TITLE' | translate }}
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="d(); closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <!-- create customer form starts -->
    <form [formGroup]="form" (ngSubmit)="submit()">
      <div class="row">
        <div class="form-group col-md-12">
          <label for="name">{{ 'SALES.CUSTOMERS.CUSTOMER_DATA.CUSTOMER_NAME' | translate }}</label>
          <input
            type="text"
            id="name"
            class="form-control round"
            [placeholder]="'SALES.CUSTOMERS.CUSTOMER_DATA.CUSTOMER_NAME_PLACEHOLDER' | translate"
            maxlength="50"
            formControlName="name"
          />
        </div>
        <div class="form-group col-md-12">
          <label for="select-id">{{ 'SALES.CUSTOMERS.CUSTOMER_DATA.CITY_NAME' | translate }}</label>
          <ng-select
            class="round"
            formControlName="cityId"
            labelForId="select-id"
            autocomplete="off"
            searchable="true"
            [placeholder]="'SALES.CUSTOMERS.CUSTOMER_DATA.CITY_NAME_PLACEHOLDER' | translate"
            [notFoundText]="'SHARED.SEARCH.SEARCH_NOT_FOUND' | translate"
          >
            <ng-option *ngFor="let city of cities$ | async" [value]="city.id">
              {{ city.name | localize: city.nameEn | async }}
            </ng-option>
          </ng-select>
        </div>
        <div class="form-group col-md-12">
          <label for="representorMobile">{{ 'SALES.CUSTOMERS.CUSTOMER_DATA.MOBILE' | translate }}</label>
          <input
            type="text"
            [patterns]="phonePattern"
            mask="{{ phoneMask }}"
            id="representorMobile"
            class="form-control round"
            [placeholder]="'SALES.CUSTOMERS.CUSTOMER_DATA.MOBILE_PLACEHOLDER' | translate"
            formControlName="representorMobile"
          />
        </div>
      </div>
    </form>
    <!-- create customer form ends -->
  </div>
  <div class="modal-footer">
    <div class="modal-actions">
      <button
        type="button"
        class="btn btn-raised btn-success round"
        (click)="submit()"
        ngbAutofocus
        [disabled]="isCreating$ | async"
      >
        <i class="fa fa-check"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.SAVE' | translate }} </span>
      </button>
      <button type="button" class="btn btn-outline-dark round" (click)="c(); closeModal()">
        <i class="fa fa-times"></i>
        <span class="mx-1"> {{ 'SHARED.CONFIRM.CANCEL' | translate }} </span>
      </button>
    </div>
  </div>
</ng-template>

<!-- confirm modal starts -->
<ng-template #confirmModalRef let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
      {{ 'SALES.CUSTOMERS.CONFIRM_CREATE_CUSTOMER_TITLE' | translate }}
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="d()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p class="text-success">{{ 'SALES.CUSTOMERS.CONFIRM_CREATE_CUSTOMER_MESSAGE' | translate }}</p>
  </div>
  <div class="modal-footer">
    <div class="modal-actions">
      <button
        type="button"
        class="btn btn-raised btn-success round"
        [disabled]="isCreating$ | async"
        (click)="c(); confirm()"
        ngbAutofocus
      >
        <i class="fa fa-check"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.SAVE' | translate }}</span>
      </button>
      <button type="button" class="btn btn-outline-dark round" (click)="c()">
        <i class="fa fa-times"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.CANCEL' | translate }}</span>
      </button>
    </div>
  </div>
</ng-template>
<!-- confirm modal ends -->
