import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { AppHttpResponse } from 'shared';
import { Account } from 'finances/models';
import { SystemAccounts } from 'lookups/models';

/**
 * The system accounts services includes functionality to findSystemAccountTypeByKey for a system account.
 */
@Injectable()
export class SystemAccountsService {
  /**
   * The relative route for the system accounts.
   *
   * No leading or trailing slashes required.
   */
  private systemAccountsApi = 'finances/accounts/system-accounts';

  constructor(private http: HttpClient) {}

  /**
   * Finds the system account with the given key.
   * @param systemAccountKey The key of the system account.
   */
  public findSystemAccountByKey(
    systemAccountKey: SystemAccounts
  ): Observable<AppHttpResponse<Account>> {
    return this.http.get<AppHttpResponse<Account>>(`${this.systemAccountsApi}/${systemAccountKey}`);
  }
}
