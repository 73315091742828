import { Injectable } from '@angular/core';

import { of } from 'rxjs';
import { switchMap, map, catchError } from 'rxjs/operators';
import { Effect, ofType, Actions } from '@ngrx/effects';

import { LookupsService } from 'lookups/services';
import {
  GetAllPettyCashRefundRequestTypeFail,
  GetAllPettyCashRefundRequestTypeSuccess,
  PettyCashRefundRequestTypeActionType,
} from 'lookups/store/actions';

@Injectable()
export class PettyCashRefundRequestTypeEffects {
  constructor(private actions$: Actions, private lookupsService: LookupsService) {}

  /* ========================= GET_ALL_PETTY_CASH_TYPE =================================== */
  @Effect()
  getAllPettyCashRefundRequestTypes$ = this.actions$.pipe(
    ofType(PettyCashRefundRequestTypeActionType.GET_ALL_PETTY_CASH_REFUND_REQUEST_TYPE),
    switchMap(() =>
      this.lookupsService.getAllPettyCashRefundRequestTypes().pipe(
        map((response) => new GetAllPettyCashRefundRequestTypeSuccess(response)),
        catchError((error) => of(new GetAllPettyCashRefundRequestTypeFail(error)))
      )
    )
  );
}
