import { Injectable } from '@angular/core';

import { of } from 'rxjs';
import { switchMap, map, catchError } from 'rxjs/operators';
import { Effect, ofType, Actions } from '@ngrx/effects';

import { LookupsService } from 'lookups/services';
import {
  PayrollElementTypeActionType,
  GetAllPayrollElementTypesFail,
  GetAllPayrollElementTypesSuccess,
} from 'lookups/store/actions';

@Injectable()
export class PayrollElementTypeEffects {
  constructor(private actions$: Actions, private lookupsService: LookupsService) {}

  /* ========================= GET_ALL_PAYROLL_ELEMENT_TYPES =================================== */
  @Effect()
  getAllPayrollElementTypes$ = this.actions$.pipe(
    ofType(PayrollElementTypeActionType.GET_ALL_PAYROLL_ELEMENT_TYPES),
    switchMap(() =>
      this.lookupsService.getAllPayrollElementTypes().pipe(
        map((response) => new GetAllPayrollElementTypesSuccess(response)),
        catchError((error) => of(new GetAllPayrollElementTypesFail(error)))
      )
    )
  );
}
