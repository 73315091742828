import { Decimal } from 'decimal.js';

/**
 * Provides utilities to handle the hard part of JavaScript which is related to numbers.
 */
export class NumbersUtil {
  /**
   * Converts the given `number` to a decimal number with only a specified number of decimal digits.
   * @notes This util methods doesn't performs any rounding to the given number.
   * @param inputNumber The input number or string to be converted to a decimal number with fixed decimal digits.
   * @param decimalDigits The number of digits to appear after the decimal point, default `2` decimal digits.
   * @returns A number representing the given number using fixed-point notation without round.
   */
  public static toFixed(inputNumber: number | string | Decimal, decimalDigits = 2): number {
    if (inputNumber === null || inputNumber === undefined || inputNumber.toString().trim().length === 0) {
      return 0;
    } else if (inputNumber.toString().indexOf('.') === -1) {
      return +inputNumber;
    } else {
      const num = inputNumber.toString().slice(0, inputNumber.toString().indexOf('.') + (decimalDigits + 1));
      return +num;
    }
  }
}
