<!-- page header starts -->
<app-page-header [info]="pageInfo"></app-page-header>
<!-- page header ends -->

<!-- loans file pages start -->

<div class="card">
  <div class="card-content">
    <div class="px-3 pt-3">
      <ul
        class="nav-tabs"
        ngbNav
        #nav="ngbNav"
        [(activeId)]="activePage"
        (activeIdChange)="selectedPageChanged($event)"
        [destroyOnHide]="false"
      >
        <li ngbNavItem="governmental-service-requests">
          <a ngbNavLink>
            <i class="fa fa-vcard-o" [class.success]="activePage == 'governmental-service-requests'"></i>
            {{ 'FINANCES.HR.GOV_SERVICE_REQUESTS_TITLE' | translate }}
          </a>
          <ng-template ngbNavContent>
            <gov-service-requests-list></gov-service-requests-list>
          </ng-template>
        </li>
        <li ngbNavItem="loans">
          <a ngbNavLink>
            <i class="fa fa-minus-circle" [class.success]="activePage == 'loans'"></i>
            {{ 'FINANCES.HR.LOANS_DATA_TITLE' | translate }}
          </a>
          <ng-template ngbNavContent>
            <app-loan-view></app-loan-view>
          </ng-template>
        </li>
        <li ngbNavItem="payrolls">
          <a ngbNavLink>
            <i class="fa fa-money" [class.success]="activePage == 'payrolls'"></i>
            {{ 'FINANCES.HR.PAYROLLS' | translate }}
          </a>
          <ng-template ngbNavContent>
            <payrolls-list></payrolls-list>
          </ng-template>
        </li>
        <li ngbNavItem="paid-payrolls">
          <a ngbNavLink>
            <i class="fa fa-usd" [class.success]="activePage == 'paid-payrolls'"></i>
            {{ 'FINANCES.HR.PAID_PAYROLLS.PAID_PAYROLLS_TITLE' | translate }}
          </a>
          <ng-template ngbNavContent>
            <app-paid-payrolls-list></app-paid-payrolls-list>
          </ng-template>
        </li>
        <li ngbNavItem="payroll-payments">
          <a ngbNavLink>
            <i class="fa fa-usd" [class.success]="activePage == 'payroll-payments'"></i>
            {{ 'FINANCES.HR.PAID_PAYROLLS.PAYROLL_PAYMENTS_TITLE' | translate }}
          </a>
          <ng-template ngbNavContent>
            <app-payroll-payments-list></app-payroll-payments-list>
          </ng-template>
        </li>
        <li ngbNavItem="payroll-payment-refunds">
          <a ngbNavLink>
            <i class="fa fa-usd" [class.success]="activePage == 'payroll-payment-refunds'"></i>
            {{ 'FINANCES.HR.PAID_PAYROLLS.PAYROLL_PAYMENT_REFUND_TITLE' | translate }}
          </a>
          <ng-template ngbNavContent>
            <app-payroll-payment-refunds-list></app-payroll-payment-refunds-list>
          </ng-template>
        </li>
      </ul>
      <div [ngbNavOutlet]="nav" class="mt-2"></div>
    </div>
  </div>
</div>

<!-- loans file pages end -->
