import { Action } from '@ngrx/store';

import { EosReason } from 'lookups/models';
import { AppHttpResponse } from 'shared';

/**
 * The Lookups-Module store end of service reason action types.
 */
export enum EndOfServiceReasonActionType {
  GET_ALL_END_OF_SERVICE_REASONS = '[Lookups] [EndOfServiceReason] Get All Annual Leaves',
  GET_ALL_END_OF_SERVICE_REASONS_FAIL = '[Lookups] [EndOfServiceReason] Get All Annual Leaves Fail',
  GET_ALL_END_OF_SERVICE_REASONS_SUCCESS = '[Lookups] [EndOfServiceReason] Get All Annual Leaves Success',
}

/**
 * Represents a store end of service reason action.
 */
export class GetAllEndOfServiceReasons implements Action {
  /**
   * The type of the action.
   */
  readonly type = EndOfServiceReasonActionType.GET_ALL_END_OF_SERVICE_REASONS;

  /**
   * Fires a new end of service reason action.
   */
  constructor() {}
}

/**
 * Represents a store end of service reason fail action.
 */
export class GetAllEndOfServiceReasonsFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = EndOfServiceReasonActionType.GET_ALL_END_OF_SERVICE_REASONS_FAIL;

  /**
   * Fires a new end of service reason fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store end of service reason success action.
 */
export class GetAllEndOfServiceReasonsSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = EndOfServiceReasonActionType.GET_ALL_END_OF_SERVICE_REASONS_SUCCESS;

  /**
   * Fires a end of service reason success action.
   * @param payload An object contains the list of end of service reasons.
   */
  constructor(public payload: AppHttpResponse<EosReason[]>) {}
}

/**
 * Lookups-module end of service reason action types.
 */
export type EndOfServiceReasonActions =
  | GetAllEndOfServiceReasons
  | GetAllEndOfServiceReasonsFail
  | GetAllEndOfServiceReasonsSuccess;
