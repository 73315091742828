<!-- pos devices search modal starts -->
<ng-template #modalRef let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title text-primary" id="modal-basic-title">
      {{ 'SALES.CASHER_PRINTERS.PRINTER_LIST' | translate }}
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="d(); closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <!-- pos devices list starts -->
    <div class="row">
      <div class="col-md-12">
        <form class="row" [formGroup]="searchForm" (ngSubmit)="search()">
          <div class="form-group col">
            <label for="posPrinters">{{ 'SALES.KITCHEN_PRINTERS.POS_PRINTERS' | translate }}</label>
            <ng-select
              class="round"
              formControlName="posPrinters"
              [multiple]="true"
              [closeOnSelect]="false"
              labelForId="posPrinters"
              searchable="true"
              [placeholder]="'SALES.KITCHEN_PRINTERS.POS_PRINTERS_PLACEHOLDER' | translate"
              [notFoundText]="'SHARED.SEARCH.SEARCH_NOT_FOUND' | translate"
              (open)="posPrintersListVisibility = true"
            >
              <ng-option *ngFor="let posPrinter of posPrinters" [value]="posPrinter.id">
                {{ posPrinter.name }}
              </ng-option>
            </ng-select>
          </div>
          <div class="form-group col">
            <label for="posDevices">{{ 'SALES.POS_PRINTERS.POS_DEVICES' | translate }}</label>
            <ng-select
              class="round"
              formControlName="posDevices"
              [multiple]="true"
              [closeOnSelect]="false"
              labelForId="posDevices"
              searchable="true"
              [placeholder]="'SALES.POS_PRINTERS.POS_DEVICES_PLACEHOLDER' | translate"
              [notFoundText]="'SHARED.SEARCH.SEARCH_NOT_FOUND' | translate"
              (open)="posDevicesListVisibility = true"
            >
              <ng-option *ngFor="let posDevice of posDevices" [value]="posDevice.id">
                {{ posDevice.name | localize: posDevice.nameEn | async }}
              </ng-option>
            </ng-select>
          </div>
          <div class="form-group col">
            <label for="locations">{{ 'SALES.POS_DEVICES.LOCATIONS' | translate }}</label>
            <ng-select
              class="round"
              formControlName="locations"
              [multiple]="true"
              [closeOnSelect]="false"
              labelForId="locations"
              searchable="true"
              [placeholder]="'SALES.POS_DEVICES.LOCATIONS_PLACEHOLDER' | translate"
              [notFoundText]="'SHARED.SEARCH.SEARCH_NOT_FOUND' | translate"
              (open)="locationsListVisibility = true"
            >
              <ng-option *ngFor="let location of locations" [value]="location.id">
                {{ location.name | localize: location.nameEn | async }}
              </ng-option>
            </ng-select>
          </div>
          <div class="col-md-auto form-group d-flex">
            <button
              type="submit"
              class="btn btn-raised btn-primary align-self-end round mx-1 mb-0"
              [disabled]="isSearching$ | async"
            >
              <i class="fa fa-search"></i>
            </button>
          </div>
        </form>
      </div>
      <div class="col-md-12" *ngIf="(posPrinterAdjustments$ | async)?.length; else noItems">
        <div class="row">
          <div class="col-md-12 alert alert-light text-center" role="alert">
            {{ 'SHARED.SEARCH.SEARCH_RESULTS' | translate }} ({{ (paginationInfo$ | async).total | toNumber }})
            {{ 'SALES.POS_PRINTERS.PRINTER' | translate }}
          </div>
          <div class="col-md-12">
            <div class="table-responsive">
              <table class="table text-center">
                <thead>
                  <tr>
                    <th class="fit-width" *ngIf="!closeOnSelect">
                      <a
                        class="primary p-0"
                        (click)="selectPosPrinterAdjustment(posPrinterAdjustmentsList)"
                        [ngbTooltip]="'SHARED.SEARCH.SELECT_ALL' | translate"
                      >
                        <i class="fa fa-check-circle font-medium-3"></i>
                      </a>
                    </th>
                    <th class="fit-width" *ngIf="closeOnSelect">
                      <i class="fa fa-gear font-medium-3"></i>
                    </th>
                    <th scope="col"><i class="fa fa-list-ol"></i></th>
                    <th scope="col">{{ 'SALES.POS_PRINTERS.POS_PRINTER_NAME' | translate }}</th>
                    <th scope="col">
                      {{ 'SALES.POS_PRINTERS.POS_PRINTER_IP' | translate }}
                    </th>
                    <th scope="col">
                      {{ 'SALES.POS_DEVICES.POS_DEVICE_NAME' | translate }}
                    </th>
                    <th scope="col">
                      {{ 'SALES.POS_DEVICES.LOCATION' | translate }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    *ngFor="
                      let posPrinterAdjustment of posPrinterAdjustments$ | async;
                      trackBy: trackItems;
                      let i = index
                    "
                  >
                    <td>
                      <a
                        class="primary p-0"
                        (click)="selectPosPrinterAdjustment([posPrinterAdjustment])"
                        [ngbTooltip]="'SALES.KITCHEN_PRINTERS.TOOL_TIP_KITCHEN_PRINTER_SELECT' | translate"
                      >
                        <i class="fa fa-check-circle-o font-medium-3"></i>
                      </a>
                    </td>
                    <th scope="row">{{ i + 1 }}</th>
                    <td>
                      {{ posPrinterAdjustment.posPrinter.name }}
                    </td>
                    <td>
                      {{ posPrinterAdjustment.posPrinter.ip }}
                    </td>
                    <td>
                      {{
                        posPrinterAdjustment.posPrinter.posDevice.name
                          | localize: posPrinterAdjustment.posPrinter.posDevice.nameEn
                          | async
                      }}
                    </td>
                    <td>
                      {{
                        posPrinterAdjustment.posPrinter.posDevice.location.name
                          | localize: posPrinterAdjustment.posPrinter.posDevice.location.nameEn
                          | async
                      }}
                    </td>
                  </tr>
                </tbody>
              </table>
              <div class="d-flex justify-content-between p-2">
                <ngb-pagination
                  *ngIf="paginationInfo$ | async; let pagination"
                  maxSize="5"
                  [collectionSize]="pagination.total"
                  [pageSize]="pagination.pageSize"
                  [page]="pagination.page"
                  (pageChange)="pageChanged($event)"
                  boundaryLinks="true"
                >
                  <ng-template ngbPaginationFirst>{{ 'SHARED.PAGINATION.PAGINATION_FIRST' | translate }}</ng-template>
                  <ng-template ngbPaginationPrevious>{{
                    'SHARED.PAGINATION.PAGINATION_PREVIOUS' | translate
                  }}</ng-template>
                  <ng-template ngbPaginationNext>{{ 'SHARED.PAGINATION.PAGINATION_NEXT' | translate }}</ng-template>
                  <ng-template ngbPaginationLast>{{ 'SHARED.PAGINATION.PAGINATION_LAST' | translate }}</ng-template>
                </ngb-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- pos devices list ends -->
  </div>
  <div class="modal-footer">
    <div class="modal-actions">
      <button type="button" class="btn btn-outline-dark round" (click)="c(); closeModal()">
        <i class="fa fa-times"></i>
        <span class="mx-1"> {{ 'SHARED.CONFIRM.CLOSE' | translate }} </span>
      </button>
    </div>
  </div>
</ng-template>
<!-- pos devices search modal ends -->

<!-- no items template starts -->
<ng-template #noItems>
  <div class="col-md-12 alert alert-warning mt-3 text-center" role="alert">
    {{ 'SALES.KITCHEN_PRINTERS.NO_SEARCH_RESULT_POS_DEVICE' | translate }}
  </div>
</ng-template>
<!-- no items template ends -->

<!-- pos device classes search starts -->
<app-pos-printers-search (posPrinter)="selectPosPrinters($event)" [(visible)]="posPrintersListVisibility">
</app-pos-printers-search>
<!-- pos device classes search ends -->

<!-- pos device classes search starts -->
<app-pos-devices-search onlyActive="true" (posDevice)="selectPosDevice($event)" [(visible)]="posDevicesListVisibility">
</app-pos-devices-search>
<!-- pos device classes search ends -->

<!-- pos device classes search starts -->
<app-locations-search onlyActive="true" (locations)="selectLocations($event)" [(visible)]="locationsListVisibility">
</app-locations-search>
<!-- pos device classes search ends -->
