<!-- page header starts -->
<app-page-header [info]="pageInfo"></app-page-header>
<!-- page header ends -->

<!-- view sale return starts -->
<div class="card">
  <div class="card-content">
    <div class="px-3 pt-3" *ngIf="saleReturn$ | async; let saleReturn">
      <div class="row">
        <div class="col-md-12">
          <ul
            class="nav-tabs"
            ngbNav
            #nav="ngbNav"
            [(activeId)]="activePage"
            (activeIdChange)="selectedPageChanged($event)"
            [destroyOnHide]="false"
          >
            <li ngbNavItem="details">
              <a ngbNavLink>
                <i class="fa fa-file-text" [class.success]="activePage == 'details'"></i>
                {{ 'SALES.RETURNS.SALE_RETURN_DATA_TITLE' | translate }}
              </a>
              <ng-template ngbNavContent>
                <app-sale-return-details [saleReturn]="saleReturn"></app-sale-return-details>
              </ng-template>
            </li>
            <li ngbNavItem="attachments">
              <a ngbNavLink>
                <i class="fa fa-paperclip" [class.success]="activePage == 'attachments'"></i>
                {{ 'SALES.RETURNS.SALE_RETURN_DATA.ATTACHMENTS' | translate }}
              </a>
              <ng-template ngbNavContent>
                <app-files-viewer
                  *ngIf="saleReturn.saleReturnAttachments?.length; else noAttachments"
                  [files]="getAttachmentsUrls(saleReturn.saleReturnAttachments)"
                ></app-files-viewer>
              </ng-template>
            </li>
            <li ngbNavItem="journal">
              <a ngbNavLink>
                <i class="fa fa-calendar" [class.success]="activePage == 'journal'"></i>
                {{ 'SHARED.JOURNAL_DATA.JOURNAL' | translate }}
              </a>
              <ng-template ngbNavContent>
                <app-journal-details [journal]="saleReturn.journalAssociations[0]?.journal"> </app-journal-details>
              </ng-template>
            </li>
          </ul>
          <div [ngbNavOutlet]="nav" class="mt-2"></div>
        </div>
      </div>
      <div class="row mt-2">
        <div class="form-group col-12 d-flex justify-content-between flex-column flex-sm-row mt-2">
          <button type="button" class="btn btn-raised btn-secondary round" ngbDropdown display="dynamic">
            <i class="fa fa-print"></i>
            <span class="mx-1" id="dropdownPrintPaperSizes" ngbDropdownToggle>
              {{ 'SHARED.CONFIRM.PRINT' | translate }}
            </span>
            <div ngbDropdownMenu aria-labelledby="dropdownPrintPaperSizes" class="text-center">
              <ng-container *ngFor="let printPaperSize of printPaperSizes$ | async">
                <button class="dropdown-item py-1" (click)="print(printPaperSize.key)">
                  <i class="fa fa-print"></i>
                  <span class="mx-1"> {{ printPaperSize.name | localize: printPaperSize.nameEn | async }}</span>
                </button>
              </ng-container>
            </div>
          </button>

          <button
            type="button"
            class="btn btn-raised btn-info round"
            *ngIf="
              [
                Claims.CREATE_SALE_INVOICE,
                Claims.CREATE_MAINTENANCE_SALE_INVOICE,
                Claims.CREATE_CUSTOMER_OPENING_BALANCE_INVOICE,
                Claims.CREATE_POINT_OF_SALE_INVOICE
              ]
                | authorize
                | async
            "
            [routerLink]="['/sales/invoices/view', saleReturn.saleInvoiceId]"
          >
            <i class="fa fa-eye"></i>
            <span class="mx-1">{{ 'SALES.RETURNS.SALE_RETURN_DATA.DISPLAY_SALES_INVOICE' | translate }}</span>
          </button>
          <a class="btn btn btn-raised btn-dark round" routerLink="/sales/returns">
            <i class="fa fa-undo"></i>
            <span class="mx-1">{{ 'SALES.RETURNS.SALE_RETURN_DATA.SALES_RETURNS_RETURN' | translate }}</span>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- view sale return ends -->

<!-- no attachments template starts -->
<ng-template #noAttachments>
  <div class="col-md-12 alert alert-warning mt-3 text-center" role="alert">
    {{ 'SALES.RETURNS.SALE_RETURN_DATA.SEARCH_ATTACHMENTS_NOT_FOUND' | translate }}
  </div>
</ng-template>
<!-- no attachments template ends -->
