<div
  class="dockable-status dockable-status-badge"
  *ngIf="!isCentered"
  [ngClass]="status ? 'active' : 'not-active'"
  [ngbTooltip]="status ? activeText : notActiveText"
>
  <i class="fa" [ngClass]="status ? 'fa-check-circle' : 'fa-times-circle'"></i>
</div>

<div
  class="dockable-status centered"
  *ngIf="isCentered"
  [ngClass]="status ? 'active' : 'not-active'"
  [ngbTooltip]="status ? activeText : notActiveText"
>
  <i class="fa" [ngClass]="status ? 'fa-check-circle' : 'fa-exclamation-circle'"></i>
</div>
