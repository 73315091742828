import { findIndex, remove } from 'lodash';

import {
  CustomersActionType,
  CustomersActions,
  CustomerEngagementsActions,
  CustomerEngagementsActionType,
} from 'sales/store/actions';
import { CustomersState } from 'sales/store/states';

/**
 * The initial state from which the state starts.
 */
const initialState: CustomersState = {
  data: [],
  customerLogsData: [],
  customersPaymentsData: [],
  customerAdvancePaymentsData: [],
  paginationInfo: {
    total: 0,
    page: 1,
    pageSize: 0,
    count: 0,
  },
  customerLogsPaginationInfo: {
    total: 0,
    page: 1,
    pageSize: 0,
    count: 0,
  },
  customersPaymentsPaginationInfo: {
    total: 0,
    page: 1,
    pageSize: 0,
    count: 0,
  },
  customerAdvancePaymentsPaginationInfo: {
    total: 0,
    page: 1,
    pageSize: 0,
    count: 0,
  },
  error: null,
  isSearching: false,
  isSearchCompleted: false,
  isSearchingCustomerLogs: false,
  isSearchCustomerLogsCompleted: false,
  isSearchingCustomersPayments: false,
  isSearchCustomersPaymentsCompleted: false,
  isSearchingCustomerAdvancePayments: false,
  isSearchCustomerAdvancePaymentsCompleted: false,
  selectedCustomer: null,
  selectedCustomerLog: null,
  isFinding: false,
  isFindCompleted: false,
  isCreating: false,
  isCreateCompleted: false,
  isCreatingPayments: false,
  isCreatePaymentsCompleted: false,
  isCreatingAdvancePayment: false,
  isCreateAdvancePaymentCompleted: false,
  isCreatingPaymentByAdvancePayment: false,
  isCreatePaymentByAdvancePaymentCompleted: false,
  isRefundCreating: false,
  isCreateRefundCompleted: false,
  isUpdating: false,
  isUpdateCompleted: false,
  isBlocking: false,
  isBlockCompleted: false,
  isActivating: false,
  isActivateCompleted: false,
  isDeleting: false,
  isDeleteCompleted: false,
  isCancelingLog: false,
  isCancelLogCompleted: false,
};

/**
 * The reducer function that is called in each action dispatch against the state.
 * @param state The current state.
 * @param action The action that will affect the state.
 */
export function customersReducer(
  state: CustomersState = initialState,
  action: CustomersActions | CustomerEngagementsActions
): CustomersState {
  switch (action.type) {
    //#region SEARCH_CUSTOMERS

    case CustomersActionType.SEARCH_CUSTOMERS: {
      return {
        ...state,
        error: null,
        isSearching: true,
        isSearchCompleted: false,
      };
    }

    case CustomersActionType.SEARCH_CUSTOMERS_FAIL: {
      return {
        ...state,
        error: action.payload,
        isSearching: false,
        isSearchCompleted: false,
      };
    }

    case CustomersActionType.SEARCH_CUSTOMERS_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        paginationInfo: {
          ...action.payload.meta,
        },
        error: null,
        isSearching: false,
        isSearchCompleted: true,
      };
    }

    //#endregion SEARCH_CUSTOMERS

    //#region SEARCH_CUSTOMER_LOGS

    case CustomersActionType.SEARCH_CUSTOMER_LOGS: {
      return {
        ...state,
        error: null,
        isSearchingCustomerLogs: true,
        isSearchCustomerLogsCompleted: false,
      };
    }

    case CustomersActionType.SEARCH_CUSTOMER_LOGS_FAIL: {
      return {
        ...state,
        error: action.payload,
        isSearchingCustomerLogs: false,
        isSearchCustomerLogsCompleted: false,
      };
    }

    case CustomersActionType.SEARCH_CUSTOMER_LOGS_SUCCESS: {
      return {
        ...state,
        customerLogsData: action.payload.data.logs,
        customerLogsPaginationInfo: {
          ...action.payload.meta,
        },
        selectedCustomer: action.payload.data.customer,
        error: null,
        isSearchingCustomerLogs: false,
        isSearchCustomerLogsCompleted: true,
      };
    }

    //#endregion SEARCH_CUSTOMER_LOGS

    //#region SEARCH_CUSTOMERS_PAYMENTS

    case CustomersActionType.SEARCH_CUSTOMERS_PAYMENTS: {
      return {
        ...state,
        error: null,
        isSearchingCustomersPayments: true,
        isSearchCustomersPaymentsCompleted: false,
      };
    }

    case CustomersActionType.SEARCH_CUSTOMERS_PAYMENTS_FAIL: {
      return {
        ...state,
        error: action.payload,
        isSearchingCustomersPayments: false,
        isSearchCustomersPaymentsCompleted: false,
      };
    }

    case CustomersActionType.SEARCH_CUSTOMERS_PAYMENTS_SUCCESS: {
      return {
        ...state,
        customersPaymentsData: action.payload.data,
        customersPaymentsPaginationInfo: {
          ...action.payload.meta,
        },
        error: null,
        isSearchingCustomersPayments: false,
        isSearchCustomersPaymentsCompleted: true,
      };
    }

    //#endregion SEARCH_CUSTOMERS_PAYMENTS

    //#region SEARCH_CUSTOMER_ADVANCE_PAYMENTS

    case CustomersActionType.SEARCH_CUSTOMER_ADVANCE_PAYMENTS: {
      return {
        ...state,
        error: null,
        isSearchingCustomerAdvancePayments: true,
        isSearchCustomerAdvancePaymentsCompleted: false,
      };
    }

    case CustomersActionType.SEARCH_CUSTOMER_ADVANCE_PAYMENTS_FAIL: {
      return {
        ...state,
        error: action.payload,
        isSearchingCustomerAdvancePayments: false,
        isSearchCustomerAdvancePaymentsCompleted: false,
      };
    }

    case CustomersActionType.SEARCH_CUSTOMER_ADVANCE_PAYMENTS_SUCCESS: {
      return {
        ...state,
        customerAdvancePaymentsData: action.payload.data,
        customerAdvancePaymentsPaginationInfo: {
          ...action.payload.meta,
        },
        error: null,
        isSearchingCustomerAdvancePayments: false,
        isSearchCustomerAdvancePaymentsCompleted: true,
      };
    }

    //#endregion SEARCH_CUSTOMER_ADVANCE_PAYMENTS

    //#region FIND_CUSTOMER

    case CustomersActionType.FIND_CUSTOMER: {
      return {
        ...state,
        selectedCustomer: null,
        error: null,
        isFinding: true,
        isFindCompleted: false,
      };
    }

    case CustomersActionType.FIND_CUSTOMER_FAIL: {
      return {
        ...state,
        error: action.payload,
        isFinding: false,
        isFindCompleted: false,
      };
    }

    case CustomersActionType.FIND_CUSTOMER_SUCCESS: {
      let customers = [...state.data];
      const customerIndex = findIndex(customers, (customer) => customer.id === action.payload.data.id);

      /* If customer was found, update it. */
      if (customerIndex >= 0) {
        customers[customerIndex] = action.payload.data;
      } else {
        /* else, insert it to the beginning of the array. */
        customers = [action.payload.data, ...customers];
      }

      return {
        ...state,
        data: customers,
        selectedCustomer: action.payload.data,
        error: null,
        isFinding: false,
        isFindCompleted: true,
      };
    }

    //#endregion FIND_CUSTOMER

    //#region CREATE_CUSTOMER

    case CustomersActionType.CREATE_CUSTOMER: {
      return {
        ...state,
        error: null,
        isCreating: true,
        isCreateCompleted: false,
      };
    }

    case CustomersActionType.CREATE_CUSTOMER_FAIL: {
      return {
        ...state,
        error: action.payload,
        isCreating: false,
        isCreateCompleted: false,
      };
    }

    case CustomersActionType.CREATE_CUSTOMER_SUCCESS: {
      return {
        ...state,
        data: [action.payload.data, ...state.data],
        selectedCustomer: action.payload.data,
        error: null,
        isCreating: false,
        isCreateCompleted: true,
      };
    }

    //#endregion CREATE_CUSTOMER

    //#region CREATE_CUSTOMER_PAYMENTS

    case CustomersActionType.CREATE_CUSTOMER_PAYMENTS: {
      return {
        ...state,
        error: null,
        isCreatingPayments: true,
        isCreatePaymentsCompleted: false,
      };
    }

    case CustomersActionType.CREATE_CUSTOMER_PAYMENTS_FAIL: {
      return {
        ...state,
        error: action.payload,
        isCreatingPayments: false,
        isCreatePaymentsCompleted: false,
      };
    }

    case CustomersActionType.CREATE_CUSTOMER_PAYMENTS_SUCCESS: {
      let customerLogs = [...state.customerLogsData];
      const customerLogIndex = findIndex(customerLogs, (customerLog) => customerLog.id === action.payload.data.id);

      /* If customer was found, update it. */

      if (customerLogIndex >= 0) {
        customerLogs[customerLogIndex] = action.payload.data;
      } else {
        /* else, insert it to the beginning of the array. */
        customerLogs = [...customerLogs, action.payload.data];
      }

      return {
        ...state,
        customerLogsData: customerLogs,
        selectedCustomerLog: action.payload.data,
        selectedCustomer: { ...state.selectedCustomer, balance: action.payload.data.balanceAfter },
        error: null,
        isCreatingPayments: false,
        isCreatePaymentsCompleted: true,
      };
    }

    //#endregion CREATE_CUSTOMER_PAYMENTS

    //#region CREATE_CUSTOMER_ADVANCE_PAYMENT

    case CustomersActionType.CREATE_CUSTOMER_ADVANCE_PAYMENT: {
      return {
        ...state,
        error: null,
        isCreatingAdvancePayment: true,
        isCreateAdvancePaymentCompleted: false,
      };
    }

    case CustomersActionType.CREATE_CUSTOMER_ADVANCE_PAYMENT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isCreatingAdvancePayment: false,
        isCreateAdvancePaymentCompleted: false,
      };
    }

    case CustomersActionType.CREATE_CUSTOMER_ADVANCE_PAYMENT_SUCCESS: {
      let customerLogs = [...state.customerLogsData];
      const customerLogIndex = findIndex(customerLogs, (customerLog) => customerLog.id === action.payload.data.id);

      /* If customer was found, update it. */

      if (customerLogIndex >= 0) {
        customerLogs[customerLogIndex] = action.payload.data;
      } else {
        /* else, insert it to the beginning of the array. */
        customerLogs = [...customerLogs, action.payload.data];
      }

      return {
        ...state,
        customerLogsData: customerLogs,
        selectedCustomerLog: action.payload.data,
        selectedCustomer: { ...state.selectedCustomer, balance: action.payload.data.balanceAfter },
        error: null,
        isCreatingAdvancePayment: false,
        isCreateAdvancePaymentCompleted: true,
      };
    }

    //#endregion CREATE_CUSTOMER_ADVANCE_PAYMENT

    //#region CREATE_CUSTOMER_PAYMENT_BY_ADVANCE_PAYMENT

    case CustomersActionType.CREATE_CUSTOMER_PAYMENT_BY_ADVANCE_PAYMENT: {
      return {
        ...state,
        error: null,
        isCreatingPaymentByAdvancePayment: true,
        isCreatePaymentByAdvancePaymentCompleted: false,
      };
    }

    case CustomersActionType.CREATE_CUSTOMER_PAYMENT_BY_ADVANCE_PAYMENT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isCreatingPaymentByAdvancePayment: false,
        isCreatePaymentByAdvancePaymentCompleted: false,
      };
    }

    case CustomersActionType.CREATE_CUSTOMER_PAYMENT_BY_ADVANCE_PAYMENT_SUCCESS: {
      let customerLogs = [...state.customerLogsData];
      const customerLogIndex = findIndex(customerLogs, (customerLog) => customerLog.id === action.payload.data.id);

      /* If customer was found, update it. */

      if (customerLogIndex >= 0) {
        customerLogs[customerLogIndex] = action.payload.data;
      } else {
        /* else, insert it to the beginning of the array. */
        customerLogs = [...customerLogs, action.payload.data];
      }

      return {
        ...state,
        customerLogsData: customerLogs,
        selectedCustomerLog: action.payload.data,
        selectedCustomer: { ...state.selectedCustomer, balance: action.payload.data.balanceAfter },
        error: null,
        isCreatingPaymentByAdvancePayment: false,
        isCreatePaymentByAdvancePaymentCompleted: true,
      };
    }

    //#endregion CREATE_CUSTOMER_PAYMENT_BY_ADVANCE_PAYMENT

    //#region CREATE_CUSTOMER_REFUND

    case CustomersActionType.CREATE_CUSTOMER_REFUND: {
      return {
        ...state,
        error: null,
        isRefundCreating: true,
        isCreateRefundCompleted: false,
      };
    }

    case CustomersActionType.CREATE_CUSTOMER_REFUND_FAIL: {
      return {
        ...state,
        error: action.payload,
        isRefundCreating: false,
        isCreateRefundCompleted: false,
      };
    }

    case CustomersActionType.CREATE_CUSTOMER_REFUND_SUCCESS: {
      let customerLogs = [...state.customerLogsData];
      const customerLogIndex = findIndex(customerLogs, (customerLog) => customerLog.id === action.payload.data.id);

      /* If customer was found, update it. */

      if (customerLogIndex >= 0) {
        customerLogs[customerLogIndex] = action.payload.data;
      } else {
        /* else, insert it to the beginning of the array. */
        customerLogs = [...customerLogs, action.payload.data];
      }

      return {
        ...state,
        customerLogsData: customerLogs,
        selectedCustomerLog: action.payload.data,
        selectedCustomer: { ...state.selectedCustomer, balance: action.payload.data.balanceAfter },
        error: null,
        isRefundCreating: false,
        isCreateRefundCompleted: true,
      };
    }

    //#endregion CREATE_CUSTOMER_REFUND

    //#region UPDATE_CUSTOMER

    case CustomersActionType.UPDATE_CUSTOMER: {
      return {
        ...state,
        error: null,
        isUpdating: true,
        isUpdateCompleted: false,
      };
    }

    case CustomersActionType.UPDATE_CUSTOMER_FAIL: {
      return {
        ...state,
        error: action.payload,
        isUpdating: false,
        isUpdateCompleted: false,
      };
    }

    case CustomersActionType.UPDATE_CUSTOMER_SUCCESS: {
      let customers = [...state.data];
      const customerIndex = findIndex(customers, (customer) => customer.id === action.payload.data.id);

      /* If customer was found, update it. */
      if (customerIndex >= 0) {
        customers[customerIndex] = action.payload.data;
      } else {
        /* else, insert it to the beginning of the array. */
        customers = [action.payload.data, ...customers];
      }

      return {
        ...state,
        data: customers,
        selectedCustomer: action.payload.data,
        error: null,
        isUpdating: false,
        isUpdateCompleted: true,
      };
    }

    //#endregion UPDATE_CUSTOMER

    //#region BLOCK_CUSTOMER

    case CustomersActionType.BLOCK_CUSTOMER: {
      return {
        ...state,
        error: null,
        isBlocking: true,
        isBlockCompleted: false,
      };
    }

    case CustomersActionType.BLOCK_CUSTOMER_FAIL: {
      return {
        ...state,
        error: action.payload.data,
        isBlocking: false,
        isBlockCompleted: false,
      };
    }

    case CustomersActionType.BLOCK_CUSTOMER_SUCCESS: {
      let customers = [...state.data];
      const customerIndex = findIndex(customers, (customer) => customer.id === action.payload.data.id);

      /* If customer was found, update it. */
      if (customerIndex >= 0) {
        customers[customerIndex] = action.payload.data;
      } else {
        /* else, insert it to the beginning of the array. */
        customers = [action.payload.data, ...customers];
      }

      return {
        ...state,
        data: customers,
        selectedCustomer: action.payload.data,
        error: null,
        isBlocking: false,
        isBlockCompleted: true,
      };
    }

    //#endregion BLOCK_CUSTOMER

    //#region ACTIVATE_CUSTOMER

    case CustomersActionType.ACTIVATE_CUSTOMER: {
      return {
        ...state,
        error: null,
        isActivating: true,
        isActivateCompleted: false,
      };
    }

    case CustomersActionType.ACTIVATE_CUSTOMER_FAIL: {
      return {
        ...state,
        error: action.payload.data,
        isActivating: false,
        isActivateCompleted: false,
      };
    }

    case CustomersActionType.ACTIVATE_CUSTOMER_SUCCESS: {
      let customers = [...state.data];
      const customerIndex = findIndex(customers, (customer) => customer.id === action.payload.data.id);

      /* If customer was found, update it. */
      if (customerIndex >= 0) {
        customers[customerIndex] = action.payload.data;
      } else {
        /* else, insert it to the beginning of the array. */
        customers = [action.payload.data, ...customers];
      }

      return {
        ...state,
        data: customers,
        selectedCustomer: action.payload.data,
        error: null,
        isActivating: false,
        isActivateCompleted: true,
      };
    }

    //#endregion ACTIVATE_CUSTOMER

    //#region DELETE_CUSTOMER

    case CustomersActionType.DELETE_CUSTOMER: {
      return {
        ...state,
        error: null,
        isDeleting: true,
        isDeleteCompleted: false,
      };
    }

    case CustomersActionType.DELETE_CUSTOMER_FAIL: {
      return {
        ...state,
        error: action.payload.data,
        isDeleting: false,
        isDeleteCompleted: false,
      };
    }

    case CustomersActionType.DELETE_CUSTOMER_SUCCESS: {
      const customers = [...state.data];

      /* Remove the deleted customer from the array. */
      remove(customers, (customer) => customer.id === action.payload.data.id);

      return {
        ...state,
        data: customers,
        selectedCustomer: action.payload.data,
        error: null,
        isDeleting: false,
        isDeleteCompleted: true,
      };
    }

    //#endregion DELETE_CUSTOMER

    //#region CREATE_CUSTOMER_ENGAGEMENT

    case CustomerEngagementsActionType.CREATE_CUSTOMER_ENGAGEMENT_SUCCESS: {
      if (!state.selectedCustomer || state.selectedCustomer?.id !== action.payload.data?.customer?.id) {
        return state;
      }

      const customerEngagements = [action.payload.data, ...state.selectedCustomer.customerEngagements];

      return {
        ...state,
        selectedCustomer: {
          ...state.selectedCustomer,
          customerEngagements: [...customerEngagements],
        },
      };
    }

    //#endregion CREATE_CUSTOMER_ENGAGEMENT

    //#region UPDATE_CUSTOMER_ENGAGEMENT

    case CustomerEngagementsActionType.UPDATE_CUSTOMER_ENGAGEMENT_SUCCESS: {
      if (!state.selectedCustomer || state.selectedCustomer?.id !== action.payload.data?.customer?.id) {
        return state;
      }

      let customerEngagements = [...state.selectedCustomer.customerEngagements];
      const customerEngagementIndex = findIndex(
        customerEngagements,
        (customerEngagement) => customerEngagement.id === action.payload.data.id
      );

      /* If customer was found, update it. */
      if (customerEngagementIndex >= 0) {
        customerEngagements[customerEngagementIndex] = action.payload.data;
      } else {
        /* else, insert it to the beginning of the array. */
        customerEngagements = [action.payload.data, ...customerEngagements];
      }

      return {
        ...state,
        selectedCustomer: {
          ...state.selectedCustomer,
          customerEngagements: [...customerEngagements],
        },
      };
    }

    //#endregion UPDATE_CUSTOMER_ENGAGEMENT

    //#region DELETE_CUSTOMER_ENGAGEMENT

    case CustomerEngagementsActionType.DELETE_CUSTOMER_ENGAGEMENT_SUCCESS: {
      if (!state.selectedCustomer || state.selectedCustomer?.id !== action.payload.data?.customer?.id) {
        return state;
      }

      const customerEngagements = [...state.selectedCustomer.customerEngagements];

      /* Remove the deleted customer engagement from the array. */
      remove(customerEngagements, (customerEngagement) => customerEngagement.id === action.payload.data.id);

      return {
        ...state,
        selectedCustomer: {
          ...state.selectedCustomer,
          customerEngagements: [...customerEngagements],
        },
      };
    }

    //#endregion DELETE_CUSTOMER_ENGAGEMENT

    //#region CANCEL_CUSTOMER_LOG

    case CustomersActionType.CANCEL_CUSTOMER_LOG: {
      return {
        ...state,
        error: null,
        isCancelingLog: true,
        isCancelLogCompleted: false,
      };
    }

    case CustomersActionType.CANCEL_CUSTOMER_LOG_FAIL: {
      return {
        ...state,
        error: action.payload,
        isCancelingLog: false,
        isCancelLogCompleted: false,
      };
    }

    case CustomersActionType.CANCEL_CUSTOMER_LOG_SUCCESS: {
      let customerLogs = [...state.customerLogsData];

      const customerLogIndex = findIndex(customerLogs, (customerLog) => customerLog.id === action.payload.data.log.id);

      /* If customer logs was found, update it. */
      if (customerLogIndex >= 0) {
        customerLogs[customerLogIndex] = action.payload.data.log;
      }

      /* Insert the cancel log at the end of the array. */
      customerLogs = [...customerLogs, action.payload.data.cancelLog];

      return {
        ...state,
        customerLogsData: customerLogs,
        selectedCustomerLog: { ...action.payload.data.cancelLog },
        selectedCustomer: { ...action.payload.data.customer },
        error: null,
        isCancelingLog: false,
        isCancelLogCompleted: true,
      };
    }

    //#endregion CANCEL_CUSTOMER_LOG

    default:
      return state;
  }
}
