import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { FormArray, FormGroup, FormControl } from '@angular/forms';

import { CostCenterFormItem, CostCenter } from 'finances/models';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-cost-centers-form',
  templateUrl: './cost-centers-form.component.html',
  styles: [],
})
export class CostCentersFormComponent {
  /**
   * The confirm modal template reference.
   */
  @ViewChild('deleteModalRef') deleteModalRef: ElementRef<any>;

  /**
   * Sets the cost-centers form-array.
   */
  @Input() formArray: FormArray;

  /**
   * Sets the name form-array in its parent form.
   * @default 'costCenters'
   */
  @Input() formArrayName = 'costCenters';

  /**
   * Adds a list of new cost center form items to the cost centers form.
   * @param items The list of cost center form items to be added to the cost centers form.
   */
  @Input() set newCostCenters(items: CostCenterFormItem[]) {
    if (!items?.length) {
      return;
    }

    items.forEach((item) => this.createCostCenterFormGroup(item));
  }

  /**
   * Shows or hides the cost centers list.
   */
  costCentersListVisibility = false;

  /**
   * @param modalService The modal service.
   */
  constructor(private modalService: NgbModal) {}

  /**
   * Creates & adds a new bank-account form-group with validations.
   * @param costCenter The cost-center details to be bounded to the cost-center, If omitted a blank cost-center will be created.
   */
  addCostCenter() {
    this.costCentersListVisibility = true;
  }

  /**
   * Open confirm delete all cost centers modal .
   */
  openDeleteModal() {
    this.openModal(this.deleteModalRef);
  }

  /**
   * Opens the modal of the given templateRef.
   * @param modalRef The modal templateRef to be opened.
   */
  openModal(modalRef) {
    this.modalService.open(modalRef);
  }

  /**
   * Remove the cost-center at the given index from the cost centers form.
   * @param index The index of the cost-center form group.
   */
  removeCostCenter(index: number) {
    this.formArray.removeAt(index);
  }

  /**
   * Remove all of the list of cost-centers.
   *
   * It only adds one cost-center for quick start.
   */
  resetCostCenters() {
    this.formArray.clear();
  }

  /**
   * Handles the invoices select event.
   * @param invoices The newly selected invoices.
   */
  selectCostCenter(costCenters: CostCenter[]) {
    if (costCenters) {
      costCenters.map((costCenter) =>
        this.createCostCenterFormGroup(this.createCostCenterFormItemFromCostCenter(costCenter))
      );
    }
  }

  /**
   * Creates and returns a new cost center form item from a given cost center.
   * @param costCenter The cost center to be used to create the cost center form item.
   */
  createCostCenterFormItemFromCostCenter(costCenter: CostCenter): CostCenterFormItem {
    return {
      costCenterId: costCenter.id,
      costCenter: costCenter,
    };
  }

  /**
   * Create a new cost center form group from the provided cost center form item.
   * @param costCenter The cost center form item that contains data about the new cost center.
   */
  createCostCenterFormGroup(costCenter: CostCenterFormItem) {
    if (this.isCostCenterExists(costCenter.costCenter.id)) {
      return;
    }

    const formGroup = new FormGroup({
      costCenterId: new FormControl(costCenter?.costCenterId ?? null),
      costCenter: new FormControl(costCenter?.costCenter),
    });
    this.formArray.push(formGroup);
  }

  /**
   * Indicates whether if the given cost center id is already exists in the form array or not.
   * @param costCenterId The id of the cost center.
   * @returns `boolean`.
   */
  isCostCenterExists(costCenterId: number): boolean {
    return (this.formArray.value as CostCenterFormItem[]).some(
      (costCenterItem) => costCenterItem.costCenter.id === costCenterId
    );
  }
}
