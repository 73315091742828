<!-- revenue details starts -->
<div class="row">
  <div class="col-md-12">
    <div class="card-header mb-3 pt-0">
      <h4 class="card-title">
        {{ 'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.INVOICE_DETAILS' | translate }} ({{
          revenue.saleInvoice.saleInvoiceType.name | localize: revenue.saleInvoice.saleInvoiceType.nameEn | async
        }})
      </h4>
    </div>
    <div class="card-content">
      <div class="px-3">
        <div class="row">
          <ng-container *ngIf="revenue.saleInvoiceId">
            <div class="form-group col-md-6">
              <label for="location">{{ 'SALES.INVOICES.SALE_INVOICE_DATA.SALE_INVOICE_LOCATION' | translate }}</label>
              <input
                type="text"
                id="location"
                class="form-control round"
                [value]="revenue.project.location.name | localize: revenue.project.location.nameEn | async"
                readonly
              />
            </div>
            <div class="form-group col-md-6">
              <label for="customer">{{ 'SALES.INVOICES.SALE_INVOICE_DATA.CUSTOMER' | translate }}</label>
              <input
                type="text"
                id="customer"
                class="form-control round"
                [value]="revenue.project.customer.name | localize: revenue.project.customer.nameEn | async"
                readonly
              />
            </div>
            <div class="form-group col-md-6">
              <label for="engagement">{{
                'SALES.INVOICES.SALE_INVOICE_DATA.CUSTOMER_ENGAGEMENT_TYPE' | translate
              }}</label>
              <div class="form-control round" readonly>
                <i class="fa fa-link c-primary"></i>
                <span class="mx-1">{{
                  revenue.saleInvoice.customerEngagement?.customerEngagementType?.name
                    | localize: revenue.saleInvoice.customerEngagement?.customerEngagementType.nameEn
                    | async
                }}</span>
                -
                <span class="text-danger" style="text-decoration: line-through;">{{
                  revenue.saleInvoice.customerEngagement?.value | toDecimal
                }}</span>
                - <span>({{ revenue.saleInvoice.customerEngagement?.remainingValue | toDecimal }})</span>
              </div>
            </div>
            <div class="form-group col-md-6">
              <label for="revenue.saleInvoice.saleInvoiceType.name">{{
                'SALES.INVOICES.SALE_INVOICE_DATA.INVOICE_TYPE' | translate
              }}</label>
              <input
                type="text"
                id="revenue.saleInvoice.saleInvoiceType.name"
                class="form-control round"
                [value]="
                  revenue.saleInvoice.saleInvoiceType.name
                    | localize: revenue.saleInvoice.saleInvoiceType.nameEn
                    | async
                "
                readonly
              />
            </div>
            <div class="form-group col-md-6">
              <label for="transactionDate">{{ 'SALES.INVOICES.SALE_INVOICE_DATA.INVOICE_DATE' | translate }}</label>
              <input
                type="text"
                id="transactionDate"
                class="form-control round"
                [value]="revenue.saleInvoice.transactionDate | date: 'datetime'"
                readonly
              />
            </div>
            <div class="form-group col-md-6">
              <label for="createdAt">{{ 'SHARED.SEARCH.CREATED_AT' | translate }}</label>
              <input
                type="text"
                id="createdAt"
                class="form-control round"
                [value]="revenue.saleInvoice.createdAt | date: 'datetime'"
                readonly
              />
            </div>
            <div class="form-group col-md-6">
              <label for="dateOfSupply">{{ 'SALES.INVOICES.SALE_INVOICE_DATA.SUPPLY_DATE' | translate }}</label>
              <input
                type="text"
                id="dateOfSupply"
                class="form-control round"
                [value]="revenue.saleInvoice.dateOfSupply | date: 'datetime'"
                readonly
              />
            </div>
            <div class="form-group col-md-6">
              <label for="user">{{ 'SALES.INVOICES.SALE_INVOICE_DATA.USER_CREATE' | translate }}</label>
              <input
                type="text"
                id="user"
                class="form-control round"
                [value]="revenue.saleInvoice.user.name"
                readonly
              />
            </div>
            <div class="form-group col-md-12">
              <label for="notes">{{ 'SALES.INVOICES.SALE_INVOICE_DATA.NOTES' | translate }}</label>
              <textarea
                id="notes"
                class="form-control round"
                [innerText]="revenue.saleInvoice.notes"
                [placeholder]="'SALES.INVOICES.SALE_INVOICE_DATA.NOTES_NOT_FOUND' | translate"
                readonly
              ></textarea>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
    <!-- customer details ends -->

    <!-- products details starts -->
    <div class="card-header mb-3 pt-2">
      <h4 class="card-title">{{ 'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.PRODUCTS' | translate }}</h4>
    </div>
    <div class="card-content">
      <div class="px-3">
        <div class="row">
          <div class="col-md-12">
            <div class="table-responsive">
              <table class="table text-center">
                <thead>
                  <tr>
                    <th scope="col" class="fit-width"><i class="fa fa-list-ol"></i></th>

                    <th scope="col" class="fit-width">
                      <i class="fa fa-comments"></i>
                      {{ 'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.PRODUCT_DESCRIPTION' | translate }}
                    </th>
                    <th scope="col" class="fit-width">
                      <i class="fa fa-list-ol"></i>
                      {{ 'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.PRODUCT_QUANTITY' | translate }}
                    </th>

                    <th scope="col" class="fit-width">
                      <i class="fa fa-usd"></i>
                      {{ 'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.PRODUCT_VALUE' | translate }}
                    </th>
                    <th scope="col" class="fit-width">
                      <i class="fa fa-usd"></i>
                      {{ 'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.PRODUCT_TOTAL' | translate }}
                    </th>
                    <th scope="col" class="fit-width">
                      <i class="fa fa-percent"></i>
                      {{ 'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.TAX' | translate }}
                    </th>
                    <th scope="col" class="fit-width">
                      <i class="fa fa-usd"></i>
                      {{ 'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.TAX_AMOUNT' | translate }}
                    </th>
                    <th scope="col" class="fit-width">
                      <i class="fa fa-usd"></i>
                      {{ 'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.NET_TOTAL' | translate }}
                    </th>
                    <th scope="col" class="fit-width">
                      {{ 'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.PROJECT_SUB_TASK' | translate }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let projectRevenueLine of revenue.projectRevenueLines; let i = index">
                    <th scope="row">{{ i + 1 }}</th>
                    <td>
                      {{
                        (projectRevenueLine.saleInvoiceProduct.product?.description
                          | localize: projectRevenueLine.saleInvoiceProduct.product?.descriptionEn
                          | async) || projectRevenueLine.saleInvoiceProduct.description
                      }}
                      <div *ngIf="projectRevenueLine.saleInvoiceProduct.subProduct" class="mt-1 d-block">
                        <app-sub-product-info
                          [subProduct]="projectRevenueLine.saleInvoiceProduct.subProduct"
                        ></app-sub-product-info>
                      </div>
                    </td>
                    <td>{{ projectRevenueLine.saleInvoiceProduct.quantity | toDecimal }}</td>
                    <td>{{ projectRevenueLine.saleInvoiceProduct.value | toDecimal }}</td>
                    <td>{{ projectRevenueLine.saleInvoiceProduct.total | toDecimal }}</td>
                    <td>{{ projectRevenueLine.saleInvoiceProduct.tax | toDecimal }}</td>
                    <td>{{ projectRevenueLine.saleInvoiceProduct.taxAmount | toDecimal }}</td>
                    <td>{{ projectRevenueLine.saleInvoiceProduct.netTotal | toDecimal }}</td>
                    <td>
                      {{
                        projectRevenueLine.saleInvoiceProduct.projectSubTask?.name
                          | localize: projectRevenueLine.saleInvoiceProduct.projectSubTask?.nameEn
                          | async
                      }}
                      <span class="d-block">
                        ({{
                          projectRevenueLine.saleInvoiceProduct.projectSubTask?.project.description
                            | localize: projectRevenueLine.saleInvoiceProduct.projectSubTask?.project.descriptionEn
                            | async
                        }})
                      </span>
                    </td>
                  </tr>
                </tbody>
                <tfoot></tfoot>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- products details ends -->
  </div>
</div>
<!-- revenue details ends -->
