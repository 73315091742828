import { Action } from '@ngrx/store';

import {
  Customer,
  CreateCustomerInput,
  UpdateCustomerInput,
  CustomerLog,
  CreateCustomerPaymentsInput,
  CreateCustomerAdvancePaymentInput,
  CreateCustomerPaymentByAdvancePaymentInput,
  CreateCustomerRefundInput,
} from 'sales/models';
import { AppHttpResponse } from 'shared';

/**
 * The Sales-Module store customers action types.
 */
export enum CustomersActionType {
  SEARCH_CUSTOMERS = '[Sales] [Customers] Search Customers',
  SEARCH_CUSTOMERS_FAIL = '[Sales] [Customers] Search Customers Fail',
  SEARCH_CUSTOMERS_SUCCESS = '[Sales] [Customers] Search Customers Success',

  SEARCH_CUSTOMER_LOGS = '[Sales] [Customers] Search Customer Logs',
  SEARCH_CUSTOMER_LOGS_FAIL = '[Sales] [Customers] Search Customer Logs Fail',
  SEARCH_CUSTOMER_LOGS_SUCCESS = '[Sales] [Customers] Search Customer Logs Success',

  SEARCH_CUSTOMERS_PAYMENTS = '[Sales] [Customers] Search customers Payments',
  SEARCH_CUSTOMERS_PAYMENTS_FAIL = '[Sales] [Customers] Search customers Payments Fail',
  SEARCH_CUSTOMERS_PAYMENTS_SUCCESS = '[Sales] [Customers] Search customers Payments Success',

  SEARCH_CUSTOMER_ADVANCE_PAYMENTS = '[Sales] [Customers] Search customer Advance Payments',
  SEARCH_CUSTOMER_ADVANCE_PAYMENTS_FAIL = '[Sales] [Customers] Search customer Advance Payments Fail',
  SEARCH_CUSTOMER_ADVANCE_PAYMENTS_SUCCESS = '[Sales] [Customers] Search customer Advance Payments Success',

  FIND_CUSTOMER = '[Sales] [Customers] Find Customer',
  FIND_CUSTOMER_FAIL = '[Sales] [Customers] Find Customer Fail',
  FIND_CUSTOMER_SUCCESS = '[Sales] [Customers] Find Customer Success',

  CREATE_CUSTOMER = '[Sales] [Customers] Create Customer',
  CREATE_CUSTOMER_FAIL = '[Sales] [Customers] Create Customer Fail',
  CREATE_CUSTOMER_SUCCESS = '[Sales] [Customers] Create Customer Success',

  CREATE_CUSTOMER_ADVANCE_PAYMENT = '[Sales] [Customers] Create Customer Advance Payment',
  CREATE_CUSTOMER_ADVANCE_PAYMENT_FAIL = '[Sales] [Customers] Create Customer Advance Payment Fail',
  CREATE_CUSTOMER_ADVANCE_PAYMENT_SUCCESS = '[Sales] [Customers] Create Customer Advance Payment Success',

  CREATE_CUSTOMER_PAYMENT_BY_ADVANCE_PAYMENT = '[Sales] [Customers] Create Customer Payment By Advance Payment',
  CREATE_CUSTOMER_PAYMENT_BY_ADVANCE_PAYMENT_FAIL = '[Sales] [Customers] Create Customer Payment By Advance Payment Fail',
  CREATE_CUSTOMER_PAYMENT_BY_ADVANCE_PAYMENT_SUCCESS = '[Sales] [Customers] Create Customer Payment By Advance Payment Success',

  CREATE_CUSTOMER_PAYMENTS = '[Sales] [Customers] Create Customer Payments',
  CREATE_CUSTOMER_PAYMENTS_FAIL = '[Sales] [Customers] Create Customer Payments Fail',
  CREATE_CUSTOMER_PAYMENTS_SUCCESS = '[Sales] [Customers] Create Customer Payments Success',

  CREATE_CUSTOMER_REFUND = '[Sales] [Customers] Create Customer Refund',
  CREATE_CUSTOMER_REFUND_FAIL = '[Sales] [Customers] Create Customer Refund Fail',
  CREATE_CUSTOMER_REFUND_SUCCESS = '[Sales] [Customers] Create Customer Refund Success',

  UPDATE_CUSTOMER = '[Sales] [Customers] Update Customer',
  UPDATE_CUSTOMER_FAIL = '[Sales] [Customers] Update Customer Fail',
  UPDATE_CUSTOMER_SUCCESS = '[Sales] [Customers] Update Customer Success',

  BLOCK_CUSTOMER = '[Sales] [Customers] Block Customer',
  BLOCK_CUSTOMER_FAIL = '[Sales] [Customers] Block Customer Fail',
  BLOCK_CUSTOMER_SUCCESS = '[Sales] [Customers] Block Customer Success',

  ACTIVATE_CUSTOMER = '[Sales] [Customers] Activate Customer',
  ACTIVATE_CUSTOMER_FAIL = '[Sales] [Customers] Activate Customer Fail',
  ACTIVATE_CUSTOMER_SUCCESS = '[Sales] [Customers] Activate Customer Success',

  DELETE_CUSTOMER = '[Sales] [Customers] Delete Customer',
  DELETE_CUSTOMER_FAIL = '[Sales] [Customers] Delete Customer Fail',
  DELETE_CUSTOMER_SUCCESS = '[Sales] [Customers] Delete Customer Success',

  CANCEL_CUSTOMER_LOG = '[Sales] [Customers] Cancel Customer Log',
  CANCEL_CUSTOMER_LOG_FAIL = '[Sales] [Customers] Cancel Customer Log Fail',
  CANCEL_CUSTOMER_LOG_SUCCESS = '[Sales] [Customers] Cancel Customer Log Success',
}

/**
 * Represents a store customers search action.
 */
export class SearchCustomers implements Action {
  /**
   * The type of the action.
   */
  readonly type = CustomersActionType.SEARCH_CUSTOMERS;

  /**
   * Fires a new customers search action.
   * @param payload the search parameters,
   * if omitted, all customers will be loaded.
   */
  constructor(public payload?: { name: string; page: number }) {}
}

/**
 * Represents a store customers search-fail action.
 */
export class SearchCustomersFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = CustomersActionType.SEARCH_CUSTOMERS_FAIL;

  /**
   * Fires a new customers search-fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store search-success action.
 */
export class SearchCustomersSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = CustomersActionType.SEARCH_CUSTOMERS_SUCCESS;

  /**
   * Fires a new customers search-success action.
   * @param payload An object contains the list of customers that matches the search criteria
   * in addition to the pagination info.
   */
  constructor(public payload: AppHttpResponse<Customer[]>) {}
}

/**
 * Represents a store customer logs search action.
 */
export class SearchCustomerLogs implements Action {
  /**
   * The type of the action.
   */
  readonly type = CustomersActionType.SEARCH_CUSTOMER_LOGS;

  /**
   * Fires a new customer logs search action.
   * @param payload the search parameters,
   * if omitted, all customer logs will be loaded.
   */
  constructor(
    public payload?: {
      customerId: number;
      fromDate: Date;
      toDate: Date;
      page: number;
    }
  ) {}
}

/**
 * Represents a store customer logs search-fail action.
 */
export class SearchCustomerLogsFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = CustomersActionType.SEARCH_CUSTOMER_LOGS_FAIL;

  /**
   * Fires a new customer logs search-fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store search-success action.
 */
export class SearchCustomerLogsSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = CustomersActionType.SEARCH_CUSTOMER_LOGS_SUCCESS;

  /**
   * Fires a new customer logs search-success action.
   * @param payload An object contains the list of customer logs that matches the search criteria
   * in addition to the pagination info.
   */
  constructor(public payload: AppHttpResponse<{ logs: CustomerLog[]; customer: Customer }>) {}
}

/**
 * Represents a store customers payments search action.
 */
export class SearchCustomersPayments implements Action {
  /**
   * The type of the action.
   */
  readonly type = CustomersActionType.SEARCH_CUSTOMERS_PAYMENTS;

  /**
   * Fires a new customers payments search action.
   * @param payload the search parameters,
   * if omitted, all customer payments will be loaded.
   */
  constructor(
    public payload?: {
      customers: number[];
      customerLogTypes: number[];
      fromDate: Date;
      toDate: Date;
      page: number;
    }
  ) {}
}

/**
 * Represents a store customers payments search-fail action.
 */
export class SearchCustomersPaymentsFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = CustomersActionType.SEARCH_CUSTOMERS_PAYMENTS_FAIL;

  /**
   * Fires a new customers payments search-fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store search-success action.
 */
export class SearchCustomersPaymentsSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = CustomersActionType.SEARCH_CUSTOMERS_PAYMENTS_SUCCESS;

  /**
   * Fires a new customers payments search-success action.
   * @param payload An object contains the list of customers payments that matches the search criteria
   * in addition to the pagination info.
   */
  constructor(public payload: AppHttpResponse<CustomerLog[]>) {}
}

/**
 * Represents a store customer advance payments search action.
 */
export class SearchCustomerAdvancePayments implements Action {
  /**
   * The type of the action.
   */
  readonly type = CustomersActionType.SEARCH_CUSTOMER_ADVANCE_PAYMENTS;

  /**
   * Fires a new customer advance payments search action.
   * @param payload the search parameters,
   * if omitted, all customer advance payments will be loaded.
   */
  constructor(
    public payload?: {
      customerId: number;
      fromDate: Date;
      toDate: Date;
      page: number;
    }
  ) {}
}

/**
 * Represents a store customer advance payments search-fail action.
 */
export class SearchCustomerAdvancePaymentsFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = CustomersActionType.SEARCH_CUSTOMER_ADVANCE_PAYMENTS_FAIL;

  /**
   * Fires a new customer advance payments search-fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store search-success action.
 */
export class SearchCustomerAdvancePaymentsSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = CustomersActionType.SEARCH_CUSTOMER_ADVANCE_PAYMENTS_SUCCESS;

  /**
   * Fires a new customer advance payments search-success action.
   * @param payload An object contains the list of customer advance payments that matches the search criteria
   * in addition to the pagination info.
   */
  constructor(public payload: AppHttpResponse<CustomerLog[]>) {}
}

/**
 * Represents a store find-customer action.
 */
export class FindCustomer implements Action {
  /**
   * The type of the action.
   */
  readonly type = CustomersActionType.FIND_CUSTOMER;

  /**
   * Fires a new find customer action.
   * @param payload the id of the customer.
   */
  constructor(public payload: number) {}
}

/**
 * Represents a store find-customer-fail action.
 */
export class FindCustomerFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = CustomersActionType.FIND_CUSTOMER_FAIL;

  /**
   * Fires a new find-customer-fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store find customer-success action.
 */
export class FindCustomerSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = CustomersActionType.FIND_CUSTOMER_SUCCESS;

  /**
   * Fires a new find-customer-success action.
   * @param payload The loaded customer.
   */
  constructor(public payload: AppHttpResponse<Customer>) {}
}

/**
 * Represents a store customer create action.
 */
export class CreateCustomer implements Action {
  /** The type of the action. */
  readonly type = CustomersActionType.CREATE_CUSTOMER;

  /**
   * Fires a new customer create action.
   * @param payload the new customer data-model.
   */
  constructor(public payload: CreateCustomerInput) {}
}

/**
 * Represents a store customer create-fail action.
 */
export class CreateCustomerFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = CustomersActionType.CREATE_CUSTOMER_FAIL;

  /**
   * Fires a new customer create-fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store customer create-success action.
 */
export class CreateCustomerSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = CustomersActionType.CREATE_CUSTOMER_SUCCESS;

  /**
   * Fires a new customer create-success action.
   * @param payload The created customer.
   */
  constructor(public payload: AppHttpResponse<Customer>) {}
}

/**
 * Represents a store customer payment create action.
 */
export class CreateCustomerPayment implements Action {
  /** The type of the action. */
  readonly type = CustomersActionType.CREATE_CUSTOMER_PAYMENTS;

  /**
   * Fires a new customerPayment create action.
   * @param payload the new customerPayment data-model.
   */
  constructor(public payload: CreateCustomerPaymentsInput) {}
}

/**
 * Represents a store customer payment create-fail action.
 */
export class CreateCustomerPaymentFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = CustomersActionType.CREATE_CUSTOMER_PAYMENTS_FAIL;

  /**
   * Fires a new customerPayment create-fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store customer payment create-success action.
 */
export class CreateCustomerPaymentSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = CustomersActionType.CREATE_CUSTOMER_PAYMENTS_SUCCESS;

  /**
   * Fires a new customerPayment create-success action.
   * @param payload The created customerPayment.
   */
  constructor(public payload: AppHttpResponse<CustomerLog>) {}
}

/**
 * Represents a store customer Advance payment create action.
 */
export class CreateCustomerAdvancePayment implements Action {
  /** The type of the action. */
  readonly type = CustomersActionType.CREATE_CUSTOMER_ADVANCE_PAYMENT;

  /**
   * Fires a new customerAdvancePayment create action.
   * @param payload the new customerAdvancePayment data-model.
   */
  constructor(public payload: CreateCustomerAdvancePaymentInput) {}
}

/**
 * Represents a store customer payment create-fail action.
 */
export class CreateCustomerAdvancePaymentFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = CustomersActionType.CREATE_CUSTOMER_ADVANCE_PAYMENT_FAIL;

  /**
   * Fires a new customerAdvancePayment create-fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store customer Advance payment create-success action.
 */
export class CreateCustomerAdvancePaymentSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = CustomersActionType.CREATE_CUSTOMER_ADVANCE_PAYMENT_SUCCESS;

  /**
   * Fires a new customerAdvancePayment create-success action.
   * @param payload The created customerAdvancePayment.
   */
  constructor(public payload: AppHttpResponse<CustomerLog>) {}
}

/**
 * Represents a store customer Advance payment create action.
 */
export class CreateCustomerPaymentByAdvancePayment implements Action {
  /** The type of the action. */
  readonly type = CustomersActionType.CREATE_CUSTOMER_PAYMENT_BY_ADVANCE_PAYMENT;

  /**
   * Fires a new customerAdvancePayment create action.
   * @param payload the new customerAdvancePayment data-model.
   */
  constructor(public payload: CreateCustomerPaymentByAdvancePaymentInput) {}
}

/**
 * Represents a store customer payment create-fail action.
 */
export class CreateCustomerPaymentByAdvancePaymentFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = CustomersActionType.CREATE_CUSTOMER_PAYMENT_BY_ADVANCE_PAYMENT_FAIL;

  /**
   * Fires a new customerAdvancePayment create-fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store customer Advance payment create-success action.
 */
export class CreateCustomerPaymentByAdvancePaymentSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = CustomersActionType.CREATE_CUSTOMER_PAYMENT_BY_ADVANCE_PAYMENT_SUCCESS;

  /**
   * Fires a new customerAdvancePayment create-success action.
   * @param payload The created customerAdvancePayment.
   */
  constructor(public payload: AppHttpResponse<CustomerLog>) {}
}

/**
 * Represents a store customer refund create action.
 */
export class CreateCustomerRefund implements Action {
  /** The type of the action. */
  readonly type = CustomersActionType.CREATE_CUSTOMER_REFUND;

  /**
   * Fires a new customer refund create action.
   * @param payload the new customer refund data-model.
   */
  constructor(public payload: CreateCustomerRefundInput) {}
}

/**
 * Represents a store customer refund create-fail action.
 */
export class CreateCustomerRefundFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = CustomersActionType.CREATE_CUSTOMER_REFUND_FAIL;

  /**
   * Fires a new customer refund create-fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store customer refund create-success action.
 */
export class CreateCustomerRefundSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = CustomersActionType.CREATE_CUSTOMER_REFUND_SUCCESS;

  /**
   * Fires a new customer refund create-success action.
   * @param payload The created customer refund.
   */
  constructor(public payload: AppHttpResponse<CustomerLog>) {}
}

/**
 * Represents a store customer update action.
 */
export class UpdateCustomer implements Action {
  /**
   * The type of the action.
   */
  readonly type = CustomersActionType.UPDATE_CUSTOMER;

  /**
   * Fires a new customer update action.
   * @param payload The customer's updated data.
   */
  constructor(public payload: UpdateCustomerInput) {}
}

/**
 * Represents a store customer update-fail action.
 */
export class UpdateCustomerFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = CustomersActionType.UPDATE_CUSTOMER_FAIL;

  /**
   * Fires a new customer update-fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store customer update-success action.
 */
export class UpdateCustomerSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = CustomersActionType.UPDATE_CUSTOMER_SUCCESS;

  /**
   * Fires a new customer update-success action.
   * @param payload The updated customer.
   */
  constructor(public payload: AppHttpResponse<Customer>) {}
}

/**
 * Represents a store customer block action.
 */
export class BlockCustomer implements Action {
  /**
   * The type of the action.
   */
  readonly type = CustomersActionType.BLOCK_CUSTOMER;

  /**
   * Fires a new customer block action.
   * @param payload The customer's id.
   */
  constructor(public payload: number) {}
}

/**
 * Represents a store customer block-fail action.
 */
export class BlockCustomerFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = CustomersActionType.BLOCK_CUSTOMER_FAIL;

  /**
   * Fires a new customer block-fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store customer block-success action.
 */
export class BlockCustomerSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = CustomersActionType.BLOCK_CUSTOMER_SUCCESS;

  /**
   * Fires a new customer block-success action.
   * @param payload The updated customer.
   */
  constructor(public payload: AppHttpResponse<Customer>) {}
}

/**
 * Represents a store customer activate action.
 */
export class ActivateCustomer implements Action {
  /**
   * The type of the action.
   */
  readonly type = CustomersActionType.ACTIVATE_CUSTOMER;

  /**
   * Fires a new customer activate action.
   * @param payload The customer's id.
   */
  constructor(public payload: number) {}
}

/**
 * Represents a store customer activate-fail action.
 */
export class ActivateCustomerFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = CustomersActionType.ACTIVATE_CUSTOMER_FAIL;

  /**
   * Fires a new customer activate-fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store customer activate-success action.
 */
export class ActivateCustomerSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = CustomersActionType.ACTIVATE_CUSTOMER_SUCCESS;

  /**
   * Fires a new customer activate-success action.
   * @param payload The updated customer.
   */
  constructor(public payload: AppHttpResponse<Customer>) {}
}

/**
 * Represents a store customer delete action.
 */
export class DeleteCustomer implements Action {
  /**
   * The type of the action.
   */
  readonly type = CustomersActionType.DELETE_CUSTOMER;

  /**
   * Fires a new customer delete action.
   * @param payload The customer's id.
   */
  constructor(public payload: number) {}
}

/**
 * Represents a store customer delete-fail action.
 */
export class DeleteCustomerFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = CustomersActionType.DELETE_CUSTOMER_FAIL;

  /**
   * Fires a new customer delete-fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store customer delete-success action.
 */
export class DeleteCustomerSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = CustomersActionType.DELETE_CUSTOMER_SUCCESS;

  /**
   * Fires a new customer delete-success action.
   * @param payload The deleted customer.
   */
  constructor(public payload: AppHttpResponse<Customer>) {}
}

/**
 * Represents a store customer log cancel action.
 */
export class CancelCustomerLog implements Action {
  /**
   * The type of the action.
   */
  readonly type = CustomersActionType.CANCEL_CUSTOMER_LOG;

  /**
   * Fires a new customer log cancel action.
   * @param payload The customer's id.
   */
  constructor(public payload: number) {}
}

/**
 * Represents a store customer log cancel-fail action.
 */
export class CancelCustomerLogFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = CustomersActionType.CANCEL_CUSTOMER_LOG_FAIL;

  /**
   * Fires a new customer log cancel-fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store customer log cancel-success action.
 */
export class CancelCustomerLogSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = CustomersActionType.CANCEL_CUSTOMER_LOG_SUCCESS;

  /**
   * Fires a new customer log cancel-success action.
   * @param payload The canceled customer log.
   */
  constructor(public payload: AppHttpResponse<{ log: CustomerLog; cancelLog: CustomerLog; customer: Customer }>) {}
}

/**
 * Sales-module customers action types.
 */
export type CustomersActions =
  | SearchCustomers
  | SearchCustomersFail
  | SearchCustomersSuccess
  | SearchCustomerLogs
  | SearchCustomerLogsFail
  | SearchCustomerLogsSuccess
  | SearchCustomersPayments
  | SearchCustomersPaymentsFail
  | SearchCustomersPaymentsSuccess
  | SearchCustomerAdvancePayments
  | SearchCustomerAdvancePaymentsFail
  | SearchCustomerAdvancePaymentsSuccess
  | FindCustomer
  | FindCustomerFail
  | FindCustomerSuccess
  | CreateCustomer
  | CreateCustomerFail
  | CreateCustomerSuccess
  | CreateCustomerPayment
  | CreateCustomerPaymentFail
  | CreateCustomerPaymentSuccess
  | CreateCustomerAdvancePayment
  | CreateCustomerAdvancePaymentFail
  | CreateCustomerAdvancePaymentSuccess
  | CreateCustomerPaymentByAdvancePayment
  | CreateCustomerPaymentByAdvancePaymentFail
  | CreateCustomerPaymentByAdvancePaymentSuccess
  | CreateCustomerRefund
  | CreateCustomerRefundFail
  | CreateCustomerRefundSuccess
  | UpdateCustomer
  | UpdateCustomerFail
  | UpdateCustomerSuccess
  | BlockCustomer
  | BlockCustomerFail
  | BlockCustomerSuccess
  | ActivateCustomer
  | ActivateCustomerFail
  | ActivateCustomerSuccess
  | DeleteCustomer
  | DeleteCustomerFail
  | DeleteCustomerSuccess
  | CancelCustomerLog
  | CancelCustomerLogFail
  | CancelCustomerLogSuccess;
