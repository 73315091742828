import { Action } from '@ngrx/store';

import { OtherEarningType } from 'lookups/models';
import { AppHttpResponse } from 'shared';

/**
 * The Lookups-Module store other earning type action types.
 */
export enum OtherEarningTypeActionType {
  GET_ALL_OTHER_EARNING_TYPES = '[Lookups] [OtherEarningType] Get All Other Earning Types',
  GET_ALL_OTHER_EARNING_TYPES_FAIL = '[Lookups] [OtherEarningType] Get All Other Earning Types Fail',
  GET_ALL_OTHER_EARNING_TYPES_SUCCESS = '[Lookups] [OtherEarningType] Get All Other Earning Types Success',
}

/**
 * Represents a store other earning types action.
 */
export class GetAllOtherEarningTypes implements Action {
  /**
   * The type of the action.
   */
  readonly type = OtherEarningTypeActionType.GET_ALL_OTHER_EARNING_TYPES;

  /**
   * Fires a new other earning types action.
   */
  constructor() {}
}

/**
 * Represents a store other earning types fail action.
 */
export class GetAllOtherEarningTypesFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = OtherEarningTypeActionType.GET_ALL_OTHER_EARNING_TYPES_FAIL;

  /**
   * Fires a new other earning types fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store other earning types success action.
 */
export class GetAllOtherEarningTypesSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = OtherEarningTypeActionType.GET_ALL_OTHER_EARNING_TYPES_SUCCESS;

  /**
   * Fires a other earning types success action.
   * @param payload An object contains the list of other earning types.
   */
  constructor(public payload: AppHttpResponse<OtherEarningType[]>) {}
}

/**
 * Lookups-module other earning type action types.
 */
export type OtherEarningTypeActions =
  | GetAllOtherEarningTypes
  | GetAllOtherEarningTypesFail
  | GetAllOtherEarningTypesSuccess;
