import { Component, Input } from '@angular/core';

import { Decimal } from 'decimal.js';

import { SaleReturn, SaleReturnProduct } from 'sales/models';

@Component({
  selector: 'app-sale-return-details',
  templateUrl: './sale-return-details.component.html',
  styles: [],
})
export class SaleReturnsDetailComponent {
  /**
   * Sets the current viewed sale return.
   */
  @Input() saleReturn: SaleReturn;

  /**
   * Gets the total quantity of sale returns products.
   */
  productsQuantity(saleReturnProducts: SaleReturnProduct[]): number {
    return Decimal.sum(0, ...saleReturnProducts.map((product) => product.quantity ?? 0)).toNumber();
  }
}
