import { Action } from '@ngrx/store';

import { AppHttpResponse } from 'shared';
import {
  CurrencyExchangeRate,
  CreateCurrencyExchangeRateInput,
  UpdateCurrencyExchangeRateInput,
} from 'finances/models';

/**
 * The finances-module store currency exchange rate action types.
 */
export enum CurrencyExchangeRatesActionType {
  SEARCH_CURRENCY_EXCHANGE_RATES = '[Finances] [CurrencyExchangeRates] Search Currency Exchanges Rate',
  SEARCH_CURRENCY_EXCHANGE_RATES_FAIL = '[Finances] [CurrencyExchangeRates] Search Currency Exchanges Rate Fail',
  SEARCH_CURRENCY_EXCHANGE_RATES_SUCCESS = '[Finances] [CurrencyExchangeRates] Search Currency Exchanges Rate Success',

  FIND_CURRENCY_EXCHANGE_RATE = '[Finances] [CurrencyExchangeRates] Find Currency Exchange Rate',
  FIND_CURRENCY_EXCHANGE_RATE_FAIL = '[Finances] [CurrencyExchangeRates] Find Currency Exchange Rate Fail',
  FIND_CURRENCY_EXCHANGE_RATE_SUCCESS = '[Finances] [CurrencyExchangeRates] Find Currency Exchange Rate Success',

  CREATE_CURRENCY_EXCHANGE_RATE = '[Finances] [CurrencyExchangeRates] Create Currency Exchange Rate',
  CREATE_CURRENCY_EXCHANGE_RATE_FAIL = '[Finances] [CurrencyExchangeRates] Create Currency Exchange Rate Fail',
  CREATE_CURRENCY_EXCHANGE_RATE_SUCCESS = '[Finances] [CurrencyExchangeRates] Create Currency Exchange Rate Success',

  UPDATE_CURRENCY_EXCHANGE_RATE = '[Finances] [CurrencyExchangeRates] Update Currency Exchange Rate',
  UPDATE_CURRENCY_EXCHANGE_RATE_FAIL = '[Finances] [CurrencyExchangeRates] Update Currency Exchange Rate Fail',
  UPDATE_CURRENCY_EXCHANGE_RATE_SUCCESS = '[Finances] [CurrencyExchangeRates] Update Currency Exchange Rate Success',

  DELETE_CURRENCY_EXCHANGE_RATE = '[Finances] [CurrencyExchangeRates] Delete Currency Exchange Rate',
  DELETE_CURRENCY_EXCHANGE_RATE_FAIL = '[Finances] [CurrencyExchangeRates] Delete Currency Exchange Rate Fail',
  DELETE_CURRENCY_EXCHANGE_RATE_SUCCESS = '[Finances] [CurrencyExchangeRates] Delete Currency Exchange Rate Success',
}

/**
 * Represents a store currency exchange rates search action.
 */
export class SearchCurrencyExchangeRates implements Action {
  /**
   * The type of the action.
   */
  readonly type = CurrencyExchangeRatesActionType.SEARCH_CURRENCY_EXCHANGE_RATES;

  /**
   * Fires a new currency exchange rates search action.
   * @param payload the search parameters,
   * if omitted, all currency exchange rate will be loaded.
   */
  constructor(
    public payload: {
      primaryCurrencies: number[];
      secondaryCurrencies: number[];
      fromDate: Date;
      toDate: Date;
      page: number;
    }
  ) {}
}

/**
 * Represents a store currency exchange rates search fail action.
 */
export class SearchCurrencyExchangeRatesFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = CurrencyExchangeRatesActionType.SEARCH_CURRENCY_EXCHANGE_RATES_FAIL;

  /**
   * Fires a new currency exchange rates search fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store search success action.
 */
export class SearchCurrencyExchangeRatesSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = CurrencyExchangeRatesActionType.SEARCH_CURRENCY_EXCHANGE_RATES_SUCCESS;

  /**
   * Fires a new currency exchange rate search success action.
   * @param payload An object contains the list of currency exchange rate that matches the search criteria
   * in addition to the pagination info.
   */
  constructor(public payload: AppHttpResponse<CurrencyExchangeRate[]>) {}
}

/**
 * Represents a store find currency exchange rate action.
 */
export class FindCurrencyExchangeRate implements Action {
  /**
   * The type of the action.
   */
  readonly type = CurrencyExchangeRatesActionType.FIND_CURRENCY_EXCHANGE_RATE;

  /**
   * Fires a new find currency exchange rate action.
   * @param payload the id of the currency exchange rate.
   */
  constructor(public payload: number) {}
}

/**
 * Represents a store find currency exchange rate fail action.
 */
export class FindCurrencyExchangeRateFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = CurrencyExchangeRatesActionType.FIND_CURRENCY_EXCHANGE_RATE_FAIL;

  /**
   * Fires a new find currency exchange rate fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store find currency exchange rate success action.
 */
export class FindCurrencyExchangeRateSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = CurrencyExchangeRatesActionType.FIND_CURRENCY_EXCHANGE_RATE_SUCCESS;

  /**
   * Fires a new find currency exchange rate success action.
   * @param payload The loaded currency.
   */
  constructor(public payload: AppHttpResponse<CurrencyExchangeRate>) {}
}

/**
 * Represents a store currency exchange rate create action.
 */
export class CreateCurrencyExchangeRate implements Action {
  /** The type of the action. */
  readonly type = CurrencyExchangeRatesActionType.CREATE_CURRENCY_EXCHANGE_RATE;

  /**
   * Fires a new currency exchange rate create action.
   * @param payload the new currency exchange rate data-model.
   */
  constructor(public payload: CreateCurrencyExchangeRateInput) {}
}

/**
 * Represents a store currency exchange rate create fail action.
 */
export class CreateCurrencyExchangeRateFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = CurrencyExchangeRatesActionType.CREATE_CURRENCY_EXCHANGE_RATE_FAIL;

  /**
   * Fires a new currency exchange rate create fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store currency exchange rate create success action.
 */
export class CreateCurrencyExchangeRateSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = CurrencyExchangeRatesActionType.CREATE_CURRENCY_EXCHANGE_RATE_SUCCESS;

  /**
   * Fires a new currency exchange rate create success action.
   * @param payload The created currency exchange rate.
   */
  constructor(public payload: AppHttpResponse<CurrencyExchangeRate>) {}
}

/**
 * Represents a store currency exchange rate update action.
 */
export class UpdateCurrencyExchangeRate implements Action {
  /**
   * The type of the action.
   */
  readonly type = CurrencyExchangeRatesActionType.UPDATE_CURRENCY_EXCHANGE_RATE;

  /**
   * Fires a new currency exchange rate update action.
   * @param payload The currency's exchange rate updated data.
   */
  constructor(public payload: UpdateCurrencyExchangeRateInput) {}
}

/**
 * Represents a store currency exchange rate update fail action.
 */
export class UpdateCurrencyExchangeRateFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = CurrencyExchangeRatesActionType.UPDATE_CURRENCY_EXCHANGE_RATE_FAIL;

  /**
   * Fires a new currency exchange rate update fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store currency exchange rate update success action.
 */
export class UpdateCurrencyExchangeRateSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = CurrencyExchangeRatesActionType.UPDATE_CURRENCY_EXCHANGE_RATE_SUCCESS;

  /**
   * Fires a new currency exchange rate update-success action.
   * @param payload The updated currency exchange rate.
   */
  constructor(public payload: AppHttpResponse<CurrencyExchangeRate>) {}
}

/**
 * Represents a store currency exchange rate delete action.
 */
export class DeleteCurrencyExchangeRate implements Action {
  /**
   * The type of the action.
   */
  readonly type = CurrencyExchangeRatesActionType.DELETE_CURRENCY_EXCHANGE_RATE;

  /**
   * Fires a new currency exchange rate delete action.
   * @param payload The currency's exchange rate id.
   */
  constructor(public payload: number) {}
}

/**
 * Represents a store currency exchange rate delete fail action.
 */
export class DeleteCurrencyExchangeRateFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = CurrencyExchangeRatesActionType.DELETE_CURRENCY_EXCHANGE_RATE_FAIL;

  /**
   * Fires a new currency exchange rate delete fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store currency exchange rate delete success action.
 */
export class DeleteCurrencyExchangeRateSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = CurrencyExchangeRatesActionType.DELETE_CURRENCY_EXCHANGE_RATE_SUCCESS;

  /**
   * Fires a new currency exchange rate delete success action.
   * @param payload The deleted currency exchange rate.
   */
  constructor(public payload: AppHttpResponse<CurrencyExchangeRate>) {}
}

/**
 * finances-module currency exchange rate action types.
 */
export type CurrencyExchangeRatesActions =
  | SearchCurrencyExchangeRates
  | SearchCurrencyExchangeRatesFail
  | SearchCurrencyExchangeRatesSuccess
  | FindCurrencyExchangeRate
  | FindCurrencyExchangeRateFail
  | FindCurrencyExchangeRateSuccess
  | CreateCurrencyExchangeRate
  | CreateCurrencyExchangeRateFail
  | CreateCurrencyExchangeRateSuccess
  | UpdateCurrencyExchangeRate
  | UpdateCurrencyExchangeRateFail
  | UpdateCurrencyExchangeRateSuccess
  | DeleteCurrencyExchangeRate
  | DeleteCurrencyExchangeRateFail
  | DeleteCurrencyExchangeRateSuccess;
