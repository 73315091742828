import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';

import { AppHttpResponse } from 'shared';
import { BankAccount } from 'finances/models';

/**
 * The bank account services includes functionality to search for a bank account.
 */
@Injectable()
export class BankAccountsService {
  /**
   * The relative route for the banks.
   *
   * No leading or trailing slashes required.
   */
  private bankAccountsApi = 'finances/banks/accounts';
  constructor(private http: HttpClient) {}

  /**
   * Searches in the bank accounts by name ,accountNumber ,iban and banks.
   * @param name The name of the bank account.
   * @param banks The banks of the account.
   * @param page The current pagination page number.
   * @param pageSize The maximum number of bank accounts allowed per one pagination page.
   */
  public search(
    name: string,
    banks: number[],
    page: number,
    pageSize: number
  ): Observable<AppHttpResponse<BankAccount[]>> {
    const params = new HttpParams()
      .set('name', name)
      .set('banks', banks.join(','))
      .set('page', page.toString())
      .set('pageSize', pageSize.toString());
    return this.http.get<AppHttpResponse<BankAccount[]>>(`${this.bankAccountsApi}`, { params });
  }
}
