import { CustomersEffects } from 'sales/store/effects/customers.effects';
import { CustomerEngagementsEffects } from 'sales/store/effects/customer-engagements.effects';
import { CustomerAssetsEffects } from 'sales/store/effects/customer-assets.effects';
import { SalesInvoicesEffects } from 'sales/store/effects/sales-invoices.effects';
import { SaleReturnsEffects } from 'sales/store/effects/sale-returns.effects';
import { ShiftsEffects } from 'sales/store/effects/shifts.effects';
import { PendingPosInvoicesEffects } from 'sales/store/effects/pending-pos-invoices.effects';
import { OrdersDeliveryEffects } from 'sales/store/effects/orders-delivery.effects';
import { BoardsEffects } from 'sales/store/effects/boards.effects';
import { PosDevicesEffects } from 'sales/store/effects/pos-devices.effects';
import { PosPrintersEffects } from 'sales/store/effects/pos-printers.effects';
import { PosPrinterAdjustmentsEffects } from 'sales/store/effects/pos-printer-adjustment.effects';

/**
 * The Sales-Module store effects.
 */
export const effects: any[] = [
  CustomersEffects,
  CustomerEngagementsEffects,
  CustomerAssetsEffects,
  SalesInvoicesEffects,
  SaleReturnsEffects,
  ShiftsEffects,
  PendingPosInvoicesEffects,
  OrdersDeliveryEffects,
  BoardsEffects,
  PosDevicesEffects,
  PosPrintersEffects,
  PosPrinterAdjustmentsEffects,
];
