import { Action } from '@ngrx/store';

import { GovServiceRequestStatus } from 'lookups/models';
import { AppHttpResponse } from 'shared';

/**
 * The Lookups-Module store gov service request statuses action types.
 */
export enum GovServiceRequestStatusActionType {
  GET_ALL_GOV_SERVICE_REQUEST_STATUSES = '[Lookups] [GovServiceRequestStatus] Get All Gov Service Request Statuses',
  GET_ALL_GOV_SERVICE_REQUEST_STATUSES_FAIL = '[Lookups] [GovServiceRequestStatus] Get All Gov Service Request Statuses Fail',
  GET_ALL_GOV_SERVICE_REQUEST_STATUSES_SUCCESS = '[Lookups] [GovServiceRequestStatus] Get All Gov Service Request Statuses Success',
}

/**
 * Represents a store gov service request statuses action.
 */
export class GetAllGovServiceRequestStatuses implements Action {
  /**
   * The type of the action.
   */
  readonly type = GovServiceRequestStatusActionType.GET_ALL_GOV_SERVICE_REQUEST_STATUSES;

  /**
   * Fires a new gov service request statuses action.
   */
  constructor() {}
}

/**
 * Represents a store gov service request statuses fail action.
 */
export class GetAllGovServiceRequestStatusesFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = GovServiceRequestStatusActionType.GET_ALL_GOV_SERVICE_REQUEST_STATUSES_FAIL;

  /**
   * Fires a new gov service request statuses fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store gov service request statuses success action.
 */
export class GetAllGovServiceRequestStatusesSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = GovServiceRequestStatusActionType.GET_ALL_GOV_SERVICE_REQUEST_STATUSES_SUCCESS;

  /**
   * Fires a gov service request statuses success action.
   * @param payload An object contains the list of gov service request statuses.
   */
  constructor(public payload: AppHttpResponse<GovServiceRequestStatus[]>) {}
}

/**
 * Lookups-module gov service request statuses action types.
 */
export type GovServiceRequestStatusActions =
  | GetAllGovServiceRequestStatuses
  | GetAllGovServiceRequestStatusesFail
  | GetAllGovServiceRequestStatusesSuccess;
