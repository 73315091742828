import { ActionReducerMap } from '@ngrx/store';

import { customersReducer } from 'sales/store/reducers/customers.reducer';
import { SalesState } from 'sales/store/states';
import { saleInvoicesReducer } from 'sales/store/reducers/sales-invoices.reducer';
import { customerEngagementsReducer } from 'sales/store/reducers/customer-engagements.reducer';
import { customerAssetsReducer } from 'sales/store/reducers/customer-assets.reducer';
import { saleReturnsReducer } from 'sales/store/reducers/sale-returns.reducer';
import { parkedSaleInvoicesReducer } from 'sales/store/reducers/parked-sale-invoices.reducer';
import { shiftsReducer } from 'sales/store/reducers/shifts.reducer';
import { pendingPosInvoicesReducer } from 'sales/store/reducers/pending-pos-invoices.reducer';
import { ordersDeliveryReducer } from 'sales/store/reducers/orders-delivery.reducer';
import { boardsReducer } from 'sales/store/reducers/boards.reducer';
import { PosDevicesReducer } from 'sales/store/reducers/pos-devices.reducer';
import { PosPrintersReducer } from 'sales/store/reducers/pos-printers.reducer';
import { PosPrinterAdjustmentsReducer } from 'sales/store/reducers/pos-printer-adjustments.reducer';

/**
 * The Sales-Module store reducers.
 */
export const reducers: ActionReducerMap<SalesState> = {
  customers: customersReducer,
  customerEngagements: customerEngagementsReducer,
  customerAssets: customerAssetsReducer,
  saleInvoices: saleInvoicesReducer,
  saleReturns: saleReturnsReducer,
  parkedSaleInvoices: parkedSaleInvoicesReducer,
  shifts: shiftsReducer,
  pendingPosInvoices: pendingPosInvoicesReducer,
  ordersDelivery: ordersDeliveryReducer,
  boards: boardsReducer,
  posDevices: PosDevicesReducer,
  posPrinters: PosPrintersReducer,
  posPrinterAdjustments: PosPrinterAdjustmentsReducer,
};
