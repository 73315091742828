import { Component, ElementRef, ViewChild, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';

import { select, Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Decimal } from 'decimal.js';

import {
  CustomValidators,
  DateUtil,
  NotificationMessage,
  NotificationService,
  PageInfo,
  TranslationService,
} from 'shared';
import * as fromFinancesStore from 'finances/store';
import * as fromLookupsStore from 'lookups/store';
import * as fromSettingsStore from 'settings/store';
import {
  PettyCashRefundRequest,
  PettyCashRefundRequestLine,
  PettyCashRefundRequestLineAttachment,
  PettyCashRefundRequestLineFormItem,
  UpdatePettyCashRefundRequestLineInput,
} from 'finances/models';
import { PettyCashRefundRequestType, PettyCashRefundRequestTypes } from 'lookups/models';
import { Tax } from 'settings/models';
import { Claims } from 'security/models';

@Component({
  selector: 'app-update-petty-cash-refund-request',
  templateUrl: './update-petty-cash-refund-request.component.html',
  styles: [],
})
export class UpdatePettyCashRefundRequestComponent implements OnInit {
  /**
   * The confirm view attachments modal template reference.
   */
  @ViewChild('viewAttachmentsModalRef') viewAttachmentsModalRef: ElementRef<any>;

  /**
   * The confirm modal template reference.
   */
  @ViewChild('confirmModalRef') confirmModalRef: ElementRef<any>;

  /**
   * Gets or sets the information about the current page.
   */
  pageInfo: PageInfo = {
    title: 'FINANCES.PETTY_CASH_REFUND_REQUESTS.UPDATE_PETTY_CASH_REFUND_REQUEST_DATA',
    icon: 'fa fa-edit',
  };

  /**
   * The system supported user claims.
   */
  Claims = Claims;

  /**
   * The list of types.
   */
  types$: Observable<PettyCashRefundRequestType[]>;

  /**
   * Gets or sets the id of the debit notification refund request type.
   */
  debitNotificationPettyCashRefundRequestTypeId: number;

  /**
   * The list of taxes.
   */
  taxes$: Observable<Tax[]>;

  /**
   * The default tax on the system.
   */
  defaultTax: Observable<Tax>;

  /**
   * Sets the request id.
   */
  requestId: number;

  /**
   * The lines total of petty cash refund requests.
   */
  linesTotal: number;

  /**
   * The lines tax of petty cash refund requests.
   */
  linesTax: number;

  /**
   * The current viewed request line.
   */
  request$: Observable<PettyCashRefundRequest>;

  /**
   * Get selected petty cash refund requests approval.
   */
  selectedLine: PettyCashRefundRequestLine;

  /**
   * Indicates whether there is a update-petty-cash-refund-request process is running or not.
   */
  isUpdating$: Observable<boolean>;

  /**
   * Used to add new petty cash requests form items to the petty cash requests form.
   */
  newPettyCashRefundRequests: PettyCashRefundRequestLineFormItem[];

  /**
   * The set of subscriptions on this components,
   * these subscriptions must be unsubscribed before this component got destroyed.
   */
  subscriptions = new Subscription();

  /**
   * The create petty cash refund request form.
   */
  form: FormGroup;

  /**
   * Gets the lines form-array.
   */
  get linesForm(): FormArray {
    return this.form?.controls.lines as FormArray;
  }

  /**
   * @param modalService The modal service.
   * @param route The activated route.
   * @param financesStore$ The finances store.
   * @param translationService The translation service.
   * @param notificationService The notification service.
   * @param lookupsStore$ The lookups store.
   * @param settingsStore$ The settings store.
   */
  constructor(
    private modalService: NgbModal,
    private route: ActivatedRoute,
    private financesStore$: Store<fromFinancesStore.FinancesState>,
    private translationService: TranslationService,
    private notificationService: NotificationService,
    private lookupsStore$: Store<fromLookupsStore.LookupsState>,
    private settingsStore$: Store<fromSettingsStore.SettingsState>
  ) {}

  ngOnInit(): void {
    this.init();
  }

  /**
   * Destroy component data
   */
  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  /**
   * Initialize component data.
   */
  init() {
    this.initForm();

    /**
     * Load data.
     */
    this.isUpdating$ = this.financesStore$.pipe(select(fromFinancesStore.getSelectedPettyCashRefundRequestUpdating));

    /* Load the selected request line. */
    this.requestId = +this.route.snapshot.params.requestId;

    /* Load the selected request line. */
    this.request$ = this.financesStore$.pipe(
      select(fromFinancesStore.getSelectedPettyCashRefundRequest),
      tap((request) => {
        if (request) {
          /* Update page info. */
          this.pageInfo = {
            ...this.pageInfo,
            subTitle: this.translationService.translate(
              'FINANCES.PETTY_CASH_REFUND_REQUESTS.PETTY_CASH_REFUND_REQUEST_DATA.REQUEST_NUMBER',
              {
                requestNumber: request.id,
              }
            ),
          };

          this.form.patchValue({
            ...request,
            transactionDate: DateUtil.toDatePickerDate(request.transactionDate),
          });
          this.linesForm.clear();

          this.newPettyCashRefundRequests = request.pettyCashRefundRequestLines.map(
            (pettyCashLine: PettyCashRefundRequestLine) => ({
              description: pettyCashLine.description,
              pettyCashRefundRequestTypeId: pettyCashLine.pettyCashRefundRequestTypeId,
              pettyCashRefundRequestType: pettyCashLine.pettyCashRefundRequestType,
              vendorName: pettyCashLine.vendorName,
              value: pettyCashLine.value,
              tax: pettyCashLine.tax,
              invoiceDate: DateUtil.toDatePickerDate(pettyCashLine.invoiceDate),
            })
          );
        }
      })
    );

    this.types$ = this.lookupsStore$.pipe(
      select(fromLookupsStore.getPettyCashRefundRequestTypes),
      tap((types) => {
        this.debitNotificationPettyCashRefundRequestTypeId =
          types?.find((type) => type.key === PettyCashRefundRequestTypes.DEBIT_NOTIFICATION)?.id ?? 0;
      })
    );

    this.taxes$ = this.settingsStore$.pipe(select(fromSettingsStore.getTaxes));
    this.defaultTax = this.settingsStore$.pipe(select(fromSettingsStore.getDefaultTax));

    /** Load data. */
    this.lookupsStore$.dispatch(new fromLookupsStore.GetAllPettyCashRefundRequestType());
    this.settingsStore$.dispatch(new fromSettingsStore.SearchTaxes({ name: '', page: 1 }));
    this.settingsStore$.dispatch(new fromSettingsStore.FindDefaultTax());

    this.financesStore$.dispatch(new fromFinancesStore.FindPettyCashRefundRequest(this.requestId));
  }

  /**
   * Initialize form and add validators.
   */
  initForm() {
    const linesForm = new FormArray([], CustomValidators.arrayItems(1));

    this.form = new FormGroup({
      pettyCashId: new FormControl(null, Validators.required),
      notes: new FormControl('', [Validators.minLength(0), Validators.maxLength(200)]),
      transactionDate: new FormControl(null),
      lines: linesForm,
    });
  }

  /**
   * Submits the form.
   */
  submit() {
    const errorMessage = new NotificationMessage();

    if (this.form.invalid) {
      if (this.form.get('pettyCashId').invalid) {
        errorMessage.title = this.translationService.translate(
          'FINANCES.PETTY_CASH_REFUND_REQUESTS.PETTY_CASH_REFUND_REQUEST_DATA_VALIDATION.PETTY_CASH_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate(
            'FINANCES.PETTY_CASH_REFUND_REQUESTS.PETTY_CASH_REFUND_REQUEST_DATA_VALIDATION.PETTY_CASH_IS_REQUIRED'
          ),
        ];
      } else if (this.form.get('notes').invalid) {
        errorMessage.title = this.translationService.translate(
          'FINANCES.PETTY_CASH_REFUND_REQUESTS.PETTY_CASH_REFUND_REQUEST_DATA_VALIDATION.NOTES_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate(
            'FINANCES.PETTY_CASH_REFUND_REQUESTS.PETTY_CASH_REFUND_REQUEST_DATA_VALIDATION.NOTES_IS_REQUIRED'
          ),
        ];
      } else if (this.form.get('lines').invalid) {
        /**
         * Check if lines count = 0.
         */
        if (!this.linesForm.controls.length) {
          errorMessage.title = this.translationService.translate(
            'FINANCES.PETTY_CASH_REFUND_REQUESTS.PETTY_CASH_REFUND_REQUEST_DATA_VALIDATION.PETTY_CASH_REFUND_REQUESTS_ERROR'
          );
          errorMessage.body = [
            this.translationService.translate(
              'FINANCES.PETTY_CASH_REFUND_REQUESTS.PETTY_CASH_REFUND_REQUEST_DATA_VALIDATION.PETTY_CASH_REFUND_REQUESTS_LENGTH_ERROR'
            ),
          ];
        } else {
          /**
           * Check if some of lines has errors.
           */
          for (let index = 0; index < this.linesForm.controls.length; index++) {
            const line = this.linesForm.controls[index];

            if (line.valid) {
              continue;
            }

            errorMessage.title = this.translationService.translate(
              'FINANCES.PETTY_CASH_REFUND_REQUESTS.PETTY_CASH_REFUND_REQUEST_DATA_VALIDATION.REQUEST_NUMBER_ERROR',
              {
                requestNumber: index + 1,
              }
            );
            errorMessage.body = [];

            if (line.get('description').invalid) {
              errorMessage.body.push(
                this.translationService.translate(
                  'FINANCES.PETTY_CASH_REFUND_REQUESTS.PETTY_CASH_REFUND_REQUEST_DATA_VALIDATION.DESCRIPTION_IS_REQUIRED'
                )
              );
            }

            if (line.get('pettyCashRefundRequestTypeId').invalid) {
              errorMessage.body.push(
                this.translationService.translate(
                  'FINANCES.PETTY_CASH_REFUND_REQUESTS.PETTY_CASH_REFUND_REQUEST_DATA_VALIDATION.PETTY_CASH_REFUND_REQUEST_TYPE_IS_REQUIRED'
                )
              );
            }

            if (line.get('vendorName').invalid) {
              errorMessage.body.push(
                this.translationService.translate(
                  'FINANCES.PETTY_CASH_REFUND_REQUESTS.PETTY_CASH_REFUND_REQUEST_DATA_VALIDATION.VENDOR_IS_REQUIRED'
                )
              );
            }

            if (line.get('value').invalid) {
              errorMessage.body.push(
                this.translationService.translate(
                  'FINANCES.PETTY_CASH_REFUND_REQUESTS.PETTY_CASH_REFUND_REQUEST_DATA_VALIDATION.VALUE_LENGTH_ERROR'
                )
              );
            }

            if (line.get('tax').invalid) {
              errorMessage.body.push(
                this.translationService.translate(
                  'FINANCES.PETTY_CASH_REFUND_REQUESTS.PETTY_CASH_REFUND_REQUEST_DATA_VALIDATION.TAX_IS_REQUIRED'
                )
              );
            }

            if (line.get('invoiceDate').invalid) {
              errorMessage.body.push(
                this.translationService.translate(
                  'FINANCES.PETTY_CASH_REFUND_REQUESTS.PETTY_CASH_REFUND_REQUEST_DATA_VALIDATION.INVOICE_DATE_IS_REQUIRED'
                )
              );
            }

            break;
          }
        }
      }

      this.form.markAllAsTouched();
      return this.notificationService.warningWithTitle(errorMessage);
    }

    this.openModal(this.confirmModalRef);
  }

  /**
   * Confirms the form submit.
   */
  confirm() {
    let transactionDateFormatted;
    const { transactionDate } = this.form.value;

    if (transactionDate) {
      transactionDateFormatted = new Date(transactionDate.year, transactionDate.month - 1, transactionDate.day);
      transactionDateFormatted.setHours(new Date().getHours(), new Date().getMinutes());
    }

    /**
     * The list of lines in the lines form.
     */
    const lines: UpdatePettyCashRefundRequestLineInput[] = this.linesForm.value.map(
      (item: PettyCashRefundRequestLineFormItem) => {
        let invoiceDateFormatted;
        const invoiceDate = item.invoiceDate;

        if (invoiceDate) {
          invoiceDateFormatted = new Date(invoiceDate.year, invoiceDate.month - 1, invoiceDate.day);
          invoiceDateFormatted.setHours(new Date().getHours(), new Date().getMinutes());
        }

        const line: PettyCashRefundRequestLineFormItem = {
          description: item.description,
          pettyCashRefundRequestTypeId: item.pettyCashRefundRequestTypeId,
          value: item.value,
          vendorName: item.vendorName,
          tax: item.tax,
          invoiceDate: invoiceDateFormatted ? invoiceDateFormatted : null,
          attachments: item.attachments.filter((file) => !!file),
        };
        return line;
      }
    );

    this.financesStore$.dispatch(
      new fromFinancesStore.UpdatePettyCashRefundRequest({
        pettyCashRefundRequestId: this.requestId,
        pettyCashId: this.form.value.pettyCashId,
        transactionDate: transactionDateFormatted ? transactionDateFormatted : null,
        notes: this.form.value.notes,
        lines,
      })
    );
  }

  /**
   * Gets the sum of selected request lines values.
   */
  get requestLinesTotal(): number {
    return Decimal.sum(0, ...this.linesForm?.controls.map((requestLine) => requestLine.value.value ?? 0)).toNumber();
  }

  /**
   * Gets the sum of selected request lines taxes.
   */
  get requestLinesTax(): number {
    return Decimal.sum(
      0,
      ...this.linesForm?.controls.map((requestLine) =>
        requestLine.value.value < 0 || requestLine.value.tax < 0 || requestLine.value.tax > 100
          ? 0
          : Decimal.div(requestLine.value.tax, 100).mul(requestLine.value.value).toNumber() ?? 0
      )
    ).toNumber();
  }

  /**
   * Gets net value of the request.
   */
  get requestTotal(): number {
    return Decimal.add(this.requestLinesTotal, this.requestLinesTax).toNumber();
  }

  /**
   * Maps and returns the set of petty cash refund request line attachments into a set of urls.
   * @param attachments The petty cash refund request line attachments to be mapped.
   */
  getAttachmentsUrls(attachments: PettyCashRefundRequestLineAttachment[]): string[] {
    return attachments ? attachments.map((attachment) => attachment.url) : [];
  }

  /**
   * Opens the modal of the given templateRef.
   * @param modalRef The modal templateRef to be opened.
   */
  openModal(modalRef) {
    this.modalService.open(modalRef);
  }
}
