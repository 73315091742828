import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms';

import { skip, tap } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { Subscription, Observable } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { PageInfo, NotificationService, NotificationMessage, TranslationService, CustomValidators } from 'shared';
import * as fromSalesStore from 'sales/store';
import * as fromLookupsStore from 'lookups/store';
import { PosPrinter } from 'sales/models';
import { ProductClass, ProductClassFormItem } from 'stores/models';
import { PosPrinterAdjustmentTypes } from 'lookups/models';

@Component({
  selector: 'app-create-kitchen-printer',
  templateUrl: './create-kitchen-printer.component.html',
  styles: [],
})
export class CreateKitchenPrinterComponent implements OnInit, OnDestroy {
  /**
   * The confirm modal template reference.
   */
  @ViewChild('confirmModalRef') confirmModalRef: ElementRef<any>;

  /**
   * Gets or sets the information about the current page.
   */
  pageInfo: PageInfo = {
    title: 'SALES.KITCHEN_PRINTERS.CREATE_KITCHEN_PRINTER_TITLE',
    icon: 'fa fa-plus',
  };

  /**
   * Indicates whether there is a create pos printer process is running or not.
   */
  isCreating$: Observable<boolean>;

  /**
   * The create pos printer form.
   */
  form: FormGroup;

  /**
   * Shows or hide the pos printers list.
   */
  posPrintersListVisibility = false;

  /**
   * The list of selected pos devices.
   */
  posPrinters: PosPrinter[] = [];

  /**
   * The list of product classes.
   */
  productClasses: ProductClass[] = [];

  /**
   * Gets or sets the id of the pos printer adjustment for `KITCHEN_PRINTER `.
   */
  posPrinterAdjustmentIdForKitchen: number;

  /**
   * The set of subscriptions on this components,
   * these subscriptions must be unsubscribed before this component got destroyed.
   */
  subscriptions = new Subscription();

  /**
   * Gets the product classes form-array.
   */
  get productClassesForm(): FormArray {
    return this.form?.controls.productClasses as FormArray;
  }

  /**
   * @param modalService The modal service.
   * @param notificationService The notification service.
   * @param salesStore$  The stores store.
   * @param lookupsStore$ the lookups-store module.
   * @param authStore$ the auth-store module.
   */
  constructor(
    private modalService: NgbModal,
    private notificationService: NotificationService,
    private salesStore$: Store<fromSalesStore.SalesState>,
    private lookupsStore$: Store<fromLookupsStore.LookupsState>,
    private translationService: TranslationService
  ) {}

  ngOnInit(): void {
    this.init();
  }

  /**
   * Destroy component data
   */
  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  /**
   * Initialize component data.
   */
  init() {
    this.initForm();

    let isManualSearchTriggeredBeforeForPosPrinterAdjustmentTypes = false;
    this.subscriptions.add(
      this.lookupsStore$
        .pipe(
          select(fromLookupsStore.getPosPrinterAdjustmentTypes),
          tap((posPrinterAdjustments) => {
            if (!isManualSearchTriggeredBeforeForPosPrinterAdjustmentTypes && !posPrinterAdjustments.length) {
              isManualSearchTriggeredBeforeForPosPrinterAdjustmentTypes = true;
              this.lookupsStore$.dispatch(new fromLookupsStore.GetAllPosPrinterAdjustmentTypes());
            } else if (posPrinterAdjustments?.length) {
              this.posPrinterAdjustmentIdForKitchen = posPrinterAdjustments?.find(
                (posPrinterAdjustment) => posPrinterAdjustment.key === PosPrinterAdjustmentTypes.KITCHEN_PRINTER
              )?.id;
            }
          })
        )
        .subscribe()
    );

    /**
     * Reset form controls when new kitchen printer is created.
     */
    this.subscriptions.add(
      this.salesStore$
        .pipe(
          select(fromSalesStore.getSelectedKitchenPrinterCreateCompleted),
          skip(1),
          tap((isCreated) => {
            if (isCreated) {
              this.initForm();
            }
          })
        )
        .subscribe()
    );

    /**
     * Load data.
     */
    this.isCreating$ = this.salesStore$.pipe(select(fromSalesStore.getSelectedKitchenPrinterCreating));
  }

  /**
   * Initialize form and add validators.
   */
  initForm() {
    this.form = new FormGroup({
      productClasses: new FormArray([], CustomValidators.arrayItems(1)),
      posPrinterId: new FormControl(null, [Validators.required]),
    });
  }

  /**
   * Submits the form.
   */
  submit() {
    if (this.form.invalid) {
      const errorMessage = new NotificationMessage();

      if (this.form.get('posPrinterId').invalid) {
        errorMessage.title = this.translationService.translate(
          'SALES.KITCHEN_PRINTERS.KITCHEN_PRINTER_DATA_VALIDATION.KITCHEN_PRINTER_ID_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate(
            'SALES.KITCHEN_PRINTERS.KITCHEN_PRINTER_DATA_VALIDATION.KITCHEN_PRINTER_ID_IS_REQUIRED'
          ),
        ];
      } else if (this.form.get('productClasses').invalid) {
        /**
         * Check if lines count = 0.
         */
        if (!this.productClassesForm.controls.length) {
          errorMessage.title = this.translationService.translate(
            'SALES.KITCHEN_PRINTERS.KITCHEN_PRINTER_DATA_VALIDATION.PRODUCT_CLASSES_ERROR'
          );
          errorMessage.body = [
            this.translationService.translate(
              'SALES.KITCHEN_PRINTERS.KITCHEN_PRINTER_DATA_VALIDATION.PRODUCT_CLASSES_LENGTH_ERROR'
            ),
          ];
        } else {
          /**
           * Check if some of productClasses has errors.
           */
          for (let index = 0; index < this.productClassesForm.controls.length; index++) {
            const productClass = this.productClassesForm.controls[index];

            if (productClass.valid) {
              continue;
            }

            errorMessage.title = this.translationService.translate(
              'SALES.KITCHEN_PRINTERS.KITCHEN_PRINTER_DATA_VALIDATION.PRODUCT_CLASSES_NUMBER_ERROR',
              {
                productClassNumber: index + 1,
              }
            );
            errorMessage.body = [];

            if (productClass.get('productClassId').invalid) {
              errorMessage.body.push(
                this.translationService.translate(
                  'SALES.KITCHEN_PRINTERS.KITCHEN_PRINTER_DATA_VALIDATION.PRODUCT_CLASSES_IS_REQUIRED'
                )
              );
            }
          }
        }
      }
      this.form.markAllAsTouched();
      return this.notificationService.warningWithTitle(errorMessage);
    } else {
      this.openModal(this.confirmModalRef);
    }
  }

  /**
   * Confirms the form submit.
   */
  confirm() {
    const productClasses = this.productClassesForm.value.map((item: ProductClassFormItem) => item.productClassId);

    this.salesStore$.dispatch(
      new fromSalesStore.CreateKitchenPrinter({
        ...this.form.value,
        productClasses,
        posPrinterAdjustmentTypeId: this.posPrinterAdjustmentIdForKitchen,
        orderTypes: [],
      })
    );
  }

  /**
   * Opens the modal of the given templateRef.
   * @param modalRef The modal templateRef to be opened.
   */
  openModal(modalRef) {
    this.modalService.open(modalRef);
  }

  /**
   * Selects the newly selected pos printer from the pos printer search list.
   * @param posPrinter The list of newly selected pos printer to select the only one in the list.
   */
  selectPosPrinter([posPrinter]: PosPrinter[]) {
    if (posPrinter) {
      this.posPrinters = [posPrinter];
      this.form.patchValue({ posPrinterId: posPrinter.id });
    }
  }
}
