<!-- page header starts -->
<app-page-header [info]="pageInfo"></app-page-header>
<!-- page header ends -->

<!-- petty cash reductions list starts -->
<div class="row">
  <div class="col-md-12">
    <div class="card">
      <div class="card-header mb-3">
        <h4 class="card-title">{{ 'SHARED.SEARCH.SEARCH_TOOLS' | translate }}</h4>
      </div>
      <div class="card-content">
        <div class="px-3">
          <div class="row">
            <div class="col-md-12">
              <form class="row" [formGroup]="searchForm" (ngSubmit)="search()">
                <div class="form-group col">
                  <label for="pettyCash">{{
                    'FINANCES.PETTY_CASH_REDUCTION.PETTY_CASH_REDUCTION_DATA.PETTY_CASH' | translate
                  }}</label>
                  <ng-select
                    class="round"
                    formControlName="pettyCash"
                    [multiple]="true"
                    [closeOnSelect]="false"
                    labelForId="pettyCash"
                    searchable="true"
                    [placeholder]="
                      'FINANCES.PETTY_CASH_REDUCTION.PETTY_CASH_REDUCTION_DATA.PETTY_CASH_PLACEHOLDER' | translate
                    "
                    [notFoundText]="'SHARED.SEARCH.SEARCH_NOT_FOUND' | translate"
                    (open)="pettyCashListVisibility = true"
                  >
                    <ng-option *ngFor="let petty of pettyCash" [value]="petty.id">
                      {{ petty.description }}
                    </ng-option>
                  </ng-select>
                </div>
                <div class="col">
                  <div class="row">
                    <div class="form-group col-12 col-sm-6 mb-0">
                      <label for="fromDate">{{ 'SHARED.SEARCH.FROM_DATE' | translate }}</label>
                      <div class="input-group round">
                        <input
                          class="form-control"
                          id="fromDate"
                          name="fromDate"
                          formControlName="fromDate"
                          [placeholder]="'SHARED.SEARCH.DATE_PLACEHOLDER' | translate"
                          ngbDatepicker
                          #fromDate="ngbDatepicker"
                        />
                        <div class="input-group-append">
                          <span
                            class="input-group-text gradient-light-blue-indigo"
                            style="cursor: pointer;"
                            (click)="fromDate.toggle()"
                          >
                            <i class="fa fa-calendar"></i>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="form-group col-12 col-sm-6 mb-0">
                      <label for="toDate">{{ 'SHARED.SEARCH.TO_DATE' | translate }}</label>
                      <div class="input-group round">
                        <input
                          class="form-control"
                          id="toDate"
                          name="toDate"
                          formControlName="toDate"
                          [placeholder]="'SHARED.SEARCH.DATE_PLACEHOLDER' | translate"
                          ngbDatepicker
                          #toDate="ngbDatepicker"
                        />
                        <div class="input-group-append">
                          <span
                            class="input-group-text gradient-light-blue-indigo"
                            style="cursor: pointer;"
                            (click)="toDate.toggle()"
                          >
                            <i class="fa fa-calendar"></i>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-auto form-group d-flex">
                  <button
                    type="submit"
                    class="btn btn-raised btn-primary align-self-end round mx-1 mb-0"
                    [disabled]="isSearching$ | async"
                  >
                    <i class="fa fa-search"></i>
                  </button>
                  <a
                    *ngIf="[Claims.CREATE_PETTY_CASH_REDUCTION] | authorize | async"
                    routerLink="/finances/petty-cash-reductions/create"
                    class="btn btn-raised btn-success align-self-end round mx-1 mb-0"
                  >
                    <i class="fa fa-plus"></i>
                    <span class="mx-1">{{ 'SHARED.CONFIRM.ADD' | translate }}</span>
                  </a>
                  <app-export-tools
                    *ngIf="(pettyCashReductions$ | async)?.length"
                    class="d-flex align-self-end mx-1"
                    targetElementId="petty-cash-reductions-table-export-target-element"
                    [outputFileName]="'SHARED.EXPORT_TABLES.PETTY_CASH_REDUCTIONS_TABLE' | translate"
                  ></app-export-tools>
                </div>
              </form>
            </div>
            <div class="col-md-12" *ngIf="(pettyCashReductions$ | async)?.length; else noItems">
              <div class="row">
                <div class="col-md-12 alert alert-light text-center" role="alert">
                  {{ 'SHARED.SEARCH.SEARCH_RESULTS' | translate }} ({{ (paginationInfo$ | async).total | toNumber }})
                  {{ 'FINANCES.PETTY_CASH_REDUCTION.PETTY_CASH_REDUCTION_DATA.PETTY_REDUCTION' | translate }}
                </div>
                <div class="col-md-12">
                  <div class="table-responsive">
                    <table class="table text-center" id="petty-cash-reductions-table-export-target-element">
                      <thead>
                        <tr>
                          <th scope="col"><i class="fa fa-list-ol"></i></th>
                          <th scope="col">
                            {{ 'FINANCES.PETTY_CASH_REDUCTION.PETTY_CASH_REDUCTION_DATA.PETTY_CASH' | translate }}
                          </th>
                          <th scope="col">
                            {{
                              'FINANCES.PETTY_CASH_REDUCTION.PETTY_CASH_REDUCTION_DATA.PETTY_CASH_REDUCTION_VALUE'
                                | translate
                            }}
                          </th>
                          <th scope="col">
                            {{ 'FINANCES.PETTY_CASH_REDUCTION.PETTY_CASH_REDUCTION_DATA.TRANSACTION_DATE' | translate }}
                          </th>
                          <th scope="col">{{ 'SHARED.SEARCH.CREATED_AT' | translate }}</th>
                          <th scope="col">{{ 'SHARED.TOOLS' | translate }}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let reduction of pettyCashReductions$ | async; trackBy: trackItems; let i = index">
                          <th scope="row">{{ i + 1 }}</th>
                          <td>{{ reduction.pettyCash.description }}</td>
                          <td>{{ reduction.value | toDecimal }}</td>
                          <td>
                            <span [ngbTooltip]="reduction.transactionDate | date: 'time'">
                              {{ reduction.transactionDate | date }}
                            </span>
                          </td>
                          <td>
                            <span [ngbTooltip]="reduction.createdAt | date: 'time'">
                              {{ reduction.createdAt | date }}
                            </span>
                          </td>
                          <td>
                            <a
                              *ngIf="[Claims.CREATE_PETTY_CASH_REDUCTION] | authorize | async"
                              class="info p-0"
                              (click)="selectedPettyCashReduction = reduction; openModal(viewModalRef)"
                              [ngbTooltip]="
                                'FINANCES.PETTY_CASH_REDUCTION.PETTY_CASH_REDUCTION_DATA.DISPLAY_DETAILS_PETTY_CASH_REDUCTION'
                                  | translate
                              "
                            >
                              <i class="fa fa-eye font-medium-3 mr-2"></i>
                            </a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div class="d-flex justify-content-between p-2">
                      <ngb-pagination
                        *ngIf="paginationInfo$ | async; let pagination"
                        maxSize="5"
                        [collectionSize]="pagination.total"
                        [pageSize]="pagination.pageSize"
                        [page]="pagination.page"
                        (pageChange)="pageChanged($event)"
                        boundaryLinks="true"
                      >
                        <ng-template ngbPaginationFirst>{{
                          'SHARED.PAGINATION.PAGINATION_FIRST' | translate
                        }}</ng-template>
                        <ng-template ngbPaginationFirst>{{
                          'SHARED.PAGINATION.PAGINATION_PREVIOUS' | translate
                        }}</ng-template>
                        <ng-template ngbPaginationFirst>{{
                          'SHARED.PAGINATION.PAGINATION_NEXT' | translate
                        }}</ng-template>
                        <ng-template ngbPaginationFirst>{{
                          'SHARED.PAGINATION.PAGINATION_LAST' | translate
                        }}</ng-template>
                      </ngb-pagination>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- petty cash reductions list ends -->

<!-- no items template starts -->
<ng-template #noItems>
  <div class="col-md-12 alert alert-warning mt-3 text-center" role="alert">
    {{ 'FINANCES.PETTY_CASH_REDUCTION.SEARCH_PETTY_CASH_REDUCTION_NOT_FOUND' | translate }}
    <!--   -->
    <a
      *ngIf="[Claims.CREATE_PETTY_CASH_REDUCTION] | authorize | async"
      routerLink="/finances/petty-cash-reductions/create"
      class="btn btn-raised btn-success round"
    >
      <i class="fa fa-plus"></i>
      <span class="mx-1">{{ 'SHARED.CONFIRM.ADD' | translate }}</span>
    </a>
  </div>
</ng-template>
<!-- no items template ends -->

<!-- view petty cash reduction modal starts -->
<ng-template #viewModalRef let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title text-info" id="modal-basic-title">
      {{ 'FINANCES.PETTY_CASH_REDUCTION.PETTY_CASH_REDUCTION_DATA_TITLE' | translate }}
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="d()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="card-content">
      <div class="row">
        <div class="col-md-12">
          <ul
            class="nav-tabs pr-3"
            ngbNav
            #nav="ngbNav"
            [(activeId)]="activePage"
            (activeIdChange)="selectedPageChanged($event)"
            [destroyOnHide]="false"
          >
            <li ngbNavItem="details">
              <a ngbNavLink>
                <i class="fa fa-file-text" [class.success]="activePage == 'details'"></i>
                {{ 'FINANCES.PETTY_CASH_REDUCTION.PETTY_CASH_REDUCTION_DATA_TITLE' | translate }}
              </a>

              <!-- view petty cash reduction starts -->
              <ng-template ngbNavContent>
                <div class="row">
                  <div class="form-group col-12">
                    <label for="selectedPettyCashReduction.pettyCash.description">{{
                      'FINANCES.PETTY_CASH_REDUCTION.PETTY_CASH_REDUCTION_DATA.PETTY_CASH' | translate
                    }}</label>
                    <input
                      type="text"
                      id="selectedPettyCashReduction.pettyCash.description"
                      class="form-control round"
                      [placeholder]="
                        'FINANCES.PETTY_CASH_REDUCTION.PETTY_CASH_REDUCTION_DATA.PETTY_CASH_PLACEHOLDER' | translate
                      "
                      readonly
                      [value]="selectedPettyCashReduction.pettyCash.description"
                    />
                  </div>
                  <div class="form-group col-6">
                    <label for="selectedPettyCashReduction.value">{{
                      'FINANCES.PETTY_CASH_REDUCTION.PETTY_CASH_REDUCTION_DATA.VALUE' | translate
                    }}</label>
                    <input
                      type="text"
                      id="selectedPettyCashReduction.value"
                      class="form-control round"
                      [placeholder]="
                        'FINANCES.PETTY_CASH_REDUCTION.PETTY_CASH_REDUCTION_DATA.VALUE_PLACEHOLDER' | translate
                      "
                      readonly
                      [value]="selectedPettyCashReduction.value | toDecimal"
                    />
                  </div>
                  <div class="form-group col-6">
                    <label for="selectedPettyCashReduction.pettyCash.value">{{
                      'FINANCES.PETTY_CASH_REDUCTION.PETTY_CASH_REDUCTION_DATA.CURRENT_PETTY_CASH_REDUCTION_VALUE'
                        | translate
                    }}</label>
                    <input
                      type="text"
                      id="selectedPettyCashReduction.pettyCash.value"
                      class="form-control round"
                      [placeholder]="
                        'FINANCES.PETTY_CASH_REDUCTION.PETTY_CASH_REDUCTION_DATA.CURRENT_PETTY_CASH_REDUCTION_VALUE_PLACEHOLDER'
                          | translate
                      "
                      readonly
                      [value]="selectedPettyCashReduction.pettyCash.value | toDecimal"
                    />
                  </div>
                  <div class="form-group col-6">
                    <label for="selectedPettyCashReduction.pettyCash.transactionDate">{{
                      'FINANCES.PETTY_CASH_REDUCTION.PETTY_CASH_REDUCTION_DATA.TRANSACTION_DATE' | translate
                    }}</label>
                    <input
                      type="text"
                      id="selectedPettyCashReduction.pettyCash.transactionDate"
                      class="form-control round"
                      [placeholder]="'SHARED.SEARCH.DATE_PLACEHOLDER' | translate"
                      readonly
                      [value]="selectedPettyCashReduction.pettyCash.transactionDate | date: 'datetime'"
                    />
                  </div>
                  <div class="form-group col-6">
                    <label for="selectedPettyCashReduction.pettyCash.createdAt">{{
                      'SHARED.SEARCH.CREATED_AT' | translate
                    }}</label>
                    <input
                      type="text"
                      id="selectedPettyCashReduction.pettyCash.createdAt"
                      class="form-control round"
                      [placeholder]="'SHARED.SEARCH.DATE_PLACEHOLDER' | translate"
                      readonly
                      [value]="selectedPettyCashReduction.pettyCash.createdAt | date: 'datetime'"
                    />
                  </div>
                  <div class="form-group col-md-12">
                    <label for="selectedPettyCashReduction.notes">{{
                      'FINANCES.PETTY_CASH_REDUCTION.PETTY_CASH_REDUCTION_DATA.NOTES' | translate
                    }}</label>
                    <textarea
                      id="selectedPettyCashReduction.notes"
                      class="form-control round"
                      [innerText]="selectedPettyCashReduction.notes"
                      [placeholder]="
                        'FINANCES.PETTY_CASH_REDUCTION.PETTY_CASH_REDUCTION_DATA.NOTES_NOT_FOUND' | translate
                      "
                      readonly
                    ></textarea>
                  </div>
                </div>
              </ng-template>
              <!-- view petty cash reduction ends -->
            </li>

            <li ngbNavItem="journal">
              <a ngbNavLink>
                <i class="fa fa-calendar" [class.success]="activePage == 'journal'"></i>
                {{ 'SHARED.JOURNAL_DATA.JOURNAL' | translate }}
              </a>

              <!-- create journal form starts -->
              <ng-template ngbNavContent>
                <app-journal-details [journal]="selectedPettyCashReduction.journalAssociations[0]?.journal">
                </app-journal-details>
              </ng-template>
              <!-- create journal form ends -->
            </li>
          </ul>
          <div [ngbNavOutlet]="nav" class="mt-2"></div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="modal-actions">
      <button type="button" class="btn btn-outline-dark round" (click)="c()" ngbAutofocus>
        <i class="fa fa-times"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.CANCEL' | translate }}</span>
      </button>
    </div>
  </div>
</ng-template>
<!-- view petty cash reduction modal ends -->

<!-- petty cash search starts -->
<app-petty-cash-search [(visible)]="pettyCashListVisibility" (pettyCash)="selectPettyCash($event)">
</app-petty-cash-search>
<!-- petty cash search ends -->
