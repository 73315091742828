<ul class="navbar-nav">
  <li class="nav-item" ngbDropdown display="dynamic" placement="bottom" (openChange)="dropDownIsOpened.emit($event)">
    <span
      *ngIf="locationId"
      class="badge badge-pill badge-primary"
      [ngClass]="{
        'badge-primary': locationId && quantity === 0,
        'badge-danger': isOutOfStock,
        'badge-success': !isOutOfStock && quantity !== 0
      }"
      >{{ quantityOfSelectedLocation | toDecimal }}</span
    >
    <i
      class="fa fa-info-circle font-medium-3 info mx-1"
      [ngClass]="{ danger: isOutOfStock }"
      style="cursor: pointer;"
      ngbDropdownToggle
    >
    </i>
    <div ngbDropdownMenu>
      <table class="table text-center min-width-16">
        <thead>
          <tr dropdown-item>
            <th scope="col">
              <i class="fa fa-university"></i>
              {{ 'SHARED.STORE' | translate }}
            </th>

            <th *ngIf="product.hasSubProducts" scope="col">
              <i class="fa fa-comments"></i>
              {{ 'SHARED.SUB_PRODUCT' | translate }}
            </th>
            <th scope="col">
              <i class="fa fa-list-ol"></i>
              {{ 'SHARED.AVAILABLE' | translate }}
            </th>
          </tr>
        </thead>
        <tbody dropdown-item>
          <tr *ngFor="let stock of product.stocks">
            <th scope="row" [ngClass]="{ success: stock.locationId === locationId }">
              {{ stock.location?.name | localize: stock.location?.nameEn | async }}
            </th>
            <td *ngIf="product.hasSubProducts">
              <div *ngIf="stock.subProduct" class="mt-1 d-block">
                <app-sub-product-info [subProduct]="stock.subProduct"></app-sub-product-info>
              </div>
            </td>
            <td [ngClass]="{ success: stock.locationId === locationId }">
              {{ resolveStockQuantity(stock.quantity) | toDecimal }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </li>
</ul>
