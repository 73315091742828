<div [formGroup]="formArray.parent" class="row pos-products-form">
  <div class="col-12">
    <div class="table-responsive items-container mt-2">
      <table class="table table-sm text-center">
        <thead class="thead-light">
          <tr>
            <th scope="col">
              {{ 'SALES.INVOICES.POS.POS_DATA.PRODUCT' | translate }}
            </th>
            <th scope="col">
              {{ 'SALES.INVOICES.POS.POS_DATA.PRODUCT_QUANTITY' | translate }}
            </th>
            <th scope="col">
              {{ 'SALES.INVOICES.POS.POS_DATA.UNIT_OF_MEASURE' | translate }}
            </th>
            <th scope="col">
              {{ 'SALES.INVOICES.POS.POS_DATA.PRODUCT_PRICE' | translate }}
            </th>
            <th scope="col">
              {{ 'SALES.INVOICES.POS.POS_DATA.DISCOUNT' | translate }}
            </th>
            <th scope="col">
              {{ 'SALES.INVOICES.POS.POS_DATA.DISCOUNT_PERCENT' | translate }}
            </th>
            <th scope="col">
              {{ 'SALES.INVOICES.POS.POS_DATA.PRODUCT_TOTAL' | translate }}
            </th>
            <th scope="col">
              {{ 'SALES.INVOICES.POS.POS_DATA.PRODUCT_NOTES' | translate }}
            </th>
            <th scope="col">
              <i class="fa fa-cogs"></i>
            </th>
          </tr>
        </thead>
        <tbody [formArrayName]="formArrayName">
          <ng-container [formGroup]="formGroup" *ngFor="let formGroup of formArray.controls; let index = index">
            <tr [id]="'product-row-' + (index + 1)">
              <td>
                <ng-container *ngIf="!formGroup.value.isPointsExchange">
                  {{ formGroup.value.product?.description | localize: formGroup.value.product?.descriptionEn | async }}
                  <div class="d-block">
                    <app-point-of-sale-invoice-sub-products-form
                      *ngIf="formGroup.value.product?.hasSubProducts"
                      [formGroup]="formGroup"
                      controlName="subProductId"
                    ></app-point-of-sale-invoice-sub-products-form>
                  </div>
                </ng-container>
                <span *ngIf="formGroup.value.isPointsExchange">{{ formGroup.value.description }}</span>
              </td>
              <td>
                <input
                  *ngIf="!formGroup.value.isPointsExchange"
                  type="text"
                  [mask]="DECIMAL_MASK"
                  thousandSeparator=","
                  [id]="'product-' + (index + 1) + '-quantity'"
                  name="quantity"
                  autocomplete="off"
                  class="form-control round"
                  [placeholder]="'SALES.INVOICES.POS.POS_DATA.PRODUCT_QUANTITY_PLACEHOLDER' | translate"
                  formControlName="quantity"
                  appTouchKeyboardTarget
                  [touchKeyboardConfig]="touchKeyboardConfig"
                />
                <span *ngIf="formGroup.value.isPointsExchange">{{ formGroup.value.quantity }}</span>
              </td>
              <td class="min-width-9">
                <ng-select
                  *ngIf="!formGroup.value.isPointsExchange"
                  class="round"
                  formControlName="unitOfMeasureId"
                  searchable="true"
                  [placeholder]="'SALES.INVOICES.POS.POS_DATA.UNIT_OF_MEASURE_PLACEHOLDER' | translate"
                  [notFoundText]="'SHARED.SEARCH.SEARCH_NOT_FOUND' | translate"
                  dropdownPosition="bottom"
                  (open)="isDropDownOpened = true"
                  (close)="isDropDownOpened = false"
                >
                  <ng-option *ngFor="let unit of getProductUnitsOfMeasure(formGroup.value.product)" [value]="unit.id">
                    {{ unit.name | localize: unit.nameEn | async }}
                  </ng-option>
                </ng-select>
              </td>
              <td>
                {{ formGroup.value.value || 0 | toDecimal }}
              </td>
              <td>
                <ng-container *ngIf="!formGroup.value.isPointsExchange">
                  <input
                    *ngIf="[Claims.CREATE_DISCOUNT_ON_SALE_INVOICE] | authorize | async"
                    type="text"
                    [mask]="DECIMAL_MASK"
                    thousandSeparator=","
                    name="discount"
                    autocomplete="off"
                    class="form-control round"
                    [placeholder]="'SALES.INVOICES.POS.POS_DATA.DISCOUNT_PLACEHOLDER' | translate"
                    formControlName="discount"
                    appTouchKeyboardTarget
                    [touchKeyboardConfig]="touchKeyboardConfig"
                  />
                  <span *ngIf="!([Claims.CREATE_DISCOUNT_ON_SALE_INVOICE] | authorize | async)">
                    {{ (formGroup.value.discount | toDecimal) || '0' }}
                  </span>
                </ng-container>
              </td>
              <td>
                <ng-container *ngIf="!formGroup.value.isPointsExchange">
                  <input
                    *ngIf="[Claims.CREATE_DISCOUNT_ON_SALE_INVOICE] | authorize | async"
                    type="text"
                    name="discountPercent"
                    autocomplete="off"
                    class="form-control round"
                    [placeholder]="'SALES.INVOICES.POS.POS_DATA.DISCOUNT_PERCENT_PLACEHOLDER' | translate"
                    formControlName="discountPercent"
                    appTouchKeyboardTarget
                    [touchKeyboardConfig]="touchKeyboardConfig"
                  />
                  <span *ngIf="!([Claims.CREATE_DISCOUNT_ON_SALE_INVOICE] | authorize | async)">
                    {{ (formGroup.value.discountPercent | toDecimal) || '0' }}
                  </span>
                </ng-container>
              </td>
              <td class="min-width-6">
                <span>{{ (calculateProductTotal(formGroup.value) | toDecimal) || '0.0' }}</span>
              </td>
              <td class="min-width-12">
                <textarea
                  *ngIf="!formGroup.value.isPointsExchange"
                  class="form-control round"
                  [placeholder]="'SALES.INVOICES.POS.POS_DATA.PRODUCT_NOTES_PLACEHOLDER' | translate"
                  rows="1"
                  minlength="0"
                  maxlength="200"
                  formControlName="notes"
                ></textarea>
              </td>
              <td>
                <a class="danger p-0" [ngbTooltip]="'SHARED.CONFIRM.DELETE' | translate" (click)="removeProduct(index)">
                  <i class="fa fa-close font-medium-3 mr-2"></i>
                </a>
              </td>
            </tr>
            <tr *ngIf="formGroup.value?.product?.hasSerialNumber">
              <td colspan="3">{{ 'SALES.INVOICES.POS.POS_DATA.SERIAL_NUMBER' | translate }}</td>
              <td colspan="17">
                <input
                  type="text"
                  name="serialNumber"
                  autocomplete="off"
                  class="form-control round"
                  [placeholder]="'SALES.INVOICES.POS.POS_DATA.SERIAL_NUMBER_PLACEHOLDER' | translate"
                  maxlength="50"
                  formControlName="serialNumber"
                  appTouchKeyboardTarget
                  [touchKeyboardConfig]="touchKeyboardConfig"
                />
              </td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </div>
  </div>
  <div class="col-12">
    <div class="table-responsive">
      <table class="table table-sm text-center mt-2">
        <thead>
          <tr>
            <th scope="col">{{ 'SALES.INVOICES.POS.POS_DATA.PRODUCTS' | translate }}</th>
            <th scope="col">{{ 'SALES.INVOICES.POS.POS_DATA.PRODUCT_QUANTITY' | translate }}</th>
            <th scope="col">{{ 'SALES.INVOICES.POS.POS_DATA.PRODUCTS_TOTAL' | translate }}</th>
            <th scope="col">{{ 'SALES.INVOICES.POS.POS_DATA.DISCOUNT_TOTAL' | translate }}</th>
            <th scope="col">{{ 'SALES.INVOICES.POS.POS_DATA.PRODUCT_TAX' | translate }}</th>
            <th scope="col">{{ 'SALES.INVOICES.POS.POS_DATA.INVOICE_TOTAL' | translate }}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{{ formArray.length }}</td>
            <td>{{ productsQuantity }}</td>
            <td>{{ (productsTotalWithoutDiscountAndTax | toDecimal) || '0.0' }}</td>
            <td>{{ (productsDiscount | toDecimal) || '0.0' }}</td>
            <td>{{ (productsTax | toDecimal) || '0.0' }}</td>
            <td class="font-weight-bold">{{ (invoiceNet | toDecimal) || '0.0' }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
