<div class="file-viewer">
  <div class="preview-wrapper">
    <div class="preview" *ngIf="url">
      <img class="preview-img" *ngIf="isImage" [src]="url" />
      <i class="preview-file fa fa-file-pdf-o" *ngIf="isPdf"></i>
      <i class="preview-file fa fa-file-word-o" *ngIf="isMSWord"></i>
      <i class="preview-file fa fa-file-excel-o" *ngIf="isMSExcel"></i>
      <i class="preview-file fa fa-file-text-o" *ngIf="isText"></i>
      <i class="preview-file fa fa-file-archive-o" *ngIf="isArchive"></i>
      <i class="preview-file fa fa-file" *ngIf="isUnknownType"></i>
    </div>
  </div>
  <a *ngIf="url" [href]="url" target="_blank">
    <i class="fa fa-eye primary view" [ngbTooltip]="'SHARED.DISPLAY_FILE' | translate"></i>
  </a>
  <a *ngIf="url" [href]="url" [download]="fileName" target="_blank">
    <i class="fa fa-download success download" [ngbTooltip]="'SHARED.UPLOAD_THE_FILE' | translate"></i>
  </a>
  <a *ngIf="allowDelete" href="javascript:;" (click)="deleteFile()">
    <i class="fa fa-trash danger delete" [ngbTooltip]="'SHARED.DELETE_FILE' | translate"></i>
  </a>
  <a *ngIf="allowUpdate" (click)="updateFile()">
    <i class="fa fa-edit success update" [ngbTooltip]="'SHARED.UPDATE_FILE' | translate"></i>
  </a>
</div>
