import { Action } from '@ngrx/store';

import { EmployeeDeductionType } from 'lookups/models';
import { AppHttpResponse } from 'shared';

/**
 * The Lookups-Module store employee deduction type action types.
 */
export enum EmployeeDeductionTypeActionType {
  GET_ALL_EMPLOYEE_DEDUCTION_TYPES = '[Lookups] [EmployeeDeductionType] Get All Employee Deduction Types',
  GET_ALL_EMPLOYEE_DEDUCTION_TYPES_FAIL = '[Lookups] [EmployeeDeductionType] Get All Employee Deduction Types Fail',
  GET_ALL_EMPLOYEE_DEDUCTION_TYPES_SUCCESS = '[Lookups] [EmployeeDeductionType] Get All Employee Deduction Types Success',
}

/**
 * Represents a store employee deduction type action.
 */
export class GetAllEmployeeDeductionTypes implements Action {
  /**
   * The type of the action.
   */
  readonly type = EmployeeDeductionTypeActionType.GET_ALL_EMPLOYEE_DEDUCTION_TYPES;

  /**
   * Fires a new employee deduction type action.
   */
  constructor() {}
}

/**
 * Represents a store employee deduction type fail action.
 */
export class GetAllEmployeeDeductionTypesFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = EmployeeDeductionTypeActionType.GET_ALL_EMPLOYEE_DEDUCTION_TYPES_FAIL;

  /**
   * Fires a new employee deduction type fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store employee deduction type success action.
 */
export class GetAllEmployeeDeductionTypesSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = EmployeeDeductionTypeActionType.GET_ALL_EMPLOYEE_DEDUCTION_TYPES_SUCCESS;

  /**
   * Fires a employee deduction type success action.
   * @param payload An object contains the list of employee deduction type.
   */
  constructor(public payload: AppHttpResponse<EmployeeDeductionType[]>) {}
}

/**
 * Lookups-module employee deduction type action types.
 */
export type EmployeeDeductionTypeActions =
  | GetAllEmployeeDeductionTypes
  | GetAllEmployeeDeductionTypesFail
  | GetAllEmployeeDeductionTypesSuccess;
