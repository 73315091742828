import { createFeatureSelector } from '@ngrx/store';
import * as fromRouterStore from '@ngrx/router-store';

import { RouterStateUrl, AppStatusState } from 'core/store/states';

/**
 * The router state feature selector.
 */
export const getRouterState = createFeatureSelector<fromRouterStore.RouterReducerState<RouterStateUrl>>('router');

/**
 * The app status state feature selector.
 */
export const selectAppStatusState = createFeatureSelector<AppStatusState>('status');
