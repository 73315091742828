import { createSelector } from '@ngrx/store';

import { AuthState, SessionState } from 'auth/store/states';
import { selectAuthState } from 'auth/store/selectors/feature.selectors';

/* Selectors */

/**
 * Gets the session state.
 */
const selectSessionState = createSelector(selectAuthState, (state: AuthState) => state.session);

/**
 * Gets the current logged-in user.
 */
export const getSessionUser = createSelector(selectSessionState, (state: SessionState) => state.user);

/**
 * Gets the default pos devices for the user.
 */
export const getSessionUserDefaultPosDevices = createSelector(
  selectSessionState,
  (state: SessionState) => state.user.userPreference.defaultPosDevice
);

/**
 * Gets the current logged-in user claims.
 */
export const getSessionUserClaims = createSelector(
  selectSessionState,
  (state: SessionState) => state.user?.userClaims?.map((userClaim) => userClaim.claim) ?? []
);

/**
 * Gets the current logged-in user's preferred language.
 */
export const getSessionUserLanguage = createSelector(
  selectSessionState,
  (state: SessionState) =>
    state.user?.language || {
      id: 1,
      name: 'العربيه',
      nameEn: 'Arabic',
      key: 'ar',
    }
);

/**
 * Gets the error that occurred in the state.
 */
export const getSessionError = createSelector(selectSessionState, (state: SessionState) => state.error);

/**
 * Determines if there is a running log-in process.
 */
export const getSessionLogging = createSelector(selectSessionState, (state: SessionState) => state.isLogging);

/**
 * Determines if the user is logged-in.
 */
export const getSessionLoggedIn = createSelector(selectSessionState, (state: SessionState) => state.isLoggedIn);

/**
 * Determines if there is a running refresh-token process.
 */
export const getSessionRefreshingToken = createSelector(
  selectSessionState,
  (state: SessionState) => state.isRefreshingToken
);

/**
 * Gets the api key.
 */
export const getApiKey = createSelector(selectSessionState, (state: SessionState) => state.apiKey);

/**
 * Determines if the last refresh-token action was completed successfully.
 */
export const getSessionTokenRefreshed = createSelector(
  selectSessionState,
  (state: SessionState) => state.isTokenRefreshed
);

/**
 * Determines if there is a running fetch user data process.
 */
export const getSessionFetchingUserData = createSelector(
  selectSessionState,
  (state: SessionState) => state.isFetchingUserData
);

/**
 * Determines if there is a running refresh user data process.
 */
export const getSessionRefreshingUserData = createSelector(
  selectSessionState,
  (state: SessionState) => state.isRefreshingUserData
);

/**
 * Determines if there is a running update user profile info/credentials process.
 */
export const getSessionUpdatingProfile = createSelector(
  selectSessionState,
  (state: SessionState) => state.isUpdatingProfile
);

/**
 * Indicates whether the app will remember user's token if the user closed the app.
 */
export const getSessionRememberUser = createSelector(selectSessionState, (state: SessionState) => state.rememberUser);

/**
 * Determines if there is a running update user language process.
 */
export const getSessionUserLanguageUpdating = createSelector(
  selectSessionState,
  (state: SessionState) => state.isUpdatingUserLanguage
);

/**
 * Determines if there is a running update process.
 */
export const getSessionUserDefaultLocationUpdating = createSelector(
  selectSessionState,
  (state: SessionState) => state.isUserDefaultLocationUpdating
);

/**
 * Determines if the last update process has been ended successfully.
 */
export const getSessionUserDefaultLocationUpdateCompleted = createSelector(
  selectSessionState,
  (state: SessionState) => state.isUserDefaultLocationUpdateCompleted
);

/**
 * Determines if there is a running update process.
 */
export const getSessionUserDefaultCostCenterUpdating = createSelector(
  selectSessionState,
  (state: SessionState) => state.isUserDefaultCostCenterUpdating
);

/**
 * Determines if the last update process has been ended successfully.
 */
export const getSessionUserDefaultCostCenterUpdateCompleted = createSelector(
  selectSessionState,
  (state: SessionState) => state.istUserDefaultCostCenterUpdateCompleted
);

/**
 * Gets the default location for the user.
 */
export const getSessionUserDefaultLocation = createSelector(
  selectSessionState,
  (state: SessionState) => state.user.userPreference.defaultLocation
);

/**
 * Gets the default cost center for the user.
 */
export const getSessionUserDefaultCostCenter = createSelector(
  selectSessionState,
  (state: SessionState) => state.user.userPreference.defaultCostCenter
);

/**
 * Gets the default cash bank account for the user.
 */
export const getSessionUserDefaultCashBankAccount = createSelector(
  selectSessionState,
  (state: SessionState) => state.user.userPreference.defaultCashBankAccount
);

/**
 * Gets the default electronic bank account for the user.
 */
export const getSessionUserDefaultElectronicBankAccount = createSelector(
  selectSessionState,
  (state: SessionState) => state.user.userPreference.defaultElectronicBankAccount
);

/**
 * Gets the loaded list of user locations.
 */
export const getUserLocations = createSelector(selectSessionState, (state: SessionState) => state.userLocations);

/**
 * Determines if there is a running process.
 */
export const isGettingUserLocations = createSelector(
  selectSessionState,
  (state: SessionState) => state.isGettingUserLocations
);

/**
 * Determines if the last process has been ended successfully.
 */
export const getUserLocationsCompleted = createSelector(
  selectSessionState,
  (state: SessionState) => state.isGetUserLocationsCompleted
);

/**
 * Gets the loaded list of user cost centers.
 */
export const getUserCostCenters = createSelector(selectSessionState, (state: SessionState) => state.userCostCenters);

/**
 * Determines if there is a running process.
 */
export const isGettingUserCostCenters = createSelector(
  selectSessionState,
  (state: SessionState) => state.isGettingUserCostCenters
);

/**
 * Determines if the last process has been ended successfully.
 */
export const getUserCostCentersCompleted = createSelector(
  selectSessionState,
  (state: SessionState) => state.isGetUserCostCentersCompleted
);

/**
 * Gets the loaded list of user bank accounts.
 */
export const getUserBankAccounts = createSelector(selectSessionState, (state: SessionState) => state.userBankAccounts);

/**
 * Determines if there is a running process.
 */
export const isGettingUserBankAccounts = createSelector(
  selectSessionState,
  (state: SessionState) => state.isGettingUserBankAccounts
);

/**
 * Determines if the last process has been ended successfully.
 */
export const getUserBankAccountsCompleted = createSelector(
  selectSessionState,
  (state: SessionState) => state.isGetUserBankAccountsCompleted
);

/**
 * Determines if there is a running update process.
 */
export const getSessionUserDefaultCashAccountUpdating = createSelector(
  selectSessionState,
  (state: SessionState) => state.isUserDefaultCashAccountUpdating
);

/**
 * Determines if the last update process has been ended successfully.
 */
export const getSessionUserDefaultCashAccountUpdateCompleted = createSelector(
  selectSessionState,
  (state: SessionState) => state.isUserDefaultCashAccountUpdateCompleted
);

/**
 * Determines if there is a running update process.
 */
export const getSessionUserDefaultElectronicAccountUpdating = createSelector(
  selectSessionState,
  (state: SessionState) => state.isUserDefaultElectronicAccountUpdating
);

/**
 * Determines if the last update process has been ended successfully.
 */
export const getSessionUserDefaultElectronicAccountUpdateCompleted = createSelector(
  selectSessionState,
  (state: SessionState) => state.isUserDefaultElectronicAccountUpdateCompleted
);

/**
 * Determines if there is a running update process.
 */
export const getSessionUserDefaultPosDeviceUpdating = createSelector(
  selectSessionState,
  (state: SessionState) => state.isUserDefaultPosDeviceUpdating
);

/**
 * Determines if the last update process has been ended successfully.
 */
export const getSessionUserDefaultPosDeviceUpdateCompleted = createSelector(
  selectSessionState,
  (state: SessionState) => state.isUserDefaultPosDeviceUpdateCompleted
);

/**
 * Determines if there is a running process.
 */
export const getApiKeyGetting = createSelector(selectSessionState, (state: SessionState) => state.isGettingApiKey);

/**
 * Determines if the last process has been ended successfully.
 */
export const getApiKeyGetCompleted = createSelector(
  selectSessionState,
  (state: SessionState) => state.isGetApiKeyCompleted
);

/**
 * Determines if there is a running update process.
 */
export const getSelectedApiKeyUpdating = createSelector(
  selectSessionState,
  (state: SessionState) => state.isApiKeyUpdating
);

/**
 * Determines if the last update process has been ended successfully.
 */
export const getSelectedApiKeyUpdateCompleted = createSelector(
  selectSessionState,
  (state: SessionState) => state.isApiKeyUpdateCompleted
);
