import { createSelector } from '@ngrx/store';

import { selectLookupsState } from 'lookups/store/selectors/feature.selectors';
import { LookupsState, LanguagesState } from 'lookups/store/states';

/**
 * Gets the languages state.
 */
const selectLanguages = createSelector(selectLookupsState, (state: LookupsState) => state.languages);

/**
 * Gets the list of loaded languages.
 */
export const getLanguages = createSelector(selectLanguages, (state: LanguagesState) => state.data);
