import { Action } from '@ngrx/store';

import { AppHttpResponse } from 'shared';
import { PettyCashRefund, CreatePettyCashRefundInput } from 'finances/models';

/**
 * The finances-module store petty Cash refund action types.
 */
export enum PettyCashRefundActionType {
  SEARCH_PETTY_CASH_REFUND = '[Finances] [PettyCashRefund] Search Petty Cash Refund',
  SEARCH_PETTY_CASH_REFUND_FAIL = '[Finances] [PettyCashRefund] Search Petty Cash Refund Fail',
  SEARCH_PETTY_CASH_REFUND_SUCCESS = '[Finances] [PettyCashRefund] Search Petty Cash Refund Success',

  FIND_PETTY_CASH_REFUND = '[Finances] [PettyCashRefund] Find Petty Cash Refund',
  FIND_PETTY_CASH_REFUND_FAIL = '[Finances] [PettyCashRefund] Find Petty Cash Refund Fail',
  FIND_PETTY_CASH_REFUND_SUCCESS = '[Finances] [PettyCashRefund] Find Petty Cash Refund Success',

  CREATE_PETTY_CASH_REFUND = '[Finances] [PettyCashRefund] Create Petty Cash Refund',
  CREATE_PETTY_CASH_REFUND_FAIL = '[Finances] [PettyCashRefund] Create Petty Cash Refund Fail',
  CREATE_PETTY_CASH_REFUND_SUCCESS = '[Finances] [PettyCashRefund] Create Petty Cash Refund Success',
}

/**
 * Represents a store petty cash refund search action.
 */
export class SearchPettyCashRefund implements Action {
  /**
   * The type of the action.
   */
  readonly type = PettyCashRefundActionType.SEARCH_PETTY_CASH_REFUND;

  /**
   * Fires a new petty cash refund search action.
   * @param payload the search parameters,
   * if omitted, all petty cash refund will be loaded.
   */
  constructor(
    public payload?: {
      pettyCash: number[];
      fromDate: Date;
      toDate: Date;
      page: number;
    }
  ) {}
}

/**
 * Represents a store petty cash refund search-fail action.
 */
export class SearchPettyCashRefundFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = PettyCashRefundActionType.SEARCH_PETTY_CASH_REFUND_FAIL;

  /**
   * Fires a new petty cash refund search-fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store petty cash refund search-success action.
 */
export class SearchPettyCashRefundSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = PettyCashRefundActionType.SEARCH_PETTY_CASH_REFUND_SUCCESS;

  /**
   * Fires a new petty cash refund search-success action.
   * @param payload An object contains the list of petty cash refund that matches the search criteria
   * in addition to the pagination info.
   */
  constructor(public payload: AppHttpResponse<PettyCashRefund[]>) {}
}

/**
 * Represents a store find-petty-cash-refund action.
 */
export class FindPettyCashRefund implements Action {
  /**
   * The type of the action.
   */
  readonly type = PettyCashRefundActionType.FIND_PETTY_CASH_REFUND;

  /**
   * Fires a new Find petty cash refund action.
   * @param payload the id of the petty cash refund.
   */
  constructor(public payload: number) {}
}

/**
 * Represents a store find-petty-cash-refund-fail action.
 */
export class FindPettyCashRefundFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = PettyCashRefundActionType.FIND_PETTY_CASH_REFUND_FAIL;

  /**
   * Fires a new find-petty-cash-refund-fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store Find-petty-cash-refund-success action.
 */
export class FindPettyCashRefundSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = PettyCashRefundActionType.FIND_PETTY_CASH_REFUND_SUCCESS;

  /**
   * Fires a new find-petty-cash-refund-success action.
   * @param payload The loaded petty-cash-refund.
   */
  constructor(public payload: AppHttpResponse<PettyCashRefund>) {}
}

/**
 * Represents a store petty cash refund create action.
 */
export class CreatePettyCashRefund implements Action {
  /** The type of the action. */
  readonly type = PettyCashRefundActionType.CREATE_PETTY_CASH_REFUND;

  /**
   * Fires a new petty cash refund create action.
   * @param payload the new petty cash refund data-model.
   */
  constructor(public payload: CreatePettyCashRefundInput) {}
}

/**
 * Represents a store petty cash refund create-fail action.
 */
export class CreatePettyCashRefundFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = PettyCashRefundActionType.CREATE_PETTY_CASH_REFUND_FAIL;

  /**
   * Fires a new petty cash refund create-fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store petty cash refund create-success action.
 */
export class CreatePettyCashRefundSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = PettyCashRefundActionType.CREATE_PETTY_CASH_REFUND_SUCCESS;

  /**
   * Fires a new petty cash refund create-success action.
   * @param payload The created petty cash refund.
   */
  constructor(public payload: AppHttpResponse<PettyCashRefund>) {}
}

/**
 * Finances-module petty cash refund action types.
 */
export type PettyCashRefundActions =
  | SearchPettyCashRefund
  | SearchPettyCashRefundFail
  | SearchPettyCashRefundSuccess
  | FindPettyCashRefund
  | FindPettyCashRefundFail
  | FindPettyCashRefundSuccess
  | CreatePettyCashRefund
  | CreatePettyCashRefundFail
  | CreatePettyCashRefundSuccess;
