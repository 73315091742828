import { Component, EventEmitter, Input, Output } from '@angular/core';

import Decimal from 'decimal.js';

import { Product, UnitOfMeasure } from 'stores/models';

@Component({
  selector: 'app-product-available-stocks',
  templateUrl: './product-available-stocks.component.html',
  styles: [],
})
export class ProductAvailableStocksComponent {
  /**
   * The product.
   */
  @Input() product: Product;

  /**
   * The id of the current location to compare available stocks in it.
   */
  @Input() locationId: number;

  /**
   * The id of the current sub product to compare available stocks in it.
   */
  @Input() subProductId: number;

  /**
   * The id of the current selected unit of measure.
   */
  @Input() unitOfMeasureId: number;

  /**
   * The unit of measure list of the product.
   */
  @Input() unitsOfMeasure: UnitOfMeasure[] = [];

  /**
   * The quantity to be compared with the available stock in the current location.
   */
  @Input() quantity: number;

  /**
   * Emits a value indicates that a drop-down field in the form is opened and therefore the table height should be expanded.
   */
  @Output() dropDownIsOpened = new EventEmitter<boolean>(false);

  /**
   * Indicates whether the desired quantity if more than the available stock in the current location.
   */
  get isOutOfStock(): boolean {
    return this.quantity > this.quantityOfSelectedLocation;
  }

  /**
   * The available stock in the current location.
   */
  get quantityOfSelectedLocation(): number {
    const locationIndex = this.product.stocks.findIndex(
      (stock) => stock.locationId === this.locationId && stock.subProductId === this.subProductId
    );

    if (locationIndex >= 0) {
      return this.resolveStockQuantity(this.product.stocks[locationIndex]?.quantity);
    }
  }

  /**
   * Resolves the given stock quantity by determine the unit of measure rate.
   * @param quantity The quantity of the stock.
   * @returns The resolved stock quantity.
   */
  resolveStockQuantity(quantity: number): number {
    let stock = 0;

    if (!this.unitOfMeasureId || this.unitOfMeasureId === this.product.unitOfMeasureId) {
      stock = quantity;
    } else {
      stock = Decimal.div(
        quantity,
        this.product.productUnitOfMeasureRates.find((item) => item.unitOfMeasureId === this.unitOfMeasureId)?.rate ?? 1
      ).toNumber();
    }

    return stock;
  }
}
