/**
 * Represents a system supported payroll element types.
 */
export enum PayrollElementTypes {
  /**
   * The direct amount value of payroll element types.
   */
  DIRECT_AMOUNT = 'DIRECT_AMOUNT',

  /**
   * The percentage from another elements value of payroll element types.
   */
  PERCENTAGE_FROM_ANOTHER_ELEMENTS = 'PERCENTAGE_FROM_ANOTHER_ELEMENTS',
}
