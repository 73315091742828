import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormGroup, FormControl, Validators } from '@angular/forms';

import { Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { Decimal } from 'decimal.js';

import * as fromFinancesStore from 'finances/store';
import * as fromAuthStore from 'auth/store';
import { CustomValidators } from 'shared';
import { Account, CostCenter, Journal, JournalLineFormItem } from 'finances/models';
import { User } from 'security/models';

@Component({
  selector: 'app-journal-line-form',
  templateUrl: './journal-line-form.component.html',
  styles: [],
})
export class JournalLineFormComponent implements OnInit, OnDestroy {
  /**
   * Sets the journal lines form-array.
   */
  @Input() journalLinesForm: FormArray;

  /**
   * Sets the initial journals in the journals form.
   * @param lines An object contains `count` of initial blank journal lines.
   * @param lines A single journal line details to be added to the form.
   * @param lines A list of journal lines details to be added to the form.
   */
  @Input() set initialJournalLines(lines: { count: number } | JournalLineFormItem | JournalLineFormItem[]) {
    if (Object.getOwnPropertyNames(lines).includes('count')) {
      for (let index = 0; index < (lines as { count: number }).count; index++) {
        this.addJournalLine();
      }
    } else if (lines instanceof Array) {
      lines.forEach((line) => this.addJournalLine(line));
    } else if (typeof lines === 'object') {
      this.addJournalLine(lines as JournalLineFormItem);
    }
  }

  /**
   * Indicates whether if the notes is required or not.
   * @default `false`.
   */
  @Input() isNotesRequired = false;

  /**
   * Indicates whether if the general cost center display or not.
   * @default `false`.
   */
  @Input() isGeneralCostCenter = false;

  /**
   * Indicates whether if the general cost center display or not.
   * @default `false`.
   */
  @Input() generalCostCenterData = null;

  /**
   * Indicates whether if we can add line or not.
   */
  @Input() allowAddLine = true;

  /**
   * Indicates whether if we can clear lines or not.
   * @default `true`.
   */
  @Input() allowClearLines = true;

  /**
   * Indicates whether if we can remove line or not.
   * @default `true`.
   */
  @Input() allowRemoveLine = true;

  /**
   * Sets a list of account ids for which the cost center id shouldn't be required.
   */
  @Input() accountIdsNotRequireCostCenter: number[] = [];

  /**
   * Indicates whether the sale invoice number column should be visible or not.
   * @default `false`.
   */
  @Input() showSaleInvoiceNumberColumn = false;

  /**
   * Indicates whether the purchase invoice number column should be visible or not.
   * @default `false`.
   */
  @Input() showPurchaseInvoiceNumberColumn = false;

  /**
   * Gets or sets the initial select list journals
   * which will be passed to the child form-controls to allow render journal description.
   */
  initialSelectJournals: Journal[] = [];

  /**
   * Gets the currency rounding difference of the account.
   */
  currencyRoundingDifferenceAccount: Account;

  /**
   * Gets or sets the default cost-center in the organization.
   */
  defaultCostCenter: CostCenter;

  /**
   * Indicates that a drop-down field in the form is opened and therefore the table height should be expanded.
   */
  dropDownIsOpened: boolean;

  /**
   * The set of subscriptions on this components,
   * these subscriptions must be unsubscribed before this component got destroyed.
   */
  subscriptions = new Subscription();

  /**
   * @param authStore$ The auth-store$.
   * @param financesStore$ The finances-store.
   */
  constructor(
    private authStore$: Store<fromAuthStore.AuthState>,
    private financesStore$: Store<fromFinancesStore.FinancesState>
  ) {}

  ngOnInit() {
    this.init();
  }

  /**
   * Destroy component data
   */
  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  /**
   * Initialize component data.
   */
  init() {
    this.initJournalData();

    /**
     * Get default selected cost center.
     */
    this.subscriptions.add(
      this.authStore$
        .pipe(
          select(fromAuthStore.getSessionUser),
          tap((user: User) => {
            if (user) {
              this.defaultCostCenter = user.userPreference.defaultCostCenter;
            }
          })
        )
        .subscribe()
    );
  }

  /**
   * Initialize the journal data.
   */
  initJournalData() {
    /**
     * Fetch system accounts then fill the journal line form with its lines.
     */
    let isManualSearchTriggeredBeforeForCurrencyRoundingDifferenceAccount = false;
    this.subscriptions.add(
      /**
       * Currency rounding difference Account.
       */
      this.financesStore$
        .pipe(
          select(fromFinancesStore.getCurrencyRoundingDifferenceAccount),
          tap((account) => {
            this.currencyRoundingDifferenceAccount = account;

            if (!account && !isManualSearchTriggeredBeforeForCurrencyRoundingDifferenceAccount) {
              isManualSearchTriggeredBeforeForCurrencyRoundingDifferenceAccount = true;
              this.financesStore$.dispatch(new fromFinancesStore.FindCurrencyRoundingDifferenceAccount());
            }
          })
        )
        .subscribe()
    );
  }

  /**
   * Adjusts the balance of total journal lines to be equal.
   */
  adjustJournalBalance() {
    let lastDebitLineIndex = -1;
    let lastCreditLineIndex = -1;

    let totalDebits = new Decimal(0.0);
    let totalCredits = new Decimal(0.0);

    const lines = this.journalLinesForm.value as JournalLineFormItem[];

    /**
     * Loop through all lines to figure out if the credit total and debit total are not equal.
     * Remember the last index of the debit and credit lines.
     * Add a new line to make the journal balanced.
     */
    for (let index = 0; index < lines.length; index++) {
      const line = lines[index];
      const debit = new Decimal(line.debit);
      const credit = new Decimal(line.credit);

      if (debit.gt(0)) {
        lastDebitLineIndex = index;
        totalDebits = Decimal.add(totalDebits, debit);
      } else if (credit.gt(0)) {
        lastCreditLineIndex = index;
        totalCredits = Decimal.add(totalCredits, credit);
      }
    }

    if (this.currencyRoundingDifferenceAccount && !totalDebits.equals(totalCredits)) {
      const isDebitNeed = totalCredits.gt(totalDebits);

      this.addJournalLine(
        {
          accountId: this.currencyRoundingDifferenceAccount.id,
          account: this.currencyRoundingDifferenceAccount,
          costCenterId: null,
          credit: isDebitNeed ? 0 : totalDebits.sub(totalCredits).toNumber(),
          debit: isDebitNeed ? totalCredits.sub(totalDebits).toNumber() : 0,
          notes: '',
          disableAccountChange: true,
          disableDebitAndCreditValueChange: true,
        },
        (isDebitNeed ? lastDebitLineIndex : lastCreditLineIndex) + 1
      );
    }
  }

  /**
   * Creates & adds a new journal line form-group with validations.
   * @param line The journal line details to be bounded to the journal, If omitted a blank journal will be created.
   * @param lineIndex The index in which the new line will be inserted, by default it will be added to the end of the form array.
   */
  addJournalLine(line?: JournalLineFormItem, lineIndex?: number) {
    const journalLineFormGroup = new FormGroup({
      accountId: new FormControl(line?.accountId, Validators.required),
      account: new FormControl(line?.account),
      credit: new FormControl(line?.credit ?? 0, [Validators.required, CustomValidators.gte(0)]),
      debit: new FormControl(line?.debit ?? 0, [Validators.required, CustomValidators.gte(0)]),
      costCenterId: new FormControl(line?.costCenterId ?? this.defaultCostCenter?.id, Validators.required),
      costCenter: new FormControl(line?.costCenter),
      notes: new FormControl(
        line?.notes ?? '',
        this.isNotesRequired
          ? [Validators.required, Validators.minLength(1), Validators.maxLength(200)]
          : [Validators.minLength(0), Validators.maxLength(200)]
      ),
      disableAccountChange: new FormControl(line?.disableAccountChange ?? false),
      disableDebitAndCreditValueChange: new FormControl(line?.disableDebitAndCreditValueChange ?? false),
      disableNotesChange: new FormControl(line?.disableNotesChange ?? false),
      restrictedPrimaryAccountKey: new FormControl(line?.restrictedPrimaryAccountKey ?? ''),
      saleInvoiceId: new FormControl(line?.saleInvoiceId),
      purchaseInvoiceId: new FormControl(line?.purchaseInvoiceId),
    });

    if (lineIndex >= 0) {
      this.journalLinesForm.insert(lineIndex, journalLineFormGroup);
    } else {
      this.journalLinesForm.push(journalLineFormGroup);
    }

    /**
     * Dynamically set line-form-group validations based on the selected account value.
     */
    this.subscriptions.add(
      journalLineFormGroup.controls.debit.valueChanges.subscribe(() =>
        this.onDebitOrCreditValueChange(journalLineFormGroup)
      )
    );
    this.subscriptions.add(
      journalLineFormGroup.controls.credit.valueChanges.subscribe(() =>
        this.onDebitOrCreditValueChange(journalLineFormGroup)
      )
    );

    /**
     * Initialize validations.
     */
    this.onDebitOrCreditValueChange(journalLineFormGroup);
  }

  /**
   * Sets the conditional validation for the line based on the selected account type and debit or credit value.
   * @param journalLineFormGroup The form-group that represents a line.
   */
  onDebitOrCreditValueChange(journalLineFormGroup: FormGroup) {
    if (
      journalLineFormGroup.value.debit === 0 &&
      journalLineFormGroup.value.credit === 0 &&
      this.accountIdsNotRequireCostCenter?.some((accountId) => accountId === journalLineFormGroup.value.accountId)
    ) {
      journalLineFormGroup.get('costCenterId').clearValidators();
    } else {
      journalLineFormGroup.get('costCenterId').setValidators(Validators.required);
    }
    journalLineFormGroup.get('costCenterId').updateValueAndValidity();
  }

  /**
   * Remove the journal-line at the given index from the list.
   * @param index The index of the journal-line to be removed.
   */
  removeJournalLine(index: number) {
    this.journalLinesForm.removeAt(index);
  }

  /**
   * Remove all of the list of journal lines.
   */
  resetJournalLines() {
    this.journalLinesForm.clear();
    this.addJournalLine();
  }
}
