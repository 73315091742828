import { createSelector } from '@ngrx/store';

import { selectLookupsState } from 'lookups/store/selectors/feature.selectors';
import { LookupsState, SaleInvoiceOrderTypeState } from 'lookups/store/states';

/**
 * Gets the sale invoice order type state.
 */
const selectSaleInvoiceOrderTypes = createSelector(
  selectLookupsState,
  (state: LookupsState) => state.saleInvoiceOrderTypes
);

/**
 * Gets the list of loaded sale invoice order types.
 */
export const getSaleInvoiceOrderTypes = createSelector(
  selectSaleInvoiceOrderTypes,
  (state: SaleInvoiceOrderTypeState) => state.data
);

/**
 * Gets the error that occurred in the state.
 */
export const getSaleInvoiceOrderTypesError = createSelector(
  selectSaleInvoiceOrderTypes,
  (state: SaleInvoiceOrderTypeState) => state.error
);

/**
 * Determines if there is a running load process.
 */
export const getSaleInvoiceOrderTypesIsLoading = createSelector(
  selectSaleInvoiceOrderTypes,
  (state: SaleInvoiceOrderTypeState) => state.isLoading
);

/**
 * Determines if the last load process has been ended successfully.
 */
export const getSaleInvoiceOrderTypesIsLoaded = createSelector(
  selectSaleInvoiceOrderTypes,
  (state: SaleInvoiceOrderTypeState) => state.isLoaded
);
