import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import * as fromComponents from 'touch-keyboard/components';
import * as fromDirectives from 'touch-keyboard/directives';

/**
 * Provides a smart touch keyboard.
 */
@NgModule({
  declarations: [...fromComponents.COMPONENTS, ...fromDirectives.DIRECTIVES],
  imports: [CommonModule],
  exports: [...fromComponents.COMPONENTS, ...fromDirectives.DIRECTIVES],
})
export class TouchKeyboardModule {}
