import { findIndex } from 'lodash';

import { SaleInvoicesActionType, SaleInvoicesActions } from 'sales/store/actions';
import { SaleInvoicesState } from 'sales/store/states';

/**
 * The initial state from which the state starts.
 */
const initialState: SaleInvoicesState = {
  data: [],
  UnpaidInvoicesData: [],
  paginationInfo: {
    total: 0,
    page: 1,
    pageSize: 0,
    count: 0,
  },
  unpaidInvoicesPaginationInfo: {
    total: 0,
    page: 1,
    pageSize: 0,
    count: 0,
  },
  error: null,
  isSearching: false,
  isSearchCompleted: false,
  isSearchingUnpaid: false,
  isSearchCompletedUnpaid: false,
  selectedSalesInvoice: null,
  isFinding: false,
  isFindCompleted: false,
  isCreating: false,
  isCreateCompleted: false,
  isCreatingPayment: false,
  isCreatePaymentCompleted: false,
  isChangingMaintenanceStage: false,
  isMaintenanceStageChangeCompleted: false,
};

/**
 * The reducer function that is called in each action dispatch against the state.
 * @param state The current state.
 * @param action The action that will affect the state.
 */
export function saleInvoicesReducer(
  state: SaleInvoicesState = initialState,
  action: SaleInvoicesActions
): SaleInvoicesState {
  switch (action.type) {
    //#region SEARCH_SALE_INVOICES

    case SaleInvoicesActionType.SEARCH_SALE_INVOICES: {
      return {
        ...state,
        error: null,
        isSearching: true,
        isSearchCompleted: false,
      };
    }

    case SaleInvoicesActionType.SEARCH_SALE_INVOICES_FAIL: {
      return {
        ...state,
        error: action.payload,
        isSearching: false,
        isSearchCompleted: false,
      };
    }

    case SaleInvoicesActionType.SEARCH_SALE_INVOICES_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        paginationInfo: {
          ...action.payload.meta,
        },
        error: null,
        isSearching: false,
        isSearchCompleted: true,
      };
    }

    //#endregion SEARCH_SALE_INVOICES

    //#region SEARCH_UNPAID_SALE_INVOICES

    case SaleInvoicesActionType.SEARCH_UNPAID_SALE_INVOICES: {
      return {
        ...state,
        error: null,
        isSearchingUnpaid: true,
        isSearchCompletedUnpaid: false,
      };
    }

    case SaleInvoicesActionType.SEARCH_UNPAID_SALE_INVOICES_FAIL: {
      return {
        ...state,
        error: action.payload,
        isSearchingUnpaid: false,
        isSearchCompletedUnpaid: false,
      };
    }

    case SaleInvoicesActionType.SEARCH_UNPAID_SALE_INVOICES_SUCCESS: {
      return {
        ...state,
        UnpaidInvoicesData: action.payload.data,
        unpaidInvoicesPaginationInfo: {
          ...action.payload.meta,
        },
        error: null,
        isSearchingUnpaid: false,
        isSearchCompletedUnpaid: true,
      };
    }

    //#endregion SEARCH_UNPAID_SALE_INVOICES

    //#region FIND_SALE_INVOICE

    case SaleInvoicesActionType.FIND_SALE_INVOICE: {
      return {
        ...state,
        selectedSalesInvoice: null,
        error: null,
        isFinding: true,
        isFindCompleted: false,
      };
    }

    case SaleInvoicesActionType.FIND_SALE_INVOICE_FAIL: {
      return {
        ...state,
        error: action.payload,
        isFinding: false,
        isFindCompleted: false,
      };
    }

    case SaleInvoicesActionType.FIND_SALE_INVOICE_SUCCESS: {
      let salesInvoices = [...state.data];
      const salesInvoiceIndex = findIndex(salesInvoices, (salesInvoice) => salesInvoice.id === action.payload.data.id);

      /* If salesInvoice was found, update it. */
      if (salesInvoiceIndex >= 0) {
        salesInvoices[salesInvoiceIndex] = action.payload.data;
      } else {
        /* else, insert it to the beginning of the array. */
        salesInvoices = [action.payload.data, ...salesInvoices];
      }

      return {
        ...state,
        data: salesInvoices,
        selectedSalesInvoice: action.payload.data,
        error: null,
        isFinding: false,
        isFindCompleted: true,
      };
    }

    //#endregion FIND_SALE_INVOICE

    //#region CREATE_SALE_INVOICE

    case SaleInvoicesActionType.CREATE_REGULAR_SALE_INVOICE: {
      return {
        ...state,
        error: null,
        isCreating: true,
        isCreateCompleted: false,
      };
    }

    case SaleInvoicesActionType.CREATE_OPENING_BALANCE_INVOICE: {
      return {
        ...state,
        error: null,
        isCreating: true,
        isCreateCompleted: false,
      };
    }

    case SaleInvoicesActionType.CREATE_POS_INVOICE: {
      return {
        ...state,
        error: null,
        isCreating: true,
        isCreateCompleted: false,
      };
    }

    case SaleInvoicesActionType.CREATE_SALE_INVOICE_FAIL: {
      return {
        ...state,
        error: action.payload,
        isCreating: false,
        isCreateCompleted: false,
      };
    }

    case SaleInvoicesActionType.CREATE_SALE_INVOICE_SUCCESS: {
      return {
        ...state,
        data: [action.payload.data, ...state.data],
        selectedSalesInvoice: action.payload.data,
        error: null,
        isCreating: false,
        isCreateCompleted: true,
      };
    }

    //#endregion CREATE_SALE_INVOICE

    //#region CHANGE_SALE_INVOICE_MAINTENANCE_STAGE

    case SaleInvoicesActionType.CHANGE_SALE_INVOICE_MAINTENANCE_STAGE: {
      return {
        ...state,
        error: null,
        isChangingMaintenanceStage: true,
        isMaintenanceStageChangeCompleted: false,
      };
    }

    case SaleInvoicesActionType.CHANGE_SALE_INVOICE_MAINTENANCE_STAGE_FAIL: {
      return {
        ...state,
        error: action.payload,
        isChangingMaintenanceStage: false,
        isMaintenanceStageChangeCompleted: false,
      };
    }

    case SaleInvoicesActionType.CHANGE_SALE_INVOICE_MAINTENANCE_STAGE_SUCCESS: {
      let salesInvoices = [...state.data];
      const salesInvoiceIndex = findIndex(salesInvoices, (salesInvoice) => salesInvoice.id === action.payload.data.id);

      /* If salesInvoice was found, update it. */
      if (salesInvoiceIndex >= 0) {
        salesInvoices[salesInvoiceIndex] = action.payload.data;
      } else {
        /* else, insert it to the beginning of the array. */
        salesInvoices = [action.payload.data, ...salesInvoices];
      }

      return {
        ...state,
        data: salesInvoices,
        selectedSalesInvoice: action.payload.data,
        error: null,
        isChangingMaintenanceStage: false,
        isMaintenanceStageChangeCompleted: true,
      };
    }

    //#endregion CHANGE_SALE_INVOICE_MAINTENANCE_STAGE

    default:
      return state;
  }
}
