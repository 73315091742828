/**
 * The set of system supported asset categories.
 */
export enum AssetCategories {
  LANDS = 'LANDS',
  BUILDINGS = 'BUILDINGS',
  IMPROVEMENTS_TO_RENTED_BUILDINGS = 'IMPROVEMENTS_TO_RENTED_BUILDINGS',
  FURNITURE = 'FURNITURE',
  COMPUTERS = 'COMPUTERS',
  DESKTOP_DEVICES = 'DESKTOP_DEVICES',
  CARS = 'CARS',
  MACHINES_AND_EQUIPMENTS = 'MACHINES_AND_EQUIPMENTS',
}
