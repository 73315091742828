<!-- page header starts -->
<app-page-header [info]="pageInfo"></app-page-header>
<!-- page header ends -->

<!-- taxes list starts -->
<div class="row">
  <div class="col-md-12">
    <div class="card">
      <div class="card-header mb-3">
        <h4 class="card-title">{{ 'SHARED.SEARCH.SEARCH_TOOLS' | translate }}</h4>
      </div>
      <div class="card-content">
        <div class="px-3">
          <div class="row">
            <div class="col-md-12">
              <form class="row" [formGroup]="searchForm">
                <div class="form-group col">
                  <input
                    type="text"
                    class="form-control round"
                    [placeholder]="'SETTINGS.TAXES.TAX_DATA.TAX_SEARCH_PLACEHOLDER' | translate"
                    formControlName="name"
                    (input)="search($event)"
                  />
                </div>
                <div class="col-md-auto form-group d-flex">
                  <button
                    type="button"
                    class="btn btn-raised btn-primary align-self-end round mx-1 mb-0"
                    (click)="initForm(); search()"
                  >
                    <i class="fa fa-refresh"></i>
                  </button>
                  <a
                    *ngIf="[Claims.CREATE_TAX] | authorize | async"
                    routerLink="/settings/taxes/create"
                    class="btn btn-raised btn-success align-self-end round mx-1 mb-0"
                  >
                    <i class="fa fa-plus"></i>
                    <span class="mx-1">{{ 'SHARED.CONFIRM.ADD' | translate }}</span>
                  </a>
                  <app-export-tools
                    *ngIf="(taxes$ | async)?.length"
                    class="d-flex align-self-end mx-1"
                    targetElementId="taxes-table-export-target-element"
                    [outputFileName]="'SHARED.EXPORT_TABLES.TAXES_TABLE' | translate"
                  ></app-export-tools>
                </div>
              </form>
            </div>
            <div class="col-md-12" *ngIf="(taxes$ | async)?.length; else noItems">
              <div class="row">
                <div class="col-md-12 alert alert-light text-center" role="alert">
                  {{ 'SHARED.SEARCH.SEARCH_RESULTS' | translate }} ({{ (paginationInfo$ | async).total | toNumber }})
                  {{ 'SETTINGS.TAXES.TAX' | translate }}
                </div>
                <div class="col-md-12">
                  <div class="table-responsive">
                    <table class="table text-center" id="taxes-table-export-target-element">
                      <thead>
                        <tr>
                          <th scope="col">
                            <i class="fa fa-barcode"></i
                            ><span class="mx-1">{{ 'SETTINGS.TAXES.TAX_DATA.TAX_CODE' | translate }}</span>
                          </th>
                          <th scope="col">{{ 'SETTINGS.TAXES.TAX_DATA.TAX_NAME' | translate }}</th>
                          <th scope="col">
                            {{ 'SETTINGS.TAXES.TAX_DATA.TAX_VALUE' | translate
                            }}<span class="ml-1">(<i class="fa fa-percent"></i>)</span>
                          </th>
                          <th scope="col">
                            {{ 'SETTINGS.TAXES.TAX_DATA.IS_TAX_DEFAULT' | translate }}
                          </th>
                          <th scope="col">{{ 'SHARED.TOOLS' | translate }}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let tax of taxes$ | async; trackBy: trackItems">
                          <th scope="row">{{ tax.id }}</th>
                          <td>{{ tax.name | localize: tax.nameEn | async }}</td>
                          <td>
                            {{ tax.value | toDecimal }}<span class="ml-1"><i class="fa fa-percent"></i></span>
                          </td>
                          <td>
                            <app-status
                              [status]="tax.isDefault"
                              [activeText]="'SETTINGS.TAXES.TAX_DATA.TAX_DEFAULT_VALUE' | translate"
                              *ngIf="tax.isDefault"
                            >
                            </app-status>
                          </td>
                          <td>
                            <a
                              *ngIf="[Claims.UPDATE_TAX] | authorize | async"
                              class="info p-0"
                              [routerLink]="['/settings/taxes/update', tax.id]"
                              [ngbTooltip]="'SETTINGS.TAXES.TAX_DATA.UPDATE_TAX_DATA' | translate"
                            >
                              <i class="fa fa-edit font-medium-3 mr-2"></i>
                            </a>
                            <a
                              *ngIf="[Claims.DELETE_TAX] | authorize | async"
                              class="danger p-0"
                              [ngbTooltip]="'SETTINGS.TAXES.TAX_DATA.DELETE_TAX' | translate"
                              (click)="selectedTax = tax; openModal(deleteModalRef)"
                            >
                              <i class="fa fa-close font-medium-3 mr-2"></i>
                            </a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div class="d-flex justify-content-between p-2">
                      <ngb-pagination
                        *ngIf="paginationInfo$ | async; let pagination"
                        maxSize="5"
                        [collectionSize]="pagination.total"
                        [pageSize]="pagination.pageSize"
                        [page]="pagination.page"
                        (pageChange)="pageChanged($event)"
                        boundaryLinks="true"
                      >
                        <ng-template ngbPaginationFirst>{{
                          'SHARED.PAGINATION.PAGINATION_FIRST' | translate
                        }}</ng-template>
                        <ng-template ngbPaginationPrevious>{{
                          'SHARED.PAGINATION.PAGINATION_PREVIOUS' | translate
                        }}</ng-template>
                        <ng-template ngbPaginationNext>{{
                          'SHARED.PAGINATION.PAGINATION_NEXT' | translate
                        }}</ng-template>
                        <ng-template ngbPaginationLast>{{
                          'SHARED.PAGINATION.PAGINATION_LAST' | translate
                        }}</ng-template>
                      </ngb-pagination>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- taxes list ends -->

<!-- no items template starts -->
<ng-template #noItems>
  <div class="col-md-12 alert alert-warning mt-3 text-center" role="alert">
    {{ 'SETTINGS.TAXES.SEARCH_RESULTS_NOT_FOUND' | translate }}
    <a
      *ngIf="[Claims.CREATE_TAX] | authorize | async"
      routerLink="/settings/taxes/create"
      class="btn btn-raised btn-success round"
    >
      <i class="fa fa-plus"></i>
      <span class="mx-1">{{ 'SHARED.CONFIRM.ADD' | translate }}</span>
    </a>
  </div>
</ng-template>
<!-- no items template ends -->

<!-- delete tax modal starts -->
<ng-template #deleteModalRef let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title text-danger" id="modal-basic-title">
      {{ 'SETTINGS.TAXES.CONFIRM_DELETE_TAX_TITLE' | translate }}
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="d()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-12">
        <p class="text-danger">{{ 'SETTINGS.TAXES.CONFIRM_DELETE_TAX_MESSAGE' | translate }}</p>
      </div>
      <div class="form-group col-6">
        <label for="selectedTax.name">{{ 'SETTINGS.TAXES.TAX_DATA.TAX_NAME' | translate }}</label>
        <input
          type="text"
          id="selectedTax.name"
          class="form-control round"
          [placeholder]="'SETTINGS.TAXES.TAX_DATA.TAX_NAME_PLACEHOLDER' | translate"
          readonly
          [value]="selectedTax.name | localize: selectedTax.nameEn | async"
        />
      </div>
      <div class="form-group col-6">
        <label for="selectedTax.value">{{ 'SETTINGS.TAXES.TAX_DATA.TAX_VALUE' | translate }}</label>
        <input
          type="number"
          id="selectedTax.value"
          class="form-control round"
          [placeholder]="'SETTINGS.TAXES.TAX_DATA.TAX_VALUE_PLACEHOLDER' | translate"
          readonly
          [value]="selectedTax.value"
        />
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="modal-actions">
      <button
        type="button"
        class="btn btn-raised btn-danger round"
        [disabled]="isDeleting$ | async"
        (click)="c(); delete(selectedTax.id)"
        ngbAutofocus
      >
        <i class="fa fa-check"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.DELETE' | translate }}</span>
      </button>
      <button type="button" class="btn btn-outline-dark round" (click)="c()">
        <i class="fa fa-times"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.CANCEL' | translate }}</span>
      </button>
    </div>
  </div>
</ng-template>
<!-- delete tax modal ends -->
