<!-- payroll payments list starts -->
<div class="row">
  <div class="col-md-12">
    <div class="card">
      <div class="card-content">
        <div class="px-3">
          <div class="row">
            <div class="col-md-12">
              <form class="row" [formGroup]="searchForm" (ngSubmit)="searchPayrollPayments()">
                <div class="form-group col-md-4">
                  <label for="employeePaymentMethods">{{
                    'FINANCES.HR.PAYROLL_PAYMENTS.PAYROLL_PAYMENTS_DATA.PAYMENT_METHODS' | translate
                  }}</label>
                  <ng-select
                    class="round"
                    formControlName="employeePaymentMethods"
                    [multiple]="true"
                    [closeOnSelect]="false"
                    labelForId="employeePaymentMethods"
                    searchable="true"
                    [placeholder]="
                      'FINANCES.HR.PAYROLL_PAYMENTS.PAYROLL_PAYMENTS_DATA.PAYMENT_METHODS_PLACEHOLDER' | translate
                    "
                    [notFoundText]="'SHARED.SEARCH.SEARCH_NOT_FOUND' | translate"
                  >
                    <ng-option
                      *ngFor="let employeePaymentMethod of employeePaymentMethods$ | async"
                      [value]="employeePaymentMethod.id"
                    >
                      {{ employeePaymentMethod.name | localize: employeePaymentMethod.nameEn | async }}
                    </ng-option>
                  </ng-select>
                </div>
                <div class="col-md-8">
                  <div class="row">
                    <div class="form-group col-12 col-sm-6 mb-0">
                      <label for="fromDate">{{ 'SHARED.SEARCH.FROM_DATE' | translate }}</label>
                      <div class="input-group round">
                        <input
                          class="form-control"
                          id="fromDate"
                          name="fromDate"
                          formControlName="fromDate"
                          [placeholder]="'SHARED.SEARCH.DATE_PLACEHOLDER' | translate"
                          ngbDatepicker
                          #fromDate="ngbDatepicker"
                        />
                        <div class="input-group-append">
                          <span
                            class="input-group-text gradient-light-blue-indigo"
                            style="cursor: pointer;"
                            (click)="fromDate.toggle()"
                          >
                            <i class="fa fa-calendar"></i>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="form-group col-12 col-sm-6 mb-0">
                      <label for="toDate">{{ 'SHARED.SEARCH.TO_DATE' | translate }}</label>
                      <div class="input-group round">
                        <input
                          class="form-control"
                          id="toDate"
                          name="toDate"
                          formControlName="toDate"
                          [placeholder]="'SHARED.SEARCH.DATE_PLACEHOLDER' | translate"
                          ngbDatepicker
                          #toDate="ngbDatepicker"
                        />
                        <div class="input-group-append">
                          <span
                            class="input-group-text gradient-light-blue-indigo"
                            style="cursor: pointer;"
                            (click)="toDate.toggle()"
                          >
                            <i class="fa fa-calendar"></i>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-auto form-group d-flex">
                  <button
                    type="submit"
                    class="btn btn-raised btn-primary align-self-end round mx-1 mb-0"
                    [disabled]="isSearching$ | async"
                  >
                    <i class="fa fa-search"></i>
                  </button>
                  <a
                    routerLink="/finances/hr/payroll-payment"
                    class="btn btn-raised btn-success align-self-end round mx-1 mb-0"
                  >
                    <i class="fa fa-plus"></i>
                    <span class="mx-1">{{ 'SHARED.CONFIRM.PAY' | translate }}</span>
                  </a>

                  <app-export-tools
                    *ngIf="(payrollPayments$ | async)?.length"
                    class="d-flex align-self-end mx-1"
                    targetElementId="payroll-payments-table-export-target-element"
                    [outputFileName]="'SHARED.EXPORT_TABLES.PAYROLLS_TABLE' | translate"
                  ></app-export-tools>
                </div>
              </form>
            </div>
            <div class="col-md-12" *ngIf="(payrollPayments$ | async)?.length; else noItems">
              <div class="row">
                <div class="col-md-12 alert alert-light text-center" role="alert">
                  {{ 'SHARED.SEARCH.SEARCH_RESULTS' | translate }} ({{ (paginationInfo$ | async).total | toNumber }})
                  {{ 'FINANCES.HR.PAID_PAYROLLS.PAID_PAYROLLS_DATA.PAYROLL_PAYMENT' | translate }}
                </div>
                <div class="col-md-12">
                  <div class="table-responsive">
                    <table class="table text-center" id="payroll-payments-table-export-target-element">
                      <thead>
                        <tr>
                          <th scope="col"><i class="fa fa-list-ol"></i></th>
                          <th scope="col">{{ 'FINANCES.HR.PAID_PAYROLLS.PAID_PAYROLLS_DATA.PAID_WAY' | translate }}</th>
                          <th scope="col">
                            {{ 'FINANCES.HR.PAID_PAYROLLS.PAID_PAYROLLS_DATA.PAID_VALUE' | translate }}
                          </th>
                          <th scope="col">{{ 'FINANCES.HR.PAID_PAYROLLS.PAID_PAYROLLS_DATA.NOTES' | translate }}</th>
                          <th scope="col">
                            {{ 'FINANCES.HR.PAID_PAYROLLS.PAID_PAYROLLS_DATA.TRANSACTION_DATE' | translate }}
                          </th>
                          <th scope="col">
                            {{ 'FINANCES.HR.PAID_PAYROLLS.PAID_PAYROLLS_DATA.CREATED_AT' | translate }}
                          </th>
                          <th scope="col">
                            {{ 'FINANCES.HR.PAID_PAYROLLS.PAID_PAYROLLS_DATA.USER' | translate }}
                          </th>
                          <th scope="col">{{ 'SHARED.TOOLS' | translate }}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          *ngFor="
                            let payrollPayment of payrollPayments$ | async;
                            let index = index;
                            trackBy: trackItems
                          "
                        >
                          <th scope="row">{{ index + 1 }}</th>
                          <td>
                            {{
                              payrollPayment.employeePaymentMethod.name
                                | localize: payrollPayment.employeePaymentMethod.nameEn
                                | async
                            }}
                          </td>
                          <td>{{ payrollPayment.value | toDecimal }}</td>
                          <td>{{ payrollPayment.notes }}</td>
                          <td>
                            <span [ngbTooltip]="payrollPayment.transactionDate | date: 'time'">
                              {{ payrollPayment.transactionDate | date }}
                            </span>
                          </td>
                          <td>
                            <span [ngbTooltip]="payrollPayment.createdAt | date: 'time'">
                              {{ payrollPayment.createdAt | date }}
                            </span>
                          </td>
                          <td>{{ payrollPayment.user.name }}</td>
                          <td>
                            <a
                              class="info p-0"
                              [routerLink]="['/finances/hr/payroll-payments/view', payrollPayment.id]"
                              [ngbTooltip]="
                                'FINANCES.HR.PAID_PAYROLLS.PAID_PAYROLLS_DATA.DISPLAY_DETAILS_PAYROLL_PAYMENT'
                                  | translate
                              "
                            >
                              <i class="fa fa-eye font-medium-3 mr-2"></i>
                            </a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div class="d-flex justify-content-between p-2">
                      <ngb-pagination
                        *ngIf="paginationInfo$ | async; let pagination"
                        maxSize="5"
                        [collectionSize]="pagination.total"
                        [pageSize]="pagination.pageSize"
                        [page]="pagination.page"
                        (pageChange)="pageChanged($event)"
                        boundaryLinks="true"
                      >
                        <ng-template ngbPaginationFirst>{{
                          'SHARED.PAGINATION.PAGINATION_FIRST' | translate
                        }}</ng-template>
                        <ng-template ngbPaginationFirst>{{
                          'SHARED.PAGINATION.PAGINATION_PREVIOUS' | translate
                        }}</ng-template>
                        <ng-template ngbPaginationFirst>{{
                          'SHARED.PAGINATION.PAGINATION_NEXT' | translate
                        }}</ng-template>
                        <ng-template ngbPaginationFirst>{{
                          'SHARED.PAGINATION.PAGINATION_LAST' | translate
                        }}</ng-template>
                      </ngb-pagination>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- payroll payments list ends -->

<!-- no items template starts -->
<ng-template #noItems>
  <div class="col-md-12 alert alert-warning mt-3 text-center" role="alert">
    {{ 'FINANCES.HR.PAID_PAYROLLS.PAID_PAYROLLS_DATA.SEARCH_PAYROLL_PAYMENTS_NOT_FOUND' | translate }}
  </div>
</ng-template>
<!-- no items template ends -->
