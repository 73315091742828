import { Injectable } from '@angular/core';

import { switchMap, map, catchError, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { Effect, ofType, Actions } from '@ngrx/effects';

import { NotificationService, TranslationService } from 'shared/services';
import { StatusCode } from 'shared/models';
import { FinancesReportsService } from 'reports/services';
import {
  FinancesReportsActionType,
  GetAccountAnalysisReport,
  GetAccountAnalysisReportFail,
  GetAccountAnalysisReportReceived,
  GetAccountAnalysisReportSuccess,
  GetBalanceSheetReport,
  GetBalanceSheetReportReceived,
  GetBalanceSheetReportFail,
  GetBalanceSheetReportSuccess,
  GetBankAccountSummaryReport,
  GetBankAccountSummaryReportReceived,
  GetBankAccountSummaryReportFail,
  GetBankAccountSummaryReportSuccess,
  GetIncomeStatementReport,
  GetIncomeStatementReportReceived,
  GetIncomeStatementReportFail,
  GetIncomeStatementReportSuccess,
  GetJournalsReport,
  GetJournalsReportFail,
  GetJournalsReportReceived,
  GetJournalsReportSuccess,
  GetTaxesReport,
  GetTaxesReportFail,
  GetTaxesReportReceived,
  GetTaxesReportSuccess,
  GetTrialBalanceReport,
  GetTrialBalanceReportFail,
  GetTrialBalanceReportReceived,
  GetTrialBalanceReportSuccess,
} from 'reports/store/actions';

@Injectable()
export class FinancesReportsEffects {
  constructor(
    private actions$: Actions,
    private reportsService: FinancesReportsService,
    private notificationService: NotificationService,
    private translationService: TranslationService
  ) {}

  /* ========================= GET_ACCOUNT_ANALYSIS_REPORT =================================== */
  @Effect()
  accountAnalysis$ = this.actions$.pipe(
    ofType(FinancesReportsActionType.GET_ACCOUNT_ANALYSIS_REPORT),
    switchMap(({ payload }: GetAccountAnalysisReport) =>
      this.reportsService
        .getAccountAnalysisReport(payload.accountId, payload.costCenters, payload.fromDate, payload.toDate)
        .pipe(
          map((response) => new GetAccountAnalysisReportReceived(response)),
          catchError((error) => of(new GetAccountAnalysisReportFail(error)))
        )
    )
  );

  @Effect({ dispatch: false })
  accountAnalysisReceived$ = this.actions$.pipe(
    ofType(FinancesReportsActionType.GET_ACCOUNT_ANALYSIS_REPORT_RECEIVED),
    tap(({ payload: { data } }: GetAccountAnalysisReportReceived) => {
      this.notificationService.info(data);
    })
  );

  @Effect({ dispatch: false })
  accountAnalysisSuccess$ = this.actions$.pipe(
    ofType(FinancesReportsActionType.GET_ACCOUNT_ANALYSIS_REPORT_SUCCESS),
    tap((action: GetAccountAnalysisReportSuccess) => {
      this.notificationService.success(
        this.translationService.translate('REPORTS.NOTIFICATION_MESSAGE.REPORT_IS_READY')
      );
    })
  );

  @Effect({ dispatch: false })
  accountAnalysisFail$ = this.actions$.pipe(
    ofType(FinancesReportsActionType.GET_ACCOUNT_ANALYSIS_REPORT_FAIL),
    tap((action: GetAccountAnalysisReportFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );

  /* ========================= GET_BALANCE_SHEET_REPORT =================================== */
  @Effect()
  balanceSheet$ = this.actions$.pipe(
    ofType(FinancesReportsActionType.GET_BALANCE_SHEET_REPORT),
    switchMap(() =>
      this.reportsService.getBalanceSheetReport().pipe(
        map((response) => new GetBalanceSheetReportReceived(response)),
        catchError((error) => of(new GetBalanceSheetReportFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  balanceSheetReceived$ = this.actions$.pipe(
    ofType(FinancesReportsActionType.GET_BALANCE_SHEET_REPORT_RECEIVED),
    tap(({ payload: { data } }: GetBalanceSheetReportReceived) => {
      this.notificationService.info(data);
    })
  );

  @Effect({ dispatch: false })
  balanceSheetSuccess$ = this.actions$.pipe(
    ofType(FinancesReportsActionType.GET_BALANCE_SHEET_REPORT_SUCCESS),
    tap((action: GetBalanceSheetReportSuccess) => {
      this.notificationService.success(
        this.translationService.translate('REPORTS.NOTIFICATION_MESSAGE.REPORT_IS_READY')
      );
    })
  );

  @Effect({ dispatch: false })
  balanceSheetFail$ = this.actions$.pipe(
    ofType(FinancesReportsActionType.GET_BALANCE_SHEET_REPORT_FAIL),
    tap((action: GetBalanceSheetReportFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );

  /* ========================= GET_BANK_ACCOUNT_SUMMARY_REPORT =================================== */
  @Effect()
  bankAccountSummary$ = this.actions$.pipe(
    ofType(FinancesReportsActionType.GET_BANK_ACCOUNT_SUMMARY_REPORT),
    switchMap(({ payload }: GetBankAccountSummaryReport) =>
      this.reportsService.getBankAccountSummaryReport(payload.bankAccountId, payload.fromDate, payload.toDate).pipe(
        map((response) => new GetBankAccountSummaryReportReceived(response)),
        catchError((error) => of(new GetBankAccountSummaryReportFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  bankAccountSummaryReceived$ = this.actions$.pipe(
    ofType(FinancesReportsActionType.GET_BANK_ACCOUNT_SUMMARY_REPORT_RECEIVED),
    tap(({ payload: { data } }: GetBankAccountSummaryReportReceived) => {
      this.notificationService.info(data);
    })
  );

  @Effect({ dispatch: false })
  bankAccountSummarySuccess$ = this.actions$.pipe(
    ofType(FinancesReportsActionType.GET_BANK_ACCOUNT_SUMMARY_REPORT_SUCCESS),
    tap((action: GetBankAccountSummaryReportSuccess) => {
      this.notificationService.success(
        this.translationService.translate('REPORTS.NOTIFICATION_MESSAGE.REPORT_IS_READY')
      );
    })
  );

  @Effect({ dispatch: false })
  bankAccountSummaryFail$ = this.actions$.pipe(
    ofType(FinancesReportsActionType.GET_BANK_ACCOUNT_SUMMARY_REPORT_FAIL),
    tap((action: GetBankAccountSummaryReportFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );

  /* ========================= GET_INCOME_STATEMENT_REPORT =================================== */
  @Effect()
  incomeStatement$ = this.actions$.pipe(
    ofType(FinancesReportsActionType.GET_INCOME_STATEMENT_REPORT),
    switchMap(({ payload }: GetIncomeStatementReport) =>
      this.reportsService.getIncomeStatementReport(payload.costCenters, payload.fromDate, payload.toDate).pipe(
        map((response) => new GetIncomeStatementReportReceived(response)),
        catchError((error) => of(new GetIncomeStatementReportFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  incomeStatementReceived$ = this.actions$.pipe(
    ofType(FinancesReportsActionType.GET_INCOME_STATEMENT_REPORT_RECEIVED),
    tap(({ payload: { data } }: GetIncomeStatementReportReceived) => {
      this.notificationService.info(data);
    })
  );

  @Effect({ dispatch: false })
  incomeStatementSuccess$ = this.actions$.pipe(
    ofType(FinancesReportsActionType.GET_INCOME_STATEMENT_REPORT_SUCCESS),
    tap((action: GetIncomeStatementReportSuccess) => {
      this.notificationService.success(
        this.translationService.translate('REPORTS.NOTIFICATION_MESSAGE.REPORT_IS_READY')
      );
    })
  );

  @Effect({ dispatch: false })
  incomeStatementFail$ = this.actions$.pipe(
    ofType(FinancesReportsActionType.GET_INCOME_STATEMENT_REPORT_FAIL),
    tap((action: GetIncomeStatementReportFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );

  /* ========================= GET_JOURNALS_REPORT =================================== */
  @Effect()
  journals$ = this.actions$.pipe(
    ofType(FinancesReportsActionType.GET_JOURNALS_REPORT),
    switchMap(({ payload }: GetJournalsReport) =>
      this.reportsService.getJournalsReport(payload.journalTypes, payload.fromDate, payload.toDate).pipe(
        map((response) => new GetJournalsReportReceived(response)),
        catchError((error) => of(new GetJournalsReportFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  journalsReceived$ = this.actions$.pipe(
    ofType(FinancesReportsActionType.GET_JOURNALS_REPORT_RECEIVED),
    tap(({ payload: { data } }: GetJournalsReportReceived) => {
      this.notificationService.info(data);
    })
  );

  @Effect({ dispatch: false })
  journalsSuccess$ = this.actions$.pipe(
    ofType(FinancesReportsActionType.GET_JOURNALS_REPORT_SUCCESS),
    tap((action: GetJournalsReportSuccess) => {
      this.notificationService.success(
        this.translationService.translate('REPORTS.NOTIFICATION_MESSAGE.REPORT_IS_READY')
      );
    })
  );

  @Effect({ dispatch: false })
  journalsFail$ = this.actions$.pipe(
    ofType(FinancesReportsActionType.GET_JOURNALS_REPORT_FAIL),
    tap((action: GetJournalsReportFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );

  /* ========================= GET_TAXES_REPORT =================================== */
  @Effect()
  taxes$ = this.actions$.pipe(
    ofType(FinancesReportsActionType.GET_TAXES_REPORT),
    switchMap(({ payload }: GetTaxesReport) =>
      this.reportsService.getTaxesReport(payload.costCenters, payload.fromDate, payload.toDate).pipe(
        map((response) => new GetTaxesReportReceived(response)),
        catchError((error) => of(new GetTaxesReportFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  taxesReceived$ = this.actions$.pipe(
    ofType(FinancesReportsActionType.GET_TAXES_REPORT_RECEIVED),
    tap(({ payload: { data } }: GetTaxesReportReceived) => {
      this.notificationService.info(data);
    })
  );

  @Effect({ dispatch: false })
  taxesSuccess$ = this.actions$.pipe(
    ofType(FinancesReportsActionType.GET_TAXES_REPORT_SUCCESS),
    tap((action: GetTaxesReportSuccess) => {
      this.notificationService.success(
        this.translationService.translate('REPORTS.NOTIFICATION_MESSAGE.REPORT_IS_READY')
      );
    })
  );

  @Effect({ dispatch: false })
  taxesFail$ = this.actions$.pipe(
    ofType(FinancesReportsActionType.GET_TAXES_REPORT_FAIL),
    tap((action: GetTaxesReportFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );

  /* ========================= GET_TRIAL_BALANCE_REPORT =================================== */
  @Effect()
  trialBalance$ = this.actions$.pipe(
    ofType(FinancesReportsActionType.GET_TRIAL_BALANCE_REPORT),
    switchMap(({ payload }: GetTrialBalanceReport) =>
      this.reportsService
        .getTrialBalanceReport(payload.accounts, payload.costCenters, payload.fromDate, payload.toDate)
        .pipe(
          map((response) => new GetTrialBalanceReportReceived(response)),
          catchError((error) => of(new GetTrialBalanceReportFail(error)))
        )
    )
  );

  @Effect({ dispatch: false })
  trialBalanceReceived$ = this.actions$.pipe(
    ofType(FinancesReportsActionType.GET_TRIAL_BALANCE_REPORT_RECEIVED),
    tap(({ payload: { data } }: GetTrialBalanceReportReceived) => {
      this.notificationService.info(data);
    })
  );

  @Effect({ dispatch: false })
  trialBalanceSuccess$ = this.actions$.pipe(
    ofType(FinancesReportsActionType.GET_TRIAL_BALANCE_REPORT_SUCCESS),
    tap((action: GetTrialBalanceReportSuccess) => {
      this.notificationService.success(
        this.translationService.translate('REPORTS.NOTIFICATION_MESSAGE.REPORT_IS_READY')
      );
    })
  );

  @Effect({ dispatch: false })
  trialBalanceFail$ = this.actions$.pipe(
    ofType(FinancesReportsActionType.GET_TRIAL_BALANCE_REPORT_FAIL),
    tap((action: GetTrialBalanceReportFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );
}
