<!-- casher devices list starts -->
<div class="row">
  <div class="col-md-12">
    <div class="card m-0">
      <div class="card-header mb-3">
        <h4 class="card-title">{{ 'SHARED.SEARCH.SEARCH_TOOLS' | translate }}</h4>
      </div>
      <div class="card-content">
        <div class="px-3">
          <div class="row">
            <div class="col-md-12">
              <form class="row" [formGroup]="searchForm" (ngSubmit)="search()">
                <div class="form-group col">
                  <label for="posPrinters">{{ 'SALES.KITCHEN_PRINTERS.POS_PRINTERS' | translate }}</label>
                  <ng-select
                    class="round"
                    formControlName="posPrinters"
                    [multiple]="true"
                    [closeOnSelect]="false"
                    labelForId="posPrinters"
                    searchable="true"
                    [placeholder]="'SALES.KITCHEN_PRINTERS.POS_PRINTERS_PLACEHOLDER' | translate"
                    [notFoundText]="'SHARED.SEARCH.SEARCH_NOT_FOUND' | translate"
                    (open)="posPrintersListVisibility = true"
                  >
                    <ng-option *ngFor="let posPrinter of posPrinters" [value]="posPrinter.id">
                      {{ posPrinter.name }}
                    </ng-option>
                  </ng-select>
                </div>
                <div class="form-group col">
                  <label for="posDevices">{{ 'SALES.POS_PRINTERS.POS_DEVICES' | translate }}</label>
                  <ng-select
                    class="round"
                    formControlName="posDevices"
                    [multiple]="true"
                    [closeOnSelect]="false"
                    labelForId="posDevices"
                    searchable="true"
                    [placeholder]="'SALES.POS_PRINTERS.POS_DEVICES_PLACEHOLDER' | translate"
                    [notFoundText]="'SHARED.SEARCH.SEARCH_NOT_FOUND' | translate"
                    (open)="posDevicesListVisibility = true"
                  >
                    <ng-option *ngFor="let posDevice of posDevices" [value]="posDevice.id">
                      {{ posDevice.name | localize: posDevice.nameEn | async }}
                    </ng-option>
                  </ng-select>
                </div>
                <div class="form-group col">
                  <label for="locations">{{ 'SALES.POS_DEVICES.LOCATIONS' | translate }}</label>
                  <ng-select
                    class="round"
                    formControlName="locations"
                    [multiple]="true"
                    [closeOnSelect]="false"
                    labelForId="locations"
                    searchable="true"
                    [placeholder]="'SALES.POS_DEVICES.LOCATIONS_PLACEHOLDER' | translate"
                    [notFoundText]="'SHARED.SEARCH.SEARCH_NOT_FOUND' | translate"
                    (open)="locationsListVisibility = true"
                  >
                    <ng-option *ngFor="let location of locations" [value]="location.id">
                      {{ location.name | localize: location.nameEn | async }}
                    </ng-option>
                  </ng-select>
                </div>
                <div class="col-md-auto form-group d-flex">
                  <button
                    type="submit"
                    class="btn btn-raised btn-primary align-self-end round mx-1 mb-0"
                    [disabled]="isSearching$ | async"
                  >
                    <i class="fa fa-search"></i>
                  </button>
                  <a
                    *ngIf="[claims.CREATE_POS_DEVICE] | authorize | async"
                    routerLink="/sales/casher-printers/create"
                    class="btn btn-raised btn-success align-self-end round mx-1 mb-0"
                  >
                    <i class="fa fa-plus"></i>
                    <span class="mx-1">{{ 'SHARED.CONFIRM.ADD' | translate }}</span>
                  </a>
                  <app-export-tools
                    *ngIf="(posPrinterAdjustments$ | async)?.length"
                    class="d-flex align-self-end mx-1"
                    targetElementId="casher-printers-table-export-target-element"
                    [outputFileName]="'SHARED.EXPORT_TABLES.CASHER_PRINTERS_TABLE' | translate"
                  ></app-export-tools>
                </div>
              </form>
            </div>
            <div class="col-md-12" *ngIf="(posPrinterAdjustments$ | async)?.length; else noItems">
              <div class="row">
                <div class="col-md-12 alert alert-light text-center" role="alert">
                  {{ 'SHARED.SEARCH.SEARCH_RESULTS' | translate }} ({{ (paginationInfo$ | async).total | toNumber }})
                  {{ 'SALES.POS_PRINTERS.PRINTER' | translate }}
                </div>
                <div class="col-md-12">
                  <div class="table-responsive">
                    <table class="table text-center" id="casher-printers-table-export-target-element">
                      <thead>
                        <tr>
                          <th scope="col"><i class="fa fa-list-ol"></i></th>
                          <th scope="col">{{ 'SALES.POS_PRINTERS.POS_PRINTER_NAME' | translate }}</th>
                          <th scope="col">
                            {{ 'SALES.POS_PRINTERS.POS_PRINTER_IP' | translate }}
                          </th>
                          <th scope="col">
                            {{ 'SALES.POS_DEVICES.POS_DEVICE_NAME' | translate }}
                          </th>
                          <th scope="col">
                            {{ 'SALES.POS_DEVICES.LOCATION' | translate }}
                          </th>
                          <th scope="col">{{ 'SHARED.TOOLS' | translate }}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          *ngFor="
                            let posPrinterAdjustment of posPrinterAdjustments$ | async;
                            trackBy: trackItems;
                            let i = index
                          "
                        >
                          <th scope="row">{{ i + 1 }}</th>
                          <td>
                            {{ posPrinterAdjustment.posPrinter.name }}
                          </td>
                          <td>
                            {{ posPrinterAdjustment.posPrinter.ip }}
                          </td>
                          <td>
                            {{
                              posPrinterAdjustment.posPrinter.posDevice.name
                                | localize: posPrinterAdjustment.posPrinter.posDevice.nameEn
                                | async
                            }}
                          </td>
                          <td>
                            {{
                              posPrinterAdjustment.posPrinter.posDevice.location.name
                                | localize: posPrinterAdjustment.posPrinter.posDevice.location.nameEn
                                | async
                            }}
                          </td>
                          <td>
                            <a
                              *ngIf="[claims.UPDATE_POS_DEVICE] | authorize | async"
                              class="info p-0"
                              [routerLink]="['/sales/casher-printers/update', posPrinterAdjustment.id]"
                              [ngbTooltip]="'SALES.CASHER_PRINTERS.UPDATE_CASHER_PRINTER_DATA' | translate"
                            >
                              <i class="fa fa-edit font-medium-3 mr-2"></i>
                            </a>
                            <a
                              *ngIf="[claims.DELETE_POS_DEVICE] | authorize | async"
                              class="danger p-0"
                              [ngbTooltip]="'SHARED.CONFIRM.DELETE' | translate"
                              (click)="selectedPosPrinterAdjustment = posPrinterAdjustment; openModal(deleteModalRef)"
                            >
                              <i class="fa fa-close font-medium-3 mr-2"></i>
                            </a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div class="d-flex justify-content-between p-2">
                      <ngb-pagination
                        *ngIf="paginationInfo$ | async; let pagination"
                        maxSize="5"
                        [collectionSize]="pagination.total"
                        [pageSize]="pagination.pageSize"
                        [page]="pagination.page"
                        (pageChange)="pageChanged($event)"
                        boundaryLinks="true"
                      >
                        <ng-template ngbPaginationFirst>{{
                          'SHARED.PAGINATION.PAGINATION_FIRST' | translate
                        }}</ng-template>
                        <ng-template ngbPaginationFirst>{{
                          'SHARED.PAGINATION.PAGINATION_PREVIOUS' | translate
                        }}</ng-template>
                        <ng-template ngbPaginationFirst>{{
                          'SHARED.PAGINATION.PAGINATION_NEXT' | translate
                        }}</ng-template>
                        <ng-template ngbPaginationFirst>{{
                          'SHARED.PAGINATION.PAGINATION_LAST' | translate
                        }}</ng-template>
                      </ngb-pagination>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- pos printers list ends -->

<!-- no items template starts -->
<ng-template #noItems>
  <div class="col-md-12 alert alert-warning mt-3 text-center" role="alert">
    {{ 'SALES.CASHER_PRINTERS.SEARCH_CASHER_PRINTER_NOT_FOUND' | translate }}
    <a
      *ngIf="[claims.CREATE_POS_DEVICE] | authorize | async"
      routerLink="/sales/casher-printers/create"
      class="btn btn-raised btn-success round"
    >
      <i class="fa fa-plus"></i>
      <span class="mx-1">{{ 'SHARED.CONFIRM.ADD' | translate }}</span>
    </a>
  </div>
</ng-template>
<!-- no items template ends -->

<!-- delete pos printers modal starts -->
<ng-template #deleteModalRef let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title text-danger" id="modal-basic-title">
      {{ 'SALES.CASHER_PRINTERS.CONFIRM_DELETE_CASHER_PRINTER_TITLE' | translate }}
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="d()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-12">
        <p class="text-danger">
          {{ 'SALES.CASHER_PRINTERS.CONFIRM_DELETE_CASHER_PRINTER_MESSAGE' | translate }}
        </p>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="modal-actions">
      <button
        type="button"
        class="btn btn-raised btn-danger round"
        (click)="c(); delete(selectedPosPrinterAdjustment.id)"
        ngbAutofocus
        [disabled]="isDeleting$ | async"
      >
        <i class="fa fa-check"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.DELETE' | translate }}</span>
      </button>
      <button type="button" class="btn btn-outline-dark round" (click)="c()">
        <i class="fa fa-times"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.CANCEL' | translate }}</span>
      </button>
    </div>
  </div>
</ng-template>
<!-- delete pos device modal ends -->

<!-- pos device classes search starts -->
<app-pos-printers-search (posPrinter)="selectPosPrinters($event)" [(visible)]="posPrintersListVisibility">
</app-pos-printers-search>
<!-- pos device classes search ends -->

<!-- pos device classes search starts -->
<app-pos-devices-search onlyActive="true" (posDevice)="selectPosDevice($event)" [(visible)]="posDevicesListVisibility">
</app-pos-devices-search>
<!-- pos device classes search ends -->

<!-- pos device classes search starts -->
<app-locations-search onlyActive="true" (locations)="selectLocations($event)" [(visible)]="locationsListVisibility">
</app-locations-search>
<!-- pos device classes search ends -->
