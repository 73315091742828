import { Action } from '@ngrx/store';

import { PhoneType } from 'lookups/models';
import { AppHttpResponse } from 'shared';

/**
 * The Lookups-Module store phone type action types.
 */
export enum PhoneTypeActionType {
  GET_ALL_PHONE_TYPES = '[Lookups] [PhoneType] Get All Phone Types',
  GET_ALL_PHONE_TYPES_FAIL = '[Lookups] [PhoneType] Get All Phone Types Fail',
  GET_ALL_PHONE_TYPES_SUCCESS = '[Lookups] [PhoneType] Get All Phone Types Success',
}

/**
 * Represents a store phone type action.
 */
export class GetAllPhoneTypes implements Action {
  /**
   * The type of the action.
   */
  readonly type = PhoneTypeActionType.GET_ALL_PHONE_TYPES;

  /**
   * Fires a new phone type action.
   */
  constructor() {}
}

/**
 * Represents a store phone type fail action.
 */
export class GetAllPhoneTypesFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = PhoneTypeActionType.GET_ALL_PHONE_TYPES_FAIL;

  /**
   * Fires a new phone type fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store phone type success action.
 */
export class GetAllPhoneTypesSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = PhoneTypeActionType.GET_ALL_PHONE_TYPES_SUCCESS;

  /**
   * Fires a phone type success action.
   * @param payload An object contains the list of phone types.
   */
  constructor(public payload: AppHttpResponse<PhoneType[]>) {}
}

/**
 * Lookups-module phone type action types.
 */
export type PhoneTypeActions = GetAllPhoneTypes | GetAllPhoneTypesFail | GetAllPhoneTypesSuccess;
