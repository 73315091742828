<!-- bank account summary starts -->

<!-- bank account summary list starts -->
<div class="row">
  <div class="col-md-12">
    <div class="card-header mb-3 pt-0">
      <h4 class="card-title">{{ 'SHARED.SEARCH.SEARCH_TOOLS' | translate }}</h4>
    </div>
    <div class="card-content">
      <div class="px-3">
        <div class="row">
          <div class="col-md-12">
            <form class="row" [formGroup]="searchForm" (ngSubmit)="search()">
              <div class="col-md-12">
                <div class="row">
                  <div class="form-group col-md-5 col-sm-6 mb-0">
                    <label for="fromDate">{{ 'SHARED.SEARCH.FROM_DATE' | translate }}</label>
                    <div class="input-group round">
                      <input
                        class="form-control"
                        id="fromDate"
                        name="fromDate"
                        formControlName="fromDate"
                        [placeholder]="'SHARED.SEARCH.DATE_PLACEHOLDER' | translate"
                        ngbDatepicker
                        #fromDate="ngbDatepicker"
                      />
                      <div class="input-group-append">
                        <span
                          class="input-group-text gradient-light-blue-indigo"
                          style="cursor: pointer;"
                          (click)="fromDate.toggle()"
                        >
                          <i class="fa fa-calendar"></i>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="form-group col-md-5 col-sm-6 mb-0">
                    <label for="toDate">{{ 'SHARED.SEARCH.TO_DATE' | translate }}</label>
                    <div class="input-group round">
                      <input
                        class="form-control"
                        id="toDate"
                        name="toDate"
                        formControlName="toDate"
                        [placeholder]="'SHARED.SEARCH.DATE_PLACEHOLDER' | translate"
                        ngbDatepicker
                        #toDate="ngbDatepicker"
                      />
                      <div class="input-group-append">
                        <span
                          class="input-group-text gradient-light-blue-indigo"
                          style="cursor: pointer;"
                          (click)="toDate.toggle()"
                        >
                          <i class="fa fa-calendar"></i>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-auto form-group d-flex">
                    <button
                      type="submit"
                      class="btn btn-raised btn-primary round align-self-center col-md-auto"
                      [disabled]="isSearching$ | async"
                    >
                      <i class="fa fa-search"></i>
                    </button>
                    <a
                      *ngIf="[Claims.BANK_ACCOUNTS_REPORTS] | authorize | async"
                      routerLink="/reports/bank-account-summary"
                      [queryParams]="{
                        bankAccountId: bankAccount.id
                      }"
                      class="btn btn-raised btn-secondary round mx-1 align-self-center col-md-auto"
                    >
                      <i class="fa fa-print"></i>
                      <span class="mx-1">{{ 'SHARED.CONFIRM.PRINT' | translate }}</span>
                    </a>
                  </div>
                </div>
              </div>
              <div class="form-group col-md-12">
                <a
                  *ngIf="[Claims.CREATE_BANK_ACCOUNT_TRANSACTION] | authorize | async"
                  routerLink="/finances/bank-accounts/create-bank-account-cheque-deposit"
                  [queryParams]="{
                    bankAccountId: bankAccount.id
                  }"
                  class="btn btn-raised btn-success round mx-1 align-self-center col-md-auto"
                >
                  <i class="fa fa-plus"></i>
                  <span class="mx-1">{{ 'FINANCES.BANK_ACCOUNTS.CREATE_BANK_ACCOUNT_LOG_TITLE' | translate }}</span>
                </a>
                <a
                  *ngIf="[Claims.CREATE_BANK_ACCOUNT_TRANSACTION] | authorize | async"
                  routerLink="/finances/bank-accounts/create-bank-account-deposit-by-employee"
                  [queryParams]="{
                    bankAccountId: bankAccount.id
                  }"
                  class="btn btn-raised btn-success round mx-1 align-self-center col-md-auto"
                >
                  <i class="fa fa-plus"></i>
                  <span class="mx-1">{{
                    'FINANCES.BANK_ACCOUNTS.CREATE_BANK_ACCOUNT_BY_EMPLOYEE_PAGE_TITLE' | translate
                  }}</span>
                </a>
                <a
                  *ngIf="[Claims.CREATE_BANK_ACCOUNT_TRANSACTION] | authorize | async"
                  routerLink="/finances/bank-accounts/create-bank-account-by-withdraw-from-account"
                  [queryParams]="{
                    bankAccountId: bankAccount.id
                  }"
                  class="btn btn-raised btn-success round mx-1 align-self-center col-md-auto"
                >
                  <i class="fa fa-plus"></i>
                  <span class="mx-1">{{
                    'FINANCES.BANK_ACCOUNTS.CONFIRM_CREATE_BANK_ACCOUNT_WITHDRAW_FROM_ACCOUNT_TITLE' | translate
                  }}</span>
                </a>
                <a
                  *ngIf="[Claims.CREATE_BANK_ACCOUNT_TRANSACTION] | authorize | async"
                  routerLink="/finances/bank-accounts/create-bank-account-money-transfer"
                  [queryParams]="{
                    bankAccountId: bankAccount.id
                  }"
                  class="btn btn-raised btn-success round mx-1 align-self-center col-md-auto"
                >
                  <i class="fa fa-plus"></i>
                  <span class="mx-1">{{
                    'FINANCES.BANK_ACCOUNTS.CONFIRM_CREATE_MONEY_TRANSFER_TITLE' | translate
                  }}</span>
                </a>
                <a
                  *ngIf="[Claims.CREATE_BANK_ACCOUNT_TRANSACTION] | authorize | async"
                  routerLink="/finances/bank-accounts/create-bank-account-cash-receipt"
                  [queryParams]="{
                    bankAccountId: bankAccount.id
                  }"
                  class="btn btn-raised btn-success round mx-1 align-self-center col-md-auto"
                >
                  <i class="fa fa-plus"></i>
                  <span class="mx-1">{{ 'FINANCES.BANK_ACCOUNTS.CONFIRM_CREATE_CASH_RECEIPT_TITLE' | translate }}</span>
                </a>
                <a
                  *ngIf="[Claims.CREATE_BANK_ACCOUNT_TRANSACTION] | authorize | async"
                  routerLink="/finances/bank-accounts/create-bank-account-settlement-deed"
                  [queryParams]="{
                    bankAccountId: bankAccount.id
                  }"
                  class="btn btn-raised btn-success round mx-1 align-self-center col-md-auto"
                >
                  <i class="fa fa-plus"></i>
                  <span class="mx-1">{{
                    'FINANCES.BANK_ACCOUNTS.CONFIRM_CREATE_SETTLEMENT_DEED_TITLE' | translate
                  }}</span>
                </a>
              </div>
            </form>
          </div>
          <ng-container *ngIf="logs$ | async; let logs">
            <div class="col-md-12" *ngIf="logs.length; else noItems">
              <div class="row">
                <div class="col-md-12 alert alert-light text-center" role="alert">
                  {{ 'SHARED.SEARCH.SEARCH_RESULTS' | translate }} ({{ (paginationInfo$ | async).total | toNumber }})
                  {{ 'FINANCES.BANK_ACCOUNTS.BANK_ACCOUNT_DATA.LOG' | translate }}
                </div>
                <div class="col-md-12">
                  <div class="table-responsive">
                    <table class="table text-center">
                      <thead>
                        <tr>
                          <th scope="col"><i class="fa fa-list-ol"></i><span class="mx-1"></span></th>
                          <th scope="col">
                            {{ 'FINANCES.BANK_ACCOUNTS.BANK_ACCOUNT_DATA.BANK_ACCOUNT_LOG' | translate }}
                          </th>
                          <th scope="col">
                            {{ 'FINANCES.BANK_ACCOUNTS.BANK_ACCOUNT_DATA.BENEFICIARY' | translate }}
                          </th>
                          <th scope="col">
                            {{ 'FINANCES.BANK_ACCOUNTS.BANK_ACCOUNT_DATA.DEBIT' | translate }}
                          </th>
                          <th scope="col">
                            {{ 'FINANCES.BANK_ACCOUNTS.BANK_ACCOUNT_DATA.CREDIT' | translate }}
                          </th>
                          <th scope="col">
                            {{ 'FINANCES.BANK_ACCOUNTS.BANK_ACCOUNT_DATA.BALANCE_AFTER' | translate }}
                          </th>
                          <th scope="col">{{ 'FINANCES.BANK_ACCOUNTS.BANK_ACCOUNT_DATA.ACCOUNT_DATE' | translate }}</th>
                          <th scope="col" class="px-4">{{ 'SHARED.TOOLS' | translate }}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let bankAccountLog of logs$ | async; trackBy: trackItems; let i = index">
                          <th scope="row">{{ i + 1 }}</th>
                          <td class="font-weight-bold">
                            {{
                              bankAccountLog.bankAccountLogType?.name
                                | localize: bankAccountLog.bankAccountLogType?.nameEn
                                | async
                            }}
                          </td>
                          <td>
                            <span
                              *ngIf="
                                bankAccountLog.bankAccountLogType?.key === 'TRANSFER_MONEY_FROM_BANK_ACCOUNT' ||
                                bankAccountLog.bankAccountLogType?.key === 'TRANSFER_MONEY_TO_BANK_ACCOUNT'
                              "
                            >
                              {{
                                bankAccountLog.otherMoneyTransferBankAccount?.name
                                  | localize: bankAccountLog.otherMoneyTransferBankAccount?.nameEn
                                  | async
                              }}
                            </span>
                            <span *ngIf="bankAccountLog.vendorLog?.vendor">
                              {{
                                bankAccountLog.vendorLog?.vendor?.name
                                  | localize: bankAccountLog.vendorLog?.vendor?.nameEn
                                  | async
                              }}
                            </span>
                            <span *ngIf="bankAccountLog.customerLog?.customer">
                              {{
                                bankAccountLog.customerLog?.customer?.name
                                  | localize: bankAccountLog.customerLog?.customer?.nameEn
                                  | async
                              }}
                            </span>
                            <span *ngIf="bankAccountLog.pettyCashRefund?.pettyCash">
                              {{ bankAccountLog.pettyCashRefund?.pettyCash?.description }}
                            </span>
                            <span *ngIf="bankAccountLog.pettyCashReduction?.pettyCash">
                              {{ bankAccountLog.pettyCashReduction?.pettyCash?.description }}
                            </span>
                          </td>
                          <td>
                            <span *ngIf="bankAccountLog.bankAccountLogType.isDebit">{{
                              bankAccountLog.value | toDecimal
                            }}</span>
                          </td>
                          <td>
                            <span *ngIf="bankAccountLog.bankAccountLogType.isCredit">{{
                              bankAccountLog.value | toDecimal
                            }}</span>
                          </td>
                          <td>{{ bankAccountLog.balanceAfter | toDecimal }}</td>
                          <td class="fit-width">
                            <span [ngbTooltip]="bankAccountLog.transactionDate | date: 'time'">
                              {{ bankAccountLog.transactionDate | date }}
                            </span>
                          </td>
                          <td>
                            <a
                              class="info p-0"
                              (click)="selectedBankAccountLog = bankAccountLog; openModal(infoBankAccountLogModalRef)"
                              [ngbTooltip]="'FINANCES.BANK_ACCOUNTS.BANK_ACCOUNT_DATA_TITLE' | translate"
                            >
                              <i class="fa fa-info font-medium-3 mr-2"></i>
                            </a>
                            <a
                              *ngIf="bankAccountLog.bankAccountLogAttachments.length"
                              class="info p-0"
                              (click)="selectedBankAccountLog = bankAccountLog; openModal(viewAttachmentsModalRef)"
                              [ngbTooltip]="'FINANCES.BANK_ACCOUNTS.BANK_ACCOUNT_DATA.DISPLAY_ATTACHMENTS' | translate"
                            >
                              <i class="fa fa-paperclip font-medium-3 mr-2"></i>
                            </a>
                            <a
                              class="info p-0"
                              [ngbTooltip]="'FINANCES.BANK_ACCOUNTS.BANK_ACCOUNT_DATA.JOURNAL_LINE_DISPLAY' | translate"
                              (click)="selectedBankAccountLog = bankAccountLog; openModal(viewJournalModalRef)"
                            >
                              <i class="fa fa-calendar font-medium-3 mr-2"></i>
                            </a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div class="d-flex justify-content-between p-2">
                      <ngb-pagination
                        *ngIf="paginationInfo$ | async; let pagination"
                        maxSize="5"
                        [collectionSize]="pagination.total"
                        [pageSize]="pagination.pageSize"
                        [page]="pagination.page"
                        (pageChange)="pageChanged($event)"
                        boundaryLinks="true"
                      >
                        <ng-template ngbPaginationFirst>{{
                          'SHARED.PAGINATION.PAGINATION_FIRST' | translate
                        }}</ng-template>
                        <ng-template ngbPaginationFirst>{{
                          'SHARED.PAGINATION.PAGINATION_PREVIOUS' | translate
                        }}</ng-template>
                        <ng-template ngbPaginationFirst>{{
                          'SHARED.PAGINATION.PAGINATION_NEXT' | translate
                        }}</ng-template>
                        <ng-template ngbPaginationFirst>{{
                          'SHARED.PAGINATION.PAGINATION_LAST' | translate
                        }}</ng-template>
                      </ngb-pagination>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- bank account summary list ends -->

<!-- view bank account log journal modal starts -->
<ng-template #viewJournalModalRef let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title text-info" id="modal-basic-title">
      {{ 'FINANCES.BANK_ACCOUNTS.BANK_ACCOUNT_DATA.BANK_ACCOUNT_JOURNAL_LINE_DISPLAY' | translate }}
    </h4>

    <button type="button" class="close" aria-label="Close" (click)="d()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <app-journal-details [journal]="selectedBankAccountLog.journalAssociations[0]?.journal"> </app-journal-details>
  </div>
  <div class="modal-footer">
    <div class="modal-actions">
      <button *ngIf="false" type="button" class="btn btn-outline-dark round" (click)="c()">
        <i class="fa fa-times"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.CANCEL' | translate }}</span>
      </button>
    </div>
  </div>
</ng-template>
<!-- view bank account log journal modal ends -->

<!-- info bank account log modal starts -->
<ng-template #infoBankAccountLogModalRef let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title text-primary" id="modal-basic-title">
      {{ 'FINANCES.BANK_ACCOUNTS.BANK_ACCOUNT_DATA_TITLE' | translate }}
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="d()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-12">
        <div class="row">
          <div class="form-group col-6">
            <label for="bankFee">{{ 'FINANCES.BANK_ACCOUNTS.BANK_ACCOUNT_DATA.BANK_FEE' | translate }}</label>
            <input
              type="text"
              id="bankFee"
              class="form-control round"
              readonly
              [value]="selectedBankAccountLog.bankFee | toDecimal"
            />
          </div>
          <div class="form-group col-6">
            <label for="bankFeeTaxValue">{{
              'FINANCES.BANK_ACCOUNTS.BANK_ACCOUNT_DATA.BANK_FEE_TAX_VALUE' | translate
            }}</label>
            <input
              type="text"
              id="bankFeeTaxValue"
              class="form-control round"
              readonly
              [value]="selectedBankAccountLog.bankFeeTaxValue | toDecimal"
            />
          </div>
          <div class="form-group col-6">
            <label for="user">{{ 'FINANCES.BANK_ACCOUNTS.BANK_ACCOUNT_DATA.USER' | translate }}</label>
            <input
              type="text"
              id="user"
              class="form-control round"
              readonly
              [value]="selectedBankAccountLog.user.name"
            />
          </div>
          <div class="form-group col-6">
            <label for="transactionDate">{{ 'SHARED.SEARCH.CREATED_AT' | translate }}</label>
            <input
              type="text"
              id="transactionDate"
              class="form-control round"
              readonly
              [value]="selectedBankAccountLog.createdAt | date: 'datetime'"
            />
          </div>
          <div class="form-group col-6">
            <label for="extraDetails">{{ 'FINANCES.BANK_ACCOUNTS.BANK_ACCOUNT_DATA.EXTRA_DETAILS' | translate }}</label>
            <textarea
              id="extraDetails"
              class="form-control round"
              readonly
              [value]="
                selectedBankAccountLog.extraDetails ||
                selectedBankAccountLog.customerLog?.extraDetails ||
                selectedBankAccountLog.vendorLog?.extraDetails
              "
            >
            </textarea>
          </div>
          <div class="form-group col-6">
            <label for="notes">{{ 'FINANCES.BANK_ACCOUNTS.BANK_ACCOUNT_DATA.NOTES' | translate }}</label>
            <textarea id="notes" class="form-control round" readonly [value]="selectedBankAccountLog.notes"> </textarea>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="modal-actions">
      <button type="button" class="btn btn-outline-dark round" (click)="c()">
        <i class="fa fa-times"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.CANCEL' | translate }}</span>
      </button>
    </div>
  </div>
</ng-template>
<!-- info bank account log modal ends -->

<!-- no items template starts -->
<ng-template #noItems>
  <div class="col-md-12 alert alert-warning mt-3 text-center" role="alert">
    {{ 'FINANCES.BANK_ACCOUNTS.SEARCH_BANK_ACCOUNT_CURRENT_NOT_FOUND' | translate }}
  </div>
</ng-template>
<!-- no items template ends -->

<!-- bank account summary ends -->

<!-- view attachments modal starts -->
<ng-template #viewAttachmentsModalRef let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title text-info" id="modal-basic-title">
      {{ 'FINANCES.BANK_ACCOUNTS.BANK_ACCOUNT_DATA.ATTACHMENTS' | translate }}
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="d()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="card-content">
        <div class="px-3">
          <div class="row">
            <div class="form-group col-md-12">
              <!-- bank account log attachments starts -->
              <div class="card-content">
                <div class="px-3">
                  <app-files-viewer
                    [files]="getAttachmentsUrls(selectedBankAccountLog.bankAccountLogAttachments)"
                  ></app-files-viewer>
                </div>
              </div>
              <!-- bank account log attachments ends -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="modal-actions">
      <button type="button" class="btn btn-raised btn-primary round" (click)="c()" ngbAutoFocus>
        <i class="fa fa-times"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.CLOSE' | translate }}</span>
      </button>
    </div>
  </div>
</ng-template>
<!-- view attachments modal ends -->
