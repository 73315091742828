import { VisaTypeActionType, VisaTypeActions } from 'lookups/store/actions';
import { VisaTypeState } from 'lookups/store/states';

/**
 * The initial state from which the state starts.
 */
const initialState: VisaTypeState = {
  data: [],
  error: null,
  isLoaded: false,
  isLoading: false,
};

/**
 * The reducer function that is called in each action dispatch against the state.
 * @param state The current state.
 * @param action The action that will affect the state.
 */
export function visaTypeReducer(state: VisaTypeState = initialState, action: VisaTypeActions): VisaTypeState {
  switch (action.type) {
    case VisaTypeActionType.GET_ALL_VISA_TYPES: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case VisaTypeActionType.GET_ALL_VISA_TYPES_FAIL: {
      return {
        ...state,
        error: action.payload,
        isLoading: false,
        isLoaded: false,
      };
    }

    case VisaTypeActionType.GET_ALL_VISA_TYPES_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        isLoading: false,
        isLoaded: true,
      };
    }

    default:
      return state;
  }
}
