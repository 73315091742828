
    import { AssetYearsOfUse } from 'lookups/models';

    /**
     * Represents the AssetYearsOfUses state.
     */
    export class AssetYearsOfUseState {
      /**
       * The list of AssetYearsOfUses.
       */
      public data: AssetYearsOfUse [];
    
      /**
       * An error that may resulting during processing some actions.
       */
      public error: any;
    
      /**
       * Determines if there is a running load process.
       */
      public isLoading: boolean;
    
      /**
       * Determines if the last load process has been ended successfully.
       */
      public isLoaded: boolean;
    }

