/**
 * The set of system supported system account.
 */
export enum SystemAccounts {
  CASH_IN_HAND = 'CASH_IN_HAND',
  CASH_IN_BANKS = 'CASH_IN_BANKS',
  CHECKS_UNDER_COLLECTION = 'CHECKS_UNDER_COLLECTION',
  CASH_INVESTMENTS_LESS_THAN_90_DAYS = 'CASH_INVESTMENTS_LESS_THAN_90_DAYS',
  RESTRICTED_CASH_IN_BANKS = 'RESTRICTED_CASH_IN_BANKS',
  SHORT_TERM_DEPOSITS = 'SHORT_TERM_DEPOSITS',
  CASH_INVESTMENTS_FOR_TRADING = 'CASH_INVESTMENTS_FOR_TRADING',
  TRADE_DEBTORS = 'TRADE_DEBTORS',
  ALLOWANCE_FOR_DOUBTFUL_DEBTS = 'ALLOWANCE_FOR_DOUBTFUL_DEBTS',
  RELATED_PARTIES = 'RELATED_PARTIES',
  ADVANCES_OF_STAFF = 'ADVANCES_OF_STAFF',
  LETTERS_OF_GUARANTEE = 'LETTERS_OF_GUARANTEE',
  DOCUMENTARY_CREDITS = 'DOCUMENTARY_CREDITS',
  PETTY_CASH = 'PETTY_CASH',
  WELL_EXECUTED_DETENTION = 'WELL_EXECUTED_DETENTION',
  ACCRUED_REVENUE = 'ACCRUED_REVENUE',
  WORK_IN_PROGRESS_UNBILLED_REVENUE = 'WORK_IN_PROGRESS_UNBILLED_REVENUE',
  VALUE_ADDED_TAX_DEBIT = 'VALUE_ADDED_TAX_DEBIT',
  MISCELLANEOUS_DEBTORS = 'MISCELLANEOUS_DEBTORS',
  PREPAID_EXPENSES = 'PREPAID_EXPENSES',
  ADVANCE_PAYMENTS_TO_VENDORS_AND_CONTRACTORS = 'ADVANCE_PAYMENTS_TO_VENDORS_AND_CONTRACTORS',
  FINANCING_CHARGES_FOR_LEASE_CONTRACTS = 'FINANCING_CHARGES_FOR_LEASE_CONTRACTS',
  FINANCING_CHARGES_FOR_BANK_LOANS_AND_CREDIT_FACILITIES = 'FINANCING_CHARGES_FOR_BANK_LOANS_AND_CREDIT_FACILITIES',
  GOODS_AND_MATERIALS_STOCK = 'GOODS_AND_MATERIALS_STOCK',
  STOCK_IN_PROCESS = 'STOCK_IN_PROCESS',
  COMPLETED_STOCK = 'COMPLETED_STOCK',
  PROVISION_FOR_DAMAGED_OR_OBSOLETE_INVENTORY = 'PROVISION_FOR_DAMAGED_OR_OBSOLETE_INVENTORY',
  INVESTMENTS_AVAILABLE_FOR_SALE_OR_HELD_TO_MATURITY = 'INVESTMENTS_AVAILABLE_FOR_SALE_OR_HELD_TO_MATURITY',
  LONG_TERM_DEPOSITS = 'LONG_TERM_DEPOSITS',
  FINANCE_CHARGES_FOR_LEASE_CONTRACTS_NON_CURRENT = 'FINANCE_CHARGES_FOR_LEASE_CONTRACTS_NON_CURRENT',
  FINANCING_CHARGES_FOR_LOANS_AND_CREDIT_FACILITIES_NON_CURRENT = 'FINANCING_CHARGES_FOR_LOANS_AND_CREDIT_FACILITIES_NON_CURRENT',
  PROJECTS_UNDER_IMPLEMENTATION = 'PROJECTS_UNDER_IMPLEMENTATION',
  LANDS = 'LANDS',
  BUILDINGS = 'BUILDINGS',
  FURNITURE = 'FURNITURE',
  DEVICES = 'DEVICES',
  COMPUTERS = 'COMPUTERS',
  EQUIPMENTS = 'EQUIPMENTS',
  CARS = 'CARS',
  ASSETS_UNDER_FINANCE_LEASES = 'ASSETS_UNDER_FINANCE_LEASES',
  BUILDING_CONSUMPTION_COMPLEX = 'BUILDING_CONSUMPTION_COMPLEX',
  FURNITURE_CONSUMPTION_COMPLEX = 'FURNITURE_CONSUMPTION_COMPLEX',
  DEVICES_CONSUMPTION_COMPLEX = 'DEVICES_CONSUMPTION_COMPLEX',
  COMPUTERS_CONSUMPTION_COMPLEX = 'COMPUTERS_CONSUMPTION_COMPLEX',
  EQUIPMENTS_CONSUMPTION_COMPLEX = 'EQUIPMENTS_CONSUMPTION_COMPLEX',
  CARS_CONSUMPTION_COMPLEX = 'CARS_CONSUMPTION_COMPLEX',
  POOLED_DEPRECIATION_OF_ASSETS_UNDER_FINANCE_LEASE_CONTRACTS = 'POOLED_DEPRECIATION_OF_ASSETS_UNDER_FINANCE_LEASE_CONTRACTS',
  FAME = 'FAME',
  SOFTWARE_AND_SYSTEMS = 'SOFTWARE_AND_SYSTEMS',
  PATENTS_AND_TRADEMARKS = 'PATENTS_AND_TRADEMARKS',
  FAME_EXTINGUISHING_COMPLEX = 'FAME_EXTINGUISHING_COMPLEX',
  SOFTWARE_AND_HARDWARE_EXTINGUISHING_COMPLEX = 'SOFTWARE_AND_HARDWARE_EXTINGUISHING_COMPLEX',
  PATENT_AND_TRADEMARK_EXTINGUISHING_COMPLEX = 'PATENT_AND_TRADEMARK_EXTINGUISHING_COMPLEX',
  REAL_ESTATE_INVESTMENT_LANDS = 'REAL_ESTATE_INVESTMENT_LANDS',
  REAL_ESTATE_INVESTMENT_BUILDINGS = 'REAL_ESTATE_INVESTMENT_BUILDINGS',
  REAL_ESTATE_INVESTMENT_BUILDING_CONSUMPTION_COMPLEX = 'REAL_ESTATE_INVESTMENT_BUILDING_CONSUMPTION_COMPLEX',
  INVESTMENTS_IN_SUBSIDIARIES = 'INVESTMENTS_IN_SUBSIDIARIES',
  INVESTMENTS_IN_ASSOCIATES = 'INVESTMENTS_IN_ASSOCIATES',
  INVESTMENTS_IN_JOINT_VENTURES = 'INVESTMENTS_IN_JOINT_VENTURES',
  LOAN_INSTALLMENTS_THE_CURRENT_PART = 'LOAN_INSTALLMENTS_THE_CURRENT_PART',
  CURRENT_CONTRACTS_FOR_RENT_THE_CURRENT_PART = 'CURRENT_CONTRACTS_FOR_RENT_THE_CURRENT_PART',
  TRADE_CREDITORS = 'TRADE_CREDITORS',
  VENDORS_BROKER = 'VENDORS_BROKER',
  CREDIT_FACILITIES_OVERDRAFT = 'CREDIT_FACILITIES_OVERDRAFT',
  CREDITORS_WITHHOLDING_BALANCES = 'CREDITORS_WITHHOLDING_BALANCES',
  PAYABLE_EXPENSES = 'PAYABLE_EXPENSES',
  EMPLOYEES_ENTITLEMENTS_SALARIES = 'EMPLOYEES_ENTITLEMENTS_SALARIES',
  EMPLOYEES_ENTITLEMENTS_BENEFITS = 'EMPLOYEES_ENTITLEMENTS_BENEFITS',
  EMPLOYEES_ENTITLEMENTS_TRAVELING_TICKETS = 'EMPLOYEES_ENTITLEMENTS_TRAVELING_TICKETS',
  ADVANCE_REVENUE = 'ADVANCE_REVENUE',
  VALUE_ADDED_TAX_CREDIT = 'VALUE_ADDED_TAX_CREDIT',
  RECEIVABLES_STAFF = 'RECEIVABLES_STAFF',
  OTHER_CURRENT_LIABILITIES_RELATED_PARTIES = 'OTHER_CURRENT_LIABILITIES_RELATED_PARTIES',
  MISCELLANEOUS_CREDITORS = 'MISCELLANEOUS_CREDITORS',
  DIVIDEND_PAYABLE = 'DIVIDEND_PAYABLE',
  ZAKAT_PROVISION = 'ZAKAT_PROVISION',
  INCOME_TAX_PROVISION = 'INCOME_TAX_PROVISION',
  PROVISION_FOR_WITHHOLDING_TAX = 'PROVISION_FOR_WITHHOLDING_TAX',
  BANK_LOANS = 'BANK_LOANS',
  LEASE_LIABILITIES_NON_CURRENT_PORTION = 'LEASE_LIABILITIES_NON_CURRENT_PORTION',
  PROVISION_FOR_END_OF_SEVERANCE_PAY = 'PROVISION_FOR_END_OF_SEVERANCE_PAY',
  CAPITAL = 'CAPITAL',
  REGULAR_RESERVE = 'REGULAR_RESERVE',
  GENERAL_RESERVE = 'GENERAL_RESERVE',
  RETAINED_EARNINGS = 'RETAINED_EARNINGS',
  PROFIT_AND_LOSS_BROKER = 'PROFIT_AND_LOSS_BROKER',
  ADDITIONAL_CAPITAL = 'ADDITIONAL_CAPITAL',
  MAIN_ACTIVITIES_SALES_REVENUE = 'MAIN_ACTIVITIES_SALES_REVENUE',
  GAIN_LOSS_ON_DISPOSAL_OF_PROPERTY = 'GAIN_LOSS_ON_DISPOSAL_OF_PROPERTY',
  GAIN_LOSS_ON_INVESTMENT_VALUATION = 'GAIN_LOSS_ON_INVESTMENT_VALUATION',
  SUPPORT_REVENUE = 'SUPPORT_REVENUE',
  OTHER_MISCELLANEOUS_INCOME = 'OTHER_MISCELLANEOUS_INCOME',
  USED_OF_MATERIAL = 'USED_OF_MATERIAL',
  NON_INVENTORY_MATERIAL_COSTS = 'NON_INVENTORY_MATERIAL_COSTS',
  RENT = 'RENT',
  LOCAL_LABOR = 'LOCAL_LABOR',
  SALARIES_AND_WAGES_EMPLOYEE_PROTECTION = 'SALARIES_AND_WAGES_EMPLOYEE_PROTECTION',
  BENEFIT_TO_EMPLOYEES_ALL_COSTS_EXCEPT_WAGE_PROTECTION_ELEMENTS = 'BENEFIT_TO_EMPLOYEES_ALL_COSTS_EXCEPT_WAGE_PROTECTION_ELEMENTS',
  WORK_MISSIONS_AND_OVERTIME = 'WORK_MISSIONS_AND_OVERTIME',
  REPAIR_AND_MAINTENANCE = 'REPAIR_AND_MAINTENANCE',
  GOVERNMENT_FEES_AND_SUBSCRIPTIONS = 'GOVERNMENT_FEES_AND_SUBSCRIPTIONS',
  THE_COST_OF_PUBLIC_SERVICES = 'THE_COST_OF_PUBLIC_SERVICES',
  HOSPITALITY_AND_CLEANLINESS = 'HOSPITALITY_AND_CLEANLINESS',
  OFFICE_CONSUMABLES = 'OFFICE_CONSUMABLES',
  FINANCE_CHARGES_LOANS_AND_BANK_FACILITIES = 'FINANCE_CHARGES_LOANS_AND_BANK_FACILITIES',
  FINANCE_CHARGES_LEASE_CONTRACTS = 'FINANCE_CHARGES_LEASE_CONTRACTS',
  COMMISSIONS = 'COMMISSIONS',
  BANK_EXPENSES = 'BANK_EXPENSES',
  COST_OF_REVENUE_AND_GENERAL_EXPENSES_OTHERS = 'COST_OF_REVENUE_AND_GENERAL_EXPENSES_OTHERS',
  BUILDINGS_DEPRECIATION_EXPENSES = 'BUILDINGS_DEPRECIATION_EXPENSES',
  FURNITURE_DEPRECIATION_EXPENSES = 'FURNITURE_DEPRECIATION_EXPENSES',
  DEVICES_DEPRECIATION_EXPENSES = 'DEVICES_DEPRECIATION_EXPENSES',
  COMPUTERS_DEPRECIATION_EXPENSES = 'COMPUTERS_DEPRECIATION_EXPENSES',
  EQUIPMENTS_DEPRECIATION_EXPENSES = 'EQUIPMENTS_DEPRECIATION_EXPENSES',
  CARS_DEPRECIATION_EXPENSES = 'CARS_DEPRECIATION_EXPENSES',
  ASSETS_UNDER_FINANCE_LEASES_DEPRECIATION_EXPENSES = 'ASSETS_UNDER_FINANCE_LEASES_DEPRECIATION_EXPENSES',
  FAME_DEPRECIATION_EXPENSES = 'FAME_DEPRECIATION_EXPENSES',
  SOFTWARE_AND_SYSTEMS_DEPRECIATION_EXPENSES = 'SOFTWARE_AND_SYSTEMS_DEPRECIATION_EXPENSESS',
  PATENTS_AND_TRADEMARKS_DEPRECIATION_EXPENSES = 'PATENTS_AND_TRADEMARKS_DEPRECIATION_EXPENSES',
  REAL_ESTATE_INVESTMENT_BUILDINGS_DEPRECIATION_EXPENSES = 'REAL_ESTATE_INVESTMENT_BUILDINGS_DEPRECIATION_EXPENSES',
  ASSETS_BROKER = 'ASSETS_BROKER',
  CURRENCY_ROUNDING_DIFFERENCE = 'CURRENCY_ROUNDING_DIFFERENCE',

  GOSI_ACCRUAL = 'GOSI_ACCRUAL',

  GOSI_EXPENSES = 'GOSI_EXPENSES',
}
