<div class="row mb-1">
  <div class="col mb-2">
    <input
      *ngIf="allowSearchByCodeOrBarcode && allowAdd"
      type="text"
      class="form-control round"
      [placeholder]="'STORES.PRODUCTS.PRODUCT_DATA.PRODUCT_DESCRIPTION_SEARCH_PLACEHOLDER' | translate"
      autocomplete="off"
      (keydown)="searchProductByCode($event)"
    />
  </div>
  <div class="col-md-auto">
    <ng-container *ngIf="allowAdd && (allowAddStorableProduct || allowAddServiceProduct || allowAddNonStorableProduct)">
      <button
        *ngIf="allowAddStorableProduct"
        type="button"
        class="btn btn-md btn-raised btn-success round mx-2"
        (click)="addStorableProduct()"
      >
        <i class="fa fa-plus"></i>
        <span class="mx-1"> {{ 'STORES.PRODUCTS.PRODUCT_DATA.STORABLE_PRODUCT' | translate }} </span>
      </button>
      <button
        *ngIf="allowAddServiceProduct"
        type="button"
        class="btn btn-md btn-raised btn-primary round mx-2"
        (click)="addServiceProduct()"
      >
        <i class="fa fa-plus"></i>
        <span class="mx-1"> {{ 'STORES.PRODUCTS.PRODUCT_DATA.SERVICE_PRODUCT' | translate }}</span>
      </button>
      <button
        *ngIf="allowAddNonStorableProduct"
        type="button"
        class="btn btn-md btn-raised btn-warning round mx-2"
        (click)="addNonStorableProduct()"
      >
        <i class="fa fa-plus"></i>
        <span class="mx-1"> {{ 'STORES.PRODUCTS.PRODUCT_DATA.NON_STORABLE_PRODUCT' | translate }}</span>
      </button>
    </ng-container>
    <button
      type="button"
      class="btn btn-md btn-raised btn-danger round mx-2"
      [ngbTooltip]="'STORES.PRODUCTS.PRODUCT_DATA.DELETE_PRODUCTS' | translate"
      (click)="openDeleteModal()"
      [disabled]="formArray.controls.length == 0"
    >
      <i class="fa fa-trash"></i>
    </button>
    <span class="mx-2" ngbDropdown display="dynamic" [placement]="ngbDropdownPosition" autoClose="outside">
      <a class="position-relative" id="dropdownProductsFormOptions" ngbDropdownToggle>
        <i class="fa fa-cog font-medium-3 blue-grey darken-4"></i>
        <p class="d-none">Options</p>
      </a>
      <div ngbDropdownMenu aria-labelledby="dropdownProductsFormOptions" class="text-left">
        <ng-container *ngIf="showCodeColumn">
          <span class="dropdown-item py-1">
            <input
              type="checkbox"
              class="cursor-pointer"
              [(ngModel)]="options.showCodeColumn"
              (ngModelChange)="syncOptions()"
            />
            <span class="ml-2 mt-0 pt-0">
              {{ 'STORES.PRODUCTS.PRODUCT_DATA.CODE' | translate }}
            </span>
          </span>
          <div class="dropdown-divider"></div>
        </ng-container>
        <ng-container *ngIf="showProductTypeColumn">
          <span class="dropdown-item py-1">
            <input
              type="checkbox"
              class="cursor-pointer"
              [(ngModel)]="options.showProductTypeColumn"
              (ngModelChange)="syncOptions()"
            />
            <span class="ml-2 mt-0 pt-0">
              {{ productTypeColumnHeaderText | translate }}
            </span>
          </span>
          <div class="dropdown-divider"></div>
        </ng-container>
        <ng-container *ngIf="showDiscountByValueColumn">
          <span class="dropdown-item py-1">
            <input
              type="checkbox"
              class="cursor-pointer"
              [(ngModel)]="options.showDiscountByValueColumn"
              (ngModelChange)="syncOptions()"
            />
            <span class="ml-2 mt-0 pt-0">
              {{ discountByValueColumnHeaderText | translate }}
            </span>
          </span>
          <div class="dropdown-divider"></div>
        </ng-container>
        <ng-container *ngIf="showDiscountByPercentColumn">
          <span class="dropdown-item py-1">
            <input
              type="checkbox"
              class="cursor-pointer"
              [(ngModel)]="options.showDiscountByPercentColumn"
              (ngModelChange)="syncOptions()"
            />
            <span class="ml-2 mt-0 pt-0">
              {{ discountByPercentColumnHeaderText | translate }}
            </span>
          </span>
          <div class="dropdown-divider"></div>
        </ng-container>
        <ng-container *ngIf="showTotalColumn">
          <span class="dropdown-item py-1">
            <input
              type="checkbox"
              class="cursor-pointer"
              [(ngModel)]="options.showTotalColumn"
              (ngModelChange)="syncOptions()"
            />
            <span class="ml-2 mt-0 pt-0">
              {{ totalColumnHeaderText | translate }}
            </span>
          </span>
          <div class="dropdown-divider"></div>
        </ng-container>
        <ng-container *ngIf="showTaxColumn">
          <span class="dropdown-item py-1">
            <input
              type="checkbox"
              class="cursor-pointer"
              [(ngModel)]="options.showTaxColumn"
              (ngModelChange)="syncOptions()"
            />
            <span class="ml-2 mt-0 pt-0">
              {{ taxColumnHeaderText | translate }}
            </span>
          </span>
          <div class="dropdown-divider"></div>
        </ng-container>
        <ng-container *ngIf="showExpireDateColumn">
          <span class="dropdown-item py-1">
            <input
              type="checkbox"
              class="cursor-pointer"
              [(ngModel)]="options.showExpireDateColumn"
              (ngModelChange)="syncOptions()"
            />
            <span class="ml-2 mt-0 pt-0">
              {{ expireDateColumnHeaderText | translate }}
            </span>
          </span>
          <div class="dropdown-divider"></div>
        </ng-container>
        <ng-container *ngIf="showLocationColumn">
          <span class="dropdown-item py-1">
            <input
              type="checkbox"
              class="cursor-pointer"
              [(ngModel)]="options.showLocationColumn"
              (ngModelChange)="syncOptions()"
            />
            <span class="ml-2 mt-0 pt-0">
              {{ locationColumnHeaderText | translate }}
            </span>
          </span>
          <div class="dropdown-divider"></div>
        </ng-container>
        <ng-container *ngIf="showProjectSubTaskColumn">
          <span class="dropdown-item py-1">
            <input
              type="checkbox"
              class="cursor-pointer"
              [(ngModel)]="options.showProjectSubTaskColumn"
              (ngModelChange)="syncOptions()"
            />
            <span class="ml-2 mt-0 pt-0">
              {{ projectSubTaskColumnHeaderText | translate }}
            </span>
          </span>
          <div class="dropdown-divider"></div>
        </ng-container>
        <ng-container *ngIf="showAvailableStockColumn">
          <span class="dropdown-item py-1">
            <input
              type="checkbox"
              class="cursor-pointer"
              [(ngModel)]="options.showAvailableStockColumn"
              (ngModelChange)="syncOptions()"
            />
            <span class="ml-2 mt-0 pt-0">
              {{ availableStockColumnHeaderText | translate }}
            </span>
          </span>
          <div class="dropdown-divider"></div>
        </ng-container>
        <ng-container *ngIf="showReceiveTypeColumn">
          <span class="dropdown-item py-1">
            <input
              type="checkbox"
              class="cursor-pointer"
              [(ngModel)]="options.showReceiveTypeColumn"
              (ngModelChange)="syncOptions()"
            />
            <span class="ml-2 mt-0 pt-0">
              {{ receiveTypeColumnHeaderText | translate }}
            </span>
          </span>
          <div class="dropdown-divider"></div>
        </ng-container>
        <ng-container *ngIf="showReceiveDateColumn">
          <span class="dropdown-item py-1">
            <input
              type="checkbox"
              class="cursor-pointer"
              [(ngModel)]="options.showReceiveDateColumn"
              (ngModelChange)="syncOptions()"
            />
            <span class="ml-2 mt-0 pt-0">
              {{ receiveDateColumnHeaderText | translate }}
            </span>
          </span>
          <div class="dropdown-divider"></div>
        </ng-container>
        <ng-container *ngIf="showNotesColumn">
          <span class="dropdown-item py-1">
            <input
              type="checkbox"
              class="cursor-pointer"
              [(ngModel)]="options.showNotesColumn"
              (ngModelChange)="syncOptions()"
            />
            <span class="ml-2 mt-0 pt-0">
              {{ notesColumnHeaderText | translate }}
            </span>
          </span>
          <div class="dropdown-divider"></div>
        </ng-container>
        <ng-container *ngIf="showIsAssetColumn">
          <span class="dropdown-item py-1">
            <input
              type="checkbox"
              class="cursor-pointer"
              [(ngModel)]="options.showIsAssetColumn"
              (ngModelChange)="syncOptions()"
            />
            <span class="ml-2 mt-0 pt-0">
              {{ isAssetColumnHeaderText | translate }}
            </span>
          </span>
          <div class="dropdown-divider"></div>
        </ng-container>
        <ng-container>
          <span class="dropdown-item py-1">
            <input
              type="checkbox"
              class="cursor-pointer"
              [(ngModel)]="options.allowProductDuplicate"
              (ngModelChange)="syncOptions()"
            />
            <span class="ml-2 mt-0 pt-0">
              {{ 'STORES.PRODUCTS.PRODUCT_DATA.DUPLICATE_PRODUCTS' | translate }}
            </span>
          </span>
          <div class="dropdown-divider"></div>
        </ng-container>
      </div>
    </span>
  </div>
</div>

<div [formGroup]="formArray.parent" class="row">
  <div class="col-12">
    <div class="table-responsive" [ngStyle]="{ 'padding-bottom': isDropDownOpened ? '8rem' : 'unset' }">
      <table class="table text-center">
        <thead>
          <tr>
            <th *ngIf="showProductNumberColumn" scope="col"><i class="fa fa-list-ol"></i></th>
            <th *ngIf="showProductTypeColumn && options.showProductTypeColumn" scope="col">
              <i class="fa fa fa-map-signs"></i>
              {{ 'STORES.PRODUCTS.PRODUCT_DATA.PRODUCT_TYPE' | translate }}
            </th>
            <th *ngIf="showCodeColumn && options.showCodeColumn" scope="col">
              <i class="fa fa-barcode"></i>
              {{ 'STORES.PRODUCTS.PRODUCT_DATA.PRODUCT_CODE' | translate }}
            </th>
            <th scope="col">
              <i class="fa fa-comments"></i>
              {{ 'STORES.PRODUCTS.PRODUCT_DATA.THE_PRODUCT' | translate }}
            </th>
            <th scope="col">
              <i class="fa fa-list-ol"></i>
              {{ 'STORES.PRODUCTS.PRODUCT_DATA.QUANTITIES' | translate }}
            </th>
            <th scope="col">
              <i class="fa fa-balance-scale"></i>
              {{ 'STORES.PRODUCTS.PRODUCT_DATA.UNIT_OF_MEASURE' | translate }}
            </th>
            <th scope="col">
              <i class="fa fa-usd"></i>
              {{ 'STORES.PRODUCTS.PRODUCT_DATA.VALUE' | translate }}
            </th>
            <th *ngIf="showDiscountByValueColumn && options.showDiscountByValueColumn" scope="col">
              <i class="fa fa-minus-circle"></i>
              {{ discountByValueColumnHeaderText | translate }}
            </th>
            <th *ngIf="showDiscountByPercentColumn && options.showDiscountByPercentColumn" scope="col">
              {{ discountByPercentColumnHeaderText | translate }}
            </th>
            <th *ngIf="showTotalColumn && options.showTotalColumn" scope="col">
              <i class="fa fa-usd"></i>
              {{ 'STORES.PRODUCTS.PRODUCT_DATA.TOTAL' | translate }}
            </th>
            <th *ngIf="showTaxColumn && options.showTaxColumn" scope="col">
              <i class="fa fa-percent"></i>
              {{ 'STORES.PRODUCTS.PRODUCT_DATA.TAX' | translate }}
            </th>
            <th class="fit-width" *ngIf="showProjectSubTaskColumn && options.showProjectSubTaskColumn" scope="col">
              {{ projectSubTaskColumnHeaderText | translate }}
            </th>
            <th *ngIf="showExpireDateColumn && options.showExpireDateColumn" scope="col">
              <i class="fa fa-calendar"></i>
              {{ 'STORES.PRODUCTS.PRODUCT_DATA.EXPIRE_DATE' | translate }}
            </th>
            <th *ngIf="showLocationColumn && options.showLocationColumn" scope="col">
              <i class="fa fa-university"></i>
              {{ locationColumnHeaderText | translate }}
            </th>
            <th *ngIf="showReceiveTypeColumn && options.showReceiveTypeColumn" scope="col">
              <i class="fa fa-braille"></i>
              {{ 'STORES.PRODUCTS.PRODUCT_DATA.RECEIVE_TYPE' | translate }}
            </th>
            <th *ngIf="showReceiveDateColumn && options.showReceiveDateColumn" scope="col">
              <i class="fa fa-calendar"></i>
              {{ 'STORES.PRODUCTS.PRODUCT_DATA.RECEIVE_DATE' | translate }}
            </th>
            <th *ngIf="showNotesColumn && options.showNotesColumn" scope="col">
              <i class="fa fa-pencil"></i>
              {{ notesColumnHeaderText | translate }}
            </th>
            <th *ngIf="showIsAssetColumn && options.showIsAssetColumn" scope="col">
              <i class="fa fa-archive"></i>
              {{ isAssetColumnHeaderText | translate }}
            </th>
            <th *ngIf="showAvailableStockColumn && options.showAvailableStockColumn" scope="col">
              <i class="fa fa-puzzle-piece"></i>
              {{ 'STORES.PRODUCTS.PRODUCT_DATA.AVAILABLE_STOCK' | translate }}
            </th>
            <th *ngIf="allowRemove" scope="col">
              <i class="fa fa-cogs"></i>
            </th>
          </tr>
        </thead>
        <tbody [formArrayName]="formArrayName">
          <tr [formGroup]="formGroup" *ngFor="let formGroup of formArray.controls; let index = index">
            <th *ngIf="showProductNumberColumn" scope="row">{{ index + 1 }}</th>
            <td *ngIf="showProductTypeColumn && options.showProductTypeColumn" class="min-width-8">
              <ng-container *ngIf="formGroup.value.isStorable">
                {{ 'STORES.PRODUCTS.PRODUCT_DATA.STORABLE_PRODUCT' | translate }}
              </ng-container>
              <ng-container *ngIf="formGroup.value.isService">
                {{ 'STORES.PRODUCTS.PRODUCT_DATA.SERVICE_PRODUCT' | translate }}
              </ng-container>
              <ng-container *ngIf="formGroup.value.isNonStorable">
                {{ 'STORES.PRODUCTS.PRODUCT_DATA.NON_STORABLE_PRODUCT' | translate }}
              </ng-container>
            </td>
            <td *ngIf="showCodeColumn && options.showCodeColumn" class="min-width-8">
              <ng-container *ngIf="formGroup.value.product">
                <i class="fa fa-barcode fa-xs"></i>
                {{ formGroup.value.product?.itemCode }}
              </ng-container>
            </td>
            <td class="min-width-20">
              <ng-container *ngIf="formGroup.value.isStorable || formGroup.value.isService">
                {{ formGroup.value.product?.description | localize: formGroup.value.product?.descriptionEn | async }}
                <div class="d-block">
                  <app-product-sub-products-form
                    *ngIf="formGroup.value.product?.hasSubProducts"
                    [formGroup]="formGroup"
                    [isDisabled]="isDisabled"
                    controlName="subProductId"
                  ></app-product-sub-products-form>
                </div>
              </ng-container>
              <textarea
                *ngIf="formGroup.value.isNonStorable"
                name="description"
                autocomplete="off"
                class="form-control round"
                [placeholder]="descriptionInputPlaceholderText | translate"
                rows="2"
                minlength="1"
                maxlength="100"
                formControlName="description"
                [readonly]="allowChangeDescription"
              ></textarea>
            </td>
            <td class="min-width-9">
              <input
                type="text"
                [mask]="DECIMAL_MASK"
                thousandSeparator=","
                name="quantity"
                autocomplete="off"
                class="form-control round"
                [placeholder]="quantityInputPlaceholderText | translate"
                formControlName="quantity"
              />
            </td>
            <td class="min-width-9">
              <ng-select
                *ngIf="allowChangeUnitOfMeasure"
                class="round"
                formControlName="unitOfMeasureId"
                searchable="true"
                [placeholder]="'STORES.PRODUCTS.PRODUCT_DATA.UNIT_OF_MEASURE_PLACEHOLDER' | translate"
                [notFoundText]="'SHARED.SEARCH.SEARCH_NOT_FOUND' | translate"
                dropdownPosition="bottom"
                (open)="isDropDownOpened = true"
                (close)="isDropDownOpened = false"
              >
                <ng-option *ngFor="let unit of getProductUnitsOfMeasure(formGroup.value.product)" [value]="unit.id">
                  {{ unit?.name | localize: unit?.nameEn | async }}
                </ng-option>
              </ng-select>
              <ng-container *ngIf="!allowChangeUnitOfMeasure">
                {{ formGroup.value.unitOfMeasure?.name | localize: formGroup.value.unitOfMeasure?.nameEn | async }}
              </ng-container>
            </td>
            <td *ngIf="allowChangeValue || formGroup.value.isNonStorable" class="min-width-9">
              <input
                type="text"
                [mask]="DECIMAL_MASK"
                thousandSeparator=","
                name="value"
                autocomplete="off"
                class="form-control round"
                [placeholder]="valueInputPlaceholderText | translate"
                formControlName="value"
              />
            </td>
            <td *ngIf="!allowChangeValue && !formGroup.value.isNonStorable" class="min-width-9">
              {{ formGroup.value.value || 0 | toDecimal }}
            </td>
            <td *ngIf="showDiscountByValueColumn && options.showDiscountByValueColumn" class="min-width-9">
              <input
              *ngIf="allowChangeDiscount else disabledDiscountByValue"
                type="text"
                [mask]="DECIMAL_MASK"
                thousandSeparator=","
                name="discount"
                autocomplete="off"
                class="form-control round"
                [placeholder]="discountByValueInputPlaceholderText | translate"
                formControlName="discount"
              />
              <ng-template #disabledDiscountByValue>
                {{ formGroup.value.discount || 0 | toDecimal }}
              </ng-template>
            </td>
            <td *ngIf="showDiscountByPercentColumn && options.showDiscountByPercentColumn" class="min-width-9">
              <input
              *ngIf="allowChangeDiscount else disabledDiscountByPercent"
                type="text"
                [mask]="DECIMAL_MASK"
                thousandSeparator=","
                name="discountPercent"
                autocomplete="off"
                class="form-control round"
                [placeholder]="discountByPercentInputPlaceholderText | translate"
                formControlName="discountPercent"
              />
              <ng-template #disabledDiscountByPercent>
                {{ formGroup.value.discountPercent || 0 | toDecimal }}
              </ng-template>
            </td>
            <td *ngIf="showTotalColumn && options.showTotalColumn" class="min-width-9">
              {{ (calculateProductTotal(formGroup.value) | toDecimal) || '0.0' }}
            </td>
            <ng-container *ngIf="showTaxColumn && options.showTaxColumn">
              <td *ngIf="allowChangeTax || formGroup.value.isNonStorable; else disabledTaxValue" class="min-width-9">
                <ng-select
                  class="round"
                  formControlName="tax"
                  searchable="true"
                  [placeholder]="taxInputPlaceholderText | translate"
                  [notFoundText]="'SHARED.SEARCH.SEARCH_NOT_FOUND' | translate"
                  dropdownPosition="bottom"
                  (open)="isDropDownOpened = true"
                  (close)="isDropDownOpened = false"
                >
                  <ng-option *ngFor="let tax of taxes$ | async" [value]="tax.value">
                    {{ tax.name | localize: tax.nameEn | async }}
                  </ng-option>
                </ng-select>
              </td>
              <ng-template #disabledTaxValue>
                <td class="min-width-9">
                  {{ formGroup.value.tax || 0 | toDecimal }}
                </td>
              </ng-template>
            </ng-container>
            <td class="fit-width" *ngIf="showProjectSubTaskColumn && options.showProjectSubTaskColumn">
              <ng-select
                *ngIf="allowChangeProjectSubTask"
                class="round"
                formControlName="projectSubTaskId"
                searchable="true"
                labelForId="projectSubTaskId"
                [placeholder]="'STORES.PRODUCTS.PRODUCT_DATA.SUB_TASK_PLACEHOLDER' | translate"
                [notFoundText]="'SHARED.SEARCH.SEARCH_NOT_FOUND' | translate"
                (open)="currentSelectedFormGroup = formGroup; projectSubTasksListVisibility = true"
              >
                <ng-option *ngFor="let projectSubTask of formGroup.value.projectSubTasks" [value]="projectSubTask?.id">
                  {{ projectSubTask?.name | localize: projectSubTask?.nameEn | async }}
                </ng-option>
              </ng-select>
              <ng-container *ngIf="!allowChangeProjectSubTask">
                {{ formGroup.value.projectSubTask?.name | localize: formGroup.value.projectSubTask?.nameEn | async }}
                <span class="d-block" *ngIf="formGroup.value.projectSubTask">
                  ({{
                    formGroup.value.projectSubTask?.project.description
                      | localize: formGroup.value.projectSubTask?.project.descriptionEn
                      | async
                  }})
                </span>
              </ng-container>
            </td>
            <td *ngIf="showExpireDateColumn && options.showExpireDateColumn" class="min-width-11">
              <ng-container *ngIf="formGroup.value.product?.hasExpireDate">
                <app-date-picker
                  controlName="expireDate"
                  [parentControl]="formGroup"
                  [placeholder]="expireDateInputPlaceholderText | translate"
                  (isDropDownOpened)="isDropDownOpened = $event"
                ></app-date-picker>
              </ng-container>
            </td>
            <td *ngIf="showLocationColumn && options.showLocationColumn" class="min-width-20">
              <ng-select
                *ngIf="
                  formGroup.value.isStorable ||
                  (formGroup.value.isService && isLocationForServiceProductsRequired) ||
                  (formGroup.value.isNonStorable && isLocationForNonStorableProductsRequired) ||
                  formGroup.value.isSubProductHasStorableIngredients ||
                  formGroup.value.isProductHasStorableIngredients
                "
                class="round"
                formControlName="locationId"
                labelForId="locationId"
                searchable="true"
                [placeholder]="locationInputPlaceholderText | translate"
                [notFoundText]="'SHARED.SEARCH.SEARCH_NOT_FOUND' | translate"
                (open)="currentSelectedFormGroup = formGroup; locationsListVisibility = true"
              >
                <ng-option *ngFor="let location of formGroup.value.locations" [value]="location.id">
                  {{ location.name | localize: location.nameEn | async }}
                </ng-option>
              </ng-select>
            </td>
            <td *ngIf="showReceiveTypeColumn && options.showReceiveTypeColumn">
              <ng-select
                class="round"
                formControlName="receiveTypeId"
                labelForId="receiveTypeId"
                searchable="true"
                placement="bottom"
                [placeholder]="receiveTypeInputPlaceholderText | translate"
                [notFoundText]="'SHARED.SEARCH.SEARCH_NOT_FOUND' | translate"
                (open)="isDropDownOpened = true"
                (close)="isDropDownOpened = false"
              >
                <ng-option *ngFor="let type of receiveTypes$ | async" [value]="type.id">
                  {{ type.name | localize: type.nameEn | async }}
                </ng-option>
              </ng-select>
            </td>
            <td *ngIf="showReceiveDateColumn && options.showReceiveDateColumn" class="min-width-11">
              <app-date-picker
                controlName="receiveDate"
                [parentControl]="formGroup"
                [placeholder]="receiveDateInputPlaceholderText | translate"
                (isDropDownOpened)="isDropDownOpened = $event"
              ></app-date-picker>
            </td>
            <td *ngIf="showNotesColumn && options.showNotesColumn" [ngClass]="notesInputColumnCssClasses">
              <textarea
                type="text"
                autocomplete="off"
                class="form-control round"
                [placeholder]="notesInputPlaceholderText | translate"
                [maxlength]="notesMaxLength"
                formControlName="notes"
                rows="1"
              >
              </textarea>
            </td>
            <td *ngIf="showIsAssetColumn && options.showIsAssetColumn">
              <input *ngIf="formGroup.value.isNonStorable" type="checkbox" formControlName="isAsset" />
            </td>
            <td *ngIf="showAvailableStockColumn && options.showAvailableStockColumn" class="min-width-15">
              <app-product-available-stocks
                *ngIf="formGroup.value.isStorable"
                [product]="formGroup.value.product"
                [locationId]="availableStockLocationId || formGroup.value.locationId"
                [subProductId]="formGroup.value.subProductId"
                [unitOfMeasureId]="formGroup.value.unitOfMeasureId"
                [unitsOfMeasure]="getProductUnitsOfMeasure(formGroup.value.product)"
                [quantity]="validateAvailableStocks ? formGroup.value.quantity : 0"
                (dropDownIsOpened)="isDropDownOpened = $event"
              ></app-product-available-stocks>
            </td>
            <td *ngIf="allowRemove">
              <a
                class="danger p-0"
                [ngbTooltip]="'STORES.PRODUCTS.PRODUCT_DATA.PRODUCT_DELETE' | translate"
                (click)="removeProduct(index)"
              >
                <i class="fa fa-close font-medium-3 mr-2"></i>
              </a>
            </td>
          </tr>
        </tbody>
        <tfoot class="tfoot-light">
          <tr>
            <td [attr.colspan]="productsCountSummaryColSpan">
              <i class="fa fa-bookmark c-primary"></i>
              <span>
                {{ formArray.length | toNumber }}
                {{ 'STORES.PRODUCTS.PRODUCT_DATA.PRODUCT' | translate }}
              </span>
            </td>
            <td [attr.colspan]="2">
              <i class="fa fa-bookmark c-primary"></i>
              <span>
                {{ (productsQuantity | toDecimal) || '0.0' }}
                {{ 'STORES.PRODUCTS.PRODUCT_DATA.PRODUCT_QUANTITY' | translate }}</span
              >
            </td>
            <td colspan="1"></td>
            <td colspan="1" *ngIf="showDiscountByValueColumn && options.showDiscountByValueColumn"></td>
            <td colspan="1" *ngIf="showDiscountByPercentColumn && options.showDiscountByPercentColumn"></td>
            <td [attr.colspan]="2" class="text-justify">
              <i class="fa fa-bookmark c-primary"></i>
              <span>
                {{ (productsTotalWithoutDiscountAndTax | toDecimal) || '0.0' }}
                {{ 'STORES.PRODUCTS.PRODUCT_DATA.TOTAL' | translate }}
              </span>
            </td>
            <td colspan="20"></td>
          </tr>
        </tfoot>
      </table>
    </div>
  </div>
</div>

<!-- products search starts -->
<app-products-search
  [closeOnSelect]="false"
  [showPurchasePrice]="showPurchasePriceInProductsSearch"
  [showSalePrice]="showSalePriceInProductsSearch"
  (products)="selectProducts($event)"
  [(visible)]="productsListVisibility"
  [(searchForStorableProducts)]="searchForStorableProducts"
  [(searchForServiceProducts)]="searchForServiceProducts"
  [(searchForAllProductTypes)]="searchForAllProductTypes"
>
</app-products-search>
<!-- products search ends -->

<!-- locations search starts -->
<app-locations-search closeOnSelect="true" (locations)="selectLocation($event)" [(visible)]="locationsListVisibility">
</app-locations-search>
<!-- locations search ends -->

<!-- confirm delete all products modal starts -->
<ng-template #deleteModalRef let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title text-danger" id="modal-basic-title">
      {{ 'STORES.PRODUCTS.CONFIRM_DELETE_ALL_PRODUCTS_TITLE' | translate }}
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="d()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p class="text-danger">{{ 'STORES.PRODUCTS.CONFIRM_DELETE_ALL_PRODUCTS_MESSAGE' | translate }}</p>
  </div>
  <div class="modal-footer">
    <div class="modal-actions">
      <button type="button" class="btn btn-raised btn-danger round" (click)="c(); resetProducts()" ngbAutofocus>
        <i class="fa fa-check"></i>
        <span class="mx-1"> {{ 'SHARED.CONFIRM.YES' | translate }} </span>
      </button>
      <button type="button" class="btn btn-outline-dark round" (click)="c()">
        <i class="fa fa-times"></i>
        <span class="mx-1"> {{ 'SHARED.CONFIRM.CANCEL' | translate }} </span>
      </button>
    </div>
  </div>
</ng-template>
<!-- confirm modal ends -->

<!-- project sub tasks search starts -->
<project-sub-tasks-search
  closeOnSelect="true"
  (subTasks)="selectProjectSubTask($event)"
  [(visible)]="projectSubTasksListVisibility"
>
</project-sub-tasks-search>
<!-- project sub tasks search ends -->
