<!-- resources list starts -->
<div class="row">
  <div class="col-md-12">
    <div class="card-content">
      <div class="px-3">
        <div class="row">
          <div class="col-md-12">
            <form class="row" [formGroup]="searchForm" (ngSubmit)="search()">
              <div class="form-group col-md-4">
                <label for="projectResourceTypes">
                  {{ 'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.PROJECT_RESOURCE_TYPES' | translate }}
                </label>
                <ng-select
                  class="round"
                  formControlName="projectResourceTypes"
                  labelForId="projectResourceTypes"
                  searchable="true"
                  autocomplete="off"
                  [closeOnSelect]="false"
                  [multiple]="true"
                  [placeholder]="
                    'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.PROJECT_RESOURCE_TYPES_PLACEHOLDER' | translate
                  "
                  [notFoundText]="'SHARED.SEARCH.SEARCH_NOT_FOUND' | translate"
                >
                  <ng-option
                    *ngFor="let projectResourceType of projectResourceTypes$ | async"
                    [value]="projectResourceType?.id"
                  >
                    {{ projectResourceType?.name | localize: projectResourceType?.nameEn | async }}
                  </ng-option>
                </ng-select>
              </div>
              <div class="form-group col-md-4">
                <label for="projectMainTasks">
                  {{ 'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.PROJECT_MAIN_TASKS' | translate }}
                </label>
                <ng-select
                  class="round"
                  formControlName="projectMainTasks"
                  labelForId="projectMainTasks"
                  [multiple]="true"
                  [closeOnSelect]="false"
                  searchable="true"
                  autocomplete="off"
                  [placeholder]="'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.PROJECT_MAIN_TASKS_PLACEHOLDER' | translate"
                  [notFoundText]="'SHARED.SEARCH.SEARCH_NOT_FOUND' | translate"
                  (open)="projectMainTaskListVisibility = true"
                >
                  <ng-option *ngFor="let projectMainTask of projectMainTasks" [value]="projectMainTask?.id">
                    {{ projectMainTask?.name | localize: projectMainTask?.nameEn | async }}
                  </ng-option>
                </ng-select>
              </div>
              <div class="form-group col-md-4">
                <label for="projectSubTasks">
                  {{ 'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.PROJECT_SUB_TASKS' | translate }}
                </label>
                <ng-select
                  class="round"
                  formControlName="projectSubTasks"
                  labelForId="projectSubTasks"
                  [multiple]="true"
                  [closeOnSelect]="false"
                  searchable="true"
                  autocomplete="off"
                  [placeholder]="'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.PROJECT_SUB_TASKS_PLACEHOLDER' | translate"
                  [notFoundText]="'SHARED.SEARCH.SEARCH_NOT_FOUND' | translate"
                  (open)="projectSubTaskListVisibility = true"
                >
                  <ng-option *ngFor="let projectSubTask of projectSubTasks" [value]="projectSubTask?.id">
                    {{ projectSubTask?.name | localize: projectSubTask?.nameEn | async }}
                  </ng-option>
                </ng-select>
              </div>
              <div class="col">
                <div class="row">
                  <div class="form-group col-12 col-sm-6 mb-0">
                    <label for="fromDate">{{ 'SHARED.SEARCH.FROM_DATE' | translate }}</label>
                    <div class="input-group round">
                      <input
                        class="form-control"
                        id="fromDate"
                        name="fromDate"
                        formControlName="fromDate"
                        [placeholder]="'SHARED.SEARCH.DATE_PLACEHOLDER' | translate"
                        ngbDatepicker
                        #fromDate="ngbDatepicker"
                      />
                      <div class="input-group-append">
                        <span
                          class="input-group-text gradient-light-blue-indigo"
                          style="cursor: pointer;"
                          (click)="fromDate.toggle()"
                        >
                          <i class="fa fa-calendar"></i>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="form-group col-12 col-sm-6 mb-0">
                    <label for="toDate">{{ 'SHARED.SEARCH.TO_DATE' | translate }}</label>
                    <div class="input-group round">
                      <input
                        class="form-control"
                        id="toDate"
                        name="toDate"
                        formControlName="toDate"
                        [placeholder]="'SHARED.SEARCH.DATE_PLACEHOLDER' | translate"
                        ngbDatepicker
                        #toDate="ngbDatepicker"
                      />
                      <div class="input-group-append">
                        <span
                          class="input-group-text gradient-light-blue-indigo"
                          style="cursor: pointer;"
                          (click)="toDate.toggle()"
                        >
                          <i class="fa fa-calendar"></i>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-auto form-group d-flex">
                <button
                  type="submit"
                  class="btn btn-raised btn-primary align-self-end round mx-1 mb-0"
                  [disabled]="isSearching$ | async"
                >
                  <i class="fa fa-search"></i>
                </button>

                <app-export-tools
                  *ngIf="(projectResources$ | async)?.length"
                  class="d-flex align-self-end mx-1"
                  targetElementId="resources-table-export-target-element"
                  [outputFileName]="'SHARED.EXPORT_TABLES.RESOURCES_TABLE' | translate"
                ></app-export-tools>
              </div>
            </form>
          </div>

          <div class="col-md-12" *ngIf="(projectResources$ | async)?.length; else noItems">
            <div class="row">
              <div class="col-md-12 alert alert-light text-center" role="alert">
                {{ 'SHARED.SEARCH.SEARCH_RESULTS' | translate }} ({{ (paginationInfo$ | async).total | number }})
                {{ 'PROJECTS_MANAGEMENT.PROJECTS.RESOURCE' | translate }}
              </div>
              <div class="table-responsive">
                <table class="table text-center" id="resources-table-export-target-element">
                  <thead>
                    <tr>
                      <th scope="col"><i class="fa fa-list-ol"></i></th>
                      <th scope="col">{{ 'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.PROJECT_TITLE' | translate }}</th>
                      <th scope="col">
                        {{ 'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.PROJECT_MAIN_TASK' | translate }}
                      </th>
                      <th scope="col">
                        {{ 'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.PROJECT_SUB_TASK' | translate }}
                      </th>
                      <th scope="col">
                        {{ 'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.RESOURCE_TYPE' | translate }}
                      </th>
                      <th scope="col">
                        {{ 'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.VALUE' | translate }}
                      </th>
                      <th scope="col">
                        {{ 'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.TRANSACTION_DATE' | translate }}
                      </th>
                      <th scope="col">{{ 'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.CREATED_AT' | translate }}</th>
                      <th scope="col">{{ 'SHARED.TOOLS' | translate }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let projectResource of projectResources$ | async; trackBy: trackItems; let i = index">
                      <th scope="row">{{ i + 1 }}</th>
                      <td>
                        {{
                          projectResource.project?.description
                            | localize: projectResource.project?.descriptionEn
                            | async
                        }}
                      </td>
                      <td>
                        {{
                          projectResource.projectSubTask?.projectMainTask?.name
                            | localize: projectResource.projectSubTask?.projectMainTask?.nameEn
                            | async
                        }}
                      </td>
                      <td>
                        {{
                          projectResource.projectSubTask?.name
                            | localize: projectResource.projectSubTask?.nameEn
                            | async
                        }}
                      </td>
                      <td>
                        {{
                          projectResource.projectResourceType.name
                            | localize: projectResource.projectResourceType.nameEn
                            | async
                        }}
                        <span
                          >({{
                            projectResource.projectResourceTypeSource?.name
                              | localize: projectResource.projectResourceTypeSource?.nameEn
                              | async
                          }})</span
                        >
                      </td>
                      <td>{{ projectResource.value | toDecimal }}</td>
                      <td>
                        <span [ngbTooltip]="projectResource.transactionDate | date: 'time'">
                          {{ projectResource.transactionDate | date }}
                        </span>
                      </td>
                      <td>
                        <span [ngbTooltip]="projectResource.createdAt | date: 'time'">
                          {{ projectResource.createdAt | date }}
                        </span>
                      </td>
                      <td>
                        <a
                          *ngIf="projectResource.projectResourceLines"
                          class="info p-0"
                          [routerLink]="['/projects-management/projects/project-resources/files', projectResource.id]"
                          [ngbTooltip]="'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.DISPLAY_DETAILS' | translate"
                        >
                          <i class="fa fa-eye font-medium-3 mr-2"></i>
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div class="d-flex justify-content-between p-2">
                  <ngb-pagination
                    *ngIf="paginationInfo$ | async; let pagination"
                    maxSize="5"
                    [collectionSize]="pagination.total"
                    [pageSize]="pagination.pageSize"
                    [page]="pagination.page"
                    (pageChange)="pageChanged($event)"
                    boundaryLinks="true"
                  >
                    <ng-template ngbPaginationFirst>{{ 'SHARED.PAGINATION.PAGINATION_FIRST' | translate }}</ng-template>
                    <ng-template ngbPaginationPrevious>{{
                      'SHARED.PAGINATION.PAGINATION_PREVIOUS' | translate
                    }}</ng-template>
                    <ng-template ngbPaginationNext>{{ 'SHARED.PAGINATION.PAGINATION_NEXT' | translate }}</ng-template>
                    <ng-template ngbPaginationLast>{{ 'SHARED.PAGINATION.PAGINATION_LAST' | translate }}</ng-template>
                  </ngb-pagination>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- resources list ends -->

<!-- no items template starts -->
<ng-template #noItems>
  <div class="col-md-12 alert alert-warning mt-3 text-center" role="alert">
    {{ 'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.RESOURCES_NOT_FOUND' | translate }}
  </div>
</ng-template>
<!-- no items template ends -->

<!-- project main tasks search starts -->
<app-project-main-tasks-search
  [(visible)]="projectMainTaskListVisibility"
  (mainTasks)="selectMainTasks($event)"
  [projectId]="projectId"
>
</app-project-main-tasks-search>
<!-- project main tasks search ends -->

<!-- project sub tasks search starts -->
<app-project-sub-tasks-search
  [(visible)]="projectSubTaskListVisibility"
  (subTasks)="selectSubTasks($event)"
  [projectId]="projectId"
>
</app-project-sub-tasks-search>
<!-- project sub tasks search ends -->
