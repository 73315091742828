import { createSelector } from '@ngrx/store';

import { CurrenciesState, FinancesState } from 'finances/store/states';
import { selectFinancesState } from 'finances/store/selectors/feature.selectors';

/**
 * Gets the currencies state.
 */
const selectCurrencyState = createSelector(selectFinancesState, (state: FinancesState) => state.currencies);

/**
 * Gets the list of loaded currencies.
 */
export const getCurrencies = createSelector(selectCurrencyState, (state: CurrenciesState) => state.data);

/**
 * Gets the currencies pagination info.
 */
export const getCurrenciesPaginationInfo = createSelector(
  selectCurrencyState,
  (state: CurrenciesState) => state.paginationInfo
);

/**
 * Gets the error that occurred in the state.
 */
export const getCurrenciesError = createSelector(selectCurrencyState, (state: CurrenciesState) => state.error);

/**
 * Determines if there is a running search process.
 */
export const getCurrenciesSearching = createSelector(
  selectCurrencyState,
  (state: CurrenciesState) => state.isSearching
);

/**
 * Determines if the last search process has been ended successfully.
 */
export const getCurrenciesSearchCompleted = createSelector(
  selectCurrencyState,
  (state: CurrenciesState) => state.isSearchCompleted
);

/**
 * Gets the primary currency.
 */
export const getPrimaryCurrency = createSelector(
  selectCurrencyState,
  (state: CurrenciesState) => state.primaryCurrency
);

/**
 * Determines if there is a running find primary currency process.
 */
export const getPrimaryCurrencyFinding = createSelector(
  selectCurrencyState,
  (state: CurrenciesState) => state.isPrimaryCurrencyFinding
);

/**
 * Determines if the last find primary currency process has been ended successfully.
 */
export const getPrimaryCurrencyFindCompleted = createSelector(
  selectCurrencyState,
  (state: CurrenciesState) => state.isPrimaryCurrencyFindCompleted
);

/**
 * Gets the selected currency.
 */
export const getSelectedCurrency = createSelector(
  selectCurrencyState,
  (state: CurrenciesState) => state.selectedCurrency
);

/**
 * Determines if there is a running find currency process.
 */
export const getCurrenciesFinding = createSelector(
  selectCurrencyState,
  (state: CurrenciesState) => state.isFinding
);

/**
 * Determines if the last find currency process has been ended successfully.
 */
export const getCurrenciesFindCompleted = createSelector(
  selectCurrencyState,
  (state: CurrenciesState) => state.isFindCompleted
);

/**
 * Determines if there is a running create process.
 */
export const getSelectedCurrencyCreating = createSelector(
  selectCurrencyState,
  (state: CurrenciesState) => state.isCreating
);

/**
 * Determines if the last create process has been ended successfully.
 */
export const getSelectedCurrencyCreateCompleted = createSelector(
  selectCurrencyState,
  (state: CurrenciesState) => state.isCreateCompleted
);

/**
 * Determines if there is a running update process.
 */
export const getSelectedCurrencyUpdating = createSelector(
  selectCurrencyState,
  (state: CurrenciesState) => state.isUpdating
);

/**
 * Determines if the last update process has been ended successfully.
 */
export const getSelectedCurrencyUpdateCompleted = createSelector(
  selectCurrencyState,
  (state: CurrenciesState) => state.isUpdateCompleted
);

/**
 * Determines if there is a running delete process.
 */
export const getSelectedCurrencyDeleting = createSelector(
  selectCurrencyState,
  (state: CurrenciesState) => state.isDeleting
);

/**
 * Determines if the last delete process has been ended successfully.
 */
export const getSelectedCurrencyDeleteCompleted = createSelector(
  selectCurrencyState,
  (state: CurrenciesState) => state.isDeleteCompleted
);
