import { Action } from '@ngrx/store';

import { AppHttpResponse } from 'shared';
import { Organization, CreateOrganizationInput, UpdateOrganizationInput } from 'organizations/models';

/**
 * The organizations-module store organizations action types.
 */
export enum OrganizationsActionType {
  SEARCH_ORGANIZATIONS = '[Organizations] [Organizations] Search Organizations',
  SEARCH_ORGANIZATIONS_FAIL = '[Organizations] [Organizations] Search Organizations Fail',
  SEARCH_ORGANIZATIONS_SUCCESS = '[Organizations] [Organizations] Search Organizations Success',

  FIND_ORGANIZATION = '[Organizations] [Organizations] Find Organization',
  FIND_ORGANIZATION_FAIL = '[Organizations] [Organizations] Find Organization Fail',
  FIND_ORGANIZATION_SUCCESS = '[Organizations] [Organizations] Find Organization Success',

  CREATE_ORGANIZATION = '[Organizations] [Organizations] Create Organization',
  CREATE_ORGANIZATION_FAIL = '[Organizations] [Organizations] Create Organization Fail',
  CREATE_ORGANIZATION_SUCCESS = '[Organizations] [Organizations] Create Organization Success',

  UPDATE_ORGANIZATION = '[Organizations] [Organizations] Update Organization',
  UPDATE_ORGANIZATION_FAIL = '[Organizations] [Organizations] Update Organization Fail',
  UPDATE_ORGANIZATION_SUCCESS = '[Organizations] [Organizations] Update Organization Success',

  BLOCK_ORGANIZATION = '[Organizations] [Organizations] Block Organization',
  BLOCK_ORGANIZATION_FAIL = '[Organizations] [Organizations] Block Organization Fail',
  BLOCK_ORGANIZATION_SUCCESS = '[Organizations] [Organizations] Block Organization Success',

  ACTIVATE_ORGANIZATION = '[Organizations] [Organizations] Activate Organization',
  ACTIVATE_ORGANIZATION_FAIL = '[Organizations] [Organizations] Activate Organization Fail',
  ACTIVATE_ORGANIZATION_SUCCESS = '[Organizations] [Organizations] Activate Organization Success',

  DELETE_ORGANIZATION = '[Organizations] [Organizations] Delete Organization',
  DELETE_ORGANIZATION_FAIL = '[Organizations] [Organizations] Delete Organization Fail',
  DELETE_ORGANIZATION_SUCCESS = '[Organizations] [Organizations] Delete Organization Success',
}

/**
 * Represents a store organizations search action.
 */
export class SearchOrganizations implements Action {
  /**
   * The type of the action.
   */
  readonly type = OrganizationsActionType.SEARCH_ORGANIZATIONS;

  /**
   * Fires a new organizations search action.
   * @param payload the search parameters,
   * if omitted, all organizations will be loaded.
   */
  constructor(public payload?: { name: string; page: number }) {}
}

/**
 * Represents a store organizations search-fail action.
 */
export class SearchOrganizationsFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = OrganizationsActionType.SEARCH_ORGANIZATIONS_FAIL;

  /**
   * Fires a new organizations search-fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store search-success action.
 */
export class SearchOrganizationsSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = OrganizationsActionType.SEARCH_ORGANIZATIONS_SUCCESS;

  /**
   * Fires a new organizations search-success action.
   * @param payload An object contains the list of organizations that matches the search criteria
   * in addition to the pagination info.
   */
  constructor(public payload: AppHttpResponse<Organization[]>) {}
}

/**
 * Represents a store find-organization action.
 */
export class FindOrganization implements Action {
  /**
   * The type of the action.
   */
  readonly type = OrganizationsActionType.FIND_ORGANIZATION;

  /**
   * Fires a new find organization action.
   * @param payload the id of the organization.
   */
  constructor(public payload: number) {}
}

/**
 * Represents a store find-organization-fail action.
 */
export class FindOrganizationFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = OrganizationsActionType.FIND_ORGANIZATION_FAIL;

  /**
   * Fires a new find-organization-fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store find organization-success action.
 */
export class FindOrganizationSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = OrganizationsActionType.FIND_ORGANIZATION_SUCCESS;

  /**
   * Fires a new find-organization-success action.
   * @param payload The loaded organization.
   */
  constructor(public payload: AppHttpResponse<Organization>) {}
}

/**
 * Represents a store organization create action.
 */
export class CreateOrganization implements Action {
  /** The type of the action. */
  readonly type = OrganizationsActionType.CREATE_ORGANIZATION;

  /**
   * Fires a new organization create action.
   * @param payload the new organization data-model.
   */
  constructor(public payload: CreateOrganizationInput) {}
}

/**
 * Represents a store organization create-fail action.
 */
export class CreateOrganizationFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = OrganizationsActionType.CREATE_ORGANIZATION_FAIL;

  /**
   * Fires a new organization create-fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store organization create-success action.
 */
export class CreateOrganizationSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = OrganizationsActionType.CREATE_ORGANIZATION_SUCCESS;

  /**
   * Fires a new organization create-success action.
   * @param payload The created organization.
   */
  constructor(public payload: AppHttpResponse<Organization>) {}
}

/**
 * Represents a store organization update action.
 */
export class UpdateOrganization implements Action {
  /**
   * The type of the action.
   */
  readonly type = OrganizationsActionType.UPDATE_ORGANIZATION;

  /**
   * Fires a new organization update action.
   * @param payload The organization's updated data.
   */
  constructor(public payload: UpdateOrganizationInput) {}
}

/**
 * Represents a store organization update-fail action.
 */
export class UpdateOrganizationFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = OrganizationsActionType.UPDATE_ORGANIZATION_FAIL;

  /**
   * Fires a new organization update-fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store organization update-success action.
 */
export class UpdateOrganizationSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = OrganizationsActionType.UPDATE_ORGANIZATION_SUCCESS;

  /**
   * Fires a new organization update-success action.
   * @param payload The updated organization.
   */
  constructor(public payload: AppHttpResponse<Organization>) {}
}

/**
 * Represents a store organization block action.
 */
export class BlockOrganization implements Action {
  /**
   * The type of the action.
   */
  readonly type = OrganizationsActionType.BLOCK_ORGANIZATION;

  /**
   * Fires a new organization block action.
   * @param payload The organization's id.
   */
  constructor(public payload: number) {}
}

/**
 * Represents a store organization block-fail action.
 */
export class BlockOrganizationFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = OrganizationsActionType.BLOCK_ORGANIZATION_FAIL;

  /**
   * Fires a new organization block-fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store organization block-success action.
 */
export class BlockOrganizationSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = OrganizationsActionType.BLOCK_ORGANIZATION_SUCCESS;

  /**
   * Fires a new organization block-success action.
   * @param payload The updated organization.
   */
  constructor(public payload: AppHttpResponse<Organization>) {}
}

/**
 * Represents a store organization activate action.
 */
export class ActivateOrganization implements Action {
  /**
   * The type of the action.
   */
  readonly type = OrganizationsActionType.ACTIVATE_ORGANIZATION;

  /**
   * Fires a new organization activate action.
   * @param payload The organization's id.
   */
  constructor(public payload: number) {}
}

/**
 * Represents a store organization activate-fail action.
 */
export class ActivateOrganizationFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = OrganizationsActionType.ACTIVATE_ORGANIZATION_FAIL;

  /**
   * Fires a new organization activate-fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store organization activate-success action.
 */
export class ActivateOrganizationSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = OrganizationsActionType.ACTIVATE_ORGANIZATION_SUCCESS;

  /**
   * Fires a new organization activate-success action.
   * @param payload The updated organization.
   */
  constructor(public payload: AppHttpResponse<Organization>) {}
}

/**
 * Represents a store organization delete action.
 */
export class DeleteOrganization implements Action {
  /**
   * The type of the action.
   */
  readonly type = OrganizationsActionType.DELETE_ORGANIZATION;

  /**
   * Fires a new organization delete action.
   * @param payload The organization's id.
   */
  constructor(public payload: number) {}
}

/**
 * Represents a store organization delete-fail action.
 */
export class DeleteOrganizationFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = OrganizationsActionType.DELETE_ORGANIZATION_FAIL;

  /**
   * Fires a new organization delete-fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store organization delete-success action.
 */
export class DeleteOrganizationSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = OrganizationsActionType.DELETE_ORGANIZATION_SUCCESS;

  /**
   * Fires a new organization delete-success action.
   * @param payload The deleted organization.
   */
  constructor(public payload: AppHttpResponse<Organization>) {}
}

/**
 * organizations-module organizations action types.
 */
export type OrganizationsActions =
  | SearchOrganizations
  | SearchOrganizationsFail
  | SearchOrganizationsSuccess
  | FindOrganization
  | FindOrganizationFail
  | FindOrganizationSuccess
  | CreateOrganization
  | CreateOrganizationFail
  | CreateOrganizationSuccess
  | UpdateOrganization
  | UpdateOrganizationFail
  | UpdateOrganizationSuccess
  | BlockOrganization
  | BlockOrganizationFail
  | BlockOrganizationSuccess
  | ActivateOrganization
  | ActivateOrganizationFail
  | ActivateOrganizationSuccess
  | DeleteOrganization
  | DeleteOrganizationFail
  | DeleteOrganizationSuccess;
