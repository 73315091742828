import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';

import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subscription } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { cloneDeep } from 'lodash';
import { skip, tap } from 'rxjs/operators';
import { Decimal } from 'decimal.js';

import * as fromSalesStore from 'sales/store';
import * as fromAuthStore from 'auth/store';
import {
  Customer,
  ParkedSaleInvoice,
  CreatePointOfSaleInvoiceInput,
  CreateSalesInvoiceProductInput,
  Board,
} from 'sales/models';
import { ProductsFormItem } from 'stores';
import { CanComponentDeactivate } from 'auth';
import {
  CustomValidators,
  NotificationMessage,
  NotificationService,
  TranslationService,
  PrintService,
  APP_CONSTANTS,
} from 'shared';
import { ProductsFormPOSUtil } from 'stores';
import { Claims } from 'security/models';
import { TouchKeyboardConfig } from 'touch-keyboard';
import { SaleInvoiceOrderType, SaleInvoiceOrderTypes } from 'lookups/models';
import * as fromLookupsStore from 'lookups/store';
import * as fromPrintingStore from 'printing/store';
import * as fromSettingsStore from 'settings/store';

@Component({
  selector: 'app-create-point-of-sale-invoice',
  templateUrl: './create-point-of-sale-invoice.component.html',
  styles: [],
})
export class CreatePointOfSaleInvoiceComponent implements OnInit, OnDestroy, CanComponentDeactivate {
  /**
   * The payments modal template reference.
   */
  @ViewChild('paymentsModalRef') paymentsModalRef: ElementRef<any>;

  /**
   * The parked sale invoices modal template reference.
   */
  @ViewChild('parkedSaleInvoicesModalRef') parkedSaleInvoicesModalRef: ElementRef<any>;

  /**
   * The reset form modal template reference.
   */
  @ViewChild('resetModalRef') resetModalRef: ElementRef<any>;

  /**
   * The exchange points modal template reference.
   */
  @ViewChild('exchangePointsModalRef') exchangePointsModalRef: ElementRef<any>;

  /**
   * The decimal mask.
   */
  readonly DECIMAL_MASK = APP_CONSTANTS.numeric.decimal.mask;

  /**
   * Gets or sets the id of the default user location.
   * @optional
   */
  defaultLocationId?: number;

  /**
   * The system supported user claims.
   */
  Claims = Claims;

  /**
   * The list of parked sale invoices.
   */
  parkedSaleInvoices$: Observable<ParkedSaleInvoice[]>;

  /**
   * Gets or sets the id of the point of sale invoice.
   */
  invoiceId: number;

  /**
   * The create sales invoice form.
   */
  form: FormGroup;

  /**
   * The create exchange points form.
   */
  exchangePointsForm: FormGroup;

  /**
   * Add new product s to the products form.
   */
  newProducts: ProductsFormItem[];

  /**
   * Add new product form items to the products form.
   */
  newProductItems: ProductsFormItem[];

  /**
   * Gets or sets the touch keyboard configurations for the products form area.
   */
  productsFormTouchKeyboardConfig: TouchKeyboardConfig = {};

  /**
   * Gets or sets the touch keyboard configurations for the payments area.
   */
  paymentsTouchKeyboardConfig: TouchKeyboardConfig = {};

  /**
   * The list of selected sale invoice order types.
   */
  saleInvoiceOrderTypes$: Observable<SaleInvoiceOrderType[]>;

  /**
   * The id of selected sale invoice order type.
   */
  saleInvoiceOrderTypeId: number;

  /**
   * The selected sale invoice order type.
   */
  saleInvoiceOrderType: string;

  /**
   * The id of currently created sale invoice.
   */
  newlyCreatedInvoiceId: number;

  /**
   * The selected customer.
   */
  selectedCustomer: Customer;

  /**
   * The selected board.
   */
  selectedBoard: Board;

  /**
   * Indicates whether the customer has points or not.
   */
  isPointsExchange = false;

  /**
   * The set of subscriptions on this components,
   * these subscriptions must be unsubscribed before this component got destroyed.
   */
  subscriptions = new Subscription();

  /**
   * Shows or hide the customers list.
   */
  customersListVisibility = false;

  /**
   * The list of selected customers.
   */
  customers: Customer[] = [];

  /**
   * Shows or hide the boards list.
   */
  boardsListVisibility = false;

  /**
   * The list of selected boards.
   */
  boards: Board[] = [];

  /**
   * The value of points program exchange.
   */
  pointsProgramExchangeValue: number;

  /**
   * The points of points program exchange.
   */
  pointsProgramExchangePoints: number;

  /**
   * The family dine in order type id.
   */
  familyDineInOrderTypeId: number;

  /**
   * The dine in order type id.
   */
  dineInOrderTypeId: number;

  /**
   * Indicates whether there is a create pos invoice process is running or not.
   */
  isCreating$: Observable<boolean>;

  /**
   * Gets the products form-array.
   */
  get productsForm(): FormArray {
    return this.form?.controls.products as FormArray;
  }

  /**
   * Gets the payment form-array.
   */
  get paymentsForm(): FormArray {
    return this.form?.controls.payments as FormArray;
  }

  /**
   * Gets the products total without discount and tax.
   */
  get productsTotalWithoutDiscountAndTax(): number {
    return ProductsFormPOSUtil.productsTotal(this.productsForm.controls.map((item) => item.value));
  }

  /**
   * Gets the sum of selected products taxes.
   */
  get productsTax(): number {
    return ProductsFormPOSUtil.productsTax(this.productsForm.controls.map((item) => item.value));
  }

  /**
   * Gets the sum of discount values.
   */
  get productsDiscount(): number {
    return ProductsFormPOSUtil.productsDiscount(this.productsForm.controls.map((item) => item.value));
  }

  /**
   * Gets net value of the invoice.
   */
  get invoiceNet(): number {
    return ProductsFormPOSUtil.invoiceNet(this.productsForm.controls.map((item) => item.value));
  }

  /**
   * Gets the total payments.
   */
  get totalPayments(): number {
    return Decimal.add(
      !this.form.value.cashValue ? '0' : this.form.value.cashValue,
      !this.form.value.electronicValue ? '0' : this.form.value.electronicValue
    ).toNumber();
  }

  /**
   * The ngb-modal reference to use it when modal needed to be closed.
   */
  modal: NgbModalRef;

  constructor(
    private modalService: NgbModal,
    private notificationService: NotificationService,
    private translationService: TranslationService,
    private printService: PrintService,
    private printingStore$: Store<fromPrintingStore.PrintingState>,
    private salesStore$: Store<fromSalesStore.SalesState>,
    private lookupsStore$: Store<fromLookupsStore.LookupsState>,
    private settingsStore$: Store<fromSettingsStore.SettingsState>,
    private authStore$: Store<fromAuthStore.AuthState>
  ) {}

  ngOnInit(): void {
    this.init();
  }

  /**
   * Destroy component data
   */
  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  /**
   * Initialize component data.
   */
  init(): void {
    this.initForm();

    /* Subscribe to selected location id to select it whenever it changed. */
    this.subscriptions.add(
      this.authStore$
        .pipe(
          select(fromAuthStore.getSessionUserDefaultLocation),
          tap((location) => {
            this.defaultLocationId = location ? location.id : null;
          })
        )
        .subscribe()
    );

    /**
     * Load data.
     */
    this.parkedSaleInvoices$ = this.salesStore$.pipe(select(fromSalesStore.getParkedSaleInvoices));

    let isManualSearchTriggeredBeforeForInvoiceOrderTypes = false;
    this.saleInvoiceOrderTypes$ = this.lookupsStore$.pipe(
      select(fromLookupsStore.getSaleInvoiceOrderTypes),
      tap((orderTypes) => {
        if (!isManualSearchTriggeredBeforeForInvoiceOrderTypes && !orderTypes.length) {
          isManualSearchTriggeredBeforeForInvoiceOrderTypes = true;
          this.lookupsStore$.dispatch(new fromLookupsStore.GetAllSaleInvoiceOrderType());
        } else if (orderTypes?.length) {
          this.dineInOrderTypeId = orderTypes?.find((orderType) => orderType.key === SaleInvoiceOrderTypes.DINE_IN)?.id;
          this.familyDineInOrderTypeId = orderTypes?.find(
            (orderType) => orderType.key === SaleInvoiceOrderTypes.FAMILY_DINE_IN
          )?.id;
        }
      })
    );

    this.subscriptions.add(
      this.settingsStore$
        .pipe(
          select(fromSettingsStore.getOrganizationsSettings),
          tap((organization) => {
            if (organization) {
              this.pointsProgramExchangeValue = organization.pointsProgramExchangeValue;
              this.pointsProgramExchangePoints = organization.pointsProgramExchangePoints;
            }
          })
        )
        .subscribe()
    );

    this.settingsStore$.dispatch(new fromSettingsStore.GetOrganizationSettings());

    /**
     * Print the transaction once it is ready.
     */
    this.subscriptions.add(
      this.printingStore$
        .pipe(
          select(fromPrintingStore.getSaleInvoicePrint),
          skip(1),
          tap((report) => {
            if (report) {
              this.printService.printPDF(report.pdfUrl, (error, request) => {
                this.notificationService.error(this.translationService.translate('REPORTS.FAILED_TO_PRINT'));
              });
            }
          })
        )
        .subscribe()
    );

    /**
     * Load data.
     */
    this.isCreating$ = this.salesStore$.pipe(select(fromSalesStore.getSelectedSaleInvoiceCreating));

    /**
     * Subscribe to the selected sale-invoice created to print the newly created invoice once its created.
     */
    this.subscriptions.add(
      this.salesStore$
        .pipe(
          select(fromSalesStore.getSelectedSaleInvoice),
          /** Skip first one to avoid print old data from another page. */
          skip(1),
          tap((newlyCreatedInvoice) => {
            if (newlyCreatedInvoice) {
              if (newlyCreatedInvoice.location.automaticallyShowPrintDialogForNewSaleInvoice) {
                this.newlyCreatedInvoiceId = newlyCreatedInvoice.id;
                this.print(newlyCreatedInvoice.id);
              }
              this.reset();
              this.selectedCustomer = newlyCreatedInvoice.customer;
            }
          })
        )
        .subscribe()
    );

    /**
     * Subscribe to the selected customer to update his points.
     */
    this.subscriptions.add(
      this.salesStore$
        .pipe(
          select(fromSalesStore.getSelectedCustomer),
          skip(1),
          tap((customer) => {
            if (customer && this.selectedCustomer?.id === customer.id) {
              this.selectedCustomer = customer;
            }
          })
        )
        .subscribe()
    );

    /**
     * Subscription when selected sale invoice order type form changes.
     */
    this.subscriptions.add(
      this.form.controls.saleInvoiceOrderTypeId.valueChanges.subscribe(() => {
        this.onSaleInvoiceOrderTypeChange();
      })
    );
  }

  /**
   * Initialize form and add validators.
   */
  initForm(): void {
    /*
     * sales invoice form
     */
    this.form = new FormGroup({
      customerId: new FormControl(null, Validators.required),
      customer: new FormControl(null),
      cashValue: new FormControl('', CustomValidators.gte(0)),
      electronicValue: new FormControl('', CustomValidators.gte(0)),
      products: new FormArray([], CustomValidators.arrayItems(1)),
      saleInvoiceOrderTypeId: new FormControl(this.saleInvoiceOrderTypeId, Validators.required),
      boardId: new FormControl(null, this.saleInvoiceOrderTypeId ? Validators.required : Validators.nullValidator),
    });
  }

  /**
   * Submits the form.
   */
  submit(): void {
    const errorMessage = new NotificationMessage();

    if (this.form.invalid) {
      if (this.form.get('saleInvoiceOrderTypeId').invalid) {
        errorMessage.title = this.translationService.translate(
          'SALES.INVOICES.POS.POS_DATA_VALIDATION.ORDER_TYPE_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate('SALES.INVOICES.POS.POS_DATA_VALIDATION.ORDER_TYPE_IS_REQUIRED'),
        ];
      } else if (this.form.get('boardId').invalid) {
        errorMessage.title = this.translationService.translate('SALES.INVOICES.POS.POS_DATA_VALIDATION.BOARD_ERROR');
        errorMessage.body = [
          this.translationService.translate('SALES.INVOICES.POS.POS_DATA_VALIDATION.BOARD_IS_REQUIRED'),
        ];
        this.boardsListVisibility = true;
      } else if (this.form.get('customerId').invalid) {
        errorMessage.title = this.translationService.translate('SALES.INVOICES.POS.POS_DATA_VALIDATION.CUSTOMER_ERROR');
        errorMessage.body = [
          this.translationService.translate('SALES.INVOICES.POS.POS_DATA_VALIDATION.CUSTOMER_IS_REQUIRED'),
        ];

        this.customersListVisibility = true;
      } else if (this.form.get('products').invalid) {
        /**
         * Check if products count = 0.
         */
        if (!this.productsForm.controls.length) {
          errorMessage.title = this.translationService.translate(
            'SALES.INVOICES.POS.POS_DATA_VALIDATION.PRODUCTS_ERROR'
          );
          errorMessage.body = [
            this.translationService.translate('SALES.INVOICES.POS.POS_DATA_VALIDATION.PRODUCTS_LENGTH_ERROR'),
          ];
        } else {
          /**
           * Check if some of products has errors.
           */
          for (let index = 0; index < this.productsForm.controls.length; index++) {
            const item = this.productsForm.controls[index];

            if (item.valid) {
              continue;
            }

            errorMessage.title = this.translationService.translate(
              'SALES.INVOICES.POS.POS_DATA_VALIDATION.PRODUCT_NUMBER_ERROR',
              {
                productNumber: index + 1,
              }
            );
            errorMessage.body = [];

            if (item.get('description').invalid) {
              errorMessage.body.push(
                this.translationService.translate('SALES.INVOICES.POS.POS_DATA_VALIDATION.DESCRIPTION_IS_REQUIRED')
              );
            }
            if (item.get('subProductId').invalid) {
              errorMessage.body.push(
                this.translationService.translate('SALES.INVOICES.POS.POS_DATA_VALIDATION.SUB_PRODUCT_ERROR')
              );
            }
            if (item.get('quantity').invalid) {
              errorMessage.body.push(
                this.translationService.translate('SALES.INVOICES.POS.POS_DATA_VALIDATION.PRODUCT_QUANTITY_ERROR')
              );
            }

            if (item.get('unitOfMeasureId').invalid) {
              errorMessage.body.push(
                this.translationService.translate('SALES.INVOICES.POS.POS_DATA_VALIDATION.UNIT_OF_MEASURE_IS_REQUIRED')
              );
            }

            if (item.get('discount').invalid) {
              errorMessage.body.push(
                this.translationService.translate('SALES.INVOICES.POS.POS_DATA_VALIDATION.PRODUCT_DISCOUNT_ERROR')
              );
            }
            if (item.get('discountPercent').invalid) {
              errorMessage.body.push(
                this.translationService.translate(
                  'SALES.INVOICES.POS.POS_DATA_VALIDATION.PRODUCT_DISCOUNT_PERCENT_ERROR'
                )
              );
            }

            if (item.get('value').invalid) {
              errorMessage.body.push(
                this.translationService.translate('SALES.INVOICES.POS.POS_DATA_VALIDATION.SALE_PRICE_IS_REQUIRED')
              );
            }

            if (item.get('tax').invalid) {
              errorMessage.body.push(
                this.translationService.translate('SALES.INVOICES.POS.POS_DATA_VALIDATION.TAX_IS_REQUIRED')
              );
            }

            if (item.get('serialNumber').invalid) {
              errorMessage.body.push(
                this.translationService.translate('SALES.INVOICES.POS.POS_DATA_VALIDATION.SERIAL_NUMBER_IS_REQUIRED')
              );
            }

            break;
          }
        }
      }

      this.form.markAllAsTouched();
      return this.notificationService.warningWithTitle(errorMessage);
    }

    const electronicValue = parseFloat(!this.form.value.electronicValue ? '0' : this.form.value.electronicValue);

    if (this.invoiceNet > this.totalPayments || (this.totalPayments > this.invoiceNet && electronicValue !== 0)) {
      errorMessage.title = this.translationService.translate('SALES.INVOICES.POS.POS_DATA_VALIDATION.PAYMENTS_ERROR');
      errorMessage.body = [
        this.translationService.translate('SALES.INVOICES.POS.POS_DATA_VALIDATION.PAYMENTS_LENGTH_ERROR'),
      ];

      this.openPayments();
      this.form.markAllAsTouched();
      return this.notificationService.warningWithTitle(errorMessage);
    }

    this.confirmSubmit();
  }

  /**
   * Confirms the submit.
   */
  confirmSubmit(): void {
    this.closeModals();

    /**
     * The list of products in the products form.
     */
    const products = this.productsForm.value.map((item: ProductsFormItem) => {
      const inputProduct: CreateSalesInvoiceProductInput = {
        productId: item.isStorable || item.isService ? item.product.id : null,
        description: item.isStorable || item.isService ? null : item.description,
        subProductId: item.subProductId,
        isPointsExchange: item.isPointsExchange,
        quantity: item.quantity,
        unitOfMeasureId: item.unitOfMeasureId,
        discount: item.discount,
        discountPercent: item.discountPercent,
        value: item.value,
        tax: item.tax,
        notes: item.notes,
        locationId: item.isStorable ? item.locationId : null,
      };

      return inputProduct;
    });

    /**
     * The list of payments form.
     */
    const payments = [];
    const cashValue = parseFloat(this.form.value.cashValue ?? '0');
    const electronicValue = parseFloat(this.form.value.electronicValue ?? '0');

    if (cashValue > 0) {
      payments.push({ value: cashValue, isCash: true, isElectronic: false });
    }

    if (electronicValue > 0) {
      payments.push({ value: electronicValue, isCash: false, isElectronic: true });
    }

    const input: CreatePointOfSaleInvoiceInput = {
      customerId: this.form.value.customerId,
      saleInvoiceOrderTypeId: this.form.value.saleInvoiceOrderTypeId,
      boardId: this.form.value.boardId,
      isPointsExchange: this.form.value.isPointsExchange,
      products,
      payments,
    };

    this.salesStore$.dispatch(new fromSalesStore.CreatePointOfSaleInvoice(input));
  }

  /**
   * Open the payments popup.
   */
  openPayments(): void {
    this.openModal(this.paymentsModalRef, 'lg');
  }

  /**
   * Sets the cash value to the invoice total value.
   */
  payCash(): void {
    this.form.patchValue({ cashValue: this.invoiceNet, electronicValue: 0 });
  }

  /**
   * Sets the visa value to the invoice total value.
   */
  payVisa(): void {
    this.form.patchValue({ electronicValue: this.invoiceNet, cashValue: 0 });
  }

  /**
   * Updates points for the selected customer.
   */
  updateCustomerPoints() {
    if (!this.selectedCustomer) {
      return;
    }
    this.salesStore$.dispatch(new fromSalesStore.FindCustomer(this.selectedCustomer.id));
  }

  /**
   * Get the value of exchange points.
   */
  getValueOfExchangePoints(exchangePoints) {
    let exPoint;
    if (exchangePoints.includes(',')) {
      exPoint = exchangePoints.replaceAll(',', '');
    } else {
      exPoint = exchangePoints;
    }
    const value = (this.pointsProgramExchangeValue / this.pointsProgramExchangePoints) * exPoint;
    this.exchangePointsForm.patchValue({ pointsValue: value });
    this.exchangePointsForm.updateValueAndValidity();
  }

  /**
   * Exchanges points for the selected customer.
   */
  confirmExchangePoints() {
    const errorMessage = new NotificationMessage();

    if (this.exchangePointsForm.invalid) {
      if (this.exchangePointsForm.get('exchangePoints').invalid) {
        errorMessage.title = this.translationService.translate(
          'SALES.INVOICES.POS.POS_DATA_VALIDATION.EXCHANGE_POINTS_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate('SALES.INVOICES.POS.POS_DATA_VALIDATION.EXCHANGE_POINTS_IS_REQUIRED'),
          this.translationService.translate('SALES.INVOICES.POS.POS_DATA_VALIDATION.EXCHANGE_POINTS_LENGTH_ERROR'),
        ];
      }

      this.exchangePointsForm.markAllAsTouched();
      return this.notificationService.warningWithTitle(errorMessage);
    }

    if (this.productsForm.length) {
      if (this.productsForm.value.some((product) => product.isPointsExchange)) {
        this.notificationService.warning(
          this.translationService.translate('SALES.INVOICES.POS.POS_DATA_VALIDATION.EXCHANGE_ERROR')
        );
        this.closeModals();
      } else {
        const description = 'إستبدال النقاط';
        const formGroup = new FormGroup({
          isPointsExchange: new FormControl(true),
          product: new FormControl(null),
          description: new FormControl(description),
          subProductId: new FormControl(null),
          quantity: new FormControl(this.exchangePointsForm.value.exchangePoints),
          unitOfMeasureId: new FormControl(null),
          discount: new FormControl(0),
          discountPercent: new FormControl(0, [CustomValidators.range([0, 100])]),
          value: new FormControl((this.pointsProgramExchangeValue / this.pointsProgramExchangePoints) * -1),
          tax: new FormControl(0),
          notes: new FormControl(''),
          serialNumber: new FormControl(null),
        });
        this.productsForm.push(formGroup);
        this.closeModals();
        this.isPointsExchange = true;
      }
    } else {
      this.notificationService.warning(
        this.translationService.translate('SALES.INVOICES.POS.POS_DATA_VALIDATION.PRODUCT_LENGTH_ERROR')
      );
      this.closeModals();
    }
  }

  /**
   * Open the exchange points form popup.
   */
  openExchangePoints(modalRef) {
    this.modalService.open(modalRef);

    /*
     * exchange points form
     */
    this.exchangePointsForm = new FormGroup({
      currentPointsCount: new FormControl(this.selectedCustomer?.points),
      exchangePoints: new FormControl('', [
        Validators.required,
        CustomValidators.gte(1),
        CustomValidators.lte(this.selectedCustomer?.points),
      ]),
      pointsValue: new FormControl(''),
    });
  }

  /**
   * And Sets the conditional validation for the form based on the selected sale invoice order type id.
   */
  onSaleInvoiceOrderTypeChange() {
    /**
     * Set validation first.
     */

    /**
     * The id of the current selected sale invoice order type.
     */
    const saleInvoiceOrderTypeId = parseInt(this.form.controls.saleInvoiceOrderTypeId.value ?? '0');

    /**
     * Check if the sale invoice order type type is dine in & family dine in.
     */
    if (saleInvoiceOrderTypeId === this.dineInOrderTypeId || saleInvoiceOrderTypeId === this.familyDineInOrderTypeId) {
      this.form.controls.boardId.setValidators([Validators.required]);
    } else {
      this.form.controls.boardId.clearValidators();
    }
    this.form.controls.boardId.setValue(null);
    this.form.controls.boardId.updateValueAndValidity();
  }

  /**
   * Selects the newly selected customer from the customers search list.
   * @param customers The list of newly selected customers to select the only one in the list.
   */
  selectCustomer([customer]: Customer[]): void {
    if (customer) {
      this.selectedCustomer = customer;
      this.customers = [customer];
      this.form.patchValue({ customerId: customer.id, customer });
    }
  }

  /**
   * Selects the newly selected board from the boards search list.
   * @param customers The list of newly selected boards to select the only one in the list.
   */
  selectBoard([board]: Board[]): void {
    if (board) {
      this.selectedBoard = board;
      this.boards = [board];
      this.form.patchValue({ boardId: board.id, board });
    }
  }

  /**
   * Open the parked sale invoices popup.
   */
  openParkedSaleInvoices() {
    this.openModal(this.parkedSaleInvoicesModalRef, 'lg');
  }

  /**
   * Adds the current invoice to parked sales.
   */
  parkInvoice(): void {
    if (!this.productsForm.length) {
      return this.notificationService.warning(
        this.translationService.translate('SALES.INVOICES.POS.NOTIFICATION_MESSAGE.INVOICE_NOT_CONTAINS_PRODUCTS')
      );
    }

    const parkedInvoice: ParkedSaleInvoice = {
      customerId: this.form.value.customerId,
      customer: this.form.value.customer,
      saleInvoiceOrderTypeId: this.form.value.saleInvoiceOrderTypeId,
      boardId: this.form.value.boardId,
      productsTotal: this.productsTotalWithoutDiscountAndTax,
      tax: this.productsTax,
      discount: this.productsDiscount,
      net: this.invoiceNet,
      cashValue: this.form.value.cashValue,
      electronicValue: this.form.value.electronicValue,
      products: this.productsForm.value,
    };

    this.salesStore$.dispatch(new fromSalesStore.AddParkedSaleInvoice(parkedInvoice));

    this.reset();
  }

  /**
   * Display the given parked sale invoice and remove it from parked sales.
   * @param parkedInvoice The parked sale invoice to be displayed.
   */
  selectParkedSaleInvoice(parkedInvoice: ParkedSaleInvoice): void {
    this.reset();

    /**
     * Patch parked invoice data to form.
     */
    this.form.patchValue({
      customerId: parkedInvoice.customerId,
      customer: cloneDeep(parkedInvoice.customer),
      saleInvoiceOrderTypeId: parkedInvoice.saleInvoiceOrderTypeId,
      cashValue: parkedInvoice.cashValue,
      electronicValue: parkedInvoice.electronicValue,
      boardId: parkedInvoice.boardId,
    });
    this.newProductItems = cloneDeep(parkedInvoice.products);

    if (parkedInvoice.customer) {
      this.customers = [parkedInvoice.customer];
    }

    /**
     * Remove the invoice from parked sales.
     */
    this.salesStore$.dispatch(new fromSalesStore.RemoveParkedSaleInvoice(parkedInvoice.id));
  }

  /**
   * Open the reset form popup.
   */
  openReset() {
    this.openModal(this.resetModalRef);
  }

  /**
   * Resets the current form data.
   */
  reset(): void {
    this.form.patchValue({ cashValue: 0, electronicValue: 0 });
    this.productsForm.clear();
    this.form.markAsUntouched();
  }

  /**
   * Opens the modal of the given templateRef.
   * @param modalRef The modal templateRef to be opened.
   * @param size An optional modal size, default sm.
   */
  openModal(modalRef, size = 'sm') {
    this.closeModals();
    this.modalService.open(modalRef, { size });

    /**
     * The product search by code input to be focused.
     */
    const element = document.getElementById('pos-product-search-by-code') as HTMLInputElement;
    element.focus();
    element.select();
  }

  /**
   * Closes all of the currently opened modals.
   */
  closeModals() {
    this.modalService.dismissAll();
  }

  /**
   * Confirms leaving the page before routing.
   */
  confirmDeactivate() {
    if (this.form.dirty === true) {
      /**
       * Message to warn the user before leaving the detailed form.
       */
      return confirm(this.translationService.translate('SHARED.CONFIRM.LEAVING'));
    }
    return true;
  }

  /**
   * Prints the sale invoice with the given sale-invoice id.
   * @param invoiceId The id of the sale invoice to be printed.
   */
  print(invoiceId: number): void {
    this.printingStore$.dispatch(
      new fromPrintingStore.GetSaleInvoicePrint({
        saleInvoiceId: invoiceId,
      })
    );
  }
}
