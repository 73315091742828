import { SaleInvoice } from 'sales/models';
import { PaginationInfo } from 'shared/models';

/**
 * Represents the saleInvoices state.
 */
export class SaleInvoicesState {
  /**
   * The list of saleInvoices.
   */
  public data: SaleInvoice[];

  /**
   * The list of unpaid sale invoices.
   */
  public UnpaidInvoicesData: SaleInvoice[];

  /**
   * The pagination info for the saleInvoices.
   */
  public paginationInfo: PaginationInfo;

  /**
   * The pagination info for the unpaid sale invoices.
   */
  public unpaidInvoicesPaginationInfo: PaginationInfo;

  /**
   * An error that may resulting during processing some actions.
   */
  public error: any;

  /**
   * Determines if there is a running search process.
   */
  public isSearching: boolean;

  /**
   * Determines if the last search process has been ended successfully.
   */
  public isSearchCompleted: boolean;

  /**
   * Determines if there is a running unpaid-search process.
   */
  public isSearchingUnpaid: boolean;

  /**
   * Determines if the last unpaid-search process has been ended successfully.
   */
  public isSearchCompletedUnpaid: boolean;

  /**
   * The current selected saleInvoice.
   */
  public selectedSalesInvoice: SaleInvoice;

  /**
   * Determines if there is a running find-saleInvoice process.
   */
  public isFinding: boolean;

  /**
   * Determines if the last find-saleInvoice process has been ended successfully.
   */
  public isFindCompleted: boolean;

  /**
   * Determines if there is a running create process.
   */
  public isCreating: boolean;

  /**
   * Determines if the last create process has been ended successfully.
   */
  public isCreateCompleted: boolean;

  /**
   * Determines if there is a running change maintenance stage process.
   */
  public isChangingMaintenanceStage: boolean;

  /**
   * Determines if the last change maintenance stage process has been ended successfully.
   */
  public isMaintenanceStageChangeCompleted: boolean;

  /**
   * Determines if there is a running create payment process.
   */
  public isCreatingPayment: boolean;

  /**
   * Determines if the last create payment process has been ended successfully.
   */
  public isCreatePaymentCompleted: boolean;
}
