import { Action } from '@ngrx/store';

import { PositionType } from 'lookups/models';
import { AppHttpResponse } from 'shared';

/**
 * The Lookups-Module store  position type action types.
 */
export enum PositionTypeActionType {
  GET_ALL_POSITION_TYPE = '[Lookups] [PositionType] Get All Position Types',
  GET_ALL_POSITION_TYPE_FAIL = '[Lookups] [PositionType] Get All Position Types Fail',
  GET_ALL_POSITION_TYPE_SUCCESS = '[Lookups] [PositionType] Get All Position Types Success',
}

/**
 * Represents a store  position type action.
 */
export class GetAllPositionType implements Action {
  /**
   * The type of the action.
   */
  readonly type = PositionTypeActionType.GET_ALL_POSITION_TYPE;

  /**
   * Fires a new  position type action.
   */
  constructor() {}
}

/**
 * Represents a store  position type fail action.
 */
export class GetAllPositionTypeFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = PositionTypeActionType.GET_ALL_POSITION_TYPE_FAIL;

  /**
   * Fires a new  position type fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store  position type success action.
 */
export class GetAllPositionTypeSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = PositionTypeActionType.GET_ALL_POSITION_TYPE_SUCCESS;

  /**
   * Fires a  position type success action.
   * @param payload An object contains the list of  position Types.
   */
  constructor(public payload: AppHttpResponse<PositionType[]>) {}
}

/**
 * Lookups-module  position type action types.
 */
export type PositionTypeActions = GetAllPositionType | GetAllPositionTypeFail | GetAllPositionTypeSuccess;
