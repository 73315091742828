import { Action } from '@ngrx/store';

import { PaymentDuration } from 'lookups/models';
import { AppHttpResponse } from 'shared';

/**
 * The Lookups-Module store  payment duration action types.
 */
export enum PaymentDurationActionType {
  GET_ALL_PAYMENT_DURATION = '[Lookups] [PaymentDuration] Get All Payment Durations',
  GET_ALL_PAYMENT_DURATION_FAIL = '[Lookups] [PaymentDuration] Get All Payment Durations Fail',
  GET_ALL_PAYMENT_DURATION_SUCCESS = '[Lookups] [PaymentDuration] Get All Payment Durations Success',
}

/**
 * Represents a store  payment duration action.
 */
export class GetAllPaymentDuration implements Action {
  /**
   * The type of the action.
   */
  readonly type = PaymentDurationActionType.GET_ALL_PAYMENT_DURATION;

  /**
   * Fires a new  payment duration action.
   */
  constructor() {}
}

/**
 * Represents a store  payment duration fail action.
 */
export class GetAllPaymentDurationFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = PaymentDurationActionType.GET_ALL_PAYMENT_DURATION_FAIL;

  /**
   * Fires a new  payment duration fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store  payment duration success action.
 */
export class GetAllPaymentDurationSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = PaymentDurationActionType.GET_ALL_PAYMENT_DURATION_SUCCESS;

  /**
   * Fires a  payment duration success action.
   * @param payload An object contains the list of  payment durations.
   */
  constructor(public payload: AppHttpResponse<PaymentDuration[]>) {}
}

/**
 * Lookups-module  payment duration action types.
 */
export type PaymentDurationActions = GetAllPaymentDuration | GetAllPaymentDurationFail | GetAllPaymentDurationSuccess;
