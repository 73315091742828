<div class="row mb-1">
  <div class="col-md-auto">
    <button
      *ngIf="allowAddPaidPayroll"
      type="button"
      class="btn btn-sm btn-raised btn-primary round"
      [ngbTooltip]="'FINANCES.HR.PAID_PAYROLLS.PAID_PAYROLLS_DATA.ADD_PAYROLL' | translate"
      placement="top-right"
      (click)="addPaidPayroll()"
    >
      <i class="fa fa-plus"></i>
    </button>
    <button
      *ngIf="allowClearPaidPayrolls"
      type="button"
      class="btn btn-sm btn-raised btn-danger round mx-2"
      [ngbTooltip]="'FINANCES.HR.PAID_PAYROLLS.PAID_PAYROLLS_DATA.DELETE_PAYROLLS' | translate"
      placement="left"
      (click)="openDeleteModal()"
      [disabled]="formArray.controls.length == 0"
    >
      <i class="fa fa-trash"></i>
    </button>
  </div>
</div>
<div [formGroup]="formArray.parent" class="row">
  <div class="col-md-12">
    <div class="table-responsive">
      <table class="table text-center">
        <thead>
          <tr>
            <th class="fit-width" scope="col"><i class="fa fa-list-ol"></i></th>
            <th class="fit-width" scope="col">
              {{ 'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.EMPLOYEE_NUMBER' | translate }}
            </th>
            <th class="fit-width" scope="col">
              {{ 'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.EMPLOYEE' | translate }}
            </th>
            <th class="fit-width" scope="col">
              {{ 'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.POSITION' | translate }}
            </th>
            <th class="fit-width" scope="col">
              {{ 'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.DEPARTMENT' | translate }}
            </th>
            <th class="fit-width" scope="col">
              {{ 'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.LOCATION' | translate }}
            </th>
            <th class="fit-width" scope="col">
              {{ 'HR.EMPLOYEES.EMPLOYEE_DATA.EMPLOYEE_STATUS' | translate }}
            </th>
            <th class="fit-width" scope="col">
              {{ 'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.NET' | translate }}
            </th>
            <th class="fit-width" scope="col">
              {{ 'HR.EMPLOYEES.EMPLOYEE_DATA.SALARY' | translate }}
            </th>
            <th class="fit-width" scope="col">
              {{ 'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.SALARY_FOR_GOSI' | translate }}
            </th>
            <th class="fit-width" scope="col">
              {{ 'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.EMPLOYEE_GOSI' | translate }}
            </th>
            <th class="fit-width" scope="col">
              {{ 'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.EMPLOYER_GOSI' | translate }}
            </th>
            <th class="fit-width" scope="col">
              {{ 'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.OUT_KAFALA' | translate }}
            </th>
            <th class="fit-width" scope="col">
              {{ 'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.SPONSOR' | translate }}
            </th>
            <th class="fit-width" scope="col">
              {{ 'HR.EMPLOYEES.EMPLOYEE_DATA.TRANSACTION_DATE' | translate }}
            </th>
            <th class="fit-width" scope="col">{{ 'SHARED.SEARCH.CREATED_AT' | translate }}</th>
            <th class="fit-width" scope="col">{{ 'SHARED.TOOLS' | translate }}</th>
          </tr>
        </thead>
        <tbody [formArrayName]="formArrayName">
          <tr [formGroup]="formGroup" *ngFor="let formGroup of formArray.controls; let index = index">
            <th scope="row">{{ index + 1 }}</th>
            <th class="fit-width" scope="row">
              {{ formGroup.value.paidPayroll?.employee?.employeeAssignmentDetail.personNumber }}
            </th>
            <td class="fit-width">
              {{
                formGroup.value.paidPayroll?.employee?.employeePersonalDetail.fullName
                  | localize: formGroup.value.paidPayroll?.employee?.employeePersonalDetail.fullNameEn
                  | async
              }}
            </td>
            <td class="fit-width">
              {{
                formGroup.value.paidPayroll?.employee?.employeeAssignmentDetail.position.name
                  | localize: formGroup.value.paidPayroll?.employee?.employeeAssignmentDetail.position.nameEn
                  | async
              }}
            </td>
            <td class="fit-width">
              {{
                formGroup.value.paidPayroll?.employee?.employeeAssignmentDetail.department?.name
                  | localize: formGroup.value.paidPayroll?.employee?.employeeAssignmentDetail.department?.nameEn
                  | async
              }}
            </td>
            <td class="fit-width">
              {{
                formGroup.value.paidPayroll?.employee?.employeeAssignmentDetail.location?.name
                  | localize: formGroup.value.paidPayroll?.employee?.employeeAssignmentDetail.location?.nameEn
                  | async
              }}
            </td>
            <td class="fit-width">
              {{
                formGroup.value.paidPayroll?.payrollStatus.name
                  | localize: formGroup.value.paidPayroll?.payrollStatus.nameEn
                  | async
              }}
            </td>
            <td class="fit-width">
              <span
                [ngClass]="{
                  'text-danger': formGroup.value.paidPayroll?.net < 0
                }"
              >
                {{ formGroup.value.paidPayroll?.net | toDecimal }}
              </span>
            </td>
            <td class="fit-width">
              <span class="d-block">{{ formGroup.value.paidPayroll?.salary | toDecimal }} </span>
              <span class="text-danger" *ngIf="formGroup.value.paidPayroll?.workDays">
                ({{ formGroup.value.paidPayroll?.workDays }})
                {{ 'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.DAY' | translate }}
              </span>
            </td>
            <td class="fit-width">
              {{ formGroup.value.paidPayroll?.salaryForGosi | toDecimal }}
            </td>
            <td class="fit-width">
              {{ formGroup.value.paidPayroll?.employeeGosi | toDecimal }}
            </td>
            <td class="fit-width">
              {{ formGroup.value.paidPayroll?.employerGosi | toDecimal }}
            </td>
            <td class="fit-width">
              <app-status
                [status]="true"
                [activeText]="'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.OUT_KAFALA' | translate"
                *ngIf="formGroup.value.paidPayroll?.isOutKafala"
              >
              </app-status>
            </td>
            <td class="fit-width">
              {{
                formGroup.value.paidPayroll?.employeeSponsor?.name
                  | localize: formGroup.value.paidPayroll?.employeeSponsor?.nameEn
                  | async
              }}
            </td>
            <td class="fit-width">
              <span [ngbTooltip]="formGroup.value.paidPayroll?.transactionDate | date: 'time'">
                {{ formGroup.value.paidPayroll?.transactionDate | date }}
              </span>
            </td>
            <td class="fit-width">
              <span [ngbTooltip]="formGroup.value.paidPayroll?.createdAt | date: 'time'">
                {{ formGroup.value.paidPayroll?.createdAt | date }}
              </span>
            </td>
            <td>
              <a
                class="danger p-0"
                [ngbTooltip]="'SHARED.CONFIRM.DELETE' | translate"
                (click)="removePaidPayroll(index)"
              >
                <i class="fa fa-close font-medium-3 mr-2"></i>
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>

<!-- paid payrolls search starts -->
<app-paid-payrolls-search
  [onlyActive]="true"
  [closeOnSelect]="false"
  (paidPayrolls)="selectPaidPayroll($event)"
  [(visible)]="paidPayrollsListVisibility"
>
</app-paid-payrolls-search>
<!-- paid payrolls search ends -->

<!-- confirm delete all paid payrolls modal starts -->
<ng-template #deleteModalRef let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title text-danger" id="modal-basic-title">
      {{ 'FINANCES.HR.PAID_PAYROLLS.PAID_PAYROLLS_DATA.CONFIRM_DELETE_ALL_PAYROLLS_TITLE' | translate }}
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="d()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p class="text-danger">
      {{ 'FINANCES.HR.PAID_PAYROLLS.PAID_PAYROLLS_DATA.CONFIRM_DELETE_ALL_PAYROLLS_MESSAGE' | translate }}
    </p>
  </div>
  <div class="modal-footer">
    <div class="modal-actions">
      <button type="button" class="btn btn-raised btn-danger round" (click)="c(); resetPaidPayrolls()" ngbAutofocus>
        <i class="fa fa-check"></i>
        <span class="mx-1"> {{ 'SHARED.CONFIRM.YES' | translate }} </span>
      </button>
      <button type="button" class="btn btn-outline-dark round" (click)="c()">
        <i class="fa fa-times"></i>
        <span class="mx-1"> {{ 'SHARED.CONFIRM.CANCEL' | translate }} </span>
      </button>
    </div>
  </div>
</ng-template>
<!-- confirm modal ends -->
