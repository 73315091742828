import { createSelector } from '@ngrx/store';

import { selectLookupsState } from 'lookups/store/selectors/feature.selectors';
import { LookupsState, NationalIdentifierTypeState } from 'lookups/store/states';

/**
 * Gets the national identifier type state.
 */
const selectNationalIdentifierTypes = createSelector(
  selectLookupsState,
  (state: LookupsState) => state.nationalIdentifierTypes
);

/**
 * Gets the list of loaded national identifier types.
 */
export const getNationalIdentifierTypes = createSelector(
  selectNationalIdentifierTypes,
  (state: NationalIdentifierTypeState) => state.data
);

/**
 * Gets the error that occurred in the state.
 */
export const getNationalIdentifierTypesError = createSelector(
  selectNationalIdentifierTypes,
  (state: NationalIdentifierTypeState) => state.error
);

/**
 * Determines if there is a running load process.
 */
export const getNationalIdentifierTypesIsLoading = createSelector(
  selectNationalIdentifierTypes,
  (state: NationalIdentifierTypeState) => state.isLoading
);

/**
 * Determines if the last load process has been ended successfully.
 */
export const getNationalIdentifierTypesIsLoaded = createSelector(
  selectNationalIdentifierTypes,
  (state: NationalIdentifierTypeState) => state.isLoaded
);
