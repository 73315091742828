import { PaginationInfo } from 'shared/models';
import { Department } from 'hr/models';

/**
 * Represents the departments state.
 */
export class DepartmentsState {
  /**
   * The list of departments.
   */
  public data: Department[];

  /**
   * The pagination info for the departments.
   */
  public paginationInfo: PaginationInfo;

  /**
   * An error that may resulting during processing some actions.
   */
  public error: any;

  /**
   * Determines if there is a running search process.
   */
  isSearching: boolean;

  /**
   * Determines if the last search process has been ended successfully.
   */
  isSearchCompleted: boolean;
}
