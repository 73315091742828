import { createSelector } from '@ngrx/store';

import { selectLookupsState } from 'lookups/store/selectors/feature.selectors';
import { LookupsState, LocationTypeState } from 'lookups/store/states';

/**
 * Gets the location type state.
 */
const selectLocationTypes = createSelector(selectLookupsState, (state: LookupsState) => state.locationTypes);

/**
 * Gets the list of loaded location types.
 */
export const getLocationTypes = createSelector(selectLocationTypes, (state: LocationTypeState) => state.data);

/**
 * Gets the error that occurred in the state.
 */
export const getLocationTypesError = createSelector(selectLocationTypes, (state: LocationTypeState) => state.error);

/**
 * Determines if there is a running load process.
 */
export const getLocationTypesIsLoading = createSelector(
  selectLocationTypes,
  (state: LocationTypeState) => state.isLoading
);

/**
 * Determines if the last load process has been ended successfully.
 */
export const getLocationTypesIsLoaded = createSelector(
  selectLocationTypes,
  (state: LocationTypeState) => state.isLoaded
);
