import { findIndex, remove } from 'lodash';

import { HrActions, HrActionType } from 'finances/store/actions';
import { HrState } from 'finances/store/states';

/**
 * The initial state from which the state starts.
 */
const initialState: HrState = {
  payrollsData: [],
  paidPayrollsData: [],
  preparedPaidPayrollsData: [],
  payrollPaymentsData: [],
  payrollPaymentRefundsData: [],
  employeeLoansData: [],
  employeeLoanSettlementsData: [],
  govServiceRequestsData: [],
  employeeLoansPaginationInfo: {
    total: 0,
    page: 1,
    pageSize: 0,
    count: 0,
  },
  payrollsPaginationInfo: {
    total: 0,
    page: 1,
    pageSize: 0,
    count: 0,
  },
  paidPayrollsPaginationInfo: {
    total: 0,
    page: 1,
    pageSize: 0,
    count: 0,
  },
  employeeLoanSettlementsPaginationInfo: {
    total: 0,
    page: 1,
    pageSize: 0,
    count: 0,
  },
  payrollPaymentsPaginationInfo: {
    total: 0,
    page: 1,
    pageSize: 0,
    count: 0,
  },
  payrollPaymentRefundsPaginationInfo: {
    total: 0,
    page: 1,
    pageSize: 0,
    count: 0,
  },
  govServiceRequestsPaginationInfo: {
    total: 0,
    page: 1,
    pageSize: 0,
    count: 0,
  },
  selectedPayroll: null,
  selectedPayrollPayment: null,
  selectedPayrollPaymentRefund: null,
  selectedGovServiceRequest: null,
  isPayrollDeleting: false,
  isPayrollDeleteCompleted: false,
  error: null,
  isEmployeeLoansSearching: false,
  isEmployeeLoansSearchCompleted: false,
  isPayrollPaymentsSearching: false,
  isPayrollPaymentsSearchCompleted: false,
  isPayrollPaymentRefundsSearching: false,
  isPayrollPaymentRefundsSearchCompleted: false,
  isEmployeeLoanSettlementsSearching: false,
  isEmployeeLoanSettlementsSearchCompleted: false,
  selectedEmployeeLoan: null,
  isEmployeeLoanUpdating: false,
  isEmployeeLoanUpdateCompleted: false,
  isEmployeeLoanCreating: false,
  isEmployeeLoanCreateCompleted: false,
  isPayrollPaymentCreating: false,
  isPayrollPaymentCreateCompleted: false,
  isPayrollPaymentRefundCreating: false,
  isPayrollPaymentRefundCreateCompleted: false,
  isPayingPayroll: false,
  isPayPayrollCompleted: false,
  isPayrollsSearching: false,
  isPayrollsSearchCompleted: false,
  isPaidPayrollsSearching: false,
  isPaidPayrollsSearchCompleted: false,
  isPayrollPaymentFinding: false,
  isPayrollPaymentFindCompleted: false,
  isPayrollPaymentRefundFinding: false,
  isPayrollPaymentRefundFindCompleted: false,
  isGovServiceRequestsSearching: false,
  isGovServiceRequestsSearchCompleted: false,
  isGovServiceRequestFinding: false,
  isGovServiceRequestFindCompleted: false,
  isPayGovServiceRequestCreating: false,
  isPayGovServiceRequestCreateCompleted: false,
};

/**
 * The reducer function that is called in each action dispatch against the state.
 * @param state The current state.
 * @param action The action that will affect the state.
 */
export function hrReducer(state: HrState = initialState, action: HrActions): HrState {
  switch (action.type) {
    //#region SEARCH_EMPLOYEE_LOANS

    case HrActionType.SEARCH_EMPLOYEE_LOANS: {
      return {
        ...state,
        isEmployeeLoansSearching: true,
        isEmployeeLoansSearchCompleted: false,
        error: null,
      };
    }

    case HrActionType.SEARCH_EMPLOYEE_LOANS_FAIL: {
      return {
        ...state,
        error: action.payload,
        isEmployeeLoansSearching: false,
        isEmployeeLoansSearchCompleted: false,
      };
    }

    case HrActionType.SEARCH_EMPLOYEE_LOANS_SUCCESS: {
      return {
        ...state,
        employeeLoansData: action.payload.data,
        employeeLoansPaginationInfo: {
          ...action.payload.meta,
        },
        error: null,
        isEmployeeLoansSearching: false,
        isEmployeeLoansSearchCompleted: true,
      };
    }
    // #endregion SEARCH_EMPLOYEE_LOANS

    //#region SEARCH_EMPLOYEE_LOAN_SETTLEMENTS

    case HrActionType.SEARCH_EMPLOYEE_LOAN_SETTLEMENTS: {
      return {
        ...state,
        isEmployeeLoanSettlementsSearching: true,
        isEmployeeLoanSettlementsSearchCompleted: false,
        error: null,
      };
    }

    case HrActionType.SEARCH_EMPLOYEE_LOAN_SETTLEMENTS_FAIL: {
      return {
        ...state,
        error: action.payload,
        isEmployeeLoanSettlementsSearching: false,
        isEmployeeLoanSettlementsSearchCompleted: false,
      };
    }

    case HrActionType.SEARCH_EMPLOYEE_LOAN_SETTLEMENTS_SUCCESS: {
      return {
        ...state,
        employeeLoanSettlementsData: action.payload.data,
        employeeLoanSettlementsPaginationInfo: {
          ...action.payload.meta,
        },
        error: null,
        isEmployeeLoanSettlementsSearching: false,
        isEmployeeLoanSettlementsSearchCompleted: true,
      };
    }
    // #endregion SEARCH_EMPLOYEE_LOAN_SETTLEMENTS

    //#region SEARCH_PAYROLLS

    case HrActionType.SEARCH_PAYROLLS: {
      return {
        ...state,
        isPayrollsSearching: true,
        isPayrollsSearchCompleted: false,
        error: null,
      };
    }

    case HrActionType.SEARCH_PAYROLLS_FAIL: {
      return {
        ...state,
        error: action.payload,
        isPayrollsSearching: false,
        isPayrollsSearchCompleted: false,
      };
    }

    case HrActionType.SEARCH_PAYROLLS_SUCCESS: {
      return {
        ...state,
        payrollsData: action.payload.data,
        payrollsPaginationInfo: {
          ...action.payload.meta,
        },
        error: null,
        isPayrollsSearching: false,
        isPayrollsSearchCompleted: true,
      };
    }

    // #endregion SEARCH_PAYROLLS

    //#region SEARCH_PAID_PAYROLLS

    case HrActionType.SEARCH_PAID_PAYROLLS: {
      return {
        ...state,
        isPaidPayrollsSearching: true,
        isPaidPayrollsSearchCompleted: false,
        error: null,
      };
    }

    case HrActionType.SEARCH_PAID_PAYROLLS_FAIL: {
      return {
        ...state,
        error: action.payload,
        isPaidPayrollsSearching: false,
        isPaidPayrollsSearchCompleted: false,
      };
    }

    case HrActionType.SEARCH_PAID_PAYROLLS_SUCCESS: {
      return {
        ...state,
        paidPayrollsData: action.payload.data,
        paidPayrollsPaginationInfo: {
          ...action.payload.meta,
        },
        error: null,
        isPaidPayrollsSearching: false,
        isPaidPayrollsSearchCompleted: true,
      };
    }

    // #endregion SEARCH_PAID_PAYROLLS

    //#region SEARCH_PAYROLL_PAYMENTS

    case HrActionType.SEARCH_PAYROLL_PAYMENTS: {
      return {
        ...state,
        isPayrollPaymentsSearching: true,
        isPayrollPaymentsSearchCompleted: false,
        error: null,
      };
    }

    case HrActionType.SEARCH_PAYROLL_PAYMENTS_FAIL: {
      return {
        ...state,
        error: action.payload,
        isPayrollPaymentsSearching: false,
        isPayrollPaymentsSearchCompleted: false,
      };
    }

    case HrActionType.SEARCH_PAYROLL_PAYMENTS_SUCCESS: {
      return {
        ...state,
        payrollPaymentsData: action.payload.data,
        payrollPaymentsPaginationInfo: {
          ...action.payload.meta,
        },
        error: null,
        isPayrollPaymentsSearching: false,
        isPayrollPaymentsSearchCompleted: true,
      };
    }

    // #endregion SEARCH_PAYROLL_PAYMENTS

    //#region SEARCH_PAYROLL_PAYMENT_REFUNDS

    case HrActionType.SEARCH_PAYROLL_PAYMENT_REFUNDS: {
      return {
        ...state,
        isPayrollPaymentRefundsSearching: true,
        isPayrollPaymentRefundsSearchCompleted: false,
        error: null,
      };
    }

    case HrActionType.SEARCH_PAYROLL_PAYMENT_REFUNDS_FAIL: {
      return {
        ...state,
        error: action.payload,
        isPayrollPaymentRefundsSearching: false,
        isPayrollPaymentRefundsSearchCompleted: false,
      };
    }

    case HrActionType.SEARCH_PAYROLL_PAYMENT_REFUNDS_SUCCESS: {
      return {
        ...state,
        payrollPaymentRefundsData: action.payload.data,
        payrollPaymentRefundsPaginationInfo: {
          ...action.payload.meta,
        },
        error: null,
        isPayrollPaymentRefundsSearching: false,
        isPayrollPaymentRefundsSearchCompleted: true,
      };
    }

    // #endregion SEARCH_PAYROLL_PAYMENT_REFUNDS

    //#region SEARCH_GOV_SERVICE_REQUESTS

    case HrActionType.SEARCH_GOV_SERVICE_REQUESTS: {
      return {
        ...state,
        isGovServiceRequestsSearching: true,
        isGovServiceRequestsSearchCompleted: false,
        error: null,
      };
    }

    case HrActionType.SEARCH_GOV_SERVICE_REQUESTS_FAIL: {
      return {
        ...state,
        error: action.payload,
        isGovServiceRequestsSearching: false,
        isGovServiceRequestsSearchCompleted: false,
      };
    }

    case HrActionType.SEARCH_GOV_SERVICE_REQUESTS_SUCCESS: {
      return {
        ...state,
        govServiceRequestsData: action.payload.data,
        govServiceRequestsPaginationInfo: {
          ...action.payload.meta,
        },
        error: null,
        isGovServiceRequestsSearching: false,
        isGovServiceRequestsSearchCompleted: true,
      };
    }

    // #endregion SEARCH_GOV_SERVICE_REQUESTS

    //#region CREATE_EMPLOYEE_LOAN

    case HrActionType.CREATE_EMPLOYEE_LOAN: {
      return {
        ...state,
        error: null,
        isEmployeeLoanCreating: true,
        isEmployeeLoanCreateCompleted: false,
      };
    }

    case HrActionType.CREATE_EMPLOYEE_LOAN_FAIL: {
      return {
        ...state,
        error: action.payload,
        isEmployeeLoanCreating: false,
        isEmployeeLoanCreateCompleted: false,
      };
    }

    case HrActionType.CREATE_EMPLOYEE_LOAN_SUCCESS: {
      return {
        ...state,
        employeeLoansData: [action.payload.data.loan, ...state.employeeLoansData],
        error: null,
        isEmployeeLoanCreating: false,
        isEmployeeLoanCreateCompleted: true,
      };
    }

    //#endregion CREATE_EMPLOYEE_LOAN

    //#region UPDATE_EMPLOYEE_LOAN

    case HrActionType.UPDATE_EMPLOYEE_LOAN: {
      return {
        ...state,
        error: null,
        isEmployeeLoanUpdating: true,
        isEmployeeLoanUpdateCompleted: false,
      };
    }

    case HrActionType.UPDATE_EMPLOYEE_LOAN_FAIL: {
      return {
        ...state,
        error: action.payload,
        isEmployeeLoanUpdating: false,
        isEmployeeLoanUpdateCompleted: false,
      };
    }

    case HrActionType.UPDATE_EMPLOYEE_LOAN_SUCCESS: {
      let loans = [...state.employeeLoansData];
      const loanIndex = findIndex(loans, (loan) => loan.id === action.payload.data.id);

      /* If loan was found, update it. */
      if (loanIndex >= 0) {
        loans[loanIndex] = action.payload.data;
      } else {
        /* else, insert it to the beginning of the array. */
        loans = [action.payload.data, ...loans];
      }

      return {
        ...state,
        employeeLoansData: loans,
        selectedEmployeeLoan: action.payload.data,
        error: null,
        isEmployeeLoanUpdating: false,
        isEmployeeLoanUpdateCompleted: true,
      };
    }

    //#endregion UPDATE_EMPLOYEE_LOAN

    //#region CREATE_PAYROLL_PAYMENT

    case HrActionType.CREATE_PAYROLL_PAYMENT: {
      return {
        ...state,
        error: null,
        isPayrollPaymentCreating: true,
        isPayrollPaymentCreateCompleted: false,
      };
    }

    case HrActionType.CREATE_PAYROLL_PAYMENT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isPayrollPaymentCreating: false,
        isPayrollPaymentCreateCompleted: false,
      };
    }

    case HrActionType.CREATE_PAYROLL_PAYMENT_SUCCESS: {
      return {
        ...state,
        payrollPaymentsData: [action.payload.data, ...state.payrollPaymentsData],
        preparedPaidPayrollsData: [],
        error: null,
        isPayrollPaymentCreating: false,
        isPayrollPaymentCreateCompleted: true,
      };
    }

    //#endregion CREATE_PAY_PAYROLLS

    //#region CREATE_PAYROLL_PAYMENT_REFUND

    case HrActionType.CREATE_PAYROLL_PAYMENT_REFUND: {
      return {
        ...state,
        error: null,
        isPayrollPaymentRefundCreating: true,
        isPayrollPaymentRefundCreateCompleted: false,
      };
    }

    case HrActionType.CREATE_PAYROLL_PAYMENT_REFUND_FAIL: {
      return {
        ...state,
        error: action.payload,
        isPayrollPaymentRefundCreating: false,
        isPayrollPaymentRefundCreateCompleted: false,
      };
    }

    case HrActionType.CREATE_PAYROLL_PAYMENT_REFUND_SUCCESS: {
      return {
        ...state,
        payrollPaymentRefundsData: [action.payload.data, ...state.payrollPaymentRefundsData],
        preparedPaidPayrollsData: [],
        error: null,
        isPayrollPaymentRefundCreating: false,
        isPayrollPaymentRefundCreateCompleted: true,
      };
    }

    //#endregion CREATE_PAY_PAYROLLS

    //#region PAY_PAYROLL

    case HrActionType.PAY_PAYROLL: {
      return {
        ...state,
        error: null,
        isPayingPayroll: true,
        isPayPayrollCompleted: false,
      };
    }

    case HrActionType.PAY_PAYROLL_FAIL: {
      return {
        ...state,
        error: action.payload,
        isPayingPayroll: false,
        isPayPayrollCompleted: false,
      };
    }

    case HrActionType.PAY_PAYROLL_SUCCESS: {
      return {
        ...state,
        preparedPaidPayrollsData: action.payload.data,
        error: null,
        isPayingPayroll: false,
        isPayPayrollCompleted: true,
      };
    }

    //#endregion PAY_PAYROLL

    //#region CREATE_PAY_GOV_SERVICE_REQUEST

    case HrActionType.CREATE_PAY_GOV_SERVICE_REQUEST: {
      return {
        ...state,
        error: null,
        isPayGovServiceRequestCreating: true,
        isPayGovServiceRequestCreateCompleted: false,
      };
    }

    case HrActionType.CREATE_PAY_GOV_SERVICE_REQUEST_FAIL: {
      return {
        ...state,
        error: action.payload,
        isPayGovServiceRequestCreating: false,
        isPayGovServiceRequestCreateCompleted: false,
      };
    }

    case HrActionType.CREATE_PAY_GOV_SERVICE_REQUEST_SUCCESS: {
      let govServiceRequestsData = [...state.govServiceRequestsData];
      const govServiceRequestIndex = findIndex(
        govServiceRequestsData,
        (govServiceRequest) => govServiceRequest.id === action.payload.data.id
      );

      /* If request was found, update it. */
      if (govServiceRequestIndex >= 0) {
        govServiceRequestsData[govServiceRequestIndex] = action.payload.data;
      } else {
        /* else, insert it to the beginning of the array. */
        govServiceRequestsData = [action.payload.data, ...govServiceRequestsData];
      }

      return {
        ...state,
        govServiceRequestsData,
        selectedGovServiceRequest: action.payload.data,
        error: null,
        isPayGovServiceRequestCreating: false,
        isPayGovServiceRequestCreateCompleted: true,
      };
    }

    //#endregion CREATE_PAY_GOV_SERVICE_REQUEST

    //#region DELETE_EMPLOYEE_PAYROLL

    case HrActionType.DELETE_PAYROLL: {
      return {
        ...state,
        error: null,
        isPayrollDeleting: true,
        isPayrollDeleteCompleted: false,
      };
    }

    case HrActionType.DELETE_PAYROLL_FAIL: {
      return {
        ...state,
        error: action.payload.data,
        isPayrollDeleting: false,
        isPayrollDeleteCompleted: false,
      };
    }

    case HrActionType.DELETE_PAYROLL_SUCCESS: {
      const payrolls = [...state.payrollsData];

      /* Remove the deleted payroll from the array. */
      remove(payrolls, (payroll) => payroll.id === action.payload.data.id);

      return {
        ...state,
        payrollsData: payrolls,
        selectedPayroll: action.payload.data,
        error: null,
        isPayrollDeleting: false,
        isPayrollDeleteCompleted: true,
      };
    }

    //#endregion DELETE_PAYROLL

    //#region FIND_PAYROLL_PAYMENT

    case HrActionType.FIND_PAYROLL_PAYMENT: {
      return {
        ...state,
        selectedPayrollPayment: null,
        error: null,
        isPayrollPaymentFinding: true,
        isPayrollPaymentFindCompleted: false,
      };
    }

    case HrActionType.FIND_PAYROLL_PAYMENT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isPayrollPaymentFinding: false,
        isPayrollPaymentFindCompleted: false,
      };
    }

    case HrActionType.FIND_PAYROLL_PAYMENT_SUCCESS: {
      let payrollPaymentsData = [...state.payrollPaymentsData];
      const payrollPaymentIndex = findIndex(
        payrollPaymentsData,
        (payrollPayment) => payrollPayment.id === action.payload.data.id
      );

      /* If payment payroll was found, update it. */
      if (payrollPaymentIndex >= 0) {
        payrollPaymentsData[payrollPaymentIndex] = action.payload.data;
      } else {
        /* else, insert it to the beginning of the array. */
        payrollPaymentsData = [action.payload.data, ...payrollPaymentsData];
      }

      return {
        ...state,
        payrollPaymentsData,
        selectedPayrollPayment: action.payload.data,
        error: null,
        isPayrollPaymentFinding: false,
        isPayrollPaymentFindCompleted: true,
      };
    }

    //#endregion FIND_PAYROLL_PAYMENT

    //#region FIND_PAYROLL_PAYMENT_REFUND

    case HrActionType.FIND_PAYROLL_PAYMENT_REFUND: {
      return {
        ...state,
        selectedPayrollPaymentRefund: null,
        error: null,
        isPayrollPaymentRefundFinding: true,
        isPayrollPaymentRefundFindCompleted: false,
      };
    }

    case HrActionType.FIND_PAYROLL_PAYMENT_REFUND_FAIL: {
      return {
        ...state,
        error: action.payload,
        isPayrollPaymentRefundFinding: false,
        isPayrollPaymentRefundFindCompleted: false,
      };
    }

    case HrActionType.FIND_PAYROLL_PAYMENT_REFUND_SUCCESS: {
      let payrollPaymentRefundsData = [...state.payrollPaymentRefundsData];
      const payrollPaymentRefundIndex = findIndex(
        payrollPaymentRefundsData,
        (payrollPaymentRefund) => payrollPaymentRefund.id === action.payload.data.id
      );

      /* If payment payroll refund was found, update it. */
      if (payrollPaymentRefundIndex >= 0) {
        payrollPaymentRefundsData[payrollPaymentRefundIndex] = action.payload.data;
      } else {
        /* else, insert it to the beginning of the array. */
        payrollPaymentRefundsData = [action.payload.data, ...payrollPaymentRefundsData];
      }

      return {
        ...state,
        payrollPaymentRefundsData,
        selectedPayrollPaymentRefund: action.payload.data,
        error: null,
        isPayrollPaymentRefundFinding: false,
        isPayrollPaymentRefundFindCompleted: true,
      };
    }

    //#endregion FIND_PAYROLL_PAYMENT_REFUND

    //#region FIND_GOV_SERVICE_REQUEST

    case HrActionType.FIND_GOV_SERVICE_REQUEST: {
      return {
        ...state,
        selectedGovServiceRequest: null,
        error: null,
        isGovServiceRequestFinding: true,
        isGovServiceRequestFindCompleted: false,
      };
    }

    case HrActionType.FIND_GOV_SERVICE_REQUEST_FAIL: {
      return {
        ...state,
        error: action.payload,
        isGovServiceRequestFinding: false,
        isGovServiceRequestFindCompleted: false,
      };
    }

    case HrActionType.FIND_GOV_SERVICE_REQUEST_SUCCESS: {
      let govServiceRequestsData = [...state.govServiceRequestsData];
      const govServiceRequestIndex = findIndex(
        govServiceRequestsData,
        (govServiceRequest) => govServiceRequest.id === action.payload.data.id
      );

      /* If request was found, update it. */
      if (govServiceRequestIndex >= 0) {
        govServiceRequestsData[govServiceRequestIndex] = action.payload.data;
      } else {
        /* else, insert it to the beginning of the array. */
        govServiceRequestsData = [action.payload.data, ...govServiceRequestsData];
      }

      return {
        ...state,
        govServiceRequestsData,
        selectedGovServiceRequest: action.payload.data,
        error: null,
        isGovServiceRequestFinding: false,
        isGovServiceRequestFindCompleted: true,
      };
    }

    //#endregion FIND_GOV_SERVICE_REQUEST

    default:
      return state;
  }
}
