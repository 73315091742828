import { findIndex } from 'lodash';

import {
  PettyCashRefundRequestApprovalsActions,
  PettyCashRefundRequestApprovalsActionType,
} from 'finances/store/actions';
import { PettyCashRefundRequestApprovalsState } from 'finances/store/states';

/**
 * The initial state from which the state starts.
 */
const initialState: PettyCashRefundRequestApprovalsState = {
  data: [],
  paginationInfo: {
    total: 0,
    page: 1,
    pageSize: 0,
    count: 0,
  },
  error: null,
  isSearching: false,
  isSearchCompleted: false,
  selectedPettyCashRefundRequestApproval: null,
  isFinding: false,
  isFindCompleted: false,
  isCreating: false,
  isCreateCompleted: false,
};

/**
 * The reducer function that is called in each action dispatch against the state.
 * @param state The current state.
 * @param action The action that will affect the state.
 */
export function pettyCashRefundRequestApprovalsReducer(
  state: PettyCashRefundRequestApprovalsState = initialState,
  action: PettyCashRefundRequestApprovalsActions
): PettyCashRefundRequestApprovalsState {
  switch (action.type) {
    //#region SEARCH_PETTY_CASH_REFUND_REQUEST_APPROVALS

    case PettyCashRefundRequestApprovalsActionType.SEARCH_PETTY_CASH_REFUND_REQUEST_APPROVALS: {
      return {
        ...state,
        isSearching: true,
        isSearchCompleted: false,
        error: null,
      };
    }

    case PettyCashRefundRequestApprovalsActionType.SEARCH_PETTY_CASH_REFUND_REQUEST_APPROVALS_FAIL: {
      return {
        ...state,
        error: action.payload,
        isSearching: false,
        isSearchCompleted: false,
      };
    }

    case PettyCashRefundRequestApprovalsActionType.SEARCH_PETTY_CASH_REFUND_REQUEST_APPROVALS_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        paginationInfo: {
          ...action.payload.meta,
        },
        error: null,
        isSearching: false,
        isSearchCompleted: true,
      };
    }

    //#endregion SEARCH_PETTY_CASH_REFUND_REQUEST_APPROVALS

    //#region FIND_PETTY_CASH_REFUND_REQUEST_APPROVAL

    case PettyCashRefundRequestApprovalsActionType.FIND_PETTY_CASH_REFUND_REQUEST_APPROVAL: {
      return {
        ...state,
        selectedPettyCashRefundRequestApproval: null,
        error: null,
        isFinding: true,
        isFindCompleted: false,
      };
    }

    case PettyCashRefundRequestApprovalsActionType.FIND_PETTY_CASH_REFUND_REQUEST_APPROVAL_FAIL: {
      return {
        ...state,
        error: action.payload,
        isFinding: false,
        isFindCompleted: false,
      };
    }

    case PettyCashRefundRequestApprovalsActionType.FIND_PETTY_CASH_REFUND_REQUEST_APPROVAL_SUCCESS: {
      let pettyCashRefundRequestApprovalsList = [...state.data];
      const pettyCashRefundRequestApprovalIndex = findIndex(
        pettyCashRefundRequestApprovalsList,
        (pettyCashRefundRequestApproval) => pettyCashRefundRequestApproval.id === action.payload.data.id
      );

      /* If petty cash refund request approval was found, update it. */
      if (pettyCashRefundRequestApprovalIndex >= 0) {
        pettyCashRefundRequestApprovalsList[pettyCashRefundRequestApprovalIndex] = action.payload.data;
      } else {
        /* else, insert it to the beginning of the array. */
        pettyCashRefundRequestApprovalsList = [action.payload.data, ...pettyCashRefundRequestApprovalsList];
      }

      return {
        ...state,
        data: pettyCashRefundRequestApprovalsList,
        selectedPettyCashRefundRequestApproval: action.payload.data,
        error: null,
        isFinding: false,
        isFindCompleted: true,
      };
    }

    //#endregion FIND_PETTY_CASH_REFUND_REQUEST_APPROVAL

    //#region CREATE_PETTY_CASH_REFUND_REQUEST_APPROVAL

    case PettyCashRefundRequestApprovalsActionType.CREATE_PETTY_CASH_REFUND_REQUEST_APPROVAL: {
      return {
        ...state,
        isCreating: true,
        isCreateCompleted: false,
        error: null,
      };
    }

    case PettyCashRefundRequestApprovalsActionType.CREATE_PETTY_CASH_REFUND_REQUEST_APPROVAL_FAIL: {
      return {
        ...state,
        error: action.payload,
        isCreating: false,
        isCreateCompleted: false,
      };
    }

    case PettyCashRefundRequestApprovalsActionType.CREATE_PETTY_CASH_REFUND_REQUEST_APPROVAL_SUCCESS: {
      return {
        ...state,
        data: [action.payload.data, ...state.data],
        selectedPettyCashRefundRequestApproval: action.payload.data,
        error: null,
        isCreating: false,
        isCreateCompleted: true,
      };
    }

    //#endregion CREATE_PETTY_CASH_REFUND_REQUEST_APPROVAL

    default:
      return state;
  }
}
