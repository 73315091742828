import { createSelector } from '@ngrx/store';

import { selectFinancesState } from 'finances/store/selectors/feature.selectors';
import { FinancesState, PettyCashRefundRequestApprovalsState } from 'finances/store/states';

/* Selectors */

/**
 * Gets the petty cash refund request approvals state.
 */
const selectPettyCashRefundRequestApprovalState = createSelector(
  selectFinancesState,
  (state: FinancesState) => state.pettyCashRefundRequestApprovals
);

/**
 * Gets the list of loaded petty cash refund request approval approvals.
 */
export const getPettyCashRefundRequestApprovals = createSelector(
  selectPettyCashRefundRequestApprovalState,
  (state: PettyCashRefundRequestApprovalsState) => state.data
);

/**
 * Gets the petty cash refund request approvals pagination info.
 */
export const getPettyCashRefundRequestApprovalsPaginationInfo = createSelector(
  selectPettyCashRefundRequestApprovalState,
  (state: PettyCashRefundRequestApprovalsState) => state.paginationInfo
);

/**
 * Gets the error that occurred in the state.
 */
export const getPettyCashRefundRequestApprovalsError = createSelector(
  selectPettyCashRefundRequestApprovalState,
  (state: PettyCashRefundRequestApprovalsState) => state.error
);

/**
 * Determines if there is a running search process.
 */
export const getPettyCashRefundRequestApprovalsSearching = createSelector(
  selectPettyCashRefundRequestApprovalState,
  (state: PettyCashRefundRequestApprovalsState) => state.isSearching
);

/**
 * Determines if the last search process has been ended successfully.
 */
export const getPettyCashRefundRequestApprovalsSearchCompleted = createSelector(
  selectPettyCashRefundRequestApprovalState,
  (state: PettyCashRefundRequestApprovalsState) => state.isSearchCompleted
);

/**
 * Gets the selected petty cash refund request approval.
 */
export const getSelectedPettyCashRefundRequestApproval = createSelector(
  selectPettyCashRefundRequestApprovalState,
  (state: PettyCashRefundRequestApprovalsState) => state.selectedPettyCashRefundRequestApproval
);

/**
 * Determines if there is a running find-petty-cash-refund-request-approval process.
 */
export const getPettyCashRefundRequestApprovalFinding = createSelector(
  selectPettyCashRefundRequestApprovalState,
  (state: PettyCashRefundRequestApprovalsState) => state.isFinding
);

/**
 * Determines if the last find-petty-cash-refund-request-approval process has been ended successfully.
 */
export const getPettyCashRefundRequestApprovalFindCompleted = createSelector(
  selectPettyCashRefundRequestApprovalState,
  (state: PettyCashRefundRequestApprovalsState) => state.isFindCompleted
);

/**
 * Determines if there is a running create process.
 */
export const getSelectedPettyCashRefundRequestApprovalCreating = createSelector(
  selectPettyCashRefundRequestApprovalState,
  (state: PettyCashRefundRequestApprovalsState) => state.isCreating
);

/**
 * Determines if the last create process has been ended successfully.
 */
export const getSelectedPettyCashRefundRequestApprovalCreateCompleted = createSelector(
  selectPettyCashRefundRequestApprovalState,
  (state: PettyCashRefundRequestApprovalsState) => state.isCreateCompleted
);
