<!-- pos devices list starts -->
<div class="row">
  <div class="col-md-12">
    <div class="card m-0">
      <div class="card-content">
        <div class="card-header mb-3">
          <h4 class="card-title">{{ 'SHARED.SEARCH.SEARCH_TOOLS' | translate }}</h4>
        </div>
        <div class="row">
          <div class="col-md-12">
            <form class="row" [formGroup]="searchForm" (ngSubmit)="search()">
              <div class="form-group col">
                <label for="name">{{ 'SALES.POS_DEVICES.POS_DEVICE_NAME_SEARCH' | translate }}</label>
                <input
                  type="text"
                  id="name"
                  class="form-control round"
                  [placeholder]="'SALES.POS_DEVICES.POS_DEVICE_NAME_PLACEHOLDER' | translate"
                  formControlName="name"
                  tabindex="0"
                  (input)="search($event)"
                  ngbAutofocus
                />
              </div>
              <div class="form-group col">
                <label for="locations">{{ 'SALES.POS_DEVICES.LOCATIONS' | translate }}</label>
                <ng-select
                  class="round"
                  formControlName="locations"
                  [multiple]="true"
                  [closeOnSelect]="false"
                  labelForId="locations"
                  searchable="true"
                  [placeholder]="'SALES.POS_DEVICES.LOCATIONS_PLACEHOLDER' | translate"
                  [notFoundText]="'SHARED.SEARCH.SEARCH_NOT_FOUND' | translate"
                  (open)="locationsListVisibility = true"
                >
                  <ng-option *ngFor="let location of locations" [value]="location.id">
                    {{ location.name | localize: location.nameEn | async }}
                  </ng-option>
                </ng-select>
              </div>
              <div class="col-md-auto form-group d-flex">
                <button
                  type="submit"
                  class="btn btn-raised btn-primary align-self-end round mx-1 mb-0"
                  [disabled]="isSearching$ | async"
                >
                  <i class="fa fa-search"></i>
                </button>
                <a
                  *ngIf="[claims.CREATE_POS_DEVICE] | authorize | async"
                  routerLink="/sales/pos-devices/create"
                  class="btn btn-raised btn-success align-self-end round mx-1 mb-0"
                >
                  <i class="fa fa-plus"></i>
                  <span class="mx-1">{{ 'SHARED.CONFIRM.ADD' | translate }}</span>
                </a>
                <app-export-tools
                  *ngIf="(posDevices$ | async)?.length"
                  class="d-flex align-self-end mx-1"
                  targetElementId="pos-devices-table-export-target-element"
                  [outputFileName]="'SHARED.EXPORT_TABLES.POS_DEVICES_TABLE' | translate"
                ></app-export-tools>
              </div>
            </form>
          </div>
          <div class="col-md-12" *ngIf="(posDevices$ | async)?.length; else noItems">
            <div class="row">
              <div class="col-md-12 alert alert-light text-center" role="alert">
                {{ 'SHARED.SEARCH.SEARCH_RESULTS' | translate }} ({{ (paginationInfo$ | async).total | toNumber }})
                {{ 'SALES.POS_DEVICES.POS_DEVICE' | translate }}
              </div>
              <div class="col-md-12">
                <div class="table-responsive">
                  <table class="table text-center" id="pos-devices-table-export-target-element">
                    <thead>
                      <tr>
                        <th scope="col"><i class="fa fa-list-ol"></i></th>
                        <th scope="col">
                          {{ 'SALES.POS_DEVICES.POS_DEVICE_ID' | translate }}
                        </th>
                        <th scope="col">
                          {{ 'SALES.POS_DEVICES.POS_DEVICE_NAME' | translate }}
                        </th>
                        <th scope="col">
                          {{ 'SALES.POS_DEVICES.LOCATION' | translate }}
                        </th>
                        <th scope="col">{{ 'SHARED.TOOLS' | translate }}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let posDevice of posDevices$ | async; trackBy: trackElements; let i = index">
                        <th scope="row">{{ i + 1 }}</th>
                        <td>
                          {{ posDevice.id }}
                        </td>
                        <td>
                          {{ posDevice.name | localize: posDevice.nameEn | async }}
                        </td>
                        <td>
                          {{ posDevice.location.name | localize: posDevice.location.nameEn | async }}
                        </td>
                        <td>
                          <a
                            *ngIf="[claims.UPDATE_POS_DEVICE] | authorize | async"
                            class="info p-0"
                            [routerLink]="['/sales/pos-devices/update', posDevice.id]"
                            [ngbTooltip]="'SALES.POS_DEVICES.UPDATE_POS_DEVICE_DATA' | translate"
                          >
                            <i class="fa fa-edit font-medium-3 mr-2"></i>
                          </a>
                          <a
                            *ngIf="[claims.DELETE_POS_DEVICE] | authorize | async"
                            class="danger p-0"
                            [ngbTooltip]="'SHARED.CONFIRM.DELETE' | translate"
                            (click)="selectedPosDevice = posDevice; openModal(deleteModalRef)"
                          >
                            <i class="fa fa-close font-medium-3 mr-2"></i>
                          </a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div class="d-flex justify-content-between p-2">
                    <ngb-pagination
                      *ngIf="paginationInfo$ | async; let pagination"
                      maxSize="5"
                      [collectionSize]="pagination.total"
                      [pageSize]="pagination.pageSize"
                      [page]="pagination.page"
                      (pageChange)="pageChanged($event)"
                      boundaryLinks="true"
                    >
                      <ng-template ngbPaginationFirst>{{
                        'SHARED.PAGINATION.PAGINATION_FIRST' | translate
                      }}</ng-template>
                      <ng-template ngbPaginationFirst>{{
                        'SHARED.PAGINATION.PAGINATION_PREVIOUS' | translate
                      }}</ng-template>
                      <ng-template ngbPaginationFirst>{{
                        'SHARED.PAGINATION.PAGINATION_NEXT' | translate
                      }}</ng-template>
                      <ng-template ngbPaginationFirst>{{
                        'SHARED.PAGINATION.PAGINATION_LAST' | translate
                      }}</ng-template>
                    </ngb-pagination>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- pos devices list ends -->

<!-- no items template starts -->
<ng-template #noItems>
  <div class="col-md-12 alert alert-warning mt-3 text-center" role="alert">
    {{ 'SALES.POS_DEVICES.SEARCH_POS_DEVICE_NOT_FOUND' | translate }}
    <a
      *ngIf="[claims.CREATE_POS_DEVICE] | authorize | async"
      routerLink="/sales/pos-devices/create"
      class="btn btn-raised btn-success round"
    >
      <i class="fa fa-plus"></i>
      <span class="mx-1">{{ 'SHARED.CONFIRM.ADD' | translate }}</span>
    </a>
  </div>
</ng-template>
<!-- no items template ends -->

<!-- delete pos device modal starts -->
<ng-template #deleteModalRef let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title text-danger" id="modal-basic-title">
      {{ 'SALES.POS_DEVICES.CONFIRM_DELETE_POS_DEVICE_TITLE' | translate }}
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="d()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-12">
        <p class="text-danger">
          {{ 'SALES.POS_DEVICES.CONFIRM_DELETE_POS_DEVICE_MESSAGE' | translate }}
        </p>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="modal-actions">
      <button
        type="button"
        class="btn btn-raised btn-danger round"
        (click)="c(); delete(selectedPosDevice.id)"
        ngbAutofocus
        [disabled]="isDeleting$ | async"
      >
        <i class="fa fa-check"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.DELETE' | translate }}</span>
      </button>
      <button type="button" class="btn btn-outline-dark round" (click)="c()">
        <i class="fa fa-times"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.CANCEL' | translate }}</span>
      </button>
    </div>
  </div>
</ng-template>
<!-- delete pos device modal ends -->

<!-- pos device classes search starts -->
<app-locations-search onlyActive="true" (locations)="selectLocations($event)" [(visible)]="locationsListVisibility">
</app-locations-search>
<!-- pos device classes search ends -->
