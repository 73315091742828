import { CustomerEngagementTypeActionType, CustomerEngagementTypeActions } from 'lookups/store/actions';
import { CustomerEngagementTypeState } from 'lookups/store/states';

/**
 * The initial state from which the state starts.
 */
const initialState: CustomerEngagementTypeState = {
  data: [],
  error: null,
  isLoaded: false,
  isLoading: false,
};

/**
 * The reducer function that is called in each action dispatch against the state.
 * @param state The current state.
 * @param action The action that will affect the state.
 */
export function customerEngagementTypeReducer(
  state: CustomerEngagementTypeState = initialState,
  action: CustomerEngagementTypeActions
): CustomerEngagementTypeState {
  switch (action.type) {
    case CustomerEngagementTypeActionType.GET_ALL_CUSTOMER_ENGAGEMENT_TYPE: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case CustomerEngagementTypeActionType.GET_ALL_CUSTOMER_ENGAGEMENT_TYPE_FAIL: {
      return {
        ...state,
        error: action.payload,
        isLoading: false,
        isLoaded: false,
      };
    }

    case CustomerEngagementTypeActionType.GET_ALL_CUSTOMER_ENGAGEMENT_TYPE_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        isLoading: false,
        isLoaded: true,
      };
    }

    default:
      return state;
  }
}
