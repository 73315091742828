import { Injectable } from '@angular/core';

import { of } from 'rxjs';
import { switchMap, map, catchError, tap, debounceTime } from 'rxjs/operators';
import { Effect, ofType, Actions } from '@ngrx/effects';

import { NotificationService, StatusCode, PAGINATION, TranslationService } from 'shared';
import { PettyCashService } from 'finances/services';
import {
  SearchPettyCash,
  SearchPettyCashSuccess,
  SearchPettyCashFail,
  FindPettyCash,
  FindPettyCashSuccess,
  FindPettyCashFail,
  CreatePettyCashSuccess,
  CreatePettyCashFail,
  CreatePettyCash,
  PettyCashActionType,
  UpdatePettyCash,
  UpdatePettyCashSuccess,
  UpdatePettyCashFail,
  BlockPettyCash,
  BlockPettyCashSuccess,
  BlockPettyCashFail,
  ActivatePettyCash,
  ActivatePettyCashSuccess,
  ActivatePettyCashFail,
  DeletePettyCash,
  DeletePettyCashSuccess,
  DeletePettyCashFail,
} from 'finances/store/actions';

@Injectable()
export class PettyCashEffects {
  constructor(
    private actions$: Actions,
    private pettyCashService: PettyCashService,
    private notificationService: NotificationService,
    private translationService: TranslationService
  ) {}

  /* ========================= SEARCH_PETTY_CASH =================================== */
  @Effect()
  search$ = this.actions$.pipe(
    ofType(PettyCashActionType.SEARCH_PETTY_CASH),
    debounceTime(300),
    switchMap(({ payload }: SearchPettyCash) =>
      this.pettyCashService
        .search(payload?.description ?? '', payload.types, payload.specifications, payload.page, PAGINATION.PettyCash)
        .pipe(
          map((response) => new SearchPettyCashSuccess(response)),
          catchError((error) => of(new SearchPettyCashFail(error)))
        )
    )
  );

  /* ========================= FIND_PETTY_CASH =================================== */
  @Effect()
  find$ = this.actions$.pipe(
    ofType(PettyCashActionType.FIND_PETTY_CASH),
    switchMap((action: FindPettyCash) =>
      this.pettyCashService.findById(action.payload).pipe(
        map((response) => new FindPettyCashSuccess(response)),
        catchError((error) => of(new FindPettyCashFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  findFail$ = this.actions$.pipe(
    ofType(PettyCashActionType.FIND_PETTY_CASH_FAIL),
    tap((action: FindPettyCashFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(this.translationService.translate('FINANCES.PETTY_CASH.PETTY_CASH_NOT_FOUND'));
      }
    })
  );

  /* ========================= CREATE_PETTY_CASH =================================== */
  @Effect()
  create$ = this.actions$.pipe(
    ofType(PettyCashActionType.CREATE_PETTY_CASH),
    switchMap((action: CreatePettyCash) =>
      this.pettyCashService.create(action.payload).pipe(
        map((response) => new CreatePettyCashSuccess(response)),
        catchError((error) => of(new CreatePettyCashFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  createSuccess$ = this.actions$.pipe(
    ofType(PettyCashActionType.CREATE_PETTY_CASH_SUCCESS),
    tap((action: CreatePettyCashSuccess) => {
      this.notificationService.success(this.translationService.translate('FINANCES.PETTY_CASH.PETTY_CASH_ADDED'));
    })
  );

  @Effect({ dispatch: false })
  createFail$ = this.actions$.pipe(
    ofType(PettyCashActionType.CREATE_PETTY_CASH_FAIL),
    tap((action: CreatePettyCashFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );

  /* ========================= UPDATE_PETTY_CASH =================================== */
  @Effect()
  update$ = this.actions$.pipe(
    ofType(PettyCashActionType.UPDATE_PETTY_CASH),
    switchMap((action: UpdatePettyCash) =>
      this.pettyCashService.update(action.payload).pipe(
        map((response) => new UpdatePettyCashSuccess(response)),
        catchError((error) => of(new UpdatePettyCashFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  updateSuccess$ = this.actions$.pipe(
    ofType(PettyCashActionType.UPDATE_PETTY_CASH_SUCCESS),
    tap((action: UpdatePettyCashSuccess) => {
      this.notificationService.success(this.translationService.translate('FINANCES.PETTY_CASH.PETTY_CASH_UPDATED'));
    })
  );

  @Effect({ dispatch: false })
  updateFail$ = this.actions$.pipe(
    ofType(PettyCashActionType.UPDATE_PETTY_CASH_FAIL),
    tap((action: UpdatePettyCashFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(this.translationService.translate('FINANCES.PETTY_CASH.PETTY_CASH_NOT_FOUND'));
      } else if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );

  /* ========================= BLOCK_PETTY_CASH =================================== */
  @Effect()
  block$ = this.actions$.pipe(
    ofType(PettyCashActionType.BLOCK_PETTY_CASH),
    switchMap((action: BlockPettyCash) =>
      this.pettyCashService.block(action.payload).pipe(
        map((response) => new BlockPettyCashSuccess(response)),
        catchError((error) => of(new BlockPettyCashFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  blockSuccess$ = this.actions$.pipe(
    ofType(PettyCashActionType.BLOCK_PETTY_CASH_SUCCESS),
    tap((action: BlockPettyCashSuccess) => {
      this.notificationService.success(this.translationService.translate('FINANCES.PETTY_CASH.PETTY_CASH_BLOCKED'));
    })
  );

  @Effect({ dispatch: false })
  blockFail$ = this.actions$.pipe(
    ofType(PettyCashActionType.BLOCK_PETTY_CASH_FAIL),
    tap((action: BlockPettyCashFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(this.translationService.translate('FINANCES.PETTY_CASH.PETTY_CASH_NOT_FOUND'));
      } else if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );

  /* ========================= ACTIVATE_PETTY_CASH =================================== */
  @Effect()
  activate$ = this.actions$.pipe(
    ofType(PettyCashActionType.ACTIVATE_PETTY_CASH),
    switchMap((action: ActivatePettyCash) =>
      this.pettyCashService.activate(action.payload).pipe(
        map((response) => new ActivatePettyCashSuccess(response)),
        catchError((error) => of(new ActivatePettyCashFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  activateSuccess$ = this.actions$.pipe(
    ofType(PettyCashActionType.ACTIVATE_PETTY_CASH_SUCCESS),
    tap((action: ActivatePettyCashSuccess) => {
      this.notificationService.success(this.translationService.translate('FINANCES.PETTY_CASH.PETTY_CASH_ACTIVATED'));
    })
  );

  @Effect({ dispatch: false })
  activateFail$ = this.actions$.pipe(
    ofType(PettyCashActionType.ACTIVATE_PETTY_CASH_FAIL),
    tap((action: ActivatePettyCashFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(this.translationService.translate('FINANCES.PETTY_CASH.PETTY_CASH_NOT_FOUND'));
      } else if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );

  /* ========================= DELETE_PETTY_CASH =================================== */
  @Effect()
  delete$ = this.actions$.pipe(
    ofType(PettyCashActionType.DELETE_PETTY_CASH),
    switchMap((action: DeletePettyCash) =>
      this.pettyCashService.delete(action.payload).pipe(
        map((response) => new DeletePettyCashSuccess(response)),
        catchError((error) => of(new DeletePettyCashFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  deleteSuccess$ = this.actions$.pipe(
    ofType(PettyCashActionType.DELETE_PETTY_CASH_SUCCESS),
    tap((action: DeletePettyCashSuccess) => {
      this.notificationService.success(this.translationService.translate('FINANCES.PETTY_CASH.PETTY_CASH_DELETED'));
    })
  );

  @Effect({ dispatch: false })
  deleteFail$ = this.actions$.pipe(
    ofType(PettyCashActionType.DELETE_PETTY_CASH_FAIL),
    tap((action: DeletePettyCashFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(this.translationService.translate('FINANCES.PETTY_CASH.PETTY_CASH_NOT_FOUND'));
      } else if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );
}
