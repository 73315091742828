import { Injectable } from '@angular/core';

import { of } from 'rxjs';
import { switchMap, map, catchError, tap, debounceTime } from 'rxjs/operators';
import { Effect, ofType, Actions } from '@ngrx/effects';

import { NotificationService, StatusCode, PAGINATION, TranslationService } from 'shared';
import { ProductClassesService } from 'stores/services';
import {
  ProductClassesActionType,
  SearchProductClasses,
  SearchProductClassesFail,
  SearchProductClassesSuccess,
  SearchProductClassesForSalesScreen,
  SearchProductClassesForSalesScreenFail,
  SearchProductClassesForSalesScreenSuccess,
  SearchProductClassesWithoutPagination,
  CreateProductClass,
  CreateProductClassSuccess,
  CreateProductClassFail,
  UpdateProductClass,
  UpdateProductClassSuccess,
  UpdateProductClassFail,
  DeleteProductClass,
  DeleteProductClassSuccess,
  DeleteProductClassFail,
  FindProductClass,
  FindProductClassSuccess,
  FindProductClassFail,
  DeleteProductClassPhoto,
  UpdateProductClassPhoto,
} from 'stores/store/actions';

@Injectable()
export class ProductClassesEffects {
  constructor(
    private actions$: Actions,
    private productClassesService: ProductClassesService,
    private notificationService: NotificationService,
    private translationService: TranslationService
  ) {}

  /* ========================= SEARCH_PRODUCT_CLASSES =================================== */
  @Effect()
  search$ = this.actions$.pipe(
    ofType(ProductClassesActionType.SEARCH_PRODUCT_CLASSES),
    debounceTime(300),
    switchMap(({ payload }: SearchProductClasses) =>
      this.productClassesService
        .search(payload?.name ?? '', payload.locations, payload.page, PAGINATION.ProductClasses)
        .pipe(
          map((response) => new SearchProductClassesSuccess(response)),
          catchError((error) => of(new SearchProductClassesFail(error)))
        )
    )
  );

  /* ========================= SEARCH_PRODUCT_CLASSES_FOR_SALES_SCREEN =================================== */
  @Effect()
  searchProductClassesForSalesScreen$ = this.actions$.pipe(
    ofType(ProductClassesActionType.SEARCH_PRODUCT_CLASSES_FOR_SALES_SCREEN),
    debounceTime(300),
    switchMap(({ payload }: SearchProductClassesForSalesScreen) =>
      this.productClassesService.searchProductClassesForSalesScreen(payload).pipe(
        map((response) => new SearchProductClassesForSalesScreenSuccess(response)),
        catchError((error) => of(new SearchProductClassesForSalesScreenFail(error)))
      )
    )
  );

  /* ========================= SEARCH_PRODUCT_CLASSES_WITHOUT_PAGINATION =================================== */
  @Effect()
  searchWithoutPagination$ = this.actions$.pipe(
    ofType(ProductClassesActionType.SEARCH_PRODUCT_CLASSES_WITHOUT_PAGINATION),
    debounceTime(300),
    switchMap(({ payload }: SearchProductClassesWithoutPagination) =>
      this.productClassesService.searchWithoutPagination(payload?.name ?? '', payload.locations).pipe(
        map((response) => new SearchProductClassesSuccess(response)),
        catchError((error) => of(new SearchProductClassesFail(error)))
      )
    )
  );

  /* ========================= FIND_PRODUCT_CLASS =================================== */
  @Effect()
  find$ = this.actions$.pipe(
    ofType(ProductClassesActionType.FIND_PRODUCT_CLASS),
    switchMap((action: FindProductClass) =>
      this.productClassesService.findById(action.payload).pipe(
        map((response) => new FindProductClassSuccess(response)),
        catchError((error) => of(new FindProductClassFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  findFail$ = this.actions$.pipe(
    ofType(ProductClassesActionType.FIND_PRODUCT_CLASS_FAIL),
    tap((action: FindProductClassFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate('STORES.PRODUCT_CLASSES.PRODUCT_CLASS_NOT_FOUND')
        );
      }
    })
  );

  /* ========================= CREATE_PRODUCT_CLASS =================================== */
  @Effect()
  create$ = this.actions$.pipe(
    ofType(ProductClassesActionType.CREATE_PRODUCT_CLASS),
    switchMap((action: CreateProductClass) =>
      this.productClassesService.create(action.payload).pipe(
        map((response) => new CreateProductClassSuccess(response)),
        catchError((error) => of(new CreateProductClassFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  createSuccess$ = this.actions$.pipe(
    ofType(ProductClassesActionType.CREATE_PRODUCT_CLASS_SUCCESS),
    tap((action: CreateProductClassSuccess) => {
      this.notificationService.success(this.translationService.translate('STORES.PRODUCT_CLASSES.PRODUCT_CLASS_ADDED'));
    })
  );

  @Effect({ dispatch: false })
  createFail$ = this.actions$.pipe(
    ofType(ProductClassesActionType.CREATE_PRODUCT_CLASS_FAIL),
    tap((action: CreateProductClassFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );

  /* ========================= UPDATE_PRODUCT_CLASS =================================== */
  @Effect()
  update$ = this.actions$.pipe(
    ofType(ProductClassesActionType.UPDATE_PRODUCT_CLASS),
    switchMap((action: UpdateProductClass) =>
      this.productClassesService.update(action.payload).pipe(
        map((response) => new UpdateProductClassSuccess(response)),
        catchError((error) => of(new UpdateProductClassFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  updateSuccess$ = this.actions$.pipe(
    ofType(ProductClassesActionType.UPDATE_PRODUCT_CLASS_SUCCESS),
    tap((action: UpdateProductClassSuccess) => {
      this.notificationService.success(
        this.translationService.translate('STORES.PRODUCT_CLASSES.PRODUCT_CLASS_UPDATED')
      );
    })
  );

  @Effect({ dispatch: false })
  updateFail$ = this.actions$.pipe(
    ofType(ProductClassesActionType.UPDATE_PRODUCT_CLASS_FAIL),
    tap((action: UpdateProductClassFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate('STORES.PRODUCT_CLASSES.PRODUCT_CLASS_NOT_FOUND')
        );
      } else if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );

  @Effect()
  updatePhoto$ = this.actions$.pipe(
    ofType(ProductClassesActionType.UPDATE_PRODUCT_CLASS_PHOTO),
    switchMap((action: UpdateProductClassPhoto) =>
      this.productClassesService.updatePhoto(action.payload.id, action.payload.photo).pipe(
        map((response) => new UpdateProductClassSuccess(response)),
        catchError((error) => of(new UpdateProductClassFail(error)))
      )
    )
  );

  @Effect()
  deletePhoto$ = this.actions$.pipe(
    ofType(ProductClassesActionType.DELETE_PRODUCT_CLASS_PHOTO),
    switchMap((action: DeleteProductClassPhoto) =>
      this.productClassesService.deletePhoto(action.payload).pipe(
        map((response) => new UpdateProductClassSuccess(response)),
        catchError((error) => of(new UpdateProductClassFail(error)))
      )
    )
  );

  /* ========================= DELETE_PRODUCT_CLASS =================================== */
  @Effect()
  delete$ = this.actions$.pipe(
    ofType(ProductClassesActionType.DELETE_PRODUCT_CLASS),
    switchMap((action: DeleteProductClass) =>
      this.productClassesService.delete(action.payload).pipe(
        map((response) => new DeleteProductClassSuccess(response)),
        catchError((error) => of(new DeleteProductClassFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  deleteSuccess$ = this.actions$.pipe(
    ofType(ProductClassesActionType.DELETE_PRODUCT_CLASS_SUCCESS),
    tap((action: DeleteProductClassSuccess) => {
      this.notificationService.success(
        this.translationService.translate('STORES.PRODUCT_CLASSES.PRODUCT_CLASS_DELETED')
      );
    })
  );

  @Effect({ dispatch: false })
  deleteFail$ = this.actions$.pipe(
    ofType(ProductClassesActionType.DELETE_PRODUCT_CLASS_FAIL),
    tap((action: DeleteProductClassFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate('STORES.PRODUCT_CLASSES.PRODUCT_CLASS_NOT_FOUND')
        );
      } else if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );
}
