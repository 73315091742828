<!-- page header starts -->
<app-page-header [info]="pageInfo"></app-page-header>
<!-- page header ends -->

<div class="card" *ngIf="request$ | async; let request">
  <!-- petty cash refund request details starts -->
  <div class="card-content">
    <div class="px-3 pt-3">
      <div class="row">
        <div class="col-md-12">
          <ul
            class="nav-tabs"
            ngbNav
            #nav="ngbNav"
            [(activeId)]="activePage"
            (activeIdChange)="selectedPageChanged($event)"
            [destroyOnHide]="false"
          >
            <li ngbNavItem="details">
              <a ngbNavLink>
                <i class="fa fa-file-text" [class.success]="activePage == 'details'"></i>
                {{ 'FINANCES.PETTY_CASH_REFUND_REQUESTS.PETTY_CASH_REFUND_REQUEST_DATA_TITLE' | translate }}
              </a>

              <!-- view petty cash refund requests starts -->
              <ng-template ngbNavContent>
                <div class="row">
                  <div class="col-md-12">
                    <div class="card">
                      <div class="card-content">
                        <div class="px-3">
                          <div class="row">
                            <div class="form-group col-md-4">
                              <label for="pettyCashId">{{
                                'FINANCES.PETTY_CASH_REFUND_REQUESTS.PETTY_CASH_REFUND_REQUEST_DATA.PETTY_CASH'
                                  | translate
                              }}</label>
                              <input
                                type="text"
                                id="pettyCashId"
                                class="form-control round"
                                [value]="request.pettyCash.description"
                                readonly
                              />
                            </div>
                            <div class="form-group col-md-4">
                              <label for="transactionDate">{{
                                'FINANCES.PETTY_CASH_REFUND_REQUESTS.PETTY_CASH_REFUND_REQUEST_DATA.PETTY_CASH_REFUND_REQUEST_DATE'
                                  | translate
                              }}</label>
                              <input
                                type="text"
                                id="transactionDate"
                                class="form-control round"
                                [value]="request.transactionDate | date: 'date'"
                                readonly
                              />
                            </div>
                            <div class="form-group col-md-4">
                              <label for="createdAt">{{ 'SHARED.SEARCH.CREATED_AT' | translate }}</label>
                              <input
                                type="text"
                                id="createdAt"
                                class="form-control round"
                                [value]="request.createdAt | date: 'date'"
                                readonly
                              />
                            </div>
                            <div class="form-group col-md-4">
                              <label for="user">{{
                                'FINANCES.PETTY_CASH_REFUND_REQUESTS.PETTY_CASH_REFUND_REQUEST_DATA.USER' | translate
                              }}</label>
                              <input
                                type="text"
                                id="user"
                                class="form-control round"
                                [value]="request.user.name"
                                readonly
                              />
                            </div>
                            <div class="form-group col-md-4">
                              <label for="updatedAt">{{
                                'FINANCES.PETTY_CASH_REFUND_REQUESTS.PETTY_CASH_REFUND_REQUEST_DATA.UPDATED_AT'
                                  | translate
                              }}</label>
                              <input
                                type="text"
                                id="updatedAt"
                                class="form-control round"
                                [value]="request.updatedAt | date: 'datetime'"
                                readonly
                              />
                            </div>
                            <div class="form-group col-md-4">
                              <label for="userUpdated">{{
                                'FINANCES.PETTY_CASH_REFUND_REQUESTS.PETTY_CASH_REFUND_REQUEST_DATA.USER_UPDATED'
                                  | translate
                              }}</label>
                              <input
                                type="text"
                                id="userUpdated"
                                class="form-control round"
                                [value]="request.userUpdated?.name"
                                readonly
                              />
                            </div>
                            <div class="form-group col-md-12">
                              <label for="notes">{{
                                'FINANCES.PETTY_CASH_REFUND_REQUESTS.PETTY_CASH_REFUND_REQUEST_DATA.NOTES' | translate
                              }}</label>
                              <textarea
                                id="notes"
                                class="form-control round"
                                [innerText]="request.notes"
                                [placeholder]="
                                  'FINANCES.PETTY_CASH_REFUND_REQUESTS.PETTY_CASH_REFUND_REQUEST_DATA.NOTES_NOT_FOUND'
                                    | translate
                                "
                                readonly
                              ></textarea>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- petty cash refund request lines details starts -->
                <div class="card-header mb-3 pt-2">
                  <h4 class="card-title">
                    {{
                      'FINANCES.PETTY_CASH_REFUND_REQUESTS.PETTY_CASH_REFUND_REQUEST_DATA.PETTY_CASH_REFUND_REQUESTS'
                        | translate
                    }}
                  </h4>
                </div>
                <div class="card-content">
                  <div class="px-3">
                    <div class="row">
                      <div class="col-md-12">
                        <div class="table-responsive">
                          <table class="table text-center">
                            <thead>
                              <tr>
                                <th scope="col"><i class="fa fa-list-ol"></i></th>
                                <th scope="col">
                                  {{
                                    'FINANCES.PETTY_CASH_REFUND_REQUESTS.PETTY_CASH_REFUND_REQUEST_DATA.DESCRIPTION'
                                      | translate
                                  }}
                                </th>
                                <th scope="col">
                                  {{
                                    'FINANCES.PETTY_CASH_REFUND_REQUESTS.PETTY_CASH_REFUND_REQUEST_DATA.PETTY_CASH_REFUND_REQUEST_TYPE'
                                      | translate
                                  }}
                                </th>
                                <th scope="col">
                                  <i class="fa fa-user mr-1"></i>
                                  {{
                                    'FINANCES.PETTY_CASH_REFUND_REQUESTS.PETTY_CASH_REFUND_REQUEST_DATA.VENDOR'
                                      | translate
                                  }}
                                </th>
                                <th scope="col">
                                  <i class="fa fa-usd mr-1"></i>
                                  {{
                                    'FINANCES.PETTY_CASH_REFUND_REQUESTS.PETTY_CASH_REFUND_REQUEST_DATA.INVOICE_VALUE'
                                      | translate
                                  }}
                                </th>
                                <th scope="col">
                                  <i class="fa fa-percent mr-1"></i>
                                  {{
                                    'FINANCES.PETTY_CASH_REFUND_REQUESTS.PETTY_CASH_REFUND_REQUEST_DATA.TAX' | translate
                                  }}
                                </th>
                                <th scope="col">
                                  <i class="fa fa-usd mr-1"></i>
                                  {{
                                    'FINANCES.PETTY_CASH_REFUND_REQUESTS.PETTY_CASH_REFUND_REQUEST_DATA.TOTAL'
                                      | translate
                                  }}
                                </th>
                                <th scope="col">
                                  <i class="fa fa-calendar mr-1"></i>
                                  {{
                                    'FINANCES.PETTY_CASH_REFUND_REQUESTS.PETTY_CASH_REFUND_REQUEST_DATA.INVOICE_DATE'
                                      | translate
                                  }}
                                </th>
                                <th scope="col">
                                  {{
                                    'FINANCES.PETTY_CASH_REFUND_REQUESTS.PETTY_CASH_REFUND_REQUEST_DATA.ATTACHMENTS'
                                      | translate
                                  }}
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let line of request.pettyCashRefundRequestLines; let i = index">
                                <th scope="row">{{ i + 1 }}</th>
                                <td>{{ line.description }}</td>
                                <td>
                                  {{
                                    line.pettyCashRefundRequestType.name
                                      | localize: line.pettyCashRefundRequestType.nameEn
                                      | async
                                  }}
                                </td>
                                <td>{{ line.vendorName }}</td>
                                <td>{{ line.value | toDecimal }}</td>
                                <td>{{ line.tax | toDecimal }}</td>
                                <td>{{ (line.value + (line.tax / 100) * line.value | toDecimal) || '0.0' }}</td>
                                <td>{{ line.invoiceDate }}</td>
                                <td>
                                  <a
                                    class="info p-0"
                                    (click)="selectedLine = line; openModal(viewAttachmentsModalRef)"
                                    [ngbTooltip]="
                                      'FINANCES.PETTY_CASH_REFUND_REQUESTS.PETTY_CASH_REFUND_REQUEST_DATA.DISPLAY_ATTACHMENTS'
                                        | translate
                                    "
                                  >
                                    <i class="fa fa-paperclip font-medium-3 mr-2"></i
                                    ><span *ngIf="line.pettyCashRefundRequestLineAttachments.length">{{
                                      line.pettyCashRefundRequestLineAttachments.length
                                    }}</span>
                                  </a>
                                </td>
                              </tr>
                            </tbody>
                            <tfoot class="tfoot-light">
                              <tr>
                                <td [attr.colspan]="9">
                                  <i class="fa fa-bookmark c-primary"></i>
                                  <span>
                                    {{ request.pettyCashRefundRequestLines?.length }}
                                    {{
                                      'FINANCES.PETTY_CASH_REFUND_REQUESTS.PETTY_CASH_REFUND_REQUEST_DATA.PETTY_CASH_REFUND_REQUEST'
                                        | translate
                                    }}</span
                                  >
                                </td>
                              </tr>
                            </tfoot>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- petty cash refund request lines details ends -->

                  <!-- request summary starts -->
                  <div class="card-header mb-3 pt-3">
                    <h4 class="card-title">
                      {{
                        'FINANCES.PETTY_CASH_REFUND_REQUESTS.PETTY_CASH_REFUND_REQUEST_DATA.PETTY_CASH_REFUND_ACCOUNT'
                          | translate
                      }}
                    </h4>
                  </div>
                  <div class="card-content">
                    <div class="px-3">
                      <div class="row">
                        <div class="col-md-12">
                          <div class="row">
                            <div class="form-group col-md-4">
                              <label for="requestLinesTotal">{{
                                'FINANCES.PETTY_CASH_REFUND_REQUESTS.PETTY_CASH_REFUND_REQUEST_DATA.REFUNDS_TOTAL'
                                  | translate
                              }}</label>
                              <input
                                type="text"
                                id="requestLinesTotal"
                                name="requestLinesTotal"
                                class="form-control round"
                                [value]="(requestLinesTotal(request.pettyCashRefundRequestLines) | toDecimal) || '0.0'"
                                readonly
                              />
                            </div>
                            <div class="form-group col-md-4">
                              <label for="tax">{{
                                'FINANCES.PETTY_CASH_REFUND_REQUESTS.PETTY_CASH_REFUND_REQUEST_DATA.TAX_TOTAL'
                                  | translate
                              }}</label>
                              <input
                                type="text"
                                id="tax"
                                name="tax"
                                class="form-control round"
                                [value]="(requestLinesTax(request.pettyCashRefundRequestLines) | toDecimal) || '0.0'"
                                readonly
                              />
                            </div>
                            <div class="form-group col-md-4">
                              <label for="requestTotal" class="font-weight-bold" style="color: #212529;">
                                <i class="fa fa-star mr-1"></i>
                                <span class="mx-1">{{
                                  'FINANCES.PETTY_CASH_REFUND_REQUESTS.PETTY_CASH_REFUND_REQUEST_DATA.PETTY_CASH_REFUND_TOTAL'
                                    | translate
                                }}</span>
                              </label>
                              <input
                                type="text"
                                id="requestTotal"
                                name="requestTotal"
                                class="form-control round"
                                [value]="(requestTotal() | toDecimal) || '0.0'"
                                readonly
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-template>
              <!-- view petty cash refund requests ends -->
            </li>
          </ul>
          <div [ngbNavOutlet]="nav" class="mt-2"></div>
        </div>
      </div>

      <!-- request summary ends -->
      <div class="row mt-2">
        <div class="form-group col-12 d-flex justify-content-between flex-column flex-sm-row mt-2">
          <a
            *ngIf="
              [Claims.CREATE_PETTY_CASH_REFUND_REQUEST, Claims.APPROVE_PETTY_CASH_REFUND_REQUEST] | authorize | async
            "
            class="btn btn-raised btn-success round"
            [routerLink]="['/finances/petty-cash-refund-requests/update', request.id]"
          >
            <i class="fa fa-edit"></i>
            <span class="mx-1">{{
              'FINANCES.PETTY_CASH_REFUND_REQUESTS.UPDATE_PETTY_CASH_REFUND_REQUEST_DATA' | translate
            }}</span>
          </a>

          <a class="btn btn-dark round" routerLink="/finances/petty-cash-refund-requests">
            <i class="fa fa-undo"></i>
            <span class="mx-1">{{
              'FINANCES.PETTY_CASH_REFUND_REQUESTS.PETTY_CASH_REFUND_REQUEST_DATA.PETTY_CASH_REFUND_REQUESTS_RETURN'
                | translate
            }}</span>
          </a>
        </div>
      </div>
    </div>
  </div>
  <!-- petty cash refund request details ends -->
</div>

<!-- view attachments modal starts -->
<ng-template #viewAttachmentsModalRef let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title text-info" id="modal-basic-title">
      {{ 'FINANCES.PETTY_CASH_REFUND_REQUESTS.PETTY_CASH_REFUND_REQUEST_DATA.ATTACHMENTS' | translate }}
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="d()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="card-content">
        <div class="px-3">
          <div class="row">
            <div class="form-group col-md-12">
              <!-- request attachments starts -->
              <div class="card-content">
                <div class="px-3">
                  <app-files-viewer
                    *ngIf="selectedLine.pettyCashRefundRequestLineAttachments?.length; else noAttachments"
                    [files]="getAttachmentsUrls(selectedLine.pettyCashRefundRequestLineAttachments)"
                  ></app-files-viewer>
                </div>
              </div>
              <!-- request attachments ends -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="modal-actions">
      <button type="button" class="btn btn-raised btn-primary round" (click)="c()" ngbAutoFocus>
        <i class="fa fa-times"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.CLOSE' | translate }}</span>
      </button>
    </div>
  </div>
</ng-template>
<!-- view attachments modal ends -->

<!-- no attachments template starts -->
<ng-template #noAttachments>
  <div class="col-md-12 alert alert-warning mt-3 text-center" role="alert">
    {{ 'FINANCES.PETTY_CASH_REFUND_REQUESTS.PETTY_CASH_REFUND_REQUEST_DATA.ATTACHMENTS_NOT_FOUND' | translate }}
  </div>
</ng-template>
<!-- no attachments template ends -->
