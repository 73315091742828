<!-- page header starts -->
<app-page-header [info]="pageInfo"></app-page-header>
<!-- page header ends -->

<!-- projects list starts -->
<div class="row">
  <div class="col-md-12">
    <div class="card">
      <div class="card-header mb-3">
        <h4 class="card-title">{{ 'SHARED.SEARCH.SEARCH_TOOLS' | translate }}</h4>
      </div>
      <div class="card-content">
        <div class="px-3">
          <div class="row">
            <div class="col-md-12">
              <form class="row" [formGroup]="searchForm" (ngSubmit)="search()">
                <div class="form-group col-md-4">
                  <label for="description">{{
                    'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.PROJECT_DESCRIPTION_SEARCH' | translate
                  }}</label>
                  <input
                    id="description"
                    type="text"
                    class="form-control round"
                    [placeholder]="
                      'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.PROJECT_DESCRIPTION_SEARCH_PLACEHOLDER' | translate
                    "
                    formControlName="description"
                    (input)="search($event)"
                  />
                </div>
                <div class="form-group col-md-4">
                  <label for="locations">{{ 'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.LOCATIONS' | translate }}</label>
                  <ng-select
                    class="round"
                    formControlName="locations"
                    [multiple]="true"
                    [closeOnSelect]="false"
                    labelForId="locations"
                    searchable="true"
                    [placeholder]="'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.LOCATIONS_PLACEHOLDER' | translate"
                    [notFoundText]="'SHARED.SEARCH.SEARCH_NOT_FOUND' | translate"
                    (open)="locationsListVisibility = true"
                  >
                    <ng-option *ngFor="let location of locations" [value]="location.id">
                      {{ location.name | localize: location.nameEn | async }}
                    </ng-option>
                  </ng-select>
                </div>
                <div class="form-group col-md-4">
                  <label for="projectStatuses">{{
                    'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.PROJECT_STATUSES' | translate
                  }}</label>
                  <ng-select
                    class="round"
                    formControlName="projectStatuses"
                    [multiple]="true"
                    [closeOnSelect]="false"
                    labelForId="projectStatuses"
                    searchable="true"
                    [placeholder]="'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.PROJECT_STATUSES_PLACEHOLDER' | translate"
                    [notFoundText]="'SHARED.SEARCH.SEARCH_NOT_FOUND' | translate"
                  >
                    <ng-option *ngFor="let projectStatus of projectStatuses$ | async" [value]="projectStatus.id">
                      {{ projectStatus.name | localize: projectStatus.nameEn | async }}
                    </ng-option>
                  </ng-select>
                </div>
                <div class="form-group col">
                  <label for="customers">{{ 'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.CUSTOMERS' | translate }}</label>
                  <ng-select
                    class="round"
                    formControlName="customers"
                    [multiple]="true"
                    [closeOnSelect]="false"
                    labelForId="customers"
                    searchable="true"
                    [placeholder]="'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.CUSTOMERS_PLACEHOLDER' | translate"
                    [notFoundText]="'SHARED.SEARCH.SEARCH_NOT_FOUND' | translate"
                    (open)="customersListVisibility = true"
                  >
                    <ng-option *ngFor="let customer of customers" [value]="customer.id">
                      {{ customer.name | localize: customer.nameEn | async }}
                    </ng-option>
                  </ng-select>
                </div>
                <div class="col-md-auto form-group d-flex">
                  <button
                    type="submit"
                    class="btn btn-raised btn-primary align-self-end round mx-1 mb-0"
                    [disabled]="isSearching$ | async"
                  >
                    <i class="fa fa-search"></i>
                  </button>
                  <a
                    *ngIf="[Claims.CREATE_PROJECT] | authorize | async"
                    routerLink="/projects-management/projects/create"
                    class="btn btn-raised btn-success align-self-end round mx-1 mb-0"
                  >
                    <i class="fa fa-plus"></i>
                    <span class="mx-1">{{ 'SHARED.CONFIRM.ADD' | translate }}</span>
                  </a>
                  <app-export-tools
                    *ngIf="(projects$ | async)?.length"
                    class="d-flex align-self-end mx-1"
                    targetElementId="projects-table-export-target-element"
                    [outputFileName]="'SHARED.EXPORT_TABLES.PROJECTS_TABLE' | translate"
                  ></app-export-tools>
                </div>
              </form>
            </div>
            <div class="col-md-12" *ngIf="(projects$ | async)?.length; else noItems">
              <div class="row">
                <div class="col-md-12 alert alert-light text-center" role="alert">
                  {{ 'SHARED.SEARCH.SEARCH_RESULTS' | translate }} ({{ (paginationInfo$ | async).total | number }})
                  {{ 'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.PROJECT' | translate }}
                </div>
                <div class="col-md-12">
                  <div class="table-responsive">
                    <table class="table text-center" id="projects-table-export-target-element">
                      <thead>
                        <tr>
                          <th scope="col"><i class="fa fa-list-ol"></i></th>
                          <th scope="col">
                            <i class="fa fa-barcode"></i
                            ><span class="mx-1">
                              {{ 'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.CODE' | translate }}
                            </span>
                          </th>
                          <th scope="col">
                            {{ 'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.PROJECT_DESCRIPTION' | translate }}
                          </th>
                          <th scope="col">
                            {{ 'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.LOCATION' | translate }}
                          </th>
                          <th scope="col">
                            {{ 'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.CUSTOMER' | translate }}
                          </th>
                          <th scope="col">
                            {{ 'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.STATUS' | translate }}
                          </th>
                          <th scope="col">{{ 'SHARED.TOOLS' | translate }}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let project of projects$ | async; trackBy: trackElements; let i = index">
                          <th scope="row">{{ i + 1 }}</th>
                          <td>{{ project.code }}</td>
                          <td>{{ project.description | localize: project.descriptionEn | async }}</td>
                          <td>{{ project.location.name | localize: project.location.nameEn | async }}</td>
                          <td>{{ project.customer.name | localize: project.customer.nameEn | async }}</td>
                          <td>{{ project.projectStatus.name | localize: project.projectStatus.nameEn | async }}</td>
                          <td>
                            <a
                              *ngIf="
                                [Claims.CREATE_PROJECT, Claims.UPDATE_PROJECT, Claims.DELETE_PROJECT]
                                  | authorize
                                  | async
                              "
                              class="info p-0"
                              [routerLink]="['/projects-management/projects/files', project.id]"
                              [ngbTooltip]="'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.PROJECT_FILE' | translate"
                            >
                              <i class="fa fa-folder font-medium-3 mr-2"></i>
                            </a>
                            <a
                              *ngIf="[Claims.DELETE_PROJECT] | authorize | async"
                              class="danger p-0"
                              [ngbTooltip]="'SHARED.CONFIRM.DELETE' | translate"
                              (click)="selectedProject = project; openModal(deleteModalRef)"
                            >
                              <i class="fa fa-close font-medium-3 mr-2"></i>
                            </a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div class="d-flex justify-content-between p-2">
                      <ngb-pagination
                        *ngIf="paginationInfo$ | async; let pagination"
                        maxSize="5"
                        [collectionSize]="pagination.total"
                        [pageSize]="pagination.pageSize"
                        [page]="pagination.page"
                        (pageChange)="pageChanged($event)"
                        boundaryLinks="true"
                      >
                        <ng-template ngbPaginationFirst>{{
                          'SHARED.PAGINATION.PAGINATION_FIRST' | translate
                        }}</ng-template>
                        <ng-template ngbPaginationFirst>{{
                          'SHARED.PAGINATION.PAGINATION_PREVIOUS' | translate
                        }}</ng-template>
                        <ng-template ngbPaginationFirst>{{
                          'SHARED.PAGINATION.PAGINATION_NEXT' | translate
                        }}</ng-template>
                        <ng-template ngbPaginationFirst>{{
                          'SHARED.PAGINATION.PAGINATION_LAST' | translate
                        }}</ng-template>
                      </ngb-pagination>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- projects list ends -->

<!-- no items template starts -->
<ng-template #noItems>
  <div class="col-md-12 alert alert-warning mt-3 text-center" role="alert">
    {{ 'PROJECTS_MANAGEMENT.PROJECTS.SEARCH_PROJECTS_NOT_FOUND' | translate }}
    <a
      *ngIf="[Claims.CREATE_PROJECT] | authorize | async"
      routerLink="/projects-management/projects/create"
      class="btn btn-raised btn-success round"
    >
      <i class="fa fa-plus"></i>
      <span class="mx-1">{{ 'SHARED.CONFIRM.ADD' | translate }}</span>
    </a>
  </div>
</ng-template>
<!-- no items template ends -->

<!-- confirm delete all products modal starts -->
<ng-template #deleteModalRef let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title text-danger" id="modal-basic-title">
      {{ 'PROJECTS_MANAGEMENT.PROJECTS.CONFIRM_DELETE_PROJECT_TITLE' | translate }}
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="d()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p class="text-danger">{{ 'PROJECTS_MANAGEMENT.PROJECTS.CONFIRM_DELETE_PROJECT_MESSAGE' | translate }}</p>
  </div>
  <div class="modal-footer">
    <div class="modal-actions">
      <button
        type="button"
        class="btn btn-raised btn-danger round"
        (click)="c(); delete(selectedProject.id)"
        ngbAutofocus
        [disabled]="isDeleting$ | async"
      >
        <i class="fa fa-check"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.DELETE' | translate }}</span>
      </button>
      <button type="button" class="btn btn-outline-dark round" (click)="c()">
        <i class="fa fa-times"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.CANCEL' | translate }}</span>
      </button>
    </div>
  </div>
</ng-template>
<!-- confirm modal ends -->

<!-- locations search starts -->
<locations-search [(visible)]="locationsListVisibility" (locations)="selectLocations($event)"> </locations-search>
<!-- locations search ends -->

<!-- customers search starts -->
<app-customers-search [(visible)]="customersListVisibility" (customers)="selectCustomers($event)">
</app-customers-search>
<!-- customers search ends -->
