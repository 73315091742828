import { Action } from '@ngrx/store';

import { LoanPaymentType } from 'lookups/models';
import { AppHttpResponse } from 'shared';

/**
 * The Lookups-Module store loan payment type action types.
 */
export enum LoanPaymentTypeActionType {
  GET_ALL_LOAN_PAYMENT_TYPES = '[Lookups] [LoanPaymentType] Get All Loan Payment Types',
  GET_ALL_LOAN_PAYMENT_TYPES_FAIL = '[Lookups] [LoanPaymentType] Get All Loan Payment Types Fail',
  GET_ALL_LOAN_PAYMENT_TYPES_SUCCESS = '[Lookups] [LoanPaymentType] Get All Loan Payment Types Success',
}

/**
 * Represents a store loan payment type action.
 */
export class GetAllLoanPaymentType implements Action {
  /**
   * The type of the action.
   */
  readonly type = LoanPaymentTypeActionType.GET_ALL_LOAN_PAYMENT_TYPES;

  /**
   * Fires a new loan payment type action.
   */
  constructor() {}
}

/**
 * Represents a store loan payment type fail action.
 */
export class GetAllLoanPaymentTypeFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = LoanPaymentTypeActionType.GET_ALL_LOAN_PAYMENT_TYPES_FAIL;

  /**
   * Fires a new loan payment type fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store loan payment type success action.
 */
export class GetAllLoanPaymentTypeSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = LoanPaymentTypeActionType.GET_ALL_LOAN_PAYMENT_TYPES_SUCCESS;

  /**
   * Fires a loan payment type success action.
   * @param payload An object contains the list of loan payment Types.
   */
  constructor(public payload: AppHttpResponse<LoanPaymentType[]>) {}
}

/**
 * Lookups-module loan payment type action types.
 */
export type LoanPaymentTypeActions = GetAllLoanPaymentType | GetAllLoanPaymentTypeFail | GetAllLoanPaymentTypeSuccess;
