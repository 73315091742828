import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from 'shared';
import { ProjectsManagementRoutingModule } from 'projects-management/projects-management-routing.module';

import * as fromContainers from 'projects-management/containers';
import * as fromComponents from 'projects-management/components';
import * as fromServices from 'projects-management/services';

/* Ngrx */
import * as fromStore from 'projects-management/store';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { SalesModule } from 'sales';
import { FinancesModule } from 'finances';
import { AppSearchModule } from 'app-search';

@NgModule({
  declarations: [...fromContainers.CONTAINERS, ...fromComponents.COMPONENTS],
  imports: [
    CommonModule,
    SharedModule,
    SalesModule,
    FinancesModule,
    AppSearchModule,
    ProjectsManagementRoutingModule,
    StoreModule.forFeature('projects-management', fromStore.reducers),
    EffectsModule.forFeature(fromStore.effects),
  ],
  exports: [fromComponents.ProjectsSearchComponent],
  providers: [...fromServices.SERVICES],
})
export class ProjectsManagementModule {}
