import { Injectable } from '@angular/core';

import { of } from 'rxjs';
import { switchMap, map, catchError, tap } from 'rxjs/operators';
import { Effect, ofType, Actions } from '@ngrx/effects';

import { NotificationService, StatusCode, TranslationService } from 'shared';
import {
  SystemAccountsActionType,
  FindAccountReceivableAccount,
  FindAccountReceivableAccountSuccess,
  FindAccountReceivableAccountFail,
  FindRevenueAccount,
  FindRevenueAccountSuccess,
  FindRevenueAccountFail,
  FindTaxAccountFail,
  FindTaxAccountSuccess,
  FindTaxAccount,
  FindPettyCashClearingAccount,
  FindPettyCashClearingAccountSuccess,
  FindPettyCashClearingAccountFail,
  FindAccountsPayablesClearingAccountFail,
  FindAccountsPayablesClearingAccountSuccess,
  FindAccountsPayablesClearingAccount,
  FindInventoryClearingAccount,
  FindInventoryClearingAccountSuccess,
  FindInventoryClearingAccountFail,
  FindMaterialAccountFail,
  FindMaterialAccountSuccess,
  FindMaterialAccount,
  FindLiabilityAccount,
  FindLiabilityAccountSuccess,
  FindLiabilityAccountFail,
  FindBankAccountFail,
  FindBankAccountSuccess,
  FindBankAccount,
  FindCashAccount,
  FindCashAccountSuccess,
  FindCashAccountFail,
  FindChequesUnderCollectionAccount,
  FindChequesUnderCollectionAccountSuccess,
  FindChequesUnderCollectionAccountFail,
  FindPaymentsUnderCollectionAccountFail,
  FindPaymentsUnderCollectionAccount,
  FindPaymentsUnderCollectionAccountSuccess,
  FindPointOfSaleCashAccount,
  FindPointOfSaleCashAccountSuccess,
  FindPointOfSaleCashAccountFail,
  FindCOGSAccount,
  FindCOGSAccountFail,
  FindCOGSAccountSuccess,
  FindProfitLossAccount,
  FindProfitLossAccountFail,
  FindProfitLossAccountSuccess,
  FindPendingChequeAccount,
  FindPendingChequeAccountFail,
  FindPendingChequeAccountSuccess,
  FindPaymentsUnderPaymentAccount,
  FindPaymentsUnderPaymentAccountFail,
  FindPaymentsUnderPaymentAccountSuccess,
  FindAdvanceExpensesPettyCashAccount,
  FindAdvanceExpensesPettyCashAccountFail,
  FindAdvanceExpensesPettyCashAccountSuccess,
  FindOwnersCurrentAccount,
  FindOwnersCurrentAccountFail,
  FindOwnersCurrentAccountSuccess,
  FindCarsAccount,
  FindCarsAccountFail,
  FindCarsAccountSuccess,
  FindLandsAccount,
  FindLandsAccountFail,
  FindLandsAccountSuccess,
  FindBuildingsAccount,
  FindBuildingsAccountFail,
  FindBuildingsAccountSuccess,
  FindAssetBrokerageAccount,
  FindAssetBrokerageAccountFail,
  FindAssetBrokerageAccountSuccess,
  FindDesktopDevicesAccount,
  FindDesktopDevicesAccountFail,
  FindDesktopDevicesAccountSuccess,
  FindMachinesAndEquipmentsAccount,
  FindMachinesAndEquipmentsAccountFail,
  FindMachinesAndEquipmentsAccountSuccess,
  FindImprovementsToRentedBuildingsAccount,
  FindImprovementsToRentedBuildingsAccountFail,
  FindImprovementsToRentedBuildingsAccountSuccess,
  FindFurnitureAccount,
  FindFurnitureAccountFail,
  FindFurnitureAccountSuccess,
  FindSalariesPayableAccount,
  FindSalariesPayableAccountFail,
  FindSalariesPayableAccountSuccess,
  FindCashInHandAccount,
  FindCashInHandAccountSuccess,
  FindCashInHandAccountFail,
  FindCashInBanksAccount,
  FindCashInBanksAccountSuccess,
  FindCashInBanksAccountFail,
  FindChecksUnderCollectionAccountSuccess,
  FindChecksUnderCollectionAccount,
  FindChecksUnderCollectionAccountFail,
  FindCashInvestmentsLessThan90DaysAccount,
  FindCashInvestmentsLessThan90DaysAccountSuccess,
  FindCashInvestmentsLessThan90DaysAccountFail,
  FindRestrictedCashInBanksAccount,
  FindRestrictedCashInBanksAccountSuccess,
  FindRestrictedCashInBanksAccountFail,
  FindShortTermDepositsAccount,
  FindShortTermDepositsAccountSuccess,
  FindShortTermDepositsAccountFail,
  FindCashInvestmentsForTradingAccount,
  FindCashInvestmentsForTradingAccountSuccess,
  FindCashInvestmentsForTradingAccountFail,
  FindTradeDebtorsAccount,
  FindTradeDebtorsAccountSuccess,
  FindTradeDebtorsAccountFail,
  FindAllowanceForDoubtfulDebtsAccountFail,
  FindAllowanceForDoubtfulDebtsAccount,
  FindAllowanceForDoubtfulDebtsAccountSuccess,
  FindRelatedPartiesAccountFail,
  FindRelatedPartiesAccountSuccess,
  FindRelatedPartiesAccount,
  FindAdvancesOfStaffAccount,
  FindAdvancesOfStaffAccountSuccess,
  FindAdvancesOfStaffAccountFail,
  FindLettersOfGuaranteeAccount,
  FindLettersOfGuaranteeAccountSuccess,
  FindLettersOfGuaranteeAccountFail,
  FindDocumentaryCreditsAccount,
  FindDocumentaryCreditsAccountSuccess,
  FindDocumentaryCreditsAccountFail,
  FindPettyCashAccountFail,
  FindPettyCashAccount,
  FindPettyCashAccountSuccess,
  FindWellExecutedDetentionAccount,
  FindWellExecutedDetentionAccountFail,
  FindWellExecutedDetentionAccountSuccess,
  FindAccruedRevenueAccount,
  FindAccruedRevenueAccountFail,
  FindAccruedRevenueAccountSuccess,
  FindWorkInProgressUnbilledRevenueAccount,
  FindWorkInProgressUnbilledRevenueAccountFail,
  FindWorkInProgressUnbilledRevenueAccountSuccess,
  FindValueAddedTaxDebitAccount,
  FindValueAddedTaxDebitAccountFail,
  FindValueAddedTaxDebitAccountSuccess,
  FindMiscellaneousDebtorsAccount,
  FindMiscellaneousDebtorsAccountFail,
  FindMiscellaneousDebtorsAccountSuccess,
  FindPrepaidExpensesAccount,
  FindPrepaidExpensesAccountFail,
  FindPrepaidExpensesAccountSuccess,
  FindAdvancePaymentsToVendorsAndContractorsAccount,
  FindAdvancePaymentsToVendorsAndContractorsAccountFail,
  FindAdvancePaymentsToVendorsAndContractorsAccountSuccess,
  FindFinancingChargesForLeaseContractsAccount,
  FindFinancingChargesForLeaseContractsAccountFail,
  FindFinancingChargesForLeaseContractsAccountSuccess,
  FindFinancingChargesForBankLoansAndCreditFacilitiesAccount,
  FindFinancingChargesForBankLoansAndCreditFacilitiesAccountFail,
  FindFinancingChargesForBankLoansAndCreditFacilitiesAccountSuccess,
  FindGoodsAndMaterialsStockAccount,
  FindGoodsAndMaterialsStockAccountFail,
  FindGoodsAndMaterialsStockAccountSuccess,
  FindStockInProcessAccount,
  FindStockInProcessAccountFail,
  FindStockInProcessAccountSuccess,
  FindCompletedStockAccount,
  FindCompletedStockAccountFail,
  FindCompletedStockAccountSuccess,
  FindProvisionForDamagedOrObsoleteInventoryAccount,
  FindProvisionForDamagedOrObsoleteInventoryAccountFail,
  FindProvisionForDamagedOrObsoleteInventoryAccountSuccess,
  FindInvestmentsAvailableForSaleOrHeldToMaturityAccount,
  FindInvestmentsAvailableForSaleOrHeldToMaturityAccountFail,
  FindInvestmentsAvailableForSaleOrHeldToMaturityAccountSuccess,
  FindLongTermDepositsAccount,
  FindLongTermDepositsAccountFail,
  FindLongTermDepositsAccountSuccess,
  FindFinanceChargesForLeaseContractsNonCurrentAccount,
  FindFinanceChargesForLeaseContractsNonCurrentAccountFail,
  FindFinanceChargesForLeaseContractsNonCurrentAccountSuccess,
  FindFinancingChargesForLoansAndCreditFacilitiesNonCurrentAccount,
  FindFinancingChargesForLoansAndCreditFacilitiesNonCurrentAccountFail,
  FindFinancingChargesForLoansAndCreditFacilitiesNonCurrentAccountSuccess,
  FindProjectsUnderImplementationAccount,
  FindProjectsUnderImplementationAccountFail,
  FindProjectsUnderImplementationAccountSuccess,
  FindDevicesAccount,
  FindDevicesAccountFail,
  FindDevicesAccountSuccess,
  FindComputersAccount,
  FindComputersAccountFail,
  FindComputersAccountSuccess,
  FindEquipmentsAccount,
  FindEquipmentsAccountFail,
  FindEquipmentsAccountSuccess,
  FindAssetsUnderFinanceLeasesAccount,
  FindAssetsUnderFinanceLeasesAccountFail,
  FindAssetsUnderFinanceLeasesAccountSuccess,
  FindBuildingConsumptionComplexAccount,
  FindBuildingConsumptionComplexAccountFail,
  FindBuildingConsumptionComplexAccountSuccess,
  FindFurnitureConsumptionComplexAccount,
  FindFurnitureConsumptionComplexAccountFail,
  FindFurnitureConsumptionComplexAccountSuccess,
  FindDevicesConsumptionComplexAccount,
  FindDevicesConsumptionComplexAccountFail,
  FindDevicesConsumptionComplexAccountSuccess,
  FindEquipmentsConsumptionComplexAccount,
  FindEquipmentsConsumptionComplexAccountFail,
  FindEquipmentsConsumptionComplexAccountSuccess,
  FindCarsConsumptionComplexAccount,
  FindCarsConsumptionComplexAccountFail,
  FindCarsConsumptionComplexAccountSuccess,
  FindPooledDepreciationOfAssetsUnderFinanceLeaseContractsAccount,
  FindPooledDepreciationOfAssetsUnderFinanceLeaseContractsAccountFail,
  FindPooledDepreciationOfAssetsUnderFinanceLeaseContractsAccountSuccess,
  FindFameAccount,
  FindFameAccountFail,
  FindFameAccountSuccess,
  FindSoftwareAndSystemsAccount,
  FindSoftwareAndSystemsAccountFail,
  FindSoftwareAndSystemsAccountSuccess,
  FindPatentsAndTrademarksAccount,
  FindPatentsAndTrademarksAccountFail,
  FindPatentsAndTrademarksAccountSuccess,
  FindFameExtinguishingComplexAccount,
  FindFameExtinguishingComplexAccountFail,
  FindFameExtinguishingComplexAccountSuccess,
  FindSoftwareAndHardwareExtinguishingComplexAccount,
  FindSoftwareAndHardwareExtinguishingComplexAccountFail,
  FindSoftwareAndHardwareExtinguishingComplexAccountSuccess,
  FindPatentAndTrademarkExtinguishingComplexAccount,
  FindPatentAndTrademarkExtinguishingComplexAccountFail,
  FindPatentAndTrademarkExtinguishingComplexAccountSuccess,
  FindRealEstateInvestmentLandsAccount,
  FindRealEstateInvestmentLandsAccountFail,
  FindRealEstateInvestmentLandsAccountSuccess,
  FindRealEstateInvestmentBuildingsAccount,
  FindRealEstateInvestmentBuildingsAccountFail,
  FindRealEstateInvestmentBuildingsAccountSuccess,
  FindRealEstateInvestmentBuildingConsumptionComplexAccount,
  FindRealEstateInvestmentBuildingConsumptionComplexAccountFail,
  FindRealEstateInvestmentBuildingConsumptionComplexAccountSuccess,
  FindInvestmentsInSubsidiariesAccount,
  FindInvestmentsInSubsidiariesAccountFail,
  FindInvestmentsInSubsidiariesAccountSuccess,
  FindInvestmentsInAssociatesAccount,
  FindInvestmentsInAssociatesAccountFail,
  FindInvestmentsInAssociatesAccountSuccess,
  FindInvestmentsInJointVenturesAccount,
  FindInvestmentsInJointVenturesAccountFail,
  FindInvestmentsInJointVenturesAccountSuccess,
  FindLoanInstallmentsTheCurrentPartAccount,
  FindLoanInstallmentsTheCurrentPartAccountFail,
  FindLoanInstallmentsTheCurrentPartAccountSuccess,
  FindCurrentContractsForRentTheCurrentPartAccount,
  FindCurrentContractsForRentTheCurrentPartAccountFail,
  FindCurrentContractsForRentTheCurrentPartAccountSuccess,
  FindTradeCreditorsAccount,
  FindTradeCreditorsAccountFail,
  FindTradeCreditorsAccountSuccess,
  FindCreditFacilitiesOverdraftAccount,
  FindCreditFacilitiesOverdraftAccountFail,
  FindCreditFacilitiesOverdraftAccountSuccess,
  FindCreditorsWithholdingBalancesAccount,
  FindCreditorsWithholdingBalancesAccountFail,
  FindCreditorsWithholdingBalancesAccountSuccess,
  FindPayableExpensesAccount,
  FindPayableExpensesAccountFail,
  FindPayableExpensesAccountSuccess,
  FindEmployeesEntitlementsSalariesAccount,
  FindEmployeesEntitlementsSalariesAccountFail,
  FindEmployeesEntitlementsSalariesAccountSuccess,
  FindEmployeesEntitlementsBenefitsAccount,
  FindEmployeesEntitlementsBenefitsAccountFail,
  FindEmployeesEntitlementsBenefitsAccountSuccess,
  FindEmployeesEntitlementsTravelingTicketsAccount,
  FindEmployeesEntitlementsTravelingTicketsAccountFail,
  FindEmployeesEntitlementsTravelingTicketsAccountSuccess,
  FindAdvanceRevenueAccount,
  FindAdvanceRevenueAccountFail,
  FindAdvanceRevenueAccountSuccess,
  FindValueAddedTaxCreditAccount,
  FindValueAddedTaxCreditAccountFail,
  FindValueAddedTaxCreditAccountSuccess,
  FindReceivablesStaffAccount,
  FindReceivablesStaffAccountFail,
  FindReceivablesStaffAccountSuccess,
  FindOtherCurrentLiabilitiesRelatedPartiesAccount,
  FindOtherCurrentLiabilitiesRelatedPartiesAccountFail,
  FindOtherCurrentLiabilitiesRelatedPartiesAccountSuccess,
  FindMiscellaneousCreditorsAccount,
  FindMiscellaneousCreditorsAccountFail,
  FindMiscellaneousCreditorsAccountSuccess,
  FindDividendPayableAccount,
  FindDividendPayableAccountFail,
  FindDividendPayableAccountSuccess,
  FindZakatProvisionAccount,
  FindZakatProvisionAccountFail,
  FindZakatProvisionAccountSuccess,
  FindIncomeTaxProvisionAccount,
  FindIncomeTaxProvisionAccountFail,
  FindIncomeTaxProvisionAccountSuccess,
  FindProvisionForWithholdingTaxAccount,
  FindProvisionForWithholdingTaxAccountFail,
  FindProvisionForWithholdingTaxAccountSuccess,
  FindBankLoansAccount,
  FindBankLoansAccountFail,
  FindBankLoansAccountSuccess,
  FindLeaseLiabilitiesNonCurrentPortionAccount,
  FindLeaseLiabilitiesNonCurrentPortionAccountFail,
  FindLeaseLiabilitiesNonCurrentPortionAccountSuccess,
  FindProvisionForEndOfSeverancePayAccount,
  FindProvisionForEndOfSeverancePayAccountFail,
  FindProvisionForEndOfSeverancePayAccountSuccess,
  FindCapitalAccount,
  FindCapitalAccountFail,
  FindCapitalAccountSuccess,
  FindRegularReserveAccount,
  FindRegularReserveAccountFail,
  FindRegularReserveAccountSuccess,
  FindGeneralReserveAccount,
  FindGeneralReserveAccountFail,
  FindGeneralReserveAccountSuccess,
  FindRetainedEarningsAccount,
  FindRetainedEarningsAccountFail,
  FindRetainedEarningsAccountSuccess,
  FindProfitAndLossBrokerAccount,
  FindProfitAndLossBrokerAccountFail,
  FindProfitAndLossBrokerAccountSuccess,
  FindAdditionalCapitalAccount,
  FindAdditionalCapitalAccountFail,
  FindAdditionalCapitalAccountSuccess,
  FindMainActivitiesSalesRevenueAccount,
  FindMainActivitiesSalesRevenueAccountFail,
  FindMainActivitiesSalesRevenueAccountSuccess,
  FindGainLossOnDisposalOfPropertyAccount,
  FindGainLossOnDisposalOfPropertyAccountFail,
  FindGainLossOnDisposalOfPropertyAccountSuccess,
  FindGainLossOnInvestmentValuationAccount,
  FindGainLossOnInvestmentValuationAccountFail,
  FindGainLossOnInvestmentValuationAccountSuccess,
  FindSupportRevenueAccount,
  FindSupportRevenueAccountFail,
  FindSupportRevenueAccountSuccess,
  FindOtherMiscellaneousIncomeAccount,
  FindOtherMiscellaneousIncomeAccountFail,
  FindOtherMiscellaneousIncomeAccountSuccess,
  FindUsedOfMaterialAccount,
  FindUsedOfMaterialAccountFail,
  FindUsedOfMaterialAccountSuccess,
  FindNonInventoryMaterialCostsAccount,
  FindNonInventoryMaterialCostsAccountFail,
  FindNonInventoryMaterialCostsAccountSuccess,
  FindRentAccount,
  FindRentAccountFail,
  FindRentAccountSuccess,
  FindLocalLaborAccount,
  FindLocalLaborAccountFail,
  FindLocalLaborAccountSuccess,
  FindSalariesAndWagesEmployeeProtectionAccount,
  FindSalariesAndWagesEmployeeProtectionAccountFail,
  FindSalariesAndWagesEmployeeProtectionAccountSuccess,
  FindBenefitToEmployeesAllCostsExceptWageProtectionElementsAccount,
  FindBenefitToEmployeesAllCostsExceptWageProtectionElementsAccountFail,
  FindBenefitToEmployeesAllCostsExceptWageProtectionElementsAccountSuccess,
  FindWorkMissionsAndOvertimeAccount,
  FindWorkMissionsAndOvertimeAccountFail,
  FindWorkMissionsAndOvertimeAccountSuccess,
  FindRepairAndMaintenanceAccount,
  FindRepairAndMaintenanceAccountFail,
  FindRepairAndMaintenanceAccountSuccess,
  FindGovernmentFeesAndSubscriptionsAccount,
  FindGovernmentFeesAndSubscriptionsAccountFail,
  FindGovernmentFeesAndSubscriptionsAccountSuccess,
  FindTheCostOfPublicServicesAccount,
  FindTheCostOfPublicServicesAccountFail,
  FindTheCostOfPublicServicesAccountSuccess,
  FindHospitalityAndCleanlinessAccount,
  FindHospitalityAndCleanlinessAccountFail,
  FindHospitalityAndCleanlinessAccountSuccess,
  FindOfficeConsumablesAccount,
  FindOfficeConsumablesAccountFail,
  FindOfficeConsumablesAccountSuccess,
  FindFinanceChargesLoansAndBankFacilitiesAccount,
  FindFinanceChargesLoansAndBankFacilitiesAccountFail,
  FindFinanceChargesLoansAndBankFacilitiesAccountSuccess,
  FindFinanceChargesLeaseContractsAccount,
  FindFinanceChargesLeaseContractsAccountFail,
  FindFinanceChargesLeaseContractsAccountSuccess,
  FindCommissionsAccount,
  FindCommissionsAccountFail,
  FindCommissionsAccountSuccess,
  FindBankExpensesAccount,
  FindBankExpensesAccountFail,
  FindBankExpensesAccountSuccess,
  FindCostOfRevenueAndGeneralExpensesOthersAccount,
  FindCostOfRevenueAndGeneralExpensesOthersAccountFail,
  FindCostOfRevenueAndGeneralExpensesOthersAccountSuccess,
  FindVendorsBrokerAccount,
  FindVendorsBrokerAccountFail,
  FindVendorsBrokerAccountSuccess,
  FindBuildingDepreciationExpressAccount,
  FindBuildingDepreciationExpressAccountFail,
  FindBuildingDepreciationExpressAccountSuccess,
  FindFurnitureDepreciationExpressAccount,
  FindFurnitureDepreciationExpressAccountFail,
  FindFurnitureDepreciationExpressAccountSuccess,
  FindDevicesDepreciationExpressAccount,
  FindDevicesDepreciationExpressAccountFail,
  FindDevicesDepreciationExpressAccountSuccess,
  FindEquipmentsDepreciationExpressAccount,
  FindEquipmentsDepreciationExpressAccountFail,
  FindEquipmentsDepreciationExpressAccountSuccess,
  FindCarsDepreciationExpressAccount,
  FindCarsDepreciationExpressAccountFail,
  FindCarsDepreciationExpressAccountSuccess,
  FindAssetsUnderFinanceLeasesDepreciationExpensesAccount,
  FindAssetsUnderFinanceLeasesDepreciationExpensesAccountFail,
  FindAssetsUnderFinanceLeasesDepreciationExpensesAccountSuccess,
  FindFameDepreciationExpensesAccount,
  FindFameDepreciationExpensesAccountFail,
  FindFameDepreciationExpensesAccountSuccess,
  FindSoftwareAndSystemsDepreciationExpensesAccount,
  FindSoftwareAndSystemsDepreciationExpensesAccountFail,
  FindSoftwareAndSystemsDepreciationExpensesAccountSuccess,
  FindPatentsAndTrademarksDepreciationExpensesAccount,
  FindPatentsAndTrademarksDepreciationExpensesAccountFail,
  FindPatentsAndTrademarksDepreciationExpensesAccountSuccess,
  FindRealEstateInvestmentBuildingsDepreciationExpensesAccount,
  FindRealEstateInvestmentBuildingsDepreciationExpensesAccountFail,
  FindRealEstateInvestmentBuildingsDepreciationExpensesAccountSuccess,
  FindAssetsBrokerAccount,
  FindAssetsBrokerAccountFail,
  FindAssetsBrokerAccountSuccess,
  FindComputersConsumptionComplexAccountFail,
  FindComputersConsumptionComplexAccount,
  FindComputersConsumptionComplexAccountSuccess,
  FindComputersDepreciationExpressAccount,
  FindComputersDepreciationExpressAccountFail,
  FindComputersDepreciationExpressAccountSuccess,
  FindCurrencyRoundingDifferenceAccount,
  FindCurrencyRoundingDifferenceAccountSuccess,
  FindCurrencyRoundingDifferenceAccountFail,
  FindGosiAccrualAccountSuccess,
  FindGosiAccrualAccountFail,
  FindGosiAccrualAccount,
  FindGosiExpensesAccount,
  FindGosiExpensesAccountSuccess,
  FindGosiExpensesAccountFail,
} from 'finances/store/actions';
import { SystemAccountsService } from 'finances/services';
import { SystemAccounts } from 'lookups/models';

@Injectable()
export class SystemAccountsEffects {
  constructor(
    private actions$: Actions,
    private systemAccountsService: SystemAccountsService,
    private notificationService: NotificationService,
    private translationService: TranslationService
  ) {}

  /* ========================= FIND_ACCOUNT_RECEIVABLE_ACCOUNT =================================== */
  @Effect()
  findAccountReceivableAccount$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_ACCOUNT_RECEIVABLE),
    switchMap((action: FindAccountReceivableAccount) =>
      this.systemAccountsService.findSystemAccountByKey(SystemAccounts.CASH_IN_HAND).pipe(
        map((response) => new FindAccountReceivableAccountSuccess(response)),
        catchError((error) => of(new FindAccountReceivableAccountFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  findAccountReceivableAccountFail$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_ACCOUNT_RECEIVABLE_FAIL),
    tap((action: FindAccountReceivableAccountFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate('FINANCES.SYSTEM_ACCOUNTS.RECEIVABLE_ACCOUNT_NOT_FOUND')
        );
      }
    })
  );

  /* ========================= FIND_REVENUE_ACCOUNT =================================== */
  @Effect()
  findRevenueAccount$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_REVENUE),
    switchMap((action: FindRevenueAccount) =>
      this.systemAccountsService.findSystemAccountByKey(SystemAccounts.CASH_IN_HAND).pipe(
        map((response) => new FindRevenueAccountSuccess(response)),
        catchError((error) => of(new FindRevenueAccountFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  findRevenueAccountFail$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_REVENUE_FAIL),
    tap((action: FindRevenueAccountFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate('FINANCES.SYSTEM_ACCOUNTS.REVENUE_ACCOUNT_NOT_FOUND')
        );
      }
    })
  );

  /* ========================= FIND_TAX_ACCOUNT =================================== */
  @Effect()
  findTaxAccount$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_TAX),
    switchMap((action: FindTaxAccount) =>
      this.systemAccountsService.findSystemAccountByKey(SystemAccounts.CASH_IN_HAND).pipe(
        map((response) => new FindTaxAccountSuccess(response)),
        catchError((error) => of(new FindTaxAccountFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  findTaxAccountFail$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_TAX_FAIL),
    tap((action: FindTaxAccountFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate('FINANCES.SYSTEM_ACCOUNTS.TAX_ACCOUNT_NOT_FOUND')
        );
      }
    })
  );

  /* ========================= FIND_MATERIAL_ACCOUNT =================================== */
  @Effect()
  findMaterialAccount$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_MATERIAL),
    switchMap((action: FindMaterialAccount) =>
      this.systemAccountsService.findSystemAccountByKey(SystemAccounts.CASH_IN_HAND).pipe(
        map((response) => new FindMaterialAccountSuccess(response)),
        catchError((error) => of(new FindMaterialAccountFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  findMaterialAccountFail$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_MATERIAL_FAIL),
    tap((action: FindMaterialAccountFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate('FINANCES.SYSTEM_ACCOUNTS.MATERIAL_ACCOUNT_NOT_FOUND')
        );
      }
    })
  );

  /* ========================= FIND_INVENTORY_CLEARING_ACCOUNT =================================== */
  @Effect()
  findInventoryClearingAccount$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_INVENTORY_CLEARING),
    switchMap((action: FindInventoryClearingAccount) =>
      this.systemAccountsService.findSystemAccountByKey(SystemAccounts.CASH_IN_HAND).pipe(
        map((response) => new FindInventoryClearingAccountSuccess(response)),
        catchError((error) => of(new FindInventoryClearingAccountFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  findInventoryClearingAccountFail$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_INVENTORY_CLEARING_FAIL),
    tap((action: FindInventoryClearingAccountFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate('FINANCES.SYSTEM_ACCOUNTS.INVENTORY_CLEARING_ACCOUNT_NOT_FOUND')
        );
      }
    })
  );

  /* ========================= FIND_ACCOUNTS_PAYABLES_CLEARING_ACCOUNT =================================== */
  @Effect()
  findAccountsPayablesClearingAccount$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_ACCOUNTS_PAYABLES_CLEARING),
    switchMap((action: FindAccountsPayablesClearingAccount) =>
      this.systemAccountsService.findSystemAccountByKey(SystemAccounts.CASH_IN_HAND).pipe(
        map((response) => new FindAccountsPayablesClearingAccountSuccess(response)),
        catchError((error) => of(new FindAccountsPayablesClearingAccountFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  findAccountsPayablesClearingAccountFail$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_ACCOUNTS_PAYABLES_CLEARING_FAIL),
    tap((action: FindAccountsPayablesClearingAccountFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate('FINANCES.SYSTEM_ACCOUNTS.PAYABLES_CLEARING_ACCOUNT_NOT_FOUND')
        );
      }
    })
  );

  /* ========================= FIND_PETTY_CASH_CLEARING_ACCOUNT =================================== */
  @Effect()
  findPettyCashClearingAccount$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_PETTY_CASH_CLEARING),
    switchMap((action: FindPettyCashClearingAccount) =>
      this.systemAccountsService.findSystemAccountByKey(SystemAccounts.CASH_IN_HAND).pipe(
        map((response) => new FindPettyCashClearingAccountSuccess(response)),
        catchError((error) => of(new FindPettyCashClearingAccountFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  findPettyCashClearingAccountFail$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_PETTY_CASH_CLEARING_FAIL),
    tap((action: FindPettyCashClearingAccountFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate('FINANCES.SYSTEM_ACCOUNTS.PETTY_CASH_CLEARING_ACCOUNT_NOT_FOUND')
        );
      }
    })
  );

  /* ========================= FIND_LIABILITY_ACCOUNT =================================== */
  @Effect()
  findLiabilityAccount$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_LIABILITY),
    switchMap((action: FindLiabilityAccount) =>
      this.systemAccountsService.findSystemAccountByKey(SystemAccounts.CASH_IN_HAND).pipe(
        map((response) => new FindLiabilityAccountSuccess(response)),
        catchError((error) => of(new FindLiabilityAccountFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  findLiabilityAccountFail$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_LIABILITY_FAIL),
    tap((action: FindLiabilityAccountFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate('FINANCES.SYSTEM_ACCOUNTS.LIABILITY_ACCOUNT_NOT_FOUND')
        );
      }
    })
  );

  /* ========================= FIND_COGS_ACCOUNT =================================== */
  @Effect()
  findCOGSAccount$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_COGS),
    switchMap((action: FindCOGSAccount) =>
      this.systemAccountsService.findSystemAccountByKey(SystemAccounts.CASH_IN_HAND).pipe(
        map((response) => new FindCOGSAccountSuccess(response)),
        catchError((error) => of(new FindCOGSAccountFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  findCOGSAccountFail$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_COGS_FAIL),
    tap((action: FindCOGSAccountFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate('FINANCES.SYSTEM_ACCOUNTS.COGS_ACCOUNT_NOT_FOUND')
        );
      }
    })
  );

  /* ========================= FIND_PROFIT_LOSS_ACCOUNT =================================== */
  @Effect()
  findProfitLossAccount$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_PROFIT_LOSS),
    switchMap((action: FindProfitLossAccount) =>
      this.systemAccountsService.findSystemAccountByKey(SystemAccounts.CASH_IN_HAND).pipe(
        map((response) => new FindProfitLossAccountSuccess(response)),
        catchError((error) => of(new FindProfitLossAccountFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  findProfitLossAccountFail$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_PROFIT_LOSS_FAIL),
    tap((action: FindProfitLossAccountFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate('FINANCES.SYSTEM_ACCOUNTS.PROFIT_LOSS_ACCOUNT_NOT_FOUND')
        );
      }
    })
  );

  /* ========================= FIND_BANK_ACCOUNT =================================== */
  @Effect()
  findBankAccount$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_BANK_ACCOUNT),
    switchMap((action: FindBankAccount) =>
      this.systemAccountsService.findSystemAccountByKey(SystemAccounts.CASH_IN_HAND).pipe(
        map((response) => new FindBankAccountSuccess(response)),
        catchError((error) => of(new FindBankAccountFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  findBankAccountFail$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_BANK_ACCOUNT_FAIL),
    tap((action: FindBankAccountFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate('FINANCES.SYSTEM_ACCOUNTS.BANK_ACCOUNT_NOT_FOUND')
        );
      }
    })
  );

  /* ========================= FIND_CASH_ACCOUNT =================================== */
  @Effect()
  findCashAccount$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_CASH_ACCOUNT),
    switchMap((action: FindCashAccount) =>
      this.systemAccountsService.findSystemAccountByKey(SystemAccounts.CASH_IN_HAND).pipe(
        map((response) => new FindCashAccountSuccess(response)),
        catchError((error) => of(new FindCashAccountFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  findCashAccountFail$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_CASH_ACCOUNT_FAIL),
    tap((action: FindCashAccountFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate('FINANCES.SYSTEM_ACCOUNTS.CASH_ACCOUNT_NOT_FOUND')
        );
      }
    })
  );

  /* ========================= FIND_CHEQUES_UNDER_COLLECTION_ACCOUNT =================================== */
  @Effect()
  findChequesUnderCollectionAccount$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_CHEQUES_UNDER_COLLECTION),
    switchMap((action: FindChequesUnderCollectionAccount) =>
      this.systemAccountsService.findSystemAccountByKey(SystemAccounts.CASH_IN_HAND).pipe(
        map((response) => new FindChequesUnderCollectionAccountSuccess(response)),
        catchError((error) => of(new FindChequesUnderCollectionAccountFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  findChequesUnderCollectionAccountFail$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_CHEQUES_UNDER_COLLECTION_FAIL),
    tap((action: FindChequesUnderCollectionAccountFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate('FINANCES.SYSTEM_ACCOUNTS.CHEQUES_UNDER_COLLECTION_ACCOUNT_NOT_FOUND')
        );
      }
    })
  );

  /* ========================= FIND_PAYMENTS_UNDER_COLLECTION_ACCOUNT =================================== */
  @Effect()
  findPaymentsUnderCollectionAccount$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_PAYMENTS_UNDER_COLLECTION),
    switchMap((action: FindPaymentsUnderCollectionAccount) =>
      this.systemAccountsService.findSystemAccountByKey(SystemAccounts.CASH_IN_HAND).pipe(
        map((response) => new FindPaymentsUnderCollectionAccountSuccess(response)),
        catchError((error) => of(new FindPaymentsUnderCollectionAccountFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  findPaymentsUnderCollectionAccountFail$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_PAYMENTS_UNDER_COLLECTION_FAIL),
    tap((action: FindPaymentsUnderCollectionAccountFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate('FINANCES.SYSTEM_ACCOUNTS.PAYMENTS_UNDER_COLLECTION_ACCOUNT_NOT_FOUND')
        );
      }
    })
  );

  /* ========================= FIND_POINT_OF_SALE_CASH_ACCOUNT =================================== */
  @Effect()
  findPointOfSaleCashAccount$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_POINT_OF_SALE_CASH),
    switchMap((action: FindPointOfSaleCashAccount) =>
      this.systemAccountsService.findSystemAccountByKey(SystemAccounts.CASH_IN_HAND).pipe(
        map((response) => new FindPointOfSaleCashAccountSuccess(response)),
        catchError((error) => of(new FindPointOfSaleCashAccountFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  findPointOfSaleCashAccountFail$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_POINT_OF_SALE_CASH_FAIL),
    tap((action: FindPointOfSaleCashAccountFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate('FINANCES.SYSTEM_ACCOUNTS.POINT_OF_SALE_CASH_ACCOUNT_NOT_FOUND')
        );
      }
    })
  );

  /* ========================= FIND_PENDING_CHEQUE_ACCOUNT =================================== */
  @Effect()
  findPendingChequeAccount$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_PENDING_CHEQUE),
    switchMap((action: FindPendingChequeAccount) =>
      this.systemAccountsService.findSystemAccountByKey(SystemAccounts.CASH_IN_HAND).pipe(
        map((response) => new FindPendingChequeAccountSuccess(response)),
        catchError((error) => of(new FindPendingChequeAccountFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  findPendingChequeAccountFail$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_PENDING_CHEQUE_FAIL),
    tap((action: FindPendingChequeAccountFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate('FINANCES.SYSTEM_ACCOUNTS.PENDING_CHEQUE_ACCOUNT_NOT_FOUND')
        );
      }
    })
  );

  /* ========================= FIND_PAYMENTS_UNDER_PAYMENT_ACCOUNT =================================== */
  @Effect()
  findPaymentsUnderPaymentAccount$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_PAYMENTS_UNDER_PAYMENT),
    switchMap((action: FindPaymentsUnderPaymentAccount) =>
      this.systemAccountsService.findSystemAccountByKey(SystemAccounts.CASH_IN_HAND).pipe(
        map((response) => new FindPaymentsUnderPaymentAccountSuccess(response)),
        catchError((error) => of(new FindPaymentsUnderPaymentAccountFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  findPaymentsUnderPaymentAccountFail$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_PAYMENTS_UNDER_PAYMENT_FAIL),
    tap((action: FindPaymentsUnderPaymentAccountFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate('FINANCES.SYSTEM_ACCOUNTS.PAYMENTS_UNDER_PAYMENT_ACCOUNT_NOT_FOUND')
        );
      }
    })
  );

  /* ========================= FIND_ADVANCE_EXPENSES_PETTY_CASH_ACCOUNT =================================== */
  @Effect()
  findAdvanceExpensesPettyCashAccount$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_ADVANCE_EXPENSES_PETTY_CASH),
    switchMap((action: FindAdvanceExpensesPettyCashAccount) =>
      this.systemAccountsService.findSystemAccountByKey(SystemAccounts.CASH_IN_HAND).pipe(
        map((response) => new FindAdvanceExpensesPettyCashAccountSuccess(response)),
        catchError((error) => of(new FindAdvanceExpensesPettyCashAccountFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  findAdvanceExpensesPettyCashAccountFail$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_ADVANCE_EXPENSES_PETTY_CASH_FAIL),
    tap((action: FindAdvanceExpensesPettyCashAccountFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate('FINANCES.SYSTEM_ACCOUNTS.ADVANCE_EXPENSES_PETTY_CASH_ACCOUNT_NOT_FOUND')
        );
      }
    })
  );

  /* ========================= FIND_OWNERS_CURRENT_ACCOUNT =================================== */
  @Effect()
  findOwnersCurrentAccount$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_OWNERS_CURRENT_ACCOUNT),
    switchMap((action: FindOwnersCurrentAccount) =>
      this.systemAccountsService.findSystemAccountByKey(SystemAccounts.CASH_IN_HAND).pipe(
        map((response) => new FindOwnersCurrentAccountSuccess(response)),
        catchError((error) => of(new FindOwnersCurrentAccountFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  findOwnersCurrentAccountFail$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_OWNERS_CURRENT_ACCOUNT_FAIL),
    tap((action: FindOwnersCurrentAccountFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate('FINANCES.SYSTEM_ACCOUNTS.OWNERS_CURRENT_ACCOUNT_NOT_FOUND')
        );
      }
    })
  );

  /* ========================= FIND_ASSET_BROKERAGE_ACCOUNT =================================== */
  @Effect()
  findAssetBrokerageAccount$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_ASSET_BROKERAGE_ACCOUNT),
    switchMap((action: FindAssetBrokerageAccount) =>
      this.systemAccountsService.findSystemAccountByKey(SystemAccounts.CASH_IN_HAND).pipe(
        map((response) => new FindAssetBrokerageAccountSuccess(response)),
        catchError((error) => of(new FindAssetBrokerageAccountFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  findAssetBrokerageAccountFail$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_ASSET_BROKERAGE_ACCOUNT_FAIL),
    tap((action: FindAssetBrokerageAccountFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate('FINANCES.SYSTEM_ACCOUNTS.ASSET_BROKERAGE_ACCOUNT_NOT_FOUND')
        );
      }
    })
  );

  /* ========================= FIND_DESKTOP_DEVICES_ACCOUNT =================================== */
  @Effect()
  findDesktopDevicesAccount$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_DESKTOP_DEVICES_ACCOUNT),
    switchMap((action: FindDesktopDevicesAccount) =>
      this.systemAccountsService.findSystemAccountByKey(SystemAccounts.CASH_IN_HAND).pipe(
        map((response) => new FindDesktopDevicesAccountSuccess(response)),
        catchError((error) => of(new FindDesktopDevicesAccountFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  findDesktopDevicesAccountFail$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_DESKTOP_DEVICES_ACCOUNT_FAIL),
    tap((action: FindDesktopDevicesAccountFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate('FINANCES.SYSTEM_ACCOUNTS.DESKTOP_DEVICES_ACCOUNT_NOT_FOUND')
        );
      }
    })
  );
  /* ========================= FIND_MACHINES_AND_EQUIPMENTS_ACCOUNT =================================== */
  @Effect()
  findMachinesAndEquipmentsAccount$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_MACHINES_AND_EQUIPMENTS_ACCOUNT),
    switchMap((action: FindMachinesAndEquipmentsAccount) =>
      this.systemAccountsService.findSystemAccountByKey(SystemAccounts.CASH_IN_HAND).pipe(
        map((response) => new FindMachinesAndEquipmentsAccountSuccess(response)),
        catchError((error) => of(new FindMachinesAndEquipmentsAccountFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  findMachinesAndEquipmentsAccountFail$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_MACHINES_AND_EQUIPMENTS_ACCOUNT_FAIL),
    tap((action: FindMachinesAndEquipmentsAccountFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate('FINANCES.SYSTEM_ACCOUNTS.MACHINES_AND_EQUIPMENTS_ACCOUNT_NOT_FOUND')
        );
      }
    })
  );
  /* ========================= FIND_IMPROVEMENTS_TO_RENTED_BUILDINGS_ACCOUNT =================================== */
  @Effect()
  findImprovementsToRentedBuildingsAccount$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_IMPROVEMENTS_TO_RENTED_BUILDINGS_ACCOUNT),
    switchMap((action: FindImprovementsToRentedBuildingsAccount) =>
      this.systemAccountsService.findSystemAccountByKey(SystemAccounts.CASH_IN_HAND).pipe(
        map((response) => new FindImprovementsToRentedBuildingsAccountSuccess(response)),
        catchError((error) => of(new FindImprovementsToRentedBuildingsAccountFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  findImprovementsToRentedBuildingsAccountFail$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_IMPROVEMENTS_TO_RENTED_BUILDINGS_ACCOUNT_FAIL),
    tap((action: FindImprovementsToRentedBuildingsAccountFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate(
            'FINANCES.SYSTEM_ACCOUNTS.IMPROVEMENTS_TO_RENTED_BUILDINGS_ACCOUNT_NOT_FOUND'
          )
        );
      }
    })
  );

  /* ========================= FIND_SALARIES_PAYABLE_ACCOUNT =================================== */
  @Effect()
  findSalariesPayableAccount$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_SALARIES_PAYABLE_ACCOUNT),
    switchMap((action: FindSalariesPayableAccount) =>
      this.systemAccountsService.findSystemAccountByKey(SystemAccounts.CASH_IN_HAND).pipe(
        map((response) => new FindSalariesPayableAccountSuccess(response)),
        catchError((error) => of(new FindSalariesPayableAccountFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  findSalariesPayableAccountFail$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_SALARIES_PAYABLE_ACCOUNT_FAIL),
    tap((action: FindSalariesPayableAccountFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate('FINANCES.SYSTEM_ACCOUNTS.SALARIES_PAYABLE_ACCOUNT_NOT_FOUND')
        );
      }
    })
  );

  /* &&&&&&&&&&&&&&&&&&&&&&&&&& NEW SYSTEMS ACCOUNTS &&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&& */

  /* ========================= FIND_CASH_IN_HAND_ACCOUNT =================================== */
  @Effect()
  findCashInHandAccount$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_CASH_IN_HAND_ACCOUNT),
    switchMap((action: FindCashInHandAccount) =>
      this.systemAccountsService.findSystemAccountByKey(SystemAccounts.CASH_IN_HAND).pipe(
        map((response) => new FindCashInHandAccountSuccess(response)),
        catchError((error) => of(new FindCashInHandAccountFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  findCashInHandAccountFail$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_CASH_IN_HAND_ACCOUNT_FAIL),
    tap((action: FindCashInHandAccountFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate('FINANCES.SYSTEM_ACCOUNTS.CASH_IN_HAND_ACCOUNT_NOT_FOUND')
        );
      }
    })
  );

  /* ========================= FIND_CASH_IN_BANKS_ACCOUNT =================================== */
  @Effect()
  findCashInBanksAccount$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_CASH_IN_BANKS_ACCOUNT),
    switchMap((action: FindCashInBanksAccount) =>
      this.systemAccountsService.findSystemAccountByKey(SystemAccounts.CASH_IN_BANKS).pipe(
        map((response) => new FindCashInBanksAccountSuccess(response)),
        catchError((error) => of(new FindCashInBanksAccountFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  findCashInBanksAccountFail$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_CASH_IN_BANKS_ACCOUNT_FAIL),
    tap((action: FindCashInBanksAccountFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate('FINANCES.SYSTEM_ACCOUNTS.CASH_IN_BANKS_ACCOUNT_NOT_FOUND')
        );
      }
    })
  );

  /* ========================= FIND_CHECKS_UNDER_COLLECTION_ACCOUNT =================================== */
  @Effect()
  findChecksUnderCollectionAccount$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_CHECKS_UNDER_COLLECTION_ACCOUNT),
    switchMap((action: FindChecksUnderCollectionAccount) =>
      this.systemAccountsService.findSystemAccountByKey(SystemAccounts.CHECKS_UNDER_COLLECTION).pipe(
        map((response) => new FindChecksUnderCollectionAccountSuccess(response)),
        catchError((error) => of(new FindChecksUnderCollectionAccountFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  findChecksUnderCollectionAccountFail$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_CHECKS_UNDER_COLLECTION_ACCOUNT_FAIL),
    tap((action: FindChecksUnderCollectionAccountFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate('FINANCES.SYSTEM_ACCOUNTS.CHECKS_UNDER_COLLECTION_ACCOUNT_NOT_FOUND')
        );
      }
    })
  );

  /* ========================= FIND_CASH_INVESTMENTS_LESS_THAN_90_DAYS_ACCOUNT =================================== */
  @Effect()
  findCashInvestmentsLessThan90DaysAccount$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_CASH_INVESTMENTS_LESS_THAN_90_DAYS_ACCOUNT),
    switchMap((action: FindCashInvestmentsLessThan90DaysAccount) =>
      this.systemAccountsService.findSystemAccountByKey(SystemAccounts.CASH_INVESTMENTS_LESS_THAN_90_DAYS).pipe(
        map((response) => new FindCashInvestmentsLessThan90DaysAccountSuccess(response)),
        catchError((error) => of(new FindCashInvestmentsLessThan90DaysAccountFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  findCashInvestmentsLessThan90DaysAccountFail$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_CASH_INVESTMENTS_LESS_THAN_90_DAYS_ACCOUNT_FAIL),
    tap((action: FindCashInvestmentsLessThan90DaysAccountFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate(
            'FINANCES.SYSTEM_ACCOUNTS.CASH_INVESTMENTS_LESS_THAN_90_DAYS_ACCOUNT_NOT_FOUND'
          )
        );
      }
    })
  );

  /* ========================= FIND_RESTRICTED_CASH_IN_BANKS_ACCOUNT =================================== */
  @Effect()
  findRestrictedCashInBanksAccount$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_RESTRICTED_CASH_IN_BANKS_ACCOUNT),
    switchMap((action: FindRestrictedCashInBanksAccount) =>
      this.systemAccountsService.findSystemAccountByKey(SystemAccounts.RESTRICTED_CASH_IN_BANKS).pipe(
        map((response) => new FindRestrictedCashInBanksAccountSuccess(response)),
        catchError((error) => of(new FindRestrictedCashInBanksAccountFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  findRestrictedCashInBanksAccountFail$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_RESTRICTED_CASH_IN_BANKS_ACCOUNT_FAIL),
    tap((action: FindRestrictedCashInBanksAccountFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate('FINANCES.SYSTEM_ACCOUNTS.RESTRICTED_CASH_IN_BANKS_ACCOUNT_NOT_FOUND')
        );
      }
    })
  );

  /* ========================= FIND_SHORT_TERM_DEPOSITS_ACCOUNT =================================== */
  @Effect()
  findShortTermDepositsAccount$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_SHORT_TERM_DEPOSITS_ACCOUNT),
    switchMap((action: FindShortTermDepositsAccount) =>
      this.systemAccountsService.findSystemAccountByKey(SystemAccounts.SHORT_TERM_DEPOSITS).pipe(
        map((response) => new FindShortTermDepositsAccountSuccess(response)),
        catchError((error) => of(new FindShortTermDepositsAccountFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  findShortTermDepositsAccountFail$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_SHORT_TERM_DEPOSITS_ACCOUNT_FAIL),
    tap((action: FindShortTermDepositsAccountFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate('FINANCES.SYSTEM_ACCOUNTS.SHORT_TERM_DEPOSITS_ACCOUNT_NOT_FOUND')
        );
      }
    })
  );

  /* ========================= FIND_CASH_INVESTMENTS_FOR_TRADING_ACCOUNT =================================== */
  @Effect()
  findCashInvestmentsForTradingAccount$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_CASH_INVESTMENTS_FOR_TRADING_ACCOUNT),
    switchMap((action: FindCashInvestmentsForTradingAccount) =>
      this.systemAccountsService.findSystemAccountByKey(SystemAccounts.CASH_INVESTMENTS_FOR_TRADING).pipe(
        map((response) => new FindCashInvestmentsForTradingAccountSuccess(response)),
        catchError((error) => of(new FindCashInvestmentsForTradingAccountFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  findCashInvestmentsForTradingAccountFail$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_CASH_INVESTMENTS_FOR_TRADING_ACCOUNT_FAIL),
    tap((action: FindCashInvestmentsForTradingAccountFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate('FINANCES.SYSTEM_ACCOUNTS.CASH_INVESTMENTS_FOR_TRADING_ACCOUNT_NOT_FOUND')
        );
      }
    })
  );

  /* ========================= FIND_TRADE_DEBTORS_ACCOUNT =================================== */
  @Effect()
  findTradeDebtorsAccount$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_TRADE_DEBTORS_ACCOUNT),
    switchMap((action: FindTradeDebtorsAccount) =>
      this.systemAccountsService.findSystemAccountByKey(SystemAccounts.TRADE_DEBTORS).pipe(
        map((response) => new FindTradeDebtorsAccountSuccess(response)),
        catchError((error) => of(new FindTradeDebtorsAccountFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  findTradeDebtorsAccountFail$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_TRADE_DEBTORS_ACCOUNT_FAIL),
    tap((action: FindTradeDebtorsAccountFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate('FINANCES.SYSTEM_ACCOUNTS.TRADE_DEBTORS_ACCOUNT_NOT_FOUND')
        );
      }
    })
  );

  /* ========================= FIND_ALLOWANCE_FOR_DOUBTFUL_DEBTS_ACCOUNT =================================== */
  @Effect()
  findAllowanceForDoubtfulDebtsAccount$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_ALLOWANCE_FOR_DOUBTFUL_DEBTS_ACCOUNT),
    switchMap((action: FindAllowanceForDoubtfulDebtsAccount) =>
      this.systemAccountsService.findSystemAccountByKey(SystemAccounts.ALLOWANCE_FOR_DOUBTFUL_DEBTS).pipe(
        map((response) => new FindAllowanceForDoubtfulDebtsAccountSuccess(response)),
        catchError((error) => of(new FindAllowanceForDoubtfulDebtsAccountFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  findAllowanceForDoubtfulDebtsAccountFail$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_ALLOWANCE_FOR_DOUBTFUL_DEBTS_ACCOUNT_FAIL),
    tap((action: FindAllowanceForDoubtfulDebtsAccountFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate('FINANCES.SYSTEM_ACCOUNTS.ALLOWANCE_FOR_DOUBTFUL_DEBTS_ACCOUNT_NOT_FOUND')
        );
      }
    })
  );

  /* ========================= FIND_RELATED_PARTIES_ACCOUNT =================================== */
  @Effect()
  findRelatedPartiesAccount$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_RELATED_PARTIES_ACCOUNT),
    switchMap((action: FindRelatedPartiesAccount) =>
      this.systemAccountsService.findSystemAccountByKey(SystemAccounts.RELATED_PARTIES).pipe(
        map((response) => new FindRelatedPartiesAccountSuccess(response)),
        catchError((error) => of(new FindRelatedPartiesAccountFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  findRelatedPartiesAccountFail$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_RELATED_PARTIES_ACCOUNT_FAIL),
    tap((action: FindRelatedPartiesAccountFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate('FINANCES.SYSTEM_ACCOUNTS.RELATED_PARTIES_ACCOUNT_NOT_FOUND')
        );
      }
    })
  );

  /* ========================= FIND_ADVANCES_OF_STAFF_ACCOUNT =================================== */
  @Effect()
  findAdvancesOfStaffAccount$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_ADVANCES_OF_STAFF_ACCOUNT),
    switchMap((action: FindAdvancesOfStaffAccount) =>
      this.systemAccountsService.findSystemAccountByKey(SystemAccounts.ADVANCES_OF_STAFF).pipe(
        map((response) => new FindAdvancesOfStaffAccountSuccess(response)),
        catchError((error) => of(new FindAdvancesOfStaffAccountFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  findAdvancesOfStaffAccountFail$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_ADVANCES_OF_STAFF_ACCOUNT_FAIL),
    tap((action: FindAdvancesOfStaffAccountFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate('FINANCES.SYSTEM_ACCOUNTS.ADVANCES_OF_STAFF_ACCOUNT_NOT_FOUND')
        );
      }
    })
  );

  /* ========================= FIND_LETTERS_OF_GUARANTEE_ACCOUNT =================================== */
  @Effect()
  findLettersOfGuaranteeAccount$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_LETTERS_OF_GUARANTEE_ACCOUNT),
    switchMap((action: FindLettersOfGuaranteeAccount) =>
      this.systemAccountsService.findSystemAccountByKey(SystemAccounts.LETTERS_OF_GUARANTEE).pipe(
        map((response) => new FindLettersOfGuaranteeAccountSuccess(response)),
        catchError((error) => of(new FindLettersOfGuaranteeAccountFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  findLettersOfGuaranteeAccountFail$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_LETTERS_OF_GUARANTEE_ACCOUNT_FAIL),
    tap((action: FindLettersOfGuaranteeAccountFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate('FINANCES.SYSTEM_ACCOUNTS.LETTERS_OF_GUARANTEE_ACCOUNT_NOT_FOUND')
        );
      }
    })
  );

  /* ========================= FIND_DOCUMENTARY_CREDITS_ACCOUNT =================================== */
  @Effect()
  findDocumentaryCreditsAccount$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_DOCUMENTARY_CREDITS_ACCOUNT),
    switchMap((action: FindDocumentaryCreditsAccount) =>
      this.systemAccountsService.findSystemAccountByKey(SystemAccounts.DOCUMENTARY_CREDITS).pipe(
        map((response) => new FindDocumentaryCreditsAccountSuccess(response)),
        catchError((error) => of(new FindDocumentaryCreditsAccountFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  findDocumentaryCreditsAccountFail$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_DOCUMENTARY_CREDITS_ACCOUNT_FAIL),
    tap((action: FindDocumentaryCreditsAccountFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate('FINANCES.SYSTEM_ACCOUNTS.DOCUMENTARY_CREDITS_ACCOUNT_NOT_FOUND')
        );
      }
    })
  );

  /* ========================= FIND_PETTY_CASH_ACCOUNT =================================== */
  @Effect()
  findPettyCashAccount$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_PETTY_CASH_ACCOUNT),
    switchMap((action: FindPettyCashAccount) =>
      this.systemAccountsService.findSystemAccountByKey(SystemAccounts.PETTY_CASH).pipe(
        map((response) => new FindPettyCashAccountSuccess(response)),
        catchError((error) => of(new FindPettyCashAccountFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  findPettyCashAccountFail$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_PETTY_CASH_ACCOUNT_FAIL),
    tap((action: FindPettyCashAccountFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate('FINANCES.SYSTEM_ACCOUNTS.PETTY_CASH_ACCOUNT_NOT_FOUND')
        );
      }
    })
  );

  /* ========================= FIND_WELL_EXECUTED_DETENTION_ACCOUNT =================================== */
  @Effect()
  findWellExecutedDetentionAccount$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_WELL_EXECUTED_DETENTION_ACCOUNT),
    switchMap((action: FindWellExecutedDetentionAccount) =>
      this.systemAccountsService.findSystemAccountByKey(SystemAccounts.WELL_EXECUTED_DETENTION).pipe(
        map((response) => new FindWellExecutedDetentionAccountSuccess(response)),
        catchError((error) => of(new FindWellExecutedDetentionAccountFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  findWellExecutedDetentionAccountFail$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_WELL_EXECUTED_DETENTION_ACCOUNT_FAIL),
    tap((action: FindWellExecutedDetentionAccountFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate('FINANCES.SYSTEM_ACCOUNTS.WELL_EXECUTED_DETENTION_ACCOUNT_NOT_FOUND')
        );
      }
    })
  );

  /* ========================= FIND_ACCRUED_REVENUE_ACCOUNT =================================== */
  @Effect()
  findAccruedRevenueAccount$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_ACCRUED_REVENUE_ACCOUNT),
    switchMap((action: FindAccruedRevenueAccount) =>
      this.systemAccountsService.findSystemAccountByKey(SystemAccounts.ACCRUED_REVENUE).pipe(
        map((response) => new FindAccruedRevenueAccountSuccess(response)),
        catchError((error) => of(new FindAccruedRevenueAccountFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  findAccruedRevenueAccountFail$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_ACCRUED_REVENUE_ACCOUNT_FAIL),
    tap((action: FindAccruedRevenueAccountFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate('FINANCES.SYSTEM_ACCOUNTS.ACCRUED_REVENUE_ACCOUNT_NOT_FOUND')
        );
      }
    })
  );

  /* ========================= FIND_WORK_IN_PROGRESS_UNBILLED_REVENUE_ACCOUNT =================================== */
  @Effect()
  findWorkInProgressUnbilledRevenueAccount$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_WORK_IN_PROGRESS_UNBILLED_REVENUE_ACCOUNT),
    switchMap((action: FindWorkInProgressUnbilledRevenueAccount) =>
      this.systemAccountsService.findSystemAccountByKey(SystemAccounts.WORK_IN_PROGRESS_UNBILLED_REVENUE).pipe(
        map((response) => new FindWorkInProgressUnbilledRevenueAccountSuccess(response)),
        catchError((error) => of(new FindWorkInProgressUnbilledRevenueAccountFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  findWorkInProgressUnbilledRevenueAccountFail$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_WORK_IN_PROGRESS_UNBILLED_REVENUE_ACCOUNT_FAIL),
    tap((action: FindWorkInProgressUnbilledRevenueAccountFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate(
            'FINANCES.SYSTEM_ACCOUNTS.WORK_IN_PROGRESS_UNBILLED_REVENUE_ACCOUNT_NOT_FOUND'
          )
        );
      }
    })
  );

  /* ========================= FIND_VALUE_ADDED_TAX_DEBIT_ACCOUNT =================================== */
  @Effect()
  findValueAddedTaxDebitAccount$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_VALUE_ADDED_TAX_DEBIT_ACCOUNT),
    switchMap((action: FindValueAddedTaxDebitAccount) =>
      this.systemAccountsService.findSystemAccountByKey(SystemAccounts.VALUE_ADDED_TAX_DEBIT).pipe(
        map((response) => new FindValueAddedTaxDebitAccountSuccess(response)),
        catchError((error) => of(new FindValueAddedTaxDebitAccountFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  findValueAddedTaxDebitAccountFail$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_VALUE_ADDED_TAX_DEBIT_ACCOUNT_FAIL),
    tap((action: FindValueAddedTaxDebitAccountFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate('FINANCES.SYSTEM_ACCOUNTS.VALUE_ADDED_TAX_DEBIT_ACCOUNT_NOT_FOUND')
        );
      }
    })
  );

  /* ========================= FIND_MISCELLANEOUS_DEBTORS_ACCOUNT =================================== */
  @Effect()
  findMiscellaneousDebtorsAccount$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_MISCELLANEOUS_DEBTORS_ACCOUNT),
    switchMap((action: FindMiscellaneousDebtorsAccount) =>
      this.systemAccountsService.findSystemAccountByKey(SystemAccounts.MISCELLANEOUS_DEBTORS).pipe(
        map((response) => new FindMiscellaneousDebtorsAccountSuccess(response)),
        catchError((error) => of(new FindMiscellaneousDebtorsAccountFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  findMiscellaneousDebtorsAccountFail$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_MISCELLANEOUS_DEBTORS_ACCOUNT_FAIL),
    tap((action: FindMiscellaneousDebtorsAccountFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate('FINANCES.SYSTEM_ACCOUNTS.MISCELLANEOUS_DEBTORS_ACCOUNT_NOT_FOUND')
        );
      }
    })
  );

  /* ========================= FIND_PREPAID_EXPENSES_ACCOUNT =================================== */
  @Effect()
  findPrepaidExpensesAccount$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_PREPAID_EXPENSES_ACCOUNT),
    switchMap((action: FindPrepaidExpensesAccount) =>
      this.systemAccountsService.findSystemAccountByKey(SystemAccounts.PREPAID_EXPENSES).pipe(
        map((response) => new FindPrepaidExpensesAccountSuccess(response)),
        catchError((error) => of(new FindPrepaidExpensesAccountFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  findPrepaidExpensesAccountFail$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_PREPAID_EXPENSES_ACCOUNT_FAIL),
    tap((action: FindPrepaidExpensesAccountFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate('FINANCES.SYSTEM_ACCOUNTS.PREPAID_EXPENSES_ACCOUNT_NOT_FOUND')
        );
      }
    })
  );

  /* ========================= FIND_ADVANCE_PAYMENTS_TO_VENDORS_AND_CONTRACTORS_ACCOUNT =================================== */
  @Effect()
  findAdvancePaymentsToVendorsAndContractorsAccount$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_ADVANCE_PAYMENTS_TO_VENDORS_AND_CONTRACTORS_ACCOUNT),
    switchMap((action: FindAdvancePaymentsToVendorsAndContractorsAccount) =>
      this.systemAccountsService
        .findSystemAccountByKey(SystemAccounts.ADVANCE_PAYMENTS_TO_VENDORS_AND_CONTRACTORS)
        .pipe(
          map((response) => new FindAdvancePaymentsToVendorsAndContractorsAccountSuccess(response)),
          catchError((error) => of(new FindAdvancePaymentsToVendorsAndContractorsAccountFail(error)))
        )
    )
  );

  @Effect({ dispatch: false })
  findAdvancePaymentsToVendorsAndContractorsAccountFail$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_ADVANCE_PAYMENTS_TO_VENDORS_AND_CONTRACTORS_ACCOUNT_FAIL),
    tap((action: FindAdvancePaymentsToVendorsAndContractorsAccountFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate(
            'FINANCES.SYSTEM_ACCOUNTS.ADVANCE_PAYMENTS_TO_VENDORS_AND_CONTRACTORS_ACCOUNT_NOT_FOUND'
          )
        );
      }
    })
  );

  /* ========================= FIND_FINANCING_CHARGES_FOR_LEASE_CONTRACTS_ACCOUNT =================================== */
  @Effect()
  findFinancingChargesForLeaseContractsAccount$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_FINANCING_CHARGES_FOR_LEASE_CONTRACTS_ACCOUNT),
    switchMap((action: FindFinancingChargesForLeaseContractsAccount) =>
      this.systemAccountsService.findSystemAccountByKey(SystemAccounts.FINANCING_CHARGES_FOR_LEASE_CONTRACTS).pipe(
        map((response) => new FindFinancingChargesForLeaseContractsAccountSuccess(response)),
        catchError((error) => of(new FindFinancingChargesForLeaseContractsAccountFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  findFinancingChargesForLeaseContractsAccountFail$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_FINANCING_CHARGES_FOR_LEASE_CONTRACTS_ACCOUNT_FAIL),
    tap((action: FindFinancingChargesForLeaseContractsAccountFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate(
            'FINANCES.SYSTEM_ACCOUNTS.FINANCING_CHARGES_FOR_LEASE_CONTRACTS_ACCOUNT_NOT_FOUND'
          )
        );
      }
    })
  );

  /* ========================= FIND_FINANCING_CHARGES_FOR_BANK_LOANS_AND_CREDIT_FACILITIES_ACCOUNT =================================== */
  @Effect()
  findFinancingChargesForBankLoansAndCreditFacilitiesAccount$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_FINANCING_CHARGES_FOR_BANK_LOANS_AND_CREDIT_FACILITIES_ACCOUNT),
    switchMap((action: FindFinancingChargesForBankLoansAndCreditFacilitiesAccount) =>
      this.systemAccountsService
        .findSystemAccountByKey(SystemAccounts.FINANCING_CHARGES_FOR_BANK_LOANS_AND_CREDIT_FACILITIES)
        .pipe(
          map((response) => new FindFinancingChargesForBankLoansAndCreditFacilitiesAccountSuccess(response)),
          catchError((error) => of(new FindFinancingChargesForBankLoansAndCreditFacilitiesAccountFail(error)))
        )
    )
  );

  @Effect({ dispatch: false })
  findFinancingChargesForBankLoansAndCreditFacilitiesAccountFail$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_FINANCING_CHARGES_FOR_BANK_LOANS_AND_CREDIT_FACILITIES_ACCOUNT_FAIL),
    tap((action: FindFinancingChargesForBankLoansAndCreditFacilitiesAccountFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate(
            'FINANCES.SYSTEM_ACCOUNTS.FINANCING_CHARGES_FOR_BANK_LOANS_AND_CREDIT_FACILITIES_ACCOUNT_NOT_FOUND'
          )
        );
      }
    })
  );

  /* ========================= FIND_GOODS_AND_MATERIALS_STOCK_ACCOUNT =================================== */
  @Effect()
  findGoodsAndMaterialsStockAccount$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_GOODS_AND_MATERIALS_STOCK_ACCOUNT),
    switchMap((action: FindGoodsAndMaterialsStockAccount) =>
      this.systemAccountsService.findSystemAccountByKey(SystemAccounts.GOODS_AND_MATERIALS_STOCK).pipe(
        map((response) => new FindGoodsAndMaterialsStockAccountSuccess(response)),
        catchError((error) => of(new FindGoodsAndMaterialsStockAccountFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  findGoodsAndMaterialsStockAccountFail$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_GOODS_AND_MATERIALS_STOCK_ACCOUNT_FAIL),
    tap((action: FindGoodsAndMaterialsStockAccountFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate('FINANCES.SYSTEM_ACCOUNTS.GOODS_AND_MATERIALS_STOCK_ACCOUNT_NOT_FOUND')
        );
      }
    })
  );

  /* ========================= FIND_STOCK_IN_PROCESS_ACCOUNT =================================== */
  @Effect()
  findStockInProcessAccount$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_STOCK_IN_PROCESS_ACCOUNT),
    switchMap((action: FindStockInProcessAccount) =>
      this.systemAccountsService.findSystemAccountByKey(SystemAccounts.STOCK_IN_PROCESS).pipe(
        map((response) => new FindStockInProcessAccountSuccess(response)),
        catchError((error) => of(new FindStockInProcessAccountFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  findStockInProcessAccountFail$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_STOCK_IN_PROCESS_ACCOUNT_FAIL),
    tap((action: FindStockInProcessAccountFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate('FINANCES.SYSTEM_ACCOUNTS.STOCK_IN_PROCESS_ACCOUNT_NOT_FOUND')
        );
      }
    })
  );

  /* ========================= FIND_COMPLETED_STOCK_ACCOUNT =================================== */
  @Effect()
  findCompletedStockAccount$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_COMPLETED_STOCK_ACCOUNT),
    switchMap((action: FindCompletedStockAccount) =>
      this.systemAccountsService.findSystemAccountByKey(SystemAccounts.COMPLETED_STOCK).pipe(
        map((response) => new FindCompletedStockAccountSuccess(response)),
        catchError((error) => of(new FindCompletedStockAccountFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  findCompletedStockAccountFail$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_COMPLETED_STOCK_ACCOUNT_FAIL),
    tap((action: FindCompletedStockAccountFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate('FINANCES.SYSTEM_ACCOUNTS.COMPLETED_STOCK_ACCOUNT_NOT_FOUND')
        );
      }
    })
  );

  /* ========================= FIND_PROVISION_FOR_DAMAGED_OR_OBSOLETE_INVENTORY_ACCOUNT =================================== */
  @Effect()
  findProvisionForDamagedOrObsoleteInventoryAccount$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_PROVISION_FOR_DAMAGED_OR_OBSOLETE_INVENTORY_ACCOUNT),
    switchMap((action: FindProvisionForDamagedOrObsoleteInventoryAccount) =>
      this.systemAccountsService
        .findSystemAccountByKey(SystemAccounts.PROVISION_FOR_DAMAGED_OR_OBSOLETE_INVENTORY)
        .pipe(
          map((response) => new FindProvisionForDamagedOrObsoleteInventoryAccountSuccess(response)),
          catchError((error) => of(new FindProvisionForDamagedOrObsoleteInventoryAccountFail(error)))
        )
    )
  );

  @Effect({ dispatch: false })
  findProvisionForDamagedOrObsoleteInventoryAccountFail$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_PROVISION_FOR_DAMAGED_OR_OBSOLETE_INVENTORY_ACCOUNT_FAIL),
    tap((action: FindProvisionForDamagedOrObsoleteInventoryAccountFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate(
            'FINANCES.SYSTEM_ACCOUNTS.PROVISION_FOR_DAMAGED_OR_OBSOLETE_INVENTORY_ACCOUNT_NOT_FOUND'
          )
        );
      }
    })
  );

  /* ========================= FIND_INVESTMENTS_AVAILABLE_FOR_SALE_OR_HELD_TO_MATURITY_ACCOUNT =================================== */
  @Effect()
  findInvestmentsAvailableForSaleOrHeldToMaturityAccount$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_INVESTMENTS_AVAILABLE_FOR_SALE_OR_HELD_TO_MATURITY_ACCOUNT),
    switchMap((action: FindInvestmentsAvailableForSaleOrHeldToMaturityAccount) =>
      this.systemAccountsService
        .findSystemAccountByKey(SystemAccounts.INVESTMENTS_AVAILABLE_FOR_SALE_OR_HELD_TO_MATURITY)
        .pipe(
          map((response) => new FindInvestmentsAvailableForSaleOrHeldToMaturityAccountSuccess(response)),
          catchError((error) => of(new FindInvestmentsAvailableForSaleOrHeldToMaturityAccountFail(error)))
        )
    )
  );

  @Effect({ dispatch: false })
  findInvestmentsAvailableForSaleOrHeldToMaturityAccountFail$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_INVESTMENTS_AVAILABLE_FOR_SALE_OR_HELD_TO_MATURITY_ACCOUNT_FAIL),
    tap((action: FindInvestmentsAvailableForSaleOrHeldToMaturityAccountFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate(
            'FINANCES.SYSTEM_ACCOUNTS.INVESTMENTS_AVAILABLE_FOR_SALE_OR_HELD_TO_MATURITY_ACCOUNT_NOT_FOUND'
          )
        );
      }
    })
  );

  /* ========================= FIND_LONG_TERM_DEPOSITS_ACCOUNT =================================== */
  @Effect()
  findLongTermDepositsAccount$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_LONG_TERM_DEPOSITS_ACCOUNT),
    switchMap((action: FindLongTermDepositsAccount) =>
      this.systemAccountsService.findSystemAccountByKey(SystemAccounts.LONG_TERM_DEPOSITS).pipe(
        map((response) => new FindLongTermDepositsAccountSuccess(response)),
        catchError((error) => of(new FindLongTermDepositsAccountFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  findLongTermDepositsAccountFail$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_LONG_TERM_DEPOSITS_ACCOUNT_FAIL),
    tap((action: FindLongTermDepositsAccountFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate('FINANCES.SYSTEM_ACCOUNTS.LONG_TERM_DEPOSITS_ACCOUNT_NOT_FOUND')
        );
      }
    })
  );

  /* ========================= FIND_FINANCE_CHARGES_FOR_LEASE_CONTRACTS_NON_CURRENT_ACCOUNT =================================== */
  @Effect()
  findFinanceChargesForLeaseContractsNonCurrentAccount$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_FINANCE_CHARGES_FOR_LEASE_CONTRACTS_NON_CURRENT_ACCOUNT),
    switchMap((action: FindFinanceChargesForLeaseContractsNonCurrentAccount) =>
      this.systemAccountsService
        .findSystemAccountByKey(SystemAccounts.FINANCE_CHARGES_FOR_LEASE_CONTRACTS_NON_CURRENT)
        .pipe(
          map((response) => new FindFinanceChargesForLeaseContractsNonCurrentAccountSuccess(response)),
          catchError((error) => of(new FindFinanceChargesForLeaseContractsNonCurrentAccountFail(error)))
        )
    )
  );

  @Effect({ dispatch: false })
  findFinanceChargesForLeaseContractsNonCurrentAccountFail$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_FINANCE_CHARGES_FOR_LEASE_CONTRACTS_NON_CURRENT_ACCOUNT_FAIL),
    tap((action: FindFinanceChargesForLeaseContractsNonCurrentAccountFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate(
            'FINANCES.SYSTEM_ACCOUNTS.FINANCE_CHARGES_FOR_LEASE_CONTRACTS_NON_CURRENT_ACCOUNT_NOT_FOUND'
          )
        );
      }
    })
  );

  /* ========================= FIND_FINANCING_CHARGES_FOR_LOANS_AND_CREDIT_FACILITIES_NON_CURRENT_ACCOUNT =================================== */
  @Effect()
  findFinancingChargesForLoansAndCreditFacilitiesNonCurrentAccount$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_FINANCING_CHARGES_FOR_LOANS_AND_CREDIT_FACILITIES_NON_CURRENT_ACCOUNT),
    switchMap((action: FindFinancingChargesForLoansAndCreditFacilitiesNonCurrentAccount) =>
      this.systemAccountsService
        .findSystemAccountByKey(SystemAccounts.FINANCING_CHARGES_FOR_LOANS_AND_CREDIT_FACILITIES_NON_CURRENT)
        .pipe(
          map((response) => new FindFinancingChargesForLoansAndCreditFacilitiesNonCurrentAccountSuccess(response)),
          catchError((error) => of(new FindFinancingChargesForLoansAndCreditFacilitiesNonCurrentAccountFail(error)))
        )
    )
  );

  @Effect({ dispatch: false })
  findFinancingChargesForLoansAndCreditFacilitiesNonCurrentAccountFail$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_FINANCING_CHARGES_FOR_LOANS_AND_CREDIT_FACILITIES_NON_CURRENT_ACCOUNT_FAIL),
    tap((action: FindFinancingChargesForLoansAndCreditFacilitiesNonCurrentAccountFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate(
            'FINANCES.SYSTEM_ACCOUNTS.FINANCING_CHARGES_FOR_LOANS_AND_CREDIT_FACILITIES_NON_CURRENT_ACCOUNT_NOT_FOUND'
          )
        );
      }
    })
  );

  /* ========================= FIND_PROJECTS_UNDER_IMPLEMENTATION_ACCOUNT =================================== */
  @Effect()
  findProjectsUnderImplementationAccount$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_PROJECTS_UNDER_IMPLEMENTATION_ACCOUNT),
    switchMap((action: FindProjectsUnderImplementationAccount) =>
      this.systemAccountsService.findSystemAccountByKey(SystemAccounts.PROJECTS_UNDER_IMPLEMENTATION).pipe(
        map((response) => new FindProjectsUnderImplementationAccountSuccess(response)),
        catchError((error) => of(new FindProjectsUnderImplementationAccountFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  findProjectsUnderImplementationAccountFail$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_PROJECTS_UNDER_IMPLEMENTATION_ACCOUNT_FAIL),
    tap((action: FindProjectsUnderImplementationAccountFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate('FINANCES.SYSTEM_ACCOUNTS.PROJECTS_UNDER_IMPLEMENTATION_ACCOUNT_NOT_FOUND')
        );
      }
    })
  );

  /* ========================= FIND_LANDS_ACCOUNT =================================== */
  @Effect()
  findLandsAccount$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_LANDS_ACCOUNT),
    switchMap((action: FindLandsAccount) =>
      this.systemAccountsService.findSystemAccountByKey(SystemAccounts.LANDS).pipe(
        map((response) => new FindLandsAccountSuccess(response)),
        catchError((error) => of(new FindLandsAccountFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  findLandsAccountFail$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_LANDS_ACCOUNT_FAIL),
    tap((action: FindLandsAccountFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate('FINANCES.SYSTEM_ACCOUNTS.LANDS_ACCOUNT_NOT_FOUND')
        );
      }
    })
  );

  /* ========================= FIND_BUILDINGS_ACCOUNT =================================== */
  @Effect()
  findBuildingsAccount$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_BUILDINGS_ACCOUNT),
    switchMap((action: FindBuildingsAccount) =>
      this.systemAccountsService.findSystemAccountByKey(SystemAccounts.BUILDINGS).pipe(
        map((response) => new FindBuildingsAccountSuccess(response)),
        catchError((error) => of(new FindBuildingsAccountFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  findBuildingsAccountFail$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_BUILDINGS_ACCOUNT_FAIL),
    tap((action: FindBuildingsAccountFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate('FINANCES.SYSTEM_ACCOUNTS.BUILDINGS_ACCOUNT_NOT_FOUND')
        );
      }
    })
  );

  /* ========================= FIND_FURNITURE_ACCOUNT =================================== */
  @Effect()
  findFurnitureAccount$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_FURNITURE_ACCOUNT),
    switchMap((action: FindFurnitureAccount) =>
      this.systemAccountsService.findSystemAccountByKey(SystemAccounts.FURNITURE).pipe(
        map((response) => new FindFurnitureAccountSuccess(response)),
        catchError((error) => of(new FindFurnitureAccountFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  findFurnitureAccountFail$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_FURNITURE_ACCOUNT_FAIL),
    tap((action: FindCarsAccountFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate('FINANCES.SYSTEM_ACCOUNTS.FURNITURE_ACCOUNT_NOT_FOUND')
        );
      }
    })
  );

  /* ========================= FIND_DEVICES_ACCOUNT =================================== */
  @Effect()
  findDevicesAccount$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_DEVICES_ACCOUNT),
    switchMap((action: FindDevicesAccount) =>
      this.systemAccountsService.findSystemAccountByKey(SystemAccounts.DEVICES).pipe(
        map((response) => new FindDevicesAccountSuccess(response)),
        catchError((error) => of(new FindDevicesAccountFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  findDevicesAccountFail$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_DEVICES_ACCOUNT_FAIL),
    tap((action: FindDevicesAccountFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate('FINANCES.SYSTEM_ACCOUNTS.DEVICES_ACCOUNT_NOT_FOUND')
        );
      }
    })
  );

  /* ========================= FIND_COMPUTERS_ACCOUNT =================================== */
  @Effect()
  findComputersAccount$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_COMPUTERS_ACCOUNT),
    switchMap((action: FindComputersAccount) =>
      this.systemAccountsService.findSystemAccountByKey(SystemAccounts.CASH_IN_HAND).pipe(
        map((response) => new FindComputersAccountSuccess(response)),
        catchError((error) => of(new FindComputersAccountFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  findComputersAccountFail$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_COMPUTERS_ACCOUNT_FAIL),
    tap((action: FindComputersAccountFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate('FINANCES.SYSTEM_ACCOUNTS.COMPUTERS_ACCOUNT_NOT_FOUND')
        );
      }
    })
  );

  /* ========================= FIND_EQUIPMENTS_ACCOUNT =================================== */
  @Effect()
  findEquipmentsAccount$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_EQUIPMENTS_ACCOUNT),
    switchMap((action: FindEquipmentsAccount) =>
      this.systemAccountsService.findSystemAccountByKey(SystemAccounts.EQUIPMENTS).pipe(
        map((response) => new FindEquipmentsAccountSuccess(response)),
        catchError((error) => of(new FindEquipmentsAccountFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  findEquipmentsAccountFail$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_EQUIPMENTS_ACCOUNT_FAIL),
    tap((action: FindEquipmentsAccountFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate('FINANCES.SYSTEM_ACCOUNTS.EQUIPMENTS_ACCOUNT_NOT_FOUND')
        );
      }
    })
  );

  /* ========================= FIND_CARS_ACCOUNT =================================== */
  @Effect()
  findCarsAccount$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_CARS_ACCOUNT),
    switchMap((action: FindCarsAccount) =>
      this.systemAccountsService.findSystemAccountByKey(SystemAccounts.CARS).pipe(
        map((response) => new FindCarsAccountSuccess(response)),
        catchError((error) => of(new FindCarsAccountFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  findCarsAccountFail$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_CARS_ACCOUNT_FAIL),
    tap((action: FindCarsAccountFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate('FINANCES.SYSTEM_ACCOUNTS.CARS_ACCOUNT_NOT_FOUND')
        );
      }
    })
  );

  /* ========================= FIND_ASSETS_UNDER_FINANCE_LEASES_ACCOUNT =================================== */
  @Effect()
  findAssetsUnderFinanceLeasesAccount$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_ASSETS_UNDER_FINANCE_LEASES_ACCOUNT),
    switchMap((action: FindAssetsUnderFinanceLeasesAccount) =>
      this.systemAccountsService.findSystemAccountByKey(SystemAccounts.ASSETS_UNDER_FINANCE_LEASES).pipe(
        map((response) => new FindAssetsUnderFinanceLeasesAccountSuccess(response)),
        catchError((error) => of(new FindAssetsUnderFinanceLeasesAccountFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  findAssetsUnderFinanceLeasesAccountFail$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_ASSETS_UNDER_FINANCE_LEASES_ACCOUNT_FAIL),
    tap((action: FindAssetsUnderFinanceLeasesAccountFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate('FINANCES.SYSTEM_ACCOUNTS.ASSETS_UNDER_FINANCE_LEASES_ACCOUNT_NOT_FOUND')
        );
      }
    })
  );

  /* ========================= FIND_BUILDING_CONSUMPTION_COMPLEX_ACCOUNT =================================== */
  @Effect()
  findBuildingConsumptionComplexAccount$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_BUILDING_CONSUMPTION_COMPLEX_ACCOUNT),
    switchMap((action: FindBuildingConsumptionComplexAccount) =>
      this.systemAccountsService.findSystemAccountByKey(SystemAccounts.BUILDING_CONSUMPTION_COMPLEX).pipe(
        map((response) => new FindBuildingConsumptionComplexAccountSuccess(response)),
        catchError((error) => of(new FindBuildingConsumptionComplexAccountFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  findBuildingConsumptionComplexAccountFail$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_BUILDING_CONSUMPTION_COMPLEX_ACCOUNT_FAIL),
    tap((action: FindBuildingConsumptionComplexAccountFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate('FINANCES.SYSTEM_ACCOUNTS.BUILDING_CONSUMPTION_COMPLEX_ACCOUNT_NOT_FOUND')
        );
      }
    })
  );

  /* ========================= FIND_FURNITURE_CONSUMPTION_COMPLEX_ACCOUNT =================================== */
  @Effect()
  findFurnitureConsumptionComplexAccount$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_FURNITURE_CONSUMPTION_COMPLEX_ACCOUNT),
    switchMap((action: FindFurnitureConsumptionComplexAccount) =>
      this.systemAccountsService.findSystemAccountByKey(SystemAccounts.FURNITURE_CONSUMPTION_COMPLEX).pipe(
        map((response) => new FindFurnitureConsumptionComplexAccountSuccess(response)),
        catchError((error) => of(new FindFurnitureConsumptionComplexAccountFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  findFurnitureConsumptionComplexAccountFail$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_FURNITURE_CONSUMPTION_COMPLEX_ACCOUNT_FAIL),
    tap((action: FindFurnitureConsumptionComplexAccountFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate('FINANCES.SYSTEM_ACCOUNTS.FURNITURE_CONSUMPTION_COMPLEX_ACCOUNT_NOT_FOUND')
        );
      }
    })
  );

  /* ========================= FIND_DEVICES_CONSUMPTION_COMPLEX_ACCOUNT =================================== */
  @Effect()
  findDevicesConsumptionComplexAccount$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_DEVICES_CONSUMPTION_COMPLEX_ACCOUNT),
    switchMap((action: FindDevicesConsumptionComplexAccount) =>
      this.systemAccountsService.findSystemAccountByKey(SystemAccounts.DEVICES_CONSUMPTION_COMPLEX).pipe(
        map((response) => new FindDevicesConsumptionComplexAccountSuccess(response)),
        catchError((error) => of(new FindDevicesConsumptionComplexAccountFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  findDevicesConsumptionComplexAccountFail$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_DEVICES_CONSUMPTION_COMPLEX_ACCOUNT_FAIL),
    tap((action: FindDevicesConsumptionComplexAccountFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate('FINANCES.SYSTEM_ACCOUNTS.DEVICES_CONSUMPTION_COMPLEX_ACCOUNT_NOT_FOUND')
        );
      }
    })
  );

  /* ========================= FIND_COMPUTERS_CONSUMPTION_COMPLEX_ACCOUNT =================================== */
  @Effect()
  findComputersConsumptionComplexAccount$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_COMPUTERS_CONSUMPTION_COMPLEX_ACCOUNT),
    switchMap((action: FindComputersConsumptionComplexAccount) =>
      this.systemAccountsService.findSystemAccountByKey(SystemAccounts.COMPUTERS_CONSUMPTION_COMPLEX).pipe(
        map((response) => new FindComputersConsumptionComplexAccountSuccess(response)),
        catchError((error) => of(new FindComputersConsumptionComplexAccountFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  findComputersConsumptionComplexAccountFail$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_COMPUTERS_CONSUMPTION_COMPLEX_ACCOUNT_FAIL),
    tap((action: FindComputersConsumptionComplexAccountFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate('FINANCES.SYSTEM_ACCOUNTS.COMPUTERS_CONSUMPTION_COMPLEX_ACCOUNT_NOT_FOUND')
        );
      }
    })
  );

  /* ========================= FIND_EQUIPMENTS_CONSUMPTION_COMPLEX_ACCOUNT =================================== */
  @Effect()
  findEquipmentsConsumptionComplexAccount$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_EQUIPMENTS_CONSUMPTION_COMPLEX_ACCOUNT),
    switchMap((action: FindEquipmentsConsumptionComplexAccount) =>
      this.systemAccountsService.findSystemAccountByKey(SystemAccounts.EQUIPMENTS_CONSUMPTION_COMPLEX).pipe(
        map((response) => new FindEquipmentsConsumptionComplexAccountSuccess(response)),
        catchError((error) => of(new FindEquipmentsConsumptionComplexAccountFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  findEquipmentsConsumptionComplexAccountFail$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_EQUIPMENTS_CONSUMPTION_COMPLEX_ACCOUNT_FAIL),
    tap((action: FindEquipmentsConsumptionComplexAccountFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate('FINANCES.SYSTEM_ACCOUNTS.EQUIPMENTS_CONSUMPTION_COMPLEX_ACCOUNT_NOT_FOUND')
        );
      }
    })
  );

  /* ========================= FIND_CARS_CONSUMPTION_COMPLEX_ACCOUNT =================================== */
  @Effect()
  findCarsConsumptionComplexAccount$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_CARS_CONSUMPTION_COMPLEX_ACCOUNT),
    switchMap((action: FindCarsConsumptionComplexAccount) =>
      this.systemAccountsService.findSystemAccountByKey(SystemAccounts.CARS_CONSUMPTION_COMPLEX).pipe(
        map((response) => new FindCarsConsumptionComplexAccountSuccess(response)),
        catchError((error) => of(new FindCarsConsumptionComplexAccountFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  findCarsConsumptionComplexAccountFail$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_CARS_CONSUMPTION_COMPLEX_ACCOUNT_FAIL),
    tap((action: FindCarsConsumptionComplexAccountFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate('FINANCES.SYSTEM_ACCOUNTS.CARS_CONSUMPTION_COMPLEX_ACCOUNT_NOT_FOUND')
        );
      }
    })
  );

  /* ========================= FIND_POOLED_DEPRECIATION_OF_ASSETS_UNDER_FINANCE_LEASE_CONTRACTS_ACCOUNT =================================== */
  @Effect()
  findPooledDepreciationOfAssetsUnderFinanceLeaseContractsAccount$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_POOLED_DEPRECIATION_OF_ASSETS_UNDER_FINANCE_LEASE_CONTRACTS_ACCOUNT),
    switchMap((action: FindPooledDepreciationOfAssetsUnderFinanceLeaseContractsAccount) =>
      this.systemAccountsService
        .findSystemAccountByKey(SystemAccounts.POOLED_DEPRECIATION_OF_ASSETS_UNDER_FINANCE_LEASE_CONTRACTS)
        .pipe(
          map((response) => new FindPooledDepreciationOfAssetsUnderFinanceLeaseContractsAccountSuccess(response)),
          catchError((error) => of(new FindPooledDepreciationOfAssetsUnderFinanceLeaseContractsAccountFail(error)))
        )
    )
  );

  @Effect({ dispatch: false })
  findPooledDepreciationOfAssetsUnderFinanceLeaseContractsAccountFail$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_POOLED_DEPRECIATION_OF_ASSETS_UNDER_FINANCE_LEASE_CONTRACTS_ACCOUNT_FAIL),
    tap((action: FindPooledDepreciationOfAssetsUnderFinanceLeaseContractsAccountFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate(
            'FINANCES.SYSTEM_ACCOUNTS.POOLED_DEPRECIATION_OF_ASSETS_UNDER_FINANCE_LEASE_CONTRACTS_ACCOUNT_NOT_FOUND'
          )
        );
      }
    })
  );

  /* ========================= FIND_FAME_ACCOUNT =================================== */
  @Effect()
  findFameAccount$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_FAME_ACCOUNT),
    switchMap((action: FindFameAccount) =>
      this.systemAccountsService.findSystemAccountByKey(SystemAccounts.FAME).pipe(
        map((response) => new FindFameAccountSuccess(response)),
        catchError((error) => of(new FindFameAccountFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  findFameAccountFail$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_FAME_ACCOUNT_FAIL),
    tap((action: FindFameAccountFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate('FINANCES.SYSTEM_ACCOUNTS.FAME_ACCOUNT_NOT_FOUND')
        );
      }
    })
  );

  /* ========================= FIND_SOFTWARE_AND_SYSTEMS_ACCOUNT =================================== */
  @Effect()
  findSoftwareAndSystemsAccount$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_SOFTWARE_AND_SYSTEMS_ACCOUNT),
    switchMap((action: FindSoftwareAndSystemsAccount) =>
      this.systemAccountsService.findSystemAccountByKey(SystemAccounts.SOFTWARE_AND_SYSTEMS).pipe(
        map((response) => new FindSoftwareAndSystemsAccountSuccess(response)),
        catchError((error) => of(new FindSoftwareAndSystemsAccountFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  findSoftwareAndSystemsAccountFail$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_SOFTWARE_AND_SYSTEMS_ACCOUNT_FAIL),
    tap((action: FindSoftwareAndSystemsAccountFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate('FINANCES.SYSTEM_ACCOUNTS.SOFTWARE_AND_SYSTEMS_ACCOUNT_NOT_FOUND')
        );
      }
    })
  );

  /* ========================= FIND_PATENTS_AND_TRADEMARKS_ACCOUNT =================================== */
  @Effect()
  findPatentsAndTrademarksAccount$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_PATENTS_AND_TRADEMARKS_ACCOUNT),
    switchMap((action: FindPatentsAndTrademarksAccount) =>
      this.systemAccountsService.findSystemAccountByKey(SystemAccounts.PATENTS_AND_TRADEMARKS).pipe(
        map((response) => new FindPatentsAndTrademarksAccountSuccess(response)),
        catchError((error) => of(new FindPatentsAndTrademarksAccountFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  findPatentsAndTrademarksAccountFail$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_PATENTS_AND_TRADEMARKS_ACCOUNT_FAIL),
    tap((action: FindPatentsAndTrademarksAccountFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate('FINANCES.SYSTEM_ACCOUNTS.PATENTS_AND_TRADEMARKS_ACCOUNT_NOT_FOUND')
        );
      }
    })
  );

  /* ========================= FIND_FAME_EXTINGUISHING_COMPLEX_ACCOUNT =================================== */
  @Effect()
  findFameExtinguishingComplexAccount$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_FAME_EXTINGUISHING_COMPLEX_ACCOUNT),
    switchMap((action: FindFameExtinguishingComplexAccount) =>
      this.systemAccountsService.findSystemAccountByKey(SystemAccounts.FAME_EXTINGUISHING_COMPLEX).pipe(
        map((response) => new FindFameExtinguishingComplexAccountSuccess(response)),
        catchError((error) => of(new FindFameExtinguishingComplexAccountFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  findFameExtinguishingComplexAccountFail$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_FAME_EXTINGUISHING_COMPLEX_ACCOUNT_FAIL),
    tap((action: FindFameExtinguishingComplexAccountFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate('FINANCES.SYSTEM_ACCOUNTS.FAME_EXTINGUISHING_COMPLEX_ACCOUNT_NOT_FOUND')
        );
      }
    })
  );

  /* ========================= FIND_SOFTWARE_AND_HARDWARE_EXTINGUISHING_COMPLEX_ACCOUNT =================================== */
  @Effect()
  findSoftwareAndHardwareExtinguishingComplexAccount$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_SOFTWARE_AND_HARDWARE_EXTINGUISHING_COMPLEX_ACCOUNT),
    switchMap((action: FindSoftwareAndHardwareExtinguishingComplexAccount) =>
      this.systemAccountsService
        .findSystemAccountByKey(SystemAccounts.SOFTWARE_AND_HARDWARE_EXTINGUISHING_COMPLEX)
        .pipe(
          map((response) => new FindSoftwareAndHardwareExtinguishingComplexAccountSuccess(response)),
          catchError((error) => of(new FindSoftwareAndHardwareExtinguishingComplexAccountFail(error)))
        )
    )
  );

  @Effect({ dispatch: false })
  findSoftwareAndHardwareExtinguishingComplexAccountFail$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_SOFTWARE_AND_HARDWARE_EXTINGUISHING_COMPLEX_ACCOUNT_FAIL),
    tap((action: FindSoftwareAndHardwareExtinguishingComplexAccountFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate(
            'FINANCES.SYSTEM_ACCOUNTS.SOFTWARE_AND_HARDWARE_EXTINGUISHING_COMPLEX_ACCOUNT_NOT_FOUND'
          )
        );
      }
    })
  );

  /* ========================= FIND_PATENT_AND_TRADEMARK_EXTINGUISHING_COMPLEX_ACCOUNT =================================== */
  @Effect()
  findPatentAndTrademarkExtinguishingComplexAccount$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_PATENT_AND_TRADEMARK_EXTINGUISHING_COMPLEX_ACCOUNT),
    switchMap((action: FindPatentAndTrademarkExtinguishingComplexAccount) =>
      this.systemAccountsService.findSystemAccountByKey(SystemAccounts.PATENT_AND_TRADEMARK_EXTINGUISHING_COMPLEX).pipe(
        map((response) => new FindPatentAndTrademarkExtinguishingComplexAccountSuccess(response)),
        catchError((error) => of(new FindPatentAndTrademarkExtinguishingComplexAccountFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  findPatentAndTrademarkExtinguishingComplexAccountFail$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_PATENT_AND_TRADEMARK_EXTINGUISHING_COMPLEX_ACCOUNT_FAIL),
    tap((action: FindPatentAndTrademarkExtinguishingComplexAccountFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate(
            'FINANCES.SYSTEM_ACCOUNTS.PATENT_AND_TRADEMARK_EXTINGUISHING_COMPLEX_ACCOUNT_NOT_FOUND'
          )
        );
      }
    })
  );

  /* ========================= FIND_REAL_ESTATE_INVESTMENT_LANDS_ACCOUNT =================================== */
  @Effect()
  findRealEstateInvestmentLandsAccount$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_REAL_ESTATE_INVESTMENT_LANDS_ACCOUNT),
    switchMap((action: FindRealEstateInvestmentLandsAccount) =>
      this.systemAccountsService.findSystemAccountByKey(SystemAccounts.REAL_ESTATE_INVESTMENT_LANDS).pipe(
        map((response) => new FindRealEstateInvestmentLandsAccountSuccess(response)),
        catchError((error) => of(new FindRealEstateInvestmentLandsAccountFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  findRealEstateInvestmentLandsAccountFail$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_REAL_ESTATE_INVESTMENT_LANDS_ACCOUNT_FAIL),
    tap((action: FindRealEstateInvestmentLandsAccountFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate('FINANCES.SYSTEM_ACCOUNTS.REAL_ESTATE_INVESTMENT_LANDS_ACCOUNT_NOT_FOUND')
        );
      }
    })
  );

  /* ========================= FIND_REAL_ESTATE_INVESTMENT_BUILDINGS_ACCOUNT =================================== */
  @Effect()
  findRealEstateInvestmentBuildingsAccount$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_REAL_ESTATE_INVESTMENT_BUILDINGS_ACCOUNT),
    switchMap((action: FindRealEstateInvestmentBuildingsAccount) =>
      this.systemAccountsService.findSystemAccountByKey(SystemAccounts.REAL_ESTATE_INVESTMENT_BUILDINGS).pipe(
        map((response) => new FindRealEstateInvestmentBuildingsAccountSuccess(response)),
        catchError((error) => of(new FindRealEstateInvestmentBuildingsAccountFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  findRealEstateInvestmentBuildingsAccountFail$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_REAL_ESTATE_INVESTMENT_BUILDINGS_ACCOUNT_FAIL),
    tap((action: FindRealEstateInvestmentBuildingsAccountFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate(
            'FINANCES.SYSTEM_ACCOUNTS.REAL_ESTATE_INVESTMENT_BUILDINGS_ACCOUNT_NOT_FOUND'
          )
        );
      }
    })
  );

  /* ========================= FIND_REAL_ESTATE_INVESTMENT_BUILDING_CONSUMPTION_COMPLEX_ACCOUNT =================================== */
  @Effect()
  findRealEstateInvestmentBuildingConsumptionComplexAccount$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_REAL_ESTATE_INVESTMENT_BUILDING_CONSUMPTION_COMPLEX_ACCOUNT),
    switchMap((action: FindRealEstateInvestmentBuildingConsumptionComplexAccount) =>
      this.systemAccountsService
        .findSystemAccountByKey(SystemAccounts.REAL_ESTATE_INVESTMENT_BUILDING_CONSUMPTION_COMPLEX)
        .pipe(
          map((response) => new FindRealEstateInvestmentBuildingConsumptionComplexAccountSuccess(response)),
          catchError((error) => of(new FindRealEstateInvestmentBuildingConsumptionComplexAccountFail(error)))
        )
    )
  );

  @Effect({ dispatch: false })
  findRealEstateInvestmentBuildingConsumptionComplexAccountFail$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_REAL_ESTATE_INVESTMENT_BUILDING_CONSUMPTION_COMPLEX_ACCOUNT_FAIL),
    tap((action: FindRealEstateInvestmentBuildingConsumptionComplexAccountFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate(
            'FINANCES.SYSTEM_ACCOUNTS.REAL_ESTATE_INVESTMENT_BUILDING_CONSUMPTION_COMPLEX_ACCOUNT_NOT_FOUND'
          )
        );
      }
    })
  );

  /* ========================= FIND_INVESTMENTS_IN_SUBSIDIARIES_ACCOUNT =================================== */
  @Effect()
  findInvestmentsInSubsidiariesAccount$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_INVESTMENTS_IN_SUBSIDIARIES_ACCOUNT),
    switchMap((action: FindInvestmentsInSubsidiariesAccount) =>
      this.systemAccountsService.findSystemAccountByKey(SystemAccounts.INVESTMENTS_IN_SUBSIDIARIES).pipe(
        map((response) => new FindInvestmentsInSubsidiariesAccountSuccess(response)),
        catchError((error) => of(new FindInvestmentsInSubsidiariesAccountFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  findInvestmentsInSubsidiariesAccountFail$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_INVESTMENTS_IN_SUBSIDIARIES_ACCOUNT_FAIL),
    tap((action: FindInvestmentsInSubsidiariesAccountFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate('FINANCES.SYSTEM_ACCOUNTS.INVESTMENTS_IN_SUBSIDIARIES_ACCOUNT_NOT_FOUND')
        );
      }
    })
  );

  /* ========================= FIND_INVESTMENTS_IN_ASSOCIATES_ACCOUNT =================================== */
  @Effect()
  findInvestmentsInAssociatesAccount$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_INVESTMENTS_IN_ASSOCIATES_ACCOUNT),
    switchMap((action: FindInvestmentsInAssociatesAccount) =>
      this.systemAccountsService.findSystemAccountByKey(SystemAccounts.INVESTMENTS_IN_ASSOCIATES).pipe(
        map((response) => new FindInvestmentsInAssociatesAccountSuccess(response)),
        catchError((error) => of(new FindInvestmentsInAssociatesAccountFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  findInvestmentsInAssociatesAccountFail$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_INVESTMENTS_IN_ASSOCIATES_ACCOUNT_FAIL),
    tap((action: FindInvestmentsInAssociatesAccountFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate('FINANCES.SYSTEM_ACCOUNTS.INVESTMENTS_IN_ASSOCIATES_ACCOUNT_NOT_FOUND')
        );
      }
    })
  );

  /* ========================= FIND_INVESTMENTS_IN_JOINT_VENTURES_ACCOUNT =================================== */
  @Effect()
  findInvestmentsInJointVenturesAccount$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_INVESTMENTS_IN_JOINT_VENTURES_ACCOUNT),
    switchMap((action: FindInvestmentsInJointVenturesAccount) =>
      this.systemAccountsService.findSystemAccountByKey(SystemAccounts.INVESTMENTS_IN_JOINT_VENTURES).pipe(
        map((response) => new FindInvestmentsInJointVenturesAccountSuccess(response)),
        catchError((error) => of(new FindInvestmentsInJointVenturesAccountFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  findInvestmentsInJointVenturesAccountFail$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_INVESTMENTS_IN_JOINT_VENTURES_ACCOUNT_FAIL),
    tap((action: FindInvestmentsInJointVenturesAccountFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate('FINANCES.SYSTEM_ACCOUNTS.INVESTMENTS_IN_JOINT_VENTURES_ACCOUNT_NOT_FOUND')
        );
      }
    })
  );

  /* ========================= FIND_LOAN_INSTALLMENTS_THE_CURRENT_PART_ACCOUNT =================================== */
  @Effect()
  findLoanInstallmentsTheCurrentPartAccount$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_LOAN_INSTALLMENTS_THE_CURRENT_PART_ACCOUNT),
    switchMap((action: FindLoanInstallmentsTheCurrentPartAccount) =>
      this.systemAccountsService.findSystemAccountByKey(SystemAccounts.LOAN_INSTALLMENTS_THE_CURRENT_PART).pipe(
        map((response) => new FindLoanInstallmentsTheCurrentPartAccountSuccess(response)),
        catchError((error) => of(new FindLoanInstallmentsTheCurrentPartAccountFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  findLoanInstallmentsTheCurrentPartAccountFail$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_LOAN_INSTALLMENTS_THE_CURRENT_PART_ACCOUNT_FAIL),
    tap((action: FindLoanInstallmentsTheCurrentPartAccountFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate(
            'FINANCES.SYSTEM_ACCOUNTS.LOAN_INSTALLMENTS_THE_CURRENT_PART_ACCOUNT_NOT_FOUND'
          )
        );
      }
    })
  );

  /* ========================= FIND_CURRENT_CONTRACTS_FOR_RENT_THE_CURRENT_PART_ACCOUNT =================================== */
  @Effect()
  findCurrentContractsForRentTheCurrentPartAccount$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_CURRENT_CONTRACTS_FOR_RENT_THE_CURRENT_PART_ACCOUNT),
    switchMap((action: FindCurrentContractsForRentTheCurrentPartAccount) =>
      this.systemAccountsService
        .findSystemAccountByKey(SystemAccounts.CURRENT_CONTRACTS_FOR_RENT_THE_CURRENT_PART)
        .pipe(
          map((response) => new FindCurrentContractsForRentTheCurrentPartAccountSuccess(response)),
          catchError((error) => of(new FindCurrentContractsForRentTheCurrentPartAccountFail(error)))
        )
    )
  );

  @Effect({ dispatch: false })
  findCurrentContractsForRentTheCurrentPartAccountFail$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_CURRENT_CONTRACTS_FOR_RENT_THE_CURRENT_PART_ACCOUNT_FAIL),
    tap((action: FindCurrentContractsForRentTheCurrentPartAccountFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate(
            'FINANCES.SYSTEM_ACCOUNTS.CURRENT_CONTRACTS_FOR_RENT_THE_CURRENT_PART_ACCOUNT_NOT_FOUND'
          )
        );
      }
    })
  );

  /* ========================= FIND_TRADE_CREDITORS_ACCOUNT =================================== */
  @Effect()
  findTradeCreditorsAccount$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_TRADE_CREDITORS_ACCOUNT),
    switchMap((action: FindTradeCreditorsAccount) =>
      this.systemAccountsService.findSystemAccountByKey(SystemAccounts.TRADE_CREDITORS).pipe(
        map((response) => new FindTradeCreditorsAccountSuccess(response)),
        catchError((error) => of(new FindTradeCreditorsAccountFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  findTradeCreditorsAccountFail$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_TRADE_CREDITORS_ACCOUNT_FAIL),
    tap((action: FindTradeCreditorsAccountFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate('FINANCES.SYSTEM_ACCOUNTS.TRADE_CREDITORS_ACCOUNT_NOT_FOUND')
        );
      }
    })
  );

  /* ========================= FIND_VENDORS_BROKER_ACCOUNT =================================== */
  @Effect()
  findVendorsBrokerAccount$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_VENDORS_BROKER_ACCOUNT),
    switchMap((action: FindVendorsBrokerAccount) =>
      this.systemAccountsService.findSystemAccountByKey(SystemAccounts.VENDORS_BROKER).pipe(
        map((response) => new FindVendorsBrokerAccountSuccess(response)),
        catchError((error) => of(new FindVendorsBrokerAccountFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  findVendorsBrokerAccountFail$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_VENDORS_BROKER_ACCOUNT_FAIL),
    tap((action: FindVendorsBrokerAccountFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate('FINANCES.SYSTEM_ACCOUNTS.VENDORS_BROKER_ACCOUNT_NOT_FOUND')
        );
      }
    })
  );

  /* ========================= FIND_CREDIT_FACILITIES_OVERDRAFT_ACCOUNT =================================== */
  @Effect()
  findCreditFacilitiesOverdraftAccount$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_CREDIT_FACILITIES_OVERDRAFT_ACCOUNT),
    switchMap((action: FindCreditFacilitiesOverdraftAccount) =>
      this.systemAccountsService.findSystemAccountByKey(SystemAccounts.CREDIT_FACILITIES_OVERDRAFT).pipe(
        map((response) => new FindCreditFacilitiesOverdraftAccountSuccess(response)),
        catchError((error) => of(new FindCreditFacilitiesOverdraftAccountFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  findCreditFacilitiesOverdraftAccountFail$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_CREDIT_FACILITIES_OVERDRAFT_ACCOUNT_FAIL),
    tap((action: FindCreditFacilitiesOverdraftAccountFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate('FINANCES.SYSTEM_ACCOUNTS.CREDIT_FACILITIES_OVERDRAFT_ACCOUNT_NOT_FOUND')
        );
      }
    })
  );

  /* ========================= FIND_CREDITORS_WITHHOLDING_BALANCES_ACCOUNT =================================== */
  @Effect()
  findCreditorsWithholdingBalancesAccount$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_CREDITORS_WITHHOLDING_BALANCES_ACCOUNT),
    switchMap((action: FindCreditorsWithholdingBalancesAccount) =>
      this.systemAccountsService.findSystemAccountByKey(SystemAccounts.CREDITORS_WITHHOLDING_BALANCES).pipe(
        map((response) => new FindCreditorsWithholdingBalancesAccountSuccess(response)),
        catchError((error) => of(new FindCreditorsWithholdingBalancesAccountFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  findCreditorsWithholdingBalancesAccountFail$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_CREDITORS_WITHHOLDING_BALANCES_ACCOUNT_FAIL),
    tap((action: FindCreditorsWithholdingBalancesAccountFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate('FINANCES.SYSTEM_ACCOUNTS.CREDITORS_WITHHOLDING_BALANCES_ACCOUNT_NOT_FOUND')
        );
      }
    })
  );

  /* ========================= FIND_PAYABLE_EXPENSES_ACCOUNT =================================== */
  @Effect()
  findPayableExpensesAccount$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_PAYABLE_EXPENSES_ACCOUNT),
    switchMap((action: FindPayableExpensesAccount) =>
      this.systemAccountsService.findSystemAccountByKey(SystemAccounts.PAYABLE_EXPENSES).pipe(
        map((response) => new FindPayableExpensesAccountSuccess(response)),
        catchError((error) => of(new FindPayableExpensesAccountFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  findPayableExpensesAccountFail$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_PAYABLE_EXPENSES_ACCOUNT_FAIL),
    tap((action: FindPayableExpensesAccountFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate('FINANCES.SYSTEM_ACCOUNTS.PAYABLE_EXPENSES_ACCOUNT_NOT_FOUND')
        );
      }
    })
  );

  /* ========================= FIND_EMPLOYEES_ENTITLEMENTS_SALARIES_ACCOUNT =================================== */
  @Effect()
  findEmployeesEntitlementsSalariesAccount$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_EMPLOYEES_ENTITLEMENTS_SALARIES_ACCOUNT),
    switchMap((action: FindEmployeesEntitlementsSalariesAccount) =>
      this.systemAccountsService.findSystemAccountByKey(SystemAccounts.EMPLOYEES_ENTITLEMENTS_SALARIES).pipe(
        map((response) => new FindEmployeesEntitlementsSalariesAccountSuccess(response)),
        catchError((error) => of(new FindEmployeesEntitlementsSalariesAccountFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  findEmployeesEntitlementsSalariesAccountFail$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_EMPLOYEES_ENTITLEMENTS_SALARIES_ACCOUNT_FAIL),
    tap((action: FindEmployeesEntitlementsSalariesAccountFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate(
            'FINANCES.SYSTEM_ACCOUNTS.EMPLOYEES_ENTITLEMENTS_SALARIES_ACCOUNT_NOT_FOUND'
          )
        );
      }
    })
  );

  /* ========================= FIND_EMPLOYEES_ENTITLEMENTS_BENEFITS_ACCOUNT =================================== */
  @Effect()
  findEmployeesEntitlementsBenefitsAccount$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_EMPLOYEES_ENTITLEMENTS_BENEFITS_ACCOUNT),
    switchMap((action: FindEmployeesEntitlementsBenefitsAccount) =>
      this.systemAccountsService.findSystemAccountByKey(SystemAccounts.EMPLOYEES_ENTITLEMENTS_BENEFITS).pipe(
        map((response) => new FindEmployeesEntitlementsBenefitsAccountSuccess(response)),
        catchError((error) => of(new FindEmployeesEntitlementsBenefitsAccountFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  findEmployeesEntitlementsBenefitsAccountFail$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_EMPLOYEES_ENTITLEMENTS_BENEFITS_ACCOUNT_FAIL),
    tap((action: FindEmployeesEntitlementsBenefitsAccountFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate(
            'FINANCES.SYSTEM_ACCOUNTS.EMPLOYEES_ENTITLEMENTS_BENEFITS_ACCOUNT_NOT_FOUND'
          )
        );
      }
    })
  );

  /* ========================= FIND_EMPLOYEES_ENTITLEMENTS_TRAVELING_TICKETS_ACCOUNT =================================== */
  @Effect()
  findEmployeesEntitlementsTravelingTicketsAccount$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_EMPLOYEES_ENTITLEMENTS_TRAVELING_TICKETS_ACCOUNT),
    switchMap((action: FindEmployeesEntitlementsTravelingTicketsAccount) =>
      this.systemAccountsService.findSystemAccountByKey(SystemAccounts.EMPLOYEES_ENTITLEMENTS_TRAVELING_TICKETS).pipe(
        map((response) => new FindEmployeesEntitlementsTravelingTicketsAccountSuccess(response)),
        catchError((error) => of(new FindEmployeesEntitlementsTravelingTicketsAccountFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  findEmployeesEntitlementsTravelingTicketsAccountFail$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_EMPLOYEES_ENTITLEMENTS_TRAVELING_TICKETS_ACCOUNT_FAIL),
    tap((action: FindEmployeesEntitlementsTravelingTicketsAccountFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate(
            'FINANCES.SYSTEM_ACCOUNTS.EMPLOYEES_ENTITLEMENTS_TRAVELING_TICKETS_ACCOUNT_NOT_FOUND'
          )
        );
      }
    })
  );

  /* ========================= FIND_ADVANCE_REVENUE_ACCOUNT =================================== */
  @Effect()
  findAdvanceRevenueAccount$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_ADVANCE_REVENUE_ACCOUNT),
    switchMap((action: FindAdvanceRevenueAccount) =>
      this.systemAccountsService.findSystemAccountByKey(SystemAccounts.ADVANCE_REVENUE).pipe(
        map((response) => new FindAdvanceRevenueAccountSuccess(response)),
        catchError((error) => of(new FindAdvanceRevenueAccountFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  findAdvanceRevenueAccountFail$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_ADVANCE_REVENUE_ACCOUNT_FAIL),
    tap((action: FindAdvanceRevenueAccountFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate('FINANCES.SYSTEM_ACCOUNTS.ADVANCE_REVENUE_ACCOUNT_NOT_FOUND')
        );
      }
    })
  );

  /* ========================= FIND_VALUE_ADDED_TAX_CREDIT_ACCOUNT =================================== */
  @Effect()
  findValueAddedTaxCreditAccount$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_VALUE_ADDED_TAX_CREDIT_ACCOUNT),
    switchMap((action: FindValueAddedTaxCreditAccount) =>
      this.systemAccountsService.findSystemAccountByKey(SystemAccounts.VALUE_ADDED_TAX_CREDIT).pipe(
        map((response) => new FindValueAddedTaxCreditAccountSuccess(response)),
        catchError((error) => of(new FindValueAddedTaxCreditAccountFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  findValueAddedTaxCreditAccountFail$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_VALUE_ADDED_TAX_CREDIT_ACCOUNT_FAIL),
    tap((action: FindValueAddedTaxCreditAccountFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate('FINANCES.SYSTEM_ACCOUNTS.VALUE_ADDED_TAX_CREDIT_ACCOUNT_NOT_FOUND')
        );
      }
    })
  );

  /* ========================= FIND_RECEIVABLES_STAFF_ACCOUNT =================================== */
  @Effect()
  findReceivablesStaffAccount$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_RECEIVABLES_STAFF_ACCOUNT),
    switchMap((action: FindReceivablesStaffAccount) =>
      this.systemAccountsService.findSystemAccountByKey(SystemAccounts.RECEIVABLES_STAFF).pipe(
        map((response) => new FindReceivablesStaffAccountSuccess(response)),
        catchError((error) => of(new FindReceivablesStaffAccountFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  findReceivablesStaffAccountFail$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_RECEIVABLES_STAFF_ACCOUNT_FAIL),
    tap((action: FindReceivablesStaffAccountFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate('FINANCES.SYSTEM_ACCOUNTS.RECEIVABLES_STAFF_ACCOUNT_NOT_FOUND')
        );
      }
    })
  );

  /* ========================= FIND_OTHER_CURRENT_LIABILITIES_RELATED_PARTIES_ACCOUNT =================================== */
  @Effect()
  findOtherCurrentLiabilitiesRelatedPartiesAccount$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_OTHER_CURRENT_LIABILITIES_RELATED_PARTIES_ACCOUNT),
    switchMap((action: FindOtherCurrentLiabilitiesRelatedPartiesAccount) =>
      this.systemAccountsService.findSystemAccountByKey(SystemAccounts.OTHER_CURRENT_LIABILITIES_RELATED_PARTIES).pipe(
        map((response) => new FindOtherCurrentLiabilitiesRelatedPartiesAccountSuccess(response)),
        catchError((error) => of(new FindOtherCurrentLiabilitiesRelatedPartiesAccountFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  findOtherCurrentLiabilitiesRelatedPartiesAccountFail$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_OTHER_CURRENT_LIABILITIES_RELATED_PARTIES_ACCOUNT_FAIL),
    tap((action: FindOtherCurrentLiabilitiesRelatedPartiesAccountFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate(
            'FINANCES.SYSTEM_ACCOUNTS.OTHER_CURRENT_LIABILITIES_RELATED_PARTIES_ACCOUNT_NOT_FOUND'
          )
        );
      }
    })
  );

  /* ========================= FIND_MISCELLANEOUS_CREDITORS_ACCOUNT =================================== */
  @Effect()
  findMiscellaneousCreditorsAccount$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_MISCELLANEOUS_CREDITORS_ACCOUNT),
    switchMap((action: FindMiscellaneousCreditorsAccount) =>
      this.systemAccountsService.findSystemAccountByKey(SystemAccounts.MISCELLANEOUS_CREDITORS).pipe(
        map((response) => new FindMiscellaneousCreditorsAccountSuccess(response)),
        catchError((error) => of(new FindMiscellaneousCreditorsAccountFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  findMiscellaneousCreditorsAccountFail$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_MISCELLANEOUS_CREDITORS_ACCOUNT_FAIL),
    tap((action: FindMiscellaneousCreditorsAccountFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate('FINANCES.SYSTEM_ACCOUNTS.MISCELLANEOUS_CREDITORS_ACCOUNT_NOT_FOUND')
        );
      }
    })
  );

  /* ========================= FIND_DIVIDEND_PAYABLE_ACCOUNT =================================== */
  @Effect()
  findDividendPayableAccount$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_DIVIDEND_PAYABLE_ACCOUNT),
    switchMap((action: FindDividendPayableAccount) =>
      this.systemAccountsService.findSystemAccountByKey(SystemAccounts.DIVIDEND_PAYABLE).pipe(
        map((response) => new FindDividendPayableAccountSuccess(response)),
        catchError((error) => of(new FindDividendPayableAccountFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  findDividendPayableAccountFail$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_DIVIDEND_PAYABLE_ACCOUNT_FAIL),
    tap((action: FindDividendPayableAccountFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate('FINANCES.SYSTEM_ACCOUNTS.DIVIDEND_PAYABLE_ACCOUNT_NOT_FOUND')
        );
      }
    })
  );

  /* ========================= FIND_ZAKAT_PROVISION_ACCOUNT =================================== */
  @Effect()
  findZakatProvisionAccount$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_ZAKAT_PROVISION_ACCOUNT),
    switchMap((action: FindZakatProvisionAccount) =>
      this.systemAccountsService.findSystemAccountByKey(SystemAccounts.ZAKAT_PROVISION).pipe(
        map((response) => new FindZakatProvisionAccountSuccess(response)),
        catchError((error) => of(new FindZakatProvisionAccountFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  findZakatProvisionAccountFail$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_ZAKAT_PROVISION_ACCOUNT_FAIL),
    tap((action: FindZakatProvisionAccountFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate('FINANCES.SYSTEM_ACCOUNTS.ZAKAT_PROVISION_ACCOUNT_NOT_FOUND')
        );
      }
    })
  );

  /* ========================= FIND_INCOME_TAX_PROVISION_ACCOUNT =================================== */
  @Effect()
  findIncomeTaxProvisionAccount$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_INCOME_TAX_PROVISION_ACCOUNT),
    switchMap((action: FindIncomeTaxProvisionAccount) =>
      this.systemAccountsService.findSystemAccountByKey(SystemAccounts.INCOME_TAX_PROVISION).pipe(
        map((response) => new FindIncomeTaxProvisionAccountSuccess(response)),
        catchError((error) => of(new FindIncomeTaxProvisionAccountFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  findIncomeTaxProvisionAccountFail$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_INCOME_TAX_PROVISION_ACCOUNT_FAIL),
    tap((action: FindIncomeTaxProvisionAccountFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate('FINANCES.SYSTEM_ACCOUNTS.INCOME_TAX_PROVISION_ACCOUNT_NOT_FOUND')
        );
      }
    })
  );

  /* ========================= FIND_PROVISION_FOR_WITHHOLDING_TAX_ACCOUNT =================================== */
  @Effect()
  findProvisionForWithholdingTaxAccount$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_PROVISION_FOR_WITHHOLDING_TAX_ACCOUNT),
    switchMap((action: FindProvisionForWithholdingTaxAccount) =>
      this.systemAccountsService.findSystemAccountByKey(SystemAccounts.PROVISION_FOR_WITHHOLDING_TAX).pipe(
        map((response) => new FindProvisionForWithholdingTaxAccountSuccess(response)),
        catchError((error) => of(new FindProvisionForWithholdingTaxAccountFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  findProvisionForWithholdingTaxAccountFail$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_PROVISION_FOR_WITHHOLDING_TAX_ACCOUNT_FAIL),
    tap((action: FindProvisionForWithholdingTaxAccountFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate('FINANCES.SYSTEM_ACCOUNTS.PROVISION_FOR_WITHHOLDING_TAX_ACCOUNT_NOT_FOUND')
        );
      }
    })
  );

  /* ========================= FIND_BANK_LOANS_ACCOUNT =================================== */
  @Effect()
  findBankLoansAccount$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_BANK_LOANS_ACCOUNT),
    switchMap((action: FindBankLoansAccount) =>
      this.systemAccountsService.findSystemAccountByKey(SystemAccounts.BANK_LOANS).pipe(
        map((response) => new FindBankLoansAccountSuccess(response)),
        catchError((error) => of(new FindBankLoansAccountFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  findBankLoansAccountFail$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_BANK_LOANS_ACCOUNT_FAIL),
    tap((action: FindBankLoansAccountFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate('FINANCES.SYSTEM_ACCOUNTS.BANK_LOANS_ACCOUNT_NOT_FOUND')
        );
      }
    })
  );

  /* ========================= FIND_LEASE_LIABILITIES_NON_CURRENT_PORTION_ACCOUNT =================================== */
  @Effect()
  findLeaseLiabilitiesNonCurrentPortionAccount$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_LEASE_LIABILITIES_NON_CURRENT_PORTION_ACCOUNT),
    switchMap((action: FindLeaseLiabilitiesNonCurrentPortionAccount) =>
      this.systemAccountsService.findSystemAccountByKey(SystemAccounts.LEASE_LIABILITIES_NON_CURRENT_PORTION).pipe(
        map((response) => new FindLeaseLiabilitiesNonCurrentPortionAccountSuccess(response)),
        catchError((error) => of(new FindLeaseLiabilitiesNonCurrentPortionAccountFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  findLeaseLiabilitiesNonCurrentPortionAccountFail$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_LEASE_LIABILITIES_NON_CURRENT_PORTION_ACCOUNT_FAIL),
    tap((action: FindLeaseLiabilitiesNonCurrentPortionAccountFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate(
            'FINANCES.SYSTEM_ACCOUNTS.LEASE_LIABILITIES_NON_CURRENT_PORTION_ACCOUNT_NOT_FOUND'
          )
        );
      }
    })
  );

  /* ========================= FIND_PROVISION_FOR_END_OF_SEVERANCE_PAY_ACCOUNT =================================== */
  @Effect()
  findProvisionForEndOfSeverancePayAccount$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_PROVISION_FOR_END_OF_SEVERANCE_PAY_ACCOUNT),
    switchMap((action: FindProvisionForEndOfSeverancePayAccount) =>
      this.systemAccountsService.findSystemAccountByKey(SystemAccounts.PROVISION_FOR_END_OF_SEVERANCE_PAY).pipe(
        map((response) => new FindProvisionForEndOfSeverancePayAccountSuccess(response)),
        catchError((error) => of(new FindProvisionForEndOfSeverancePayAccountFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  findProvisionForEndOfSeverancePayAccountFail$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_PROVISION_FOR_END_OF_SEVERANCE_PAY_ACCOUNT_FAIL),
    tap((action: FindProvisionForEndOfSeverancePayAccountFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate(
            'FINANCES.SYSTEM_ACCOUNTS.PROVISION_FOR_END_OF_SEVERANCE_PAY_ACCOUNT_NOT_FOUND'
          )
        );
      }
    })
  );

  /* ========================= FIND_CAPITAL_ACCOUNT =================================== */
  @Effect()
  findCapitalAccount$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_CAPITAL_ACCOUNT),
    switchMap((action: FindCapitalAccount) =>
      this.systemAccountsService.findSystemAccountByKey(SystemAccounts.CAPITAL).pipe(
        map((response) => new FindCapitalAccountSuccess(response)),
        catchError((error) => of(new FindCapitalAccountFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  findCapitalAccountFail$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_CAPITAL_ACCOUNT_FAIL),
    tap((action: FindCapitalAccountFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate('FINANCES.SYSTEM_ACCOUNTS.CAPITAL_ACCOUNT_NOT_FOUND')
        );
      }
    })
  );

  /* ========================= FIND_REGULAR_RESERVE_ACCOUNT =================================== */
  @Effect()
  findRegularReserveAccount$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_REGULAR_RESERVE_ACCOUNT),
    switchMap((action: FindRegularReserveAccount) =>
      this.systemAccountsService.findSystemAccountByKey(SystemAccounts.REGULAR_RESERVE).pipe(
        map((response) => new FindRegularReserveAccountSuccess(response)),
        catchError((error) => of(new FindRegularReserveAccountFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  findRegularReserveAccountFail$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_REGULAR_RESERVE_ACCOUNT_FAIL),
    tap((action: FindRegularReserveAccountFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate('FINANCES.SYSTEM_ACCOUNTS.REGULAR_RESERVE_ACCOUNT_NOT_FOUND')
        );
      }
    })
  );

  /* ========================= FIND_GENERAL_RESERVE_ACCOUNT =================================== */
  @Effect()
  findGeneralReserveAccount$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_GENERAL_RESERVE_ACCOUNT),
    switchMap((action: FindGeneralReserveAccount) =>
      this.systemAccountsService.findSystemAccountByKey(SystemAccounts.GENERAL_RESERVE).pipe(
        map((response) => new FindGeneralReserveAccountSuccess(response)),
        catchError((error) => of(new FindGeneralReserveAccountFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  findGeneralReserveAccountFail$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_GENERAL_RESERVE_ACCOUNT_FAIL),
    tap((action: FindGeneralReserveAccountFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate('FINANCES.SYSTEM_ACCOUNTS.GENERAL_RESERVE_ACCOUNT_NOT_FOUND')
        );
      }
    })
  );

  /* ========================= FIND_RETAINED_EARNINGS_ACCOUNT =================================== */
  @Effect()
  findRetainedEarningsAccount$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_RETAINED_EARNINGS_ACCOUNT),
    switchMap((action: FindRetainedEarningsAccount) =>
      this.systemAccountsService.findSystemAccountByKey(SystemAccounts.RETAINED_EARNINGS).pipe(
        map((response) => new FindRetainedEarningsAccountSuccess(response)),
        catchError((error) => of(new FindRetainedEarningsAccountFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  findRetainedEarningsAccountFail$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_RETAINED_EARNINGS_ACCOUNT_FAIL),
    tap((action: FindRetainedEarningsAccountFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate('FINANCES.SYSTEM_ACCOUNTS.RETAINED_EARNINGS_ACCOUNT_NOT_FOUND')
        );
      }
    })
  );

  /* ========================= FIND_PROFIT_AND_LOSS_BROKER_ACCOUNT =================================== */
  @Effect()
  findProfitAndLossBrokerAccount$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_PROFIT_AND_LOSS_BROKER_ACCOUNT),
    switchMap((action: FindProfitAndLossBrokerAccount) =>
      this.systemAccountsService.findSystemAccountByKey(SystemAccounts.PROFIT_AND_LOSS_BROKER).pipe(
        map((response) => new FindProfitAndLossBrokerAccountSuccess(response)),
        catchError((error) => of(new FindProfitAndLossBrokerAccountFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  findProfitAndLossBrokerAccountFail$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_PROFIT_AND_LOSS_BROKER_ACCOUNT_FAIL),
    tap((action: FindProfitAndLossBrokerAccountFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate('FINANCES.SYSTEM_ACCOUNTS.PROFIT_AND_LOSS_BROKER_ACCOUNT_NOT_FOUND')
        );
      }
    })
  );

  /* ========================= FIND_ADDITIONAL_CAPITAL_ACCOUNT =================================== */
  @Effect()
  findAdditionalCapitalAccount$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_ADDITIONAL_CAPITAL_ACCOUNT),
    switchMap((action: FindAdditionalCapitalAccount) =>
      this.systemAccountsService.findSystemAccountByKey(SystemAccounts.ADDITIONAL_CAPITAL).pipe(
        map((response) => new FindAdditionalCapitalAccountSuccess(response)),
        catchError((error) => of(new FindAdditionalCapitalAccountFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  findAdditionalCapitalAccountFail$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_ADDITIONAL_CAPITAL_ACCOUNT_FAIL),
    tap((action: FindAdditionalCapitalAccountFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate('FINANCES.SYSTEM_ACCOUNTS.ADDITIONAL_CAPITAL_ACCOUNT_NOT_FOUND')
        );
      }
    })
  );

  /* ========================= FIND_MAIN_ACTIVITIES_SALES_REVENUE_ACCOUNT =================================== */
  @Effect()
  findMainActivitiesSalesRevenueAccount$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_MAIN_ACTIVITIES_SALES_REVENUE_ACCOUNT),
    switchMap((action: FindMainActivitiesSalesRevenueAccount) =>
      this.systemAccountsService.findSystemAccountByKey(SystemAccounts.MAIN_ACTIVITIES_SALES_REVENUE).pipe(
        map((response) => new FindMainActivitiesSalesRevenueAccountSuccess(response)),
        catchError((error) => of(new FindMainActivitiesSalesRevenueAccountFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  findMainActivitiesSalesRevenueAccountFail$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_MAIN_ACTIVITIES_SALES_REVENUE_ACCOUNT_FAIL),
    tap((action: FindMainActivitiesSalesRevenueAccountFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate('FINANCES.SYSTEM_ACCOUNTS.MAIN_ACTIVITIES_SALES_REVENUE_ACCOUNT_NOT_FOUND')
        );
      }
    })
  );

  /* ========================= FIND_GAIN_LOSS_ON_DISPOSAL_OF_PROPERTY_ACCOUNT =================================== */
  @Effect()
  findGainLossOnDisposalOfPropertyAccount$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_GAIN_LOSS_ON_DISPOSAL_OF_PROPERTY_ACCOUNT),
    switchMap((action: FindGainLossOnDisposalOfPropertyAccount) =>
      this.systemAccountsService.findSystemAccountByKey(SystemAccounts.GAIN_LOSS_ON_DISPOSAL_OF_PROPERTY).pipe(
        map((response) => new FindGainLossOnDisposalOfPropertyAccountSuccess(response)),
        catchError((error) => of(new FindGainLossOnDisposalOfPropertyAccountFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  findGainLossOnDisposalOfPropertyAccountFail$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_GAIN_LOSS_ON_DISPOSAL_OF_PROPERTY_ACCOUNT_FAIL),
    tap((action: FindGainLossOnDisposalOfPropertyAccountFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate(
            'FINANCES.SYSTEM_ACCOUNTS.GAIN_LOSS_ON_DISPOSAL_OF_PROPERTY_ACCOUNT_NOT_FOUND'
          )
        );
      }
    })
  );

  /* ========================= FIND_GAIN_LOSS_ON_INVESTMENT_VALUATION_ACCOUNT =================================== */
  @Effect()
  findGainLossOnInvestmentValuationAccount$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_GAIN_LOSS_ON_INVESTMENT_VALUATION_ACCOUNT),
    switchMap((action: FindGainLossOnInvestmentValuationAccount) =>
      this.systemAccountsService.findSystemAccountByKey(SystemAccounts.GAIN_LOSS_ON_INVESTMENT_VALUATION).pipe(
        map((response) => new FindGainLossOnInvestmentValuationAccountSuccess(response)),
        catchError((error) => of(new FindGainLossOnInvestmentValuationAccountFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  findGainLossOnInvestmentValuationAccountFail$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_GAIN_LOSS_ON_INVESTMENT_VALUATION_ACCOUNT_FAIL),
    tap((action: FindGainLossOnInvestmentValuationAccountFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate(
            'FINANCES.SYSTEM_ACCOUNTS.GAIN_LOSS_ON_INVESTMENT_VALUATION_ACCOUNT_NOT_FOUND'
          )
        );
      }
    })
  );

  /* ========================= FIND_SUPPORT_REVENUE_ACCOUNT =================================== */
  @Effect()
  findSupportRevenueAccount$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_SUPPORT_REVENUE_ACCOUNT),
    switchMap((action: FindSupportRevenueAccount) =>
      this.systemAccountsService.findSystemAccountByKey(SystemAccounts.SUPPORT_REVENUE).pipe(
        map((response) => new FindSupportRevenueAccountSuccess(response)),
        catchError((error) => of(new FindSupportRevenueAccountFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  findSupportRevenueAccountFail$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_SUPPORT_REVENUE_ACCOUNT_FAIL),
    tap((action: FindSupportRevenueAccountFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate('FINANCES.SYSTEM_ACCOUNTS.SUPPORT_REVENUE_ACCOUNT_NOT_FOUND')
        );
      }
    })
  );

  /* ========================= FIND_OTHER_MISCELLANEOUS_INCOME_ACCOUNT =================================== */
  @Effect()
  findOtherMiscellaneousIncomeAccount$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_OTHER_MISCELLANEOUS_INCOME_ACCOUNT),
    switchMap((action: FindOtherMiscellaneousIncomeAccount) =>
      this.systemAccountsService.findSystemAccountByKey(SystemAccounts.OTHER_MISCELLANEOUS_INCOME).pipe(
        map((response) => new FindOtherMiscellaneousIncomeAccountSuccess(response)),
        catchError((error) => of(new FindOtherMiscellaneousIncomeAccountFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  findOtherMiscellaneousIncomeAccountFail$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_OTHER_MISCELLANEOUS_INCOME_ACCOUNT_FAIL),
    tap((action: FindOtherMiscellaneousIncomeAccountFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate('FINANCES.SYSTEM_ACCOUNTS.OTHER_MISCELLANEOUS_INCOME_ACCOUNT_NOT_FOUND')
        );
      }
    })
  );

  /* ========================= FIND_USED_OF_MATERIAL_ACCOUNT =================================== */
  @Effect()
  findUsedOfMaterialAccount$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_USED_OF_MATERIAL_ACCOUNT),
    switchMap((action: FindUsedOfMaterialAccount) =>
      this.systemAccountsService.findSystemAccountByKey(SystemAccounts.USED_OF_MATERIAL).pipe(
        map((response) => new FindUsedOfMaterialAccountSuccess(response)),
        catchError((error) => of(new FindUsedOfMaterialAccountFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  findUsedOfMaterialAccountFail$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_USED_OF_MATERIAL_ACCOUNT_FAIL),
    tap((action: FindUsedOfMaterialAccountFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate('FINANCES.SYSTEM_ACCOUNTS.USED_OF_MATERIAL_ACCOUNT_NOT_FOUND')
        );
      }
    })
  );

  /* ========================= FIND_NON_INVENTORY_MATERIAL_COSTS_ACCOUNT =================================== */
  @Effect()
  findNonInventoryMaterialCostsAccount$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_NON_INVENTORY_MATERIAL_COSTS_ACCOUNT),
    switchMap((action: FindNonInventoryMaterialCostsAccount) =>
      this.systemAccountsService.findSystemAccountByKey(SystemAccounts.NON_INVENTORY_MATERIAL_COSTS).pipe(
        map((response) => new FindNonInventoryMaterialCostsAccountSuccess(response)),
        catchError((error) => of(new FindNonInventoryMaterialCostsAccountFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  findNonInventoryMaterialCostsAccountFail$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_NON_INVENTORY_MATERIAL_COSTS_ACCOUNT_FAIL),
    tap((action: FindNonInventoryMaterialCostsAccountFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate('FINANCES.SYSTEM_ACCOUNTS.NON_INVENTORY_MATERIAL_COSTS_ACCOUNT_NOT_FOUND')
        );
      }
    })
  );

  /* ========================= FIND_RENT_ACCOUNT =================================== */
  @Effect()
  findRentAccount$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_RENT_ACCOUNT),
    switchMap((action: FindRentAccount) =>
      this.systemAccountsService.findSystemAccountByKey(SystemAccounts.RENT).pipe(
        map((response) => new FindRentAccountSuccess(response)),
        catchError((error) => of(new FindRentAccountFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  findRentAccountFail$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_RENT_ACCOUNT_FAIL),
    tap((action: FindRentAccountFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate('FINANCES.SYSTEM_ACCOUNTS.RENT_ACCOUNT_NOT_FOUND')
        );
      }
    })
  );

  /* ========================= FIND_LOCAL_LABOR_ACCOUNT =================================== */
  @Effect()
  findLocalLaborAccount$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_LOCAL_LABOR_ACCOUNT),
    switchMap((action: FindLocalLaborAccount) =>
      this.systemAccountsService.findSystemAccountByKey(SystemAccounts.LOCAL_LABOR).pipe(
        map((response) => new FindLocalLaborAccountSuccess(response)),
        catchError((error) => of(new FindLocalLaborAccountFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  findLocalLaborAccountFail$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_LOCAL_LABOR_ACCOUNT_FAIL),
    tap((action: FindLocalLaborAccountFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate('FINANCES.SYSTEM_ACCOUNTS.LOCAL_LABOR_ACCOUNT_NOT_FOUND')
        );
      }
    })
  );

  /* ========================= FIND_SALARIES_AND_WAGES_EMPLOYEE_PROTECTION_ACCOUNT =================================== */
  @Effect()
  findSalariesAndWagesEmployeeProtectionAccount$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_SALARIES_AND_WAGES_EMPLOYEE_PROTECTION_ACCOUNT),
    switchMap((action: FindSalariesAndWagesEmployeeProtectionAccount) =>
      this.systemAccountsService.findSystemAccountByKey(SystemAccounts.SALARIES_AND_WAGES_EMPLOYEE_PROTECTION).pipe(
        map((response) => new FindSalariesAndWagesEmployeeProtectionAccountSuccess(response)),
        catchError((error) => of(new FindSalariesAndWagesEmployeeProtectionAccountFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  findSalariesAndWagesEmployeeProtectionAccountFail$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_SALARIES_AND_WAGES_EMPLOYEE_PROTECTION_ACCOUNT_FAIL),
    tap((action: FindSalariesAndWagesEmployeeProtectionAccountFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate(
            'FINANCES.SYSTEM_ACCOUNTS.SALARIES_AND_WAGES_EMPLOYEE_PROTECTION_ACCOUNT_NOT_FOUND'
          )
        );
      }
    })
  );

  /* ========================= FIND_BENEFIT_TO_EMPLOYEES_ALL_COSTS_EXCEPT_WAGE_PROTECTION_ELEMENTS_ACCOUNT =================================== */
  @Effect()
  findBenefitToEmployeesAllCostsExceptWageProtectionElementsAccount$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_BENEFIT_TO_EMPLOYEES_ALL_COSTS_EXCEPT_WAGE_PROTECTION_ELEMENTS_ACCOUNT),
    switchMap((action: FindBenefitToEmployeesAllCostsExceptWageProtectionElementsAccount) =>
      this.systemAccountsService
        .findSystemAccountByKey(SystemAccounts.BENEFIT_TO_EMPLOYEES_ALL_COSTS_EXCEPT_WAGE_PROTECTION_ELEMENTS)
        .pipe(
          map((response) => new FindBenefitToEmployeesAllCostsExceptWageProtectionElementsAccountSuccess(response)),
          catchError((error) => of(new FindBenefitToEmployeesAllCostsExceptWageProtectionElementsAccountFail(error)))
        )
    )
  );

  @Effect({ dispatch: false })
  findBenefitToEmployeesAllCostsExceptWageProtectionElementsAccountFail$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_BENEFIT_TO_EMPLOYEES_ALL_COSTS_EXCEPT_WAGE_PROTECTION_ELEMENTS_ACCOUNT_FAIL),
    tap((action: FindBenefitToEmployeesAllCostsExceptWageProtectionElementsAccountFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate(
            'FINANCES.SYSTEM_ACCOUNTS.BENEFIT_TO_EMPLOYEES_ALL_COSTS_EXCEPT_WAGE_PROTECTION_ELEMENTS_ACCOUNT_NOT_FOUND'
          )
        );
      }
    })
  );

  /* ========================= FIND_WORK_MISSIONS_AND_OVERTIME_ACCOUNT =================================== */
  @Effect()
  findWorkMissionsAndOvertimeAccount$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_WORK_MISSIONS_AND_OVERTIME_ACCOUNT),
    switchMap((action: FindWorkMissionsAndOvertimeAccount) =>
      this.systemAccountsService.findSystemAccountByKey(SystemAccounts.WORK_IN_PROGRESS_UNBILLED_REVENUE).pipe(
        map((response) => new FindWorkMissionsAndOvertimeAccountSuccess(response)),
        catchError((error) => of(new FindWorkMissionsAndOvertimeAccountFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  findWorkMissionsAndOvertimeAccountFail$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_WORK_MISSIONS_AND_OVERTIME_ACCOUNT_FAIL),
    tap((action: FindWorkMissionsAndOvertimeAccountFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate('FINANCES.SYSTEM_ACCOUNTS.WORK_MISSIONS_AND_OVERTIME_ACCOUNT_NOT_FOUND')
        );
      }
    })
  );

  /* ========================= FIND_REPAIR_AND_MAINTENANCE_ACCOUNT =================================== */
  @Effect()
  findRepairAndMaintenanceAccount$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_REPAIR_AND_MAINTENANCE_ACCOUNT),
    switchMap((action: FindRepairAndMaintenanceAccount) =>
      this.systemAccountsService.findSystemAccountByKey(SystemAccounts.REPAIR_AND_MAINTENANCE).pipe(
        map((response) => new FindRepairAndMaintenanceAccountSuccess(response)),
        catchError((error) => of(new FindRepairAndMaintenanceAccountFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  findRepairAndMaintenanceAccountFail$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_REPAIR_AND_MAINTENANCE_ACCOUNT_FAIL),
    tap((action: FindRepairAndMaintenanceAccountFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate('FINANCES.SYSTEM_ACCOUNTS.REPAIR_AND_MAINTENANCE_ACCOUNT_NOT_FOUND')
        );
      }
    })
  );

  /* ========================= FIND_GOVERNMENT_FEES_AND_SUBSCRIPTIONS_ACCOUNT =================================== */
  @Effect()
  findGovernmentFeesAndSubscriptionsAccount$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_GOVERNMENT_FEES_AND_SUBSCRIPTIONS_ACCOUNT),
    switchMap((action: FindGovernmentFeesAndSubscriptionsAccount) =>
      this.systemAccountsService.findSystemAccountByKey(SystemAccounts.GOVERNMENT_FEES_AND_SUBSCRIPTIONS).pipe(
        map((response) => new FindGovernmentFeesAndSubscriptionsAccountSuccess(response)),
        catchError((error) => of(new FindGovernmentFeesAndSubscriptionsAccountFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  findGovernmentFeesAndSubscriptionsAccountFail$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_GOVERNMENT_FEES_AND_SUBSCRIPTIONS_ACCOUNT_FAIL),
    tap((action: FindGovernmentFeesAndSubscriptionsAccountFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate(
            'FINANCES.SYSTEM_ACCOUNTS.GOVERNMENT_FEES_AND_SUBSCRIPTIONS_ACCOUNT_NOT_FOUND'
          )
        );
      }
    })
  );

  /* ========================= FIND_THE_COST_OF_PUBLIC_SERVICES_ACCOUNT =================================== */
  @Effect()
  findTheCostOfPublicServicesAccount$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_THE_COST_OF_PUBLIC_SERVICES_ACCOUNT),
    switchMap((action: FindTheCostOfPublicServicesAccount) =>
      this.systemAccountsService.findSystemAccountByKey(SystemAccounts.THE_COST_OF_PUBLIC_SERVICES).pipe(
        map((response) => new FindTheCostOfPublicServicesAccountSuccess(response)),
        catchError((error) => of(new FindTheCostOfPublicServicesAccountFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  findTheCostOfPublicServicesAccountFail$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_THE_COST_OF_PUBLIC_SERVICES_ACCOUNT_FAIL),
    tap((action: FindTheCostOfPublicServicesAccountFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate('FINANCES.SYSTEM_ACCOUNTS.THE_COST_OF_PUBLIC_SERVICES_ACCOUNT_NOT_FOUND')
        );
      }
    })
  );

  /* ========================= FIND_HOSPITALITY_AND_CLEANLINESS_ACCOUNT =================================== */
  @Effect()
  findHospitalityAndCleanlinessAccount$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_HOSPITALITY_AND_CLEANLINESS_ACCOUNT),
    switchMap((action: FindHospitalityAndCleanlinessAccount) =>
      this.systemAccountsService.findSystemAccountByKey(SystemAccounts.HOSPITALITY_AND_CLEANLINESS).pipe(
        map((response) => new FindHospitalityAndCleanlinessAccountSuccess(response)),
        catchError((error) => of(new FindHospitalityAndCleanlinessAccountFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  findHospitalityAndCleanlinessAccountFail$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_HOSPITALITY_AND_CLEANLINESS_ACCOUNT_FAIL),
    tap((action: FindHospitalityAndCleanlinessAccountFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate('FINANCES.SYSTEM_ACCOUNTS.HOSPITALITY_AND_CLEANLINESS_ACCOUNT_NOT_FOUND')
        );
      }
    })
  );

  /* ========================= FIND_OFFICE_CONSUMABLES_ACCOUNT =================================== */
  @Effect()
  findOfficeConsumablesAccount$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_OFFICE_CONSUMABLES_ACCOUNT),
    switchMap((action: FindOfficeConsumablesAccount) =>
      this.systemAccountsService.findSystemAccountByKey(SystemAccounts.OFFICE_CONSUMABLES).pipe(
        map((response) => new FindOfficeConsumablesAccountSuccess(response)),
        catchError((error) => of(new FindOfficeConsumablesAccountFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  findOfficeConsumablesAccountFail$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_OFFICE_CONSUMABLES_ACCOUNT_FAIL),
    tap((action: FindOfficeConsumablesAccountFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate('FINANCES.SYSTEM_ACCOUNTS.OFFICE_CONSUMABLES_ACCOUNT_NOT_FOUND')
        );
      }
    })
  );

  /* ========================= FIND_FINANCE_CHARGES_LOANS_AND_BANK_FACILITIES_ACCOUNT =================================== */
  @Effect()
  findFinanceChargesLoansAndBankFacilitiesAccount$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_FINANCE_CHARGES_LOANS_AND_BANK_FACILITIES_ACCOUNT),
    switchMap((action: FindFinanceChargesLoansAndBankFacilitiesAccount) =>
      this.systemAccountsService.findSystemAccountByKey(SystemAccounts.FINANCE_CHARGES_LOANS_AND_BANK_FACILITIES).pipe(
        map((response) => new FindFinanceChargesLoansAndBankFacilitiesAccountSuccess(response)),
        catchError((error) => of(new FindFinanceChargesLoansAndBankFacilitiesAccountFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  findFinanceChargesLoansAndBankFacilitiesAccountFail$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_FINANCE_CHARGES_LOANS_AND_BANK_FACILITIES_ACCOUNT_FAIL),
    tap((action: FindFinanceChargesLoansAndBankFacilitiesAccountFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate(
            'FINANCES.SYSTEM_ACCOUNTS.FINANCE_CHARGES_LOANS_AND_BANK_FACILITIES_ACCOUNT_NOT_FOUND'
          )
        );
      }
    })
  );

  /* ========================= FIND_FINANCE_CHARGES_LEASE_CONTRACTS_ACCOUNT =================================== */
  @Effect()
  findFinanceChargesLeaseContractsAccount$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_FINANCE_CHARGES_LEASE_CONTRACTS_ACCOUNT),
    switchMap((action: FindFinanceChargesLeaseContractsAccount) =>
      this.systemAccountsService
        .findSystemAccountByKey(SystemAccounts.FINANCE_CHARGES_FOR_LEASE_CONTRACTS_NON_CURRENT)
        .pipe(
          map((response) => new FindFinanceChargesLeaseContractsAccountSuccess(response)),
          catchError((error) => of(new FindFinanceChargesLeaseContractsAccountFail(error)))
        )
    )
  );

  @Effect({ dispatch: false })
  findFinanceChargesLeaseContractsAccountFail$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_FINANCE_CHARGES_LEASE_CONTRACTS_ACCOUNT_FAIL),
    tap((action: FindFinanceChargesLeaseContractsAccountFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate(
            'FINANCES.SYSTEM_ACCOUNTS.FINANCE_CHARGES_LEASE_CONTRACTS_ACCOUNT_NOT_FOUND'
          )
        );
      }
    })
  );

  /* ========================= FIND_COMMISSIONS_ACCOUNT =================================== */
  @Effect()
  findCommissionsAccount$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_COMMISSIONS_ACCOUNT),
    switchMap((action: FindCommissionsAccount) =>
      this.systemAccountsService.findSystemAccountByKey(SystemAccounts.COMMISSIONS).pipe(
        map((response) => new FindCommissionsAccountSuccess(response)),
        catchError((error) => of(new FindCommissionsAccountFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  findCommissionsAccountFail$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_COMMISSIONS_ACCOUNT_FAIL),
    tap((action: FindCommissionsAccountFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate('FINANCES.SYSTEM_ACCOUNTS.COMMISSIONS_ACCOUNT_NOT_FOUND')
        );
      }
    })
  );

  /* ========================= FIND_BANK_EXPENSES_ACCOUNT =================================== */
  @Effect()
  findBankExpensesAccount$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_BANK_EXPENSES_ACCOUNT),
    switchMap((action: FindBankExpensesAccount) =>
      this.systemAccountsService.findSystemAccountByKey(SystemAccounts.BANK_EXPENSES).pipe(
        map((response) => new FindBankExpensesAccountSuccess(response)),
        catchError((error) => of(new FindBankExpensesAccountFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  findBankExpensesAccountFail$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_BANK_EXPENSES_ACCOUNT_FAIL),
    tap((action: FindBankExpensesAccountFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate('FINANCES.SYSTEM_ACCOUNTS.BANK_EXPENSES_ACCOUNT_NOT_FOUND')
        );
      }
    })
  );

  /* ========================= FIND_COST_OF_REVENUE_AND_GENERAL_EXPENSES_OTHERS_ACCOUNT =================================== */
  @Effect()
  findCostOfRevenueAndGeneralExpensesOthersAccount$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_COST_OF_REVENUE_AND_GENERAL_EXPENSES_OTHERS_ACCOUNT),
    switchMap((action: FindCostOfRevenueAndGeneralExpensesOthersAccount) =>
      this.systemAccountsService
        .findSystemAccountByKey(SystemAccounts.COST_OF_REVENUE_AND_GENERAL_EXPENSES_OTHERS)
        .pipe(
          map((response) => new FindCostOfRevenueAndGeneralExpensesOthersAccountSuccess(response)),
          catchError((error) => of(new FindCostOfRevenueAndGeneralExpensesOthersAccountFail(error)))
        )
    )
  );

  @Effect({ dispatch: false })
  findCostOfRevenueAndGeneralExpensesOthersAccountFail$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_COST_OF_REVENUE_AND_GENERAL_EXPENSES_OTHERS_ACCOUNT_FAIL),
    tap((action: FindCostOfRevenueAndGeneralExpensesOthersAccountFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate(
            'FINANCES.SYSTEM_ACCOUNTS.COST_OF_REVENUE_AND_GENERAL_EXPENSES_OTHERS_ACCOUNT_NOT_FOUND'
          )
        );
      }
    })
  );

  /* ========================= FIND_BUILDINGS_DEPRECIATION_EXPENSES _ACCOUNT =================================== */
  @Effect()
  findBuildingDepreciationExpressAccount$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_BUILDINGS_DEPRECIATION_EXPENSES_ACCOUNT),
    switchMap((action: FindBuildingDepreciationExpressAccount) =>
      this.systemAccountsService.findSystemAccountByKey(SystemAccounts.BUILDINGS_DEPRECIATION_EXPENSES).pipe(
        map((response) => new FindBuildingDepreciationExpressAccountSuccess(response)),
        catchError((error) => of(new FindBuildingDepreciationExpressAccountFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  findBuildingDepreciationExpressAccountFail$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_BUILDINGS_DEPRECIATION_EXPENSES_ACCOUNT_FAIL),
    tap((action: FindBuildingDepreciationExpressAccountFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate(
            'FINANCES.SYSTEM_ACCOUNTS.BUILDINGS_DEPRECIATION_EXPENSES_ACCOUNT_NOT_FOUND'
          )
        );
      }
    })
  );

  /* ========================= FIND_FURNITURE_DEPRECIATION_EXPENSES_ACCOUNT =================================== */
  @Effect()
  findFurnitureDepreciationExpressAccount$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_FURNITURE_DEPRECIATION_EXPENSES_ACCOUNT),
    switchMap((action: FindFurnitureDepreciationExpressAccount) =>
      this.systemAccountsService.findSystemAccountByKey(SystemAccounts.FURNITURE_DEPRECIATION_EXPENSES).pipe(
        map((response) => new FindFurnitureDepreciationExpressAccountSuccess(response)),
        catchError((error) => of(new FindFurnitureDepreciationExpressAccountFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  findFurnitureDepreciationExpressAccountFail$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_FURNITURE_DEPRECIATION_EXPENSES_ACCOUNT_FAIL),
    tap((action: FindFurnitureDepreciationExpressAccountFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate(
            'FINANCES.SYSTEM_ACCOUNTS.FURNITURE_DEPRECIATION_EXPENSES_ACCOUNT_NOT_FOUND'
          )
        );
      }
    })
  );

  /* ========================= FIND_DEVICES_DEPRECIATION_EXPENSES_ACCOUNT =================================== */
  @Effect()
  findDevicesDepreciationExpressAccount$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_DEVICES_DEPRECIATION_EXPENSES_ACCOUNT),
    switchMap((action: FindDevicesDepreciationExpressAccount) =>
      this.systemAccountsService.findSystemAccountByKey(SystemAccounts.DEVICES_DEPRECIATION_EXPENSES).pipe(
        map((response) => new FindDevicesDepreciationExpressAccountSuccess(response)),
        catchError((error) => of(new FindDevicesDepreciationExpressAccountFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  findDevicesDepreciationExpressAccountFail$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_DEVICES_DEPRECIATION_EXPENSES_ACCOUNT_FAIL),
    tap((action: FindDevicesDepreciationExpressAccountFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate('FINANCES.SYSTEM_ACCOUNTS.DEVICES_DEPRECIATION_EXPENSES_ACCOUNT_NOT_FOUND')
        );
      }
    })
  );

  /* ========================= FIND_COMPUTERS_DEPRECIATION_EXPENSES_ACCOUNT =================================== */
  @Effect()
  findComputersDepreciationExpressAccount$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_COMPUTERS_DEPRECIATION_EXPENSES_ACCOUNT),
    switchMap((action: FindComputersDepreciationExpressAccount) =>
      this.systemAccountsService.findSystemAccountByKey(SystemAccounts.COMPUTERS_DEPRECIATION_EXPENSES).pipe(
        map((response) => new FindComputersDepreciationExpressAccountSuccess(response)),
        catchError((error) => of(new FindComputersDepreciationExpressAccountFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  findComputersDepreciationExpressAccountFail$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_COMPUTERS_DEPRECIATION_EXPENSES_ACCOUNT_FAIL),
    tap((action: FindComputersDepreciationExpressAccountFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate(
            'FINANCES.SYSTEM_ACCOUNTS.COMPUTERS_DEPRECIATION_EXPENSES_ACCOUNT_NOT_FOUND'
          )
        );
      }
    })
  );

  /* ========================= FIND_EQUIPMENTS_DEPRECIATION_EXPENSES_ACCOUNT =================================== */
  @Effect()
  findEquipmentsDepreciationExpressAccount$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_EQUIPMENTS_DEPRECIATION_EXPENSES_ACCOUNT),
    switchMap((action: FindEquipmentsDepreciationExpressAccount) =>
      this.systemAccountsService.findSystemAccountByKey(SystemAccounts.EQUIPMENTS_DEPRECIATION_EXPENSES).pipe(
        map((response) => new FindEquipmentsDepreciationExpressAccountSuccess(response)),
        catchError((error) => of(new FindEquipmentsDepreciationExpressAccountFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  findEquipmentsDepreciationExpressAccountFail$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_EQUIPMENTS_DEPRECIATION_EXPENSES_ACCOUNT_FAIL),
    tap((action: FindEquipmentsDepreciationExpressAccountFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate(
            'FINANCES.SYSTEM_ACCOUNTS.EQUIPMENTS_DEPRECIATION_EXPENSES_ACCOUNT_NOT_FOUND'
          )
        );
      }
    })
  );

  /* ========================= FIND_CARS_DEPRECIATION_EXPENSES_ACCOUNT =================================== */
  @Effect()
  findCarsDepreciationExpressAccount$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_CARS_DEPRECIATION_EXPENSES_ACCOUNT),
    switchMap((action: FindCarsDepreciationExpressAccount) =>
      this.systemAccountsService.findSystemAccountByKey(SystemAccounts.CARS_DEPRECIATION_EXPENSES).pipe(
        map((response) => new FindCarsDepreciationExpressAccountSuccess(response)),
        catchError((error) => of(new FindCarsDepreciationExpressAccountFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  findCarsDepreciationExpressAccountFail$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_CARS_DEPRECIATION_EXPENSES_ACCOUNT_FAIL),
    tap((action: FindCarsDepreciationExpressAccountFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate('FINANCES.SYSTEM_ACCOUNTS.CARS_DEPRECIATION_EXPENSES_ACCOUNT_NOT_FOUND')
        );
      }
    })
  );

  /* ========================= FIND_ASSETS_UNDER_FINANCE_LEASES_DEPRECIATION_EXPENSES_ACCOUNT =================================== */
  @Effect()
  findAssetsUnderFinanceLeasesDepreciationExpensesAccount$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_ASSETS_UNDER_FINANCE_LEASES_DEPRECIATION_EXPENSES_ACCOUNT),
    switchMap((action: FindAssetsUnderFinanceLeasesDepreciationExpensesAccount) =>
      this.systemAccountsService
        .findSystemAccountByKey(SystemAccounts.ASSETS_UNDER_FINANCE_LEASES_DEPRECIATION_EXPENSES)
        .pipe(
          map((response) => new FindAssetsUnderFinanceLeasesDepreciationExpensesAccountSuccess(response)),
          catchError((error) => of(new FindAssetsUnderFinanceLeasesDepreciationExpensesAccountFail(error)))
        )
    )
  );

  @Effect({ dispatch: false })
  findAssetsUnderFinanceLeasesDepreciationExpensesAccountFail$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_ASSETS_UNDER_FINANCE_LEASES_DEPRECIATION_EXPENSES_ACCOUNT_FAIL),
    tap((action: FindAssetsUnderFinanceLeasesDepreciationExpensesAccountFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate(
            'FINANCES.SYSTEM_ACCOUNTS.ASSETS_UNDER_FINANCE_LEASES_DEPRECIATION_EXPENSES_ACCOUNT_NOT_FOUND'
          )
        );
      }
    })
  );

  /* ========================= FIND_FAME_DEPRECIATION_EXPENSES_ACCOUNT =================================== */
  @Effect()
  findFameDepreciationExpensesAccount$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_FAME_DEPRECIATION_EXPENSES_ACCOUNT),
    switchMap((action: FindFameDepreciationExpensesAccount) =>
      this.systemAccountsService.findSystemAccountByKey(SystemAccounts.FAME_DEPRECIATION_EXPENSES).pipe(
        map((response) => new FindFameDepreciationExpensesAccountSuccess(response)),
        catchError((error) => of(new FindFameDepreciationExpensesAccountFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  findFameDepreciationExpensesAccountFail$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_FAME_DEPRECIATION_EXPENSES_ACCOUNT_FAIL),
    tap((action: FindFameDepreciationExpensesAccountFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate('FINANCES.SYSTEM_ACCOUNTS.FAME_DEPRECIATION_EXPENSES_ACCOUNT_NOT_FOUND')
        );
      }
    })
  );

  /* ========================= FIND_SOFTWARE_AND_SYSTEMS_DEPRECIATION_EXPENSES_ACCOUNT =================================== */
  @Effect()
  findSoftwareAndSystemsDepreciationExpensesAccount$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_SOFTWARE_AND_SYSTEMS_DEPRECIATION_EXPENSES_ACCOUNT),
    switchMap((action: FindSoftwareAndSystemsDepreciationExpensesAccount) =>
      this.systemAccountsService.findSystemAccountByKey(SystemAccounts.SOFTWARE_AND_SYSTEMS_DEPRECIATION_EXPENSES).pipe(
        map((response) => new FindSoftwareAndSystemsDepreciationExpensesAccountSuccess(response)),
        catchError((error) => of(new FindSoftwareAndSystemsDepreciationExpensesAccountFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  findSoftwareAndSystemsDepreciationExpensesAccountFail$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_SOFTWARE_AND_SYSTEMS_DEPRECIATION_EXPENSES_ACCOUNT_FAIL),
    tap((action: FindSoftwareAndSystemsDepreciationExpensesAccountFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate(
            'FINANCES.SYSTEM_ACCOUNTS.SOFTWARE_AND_SYSTEMS_DEPRECIATION_EXPENSES_ACCOUNT_NOT_FOUND'
          )
        );
      }
    })
  );

  /* ========================= FIND_PATENTS_AND_TRADEMARKS_DEPRECIATION_EXPENSES_ACCOUNT =================================== */
  @Effect()
  findPatentsAndTrademarksDepreciationExpensesAccount$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_PATENTS_AND_TRADEMARKS_DEPRECIATION_EXPENSES_ACCOUNT),
    switchMap((action: FindPatentsAndTrademarksDepreciationExpensesAccount) =>
      this.systemAccountsService
        .findSystemAccountByKey(SystemAccounts.PATENTS_AND_TRADEMARKS_DEPRECIATION_EXPENSES)
        .pipe(
          map((response) => new FindPatentsAndTrademarksDepreciationExpensesAccountSuccess(response)),
          catchError((error) => of(new FindPatentsAndTrademarksDepreciationExpensesAccountFail(error)))
        )
    )
  );

  @Effect({ dispatch: false })
  findPatentsAndTrademarksDepreciationExpensesAccountFail$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_PATENTS_AND_TRADEMARKS_DEPRECIATION_EXPENSES_ACCOUNT_FAIL),
    tap((action: FindPatentsAndTrademarksDepreciationExpensesAccountFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate(
            'FINANCES.SYSTEM_ACCOUNTS.PATENTS_AND_TRADEMARKS_DEPRECIATION_EXPENSES_ACCOUNT_NOT_FOUND'
          )
        );
      }
    })
  );

  /* ========================= FIND_REAL_ESTATE_INVESTMENT_BUILDINGS_DEPRECIATION_EXPENSES_ACCOUNT =================================== */
  @Effect()
  findRealEstateInvestmentBuildingsDepreciationExpensesAccount$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_REAL_ESTATE_INVESTMENT_BUILDINGS_DEPRECIATION_EXPENSES_ACCOUNT),
    switchMap((action: FindRealEstateInvestmentBuildingsDepreciationExpensesAccount) =>
      this.systemAccountsService
        .findSystemAccountByKey(SystemAccounts.REAL_ESTATE_INVESTMENT_BUILDINGS_DEPRECIATION_EXPENSES)
        .pipe(
          map((response) => new FindRealEstateInvestmentBuildingsDepreciationExpensesAccountSuccess(response)),
          catchError((error) => of(new FindRealEstateInvestmentBuildingsDepreciationExpensesAccountFail(error)))
        )
    )
  );

  @Effect({ dispatch: false })
  findRealEstateInvestmentBuildingsDepreciationExpensesAccountFail$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_REAL_ESTATE_INVESTMENT_BUILDINGS_DEPRECIATION_EXPENSES_ACCOUNT_FAIL),
    tap((action: FindRealEstateInvestmentBuildingsDepreciationExpensesAccountFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate(
            'FINANCES.SYSTEM_ACCOUNTS.REAL_ESTATE_INVESTMENT_BUILDINGS_DEPRECIATION_EXPENSES_ACCOUNT_NOT_FOUND'
          )
        );
      }
    })
  );

  /* ========================= FIND_ASSETS_BROKER_ACCOUNT =================================== */
  @Effect()
  findAssetsBrokerAccount$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_ASSETS_BROKER_ACCOUNT),
    switchMap((action: FindAssetsBrokerAccount) =>
      this.systemAccountsService.findSystemAccountByKey(SystemAccounts.ASSETS_BROKER).pipe(
        map((response) => new FindAssetsBrokerAccountSuccess(response)),
        catchError((error) => of(new FindAssetsBrokerAccountFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  findAssetsBrokerAccountFail$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_ASSETS_BROKER_ACCOUNT_FAIL),
    tap((action: FindAssetsBrokerAccountFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate('FINANCES.SYSTEM_ACCOUNTS.ASSETS_BROKER_ACCOUNT_NOT_FOUND')
        );
      }
    })
  );

  /* ========================= FIND_CURRENCY_ROUNDING_DIFFERENCE_ACCOUNT =================================== */
  @Effect()
  findCurrencyRoundingDifferenceAccount$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_CURRENCY_ROUNDING_DIFFERENCE_ACCOUNT),
    switchMap((action: FindCurrencyRoundingDifferenceAccount) =>
      this.systemAccountsService.findSystemAccountByKey(SystemAccounts.CURRENCY_ROUNDING_DIFFERENCE).pipe(
        map((response) => new FindCurrencyRoundingDifferenceAccountSuccess(response)),
        catchError((error) => of(new FindCurrencyRoundingDifferenceAccountFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  findCurrencyRoundingDifferenceAccountFail$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_CURRENCY_ROUNDING_DIFFERENCE_ACCOUNT_FAIL),
    tap((action: FindCurrencyRoundingDifferenceAccountFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate('FINANCES.SYSTEM_ACCOUNTS.CURRENCY_ROUNDING_DIFFERENCE_ACCOUNT_NOT_FOUND')
        );
      }
    })
  );

  /* ========================= FIND_GOSI_ACCRUAL_ACCOUNT =================================== */
  @Effect()
  findGosiAccrualAccount$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_GOSI_ACCRUAL_ACCOUNT),
    switchMap((action: FindGosiAccrualAccount) =>
      this.systemAccountsService.findSystemAccountByKey(SystemAccounts.GOSI_ACCRUAL).pipe(
        map((response) => new FindGosiAccrualAccountSuccess(response)),
        catchError((error) => of(new FindGosiAccrualAccountFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  findGosiAccrualAccountFail$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_GOSI_ACCRUAL_ACCOUNT_FAIL),
    tap((action: FindGosiAccrualAccountFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate('FINANCES.SYSTEM_ACCOUNTS.GOSI_ACCRUAL_ACCOUNT_NOT_FOUND')
        );
      }
    })
  );

  /* ========================= FIND_GOSI_EXPENSES_ACCOUNT =================================== */
  @Effect()
  findGosiExpensesAccount$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_GOSI_EXPENSES_ACCOUNT),
    switchMap((action: FindGosiExpensesAccount) =>
      this.systemAccountsService.findSystemAccountByKey(SystemAccounts.GOSI_EXPENSES).pipe(
        map((response) => new FindGosiExpensesAccountSuccess(response)),
        catchError((error) => of(new FindGosiExpensesAccountFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  findGosiExpensesAccountFail$ = this.actions$.pipe(
    ofType(SystemAccountsActionType.FIND_GOSI_EXPENSES_ACCOUNT_FAIL),
    tap((action: FindGosiExpensesAccountFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate('FINANCES.SYSTEM_ACCOUNTS.GOSI_EXPENSES_ACCOUNT_NOT_FOUND')
        );
      }
    })
  );
}
