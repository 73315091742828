import { createSelector } from '@ngrx/store';

import { OrganizationSettingsState, SettingsState } from 'settings/store/states';
import { selectSettingsState } from 'settings/store/selectors/feature.selectors';

/**
 * Gets the organization settings state.
 */
const selectOrganizationSettingsState = createSelector(
  selectSettingsState,
  (state: SettingsState) => state.organizationSettings
);

/**
 * Gets the loaded organization settings.
 */
export const getOrganizationsSettings = createSelector(
  selectOrganizationSettingsState,
  (state: OrganizationSettingsState) => state.organizationSettings
);

/**
 * Gets the error that occurred in the state.
 */
export const getOrganizationSettingsError = createSelector(
  selectOrganizationSettingsState,
  (state: OrganizationSettingsState) => state.error
);

/**
 * Determines if there is a running get organization settings process.
 */
export const getOrganizationSettings = createSelector(
  selectOrganizationSettingsState,
  (state: OrganizationSettingsState) => state.isGettingOrganizationSettings
);

/**
 * Determines if the last get organization settings process has been ended successfully.
 */
export const getOrganizationSettingsCompleted = createSelector(
  selectOrganizationSettingsState,
  (state: OrganizationSettingsState) => state.isGetOrganizationSettingsCompleted
);

/**
 * Determines if there is a running update organization settings process.
 */
export const getSelectedOrganizationSettingsUpdating = createSelector(
  selectOrganizationSettingsState,
  (state: OrganizationSettingsState) => state.isOrganizationSettingsUpdating
);

/**
 * Determines if the last update organization settings process has been ended successfully.
 */
export const getSelectedOrganizationSettingsUpdateCompleted = createSelector(
  selectOrganizationSettingsState,
  (state: OrganizationSettingsState) => state.isUpdateOrganizationSettingsCompleted
);
