import { createSelector } from '@ngrx/store';

import { AppStatusState } from 'core/store/states';
import { selectAppStatusState } from 'core/store/selectors/feature.selectors';

/* Selectors */

/**
 * Gets a value indicates whether the app has a loading status or not.
 */
export const getAppStatusLoading = createSelector(selectAppStatusState, (state: AppStatusState) => state.isLoading);

/**
 * Gets the current page information.
 */
export const getPage = createSelector(selectAppStatusState, (state: AppStatusState) => state.page);
