import { SickLeaveTypeActionType, SickLeaveTypeActions } from 'lookups/store/actions';
import { SickLeaveTypeState } from 'lookups/store/states';

/**
 * The initial state from which the state starts.
 */
const initialState: SickLeaveTypeState = {
  data: [],
  error: null,
  isLoaded: false,
  isLoading: false,
};

/**
 * The reducer function that is called in each action dispatch against the state.
 * @param state The current state.
 * @param action The action that will affect the state.
 */
export function sickLeaveTypeReducer(
  state: SickLeaveTypeState = initialState,
  action: SickLeaveTypeActions
): SickLeaveTypeState {
  switch (action.type) {
    case SickLeaveTypeActionType.GET_ALL_SICK_LEAVE_TYPES: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case SickLeaveTypeActionType.GET_ALL_SICK_LEAVE_TYPES_FAIL: {
      return {
        ...state,
        error: action.payload,
        isLoading: false,
        isLoaded: false,
      };
    }

    case SickLeaveTypeActionType.GET_ALL_SICK_LEAVE_TYPES_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        isLoading: false,
        isLoaded: true,
      };
    }

    default:
      return state;
  }
}
