import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';

import * as fromAuthStore from 'auth/store';
import * as fromCoreStore from 'core/store';
import { StorageService } from 'shared';

/**
 * Authentication guard that makes sure that the user is already logged-in.
 */
@Injectable()
export class AuthGuard implements CanActivate {
  /**
   * @param authStore$ The auth-Module store.
   * @param coreStore$ The core-Module store.
   * @param storageService The storage service.
   */
  constructor(
    private authStore$: Store<fromAuthStore.AuthState>,
    private coreStore$: Store<fromCoreStore.AppState>,
    private storageService: StorageService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.authStore$.pipe(
      select(fromAuthStore.getSessionLoggedIn),
      map((isLoggedIn) => {
        /**
         * If user is not logged in redirect him to loading page.
         */
        if (!isLoggedIn) {
          this.storageService.setRedirectUrl(state.url);
          this.coreStore$.dispatch(new fromCoreStore.Go({ path: ['/loading'] }));
        }

        return isLoggedIn;
      })
    );
  }
}
