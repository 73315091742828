<!-- journal employee loan details starts -->
<div class="row">
  <div class="col-md-12">
    <!-- employee loan details starts -->
    <div class="card-header mb-3 pt-0">
      <h4 class="card-title">{{ 'FINANCES.JOURNALS.JOURNAL_DATA.LOAN_DATA' | translate }}</h4>
    </div>
    <div class="card-content">
      <div class="px-3">
        <div class="row">
          <div class="form-group col-md-6">
            <label for="loanPaymentTypeId">{{ 'FINANCES.HR.LOAN_TYPE' | translate }}</label>
            <input
              type="text"
              id="loanPaymentTypeId"
              class="form-control round"
              [value]="employeeLoan.loanPaymentType?.name | localize: employeeLoan.loanPaymentType?.nameEn | async"
              readonly
            />
          </div>
          <div class="form-group col-md-6">
            <label for="value">{{ 'FINANCES.HR.VALUE' | translate }}</label>
            <input
              type="text"
              id="value"
              class="form-control round"
              [value]="employeeLoan.value | toDecimal"
              readonly
            />
          </div>
          <div class="form-group col-md-6">
            <label for="settlementValue">{{ 'FINANCES.HR.SETTLEMENT_VALUE' | translate }}</label>
            <input
              type="text"
              id="settlementValue"
              class="form-control round"
              [value]="employeeLoan.settlementValue | toDecimal"
              readonly
            />
          </div>
          <div class="form-group col-md-6">
            <label for="paid">{{ 'FINANCES.HR.PAID' | translate }}</label>
            <input type="text" id="paid" class="form-control round" [value]="employeeLoan.paid | toDecimal" readonly />
          </div>
          <div class="form-group col-md-6">
            <label for="remaining">{{ 'FINANCES.HR.REMAINING' | translate }}</label>
            <input
              type="text"
              id="remaining"
              class="form-control round"
              [value]="employeeLoan.remaining | toDecimal"
              readonly
            />
          </div>
          <div class="form-group col-md-6">
            <label for="settlementStartDate ">{{ 'FINANCES.HR.SETTLEMENT_START_DATE' | translate }}</label>
            <input
              type="text"
              id="settlementStartDate "
              class="form-control round"
              [value]="employeeLoan.settlementStartDate | date: 'datetime'"
              readonly
            />
          </div>
          <div class="form-group col-md-6">
            <label for="transactionDate">{{ 'FINANCES.JOURNALS.JOURNAL_DATA.JOURNAL_DATE' | translate }}</label>
            <input
              type="text"
              id="transactionDate"
              class="form-control round"
              [value]="employeeLoan.transactionDate | date: 'datetime'"
              readonly
            />
          </div>
          <div class="form-group col-md-6">
            <label for="user">{{ 'FINANCES.JOURNALS.JOURNAL_DATA.USER_CREATE' | translate }}</label>
            <input type="text" id="user" class="form-control round" [value]="employeeLoan.user.name" readonly />
          </div>
          <div class="form-group col-md-12">
            <label for="notes">{{ 'FINANCES.JOURNALS.JOURNAL_DATA.NOTES' | translate }}</label>
            <textarea
              id="notes"
              class="form-control round"
              [innerText]="employeeLoan.notes"
              [placeholder]="'FINANCES.JOURNALS.JOURNAL_DATA.NOTES_NOT_FOUND' | translate"
              readonly
            ></textarea>
          </div>
        </div>
      </div>
    </div>
    <!-- employee loan details ends -->
  </div>
  <!-- journal payroll payments details ends -->
</div>
