<!-- journal petty cash refund request details starts -->
<div class="row">
  <div class="col-md-12">
    <div class="card-header mb-3 pt-0">
      <h4 class="card-title">{{ 'FINANCES.JOURNALS.JOURNAL_DATA.PETTY_CASH_REFUND_REQUEST_DATA' | translate }}</h4>
    </div>
    <div class="card-content">
      <div class="px-3">
        <div class="row">
          <div class="form-group col-md-3">
            <label for="pettyCashId">{{ 'FINANCES.JOURNALS.JOURNAL_DATA.PETTY_CASH_REFUND' | translate }}</label>
            <input
              type="text"
              id="pettyCashId"
              class="form-control round"
              [value]="pettyCashRefundRequest.pettyCash.description"
              readonly
            />
          </div>
          <div class="form-group col-md-3">
            <label for="transactionDate">{{ 'FINANCES.JOURNALS.JOURNAL_DATA.JOURNAL_DATE' | translate }}</label>
            <input
              type="text"
              id="transactionDate"
              class="form-control round"
              [value]="pettyCashRefundRequest.transactionDate | date: 'date'"
              readonly
            />
          </div>
          <div class="form-group col-md-3">
            <label for="createdAt">{{ 'SHARED.SEARCH.CREATED_AT' | translate }}</label>
            <input
              type="text"
              id="createdAt"
              class="form-control round"
              [value]="pettyCashRefundRequest.createdAt | date: 'date'"
              readonly
            />
          </div>
          <div class="form-group col-md-3">
            <label for="user">{{ 'FINANCES.JOURNALS.JOURNAL_DATA.USER_CREATE' | translate }}</label>
            <input
              type="text"
              id="user"
              class="form-control round"
              [value]="pettyCashRefundRequest.user.name"
              readonly
            />
          </div>
          <div class="form-group col-md-12">
            <label for="notes">{{ 'FINANCES.JOURNALS.JOURNAL_DATA.NOTES' | translate }}</label>
            <textarea
              id="notes"
              class="form-control round"
              [innerText]="pettyCashRefundRequest.notes"
              [placeholder]="'FINANCES.JOURNALS.JOURNAL_DATA.NOTES_NOT_FOUND' | translate"
              readonly
            ></textarea>
          </div>

          <!-- petty cash refund request lines details starts -->
          <div class="card-header mb-3 pt-2">
            <h4 class="card-title">{{ 'FINANCES.JOURNALS.JOURNAL_DATA.PETTY_CASH_REFUND_REQUESTS' | translate }}</h4>
          </div>
          <div class="card-content">
            <div class="px-3">
              <div class="row">
                <div class="col-md-12">
                  <div class="table-responsive">
                    <table class="table text-center">
                      <thead>
                        <tr>
                          <th scope="col"><i class="fa fa-list-ol"></i></th>
                          <th scope="col">
                            {{ 'FINANCES.JOURNALS.JOURNAL_DATA.REFUND_DESCRIPTION' | translate }}
                          </th>
                          <th scope="col">
                            {{ 'FINANCES.JOURNALS.JOURNAL_DATA.PETTY_CASH_REFUND_REQUEST_TYPE' | translate }}
                          </th>
                          <th scope="col">
                            <i class="fa fa-user mr-1"></i>
                            {{ 'FINANCES.JOURNALS.JOURNAL_DATA.CUSTOMER' | translate }}
                          </th>
                          <th scope="col">
                            <i class="fa fa-usd mr-1"></i>
                            {{ 'FINANCES.JOURNALS.JOURNAL_DATA.INVOICE_VALUE' | translate }}
                          </th>
                          <th scope="col">
                            <i class="fa fa-percent mr-1"></i>
                            {{ 'FINANCES.JOURNALS.JOURNAL_DATA.PRODUCT_TAX' | translate }}
                          </th>
                          <th scope="col">
                            <i class="fa fa-usd mr-1"></i>
                            {{ 'FINANCES.JOURNALS.JOURNAL_DATA.PRODUCT_TOTAL' | translate }}
                          </th>
                          <th scope="col">
                            <i class="fa fa-calendar mr-1"></i>
                            {{ 'FINANCES.JOURNALS.JOURNAL_DATA.INVOICE_DATE' | translate }}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let line of pettyCashRefundRequest.pettyCashRefundRequestLines; let i = index">
                          <th scope="row">{{ i + 1 }}</th>
                          <td>{{ line.description }}</td>
                          <td>
                            {{
                              line.pettyCashRefundRequestType.name
                                | localize: line.pettyCashRefundRequestType.nameEn
                                | async
                            }}
                          </td>
                          <td>{{ line.customer?.name | localize: line.customer?.nameEn | async }}</td>
                          <td>{{ line.value | toDecimal }}</td>
                          <td>{{ line.tax | toDecimal }}</td>
                          <td>{{ (line.value + (line.tax / 100) * line.value | toDecimal) || '0.0' }}</td>
                          <td>{{ line.invoiceDate }}</td>
                        </tr>
                      </tbody>
                      <tfoot class="tfoot-light">
                        <tr>
                          <td [attr.colspan]="9">
                            <i class="fa fa-bookmark c-primary"></i>
                            <span>
                              {{ pettyCashRefundRequest.pettyCashRefundRequestLines?.length }}
                              {{ 'FINANCES.JOURNALS.JOURNAL_DATA.PETTY_CASH_REQUEST' | translate }}</span
                            >
                          </td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- petty cash refund request lines details ends -->
        </div>
      </div>
    </div>
  </div>
</div>
<!-- journal petty cash refund request details ends -->
