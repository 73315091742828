import { CreatePosDeviceComponent } from 'sales/components/pos-devices/create-pos-device/create-pos-device.component';
import { UpdatePosDeviceComponent } from 'sales/components/pos-devices/update-pos-device/update-pos-device.component';
import { PosDevicesListComponent } from 'sales/components/pos-devices/pos-devices-list/pos-devices-list.component';
import { PosDevicesSearchComponent } from 'sales/components/pos-devices/pos-devices-search/pos-devices-search.component';

export * from 'sales/components/pos-devices/create-pos-device/create-pos-device.component';
export * from 'sales/components/pos-devices/update-pos-device/update-pos-device.component';
export * from 'sales/components/pos-devices/pos-devices-list/pos-devices-list.component';
export * from 'sales/components/pos-devices/pos-devices-search/pos-devices-search.component';

/**
 * The set of components registered on this sub-module.
 */
export const COMPONENTS: any[] = [
  CreatePosDeviceComponent,
  UpdatePosDeviceComponent,
  PosDevicesListComponent,
  PosDevicesSearchComponent,
];
