<div *ngIf="pageInfo" class="row">
  <div class="col-sm-12">
    <div class="content-header text-left">
      <i *ngIf="pageInfo.icon" [ngClass]="[pageInfo.icon]"></i>
      {{ pageInfo.title | translate: pageInfo.titleParams }}
    </div>
    <p class="content-sub-header">
      {{
        pageInfo.subTitleNeedsTranslation ? (pageInfo.subTitle | translate: pageInfo.subTitleParams) : pageInfo.subTitle
      }}
    </p>
  </div>
</div>
