import { Action } from '@ngrx/store';

import { PettyCashSpecification } from 'lookups/models';
import { AppHttpResponse } from 'shared';

/**
 * The Lookups-Module store  petty cash specification action types.
 */
export enum PettyCashSpecificationActionType {
  GET_ALL_PETTY_CASH_SPECIFICATION = '[Lookups] [PettyCashSpecification] Get All Petty Cash Specifications',
  GET_ALL_PETTY_CASH_SPECIFICATION_FAIL = '[Lookups] [PettyCashSpecification] Get All Petty Cash Specifications Fail',
  GET_ALL_PETTY_CASH_SPECIFICATION_SUCCESS = '[Lookups] [PettyCashSpecification] Get All Petty Cash Specifications Success',
}

/**
 * Represents a store  petty cash specification action.
 */
export class GetAllPettyCashSpecification implements Action {
  /**
   * The type of the action.
   */
  readonly type = PettyCashSpecificationActionType.GET_ALL_PETTY_CASH_SPECIFICATION;

  /**
   * Fires a new  petty cash specification action.
   */
  constructor() {}
}

/**
 * Represents a store  petty cash specification fail action.
 */
export class GetAllPettyCashSpecificationFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = PettyCashSpecificationActionType.GET_ALL_PETTY_CASH_SPECIFICATION_FAIL;

  /**
   * Fires a new  petty cash specification fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store  petty cash specification success action.
 */
export class GetAllPettyCashSpecificationSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = PettyCashSpecificationActionType.GET_ALL_PETTY_CASH_SPECIFICATION_SUCCESS;

  /**
   * Fires a  petty cash specification success action.
   * @param payload An object contains the list of  petty cash specifications.
   */
  constructor(public payload: AppHttpResponse<PettyCashSpecification[]>) {}
}

/**
 * Lookups-module  petty cash specification action types.
 */
export type PettyCashSpecificationActions =
  | GetAllPettyCashSpecification
  | GetAllPettyCashSpecificationFail
  | GetAllPettyCashSpecificationSuccess;
