<ng-container [formGroup]="formGroup">
  <th class="fit-width" scope="row">{{ index + 1 }}</th>
  <td>
    {{
      formGroup.value.subProductIngredient.description
        | localize: formGroup.value.subProductIngredient.descriptionEn
        | async
    }}
  </td>
  <td>
    <input
      type="text"
      autocomplete="off"
      class="form-control round"
      [placeholder]="'STORES.PRODUCTS.PRODUCT_DATA.PRODUCT_INGREDIENT_ID' | translate"
      formControlName="quantity"
      [mask]="DECIMAL_MASK"
      thousandSeparator=","
    />
  </td>
  <td>
    {{
      formGroup.value.subProductIngredient.unitOfMeasure.name
        | localize: formGroup.value.subProductIngredient.unitOfMeasure.nameEn
        | async
    }}
  </td>
  <td>
    <a class="danger p-0" [ngbTooltip]="'SHARED.CONFIRM.DELETE' | translate" (click)="remove()">
      <i class="fa fa-close font-medium-3 mr-2"></i>
    </a>
  </td>
</ng-container>
