<!-- page header starts -->
<app-page-header [info]="pageInfo"></app-page-header>
<!-- page header ends -->

<!-- variants list starts -->
<div class="row">
  <div class="col-md-12">
    <div class="card">
      <div class="card-header mb-3">
        <h4 class="card-title">{{ 'SHARED.SEARCH.SEARCH_TOOLS' | translate }}</h4>
      </div>
      <div class="card-content">
        <div class="px-3">
          <div class="row">
            <div class="col-md-12">
              <form class="row" [formGroup]="searchForm" (ngSubmit)="search()">
                <div class="form-group col">
                  <label for="name">
                    {{ 'STORES.PRODUCT_VARIANTS.PRODUCT_VARIANT_DATA.PRODUCT_VARIANT_NAME' | translate }}
                  </label>
                  <input
                    type="text"
                    id="name"
                    class="form-control round"
                    [placeholder]="
                      'STORES.PRODUCT_VARIANTS.PRODUCT_VARIANT_DATA.PRODUCT_VARIANT_NAME_SEARCH_PLACEHOLDER' | translate
                    "
                    formControlName="name"
                    (input)="search($event)"
                  />
                </div>
                <div class="form-group col">
                  <label for="categories">{{
                    'STORES.PRODUCT_VARIANTS.PRODUCT_VARIANT_DATA.VARIANT_CATEGORIES_NAME' | translate
                  }}</label>
                  <ng-select
                    class="round"
                    formControlName="categories"
                    [multiple]="true"
                    [closeOnSelect]="false"
                    labelForId="categories"
                    searchable="true"
                    [placeholder]="
                      'STORES.PRODUCT_VARIANTS.PRODUCT_VARIANT_DATA.VARIANT_CATEGORIES_SEARCH_PLACEHOLDER' | translate
                    "
                    [notFoundText]="'SHARED.SEARCH.SEARCH_NOT_FOUND' | translate"
                  >
                    <ng-option *ngFor="let variantCategory of variantCategories$ | async" [value]="variantCategory.id">
                      {{ variantCategory.name | localize: variantCategory.nameEn | async }}
                    </ng-option>
                  </ng-select>
                </div>
                <div class="col-md-auto form-group d-flex">
                  <button
                    type="submit"
                    class="btn btn-raised btn-primary align-self-end round mx-1 mb-0"
                    [disabled]="isSearching$ | async"
                  >
                    <i class="fa fa-search"></i>
                  </button>
                  <a
                    *ngIf="[Claims.CREATE_VARIANT] | authorize | async"
                    routerLink="/stores/variants/create"
                    class="btn btn-raised btn-success align-self-end round mx-1 mb-0"
                  >
                    <i class="fa fa-plus"></i>
                    <span class="mx-1"> {{ 'SHARED.CONFIRM.ADD' | translate }} </span>
                  </a>
                  <app-export-tools
                    *ngIf="(variants$ | async)?.length"
                    class="d-flex align-self-end mx-1"
                    targetElementId="variants-table-export-target-element"
                    [outputFileName]="'SHARED.EXPORT_TABLES.PRODUCT_VARIANTS_TABLE' | translate"
                  ></app-export-tools>
                </div>
              </form>
            </div>
            <div class="col-md-12" *ngIf="(variants$ | async)?.length; else noItems">
              <div class="row">
                <div class="col-md-12 alert alert-light text-center" role="alert">
                  {{ 'SHARED.SEARCH.SEARCH_RESULTS' | translate }} ({{ (paginationInfo$ | async).total | toNumber }})
                  {{ 'STORES.PRODUCT_VARIANTS.PRODUCT_VARIANT_DATA.PRODUCT_VARIANT' | translate }}
                </div>
                <div class="col-md-12">
                  <div class="table-responsive">
                    <table class="table text-center" id="variants-table-export-target-element">
                      <thead>
                        <tr>
                          <th scope="col"><i class="fa fa-list-ol"></i></th>
                          <th scope="col">
                            {{ 'STORES.PRODUCT_VARIANTS.PRODUCT_VARIANT_DATA.PRODUCT_VARIANT' | translate }}
                          </th>
                          <th scope="col">
                            {{ 'STORES.PRODUCT_VARIANTS.PRODUCT_VARIANT_DATA.VARIANT_CATEGORY_NAME' | translate }}
                          </th>
                          <th scope="col">{{ 'SHARED.TOOLS' | translate }}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let productVariant of variants$ | async; trackBy: trackItems; let i = index">
                          <th scope="row">{{ i + 1 }}</th>
                          <td>{{ productVariant.name | localize: productVariant.nameEn | async }}</td>
                          <td>
                            {{
                              productVariant.variantCategory.name
                                | localize: productVariant.variantCategory.nameEn
                                | async
                            }}
                          </td>

                          <td>
                            <a
                              *ngIf="[Claims.UPDATE_VARIANT] | authorize | async"
                              class="info p-0"
                              [routerLink]="['/stores/variants/update', productVariant.id]"
                              [ngbTooltip]="
                                'STORES.PRODUCT_VARIANTS.PRODUCT_VARIANT_DATA.PRODUCT_VARIANT_UPDATE' | translate
                              "
                            >
                              <i class="fa fa-edit font-medium-3 mr-2"></i>
                            </a>
                            <a
                              *ngIf="[Claims.DELETE_VARIANT] | authorize | async"
                              class="danger p-0"
                              [ngbTooltip]="
                                'STORES.PRODUCT_VARIANTS.PRODUCT_VARIANT_DATA.PRODUCT_VARIANT_DELETE' | translate
                              "
                              (click)="selectedVariant = productVariant; openModal(deleteModalRef)"
                            >
                              <i class="fa fa-close font-medium-3 mr-2"></i>
                            </a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div class="d-flex justify-content-between p-2">
                      <ngb-pagination
                        *ngIf="paginationInfo$ | async; let pagination"
                        maxSize="5"
                        [collectionSize]="pagination.total"
                        [pageSize]="pagination.pageSize"
                        [page]="pagination.page"
                        (pageChange)="pageChanged($event)"
                        boundaryLinks="true"
                      >
                        <ng-template ngbPaginationFirst>{{
                          'SHARED.PAGINATION.PAGINATION_FIRST' | translate
                        }}</ng-template>
                        <ng-template ngbPaginationPrevious>{{
                          'SHARED.PAGINATION.PAGINATION_PREVIOUS' | translate
                        }}</ng-template>
                        <ng-template ngbPaginationNext>{{
                          'SHARED.PAGINATION.PAGINATION_NEXT' | translate
                        }}</ng-template>
                        <ng-template ngbPaginationLast>{{
                          'SHARED.PAGINATION.PAGINATION_LAST' | translate
                        }}</ng-template>
                      </ngb-pagination>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- variants list ends -->

<!-- no items template starts -->
<ng-template #noItems>
  <div class="col-md-12 alert alert-warning mt-3 text-center" role="alert">
    {{ 'STORES.PRODUCT_VARIANTS.SEARCH_RESULT_PRODUCT_VARIANT_NOT_FOUND' | translate }}
    <a
      *ngIf="[Claims.CREATE_VARIANT] | authorize | async"
      routerLink="/stores/variants/create"
      class="btn btn-raised btn-success round"
    >
      <i class="fa fa-plus"></i>
      <span class="mx-1"> {{ 'SHARED.CONFIRM.ADD' | translate }} </span>
    </a>
  </div>
</ng-template>
<!-- no items template ends -->

<!-- delete product variant modal starts -->
<ng-template #deleteModalRef let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title text-danger" id="modal-basic-title">
      {{ 'STORES.PRODUCT_VARIANTS.CONFIRM_DELETE_PRODUCT_VARIANT_TITLE' | translate }}
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="d()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-12">
        <p class="text-danger">{{ 'STORES.PRODUCT_VARIANTS.CONFIRM_DELETE_PRODUCT_VARIANT_MESSAGE' | translate }}</p>
      </div>
      <div class="form-group col-12">
        <label for="selectedVariant.name">{{
          'STORES.PRODUCT_VARIANTS.PRODUCT_VARIANT_DATA.PRODUCT_VARIANT_NAME' | translate
        }}</label>
        <input
          type="text"
          id="selectedVariant.name"
          class="form-control round"
          [placeholder]="
            'STORES.PRODUCT_VARIANTS.PRODUCT_VARIANT_DATA.PRODUCT_VARIANT_NAME_SEARCH_PLACEHOLDER' | translate
          "
          readonly
          [value]="selectedVariant.name | localize: selectedVariant.nameEn | async"
        />
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="modal-actions">
      <button
        type="button"
        class="btn btn-raised btn-danger round"
        (click)="c(); delete(selectedVariant.id)"
        ngbAutofocus
        [disabled]="isDeleting$ | async"
      >
        <i class="fa fa-check"></i>
        <span class="mx-1"> {{ 'SHARED.CONFIRM.DELETE' | translate }} </span>
      </button>
      <button type="button" class="btn btn-outline-dark round" (click)="c()">
        <i class="fa fa-times"></i>
        <span class="mx-1"> {{ 'SHARED.CONFIRM.CANCEL' | translate }} </span>
      </button>
    </div>
  </div>
</ng-template>
<!-- delete product variant modal ends -->
