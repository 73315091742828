import { Component, OnInit } from '@angular/core';
import { Location as AngularLocation } from '@angular/common';

import { PageInfo } from 'shared';
import { ActivatedRoute } from '@angular/router';

/**
 * The leads list component pages.
 */
enum PAGES {
  'pos-devices' = 'pos-devices',
  'pos-printers' = 'pos-printers',
  'kitchen-printers' = 'kitchen-printers',
  'casher-printers' = 'casher-printers',
  'order-delivery-printers' = 'order-delivery-printers',
}
@Component({
  selector: 'app-pos-home',
  templateUrl: './pos-home.component.html',
  styles: [],
})
export class PosHomeComponent implements OnInit {
  /**
   * Gets or sets the information about the current page.
   */
  pageInfo: PageInfo = {
    title: 'SALES.POS_DEVICES.POS_TITLE',
    icon: 'fa fa-building',
  };

  /**
   * Gets or sets the selected page.
   * @default 'listView'
   */
  activePage: PAGES = PAGES['pos-devices'];

  /**
   * @param modalService The modal service.
   * @param salesStore$ The stores store.
   * @param authStore$ the auth-store module.
   * @param route The activated route.
   * @param lookupsStore$ The lookups-module store.
   * @param locationService The location service.
   */
  constructor(private route: ActivatedRoute, private locationService: AngularLocation) {}
  ngOnInit(): void {
    this.init();
  }

  /**
   * Initialize component data.
   */
  init() {
    this.activePage = PAGES[this.route.snapshot.fragment] ?? this.activePage;
    this.selectedPageChanged(this.activePage);
  }

  /**
   * Updates the browser url according to the selected page.
   * @param page The newly selected page.
   */
  selectedPageChanged(page: PAGES) {
    this.locationService.replaceState(`${this.locationService.path()}#${PAGES[page]}`);
  }
}
