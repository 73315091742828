import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';

import { AppHttpResponse } from 'shared';
import { Employee } from 'hr/models';

/**
 * The employees services includes functionality to search employees.
 */
@Injectable()
export class EmployeesService {
  /**
   * The relative route for the employees.
   *
   * No leading or trailing slashes required.
   */
  private employeesApi = 'hr/employees';

  constructor(private http: HttpClient) {}

  /**
   * Searches in the employees by Arabic or English name.
   * @param name The Arabic or English name of the employee.
   * @param locations The locations of the employee.
   * @param page The current pagination page number.
   * @param pageSize The maximum number of employees allowed per one pagination page.
   */
  public search(
    name: string,
    locations: number[],
    page: number,
    pageSize: number
  ): Observable<AppHttpResponse<Employee[]>> {
    const params = new HttpParams()
      .set('name', name)
      .set('locations', locations.join(','))
      .set('page', page.toString())
      .set('pageSize', pageSize.toString());
    return this.http.get<AppHttpResponse<Employee[]>>(`${this.employeesApi}`, { params });
  }

  /**
   * Searches in the sales person by Arabic or English name.
   * @param name The Arabic or English name of the sales person.
   * @param locations The locations of the employee.
   * @param page The current pagination page number.
   * @param pageSize The maximum number of sales person allowed per one pagination page.
   */
  public salesPersonSearch(
    name: string,
    locations: number[],
    page: number,
    pageSize: number
  ): Observable<AppHttpResponse<Employee[]>> {
    const params = new HttpParams()
      .set('name', name)
      .set('locations', locations.join(','))
      .set('page', page.toString())
      .set('pageSize', pageSize.toString());
    return this.http.get<AppHttpResponse<Employee[]>>(`${this.employeesApi}/sales-persons`, { params });
  }
}
