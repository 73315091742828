import { findIndex, remove } from 'lodash';

import { CurrenciesActionType, CurrenciesActions } from 'finances/store/actions';
import { CurrenciesState } from 'finances/store/states';

/**
 * The initial state from which the state starts.
 */
const initialState: CurrenciesState = {
  data: [],
  paginationInfo: {
    total: 0,
    page: 1,
    pageSize: 0,
    count: 0,
  },
  error: null,
  isSearching: false,
  isSearchCompleted: false,
  selectedCurrency: null,
  isFinding: false,
  isFindCompleted: false,
  primaryCurrency: null,
  isPrimaryCurrencyFinding: false,
  isPrimaryCurrencyFindCompleted: false,
  isCreating: false,
  isCreateCompleted: false,
  isUpdating: false,
  isUpdateCompleted: false,
  isDeleting: false,
  isDeleteCompleted: false,
};

/**
 * The reducer function that is called in each action dispatch against the state.
 * @param state The current state.
 * @param action The action that will affect the state.
 */
export function currenciesReducer(state: CurrenciesState = initialState, action: CurrenciesActions): CurrenciesState {
  switch (action.type) {
    //#region SEARCH_CURRENCIES

    case CurrenciesActionType.SEARCH_CURRENCIES: {
      return {
        ...state,
        isSearching: true,
        isSearchCompleted: false,
        error: null,
      };
    }

    case CurrenciesActionType.SEARCH_CURRENCIES_FAIL: {
      return {
        ...state,
        error: action.payload,
        isSearching: false,
        isSearchCompleted: false,
      };
    }

    case CurrenciesActionType.SEARCH_CURRENCIES_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        paginationInfo: {
          ...action.payload.meta,
        },
        error: null,
        isSearching: false,
        isSearchCompleted: true,
      };
    }

    //#endregion SEARCH_CURRENCIES

    //#region FIND_CURRENCY

    case CurrenciesActionType.FIND_CURRENCY: {
      return {
        ...state,
        selectedCurrency: null,
        error: null,
        isFinding: true,
        isFindCompleted: false,
      };
    }

    case CurrenciesActionType.FIND_CURRENCY_FAIL: {
      return {
        ...state,
        error: action.payload,
        isFinding: false,
        isFindCompleted: false,
      };
    }

    case CurrenciesActionType.FIND_CURRENCY_SUCCESS: {
      let currencies = [...state.data];
      const currencyIndex = findIndex(currencies, (currency) => currency.id === action.payload.data.id);

      /* If currency was found, update it. */
      if (currencyIndex >= 0) {
        currencies[currencyIndex] = action.payload.data;
      } else {
        /* else, insert it to the beginning of the array. */
        currencies = [action.payload.data, ...currencies];
      }

      return {
        ...state,
        data: currencies,
        selectedCurrency: action.payload.data,
        error: null,
        isFinding: false,
        isFindCompleted: true,
      };
    }

    //#endregion FIND_CURRENCY

    //#region FIND_PRIMARY_CURRENCY

    case CurrenciesActionType.FIND_PRIMARY_CURRENCY: {
      return {
        ...state,
        primaryCurrency: null,
        error: null,
        isPrimaryCurrencyFinding: true,
        isPrimaryCurrencyFindCompleted: false,
      };
    }

    case CurrenciesActionType.FIND_PRIMARY_CURRENCY_FAIL: {
      return {
        ...state,
        error: action.payload,
        isPrimaryCurrencyFinding: false,
        isPrimaryCurrencyFindCompleted: false,
      };
    }

    case CurrenciesActionType.FIND_PRIMARY_CURRENCY_SUCCESS: {
      let currencies = [...state.data];
      const currencyIndex = findIndex(currencies, (currency) => currency.id === action.payload.data.id);

      /* If currency was found, update it. */
      if (currencyIndex >= 0) {
        currencies[currencyIndex] = action.payload.data;
      } else {
        /* else, insert it to the beginning of the array. */
        currencies = [action.payload.data, ...currencies];
      }

      return {
        ...state,
        data: currencies,
        primaryCurrency: action.payload.data,
        error: null,
        isPrimaryCurrencyFinding: false,
        isPrimaryCurrencyFindCompleted: true,
      };
    }

    //#endregion FIND_PRIMARY_CURRENCY

    //#region CREATE_CURRENCY

    case CurrenciesActionType.CREATE_CURRENCY: {
      return {
        ...state,
        isCreating: true,
        isCreateCompleted: false,
        error: null,
      };
    }

    case CurrenciesActionType.CREATE_CURRENCY_FAIL: {
      return {
        ...state,
        error: action.payload,
        isCreating: false,
        isCreateCompleted: false,
      };
    }

    case CurrenciesActionType.CREATE_CURRENCY_SUCCESS: {
      let currencies = [...state.data];
      const currencyIndex = findIndex(currencies, (currency) => currency.id === action.payload.data.id);

      /**
       * Make sure to update the current default currency `isPrimary` property.
       */
      const currentPrimaryCurrencyIndex = currencies.findIndex(
        (currency) => currency.id !== action.payload.data.id && currency.isPrimary
      );

      if (currentPrimaryCurrencyIndex >= 0) {
        currencies[currentPrimaryCurrencyIndex] = {
          ...currencies[currentPrimaryCurrencyIndex],
          isPrimary: false,
        };
      }
      /* If currency was found, update it. */
      if (currencyIndex >= 0) {
        currencies[currencyIndex] = action.payload.data;
      } else {
        /* else, insert it to the beginning of the array. */
        currencies = [action.payload.data, ...currencies];
      }
      return {
        ...state,
        data: currencies,
        selectedCurrency: action.payload.data,
        error: null,
        isCreating: false,
        isCreateCompleted: true,
      };
    }

    //#endregion CREATE_CURRENCY

    //#region UPDATE_CURRENCY

    case CurrenciesActionType.UPDATE_CURRENCY: {
      return {
        ...state,
        isUpdating: true,
        isUpdateCompleted: false,
        error: null,
      };
    }

    case CurrenciesActionType.UPDATE_CURRENCY_FAIL: {
      return {
        ...state,
        error: action.payload,
        isUpdating: false,
        isUpdateCompleted: false,
      };
    }

    case CurrenciesActionType.UPDATE_CURRENCY_SUCCESS: {
      let currencies = [...state.data];
      const currencyIndex = findIndex(currencies, (currency) => currency.id === action.payload.data.id);

      /**
       * Make sure to update the current primary currency `isPrimary` property.
       */
      const currentPrimaryCurrencyIndex = currencies.findIndex(
        (currency) => currency.id !== action.payload.data.id && currency.isPrimary
      );

      if (currentPrimaryCurrencyIndex >= 0) {
        currencies[currentPrimaryCurrencyIndex] = {
          ...currencies[currentPrimaryCurrencyIndex],
          isPrimary: false,
        };
      }

      /* If currency was found, update it. */
      if (currencyIndex >= 0) {
        currencies[currencyIndex] = action.payload.data;
      } else {
        /* else, insert it to the beginning of the array. */
        currencies = [action.payload.data, ...currencies];
      }

      return {
        ...state,
        data: currencies,
        selectedCurrency: action.payload.data,
        error: null,
        isUpdating: false,
        isUpdateCompleted: true,
      };
    }

    //#endregion UPDATE_CURRENCY

    //#region DELETE_CURRENCY

    case CurrenciesActionType.DELETE_CURRENCY: {
      return {
        ...state,
        isDeleting: true,
        isDeleteCompleted: false,
        error: null,
      };
    }

    case CurrenciesActionType.DELETE_CURRENCY_FAIL: {
      return {
        ...state,
        error: action.payload,
        isDeleting: false,
        isDeleteCompleted: false,
      };
    }

    case CurrenciesActionType.DELETE_CURRENCY_SUCCESS: {
      const currencies = [...state.data];

      /* Remove the deleted currency from the array. */
      remove(currencies, (currency) => currency.id === action.payload.data.id);

      return {
        ...state,
        data: currencies,
        selectedCurrency: action.payload.data,
        error: null,
        isDeleting: false,
        isDeleteCompleted: true,
      };
    }

    //#endregion DELETE_CURRENCY

    default:
      return state;
  }
}
