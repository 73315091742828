import { Component, OnInit, ViewChild, ElementRef, Input, OnDestroy } from '@angular/core';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { skip, tap } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';

import * as fromSecurityStore from 'security/store';
import * as fromAuthStore from 'auth/store';
import { User } from 'security/models';
import { FormArray, FormGroup } from '@angular/forms';
import { CostCenter, CostCenterFormItem } from 'finances/models';

@Component({
  selector: 'app-update-user-cost-center',
  templateUrl: './update-user-cost-center.component.html',
  styles: [],
})
export class UpdateUserCostCenterComponent implements OnInit, OnDestroy {
  /**
   * The cost centers confirm modal template reference.
   */
  @ViewChild('costCentersConfirmModalRef') costCentersConfirmModalRef: ElementRef<any>;

  /**
   * Gets or sets the id of the current edited user.
   */
  @Input() userId: number;

  /**
   * The current logged in user.
   */
  currentLoggedInUser: User;

  /**
   * The list of user cost centers.
   */
  costCenters$: Observable<CostCenter[]>;

  /**
   * Indicates whether there is a update user process is running or not.
   */
  isUpdating$: Observable<boolean>;

  /**
   * The update user form.
   */
  form: FormGroup;

  /**
   * Sets the initial costCenters should be added to the costCenters form.
   * @param costCenters An object contains `count` of initial blank costCenters.
   * @param costCenters A single service details to be added to the form.
   * @param costCenters A list of costCenters details to be added to the form.
   */
  initialCostCentersSubject: BehaviorSubject<
    { count: number } | CostCenterFormItem | CostCenterFormItem[]
  > = new BehaviorSubject(undefined);

  /**
   * Gets the cost centers form-array.
   */
  get costCentersForm(): FormArray {
    return this.form?.controls.costCenters as FormArray;
  }

  /**
   * Used to add new cost center form items to the cost centers form.
   */
  newCostCenters: CostCenterFormItem[];

  /**
   * The set of subscriptions on this components,
   * these subscriptions must be unsubscribed before this component got destroyed.
   */
  subscriptions = new Subscription();

  /**
   * @param modalService The modal service.
   * @param securityStore$ The Security-module store.
   * @param authStore$ The Auth-module store.
   */
  constructor(
    private modalService: NgbModal,
    private securityStore$: Store<fromSecurityStore.SecurityState>,
    private authStore$: Store<fromAuthStore.AuthState>
  ) {}

  ngOnInit(): void {
    this.init();
  }

  /**
   * Unsubscribes for component data.
   */
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  /**
   * Initialize component data.
   */
  init() {
    this.initForm();

    /* Load the selected user cost centers */
    this.costCenters$ = this.securityStore$.pipe(
      select(fromSecurityStore.getSelectedUserCostCenters),

      tap((userCostCenters) => {
        if (userCostCenters) {
          this.form.patchValue(userCostCenters);
          this.costCentersForm.clear();
          this.newCostCenters = userCostCenters.map((costCenter: CostCenter) => ({
            costCenterId: costCenter.id,
            costCenter,
          }));
        }
      })
    );

    /**
     * Load data.
     */
    this.securityStore$.dispatch(new fromSecurityStore.GetUserCostCenters(this.userId));

    this.isUpdating$ = this.securityStore$.pipe(select(fromSecurityStore.getSelectedUserCostCenterUpdating));

    /**
     * rest form on creation complete
     */
    this.subscriptions.add(
      this.securityStore$
        .pipe(
          select(fromSecurityStore.getSelectedUserUpdateCompleted),
          skip(1),
          tap((updated) => {
            if (updated) {
              this.initForm();
              this.closeModal();
            }
          })
        )
        .subscribe()
    );

    /** Load current logged in user data. */
    this.subscriptions.add(
      this.authStore$
        .pipe(
          select(fromAuthStore.getSessionUser),
          tap((user: User) => {
            if (user) {
              this.currentLoggedInUser = user;
            }
          })
        )
        .subscribe()
    );
  }

  initForm() {
    this.form = new FormGroup({
      costCenters: new FormArray([]),
    });
  }

  /**
   * Submits the cost centers form.
   */
  submit() {
    this.openModal(this.costCentersConfirmModalRef);
  }

  /**
   * Confirms the the cost centers form submit.
   */
  confirm() {
    /**
     * The list of cost centers in the cost centers form.
     */
    const costCenters = this.costCentersForm.value.map((item: CostCenterFormItem) => item.costCenterId);

    this.securityStore$.dispatch(
      new fromSecurityStore.UpdateUserCostCenter({
        id: this.userId,
        costCenters,
      })
    );
  }

  /**
   * Opens the modal of the given templateRef.
   * @param modalRef The modal templateRef to be opened.
   */
  openModal(modalRef) {
    this.modalService.open(modalRef);
  }

  /**
   * Closes the currently opened modal.
   */
  closeModal() {
    this.modalService.dismissAll();
  }
}
