import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';

import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

import { NotificationService, PaginationInfo, TranslationService } from 'shared';
import * as fromFinancesStore from 'finances/store';
import * as fromLookupsStore from 'lookups/store';
import { PettyCashType, PettyCashSpecification } from 'lookups/models';
import { PettyCash } from 'finances/models';

@Component({
  selector: 'app-petty-cash-search',
  templateUrl: './petty-cash-search.component.html',
  styles: [],
})
export class PettyCashSearchComponent implements OnInit {
  /**
   * The modal template reference.
   */
  @ViewChild('modalRef') modalRef: ElementRef<any>;

  /**
   * Indicates whether the modal will automatically close after select the first product.
   *
   * Be default it will not be closed.
   */
  @Input() closeOnSelect = false;

  /**
   * Indicates whether the products displayed on the list should be all active.
   *
   * Be default it will not be closed.
   */
  @Input() onlyActive = false;

  /**
   * Sets the visibility of this component modal.
   *
   * Handles visibility change in two-data-binding approach.
   */
  @Input() set visible(isShown: boolean) {
    if (isShown && this.modalRef) {
      this.openModal(this.modalRef);
    } else {
      this.closeModal();
    }

    this.visibleChange.emit(isShown);
  }

  /**
   * Outputs a value that indicates whether the modal is shown or not.
   *
   * Handles visibility change in two-data-binding approach.
   */
  @Output() visibleChange = new EventEmitter<boolean>();

  /** Outputs the selected petty cash. */
  @Output() pettyCash = new EventEmitter<PettyCash[]>();

  /**
   * The list of petty Cash.
   */
  pettyCash$: Observable<PettyCash[]>;

  /**
   * The list of petty Cash.
   */
  pettyCashList: PettyCash[];

  /**
   * The list of petty cash types.
   */
  pettyCashTypes$: Observable<PettyCashType[]>;

  /**
   * The list of petty cash specifications.
   */
  pettyCashSpecifications$: Observable<PettyCashSpecification[]>;

  /**
   * The pagination info.
   */
  paginationInfo$: Observable<PaginationInfo>;

  /**
   * Indicates whether there is a search process is running or not.
   */
  isSearching$: Observable<boolean>;

  /**
   * The search form.
   */
  searchForm: FormGroup;

  /**
   * The ngb-modal reference to use it when modal needed to be closed.
   */
  modal: NgbModalRef;

  /**
   * @param modalService The modal service.
   * @param notificationService The notification service.
   * @param financesStore$ The finances store.
   * @param lookupsStore$ The lookups store.
   * @param translationService The translation service.
   */
  constructor(
    private modalService: NgbModal,
    private notificationService: NotificationService,
    private financesStore$: Store<fromFinancesStore.FinancesState>,
    private lookupsStore$: Store<fromLookupsStore.LookupsState>,
    private translationService: TranslationService
  ) {}

  ngOnInit(): void {
    this.init();
  }

  /**
   * Initialize component data.
   */
  init() {
    this.initForm();

    /**
     * Load data.
     */
    this.isSearching$ = this.financesStore$.pipe(select(fromFinancesStore.getPettyCashSearching));

    let isManualSearchTriggeredBeforeForPettyCash = false;
    this.pettyCash$ = this.financesStore$.pipe(
      select(fromFinancesStore.getPettyCash),
      tap((pettyCashList) => {
        if (pettyCashList) {
          this.pettyCashList = pettyCashList;
        }
        if (!isManualSearchTriggeredBeforeForPettyCash && !pettyCashList.length) {
          isManualSearchTriggeredBeforeForPettyCash = true;
          this.search();
        }
      })
    );

    let isManualSearchTriggeredBeforeForPettyCashTypes = false;
    this.pettyCashTypes$ = this.lookupsStore$.pipe(
      select(fromLookupsStore.getPettyCashTypes),
      tap((pettyCashTypes) => {
        if (!isManualSearchTriggeredBeforeForPettyCashTypes && !pettyCashTypes.length) {
          isManualSearchTriggeredBeforeForPettyCashTypes = true;
          this.lookupsStore$.dispatch(new fromLookupsStore.GetAllPettyCashType());
        }
      })
    );

    let isManualSearchTriggeredBeforeForPettyCashSpecifications = false;
    this.pettyCashSpecifications$ = this.lookupsStore$.pipe(
      select(fromLookupsStore.getPettyCashSpecifications),
      tap((pettyCashSpecifications) => {
        if (!isManualSearchTriggeredBeforeForPettyCashSpecifications && !pettyCashSpecifications.length) {
          isManualSearchTriggeredBeforeForPettyCashSpecifications = true;
          this.lookupsStore$.dispatch(new fromLookupsStore.GetAllPettyCashSpecification());
        }
      })
    );

    this.paginationInfo$ = this.financesStore$.pipe(select(fromFinancesStore.getPettyCashPaginationInfo));
  }

  /**
   * Initialize form and add validators.
   */
  initForm() {
    this.searchForm = new FormGroup({
      description: new FormControl(''),
      types: new FormControl([]),
      specifications: new FormControl([]),
      page: new FormControl(1),
    });
  }

  /**
   * Handles search parameters change.
   */
  search(event?: KeyboardEvent) {
    if (event && event.key === 'Enter') {
      event.preventDefault();
      return;
    }

    this.applyFiltersAndSearch();
  }

  /**
   * Handles pagination page-changed event.
   * @param page The current selected page number.
   */
  pageChanged(page: number) {
    /** Update pagination page. */
    this.searchForm.patchValue({ page });

    this.applyFiltersAndSearch(page);
  }

  /**
   * Applies the search filters and dispatches a search action.
   * @param page The current page number, default to `1`.
   */
  applyFiltersAndSearch(page: number = 1) {
    this.financesStore$.dispatch(
      new fromFinancesStore.SearchPettyCash({
        description: this.searchForm.get('description').value,
        types: this.searchForm.get('types').value,
        specifications: this.searchForm.get('specifications').value,
        page,
      })
    );
  }

  /**
   * Enables trackBy feature for *ngFor utility to track items
   * depending on a comparer to reduce DOM change.
   * If the item is already exist then no HTML will change, only bounded values.
   * @param index The index of the item.
   * @param pettyCash The item to determine if it was changed or not.
   */
  trackItems(index: number, pettyCash: PettyCash) {
    return pettyCash ? pettyCash.id : undefined;
  }

  /**
   * Handles petty cash selected event.
   * @param petty cash The list of petty cash to notify selected.
   */
  selectPettyCash(pettyCash: PettyCash[]) {
    this.notificationService.successInstant(
      this.translationService.translate('FINANCES.PETTY_CASH.NOTIFICATION_MESSAGE.PETTY_CASH_SELECTED')
    );
    this.pettyCash.emit(pettyCash);
    if (this.closeOnSelect) {
      this.closeModal();
    }
  }

  /**
   * Opens the modal of the given templateRef.
   * @param modalRef The modal templateRef to be opened.
   */
  openModal(modalRef) {
    this.modal = this.modalService.open(modalRef, { size: 'xl' });
    this.modal.result.then(null, () => this.closeModal());
  }

  /**
   * Closes the currently opened modal.
   */
  closeModal() {
    this.modal?.dismiss();
    this.pettyCash.emit([]);
    this.visibleChange.emit(false);
  }
}
