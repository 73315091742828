import { findIndex } from 'lodash';

import { journalsActions, JournalsActionType } from 'finances/store/actions';
import { JournalsState } from 'finances/store/states';

/**
 * The initial state from which the state starts.
 */
const initialState: JournalsState = {
  data: [],
  paginationInfo: {
    total: 0,
    page: 1,
    pageSize: 0,
    count: 0,
  },
  error: null,
  isSearching: false,
  isSearchCompleted: false,
  selectedJournal: null,
  isFinding: false,
  isFindCompleted: false,
  isCreating: false,
  isCreateCompleted: false,
};

/**
 * The reducer function that is called in each action dispatch against the state.
 * @param state The current state.
 * @param action The action that will affect the state.
 */
export function journalsReducer(state: JournalsState = initialState, action: journalsActions): JournalsState {
  switch (action.type) {
    //#region SEARCH_JOURNALS

    case JournalsActionType.SEARCH_JOURNALS: {
      return {
        ...state,
        isSearching: true,
        isSearchCompleted: false,
        error: null,
      };
    }

    case JournalsActionType.SEARCH_JOURNALS_FAIL: {
      return {
        ...state,
        error: action.payload,
        isSearching: false,
        isSearchCompleted: false,
      };
    }

    case JournalsActionType.SEARCH_JOURNALS_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        paginationInfo: {
          ...action.payload.meta,
        },
        error: null,
        isSearching: false,
        isSearchCompleted: true,
      };
    }

    //#endregion SEARCH_JOURNALS

    //#region FIND_JOURNAL

    case JournalsActionType.FIND_JOURNAL: {
      return {
        ...state,
        selectedJournal: null,
        error: null,
        isFinding: true,
        isFindCompleted: false,
      };
    }

    case JournalsActionType.FIND_JOURNAL_FAIL: {
      return {
        ...state,
        error: action.payload,
        isFinding: false,
        isFindCompleted: false,
      };
    }

    case JournalsActionType.FIND_JOURNAL_SUCCESS: {
      let accounts = [...state.data];
      const accountIndex = findIndex(accounts, (account) => account.id === action.payload.data.id);

      /* If account was found, update it. */
      if (accountIndex >= 0) {
        accounts[accountIndex] = action.payload.data;
      } else {
        /* else, insert it to the beginning of the array. */
        accounts = [action.payload.data, ...accounts];
      }

      return {
        ...state,
        data: accounts,
        selectedJournal: action.payload.data,
        error: null,
        isFinding: false,
        isFindCompleted: true,
      };
    }

    //#endregion FIND_JOURNAL

    //#region CREATE_JOURNAL

    case JournalsActionType.CREATE_JOURNAL: {
      return {
        ...state,
        isCreating: true,
        isCreateCompleted: false,
        error: null,
      };
    }

    case JournalsActionType.CREATE_JOURNAL_FAIL: {
      return {
        ...state,
        error: action.payload,
        isCreating: false,
        isCreateCompleted: false,
      };
    }

    case JournalsActionType.CREATE_JOURNAL_SUCCESS: {
      return {
        ...state,
        data: [action.payload.data],
        error: null,
        isCreating: false,
        isCreateCompleted: true,
      };
    }

    //#endregion CREATE_JOURNAL

    default:
      return state;
  }
}
