import { createSelector } from '@ngrx/store';

import { AccountsState, FinancesState } from 'finances/store/states';
import { selectFinancesState } from 'finances/store/selectors/feature.selectors';

/**
 * Gets the accounts state.
 */
const selectAccountState = createSelector(selectFinancesState, (state: FinancesState) => state.accounts);

/**
 * Gets the list of loaded accounts.
 */
export const getAccounts = createSelector(selectAccountState, (state: AccountsState) => state.data);

/**
 * Gets the accounts pagination info.
 */
export const getAccountsPaginationInfo = createSelector(
  selectAccountState,
  (state: AccountsState) => state.paginationInfo
);

/**
 * Gets the error that occurred in the state.
 */
export const getAccountsError = createSelector(selectAccountState, (state: AccountsState) => state.error);

/**
 * Determines if there is a running search process.
 */
export const getAccountsSearching = createSelector(selectAccountState, (state: AccountsState) => state.isSearching);

/**
 * Determines if the last search process has been ended successfully.
 */
export const getAccountsSearchCompleted = createSelector(
  selectAccountState,
  (state: AccountsState) => state.isSearchCompleted
);

/**
 * Determines if there is a running search process.
 */
export const getNonParentAccountsSearching = createSelector(
  selectAccountState,
  (state: AccountsState) => state.isRegularAccountsSearching
);

/**
 * Determines if the last search process has been ended successfully.
 */
export const getNonParentAccountsSearchCompleted = createSelector(
  selectAccountState,
  (state: AccountsState) => state.isNonParentAccountsSearchCompleted
);

/**
 * Gets the selected account.
 */
export const getSelectedAccount = createSelector(selectAccountState, (state: AccountsState) => state.selectedAccount);

/**
 * Determines if there is a running find account process.
 */
export const getAccountsFinding = createSelector(selectAccountState, (state: AccountsState) => state.isFinding);

/**
 * Determines if the last find account process has been ended successfully.
 */
export const getAccountsFindCompleted = createSelector(
  selectAccountState,
  (state: AccountsState) => state.isFindCompleted
);

/**
 * Determines if there is a running create process.
 */
export const getSelectedAccountCreating = createSelector(
  selectAccountState,
  (state: AccountsState) => state.isCreating
);

/**
 * Determines if the last create process has been ended successfully.
 */
export const getSelectedAccountCreateCompleted = createSelector(
  selectAccountState,
  (state: AccountsState) => state.isCreateCompleted
);

/**
 * Determines if there is a running create secondary account process.
 */
export const getSelectedSecondaryAccountCreating = createSelector(
  selectAccountState,
  (state: AccountsState) => state.isSecondaryAccountCreating
);

/**
 * Determines if the last create secondary account process has been ended successfully.
 */
export const getSelectedSecondaryAccountCreateCompleted = createSelector(
  selectAccountState,
  (state: AccountsState) => state.isSecondaryAccountCreateCompleted
);

/**
 * Determines if there is a running update process.
 */
export const getSelectedAccountUpdating = createSelector(
  selectAccountState,
  (state: AccountsState) => state.isUpdating
);

/**
 * Determines if the last update secondary account process has been ended successfully.
 */
export const getSelectedSecondaryAccountUpdateCompleted = createSelector(
  selectAccountState,
  (state: AccountsState) => state.isSecondaryAccountUpdateCompleted
);

/**
 * Determines if there is a running update secondary account process.
 */
export const getSelectedSecondaryAccountUpdating = createSelector(
  selectAccountState,
  (state: AccountsState) => state.isSecondaryAccountUpdating
);

/**
 * Determines if the last update process has been ended successfully.
 */
export const getSelectedAccountUpdateCompleted = createSelector(
  selectAccountState,
  (state: AccountsState) => state.isUpdateCompleted
);
/**
 * Determines if there is a running delete process.
 */
export const getSelectedAccountDeleting = createSelector(
  selectAccountState,
  (state: AccountsState) => state.isDeleting
);

/**
 * Determines if the last delete process has been ended successfully.
 */
export const getSelectedAccountDeleteCompleted = createSelector(
  selectAccountState,
  (state: AccountsState) => state.isDeleteCompleted
);
