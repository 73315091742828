<div [formGroup]="linesForm.parent" class="row">
  <div class="col-md-12">
    <div class="table-responsive">
      <table class="table text-center">
        <thead>
          <tr>
            <th scope="col">
              <i class="fa fa-list-ol"></i>
            </th>
            <th scope="col">
              {{ 'FINANCES.PETTY_CASH_REFUND_REQUESTS.PETTY_CASH_REFUND_REQUEST_DATA.DESCRIPTION' | translate }}
            </th>
            <th scope="col">
              {{
                'FINANCES.PETTY_CASH_REFUND_REQUESTS.PETTY_CASH_REFUND_REQUEST_DATA.PETTY_CASH_REFUND_REQUEST_TYPE'
                  | translate
              }}
            </th>
            <th scope="col">
              <i class="fa fa-user mr-1"></i>
              {{ 'FINANCES.PETTY_CASH_REFUND_REQUESTS.PETTY_CASH_REFUND_REQUEST_DATA.VENDOR' | translate }}
            </th>
            <th scope="col">
              <i class="fa fa-usd mr-1"></i>
              {{ 'FINANCES.PETTY_CASH_REFUND_REQUESTS.PETTY_CASH_REFUND_REQUEST_DATA.INVOICE_VALUE' | translate }}
            </th>
            <th scope="col">
              <i class="fa fa-percent mr-1"></i>
              {{ 'FINANCES.PETTY_CASH_REFUND_REQUESTS.PETTY_CASH_REFUND_REQUEST_DATA.TAX' | translate }}
            </th>
            <th scope="col">
              <i class="fa fa-usd mr-1"></i>
              {{ 'FINANCES.PETTY_CASH_REFUND_REQUESTS.PETTY_CASH_REFUND_REQUEST_DATA.TOTAL' | translate }}
            </th>
            <th scope="col">
              <i class="fa fa-calendar mr-1"></i>
              {{ 'FINANCES.PETTY_CASH_REFUND_REQUESTS.PETTY_CASH_REFUND_REQUEST_DATA.INVOICE_DATE' | translate }}
            </th>
            <th scope="col">
              {{ 'FINANCES.PETTY_CASH_REFUND_REQUESTS.PETTY_CASH_REFUND_REQUEST_DATA.ATTACHMENTS' | translate }}
            </th>
            <th scope="col">{{ 'SHARED.TOOLS' | translate }}</th>
          </tr>
        </thead>
        <tbody [formArrayName]="formArrayName">
          <tr
            app-petty-cash-refund-request-approval-form-item
            *ngFor="let item of linesForm.controls; let i = index"
            [lineGroup]="item"
            [index]="i"
            (removeChange)="removeLine(i)"
          ></tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
