import { IncomingStockType } from 'lookups/models';

/**
 * Represents the incoming stock types state.
 */
export class IncomingStockTypeState {
  /**
   * The list of incoming stock types.
   */
  public data: IncomingStockType[];

  /**
   * An error that may resulting during processing some actions.
   */
  public error: any;

  /**
   * Determines if there is a running load process.
   */
  public isLoading: boolean;

  /**
   * Determines if the last load process has been ended successfully.
   */
  public isLoaded: boolean;
}
