import { Component, Input } from '@angular/core';

import { TouchKeyboardConfig } from 'touch-keyboard/models';

@Component({
  selector: 'app-touch-keyboard',
  templateUrl: './touch-keyboard.component.html',
  styles: [],
})
export class TouchKeyboardComponent {
  /**
   * Sets the configurations of the current touch keyboard.
   */
  @Input() config: TouchKeyboardConfig;

  /**
   * Inserts the given text at the end of the current HTML input's value.
   * @param text The text to be appended at the end of the current HTML input's value.
   */
  insertText(text: string): void {
    if (!this.config?.activeInput) {
      return;
    }
    this.config.activeInput.value = `${this.config.activeInput.value}${text}`;
    this.notifyDOM();
  }

  /**
   * Adds the given numeric value to be added to the current selected input's value.
   * @param value The numeric value to be added to the current selected input's value.
   */
  addValue(value: number): void {
    if (!this.config?.activeInput) {
      return;
    }

    let oldValue = 0;

    if (!isNaN(parseInt(this.config.activeInput.value)) && !this.config.activeInput.value.includes('.')) {
      oldValue = parseInt(this.config.activeInput.value);
    } else if (!isNaN(parseFloat(this.config.activeInput.value))) {
      oldValue = parseFloat(this.config.activeInput.value);
    }

    this.config.activeInput.value = (oldValue + value).toString();
    this.notifyDOM();
  }

  /**
   * Clears the current HTML input's value.
   */
  clearText(): void {
    if (!this.config?.activeInput) {
      return;
    }
    this.config.activeInput.value = '';
    this.notifyDOM();
  }

  /**
   * Notifies the DOM that there is a change in the current HTML input's value.
   */
  notifyDOM(): void {
    this.config.activeInput.dispatchEvent(new Event('input'));
  }
}
