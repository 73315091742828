import { RootComponent } from 'core/containers/root/root.component';
import { ContentLayoutComponent } from 'core/containers/content-layout/content-layout.component';
import { FullLayoutComponent } from 'core/containers/full-layout/full-layout.component';

export * from 'core/containers/root/root.component';
export * from 'core/containers/content-layout/content-layout.component';
export * from 'core/containers/full-layout/full-layout.component';

/**
 * The set of containers registered on this module.
 */
export const CONTAINERS: any[] = [RootComponent, ContentLayoutComponent, FullLayoutComponent];
