import { createSelector } from '@ngrx/store';

import { selectLookupsState } from 'lookups/store/selectors/feature.selectors';
import { LookupsState, DependentTypeState } from 'lookups/store/states';

/**
 * Gets the dependent type state.
 */
const selectDependentTypes = createSelector(selectLookupsState, (state: LookupsState) => state.dependentTypes);

/**
 * Gets the list of loaded dependent types.
 */
export const getDependentTypes = createSelector(selectDependentTypes, (state: DependentTypeState) => state.data);

/**
 * Gets the error that occurred in the state.
 */
export const getDependentTypesError = createSelector(selectDependentTypes, (state: DependentTypeState) => state.error);

/**
 * Determines if there is a running load process.
 */
export const getDependentTypesIsLoading = createSelector(
  selectDependentTypes,
  (state: DependentTypeState) => state.isLoading
);

/**
 * Determines if the last load process has been ended successfully.
 */
export const getDependentTypesIsLoaded = createSelector(
  selectDependentTypes,
  (state: DependentTypeState) => state.isLoaded
);
