import { OrganizationSettingsActions, OrganizationSettingsActionType } from 'settings/store/actions';
import { OrganizationSettingsState } from 'settings/store/states';

/**
 * The initial state from which the state starts.
 */
const initialState: OrganizationSettingsState = {
  organizationSettings: null,
  error: null,
  isGettingOrganizationSettings: false,
  isGetOrganizationSettingsCompleted: false,
  isOrganizationSettingsUpdating: false,
  isUpdateOrganizationSettingsCompleted: false,
};

/**
 * The reducer function that is called in each action dispatch against the state.
 * @param state The current state.
 * @param action The action that will affect the state.
 */
export function organizationSettingsReducer(
  state: OrganizationSettingsState = initialState,
  action: OrganizationSettingsActions
): OrganizationSettingsState {
  switch (action.type) {
    //#region GET_ORGANIZATION_SETTINGS

    case OrganizationSettingsActionType.GET_ORGANIZATION_SETTINGS: {
      return {
        ...state,
        isGettingOrganizationSettings: true,
        isGetOrganizationSettingsCompleted: false,
        error: null,
      };
    }

    case OrganizationSettingsActionType.GET_ORGANIZATION_SETTINGS_FAIL: {
      return {
        ...state,
        error: action.payload,
        isGettingOrganizationSettings: false,
        isGetOrganizationSettingsCompleted: false,
      };
    }

    case OrganizationSettingsActionType.GET_ORGANIZATION_SETTINGS_SUCCESS: {
      return {
        ...state,
        organizationSettings: action.payload.data,
        error: null,
        isGettingOrganizationSettings: false,
        isGetOrganizationSettingsCompleted: true,
      };
    }

    // #endregion GET_ORGANIZATION_SETTINGS

    //#region UPDATE_ORGANIZATION_SETTINGS

    case OrganizationSettingsActionType.UPDATE_ORGANIZATION_SETTINGS:
    case OrganizationSettingsActionType.UPDATE_ORGANIZATION_SETTINGS_LOGO:
    case OrganizationSettingsActionType.UPDATE_IMAGE_HEADER:
    case OrganizationSettingsActionType.UPDATE_IMAGE_FOOTER:
    case OrganizationSettingsActionType.DELETE_IMAGE_HEADER:
    case OrganizationSettingsActionType.DELETE_IMAGE_FOOTER: {
      return {
        ...state,
        isOrganizationSettingsUpdating: true,
        isUpdateOrganizationSettingsCompleted: false,
        error: null,
      };
    }

    case OrganizationSettingsActionType.UPDATE_ORGANIZATION_SETTINGS_FAIL: {
      return {
        ...state,
        error: action.payload,
        isOrganizationSettingsUpdating: false,
        isUpdateOrganizationSettingsCompleted: false,
      };
    }

    case OrganizationSettingsActionType.UPDATE_ORGANIZATION_SETTINGS_SUCCESS: {
      return {
        ...state,
        organizationSettings: action.payload.data,
        error: null,
        isOrganizationSettingsUpdating: false,
        isUpdateOrganizationSettingsCompleted: true,
      };
    }

    //#endregion UPDATE_ORGANIZATION_SETTINGS

    default:
      return state;
  }
}
