import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import { Observable, Subscription } from 'rxjs';
import { skip, tap } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { PageInfo, NotificationService, NotificationMessage, TranslationService } from 'shared';
import * as fromSettingsStore from 'settings/store';

@Component({
  selector: 'app-create-tax',
  templateUrl: './create-tax.component.html',
  styles: [],
})
export class CreateTaxComponent implements OnInit, OnDestroy {
  /**
   * The confirm modal template reference.
   */
  @ViewChild('confirmModalRef') confirmModalRef: ElementRef<any>;

  /**
   * Gets or sets the information about the current page.
   */
  pageInfo: PageInfo = {
    title: 'SETTINGS.TAXES.TAX_CREATE_TITLE',
    icon: 'fa fa-user-plus',
  };

  /**
   * The create tax form.
   */
  form: FormGroup;

  /**
   * The set of subscriptions on this components,
   * these subscriptions must be unsubscribed before this component got destroyed.
   */
  subscriptions = new Subscription();

  /**
   * Indicates whether there is a create-tax process is running or not.
   */
  isCreating$: Observable<boolean>;

  /**
   * @param modalService The modal service.
   * @param notificationService The notification service.
   * @param translationService The translation service.
   * @param settingsStore$ Settings-module store.
   */
  constructor(
    private modalService: NgbModal,
    private notificationService: NotificationService,
    private translationService: TranslationService,
    private settingsStore$: Store<fromSettingsStore.SettingsState>
  ) {}

  ngOnInit(): void {
    this.init();
  }

  /**
   * Destroy component data
   */
  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  /**
   * Initialize component data.
   */
  init() {
    this.initForm();

    /**
     * Reset form controls when new tax is created.
     */
    this.subscriptions.add(
      this.settingsStore$
        .pipe(
          select(fromSettingsStore.getSelectedTaxCreateCompleted),
          skip(1),
          tap((isCreated) => {
            if (isCreated) {
              this.initForm();
            }
          })
        )
        .subscribe()
    );

    /**
     * Load data.
     */
    this.isCreating$ = this.settingsStore$.pipe(select(fromSettingsStore.getSelectedTaxCreating));
  }

  /**
   * Initialize form and add validators.
   */
  initForm() {
    this.form = new FormGroup({
      name: new FormControl('', [Validators.required, Validators.minLength(1), Validators.maxLength(50)]),
      nameEn: new FormControl('', [Validators.required, Validators.minLength(1), Validators.maxLength(50)]),
      value: new FormControl('', [Validators.required, Validators.min(0), Validators.max(100)]),
      isDefault: new FormControl(false),
    });
  }

  /**
   * Submits the form.
   */
  submit() {
    if (this.form.invalid) {
      const errorMessage = new NotificationMessage();
      if (this.form.get('name').invalid) {
        errorMessage.title = this.translationService.translate('SETTINGS.TAXES.TAX_DATA_VALIDATIONS.TAX_NAME_ERROR');
        errorMessage.body = [
          this.translationService.translate('SETTINGS.TAXES.TAX_DATA_VALIDATIONS.TAX_NAME_IS_REQUIRED'),
          this.translationService.translate('SETTINGS.TAXES.TAX_DATA_VALIDATIONS.TAX_NAME_LENGTH_ERROR'),
        ];
      } else if (this.form.get('nameEn').invalid) {
        errorMessage.title = this.translationService.translate(
          'SETTINGS.TAXES.TAX_DATA_VALIDATIONS.TAX_ENGLISH_NAME_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate('SETTINGS.TAXES.TAX_DATA_VALIDATIONS.TAX_ENGLISH_NAME_IS_REQUIRED'),
          this.translationService.translate('SETTINGS.TAXES.TAX_DATA_VALIDATIONS.TAX_ENGLISH_NAME_LENGTH_ERROR'),
        ];
      } else if (this.form.get('value').invalid) {
        errorMessage.title = this.translationService.translate('SETTINGS.TAXES.TAX_DATA_VALIDATIONS.TAX_VALUE_ERROR');
        errorMessage.body = [
          this.translationService.translate('SETTINGS.TAXES.TAX_DATA_VALIDATIONS.TAX_VALUE_IS_REQUIRED'),
          this.translationService.translate('SETTINGS.TAXES.TAX_DATA_VALIDATIONS.TAX_VALUE_LENGTH_ERROR'),
        ];
      }
      this.form.markAllAsTouched();
      return this.notificationService.warningWithTitle(errorMessage);
    } else {
      this.openModal(this.confirmModalRef);
    }
  }

  /**
   * Confirms the form submit.
   */
  confirm() {
    this.settingsStore$.dispatch(new fromSettingsStore.CreateTax(this.form.value));
  }

  /**
   * Opens the modal of the given templateRef.
   * @param modalRef The modal templateRef to be opened.
   */
  openModal(modalRef) {
    this.modalService.open(modalRef);
  }
}
