<!-- journal sales invoice details starts -->
<div class="row">
  <div class="col-md-12">
    <!-- customer details starts -->
    <div class="card-header mb-3 pt-0">
      <h4 class="card-title">{{ 'FINANCES.JOURNALS.JOURNAL_DATA.CUSTOMER_DATA' | translate }}</h4>
    </div>
    <div class="card-content">
      <div class="px-3">
        <div class="row">
          <div class="form-group" [ngClass]="invoice.customerEngagementId ? 'col-md-6' : 'col-md-12'">
            <label for="customer">{{ 'FINANCES.JOURNALS.JOURNAL_DATA.CUSTOMER' | translate }}</label>
            <input
              type="text"
              id="customer"
              class="form-control round"
              [value]="invoice.customer.name | localize: invoice.customer.nameEn | async"
              readonly
            />
          </div>
          <div class="form-group col-md-6" *ngIf="invoice.customerEngagementId">
            <label for="engagement">{{ 'FINANCES.JOURNALS.JOURNAL_DATA.ENGAGEMENT' | translate }}</label>
            <div class="form-control round" readonly>
              <i class="fa fa-link c-primary"></i>
              <span class="mx-1">{{
                invoice.customerEngagement?.customerEngagementType?.name
                  | localize: invoice.customerEngagement?.customerEngagementType?.nameEn
                  | async
              }}</span>
              <i class="fa fa-usd font-medium-2 c-primary mx-1"></i>
              <span class="mx-1">{{ invoice.customerEngagement?.value | toDecimal }}</span>
            </div>
          </div>
          <div class="form-group col-md-12">
            <label label for="invoice.saleInvoiceType.name">{{
              'FINANCES.JOURNALS.JOURNAL_DATA.INVOICE_TYPE' | translate
            }}</label>
            <input
              *ngIf="invoice.saleInvoiceType.key === 'SALE_INVOICE'"
              type="text"
              id="invoice.saleInvoiceType.name"
              class="form-control round"
              [value]="invoice.saleInvoiceType.name | localize: invoice.saleInvoiceType.nameEn | async"
              readonly
            />
          </div>
          <div class="form-group col-md-3">
            <label for="transactionDate">{{ 'FINANCES.JOURNALS.JOURNAL_DATA.JOURNAL_DATE' | translate }}</label>
            <input
              type="text"
              id="transactionDate"
              class="form-control round"
              [value]="invoice.transactionDate | date: 'datetime'"
              readonly
            />
          </div>
          <div class="form-group col-md-3">
            <label for="createdAt">{{ 'SHARED.SEARCH.CREATED_AT' | translate }}</label>
            <input
              type="text"
              id="createdAt"
              class="form-control round"
              [value]="invoice.createdAt | date: 'datetime'"
              readonly
            />
          </div>
          <div class="form-group col-md-6">
            <label for="user">{{ 'FINANCES.JOURNALS.JOURNAL_DATA.USER_CREATE' | translate }}</label>
            <input type="text" id="user" class="form-control round" [value]="invoice.user.name" readonly />
          </div>
          <div class="form-group col-md-12">
            <label for="notes">{{ 'FINANCES.JOURNALS.JOURNAL_DATA.NOTES' | translate }}</label>
            <textarea
              id="notes"
              class="form-control round"
              [innerText]="invoice.notes"
              [placeholder]="'FINANCES.JOURNALS.JOURNAL_DATA.NOTES_NOT_FOUND' | translate"
              readonly
            ></textarea>
          </div>
        </div>
      </div>
    </div>
    <!-- customer details ends -->

    <!-- products details starts -->
    <div class="card-header mb-3 pt-1">
      <h4 class="card-title">{{ 'FINANCES.JOURNALS.JOURNAL_DATA.PRODUCTS' | translate }}</h4>
    </div>
    <div class="card-content">
      <div class="px-3">
        <div class="row">
          <div class="col-md-12">
            <div class="table-responsive">
              <table class="table text-center">
                <thead>
                  <tr>
                    <th scope="col"><i class="fa fa-list-ol"></i></th>
                    <th scope="col">
                      <i class="fa fa-map-signs"></i>
                      {{ 'FINANCES.JOURNALS.JOURNAL_DATA.PRODUCT_TYPE' | translate }}
                    </th>
                    <th scope="col">
                      <i class="fa fa-comments"></i>
                      {{ 'FINANCES.JOURNALS.JOURNAL_DATA.PRODUCT_DESCRIPTION' | translate }}
                    </th>
                    <th scope="col">
                      <i class="fa fa-list-ol"></i>
                      {{ 'FINANCES.JOURNALS.JOURNAL_DATA.PRODUCT_QUANTITIES' | translate }}
                    </th>
                    <th scope="col">
                      <i class="fa fa-balance-scale"></i>
                      {{ 'FINANCES.JOURNALS.JOURNAL_DATA.PRODUCT_UNIT_OF_MEASURE' | translate }}
                    </th>
                    <th scope="col">
                      <i class="fa fa-usd"></i>
                      {{ 'FINANCES.JOURNALS.JOURNAL_DATA.PRODUCT_VALUE' | translate }}
                    </th>
                    <th scope="col">
                      <i class="fa fa-usd"></i>
                      {{ 'FINANCES.JOURNALS.JOURNAL_DATA.PRODUCT_TOTAL' | translate }}
                    </th>
                    <th scope="col">
                      <i class="fa fa-minus-circle"></i>
                      {{ 'FINANCES.JOURNALS.JOURNAL_DATA.NET_DISCOUNT' | translate }}
                    </th>
                    <th scope="col">
                      <i class="fa fa-usd"></i>
                      {{ 'FINANCES.JOURNALS.JOURNAL_DATA.TOTAL_PRODUCT_TAX' | translate }}
                    </th>
                    <th scope="col">
                      <i class="fa fa-percent"></i>
                      {{ 'FINANCES.JOURNALS.JOURNAL_DATA.TAX' | translate }}
                    </th>
                    <th scope="col">
                      <i class="fa fa-usd"></i>
                      {{ 'FINANCES.JOURNALS.JOURNAL_DATA.TAX_AMOUNT' | translate }}
                    </th>
                    <th scope="col">
                      <i class="fa fa-usd"></i>
                      {{ 'FINANCES.JOURNALS.JOURNAL_DATA.NET_TOTAL' | translate }}
                    </th>
                    <th scope="col">
                      <i class="fa fa-location-arrow"></i>
                      {{ 'FINANCES.JOURNALS.JOURNAL_DATA.RECEIVE_ADDRESS' | translate }}
                    </th>
                    <th scope="col">
                      <i class="fa fa-list-ol"></i>
                      {{ 'FINANCES.JOURNALS.JOURNAL_DATA.QUANTITIES' | translate }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let product of invoice.saleInvoiceProducts; let i = index">
                    <th scope="row">{{ i + 1 }}</th>
                    <td>
                      <span class="c-success" *ngIf="product.product && !product.product?.isService">{{
                        'FINANCES.JOURNALS.JOURNAL_DATA.PRODUCT_NOT_HAS_SERVICE' | translate
                      }}</span>
                      <span class="c-success" *ngIf="product.product && product.product?.isService">{{
                        'FINANCES.JOURNALS.JOURNAL_DATA.SERVICE_PRODUCT' | translate
                      }}</span>
                      <span class="c-primary" *ngIf="!product.product">{{
                        'FINANCES.JOURNALS.JOURNAL_DATA.PRODUCT_NOT_STORABLE' | translate
                      }}</span>
                    </td>
                    <td>
                      {{
                        (product.product?.description | localize: product.product?.descriptionEn | async) ||
                          product.description
                      }}
                      <div *ngIf="product.subProduct" class="mt-1 d-block">
                        <app-sub-product-info [subProduct]="product.subProduct"></app-sub-product-info>
                      </div>
                    </td>
                    <td>{{ product.quantity | toDecimal }}</td>
                    <td>{{ product.unitOfMeasure?.name | localize: product.unitOfMeasure?.nameEn | async }}</td>
                    <td>{{ product.value | toDecimal }}</td>
                    <td>{{ product.total | toDecimal }}</td>
                    <td>{{ product.netDiscount | toDecimal }}</td>
                    <td>{{ product.taxableAmount | toDecimal }}</td>
                    <td>
                      <span class="mx-1">{{ product.tax | toDecimal }}</span>
                      <span><i class="fa fa-percent"></i></span>
                    </td>
                    <td>{{ product.taxAmount | toDecimal }}</td>
                    <td>{{ product.netTotal | toDecimal }}</td>
                    <td>{{ product.location?.name | localize: product.location?.nameEn | async }}</td>
                    <td>{{ product.returnedQuantity }}</td>
                  </tr>
                </tbody>
                <tfoot class="tfoot-light">
                  <tr>
                    <td [attr.colspan]="4">
                      <i class="fa fa-bookmark c-primary"></i>
                      <span>
                        {{ invoice.saleInvoiceProducts?.length | toNumber }}
                        {{ 'FINANCES.JOURNALS.JOURNAL_DATA.PRODUCT' | translate }}
                      </span>
                    </td>
                    <td [attr.colspan]="5">
                      <i class="fa fa-bookmark c-primary"></i>
                      <span>
                        {{ productsQuantity(invoice.saleInvoiceProducts) | toDecimal }}
                        {{ 'FINANCES.JOURNALS.JOURNAL_DATA.QUANTITY' | translate }}</span
                      >
                    </td>
                    <td [attr.colspan]="5">
                      <i class="fa fa-bookmark c-primary"></i>
                      <span>
                        {{ invoice.total + invoice.tax | toDecimal }}
                        {{ 'FINANCES.JOURNALS.JOURNAL_DATA.PRODUCT_TOTAL' | translate }}
                      </span>
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- products details ends -->

    <!-- invoice summary starts -->
    <div class="card-header mb-3 pt-1">
      <h4 class="card-title">{{ 'FINANCES.JOURNALS.JOURNAL_DATA.INVOICE_SUMMARY' | translate }}</h4>
    </div>
    <div class="card-content">
      <div class="px-3">
        <div class="row">
          <div class="form-group col-md-4">
            <label for="totalWithoutVat">{{ 'FINANCES.JOURNALS.JOURNAL_DATA.TOTAL_EXCLUDING' | translate }}</label>
            <input
              type="text"
              id="totalWithoutVat"
              class="form-control round"
              [value]="invoice.total | toDecimal"
              readonly
            />
          </div>
          <div class="form-group col-md-4">
            <label for="discount">{{ 'FINANCES.JOURNALS.JOURNAL_DATA.DISCOUNT' | translate }}</label>
            <input
              type="text"
              id="discount"
              class="form-control round"
              [value]="invoice.discount | toDecimal"
              readonly
            />
          </div>
          <div class="form-group col-md-4">
            <label for="totalTaxableAmount">{{
              'FINANCES.JOURNALS.JOURNAL_DATA.TOTAL_TAXABLE_AMOUNT_VAT' | translate
            }}</label>
            <input
              type="text"
              id="totalTaxableAmount"
              class="form-control round"
              [value]="invoice.taxableAmount | toDecimal"
              readonly
            />
          </div>
          <div class="form-group col-md-4">
            <label for="tax">{{ 'FINANCES.JOURNALS.JOURNAL_DATA.TOTAL_VAT' | translate }}</label>
            <input type="text" id="tax" class="form-control round" [value]="invoice.tax | toDecimal" readonly />
          </div>
          <div class="form-group col-md-4">
            <label for="net">{{ 'FINANCES.JOURNALS.JOURNAL_DATA.TOTAL_AMOUNT_DUE' | translate }}</label>
            <input type="text" id="net" class="form-control round" [value]="invoice.net | toDecimal" readonly />
          </div>
        </div>
      </div>
    </div>
    <!-- invoice summary ends -->
  </div>
  <!-- journal sales invoice details ends -->
</div>
