import { CreateUnitOfMeasureClassComponent } from 'stores/components/unit-of-measure-classes/create-unit-of-measure-class/create-unit-of-measure-class.component';
import { UpdateUnitOfMeasureClassComponent } from 'stores/components/unit-of-measure-classes/update-unit-of-measure-class/update-unit-of-measure-class.component';
import { UnitOfMeasureClassesListComponent } from 'stores/components/unit-of-measure-classes/unit-of-measure-classes-list/unit-of-measure-classes-list.component';
import { UnitOfMeasureClassesSearchComponent } from 'stores/components/unit-of-measure-classes/unit-of-measure-classes-search/unit-of-measure-classes-search.component';

export * from 'stores/components/unit-of-measure-classes/create-unit-of-measure-class/create-unit-of-measure-class.component';
export * from 'stores/components/unit-of-measure-classes/update-unit-of-measure-class/update-unit-of-measure-class.component';
export * from 'stores/components/unit-of-measure-classes/unit-of-measure-classes-list/unit-of-measure-classes-list.component';
export * from 'stores/components/unit-of-measure-classes/unit-of-measure-classes-search/unit-of-measure-classes-search.component';

/**
 * The set of components registered on this sub-module.
 */
export const COMPONENTS: any[] = [
  CreateUnitOfMeasureClassComponent,
  UpdateUnitOfMeasureClassComponent,
  UnitOfMeasureClassesListComponent,
  UnitOfMeasureClassesSearchComponent,
];
