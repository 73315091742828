import { createSelector } from '@ngrx/store';

import { selectLookupsState } from 'lookups/store/selectors/feature.selectors';
import { LookupsState, GosiRegTypeState } from 'lookups/store/states';

/**
 * Gets the gosi reg type state.
 */
const selectGosiRegTypes = createSelector(selectLookupsState, (state: LookupsState) => state.gosiRegTypes);

/**
 * Gets the list of loaded gosi reg types.
 */
export const getGosiRegTypes = createSelector(selectGosiRegTypes, (state: GosiRegTypeState) => state.data);

/**
 * Gets the error that occurred in the state.
 */
export const getGosiRegTypesError = createSelector(selectGosiRegTypes, (state: GosiRegTypeState) => state.error);

/**
 * Determines if there is a running load process.
 */
export const getGosiRegTypesIsLoading = createSelector(
  selectGosiRegTypes,
  (state: GosiRegTypeState) => state.isLoading
);

/**
 * Determines if the last load process has been ended successfully.
 */
export const getGosiRegTypesIsLoaded = createSelector(selectGosiRegTypes, (state: GosiRegTypeState) => state.isLoaded);
