import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import { Store, select } from '@ngrx/store';
import { Subscription, Observable } from 'rxjs';
import { skip, tap } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { PageInfo, NotificationService, NotificationMessage, TranslationService } from 'shared';
import * as fromSalesStore from 'sales/store';
import * as fromAuthStore from 'auth/store';
import { PosDevice } from 'sales/models';

@Component({
  selector: 'app-create-pos-printer',
  templateUrl: './create-pos-printer.component.html',
  styles: [],
})
export class CreatePosPrinterComponent implements OnInit, OnDestroy {
  /**
   * The confirm modal template reference.
   */
  @ViewChild('confirmModalRef') confirmModalRef: ElementRef<any>;

  /**
   * Gets or sets the information about the current page.
   */
  pageInfo: PageInfo = {
    title: 'SALES.POS_PRINTERS.CREATE_POS_PRINTER_TITLE',
    icon: 'fa fa-plus',
  };

  /**
   * Indicates whether there is a create pos printer process is running or not.
   */
  isCreating$: Observable<boolean>;

  /**
   * The create pos printer form.
   */
  form: FormGroup;

  /**
   * The set of subscriptions on this components,
   * these subscriptions must be unsubscribed before this component got destroyed.
   */
  subscriptions = new Subscription();

  /**
   * Shows or hide the pos devices list.
   */
  posDevicesListVisibility = false;

  /**
   * The list of selected pos devices.
   */
  posDevices: PosDevice[] = [];

  /**
   * @param modalService The modal service.
   * @param notificationService The notification service.
   * @param salesStore$  The stores store.
   * @param translationService The translation service.
   * @param authStore$ the auth-store module.
   */
  constructor(
    private modalService: NgbModal,
    private notificationService: NotificationService,
    private salesStore$: Store<fromSalesStore.SalesState>,
    private translationService: TranslationService,
    private authStore$: Store<fromAuthStore.AuthState>
  ) {}

  ngOnInit(): void {
    this.init();
  }

  /**
   * Destroy component data
   */
  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  /**
   * Initialize component data.
   */
  init() {
    this.initForm();

    /* Subscribe to selected posDevice to select it whenever it changed. */
    this.subscriptions.add(
      this.authStore$
        .pipe(
          select(fromAuthStore.getSessionUserDefaultPosDevices),
          tap((posDevice) => {
            if (posDevice) {
              this.selectPosDevice([posDevice]);
            }
          })
        )
        .subscribe()
    );

    /**
     * Reset form controls when new pos printer is created.
     */
    this.subscriptions.add(
      this.salesStore$
        .pipe(
          select(fromSalesStore.getSelectedPosPrinterCreateCompleted),
          skip(1),
          tap((isCreated) => {
            if (isCreated) {
              this.initForm();
            }
          })
        )
        .subscribe()
    );

    /**
     * Load data.
     */
    this.isCreating$ = this.salesStore$.pipe(select(fromSalesStore.getSelectedPosPrinterCreating));
  }

  /**
   * Initialize form and add validators.
   */
  initForm() {
    this.form = new FormGroup({
      name: new FormControl('', [Validators.required, Validators.minLength(1), Validators.maxLength(50)]),
      ip: new FormControl('', [Validators.required, Validators.minLength(1), Validators.maxLength(50)]),
      posDeviceId: new FormControl(null, [Validators.required]),
    });
  }

  /**
   * Submits the form.
   */
  submit() {
    if (this.form.invalid) {
      const errorMessage = new NotificationMessage();

      if (this.form.get('name').invalid) {
        errorMessage.title = this.translationService.translate(
          'SALES.POS_PRINTERS.POS_PRINTER_DATA_VALIDATION.POS_PRINTER_NAME_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate(
            'SALES.POS_PRINTERS.POS_PRINTER_DATA_VALIDATION.POS_PRINTER_NAME_IS_REQUIRED'
          ),
          this.translationService.translate(
            'SALES.POS_PRINTERS.POS_PRINTER_DATA_VALIDATION.POS_PRINTER_NAME_LENGTH_ERROR'
          ),
        ];
      } else if (this.form.get('ip').invalid) {
        errorMessage.title = this.translationService.translate(
          'SALES.POS_PRINTERS.POS_PRINTER_DATA_VALIDATION.POS_PRINTER_IP_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate(
            'SALES.POS_PRINTERS.POS_PRINTER_DATA_VALIDATION.POS_PRINTER_IP_IS_REQUIRED'
          ),
          this.translationService.translate(
            'SALES.POS_PRINTERS.POS_PRINTER_DATA_VALIDATION.POS_PRINTER_IP_LENGTH_ERROR'
          ),
        ];
      } else if (this.form.get('posDeviceId').invalid) {
        errorMessage.title = this.translationService.translate(
          'SALES.POS_PRINTERS.POS_PRINTER_DATA_VALIDATION.POS_DEVICE_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate('SALES.POS_PRINTERS.POS_PRINTER_DATA_VALIDATION.POS_DEVICE_IS_REQUIRED'),
        ];
      }
      this.form.markAllAsTouched();
      return this.notificationService.warningWithTitle(errorMessage);
    } else {
      this.openModal(this.confirmModalRef);
    }
  }

  /**
   * Confirms the form submit.
   */
  confirm() {
    const { posDeviceId, name, ip } = this.form.value;

    this.salesStore$.dispatch(
      new fromSalesStore.CreatePosPrinter({
        name,
        ip,
        posDeviceId,
      })
    );
  }

  /**
   * Opens the modal of the given templateRef.
   * @param modalRef The modal templateRef to be opened.
   */
  openModal(modalRef) {
    this.modalService.open(modalRef);
  }

  /**
   * Selects the newly selected pos printer from the pos printer search list.
   * @param posDevice The list of newly selected pos printer to select the only one in the list.
   */
  selectPosDevice([posDevice]: PosDevice[]) {
    if (posDevice) {
      this.posDevices = [posDevice];
      this.form.patchValue({ posDeviceId: posDevice.id });
    }
  }
}
