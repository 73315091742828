import { createSelector } from '@ngrx/store';

import { selectLookupsState } from 'lookups/store/selectors/feature.selectors';
import { LookupsState, JournalTypeState } from 'lookups/store/states';

/**
 * Gets the journal type state.
 */
const selectJournalTypes = createSelector(selectLookupsState, (state: LookupsState) => state.journalTypes);

/**
 * Gets the list of loaded JournalTypes.
 */
export const getJournalTypes = createSelector(selectJournalTypes, (state: JournalTypeState) => state.data);

/**
 * Gets the error that occurred in the state.
 */
export const getJournalTypesError = createSelector(selectJournalTypes, (state: JournalTypeState) => state.error);

/**
 * Determines if there is a running load process.
 */
export const getJournalTypesIsLoading = createSelector(
  selectJournalTypes,
  (state: JournalTypeState) => state.isLoading
);

/**
 * Determines if the last load process has been ended successfully.
 */
export const getJournalTypesIsLoaded = createSelector(selectJournalTypes, (state: JournalTypeState) => state.isLoaded);
