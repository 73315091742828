import { Component, Input } from '@angular/core';

import { flatMap } from 'lodash';

import { TranslationService } from 'shared';
import { UserClaimFormItem, UserClaimGroupFormItem } from 'security/models';

@Component({
  selector: 'app-user-claims-form',
  templateUrl: './user-claims-form.component.html',
  styles: [],
})
export class UserClaimsFormComponent {
  /**
   * The claim groups model.
   * Uses `[(ngModel)]` binding.
   */
  @Input() groups: UserClaimGroupFormItem[];

  /**
   * Gets a summary for all selected claims in all groups.
   */
  get selectedClaimsSummary(): string {
    if (!this.groups) {
      return '';
    }

    const claims = flatMap(this.groups, (group) => group.claims);
    const selectedCount = claims.filter((claim) => claim.isSelected).length;

    return `(${selectedCount} ${this.translationService.translate('SHARED.MISC.FROM')} ${claims.length})`;
  }

  constructor(private translationService: TranslationService) {}

  /**
   * Returns a summary for all selected claims in the given groups.
   * @param group The current group.
   */
  getGroupSelectedClaimsSummary(group: UserClaimGroupFormItem): string {
    return `(${group.claims.filter((claim) => claim.isSelected).length} ${this.translationService.translate(
      'SHARED.MISC.FROM'
    )} ${group.claims.length})`;
  }

  /**
   * Handles the change in group selection.
   * @param group The current group.
   * @param isSelected A value indicates whether if the group is selected or not.
   */
  onGroupSelectedChange(group: UserClaimGroupFormItem, isSelected: boolean): void {
    group.claims.forEach((claim) => (claim.isSelected = isSelected));
  }

  /**
   * Handles the change in claim selection.
   * @param claim The current claim.
   * @param group The current claim's group.
   * @param isSelected A value indicates whether if the claim is selected or not.
   */
  onClaimSelectedChange(claim: UserClaimFormItem, group: UserClaimGroupFormItem, isSelected: boolean): void {
    if (
      isSelected &&
      group.claims.filter((claimItem) => claimItem.isSelected && claimItem.claim.id !== claim.claim.id).length ===
        group.claims.length - 1
    ) {
      group.isSelected = true;
      this.onGroupSelectedChange(group, true);
    } else {
      group.isSelected = false;
    }
  }
}
