import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';

import { AppHttpResponse } from 'shared/models';

/**
 * The purchases reports services.
 */
@Injectable()
export class PurchasesReportsService {
  /**
   * The relative route for the purchases reports.
   *
   * No leading or trailing slashes required.
   */
  private purchasesReportsApi = 'reporting/purchases';

  constructor(private http: HttpClient) {}

  /**
   * Gets the purchases invoices matching the provided query params.
   * @param vendors The array of the  ids of vendors.
   * @param paymentDurations The paymentDurations of the purchase invoice.
   * @param purchaseInvoiceTypes The purchaseInvoiceTypes of the purchase invoice.
   * @param fromDate The fromDate of the purchase invoice.
   * @param toDate The toDate of the purchase invoice.
   * @param displayProducts of the report.
   */
  public getPurchaseInvoicesReport(
    vendors: number[],
    locations: number[],
    paymentDurations: number[],
    purchaseInvoiceTypes: number[],
    fromDate: Date,
    toDate: Date,
    displayProducts: boolean
  ): Observable<AppHttpResponse<string>> {
    const params = new HttpParams()
      .set('vendors', vendors?.join(','))
      .set('locations', locations.join(','))
      .set('paymentDurations', paymentDurations?.join(','))
      .set('purchaseInvoiceTypes', purchaseInvoiceTypes?.join(','))
      .set('fromDate', fromDate?.toISOString())
      .set('toDate', toDate?.toISOString())
      .set('displayProducts', displayProducts?.toString());

    return this.http.get<AppHttpResponse<string>>(`${this.purchasesReportsApi}/purchase-invoices-report`, { params });
  }

  /**
   * Gets the purchases orders matching the provided query params.
   * @param vendors The array of the  ids of vendors.
   * @param types The purchaseOrderTypes of the purchase order.
   * @param fromDate The fromDate of the purchase order.
   * @param toDate The toDate of the purchase order.
   * @param displayProducts of the report.
   */
  public getPurchaseOrdersReport(
    vendors: number[],
    types: number[],
    fromDate: Date,
    toDate: Date,
    displayProducts: boolean
  ): Observable<AppHttpResponse<string>> {
    const params = new HttpParams()
      .set('vendors', vendors.join(','))
      .set('types', types?.join(','))
      .set('fromDate', fromDate?.toISOString())
      .set('toDate', toDate?.toISOString())
      .set('displayProducts', displayProducts?.toString());

    return this.http.get<AppHttpResponse<string>>(`${this.purchasesReportsApi}/purchase-orders-report`, { params });
  }

  /**
   * Gets the purchases requests matching the provided query params.
   * @param types The purchaseRequestTypes of the purchase request.
   * @param fromDate The fromDate of the purchase request.
   * @param toDate The toDate of the purchase request.
   * @param displayProducts of the report.
   */
  public getPurchaseRequestsReport(
    types: number[],
    fromDate: Date,
    toDate: Date,
    displayProducts: boolean
  ): Observable<AppHttpResponse<string>> {
    const params = new HttpParams()
      .set('types', types?.join(','))
      .set('fromDate', fromDate?.toISOString())
      .set('toDate', toDate?.toISOString())
      .set('displayProducts', displayProducts?.toString());

    return this.http.get<AppHttpResponse<string>>(`${this.purchasesReportsApi}/purchase-requests-report`, { params });
  }

  /**
   * Gets the purchases returns matching the provided query params.
   * @param locations The array of the  ids of locations.
   * @param vendors The array of the  ids of vendors.
   * @param fromDate The fromDate of the purchase request.
   * @param toDate The toDate of the purchase request.
   * @param displayProducts of the report.
   */
  public getPurchaseReturnsReport(
    locations: number[],
    vendors: number[],
    fromDate: Date,
    toDate: Date,
    displayProducts: boolean
  ): Observable<AppHttpResponse<string>> {
    const params = new HttpParams()
      .set('locations', locations?.join(','))
      .set('vendors', vendors?.join(','))
      .set('fromDate', fromDate?.toISOString())
      .set('toDate', toDate?.toISOString())
      .set('displayProducts', displayProducts?.toString());

    return this.http.get<AppHttpResponse<string>>(`${this.purchasesReportsApi}/purchase-returns-report`, { params });
  }

  /**
   * Gets the top purchasing products report matching the provided query params.
   * @param fromDate The fromDate of the top purchasing product.
   * @param toDate The toDate of the top purchasing product.
   * @param count The count of the top purchasing product.
   */
  public getTopPurchasedProductsReport(
    locations: number[],
    fromDate: Date,
    toDate: Date,
    count: number
  ): Observable<AppHttpResponse<string>> {
    const params = new HttpParams()
      .set('locations', locations.join(','))
      .set('fromDate', fromDate?.toISOString())
      .set('toDate', toDate?.toISOString())
      .set('count', count?.toString());

    return this.http.get<AppHttpResponse<string>>(`${this.purchasesReportsApi}/top-purchased-products-report`, {
      params,
    });
  }

  /**
   * Gets the worst purchasing products report matching the provided query params.
   * @param fromDate The fromDate of the worst purchasing product.
   * @param toDate The toDate of the worst purchasing product.
   * @param count The count of the worst purchasing product.
   */
  public getWorstPurchasedProductsReport(
    locations: number[],
    fromDate: Date,
    toDate: Date,
    count: number
  ): Observable<AppHttpResponse<string>> {
    const params = new HttpParams()
      .set('locations', locations.join(','))
      .set('fromDate', fromDate?.toISOString())
      .set('toDate', toDate?.toISOString())
      .set('count', count?.toString());

    return this.http.get<AppHttpResponse<string>>(`${this.purchasesReportsApi}/worst-purchased-products-report`, {
      params,
    });
  }

  /**
   * Gets the top returned products report matching the provided query params.
   * @param fromDate The fromDate of the top returned product.
   * @param toDate The toDate of the top returned product.
   * @param count The count of the top returned product.
   */
  public getMostReturnedPurchaseProductsReport(
    locations: number[],
    fromDate: Date,
    toDate: Date,
    count: number
  ): Observable<AppHttpResponse<string>> {
    const params = new HttpParams()
      .set('locations', locations.join(','))
      .set('fromDate', fromDate?.toISOString())
      .set('toDate', toDate?.toISOString())
      .set('count', count?.toString());

    return this.http.get<AppHttpResponse<string>>(`${this.purchasesReportsApi}/top-returned-purchase-products-report`, {
      params,
    });
  }

  /**
   * Gets the worst returned products report matching the provided query params.
   * @param fromDate The fromDate of the worst returned product.
   * @param toDate The toDate of the worst returned product.
   * @param count The count of the worst returned product.
   */
  public getWorstReturnedPurchaseProductsReport(
    locations: number[],
    fromDate: Date,
    toDate: Date,
    count: number
  ): Observable<AppHttpResponse<string>> {
    const params = new HttpParams()
      .set('locations', locations.join(','))
      .set('fromDate', fromDate?.toISOString())
      .set('toDate', toDate?.toISOString())
      .set('count', count?.toString());

    return this.http.get<AppHttpResponse<string>>(
      `${this.purchasesReportsApi}/worst-returned-purchase-products-report`,
      { params }
    );
  }

  /**
   * Gets the vendors debts matching the provided query params.
   */
  public getVendorsDebtsReport(): Observable<AppHttpResponse<string>> {
    return this.http.get<AppHttpResponse<string>>(`${this.purchasesReportsApi}/vendors-debts-report`);
  }

  /**
   * Gets the vendor summary matching the provided query params.
   * @param vendorId The vendor id of the vendor id summary.
   * @param fromDate The fromDate of the vendors summary.
   * @param toDate The toDate of the vendors summary.
   */
  public getVendorSummaryReport(vendorId: number, fromDate: Date, toDate: Date): Observable<AppHttpResponse<string>> {
    const params = new HttpParams()
      .set('vendorId', vendorId.toString())
      .set('fromDate', fromDate?.toISOString())
      .set('toDate', toDate?.toISOString());

    return this.http.get<AppHttpResponse<string>>(`${this.purchasesReportsApi}/vendor-summary-report`, { params });
  }
}
