import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';

import { Observable, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

import * as fromFinancesStore from 'finances/store';
import * as fromLookupsStore from 'lookups/store';
import { NotificationService, PaginationInfo, TranslationService } from 'shared';
import { PayrollPaymentRefund, PayrollPaymentRefundAttachment } from 'finances/models';
import { EmployeePaymentMethod } from 'lookups/models';

@Component({
  selector: 'app-payroll-payment-refunds-list',
  templateUrl: './payroll-payment-refunds-list.component.html',
  styles: [],
})
export class PayrollPaymentRefundsListComponent implements OnInit, OnDestroy {
  /**
   * Gets or sets the currently selected payroll payment refund for delete.
   */
  selectedPayrollPayment: PayrollPaymentRefund;

  /**
   * The initial `fromDate` value for search.
   */
  initialFromDate = new Date();

  /**
   * The initial `toDate` value for search.
   * It's the current day of month.
   */
  initialToDate = new Date();

  /**
   * The list of payroll payment refunds.
   */
  payrollPaymentRefunds$: Observable<PayrollPaymentRefund[]>;

  /**
   * The set of system supported employee payment methods.
   */
  employeePaymentMethods$: Observable<EmployeePaymentMethod[]>;

  /**
   * The pagination info.
   */
  paginationInfo$: Observable<PaginationInfo>;

  /**
   * Indicates whether there is a search process is running or not.
   */
  isSearching$: Observable<boolean>;

  /**
   * Indicates whether there is a delete-payroll process is running or not.
   */
  isDeleting$: Observable<boolean>;

  /**
   * The search form.
   */
  searchForm: FormGroup;

  /**
   * The ngb-modal reference to use it when modal needed to be closed.
   */
  modal: NgbModalRef;

  /**
   * The set of subscriptions on this components,
   * these subscriptions must be unsubscribed before this component got destroyed.
   */
  subscriptions = new Subscription();

  /**
   * @param notificationService The notification service.
   * @param modalService The modal service.
   * @param translationService The translation service.
   * @param financesStore$ the finances-store module.
   * @param lookupsStore$ The lookups-store module.
   * @param lookupsStore$ The lookups-store module.
   */
  constructor(
    private notificationService: NotificationService,
    private modalService: NgbModal,
    private translationService: TranslationService,
    private financesStore$: Store<fromFinancesStore.FinancesState>,
    private lookupsStore$: Store<fromLookupsStore.LookupsState>
  ) {}

  ngOnInit(): void {
    this.init();
  }

  /**
   * Destroy component data
   */
  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  /**
   * Initialize component data.
   */
  init() {
    this.initForm();

    /**
     * Load data.
     */
    let isManualSearchTriggeredBeforeForPaymentMethods = false;
    this.employeePaymentMethods$ = this.lookupsStore$.pipe(
      select(fromLookupsStore.getEmployeePaymentMethods),
      tap((employeePaymentMethods) => {
        if (!isManualSearchTriggeredBeforeForPaymentMethods && !employeePaymentMethods.length) {
          isManualSearchTriggeredBeforeForPaymentMethods = true;
          this.lookupsStore$.dispatch(new fromLookupsStore.GetAllEmployeePaymentMethods());
        }
      })
    );

    this.isSearching$ = this.financesStore$.pipe(select(fromFinancesStore.getPayrollPaymentRefundsSearching));
    this.isDeleting$ = this.financesStore$.pipe(select(fromFinancesStore.getSelectedPayrollDeleting));

    this.payrollPaymentRefunds$ = this.financesStore$.pipe(select(fromFinancesStore.getPayrollPaymentRefunds));

    this.paginationInfo$ = this.financesStore$.pipe(select(fromFinancesStore.getPayrollPaymentRefundsPaginationInfo));
  }

  /**
   * Initialize form and add validators.
   */
  initForm() {
    this.searchForm = new FormGroup({
      employeePaymentMethods: new FormControl([]),
      fromDate: new FormControl({
        year: this.initialFromDate.getFullYear(),
        month: this.initialFromDate.getMonth() + 1,
        day: this.initialFromDate.getDate(),
      }),
      toDate: new FormControl({
        year: this.initialToDate.getFullYear(),
        month: this.initialToDate.getMonth() + 1,
        day: this.initialToDate.getDate(),
      }),
      page: new FormControl(1),
    });
  }

  /**
   * Handles search parameters change.
   */
  searchPayrollPaymentRefunds(event?: KeyboardEvent) {
    if (event && event.key === 'Enter') {
      event.preventDefault();
      return;
    }

    this.applyFiltersAndSearch();
  }

  /**
   * Handles pagination page-changed event.
   * @param page The current selected page number.
   */
  pageChanged(page: number) {
    /** Update pagination page. */
    this.searchForm.patchValue({ page });

    this.applyFiltersAndSearch(page);
  }

  /**
   * Applies the search filters and dispatches a search action.
   * @param page The current page number, default to `1`.
   */
  applyFiltersAndSearch(page: number = 1) {
    if (!this.searchForm.get('fromDate')?.value || !this.searchForm.get('toDate')?.value) {
      return this.notificationService.warning(
        this.translationService.translate('HR.EMPLOYEES_MANAGEMENT.NOTIFICATION_MESSAGE.SELECT_DATE')
      );
    }

    const { employeePaymentMethods, fromDate, toDate } = this.searchForm.value;
    const fromDateFormatted = new Date(fromDate.year, fromDate.month - 1, fromDate.day);
    fromDateFormatted.setHours(0, 0, 0, 0);

    const toDateFormatted = new Date(toDate.year, toDate.month - 1, toDate.day);
    toDateFormatted.setHours(23, 59, 59, 0);

    this.financesStore$.dispatch(
      new fromFinancesStore.SearchPayrollPaymentRefunds({
        employeePaymentMethods,
        fromDate: fromDateFormatted,
        toDate: toDateFormatted,
        page,
      })
    );
  }

  /**
   * Maps and returns the set of payroll payment refund attachments into a set of urls.
   * @param attachments The payroll payment refund attachments to be mapped.
   */
  getAttachmentsUrls(attachments: PayrollPaymentRefundAttachment[]): string[] {
    return attachments ? attachments.map((attachment) => attachment.url) : [];
  }

  /**
   * Enables trackBy feature for *ngFor utility to track items
   * depending on a comparer to reduce DOM change.
   * If the item is already exist then no HTML will change, only bounded values.
   * @param index The index of the item.
   * @param payrollPaymentRefund The item to determine if it was changed or not.
   */
  trackItems(index: number, payrollPaymentRefund: PayrollPaymentRefund) {
    return payrollPaymentRefund ? payrollPaymentRefund.id : undefined;
  }

  /**
   * Opens the modal of the given templateRef.
   * @param modalRef The modal templateRef to be opened.
   */
  openModal(modalRef) {
    this.modalService.open(modalRef);
  }

  /**
   * Opens the modal of the given templateRef.
   * @param modalRef The modal templateRef to be opened.
   */
  openInfoModal(modalRef) {
    /**
     * Open the modal & listen for user press ESC key.
     */
    this.modal = this.modalService.open(modalRef, { size: 'xl' });
    this.modal.result.then(null, () => this.closeModal());
  }

  /**
   * Closes the currently opened modal.
   */
  closeModal() {
    this.modalService.dismissAll();
  }
}
