import { Action } from '@ngrx/store';

import { AppHttpResponse } from 'shared';
import {
  PettyCashRefundRequest,
  CreatePettyCashRefundRequestInput,
  UpdatePettyCashRefundRequestInput,
} from 'finances/models';

/**
 * The finances-module store petty Cash refund requests action types.
 */
export enum PettyCashRefundRequestsActionType {
  SEARCH_PETTY_CASH_REFUND_REQUESTS = '[Finances] [PettyCashRefundRequests] Search Petty Cash Refund Requests',
  SEARCH_PETTY_CASH_REFUND_REQUESTS_FAIL = '[Finances] [PettyCashRefundRequests] Search Petty Cash Refund Requests Fail',
  SEARCH_PETTY_CASH_REFUND_REQUESTS_SUCCESS = '[Finances] [PettyCashRefundRequests] Search Petty Cash Refund Requests Success',

  FIND_PETTY_CASH_REFUND_REQUEST = '[Finances] [PettyCashRefundRequests] Find Petty Cash Refund Request',
  FIND_PETTY_CASH_REFUND_REQUEST_FAIL = '[Finances] [PettyCashRefundRequests] Find Petty Cash Refund Request Fail',
  FIND_PETTY_CASH_REFUND_REQUEST_SUCCESS = '[Finances] [PettyCashRefundRequests] Find Petty Cash Refund Request Success',

  CREATE_PETTY_CASH_REFUND_REQUEST = '[Finances] [PettyCashRefundRequests] Create Petty Cash Refund Request',
  CREATE_PETTY_CASH_REFUND_REQUEST_FAIL = '[Finances] [PettyCashRefundRequests] Create Petty Cash Refund Request Fail',
  CREATE_PETTY_CASH_REFUND_REQUEST_SUCCESS = '[Finances] [PettyCashRefundRequests] Create Petty Cash Refund Request Success',

  UPDATE_PETTY_CASH_REFUND_REQUEST = '[Finances] [PettyCashRefundRequests] Update Petty Cash Refund Request',
  UPDATE_PETTY_CASH_REFUND_REQUEST_FAIL = '[Finances] [PettyCashRefundRequests] Update Petty Cash Refund Request Fail',
  UPDATE_PETTY_CASH_REFUND_REQUEST_SUCCESS = '[Finances] [PettyCashRefundRequests] Update Petty Cash Refund Request Success',
}

/**
 * Represents a store petty cash refund requests search action.
 */
export class SearchPettyCashRefundRequests implements Action {
  /**
   * The type of the action.
   */
  readonly type = PettyCashRefundRequestsActionType.SEARCH_PETTY_CASH_REFUND_REQUESTS;

  /**
   * Fires a new petty cash refund requests search action.
   * @param payload the search parameters,
   * if omitted, all petty cash refund requests will be loaded.
   */
  constructor(
    public payload?: {
      pettyCash: number[];
      fromDate: Date;
      toDate: Date;
      page: number;
    }
  ) {}
}

/**
 * Represents a store petty cash refund requests search-fail action.
 */
export class SearchPettyCashRefundRequestsFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = PettyCashRefundRequestsActionType.SEARCH_PETTY_CASH_REFUND_REQUESTS_FAIL;

  /**
   * Fires a new petty cash refund requests search-fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store petty cash refund requests search-success action.
 */
export class SearchPettyCashRefundRequestsSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = PettyCashRefundRequestsActionType.SEARCH_PETTY_CASH_REFUND_REQUESTS_SUCCESS;

  /**
   * Fires a new petty cash refund requests search-success action.
   * @param payload An object contains the list of petty cash refund requests that matches the search criteria
   * in addition to the pagination info.
   */
  constructor(public payload: AppHttpResponse<PettyCashRefundRequest[]>) {}
}

/**
 * Represents a store find-petty-cash-refund-request action.
 */
export class FindPettyCashRefundRequest implements Action {
  /**
   * The type of the action.
   */
  readonly type = PettyCashRefundRequestsActionType.FIND_PETTY_CASH_REFUND_REQUEST;

  /**
   * Fires a new Find petty cash refund request action.
   * @param payload the id of the petty cash refund request.
   */
  constructor(public payload: number) {}
}

/**
 * Represents a store find-petty-cash-refund-request-fail action.
 */
export class FindPettyCashRefundRequestFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = PettyCashRefundRequestsActionType.FIND_PETTY_CASH_REFUND_REQUEST_FAIL;

  /**
   * Fires a new find-petty-cash-refund-request-fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store Find-petty-cash-refund-request-success action.
 */
export class FindPettyCashRefundRequestSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = PettyCashRefundRequestsActionType.FIND_PETTY_CASH_REFUND_REQUEST_SUCCESS;

  /**
   * Fires a new find-petty-cash-refund-request-success action.
   * @param payload The loaded petty-cash-refund-request.
   */
  constructor(public payload: AppHttpResponse<PettyCashRefundRequest>) {}
}

/**
 * Represents a store petty cash refund request create action.
 */
export class CreatePettyCashRefundRequest implements Action {
  /** The type of the action. */
  readonly type = PettyCashRefundRequestsActionType.CREATE_PETTY_CASH_REFUND_REQUEST;

  /**
   * Fires a new petty cash refund request create action.
   * @param payload the new petty cash refund request data-model.
   */
  constructor(public payload: CreatePettyCashRefundRequestInput) {}
}

/**
 * Represents a store petty cash refund request create-fail action.
 */
export class CreatePettyCashRefundRequestFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = PettyCashRefundRequestsActionType.CREATE_PETTY_CASH_REFUND_REQUEST_FAIL;

  /**
   * Fires a new petty cash refund request create-fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store petty cash refund request create-success action.
 */
export class CreatePettyCashRefundRequestSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = PettyCashRefundRequestsActionType.CREATE_PETTY_CASH_REFUND_REQUEST_SUCCESS;

  /**
   * Fires a new petty cash refund request create-success action.
   * @param payload The created petty cash refund request.
   */
  constructor(public payload: AppHttpResponse<PettyCashRefundRequest>) {}
}

/**
 * Represents a store petty cash refund request update action.
 */
export class UpdatePettyCashRefundRequest implements Action {
  /** The type of the action. */
  readonly type = PettyCashRefundRequestsActionType.UPDATE_PETTY_CASH_REFUND_REQUEST;

  /**
   * Fires a new petty cash refund request update action.
   * @param payload the new petty cash refund request data-model.
   */
  constructor(public payload: UpdatePettyCashRefundRequestInput) {}
}

/**
 * Represents a store petty cash refund request update-fail action.
 */
export class UpdatePettyCashRefundRequestFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = PettyCashRefundRequestsActionType.UPDATE_PETTY_CASH_REFUND_REQUEST_FAIL;

  /**
   * Fires a new petty cash refund request update-fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store petty cash refund request update-success action.
 */
export class UpdatePettyCashRefundRequestSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = PettyCashRefundRequestsActionType.UPDATE_PETTY_CASH_REFUND_REQUEST_SUCCESS;

  /**
   * Fires a new petty cash refund request update-success action.
   * @param payload The updated petty cash refund request.
   */
  constructor(public payload: AppHttpResponse<PettyCashRefundRequest>) {}
}

/**
 * Finances-module petty cash refund requests action types.
 */
export type PettyCashRefundRequestsActions =
  | SearchPettyCashRefundRequests
  | SearchPettyCashRefundRequestsFail
  | SearchPettyCashRefundRequestsSuccess
  | FindPettyCashRefundRequest
  | FindPettyCashRefundRequestFail
  | FindPettyCashRefundRequestSuccess
  | CreatePettyCashRefundRequest
  | CreatePettyCashRefundRequestFail
  | CreatePettyCashRefundRequestSuccess
  | UpdatePettyCashRefundRequest
  | UpdatePettyCashRefundRequestFail
  | UpdatePettyCashRefundRequestSuccess;
