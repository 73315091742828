<!-- page header starts -->
<app-page-header [info]="pageInfo"></app-page-header>
<!-- page header ends -->

<!-- boards list starts -->
<div class="row">
  <div class="col-md-12">
    <div class="card">
      <div class="card-header mb-3">
        <h4 class="card-title">{{ 'SHARED.SEARCH.SEARCH_TOOLS' | translate }}</h4>
      </div>
      <div class="card-content">
        <div class="px-3">
          <div class="row">
            <div class="col-md-12">
              <form class="row" [formGroup]="searchForm" (ngSubmit)="search()">
                <div class="form-group col">
                  <label for="description">{{ 'SALES.BOARDS.BOARD_DATA.DESCRIPTION' | translate }}</label>
                  <input
                    id="description"
                    type="text"
                    class="form-control round"
                    [placeholder]="'SALES.BOARDS.BOARD_DATA.DESCRIPTION_SEARCH_PLACEHOLDER' | translate"
                    formControlName="description"
                    (input)="search($event)"
                  />
                </div>
                <div class="form-group col">
                  <label for="locations">{{ 'SALES.BOARDS.BOARD_DATA.LOCATION' | translate }}</label>
                  <ng-select
                    class="round"
                    formControlName="locations"
                    [multiple]="true"
                    [closeOnSelect]="false"
                    labelForId="locations"
                    searchable="true"
                    [placeholder]="'SALES.BOARDS.BOARD_DATA.LOCATION_PLACEHOLDER' | translate"
                    [notFoundText]="'SHARED.SEARCH.SEARCH_NOT_FOUND' | translate"
                    (open)="locationsListVisibility = true"
                  >
                    <ng-option *ngFor="let location of locations" [value]="location.id">
                      {{ location.name | localize: location.nameEn | async }}
                    </ng-option>
                  </ng-select>
                </div>
                <div class="col-md-auto form-group d-flex">
                  <button
                    type="submit"
                    class="btn btn-raised btn-primary align-self-end round mx-1 mb-0"
                    [disabled]="isSearching$ | async"
                  >
                    <i class="fa fa-search"></i>
                  </button>

                  <a
                    *ngIf="[Claims.CREATE_BOARD] | authorize | async"
                    routerLink="/sales/boards/create"
                    class="btn btn-raised btn-success align-self-end round mx-1 mb-0"
                  >
                    <i class="fa fa-plus"></i>
                    <span class="mx-1">{{ 'SHARED.CONFIRM.ADD' | translate }}</span>
                  </a>
                  <app-export-tools
                    *ngIf="(boards$ | async)?.length"
                    class="d-flex align-self-end mx-1"
                    targetElementId="boards-board-export-target-element"
                    [outputFileName]="'SHARED.EXPORT_TABLES.BOARDS_BOARD' | translate"
                  ></app-export-tools>
                </div>
              </form>
            </div>
            <div class="col-md-12" *ngIf="(boards$ | async)?.length; else noItems">
              <div class="row">
                <div class="col-md-12 alert alert-light text-center" role="alert">
                  {{ 'SHARED.SEARCH.SEARCH_RESULTS' | translate }} ({{ (paginationInfo$ | async).total | toNumber }})
                  {{ 'SALES.BOARDS.BOARD' | translate }}
                </div>
                <div class="col-md-12">
                  <div class="table-responsive">
                    <table class="table text-center" id="boards-board-export-target-element">
                      <thead>
                        <tr>
                          <th scope="col"><i class="fa fa-list-ol"></i></th>
                          <th scope="col">{{ 'SALES.BOARDS.BOARD_DATA.DESCRIPTION' | translate }}</th>
                          <th scope="col">{{ 'SALES.BOARDS.BOARD_DATA.LOCATION' | translate }}</th>
                          <th scope="col">{{ 'SHARED.TOOLS' | translate }}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let board of boards$ | async; trackBy: trackElements; let i = index">
                          <th scope="row">{{ i + 1 }}</th>
                          <td>{{ board.description }}</td>
                          <td>
                            {{ board.location.name | localize: board.location.nameEn | async }}
                          </td>
                          <td>
                            <a
                              *ngIf="[Claims.UPDATE_BOARD] | authorize | async"
                              class="info p-0"
                              [routerLink]="['/sales/boards/update', board.id]"
                              [ngbTooltip]="'SALES.BOARDS.UPDATE_BOARD' | translate"
                            >
                              <i class="fa fa-edit font-medium-3 mr-2"></i>
                            </a>
                            <a
                              *ngIf="[Claims.DELETE_BOARD] | authorize | async"
                              class="danger p-0"
                              [ngbTooltip]="'SHARED.CONFIRM.DELETE' | translate"
                              (click)="selectedBoard = board; openModal(deleteModalRef)"
                            >
                              <i class="fa fa-close font-medium-3 mr-2"></i>
                            </a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div class="d-flex justify-content-between p-2">
                      <ngb-pagination
                        *ngIf="paginationInfo$ | async; let pagination"
                        maxSize="5"
                        [collectionSize]="pagination.total"
                        [pageSize]="pagination.pageSize"
                        [page]="pagination.page"
                        (pageChange)="pageChanged($event)"
                        boundaryLinks="true"
                      >
                        <ng-template ngbPaginationFirst>{{
                          'SHARED.PAGINATION.PAGINATION_FIRST' | translate
                        }}</ng-template>
                        <ng-template ngbPaginationFirst>{{
                          'SHARED.PAGINATION.PAGINATION_PREVIOUS' | translate
                        }}</ng-template>
                        <ng-template ngbPaginationFirst>{{
                          'SHARED.PAGINATION.PAGINATION_NEXT' | translate
                        }}</ng-template>
                        <ng-template ngbPaginationFirst>{{
                          'SHARED.PAGINATION.PAGINATION_LAST' | translate
                        }}</ng-template>
                      </ngb-pagination>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- boards list ends -->

<!-- no items template starts -->
<ng-template #noItems>
  <div class="col-md-12 alert alert-warning mt-3 text-center" role="alert">
    {{ 'SALES.BOARDS.SEARCH_NOT_FOUND' | translate }}

    <a
      *ngIf="[Claims.CREATE_BOARD] | authorize | async"
      routerLink="/sales/boards/create"
      class="btn btn-raised btn-success round"
    >
      <i class="fa fa-plus"></i>
      <span class="mx-1">{{ 'SHARED.CONFIRM.ADD' | translate }}</span>
    </a>
  </div>
</ng-template>
<!-- no items template ends -->

<!-- confirm delete all products modal starts -->
<ng-template #deleteModalRef let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title text-danger" id="modal-basic-title">
      {{ 'SALES.BOARDS.CONFIRM_DELETE_BOARD_TITLE' | translate }}
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="d()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p class="text-danger">{{ 'SALES.BOARDS.CONFIRM_DELETE_BOARD_MESSAGE' | translate }}</p>
  </div>
  <div class="modal-footer">
    <div class="modal-actions">
      <button
        type="button"
        class="btn btn-raised btn-danger round"
        (click)="c(); delete(selectedBoard.id)"
        ngbAutofocus
        [disabled]="isDeleting$ | async"
      >
        <i class="fa fa-check"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.DELETE' | translate }}</span>
      </button>
      <button type="button" class="btn btn-outline-dark round" (click)="c()">
        <i class="fa fa-times"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.CANCEL' | translate }}</span>
      </button>
    </div>
  </div>
</ng-template>
<!-- confirm modal ends -->

<!-- locations search starts -->
<app-locations-search [(visible)]="locationsListVisibility" (locations)="selectLocations($event)">
</app-locations-search>
<!-- locations search ends -->
