import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';

import { AppHttpResponse } from 'shared';
import {
  CreateProductInput,
  Product,
  ProductStock,
  UpdateProductInput,
  CreateProductDiscountInput,
  UpdateProductSalesDiscountInput,
  ProductSalesDiscount,
  ProductUnitOfMeasureRate,
  UpdateProductUnitOfMeasureRateInput,
} from 'stores/models';

/**
 * The products services includes functionality to create, search, findById, update, updateProductDiscount, delete a product.
 */

@Injectable()
export class ProductsService {
  /**
   * The relative route for the products.
   * No leading or trailing slashes required.
   */
  private productsApi = 'stores/products';
  private productSalesDiscountsApi = 'stores/products/product-sales-discounts';
  private productsSalesDiscountsApi = 'stores/products/products-sales-discounts';
  private productsUnitOfMeasureRatesApi = 'stores/products/products-unit-of-measure-rates';

  constructor(private http: HttpClient) {}

  /**
   * Creates a new product from the provided data.
   * @param data The new product data.
   */
  public create(data: CreateProductInput): Observable<AppHttpResponse<Product>> {
    const formData: any = new FormData();
    formData.append('description', data.description);
    formData.append('descriptionEn', data.descriptionEn);
    formData.append('barcode', data.barcode);
    formData.append('isService', data.isService);
    formData.append('hasExpireDate', data.hasExpireDate);
    formData.append('productClassId', data.productClassId);
    formData.append('projectSubTaskId', data.projectSubTaskId);
    formData.append('unitOfMeasureId', data.unitOfMeasureId);
    formData.append('tax', data.tax);
    formData.append('purchasePrice', data.purchasePrice);
    formData.append('salePrice', data.salePrice);
    formData.append('itemCode', data.itemCode);
    formData.append('hasSerialNumber', data.hasSerialNumber);
    formData.append('hasSubProducts', data.hasSubProducts);
    formData.append('hasIngredients', data.hasIngredients);
    formData.append('subProductHasIngredients', data.subProductHasIngredients);
    formData.append('showProductInSalesScreen', data.showProductInSalesScreen);

    if (data.hasSubProducts) {
      for (let index = 0; index < data.subProducts.length; index++) {
        const subProduct = data.subProducts[index];

        for (let variantIndex = 0; variantIndex < subProduct.subProductVariants.length; variantIndex++) {
          const variantId = subProduct.subProductVariants[variantIndex];

          formData.append(`subProducts[${index}][subProductVariants][${variantIndex}]`, variantId);
        }

        formData.append(`subProducts[${index}][purchasePrice]`, subProduct.purchasePrice);
        formData.append(`subProducts[${index}][salePrice]`, subProduct.salePrice);
        for (let ingredientIndex = 0; ingredientIndex < subProduct.subProductIngredients.length; ingredientIndex++) {
          const ingredient = subProduct.subProductIngredients[ingredientIndex];

          formData.append(
            `subProducts[${index}][subProductIngredients][${ingredientIndex}][ingredientId]`,
            ingredient.ingredientId
          );
          formData.append(
            `subProducts[${index}][subProductIngredients][${ingredientIndex}][quantity]`,
            ingredient.quantity
          );
        }
      }
    } else if (data.hasIngredients) {
      for (let ingredientIndex = 0; ingredientIndex < data.productIngredients.length; ingredientIndex++) {
        const productIngredient = data.productIngredients[ingredientIndex];

        formData.append(`productIngredients[${ingredientIndex}][ingredientId]`, productIngredient.ingredientId);
        formData.append(`productIngredients[${ingredientIndex}][quantity]`, productIngredient.quantity);
      }
    }

    /**
     * Append locations to the form data.
     */
    for (let index = 0; index < data.locations.length; index++) {
      const locationId = data.locations[index];
      formData.append(`locations[${index}]`, locationId);
    }

    /**
     * Append sales discounts to the form data.
     */
    for (let index = 0; index < data.productSalesDiscounts.length; index++) {
      formData.append(`productSalesDiscounts[${index}][locationId]`, data.productSalesDiscounts[index].locationId);
      formData.append(`productSalesDiscounts[${index}][discount]`, data.productSalesDiscounts[index].discount);
      formData.append(
        `productSalesDiscounts[${index}][discountPercent]`,
        data.productSalesDiscounts[index].discountPercent
      );
    }

    /**
     * Append unit of measure rate to the form data.
     */
    for (let index = 0; index < data.productUnitOfMeasureRates.length; index++) {
      formData.append(
        `productUnitOfMeasureRates[${index}][unitOfMeasureId]`,
        data.productUnitOfMeasureRates[index].unitOfMeasureId
      );

      formData.append(`productUnitOfMeasureRates[${index}][rate]`, data.productUnitOfMeasureRates[index].rate);
    }

    if (data.photo) {
      formData.append('photo', data.photo, data.photo.name);
    }

    return this.http.post<AppHttpResponse<Product>>(`${this.productsApi}`, formData);
  }

  /**
   * Searches in the products by description and other search terms.
   * @param description The description of the product.
   * @param classes The classes of the product.
   * @param locations The locations of the product.
   * @param searchForStorableProducts The searchForStorableProducts of the product.
   * @param searchForServiceProducts The searchForServiceProducts of the product.
   * @param searchForAllProductTypes The searchForAllProductTypes of the product.
   * @param page The current pagination page number.
   * @param pageSize The maximum number of products allowed per one pagination page.
   */
  public search(
    description: string,
    classes: number[],
    locations: number[],
    searchForStorableProducts: boolean,
    searchForServiceProducts: boolean,
    searchForAllProductTypes: boolean,
    page: number,
    pageSize: number
  ): Observable<AppHttpResponse<Product[]>> {
    const params = new HttpParams()
      .set('description', description)
      .set('classes', classes.join(','))
      .set('locations', locations.join(','))
      .set('searchForStorableProducts', searchForStorableProducts.toString())
      .set('searchForServiceProducts', searchForServiceProducts.toString())
      .set('searchForAllProductTypes', searchForAllProductTypes.toString())
      .set('page', page.toString())
      .set('pageSize', pageSize.toString());
    return this.http.get<AppHttpResponse<Product[]>>(`${this.productsApi}`, { params });
  }

  /**
   * Searches in the products discounts by description and other search terms.
   * @param description The description of the product.
   * @param classes The classes of the product.
   * @param locations The locations of the product.
   * @param page The current pagination page number.
   * @param pageSize The maximum number of products allowed per one pagination page.
   */
  public searchProductSalesDiscounts(
    description: string,
    classes: number[],
    locations: number[],
    page: number,
    pageSize: number
  ): Observable<AppHttpResponse<ProductSalesDiscount[]>> {
    const params = new HttpParams()
      .set('description', description)
      .set('classes', classes.join(','))
      .set('locations', locations.join(','))
      .set('page', page.toString())
      .set('pageSize', pageSize.toString());
    return this.http.get<AppHttpResponse<ProductSalesDiscount[]>>(`${this.productsSalesDiscountsApi}`, { params });
  }

  /**
   * Searches in the products by description and other search terms, without pagination.
   * @param description The description of the product.
   * @param classes The classes of the product.
   * @param searchForStorableProducts The searchForStorableProducts of the product.
   * @param searchForServiceProducts The searchForServiceProducts of the product.
   * @param searchForAllProductTypes The searchForAllProductTypes of the product.
   */
  public searchWithoutPagination(
    description: string,
    classes: number[],
    locations: number[],
    searchForStorableProducts: boolean,
    searchForServiceProducts: boolean,
    searchForAllProductTypes: boolean
  ): Observable<AppHttpResponse<Product[]>> {
    const params = new HttpParams()
      .set('description', description)
      .set('classes', classes.join(','))
      .set('locations', locations.join(','))
      .set('searchForStorableProducts', searchForStorableProducts.toString())
      .set('searchForServiceProducts', searchForServiceProducts.toString())
      .set('searchForAllProductTypes', searchForAllProductTypes.toString());
    return this.http.get<AppHttpResponse<Product[]>>(`${this.productsApi}/no-pagination`, { params });
  }

  /**
   * Searches in the product for sales screen.
   */
  public searchProductsForSalesScreen(defaultLocation: number[]): Observable<AppHttpResponse<Product[]>> {
    const params = new HttpParams().set('locations', defaultLocation.join(','));
    return this.http.get<AppHttpResponse<Product[]>>(`${this.productsApi}/products-for-sales-screen`, { params });
  }

  /**
   * Finds the product with the given id.
   * @param productId The id of the product.
   */
  public findById(productId: number): Observable<AppHttpResponse<Product>> {
    return this.http.get<AppHttpResponse<Product>>(`${this.productsApi}/${productId}`);
  }

  /**
   * Finds the product with the given code or barcode.
   * @param codeOrBarcode The code or barcode of the product.
   */
  public findByCodeOrBarcode(codeOrBarcode: number | string): Observable<AppHttpResponse<Product>> {
    return this.http.get<AppHttpResponse<Product>>(`${this.productsApi}/find-by-code-or-barcode/${codeOrBarcode}`);
  }

  /**
   * Updates an existing product data using the provided data.
   * @param data The updated product data.
   */
  public update(data: UpdateProductInput): Observable<AppHttpResponse<Product>> {
    return this.http.put<AppHttpResponse<Product>>(`${this.productsApi}`, data);
  }

  /**
   * Creates an existing product discount data using the provided data.
   * @param data The created product discount data.
   */
  public createProductDiscount(data: CreateProductDiscountInput): Observable<AppHttpResponse<number>> {
    return this.http.post<AppHttpResponse<number>>(`${this.productsApi}/discounts`, data);
  }

  /**
   * Deletes the product by given id.
   * @param productId The id of the product.
   */
  public delete(productId: number): Observable<AppHttpResponse<Product>> {
    return this.http.delete<AppHttpResponse<Product>>(`${this.productsApi}/${productId}`);
  }

  /** Returns all products stock. */
  public getAllIProductsStockByProductId(productId: number): Observable<AppHttpResponse<ProductStock>> {
    return this.http.get<AppHttpResponse<ProductStock>>(`${this.productsApi}/${productId}/stock`);
  }

  /**
   * Updates an existing product's photo.
   * @param productId The id of the product.
   * @param photo The new product's photo.
   */
  public updatePhoto(productId: number, photo: File): Observable<AppHttpResponse<Product>> {
    const formData: any = new FormData();
    formData.append('photo', photo, photo.name);

    return this.http.put<AppHttpResponse<Product>>(`${this.productsApi}/photo/${productId}`, formData);
  }

  /**
   * Deletes the product photo by given product id.
   * @param productId The id of the product.
   */
  public deletePhoto(productId: number): Observable<AppHttpResponse<Product>> {
    return this.http.delete<AppHttpResponse<Product>>(`${this.productsApi}/photo/${productId}`);
  }

  /**
   * Updates an existing product discount data using the provided data.
   * @param data The updated product discount data.
   */
  public updateProductSalesDiscount(
    data: UpdateProductSalesDiscountInput
  ): Observable<AppHttpResponse<ProductSalesDiscount>> {
    return this.http.post<AppHttpResponse<ProductSalesDiscount>>(`${this.productSalesDiscountsApi}`, data);
  }

  /**
   * Deletes the product sales discount by given id.
   * @param ProductSalesDiscountId The id of the product sales discount.
   */
  public deleteProductSalesDiscount(ProductSalesDiscountId: number): Observable<AppHttpResponse<ProductSalesDiscount>> {
    return this.http.delete<AppHttpResponse<ProductSalesDiscount>>(
      `${this.productSalesDiscountsApi}/${ProductSalesDiscountId}`
    );
  }

  /**
   * Updates an existing product unit of measure rate data using the provided data.
   * @param data The updated product unit of measure rate data.
   */
  public updateProductUnitOfMeasureRate(
    data: UpdateProductUnitOfMeasureRateInput
  ): Observable<AppHttpResponse<ProductUnitOfMeasureRate>> {
    return this.http.post<AppHttpResponse<ProductUnitOfMeasureRate>>(`${this.productsUnitOfMeasureRatesApi}`, data);
  }

  /**
   * Deletes the product  unit of measure rate by given id.
   * @param ProductUnitOfMeasureRateId The id of the product  unit of measure rate.
   */
  public deleteProductUnitOfMeasureRate(
    ProductUnitOfMeasureRateId: number
  ): Observable<AppHttpResponse<ProductUnitOfMeasureRate>> {
    return this.http.delete<AppHttpResponse<ProductUnitOfMeasureRate>>(
      `${this.productsUnitOfMeasureRatesApi}/${ProductUnitOfMeasureRateId}`
    );
  }
}
