<form class="pos-search" [formGroup]="searchForm">
  <div class="row">
    <div class="col-md-12">
      <ul class="nav-tabs" ngbNav #nav="ngbNav" [(activeId)]="activePage" [destroyOnHide]="false">
        <li ngbNavItem="classes">
          <a ngbNavLink>
            <i class="fa fa-television" [class.success]="activePage == 'classes'"></i>
            {{ 'SALES.INVOICES.POS.GROUPS_TITLE' | translate }}
          </a>
          <ng-template ngbNavContent>
            <div class="row mb-1">
              <div class="form-group col mb-0">
                <input
                  type="text"
                  class="form-control round"
                  [placeholder]="'SALES.INVOICES.POS.POS_DATA.PRODUCTS_GROUP_NAME_SEARCH_PLACEHOLDER' | translate"
                  formControlName="productClassName"
                  (input)="searchProductClassesInputValue($event, $event.target.value)"
                />
              </div>
              <div class="form-group col-auto mb-0">
                <button
                  type="button"
                  class="btn btn-raised btn-primary round"
                  [ngbTooltip]="'SALES.INVOICES.POS.POS_DATA.UPDATE' | translate"
                  (click)="initForm(); updateProductClasses()"
                >
                  <i class="fa fa-refresh"></i>
                </button>
              </div>
            </div>
            <div class="row">
              <div class="col-12 items-container">
                <div
                  class="item-container"
                  *ngFor="let productClass of productClassesForSalesScreen; trackBy: trackItems"
                >
                  <div class="product-item parent-item" (click)="selectProductClass(productClass.id)">
                    <i class="item-img-placeholder fa fa-television" *ngIf="!productClass.photoUrl"></i>
                    <img [src]="productClass.photoUrl" class="item-img" *ngIf="productClass.photoUrl" />
                    <span
                      *ngIf="currentLang === 'ar'"
                      class="item-name pointer"
                      [ngbTooltip]="productClass.name.length > 20 ? productClass.name : ''"
                      placement="bottom"
                    >
                      {{ productClass.name | slice: 0:20 }}
                    </span>
                    <span
                      *ngIf="currentLang === 'en'"
                      class="item-name"
                      [ngbTooltip]="productClass.nameEn.length > 20 ? productClass.nameEn : ''"
                      placement="bottom"
                    >
                      {{ productClass.nameEn | slice: 0:20 }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </ng-template>
        </li>
        <li ngbNavItem="products">
          <a ngbNavLink>
            <i class="fa fa-shopping-bag" [class.success]="activePage == 'products'"></i>
            {{ 'SALES.INVOICES.POS.POS_DATA.PRODUCTS' | translate }}
          </a>
          <ng-template ngbNavContent>
            <div class="row mb-1">
              <div class="form-group col mb-0">
                <input
                  id="pos-product-search-by-code"
                  type="text"
                  class="form-control round"
                  [placeholder]="
                    'SALES.INVOICES.POS.POS_DATA.PRODUCT_DESCRIPTION_BARCODE_CODE_SEARCH_PLACEHOLDER' | translate
                  "
                  autocomplete="off"
                  (input)="searchProductByCode($event, $event.target.value)"
                />
              </div>
              <div class="form-group col-auto mb-0">
                <button
                  type="button"
                  class="btn btn-raised btn-primary round"
                  (click)="productsListVisibility = true"
                  [ngbTooltip]="'SALES.INVOICES.POS.POS_DATA.ADVANCED_SEARCH' | translate"
                >
                  <i class="fa fa-search"></i>
                </button>
                <button
                  type="button"
                  class="btn btn-raised btn-primary round mx-1"
                  (click)="initForm(); updateProducts()"
                  [ngbTooltip]="'SALES.INVOICES.POS.POS_DATA.UPDATE' | translate"
                >
                  <i class="fa fa-refresh"></i>
                </button>
              </div>
            </div>
            <div class="row">
              <div class="col-12 items-container">
                <div class="item-container" *ngFor="let product of productsForSalesScreen; trackBy: trackItems">
                  <div class="product-item" (click)="selectProducts([product])">
                    <i class="item-img-placeholder fa fa-shopping-bag" *ngIf="!product.photoUrl"></i>
                    <img [src]="product.photoUrl" class="item-img" *ngIf="product.photoUrl" />
                    <span
                      *ngIf="currentLang === 'ar'"
                      class="item-name pointer"
                      [ngbTooltip]="product.description.length > 20 ? product.description : ''"
                      placement="bottom"
                    >
                      {{ product.description | slice: 0:20 }}
                    </span>
                    <span
                      *ngIf="currentLang === 'en'"
                      class="item-name"
                      [ngbTooltip]="product.descriptionEn.length > 20 ? product.descriptionEn : ''"
                      placement="bottom"
                    >
                      {{ product.descriptionEn | slice: 0:20 }}
                    </span>
                    <span class="item-price">
                      {{ getProductPriceWithTax(product) | number: '1.2-2' }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </ng-template>
        </li>
      </ul>
      <div [ngbNavOutlet]="nav" class="mt-2"></div>
    </div>
  </div>
</form>

<!-- products search starts -->
<app-products-search
  [closeOnSelect]="false"
  [showPurchasePrice]="false"
  [showSalePrice]="true"
  (products)="selectProducts($event)"
  [(visible)]="productsListVisibility"
  [searchForAllProductTypes]="true"
>
</app-products-search>
<!-- products search ends -->
