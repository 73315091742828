import { Injectable } from '@angular/core';

import { of } from 'rxjs';
import { switchMap, map, catchError, tap } from 'rxjs/operators';
import { Effect, ofType, Actions } from '@ngrx/effects';

import { NotificationService, StatusCode, PAGINATION, TranslationService } from 'shared';
import { UnitOfMeasureClassesService } from 'stores/services';
import {
  CreateUnitOfMeasureClass,
  CreateUnitOfMeasureClassFail,
  CreateUnitOfMeasureClassSuccess,
  DeleteUnitOfMeasureClass,
  DeleteUnitOfMeasureClassFail,
  DeleteUnitOfMeasureClassSuccess,
  FindUnitOfMeasureClass,
  FindUnitOfMeasureClassFail,
  FindUnitOfMeasureClassSuccess,
  SearchUnitOfMeasureClasses,
  SearchUnitOfMeasureClassesFail,
  SearchUnitOfMeasureClassesSuccess,
  UnitOfMeasureClassesActionType,
  UpdateUnitOfMeasureClass,
  UpdateUnitOfMeasureClassFail,
  UpdateUnitOfMeasureClassSuccess,
} from 'stores/store/actions';

@Injectable()
export class UnitOfMeasureClassesEffects {
  constructor(
    private actions$: Actions,
    private unitOfMeasureClassesService: UnitOfMeasureClassesService,
    private notificationService: NotificationService,
    private translationService: TranslationService
  ) {}

  /* ========================= SEARCH_UNIT_OF_MEASURE_CLASSES =================================== */
  @Effect()
  search$ = this.actions$.pipe(
    ofType(UnitOfMeasureClassesActionType.SEARCH_UNIT_OF_MEASURE_CLASSES),
    switchMap(({ payload }: SearchUnitOfMeasureClasses) =>
      this.unitOfMeasureClassesService.search(payload.name, payload.page, PAGINATION.UnitOfMeasureClass).pipe(
        map((response) => new SearchUnitOfMeasureClassesSuccess(response)),
        catchError((error) => of(new SearchUnitOfMeasureClassesFail(error)))
      )
    )
  );

  /* ========================= FIND_UNIT_OF_MEASURE_CLASS =================================== */
  @Effect()
  find$ = this.actions$.pipe(
    ofType(UnitOfMeasureClassesActionType.FIND_UNIT_OF_MEASURE_CLASS),
    switchMap((action: FindUnitOfMeasureClass) =>
      this.unitOfMeasureClassesService.findById(action.payload).pipe(
        map((response) => new FindUnitOfMeasureClassSuccess(response)),
        catchError((error) => of(new FindUnitOfMeasureClassFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  findFail$ = this.actions$.pipe(
    ofType(UnitOfMeasureClassesActionType.FIND_UNIT_OF_MEASURE_CLASS_FAIL),
    tap((action: FindUnitOfMeasureClassFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate('STORES.UNIT_OF_MEASURE_CLASSES.UNIT_OF_MEASURE_CLASS_NOT_FOUND')
        );
      }
    })
  );

  /* ========================= CREATE_UNIT_OF_MEASURE_CLASS =================================== */
  @Effect()
  create$ = this.actions$.pipe(
    ofType(UnitOfMeasureClassesActionType.CREATE_UNIT_OF_MEASURE_CLASS),
    switchMap((action: CreateUnitOfMeasureClass) =>
      this.unitOfMeasureClassesService.create(action.payload).pipe(
        map((response) => new CreateUnitOfMeasureClassSuccess(response)),
        catchError((error) => of(new CreateUnitOfMeasureClassFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  createSuccess$ = this.actions$.pipe(
    ofType(UnitOfMeasureClassesActionType.CREATE_UNIT_OF_MEASURE_CLASS_SUCCESS),
    tap((action: CreateUnitOfMeasureClassSuccess) => {
      this.notificationService.success(
        this.translationService.translate('STORES.UNIT_OF_MEASURE_CLASSES.UNIT_OF_MEASURE_CLASS_ADDED')
      );
    })
  );

  @Effect({ dispatch: false })
  createFail$ = this.actions$.pipe(
    ofType(UnitOfMeasureClassesActionType.CREATE_UNIT_OF_MEASURE_CLASS_FAIL),
    tap((action: CreateUnitOfMeasureClassFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );

  /* ========================= UPDATE_UNIT_OF_MEASURE_CLASS =================================== */
  @Effect()
  update$ = this.actions$.pipe(
    ofType(UnitOfMeasureClassesActionType.UPDATE_UNIT_OF_MEASURE_CLASS),
    switchMap((action: UpdateUnitOfMeasureClass) =>
      this.unitOfMeasureClassesService.update(action.payload).pipe(
        map((response) => new UpdateUnitOfMeasureClassSuccess(response)),
        catchError((error) => of(new UpdateUnitOfMeasureClassFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  updateSuccess$ = this.actions$.pipe(
    ofType(UnitOfMeasureClassesActionType.UPDATE_UNIT_OF_MEASURE_CLASS_SUCCESS),
    tap((action: UpdateUnitOfMeasureClassSuccess) => {
      this.notificationService.success(
        this.translationService.translate('STORES.UNIT_OF_MEASURE_CLASSES.UNIT_OF_MEASURE_CLASS_UPDATED')
      );
    })
  );

  @Effect({ dispatch: false })
  updateFail$ = this.actions$.pipe(
    ofType(UnitOfMeasureClassesActionType.UPDATE_UNIT_OF_MEASURE_CLASS_FAIL),
    tap((action: UpdateUnitOfMeasureClassFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate('STORES.UNIT_OF_MEASURE_CLASSES.UNIT_OF_MEASURE_CLASS_NOT_FOUND')
        );
      } else if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );

  /* ========================= DELETE_UNIT_OF_MEASURE_CLASS =================================== */
  @Effect()
  delete$ = this.actions$.pipe(
    ofType(UnitOfMeasureClassesActionType.DELETE_UNIT_OF_MEASURE_CLASS),
    switchMap((action: DeleteUnitOfMeasureClass) =>
      this.unitOfMeasureClassesService.delete(action.payload).pipe(
        map((response) => new DeleteUnitOfMeasureClassSuccess(response)),
        catchError((error) => of(new DeleteUnitOfMeasureClassFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  deleteSuccess$ = this.actions$.pipe(
    ofType(UnitOfMeasureClassesActionType.DELETE_UNIT_OF_MEASURE_CLASS_SUCCESS),
    tap((action: DeleteUnitOfMeasureClassSuccess) => {
      this.notificationService.success(
        this.translationService.translate('STORES.UNIT_OF_MEASURE_CLASSES.UNIT_OF_MEASURE_CLASS_DELETED')
      );
    })
  );

  @Effect({ dispatch: false })
  deleteFail$ = this.actions$.pipe(
    ofType(UnitOfMeasureClassesActionType.DELETE_UNIT_OF_MEASURE_CLASS_FAIL),
    tap((action: DeleteUnitOfMeasureClassFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate('STORES.UNIT_OF_MEASURE_CLASSES.UNIT_OF_MEASURE_CLASS_NOT_FOUND')
        );
      } else if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );
}
