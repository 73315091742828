import { createSelector } from '@ngrx/store';

import { StoresState, VariantState } from 'stores/store/states';
import { selectStoresState } from 'stores/store/selectors/feature.selectors';

/**
 * Gets the variants state.
 */
const selectVariantState = createSelector(selectStoresState, (state: StoresState) => state.variants);

/**
 * Gets the list of loaded variants.
 */
export const getVariants = createSelector(selectVariantState, (state: VariantState) => state.data);

/**
 * Gets the variants pagination info.
 */
export const getVariantsPaginationInfo = createSelector(
  selectVariantState,
  (state: VariantState) => state.paginationInfo
);

/**
 * Gets the error that occurred in the state.
 */
export const getVariantsError = createSelector(selectVariantState, (state: VariantState) => state.error);

/**
 * Determines if there is a running search process.
 */
export const getVariantsSearching = createSelector(selectVariantState, (state: VariantState) => state.isSearching);

/**
 * Determines if the last search process has been ended successfully.
 */
export const getVariantsSearchCompleted = createSelector(
  selectVariantState,
  (state: VariantState) => state.isSearchCompleted
);

/**
 * Gets the selected variant.
 */
export const getSelectedVariant = createSelector(selectVariantState, (state: VariantState) => state.selectedVariant);

/**
 * Determines if there is a running find-variant process.
 */
export const getVariantsFinding = createSelector(selectVariantState, (state: VariantState) => state.isFinding);

/**
 * Determines if the last find-variant process has been ended successfully.
 */
export const getVariantsFindCompleted = createSelector(
  selectVariantState,
  (state: VariantState) => state.isFindCompleted
);

/**
 * Determines if there is a running create process.
 */
export const getSelectedVariantCreating = createSelector(selectVariantState, (state: VariantState) => state.isCreating);

/**
 * Determines if the last create process has been ended successfully.
 */
export const getSelectedVariantCreateCompleted = createSelector(
  selectVariantState,
  (state: VariantState) => state.isCreateCompleted
);

/**
 * Determines if there is a running update process.
 */
export const getSelectedVariantUpdating = createSelector(selectVariantState, (state: VariantState) => state.isUpdating);

/**
 * Determines if the last update process has been ended successfully.
 */
export const getSelectedVariantUpdateCompleted = createSelector(
  selectVariantState,
  (state: VariantState) => state.isUpdateCompleted
);

/**
 * Determines if there is a running delete process.
 */
export const getSelectedVariantDeleting = createSelector(selectVariantState, (state: VariantState) => state.isDeleting);

/**
 * Determines if the last delete process has been ended successfully.
 */
export const getSelectedVariantDeleteCompleted = createSelector(
  selectVariantState,
  (state: VariantState) => state.isDeleteCompleted
);
