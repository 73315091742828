import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';

import { Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { tap } from 'rxjs/operators';

import { NotificationService, PaginationInfo, TranslationService } from 'shared';
import * as fromFinancesStore from 'finances/store';
import { PettyCash, PettyCashRefundRequest } from 'finances/models';
import { Claims } from 'security/models';

@Component({
  selector: 'app-petty-cash-refund-requests-search',
  templateUrl: './petty-cash-refund-requests-search.component.html',
  styles: [],
})
export class PettyCashRefundRequestsSearchComponent implements OnInit {
  /**
   * The modal template reference.
   */
  @ViewChild('modalRef') modalRef: ElementRef<any>;

  /**
   * Indicates whether the modal will automatically close after select the first product.
   *
   * Be default it will not be closed.
   */
  @Input() closeOnSelect = false;

  /**
   * Indicates whether the products displayed on the list should be all active.
   *
   * Be default it will not be closed.
   */
  @Input() onlyActive = false;

  /**
   * Outputs a value that indicates whether the modal is shown or not.
   *
   * Handles visibility change in two-data-binding approach.
   */
  @Output() visibleChange = new EventEmitter<boolean>();

  /** Outputs the selected petty cash refund request. */
  @Output() pettyCashRefundRequest = new EventEmitter<PettyCashRefundRequest[]>();

  /**
   * Sets the visibility of this component modal.
   *
   * Handles visibility change in two-data-binding approach.
   */
  @Input() set visible(isShown: boolean) {
    if (isShown && this.modalRef) {
      this.openModal(this.modalRef);
    } else {
      this.closeModal();
    }

    this.visibleChange.emit(isShown);
  }

  /**
   * The system supported user claims.
   */
  Claims = Claims;

  /**
   * The list of petty cash refund request.
   */
  pettyCashRefundRequests$: Observable<PettyCashRefundRequest[]>;

  /**
   * The list of petty cash refund request.
   */
  pettyCashRefundRequestsList: PettyCashRefundRequest[];

  /**
   * The list of selected petty cash.
   */
  pettyCash: PettyCash[] = [];

  /**
   * The pagination info.
   */
  paginationInfo$: Observable<PaginationInfo>;

  /**
   * Indicates whether there is a search process is running or not.
   */
  isSearching$: Observable<boolean>;

  /**
   * The initial `fromDate` value for search.
   */
  initialFromDate = new Date();

  /**
   * The initial `toDate` value for search.
   * It's the current day of month.
   */
  initialToDate = new Date();

  /**
   * The search form.
   */
  searchForm: FormGroup;

  /**
   * The ngb-modal reference to use it when modal needed to be closed.
   */
  modal: NgbModalRef;

  /**
   * Shows or hide the petty cash list.
   */
  pettyCashListVisibility = false;

  /**
   *
   * @param modalService The modal service.
   * @param financesStore$ The finances store.
   * @param notificationService The  notification service.
   * @param translationService The translation service.
   */
  constructor(
    private modalService: NgbModal,
    private financesStore$: Store<fromFinancesStore.FinancesState>,
    private notificationService: NotificationService,
    private translationService: TranslationService
  ) {}

  ngOnInit(): void {
    this.init();
  }

  /**
   * Initialize component data.
   */
  init() {
    this.initForm();

    /**
     * Load data.
     */
    this.isSearching$ = this.financesStore$.pipe(select(fromFinancesStore.getPettyCashRefundSearching));

    let isManualSearchTriggeredBefore = false;
    this.pettyCashRefundRequests$ = this.financesStore$.pipe(
      select(fromFinancesStore.getPettyCashRefundRequests),
      tap((requests) => {
        if (requests) {
          this.pettyCashRefundRequestsList = requests;
        }
        if (!isManualSearchTriggeredBefore && !requests.length) {
          isManualSearchTriggeredBefore = true;
          this.search();
        }
      })
    );

    this.paginationInfo$ = this.financesStore$.pipe(select(fromFinancesStore.getPettyCashRefundRequestsPaginationInfo));
  }

  /**
   * Initialize form and add validators.
   */
  initForm() {
    this.searchForm = new FormGroup({
      pettyCash: new FormControl([]),
      fromDate: new FormControl({
        year: this.initialFromDate.getFullYear(),
        month: this.initialFromDate.getMonth() + 1,
        day: this.initialFromDate.getDate(),
      }),
      toDate: new FormControl({
        year: this.initialToDate.getFullYear(),
        month: this.initialToDate.getMonth() + 1,
        day: this.initialToDate.getDate(),
      }),
      page: new FormControl(1),
    });
  }

  /**
   * Handles search parameters change.
   */
  search(event?: KeyboardEvent) {
    if (event && event.key === 'Enter') {
      event.preventDefault();
      return;
    }

    this.applyFiltersAndSearch();
  }

  /**
   * Handles pagination page-changed event.
   * @param page The current selected page number.
   */
  pageChanged(page: number) {
    /** Update pagination page. */
    this.searchForm.patchValue({ page });

    this.applyFiltersAndSearch(page);
  }

  /**
   * Applies the search filters and dispatches a search action.
   * @param page The current page number, default to `1`.
   */
  applyFiltersAndSearch(page: number = 1) {
    if (!this.searchForm.get('fromDate')?.value || !this.searchForm.get('toDate')?.value) {
      return this.notificationService.warning(
        this.translationService.translate('FINANCES.PETTY_CASH_REFUND_REQUESTS.NOTIFICATION_MESSAGE.SELECT_DATE')
      );
    }

    const fromDate = new Date(
      this.searchForm.get('fromDate').value.year,
      this.searchForm.get('fromDate').value.month - 1,
      this.searchForm.get('fromDate').value.day
    );
    fromDate.setHours(0, 0, 0, 0);

    const toDate = new Date(
      this.searchForm.get('toDate').value.year,
      this.searchForm.get('toDate').value.month - 1,
      this.searchForm.get('toDate').value.day
    );
    toDate.setHours(23, 59, 59, 0);
    this.financesStore$.dispatch(
      new fromFinancesStore.SearchPettyCashRefundRequests({
        pettyCash: this.searchForm.get('pettyCash').value,
        fromDate,
        toDate,
        page,
      })
    );
  }

  /**
   * Enables trackBy feature for *ngFor utility to track items
   * depending on a comparer to reduce DOM change.
   * If the item is already exist then no HTML will change, only bounded values.
   * @param index The index of the item.
   * @param pettyCashRefund The item to determine if it was changed or not.
   */
  trackItems(index: number, pettyCashRefundRequest: PettyCashRefundRequest) {
    return pettyCashRefundRequest ? pettyCashRefundRequest.id : undefined;
  }

  /**
   * Opens the modal of the given templateRef.
   * @param modalRef The modal templateRef to be opened.
   */
  openModal(modalRef) {
    this.modal = this.modalService.open(modalRef, { size: 'xl' });
    this.modal.result.then(null, () => this.closeModal());
  }

  /**
   * Handles petty cash refund request selected event.
   * @param petty cash The list of petty cash refund request to notify selected.
   */
  selectPettyCashRefundRequest(pettyCashRefundRequest: PettyCashRefundRequest[]) {
    this.notificationService.successInstant(
      this.translationService.translate(
        'FINANCES.PETTY_CASH_REFUND_REQUESTS.NOTIFICATION_MESSAGE.PETTY_CASH_REFUND_REQUEST_SELECTED'
      )
    );
    this.pettyCashRefundRequest.emit(pettyCashRefundRequest);
    if (this.closeOnSelect) {
      this.closeModal();
    }
  }

  /**
   * Closes the currently opened modal.
   */
  closeModal() {
    this.modal?.dismiss();
    this.pettyCashRefundRequest.emit([]);
    this.visibleChange.emit(false);
  }

  /**
   * Adds the newly selected petty cash the petty cash search list.
   * @param pettyCash The list of newly selected petty cash to be added.
   */
  selectPettyCash(pettyCash: PettyCash[]) {
    const selectedIds: number[] = this.searchForm.get('pettyCash').value;
    this.pettyCash = [...this.pettyCash.filter((petty) => selectedIds.includes(petty.id)), ...pettyCash];
    this.searchForm.patchValue({ pettyCash: this.pettyCash.map((petty) => petty.id) });
  }
}
