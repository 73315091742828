import * as fromJournals from 'finances/containers/journals';
import * as fromBanksAccounts from 'finances/containers/banks-accounts';
import * as fromHr from 'finances/containers/hr';

export * from 'finances/containers/journals';
export * from 'finances/containers/banks-accounts';
export * from 'finances/containers/hr';

/**
 * The set of containers registered on this module.
 */
export const CONTAINERS: any[] = [...fromJournals.CONTAINERS, ...fromBanksAccounts.CONTAINERS, ...fromHr.CONTAINERS];
