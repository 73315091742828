import { Injectable } from '@angular/core';

import { of } from 'rxjs';
import { switchMap, map, catchError } from 'rxjs/operators';
import { Effect, ofType, Actions } from '@ngrx/effects';

import { LookupsService } from 'lookups/services';
import {
  AssetExclusionReasonActionType,
  GetAllAssetExclusionReasonFail,
  GetAllAssetExclusionReasonSuccess,
} from 'lookups/store/actions';

@Injectable()
export class AssetExclusionReasonEffects {
  constructor(private actions$: Actions, private lookupsService: LookupsService) {}

  /* ========================= GET_ALL_ASSET_EXCLUSION_REASON =================================== */
  @Effect()
  getAllAssetExclusionReasons$ = this.actions$.pipe(
    ofType(AssetExclusionReasonActionType.GET_ALL_ASSET_EXCLUSION_REASON),
    switchMap(() =>
      this.lookupsService.getAllAssetExclusionReasons().pipe(
        map((response) => new GetAllAssetExclusionReasonSuccess(response)),
        catchError((error) => of(new GetAllAssetExclusionReasonFail(error)))
      )
    )
  );
}
