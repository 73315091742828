import { Injectable } from '@angular/core';

import { of } from 'rxjs';
import { switchMap, map, catchError, tap } from 'rxjs/operators';
import { Effect, ofType, Actions } from '@ngrx/effects';

import { NotificationService, StatusCode, PAGINATION, TranslationService } from 'shared';
import { PosPrinterAdjustmentService } from 'sales/services';
import {
  PosPrinterAdjustmentActionType,
  CreateKitchenPrinter,
  CreateKitchenPrinterFail,
  CreateKitchenPrinterSuccess,
  DeleteKitchenPrinter,
  DeleteKitchenPrinterFail,
  DeleteKitchenPrinterSuccess,
  FindKitchenPrinter,
  FindKitchenPrinterFail,
  FindKitchenPrinterSuccess,
  SearchKitchenPrinters,
  SearchKitchenPrintersFail,
  SearchKitchenPrintersSuccess,
  UpdateKitchenPrinter,
  UpdateKitchenPrinterFail,
  UpdateKitchenPrinterSuccess,
  CreateCasherPrinter,
  CreateCasherPrinterFail,
  CreateCasherPrinterSuccess,
  DeleteCasherPrinter,
  DeleteCasherPrinterFail,
  DeleteCasherPrinterSuccess,
  FindCasherPrinter,
  FindCasherPrinterFail,
  FindCasherPrinterSuccess,
  SearchCasherPrinters,
  SearchCasherPrintersFail,
  SearchCasherPrintersSuccess,
  UpdateCasherPrinter,
  UpdateCasherPrinterFail,
  UpdateCasherPrinterSuccess,
  CreateOrderDeliveryPrinter,
  CreateOrderDeliveryPrinterFail,
  CreateOrderDeliveryPrinterSuccess,
  DeleteOrderDeliveryPrinter,
  DeleteOrderDeliveryPrinterFail,
  DeleteOrderDeliveryPrinterSuccess,
  FindOrderDeliveryPrinter,
  FindOrderDeliveryPrinterFail,
  FindOrderDeliveryPrinterSuccess,
  SearchOrderDeliveryPrinters,
  SearchOrderDeliveryPrintersFail,
  SearchOrderDeliveryPrintersSuccess,
  UpdateOrderDeliveryPrinter,
  UpdateOrderDeliveryPrinterFail,
  UpdateOrderDeliveryPrinterSuccess,
} from 'sales/store/actions';

@Injectable()
export class PosPrinterAdjustmentsEffects {
  constructor(
    private actions$: Actions,
    private posPrinterAdjustmentService: PosPrinterAdjustmentService,
    private notificationService: NotificationService,
    private translationService: TranslationService
  ) {}

  /* ========================= SEARCH_KITCHEN_PRINTERS =================================== */
  @Effect()
  searchKitchenPrinter$ = this.actions$.pipe(
    ofType(PosPrinterAdjustmentActionType.SEARCH_KITCHEN_PRINTERS),
    switchMap(({ payload }: SearchKitchenPrinters) =>
      this.posPrinterAdjustmentService
        .searchKitchenPrinter(
          payload.productClasses,
          payload.posPrinters,
          payload.posDevices,
          payload.locations,
          payload.page,
          PAGINATION.KitchenPrinters
        )
        .pipe(
          map((response) => new SearchKitchenPrintersSuccess(response)),
          catchError((error) => of(new SearchKitchenPrintersFail(error)))
        )
    )
  );

  /* ========================= SEARCH_CASHER_PRINTERS =================================== */
  @Effect()
  searchCasherPrinter$ = this.actions$.pipe(
    ofType(PosPrinterAdjustmentActionType.SEARCH_CASHER_PRINTERS),
    switchMap(({ payload }: SearchCasherPrinters) =>
      this.posPrinterAdjustmentService
        .searchCasherPrinter(
          payload.posPrinters,
          payload.posDevices,
          payload.locations,
          payload.page,
          PAGINATION.CasherPrinters
        )
        .pipe(
          map((response) => new SearchCasherPrintersSuccess(response)),
          catchError((error) => of(new SearchCasherPrintersFail(error)))
        )
    )
  );

  /* ========================= SEARCH_ORDER_DELIVERY_PRINTERS =================================== */
  @Effect()
  searchOrderDeliveryPrinter$ = this.actions$.pipe(
    ofType(PosPrinterAdjustmentActionType.SEARCH_ORDER_DELIVERY_PRINTERS),
    switchMap(({ payload }: SearchOrderDeliveryPrinters) =>
      this.posPrinterAdjustmentService
        .searchOrderDeliveryPrinter(
          payload.orderTypes,
          payload.posPrinters,
          payload.posDevices,
          payload.locations,
          payload.page,
          PAGINATION.OrderDeliveryPrinters
        )
        .pipe(
          map((response) => new SearchOrderDeliveryPrintersSuccess(response)),
          catchError((error) => of(new SearchOrderDeliveryPrintersFail(error)))
        )
    )
  );

  /* ========================= FIND_KITCHEN_PRINTER =================================== */
  @Effect()
  findKitchenPrinter$ = this.actions$.pipe(
    ofType(PosPrinterAdjustmentActionType.FIND_KITCHEN_PRINTER),
    switchMap((action: FindKitchenPrinter) =>
      this.posPrinterAdjustmentService.findKitchenPrinterById(action.payload).pipe(
        map((response) => new FindKitchenPrinterSuccess(response)),
        catchError((error) => of(new FindKitchenPrinterFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  findKitchenPrinterFail$ = this.actions$.pipe(
    ofType(PosPrinterAdjustmentActionType.FIND_KITCHEN_PRINTER_FAIL),
    tap((action: FindKitchenPrinterFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate('SALES.KITCHEN_PRINTERS.KITCHEN_PRINTER_NOT_FOUND')
        );
      }
    })
  );

  /* ========================= FIND_CASHER_PRINTER =================================== */
  @Effect()
  findCasherPrinter$ = this.actions$.pipe(
    ofType(PosPrinterAdjustmentActionType.FIND_CASHER_PRINTER),
    switchMap((action: FindCasherPrinter) =>
      this.posPrinterAdjustmentService.findCasherPrinterById(action.payload).pipe(
        map((response) => new FindCasherPrinterSuccess(response)),
        catchError((error) => of(new FindCasherPrinterFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  findCasherPrinterFail$ = this.actions$.pipe(
    ofType(PosPrinterAdjustmentActionType.FIND_CASHER_PRINTER_FAIL),
    tap((action: FindCasherPrinterFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate('SALES.CASHER_PRINTERS.CASHER_PRINTER_NOT_FOUND')
        );
      }
    })
  );

  /* ========================= FIND_ORDER_DELIVERY_PRINTER =================================== */
  @Effect()
  findOrderDeliveryPrinter$ = this.actions$.pipe(
    ofType(PosPrinterAdjustmentActionType.FIND_ORDER_DELIVERY_PRINTER),
    switchMap((action: FindOrderDeliveryPrinter) =>
      this.posPrinterAdjustmentService.findOrderDeliveryPrinterById(action.payload).pipe(
        map((response) => new FindOrderDeliveryPrinterSuccess(response)),
        catchError((error) => of(new FindOrderDeliveryPrinterFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  findOrderDeliveryPrinterFail$ = this.actions$.pipe(
    ofType(PosPrinterAdjustmentActionType.FIND_ORDER_DELIVERY_PRINTER_FAIL),
    tap((action: FindOrderDeliveryPrinterFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate('SALES.ORDER_DELIVERY_PRINTERS.ORDER_DELIVERY_PRINTER_NOT_FOUND')
        );
      }
    })
  );

  /* ========================= CREATE_KITCHEN_PRINTER =================================== */
  @Effect()
  createKitchenPrinter$ = this.actions$.pipe(
    ofType(PosPrinterAdjustmentActionType.CREATE_KITCHEN_PRINTER),
    switchMap((action: CreateKitchenPrinter) =>
      this.posPrinterAdjustmentService.createKitchenPrinter(action.payload).pipe(
        map((response) => new CreateKitchenPrinterSuccess(response)),
        catchError((error) => of(new CreateKitchenPrinterFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  createKitchenPrinterSuccess$ = this.actions$.pipe(
    ofType(PosPrinterAdjustmentActionType.CREATE_KITCHEN_PRINTER_SUCCESS),
    tap((action: CreateKitchenPrinterSuccess) => {
      this.notificationService.success(
        this.translationService.translate('SALES.KITCHEN_PRINTERS.KITCHEN_PRINTER_ADDED')
      );
    })
  );

  @Effect({ dispatch: false })
  createKitchenPrinterFail$ = this.actions$.pipe(
    ofType(PosPrinterAdjustmentActionType.CREATE_KITCHEN_PRINTER_FAIL),
    tap((action: CreateKitchenPrinterFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );

  /* ========================= CREATE_CASHER_PRINTER =================================== */
  @Effect()
  createCasherPrinter$ = this.actions$.pipe(
    ofType(PosPrinterAdjustmentActionType.CREATE_CASHER_PRINTER),
    switchMap((action: CreateCasherPrinter) =>
      this.posPrinterAdjustmentService.createCasherPrinter(action.payload).pipe(
        map((response) => new CreateCasherPrinterSuccess(response)),
        catchError((error) => of(new CreateCasherPrinterFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  createCasherPrinterSuccess$ = this.actions$.pipe(
    ofType(PosPrinterAdjustmentActionType.CREATE_CASHER_PRINTER_SUCCESS),
    tap((action: CreateCasherPrinterSuccess) => {
      this.notificationService.success(this.translationService.translate('SALES.CASHER_PRINTERS.CASHER_PRINTER_ADDED'));
    })
  );

  @Effect({ dispatch: false })
  createCasherPrinterFail$ = this.actions$.pipe(
    ofType(PosPrinterAdjustmentActionType.CREATE_CASHER_PRINTER_FAIL),
    tap((action: CreateCasherPrinterFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );

  /* ========================= CREATE_ORDER_DELIVERY_PRINTER =================================== */
  @Effect()
  createOrderDeliveryPrinter$ = this.actions$.pipe(
    ofType(PosPrinterAdjustmentActionType.CREATE_ORDER_DELIVERY_PRINTER),
    switchMap((action: CreateOrderDeliveryPrinter) =>
      this.posPrinterAdjustmentService.createOrderDeliveryPrinter(action.payload).pipe(
        map((response) => new CreateOrderDeliveryPrinterSuccess(response)),
        catchError((error) => of(new CreateOrderDeliveryPrinterFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  createOrderDeliveryPrinterSuccess$ = this.actions$.pipe(
    ofType(PosPrinterAdjustmentActionType.CREATE_ORDER_DELIVERY_PRINTER_SUCCESS),
    tap((action: CreateOrderDeliveryPrinterSuccess) => {
      this.notificationService.success(
        this.translationService.translate('SALES.ORDER_DELIVERY_PRINTERS.ORDER_DELIVERY_PRINTER_ADDED')
      );
    })
  );

  @Effect({ dispatch: false })
  createOrderDeliveryPrinterFail$ = this.actions$.pipe(
    ofType(PosPrinterAdjustmentActionType.CREATE_ORDER_DELIVERY_PRINTER_FAIL),
    tap((action: CreateOrderDeliveryPrinterFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );

  /* ========================= UPDATE_KITCHEN_PRINTER =================================== */
  @Effect()
  updateKitchenPrinter$ = this.actions$.pipe(
    ofType(PosPrinterAdjustmentActionType.UPDATE_KITCHEN_PRINTER),
    switchMap((action: UpdateKitchenPrinter) =>
      this.posPrinterAdjustmentService.updateKitchenPrinter(action.payload).pipe(
        map((response) => new UpdateKitchenPrinterSuccess(response)),
        catchError((error) => of(new UpdateKitchenPrinterFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  updateKitchenPrinterSuccess$ = this.actions$.pipe(
    ofType(PosPrinterAdjustmentActionType.UPDATE_KITCHEN_PRINTER_SUCCESS),
    tap((action: UpdateKitchenPrinterSuccess) => {
      this.notificationService.success(
        this.translationService.translate('SALES.KITCHEN_PRINTERS.KITCHEN_PRINTER_UPDATED')
      );
    })
  );

  @Effect({ dispatch: false })
  updateKitchenPrinterFail$ = this.actions$.pipe(
    ofType(PosPrinterAdjustmentActionType.UPDATE_KITCHEN_PRINTER_FAIL),
    tap((action: UpdateKitchenPrinterFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate('SALES.KITCHEN_PRINTERS.KITCHEN_PRINTER_NOT_FOUND')
        );
      } else if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );

  /* ========================= UPDATE_CASHER_PRINTER =================================== */
  @Effect()
  updateCasherPrinter$ = this.actions$.pipe(
    ofType(PosPrinterAdjustmentActionType.UPDATE_CASHER_PRINTER),
    switchMap((action: UpdateCasherPrinter) =>
      this.posPrinterAdjustmentService.updateCasherPrinter(action.payload).pipe(
        map((response) => new UpdateCasherPrinterSuccess(response)),
        catchError((error) => of(new UpdateCasherPrinterFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  updateCasherPrinterSuccess$ = this.actions$.pipe(
    ofType(PosPrinterAdjustmentActionType.UPDATE_CASHER_PRINTER_SUCCESS),
    tap((action: UpdateCasherPrinterSuccess) => {
      this.notificationService.success(
        this.translationService.translate('SALES.CASHER_PRINTERS.CASHER_PRINTER_UPDATED')
      );
    })
  );

  @Effect({ dispatch: false })
  updateCasherPrinterFail$ = this.actions$.pipe(
    ofType(PosPrinterAdjustmentActionType.UPDATE_CASHER_PRINTER_FAIL),
    tap((action: UpdateCasherPrinterFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate('SALES.CASHER_PRINTERS.CASHER_PRINTER_NOT_FOUND')
        );
      } else if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );

  /* ========================= UPDATE_ORDER_DELIVERY_PRINTER =================================== */
  @Effect()
  updateOrderDeliveryPrinter$ = this.actions$.pipe(
    ofType(PosPrinterAdjustmentActionType.UPDATE_ORDER_DELIVERY_PRINTER),
    switchMap((action: UpdateOrderDeliveryPrinter) =>
      this.posPrinterAdjustmentService.updateOrderDeliveryPrinter(action.payload).pipe(
        map((response) => new UpdateOrderDeliveryPrinterSuccess(response)),
        catchError((error) => of(new UpdateOrderDeliveryPrinterFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  updateOrderDeliveryPrinterSuccess$ = this.actions$.pipe(
    ofType(PosPrinterAdjustmentActionType.UPDATE_ORDER_DELIVERY_PRINTER_SUCCESS),
    tap((action: UpdateOrderDeliveryPrinterSuccess) => {
      this.notificationService.success(
        this.translationService.translate('SALES.ORDER_DELIVERY_PRINTERS.ORDER_DELIVERY_PRINTER_UPDATED')
      );
    })
  );

  @Effect({ dispatch: false })
  updateOrderDeliveryPrinterFail$ = this.actions$.pipe(
    ofType(PosPrinterAdjustmentActionType.UPDATE_ORDER_DELIVERY_PRINTER_FAIL),
    tap((action: UpdateOrderDeliveryPrinterFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate('SALES.ORDER_DELIVERY_PRINTERS.ORDER_DELIVERY_PRINTER_NOT_FOUND')
        );
      } else if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );

  /* ========================= DELETE_KITCHEN_PRINTER =================================== */
  @Effect()
  deleteKitchenPrinter$ = this.actions$.pipe(
    ofType(PosPrinterAdjustmentActionType.DELETE_KITCHEN_PRINTER),
    switchMap((action: DeleteKitchenPrinter) =>
      this.posPrinterAdjustmentService.deleteKitchenPrinter(action.payload).pipe(
        map((response) => new DeleteKitchenPrinterSuccess(response)),
        catchError((error) => of(new DeleteKitchenPrinterFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  deleteKitchenPrinterSuccess$ = this.actions$.pipe(
    ofType(PosPrinterAdjustmentActionType.DELETE_KITCHEN_PRINTER_SUCCESS),
    tap((action: DeleteKitchenPrinterSuccess) => {
      this.notificationService.success(
        this.translationService.translate('SALES.KITCHEN_PRINTERS.KITCHEN_PRINTER_DELETED')
      );
    })
  );

  @Effect({ dispatch: false })
  deleteKitchenPrinterFail$ = this.actions$.pipe(
    ofType(PosPrinterAdjustmentActionType.DELETE_KITCHEN_PRINTER_FAIL),
    tap((action: DeleteKitchenPrinterFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate('SALES.KITCHEN_PRINTERS.KITCHEN_PRINTER_NOT_FOUND')
        );
      } else if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );

  /* ========================= DELETE_CASHER_PRINTER =================================== */
  @Effect()
  deleteCasherPrinter$ = this.actions$.pipe(
    ofType(PosPrinterAdjustmentActionType.DELETE_CASHER_PRINTER),
    switchMap((action: DeleteCasherPrinter) =>
      this.posPrinterAdjustmentService.deleteCasherPrinter(action.payload).pipe(
        map((response) => new DeleteCasherPrinterSuccess(response)),
        catchError((error) => of(new DeleteCasherPrinterFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  deleteCasherPrinterSuccess$ = this.actions$.pipe(
    ofType(PosPrinterAdjustmentActionType.DELETE_CASHER_PRINTER_SUCCESS),
    tap((action: DeleteCasherPrinterSuccess) => {
      this.notificationService.success(
        this.translationService.translate('SALES.CASHER_PRINTERS.CASHER_PRINTER_DELETED')
      );
    })
  );

  @Effect({ dispatch: false })
  deleteCasherPrinterFail$ = this.actions$.pipe(
    ofType(PosPrinterAdjustmentActionType.DELETE_CASHER_PRINTER_FAIL),
    tap((action: DeleteCasherPrinterFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate('SALES.CASHER_PRINTERS.CASHER_PRINTER_NOT_FOUND')
        );
      } else if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );

  /* ========================= DELETE_ORDER_DELIVERY_PRINTER =================================== */
  @Effect()
  deleteOrderDeliveryPrinter$ = this.actions$.pipe(
    ofType(PosPrinterAdjustmentActionType.DELETE_ORDER_DELIVERY_PRINTER),
    switchMap((action: DeleteOrderDeliveryPrinter) =>
      this.posPrinterAdjustmentService.deleteOrderDeliveryPrinter(action.payload).pipe(
        map((response) => new DeleteOrderDeliveryPrinterSuccess(response)),
        catchError((error) => of(new DeleteOrderDeliveryPrinterFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  deleteOrderDeliveryPrinterSuccess$ = this.actions$.pipe(
    ofType(PosPrinterAdjustmentActionType.DELETE_ORDER_DELIVERY_PRINTER_SUCCESS),
    tap((action: DeleteOrderDeliveryPrinterSuccess) => {
      this.notificationService.success(
        this.translationService.translate('SALES.ORDER_DELIVERY_PRINTERS.ORDER_DELIVERY_PRINTER_DELETED')
      );
    })
  );

  @Effect({ dispatch: false })
  deleteOrderDeliveryPrinterFail$ = this.actions$.pipe(
    ofType(PosPrinterAdjustmentActionType.DELETE_ORDER_DELIVERY_PRINTER_FAIL),
    tap((action: DeleteCasherPrinterFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate('SALES.ORDER_DELIVERY_PRINTERS.ORDER_DELIVERY_PRINTER_NOT_FOUND')
        );
      } else if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );
}
