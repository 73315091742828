<!-- page header starts -->
<app-page-header [info]="pageInfo"></app-page-header>
<!-- page header ends -->

<!-- create bank account money transfer form starts -->
<div class="card">
  <div class="card-content">
    <div class="px-3 pt-3">
      <form (ngSubmit)="submit()">
        <div class="row">
          <div class="col-md-12">
            <ul class="nav-tabs" ngbNav #nav="ngbNav" [(activeId)]="activePage" [destroyOnHide]="false">
              <li ngbNavItem="details">
                <a ngbNavLink>
                  <i class="fa fa-file-text" [class.success]="activePage == 'details'"></i>
                  {{ 'FINANCES.BANK_ACCOUNTS.MONEY_TRANSFER_DETAILS' | translate }}
                </a>
                <!-- create bank account money transfer starts -->
                <ng-template ngbNavContent>
                  <div class="row">
                    <div class="col-md-12">
                      <form [formGroup]="form">
                        <div class="card">
                          <div class="card-header mb-3">
                            <h4 class="card-title">
                              {{ 'FINANCES.BANK_ACCOUNTS.MONY_TRANSFER_DATA' | translate }}
                            </h4>
                          </div>
                          <!-- bank account money transfer details starts -->
                          <div class="card-content">
                            <div class="px-3">
                              <div class="row">
                                <div class="form-group col-md-6">
                                  <label for="fromBankAccountId">{{
                                    'FINANCES.BANK_ACCOUNTS.BANK_ACCOUNT_DATA.FROM_BANK_ACCOUNT' | translate
                                  }}</label>
                                  <ng-select
                                    class="round"
                                    formControlName="fromBankAccountId"
                                    labelForId="fromBankAccountId"
                                    searchable="true"
                                    [placeholder]="
                                      'FINANCES.BANK_ACCOUNTS.BANK_ACCOUNT_DATA.FROM_BANK_ACCOUNT_PLACEHOLDER'
                                        | translate
                                    "
                                    [notFoundText]="'SHARED.SEARCH.SEARCH_NOT_FOUND' | translate"
                                    (open)="fromBankAccountsListVisibility = true"
                                  >
                                    <ng-option *ngFor="let bankAccount of fromBankAccounts" [value]="bankAccount.id">
                                      {{ bankAccount.bank.name | localize: bankAccount.bank.nameEn | async }} -
                                      {{ bankAccount.name | localize: bankAccount.nameEn | async }}
                                    </ng-option>
                                  </ng-select>
                                </div>
                                <div class="form-group col-md-6">
                                  <label for="toBankAccountId">{{
                                    'FINANCES.BANK_ACCOUNTS.BANK_ACCOUNT_DATA.TO_BANK_ACCOUNT' | translate
                                  }}</label>
                                  <ng-select
                                    class="round"
                                    formControlName="toBankAccountId"
                                    labelForId="toBankAccountId"
                                    searchable="true"
                                    [placeholder]="
                                      'FINANCES.BANK_ACCOUNTS.BANK_ACCOUNT_DATA.TO_BANK_ACCOUNT_PLACEHOLDER' | translate
                                    "
                                    [notFoundText]="'SHARED.SEARCH.SEARCH_NOT_FOUND' | translate"
                                    (open)="toBankAccountsListVisibility = true"
                                  >
                                    <ng-option *ngFor="let bankAccount of toBankAccounts" [value]="bankAccount.id">
                                      {{ bankAccount.bank.name | localize: bankAccount.bank.nameEn | async }} -
                                      {{ bankAccount.name | localize: bankAccount.nameEn | async }}
                                    </ng-option>
                                  </ng-select>
                                </div>
                                <div class="form-group col-md-6">
                                  <label for="transferType">{{
                                    'FINANCES.BANK_ACCOUNTS.BANK_ACCOUNT_DATA.TRANSFER_TYPE' | translate
                                  }}</label>
                                  <ng-select
                                    class="round"
                                    formControlName="transferType"
                                    labelForId="transferType"
                                    searchable="true"
                                    [placeholder]="
                                      'FINANCES.BANK_ACCOUNTS.BANK_ACCOUNT_DATA.TRANSFER_TYPE_PLACEHOLDER' | translate
                                    "
                                    [notFoundText]="'SHARED.SEARCH.SEARCH_NOT_FOUND' | translate"
                                  >
                                    <ng-option *ngFor="let type of transferTypes" [value]="type.key">
                                      {{ type.name | localize: type.nameEn | async }}
                                    </ng-option>
                                  </ng-select>
                                </div>
                                <div class="form-group col-md-6">
                                  <label for="value">{{
                                    'FINANCES.BANK_ACCOUNTS.BANK_ACCOUNT_DATA.TRANSFER_VALUE' | translate
                                  }}</label>
                                  <input
                                    type="text"
                                    [mask]="DECIMAL_MASK"
                                    thousandSeparator=","
                                    minLength="0"
                                    id="value"
                                    class="form-control round"
                                    [placeholder]="
                                      'FINANCES.BANK_ACCOUNTS.BANK_ACCOUNT_DATA.TRANSFER_VALUE_PLACEHOLDER' | translate
                                    "
                                    formControlName="value"
                                  />
                                </div>
                                <div
                                  class="form-group col-md-6"
                                  *ngIf="form.controls.transferType.value === 'BANK_TRANSFER'"
                                >
                                  <label for="bankFee">{{
                                    'FINANCES.BANK_ACCOUNTS.BANK_ACCOUNT_DATA.BANK_FEE' | translate
                                  }}</label>
                                  <input
                                    type="text"
                                    [mask]="DECIMAL_MASK"
                                    thousandSeparator=","
                                    minLength="0"
                                    id="bankFee"
                                    class="form-control round"
                                    [placeholder]="
                                      'FINANCES.BANK_ACCOUNTS.BANK_ACCOUNT_DATA.BANK_FEE_PLACEHOLDER' | translate
                                    "
                                    formControlName="bankFee"
                                  />
                                </div>
                                <div
                                  class="form-group col-md-6"
                                  *ngIf="form.controls.transferType.value === 'BANK_TRANSFER'"
                                >
                                  <label for="bankFeeTaxValue">{{
                                    'FINANCES.BANK_ACCOUNTS.BANK_ACCOUNT_DATA.BANK_FEE_TAX_VALUE' | translate
                                  }}</label>
                                  <input
                                    type="text"
                                    [mask]="DECIMAL_MASK"
                                    thousandSeparator=","
                                    minLength="0"
                                    id="bankFeeTaxValue"
                                    class="form-control round"
                                    [placeholder]="
                                      'FINANCES.BANK_ACCOUNTS.BANK_ACCOUNT_DATA.BANK_FEE_TAX_VALUE_PLACEHOLDER'
                                        | translate
                                    "
                                    formControlName="bankFeeTaxValue"
                                  />
                                </div>
                                <div class="form-group col-md-6">
                                  <label for="transactionDate">{{
                                    'FINANCES.BANK_ACCOUNTS.BANK_ACCOUNT_DATA.TRANSACTION_DATE' | translate
                                  }}</label>
                                  <div class="input-group round">
                                    <input
                                      class="form-control"
                                      id="transactionDate"
                                      name="transactionDate"
                                      formControlName="transactionDate"
                                      [placeholder]="'SHARED.SEARCH.DATE_PLACEHOLDER' | translate"
                                      ngbDatepicker
                                      #transactionDate="ngbDatepicker"
                                    />
                                    <div class="input-group-append">
                                      <span
                                        class="input-group-text gradient-light-blue-indigo"
                                        style="cursor: pointer;"
                                        (click)="transactionDate.toggle()"
                                      >
                                        <i class="fa fa-calendar"></i>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <!-- bank account money transfer details ends -->
                        </div>
                      </form>
                    </div>
                  </div>
                </ng-template>
                <!-- create bank account money transfer ends -->
              </li>

              <li ngbNavItem="journal">
                <a ngbNavLink>
                  <i class="fa fa-calendar" [class.success]="activePage == 'journal'"></i>
                  {{ 'SHARED.JOURNAL_DATA.JOURNAL' | translate }}
                </a>
                <!-- create journal form starts -->
                <ng-template ngbNavContent>
                  <div class="row">
                    <div class="col-md-12">
                      <form [formGroup]="form">
                        <div class="card">
                          <!-- journal starts -->
                          <!-- journal lines details starts -->
                          <div class="card-header mb-3">
                            <h4 class="card-title">{{ 'SHARED.JOURNAL_DATA.JOURNAL_LINES' | translate }}</h4>
                          </div>
                          <div class="card-content">
                            <div class="px-3">
                              <app-journal-line-form
                                [journalLinesForm]="journalLinesForm"
                                [isNotesRequired]="false"
                                [allowAddLine]="false"
                                [allowClearLines]="false"
                                [allowRemoveLine]="false"
                                [initialJournalLines]="initialJournalLinesSubject.asObservable() | async"
                              ></app-journal-line-form>
                            </div>
                          </div>
                          <!-- journal ends -->
                        </div>
                      </form>
                    </div>
                  </div>
                </ng-template>
                <!-- create journal form ends -->
              </li>
            </ul>
            <div [ngbNavOutlet]="nav" class="mt-2"></div>
          </div>
        </div>

        <!-- bank account money transfer tools starts -->
        <div class="row">
          <div class="form-group col-12 d-flex justify-content-between flex-column flex-sm-row mt-2">
            <button type="submit" class="btn btn-raised btn-success round" [disabled]="isCreating$ | async">
              <i class="fa fa-check"></i>
              <span class="mx-1">{{ 'SHARED.CONFIRM.SAVE' | translate }}</span>
            </button>
            <button
              *ngIf="form.controls.value"
              type="button"
              class="btn btn-raised btn-danger round"
              (click)="openResetModal()"
              [disabled]="isCreating$ | async"
            >
              <i class="fa fa-trash"></i>
              <span class="mx-1">{{ 'FINANCES.BANK_ACCOUNTS.BANK_ACCOUNT_DATA.RESET_DATA' | translate }}</span>
            </button>
            <a class="btn btn-dark round" [routerLink]="['/finances/bank-accounts/files', bankAccountId]">
              <i class="fa fa-undo"></i>
              <span class="mx-1">{{
                'FINANCES.BANK_ACCOUNTS.BANK_ACCOUNT_DATA.BANK_ACCOUNTS_SUMMARY_RETURN' | translate
              }}</span>
            </a>
          </div>
        </div>
        <!-- bank account money transfer tools ends -->
      </form>
    </div>
  </div>
</div>
<!-- create bank account money transfer form ends -->

<!-- confirm modal starts -->
<ng-template #confirmModalRef let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
      {{ 'FINANCES.BANK_ACCOUNTS.CONFIRM_CREATE_MONEY_TRANSFER_TITLE' | translate }}
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="d()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p class="text-success">
      {{ 'FINANCES.BANK_ACCOUNTS.CONFIRM_CREATE_MONEY_TRANSFER_MESSAGE' | translate }}
    </p>
  </div>
  <div class="modal-footer">
    <div class="modal-actions">
      <button type="button" class="btn btn-raised btn-success round" (click)="c(); confirm()" ngbAutofocus>
        <i class="fa fa-check"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.SAVE' | translate }}</span>
      </button>
      <button type="button" class="btn btn-outline-dark round" (click)="c()">
        <i class="fa fa-times"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.CANCEL' | translate }}</span>
      </button>
    </div>
  </div>
</ng-template>
<!-- confirm modal ends -->

<!-- confirm reset modal starts -->
<ng-template #resetModalRef let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title text-danger" id="modal-basic-title">
      {{ 'FINANCES.BANK_ACCOUNTS.CONFIRM_RESET_MONEY_TRANSFER_TITLE' | translate }}
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="d()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p class="text-danger">
      {{ 'FINANCES.BANK_ACCOUNTS.CONFIRM_RESET_MONEY_TRANSFER_TITLE_MESSAGE' | translate }}
    </p>
  </div>
  <div class="modal-footer">
    <div class="modal-actions">
      <button type="button" class="btn btn-raised btn-danger round" (click)="c(); resetForm()" ngbAutofocus>
        <i class="fa fa-check"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.YES' | translate }}</span>
      </button>
      <button type="button" class="btn btn-outline-dark round" (click)="c()">
        <i class="fa fa-times"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.CANCEL' | translate }}</span>
      </button>
    </div>
  </div>
</ng-template>
<!-- confirm reset modal ends -->

<!-- from bank accounts search starts -->
<app-bank-accounts-search
  onlyActive="true"
  closeOnSelect="true"
  (bankAccounts)="selectFromBankAccount($event)"
  [(visible)]="fromBankAccountsListVisibility"
>
</app-bank-accounts-search>
<!-- from bank accounts search ends -->

<!-- to bank accounts search starts -->
<app-bank-accounts-search
  onlyActive="true"
  closeOnSelect="true"
  (bankAccounts)="selectToBankAccount($event)"
  [(visible)]="toBankAccountsListVisibility"
>
</app-bank-accounts-search>
<!-- to bank accounts search ends -->
