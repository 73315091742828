<section id="login">
  <div class="container-fluid">
    <div class="row full-height-vh m-0">

      <div class="col-12 d-flex align-items-center justify-content-center">
        <div class="card">
          <div class="card-content">
            <div class="card-body login-img">
              <div class="row m-0 h-100">
                <div class="col-lg-6 d-lg-block d-none py-2 px-3 text-center align-middle img-container h-100" width="400">
                  <!-- <img src="assets/img/erp5.png" alt="" class="img-fluid img-box mt-3" /> -->
  <div class="slogan">
    <span> Every Day Operations in One Application</span>
  </div>
                </div>
                <div class="col-lg-6 col-md-12 bg-white px-4 pt-3">
                  <div class="col-12 d-flex mb-4 align-items-start px-0">
                    <div class="col-6 col-lg-6 col-md-6 px-0 " dir="rtl">
                      <span class="erp-title">ERP</span>
                      <img src="assets/img/logo.png" alt="" class="img-fluid mx-1" width="80" height="100%" />

                  </div>
                    <div class="col-6 col-lg-6 col-md-6 " style="text-align: end;">

                    <span class="lang-switch" ngbDropdown display="dynamic" [placement]="ngbDropdownPosition">
                      <a class="position-relative" dir="ltr" (click)="changeLanguage('en')"  *ngIf="currentLang === 'ar'">
                        <small  class="blue-grey">En</small>
                        <i
                          class="font-medium-3 blue-grey fa fa-globe m-1"

                        ></i>


                      </a>
                      <a class="position-relative " dir="rtl"  (click)="changeLanguage('ar')" *ngIf="currentLang === 'en'">
                        <small class="blue-grey">Ar</small>
                        <i
                          class=" font-medium-3 blue-grey  fa fa-globe m-1"

                        ></i>

                      </a>

                    </span>
                    </div>

                  </div>
                  <form [formGroup]="form" (ngSubmit)="submit()">
                    <h4 class="card-title mb-3">{{ 'AUTH.LOGIN_TITLE' | translate }}</h4>
                    <p class="card-text mb-4">
                      {{ 'AUTH.WELCOME_MESSAGE' | translate }}
                    </p>
                    <input
                      type="text"
                      class="form-control round mb-3"
                      name="username"
                      id="username"
                      formControlName="username"
                      [placeholder]="'AUTH.USER_NAME' | translate"
                    />
                    <input
                      type="password"
                      class="form-control round mb-3"
                      name="password"
                      id="password"
                      formControlName="password"
                      [placeholder]="'AUTH.PASSWORD' | translate"
                    />
                    <div class="d-flex justify-content-between mt-2">
                      <div class="remember-me">
                        <div class="custom-control custom-checkbox custom-control-inline mb-3">
                          <input
                            type="checkbox"
                            class="custom-control-input"
                            id="rememberUser"
                            name="rememberUser"
                            formControlName="rememberUser"
                          />
                          <label class="custom-control-label" for="rememberUser">
                            {{ 'AUTH.REMEMBER_ME' | translate }}
                          </label>
                        </div>
                      </div>
                    </div>
                    <div class="fg-actions d-flex justify-content-between">
                      <div class="login-btn w-100">
                        <button type="submit" class="btn-submit round" [disabled]="isLogin$ | async">
                          {{ 'AUTH.LOGIN' | translate }}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!--Login Page Ends-->
