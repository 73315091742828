import { findIndex, remove } from 'lodash';

import { TaxesActionType, TaxesActions } from 'settings/store/actions';
import { TaxesState } from 'settings/store/states';

/**
 * The initial state from which the state starts.
 */
const initialState: TaxesState = {
  data: [],
  paginationInfo: {
    total: 0,
    page: 1,
    pageSize: 0,
    count: 0,
  },
  error: null,
  isSearching: false,
  isSearchCompleted: false,
  selectedTax: null,
  isFinding: false,
  isFindCompleted: false,
  defaultTax: null,
  isDefaultTaxFinding: false,
  isDefaultTaxFindCompleted: false,
  isCreating: false,
  isCreateCompleted: false,
  isUpdating: false,
  isUpdateCompleted: false,
  isDeleting: false,
  isDeleteCompleted: false,
};

/**
 * The reducer function that is called in each action dispatch against the state.
 * @param state The current state.
 * @param action The action that will affect the state.
 */
export function taxesReducer(state: TaxesState = initialState, action: TaxesActions): TaxesState {
  switch (action.type) {
    //#region SEARCH_TAXES

    case TaxesActionType.SEARCH_TAXES: {
      return {
        ...state,
        isSearching: true,
        isSearchCompleted: false,
        error: null,
      };
    }

    case TaxesActionType.SEARCH_TAXES_FAIL: {
      return {
        ...state,
        error: action.payload,
        isSearching: false,
        isSearchCompleted: false,
      };
    }

    case TaxesActionType.SEARCH_TAXES_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        paginationInfo: {
          ...action.payload.meta,
        },
        error: null,
        isSearching: false,
        isSearchCompleted: true,
      };
    }

    //#endregion SEARCH_TAXES

    //#region FIND_TAX

    case TaxesActionType.FIND_TAX: {
      return {
        ...state,
        selectedTax: null,
        error: null,
        isFinding: true,
        isFindCompleted: false,
      };
    }

    case TaxesActionType.FIND_TAX_FAIL: {
      return {
        ...state,
        error: action.payload,
        isFinding: false,
        isFindCompleted: false,
      };
    }

    case TaxesActionType.FIND_TAX_SUCCESS: {
      let taxes = [...state.data];
      const taxIndex = findIndex(taxes, (tax) => tax.id === action.payload.data.id);

      /* If tax was found, update it. */
      if (taxIndex >= 0) {
        taxes[taxIndex] = action.payload.data;
      } else {
        /* else, insert it to the beginning of the array. */
        taxes = [action.payload.data, ...taxes];
      }

      return {
        ...state,
        data: taxes,
        selectedTax: action.payload.data,
        error: null,
        isFinding: false,
        isFindCompleted: true,
      };
    }

    //#endregion FIND_TAX

    //#region FIND_DEFAULT_TAX

    case TaxesActionType.FIND_DEFAULT_TAX: {
      return {
        ...state,
        isDefaultTaxFinding: true,
        isDefaultTaxFindCompleted: false,
        error: null,
      };
    }

    case TaxesActionType.FIND_DEFAULT_TAX_FAIL: {
      return {
        ...state,
        error: action.payload,
        isDefaultTaxFinding: false,
        isDefaultTaxFindCompleted: false,
      };
    }

    case TaxesActionType.FIND_DEFAULT_TAX_SUCCESS: {
      let taxes = [...state.data];
      const taxIndex = findIndex(taxes, (tax) => tax.id === action.payload.data.id);

      /* If tax was found, update it. */
      if (taxIndex >= 0) {
        taxes[taxIndex] = action.payload.data;
      } else {
        /* else, insert it to the beginning of the array. */
        taxes = [action.payload.data, ...taxes];
      }

      return {
        ...state,
        data: taxes,
        defaultTax: action.payload.data,
        error: null,
        isDefaultTaxFinding: false,
        isDefaultTaxFindCompleted: true,
      };
    }

    //#endregion FIND_DEFAULT_TAX

    //#region CREATE_TAX

    case TaxesActionType.CREATE_TAX: {
      return {
        ...state,
        isCreating: true,
        isCreateCompleted: false,
        error: null,
      };
    }

    case TaxesActionType.CREATE_TAX_FAIL: {
      return {
        ...state,
        error: action.payload,
        isCreating: false,
        isCreateCompleted: false,
      };
    }

    case TaxesActionType.CREATE_TAX_SUCCESS: {
      let taxes = [...state.data];
      const taxIndex = findIndex(taxes, (tax) => tax.id === action.payload.data.id);

      /**
       * Make sure to update the current default `isDefault` property.
       */
      const currentDefaultTaxIndex = taxes.findIndex((tax) => tax.id !== action.payload.data.id && tax.isDefault);

      if (currentDefaultTaxIndex >= 0) {
        taxes[currentDefaultTaxIndex] = {
          ...taxes[currentDefaultTaxIndex],
          isDefault: false,
        };
      }
      /* If tax was found, update it. */
      if (taxIndex >= 0) {
        taxes[taxIndex] = action.payload.data;
      } else {
        /* else, insert it to the beginning of the array. */
        taxes = [action.payload.data, ...taxes];
      }
      return {
        ...state,
        data: taxes,
        selectedTax: action.payload.data,
        error: null,
        isCreating: false,
        isCreateCompleted: true,
      };
    }

    //#endregion CREATE_TAX

    //#region UPDATE_TAX

    case TaxesActionType.UPDATE_TAX: {
      return {
        ...state,
        isUpdating: true,
        isUpdateCompleted: false,
        error: null,
      };
    }

    case TaxesActionType.UPDATE_TAX_FAIL: {
      return {
        ...state,
        error: action.payload,
        isUpdating: false,
        isUpdateCompleted: false,
      };
    }

    case TaxesActionType.UPDATE_TAX_SUCCESS: {
      let taxes = [...state.data];
      const taxIndex = findIndex(taxes, (tax) => tax.id === action.payload.data.id);

      /**
       * Make sure to update the current default `isDefault` property.
       */
      const currentDefaultTaxIndex = taxes.findIndex((tax) => tax.id !== action.payload.data.id && tax.isDefault);

      if (currentDefaultTaxIndex >= 0) {
        taxes[currentDefaultTaxIndex] = {
          ...taxes[currentDefaultTaxIndex],
          isDefault: false,
        };
      }
      /* If tax was found, update it. */
      if (taxIndex >= 0) {
        taxes[taxIndex] = action.payload.data;
      } else {
        /* else, insert it to the beginning of the array. */
        taxes = [action.payload.data, ...taxes];
      }
      return {
        ...state,
        data: taxes,
        selectedTax: action.payload.data,
        error: null,
        isUpdating: false,
        isUpdateCompleted: true,
      };
    }

    //#endregion UPDATE_TAX

    //#region DELETE_TAX

    case TaxesActionType.DELETE_TAX: {
      return {
        ...state,
        isDeleting: true,
        isDeleteCompleted: false,
        error: null,
      };
    }

    case TaxesActionType.DELETE_TAX_FAIL: {
      return {
        ...state,
        error: action.payload,
        isDeleting: false,
        isDeleteCompleted: false,
      };
    }

    case TaxesActionType.DELETE_TAX_SUCCESS: {
      const taxes = [...state.data];

      /* Remove the deleted tax from the array. */
      remove(taxes, (tax) => tax.id === action.payload.data.id);

      return {
        ...state,
        data: taxes,
        selectedTax: action.payload.data,
        error: null,
        isDeleting: false,
        isDeleteCompleted: true,
      };
    }

    //#endregion DELETE_TAX

    default:
      return state;
  }
}
