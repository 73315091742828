import { Component, Input } from '@angular/core';

import { Observable } from 'rxjs';

import { ProjectResource } from 'projects-management/models';
import { PageInfo } from 'shared';

@Component({
  selector: 'app-view-project-resource',
  templateUrl: './view-project-resource.component.html',
  styles: [],
})
export class ViewProjectResourceComponent {
  /**
   * Gets or sets the information about the current page.
   */
  pageInfo: PageInfo = {
    title: 'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.RESOURCES',
    icon: 'fa fa-group',
  };

  /**
   * The data of project resource.
   */
  @Input() projectResource: ProjectResource;
}
