import { CustomerLogType } from 'lookups/models';

/**
 * Represents the customer payment log types state.
 */
export class CustomerPaymentLogTypeState {
  /**
   * The list of customer payment log types.
   */
  public data: CustomerLogType[];

  /**
   * The list of customer payment log types including advance payments.
   */
  public allData: CustomerLogType[];

  /**
   * An error that may resulting during processing some actions.
   */
  public error: any;

  /**
   * Determines if there is a running load process.
   */
  public isLoading: boolean;

  /**
   * Determines if the last load process has been ended successfully.
   */
  public isLoaded: boolean;
}
