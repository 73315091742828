<!-- page header starts -->
<app-page-header [info]="pageInfo"></app-page-header>
<!-- page header ends -->

<!-- petty cash refund requests list starts -->
<div class="row">
  <div class="col-md-12">
    <div class="card">
      <div class="card-header mb-3">
        <h4 class="card-title">{{ 'SHARED.SEARCH.SEARCH_TOOLS' | translate }}</h4>
      </div>
      <div class="card-content">
        <div class="px-3">
          <div class="row">
            <div class="col-md-12">
              <form class="row" [formGroup]="searchForm" (ngSubmit)="search()">
                <div class="form-group col">
                  <label for="pettyCash">{{
                    'FINANCES.PETTY_CASH_REFUND_REQUESTS.PETTY_CASH_REFUND_REQUEST_DATA.PETTY_CASH' | translate
                  }}</label>
                  <ng-select
                    class="round"
                    formControlName="pettyCash"
                    [multiple]="true"
                    [closeOnSelect]="false"
                    labelForId="pettyCash"
                    searchable="true"
                    [placeholder]="
                      'FINANCES.PETTY_CASH_REFUND_REQUESTS.PETTY_CASH_REFUND_REQUEST_DATA.PETTY_CASH_PLACEHOLDER'
                        | translate
                    "
                    [notFoundText]="'SHARED.SEARCH.SEARCH_NOT_FOUND' | translate"
                    (open)="pettyCashListVisibility = true"
                  >
                    <ng-option *ngFor="let petty of pettyCash" [value]="petty.id">
                      {{ petty.description }}
                    </ng-option>
                  </ng-select>
                </div>
                <div class="col">
                  <div class="row">
                    <div class="form-group col-12 col-sm-6 mb-0">
                      <label for="fromDate"> {{ 'SHARED.SEARCH.FROM_DATE' | translate }} </label>
                      <div class="input-group round">
                        <input
                          class="form-control"
                          id="fromDate"
                          name="fromDate"
                          formControlName="fromDate"
                          [placeholder]="'SHARED.SEARCH.DATE_PLACEHOLDER' | translate"
                          ngbDatepicker
                          #fromDate="ngbDatepicker"
                        />
                        <div class="input-group-append">
                          <span
                            class="input-group-text gradient-light-blue-indigo"
                            style="cursor: pointer;"
                            (click)="fromDate.toggle()"
                          >
                            <i class="fa fa-calendar"></i>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="form-group col-12 col-sm-6 mb-0">
                      <label for="toDate"> {{ 'SHARED.SEARCH.TO_DATE' | translate }} </label>
                      <div class="input-group round">
                        <input
                          class="form-control"
                          id="toDate"
                          name="toDate"
                          formControlName="toDate"
                          [placeholder]="'SHARED.SEARCH.DATE_PLACEHOLDER' | translate"
                          ngbDatepicker
                          #toDate="ngbDatepicker"
                        />
                        <div class="input-group-append">
                          <span
                            class="input-group-text gradient-light-blue-indigo"
                            style="cursor: pointer;"
                            (click)="toDate.toggle()"
                          >
                            <i class="fa fa-calendar"></i>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-auto form-group d-flex">
                  <button
                    type="submit"
                    class="btn btn-raised btn-primary align-self-end round mx-1 mb-0"
                    [disabled]="isSearching$ | async"
                  >
                    <i class="fa fa-search"></i>
                  </button>
                  <a
                    *ngIf="[Claims.CREATE_PETTY_CASH_REFUND_REQUEST] | authorize | async"
                    routerLink="/finances/petty-cash-refund-requests/create"
                    class="btn btn-raised btn-success align-self-end round mx-1 mb-0"
                  >
                    <i class="fa fa-plus"></i>
                    <span class="mx-1">{{ 'SHARED.CONFIRM.ADD' | translate }}</span>
                  </a>
                  <app-export-tools
                    *ngIf="(pettyCashRefundRequests$ | async)?.length"
                    class="d-flex align-self-end mx-1"
                    targetElementId="petty-cash-refund-requests-table-export-target-element"
                    [outputFileName]="'SHARED.EXPORT_TABLES.PETTY_CASH_REFUND_REQUESTS_TABLE' | translate"
                  ></app-export-tools>
                </div>
              </form>
            </div>
            <div class="col-md-12" *ngIf="(pettyCashRefundRequests$ | async)?.length; else noItems">
              <div class="row">
                <div class="col-md-12 alert alert-light text-center" role="alert">
                  {{ 'SHARED.SEARCH.SEARCH_RESULTS' | translate }} ({{ (paginationInfo$ | async).total | toNumber }})
                  {{ 'FINANCES.PETTY_CASH_REFUND_REQUESTS.PETTY_CASH_REFUND_REQUEST_DATA.REFUND_REQUEST' | translate }}
                </div>
                <div class="col-md-12">
                  <div class="table-responsive">
                    <table class="table text-center" id="petty-cash-refund-requests-table-export-target-element">
                      <thead>
                        <tr>
                          <th scope="col"><i class="fa fa-list-ol"></i></th>
                          <th scope="col">
                            {{
                              'FINANCES.PETTY_CASH_REFUND_REQUESTS.PETTY_CASH_REFUND_REQUEST_DATA.PETTY_CASH'
                                | translate
                            }}
                          </th>
                          <th scope="col">
                            <i class="fa fa-usd"></i
                            ><span class="mx-1">{{
                              'FINANCES.PETTY_CASH_REFUND_REQUESTS.PETTY_CASH_REFUND_REQUEST_DATA.REFUNDS_TOTAL'
                                | translate
                            }}</span>
                          </th>
                          <th scope="col">
                            {{
                              'FINANCES.PETTY_CASH_REFUND_REQUESTS.PETTY_CASH_REFUND_REQUEST_DATA.PETTY_CASH_REFUND_REQUEST_DATE'
                                | translate
                            }}
                          </th>
                          <th scope="col">{{ 'SHARED.SEARCH.CREATED_AT' | translate }}</th>
                          <th scope="col">
                            {{ 'FINANCES.PETTY_CASH_REFUND_REQUESTS.PETTY_CASH_REFUND_REQUEST_DATA.USER' | translate }}
                          </th>
                          <th scope="col">{{ 'SHARED.TOOLS' | translate }}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          *ngFor="let request of pettyCashRefundRequests$ | async; trackBy: trackItems; let i = index"
                        >
                          <th scope="row">{{ i + 1 }}</th>
                          <td>{{ request.pettyCash.description }}</td>
                          <td>{{ request.value | toDecimal }}</td>
                          <td>
                            <span [ngbTooltip]="request.transactionDate | date: 'time'">
                              {{ request.transactionDate | date }}
                            </span>
                          </td>
                          <td>
                            <span [ngbTooltip]="request.createdAt | date: 'time'">
                              {{ request.createdAt | date }}
                            </span>
                          </td>
                          <td>{{ request.user.name }}</td>
                          <td>
                            <a
                              *ngIf="
                                [Claims.CREATE_PETTY_CASH_REFUND_REQUEST, Claims.APPROVE_PETTY_CASH_REFUND_REQUEST]
                                  | authorize
                                  | async
                              "
                              class="info p-0"
                              [routerLink]="['/finances/petty-cash-refund-requests/update', request.id]"
                              [ngbTooltip]="
                                'FINANCES.PETTY_CASH_REFUND_REQUESTS.UPDATE_PETTY_CASH_REFUND_REQUEST_DATA' | translate
                              "
                            >
                              <i class="fa fa-edit font-medium-3 mr-2"></i>
                            </a>
                            <a
                              *ngIf="[Claims.CREATE_PETTY_CASH_REFUND_REQUEST] | authorize | async"
                              class="info p-0"
                              [routerLink]="['/finances/petty-cash-refund-requests/view', request.id]"
                              [ngbTooltip]="
                                'FINANCES.PETTY_CASH_REFUND_REQUESTS.PETTY_CASH_REFUND_REQUEST_DATA.DISPLAY_DETAILS_REFUND_REQUEST'
                                  | translate
                              "
                            >
                              <i class="fa fa-eye font-medium-3 mr-2"></i>
                            </a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div class="d-flex justify-content-between p-2">
                      <ngb-pagination
                        *ngIf="paginationInfo$ | async; let pagination"
                        maxSize="5"
                        [collectionSize]="pagination.total"
                        [pageSize]="pagination.pageSize"
                        [page]="pagination.page"
                        (pageChange)="pageChanged($event)"
                        boundaryLinks="true"
                      >
                        <ng-template ngbPaginationFirst>{{
                          'SHARED.PAGINATION.PAGINATION_FIRST' | translate
                        }}</ng-template>
                        <ng-template ngbPaginationFirst>{{
                          'SHARED.PAGINATION.PAGINATION_PREVIOUS' | translate
                        }}</ng-template>
                        <ng-template ngbPaginationFirst>{{
                          'SHARED.PAGINATION.PAGINATION_NEXT' | translate
                        }}</ng-template>
                        <ng-template ngbPaginationFirst>{{
                          'SHARED.PAGINATION.PAGINATION_LAST' | translate
                        }}</ng-template>
                      </ngb-pagination>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- petty cash refund requests list ends -->

<!-- no items template starts -->
<ng-template #noItems>
  <div class="col-md-12 alert alert-warning mt-3 text-center" role="alert">
    {{ 'FINANCES.PETTY_CASH_REFUND_REQUESTS.SEARCH_PETTY_CASH_REFUND_REQUEST_NOT_FOUND' | translate }}
    <a
      *ngIf="[Claims.CREATE_PETTY_CASH_REFUND_REQUEST] | authorize | async"
      routerLink="/finances/petty-cash-refund-requests/create"
      class="btn btn-raised btn-success round"
    >
      <i class="fa fa-plus"></i>
      <span class="mx-1">{{ 'SHARED.CONFIRM.ADD' | translate }}</span>
    </a>
  </div>
</ng-template>
<!-- no items template ends -->

<!-- view petty cash refund request modal starts -->
<ng-template #viewModalRef let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title text-info" id="modal-basic-title">
      {{ 'FINANCES.PETTY_CASH_REFUND_REQUESTS.PETTY_CASH_DATA' | translate }}
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="d()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-12">
        <p class="text-info">{{ 'FINANCES.PETTY_CASH_REFUND_REQUESTS.PETTY_CASH_DATA' | translate }}</p>
      </div>
      <div class="form-group col-12">
        <label for="selectedPettyCashRefund.pettyCash.description">{{
          'FINANCES.PETTY_CASH_REFUND_REQUESTS.PETTY_CASH_REFUND_REQUEST_DATA.PETTY_CASH' | translate
        }}</label>
        <input
          type="text"
          id="selectedPettyCashRefund.pettyCash.description"
          class="form-control round"
          [placeholder]="
            'FINANCES.PETTY_CASH_REFUND_REQUESTS.PETTY_CASH_REFUND_REQUEST_DATA.PETTY_CASH_PLACEHOLDER' | translate
          "
          readonly
          [value]="selectedPettyCashRefund.pettyCash.description"
        />
      </div>
      <div class="form-group col-12">
        <label for="selectedPettyCashRefund.value">{{
          'FINANCES.PETTY_CASH_REFUND_REQUESTS.PETTY_CASH_REFUND_REQUEST_DATA.PETTY_CASH_REFUND' | translate
        }}</label>
        <input
          type="text"
          id="selectedPettyCashRefund.value"
          class="form-control round"
          [placeholder]="
            'FINANCES.PETTY_CASH_REFUND_REQUESTS.PETTY_CASH_REFUND_REQUEST_DATA.PETTY_CASH_REFUND_PLACEHOLDER'
              | translate
          "
          readonly
          [value]="selectedPettyCashRefund.value | toDecimal"
        />
      </div>
      <div class="form-group col-12">
        <label for="selectedPettyCashRefund.pettyCash.value">{{
          'FINANCES.PETTY_CASH_REFUND_REQUESTS.PETTY_CASH_REFUND_REQUEST_DATA.CURRENT_PETTY_CASH_REFUND_VALUE'
            | translate
        }}</label>
        <input
          type="text"
          id="selectedPettyCashRefund.pettyCash.value"
          class="form-control round"
          [placeholder]="
            'FINANCES.PETTY_CASH_REFUND_REQUESTS.PETTY_CASH_REFUND_REQUEST_DATA.CURRENT_PETTY_CASH_REFUND_VALUE_PLACEHOLDER'
              | translate
          "
          readonly
          [value]="selectedPettyCashRefund.pettyCash.value | toDecimal"
        />
      </div>
      <div class="form-group col-md-12">
        <label for="selectedPettyCashRefund.notes">{{
          'FINANCES.PETTY_CASH_REFUND_REQUESTS.PETTY_CASH_REFUND_REQUEST_DATA.NOTES' | translate
        }}</label>
        <textarea
          id="selectedPettyCashRefund.notes"
          class="form-control round"
          [innerText]="selectedPettyCashRefund.notes"
          [placeholder]="
            'FINANCES.PETTY_CASH_REFUND_REQUESTS.PETTY_CASH_REFUND_REQUEST_DATA.NOTES_NOT_FOUND' | translate
          "
          readonly
        ></textarea>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="modal-actions">
      <button type="button" class="btn btn-outline-dark round" (click)="c()">
        <i class="fa fa-times"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.CANCEL' | translate }}</span>
      </button>
    </div>
  </div>
</ng-template>
<!-- view petty cash refund request modal ends -->

<!-- petty cash search starts -->
<app-petty-cash-search [(visible)]="pettyCashListVisibility" (pettyCash)="selectPettyCash($event)">
</app-petty-cash-search>
<!-- petty cash search ends -->
