import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-status',
  templateUrl: './status.component.html',
})
export class StatusComponent {
  /**
   * The status flag.
   */
  @Input() status: boolean;

  /**
   * Active status text.
   */
  @Input() activeText: string;

  /**
   * Not-Active status text.
   */
  @Input() notActiveText: string;
}
