import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';

import { AppHttpResponse } from 'shared';
import { CreateSectorInput, Sector, UpdateSectorInput } from 'stores/models';

/**
 * The sector services includes functionality to create, search, findById, update and delete for a sector.
 */
@Injectable()
export class SectorsService {
  /**
   * The relative route for the sectors.
   *
   * No leading or trailing slashes require.
   */
  private sectorsApi = 'stores/sectors';

  constructor(private http: HttpClient) {}

  /**
   * Creates a new sector from the provided data.
   * @param data The new sector data.
   */
  public create(data: CreateSectorInput): Observable<AppHttpResponse<Sector>> {
    return this.http.post<AppHttpResponse<Sector>>(`${this.sectorsApi}`, data);
  }

  /**
   * Searches in the sector by fromDate and toDate.
   * @param name of the sector.
   * @param page The current pagination page number.
   * @param pageSize The maximum number of sector allowed per one pagination page.
   */
  public search(name: string, page: number, pageSize: number): Observable<AppHttpResponse<Sector[]>> {
    const params = new HttpParams().set('name', name).set('page', page.toString()).set('pageSize', pageSize.toString());
    return this.http.get<AppHttpResponse<Sector[]>>(`${this.sectorsApi}`, { params });
  }

  /**
   * Finds the sector with the given id.
   * @param id The id of the sector.
   */
  public findById(id: number): Observable<AppHttpResponse<Sector>> {
    return this.http.get<AppHttpResponse<Sector>>(`${this.sectorsApi}/${id}`);
  }

  /**
   * Updates an existing sector data using the provided data.
   * @param data The updated sector data.
   */
  public update(data: UpdateSectorInput): Observable<AppHttpResponse<Sector>> {
    return this.http.put<AppHttpResponse<Sector>>(`${this.sectorsApi}`, data);
  }

  /**
   * Deletes the sector by given id.
   * @param id The id of the sector.
   */
  public delete(id: number): Observable<AppHttpResponse<Sector>> {
    return this.http.delete<AppHttpResponse<Sector>>(`${this.sectorsApi}/${id}`);
  }
}
