import { Injectable } from '@angular/core';

import { ToastrService, IndividualConfig } from 'ngx-toastr';
import { flatten } from 'lodash';

import { NotificationMessage } from 'shared/models';
import { TranslationService } from 'shared/services/translation.service';

/**
 * The notification service that uses the toaster styled notifications.
 */
@Injectable()
export class NotificationService {
  /**
   * Toaster configurations.
   */
  private config: Partial<IndividualConfig>;

  constructor(private toaster: ToastrService, private translationService: TranslationService) {
    this.config = {
      enableHtml: true, // e.g. foo <br> bar.
      tapToDismiss: true,
      closeButton: true,
      timeOut: 5000, // 5 seconds.
      extendedTimeOut: 3000, // 3 seconds.
    };

    /* Override global-configs. */
    // Duplicates
    this.toaster.toastrConfig.preventDuplicates = true;
    this.toaster.toastrConfig.countDuplicates = true;
    this.toaster.toastrConfig.resetTimeoutOnDuplicate = true;
    // Toaster configs.
    this.toaster.toastrConfig.newestOnTop = true;
    this.adjustToasterPosition();
  }

  /**
   * Shows a success notification contains the given messages.
   * @param messages the messages to show, one per-line.
   */
  public success(...messages: string[]) {
    this.toaster.success(this.formatMessages(messages), null, this.config);
  }

  /**
   * Shows a success notification contains the given messages within lower display time.
   * @param messages the messages to show, one per-line.
   */
  public successInstant(...messages: string[]) {
    this.toaster.success(this.formatMessages(messages), null, { ...this.config, timeOut: 700 });
  }

  /**
   * Shows a success notification contains the given message's title and body.
   * @param message the message object to be shown as a title and body.
   */
  public successWithTitle(message: NotificationMessage) {
    this.toaster.success(this.formatMessages(message.body), message.title, this.config);
  }

  /**
   * Shows an info notification contains the given messages.
   * @param messages the messages to show, one per-line.
   */
  public info(...messages: string[]) {
    this.toaster.info(this.formatMessages(messages), null, this.config);
  }

  /**
   * Shows an info notification contains the given message's title and body.
   * @param message the message object to be shown as a title and body.
   */
  public infoWithTitle(message: NotificationMessage) {
    this.toaster.info(this.formatMessages(message.body), message.title, this.config);
  }

  /**
   * Shows a warning notification contains the given messages.
   * @param messages the messages to show, one per-line.
   */
  public warning(...messages: string[]) {
    this.toaster.warning(this.formatMessages(messages), null, this.config);
  }

  /**
   * Shows a warning notification contains the given message's title and body.
   * @param message the message object to be shown as a title and body.
   */
  public warningWithTitle(message: NotificationMessage) {
    this.toaster.warning(this.formatMessages(message.body), message.title, this.config);
  }

  /**
   * Shows an error notification contains the given messages.
   * @param messages the messages to show, one per-line.
   */
  public error(...messages: string[]) {
    this.toaster.error(this.formatMessages(messages), null, this.config);
  }

  /**
   * Shows an error notification contains the given message's title and body.
   * @param message the message object to be shown as a title and body.
   */
  public errorWithTitle(message: NotificationMessage) {
    this.toaster.error(this.formatMessages(message.body), message.title, this.config);
  }

  /**
   * Reformats the given messages array
   * by breaking each new-line into a separate message depending on the new-line symbol `\r\n`,
   * then joining the resulting in one string as an unordered list with leading `●` symbol for each line.
   * @param messages The messages sent from the server or from the app.
   */
  private formatMessages(messages: string[]): string {
    this.adjustToasterPosition();

    const flattenMessages = flatten(messages.map((message) => message.split('\r\n')));
    if (!flattenMessages.length) {
      return this.translationService.translate('SHARED.NO_MESSAGE_CONTENT');
    }

    return flattenMessages.reduce((str1, str2, index) =>
      index === 1 ? `● ${str1}<br>● ${str2}` : `${str1}<br>● ${str2}`
    );
  }

  /**
   * Adjusts the ngx-toaster position according to the current language write direction.
   */
  private adjustToasterPosition() {
    this.toaster.toastrConfig.positionClass =
      this.translationService.language === 'ar' ? 'toast-top-left' : 'toast-top-right';
    this.toaster.toastrConfig.toastClass = this.translationService.language === 'ar' ? 'ngx-toastr rtl' : 'ngx-toastr';
  }
}
