import { PaginationInfo } from 'shared/models';
import { User } from 'security/models';

/**
 * Represents the users state.
 */
export class UsersState {
  /**
   * The list of users.
   */
  public data: User[];
  /**
   * The pagination info for the users.
   */
  public paginationInfo: PaginationInfo;

  /**
   * An error that may resulting during processing some actions.
   */
  public error: any;

  /**
   * Determines if there is a running process.
   */
  public isGettingOrganizationUsers: boolean;

  /**
   * Determines if the last process has been ended successfully.
   */
  public isGetOrganizationUsersCompleted: boolean;
}
