import { createSelector } from '@ngrx/store';

import { selectLookupsState } from 'lookups/store/selectors/feature.selectors';
import { LookupsState, QuotationStatusState } from 'lookups/store/states';

/**
 * Gets the quotation status state.
 */
const selectQuotationState = createSelector(selectLookupsState, (state: LookupsState) => state.quotationStatuses);

/**
 * Gets the list of loaded quotation statuses.
 */
export const getQuotationStatuses = createSelector(selectQuotationState, (state: QuotationStatusState) => state.data);

/**
 * Gets the error that occurred in the state.
 */
export const getQuotationStatusesError = createSelector(
  selectQuotationState,
  (state: QuotationStatusState) => state.error
);

/**
 * Determines if there is a running load process.
 */
export const getQuotationStatusesIsLoading = createSelector(
  selectQuotationState,
  (state: QuotationStatusState) => state.isLoading
);

/**
 * Determines if the last load process has been ended successfully.
 */
export const getQuotationStatusesIsLoaded = createSelector(
  selectQuotationState,
  (state: QuotationStatusState) => state.isLoaded
);
