import { Injectable } from '@angular/core';

import { switchMap, map, catchError, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { Effect, ofType, Actions } from '@ngrx/effects';

import { NotificationService, TranslationService } from 'shared/services';
import { StatusCode } from 'shared/models';
import { ReportsService } from 'reports/services';
import {
  UserReportsActionType,
  GetMyReportsFail,
  GetMyReportsSuccess,
  DeleteUserReport,
  DeleteUserReportSuccess,
  DeleteUserReportFail,
} from 'reports/store/actions';

@Injectable()
export class UserReportsEffects {
  constructor(
    private actions$: Actions,
    private reportsService: ReportsService,
    private notificationService: NotificationService,
    private translationService: TranslationService
  ) {}

  /* ========================= GET_MY_REPORTS =================================== */
  @Effect()
  getMyReports$ = this.actions$.pipe(
    ofType(UserReportsActionType.GET_MY_REPORTS),
    switchMap(() =>
      this.reportsService.getMyReports().pipe(
        map((response) => new GetMyReportsSuccess(response)),
        catchError((error) => of(new GetMyReportsFail(error)))
      )
    )
  );

  /* ========================= DELETE_USER_REPORT =================================== */
  @Effect()
  delete$ = this.actions$.pipe(
    ofType(UserReportsActionType.DELETE_USER_REPORT),
    switchMap((action: DeleteUserReport) =>
      this.reportsService.delete(action.payload).pipe(
        map((response) => new DeleteUserReportSuccess(response)),
        catchError((error) => of(new DeleteUserReportFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  deleteSuccess$ = this.actions$.pipe(
    ofType(UserReportsActionType.DELETE_USER_REPORT_SUCCESS),
    tap((action: DeleteUserReportSuccess) => {
      this.notificationService.success(this.translationService.translate('REPORTS.MY_REPORTS.DELETED'));
    })
  );

  @Effect({ dispatch: false })
  deleteFail$ = this.actions$.pipe(
    ofType(UserReportsActionType.DELETE_USER_REPORT_FAIL),
    tap((action: DeleteUserReportFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(this.translationService.translate('REPORTS.MY_REPORTS.REPORT_NOT_FOUND'));
      } else if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );
}
