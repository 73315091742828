import { CreatePettyCashComponent } from 'finances/components/petty-cash/create-petty-cash/create-petty-cash.component';
import { UpdatePettyCashComponent } from 'finances/components/petty-cash/update-petty-cash/update-petty-cash.component';
import { PettyCashListComponent } from 'finances/components/petty-cash/petty-cash-list/petty-cash-list.component';
import { PettyCashSearchComponent } from 'finances/components/petty-cash/petty-cash-search/petty-cash-search.component';

export * from 'finances/components/petty-cash/create-petty-cash/create-petty-cash.component';
export * from 'finances/components/petty-cash/update-petty-cash/update-petty-cash.component';
export * from 'finances/components/petty-cash/petty-cash-list/petty-cash-list.component';
export * from 'finances/components/petty-cash/petty-cash-search/petty-cash-search.component';

/**
 * The set of components registered on this sub-module.
 */
export const COMPONENTS: any[] = [
  CreatePettyCashComponent,
  UpdatePettyCashComponent,
  PettyCashListComponent,
  PettyCashSearchComponent,
];
