import { PaginationInfo } from 'shared/models';
import { User, UserClaim } from 'security/models';
import { BankAccount, CostCenter } from 'finances/models';
import { Location } from 'stores/models';

/**
 * Represents the users state.
 */
export class UsersState {
  /**
   * The list of users.
   */
  public users: User[];

  /**
   * The list of user claims.
   */
  public userClaims: UserClaim[];

  /**
   * The list of user bank accounts.
   */
  public userBankAccounts: BankAccount[];

  /**
   * The list of user cost centers.
   */
  public userLocations: Location[];

  /**
   * The list of user cost centers.
   */
  public userCostCenters: CostCenter[];

  /**
   * The pagination info for the users.
   */
  public paginationInfo: PaginationInfo;

  /**
   * An error that may resulting during processing some actions.
   */
  public error: any;

  /**
   * Determines if there is a running search process.
   */
  public isSearching: boolean;

  /**
   * Determines if the last search process has been ended successfully.
   */
  public isSearchCompleted: boolean;

  /**
   * Determines if there is a running process.
   */
  public isGettingOrganizationUsers: boolean;

  /**
   * Determines if the last process has been ended successfully.
   */
  public isGetOrganizationUsersCompleted: boolean;

  /**
   * The current selected user.
   */
  public selectedUser: User;

  /**
   * The current selected organization user.
   */
  public selectedOrganizationUser: User;

  /**
   * The current selected user claim.
   */
  public selectedUserClaim: UserClaim;

  /**
   * Determines if there is a running find-user process.
   */
  public isFinding: boolean;

  /**
   * Determines if the last find-user process has been ended successfully.
   */
  public isFindCompleted: boolean;

  /**
   * Determines if there is a running create process.
   */
  public isCreating: boolean;

  /**
   * Determines if the last create process has been ended successfully.
   */
  public isCreateCompleted: boolean;

  /**
   * Determines if there is a running create organization user process.
   */
  public isOrganizationUserCreating: boolean;

  /**
   * Determines if the last create organization user process has been ended successfully.
   */
  public isCreateOrganizationUserCompleted: boolean;

  /**
   * Determines if there is a running update process.
   */
  public isUpdating: boolean;

  /**
   * Determines if the last update process has been ended successfully.
   */
  public isUpdateCompleted: boolean;

  /**
   * Determines if there is a running block process.
   */
  public isBlocking: boolean;

  /**
   * Determines if the last block process has been ended successfully.
   */
  public isBlockCompleted: boolean;

  /**
   * Determines if there is a running activate process.
   */
  public isActivating: boolean;

  /**
   * Determines if the last activate process has been ended successfully.
   */
  public isActivateCompleted: boolean;

  /**
   * Determines if there is a running update process.
   */
  public isUserClaimsUpdating: boolean;

  /**
   * Determines if the last update process has been ended successfully.
   */
  public isUserClaimsUpdateCompleted: boolean;

  /**
   * Determines if there is a running update process.
   */
  public isUserLocationUpdating: boolean;

  /**
   * Determines if the last update process has been ended successfully.
   */
  public isUserLocationUpdateCompleted: boolean;

  /**
   * Determines if there is a running update process.
   */
  public isUserBankAccountUpdating: boolean;

  /**
   * Determines if the last update process has been ended successfully.
   */
  public isUserBankAccountUpdateCompleted: boolean;

  /**
   * Determines if there is a running update process.
   */
  public isUserCostCenterUpdating: boolean;

  /**
   * Determines if the last update process has been ended successfully.
   */
  public isUserCostCenterUpdateCompleted: boolean;

  /**
   * Determines if there is a running process.
   */
  public isGettingUserLocations: boolean;

  /**
   * Determines if the last process has been ended successfully.
   */
  public isGetUserLocationsCompleted: boolean;

  /**
   * Determines if there is a running process.
   */
  public isGettingUserBankAccounts: boolean;

  /**
   * Determines if the last process has been ended successfully.
   */
  public isGetUserBankAccountsCompleted: boolean;

  /**
   * Determines if there is a running process.
   */
  public isGettingUserCostCenters: boolean;

  /**
   * Determines if the last process has been ended successfully.
   */
  public isGetUserCostCentersCompleted: boolean;
}
