<div class="row">
  <div class="col-md-12">
    <!-- sale Return details starts -->
    <div class="card-header mb-3 pt-0">
      <h4 class="card-title">{{ 'SALES.RETURNS.SALE_RETURN_DATA_TITLE' | translate }}</h4>
    </div>
    <div class="card-content">
      <div class="px-3">
        <div class="row">
          <div class="form-group col-md-6">
            <label for="location">{{ 'SALES.RETURNS.SALE_RETURN_DATA.LOCATION' | translate }}</label>
            <input
              type="text"
              id="location"
              class="form-control round"
              [value]="saleReturn.location.name | localize: saleReturn.location.nameEn | async"
              readonly
            />
          </div>
          <div class="form-group col-md-6">
            <label for="employee">{{ 'SALES.RETURNS.SALE_RETURN_DATA.SALES_PERSON' | translate }}</label>
            <input
              type="text"
              id="employee"
              class="form-control round"
              [value]="
                saleReturn.employee?.employeePersonalDetail.fullName
                  | localize: saleReturn.employee?.employeePersonalDetail.fullName
                  | async
              "
              readonly
            />
          </div>
          <div class="form-group col-md-6">
            <label for="saleInvoiceNum">{{ 'SALES.RETURNS.SALE_RETURN_DATA.SALE_INVOICE_NUMBER' | translate }}</label>
            <input
              type="text"
              id="saleInvoiceNum"
              class="form-control round"
              [value]="saleReturn.saleInvoice?.invoiceNum"
              readonly
            />
          </div>
          <div class="form-group col-md-6">
            <label for="customer">{{ 'SALES.RETURNS.SALE_RETURN_DATA.CUSTOMER' | translate }}</label>
            <input
              type="text"
              id="customer"
              class="form-control round"
              [value]="saleReturn.customer?.name | localize: saleReturn.customer?.nameEn | async"
              readonly
            />
          </div>
          <div class="form-group col-md-12">
            <label for="notes">{{ 'SALES.RETURNS.SALE_RETURN_DATA.NOTES' | translate }}</label>
            <textarea
              id="notes"
              class="form-control round"
              [innerText]="saleReturn.notes"
              [placeholder]="'SALES.RETURNS.SALE_RETURN_DATA.NOTES_NOT_FOUND' | translate"
              readonly
            ></textarea>
          </div>
          <div class="form-group col-md-3">
            <label for="transactionDate">{{ 'SALES.RETURNS.SALE_RETURN_DATA.SALE_RETURN_DATE' | translate }}</label>
            <input
              type="text"
              id="transactionDate"
              class="form-control round"
              [value]="saleReturn.transactionDate | date: 'datetime'"
              readonly
            />
          </div>
          <div class="form-group col-md-3">
            <label for="createdAt">{{ 'SHARED.SEARCH.CREATED_AT' | translate }}</label>
            <input
              type="text"
              id="createdAt"
              class="form-control round"
              [value]="saleReturn.createdAt | date: 'datetime'"
              readonly
            />
          </div>
          <div class="form-group col-md-6">
            <label for="user">{{ 'SALES.RETURNS.SALE_RETURN_DATA.USER_CREATE' | translate }}</label>
            <input type="text" id="user" class="form-control round" [value]="saleReturn.user.name" readonly />
          </div>
        </div>
      </div>
    </div>
    <!-- sale Return details ends -->

    <!-- products details starts -->
    <div class="card-header mb-3 pt-2">
      <h4 class="card-title">{{ 'SALES.RETURNS.SALE_RETURN_DATA.PRODUCTS' | translate }}</h4>
    </div>
    <div class="card-content">
      <div class="px-3">
        <div class="row">
          <div class="col-md-12">
            <div class="table-responsive">
              <table class="table text-center">
                <thead>
                  <tr>
                    <th scope="col"><i class="fa fa-list-ol"></i></th>
                    <th scope="col">
                      <i class="fa fa-map-signs"></i>
                      {{ 'SALES.RETURNS.SALE_RETURN_DATA.PRODUCT_TYPE' | translate }}
                    </th>
                    <th scope="col">
                      <i class="fa fa-comments"></i>
                      {{ 'SALES.RETURNS.SALE_RETURN_DATA.PRODUCT_DESCRIPTION' | translate }}
                    </th>
                    <th scope="col">
                      <i class="fa fa-list-ol"></i>
                      {{ 'SALES.RETURNS.SALE_RETURN_DATA.PRODUCT_QUANTITY' | translate }}
                    </th>
                    <th scope="col">
                      <i class="fa fa-balance-scale"></i>
                      {{ 'SALES.RETURNS.SALE_RETURN_DATA.PRODUCT_UNIT_OF_MEASURE' | translate }}
                    </th>
                    <th scope="col">
                      <i class="fa fa-usd"></i>
                      {{ 'SALES.RETURNS.SALE_RETURN_DATA.PRODUCT_VALUE' | translate }}
                    </th>
                    <th scope="col">
                      <i class="fa fa-usd"></i>
                      {{ 'SALES.RETURNS.SALE_RETURN_DATA.PRODUCT_TOTAL' | translate }}
                    </th>
                    <th scope="col">
                      <i class="fa fa-percent"></i>
                      {{ 'SALES.RETURNS.SALE_RETURN_DATA.PRODUCT_TAX' | translate }}
                    </th>
                    <th scope="col">
                      {{ 'PURCHASES.REQUESTS.REQUEST_DATA.PROJECT_SUB_TASK' | translate }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let product of saleReturn.saleReturnProducts; let i = index">
                    <th scope="row">{{ i + 1 }}</th>
                    <td>
                      <span class="c-success" *ngIf="product.product && !product.product?.isService">{{
                        'SALES.RETURNS.SALE_RETURN_DATA.STORABLE_PRODUCT' | translate
                      }}</span>
                      <span class="c-success" *ngIf="product.product && product.product?.isService">{{
                        'SALES.RETURNS.SALE_RETURN_DATA.SERVICE_PRODUCT' | translate
                      }}</span>
                      <span class="c-primary" *ngIf="!product.product">
                        {{ 'SALES.RETURNS.SALE_RETURN_DATA.NON_STORABLE_PRODUCT' | translate }}</span
                      >
                    </td>
                    <td>
                      {{
                        (product.product?.description | localize: product.product?.descriptionEn | async) ||
                          product.description
                      }}
                      <div *ngIf="product.subProduct" class="mt-1 d-block">
                        <app-sub-product-info [subProduct]="product.subProduct"></app-sub-product-info>
                      </div>
                    </td>
                    <td>{{ product.quantity | toDecimal }}</td>
                    <td>{{ product.unitOfMeasure?.name | localize: product.unitOfMeasure?.nameEn | async }}</td>
                    <td>{{ product.value | toDecimal }}</td>
                    <td>{{ product.total | toDecimal }}</td>
                    <td>{{ product.tax | toDecimal }}</td>
                    <td>
                      <span *ngIf="product.projectSubTask">
                        {{ product.projectSubTask?.name | localize: product.projectSubTask?.nameEn | async }}
                      </span>
                      <span *ngIf="product.projectSubTask" class="d-block">
                        ({{
                          product.projectSubTask?.project.description
                            | localize: product.projectSubTask?.project.descriptionEn
                            | async
                        }})
                      </span>
                    </td>
                  </tr>
                </tbody>
                <tfoot class="tfoot-light">
                  <tr>
                    <td [attr.colspan]="3">
                      <i class="fa fa-bookmark c-primary"></i>
                      <span>
                        {{ saleReturn.saleReturnProducts.length | toNumber }}
                        {{ 'SALES.RETURNS.SALE_RETURN_DATA.PRODUCT' | translate }}
                      </span>
                    </td>
                    <td [attr.colspan]="3">
                      <i class="fa fa-bookmark c-primary"></i>
                      <span>
                        {{ productsQuantity(saleReturn.saleReturnProducts) | toDecimal }}
                        {{ 'SALES.RETURNS.SALE_RETURN_DATA.QUANTITY' | translate }}
                      </span>
                    </td>
                    <td [attr.colspan]="4">
                      <i class="fa fa-bookmark c-primary"></i>
                      <span>
                        {{ saleReturn.total | toDecimal }}
                        {{ 'SALES.RETURNS.SALE_RETURN_DATA.TOTAL' | translate }}
                      </span>
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- products details ends -->

    <!-- sale return summary starts -->
    <div class="card-header mb-3 pt-2">
      <h4 class="card-title">{{ 'SALES.RETURNS.SALE_RETURN_DATA.RETURNS' | translate }}</h4>
    </div>
    <div class="card-content">
      <div class="px-3">
        <div class="row">
          <div class="form-group col-md-4">
            <label for="productsTotal">{{ 'SALES.RETURNS.SALE_RETURN_DATA.PRODUCTS_TOTAL' | translate }}</label>
            <input
              type="text"
              id="productsTotal"
              class="form-control round"
              [value]="saleReturn.total | toDecimal"
              readonly
            />
          </div>
          <div class="form-group col-md-4">
            <label for="tax">{{ 'SALES.RETURNS.SALE_RETURN_DATA.PRODUCT_TAX' | translate }}</label>
            <input type="text" id="tax" class="form-control round" [value]="saleReturn.tax | toDecimal" readonly />
          </div>
          <div class="form-group col-md-4">
            <label for="returnTotal" class="font-weight-bold" style="color: #212529;">
              <i class="fa fa-star"></i>
              <span class="mx-1">{{ 'SALES.RETURNS.SALE_RETURN_DATA.RETURNS_TOTAL' | translate }}</span>
            </label>
            <input
              type="text"
              id="returnTotal"
              class="form-control round"
              [value]="saleReturn.net | toDecimal"
              readonly
            />
          </div>
        </div>
      </div>
    </div>
    <!-- saleReturn summary ends -->
  </div>
</div>
