import { Action } from '@ngrx/store';

import { Report } from 'reports/models';
import { AppHttpResponse } from 'shared';

/**
 * The Reports-Module sales reports action types.
 */
export enum SalesReportsActionType {
  GET_CUSTOMER_SUMMARY_REPORT = '[Reports] [Sales] Get Customer Summary Report',
  GET_CUSTOMER_SUMMARY_REPORT_FAIL = '[Reports] [Sales] Get Customer Summary Report Fail',
  GET_CUSTOMER_SUMMARY_REPORT_RECEIVED = '[Reports] [Sales] Get Customer Summary Report Received',
  GET_CUSTOMER_SUMMARY_REPORT_SUCCESS = '[Reports] [Sales] Get Customer Summary Report Success',
  CUSTOMER_SUMMARY_REPORT_VIEW = '[Reports] [Sales] Get Customer Summary Report View',

  GET_CUSTOMERS_DEBTS_REPORT = '[Reports] [Sales] Get Customers Debts Report',
  GET_CUSTOMERS_DEBTS_REPORT_FAIL = '[Reports] [Sales] Get Customers Debts Report Fail',
  GET_CUSTOMERS_DEBTS_REPORT_RECEIVED = '[Reports] [Sales] Get Customers Debts Report Received',
  GET_CUSTOMERS_DEBTS_REPORT_SUCCESS = '[Reports] [Sales] Get Customers Debts Report Success',
  CUSTOMERS_DEBTS_REPORT_VIEW = '[Reports] [Sales] Get Customers Debts Report View',

  GET_CUSTOMERS_PAYMENTS_REPORT = '[Reports] [Sales] Get Customers Payments Report',
  GET_CUSTOMERS_PAYMENTS_REPORT_FAIL = '[Reports] [Sales] Get Customers Payments Report Fail',
  GET_CUSTOMERS_PAYMENTS_REPORT_RECEIVED = '[Reports] [Sales] Get Customers Payments Report Received',
  GET_CUSTOMERS_PAYMENTS_REPORT_SUCCESS = '[Reports] [Sales] Get Customers Payments Report Success',
  CUSTOMERS_PAYMENTS_REPORT_VIEW = '[Reports] [Sales] Get Customers Payments Report View',

  GET_CUSTOMERS_POINTS_REPORT = '[Reports] [Sales] Get Customers Points Report',
  GET_CUSTOMERS_POINTS_REPORT_FAIL = '[Reports] [Sales] Get Customers Points Report Fail',
  GET_CUSTOMERS_POINTS_REPORT_RECEIVED = '[Reports] [Sales] Get Customers Points Report Received',
  GET_CUSTOMERS_POINTS_REPORT_SUCCESS = '[Reports] [Sales] Get Customers Points Report Success',
  CUSTOMERS_POINTS_REPORT_VIEW = '[Reports] [Sales] Get Customers Points Report View',

  GET_GROSS_PROFIT_REPORT = '[Reports] [Sales] Get Gross Profit Report',
  GET_GROSS_PROFIT_REPORT_FAIL = '[Reports] [Sales] Get Gross Profit Report Fail',
  GET_GROSS_PROFIT_REPORT_RECEIVED = '[Reports] [Sales] Get Gross Profit Report Received',
  GET_GROSS_PROFIT_REPORT_SUCCESS = '[Reports] [Sales] Get Gross Profit Report Success',
  GROSS_PROFIT_REPORT_VIEW = '[Reports] [Sales] Get Gross Profit Report View',

  GET_MAINTENANCE_STAGE_INVOICE_REPORT = '[Reports] [Sales] Get Maintenance Stage Invoice Report',
  GET_MAINTENANCE_STAGE_INVOICE_REPORT_FAIL = '[Reports] [Sales] Get Maintenance Stage Invoice Report Fail',
  GET_MAINTENANCE_STAGE_INVOICE_REPORT_RECEIVED = '[Reports] [Sales] Get Maintenance Stage Invoice Report Received',
  GET_MAINTENANCE_STAGE_INVOICE_REPORT_SUCCESS = '[Reports] [Sales] Get Maintenance Stage Invoice Report Success',
  MAINTENANCE_STAGE_INVOICE_REPORT_VIEW = '[Reports] [Sales] Get Maintenance Stage Invoice Report View',

  GET_TOP_RETURNED_SALES_PRODUCTS_REPORT = '[Reports] [Sales] Get Top Returned Sales Products Report',
  GET_TOP_RETURNED_SALES_PRODUCTS_REPORT_FAIL = '[Reports] [Sales] Get Top Returned Sales Products Report Fail',
  GET_TOP_RETURNED_SALES_PRODUCTS_REPORT_RECEIVED = '[Reports] [Sales] Get Top Returned Sales Products Report Received',
  GET_TOP_RETURNED_SALES_PRODUCTS_REPORT_SUCCESS = '[Reports] [Sales] Get Top Returned Sales Products Report Success',
  TOP_RETURNED_SALES_PRODUCTS_REPORT_VIEW = '[Reports] [Sales] Get Top Returned Sales Products Report View',

  GET_NET_SALES_REPORT = '[Reports] [Sales] Get Net Sales Report',
  GET_NET_SALES_REPORT_FAIL = '[Reports] [Sales] Get Net Sales Report Fail',
  GET_NET_SALES_REPORT_RECEIVED = '[Reports] [Sales] Get Net Sales Report Received',
  GET_NET_SALES_REPORT_SUCCESS = '[Reports] [Sales] Get Net Sales Report Success',
  NET_SALES_REPORT_VIEW = '[Reports] [Sales] Get Net Sales Report View',

  GET_PRODUCT_SALES_REPORT = '[Reports] [Sales] Get Product Sales Report',
  GET_PRODUCT_SALES_REPORT_FAIL = '[Reports] [Sales] Get Product Sales Report Fail',
  GET_PRODUCT_SALES_REPORT_RECEIVED = '[Reports] [Sales] Get Product Sales Report Received',
  GET_PRODUCT_SALES_REPORT_SUCCESS = '[Reports] [Sales] Get Product Sales Report Success',
  PRODUCT_SALES_REPORT_VIEW = '[Reports] [Sales] Get Product Sales Report View',

  GET_PRODUCT_SALES_RETURNS_REPORT = '[Reports] [Sales] Get Product Sales Returns Report',
  GET_PRODUCT_SALES_RETURNS_REPORT_FAIL = '[Reports] [Sales] Get Product Sales Returns Report Fail',
  GET_PRODUCT_SALES_RETURNS_REPORT_RECEIVED = '[Reports] [Sales] Get Product Sales Returns Report Received',
  GET_PRODUCT_SALES_RETURNS_REPORT_SUCCESS = '[Reports] [Sales] Get Product Sales Returns Report Success',
  PRODUCT_SALES_RETURNS_REPORT_VIEW = '[Reports] [Sales] Get Product Sales Returns Report View',

  GET_PRODUCT_CLASSES_SALES_REPORT = '[Reports] [Sales] Get Product Classes Sales Report',
  GET_PRODUCT_CLASSES_SALES_REPORT_FAIL = '[Reports] [Sales] Get Product Classes Sales Report Fail',
  GET_PRODUCT_CLASSES_SALES_REPORT_RECEIVED = '[Reports] [Sales] Get Product Classes Sales Report Received',
  GET_PRODUCT_CLASSES_SALES_REPORT_SUCCESS = '[Reports] [Sales] Get Product Classes Sales Report Success',
  PRODUCT_CLASSES_SALES_REPORT_VIEW = '[Reports] [Sales] Get Product Classes Sales Report View',

  GET_PRODUCTS_SALES_REPORT = '[Reports] [Sales] Get Products Sales Report',
  GET_PRODUCTS_SALES_REPORT_FAIL = '[Reports] [Sales] Get Products Sales Report Fail',
  GET_PRODUCTS_SALES_REPORT_RECEIVED = '[Reports] [Sales] Get Products Sales Report Received',
  GET_PRODUCTS_SALES_REPORT_SUCCESS = '[Reports] [Sales] Get Products Sales Report Success',
  PRODUCTS_SALES_REPORT_VIEW = '[Reports] [Sales] Get Products Sales Report View',

  GET_PRODUCTS_SALES_RETURNS_REPORT = '[Reports] [Sales] Get Products Sales Returns Report',
  GET_PRODUCTS_SALES_RETURNS_REPORT_FAIL = '[Reports] [Sales] Get Products Sales Returns Report Fail',
  GET_PRODUCTS_SALES_RETURNS_REPORT_RECEIVED = '[Reports] [Sales] Get Products Sales Returns Report Received',
  GET_PRODUCTS_SALES_RETURNS_REPORT_SUCCESS = '[Reports] [Sales] Get Products Sales Returns Report Success',
  PRODUCTS_SALES_RETURNS_REPORT_VIEW = '[Reports] [Sales] Get Products Sales Returns Report View',

  GET_SALES_INVOICES_REPORT = '[Reports] [Sales] Get Sales Invoices Report',
  GET_SALES_INVOICES_REPORT_FAIL = '[Reports] [Sales] Get Sales Invoices Report Fail',
  GET_SALES_INVOICES_REPORT_RECEIVED = '[Reports] [Sales] Get Sales Invoices Report Received',
  GET_SALES_INVOICES_REPORT_SUCCESS = '[Reports] [Sales] Get Sales Invoices Report Success',
  SALES_INVOICES_REPORT_VIEW = '[Reports] [Sales] Get Sales Invoices Report View',

  GET_SALES_RETURNS_REPORT = '[Reports] [Sales] Get Sales Returns Report',
  GET_SALES_RETURNS_REPORT_FAIL = '[Reports] [Sales] Get Sales Returns Report Fail',
  GET_SALES_RETURNS_REPORT_RECEIVED = '[Reports] [Sales] Get Sales Returns Report Received',
  GET_SALES_RETURNS_REPORT_SUCCESS = '[Reports] [Sales] Get Sales Returns Report Success',
  SALES_RETURNS_REPORT_VIEW = '[Reports] [Sales] Get Sales Returns Report View',

  GET_SALES_SUMMARY_REPORT = '[Reports] [Sales] Get Sales Summary Report',
  GET_SALES_SUMMARY_REPORT_FAIL = '[Reports] [Sales] Get Sales Summary Report Fail',
  GET_SALES_SUMMARY_REPORT_RECEIVED = '[Reports] [Sales] Get Sales Summary Report Received',
  GET_SALES_SUMMARY_REPORT_SUCCESS = '[Reports] [Sales] Get Sales Summary Report Success',
  SALES_SUMMARY_REPORT_VIEW = '[Reports] [Sales] Get Sales Summary Report View',

  GET_SHIFT_SUMMARY_REPORT = '[Reports] [Sales] Get Shift Summary Report',
  GET_SHIFT_SUMMARY_REPORT_FAIL = '[Reports] [Sales] Get Shift Summary Report Fail',
  GET_SHIFT_SUMMARY_REPORT_RECEIVED = '[Reports] [Sales] Get Shift Summary Report Received',
  GET_SHIFT_SUMMARY_REPORT_SUCCESS = '[Reports] [Sales] Get Shift Summary Report Success',
  SHIFT_SUMMARY_REPORT_VIEW = '[Reports] [Sales] Get Shift Summary Report View',

  GET_TOP_CUSTOMERS_REPORT = '[Reports] [Sales] Get Top Customers Report',
  GET_TOP_CUSTOMERS_REPORT_FAIL = '[Reports] [Sales] Get Top Customers Report Fail',
  GET_TOP_CUSTOMERS_REPORT_RECEIVED = '[Reports] [Sales] Get Top Customers Report Received',
  GET_TOP_CUSTOMERS_REPORT_SUCCESS = '[Reports] [Sales] Get Top Customers Report Success',
  TOP_CUSTOMERS_REPORT_VIEW = '[Reports] [Sales] Get Top Customers Report View',

  GET_TOP_SELLING_PRODUCTS_REPORT = '[Reports] [Sales] Get Top Selling Products Report',
  GET_TOP_SELLING_PRODUCTS_REPORT_FAIL = '[Reports] [Sales] Get Top Selling Products Report Fail',
  GET_TOP_SELLING_PRODUCTS_REPORT_RECEIVED = '[Reports] [Sales] Get Top Selling Products Report Received',
  GET_TOP_SELLING_PRODUCTS_REPORT_SUCCESS = '[Reports] [Sales] Get Top Selling Products Report Success',
  TOP_SELLING_PRODUCTS_REPORT_VIEW = '[Reports] [Sales] Get Top Selling Products Report View',

  GET_WORST_CUSTOMERS_REPORT = '[Reports] [Sales] Get Worst Customers Report',
  GET_WORST_CUSTOMERS_REPORT_FAIL = '[Reports] [Sales] Get Worst Customers Report Fail',
  GET_WORST_CUSTOMERS_REPORT_RECEIVED = '[Reports] [Sales] Get Worst Customers Report Received',
  GET_WORST_CUSTOMERS_REPORT_SUCCESS = '[Reports] [Sales] Get Worst Customers Report Success',
  WORST_CUSTOMERS_REPORT_VIEW = '[Reports] [Sales] Get Worst Customers Report View',

  GET_SALES_PERSON_SALES_REPORT = '[Reports] [Sales] Get Sales Person Sales Report',
  GET_SALES_PERSON_SALES_REPORT_FAIL = '[Reports] [Sales] Get Sales Person Sales Report Fail',
  GET_SALES_PERSON_SALES_REPORT_RECEIVED = '[Reports] [Sales] Get Sales Person Sales Report Received',
  GET_SALES_PERSON_REPORT_SUCCESS = '[Reports] [Sales] Get Sales Person Sales Report Success',
  SALES_PERSON_SALES_REPORT_VIEW = '[Reports] [Sales] Get Sales Person Sales Report View',

  GET_WORST_RETURNED_SALES_PRODUCTS_REPORT = '[Reports] [Sales] Get Worst Returned Sales Products Report',
  GET_WORST_RETURNED_SALES_PRODUCTS_REPORT_FAIL = '[Reports] [Sales] Get Worst Returned Sales Products Report Fail',
  GET_WORST_RETURNED_SALES_PRODUCTS_REPORT_RECEIVED = '[Reports] [Sales] Get Worst Returned Sales Products Report Received',
  GET_WORST_RETURNED_SALES_PRODUCTS_REPORT_SUCCESS = '[Reports] [Sales] Get Worst Returned Sales Products Report Success',
  WORST_RETURNED_SALES_PRODUCTS_REPORT_VIEW = '[Reports] [Sales] Get Worst Returned Sales Products Report View',

  GET_WORST_SELLING_PRODUCTS_REPORT = '[Reports] [Sales] Get Worst Selling Products Report',
  GET_WORST_SELLING_PRODUCTS_REPORT_FAIL = '[Reports] [Sales] Get Worst Selling Products Report Fail',
  GET_WORST_SELLING_PRODUCTS_REPORT_RECEIVED = '[Reports] [Sales] Get Worst Selling Products Report Received',
  GET_WORST_SELLING_PRODUCTS_REPORT_SUCCESS = '[Reports] [Sales] Get Worst Selling Products Report Success',
  WORST_SELLING_PRODUCTS_REPORT_VIEW = '[Reports] [Sales] Get Worst Selling Products Report View',
}

/**
 * Represents a store customer summary report load action.
 */
export class GetCustomerSummaryReport implements Action {
  /**
   * The type of the action.
   */
  readonly type = SalesReportsActionType.GET_CUSTOMER_SUMMARY_REPORT;

  /**
   * Fires a new customer summary report load action.
   * @param payload the search parameters,
   * if omitted, all customer summary report will be loaded.
   */
  constructor(
    public payload?: {
      customerId: string;
      fromDate: Date;
      toDate: Date;
    }
  ) {}
}

/**
 * Represents a store customer summary report load fail action.
 */
export class GetCustomerSummaryReportFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = SalesReportsActionType.GET_CUSTOMER_SUMMARY_REPORT_FAIL;

  /**
   * Fires a new customer summary report load fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents an customer summary  report received action.
 */
export class GetCustomerSummaryReportReceived implements Action {
  /**
   * The type of the action.
   */
  readonly type = SalesReportsActionType.GET_CUSTOMER_SUMMARY_REPORT_RECEIVED;

  /**
   * Fires an customer summary report received action.
   * @param payload Received acknowledgment.
   */
  constructor(public payload: AppHttpResponse<string>) {}
}
/**
 * Represents a store customer summary report get success action.
 */
export class GetCustomerSummaryReportSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = SalesReportsActionType.GET_CUSTOMER_SUMMARY_REPORT_SUCCESS;

  /**
   * Fires a customer summary report get success action.
   *  @param payload The loaded report.
   */
  constructor(public payload: AppHttpResponse<Report>) {}
}

/**
 * Represents a customer summary report view action.
 */
export class CustomerSummaryReportView implements Action {
  /**
   * The type of the action.
   */
  readonly type = SalesReportsActionType.CUSTOMER_SUMMARY_REPORT_VIEW;

  /**
   * Fires an customer summary report view action.
   * @param payload The report to be loaded.
   */
  constructor(public payload: Report) {}
}

/**
 * Represents a customers debits report action.
 */
export class GetCustomersDebtsReport implements Action {
  /**
   * The type of the action.
   */
  readonly type = SalesReportsActionType.GET_CUSTOMERS_DEBTS_REPORT;

  /**
   * Fires a new customers debits report load action.
   */
  constructor() {}
}

/**
 * Represents a customers debits report fail action.
 */
export class GetCustomersDebtsReportFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = SalesReportsActionType.GET_CUSTOMERS_DEBTS_REPORT_FAIL;

  /**
   * Fires a new customers debits report fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a customers debits report received action.
 */
export class GetCustomersDebtsReportReceived implements Action {
  /**
   * The type of the action.
   */
  readonly type = SalesReportsActionType.GET_CUSTOMERS_DEBTS_REPORT_RECEIVED;

  /**
   * Fires a customers debits report received action.
   * @param payload Received acknowledgment.
   */
  constructor(public payload: AppHttpResponse<string>) {}
}

/**
 * Represents a customers debits report success action.
 */
export class GetCustomersDebtsReportSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = SalesReportsActionType.GET_CUSTOMERS_DEBTS_REPORT_SUCCESS;

  /**
   * Fires a customers debits report success action.
   * @param payload The loaded report.
   */
  constructor(public payload: AppHttpResponse<Report>) {}
}

/**
 * Represents a customers debits report view action.
 */
export class CustomersDebtsReportView implements Action {
  /**
   * The type of the action.
   */
  readonly type = SalesReportsActionType.CUSTOMERS_DEBTS_REPORT_VIEW;

  /**
   * Fires a customers debits report view action.
   * @param payload The report to be loaded.
   */
  constructor(public payload: Report) {}
}

/**
 * Represents a customers payments report action.
 */
export class GetCustomersPaymentsReport implements Action {
  /**
   * The type of the action.
   */
  readonly type = SalesReportsActionType.GET_CUSTOMERS_PAYMENTS_REPORT;

  /**
   * Fires a new customers payments report action.
   */
  constructor(
    public payload?: {
      customerLogTypes: number[];
      customers: number[];
      fromDate: Date;
      toDate: Date;
    }
  ) {}
}

/**
 * Represents a customers payments report fail action.
 */
export class GetCustomersPaymentsReportFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = SalesReportsActionType.GET_CUSTOMERS_PAYMENTS_REPORT_FAIL;

  /**
   * Fires a new customers payments report fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a customers payments report received action.
 */
export class GetCustomersPaymentsReportReceived implements Action {
  /**
   * The type of the action.
   */
  readonly type = SalesReportsActionType.GET_CUSTOMERS_PAYMENTS_REPORT_RECEIVED;

  /**
   * Fires a customers payments report received action.
   * @param payload Received acknowledgment.
   */
  constructor(public payload: AppHttpResponse<string>) {}
}

/**
 * Represents a customers payments report success action.
 */
export class GetCustomersPaymentsReportSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = SalesReportsActionType.GET_CUSTOMERS_PAYMENTS_REPORT_SUCCESS;

  /**
   * Fires a customers payments report success action.
   * @param payload The loaded report.
   */
  constructor(public payload: AppHttpResponse<Report>) {}
}

/**
 * Represents a customers payments report view action.
 */
export class CustomersPaymentsReportView implements Action {
  /**
   * The type of the action.
   */
  readonly type = SalesReportsActionType.CUSTOMERS_PAYMENTS_REPORT_VIEW;

  /**
   * Fires a customers payments report view action.
   * @param payload The report to be loaded.
   */
  constructor(public payload: Report) {}
}

/**
 * Represents a income statement report action.
 */
export class GetCustomersPointsReport implements Action {
  /**
   * The type of the action.
   */
  readonly type = SalesReportsActionType.GET_CUSTOMERS_POINTS_REPORT;

  /**
   * Fires a new customers points report load action.
   * @param payload the search parameters,
   * if omitted, all customers points report will be loaded.
   */
  constructor(
    public payload: {
      customers: number[];
    }
  ) {}
}

/**
 * Represents a income statement report fail action.
 */
export class GetCustomersPointsReportFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = SalesReportsActionType.GET_CUSTOMERS_POINTS_REPORT_FAIL;

  /**
   * Fires a new income statement report fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a income statement report received action.
 */
export class GetCustomersPointsReportReceived implements Action {
  /**
   * The type of the action.
   */
  readonly type = SalesReportsActionType.GET_CUSTOMERS_POINTS_REPORT_RECEIVED;

  /**
   * Fires a income statement report received action.
   * @param payload Received acknowledgment.
   */
  constructor(public payload: AppHttpResponse<string>) {}
}

/**
 * Represents a income statement report success action.
 */
export class GetCustomersPointsReportSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = SalesReportsActionType.GET_CUSTOMERS_POINTS_REPORT_SUCCESS;

  /**
   * Fires a income statement report success action.
   * @param payload The loaded report.
   */
  constructor(public payload: AppHttpResponse<Report>) {}
}

/**
 * Represents a income statement report view action.
 */
export class CustomersPointsReportView implements Action {
  /**
   * The type of the action.
   */
  readonly type = SalesReportsActionType.CUSTOMERS_POINTS_REPORT_VIEW;

  /**
   * Fires a income statement report view action.
   * @param payload The report to be loaded.
   */
  constructor(public payload: Report) {}
}

/**
 * Represents a gross profit report action.
 */
export class GetGrossProfitReport implements Action {
  /**
   * The type of the action.
   */
  readonly type = SalesReportsActionType.GET_GROSS_PROFIT_REPORT;

  /**
   * Fires a new gross profit report action.
   */
  constructor(
    public payload: {
      locations: number[];
      fromDate: Date;
      toDate: Date;
    }
  ) {}
}

/**
 * Represents a gross profit report fail action.
 */
export class GetGrossProfitReportFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = SalesReportsActionType.GET_GROSS_PROFIT_REPORT_FAIL;

  /**
   * Fires a new gross profit report fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a gross profit report received action.
 */
export class GetGrossProfitReportReceived implements Action {
  /**
   * The type of the action.
   */
  readonly type = SalesReportsActionType.GET_GROSS_PROFIT_REPORT_RECEIVED;

  /**
   * Fires a gross profit report received action.
   * @param payload Received acknowledgment.
   */
  constructor(public payload: AppHttpResponse<string>) {}
}

/**
 * Represents a gross profit report success action.
 */
export class GetGrossProfitReportSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = SalesReportsActionType.GET_GROSS_PROFIT_REPORT_SUCCESS;

  /**
   * Fires a gross profit report success action.
   * @param payload The loaded report.
   */
  constructor(public payload: AppHttpResponse<Report>) {}
}

/**
 * Represents a gross profit report view action.
 */
export class GrossProfitReportView implements Action {
  /**
   * The type of the action.
   */
  readonly type = SalesReportsActionType.GROSS_PROFIT_REPORT_VIEW;

  /**
   * Fires a gross profit report view action.
   * @param payload The report to be loaded.
   */
  constructor(public payload: Report) {}
}

/**
 * Represents a maintenance stage invoice report action.
 */
export class GetMaintenanceStageInvoiceReport implements Action {
  /**
   * The type of the action.
   */
  readonly type = SalesReportsActionType.GET_MAINTENANCE_STAGE_INVOICE_REPORT;

  /**
   * Fires a new maintenance stage invoice report action.
   */
  constructor(
    public payload: {
      maintenanceStages: number[];
      locations: number[];
      fromDate: Date;
      toDate: Date;
      displayProductsInReport: boolean;
    }
  ) {}
}

/**
 * Represents a maintenance stage invoice report fail action.
 */
export class GetMaintenanceStageInvoiceReportFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = SalesReportsActionType.GET_MAINTENANCE_STAGE_INVOICE_REPORT_FAIL;

  /**
   * Fires a new maintenance stage invoice report fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a maintenance stage invoice report received action.
 */
export class GetMaintenanceStageInvoiceReportReceived implements Action {
  /**
   * The type of the action.
   */
  readonly type = SalesReportsActionType.GET_MAINTENANCE_STAGE_INVOICE_REPORT_RECEIVED;

  /**
   * Fires a maintenance stage invoice report received action.
   * @param payload Received acknowledgment.
   */
  constructor(public payload: AppHttpResponse<string>) {}
}

/**
 * Represents a maintenance stage invoice report success action.
 */
export class GetMaintenanceStageInvoiceReportSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = SalesReportsActionType.GET_MAINTENANCE_STAGE_INVOICE_REPORT_SUCCESS;

  /**
   * Fires a maintenance stage invoice report success action.
   * @param payload The loaded report.
   */
  constructor(public payload: AppHttpResponse<Report>) {}
}

/**
 * Represents a maintenance stage invoice report view action.
 */
export class MaintenanceStageInvoiceReportView implements Action {
  /**
   * The type of the action.
   */
  readonly type = SalesReportsActionType.MAINTENANCE_STAGE_INVOICE_REPORT_VIEW;

  /**
   * Fires a maintenance stage invoice report view action.
   * @param payload The report to be loaded.
   */
  constructor(public payload: Report) {}
}

/**
 * Represents a most returned sale products report action.
 */
export class GetTopReturnedSalesProductsReport implements Action {
  /**
   * The type of the action.
   */
  readonly type = SalesReportsActionType.GET_TOP_RETURNED_SALES_PRODUCTS_REPORT;

  /**
   * Fires a new most returned sale products report action.
   */
  constructor(
    public payload: {
      locations: number[];
      fromDate: Date;
      toDate: Date;
      count: number;
    }
  ) {}
}

/**
 * Represents a most returned sale products report fail action.
 */
export class GetTopReturnedSalesProductsReportFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = SalesReportsActionType.GET_TOP_RETURNED_SALES_PRODUCTS_REPORT_FAIL;

  /**
   * Fires a new most returned sale products report fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a most returned sale products report received action.
 */
export class GetTopReturnedSalesProductsReportReceived implements Action {
  /**
   * The type of the action.
   */
  readonly type = SalesReportsActionType.GET_TOP_RETURNED_SALES_PRODUCTS_REPORT_RECEIVED;

  /**
   * Fires a most returned sale products report received action.
   * @param payload Received acknowledgment.
   */
  constructor(public payload: AppHttpResponse<string>) {}
}

/**
 * Represents a most returned sale products report success action.
 */
export class GetTopReturnedSalesProductsReportSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = SalesReportsActionType.GET_TOP_RETURNED_SALES_PRODUCTS_REPORT_SUCCESS;

  /**
   * Fires a most returned sale products report success action.
   * @param payload The loaded report.
   */
  constructor(public payload: AppHttpResponse<Report>) {}
}

/**
 * Represents a most returned sale products report view action.
 */
export class TopReturnedSalesProductsReportView implements Action {
  /**
   * The type of the action.
   */
  readonly type = SalesReportsActionType.TOP_RETURNED_SALES_PRODUCTS_REPORT_VIEW;

  /**
   * Fires a most returned sale products report view action.
   * @param payload The report to be loaded.
   */
  constructor(public payload: Report) {}
}

/**
 * Represents a net sales  report action.
 */
export class GetNetSalesReport implements Action {
  /**
   * The type of the action.
   */
  readonly type = SalesReportsActionType.GET_NET_SALES_REPORT;

  /**
   * Fires a new net sales  report action.
   */
  constructor(
    public payload: {
      locations: number[];
      customers: number[];
      saleInvoiceTypes: number[];
      saleInvoiceOrderTypes: number[];
      fromDate: Date;
      toDate: Date;
      displayProducts: boolean;
    }
  ) {}
}

/**
 * Represents a net sales  report fail action.
 */
export class GetNetSalesReportFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = SalesReportsActionType.GET_NET_SALES_REPORT_FAIL;

  /**
   * Fires a new net sales  report fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a net sales  report received action.
 */
export class GetNetSalesReportReceived implements Action {
  /**
   * The type of the action.
   */
  readonly type = SalesReportsActionType.GET_NET_SALES_REPORT_RECEIVED;

  /**
   * Fires a net sales  report received action.
   * @param payload Received acknowledgment.
   */
  constructor(public payload: AppHttpResponse<string>) {}
}

/**
 * Represents a net sales  report success action.
 */
export class GetNetSalesReportSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = SalesReportsActionType.GET_NET_SALES_REPORT_SUCCESS;

  /**
   * Fires a net sales  report success action.
   * @param payload The loaded report.
   */
  constructor(public payload: AppHttpResponse<Report>) {}
}

/**
 * Represents a net sales  report view action.
 */
export class NetSalesReportView implements Action {
  /**
   * The type of the action.
   */
  readonly type = SalesReportsActionType.NET_SALES_REPORT_VIEW;

  /**
   * Fires a net sales  report view action.
   * @param payload The report to be loaded.
   */
  constructor(public payload: Report) {}
}

/**
 * Represents a product sales report action.
 */
export class GetProductSalesReport implements Action {
  /**
   * The type of the action.
   */
  readonly type = SalesReportsActionType.GET_PRODUCT_SALES_REPORT;

  /**
   * Fires a new product sales report action.
   */
  constructor(
    public payload?: {
      productId: string;
      orderTypes: number[];
      customers: number[];
      locations: number[];
      fromDate: Date;
      toDate: Date;
    }
  ) {}
}

/**
 * Represents a product sales report fail action.
 */
export class GetProductSalesReportFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = SalesReportsActionType.GET_PRODUCT_SALES_REPORT_FAIL;

  /**
   * Fires a new product sales report fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a product sales report received action.
 */
export class GetProductSalesReportReceived implements Action {
  /**
   * The type of the action.
   */
  readonly type = SalesReportsActionType.GET_PRODUCT_SALES_REPORT_RECEIVED;

  /**
   * Fires a product sales report received action.
   * @param payload Received acknowledgment.
   */
  constructor(public payload: AppHttpResponse<string>) {}
}

/**
 * Represents a product sales report success action.
 */
export class GetProductSalesReportSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = SalesReportsActionType.GET_PRODUCT_SALES_REPORT_SUCCESS;

  /**
   * Fires a product sales report success action.
   * @param payload The loaded report.
   */
  constructor(public payload: AppHttpResponse<Report>) {}
}

/**
 * Represents a product sales report view action.
 */
export class ProductSalesReportView implements Action {
  /**
   * The type of the action.
   */
  readonly type = SalesReportsActionType.PRODUCT_SALES_REPORT_VIEW;

  /**
   * Fires a product sales report view action.
   * @param payload The report to be loaded.
   */
  constructor(public payload: Report) {}
}

/**
 * Represents a product sales returns report action.
 */
export class GetProductSalesReturnsReport implements Action {
  /**
   * The type of the action.
   */
  readonly type = SalesReportsActionType.GET_PRODUCT_SALES_RETURNS_REPORT;

  /**
   * Fires a new product sales returns report action.
   */
  constructor(
    public payload?: {
      productId: string;
      locations: number[];
      fromDate: Date;
      toDate: Date;
    }
  ) {}
}

/**
 * Represents a product sales returns report fail action.
 */
export class GetProductSalesReturnsReportFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = SalesReportsActionType.GET_PRODUCT_SALES_RETURNS_REPORT_FAIL;

  /**
   * Fires a new product sales returns report fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a product sales returns report received action.
 */
export class GetProductSalesReturnsReportReceived implements Action {
  /**
   * The type of the action.
   */
  readonly type = SalesReportsActionType.GET_PRODUCT_SALES_RETURNS_REPORT_RECEIVED;

  /**
   * Fires a product sales returns report received action.
   * @param payload Received acknowledgment.
   */
  constructor(public payload: AppHttpResponse<string>) {}
}

/**
 * Represents a product sales returns report success action.
 */
export class GetProductSalesReturnsReportSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = SalesReportsActionType.GET_PRODUCT_SALES_RETURNS_REPORT_SUCCESS;

  /**
   * Fires a product sales returns report success action.
   * @param payload The loaded report.
   */
  constructor(public payload: AppHttpResponse<Report>) {}
}

/**
 * Represents a product sales returns report view action.
 */
export class ProductSalesReturnsReportView implements Action {
  /**
   * The type of the action.
   */
  readonly type = SalesReportsActionType.PRODUCT_SALES_RETURNS_REPORT_VIEW;

  /**
   * Fires a product sales returns report view action.
   * @param payload The report to be loaded.
   */
  constructor(public payload: Report) {}
}

/**
 * Represents a product classes sales report action.
 */
export class GetProductClassesSalesReport implements Action {
  /**
   * The type of the action.
   */
  readonly type = SalesReportsActionType.GET_PRODUCT_CLASSES_SALES_REPORT;

  /**
   * Fires a new product classes sales report load action.
   * @param payload the search parameters,
   * if omitted, all product classes sales report will be loaded.
   */
  constructor(
    public payload?: {
      locations: number[];
      productClasses: number[];
      users: number[];
      fromDate: Date;
      toDate: Date;
    }
  ) {}
}

/**
 * Represents a product classes sales report fail action.
 */
export class GetProductClassesSalesReportFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = SalesReportsActionType.GET_PRODUCT_CLASSES_SALES_REPORT_FAIL;

  /**
   * Fires a new product classes sales report fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a product classes sales report received action.
 */
export class GetProductClassesSalesReportReceived implements Action {
  /**
   * The type of the action.
   */
  readonly type = SalesReportsActionType.GET_PRODUCT_CLASSES_SALES_REPORT_RECEIVED;

  /**
   * Fires a product classes sales report received action.
   * @param payload Received acknowledgment.
   */
  constructor(public payload: AppHttpResponse<string>) {}
}

/**
 * Represents a product classes sales report success action.
 */
export class GetProductClassesSalesReportSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = SalesReportsActionType.GET_PRODUCT_CLASSES_SALES_REPORT_SUCCESS;

  /**
   * Fires a product classes sales report success action.
   * @param payload The loaded report.
   */
  constructor(public payload: AppHttpResponse<Report>) {}
}

/**
 * Represents a product classes sales report view action.
 */
export class ProductClassesSalesReportView implements Action {
  /**
   * The type of the action.
   */
  readonly type = SalesReportsActionType.PRODUCT_CLASSES_SALES_REPORT_VIEW;

  /**
   * Fires a product classes sales report view action.
   * @param payload The report to be loaded.
   */
  constructor(public payload: Report) {}
}
/**
 * Represents a products sales report action.
 */
export class GetProductsSalesReport implements Action {
  /**
   * The type of the action.
   */
  readonly type = SalesReportsActionType.GET_PRODUCTS_SALES_REPORT;

  /**
   * Fires a new products sales report load action.
   * @param payload the search parameters,
   * if omitted, all products sales report will be loaded.
   */
  constructor(
    public payload?: {
      products: number[];
      productClasses: number[];
      orderTypes: number[];
      customers: number[];
      locations: number[];
      users: number[];
      fromDate: Date;
      toDate: Date;
    }
  ) {}
}

/**
 * Represents a products sales report fail action.
 */
export class GetProductsSalesReportFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = SalesReportsActionType.GET_PRODUCTS_SALES_REPORT_FAIL;

  /**
   * Fires a new products sales report fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a products sales report received action.
 */
export class GetProductsSalesReportReceived implements Action {
  /**
   * The type of the action.
   */
  readonly type = SalesReportsActionType.GET_PRODUCTS_SALES_REPORT_RECEIVED;

  /**
   * Fires a products sales report received action.
   * @param payload Received acknowledgment.
   */
  constructor(public payload: AppHttpResponse<string>) {}
}

/**
 * Represents a products sales report success action.
 */
export class GetProductsSalesReportSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = SalesReportsActionType.GET_PRODUCTS_SALES_REPORT_SUCCESS;

  /**
   * Fires a products sales report success action.
   * @param payload The loaded report.
   */
  constructor(public payload: AppHttpResponse<Report>) {}
}

/**
 * Represents a products sales report view action.
 */
export class ProductsSalesReportView implements Action {
  /**
   * The type of the action.
   */
  readonly type = SalesReportsActionType.PRODUCTS_SALES_REPORT_VIEW;

  /**
   * Fires a products sales report view action.
   * @param payload The report to be loaded.
   */
  constructor(public payload: Report) {}
}

/**
 * Represents a products sales returns report action.
 */
export class GetProductsSalesReturnsReport implements Action {
  /**
   * The type of the action.
   */
  readonly type = SalesReportsActionType.GET_PRODUCTS_SALES_RETURNS_REPORT;

  /**
   * Fires a new products sales returns report load action.
   * @param payload the search parameters,
   * if omitted, all products sales returns report will be loaded.
   */
  constructor(
    public payload: {
      products: number[];
      productClasses: number[];
      locations: number[];
      users: number[];
      fromDate: Date;
      toDate: Date;
    }
  ) {}
}

/**
 * Represents a products sales returns report fail action.
 */
export class GetProductsSalesReturnsReportFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = SalesReportsActionType.GET_PRODUCTS_SALES_RETURNS_REPORT_FAIL;

  /**
   * Fires a new products sales returns report fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a products sales returns report received action.
 */
export class GetProductsSalesReturnsReportReceived implements Action {
  /**
   * The type of the action.
   */
  readonly type = SalesReportsActionType.GET_PRODUCTS_SALES_RETURNS_REPORT_RECEIVED;

  /**
   * Fires a products sales returns report received action.
   * @param payload Received acknowledgment.
   */
  constructor(public payload: AppHttpResponse<string>) {}
}

/**
 * Represents a products sales returns report success action.
 */
export class GetProductsSalesReturnsReportSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = SalesReportsActionType.GET_PRODUCTS_SALES_RETURNS_REPORT_SUCCESS;

  /**
   * Fires a products sales returns report success action.
   * @param payload The loaded report.
   */
  constructor(public payload: AppHttpResponse<Report>) {}
}

/**
 * Represents a products sales returns report view action.
 */
export class ProductsSalesReturnsReportView implements Action {
  /**
   * The type of the action.
   */
  readonly type = SalesReportsActionType.PRODUCTS_SALES_RETURNS_REPORT_VIEW;

  /**
   * Fires a products sales returns report view action.
   * @param payload The report to be loaded.
   */
  constructor(public payload: Report) {}
}

/**
 * Represents a sales invoices report action.
 */
export class GetSalesInvoicesReport implements Action {
  /**
   * The type of the action.
   */
  readonly type = SalesReportsActionType.GET_SALES_INVOICES_REPORT;

  /**
   * Fires a new sales invoices report load action.
   * @param payload the search parameters,
   * if omitted, all sales invoices report will be loaded.
   */
  constructor(
    public payload?: {
      locations: number[];
      customers: number[];
      saleInvoiceTypes: number[];
      saleInvoiceOrderTypes: number[];
      fromDate: Date;
      toDate: Date;
      displayProducts: boolean;
    }
  ) {}
}

/**
 * Represents a sales invoices report fail action.
 */
export class GetSalesInvoicesReportFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = SalesReportsActionType.GET_SALES_INVOICES_REPORT_FAIL;

  /**
   * Fires a new sales invoices report fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a sales invoices report received action.
 */
export class GetSalesInvoicesReportReceived implements Action {
  /**
   * The type of the action.
   */
  readonly type = SalesReportsActionType.GET_SALES_INVOICES_REPORT_RECEIVED;

  /**
   * Fires a sales invoices report received action.
   * @param payload Received acknowledgment.
   */
  constructor(public payload: AppHttpResponse<string>) {}
}

/**
 * Represents a sales invoices report success action.
 */
export class GetSalesInvoicesReportSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = SalesReportsActionType.GET_SALES_INVOICES_REPORT_SUCCESS;

  /**
   * Fires a sales invoices report success action.
   * @param payload The loaded report.
   */
  constructor(public payload: AppHttpResponse<Report>) {}
}

/**
 * Represents a sales invoices report view action.
 */
export class SalesInvoicesReportView implements Action {
  /**
   * The type of the action.
   */
  readonly type = SalesReportsActionType.SALES_INVOICES_REPORT_VIEW;

  /**
   * Fires a sales invoices report view action.
   * @param payload The report to be loaded.
   */
  constructor(public payload: Report) {}
}

/**
 * Represents a sales returns report action.
 */
export class GetSalesReturnsReport implements Action {
  /**
   * The type of the action.
   */
  readonly type = SalesReportsActionType.GET_SALES_RETURNS_REPORT;

  /**
   * Fires a new sales returns report load action.
   * @param payload the search parameters,
   * if omitted, all sales returns report will be loaded.
   */
  constructor(
    public payload?: {
      locations: number[];
      customers: number[];
      fromDate: Date;
      toDate: Date;
      displayProducts: boolean;
    }
  ) {}
}

/**
 * Represents a sales returns report fail action.
 */
export class GetSalesReturnsReportFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = SalesReportsActionType.GET_SALES_RETURNS_REPORT_FAIL;

  /**
   * Fires a new sales returns report fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a sales returns report received action.
 */
export class GetSalesReturnsReportReceived implements Action {
  /**
   * The type of the action.
   */
  readonly type = SalesReportsActionType.GET_SALES_RETURNS_REPORT_RECEIVED;

  /**
   * Fires a sales returns report received action.
   * @param payload Received acknowledgment.
   */
  constructor(public payload: AppHttpResponse<string>) {}
}

/**
 * Represents a sales returns report success action.
 */
export class GetSalesReturnsReportSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = SalesReportsActionType.GET_SALES_RETURNS_REPORT_SUCCESS;

  /**
   * Fires a sales returns report success action.
   * @param payload The loaded report.
   */
  constructor(public payload: AppHttpResponse<Report>) {}
}

/**
 * Represents a sales returns report view action.
 */
export class SalesReturnsReportView implements Action {
  /**
   * The type of the action.
   */
  readonly type = SalesReportsActionType.SALES_RETURNS_REPORT_VIEW;

  /**
   * Fires a sales returns report view action.
   * @param payload The report to be loaded.
   */
  constructor(public payload: Report) {}
}

/**
 * Represents a sales summary report action.
 */
export class GetSalesSummary implements Action {
  /**
   * The type of the action.
   */
  readonly type = SalesReportsActionType.GET_SALES_SUMMARY_REPORT;

  /**
   * Fires a new sales summary report load action.
   * @param payload the search parameters,
   * if omitted, all sales summary report will be loaded.
   */
  constructor(
    public payload: {
      customers: number[];
      locations: number[];
      saleInvoiceTypes: number[];
      fromDate: Date;
      toDate: Date;
    }
  ) {}
}

/**
 * Represents a sales summary report fail action.
 */
export class GetSalesSummaryFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = SalesReportsActionType.GET_SALES_SUMMARY_REPORT_FAIL;

  /**
   * Fires a new sales summary report fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a sales summary report received action.
 */
export class GetSalesSummaryReceived implements Action {
  /**
   * The type of the action.
   */
  readonly type = SalesReportsActionType.GET_SALES_SUMMARY_REPORT_RECEIVED;

  /**
   * Fires a sales summary report received action.
   * @param payload Received acknowledgment.
   */
  constructor(public payload: AppHttpResponse<string>) {}
}

/**
 * Represents a sales summary report success action.
 */
export class GetSalesSummarySuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = SalesReportsActionType.GET_SALES_SUMMARY_REPORT_SUCCESS;

  /**
   * Fires a sales summary report success action.
   * @param payload The loaded report.
   */
  constructor(public payload: AppHttpResponse<Report>) {}
}

/**
 * Represents a sales summary report view action.
 */
export class SalesSummaryView implements Action {
  /**
   * The type of the action.
   */
  readonly type = SalesReportsActionType.SALES_SUMMARY_REPORT_VIEW;

  /**
   * Fires a sales summary report view action.
   * @param payload The report to be loaded.
   */
  constructor(public payload: Report) {}
}

/**
 * Represents a shift report action.
 */
export class GetShiftSummaryReport implements Action {
  /**
   * The type of the action.
   */
  readonly type = SalesReportsActionType.GET_SHIFT_SUMMARY_REPORT;

  /**
   * Fires a new shift report load action.
   * @param payload the search parameters,
   * if omitted, all shift report will be loaded.
   */
  constructor(
    public payload?: {
      shiftId: number;
      paperSize: string;
    }
  ) {}
}

/**
 * Represents a shift report fail action.
 */
export class GetShiftSummaryReportFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = SalesReportsActionType.GET_SHIFT_SUMMARY_REPORT_FAIL;

  /**
   * Fires a new shift report fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a shift report received action.
 */
export class GetShiftSummaryReportReceived implements Action {
  /**
   * The type of the action.
   */
  readonly type = SalesReportsActionType.GET_SHIFT_SUMMARY_REPORT_RECEIVED;

  /**
   * Fires a shift report received action.
   * @param payload Received acknowledgment.
   */
  constructor(public payload: AppHttpResponse<string>) {}
}

/**
 * Represents a shift report success action.
 */
export class GetShiftSummaryReportSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = SalesReportsActionType.GET_SHIFT_SUMMARY_REPORT_SUCCESS;

  /**
   * Fires a shift report success action.
   * @param payload The loaded report.
   */
  constructor(public payload: AppHttpResponse<Report>) {}
}

/**
 * Represents a shift report view action.
 */
export class ShiftSummaryReportView implements Action {
  /**
   * The type of the action.
   */
  readonly type = SalesReportsActionType.SHIFT_SUMMARY_REPORT_VIEW;

  /**
   * Fires a shift report view action.
   * @param payload The report to be loaded.
   */
  constructor(public payload: Report) {}
}

/**
 * Represents a top customers report action.
 */
export class GetTopCustomersReport implements Action {
  /**
   * The type of the action.
   */
  readonly type = SalesReportsActionType.GET_TOP_CUSTOMERS_REPORT;

  /**
   * Fires a new top customers report load action.
   * @param payload the search parameters,
   * if omitted, all top customers report will be loaded.
   */
  constructor(
    public payload?: {
      count: number;
      locations: number[];
      fromDate: Date;
      toDate: Date;
    }
  ) {}
}

/**
 * Represents a top customers report fail action.
 */
export class GetTopCustomersReportFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = SalesReportsActionType.GET_TOP_CUSTOMERS_REPORT_FAIL;

  /**
   * Fires a new top customers report fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a top customers report received action.
 */
export class GetTopCustomersReportReceived implements Action {
  /**
   * The type of the action.
   */
  readonly type = SalesReportsActionType.GET_TOP_CUSTOMERS_REPORT_RECEIVED;

  /**
   * Fires a top customers report received action.
   * @param payload Received acknowledgment.
   */
  constructor(public payload: AppHttpResponse<string>) {}
}

/**
 * Represents a top customers report success action.
 */
export class GetTopCustomersReportSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = SalesReportsActionType.GET_TOP_CUSTOMERS_REPORT_SUCCESS;

  /**
   * Fires a top customers report success action.
   * @param payload The loaded report.
   */
  constructor(public payload: AppHttpResponse<Report>) {}
}

/**
 * Represents a top customers report view action.
 */
export class TopCustomersReportView implements Action {
  /**
   * The type of the action.
   */
  readonly type = SalesReportsActionType.TOP_CUSTOMERS_REPORT_VIEW;

  /**
   * Fires a top customers report view action.
   * @param payload The report to be loaded.
   */
  constructor(public payload: Report) {}
}

/**
 * Represents a top selling products report action.
 */
export class GetTopSellingProductsReport implements Action {
  /**
   * The type of the action.
   */
  readonly type = SalesReportsActionType.GET_TOP_SELLING_PRODUCTS_REPORT;

  /**
   * Fires a new top selling products report load action.
   * @param payload the search parameters,
   * if omitted, all top selling products report will be loaded.
   */
  constructor(
    public payload: {
      fromDate: Date;
      toDate: Date;
      count: number;
      locations: number[];
    }
  ) {}
}

/**
 * Represents a top selling products report fail action.
 */
export class GetTopSellingProductsReportFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = SalesReportsActionType.GET_TOP_SELLING_PRODUCTS_REPORT_FAIL;

  /**
   * Fires a new top selling products report fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a top selling products report received action.
 */
export class GetTopSellingProductsReportReceived implements Action {
  /**
   * The type of the action.
   */
  readonly type = SalesReportsActionType.GET_TOP_SELLING_PRODUCTS_REPORT_RECEIVED;

  /**
   * Fires a top selling products report received action.
   * @param payload Received acknowledgment.
   */
  constructor(public payload: AppHttpResponse<string>) {}
}

/**
 * Represents a top selling products report success action.
 */
export class GetTopSellingProductsReportSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = SalesReportsActionType.GET_TOP_SELLING_PRODUCTS_REPORT_SUCCESS;

  /**
   * Fires a top selling products report success action.
   * @param payload The loaded report.
   */
  constructor(public payload: AppHttpResponse<Report>) {}
}

/**
 * Represents a top selling products report view action.
 */
export class TopSellingProductsReportView implements Action {
  /**
   * The type of the action.
   */
  readonly type = SalesReportsActionType.TOP_SELLING_PRODUCTS_REPORT_VIEW;

  /**
   * Fires a top selling products report view action.
   * @param payload The report to be loaded.
   */
  constructor(public payload: Report) {}
}

/**
 * Represents a worst customers report action.
 */
export class GetWorstCustomersReport implements Action {
  /**
   * The type of the action.
   */
  readonly type = SalesReportsActionType.GET_WORST_CUSTOMERS_REPORT;

  /**
   * Fires a new worst customers report load action.
   * @param payload the search parameters,
   * if omitted, all worst customers report will be loaded.
   */
  constructor(
    public payload?: {
      count: number;
      locations: number[];
      fromDate: Date;
      toDate: Date;
    }
  ) {}
}

/**
 * Represents a worst customers report fail action.
 */
export class GetWorstCustomersReportFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = SalesReportsActionType.GET_WORST_CUSTOMERS_REPORT_FAIL;

  /**
   * Fires a new worst customers report fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a worst customers report received action.
 */
export class GetWorstCustomersReportReceived implements Action {
  /**
   * The type of the action.
   */
  readonly type = SalesReportsActionType.GET_WORST_CUSTOMERS_REPORT_RECEIVED;

  /**
   * Fires a worst customers report received action.
   * @param payload Received acknowledgment.
   */
  constructor(public payload: AppHttpResponse<string>) {}
}

/**
 * Represents a worst customers report success action.
 */
export class GetWorstCustomersReportSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = SalesReportsActionType.GET_WORST_CUSTOMERS_REPORT_SUCCESS;

  /**
   * Fires a worst customers report success action.
   * @param payload The loaded report.
   */
  constructor(public payload: AppHttpResponse<Report>) {}
}

/**
 * Represents a worst customers report view action.
 */
export class WorstCustomersReportView implements Action {
  /**
   * The type of the action.
   */
  readonly type = SalesReportsActionType.WORST_CUSTOMERS_REPORT_VIEW;

  /**
   * Fires a worst customers report view action.
   * @param payload The report to be loaded.
   */
  constructor(public payload: Report) {}
}

/**
 * Represents a worst returned sale products report action.
 */
export class GetWorstReturnedSalesProductsReport implements Action {
  /**
   * The type of the action.
   */
  readonly type = SalesReportsActionType.GET_WORST_RETURNED_SALES_PRODUCTS_REPORT;

  /**
   * Fires a new worst returned sale products report load action.
   * @param payload the search parameters,
   * if omitted, all worst returned sale products report will be loaded.
   */
  constructor(
    public payload?: {
      fromDate: Date;
      toDate: Date;
      count: number;
      locations: number[];
    }
  ) {}
}

/**
 * Represents a worst returned sale products report fail action.
 */
export class GetWorstReturnedSalesProductsReportFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = SalesReportsActionType.GET_WORST_RETURNED_SALES_PRODUCTS_REPORT_FAIL;

  /**
   * Fires a new worst returned sale products report fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a worst returned sale products report received action.
 */
export class GetWorstReturnedSalesProductsReportReceived implements Action {
  /**
   * The type of the action.
   */
  readonly type = SalesReportsActionType.GET_WORST_RETURNED_SALES_PRODUCTS_REPORT_RECEIVED;

  /**
   * Fires a worst returned sale products report received action.
   * @param payload Received acknowledgment.
   */
  constructor(public payload: AppHttpResponse<string>) {}
}

/**
 * Represents a worst returned sale products report success action.
 */
export class GetWorstReturnedSalesProductsReportSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = SalesReportsActionType.GET_WORST_RETURNED_SALES_PRODUCTS_REPORT_SUCCESS;

  /**
   * Fires a worst returned sale products report success action.
   * @param payload The loaded report.
   */
  constructor(public payload: AppHttpResponse<Report>) {}
}

/**
 * Represents a worst returned sale products report view action.
 */
export class WorstReturnedSalesProductsReportView implements Action {
  /**
   * The type of the action.
   */
  readonly type = SalesReportsActionType.WORST_RETURNED_SALES_PRODUCTS_REPORT_VIEW;

  /**
   * Fires a worst returned sale products report view action.
   * @param payload The report to be loaded.
   */
  constructor(public payload: Report) {}
}

/**
 * Represents a worst selling products  report action.
 */
export class GetWorstSellingProductsReport implements Action {
  /**
   * The type of the action.
   */
  readonly type = SalesReportsActionType.GET_WORST_SELLING_PRODUCTS_REPORT;

  /**
   * Fires a new worst selling products  report load action.
   * @param payload the search parameters,
   * if omitted, all worst selling products  report will be loaded.
   */
  constructor(
    public payload?: {
      fromDate: Date;
      toDate: Date;
      count: number;
      locations: number[];
    }
  ) {}
}

/**
 * Represents a worst selling products  report fail action.
 */
export class GetWorstSellingProductsReportFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = SalesReportsActionType.GET_WORST_SELLING_PRODUCTS_REPORT_FAIL;

  /**
   * Fires a new worst selling products  report fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a worst selling products  report received action.
 */
export class GetWorstSellingProductsReportReceived implements Action {
  /**
   * The type of the action.
   */
  readonly type = SalesReportsActionType.GET_WORST_SELLING_PRODUCTS_REPORT_RECEIVED;

  /**
   * Fires a worst selling products  report received action.
   * @param payload Received acknowledgment.
   */
  constructor(public payload: AppHttpResponse<string>) {}
}

/**
 * Represents a worst selling products  report success action.
 */
export class GetWorstSellingProductsReportSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = SalesReportsActionType.GET_WORST_SELLING_PRODUCTS_REPORT_SUCCESS;

  /**
   * Fires a worst selling products  report success action.
   * @param payload The loaded report.
   */
  constructor(public payload: AppHttpResponse<Report>) {}
}

/**
 * Represents a worst selling products  report view action.
 */
export class WorstSellingProductsReportView implements Action {
  /**
   * The type of the action.
   */
  readonly type = SalesReportsActionType.WORST_SELLING_PRODUCTS_REPORT_VIEW;

  /**
   * Fires a worst selling products  report view action.
   * @param payload The report to be loaded.
   */
  constructor(public payload: Report) {}
}

/**
 * Represents a sales summary report action.
 */
export class GetSalesSummaryReport implements Action {
  /**
   * The type of the action.
   */
  readonly type = SalesReportsActionType.GET_SALES_SUMMARY_REPORT;

  /**
   * Fires a new sales summary report load action.
   * @param payload the search parameters,
   * if omitted, all sales summary report will be loaded.
   */
  constructor(
    public payload?: {
      customers: number[];
      locations: number[];
      types: number[];
      fromDate: Date;
      toDate: Date;
    }
  ) {}
}

/**
 * Represents a sales summary report fail action.
 */
export class GetSalesSummaryReportFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = SalesReportsActionType.GET_SALES_SUMMARY_REPORT_FAIL;

  /**
   * Fires a new sales summary report fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a sales summary report received action.
 */
export class GetSalesSummaryReportReceived implements Action {
  /**
   * The type of the action.
   */
  readonly type = SalesReportsActionType.GET_SALES_SUMMARY_REPORT_RECEIVED;

  /**
   * Fires a sales summary report received action.
   * @param payload Received acknowledgment.
   */
  constructor(public payload: AppHttpResponse<string>) {}
}

/**
 * Represents a sales summary report success action.
 */
export class GetSalesSummaryReportSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = SalesReportsActionType.GET_SALES_SUMMARY_REPORT_SUCCESS;

  /**
   * Fires a sales summary report success action.
   * @param payload The loaded report.
   */
  constructor(public payload: AppHttpResponse<Report>) {}
}

/**
 * Represents a sales summary report view action.
 */
export class SalesSummaryReportView implements Action {
  /**
   * The type of the action.
   */
  readonly type = SalesReportsActionType.SALES_SUMMARY_REPORT_VIEW;

  /**
   * Fires a sales summary report view action.
   * @param payload The report to be loaded.
   */
  constructor(public payload: Report) {}
}

/**
 * Represents a sales person sales report action.
 */
export class GetSalesPersonSalesReport implements Action {
  /**
   * The type of the action.
   */
  readonly type = SalesReportsActionType.GET_SALES_PERSON_SALES_REPORT;

  /**
   * Fires a new sales person sales report load action.
   * @param payload the search parameters,
   * if omitted, all sales person report will be loaded.
   */
  constructor(
    public payload?: {
      employeeId: string;
      fromDate: Date;
      toDate: Date;
    }
  ) {}
}

/**
 * Represents a sales person sales report fail action.
 */
export class GetSalesPersonSalesReportFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = SalesReportsActionType.GET_SALES_PERSON_SALES_REPORT_FAIL;

  /**
   * Fires a new sales person sales report fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a sales person sales report received action.
 */
export class GetSalesPersonSalesReportReceived implements Action {
  /**
   * The type of the action.
   */
  readonly type = SalesReportsActionType.GET_SALES_PERSON_SALES_REPORT_RECEIVED;

  /**
   * Fires a sales person report received action.
   * @param payload Received acknowledgment.
   */
  constructor(public payload: AppHttpResponse<string>) {}
}

/**
 * Represents a sales person sales report success action.
 */
export class GetSalesPersonSalesReportSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = SalesReportsActionType.GET_SALES_PERSON_REPORT_SUCCESS;

  /**
   * Fires a sales person sales report success action.
   * @param payload The loaded report.
   */
  constructor(public payload: AppHttpResponse<Report>) {}
}

/**
 * Represents a sales person report view action.
 */
export class SalesPersonSalesReportView implements Action {
  /**
   * The type of the action.
   */
  readonly type = SalesReportsActionType.SALES_PERSON_SALES_REPORT_VIEW;

  /**
   * Fires a sales person report view action.
   * @param payload The report to be loaded.
   */
  constructor(public payload: Report) {}
}

/**
 * Reports-Module sales reports action types.
 */
export type SalesReportsActions =
  | GetCustomerSummaryReport
  | GetCustomerSummaryReportFail
  | GetCustomerSummaryReportReceived
  | GetCustomerSummaryReportSuccess
  | CustomerSummaryReportView
  | GetCustomersDebtsReport
  | GetCustomersDebtsReportFail
  | GetCustomersDebtsReportReceived
  | GetCustomersDebtsReportSuccess
  | CustomersDebtsReportView
  | GetCustomersPaymentsReport
  | GetCustomersPaymentsReportFail
  | GetCustomersPaymentsReportReceived
  | GetCustomersPaymentsReportSuccess
  | CustomersPaymentsReportView
  | GetCustomersPointsReport
  | GetCustomersPointsReportFail
  | GetCustomersPointsReportReceived
  | GetCustomersPointsReportSuccess
  | CustomersPointsReportView
  | GetGrossProfitReport
  | GetGrossProfitReportFail
  | GetGrossProfitReportReceived
  | GetGrossProfitReportSuccess
  | GrossProfitReportView
  | GetMaintenanceStageInvoiceReport
  | GetMaintenanceStageInvoiceReportFail
  | GetMaintenanceStageInvoiceReportReceived
  | GetMaintenanceStageInvoiceReportSuccess
  | MaintenanceStageInvoiceReportView
  | GetTopReturnedSalesProductsReport
  | GetTopReturnedSalesProductsReportFail
  | GetTopReturnedSalesProductsReportReceived
  | GetTopReturnedSalesProductsReportSuccess
  | TopReturnedSalesProductsReportView
  | GetNetSalesReport
  | GetNetSalesReportFail
  | GetNetSalesReportReceived
  | GetNetSalesReportSuccess
  | NetSalesReportView
  | GetProductSalesReport
  | GetProductSalesReportFail
  | GetProductSalesReportReceived
  | GetProductSalesReportSuccess
  | ProductSalesReportView
  | GetProductSalesReturnsReport
  | GetProductSalesReturnsReportFail
  | GetProductSalesReturnsReportReceived
  | GetProductSalesReturnsReportSuccess
  | ProductSalesReturnsReportView
  | GetProductClassesSalesReport
  | GetProductClassesSalesReportFail
  | GetProductClassesSalesReportReceived
  | GetProductClassesSalesReportSuccess
  | ProductClassesSalesReportView
  | GetProductsSalesReport
  | GetProductsSalesReportFail
  | GetProductsSalesReportReceived
  | GetProductsSalesReportSuccess
  | ProductsSalesReportView
  | GetProductsSalesReturnsReport
  | GetProductsSalesReturnsReportFail
  | GetProductsSalesReturnsReportReceived
  | GetProductsSalesReturnsReportSuccess
  | ProductsSalesReturnsReportView
  | GetSalesInvoicesReport
  | GetSalesInvoicesReportFail
  | GetSalesInvoicesReportReceived
  | GetSalesInvoicesReportSuccess
  | SalesInvoicesReportView
  | GetSalesReturnsReport
  | GetSalesReturnsReportFail
  | GetSalesReturnsReportReceived
  | GetSalesReturnsReportSuccess
  | SalesReturnsReportView
  | GetSalesSummaryReport
  | GetSalesSummaryReportFail
  | GetSalesSummaryReportReceived
  | GetSalesSummaryReportSuccess
  | SalesSummaryReportView
  | GetShiftSummaryReport
  | GetShiftSummaryReportFail
  | GetShiftSummaryReportReceived
  | GetShiftSummaryReportSuccess
  | ShiftSummaryReportView
  | GetTopCustomersReport
  | GetTopCustomersReportFail
  | GetTopCustomersReportReceived
  | GetTopCustomersReportSuccess
  | TopCustomersReportView
  | GetTopSellingProductsReport
  | GetTopSellingProductsReportFail
  | GetTopSellingProductsReportReceived
  | GetTopSellingProductsReportSuccess
  | TopSellingProductsReportView
  | GetWorstCustomersReport
  | GetWorstCustomersReportFail
  | GetWorstCustomersReportReceived
  | GetWorstCustomersReportSuccess
  | WorstCustomersReportView
  | GetWorstReturnedSalesProductsReport
  | GetWorstReturnedSalesProductsReportFail
  | GetWorstReturnedSalesProductsReportReceived
  | GetWorstReturnedSalesProductsReportSuccess
  | WorstReturnedSalesProductsReportView
  | GetWorstSellingProductsReport
  | GetWorstSellingProductsReportFail
  | GetWorstSellingProductsReportReceived
  | GetWorstSellingProductsReportSuccess
  | WorstSellingProductsReportView
  | GetSalesPersonSalesReport
  | GetSalesPersonSalesReportFail
  | GetSalesPersonSalesReportReceived
  | GetSalesPersonSalesReportSuccess
  | SalesPersonSalesReportView;
