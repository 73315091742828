<!-- project status logs list starts -->
<div class="row">
  <div class="col-md-12">
    <div class="card-content">
      <div class="px-3">
        <div class="row">
          <div class="col-md-12">
            <form class="row" [formGroup]="searchForm" (ngSubmit)="search()">
              <div class="form-group col mb-0">
                <label for="fromDate">{{ 'SHARED.SEARCH.FROM_DATE' | translate }}</label>
                <div class="input-group round">
                  <input
                    class="form-control"
                    id="fromDate"
                    name="fromDate"
                    formControlName="fromDate"
                    [placeholder]="'SHARED.SEARCH.DATE_PLACEHOLDER' | translate"
                    ngbDatepicker
                    #fromDate="ngbDatepicker"
                  />
                  <div class="input-group-append">
                    <span
                      class="input-group-text gradient-light-blue-indigo"
                      style="cursor: pointer;"
                      (click)="fromDate.toggle()"
                    >
                      <i class="fa fa-calendar"></i>
                    </span>
                  </div>
                </div>
              </div>
              <div class="form-group col mb-0">
                <label for="toDate">{{ 'SHARED.SEARCH.TO_DATE' | translate }}</label>
                <div class="input-group round">
                  <input
                    class="form-control"
                    id="toDate"
                    name="toDate"
                    formControlName="toDate"
                    [placeholder]="'SHARED.SEARCH.DATE_PLACEHOLDER' | translate"
                    ngbDatepicker
                    #toDate="ngbDatepicker"
                  />
                  <div class="input-group-append">
                    <span
                      class="input-group-text gradient-light-blue-indigo"
                      style="cursor: pointer;"
                      (click)="toDate.toggle()"
                    >
                      <i class="fa fa-calendar"></i>
                    </span>
                  </div>
                </div>
              </div>
              <div class="col-md-auto form-group d-flex">
                <button
                  type="submit"
                  class="btn btn-raised btn-primary align-self-end round mx-1 mb-0"
                  [disabled]="isSearching$ | async"
                >
                  <i class="fa fa-search"></i>
                </button>
                <a
                  *ngIf="[Claims.UPDATE_PROJECT] | authorize | async"
                  (click)="create()"
                  class="btn btn-raised btn-success align-self-end round mx-1 mb-0"
                >
                  <i class="fa fa-plus"></i>
                  <span class="mx-1"> {{ 'SHARED.CONFIRM.ADD' | translate }} </span>
                </a>
                <app-export-tools
                  *ngIf="(projectLogs$ | async)?.length"
                  class="d-flex align-self-end mx-1"
                  targetElementId="project-status-logs-table-export-target-element"
                  [outputFileName]="'SHARED.EXPORT_TABLES.PROJECT_STATUS_LOGS_TABLE' | translate"
                ></app-export-tools>
              </div>
            </form>
          </div>
          <div class="col-md-12" *ngIf="(projectLogs$ | async)?.length; else noItems">
            <div class="row">
              <div class="col-md-12 alert alert-light text-center" role="alert">
                {{ 'SHARED.SEARCH.SEARCH_RESULTS' | translate }} ({{ (projectLogs$ | async).length }})
                {{ 'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.LOG' | translate }}
              </div>
              <div class="col-md-12">
                <div class="table-responsive">
                  <table class="table text-center" id="project-status-logs-table-export-target-element">
                    <thead>
                      <tr>
                        <th scope="col"><i class="fa fa-list-ol"></i></th>
                        <th scope="col">
                          {{ 'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.PROJECT' | translate }}
                        </th>
                        <th scope="col">
                          {{ 'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.PROJECT_STATUS_LOG' | translate }}
                        </th>
                        <th scope="col">
                          {{ 'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.CREATED_AT' | translate }}
                        </th>
                        <th scope="col">
                          {{ 'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.STATUS_DATE' | translate }}
                        </th>
                        <th scope="col">
                          {{ 'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.USER' | translate }}
                        </th>
                        <th scope="col">
                          {{ 'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.NOTES' | translate }}
                        </th>
                        <th scope="col">{{ 'SHARED.TOOLS' | translate }}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let projectLog of projectLogs$ | async; trackBy: trackItems; let i = index">
                        <th scope="row">{{ i + 1 }}</th>
                        <td>
                          {{ projectLog.project.description | localize: projectLog.project.descriptionEn | async }}
                        </td>
                        <td>
                          {{ projectLog.projectStatus.name | localize: projectLog.projectStatus.nameEn | async }}
                        </td>
                        <td>
                          <span [ngbTooltip]="projectLog.createdAt | date: 'time'">
                            {{ projectLog.createdAt | date }}
                          </span>
                        </td>
                        <td>
                          <span [ngbTooltip]="projectLog.transactionDate | date: 'time'">
                            {{ projectLog.transactionDate | date }}
                          </span>
                        </td>
                        <td>{{ projectLog.user.name | localize: projectLog.user.nameEn | async }}</td>
                        <td>
                          {{ projectLog.notes }}
                        </td>
                        <td>
                          <a
                            class="info p-0"
                            [ngbTooltip]="'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.ATTACHMENTS_DISPLAY' | translate"
                            *ngIf="projectLog.projectStatusLogAttachments.length"
                            (click)="viewAttachments(projectLog)"
                          >
                            <i class="fa fa-paperclip font-medium-3 mr-2"></i>
                          </a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- project status logs list ends -->

<!-- no items template starts -->
<ng-template #noItems>
  <div class="col-md-12 alert alert-warning mt-3 text-center" role="alert">
    {{ 'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.SEARCH_RESULTS_NOT_FOUND' | translate }}
    <button class="btn btn-raised btn-success round" (click)="create()">
      <i class="fa fa-plus"></i>
      <span class="mx-1">{{ 'SHARED.CONFIRM.ADD' | translate }}</span>
    </button>
  </div>
</ng-template>
<!-- no items template ends -->

<!-- project status logs ends -->

<!-- create projectLog modal starts -->
<ng-template #createModalRef let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
      {{ 'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.CREATE_PROJECT_STATUS_LOG' | translate }}
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="d()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-md-12">
        <form [formGroup]="createForm" (ngSubmit)="confirmCreate()">
          <div>
            <h4>{{ 'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.PROJECT_STATUS_LOG_DATA' | translate }}</h4>
          </div>
          <div class="row">
            <div class="form-group col-md-12">
              <label for="projectStatusId">
                {{ 'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.PROJECT_STATUS_LOG_TYPE' | translate }}
              </label>
              <ng-select
                class="round"
                formControlName="projectStatusId"
                labelForId="projectStatusId"
                searchable="true"
                autocomplete="off"
                [placeholder]="'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.STATUS_PLACEHOLDER' | translate"
                [notFoundText]="'SHARED.SEARCH.SEARCH_NOT_FOUND' | translate"
              >
                <ng-option *ngFor="let projectStatus of projectStatuses" [value]="projectStatus?.id">
                  {{ projectStatus?.name | localize: projectStatus?.nameEn | async }}
                </ng-option>
              </ng-select>
            </div>
            <div class="form-group col-md-12">
              <label for="transactionDate">{{ 'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.DATE' | translate }}</label>
              <div class="input-group round">
                <input
                  class="form-control"
                  id="transactionDate"
                  name="transactionDate"
                  formControlName="transactionDate"
                  [placeholder]="'SHARED.SEARCH.DATE_PLACEHOLDER' | translate"
                  ngbDatepicker
                  #transactionDate="ngbDatepicker"
                />
                <div class="input-group-append">
                  <span
                    class="input-group-text gradient-light-blue-indigo"
                    style="cursor: pointer;"
                    (click)="transactionDate.toggle()"
                  >
                    <i class="fa fa-calendar"></i>
                  </span>
                </div>
              </div>
            </div>
            <div class="form-group col-md-12">
              <label for="notes">{{ 'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.NOTES' | translate }}</label>
              <textarea
                id="notes"
                class="form-control round"
                [placeholder]="'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.NOTES_PLACEHOLDER' | translate"
                maxlength="200"
                rows="1"
                formControlName="notes"
              ></textarea>
            </div>
            <div class="form-group col-md-12">
              <label for="photo"> {{ 'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.ATTACHMENTS' | translate }}</label>
              <app-files-upload formArrayName="attachments" [filesForm]="attachmentsForm"></app-files-upload>
            </div>
            <!-- A hidden submit button to allow user submit form with Enter -->
            <button type="submit" hidden></button>
          </div>
        </form>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="modal-actions">
      <button
        type="button"
        class="btn btn-raised btn-success round"
        [disabled]="isProjectLogCreating$ | async"
        (click)="confirmCreate()"
        ngbAutofocus
      >
        <i class="fa fa-check"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.SAVE' | translate }}</span>
      </button>
      <button type="button" class="btn btn-outline-dark round" (click)="c()">
        <i class="fa fa-times"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.CANCEL' | translate }}</span>
      </button>
    </div>
  </div>
</ng-template>
<!-- create projectLog modal ends -->

<!-- view project status logs attachments modal starts -->
<ng-template #viewAttachmentsModalRef let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title text-primary" id="modal-basic-title">
      {{ 'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.ATTACHMENTS_LOGS' | translate }}
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="d()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-md-12">
        <app-files-viewer
          *ngIf="selectedProjectStatusLog.projectStatusLogAttachments?.length; else noAttachments"
          [files]="getAttachmentsUrls(selectedProjectStatusLog.projectStatusLogAttachments)"
        ></app-files-viewer>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="modal-actions">
      <button type="button" class="btn btn-outline-dark round" (click)="c()">
        <i class="fa fa-times"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.CANCEL' | translate }}</span>
      </button>
    </div>
  </div>
</ng-template>
<!-- view project status logs attachments modal ends -->

<!-- no attachments template starts -->
<ng-template #noAttachments>
  <div class="col-md-12 alert alert-warning mt-3 text-center" role="alert">
    {{ 'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.ATTACHMENTS_LOGS_NOT_FOUND' | translate }}
  </div>
</ng-template>
<!-- no attachments template ends -->
