import { Action } from '@ngrx/store';

import { MeridiemIndicator } from 'lookups/models';
import { AppHttpResponse } from 'shared';

/**
 * The Lookups-Module store meridiem indicator action types.
 */
export enum MeridiemIndicatorActionType {
  GET_ALL_MERIDIEM_INDICATORS = '[Lookups] [MeridiemIndicator] Get All Meridiem Indicators',
  GET_ALL_MERIDIEM_INDICATORS_FAIL = '[Lookups] [MeridiemIndicator] Get All Meridiem Indicators Fail',
  GET_ALL_MERIDIEM_INDICATORS_SUCCESS = '[Lookups] [MeridiemIndicator] Get All Meridiem Indicators Success',
}

/**
 * Represents a store meridiem indicator action.
 */
export class GetAllMeridiemIndicators implements Action {
  /**
   * The type of the action.
   */
  readonly type = MeridiemIndicatorActionType.GET_ALL_MERIDIEM_INDICATORS;

  /**
   * Fires a new meridiem indicator action.
   */
  constructor() {}
}

/**
 * Represents a store meridiem indicator fail action.
 */
export class GetAllMeridiemIndicatorsFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = MeridiemIndicatorActionType.GET_ALL_MERIDIEM_INDICATORS_FAIL;

  /**
   * Fires a new meridiem indicator fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store meridiem indicator success action.
 */
export class GetAllMeridiemIndicatorsSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = MeridiemIndicatorActionType.GET_ALL_MERIDIEM_INDICATORS_SUCCESS;

  /**
   * Fires a meridiem indicator success action.
   * @param payload An object contains the list of meridiem indicators.
   */
  constructor(public payload: AppHttpResponse<MeridiemIndicator[]>) {}
}

/**
 * Lookups-module meridiem indicator action types.
 */
export type MeridiemIndicatorActions =
  | GetAllMeridiemIndicators
  | GetAllMeridiemIndicatorsFail
  | GetAllMeridiemIndicatorsSuccess;
