/**
 * The set of system supported employee deduction types.
 */
export enum EmployeeDeductionTypes {
  /**
   * The employee deduction type.
   */
  DAYS_DEDUCTION = 'DAYS_DEDUCTION',

  HOURS_DEDUCTION = 'HOURS_DEDUCTION',

  DIRECT_AMOUNT_DEDUCTION = 'DIRECT_AMOUNT_DEDUCTION',
}
