import { Injectable } from '@angular/core';

import { of } from 'rxjs';
import { switchMap, map, catchError, tap } from 'rxjs/operators';
import { Effect, ofType, Actions } from '@ngrx/effects';

import { NotificationService, StatusCode, PAGINATION, TranslationService } from 'shared';
import { PettyCashRefundRequestApprovalsService } from 'finances/services';
import {
  PettyCashRefundRequestApprovalsActionType,
  SearchPettyCashRefundRequestApprovals,
  SearchPettyCashRefundRequestApprovalsSuccess,
  SearchPettyCashRefundRequestApprovalsFail,
  FindPettyCashRefundRequestApprovalSuccess,
  FindPettyCashRefundRequestApprovalFail,
  FindPettyCashRefundRequestApproval,
  CreatePettyCashRefundRequestApproval,
  CreatePettyCashRefundRequestApprovalSuccess,
  CreatePettyCashRefundRequestApprovalFail,
} from 'finances/store/actions';

@Injectable()
export class PettyCashRefundRequestApprovalsEffects {
  constructor(
    private actions$: Actions,
    private pettyCashRefundRequestApprovalsService: PettyCashRefundRequestApprovalsService,
    private notificationService: NotificationService,
    private translationService: TranslationService
  ) {}

  /* ========================= SEARCH_PETTY_CASH_REFUND_REQUEST_APPROVALS =================================== */
  @Effect()
  search$ = this.actions$.pipe(
    ofType(PettyCashRefundRequestApprovalsActionType.SEARCH_PETTY_CASH_REFUND_REQUEST_APPROVALS),
    switchMap(({ payload }: SearchPettyCashRefundRequestApprovals) =>
      this.pettyCashRefundRequestApprovalsService
        .search(
          payload?.pettyCashRefundRequests,
          payload.fromDate,
          payload.toDate,
          payload.page,
          PAGINATION.PettyCashRefundRequests
        )
        .pipe(
          map((response) => new SearchPettyCashRefundRequestApprovalsSuccess(response)),
          catchError((error) => of(new SearchPettyCashRefundRequestApprovalsFail(error)))
        )
    )
  );

  /* ========================= FIND_PETTY_CASH_REFUND_REQUEST_APPROVAL =================================== */
  @Effect()
  find$ = this.actions$.pipe(
    ofType(PettyCashRefundRequestApprovalsActionType.FIND_PETTY_CASH_REFUND_REQUEST_APPROVAL),
    switchMap((action: FindPettyCashRefundRequestApproval) =>
      this.pettyCashRefundRequestApprovalsService.findById(action.payload).pipe(
        map((response) => new FindPettyCashRefundRequestApprovalSuccess(response)),
        catchError((error) => of(new FindPettyCashRefundRequestApprovalFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  findFail$ = this.actions$.pipe(
    ofType(PettyCashRefundRequestApprovalsActionType.FIND_PETTY_CASH_REFUND_REQUEST_APPROVAL_FAIL),
    tap((action: FindPettyCashRefundRequestApprovalFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate(
            'FINANCES.PETTY_CASH_REFUND_REQUEST_APPROVALS.PETTY_CASH_REFUND_REQUEST_APPROVAL_NOT_FOUND'
          )
        );
      }
    })
  );

  /* ========================= CREATE_PETTY_CASH_REFUND_REQUEST_APPROVAL =================================== */
  @Effect()
  create$ = this.actions$.pipe(
    ofType(PettyCashRefundRequestApprovalsActionType.CREATE_PETTY_CASH_REFUND_REQUEST_APPROVAL),
    switchMap((action: CreatePettyCashRefundRequestApproval) =>
      this.pettyCashRefundRequestApprovalsService.create(action.payload).pipe(
        map((response) => new CreatePettyCashRefundRequestApprovalSuccess(response)),
        catchError((error) => of(new CreatePettyCashRefundRequestApprovalFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  createSuccess$ = this.actions$.pipe(
    ofType(PettyCashRefundRequestApprovalsActionType.CREATE_PETTY_CASH_REFUND_REQUEST_APPROVAL_SUCCESS),
    tap((action: CreatePettyCashRefundRequestApprovalSuccess) => {
      this.notificationService.success(
        this.translationService.translate(
          'FINANCES.PETTY_CASH_REFUND_REQUEST_APPROVALS.PETTY_CASH_REFUND_REQUEST_APPROVAL_ADDED',
          {
            pettyCashRefundRequestApprovalNumber: action.payload.data.id,
          }
        )
      );
    })
  );

  @Effect({ dispatch: false })
  createFail$ = this.actions$.pipe(
    ofType(PettyCashRefundRequestApprovalsActionType.CREATE_PETTY_CASH_REFUND_REQUEST_APPROVAL_FAIL),
    tap((action: CreatePettyCashRefundRequestApprovalFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );
}
