import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';

import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';

import * as fromSecurityStore from 'security/store';
import { NotificationService, PaginationInfo, TranslationService } from 'shared';
import { Claims, User } from 'security/models';

@Component({
  selector: 'app-organization-users-search',
  templateUrl: './organization-users-search.component.html',
  styles: [],
})
export class OrganizationUsersSearchComponent implements OnInit {
  /**
   * Indicates whether the modal will automatically close after select the first product.
   *
   * Be default it will not be closed.
   */
  @Input() closeOnSelect = false;

  /**
   * Indicates whether the products displayed on the list should be all active.
   *
   * Be default it will not be closed.
   */
  @Input() onlyActive = false;

  /**
   * Sets the visibility of this component modal.
   *
   * Handles visibility change in two-data-binding approach.
   */
  @Input() set visible(isShown: boolean) {
    if (isShown && this.modalRef) {
      this.openModal(this.modalRef);
    } else {
      this.closeModal();
    }

    this.visibleChange.emit(isShown);
  }

  /**
   * Outputs a value that indicates whether the modal is shown or not.
   *
   * Handles visibility change in two-data-binding approach.
   */
  @Output() visibleChange = new EventEmitter<boolean>();

  /** Outputs the selected organization users. */
  @Output() organizationUsers = new EventEmitter<User[]>();

  /**
   * The modal template reference.
   */
  @ViewChild('modalRef') modalRef: ElementRef<any>;

  /**
   * Indicates whether there is a search process is running or not.
   */
  isSearching$: Observable<boolean>;

  /**
   * The list of organization users.
   */
  organizationUsers$: Observable<User[]>;

  /**
   * The list of organization users.
   */
  organizationUsersList: User[];

  /**
   * The pagination info.
   */
  paginationInfo$: Observable<PaginationInfo>;

  /**
   * The search form.
   */
  searchForm: FormGroup;

  /**
   * The system supported user claims.
   */
  Claims = Claims;

  /**
   * The ngb-modal reference to use it when modal needed to be closed.
   */
  modal: NgbModalRef;

  /**
   * @param modalService The modal service.
   * @param notificationService The notification service.
   * @param translationService The translation service.
   * @param securityStore$ Security-module store.
   */
  constructor(
    private modalService: NgbModal,
    private notificationService: NotificationService,
    private translationService: TranslationService,
    private securityStore$: Store<fromSecurityStore.SecurityState>
  ) {}

  ngOnInit(): void {
    this.init();
  }

  /**
   * Initialize component data.
   */
  init() {
    this.initForm();

    /**
     * Loads data.
     */
    this.isSearching$ = this.securityStore$.pipe(select(fromSecurityStore.getUsersSearching));

    let isManualSearchTriggeredBeforeOrganizationUsers = false;
    this.organizationUsers$ = this.securityStore$.pipe(
      select(fromSecurityStore.getUsers),
      tap((organizationUsers) => {
        if (organizationUsers) {
          this.organizationUsersList = organizationUsers;
        }
        if (!isManualSearchTriggeredBeforeOrganizationUsers && !organizationUsers.length) {
          isManualSearchTriggeredBeforeOrganizationUsers = true;
          this.search();
        }
      })
    );
    this.paginationInfo$ = this.securityStore$.pipe(select(fromSecurityStore.getUsersPaginationInfo));
  }

  /**
   * Initialize form and add validators.
   */
  initForm() {
    this.searchForm = new FormGroup({
      name: new FormControl(''),
      page: new FormControl(1),
    });
  }

  /**
   * Handles search parameters change.
   */
  search(event?: KeyboardEvent) {
    if (event && event.key === 'Enter') {
      event.preventDefault();
      return;
    }
    this.securityStore$.dispatch(
      new fromSecurityStore.GetOrganizationUsers({
        name: this.searchForm.get('name').value,
        page: 1,
      })
    );
  }

  /**
   * Handles pagination page-changed event.
   * @param page The current selected page number.
   */
  pageChanged(page: number) {
    /** Update pagination page. */
    this.searchForm.patchValue({ page });

    /** Get selected page items. */
    this.securityStore$.dispatch(
      new fromSecurityStore.GetOrganizationUsers({
        name: this.searchForm.get('name').value,
        page: this.searchForm.get('page').value,
      })
    );
  }

  /**
   * Enables trackBy feature for *ngFor utility to track items
   * depending on a comparer to reduce DOM change.
   * If the item is already exist then no HTML will change, only bounded values.
   * @param index The index of the item.
   * @param user The item to determine if it was changed or not.
   */
  trackItems(index: number, user: User) {
    return user ? user.id : undefined;
  }

  /**
   * Handles organization user selected event.
   * @param organizationUsers The list of organization users to notify selected.
   */
  selectUser(organizationUsers: User[]) {
    this.notificationService.successInstant(
      this.translationService.translate('SECURITY.USERS.NOTIFICATION_MESSAGE.USER_SELECTED')
    );
    this.organizationUsers.emit(organizationUsers);
    if (this.closeOnSelect) {
      this.closeModal();
    }
  }

  /**
   * Opens the modal of the given templateRef.
   * @param modalRef The modal templateRef to be opened.
   */
  openModal(modalRef) {
    this.modal = this.modalService.open(modalRef, { size: 'lg' });
    this.modal.result.then(null, () => this.closeModal());
  }

  /**
   * Closes the currently opened modal.
   */
  closeModal() {
    this.modal?.dismiss();
    this.organizationUsers.emit([]);
    this.visibleChange.emit(false);
  }
}
