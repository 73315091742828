import { Action } from '@ngrx/store';

import { PersonTitle } from 'lookups/models';
import { AppHttpResponse } from 'shared';

/**
 * The Lookups-Module store person title action types.
 */
export enum PersonTitleActionType {
  GET_ALL_PERSON_TITLES = '[Lookups] [PersonTitle] Get All Person Titles',
  GET_ALL_PERSON_TITLES_FAIL = '[Lookups] [PersonTitle] Get All Person Titles Fail',
  GET_ALL_PERSON_TITLES_SUCCESS = '[Lookups] [PersonTitle] Get All Person Titles Success',
}

/**
 * Represents a store person title action.
 */
export class GetAllPersonTitles implements Action {
  /**
   * The type of the action.
   */
  readonly type = PersonTitleActionType.GET_ALL_PERSON_TITLES;

  /**
   * Fires a new person title action.
   */
  constructor() {}
}

/**
 * Represents a store person title fail action.
 */
export class GetAllPersonTitlesFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = PersonTitleActionType.GET_ALL_PERSON_TITLES_FAIL;

  /**
   * Fires a new person title fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store person title success action.
 */
export class GetAllPersonTitlesSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = PersonTitleActionType.GET_ALL_PERSON_TITLES_SUCCESS;

  /**
   * Fires a person title success action.
   * @param payload An object contains the list of person titles.
   */
  constructor(public payload: AppHttpResponse<PersonTitle[]>) {}
}

/**
 * Lookups-module person title action types.
 */
export type PersonTitleActions = GetAllPersonTitles | GetAllPersonTitlesFail | GetAllPersonTitlesSuccess;
