import { createSelector } from '@ngrx/store';

import { BanksState, FinancesState } from 'finances/store/states';
import { selectFinancesState } from 'finances/store/selectors/feature.selectors';

/**
 * Gets the banks state.
 */
const selectBankState = createSelector(selectFinancesState, (state: FinancesState) => state.banks);

/**
 * Gets the list of loaded banks.
 */
export const getBanks = createSelector(selectBankState, (state: BanksState) => state.data);

/**
 * Gets the banks pagination info.
 */
export const getBanksPaginationInfo = createSelector(selectBankState, (state: BanksState) => state.paginationInfo);

/**
 * Gets the error that occurred in the state.
 */
export const getBanksError = createSelector(selectBankState, (state: BanksState) => state.error);

/**
 * Determines if there is a running search process.
 */
export const getBanksSearching = createSelector(selectBankState, (state: BanksState) => state.isSearching);

/**
 * Determines if the last search process has been ended successfully.
 */
export const getBanksSearchCompleted = createSelector(selectBankState, (state: BanksState) => state.isSearchCompleted);

/**
 * Gets the selected bank.
 */
export const getSelectedBank = createSelector(selectBankState, (state: BanksState) => state.selectedBank);

/**
 * Determines if there is a running find bank process.
 */
export const getBanksFinding = createSelector(selectBankState, (state: BanksState) => state.isFinding);

/**
 * Determines if the last find bank process has been ended successfully.
 */
export const getBanksFindCompleted = createSelector(selectBankState, (state: BanksState) => state.isFindCompleted);

/**
 * Determines if there is a running create process.
 */
export const getSelectedBankCreating = createSelector(selectBankState, (state: BanksState) => state.isCreating);

/**
 * Determines if the last create process has been ended successfully.
 */
export const getSelectedBankCreateCompleted = createSelector(
  selectBankState,
  (state: BanksState) => state.isCreateCompleted
);

/**
 * Determines if there is a running update process.
 */
export const getSelectedBankUpdating = createSelector(selectBankState, (state: BanksState) => state.isUpdating);

/**
 * Determines if the last update process has been ended successfully.
 */
export const getSelectedBankUpdateCompleted = createSelector(
  selectBankState,
  (state: BanksState) => state.isUpdateCompleted
);

/**
 * Determines if there is a running delete process.
 */
export const getSelectedBankDeleting = createSelector(selectBankState, (state: BanksState) => state.isDeleting);

/**
 * Determines if the last delete process has been ended successfully.
 */
export const getSelectedBankDeleteCompleted = createSelector(
  selectBankState,
  (state: BanksState) => state.isDeleteCompleted
);
