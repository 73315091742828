import { CustomersService } from 'app-search/services/customers.service';
import { UsersService } from 'app-search/services/users.service';
import { VendorsService } from 'app-search/services/vendors.service';
import { PosDevicesService } from 'app-search/services/pos-devices.service';
import { EmployeesService } from 'app-search/services/employees.service';
import { DepartmentsService } from 'app-search/services/departments.service';
import { LocationsService } from 'app-search/services/locations.service';
import { BankAccountsService } from 'app-search/services/bank-accounts.service';
import { BanksService } from 'app-search/services/banks.service';
import { SubTasksService } from 'app-search/services/sub-tasks.service';
import { ProjectsService } from 'app-search/services/projects.service';
import { GovServicesService } from 'app-search/services/gov-services.service';
import { RegionsService } from 'app-search/services/regions.service';
import { SectorsService } from 'app-search/services/sectors.service';

export * from 'app-search/services/customers.service';
export * from 'app-search/services/users.service';
export * from 'app-search/services/vendors.service';
export * from 'app-search/services/pos-devices.service';
export * from 'app-search/services/employees.service';
export * from 'app-search/services/departments.service';
export * from 'app-search/services/locations.service';
export * from 'app-search/services/bank-accounts.service';
export * from 'app-search/services/banks.service';
export * from 'app-search/services/sub-tasks.service';
export * from 'app-search/services/projects.service';
export * from 'app-search/services/gov-services.service';
export * from 'app-search/services/regions.service';
export * from 'app-search/services/sectors.service';

/**
 * The set of services registered on this module.
 */
export const SERVICES: any[] = [
  CustomersService,
  UsersService,
  VendorsService,
  PosDevicesService,
  EmployeesService,
  DepartmentsService,
  LocationsService,
  BankAccountsService,
  BanksService,
  SubTasksService,
  ProjectsService,
  GovServicesService,
  RegionsService,
  SectorsService,
];
