import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';

import { AppHttpResponse } from 'shared';
import { SaleInvoice } from 'sales/models';
import { UpdatePosInvoiceInput } from 'sales/models/invoices/update-pos-invoice.model';

/**
 * The pending pos invoices services includes functionality to prepare and update a pending pos invoices.
 */
@Injectable()
export class PendingPosInvoicesService {
  /**
   * The relative route for the pending pos invoices.
   * No leading or trailing slashes required.
   */
  private pendingPosInvoicesApi = 'sales/invoices';

  constructor(private http: HttpClient) {}

  /**
   * Preparing in the sale invoices by pos invoices.
   * @param locations The locations of the sales invoice.
   * @param classes The array of the  ids of product classes.
   */
  public PreparePosInvoices(locations: number[], productClasses: number[]): Observable<AppHttpResponse<SaleInvoice[]>> {
    const params = new HttpParams()
      .set('locations', locations.join(','))
      .set('productClasses', productClasses.join(','));
    return this.http.get<AppHttpResponse<SaleInvoice[]>>(
      `${this.pendingPosInvoicesApi}/pending-pos-invoices-products`,
      {
        params,
      }
    );
  }

  /**
   * Updates the pending point of sale invoice with the given id.
   * @param id The id of the pending point of sale invoice.
   */
  public updatePendingPosInvoice(data: UpdatePosInvoiceInput): Observable<AppHttpResponse<SaleInvoice>> {
    return this.http.put<AppHttpResponse<SaleInvoice>>(`${this.pendingPosInvoicesApi}/update-pos-products-stage`, data);
  }
}
