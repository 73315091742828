import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';

import { AppHttpResponse } from 'shared';
import { CreateUnitOfMeasureInput, UnitOfMeasure, UpdateUnitOfMeasureInput } from 'stores/models';

/**
 * The unit of measure services includes functionality to create, search, findById, update and delete for a unit of measure class.
 */
@Injectable()
export class UnitsOfMeasureService {
  /**
   * The relative route for the unit of measure .
   *
   * No leading or trailing slashes required.
   */
  private unitOfMeasureApi = 'stores/units-of-measure';

  constructor(private http: HttpClient) {}

  /**
   * Creates a new unit of measure class from the provided data.
   * @param data The new unit of measure data.
   */
  public create(data: CreateUnitOfMeasureInput): Observable<AppHttpResponse<UnitOfMeasure>> {
    return this.http.post<AppHttpResponse<UnitOfMeasure>>(`${this.unitOfMeasureApi}`, data);
  }

  /**
   * Searches in the unit of measure class by fromDate and toDate.
   * @param name of the unit of measure class.
   * @param page The current pagination page number.
   * @param pageSize The maximum number of unit of measure allowed per one pagination page.
   */
  public search(
    name: string,
    unitOfMeasureClasses: number[],
    page: number,
    pageSize: number
  ): Observable<AppHttpResponse<UnitOfMeasure[]>> {
    const params = new HttpParams()
      .set('name', name)
      .set('unitOfMeasureClasses', unitOfMeasureClasses.join(','))
      .set('page', page.toString())
      .set('pageSize', pageSize.toString());
    return this.http.get<AppHttpResponse<UnitOfMeasure[]>>(`${this.unitOfMeasureApi}`, { params });
  }

  /**
   * Get all the units of measure.
   */
  public getAll(): Observable<AppHttpResponse<UnitOfMeasure[]>> {
    return this.http.get<AppHttpResponse<UnitOfMeasure[]>>(`${this.unitOfMeasureApi}/all-units-of-measure`);
  }

  /**
   * Finds the unit of measure with the given id.
   * @param id The id of the unit of measure.
   */
  public findById(id: number): Observable<AppHttpResponse<UnitOfMeasure>> {
    return this.http.get<AppHttpResponse<UnitOfMeasure>>(`${this.unitOfMeasureApi}/${id}`);
  }

  /**
   * Updates an existing unit of measure data using the provided data.
   * @param data The updated unit of measure data.
   */
  public update(data: UpdateUnitOfMeasureInput): Observable<AppHttpResponse<UnitOfMeasure>> {
    return this.http.put<AppHttpResponse<UnitOfMeasure>>(`${this.unitOfMeasureApi}`, data);
  }

  /**
   * Deletes the unit of measure class by given id.
   * @param id The id of the unit of measure class.
   */
  public delete(id: number): Observable<AppHttpResponse<UnitOfMeasure>> {
    return this.http.delete<AppHttpResponse<UnitOfMeasure>>(`${this.unitOfMeasureApi}/${id}`);
  }
}
