import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

import { Observable } from 'rxjs';
import { Store, select } from '@ngrx/store';

import * as fromCoreStore from 'core/store';
import * as fromAuthStore from 'auth/store';
import * as fromLookupsStore from 'lookups/store';
import { NotificationService, TranslationService } from 'shared';
import { WebsocketService } from 'websocket';
import { Language } from 'lookups/models';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  /**
   * Indicates whether there is a login-user process is running or not.
   */
  isLogin$: Observable<boolean>;

  /**
   * The list of languages
   */
  languages$: Observable<Language[]>;

  /**
   * The login form.
   */
  form: FormGroup;

  /**
   * Gets or sets the current selected language.
   */
  get currentLang() {
    return this.translationService.language;
  }

  /**
   * Gets or sets the ngb-dropdown position based on the current user display language.
   */
  get ngbDropdownPosition(): string {
    return this.currentLang === 'ar' ? 'bottom-right' : 'bottom-left';
  }

  /**
   * @param route The angular activated route.
   * @param coreStore$ The core-Module store.
   * @param authStore$ The auth-Module store.
   * @param lookupsStore$ The lookups-store module.
   * @param notificationService The notification service.
   * @param translationService The translation service.
   */
  constructor(
    private route: ActivatedRoute,
    private coreStore$: Store<fromCoreStore.AppState>,
    private authStore$: Store<fromAuthStore.AuthState>,
    private lookupsStore$: Store<fromLookupsStore.LookupsState>,
    private notificationService: NotificationService,
    private translationService: TranslationService,
    private websocketService: WebsocketService
  ) {}

  ngOnInit() {
    if (!this.route.snapshot.queryParamMap.has('reloaded')) {
      window.location.replace('/login?reloaded=true');
    }

    this.init();
  }

  /** Initialize component data. */
  init() {
    /* Set the current page info {title, icon} */
    this.coreStore$.dispatch(new fromCoreStore.ChangePageInfo({ title: 'تسجيل الدخول' }));

    /**
     * Disconnect from the websocket server.
     */
    this.websocketService.disconnect();

    this.initForm();

    /**
     * Set data.
     */
    this.isLogin$ = this.authStore$.pipe(select(fromAuthStore.getSessionLogging));
    this.languages$ = this.lookupsStore$.pipe(select(fromLookupsStore.getLanguages));
  }

  /** Initialize form and add validators. */
  initForm() {
    this.form = new FormGroup({
      username: new FormControl('', [Validators.required, Validators.minLength(1)]),
      password: new FormControl('', [Validators.required, Validators.minLength(1)]),
      rememberUser: new FormControl(false),
    });
  }

  /** Handles login form submit. */
  submit() {
    if (this.form.invalid) {
      return this.notificationService.warning(
        this.translationService.translate('AUTH.NOTIFICATION_MESSAGE.WRITE_PASSWORD')
      );
    }
    this.authStore$.dispatch(new fromAuthStore.Login(this.form.value));
  }

  /**
   * Sets the default language.
   */
  changeLanguage(lang: string) {
    this.translationService.setLanguage(lang);
  }
}
