import { Injectable } from '@angular/core';

import { of } from 'rxjs';
import { switchMap, map, catchError } from 'rxjs/operators';
import { Effect, ofType, Actions } from '@ngrx/effects';

import { LookupsService } from 'lookups/services';
import { NationalityActionType, GetAllNationalityFail, GetAllNationalitySuccess } from 'lookups/store/actions';

@Injectable()
export class NationalityEffects {
  constructor(private actions$: Actions, private lookupsService: LookupsService) {}

  /* ========================= GET_ALL_NATIONALITY =================================== */
  @Effect()
  getAllNationalities$ = this.actions$.pipe(
    ofType(NationalityActionType.GET_ALL_NATIONALITY),
    switchMap(() =>
      this.lookupsService.getAllNationalities().pipe(
        map((response) => new GetAllNationalitySuccess(response)),
        catchError((error) => of(new GetAllNationalityFail(error)))
      )
    )
  );
}
