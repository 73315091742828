import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';

import { Observable, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { PaginationInfo } from 'shared';
import * as fromSalesStore from 'sales/store';
import * as fromAuthStore from 'auth/store';
import { PosPrinterAdjustment, PosDevice, PosPrinter } from 'sales/models';
import { Claims } from 'security/models';
import { Location } from 'stores/models';

@Component({
  selector: 'app-casher-printers-list',
  templateUrl: './casher-printers-list.component.html',
  styles: [],
})
export class CasherPrintersListComponent implements OnInit, OnDestroy {
  /**
   * The system supported user claims.
   */
  claims = Claims;

  /**
   * Indicates whether there is a delete-pos-printer process is running or not.
   */
  isDeleting$: Observable<boolean>;

  /**
   * The pagination info.
   */
  paginationInfo$: Observable<PaginationInfo>;

  /**
   * Indicates whether there is a search process is running or not.
   */
  isSearching$: Observable<boolean>;

  /**
   * The list of pos printer adjustments.
   */
  posPrinterAdjustments$: Observable<PosPrinterAdjustment[]>;

  /**
   * Shows or hide the pos printers list.
   */
  posPrintersListVisibility = false;

  /**
   * The list of selected pos printer.
   */
  posPrinters: PosPrinter[] = [];

  /**
   * Shows or hide the pos devices list.
   */
  posDevicesListVisibility = false;

  /**
   * The list of selected pos device.
   */
  posDevices: PosDevice[] = [];

  /**
   * Shows or hide the pos devices list.
   */
  locationsListVisibility = false;

  /**
   * The list of selected pos device.
   */
  locations: Location[] = [];

  /**
   * The search form.
   */
  searchForm: FormGroup;

  /**
   * The set of subscriptions on this components,
   * these subscriptions must be unsubscribed before this component got destroyed.
   */
  subscriptions = new Subscription();

  /**
   * Gets or sets the currently selected pos printer adjustment for delete.
   */
  selectedPosPrinterAdjustment: PosPrinterAdjustment;

  /**
   * @param modalService The modal service.
   * @param salesStore$ The stores store.
   * @param authStore$ the auth-store module.
   */
  constructor(
    private modalService: NgbModal,
    private salesStore$: Store<fromSalesStore.SalesState>,
    private authStore$: Store<fromAuthStore.AuthState>
  ) {}
  ngOnInit(): void {
    this.init();
  }

  /**
   * Destroy component data
   */
  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  /**
   * Initialize component data.
   */
  init() {
    this.initForm();

    /**
     * Load data.
     */
    this.isDeleting$ = this.salesStore$.pipe(select(fromSalesStore.getSelectedCasherPrinterDeleting));
    this.isSearching$ = this.salesStore$.pipe(select(fromSalesStore.getCasherPrintersSearching));

    let isManualSearchTriggeredBeforeForCasherPrinters = false;
    this.posPrinterAdjustments$ = this.salesStore$.pipe(
      select(fromSalesStore.getCasherPrinters),
      tap((casherPrinters) => {
        if (!isManualSearchTriggeredBeforeForCasherPrinters && !casherPrinters.length) {
          isManualSearchTriggeredBeforeForCasherPrinters = true;
          this.search();
        }
      })
    );

    /* Subscribe to selected location to select it whenever it changed. */
    this.subscriptions.add(
      this.authStore$
        .pipe(
          select(fromAuthStore.getSessionUserDefaultLocation),
          tap((location) => {
            if (location && !this.searchForm.value.locations.includes(location.id)) {
              this.selectLocations([location]);
            }
          })
        )
        .subscribe()
    );

    this.paginationInfo$ = this.salesStore$.pipe(select(fromSalesStore.getCasherPrintersPaginationInfo));
  }

  /**
   * Initialize form and add validators.
   */
  initForm() {
    this.searchForm = new FormGroup({
      posPrinters: new FormControl([]),
      posDevices: new FormControl([]),
      locations: new FormControl([]),
      page: new FormControl(1),
    });
  }

  /**
   * Handles search parameters change.
   */
  search(event?: KeyboardEvent) {
    if (event && event.key === 'Enter') {
      event.preventDefault();
      return;
    }

    this.applyFiltersAndSearch();
  }

  /**
   * Handles pagination page-changed event.
   * @param page The current selected page number.
   */
  pageChanged(page: number) {
    /** Update pagination page. */
    this.searchForm.patchValue({ page });

    this.applyFiltersAndSearch(page);
  }

  /**
   * Enables trackBy feature for *ngFor utility to track elements
   * depending on a comparer to reduce DOM change.
   * If the element is already exist then no HTML will change, only bounded values.
   * @param element The element to determine if it was changed or not.
   */
  trackItems(posPrinterAdjustment: PosPrinterAdjustment) {
    return posPrinterAdjustment ? posPrinterAdjustment.id : undefined;
  }

  applyFiltersAndSearch(page: number = 1) {
    this.salesStore$.dispatch(
      new fromSalesStore.SearchCasherPrinters({
        posPrinters: this.searchForm.get('posPrinters').value,
        posDevices: this.searchForm.get('posDevices').value,
        locations: this.searchForm.get('locations').value,
        page,
      })
    );
  }

  /**
   * Deletes the casher printer with the given id.
   * @param posPrinterId The id of the pos printer to be deleted.
   */
  delete(casherPrinterId: number) {
    this.salesStore$.dispatch(new fromSalesStore.DeleteCasherPrinter(casherPrinterId));
  }

  /**
   * Opens the modal of the given templateRef.
   * @param modalRef The modal templateRef to be opened.
   */
  openModal(modalRef) {
    this.modalService.open(modalRef);
  }

  /**
   * Adds the newly selected pos printers search list.
   * @param pos printers The list of newly selected pos printers to be added.
   */
  selectPosPrinters(posPrinters: PosPrinter[]) {
    const selectedIds: number[] = this.searchForm.get('posPrinters').value;
    this.posPrinters = [
      ...this.posPrinters.filter((posPrinter) => selectedIds.includes(posPrinter.id)),
      ...posPrinters,
    ];
    this.searchForm.patchValue({
      posPrinters: this.posPrinters.map((posPrinter) => posPrinter.id),
    });
  }

  /**
   * Adds the newly selected pos devices search list.
   * @param pos devices The list of newly selected pos devices to be added.
   */
  selectPosDevice(posDevices: PosDevice[]) {
    const selectedIds: number[] = this.searchForm.get('posDevices').value;
    this.posDevices = [...this.posDevices.filter((posDevice) => selectedIds.includes(posDevice.id)), ...posDevices];
    this.searchForm.patchValue({
      posDevices: this.posDevices.map((posDevice) => posDevice.id),
    });
  }

  /**
   * Adds the newly selected locations search list.
   * @param locations The list of newly selected locations to be added.
   */
  selectLocations(locations: Location[]) {
    const selectedIds: number[] = this.searchForm.get('locations').value;
    this.locations = [...this.locations.filter((location) => selectedIds.includes(location.id)), ...locations];
    this.searchForm.patchValue({
      locations: this.locations.map((location) => location.id),
    });
  }
}
