import { createSelector } from '@ngrx/store';

import { selectLookupsState } from 'lookups/store/selectors/feature.selectors';
import { LookupsState, SickLeaveTypeState } from 'lookups/store/states';

/**
 * Gets the bank account log type state.
 */
const selectSickLeaveTypes = createSelector(selectLookupsState, (state: LookupsState) => state.sickLeaveTypes);

/**
 * Gets the list of loaded bank account log types.
 */
export const getSickLeaveTypes = createSelector(selectSickLeaveTypes, (state: SickLeaveTypeState) => state.data);

/**
 * Gets the error that occurred in the state.
 */
export const getSickLeaveTypesError = createSelector(selectSickLeaveTypes, (state: SickLeaveTypeState) => state.error);

/**
 * Determines if there is a running load process.
 */
export const getSickLeaveTypesIsLoading = createSelector(
  selectSickLeaveTypes,
  (state: SickLeaveTypeState) => state.isLoading
);

/**
 * Determines if the last load process has been ended successfully.
 */
export const getSickLeaveTypesIsLoaded = createSelector(
  selectSickLeaveTypes,
  (state: SickLeaveTypeState) => state.isLoaded
);
