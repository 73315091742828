import { createSelector } from '@ngrx/store';

import { ReportsState, UserReportsState } from 'reports/store/states';
import { selectReportsState } from 'reports/store/selectors/feature.selectors';

/* Selectors */

/**
 * Gets the user reports state.
 */
const selectUserReportsState = createSelector(selectReportsState, (state: ReportsState) => state.userReports);

/**
 * Gets the loaded reports.
 */
export const getUserReports = createSelector(selectUserReportsState, (state: UserReportsState) => state.data);

/**
 * Gets the error that occurred in the state.
 */
export const getUserReportsError = createSelector(selectUserReportsState, (state: UserReportsState) => state.error);

/**
 * Determines if there is a running load process.
 */
export const getUserReportsLoading = createSelector(
  selectUserReportsState,
  (state: UserReportsState) => state.isLoading
);

/**
 * Determines if the last load process has been ended successfully.
 */
export const getUserReportsLoadCompleted = createSelector(
  selectUserReportsState,
  (state: UserReportsState) => state.isLoadCompleted
);

/**
 * Determines if there is a running delete process.
 */
export const getUserReportDeleting = createSelector(
  selectUserReportsState,
  (state: UserReportsState) => state.isDeleting
);

/**
 * Determines if the last delete process has been ended successfully.
 */
export const getUserReportDeleteCompleted = createSelector(
  selectUserReportsState,
  (state: UserReportsState) => state.isDeleteCompleted
);
