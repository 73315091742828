import { Action } from '@ngrx/store';

import { IdentityType } from 'lookups/models';
import { AppHttpResponse } from 'shared';

/**
 * The Lookups-Module store  identity type action types.
 */
export enum IdentityTypeActionType {
  GET_ALL_IDENTITY_TYPE = '[Lookups] [IdentityType] Get All Identity Types',
  GET_ALL_IDENTITY_TYPE_FAIL = '[Lookups] [IdentityType] Get All Identity Types Fail',
  GET_ALL_IDENTITY_TYPE_SUCCESS = '[Lookups] [IdentityType] Get All Identity Types Success',
}

/**
 * Represents a store  identity type action.
 */
export class GetAllIdentityType implements Action {
  /**
   * The type of the action.
   */
  readonly type = IdentityTypeActionType.GET_ALL_IDENTITY_TYPE;

  /**
   * Fires a new  identity type action.
   */
  constructor() {}
}

/**
 * Represents a store  identity type fail action.
 */
export class GetAllIdentityTypeFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = IdentityTypeActionType.GET_ALL_IDENTITY_TYPE_FAIL;

  /**
   * Fires a new  identity type fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store  identity type success action.
 */
export class GetAllIdentityTypeSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = IdentityTypeActionType.GET_ALL_IDENTITY_TYPE_SUCCESS;

  /**
   * Fires a  identity type success action.
   * @param payload An object contains the list of  identity types.
   */
  constructor(public payload: AppHttpResponse<IdentityType[]>) {}
}

/**
 * Lookups-module  identity type action types.
 */
export type IdentityTypeActions = GetAllIdentityType | GetAllIdentityTypeFail | GetAllIdentityTypeSuccess;
