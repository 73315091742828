import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';

import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

import { NotificationService, PaginationInfo, TranslationService } from 'shared';
import * as fromFinancesStore from 'finances/store';
import { Account } from 'finances/models';

/**
 * A accounts search form wrapped in modal.
 */
@Component({
  selector: 'app-accounts-search',
  templateUrl: './accounts-search.component.html',
  styles: [],
})
export class AccountsSearchComponent implements OnInit {
  /**
   * Indicates whether the modal will automatically close after select the first account.
   *
   * Be default it will not be closed.
   */
  @Input() closeOnSelect = false;

  /**
   * Indicates whether the accounts displayed on the list should be all active.
   *
   * Be default it will not be closed.
   */
  @Input() onlyActive = false;

  /**
   * Indicates whether the accounts displayed on the list parent or nor.
   */
  @Input() onlyNonParent = false;

  /**
   * Sets a value that restricts the search for accounts to a specific primary account by its key.
   */
  @Input() restrictedPrimaryAccountKey = '';

  /**
   * Sets the visibility of this component modal.
   *
   * Handles visibility change in two-data-binding approach.
   */
  @Input() set visible(isShown: boolean) {
    if (isShown && this.modalRef) {
      this.openModal(this.modalRef);
    } else {
      this.closeModal();
    }

    this.visibleChange.emit(isShown);
  }

  /**
   * Outputs a value that indicates whether the modal is shown or not.
   *
   * Handles visibility change in two-data-binding approach.
   */
  @Output() visibleChange = new EventEmitter<boolean>();

  /** Outputs the selected accounts. */
  @Output() accounts = new EventEmitter<Account[]>();

  /**
   * The modal template reference.
   */
  @ViewChild('modalRef') modalRef: ElementRef<any>;

  /**
   * The list of accounts.
   */
  accounts$: Observable<Account[]>;

  /**
   * The list of accounts.
   */
  accountsList: Account[];

  /**
   * The pagination info.
   */
  paginationInfo$: Observable<PaginationInfo>;

  /**
   * The search form.
   */
  searchForm: FormGroup;

  /**
   * The ngb-modal reference to use it when modal needed to be closed.
   */
  modal: NgbModalRef;

  /**
   *
   * @param modalService The modal service.
   * @param notificationService The notification service.
   * @param financesStore$ The finances store.
   * @param translationService The translation service.
   */
  constructor(
    private modalService: NgbModal,
    private notificationService: NotificationService,
    private financesStore$: Store<fromFinancesStore.FinancesState>,
    private translationService: TranslationService
  ) {}

  ngOnInit(): void {
    this.init();
  }

  /**
   * Initialize component data.
   */
  init() {
    this.initForm();

    /**
     * Load data.
     */
    let isManualSearchTriggeredBeforeForAccounts = false;
    this.accounts$ = this.financesStore$.pipe(
      select(fromFinancesStore.getAccounts),
      tap((accounts) => {
        if (accounts) {
          this.accountsList = accounts;
        }
        if (!isManualSearchTriggeredBeforeForAccounts && !accounts.length) {
          isManualSearchTriggeredBeforeForAccounts = true;
          this.search();
        }
      })
    );

    this.paginationInfo$ = this.financesStore$.pipe(select(fromFinancesStore.getAccountsPaginationInfo));
  }

  /**
   * Initialize form and add validators.
   */
  initForm() {
    this.searchForm = new FormGroup({
      description: new FormControl(''),
      page: new FormControl(1),
    });
  }

  /**
   * Handles search parameters change.
   */
  search(event?: KeyboardEvent) {
    if (event && event.key === 'Enter') {
      event.preventDefault();
      return;
    }

    this.applyFiltersAndSearch();
  }

  /**
   * Handles pagination page-changed event.
   * @param page The current selected page number.
   */
  pageChanged(page: number) {
    /** Update pagination page. */
    this.searchForm.patchValue({ page });

    this.applyFiltersAndSearch(page);
  }

  /**
   * Enables trackBy feature for *ngFor utility to track items
   * depending on a comparer to reduce DOM change.
   * If the item is already exist then no HTML will change, only bounded values.
   * @param item The item to determine if it was changed or not.
   */
  trackItems(item: Account) {
    return item ? item.id : undefined;
  }

  /**
   * Handles account selected event.
   * @param accounts The list of accounts to notify selected.
   */
  selectAccount(accounts: Account[]) {
    this.notificationService.successInstant(
      this.translationService.translate('FINANCES.ACCOUNTS.NOTIFICATION_MESSAGE.ACCOUNT_SELECTED')
    );
    this.accounts.emit(accounts);
    if (this.closeOnSelect) {
      this.closeModal();
    }
  }

  applyFiltersAndSearch(page: number = 1) {
    const { description } = this.searchForm.value;
    if (this.onlyNonParent === true) {
      this.financesStore$.dispatch(
        new fromFinancesStore.SearchRegularAccounts({
          description,
          primaryAccountKey: this.restrictedPrimaryAccountKey,
          page,
        })
      );
    } else {
      this.financesStore$.dispatch(
        new fromFinancesStore.SearchAccounts({
          description,
          page,
        })
      );
    }
  }

  /**
   * Opens the modal of the given templateRef.
   * @param modalRef The modal templateRef to be opened.
   */
  openModal(modalRef) {
    /**
     * Open the modal & listen for user press ESC key.
     */
    this.modal = this.modalService.open(modalRef, { size: 'lg' });
    this.modal.result.then(null, () => this.closeModal());
  }

  /**
   * Closes the currently opened modal.
   */
  closeModal() {
    this.modal?.dismiss();
    this.accounts.emit([]);
    this.visibleChange.emit(false);
  }
}
