import { SaleInvoice } from 'sales/models';

/**
 * Represents the pending point of sale invoices state.
 */
export class PendingPosInvoicesState {
  /**
   * The list of pending point of sale invoices.
   */
  public data: SaleInvoice[];

  /**
   * An error that may resulting during processing some actions.
   */
  public error: any;

  /**
   * The current selected pending point of sale invoice.
   */
  public selectedPendingPointOfSaleInvoice: SaleInvoice;

  /**
   * Determines if there is a running prepare point of sale invoices process.
   */
  public isPreparing: boolean;

  /**
   * Determines if the preparing point of sale invoices process has been ended successfully.
   */
  public isPrepareCompleted: boolean;

  /**
   * Determines if there is a updating point of sale invoice process.
   */
  public isUpdating: boolean;

  /**
   * Determines if there is a updating point of sale invoice process has been ended successfully.
   */
  public isUpdateCompleted: boolean;
}
