import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import { Observable, Subscription } from 'rxjs';
import { skip, tap } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { PageInfo, NotificationService, NotificationMessage, TranslationService } from 'shared';
import * as fromSalesStore from 'sales/store';
import * as fromAuthStore from 'auth/store';
import { Location } from 'stores/models';

@Component({
  selector: 'app-create-board',
  templateUrl: './create-board.component.html',
  styles: [],
})
export class CreateBoardComponent implements OnInit, OnDestroy {
  /**
   * The confirm modal template reference.
   */
  @ViewChild('confirmModalRef') confirmModalRef: ElementRef<any>;

  /**
   * Gets or sets the information about the current page.
   */
  pageInfo: PageInfo = {
    title: 'SALES.BOARDS.ADD_BOARD_PAGE_TITLE',
    icon: 'fa fa-plus',
  };

  /**
   * Indicates whether there is a create board process is running or not.
   */
  isCreating$: Observable<boolean>;

  /**
   * The create board form.
   */
  form: FormGroup;

  /**
   * The list of active locations.
   */
  locations: Location[] = [];

  /**
   * Shows or hide the locations list.
   */
  locationsListVisibility = false;

  /**
   * The set of subscriptions on this components,
   * these subscriptions must be unsubscribed before this component got destroyed.
   */
  subscriptions = new Subscription();

  /**
   * @param modalService The modal service.
   * @param notificationService The notification service.
   * @param salesStore$ The sales store.
   * @param translationService The translation service.
   * @param authStore$ the auth-store module.
   */
  constructor(
    private modalService: NgbModal,
    private notificationService: NotificationService,
    private salesStore$: Store<fromSalesStore.SalesState>,
    private translationService: TranslationService,
    private authStore$: Store<fromAuthStore.AuthState>
  ) {}

  ngOnInit(): void {
    this.init();
  }

  /**
   * Destroy component data
   */
  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  /**
   * Initialize component data.
   */
  init() {
    this.initForm();

    /**
     * Reset form controls when new board is created.
     */
    this.subscriptions.add(
      this.salesStore$
        .pipe(
          select(fromSalesStore.getSelectedBoardCreateCompleted),
          skip(1),
          tap((isCreated) => {
            if (isCreated) {
              this.initForm();
            }
          })
        )
        .subscribe()
    );

    /* Subscribe to selected location to select it whenever it changed. */
    this.subscriptions.add(
      this.authStore$
        .pipe(
          select(fromAuthStore.getSessionUserDefaultLocation),
          tap((location) => {
            if (location) {
              this.selectLocation([location]);
            }
          })
        )
        .subscribe()
    );

    this.isCreating$ = this.salesStore$.pipe(select(fromSalesStore.getSelectedBoardCreating));
  }

  /**
   * Initialize form and add validators.
   */
  initForm() {
    this.form = new FormGroup({
      description: new FormControl('', [Validators.required, Validators.minLength(1), Validators.maxLength(100)]),
      locationId: new FormControl(null, [Validators.required]),
    });
  }

  /**
   * Submits the form.
   */
  submit() {
    if (this.form.invalid) {
      const errorMessage = new NotificationMessage();

      if (this.form.get('description').invalid) {
        errorMessage.title = this.translationService.translate('SALES.BOARDS.BOARD_DATA_VALIDATION.DESCRIPTION_ERROR');
        errorMessage.body = [
          this.translationService.translate('SALES.BOARDS.BOARD_DATA_VALIDATION.DESCRIPTION_IS_REQUIRED'),
          this.translationService.translate('SALES.BOARDS.BOARD_DATA_VALIDATION.DESCRIPTION_LENGTH_ERROR'),
        ];
      } else if (this.form.get('locationId').invalid) {
        errorMessage.title = this.translationService.translate('SALES.BOARDS.BOARD_DATA_VALIDATION.LOCATION_ERROR');
        errorMessage.body = [
          this.translationService.translate('SALES.BOARDS.BOARD_DATA_VALIDATION.LOCATION_IS_REQUIRED'),
        ];
      }

      this.form.markAllAsTouched();
      return this.notificationService.warningWithTitle(errorMessage);
    } else {
      this.openModal(this.confirmModalRef);
    }
  }

  /**
   * Confirms the form submit.
   */
  confirm() {
    this.salesStore$.dispatch(
      new fromSalesStore.CreateBoard({
        ...this.form.value,
      })
    );
  }

  /**
   * Selects the newly selected location from the locations search list.
   * @param locations The list of newly selected locations to select the only one in the list.
   */
  selectLocation([location]: Location[]) {
    if (location) {
      this.locations = [location];
      this.form.patchValue({ locationId: location.id });
    }
  }

  /**
   * Opens the modal of the given templateRef.
   * @param modalRef The modal templateRef to be opened.
   */
  openModal(modalRef) {
    this.modalService.open(modalRef);
  }
}
