import { createSelector } from '@ngrx/store';

import { PosPrinterAdjustmentsState, SalesState } from 'sales/store/states';
import { selectSalesState } from 'sales/store/selectors/feature.selectors';

/**
 * Gets the pos printer state.
 */
const selectPosPrinterAdjustmentsState = createSelector(
  selectSalesState,
  (state: SalesState) => state.posPrinterAdjustments
);
/**
 * Gets the error that occurred in the state.
 */
export const getPosPrinterAdjustmentsError = createSelector(
  selectPosPrinterAdjustmentsState,
  (state: PosPrinterAdjustmentsState) => state.error
);

/**
 * Gets the list of loaded kitchen printer.
 */
export const getKitchenPrinters = createSelector(
  selectPosPrinterAdjustmentsState,
  (state: PosPrinterAdjustmentsState) => state.kitchenPrinterData
);

/**
 * Gets the kitchen printer pagination info.
 */
export const getKitchenPrintersPaginationInfo = createSelector(
  selectPosPrinterAdjustmentsState,
  (state: PosPrinterAdjustmentsState) => state.kitchenPrinterPaginationInfo
);

/**
 * Determines if there is a running search process.
 */
export const getKitchenPrintersSearching = createSelector(
  selectPosPrinterAdjustmentsState,
  (state: PosPrinterAdjustmentsState) => state.isKitchenPrintersSearching
);

/**
 * Determines if the last search process has been ended successfully.
 */
export const getKitchenPrintersSearchCompleted = createSelector(
  selectPosPrinterAdjustmentsState,
  (state: PosPrinterAdjustmentsState) => state.isKitchenPrintersSearchCompleted
);

/**
 * Gets the selected kitchen printer.
 */
export const getSelectedKitchenPrinter = createSelector(
  selectPosPrinterAdjustmentsState,
  (state: PosPrinterAdjustmentsState) => state.selectedKitchenPrinter
);

/**
 * Determines if there is a running find kitchen printer process.
 */
export const getKitchenPrinterFinding = createSelector(
  selectPosPrinterAdjustmentsState,
  (state: PosPrinterAdjustmentsState) => state.isKitchenPrinterFinding
);

/**
 * Determines if the last find kitchen printer process has been ended successfully.
 */
export const getKitchenPrinterFindCompleted = createSelector(
  selectPosPrinterAdjustmentsState,
  (state: PosPrinterAdjustmentsState) => state.isKitchenPrinterFindCompleted
);

/**
 * Determines if there is a running create process.
 */
export const getSelectedKitchenPrinterCreating = createSelector(
  selectPosPrinterAdjustmentsState,
  (state: PosPrinterAdjustmentsState) => state.isKitchenPrinterCreating
);

/**
 * Determines if the last create process has been ended successfully.
 */
export const getSelectedKitchenPrinterCreateCompleted = createSelector(
  selectPosPrinterAdjustmentsState,
  (state: PosPrinterAdjustmentsState) => state.isKitchenPrinterCreateCompleted
);

/**
 * Determines if there is a running update process.
 */
export const getSelectedKitchenPrinterUpdating = createSelector(
  selectPosPrinterAdjustmentsState,
  (state: PosPrinterAdjustmentsState) => state.isKitchenPrinterUpdating
);

/**
 * Determines if the last update process has been ended successfully.
 */
export const getSelectedKitchenPrinterUpdateCompleted = createSelector(
  selectPosPrinterAdjustmentsState,
  (state: PosPrinterAdjustmentsState) => state.isKitchenPrinterUpdateCompleted
);

/**
 * Determines if there is a running delete process.
 */
export const getSelectedKitchenPrinterDeleting = createSelector(
  selectPosPrinterAdjustmentsState,
  (state: PosPrinterAdjustmentsState) => state.isKitchenPrinterDeleting
);

/**
 * Determines if the last delete process has been ended successfully.
 */
export const getSelectedKitchenPrinterDeleteCompleted = createSelector(
  selectPosPrinterAdjustmentsState,
  (state: PosPrinterAdjustmentsState) => state.isKitchenPrinterDeleteCompleted
);

/**
 * Gets the list of loaded casher printer.
 */
export const getCasherPrinters = createSelector(
  selectPosPrinterAdjustmentsState,
  (state: PosPrinterAdjustmentsState) => state.casherPrinterData
);

/**
 * Gets the casher printer pagination info.
 */
export const getCasherPrintersPaginationInfo = createSelector(
  selectPosPrinterAdjustmentsState,
  (state: PosPrinterAdjustmentsState) => state.casherPrinterPaginationInfo
);

/**
 * Determines if there is a running search process.
 */
export const getCasherPrintersSearching = createSelector(
  selectPosPrinterAdjustmentsState,
  (state: PosPrinterAdjustmentsState) => state.isCasherPrintersSearching
);

/**
 * Determines if the last search process has been ended successfully.
 */
export const getCasherPrintersSearchCompleted = createSelector(
  selectPosPrinterAdjustmentsState,
  (state: PosPrinterAdjustmentsState) => state.isCasherPrintersSearchCompleted
);

/**
 * Gets the selected casher printer.
 */
export const getSelectedCasherPrinter = createSelector(
  selectPosPrinterAdjustmentsState,
  (state: PosPrinterAdjustmentsState) => state.selectedCasherPrinter
);

/**
 * Determines if there is a running find casher printer process.
 */
export const getCasherPrinterFinding = createSelector(
  selectPosPrinterAdjustmentsState,
  (state: PosPrinterAdjustmentsState) => state.isCasherPrinterFinding
);

/**
 * Determines if the last find casher printer process has been ended successfully.
 */
export const getCasherPrinterFindCompleted = createSelector(
  selectPosPrinterAdjustmentsState,
  (state: PosPrinterAdjustmentsState) => state.isCasherPrinterFindCompleted
);

/**
 * Determines if there is a running create process.
 */
export const getSelectedCasherPrinterCreating = createSelector(
  selectPosPrinterAdjustmentsState,
  (state: PosPrinterAdjustmentsState) => state.isCasherPrinterCreating
);

/**
 * Determines if the last create process has been ended successfully.
 */
export const getSelectedCasherPrinterCreateCompleted = createSelector(
  selectPosPrinterAdjustmentsState,
  (state: PosPrinterAdjustmentsState) => state.isCasherPrinterCreateCompleted
);

/**
 * Determines if there is a running update process.
 */
export const getSelectedCasherPrinterUpdating = createSelector(
  selectPosPrinterAdjustmentsState,
  (state: PosPrinterAdjustmentsState) => state.isCasherPrinterUpdating
);

/**
 * Determines if the last update process has been ended successfully.
 */
export const getSelectedCasherPrinterUpdateCompleted = createSelector(
  selectPosPrinterAdjustmentsState,
  (state: PosPrinterAdjustmentsState) => state.isCasherPrinterUpdateCompleted
);

/**
 * Determines if there is a running delete process.
 */
export const getSelectedCasherPrinterDeleting = createSelector(
  selectPosPrinterAdjustmentsState,
  (state: PosPrinterAdjustmentsState) => state.isCasherPrinterDeleting
);

/**
 * Determines if the last delete process has been ended successfully.
 */
export const getSelectedCasherPrinterDeleteCompleted = createSelector(
  selectPosPrinterAdjustmentsState,
  (state: PosPrinterAdjustmentsState) => state.isCasherPrinterDeleteCompleted
);

/**
 * Gets the list of loaded order delivery printer.
 */
export const getOrderDeliveryPrinters = createSelector(
  selectPosPrinterAdjustmentsState,
  (state: PosPrinterAdjustmentsState) => state.orderDeliveryPrinterData
);

/**
 * Gets the pos printer pagination info.
 */
export const getOrderDeliveryPrintersPaginationInfo = createSelector(
  selectPosPrinterAdjustmentsState,
  (state: PosPrinterAdjustmentsState) => state.orderDeliveryPrinterPaginationInfo
);

/**
 * Determines if there is a running search process.
 */
export const getOrderDeliveryPrintersSearching = createSelector(
  selectPosPrinterAdjustmentsState,
  (state: PosPrinterAdjustmentsState) => state.isOrderDeliveryPrintersSearching
);

/**
 * Determines if the last search process has been ended successfully.
 */
export const getOrderDeliveryPrintersSearchCompleted = createSelector(
  selectPosPrinterAdjustmentsState,
  (state: PosPrinterAdjustmentsState) => state.isOrderDeliveryPrintersSearchCompleted
);

/**
 * Gets the selected kitchen printer.
 */
export const getSelectedOrderDeliveryPrinter = createSelector(
  selectPosPrinterAdjustmentsState,
  (state: PosPrinterAdjustmentsState) => state.selectedOrderDeliveryPrinter
);

/**
 * Determines if there is a running find kitchen printer process.
 */
export const getOrderDeliveryPrinterFinding = createSelector(
  selectPosPrinterAdjustmentsState,
  (state: PosPrinterAdjustmentsState) => state.isOrderDeliveryPrinterFinding
);

/**
 * Determines if the last find kitchen printer process has been ended successfully.
 */
export const getOrderDeliveryPrinterFindCompleted = createSelector(
  selectPosPrinterAdjustmentsState,
  (state: PosPrinterAdjustmentsState) => state.isOrderDeliveryPrinterFindCompleted
);

/**
 * Determines if there is a running create process.
 */
export const getSelectedOrderDeliveryPrinterCreating = createSelector(
  selectPosPrinterAdjustmentsState,
  (state: PosPrinterAdjustmentsState) => state.isOrderDeliveryPrinterCreating
);

/**
 * Determines if the last create process has been ended successfully.
 */
export const getSelectedOrderDeliveryPrinterCreateCompleted = createSelector(
  selectPosPrinterAdjustmentsState,
  (state: PosPrinterAdjustmentsState) => state.isOrderDeliveryPrinterCreateCompleted
);

/**
 * Determines if there is a running update process.
 */
export const getSelectedOrderDeliveryPrinterUpdating = createSelector(
  selectPosPrinterAdjustmentsState,
  (state: PosPrinterAdjustmentsState) => state.isOrderDeliveryPrinterUpdating
);

/**
 * Determines if the last update process has been ended successfully.
 */
export const getSelectedOrderDeliveryPrinterUpdateCompleted = createSelector(
  selectPosPrinterAdjustmentsState,
  (state: PosPrinterAdjustmentsState) => state.isOrderDeliveryPrinterUpdateCompleted
);

/**
 * Determines if there is a running delete process.
 */
export const getSelectedOrderDeliveryPrinterDeleting = createSelector(
  selectPosPrinterAdjustmentsState,
  (state: PosPrinterAdjustmentsState) => state.isOrderDeliveryPrinterDeleting
);

/**
 * Determines if the last delete process has been ended successfully.
 */
export const getSelectedOrderDeliveryPrinterDeleteCompleted = createSelector(
  selectPosPrinterAdjustmentsState,
  (state: PosPrinterAdjustmentsState) => state.isOrderDeliveryPrinterDeleteCompleted
);
