<!--system statistics cards starts-->
<div class="row">
  <div class="col-12 d-flex justify-content-start">
    <button
      type="button"
      class="btn btn-sm btn-raised btn-primary round"
      [ngbTooltip]="'CORE.DASHBOARD.UPDATE_STATISTICS' | translate"
      [placement]="ngbPosition"
      (click)="loadStatistics()"
    >
      <i class="fa fa-refresh"></i>
    </button>
  </div>
</div>
<div class="row text-left" matchHeight="card">
  <div
    *ngIf="
      [
        Claims.CREATE_ORGANIZATION,
        Claims.UPDATE_ORGANIZATION,
        Claims.DELETE_ORGANIZATION,
        Claims.BLOCK_ORGANIZATION,
        Claims.ACTIVATE_ORGANIZATION
      ]
        | authorize
        | async
    "
    class="col-xl-4 col-lg-6 col-12"
  >
    <div class="card">
      <div class="card-content">
        <div class="px-3 py-3">
          <div class="media">
            <div class="media-body text-left">
              <h3 class="mb-1">{{ (statistics$ | async).organizationsCount | toNumber }}</h3>
              <span class="cursor-pointer" routerLink="/organizations">{{
                'CORE.DASHBOARD.ORGANIZATIONS' | translate
              }}</span>
            </div>
            <div class="media-right align-self-center">
              <i class="fa fa-bank primary font-large-2 float-right"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    *ngIf="
      [Claims.CREATE_USER, Claims.UPDATE_USER, Claims.BLOCK_USER, Claims.ACTIVATE_USER, Claims.RESET_USER_CREDENTIALS]
        | authorize
        | async
    "
    class="col-xl-4 col-lg-6 col-12"
  >
    <div class="card">
      <div class="card-content">
        <div class="px-3 py-3">
          <div class="media">
            <div class="media-body text-left">
              <h3 class="mb-1">{{ (statistics$ | async).serviceProviderUsersCount | toNumber }}</h3>
              <span class="cursor-pointer" routerLink="/security/users">{{
                'CORE.DASHBOARD.SERVICE_PROVIDERS' | translate
              }}</span>
            </div>
            <div class="media-right align-self-center">
              <i class="icon-user primary font-large-2 float-right"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    *ngIf="
      [Claims.CREATE_USER, Claims.UPDATE_USER, Claims.BLOCK_USER, Claims.ACTIVATE_USER, Claims.RESET_USER_CREDENTIALS]
        | authorize
        | async
    "
    class="col-xl-4 col-lg-6 col-12"
  >
    <div class="card">
      <div class="card-content">
        <div class="px-3 py-3">
          <div class="media">
            <div class="media-body text-left">
              <h3 class="mb-1">{{ (statistics$ | async).organizationUsersCount | toNumber }}</h3>
              <span class="cursor-pointer" routerLink="/security/users">{{
                'CORE.DASHBOARD.USERS_SYSTEM' | translate
              }}</span>
            </div>
            <div class="media-right align-self-center">
              <i class="icon-user primary font-large-2 float-right"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    *ngIf="
      [Claims.CREATE_USER, Claims.UPDATE_USER, Claims.BLOCK_USER, Claims.ACTIVATE_USER, Claims.RESET_USER_CREDENTIALS]
        | authorize
        | async
    "
    class="col-xl-4 col-lg-6 col-12"
  >
    <div class="card">
      <div class="card-content">
        <div class="px-3 py-3">
          <div class="media">
            <div class="media-body text-left">
              <h3 class="mb-1">{{ (statistics$ | async).activeUsersCount | toNumber }}</h3>
              <span class="cursor-pointer" routerLink="/security/users">{{
                'CORE.DASHBOARD.ACTIVE_USERS_COUNT' | translate
              }}</span>
            </div>
            <div class="media-right align-self-center">
              <i class="icon-user-following primary font-large-2 float-right"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    *ngIf="
      [Claims.CREATE_USER, Claims.UPDATE_USER, Claims.BLOCK_USER, Claims.ACTIVATE_USER, Claims.RESET_USER_CREDENTIALS]
        | authorize
        | async
    "
    class="col-xl-4 col-lg-6 col-12"
  >
    <div class="card">
      <div class="card-content">
        <div class="px-3 py-3">
          <div class="media">
            <div class="media-body text-left">
              <h3 class="mb-1">{{ (statistics$ | async).blockedUsersCount | toNumber }}</h3>
              <span class="cursor-pointer" routerLink="/security/users">{{
                'CORE.DASHBOARD.BLOCKED_USERS_COUNT' | translate
              }}</span>
            </div>
            <div class="media-right align-self-center">
              <i class="icon-user-unfollow primary font-large-2 float-right"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!--system statistics cards End-->
