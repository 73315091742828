import { Injectable } from '@angular/core';

import { of } from 'rxjs';
import { switchMap, map, catchError } from 'rxjs/operators';
import { Effect, ofType, Actions } from '@ngrx/effects';

import { LookupsService } from 'lookups/services';
import { AnnualLeaveActionType, GetAllAnnualLeavesFail, GetAllAnnualLeavesSuccess } from 'lookups/store/actions';

@Injectable()
export class AnnualLeaveEffects {
  constructor(private actions$: Actions, private lookupsService: LookupsService) {}

  /* ========================= GET_ALL_ANNUAL_LEAVES =================================== */
  @Effect()
  getAllAnnualLeaves$ = this.actions$.pipe(
    ofType(AnnualLeaveActionType.GET_ALL_ANNUAL_LEAVES),
    switchMap(() =>
      this.lookupsService.getAllAnnualLeaves().pipe(
        map((response) => new GetAllAnnualLeavesSuccess(response)),
        catchError((error) => of(new GetAllAnnualLeavesFail(error)))
      )
    )
  );
}
