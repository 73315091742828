import { Action } from '@ngrx/store';

import { AppHttpResponse } from 'shared';
import {
  Account,
  CreateAccountInput,
  CreateSecondaryAccountInput,
  UpdateSecondaryAccountInput,
  UpdateAccountInput,
} from 'finances/models';

/**
 * The finances-module store accounts action types.
 */
export enum AccountsActionType {
  SEARCH_ACCOUNTS = '[Finances] [Accounts] Search Account',
  SEARCH_ACCOUNTS_FAIL = '[Finances] [Accounts] Search Account Fail',
  SEARCH_ACCOUNTS_SUCCESS = '[Finances] [Accounts] Search Account Success',

  SEARCH_REGULAR_ACCOUNTS = '[Finances] [Accounts] Search Regular Accounts',

  FIND_ACCOUNT = '[Finances] [Accounts] Find Account',
  FIND_ACCOUNT_FAIL = '[Finances] [Accounts] Find Account Fail',
  FIND_ACCOUNT_SUCCESS = '[Finances] [Accounts] Find Account Success',

  CREATE_ACCOUNT = '[Finances] [Accounts] Create Account',
  CREATE_ACCOUNT_FAIL = '[Finances] [Accounts] Create Account Fail',
  CREATE_ACCOUNT_SUCCESS = '[Finances] [Accounts] Create Account Success',

  CREATE_SECONDARY_ACCOUNT = '[Finances] [Accounts] Create Secondary Account',
  CREATE_SECONDARY_ACCOUNT_FAIL = '[Finances] [Accounts] Create Secondary Account Fail',
  CREATE_SECONDARY_ACCOUNT_SUCCESS = '[Finances] [Accounts] Create Secondary Account Success',

  UPDATE_ACCOUNT = '[Finances] [Accounts] Update Account',
  UPDATE_ACCOUNT_FAIL = '[Finances] [Accounts] Update Account Fail',
  UPDATE_ACCOUNT_SUCCESS = '[Finances] [Accounts] Update Account Success',

  UPDATE_SECONDARY_ACCOUNT = '[Finances] [Accounts] Update Secondary Account',
  UPDATE_SECONDARY_ACCOUNT_FAIL = '[Finances] [Accounts] Update Secondary Account Fail',
  UPDATE_SECONDARY_ACCOUNT_SUCCESS = '[Finances] [Accounts] Update Secondary Account Success',

  DELETE_ACCOUNT = '[Finances] [Accounts] Delete Account',
  DELETE_ACCOUNT_FAIL = '[Finances] [Accounts] Delete Account Fail',
  DELETE_ACCOUNT_SUCCESS = '[Finances] [Accounts] Delete Account Success',
}

/**
 * Represents a store accounts search action.
 */
export class SearchAccounts implements Action {
  /**
   * The type of the action.
   */
  readonly type = AccountsActionType.SEARCH_ACCOUNTS;

  /**
   * Fires a new accounts search action.
   * @param payload the search parameters,
   * if omitted, all accounts will be loaded.
   */
  constructor(public payload?: { description: string; page: number }) {}
}

/**
 * Represents a store accounts search fail action.
 */
export class SearchAccountsFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = AccountsActionType.SEARCH_ACCOUNTS_FAIL;

  /**
   * Fires a new accounts search fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store search success action.
 */
export class SearchAccountsSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = AccountsActionType.SEARCH_ACCOUNTS_SUCCESS;

  /**
   * Fires a new accounts search success action.
   * @param payload An object contains the list of accounts that matches the search criteria
   * in addition to the pagination info.
   */
  constructor(public payload: AppHttpResponse<Account[]>) {}
}

/**
 * Represents a store regular accounts search action.
 */
export class SearchRegularAccounts implements Action {
  /**
   * The type of the action.
   */
  readonly type = AccountsActionType.SEARCH_REGULAR_ACCOUNTS;

  /**
   * Fires a new regular accounts search action.
   * @param payload the search parameters,
   * if omitted, all accounts will be loaded.
   */
  constructor(public payload?: { description: string; primaryAccountKey: string; page: number }) {}
}

/**
 * Represents a store find account action.
 */
export class FindAccount implements Action {
  /**
   * The type of the action.
   */
  readonly type = AccountsActionType.FIND_ACCOUNT;

  /**
   * Fires a new find account action.
   * @param payload the id of the account.
   */
  constructor(public payload: number) {}
}

/**
 * Represents a store find account fail action.
 */
export class FindAccountFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = AccountsActionType.FIND_ACCOUNT_FAIL;

  /**
   * Fires a new find account fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store find account success action.
 */
export class FindAccountSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = AccountsActionType.FIND_ACCOUNT_SUCCESS;

  /**
   * Fires a new find account success action.
   * @param payload The loaded account.
   */
  constructor(public payload: AppHttpResponse<Account>) {}
}

/**
 * Represents a store account create action.
 */
export class CreateAccount implements Action {
  /** The type of the action. */
  readonly type = AccountsActionType.CREATE_ACCOUNT;

  /**
   * Fires a new account create action.
   * @param payload the new account data-model.
   */
  constructor(public payload: CreateAccountInput) {}
}

/**
 * Represents a store account create fail action.
 */
export class CreateAccountFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = AccountsActionType.CREATE_ACCOUNT_FAIL;

  /**
   * Fires a new account create fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store account create success action.
 */
export class CreateAccountSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = AccountsActionType.CREATE_ACCOUNT_SUCCESS;

  /**
   * Fires a new account create success action.
   * @param payload The created account.
   */
  constructor(public payload: AppHttpResponse<Account>) {}
}

/**
 * Represents a store secondary account create action.
 */
export class CreateSecondaryAccount implements Action {
  /** The type of the action. */
  readonly type = AccountsActionType.CREATE_SECONDARY_ACCOUNT;

  /**
   * Fires a new secondary account create action.
   * @param payload the new account data-model.
   */
  constructor(public payload: CreateSecondaryAccountInput) {}
}

/**
 * Represents a store secondary account create fail action.
 */
export class CreateSecondaryAccountFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = AccountsActionType.CREATE_SECONDARY_ACCOUNT_FAIL;

  /**
   * Fires a new secondary account create fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store secondary account create success action.
 */
export class CreateSecondaryAccountSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = AccountsActionType.CREATE_SECONDARY_ACCOUNT_SUCCESS;

  /**
   * Fires a new secondary account create success action.
   * @param payload The created account.
   */
  constructor(public payload: AppHttpResponse<Account>) {}
}

/**
 * Represents a store account update action.
 */
export class UpdateAccount implements Action {
  /**
   * The type of the action.
   */
  readonly type = AccountsActionType.UPDATE_ACCOUNT;

  /**
   * Fires a new account update action.
   * @param payload The account's updated data.
   */
  constructor(public payload: UpdateAccountInput) {}
}

/**
 * Represents a store account update fail action.
 */
export class UpdateAccountFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = AccountsActionType.UPDATE_ACCOUNT_FAIL;

  /**
   * Fires a new account update fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store account update success action.
 */
export class UpdateAccountSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = AccountsActionType.UPDATE_ACCOUNT_SUCCESS;

  /**
   * Fires a new account update-success action.
   * @param payload The updated account.
   */
  constructor(public payload: AppHttpResponse<Account>) {}
}

/**
 * Represents a store secondary account update action.
 */
export class UpdateSecondaryAccount implements Action {
  /**
   * The type of the action.
   */
  readonly type = AccountsActionType.UPDATE_SECONDARY_ACCOUNT;

  /**
   * Fires a new secondary account update action.
   * @param payload The secondary account's updated data.
   */
  constructor(public payload: UpdateSecondaryAccountInput) {}
}

/**
 * Represents a store account update fail action.
 */
export class UpdateSecondaryAccountFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = AccountsActionType.UPDATE_SECONDARY_ACCOUNT_FAIL;

  /**
   * Fires a new secondary account update fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store account update success action.
 */
export class UpdateSecondaryAccountSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = AccountsActionType.UPDATE_SECONDARY_ACCOUNT_SUCCESS;

  /**
   * Fires a new secondary account update-success action.
   * @param payload The secondary updated account.
   */
  constructor(public payload: AppHttpResponse<Account>) {}
}

/**
 * Represents a store account delete action.
 */
export class DeleteAccount implements Action {
  /**
   * The type of the action.
   */
  readonly type = AccountsActionType.DELETE_ACCOUNT;

  /**
   * Fires a new account delete action.
   * @param payload The account's id.
   */
  constructor(public payload: number) {}
}

/**
 * Represents a store accounts delete fail action.
 */
export class DeleteAccountFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = AccountsActionType.DELETE_ACCOUNT_FAIL;

  /**
   * Fires a new account delete fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store account delete success action.
 */
export class DeleteAccountSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = AccountsActionType.DELETE_ACCOUNT_SUCCESS;

  /**
   * Fires a new account delete success action.
   * @param payload The deleted account.
   */
  constructor(public payload: AppHttpResponse<Account>) {}
}

/**
 * finances-module accounts action types.
 */
export type AccountsActions =
  | SearchAccounts
  | SearchAccountsFail
  | SearchAccountsSuccess
  | SearchRegularAccounts
  | FindAccount
  | FindAccountFail
  | FindAccountSuccess
  | CreateAccount
  | CreateAccountFail
  | CreateAccountSuccess
  | CreateSecondaryAccount
  | CreateSecondaryAccountFail
  | CreateSecondaryAccountSuccess
  | UpdateAccount
  | UpdateAccountFail
  | UpdateAccountSuccess
  | UpdateSecondaryAccount
  | UpdateSecondaryAccountFail
  | UpdateSecondaryAccountSuccess
  | DeleteAccount
  | DeleteAccountFail
  | DeleteAccountSuccess;
