import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';

import { Observable } from 'rxjs';
import { tap, skip } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';

import * as fromFinancesStore from 'finances/store';
import { PageInfo, TranslationService } from 'shared';
import { Claims } from 'security/models';
import { BankAccount } from 'finances/models';

/**
 * The bank account file pages.
 */
enum PAGES {
  info = 'info',
  summary = 'summary',
}

@Component({
  selector: 'app-bank-account-file',
  templateUrl: './bank-account-file.component.html',
  styles: [],
})
export class BankAccountFileComponent implements OnInit {
  /**
   * Gets or sets the information about the current page.
   */
  pageInfo: PageInfo = {
    title: 'FINANCES.BANK_ACCOUNTS.BANK_ACCOUNT_DATA.BANK_ACCOUNT',
    icon: 'fa fa-folder-open',
  };

  /**
   * The system supported user claims.
   */
  Claims = Claims;

  /**
   * Gets or sets the id of the current edited bank account.
   */
  bankAccountId: number;

  /**
   * The name of the current bank account.
   */
  bankAccountName: string;

  /**
   * The current edited bank account.
   */
  bankAccount$: Observable<BankAccount>;

  /**
   * Gets or sets the selected page.
   * @default 'info'
   */
  activePage: PAGES = PAGES.info;

  /**
   * Gets or sets the current selected language.
   */
  get currentLang() {
    return this.translationService.language;
  }

  /**
   * @param route The activated route service.
   * @param locationService the location service.
   * @param financesStore$ the finances-store module.
   * @param translationService The translation service.
   */
  constructor(
    private route: ActivatedRoute,
    private locationService: Location,
    private financesStore$: Store<fromFinancesStore.FinancesState>,
    private translationService: TranslationService
  ) {}

  ngOnInit(): void {
    this.init();
  }

  /**
   * Initialize component data.
   */
  init() {
    /* Load the selected bank account. */
    this.bankAccountId = +this.route.snapshot.params.bankAccountId;
    this.financesStore$.dispatch(new fromFinancesStore.FindBankAccounts(this.bankAccountId));

    this.bankAccount$ = this.financesStore$.pipe(
      select(fromFinancesStore.getSelectedBankAccount),
      skip(1),
      tap((bankAccount) => {
        if (bankAccount) {
          this.bankAccountName = this.currentLang === 'en' ? bankAccount.nameEn : bankAccount.name;

          /* Update page info. */
          this.pageInfo = {
            ...this.pageInfo,
            subTitle: `(${this.bankAccountName})`,
          };
        }
      })
    );

    /** Select the user desired page. */
    this.activePage = PAGES[this.route.snapshot.fragment] ?? this.activePage;
    this.selectedPageChanged(this.activePage);
  }

  /**
   * Updates the browser url according to the selected page.
   * @param page The newly selected page.
   */
  selectedPageChanged(page: PAGES) {
    this.locationService.replaceState(`${this.locationService.path()}#${PAGES[page]}`);
  }
}
