import { Action } from '@ngrx/store';

import { AssetExclusionReason } from 'lookups/models';
import { AppHttpResponse } from 'shared';

/**
 * The Lookups-Module store  asset exclusion reason action types.
 */
export enum AssetExclusionReasonActionType {
  GET_ALL_ASSET_EXCLUSION_REASON = '[Lookups] [AssetExclusionReason] Get All Asset Exclusion Reasons',
  GET_ALL_ASSET_EXCLUSION_REASON_FAIL = '[Lookups] [AssetExclusionReason] Get All Asset Exclusion Reasons Fail',
  GET_ALL_ASSET_EXCLUSION_REASON_SUCCESS = '[Lookups] [AssetExclusionReason] Get All Asset Exclusion Reasons Success',
}

/**
 * Represents a store  asset exclusion reason action.
 */
export class GetAllAssetExclusionReason implements Action {
  /**
   * The type of the action.
   */
  readonly type = AssetExclusionReasonActionType.GET_ALL_ASSET_EXCLUSION_REASON;

  /**
   * Fires a new  asset exclusion reason action.
   */
  constructor() {}
}

/**
 * Represents a store  asset exclusion reason fail action.
 */
export class GetAllAssetExclusionReasonFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = AssetExclusionReasonActionType.GET_ALL_ASSET_EXCLUSION_REASON_FAIL;

  /**
   * Fires a new  asset exclusion reason fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store  asset exclusion reason success action.
 */
export class GetAllAssetExclusionReasonSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = AssetExclusionReasonActionType.GET_ALL_ASSET_EXCLUSION_REASON_SUCCESS;

  /**
   * Fires a  asset exclusion reason success action.
   * @param payload An object contains the list of  asset exclusion reasons.
   */
  constructor(public payload: AppHttpResponse<AssetExclusionReason[]>) {}
}

/**
 * Lookups-module  asset exclusion reason action types.
 */
export type AssetExclusionReasonActions =
  | GetAllAssetExclusionReason
  | GetAllAssetExclusionReasonFail
  | GetAllAssetExclusionReasonSuccess;
