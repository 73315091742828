import { Component, ElementRef, ViewChild, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { flatMap } from 'lodash';

import { PageInfo, TranslationService } from 'shared';
import * as fromFinancesStore from 'finances/store';
import {
  Payroll,
  PayrollPaymentRefund,
  PayrollPaymentRefundAttachment,
  PayrollPaymentRefundPayroll,
} from 'finances/models';

@Component({
  selector: 'app-payroll-payment-refund-details',
  templateUrl: './payroll-payment-refund-details.component.html',
  styles: [],
})
export class PayrollPaymentRefundDetailsComponent implements OnInit {
  /**
   * The confirm view attachments modal template reference.
   */
  @ViewChild('viewAttachmentsModalRef') viewAttachmentsModalRef: ElementRef<any>;

  /**
   * Gets or sets the information about the current page.
   */
  pageInfo: PageInfo = {
    title: 'FINANCES.HR.PAYROLL_PAYMENTS.PAYROLL_PAYMENT_REFUND_DETAILS',
    icon: 'fa fa-money',
  };

  /**
   * Sets the payroll payment refund id.
   */
  payrollPaymentRefundId: number;

  /**
   * Get selected payroll payment refund.
   */
  selectedPayrollPaymentRefund: PayrollPaymentRefund;

  /**
   * The current viewed payroll payment refund.
   */
  payrollPaymentRefund$: Observable<PayrollPaymentRefund>;

  /**
   * @param modalService The modal service.
   * @param route The activated route.
   * @param financesStore$ The finances store.
   * @param translationService The translation service.
   */
  constructor(
    private modalService: NgbModal,
    private route: ActivatedRoute,
    private financesStore$: Store<fromFinancesStore.FinancesState>,
    private translationService: TranslationService
  ) {}

  ngOnInit(): void {
    this.init();
  }

  /**
   * Initialize component data.
   */
  init() {
    /* Load the selected payroll payment refund line. */
    this.payrollPaymentRefundId = +this.route.snapshot.params.payrollPaymentRefundId;

    /* Load the selected payroll payment refund line. */
    this.payrollPaymentRefund$ = this.financesStore$.pipe(
      select(fromFinancesStore.getSelectedPayrollPaymentRefund),
      tap((payrollPaymentRefund) => {
        if (payrollPaymentRefund) {
          /* Update page info. */
          this.pageInfo = {
            ...this.pageInfo,
            subTitle: this.translationService.translate(
              'FINANCES.HR.PAYROLL_PAYMENTS.PAYROLL_PAYMENTS_DATA.PAYROLL_PAYMENT_REFUND_NUMBER',
              {
                payrollPaymentRefundNumber: payrollPaymentRefund.id,
              }
            ),
          };
        }
      })
    );

    this.financesStore$.dispatch(new fromFinancesStore.FindPayrollPaymentRefund(this.payrollPaymentRefundId));
  }

  /**
   * Maps and returns the list of payrolls.
   * @param data The list of payroll payment payrolls.
   * @returns
   */
  getPayrolls(data: PayrollPaymentRefundPayroll[]): Payroll[] {
    return flatMap(data, (item) => item.payroll);
  }

  /**
   * Maps and returns the set of petty cash refund request line attachments into a set of urls.
   * @param attachments The petty cash refund request line attachments to be mapped.
   */
  getAttachmentsUrls(attachments: PayrollPaymentRefundAttachment[]): string[] {
    return attachments ? attachments.map((attachment) => attachment.url) : [];
  }

  /**
   * Opens the modal of the given templateRef.
   * @param modalRef The modal templateRef to be opened.
   */
  openModal(modalRef) {
    this.modalService.open(modalRef);
  }
}
