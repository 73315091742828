import { CreateBoardComponent } from 'sales/components/boards/create-board/create-board.component';
import { UpdateBoardComponent } from 'sales/components/boards/update-board/update-board.component';
import { BoardsListComponent } from 'sales/components/boards/boards-list/boards-list.component';
import { BoardsSearchComponent } from 'sales/components/boards/boards-search/boards-search.component';

export * from 'sales/components/boards/create-board/create-board.component';
export * from 'sales/components/boards/update-board/update-board.component';
export * from 'sales/components/boards/boards-list/boards-list.component';
export * from 'sales/components/boards/boards-search/boards-search.component';

/**
 * The set of components registered on this sub-module.
 */
export const COMPONENTS: any[] = [
  CreateBoardComponent,
  UpdateBoardComponent,
  BoardsListComponent,
  BoardsSearchComponent,
];
