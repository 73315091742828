import { CustomerAsset } from 'sales/models';
import { PaginationInfo } from 'shared/models';

/**
 * Represents the customer assets state.
 */
export class CustomerAssetsState {

  /**
   * The list of customer assets.
   */
  public data: CustomerAsset[];

  /**
   * The pagination info for the customer assets.
   */
  public paginationInfo: PaginationInfo;

  /**
   * An error that may resulting during processing some actions.
   */
  public error: any;

  /**
   * The current selected customer assets.
   */
  public selectedCustomerAsset: CustomerAsset;

  /**
   * Determines if there is a running find-customer-asset process.
   */
  public isFinding: boolean;

  /**
   * Determines if the last find-customer-asset process has been ended successfully.
   */
  public isFindCompleted: boolean;

  /**
   * Determines if there is a running create customer asset process.
   */
  public isCreating: boolean;

  /**
   * Determines if the last create customer asset process has been ended successfully.
   */
  public isCreateCompleted: boolean;

  /**
   * Determines if there is a running search process.
   */
  public isSearching: boolean;

  /**
   * Determines if the last search process has been ended successfully.
   */
  public isSearchCompleted: boolean;

  /**
   * Determines if there is a running update customer asset process.
   */
  public isUpdating: boolean;

  /**
   * Determines if the last update customer asset process has been ended successfully.
   */
  public isUpdateCompleted: boolean;

  /**
   * Determines if there is a running customer asset delete process.
   */
  public isDeleting: boolean;

  /**
   * Determines if the last delete customer asset process has been ended successfully.
   */
  public isDeleteCompleted: boolean;

  /**
   * Determines if there is a running get customer asset process.
   */
  public isGetting: boolean;

  /**
   * Determines if the last get customer asset process has been ended successfully.
   */
  public isGetCompleted: boolean;
}
