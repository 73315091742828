import { BankAccountsActionType, BankAccountsActions } from 'app-search/store/actions';
import { BankAccountState } from 'app-search/store/states';

/**
 * The initial state from which the state starts.
 */
const initialState: BankAccountState = {
  bankAccounts: [],
  paginationInfo: {
    total: 0,
    page: 1,
    pageSize: 0,
    count: 0,
  },
  error: null,
  isSearching: false,
  isSearchCompleted: false,
};

/**
 * The reducer function that is called in each action dispatch against the state.
 * @param state The current state.
 * @param action The action that will affect the state.
 */
export function bankAccountsReducer(
  state: BankAccountState = initialState,
  action: BankAccountsActions
): BankAccountState {
  switch (action.type) {
    //#region SEARCH_BANK_ACCOUNTS

    case BankAccountsActionType.SEARCH_BANK_ACCOUNTS: {
      return {
        ...state,
        isSearching: true,
        isSearchCompleted: false,
        error: null,
      };
    }

    case BankAccountsActionType.SEARCH_BANK_ACCOUNTS_FAIL: {
      return {
        ...state,
        error: action.payload,
        isSearching: false,
        isSearchCompleted: false,
      };
    }

    case BankAccountsActionType.SEARCH_BANK_ACCOUNTS_SUCCESS: {
      return {
        ...state,
        bankAccounts: action.payload.data,
        paginationInfo: {
          ...action.payload.meta,
        },
        error: null,
        isSearching: false,
        isSearchCompleted: true,
      };
    }

    //#endregion SEARCH_BANK_ACCOUNTS

    default:
      return state;
  }
}
