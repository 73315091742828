import { CustomerEngagementsComponent } from 'sales/components/customer-engagements/customer-engagements/customer-engagements.component';
import { CustomerEngagementsListComponent } from 'sales/components/customer-engagements/customer-engagements-list/customer-engagements-list.component';
import { CustomerEngagementsFormComponent } from 'sales/components/customer-engagements/customer-engagements-form/customer-engagements-form.component';

export * from 'sales/components/customer-engagements/customer-engagements/customer-engagements.component';
export * from 'sales/components/customer-engagements/customer-engagements-list/customer-engagements-list.component';
export * from 'sales/components/customer-engagements/customer-engagements-form/customer-engagements-form.component';

/**
 * The set of components registered on this sub-module.
 */
export const COMPONENTS: any[] = [
  CustomerEngagementsComponent,
  CustomerEngagementsListComponent,
  CustomerEngagementsFormComponent,
];
