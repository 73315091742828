import { Shift } from 'sales/models';
import { PaginationInfo } from 'shared/models';

/**
 * Represents the shifts state.
 */
export class ShiftsState {
  /**
   * The list of shifts.
   */
  public data: Shift[];

  /**
   * The pagination info for the shifts.
   */
  public paginationInfo: PaginationInfo;

  /**
   * An error that may resulting during processing some actions.
   */
  public error: any;

  /**
   * Determines if there is a running search process.
   */
  public isSearching: boolean;

  /**
   * Determines if the last search process has been ended successfully.
   */
  public isSearchCompleted: boolean;

  /**
   * Determines if there is a running search all shifts process.
   */
  public isAllShiftsSearching: boolean;

  /**
   * Determines if the last search all shifts process has been ended successfully.
   */
  public isAllShiftsSearchCompleted: boolean;

  /**
   * The current selected shift.
   */
  public selectedShift: Shift;

  /**
   * Determines if there is a running find-shift process.
   */
  public isFinding: boolean;

  /**
   * Determines if the last find-shift process has been ended successfully.
   */
  public isFindCompleted: boolean;

  /**
   * Determines if there is a running start process.
   */
  public isStarting: boolean;

  /**
   * Determines if the last start process has been ended successfully.
   */
  public isStartCompleted: boolean;

  /**
   * Determines if there is a running start shift for casher process.
   */
  public isShiftForCasherStarting: boolean;

  /**
   * Determines if the last start shift for casher process has been ended successfully.
   */
  public isShiftForCasherStartCompleted: boolean;

  /**
   * Determines if there is a running cancel process.
   */
  public isCanceling: boolean;

  /**
   * Determines if the last cancel process has been ended successfully.
   */
  public isCancelCompleted: boolean;

  /**
   * Determines if there is a running cancel shift process.
   */
  public isShiftCanceling: boolean;

  /**
   * Determines if the last cancel shift process has been ended successfully.
   */
  public isShiftCancelCompleted: boolean;

  /**
   * Determines if there is a running delete shift process.
   */
    public isShiftDeleting: boolean;

    /**
     * Determines if the last delete shift process has been ended successfully.
     */
    public isShiftDeleteCompleted: boolean;
}
