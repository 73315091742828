import { Action } from '@ngrx/store';

import { GosiCoverageType } from 'lookups/models';
import { AppHttpResponse } from 'shared';

/**
 * The Lookups-Module store gosi coverage type type action types.
 */
export enum GosiCoverageTypeActionType {
  GET_ALL_GOSI_COVERAGE_TYPES = '[Lookups] [GosiCoverageTypes] Get All Gosi Coverage Types',
  GET_ALL_GOSI_COVERAGE_TYPES_FAIL = '[Lookups] [GosiCoverageTypes] Get All Gosi Coverage Types Fail',
  GET_ALL_GOSI_COVERAGE_TYPES_SUCCESS = '[Lookups] [GosiCoverageTypes] Get All Gosi Coverage Types Success',
}

/**
 * Represents a store gosi coverage type type action.
 */
export class GetAllGosiCoverageType implements Action {
  /**
   * The type of the action.
   */
  readonly type = GosiCoverageTypeActionType.GET_ALL_GOSI_COVERAGE_TYPES;

  /**
   * Fires a new gosi coverage type type action.
   */
  constructor() {}
}

/**
 * Represents a store gosi coverage type type fail action.
 */
export class GetAllGosiCoverageTypeFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = GosiCoverageTypeActionType.GET_ALL_GOSI_COVERAGE_TYPES_FAIL;

  /**
   * Fires a new gosi coverage type type fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store gosi coverage type type success action.
 */
export class GetAllGosiCoverageTypeSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = GosiCoverageTypeActionType.GET_ALL_GOSI_COVERAGE_TYPES_SUCCESS;

  /**
   * Fires a gosi coverage type type success action.
   * @param payload An object contains the list of  gosi coverage type Types.
   */
  constructor(public payload: AppHttpResponse<GosiCoverageType[]>) {}
}

/**
 * Lookups-module gosi coverage type type action types.
 */
export type GosiCoverageTypeActions =
  | GetAllGosiCoverageType
  | GetAllGosiCoverageTypeFail
  | GetAllGosiCoverageTypeSuccess;
