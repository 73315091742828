import { AssetCategoryEffects } from 'lookups/store/effects/asset-category.effects';
import { AssetTypeEffects } from 'lookups/store/effects/asset-type.effects';
import { AssetExclusionReasonEffects } from 'lookups/store/effects/asset-exclusion-reason.effects';
import { AssetYearsOfUseEffects } from 'lookups/store/effects/asset-years-of-use.effects';
import { CustomerEngagementTypeEffects } from 'lookups/store/effects/customer-engagement-type.effects';
import { IdentityTypeEffects } from 'lookups/store/effects/identity-type.effects';
import { NationalityEffects } from 'lookups/store/effects/nationality.effects';
import { PaymentDurationEffects } from 'lookups/store/effects/payment-duration.effects';
import { PettyCashSpecificationEffects } from 'lookups/store/effects/petty-cash-specification.effects';
import { PettyCashRefundRequestTypeEffects } from 'lookups/store/effects/petty-cash-refund-request-type.effects';
import { PettyCashTypeEffects } from 'lookups/store/effects/petty-cash-type.effects';
import { PurchaseOrderReceiveTypeEffects } from 'lookups/store/effects/purchase-order-receive-type.effects';
import { PurchaseOrderTypeEffects } from 'lookups/store/effects/purchase-order-type.effects';
import { PurchaseRequestReceiveTypeEffects } from 'lookups/store/effects/purchase-request-receive-type.effects';
import { PurchaseRequestTypeEffects } from 'lookups/store/effects/purchase-request-type.effects';
import { CustomerCategoryEffects } from 'lookups/store/effects/customer-category.effects';
import { PrimaryAccountsEffects } from 'lookups/store/effects/primary-accounts.effects';
import { OutgoingStockTypeEffects } from 'lookups/store/effects/outgoing-stock-type.effects';
import { CityEffects } from 'lookups/store/effects/city.effects';
import { IncomingStockTypeEffects } from 'lookups/store/effects/incoming-stock-type.effects';
import { JournalTypeEffects } from 'lookups/store/effects/journal-type.effects';
import { CustomerLogTypeEffects } from 'lookups/store/effects/customer-log-type.effects';
import { VendorLogTypeEffects } from 'lookups/store/effects/vendor-log-type.effects';
import { VendorPaymentLogTypeEffects } from 'lookups/store/effects/vendor-payment-log-type.effects';
import { VendorAdvancePaymentLogTypeEffects } from 'lookups/store/effects/vendor-advance-payment-log-type.effects';
import { CustomerPaymentLogTypeEffects } from 'lookups/store/effects/customer-payment-log-type.effects';
import { CustomerAdvancePaymentLogTypeEffects } from 'lookups/store/effects/customer-advance-payment-log-type.effects';
import { LocationTypeEffects } from 'lookups/store/effects/location-type.effects';
import { BankAccountLogTypeEffects } from 'lookups/store/effects/bank-account-log-type.effects';
import { SaleInvoiceTypeEffects } from 'lookups/store/effects/sale-invoice-type.effects';
import { SaleInvoiceOrderTypeEffects } from 'lookups/store/effects/sale-invoice-order-type.effects';
import { PurchaseInvoiceTypeEffects } from 'lookups/store/effects/purchase-invoice-type.effects';
import { MaintenanceStageEffects } from 'lookups/store/effects/maintenance-stage.effects';
import { VariantCategoryEffects } from 'lookups/store/effects/variant-category.effects';
import { TimeZoneEffects } from 'lookups/store/effects/time-zone.effects';
import { PrintPaperSizeEffects } from 'lookups/store/effects/print-paper-size.effects';
import { CountryEffects } from 'lookups/store/effects/country.effects';
import { PhoneTypeEffects } from 'lookups/store/effects/phone-type.effects';
import { EmailTypeEffects } from 'lookups/store/effects/email-type.effects';
import { MeridiemIndicatorEffects } from 'lookups/store/effects/meridiem-indicator.effects';
import { PersonTitleEffects } from 'lookups/store/effects/person-title.effects';
import { GenderEffects } from 'lookups/store/effects/gender.effects';
import { WorkTypeEffects } from 'lookups/store/effects/work-type.effects';
import { ContractTypeEffects } from 'lookups/store/effects/contract-type.effects';
import { ContractDurationEffects } from 'lookups/store/effects/contract-duration.effects';
import { ProbationPeriodEffects } from 'lookups/store/effects/probation-period.effects';
import { NoticePeriodEffects } from 'lookups/store/effects/notice-period.effects';
import { NationalIdentifierTypeEffects } from 'lookups/store/effects/national-identifier-type.effects';
import { MaritalStatusEffects } from 'lookups/store/effects/marital-status.effects';
import { ReligionEffects } from 'lookups/store/effects/religion.effects';
import { EducationLevelEffects } from 'lookups/store/effects/education-level.effects';
import { VisaTypeEffects } from 'lookups/store/effects/visa-type.effects';
import { DependentTypeEffects } from 'lookups/store/effects/dependent-type.effects';
import { LeadStatusEffects } from 'lookups/store/effects/lead-status.effects';
import { DepartmentTypeEffects } from 'lookups/store/effects/department-type.effects';
import { DepartmentCategoryEffects } from 'lookups/store/effects/department-category.effects';
import { ManagerialLevelEffects } from 'lookups/store/effects/managerial-level.effects';
import { PositionTypeEffects } from 'lookups/store/effects/position-type.effects';
import { AssignmentStatusEffects } from 'lookups/store/effects/assignment-status.effects';
import { EmployeePaymentMethodEffects } from 'lookups/store/effects/employee-payment-method.effects';
import { PayrollElementClassificationEffects } from 'lookups/store/effects/payroll-element-classification.effects';
import { PayrollElementTypeEffects } from 'lookups/store/effects/payroll-element-type.effects';
import { OvertimeRateEffects } from 'lookups/store/effects/overtime-rate.effects';
import { QuotationStatusEffects } from 'lookups/store/effects/quotation-status.effects';
import { AnnualLeaveEffects } from 'lookups/store/effects/annual-leave.effects';
import { SickLeaveTypeEffects } from 'lookups/store/effects/sick-leave-type.effects';
import { LoanSettlementTypeEffects } from 'lookups/store/effects/loan-settlement-type.effects';
import { DeductionTypeEffects } from 'lookups/store/effects/deduction-type.effects';
import { OtherEarningTypeEffects } from 'lookups/store/effects/other-earning-type.effects';
import { PayrollElementNatureEffects } from 'lookups/store/effects/payroll-element-nature.effects';
import { EmployeeDeductionTypeEffects } from 'lookups/store/effects/employee-deduction-type.effects';
import { LeaveTypeEffects } from 'lookups/store/effects/leave-type.effects';
import { GosiRegTypeEffects } from 'lookups/store/effects/gosi-reg-type.effects';
import { GosiCoverageTypeEffects } from 'lookups/store/effects/gosi-coverage-type.effects';
import { EndOfServiceReasonEffects } from 'lookups/store/effects/end-of-service-reason.effects';
import { AnnualLeaveLogTypeEffects } from 'lookups/store/effects/annual-leave-log-type.effects';
import { ProjectResourceTypeEffects } from 'lookups/store/effects/project-resource-type.effects';
import { ProjectResourceTypeSourceEffects } from 'lookups/store/effects/project-resource-type-source.effects';
import { ProjectStatusEffects } from 'lookups/store/effects/project-status.effects';
import { ProjectLogTypeEffects } from 'lookups/store/effects/project-log-type.effects';
import { ProjectRevenueTypeEffects } from 'lookups/store/effects/project-revenue-type.effects';
import { PayrollStatusEffects } from 'lookups/store/effects/payroll-status.effects';
import { PosPrinterAdjustmentTypeEffects } from 'lookups/store/effects/pos-printer-adjustment-type.effects';
import { LoanPaymentTypeEffects } from 'lookups/store/effects/loan-payment-type.effects';
import { GovServiceRequestStatusEffects } from 'lookups/store/effects/gov-service-request-statuses.effects';
import { GovServiceRequestPaymentMethodEffects } from 'lookups/store/effects/gov-service-request-payment-methods.effects';

export * from 'lookups/store/effects/asset-category.effects';
export * from 'lookups/store/effects/asset-exclusion-reason.effects';
export * from 'lookups/store/effects/asset-exclusion-reason.effects';
export * from 'lookups/store/effects/asset-years-of-use.effects';
export * from 'lookups/store/effects/customer-engagement-type.effects';
export * from 'lookups/store/effects/identity-type.effects';
export * from 'lookups/store/effects/nationality.effects';
export * from 'lookups/store/effects/payment-duration.effects';
export * from 'lookups/store/effects/petty-cash-specification.effects';
export * from 'lookups/store/effects/petty-cash-refund-request-type.effects';
export * from 'lookups/store/effects/petty-cash-type.effects';
export * from 'lookups/store/effects/purchase-order-receive-type.effects';
export * from 'lookups/store/effects/purchase-order-type.effects';
export * from 'lookups/store/effects/purchase-request-receive-type.effects';
export * from 'lookups/store/effects/purchase-request-type.effects';
export * from 'lookups/store/effects/primary-accounts.effects';
export * from 'lookups/store/effects/outgoing-stock-type.effects';
export * from 'lookups/store/effects/incoming-stock-type.effects';
export * from 'lookups/store/effects/city.effects';
export * from 'lookups/store/effects/journal-type.effects';
export * from 'lookups/store/effects/customer-log-type.effects';
export * from 'lookups/store/effects/vendor-log-type.effects';
export * from 'lookups/store/effects/vendor-payment-log-type.effects';
export * from 'lookups/store/effects/vendor-advance-payment-log-type.effects';
export * from 'lookups/store/effects/customer-payment-log-type.effects';
export * from 'lookups/store/effects/customer-advance-payment-log-type.effects';
export * from 'lookups/store/effects/location-type.effects';
export * from 'lookups/store/effects/bank-account-log-type.effects';
export * from 'lookups/store/effects/sale-invoice-type.effects';
export * from 'lookups/store/effects/sale-invoice-order-type.effects';
export * from 'lookups/store/effects/purchase-invoice-type.effects';
export * from 'lookups/store/effects/maintenance-stage.effects';
export * from 'lookups/store/effects/variant-category.effects';
export * from 'lookups/store/effects/time-zone.effects';
export * from 'lookups/store/effects/print-paper-size.effects';
export * from 'lookups/store/effects/country.effects';
export * from 'lookups/store/effects/phone-type.effects';
export * from 'lookups/store/effects/email-type.effects';
export * from 'lookups/store/effects/meridiem-indicator.effects';
export * from 'lookups/store/effects/person-title.effects';
export * from 'lookups/store/effects/gender.effects';
export * from 'lookups/store/effects/work-type.effects';
export * from 'lookups/store/effects/contract-type.effects';
export * from 'lookups/store/effects/contract-duration.effects';
export * from 'lookups/store/effects/probation-period.effects';
export * from 'lookups/store/effects/notice-period.effects';
export * from 'lookups/store/effects/national-identifier-type.effects';
export * from 'lookups/store/effects/marital-status.effects';
export * from 'lookups/store/effects/religion.effects';
export * from 'lookups/store/effects/education-level.effects';
export * from 'lookups/store/effects/visa-type.effects';
export * from 'lookups/store/effects/dependent-type.effects';
export * from 'lookups/store/effects/lead-status.effects';
export * from 'lookups/store/effects/department-type.effects';
export * from 'lookups/store/effects/department-category.effects';
export * from 'lookups/store/effects/managerial-level.effects';
export * from 'lookups/store/effects/position-type.effects';
export * from 'lookups/store/effects/assignment-status.effects';
export * from 'lookups/store/effects/employee-payment-method.effects';
export * from 'lookups/store/effects/payroll-element-classification.effects';
export * from 'lookups/store/effects/payroll-element-type.effects';
export * from 'lookups/store/effects/overtime-rate.effects';
export * from 'lookups/store/effects/quotation-status.effects';
export * from 'lookups/store/effects/annual-leave.effects';
export * from 'lookups/store/effects/sick-leave-type.effects';
export * from 'lookups/store/effects/loan-settlement-type.effects';
export * from 'lookups/store/effects/deduction-type.effects';
export * from 'lookups/store/effects/other-earning-type.effects';
export * from 'lookups/store/effects/payroll-element-nature.effects';
export * from 'lookups/store/effects/employee-deduction-type.effects';
export * from 'lookups/store/effects/leave-type.effects';
export * from 'lookups/store/effects/gosi-reg-type.effects';
export * from 'lookups/store/effects/gosi-coverage-type.effects';
export * from 'lookups/store/effects/end-of-service-reason.effects';
export * from 'lookups/store/effects/annual-leave-log-type.effects';
export * from 'lookups/store/effects/project-resource-type.effects';
export * from 'lookups/store/effects/project-resource-type-source.effects';
export * from 'lookups/store/effects/project-status.effects';
export * from 'lookups/store/effects/project-log-type.effects';
export * from 'lookups/store/effects/project-revenue-type.effects';
export * from 'lookups/store/effects/payroll-status.effects';
export * from 'lookups/store/effects/pos-printer-adjustment-type.effects';
export * from 'lookups/store/effects/loan-payment-type.effects';
export * from 'lookups/store/effects/gov-service-request-statuses.effects';
export * from 'lookups/store/effects/gov-service-request-payment-methods.effects';

/**
 * The Lookups-Module store effects.
 */
export const effects: any[] = [
  AssetCategoryEffects,
  AssetTypeEffects,
  AssetExclusionReasonEffects,
  AssetYearsOfUseEffects,
  CustomerEngagementTypeEffects,
  IdentityTypeEffects,
  NationalityEffects,
  PaymentDurationEffects,
  PettyCashSpecificationEffects,
  PettyCashTypeEffects,
  PettyCashRefundRequestTypeEffects,
  PurchaseOrderReceiveTypeEffects,
  PurchaseOrderTypeEffects,
  PurchaseRequestReceiveTypeEffects,
  PurchaseRequestTypeEffects,
  CustomerCategoryEffects,
  PrimaryAccountsEffects,
  OutgoingStockTypeEffects,
  CityEffects,
  IncomingStockTypeEffects,
  JournalTypeEffects,
  CustomerLogTypeEffects,
  VendorLogTypeEffects,
  VendorPaymentLogTypeEffects,
  VendorAdvancePaymentLogTypeEffects,
  CustomerPaymentLogTypeEffects,
  CustomerAdvancePaymentLogTypeEffects,
  LocationTypeEffects,
  BankAccountLogTypeEffects,
  SaleInvoiceTypeEffects,
  SaleInvoiceOrderTypeEffects,
  PurchaseInvoiceTypeEffects,
  MaintenanceStageEffects,
  VariantCategoryEffects,
  TimeZoneEffects,
  PrintPaperSizeEffects,
  CountryEffects,
  PhoneTypeEffects,
  EmailTypeEffects,
  MeridiemIndicatorEffects,
  PersonTitleEffects,
  GenderEffects,
  WorkTypeEffects,
  ContractTypeEffects,
  ContractDurationEffects,
  ProbationPeriodEffects,
  NoticePeriodEffects,
  NationalIdentifierTypeEffects,
  MaritalStatusEffects,
  ReligionEffects,
  EducationLevelEffects,
  VisaTypeEffects,
  DependentTypeEffects,
  LeadStatusEffects,
  DepartmentTypeEffects,
  DepartmentCategoryEffects,
  ManagerialLevelEffects,
  PositionTypeEffects,
  AssignmentStatusEffects,
  EmployeePaymentMethodEffects,
  PayrollElementClassificationEffects,
  PayrollElementTypeEffects,
  OvertimeRateEffects,
  QuotationStatusEffects,
  AnnualLeaveEffects,
  SickLeaveTypeEffects,
  LoanSettlementTypeEffects,
  DeductionTypeEffects,
  OtherEarningTypeEffects,
  PayrollElementNatureEffects,
  EmployeeDeductionTypeEffects,
  LeaveTypeEffects,
  GosiRegTypeEffects,
  GosiCoverageTypeEffects,
  EndOfServiceReasonEffects,
  AnnualLeaveLogTypeEffects,
  ProjectResourceTypeEffects,
  ProjectResourceTypeSourceEffects,
  ProjectStatusEffects,
  ProjectLogTypeEffects,
  ProjectRevenueTypeEffects,
  PayrollStatusEffects,
  PosPrinterAdjustmentTypeEffects,
  LoanPaymentTypeEffects,
  GovServiceRequestStatusEffects,
  GovServiceRequestPaymentMethodEffects,
];
