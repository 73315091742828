import { Location } from 'stores/models';
import { BankAccount, CostCenter } from 'finances/models';
import { ApiKey, User } from 'security/models';

/**
 * Represents a user session state.
 */
export class SessionState {
  /**
   * The currently logged in user;
   */
  public user: User;

  /**
   * The api key.
   */
  public apiKey: ApiKey;

  /**
   * The list of user's locations.
   */
  public userLocations: Location[];

  /**
   * The list of user's cost centers.
   */
  public userCostCenters: CostCenter[];

  /**
   * The list of user's bank accounts.
   */
  public userBankAccounts: BankAccount[];

  /**
   * An error that may resulting during processing some actions.
   */
  public error: any;

  /**
   * Determines if there is a running log-in process.
   */
  public isLogging: boolean;

  /**
   * Determines if the user is logged-in.
   */
  public isLoggedIn: boolean;

  /**
   * Determines if there is a running fetch-user-data process.
   */
  public isFetchingUserData: boolean;

  /**
   * Determines if there is a running refresh-token process.
   */
  public isRefreshingToken: boolean;

  /**
   * Determines if the last refresh-token action was completed successfully.
   */
  public isTokenRefreshed: boolean;

  /**
   * Determines if there is a running refresh-user-data process.
   */
  public isRefreshingUserData: boolean;

  /**
   * Determines if there is a running process.
   */
  public isGettingUserLocations: boolean;

  /**
   * Determines if the last process has been ended successfully.
   */
  public isGetUserLocationsCompleted: boolean;

  /**
   * Determines if there is a running process.
   */
  public isGettingUserBankAccounts: boolean;

  /**
   * Determines if the last process has been ended successfully.
   */
  public isGetUserBankAccountsCompleted: boolean;

  /**
   * Determines if there is a running process.
   */
  public isGettingUserCostCenters: boolean;

  /**
   * Determines if the last process has been ended successfully.
   */
  public isGetUserCostCentersCompleted: boolean;

  /**
   * Determines if there is a running update-user-profile-info/credentials process.
   */
  public isUpdatingProfile: boolean;

  /**
   * Indicates whether the app will remember user's token if the user closed the app.
   */
  public rememberUser: boolean;

  /**
   * Determines if there is a running update-user-language.
   */
  public isUpdatingUserLanguage: boolean;

  /**
   * Determines if there is a running update process.
   */
  public isUserDefaultLocationUpdating: boolean;

  /**
   * Determines if the last update process has been ended successfully.
   */
  public isUserDefaultLocationUpdateCompleted: boolean;

  /**
   * Determines if there is a running update process.
   */
  public isUserDefaultCostCenterUpdating: boolean;

  /**
   * Determines if the last update process has been ended successfully.
   */
  public istUserDefaultCostCenterUpdateCompleted: boolean;

  /**
   * Determines if there is a running update process.
   */
  public isUserDefaultCashAccountUpdating: boolean;

  /**
   * Determines if the last update process has been ended successfully.
   */
  public isUserDefaultCashAccountUpdateCompleted: boolean;

  /**
   * Determines if there is a running update process.
   */
  public isUserDefaultElectronicAccountUpdating: boolean;

  /**
   * Determines if the last update process has been ended successfully.
   */
  public isUserDefaultElectronicAccountUpdateCompleted: boolean;

  /**
   * Determines if there is a running update process.
   */
  public isUserDefaultPosDeviceUpdating: boolean;

  /**
   * Determines if the last update process has been ended successfully.
   */
  public isUserDefaultPosDeviceUpdateCompleted: boolean;

  /**
   * Determines if there is a running process.
   */
  public isGettingApiKey: boolean;

  /**
   * Determines if the last process has been ended successfully.
   */
  public isGetApiKeyCompleted: boolean;

  /**
   * Determines if there is a running update process.
   */
  public isApiKeyUpdating: boolean;

  /**
   * Determines if the last update process has been ended successfully.
   */
  public isApiKeyUpdateCompleted: boolean;
}
