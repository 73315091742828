import { Action } from '@ngrx/store';

import { PageState } from 'core/store/states';

/**
 * The store app action types.
 */
export enum AppActionType {
  APP_START_LOAD = '[Core] App Started Loading',
  APP_END_LOAD = '[Core] App Stopped Loading',
  CHANGE_PAGE_INFO = '[Core] Change Current Page Info',
}

/**
 * Represents a store app-start-load action against for an http-request.
 */
export class AppStatusStartLoad implements Action {
  /**
   * The type of the action.
   */
  readonly type = AppActionType.APP_START_LOAD;
}

/**
 * Represents a store app-end-load action against for an http-request.
 */
export class AppStatusEndLoad implements Action {
  /**
   * The type of the action.
   */
  readonly type = AppActionType.APP_END_LOAD;
}

/**
 * Represents a store change-page-info.
 */
export class ChangePageInfo implements Action {
  /**
   * The type of the action.
   */
  readonly type = AppActionType.CHANGE_PAGE_INFO;

  /**
   * Fires a new change-page-info action.
   * @param payload The new page information.
   */
  constructor(public payload: PageState) {}
}

/**
 * Core-module App action types.
 */
export type AppActions = AppStatusStartLoad | AppStatusEndLoad | ChangePageInfo;
