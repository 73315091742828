import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';

import { Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { tap } from 'rxjs/operators';

import { NotificationService, PageInfo, PaginationInfo, TranslationService } from 'shared';
import * as fromFinancesStore from 'finances/store';
import { PettyCashRefundRequest, PettyCashRefundRequestApproval } from 'finances/models';
import { Claims } from 'security/models';

@Component({
  selector: 'app-petty-cash-refund-requests-approvals-list',
  templateUrl: './petty-cash-refund-requests-approvals-list.component.html',
  styles: [],
})
export class PettyCashRefundRequestsApprovalsListComponent implements OnInit {
  /**
   * Gets or sets the information about the current page.
   */
  pageInfo: PageInfo = {
    title: 'FINANCES.PETTY_CASH_REFUND_REQUEST_APPROVALS.PETTY_CASH_REFUND_REQUEST_APPROVALS',
    icon: 'fa fa-get-pocket',
  };

  /**
   * The system supported user claims.
   */
  Claims = Claims;

  /**
   * The list of petty cash refund request approvals.
   */
  pettyCashRefundRequestApprovals$: Observable<PettyCashRefundRequestApproval[]>;

  /**
   * The list of selected petty cash refund request.
   */
  pettyCashRefundRequest: PettyCashRefundRequest[] = [];

  /**
   * The pagination info.
   */
  paginationInfo$: Observable<PaginationInfo>;

  /**
   * Indicates whether there is a search process is running or not.
   */
  isSearching$: Observable<boolean>;

  /**
   * The initial `fromDate` value for search.
   */
  initialFromDate = new Date();

  /**
   * The initial `toDate` value for search.
   * It's the current day of month.
   */
  initialToDate = new Date();

  /**
   * The search form.
   */
  searchForm: FormGroup;

  /**
   * Shows or hide the petty cash refund request list.
   */
  pettyCashRefundRequestListVisibility = false;

  /**
   *
   * @param financesStore$ The finances store.
   * @param notificationService The  notification service.
   * @param translationService The translation service.
   */
  constructor(
    private financesStore$: Store<fromFinancesStore.FinancesState>,
    private notificationService: NotificationService,
    private translationService: TranslationService
  ) {}

  ngOnInit(): void {
    this.init();
  }

  /**
   * Initialize component data.
   */
  init() {
    this.initForm();

    /**
     * Load data.
     */

    let isManualSearchTriggeredBeforeForRequestApprovals = false;
    this.pettyCashRefundRequestApprovals$ = this.financesStore$.pipe(
      select(fromFinancesStore.getPettyCashRefundRequestApprovals),
      tap((pettyCashRefundRequestApprovals) => {
        if (!isManualSearchTriggeredBeforeForRequestApprovals && !pettyCashRefundRequestApprovals.length) {
          isManualSearchTriggeredBeforeForRequestApprovals = true;
          this.search();
        }
      })
    );

    this.paginationInfo$ = this.financesStore$.pipe(
      select(fromFinancesStore.getPettyCashRefundRequestApprovalsPaginationInfo)
    );
    this.isSearching$ = this.financesStore$.pipe(select(fromFinancesStore.getPettyCashRefundRequestApprovalsSearching));
  }

  /**
   * Initialize form and add validators.
   */
  initForm() {
    this.searchForm = new FormGroup({
      pettyCashRefundRequests: new FormControl([]),
      fromDate: new FormControl({
        year: this.initialFromDate.getFullYear(),
        month: this.initialFromDate.getMonth() + 1,
        day: this.initialFromDate.getDate(),
      }),
      toDate: new FormControl({
        year: this.initialToDate.getFullYear(),
        month: this.initialToDate.getMonth() + 1,
        day: this.initialToDate.getDate(),
      }),
      page: new FormControl(1),
    });
  }

  /**
   * Handles search parameters change.
   */
  search(event?: KeyboardEvent) {
    if (event && event.key === 'Enter') {
      event.preventDefault();
      return;
    }

    this.applyFiltersAndSearch();
  }

  /**
   * Handles pagination page-changed event.
   * @param page The current selected page number.
   */
  pageChanged(page: number) {
    /** Update pagination page. */
    this.searchForm.patchValue({ page });

    this.applyFiltersAndSearch(page);
  }

  /**
   * Applies the search filters and dispatches a search action.
   * @param page The current page number, default to `1`.
   */
  applyFiltersAndSearch(page: number = 1) {
    if (!this.searchForm.get('fromDate')?.value || !this.searchForm.get('toDate')?.value) {
      return this.notificationService.warning(
        this.translationService.translate(
          'FINANCES.PETTY_CASH_REFUND_REQUEST_APPROVALS.NOTIFICATION_MESSAGE.SELECT_DATE'
        )
      );
    }

    const fromDate = new Date(
      this.searchForm.get('fromDate').value.year,
      this.searchForm.get('fromDate').value.month - 1,
      this.searchForm.get('fromDate').value.day
    );
    fromDate.setHours(0, 0, 0, 0);

    const toDate = new Date(
      this.searchForm.get('toDate').value.year,
      this.searchForm.get('toDate').value.month - 1,
      this.searchForm.get('toDate').value.day
    );
    toDate.setHours(23, 59, 59, 0);
    this.financesStore$.dispatch(
      new fromFinancesStore.SearchPettyCashRefundRequestApprovals({
        pettyCashRefundRequests: this.searchForm.get('pettyCashRefundRequests').value,
        fromDate,
        toDate,
        page,
      })
    );
  }

  /**
   * Enables trackBy feature for *ngFor utility to track items
   * depending on a comparer to reduce DOM change.
   * If the item is already exist then no HTML will change, only bounded values.
   * @param index The index of the item.
   * @param pettyCashRefundRequestApproval The item to determine if it was changed or not.
   */
  trackItems(index: number, pettyCashRefundRequestApproval: PettyCashRefundRequestApproval) {
    return pettyCashRefundRequestApproval ? pettyCashRefundRequestApproval.id : undefined;
  }

  /**
   * Adds the newly selected petty cash the petty cash refund request search list.
   * @param pettyCashRefundRequest The list of newly selected petty cash refund request to be added.
   */
  selectPettyCashRefundRequest(pettyCashRefundRequest: PettyCashRefundRequest[]) {
    const selectedIds: number[] = this.searchForm.get('pettyCashRefundRequests').value;
    this.pettyCashRefundRequest = [
      ...this.pettyCashRefundRequest.filter((request) => selectedIds.includes(request.id)),
      ...pettyCashRefundRequest,
    ];
    this.searchForm.patchValue({ pettyCashRefundRequests: this.pettyCashRefundRequest.map((request) => request.id) });
  }
}
