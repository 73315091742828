import { createSelector } from '@ngrx/store';

import { AppSearchState, UsersState } from 'app-search/store/states';
import { selectAppSearchState } from 'app-search/store/selectors/feature.selectors';

/* Selectors */

/**
 * Gets the users state.
 */
const selectUsersState = createSelector(selectAppSearchState, (state: AppSearchState) => state.users);

/**
 * Gets the list of loaded users.
 */
export const getUsers = createSelector(selectUsersState, (state: UsersState) => state.data);

/**
 * Gets the users pagination info.
 */
export const getUsersPaginationInfo = createSelector(selectUsersState, (state: UsersState) => state.paginationInfo);

/**
 * Gets the error that occurred in the state.
 */
export const getUsersError = createSelector(selectUsersState, (state: UsersState) => state.error);

/**
 * Determines if the last process has been ended successfully.
 */
export const getOrganizationUsersCompleted = createSelector(
  selectUsersState,
  (state: UsersState) => state.isGetOrganizationUsersCompleted
);

/**
 * Determines if there is a running process.
 */
export const getOrganizationUsersSearching = createSelector(
  selectUsersState,
  (state: UsersState) => state.isGettingOrganizationUsers
);
