<div class="row">
  <div class="col-md-12">
    <!-- project resource payrolls list starts -->
    <div class="card-header mb-3">
      <h4 class="card-title">{{ 'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.PAYROLLS_DATA_TITLE' | translate }}</h4>
    </div>
    <div class="card-content">
      <div class="px-3">
        <div class="row">
          <div class="col-md-12">
            <app-payrolls-list
              [projectResourceLines]="projectResourceLines$ | async"
              [displayDeleteButton]="false"
              [isEmployeeManagement]="true"
            ></app-payrolls-list>
          </div>
        </div>
      </div>
    </div>
    <!-- project resource payrolls list ends -->
  </div>
</div>
