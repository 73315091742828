import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';

import { Observable } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { tap } from 'rxjs/operators';

import { PageInfo, PaginationInfo } from 'shared';
import * as fromFinancesStore from 'finances/store';
import { Account } from 'finances/models';
import { Claims } from 'security/models';

@Component({
  selector: 'app-accounts-list',
  templateUrl: './accounts-list.component.html',
  styles: [],
})
export class AccountsListComponent implements OnInit {
  /**
   * Gets or sets the information about the current page.
   */
  pageInfo: PageInfo = {
    title: 'FINANCES.ACCOUNTS.ACCOUNTS_LIST',
    icon: 'icon-chart'
  };

  /**
   * The system supported user claims.
   */
  Claims = Claims;

  /**
   * The list of accounts.
   */
  accounts$: Observable<Account[]>;

  /**
   * Indicates whether there is a delete-account process is running or not.
   */
  isDeleting$: Observable<boolean>;

  /**
   * The pagination info.
   */
  paginationInfo$: Observable<PaginationInfo>;

  /**
   * The search form.
   */
  searchForm: FormGroup;

  /**
   * Gets or sets the currently selected account for delete.
   */
  selectedAccount: Account;

  /**
   * @param modalService The modal service.
   * @param financesStore$ The finances store.
   */
  constructor(private modalService: NgbModal, private financesStore$: Store<fromFinancesStore.FinancesState>) {}

  ngOnInit(): void {
    this.init();
  }

  /**
   * Initialize component data.
   */
  init() {
    this.initForm();

    /**
     * Load data.
     */
    this.isDeleting$ = this.financesStore$.pipe(select(fromFinancesStore.getSelectedAccountDeleting));

    let isManualSearchTriggeredBeforeForAccounts = false;
    this.accounts$ = this.financesStore$.pipe(
      select(fromFinancesStore.getAccounts),
      tap((accounts)=>{
        if (!isManualSearchTriggeredBeforeForAccounts && !accounts.length) {
          isManualSearchTriggeredBeforeForAccounts = true;
          this.search();
      }
    })
  );

    this.paginationInfo$ = this.financesStore$.pipe(select(fromFinancesStore.getAccountsPaginationInfo));
  }

  /**
   * Initialize form and add validators.
   */
  initForm() {
    this.searchForm = new FormGroup({
      description: new FormControl(''),
      page: new FormControl(1),
    });
  }

  /**
   * Handles search parameters change.
   */
  search(event?: KeyboardEvent) {
    if (event && event.key === 'Enter') {
      event.preventDefault();
      return;
    }

    this.applyFiltersAndSearch();
  }

  /**
   * Handles pagination page-changed event.
   * @param page The current selected page number.
   */
  pageChanged(page: number) {
    /** Update pagination page. */
    this.searchForm.patchValue({ page });

    this.applyFiltersAndSearch(page);
  }

  /**
   * Enables trackBy feature for *ngFor utility to track elements
   * depending on a comparer to reduce DOM change.
   * If the element is already exist then no HTML will change, only bounded values.
   * @param element The element to determine if it was changed or not.
   */
  trackElements(element: Account) {
    return element ? element.id : undefined;
  }

  applyFiltersAndSearch(page: number = 1) {
    const { description } = this.searchForm.value;
    this.financesStore$.dispatch(
      new fromFinancesStore.SearchAccounts({
        description,
        page,
      })
    );
  }

  /**
   * Deletes the account with the given id.
   * @param accountId The id of the account to be deleted.
   */
  delete(accountId: number) {
    this.financesStore$.dispatch(new fromFinancesStore.DeleteAccount(accountId));
  }

  /**
   * Opens the modal of the given templateRef.
   * @param modalRef The modal templateRef to be opened.
   */
  openModal(modalRef) {
    this.modalService.open(modalRef);
  }
}
