/**
 * The pagination page-size for each entity search query.
 */
export enum PAGINATION {
  /**
   * The pagination page-size for user entity search.
   */
  Users = 100,

  /**
   * The pagination page-size for organization entity search.
   */
  Organizations = 100,

  /**
   * The pagination page-size for product entity search.
   */
  Products = 100,

  /**
   * The pagination page-size for product sales discounts entity search.
   */
  ProductSalesDiscounts = 100,

  /**
   * The pagination page-size for Location entity search.
   */
  Locations = 100,

  /**
   * The pagination page-size for organization Location entity search.
   */
  OrganizationLocations = 100,

  /**
   * The pagination page-size for vendor entity search.
   */
  Vendors = 100,

  /**
   * The pagination page-size for purchaseRequests entity search.
   */
  PurchaseRequests = 100,

  /**
   * The pagination page-size for purchaseOrders entity search.
   */
  PurchaseOrders = 100,

  /**
   * The pagination page-size for purchaseInvoices entity search.
   */
  PurchaseInvoices = 100,

  /**
   * The pagination page-size for unpaid purchase invoices entity search.
   */
  UnpaidPurchaseInvoices = 100,

  /**
   * The pagination page-size for customers entity search.
   */
  Customers = 100,

  /**
   * The pagination page-size for salesInvoices entity search.
   */
  SaleInvoices = 100,

  /**
   * The pagination page-size for customerEngagements entity search.
   */
  CustomerEngagements = 100,

  /**
   * The pagination page-size for taxes entity search.
   */
  Taxes = Number.MAX_SAFE_INTEGER,

  /**
   * The pagination page-size for vendor payments entity search.
   */
  VendorPayments = 100,

  /**
   * The pagination page-size for customer payments entity search.
   */
  CustomerPayments = 100,

  /**
   * The pagination page-size for payroll elements entity search.
   */
  PayrollElements = 100,

  /**
   * The pagination page-size for recurring payroll elements entity search.
   */
  RecurringPayrollElements = 100,

  /**
   * The pagination page-size for non recurring earnings payroll elements entity search.
   */
  NonRecurringEarningsPayrollElements = 100,

  /**
   * The pagination page-size for non recurring deductions payroll elements entity search.
   */
  NonRecurringDeductionsPayrollElements = 100,

  /**
   * The pagination page-size for employees entity search.
   */
  Employees = 100,

  /**
   * The pagination page-size for assets entity search.
   */
  Assets = 100,

  /**
   * The pagination page-size for assets entity search.
   */
  AssetsProcessing = 100,

  /**
   * The pagination page-size for assets sales entity search.
   */
  AssetsSales = 100,

  /**
   * The pagination page-size for assets depreciations entity search.
   */
  AssetsDepreciations = 100,

  /**
   * The pagination page-size for excluded assets entity search.
   */
  ExcludedAssets = 100,

  /**
   * The pagination page-size for employee reward and deductions entity search.
   */
  EmployeeRewardAndDeductions = 100,

  /**
   * The pagination page-size for cost centers entity search.
   */
  CostCenters = 100,

  /**
   * The pagination page-size for petty Cash entity search.
   */
  PettyCash = 100,

  /**
   * The pagination page-size for petty cash refund requests entity search.
   */
  PettyCashRefundRequests = 100,

  /**
   * The pagination page-size for petty cash refund entity search.
   */
  PettyCashRefund = 100,

  /**
   * The pagination page-size for petty cash reduction entity search.
   */
  PettyCashReduction = 100,

  /**
   * The pagination page-size for accounts entity search.
   */
  Accounts = 200,

  /**
   * The pagination page-size for banks entity search.
   */
  Banks = 100,

  /**
   * The pagination page-size for journals entity search.
   */

  Journals = 100,

  /**
   * The pagination page-size for employee payrolls entity search.
   */
  EmployeePayrolls = 100,

  /**
   * The pagination page-size for incoming stocks entity search.
   */
  IncomingStocks = 100,

  /**
   * The pagination page-size for outgoing stocks entity search.
   */
  OutgoingStocks = 100,

  /**
   * The pagination page-size for transfer stocks entity search.
   */
  TransferStocks = 100,

  /**
   * The pagination page-size for product classes entity search.
   */
  ProductClasses = 100,

  /**
   * The pagination page-size for product measure types entity search.
   */
  ProductMeasureTypes = 100,

  /**
   * The pagination page-size for customer log entity search.
   */
  CustomerLog = 100,

  /**
   * The pagination page-size for vendor log entity search.
   */
  VendorLog = 100,

  /**
   * The pagination page-size for purchase returns log entity search.
   */
  PurchaseReturns = 100,

  /**
   * The pagination page-size for sale returns entity search.
   */
  SaleReturns = 100,

  /**
   * The pagination page-size for salary closings entity search.
   */
  SalaryClosings = 100,

  /**
   * The pagination page-size for bank account logs entity search.
   */
  BankAccountLogs = 100,

  /**
   * The pagination page-size for bank accounts entity search.
   */
  BankAccounts = 100,

  /**
   * The pagination page-size for customer assets entity search.
   */
  CustomerAssets = 100,

  /**
   * The pagination page-size for customers assets entity search.
   */
  CustomersAssets = 100,

  /**
   * The pagination page-size for my shifts entity search.
   */
  MyShifts = 100,

  /**
   * The pagination page-size for all shifts entity search.
   */
  AllShifts = 100,

  /**
   * The pagination page-size for financial periods entity search.
   */
  FinancialPeriods = 100,

  /**
   * The pagination page-size for currencies entity search.
   */
  Currencies = 100,

  /**
   * The pagination page-size for currency exchange rate entity search.
   */
  CurrencyExchangeRate = 100,

  /**
   * The pagination page-size for unit of measure class entity search.
   */
  UnitOfMeasureClass = 100,

  /**
   * The pagination page-size for unit of measure entity search.
   */
  UnitOfMeasure = 100,

  /**
   * The pagination page-size for unit of measure rate entity search.
   */
  UnitOfMeasureRate = 100,

  /**
   * The pagination page-size for variants entity search.
   */
  Variants = 100,

  /**
   * The pagination page-size for boards entity search.
   */
  Boards = 100,

  /**
   * The pagination page-size for point of sales devices entity search.
   */
  PosDevices = 100,

  /**
   * The pagination page-size for point of sales printers entity search.
   */
  PosPrinters = 100,

  /**
   * The pagination page-size for kitchen printers entity search.
   */
  KitchenPrinters = 100,

  /**
   * The pagination page-size for casher printers entity search.
   */
  CasherPrinters = 100,

  /**
   * The pagination page-size for order delivery printers entity search.
   */
  OrderDeliveryPrinters = 100,

  /**
   * The pagination page-size for leads entity search.
   */
  Leads = 100,

  /**
   * The pagination page-size for grades
   *  entity search.
   */
  Grade = 100,

  /**
   * The pagination page-size for jobs
   *  entity search.
   */
  Job = 100,

  /**
   * The pagination page-size for job families
   *  entity search.
   */
  JobFamily = 100,

  /**
   * The pagination page-size for grade steps
   *  entity search.
   */
  GradeStep = 100,

  /**
   * The pagination page-size for grade levels
   *  entity search.
   */
  GradeLevel = 100,

  /**
   * The pagination page-size for departments entity search.
   */
  Departments = 100,

  /**
   * The pagination page-size for positions entity search.
   */
  Positions = 100,

  /**
   * The pagination page-size for managers entity search.
   */
  Managers = 100,

  /**
   * The pagination page-size for employee overtimes entity search.
   */
  EmployeeOvertimes = 100,

  /**
   * The pagination page-size for overtimes entity search.
   */
  Overtimes = 100,

  /**
   * The pagination page-size for quotations entity search.
   */
  Quotations = 100,

  /**
   * The pagination page-size for lead quotation entity search.
   */
  LeadQuotation = 100,

  /**
   * The pagination page-size for sick leave entity search.
   */
  SickLeaves = 100,

  /**
   * The pagination page-size for employee sick leaves entity search.
   */
  EmployeeSickLeaves = 100,

  /**
   * The pagination page-size for employee sick leave plans entity search.
   */
  EmployeeSickLeavePlans = 100,

  /**
   * The pagination page-size for employee loans entity search.
   */
  EmployeeLoans = 100,

  /**
   * The pagination page-size for employee location transfers entity search.
   */
  EmployeeLocationTransfers = 100,

  /**
   * The pagination page-size for employee project transfers entity search.
   */
  EmployeeProjectTransfers = 100,

  /**
   * The pagination page-size for employee annual leave entity search.
   */
  annualLeaves = 100,

  /**
   * The pagination page-size for  unpaid leave entity search.
   */
  unpaidLeaves = 100,

  /**
   * The pagination page-size for  paid leave entity search.
   */
  paidLeaves = 100,

  /**
   * The pagination page-size for employee annual leaves opening balance entity search.
   */
  annualLeavesOpeningBalance = 100,

  /**
   * The pagination page-size for employee annual leaves adjustment entity search.
   */
  annualLeavesAdjustment = 100,

  /**
   * The pagination page-size for employee loan settlements entity search.
   */
  EmployeeLoanSettlements = 100,

  /**
   * The pagination page-size for deductions entity search.
   */
  Deductions = 100,

  /**
   * The pagination page-size for other earnings entity search.
   */
  OtherEarnings = 100,

  /**
   * The pagination page-size for employee deductions entity search.
   */
  EmployeeDeductions = 100,

  /**
   * The pagination page-size for employee other earnings entity search.
   */
  EmployeeOtherEarnings = 100,

  /**
   * The pagination page-size for employee management  sick leave plans entity search.
   */
  EmployeeManagementSickLeavePlans = 100,

  /**
   * The pagination page-size for employee annual leaves entity search.
   */
  EmployeeAnnualLeaves = 100,

  /**
   * The pagination page-size for employee annual leaves opening balance entity search.
   */
  EmployeeAnnualLeavesOpeningBalance = 100,

  /**
   * The pagination page-size for employee annual leaves adjustment entity search.
   */
  EmployeeAnnualLeavesAdjustment = 100,

  /**
   * The pagination page-size for employee paid leaves entity search.
   */
  EmployeePaidLeaves = 100,

  /**
   * The pagination page-size for employee unpaid leaves entity search.
   */
  EmployeeUnpaidLeaves = 100,

  /**
   * The pagination page-size for employee leaves entity search.
   */
  EmployeeLeaves = 100,

  /**
   * The pagination page-size for leaves entity search.
   */
  leaves = 100,

  /**
   * The pagination page-size for annual leave logs entity search.
   */
  annualLeaveLogs = 100,

  /**
   * The pagination page-size for employee sponsors entity search.
   */
  employeeSponsors = 100,

  /**
   * The pagination page-size for employee annual leaves log entity search.
   */
  employeeAnnualLeavesLog = 100,

  /**
   * The pagination page-size for payrolls entity search.
   */
  payrolls = 100,

  /**
   * The pagination page-size for all payrolls entity search.
   */
  allPayrolls = 100,

  /**
   * The pagination page-size for approved payrolls entity search.
   */
  approvedPayrolls = 100,

  /**
   * The pagination page-size for employee payrolls entity search.
   */
  employeePayrolls = 100,

  /**
   * The pagination page-size for all employee payrolls entity search.
   */
  allEmployeePayrolls = 100,

  /**
   * The pagination page-size for approved employee payrolls entity search.
   */
  approvedEmployeePayrolls = 100,

  /**
   * The pagination page-size for paid employee payrolls entity search.
   */
  paidEmployeePayrolls = 100,

  /**
   * The pagination page-size for paid payrolls entity search.
   */
  paidPayrolls = 100,

  /**
   * The pagination page-size for payroll payments entity search.
   */
  payrollPayments = 100,

  /**
   * The pagination page-size for payroll payment refunds entity search.
   */
  payrollPaymentRefunds = 100,

  /**
   * The pagination page-size for projects entity search.
   */
  Projects = 100,

  /**
   * The pagination page-size for project logs entity search.
   */
  ProjectLogs = 100,

  /**
   * The pagination page-size for project status logs entity search.
   */
  ProjectStatusLogs = 100,

  /**
   * The pagination page-size for employee status logs entity search.
   */
  EmployeeStatusLogs = 100,

  /**
   * The pagination page-size for main tasks entity search.
   */
  MainTasks = 100,

  /**
   * The pagination page-size for sub tasks entity search.
   */
  SubTasks = 100,

  /**
   * The pagination page-size for project resources entity search.
   */
  ProjectResources = 100,

  /**
   * The pagination page-size for project revenues entity search.
   */
  ProjectRevenues = 100,

  /**
   * The pagination page-size for sales persons entity search.
   */
  SalesPersons = 100,

  /**
   * The pagination page-size for asset location transfers entity search.
   */
  AssetLocationTransfers = 100,

  /**
   * The pagination page-size for asset project transfers entity search.
   */
  AssetProjectTransfers = 100,

  /**
   * The pagination page-size for gov services entity search.
   */
  Services = 100,

  /**
   * The pagination page-size for gov service requests entity search.
   */
  GovServiceRequests = 100,

  /**
   * The pagination page-size for regions entity search.
   */
  Regions = 100,

  /**
   * The pagination page-size for sectors entity search.
   */
  Sectors = 100,
}
