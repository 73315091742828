import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';

import { AppHttpResponse } from 'shared';
import { Quotation, CreateQuotationInput } from 'crm/models';

/**
 * The quotations services includes functionality to create, search and findById for a quotation
 */
@Injectable()
export class QuotationsService {
  /**
   * The relative route for the quotations.
   *
   * No leading or trailing slashes required.
   */
  private quotationsApi = 'crm/quotations';

  constructor(private http: HttpClient) {}

  /**
   * Creates a new quotation from the provided data.
   * @param data The new quotation data.
   */
  public create(data: CreateQuotationInput): Observable<AppHttpResponse<Quotation>> {
    const formData: any = new FormData();
    formData.append('leadId', data.leadId);
    formData.append('notes', data.notes);

    /**
     * Append products to the form data.
     */
    for (let index = 0; index < data.products.length; index++) {
      formData.append(`products[${index}][productId]`, data.products[index].productId);
      formData.append(`products[${index}][subProductId]`, data.products[index].subProductId);
      formData.append(`products[${index}][description]`, data.products[index].description);
      formData.append(`products[${index}][quantity]`, data.products[index].quantity);
      formData.append(`products[${index}][unitOfMeasureId]`, data.products[index].unitOfMeasureId);
      formData.append(`products[${index}][value]`, data.products[index].value);
      formData.append(`products[${index}][discount]`, data.products[index].discount);
      formData.append(`products[${index}][discountPercent]`, data.products[index].discountPercent);
      formData.append(`products[${index}][tax]`, data.products[index].tax);
      formData.append(`products[${index}][notes]`, data.products[index].notes);
    }
    /**
     * Append attachments to the form data.
     */
    data.attachments.forEach(
      (attachment) => attachment && formData.append(`attachments[]`, attachment, attachment.name)
    );

    return this.http.post<AppHttpResponse<Quotation>>(`${this.quotationsApi}`, formData);
  }

  /**
   * Searches in the quotations by leads, fromDate,toDate.
   * @param leads The ids of the leads.
   * @param fromDate The fromDate of the quotation.
   * @param toDate The toDate of the quotation.
   * @param page The current pagination page number.
   * @param pageSize The maximum number of leads allowed per one pagination page.
   */
  public search(
    leads: number[],
    quotationStatuses: number[],
    fromDate: Date,
    toDate: Date,
    page: number,
    pageSize: number
  ): Observable<AppHttpResponse<Quotation[]>> {
    const params = new HttpParams()
      .set('leads', leads.join(','))
      .set('quotationStatuses', quotationStatuses.join(','))
      .set('fromDate', fromDate.toISOString())
      .set('toDate', toDate.toISOString())
      .set('page', page.toString())
      .set('pageSize', pageSize.toString());
    return this.http.get<AppHttpResponse<Quotation[]>>(`${this.quotationsApi}`, { params });
  }

  /**
   * Finds the quotation with the given id.
   * @param quotationId The id of the quotation.
   */
  public findById(quotationId: number): Observable<AppHttpResponse<Quotation>> {
    return this.http.get<AppHttpResponse<Quotation>>(`${this.quotationsApi}/${quotationId}`);
  }

  /**
   * reject the quotation  by given id.
   * @param id The id of the quotation.
   */
  public rejectQuotation(id: number): Observable<AppHttpResponse<Quotation>> {
    return this.http.put<AppHttpResponse<Quotation>>(`${this.quotationsApi}/reject/${id}`, null);
  }
}
