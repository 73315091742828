import { cloneDeep, findIndex, remove } from 'lodash';

import { LeadsActionType, LeadsActions } from 'crm/store/actions';
import { LeadsState } from 'crm/store/states';

/**
 * The initial state from which the state starts.
 */
const initialState: LeadsState = {
  data: [],
  paginationInfo: {
    total: 0,
    page: 1,
    pageSize: 0,
    count: 0,
  },
  error: null,
  isSearching: false,
  isSearchCompleted: false,
  selectedLead: null,
  selectedLeadNote: null,
  selectedLeadAttachment: null,
  isFinding: false,
  isFindCompleted: false,
  isCreating: false,
  isCreateCompleted: false,
  isLeadNoteCreating: false,
  isLeadNoteCreateCompleted: false,
  isLeadAttachmentsCreating: false,
  isLeadAttachmentsCreateCompleted: false,
  isUpdating: false,
  isUpdateCompleted: false,
  isDeleting: false,
  isDeleteCompleted: false,
  isLeadNoteDeleting: false,
  isLeadNoteDeleteCompleted: false,
  isLeadAttachmentDeleting: false,
  isLeadAttachmentDeleteCompleted: false,
  isLeadStatusMarkAsNotHandledYetUpdating: false,
  isLeadStatusMarkAsNotHandledYetUpdateCompleted: false,
  isLeadStatusMarkAsLostUpdating: false,
  isLeadStatusMarkAsOpportunityUpdateCompleted: false,
  isLeadStatusMarkAsOpportunityUpdating: false,
  isLeadStatusMarkAsLostUpdateCompleted: false,
};

/**
 * The reducer function that is called in each action dispatch against the state.
 * @param state The current state.
 * @param action The action that will affect the state.
 */
export function leadsReducer(state: LeadsState = initialState, action: LeadsActions): LeadsState {
  switch (action.type) {
    //#region SEARCH_LEADS

    case LeadsActionType.SEARCH_LEADS: {
      return {
        ...state,
        error: null,
        isSearching: true,
        isSearchCompleted: false,
      };
    }

    case LeadsActionType.SEARCH_LEADS_FAIL: {
      return {
        ...state,
        error: action.payload,
        isSearching: false,
        isSearchCompleted: false,
      };
    }

    case LeadsActionType.SEARCH_LEADS_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        paginationInfo: {
          ...action.payload.meta,
        },
        error: null,
        isSearching: false,
        isSearchCompleted: true,
      };
    }

    //#endregion SEARCH_LEADS

    //#region FIND_LEAD

    case LeadsActionType.FIND_LEAD: {
      return {
        ...state,
        selectedLead: null,
        error: null,
        isFinding: true,
        isFindCompleted: false,
      };
    }

    case LeadsActionType.FIND_LEAD_FAIL: {
      return {
        ...state,
        error: action.payload,
        isFinding: false,
        isFindCompleted: false,
      };
    }

    case LeadsActionType.FIND_LEAD_SUCCESS: {
      let leads = [...state.data];
      const leadIndex = findIndex(leads, (lead) => lead.id === action.payload.data.id);

      /* If lead was found, update it. */
      if (leadIndex >= 0) {
        leads[leadIndex] = action.payload.data;
      } else {
        /* else, insert it to the beginning of the array. */
        leads = [action.payload.data, ...leads];
      }

      return {
        ...state,
        data: leads,
        selectedLead: action.payload.data,
        error: null,
        isFinding: false,
        isFindCompleted: true,
      };
    }

    //#endregion FIND_LEAD

    //#region CREATE_LEAD

    case LeadsActionType.CREATE_LEAD: {
      return {
        ...state,
        error: null,
        isCreating: true,
        isCreateCompleted: false,
      };
    }

    case LeadsActionType.CREATE_LEAD_FAIL: {
      return {
        ...state,
        error: action.payload,
        isCreating: false,
        isCreateCompleted: false,
      };
    }

    case LeadsActionType.CREATE_LEAD_SUCCESS: {
      return {
        ...state,
        data: [action.payload.data, ...state.data],
        selectedLead: action.payload.data,
        error: null,
        isCreating: false,
        isCreateCompleted: true,
      };
    }

    //#endregion CREATE_LEAD

    //#region CREATE_LEAD_NOTE

    case LeadsActionType.CREATE_LEAD_NOTE: {
      return {
        ...state,
        error: null,
        isLeadNoteCreating: true,
        isLeadNoteCreateCompleted: false,
      };
    }

    case LeadsActionType.CREATE_LEAD_NOTE_FAIL: {
      return {
        ...state,
        error: action.payload,
        isLeadNoteCreating: false,
        isLeadNoteCreateCompleted: false,
      };
    }

    case LeadsActionType.CREATE_LEAD_NOTE_SUCCESS: {
      const selectedLead = cloneDeep(state.selectedLead);
      selectedLead.leadNotes.push(action.payload.data);
      return {
        ...state,
        selectedLead,
        error: null,
        isLeadNoteCreating: false,
        isLeadNoteCreateCompleted: true,
      };
    }

    //#endregion CREATE_LEAD_NOTE

    //#region CREATE_LEAD_ATTACHMENTS

    case LeadsActionType.CREATE_LEAD_ATTACHMENTS: {
      return {
        ...state,
        error: null,
        isLeadAttachmentsCreating: true,
        isLeadAttachmentsCreateCompleted: false,
      };
    }

    case LeadsActionType.CREATE_LEAD_ATTACHMENTS_FAIL: {
      return {
        ...state,
        error: action.payload,
        isLeadAttachmentsCreating: false,
        isLeadAttachmentsCreateCompleted: false,
      };
    }

    case LeadsActionType.CREATE_LEAD_ATTACHMENTS_SUCCESS: {
      const selectedLead = cloneDeep(state.selectedLead);
      selectedLead.leadAttachments.push(...action.payload.data);
      return {
        ...state,
        selectedLead,
        error: null,
        isLeadAttachmentsCreating: false,
        isLeadAttachmentsCreateCompleted: true,
      };
    }

    //#endregion CREATE_LEAD_ATTACHMENTS

    //#region UPDATE_LEAD

    case LeadsActionType.UPDATE_LEAD: {
      return {
        ...state,
        error: null,
        isUpdating: true,
        isUpdateCompleted: false,
      };
    }

    case LeadsActionType.UPDATE_LEAD_FAIL: {
      return {
        ...state,
        error: action.payload,
        isUpdating: false,
        isUpdateCompleted: false,
      };
    }

    case LeadsActionType.UPDATE_LEAD_SUCCESS: {
      let leads = [...state.data];
      const leadIndex = findIndex(leads, (lead) => lead.id === action.payload.data.id);

      /* If lead was found, update it. */
      if (leadIndex >= 0) {
        leads[leadIndex] = action.payload.data;
      } else {
        /* else, insert it to the beginning of the array. */
        leads = [action.payload.data, ...leads];
      }

      return {
        ...state,
        data: leads,
        selectedLead: action.payload.data,
        error: null,
        isUpdating: false,
        isUpdateCompleted: true,
      };
    }

    //#endregion UPDATE_LEAD

    //#region DELETE_LEAD

    case LeadsActionType.DELETE_LEAD: {
      return {
        ...state,
        error: null,
        isDeleting: true,
        isDeleteCompleted: false,
      };
    }

    case LeadsActionType.DELETE_LEAD_FAIL: {
      return {
        ...state,
        error: action.payload.data,
        isDeleting: false,
        isDeleteCompleted: false,
      };
    }

    case LeadsActionType.DELETE_LEAD_SUCCESS: {
      const leads = [...state.data];

      /* Remove the deleted lead from the array. */
      remove(leads, (lead) => lead.id === action.payload.data.id);

      return {
        ...state,
        data: leads,
        selectedLead: action.payload.data,
        error: null,
        isDeleting: false,
        isDeleteCompleted: true,
      };
    }

    //#endregion DELETE_LEAD

    //#region DELETE_LEAD_NOTE

    case LeadsActionType.DELETE_LEAD_NOTE: {
      return {
        ...state,
        error: null,
        isLeadNoteDeleting: true,
        isLeadNoteDeleteCompleted: false,
      };
    }

    case LeadsActionType.DELETE_LEAD_NOTE_FAIL: {
      return {
        ...state,
        error: action.payload.data,
        isLeadNoteDeleting: false,
        isLeadNoteDeleteCompleted: false,
      };
    }

    case LeadsActionType.DELETE_LEAD_NOTE_SUCCESS: {
      const selectedLead = cloneDeep(state.selectedLead);
      const leadNotes = selectedLead.leadNotes;

      /* Remove the deleted lead note from the array. */
      remove(leadNotes, (leadNote) => leadNote.id === action.payload.data.id);

      return {
        ...state,
        selectedLead,
        selectedLeadNote: action.payload.data,
        error: null,
        isLeadNoteDeleting: false,
        isLeadNoteDeleteCompleted: true,
      };
    }

    //#endregion DELETE_LEAD_NOTE

    //#region DELETE_LEAD_ATTACHMENT

    case LeadsActionType.DELETE_LEAD_ATTACHMENT: {
      return {
        ...state,
        error: null,
        isLeadAttachmentDeleting: true,
        isLeadAttachmentDeleteCompleted: false,
      };
    }

    case LeadsActionType.DELETE_LEAD_ATTACHMENT_FAIL: {
      return {
        ...state,
        error: action.payload.data,
        isLeadAttachmentDeleting: false,
        isLeadAttachmentDeleteCompleted: false,
      };
    }

    case LeadsActionType.DELETE_LEAD_ATTACHMENT_SUCCESS: {
      const selectedLead = cloneDeep(state.selectedLead);
      const leadAttachments = selectedLead.leadAttachments;

      /* Remove the deleted lead attachments from the array. */
      remove(leadAttachments, (leadAttachment) => leadAttachment.id === action.payload.data.id);

      return {
        ...state,
        selectedLead,
        selectedLeadAttachment: action.payload.data,
        error: null,
        isLeadAttachmentDeleting: false,
        isLeadAttachmentDeleteCompleted: true,
      };
    }

    //#endregion DELETE_LEAD_ATTACHMENT

    //#region UPDATE_LEAD_STATUS_MARK_AS_NOT_HANDLED_YET

    case LeadsActionType.UPDATE_LEAD_STATUS_MARK_AS_NOT_HANDLED_YET: {
      return {
        ...state,
        error: null,
        isLeadStatusMarkAsNotHandledYetUpdating: true,
        isLeadStatusMarkAsNotHandledYetUpdateCompleted: false,
      };
    }

    case LeadsActionType.UPDATE_LEAD_STATUS_MARK_AS_NOT_HANDLED_YET_FAIL: {
      return {
        ...state,
        error: action.payload,
        isLeadStatusMarkAsNotHandledYetUpdating: false,
        isLeadStatusMarkAsNotHandledYetUpdateCompleted: false,
      };
    }

    case LeadsActionType.UPDATE_LEAD_STATUS_MARK_AS_NOT_HANDLED_YET_SUCCESS: {
      let leads = [...state.data];
      const leadIndex = findIndex(leads, (lead) => lead.id === action.payload.data.id);

      /* If lead status was found, update it. */
      if (leadIndex >= 0) {
        leads[leadIndex] = action.payload.data;
      } else {
        /* else, insert it to the beginning of the array. */
        leads = [action.payload.data, ...leads];
      }

      return {
        ...state,
        data: leads,
        selectedLead: action.payload.data,
        error: null,
        isLeadStatusMarkAsNotHandledYetUpdating: false,
        isLeadStatusMarkAsNotHandledYetUpdateCompleted: true,
      };
    }

    //#endregion UPDATE_LEAD_STATUS_MARK_AS_NOT_HANDLED_YET

    //#region UPDATE_LEAD_STATUS_MARK_AS_LOST

    case LeadsActionType.UPDATE_LEAD_STATUS_MARK_AS_LOST: {
      return {
        ...state,
        error: null,
        isLeadStatusMarkAsLostUpdating: true,
        isLeadStatusMarkAsLostUpdateCompleted: false,
      };
    }

    case LeadsActionType.UPDATE_LEAD_STATUS_MARK_AS_LOST_FAIL: {
      return {
        ...state,
        error: action.payload,
        isLeadStatusMarkAsLostUpdating: false,
        isLeadStatusMarkAsLostUpdateCompleted: false,
      };
    }

    case LeadsActionType.UPDATE_LEAD_STATUS_MARK_AS_LOST_SUCCESS: {
      let leads = [...state.data];
      const leadIndex = findIndex(leads, (lead) => lead.id === action.payload.data.id);

      /* If lead status was found, update it. */
      if (leadIndex >= 0) {
        leads[leadIndex] = action.payload.data;
      } else {
        /* else, insert it to the beginning of the array. */
        leads = [action.payload.data, ...leads];
      }

      return {
        ...state,
        data: leads,
        selectedLead: action.payload.data,
        error: null,
        isLeadStatusMarkAsLostUpdating: false,
        isLeadStatusMarkAsLostUpdateCompleted: true,
      };
    }

    //#endregion UPDATE_LEAD_STATUS_MARK_AS_LOST

    //#region UPDATE_LEAD_STATUS_MARK_AS_OPPORTUNITY

    case LeadsActionType.UPDATE_LEAD_STATUS_MARK_AS_OPPORTUNITY: {
      return {
        ...state,
        error: null,
        isLeadStatusMarkAsOpportunityUpdating: true,
        isLeadStatusMarkAsOpportunityUpdateCompleted: false,
      };
    }

    case LeadsActionType.UPDATE_LEAD_STATUS_MARK_AS_OPPORTUNITY_FAIL: {
      return {
        ...state,
        error: action.payload,
        isLeadStatusMarkAsOpportunityUpdating: false,
        isLeadStatusMarkAsOpportunityUpdateCompleted: false,
      };
    }

    case LeadsActionType.UPDATE_LEAD_STATUS_MARK_AS_OPPORTUNITY_SUCCESS: {
      let leads = [...state.data];
      const leadIndex = findIndex(leads, (lead) => lead.id === action.payload.data.id);

      /* If lead status was found, update it. */
      if (leadIndex >= 0) {
        leads[leadIndex] = action.payload.data;
      } else {
        /* else, insert it to the beginning of the array. */
        leads = [action.payload.data, ...leads];
      }

      return {
        ...state,
        data: leads,
        selectedLead: action.payload.data,
        error: null,
        isLeadStatusMarkAsOpportunityUpdating: false,
        isLeadStatusMarkAsOpportunityUpdateCompleted: true,
      };
    }

    //#endregion UPDATE_LEAD_STATUS_MARK_AS_OPPORTUNITY

    default:
      return state;
  }
}
