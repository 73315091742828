import { createSelector } from '@ngrx/store';

import { SecurityState, UsersState } from 'security/store/states';
import { selectSecurityState } from 'security/store/selectors/feature.selectors';

/* Selectors */

/**
 * Gets the users state.
 */
const selectUsersState = createSelector(selectSecurityState, (state: SecurityState) => state.users);

/**
 * Gets the list of loaded users.
 */
export const getUsers = createSelector(selectUsersState, (state: UsersState) => state.users);

/**
 * Gets the list of loaded user claims.
 */
export const getUserClaims = createSelector(selectUsersState, (state: UsersState) => state.userClaims);

/**
 * Gets the list of loaded user locations.
 */
export const getSelectedUserLocations = createSelector(selectUsersState, (state: UsersState) => state.userLocations);

/**
 * Gets the list of loaded user bank accounts.
 */
export const getSelectedUserBankAccounts = createSelector(
  selectUsersState,
  (state: UsersState) => state.userBankAccounts
);

/**
 * Gets the list of loaded user cost centers.
 */
export const getSelectedUserCostCenters = createSelector(
  selectUsersState,
  (state: UsersState) => state.userCostCenters
);

/**
 * Gets the users pagination info.
 */
export const getUsersPaginationInfo = createSelector(selectUsersState, (state: UsersState) => state.paginationInfo);

/**
 * Gets the error that occurred in the state.
 */
export const getUsersError = createSelector(selectUsersState, (state: UsersState) => state.error);

/**
 * Determines if there is a running search process.
 */
export const getUsersSearching = createSelector(selectUsersState, (state: UsersState) => state.isSearching);

/**
 * Determines if the last process has been ended successfully.
 */
export const getOrganizationUsersCompleted = createSelector(
  selectUsersState,
  (state: UsersState) => state.isGetOrganizationUsersCompleted
);

/**
 * Determines if there is a running process.
 */
export const getOrganizationUsersSearching = createSelector(
  selectUsersState,
  (state: UsersState) => state.isGettingOrganizationUsers
);

/**
 * Determines if the last search process has been ended successfully.
 */
export const getUsersSearchCompleted = createSelector(selectUsersState, (state: UsersState) => state.isSearchCompleted);

/**
 * Gets the selected user.
 */
export const getSelectedUser = createSelector(selectUsersState, (state: UsersState) => state.selectedUser);

/**
 * Gets the selected organization user.
 */
export const getSelectedOrganizationUser = createSelector(
  selectUsersState,
  (state: UsersState) => state.selectedOrganizationUser
);

/**
 * Gets the selected user claim.
 */
export const getSelectedUserClaim = createSelector(selectUsersState, (state: UsersState) => state.selectedUserClaim);

/**
 * Determines if there is a running find-user process.
 */
export const getUsersFinding = createSelector(selectUsersState, (state: UsersState) => state.isFinding);

/**
 * Determines if the last find-user process has been ended successfully.
 */
export const getUsersFindCompleted = createSelector(selectUsersState, (state: UsersState) => state.isFindCompleted);

/**
 * Determines if there is a running create process.
 */
export const getSelectedOrganizationUserCreating = createSelector(
  selectUsersState,
  (state: UsersState) => state.isOrganizationUserCreating
);

/**
 * Determines if the last create organization user process has been ended successfully.
 */
export const getSelectedOrganizationUserCreateCompleted = createSelector(
  selectUsersState,
  (state: UsersState) => state.isGettingOrganizationUsers
);

/**
 * Determines if there is a running create process.
 */
export const getSelectedUserCreating = createSelector(selectUsersState, (state: UsersState) => state.isCreating);

/**
 * Determines if the last create process has been ended successfully.
 */
export const getSelectedUserCreateCompleted = createSelector(
  selectUsersState,
  (state: UsersState) => state.isCreateCompleted
);

/**
 * Determines if there is a running update process.
 */
export const getSelectedUserUpdating = createSelector(selectUsersState, (state: UsersState) => state.isUpdating);

/**
 * Determines if the last update process has been ended successfully.
 */
export const getSelectedUserUpdateCompleted = createSelector(
  selectUsersState,
  (state: UsersState) => state.isUpdateCompleted
);

/**
 * Determines if there is a running block process.
 */
export const getSelectedUserBlocking = createSelector(selectUsersState, (state: UsersState) => state.isBlocking);

/**
 * Determines if the last block process has been ended successfully.
 */
export const getSelectedUserBlockCompleted = createSelector(
  selectUsersState,
  (state: UsersState) => state.isBlockCompleted
);

/**
 * Determines if there is a running activate process.
 */
export const getSelectedUserActivating = createSelector(selectUsersState, (state: UsersState) => state.isActivating);

/**
 * Determines if the last activate process has been ended successfully.
 */
export const getSelectedUserActivateCompleted = createSelector(
  selectUsersState,
  (state: UsersState) => state.isActivateCompleted
);

/**
 * Determines if there is a running update process.
 */
export const getSelectedUserClaimsUpdating = createSelector(
  selectUsersState,
  (state: UsersState) => state.isUserClaimsUpdating
);

/**
 * Determines if the last update process has been ended successfully.
 */
export const getSelectedUserClaimsUpdateCompleted = createSelector(
  selectUsersState,
  (state: UsersState) => state.isUserClaimsUpdateCompleted
);

/**
 * Determines if there is a running update process.
 */
export const getSelectedUserLocationUpdating = createSelector(
  selectUsersState,
  (state: UsersState) => state.isUserLocationUpdating
);

/**
 * Determines if the last update process has been ended successfully.
 */
export const getSelectedUserLocationUpdateCompleted = createSelector(
  selectUsersState,
  (state: UsersState) => state.isUserLocationUpdateCompleted
);

/**
 * Determines if there is a running update process.
 */
export const getSelectedUserBankAccountUpdating = createSelector(
  selectUsersState,
  (state: UsersState) => state.isUserBankAccountUpdating
);

/**
 * Determines if the last update process has been ended successfully.
 */
export const getSelectedUserBankAccountUpdateCompleted = createSelector(
  selectUsersState,
  (state: UsersState) => state.isUserBankAccountUpdateCompleted
);

/**
 * Determines if there is a running update process.
 */
export const getSelectedUserCostCenterUpdating = createSelector(
  selectUsersState,
  (state: UsersState) => state.isUserCostCenterUpdating
);

/**
 * Determines if the last update process has been ended successfully.
 */
export const getSelectedUserCostCenterUpdateCompleted = createSelector(
  selectUsersState,
  (state: UsersState) => state.isUserCostCenterUpdateCompleted
);

/**
 * Determines if there is a running process.
 */
export const getUserLocations = createSelector(selectUsersState, (state: UsersState) => state.isGettingUserLocations);

/**
 * Determines if the last process has been ended successfully.
 */
export const getUserLocationsCompleted = createSelector(
  selectUsersState,
  (state: UsersState) => state.isGetUserLocationsCompleted
);

/**
 * Determines if there is a running process.
 */
export const getUserBankAccounts = createSelector(
  selectUsersState,
  (state: UsersState) => state.isGettingUserBankAccounts
);

/**
 * Determines if the last process has been ended successfully.
 */
export const getUserBankAccountsCompleted = createSelector(
  selectUsersState,
  (state: UsersState) => state.isGetUserBankAccountsCompleted
);

/**
 * Determines if there is a running process.
 */
export const getUserCostCenters = createSelector(
  selectUsersState,
  (state: UsersState) => state.isGettingUserCostCenters
);

/**
 * Determines if the last process has been ended successfully.
 */
export const getUserCostCentersCompleted = createSelector(
  selectUsersState,
  (state: UsersState) => state.isGetUserCostCentersCompleted
);
