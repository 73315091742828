import { Action } from '@ngrx/store';

import { OvertimeRate } from 'lookups/models';
import { AppHttpResponse } from 'shared';

/**
 * The Lookups-Module store overtime rates action types.
 */
export enum OvertimeRateActionType {
  GET_ALL_OVERTIME_RATES = '[Lookups] [OvertimeRate] Get All Overtime Rates',
  GET_ALL_OVERTIME_RATES_FAIL = '[Lookups] [OvertimeRate] Get All Overtime Rates Fail',
  GET_ALL_OVERTIME_RATES_SUCCESS = '[Lookups] [OvertimeRate] Get All Overtime Rates Success',
}

/**
 * Represents a store overtime rate action.
 */
export class GetAllOvertimeRates implements Action {
  /**
   * The type of the action.
   */
  readonly type = OvertimeRateActionType.GET_ALL_OVERTIME_RATES;

  /**
   * Fires a new overtime rate action.
   */
  constructor() {}
}

/**
 * Represents a store overtime rate fail action.
 */

export class GetAllOvertimeRatesFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = OvertimeRateActionType.GET_ALL_OVERTIME_RATES_FAIL;

  /**
   * Fires a new overtime rate fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store overtime rate success action.
 */
export class GetAllOvertimeRatesSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = OvertimeRateActionType.GET_ALL_OVERTIME_RATES_SUCCESS;

  /**
   * Fires a overtime rate success action.
   * @param payload An object contains the list of overtime rates.
   */
  constructor(public payload: AppHttpResponse<OvertimeRate[]>) {}
}

/**
 * Lookups-module overtime rate action types.
 */
export type OvertimeRateActions = GetAllOvertimeRates | GetAllOvertimeRatesFail | GetAllOvertimeRatesSuccess;
