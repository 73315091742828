import { CreateOrderDeliveryPrinterComponent } from 'sales/components/order-delivery-printers/create-order-delivery-printer/create-order-delivery-printer.component';
import { UpdateOrderDeliveryPrinterComponent } from 'sales/components/order-delivery-printers/update-order-delivery-printer/update-order-delivery-printer.component';
import { OrderDeliveryPrintersListComponent } from 'sales/components/order-delivery-printers/order-delivery-printers-list/order-delivery-printers-list.component';
import { OrderDeliveryPrintersSearchComponent } from 'sales/components/order-delivery-printers/order-delivery-printers-search/order-delivery-printers-search.component';
import { OrderTypesFormComponent } from 'sales/components/order-delivery-printers/order-types-form/order-types-form.component';
import { OrderTypesSearchComponent } from 'sales/components/order-delivery-printers/order-types-search/order-types-search.component';

export * from 'sales/components/order-delivery-printers/create-order-delivery-printer/create-order-delivery-printer.component';
export * from 'sales/components/order-delivery-printers/update-order-delivery-printer/update-order-delivery-printer.component';
export * from 'sales/components/order-delivery-printers/order-delivery-printers-list/order-delivery-printers-list.component';
export * from 'sales/components/order-delivery-printers/order-delivery-printers-search/order-delivery-printers-search.component';
export * from 'sales/components/order-delivery-printers/order-types-form/order-types-form.component';
export * from 'sales/components/order-delivery-printers/order-types-search/order-types-search.component';

/**
 * The set of components registered on this sub-module.
 */
export const COMPONENTS: any[] = [
  CreateOrderDeliveryPrinterComponent,
  UpdateOrderDeliveryPrinterComponent,
  OrderDeliveryPrintersListComponent,
  OrderDeliveryPrintersSearchComponent,
  OrderTypesFormComponent,
  OrderTypesSearchComponent,
];
