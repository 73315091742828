import { createSelector } from '@ngrx/store';

import { selectLookupsState } from 'lookups/store/selectors/feature.selectors';
import { LookupsState, ProjectStatusState } from 'lookups/store/states';

/**
 * Gets the project statuses state.
 */
const selectProjectStatuses = createSelector(selectLookupsState, (state: LookupsState) => state.projectStatuses);

/**
 * Gets the list of loaded project statuses.
 */
export const getProjectStatuses = createSelector(selectProjectStatuses, (state: ProjectStatusState) => state.data);

/**
 * Gets the error that occurred in the state.
 */
export const getProjectStatusesError = createSelector(
  selectProjectStatuses,
  (state: ProjectStatusState) => state.error
);

/**
 * Determines if there is a running load process.
 */
export const getProjectStatusesIsLoading = createSelector(
  selectProjectStatuses,
  (state: ProjectStatusState) => state.isLoading
);

/**
 * Determines if the last load process has been ended successfully.
 */
export const getProjectStatusesIsLoaded = createSelector(
  selectProjectStatuses,
  (state: ProjectStatusState) => state.isLoaded
);
