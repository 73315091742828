import { createSelector } from '@ngrx/store';

import { selectLookupsState } from 'lookups/store/selectors/feature.selectors';
import { LookupsState, EndOfServiceReasonState } from 'lookups/store/states';

/**
 * Gets the end of service reason state.
 */
const selectEndOfServiceReasons = createSelector(
  selectLookupsState,
  (state: LookupsState) => state.endOfServiceReasons
);

/**
 * Gets the list of loaded end of service reasons.
 */
export const getEndOfServiceReasons = createSelector(
  selectEndOfServiceReasons,
  (state: EndOfServiceReasonState) => state.data
);

/**
 * Gets the error that occurred in the state.
 */
export const getEndOfServiceReasonsError = createSelector(
  selectEndOfServiceReasons,
  (state: EndOfServiceReasonState) => state.error
);

/**
 * Determines if there is a running load process.
 */
export const getEndOfServiceReasonsIsLoading = createSelector(
  selectEndOfServiceReasons,
  (state: EndOfServiceReasonState) => state.isLoading
);

/**
 * Determines if the last load process has been ended successfully.
 */
export const getEndOfServiceReasonsIsLoaded = createSelector(
  selectEndOfServiceReasons,
  (state: EndOfServiceReasonState) => state.isLoaded
);
