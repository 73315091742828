import { createSelector } from '@ngrx/store';

import { FinancesState, SystemAccountsState } from 'finances/store/states';
import { selectFinancesState } from 'finances/store/selectors/feature.selectors';

/**
 * Gets the system accounts state.
 */
const selectSystemAccountState = createSelector(selectFinancesState, (state: FinancesState) => state.systemAccounts);

/**
 * Gets the error that occurred in the state.
 */
export const getSystemAccountsError = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.error
);

/**
 * Gets the account receivable account.
 */
export const getAccountReceivableAccount = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.accountReceivableAccount
);

/**
 * Gets the revenue account.
 */
export const getRevenueAccount = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.revenueAccount
);

/**
 * Gets the tax account.
 */
export const getTaxAccount = createSelector(selectSystemAccountState, (state: SystemAccountsState) => state.taxAccount);

/**
 * Gets the material account.
 */
export const getMaterialAccount = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.materialAccount
);

/**
 * Gets the inventory clearing account.
 */
export const getInventoryClearingAccount = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.inventoryClearingAccount
);

/**
 * Gets the accounts payables clearing account.
 */
export const getAccountsPayablesClearingAccount = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.accountsPayablesClearingAccount
);

/**
 * Gets the petty cash clearing account.
 */
export const getPettyCashClearingAccount = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.pettyCashClearingAccount
);

/**
 * Gets the liability account.
 */
export const getLiabilityAccount = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.liabilityAccount
);

/**
 * Gets the COGS account.
 */
export const getCOGSAccount = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.COGSAccount
);

/**
 * Gets the profit loss account.
 */
export const getProfitLossAccount = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.profitLossAccount
);

/**
 * Gets the bank account.
 */
export const getBankAccount = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.bankAccount
);

/**
 * Gets the cash account.
 */
export const getCashAccount = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.cashAccount
);

/**
 * Gets the cheques under collection account.
 */
export const getChequesUnderCollectionAccount = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.chequesUnderCollectionAccount
);

/**
 * Gets the payments under collection account.
 */
export const getPaymentsUnderCollectionAccount = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.paymentsUnderCollectionAccount
);

/**
 * Gets the point of sale cash account.
 */
export const getPointOfSaleCashAccount = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.pointOfSaleCashAccount
);

/**
 * Gets the pending cheque account.
 */
export const getPendingChequeAccount = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.pendingChequeAccount
);

/**
 * Gets the payments under payment account.
 */
export const getPaymentsUnderPaymentAccount = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.paymentsUnderPaymentAccount
);

/**
 * Gets the advance expenses petty cash account.
 */
export const getAdvanceExpensesPettyCashAccount = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.advanceExpensesPettyCashAccount
);

/**
 * Gets the owners current account.
 */
export const getOwnersCurrentAccount = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.ownersCurrentAccount
);

/**
 * Gets the salaries payable account.
 */
export const getSalariesPayableAccount = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.salariesPayableAccount
);

/**
 * Determines if there is a running find account receivable process.
 */
export const getAccountReceivableFinding = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindingAccountReceivable
);

/**
 * Determines if the last find account receivable process has been ended successfully.
 */
export const getAccountReceivableFindCompleted = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindAccountReceivableCompleted
);

/**
 * Determines if there is a running find revenue process.
 */
export const getRevenueFinding = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindingRevenue
);

/**
 * Determines if the last find revenue process has been ended successfully.
 */
export const getRevenueFindCompleted = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindRevenueCompleted
);

/**
 * Determines if there is a running find tax process.
 */
export const getTaxFinding = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindingTax
);

/**
 * Determines if the last find tax process has been ended successfully.
 */
export const getTaxFindCompleted = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindTaxCompleted
);

/**
 * Determines if there is a running find material account process.
 */
export const getMaterialAccountFinding = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindingMaterialAccount
);

/**
 * Determines if the last find material account process has been ended successfully.
 */
export const getMaterialAccountFindCompleted = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindMaterialAccountCompleted
);

/**
 * Determines if there is a running find inventory clearing account process.
 */
export const getInventoryClearingAccountFinding = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindingInventoryClearingAccount
);

/**
 * Determines if the last find inventory clearing account process has been ended successfully.
 */
export const getInventoryClearingAccountFindCompleted = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindInventoryClearingAccountCompleted
);

/**
 * Determines if there is a running find accounts payables clearing account process.
 */
export const getAccountsPayablesClearingAccountFinding = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindingAccountsPayablesClearingAccount
);

/**
 * Determines if the last find accounts payables clearing account process has been ended successfully.
 */
export const getAccountsPayablesClearingAccountFindCompleted = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindAccountsPayablesClearingAccountCompleted
);

/**
 * Determines if there is a running find petty cash clearing account process.
 */
export const getPettyCashClearingAccountFinding = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindingPettyCashClearingAccount
);

/**
 * Determines if the last find petty cash clearing account process has been ended successfully.
 */
export const getPettyCashClearingAccountFindCompleted = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindPettyCashClearingAccountCompleted
);

/**
 * Determines if there is a running find liability account process.
 */
export const getLiabilityAccountFinding = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindingLiabilityAccount
);

/**
 * Determines if the last find liability account process has been ended successfully.
 */
export const getLiabilityAccountFindCompleted = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindLiabilityAccountCompleted
);

/**
 * Determines if there is a running find COGS account process.
 */
export const getCOGSAccountFinding = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindingCOGSAccount
);

/**
 * Determines if the last find COGS account process has been ended successfully.
 */
export const getCOGSAccountFindCompleted = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindCOGSAccountCompleted
);

/**
 * Determines if there is a running find profit loss account process.
 */
export const getProfitLossAccountFinding = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindingProfitLossAccount
);

/**
 * Determines if the last find profit loss account process has been ended successfully.
 */
export const getProfitLossAccountFindCompleted = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindProfitLossAccountCompleted
);

/**
 * Determines if there is a running find bank account process.
 */
export const getBankAccountFinding = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindingBankAccount
);

/**
 * Determines if the last find bank account process has been ended successfully.
 */
export const getBankAccountFindCompleted = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindBankAccountCompleted
);

/**
 * Determines if there is a running find cash account process.
 */
export const getCashAccountFinding = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindingCashAccount
);

/**
 * Determines if the last find cash account process has been ended successfully.
 */
export const getCashAccountFindCompleted = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindCashAccountCompleted
);

/**
 * Determines if there is a running find cheques under collection account process.
 */
export const getChequesUnderCollectionAccountFinding = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindingChequesUnderCollectionAccount
);

/**
 * Determines if the last find cheques under collection account process has been ended successfully.
 */
export const getChequesUnderCollectionAccountFindCompleted = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindChequesUnderCollectionAccountCompleted
);

/**
 * Determines if there is a running find payments under collection account process.
 */
export const getPaymentsUnderCollectionAccountFinding = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindingPaymentsUnderCollectionAccount
);

/**
 * Determines if the last find payments under collection account process has been ended successfully.
 */
export const getPaymentsUnderCollectionAccountFindCompleted = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindPaymentsUnderCollectionAccountCompleted
);

/**
 * Determines if there is a running find point of sale cash account process.
 */
export const getPointOfSaleCashAccountFinding = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindingPointOfSaleCashAccount
);

/**
 * Determines if the last find point of sale cash account process has been ended successfully.
 */
export const getPointOfSaleCashAccountFindCompleted = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindPointOfSaleCashAccountCompleted
);

/**
 * Determines if there is a running find pending cheque account process.
 */
export const getPendingChequeAccountFinding = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindingPendingChequeAccount
);

/**
 * Determines if the last find pending cheque account process has been ended successfully.
 */
export const getPendingChequeFindCompleted = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindPendingChequeAccountCompleted
);

/**
 * Determines if there is a running find payments under payment account process.
 */
export const getPaymentsUnderPaymentAccountFinding = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindingPaymentsUnderPaymentAccount
);

/**
 * Determines if the last find payments under payment account process has been ended successfully.
 */
export const getPaymentsUnderPaymentAccountFindCompleted = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindPaymentsUnderPaymentAccountCompleted
);

/**
 * Determines if there is a running find advance expenses petty cash account process.
 */
export const getAdvanceExpensesPettyCashAccountFinding = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindingAdvanceExpensesPettyCashAccount
);

/**
 * Determines if the last find advance expenses petty cash account process has been ended successfully.
 */
export const getAdvanceExpensesPettyCashFindCompleted = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindAdvanceExpensesPettyCashAccountCompleted
);
/**
 * Determines if there is a running find owners current account process.
 */
export const getOwnersCurrentFinding = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindingOwnersCurrentAccount
);

/**
 * Determines if the last find owners current account process has been ended successfully.
 */
export const getOwnersCurrentFindCompleted = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindOwnersCurrentAccountCompleted
);

/**
 * Determines if the last find machinesAndEquipments account process has been ended successfully.
 */
export const getMachinesAndEquipmentsFindCompleted = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindMachinesAndEquipmentsAccountCompleted
);

export const getMachinesAndEquipmentsAccount = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.machinesAndEquipmentsAccount
);

/**
 * Determines if the last find assetBrokerage account process has been ended successfully.
 */
export const getAssetBrokerageFindCompleted = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindAssetBrokerageAccountCompleted
);

export const getAssetBrokerageAccount = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.assetBrokerageAccount
);

/**
 * Determines if the last find improvementsToRentedBuildings account process has been ended successfully.
 */
export const getImprovementsToRentedBuildingsFindCompleted = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindImprovementsToRentedBuildingsAccountCompleted
);

export const getImprovementsToRentedBuildingsAccount = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.improvementsToRentedBuildingsAccount
);

/**
 * Determines if the last find desktopDevices account process has been ended successfully.
 */
export const getDesktopDevicesFindCompleted = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindDesktopDevicesAccountCompleted
);

export const getDesktopDevicesAccount = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.desktopDevicesAccount
);

/**
 * Determines if there is a running find salaries payable process.
 */
export const getSalariesPayableFinding = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindingSalariesPayableAccount
);

/**
 * Determines if the last find salaries payable account process has been ended successfully.
 */
export const getSalariesPayableFindCompleted = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindSalariesPayableAccountCompleted
);

/* &&&&&&&&&&&&&&&&&&&&&&&&&& NEW SYSTEMS ACCOUNTS &&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&& */
/**
 * Gets the cash in hand account.
 */
export const getCashInHandAccount = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.cashInHandAccount
);

/**
 * Determines if there is a running find account cash in hand process.
 */
export const getCashInHandAccountFinding = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindingCashInHandAccount
);

/**
 * Determines if the last find account cash in hand process has been ended successfully.
 */
export const getCashInHandAccountFindCompleted = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindCashInHandAccountCompleted
);

/**
 * Gets the cash in banks account.
 */
export const getCashInBanksAccount = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.cashInBanksAccount
);

/**
 * Determines if there is a running find cash in banks account process.
 */
export const getCashInBanksAccountFinding = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindingCashInBanksAccount
);

/**
 * Determines if the last find cash in banks account process has been ended successfully.
 */
export const getCashInBanksAccountFindCompleted = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindCashInBanksAccountCompleted
);

/**
 * Gets the checks under collection account.
 */
export const getChecksUnderCollectionAccount = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.checksUnderCollectionAccount
);

/**
 * Determines if there is a running find checks under collection account process.
 */
export const getChecksUnderCollectionAccountFinding = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindingChecksUnderCollectionAccount
);

/**
 * Determines if the last find checks under collection account process has been ended successfully.
 */
export const getChecksUnderCollectionAccountFindCompleted = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindChecksUnderCollectionAccountCompleted
);
/**
 * Gets the cash investments less than 90 days account.
 */
export const getCashInvestmentsLessThan90DaysAccount = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.cashInvestmentsLessThan90DaysAccount
);

/**
 * Determines if there is a running find cash investments less than 90 days account process.
 */
export const getCashInvestmentsLessThan90DaysAccountFinding = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindingCashInvestmentsLessThan90DaysAccount
);

/**
 * Determines if the last find cash investments less than 90 days account process has been ended successfully.
 */
export const getCashInvestmentsLessThan90DaysAccountFindCompleted = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindCashInvestmentsLessThan90DaysAccountCompleted
);

/**
 * Gets the restricted cash in bank account.
 */
export const getRestrictedCashInBanksAccount = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.restrictedCashInBanksAccount
);

/**
 * Determines if there is a running find restricted cash in bank account process.
 */
export const getRestrictedCashInBanksAccountFinding = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindingRestrictedCashInBanksAccount
);

/**
 * Determines if the last find restricted cash in bank account process has been ended successfully.
 */
export const getRestrictedCashInBanksAccountFindCompleted = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindRestrictedCashInBanksAccountCompleted
);

/**
 * Gets the short term deposits account.
 */
export const getShortTermDepositsAccount = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.shortTermDepositsAccount
);

/**
 * Determines if there is a running find short term deposits account process.
 */
export const getShortTermDepositsAccountFinding = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindingShortTermDepositsAccount
);

/**
 * Determines if the last find short term deposits account process has been ended successfully.
 */
export const getShortTermDepositsAccountFindCompleted = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindShortTermDepositsAccountCompleted
);

/**
 * Gets the cash investments for trading account.
 */
export const getCashInvestmentsForTradingAccount = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.cashInvestmentsForTradingAccount
);

/**
 * Determines if there is a running find cash investments for trading account process.
 */
export const getCashInvestmentsForTradingAccountFinding = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindingCashInvestmentsForTradingAccount
);

/**
 * Determines if the last find cash investments for trading account process has been ended successfully.
 */
export const getCashInvestmentsForTradingAccountFindCompleted = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindCashInvestmentsForTradingAccountCompleted
);

/**
 * Gets the trade debtors account.
 */
export const getTradeDebtorsAccount = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.tradeDebtorsAccount
);

/**
 * Determines if there is a running find trade debtors account process.
 */
export const getTradeDebtorsAccountFinding = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindingTradeDebtorsAccount
);

/**
 * Determines if the last find trade debtors account process has been ended successfully.
 */
export const getTradeDebtorsAccountFindCompleted = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindTradeDebtorsAccountCompleted
);

/**
 * Gets the allowance for doubtful debts account.
 */
export const getAllowanceForDoubtfulDebtsAccount = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.allowanceForDoubtfulDebtsAccount
);

/**
 * Determines if there is a running find allowance for doubtful debts account process.
 */
export const getAllowanceForDoubtfulDebtsAccountFinding = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindingAllowanceForDoubtfulDebtsAccount
);

/**
 * Determines if the last find allowance for doubtful debts account process has been ended successfully.
 */
export const getAllowanceForDoubtfulDebtsAccountFindCompleted = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindAllowanceForDoubtfulDebtsAccountCompleted
);

/**
 * Gets the related parties account.
 */
export const getRelatedPartiesAccount = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.relatedPartiesAccount
);

/**
 * Determines if there is a running find related parties account process.
 */
export const getRelatedPartiesAccountFinding = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindingRelatedPartiesAccount
);

/**
 * Determines if the last find related parties account process has been ended successfully.
 */
export const getRelatedPartiesAccountFindCompleted = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindRelatedPartiesAccountCompleted
);

/**
 * Gets the advances of staff account.
 */
export const getAdvancesOfStaffAccount = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.advancesOfStaffAccount
);

/**
 * Determines if there is a running find advances of staff account process.
 */
export const getAdvancesOfStaffAccountFinding = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindingAdvancesOfStaffAccount
);

/**
 * Determines if the last find advances of staff account process has been ended successfully.
 */
export const getAdvancesOfStaffAccountFindCompleted = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindAdvancesOfStaffAccountCompleted
);

/**
 * Gets the letters of guarantee account.
 */
export const getLettersOfGuaranteeAccount = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.lettersOfGuaranteeAccount
);

/**
 * Determines if there is a running find letters of guarantee account process.
 */
export const getLettersOfGuaranteeAccountFinding = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindingLettersOfGuaranteeAccount
);

/**
 * Determines if the last find letters of guarantee account process has been ended successfully.
 */
export const getLettersOfGuaranteeAccountFindCompleted = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindLettersOfGuaranteeAccountCompleted
);

/**
 * Gets the documentary credits account.
 */
export const getDocumentaryCreditsAccount = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.documentaryCreditsAccount
);

/**
 * Determines if there is a running find documentary credits account process.
 */
export const getDocumentaryCreditsAccountFinding = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindingDocumentaryCreditsAccount
);

/**
 * Determines if the last find documentary credits account process has been ended successfully.
 */
export const getDocumentaryCreditsAccountFindCompleted = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindDocumentaryCreditsAccountCompleted
);

/**
 * Gets the petty cash account.
 */
export const getPettyCashAccount = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.pettyCashAccount
);

/**
 * Determines if there is a running find petty cash account process.
 */
export const getPettyCashAccountFinding = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindingPettyCashAccount
);

/**
 * Determines if the last find petty cash account process has been ended successfully.
 */
export const getPettyCashAccountFindCompleted = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindPettyCashAccountCompleted
);

/**
 * Gets the well executed detention account.
 */
export const getWellExecutedDetentionAccount = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.wellExecutedDetentionAccount
);

/**
 * Determines if there is a running findwell executed detention account process.
 */
export const getWellExecutedDetentionAccountFinding = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindingWellExecutedDetentionAccount
);

/**
 * Determines if the last find well executed detention account process has been ended successfully.
 */
export const getWellExecutedDetentionAccountFindCompleted = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindWellExecutedDetentionAccountCompleted
);

/**
 * Gets the accrued revenue account.
 */
export const getAccruedRevenueAccount = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.accruedRevenueAccount
);

/**
 * Determines if there is a running find accrued revenue account process.
 */
export const getAccruedRevenueAccountFinding = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindingAccruedRevenueAccount
);

/**
 * Determines if the last find accrued revenue account process has been ended successfully.
 */
export const getAccruedRevenueAccountFindCompleted = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindAccruedRevenueAccountCompleted
);

/**
 * Gets the work in progress unbilled revenue account.
 */
export const getWorkInProgressUnbilledRevenueAccount = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.workInProgressUnbilledRevenueAccount
);

/**
 * Determines if there is a running find work in progress unbilled revenue account process.
 */
export const getWorkInProgressUnbilledRevenueAccountFinding = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindingWorkInProgressUnbilledRevenueAccount
);

/**
 * Determines if the last find work in progress unbilled revenue account process has been ended successfully.
 */
export const getWorkInProgressUnbilledRevenueAccountFindCompleted = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindWorkInProgressUnbilledRevenueAccountCompleted
);

/**
 * Gets the value added tax debit account.
 */
export const getValueAddedTaxDebitAccount = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.valueAddedTaxDebitAccount
);

/**
 * Determines if there is a running find value added tax debit account process.
 */
export const getValueAddedTaxDebitAccountFinding = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindingValueAddedTaxDebitAccount
);

/**
 * Determines if the last find value added tax debit account process has been ended successfully.
 */
export const getValueAddedTaxDebitAccountFindCompleted = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindValueAddedTaxDebitAccountCompleted
);

/**
 * Gets the miscellaneous debtors account.
 */
export const getMiscellaneousDebtorsAccount = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.miscellaneousDebtorsAccount
);

/**
 * Determines if there is a running find miscellaneous debtors account process.
 */
export const getMiscellaneousDebtorsAccountFinding = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindingMiscellaneousDebtorsAccount
);

/**
 * Determines if the last find miscellaneous debtors account process has been ended successfully.
 */
export const getMiscellaneousDebtorsAccountFindCompleted = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindMiscellaneousDebtorsAccountCompleted
);

/**
 * Gets the prepaid expenses account.
 */
export const getPrepaidExpensesAccount = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.prepaidExpensesAccount
);

/**
 * Determines if there is a running find prepaid expenses account process.
 */
export const getPrepaidExpensesAccountFinding = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindingPrepaidExpensesAccount
);

/**
 * Determines if the last find prepaid expenses account process has been ended successfully.
 */
export const getPrepaidExpensesAccountFindCompleted = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindPrepaidExpensesAccountCompleted
);

/**
 * Gets the advance payments to vendors and contractors account.
 */
export const getAdvancePaymentsToVendorsAndContractorsAccount = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.advancePaymentsToVendorsAndContractorsAccount
);

/**
 * Determines if there is a running find advance payments to vendors and contractors account process.
 */
export const getAdvancePaymentsToVendorsAndContractorsAccountFinding = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindingAdvancePaymentsToVendorsAndContractorsAccount
);

/**
 * Determines if the last find advance payments to vendors and contractors account process has been ended successfully.
 */
export const getAdvancePaymentsToVendorsAndContractorsAccountFindCompleted = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindAdvancePaymentsToVendorsAndContractorsAccountCompleted
);

/**
 * Gets the financing charges for lease contracts account.
 */
export const getFinancingChargesForLeaseContractsAccount = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.financingChargesForLeaseContractsAccount
);

/**
 * Determines if there is a running find financing charges for lease contracts account process.
 */
export const getFinancingChargesForLeaseContractsAccountFinding = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindingFinancingChargesForLeaseContractsAccount
);

/**
 * Determines if the last find financing charges for lease contracts account process has been ended successfully.
 */
export const getFinancingChargesForLeaseContractsAccountFindCompleted = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindFinancingChargesForLeaseContractsAccountCompleted
);

/**
 * Gets the financing charges for bank loans and credit facilities account.
 */
export const getFinancingChargesForBankLoansAndCreditFacilitiesAccount = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.financingChargesForBankLoansAndCreditFacilitiesAccount
);

/**
 * Determines if there is a running find financing charges for bank loans and credit facilities account process.
 */
export const getFinancingChargesForBankLoansAndCreditFacilitiesAccountFinding = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindingFinancingChargesForBankLoansAndCreditFacilitiesAccount
);

/**
 * Determines if the last find financing charges for bank loans and credit facilities account process has been ended successfully.
 */
export const getFinancingChargesForBankLoansAndCreditFacilitiesAccountFindCompleted = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindFinancingChargesForBankLoansAndCreditFacilitiesAccountCompleted
);

/**
 * Gets the goods and materials account.
 */
export const getGoodsAndMaterialsStockAccount = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.goodsAndMaterialsStockAccount
);

/**
 * Determines if there is a running find goods and materials account process.
 */
export const getGoodsAndMaterialsStockAccountFinding = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindingGoodsAndMaterialsStockAccount
);

/**
 * Determines if the last find goods and materials account process has been ended successfully.
 */
export const getGoodsAndMaterialsStockAccountFindCompleted = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindGoodsAndMaterialsStockAccountCompleted
);

/**
 * Gets the stock in process account.
 */
export const getStockInProcessAccount = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.stockInProcessAccount
);

/**
 * Determines if there is a running find stock in process account process.
 */
export const getStockInProcessAccountFinding = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindingStockInProcessAccount
);

/**
 * Determines if the last find stock in process account process has been ended successfully.
 */
export const getStockInProcessAccountFindCompleted = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindStockInProcessAccountCompleted
);

/**
 * Gets the completed stock account.
 */
export const getCompletedStockAccount = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.completedStockAccount
);

/**
 * Determines if there is a running find completed stock account process.
 */
export const getCompletedStockAccountFinding = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindingCompletedStockAccount
);

/**
 * Determines if the last find completed stock account process has been ended successfully.
 */
export const getCompletedStockAccountFindCompleted = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindCompletedStockAccountCompleted
);

/**
 * Gets the provision for damaged or obsolete inventory account.
 */
export const getProvisionForDamagedOrObsoleteInventoryAccount = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.provisionForDamagedOrObsoleteInventoryAccount
);

/**
 * Determines if there is a running find provision for damaged or obsolete inventory account process.
 */
export const getProvisionForDamagedOrObsoleteInventoryAccountFinding = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindingProvisionForDamagedOrObsoleteInventoryAccount
);

/**
 * Determines if the last find provision for damaged or obsolete inventory account process has been ended successfully.
 */
export const getProvisionForDamagedOrObsoleteInventoryAccountFindCompleted = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindProvisionForDamagedOrObsoleteInventoryAccountCompleted
);

/**
 * Gets the investments available for sale or held to maturity account.
 */
export const getInvestmentsAvailableForSaleOrHeldToMaturityAccount = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.investmentsAvailableForSaleOrHeldToMaturityAccount
);

/**
 * Determines if there is a running find investments available for sale or held to maturity account process.
 */
export const getInvestmentsAvailableForSaleOrHeldToMaturityAccountFinding = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindingInvestmentsAvailableForSaleOrHeldToMaturityAccount
);

/**
 * Determines if the last find investments available for sale or held to maturity account process has been ended successfully.
 */
export const getInvestmentsAvailableForSaleOrHeldToMaturityAccountFindCompleted = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindInvestmentsAvailableForSaleOrHeldToMaturityAccountCompleted
);

/**
 * Gets the long term deposits account.
 */
export const getLongTermDepositsAccount = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.longTermDepositsAccount
);

/**
 * Determines if there is a running find long term deposits account process.
 */
export const getLongTermDepositsAccountFinding = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindingLongTermDepositsAccount
);

/**
 * Determines if the last find long term deposits account process has been ended successfully.
 */
export const getLongTermDepositsAccountFindCompleted = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindLongTermDepositsAccountCompleted
);

/**
 * Gets the finance charges for lease contracts non current account.
 */
export const getFinanceChargesForLeaseContractsNonCurrentAccount = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.financeChargesForLeaseContractsNonCurrentAccount
);

/**
 * Determines if there is a running find finance charges for lease contracts non current account process.
 */
export const getFinanceChargesForLeaseContractsNonCurrentAccountFinding = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindingFinanceChargesForLeaseContractsNonCurrentAccount
);

/**
 * Determines if the last find finance charges for lease contracts non current account process has been ended successfully.
 */
export const getFinanceChargesForLeaseContractsNonCurrentAccountFindCompleted = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindFinanceChargesForLeaseContractsNonCurrentAccountCompleted
);

/**
 * Gets the financing charges for loans and credit facilities non current account.
 */
export const getFinancingChargesForLoansAndCreditFacilitiesNonCurrentAccount = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.financingChargesForLoansAndCreditFacilitiesNonCurrentAccount
);

/**
 * Determines if there is a running find financing charges for loans and credit facilities non current account process.
 */
export const getFinancingChargesForLoansAndCreditFacilitiesNonCurrentAccountFinding = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindingFinancingChargesForLoansAndCreditFacilitiesNonCurrentAccount
);

/**
 * Determines if the last find financing charges for loans and credit facilities non current account process has been ended successfully.
 */
export const getFinancingChargesForLoansAndCreditFacilitiesNonCurrentAccountFindCompleted = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindFinancingChargesForLoansAndCreditFacilitiesNonCurrentAccountCompleted
);

/**
 * Gets the projects under implementation account.
 */
export const getProjectsUnderImplementationAccount = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.projectsUnderImplementationAccount
);

/**
 * Determines if there is a running find projects under implementation account process.
 */
export const getProjectsUnderImplementationAccountFinding = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindingProjectsUnderImplementationAccount
);

/**
 * Determines if the last find projects under implementation account process has been ended successfully.
 */
export const getProjectsUnderImplementationAccountFindCompleted = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindProjectsUnderImplementationAccountCompleted
);

/**
 * Gets the lands account.
 */
export const getLandsAccount = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.landsAccount
);

/**
 * Determines if there is a running find lands account process.
 */
export const getLandsAccountFinding = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindingLandsAccount
);

/**
 * Determines if the last find lands account process has been ended successfully.
 */
export const getLandsFindCompleted = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindLandsAccountCompleted
);

/**
 * Gets the buildings account.
 */
export const getBuildingsAccount = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.buildingsAccount
);

/**
 * Determines if there is a running find buildings account process.
 */
export const getBuildingsAccountFinding = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindingBuildingsAccount
);

/**
 * Determines if the last find buildings account process has been ended successfully.
 */
export const getBuildingsFindCompleted = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindBuildingsAccountCompleted
);

/**
 * Gets the furniture account.
 */
export const getFurnitureAccount = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.furnitureAccount
);

/**
 * Determines if there is a running find furniture account process.
 */
export const getFurnitureAccountFinding = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindingFurnitureAccount
);

/**
 * Determines if the last find furniture account process has been ended successfully.
 */
export const getFurnitureFindCompleted = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindFurnitureAccountCompleted
);

/**
 * Gets the devices account.
 */
export const getDevicesAccount = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.devicesAccount
);

/**
 * Determines if there is a running find devices account process.
 */
export const getDevicesAccountFinding = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindingDevicesAccount
);

/**
 * Determines if the last find devices account process has been ended successfully.
 */
export const getDevicesFindCompleted = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindDevicesAccountCompleted
);

/**
 * Gets the computers account.
 */
export const getComputersAccount = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.computersAccount
);

/**
 * Determines if there is a running find computers account process.
 */
export const getComputersAccountFinding = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindingComputersAccount
);

/**
 * Determines if the last find computers account process has been ended successfully.
 */
export const getComputersFindCompleted = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindComputersAccountCompleted
);

/**
 * Gets the equipments account.
 */
export const getEquipmentsAccount = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.equipmentsAccount
);

/**
 * Determines if there is a running find equipments account process.
 */
export const getEquipmentsAccountFinding = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindingEquipmentsAccount
);

/**
 * Determines if the last find equipments account process has been ended successfully.
 */
export const getEquipmentsFindCompleted = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindEquipmentsAccountCompleted
);

/**
 * Gets the cars account.
 */
export const getCarsAccount = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.carsAccount
);

/**
 * Determines if there is a running find cars account process.
 */
export const getCarsFinding = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindingCarsAccount
);

/**
 * Determines if the last find cars account process has been ended successfully.
 */
export const getCarsFindCompleted = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindCarsAccountCompleted
);

/**
 * Gets the assets under finance leases account.
 */
export const getAssetsUnderFinanceLeasesAccount = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.assetsUnderFinanceLeasesAccount
);

/**
 * Determines if there is a running find assets under finance leases account process.
 */
export const getAssetsUnderFinanceLeasesAccountFinding = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindingAssetsUnderFinanceLeasesAccount
);

/**
 * Determines if the last find assets under finance leases account process has been ended successfully.
 */
export const getAssetsUnderFinanceLeasesFindCompleted = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindAssetsUnderFinanceLeasesAccountCompleted
);

/**
 * Gets the building consumption complex account.
 */
export const getBuildingConsumptionComplexAccount = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.buildingConsumptionComplexAccount
);

/**
 * Determines if there is a running find building consumption complex account process.
 */
export const getBuildingConsumptionComplexAccountinding = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindingBuildingConsumptionComplexAccount
);

/**
 * Determines if the last find building consumption complex account process has been ended successfully.
 */
export const getBuildingConsumptionComplexAccountFindCompleted = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindBuildingConsumptionComplexAccountCompleted
);

/**
 * Gets the furniture consumption complex account.
 */
export const getFurnitureConsumptionComplexAccount = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.furnitureConsumptionComplexAccount
);

/**
 * Determines if there is a running find furniture consumption complex account process.
 */
export const getFurnitureConsumptionComplexAccountFinding = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindingFurnitureConsumptionComplexAccount
);

/**
 * Determines if the last find furniture consumption complex account process has been ended successfully.
 */
export const getFurnitureConsumptionComplexAccountFindCompleted = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindFurnitureConsumptionComplexAccountCompleted
);

/**
 * Gets the devices consumption complex account.
 */
export const getDevicesConsumptionComplexAccount = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.devicesConsumptionComplexAccount
);

/**
 * Determines if there is a running find devices consumption complex account process.
 */
export const getDevicesConsumptionComplexAccountFinding = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindingDevicesConsumptionComplexAccount
);

/**
 * Determines if the last find devices consumption complex account process has been ended successfully.
 */
export const getDevicesConsumptionComplexAccountFindCompleted = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindDevicesConsumptionComplexAccountCompleted
);

/**
 * Gets the computers consumption complex account.
 */
export const getComputersConsumptionComplexAccount = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.computersConsumptionComplexAccount
);

/**
 * Determines if there is a running find computers consumption complex account process.
 */
export const getComputersConsumptionComplexAccountFinding = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindingComputersConsumptionComplexAccount
);

/**
 * Determines if the last find computers consumption complex account process has been ended successfully.
 */
export const getComputersConsumptionComplexAccountFindCompleted = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindComputersConsumptionComplexAccountCompleted
);

/**
 * Gets the equipments consumption complex account.
 */
export const getEquipmentsConsumptionComplexAccount = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.equipmentsConsumptionComplexAccount
);

/**
 * Determines if there is a running find equipments consumption complex account process.
 */
export const getEquipmentsConsumptionComplexAccountFinding = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindingEquipmentsConsumptionComplexAccount
);

/**
 * Determines if the last find equipments consumption complex account process has been ended successfully.
 */
export const getEquipmentsConsumptionComplexAccountFindCompleted = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindEquipmentsConsumptionComplexAccountCompleted
);

/**
 * Gets the cars consumption complex account.
 */
export const getCarsConsumptionComplexAccount = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.carsConsumptionComplexAccount
);

/**
 * Determines if there is a running find cars consumption complex account process.
 */
export const getCarsConsumptionComplexAccountFinding = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindingCarsConsumptionComplexAccount
);

/**
 * Determines if the last find cars consumption complex account process has been ended successfully.
 */
export const getCarsConsumptionComplexAccountFindCompleted = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindCarsConsumptionComplexAccountCompleted
);

/**
 * Gets the pooled depreciation of assets under finance lease contracts account.
 */
export const getPooledDepreciationOfAssetsUnderFinanceLeaseContractsAccount = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.pooledDepreciationOfAssetsUnderFinanceLeaseContractsAccount
);

/**
 * Determines if there is a running find pooled depreciation of assets under finance lease contracts account process.
 */
export const getPooledDepreciationOfAssetsUnderFinanceLeaseContractsAccountFinding = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindingPooledDepreciationOfAssetsUnderFinanceLeaseContractsAccount
);

/**
 * Determines if the last find pooled depreciation of assets under finance lease contracts account process has been ended successfully.
 */
export const getPooledDepreciationOfAssetsUnderFinanceLeaseContractsAccountFindCompleted = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindPooledDepreciationOfAssetsUnderFinanceLeaseContractsAccountCompleted
);

/**
 * Gets the fame account.
 */
export const getFameAccount = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.fameAccount
);

/**
 * Determines if there is a running find fame account process.
 */
export const getFameAccountFinding = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindingFameAccount
);

/**
 * Determines if the last find fame account process has been ended successfully.
 */
export const getFameAccountFindCompleted = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindFameAccountCompleted
);

/**
 * Gets the software and systems account.
 */
export const getSoftwareAndSystemsAccount = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.softwareAndSystemsAccount
);

/**
 * Determines if there is a running find software and systems account process.
 */
export const getSoftwareAndSystemsAccountFinding = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindingSoftwareAndSystemsAccount
);

/**
 * Determines if the last find software and systems account process has been ended successfully.
 */
export const getSoftwareAndSystemsAccountFindCompleted = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindSoftwareAndSystemsAccountCompleted
);

/**
 * Gets the patents and trademarks account.
 */
export const getPatentsAndTrademarksAccount = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.patentsAndTrademarksAccount
);

/**
 * Determines if there is a running find patents and trademarks account process.
 */
export const getPatentsAndTrademarksAccountFinding = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindingPatentsAndTrademarksAccount
);

/**
 * Determines if the last find patents and trademarks account process has been ended successfully.
 */
export const getPatentsAndTrademarksAccountFindCompleted = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindPatentsAndTrademarksAccountCompleted
);

/**
 * Gets the fame extinguishing complex account.
 */
export const getFameExtinguishingComplexAccount = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.fameExtinguishingComplexAccount
);

/**
 * Determines if there is a running find fame extinguishing complex account process.
 */
export const getFameExtinguishingComplexAccountFinding = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindingFameExtinguishingComplexAccount
);

/**
 * Determines if the last find fame extinguishing complex account process has been ended successfully.
 */
export const getFameExtinguishingComplexAccountFindCompleted = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindFameExtinguishingComplexAccountCompleted
);

/**
 * Gets the software and hardware extinguishing complex account.
 */
export const getSoftwareAndHardwareExtinguishingComplexAccount = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.softwareAndHardwareExtinguishingComplexAccount
);

/**
 * Determines if there is a running find software and hardware extinguishing complex account process.
 */
export const getSoftwareAndHardwareExtinguishingComplexAccountFinding = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindingSoftwareAndHardwareExtinguishingComplexAccount
);

/**
 * Determines if the last find software and hardware extinguishing complex account process has been ended successfully.
 */
export const getSoftwareAndHardwareExtinguishingComplexAccountFindCompleted = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindSoftwareAndHardwareExtinguishingComplexAccountCompleted
);

/**
 * Gets the patent and trademark extinguishing complex account.
 */
export const getPatentAndTrademarkExtinguishingComplexAccount = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.patentAndTrademarkExtinguishingComplexAccount
);

/**
 * Determines if there is a running find patent and trademark extinguishing complex account process.
 */
export const getPatentAndTrademarkExtinguishingComplexAccountFinding = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindingPatentAndTrademarkExtinguishingComplexAccount
);

/**
 * Determines if the last find patent and trademark extinguishing complex account process has been ended successfully.
 */
export const getPatentAndTrademarkExtinguishingComplexAccountFindCompleted = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindPatentAndTrademarkExtinguishingComplexAccountCompleted
);

/**
 * Gets the real estate investment lands account.
 */
export const getRealEstateInvestmentLandsAccount = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.realEstateInvestmentLandsAccount
);

/**
 * Determines if there is a running find real estate investment lands account process.
 */
export const getRealEstateInvestmentLandsAccountFinding = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindingRealEstateInvestmentLandsAccount
);

/**
 * Determines if the last find real estate investment lands account process has been ended successfully.
 */
export const getRealEstateInvestmentLandsAccountFindCompleted = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindRealEstateInvestmentLandsAccountCompleted
);

/**
 * Gets the real estate investment buildings account.
 */
export const getRealEstateInvestmentBuildingsAccount = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.realEstateInvestmentBuildingsAccount
);

/**
 * Determines if there is a running find real estate investment buildings account process.
 */
export const getRealEstateInvestmentBuildingsAccountFinding = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindingRealEstateInvestmentBuildingsAccount
);

/**
 * Determines if the last find real estate investment buildings account process has been ended successfully.
 */
export const getRealEstateInvestmentBuildingsAccountFindCompleted = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindRealEstateInvestmentBuildingsAccountCompleted
);

/**
 * Gets the real estate investment building consumption complexaccount.
 */
export const getRealEstateInvestmentBuildingConsumptionComplexAccount = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.realEstateInvestmentBuildingConsumptionComplexAccount
);

/**
 * Determines if there is a running find real estate investment building consumption complex account process.
 */
export const getRealEstateInvestmentBuildingConsumptionComplexAccountFinding = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindingRealEstateInvestmentBuildingConsumptionComplexAccount
);

/**
 * Determines if the last find real estate investment building consumption complex account process has been ended successfully.
 */
export const getRealEstateInvestmentBuildingConsumptionComplexAccountFindCompleted = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindRealEstateInvestmentBuildingConsumptionComplexAccountCompleted
);

/**
 * Gets the investments in subsidiaries account.
 */
export const getInvestmentsInSubsidiariesAccountAccount = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.investmentsInSubsidiariesAccount
);

/**
 * Determines if there is a running find investments in subsidiaries account process.
 */
export const getInvestmentsInSubsidiariesAccountFinding = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindingInvestmentsInSubsidiariesAccount
);

/**
 * Determines if the last find investments in subsidiaries account process has been ended successfully.
 */
export const getInvestmentsInSubsidiariesAccountFindCompleted = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindInvestmentsInSubsidiariesAccountCompleted
);

/**
 * Gets the investments in associates account.
 */
export const getInvestmentsInAssociatesAccountAccount = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.investmentsInAssociatesAccount
);

/**
 * Determines if there is a running find investments in associates account process.
 */
export const getInvestmentsInAssociatesAccountFinding = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindingInvestmentsInAssociatesAccount
);

/**
 * Determines if the last find investments in associates account process has been ended successfully.
 */
export const getInvestmentsInAssociatesAccountFindCompleted = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindInvestmentsInAssociatesAccountCompleted
);

/**
 * Gets the investments in joint ventures account.
 */
export const getInvestmentsInJointVenturesAccount = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.investmentsInJointVenturesAccount
);

/**
 * Determines if there is a running find investments in joint ventures account process.
 */
export const getInvestmentsInJointVenturesAccountFinding = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindingInvestmentsInJointVenturesAccount
);

/**
 * Determines if the last find investments in joint ventures account process has been ended successfully.
 */
export const getInvestmentsInJointVenturesAccountFindCompleted = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindInvestmentsInJointVenturesAccountCompleted
);

/**
 * Gets the loan installments the current part account.
 */
export const getLoanInstallmentsTheCurrentPartAccount = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.loanInstallmentsTheCurrentPartAccount
);

/**
 * Determines if there is a running find loan installments the current part account process.
 */
export const getLoanInstallmentsTheCurrentPartAccountFinding = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindingLoanInstallmentsTheCurrentPartAccount
);

/**
 * Determines if the last find loan installments the current part account process has been ended successfully.
 */
export const getLoanInstallmentsTheCurrentPartAccountFindCompleted = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindLoanInstallmentsTheCurrentPartAccountCompleted
);

/**
 * Gets the current contracts for rent the current part account.
 */
export const getCurrentContractsForRentTheCurrentPartAccount = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.currentContractsForRentTheCurrentPartAccount
);

/**
 * Determines if there is a running find current contracts for rent the current part account process.
 */
export const getCurrentContractsForRentTheCurrentPartAccountFinding = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindingCurrentContractsForRentTheCurrentPartAccount
);

/**
 * Determines if the last find current contracts for rent the current part account process has been ended successfully.
 */
export const getCurrentContractsForRentTheCurrentPartAccountFindCompleted = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindCurrentContractsForRentTheCurrentPartAccountCompleted
);

/**
 * Gets the trade creditors account.
 */
export const getTradeCreditorsAccount = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.tradeCreditorsAccount
);

/**
 * Determines if there is a running find trade creditors account process.
 */
export const getTradeCreditorsAccountFinding = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindingTradeCreditorsAccount
);

/**
 * Determines if the last find trade creditors account process has been ended successfully.
 */
export const getTradeCreditorsAccountFindCompleted = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindTradeCreditorsAccountCompleted
);

/**
 * Gets the vendors broker account.
 */
export const getVendorsBrokerAccount = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.vendorsBrokerAccount
);

/**
 * Determines if there is a running find vendors broker account process.
 */
export const getVendorsBrokerAccountFinding = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindingVendorsBrokerAccount
);

/**
 * Determines if the last find vendors broker account process has been ended successfully.
 */
export const getVendorsBrokerAccountFindCompleted = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindVendorsBrokerAccountCompleted
);

/**
 * Gets the credit facilities overdraft account.
 */
export const getCreditFacilitiesOverdraftAccount = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.creditFacilitiesOverdraftAccount
);

/**
 * Determines if there is a running find credit facilities overdraft account process.
 */
export const getCreditFacilitiesOverdraftAccountFinding = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindingCreditFacilitiesOverdraftAccount
);

/**
 * Determines if the last find credit facilities overdraft account process has been ended successfully.
 */
export const getCreditFacilitiesOverdraftAccountFindCompleted = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindCreditFacilitiesOverdraftAccountCompleted
);

/**
 * Gets the creditors withholding balances account.
 */
export const getCreditorsWithholdingBalancesAccount = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.creditorsWithholdingBalancesAccount
);

/**
 * Determines if there is a running find creditors withholding balances account process.
 */
export const getCreditorsWithholdingBalancesAccountFinding = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindingCreditorsWithholdingBalancesAccount
);

/**
 * Determines if the last find creditors withholding balances account process has been ended successfully.
 */
export const getCreditorsWithholdingBalancesAccountFindCompleted = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindCreditorsWithholdingBalancesAccountCompleted
);

/**
 * Gets the payable expenses account.
 */
export const getPayableExpensesAccount = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.payableExpensesAccount
);

/**
 * Determines if there is a running find payable expenses account process.
 */
export const getPayableExpensesAccountFinding = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindingPayableExpensesAccount
);

/**
 * Determines if the last find payable expenses account process has been ended successfully.
 */
export const getPayableExpensesAccountFindCompleted = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindPayableExpensesAccountCompleted
);

/**
 * Gets the employees entitlements salaries account.
 */
export const getEmployeesEntitlementsSalariesAccount = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.employeesEntitlementsSalariesAccount
);

/**
 * Determines if there is a running find employees entitlements salaries account process.
 */
export const getEmployeesEntitlementsSalariesAccountFinding = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindingEmployeesEntitlementsSalariesAccount
);

/**
 * Determines if the last find employees entitlements salaries account process has been ended successfully.
 */
export const getEmployeesEntitlementsSalariesAccountFindCompleted = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindEmployeesEntitlementsSalariesAccountCompleted
);

/**
 * Gets the employees entitlements benefits account.
 */
export const getEmployeesEntitlementsBenefitsAccount = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.employeesEntitlementsBenefitsAccount
);

/**
 * Determines if there is a running find employees entitlements benefits account process.
 */
export const getEmployeesEntitlementsBenefitsAccountFinding = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindingEmployeesEntitlementsBenefitsAccount
);

/**
 * Determines if the last find employees entitlements benefits account process has been ended successfully.
 */
export const getEmployeesEntitlementsBenefitsAccountFindCompleted = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindEmployeesEntitlementsBenefitsAccountCompleted
);

/**
 * Gets the employees entitlements traveling tickets account.
 */
export const getEmployeesEntitlementsTravelingTicketsAccount = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.employeesEntitlementsTravelingTicketsAccount
);

/**
 * Determines if there is a running find employees entitlements traveling tickets account process.
 */
export const getEmployeesEntitlementsTravelingTicketsAccountFinding = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindingEmployeesEntitlementsTravelingTicketsAccount
);

/**
 * Determines if the last find employees entitlements traveling tickets account process has been ended successfully.
 */
export const getEmployeesEntitlementsTravelingTicketsAccountFindCompleted = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindEmployeesEntitlementsTravelingTicketsAccountCompleted
);

/**
 * Gets the advance revenue account.
 */
export const getAdvanceRevenueAccount = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.advanceRevenueAccount
);

/**
 * Determines if there is a running find advance revenue account process.
 */
export const getAdvanceRevenueAccountFinding = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindingAdvanceRevenueAccount
);

/**
 * Determines if the last find advance revenue account process has been ended successfully.
 */
export const getAdvanceRevenueAccountFindCompleted = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindAdvanceRevenueAccountCompleted
);

/**
 * Gets the value added tax credit account.
 */
export const getValueAddedTaxCreditAccount = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.valueAddedTaxCreditAccount
);

/**
 * Determines if there is a running find value added tax credit account process.
 */
export const getValueAddedTaxCreditAccountFinding = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindingValueAddedTaxCreditAccount
);

/**
 * Determines if the last find value added tax credit account process has been ended successfully.
 */
export const getValueAddedTaxCreditAccountFindCompleted = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindValueAddedTaxCreditAccountCompleted
);

/**
 * Gets the receivables staff account.
 */
export const getReceivablesStaffAccount = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.receivablesStaffAccount
);

/**
 * Determines if there is a running find receivables staff account process.
 */
export const getReceivablesStaffAccountFinding = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindingReceivablesStaffAccount
);

/**
 * Determines if the last find receivables staff account process has been ended successfully.
 */
export const getReceivablesStaffAccountFindCompleted = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindReceivablesStaffAccountCompleted
);

/**
 * Gets the other current liabilities related parties account.
 */
export const getOtherCurrentLiabilitiesRelatedPartiesAccount = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.otherCurrentLiabilitiesRelatedPartiesAccount
);

/**
 * Determines if there is a running find other current liabilities related parties account process.
 */
export const getOtherCurrentLiabilitiesRelatedPartiesAccountFinding = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindingOtherCurrentLiabilitiesRelatedPartiesAccount
);

/**
 * Determines if the last find other current liabilities related parties account process has been ended successfully.
 */
export const getOtherCurrentLiabilitiesRelatedPartiesAccountFindCompleted = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindOtherCurrentLiabilitiesRelatedPartiesAccountCompleted
);

/**
 * Gets the miscellaneous creditors account.
 */
export const getMiscellaneousCreditorsAccount = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.miscellaneousCreditorsAccount
);

/**
 * Determines if there is a running find miscellaneous creditors account process.
 */
export const getMiscellaneousCreditorsAccountFinding = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindingMiscellaneousCreditorsAccount
);

/**
 * Determines if the last find miscellaneous creditors account process has been ended successfully.
 */
export const getMiscellaneousCreditorsAccountFindCompleted = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindMiscellaneousCreditorsAccountCompleted
);

/**
 * Gets the dividend payable account.
 */
export const getDividendPayableAccount = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.dividendPayableAccount
);

/**
 * Determines if there is a running find dividend payable account process.
 */
export const getDividendPayableAccountFinding = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindingDividendPayableAccount
);

/**
 * Determines if the last find dividend payable account process has been ended successfully.
 */
export const getDividendPayableAccountFindCompleted = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindDividendPayableAccountCompleted
);

/**
 * Gets the zakat provision account.
 */
export const getZakatProvisionAccount = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.zakatProvisionAccount
);

/**
 * Determines if there is a running find zakat provision account process.
 */
export const getZakatProvisionAccountFinding = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindingZakatProvisionAccount
);

/**
 * Determines if the last find zakat provision account process has been ended successfully.
 */
export const getZakatProvisionAccountFindCompleted = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindZakatProvisionAccountCompleted
);

/**
 * Gets the income tax provision account.
 */
export const getIncomeTaxProvisionAccount = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.incomeTaxProvisionAccount
);

/**
 * Determines if there is a running find income tax provision account process.
 */
export const getIncomeTaxProvisionAccountFinding = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindingIncomeTaxProvisionAccount
);

/**
 * Determines if the last find income tax provision account process has been ended successfully.
 */
export const getIncomeTaxProvisionAccountFindCompleted = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindIncomeTaxProvisionAccountCompleted
);

/**
 * Gets the provision for withholding tax account.
 */
export const getProvisionForWithholdingTaxAccount = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.provisionForWithholdingTaxAccount
);

/**
 * Determines if there is a running find provision for withholding tax account process.
 */
export const getProvisionForWithholdingTaxAccountFinding = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindingProvisionForWithholdingTaxAccount
);

/**
 * Determines if the last find provision for withholding tax account process has been ended successfully.
 */
export const getProvisionForWithholdingTaxAccountFindCompleted = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindProvisionForWithholdingTaxAccountCompleted
);

/**
 * Gets the bank loans account.
 */
export const getBankLoansAccount = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.bankLoansAccount
);

/**
 * Determines if there is a running find bank loans account process.
 */
export const getBankLoansAccountFinding = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindingBankLoansAccount
);

/**
 * Determines if the last find bank loans account process has been ended successfully.
 */
export const getBankLoansAccountFindCompleted = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindBankLoansAccountCompleted
);

/**
 * Gets the lease liabilities non current portion account.
 */
export const getLeaseLiabilitiesNonCurrentPortionAccount = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.leaseLiabilitiesNonCurrentPortionAccount
);

/**
 * Determines if there is a running find lease liabilities non current portion account process.
 */
export const getLeaseLiabilitiesNonCurrentPortionAccountFinding = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindingLeaseLiabilitiesNonCurrentPortionAccount
);

/**
 * Determines if the last find lease liabilities non current portion account process has been ended successfully.
 */
export const getLeaseLiabilitiesNonCurrentPortionAccountFindCompleted = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindLeaseLiabilitiesNonCurrentPortionAccountCompleted
);

/**
 * Gets the provision for end of severance pay account.
 */
export const getProvisionForEndOfSeverancePayAccount = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.provisionForEndOfSeverancePayAccount
);

/**
 * Determines if there is a running find provision for end of severance pay account process.
 */
export const getProvisionForEndOfSeverancePayAccountFinding = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindingProvisionForEndOfSeverancePayAccount
);

/**
 * Determines if the last find provision for end of severance pay account process has been ended successfully.
 */
export const getProvisionForEndOfSeverancePayAccountFindCompleted = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindProvisionForEndOfSeverancePayAccountCompleted
);

/**
 * Gets the capital account.
 */
export const getCapitalAccount = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.capitalAccount
);

/**
 * Determines if there is a running find capital account process.
 */
export const getCapitalAccountFinding = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindingCapitalAccount
);

/**
 * Determines if the last find capital account process has been ended successfully.
 */
export const getCapitalAccountFindCompleted = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindCapitalAccountCompleted
);

/**
 * Gets the regular reserve account.
 */
export const getRegularReserveAccount = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.regularReserveAccount
);

/**
 * Determines if there is a running find regular reserve account process.
 */
export const getRegularReserveAccountFinding = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindingRegularReserveAccount
);

/**
 * Determines if the last find regular reserve account process has been ended successfully.
 */
export const getRegularReserveAccountFindCompleted = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindRegularReserveAccountCompleted
);

/**
 * Gets the general reserve account.
 */
export const getGeneralReserveAccount = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.generalReserveAccount
);

/**
 * Determines if there is a running find general reserve account process.
 */
export const getGeneralReserveAccountFinding = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindingGeneralReserveAccount
);

/**
 * Determines if the last find general reserve account process has been ended successfully.
 */
export const getGeneralReserveAccountFindCompleted = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindGeneralReserveAccountCompleted
);

/**
 * Gets the retained earnings account.
 */
export const getRetainedEarningsAccount = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.retainedEarningsAccount
);

/**
 * Determines if there is a running find retained earnings account process.
 */
export const getRetainedEarningsAccountFinding = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindingRetainedEarningsAccount
);

/**
 * Determines if the last find retained earnings account process has been ended successfully.
 */
export const getRetainedEarningsAccountFindCompleted = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindRetainedEarningsAccountCompleted
);

/**
 * Gets the profit and loss broker account.
 */
export const getProfitAndLossBrokerAccount = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.profitAndLossBrokerAccount
);

/**
 * Determines if there is a running find profit and loss broker account process.
 */
export const getProfitAndLossBrokerAccountFinding = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindingProfitAndLossBrokerAccount
);

/**
 * Determines if the last find profit and loss broker account process has been ended successfully.
 */
export const getProfitAndLossBrokerAccountFindCompleted = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindProfitAndLossBrokerAccountCompleted
);

/**
 * Gets the additional capital account.
 */
export const getAdditionalCapitalAccount = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.additionalCapitalAccount
);

/**
 * Determines if there is a running find additional capital account process.
 */
export const getAdditionalCapitalAccountFinding = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindingAdditionalCapitalAccount
);

/**
 * Determines if the last find additional capital account process has been ended successfully.
 */
export const getAdditionalCapitalAccountFindCompleted = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindAdditionalCapitalAccountCompleted
);

/**
 * Gets the main activities sales revenue account.
 */
export const getMainActivitiesSalesRevenueAccount = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.mainActivitiesSalesRevenueAccount
);

/**
 * Determines if there is a running find main activities sales revenue account process.
 */
export const getMainActivitiesSalesRevenueAccountFinding = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindingMainActivitiesSalesRevenueAccount
);

/**
 * Determines if the last find main activities sales revenue account process has been ended successfully.
 */
export const getMainActivitiesSalesRevenueAccountFindCompleted = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindMainActivitiesSalesRevenueAccountCompleted
);

/**
 * Gets the gain loss on disposal of property account.
 */
export const getGainLossOnDisposalOfPropertyAccount = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.gainLossOnDisposalOfPropertyAccount
);

/**
 * Determines if there is a running find gain loss on disposal of property account process.
 */
export const getGainLossOnDisposalOfPropertyAccountFinding = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindingGainLossOnDisposalOfPropertyAccount
);

/**
 * Determines if the last find gain loss on disposal of property account process has been ended successfully.
 */
export const getGainLossOnDisposalOfPropertyAccountFindCompleted = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindGainLossOnDisposalOfPropertyAccountCompleted
);

/**
 * Gets the gain loss on investment valuation account.
 */
export const getGainLossOnInvestmentValuationAccount = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.gainLossOnInvestmentValuationAccount
);

/**
 * Determines if there is a running find gain loss on investment valuation account process.
 */
export const getGainLossOnInvestmentValuationAccountFinding = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindingGainLossOnInvestmentValuationAccount
);

/**
 * Determines if the last find gain loss on investment valuation account process has been ended successfully.
 */
export const getGainLossOnInvestmentValuationAccountFindCompleted = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindGainLossOnInvestmentValuationAccountCompleted
);

/**
 * Gets the support revenue account.
 */
export const getSupportRevenueAccount = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.supportRevenueAccount
);

/**
 * Determines if there is a running find support revenue account process.
 */
export const getSupportRevenueAccountFinding = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindingSupportRevenueAccount
);

/**
 * Determines if the last find support revenue account process has been ended successfully.
 */
export const getSupportRevenueAccountFindCompleted = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindSupportRevenueAccountCompleted
);

/**
 * Gets the other miscellaneous income account.
 */
export const getOtherMiscellaneousIncomeAccount = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.otherMiscellaneousIncomeAccount
);

/**
 * Determines if there is a running find other miscellaneous income account process.
 */
export const getOtherMiscellaneousIncomeAccountFinding = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindingOtherMiscellaneousIncomeAccount
);

/**
 * Determines if the last find other miscellaneous income account process has been ended successfully.
 */
export const getOtherMiscellaneousIncomeAccountFindCompleted = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindOtherMiscellaneousIncomeAccountCompleted
);

/**
 * Gets the used of material account.
 */
export const getUsedOfMaterialAccount = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.usedOfMaterialAccount
);

/**
 * Determines if there is a running find used of material account process.
 */
export const getUsedOfMaterialAccountFinding = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindingUsedOfMaterialAccount
);

/**
 * Determines if the last find used of material account process has been ended successfully.
 */
export const getUsedOfMaterialAccountFindCompleted = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindUsedOfMaterialAccountCompleted
);

/**
 * Gets the non inventory material costs account.
 */
export const getNonInventoryMaterialCostsAccount = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.nonInventoryMaterialCostsAccount
);

/**
 * Determines if there is a running find non inventory material costs account process.
 */
export const getNonInventoryMaterialCostsAccountFinding = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindingNonInventoryMaterialCostsAccount
);

/**
 * Determines if the last find non inventory material costs account process has been ended successfully.
 */
export const getNonInventoryMaterialCostsAccountFindCompleted = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindNonInventoryMaterialCostsAccountCompleted
);

/**
 * Gets the rent account.
 */
export const getRentAccount = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.rentAccount
);

/**
 * Determines if there is a running find rent account process.
 */
export const getRentAccountFinding = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindingRentAccount
);

/**
 * Determines if the last find rent account process has been ended successfully.
 */
export const getRentAccountFindCompleted = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindRentAccountCompleted
);

/**
 * Gets the local labor account.
 */
export const getLocalLaborAccount = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.localLaborAccount
);

/**
 * Determines if there is a running find local labor account process.
 */
export const getLocalLaborAccountFinding = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindingLocalLaborAccount
);

/**
 * Determines if the last find local labor account process has been ended successfully.
 */
export const getLocalLaborAccountFindCompleted = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindLocalLaborAccountCompleted
);

/**
 * Gets the salaries and wages employee protection account.
 */
export const getSalariesAndWagesEmployeeProtectionAccount = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.salariesAndWagesEmployeeProtectionAccount
);

/**
 * Determines if there is a running find salaries and wages employee protection account process.
 */
export const getSalariesAndWagesEmployeeProtectionAccountFinding = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindingSalariesAndWagesEmployeeProtectionAccount
);

/**
 * Determines if the last find salaries and wages employee protection account process has been ended successfully.
 */
export const getSalariesAndWagesEmployeeProtectionAccountFindCompleted = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindSalariesAndWagesEmployeeProtectionAccountCompleted
);

/**
 * Gets the benefit to employees all costs except wage protection elements account.
 */
export const getBenefitToEmployeesAllCostsExceptWageProtectionElementsAccount = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.benefitToEmployeesAllCostsExceptWageProtectionElementsAccount
);

/**
 * Determines if there is a running find benefit to employees all costs except wage protection elements account process.
 */
export const getBenefitToEmployeesAllCostsExceptWageProtectionElementsAccountFinding = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindingBenefitToEmployeesAllCostsExceptWageProtectionElementsAccount
);

/**
 * Determines if the last find benefit to employees all costs except wage protection elements account process has been ended successfully.
 */
export const getBenefitToEmployeesAllCostsExceptWageProtectionElementsAccountFindCompleted = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindBenefitToEmployeesAllCostsExceptWageProtectionElementsAccountCompleted
);

/**
 * Gets the work missions and overtime account.
 */
export const getWorkMissionsAndOvertimeAccount = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.workMissionsAndOvertimeAccount
);

/**
 * Determines if there is a running find work missions and overtime account process.
 */
export const getWorkMissionsAndOvertimeAccountFinding = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindingWorkMissionsAndOvertimeAccount
);

/**
 * Determines if the last find work missions and overtime account process has been ended successfully.
 */
export const getWorkMissionsAndOvertimeAccountFindCompleted = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindWorkMissionsAndOvertimeAccountCompleted
);

/**
 * Gets the repair and maintenance account.
 */
export const getRepairAndMaintenanceAccount = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.repairAndMaintenanceAccount
);

/**
 * Determines if there is a running find repair and maintenance account process.
 */
export const getRepairAndMaintenanceAccountFinding = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindingRepairAndMaintenanceAccount
);

/**
 * Determines if the last find repair and maintenance account process has been ended successfully.
 */
export const getRepairAndMaintenanceAccountFindCompleted = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindRepairAndMaintenanceAccountCompleted
);

/**
 * Gets the government fees and subscriptions account.
 */
export const getGovernmentFeesAndSubscriptionsAccount = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.governmentFeesAndSubscriptionsAccount
);

/**
 * Determines if there is a running find government fees and subscriptions account process.
 */
export const getGovernmentFeesAndSubscriptionsAccountFinding = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindingGovernmentFeesAndSubscriptionsAccount
);

/**
 * Determines if the last find government fees and subscriptions account process has been ended successfully.
 */
export const getGovernmentFeesAndSubscriptionsAccountFindCompleted = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindGovernmentFeesAndSubscriptionsAccountCompleted
);

/**
 * Gets the cost of public services account.
 */
export const getTheCostOfPublicServicesAccount = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.theCostOfPublicServicesAccount
);

/**
 * Determines if there is a running find cost of public services account process.
 */
export const getTheCostOfPublicServicesAccountFinding = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindingTheCostOfPublicServicesAccount
);

/**
 * Determines if the last find cost of public services account process has been ended successfully.
 */
export const getTheCostOfPublicServicesAccountFindCompleted = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindTheCostOfPublicServicesAccountCompleted
);

/**
 * Gets the hospitality and cleanliness account.
 */
export const getHospitalityAndCleanlinessAccount = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.hospitalityAndCleanlinessAccount
);

/**
 * Determines if there is a running find hospitality and cleanliness account process.
 */
export const getHospitalityAndCleanlinessAccountFinding = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindingHospitalityAndCleanlinessAccount
);

/**
 * Determines if the last find hospitality and cleanliness account process has been ended successfully.
 */
export const getHospitalityAndCleanlinessAccountFindCompleted = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindHospitalityAndCleanlinessAccountCompleted
);

/**
 * Gets the office consumables account.
 */
export const getOfficeConsumablesAccount = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.officeConsumablesAccount
);

/**
 * Determines if there is a running find office consumables account process.
 */
export const getOfficeConsumablesAccountFinding = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindingOfficeConsumablesAccount
);

/**
 * Determines if the last find office consumables account process has been ended successfully.
 */
export const getOfficeConsumablesAccountFindCompleted = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindOfficeConsumablesAccountCompleted
);

/**
 * Gets the finance charges loans and bank facilities account.
 */
export const getFinanceChargesLoansAndBankFacilitiesAccount = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.financeChargesLoansAndBankFacilitiesAccount
);

/**
 * Determines if there is a running find finance charges loans and bank facilities account process.
 */
export const getFinanceChargesLoansAndBankFacilitiesAccountFinding = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindingFinanceChargesLoansAndBankFacilitiesAccount
);

/**
 * Determines if the last find finance charges loans and bank facilities account process has been ended successfully.
 */
export const getFinanceChargesLoansAndBankFacilitiesAccountFindCompleted = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindFinanceChargesLoansAndBankFacilitiesAccountCompleted
);

/**
 * Gets the finance charges lease contracts account.
 */
export const getFinanceChargesLeaseContractsAccount = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.financeChargesLeaseContractsAccount
);

/**
 * Determines if there is a running find finance finance charges lease contracts account process.
 */
export const getFinanceChargesLeaseContractsAccountFinding = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindingFinanceChargesLeaseContractsAccount
);

/**
 * Determines if the last find finance charges lease contracts account process has been ended successfully.
 */
export const getFinanceChargesLeaseContractsAccountFindCompleted = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindFinanceChargesLeaseContractsAccountCompleted
);

/**
 * Gets the commissions account.
 */
export const getCommissionsAccount = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.commissionsAccount
);

/**
 * Determines if there is a running find commissions account process.
 */
export const getCommissionsAccountFinding = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindingCommissionsAccount
);

/**
 * Determines if the last find commissions account process has been ended successfully.
 */
export const getCommissionsAccountFindCompleted = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindCommissionsAccountCompleted
);

/**
 * Gets the bank expenses account.
 */
export const getBankExpensesAccount = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.bankExpensesAccount
);

/**
 * Determines if there is a running find bank expenses account process.
 */
export const getBankExpensesAccountFinding = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindingBankExpensesAccount
);

/**
 * Determines if the last find bank expenses account process has been ended successfully.
 */
export const getBankExpensesAccountFindCompleted = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindBankExpensesAccountCompleted
);

/**
 * Gets the cost of revenue and general expenses others account.
 */
export const getCostOfRevenueAndGeneralExpensesOthersAccount = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.costOfRevenueAndGeneralExpensesOthersAccount
);

/**
 * Determines if there is a running find cost of revenue and general expenses others account process.
 */
export const getCostOfRevenueAndGeneralExpensesOthersAccountFinding = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindingCostOfRevenueAndGeneralExpensesOthersAccount
);

/**
 * Determines if the last find cost of revenue and general expenses others account process has been ended successfully.
 */
export const getCostOfRevenueAndGeneralExpensesOthersAccountFindCompleted = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindCostOfRevenueAndGeneralExpensesOthersAccountCompleted
);

/**
 * Gets the building depreciation express account.
 */
export const getBuildingDepreciationExpressAccount = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.buildingDepreciationExpressAccount
);

/**
 * Determines if there is a running find building depreciation express account process.
 */
export const getBuildingDepreciationExpressAccountFinding = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindingBuildingDepreciationExpressAccount
);

/**
 * Determines if the last find building depreciation express account process has been ended successfully.
 */
export const getBuildingDepreciationExpressAccountFindCompleted = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindBuildingDepreciationExpressAccountCompleted
);

/**
 * Gets the furniture depreciation express account.
 */
export const getFurnitureDepreciationExpressAccount = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.furnitureDepreciationExpressAccount
);

/**
 * Determines if there is a running find furniture depreciation express account process.
 */
export const getFurnitureDepreciationExpressAccountFinding = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindingFurnitureDepreciationExpressAccount
);

/**
 * Determines if the last find furniture depreciation express account process has been ended successfully.
 */
export const getFurnitureDepreciationExpressAccountFindCompleted = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindFurnitureDepreciationExpressAccountCompleted
);

/**
 * Gets the devices depreciation express account.
 */
export const getDevicesDepreciationExpressAccount = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.devicesDepreciationExpressAccount
);

/**
 * Determines if there is a running find devices depreciation express account process.
 */
export const getDevicesDepreciationExpressAccountFinding = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindingDevicesDepreciationExpressAccount
);

/**
 * Determines if the last find devices depreciation express account process has been ended successfully.
 */
export const getDevicesDepreciationExpressAccountFindCompleted = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindDevicesDepreciationExpressAccountCompleted
);

/**
 * Gets the computers depreciation express account.
 */
export const getComputersDepreciationExpressAccount = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.computersDepreciationExpressAccount
);

/**
 * Determines if there is a running find computers depreciation express account process.
 */
export const getComputersDepreciationExpressAccountFinding = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindingComputersDepreciationExpressAccount
);

/**
 * Determines if the last find computers depreciation express account process has been ended successfully.
 */
export const getComputersDepreciationExpressAccountFindCompleted = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindComputersDepreciationExpressAccountCompleted
);

/**
 * Gets the equipments depreciation express account.
 */
export const getEquipmentsDepreciationExpressAccount = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.equipmentsDepreciationExpressAccount
);

/**
 * Determines if there is a running find equipments depreciation express account process.
 */
export const getEquipmentsDepreciationExpressAccountFinding = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindingEquipmentsDepreciationExpressAccount
);

/**
 * Determines if the last find equipments depreciation express account process has been ended successfully.
 */
export const getEquipmentsDepreciationExpressAccountFindCompleted = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindEquipmentsDepreciationExpressAccountCompleted
);

/**
 * Gets the cars depreciation express account.
 */
export const getCarsDepreciationExpressAccount = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.carsDepreciationExpressAccount
);

/**
 * Determines if there is a running find cars depreciation express account process.
 */
export const getCarsDepreciationExpressAccountFinding = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindingCarsDepreciationExpressAccount
);

/**
 * Determines if the last find cars depreciation express account process has been ended successfully.
 */
export const getCarsDepreciationExpressAccountFindCompleted = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindCarsDepreciationExpressAccountCompleted
);

/**
 * Gets the assets under finance leases depreciation expenses account.
 */
export const getAssetsUnderFinanceLeasesDepreciationExpensesAccount = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.assetsUnderFinanceLeasesDepreciationExpensesAccount
);

/**
 * Determines if there is a running find assets under finance leases depreciation expenses account process.
 */
export const getAssetsUnderFinanceLeasesDepreciationExpensesAccountFinding = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindingAssetsUnderFinanceLeasesDepreciationExpensesAccount
);

/**
 * Determines if the last find assets under finance leases depreciation expenses account process has been ended successfully.
 */
export const getAssetsUnderFinanceLeasesDepreciationExpensesAccountFindCompleted = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindAssetsUnderFinanceLeasesDepreciationExpensesAccountCompleted
);

/**
 * Gets the fame depreciation expenses account.
 */
export const getFameDepreciationExpensesAccount = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.fameDepreciationExpensesAccount
);

/**
 * Determines if there is a running find fame depreciation expenses account process.
 */
export const getFameDepreciationExpensesAccountFinding = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindingFameDepreciationExpensesAccount
);

/**
 * Determines if the last find fame depreciation expenses account process has been ended successfully.
 */
export const getFameDepreciationExpensesAccountFindCompleted = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindFameDepreciationExpensesAccountCompleted
);

/**
 * Gets the software and systems depreciation expenses account.
 */
export const getSoftwareAndSystemsDepreciationExpensesAccount = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.softwareAndSystemsDepreciationExpensesAccount
);

/**
 * Determines if there is a running find software and systems depreciation expenses account process.
 */
export const getSoftwareAndSystemsDepreciationExpensesAccountFinding = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindingSoftwareAndSystemsDepreciationExpensesAccount
);

/**
 * Determines if the last find software and systems depreciation expenses account process has been ended successfully.
 */
export const getSoftwareAndSystemsDepreciationExpensesAccountFindCompleted = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindSoftwareAndSystemsDepreciationExpensesAccountCompleted
);

/**
 * Gets the patents and trade marks depreciation expenses account.
 */
export const getPatentsAndTrademarksDepreciationExpensesAccount = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.patentsAndTrademarksDepreciationExpensesAccount
);

/**
 * Determines if there is a running find patents and trade marks depreciation expenses account process.
 */
export const getPatentsAndTrademarksDepreciationExpensesAccountFinding = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindingPatentsAndTrademarksDepreciationExpensesAccount
);

/**
 * Determines if the last find patents and trade marks depreciation expenses account process has been ended successfully.
 */
export const getPatentsAndTrademarksDepreciationExpensesAccountFindCompleted = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindPatentsAndTrademarksDepreciationExpensesAccountCompleted
);

/**
 * Gets the real estate investment buildings depreciation expenses account.
 */
export const getRealEstateInvestmentBuildingsDepreciationExpensesAccount = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.realEstateInvestmentBuildingsDepreciationExpensesAccount
);

/**
 * Determines if there is a running find real estate investment buildings depreciation expenses account process.
 */
export const getRealEstateInvestmentBuildingsDepreciationExpensesAccountFinding = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindingRealEstateInvestmentBuildingsDepreciationExpensesAccount
);

/**
 * Determines if the last find real estate investment buildings depreciation expenses account process has been ended successfully.
 */
export const getRealEstateInvestmentBuildingsDepreciationExpensesAccountFindCompleted = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindRealEstateInvestmentBuildingsDepreciationExpensesAccountCompleted
);

/**
 * Gets the assets broker account.
 */
export const getAssetsBrokerAccount = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.assetsBrokerAccount
);

/**
 * Determines if there is a running find assets broker account process.
 */
export const getAssetsBrokerAccountFinding = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindingAssetsBrokerAccount
);

/**
 * Determines if the last find assets broker account process has been ended successfully.
 */
export const getAssetsBrokerAccountFindCompleted = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindAssetsBrokerAccountCompleted
);

/**
 * Gets the currency rounding difference account.
 */
export const getCurrencyRoundingDifferenceAccount = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.currencyRoundingDifferenceAccount
);

/**
 * Determines if there is a running find currency rounding difference account process.
 */
export const getCurrencyRoundingDifferenceAccountFinding = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindingCurrencyRoundingDifferenceAccount
);

/**
 * Determines if the last find currency rounding difference account process has been ended successfully.
 */
export const getCurrencyRoundingDifferenceAccountFindCompleted = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindCurrencyRoundingDifferenceAccountCompleted
);

/**
 * Gets the gosi accrual account.
 */
export const getGosiAccrualAccount = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.gosiAccrualAccount
);

/**
 * Determines if there is a running find gosi accrual account process.
 */
export const getGosiAccrualAccountFinding = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindingGosiAccrualAccount
);

/**
 * Determines if the last find gosi accrual account process has been ended successfully.
 */
export const getGosiAccrualAccountFindCompleted = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindGosiAccrualAccountCompleted
);

/**
 * Gets the gosi expenses account.
 */
export const getGosiExpensesAccount = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.gosiExpensesAccount
);

/**
 * Determines if there is a running find gosi expenses account process.
 */
export const getGosiExpensesAccountFinding = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindingGosiExpensesAccount
);

/**
 * Determines if the last find gosi expenses account process has been ended successfully.
 */
export const getGosiExpensesAccountFindCompleted = createSelector(
  selectSystemAccountState,
  (state: SystemAccountsState) => state.isFindGosiExpensesAccountCompleted
);
