import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import { Observable, Subscription } from 'rxjs';
import { skip, tap } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { PageInfo, NotificationService, NotificationMessage, TranslationService } from 'shared';
import * as fromStoresStore from 'stores/store';

@Component({
  selector: 'app-create-unit-of-measure-class',
  templateUrl: './create-unit-of-measure-class.component.html',
  styles: [],
})
export class CreateUnitOfMeasureClassComponent implements OnInit, OnDestroy {
  /**
   * The confirm modal template reference.
   */
  @ViewChild('confirmModalRef') confirmModalRef: ElementRef<any>;

  /**
   * Gets or sets the information about the current page.
   */
  pageInfo: PageInfo = {
    title: 'STORES.UNIT_OF_MEASURE_CLASSES.CREATE_UNIT_OF_MEASURE_CLASS_TITLE',
    icon: 'fa fa-plus',
  };

  /**
   * Indicates whether there is a create unit of measure class process is running or not.
   */
  isCreating$: Observable<boolean>;

  /**
   * The create unit of measure class form.
   */
  form: FormGroup;

  /**
   * The set of subscriptions on this components,
   * these subscriptions must be unsubscribed before this component got destroyed.
   */
  subscriptions = new Subscription();

  /**
   * @param modalService The modal service.
   * @param notificationService The notification service.
   * @param storesStore$  The stores store.
   * @param translationService The translation service.
   */
  constructor(
    private modalService: NgbModal,
    private notificationService: NotificationService,
    private storesStore$: Store<fromStoresStore.StoresState>,
    private translationService: TranslationService
  ) {}

  ngOnInit(): void {
    this.init();
  }

  /**
   * Destroy component data
   */
  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  /**
   * Initialize component data.
   */
  init() {
    this.initForm();

    /**
     * Reset form controls when new unit of measure class is created.
     */
    this.subscriptions.add(
      this.storesStore$
        .pipe(
          select(fromStoresStore.getSelectedUnitOfMeasureClassCreateCompleted),
          skip(1),
          tap((isCreated) => {
            if (isCreated) {
              this.initForm();
            }
          })
        )
        .subscribe()
    );

    /**
     * Load data.
     */
    this.isCreating$ = this.storesStore$.pipe(select(fromStoresStore.getSelectedUnitOfMeasureClassCreating));
  }

  /**
   * Initialize form and add validators.
   */
  initForm() {
    this.form = new FormGroup({
      name: new FormControl('', [Validators.required]),
      nameEn: new FormControl('', [Validators.required]),
    });
  }

  /**
   * Submits the form.
   */
  submit() {
    if (this.form.invalid) {
      const errorMessage = new NotificationMessage();

      if (this.form.get('name').invalid) {
        errorMessage.title = this.translationService.translate(
          'STORES.UNIT_OF_MEASURE_CLASSES.UNIT_OF_MEASURE_CLASS_DATA_VALIDATION.NAME_ARABIC_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate(
            'STORES.UNIT_OF_MEASURE_CLASSES.UNIT_OF_MEASURE_CLASS_DATA_VALIDATION.NAME_IS_REQUIRED'
          ),
        ];
      } else if (this.form.get('nameEn').invalid) {
        errorMessage.title = this.translationService.translate(
          'STORES.UNIT_OF_MEASURE_CLASSES.UNIT_OF_MEASURE_CLASS_DATA_VALIDATION.NAME_ENGLISH_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate(
            'STORES.UNIT_OF_MEASURE_CLASSES.UNIT_OF_MEASURE_CLASS_DATA_VALIDATION.NAME_IS_REQUIRED'
          ),
        ];
      }
      this.form.markAllAsTouched();
      return this.notificationService.warningWithTitle(errorMessage);
    } else {
      this.openModal(this.confirmModalRef);
    }
  }

  /**
   * Confirms the form submit.
   */
  confirm() {
    const { name, nameEn } = this.form.value;

    this.storesStore$.dispatch(
      new fromStoresStore.CreateUnitOfMeasureClass({
        name,
        nameEn,
      })
    );
  }

  /**
   * Opens the modal of the given templateRef.
   * @param modalRef The modal templateRef to be opened.
   */
  openModal(modalRef) {
    this.modalService.open(modalRef);
  }
}
