import { LoanSettlementTypeActionType, LoanSettlementTypeActions } from 'lookups/store/actions';
import { LoanSettlementTypeState } from 'lookups/store/states';

/**
 * The initial state from which the state starts.
 */
const initialState: LoanSettlementTypeState = {
  data: [],
  error: null,
  isLoaded: false,
  isLoading: false,
};

/**
 * The reducer function that is called in each action dispatch against the state.
 * @param state The current state.
 * @param action The action that will affect the state.
 */
export function loanSettlementTypeReducer(
  state: LoanSettlementTypeState = initialState,
  action: LoanSettlementTypeActions
): LoanSettlementTypeState {
  switch (action.type) {
    case LoanSettlementTypeActionType.GET_ALL_LOAN_SETTLEMENT_TYPES: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case LoanSettlementTypeActionType.GET_ALL_LOAN_SETTLEMENT_TYPES_FAIL: {
      return {
        ...state,
        error: action.payload,
        isLoading: false,
        isLoaded: false,
      };
    }

    case LoanSettlementTypeActionType.GET_ALL_LOAN_SETTLEMENT_TYPES_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        isLoading: false,
        isLoaded: true,
      };
    }

    default:
      return state;
  }
}
