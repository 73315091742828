import { NotFoundComponent } from 'core/components/not-found/not-found.component';
import { ForbiddenComponent } from 'core/components/forbidden/forbidden.component';
import { SplashScreenComponent } from 'core/components/splash-screen/splash-screen.component';
import { NavbarComponent } from 'core/components/navbar/navbar.component';
import { SidebarComponent } from 'core/components/sidebar/sidebar.component';
import { FooterComponent } from 'core/components/footer/footer.component';
import { DashboardComponent } from 'core/components/dashboard/dashboard.component';
import { GeneralLoaderComponent } from 'core/components/general-loader/general-loader.component';

export * from 'core/components/not-found/not-found.component';
export * from 'core/components/forbidden/forbidden.component';
export * from 'core/components/splash-screen/splash-screen.component';
export * from 'core/components/navbar/navbar.component';
export * from 'core/components/sidebar/sidebar.component';
export * from 'core/components/footer/footer.component';
export * from 'core/components/dashboard/dashboard.component';

/**
 * The set of components registered on this module.
 */
export const COMPONENTS: any[] = [
  NotFoundComponent,
  ForbiddenComponent,
  SplashScreenComponent,
  NavbarComponent,
  SidebarComponent,
  FooterComponent,
  DashboardComponent,
  GeneralLoaderComponent,
];
