import { Action } from '@ngrx/store';

import { PrintPaperSize } from 'lookups/models';
import { AppHttpResponse } from 'shared';

/**
 * The Lookups-Module store  print paper size action types.
 */
export enum PrintPaperSizeActionType {
  GET_ALL_PRINT_PAPER_SIZE = '[Lookups] [PrintPaperSize] Get All Print Paper Size',
  GET_ALL_PRINT_PAPER_SIZE_FAIL = '[Lookups] [PrintPaperSize] Get All Print Paper Size Fail',
  GET_ALL_PRINT_PAPER_SIZE_SUCCESS = '[Lookups] [PrintPaperSize] Get All Print Paper Size Success',
}

/**
 * Represents a store  print paper size action.
 */
export class GetAllPrintPaperSize implements Action {
  /**
   * The type of the action.
   */
  readonly type = PrintPaperSizeActionType.GET_ALL_PRINT_PAPER_SIZE;

  /**
   * Fires a new  print paper size action.
   */
  constructor() {}
}

/**
 * Represents a store  print paper size fail action.
 */
export class GetAllPrintPaperSizeFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = PrintPaperSizeActionType.GET_ALL_PRINT_PAPER_SIZE_FAIL;

  /**
   * Fires a new  print paper size fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store  print paper size success action.
 */
export class GetAllPrintPaperSizeSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = PrintPaperSizeActionType.GET_ALL_PRINT_PAPER_SIZE_SUCCESS;

  /**
   * Fires a  print paper size success action.
   * @param payload An object contains the list of  time zones.
   */
  constructor(public payload: AppHttpResponse<PrintPaperSize[]>) {}
}

/**
 * Lookups-module  print paper size action types.
 */
export type PrintPaperSizeActions = GetAllPrintPaperSize | GetAllPrintPaperSizeFail | GetAllPrintPaperSizeSuccess;
