import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, LOCALE_ID } from '@angular/core';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

/* Add support for internationalizations e.g. (currency, date). */
import { registerLocaleData } from '@angular/common';
import localeAr from '@angular/common/locales/ar';
registerLocaleData(localeAr);

import { ToastrModule } from 'ngx-toastr';
import { TranslateModule, TranslateLoader, MissingTranslationHandler } from '@ngx-translate/core';
/* Configure perfect-scrollbar. */
import {
  PerfectScrollbarModule,
  PERFECT_SCROLLBAR_CONFIG,
  PerfectScrollbarConfigInterface,
} from 'ngx-perfect-scrollbar';
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelPropagation: false,
};

import { CoreRoutingModule } from 'core/core-routing.module';
import { MissingTranslationHelper, SharedModule, TranslateHttpLoaderHelper } from 'shared';
import { WebSocketModule } from 'websocket';
import { SettingsModule } from 'settings';
import { InterceptorService } from 'core/services';
import { AuthModule } from 'auth';
import { LookupsModule } from 'lookups';
import { SecurityModule } from 'security';
import { ProjectsManagementModule } from 'projects-management';
import { StatisticsModule } from 'statistics';
import { PrintingModule } from 'printing';

import * as fromContainers from 'core/containers';
import * as fromComponents from 'core/components';
import * as fromServices from 'core/services';
import * as fromDirectives from 'core/directives';

/* Ngrx */
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { StoreModule } from '@ngrx/store';
import { RouterStateSerializer, StoreRouterConnectingModule } from '@ngrx/router-store';
import { EffectsModule } from '@ngrx/effects';
import * as fromStore from 'core/store';

import { environment } from '../../environments/environment';

/**
 * The entry point for this application.
 */
@NgModule({
  declarations: [...fromContainers.CONTAINERS, ...fromComponents.COMPONENTS, ...fromDirectives.DIRECTIVES],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    CoreRoutingModule,
    PerfectScrollbarModule,
    SecurityModule,
    ProjectsManagementModule,
    StatisticsModule,
    PrintingModule,

    SharedModule.forRoot(),
    WebSocketModule.forRoot(),
    SettingsModule.forRoot(),
    AuthModule.forRoot(),
    LookupsModule.forRoot(),

    ToastrModule.forRoot(),
    StoreModule.forRoot(fromStore.reducers, {
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
        strictStateSerializability: false,
        strictActionSerializability: false,
      },
    }),
    EffectsModule.forRoot(fromStore.effects),
    StoreRouterConnectingModule.forRoot({
      stateKey: 'router',
    }),

    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: TranslateHttpLoaderHelper,
        deps: [HttpClient],
      },
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: MissingTranslationHelper,
      },
    }),

    environment.production ? [] : StoreDevtoolsModule.instrument(),
  ],
  providers: [
    {
      provide: LOCALE_ID,
      useValue: 'ar',
    },
    ...fromServices.SERVICES,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorService,
      multi: true,
    },
    {
      provide: RouterStateSerializer,
      useClass: fromStore.CustomSerializer,
    },
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
  ],
  bootstrap: [fromContainers.RootComponent],
})
export class CoreModule {}
