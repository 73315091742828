import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms';

import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { skip, tap } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Decimal } from 'decimal.js';

import { NotificationService, NotificationMessage, TranslationService, CustomValidators, APP_CONSTANTS } from 'shared';
import * as fromFinancesStore from 'finances/store';
import * as fromLookupsStore from 'lookups/store';
import { CanComponentDeactivate } from 'auth/models';
import { Department, Employee, Payroll, PayrollItem } from 'hr/models';
import { Location } from 'stores/models';
import { EmployeePaymentMethod, EmployeePaymentMethods } from 'lookups/models';
import { BankAccount } from 'finances/models';

@Component({
  selector: 'app-create-payroll-payment',
  templateUrl: './create-payroll-payment.component.html',
  styles: [],
})
export class CreatePayrollPaymentComponent implements OnInit, OnDestroy, CanComponentDeactivate {
  /**
   * The confirm modal template reference.
   */
  @ViewChild('confirmModalRef') confirmModalRef: ElementRef<any>;

  /**
   * The confirm review modal template reference.
   */
  @ViewChild('confirmPayModalRef') confirmPayModalRef: ElementRef<any>;

  /**
   * The list of paying types.
   */
  payingTypes = [
    { id: 1, key: 'EMPLOYEES', name: 'الموظفين', nameEn: 'Employees' },
    { id: 2, key: 'LOCATIONS', name: 'المواقع', nameEn: 'Locations' },
    { id: 3, key: 'DEPARTMENTS', name: 'الإدارات', nameEn: 'Departments' },
  ];

  /**
   * The list of employee payment method.
   */
  employeePaymentMethods$: Observable<EmployeePaymentMethod[]>;

  /**
   * The list of bank accounts.
   */
  bankAccounts: BankAccount[] = [];

  /**
   * Gets or sets the id of the employee payment method for `CASH`.
   */
  employeePaymentMethodIdForCash: number;

  /**
   * Gets or sets the id of the employee payment method for `BANK_TRANSFER`.
   */
  employeePaymentMethodIdForBankTransfer: number;

  /**
   * Gets or sets the id of the employee paying type for `EMPLOYEES`.
   */
  payingTypeForEmployeeId: number;

  /**
   * Gets or sets the id of the employee paying type for `DEPARTMENTS`.
   */
  payingTypeForDepartmentId: number;

  /**
   * Gets or sets the id of the employee paying type for `LOCATIONS`.
   */
  payingTypeForLocationId: number;

  /**
   * Indicates whether there is a pay payrolls process is running or not.
   */
  isInPaying$: Observable<boolean>;

  /**
   * The decimal mask.
   */
  readonly DECIMAL_MASK = APP_CONSTANTS.numeric.decimal.mask;

  /**
   * Indicates whether there is a pay payrolls process is complete or not.
   */
  isInPayCompleted$: Observable<boolean>;

  /**
   * Display payrolls form default false.
   */
  displayPayrollsResult = false;

  /**
   * The pay payrolls form.
   */
  form: FormGroup;

  /**
   * The pay payrolls form.
   */
  paidPayrollsForm: FormGroup;

  /**
   * Shows or hide the bank accounts list.
   */
  bankAccountsListVisibility = false;

  /**
   * Used to add new payroll form items to the payrolls form.
   */
  newPayrolls: PayrollItem[];

  /**
   * The list of selected employees.
   */
  employees: Employee[] = [];

  /**
   * The list of selected departments.
   */
  departments: Department[] = [];

  /**
   * The list of selected locations.
   */
  locations: Location[] = [];

  /**
   * Shows or hide the employees list.
   */
  employeesListVisibility = false;

  /**
   * Shows or hide the departments list.
   */
  departmentsListVisibility = false;

  /**
   * Shows or hide the locations list.
   */
  locationsListVisibility = false;

  /**
   * Shows or hide the payrolls form.
   */
  isDisplayPayrolls = false;

  /**
   * Gets or sets the payrolls net values.
   */
  get payrollsNet(): number {
    return Decimal.sum(...(this.payrollsForm?.value.map((item: PayrollItem) => item.net) ?? [])).toNumber();
  }

  /**
   * Get bank fee value.
   */
  get bankFee(): number {
    return this.paidPayrollsForm.value?.bankFee ?? 0;
  }

  /**
   * Get bank fee tax value.
   */
  get bankFeeTaxValue(): number {
    return this.paidPayrollsForm.value?.bankFeeTaxValue ?? 0;
  }
  /**
   * Gets the payment net.
   */

  get paymentNet(): number {
    return Decimal.sum(this.payrollsNet, this.bankFee, this.bankFeeTaxValue).toNumber();
  }

  /**
   * Sets the initial payrolls should be added to the payrolls form.
   * @param payrolls An object contains `count` of initial blank payrolls.
   * @param payrolls A single payroll details to be added to the form.
   * @param payrolls A list of payrolls details to be added to the form.
   */
  initialPayrollsSubject: BehaviorSubject<{ count: number } | PayrollItem | PayrollItem[]> = new BehaviorSubject(
    undefined
  );

  /**
   * The set of subscriptions on this components,
   * these subscriptions must be unsubscribed before this component got destroyed.
   */
  subscriptions = new Subscription();

  /**
   * Gets the attachments form-array.
   */
  get attachmentsForm(): FormArray {
    return this.paidPayrollsForm?.controls.attachments as FormArray;
  }

  /**
   * Gets the payrolls form-array.
   */
  get payrollsForm(): FormArray {
    return this.paidPayrollsForm?.controls.payrolls as FormArray;
  }

  /**
   * @param modalService The modal service.
   * @param notificationService The notification service.
   * @param financesStore$ the finances-store module.
   * @param lookupsStore$ The lookups-store module.
   * @param translationService The translation service.
   */
  constructor(
    private modalService: NgbModal,
    private notificationService: NotificationService,
    private financesStore$: Store<fromFinancesStore.FinancesState>,
    private lookupsStore$: Store<fromLookupsStore.LookupsState>,
    private translationService: TranslationService
  ) {}

  ngOnInit(): void {
    this.init();
  }

  /**
   * Unsubscribes for component data.
   */
  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  /**
   * Initialize component data.
   */
  init() {
    this.initForm();
    this.initPaidForm();

    this.subscriptions.add(
      this.financesStore$
        .pipe(
          select(fromFinancesStore.getSelectedPayrollPaymentCreateCompleted),
          skip(1),
          tap((isPay) => {
            if (isPay) {
              this.initForm();
              this.initPaidForm();
              this.isDisplayPayrolls = false;
            }
          })
        )
        .subscribe()
    );

    /** Load data. */
    let isManualSearchTriggeredBeforeForEmployeePaymentMethods = false;
    this.employeePaymentMethods$ = this.lookupsStore$.pipe(
      select(fromLookupsStore.getEmployeePaymentMethods),
      tap((employeePaymentMethods) => {
        if (!isManualSearchTriggeredBeforeForEmployeePaymentMethods && !employeePaymentMethods.length) {
          isManualSearchTriggeredBeforeForEmployeePaymentMethods = true;
          this.lookupsStore$.dispatch(new fromLookupsStore.GetAllEmployeePaymentMethods());
        } else if (employeePaymentMethods?.length) {
          this.employeePaymentMethodIdForCash = employeePaymentMethods?.find(
            (employeePaymentMethod) => employeePaymentMethod.key === EmployeePaymentMethods.CASH
          )?.id;
          this.employeePaymentMethodIdForBankTransfer = employeePaymentMethods?.find(
            (employeePaymentMethod) => employeePaymentMethod.key === EmployeePaymentMethods.BANK_TRANSFER
          )?.id;
        }
      })
    );

    this.payingTypeForDepartmentId = this.payingTypes?.find((payingType) => payingType.key === 'DEPARTMENTS')?.id;
    this.payingTypeForLocationId = this.payingTypes?.find((payingType) => payingType.key === 'LOCATIONS')?.id;
    this.payingTypeForEmployeeId = this.payingTypes?.find((payingType) => payingType.key === 'EMPLOYEES')?.id;

    /**
     * Reset form controls when new payrolls is paid.
     */
    this.subscriptions.add(
      this.financesStore$
        .pipe(
          select(fromFinancesStore.getSelectedPayPayrollCompleted),
          skip(1),
          tap((isPaid) => {
            if (isPaid) {
              this.initPaidForm();
              this.isDisplayPayrolls = true;
            }
          })
        )
        .subscribe()
    );

    /**
     * Reset form controls when new payrolls is paid.
     */

    this.subscriptions.add(
      this.financesStore$
        .pipe(
          select(fromFinancesStore.getPreparedPaidPayrolls),
          tap((payrolls) => {
            this.newPayrolls = payrolls.map((payroll: Payroll) => ({
              id: payroll.id,
              employeeId: payroll.employeeId,
              employee: payroll.employee,
              net: payroll.net,
              salary: payroll.salary,
              salaryForGosi: payroll.salaryForGosi,
              employeeGosi: payroll.employeeGosi,
              employerGosi: payroll.employerGosi,
              employeeSponsor: payroll.employeeSponsor,
              employeeSponsorId: payroll.employeeSponsorId,
              payrollGosiPayrollElements: payroll.payrollGosiPayrollElements,
              payrollDate: payroll.payrollDate,
              year: payroll.year,
              month: payroll.month,
              workDays: payroll.workDays,
              payrollRecurringElements: payroll.payrollRecurringElements,
              payrollLoanSettlements: payroll.payrollLoanSettlements,
              payrollOvertimes: payroll.payrollOvertimes,
              payrollOtherEarnings: payroll.payrollOtherEarnings,
              deductions: payroll.deductions,
              isOutKafala: payroll.isOutKafala,
              payrollStatus: payroll.payrollStatus,
              payrollDeductions: payroll.payrollDeductions,
              loanSettlement: payroll.loanSettlement,
              otherEarnings: payroll.otherEarnings,
              payrollProvisions: payroll.payrollProvisions,
              payrollGovServices: payroll.payrollGovServices,
              payrollUnpaidLeaves: payroll.payrollUnpaidLeaves,
              payrollLocationCosts: payroll.payrollLocationCosts,
              payrollProjectCosts: payroll.payrollProjectCosts,
              overtime: payroll.overtime,
              transactionDate: payroll.transactionDate,
              createdAt: payroll.createdAt,
              notes: payroll.notes,
              attachments: payroll.attachments,
              payrollAttachments: payroll.payrollAttachments,
            }));

            /**
             * The list of payrolls to be added to the form.
             */
            const lines: PayrollItem[] = [];

            /**
             * Add line for payroll.
             */
            lines.push(...this.newPayrolls);
            this.initialPayrollsSubject.next(lines);
          })
        )
        .subscribe()
    );

    /**
     * Load data.
     */
    this.isInPaying$ = this.financesStore$.pipe(select(fromFinancesStore.getSelectedPayrollPaying));
    this.isInPayCompleted$ = this.financesStore$.pipe(select(fromFinancesStore.getSelectedPayPayrollCompleted));
  }

  /**
   * Initialize form and add validators.
   */
  initForm() {
    const paymentMethodIdFormControl = new FormControl(null, Validators.required);
    const payingTypeFormControl = new FormControl(null, Validators.required);
    this.form = new FormGroup({
      payingType: payingTypeFormControl,
      paymentMethodId: paymentMethodIdFormControl,
      employees: new FormControl([]),
      departments: new FormControl([]),
      locations: new FormControl([]),
    });

    /**
     * Subscription when selected employee paying type.
     */
    this.subscriptions.add(
      payingTypeFormControl.valueChanges.subscribe(() => {
        this.onPayingTypeChange();
      })
    );

    this.setBlankPayrollsCount();
  }

  /**
   * Initialize paid form and add validators.
   */
  initPaidForm() {
    const bankFeeFormControl = new FormControl(null);
    const bankFeeTaxValueFormControl = new FormControl(null);

    this.paidPayrollsForm = new FormGroup({
      payrolls: new FormArray([]),
      bankAccountId: new FormControl(null, Validators.required),
      bankFee: bankFeeFormControl,
      bankFeeTaxValue: bankFeeTaxValueFormControl,
      transactionDate: new FormControl(null),
      notes: new FormControl(''),
      attachments: new FormArray([]),
    });

    this.subscriptions.add(
      this.form.controls.paymentMethodId.valueChanges.subscribe(() => {
        this.onPaymentMethodTypeChange();
        this.isDisplayPayrolls = false;
      })
    );

    this.addBlankAttachment();
  }

  /**
   * Adds a blank attachment form-control to the attachments form for quick start..
   */
  addBlankAttachment() {
    this.attachmentsForm.push(new FormControl());
  }

  /**
   * Submits the form.
   */
  submit() {
    const errorMessage = new NotificationMessage();
    if (this.form.invalid) {
      if (this.form.get('payingType').invalid) {
        errorMessage.title = this.translationService.translate(
          'FINANCES.HR.PAID_PAYROLLS.PAID_PAYROLLS_DATA_VALIDATION.PAYING_TYPE_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate(
            'FINANCES.HR.PAID_PAYROLLS.PAID_PAYROLLS_DATA_VALIDATION.PAYING_TYPE_IS_REQUIRED'
          ),
        ];
      } else if (
        this.form.get('employees').invalid &&
        this.form.get('payingType').value === this.payingTypeForEmployeeId
      ) {
        /**
         * Check if employees count = 0.
         */
        if (!this.form.value.employees.length) {
          errorMessage.title = this.translationService.translate(
            'FINANCES.HR.PAID_PAYROLLS.PAID_PAYROLLS_DATA_VALIDATION.EMPLOYEES_ERROR'
          );
          errorMessage.body = [
            this.translationService.translate(
              'FINANCES.HR.PAID_PAYROLLS.PAID_PAYROLLS_DATA_VALIDATION.EMPLOYEES_LENGTH_ERROR'
            ),
          ];
        }
      } else if (
        this.form.get('locations').invalid &&
        this.form.get('payingType').value === this.payingTypeForLocationId
      ) {
        /**
         * Check if locations count = 0.
         */
        if (!this.form.value.locations.length) {
          errorMessage.title = this.translationService.translate(
            'FINANCES.HR.PAID_PAYROLLS.PAID_PAYROLLS_DATA_VALIDATION.LOCATIONS_ERROR'
          );
          errorMessage.body = [
            this.translationService.translate(
              'FINANCES.HR.PAID_PAYROLLS.PAID_PAYROLLS_DATA_VALIDATION.LOCATIONS_LENGTH_ERROR'
            ),
          ];
        }
      } else if (
        this.form.get('departments').invalid &&
        this.form.get('payingType').value === this.payingTypeForDepartmentId
      ) {
        /**
         * Check if departments count = 0.
         */
        if (!this.form.value.departments.length) {
          errorMessage.title = this.translationService.translate(
            'FINANCES.HR.PAID_PAYROLLS.PAID_PAYROLLS_DATA_VALIDATION.DEPARTMENTS_ERROR'
          );
          errorMessage.body = [
            this.translationService.translate(
              'FINANCES.HR.PAID_PAYROLLS.PAID_PAYROLLS_DATA_VALIDATION.DEPARTMENTS_LENGTH_ERROR'
            ),
          ];
        }
      } else if (this.form.get('paymentMethodId').invalid) {
        errorMessage.title = this.translationService.translate(
          'FINANCES.HR.PAID_PAYROLLS.PAID_PAYROLLS_DATA_VALIDATION.PAYMENT_METHOD_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate(
            'FINANCES.HR.PAID_PAYROLLS.PAID_PAYROLLS_DATA_VALIDATION.PAYMENT_METHOD_IS_REQUIRED'
          ),
        ];
      }
      this.form.markAllAsTouched();
      return this.notificationService.warningWithTitle(errorMessage);
    } else {
      this.openModal(this.confirmModalRef);
    }
  }

  /**
   * Confirms the form submit.
   */
  confirm() {
    this.financesStore$.dispatch(
      new fromFinancesStore.PayPayroll({
        locations: this.form.get('locations').value ? this.form.get('locations').value : [],
        employees: this.form.get('employees').value ? this.form.get('employees').value : [],
        departments: this.form.get('departments').value ? this.form.get('departments').value : [],
        paymentMethodId: this.form.get('paymentMethodId').value,
      })
    );
  }

  /**
   * Submits paid payrolls the form.
   */
  submitPayPayrolls() {
    const errorMessage = new NotificationMessage();
    if (this.paidPayrollsForm.invalid) {
      if (this.paidPayrollsForm.get('bankAccountId').invalid) {
        errorMessage.title = this.translationService.translate(
          'FINANCES.BANK_ACCOUNTS.BANK_ACCOUNT_DATA_VALIDATION.BANK_ACCOUNT_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate(
            'FINANCES.BANK_ACCOUNTS.BANK_ACCOUNT_DATA_VALIDATION.BANK_ACCOUNT_IS_REQUIRED'
          ),
        ];
      } else if (this.paidPayrollsForm.get('bankFee').invalid) {
        errorMessage.title = this.translationService.translate(
          'FINANCES.BANK_ACCOUNTS.BANK_ACCOUNT_DATA_VALIDATION.BANK_FEE_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate(
            'FINANCES.BANK_ACCOUNTS.BANK_ACCOUNT_DATA_VALIDATION.BANK_FEE_LENGTH_ERROR'
          ),
        ];
      } else if (this.paidPayrollsForm.get('bankFeeTaxValue').invalid) {
        errorMessage.title = this.translationService.translate(
          'FINANCES.BANK_ACCOUNTS.BANK_ACCOUNT_DATA_VALIDATION.BANK_FEE_TAX_VALUE_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate(
            'FINANCES.BANK_ACCOUNTS.BANK_ACCOUNT_DATA_VALIDATION.BANK_FEE_TAX_VALUE_LENGTH_ERROR'
          ),
        ];
      }

      this.paidPayrollsForm.markAllAsTouched();
      return this.notificationService.warningWithTitle(errorMessage);
    }

    this.openModal(this.confirmPayModalRef);
  }

  /**
   * Confirms the form submit Reviewed payrolls.
   */
  confirmPayPayrolls() {
    let fromDateFormatted;
    const { transactionDate } = this.paidPayrollsForm.value;
    if (transactionDate) {
      fromDateFormatted = new Date(transactionDate.year, transactionDate.month - 1, transactionDate.day);
      fromDateFormatted.setHours(new Date().getHours(), new Date().getMinutes());
    }

    /**
     * The list of payrolls in the payrolls form.
     */
    const payrolls = this.payrollsForm.value.map((payroll) => {
      return payroll.id;
    });

    /**
     * Dispatch update payrolls action.
     */
    this.financesStore$.dispatch(
      new fromFinancesStore.CreatePayrollPayment({
        bankAccountId: this.paidPayrollsForm.value.bankAccountId,
        bankFee: this.paidPayrollsForm.value.bankFee ? this.paidPayrollsForm.value.bankFee : null,
        bankFeeTaxValue: this.paidPayrollsForm.value.bankFeeTaxValue
          ? this.paidPayrollsForm.value.bankFeeTaxValue
          : null,
        employeePaymentMethodId: this.form.value.paymentMethodId,
        notes: this.paidPayrollsForm.value.notes ?? '',
        transactionDate: fromDateFormatted,
        payrolls,
        attachments: this.attachmentsForm.value.filter((file) => !!file),
      })
    );
  }

  /**
   * Sets the count of the payrolls should be added to the payrolls form for quick start..
   */
  setBlankPayrollsCount() {
    this.initialPayrollsSubject.next({ count: 0 });
  }

  /**
   * And Sets the conditional validation for the form based on the selected employee paying type id.
   */
  onPayingTypeChange() {
    /**
     * The id of the current selected employee paying type.
     */
    const payingTypeId = parseInt(this.form.controls.payingType.value ?? '0');

    /**
     * Check if the employee paying type is location.
     */
    if (payingTypeId === this.payingTypeForLocationId) {
      this.form.controls.locations.setValidators([Validators.required]);
      this.form.controls.departments.clearValidators();
      this.form.controls.employees.clearValidators();
    } else if (payingTypeId === this.payingTypeForEmployeeId) {
      this.form.controls.employees.setValidators([Validators.required]);
      this.form.controls.departments.clearValidators();
      this.form.controls.locations.clearValidators();
    } else {
      this.form.controls.departments.setValidators([Validators.required]);
      this.form.controls.locations.clearValidators();
      this.form.controls.employees.clearValidators();
    }

    this.form.controls.departments.setValue([]);
    this.form.controls.departments.updateValueAndValidity();
    this.form.controls.employees.setValue([]);
    this.form.controls.employees.updateValueAndValidity();
    this.form.controls.locations.setValue([]);
    this.form.controls.locations.updateValueAndValidity();
  }

  /**
   * And Sets the conditional validation for the form based on the selected employee payment method id.
   */
  onPaymentMethodTypeChange() {
    /**
     * The id of the current selected employee payment method type.
     */
    const paymentMethodId = parseInt(this.form.controls.paymentMethodId.value ?? '0');

    /**
     * Check if the employee payment method type is bank transfer.
     */
    if (paymentMethodId === this.employeePaymentMethodIdForBankTransfer) {
      this.paidPayrollsForm.controls.bankFee.setValidators([Validators.required, CustomValidators.gte(0)]);
      this.paidPayrollsForm.controls.bankFeeTaxValue.setValidators([Validators.required, CustomValidators.gte(0)]);
    } else {
      this.paidPayrollsForm.controls.bankFee.clearValidators();
      this.paidPayrollsForm.controls.bankFee.setValue(null);
      this.paidPayrollsForm.controls.bankFeeTaxValue.clearValidators();
      this.paidPayrollsForm.controls.bankFeeTaxValue.setValue(null);
    }
    this.paidPayrollsForm.controls.bankFee.updateValueAndValidity();
    this.paidPayrollsForm.controls.bankFeeTaxValue.updateValueAndValidity();
  }

  /**
   * Opens the modal of the given templateRef.
   * @param modalRef The modal templateRef to be opened.
   */
  openModal(modalRef) {
    this.modalService.open(modalRef);
  }

  /**
   * Adds the newly selected employees the employees search list.
   * @param employees The list of newly selected employees to be added.
   */
  selectEmployees(employees: Employee[]) {
    const selectedIds: number[] = this.form.get('employees').value;
    this.employees = [...this.employees.filter((employee) => selectedIds.includes(employee.id)), ...employees];
    this.form.patchValue({ employees: this.employees.map((employee) => employee.id) });
  }

  /**
   * Adds the newly selected departments the departments search list.
   * @param departments The list of newly selected departments to be added.
   */
  selectDepartments(departments: Department[]) {
    const selectedIds: number[] = this.form.get('departments').value;
    this.departments = [
      ...this.departments.filter((department) => selectedIds.includes(department.id)),
      ...departments,
    ];
    this.form.patchValue({ departments: this.departments.map((department) => department.id) });
  }

  /**
   * Adds the newly selected locations the locations search list.
   * @param locations The list of newly selected locations to be added.
   */
  selectLocations(locations: Location[]) {
    const selectedIds: number[] = this.form.get('locations').value;
    this.locations = [...this.locations.filter((location) => selectedIds.includes(location.id)), ...locations];
    this.form.patchValue({ locations: this.locations.map((location) => location.id) });
  }

  /**
   * Selects the newly selected bank account from the bank accounts search list.
   * @param bankAccounts The list of newly selected bank accounts to select the only one in the list.
   */
  selectBankAccount([bankAccount]: BankAccount[]) {
    if (bankAccount) {
      this.bankAccounts = [bankAccount];
      this.paidPayrollsForm.patchValue({ bankAccountId: bankAccount.id });
    }
  }
  /**
   * Confirms leaving the page before routing.
   */
  confirmDeactivate() {
    if (this.form.dirty === true) {
      /**
       * Message to warn the user before leaving the detailed form.
       */
      return confirm(this.translationService.translate('SHARED.CONFIRM.LEAVING'));
    }
    return true;
  }
}
