<!-- page header starts -->
<app-page-header [info]="pageInfo"></app-page-header>
<!-- page header ends -->

<ng-container *ngIf="user$ | async; let user">
  <!-- update profile personals form starts -->
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-header mb-3">
          <h4 class="card-title">{{ 'SECURITY.USERS.PERSONAL_DATA' | translate }}</h4>
        </div>
        <div class="card-content">
          <div class="px-3">
            <form [formGroup]="personalsForm" (ngSubmit)="submitPersonals()">
              <div class="row">
                <div class="form-group col-md-12">
                  <label for="name">{{ 'SECURITY.USERS.USER_DATA.USER_NAME' | translate }}</label>
                  <input
                    type="text"
                    id="name"
                    class="form-control round"
                    [placeholder]="'SECURITY.USERS.USER_DATA.USER_NAME_PLACEHOLDER' | translate"
                    maxlength="50"
                    formControlName="name"
                  />
                </div>
                <div class="form-group col-12 d-flex justify-content-between">
                  <button type="submit" class="btn btn-raised btn-success round" [disabled]="isUpdating$ | async">
                    <i class="fa fa-check"></i>
                    <span class="mx-1">{{ 'SHARED.CONFIRM.SAVE' | translate }}</span>
                  </button>
                  <a class="btn btn-outline-dark round" routerLink="/">
                    <i class="fa fa-times"></i>
                    <span class="mx-1">{{ 'SHARED.CONFIRM.CANCEL' | translate }}</span>
                  </a>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- profile personals form ends -->

  <!-- update profile credentials form starts -->
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-header mb-3">
          <h4 class="card-title">{{ 'SECURITY.USERS.LOGIN_DATA' | translate }}</h4>
        </div>
        <div class="card-content">
          <div class="px-3">
            <form [formGroup]="credentialsForm" (ngSubmit)="submitCredentials()">
              <div class="row">
                <div class="form-group col-md-6">
                  <label for="username">{{ 'SECURITY.USERS.USER_DATA.USERNAME' | translate }}</label>
                  <input
                    type="text"
                    id="username"
                    class="form-control round"
                    [placeholder]="'SECURITY.USERS.USER_DATA.USERNAME' | translate"
                    maxlength="50"
                    autocomplete="off"
                    formControlName="username"
                  />
                </div>
                <div class="form-group col-md-6">
                  <label for="oldPassword">{{ 'SECURITY.USERS.USER_DATA.OLD_PASSWORD' | translate }}</label>
                  <input
                    type="password"
                    id="oldPassword"
                    class="form-control round"
                    [placeholder]="'SECURITY.USERS.USER_DATA.OLD_PASSWORD_PLACEHOLDER' | translate"
                    autocomplete="off"
                    formControlName="oldPassword"
                  />
                </div>
                <div class="form-group col-md-12">
                  <label for="newPassword">{{ 'SECURITY.USERS.USER_DATA.NEW_PASSWORD' | translate }}</label>
                  <input
                    type="password"
                    id="newPassword"
                    class="form-control round"
                    [placeholder]="'SECURITY.USERS.USER_DATA.NEW_PASSWORD' | translate"
                    maxlength="50"
                    autocomplete="off"
                    formControlName="newPassword"
                  />
                </div>
                <div class="form-group col-12 d-flex justify-content-between">
                  <button type="submit" class="btn btn-raised btn-success round" [disabled]="isUpdating$ | async">
                    <i class="fa fa-check"></i>
                    <span class="mx-1">{{ 'SHARED.CONFIRM.SAVE' | translate }}</span>
                  </button>
                  <a class="btn btn-outline-dark round" routerLink="/">
                    <i class="fa fa-times"></i>
                    <span class="mx-1">{{ 'SHARED.CONFIRM.CANCEL' | translate }}</span>
                  </a>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- profile credentials form ends -->

  <!-- update profile api key form starts -->
  <div class="row" *ngIf="currentLoggedInUser.organizationId">
    <div class="col-md-12">
      <div class="card">
        <div class="card-header mb-3">
          <h4 class="card-title">{{ 'SECURITY.USERS.UNIFIED_API_KEY' | translate }}</h4>
        </div>
        <div class="card-content">
          <div class="px-3">
            <form [formGroup]="apiKeyForm" (ngSubmit)="submitApiKey()">
              <div class="row">
                <div class="form-group col-md-12">
                  <label for="apiKey">{{ 'SECURITY.USERS.USER_DATA.API_KEY' | translate }}</label>
                  <textarea
                    minlength="0"
                    maxlength="500"
                    rows="2"
                    class="form-control round"
                    [placeholder]="'SECURITY.USERS.USER_DATA.API_KEY_PLACEHOLDER' | translate"
                    id="apiKey"
                    formControlName="apiKey"
                    [readOnly]="true"
                  ></textarea>
                </div>
                <div class="form-group col-12 d-flex justify-content-between">
                  <button type="submit" class="btn btn-raised btn-success round" [disabled]="isApiKeyUpdating$ | async">
                    <i class="fa fa-check"></i>
                    <span class="mx-1">{{ 'SHARED.CONFIRM.SET_UP' | translate }}</span>
                  </button>
                  <a class="btn btn-raised btn-primary round" routerLink="/">
                    <i class="fa fa-times"></i>
                    <span class="mx-1">{{ 'SHARED.CONFIRM.CANCEL' | translate }}</span>
                  </a>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- profile api key form ends -->
</ng-container>

<!-- personal confirm modal starts -->
<ng-template #personalsConfirmModalRef let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title text-primary" id="modal-basic-title">
      {{ 'SECURITY.USERS.CONFIRM_PERSONAL_DATA_TITLE' | translate }}
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="d()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p class="text-primary">{{ 'SECURITY.USERS.CONFIRM_PERSONAL_DATA_MESSAGE' | translate }}</p>
  </div>
  <div class="modal-footer">
    <div class="modal-actions">
      <button type="button" class="btn btn-raised btn-success round" (click)="c(); confirmPersonals()" ngbAutofocus>
        <i class="fa fa-check"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.SAVE' | translate }}</span>
      </button>
      <button type="button" class="btn btn-outline-dark round" (click)="c()">
        <i class="fa fa-times"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.CANCEL' | translate }}</span>
      </button>
    </div>
  </div>
</ng-template>
<!-- personal confirm modal ends -->

<!-- personal confirm modal starts -->
<ng-template #credentialsConfirmModalRef let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title text-primary" id="modal-basic-title">
      {{ 'SECURITY.USERS.CONFIRM_LOGIN_DATA_TITLE' | translate }}
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="d()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p class="text-primary">{{ 'SECURITY.USERS.CONFIRM_LOGIN_DATA_MESSAGE' | translate }}</p>
  </div>
  <div class="modal-footer">
    <div class="modal-actions">
      <button type="button" class="btn btn-raised btn-success round" (click)="c(); confirmCredentials()" ngbAutofocus>
        <i class="fa fa-check"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.SAVE' | translate }}</span>
      </button>
      <button type="button" class="btn btn-outline-dark round" (click)="c()">
        <i class="fa fa-times"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.CANCEL' | translate }}</span>
      </button>
    </div>
  </div>
</ng-template>
<!-- personal confirm modal ends -->

<!-- personal confirm modal starts -->
<ng-template #apiKeyConfirmModalRef let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title text-primary" id="modal-basic-title">
      {{ 'SECURITY.USERS.CONFIRM_API_KEY_DATA_TITLE' | translate }}
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="d()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p class="text-primary">{{ 'SECURITY.USERS.CONFIRM_API_KEY_DATA_MESSAGE' | translate }}</p>
  </div>
  <div class="modal-footer">
    <div class="modal-actions">
      <button type="button" class="btn btn-raised btn-success round" (click)="c(); confirmApiKey()" ngbAutofocus>
        <i class="fa fa-check"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.SAVE' | translate }}</span>
      </button>
      <button type="button" class="btn btn-outline-dark round" (click)="c()">
        <i class="fa fa-times"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.CANCEL' | translate }}</span>
      </button>
    </div>
  </div>
</ng-template>
<!-- personal confirm modal ends -->
