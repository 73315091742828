import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';

import { AppHttpResponse } from 'shared';
import { Customer } from 'sales/models';

/**
 * The customers services includes functionality to search a customer.
 */
@Injectable()
export class CustomersService {
  /**
   * The relative route for the customers.
   *
   * No leading or trailing slashes required.
   */
  private customersApi = 'sales/customers';

  constructor(private http: HttpClient) {}

  /**
   * Searches in the customers by name.
   * @param name The name of the customer.
   * @param page The current pagination page number.
   * @param pageSize The maximum number of customers allowed per one pagination page.
   */
  public search(name: string, page: number, pageSize: number): Observable<AppHttpResponse<Customer[]>> {
    const params = new HttpParams().set('name', name).set('page', page.toString()).set('pageSize', pageSize.toString());
    return this.http.get<AppHttpResponse<Customer[]>>(`${this.customersApi}`, { params });
  }
}
