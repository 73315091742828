import { ActionReducerMap } from '@ngrx/store';

import { leadsReducer } from 'crm/store/reducers/leads.reducer';
import { quotationsReducer } from 'crm/store/reducers/quotations.reducer';
import { CRMState } from 'crm/store/states';

/**
 * The CRM-Module store reducers.
 */
export const reducers: ActionReducerMap<CRMState> = {
  leads: leadsReducer,
  quotations: quotationsReducer,
};
