import { Action } from '@ngrx/store';

import { Report } from 'reports/models';
import { AppHttpResponse } from 'shared';

/**
 * The Reports-Module purchases reports action types.
 */
export enum PurchasesReportsActionType {
  GET_PURCHASE_INVOICES_REPORT = '[Reports] [Purchases] Get Purchase Invoices Report',
  GET_PURCHASE_INVOICES_REPORT_FAIL = '[Reports] [Purchases] Get Purchase Invoices Report Fail',
  GET_PURCHASE_INVOICES_REPORT_RECEIVED = '[Reports] [Purchases] Get Purchase Invoices Report Received',
  GET_PURCHASE_INVOICES_REPORT_SUCCESS = '[Reports] [Purchases] Get Purchase Invoices Report Success',
  PURCHASE_INVOICES_REPORT_VIEW = '[Reports] [Purchases] Get Purchase Invoices Report View',

  GET_PURCHASE_ORDERS_REPORT = '[Reports] [Purchases] Get Purchase Orders Report',
  GET_PURCHASE_ORDERS_REPORT_FAIL = '[Reports] [Purchases] Get Purchase Orders Report Fail',
  GET_PURCHASE_ORDERS_REPORT_RECEIVED = '[Reports] [Purchases] Get Purchase Orders Report Received',
  GET_PURCHASE_ORDERS_REPORT_SUCCESS = '[Reports] [Purchases] Get Purchase Orders Report Success',
  PURCHASE_ORDERS_REPORT_VIEW = '[Reports] [Purchases] Get Purchase Orders Report View',

  GET_PURCHASE_REQUESTS_REPORT = '[Reports] [Purchases] Get Purchase Requests Report',
  GET_PURCHASE_REQUESTS_REPORT_FAIL = '[Reports] [Purchases] Get Purchase Requests Report Fail',
  GET_PURCHASE_REQUESTS_REPORT_RECEIVED = '[Reports] [Purchases] Get Purchase Requests Report Received',
  GET_PURCHASE_REQUESTS_REPORT_SUCCESS = '[Reports] [Purchases] Get Purchase Requests Report Success',
  PURCHASE_REQUESTS_REPORT_VIEW = '[Reports] [Purchases] Get Purchase Requests Report View',

  GET_PURCHASE_RETURNS_REPORT = '[Reports] [Purchases] Get Purchase Returns Report',
  GET_PURCHASE_RETURNS_REPORT_FAIL = '[Reports] [Purchases] Get Purchase Returns Report Fail',
  GET_PURCHASE_RETURNS_REPORT_RECEIVED = '[Reports] [Purchases] Get Purchase Returns Report Received',
  GET_PURCHASE_RETURNS_REPORT_SUCCESS = '[Reports] [Purchases] Get Purchase Returns Report Success',
  PURCHASE_RETURNS_REPORT_VIEW = '[Reports] [Purchases] Get Purchase Returns Report View',

  GET_TOP_RETURNED_PURCHASE_PRODUCTS_REPORT = '[Reports] [Purchases] Get Top Returned Purchase Products Report',
  GET_TOP_RETURNED_PURCHASE_PRODUCTS_REPORT_FAIL = '[Reports] [Purchases] Get Top Returned Purchase Products Report Fail',
  GET_TOP_RETURNED_PURCHASE_PRODUCTS_REPORT_RECEIVED = '[Reports] [Purchases] Get Top Returned Purchase Products Report Received',
  GET_TOP_RETURNED_PURCHASE_PRODUCTS_REPORT_SUCCESS = '[Reports] [Purchases] Get Top Returned Purchase Products Report Success',
  TOP_RETURNED_PURCHASE_PRODUCTS_REPORT_VIEW = '[Reports] [Purchases] Get Top Returned Purchase Products Report View',

  GET_TOP_PURCHASED_PRODUCTS_REPORT = '[Reports] [Purchases] Get Top Purchased Products Report',
  GET_TOP_PURCHASED_PRODUCTS_REPORT_FAIL = '[Reports] [Purchases] Get Top Purchased Products Report Fail',
  GET_TOP_PURCHASED_PRODUCTS_REPORT_RECEIVED = '[Reports] [Purchases] Get Top Purchased Products Report Received',
  GET_TOP_PURCHASED_PRODUCTS_REPORT_SUCCESS = '[Reports] [Purchases] Get Top Purchased Products Report Success',
  TOP_PURCHASED_PRODUCTS_REPORT_VIEW = '[Reports] [Purchases] Get Top Purchased Products Report View',

  GET_VENDOR_SUMMARY_REPORT = '[Reports] [Purchases] Get Vendor summary Report',
  GET_VENDOR_SUMMARY_REPORT_FAIL = '[Reports] [Purchases] Get Vendor summary Report Fail',
  GET_VENDOR_SUMMARY_REPORT_RECEIVED = '[Reports] [Purchases] Get Vendor summary Report Received',
  GET_VENDOR_SUMMARY_REPORT_SUCCESS = '[Reports] [Purchases] Get Vendor summary Report Success',
  VENDOR_SUMMARY_REPORT_VIEW = '[Reports] [Purchases] Get Vendor summary Report View',

  GET_VENDORS_DEBTS_REPORT = '[Reports] [Purchases] Get Vendors Debts Report',
  GET_VENDORS_DEBTS_REPORT_FAIL = '[Reports] [Purchases] Get Vendors Debts Report Fail',
  GET_VENDORS_DEBTS_REPORT_RECEIVED = '[Reports] [Purchases] Get Vendors Debts Report Received',
  GET_VENDORS_DEBTS_REPORT_SUCCESS = '[Reports] [Purchases] Get Vendors Debts Report Success',
  VENDORS_DEBTS_REPORT_VIEW = '[Reports] [Purchases] Get Vendors Debts Report View',

  GET_WORST_PURCHASED_PRODUCTS_REPORT = '[Reports] [Purchases] Get Worst Purchased Products Report',
  GET_WORST_PURCHASED_PRODUCTS_REPORT_FAIL = '[Reports] [Purchases] Get Worst Purchased Products Report Fail',
  GET_WORST_PURCHASED_PRODUCTS_REPORT_RECEIVED = '[Reports] [Purchases] Get Worst Purchased Products Report Received',
  GET_WORST_PURCHASED_PRODUCTS_REPORT_SUCCESS = '[Reports] [Purchases] Get Worst Purchased Products Report Success',
  WORST_PURCHASED_PRODUCTS_REPORT_VIEW = '[Reports] [Purchases] Get Worst Purchased Products Report View',

  GET_WORST_RETURNED_PURCHASE_PRODUCTS_REPORT = '[Reports] [Purchases] Get Worst Returned Purchase Products Report',
  GET_WORST_RETURNED_PURCHASE_PRODUCTS_REPORT_FAIL = '[Reports] [Purchases] Get Worst Returned Purchase Products Report Fail',
  GET_WORST_RETURNED_PURCHASE_PRODUCTS_REPORT_RECEIVED = '[Reports] [Purchases] Get Worst Returned Purchase Products Report Received',
  GET_WORST_RETURNED_PURCHASE_PRODUCTS_REPORT_SUCCESS = '[Reports] [Purchases] Get Worst Returned Purchase Products Report Success',
  WORST_RETURNED_PURCHASE_PRODUCTS_REPORT_VIEW = '[Reports] [Purchases] Get Worst Returned Purchase Products Report View',
}

/**
 * Represents a store top returned purchase products report load action.
 */
export class GetTopReturnedPurchaseProductsReport implements Action {
  /**
   * The type of the action.
   */
  readonly type = PurchasesReportsActionType.GET_TOP_RETURNED_PURCHASE_PRODUCTS_REPORT;

  /**
   * Fires a new top returned purchase products report load action.
   * @param payload the search parameters,
   * if omitted, all top returned purchase products report will be loaded.
   */
  constructor(
    public payload: {
      locations: number[];
      fromDate: Date;
      toDate: Date;
      count: number;
    }
  ) {}
}

/**
 * Represents a store top returned purchase products report load fail action.
 */
export class GetTopReturnedPurchaseProductsReportFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = PurchasesReportsActionType.GET_TOP_RETURNED_PURCHASE_PRODUCTS_REPORT_FAIL;

  /**
   * Fires a new top returned purchase products report load fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents an top returned purchase products  report received action.
 */
export class GetTopReturnedPurchaseProductsReportReceived implements Action {
  /**
   * The type of the action.
   */
  readonly type = PurchasesReportsActionType.GET_TOP_RETURNED_PURCHASE_PRODUCTS_REPORT_RECEIVED;

  /**
   * Fires an top returned purchase products report received action.
   * @param payload Received acknowledgment.
   */
  constructor(public payload: AppHttpResponse<string>) {}
}
/**
 * Represents a store top returned purchase products report get success action.
 */
export class GetTopReturnedPurchaseProductsReportSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = PurchasesReportsActionType.GET_TOP_RETURNED_PURCHASE_PRODUCTS_REPORT_SUCCESS;

  /**
   * Fires a top returned purchase products report get success action.
   *  @param payload The loaded report.
   */
  constructor(public payload: AppHttpResponse<Report>) {}
}

/**
 * Represents a top returned purchases products report view action.
 */
export class TopReturnedPurchaseProductsReportView implements Action {
  /**
   * The type of the action.
   */
  readonly type = PurchasesReportsActionType.TOP_RETURNED_PURCHASE_PRODUCTS_REPORT_VIEW;

  /**
   * Fires an top returned purchase products report view action.
   * @param payload The report to be loaded.
   */
  constructor(public payload: Report) {}
}

/**
 * Represents a purchase invoices report action.
 */
export class GetPurchaseInvoicesReport implements Action {
  /**
   * The type of the action.
   */
  readonly type = PurchasesReportsActionType.GET_PURCHASE_INVOICES_REPORT;

  /**
   * Fires a new purchase invoices report load action.
   * @param payload the search parameters,
   * if omitted, all purchase invoices report will be loaded.
   */
  constructor(
    public payload: {
      vendors: number[];
      locations: number[];
      paymentDurations: number[];
      purchaseInvoiceTypes: number[];
      fromDate: Date;
      toDate: Date;
      displayProductsInReport: boolean;
    }
  ) {}
}

/**
 * Represents a purchase invoices report fail action.
 */
export class GetPurchaseInvoicesReportFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = PurchasesReportsActionType.GET_PURCHASE_INVOICES_REPORT_FAIL;

  /**
   * Fires a new purchase invoices report fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a purchase invoices report received action.
 */
export class GetPurchaseInvoicesReportReceived implements Action {
  /**
   * The type of the action.
   */
  readonly type = PurchasesReportsActionType.GET_PURCHASE_INVOICES_REPORT_RECEIVED;

  /**
   * Fires a purchase invoices report received action.
   * @param payload Received acknowledgment.
   */
  constructor(public payload: AppHttpResponse<string>) {}
}

/**
 * Represents a purchase invoices report success action.
 */
export class GetPurchaseInvoicesReportSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = PurchasesReportsActionType.GET_PURCHASE_INVOICES_REPORT_SUCCESS;

  /**
   * Fires a purchase invoices report success action.
   * @param payload The loaded report.
   */
  constructor(public payload: AppHttpResponse<Report>) {}
}

/**
 * Represents a purchase invoices report view action.
 */
export class PurchaseInvoicesReportView implements Action {
  /**
   * The type of the action.
   */
  readonly type = PurchasesReportsActionType.PURCHASE_INVOICES_REPORT_VIEW;

  /**
   * Fires a purchase invoices report view action.
   * @param payload The report to be loaded.
   */
  constructor(public payload: Report) {}
}

/**
 * Represents a purchase orders report action.
 */
export class GetPurchaseOrdersReport implements Action {
  /**
   * The type of the action.
   */
  readonly type = PurchasesReportsActionType.GET_PURCHASE_ORDERS_REPORT;

  /**
   * Fires a new purchase orders report action.
   */
  constructor(
    public payload: {
      vendors: number[];
      purchaseOrderTypes: number[];
      fromDate: Date;
      toDate: Date;
      displayProductsInReport: boolean;
    }
  ) {}
}

/**
 * Represents a purchase orders report fail action.
 */
export class GetPurchaseOrdersReportFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = PurchasesReportsActionType.GET_PURCHASE_ORDERS_REPORT_FAIL;

  /**
   * Fires a new purchase orders report fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a purchase orders report received action.
 */
export class GetPurchaseOrdersReportReceived implements Action {
  /**
   * The type of the action.
   */
  readonly type = PurchasesReportsActionType.GET_PURCHASE_ORDERS_REPORT_RECEIVED;

  /**
   * Fires a purchase orders report received action.
   * @param payload Received acknowledgment.
   */
  constructor(public payload: AppHttpResponse<string>) {}
}

/**
 * Represents a purchase orders report success action.
 */
export class GetPurchaseOrdersReportSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = PurchasesReportsActionType.GET_PURCHASE_ORDERS_REPORT_SUCCESS;

  /**
   * Fires a purchase orders report success action.
   * @param payload The loaded report.
   */
  constructor(public payload: AppHttpResponse<Report>) {}
}

/**
 * Represents a purchase orders report view action.
 */
export class PurchaseOrdersReportView implements Action {
  /**
   * The type of the action.
   */
  readonly type = PurchasesReportsActionType.PURCHASE_ORDERS_REPORT_VIEW;

  /**
   * Fires a purchase orders report view action.
   * @param payload The report to be loaded.
   */
  constructor(public payload: Report) {}
}

/**
 * Represents a income statement report action.
 */
export class GetPurchaseRequestsReport implements Action {
  /**
   * The type of the action.
   */
  readonly type = PurchasesReportsActionType.GET_PURCHASE_REQUESTS_REPORT;

  /**
   * Fires a new purchase requests report load action.
   * @param payload the search parameters,
   * if omitted, all purchase requests report will be loaded.
   */
  constructor(
    public payload: {
      purchaseRequestTypes: number[];
      fromDate: Date;
      toDate: Date;
      displayProductsInReport: boolean;
    }
  ) {}
}

/**
 * Represents a income statement report fail action.
 */
export class GetPurchaseRequestsReportFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = PurchasesReportsActionType.GET_PURCHASE_REQUESTS_REPORT_FAIL;

  /**
   * Fires a new income statement report fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a income statement report received action.
 */
export class GetPurchaseRequestsReportReceived implements Action {
  /**
   * The type of the action.
   */
  readonly type = PurchasesReportsActionType.GET_PURCHASE_REQUESTS_REPORT_RECEIVED;

  /**
   * Fires a income statement report received action.
   * @param payload Received acknowledgment.
   */
  constructor(public payload: AppHttpResponse<string>) {}
}

/**
 * Represents a income statement report success action.
 */
export class GetPurchaseRequestsReportSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = PurchasesReportsActionType.GET_PURCHASE_REQUESTS_REPORT_SUCCESS;

  /**
   * Fires a income statement report success action.
   * @param payload The loaded report.
   */
  constructor(public payload: AppHttpResponse<Report>) {}
}

/**
 * Represents a income statement report view action.
 */
export class PurchaseRequestsReportView implements Action {
  /**
   * The type of the action.
   */
  readonly type = PurchasesReportsActionType.PURCHASE_REQUESTS_REPORT_VIEW;

  /**
   * Fires a income statement report view action.
   * @param payload The report to be loaded.
   */
  constructor(public payload: Report) {}
}

/**
 * Represents a purchase returns report action.
 */
export class GetPurchaseReturnsReport implements Action {
  /**
   * The type of the action.
   */
  readonly type = PurchasesReportsActionType.GET_PURCHASE_RETURNS_REPORT;

  /**
   * Fires a new purchase returns report action.
   */
  constructor(
    public payload: {
      locations: number[];
      vendors: number[];
      fromDate: Date;
      toDate: Date;
      displayProductsInReport: boolean;
    }
  ) {}
}

/**
 * Represents a purchase returns report fail action.
 */
export class GetPurchaseReturnsReportFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = PurchasesReportsActionType.GET_PURCHASE_RETURNS_REPORT_FAIL;

  /**
   * Fires a new purchase returns report fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a purchase returns report received action.
 */
export class GetPurchaseReturnsReportReceived implements Action {
  /**
   * The type of the action.
   */
  readonly type = PurchasesReportsActionType.GET_PURCHASE_RETURNS_REPORT_RECEIVED;

  /**
   * Fires a purchase returns report received action.
   * @param payload Received acknowledgment.
   */
  constructor(public payload: AppHttpResponse<string>) {}
}

/**
 * Represents a purchase returns report success action.
 */
export class GetPurchaseReturnsReportSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = PurchasesReportsActionType.GET_PURCHASE_RETURNS_REPORT_SUCCESS;

  /**
   * Fires a purchase returns report success action.
   * @param payload The loaded report.
   */
  constructor(public payload: AppHttpResponse<Report>) {}
}

/**
 * Represents a purchase returns report view action.
 */
export class PurchaseReturnsReportView implements Action {
  /**
   * The type of the action.
   */
  readonly type = PurchasesReportsActionType.PURCHASE_RETURNS_REPORT_VIEW;

  /**
   * Fires a purchase returns report view action.
   * @param payload The report to be loaded.
   */
  constructor(public payload: Report) {}
}

/**
 * Represents a top purchased products report action.
 */
export class GetTopPurchasedProductsReport implements Action {
  /**
   * The type of the action.
   */
  readonly type = PurchasesReportsActionType.GET_TOP_PURCHASED_PRODUCTS_REPORT;

  /**
   * Fires a new top purchased products report action.
   */
  constructor(
    public payload: {
      locations: number[];
      fromDate: Date;
      toDate: Date;
      count: number;
    }
  ) {}
}

/**
 * Represents a top purchased products report fail action.
 */
export class GetTopPurchasedProductsReportFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = PurchasesReportsActionType.GET_TOP_PURCHASED_PRODUCTS_REPORT_FAIL;

  /**
   * Fires a new top purchase products report fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a top purchased products report received action.
 */
export class GetTopPurchasedProductsReportReceived implements Action {
  /**
   * The type of the action.
   */
  readonly type = PurchasesReportsActionType.GET_TOP_PURCHASED_PRODUCTS_REPORT_RECEIVED;

  /**
   * Fires a top purchase products report received action.
   * @param payload Received acknowledgment.
   */
  constructor(public payload: AppHttpResponse<string>) {}
}

/**
 * Represents a top purchased products report success action.
 */
export class GetTopPurchasedProductsReportSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = PurchasesReportsActionType.GET_TOP_PURCHASED_PRODUCTS_REPORT_SUCCESS;

  /**
   * Fires a top purchase products report success action.
   * @param payload The loaded report.
   */
  constructor(public payload: AppHttpResponse<Report>) {}
}

/**
 * Represents a top purchased products report view action.
 */
export class TopPurchasedProductsReportView implements Action {
  /**
   * The type of the action.
   */
  readonly type = PurchasesReportsActionType.TOP_PURCHASED_PRODUCTS_REPORT_VIEW;

  /**
   * Fires a top purchase products report view action.
   * @param payload The report to be loaded.
   */
  constructor(public payload: Report) {}
}

/**
 * Represents a vendor summary report action.
 */
export class GetVendorSummaryReport implements Action {
  /**
   * The type of the action.
   */
  readonly type = PurchasesReportsActionType.GET_VENDOR_SUMMARY_REPORT;

  /**
   * Fires a new vendor summary report action.
   */
  constructor(
    public payload?: {
      vendorId: number;
      fromDate: Date;
      toDate: Date;
    }
  ) {}
}

/**
 * Represents a vendor summary report fail action.
 */
export class GetVendorSummaryReportFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = PurchasesReportsActionType.GET_VENDOR_SUMMARY_REPORT_FAIL;

  /**
   * Fires a new vendor summary report fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a vendor summary report received action.
 */
export class GetVendorSummaryReportReceived implements Action {
  /**
   * The type of the action.
   */
  readonly type = PurchasesReportsActionType.GET_VENDOR_SUMMARY_REPORT_RECEIVED;

  /**
   * Fires a vendor summary report received action.
   * @param payload Received acknowledgment.
   */
  constructor(public payload: AppHttpResponse<string>) {}
}

/**
 * Represents a vendor summary report success action.
 */
export class GetVendorSummaryReportSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = PurchasesReportsActionType.GET_VENDOR_SUMMARY_REPORT_SUCCESS;

  /**
   * Fires a vendor summary report success action.
   * @param payload The loaded report.
   */
  constructor(public payload: AppHttpResponse<Report>) {}
}

/**
 * Represents a vendor summary report view action.
 */
export class VendorSummaryReportView implements Action {
  /**
   * The type of the action.
   */
  readonly type = PurchasesReportsActionType.VENDOR_SUMMARY_REPORT_VIEW;

  /**
   * Fires a vendor summary report view action.
   * @param payload The report to be loaded.
   */
  constructor(public payload: Report) {}
}

/**
 * Represents a vendors debits report action.
 */
export class GetVendorsDebtsReport implements Action {
  /**
   * The type of the action.
   */
  readonly type = PurchasesReportsActionType.GET_VENDORS_DEBTS_REPORT;

  /**
   * Fires a new vendors debits report action.
   */
  constructor() {}
}

/**
 * Represents a vendors debits report fail action.
 */
export class GetVendorsDebtsReportFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = PurchasesReportsActionType.GET_VENDORS_DEBTS_REPORT_FAIL;

  /**
   * Fires a new vendors debits report fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a vendors debits report received action.
 */
export class GetVendorsDebtsReportReceived implements Action {
  /**
   * The type of the action.
   */
  readonly type = PurchasesReportsActionType.GET_VENDORS_DEBTS_REPORT_RECEIVED;

  /**
   * Fires a vendors debits report received action.
   * @param payload Received acknowledgment.
   */
  constructor(public payload: AppHttpResponse<string>) {}
}

/**
 * Represents a vendors debits report success action.
 */
export class GetVendorsDebtsReportSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = PurchasesReportsActionType.GET_VENDORS_DEBTS_REPORT_SUCCESS;

  /**
   * Fires a vendors debits report success action.
   * @param payload The loaded report.
   */
  constructor(public payload: AppHttpResponse<Report>) {}
}

/**
 * Represents a vendors debits report view action.
 */
export class VendorsDebtsReportView implements Action {
  /**
   * The type of the action.
   */
  readonly type = PurchasesReportsActionType.VENDORS_DEBTS_REPORT_VIEW;

  /**
   * Fires a vendors debits report view action.
   * @param payload The report to be loaded.
   */
  constructor(public payload: Report) {}
}

/**
 * Represents a worst purchased products report action.
 */
export class GetWorstPurchasedProductsReport implements Action {
  /**
   * The type of the action.
   */
  readonly type = PurchasesReportsActionType.GET_WORST_PURCHASED_PRODUCTS_REPORT;

  /**
   * Fires a new worst purchased products report action.
   */
  constructor(
    public payload: {
      locations: number[];
      fromDate: Date;
      toDate: Date;
      count: number;
    }
  ) {}
}

/**
 * Represents a worst purchased products report fail action.
 */
export class GetWorstPurchasedProductsReportFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = PurchasesReportsActionType.GET_WORST_PURCHASED_PRODUCTS_REPORT_FAIL;

  /**
   * Fires a new worst purchase products report fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a worst purchased products report received action.
 */
export class GetWorstPurchasedProductsReportReceived implements Action {
  /**
   * The type of the action.
   */
  readonly type = PurchasesReportsActionType.GET_WORST_PURCHASED_PRODUCTS_REPORT_RECEIVED;

  /**
   * Fires a worst purchase products report received action.
   * @param payload Received acknowledgment.
   */
  constructor(public payload: AppHttpResponse<string>) {}
}

/**
 * Represents a worst purchased products report success action.
 */
export class GetWorstPurchasedProductsReportSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = PurchasesReportsActionType.GET_WORST_PURCHASED_PRODUCTS_REPORT_SUCCESS;

  /**
   * Fires a worst purchased products report success action.
   * @param payload The loaded report.
   */
  constructor(public payload: AppHttpResponse<Report>) {}
}

/**
 * Represents a worst purchased products report view action.
 */
export class WorstPurchasedProductsReportView implements Action {
  /**
   * The type of the action.
   */
  readonly type = PurchasesReportsActionType.WORST_PURCHASED_PRODUCTS_REPORT_VIEW;

  /**
   * Fires a worst purchase products report view action.
   * @param payload The report to be loaded.
   */
  constructor(public payload: Report) {}
}

/**
 * Represents a worst returned purchase products report action.
 */
export class GetWorstReturnedPurchaseProductsReport implements Action {
  /**
   * The type of the action.
   */
  readonly type = PurchasesReportsActionType.GET_WORST_RETURNED_PURCHASE_PRODUCTS_REPORT;

  /**
   * Fires a new worst returned purchase products report action.
   */
  constructor(
    public payload?: {
      locations: number[];
      fromDate: Date;
      toDate: Date;
      count: number;
    }
  ) {}
}

/**
 * Represents a worst returned purchase products report fail action.
 */
export class GetWorstReturnedPurchaseProductsReportFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = PurchasesReportsActionType.GET_WORST_RETURNED_PURCHASE_PRODUCTS_REPORT_FAIL;

  /**
   * Fires a new worst returned purchase products report fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a worst returned purchase products report received action.
 */
export class GetWorstReturnedPurchaseProductsReportReceived implements Action {
  /**
   * The type of the action.
   */
  readonly type = PurchasesReportsActionType.GET_WORST_RETURNED_PURCHASE_PRODUCTS_REPORT_RECEIVED;

  /**
   * Fires a worst returned purchase products report received action.
   * @param payload Received acknowledgment.
   */
  constructor(public payload: AppHttpResponse<string>) {}
}

/**
 * Represents a worst returned purchase products report success action.
 */
export class GetWorstReturnedPurchaseProductsReportSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = PurchasesReportsActionType.GET_WORST_RETURNED_PURCHASE_PRODUCTS_REPORT_SUCCESS;

  /**
   * Fires a worst returned purchase products report success action.
   * @param payload The loaded report.
   */
  constructor(public payload: AppHttpResponse<Report>) {}
}

/**
 * Represents a worst returned purchase products report view action.
 */
export class WorstReturnedPurchaseProductsReportView implements Action {
  /**
   * The type of the action.
   */
  readonly type = PurchasesReportsActionType.WORST_RETURNED_PURCHASE_PRODUCTS_REPORT_VIEW;

  /**
   * Fires a worst returned purchase products report view action.
   * @param payload The report to be loaded.
   */
  constructor(public payload: Report) {}
}

/**
 * Reports-Module purchases reports action types.
 */
export type PurchasesReportsActions =
  | GetTopReturnedPurchaseProductsReport
  | GetTopReturnedPurchaseProductsReportFail
  | GetTopReturnedPurchaseProductsReportReceived
  | GetTopReturnedPurchaseProductsReportSuccess
  | TopReturnedPurchaseProductsReportView
  | GetPurchaseInvoicesReport
  | GetPurchaseInvoicesReportFail
  | GetPurchaseInvoicesReportReceived
  | GetPurchaseInvoicesReportSuccess
  | PurchaseInvoicesReportView
  | GetPurchaseOrdersReport
  | GetPurchaseOrdersReportFail
  | GetPurchaseOrdersReportReceived
  | GetPurchaseOrdersReportSuccess
  | PurchaseOrdersReportView
  | GetPurchaseRequestsReport
  | GetPurchaseRequestsReportFail
  | GetPurchaseRequestsReportReceived
  | GetPurchaseRequestsReportSuccess
  | PurchaseRequestsReportView
  | GetPurchaseReturnsReport
  | GetPurchaseReturnsReportFail
  | GetPurchaseReturnsReportReceived
  | GetPurchaseReturnsReportSuccess
  | PurchaseReturnsReportView
  | GetTopPurchasedProductsReport
  | GetTopPurchasedProductsReportFail
  | GetTopPurchasedProductsReportReceived
  | GetTopPurchasedProductsReportSuccess
  | TopPurchasedProductsReportView
  | GetVendorSummaryReport
  | GetVendorSummaryReportFail
  | GetVendorSummaryReportReceived
  | GetVendorSummaryReportSuccess
  | VendorSummaryReportView
  | GetVendorsDebtsReport
  | GetVendorsDebtsReportFail
  | GetVendorsDebtsReportReceived
  | GetVendorsDebtsReportSuccess
  | VendorsDebtsReportView
  | GetWorstPurchasedProductsReport
  | GetWorstPurchasedProductsReportFail
  | GetWorstPurchasedProductsReportReceived
  | GetWorstPurchasedProductsReportSuccess
  | WorstPurchasedProductsReportView
  | GetWorstReturnedPurchaseProductsReport
  | GetWorstReturnedPurchaseProductsReportFail
  | GetWorstReturnedPurchaseProductsReportReceived
  | GetWorstReturnedPurchaseProductsReportSuccess
  | WorstReturnedPurchaseProductsReportView;
