import { Injectable } from '@angular/core';

import { of } from 'rxjs';
import { switchMap, map, catchError } from 'rxjs/operators';
import { Effect, ofType, Actions } from '@ngrx/effects';

import { LookupsService } from 'lookups/services';
import { PettyCashTypeActionType, GetAllPettyCashTypeFail, GetAllPettyCashTypeSuccess } from 'lookups/store/actions';

@Injectable()
export class PettyCashTypeEffects {
  constructor(private actions$: Actions, private lookupsService: LookupsService) {}

  /* ========================= GET_ALL_PETTY_CASH_TYPE =================================== */
  @Effect()
  getAllPettyCashTypes$ = this.actions$.pipe(
    ofType(PettyCashTypeActionType.GET_ALL_PETTY_CASH_TYPE),
    switchMap(() =>
      this.lookupsService.getAllPettyCashTypes().pipe(
        map((response) => new GetAllPettyCashTypeSuccess(response)),
        catchError((error) => of(new GetAllPettyCashTypeFail(error)))
      )
    )
  );
}
