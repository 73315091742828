<div class="row">
  <div class="col-md-12">
    <!-- journal bank account log details starts -->
    <div class="card-header mb-3">
      <h4 class="card-title">{{ 'FINANCES.JOURNALS.JOURNAL_DATA.BANK_ACCOUNT_DATA' | translate }}</h4>
    </div>
    <div class="card-content">
      <div class="px-3">
        <div class="row">
          <div class="form-group col-md-6">
            <label for="name">{{ 'FINANCES.JOURNALS.JOURNAL_DATA.BANK_ACCOUNT_NAME' | translate }}</label>
            <input
              type="text"
              id="name"
              class="form-control round"
              [value]="bankAccountLog.bankAccount.name | localize: bankAccountLog.bankAccount.nameEn | async"
              readonly
            />
          </div>
          <div class="form-group col-md-6">
            <label for="bankAccountLogType"> {{ 'FINANCES.JOURNALS.JOURNAL_DATA.LOG_TYPE' | translate }}</label>
            <input
              type="text"
              id="bankAccountLogType"
              class="form-control round"
              [value]="
                bankAccountLog.bankAccountLogType.name | localize: bankAccountLog.bankAccountLogType.nameEn | async
              "
              readonly
            />
          </div>
          <div class="form-group col-md-6">
            <label for="value">{{ 'FINANCES.JOURNALS.JOURNAL_DATA.VALUE' | translate }}</label>
            <input type="text" id="value" class="form-control round" [value]="bankAccountLog.value" readonly />
          </div>
          <div class="form-group col-md-6">
            <label for="bankFee">{{ 'FINANCES.JOURNALS.JOURNAL_DATA.BANK_FEE' | translate }}</label>
            <input type="text" id="bankFee" class="form-control round" [value]="bankAccountLog.bankFee" readonly />
          </div>
          <div class="form-group col-md-6">
            <label for="bankFeeTaxValue">{{ 'FINANCES.JOURNALS.JOURNAL_DATA.BANK_FEE_TAX_VALUE' | translate }}</label>
            <input
              type="text"
              id="bankFeeTaxValue"
              class="form-control round"
              [value]="bankAccountLog.bankFeeTaxValue"
              readonly
            />
          </div>
          <div class="form-group col-md-6">
            <label for="transactionDate">{{ 'FINANCES.JOURNALS.JOURNAL_DATA.JOURNAL_DATE' | translate }}</label>
            <input
              type="text"
              id="transactionDate"
              class="form-control round"
              [value]="bankAccountLog.transactionDate | date: 'datetime'"
              readonly
            />
          </div>
          <div class="form-group col-md-6">
            <label for="createdAt">{{ 'SHARED.SEARCH.CREATED_AT' | translate }}</label>
            <input
              type="text"
              id="createdAt"
              class="form-control round"
              [value]="bankAccountLog.createdAt | date: 'datetime'"
              readonly
            />
          </div>
          <div class="form-group col-md-6">
            <label for="user">{{ 'FINANCES.JOURNALS.JOURNAL_DATA.USER_CREATE' | translate }}</label>
            <input type="text" id="user" class="form-control round" [value]="bankAccountLog.user.name" readonly />
          </div>
          <div class="form-group col-md-12">
            <label for="notes">{{ 'FINANCES.JOURNALS.JOURNAL_DATA.NOTES' | translate }}</label>
            <textarea
              id="notes"
              class="form-control round"
              [innerText]="bankAccountLog.notes"
              [placeholder]="'FINANCES.JOURNALS.JOURNAL_DATA.NOTES_NOT_FOUND' | translate"
              readonly
            ></textarea>
          </div>
        </div>
      </div>
    </div>
    <!-- journal bank account log details ends -->
  </div>
</div>
