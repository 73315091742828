import { CreateLocationComponent } from 'stores/components/locations/create-location/create-location.component';
import { UpdateLocationComponent } from 'stores/components/locations/update-location/update-location.component';
import { LocationsListComponent } from 'stores/components/locations/locations-list/locations-list.component';
import { LocationsSearchComponent } from 'stores/components/locations/locations-search/locations-search.component';
import { LocationsFormComponent } from 'stores/components/locations/locations-form/locations-form.component';
import { OrganizationLocationsSearchComponent } from 'stores/components/locations/organization-locations-search/organization-locations-search.component';

export * from 'stores/components/locations/create-location/create-location.component';
export * from 'stores/components/locations/update-location/update-location.component';
export * from 'stores/components/locations/locations-list/locations-list.component';
export * from 'stores/components/locations/locations-search/locations-search.component';
export * from 'stores/components/locations/locations-form/locations-form.component';
export * from 'stores/components/locations/organization-locations-search/organization-locations-search.component';

/**
 * The set of components registered on this sub-module.
 */
export const COMPONENTS: any[] = [
  CreateLocationComponent,
  UpdateLocationComponent,
  LocationsListComponent,
  LocationsSearchComponent,
  LocationsFormComponent,
  OrganizationLocationsSearchComponent,
];
