import { Component, OnInit, OnDestroy, AfterViewChecked, ChangeDetectorRef } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

import { Subscription, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';

import * as fromCoreStore from 'core/store';
import { StorageService, TranslationService } from 'shared';

@Component({
  selector: 'app-root',
  templateUrl: './root.component.html',
  styles: [],
})
export class RootComponent implements OnInit, OnDestroy, AfterViewChecked {
  /**
   * The set of this components observables subscriptions.
   * @summary These subscriptions are supposed to be subscripted on component destroy.
   */
  private subscriptions = new Subscription();

  /**
   * if true the general application loader will be shown
   */
  loading$: Observable<boolean>;

  /**
   * Gets the ui direction according to the current user display language write direction.
   */
  get uiDirection(): string {
    return this.translationService.language === 'ar' ? 'rtl' : 'ltr';
  }

  /**
   * @param router The router service.
   * @param modalConfigThe modal service.
   * @param coreStore$ The core-Module store.
   * @param cdRef The change detector ref.
   * @param translationService The translation service.
   */

  constructor(
    private router: Router,
    private modalConfig: NgbModalConfig,
    private coreStore$: Store<fromCoreStore.AppState>,
    private cdRef: ChangeDetectorRef,
    private translationService: TranslationService,
    private storageService: StorageService
  ) {}

  ngOnInit() {
    /**
     * Set device id if not exists.
     */
    this.storageService.setDeviceId();

    /* Subscribe to router navigation to scroll back top the top on each navigation. */
    this.subscriptions.add(
      this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(() => window.scrollTo(0, 0))
    );

    this.setModalConfigs();
    this.translationService.initializeLanguages('ar');
  }

  ngAfterViewChecked() {
    this.loading$ = this.coreStore$.select(fromCoreStore.getAppStatusLoading);
    this.cdRef.detectChanges();
  }

  /**
   * Destroy component data
   */
  ngOnDestroy() {
    /* Release all subscriptions to prevent memory leak. */
    this.subscriptions.unsubscribe();
  }

  /**
   * Sets the global modal configurations since this is the root component.
   */
  setModalConfigs() {
    this.modalConfig.backdrop = 'static';
    this.modalConfig.keyboard = false;
    this.translationService.language === 'ar'
      ? (this.modalConfig.windowClass = 'rtl')
      : (this.modalConfig.windowClass = 'ltr');

    this.modalConfig.keyboard = true;
  }
}
