import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { SharedModule } from 'shared';
import { OrganizationsRoutingModule } from 'organizations/organizations-routing.module';

import * as fromComponents from 'organizations/components';
import * as fromServices from 'organizations/services';
import * as fromStore from 'organizations/store';

@NgModule({
  declarations: [...fromComponents.COMPONENTS],
  imports: [
    CommonModule,
    SharedModule,
    OrganizationsRoutingModule,
    StoreModule.forFeature('organizations', fromStore.reducers),
    EffectsModule.forFeature(fromStore.effects),
  ],
  exports: [fromComponents.OrganizationsSearchComponent],
  providers: [...fromServices.SERVICES],
})
export class OrganizationsModule {}
