import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styles: [],
})
export class DatePickerComponent {
  /**
   * Sets the name of the form control.
   */
  @Input() controlName: string;

  /**
   * Sets the form control's parent.
   */
  @Input() parentControl: AbstractControl;

  /**
   * Sets the placeholder of the date-picker input.
   */
  @Input() placeholder: string;

  /**
   * Emits a value indicates that a drop-down field in the form is opened and therefore the table height should be expanded.
   */
  @Output() isDropDownOpened = new EventEmitter<boolean>(false);
}
