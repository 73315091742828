import { CreateRegionComponent } from 'stores/components/regions/create-region/create-region.component';
import { UpdateRegionComponent } from 'stores/components/regions/update-region/update-region.component';
import { RegionsListComponent } from 'stores/components/regions/regions-list/regions-list.component';
import { RegionsSearchComponent } from 'stores/components/regions/regions-search/regions-search.component';

export * from 'stores/components/regions/create-region/create-region.component';
export * from 'stores/components/regions/update-region/update-region.component';
export * from 'stores/components/regions/regions-list/regions-list.component';
export * from 'stores/components/regions/regions-search/regions-search.component';

/**
 * The set of components registered on this sub-module.
 */
export const COMPONENTS: any[] = [
  CreateRegionComponent,
  UpdateRegionComponent,
  RegionsListComponent,
  RegionsSearchComponent,
];
