<div
  class="wrapper"
  [ngClass]="{
    'nav-collapsed': isCollapsed,
    'menu-collapsed': isCollapsed,
    'sidebar-sm': isSidebarSmall,
    'sidebar-lg': isSidebarLarge
  }"
  [dir]="uiDirection"
>
  <div
    appSidebar
    class="app-sidebar"
    (toggleHideSidebar)="toggleHideSidebar($event)"
    [ngClass]="{'hide-sidebar': isSidebarHidden }"
    data-active-color="white"
    [attr.data-background-color]="environmentConfig.sidebarBgColor"
    [attr.data-image]="environmentConfig.sidebarBgImgUrl"
  >
    <app-sidebar></app-sidebar>
    <div class="sidebar-background"></div>
  </div>
  <app-navbar (toggleHideSidebar)="toggleHideSidebar($event)"></app-navbar>
  <div class="main-panel">
    <div class="main-content"  [ngClass]="{'removePadding': isSidebarHidden,
      'addPadding': !isSidebarHidden
    }">
      <div class="content-wrapper">
        <div class="container-fluid">
          <router-outlet></router-outlet>
        </div>
      </div>
    </div>
    <!-- <app-footer></app-footer> -->
  </div>
</div>
