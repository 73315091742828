import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Location as AngularLocation } from '@angular/common';
import { ActivatedRoute } from '@angular/router';

import { Observable, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import {
  PageInfo,
  NotificationMessage,
  TranslationService,
  NotificationService,
  CustomValidators,
  APP_CONSTANTS,
} from 'shared';
import * as fromStoresStore from 'stores/store';
import { Product, ProductClass, Location } from 'stores/models';

/**
 * The create product discounts  pages.
 */
enum PAGES {
  'apply-products-discounts' = 'apply-products-discounts',
  'products-discounts' = 'products-discounts',
}

@Component({
  selector: 'app-product-sales-discounts',
  templateUrl: './product-sales-discounts.component.html',
  styles: [],
})
export class ProductSalesDiscountsComponent implements OnInit, OnDestroy {
  /**
   * The confirm modal template reference.
   */
  @ViewChild('confirmModalRef') confirmModalRef: ElementRef<any>;

  /**
   * The decimal mask.
   */
  readonly DECIMAL_MASK = APP_CONSTANTS.numeric.decimal.mask;

  /**
   * Gets or sets the information about the current page.
   */
  pageInfo: PageInfo = {
    title: 'STORES.PRODUCTS.DISCOUNTS',
    icon: 'fa fa-cut',
  };

  /**
   * The list of employees management types.
   */
  ProductDiscountTypes = [
    { id: 1, key: 'CLASSES', name: 'فئات الأصناف', nameEn: 'Product classes' },
    { id: 2, key: 'PRODUCTS', name: 'الأصناف', nameEn: 'Products' },
  ];

  /**
   * Gets or sets the id of the product discount type for `CLASSES`.
   */
  productDiscountTypeForClassesId: number;

  /**
   * Gets or sets the id of the product discount type for `PRODUCTS`.
   */
  productDiscountTypeForProductsId: number;

  /**
   * Gets or sets the selected page.
   * @default 'products'
   */
  activePage: PAGES = PAGES['products-discounts'];

  /**
   * Shows or hides the products list.
   */
  productsListVisibility = false;

  /**
   * Shows or hides the classes list.
   */
  classesListVisibility = false;

  /**
   * Shows or hide the locations list.
   */
  locationsListVisibility = false;

  /**
   * The list of active product classes.
   */
  classes: ProductClass[] = [];

  /**
   * The list of active product.
   */
  products: Product[] = [];

  /**
   * The list of active locations.
   */
  locations: Location[] = [];

  /**
   * Indicates whether there is a create products discount  process is running or not.
   */
  isUpdating$: Observable<boolean>;

  /**
   * The create product discount form.
   */
  form: FormGroup;

  /**
   * The set of subscriptions on this components,
   * these subscriptions must be unsubscribed before this component got destroyed.
   */
  subscriptions = new Subscription();

  /**
   * @param modalService The modal service.
   * @param notificationService The notification service.
   * @param translationService The translation service.
   * @param route The activated route.
   * @param locationService The location service.
   * @param storesStore$ The stores store.
   */
  constructor(
    private modalService: NgbModal,
    private notificationService: NotificationService,
    private translationService: TranslationService,
    private route: ActivatedRoute,
    private locationService: AngularLocation,
    private storesStore$: Store<fromStoresStore.StoresState>
  ) {}

  ngOnInit(): void {
    this.init();
  }

  /**
   * Destroy component data
   */
  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  /**
   * Initialize component data.
   */
  init() {
    this.initForm();

    this.productDiscountTypeForClassesId = this.ProductDiscountTypes?.find(
      (productDiscountType) => productDiscountType.key === 'CLASSES'
    )?.id;
    this.productDiscountTypeForProductsId = this.ProductDiscountTypes?.find(
      (productDiscountType) => productDiscountType.key === 'PRODUCTS'
    )?.id;
    /**
     * Load data.
     */
    /** Select the user desired page. */
    this.activePage = PAGES[this.route.snapshot.fragment] ?? this.activePage;
    this.selectedPageChanged(this.activePage);
  }

  /**
   * Initialize form and add validators.
   */
  initForm() {
    const productDiscountFormControl = new FormControl(null, Validators.required);
    this.form = new FormGroup({
      productDiscountType: productDiscountFormControl,
      products: new FormControl([]),
      classes: new FormControl([]),
      locationId: new FormControl(null, Validators.required),
      discount: new FormControl(0, [CustomValidators.gte(0)]),
      discountPercent: new FormControl(0, [CustomValidators.range([0, 100])]),
    });

    /**
     * Subscription when selected product discount type.
     */
    this.subscriptions.add(
      productDiscountFormControl.valueChanges.subscribe(() => {
        this.onProductDiscountTypeChange();
      })
    );
  }

  /**
   * Submits the form.
   */
  submit() {
    const errorMessage = new NotificationMessage();
    if (this.form.invalid) {
      if (this.form.get('productDiscountType').invalid) {
        errorMessage.title = this.translationService.translate(
          'STORES.PRODUCTS.PRODUCT_DATA_VALIDATION.PRODUCT_DISCOUNT_TYPE_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate(
            'STORES.PRODUCTS.PRODUCT_DATA_VALIDATION.PRODUCT_DISCOUNT_TYPE_IS_REQUIRED'
          ),
        ];
      } else if (
        this.form.get('classes').invalid &&
        this.form.get('productDiscountType').value === this.productDiscountTypeForClassesId
      ) {
        /**
         * Check if classes count = 0.
         */
        if (!this.form.value.classes.length) {
          errorMessage.title = this.translationService.translate(
            'STORES.PRODUCTS.PRODUCT_DATA_VALIDATION.PRODUCT_CLASSES_ERROR'
          );
          errorMessage.body = [
            this.translationService.translate('STORES.PRODUCTS.PRODUCT_DATA_VALIDATION.PRODUCT_CLASSES_LENGTH_ERROR'),
          ];
        }
      } else if (
        this.form.get('products').invalid &&
        this.form.get('productDiscountType').value === this.productDiscountTypeForProductsId
      ) {
        /**
         * Check if products count = 0.
         */
        if (!this.form.value.products.length) {
          errorMessage.title = this.translationService.translate(
            'STORES.PRODUCTS.PRODUCT_DATA_VALIDATION.PRODUCTS_ERROR'
          );
          errorMessage.body = [
            this.translationService.translate('STORES.PRODUCTS.PRODUCT_DATA_VALIDATION.PRODUCTS_LENGTH_ERROR'),
          ];
        }
      } else if (this.form.get('locationId').invalid) {
        errorMessage.title = this.translationService.translate(
          'STORES.PRODUCTS.PRODUCT_DATA_VALIDATION.LOCATION_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate('STORES.PRODUCTS.PRODUCT_DATA_VALIDATION.LOCATION_IS_REQUIRED'),
        ];
      } else if (this.form.get('discount').invalid) {
        errorMessage.title = this.translationService.translate(
          'STORES.PRODUCTS.PRODUCT_DATA_VALIDATION.PRODUCT_DISCOUNT_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate('STORES.PRODUCTS.PRODUCT_DATA_VALIDATION.DISCOUNT_LENGTH_ERROR'),
        ];
      } else if (this.form.get('discountPercent').invalid) {
        errorMessage.title = this.translationService.translate(
          'STORES.PRODUCTS.PRODUCT_DATA_VALIDATION.PRODUCT_DISCOUNT_PERCENT_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate('STORES.PRODUCTS.PRODUCT_DATA_VALIDATION.DISCOUNT_PERCENT_LENGTH_ERROR'),
        ];
      }

      this.form.markAllAsTouched();
      return this.notificationService.warningWithTitle(errorMessage);
    } else {
      this.openModal(this.confirmModalRef);
    }
  }

  /**
   * And Sets the conditional validation for the form based on the selected product discount type id.
   */
  onProductDiscountTypeChange() {
    /**
     * The id of the current selected product discount type.
     */
    const productDiscountTypeId = parseInt(this.form.controls.productDiscountType.value ?? '0');

    /**
     * Check if the product discount type is location.
     */
    if (productDiscountTypeId === this.productDiscountTypeForClassesId) {
      this.form.controls.classes.setValidators([Validators.required]);
      this.form.controls.products.clearValidators();
    } else {
      this.form.controls.products.setValidators([Validators.required]);
      this.form.controls.classes.clearValidators();
    }
    this.form.controls.products.setValue([]);
    this.form.controls.products.updateValueAndValidity();
    this.form.controls.classes.setValue([]);
    this.form.controls.classes.updateValueAndValidity();
  }

  /**
   * Confirms the the form submit.
   */
  confirm() {
    this.storesStore$.dispatch(
      new fromStoresStore.CreateProductDiscount({
        products: this.form.value.products,
        classes: this.form.value.classes,
        locationId: this.form.value.locationId,
        discount: this.form.value.discount,
        discountPercent: this.form.value.discountPercent,
      })
    );
  }

  /**
   * Opens the modal of the given templateRef.
   * @param modalRef The modal templateRef to be opened.
   */
  openModal(modalRef) {
    this.modalService.open(modalRef);
  }

  /**
   * Updates the browser url according to the selected page.
   * @param page The newly selected page.
   */
  selectedPageChanged(page: PAGES) {
    this.locationService.replaceState(`${this.locationService.path()}#${PAGES[page]}`);
  }

  /**
   * Adds the newly selected locations the product classes search list.
   * @param classes The list of newly selected classes to be added.
   */
  selectClasses(classes: ProductClass[]) {
    const selectedIds: number[] = this.form.get('classes').value;
    this.classes = [...this.classes.filter((productClass) => selectedIds.includes(productClass.id)), ...classes];
    this.form.patchValue({ classes: this.classes.map((productClass) => productClass.id) });
  }

  /**
   * Adds the newly selected locations the product classes search list.
   * @param classes The list of newly selected classes to be added.
   */
  selectProducts(products: Product[]) {
    const selectedIds: number[] = this.form.get('products').value;
    this.products = [...this.products.filter((product) => selectedIds.includes(product.id)), ...products];
    this.form.patchValue({ products: this.products.map((product) => product.id) });
  }

  /**
   * Selects the newly selected location from the locations search list.
   * @param locations The list of newly selected locations to select the only one in the list.
   */
  selectLocation([location]: Location[]) {
    if (location) {
      this.locations = [location];
      this.form.patchValue({ locationId: location.id });
    }
  }
}
