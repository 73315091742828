
    import { IdentityTypeActionType, IdentityTypeActions } from 'lookups/store/actions';
    import { IdentityTypeState } from 'lookups/store/states';
    
    /**
     * The initial state from which the state starts.
     */
    const initialState: IdentityTypeState = {
      data: [],
      error: null,
      isLoaded: false,
      isLoading: false,
    };
    
    /**
     * The reducer function that is called in each action dispatch against the state.
     * @param state The current state.
     * @param action The action that will affect the state.
     */
    export function identityTypeReducer(
      state: IdentityTypeState = initialState,
      action: IdentityTypeActions
    ): IdentityTypeState {
      switch (action.type) {
        case IdentityTypeActionType.GET_ALL_IDENTITY_TYPE: {
          return {
            ...state,
            isLoading: true,
          };
        }
    
        case IdentityTypeActionType.GET_ALL_IDENTITY_TYPE_FAIL: {
          return {
            ...state,
            error: action.payload,
            isLoading: false,
            isLoaded: false,
          };
        }
    
        case IdentityTypeActionType.GET_ALL_IDENTITY_TYPE_SUCCESS: {
          return {
            ...state,
            data: action.payload.data,
            isLoading: false,
            isLoaded: true,
          };
        }
    
        default:
          return state;
      }
    }    
