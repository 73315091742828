import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { catchError, finalize, switchMap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { DateTime } from 'luxon';
import { includes } from 'lodash';

import * as fromCoreStore from 'core/store';
import * as fromAuthStore from 'auth/store';
import { AuthService } from 'auth/services';
import { NotificationService, StorageService, TranslationService, AppHttpResponse, StatusCode } from 'shared';
import { environment } from '../../../environments/environment';

/**
 * The interceptor service that intercepts each http-request,
 * to add some useful information, e.g. authorization-header.
 */
@Injectable()
export class InterceptorService implements HttpInterceptor {
  /**
   * The set of api-routes for which the loader will discard and consider it as a background processes.
   */
  private silentRequests: string[] = [];

  /**
   * Whether if the JWT has been refreshed before or not.
   */
  private isJWTRefreshed = false;

  /**
   * @param coreStore$ The core-Module store.
   * @param authStore$ The auth-Module store.
   * @param storageService The storage service.
   * @param notificationService The notification service.
   * @param authService The auth service.
   * @param translationService The translation service.
   */

  constructor(
    private coreStore$: Store<fromCoreStore.AppState>,
    private authStore$: Store<fromAuthStore.AuthState>,
    private storageService: StorageService,
    private notificationService: NotificationService,
    private authService: AuthService,
    private translationService: TranslationService
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    /**
     * Discard any i18n translation request.
     */
    if (includes(req.url, '/assets/i18n')) {
      return next.handle(req.clone());
    }

    /*
     * If the request isn't going to a third party & it is issued to our backend-api.
     * Then the request url should be modified.
     */

    /**
     * Check if this request should be run silent.
     */
    const isSilentRequest = this.silentRequests.includes(req.url);
    if (!isSilentRequest) {
      /**
       * Start loader.
       */
      this.coreStore$.dispatch(new fromCoreStore.AppStatusStartLoad());
    }

    /* Handle request. */
    return next.handle(this.applyCredentials(req)).pipe(
      finalize(() => {
        /**
         * Stop loader whenever this request completed or error.
         */
        if (!isSilentRequest) {
          this.coreStore$.dispatch(new fromCoreStore.AppStatusEndLoad());
        }
      }),
      catchError((error) => {
        const result: AppHttpResponse<any> = { ...error.error, statusCode: error.status };

        if (result.statusCode === StatusCode.ConnectionRefused) {
          this.notificationService.errorWithTitle({
            title: this.translationService.translate('CORE.ERRORS.INTERNET_DISCONNECT'),
            body: [this.translationService.translate('CORE.ERRORS.CHECK_INTERNET')],
          });
        } else if (result.statusCode === StatusCode.ClientErrorForbidden) {
          this.notificationService.errorWithTitle({
            title: this.translationService.translate('CORE.ERRORS.NOT_FOUND'),
            body: [this.translationService.translate('CORE.ERRORS.NOT_ALLOWED')],
          });
        } else if (result.statusCode === StatusCode.ServerErrorInternal) {
          this.notificationService.error(this.translationService.translate('CORE.ERRORS.SERVER_ERROR'));
        } else if (result.statusCode === StatusCode.ClientErrorUnauthorized) {
          /**
           * Check if the JWT is refreshed before or not.
           */
          if (this.isJWTRefreshed) {
            this.isJWTRefreshed = false;

            this.authStore$.dispatch(new fromAuthStore.Logout(true));
            return throwError(error);
          }

          return this.handleUnauthorizedError(req, next);
        }

        return throwError(result);
      })
    );
  }

  handleUnauthorizedError(req: HttpRequest<any>, next: HttpHandler) {
    this.isJWTRefreshed = true;

    return this.authService.refreshToken().pipe(
      switchMap((response) => {
        if (response) {
          const subscription = this.authStore$
            .select(fromAuthStore.getSessionRememberUser)
            .subscribe((rememberUser) => {
              /**
               * Save jwt to local storage.
               */
              this.storageService.setJwt(response.data.jwt, rememberUser);

              /**
               * Refresh store data.
               */
              this.authStore$.dispatch(
                new fromAuthStore.RefreshTokenSuccess({ response: response.data, rememberUser })
              );

              /**
               * Unsubscribe and release the object.
               */
              subscription.unsubscribe();
            });

          /**
           * In case of token refreshed, repeat the request that caused the jwt expired.
           */
          return next.handle(this.applyCredentials(req)).pipe(
            catchError((error) => {
              this.authStore$.dispatch(new fromAuthStore.Logout(true));
              return throwError(error);
            })
          );
        }

        /**
         * If we don't get a new token, we are in trouble so logout.
         */
        this.authStore$.dispatch(new fromAuthStore.Logout(true));
      }),
      catchError((error) => {
        this.authStore$.dispatch(new fromAuthStore.Logout(true));
        return throwError(error);
      })
    );
  }

  applyCredentials(req: HttpRequest<any>): HttpRequest<any> {
    let domain = window.location.origin.replace(/(^\w+:|^)\/\//, '');
    // If there's a path or query string, remove it
    domain = domain.split('/')[0];
    return req.clone({
      url: `${req.url.includes('reporting') ? environment.reportingServiceApiUrl : environment.apiUrl}/api/${req.url}`,
      setHeaders: {
        Authorization: this.storageService.getJwt()?.token ?? '',
        'Time-Zone': DateTime.now().zoneName,
        'device-id': this.storageService.deviceId,
        'client-domain':domain
      },
      params: req.params.set('lang', this.translationService.language),
    });
  }
}
