import { createSelector } from '@ngrx/store';

import { ProjectsState, AppSearchState } from 'app-search/store/states';
import { selectAppSearchState } from 'app-search/store/selectors/feature.selectors';

/**
 * Gets the projects state.
 */
const selectProjectState = createSelector(selectAppSearchState, (state: AppSearchState) => state.projects);

/**
 * Gets the list of loaded projects.
 */
export const getProjects = createSelector(selectProjectState, (state: ProjectsState) => state.data);

/**
 * Gets the projects pagination info.
 */
export const getProjectsPaginationInfo = createSelector(
  selectProjectState,
  (state: ProjectsState) => state.paginationInfo
);

/**
 * Gets the error that occurred in the state.
 */
export const getProjectsError = createSelector(selectProjectState, (state: ProjectsState) => state.error);

/**
 * Determines if there is a running search process.
 */
export const getProjectsSearching = createSelector(selectProjectState, (state: ProjectsState) => state.isSearching);

/**
 * Determines if the last search process has been ended successfully.
 */
export const getProjectsSearchCompleted = createSelector(
  selectProjectState,
  (state: ProjectsState) => state.isSearchCompleted
);
