<!-- page header starts -->
<app-page-header [info]="pageInfo"></app-page-header>
<!-- page header ends -->

<!-- update user starts -->
<div class="card">
  <div class="card-content">
    <div class="px-3 pt-3" *ngIf="user$ | async; let user">
      <!-- user dockable status starts -->
      <app-dockable-status
        [status]="user.isActive"
        [activeText]="'SECURITY.USERS.USER_DATA.ACTIVE_STATUS' | translate"
        [notActiveText]="'SECURITY.USERS.USER_DATA.NOT_ACTIVE_STATUS' | translate"
      >
      </app-dockable-status>
      <!-- user dockable status ends -->
      <div class="row">
        <div class="col-md-12">
          <ul
            class="nav-tabs"
            ngbNav
            #nav="ngbNav"
            [(activeId)]="activePage"
            (activeIdChange)="selectedPageChanged($event)"
            [destroyOnHide]="false"
          >
            <li
              *ngIf="
                ([Claims.UPDATE_ORGANIZATION_USER_CLAIMS] | authorize | async) && currentLoggedInUserId !== user.id
              "
              ngbNavItem="claims"
            >
              <a ngbNavLink>
                <i class="fa fa-user-secret" [class.success]="activePage == 'claims'"></i>
                {{ 'SECURITY.USERS.UPDATE_USER_PERMISSIONS_PAGE_TITLE' | translate }}
              </a>
              <ng-template ngbNavContent>
                <app-update-organization-user-claims [userId]="userId"></app-update-organization-user-claims>
              </ng-template>
            </li>
            <li
              *ngIf="([Claims.UPDATE_USER] | authorize | async) && currentLoggedInUserId !== user.id"
              ngbNavItem="update-user"
            >
              <a ngbNavLink>
                <i class="fa fa-user-secret" [class.success]="activePage == 'update-user'"></i>
                {{ 'SECURITY.USERS.UPDATE_USER_PERMISSIONS_PAGE_TITLE' | translate }}
              </a>
              <ng-template ngbNavContent>
                <app-update-user [userId]="userId"></app-update-user>
              </ng-template>
            </li>
            <li
              *ngIf="
                ([Claims.RESET_ORGANIZATION_USER_CREDENTIALS, Claims.RESET_USER_CREDENTIALS] | authorize | async) &&
                currentLoggedInUserId !== user.id
              "
              ngbNavItem="credentials"
            >
              <a ngbNavLink>
                <i class="fa fa-key" [class.success]="activePage == 'credentials'"></i>
                {{ 'SECURITY.USERS.RESET_USER_LOGIN_DATA_TITLE' | translate }}
              </a>
              <ng-template ngbNavContent>
                <app-reset-user-credentials [userId]="userId"> </app-reset-user-credentials>
              </ng-template>
            </li>
            <li *ngIf="[Claims.UPDATE_ORGANIZATION_USER_LOCATIONS] | authorize | async" ngbNavItem="locations">
              <a ngbNavLink>
                <i class="fa fa-map-signs" [class.success]="activePage == 'locations'"></i>
                {{ 'SECURITY.USERS.LOCATIONS_TITLE' | translate }}
              </a>
              <ng-template ngbNavContent>
                <app-update-user-location [userId]="userId"> </app-update-user-location>
              </ng-template>
            </li>
            <li *ngIf="[Claims.UPDATE_ORGANIZATION_USER_BANK_ACCOUNTS] | authorize | async" ngbNavItem="bank-accounts">
              <a ngbNavLink>
                <i class="icon-notebook" [class.success]="activePage == 'bank-accounts'"></i>
                {{ 'SECURITY.USERS.BANK_ACCOUNTS_TITLE' | translate }}
              </a>
              <ng-template ngbNavContent>
                <app-update-user-bank-account [userId]="userId"> </app-update-user-bank-account>
              </ng-template>
            </li>
            <li *ngIf="[Claims.UPDATE_ORGANIZATION_USER_COST_CENTERS] | authorize | async" ngbNavItem="cost-centers">
              <a ngbNavLink>
                <i class="icon-vector" [class.success]="activePage == 'cost-centers'"></i>
                {{ 'SECURITY.USERS.COST_CENTERS_TITLE' | translate }}
              </a>
              <ng-template ngbNavContent>
                <app-update-user-cost-center [userId]="userId"> </app-update-user-cost-center>
              </ng-template>
            </li>
          </ul>
          <div [ngbNavOutlet]="nav" class="mt-2"></div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- update user ends -->
