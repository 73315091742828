import { ActionReducerMap } from '@ngrx/store';

import { sessionReducer } from 'auth/store/reducers/session.reducer';
import { AuthState } from 'auth/store/states';

/**
 * The Auth-Module store reducers.
 */
export const reducers: ActionReducerMap<AuthState> = {
  session: sessionReducer,
};
