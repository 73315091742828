import { Action } from '@ngrx/store';

import { AppHttpResponse } from 'shared';
import { Tax, CreateTaxInput, UpdateTaxInput } from 'settings/models';

/**
 * The taxes-module store taxes action types.
 */
export enum TaxesActionType {
  SEARCH_TAXES = '[Taxes] [Taxes] Search Taxes',
  SEARCH_TAXES_FAIL = '[Taxes] [Taxes] Search Taxes Fail',
  SEARCH_TAXES_SUCCESS = '[Taxes] [Taxes] Search Taxes Success',

  FIND_TAX = '[Taxes] [Taxes] Find Tax',
  FIND_TAX_FAIL = '[Taxes] [Taxes] Find Tax Fail',
  FIND_TAX_SUCCESS = '[Taxes] [Taxes] Find Tax Success',

  FIND_DEFAULT_TAX = '[Taxes] [Taxes] Find Default Tax',
  FIND_DEFAULT_TAX_FAIL = '[Taxes] [Taxes] Find Default Tax Fail',
  FIND_DEFAULT_TAX_SUCCESS = '[Taxes] [Taxes] Find Default Tax Success',

  CREATE_TAX = '[Taxes] [Taxes] Create Tax',
  CREATE_TAX_FAIL = '[Taxes] [Taxes] Create Tax Fail',
  CREATE_TAX_SUCCESS = '[Taxes] [Taxes] Create Tax Success',

  UPDATE_TAX = '[Taxes] [Taxes] Update Tax',
  UPDATE_TAX_FAIL = '[Taxes] [Taxes] Update Tax Fail',
  UPDATE_TAX_SUCCESS = '[Taxes] [Taxes] Update Tax Success',

  DELETE_TAX = '[Taxes] [Taxes] Delete Tax',
  DELETE_TAX_FAIL = '[Taxes] [Taxes] Delete Tax Fail',
  DELETE_TAX_SUCCESS = '[Taxes] [Taxes] Delete Tax Success',
}

/**
 * Represents a store taxes search action.
 */
export class SearchTaxes implements Action {
  /**
   * The type of the action.
   */
  readonly type = TaxesActionType.SEARCH_TAXES;

  /**
   * Fires a new taxes search action.
   * @param payload the search parameters,
   * if omitted, all taxes will be loaded.
   */
  constructor(public payload?: { name: string; page: number }) {}
}

/**
 * Represents a store taxes search-fail action.
 */
export class SearchTaxesFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = TaxesActionType.SEARCH_TAXES_FAIL;

  /**
   * Fires a new taxes search-fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store search-success action.
 */
export class SearchTaxesSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = TaxesActionType.SEARCH_TAXES_SUCCESS;

  /**
   * Fires a new taxes search-success action.
   * @param payload An object contains the list of taxes that matches the search criteria
   * in addition to the pagination info.
   */
  constructor(public payload: AppHttpResponse<Tax[]>) {}
}

/**
 * Represents a store find-tax action.
 */
export class FindTax implements Action {
  /**
   * The type of the action.
   */
  readonly type = TaxesActionType.FIND_TAX;

  /**
   * Fires a new find tax action.
   * @param payload the id of the tax.
   */
  constructor(public payload: number) {}
}

/**
 * Represents a store find-tax-fail action.
 */
export class FindTaxFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = TaxesActionType.FIND_TAX_FAIL;

  /**
   * Fires a new find-tax-fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store find tax-success action.
 */
export class FindTaxSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = TaxesActionType.FIND_TAX_SUCCESS;

  /**
   * Fires a new find-tax-success action.
   * @param payload The loaded tax.
   */
  constructor(public payload: AppHttpResponse<Tax>) {}
}

/**
 * Represents a store find-default-tax action.
 */
export class FindDefaultTax implements Action {
  /**
   * The type of the action.
   */
  readonly type = TaxesActionType.FIND_DEFAULT_TAX;

  /**
   * Fires a new find default tax action.
   */
  constructor() {}
}

/**
 * Represents a store find-default-tax-fail action.
 */
export class FindDefaultTaxFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = TaxesActionType.FIND_DEFAULT_TAX_FAIL;

  /**
   * Fires a new find-default-tax-fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store find default tax-success action.
 */
export class FindDefaultTaxSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = TaxesActionType.FIND_DEFAULT_TAX_SUCCESS;

  /**
   * Fires a new find-default-tax-success action.
   * @param payload The loaded tax.
   */
  constructor(public payload: AppHttpResponse<Tax>) {}
}

/**
 * Represents a store tax create action.
 */
export class CreateTax implements Action {
  /** The type of the action. */
  readonly type = TaxesActionType.CREATE_TAX;

  /**
   * Fires a new tax create action.
   * @param payload the new tax data-model.
   */
  constructor(public payload: CreateTaxInput) {}
}

/**
 * Represents a store tax create-fail action.
 */
export class CreateTaxFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = TaxesActionType.CREATE_TAX_FAIL;

  /**
   * Fires a new tax create-fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store tax create-success action.
 */
export class CreateTaxSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = TaxesActionType.CREATE_TAX_SUCCESS;

  /**
   * Fires a new tax create-success action.
   * @param payload The created tax.
   */
  constructor(public payload: AppHttpResponse<Tax>) {}
}

/**
 * Represents a store tax update action.
 */
export class UpdateTax implements Action {
  /**
   * The type of the action.
   */
  readonly type = TaxesActionType.UPDATE_TAX;

  /**
   * Fires a new tax update action.
   * @param payload The tax's updated data.
   */
  constructor(public payload: UpdateTaxInput) {}
}

/**
 * Represents a store tax update-fail action.
 */
export class UpdateTaxFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = TaxesActionType.UPDATE_TAX_FAIL;

  /**
   * Fires a new tax update-fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store tax update-success action.
 */
export class UpdateTaxSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = TaxesActionType.UPDATE_TAX_SUCCESS;

  /**
   * Fires a new tax update-success action.
   * @param payload The updated tax.
   */
  constructor(public payload: AppHttpResponse<Tax>) {}
}

/**
 * Represents a store tax delete action.
 */
export class DeleteTax implements Action {
  /**
   * The type of the action.
   */
  readonly type = TaxesActionType.DELETE_TAX;

  /**
   * Fires a new tax delete action.
   * @param payload The tax's id.
   */
  constructor(public payload: number) {}
}

/**
 * Represents a store tax delete-fail action.
 */
export class DeleteTaxFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = TaxesActionType.DELETE_TAX_FAIL;

  /**
   * Fires a new tax delete-fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store tax delete-success action.
 */
export class DeleteTaxSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = TaxesActionType.DELETE_TAX_SUCCESS;

  /**
   * Fires a new tax delete-success action.
   * @param payload The deleted tax.
   */
  constructor(public payload: AppHttpResponse<Tax>) {}
}

/**
 * taxes-module taxes action types.
 */
export type TaxesActions =
  | SearchTaxes
  | SearchTaxesFail
  | SearchTaxesSuccess
  | FindTax
  | FindTaxFail
  | FindTaxSuccess
  | FindDefaultTax
  | FindDefaultTaxFail
  | FindDefaultTaxSuccess
  | CreateTax
  | CreateTaxFail
  | CreateTaxSuccess
  | UpdateTax
  | UpdateTaxFail
  | UpdateTaxSuccess
  | DeleteTax
  | DeleteTaxFail
  | DeleteTaxSuccess;
