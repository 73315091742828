<!-- page header starts -->
<app-page-header [info]="pageInfo"></app-page-header>
<!-- page header ends -->

<div class="card" *ngIf="govServiceRequest$ | async; let govServiceRequest">
  <!-- gov service request details starts -->
  <div class="card-content">
    <div class="px-3 pt-3">
      <div class="row">
        <div class="col-md-12">
          <div class="card-header mb-3 pt-2">
            <h4 class="card-title">
              {{ 'HR.GOV_SERVICE_REQUESTS.GOV_SERVICE_REQUEST_DATA.GOV_SERVICE_REQUEST_DATA' | translate }}
            </h4>
          </div>
          <div class="card-content">
            <div class="px-3">
              <div class="row">
                <div class="form-group col-md-6">
                  <label for="govService">{{
                    'HR.GOV_SERVICE_REQUESTS.GOV_SERVICE_REQUEST_DATA.GOV_SERVICE' | translate
                  }}</label>
                  <input
                    type="text"
                    id="govService"
                    class="form-control round"
                    [value]="govServiceRequest.govService.name | localize: govServiceRequest.govService.nameEn | async"
                    readonly
                  />
                </div>
                <div class="form-group col-md-6">
                  <label for="value">{{ 'HR.GOV_SERVICE_REQUESTS.GOV_SERVICE_REQUEST_DATA.VALUE' | translate }}</label>
                  <input
                    type="text"
                    id="value"
                    class="form-control round"
                    [value]="govServiceRequest.value | toDecimal"
                    readonly
                  />
                </div>
                <div class="form-group col-md-12">
                  <label for="govServiceRequestStatus">{{
                    'HR.GOV_SERVICE_REQUESTS.GOV_SERVICE_REQUEST_DATA.STATUS' | translate
                  }}</label>
                  <input
                    type="text"
                    id="govServiceRequestStatus"
                    class="form-control round"
                    [value]="
                      govServiceRequest.govServiceRequestStatus.name
                        | localize: govServiceRequest.govServiceRequestStatus.nameEn
                        | async
                    "
                    readonly
                  />
                </div>
                <div *ngIf="govServiceRequest.govServiceRequestPaymentMethod" class="form-group col-md-6">
                  <label for="govServiceRequestPaymentMethod">{{
                    'HR.GOV_SERVICE_REQUESTS.GOV_SERVICE_REQUEST_DATA.PAYMENT_METHOD' | translate
                  }}</label>
                  <input
                    type="text"
                    id="govServiceRequestPaymentMethod"
                    class="form-control round"
                    [value]="
                      govServiceRequest.govServiceRequestPaymentMethod.name
                        | localize: govServiceRequest.govServiceRequestPaymentMethod.nameEn
                        | async
                    "
                    readonly
                  />
                </div>
                <div *ngIf="govServiceRequest.bankAccount" class="form-group col-md-6">
                  <label for="bankAccount">{{
                    'HR.GOV_SERVICE_REQUESTS.GOV_SERVICE_REQUEST_DATA.BANK_ACCOUNT' | translate
                  }}</label>
                  <input
                    type="text"
                    id="bankAccount"
                    class="form-control round"
                    [value]="
                      govServiceRequest.bankAccount.name | localize: govServiceRequest.bankAccount.nameEn | async
                    "
                    readonly
                  />
                </div>
                <div class="form-group col-md-4">
                  <label for="createTransactionDate">{{
                    'HR.GOV_SERVICE_REQUESTS.GOV_SERVICE_REQUEST_DATA.TRANSACTION_DATE' | translate
                  }}</label>
                  <input
                    type="text"
                    id="createTransactionDate"
                    class="form-control round"
                    [value]="govServiceRequest.createTransactionDate | date: 'datetime'"
                    readonly
                  />
                </div>
                <div class="form-group col-md-4">
                  <label for="createdAt">{{ 'SHARED.SEARCH.CREATED_AT' | translate }}</label>
                  <input
                    type="text"
                    id="createdAt"
                    class="form-control round"
                    [value]="govServiceRequest.createdAt | date: 'datetime'"
                    readonly
                  />
                </div>
                <div class="form-group col-md-4">
                  <label for="userCreated">{{
                    'HR.GOV_SERVICE_REQUESTS.GOV_SERVICE_REQUEST_DATA.USER_CREATED' | translate
                  }}</label>
                  <input
                    type="text"
                    id="userCreated"
                    class="form-control round"
                    [value]="govServiceRequest.userCreated.name"
                    readonly
                  />
                </div>
                <div class="form-group col-md-4" *ngIf="govServiceRequest.userUpdated">
                  <label for="updateTransactionDate">{{
                    'HR.GOV_SERVICE_REQUESTS.GOV_SERVICE_REQUEST_DATA.TRANSACTION_DATE' | translate
                  }}</label>
                  <input
                    type="text"
                    id="updateTransactionDate"
                    class="form-control round"
                    [value]="govServiceRequest.updateTransactionDate | date: 'datetime'"
                    readonly
                  />
                </div>
                <div class="form-group col-md-4" *ngIf="govServiceRequest.userUpdated">
                  <label for="updatedAt">{{
                    'HR.GOV_SERVICE_REQUESTS.GOV_SERVICE_REQUEST_DATA.UPDATED_AT' | translate
                  }}</label>
                  <input
                    type="text"
                    id="updatedAt"
                    class="form-control round"
                    [value]="govServiceRequest.updatedAt | date: 'datetime'"
                    readonly
                  />
                </div>
                <div class="form-group col-md-4" *ngIf="govServiceRequest.userUpdated">
                  <label for="userUpdated">{{
                    'HR.GOV_SERVICE_REQUESTS.GOV_SERVICE_REQUEST_DATA.USER_UPDATED' | translate
                  }}</label>
                  <input
                    type="text"
                    id="userUpdated"
                    class="form-control round"
                    [value]="govServiceRequest.userUpdated.name"
                    readonly
                  />
                </div>
                <div class="form-group col-md-4" *ngIf="govServiceRequest.userPaid">
                  <label for="paymentTransactionDate">{{
                    'HR.GOV_SERVICE_REQUESTS.GOV_SERVICE_REQUEST_DATA.TRANSACTION_DATE' | translate
                  }}</label>
                  <input
                    type="text"
                    id="paymentTransactionDate"
                    class="form-control round"
                    [value]="govServiceRequest.paymentTransactionDate | date: 'datetime'"
                    readonly
                  />
                </div>
                <div class="form-group col-md-4" *ngIf="govServiceRequest.userPaid">
                  <label for="paidAt">{{
                    'HR.GOV_SERVICE_REQUESTS.GOV_SERVICE_REQUEST_DATA.PAID_AT' | translate
                  }}</label>
                  <input
                    type="text"
                    id="paidAt"
                    class="form-control round"
                    [value]="govServiceRequest.paidAt | date: 'datetime'"
                    readonly
                  />
                </div>
                <div class="form-group col-md-4" *ngIf="govServiceRequest.userPaid">
                  <label for="userPaid">{{
                    'HR.GOV_SERVICE_REQUESTS.GOV_SERVICE_REQUEST_DATA.USER_PAID' | translate
                  }}</label>
                  <input
                    type="text"
                    id="userPaid"
                    class="form-control round"
                    [value]="govServiceRequest.userPaid.name"
                    readonly
                  />
                </div>
                <div class="form-group col-md-12">
                  <label for="notes">{{ 'HR.GOV_SERVICE_REQUESTS.GOV_SERVICE_REQUEST_DATA.NOTES' | translate }}</label>
                  <textarea
                    id="notes"
                    class="form-control round"
                    [innerText]="govServiceRequest.notes"
                    [placeholder]="'HR.GOV_SERVICE_REQUESTS.GOV_SERVICE_REQUEST_DATA.NOTES_NOT_FOUND' | translate"
                    readonly
                  ></textarea>
                </div>
                <div class="form-group col-md-12">
                  <a (click)="selectedGovServiceRequest = govServiceRequest; openModal(viewAttachmentsModalRef)">
                    <i class="fa fa-paperclip"></i>
                    <span class="mx-1">{{
                      'HR.GOV_SERVICE_REQUESTS.GOV_SERVICE_REQUEST_DATA.DISPLAY_ATTACHMENTS' | translate
                    }}</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-12">
          <!-- gov service request employee details starts -->
          <div class="card-header mb-3 pt-2">
            <h4 class="card-title">
              {{ 'HR.GOV_SERVICE_REQUESTS.GOV_SERVICE_REQUEST_DATA.EMPLOYEES' | translate }}
            </h4>
          </div>
          <div class="card-content">
            <div class="px-3">
              <div class="row">
                <div class="col-md-12">
                  <div class="table-responsive">
                    <table class="table text-center">
                      <thead>
                        <tr>
                          <th scope="col"><i class="fa fa-list-ol"></i></th>
                          <th scope="col">
                            {{ 'HR.GOV_SERVICE_REQUESTS.GOV_SERVICE_REQUEST_DATA.EMPLOYEE_NUMBER' | translate }}
                          </th>
                          <th scope="col">
                            {{ 'HR.GOV_SERVICE_REQUESTS.GOV_SERVICE_REQUEST_DATA.EMPLOYEE_NAME' | translate }}
                          </th>
                          <th scope="col">
                            {{ 'HR.GOV_SERVICE_REQUESTS.GOV_SERVICE_REQUEST_DATA.FROM_DATE' | translate }}
                          </th>
                          <th scope="col">
                            {{ 'HR.GOV_SERVICE_REQUESTS.GOV_SERVICE_REQUEST_DATA.TO_DATE' | translate }}
                          </th>
                          <th scope="col">
                            {{ 'HR.GOV_SERVICE_REQUESTS.GOV_SERVICE_REQUEST_DATA.SERVICE_VALUE' | translate }}
                          </th>
                          <th scope="col">
                            {{ 'HR.GOV_SERVICE_REQUESTS.GOV_SERVICE_REQUEST_DATA.PENALTY_VALUE' | translate }}
                          </th>
                          <th scope="col">
                            {{ 'HR.GOV_SERVICE_REQUESTS.GOV_SERVICE_REQUEST_DATA.TOTAL' | translate }}
                          </th>
                          <th scope="col">
                            {{ 'HR.GOV_SERVICE_REQUESTS.GOV_SERVICE_REQUEST_DATA.NOTES' | translate }}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          *ngFor="
                            let requestEmployee of orderEmployees(govServiceRequest.govServiceRequestEmployees);
                            let i = index
                          "
                        >
                          <th scope="row">{{ i + 1 }}</th>
                          <th scope="row">{{ requestEmployee.employee.employeeAssignmentDetail.personNumber }}</th>
                          <td>
                            {{
                              requestEmployee.employee.employeePersonalDetail.fullName
                                | localize: requestEmployee.employee.employeePersonalDetail.fullNameEn
                                | async
                            }}
                          </td>
                          <td>
                            <span>
                              {{ requestEmployee.fromDate }}
                            </span>
                          </td>
                          <td>
                            <span>
                              {{ requestEmployee.toDate }}
                            </span>
                          </td>
                          <td>{{ requestEmployee.serviceValue | toDecimal }}</td>
                          <td>{{ requestEmployee.penaltyValue | toDecimal }}</td>
                          <td>{{ requestEmployee.net | toDecimal }}</td>
                          <td>{{ requestEmployee.notes }}</td>
                        </tr>
                      </tbody>
                      <tfoot class="tfoot-light">
                        <tr>
                          <td [attr.colspan]="9">
                            <i class="fa fa-bookmark c-primary"></i>
                            <span>
                              {{ govServiceRequest.govServiceRequestEmployees.length | toNumber }}
                              {{ 'HR.GOV_SERVICE_REQUESTS.GOV_SERVICE_REQUEST_DATA.EMPLOYEE' | translate }}</span
                            >
                          </td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-2">
        <div class="form-group col-12 d-flex justify-content-end mt-2">
          <button class="btn btn-dark round" routerLink="/finances/hr" fragment="governmental-service-requests">
            <i class="fa fa-undo"></i>
            <span class="mx-1">{{ 'HR.GOV_SERVICE_REQUESTS.GOV_RELATIONS_RETURN' | translate }}</span>
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- gov service request details ends -->
</div>

<!-- view attachments modal starts -->
<ng-template #viewAttachmentsModalRef let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title text-info" id="modal-basic-title">
      {{ 'HR.GOV_SERVICE_REQUESTS.GOV_SERVICE_REQUEST_DATA.ATTACHMENTS' | translate }}
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="d()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="card-content">
        <div class="px-3">
          <div class="row">
            <div class="form-group col-md-12">
              <!-- gov service request attachments starts -->
              <div class="card-content">
                <div class="px-3">
                  <app-files-viewer
                    *ngIf="selectedGovServiceRequest.govServiceRequestAttachments?.length; else noAttachments"
                    [files]="getAttachmentsUrls(selectedGovServiceRequest.govServiceRequestAttachments)"
                  ></app-files-viewer>
                </div>
              </div>
              <!-- gov service request attachments ends -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="modal-actions">
      <button type="button" class="btn btn-raised btn-primary round" (click)="c()" ngbAutoFocus>
        <i class="fa fa-times"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.CLOSE' | translate }}</span>
      </button>
    </div>
  </div>
</ng-template>
<!-- view attachments modal ends -->

<!-- no attachments template starts -->
<ng-template #noAttachments>
  <div class="col-md-12 alert alert-warning mt-3 text-center" role="alert">
    {{ 'HR.GOV_SERVICE_REQUESTS.GOV_SERVICE_REQUEST_DATA.ATTACHMENTS_NOT_FOUND' | translate }}
  </div>
</ng-template>
<!-- no attachments template ends -->
