import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { Account } from 'finances/models';

@Component({
  selector: '[app-employees-provision-link-form-item]',
  templateUrl: './employees-provision-link-form-item.component.html',
  styles: [],
})
export class EmployeesProvisionLinkFormItemComponent implements OnInit {
  /**
   * Sets the employee provision link index in the links-form.
   */
  @Input() index: number;

  /**
   * Sets the employee provision link form-group.
   */
  @Input() employeeProvisionLinkGroup: FormGroup;

  /**
   * Emits a value indicates that a drop-down field in the form is opened and therefore the table height should be expanded.
   */
  @Output() dropDownIsOpened = new EventEmitter<boolean>(false);

  /**
   * The list of selected accounts.
   */
  accounts: Account[] = [];

  /**
   * Shows or hide the accounts list.
   */
  accountsListVisibility = false;

  /**
   * Returns the list of accounts that should be displayed in the accounts select list.
   */
  get accountsList(): Account[] {
    const list: Account[] = [];

    if (this.accounts?.length) {
      list.push(...this.accounts);
    }
    if (this.employeeProvisionLinkGroup?.value?.account) {
      list.push(this.employeeProvisionLinkGroup.value.account);
    }

    return list;
  }

  /**
   * Destroy component data
   */
  ngOnInit(): void {}

  /**
   * Selects the newly selected account from the accounts search list.
   * @param accounts The list of newly selected account to select the only one in the list.
   */
  selectAccount([account]: Account[]) {
    if (account) {
      this.accounts = [account];
      this.employeeProvisionLinkGroup.patchValue({ accountId: account.id });
    }
  }
}
