import { cloneDeep, orderBy } from 'lodash';

import { ClaimsActionType, ClaimsActions } from 'security/store/actions';
import { ClaimsState } from 'security/store/states';

/**
 * The initial state from which the state starts.
 */
const initialState: ClaimsState = {
  claimGroups: [],
  currentLoggedInUserClaimGroups: [],
  paginationInfo: {
    total: 0,
    page: 1,
    pageSize: 0,
    count: 0,
  },
  error: null,
  isGetClaimsGroupsCompleted: false,
  isGettingClaimsGroups: false,
  isGetCurrentLoggedInUserClaimsGroupsCompleted: false,
  isGettingCurrentLoggedInUserClaimsGroups: false,
};

/**
 * The reducer function that is called in each action dispatch against the state.
 * @param state The current state.
 * @param action The action that will affect the state.
 */
export function claimsReducer(state: ClaimsState = initialState, action: ClaimsActions): ClaimsState {
  switch (action.type) {
    //#region GET_CLAIMS_GROUPS

    case ClaimsActionType.GET_CLAIMS_GROUPS: {
      return {
        ...state,
        isGetClaimsGroupsCompleted: false,
        isGettingClaimsGroups: true,
        error: null,
      };
    }

    case ClaimsActionType.GET_CLAIMS_GROUPS_FAIL: {
      return {
        ...state,
        error: action.payload,
        isGetClaimsGroupsCompleted: false,
        isGettingClaimsGroups: false,
      };
    }

    case ClaimsActionType.GET_CLAIMS_GROUPS_SUCCESS: {
      /**
       * Reorder group claims.
       */
      const groups = (action.payload.data ? cloneDeep(action.payload.data) : []).map((group) => {
        group.claims = orderBy(group.claims ?? [], (claim) => claim.displayOrder);
        return group;
      });

      return {
        ...state,
        claimGroups: groups,
        paginationInfo: {
          ...action.payload.meta,
        },
        error: null,
        isGetClaimsGroupsCompleted: true,
        isGettingClaimsGroups: false,
      };
    }

    // #end region GET_CLAIMS_GROUPS

    //#region GET_CURRENT_LOGGED_IN_USER_CLAIMS_GROUPS

    case ClaimsActionType.GET_CURRENT_LOGGED_IN_USER_CLAIMS_GROUPS: {
      return {
        ...state,
        isGetCurrentLoggedInUserClaimsGroupsCompleted: false,
        isGettingCurrentLoggedInUserClaimsGroups: true,
        error: null,
      };
    }

    case ClaimsActionType.GET_CURRENT_LOGGED_IN_USER_CLAIMS_GROUPS_FAIL: {
      return {
        ...state,
        isGetCurrentLoggedInUserClaimsGroupsCompleted: false,
        isGettingCurrentLoggedInUserClaimsGroups: false,
        error: action.payload,
      };
    }

    case ClaimsActionType.GET_CURRENT_LOGGED_IN_USER_CLAIMS_GROUPS_SUCCESS: {
      /**
       * Reorder current logged-in user claims groups.
       */
      const groups = (action.payload.data ? cloneDeep(action.payload.data) : []).map((group) => {
        group.claims = orderBy(group.claims ?? [], (claim) => claim.displayOrder);
        return group;
      });

      return {
        ...state,
        currentLoggedInUserClaimGroups: groups,
        error: null,
        isGetCurrentLoggedInUserClaimsGroupsCompleted: true,
        isGettingCurrentLoggedInUserClaimsGroups: false,
      };
    }

    // #end region GET_CURRENT_LOGGED_IN_USER_CLAIMS_GROUPS

    default:
      return state;
  }
}
