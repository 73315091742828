import { Component, Input } from '@angular/core';

import { SubProduct } from 'stores/models';

@Component({
  selector: 'app-sub-product-info',
  templateUrl: './sub-product-info.component.html',
  styles: [],
})
export class SubProductInfoComponent {
  /**
   * Sets the current displayed sub-product.
   */
  @Input() subProduct: SubProduct;
}
