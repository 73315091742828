import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { SharedModule } from 'shared';
import { SalesRoutingModule } from 'sales/sales-routing.module';
import { StoresModule } from 'stores';
import { FinancesModule } from 'finances';
import { AppSearchModule } from 'app-search';
import { TouchKeyboardModule } from 'touch-keyboard';

import * as fromContainers from 'sales/containers';
import * as fromComponents from 'sales/components';
import * as fromServices from 'sales/services';
import * as fromStore from 'sales/store';

@NgModule({
  declarations: [...fromContainers.CONTAINERS, ...fromComponents.COMPONENTS],
  imports: [
    CommonModule,
    SharedModule,
    FinancesModule,
    SalesRoutingModule,
    StoresModule,
    AppSearchModule,
    TouchKeyboardModule,
    StoreModule.forFeature('sales', fromStore.reducers),
    EffectsModule.forFeature(fromStore.effects),
  ],
  exports: [
    fromComponents.CustomersSearchComponent,
    fromComponents.SaleInvoicesSearchComponent,
    fromComponents.ShiftsSearchComponent,
  ],
  providers: [...fromServices.SERVICES],
})
export class SalesModule {}
