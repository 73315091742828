import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Subscription } from 'rxjs';
import * as fromAuthStore from 'auth/store';
import * as fromCoreStore from 'core/store';
import { StorageService } from 'shared';
import { tap, skip } from 'rxjs/operators';

@Component({
  selector: 'app-splash-screen',
  templateUrl: './splash-screen.component.html',
  styleUrls: ['./splash-screen.component.scss'],
})
export class SplashScreenComponent implements OnInit, OnDestroy {
  /**
   * The subscription that listen for user data fetched.
   */
  fetchDataSubscription: Subscription;

  /**.
   * @param coreStore$ The core-Module store.
   * @param authStore$ The auth-Module store.
   * @param storageService The storage service.
   */
  constructor(
    private coreStore$: Store<fromCoreStore.AppState>,
    private authStore$: Store<fromAuthStore.AuthState>,
    private storageService: StorageService
  ) {}

  ngOnInit() {
    /**
     * Check if there is a jwt for the user.
     */
    if (this.storageService.isJwtExists()) {
      /**
       * Start fetch user data.
       */
      this.authStore$.dispatch(new fromAuthStore.FetchUserData());

      /**
       * Subscribe to session error to detect if the fetch-user-data failed.
       */
      this.fetchDataSubscription = this.authStore$
        .pipe(
          select(fromAuthStore.getSessionLoggedIn),
          skip(1),
          tap((isLoggedIn: boolean) => {
            if (!isLoggedIn) {
              /**
               * Logout and redirect to login page.
               */
              this.authStore$.dispatch(new fromAuthStore.Logout(true));
            } else {
              /**
               * Redirect the user to homepage or the pre-stored redirectUrl.
               */
              this.redirect();
            }
          })
        )
        .subscribe();
    } else {
      /**
       * Logout and redirect to login page.
       */
      this.authStore$.dispatch(new fromAuthStore.Logout(true));
    }
  }

  ngOnDestroy(): void {
    if (this.fetchDataSubscription) {
      this.fetchDataSubscription.unsubscribe();
    }
  }

  /**
   * Redirects the user to the saved url in the local-storage if there is one, otherwise redirects the user to home page.
   */
  redirect() {
    if (this.storageService.redirectUrlExists()) {
      const urlComponents = this.storageService.getRedirectUrl().split('#');

      const url = urlComponents[0].split('#')[0].split('?')[0];
      const fragment = urlComponents[1]?.split('?')?.[0];
      const queryString = urlComponents[fragment ? 1 : 0]?.split('?')?.[1]?.split('&') ?? [];

      const queryParams: any = {};
      queryString.forEach((query) => {
        queryParams[`${query.split('=')?.[0]}`] = query.split('=')?.[1];
      });

      this.coreStore$.dispatch(
        new fromCoreStore.Go({
          path: [url],
          extras: {
            fragment: urlComponents[1],
          },
          query: queryParams,
        })
      );

      this.storageService.deleteRedirectUrl();
    } else {
      this.coreStore$.dispatch(new fromCoreStore.Go({ path: ['/'] }));
    }
  }
}
