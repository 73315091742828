import { Injectable } from '@angular/core';

import { of } from 'rxjs';
import { switchMap, map, catchError, tap, debounceTime } from 'rxjs/operators';
import { Effect, ofType, Actions } from '@ngrx/effects';

import { NotificationService, StatusCode, PAGINATION, TranslationService } from 'shared';
import { BoardsService } from 'sales/services';
import {
  BoardsActionType,
  CreateBoard,
  CreateBoardFail,
  CreateBoardSuccess,
  DeleteBoard,
  DeleteBoardFail,
  DeleteBoardSuccess,
  FindBoard,
  FindBoardFail,
  FindBoardSuccess,
  SearchBoards,
  SearchBoardsFail,
  SearchBoardsSuccess,
  UpdateBoard,
  UpdateBoardFail,
  UpdateBoardSuccess,
  SearchBoardsInPos,
  SearchBoardsInPosSuccess,
  SearchBoardsInPosFail,
} from 'sales/store/actions';

@Injectable()
export class BoardsEffects {
  constructor(
    private actions$: Actions,
    private boardsService: BoardsService,
    private notificationService: NotificationService,
    private translationService: TranslationService
  ) {}

  /* ========================= SEARCH_BOARDS =================================== */
  @Effect()
  search$ = this.actions$.pipe(
    ofType(BoardsActionType.SEARCH_BOARDS),
    debounceTime(300),
    switchMap(({ payload }: SearchBoards) =>
      this.boardsService.search(payload?.description ?? '', payload.locations, payload.page, PAGINATION.Boards).pipe(
        map((response) => new SearchBoardsSuccess(response)),
        catchError((error) => of(new SearchBoardsFail(error)))
      )
    )
  );

  /* ========================= SEARCH_BOARDS_IN_POS =================================== */
  @Effect()
  searchInPos$ = this.actions$.pipe(
    ofType(BoardsActionType.SEARCH_BOARDS_IN_POS),
    debounceTime(300),
    switchMap(({ payload }: SearchBoardsInPos) =>
      this.boardsService.searchInPos(payload?.description ?? '', payload.page, PAGINATION.Boards).pipe(
        map((response) => new SearchBoardsInPosSuccess(response)),
        catchError((error) => of(new SearchBoardsInPosFail(error)))
      )
    )
  );

  /* ========================= FIND_BOARD =================================== */
  @Effect()
  find$ = this.actions$.pipe(
    ofType(BoardsActionType.FIND_BOARD),
    switchMap((action: FindBoard) =>
      this.boardsService.findById(action.payload).pipe(
        map((response) => new FindBoardSuccess(response)),
        catchError((error) => of(new FindBoardFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  findFail$ = this.actions$.pipe(
    ofType(BoardsActionType.FIND_BOARD_FAIL),
    tap((action: FindBoardFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(this.translationService.translate('SALES.BOARDS.BOARD_NOT_FOUND'));
      }
    })
  );

  /* ========================= CREATE_BOARD =================================== */
  @Effect()
  create$ = this.actions$.pipe(
    ofType(BoardsActionType.CREATE_BOARD),
    switchMap((action: CreateBoard) =>
      this.boardsService.create(action.payload).pipe(
        map((response) => new CreateBoardSuccess(response)),
        catchError((error) => of(new CreateBoardFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  createSuccess$ = this.actions$.pipe(
    ofType(BoardsActionType.CREATE_BOARD_SUCCESS),
    tap((action: CreateBoardSuccess) => {
      this.notificationService.success(this.translationService.translate('SALES.BOARDS.BOARD_ADDED'));
    })
  );

  @Effect({ dispatch: false })
  createFail$ = this.actions$.pipe(
    ofType(BoardsActionType.CREATE_BOARD_FAIL),
    tap((action: CreateBoardFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );

  /* ========================= UPDATE_BOARD =================================== */
  @Effect()
  update$ = this.actions$.pipe(
    ofType(BoardsActionType.UPDATE_BOARD),
    switchMap((action: UpdateBoard) =>
      this.boardsService.update(action.payload).pipe(
        map((response) => new UpdateBoardSuccess(response)),
        catchError((error) => of(new UpdateBoardFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  updateSuccess$ = this.actions$.pipe(
    ofType(BoardsActionType.UPDATE_BOARD_SUCCESS),
    tap((action: UpdateBoardSuccess) => {
      this.notificationService.success(this.translationService.translate('SALES.BOARDS.BOARD_UPDATED'));
    })
  );

  @Effect({ dispatch: false })
  updateFail$ = this.actions$.pipe(
    ofType(BoardsActionType.UPDATE_BOARD_FAIL),
    tap((action: UpdateBoardFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(this.translationService.translate('SALES.BOARDS.BOARD_NOT_FOUND'));
      } else if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );

  /* ========================= DELETE_BOARD =================================== */
  @Effect()
  delete$ = this.actions$.pipe(
    ofType(BoardsActionType.DELETE_BOARD),
    switchMap((action: DeleteBoard) =>
      this.boardsService.delete(action.payload).pipe(
        map((response) => new DeleteBoardSuccess(response)),
        catchError((error) => of(new DeleteBoardFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  deleteSuccess$ = this.actions$.pipe(
    ofType(BoardsActionType.DELETE_BOARD_SUCCESS),
    tap((action: DeleteBoardSuccess) => {
      this.notificationService.success(this.translationService.translate('SALES.BOARDS.BOARD_DELETED'));
    })
  );

  @Effect({ dispatch: false })
  deleteFail$ = this.actions$.pipe(
    ofType(BoardsActionType.DELETE_BOARD_FAIL),
    tap((action: DeleteBoardFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(this.translationService.translate('SALES.BOARDS.BOARD_NOT_FOUND'));
      } else if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );
}
