import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';

import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store, select } from '@ngrx/store';

import * as fromSalesStore from 'sales/store';
import { PageInfo, PaginationInfo } from 'shared';
import { Customer } from 'sales/models';
import { Claims } from 'security/models';

@Component({
  selector: 'app-customers-list',
  templateUrl: './customers-list.component.html',
  styles: [],
})
export class CustomersListComponent implements OnInit {
  /**
   * Gets or sets the information about the current page.
   */
  pageInfo: PageInfo = {
    title: 'SALES.CUSTOMERS.CUSTOMER_DATA.CUSTOMERS',
    icon: 'fa fa-user',
  };

  /**
   * The system supported user claims.
   */
  Claims = Claims;

  /**
   * The list of customers.
   */
  customers$: Observable<Customer[]>;

  /**
   * The pagination info.
   */
  paginationInfo$: Observable<PaginationInfo>;

  /**
   * The search form.
   */
  searchForm: FormGroup;

  /**
   * Gets or sets the currently selected customer for block, activate and delete.
   */
  selectedCustomer: Customer;

  /**
   * @param modalService The modal service.
   * @param salesStore$ The sales-module store.
   */
  constructor(private modalService: NgbModal, private salesStore$: Store<fromSalesStore.SalesState>) {}

  ngOnInit(): void {
    this.init();
  }

  /**
   * Initialize component data.
   */
  init() {
    this.initForm();

    /**
     * Load data.
     */
    let isManualSearchTriggeredBeforeForCustomers = false;
    this.customers$ = this.salesStore$.pipe(
      select(fromSalesStore.getCustomers),
      tap((customers) => {
        if (!isManualSearchTriggeredBeforeForCustomers && !customers.length) {
          isManualSearchTriggeredBeforeForCustomers = true;
          this.search();
        }
      })
    );
    this.paginationInfo$ = this.salesStore$.pipe(select(fromSalesStore.getCustomersPaginationInfo));
  }

  /**
   * Initialize form and add validators.
   */
  initForm() {
    this.searchForm = new FormGroup({
      name: new FormControl(''),
      page: new FormControl(1),
    });
  }

  /**
   * Handles search parameters change.
   */
  search(event?: KeyboardEvent) {
    if (event && event.key === 'Enter') {
      event.preventDefault();
      return;
    }

    this.salesStore$.dispatch(
      new fromSalesStore.SearchCustomers({
        name: this.searchForm.get('name').value,
        page: 1,
      })
    );
  }

  /**
   * Handles pagination page-changed event.
   * @param page The current selected page number.
   */
  pageChanged(page: number) {
    /** Update pagination page. */
    this.searchForm.patchValue({ page });

    /** Get selected page items. */
    this.salesStore$.dispatch(
      new fromSalesStore.SearchCustomers({
        name: this.searchForm.get('name').value,
        page: this.searchForm.get('page').value,
      })
    );
  }

  /**
   * Enables trackBy feature for *ngFor utility to track items
   * depending on a comparer to reduce DOM change.
   * If the item is already exist then no HTML will change, only bounded values.
   * @param index The index of the item.
   * @param customer The item to determine if it was changed or not.
   */
  trackItems(index: number, customer: Customer) {
    return customer ? customer.id : undefined;
  }

  /**
   * Blocks the customer with the given id.
   * @param customer The id of the customer to be blocked.
   */
  block(id: number) {
    this.salesStore$.dispatch(new fromSalesStore.BlockCustomer(id));
  }

  /**
   * Activates the customer with the given id.
   * @param customer The id of the customer to be activated.
   */
  activate(id: number) {
    this.salesStore$.dispatch(new fromSalesStore.ActivateCustomer(id));
  }

  /**
   * Deletes the customer with the given id.
   * @param customer The id of the customer to be deleted.
   */
  delete(id: number) {
    this.salesStore$.dispatch(new fromSalesStore.DeleteCustomer(id));
  }

  /**
   * Opens the modal of the given templateRef.
   * @param modalRef The modal templateRef to be opened.
   */
  openModal(modalRef) {
    this.modalService.open(modalRef);
  }

  /**
   * Extract the contacts from the given customer object.
   * @param customer The customer object.
   */
  extractContacts(customer: Customer): string {
    let contacts = customer.representorMobile ?? '';

    contacts = customer.representorPhone
      ? contacts
        ? `${contacts}\n${customer.representorPhone}`
        : customer.representorPhone
      : contacts;

    contacts = customer.representorEmail
      ? contacts
        ? `${contacts}\n${customer.representorEmail}`
        : customer.representorEmail
      : contacts;

    return contacts;
  }
}
