import { Component, ViewChild, ElementRef, Input, Output, EventEmitter } from '@angular/core';

import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

import { PayrollProjectCost, PayrollProvision, PayrollGovService, PayrollUnpaidLeave } from 'hr/models';

@Component({
  selector: 'app-payroll-projects-costs-info',
  templateUrl: './payroll-projects-costs-info.component.html',
  styles: [],
})
export class PayrollProjectsCostsInfoComponent {
  /**
   * The modal template reference.
   */
  @ViewChild('modalRef') modalRef: ElementRef<any>;

  /**
   * The list of projects-costs.
   */
  @Input() payrollProjectCosts: PayrollProjectCost[];

  /**
   * Gets the list of selected payroll project provisions for current projects-costs.
   */
  selectedPayrollProjectProvisions: PayrollProvision[];

  /**
   * Gets the list of selected payroll project unpaid leave for current projects-costs.
   */
  selectedPayrollProjectUnpaidLeaves: PayrollUnpaidLeave[];

  /**
   * Gets the list of selected payroll project gov services for current projects-costs.
   */
  selectedPayrollProjectGovServices: PayrollGovService[];

  /**
   * open the list of payroll project provisions.
   */
  payrollProjectProvisionsListVisibility = false;

  /**
   * open the list of payroll project unpaid leaves.
   */
  payrollProjectUnpaidLeavesListVisibility = false;

  /**
   * open the list of payroll project gov services.
   */
  payrollProjectGovServicesListVisibility = false;

  /**
   * Sets the visibility of this component modal.
   * Handles visibility change in two-data-binding approach.
   */
  @Input() set visible(isShown: boolean) {
    if (isShown && this.modalRef) {
      this.openModal(this.modalRef);
    } else {
      this.closeModal();
    }

    this.visibleChange.emit(isShown);
  }

  /**
   * Outputs a value that indicates whether the modal is shown or not.
   *
   * Handles visibility change in two-data-binding approach.
   */
  @Output() visibleChange = new EventEmitter<boolean>();

  /**
   * The ngb-modal reference to use it when modal needed to be closed.
   */
  modal: NgbModalRef;

  /**
   * @param modalService The modal service.
   */
  constructor(private modalService: NgbModal) {}

  /**
   * Initialize component data.
   */
  init() {}

  /**
   * Enables trackBy feature for *ngFor utility to track items
   * depending on a comparer to reduce DOM change.
   * If the item is already exist then no HTML will change, only bounded values.
   * @param index The index of the item.
   * @param payrollProjectCost The item to determine if it was changed or not.
   */
  trackItems(index: number, payrollProjectCost: PayrollProjectCost) {
    return payrollProjectCost ? payrollProjectCost.id : undefined;
  }

  /**
   * Opens the modal of the given templateRef.
   * @param modalRef The modal templateRef to be opened.
   */
  openModal(modalRef) {
    /**
     * Open the modal & listen for user press ESC key.
     */
    this.modal = this.modalService.open(modalRef, { size: 'xl' });
    this.modal.result.then(null, () => this.closeModal());
  }

  /**
   * Closes the currently opened modal.
   */
  closeModal() {
    this.modal?.dismiss();
    this.visibleChange.emit(false);
  }
}
