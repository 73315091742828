import { Injectable } from '@angular/core';

import { of } from 'rxjs';
import { switchMap, map, catchError, tap, debounceTime } from 'rxjs/operators';
import { Effect, ofType, Actions } from '@ngrx/effects';

import { NotificationService, StatusCode, PAGINATION, TranslationService } from 'shared';
import { CurrenciesService } from 'finances/services';
import {
  CurrenciesActionType,
  CreateCurrency,
  CreateCurrencyFail,
  CreateCurrencySuccess,
  DeleteCurrency,
  DeleteCurrencyFail,
  DeleteCurrencySuccess,
  FindCurrency,
  FindCurrencyFail,
  FindCurrencySuccess,
  FindPrimaryCurrency,
  FindPrimaryCurrencyFail,
  FindPrimaryCurrencySuccess,
  SearchCurrencies,
  SearchCurrenciesFail,
  SearchCurrenciesSuccess,
  UpdateCurrency,
  UpdateCurrencyFail,
  UpdateCurrencySuccess,
} from 'finances/store/actions';

@Injectable()
export class CurrenciesEffects {
  constructor(
    private actions$: Actions,
    private currenciesService: CurrenciesService,
    private notificationService: NotificationService,
    private translationService: TranslationService
  ) {}

  /* ========================= SEARCH_CURRENCIES =================================== */
  @Effect()
  search$ = this.actions$.pipe(
    ofType(CurrenciesActionType.SEARCH_CURRENCIES),
    debounceTime(300),
    switchMap(({ payload }: SearchCurrencies) =>
      this.currenciesService.search(payload?.name ?? '', payload.page, PAGINATION.Currencies).pipe(
        map((response) => new SearchCurrenciesSuccess(response)),
        catchError((error) => of(new SearchCurrenciesFail(error)))
      )
    )
  );

  /* ========================= FIND_CURRENCY =================================== */
  @Effect()
  find$ = this.actions$.pipe(
    ofType(CurrenciesActionType.FIND_CURRENCY),
    switchMap((action: FindCurrency) =>
      this.currenciesService.findById(action.payload).pipe(
        map((response) => new FindCurrencySuccess(response)),
        catchError((error) => of(new FindCurrencyFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  findFail$ = this.actions$.pipe(
    ofType(CurrenciesActionType.FIND_CURRENCY_FAIL),
    tap((action: FindCurrencyFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(this.translationService.translate('FINANCES.CURRENCIES.CURRENCY_NOT_FOUND'));
      }
    })
  );

  /* ========================= FIND_PRIMARY_CURRENCY =================================== */
  @Effect()
  findPrimaryCurrency$ = this.actions$.pipe(
    ofType(CurrenciesActionType.FIND_PRIMARY_CURRENCY),
    switchMap((action: FindPrimaryCurrency) =>
      this.currenciesService.findPrimaryCurrency().pipe(
        map((response) => new FindPrimaryCurrencySuccess(response)),
        catchError((error) => of(new FindPrimaryCurrencyFail(error)))
      )
    )
  );

  /* ========================= CREATE_CURRENCY =================================== */
  @Effect()
  create$ = this.actions$.pipe(
    ofType(CurrenciesActionType.CREATE_CURRENCY),
    switchMap((action: CreateCurrency) =>
      this.currenciesService.create(action.payload).pipe(
        map((response) => new CreateCurrencySuccess(response)),
        catchError((error) => of(new CreateCurrencyFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  createSuccess$ = this.actions$.pipe(
    ofType(CurrenciesActionType.CREATE_CURRENCY_SUCCESS),
    tap((action: CreateCurrencySuccess) => {
      this.notificationService.success(this.translationService.translate('FINANCES.CURRENCIES.CURRENCY_ADDED'));
    })
  );

  @Effect({ dispatch: false })
  createFail$ = this.actions$.pipe(
    ofType(CurrenciesActionType.CREATE_CURRENCY_FAIL),
    tap((action: CreateCurrencyFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );

  /* ========================= UPDATE_CURRENCY =================================== */
  @Effect()
  update$ = this.actions$.pipe(
    ofType(CurrenciesActionType.UPDATE_CURRENCY),
    switchMap((action: UpdateCurrency) =>
      this.currenciesService.update(action.payload).pipe(
        map((response) => new UpdateCurrencySuccess(response)),
        catchError((error) => of(new UpdateCurrencyFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  updateSuccess$ = this.actions$.pipe(
    ofType(CurrenciesActionType.UPDATE_CURRENCY_SUCCESS),
    tap((action: UpdateCurrencySuccess) => {
      this.notificationService.success(this.translationService.translate('FINANCES.CURRENCIES.CURRENCY_UPDATED'));
    })
  );

  @Effect({ dispatch: false })
  updateFail$ = this.actions$.pipe(
    ofType(CurrenciesActionType.UPDATE_CURRENCY_FAIL),
    tap((action: UpdateCurrencyFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(this.translationService.translate('FINANCES.CURRENCIES.CURRENCY_NOT_FOUND'));
      } else if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );

  /* ========================= DELETE_CURRENCY =================================== */
  @Effect()
  delete$ = this.actions$.pipe(
    ofType(CurrenciesActionType.DELETE_CURRENCY),
    switchMap((action: DeleteCurrency) =>
      this.currenciesService.delete(action.payload).pipe(
        map((response) => new DeleteCurrencySuccess(response)),
        catchError((error) => of(new DeleteCurrencyFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  deleteSuccess$ = this.actions$.pipe(
    ofType(CurrenciesActionType.DELETE_CURRENCY_SUCCESS),
    tap((action: DeleteCurrencySuccess) => {
      this.notificationService.success(this.translationService.translate('FINANCES.CURRENCIES.CURRENCY_DELETED'));
    })
  );

  @Effect({ dispatch: false })
  deleteFail$ = this.actions$.pipe(
    ofType(CurrenciesActionType.DELETE_CURRENCY_FAIL),
    tap((action: DeleteCurrencyFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(this.translationService.translate('FINANCES.CURRENCIES.CURRENCY_NOT_FOUND'));
      } else if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );
}
