<!-- revenue details starts -->
<div class="row">
  <div class="col-md-12">
    <div class="card-header mb-3 pt-0">
      <h4 class="card-title">{{ 'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.REVENUE_DETAILS' | translate }}</h4>
    </div>
    <div class="card-content">
      <div class="px-3">
        <div class="row">
          <div class="form-group col-md-6">
            <label for="saleInvoiceId">{{
              'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.TRANSACTION_NUM' | translate
            }}</label>
            <input
              type="text"
              id="saleInvoiceId"
              class="form-control round"
              [value]="revenue.saleInvoice ? revenue.saleInvoice.invoiceNum : revenue.saleReturn.saleReturnNum"
              readonly
            />
          </div>
          <div class="form-group col-md-6">
            <label for="projectRevenueType">{{
              'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.PROJECT_REVENUE_TYPE' | translate
            }}</label>
            <input
              type="text"
              class="form-control round"
              id="projectRevenueType"
              [value]="revenue.projectRevenueType.name | localize: revenue.projectRevenueType.nameEn | async"
              readonly
            />
          </div>
          <div class="form-group col-md-6">
            <label for="value">{{ 'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.VALUE_REVENUE' | translate }}</label>
            <input type="text" id="value" class="form-control round" [value]="revenue.value | toDecimal" readonly />
          </div>
          <div class="form-group col-md-6">
            <label for="revenue.createdAt">{{
              'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.CREATED_AT' | translate
            }}</label>
            <input
              type="text"
              class="form-control round"
              id="revenue.createdAt"
              [value]="revenue.createdAt | date"
              readonly
            />
          </div>
          <div class="form-group col-md-12">
            <label for="revenue.user.name">{{ 'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.USER' | translate }}</label>
            <input id="revenue.user.name" type="text" class="form-control round" [value]="revenue.user.name" readonly />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- revenue details ends -->
