import { createSelector } from '@ngrx/store';

import { SalesState, ShiftsState } from 'sales/store/states';
import { selectSalesState } from 'sales/store/selectors/feature.selectors';

/**
 * Gets the shifts state.
 */
const selectShiftsState = createSelector(selectSalesState, (state: SalesState) => state.shifts);

/**
 * Gets the list of my loaded shifts.
 */
export const getMyShifts = createSelector(selectShiftsState, (state: ShiftsState) => state.data);

/**
 * Gets the list of loaded shifts.
 */
export const getShifts = createSelector(selectShiftsState, (state: ShiftsState) => state.data);

/**
 * Gets the my shifts pagination info.
 */
export const getMyShiftsPaginationInfo = createSelector(
  selectShiftsState,
  (state: ShiftsState) => state.paginationInfo
);

/**
 * Gets the error that occurred in the state.
 */
export const getMyShiftsError = createSelector(selectShiftsState, (state: ShiftsState) => state.error);

/**
 * Determines if there is a running search process.
 */
export const getMyShiftsSearching = createSelector(selectShiftsState, (state: ShiftsState) => state.isSearching);

/**
 * Determines if the last search process has been ended successfully.
 */
export const getMyShiftsSearchCompleted = createSelector(
  selectShiftsState,
  (state: ShiftsState) => state.isSearchCompleted
);

/**
 * Determines if there is a running search all shifts process.
 */
export const getAllShiftsSearching = createSelector(selectShiftsState, (state: ShiftsState) => state.isAllShiftsSearching);

/**
 * Determines if the last search all shifts process has been ended successfully.
 */
export const getAllShiftsSearchCompleted = createSelector(
  selectShiftsState,
  (state: ShiftsState) => state.isAllShiftsSearchCompleted
);

/**
 * Gets the selected shift.
 */
export const getMySelectedShift = createSelector(selectShiftsState, (state: ShiftsState) => state.selectedShift);

/**
 * Determines if there is a running find-shift process.
 */
export const getMyShiftsFinding = createSelector(selectShiftsState, (state: ShiftsState) => state.isFinding);

/**
 * Determines if the last find-shift process has been ended successfully.
 */
export const getMyShiftsFindCompleted = createSelector(
  selectShiftsState,
  (state: ShiftsState) => state.isFindCompleted
);

/**
 * Determines if there is a running start process.
 */
export const getMyShiftStarting = createSelector(selectShiftsState, (state: ShiftsState) => state.isStarting);

/**
 * Determines if the last start process has been ended successfully.
 */
export const getMyShiftStartCompleted = createSelector(
  selectShiftsState,
  (state: ShiftsState) => state.isStartCompleted
);

/**
 * Determines if there is a running start shift for casher process.
 */
export const getShiftForCasherStarting = createSelector(selectShiftsState, (state: ShiftsState) => state.isShiftForCasherStarting);

/**
 * Determines if the last start shift for casher process has been ended successfully.
 */
export const getShiftForCasherStartCompleted = createSelector(
  selectShiftsState,
  (state: ShiftsState) => state.isShiftForCasherStartCompleted
);

/**
 * Determines if there is a running cancel process.
 */
export const getMySelectedShiftCanceling = createSelector(selectShiftsState, (state: ShiftsState) => state.isCanceling);

/**
 * Determines if the last cancel process has been ended successfully.
 */
export const getMySelectedShiftCancelCompleted = createSelector(
  selectShiftsState,
  (state: ShiftsState) => state.isCancelCompleted
);

/**
 * Determines if there is a running cancel shift process.
 */
export const getSelectedShiftCanceling = createSelector(selectShiftsState, (state: ShiftsState) => state.isShiftCanceling);

/**
 * Determines if the last cancel shift process has been ended successfully.
 */
export const getSelectedShiftCancelCompleted = createSelector(
  selectShiftsState,
  (state: ShiftsState) => state.isShiftCancelCompleted
);
/**
 * Determines if there is a running delete shift process.
 */
export const getSelectedShiftDeleting = createSelector(selectShiftsState, (state: ShiftsState) => state.isShiftDeleting);

/**
 * Determines if the last delete shift process has been ended successfully.
 */
export const getSelectedShiftDeleteCompleted = createSelector(
  selectShiftsState,
  (state: ShiftsState) => state.isShiftDeleteCompleted
);
