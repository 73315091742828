import { Action } from '@ngrx/store';

import { IncomingStockType } from 'lookups/models';
import { AppHttpResponse } from 'shared';

/**
 * The Lookups-Module store incoming stock types action types.
 */
export enum IncomingStockTypeActionType {
  GET_ALL_INCOMING_STOCK_TYPES = '[Lookups] [IncomingStockType] Get All Incoming Stock Types',
  GET_ALL_INCOMING_STOCK_TYPES_FAIL = '[Lookups] [IncomingStockType] Get All Incoming Stock Types Fail',
  GET_ALL_INCOMING_STOCK_TYPES_SUCCESS = '[Lookups] [IncomingStockType] Get All Incoming Stock Types Success',
}

/**
 * Represents a store incoming stock type action.
 */
export class GetAllIncomingStockTypes implements Action {
  /**
   * The type of the action.
   */
  readonly type = IncomingStockTypeActionType.GET_ALL_INCOMING_STOCK_TYPES;

  /**
   * Fires a new incoming stock type action.
   */
  constructor() {}
}

/**
 * Represents a store incoming stock type fail action.
 */

export class GetAllIncomingStockTypesFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = IncomingStockTypeActionType.GET_ALL_INCOMING_STOCK_TYPES_FAIL;

  /**
   * Fires a new incoming stock type fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store incoming stock type success action.
 */
export class GetAllIncomingStockTypesSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = IncomingStockTypeActionType.GET_ALL_INCOMING_STOCK_TYPES_SUCCESS;

  /**
   * Fires a incoming stock type success action.
   * @param payload An object contains the list of incoming stock types.
   */
  constructor(public payload: AppHttpResponse<IncomingStockType[]>) {}
}

/**
 * Lookups-module incoming stock type action types.
 */
export type IncomingStockTypeActions =
  | GetAllIncomingStockTypes
  | GetAllIncomingStockTypesFail
  | GetAllIncomingStockTypesSuccess;
