<!-- page header starts -->
<app-page-header [info]="pageInfo"></app-page-header>
<!-- page header ends -->

<!-- create petty cash refund form starts -->
<div class="card">
  <div class="card-content">
    <div class="px-3 pt-3">
      <form (ngSubmit)="submit()">
        <div class="row">
          <div class="col-md-12">
            <ul
              class="nav-tabs"
              ngbNav
              #nav="ngbNav"
              [(activeId)]="activePage"
              (activeIdChange)="selectedPageChanged($event)"
              [destroyOnHide]="false"
            >
              <li ngbNavItem="details">
                <a ngbNavLink>
                  <i class="fa fa-file-text" [class.success]="activePage == 'details'"></i>
                  {{ 'FINANCES.PETTY_CASH_REFUND.PETTY_CASH_REFUND_DETAILS' | translate }}
                </a>

                <!-- create petty cash refund starts -->
                <ng-template ngbNavContent>
                  <div class="row">
                    <div class="col-md-12">
                      <form [formGroup]="form">
                        <div class="card">
                          <div class="card-header mb-3">
                            <h4 class="card-title">
                              {{ 'FINANCES.PETTY_CASH_REFUND.PETTY_REFUND_DATA_TITLE' | translate }}
                            </h4>
                          </div>
                          <!-- petty cash refund details starts -->
                          <div class="card-content">
                            <div class="px-3">
                              <div class="row">
                                <div class="form-group col-md-6">
                                  <label for="pettyCashId">{{
                                    'FINANCES.PETTY_CASH_REFUND.PETTY_CASH_REFUND_DATA.PETTY_CASH' | translate
                                  }}</label>
                                  <ng-select
                                    class="round"
                                    formControlName="pettyCashId"
                                    labelForId="pettyCashId"
                                    searchable="true"
                                    [placeholder]="
                                      'FINANCES.PETTY_CASH_REFUND.PETTY_CASH_REFUND_DATA.PETTY_CASH_SEARCH_PLACEHOLDER'
                                        | translate
                                    "
                                    [notFoundText]="'SHARED.SEARCH.SEARCH_NOT_FOUND' | translate"
                                    (open)="pettyCashListVisibility = true"
                                  >
                                    <ng-option *ngFor="let petty of pettyCash" [value]="petty.id">
                                      {{ petty.description }}
                                    </ng-option>
                                  </ng-select>
                                </div>
                                <div class="form-group col-md-6">
                                  <label for="paymentType">{{
                                    'FINANCES.PETTY_CASH_REFUND.PETTY_CASH_REFUND_DATA.PAYMENT_TYPE' | translate
                                  }}</label>
                                  <ng-select
                                    class="round"
                                    formControlName="paymentType"
                                    labelForId="paymentType"
                                    searchable="true"
                                    [placeholder]="
                                      'FINANCES.PETTY_CASH_REFUND.PETTY_CASH_REFUND_DATA.PAYMENT_TYPE_PLACEHOLDER'
                                        | translate
                                    "
                                    [notFoundText]="'SHARED.SEARCH.SEARCH_NOT_FOUND' | translate"
                                  >
                                    <ng-option *ngFor="let paymentType of paymentTypes" [value]="paymentType.key">
                                      {{ paymentType.name | localize: paymentType.nameEn | async }}
                                    </ng-option>
                                  </ng-select>
                                </div>
                                <div class="form-group col-md-6" *ngIf="this.form?.value?.paymentType">
                                  <label *ngIf="this.form?.value.paymentType == 'CASH'" for="bankAccountId">{{
                                    'FINANCES.PETTY_CASH_REFUND.PETTY_CASH_REFUND_DATA.CASH_ACCOUNT' | translate
                                  }}</label>
                                  <label *ngIf="this.form?.value.paymentType == 'BANK'" for="bankAccountId">{{
                                    'FINANCES.PETTY_CASH_REFUND.PETTY_CASH_REFUND_DATA.BANK_ACCOUNT' | translate
                                  }}</label>
                                  <ng-select
                                    class="round"
                                    formControlName="bankAccountId"
                                    labelForId="bankAccountId"
                                    searchable="true"
                                    [placeholder]="
                                      'FINANCES.PETTY_CASH_REFUND.PETTY_CASH_REFUND_DATA.BANK_ACCOUNT_PLACEHOLDER'
                                        | translate
                                    "
                                    [notFoundText]="'SHARED.SEARCH.SEARCH_NOT_FOUND' | translate"
                                    (open)="bankAccountsListVisibility = true"
                                  >
                                    <ng-option *ngFor="let bankAccount of bankAccounts" [value]="bankAccount.id">
                                      {{ bankAccount.bank.name | localize: bankAccount.bank.nameEn | async }} -
                                      {{ bankAccount.name | localize: bankAccount.nameEn | async }}
                                    </ng-option>
                                  </ng-select>
                                </div>
                                <div class="form-group col-md-6">
                                  <label for="value">{{
                                    'FINANCES.PETTY_CASH_REFUND.PETTY_CASH_REFUND_DATA.VALUE' | translate
                                  }}</label>
                                  <input
                                    type="text"
                                    thousandSeparator=","
                                    [mask]="DECIMAL_MASK"
                                    minLength="0"
                                    id="value"
                                    class="form-control round"
                                    [placeholder]="
                                      'FINANCES.PETTY_CASH_REFUND.PETTY_CASH_REFUND_DATA.VALUE_PLACEHOLDER' | translate
                                    "
                                    formControlName="value"
                                  />
                                </div>
                                <div class="form-group col-md-6" *ngIf="form.controls.paymentType.value === 'BANK'">
                                  <label for="bankFee">{{
                                    'FINANCES.BANK_ACCOUNTS.BANK_ACCOUNT_DATA.BANK_FEE' | translate
                                  }}</label>
                                  <input
                                    type="text"
                                    thousandSeparator=","
                                    minLength="0"
                                    id="bankFee"
                                    [mask]="DECIMAL_MASK"
                                    class="form-control round"
                                    [placeholder]="
                                      'FINANCES.BANK_ACCOUNTS.BANK_ACCOUNT_DATA.BANK_FEE_PLACEHOLDER' | translate
                                    "
                                    formControlName="bankFee"
                                  />
                                </div>
                                <div class="form-group col-md-6" *ngIf="form.controls.paymentType.value === 'BANK'">
                                  <label for="bankFeeTaxValue">{{
                                    'FINANCES.BANK_ACCOUNTS.BANK_ACCOUNT_DATA.BANK_FEE_TAX_VALUE' | translate
                                  }}</label>
                                  <input
                                    type="text"
                                    thousandSeparator=","
                                    minLength="0"
                                    [mask]="DECIMAL_MASK"
                                    id="bankFeeTaxValue"
                                    class="form-control round"
                                    [placeholder]="
                                      'FINANCES.BANK_ACCOUNTS.BANK_ACCOUNT_DATA.BANK_FEE_TAX_VALUE_PLACEHOLDER'
                                        | translate
                                    "
                                    formControlName="bankFeeTaxValue"
                                  />
                                </div>
                                <div
                                  class="form-group"
                                  [ngClass]="this.form?.value?.paymentType ? 'col-md-12' : 'col-md-6'"
                                >
                                  <label for="transactionDate">{{
                                    'FINANCES.PETTY_CASH_REFUND.PETTY_CASH_REFUND_DATA.PETTY_CASH_REFUND_DATE'
                                      | translate
                                  }}</label>
                                  <div class="input-group round">
                                    <input
                                      class="form-control"
                                      id="transactionDate"
                                      name="transactionDate"
                                      formControlName="transactionDate"
                                      [placeholder]="'SHARED.SEARCH.DATE_PLACEHOLDER' | translate"
                                      ngbDatepicker
                                      #transactionDate="ngbDatepicker"
                                    />
                                    <div class="input-group-append">
                                      <span
                                        class="input-group-text gradient-light-blue-indigo"
                                        style="cursor: pointer;"
                                        (click)="transactionDate.toggle()"
                                      >
                                        <i class="fa fa-calendar"></i>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <div class="form-group col-md-12">
                                  <label for="notes">{{
                                    'FINANCES.PETTY_CASH_REFUND.PETTY_CASH_REFUND_DATA.NOTES' | translate
                                  }}</label>
                                  <textarea
                                    id="notes"
                                    class="form-control round"
                                    [placeholder]="
                                      'FINANCES.PETTY_CASH_REFUND.PETTY_CASH_REFUND_DATA.NOTES_PLACEHOLDER' | translate
                                    "
                                    minlength="0"
                                    maxlength="200"
                                    formControlName="notes"
                                  ></textarea>
                                </div>
                              </div>
                            </div>
                          </div>
                          <!-- petty cash refund details ends -->
                        </div>
                      </form>
                    </div>
                  </div>
                </ng-template>
                <!-- create petty cash refund ends -->
              </li>
              <li ngbNavItem="journal">
                <a ngbNavLink>
                  <i class="fa fa-calendar" [class.success]="activePage == 'journal'"></i>
                  {{ 'SHARED.JOURNAL_DATA.JOURNAL' | translate }}
                </a>

                <!-- create journal form starts -->
                <ng-template ngbNavContent>
                  <div class="row">
                    <div class="col-md-12">
                      <form [formGroup]="form">
                        <div class="card">
                          <!-- journal starts -->
                          <!-- journal lines details starts -->
                          <div class="card-header mb-3">
                            <h4 class="card-title">{{ 'SHARED.JOURNAL_DATA.JOURNAL_LINES' | translate }}</h4>
                          </div>
                          <div class="card-content">
                            <div class="px-3">
                              <app-journal-line-form
                                [journalLinesForm]="journalLinesForm"
                                [isNotesRequired]="false"
                                [allowAddLine]="false"
                                [allowClearLines]="false"
                                [allowRemoveLine]="false"
                                [initialJournalLines]="initialJournalLinesSubject.asObservable() | async"
                              ></app-journal-line-form>
                            </div>
                          </div>
                          <!-- journal ends -->
                        </div>
                      </form>
                    </div>
                  </div>
                </ng-template>
                <!-- create journal form ends -->
              </li>
            </ul>
            <div [ngbNavOutlet]="nav" class="mt-2"></div>
          </div>
        </div>

        <div class="row mt-2">
          <div class="form-group col-12 d-flex justify-content-between flex-column flex-sm-row mt-2">
            <button type="submit" class="btn btn-raised btn-success round" [disabled]="isCreating$ | async">
              <i class="fa fa-check"></i>
              <span class="mx-1">{{ 'SHARED.CONFIRM.SAVE' | translate }}</span>
            </button>
            <a class="btn btn-dark round" routerLink="/finances/petty-cash-refunds">
              <i class="fa fa-undo"></i>
              <span class="mx-1">{{
                'FINANCES.PETTY_CASH_REFUND.PETTY_CASH_REFUND_DATA.PETTY_CASH_REFUND_RETURN' | translate
              }}</span>
            </a>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
<!-- create petty cash refund form ends -->

<!-- confirm modal starts -->
<ng-template #confirmModalRef let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
      {{ 'FINANCES.PETTY_CASH_REFUND.CONFIRM_CREATE_PETTY_CASH_REFUND_TITLE' | translate }}
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="d()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p class="text-success">{{ 'FINANCES.PETTY_CASH_REFUND.CONFIRM_CREATE_PETTY_CASH_REFUND_MESSAGE' | translate }}</p>
  </div>
  <div class="modal-footer">
    <div class="modal-actions">
      <button type="button" class="btn btn-raised btn-success round" (click)="c(); confirm()" ngbAutofocus>
        <i class="fa fa-check"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.SAVE' | translate }}</span>
      </button>
      <button type="button" class="btn btn-outline-dark round" (click)="c()">
        <i class="fa fa-times"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.CANCEL' | translate }}</span>
      </button>
    </div>
  </div>
</ng-template>
<!-- confirm modal ends -->

<!-- petty cash search starts -->
<app-petty-cash-search
  [(visible)]="pettyCashListVisibility"
  [closeOnSelect]="true"
  (pettyCash)="selectPettyCash($event)"
>
</app-petty-cash-search>
<!-- petty cash search ends -->

<!-- bank accounts search starts -->
<app-bank-accounts-search
  onlyActive="true"
  closeOnSelect="true"
  (bankAccounts)="selectBankAccount($event)"
  [(visible)]="bankAccountsListVisibility"
>
</app-bank-accounts-search>
<!-- bank accounts search ends -->
