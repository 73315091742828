import { Action } from '@ngrx/store';

import { AppHttpResponse } from 'shared';
import { Employee } from 'hr/models';

/**
 * The AppSearch-Module store employees action types.
 */
export enum EmployeesActionType {
  SEARCH_EMPLOYEES = '[AppSearch] [Employees] Search Employees',
  SEARCH_EMPLOYEES_FAIL = '[AppSearch] [Employees] Search Employees Fail',
  SEARCH_EMPLOYEES_SUCCESS = '[AppSearch] [Employees] Search Employees Success',

  SEARCH_SALES_PERSONS = '[AppSearch] [Employees] Search Sales Persons',
  SEARCH_SALES_PERSONS_FAIL = '[AppSearch] [Employees] Search Sales Persons Fail',
  SEARCH_SALES_PERSONS_SUCCESS = '[AppSearch] [Employees] Search Sales Persons Success',
}

/**
 * Represents a store employees search action.
 */
export class SearchEmployees implements Action {
  /**
   * The type of the action.
   */
  readonly type = EmployeesActionType.SEARCH_EMPLOYEES;

  /**
   * Fires a new employees search action.
   * @param payload the search parameters,
   * if omitted, all employees will be loaded.
   */
  constructor(public payload?: { name: string; locations: number[]; page: number }) {}
}

/**
 * Represents a store employees search-fail action.
 */
export class SearchEmployeesFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = EmployeesActionType.SEARCH_EMPLOYEES_FAIL;

  /**
   * Fires a new employees search-fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store search-success action.
 */
export class SearchEmployeesSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = EmployeesActionType.SEARCH_EMPLOYEES_SUCCESS;

  /**
   * Fires a new employees search-success action.
   * @param payload An object contains the list of employees that matches the search criteria
   * in addition to the pagination info.
   */
  constructor(public payload: AppHttpResponse<Employee[]>) {}
}

/**
 * Represents a store sales persons search action.
 */
export class SearchSalesPersons implements Action {
  /**
   * The type of the action.
   */
  readonly type = EmployeesActionType.SEARCH_SALES_PERSONS;

  /**
   * Fires a new sales persons search action.
   * @param payload the search parameters,
   * if omitted, all sales persons will be loaded.
   */
  constructor(public payload?: { name: string; locations: number[]; page: number }) {}
}

/**
 * Represents a store sales persons search-fail action.
 */
export class SearchSalesPersonsFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = EmployeesActionType.SEARCH_SALES_PERSONS_FAIL;

  /**
   * Fires a new sales persons search-fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store search-success action.
 */
export class SearchSalesPersonsSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = EmployeesActionType.SEARCH_SALES_PERSONS_SUCCESS;

  /**
   * Fires a new sales persons search-success action.
   * @param payload An object contains the list of sales persons that matches the search criteria
   * in addition to the pagination info.
   */
  constructor(public payload: AppHttpResponse<Employee[]>) {}
}

/**
 * hr-module employees action types.
 */
export type EmployeesActions =
  | SearchEmployees
  | SearchEmployeesFail
  | SearchEmployeesSuccess
  | SearchSalesPersons
  | SearchSalesPersonsFail
  | SearchSalesPersonsSuccess;
