import { createSelector } from '@ngrx/store';

import { selectLookupsState } from 'lookups/store/selectors/feature.selectors';
import { LookupsState, GenderState } from 'lookups/store/states';

/**
 * Gets the gender state.
 */
const selectGenders = createSelector(selectLookupsState, (state: LookupsState) => state.genders);

/**
 * Gets the list of loaded genders.
 */
export const getGenders = createSelector(selectGenders, (state: GenderState) => state.data);

/**
 * Gets the error that occurred in the state.
 */
export const getGendersError = createSelector(selectGenders, (state: GenderState) => state.error);

/**
 * Determines if there is a running load process.
 */
export const getGendersIsLoading = createSelector(selectGenders, (state: GenderState) => state.isLoading);

/**
 * Determines if the last load process has been ended successfully.
 */
export const getGendersIsLoaded = createSelector(selectGenders, (state: GenderState) => state.isLoaded);
