import { CreateRegularSaleInvoiceComponent } from 'sales/components/invoices/create-regular-sale-invoice/create-regular-sale-invoice.component';
import { CreateOpeningBalanceInvoiceComponent } from 'sales/components/invoices/create-opening-balance-invoice/create-opening-balance-invoice.component';
import { SaleInvoiceDetailsComponent } from 'sales/components/invoices/sale-invoice-details/sale-invoice-details.component';
import { SaleInvoicesListComponent } from 'sales/components/invoices/sale-invoices-list/sale-invoices-list.component';
import { SaleInvoicesSearchComponent } from 'sales/components/invoices/sale-invoices-search/sale-invoices-search.component';
import { UnpaidSaleInvoicesSearchComponent } from 'sales/components/invoices/unpaid-sale-invoices-search/unpaid-sale-invoices-search.component';
import { SaleInvoicePaymentHistoryComponent } from 'sales/components/invoices/sale-invoice-payment-history/sale-invoice-payment-history.component';
import { CreatePointOfSaleInvoiceComponent } from 'sales/components/invoices/point-of-sale-invoice/create-point-of-sale-invoice/create-point-of-sale-invoice.component';
import { PointOfSaleInvoiceProductsSearchComponent } from 'sales/components/invoices/point-of-sale-invoice/point-of-sale-invoice-products-search/point-of-sale-invoice-products-search.component';
import { PointOfSaleInvoiceProductsFormComponent } from 'sales/components/invoices/point-of-sale-invoice/point-of-sale-invoice-products-form/point-of-sale-invoice-products-form.component';
import { CreateMaintenanceInvoiceComponent } from 'sales/components/invoices/create-maintenance-invoice/create-maintenance-invoice.component';
import { PointOFSaleInvoiceSubProductsFormComponent } from 'sales/components/invoices/point-of-sale-invoice/point-of-sale-invoice-sub-products-form/point-of-sale-invoice-sub-products-form.component';

export * from 'sales/components/invoices/create-regular-sale-invoice/create-regular-sale-invoice.component';
export * from 'sales/components/invoices/create-opening-balance-invoice/create-opening-balance-invoice.component';
export * from 'sales/components/invoices/sale-invoice-details/sale-invoice-details.component';
export * from 'sales/components/invoices/sale-invoices-list/sale-invoices-list.component';
export * from 'sales/components/invoices/sale-invoices-search/sale-invoices-search.component';
export * from 'sales/components/invoices/unpaid-sale-invoices-search/unpaid-sale-invoices-search.component';
export * from 'sales/components/invoices/sale-invoice-payment-history/sale-invoice-payment-history.component';
export * from 'sales/components/invoices/point-of-sale-invoice/create-point-of-sale-invoice/create-point-of-sale-invoice.component';
export * from 'sales/components/invoices/point-of-sale-invoice/point-of-sale-invoice-products-search/point-of-sale-invoice-products-search.component';
export * from 'sales/components/invoices/point-of-sale-invoice/point-of-sale-invoice-products-form/point-of-sale-invoice-products-form.component';
export * from 'sales/components/invoices/create-maintenance-invoice/create-maintenance-invoice.component';
export * from 'sales/components/invoices/point-of-sale-invoice/point-of-sale-invoice-sub-products-form/point-of-sale-invoice-sub-products-form.component';

/**
 * The set of components registered on this sub-module.
 */
export const COMPONENTS: any[] = [
  CreateRegularSaleInvoiceComponent,
  CreateOpeningBalanceInvoiceComponent,
  SaleInvoiceDetailsComponent,
  SaleInvoicesSearchComponent,
  SaleInvoicesListComponent,
  UnpaidSaleInvoicesSearchComponent,
  SaleInvoicePaymentHistoryComponent,
  CreatePointOfSaleInvoiceComponent,
  PointOfSaleInvoiceProductsSearchComponent,
  PointOfSaleInvoiceProductsFormComponent,
  CreateMaintenanceInvoiceComponent,
  PointOFSaleInvoiceSubProductsFormComponent,
];
