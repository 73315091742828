import { Component, ViewChild, ElementRef, Input, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store, select } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';

import * as fromSalesStore from 'sales/store';
import * as fromLookupsStore from 'lookups/store';
import { NotificationService, NotificationMessage, CustomValidators, TranslationService, APP_CONSTANTS } from 'shared';
import { Customer } from 'sales/models';
import { Location } from 'stores/models';
import { City, CustomerCategories, CustomerCategory } from 'lookups/models';

@Component({
  selector: 'app-customer-info',
  templateUrl: './customer-info.component.html',
  styles: [],
})
export class CustomerInfoComponent implements OnInit, OnDestroy {
  /**
   * The confirm modal template reference.
   */
  @ViewChild('confirmModalRef') confirmModalRef: ElementRef<any>;

  /**
   * Sets the currently edited customer.
   */
  @Input() set customer(value: Customer) {
    this.customerObj = value;

    /** First of all initialize form if it hasn't initialized yet. */
    if (!this.form) {
      this.initForm();
    }

    /** Update form data. */
    if (value) {
      this.locations = [value.location];
      this.form.patchValue({ ...value });
    } else {
      this.form.reset();
    }
  }

  /**
   * The list of active locations.
   */
  locations: Location[] = [];

  /**
   * Shows or hide the locations list.
   */
  locationsListVisibility = false;

  /**
   * Gets or sets the phone pattern.
   */
  public phonePattern = APP_CONSTANTS.phone.pattern;

  /**
   * Gets or sets the phone mask.
   */
  public phoneMask = APP_CONSTANTS.phone.mask;

  /**
   * Gets or sets the currently edited customer.
   */
  customerObj: Customer;

  /**
   * Gets the customer category organization id.
   */
  customerCategoryOrganizationId: number;

  /**
   * The create customer form.
   */
  form: FormGroup;

  /**
   * Indicates whether there is a update customer process is running or not.
   */
  isUpdating$: Observable<boolean>;

  /**
   * The list of customer Categories.
   */
  customerCategories$: Observable<CustomerCategory[]>;

  /**
   * The list of cities.
   */
  cities$: Observable<City[]>;

  /**
   * The set of subscriptions on this components,
   * these subscriptions must be unsubscribed before this component got destroyed.
   */
  subscriptions = new Subscription();

  /**
   * @param modalService The modal service.
   * @param notificationService The notification service.
   * @param translationService The translation service.
   * @param salesStore$ the sales-store module.
   * @param lookupsStore$ the lookups-store module.
   */
  constructor(
    private modalService: NgbModal,
    private notificationService: NotificationService,
    private translationService: TranslationService,
    private salesStore$: Store<fromSalesStore.SalesState>,
    private lookupsStore$: Store<fromLookupsStore.LookupsState>
  ) {}

  ngOnInit(): void {
    this.init();
  }

  /**
   * Destroy component data
   */
  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  /**
   * Initialize component data.
   */
  init() {
    /**
     * Load data.
     */
    this.isUpdating$ = this.salesStore$.pipe(select(fromSalesStore.getSelectedCustomerUpdating));

    let isManualSearchTriggeredBeforeForCategories = false;
    this.customerCategories$ = this.lookupsStore$.pipe(
      select(fromLookupsStore.getCustomerCategories),

      /*
       * Get the 'ORGANIZATION' customer category organization id.
       */
      tap((customerCategories) => {
        if (!isManualSearchTriggeredBeforeForCategories && !customerCategories.length) {
          isManualSearchTriggeredBeforeForCategories = true;
          this.lookupsStore$.dispatch(new fromLookupsStore.GetAllCustomerCategory());
        } else if (customerCategories?.length) {
          this.customerCategoryOrganizationId = customerCategories.find(
            (customerCategory) => customerCategory.key === CustomerCategories.ORGANIZATION
          )?.id;
        }
      })
    );

    let isManualSearchTriggeredBeforeForCities = false;
    this.cities$ = this.lookupsStore$.pipe(
      select(fromLookupsStore.getCities),
      tap((cities) => {
        if (!isManualSearchTriggeredBeforeForCities && !cities.length) {
          isManualSearchTriggeredBeforeForCities = true;
          this.lookupsStore$.dispatch(new fromLookupsStore.GetAllCities());
        }
      })
    );
  }

  /**
   * Initialize form and add validators.
   */
  initForm() {
    const customerCategoryIdFormControl = new FormControl(null, Validators.required);

    this.form = new FormGroup({
      name: new FormControl('', [Validators.required, Validators.minLength(1), Validators.maxLength(50)]),
      nameEn: new FormControl('', [Validators.required, Validators.minLength(1), Validators.maxLength(50)]),
      cityId: new FormControl(null, Validators.required),
      locationId: new FormControl(null),
      customerCategoryId: customerCategoryIdFormControl,
      vatNumber: new FormControl('', [CustomValidators.number, Validators.minLength(0), Validators.maxLength(15)]),
      commercialRegistrationNo: new FormControl('', [Validators.minLength(0), Validators.maxLength(10)]),
      buildingNo: new FormControl('', [Validators.minLength(0), Validators.maxLength(50)]),
      streetName: new FormControl('', [Validators.minLength(0), Validators.maxLength(50)]),
      district: new FormControl('', [Validators.minLength(0), Validators.maxLength(50)]),
      country: new FormControl('', [Validators.minLength(0), Validators.maxLength(50)]),
      postalCode: new FormControl('', [Validators.minLength(0), Validators.maxLength(50)]),
      additionalNo: new FormControl('', [Validators.minLength(0), Validators.maxLength(50)]),
      otherBuyerId: new FormControl('', [Validators.minLength(0), Validators.maxLength(50)]),
      moreInfo: new FormControl('', [Validators.minLength(0), Validators.maxLength(200)]),
      representorName: new FormControl('', [Validators.required, Validators.minLength(1), Validators.maxLength(50)]),
      representorMobile: new FormControl('', [Validators.required, Validators.minLength(1), Validators.maxLength(11)]),
      representorPhone: new FormControl('', [Validators.minLength(0), Validators.maxLength(11)]),
      representorEmail: new FormControl('', [Validators.email]),
    });

    /**
     * Dynamically set form validations based on the selected customer category.
     */
    this.subscriptions.add(customerCategoryIdFormControl.valueChanges.subscribe(() => this.setValidations()));
  }

  /**
   * Submits the form.
   */
  submit() {
    if (this.form.invalid) {
      const errorMessage = new NotificationMessage();

      if (this.form.get('name').invalid) {
        errorMessage.title = this.translationService.translate(
          'SALES.CUSTOMERS.CUSTOMER_DATA_VALIDATION.CUSTOMER_NAME_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate('SALES.CUSTOMERS.CUSTOMER_DATA_VALIDATION.CUSTOMER_NAME_IS_REQUIRED'),
          this.translationService.translate('SALES.CUSTOMERS.CUSTOMER_DATA_VALIDATION.CUSTOMER_NAME_LENGTH_ERROR'),
        ];
      } else if (this.form.get('nameEn').invalid) {
        errorMessage.title = this.translationService.translate(
          'SALES.CUSTOMERS.CUSTOMER_DATA_VALIDATION.CUSTOMER_ENGLISH_NAME_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate('SALES.CUSTOMERS.CUSTOMER_DATA_VALIDATION.CUSTOMER_NAME_EN_IS_REQUIRED'),
          this.translationService.translate('SALES.CUSTOMERS.CUSTOMER_DATA_VALIDATION.CUSTOMER_NAME_EN_LENGTH_ERROR'),
        ];
      } else if (this.form.get('customerCategoryId').invalid) {
        errorMessage.title = this.translationService.translate(
          'SALES.CUSTOMERS.CUSTOMER_DATA_VALIDATION.CUSTOMER_TYPE_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate('SALES.CUSTOMERS.CUSTOMER_DATA_VALIDATION.CUSTOMER_TYPE_IS_REQUIRED'),
        ];
      } else if (this.form.get('commercialRegistrationNo').invalid) {
        errorMessage.title = this.translationService.translate(
          'SALES.CUSTOMERS.CUSTOMER_DATA_VALIDATION.COMMERCIAL_REGISTRY_NUMBER_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate(
            'SALES.CUSTOMERS.CUSTOMER_DATA_VALIDATION.COMMERCIAL_REGISTRY_NUMBER_IS_REQUIRED'
          ),
          this.translationService.translate(
            'SALES.CUSTOMERS.CUSTOMER_DATA_VALIDATION.COMMERCIAL_REGISTRY_NUMBER_LENGTH_ERROR'
          ),
        ];
      } else if (this.form.get('vatNumber').invalid) {
        errorMessage.title = this.translationService.translate(
          'SALES.CUSTOMERS.CUSTOMER_DATA_VALIDATION.VAT_NUMBER_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate('SALES.CUSTOMERS.CUSTOMER_DATA_VALIDATION.VAT_NUMBER_LENGTH_ERROR'),
        ];
      } else if (this.form.get('cityId').invalid) {
        errorMessage.title = this.translationService.translate(
          'SALES.CUSTOMERS.CUSTOMER_DATA_VALIDATION.CITY_NAME_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate('SALES.CUSTOMERS.CUSTOMER_DATA_VALIDATION.CITY_NAME_IS_REQUIRED'),
        ];
      } else if (this.form.get('buildingNo').invalid) {
        errorMessage.title = this.translationService.translate(
          'SALES.CUSTOMERS.CUSTOMER_DATA_VALIDATION.BUILDING_NUMBER_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate('SALES.CUSTOMERS.CUSTOMER_DATA_VALIDATION.BUILDING_NUMBER_IS_REQUIRED'),
          this.translationService.translate('SALES.CUSTOMERS.CUSTOMER_DATA_VALIDATION.BUILDING_NUMBER_LENGTH_ERROR'),
        ];
      } else if (this.form.get('streetName').invalid) {
        errorMessage.title = this.translationService.translate(
          'SALES.CUSTOMERS.CUSTOMER_DATA_VALIDATION.STREET_NAME_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate('SALES.CUSTOMERS.CUSTOMER_DATA_VALIDATION.STREET_NAME_IS_REQUIRED'),
          this.translationService.translate('SALES.CUSTOMERS.CUSTOMER_DATA_VALIDATION.STREET_NAME_LENGTH_ERROR'),
        ];
      } else if (this.form.get('district').invalid) {
        errorMessage.title = this.translationService.translate(
          'SALES.CUSTOMERS.CUSTOMER_DATA_VALIDATION.DISTRICT_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate('SALES.CUSTOMERS.CUSTOMER_DATA_VALIDATION.DISTRICT_IS_REQUIRED'),
          this.translationService.translate('SALES.CUSTOMERS.CUSTOMER_DATA_VALIDATION.DISTRICT_LENGTH_ERROR'),
        ];
      } else if (this.form.get('country').invalid) {
        errorMessage.title = this.translationService.translate(
          'SALES.CUSTOMERS.CUSTOMER_DATA_VALIDATION.COUNTRY_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate('SALES.CUSTOMERS.CUSTOMER_DATA_VALIDATION.COUNTRY_IS_REQUIRED'),
          this.translationService.translate('SALES.CUSTOMERS.CUSTOMER_DATA_VALIDATION.COUNTRY_LENGTH_ERROR'),
        ];
      } else if (this.form.get('postalCode').invalid) {
        errorMessage.title = this.translationService.translate(
          'SALES.CUSTOMERS.CUSTOMER_DATA_VALIDATION.POSTAL_CODE_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate('SALES.CUSTOMERS.CUSTOMER_DATA_VALIDATION.POSTAL_CODE_IS_REQUIRED'),
          this.translationService.translate('SALES.CUSTOMERS.CUSTOMER_DATA_VALIDATION.POSTAL_CODE_LENGTH_ERROR'),
        ];
      } else if (this.form.get('additionalNo').invalid) {
        errorMessage.title = this.translationService.translate(
          'SALES.CUSTOMERS.CUSTOMER_DATA_VALIDATION.ADDITIONAL_NUMBER_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate('SALES.CUSTOMERS.CUSTOMER_DATA_VALIDATION.ADDITIONAL_NUMBER_IS_REQUIRED'),
          this.translationService.translate('SALES.CUSTOMERS.CUSTOMER_DATA_VALIDATION.ADDITIONAL_NUMBER_LENGTH_ERROR'),
        ];
      } else if (this.form.get('otherBuyerId').invalid) {
        errorMessage.title = this.translationService.translate(
          'SALES.CUSTOMERS.CUSTOMER_DATA_VALIDATION.OTHER_BUYER_ID_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate('SALES.CUSTOMERS.CUSTOMER_DATA_VALIDATION.OTHER_BUYER_ID_LENGTH_ERROR'),
        ];
      } else if (this.form.get('moreInfo').invalid) {
        errorMessage.title = this.translationService.translate(
          'SALES.CUSTOMERS.CUSTOMER_DATA_VALIDATION.EXTRA_DATA_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate('SALES.CUSTOMERS.CUSTOMER_DATA_VALIDATION.EXTRA_DATA_LENGTH_ERROR'),
        ];
      } else if (this.form.get('representorName').invalid) {
        errorMessage.title = this.translationService.translate(
          'SALES.CUSTOMERS.CUSTOMER_DATA_VALIDATION.RESPONSIBLE_NAME_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate('SALES.CUSTOMERS.CUSTOMER_DATA_VALIDATION.RESPONSIBLE_NAME_IS_REQUIRED'),
          this.translationService.translate('SALES.CUSTOMERS.CUSTOMER_DATA_VALIDATION.RESPONSIBLE_NAME_LENGTH_ERROR'),
        ];
      } else if (this.form.get('representorMobile').invalid) {
        errorMessage.title = this.translationService.translate(
          'SALES.CUSTOMERS.CUSTOMER_DATA_VALIDATION.RESPONSIBLE_MOBILE_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate('SALES.CUSTOMERS.CUSTOMER_DATA_VALIDATION.RESPONSIBLE_MOBILE_IS_REQUIRED'),
          this.translationService.translate('SALES.CUSTOMERS.CUSTOMER_DATA_VALIDATION.RESPONSIBLE_MOBILE_LENGTH_ERROR'),
        ];
      } else if (this.form.get('representorPhone').invalid) {
        errorMessage.title = this.translationService.translate(
          'SALES.CUSTOMERS.CUSTOMER_DATA_VALIDATION.RESPONSIBLE_PHONE_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate('SALES.CUSTOMERS.CUSTOMER_DATA_VALIDATION.RESPONSIBLE_PHONE_LENGTH_ERROR'),
        ];
      } else if (this.form.get('representorEmail').invalid) {
        errorMessage.title = this.translationService.translate(
          'SALES.CUSTOMERS.CUSTOMER_DATA_VALIDATION.RESPONSIBLE_EMAIL_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate('SALES.CUSTOMERS.CUSTOMER_DATA_VALIDATION.RESPONSIBLE_EMAIL_VALUE_ERROR'),
        ];
      }

      this.form.markAllAsTouched();
      return this.notificationService.warningWithTitle(errorMessage);
    } else {
      this.openModal(this.confirmModalRef);
    }
  }

  /**
   * Sets the conditional validation for the form based on the selected customer category.
   */
  setValidations() {
    const customerCategoryId = parseInt(this.form.controls.customerCategoryId.value ?? '0');
    /**
     * Check if the customer category is organization.
     */
    if (customerCategoryId === this.customerCategoryOrganizationId) {
      this.form.controls.commercialRegistrationNo.setValidators([
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(10),
      ]);
      this.form.controls.vatNumber.setValidators([
        CustomValidators.number,
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(50),
      ]);
      this.form.controls.representorName.setValidators([
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(50),
      ]);
      this.form.controls.buildingNo.setValidators([
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(50),
      ]);
      this.form.controls.streetName.setValidators([
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(50),
      ]);
      this.form.controls.district.setValidators([
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(50),
      ]);
      this.form.controls.country.setValidators([
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(50),
      ]);
      this.form.controls.postalCode.setValidators([
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(50),
      ]);
      this.form.controls.additionalNo.setValidators([
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(50),
      ]);
    } else {
      this.form.controls.commercialRegistrationNo.setValidators([Validators.minLength(0), Validators.maxLength(10)]);
      this.form.controls.vatNumber.setValidators([
        CustomValidators.number,
        Validators.minLength(0),
        Validators.maxLength(50),
      ]);
      this.form.controls.representorName.setValidators([Validators.minLength(0), Validators.maxLength(50)]);
      this.form.controls.buildingNo.setValidators([Validators.minLength(0), Validators.maxLength(50)]);
      this.form.controls.streetName.setValidators([Validators.minLength(0), Validators.maxLength(50)]);
      this.form.controls.district.setValidators([Validators.minLength(0), Validators.maxLength(50)]);
      this.form.controls.country.setValidators([Validators.minLength(0), Validators.maxLength(50)]);
      this.form.controls.postalCode.setValidators([Validators.minLength(0), Validators.maxLength(50)]);
      this.form.controls.additionalNo.setValidators([Validators.minLength(0), Validators.maxLength(50)]);
    }
    this.form.controls.commercialRegistrationNo.updateValueAndValidity();
    this.form.controls.vatNumber.updateValueAndValidity();
    this.form.controls.representorName.updateValueAndValidity();
    this.form.controls.buildingNo.updateValueAndValidity();
    this.form.controls.streetName.updateValueAndValidity();
    this.form.controls.district.updateValueAndValidity();
    this.form.controls.country.updateValueAndValidity();
    this.form.controls.postalCode.updateValueAndValidity();
    this.form.controls.additionalNo.updateValueAndValidity();
  }

  /**
   * Confirms the form submit.
   */
  confirm() {
    const { vatNumber, representorPhone, representorMobile, commercialRegistrationNo, ...rest } = this.form.value;
    this.salesStore$.dispatch(
      new fromSalesStore.UpdateCustomer({
        id: this.customerObj?.id,
        ...rest,
        locationId: this.form.value.locationId,
        representorPhone: representorPhone?.toString(),
        representorMobile: representorMobile?.toString(),
        vatNumber: vatNumber?.toString(),
        commercialRegistrationNo: commercialRegistrationNo.toString(),
      })
    );
  }

  /**
   * Undo form changes.
   */
  discardChanges() {
    this.form.reset({ ...this.customerObj });
    this.form.markAllAsTouched();
  }

  /**
   * Opens the modal of the given templateRef.
   * @param modalRef The modal templateRef to be opened.
   */
  openModal(modalRef) {
    this.modalService.open(modalRef);
  }

  /**
   * Selects the newly selected location from the locations search list.
   * @param locations The list of newly selected locations to select the only one in the list.
   */
  selectLocation([location]: Location[]) {
    if (location) {
      this.locations = [location];
      this.form.patchValue({ locationId: location.id });
    }
  }
}
