import { Action } from '@ngrx/store';

import { PayrollElementClassification } from 'lookups/models';
import { AppHttpResponse } from 'shared';

/**
 * The Lookups-Module store payroll element classification action types.
 */
export enum PayrollElementClassificationActionType {
  GET_ALL_PAYROLL_ELEMENT_CLASSIFICATIONS = '[Lookups] [PayrollElementClassification] Get All Payroll Element Classifications',
  GET_ALL_PAYROLL_ELEMENT_CLASSIFICATIONS_FAIL = '[Lookups] [PayrollElementClassification] Get All Payroll Element Classifications Fail',
  GET_ALL_PAYROLL_ELEMENT_CLASSIFICATIONS_SUCCESS = '[Lookups] [PayrollElementClassification] Get All Payroll Element Classifications Success',
}

/**
 * Represents a store payroll element classification action.
 */
export class GetAllPayrollElementClassifications implements Action {
  /**
   * The type of the action.
   */
  readonly type = PayrollElementClassificationActionType.GET_ALL_PAYROLL_ELEMENT_CLASSIFICATIONS;

  /**
   * Fires a new payroll element classification action.
   */
  constructor() {}
}

/**
 * Represents a store payroll element classification fail action.
 */
export class GetAllPayrollElementClassificationsFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = PayrollElementClassificationActionType.GET_ALL_PAYROLL_ELEMENT_CLASSIFICATIONS_FAIL;

  /**
   * Fires a new payroll element classification fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store payroll element classification success action.
 */
export class GetAllPayrollElementClassificationsSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = PayrollElementClassificationActionType.GET_ALL_PAYROLL_ELEMENT_CLASSIFICATIONS_SUCCESS;

  /**
   * Fires a payroll element classification success action.
   * @param payload An object contains the list of payroll element classifications.
   */
  constructor(public payload: AppHttpResponse<PayrollElementClassification[]>) {}
}

/**
 * Lookups-module payroll element classification action types.
 */
export type PayrollElementClassificationActions =
  | GetAllPayrollElementClassifications
  | GetAllPayrollElementClassificationsFail
  | GetAllPayrollElementClassificationsSuccess;
