import { PaginationInfo } from 'shared/models';
import { ProjectSubTask } from 'projects-management/models';

/**
 * Represents the sub tasks state.
 */
export class SubTasksState {
  /**
   * The list of sub tasks.
   */
  public data: ProjectSubTask[];

  /**
   * The pagination info for the sub tasks.
   */
  public paginationInfo: PaginationInfo;

  /**
   * An error that may resulting during processing some actions.
   */
  public error: any;

  /**
   * Determines if there is a running search process.
   */
  isSearching: boolean;

  /**
   * Determines if the last search process has been ended successfully.
   */
  isSearchCompleted: boolean;
}
