import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms';

import { Observable, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DateTime } from 'luxon';
import Decimal from 'decimal.js';

import {
  NotificationService,
  NotificationMessage,
  TranslationService,
  APP_CONSTANTS,
  CustomValidators,
  PageInfo,
  DateUtil,
} from 'shared';
import * as fromAuthStore from 'auth/store';
import * as fromLookupsStore from 'lookups/store';
import * as fromFinancesStore from 'finances/store';
import { CanComponentDeactivate } from 'auth/models';
import { GovServiceRequestPaymentMethod, GovServiceRequestPaymentMethods } from 'lookups';
import { BankAccount, CostCenter } from 'finances/models';
import {
  GovService,
  GovServiceRequest,
  GovServiceRequestAttachment,
  GovServiceRequestEmployee,
  GovServiceRequestEmployeeFormItem,
  GovServiceRequestEmployeeInput,
} from 'hr/models';

@Component({
  selector: 'app-create-pay-gov-service-request',
  templateUrl: './create-pay-gov-service-request.component.html',
  styles: [],
})
export class CreatePayGovServiceRequestComponent implements OnInit, OnDestroy, CanComponentDeactivate {
  /**
   * The confirm modal template reference.
   */
  @ViewChild('confirmModalRef') confirmModalRef: ElementRef<any>;

  /**
   * The confirm view attachments modal template reference.
   */
  @ViewChild('viewAttachmentsModalRef') viewAttachmentsModalRef: ElementRef<any>;

  /**
   * Gets or sets the information about the current page.
   */
  pageInfo: PageInfo = {
    title: 'HR.GOV_SERVICE_REQUESTS.PAY_GOV_SERVICE_REQUEST_TITLE',
    icon: 'fa fa-usd',
  };

  /**
   * Gets the gov service request employee form-array.
   */
  get employeesForm(): FormArray {
    return this.form?.controls.employees as FormArray;
  }

  /**
   * Indicates whether there is a create pay gov service request refunds process is running or not.
   */
  isPaying$: Observable<boolean>;

  /**
   * Shows or hide the bank accounts list.
   */
  bankAccountsListVisibility = false;

  /**
   * Shows or hide the cost centers list.
   */
  costCentersListVisibility = false;

  /**
   * The list of selected gov services.
   */
  govServices: GovService[] = [];

  /**
   * The list of gov service payment method.
   */
  paymentMethods$: Observable<GovServiceRequestPaymentMethod[]>;

  /**
   * The list of bank accounts.
   */
  bankAccounts: BankAccount[] = [];

  /**
   * The list of cost centers.
   */
  costCenters: CostCenter[] = [];

  /**
   * Gets or sets the id of the gov service request payment method for `CASH`.
   */
  paymentMethodIdForCash: number;

  /**
   * Gets or sets the id of the gov service request payment method for `BANK_TRANSFER`.
   */
  paymentMethodIdForBankTransfer: number;

  /**
   * The decimal mask.
   */
  readonly DECIMAL_MASK = APP_CONSTANTS.numeric.decimal.mask;

  /**
   * The gov service request form.
   */
  form: FormGroup;

  /**
   * Gets or sets the id of the current edited gov service request.
   */
  govServiceRequestId: number;

  /**
   * Gets or sets the version of the current edited gov service request.
   */
  version: number;

  /**
   * The current edited gov service request.
   */
  govServiceRequest$: Observable<GovServiceRequest>;

  /**
   * Used to add new gov service request employees form items to the gov service request employees form.
   */
  newEmployeeItems: GovServiceRequestEmployeeFormItem[];

  /**
   * Gets the attachments form-array.
   */
  get attachmentsForm(): FormArray {
    return this.form?.controls.attachments as FormArray;
  }

  /**
   * Get selected gov service request.
   */
  selectedGovServiceRequest: GovServiceRequest;

  /**
   * Gets the total request service values.
   */
  get serviceValueTotal(): number {
    return Decimal.sum(0, ...(this.employeesForm?.value.map((item) => item.serviceValue ?? 0) ?? [])).toNumber();
  }

  /**
   * Gets the total request penalty values.
   */
  get penaltyValueTotal(): number {
    return Decimal.sum(0, ...(this.employeesForm?.value.map((item) => item.penaltyValue ?? 0) ?? [])).toNumber();
  }

  /**
   * Gets the total request net.
   */
  get requestNet(): number {
    return Decimal.add(this.serviceValueTotal, this.penaltyValueTotal).toNumber();
  }

  /**
   * Get bank fee value.
   */
  get bankFee(): number {
    return this.form?.controls.bankFee.value > 0 ? this.form?.controls.bankFee.value : 0;
  }

  /**
   * Get bank fee tax value.
   */
  get bankFeeTaxValue(): number {
    return this.form?.controls.bankFeeTaxValue.value > 0 ? this.form?.controls.bankFeeTaxValue.value : 0;
  }

  /**
   * Gets the payment net.
   */
  get paymentNet(): number {
    return Decimal.sum(this.requestNet, this.bankFee, this.bankFeeTaxValue).toNumber();
  }

  /**
   * The set of subscriptions on this components,
   * these subscriptions must be unsubscribed before this component got destroyed.
   */
  subscriptions = new Subscription();

  constructor(
    private modalService: NgbModal,
    private notificationService: NotificationService,
    private authStore$: Store<fromAuthStore.AuthState>,
    private financesStore$: Store<fromFinancesStore.FinancesState>,
    private lookupsStore$: Store<fromLookupsStore.LookupsState>,
    private translationService: TranslationService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.init();
  }

  /**
   * Unsubscribes for component data.
   */
  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  /**
   * Initialize component data.
   */
  init() {
    /** Subscribe to route to get the product id whenever it changed. */
    const routeSubscription = this.route.paramMap
      .pipe(
        tap((params) => {
          this.govServiceRequestId = +params.get('govServiceRequestId');
          this.financesStore$.dispatch(new fromFinancesStore.FindGovServiceRequest(this.govServiceRequestId));
        })
      )
      .subscribe();
    this.subscriptions.add(routeSubscription);

    this.initForm();

    // /* Subscribe to default cost center to select it whenever it changed. */
    this.subscriptions.add(
      this.financesStore$
        .pipe(
          select(fromFinancesStore.getGeneralCostCenter),
          tap((costCenter) => {
            if (costCenter) {
              if (!this.costCenters.some((item) => item.id === costCenter.id)) {
                this.costCenters.push(costCenter);
              }

              this.selectCostCenter([costCenter]);
            }
          })
        )
        .subscribe()
    );

    this.financesStore$.dispatch(new fromFinancesStore.GetGeneralCostCenter());

    /* Subscribe to user cost centers to select it whenever it changed. */
    this.subscriptions.add(
      this.authStore$
        .pipe(
          select(fromAuthStore.getUserCostCenters),
          tap((costCenters) => {
            if (costCenters?.length) {
              costCenters.forEach((costCenter) => {
                if (!this.costCenters.some((item) => item.id === costCenter.id)) {
                  this.costCenters.push(costCenter);
                }
              });
            }
          })
        )
        .subscribe()
    );

    this.authStore$.dispatch(new fromAuthStore.GetUserCostCenters());

    /** Load data. */
    let isManualSearchTriggeredBeforeForGovServiceRequestPaymentMethods = false;
    this.paymentMethods$ = this.lookupsStore$.pipe(
      select(fromLookupsStore.getGovServiceRequestPaymentMethods),
      tap((paymentMethods) => {
        if (!isManualSearchTriggeredBeforeForGovServiceRequestPaymentMethods && !paymentMethods.length) {
          isManualSearchTriggeredBeforeForGovServiceRequestPaymentMethods = true;
          this.lookupsStore$.dispatch(new fromLookupsStore.GetAllGovServiceRequestPaymentMethods());
        } else if (paymentMethods?.length) {
          this.paymentMethodIdForCash = paymentMethods?.find(
            (paymentMethod) => paymentMethod.key === GovServiceRequestPaymentMethods.CASH
          )?.id;
          this.paymentMethodIdForBankTransfer = paymentMethods?.find(
            (paymentMethod) => paymentMethod.key === GovServiceRequestPaymentMethods.BANK_TRANSFER
          )?.id;
        }

        this.form.patchValue({
          govServiceRequestPaymentMethodId: this.paymentMethodIdForBankTransfer,
        });
      })
    );

    /* Load the selected gov service request. */
    this.govServiceRequest$ = this.financesStore$.pipe(
      select(fromFinancesStore.getSelectedGovServiceRequest),
      tap((request) => {
        if (request) {
          this.form.patchValue({
            govServiceRequestPaymentMethodId: this.paymentMethodIdForBankTransfer,
            version: request.version,
            govServiceId: request.govServiceId,
            govService: request.govService,
            notes: request.notes,
          });

          this.employeesForm.clear();

          this.newEmployeeItems = request.govServiceRequestEmployees.map((emp: GovServiceRequestEmployee) => {
            {
              const fromDateFormatted = DateTime.fromSQL(emp.fromDate);
              const toDateFormatted = DateTime.fromSQL(emp.toDate);

              const fromDate = {
                year: fromDateFormatted.year,
                month: fromDateFormatted.month,
                day: fromDateFormatted.day,
              };

              const toDate = {
                year: toDateFormatted.year,
                month: toDateFormatted.month,
                day: toDateFormatted.day,
              };

              const formItem: GovServiceRequestEmployeeFormItem = {
                employeeId: emp.employeeId,
                employee: emp.employee,
                serviceValue: emp.serviceValue,
                penaltyValue: emp.penaltyValue,
                fromDate: fromDate,
                toDate: toDate,
                notes: emp.notes,
              };

              return formItem;
            }
          });

          this.form.markAsPristine();
        }
      })
    );

    this.isPaying$ = this.financesStore$.pipe(select(fromFinancesStore.getSelectedPayGovServiceRequestCreating));
  }

  /**
   * Initialize paid form and add validators.
   */
  initForm() {
    const paymentMethodIdFormControl = new FormControl(null, Validators.required);
    const bankFeeFormControl = new FormControl(null);
    const bankFeeTaxValueFormControl = new FormControl(null);

    this.form = new FormGroup({
      govServiceRequestPaymentMethodId: paymentMethodIdFormControl,
      bankAccountId: new FormControl(null, Validators.required),
      bankFee: bankFeeFormControl,
      bankFeeTaxValue: bankFeeTaxValueFormControl,
      costCenterId: new FormControl(null, Validators.required),
      version: new FormControl(null),
      govServiceId: new FormControl(null),
      govService: new FormControl(null),
      employees: new FormArray([]),
      transactionDate: new FormControl(null),
      notes: new FormControl(''),
      attachments: new FormArray([]),
    });

    this.subscriptions.add(
      this.form.controls.govServiceRequestPaymentMethodId.valueChanges.subscribe(() => {
        this.onPaymentMethodTypeChange();
      })
    );
  }

  /**
   * And Sets the conditional validation for the form based on the selected payment method id.
   */
  onPaymentMethodTypeChange() {
    /**
     * The id of the current selected payment method type.
     */
    const paymentMethodId = parseInt(this.form.controls.govServiceRequestPaymentMethodId.value ?? '0');

    /**
     * Check if the payment method type is bank transfer.
     */
    if (paymentMethodId === this.paymentMethodIdForBankTransfer) {
      this.form.controls.bankFee.setValidators([Validators.required, CustomValidators.gte(0)]);
      this.form.controls.bankFeeTaxValue.setValidators([Validators.required, CustomValidators.gte(0)]);
    } else {
      this.form.controls.bankFee.clearValidators();
      this.form.controls.bankFee.setValue(null);
      this.form.controls.bankFeeTaxValue.clearValidators();
      this.form.controls.bankFeeTaxValue.setValue(null);
    }
    this.form.controls.bankFee.updateValueAndValidity();
    this.form.controls.bankFeeTaxValue.updateValueAndValidity();
  }

  /**
   * Submits the form.
   */
  submit() {
    const errorMessage = new NotificationMessage();
    if (this.form.invalid) {
      if (this.form.get('govServiceRequestPaymentMethodId').invalid) {
        errorMessage.title = this.translationService.translate(
          'HR.GOV_SERVICE_REQUESTS.GOV_SERVICE_REQUEST_DATA_VALIDATION.PAYMENT_METHOD_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate(
            'HR.GOV_SERVICE_REQUESTS.GOV_SERVICE_REQUEST_DATA_VALIDATION.PAYMENT_METHOD_IS_REQUIRED'
          ),
        ];
      } else if (this.form.get('bankAccountId').invalid) {
        errorMessage.title = this.translationService.translate(
          'HR.GOV_SERVICE_REQUESTS.GOV_SERVICE_REQUEST_DATA_VALIDATION.BANK_ACCOUNT_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate(
            'HR.GOV_SERVICE_REQUESTS.GOV_SERVICE_REQUEST_DATA_VALIDATION.BANK_ACCOUNT_IS_REQUIRED'
          ),
        ];
      } else if (this.form.get('bankFee').invalid) {
        errorMessage.title = this.translationService.translate(
          'HR.GOV_SERVICE_REQUESTS.GOV_SERVICE_REQUEST_DATA_VALIDATION.BANK_FEE_VALUE_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate(
            'HR.GOV_SERVICE_REQUESTS.GOV_SERVICE_REQUEST_DATA_VALIDATION.BANK_FEE_VALUE_IS_REQUIRED'
          ),
        ];
      } else if (this.form.get('bankFeeTaxValue').invalid) {
        errorMessage.title = this.translationService.translate(
          'HR.GOV_SERVICE_REQUESTS.GOV_SERVICE_REQUEST_DATA_VALIDATION.BANK_FEE_TAX_VALUE_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate(
            'HR.GOV_SERVICE_REQUESTS.GOV_SERVICE_REQUEST_DATA_VALIDATION.BANK_FEE_TAX_VALUE_IS_REQUIRED'
          ),
        ];
      } else if (this.form.get('costCenterId').invalid) {
        errorMessage.title = this.translationService.translate(
          'HR.GOV_SERVICE_REQUESTS.GOV_SERVICE_REQUEST_DATA_VALIDATION.COST_CENTER_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate(
            'HR.GOV_SERVICE_REQUESTS.GOV_SERVICE_REQUEST_DATA_VALIDATION.COST_CENTER_IS_REQUIRED'
          ),
        ];
      } else if (this.form.get('employees').invalid) {
        /**
         * Check if gov service request employees count = 0.
         */
        if (!this.employeesForm.controls.length) {
          errorMessage.title = this.translationService.translate(
            'HR.GOV_SERVICE_REQUESTS.GOV_SERVICE_REQUEST_DATA_VALIDATION.EMPLOYEES_ERROR'
          );
          errorMessage.body = [
            this.translationService.translate(
              'HR.GOV_SERVICE_REQUESTS.GOV_SERVICE_REQUEST_DATA_VALIDATION.EMPLOYEES_COUNT_ERROR'
            ),
          ];
        } else {
          /**
           * Check if some of gov service request employees  has errors.
           */
          for (let index = 0; index < this.employeesForm.controls.length; index++) {
            const govServiceRequestEmployeeFormItem = this.employeesForm.controls[index] as FormArray;

            if (govServiceRequestEmployeeFormItem.valid) {
              continue;
            }

            errorMessage.title = this.translationService.translate(
              'HR.GOV_SERVICE_REQUESTS.GOV_SERVICE_REQUEST_DATA_VALIDATION.EMPLOYEE_NUMBER',
              {
                employeeNumber: index + 1,
              }
            );
            errorMessage.body = [];

            if (govServiceRequestEmployeeFormItem.get('fromDate').invalid) {
              errorMessage.body.push(
                this.translationService.translate(
                  'HR.GOV_SERVICE_REQUESTS.GOV_SERVICE_REQUEST_DATA_VALIDATION.FROM_DATE_IS_REQUIRED'
                )
              );
            }
            if (govServiceRequestEmployeeFormItem.get('toDate').invalid) {
              errorMessage.body.push(
                this.translationService.translate(
                  'HR.GOV_SERVICE_REQUESTS.GOV_SERVICE_REQUEST_DATA_VALIDATION.TO_DATE_IS_REQUIRED'
                )
              );
            }

            if (govServiceRequestEmployeeFormItem.get('serviceValue').invalid) {
              errorMessage.body.push(
                this.translationService.translate(
                  'HR.GOV_SERVICE_REQUESTS.GOV_SERVICE_REQUEST_DATA_VALIDATION.SERVICE_VALUE_IS_REQUIRED'
                )
              );
            }

            if (govServiceRequestEmployeeFormItem.get('penaltyValue').invalid) {
              errorMessage.body.push(
                this.translationService.translate(
                  'HR.GOV_SERVICE_REQUESTS.GOV_SERVICE_REQUEST_DATA_VALIDATION.PENALTY_VALUE_IS_REQUIRED'
                )
              );
            }
            break;
          }
        }
      }

      this.form.markAllAsTouched();
      return this.notificationService.warningWithTitle(errorMessage);
    } else {
      this.openModal(this.confirmModalRef);
    }
  }

  /**
   * Confirms the form submit gov service request.
   */
  confirm() {
    let transactionDateFormatted;
    const { transactionDate } = this.form.value;
    if (transactionDate) {
      transactionDateFormatted = new Date(transactionDate.year, transactionDate.month - 1, transactionDate.day);
      transactionDateFormatted.setHours(new Date().getHours(), new Date().getMinutes());
    }

    /**
     * The list of gov service request employee in the lines form.
     */
    const govServiceRequestEmployees = this.employeesForm.value.map((item: GovServiceRequestEmployeeFormItem) => {
      const emp: GovServiceRequestEmployeeInput = {
        employeeId: item.employeeId,
        fromDate: DateUtil.toDateOnly(new Date(item.fromDate.year, item.fromDate.month - 1, item.fromDate.day)),
        toDate: DateUtil.toDateOnly(new Date(item.toDate.year, item.toDate.month - 1, item.toDate.day)),
        serviceValue: item.serviceValue,
        penaltyValue: item.penaltyValue,
        notes: item.notes,
      };

      return emp;
    });

    /**
     * Dispatch create pay gov service request employee action.
     */
    this.financesStore$.dispatch(
      new fromFinancesStore.CreatePayGovServiceRequest({
        govServiceRequestPaymentMethodId: this.form.value.govServiceRequestPaymentMethodId,
        bankAccountId: this.form.value.bankAccountId,
        bankFee: this.form.value.bankFee ?? null,
        bankFeeTaxValue: this.form.value.bankFeeTaxValue ?? null,
        costCenterId: this.form.value.costCenterId,
        govServiceRequestId: this.govServiceRequestId,
        govServiceId: this.form.value.govServiceId,
        version: this.form.value.version,
        transactionDate: transactionDateFormatted,
        notes: this.form.value.notes,
        govServiceRequestEmployees,
        attachments: this.attachmentsForm.value.filter((file) => !!file),
      })
    );
  }

  /**
   * Opens the modal of the given templateRef.
   * @param modalRef The modal templateRef to be opened.
   */
  openModal(modalRef) {
    this.modalService.open(modalRef);
  }

  /**
   * Selects the newly selected bank account from the bank accounts search list.
   * @param bankAccounts The list of newly selected bank accounts to select the only one in the list.
   */
  selectBankAccount([bankAccount]: BankAccount[]) {
    if (bankAccount) {
      this.bankAccounts = [bankAccount];
      this.form.patchValue({ bankAccountId: bankAccount.id });
    }
  }

  /**
   * Selects the newly selected cost center from the cost centers search list.
   * @param costCenters The list of newly selected cost centers to select the only one in the list.
   */
  selectCostCenter([costCenter]: CostCenter[]) {
    if (costCenter) {
      this.form.patchValue({ costCenterId: costCenter.id });
    }
  }

  /**
   * Maps and returns the set of petty cash refund request line attachments into a set of urls.
   * @param attachments The petty cash refund request line attachments to be mapped.
   */
  getAttachmentsUrls(attachments: GovServiceRequestAttachment[]): string[] {
    return attachments ? attachments.map((attachment) => attachment.url) : [];
  }

  /**
   * Confirms leaving the page before routing.
   */
  confirmDeactivate() {
    if (this.form.dirty === true) {
      /**
       * Message to warn the user before leaving the detailed form.
       */
      return confirm(this.translationService.translate('SHARED.CONFIRM.LEAVING'));
    }
    return true;
  }
}
