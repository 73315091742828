import { ManagerialLevelActionType, ManagerialLevelActions } from 'lookups/store/actions';
import { ManagerialLevelState } from 'lookups/store/states';

/**
 * The initial state from which the state starts.
 */
const initialState: ManagerialLevelState = {
  data: [],
  error: null,
  isLoaded: false,
  isLoading: false,
};

/**
 * The reducer function that is called in each action dispatch against the state.
 * @param state The current state.
 * @param action The action that will affect the state.
 */
export function managerialLevelReducer(
  state: ManagerialLevelState = initialState,
  action: ManagerialLevelActions
): ManagerialLevelState {
  switch (action.type) {
    case ManagerialLevelActionType.GET_ALL_MANAGERIAL_LEVELS: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case ManagerialLevelActionType.GET_ALL_MANAGERIAL_LEVELS_FAIL: {
      return {
        ...state,
        error: action.payload,
        isLoading: false,
        isLoaded: false,
      };
    }

    case ManagerialLevelActionType.GET_ALL_MANAGERIAL_LEVELS_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        isLoading: false,
        isLoaded: true,
      };
    }

    default:
      return state;
  }
}
