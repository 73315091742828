import { EmployeesActionType, EmployeesActions } from 'app-search/store/actions';
import { EmployeesState } from 'app-search/store/states';

/**
 * The initial state from which the state starts.
 */
const initialState: EmployeesState = {
  data: [],
  salesPersonsData: [],
  paginationInfo: {
    total: 0,
    page: 1,
    pageSize: 0,
    count: 0,
  },
  salesPersonsPaginationInfo: {
    total: 0,
    page: 1,
    pageSize: 0,
    count: 0,
  },
  error: null,
  isSearching: false,
  isSearchCompleted: false,
  isSalesPersonsSearching: false,
  isSalesPersonsSearchCompleted: false,
};

/**
 * The reducer function that is called in each action dispatch against the state.
 * @param state The current state.
 * @param action The action that will affect the state.
 */
export function employeesReducer(state: EmployeesState = initialState, action: EmployeesActions): EmployeesState {
  switch (action.type) {
    //#region SEARCH_EMPLOYEE

    case EmployeesActionType.SEARCH_EMPLOYEES: {
      return {
        ...state,
        isSearching: true,
        isSearchCompleted: false,
        error: null,
      };
    }

    case EmployeesActionType.SEARCH_EMPLOYEES_FAIL: {
      return {
        ...state,
        error: action.payload,
        isSearching: false,
        isSearchCompleted: false,
      };
    }

    case EmployeesActionType.SEARCH_EMPLOYEES_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        paginationInfo: {
          ...action.payload.meta,
        },
        error: null,
        isSearching: false,
        isSearchCompleted: true,
      };
    }

    //#endregion SEARCH_EMPLOYEE

    //#region SEARCH_SALES_PERSONS

    case EmployeesActionType.SEARCH_SALES_PERSONS: {
      return {
        ...state,
        isSalesPersonsSearching: true,
        isSalesPersonsSearchCompleted: false,
        error: null,
      };
    }

    case EmployeesActionType.SEARCH_SALES_PERSONS_FAIL: {
      return {
        ...state,
        error: action.payload,
        isSalesPersonsSearching: false,
        isSalesPersonsSearchCompleted: false,
      };
    }

    case EmployeesActionType.SEARCH_SALES_PERSONS_SUCCESS: {
      return {
        ...state,
        salesPersonsData: action.payload.data,
        salesPersonsPaginationInfo: {
          ...action.payload.meta,
        },
        error: null,
        isSalesPersonsSearching: false,
        isSalesPersonsSearchCompleted: true,
      };
    }

    //#endregion SEARCH_SALES_PERSONS

    default:
      return state;
  }
}
