import { Action } from '@ngrx/store';

import { AppHttpResponse } from 'shared';
import { CreateSectorInput, Sector, UpdateSectorInput } from 'stores/models';

/**
 * The stores-module store sector action types.
 */
export enum SectorsActionType {
  SEARCH_SECTORS = '[Stores] [Sector] Search Sectors',
  SEARCH_SECTORS_FAIL = '[Stores] [Sector] Search Sectors Fail',
  SEARCH_SECTORS_SUCCESS = '[Stores] [Sector] Search Sectors Success',

  FIND_SECTOR = '[Stores] [Sector] Find Sector',
  FIND_SECTOR_FAIL = '[Stores] [Sector] Find Sector Fail',
  FIND_SECTOR_SUCCESS = '[Stores] [Sector] Find Sector Success',

  CREATE_SECTOR = '[Stores] [Sector] Create Sector',
  CREATE_SECTOR_FAIL = '[Stores] [Sector] Create Sector Fail',
  CREATE_SECTOR_SUCCESS = '[Stores] [Sector] Create Sector Success',

  UPDATE_SECTOR = '[Stores] [Sector] Update Sector',
  UPDATE_SECTOR_FAIL = '[Stores] [Sector] Update Sector Fail',
  UPDATE_SECTOR_SUCCESS = '[Stores] [Sector] Update Sector Success',

  DELETE_SECTOR = '[Stores] [Sector] Delete Sector',
  DELETE_SECTOR_FAIL = '[Stores] [Sector] Delete Sector Fail',
  DELETE_SECTOR_SUCCESS = '[Stores] [Sector] Delete Sector Success',
}

/**
 * Represents a store Sectors search action.
 */
export class SearchSectors implements Action {
  /**
   * The type of the action.
   */
  readonly type = SectorsActionType.SEARCH_SECTORS;

  /**
   * Fires a new sectors search action.
   * @param payload the search parameters,
   * if omitted, all sectors will be loaded.
   */
  constructor(
    public payload: {
      name: string;
      page: number;
    }
  ) {}
}

/**
 * Represents a store sectors search fail action.
 */
export class SearchSectorsFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = SectorsActionType.SEARCH_SECTORS_FAIL;

  /**
   * Fires a new sectors search fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store search success action.
 */
export class SearchSectorsSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = SectorsActionType.SEARCH_SECTORS_SUCCESS;

  /**
   * Fires a new sectors search success action.
   * @param payload An object contains the list of sectors that matches the search criteria
   * in addition to the pagination info.
   */
  constructor(public payload: AppHttpResponse<Sector[]>) {}
}

/**
 * Represents a store find sector action.
 */
export class FindSector implements Action {
  /**
   * The type of the action.
   */
  readonly type = SectorsActionType.FIND_SECTOR;

  /**
   * Fires a new find sector action.
   * @param payload the id of the sector.
   */
  constructor(public payload: number) {}
}

/**
 * Represents a store find sector fail action.
 */
export class FindSectorFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = SectorsActionType.FIND_SECTOR_FAIL;

  /**
   * Fires a new find sector fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store find sector success action.
 */
export class FindSectorSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = SectorsActionType.FIND_SECTOR_SUCCESS;

  /**
   * Fires a new find sectors success action.
   * @param payload The loaded sector.
   */
  constructor(public payload: AppHttpResponse<Sector>) {}
}

/**
 * Represents a store sector create action.
 */
export class CreateSector implements Action {
  /** The type of the action. */
  readonly type = SectorsActionType.CREATE_SECTOR;

  /**
   * Fires a new sector create action.
   * @param payload the new sector data-model.
   */
  constructor(public payload: CreateSectorInput) {}
}

/**
 * Represents a store sector create fail action.
 */
export class CreateSectorFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = SectorsActionType.CREATE_SECTOR_FAIL;

  /**
   * Fires a new sector create fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store sector create success action.
 */
export class CreateSectorSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = SectorsActionType.CREATE_SECTOR_SUCCESS;

  /**
   * Fires a new sector create success action.
   * @param payload The created sector.
   */
  constructor(public payload: AppHttpResponse<Sector>) {}
}

/**
 * Represents a store sector update action.
 */
export class UpdateSector implements Action {
  /**
   * The type of the action.
   */
  readonly type = SectorsActionType.UPDATE_SECTOR;

  /**
   * Fires a new sector update action.
   * @param payload The  sector updated data.
   */
  constructor(public payload: UpdateSectorInput) {}
}

/**
 * Represents a store sector update fail action.
 */
export class UpdateSectorFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = SectorsActionType.UPDATE_SECTOR_FAIL;

  /**
   * Fires a new sector update fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store sector update success action.
 */
export class UpdateSectorSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = SectorsActionType.UPDATE_SECTOR_SUCCESS;

  /**
   * Fires a new sector update-success action.
   * @param payload The updated sector.
   */
  constructor(public payload: AppHttpResponse<Sector>) {}
}

/**
 * Represents a store sector delete action.
 */
export class DeleteSector implements Action {
  /**
   * The type of the action.
   */
  readonly type = SectorsActionType.DELETE_SECTOR;

  /**
   * Fires a new sector delete action.
   * @param payload The sector id.
   */
  constructor(public payload: number) {}
}

/**
 * Represents a store sector delete fail action.
 */
export class DeleteSectorFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = SectorsActionType.DELETE_SECTOR_FAIL;

  /**
   * Fires a new sector delete fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store sector delete success action.
 */
export class DeleteSectorSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = SectorsActionType.DELETE_SECTOR_SUCCESS;

  /**
   * Fires a new sector delete success action.
   * @param payload The deleted sector.
   */
  constructor(public payload: AppHttpResponse<Sector>) {}
}

/**
 * stores-module sectors action types.
 */
export type SectorsActions =
  | SearchSectors
  | SearchSectorsFail
  | SearchSectorsSuccess
  | FindSector
  | FindSectorFail
  | FindSectorSuccess
  | CreateSector
  | CreateSectorFail
  | CreateSectorSuccess
  | UpdateSector
  | UpdateSectorFail
  | UpdateSectorSuccess
  | DeleteSector
  | DeleteSectorFail
  | DeleteSectorSuccess;
