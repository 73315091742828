import { Injectable } from '@angular/core';

import { of } from 'rxjs';
import { switchMap, map, catchError } from 'rxjs/operators';
import { Effect, ofType, Actions } from '@ngrx/effects';

import { LookupsService } from 'lookups/services';
import {
  OutgoingStockTypeActionType,
  GetAllOutgoingStockTypesFail,
  GetAllOutgoingStockTypesSuccess,
} from 'lookups/store/actions';

@Injectable()
export class OutgoingStockTypeEffects {
  constructor(private actions$: Actions, private lookupsService: LookupsService) {}

  /* ========================= GET_ALL_OUTGOING-STOCK_TYPES =================================== */
  @Effect()
  getAllOutgoingStockTypes$ = this.actions$.pipe(
    ofType(OutgoingStockTypeActionType.GET_ALL_OUTGOING_STOCK_TYPES),
    switchMap(() =>
      this.lookupsService.getAllOutgoingStockTypes().pipe(
        map((response) => new GetAllOutgoingStockTypesSuccess(response)),
        catchError((error) => of(new GetAllOutgoingStockTypesFail(error)))
      )
    )
  );
}
