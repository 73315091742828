import { Action } from '@ngrx/store';

import { AssetYearsOfUse } from 'lookups/models';
import { AppHttpResponse } from 'shared';

/**
 * The Lookups-Module store  asset years of use action types.
 */
export enum AssetYearsOfUseActionType {
  GET_ALL_ASSET_YEARS_OF_USE = '[Lookups] [AssetYearsOfUse] Get All Asset Years Of Uses',
  GET_ALL_ASSET_YEARS_OF_USE_FAIL = '[Lookups] [AssetYearsOfUse] Get All Asset Years Of Uses Fail',
  GET_ALL_ASSET_YEARS_OF_USE_SUCCESS = '[Lookups] [AssetYearsOfUse] Get All Asset Years Of Uses Success',
}

/**
 * Represents a store  asset years of use action.
 */
export class GetAllAssetYearsOfUse implements Action {
  /**
   * The type of the action.
   */
  readonly type = AssetYearsOfUseActionType.GET_ALL_ASSET_YEARS_OF_USE;

  /**
   * Fires a new  asset years of use action.
   * @param payload the assetCategoryId
   */
  constructor() {}
}

/**
 * Represents a store  asset years of use fail action.
 */
export class GetAllAssetYearsOfUseFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = AssetYearsOfUseActionType.GET_ALL_ASSET_YEARS_OF_USE_FAIL;

  /**
   * Fires a new  asset years of use fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store  asset years of use success action.
 */
export class GetAllAssetYearsOfUseSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = AssetYearsOfUseActionType.GET_ALL_ASSET_YEARS_OF_USE_SUCCESS;

  /**
   * Fires a  asset years of use success action.
   * @param payload An object contains the list of  asset years of uses.
   */
  constructor(public payload: AppHttpResponse<AssetYearsOfUse[]>) {}
}

/**
 * Lookups-module  asset years of use action types.
 */
export type AssetYearsOfUseActions = GetAllAssetYearsOfUse | GetAllAssetYearsOfUseFail | GetAllAssetYearsOfUseSuccess;
