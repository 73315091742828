import { createSelector } from '@ngrx/store';

import { selectLookupsState } from 'lookups/store/selectors/feature.selectors';
import { LookupsState, OutgoingStockTypeState } from 'lookups/store/states';

/**
 * Gets the outgoing stock types state.
 */
const selectOutgoingStockTypes = createSelector(selectLookupsState, (state: LookupsState) => state.outgoingStockTypes);

/**
 * Gets the list of loaded outgoing stock types.
 */
export const getOutgoingStockTypes = createSelector(
  selectOutgoingStockTypes,
  (state: OutgoingStockTypeState) => state.data
);

/**
 * Gets the error that occurred in the state.
 */
export const getOutgoingStockTypesError = createSelector(
  selectOutgoingStockTypes,
  (state: OutgoingStockTypeState) => state.error
);

/**
 * Determines if there is a running load process.
 */
export const getOutgoingStockTypesIsLoading = createSelector(
  selectOutgoingStockTypes,
  (state: OutgoingStockTypeState) => state.isLoading
);

/**
 * Determines if the last load process has been ended successfully.
 */
export const getOutgoingStockTypesIsLoaded = createSelector(
  selectOutgoingStockTypes,
  (state: OutgoingStockTypeState) => state.isLoaded
);
