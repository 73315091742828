import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { FormArray, FormGroup, FormControl, Validators } from '@angular/forms';

import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';

import { APP_CONSTANTS, CustomValidators, NotificationMessage, NotificationService, TranslationService } from 'shared';
import { ProductUnitOfMeasureRate, ProductUnitOfMeasureRateItem, UnitOfMeasure } from 'stores/models';

@Component({
  selector: 'app-product-unit-of-measure-rate-form',
  templateUrl: './product-unit-of-measure-rate-form.component.html',
  styles: [],
})
export class ProductUnitOfMeasureRateFormComponent {
  /**
   * The delete modal template reference.
   */
  @ViewChild('deleteModalRef') deleteModalRef: ElementRef<any>;

  /**
   * Sets the product unit of measure rate for array.
   */
  @Input() productUnitOfMeasureRatesForm: FormArray;

  /**
   * Sets the name form-array in its parent form.
   * @default 'productUnitOfMeasureRates'
   */
  @Input() formArrayName = 'productUnitOfMeasureRates';

  /**
   * Adds a list of new product unit of measure rate form items to the product unit of measure rates form.
   * @param items The list of product unit of measure rate form items to be added to the product unit of measure rates form.
   */
  @Input() set newProductUnitOfMeasureRates(items: ProductUnitOfMeasureRateItem[]) {
    if (!items?.length) {
      return;
    }

    items.forEach((item) => this.createProductUnitOfMeasureRateFormGroup(item));
  }

  /**
   * Sets a value indicates whether if the user should specify a product unit of measure rate or not.
   * @default false
   */
  @Input() isProductUnitOfMeasureRateRequired = false;

  /**
   * Indicates whether if we can add product unit of measure rate or not.
   *  @default `true`.
   */
  @Input() allowAddProductUnitOfMeasureRate = true;

  /**
   * Indicates whether if we can clear product unit of measure rate or not.
   * @default `true`.
   */
  @Input() allowClearProductUnitOfMeasureRates = true;

  /**
   * The decimal mask.
   */
  readonly DECIMAL_MASK = APP_CONSTANTS.numeric.decimal.mask;

  /**
   * Shows or hide the UnitOfMeasure list in search.
   */
  unitsOfMeasureListVisibility = false;

  /**
   * The list of active product unit of measure rates.
   */
  productUnitOfMeasureRates: ProductUnitOfMeasureRate[] = [];

  /**
   * The employee product unit of measure rates form.
   */
  productUnitOfMeasureRatesFormGroup: FormGroup;

  /**
   * The set of subscriptions on this components,
   * these subscriptions must be unsubscribed before this component got destroyed.
   */
  subscriptions = new Subscription();

  /**
   * @param modalService The modal service.
   */
  constructor(private modalService: NgbModal) {}

  /**
   * Creates & adds a new product unit of measure rate form-group with validations.
   */
  addProductUnitOfMeasureRate() {
    this.unitsOfMeasureListVisibility = true;
  }

  /**
   * Open confirm delete all product unit of measure rate modal .
   */
  openDeleteModal() {
    this.openModal(this.deleteModalRef);
  }

  /**
   * Opens the modal of the given templateRef.
   * @param modalRef The modal templateRef to be opened.
   */
  openModal(modalRef) {
    this.modalService.open(modalRef);
  }

  /**
   * Remove the product unit of measure rate at the given index from the product unit of measure rate form.
   * @param index The index of the product unit of measure rate form group.
   */
  removeProductUnitOfMeasureRate(index: number) {
    this.productUnitOfMeasureRatesForm.removeAt(index);
  }

  /**
   * Remove all of the list of product unit of measure rate.
   *
   * It only adds one product unit of measure rate for quick start.
   */
  resetProductUnitOfMeasureRates() {
    this.productUnitOfMeasureRatesForm.clear();
  }

  /**
   * Adds the newly selected unitOfMeasure the unitOfMeasure search list.
   * @param unitOfMeasure The list of newly selected unitOfMeasure to be added.
   */
  selectUnitOfMeasure(unitOfMeasures: UnitOfMeasure[]) {
    if (unitOfMeasures) {
      unitOfMeasures.map((unitOfMeasure) =>
        this.createProductUnitOfMeasureRateFormGroup(
          this.createProductUnitOfMeasureRateFormItemFromProductUnitOfMeasureRate(unitOfMeasure)
        )
      );
    }
  }

  /**
   * Creates and returns a new product unit of measure rate form item from a given product unit of measure rate.
   * @param productUnitOfMeasureRate The product unit of measure rate to be used to create the product unit of measure rate form item.
   */
  createProductUnitOfMeasureRateFormItemFromProductUnitOfMeasureRate(
    unitOfMeasure: UnitOfMeasure
  ): ProductUnitOfMeasureRateItem {
    return {
      unitOfMeasureId: unitOfMeasure.id,
      unitOfMeasure: unitOfMeasure,
      rate: null,
    };
  }

  /**
   * Create a new product unit of measure rate form group from the provided product unit of measure rate form item.
   * @param productUnitOfMeasureRate The product unit of measure rate form item that contains data about the new product unit of measure rate.
   */
  createProductUnitOfMeasureRateFormGroup(productUnitOfMeasureRate: ProductUnitOfMeasureRateItem) {
    if (this.isUnitOfMeasureExists(productUnitOfMeasureRate.unitOfMeasure.id)) {
      return;
    }

    const formGroup = new FormGroup({
      unitOfMeasureId: new FormControl(productUnitOfMeasureRate?.unitOfMeasureId ?? null),
      unitOfMeasure: new FormControl(productUnitOfMeasureRate.unitOfMeasure),
      rate: new FormControl(0, [Validators.required, CustomValidators.gte(0)]),
    });

    this.productUnitOfMeasureRatesForm.push(formGroup);
  }

  /**
   * Indicates whether if the given unitOfMeasure id is already exists in the form array or not.
   * @param unitOfMeasureId The id of the unitOfMeasure.
   * @returns `boolean`.
   */
  isUnitOfMeasureExists(unitOfMeasureId: number): boolean {
    return (this.productUnitOfMeasureRatesForm.value as ProductUnitOfMeasureRateItem[]).some(
      (productUnitOfMeasureRateItem) => productUnitOfMeasureRateItem.unitOfMeasureId === unitOfMeasureId
    );
  }
}
