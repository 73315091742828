import { Component, Input } from '@angular/core';
import { Location } from '@angular/common';

/**
 * The employee loan pages.
 */
enum PAGES {
  loans = 'loans',
  settlements = 'settlements',
}

@Component({
  selector: 'app-loan-view',
  templateUrl: './loan-view.component.html',
  styles: [],
})
export class LoanViewComponent {
  /**
   * Sets the employee id.
   */
  @Input() employeeId: number;

  /**
   * Gets or sets the selected page.
   * @default 'loans'
   */
  activePage: PAGES = PAGES.loans;

  /**
   * @param locationService The location service.
   */
  constructor(private locationService: Location) {}

  /**
   * Updates the browser url according to the selected page.
   * @param page The newly selected page.
   */
  selectedPageChanged(page: PAGES) {
    this.locationService.replaceState(`${this.locationService.path()}#${PAGES[page]}`);
  }
}
