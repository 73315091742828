import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, FormArray, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { skip, tap } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Decimal } from 'decimal.js';

import { CanComponentDeactivate } from 'auth/models';
import * as fromFinancesStore from 'finances/store';
import {
  APP_CONSTANTS,
  CustomValidators,
  NotificationMessage,
  NotificationService,
  PageInfo,
  TranslationService,
} from 'shared';
import { CreateJournalLineInput, JournalLineFormItem, Account, BankAccount } from 'finances/models';

/**
 * The payment pages.
 */
enum PAGES {
  details = 'details',
  journal = 'journal',
}

@Component({
  selector: 'app-create-bank-account-deposit-by-employee',
  templateUrl: './create-bank-account-deposit-by-employee.component.html',
  styles: [],
})
export class CreateBankAccountDepositByEmployeeComponent implements OnInit, OnDestroy, CanComponentDeactivate {
  /**
   * The confirm modal template reference.
   */
  @ViewChild('confirmModalRef') confirmModalRef: ElementRef<any>;

  /**
   * The reset modal template reference.
   */
  @ViewChild('resetModalRef') resetModalRef: ElementRef<any>;

  /**
   * The decimal mask.
   */
  readonly DECIMAL_MASK = APP_CONSTANTS.numeric.decimal.mask;

  /**
   * Gets or sets the information about the current page.
   */
  pageInfo: PageInfo = {
    title: 'FINANCES.BANK_ACCOUNTS.CREATE_BANK_ACCOUNT_BY_EMPLOYEE_PAGE_TITLE',
    icon: 'fa fa-plus',
  };

  /**
   * Gets or sets the selected page.
   * @default 'details'
   */
  activePage: PAGES = PAGES.details;

  /**
   * The list of bank accounts.
   */
  bankAccounts: BankAccount[] = [];

  /**
   * The id of the bank account.
   */
  bankAccountId: number;

  /**
   * The name of the bank account.
   */
  bankAccountName: string;

  /**
   * The name of the currency.
   */
  currencyName: string;

  /**
   * Indicates whether there is a create payment process is running or not.
   */
  isCreating$: Observable<boolean>;

  /**
   * Shows or hide the bank accounts list.
   */
  bankAccountsListVisibility = false;

  /**
   * Gets the cash in banks account.
   */
  cashInBanksAccount: Account;

  /**
   * Gets the receivables staff account.
   */
  receivablesStaffAccount: Account;

  /**
   * The create customer payments form.
   */
  form: FormGroup;

  /**
   * The set of subscriptions on this components,
   * these subscriptions must be unsubscribed before this component got destroyed.
   */
  subscriptions = new Subscription();

  /**
   * Sets the initial journal lines should be added to the journals form.
   * @param journal lines An object contains `count` of initial blank journal lines.
   * @param journal lines A single journal line details to be added to the form.
   * @param journal lines A list of journal lines details to be added to the form.
   */
  initialJournalLinesSubject: BehaviorSubject<
    { count: number } | JournalLineFormItem | JournalLineFormItem[]
  > = new BehaviorSubject(undefined);

  /**
   * Gets the attachments form-array.
   */
  get attachmentsForm(): FormArray {
    return this.form?.controls.attachments as FormArray;
  }

  /**
   * Gets the journal-lines form-array.
   */
  get journalLinesForm(): FormArray {
    return this.form?.controls.lines as FormArray;
  }

  /**
   * Gets or sets the current selected language.
   */
  get currentLang() {
    return this.translationService.language;
  }

  /**
   * @param modalService The modal service.
   * @param notificationService the notification service.
   * @param financesStore$ the finances-store module.
   * @param translationService the translation service.
   * @param route The activated route.
   */
  constructor(
    private modalService: NgbModal,
    private notificationService: NotificationService,
    private financesStore$: Store<fromFinancesStore.FinancesState>,
    private translationService: TranslationService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.init();
  }

  /**
   * Destroy component data
   */
  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  /**
   * Initialize component data.
   */
  init() {
    this.initForm();
    this.initJournalData();

    /**
     * Load data.
     */
    this.isCreating$ = this.financesStore$.pipe(
      select(fromFinancesStore.getSelectedBankAccountLogDepositByEmployeeCreating)
    );

    /**
     * Reset form controls when new bank account log is created.
     */
    this.subscriptions.add(
      this.financesStore$
        .pipe(
          select(fromFinancesStore.getSelectedBankAccountLogDepositByEmployeeCreateCompleted),
          skip(1),
          tap((isCreated) => {
            if (isCreated) {
              this.initForm();
            }
          })
        )
        .subscribe()
    );

    /**
     * Fetch query params params.
     */
    if (this.route.snapshot.queryParamMap.has('bankAccountId')) {
      this.bankAccountId = +this.route.snapshot.queryParams.bankAccountId;
    }

    /**
     * Select the bank account currency.
     */
    this.subscriptions.add(
      this.financesStore$
        .pipe(
          select(fromFinancesStore.getSelectedBankAccount),
          tap((bankAccount) => {
            if (bankAccount) {
              this.bankAccountName = this.currentLang === 'en' ? bankAccount.nameEn : bankAccount.name;
              this.currencyName = this.currentLang === 'en' ? bankAccount.currency.nameEn : bankAccount.currency.name;

              /* Update page info. */
              this.pageInfo = {
                ...this.pageInfo,
                subTitle: `(${this.bankAccountName})`,
              };
            }
          })
        )
        .subscribe()
    );
  }

  /**
   * Initialize forms and add validators.
   */
  initForm() {
    const valueFormControl = new FormControl('', [Validators.required, Validators.minLength(0)]);

    this.form = new FormGroup({
      value: valueFormControl,
      extraDetails: new FormControl('', [Validators.minLength(0), Validators.maxLength(100)]),
      transactionDate: new FormControl(null),
      notes: new FormControl('', [Validators.minLength(0), Validators.maxLength(200)]),
      attachments: new FormArray([]),
      lines: new FormArray([], CustomValidators.arrayItems(2)),
    });

    this.addBlankAttachment();
    this.setBlankJournalsCount();

    /**
     * Subscription when value changes.
     */
    this.subscriptions.add(valueFormControl.valueChanges.subscribe(() => this.onValueChanges()));
  }

  /**
   * Initialize the journal data.
   */
  initJournalData() {
    /**
     * Fetch system accounts then fill the journal line form with its lines.
     */

    let isManualSearchTriggeredBeforeForCashInBanksAccount = false;
    this.subscriptions.add(
      /**
       * Account Cash In Bank Account.
       */
      this.financesStore$
        .pipe(
          select(fromFinancesStore.getCashInBanksAccount),
          tap((account) => {
            this.cashInBanksAccount = account;
            if (account) {
              this.onValueChanges();
            } else if (!account && !isManualSearchTriggeredBeforeForCashInBanksAccount) {
              isManualSearchTriggeredBeforeForCashInBanksAccount = true;
              this.financesStore$.dispatch(new fromFinancesStore.FindCashInBanksAccount());
            }
          })
        )
        .subscribe()
    );

    let isManualSearchTriggeredBeforeForReceivablesStaffAccount = false;
    this.subscriptions.add(
      /**
       * receivables staff Account.
       */
      this.financesStore$
        .pipe(
          select(fromFinancesStore.getReceivablesStaffAccount),
          tap((account) => {
            this.receivablesStaffAccount = account;
            if (account) {
              this.onValueChanges();
            } else if (!account && !isManualSearchTriggeredBeforeForReceivablesStaffAccount) {
              isManualSearchTriggeredBeforeForReceivablesStaffAccount = true;
              this.financesStore$.dispatch(new fromFinancesStore.FindReceivablesStaffAccount());
            }
          })
        )
        .subscribe()
    );
  }

  /**
   * Handles the change on `form.lines` form array or any form (group or control) within it.
   */
  onValueChanges() {
    const value = this.form.controls.value.value ?? 0;
    /**
     * Set Journal Lines based on the entered refund request lines data.
     */
    this.resetJournalLines();

    /**
     * The list of journal lines to be added to the form.
     */
    const lines: JournalLineFormItem[] = [];

    /**
     * Add line for Cash In Bank Account.
     */
    if (this.cashInBanksAccount) {
      lines.push({
        accountId: this.cashInBanksAccount.id,
        account: this.cashInBanksAccount,
        costCenterId: null,
        credit: 0,
        debit: value,
        notes: '',
        disableAccountChange: true,
        disableDebitAndCreditValueChange: true,
      });
    }

    /**
     * Add line for receivables staff Account.
     */
    if (this.receivablesStaffAccount) {
      lines.push({
        accountId: this.receivablesStaffAccount.id,
        account: this.receivablesStaffAccount,
        costCenterId: null,
        credit: value,
        debit: 0,
        notes: '',
        disableAccountChange: true,
        disableDebitAndCreditValueChange: true,
      });
    }

    this.initialJournalLinesSubject.next(lines);
  }

  /**
   * Adds a blank attachment form-control to the attachments form for quick start..
   */
  addBlankAttachment() {
    this.attachmentsForm.push(new FormControl());
  }

  /**
   * Sets the count of the journal lines should be added to the journals form for quick start..
   */
  setBlankJournalsCount() {
    this.initialJournalLinesSubject.next({ count: 0 });
  }

  /**
   * Resets journal lines data.
   */
  resetJournalLines() {
    this.journalLinesForm.clear();
  }

  /**
   * Opens the modal of the given templateRef.
   * @param modalRef The modal templateRef to be opened.
   */
  openModal(modalRef) {
    this.modalService.open(modalRef);
  }

  /**
   * Open confirm reset invoice modal .
   */
  openResetModal() {
    this.openModal(this.resetModalRef);
  }

  /**
   * Resets all form data.
   *
   * It only adds one product for quick start.
   */
  resetForm() {
    this.form.reset({ extraDetails: '', notes: '', transactionDate: {} });
    this.attachmentsForm.clear();
    this.addBlankAttachment();
    this.resetJournalLines();
  }

  /**
   * Submits the form.
   */
  submit() {
    let errorMessage = new NotificationMessage();

    if (this.form.invalid) {
      if (this.form.get('value').invalid) {
        errorMessage.title = this.translationService.translate(
          'FINANCES.BANK_ACCOUNTS.BANK_ACCOUNT_DATA_VALIDATION.VALUE_BY_EMPLOYEE_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate(
            'FINANCES.BANK_ACCOUNTS.BANK_ACCOUNT_DATA_VALIDATION.VALUE_BY_EMPLOYEE_IS_REQUIRED'
          ),
        ];
        this.activePage = PAGES.details;
      } else if (this.form.get('notes').invalid) {
        errorMessage.title = this.translationService.translate(
          'FINANCES.BANK_ACCOUNTS.BANK_ACCOUNT_DATA_VALIDATION.NOTES_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate('FINANCES.BANK_ACCOUNTS.BANK_ACCOUNT_DATA_VALIDATION.NOTES_LENGTH_ERROR'),
        ];
        this.activePage = PAGES.details;
      } else if (this.form.get('lines').invalid) {
        /**
         * Check if journal lines count < 2.
         */
        if (this.journalLinesForm.controls.length < 2) {
          errorMessage.title = this.translationService.translate(
            'FINANCES.BANK_ACCOUNTS.BANK_ACCOUNT_DATA_VALIDATION.JOURNALS_LINES_ERROR'
          );
          errorMessage.body = [
            this.translationService.translate(
              'FINANCES.BANK_ACCOUNTS.BANK_ACCOUNT_DATA_VALIDATION.JOURNALS_LINES_COUNT_ERROR'
            ),
          ];
        } else {
          /**
           * Check if some of journals has errors.
           */
          for (let index = 0; index < this.journalLinesForm.controls.length; index++) {
            const journal = this.journalLinesForm.controls[index];

            if (journal.valid) {
              continue;
            }

            errorMessage.title = this.translationService.translate(
              'FINANCES.BANK_ACCOUNTS.BANK_ACCOUNT_DATA_VALIDATION.JOURNAL_LINE_NUMBER_ERROR',
              {
                journalNumber: index + 1,
              }
            );
            errorMessage.body = [];

            if (journal.get('accountId').invalid) {
              errorMessage.body.push(
                this.translationService.translate('FINANCES.BANK_ACCOUNTS.BANK_ACCOUNT_DATA_VALIDATION.ACCOUNT_ERROR')
              );
              errorMessage.body.push(
                this.translationService.translate(
                  'FINANCES.BANK_ACCOUNTS.BANK_ACCOUNT_DATA_VALIDATION.ACCOUNT_IS_REQUIRED'
                )
              );
            }

            if (journal.get('credit').invalid) {
              errorMessage.body.push(
                this.translationService.translate(
                  'FINANCES.BANK_ACCOUNTS.BANK_ACCOUNT_DATA_VALIDATION.CREDIT_IS_REQUIRED'
                )
              );
            }

            if (journal.get('debit').invalid) {
              errorMessage.body.push(
                this.translationService.translate(
                  'FINANCES.BANK_ACCOUNTS.BANK_ACCOUNT_DATA_VALIDATION.DEBIT_IS_REQUIRED'
                )
              );
            }

            if (journal.get('costCenterId').invalid) {
              errorMessage.body.push(
                this.translationService.translate(
                  'FINANCES.BANK_ACCOUNTS.BANK_ACCOUNT_DATA_VALIDATION.COST_CENTER_ERROR'
                )
              );
              errorMessage.body.push(
                this.translationService.translate(
                  'FINANCES.BANK_ACCOUNTS.BANK_ACCOUNT_DATA_VALIDATION.COST_CENTER_IS_REQUIRED'
                )
              );
            }

            if (journal.get('notes').invalid) {
              errorMessage.body.push(
                this.translationService.translate('FINANCES.BANK_ACCOUNTS.BANK_ACCOUNT_DATA_VALIDATION.NOTES_ERROR')
              );
              errorMessage.body.push(
                this.translationService.translate(
                  'FINANCES.BANK_ACCOUNTS.BANK_ACCOUNT_DATA_VALIDATION.NOTES_IS_REQUIRED'
                )
              );
              errorMessage.body.push(
                this.translationService.translate(
                  'FINANCES.BANK_ACCOUNTS.BANK_ACCOUNT_DATA_VALIDATION.NOTES_REQUIRED_LENGTH_ERROR'
                )
              );
            }

            break;
          }
        }

        this.activePage = PAGES.journal;
      }

      this.form.markAllAsTouched();
      return this.notificationService.warningWithTitle(errorMessage);
    }

    errorMessage = new NotificationMessage();
    const lines: JournalLineFormItem[] = this.journalLinesForm.value;

    if (
      lines.some((line) => line.debit === 0 && line.credit === 0) ||
      lines.some((line) => line.debit !== 0 && line.credit !== 0)
    ) {
      errorMessage.title = this.translationService.translate(
        'FINANCES.BANK_ACCOUNTS.BANK_ACCOUNT_DATA_VALIDATION.JOURNALS_LINES_TITLE_ERROR'
      );
      errorMessage.body = [
        this.translationService.translate('FINANCES.BANK_ACCOUNTS.BANK_ACCOUNT_DATA_VALIDATION.DEBIT_CREDIT_ERROR'),
        this.translationService.translate(
          'FINANCES.BANK_ACCOUNTS.BANK_ACCOUNT_DATA_VALIDATION.JOURNALS_LINES_TYPE_ERROR'
        ),
      ];
      this.activePage = PAGES.journal;

      this.form.markAllAsTouched();
      return this.notificationService.warningWithTitle(errorMessage);
    } else if (
      !Decimal.sum(0, ...lines.filter((line) => !!line.debit).map((line) => line.debit)).eq(
        Decimal.sum(0, ...lines.filter((line) => !!line.credit).map((line) => line.credit))
      )
    ) {
      errorMessage.title = this.translationService.translate(
        'FINANCES.BANK_ACCOUNTS.BANK_ACCOUNT_DATA_VALIDATION.JOURNALS_LINES_TOTAL_ERROR'
      );
      errorMessage.body = [
        this.translationService.translate(
          'FINANCES.BANK_ACCOUNTS.BANK_ACCOUNT_DATA_VALIDATION.JOURNALS_LINES_TOTAL_VALUE_ERROR'
        ),
      ];
      this.activePage = PAGES.journal;

      this.form.markAllAsTouched();
      return this.notificationService.warningWithTitle(errorMessage);
    }

    this.openModal(this.confirmModalRef);
  }

  /**
   * Confirms the form submit.
   */
  confirm() {
    let fromDateFormatted;
    const { transactionDate } = this.form.value;

    if (transactionDate) {
      fromDateFormatted = new Date(transactionDate.year, transactionDate.month - 1, transactionDate.day);
      fromDateFormatted.setHours(new Date().getHours(), new Date().getMinutes());
    }

    /**
     * The list of lines in the lines form.
     */
    const lines = this.journalLinesForm.value
      .filter((line: JournalLineFormItem) => line.credit > 0 || line.debit > 0)
      .map((line) => {
        const inputLine: CreateJournalLineInput = {
          accountId: line.accountId,
          debit: line.debit,
          credit: line.credit,
          costCenterId: line.costCenterId,
          notes: line.notes,
        };

        return inputLine;
      });

    this.financesStore$.dispatch(
      new fromFinancesStore.CreateBankAccountLogDepositByEmployee({
        bankAccountId: this.bankAccountId,
        value: this.form.value.value,
        extraDetails: this.form.value.extraDetails,
        notes: this.form.value.notes,
        transactionDate: fromDateFormatted,
        lines,
        attachments: this.attachmentsForm.value.filter((file) => !!file),
      })
    );
  }

  /**
   * Confirms leaving the page before routing.
   */
  confirmDeactivate() {
    if (this.form.dirty === true) {
      /**
       * Message to warn the user before leaving the detailed form.
       */
      return confirm(this.translationService.translate('SHARED.CONFIRM.LEAVING'));
    }
    return true;
  }
}
