<!-- journal petty cash refund details starts -->
<div class="row">
  <div class="col-md-12">
    <div class="card-header mb-3 pt-0">
      <h4 class="card-title">{{ 'FINANCES.JOURNALS.JOURNAL_DATA.PETTY_CASH_REFUND_DATA' | translate }}</h4>
    </div>
    <div class="card-content">
      <div class="px-3">
        <div class="row">
          <div class="form-group col-12">
            <label for="pettyCashRefund.pettyCash.description">{{
              'FINANCES.JOURNALS.JOURNAL_DATA.PETTY_CASH_REFUND' | translate
            }}</label>
            <input
              type="text"
              id="pettyCashRefund.pettyCash.description"
              class="form-control round"
              [placeholder]="'FINANCES.JOURNALS.JOURNAL_DATA.PETTY_CASH_REFUND_PLACEHOLDER' | translate"
              readonly
              [value]="pettyCashRefund.pettyCash.description"
            />
          </div>
          <div class="form-group col-12">
            <label for="pettyCashRefund.value">{{
              'FINANCES.JOURNALS.JOURNAL_DATA.PETTY_CASH_REFUND_VALUE' | translate
            }}</label>
            <input
              type="text"
              id="pettyCashRefund.value"
              class="form-control round"
              [placeholder]="'FINANCES.JOURNALS.JOURNAL_DATA.PETTY_CASH_REFUND_VALUE_PLACEHOLDER' | translate"
              readonly
              [value]="pettyCashRefund.value | toDecimal"
            />
          </div>
          <div class="form-group col-12">
            <label for="pettyCashRefund.pettyCash.value">{{
              'FINANCES.JOURNALS.JOURNAL_DATA.CURRENT_PETTY_CASH_REFUND_VALUE' | translate
            }}</label>
            <input
              type="text"
              id="pettyCashRefund.pettyCash.value"
              class="form-control round"
              [placeholder]="'FINANCES.JOURNALS.JOURNAL_DATA.CURRENT_PETTY_CASH_REFUND_VALUE_PLACEHOLDER' | translate"
              readonly
              [value]="pettyCashRefund.pettyCash.value | toDecimal"
            />
          </div>
          <div class="form-group col-md-12">
            <label for="pettyCashRefund.notes">{{ 'FINANCES.JOURNALS.JOURNAL_DATA.NOTES' | translate }}</label>
            <textarea
              id="pettyCashRefund.notes"
              class="form-control round"
              [innerText]="pettyCashRefund.notes"
              [placeholder]="'FINANCES.JOURNALS.JOURNAL_DATA.NOTES_NOT_FOUND' | translate"
              readonly
            ></textarea>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- journal petty cash refund details ends -->
