import { CreateProductComponent } from 'stores/components/products/create-product/create-product.component';
import { CreateProductInModalComponent } from 'stores/components/products/create-product-in-modal/create-product-in-modal.component';
import { UpdateProductComponent } from 'stores/components/products/update-product/update-product.component';
import { ProductsSearchComponent } from 'stores/components/products/products-search/products-search.component';
import { ProductsListComponent } from 'stores/components/products/products-list/products-list.component';
import { ProductStockQueryComponent } from 'stores/components/products/product-stock-query/product-stock-query.component';
import { ProductsFormComponent } from 'stores/components/products/products-form/products-form.component';
import { ProductIngredientsFormComponent } from 'stores/components/products/product-ingredients-form/product-ingredients-form.component';
import { ProductIngredientsFormItemComponent } from 'stores/components/products/product-ingredients-form-item/product-ingredients-form-item.component';
import { SubProductsFormComponent } from 'stores/components/products/sub-products-form/sub-products-form.component';
import { SubProductsFormItemComponent } from 'stores/components/products/sub-products-form-item/sub-products-form-item.component';
import { ProductSubProductsFormComponent } from 'stores/components/products/product-sub-products-form/product-sub-products-form.component';
import { SubProductIngredientsFormComponent } from 'stores/components/products/sub-product-ingredients-form/sub-product-ingredients-form.component';
import { SubProductIngredientsFormItemComponent } from 'stores/components/products/sub-product-ingredients-form-item/sub-product-ingredients-form-item.component';
import { ProductSalesDiscountsComponent } from 'stores/components/products/product-sales-discounts/product-sales-discounts.component';
import { ProductSalesDiscountsListComponent } from 'stores/components/products/product-sales-discounts-list/product-sales-discounts-list.component';
import { ProductSalesDiscountsFormComponent } from 'stores/components/products/product-sales-discounts-form/product-sales-discounts-form.component';
import { UpdateProductSalesDiscountsComponent } from 'stores/components/products/update-product-sales-discounts/update-product-sales-discounts.component';
import { ProductUnitOfMeasureRateFormComponent } from 'stores/components/products/product-unit-of-measure-rate-form/product-unit-of-measure-rate-form.component';
import { UpdateProductUnitOfMeasureRatesComponent } from 'stores/components/products/update-product-unit-of-measure-rate/update-product-unit-of-measure-rate.component';

export * from 'stores/components/products/create-product/create-product.component';
export * from 'stores/components/products/create-product-in-modal/create-product-in-modal.component';
export * from 'stores/components/products/update-product/update-product.component';
export * from 'stores/components/products/products-list/products-list.component';
export * from 'stores/components/products/products-search/products-search.component';
export * from 'stores/components/products/product-stock-query/product-stock-query.component';
export * from 'stores/components/products/products-form/products-form.component';
export * from 'stores/components/products/product-ingredients-form-item/product-ingredients-form-item.component';
export * from 'stores/components/products/product-ingredients-form/product-ingredients-form.component';
export * from 'stores/components/products/sub-products-form/sub-products-form.component';
export * from 'stores/components/products/sub-products-form-item/sub-products-form-item.component';
export * from 'stores/components/products/product-sub-products-form/product-sub-products-form.component';
export * from 'stores/components/products/sub-product-ingredients-form-item/sub-product-ingredients-form-item.component';
export * from 'stores/components/products/sub-product-ingredients-form/sub-product-ingredients-form.component';
export * from 'stores/components/products/product-sales-discounts/product-sales-discounts.component';
export * from 'stores/components/products/product-sales-discounts-list/product-sales-discounts-list.component';
export * from 'stores/components/products/product-sales-discounts-form/product-sales-discounts-form.component';
export * from 'stores/components/products/update-product-sales-discounts/update-product-sales-discounts.component';
export * from 'stores/components/products/product-unit-of-measure-rate-form/product-unit-of-measure-rate-form.component';
export * from 'stores/components/products/update-product-unit-of-measure-rate/update-product-unit-of-measure-rate.component';

/**
 * The set of components registered on this module.
 */
export const COMPONENTS: any[] = [
  CreateProductComponent,
  CreateProductInModalComponent,
  UpdateProductComponent,
  ProductsSearchComponent,
  ProductsListComponent,
  ProductStockQueryComponent,
  ProductsFormComponent,
  ProductIngredientsFormComponent,
  ProductIngredientsFormItemComponent,
  SubProductsFormComponent,
  SubProductsFormItemComponent,
  ProductSubProductsFormComponent,
  SubProductIngredientsFormComponent,
  SubProductIngredientsFormItemComponent,
  ProductSalesDiscountsComponent,
  ProductSalesDiscountsListComponent,
  ProductSalesDiscountsFormComponent,
  UpdateProductSalesDiscountsComponent,
  ProductUnitOfMeasureRateFormComponent,
  UpdateProductUnitOfMeasureRatesComponent,
];
