/**
 * The set of system supported primary accounts.
 */
export enum PrimaryAccounts {
  /**
   * The current assets primary account.
   */
  CURRENT_ASSETS = 'CURRENT_ASSETS',

  /**
   * The fixed assets primary account.
   */
  FIXED_ASSETS = 'FIXED_ASSETS',

  /**
   * The short term liabilities primary account.
   */
  SHORT_TERM_LIABILITIES = 'SHORT_TERM_LIABILITIES',

  /**
   * The long term liabilities primary account.
   */
  LONG_TERM_LIABILITIES = 'LONG_TERM_LIABILITIES',

  /**
   * The property rights primary account.
   */
  PROPERTY_RIGHTS = 'PROPERTY_RIGHTS',

  /**
   * The activities revenue primary account.
   */
  ACTIVITIES_REVENUE = 'ACTIVITIES_REVENUE',

  /**
   * The other revenue primary account.
   */
  OTHER_REVENUE = 'OTHER_REVENUE',

  /**
   * The expenses primary account.
   */
  EXPENSES = 'EXPENSES',
}
