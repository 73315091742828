import { Component, ElementRef, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import { Observable, Subscription } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store, select } from '@ngrx/store';
import { skip, tap } from 'rxjs/operators';

import * as fromSalesStore from 'sales/store';
import * as fromAuthStore from 'auth/store';
import {
  APP_CONSTANTS,
  CustomValidators,
  NotificationMessage,
  NotificationService,
  PageInfo,
  PaginationInfo,
  TranslationService,
} from 'shared';
import { Shift } from 'sales/models';
import { Location } from 'stores/models';
import { Claims, User } from 'security/models';

@Component({
  selector: 'app-shifts-list',
  templateUrl: './shifts-list.component.html',
  styles: [],
})
export class ShiftsListComponent implements OnInit, OnDestroy {
  /**
   * The close modal template reference.
   */
  @ViewChild('closeModalRef') closeModalRef: ElementRef<any>;

  /**
   * The create modal template reference.
   */
  @ViewChild('createModalRef') createModalRef: ElementRef<any>;

  /**
   * The decimal mask.
   */
  readonly DECIMAL_MASK = APP_CONSTANTS.numeric.decimal.mask;

  /**
   * Gets or sets the information about the current page.
   */
  pageInfo: PageInfo = {
    title: 'SALES.SHIFTS.ALL_SHIFTS_DATA_TITLE',
    icon: 'fa fa-calendar',
  };

  /**
   * The system supported user claims.
   */
  Claims = Claims;

  /**
   * Shows or hide the locations list.
   */
  locationsListVisibility = false;

  /**
   * The list of selected locations.
   */
  locations: Location[] = [];

  /**
   * Shows or hide the locations list.
   */
  createShiftLocationsListVisibility = false;

  /**
   * The list of selected locations.
   */
  createShiftLocations: Location[] = [];

  /**
   * The list of shifts.
   */
  shifts$: Observable<Shift[]>;

  /**
   * The pagination info.
   */
  paginationInfo$: Observable<PaginationInfo>;

  /**
   * Indicates whether there is a search process is running or not.
   */
  isSearching$: Observable<boolean>;

  /**
   * Indicates whether there is a create shift process is running or not.
   */
  isShiftCreating$: Observable<boolean>;

  /**
   * Indicates whether there is a close shift log process is running or not.
   */
  isCancelling$: Observable<boolean>;

  /**
   * Indicates whether there is a delete-organization process is running or not.
   */
  isDeleting$: Observable<boolean>;

  /**
   * The current logged in user id.
   */
  currentLoggedInUserId: number;

  /**
   * The search form.
   */
  searchForm: FormGroup;

  /**
   * The close form.
   */
  closeForm: FormGroup;

  /**
   * The set of subscriptions on this components,
   * these subscriptions must be unsubscribed before this component got destroyed.
   */
  subscriptions = new Subscription();

  /**
   * The create form.
   */
  createForm: FormGroup;

  /**
   * Gets or sets the currently selected shift for close.
   */
  selectedShift: Shift;

  /**
   * Shows or hide the users list.
   */
  usersListVisibility = false;

  /**
   * Shows or hide the users list.
   */
  userListVisibility = false;

  /**
   * The list of selected organization users.
   */
  organizationUsers: User[] = [];

  /** The initial `fromDate` value for search. */
  initialFromDate = new Date();

  /** The initial `toDate` value for search.
   * It's the current day of month.
   */
  initialToDate = new Date();

  /**
   * @param modalService The modal service.
   * @param notificationService The  notification service.
   * @param translationService The translation service.
   * @param salesStore$ The sales-module store.
   * @param authStore$ The auth-module store.
   */
  constructor(
    private modalService: NgbModal,
    private notificationService: NotificationService,
    private translationService: TranslationService,
    private salesStore$: Store<fromSalesStore.SalesState>,
    private authStore$: Store<fromAuthStore.AuthState>
  ) {}

  ngOnInit(): void {
    this.init();
  }

  /**
   * Destroy component data
   */
  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  /**
   * Initialize component data.
   */
  init() {
    this.initForm();

    /**
     * reset on customer engagement creation complete
     */
    this.subscriptions.add(
      this.salesStore$
        .pipe(
          select(fromSalesStore.getShiftForCasherStartCompleted),
          skip(1),
          tap((created) => {
            if (created) {
              this.initForm();
              this.createForm.markAsUntouched();
              this.closeModal();
            }
          })
        )
        .subscribe()
    );

    /** Load current logged in user data. */
    this.subscriptions.add(
      this.authStore$
        .pipe(
          select(fromAuthStore.getSessionUser),
          tap((user: User) => {
            this.currentLoggedInUserId = user.id;
          })
        )
        .subscribe()
    );

    /**
     * Loads data.
     */

    this.isShiftCreating$ = this.salesStore$.pipe(select(fromSalesStore.getShiftForCasherStarting));
    this.isCancelling$ = this.salesStore$.pipe(select(fromSalesStore.getSelectedShiftCanceling));
    this.isDeleting$ = this.salesStore$.pipe(select(fromSalesStore.getSelectedShiftDeleting));
    this.isSearching$ = this.salesStore$.pipe(select(fromSalesStore.getAllShiftsSearching));

    let isManualSearchTriggeredBeforeForShifts = false;
    this.shifts$ = this.salesStore$.pipe(
      select(fromSalesStore.getShifts),
      tap((shifts) => {
        if (!isManualSearchTriggeredBeforeForShifts && !shifts.length) {
          isManualSearchTriggeredBeforeForShifts = true;
          this.search();
        }
      })
    );

    /* Subscribe to selected location to select it whenever it changed. */
    this.subscriptions.add(
      this.authStore$
        .pipe(
          select(fromAuthStore.getSessionUserDefaultLocation),
          tap((location) => {
            if (location) {
              this.selectLocation([location]);
              if (!this.searchForm.value.locations.includes(location.id)) {
                this.selectLocations([location]);
              }
            }
          })
        )
        .subscribe()
    );

    this.paginationInfo$ = this.salesStore$.pipe(select(fromSalesStore.getMyShiftsPaginationInfo));
  }

  /**
   * Initialize form and add validators.
   */
  initForm() {
    this.searchForm = new FormGroup({
      users: new FormControl([]),
      locations: new FormControl([]),
      fromDate: new FormControl({
        year: this.initialFromDate.getFullYear(),
        month: this.initialFromDate.getMonth() + 1,
        day: this.initialFromDate.getDate(),
      }),
      toDate: new FormControl({
        year: this.initialToDate.getFullYear(),
        month: this.initialToDate.getMonth() + 1,
        day: this.initialToDate.getDate(),
      }),
      page: new FormControl(1),
    });

    this.createForm = new FormGroup({
      casherId: new FormControl(null, Validators.required),
      locationId: new FormControl(null, Validators.required),
    });

    this.closeForm = new FormGroup({
      cashValue: new FormControl('', [Validators.required, CustomValidators.gte(0)]),
    });
  }

  /**
   * Handles search parameters change.
   */
  search(event?: KeyboardEvent) {
    if (event && event.key === 'Enter') {
      event.preventDefault();
      return;
    }

    this.applyFiltersAndSearch();
  }

  /**
   * Handles pagination page-changed event.
   * @param page The current selected page number.
   */
  pageChanged(page: number) {
    /** Update pagination page. */
    this.searchForm.patchValue({ page });

    this.applyFiltersAndSearch(page);
  }

  applyFiltersAndSearch(page: number = 1) {
    if (!this.searchForm.get('fromDate')?.value || !this.searchForm.get('toDate')?.value) {
      return this.notificationService.warning(
        this.translationService.translate('SALES.SHIFTS.NOTIFICATION_MESSAGE.DATE_SELECT')
      );
    }

    const { fromDate, toDate, users, locations } = this.searchForm.value;

    const fromDateFormatted = new Date(fromDate.year, fromDate.month - 1, fromDate.day);
    fromDateFormatted.setHours(0, 0, 0, 0);

    const toDateFormatted = new Date(toDate.year, toDate.month - 1, toDate.day);
    toDateFormatted.setHours(23, 59, 59, 0);

    this.salesStore$.dispatch(
      new fromSalesStore.SearchShifts({
        users,
        locations,
        fromDate: fromDateFormatted,
        toDate: toDateFormatted,
        page,
      })
    );
  }

  /**
   * Enables trackBy feature for *ngFor utility to track items
   * depending on a comparer to reduce DOM change.
   * If the item is already exist then no HTML will change, only bounded values.
   * @param index The index of the item.
   * @param shift The item to determine if it was changed or not.
   */
  trackItems(index: number, shift: Shift) {
    return shift ? shift.id : undefined;
  }

  /**
   * Shows create new shift modal.
   */
  create() {
    this.openModal(this.createModalRef);
  }

  /**
   * Creates a new shift only if the filled data passed validations.
   */
  confirmCreate() {
    this.closeModal();
    if (this.createForm.invalid) {
      const errorMessage = new NotificationMessage();

      if (this.createForm.get('casherId').invalid) {
        errorMessage.title = this.translationService.translate('SALES.SHIFTS.SHIFT_DATA_VALIDATION.USER_ERROR');
        errorMessage.body = [this.translationService.translate('SALES.SHIFTS.SHIFT_DATA_VALIDATION.USER_IS_REQUIRED')];
      } else if (this.createForm.get('locationId').invalid) {
        errorMessage.title = this.translationService.translate('SALES.SHIFTS.SHIFT_DATA_VALIDATION.LOCATION_ERROR');
        errorMessage.body = [
          this.translationService.translate('SALES.SHIFTS.SHIFT_DATA_VALIDATION.LOCATION_IS_REQUIRED'),
        ];
      }

      this.createForm.markAllAsTouched();
      return this.notificationService.warningWithTitle(errorMessage);
    } else {
      this.salesStore$.dispatch(new fromSalesStore.StartShiftForCasher(this.createForm.value));
    }
  }

  /**
   * Cancels the shift with the given id.
   */
  close() {
    let errorMessage = new NotificationMessage();

    if (this.closeForm.invalid) {
      if (this.closeForm.get('cashValue').invalid) {
        errorMessage.title = this.translationService.translate('SALES.SHIFTS.CASH_VALUE_ERROR');
        errorMessage.body = [this.translationService.translate('SALES.SHIFTS.CASH_VALUE_IS_REQUIRED')];
      }
      this.closeForm.markAllAsTouched();
      return this.notificationService.warningWithTitle(errorMessage);
    }

    this.salesStore$.dispatch(
      new fromSalesStore.CancelShift({
        shiftId: this.selectedShift.id,
        cashValue: this.closeForm.value.cashValue,
      })
    );
  }

  /**
   * Deletes the shift with the given id.
   * @param shift The id of the shift to be deleted.
   */
  delete(id: number) {
    this.salesStore$.dispatch(new fromSalesStore.DeleteShift(id));
  }

  /**
   * Opens the modal of the given templateRef.
   * @param modalRef The modal templateRef to be opened.
   */
  openModal(modalRef) {
    this.modalService.open(modalRef);
  }

  /**
   * Adds the newly selected users the user search list.
   * @param users The list of newly selected users to be added.
   */
  selectUsers(users: User[]) {
    const selectedIds: number[] = this.searchForm.get('users').value;
    this.organizationUsers = [...this.organizationUsers.filter((user) => selectedIds.includes(user.id)), ...users];
    this.searchForm.patchValue({ users: this.organizationUsers.map((user) => user.id) });
  }

  /**
   * Adds the newly selected locations the locations search list.
   * @param locations The list of newly selected locations to be added.
   */
  selectLocations(locations: Location[]) {
    const selectedIds: number[] = this.searchForm.get('locations').value;
    this.locations = [...this.locations.filter((location) => selectedIds.includes(location.id)), ...locations];
    this.searchForm.patchValue({ locations: this.locations.map((location) => location.id) });
  }

  /**
   * Selects the newly selected user from the users search list.
   * @param users The list of newly selected users to select the only one in the list.
   */
  selectUser([user]: User[]) {
    if (user) {
      this.organizationUsers = [user];
      this.createForm.patchValue({ casherId: user.id });
    }
  }

  /**
   * Closes the currently opened modal.
   */
  closeModal() {
    this.modalService.dismissAll();
  }

  /**
   * Selects the newly selected location from the locations search list.
   * @param locations The list of newly selected locations to select the only one in the list.
   */
  selectLocation([location]: Location[]) {
    if (location) {
      this.createShiftLocations = [location];
      this.createForm.patchValue({ locationId: location.id });
    }
  }
}
