import { Injectable } from '@angular/core';

import { of } from 'rxjs';
import { switchMap, map, catchError } from 'rxjs/operators';
import { Effect, ofType, Actions } from '@ngrx/effects';

import { PAGINATION } from 'shared';
import { PosDevicesService } from 'app-search/services';
import {
  PosDeviceActionType,
  SearchPosDevices,
  SearchPosDevicesFail,
  SearchPosDevicesSuccess,
} from 'app-search/store/actions';

@Injectable()
export class PosDevicesEffects {
  constructor(private actions$: Actions, private posDevicesService: PosDevicesService) {}

  /* ========================= SEARCH_POS_DEVICES =================================== */
  @Effect()
  search$ = this.actions$.pipe(
    ofType(PosDeviceActionType.SEARCH_POS_DEVICES),
    switchMap(({ payload }: SearchPosDevices) =>
      this.posDevicesService.search(payload.name, payload.locations, payload.page, PAGINATION.PosDevices).pipe(
        map((response) => new SearchPosDevicesSuccess(response)),
        catchError((error) => of(new SearchPosDevicesFail(error)))
      )
    )
  );
}
