import { Action } from '@ngrx/store';

import { DependentType } from 'lookups/models';
import { AppHttpResponse } from 'shared';

/**
 * The Lookups-Module store dependent type action types.
 */
export enum DependentTypeActionType {
  GET_ALL_DEPENDENT_TYPES = '[Lookups] [DependentType] Get All Dependent Types',
  GET_ALL_DEPENDENT_TYPES_FAIL = '[Lookups] [DependentType] Get All Dependent Types Fail',
  GET_ALL_DEPENDENT_TYPES_SUCCESS = '[Lookups] [DependentType] Get All Dependent Types Success',
}

/**
 * Represents a store dependent type action.
 */
export class GetAllDependentTypes implements Action {
  /**
   * The type of the action.
   */
  readonly type = DependentTypeActionType.GET_ALL_DEPENDENT_TYPES;

  /**
   * Fires a new dependent type action.
   */
  constructor() {}
}

/**
 * Represents a store dependent type fail action.
 */
export class GetAllDependentTypesFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = DependentTypeActionType.GET_ALL_DEPENDENT_TYPES_FAIL;

  /**
   * Fires a new dependent type fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store dependent type success action.
 */
export class GetAllDependentTypesSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = DependentTypeActionType.GET_ALL_DEPENDENT_TYPES_SUCCESS;

  /**
   * Fires a dependent type success action.
   * @param payload An object contains the list of dependent types.
   */
  constructor(public payload: AppHttpResponse<DependentType[]>) {}
}

/**
 * Lookups-module dependent type action types.
 */
export type DependentTypeActions = GetAllDependentTypes | GetAllDependentTypesFail | GetAllDependentTypesSuccess;
