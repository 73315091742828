import { Injectable } from '@angular/core';

import { of } from 'rxjs';
import { switchMap, map, catchError } from 'rxjs/operators';
import { Effect, ofType, Actions } from '@ngrx/effects';

import { LookupsService } from 'lookups/services';
import { NoticePeriodActionType, GetAllNoticePeriodsFail, GetAllNoticePeriodsSuccess } from 'lookups/store/actions';

@Injectable()
export class NoticePeriodEffects {
  constructor(private actions$: Actions, private lookupsService: LookupsService) {}

  /* ========================= GET_ALL_NOTICE_PERIODS =================================== */
  @Effect()
  getAllNoticePeriods$ = this.actions$.pipe(
    ofType(NoticePeriodActionType.GET_ALL_NOTICE_PERIODS),
    switchMap(() =>
      this.lookupsService.getAllNoticePeriods().pipe(
        map((response) => new GetAllNoticePeriodsSuccess(response)),
        catchError((error) => of(new GetAllNoticePeriodsFail(error)))
      )
    )
  );
}
