import { createSelector } from '@ngrx/store';

import { SubTasksState, AppSearchState } from 'app-search/store/states';
import { selectAppSearchState } from 'app-search/store/selectors/feature.selectors';

/**
 * Gets the sub tasks state.
 */
const selectSubTaskState = createSelector(selectAppSearchState, (state: AppSearchState) => state.subTasks);

/**
 * Gets the list of loaded sub tasks.
 */
export const getSubTasks = createSelector(selectSubTaskState, (state: SubTasksState) => state.data);

/**
 * Gets the sub tasks pagination info.
 */
export const getSubTasksPaginationInfo = createSelector(
  selectSubTaskState,
  (state: SubTasksState) => state.paginationInfo
);

/**
 * Gets the error that occurred in the state.
 */
export const getSubTasksError = createSelector(selectSubTaskState, (state: SubTasksState) => state.error);

/**
 * Determines if there is a running search process.
 */
export const getSubTasksSearching = createSelector(selectSubTaskState, (state: SubTasksState) => state.isSearching);

/**
 * Determines if the last search process has been ended successfully.
 */
export const getSubTasksSearchCompleted = createSelector(
  selectSubTaskState,
  (state: SubTasksState) => state.isSearchCompleted
);
