import { UsersState } from 'security/store/states/users.state';
import { ClaimsState } from 'security/store/states/claims.state';

/**
 * Represents the security-module state.
 */
export class SecurityState {
  /**
   * Represents the users state.
   */
  public users: UsersState;

  /**
   * Represents the claims state.
   */
  public claims: ClaimsState ;
}
