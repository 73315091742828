import { createSelector } from '@ngrx/store';

import { DepartmentsState, AppSearchState } from 'app-search/store/states';
import { selectAppSearchState } from 'app-search/store/selectors/feature.selectors';

/**
 * Gets the departments state.
 */
const selectDepartmentState = createSelector(selectAppSearchState, (state: AppSearchState) => state.departments);

/**
 * Gets the list of loaded departments.
 */
export const getDepartments = createSelector(selectDepartmentState, (state: DepartmentsState) => state.data);

/**
 * Gets the departments pagination info.
 */
export const getDepartmentsPaginationInfo = createSelector(
  selectDepartmentState,
  (state: DepartmentsState) => state.paginationInfo
);

/**
 * Gets the error that occurred in the state.
 */
export const getDepartmentsError = createSelector(selectDepartmentState, (state: DepartmentsState) => state.error);

/**
 * Determines if there is a running search process.
 */
export const getDepartmentsSearching = createSelector(
  selectDepartmentState,
  (state: DepartmentsState) => state.isSearching
);

/**
 * Determines if the last search process has been ended successfully.
 */
export const getDepartmentsSearchCompleted = createSelector(
  selectDepartmentState,
  (state: DepartmentsState) => state.isSearchCompleted
);
