import { createSelector } from '@ngrx/store';

import { selectLookupsState } from 'lookups/store/selectors/feature.selectors';
import { LookupsState, DeductionTypeState } from 'lookups/store/states';

/**
 * Gets the deduction type state.
 */
const selectDeductionTypes = createSelector(selectLookupsState, (state: LookupsState) => state.deductionTypes);

/**
 * Gets the list of loaded deduction types.
 */
export const getDeductionTypes = createSelector(selectDeductionTypes, (state: DeductionTypeState) => state.data);

/**
 * Gets the error that occurred in the state.
 */
export const getDeductionTypesError = createSelector(selectDeductionTypes, (state: DeductionTypeState) => state.error);

/**
 * Determines if there is a running load process.
 */
export const getDeductionTypesIsLoading = createSelector(
  selectDeductionTypes,
  (state: DeductionTypeState) => state.isLoading
);

/**
 * Determines if the last load process has been ended successfully.
 */
export const getDeductionTypesIsLoaded = createSelector(
  selectDeductionTypes,
  (state: DeductionTypeState) => state.isLoaded
);
