import { ProjectResourceTypeSource } from 'lookups/models';

/**
 * Represents the project resource type sources state.
 */
export class ProjectResourceTypeSourceState {
  /**
   * The list of project resource type source stat.
   */
  public data: ProjectResourceTypeSource[];

  /**
   * An error that may resulting during processing some actions.
   */
  public error: any;

  /**
   * Determines if there is a running load process.
   */
  public isLoading: boolean;

  /**
   * Determines if the last load process has been ended successfully.
   */
  public isLoaded: boolean;
}
