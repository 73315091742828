<ng-template #modalRef let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title text-primary" id="modal-basic-title">
      {{ 'STORES.PRODUCTS.CONFIRM_CREATE_PRODUCT_TITLE' | translate }}
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="d(); closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <!-- create product form starts -->
    <div class="row">
      <div class="col-md-12">
        <form [formGroup]="form" (ngSubmit)="submit()">
          <div class="row">
            <div class="col-md-12">
              <ul
                class="nav-tabs"
                ngbNav
                #nav="ngbNav"
                [(activeId)]="activePage"
                (activeIdChange)="selectedPageChanged($event)"
                [destroyOnHide]="false"
              >
                <li ngbNavItem="product-details">
                  <a ngbNavLink>
                    <i class="fa fa-cubes" [class.success]="activePage == 'product-details'"></i>
                    {{ 'STORES.PRODUCTS.PRODUCT_TITLE' | translate }}
                  </a>
                  <ng-template ngbNavContent>
                    <!-- create product form starts -->
                    <div class="row">
                      <div class="col-md-12">
                        <div class="card">
                          <div class="card-header mb-3 pt-0">
                            <h4 class="card-title">{{ 'STORES.PRODUCTS.PRODUCT_TITLE' | translate }}</h4>
                          </div>
                          <div class="card-content">
                            <div class="px-3">
                              <div class="row">
                                <div class="form-group col-md-12">
                                  <label for="description">{{
                                    'STORES.PRODUCTS.PRODUCT_DATA.ARABIC_PRODUCT_DESCRIPTION' | translate
                                  }}</label>
                                  <textarea
                                    id="description"
                                    class="form-control round"
                                    [placeholder]="
                                      'STORES.PRODUCTS.PRODUCT_DATA.ARABIC_PRODUCT_DESCRIPTION_PLACEHOLDER' | translate
                                    "
                                    rows="2"
                                    minlength="1"
                                    maxlength="100"
                                    formControlName="description"
                                  ></textarea>
                                </div>
                                <div class="form-group col-md-12">
                                  <label for="descriptionEn">{{
                                    'STORES.PRODUCTS.PRODUCT_DATA.ENGLISH_PRODUCT_DESCRIPTION' | translate
                                  }}</label>
                                  <textarea
                                    id="descriptionEn"
                                    class="form-control round"
                                    [placeholder]="
                                      'STORES.PRODUCTS.PRODUCT_DATA.ENGLISH_PRODUCT_DESCRIPTION_PLACEHOLDER' | translate
                                    "
                                    rows="2"
                                    minlength="1"
                                    maxlength="100"
                                    formControlName="descriptionEn"
                                  ></textarea>
                                </div>
                                <div class="form-group col-md-6">
                                  <label for="itemCode">{{
                                    'STORES.PRODUCTS.PRODUCT_DATA.ITEM_CODE' | translate
                                  }}</label>
                                  <input
                                    type="text"
                                    id="itemCode"
                                    class="form-control round"
                                    [placeholder]="
                                      'STORES.PRODUCTS.PRODUCT_DATA.PRODUCT_ITEM_CODE_PLACEHOLDER' | translate
                                    "
                                    maxlength="50"
                                    minlength="0"
                                    formControlName="itemCode"
                                  />
                                </div>
                                <div class="form-group col-md-6">
                                  <label for="barcode">{{
                                    'STORES.PRODUCTS.PRODUCT_DATA.PRODUCT_BARCODE' | translate
                                  }}</label>
                                  <input
                                    type="text"
                                    id="barcode"
                                    class="form-control round"
                                    [placeholder]="
                                      'STORES.PRODUCTS.PRODUCT_DATA.PRODUCT_BARCODE_PLACEHOLDER' | translate
                                    "
                                    maxlength="50"
                                    minlength="0"
                                    formControlName="barcode"
                                  />
                                </div>
                                <div class="form-group col-md-6">
                                  <label for="productClassId">{{
                                    'STORES.PRODUCTS.PRODUCT_DATA.PRODUCT_CLASS' | translate
                                  }}</label>
                                  <ng-select
                                    class="round"
                                    formControlName="productClassId"
                                    labelForId="productClassId"
                                    searchable="true"
                                    [placeholder]="'STORES.PRODUCTS.PRODUCT_DATA.PRODUCT_CLASS_PLACEHOLDER' | translate"
                                    [notFoundText]="'SHARED.SEARCH.SEARCH_NOT_FOUND' | translate"
                                    (open)="productClassesListVisibility = true"
                                  >
                                    <ng-option *ngFor="let productClass of productClasses" [value]="productClass.id">
                                      {{ productClass.name | localize: productClass.nameEn | async }}
                                    </ng-option>
                                  </ng-select>
                                </div>
                                <div class="form-group col-md-6">
                                  <label for="tax">{{ 'STORES.PRODUCTS.PRODUCT_DATA.PRODUCT_TAX' | translate }}</label>
                                  <ng-select
                                    class="round"
                                    labelForId="tax"
                                    formControlName="tax"
                                    searchable="true"
                                    [placeholder]="'STORES.PRODUCTS.PRODUCT_DATA.PRODUCT_TAX_PLACEHOLDER' | translate"
                                    [notFoundText]="'SHARED.SEARCH.SEARCH_NOT_FOUND' | translate"
                                  >
                                    <ng-option *ngFor="let tax of taxes$ | async" [value]="tax.value">
                                      {{ tax.name | localize: tax.nameEn | async }}
                                    </ng-option>
                                  </ng-select>
                                </div>
                                <div class="form-group col-md-6">
                                  <label for="unitOfMeasureId">{{
                                    'STORES.PRODUCTS.PRODUCT_DATA.UNIT_OF_MEASURE' | translate
                                  }}</label>
                                  <ng-select
                                    class="round"
                                    formControlName="unitOfMeasureId"
                                    labelForId="unitOfMeasureId"
                                    searchable="true"
                                    [placeholder]="
                                      'STORES.PRODUCTS.PRODUCT_DATA.UNIT_OF_MEASURE_PLACEHOLDER' | translate
                                    "
                                    [notFoundText]="'SHARED.SEARCH.SEARCH_NOT_FOUND' | translate"
                                    (open)="productUnitsOfMeasureListVisibility = true"
                                  >
                                    <ng-option
                                      *ngFor="let unitOfMeasure of productUnitsOfMeasure"
                                      [value]="unitOfMeasure.id"
                                    >
                                      {{ unitOfMeasure.name | localize: unitOfMeasure.nameEn | async }}
                                    </ng-option>
                                  </ng-select>
                                </div>
                                <div class="form-group col-md-6">
                                  <input type="checkbox" formControlName="hasExpireDate" />
                                  <span class="ml-2 mt-0 pt-0">{{
                                    'STORES.PRODUCTS.PRODUCT_DATA.HAS_EXPIRE_DATE' | translate
                                  }}</span>
                                </div>
                                <div class="form-group col-md-6">
                                  <input type="checkbox" formControlName="hasSerialNumber" />
                                  <span class="ml-2 mt-0 pt-0">{{
                                    'STORES.PRODUCTS.PRODUCT_DATA.HAS_SERIAL_NUMBER' | translate
                                  }}</span>
                                </div>
                                <div class="form-group col-md-6">
                                  <input type="checkbox" formControlName="showProductInSalesScreen" />
                                  <span class="ml-2 mt-0 pt-0">{{
                                    'STORES.PRODUCTS.PRODUCT_DATA.SHOW_PRODUCT_IN_SALES_SCREEN' | translate
                                  }}</span>
                                </div>
                                <div class="form-group col-md-6">
                                  <input type="checkbox" formControlName="hasIngredients" />
                                  <span class="ml-2 mt-0 pt-0">{{
                                    'STORES.PRODUCTS.PRODUCT_DATA.HAS_INGREDIENTS' | translate
                                  }}</span>
                                </div>
                                <div class="form-group col-md-6">
                                  <input type="checkbox" formControlName="isService" />
                                  <span class="ml-2 mt-0 pt-0">{{
                                    'STORES.PRODUCTS.PRODUCT_DATA.IS_SERVICE' | translate
                                  }}</span>
                                </div>
                                <div
                                  *ngIf="!form.value.hasIngredients"
                                  class="form-group col-md-12"
                                  [ngClass]="{
                                    'col-md-12': !form.value.hasSubProducts,
                                    'col-md-6': form.value.hasSubProducts
                                  }"
                                >
                                  <input type="checkbox" formControlName="hasSubProducts" />
                                  <span class="ml-2 mt-0 pt-0">{{
                                    'STORES.PRODUCTS.PRODUCT_DATA.HAS_SUB_PRODUCTS' | translate
                                  }}</span>
                                </div>
                                <div class="form-group col-md-6" *ngIf="form.value.hasSubProducts">
                                  <input type="checkbox" formControlName="subProductHasIngredients" />
                                  <span class="ml-2 mt-0 pt-0">{{
                                    'STORES.PRODUCTS.PRODUCT_DATA.SUB_PRODUCTS_HAS_INGREDIENTS' | translate
                                  }}</span>
                                </div>
                                <div class="form-group col-md-6" *ngIf="!form.value.hasSubProducts">
                                  <label for="purchasePrice">{{
                                    'STORES.PRODUCTS.PRODUCT_DATA.PURCHASE_PRICE' | translate
                                  }}</label>
                                  <input
                                    type="text"
                                    [mask]="DECIMAL_MASK"
                                    thousandSeparator=","
                                    minLength="0"
                                    id="purchasePrice"
                                    class="form-control round"
                                    [placeholder]="
                                      'STORES.PRODUCTS.PRODUCT_DATA.PURCHASE_PRICE_PLACEHOLDER' | translate
                                    "
                                    formControlName="purchasePrice"
                                  />
                                </div>
                                <div class="form-group col-md-6" *ngIf="!form.value.hasSubProducts">
                                  <label for="salePrice">{{
                                    'STORES.PRODUCTS.PRODUCT_DATA.SALE_PRICE' | translate
                                  }}</label>
                                  <input
                                    type="text"
                                    [mask]="DECIMAL_MASK"
                                    thousandSeparator=","
                                    minLength="0"
                                    id="salePrice"
                                    class="form-control round"
                                    [placeholder]="'STORES.PRODUCTS.PRODUCT_DATA.SALE_PRICE_PLACEHOLDER' | translate"
                                    formControlName="salePrice"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="card" *ngIf="form.value.hasIngredients">
                          <div class="card-header mb-3 pt-0">
                            <h4 class="card-title">
                              {{ 'STORES.PRODUCTS.PRODUCT_DATA.INGREDIENTS_OF_PRODUCT' | translate }}
                            </h4>
                          </div>
                          <div class="card-content">
                            <div class="px-3 pt-3">
                              <div class="row">
                                <div class="col-md-12">
                                  <div app-product-ingredients-form [formArray]="ingredientsForm"></div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="card" *ngIf="form.value.hasSubProducts">
                          <div class="card-header mb-3 pt-0">
                            <h4 class="card-title">
                              {{ 'STORES.PRODUCTS.PRODUCT_DATA.PRODUCT_SUB_PRODUCTS' | translate }}
                            </h4>
                          </div>
                          <div class="card-content">
                            <div class="px-3">
                              <div class="row">
                                <div class="col-md-12">
                                  <app-sub-products-form
                                    [formArray]="subProductsForm"
                                    [subProductHasIngredients]="form.value.subProductHasIngredients"
                                  ></app-sub-products-form>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="card">
                          <div class="card-header mb-3 pt-0">
                            <h4 class="card-title">
                              {{ 'STORES.PRODUCTS.PRODUCT_DATA.LOCATION_FORM_TITLE' | translate }}
                            </h4>
                          </div>
                          <div class="card-content">
                            <!-- locations details starts -->
                            <div class="px-3">
                              <div class="row">
                                <div class="col-md-12">
                                  <app-locations-form
                                    [formArray]="locationsForm"
                                    [isLocationRequired]="true"
                                  ></app-locations-form>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <!-- create location form ends -->
                      </div>
                    </div>
                  </ng-template>
                </li>
                <li ngbNavItem="unit-of-measure-rates">
                  <a ngbNavLink>
                    <i class="fa fa-line-chart" [class.success]="activePage == 'unit-of-measure-rates'"></i>
                    {{ 'STORES.PRODUCTS.PRODUCT_DATA.PRODUCT_UNIT_OF_MEASURE_RATE_TITLE' | translate }}
                  </a>
                  <ng-template ngbNavContent>
                    <div class="row">
                      <div class="col-md-12">
                        <div class="card">
                          <div class="card-content">
                            <!-- product unit of measure rate details starts -->
                            <div class="px-3">
                              <div class="row">
                                <div class="col-md-12">
                                  <app-product-unit-of-measure-rate-form
                                    [productUnitOfMeasureRatesForm]="productUnitOfMeasureRatesForm"
                                  ></app-product-unit-of-measure-rate-form>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <!-- product unit of measure rate details ends -->
                      </div>
                    </div>
                  </ng-template>
                </li>
                <li ngbNavItem="other-details">
                  <a ngbNavLink>
                    <i class="icon-energy" [class.success]="activePage == 'other-details'"></i>
                    {{ 'STORES.PRODUCTS.PRODUCT_DATA.PRODUCT_PHOTO' | translate }}
                  </a>
                  <ng-template ngbNavContent>
                    <div class="row">
                      <div class="col-md-12">
                        <div class="card-content">
                          <div class="px-3">
                            <app-file-upload formControlName="photo"></app-file-upload>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-template>
                </li>
                <li ngbNavItem="sales-discounts">
                  <a ngbNavLink>
                    <i class="fa fa-minus-circle" [class.success]="activePage == 'sales-discounts'"></i>
                    {{ 'STORES.PRODUCTS.PRODUCT_DATA.PRODUCT_DISCOUNTS_TITLE' | translate }}
                  </a>
                  <ng-template ngbNavContent>
                    <div class="row">
                      <div class="col-md-12">
                        <div class="card">
                          <div class="card-content">
                            <!-- product sales discounts details starts -->
                            <div class="px-3">
                              <div class="row">
                                <div class="col-md-12">
                                  <app-product-sales-discounts-form
                                    [productSalesDiscountsForm]="productSalesDiscountsForm"
                                  ></app-product-sales-discounts-form>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <!-- product sales discounts details ends -->
                      </div>
                    </div>
                  </ng-template>
                </li>
              </ul>
              <div [ngbNavOutlet]="nav" class="mt-2"></div>
            </div>
          </div>
        </form>
      </div>
    </div>
    <!-- create product form ends -->
  </div>
  <div class="modal-footer">
    <div class="modal-actions">
      <button
        type="button"
        class="btn btn-raised btn-success round"
        (click)="submit()"
        ngbAutofocus
        [disabled]="isCreating$ | async"
      >
        <i class="fa fa-check"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.SAVE' | translate }} </span>
      </button>
      <button type="button" class="btn btn-outline-dark round" (click)="c(); closeModal()">
        <i class="fa fa-times"></i>
        <span class="mx-1"> {{ 'SHARED.CONFIRM.CANCEL' | translate }} </span>
      </button>
    </div>
  </div>
</ng-template>

<!-- confirm modal starts -->
<ng-template #confirmModalRef let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
      {{ 'STORES.PRODUCTS.CONFIRM_CREATE_PRODUCT_TITLE' | translate }}
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="d()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p class="text-success">{{ 'STORES.PRODUCTS.CONFIRM_CREATE_PRODUCT_MESSAGE' | translate }}</p>
  </div>
  <div class="modal-footer">
    <div class="modal-actions">
      <button type="button" class="btn btn-raised btn-success round" (click)="c(); confirm()" ngbAutofocus>
        <i class="fa fa-check"></i>
        <span class="mx-1"> {{ 'SHARED.CONFIRM.SAVE' | translate }} </span>
      </button>
      <button type="button" class="btn btn-outline-dark round" (click)="c()">
        <i class="fa fa-times"></i>
        <span class="mx-1">
          <span class="mx-1"> {{ 'SHARED.CONFIRM.CANCEL' | translate }} </span>
        </span>
      </button>
    </div>
  </div>
</ng-template>
<!-- confirm modal ends -->

<!-- product classes search starts -->
<app-product-classes-search
  onlyActive="true"
  closeOnSelect="true"
  (productClasses)="selectProductClass($event)"
  [(visible)]="productClassesListVisibility"
>
</app-product-classes-search>
<!-- product classes search ends -->

<!-- product units of measure search starts -->
<app-units-of-measure-search
  onlyActive="true"
  closeOnSelect="true"
  (unitsOfMeasure)="selectProductUnitsOfMeasure($event)"
  [(visible)]="productUnitsOfMeasureListVisibility"
>
</app-units-of-measure-search>
<!-- product units of measure search ends -->
