import { Action } from '@ngrx/store';

import { AppHttpResponse } from 'shared';
import { CostCenter, CreateCostCenterInput, UpdateCostCenterInput } from 'finances/models';

/**
 * The finances-module store cost centers action types.
 */
export enum CostCentersActionType {
  SEARCH_COST_CENTERS = '[Finances] [CostCenters] Search Cost Center',
  SEARCH_COST_CENTERS_FAIL = '[Finances] [CostCenters] Search Cost Center Fail',
  SEARCH_COST_CENTERS_SUCCESS = '[Finances] [CostCenters] Search Cost Center Success',

  GET_GENERAL_COST_CENTER = '[Finances] [CostCenters] Get General Cost Center',
  GET_GENERAL_COST_CENTER_FAIL = '[Finances] [CostCenters] Get General Cost Center Fail',
  GET_GENERAL_COST_CENTER_SUCCESS = '[Finances] [CostCenters] Get General Cost Center Success',

  FIND_COST_CENTER = '[Finances] [CostCenters] Find Cost Center',
  FIND_COST_CENTER_FAIL = '[Finances] [CostCenters] Find Cost Center Fail',
  FIND_COST_CENTER_SUCCESS = '[Finances] [CostCenters] Find Cost Center Success',

  CREATE_COST_CENTER = '[Finances] [CostCenters] Create Cost Center',
  CREATE_COST_CENTER_FAIL = '[Finances] [CostCenters] Create Cost Center Fail',
  CREATE_COST_CENTER_SUCCESS = '[Finances] [CostCenters] Create Cost Center Success',

  UPDATE_COST_CENTER = '[Finances] [CostCenters] Update Cost Center',
  UPDATE_COST_CENTER_FAIL = '[Finances] [CostCenters] Update Cost Center Fail',
  UPDATE_COST_CENTER_SUCCESS = '[Finances] [CostCenters] Update Cost Center Success',

  DELETE_COST_CENTER = '[Finances] [CostCenters] Delete Cost Center',
  DELETE_COST_CENTER_FAIL = '[Finances] [CostCenters] Delete Cost Center Fail',
  DELETE_COST_CENTER_SUCCESS = '[Finances] [CostCenters] Delete Cost Center Success',

  UPDATE_DEFAULT_COST_CENTER = '[Finances] [CostCenters] Update Default Cost Center',
  UPDATE_DEFAULT_COST_CENTER_FAIL = '[Finances] [CostCenters] Update Default Cost Center Fail',
  UPDATE_DEFAULT_COST_CENTER_SUCCESS = '[Finances] [CostCenters] Update Default Cost Center Success',
}

/**
 * Represents a store cost centers search action.
 */
export class SearchCostCenters implements Action {
  /**
   * The type of the action.
   */
  readonly type = CostCentersActionType.SEARCH_COST_CENTERS;

  /**
   * Fires a new cost centers search action.
   * @param payload the search parameters,
   * if omitted, all cost centers will be loaded.
   */
  constructor(public payload?: { description: string; page: number }) {}
}

/**
 * Represents a store cost centers search fail action.
 */
export class SearchCostCentersFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = CostCentersActionType.SEARCH_COST_CENTERS_FAIL;

  /**
   * Fires a new cost centers search fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store search success action.
 */
export class SearchCostCentersSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = CostCentersActionType.SEARCH_COST_CENTERS_SUCCESS;

  /**
   * Fires a new cost centers search success action.
   * @param payload An object contains the list of cost centers that matches the search criteria
   * in addition to the pagination info.
   */
  constructor(public payload: AppHttpResponse<CostCenter[]>) {}
}

/**
 * Represents a store general cost center get action.
 */
export class GetGeneralCostCenter implements Action {
  /**
   * The type of the action.
   */
  readonly type = CostCentersActionType.GET_GENERAL_COST_CENTER;

  /**
   * Fires a new general cost center get action.
   * @param payload the search parameters,
   * if omitted, general cost center will be loaded.
   */
  constructor() {}
}

/**
 * Represents a store general cost center get fail action.
 */
export class GetGeneralCostCenterFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = CostCentersActionType.GET_GENERAL_COST_CENTER_FAIL;

  /**
   * Fires a new general cost center get fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store get success action.
 */
export class GetGeneralCostCenterSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = CostCentersActionType.GET_GENERAL_COST_CENTER_SUCCESS;

  /**
   * Fires a new general cost center search success action.
   * @param payload An object contains the object of general cost center that matches the search criteria
   */
  constructor(public payload: AppHttpResponse<CostCenter>) {}
}

/**
 * Represents a store find cost center action.
 */
export class FindCostCenter implements Action {
  /**
   * The type of the action.
   */
  readonly type = CostCentersActionType.FIND_COST_CENTER;

  /**
   * Fires a new find cost center action.
   * @param payload the id of the cost center.
   */
  constructor(public payload: number) {}
}

/**
 * Represents a store find cost center fail action.
 */
export class FindCostCenterFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = CostCentersActionType.FIND_COST_CENTER_FAIL;

  /**
   * Fires a new find cost center fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store find cost center success action.
 */
export class FindCostCenterSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = CostCentersActionType.FIND_COST_CENTER_SUCCESS;

  /**
   * Fires a new find cost center success action.
   * @param payload The loaded cost center.
   */
  constructor(public payload: AppHttpResponse<CostCenter>) {}
}

/**
 * Represents a store cost center create action.
 */
export class CreateCostCenter implements Action {
  /** The type of the action. */
  readonly type = CostCentersActionType.CREATE_COST_CENTER;

  /**
   * Fires a new cost center create action.
   * @param payload the new cost center data-model.
   */
  constructor(public payload: CreateCostCenterInput) {}
}

/**
 * Represents a store cost center create fail action.
 */
export class CreateCostCenterFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = CostCentersActionType.CREATE_COST_CENTER_FAIL;

  /**
   * Fires a new cost center create fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store cost center create success action.
 */
export class CreateCostCenterSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = CostCentersActionType.CREATE_COST_CENTER_SUCCESS;

  /**
   * Fires a new cost center create success action.
   * @param payload The created cost center.
   */
  constructor(public payload: AppHttpResponse<CostCenter>) {}
}

/**
 * Represents a store cost center update action.
 */
export class UpdateCostCenter implements Action {
  /**
   * The type of the action.
   */
  readonly type = CostCentersActionType.UPDATE_COST_CENTER;

  /**
   * Fires a new cost center update action.
   * @param payload The cost center's updated data.
   */
  constructor(public payload: UpdateCostCenterInput) {}
}

/**
 * Represents a store cost center update fail action.
 */
export class UpdateCostCenterFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = CostCentersActionType.UPDATE_COST_CENTER_FAIL;

  /**
   * Fires a new cost center update fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store cost center update success action.
 */
export class UpdateCostCenterSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = CostCentersActionType.UPDATE_COST_CENTER_SUCCESS;

  /**
   * Fires a new cost center update-success action.
   * @param payload The updated cost center.
   */
  constructor(public payload: AppHttpResponse<CostCenter>) {}
}

/**
 * Represents a store cost center delete action.
 */
export class DeleteCostCenter implements Action {
  /**
   * The type of the action.
   */
  readonly type = CostCentersActionType.DELETE_COST_CENTER;

  /**
   * Fires a new cost center delete action.
   * @param payload The cost center's id.
   */
  constructor(public payload: number) {}
}

/**
 * Represents a store cost centers delete fail action.
 */
export class DeleteCostCenterFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = CostCentersActionType.DELETE_COST_CENTER_FAIL;

  /**
   * Fires a new cost center delete fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store cost center delete success action.
 */
export class DeleteCostCenterSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = CostCentersActionType.DELETE_COST_CENTER_SUCCESS;

  /**
   * Fires a new cost center delete success action.
   * @param payload The deleted cost center.
   */
  constructor(public payload: AppHttpResponse<CostCenter>) {}
}

/**
 * Represents a store default cost center update action.
 */
export class UpdateDefaultCostCenter implements Action {
  /**
   * The type of the action.
   */
  readonly type = CostCentersActionType.UPDATE_DEFAULT_COST_CENTER;

  /**
   * Fires a new default cost center update action.
   * @param payload The default cost center updated data.
   */
  constructor(public payload: { id: number }) {}
}

/**
 * Represents a store default cost center update-fail action.
 */
export class UpdateDefaultCostCenterFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = CostCentersActionType.UPDATE_DEFAULT_COST_CENTER_FAIL;

  /**
   * Fires a new default cost center update-fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store default cost center update-success action.
 */
export class UpdateDefaultCostCenterSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = CostCentersActionType.UPDATE_DEFAULT_COST_CENTER_SUCCESS;

  /**
   * Fires a new cost centers update-success action.
   * @param payload The updated cost center.
   */
  constructor(public payload: AppHttpResponse<CostCenter>) {}
}

/**
 * finances-module cost centers action types.
 */
export type CostCentersActions =
  | SearchCostCenters
  | SearchCostCentersFail
  | SearchCostCentersSuccess
  | GetGeneralCostCenter
  | GetGeneralCostCenterFail
  | GetGeneralCostCenterSuccess
  | FindCostCenter
  | FindCostCenterFail
  | FindCostCenterSuccess
  | CreateCostCenter
  | CreateCostCenterFail
  | CreateCostCenterSuccess
  | UpdateCostCenter
  | UpdateCostCenterFail
  | UpdateCostCenterSuccess
  | DeleteCostCenter
  | DeleteCostCenterFail
  | DeleteCostCenterSuccess;
