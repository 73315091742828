import { Action } from '@ngrx/store';

import { ProbationPeriod } from 'lookups/models';
import { AppHttpResponse } from 'shared';

/**
 * The Lookups-Module store probation period action types.
 */
export enum ProbationPeriodActionType {
  GET_ALL_PROBATION_PERIODS = '[Lookups] [ProbationPeriod] Get All Probation Periods',
  GET_ALL_PROBATION_PERIODS_FAIL = '[Lookups] [ProbationPeriod] Get All Probation Periods Fail',
  GET_ALL_PROBATION_PERIODS_SUCCESS = '[Lookups] [ProbationPeriod] Get All Probation Periods Success',
}

/**
 * Represents a store probation period action.
 */
export class GetAllProbationPeriods implements Action {
  /**
   * The type of the action.
   */
  readonly type = ProbationPeriodActionType.GET_ALL_PROBATION_PERIODS;

  /**
   * Fires a new probation period action.
   */
  constructor() {}
}

/**
 * Represents a store probation period fail action.
 */
export class GetAllProbationPeriodsFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = ProbationPeriodActionType.GET_ALL_PROBATION_PERIODS_FAIL;

  /**
   * Fires a new probation period fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store probation period success action.
 */
export class GetAllProbationPeriodsSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = ProbationPeriodActionType.GET_ALL_PROBATION_PERIODS_SUCCESS;

  /**
   * Fires a probation period success action.
   * @param payload An object contains the list of probation periods.
   */
  constructor(public payload: AppHttpResponse<ProbationPeriod[]>) {}
}

/**
 * Lookups-module probation period action types.
 */
export type ProbationPeriodActions =
  | GetAllProbationPeriods
  | GetAllProbationPeriodsFail
  | GetAllProbationPeriodsSuccess;
