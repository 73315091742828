<!-- page header starts -->
<app-page-header [info]="pageInfo"></app-page-header>
<!-- page header ends -->

<!-- orders monitoring list starts -->
<div class="row">
  <div class="col-md-12">
    <div class="card my-0 py-0">
      <div class="card-header mb-3">
        <h4 class="card-title">{{ 'SHARED.SEARCH.SEARCH_TOOLS' | translate }}</h4>
      </div>
      <div class="card-content">
        <div class="px-3">
          <div class="row">
            <div class="col-md-12">
              <form class="row" [formGroup]="prepareForm" (ngSubmit)="prepare()">
                <div class="form-group col">
                  <label for="locations">{{
                    'SALES.PENDING_POS_INVOICES.PENDING_POS_INVOICES_DATA.LOCATIONS' | translate
                  }}</label>
                  <ng-select
                    class="round"
                    formControlName="locations"
                    [multiple]="true"
                    [closeOnSelect]="false"
                    labelForId="locations"
                    searchable="true"
                    [placeholder]="
                      'SALES.PENDING_POS_INVOICES.PENDING_POS_INVOICES_DATA.LOCATIONS_PLACEHOLDER' | translate
                    "
                    [notFoundText]="'SHARED.SEARCH.SEARCH_NOT_FOUND' | translate"
                    (open)="locationsListVisibility = true"
                  >
                    <ng-option *ngFor="let location of locations" [value]="location.id">
                      {{ location.name | localize: location.nameEn | async }}
                    </ng-option>
                  </ng-select>
                </div>
                <div class="form-group col">
                  <label for="productClasses">{{
                    'SALES.PENDING_POS_INVOICES.PENDING_POS_INVOICES_DATA.PRODUCTS_CLASSES' | translate
                  }}</label>
                  <ng-select
                    class="round"
                    formControlName="productClasses"
                    [multiple]="true"
                    [closeOnSelect]="false"
                    labelForId="productClasses"
                    searchable="true"
                    [placeholder]="
                      'SALES.PENDING_POS_INVOICES.PENDING_POS_INVOICES_DATA.PRODUCTS_CLASSES_PLACEHOLDER' | translate
                    "
                    [notFoundText]="'SHARED.SEARCH.SEARCH_NOT_FOUND' | translate"
                    (open)="classesListVisibility = true"
                  >
                    <ng-option *ngFor="let productClass of classes" [value]="productClass.id">
                      {{ productClass.name | localize: productClass.nameEn | async }}
                    </ng-option>
                  </ng-select>
                </div>
                <div class="form-group col-md-auto mt-3">
                  <button type="submit" class="btn btn-raised btn-primary round">
                    <i class="fa fa-eye"></i>
                    <span class="mx-1">{{
                      'SALES.PENDING_POS_INVOICES.PENDING_POS_INVOICES_DATA.WATCH' | translate
                    }}</span>
                  </button>
                </div>
              </form>
            </div>
            <div class="col-md-12" *ngIf="(saleInvoices$ | async)?.length; else noItems">
              <div class="row d-flex flex-row justify-content-between">
                <div class="col-md-12">
                  <!--orders monitoring cards Starts-->
                  <div class="row d-flex flex-row justify-content-between text-left mb-4">
                    <div class="col-md-3" *ngFor="let saleInvoice of saleInvoices$ | async; trackBy: trackItems">
                      <ng-container *ngIf="saleInvoice.saleInvoiceProducts.length">
                        <div class="card order-monitoring-menu-item-card">
                          <div class="card-content">
                            <div class="col-md-12">
                              <div class="table-responsive-xl">
                                <p class="font-weight-bold text-center mt-2 mb-0">
                                  {{ 'SALES.PENDING_POS_INVOICES.PENDING_POS_INVOICES_DATA.ORDER_NUMBER' | translate }}
                                  <span> {{ saleInvoice.orderNum }}</span>
                                </p>
                                <p
                                  *ngIf="
                                    saleInvoice.saleInvoiceOrderType.key === saleInvoiceOrderType.DINE_IN &&
                                    saleInvoice.board
                                  "
                                  class="text-center mb-0"
                                >
                                  {{ 'SALES.PENDING_POS_INVOICES.PENDING_POS_INVOICES_DATA.BOARD_NUMBER' | translate }}
                                  <span> {{ saleInvoice.board.description }}</span>
                                </p>
                                <p class="text-center">
                                  {{ 'SALES.PENDING_POS_INVOICES.PENDING_POS_INVOICES_DATA.ORDER_TYPE' | translate }}
                                  <span>
                                    {{
                                      saleInvoice.saleInvoiceOrderType.name
                                        | localize: saleInvoice.saleInvoiceOrderType.nameEn
                                        | async
                                    }}</span
                                  >
                                </p>
                                <table class="table table-sm text-center table-tr">
                                  <thead>
                                    <tr>
                                      <th scope="col-3">
                                        {{
                                          'SALES.PENDING_POS_INVOICES.PENDING_POS_INVOICES_DATA.QUANTITY' | translate
                                        }}
                                      </th>
                                      <th scope="col-9">
                                        {{ 'SALES.PENDING_POS_INVOICES.PENDING_POS_INVOICES_DATA.PRODUCT' | translate }}
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody *ngFor="let saleInvoiceProduct of saleInvoice.saleInvoiceProducts">
                                    <tr *ngIf="saleInvoiceProduct.product">
                                      <th scope="col-3">{{ saleInvoiceProduct.quantity }}</th>
                                      <td scope="col-9">
                                        <span>{{
                                          saleInvoiceProduct.product.description
                                            | localize: saleInvoiceProduct.product.descriptionEn
                                            | async
                                        }}</span>
                                        <p class="mb-0" *ngIf="saleInvoiceProduct.product.hasSubProducts">
                                          (
                                          <span
                                            *ngFor="
                                              let subProductVariant of saleInvoiceProduct.product.subProducts[0]
                                                .subProductVariants;
                                              last as isLast
                                            "
                                            >{{
                                              subProductVariant.variant.name
                                                | localize: subProductVariant.variant.nameEn
                                                | async
                                            }}
                                            <span *ngIf="!isLast">-</span></span
                                          >
                                          )
                                        </p>
                                        <span>{{ saleInvoiceProduct.notes }}</span>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                              <div class="text-right mt-2 d-flex justify-content-between">
                                <button
                                  type="button"
                                  class="btn btn-raised btn-secondary round"
                                  ngbAutofocus
                                  (click)="selectedSaleInvoice = saleInvoice; print()"
                                >
                                  <i class="fa fa-print"></i>
                                  <span class="mx-1">{{ 'SHARED.CONFIRM.PRINT' | translate }}</span>
                                </button>
                                <button
                                  *ngIf="[Claims.ORDERS_MONITORING] | authorize | async"
                                  type="button"
                                  class="btn btn-raised btn-success round"
                                  [disabled]="isUpdating$ | async"
                                  ngbAutofocus
                                  (click)="selectedSaleInvoice = saleInvoice; openModal(updateModalRef)"
                                >
                                  <i class="fa fa-check"></i>
                                  <span class="mx-1">{{
                                    'SALES.PENDING_POS_INVOICES.PENDING_POS_INVOICES_DATA.DONE_ORDER' | translate
                                  }}</span>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </ng-container>
                    </div>
                  </div>
                  <!--orders monitoring cards End-->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- orders monitoring list ends -->

<!-- no items template starts -->
<ng-template #noItems>
  <div class="col-md-12 alert alert-warning mt-3 text-center" role="alert">
    {{ 'SALES.PENDING_POS_INVOICES.PREPARE_POS_INVOICES_NOT_FOUND' | translate }}
  </div>
</ng-template>
<!-- no items template ends -->

<!-- update order modal starts -->
<ng-template #updateModalRef let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
      {{ 'SALES.PENDING_POS_INVOICES.CONFIRM_UPDATE_ORDER_TITLE' | translate }}
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="d()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-12">
        <p class="text-success">{{ 'SALES.PENDING_POS_INVOICES.CONFIRM_UPDATE_ORDER_MESSAGE' | translate }}</p>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="modal-actions">
      <button type="button" class="btn btn-raised btn-success round" (click)="c(); update()" ngbAutofocus>
        <i class="fa fa-check"></i>
        <span class="mx-1">{{ 'SALES.PENDING_POS_INVOICES.PENDING_POS_INVOICES_DATA.DONE_ORDER' | translate }}</span>
      </button>
      <button type="button" class="btn btn-outline-dark round" (click)="c()">
        <i class="fa fa-times"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.CANCEL' | translate }}</span>
      </button>
    </div>
  </div>
</ng-template>
<!-- update order modal ends -->

<!-- locations search starts -->
<app-locations-search [(visible)]="locationsListVisibility" (locations)="selectLocations($event)">
</app-locations-search>
<!-- locations search ends -->

<!-- product classes search starts -->
<app-product-classes-search (productClasses)="selectClasses($event)" [(visible)]="classesListVisibility">
</app-product-classes-search>
<!-- product classes search ends -->
