import { Injectable } from '@angular/core';

import { of } from 'rxjs';
import { switchMap, map, catchError, debounceTime } from 'rxjs/operators';
import { Effect, ofType, Actions } from '@ngrx/effects';

import { PAGINATION } from 'shared';
import { SubTasksService } from 'app-search/services';
import {
  SubTasksActionType,
  SearchSubTasks,
  SearchSubTasksFail,
  SearchSubTasksSuccess,
} from 'app-search/store/actions';

@Injectable()
export class SubTasksEffects {
  constructor(private actions$: Actions, private subTasksService: SubTasksService) {}

  /* ========================= SEARCH_SUB_TASKS =================================== */
  @Effect()
  search$ = this.actions$.pipe(
    ofType(SubTasksActionType.SEARCH_SUB_TASKS),
    debounceTime(300),
    switchMap(({ payload }: SearchSubTasks) =>
      this.subTasksService.search(payload?.description ?? '', payload.projects, payload.page, PAGINATION.SubTasks).pipe(
        map((response) => new SearchSubTasksSuccess(response)),
        catchError((error) => of(new SearchSubTasksFail(error)))
      )
    )
  );
}
