import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms';

import { Observable, Subscription } from 'rxjs';
import { skip, tap } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { NotificationService, NotificationMessage, TranslationService, APP_CONSTANTS, CustomValidators } from 'shared';
import * as fromFinancesStore from 'finances/store';
import * as fromLookupsStore from 'lookups/store';
import { CanComponentDeactivate } from 'auth/models';
import { EmployeePaymentMethod, EmployeePaymentMethods } from 'lookups/models';
import { BankAccount, PaidPayrollFormItem } from 'finances/models';
import Decimal from 'decimal.js';

@Component({
  selector: 'app-create-payroll-payment-refund',
  templateUrl: './create-payroll-payment-refund.component.html',
  styles: [],
})
export class CreatePayrollPaymentRefundComponent implements OnInit, OnDestroy, CanComponentDeactivate {
  /**
   * The confirm modal template reference.
   */
  @ViewChild('confirmModalRef') confirmModalRef: ElementRef<any>;

  /**
   * The list of employee payment method.
   */
  employeePaymentMethods$: Observable<EmployeePaymentMethod[]>;

  /**
   * Gets the locations form-array.
   */
  get paidPayrollsForm(): FormArray {
    return this.form?.controls.payrolls as FormArray;
  }

  /**
   * The list of bank accounts.
   */
  bankAccounts: BankAccount[] = [];

  /**
   * Gets or sets the id of the employee payment method for `CASH`.
   */
  employeePaymentMethodIdForCash: number;

  /**
   * Gets or sets the id of the employee payment method for `BANK_TRANSFER`.
   */
  employeePaymentMethodIdForBankTransfer: number;

  /**
   * Indicates whether there is a  payroll payment refunds process is running or not.
   */
  isCreating$: Observable<boolean>;

  /**
   * The decimal mask.
   */
  readonly DECIMAL_MASK = APP_CONSTANTS.numeric.decimal.mask;

  /**
   * The pay payrolls form.
   */
  form: FormGroup;

  /**
   * Shows or hide the bank accounts list.
   */
  bankAccountsListVisibility = false;

  /**
   * The set of subscriptions on this components,
   * these subscriptions must be unsubscribed before this component got destroyed.
   */
  subscriptions = new Subscription();

  /**
   * Gets or sets the payrolls net values.
   */
  get payrollsNet(): number {
    return Decimal.sum(
      0,
      ...this.paidPayrollsForm.controls.map((item) => item.get('paidPayroll').value.net)
    ).toNumber();
  }

  /**
   * Get bank fee value.
   */
  get bankFee(): number {
    return this.form.value?.bankFee ?? 0;
  }

  /**
   * Get bank fee tax value.
   */
  get bankFeeTaxValue(): number {
    return this.form.value?.bankFeeTaxValue ?? 0;
  }
  /**
   * Gets the payment net.
   */

  get paymentNet(): number {
    return Decimal.sum(this.payrollsNet, this.bankFee, this.bankFeeTaxValue).toNumber();
  }

  /**
   * Gets the attachments form-array.
   */
  get attachmentsForm(): FormArray {
    return this.form?.controls.attachments as FormArray;
  }

  /**
   * @param modalService The modal service.
   * @param notificationService The notification service.
   * @param financesStore$ the finances-store module.
   * @param lookupsStore$ The lookups-store module.
   * @param translationService The translation service.
   */
  constructor(
    private modalService: NgbModal,
    private notificationService: NotificationService,
    private financesStore$: Store<fromFinancesStore.FinancesState>,
    private lookupsStore$: Store<fromLookupsStore.LookupsState>,
    private translationService: TranslationService
  ) {}

  ngOnInit(): void {
    this.init();
  }

  /**
   * Unsubscribes for component data.
   */
  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  /**
   * Initialize component data.
   */
  init() {
    this.initForm();

    this.subscriptions.add(
      this.financesStore$
        .pipe(
          select(fromFinancesStore.getSelectedPayrollPaymentRefundCreateCompleted),
          skip(1),
          tap((isPay) => {
            if (isPay) {
              this.initForm();
            }
          })
        )
        .subscribe()
    );

    /** Load data. */
    let isManualSearchTriggeredBeforeForEmployeePaymentMethods = false;
    this.employeePaymentMethods$ = this.lookupsStore$.pipe(
      select(fromLookupsStore.getEmployeePaymentMethods),
      tap((employeePaymentMethods) => {
        if (!isManualSearchTriggeredBeforeForEmployeePaymentMethods && !employeePaymentMethods.length) {
          isManualSearchTriggeredBeforeForEmployeePaymentMethods = true;
          this.lookupsStore$.dispatch(new fromLookupsStore.GetAllEmployeePaymentMethods());
        } else if (employeePaymentMethods?.length) {
          this.employeePaymentMethodIdForCash = employeePaymentMethods?.find(
            (employeePaymentMethod) => employeePaymentMethod.key === EmployeePaymentMethods.CASH
          )?.id;
          this.employeePaymentMethodIdForBankTransfer = employeePaymentMethods?.find(
            (employeePaymentMethod) => employeePaymentMethod.key === EmployeePaymentMethods.BANK_TRANSFER
          )?.id;
        }
      })
    );

    this.isCreating$ = this.financesStore$.pipe(select(fromFinancesStore.getSelectedPayrollPaymentRefundCreating));
  }

  /**
   * Initialize paid form and add validators.
   */
  initForm() {
    const bankFeeFormControl = new FormControl(null);
    const bankFeeTaxValueFormControl = new FormControl(null);
    const paymentMethodIdFormControl = new FormControl(null, Validators.required);
    this.form = new FormGroup({
      paymentMethodId: paymentMethodIdFormControl,
      payrolls: new FormArray([], CustomValidators.arrayItems(1)),
      bankAccountId: new FormControl(null, Validators.required),
      bankFee: bankFeeFormControl,
      bankFeeTaxValue: bankFeeTaxValueFormControl,
      transactionDate: new FormControl(null),
      notes: new FormControl(''),
      attachments: new FormArray([]),
    });

    this.subscriptions.add(
      this.form.controls.paymentMethodId.valueChanges.subscribe(() => {
        this.onPaymentMethodTypeChange();
      })
    );

    this.addBlankAttachment();
  }

  /**
   * Adds a blank attachment form-control to the attachments form for quick start..
   */
  addBlankAttachment() {
    this.attachmentsForm.push(new FormControl());
  }

  /**
   * Submits the form.
   */
  submit() {
    const errorMessage = new NotificationMessage();
    if (this.form.invalid) {
      if (this.form.get('paymentMethodId').invalid) {
        errorMessage.title = this.translationService.translate(
          'FINANCES.HR.PAID_PAYROLLS.PAID_PAYROLLS_DATA_VALIDATION.PAYMENT_METHOD_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate(
            'FINANCES.HR.PAID_PAYROLLS.PAID_PAYROLLS_DATA_VALIDATION.PAYMENT_METHOD_IS_REQUIRED'
          ),
        ];
      } else if (
        this.form.get('bankAccountId').invalid &&
        this.form.get('paymentMethodId').value === this.employeePaymentMethodIdForBankTransfer
      ) {
        errorMessage.title = this.translationService.translate(
          'FINANCES.BANK_ACCOUNTS.BANK_ACCOUNT_DATA_VALIDATION.BANK_ACCOUNT_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate(
            'FINANCES.BANK_ACCOUNTS.BANK_ACCOUNT_DATA_VALIDATION.BANK_ACCOUNT_IS_REQUIRED'
          ),
        ];
      } else if (
        this.form.get('bankAccountId').invalid &&
        this.form.get('paymentMethodId').value === this.employeePaymentMethodIdForCash
      ) {
        errorMessage.title = this.translationService.translate(
          'FINANCES.BANK_ACCOUNTS.BANK_ACCOUNT_DATA_VALIDATION.CASH_ACCOUNT_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate(
            'FINANCES.BANK_ACCOUNTS.BANK_ACCOUNT_DATA_VALIDATION.CASH_ACCOUNT_IS_REQUIRED'
          ),
        ];
      } else if (this.form.get('bankFee').invalid) {
        errorMessage.title = this.translationService.translate(
          'FINANCES.BANK_ACCOUNTS.BANK_ACCOUNT_DATA_VALIDATION.BANK_FEE_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate(
            'FINANCES.BANK_ACCOUNTS.BANK_ACCOUNT_DATA_VALIDATION.BANK_FEE_LENGTH_ERROR'
          ),
        ];
      } else if (this.form.get('bankFeeTaxValue').invalid) {
        errorMessage.title = this.translationService.translate(
          'FINANCES.BANK_ACCOUNTS.BANK_ACCOUNT_DATA_VALIDATION.BANK_FEE_TAX_VALUE_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate(
            'FINANCES.BANK_ACCOUNTS.BANK_ACCOUNT_DATA_VALIDATION.BANK_FEE_TAX_VALUE_LENGTH_ERROR'
          ),
        ];
      } else if (this.form.get('payrolls').invalid) {
        /**
         * Check if gov service request payrolls count = 0.
         */
        if (!this.paidPayrollsForm.controls.length) {
          errorMessage.title = this.translationService.translate(
            'FINANCES.HR.PAID_PAYROLLS.PAID_PAYROLLS_DATA_VALIDATION.PAYROLLS_ERROR'
          );
          errorMessage.body = [
            this.translationService.translate(
              'FINANCES.HR.PAID_PAYROLLS.PAID_PAYROLLS_DATA_VALIDATION.PAYROLLS_COUNT_ERROR'
            ),
          ];
        }
      }

      this.form.markAllAsTouched();
      return this.notificationService.warningWithTitle(errorMessage);
    } else {
      this.openModal(this.confirmModalRef);
    }
  }

  /**
   * Confirms the form submit Reviewed payrolls.
   */
  confirm() {
    let fromDateFormatted;
    const { transactionDate } = this.form.value;
    if (transactionDate) {
      fromDateFormatted = new Date(transactionDate.year, transactionDate.month - 1, transactionDate.day);
      fromDateFormatted.setHours(new Date().getHours(), new Date().getMinutes());
    }

    /**
     * The list of paid payrolls in the paid payrolls form.
     */

    const payrolls = this.paidPayrollsForm.value.map((item: PaidPayrollFormItem) => item.paidPayrollId);

    /**
     * Dispatch update payrolls action.
     */
    this.financesStore$.dispatch(
      new fromFinancesStore.CreatePayrollPaymentRefund({
        bankAccountId: this.form.value.bankAccountId,
        employeePaymentMethodId: this.form.value.paymentMethodId,
        bankFee: this.form.value.bankFee ? this.form.value.bankFee : null,
        bankFeeTaxValue: this.form.value.bankFeeTaxValue ? this.form.value.bankFeeTaxValue : null,
        notes: this.form.value.notes ?? '',
        transactionDate: fromDateFormatted,
        payrolls,
        attachments: this.attachmentsForm.value.filter((file) => !!file),
      })
    );
  }

  /**
   * And Sets the conditional validation for the form based on the selected employee payment method id.
   */
  onPaymentMethodTypeChange() {
    /**
     * The id of the current selected employee payment method type.
     */
    const paymentMethodId = parseInt(this.form.controls.paymentMethodId.value ?? '0');

    /**
     * Check if the employee payment method type is bank transfer.
     */
    if (paymentMethodId === this.employeePaymentMethodIdForBankTransfer) {
      this.form.controls.bankFee.setValidators([Validators.required, CustomValidators.gte(0)]);
      this.form.controls.bankFeeTaxValue.setValidators([Validators.required, CustomValidators.gte(0)]);
    } else {
      this.form.controls.bankFee.clearValidators();
      this.form.controls.bankFee.setValue(null);
      this.form.controls.bankFeeTaxValue.clearValidators();
      this.form.controls.bankFeeTaxValue.setValue(null);
    }
    this.form.controls.bankFee.updateValueAndValidity();
    this.form.controls.bankFeeTaxValue.updateValueAndValidity();
  }

  /**
   * Opens the modal of the given templateRef.
   * @param modalRef The modal templateRef to be opened.
   */
  openModal(modalRef) {
    this.modalService.open(modalRef);
  }

  /**
   * Selects the newly selected bank account from the bank accounts search list.
   * @param bankAccounts The list of newly selected bank accounts to select the only one in the list.
   */
  selectBankAccount([bankAccount]: BankAccount[]) {
    if (bankAccount) {
      this.bankAccounts = [bankAccount];
      this.form.patchValue({ bankAccountId: bankAccount.id });
    }
  }
  /**
   * Confirms leaving the page before routing.
   */
  confirmDeactivate() {
    if (this.form.dirty === true) {
      /**
       * Message to warn the user before leaving the detailed form.
       */
      return confirm(this.translationService.translate('SHARED.CONFIRM.LEAVING'));
    }
    return true;
  }
}
