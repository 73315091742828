import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, FormArray, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { skip, tap } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Decimal } from 'decimal.js';

import * as fromSalesStore from 'sales/store';
import * as fromFinancesStore from 'finances/store';
import * as fromLookupsStore from 'lookups/store';
import {
  APP_CONSTANTS,
  CustomValidators,
  NotificationMessage,
  NotificationService,
  PageInfo,
  TranslationService,
} from 'shared';
import { CreateJournalLineInput, JournalLineFormItem, Account, BankAccount } from 'finances/models';
import { CustomerLogType, CustomerLogTypes } from 'lookups/models';
import { CanComponentDeactivate } from 'auth';

/**
 * The advance payment pages.
 */
enum PAGES {
  details = 'details',
  journal = 'journal',
}

@Component({
  selector: 'app-create-customer-advance-payment',
  templateUrl: './create-customer-advance-payment.component.html',
  styles: [],
})
export class CreateCustomerAdvancePaymentComponent implements OnInit, OnDestroy, CanComponentDeactivate {
  /**
   * The confirm modal template reference.
   */
  @ViewChild('confirmModalRef') confirmModalRef: ElementRef<any>;

  /**
   * The reset modal template reference.
   */
  @ViewChild('resetModalRef') resetModalRef: ElementRef<any>;

  /**
   * The decimal mask.
   */
  readonly DECIMAL_MASK = APP_CONSTANTS.numeric.decimal.mask;

  /**
   * Gets or sets the information about the current page.
   */
  pageInfo: PageInfo = {
    title: 'SALES.CUSTOMERS.CUSTOMER_DATA.CUSTOMER_ADVANCE_PAYMENT_TITLE',
    icon: 'fa fa-plus',
  };

  /**
   * Gets or sets the selected page.
   * @default 'details'
   */
  activePage: PAGES = PAGES.details;

  /**
   * Indicates whether there is a create payment process is running or not.
   */
  isCreating$: Observable<boolean>;

  /**
   * The create customer advance payment form.
   */
  form: FormGroup;

  /**
   * The id of the customer.
   */
  customerId: number;

  /**
   * The name of the current customer.
   */
  customerName: string;

  /**
   * The list of system supported customer advance payment log types.
   */
  customerLogTypes$: Observable<CustomerLogType[]>;

  /**
   * The list of bank accounts.
   */
  bankAccounts: BankAccount[] = [];

  /**
   * Shows or hide the bank accounts list.
   */
  bankAccountsListVisibility = false;

  /**
   * Gets the cash in hand account.
   */
  cashInHandAccount: Account;

  /**
   * Gets the trade debtors account.
   */
  tradeDebtorsAccount: Account;

  /**
   * Gets the cash in banks account.
   */
  cashInBanksAccount: Account;

  /**
   * Gets the checks under collection account.
   */
  checksUnderCollectionAccount: Account;

  /**
   * Gets or sets the id of the customer log type for `ADVANCE_PAYMENT_CASH`.
   */
  customerLogTypeIdForAdvancePaymentCash: number;

  /**
   * Gets or sets the id of the customer log type for `ADVANCE_PAYMENT_CHEQUE`.
   */
  customerLogTypeIdForAdvancePaymentCheque: number;

  /**
   * Gets or sets the id of the customer log type for `ADVANCE_PAYMENT_BANK_TRANSFER`.
   */
  customerLogTypeIdForAdvancePaymentBankTransfer: number;

  /**
   * Gets or sets the id of the customer log type for `ADVANCE_PAYMENT_ELECTRONIC`.
   */
  customerLogTypeIdForElectronicAdvancePayment: number;

  /**
   * The set of subscriptions on this components,
   * these subscriptions must be unsubscribed before this component got destroyed.
   */
  subscriptions = new Subscription();

  /**
   * Sets the initial journal lines should be added to the journals form.
   * @param journal lines An object contains `count` of initial blank journal lines.
   * @param journal lines A single journal line details to be added to the form.
   * @param journal lines A list of journal lines details to be added to the form.
   */
  initialJournalLinesSubject: BehaviorSubject<
    { count: number } | JournalLineFormItem | JournalLineFormItem[]
  > = new BehaviorSubject(undefined);

  /**
   * Gets the journal-lines form-array.
   */
  get journalLinesForm(): FormArray {
    return this.form?.controls.lines as FormArray;
  }

  /**
   * Gets the attachments form-array.
   */
  get attachmentsForm(): FormArray {
    return this.form?.controls.attachments as FormArray;
  }

  /**
   * Gets or sets the current selected language.
   */
  get currentLang() {
    return this.translationService.language;
  }

  /**
   * @param modalService The modal service.
   * @param route The activated route.
   * @param notificationService the notification service.
   * @param translationService the translation service.
   * @param salesStore$ the sales-store module.
   * @param financesStore$ the finances-store module.
   * @param lookupsStore$ the lookups-store module.
   */
  constructor(
    private modalService: NgbModal,
    private route: ActivatedRoute,
    private notificationService: NotificationService,
    private translationService: TranslationService,
    private salesStore$: Store<fromSalesStore.SalesState>,
    private financesStore$: Store<fromFinancesStore.FinancesState>,
    private lookupsStore$: Store<fromLookupsStore.LookupsState>
  ) {}

  ngOnInit(): void {
    this.init();
  }

  /**
   * Destroy component data
   */
  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  /**
   * Initialize component data.
   */
  init() {
    this.initForm();
    this.initJournalData();

    /**
     * Reset form controls when new customer advance payment is created.
     */
    this.subscriptions.add(
      this.salesStore$
        .pipe(
          select(fromSalesStore.getCustomerAdvancePaymentCreateCompleted),
          skip(1),
          tap((isCreated) => {
            if (isCreated) {
              this.initForm();
            }
          })
        )
        .subscribe()
    );

    /**
     * Fetch query params params.
     */
    if (this.route.snapshot.queryParamMap.has('customerId')) {
      this.customerId = +this.route.snapshot.queryParams.customerId;

      /**
       * Select the customer name.
       */
      this.subscriptions.add(
        this.salesStore$
          .pipe(
            select(fromSalesStore.getSelectedCustomer),
            tap((customer) => {
              if (customer) {
                this.customerName = this.currentLang === 'en' ? customer.nameEn : customer.name;

                /* Update page info. */
                this.pageInfo = {
                  ...this.pageInfo,
                  subTitle: `(${this.customerName})`,
                };
              }
            })
          )
          .subscribe()
      );
    }

    /* Load the data. */
    this.isCreating$ = this.salesStore$.pipe(select(fromSalesStore.getCustomerAdvancePaymentCreating));

    let isManualSearchTriggeredBeforeForTypes = false;
    this.customerLogTypes$ = this.lookupsStore$.pipe(
      select(fromLookupsStore.getCustomerAdvancePaymentLogTypes),
      tap((customerLogTypes) => {
        if (!isManualSearchTriggeredBeforeForTypes && !customerLogTypes.length) {
          isManualSearchTriggeredBeforeForTypes = true;
          this.lookupsStore$.dispatch(new fromLookupsStore.GetAllCustomerAdvancePaymentLogType());
        } else if (customerLogTypes?.length) {
          this.customerLogTypeIdForAdvancePaymentBankTransfer = customerLogTypes?.find(
            (customerLogType) => customerLogType.key === CustomerLogTypes.ADVANCE_PAYMENT_BANK_TRANSFER
          )?.id;

          this.customerLogTypeIdForAdvancePaymentCheque = customerLogTypes?.find(
            (customerLogType) => customerLogType.key === CustomerLogTypes.ADVANCE_PAYMENT_CHEQUE
          )?.id;

          this.customerLogTypeIdForAdvancePaymentCash = customerLogTypes?.find(
            (customerLogType) => customerLogType.key === CustomerLogTypes.ADVANCE_PAYMENT_CASH
          )?.id;

          this.customerLogTypeIdForElectronicAdvancePayment = customerLogTypes?.find(
            (customerLogType) => customerLogType.key === CustomerLogTypes.ADVANCE_PAYMENT_ELECTRONIC
          )?.id;
        }
      })
    );
  }

  /**
   * Initialize forms and add validators.
   */
  initForm() {
    const customerLogTypeIdFormControl = new FormControl(null, Validators.required);

    this.form = new FormGroup({
      customerLogTypeId: customerLogTypeIdFormControl,
      transactionDate: new FormControl(null),
      bankAccountId: new FormControl(null),
      extraDetails: new FormControl(''),
      notes: new FormControl('', [Validators.minLength(0), Validators.maxLength(200)]),
      value: new FormControl('', [Validators.required, CustomValidators.gt(0)]),
      attachments: new FormArray([]),
      lines: new FormArray([], CustomValidators.arrayItems(2)),
    });

    this.addBlankAttachment();
    this.setBlankJournalsCount();

    /**
     * Subscription when selected customer log type and payments form changes.
     */
    this.subscriptions.add(
      customerLogTypeIdFormControl.valueChanges.subscribe(() => {
        this.onCustomerLogTypeIdChange();
        this.onCustomerLogTypeChange();
      })
    );
  }

  /**
   * Initialize the journal data.
   */
  initJournalData() {
    /**
     * Fetch system accounts then fill the journal line form with its lines.
     */

    let isManualSearchTriggeredBeforeForCashInBanksAccount = false;
    this.subscriptions.add(
      /**
       * Cash In Banks Account.
       */
      this.financesStore$
        .pipe(
          select(fromFinancesStore.getCashInBanksAccount),
          tap((account) => {
            this.cashInBanksAccount = account;
            if (account) {
              this.onCustomerLogTypeIdChange();
            } else if (!account && !isManualSearchTriggeredBeforeForCashInBanksAccount) {
              isManualSearchTriggeredBeforeForCashInBanksAccount = true;
              this.financesStore$.dispatch(new fromFinancesStore.FindCashInBanksAccount());
            }
          })
        )
        .subscribe()
    );

    let isManualSearchTriggeredBeforeForCashInHandAccount = false;
    this.subscriptions.add(
      /**
       * Cash In Hand Account.
       */
      this.financesStore$
        .pipe(
          select(fromFinancesStore.getCashInHandAccount),
          tap((account) => {
            this.cashInHandAccount = account;
            if (account) {
              this.onCustomerLogTypeIdChange();
            } else if (!account && !isManualSearchTriggeredBeforeForCashInHandAccount) {
              isManualSearchTriggeredBeforeForCashInHandAccount = true;
              this.financesStore$.dispatch(new fromFinancesStore.FindCashInHandAccount());
            }
          })
        )
        .subscribe()
    );

    let isManualSearchTriggeredBeforeForTradeDebtorsAccount = false;
    this.subscriptions.add(
      /**
       * Account trade debtors Account.
       */
      this.financesStore$
        .pipe(
          select(fromFinancesStore.getTradeDebtorsAccount),
          tap((account) => {
            this.tradeDebtorsAccount = account;
            if (account) {
              this.onCustomerLogTypeIdChange();
            } else if (!account && !isManualSearchTriggeredBeforeForTradeDebtorsAccount) {
              isManualSearchTriggeredBeforeForTradeDebtorsAccount = true;
              this.financesStore$.dispatch(new fromFinancesStore.FindTradeDebtorsAccount());
            }
          })
        )
        .subscribe()
    );

    let isManualSearchTriggeredBeforeForChecksUnderCollectionAccount = false;
    this.subscriptions.add(
      /**
       * Account checks under collection Account.
       */
      this.financesStore$
        .pipe(
          select(fromFinancesStore.getChecksUnderCollectionAccount),
          tap((account) => {
            this.checksUnderCollectionAccount = account;
            if (account) {
              this.onCustomerLogTypeIdChange();
            } else if (!account && !isManualSearchTriggeredBeforeForChecksUnderCollectionAccount) {
              isManualSearchTriggeredBeforeForChecksUnderCollectionAccount = true;
              this.financesStore$.dispatch(new fromFinancesStore.FindChecksUnderCollectionAccount());
            }
          })
        )
        .subscribe()
    );
  }

  /**
   * Adds a blank attachment form-control to the attachments form for quick start..
   */
  addBlankAttachment() {
    this.attachmentsForm.push(new FormControl());
  }

  /**
   * Sets the count of the journal lines should be added to the journals form for quick start..
   */
  setBlankJournalsCount() {
    this.initialJournalLinesSubject.next({ count: 0 });
  }

  /**
   * Resets journal lines data.
   */
  resetJournalLines() {
    this.journalLinesForm.clear();
  }

  /**
   * Opens the modal of the given templateRef.
   * @param modalRef The modal templateRef to be opened.
   */
  openModal(modalRef) {
    this.modalService.open(modalRef);
  }

  /**
   * Open confirm reset invoice modal .
   */
  openResetModal() {
    this.openModal(this.resetModalRef);
  }

  /**
   * Resets all form data.
   */
  resetForm() {
    this.form.reset({
      customerLogTypeId: null,
      extraDetails: '',
      notes: '',
      value: '',
      transactionDate: {},
      bankAccountId: null,
    });
    this.attachmentsForm.clear();
    this.addBlankAttachment();
    this.resetJournalLines();
  }

  /**
   * Submits the form.
   */
  submit() {
    let errorMessage = new NotificationMessage();
    const customerLogTypeId = parseInt(this.form.controls.customerLogTypeId.value ?? '0');

    if (this.form.invalid) {
      if (this.form.get('customerLogTypeId').invalid) {
        errorMessage.title = this.translationService.translate(
          'SALES.CUSTOMERS.CUSTOMER_DATA_VALIDATION.ADVANCE_PAYMENT_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate('SALES.CUSTOMERS.CUSTOMER_DATA_VALIDATION.ADVANCE_PAYMENT_IS_REQUIRED'),
        ];
        this.activePage = PAGES.details;
      } else if (this.form.get('bankAccountId').invalid) {
        if (customerLogTypeId === this.customerLogTypeIdForAdvancePaymentCash) {
          errorMessage.title = this.translationService.translate(
            'SALES.CUSTOMERS.CUSTOMER_DATA_VALIDATION.CASH_ACCOUNT_ERROR'
          );
          errorMessage.body = [
            this.translationService.translate('SALES.CUSTOMERS.CUSTOMER_DATA_VALIDATION.CASH_ACCOUNT_IS_REQUIRED'),
          ];
        } else {
          errorMessage.title = this.translationService.translate(
            'SALES.CUSTOMERS.CUSTOMER_DATA_VALIDATION.BANK_ACCOUNT_ERROR'
          );
          errorMessage.body = [
            this.translationService.translate('SALES.CUSTOMERS.CUSTOMER_DATA_VALIDATION.BANK_ACCOUNT_IS_REQUIRED'),
          ];
        }
        this.activePage = PAGES.details;
      } else if (this.form.get('extraDetails').invalid) {
        errorMessage.title = this.translationService.translate(
          'SALES.CUSTOMERS.CUSTOMER_DATA_VALIDATION.EXTRA_DETAILS_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate('SALES.CUSTOMERS.CUSTOMER_DATA_VALIDATION.EXTRA_DETAILS_IS_REQUIRED'),
        ];
        this.activePage = PAGES.details;
      } else if (this.form.get('value').invalid) {
        errorMessage.title = this.translationService.translate(
          'SALES.CUSTOMERS.CUSTOMER_DATA_VALIDATION.ADVANCE_PAYMENT_VALUE_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate(
            'SALES.CUSTOMERS.CUSTOMER_DATA_VALIDATION.ADVANCE_PAYMENT_VALUE_IS_REQUIRED'
          ),
          this.translationService.translate(
            'SALES.CUSTOMERS.CUSTOMER_DATA_VALIDATION.ADVANCE_PAYMENT_VALUE_LENGTH_ERROR'
          ),
        ];
        this.activePage = PAGES.details;
      } else if (this.form.get('notes').invalid) {
        errorMessage.title = this.translationService.translate('SALES.CUSTOMERS.CUSTOMER_DATA_VALIDATION.NOTES_ERROR');
        errorMessage.body = [
          this.translationService.translate('SALES.CUSTOMERS.CUSTOMER_DATA_VALIDATION.NOTES_LENGTH_ERROR'),
        ];
        this.activePage = PAGES.details;
      } else if (this.form.get('lines').invalid) {
        /**
         * Check if journal lines count < 2.
         */
        if (this.journalLinesForm.controls.length < 2) {
          errorMessage.title = this.translationService.translate(
            'SALES.CUSTOMERS.CUSTOMER_DATA_VALIDATION.JOURNALS_LINES_ERROR'
          );
          errorMessage.body = [
            this.translationService.translate('SALES.CUSTOMERS.CUSTOMER_DATA_VALIDATION.JOURNALS_LINES_COUNT_ERROR'),
          ];
        } else {
          /**
           * Check if some of journals has errors.
           */
          for (let index = 0; index < this.journalLinesForm.controls.length; index++) {
            const journal = this.journalLinesForm.controls[index];

            if (journal.valid) {
              continue;
            }

            errorMessage.title = this.translationService.translate(
              'SALES.CUSTOMERS.CUSTOMER_DATA_VALIDATION.JOURNAL_LINE_NUMBER_ERROR',
              {
                journalNumber: index + 1,
              }
            );
            errorMessage.body = [];

            if (journal.get('accountId').invalid) {
              errorMessage.body.push(
                this.translationService.translate('SALES.CUSTOMERS.CUSTOMER_DATA_VALIDATION.ACCOUNT_ERROR')
              );
              errorMessage.body.push(
                this.translationService.translate('SALES.CUSTOMERS.CUSTOMER_DATA_VALIDATION.ACCOUNT_IS_REQUIRED')
              );
            }

            if (journal.get('credit').invalid) {
              errorMessage.body.push(
                this.translationService.translate('SALES.CUSTOMERS.CUSTOMER_DATA_VALIDATION.CREDIT_IS_REQUIRED')
              );
            }

            if (journal.get('debit').invalid) {
              errorMessage.body.push(
                this.translationService.translate('SALES.CUSTOMERS.CUSTOMER_DATA_VALIDATION.DEBIT_IS_REQUIRED')
              );
            }

            if (journal.get('costCenterId').invalid) {
              errorMessage.body.push(
                this.translationService.translate('SALES.CUSTOMERS.CUSTOMER_DATA_VALIDATION.COST_CENTER_ERROR')
              );
              errorMessage.body.push(
                this.translationService.translate('SALES.CUSTOMERS.CUSTOMER_DATA_VALIDATION.COST_CENTER_IS_REQUIRED')
              );
            }

            if (journal.get('notes').invalid) {
              errorMessage.body.push(
                this.translationService.translate('SALES.CUSTOMERS.CUSTOMER_DATA_VALIDATION.NOTES_ERROR')
              );
              errorMessage.body.push(
                this.translationService.translate('SALES.CUSTOMERS.CUSTOMER_DATA_VALIDATION.NOTES_IS_REQUIRED')
              );
              errorMessage.body.push(
                this.translationService.translate(
                  'SALES.CUSTOMERS.CUSTOMER_DATA_VALIDATION.NOTES_REQUIRED_LENGTH_ERROR'
                )
              );
            }

            break;
          }
        }

        this.activePage = PAGES.journal;
      }

      this.form.markAllAsTouched();
      return this.notificationService.warningWithTitle(errorMessage);
    }

    errorMessage = new NotificationMessage();
    const lines: JournalLineFormItem[] = this.journalLinesForm.value;

    if (
      lines.some((line) => line.debit === 0 && line.credit === 0) ||
      lines.some((line) => line.debit !== 0 && line.credit !== 0)
    ) {
      errorMessage.title = this.translationService.translate(
        'SALES.CUSTOMERS.CUSTOMER_DATA_VALIDATION.JOURNALS_LINES_TITLE_ERROR'
      );
      errorMessage.body = [
        this.translationService.translate('SALES.CUSTOMERS.CUSTOMER_DATA_VALIDATION.DEBIT_CREDIT_ERROR'),
        this.translationService.translate('SALES.CUSTOMERS.CUSTOMER_DATA_VALIDATION.JOURNALS_LINES_TYPE_ERROR'),
      ];
      this.activePage = PAGES.journal;

      this.form.markAllAsTouched();
      return this.notificationService.warningWithTitle(errorMessage);
    } else if (
      !Decimal.sum(0, ...lines.filter((line) => !!line.debit).map((line) => line.debit)).eq(
        Decimal.sum(0, ...lines.filter((line) => !!line.credit).map((line) => line.credit))
      )
    ) {
      errorMessage.title = this.translationService.translate(
        'SALES.CUSTOMERS.CUSTOMER_DATA_VALIDATION.JOURNALS_LINES_TOTAL_ERROR'
      );
      errorMessage.body = [
        this.translationService.translate('SALES.CUSTOMERS.CUSTOMER_DATA_VALIDATION.JOURNALS_LINES_TOTAL_VALUE_ERROR'),
      ];
      this.activePage = PAGES.journal;

      this.form.markAllAsTouched();
      return this.notificationService.warningWithTitle(errorMessage);
    }

    this.openModal(this.confirmModalRef);
  }

  /**
   * Confirms the form submit.
   */
  confirm() {
    let fromDateFormatted;
    const { transactionDate } = this.form.value;

    if (transactionDate) {
      fromDateFormatted = new Date(transactionDate.year, transactionDate.month - 1, transactionDate.day);
      fromDateFormatted.setHours(new Date().getHours(), new Date().getMinutes());
    }

    /**
     * The list of lines in the lines form.
     */
    const lines = this.journalLinesForm.value
      .filter((line: JournalLineFormItem) => line.credit > 0 || line.debit > 0)
      .map((line) => {
        const inputLine: CreateJournalLineInput = {
          accountId: line.accountId,
          debit: line.debit,
          credit: line.credit,
          costCenterId: line.costCenterId,
          notes: line.notes,
        };

        return inputLine;
      });

    this.salesStore$.dispatch(
      new fromSalesStore.CreateCustomerAdvancePayment({
        customerId: this.customerId,
        bankAccountId: this.form.value.bankAccountId,
        extraDetails: this.form.value.extraDetails,
        customerLogTypeId: this.form.value.customerLogTypeId,
        value: this.form.value.value,
        notes: this.form.value.notes,
        transactionDate: fromDateFormatted,
        attachments: this.attachmentsForm.value.filter((file) => !!file),
        lines,
      })
    );
  }

  /**
   * Handles the changes on selected customer log type id.
   */
  onCustomerLogTypeIdChange() {
    /**
     * The id of the current selected customer log type.
     */
    const customerLogTypeId = parseInt(this.form.controls.customerLogTypeId.value ?? '0');
    const value = this.form.controls.value.value ? parseInt(this.form.controls.value.value) : 0;

    /**
     * Set Journal Lines based on the selected customer log type.
     */
    this.resetJournalLines();

    /**
     * The list of journal lines to be added to the form.
     */
    const lines: JournalLineFormItem[] = [];

    if (customerLogTypeId === this.customerLogTypeIdForAdvancePaymentBankTransfer && this.cashInBanksAccount) {
      /**
       * Add line for bank transfer payment.
       */
      lines.push({
        accountId: this.cashInBanksAccount.id,
        account: this.cashInBanksAccount,
        costCenterId: null,
        credit: 0,
        debit: value,
        notes: '',
        disableAccountChange: true,
        disableDebitAndCreditValueChange: true,
      });
    } else if (
      customerLogTypeId === this.customerLogTypeIdForAdvancePaymentCheque &&
      this.checksUnderCollectionAccount
    ) {
      /**
       * Add line for payment cheque.
       */
      lines.push({
        accountId: this.checksUnderCollectionAccount.id,
        account: this.checksUnderCollectionAccount,
        costCenterId: null,
        credit: 0,
        debit: value,
        notes: '',
        disableAccountChange: true,
        disableDebitAndCreditValueChange: true,
      });
    } else if (customerLogTypeId === this.customerLogTypeIdForElectronicAdvancePayment && this.cashInBanksAccount) {
      /**
       * Add line for  electronic payment.
       */
      lines.push({
        accountId: this.cashInBanksAccount.id,
        account: this.cashInBanksAccount,
        costCenterId: null,
        credit: 0,
        debit: value,
        notes: '',
        disableAccountChange: true,
        disableDebitAndCreditValueChange: true,
      });
    } else if (customerLogTypeId === this.customerLogTypeIdForAdvancePaymentCash && this.cashInHandAccount) {
      /**
       * Add line for payment cash.
       */
      lines.push({
        accountId: this.cashInHandAccount.id,
        account: this.cashInHandAccount,
        costCenterId: null,
        credit: 0,
        debit: value,
        notes: '',
        disableAccountChange: true,
        disableDebitAndCreditValueChange: true,
      });
    }

    /**
     * Add line for trade debtors account.
     */
    if (this.tradeDebtorsAccount) {
      lines.push({
        accountId: this.tradeDebtorsAccount.id,
        account: this.tradeDebtorsAccount,
        costCenterId: null,
        credit: value,
        debit: 0,
        notes: '',
        disableAccountChange: true,
        disableDebitAndCreditValueChange: true,
      });
    }

    this.initialJournalLinesSubject.next(lines);
  }

  /**
   * And Sets the conditional validation for the form based on the selected customer log type id.
   */
  onCustomerLogTypeChange() {
    /**
     * Set validation first.
     */

    /**
     * The id of the current selected customer log type.
     */
    const customerLogTypeId = parseInt(this.form.controls.customerLogTypeId.value ?? '0');

    /**
     * Check if the customer log type is payment cheque & payment bank transfer & electronic payment.
     */
    if (
      customerLogTypeId === this.customerLogTypeIdForAdvancePaymentBankTransfer ||
      customerLogTypeId === this.customerLogTypeIdForElectronicAdvancePayment ||
      customerLogTypeId === this.customerLogTypeIdForAdvancePaymentCash
    ) {
      this.form.controls.bankAccountId.setValidators(Validators.required);
    } else {
      this.form.controls.bankAccountId.clearValidators();
      this.form.controls.bankAccountId.setValue(null);
    }
    this.form.controls.bankAccountId.setValue(null);
    this.form.controls.bankAccountId.updateValueAndValidity();

    /**
     * Check if the customer log type is payment cheque & payment other.
     */
    if (customerLogTypeId === this.customerLogTypeIdForAdvancePaymentCheque) {
      this.form.controls.extraDetails.setValidators([
        Validators.minLength(1),
        Validators.maxLength(100),
        Validators.required,
      ]);
    } else {
      this.form.controls.extraDetails.clearValidators();
    }

    this.form.controls.extraDetails.setValue('');
    this.form.controls.extraDetails.updateValueAndValidity();
  }

  /**
   * Selects the newly selected bank account from the bank accounts search list.
   * @param bankAccounts The list of newly selected bank accounts to select the only one in the list.
   */
  selectBankAccount([bankAccount]: BankAccount[]) {
    if (bankAccount) {
      this.bankAccounts = [bankAccount];
      this.form.patchValue({ bankAccountId: bankAccount.id });
    }
  }

  /**
   * Confirms leaving the page before routing.
   */
  confirmDeactivate() {
    if (this.form.dirty === true) {
      /**
       * Message to warn the user before leaving the detailed form.
       */
      return confirm(this.translationService.translate('SHARED.CONFIRM.LEAVING'));
    }
    return true;
  }
}
