import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import * as fromContainers from 'finances/containers';
import * as fromComponents from 'finances/components';
import { Claims } from 'security/models';
import { ConfirmationGuard } from 'auth/guards';

const routes: Routes = [
  // petty-cash-routes
  {
    path: 'petty-cash/create',
    component: fromComponents.CreatePettyCashComponent,
    data: { claims: [Claims.CREATE_PETTY_CASH] },
  },
  {
    path: 'petty-cash/update/:pettyCashId',
    component: fromComponents.UpdatePettyCashComponent,
    data: { claims: [Claims.UPDATE_PETTY_CASH] },
  },
  {
    path: 'petty-cash',
    component: fromComponents.PettyCashListComponent,
    data: {
      claims: [
        Claims.CREATE_PETTY_CASH,
        Claims.UPDATE_PETTY_CASH,
        Claims.DELETE_PETTY_CASH,
        Claims.BLOCK_PETTY_CASH,
        Claims.ACTIVATE_PETTY_CASH,
      ],
    },
  },

  // petty-cash-refund-requests-routes
  {
    path: 'petty-cash-refund-requests/create',
    component: fromComponents.CreatePettyCashRefundRequestComponent,
    data: { claims: [Claims.CREATE_PETTY_CASH_REFUND_REQUEST] },
    canDeactivate: [ConfirmationGuard],
  },
  {
    path: 'petty-cash-refund-requests',
    component: fromComponents.PettyCashRefundRequestsListComponent,
    data: { claims: [Claims.CREATE_PETTY_CASH_REFUND_REQUEST] },
  },
  {
    path: 'petty-cash-refund-requests/view/:requestId',
    component: fromComponents.PettyCashRefundRequestDetailsComponent,
    data: { claims: [Claims.CREATE_PETTY_CASH_REFUND_REQUEST] },
  },
  {
    path: 'petty-cash-refund-requests/update/:requestId',
    component: fromComponents.UpdatePettyCashRefundRequestComponent,
    data: { claims: [Claims.CREATE_PETTY_CASH_REFUND_REQUEST, Claims.APPROVE_PETTY_CASH_REFUND_REQUEST] },
  },
  // petty-cash-refunds-routes
  {
    path: 'petty-cash-refunds/create',
    component: fromComponents.CreatePettyCashRefundComponent,
    data: { claims: [Claims.CREATE_PETTY_CASH_REFUND] },
    canDeactivate: [ConfirmationGuard],
  },
  {
    path: 'petty-cash-refunds',
    component: fromComponents.PettyCashRefundsListComponent,
    data: { claims: [Claims.CREATE_PETTY_CASH_REFUND] },
  },

  // petty-cash-refund-request-approvals-routes
  {
    path: 'petty-cash-refund-request-approvals/create',
    component: fromComponents.CreatePettyCashRefundRequestsApprovalComponent,
    data: {
      claims: [Claims.APPROVE_PETTY_CASH_REFUND_REQUEST],
    },
    canDeactivate: [ConfirmationGuard],
  },
  {
    path: 'petty-cash-refund-request-approvals',
    component: fromComponents.PettyCashRefundRequestsApprovalsListComponent,
    data: {
      claims: [Claims.APPROVE_PETTY_CASH_REFUND_REQUEST],
    },
  },
  {
    path: 'petty-cash-refund-request-approvals/view/:requestId',
    component: fromComponents.PettyCashRefundRequestApprovalDetailsComponent,
    data: {
      claims: [Claims.APPROVE_PETTY_CASH_REFUND_REQUEST],
    },
  },

  // petty-cash-reductions-routes
  {
    path: 'petty-cash-reductions/create',
    component: fromComponents.CreatePettyCashReductionComponent,
    data: { claims: [Claims.CREATE_PETTY_CASH_REDUCTION] },
    canDeactivate: [ConfirmationGuard],
  },
  {
    path: 'petty-cash-reductions',
    component: fromComponents.PettyCashReductionsListComponent,
    data: { claims: [Claims.CREATE_PETTY_CASH_REDUCTION] },
  },

  // cost-centers-routes
  {
    path: 'cost-centers/create',
    component: fromComponents.CreateCostCenterComponent,
    data: { claims: [Claims.CREATE_COST_CENTER] },
  },
  {
    path: 'cost-centers/update/:costCenterId',
    component: fromComponents.UpdateCostCenterComponent,
    data: { claims: [Claims.UPDATE_COST_CENTER] },
  },
  {
    path: 'cost-centers',
    component: fromComponents.CostCenterListComponent,
    data: { claims: [Claims.CREATE_COST_CENTER, Claims.UPDATE_COST_CENTER, Claims.DELETE_COST_CENTER] },
  },

  // accounts-routes
  {
    path: 'accounts/create',
    component: fromComponents.CreateAccountComponent,
    data: { claims: [Claims.CREATE_ACCOUNT] },
  },
  {
    path: 'accounts/update/:accountId',
    component: fromComponents.UpdateAccountComponent,
    data: { claims: [Claims.UPDATE_ACCOUNT] },
  },
  {
    path: 'accounts/create-secondary-account',
    component: fromComponents.CreateSecondaryAccountComponent,
    data: { claims: [Claims.CREATE_ACCOUNT] },
  },
  {
    path: 'accounts/update-secondary-account/:accountId',
    component: fromComponents.UpdateSecondaryAccountComponent,
    data: { claims: [Claims.UPDATE_ACCOUNT] },
  },
  {
    path: 'accounts',
    component: fromComponents.AccountsListComponent,
    data: { claims: [Claims.CREATE_ACCOUNT, Claims.UPDATE_ACCOUNT, Claims.DELETE_ACCOUNT] },
  },

  // journals-routes
  {
    path: 'journals/create',
    component: fromComponents.CreateJournalComponent,
    data: { claims: [Claims.CREATE_JOURNAL] },
  },
  {
    path: 'journals',
    component: fromComponents.JournalsListComponent,
    data: { claims: [Claims.CREATE_JOURNAL] },
  },
  {
    path: 'journals/view/:journalId',
    component: fromContainers.ViewJournalTransactionComponent,
    data: { claims: [Claims.CREATE_JOURNAL] },
  },

  // banks-routes
  {
    path: 'banks/create',
    component: fromComponents.CreateBankComponent,
    data: { claims: [Claims.CREATE_BANK] },
  },
  {
    path: 'banks/update/:bankId',
    component: fromComponents.UpdateBankComponent,
    data: { claims: [Claims.UPDATE_BANK] },
  },
  {
    path: 'banks',
    component: fromComponents.BanksListComponent,
    data: { claims: [Claims.CREATE_BANK, Claims.UPDATE_BANK, Claims.DELETE_BANK] },
  },

  // bank accounts-routes
  {
    path: 'bank-accounts/create',
    component: fromComponents.CreateBankAccountComponent,
    data: { claims: [Claims.CREATE_BANK_ACCOUNT] },
  },
  {
    path: 'bank-accounts/create-bank-account-cheque-deposit',
    component: fromComponents.CreateBankAccountChequeDepositComponent,
    data: { claims: [Claims.CREATE_BANK_ACCOUNT_TRANSACTION] },
    canDeactivate: [ConfirmationGuard],
  },
  {
    path: 'bank-accounts/create-bank-account-deposit-by-employee',
    component: fromComponents.CreateBankAccountDepositByEmployeeComponent,
    data: { claims: [Claims.CREATE_BANK_ACCOUNT_TRANSACTION] },
    canDeactivate: [ConfirmationGuard],
  },
  {
    path: 'bank-accounts/create-bank-account-by-withdraw-from-account',
    component: fromComponents.CreateBankAccountWithdrawComponent,
    data: { claims: [Claims.CREATE_BANK_ACCOUNT_TRANSACTION] },
    canDeactivate: [ConfirmationGuard],
  },
  {
    path: 'bank-accounts/create-bank-account-money-transfer',
    component: fromComponents.CreateBankAccountMoneyTransferComponent,
    data: { claims: [Claims.CREATE_BANK_ACCOUNT_TRANSACTION] },
    canDeactivate: [ConfirmationGuard],
  },
  {
    path: 'bank-accounts/create-bank-account-cash-receipt',
    component: fromComponents.CreateBankAccountCashReceiptComponent,
    data: { claims: [Claims.CREATE_BANK_ACCOUNT_TRANSACTION] },
    canDeactivate: [ConfirmationGuard],
  },
  {
    path: 'bank-accounts/create-bank-account-settlement-deed',
    component: fromComponents.CreateBankAccountSettlementDeedComponent,
    data: { claims: [Claims.CREATE_BANK_ACCOUNT_TRANSACTION] },
    canDeactivate: [ConfirmationGuard],
  },
  {
    path: 'bank-accounts/files/:bankAccountId',
    component: fromContainers.BankAccountFileComponent,
    data: {},
  },
  {
    path: 'bank-accounts',
    component: fromComponents.BankAccountsListComponent,
    data: { claims: [Claims.CREATE_BANK_ACCOUNT, Claims.DELETE_BANK_ACCOUNT] },
  },

  // hr-routes
  {
    path: 'hr/payroll-payment',
    component: fromComponents.CreatePayrollPaymentComponent,
    data: {
      claims: [],
    },
    canDeactivate: [ConfirmationGuard],
  },
  {
    path: 'hr/payroll-payments/view/:payrollPaymentId',
    component: fromComponents.PayrollPaymentDetailsComponent,
  },
  {
    path: 'hr/payroll-payment-refund',
    component: fromComponents.CreatePayrollPaymentRefundComponent,
    data: {
      claims: [],
    },
    canDeactivate: [ConfirmationGuard],
  },
  {
    path: 'hr/payroll-payment-refunds/view/:payrollPaymentRefundId',
    component: fromComponents.PayrollPaymentRefundDetailsComponent,
  },
  {
    path: 'hr/governmental-service-requests/view/:govServiceRequestId',
    component: fromComponents.GovServiceRequestDetailsComponent,
  },
  {
    path: 'hr/governmental-service-requests/pay/:govServiceRequestId',
    component: fromComponents.CreatePayGovServiceRequestComponent,
  },
  {
    path: 'hr/governmental-service-requests',
    component: fromComponents.GovServiceRequestsListComponent,
  },
  {
    path: 'hr',
    component: fromContainers.HrContainerComponent,
    data: { claims: [] },
  },

  // financial-periods-routes
  {
    path: 'financial-periods',
    component: fromComponents.FinancialPeriodsListComponent,
    data: {
      claims: [Claims.OPEN_FINANCIAL_PERIOD, Claims.PAUSE_FINANCIAL_PERIOD, Claims.CLOSE_FINANCIAL_PERIOD],
    },
  },

  // currencies-routes
  {
    path: 'currencies/create',
    component: fromComponents.CreateCurrencyComponent,
    data: { claims: [Claims.CREATE_CURRENCY] },
  },
  {
    path: 'currencies/update/:currencyId',
    component: fromComponents.UpdateCurrencyComponent,
    data: { claims: [Claims.UPDATE_CURRENCY] },
  },
  {
    path: 'currencies',
    component: fromComponents.CurrencyListComponent,
    data: { claims: [Claims.CREATE_CURRENCY, Claims.UPDATE_CURRENCY, Claims.DELETE_CURRENCY] },
  },

  // currency-exchange-rates-routes
  {
    path: 'currency-exchange-rates/create',
    component: fromComponents.CreateCurrencyExchangeRateComponent,
    data: { claims: [Claims.CREATE_CURRENCY_EXCHANGE_RATE] },
  },
  {
    path: 'currency-exchange-rates/update/:currencyExchangeRateId',
    component: fromComponents.UpdateCurrencyExchangeRateComponent,
    data: { claims: [Claims.UPDATE_CURRENCY_EXCHANGE_RATE] },
  },
  {
    path: 'currency-exchange-rates',
    component: fromComponents.CurrencyExchangeRatesListComponent,
    data: {
      claims: [
        Claims.CREATE_CURRENCY_EXCHANGE_RATE,
        Claims.UPDATE_CURRENCY_EXCHANGE_RATE,
        Claims.DELETE_CURRENCY_EXCHANGE_RATE,
      ],
    },
  },
  {
    path: 'finance-configurations',
    component: fromComponents.FinanceConfigurationsComponent,
    data: {
      claims: [Claims.UPDATE_FINANCE_CONFIGURATIONS],
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class FinancesRoutingModule {}
