import { Action } from '@ngrx/store';

import { AppHttpResponse } from 'shared';
import { CreateUnitOfMeasureInput, UnitOfMeasure, UpdateUnitOfMeasureInput } from 'stores/models';

/**
 * The stores-module store unit of measure action types.
 */
export enum UnitsOfMeasureActionType {
  SEARCH_UNITS_OF_MEASURE = '[Stores] [UnitsOfMeasure] Search Units Of Measure',
  SEARCH_UNITS_OF_MEASURE_FAIL = '[Stores] [UnitsOfMeasure] Search Units Of Measure Fail',
  SEARCH_UNITS_OF_MEASURE_SUCCESS = '[Stores] [UnitsOfMeasure] Search Units Of Measure Success',

  GET_ALL_UNITS_OF_MEASURE = '[Stores] [UnitsOfMeasure] Get All Units Of Measure',
  GET_ALL_UNITS_OF_MEASURE_FAIL = '[Stores] [UnitsOfMeasure] Get All Units Of Measure Fail',
  GET_ALL_UNITS_OF_MEASURE_SUCCESS = '[Stores] [UnitsOfMeasure] Get All Units Of Measure Success',

  FIND_UNIT_OF_MEASURE = '[Stores] [UnitsOfMeasure] Find Unit Of Measure',
  FIND_UNIT_OF_MEASURE_FAIL = '[Stores] [UnitsOfMeasure] Find Unit Of Measure Fail',
  FIND_UNIT_OF_MEASURE_SUCCESS = '[Stores] [UnitsOfMeasure] Find Unit Of Measure Success',

  CREATE_UNIT_OF_MEASURE = '[Stores] [UnitsOfMeasure] Create Unit Of Measure',
  CREATE_UNIT_OF_MEASURE_FAIL = '[Stores] [UnitsOfMeasure] Create Unit Of Measure Fail',
  CREATE_UNIT_OF_MEASURE_SUCCESS = '[Stores] [UnitsOfMeasure] Create Unit Of Measure Success',

  UPDATE_UNIT_OF_MEASURE = '[Stores] [UnitsOfMeasure] Update Unit Of Measure',
  UPDATE_UNIT_OF_MEASURE_FAIL = '[Stores] [UnitsOfMeasure] Update Unit Of Measure Fail',
  UPDATE_UNIT_OF_MEASURE_SUCCESS = '[Stores] [UnitsOfMeasure] Update Unit Of Measure Success',

  DELETE_UNIT_OF_MEASURE = '[Stores] [UnitsOfMeasure] Delete Unit Of Measure',
  DELETE_UNIT_OF_MEASURE_FAIL = '[Stores] [UnitsOfMeasure] Delete Unit Of Measure Fail',
  DELETE_UNIT_OF_MEASURE_SUCCESS = '[Stores] [UnitsOfMeasure] Delete Unit Of Measure Success',
}

/**
 * Represents a store Units Of Measure search action.
 */
export class SearchUnitsOfMeasure implements Action {
  /**
   * The type of the action.
   */
  readonly type = UnitsOfMeasureActionType.SEARCH_UNITS_OF_MEASURE;

  /**
   * Fires a new units of measure search action.
   * @param payload the search parameters,
   * if omitted, all units of measure will be loaded.
   */
  constructor(
    public payload: {
      name: string;
      unitOfMeasureClasses: number[];
      page: number;
    }
  ) {}
}

/**
 * Represents a store units of measure search fail action.
 */
export class SearchUnitsOfMeasureFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = UnitsOfMeasureActionType.SEARCH_UNITS_OF_MEASURE_FAIL;

  /**
   * Fires a new units of measure search fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store search success action.
 */
export class SearchUnitsOfMeasureSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = UnitsOfMeasureActionType.SEARCH_UNITS_OF_MEASURE_SUCCESS;

  /**
   * Fires a new units of measure search success action.
   * @param payload An object contains the list of units of measure that matches the search criteria
   * in addition to the pagination info.
   */
  constructor(public payload: AppHttpResponse<UnitOfMeasure[]>) {}
}

/**
 * Represents a store Units Of Measure get all action.
 */
export class GetAllUnitsOfMeasure implements Action {
  /**
   * The type of the action.
   */
  readonly type = UnitsOfMeasureActionType.GET_ALL_UNITS_OF_MEASURE;

  /**
   * Fires a new units of measure get all action.
   * if omitted, all units of measure will be loaded.
   */
  constructor() {}
}

/**
 * Represents a store units of measure get all fail action.
 */
export class GetAllUnitsOfMeasureFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = UnitsOfMeasureActionType.GET_ALL_UNITS_OF_MEASURE_FAIL;

  /**
   * Fires a new units of measure get all fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store get all success action.
 */
export class GetAllUnitsOfMeasureSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = UnitsOfMeasureActionType.GET_ALL_UNITS_OF_MEASURE_SUCCESS;

  /**
   * Fires a new units of measure get all success action.
   * @param payload An object contains the list of units of measure that matches the get all criteria
   * in addition to the pagination info.
   */
  constructor(public payload: AppHttpResponse<UnitOfMeasure[]>) {}
}

/**
 * Represents a store find unit of measure action.
 */
export class FindUnitOfMeasure implements Action {
  /**
   * The type of the action.
   */
  readonly type = UnitsOfMeasureActionType.FIND_UNIT_OF_MEASURE;

  /**
   * Fires a new find unit of measure action.
   * @param payload the id of the unit of measure.
   */
  constructor(public payload: number) {}
}

/**
 * Represents a store find unit of measure fail action.
 */
export class FindUnitOfMeasureFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = UnitsOfMeasureActionType.FIND_UNIT_OF_MEASURE_FAIL;

  /**
   * Fires a new find unit of measure fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store find unit of measure success action.
 */
export class FindUnitOfMeasureSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = UnitsOfMeasureActionType.FIND_UNIT_OF_MEASURE_SUCCESS;

  /**
   * Fires a new find unit of measure success action.
   * @param payload The loaded unit of measure.
   */
  constructor(public payload: AppHttpResponse<UnitOfMeasure>) {}
}

/**
 * Represents a store unit of measure create action.
 */
export class CreateUnitOfMeasure implements Action {
  /** The type of the action. */
  readonly type = UnitsOfMeasureActionType.CREATE_UNIT_OF_MEASURE;

  /**
   * Fires a new unit of measure create action.
   * @param payload the new unit of measure data-model.
   */
  constructor(public payload: CreateUnitOfMeasureInput) {}
}

/**
 * Represents a store unit of measure create fail action.
 */
export class CreateUnitOfMeasureFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = UnitsOfMeasureActionType.CREATE_UNIT_OF_MEASURE_FAIL;

  /**
   * Fires a new unit of measure create fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store unit of measure create success action.
 */
export class CreateUnitOfMeasureSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = UnitsOfMeasureActionType.CREATE_UNIT_OF_MEASURE_SUCCESS;

  /**
   * Fires a new unit of measure create success action.
   * @param payload The created unit of measure.
   */
  constructor(public payload: AppHttpResponse<UnitOfMeasure>) {}
}

/**
 * Represents a store unit of measure update action.
 */
export class UpdateUnitOfMeasure implements Action {
  /**
   * The type of the action.
   */
  readonly type = UnitsOfMeasureActionType.UPDATE_UNIT_OF_MEASURE;

  /**
   * Fires a new unit of measure update action.
   * @param payload The  unit of measure updated data.
   */
  constructor(public payload: UpdateUnitOfMeasureInput) {}
}

/**
 * Represents a store unit of measure update fail action.
 */
export class UpdateUnitOfMeasureFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = UnitsOfMeasureActionType.UPDATE_UNIT_OF_MEASURE_FAIL;

  /**
   * Fires a new unit of measure update fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store unit of measure update success action.
 */
export class UpdateUnitOfMeasureSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = UnitsOfMeasureActionType.UPDATE_UNIT_OF_MEASURE_SUCCESS;

  /**
   * Fires a new unit of measure update-success action.
   * @param payload The updated unit of measure.
   */
  constructor(public payload: AppHttpResponse<UnitOfMeasure>) {}
}

/**
 * Represents a store unit of measure delete action.
 */
export class DeleteUnitOfMeasure implements Action {
  /**
   * The type of the action.
   */
  readonly type = UnitsOfMeasureActionType.DELETE_UNIT_OF_MEASURE;

  /**
   * Fires a new unit of measure delete action.
   * @param payload The unit of measure id.
   */
  constructor(public payload: number) {}
}

/**
 * Represents a store unit of measure delete fail action.
 */
export class DeleteUnitOfMeasureFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = UnitsOfMeasureActionType.DELETE_UNIT_OF_MEASURE_FAIL;

  /**
   * Fires a new unit of measure delete fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store unit of measure delete success action.
 */
export class DeleteUnitOfMeasureSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = UnitsOfMeasureActionType.DELETE_UNIT_OF_MEASURE_SUCCESS;

  /**
   * Fires a new unit of measure delete success action.
   * @param payload The deleted unit of measure.
   */
  constructor(public payload: AppHttpResponse<UnitOfMeasure>) {}
}

/**
 * stores-module units of measure action types.
 */
export type UnitsOfMeasureActions =
  | SearchUnitsOfMeasure
  | SearchUnitsOfMeasureFail
  | SearchUnitsOfMeasureSuccess
  | GetAllUnitsOfMeasure
  | GetAllUnitsOfMeasureFail
  | GetAllUnitsOfMeasureSuccess
  | FindUnitOfMeasure
  | FindUnitOfMeasureFail
  | FindUnitOfMeasureSuccess
  | CreateUnitOfMeasure
  | CreateUnitOfMeasureFail
  | CreateUnitOfMeasureSuccess
  | UpdateUnitOfMeasure
  | UpdateUnitOfMeasureFail
  | UpdateUnitOfMeasureSuccess
  | DeleteUnitOfMeasure
  | DeleteUnitOfMeasureFail
  | DeleteUnitOfMeasureSuccess;
