/**
 * The set of system supported gosi covered types.
 */
export enum GosiCoverageTypes {
  /**
   * The direct amount of gosi covered types.
   */
  DIRECT_AMOUNT = 'DIRECT_AMOUNT',

  /**
   * The percentage of gosi covered types.
   */
  PERCENTAGE = 'PERCENTAGE',
}
