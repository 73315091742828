import { Action } from '@ngrx/store';

import { AppHttpResponse } from 'shared';
import { Location } from 'stores/models';
import { BankAccount, CostCenter } from 'finances/models';
import { ApiKey, User } from 'security/models';
import { UserLoginResult, UpdateUserProfileInfoInput, UpdateUserProfileCredentialsInput } from 'auth/models';

/**
 * The store session action types.
 */
export enum SessionActionType {
  LOGIN = '[Auth] [Session] Login',
  LOGIN_FAIL = '[Auth] [Session] Login Fail',
  LOGIN_SUCCESS = '[Auth] [Session] Login Success',

  FETCH_USER_DATA = '[Auth] [Session] Fetch User Data',
  FETCH_USER_DATA_FAIL = '[Auth] [Session] Fetch User Data Fail',
  FETCH_USER_DATA_SUCCESS = '[Auth] [Session] Fetch User Data Success',

  GET_USER_LOCATIONS = '[Auth] [Session] Get User Locations',
  GET_USER_LOCATIONS_FAIL = '[Auth] [Session] Get User Locations Fail',
  GET_USER_LOCATIONS_SUCCESS = '[Auth] [Session] Get User Locations Success',

  GET_USER_BANK_ACCOUNTS = '[Auth] [Session] Get User Bank Accounts',
  GET_USER_BANK_ACCOUNTS_FAIL = '[Auth] [Session] Get User Bank Accounts Fail',
  GET_USER_BANK_ACCOUNTS_SUCCESS = '[Auth] [Session] Get User Bank Accounts Success',

  GET_USER_COST_CENTERS = '[Auth] [Session] Get User Cost Centers',
  GET_USER_COST_CENTERS_FAIL = '[Auth] [Session] Get User Cost Centers Fail',
  GET_USER_COST_CENTERS_SUCCESS = '[Auth] [Session] Get User Cost Centers Success',

  REFRESH_TOKEN = '[Auth] [Session] Refresh Token',
  REFRESH_TOKEN_FAIL = '[Auth] [Session] Refresh Token Fail',
  REFRESH_TOKEN_SUCCESS = '[Auth] [Session] Refresh Token Success',

  UPDATE_USER_PROFILE_INFO = '[Auth] [Session] Update user profile info',
  UPDATE_USER_PROFILE_INFO_FAIL = '[Auth] [Session] Update user profile info Fail',
  UPDATE_USER_PROFILE_INFO_SUCCESS = '[Auth] [Session] Update user profile info Success',

  UPDATE_USER_PROFILE_CREDENTIALS = '[Auth] [Session] Update user profile credentials',
  UPDATE_USER_PROFILE_CREDENTIALS_FAIL = '[Auth] [Session] Update user profile credentials Fail',
  UPDATE_USER_PROFILE_CREDENTIALS_SUCCESS = '[Auth] [Session] Update user profile credentials Success',

  LOGOUT = '[Auth] [Session] Logout',

  UPDATE_USER_LANGUAGE = '[Auth] [Session] Update user language',
  UPDATE_USER_LANGUAGE_FAIL = '[Auth] [Session] Update user language Fail',
  UPDATE_USER_LANGUAGE_SUCCESS = '[Auth] [Session] Update user language Success',

  UPDATE_USER_DEFAULT_LOCATION = '[Auth] [Session] Update User Default Location',
  UPDATE_USER_DEFAULT_LOCATION_FAIL = '[Auth] [Session] Update User Default Location Fail',
  UPDATE_USER_DEFAULT_LOCATION_SUCCESS = '[Auth] [Session] Update User Default Location Success',

  UPDATE_USER_DEFAULT_COST_CENTER = '[Auth] [Session] Update User Default Cost Center',
  UPDATE_USER_DEFAULT_COST_CENTER_FAIL = '[Auth] [Session] Update User Default Cost Center Fail',
  UPDATE_USER_DEFAULT_COST_CENTER_SUCCESS = '[Auth] [Session] Update User Default Cost Center Success',

  UPDATE_USER_DEFAULT_CASH_ACCOUNT = '[Auth] [Session] Update User Default cash account',
  UPDATE_USER_DEFAULT_CASH_ACCOUNT_FAIL = '[Auth] [Session] Update User Default cash account Fail',
  UPDATE_USER_DEFAULT_CASH_ACCOUNT_SUCCESS = '[Auth] [Session] Update User Default cash account Success',

  UPDATE_USER_DEFAULT_ELECTRONIC_ACCOUNT = '[Auth] [Session] Update User Default electronic account',
  UPDATE_USER_DEFAULT_ELECTRONIC_ACCOUNT_FAIL = '[Auth] [Session] Update User Default electronic account Fail',
  UPDATE_USER_DEFAULT_ELECTRONIC_ACCOUNT_SUCCESS = '[Auth] [Session] Update User Default electronic account Success',

  UPDATE_USER_DEFAULT_POS_DEVICE = '[Auth] [Session] Update User Default Pos Device',
  UPDATE_USER_DEFAULT_POS_DEVICE_FAIL = '[Auth] [Session] Update User Default Pos Device Fail',
  UPDATE_USER_DEFAULT_POS_DEVICE_SUCCESS = '[Auth] [Session] Update User Default Pos Device Success',

  GET_API_KEY = '[Auth] [Session] Get Api Key',
  GET_API_KEY_FAIL = '[Auth] [Session] Get Api Key Fail',
  GET_API_KEY_SUCCESS = '[Auth] [Session] Get Api Key Success',

  UPDATE_API_KEY = '[Auth] [Session] Update Api Key',
  UPDATE_API_KEY_FAIL = '[Auth] [Session] Update Api Key Fail',
  UPDATE_API_KEY_SUCCESS = '[Auth] [Session] Update Api Key Success',
}

/**
 * Represents a store login action.
 */
export class Login implements Action {
  /**
   * The type of the action.
   */
  readonly type = SessionActionType.LOGIN;

  /**
   * Fires a new login action.
   * @param payload the credentials required to get user logged in.
   */
  constructor(public payload: { username: string; password: string; rememberUser: boolean }) {}
}

/**
 * Represents a store login-fail action.
 */
export class LoginFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = SessionActionType.LOGIN_FAIL;

  /**
   * Fires a new login-fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store login-success action.
 */
export class LoginSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = SessionActionType.LOGIN_SUCCESS;

  /**
   * Fires a new login-success action.
   * @param payload An object contains the response returned from the remote server
   * after login and a flag rememberUser indicates whether the user's token will be saved
   * in the local-storage for a time or will be removed when the session is ended.
   */
  constructor(public payload: { response: UserLoginResult; rememberUser: boolean }) {}
}

/**
 * Represents a store fetch-user-data action, typically it's used in the splash-screen page after user login.
 */
export class FetchUserData implements Action {
  /**
   * The type of the action.
   */
  readonly type = SessionActionType.FETCH_USER_DATA;

  constructor() {}
}

/**
 * Represents a store fetch-user-data fail action, typically it's used in the splash-screen page after user login.
 */
export class FetchUserDataFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = SessionActionType.FETCH_USER_DATA_FAIL;

  /**
   * Fires a new fetch-user-data fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store fetch-user-data success action, typically it's used in the splash-screen page after user login.
 */
export class FetchUserDataSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = SessionActionType.FETCH_USER_DATA_SUCCESS;

  /**
   * Fires a new fetch-user-data success action.
   * @param payload The user that is currently logged-in.
   */
  constructor(public payload: User) {}
}

/**
 * Represents a store get-user-locations action.
 */
export class GetUserLocations implements Action {
  /**
   * The type of the action.
   */
  readonly type = SessionActionType.GET_USER_LOCATIONS;

  /**
   * Fires a new user profile locations action.
   */
  constructor() {}
}

/**
 * Represents a store get-user-locations-fail action.
 */
export class GetUserLocationsFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = SessionActionType.GET_USER_LOCATIONS_FAIL;

  /**
   * Fires a new get-user-locations-fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store get-user-locations-success action.
 */
export class GetUserLocationsSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = SessionActionType.GET_USER_LOCATIONS_SUCCESS;

  /**
   * Fires a new get-user-locations-success action.
   * @param payload An object contains the list of users that matches the search criteria.
   */
  constructor(public payload: AppHttpResponse<Location[]>) {}
}

/**
 * Represents a store get-user-cost-centers action.
 */
export class GetUserCostCenters implements Action {
  /**
   * The type of the action.
   */
  readonly type = SessionActionType.GET_USER_COST_CENTERS;

  /**
   * Fires a new user cost centers action.
   */
  constructor() {}
}

/**
 * Represents a store get-user-cost-centers--fail action.
 */
export class GetUserCostCentersFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = SessionActionType.GET_USER_COST_CENTERS_FAIL;

  /**
   * Fires a new get-user-cost-centers-fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store get-user-cost-centers-success action.
 */
export class GetUserCostCentersSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = SessionActionType.GET_USER_COST_CENTERS_SUCCESS;

  /**
   * Fires a new get-user-cost-centers-success action.
   * @param payload An object contains the list of users that matches the search criteria.
   */
  constructor(public payload: AppHttpResponse<CostCenter[]>) {}
}

/**
 * Represents a store get-user-bank-accounts action.
 */
export class GetUserBankAccounts implements Action {
  /**
   * The type of the action.
   */
  readonly type = SessionActionType.GET_USER_BANK_ACCOUNTS;

  /**
   * Fires a new user profile bank accounts action.
   */
  constructor() {}
}

/**
 * Represents a store get-user-bank-accounts-fail action.
 */
export class GetUserBankAccountsFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = SessionActionType.GET_USER_BANK_ACCOUNTS_FAIL;

  /**
   * Fires a new get-user-bank-accounts-fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store get-user-bank-accounts-success action.
 */
export class GetUserBankAccountsSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = SessionActionType.GET_USER_BANK_ACCOUNTS_SUCCESS;

  /**
   * Fires a new get-user-bank-accounts-success action.
   * @param payload An object contains the list of users that matches the search criteria.
   */
  constructor(public payload: AppHttpResponse<BankAccount[]>) {}
}

/**
 * Represents a store refresh-token action.
 */
export class RefreshToken implements Action {
  /**
   * The type of the action.
   */
  readonly type = SessionActionType.REFRESH_TOKEN;
}

/**
 * Represents a store refresh-token-fail action.
 */
export class RefreshTokenFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = SessionActionType.REFRESH_TOKEN_FAIL;

  /**
   * Fires a new refresh-token-fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store refresh-token-success action.
 */
export class RefreshTokenSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = SessionActionType.REFRESH_TOKEN_SUCCESS;

  /**
   * Fires a new refresh-token-success action.
   * @param payload An object contains the response returned from the remote server
   * after refresh-token and a flag rememberUser indicates whether the user's token will be saved
   * in the local-storage for a time or will be removed when the session is ended.
   */
  constructor(public payload: { response: UserLoginResult; rememberUser: boolean }) {}
}

/**
 * Represents a store update-user-profile-info action.
 */
export class UpdateUserProfileInfo implements Action {
  /**
   * The type of the action.
   */
  readonly type = SessionActionType.UPDATE_USER_PROFILE_INFO;

  /**
   * Fires a new update-user-profile action.
   * @param payload The updated user data.
   */
  constructor(public payload: UpdateUserProfileInfoInput) {}
}

/**
 * Represents a store update-user-profile-info fail action.
 */
export class UpdateUserProfileInfoFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = SessionActionType.UPDATE_USER_PROFILE_INFO_FAIL;

  /**
   * Fires a new update-user-profile fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store update-user-profile-info success action.
 */
export class UpdateUserProfileInfoSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = SessionActionType.UPDATE_USER_PROFILE_INFO_SUCCESS;

  /**
   * Fires a new update-user-profile success action.
   * @param payload The updated user profile data.
   */
  constructor(public payload: User) {}
}

/**
 * Represents a store update-user-profile-credentials action.
 */
export class UpdateUserProfileCredentials implements Action {
  /**
   * The type of the action.
   */
  readonly type = SessionActionType.UPDATE_USER_PROFILE_CREDENTIALS;

  /**
   * Fires a new update-user-credentials action.
   * @param payload The updated user credentials data.
   */
  constructor(public payload: UpdateUserProfileCredentialsInput) {}
}

/**
 * Represents a store update-user-profile-credentials fail action.
 */
export class UpdateUserProfileCredentialsFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = SessionActionType.UPDATE_USER_PROFILE_CREDENTIALS_FAIL;

  /**
   * Fires a new update-user-credentials fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: any) {}
}

/**
 * Represents a store update-user-profile-credentials success action.
 */
export class UpdateUserProfileCredentialsSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = SessionActionType.UPDATE_USER_PROFILE_CREDENTIALS_SUCCESS;

  /**
   * Fires a new update-user-credentials success action.
   * @param payload The updated user data.
   */
  constructor(public payload: User) {}
}

/**
 * Represents a store logout action.
 */
export class Logout implements Action {
  /**
   * The type of the action.
   */
  readonly type = SessionActionType.LOGOUT;

  /**
   * Fires a new logout action.
   * @param payload Indicates whether to redirect to the login page after logout or not.
   */
  constructor(public payload: boolean) {}
}

/**
 * Represents a store update-user-language action.
 */
export class UpdateUserLanguage implements Action {
  /**
   * The type of the action.
   */
  readonly type = SessionActionType.UPDATE_USER_LANGUAGE;

  /**
   * Fires a new update-user-language action.
   * @param payload The updated user data.
   */
  constructor(public payload: string) {}
}

/**
 * Represents a store update-user-language fail action.
 */
export class UpdateUserLanguageFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = SessionActionType.UPDATE_USER_LANGUAGE_FAIL;

  /**
   * Fires a new update-user-language fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store update-user-language success action.
 */
export class UpdateUserLanguageSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = SessionActionType.UPDATE_USER_LANGUAGE_SUCCESS;

  /**
   * Fires a new update-user-language success action.
   * @param payload The updated user language data.
   */
  constructor(public payload: User) {}
}

/**
 * Represents a store default user location update-fail action.
 */
export class UpdateUserDefaultLocationFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = SessionActionType.UPDATE_USER_DEFAULT_LOCATION_FAIL;

  /**
   * Fires a new default user location update-fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store default user location update action.
 */
export class UpdateUserDefaultLocation implements Action {
  /**
   * The type of the action.
   */
  readonly type = SessionActionType.UPDATE_USER_DEFAULT_LOCATION;

  /**
   * Fires a new default user location update action.
   * @param payload The default user location updated data.
   */
  constructor(public payload: number) {}
}

/**
 * Represents a store default user location update-success action.
 */
export class UpdateUserDefaultLocationSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = SessionActionType.UPDATE_USER_DEFAULT_LOCATION_SUCCESS;

  /**
   * Fires a new user locations update-success action.
   * @param payload The updated user location.
   */
  constructor(public payload: AppHttpResponse<User>) {}
}

/**
 * Represents a store default user cost center update action.
 */
export class UpdateUserDefaultCostCenter implements Action {
  /**
   * The type of the action.
   */
  readonly type = SessionActionType.UPDATE_USER_DEFAULT_COST_CENTER;

  /**
   * Fires a new default user cost center update action.
   * @param payload The default user cost center updated data.
   */
  constructor(public payload: number) {}
}

/**
 * Represents a store default user cost center update-fail action.
 */
export class UpdateUserDefaultCostCenterFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = SessionActionType.UPDATE_USER_DEFAULT_COST_CENTER_FAIL;

  /**
   * Fires a new default user cost center update-fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store default user cost center update-success action.
 */
export class UpdateUserDefaultCostCenterSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = SessionActionType.UPDATE_USER_DEFAULT_COST_CENTER_SUCCESS;

  /**
   * Fires a new cost centers update-success action.
   * @param payload The updated cost center.
   */
  constructor(public payload: AppHttpResponse<User>) {}
}

/**
 * Represents a store default user cash account update-fail action.
 */
export class UpdateUserDefaultCashAccountFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = SessionActionType.UPDATE_USER_DEFAULT_CASH_ACCOUNT_FAIL;

  /**
   * Fires a new default user cash account update-fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store default user cash account update action.
 */
export class UpdateUserDefaultCashAccount implements Action {
  /**
   * The type of the action.
   */
  readonly type = SessionActionType.UPDATE_USER_DEFAULT_CASH_ACCOUNT;

  /**
   * Fires a new default user cash account update action.
   * @param payload The default user cash account updated data.
   */
  constructor(public payload: number) {}
}

/**
 * Represents a store default user cash account update-success action.
 */
export class UpdateUserDefaultCashAccountSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = SessionActionType.UPDATE_USER_DEFAULT_CASH_ACCOUNT_SUCCESS;

  /**
   * Fires a new user cash account update-success action.
   * @param payload The updated user cash account.
   */
  constructor(public payload: AppHttpResponse<User>) {}
}

/**
 * Represents a store default user cash account update-fail action.
 */
export class UpdateUserDefaultElectronicAccountFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = SessionActionType.UPDATE_USER_DEFAULT_ELECTRONIC_ACCOUNT_FAIL;

  /**
   * Fires a new default user electronic account update-fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store default user electronic account update action.
 */
export class UpdateUserDefaultElectronicAccount implements Action {
  /**
   * The type of the action.
   */
  readonly type = SessionActionType.UPDATE_USER_DEFAULT_ELECTRONIC_ACCOUNT;

  /**
   * Fires a new default user electronic account update action.
   * @param payload The default user electronic account updated data.
   */
  constructor(public payload: number) {}
}

/**
 * Represents a store default user electronic account update-success action.
 */
export class UpdateUserDefaultElectronicAccountSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = SessionActionType.UPDATE_USER_DEFAULT_ELECTRONIC_ACCOUNT_SUCCESS;

  /**
   * Fires a new user electronic account update-success action.
   * @param payload The updated user electronic account.
   */
  constructor(public payload: AppHttpResponse<User>) {}
}

/**
 * Represents a store default user pos device update-fail action.
 */
export class UpdateUserDefaultPosDeviceFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = SessionActionType.UPDATE_USER_DEFAULT_POS_DEVICE_FAIL;

  /**
   * Fires a new default user electronic account update-fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store default user pos device update action.
 */
export class UpdateUserDefaultPosDevice implements Action {
  /**
   * The type of the action.
   */
  readonly type = SessionActionType.UPDATE_USER_DEFAULT_POS_DEVICE;

  /**
   * Fires a new default user pos device update action.
   * @param payload The default user pos device updated data.
   */
  constructor(public payload: number) {}
}

/**
 * Represents a store default user pos device update-success action.
 */
export class UpdateUserDefaultPosDeviceSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = SessionActionType.UPDATE_USER_DEFAULT_POS_DEVICE_SUCCESS;

  /**
   * Fires a new user pos device update-success action.
   * @param payload The updated pos device account.
   */
  constructor(public payload: AppHttpResponse<User>) {}
}

/**
 * Represents a store get-api-key action.
 */
export class GetApiKey implements Action {
  /**
   * The type of the action.
   */
  readonly type = SessionActionType.GET_API_KEY;

  /**
   * Fires a new  api key action.
   */
  constructor() {}
}

/**
 * Represents a store users get api key-fail action.
 */
export class GetApiKeyFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = SessionActionType.GET_API_KEY_FAIL;

  /**
   * Fires a new get-api-key-fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store get-api-key-success action.
 */
export class GetApiKeySuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = SessionActionType.GET_API_KEY_SUCCESS;

  /**
   * Fires a new get-api-key-success action.
   * @param payload An api key
   */
  constructor(public payload: AppHttpResponse<ApiKey>) {}
}

/**
 * Represents a store user update action.
 */
export class UpdateApiKey implements Action {
  /**
   * The type of the action.
   */
  readonly type = SessionActionType.UPDATE_API_KEY;

  /**
   * Fires a api key update action.
   */
  constructor() {}
}

/**
 * Represents a store session update-fail action.
 */
export class UpdateApiKeyFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = SessionActionType.UPDATE_API_KEY_FAIL;

  /**
   * Fires an api key update-fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store session update-success action.
 */
export class UpdateApiKeySuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = SessionActionType.UPDATE_API_KEY_SUCCESS;

  /**
   * Fires an api key update-success action.
   * @param payload The updated user.
   */
  constructor(public payload: AppHttpResponse<ApiKey>) {}
}

/**
 * Auth-module session action types.
 */
export type SessionActions =
  | Login
  | LoginFail
  | LoginSuccess
  | FetchUserData
  | FetchUserDataFail
  | FetchUserDataSuccess
  | GetUserBankAccounts
  | GetUserBankAccountsFail
  | GetUserBankAccountsSuccess
  | GetUserCostCenters
  | GetUserCostCentersFail
  | GetUserCostCentersSuccess
  | GetUserLocations
  | GetUserLocationsFail
  | GetUserLocationsSuccess
  | RefreshToken
  | RefreshTokenFail
  | RefreshTokenSuccess
  | UpdateUserProfileInfo
  | UpdateUserProfileInfoFail
  | UpdateUserProfileInfoSuccess
  | UpdateUserProfileCredentials
  | UpdateUserProfileCredentialsFail
  | UpdateUserProfileCredentialsSuccess
  | Logout
  | UpdateUserLanguage
  | UpdateUserLanguageFail
  | UpdateUserLanguageSuccess
  | UpdateUserDefaultLocation
  | UpdateUserDefaultLocationFail
  | UpdateUserDefaultLocationSuccess
  | UpdateUserDefaultCostCenter
  | UpdateUserDefaultCostCenterFail
  | UpdateUserDefaultCostCenterSuccess
  | UpdateUserDefaultCashAccount
  | UpdateUserDefaultCashAccountFail
  | UpdateUserDefaultCashAccountSuccess
  | UpdateUserDefaultElectronicAccount
  | UpdateUserDefaultElectronicAccountFail
  | UpdateUserDefaultElectronicAccountSuccess
  | UpdateUserDefaultPosDevice
  | UpdateUserDefaultPosDeviceFail
  | UpdateUserDefaultPosDeviceSuccess
  | GetApiKey
  | GetApiKeyFail
  | GetApiKeySuccess
  | UpdateApiKey
  | UpdateApiKeyFail
  | UpdateApiKeySuccess;
