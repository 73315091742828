import { Action } from '@ngrx/store';

import { AppHttpResponse } from 'shared';
import { VendorLogType } from 'lookups/models';

/**
 * The Lookups-Module store vendor advance payment log type action types.
 */
export enum VendorAdvancePaymentLogTypeActionType {
  GET_ALL_VENDOR_ADVANCE_PAYMENT_LOG_TYPES = '[Lookups] [VendorAdvancePaymentLogType] Get All Vendor Advance Payment Log Types',
  GET_ALL_VENDOR_ADVANCE_PAYMENT_LOG_TYPES_FAIL = '[Lookups] [VendorAdvancePaymentLogType] Get All Vendor Advance Payment Log Types Fail',
  GET_ALL_VENDOR_ADVANCE_PAYMENT_LOG_TYPES_SUCCESS = '[Lookups] [VendorAdvancePaymentLogType] Get All Vendor Advance Payment Log Types Success',
}

/**
 * Represents a store vendor advance payment log type action.
 */
export class GetAllVendorAdvancePaymentLogType implements Action {
  /**
   * The type of the action.
   */
  readonly type = VendorAdvancePaymentLogTypeActionType.GET_ALL_VENDOR_ADVANCE_PAYMENT_LOG_TYPES;

  /**
   * Fires a new vendor advance payment log type action.
   */
  constructor() {}
}

/**
 * Represents a store vendor advance payment log type fail action.
 */
export class GetAllVendorAdvancePaymentLogTypeFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = VendorAdvancePaymentLogTypeActionType.GET_ALL_VENDOR_ADVANCE_PAYMENT_LOG_TYPES_FAIL;

  /**
   * Fires a new vendor advance payment log type fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store vendor advance payment log type success action.
 */
export class GetAllVendorAdvancePaymentLogTypeSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = VendorAdvancePaymentLogTypeActionType.GET_ALL_VENDOR_ADVANCE_PAYMENT_LOG_TYPES_SUCCESS;

  /**
   * Fires a vendor advance payment log type success action.
   * @param payload An object contains the list of vendor advance payment log types.
   */
  constructor(public payload: AppHttpResponse<VendorLogType[]>) {}
}

/**
 * Lookups-module vendor advance payment log type action types.
 */
export type VendorAdvancePaymentLogTypeActions =
  | GetAllVendorAdvancePaymentLogType
  | GetAllVendorAdvancePaymentLogTypeFail
  | GetAllVendorAdvancePaymentLogTypeSuccess;
