import { createSelector } from '@ngrx/store';

import { selectFinancesState } from 'finances/store/selectors/feature.selectors';
import { FinancesState, PettyCashReductionState } from 'finances/store/states';

/* Selectors */

/**
 * Gets the petty cash reduction state.
 */
const selectPettyCashReductionState = createSelector(selectFinancesState, (state: FinancesState) => state.pettyCashReduction);

/**
 * Gets the list of loaded petty cash reduction.
 */
export const getPettyCashReduction = createSelector(
  selectPettyCashReductionState,
  (state: PettyCashReductionState) => state.data
);

/**
 * Gets the petty cash reduction pagination info.
 */
export const getPettyCashReductionPaginationInfo = createSelector(
  selectPettyCashReductionState,
  (state: PettyCashReductionState) => state.paginationInfo
);

/**
 * Gets the error that occurred in the state.
 */
export const getPettyCashReductionError = createSelector(
  selectPettyCashReductionState,
  (state: PettyCashReductionState) => state.error
);

/**
 * Determines if there is a running search process.
 */
export const getPettyCashReductionSearching = createSelector(
  selectPettyCashReductionState,
  (state: PettyCashReductionState) => state.isSearching
);

/**
 * Determines if the last search process has been ended successfully.
 */
export const getPettyCashReductionSearchCompleted = createSelector(
  selectPettyCashReductionState,
  (state: PettyCashReductionState) => state.isSearchCompleted
);

/**
 * Gets the selected petty cash reduction.
 */
export const getSelectedPettyCashReduction = createSelector(
  selectPettyCashReductionState,
  (state: PettyCashReductionState) => state.selectedPettyCashReduction
);

/**
 * Determines if there is a running find-petty-cash-reduction process.
 */
export const getPettyCashReductionFinding = createSelector(
  selectPettyCashReductionState,
  (state: PettyCashReductionState) => state.isFinding
);

/**
 * Determines if the last find-petty-cash-reduction process has been ended successfully.
 */
export const getPettyCashReductionFindCompleted = createSelector(
  selectPettyCashReductionState,
  (state: PettyCashReductionState) => state.isFindCompleted
);

/**
 * Determines if there is a running create process.
 */
export const getSelectedPettyCashReductionCreating = createSelector(
  selectPettyCashReductionState,
  (state: PettyCashReductionState) => state.isCreating
);

/**
 * Determines if the last create process has been ended successfully.
 */
export const getSelectedPettyCashReductionCreateCompleted = createSelector(
  selectPettyCashReductionState,
  (state: PettyCashReductionState) => state.isCreateCompleted
);
