import { Action } from '@ngrx/store';

import { SaleInvoiceOrderType } from 'lookups/models';
import { AppHttpResponse } from 'shared';

/**
 * The Lookups-Module store sale invoice order type action types.
 */
export enum SaleInvoiceOrderTypeActionType {
  GET_ALL_SALE_INVOICE_ORDER_TYPES = '[Lookups] [SaleInvoiceOrderType] Get All Sale Invoice Order Types',
  GET_ALL_SALE_INVOICE_ORDER_TYPES_FAIL = '[Lookups] [SaleInvoiceOrderType] Get All Sale Invoice Order Types Fail',
  GET_ALL_SALE_INVOICE_ORDER_TYPES_SUCCESS = '[Lookups] [SaleInvoiceOrderType] Get All Sale Invoice Order Types Success',
}

/**
 * Represents a store sale invoice order type action.
 */
export class GetAllSaleInvoiceOrderType implements Action {
  /**
   * The type of the action.
   */
  readonly type = SaleInvoiceOrderTypeActionType.GET_ALL_SALE_INVOICE_ORDER_TYPES;

  /**
   * Fires a new sale invoice order type action.
   */
  constructor() {}
}

/**
 * Represents a store sale invoice order type fail action.
 */
export class GetAllSaleInvoiceOrderTypeFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = SaleInvoiceOrderTypeActionType.GET_ALL_SALE_INVOICE_ORDER_TYPES_FAIL;

  /**
   * Fires a new sale invoice order type fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store sale invoice order type success action.
 */
export class GetAllSaleInvoiceOrderTypeSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = SaleInvoiceOrderTypeActionType.GET_ALL_SALE_INVOICE_ORDER_TYPES_SUCCESS;

  /**
   * Fires a sale invoice order type success action.
   * @param payload An object contains the list of sale invoice order types.
   */
  constructor(public payload: AppHttpResponse<SaleInvoiceOrderType[]>) {}
}

/**
 * Lookups-module sale invoice order type action types.
 */
export type SaleInvoiceOrderTypeActions =
  | GetAllSaleInvoiceOrderType
  | GetAllSaleInvoiceOrderTypeFail
  | GetAllSaleInvoiceOrderTypeSuccess;
