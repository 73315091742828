import { Component, Input } from '@angular/core';

import { Store } from '@ngrx/store';

import * as fromCoreStore from 'core/store';
import { PageInfo } from 'shared/models';
import { environment } from '../../../../environments/environment';
import { TranslationService } from 'shared/services';

@Component({
  selector: 'app-page-header',
  templateUrl: './page-header.component.html',
  styles: [],
})
export class PageHeaderComponent {
  /**
   * Sets the information about the current page.
   */
  @Input() set info(value: PageInfo) {
    this.pageInfo = value;

    /* Change page title in the browser. */
    if (value && !value.noChangeForBrowserTitle) {
      if (value.title) {
        document.title = `${environment.softwareSettings.app.name} | ${this.translationService.translate(
          value.title,
          value.titleParams
        )}`;
      } else {
        document.title = environment.softwareSettings.app.name;
      }

      this.coreStore$.dispatch(new fromCoreStore.ChangePageInfo(value));
    }
  }

  /**
   * Gets or sets the information about the current page.
   */
  pageInfo: PageInfo;

  /**
   * @param coreStore$ The core-Module store.
   * @param translationService The translation service.
   */
  constructor(private coreStore$: Store<fromCoreStore.AppState>, private translationService: TranslationService) {}
}
