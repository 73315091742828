import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';

import { Observable, Subscription } from 'rxjs';
import { skip, tap } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Decimal } from 'decimal.js';

import * as fromStoresStore from 'stores/store';
import * as fromSettingsStore from 'settings/store';
import * as fromLookupsStore from 'lookups/store';
import { Location, Product, ProductsFormOptions, ProductsFormItem, UnitOfMeasure } from 'stores/models';
import { Tax } from 'settings/models';
import { PurchaseRequestReceiveType, PurchaseOrderReceiveType } from 'lookups/models';
import { APP_CONSTANTS, CustomValidators, StorageService, TranslationService } from 'shared';
import { ProductsFormUtil, ProductsFormPOSUtil } from 'stores/utils';
import { ProjectSubTask } from 'projects-management/models';

@Component({
  selector: 'app-products-form',
  templateUrl: './products-form.component.html',
  styles: [],
})
export class ProductsFormComponent implements OnInit, OnDestroy {
  /**
   * The confirm modal template reference.
   */
  @ViewChild('deleteModalRef') deleteModalRef: ElementRef<any>;

  /**
   * Sets a value indicates whether if the product number column should be visible to the user.
   * @default true
   */
  @Input() showProductNumberColumn = true;

  /**
   * Sets a value indicates whether if the product code column should be visible to the user.
   * @default false
   */
  @Input() showCodeColumn = false;

  /**
   * Sets a value indicates whether if the product code column should be visible to the user.
   * @default false
   */
  @Input() showProductTypeColumn = false;

  /**
   * disable subProduct select.
   * @default 'false'
   */
  @Input() isDisabled = false;

  /**
   * Sets the default text for the product's type column header.
   * @default 'نوع الصنف'
   */
  @Input() productTypeColumnHeaderText = 'STORES.PRODUCTS.PRODUCT_DATA.PRODUCT_TYPE';

  /**
   * Sets the default text for the product's description column header.
   * @default 'الصنف'
   */
  @Input() descriptionColumnHeaderText = 'STORES.PRODUCTS.PRODUCT_DATA.THE_PRODUCT';

  /**
   * Sets the default placeholder text for the product's description input.
   * @default 'وصف الصنف...'
   */
  @Input() descriptionInputPlaceholderText = 'STORES.PRODUCTS.PRODUCT_DATA.DESCRIPTION_PLACEHOLDER';

  /**
   * Sets a value indicates whether if the product description can be changed by the user.
   * @default false
   */
  @Input() allowChangeDescription = false;

  /**
   * Sets a value indicates whether if the project sub task can be changed by the user.
   * @default false
   */
  @Input() allowChangeProjectSubTask = false;

  /**
   * Sets the default text for the quantity column header.
   * @default 'الوحدات'
   */
  @Input() quantityColumnHeaderText = 'STORES.PRODUCTS.PRODUCT_DATA.QUANTITIES';

  /**
   * Sets the default placeholder text for the quantity input.
   * @default 'كمية الصنف...'
   */
  @Input() quantityInputPlaceholderText = 'STORES.PRODUCTS.PRODUCT_DATA.QUANTITY_PLACEHOLDER';

  /**
   * Sets the default quantity value.
   * @default 1
   */
  @Input() defaultQuantity = 1;

  /**
   * Sets the default text for the value column header.
   * @default 'القيمه'
   */
  @Input() valueColumnHeaderText = 'STORES.PRODUCTS.PRODUCT_DATA.VALUE';

  /**
   * Sets the default placeholder text for the value input.
   * @default 'القيمه الماليه...'
   */
  @Input() valueInputPlaceholderText = 'STORES.PRODUCTS.PRODUCT_DATA.VALUE_PLACEHOLDER';

  /**
   * Sets a value indicates whether if the value can be changed by the user.
   * @default true
   */
  @Input() allowChangeValue = true;

  /**
   * Sets a value indicates whether if the user should specify a value more than zero or not.
   * @default true
   */
  @Input() isValueRequiredToBeMoreThanZero = true;

  /**
   * Sets a value indicates whether if the purchase price will be inserted automatically in the value field.
   * @default false
   */
  @Input() autoInsertPurchasePrice = false;

  /**
   * Sets a value indicates whether if the sale price will be inserted automatically in the value field.
   * @default false
   */
  @Input() autoInsertSalePrice = false;

  /**
   * Sets a value indicates whether if the on hand value will be inserted automatically in the value field.
   * @default false
   */
  @Input() autoInsertOnHandValue = false;

  /**
   * Sets a value indicates whether if the on hand value will be inserted automatically in the value field.
   * @default false
   */
  @Input() autoInsertProductValue = false;

  /**
   * Sets a value indicates whether if the product value will be updated automatically in the value field if the source invoice changed.
   * @default false
   */
  @Input() autoUpdateOnHandValue = false;

  /**
   * Sets a value indicates whether if the value of the product includes the tax or not.
   * @default false
   */
  @Input() isValueIncludingTax = false;

  /**
   * Sets the value of the source location id that will be used to auto update the on hand value for products.
   * @notes this will be in action if the input autoUpdateOnHandValue is true.
   * @default false
   */
  @Input() set locationIdForAutoUpdateOnHandValue(locationId: number) {
    if (!locationId || !this.autoUpdateOnHandValue) {
      return;
    }

    this.formArray?.controls.forEach((formGroup: FormGroup) => {
      if (!formGroup.value.isStorable) {
        return;
      }
      this.onUnitOfMeasureIdChange(formGroup);
    });
  }

  /**
   * Sets a value indicates whether if the discount by value column should be visible to the user.
   * @default false
   */
  @Input() showDiscountByValueColumn = false;

  /**
   * Sets the default text for the discount by value column header.
   * @default 'الخصم'
   */
  @Input() discountByValueColumnHeaderText = 'STORES.PRODUCTS.PRODUCT_DATA.DISCOUNT_BY_VALUE';

  /**
   * Sets the default placeholder text for the discount by value input.
   * @default 'قيمة الخصم...'
   */
  @Input() discountByValueInputPlaceholderText = 'STORES.PRODUCTS.PRODUCT_DATA.DISCOUNT_BY_VALUE_PLACEHOLDER';

  /**
   * Sets a value indicates whether if the discount by percent column should be visible to the user.
   * @default false
   */
  @Input() showDiscountByPercentColumn = false;

  /**
   * Sets a value indicates whether if the discount and discount percent will be inserted automatically.
   * @default false
   */
  @Input() autoInsertDiscount = false;

  /**
   * Sets a value indicates whether if the discount can be changed by the user.
   * @default true
   */
  @Input() allowChangeDiscount = true;

  /**
   * Sets the default text for the discount by percent column header.
   * @default 'الخصم %'
   */
  @Input() discountByPercentColumnHeaderText = 'STORES.PRODUCTS.PRODUCT_DATA.DISCOUNT_BY_PERCENT';

  /**
   * Sets the default placeholder text for the discount by percent input.
   * @default 'نسبة الخصم...'
   */
  @Input() discountByPercentInputPlaceholderText = 'STORES.PRODUCTS.PRODUCT_DATA.DISCOUNT_BY_PERCENT_PLACEHOLDER';

  /**
   * Sets a value indicates whether if the total column should be visible to the user.
   * @default true
   */
  @Input() showTotalColumn = true;

  /**
   * Sets the default text for the total column header.
   * @default 'الإجمالي'
   */
  @Input() totalColumnHeaderText = 'STORES.PRODUCTS.PRODUCT_DATA.THE_TOTAL';

  /**
   * Sets a value indicates whether if the tax column should be visible to the user.
   * @default true
   */
  @Input() showTaxColumn = true;

  /**
   * Sets a value indicates whether if the tax can be changed by the user.
   * @default true
   */
  @Input() allowChangeTax = true;

  /**
   * Sets a value indicates whether if the user should specify a tax or not.
   * @default true
   */
  @Input() isTaxRequired = true;

  /**
   * Sets a value indicates whether if tax of the newly added products should be zero and discard auto fetch for product tax.
   * @default false
   */
  @Input() withNoTax = false;

  /**
   * Sets the default text for the tax column header.
   * @default 'الضريبه'
   */
  @Input() taxColumnHeaderText = 'STORES.PRODUCTS.PRODUCT_DATA.TAX';

  /**
   * Sets the default placeholder text for the tax input.
   * @default '(قم بإختيار الضريبه)'
   */
  @Input() taxInputPlaceholderText = 'STORES.PRODUCTS.PRODUCT_DATA.TAX_PLACEHOLDER';

  /**
   * Sets a value indicates whether if the tax will be inserted automatically.
   * @default true
   */
  @Input() autoInsertTax = true;

  /**
   * Sets a value indicates whether if the expire date column should be visible to the user.
   * @default false
   */
  @Input() showExpireDateColumn = false;

  /**
   * Sets the default text for the expire date column header.
   * @default 'تاريخ الصلاحيه'
   */
  @Input() expireDateColumnHeaderText = 'STORES.PRODUCTS.PRODUCT_DATA.EXPIRE_DATE';

  /**
   * Sets the default placeholder text for the expire date input.
   * @default 'يوم-شهر-سنه'
   */
  @Input() expireDateInputPlaceholderText = 'STORES.PRODUCTS.PRODUCT_DATA.DATE_PLACEHOLDER';

  /**
   * Sets a value indicates whether if the location column should be visible to the user.
   * @default false
   */
  @Input() showLocationColumn = false;

  /**
   * Sets a value indicates whether if the project sub task column should be visible to the user.
   * @default false
   */
  @Input() showProjectSubTaskColumn = false;

  /**
   * Sets a value indicates whether if the location can be changed by the user.
   * @default true
   */
  @Input() allowChangeLocation = true;

  /**
   * Sets a value indicates whether if the user should specify a location or not (for storable products).
   * @default false
   */
  @Input() isLocationForStorableProductsRequired = false;

  /**
   * Sets a value indicates whether if the user should specify a location or not (for service products).
   * @default false
   */
  @Input() isLocationForServiceProductsRequired = false;

  /**
   * Sets a value indicates whether if the user should specify a location or not (for non-storable products).
   * @default false
   */
  @Input() isLocationForNonStorableProductsRequired = false;

  /**
   * Sets a value indicates whether if the user should specify a location or not
   * (for non-storable products with sub-product has storable product in its ingredients).
   * @default false
   */
  @Input() isLocationForSubProductWithStorableIngredientsRequired = false;

  /**
   * Sets a value indicates whether if the user should specify a location or not
   * (for non-storable products with product has storable product in its ingredients).
   * @default false
   */
  @Input() isLocationForProductWithStorableIngredientsRequired = false;

  /**
   * Sets the default text for the location column header.
   * @default 'المخزن'
   */
  @Input() locationColumnHeaderText = 'STORES.PRODUCTS.PRODUCT_DATA.LOCATION';

  /**
   * Sets the default text for the project sub task column header.
   */
  @Input() projectSubTaskColumnHeaderText = 'STORES.PRODUCTS.PRODUCT_DATA.SUB_TASK';

  /**
   * Sets the default placeholder text for the location input.
   * @default '(قم بإختيار المخزن)'
   */
  @Input() locationInputPlaceholderText = 'STORES.PRODUCTS.PRODUCT_DATA.LOCATION_PLACEHOLDER';

  /**
   * Sets a value indicates whether if the available stock column should be visible to the user.
   * @default true
   */
  @Input() showAvailableStockColumn = true;

  /**
   * Sets the default text for the available stock column header.
   * @default 'الوحدات المتاحه'
   */
  @Input() availableStockColumnHeaderText = 'STORES.PRODUCTS.PRODUCT_DATA.AVAILABLE_STOCK';

  /**
   * Sets a value indicates whether if the product's available stocks will be validated.
   * @default false
   */
  @Input() validateAvailableStocks = false;

  /**
   * Sets the id of the location against it the product available stocks will be validated.
   * If this value was provided that will overwrite any `locationId` selected on the product line level.
   */
  @Input() availableStockLocationId: number;

  /**
   * Sets a value indicates whether if the receive type column should be visible to the user.
   * @default false
   */
  @Input() showReceiveTypeColumn = false;

  /**
   * Sets a value indicates whether if the receive type can be changed by the user.
   * @default true
   */
  @Input() allowChangeReceiveType = true;

  /**
   * Sets a value indicates whether if the user should specify a receive type or not.
   * @default false
   */
  @Input() isReceiveTypeRequired = false;

  /**
   * Sets the default text for the receive type column header.
   * @default 'طريقة التسليم'
   */
  @Input() receiveTypeColumnHeaderText = 'STORES.PRODUCTS.PRODUCT_DATA.RECEIVE_TYPE';

  /**
   * Sets the default placeholder text for the receive type input.
   * @default '(قم بإختيار طريقة تسليم الصنف)'
   */
  @Input() receiveTypeInputPlaceholderText = 'STORES.PRODUCTS.PRODUCT_DATA.RECEIVE_TYPE_PLACEHOLDER';

  /**
   * Sets a value indicates whether to use the purchase request receive types or not.
   * @default false
   */
  @Input() usePurchaseRequestReceiveTypes = false;

  /**
   * Sets a value indicates whether to use the purchase order receive types or not.
   * @default false
   */
  @Input() usePurchaseOrderReceiveTypes = false;

  /**
   * Sets a value indicates whether if the receive date column should be visible to the user.
   * @default false
   */
  @Input() showReceiveDateColumn = false;

  /**
   * Sets a value indicates whether if the receive date can be changed by the user.
   * @default true
   */
  @Input() allowChangeReceiveDate = true;

  /**
   * Sets a value indicates whether if the user should specify a receive date or not.
   * @default false
   */
  @Input() isReceiveDateRequired = false;

  /**
   * Sets the default text for the receive date column header.
   * @default 'تاريخ التسليم'
   */
  @Input() receiveDateColumnHeaderText = 'STORES.PRODUCTS.PRODUCT_DATA.RECEIVE_DATE';

  /**
   * Sets the default placeholder text for the receive date input.
   * @default 'يوم-شهر-سنه'
   */
  @Input() receiveDateInputPlaceholderText = 'STORES.PRODUCTS.PRODUCT_DATA.DATE_PLACEHOLDER';

  /**
   * Sets a value indicates whether if the notes column should be visible to the user.
   * @default false
   */
  @Input() showNotesColumn = false;

  /**
   * Sets a value indicates whether if the user should specify a notes or not.
   * @default false
   */
  @Input() isNotesRequired = false;

  /**
   * Sets the maximum length of the notes.
   * @default 200 character.
   */
  @Input() notesMaxLength = 200;

  /**
   * Sets the default text for the notes column header.
   * @default 'الملاحظات'
   */
  @Input() notesColumnHeaderText = 'STORES.PRODUCTS.PRODUCT_DATA.NOTES';

  /**
   * Sets the default placeholder text for the notes input.
   * @default 'الملاحظات...'
   */
  @Input() notesInputPlaceholderText = 'STORES.PRODUCTS.PRODUCT_DATA.NOTES_PLACEHOLDER';

  /**
   * Sets the default notes input column bootstrap classes.
   * @default ['min-width-15']
   */
  @Input() notesInputColumnCssClasses = ['min-width-15'];

  /**
   * Sets a value indicates whether if the is-asset column should be visible to the user.
   * @default false
   */
  @Input() showIsAssetColumn = false;

  /**
   * Sets the default text for the is-asset column header.
   * @default 'أصل؟'
   */
  @Input() isAssetColumnHeaderText = 'STORES.PRODUCTS.PRODUCT_DATA.IS_ASSET';

  /**
   * Sets a value indicates whether if the user can add product(storable, service or non-storable) to the form.
   * @default true
   */
  @Input() allowAdd = true;

  /**
   * Sets a value indicates whether if the user can add storable product to the form.
   * @default true
   */
  @Input() allowAddStorableProduct = true;

  /**
   * Sets a value indicates whether if the user can add service product to the form.
   * @default true
   */
  @Input() allowAddServiceProduct = true;

  /**
   * Sets a value indicates whether if the user can add non-storable product to the form.
   * @default true
   */
  @Input() allowAddNonStorableProduct = true;

  /**
   * Sets a value indicates whether if the user can remove product from the form.
   * @default true
   */
  @Input() allowRemove = true;

  /**
   * Sets a value indicates whether if the user can search for product by code or barcode.
   * @default true
   */
  @Input() allowSearchByCodeOrBarcode = true;

  /**
   * Sets a value indicates whether if the user can add the same product more than one time in the form,
   * Otherwise the product quantity will be incremented by one.
   * @default false
   */
  @Input() set allowProductDuplicate(value: boolean) {
    this.options.allowProductDuplicate = value;
  }

  /**
   * Indicates whether the product purchase price will be visible to the user or not in the products search modal.
   */
  @Input() showPurchasePriceInProductsSearch = false;

  /**
   * Indicates whether the product sale price will be visible to the user or not in the products search modal.
   */
  @Input() showSalePriceInProductsSearch = false;

  /**
   * Sets a value indicates whether if the unit of measure can be changed by the user.
   * @default true
   */
  @Input() allowChangeUnitOfMeasure = true;

  /**
   * Sets the products form-array.
   */
  @Input() formArray: FormArray;

  /**
   * Sets the name form-array in its parent form.
   * @default 'products'
   */
  @Input() formArrayName = 'products';

  /**
   * Adds a list of new product form items to the products form.
   * @param items The list of product form items to be added to the products form.
   */
  @Input() set newProducts(items: ProductsFormItem[]) {
    if (!items?.length) {
      return;
    }

    items.forEach((item) => this.createProductFormGroup(item));
  }

  /**
   * The decimal mask.
   */
  readonly DECIMAL_MASK = APP_CONSTANTS.numeric.decimal.mask;

  /**
   * Gets or sets the products form options that can overwrite the options that are passed to the component.
   */
  options: ProductsFormOptions = {
    showCodeColumn: true,
    showProductTypeColumn: true,
    showDiscountByValueColumn: false,
    showDiscountByPercentColumn: false,
    showTotalColumn: true,
    showTaxColumn: true,
    showExpireDateColumn: true,
    showLocationColumn: true,
    showProjectSubTaskColumn: true,
    showAvailableStockColumn: false,
    showReceiveTypeColumn: true,
    showReceiveDateColumn: true,
    showNotesColumn: false,
    showIsAssetColumn: false,
    allowProductDuplicate: false,
  };

  /**
   * Shows or hides the products list.
   */
  productsListVisibility = false;

  /**
   * Shows storable products list.
   */
  searchForStorableProducts = false;

  /**
   * Shows service products list.
   */
  searchForServiceProducts = false;

  /**
   * Shows all products list.
   */
  searchForAllProductTypes = false;

  /**
   * Shows or hides the locations list.
   */
  locationsListVisibility = false;

  /**
   * Indicates that a drop-down field in the form is opened and therefore the table height should be expanded.
   */
  isDropDownOpened: boolean;

  /**
   * Gets the list of taxes.
   */
  taxes$: Observable<Tax[]>;

  /**
   * Gets or sets the default tax on the system.
   */
  defaultTax: Tax;

  /**
   * Gets the list of product receive types.
   */
  receiveTypes$: Observable<PurchaseRequestReceiveType[] | PurchaseOrderReceiveType[]>;

  /**
   * Gets all units of measure.
   */
  unitsOfMeasure: UnitOfMeasure[] = [];

  /**
   * Gets or sets the current selected or edited form group.
   */
  currentSelectedFormGroup: FormGroup;

  /**
   * The set of subscriptions on this components,
   * these subscriptions must be unsubscribed before this component got destroyed.
   */
  subscriptions = new Subscription();

  /**
   * Shows or hide the project sub tasks list in search.
   */
  projectSubTasksListVisibility = false;

  /**
   * The list of selected project sub tasks.
   */
  projectSubTasks: ProjectSubTask[] = [];

  /**
   * Gets the total quantity of selected products.
   */
  get productsQuantity(): number {
    return ProductsFormUtil.productsQuantity(this.formArray.controls.map((item) => item.value));
  }

  /**
   * Gets the products total without discount and tax.
   */
  get productsTotalWithoutDiscountAndTax(): number {
    return ProductsFormUtil.productsTotalWithoutDiscountAndTax(this.formArray.controls.map((item) => item.value));
  }

  /**
   * Gets the best col-span value for the products count summary cell.
   */
  get productsCountSummaryColSpan(): number {
    let result = 1;
    if (this.showProductNumberColumn) {
      result++;
    }
    if (this.showProductTypeColumn && this.options.showProductTypeColumn) {
      result++;
    }
    if (this.showCodeColumn && this.options.showCodeColumn) {
      result++;
    }

    return result;
  }

  /**
   * Gets or sets the current selected language.
   */
  get currentLang() {
    return this.translationService.language;
  }

  /**
   * Gets or sets the ngb-dropdown position based on the current user display language.
   */
  get ngbDropdownPosition(): string {
    return this.currentLang === 'ar' ? 'bottom-left' : 'bottom-right';
  }

  /**
   * @param modalService The modal service.
   * @param storageService The storage service.
   * @param settingsStore$ the  settings-store module.
   * @param lookupsStore$ the lookups-store module.
   * @param translationService The translation service.
   * @param storesStore$ The stores-store module.
   */
  constructor(
    private modalService: NgbModal,
    private storesStore$: Store<fromStoresStore.StoresState>,
    private settingsStore$: Store<fromSettingsStore.SettingsState>,
    private lookupsStore$: Store<fromLookupsStore.LookupsState>,
    private translationService: TranslationService,
    private storageService: StorageService
  ) {}

  ngOnInit() {
    this.init();
  }

  /**
   * Destroy component data
   */
  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  /**
   * Initialize component data.
   */
  init() {
    /**
     * Load data.
     */
    const options = this.storageService.getProductsFormOptions();
    if (options) {
      this.options = options;
    } else {
      this.syncOptions();
    }

    if (this.showTaxColumn) {
      this.taxes$ = this.settingsStore$.pipe(select(fromSettingsStore.getTaxes));
      this.settingsStore$.dispatch(new fromSettingsStore.SearchTaxes({ name: '', page: 1 }));
    }

    if (this.usePurchaseRequestReceiveTypes) {
      let isManualSearchTriggeredBeforeForReceiveTypes = false;
      this.receiveTypes$ = this.lookupsStore$.pipe(
        select(fromLookupsStore.getPurchaseRequestReceiveTypes),
        tap((types) => {
          if (!isManualSearchTriggeredBeforeForReceiveTypes && !types.length) {
            isManualSearchTriggeredBeforeForReceiveTypes = true;
            this.lookupsStore$.dispatch(new fromLookupsStore.GetAllPurchaseRequestReceiveType());
          }
        })
      );
    } else if (this.usePurchaseOrderReceiveTypes) {
      let isManualSearchTriggeredBeforeForReceiveTypes = false;
      this.receiveTypes$ = this.lookupsStore$.pipe(
        select(fromLookupsStore.getPurchaseOrderReceiveTypes),
        tap((types) => {
          if (!isManualSearchTriggeredBeforeForReceiveTypes && !types.length) {
            isManualSearchTriggeredBeforeForReceiveTypes = true;
            this.lookupsStore$.dispatch(new fromLookupsStore.GetAllPurchaseOrderReceiveType());
          }
        })
      );
    }

    /**
     * Listen for any search for product by code or barcode.
     */
    this.subscriptions.add(
      this.storesStore$
        .pipe(
          select(fromStoresStore.getSelectedProductByCodeOrBarcode),
          skip(1),
          tap((product) => {
            if (product) {
              this.createProductFormGroup(this.createProductFormItemFromProduct(product));
            }
          })
        )
        .subscribe()
    );

    /**
     * Get default selected tax.
     */
    this.subscriptions.add(
      this.settingsStore$
        .pipe(
          select(fromSettingsStore.getDefaultTax),
          tap((tax) => (this.defaultTax = tax))
        )
        .subscribe()
    );
    this.settingsStore$.dispatch(new fromSettingsStore.FindDefaultTax());

    /**
     * Get all units of measure.
     */
    this.subscriptions.add(
      this.storesStore$
        .pipe(
          select(fromStoresStore.getUnitsOfMeasure),
          skip(1),
          tap((units) => {
            this.unitsOfMeasure = units;
          })
        )
        .subscribe()
    );
    this.storesStore$.dispatch(new fromStoresStore.GetAllUnitsOfMeasure());
  }

  /**
   * Handles the search for product using product code event.
   * @param event The event that results from the barcode scan or code enter.
   */
  searchProductByCode(event: KeyboardEvent) {
    if (event && event.key === 'F12') {
      return event.preventDefault();
    } else if (!event || event.key !== 'Enter') {
      return;
    }
    event.preventDefault();

    const elem = event.target as HTMLInputElement;

    this.storesStore$.dispatch(new fromStoresStore.FindProductByCodeOrBarcode(encodeURIComponent(elem.value.trim())));
    elem.focus();
    elem.select();
  }

  /**
   * Opens the products search modal to add a new storable product.
   */
  addStorableProduct() {
    this.searchForStorableProducts = true;
    this.searchForServiceProducts = false;
    this.searchForAllProductTypes = false;

    this.productsListVisibility = true;
  }

  /**
   * Opens the products search modal to add a new service product.
   */
  addServiceProduct() {
    this.searchForStorableProducts = false;
    this.searchForServiceProducts = true;
    this.searchForAllProductTypes = false;

    this.productsListVisibility = true;
  }

  /**
   * Adds a new non-storable product.
   */
  addNonStorableProduct() {
    this.createProductFormGroup(this.createProductFormItemFromProduct());
  }

  /**
   * Creates and returns a new product form item from a given product.
   * If the product was omitted a non-storable product will be created.
   * @param product The product to be used to create the product form item.
   */
  createProductFormItemFromProduct(product?: Product): ProductsFormItem {
    const today = new Date();

    let value = 0;
    if (this.autoInsertPurchasePrice && product) {
      if (product.hasSubProducts) {
        value = product.subProducts?.[0]?.purchasePrice ?? 0;
      } else {
        value = product.purchasePrice;
      }
    } else if (this.autoInsertSalePrice && product) {
      if (product.hasSubProducts) {
        value = product.subProducts?.[0]?.salePrice ?? 0;
      } else {
        value = product.salePrice;
      }
    } else if (this.autoInsertOnHandValue && product) {
      value = product.stocks?.find((stock) => stock.locationId === this.availableStockLocationId)?.value ?? 0;
    }

    let tax = this.defaultTax?.value ?? 0;
    if (this.withNoTax) {
      tax = 0;
    } else if (this.autoInsertTax && product) {
      tax = product.tax;
    }

    return {
      isStorable: product && !product?.isService,
      isService: product?.isService,
      isNonStorable: !product,
      product,
      subProductId: product && product.hasSubProducts ? product.subProducts?.[0]?.id : null,
      description: product ? null : '',
      quantity: this.defaultQuantity,
      unitOfMeasureId: product?.unitOfMeasureId ?? null,
      projectSubTaskId: product?.projectSubTaskId ?? null,
      projectSubTask: product?.projectSubTask ?? null,
      projectSubTasks: [],
      value,
      tax,
      expireDate: {
        year: today.getFullYear(),
        month: today.getMonth() + 1,
        day: today.getDate(),
      },
      locationId: null,
      locations: [],
      receiveTypeId: null,
      receiveDate: {
        year: today.getFullYear(),
        month: today.getMonth() + 1,
        day: today.getDate(),
      },
      notes: '',
      isAsset: false,
    };
  }

  /**
   * Create a new product form group from the provided product form item.
   * @param item The product form item that contains data about the new product.
   */
  createProductFormGroup(item: ProductsFormItem) {
    let existingProductFormGroup: FormGroup;
    if (!this.options.allowProductDuplicate) {
      existingProductFormGroup = this.formArray.controls.find((ctrl) => {
        const value = ctrl.value as ProductsFormItem;
        return (
          (value.isStorable || value.isService) &&
          value.product?.id === item.product?.id &&
          !value.product.hasSubProducts
        );
      }) as FormGroup;
    }

    if (!this.options.allowProductDuplicate && (item.isStorable || item.isService) && existingProductFormGroup) {
      existingProductFormGroup.patchValue({
        quantity: Decimal.add(existingProductFormGroup.value.quantity || 0, 1).toNumber(),
      });
      return;
    }

    const subProductIdFormControl = new FormControl(
      item.subProductId,
      item.product?.hasSubProducts ? [Validators.required] : []
    );

    const unitOfMeasureIdFormControl = new FormControl(item.unitOfMeasureId, Validators.required);

    const productSalesDiscount = item.product?.productSalesDiscounts?.find(
      (discount) => this.autoInsertDiscount && discount.locationId === this.availableStockLocationId
    );

    const formGroup = new FormGroup({
      isStorable: new FormControl(item.isStorable ?? false),
      isService: new FormControl(item.isService ?? false),
      isNonStorable: new FormControl(item.isNonStorable ?? false),
      isPointsExchange: new FormControl(item.isPointsExchange ?? false),
      product: new FormControl(item.product),
      subProductId: subProductIdFormControl,
      projectSubTaskId: new FormControl(item.projectSubTaskId),
      projectSubTasks: new FormControl(item.projectSubTasks),
      projectSubTask: new FormControl(item.projectSubTask),
      isSubProductHasStorableIngredients: new FormControl(item.isSubProductHasStorableIngredients),
      isProductHasStorableIngredients: new FormControl(item.isProductHasStorableIngredients),
      description: new FormControl(
        item.description,
        item.isNonStorable ? [Validators.required, Validators.minLength(1), Validators.maxLength(100)] : []
      ),
      quantity: new FormControl(item.quantity, [Validators.required, CustomValidators.gt(0)]),
      unitOfMeasureId: unitOfMeasureIdFormControl,
      unitOfMeasure: new FormControl(item.unitOfMeasure),
      value: new FormControl(
        item.value,
        item.isPointsExchange
          ? [Validators.required, CustomValidators.lt(0)]
          : [
              Validators.required,
              this.isValueRequiredToBeMoreThanZero ? CustomValidators.gt(0) : CustomValidators.gte(0),
            ]
      ),
      discount: new FormControl(item.discount ?? productSalesDiscount?.discount ?? 0, CustomValidators.gte(0)),
      discountPercent: new FormControl(
        item.discountPercent ?? productSalesDiscount?.discountPercent ?? 0,
        CustomValidators.range([0, 100])
      ),
      tax: new FormControl(item.tax, this.isTaxRequired ? [Validators.required, CustomValidators.range([0, 100])] : []),
      expireDate: new FormControl(
        item.expireDate,
        this.showExpireDateColumn && item.product?.hasExpireDate ? Validators.required : Validators.nullValidator
      ),
      locationId: new FormControl(
        item.locationId,
        (this.isLocationForStorableProductsRequired && item.isStorable) ||
        (this.isLocationForServiceProductsRequired && item.isService) ||
        (this.isLocationForNonStorableProductsRequired && item.isNonStorable)
          ? Validators.required
          : Validators.nullValidator
      ),
      locations: new FormControl(item.locations),
      receiveTypeId: new FormControl(
        item.receiveTypeId,
        this.isReceiveTypeRequired ? Validators.required : Validators.nullValidator
      ),
      receiveDate: new FormControl(
        item.receiveDate,
        this.isReceiveDateRequired ? Validators.required : Validators.nullValidator
      ),
      notes: new FormControl(item.notes, [
        Validators.minLength(this.isNotesRequired ? 1 : 0),
        Validators.maxLength(this.notesMaxLength),
      ]),
      isAsset: new FormControl(item.isAsset ?? false),
      extraData: new FormControl(item.extraData),
    });

    /**
     * Only if the product had sub-products.
     */
    if (item.product?.hasSubProducts || item.product?.hasIngredients) {
      this.subscriptions.add(
        subProductIdFormControl.valueChanges.subscribe(() => this.onSubProductIdChange(formGroup))
      );

      /**
       * Initialize sub-product validations.
       */
      if (item.subProductId) {
        this.onSubProductIdChange(formGroup);
      }
    }

    /**
     * Only if the product is a db-product.
     */
    if (item.product && this.allowChangeUnitOfMeasure) {
      this.subscriptions.add(
        unitOfMeasureIdFormControl.valueChanges.subscribe(() => this.onUnitOfMeasureIdChange(formGroup))
      );
    }

    this.formArray.push(formGroup);
  }

  /**
   * Handles the change in the selected sub product.
   * @param formGroup The product form-group.
   */
  onSubProductIdChange(formGroup: FormGroup): void {
    const product: Product = formGroup.value.product;

    if (!product) {
      return;
    }

    const subProductId: number = formGroup.get('subProductId').value;

    let value = 0;
    if (subProductId) {
      const subProduct = product.subProducts.find((item) => item.id === subProductId);

      if (this.autoInsertPurchasePrice) {
        if (product.hasSubProducts) {
          value = subProduct?.purchasePrice ?? 0;
        } else {
          value = product.purchasePrice;
        }
      } else if (this.autoInsertSalePrice) {
        if (product.hasSubProducts) {
          value = subProduct?.salePrice ?? 0;
        } else {
          value = product.salePrice;
        }
      } else if (this.autoInsertOnHandValue) {
        value =
          product.stocks?.find(
            (stock) => stock.locationId === this.availableStockLocationId && stock.subProductId === subProductId
          )?.value ?? 0;
      } else if (this.autoInsertProductValue) {
        value = formGroup.value.value;
      }

      if (this.isLocationForSubProductWithStorableIngredientsRequired && product.subProductHasIngredients) {
        formGroup.controls.locationId.setValidators(Validators.required);
        formGroup.controls.isSubProductHasStorableIngredients.patchValue(product.subProductHasIngredients);
      } else {
        formGroup.controls.locationId.clearValidators();
        formGroup.controls.isSubProductHasStorableIngredients.patchValue(product.subProductHasIngredients);
      }
    }
    if (this.isLocationForProductWithStorableIngredientsRequired && product.hasIngredients) {
      formGroup.controls.locationId.setValidators(Validators.required);
      formGroup.controls.isProductHasStorableIngredients.patchValue(product.hasIngredients);
    } else {
      formGroup.controls.locationId.clearValidators();
      formGroup.controls.isProductHasStorableIngredients.patchValue(product.hasIngredients);
    }

    /**
     * Update product value.
     */
    formGroup.patchValue({ value });

    /**
     * Update value to match the selected unit of measure.
     */
    this.onUnitOfMeasureIdChange(formGroup);
  }

  /**
   * Handles the change in the selected UOM.
   * @param formGroup The product form-group.
   */
  onUnitOfMeasureIdChange(formGroup: FormGroup): void {
    const product: Product = formGroup.value.product;
    const subProductId: number = formGroup.get('subProductId').value;
    const unitOfMeasureId: number = formGroup.get('unitOfMeasureId').value;

    /**
     * Calculate unit of measure rate.
     */
    const unitOfMeasureRate =
      (unitOfMeasureId &&
        product?.productUnitOfMeasureRates?.find((item) => item.unitOfMeasureId === unitOfMeasureId)?.rate) ??
      1;

    let value = 0;
    if (product && subProductId) {
      const subProduct = product.subProducts?.find((item) => item.id === subProductId);

      if (this.autoInsertPurchasePrice) {
        if (product && product.hasSubProducts) {
          value = Decimal.mul(subProduct?.purchasePrice ?? 0, unitOfMeasureRate).toNumber();
        } else {
          value = Decimal.mul(product.purchasePrice, unitOfMeasureRate).toNumber();
        }
      } else if (this.autoInsertSalePrice) {
        if (product && product.hasSubProducts) {
          value = Decimal.mul(subProduct?.salePrice ?? 0, unitOfMeasureRate).toNumber();
        } else {
          value = Decimal.mul(product.salePrice, unitOfMeasureRate).toNumber();
        }
      } else if (this.autoInsertOnHandValue) {
        value = Decimal.mul(
          product.stocks?.find(
            (stock) => stock.locationId === this.availableStockLocationId && stock.subProductId === subProductId
          )?.value ?? 0,
          unitOfMeasureRate
        ).toNumber();
      } else if (this.autoInsertProductValue) {
        value = formGroup.value.value;
      }
    } else if (product) {
      if (this.autoInsertPurchasePrice) {
        value = Decimal.mul(product.purchasePrice, unitOfMeasureRate).toNumber();
      } else if (this.autoInsertSalePrice) {
        value = Decimal.mul(product.salePrice, unitOfMeasureRate).toNumber();
      } else if (this.autoInsertOnHandValue) {
        value = Decimal.mul(
          product.stocks?.find((stock) => stock.locationId === this.availableStockLocationId)?.value ?? 0,
          unitOfMeasureRate
        ).toNumber();
      }
    }

    /**
     * Update product value.
     */
    formGroup.patchValue({ value });
  }

  /**
   * Opens the modal of the given templateRef.
   * @param modalRef The modal templateRef to be opened.
   */
  openModal(modalRef) {
    this.modalService.open(modalRef);
  }

  /**
   * Open confirm delete all products modal .
   */
  openDeleteModal() {
    this.openModal(this.deleteModalRef);
  }

  /**
   * Remove the product at the given index from the products form.
   * @param index The index of the product form group.
   */
  removeProduct(index: number) {
    this.formArray.removeAt(index);
  }

  /**
   * Remove all of the list of products.
   */
  resetProducts() {
    this.formArray.clear();
  }

  /**
   * Handles the products select event.
   * @param products The newly selected products.
   */
  selectProducts(products: Product[]) {
    if (products) {
      products.map((product) => this.createProductFormGroup(this.createProductFormItemFromProduct(product)));
    }
  }

  /**
   * Selects the newly selected location from the locations search list.
   * @param locations The list of newly selected locations to select the only one in the list.
   */
  selectLocation([location]: Location[]) {
    if (location && this.currentSelectedFormGroup) {
      this.currentSelectedFormGroup.patchValue({
        locations: [location],
        locationId: location.id,
      });
    }
  }

  /**
   * Returns the total value of the given product item.
   * @param item The product item.
   */
  calculateProductTotal(item: ProductsFormItem): number {
    return this.isValueIncludingTax
      ? ProductsFormPOSUtil.invoiceNet([item])
      : ProductsFormUtil.productTotalWithoutTax(item);
  }

  /**
   * Returns the list of suitable units related to the product.
   * @param product The product if exists.
   * @returns A suitable list of units related to the product.
   */
  getProductUnitsOfMeasure(product?: Product): UnitOfMeasure[] {
    const units: UnitOfMeasure[] = [];

    if (!product) {
      units.push(...this.unitsOfMeasure);
    } else {
      units.push(product.unitOfMeasure, ...product.productUnitOfMeasureRates.map((item) => item.unitOfMeasure));
    }

    return units;
  }

  /**
   * Syncs the current options to the local storage.
   */
  syncOptions(): void {
    this.storageService.setProductsFormOptions(this.options);
  }

  /**
   * Selects the newly selected project sub task from the project sub tasks search list.
   * @param projectSubTasks The list of newly selected project sub tasks to select the only one in the list.
   */
  selectProjectSubTask([projectSubTask]: ProjectSubTask[]) {
    if (projectSubTask && this.currentSelectedFormGroup) {
      this.currentSelectedFormGroup.patchValue({
        projectSubTasks: [projectSubTask],
        projectSubTaskId: projectSubTask.id,
      });
    }
  }
}
