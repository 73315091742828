import { Injectable } from '@angular/core';

import { of } from 'rxjs';
import { switchMap, map, catchError, tap } from 'rxjs/operators';
import { Effect, ofType, Actions } from '@ngrx/effects';

import { NotificationService, StatusCode, PAGINATION, TranslationService } from 'shared';
import { PosPrintersService } from 'sales/services';
import {
  CreatePosPrinter,
  CreatePosPrinterFail,
  CreatePosPrinterSuccess,
  DeletePosPrinter,
  DeletePosPrinterFail,
  DeletePosPrinterSuccess,
  FindPosPrinter,
  FindPosPrinterFail,
  FindPosPrinterSuccess,
  SearchPosPrinters,
  SearchPosPrintersFail,
  SearchPosPrintersSuccess,
  PosPrinterActionType,
  UpdatePosPrinter,
  UpdatePosPrinterFail,
  UpdatePosPrinterSuccess,
} from 'sales/store/actions';

@Injectable()
export class PosPrintersEffects {
  constructor(
    private actions$: Actions,
    private posPrintersService: PosPrintersService,
    private notificationService: NotificationService,
    private translationService: TranslationService
  ) {}

  /* ========================= SEARCH_POS_PRINTERS =================================== */
  @Effect()
  search$ = this.actions$.pipe(
    ofType(PosPrinterActionType.SEARCH_POS_PRINTERS),
    switchMap(({ payload }: SearchPosPrinters) =>
      this.posPrintersService
        .search(payload.name, payload.posDevices, payload.locations, payload.page, PAGINATION.PosPrinters)
        .pipe(
          map((response) => new SearchPosPrintersSuccess(response)),
          catchError((error) => of(new SearchPosPrintersFail(error)))
        )
    )
  );

  /* ========================= FIND_POS_PRINTER =================================== */
  @Effect()
  find$ = this.actions$.pipe(
    ofType(PosPrinterActionType.FIND_POS_PRINTER),
    switchMap((action: FindPosPrinter) =>
      this.posPrintersService.findById(action.payload).pipe(
        map((response) => new FindPosPrinterSuccess(response)),
        catchError((error) => of(new FindPosPrinterFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  findFail$ = this.actions$.pipe(
    ofType(PosPrinterActionType.FIND_POS_PRINTER_FAIL),
    tap((action: FindPosPrinterFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(this.translationService.translate('SALES.POS_PRINTERS.POS_PRINTER_NOT_FOUND'));
      }
    })
  );

  /* ========================= CREATE_POS_PRINTER =================================== */
  @Effect()
  create$ = this.actions$.pipe(
    ofType(PosPrinterActionType.CREATE_POS_PRINTER),
    switchMap((action: CreatePosPrinter) =>
      this.posPrintersService.create(action.payload).pipe(
        map((response) => new CreatePosPrinterSuccess(response)),
        catchError((error) => of(new CreatePosPrinterFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  createSuccess$ = this.actions$.pipe(
    ofType(PosPrinterActionType.CREATE_POS_PRINTER_SUCCESS),
    tap((action: CreatePosPrinterSuccess) => {
      this.notificationService.success(this.translationService.translate('SALES.POS_PRINTERS.POS_PRINTER_ADDED'));
    })
  );

  @Effect({ dispatch: false })
  createFail$ = this.actions$.pipe(
    ofType(PosPrinterActionType.CREATE_POS_PRINTER_FAIL),
    tap((action: CreatePosPrinterFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );

  /* ========================= UPDATE_POS_PRINTER =================================== */
  @Effect()
  update$ = this.actions$.pipe(
    ofType(PosPrinterActionType.UPDATE_POS_PRINTER),
    switchMap((action: UpdatePosPrinter) =>
      this.posPrintersService.update(action.payload).pipe(
        map((response) => new UpdatePosPrinterSuccess(response)),
        catchError((error) => of(new UpdatePosPrinterFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  updateSuccess$ = this.actions$.pipe(
    ofType(PosPrinterActionType.UPDATE_POS_PRINTER_SUCCESS),
    tap((action: UpdatePosPrinterSuccess) => {
      this.notificationService.success(this.translationService.translate('SALES.POS_PRINTERS.POS_PRINTER_UPDATED'));
    })
  );

  @Effect({ dispatch: false })
  updateFail$ = this.actions$.pipe(
    ofType(PosPrinterActionType.UPDATE_POS_PRINTER_FAIL),
    tap((action: UpdatePosPrinterFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(this.translationService.translate('SALES.POS_PRINTERS.POS_PRINTER_NOT_FOUND'));
      } else if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );

  /* ========================= DELETE_POS_PRINTER =================================== */
  @Effect()
  delete$ = this.actions$.pipe(
    ofType(PosPrinterActionType.DELETE_POS_PRINTER),
    switchMap((action: DeletePosPrinter) =>
      this.posPrintersService.delete(action.payload).pipe(
        map((response) => new DeletePosPrinterSuccess(response)),
        catchError((error) => of(new DeletePosPrinterFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  deleteSuccess$ = this.actions$.pipe(
    ofType(PosPrinterActionType.DELETE_POS_PRINTER_SUCCESS),
    tap((action: DeletePosPrinterSuccess) => {
      this.notificationService.success(this.translationService.translate('SALES.POS_PRINTERS.POS_PRINTER_DELETED'));
    })
  );

  @Effect({ dispatch: false })
  deleteFail$ = this.actions$.pipe(
    ofType(PosPrinterActionType.DELETE_POS_PRINTER_FAIL),
    tap((action: DeletePosPrinterFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(this.translationService.translate('SALES.POS_PRINTERS.POS_PRINTER_NOT_FOUND'));
      } else if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );
}
