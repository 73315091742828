import { PaginationInfo } from 'shared/models';
import { PettyCashRefundRequest } from 'finances/models';

/**
 * Represents the petty cash refund request state.
 */
export class PettyCashRefundRequestsState {
  /**
   * The list of petty cash refund request.
   */
  public data: PettyCashRefundRequest[];

  /**
   * The pagination info for the petty cash refund request.
   */
  public paginationInfo: PaginationInfo;

  /**
   * An error that may resulting during processing some actions.
   */
  public error: any;

  /**
   * Determines if there is a running search process.
   */
  public isSearching: boolean;

  /**
   * Determines if the last search process has been ended successfully.
   */
  public isSearchCompleted: boolean;

  /**
   * The current selected petty cash refund request.
   */
  public selectedPettyCashRefundRequest: PettyCashRefundRequest;

  /**
   * Determines if there is a running find petty cash refund request process.
   */
  isFinding: boolean;

  /**
   * Determines if the last find petty cash refund request process has been ended successfully.
   */
  isFindCompleted: boolean;

  /**
   * Determines if there is a running create process.
   */
  public isCreating: boolean;

  /**
   * Determines if the last create process has been ended successfully.
   */
  public isCreateCompleted: boolean;

  /**
   * Determines if there is a running update process.
   */
  public isUpdating: boolean;

  /**
   * Determines if the last update process has been ended successfully.
   */
  public isUpdateCompleted: boolean;
}
