import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';

import { AppHttpResponse } from 'shared';
import { CreatePosPrinterInput, PosPrinter, UpdatePosPrinterInput } from 'sales/models';

/**
 * The pos printer services includes functionality to create, search, findById, update and delete for a pos printer.
 */
@Injectable()
export class PosPrintersService {
  /**
   * The relative route for the pos device .
   *
   * No leading or trailing slashes required.
   */
  private PosPrinterApi = 'sales/pos-printers';

  constructor(private http: HttpClient) {}
  /**
   * Creates a new pos printer from the provided data.
   * @param data The new pos printer data.
   */
  public create(data: CreatePosPrinterInput): Observable<AppHttpResponse<PosPrinter>> {
    return this.http.post<AppHttpResponse<PosPrinter>>(`${this.PosPrinterApi}`, data);
  }

  /**
   * Searches in the pos printer by name and locations.
   * @param name of the pos printer name.
   * @param posDevices of the pos printer pos devices.
   * @param locations of the pos printer pos devices.
   * @param page The current pagination page number.
   * @param pageSize The maximum number of pos printer allowed per one pagination page.
   */
  public search(
    name: string,
    posDevices: number[],
    locations: number[],
    page: number,
    pageSize: number
  ): Observable<AppHttpResponse<PosPrinter[]>> {
    const params = new HttpParams()
      .set('name', name)
      .set('posDevices', posDevices.join(','))
      .set('locations', locations.join(','))
      .set('page', page.toString())
      .set('pageSize', pageSize.toString());
    return this.http.get<AppHttpResponse<PosPrinter[]>>(`${this.PosPrinterApi}`, { params });
  }

  /**
   * Finds the pos printer with the given id.
   * @param id The id of the pos printer.
   */
  public findById(id: number): Observable<AppHttpResponse<PosPrinter>> {
    return this.http.get<AppHttpResponse<PosPrinter>>(`${this.PosPrinterApi}/${id}`);
  }

  /**
   * Updates an existing pos printer data using the provided data.
   * @param data The updated pos printer data.
   */
  public update(data: UpdatePosPrinterInput): Observable<AppHttpResponse<PosPrinter>> {
    return this.http.put<AppHttpResponse<PosPrinter>>(`${this.PosPrinterApi}`, data);
  }

  /**
   * Deletes the pos printer by given id.
   * @param id The id of the pos printer.
   */
  public delete(id: number): Observable<AppHttpResponse<PosPrinter>> {
    return this.http.delete<AppHttpResponse<PosPrinter>>(`${this.PosPrinterApi}/${id}`);
  }
}
