<!-- variants search modal starts -->
<ng-template #modalRef let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title text-primary" id="modal-basic-title">
      {{ 'STORES.PRODUCT_VARIANTS.PRODUCTS_VARIANTS_LIST' | translate }}
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="d(); closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <!-- variants list starts -->
    <div class="row">
      <div class="col-md-12">
        <form class="row" [formGroup]="searchForm" (ngSubmit)="search()">
          <div class="form-group col">
            <label for="name">
              {{ 'STORES.PRODUCT_VARIANTS.PRODUCT_VARIANT_DATA.PRODUCT_VARIANT_NAME' | translate }}
            </label>
            <input
              type="text"
              id="name"
              class="form-control round"
              [placeholder]="
                'STORES.PRODUCT_VARIANTS.PRODUCT_VARIANT_DATA.PRODUCT_VARIANT_NAME_SEARCH_PLACEHOLDER' | translate
              "
              formControlName="name"
              (input)="search($event)"
            />
          </div>
          <div class="form-group col">
            <label for="categories">{{
              'STORES.PRODUCT_VARIANTS.PRODUCT_VARIANT_DATA.VARIANT_CATEGORIES_NAME' | translate
            }}</label>
            <ng-select
              class="round"
              formControlName="categories"
              [multiple]="true"
              [closeOnSelect]="false"
              labelForId="categories"
              searchable="true"
              [placeholder]="
                'STORES.PRODUCT_VARIANTS.PRODUCT_VARIANT_DATA.VARIANT_CATEGORIES_SEARCH_PLACEHOLDER' | translate
              "
              [notFoundText]="'SHARED.SEARCH.SEARCH_NOT_FOUND' | translate"
            >
              <ng-option *ngFor="let variantCategory of variantCategories$ | async" [value]="variantCategory.id">
                {{ variantCategory.name | localize: variantCategory.nameEn | async }}
              </ng-option>
            </ng-select>
          </div>
          <div class="col-md-auto form-group d-flex">
            <button
              type="submit"
              class="btn btn-raised btn-primary align-self-end round mx-1 mb-0"
              [disabled]="isSearching$ | async"
            >
              <i class="fa fa-search"></i>
            </button>
          </div>
        </form>
      </div>
      <div class="col-md-12" *ngIf="(variants$ | async)?.length; else noItems">
        <div class="row">
          <div class="col-md-12 alert alert-light text-center" role="alert">
            {{ 'SHARED.SEARCH.SEARCH_RESULTS' | translate }} ({{ (paginationInfo$ | async).total | toNumber }})
            {{ 'STORES.PRODUCT_VARIANTS.PRODUCT_VARIANT_DATA.PRODUCT_VARIANT' | translate }}
          </div>
          <div class="col-md-12">
            <div class="table-responsive">
              <table class="table text-center">
                <thead>
                  <tr>
                    <th class="fit-width" *ngIf="!closeOnSelect">
                      <a
                        class="primary p-0"
                        (click)="selectVariant(variantsList)"
                        [ngbTooltip]="'SHARED.SEARCH.SELECT_ALL' | translate"
                      >
                        <i class="fa fa-check-circle font-medium-3"></i>
                      </a>
                    </th>
                    <th class="fit-width" *ngIf="closeOnSelect">
                      <i class="fa fa-gear font-medium-3"></i>
                    </th>
                    <th scope="col"><i class="fa fa-list-ol"></i></th>
                    <th scope="col">
                      {{ 'STORES.PRODUCT_VARIANTS.PRODUCT_VARIANT_DATA.PRODUCT_VARIANT' | translate }}
                    </th>
                    <th scope="col">
                      {{ 'STORES.PRODUCT_VARIANTS.PRODUCT_VARIANT_DATA.VARIANT_CATEGORY_NAME' | translate }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let variant of variants$ | async; trackBy: trackItems; let i = index">
                    <td>
                      <a
                        class="primary p-0"
                        (click)="selectVariant([variant])"
                        [ngbTooltip]="'STORES.PRODUCT_VARIANTS.PRODUCT_VARIANT_DATA.SELECT_VARIANT' | translate"
                      >
                        <i class="fa fa-check-circle-o font-medium-3"></i>
                      </a>
                    </td>
                    <th scope="row">{{ i + 1 }}</th>
                    <td>{{ variant.name | localize: variant.nameEn | async }}</td>
                    <td>
                      {{ variant.variantCategory.name | localize: variant.variantCategory.nameEn | async }}
                    </td>
                  </tr>
                </tbody>
              </table>
              <div class="d-flex justify-content-between p-2">
                <ngb-pagination
                  *ngIf="paginationInfo$ | async; let pagination"
                  maxSize="5"
                  [collectionSize]="pagination.total"
                  [pageSize]="pagination.pageSize"
                  [page]="pagination.page"
                  (pageChange)="pageChanged($event)"
                  boundaryLinks="true"
                >
                  <ng-template ngbPaginationFirst>{{ 'SHARED.PAGINATION.PAGINATION_FIRST' | translate }}</ng-template>
                  <ng-template ngbPaginationPrevious>{{
                    'SHARED.PAGINATION.PAGINATION_PREVIOUS' | translate
                  }}</ng-template>
                  <ng-template ngbPaginationNext>{{ 'SHARED.PAGINATION.PAGINATION_NEXT' | translate }}</ng-template>
                  <ng-template ngbPaginationLast>{{ 'SHARED.PAGINATION.PAGINATION_LAST' | translate }}</ng-template>
                </ngb-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- variants list ends -->
  </div>
  <div class="modal-footer">
    <div class="modal-actions">
      <button type="button" class="btn btn-outline-dark round" (click)="c(); closeModal()">
        <i class="fa fa-times"></i>
        <span class="mx-1"> {{ 'SHARED.CONFIRM.CLOSE' | translate }} </span>
      </button>
    </div>
  </div>
</ng-template>
<!-- variants search modal ends -->

<!-- no items template starts -->
<ng-template #noItems>
  <div class="col-md-12 alert alert-warning mt-3 text-center" role="alert">
    {{ 'STORES.PRODUCT_VARIANTS.SEARCH_RESULT_PRODUCT_VARIANT_WITHOUT_ADD_NOT_FOUND' | translate }}
  </div>
</ng-template>
<!-- no items template ends -->
