<!-- page header starts -->
<app-page-header [info]="pageInfo"></app-page-header>
<!-- page header ends -->

<!-- finance configurations form starts -->
<div class="card">
  <div class="card-content">
    <div class="px-3 pt-3">
      <div class="row">
        <div class="col-md-12">
          <ul
            class="nav-tabs"
            ngbNav
            #nav="ngbNav"
            [(activeId)]="activePage"
            (activeIdChange)="selectedPageChanged($event)"
            [destroyOnHide]="false"
          >
            <li ngbNavItem="employees-provision">
              <a ngbNavLink>
                <i class="fa fa-support" [class.success]="activePage == 'employees-provision'"></i>
                {{ 'FINANCES.FINANCE_CONFIGURATIONS.FINANCE_CONFIGURATIONS_DATA.EMPLOYEE_PROVISION_LINKS' | translate }}
              </a>

              <ng-template ngbNavContent>
                <div class="row">
                  <div class="col-md-12">
                    <!--employee provision links starts -->
                    <app-employees-provision-links></app-employees-provision-links>
                  </div>
                </div>
              </ng-template>
            </li>
            <li ngbNavItem="governmental-services">
              <a ngbNavLink>
                <i class="fa fa-support" [class.success]="activePage == 'governmental-services'"></i>
                {{ 'FINANCES.FINANCE_CONFIGURATIONS.FINANCE_CONFIGURATIONS_DATA.GOV_SERVICES' | translate }}
              </a>
              <ng-template ngbNavContent>
                <div class="row">
                  <div class="col-md-12">
                    <!--gov services starts -->
                    <app-gov-services></app-gov-services>
                  </div>
                </div>
              </ng-template>
            </li>
          </ul>
          <div [ngbNavOutlet]="nav" class="mt-2"></div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- finance configurations ends -->
