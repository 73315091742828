import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import { Observable, Subscription } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { skip, tap } from 'rxjs/operators';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

import { NotificationMessage, NotificationService, TranslationService, CustomValidators } from 'shared';
import * as fromProjectsManagementStore from 'projects-management/store';
import { Claims } from 'security/models';
import { Project, ProjectMainTask, ProjectSubTask } from 'projects-management/models';

@Component({
  selector: 'app-project-plan-details',
  templateUrl: './project-plan-details.component.html',
  styles: [],
})
export class ProjectPlanDetailsComponent implements OnInit, OnDestroy {
  /**
   * The confirm modal template reference.
   */
  @ViewChild('createProjectMainTaskModalRef') createProjectMainTaskModalRef: ElementRef<any>;

  /**
   * The confirm modal template reference.
   */
  @ViewChild('createProjectSubTaskModalRef') createProjectSubTaskModalRef: ElementRef<any>;

  /**
   * The confirm modal template reference.
   */
  @ViewChild('updateProjectMainTaskModalRef') updateProjectMainTaskModalRef: ElementRef<any>;

  /**
   * The confirm modal template reference.
   */
  @ViewChild('updateProjectSubTaskModalRef') updateProjectSubTaskModalRef: ElementRef<any>;

  /**
   * Sets the project id.
   */
  @Input() projectId: number;

  /**
   * Sets the currently edited project.
   */
  @Input() set project(value: Project) {
    this.projectObj = value;
  }

  /**
   * Indicates if item is collapse is not.
   */
  isMainCollapsed: boolean[] = [];

  /**
   * Gets or sets the currently edited project plan.
   */
  projectObj: Project;

  /**
   * The system supported user claims.
   */
  Claims = Claims;

  /**
   * Indicates whether there is a search process is running or not.
   */
  isProjectMainTaskCreating$: Observable<boolean>;

  /**
   * Indicates whether there is a search process is running or not.
   */
  isProjectSubTaskCreating$: Observable<boolean>;

  /**
   * Indicates whether there is a search process is running or not.
   */
  isProjectMainTaskUpdating$: Observable<boolean>;

  /**
   * Indicates whether there is a search process is running or not.
   */
  isProjectSubTaskUpdating$: Observable<boolean>;

  /**
   * Indicates whether there is a delete-main task process is running or not.
   */
  isProjectMainTaskDeleting$: Observable<boolean>;

  /**
   * Indicates whether there is a delete-sub task process is running or not.
   */
  isProjectSubTaskDeleting$: Observable<boolean>;

  /**
   * Indicates whether there is a search process is running or not.
   */
  isProjectUpdating$: Observable<boolean>;

  /**
   * The project main task form.
   */
  projectMainTaskForm: FormGroup;

  /**
   * The project sub task form.
   */
  projectSubTaskForm: FormGroup;

  /**
   * Gets or sets the currently selected project.
   */
  selectedProjectId: number;

  /**
   * Gets or sets the currently selected project.
   */
  selectedProject: Project;

  /**
   * Gets or sets the currently selected project main task.
   */
  selectedProjectMainTask: ProjectMainTask;

  /**
   * Gets or sets the currently selected project sub task.
   */
  selectedProjectSubTask: ProjectSubTask;

  /**
   * The ngb-modal reference to use it when modal needed to be closed.
   */
  modal: NgbModalRef;

  /**
   * The set of subscriptions on this components,
   * these subscriptions must be unsubscribed before this component got destroyed.
   */
  subscriptions = new Subscription();

  /**
   * @param modalService The modal service.
   * @param notificationService the notification service.
   * @param projectsManagementStore$ The hr-store.
   * @param translationService The translation service.
   */
  constructor(
    private modalService: NgbModal,
    private notificationService: NotificationService,
    private projectsManagementStore$: Store<fromProjectsManagementStore.ProjectsManagementState>,
    private translationService: TranslationService
  ) {}

  ngOnInit(): void {
    this.init();
  }

  /**
   * Destroy component data
   */
  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  /**
   * Initialize component data.
   */
  init() {
    this.initForm();

    /**
     * Load data.
     */

    this.isProjectMainTaskCreating$ = this.projectsManagementStore$.pipe(
      select(fromProjectsManagementStore.getSelectedProjectMainTaskCreating)
    );
    this.isProjectSubTaskCreating$ = this.projectsManagementStore$.pipe(
      select(fromProjectsManagementStore.getSelectedProjectSubTaskCreating)
    );
    this.isProjectMainTaskUpdating$ = this.projectsManagementStore$.pipe(
      select(fromProjectsManagementStore.getSelectedProjectMainTaskUpdating)
    );
    this.isProjectSubTaskUpdating$ = this.projectsManagementStore$.pipe(
      select(fromProjectsManagementStore.getSelectedProjectSubTaskUpdating)
    );
    this.isProjectMainTaskDeleting$ = this.projectsManagementStore$.pipe(
      select(fromProjectsManagementStore.getSelectedProjectMainTaskDeleting)
    );
    this.isProjectSubTaskDeleting$ = this.projectsManagementStore$.pipe(
      select(fromProjectsManagementStore.getSelectedProjectSubTaskDeleting)
    );

    /**
     * rest form on creation complete
     */
    this.subscriptions.add(
      this.projectsManagementStore$
        .pipe(
          select(fromProjectsManagementStore.getSelectedProjectMainTaskCreateCompleted),
          skip(1),
          tap((created) => {
            if (created) {
              this.resetProjectMainTaskForm();
              this.closeModal();
              this.projectMainTaskForm.markAsUntouched();
            }
          })
        )
        .subscribe()
    );

    this.subscriptions.add(
      this.projectsManagementStore$
        .pipe(
          select(fromProjectsManagementStore.getSelectedProjectSubTaskCreateCompleted),
          skip(1),
          tap((created) => {
            if (created) {
              this.resetProjectSubTaskForm();
              this.closeModal();
              this.projectSubTaskForm.markAsUntouched();
            }
          })
        )
        .subscribe()
    );

    /**
     * Closes modal after update complete
     */
    this.subscriptions.add(
      this.projectsManagementStore$
        .pipe(
          select(fromProjectsManagementStore.getSelectedProjectMainTaskUpdateCompleted),
          tap((updated) => {
            if (updated) {
              this.resetProjectMainTaskForm();
              this.closeModal();
            }
          })
        )
        .subscribe()
    );

    this.subscriptions.add(
      this.projectsManagementStore$
        .pipe(
          select(fromProjectsManagementStore.getSelectedProjectSubTaskUpdateCompleted),
          tap((updated) => {
            if (updated) {
              this.resetProjectSubTaskForm();
              this.closeModal();
            }
          })
        )
        .subscribe()
    );

    this.subscriptions.add(
      this.projectsManagementStore$
        .pipe(
          select(fromProjectsManagementStore.getSelectedProjectMainTaskDeleteCompleted),
          tap((deleted) => {
            if (deleted) {
              this.resetProjectMainTaskForm();
              this.closeModal();
            }
          })
        )
        .subscribe()
    );

    this.subscriptions.add(
      this.projectsManagementStore$
        .pipe(
          select(fromProjectsManagementStore.getSelectedProjectSubTaskDeleteCompleted),
          tap((deleted) => {
            if (deleted) {
              this.resetProjectSubTaskForm();
              this.closeModal();
            }
          })
        )
        .subscribe()
    );
  }

  /**
   * Initialize form and add validators.
   */
  initForm() {
    this.projectMainTaskForm = new FormGroup({
      name: new FormControl('', [Validators.required, Validators.minLength(1), Validators.maxLength(50)]),
      nameEn: new FormControl('', [Validators.required, Validators.minLength(1), Validators.maxLength(50)]),
      description: new FormControl('', [Validators.minLength(0), Validators.maxLength(500)]),
      descriptionEn: new FormControl('', [Validators.minLength(0), Validators.maxLength(500)]),
      startDate: new FormControl(null, Validators.required),
      endDate: new FormControl(null, Validators.required),
    });

    this.projectSubTaskForm = new FormGroup({
      name: new FormControl('', [Validators.required, Validators.minLength(1), Validators.maxLength(50)]),
      nameEn: new FormControl('', [Validators.minLength(0), Validators.maxLength(50)]),
      description: new FormControl('', [Validators.minLength(0), Validators.maxLength(500)]),
      descriptionEn: new FormControl('', [Validators.minLength(0), Validators.maxLength(500)]),
      startDate: new FormControl(null, Validators.required),
      endDate: new FormControl(null, Validators.required),
      budget: new FormControl('', [Validators.required, CustomValidators.gt(0)]),
    });
  }

  /**
   * Opens the modal of the given templateRef.
   * @param modalRef The modal templateRef to be opened.
   */
  openModal(modalRef) {
    /**
     * Open the modal & listen for user press ESC key.
     */
    this.modal = this.modalService.open(modalRef, { size: 'lg' });
    this.modal.result.then(null, () => this.closeModal());
  }

  /**
   * Closes the currently opened modal.
   */
  closeModal() {
    this.modal?.dismiss();
  }

  /**
   * Submits the create project main task form.
   */
  submitCreateProjectMainTask() {
    const errorMessage = new NotificationMessage();

    if (this.projectMainTaskForm.invalid) {
      if (this.projectMainTaskForm.get('name').invalid) {
        errorMessage.title = this.translationService.translate(
          'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA_VALIDATION.MAIN_TASK_NAME_ARABIC_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate(
            'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA_VALIDATION.MAIN_TASK_NAME_IS_REQUIRED'
          ),
          this.translationService.translate(
            'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA_VALIDATION.MAIN_TASK_NAME_LENGTH_ERROR'
          ),
        ];
      } else if (this.projectMainTaskForm.get('nameEn').invalid) {
        errorMessage.title = this.translationService.translate(
          'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA_VALIDATION.MAIN_TASK_NAME_ENGLISH_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate(
            'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA_VALIDATION.MAIN_TASK_NAME_IS_REQUIRED'
          ),
          this.translationService.translate(
            'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA_VALIDATION.MAIN_TASK_NAME_LENGTH_ERROR'
          ),
        ];
      } else if (this.projectMainTaskForm.get('startDate').invalid) {
        errorMessage.title = this.translationService.translate(
          'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA_VALIDATION.START_DATE_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate(
            'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA_VALIDATION.START_DATE_IS_REQUIRED'
          ),
        ];
      } else if (this.projectMainTaskForm.get('endDate').invalid) {
        errorMessage.title = this.translationService.translate(
          'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA_VALIDATION.END_DATE_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate(
            'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA_VALIDATION.END_DATE_IS_REQUIRED'
          ),
        ];
      } else if (this.projectMainTaskForm.get('description').invalid) {
        errorMessage.title = this.translationService.translate(
          'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA_VALIDATION.MAIN_TASK_DESCRIPTION_ARABIC_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate(
            'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA_VALIDATION.MAIN_TASK_DESCRIPTION_LENGTH_ERROR'
          ),
        ];
      } else if (this.projectMainTaskForm.get('descriptionEn').invalid) {
        errorMessage.title = this.translationService.translate(
          'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA_VALIDATION.MAIN_TASK_DESCRIPTION_ENGLISH_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate(
            'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA_VALIDATION.MAIN_TASK_DESCRIPTION_LENGTH_ERROR'
          ),
        ];
      }
      this.projectMainTaskForm.markAllAsTouched();
      return this.notificationService.warningWithTitle(errorMessage);
    }
    this.createProjectMainTask();
  }

  /**
   * Submits the update project main task form.
   */
  submitUpdateProjectMainTask() {
    const errorMessage = new NotificationMessage();

    if (this.projectMainTaskForm.invalid) {
      if (this.projectMainTaskForm.get('name').invalid) {
        errorMessage.title = this.translationService.translate(
          'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA_VALIDATION.MAIN_TASK_NAME_ARABIC_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate(
            'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA_VALIDATION.MAIN_TASK_NAME_IS_REQUIRED'
          ),
          this.translationService.translate(
            'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA_VALIDATION.MAIN_TASK_NAME_LENGTH_ERROR'
          ),
        ];
      } else if (this.projectMainTaskForm.get('nameEn').invalid) {
        errorMessage.title = this.translationService.translate(
          'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA_VALIDATION.MAIN_TASK_NAME_ENGLISH_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate(
            'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA_VALIDATION.MAIN_TASK_NAME_IS_REQUIRED'
          ),
          this.translationService.translate(
            'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA_VALIDATION.MAIN_TASK_NAME_LENGTH_ERROR'
          ),
        ];
      } else if (this.projectMainTaskForm.get('startDate').invalid) {
        errorMessage.title = this.translationService.translate(
          'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA_VALIDATION.START_DATE_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate(
            'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA_VALIDATION.START_DATE_IS_REQUIRED'
          ),
        ];
      } else if (this.projectMainTaskForm.get('endDate').invalid) {
        errorMessage.title = this.translationService.translate(
          'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA_VALIDATION.END_DATE_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate(
            'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA_VALIDATION.END_DATE_IS_REQUIRED'
          ),
        ];
      } else if (this.projectMainTaskForm.get('description').invalid) {
        errorMessage.title = this.translationService.translate(
          'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA_VALIDATION.MAIN_TASK_DESCRIPTION_ARABIC_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate(
            'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA_VALIDATION.MAIN_TASK_DESCRIPTION_LENGTH_ERROR'
          ),
        ];
      } else if (this.projectMainTaskForm.get('descriptionEn').invalid) {
        errorMessage.title = this.translationService.translate(
          'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA_VALIDATION.MAIN_TASK_DESCRIPTION_ENGLISH_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate(
            'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA_VALIDATION.MAIN_TASK_DESCRIPTION_LENGTH_ERROR'
          ),
        ];
      }

      this.projectMainTaskForm.markAllAsTouched();
      return this.notificationService.warningWithTitle(errorMessage);
    }
    this.updateProjectMainTask();
  }

  /**
   * Submits the create project sub task form.
   */
  submitCreateProjectSubTask() {
    const errorMessage = new NotificationMessage();

    if (this.projectSubTaskForm.invalid) {
      if (this.projectSubTaskForm.get('name').invalid) {
        errorMessage.title = this.translationService.translate(
          'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA_VALIDATION.SUB_TASK_NAME_ARABIC_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate(
            'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA_VALIDATION.SUB_TASK_NAME_IS_REQUIRED'
          ),
          this.translationService.translate(
            'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA_VALIDATION.SUB_TASK_NAME_LENGTH_ERROR'
          ),
        ];
      } else if (this.projectSubTaskForm.get('nameEn').invalid) {
        errorMessage.title = this.translationService.translate(
          'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA_VALIDATION.SUB_TASK_NAME_ENGLISH_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate(
            'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA_VALIDATION.SUB_TASK_NAME_IS_REQUIRED'
          ),
          this.translationService.translate(
            'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA_VALIDATION.SUB_TASK_NAME_LENGTH_ERROR'
          ),
        ];
      } else if (this.projectSubTaskForm.get('startDate').invalid) {
        errorMessage.title = this.translationService.translate(
          'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA_VALIDATION.START_DATE_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate(
            'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA_VALIDATION.START_DATE_IS_REQUIRED'
          ),
        ];
      } else if (this.projectSubTaskForm.get('endDate').invalid) {
        errorMessage.title = this.translationService.translate(
          'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA_VALIDATION.END_DATE_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate(
            'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA_VALIDATION.END_DATE_IS_REQUIRED'
          ),
        ];
      } else if (this.projectSubTaskForm.get('budget').invalid) {
        errorMessage.title = this.translationService.translate(
          'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA_VALIDATION.BUDGET_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate('PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA_VALIDATION.BUDGET_IS_REQUIRED'),
          this.translationService.translate('PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA_VALIDATION.BUDGET_LENGTH_ERROR'),
        ];
      } else if (this.projectSubTaskForm.get('description').invalid) {
        errorMessage.title = this.translationService.translate(
          'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA_VALIDATION.SUB_TASK_DESCRIPTION_ARABIC_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate(
            'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA_VALIDATION.SUB_TASK_DESCRIPTION_LENGTH_ERROR'
          ),
        ];
      } else if (this.projectSubTaskForm.get('descriptionEn').invalid) {
        errorMessage.title = this.translationService.translate(
          'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA_VALIDATION.SUB_TASK_DESCRIPTION_ENGLISH_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate(
            'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA_VALIDATION.SUB_TASK_DESCRIPTION_LENGTH_ERROR'
          ),
        ];
      }

      this.projectSubTaskForm.markAllAsTouched();
      return this.notificationService.warningWithTitle(errorMessage);
    }
    this.createProjectSubTask();
  }

  /**
   * Submits the update project sub task form.
   */
  submitUpdateProjectSubTask() {
    const errorMessage = new NotificationMessage();

    if (this.projectSubTaskForm.invalid) {
      if (this.projectSubTaskForm.get('name').invalid) {
        errorMessage.title = this.translationService.translate(
          'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA_VALIDATION.SUB_TASK_NAME_ARABIC_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate(
            'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA_VALIDATION.SUB_TASK_NAME_IS_REQUIRED'
          ),
          this.translationService.translate(
            'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA_VALIDATION.SUB_TASK_NAME_LENGTH_ERROR'
          ),
        ];
      } else if (this.projectSubTaskForm.get('nameEn').invalid) {
        errorMessage.title = this.translationService.translate(
          'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA_VALIDATION.SUB_TASK_NAME_ENGLISH_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate(
            'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA_VALIDATION.SUB_TASK_NAME_IS_REQUIRED'
          ),
          this.translationService.translate(
            'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA_VALIDATION.SUB_TASK_NAME_LENGTH_ERROR'
          ),
        ];
      } else if (this.projectSubTaskForm.get('startDate').invalid) {
        errorMessage.title = this.translationService.translate(
          'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA_VALIDATION.START_DATE_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate(
            'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA_VALIDATION.START_DATE_IS_REQUIRED'
          ),
        ];
      } else if (this.projectSubTaskForm.get('endDate').invalid) {
        errorMessage.title = this.translationService.translate(
          'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA_VALIDATION.END_DATE_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate(
            'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA_VALIDATION.END_DATE_IS_REQUIRED'
          ),
        ];
      } else if (this.projectSubTaskForm.get('budget').invalid) {
        errorMessage.title = this.translationService.translate(
          'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA_VALIDATION.BUDGET_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate('PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA_VALIDATION.BUDGET_IS_REQUIRED'),
          this.translationService.translate('PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA_VALIDATION.BUDGET_LENGTH_ERROR'),
        ];
      } else if (this.projectSubTaskForm.get('description').invalid) {
        errorMessage.title = this.translationService.translate(
          'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA_VALIDATION.SUB_TASK_DESCRIPTION_ARABIC_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate(
            'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA_VALIDATION.SUB_TASK_DESCRIPTION_LENGTH_ERROR'
          ),
        ];
      } else if (this.projectSubTaskForm.get('descriptionEn').invalid) {
        errorMessage.title = this.translationService.translate(
          'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA_VALIDATION.SUB_TASK_DESCRIPTION_ENGLISH_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate(
            'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA_VALIDATION.SUB_TASK_DESCRIPTION_LENGTH_ERROR'
          ),
        ];
      }

      this.projectSubTaskForm.markAllAsTouched();
      return this.notificationService.warningWithTitle(errorMessage);
    }
    this.updateProjectSubTask();
  }

  /**
   * Creates a new list of project main task only if the filled data passed validations.
   */
  createProjectMainTask() {
    let startDateFormatted;
    let endDateFormatted;

    const { startDate, endDate, description, descriptionEn } = this.projectMainTaskForm.value;
    if (startDate && startDate.year) {
      startDateFormatted = new Date(startDate.year, startDate.month - 1, startDate.day);
      startDateFormatted.setHours(new Date().getHours(), new Date().getMinutes());
      this.projectMainTaskForm.value.startDate = startDateFormatted;
    }

    if (endDate && endDate.year) {
      endDateFormatted = new Date(endDate.year, endDate.month - 1, endDate.day);
      endDateFormatted.setHours(new Date().getHours(), new Date().getMinutes());
      this.projectMainTaskForm.value.endDate = endDateFormatted;
    }

    this.projectsManagementStore$.dispatch(
      new fromProjectsManagementStore.CreateProjectMainTask({
        projectId: this.projectObj.id,
        ...this.projectMainTaskForm.value,
        description: description ? description : '',
        descriptionEn: description ? descriptionEn : '',
      })
    );
  }

  /**
   * Update a new list of project main task only if the filled data passed validations.
   */
  updateProjectMainTask() {
    let startDateFormatted;
    let endDateFormatted;

    const { startDate, endDate } = this.projectMainTaskForm.value;
    if (startDate && startDate.year) {
      startDateFormatted = new Date(startDate.year, startDate.month - 1, startDate.day);
      startDateFormatted.setHours(new Date().getHours(), new Date().getMinutes());
      this.projectMainTaskForm.value.startDate = startDateFormatted;
    }

    if (endDate && endDate.year) {
      endDateFormatted = new Date(endDate.year, endDate.month - 1, endDate.day);
      endDateFormatted.setHours(new Date().getHours(), new Date().getMinutes());
      this.projectMainTaskForm.value.endDate = endDateFormatted;
    }

    this.projectsManagementStore$.dispatch(
      new fromProjectsManagementStore.UpdateProjectMainTask({
        projectMainTaskId: this.selectedProjectMainTask.id,
        ...this.projectMainTaskForm.value,
      })
    );
  }

  /**
   * Deletes the project main task with the given id.
   * @param projectMainTaskId The id of the project main task to be deleted.
   */
  deleteProjectMainTask(projectMainTaskId: number) {
    this.projectsManagementStore$.dispatch(new fromProjectsManagementStore.DeleteProjectMainTask(projectMainTaskId));
  }

  /**
   * Creates a new list of project sub task only if the filled data passed validations.
   */
  createProjectSubTask() {
    let startDateFormatted;
    let endDateFormatted;

    const { startDate, endDate, description, descriptionEn } = this.projectSubTaskForm.value;
    if (startDate && startDate.year) {
      startDateFormatted = new Date(startDate.year, startDate.month - 1, startDate.day);
      startDateFormatted.setHours(new Date().getHours(), new Date().getMinutes());
      this.projectSubTaskForm.value.startDate = startDateFormatted;
    }

    if (endDate && endDate.year) {
      endDateFormatted = new Date(endDate.year, endDate.month - 1, endDate.day);
      endDateFormatted.setHours(new Date().getHours(), new Date().getMinutes());
      this.projectSubTaskForm.value.endDate = endDateFormatted;
    }

    this.projectsManagementStore$.dispatch(
      new fromProjectsManagementStore.CreateProjectSubTask({
        projectMainTaskId: this.selectedProjectMainTask.id,
        projectId: this.selectedProjectMainTask.projectId,
        ...this.projectSubTaskForm.value,
        description: description ? description : '',
        descriptionEn: description ? descriptionEn : '',
      })
    );
  }

  /**
   * Update a new list of project sub task only if the filled data passed validations.
   */
  updateProjectSubTask() {
    let startDateFormatted;
    let endDateFormatted;

    const { startDate, endDate } = this.projectSubTaskForm.value;
    if (startDate && startDate.year) {
      startDateFormatted = new Date(startDate.year, startDate.month - 1, startDate.day);
      startDateFormatted.setHours(new Date().getHours(), new Date().getMinutes());
      this.projectSubTaskForm.value.startDate = startDateFormatted;
    }

    if (endDate && endDate.year) {
      endDateFormatted = new Date(endDate.year, endDate.month - 1, endDate.day);
      endDateFormatted.setHours(new Date().getHours(), new Date().getMinutes());
      this.projectSubTaskForm.value.endDate = endDateFormatted;
    }

    this.projectsManagementStore$.dispatch(
      new fromProjectsManagementStore.UpdateProjectSubTask({
        projectSubTaskId: this.selectedProjectSubTask.id,
        ...this.projectSubTaskForm.value,
      })
    );
  }

  /**
   * Deletes the project sub task with the given id.
   * @param projectSubTaskId The id of the project sub task to be deleted.
   */
  deleteProjectSubTask(projectSubTaskId: number) {
    this.projectsManagementStore$.dispatch(new fromProjectsManagementStore.DeleteProjectSubTask(projectSubTaskId));
  }

  /**
   * Resets all form data.
   *
   * It only adds one product for quick start.
   */
  resetProjectMainTaskForm() {
    this.projectMainTaskForm.reset();
  }

  /**
   * Resets all form data.
   *
   * It only adds one product for quick start.
   */
  resetProjectSubTaskForm() {
    this.projectSubTaskForm.reset();
  }
}
