import { Component, Input } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';

import { Payroll, PayrollItem } from 'hr/models';

@Component({
  selector: 'app-pay-payrolls-form',
  templateUrl: './pay-payrolls-form.component.html',
  styles: [],
})
export class PayPayrollsFormComponent {
  /**
   * Sets the in review in the payrolls false.
   */
  @Input() inReview: false;

  /**
   * Sets the in employee in the payrolls false.
   */
  @Input() isEmployee: false;

  /**
   * Sets the payrolls form-array.
   */
  @Input() payrollsForm: FormArray;

  /**
   * Sets the initial payrolls in the payrolls form.
   * @param  payrolls An object contains `count` of initial blank payrolls.
   * @param  payrolls A single payrolls details to be added to the form.
   * @param  payrolls A list of payrolls details to be added to the form.
   */
  @Input() set initialPayrolls(payrolls: { count: number } | PayrollItem | PayrollItem[]) {
    if (Object.getOwnPropertyNames(payrolls).includes('count')) {
      for (let index = 0; index < (payrolls as { count: number }).count; index++) {
        this.addPayroll();
      }
    } else if (payrolls instanceof Array) {
      payrolls.forEach((payroll) => this.addPayroll(payroll));
    } else if (typeof payrolls === 'object') {
      this.addPayroll(payrolls as PayrollItem);
    }
  }

  /**
   * Gets or sets the initial select list journals
   * which will be passed to the child form-controls to allow render payroll description.
   */
  initialSelectPayrolls: Payroll[] = [];

  /**
   * Indicates that a drop-down field in the form is opened and therefore the table height should be expanded.
   */
  dropDownIsOpened: boolean;

  /**
   * Creates & adds a new payroll form-group with validations.
   * @param  payroll The payroll details to be bounded to the payroll, If omitted a blank payroll will be created.
   */
  addPayroll(payroll?: PayrollItem) {
    const payrollFormGroup = new FormGroup({
      id: new FormControl(payroll?.id),
      employee: new FormControl(payroll?.employee),
      employeeId: new FormControl(payroll?.employeeId),
      net: new FormControl(payroll?.net),
      salary: new FormControl(payroll?.salary),
      salaryForGosi: new FormControl(payroll?.salaryForGosi),
      employeeGosi: new FormControl(payroll?.employeeGosi),
      employerGosi: new FormControl(payroll?.employerGosi),
      employeeSponsor: new FormControl(payroll?.employeeSponsor),
      employeeSponsorId: new FormControl(payroll?.employeeSponsorId),
      payrollGosiPayrollElements: new FormControl(payroll?.payrollGosiPayrollElements),
      loanSettlement: new FormControl(payroll?.loanSettlement),
      otherEarnings: new FormControl(payroll?.otherEarnings),
      deductions: new FormControl(payroll?.deductions),
      overtime: new FormControl(payroll?.overtime),
      transactionDate: new FormControl(payroll?.transactionDate ?? null),
      createdAt: new FormControl(payroll?.createdAt ?? null),
      payrollStatus: new FormControl(payroll?.payrollStatus),
      notes: new FormControl(payroll?.notes ?? ''),
      payrollRecurringElements: new FormControl(payroll?.payrollRecurringElements),
      payrollLoanSettlements: new FormControl(payroll?.payrollLoanSettlements),
      payrollOvertimes: new FormControl(payroll?.payrollOvertimes),
      payrollOtherEarnings: new FormControl(payroll?.payrollOtherEarnings),
      payrollDeductions: new FormControl(payroll?.payrollDeductions),
      payrollLocationCosts: new FormControl(payroll?.payrollLocationCosts),
      payrollProvisions: new FormControl(payroll?.payrollProvisions),
      payrollProjectCosts: new FormControl(payroll?.payrollProjectCosts),
      payrollUnpaidLeaves: new FormControl(payroll?.payrollUnpaidLeaves),
      payrollAttachments: new FormControl(payroll?.payrollAttachments),
      payrollDate: new FormControl(payroll?.payrollDate),
      workDays: new FormControl(payroll?.workDays),
      attachments: new FormArray([]),
    });
    this.payrollsForm.push(payrollFormGroup);
  }

  /**
   * Remove the payroll at the given index from the list.
   * @param index The index of the payroll to be removed.
   */
  removeLine(index: number) {
    this.payrollsForm.removeAt(index);
  }
}
