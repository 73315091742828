import { Injectable } from '@angular/core';

import { of } from 'rxjs';
import { switchMap, map, catchError, tap, debounceTime } from 'rxjs/operators';
import { Effect, ofType, Actions } from '@ngrx/effects';

import { NotificationService, StatusCode, PAGINATION, TranslationService } from 'shared';
import { ProjectsService } from 'projects-management/services';
import {
  ProjectsActionType,
  CreateProject,
  CreateProjectFail,
  CreateProjectSuccess,
  DeleteProject,
  DeleteProjectFail,
  DeleteProjectSuccess,
  FindProject,
  FindProjectFail,
  FindProjectSuccess,
  FindProjectRevenue,
  FindProjectRevenueFail,
  FindProjectRevenueSuccess,
  SearchProjects,
  SearchProjectsFail,
  SearchProjectsSuccess,
  SearchMainTasks,
  SearchMainTasksFail,
  SearchMainTasksSuccess,
  SearchSubTasks,
  SearchSubTasksFail,
  SearchSubTasksSuccess,
  UpdateProject,
  UpdateProjectFail,
  UpdateProjectSuccess,
  SearchProjectStatusLogs,
  SearchProjectStatusLogsSuccess,
  SearchProjectStatusLogsFail,
  SearchProjectResources,
  SearchProjectResourcesSuccess,
  SearchProjectResourcesFail,
  CreateProjectStatusLog,
  CreateProjectStatusLogSuccess,
  CreateProjectStatusLogFail,
  CreateProjectPlanningResource,
  CreateProjectPlanningResourceFail,
  CreateProjectPlanningResourceSuccess,
  DeleteProjectPlanningResource,
  DeleteProjectPlanningResourceFail,
  DeleteProjectPlanningResourceSuccess,
  CreateProjectMainTask,
  CreateProjectMainTaskSuccess,
  CreateProjectMainTaskFail,
  CreateProjectSubTask,
  CreateProjectSubTaskSuccess,
  CreateProjectSubTaskFail,
  UpdateProjectMainTask,
  UpdateProjectMainTaskSuccess,
  UpdateProjectMainTaskFail,
  UpdateProjectSubTask,
  UpdateProjectSubTaskSuccess,
  UpdateProjectSubTaskFail,
  SearchProjectLogs,
  SearchProjectLogsSuccess,
  SearchProjectLogsFail,
  DeleteProjectMainTask,
  DeleteProjectMainTaskSuccess,
  DeleteProjectMainTaskFail,
  DeleteProjectSubTask,
  DeleteProjectSubTaskSuccess,
  DeleteProjectSubTaskFail,
  FindProjectResource,
  FindProjectResourceFail,
  FindProjectResourceSuccess,
  SearchProjectRevenues,
  SearchProjectRevenuesFail,
  SearchProjectRevenuesSuccess,
} from 'projects-management/store/actions';

@Injectable()
export class ProjectsEffects {
  constructor(
    private actions$: Actions,
    private ProjectsService: ProjectsService,
    private notificationService: NotificationService,
    private translationService: TranslationService
  ) {}

  /* ========================= SEARCH_PROJECTS =================================== */
  @Effect()
  search$ = this.actions$.pipe(
    ofType(ProjectsActionType.SEARCH_PROJECTS),
    debounceTime(300),
    switchMap(({ payload }: SearchProjects) =>
      this.ProjectsService.search(
        payload?.description ?? '',
        payload.locations,
        payload.projectStatuses,
        payload.customers,
        payload.page,
        PAGINATION.Projects
      ).pipe(
        map((response) => new SearchProjectsSuccess(response)),
        catchError((error) => of(new SearchProjectsFail(error)))
      )
    )
  );

  /* ========================= SEARCH_MAIN_TASKS =================================== */
  @Effect()
  searchMainTasks$ = this.actions$.pipe(
    ofType(ProjectsActionType.SEARCH_MAIN_TASKS),
    debounceTime(300),
    switchMap(({ payload }: SearchMainTasks) =>
      this.ProjectsService.searchMainTasks(
        payload?.description ?? '',
        payload.projects,
        payload.page,
        PAGINATION.MainTasks
      ).pipe(
        map((response) => new SearchMainTasksSuccess(response)),
        catchError((error) => of(new SearchMainTasksFail(error)))
      )
    )
  );

  /* ========================= SEARCH_SUB_TASKS =================================== */
  @Effect()
  searchSubTasks$ = this.actions$.pipe(
    ofType(ProjectsActionType.SEARCH_SUB_TASKS),
    debounceTime(300),
    switchMap(({ payload }: SearchSubTasks) =>
      this.ProjectsService.searchSubTasks(
        payload?.description ?? '',
        payload.projects,
        payload.page,
        PAGINATION.SubTasks
      ).pipe(
        map((response) => new SearchSubTasksSuccess(response)),
        catchError((error) => of(new SearchSubTasksFail(error)))
      )
    )
  );

  /* ========================= SEARCH_PROJECT_LOGS =================================== */
  @Effect()
  searchProjectLogs$ = this.actions$.pipe(
    ofType(ProjectsActionType.SEARCH_PROJECT_LOGS),
    debounceTime(300),
    switchMap(({ payload }: SearchProjectLogs) =>
      this.ProjectsService.searchProjectLogs(
        payload?.projectId,
        payload?.projectLogTypes,
        payload?.fromDate,
        payload?.toDate,
        payload.page,
        PAGINATION.ProjectLogs
      ).pipe(
        map((response) => new SearchProjectLogsSuccess(response)),
        catchError((error) => of(new SearchProjectLogsFail(error)))
      )
    )
  );

  /* ========================= SEARCH_PROJECT_REVENUES =================================== */
  @Effect()
  searchProjectRevenues$ = this.actions$.pipe(
    ofType(ProjectsActionType.SEARCH_PROJECT_REVENUES),
    debounceTime(300),
    switchMap(({ payload }: SearchProjectRevenues) =>
      this.ProjectsService.searchProjectRevenues(
        payload?.projectId,
        payload?.projectRevenueTypes,
        payload?.fromDate,
        payload?.toDate,
        payload.page,
        PAGINATION.ProjectRevenues
      ).pipe(
        map((response) => new SearchProjectRevenuesSuccess(response)),
        catchError((error) => of(new SearchProjectRevenuesFail(error)))
      )
    )
  );

  /* ========================= SEARCH_PROJECT_STATUS_LOGS =================================== */
  @Effect()
  searchProjectStatusLogs$ = this.actions$.pipe(
    ofType(ProjectsActionType.SEARCH_PROJECT_STATUS_LOGS),
    switchMap(({ payload }: SearchProjectStatusLogs) => {
      return this.ProjectsService.searchProjectStatusLogs(
        payload.projectId,
        payload.fromDate,
        payload.toDate,
        payload.page,
        PAGINATION.ProjectStatusLogs
      ).pipe(
        map((response: any) => new SearchProjectStatusLogsSuccess(response)),
        catchError((error) => of(new SearchProjectStatusLogsFail(error)))
      );
    })
  );

  /* ========================= SEARCH_PROJECT_RESOURCES =================================== */
  @Effect()
  searchProjectResources$ = this.actions$.pipe(
    ofType(ProjectsActionType.SEARCH_PROJECT_RESOURCES),
    switchMap(({ payload }: SearchProjectResources) => {
      return this.ProjectsService.searchProjectResources(
        payload?.projectId,
        payload?.projectMainTasks,
        payload?.projectSubTasks,
        payload?.projectResourceTypes,
        payload?.fromDate,
        payload?.toDate,
        payload.page,
        PAGINATION.ProjectResources
      ).pipe(
        map((response: any) => new SearchProjectResourcesSuccess(response)),
        catchError((error) => of(new SearchProjectResourcesFail(error)))
      );
    })
  );

  /* ========================= FIND_PROJECT =================================== */
  @Effect()
  find$ = this.actions$.pipe(
    ofType(ProjectsActionType.FIND_PROJECT),
    switchMap((action: FindProject) =>
      this.ProjectsService.findById(action.payload).pipe(
        map((response) => new FindProjectSuccess(response)),
        catchError((error) => of(new FindProjectFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  findFail$ = this.actions$.pipe(
    ofType(ProjectsActionType.FIND_PROJECT_FAIL),
    tap((action: FindProjectFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate('PROJECTS_MANAGEMENT.PROJECTS.PROJECT_NOT_FOUND')
        );
      }
    })
  );

  /* ========================= FIND_PROJECT_REVENUE =================================== */
  @Effect()
  findProjectRevenue$ = this.actions$.pipe(
    ofType(ProjectsActionType.FIND_PROJECT_REVENUE),
    switchMap((action: FindProjectRevenue) =>
      this.ProjectsService.findProjectRevenueById(action.payload).pipe(
        map((response) => new FindProjectRevenueSuccess(response)),
        catchError((error) => of(new FindProjectRevenueFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  findProjectRevenueFail$ = this.actions$.pipe(
    ofType(ProjectsActionType.FIND_PROJECT_REVENUE_FAIL),
    tap((action: FindProjectRevenueFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate('PROJECTS_MANAGEMENT.PROJECTS.PROJECT_REVENUE_NOT_FOUND')
        );
      }
    })
  );

  /* ========================= FIND_PROJECT_RESOURCE =================================== */
  @Effect()
  findProjectResource$ = this.actions$.pipe(
    ofType(ProjectsActionType.FIND_PROJECT_RESOURCE),
    switchMap((action: FindProjectResource) =>
      this.ProjectsService.findProjectResourceById(action.payload).pipe(
        map((response) => new FindProjectResourceSuccess(response)),
        catchError((error) => of(new FindProjectResourceFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  findProjectResourceFail$ = this.actions$.pipe(
    ofType(ProjectsActionType.FIND_PROJECT_RESOURCE_FAIL),
    tap((action: FindProjectResourceFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate('PROJECTS_MANAGEMENT.PROJECTS.PROJECT_RESOURCE_NOT_FOUND')
        );
      }
    })
  );

  /* ========================= CREATE_PROJECT =================================== */
  @Effect()
  create$ = this.actions$.pipe(
    ofType(ProjectsActionType.CREATE_PROJECT),
    switchMap((action: CreateProject) =>
      this.ProjectsService.create(action.payload).pipe(
        map((response) => new CreateProjectSuccess(response)),
        catchError((error) => of(new CreateProjectFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  createSuccess$ = this.actions$.pipe(
    ofType(ProjectsActionType.CREATE_PROJECT_SUCCESS),
    tap((action: CreateProjectSuccess) => {
      this.notificationService.success(this.translationService.translate('PROJECTS_MANAGEMENT.PROJECTS.PROJECT_ADDED'));
    })
  );

  @Effect({ dispatch: false })
  createFail$ = this.actions$.pipe(
    ofType(ProjectsActionType.CREATE_PROJECT_FAIL),
    tap((action: CreateProjectFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );

  /* ========================= CREATE_PROJECT_MAIN_TASK =================================== */
  @Effect()
  createProjectMainTask$ = this.actions$.pipe(
    ofType(ProjectsActionType.CREATE_PROJECT_MAIN_TASK),
    switchMap((action: CreateProjectMainTask) =>
      this.ProjectsService.createProjectMainTask(action.payload).pipe(
        map((response) => new CreateProjectMainTaskSuccess(response)),
        catchError((error) => of(new CreateProjectMainTaskFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  createProjectMainTaskSuccess$ = this.actions$.pipe(
    ofType(ProjectsActionType.CREATE_PROJECT_MAIN_TASK_SUCCESS),
    tap((action: CreateProjectMainTaskSuccess) => {
      this.notificationService.success(
        this.translationService.translate('PROJECTS_MANAGEMENT.PROJECTS.PROJECT_MAIN_TASK_ADDED')
      );
    })
  );

  @Effect({ dispatch: false })
  createProjectMainTaskFail$ = this.actions$.pipe(
    ofType(ProjectsActionType.CREATE_PROJECT_MAIN_TASK_FAIL),
    tap((action: CreateProjectMainTaskFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );

  /* ========================= CREATE_PROJECT_SUB_TASK =================================== */
  @Effect()
  createProjectSubTask$ = this.actions$.pipe(
    ofType(ProjectsActionType.CREATE_PROJECT_SUB_TASK),
    switchMap((action: CreateProjectSubTask) =>
      this.ProjectsService.createProjectSubTask(action.payload).pipe(
        map((response) => new CreateProjectSubTaskSuccess(response)),
        catchError((error) => of(new CreateProjectSubTaskFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  createProjectSubTaskSuccess$ = this.actions$.pipe(
    ofType(ProjectsActionType.CREATE_PROJECT_SUB_TASK_SUCCESS),
    tap((action: CreateProjectSubTaskSuccess) => {
      this.notificationService.success(
        this.translationService.translate('PROJECTS_MANAGEMENT.PROJECTS.PROJECT_SUB_TASK_ADDED')
      );
    })
  );

  @Effect({ dispatch: false })
  createProjectSubTaskFail$ = this.actions$.pipe(
    ofType(ProjectsActionType.CREATE_PROJECT_SUB_TASK_FAIL),
    tap((action: CreateProjectSubTaskFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );

  /* ========================= CREATE_PROJECT_STATUS_LOG =================================== */
  @Effect()
  createLog$ = this.actions$.pipe(
    ofType(ProjectsActionType.CREATE_PROJECT_STATUS_LOG),
    switchMap((action: CreateProjectStatusLog) =>
      this.ProjectsService.createProjectStatusLog(action.payload).pipe(
        map((response) => new CreateProjectStatusLogSuccess(response)),
        catchError((error) => of(new CreateProjectStatusLogFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  createLogSuccess$ = this.actions$.pipe(
    ofType(ProjectsActionType.CREATE_PROJECT_STATUS_LOG_SUCCESS),
    tap((action: CreateProjectStatusLogSuccess) => {
      this.notificationService.success(
        this.translationService.translate('PROJECTS_MANAGEMENT.PROJECTS.PROJECT_STATUS_LOG_ADDED')
      );
    })
  );

  @Effect({ dispatch: false })
  createLogFail$ = this.actions$.pipe(
    ofType(ProjectsActionType.CREATE_PROJECT_STATUS_LOG_FAIL),
    tap((action: CreateProjectStatusLogFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );

  /* ========================= UPDATE_PROJECT =================================== */
  @Effect()
  update$ = this.actions$.pipe(
    ofType(ProjectsActionType.UPDATE_PROJECT),
    switchMap((action: UpdateProject) =>
      this.ProjectsService.update(action.payload).pipe(
        map((response) => new UpdateProjectSuccess(response)),
        catchError((error) => of(new UpdateProjectFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  updateSuccess$ = this.actions$.pipe(
    ofType(ProjectsActionType.UPDATE_PROJECT_SUCCESS),
    tap((action: UpdateProjectSuccess) => {
      this.notificationService.success(
        this.translationService.translate('PROJECTS_MANAGEMENT.PROJECTS.PROJECT_UPDATED')
      );
    })
  );

  @Effect({ dispatch: false })
  updateFail$ = this.actions$.pipe(
    ofType(ProjectsActionType.UPDATE_PROJECT_FAIL),
    tap((action: UpdateProjectFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate('PROJECTS_MANAGEMENT.PROJECTS.PROJECT_NOT_FOUND')
        );
      } else if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );

  /* ========================= UPDATE_PROJECT_MAIN_TASK=================================== */
  @Effect()
  updateProjectMainTask$ = this.actions$.pipe(
    ofType(ProjectsActionType.UPDATE_PROJECT_MAIN_TASK),
    switchMap((action: UpdateProjectMainTask) =>
      this.ProjectsService.updateProjectMainTask(action.payload).pipe(
        map((response) => new UpdateProjectMainTaskSuccess(response)),
        catchError((error) => of(new UpdateProjectMainTaskFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  updateProjectMainTaskSuccess$ = this.actions$.pipe(
    ofType(ProjectsActionType.UPDATE_PROJECT_MAIN_TASK_SUCCESS),
    tap((action: UpdateProjectMainTaskSuccess) => {
      this.notificationService.success(
        this.translationService.translate('PROJECTS_MANAGEMENT.PROJECTS.PROJECT_MAIN_TASK_UPDATED')
      );
    })
  );

  @Effect({ dispatch: false })
  updateProjectMainTaskFail$ = this.actions$.pipe(
    ofType(ProjectsActionType.UPDATE_PROJECT_MAIN_TASK_FAIL),
    tap((action: UpdateProjectMainTaskFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate('PROJECTS_MANAGEMENT.PROJECTS.PROJECT_MAIN_TASK_NOT_FOUND')
        );
      } else if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );

  /* ========================= UPDATE_PROJECT_SUB_TASK=================================== */
  @Effect()
  updateProjectSubTask$ = this.actions$.pipe(
    ofType(ProjectsActionType.UPDATE_PROJECT_SUB_TASK),
    switchMap((action: UpdateProjectSubTask) =>
      this.ProjectsService.updateProjectSubTask(action.payload).pipe(
        map((response) => new UpdateProjectSubTaskSuccess(response)),
        catchError((error) => of(new UpdateProjectSubTaskFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  updateProjectSubTaskSuccess$ = this.actions$.pipe(
    ofType(ProjectsActionType.UPDATE_PROJECT_SUB_TASK_SUCCESS),
    tap((action: UpdateProjectSubTaskSuccess) => {
      this.notificationService.success(
        this.translationService.translate('PROJECTS_MANAGEMENT.PROJECTS.PROJECT_SUB_TASK_UPDATED')
      );
    })
  );

  @Effect({ dispatch: false })
  updateProjectSubTaskFail$ = this.actions$.pipe(
    ofType(ProjectsActionType.UPDATE_PROJECT_SUB_TASK_FAIL),
    tap((action: UpdateProjectSubTaskFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate('PROJECTS_MANAGEMENT.PROJECTS.PROJECT_SUB_TASK_NOT_FOUND')
        );
      } else if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );

  /* ========================= DELETE_PROJECT =================================== */
  @Effect()
  delete$ = this.actions$.pipe(
    ofType(ProjectsActionType.DELETE_PROJECT),
    switchMap((action: DeleteProject) =>
      this.ProjectsService.delete(action.payload).pipe(
        map((response) => new DeleteProjectSuccess(response)),
        catchError((error) => of(new DeleteProjectFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  deleteSuccess$ = this.actions$.pipe(
    ofType(ProjectsActionType.DELETE_PROJECT_SUCCESS),
    tap((action: DeleteProjectSuccess) => {
      this.notificationService.success(
        this.translationService.translate('PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DELETED')
      );
    })
  );

  @Effect({ dispatch: false })
  deleteFail$ = this.actions$.pipe(
    ofType(ProjectsActionType.DELETE_PROJECT_FAIL),
    tap((action: DeleteProjectFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate('PROJECTS_MANAGEMENT.PROJECTS.PROJECT_NOT_FOUND')
        );
      } else if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );

  /* ========================= DELETE_PROJECT_MAIN_TASK =================================== */
  @Effect()
  deleteProjectMainTask$ = this.actions$.pipe(
    ofType(ProjectsActionType.DELETE_PROJECT_MAIN_TASK),
    switchMap((action: DeleteProjectMainTask) =>
      this.ProjectsService.deleteProjectMainTask(action.payload).pipe(
        map((response) => new DeleteProjectMainTaskSuccess(response)),
        catchError((error) => of(new DeleteProjectMainTaskFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  deleteProjectMainTaskSuccess$ = this.actions$.pipe(
    ofType(ProjectsActionType.DELETE_PROJECT_MAIN_TASK_SUCCESS),
    tap((action: DeleteProjectMainTaskSuccess) => {
      this.notificationService.success(
        this.translationService.translate('PROJECTS_MANAGEMENT.PROJECTS.PROJECT_MAIN_TASK_DELETED')
      );
    })
  );

  @Effect({ dispatch: false })
  deleteProjectMainTaskFail$ = this.actions$.pipe(
    ofType(ProjectsActionType.DELETE_PROJECT_MAIN_TASK_FAIL),
    tap((action: DeleteProjectMainTaskFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate('PROJECTS_MANAGEMENT.PROJECTS.PROJECT_MAIN_TASK_NOT_FOUND')
        );
      } else if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );

  /* ========================= DELETE_PROJECT_SUB_TASK =================================== */
  @Effect()
  deleteProjectSubTask$ = this.actions$.pipe(
    ofType(ProjectsActionType.DELETE_PROJECT_SUB_TASK),
    switchMap((action: DeleteProjectSubTask) =>
      this.ProjectsService.deleteProjectSubTask(action.payload).pipe(
        map((response) => new DeleteProjectSubTaskSuccess(response)),
        catchError((error) => of(new DeleteProjectSubTaskFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  deleteProjectSubTaskSuccess$ = this.actions$.pipe(
    ofType(ProjectsActionType.DELETE_PROJECT_SUB_TASK_SUCCESS),
    tap((action: DeleteProjectSubTaskSuccess) => {
      this.notificationService.success(
        this.translationService.translate('PROJECTS_MANAGEMENT.PROJECTS.PROJECT_SUB_TASK_DELETED')
      );
    })
  );

  @Effect({ dispatch: false })
  deleteProjectSubTaskFail$ = this.actions$.pipe(
    ofType(ProjectsActionType.DELETE_PROJECT_SUB_TASK_FAIL),
    tap((action: DeleteProjectSubTaskFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate('PROJECTS_MANAGEMENT.PROJECTS.PROJECT_SUB_TASK_NOT_FOUND')
        );
      } else if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );

  /* ========================= CREATE_PROJECT_PLANNING_RESOURCE =================================== */
  @Effect()
  createProjectPlanningResource$ = this.actions$.pipe(
    ofType(ProjectsActionType.CREATE_PROJECT_PLANNING_RESOURCE),
    switchMap((action: CreateProjectPlanningResource) =>
      this.ProjectsService.createProjectPlanningResource(action.payload).pipe(
        map((response) => new CreateProjectPlanningResourceSuccess(response)),
        catchError((error) => of(new CreateProjectPlanningResourceFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  createProjectPlanningResourceSuccess$ = this.actions$.pipe(
    ofType(ProjectsActionType.CREATE_PROJECT_PLANNING_RESOURCE_SUCCESS),
    tap((action: CreateProjectPlanningResourceSuccess) => {
      this.notificationService.success(
        this.translationService.translate('PROJECTS_MANAGEMENT.PROJECTS.PROJECT_RESOURCE_ADDED')
      );
    })
  );

  @Effect({ dispatch: false })
  createProjectPlanningResourceFail$ = this.actions$.pipe(
    ofType(ProjectsActionType.CREATE_PROJECT_PLANNING_RESOURCE_FAIL),
    tap((action: CreateProjectPlanningResourceFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );

  /* ========================= DELETE_PROJECT_PLANNING_RESOURCE =================================== */
  @Effect()
  deleteProjectPlanningResource$ = this.actions$.pipe(
    ofType(ProjectsActionType.DELETE_PROJECT_PLANNING_RESOURCE),
    switchMap((action: DeleteProjectPlanningResource) =>
      this.ProjectsService.deleteProjectPlanningResource(action.payload).pipe(
        map((response) => new DeleteProjectPlanningResourceSuccess(response)),
        catchError((error) => of(new DeleteProjectPlanningResourceFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  deleteProjectPlanningResourceSuccess$ = this.actions$.pipe(
    ofType(ProjectsActionType.DELETE_PROJECT_PLANNING_RESOURCE_SUCCESS),
    tap((action: DeleteProjectPlanningResourceSuccess) => {
      this.notificationService.success(
        this.translationService.translate('PROJECTS_MANAGEMENT.PROJECTS.PROJECT_RESOURCE_DELETED')
      );
    })
  );

  @Effect({ dispatch: false })
  deleteProjectPlanningResourceFail$ = this.actions$.pipe(
    ofType(ProjectsActionType.DELETE_PROJECT_PLANNING_RESOURCE_FAIL),
    tap((action: DeleteProjectPlanningResourceFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate('PROJECTS_MANAGEMENT.PROJECTS.PROJECT_RESOURCE_NOT_FOUND')
        );
      } else if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );
}
