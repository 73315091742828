import { Component, Input } from '@angular/core';
import { FormArray, FormGroup, FormControl, Validators } from '@angular/forms';

import { CustomValidators, NotificationService, TranslationService } from 'shared';
import { ProductIngredientsFormItem, Product, IngredientProducts } from 'stores/models';

@Component({
  selector: '[app-product-ingredients-form]',
  templateUrl: './product-ingredients-form.component.html',
  styles: [],
})
export class ProductIngredientsFormComponent {
  /**
   * Sets the product ingredients form-array.
   */
  @Input() formArray: FormArray;

  /**
   * Sets the name form-array in its parent form.
   * @default 'ingredients'
   */
  @Input() formArrayName = 'ingredients';

  /**
   * Sets the initial productIngredients.
   */
  @Input() set initialProductIngredients(ingredientProducts: IngredientProducts[]) {
    if (!ingredientProducts?.length) {
      return;
    }

    ingredientProducts.forEach((productIngredient) => {
      this.addOldProductIngredient(productIngredient);
    });
  }

  /**
   * Shows or hide the product list.
   */
  ProductsListVisibility = false;

  constructor(private notificationService: NotificationService, private translationService: TranslationService) {}

  /**
   * Adds the newly selected locations the products search list.
   * @param products The list of newly selected products to be added.
   */
  selectProduct([product]: Product[]) {
    if (product) {
      if ((this.formArray.value as ProductIngredientsFormItem[]).some((item) => item.product.id === product.id)) {
        return this.notificationService.warning(
          this.translationService.translate('STORES.PRODUCTS.NOTIFICATION_MESSAGE.PRODUCT_ALREADY_EXIST')
        );
      }

      this.addIngredient({ ingredientId: product.id, product, quantity: 0 });
    }
  }

  /**
   * Creates & adds a new product ingredient form-group with validations.
   * @param ingredientProduct The product ingredient details to be
   *  bounded to the product ingredient, If omitted a blank product ingredient will be created.
   */
  addIngredient(ingredientProduct: ProductIngredientsFormItem) {
    const ingredientProductFormGroup = new FormGroup({
      ingredientId: new FormControl(ingredientProduct.product.id),
      product: new FormControl(ingredientProduct.product),
      quantity: new FormControl(ingredientProduct.quantity, [Validators.required, CustomValidators.gt(0)]),
    });

    this.formArray.push(ingredientProductFormGroup);
  }

  /**
   * Creates & adds a new product-ingredient form-group for an existing product-ingredient with validations.
   * @param productIngredient The product-ingredient to be added to the form-array.
   */
  addOldProductIngredient(productIngredient: IngredientProducts) {
    const productIngredientFormGroup = new FormGroup({
      id: new FormControl(productIngredient.id),
      ingredientId: new FormControl(productIngredient.ingredientProductId),
      product: new FormControl(productIngredient.ingredientProduct),
      quantity: new FormControl(productIngredient.quantity, [Validators.required, CustomValidators.gt(0)]),
      isNew: new FormControl(false),
    });

    this.formArray.push(productIngredientFormGroup);
  }

  /**
   * Remove the product ingredient at the given index from the list.
   * @param index The index of the product ingredient to be removed.
   */
  removeIngredient(index: number) {
    this.formArray.removeAt(index);
  }

  /**
   * Remove all of the list of commercial registration Numbers.
   *
   * It only adds one product ingredient for quick start.
   */
  resetIngredients() {
    this.formArray.clear();
  }
}
