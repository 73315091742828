import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';

import * as fromSalesStore from 'sales/store';
import {
  APP_CONSTANTS,
  CustomValidators,
  NotificationMessage,
  NotificationService,
  PageInfo,
  PaginationInfo,
  TranslationService,
} from 'shared';
import { Shift } from 'sales/models';
import { Claims } from 'security/models';

@Component({
  selector: 'app-my-shifts-list',
  templateUrl: './my-shifts-list.component.html',
  styles: [],
})
export class MyShiftsListComponent implements OnInit {
  /**
   * The create modal template reference.
   */
  @ViewChild('createModalRef') createModalRef: ElementRef<any>;

  /**
   * The close modal template reference.
   */
  @ViewChild('closeModalRef') closeModalRef: ElementRef<any>;

  /**
   * The decimal mask.
   */
  readonly DECIMAL_MASK = APP_CONSTANTS.numeric.decimal.mask;

  /**
   * Gets or sets the information about the current page.
   */
  pageInfo: PageInfo = {
    title: 'SALES.SHIFTS.MY_SHIFTS_DATA_TITLE',
    icon: 'fa fa-calendar',
  };

  /**
   * The system supported user claims.
   */
  Claims = Claims;

  /**
   * The list of shifts.
   */
  shifts$: Observable<Shift[]>;

  /**
   * The pagination info.
   */
  paginationInfo$: Observable<PaginationInfo>;

  /**
   * Indicates whether there is a search process is running or not.
   */
  isSearching$: Observable<boolean>;

  /**
   * Indicates whether there is a create shift process is running or not.
   */
  isShiftCreating$: Observable<boolean>;

  /**
   * Indicates whether there is a close shift log process is running or not.
   */
  isCancelling$: Observable<boolean>;

  /**
   * The search form.
   */
  searchForm: FormGroup;

  /**
   * The close form.
   */
  closeForm: FormGroup;

  /**
   * Gets or sets the currently selected shift for close.
   */
  selectedShift: Shift;

  /** The initial `fromDate` value for search. */
  initialFromDate = new Date();

  /** The initial `toDate` value for search.
   * It's the current day of month.
   */
  initialToDate = new Date();

  /**
   * @param modalService The modal service.
   * @param notificationService The  notification service.
   * @param translationService The translation service.
   * @param salesStore$ The sales-store module.
   */
  constructor(
    private modalService: NgbModal,
    private notificationService: NotificationService,
    private translationService: TranslationService,
    private salesStore$: Store<fromSalesStore.SalesState>
  ) {}

  ngOnInit(): void {
    this.init();
  }

  /**
   * Initialize component data.
   */
  init() {
    this.initForm();

    /**
     * Loads data.
     */

    this.isCancelling$ = this.salesStore$.pipe(select(fromSalesStore.getMySelectedShiftCanceling));
    this.isShiftCreating$ = this.salesStore$.pipe(select(fromSalesStore.getMyShiftStarting));
    this.isSearching$ = this.salesStore$.pipe(select(fromSalesStore.getMyShiftsSearching));

    let isManualSearchTriggeredBeforeForShifts = false;
    this.shifts$ = this.salesStore$.pipe(
      select(fromSalesStore.getMyShifts),
      tap((shifts) => {
        if (!isManualSearchTriggeredBeforeForShifts && !shifts.length) {
          isManualSearchTriggeredBeforeForShifts = true;
          this.search();
        }
      })
    );
    this.paginationInfo$ = this.salesStore$.pipe(select(fromSalesStore.getMyShiftsPaginationInfo));
  }

  /**
   * Initialize form and add validators.
   */
  initForm() {
    this.searchForm = new FormGroup({
      fromDate: new FormControl({
        year: this.initialFromDate.getFullYear(),
        month: this.initialFromDate.getMonth() + 1,
        day: this.initialFromDate.getDate(),
      }),
      toDate: new FormControl({
        year: this.initialToDate.getFullYear(),
        month: this.initialToDate.getMonth() + 1,
        day: this.initialToDate.getDate(),
      }),
      page: new FormControl(1),
    });

    this.closeForm = new FormGroup({
      cashValue: new FormControl('', [Validators.required, CustomValidators.gte(0)]),
    });
  }

  /**
   * Cancels the shift with the given id.
   * @param shift The id of the shift to be closed.
   */
  close() {
    let errorMessage = new NotificationMessage();

    if (this.closeForm.invalid) {
      if (this.closeForm.get('cashValue').invalid) {
        errorMessage.title = this.translationService.translate('SALES.SHIFTS.CASH_VALUE_ERROR');
        errorMessage.body = [this.translationService.translate('SALES.SHIFTS.CASH_VALUE_IS_REQUIRED')];
      }
      this.closeForm.markAllAsTouched();
      return this.notificationService.warningWithTitle(errorMessage);
    }

    this.salesStore$.dispatch(
      new fromSalesStore.CancelMyShift({
        shiftId: this.selectedShift.id,
        cashValue: this.closeForm.value.cashValue,
      })
    );
  }

  /**
   * Shows create new shift modal.
   */
  startShift() {
    this.openModal(this.createModalRef);
  }

  /**
   * Creates a new shift.
   */
  confirmCreate() {
    this.closeModal();
    this.salesStore$.dispatch(new fromSalesStore.StartShift());
  }

  /**
   * Handles search parameters change.
   */
  search(event?: KeyboardEvent) {
    if (event && event.key === 'Enter') {
      event.preventDefault();
      return;
    }

    this.applyFiltersAndSearch();
  }

  /**
   * Handles pagination page-changed event.
   * @param page The current selected page number.
   */
  pageChanged(page: number) {
    /** Update pagination page. */
    this.searchForm.patchValue({ page });

    this.applyFiltersAndSearch(page);
  }

  applyFiltersAndSearch(page: number = 1) {
    if (!this.searchForm.get('fromDate')?.value || !this.searchForm.get('toDate')?.value) {
      return this.notificationService.warning(
        this.translationService.translate('SALES.SHIFTS.NOTIFICATION_MESSAGE.DATE_SELECT')
      );
    }

    const { fromDate, toDate } = this.searchForm.value;

    const fromDateFormatted = new Date(fromDate.year, fromDate.month - 1, fromDate.day);
    fromDateFormatted.setHours(0, 0, 0, 0);

    const toDateFormatted = new Date(toDate.year, toDate.month - 1, toDate.day);
    toDateFormatted.setHours(23, 59, 59, 0);

    this.salesStore$.dispatch(
      new fromSalesStore.SearchMyShifts({
        fromDate: fromDateFormatted,
        toDate: toDateFormatted,
        page,
      })
    );
  }

  /**
   * Start new shift.
   */

  /**
   * Enables trackBy feature for *ngFor utility to track items
   * depending on a comparer to reduce DOM change.
   * If the item is already exist then no HTML will change, only bounded values.
   * @param index The index of the item.
   * @param shift The item to determine if it was changed or not.
   */
  trackItems(index: number, shift: Shift) {
    return shift ? shift.id : undefined;
  }

  /**
   * Opens the modal of the given templateRef.
   * @param modalRef The modal templateRef to be opened.
   */
  openModal(modalRef) {
    this.modalService.open(modalRef);
  }

  /**
   * Closes the currently opened modal.
   */
  closeModal() {
    this.modalService.dismissAll();
  }
}
