<!--Footer Starts-->
<footer>
  <div class="container-fluid">
    <p class="copyright text-center">
      Copyright &copy; {{ currentYear }}
      <a [href]="softwareSettings.organization.siteUrl" target="_blank">{{ softwareSettings.organization.name }}</a
      >, All rights reserved.
    </p>
  </div>
</footer>
<!--Footer Ends-->
