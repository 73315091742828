import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { UiSwitchModule } from 'ngx-ui-switch';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';

import * as fromComponents from 'shared/components';
import * as fromServices from 'shared/services';
import * as fromPipes from 'shared/pipes';
import * as fromDirectives from 'shared/directives';
export const NgxMaskOptions: Partial<IConfig> | (() => Partial<IConfig>) = null;

/**
 * Contains shared services, components, directives and pipes.
 * This module should imported in the core-module using `.forRoot()` static method.
 * To import this module in a lazy loaded module, just import the module without `.forRoot()`.
 */
@NgModule({
  declarations: [...fromComponents.COMPONENTS, ...fromPipes.PIPES, ...fromDirectives.DIRECTIVES],
  imports: [
    CommonModule,
    NgbModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMaskModule.forRoot(NgxMaskOptions),
    TranslateModule,
  ],
  exports: [
    ...fromComponents.COMPONENTS,
    ...fromPipes.PIPES,
    ...fromDirectives.DIRECTIVES,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    NgSelectModule,
    UiSwitchModule,
    NgxMaskModule,
    TranslateModule,
  ],
})
export class SharedModule {
  /**
   * Register all of services belong to shared module into the module that calls this `forRoot()` method.
   */
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: SharedModule,
      providers: [...fromServices.SERVICES],
    };
  }
}
