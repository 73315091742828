import { createSelector } from '@ngrx/store';

import { selectLookupsState } from 'lookups/store/selectors/feature.selectors';
import { LookupsState, OtherEarningTypeState } from 'lookups/store/states';

/**
 * Gets the other earning type state.
 */
const selectOtherEarningTypes = createSelector(selectLookupsState, (state: LookupsState) => state.otherEarningTypes);

/**
 * Gets the list of loaded other earning types.
 */
export const getOtherEarningTypes = createSelector(
  selectOtherEarningTypes,
  (state: OtherEarningTypeState) => state.data
);

/**
 * Gets the error that occurred in the state.
 */
export const getOtherEarningTypesError = createSelector(
  selectOtherEarningTypes,
  (state: OtherEarningTypeState) => state.error
);

/**
 * Determines if there is a running load process.
 */
export const getOtherEarningTypesIsLoading = createSelector(
  selectOtherEarningTypes,
  (state: OtherEarningTypeState) => state.isLoading
);

/**
 * Determines if the last load process has been ended successfully.
 */
export const getOtherEarningTypesIsLoaded = createSelector(
  selectOtherEarningTypes,
  (state: OtherEarningTypeState) => state.isLoaded
);
