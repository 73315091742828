<!-- page header starts -->
<app-page-header [info]="pageInfo"></app-page-header>
<!-- page header ends -->

<!-- create petty cash form starts -->
<div class="row">
  <div class="col-md-12">
    <form [formGroup]="form" (ngSubmit)="submit()">
      <div class="card">
        <div class="card-header mb-3">
          <h4 class="card-title">{{ 'FINANCES.PETTY_CASH.PETTY_CASH_DATA_TITLE' | translate }}</h4>
        </div>
        <div class="card-content">
          <div class="px-3">
            <div class="row">
              <div class="form-group col-md-6">
                <label for="description">{{
                  'FINANCES.PETTY_CASH.PETTY_CASH_DATA.PETTY_CASH_DESCRIPTION' | translate
                }}</label>
                <input
                  type="text"
                  id="description"
                  class="form-control round"
                  [placeholder]="
                    'FINANCES.PETTY_CASH.PETTY_CASH_DATA.PETTY_CASH_DESCRIPTION_INPUT_PLACEHOLDER' | translate
                  "
                  maxlength="50"
                  formControlName="description"
                />
              </div>
              <div class="form-group col-md-6">
                <label for="pettyCashTypeId">{{
                  'FINANCES.PETTY_CASH.PETTY_CASH_DATA.PETTY_CASH_TYPE' | translate
                }}</label>
                <ng-select
                  class="round"
                  formControlName="pettyCashTypeId"
                  labelForId="pettyCashTypeId"
                  searchable="true"
                  [placeholder]="'FINANCES.PETTY_CASH.PETTY_CASH_DATA.PETTY_CASH_TYPE_PLACEHOLDER' | translate"
                  [notFoundText]="'SHARED.SEARCH.SEARCH_NOT_FOUND' | translate"
                >
                  <ng-option *ngFor="let pettyCashType of pettyCashTypes$ | async" [value]="pettyCashType.id">
                    {{ pettyCashType.name | localize: pettyCashType.nameEn | async }}
                  </ng-option>
                </ng-select>
              </div>
              <div class="form-group col-md-6">
                <label for="maxAllowedPaymentValue">{{
                  'FINANCES.PETTY_CASH.PETTY_CASH_DATA.MAX_PAYMENT_VALUE' | translate
                }}</label>
                <input
                  type="text"
                  [mask]="DECIMAL_MASK"
                  thousandSeparator=","
                  minLength="0"
                  id="maxAllowedPaymentValue"
                  class="form-control round"
                  [placeholder]="'FINANCES.PETTY_CASH.PETTY_CASH_DATA.MAX_PAYMENT_VALUE_PLACEHOLDER' | translate"
                  formControlName="maxAllowedPaymentValue"
                />
              </div>
              <div class="form-group col-md-6">
                <label for="maxAllowedRefundValue">{{
                  'FINANCES.PETTY_CASH.PETTY_CASH_DATA.MAX_REFUND_VALUE' | translate
                }}</label>
                <input
                  type="text"
                  [mask]="DECIMAL_MASK"
                  thousandSeparator=","
                  minLength="0"
                  id="maxAllowedRefundValue"
                  class="form-control round"
                  [placeholder]="'FINANCES.PETTY_CASH.PETTY_CASH_DATA.MAX_REFUND_VALUE_PLACEHOLDER' | translate"
                  formControlName="maxAllowedRefundValue"
                />
              </div>
              <div class="form-group col-md-12">
                <label for="pettyCashSpecificationId">{{
                  'FINANCES.PETTY_CASH.PETTY_CASH_DATA.PETTY_CASH_SPECIFICATION' | translate
                }}</label>
                <ng-select
                  class="round"
                  formControlName="pettyCashSpecificationId"
                  labelForId="pettyCashSpecificationId"
                  searchable="true"
                  [placeholder]="'FINANCES.PETTY_CASH.PETTY_CASH_DATA.PETTY_CASH_SPECIFICATION_PLACEHOLDER' | translate"
                  [notFoundText]="'SHARED.SEARCH.SEARCH_NOT_FOUND' | translate"
                >
                  <ng-option
                    *ngFor="let pettyCashSpecification of pettyCashSpecifications$ | async"
                    [value]="pettyCashSpecification.id"
                  >
                    {{ pettyCashSpecification.name | localize: pettyCashSpecification.nameEn | async }}
                  </ng-option>
                </ng-select>
              </div>

              <div class="form-group col-12 d-flex justify-content-between mt-2">
                <button type="submit" class="btn btn-raised btn-success round" [disabled]="isCreating$ | async">
                  <i class="fa fa-check"></i>
                  <span class="mx-1">{{ 'SHARED.CONFIRM.SAVE' | translate }}</span>
                </button>
                <a class="btn btn-dark round" routerLink="/finances/petty-cash">
                  <i class="fa fa-undo"></i>
                  <span class="mx-1">{{ 'FINANCES.PETTY_CASH.PETTY_CASH_DATA.PETTY_CASH_RETURN' | translate }}</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
<!-- create petty cash form ends -->

<!-- confirm modal starts -->
<ng-template #confirmModalRef let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
      {{ 'FINANCES.PETTY_CASH.CONFIRM_CREATE_PETTY_CASH_TITLE' | translate }}
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="d()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p class="text-success">{{ 'FINANCES.PETTY_CASH.CONFIRM_CREATE_PETTY_CASH_MESSAGE' | translate }}</p>
  </div>
  <div class="modal-footer">
    <div class="modal-actions">
      <button type="button" class="btn btn-raised btn-success round" (click)="c(); confirm()" ngbAutofocus>
        <i class="fa fa-check"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.SAVE' | translate }}</span>
      </button>
      <button type="button" class="btn btn-outline-dark round" (click)="c()">
        <i class="fa fa-times"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.CANCEL' | translate }}</span>
      </button>
    </div>
  </div>
</ng-template>
<!-- confirm modal ends -->
