import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';

import * as fromAuthStore from 'auth/store';
import * as fromCoreStore from 'core/store';
import { Claim } from 'security';

/**
 * Authorization guard that makes sure that the user has the permission to access
 * a specified route according to some claims.
 */
@Injectable()
export class AuthorizeGuard implements CanActivate {
  /**
   * @param authStore$ The auth-Module store.
   * @param coreStore$ The core-Module store.
   */
  constructor(private authStore$: Store<fromAuthStore.AuthState>, private coreStore$: Store<fromCoreStore.AppState>) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    /** The set of claims passed with the route data. */
    const claims: string[] = (route.data.claims as string[]) || [];

    return this.authStore$.pipe(
      select(fromAuthStore.getSessionUserClaims),
      map((userClaims: Claim[]) => {
        const hasPermission = claims.some((claimKey) =>
          userClaims.map((userClaim) => userClaim.key).includes(claimKey)
        );

        /**
         * If user can't access this route redirect him to 403 forbidden page.
         */
        if (!hasPermission) {
          this.coreStore$.dispatch(new fromCoreStore.Go({ path: ['/forbidden'] }));
        }

        return hasPermission;
      })
    );
  }
}
