import { createSelector } from '@ngrx/store';

import { selectLookupsState } from 'lookups/store/selectors/feature.selectors';
import { LookupsState, ContractDurationState } from 'lookups/store/states';

/**
 * Gets the contract duration state.
 */
const selectContractDurations = createSelector(selectLookupsState, (state: LookupsState) => state.contractDurations);

/**
 * Gets the list of loaded contract durations.
 */
export const getContractDurations = createSelector(
  selectContractDurations,
  (state: ContractDurationState) => state.data
);

/**
 * Gets the error that occurred in the state.
 */
export const getContractDurationsError = createSelector(
  selectContractDurations,
  (state: ContractDurationState) => state.error
);

/**
 * Determines if there is a running load process.
 */
export const getContractDurationsIsLoading = createSelector(
  selectContractDurations,
  (state: ContractDurationState) => state.isLoading
);

/**
 * Determines if the last load process has been ended successfully.
 */
export const getContractDurationsIsLoaded = createSelector(
  selectContractDurations,
  (state: ContractDurationState) => state.isLoaded
);
