import { cloneDeep, findIndex, remove } from 'lodash';

import { ProjectsActionType, ProjectsActions } from 'projects-management/store/actions';
import { ProjectsState } from 'projects-management/store/states';

/**
 * The initial state from which the state starts.
 */
const initialState: ProjectsState = {
  data: [],
  projectMainTasksData: [],
  projectSubTasksData: [],
  projectLogsData: [],
  projectRevenuesData: [],
  projectResourcesData: [],
  projectStatusLogsData: [],
  projectMainTasksPaginationInfo: {
    total: 0,
    page: 1,
    pageSize: 0,
    count: 0,
  },
  projectSubTasksPaginationInfo: {
    total: 0,
    page: 1,
    pageSize: 0,
    count: 0,
  },
  paginationInfo: {
    total: 0,
    page: 1,
    pageSize: 0,
    count: 0,
  },
  projectLogsPaginationInfo: {
    total: 0,
    page: 1,
    pageSize: 0,
    count: 0,
  },
  projectRevenuesPaginationInfo: {
    total: 0,
    page: 1,
    pageSize: 0,
    count: 0,
  },
  projectStatusLogsPaginationInfo: {
    total: 0,
    page: 1,
    pageSize: 0,
    count: 0,
  },
  projectResourcesPaginationInfo: {
    total: 0,
    page: 1,
    pageSize: 0,
    count: 0,
  },
  error: null,
  isSearching: false,
  isSearchCompleted: false,
  isProjectMainTasksSearching: false,
  isProjectMainTasksSearchCompleted: false,
  isProjectSubTasksSearching: false,
  isProjectSubTasksSearchCompleted: false,
  isProjectLogsSearching: false,
  isProjectLogsSearchCompleted: false,
  isProjectRevenuesSearching: false,
  isProjectRevenuesSearchCompleted: false,
  isProjectResourcesSearching: false,
  isProjectResourcesSearchCompleted: false,
  isSearchingProjectStatusLogs: false,
  isSearchProjectStatusLogsCompleted: false,
  selectedProject: null,
  selectedProjectResource: null,
  selectedProjectRevenue: null,
  selectedProjectPlanningResource: null,
  selectedProjectStatusLog: null,
  isFinding: false,
  isFindCompleted: false,
  isProjectResourceFinding: false,
  isProjectResourceFindCompleted: false,
  isProjectRevenueFinding: false,
  isProjectRevenueFindCompleted: false,
  isCreating: false,
  isCreateCompleted: false,
  isProjectPlanningResourceCreating: false,
  isProjectPlanningResourceCreateCompleted: false,
  isProjectMainTaskCreating: false,
  isProjectMainTaskCreateCompleted: false,
  isProjectSubTaskCreating: false,
  isProjectSubTaskCreateCompleted: false,
  isProjectPlanningResourceDeleting: false,
  isProjectPlanningResourceDeleteCompleted: false,
  isProjectStatusLogCreating: false,
  isProjectStatusLogCreateCompleted: false,
  isUpdating: false,
  isUpdateCompleted: false,
  isProjectMainTaskUpdating: false,
  isProjectMainTaskUpdateCompleted: false,
  isProjectSubTaskUpdating: false,
  isProjectSubTaskUpdateCompleted: false,
  isDeleting: false,
  isDeleteCompleted: false,
  isProjectMainTaskDeleting: false,
  isProjectMainTaskDeleteCompleted: false,
  isProjectSubTaskDeleting: false,
  isProjectSubTaskDeleteCompleted: false,
};

/**
 * The reducer function that is called in each action dispatch against the state.
 * @param state The current state.
 * @param action The action that will affect the state.
 */
export function projectsReducer(state: ProjectsState = initialState, action: ProjectsActions): ProjectsState {
  switch (action.type) {
    //#region SEARCH_PROJECTS

    case ProjectsActionType.SEARCH_PROJECTS: {
      return {
        ...state,
        isSearching: true,
        isSearchCompleted: false,
        error: null,
      };
    }

    case ProjectsActionType.SEARCH_PROJECTS_FAIL: {
      return {
        ...state,
        error: action.payload,
        isSearching: false,
        isSearchCompleted: false,
      };
    }

    case ProjectsActionType.SEARCH_PROJECTS_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        paginationInfo: {
          ...action.payload.meta,
        },
        error: null,
        isSearching: false,
        isSearchCompleted: true,
      };
    }

    // #end region SEARCH_PROJECTS

    //#region SEARCH_MAIN_TASKS

    case ProjectsActionType.SEARCH_MAIN_TASKS: {
      return {
        ...state,
        isProjectMainTasksSearching: true,
        isProjectMainTasksSearchCompleted: false,
        error: null,
      };
    }

    case ProjectsActionType.SEARCH_MAIN_TASKS_FAIL: {
      return {
        ...state,
        error: action.payload,
        isProjectMainTasksSearching: false,
        isProjectMainTasksSearchCompleted: false,
      };
    }

    case ProjectsActionType.SEARCH_MAIN_TASKS_SUCCESS: {
      return {
        ...state,
        projectMainTasksData: action.payload.data,
        projectMainTasksPaginationInfo: {
          ...action.payload.meta,
        },
        error: null,
        isProjectMainTasksSearching: false,
        isProjectMainTasksSearchCompleted: true,
      };
    }

    // #end region SEARCH_MAIN_TASKS

    //#region SEARCH_SUB_TASKS

    case ProjectsActionType.SEARCH_SUB_TASKS: {
      return {
        ...state,
        isProjectSubTasksSearching: true,
        isProjectSubTasksSearchCompleted: false,
        error: null,
      };
    }

    case ProjectsActionType.SEARCH_SUB_TASKS_FAIL: {
      return {
        ...state,
        error: action.payload,
        isProjectSubTasksSearching: false,
        isProjectSubTasksSearchCompleted: false,
      };
    }

    case ProjectsActionType.SEARCH_SUB_TASKS_SUCCESS: {
      return {
        ...state,
        projectSubTasksData: action.payload.data,
        projectSubTasksPaginationInfo: {
          ...action.payload.meta,
        },
        error: null,
        isProjectSubTasksSearching: false,
        isProjectSubTasksSearchCompleted: true,
      };
    }

    // #end region SEARCH_SUB_TASKS

    //#region SEARCH_PROJECT_LOGS

    case ProjectsActionType.SEARCH_PROJECT_LOGS: {
      return {
        ...state,
        isProjectLogsSearching: true,
        isProjectLogsSearchCompleted: false,
        error: null,
      };
    }

    case ProjectsActionType.SEARCH_PROJECT_LOGS_FAIL: {
      return {
        ...state,
        error: action.payload,
        isProjectLogsSearching: false,
        isProjectLogsSearchCompleted: false,
      };
    }

    case ProjectsActionType.SEARCH_PROJECT_LOGS_SUCCESS: {
      return {
        ...state,
        projectLogsData: action.payload.data,
        projectLogsPaginationInfo: {
          ...action.payload.meta,
        },
        error: null,
        isProjectLogsSearching: false,
        isProjectLogsSearchCompleted: true,
      };
    }

    // #end region SEARCH_PROJECT_LOGS

    //#region SEARCH_PROJECT_REVENUES

    case ProjectsActionType.SEARCH_PROJECT_REVENUES: {
      return {
        ...state,
        isProjectRevenuesSearching: true,
        isProjectRevenuesSearchCompleted: false,
        error: null,
      };
    }

    case ProjectsActionType.SEARCH_PROJECT_REVENUES_FAIL: {
      return {
        ...state,
        error: action.payload,
        isProjectRevenuesSearching: false,
        isProjectRevenuesSearchCompleted: false,
      };
    }

    case ProjectsActionType.SEARCH_PROJECT_REVENUES_SUCCESS: {
      return {
        ...state,
        projectRevenuesData: action.payload.data,
        projectRevenuesPaginationInfo: {
          ...action.payload.meta,
        },
        error: null,
        isProjectRevenuesSearching: false,
        isProjectRevenuesSearchCompleted: true,
      };
    }

    // #end region SEARCH_PROJECT_REVENUES

    //#region SEARCH_PROJECT_STATUS_LOGS

    case ProjectsActionType.SEARCH_PROJECT_STATUS_LOGS: {
      return {
        ...state,
        isSearchingProjectStatusLogs: true,
        isSearchProjectStatusLogsCompleted: false,
        error: null,
      };
    }

    case ProjectsActionType.SEARCH_PROJECT_STATUS_LOGS_FAIL: {
      return {
        ...state,
        error: action.payload,
        isSearchingProjectStatusLogs: false,
        isSearchProjectStatusLogsCompleted: false,
      };
    }

    case ProjectsActionType.SEARCH_PROJECT_STATUS_LOGS_SUCCESS: {
      return {
        ...state,
        projectStatusLogsData: action.payload.data,
        projectStatusLogsPaginationInfo: {
          ...action.payload.meta,
        },
        error: null,
        isSearchingProjectStatusLogs: false,
        isSearchProjectStatusLogsCompleted: true,
      };
    }

    //#endregion SEARCH_PROJECT_STATUS_LOGS

    //#region SEARCH_PROJECT_RESOURCES

    case ProjectsActionType.SEARCH_PROJECT_RESOURCES: {
      return {
        ...state,
        isProjectResourcesSearching: true,
        isProjectResourcesSearchCompleted: false,
        error: null,
      };
    }

    case ProjectsActionType.SEARCH_PROJECT_RESOURCES_FAIL: {
      return {
        ...state,
        error: action.payload,
        isProjectResourcesSearching: false,
        isProjectResourcesSearchCompleted: false,
      };
    }

    case ProjectsActionType.SEARCH_PROJECT_RESOURCES_SUCCESS: {
      return {
        ...state,
        projectResourcesData: action.payload.data,
        projectResourcesPaginationInfo: {
          ...action.payload.meta,
        },
        error: null,
        isProjectResourcesSearching: false,
        isProjectResourcesSearchCompleted: true,
      };
    }

    // #end region SEARCH_PROJECT_RESOURCES

    //#region FIND_PROJECT

    case ProjectsActionType.FIND_PROJECT: {
      return {
        ...state,
        selectedProject: null,
        error: null,
        isFinding: true,
        isFindCompleted: false,
      };
    }

    case ProjectsActionType.FIND_PROJECT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isFinding: false,
        isFindCompleted: false,
      };
    }

    case ProjectsActionType.FIND_PROJECT_SUCCESS: {
      let projects = [...state.data];
      const projectIndex = findIndex(projects, (project) => project.id === action.payload.data.id);

      /* If project was found, update it. */
      if (projectIndex >= 0) {
        projects[projectIndex] = action.payload.data;
      } else {
        /* else, insert it to the beginning of the array. */
        projects = [action.payload.data, ...projects];
      }

      return {
        ...state,
        data: projects,
        selectedProject: action.payload.data,
        error: null,
        isFinding: false,
        isFindCompleted: true,
      };
    }

    //#endregion FIND_PROJECT

    //#region FIND_PROJECT_RESOURCE

    case ProjectsActionType.FIND_PROJECT_RESOURCE: {
      return {
        ...state,
        selectedProjectResource: null,
        error: null,
        isFinding: true,
        isFindCompleted: false,
      };
    }

    case ProjectsActionType.FIND_PROJECT_RESOURCE_FAIL: {
      return {
        ...state,
        error: action.payload,
        isFinding: false,
        isFindCompleted: false,
      };
    }

    case ProjectsActionType.FIND_PROJECT_RESOURCE_SUCCESS: {
      let resources = [...state.projectResourcesData];
      const resourceIndex = findIndex(resources, (resource) => resource.id === action.payload.data.id);

      /* If project resource was found, update it. */
      if (resourceIndex >= 0) {
        resources[resourceIndex] = action.payload.data;
      } else {
        /* else, insert it to the beginning of the array. */
        resources = [action.payload.data, ...resources];
      }

      return {
        ...state,
        projectResourcesData: resources,
        selectedProjectResource: action.payload.data,
        error: null,
        isFinding: false,
        isFindCompleted: true,
      };
    }

    //#endregion FIND_PROJECT_RESOURCE

    //#region FIND_PROJECT_REVENUE

    case ProjectsActionType.FIND_PROJECT_REVENUE: {
      return {
        ...state,
        selectedProjectRevenue: null,
        error: null,
        isProjectRevenueFinding: true,
        isProjectRevenueFindCompleted: false,
      };
    }

    case ProjectsActionType.FIND_PROJECT_REVENUE_FAIL: {
      return {
        ...state,
        error: action.payload,
        isProjectRevenueFinding: false,
        isProjectRevenueFindCompleted: false,
      };
    }

    case ProjectsActionType.FIND_PROJECT_REVENUE_SUCCESS: {
      let projectRevenues = [...state.projectRevenuesData];
      const projectRevenueIndex = findIndex(projectRevenues, (revenue) => revenue.id === action.payload.data.id);

      /* If project revenue was found, update it. */
      if (projectRevenueIndex >= 0) {
        projectRevenues[projectRevenueIndex] = action.payload.data;
      } else {
        /* else, insert it to the beginning of the array. */
        projectRevenues = [action.payload.data, ...projectRevenues];
      }

      return {
        ...state,
        projectRevenuesData: projectRevenues,
        selectedProjectRevenue: action.payload.data,
        error: null,
        isProjectRevenueFinding: false,
        isProjectRevenueFindCompleted: true,
      };
    }

    //#endregion FIND_PROJECT_REVENUE

    //#region FIND_PROJECT_RESOURCE

    case ProjectsActionType.FIND_PROJECT_RESOURCE: {
      return {
        ...state,
        selectedProjectResource: null,
        error: null,
        isProjectResourceFinding: true,
        isProjectResourceFindCompleted: false,
      };
    }

    case ProjectsActionType.FIND_PROJECT_RESOURCE_FAIL: {
      return {
        ...state,
        error: action.payload,
        isProjectResourceFinding: false,
        isProjectResourceFindCompleted: false,
      };
    }

    case ProjectsActionType.FIND_PROJECT_RESOURCE_SUCCESS: {
      let projectResources = [...state.projectResourcesData];
      const projectResourceIndex = findIndex(projectResources, (resource) => resource.id === action.payload.data.id);

      /* If project resource was found, update it. */
      if (projectResourceIndex >= 0) {
        projectResources[projectResourceIndex] = action.payload.data;
      } else {
        /* else, insert it to the beginning of the array. */
        projectResources = [action.payload.data, ...projectResources];
      }

      return {
        ...state,
        projectResourcesData: projectResources,
        selectedProjectResource: action.payload.data,
        error: null,
        isProjectResourceFinding: false,
        isProjectResourceFindCompleted: true,
      };
    }

    //#endregion FIND_PROJECT_RESOURCE

    //#region CREATE_PROJECT

    case ProjectsActionType.CREATE_PROJECT: {
      return {
        ...state,
        isCreating: true,
        isCreateCompleted: false,
        error: null,
      };
    }

    case ProjectsActionType.CREATE_PROJECT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isCreating: false,
        isCreateCompleted: false,
      };
    }

    case ProjectsActionType.CREATE_PROJECT_SUCCESS: {
      return {
        ...state,
        data: [action.payload.data, ...state.data],
        selectedProject: action.payload.data,
        error: null,
        isCreating: false,
        isCreateCompleted: true,
      };
    }

    // #end region CREATE_PROJECT

    //#region CREATE_PROJECT_MAIN_TASK

    case ProjectsActionType.CREATE_PROJECT_MAIN_TASK: {
      return {
        ...state,
        isProjectMainTaskCreating: true,
        isProjectMainTaskCreateCompleted: false,
        error: null,
      };
    }

    case ProjectsActionType.CREATE_PROJECT_MAIN_TASK_FAIL: {
      return {
        ...state,
        error: action.payload,
        isProjectMainTaskCreating: false,
        isProjectMainTaskCreateCompleted: false,
      };
    }
    case ProjectsActionType.CREATE_PROJECT_MAIN_TASK_SUCCESS: {
      return {
        ...state,
        selectedProject: { ...action.payload.data.project },
        projectLogsData: [action.payload.data.projectLog, ...state.projectLogsData],
        error: null,
        isProjectMainTaskCreating: false,
        isProjectMainTaskCreateCompleted: true,
      };
    }

    // #end region CREATE_PROJECT_MAIN_TASK

    //#region CREATE_PROJECT_SUB_TASK

    case ProjectsActionType.CREATE_PROJECT_SUB_TASK: {
      return {
        ...state,
        isProjectSubTaskCreating: true,
        isProjectSubTaskCreateCompleted: false,
        error: null,
      };
    }

    case ProjectsActionType.CREATE_PROJECT_SUB_TASK_FAIL: {
      return {
        ...state,
        error: action.payload,
        isProjectSubTaskCreating: false,
        isProjectSubTaskCreateCompleted: false,
      };
    }

    case ProjectsActionType.CREATE_PROJECT_SUB_TASK_SUCCESS: {
      return {
        ...state,
        selectedProject: { ...action.payload.data.project },
        projectLogsData: [action.payload.data.projectLog, ...state.projectLogsData],
        error: null,
        isProjectSubTaskCreating: false,
        isProjectSubTaskCreateCompleted: true,
      };
    }

    // #end region CREATE_PROJECT_SUB_TASK

    //#region CREATE_PROJECT_STATUS_LOG

    case ProjectsActionType.CREATE_PROJECT_STATUS_LOG: {
      return {
        ...state,
        isProjectStatusLogCreating: true,
        isProjectStatusLogCreateCompleted: false,
        error: null,
      };
    }

    case ProjectsActionType.CREATE_PROJECT_STATUS_LOG_FAIL: {
      return {
        ...state,
        error: action.payload,
        isProjectStatusLogCreating: false,
        isProjectStatusLogCreateCompleted: false,
      };
    }

    case ProjectsActionType.CREATE_PROJECT_STATUS_LOG_SUCCESS: {
      let projectStatusLogs = [...state.projectStatusLogsData];
      const projectStatusLogIndex = findIndex(
        projectStatusLogs,
        (projectStatusLog) => projectStatusLog.id === action.payload.data.id
      );

      /* If project status was found, update it. */

      if (projectStatusLogIndex >= 0) {
        projectStatusLogs[projectStatusLogIndex] = action.payload.data;
      } else {
        /* else, insert it to the beginning of the array. */
        projectStatusLogs = [...projectStatusLogs, action.payload.data];
      }

      return {
        ...state,
        projectStatusLogsData: projectStatusLogs,
        selectedProjectStatusLog: action.payload.data,
        selectedProject: {
          ...state.selectedProject,
          projectStatusId: action.payload.data.projectStatusId,
          projectStatus: action.payload.data.projectStatus,
        },
        error: null,
        isProjectStatusLogCreating: false,
        isProjectStatusLogCreateCompleted: true,
      };
    }

    //#endregion CREATE_PROJECT_STATUS_LOG

    //#region UPDATE_PROJECT

    case ProjectsActionType.UPDATE_PROJECT: {
      return {
        ...state,
        isUpdating: true,
        isUpdateCompleted: false,
        error: null,
      };
    }

    case ProjectsActionType.UPDATE_PROJECT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isUpdating: false,
        isUpdateCompleted: false,
      };
    }

    case ProjectsActionType.UPDATE_PROJECT_SUCCESS: {
      let projects = [...state.data];
      const projectIndex = findIndex(projects, (project) => project.id === action.payload.data.id);

      /* If project was found, update it. */
      if (projectIndex >= 0) {
        projects[projectIndex] = action.payload.data;
      } else {
        /* else, insert it to the beginning of the array. */
        projects = [action.payload.data, ...projects];
      }

      return {
        ...state,
        data: projects,
        selectedProject: action.payload.data,
        error: null,
        isUpdating: false,
        isUpdateCompleted: true,
      };
    }

    //#endregion UPDATE_PROJECT

    //#region UPDATE_PROJECT_MAIN_TASK
    case ProjectsActionType.UPDATE_PROJECT_MAIN_TASK: {
      return {
        ...state,
        isProjectMainTaskUpdating: true,
        isProjectMainTaskUpdateCompleted: false,
        error: null,
      };
    }

    case ProjectsActionType.UPDATE_PROJECT_MAIN_TASK_FAIL: {
      return {
        ...state,
        error: action.payload,
        isProjectMainTaskUpdating: false,
        isProjectMainTaskUpdateCompleted: false,
      };
    }

    case ProjectsActionType.UPDATE_PROJECT_MAIN_TASK_SUCCESS: {
      return {
        ...state,
        selectedProject: { ...action.payload.data.project },
        projectLogsData: [action.payload.data.projectLog, ...state.projectLogsData],
        error: null,
        isProjectMainTaskUpdating: false,
        isProjectMainTaskUpdateCompleted: true,
      };
    }

    //#endregion UPDATE_PROJECT_MAIN_TASK

    //#region UPDATE_PROJECT_SUB_TASK
    case ProjectsActionType.UPDATE_PROJECT_SUB_TASK: {
      return {
        ...state,
        isProjectSubTaskUpdating: true,
        isProjectSubTaskUpdateCompleted: false,
        error: null,
      };
    }

    case ProjectsActionType.UPDATE_PROJECT_SUB_TASK_FAIL: {
      return {
        ...state,
        error: action.payload,
        isProjectSubTaskUpdating: false,
        isProjectSubTaskUpdateCompleted: false,
      };
    }

    case ProjectsActionType.UPDATE_PROJECT_SUB_TASK_SUCCESS: {
      return {
        ...state,
        selectedProject: { ...action.payload.data.project },
        projectLogsData: [action.payload.data.projectLog, ...state.projectLogsData],
        error: null,
        isProjectSubTaskUpdating: false,
        isProjectSubTaskUpdateCompleted: true,
      };
    }

    //#endregion UPDATE_PROJECT_SUB_TASK

    //#region DELETE_PROJECT

    case ProjectsActionType.DELETE_PROJECT: {
      return {
        ...state,
        isDeleting: true,
        isDeleteCompleted: false,
        error: null,
      };
    }

    case ProjectsActionType.DELETE_PROJECT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isDeleting: false,
        isDeleteCompleted: false,
      };
    }

    case ProjectsActionType.DELETE_PROJECT_SUCCESS: {
      const projects = [...state.data];

      /* Remove the deleted project from the array. */
      remove(projects, (Project) => Project.id === action.payload.data.id);

      return {
        ...state,
        data: projects,
        selectedProject: action.payload.data,
        error: null,
        isDeleting: false,
        isDeleteCompleted: true,
      };
    }

    //#endregion DELETE_PROJECT

    //#region DELETE_PROJECT_MAIN_TASK

    case ProjectsActionType.DELETE_PROJECT_MAIN_TASK: {
      return {
        ...state,
        isProjectMainTaskDeleting: true,
        isProjectMainTaskDeleteCompleted: false,
        error: null,
      };
    }

    case ProjectsActionType.DELETE_PROJECT_MAIN_TASK_FAIL: {
      return {
        ...state,
        error: action.payload,
        isProjectMainTaskDeleting: false,
        isProjectMainTaskDeleteCompleted: false,
      };
    }

    case ProjectsActionType.DELETE_PROJECT_MAIN_TASK_SUCCESS: {
      return {
        ...state,
        selectedProject: { ...action.payload.data.project },
        projectLogsData: [action.payload.data.projectLog, ...state.projectLogsData],
        error: null,
        isProjectMainTaskDeleting: false,
        isProjectMainTaskDeleteCompleted: true,
      };
    }

    //#endregion DELETE_PROJECT_MAIN_TASK

    //#region DELETE_PROJECT_SUB_TASK

    case ProjectsActionType.DELETE_PROJECT_SUB_TASK: {
      return {
        ...state,
        isProjectSubTaskDeleting: true,
        isProjectSubTaskDeleteCompleted: false,
        error: null,
      };
    }

    case ProjectsActionType.DELETE_PROJECT_SUB_TASK_FAIL: {
      return {
        ...state,
        error: action.payload,
        isProjectSubTaskDeleting: false,
        isProjectSubTaskDeleteCompleted: false,
      };
    }

    case ProjectsActionType.DELETE_PROJECT_SUB_TASK_SUCCESS: {
      return {
        ...state,
        selectedProject: { ...action.payload.data.project },
        projectLogsData: [action.payload.data.projectLog, ...state.projectLogsData],
        error: null,
        isProjectSubTaskDeleting: false,
        isProjectSubTaskDeleteCompleted: true,
      };
    }

    //#endregion DELETE_PROJECT_MAIN_TASK

    //#region CREATE_PROJECT_PLANNING_RESOURCE

    case ProjectsActionType.CREATE_PROJECT_PLANNING_RESOURCE: {
      return {
        ...state,
        error: null,
        isProjectPlanningResourceCreating: true,
        isProjectPlanningResourceCreateCompleted: false,
      };
    }

    case ProjectsActionType.CREATE_PROJECT_PLANNING_RESOURCE_FAIL: {
      return {
        ...state,
        error: action.payload,
        isProjectPlanningResourceCreating: false,
        isProjectPlanningResourceCreateCompleted: false,
      };
    }

    case ProjectsActionType.CREATE_PROJECT_PLANNING_RESOURCE_SUCCESS: {
      const selectedProject = cloneDeep(state.selectedProject);
      selectedProject.projectPlanningResourceTypes.push(action.payload.data);
      return {
        ...state,
        selectedProject,
        error: null,
        isProjectPlanningResourceCreating: false,
        isProjectPlanningResourceCreateCompleted: true,
      };
    }

    //#endregion CREATE_PROJECT_PLANNING_RESOURCE

    //#region DELETE_PROJECT_PLANNING_RESOURCE

    case ProjectsActionType.DELETE_PROJECT_PLANNING_RESOURCE: {
      return {
        ...state,
        error: null,
        isProjectPlanningResourceDeleting: true,
        isProjectPlanningResourceDeleteCompleted: false,
      };
    }

    case ProjectsActionType.DELETE_PROJECT_PLANNING_RESOURCE_FAIL: {
      return {
        ...state,
        error: action.payload.data,
        isProjectPlanningResourceDeleting: false,
        isProjectPlanningResourceDeleteCompleted: false,
      };
    }

    case ProjectsActionType.DELETE_PROJECT_PLANNING_RESOURCE_SUCCESS: {
      const selectedProject = cloneDeep(state.selectedProject);
      const projectPlanningResources = selectedProject.projectPlanningResourceTypes;

      /* Remove the deleted project national identifier from the array. */
      remove(
        projectPlanningResources,
        (projectPlanningResource) => projectPlanningResource.id === action.payload.data.id
      );

      return {
        ...state,
        selectedProject,
        selectedProjectPlanningResource: action.payload.data,
        error: null,
        isProjectPlanningResourceDeleting: false,
        isProjectPlanningResourceDeleteCompleted: true,
      };
    }

    //#endregion DELETE_PROJECT_PLANNING_RESOURCE

    default:
      return state;
  }
}
