import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { FormArray, FormGroup, FormControl } from '@angular/forms';

import { BankAccountFormItem, BankAccount } from 'finances/models';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-bank-accounts-form',
  templateUrl: './bank-accounts-form.component.html',
  styles: [],
})
export class BankAccountsFormComponent {
  /**
   * The confirm modal template reference.
   */
  @ViewChild('deleteModalRef') deleteModalRef: ElementRef<any>;

  /**
   * Sets the bank-accounts form-array.
   */
  @Input() formArray: FormArray;

  /**
   * Sets the name form-array in its parent form.
   * @default 'bankAccounts'
   */
  @Input() formArrayName = 'bankAccounts';

  /**
   * Adds a list of new bank account form items to the bank accounts form.
   * @param items The list of bank account form items to be added to the bank accounts form.
   */
  @Input() set newBankAccounts(items: BankAccountFormItem[]) {
    if (!items?.length) {
      return;
    }

    items.forEach((item) => this.createBankAccountFormGroup(item));
  }

  /**
   * Shows or hides the bank accounts list.
   */
  bankAccountsListVisibility = false;

  /**
   * @param modalService The modal service.
   */
  constructor(private modalService: NgbModal) {}

  /**
   * Creates & adds a new bank-account form-group with validations.
   * @param bankAccount The bank-account details to be bounded to the bank-account, If omitted a blank bank-account will be created.
   */
  addBankAccount() {
    this.bankAccountsListVisibility = true;
  }

  /**
   * Open confirm delete all bank accounts modal .
   */
  openDeleteModal() {
    this.openModal(this.deleteModalRef);
  }

  /**
   * Opens the modal of the given templateRef.
   * @param modalRef The modal templateRef to be opened.
   */
  openModal(modalRef) {
    this.modalService.open(modalRef);
  }

  /**
   * Remove the bank-account at the given index from the bank accounts form.
   * @param index The index of the bank-account form group.
   */
  removeBankAccount(index: number) {
    this.formArray.removeAt(index);
  }

  /**
   * Remove all of the list of bank-accounts.
   *
   * It only adds one bank-account for quick start.
   */
  resetBankAccounts() {
    this.formArray.clear();
  }

  /**
   * Handles the invoices select event.
   * @param invoices The newly selected invoices.
   */
  selectBankAccount(bankAccounts: BankAccount[]) {
    if (bankAccounts) {
      bankAccounts.map((bankAccount) =>
        this.createBankAccountFormGroup(this.createBankAccountFormItemFromBankAccount(bankAccount))
      );
    }
  }

  /**
   * Creates and returns a new bank account form item from a given bank account.
   * @param bankAccount The bank account to be used to create the bank account form item.
   */
  createBankAccountFormItemFromBankAccount(bankAccount: BankAccount): BankAccountFormItem {
    return {
      bankAccountId: bankAccount.id,
      bankAccount: bankAccount,
    };
  }

  /**
   * Create a new bank account form group from the provided bank account form item.
   * @param bankAccount The bank account form item that contains data about the new bank account.
   */
  createBankAccountFormGroup(bankAccount: BankAccountFormItem) {
    if (this.isBankAccountExists(bankAccount.bankAccount.id)) {
      return;
    }

    const formGroup = new FormGroup({
      bankAccountId: new FormControl(bankAccount?.bankAccountId ?? null),
      bankAccount: new FormControl(bankAccount?.bankAccount),
    });
    this.formArray.push(formGroup);
  }

  /**
   * Indicates whether if the given bank account id is already exists in the form array or not.
   * @param bankAccountId The id of the bank account.
   * @returns `boolean`.
   */
  isBankAccountExists(bankAccountId: number): boolean {
    return (this.formArray.value as BankAccountFormItem[]).some(
      (bankAccountItem) => bankAccountItem.bankAccount.id === bankAccountId
    );
  }
}
