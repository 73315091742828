import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';
import { Effect, ofType, Actions } from '@ngrx/effects';
import { of } from 'rxjs';
import { switchMap, map, catchError, tap, take } from 'rxjs/operators';

import * as fromCoreStore from 'core/store';
import { AuthService } from 'auth/services';
import {
  SessionActionType,
  Login,
  LoginFail,
  LoginSuccess,
  Logout,
  FetchUserData,
  FetchUserDataSuccess,
  FetchUserDataFail,
  UpdateUserProfileInfo,
  UpdateUserProfileInfoSuccess,
  UpdateUserProfileInfoFail,
  UpdateUserProfileCredentials,
  UpdateUserProfileCredentialsSuccess,
  UpdateUserProfileCredentialsFail,
  RefreshToken,
  RefreshTokenSuccess,
  RefreshTokenFail,
  UpdateUserLanguage,
  UpdateUserLanguageSuccess,
  UpdateUserLanguageFail,
  UpdateUserDefaultLocation,
  UpdateUserDefaultLocationSuccess,
  UpdateUserDefaultLocationFail,
  UpdateUserDefaultCostCenter,
  UpdateUserDefaultCostCenterSuccess,
  UpdateUserDefaultCostCenterFail,
  UpdateUserDefaultCashAccount,
  UpdateUserDefaultCashAccountSuccess,
  UpdateUserDefaultCashAccountFail,
  UpdateUserDefaultElectronicAccount,
  UpdateUserDefaultElectronicAccountSuccess,
  UpdateUserDefaultElectronicAccountFail,
  UpdateUserDefaultPosDevice,
  UpdateUserDefaultPosDeviceFail,
  UpdateUserDefaultPosDeviceSuccess,
  GetUserLocationsSuccess,
  GetUserLocationsFail,
  GetUserBankAccounts,
  GetUserBankAccountsSuccess,
  GetUserBankAccountsFail,
  GetUserCostCenters,
  GetUserCostCentersSuccess,
  GetUserCostCentersFail,
  GetUserLocations,
  GetApiKey,
  GetApiKeySuccess,
  GetApiKeyFail,
  UpdateApiKey,
  UpdateApiKeySuccess,
  UpdateApiKeyFail,
} from 'auth/store/actions';
import { StorageService, NotificationService, TranslationService } from 'shared/services';
import { WebsocketService } from 'websocket/services';
import { StatusCode } from 'shared/models';

@Injectable()
export class SessionEffects {
  constructor(
    private actions$: Actions,
    private coreStore$: Store<fromCoreStore.AppState>,
    private authService: AuthService,
    private storageService: StorageService,
    private notificationService: NotificationService,
    private translationService: TranslationService,
    private websocketService: WebsocketService
  ) {}

  /* ========================= LOGIN =================================== */
  @Effect()
  login$ = this.actions$.pipe(
    ofType(SessionActionType.LOGIN),
    switchMap((action: Login) =>
      this.authService.login(action.payload.username, action.payload.password).pipe(
        map((response) => new LoginSuccess({ response: response.data, rememberUser: action.payload.rememberUser })),
        catchError((error) => of(new LoginFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  loginSuccess$ = this.actions$.pipe(
    ofType(SessionActionType.LOGIN_SUCCESS),
    tap((action: LoginSuccess) => {
      this.storageService.setJwt(action.payload.response.jwt, action.payload.rememberUser);

      /**
       * Connect to the websocket server.
       */
      this.websocketService.connect();

      this.goToHome();
    })
  );

  @Effect({ dispatch: false })
  loginFail$ = this.actions$.pipe(
    ofType(SessionActionType.LOGIN_FAIL),
    tap((action: LoginFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );

  /* ========================= REFRESH_TOKEN =================================== */
  @Effect()
  refreshToken$ = this.actions$.pipe(
    ofType(SessionActionType.REFRESH_TOKEN),
    switchMap((action: RefreshToken) => {
      return this.authService.refreshToken().pipe(
        map((response) => new RefreshTokenSuccess({ response: response.data, rememberUser: true })),
        catchError((error) => of(new RefreshTokenFail(error)))
      );
    })
  );

  @Effect({ dispatch: false })
  refreshTokenSuccess$ = this.actions$.pipe(
    ofType(SessionActionType.REFRESH_TOKEN_SUCCESS),
    tap((action: RefreshTokenSuccess) => {
      this.storageService.setJwt(action.payload.response.jwt, action.payload.rememberUser);

      /**
       * Connect to the websocket server.
       */
      this.websocketService.connect();
    })
  );

  @Effect({ dispatch: false })
  refreshTokenFail$ = this.actions$.pipe(
    ofType(SessionActionType.REFRESH_TOKEN_FAIL),
    tap((action: RefreshTokenFail) => {
      this.logout(true);
    })
  );

  /* ========================= FETCH_USER_DATA ========================= */

  @Effect()
  fetchUserData$ = this.actions$.pipe(
    ofType(SessionActionType.FETCH_USER_DATA),
    switchMap((action: FetchUserData) =>
      this.authService.getUserProfileData().pipe(
        map((response) => new FetchUserDataSuccess(response.data)),
        catchError((error) => of(new FetchUserDataFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  fetchUserDataSuccess$ = this.actions$.pipe(
    ofType(SessionActionType.FETCH_USER_DATA_SUCCESS),
    tap((action: FetchUserDataSuccess) => {
      /**
       * Connect to the websocket server.
       */
      this.websocketService.connect();
    })
  );

  @Effect({ dispatch: false })
  fetchUserDataFail$ = this.actions$.pipe(
    ofType(SessionActionType.FETCH_USER_DATA_FAIL),
    tap((action: FetchUserDataFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorForbidden) {
        this.logout(true);
      }
    })
  );

  /* ========================= GET_USER_LOCATIONS =================================== */
  @Effect()
  getUserLocations$ = this.actions$.pipe(
    ofType(SessionActionType.GET_USER_LOCATIONS),
    switchMap((action: GetUserLocations) =>
      this.authService.getUserLocations().pipe(
        map((response) => new GetUserLocationsSuccess(response)),
        catchError((error) => of(new GetUserLocationsFail(error)))
      )
    )
  );

  /* ========================= GET_USER_BANK_ACCOUNTS =================================== */
  @Effect()
  getUserBankAccounts$ = this.actions$.pipe(
    ofType(SessionActionType.GET_USER_BANK_ACCOUNTS),
    switchMap((action: GetUserBankAccounts) =>
      this.authService.getUserBankAccounts().pipe(
        map((response) => new GetUserBankAccountsSuccess(response)),
        catchError((error) => of(new GetUserBankAccountsFail(error)))
      )
    )
  );

  /* ========================= GET_USER_COST_CENTERS =================================== */
  @Effect()
  getUserCostCenters$ = this.actions$.pipe(
    ofType(SessionActionType.GET_USER_COST_CENTERS),
    switchMap((action: GetUserCostCenters) =>
      this.authService.getUserCostCenters().pipe(
        map((response) => new GetUserCostCentersSuccess(response)),
        catchError((error) => of(new GetUserCostCentersFail(error)))
      )
    )
  );

  /* ========================= UPDATE_USER_PROFILE_INFO ========================= */

  @Effect()
  updateUserProfileInfo$ = this.actions$.pipe(
    ofType(SessionActionType.UPDATE_USER_PROFILE_INFO),
    switchMap((action: UpdateUserProfileInfo) =>
      this.authService.updateUserProfileInfo(action.payload).pipe(
        map((response) => new UpdateUserProfileInfoSuccess(response.data)),
        catchError((error) => of(new UpdateUserProfileInfoFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  updateUserProfileInfoSuccess$ = this.actions$.pipe(
    ofType(SessionActionType.UPDATE_USER_PROFILE_INFO_SUCCESS),
    tap((action: UpdateUserProfileInfoSuccess) => {
      this.notificationService.success(this.translationService.translate('AUTH.USER_PROFILE_INFO_UPDATED'));
    })
  );

  @Effect({ dispatch: false })
  updateUserProfileInfoFail$ = this.actions$.pipe(
    ofType(SessionActionType.UPDATE_USER_PROFILE_INFO_FAIL),
    tap((action: UpdateUserProfileInfoFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorForbidden) {
        this.logout(true);
      } else if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );

  /* ========================= UPDATE_USER_PROFILE_CREDENTIALS ========================= */

  @Effect()
  updateUserProfileCredentials$ = this.actions$.pipe(
    ofType(SessionActionType.UPDATE_USER_PROFILE_CREDENTIALS),
    switchMap((action: UpdateUserProfileCredentials) =>
      this.authService.updateUserProfileCredentials(action.payload).pipe(
        map((response) => new UpdateUserProfileCredentialsSuccess(response.data)),
        catchError((error) => of(new UpdateUserProfileCredentialsFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  updateUserProfileCredentialsSuccess$ = this.actions$.pipe(
    ofType(SessionActionType.UPDATE_USER_PROFILE_CREDENTIALS_SUCCESS),
    tap((action: UpdateUserProfileCredentialsSuccess) => {
      this.notificationService.success(this.translationService.translate('AUTH.USER_PROFILE_CREDENTIALS_UPDATED'));
    })
  );

  @Effect({ dispatch: false })
  updateUserProfileCredentialsFail$ = this.actions$.pipe(
    ofType(SessionActionType.UPDATE_USER_PROFILE_CREDENTIALS_FAIL),
    tap((action: UpdateUserProfileCredentialsFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorForbidden) {
        this.logout(true);
      } else if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );

  /* ========================= LOGOUT ========================= */

  @Effect()
  logout$ = this.actions$.pipe(
    ofType(SessionActionType.LOGOUT),
    take(1),
    tap((action: Logout) => this.logout(action.payload))
  );

  /* ========================= UPDATE_USER_LANGUAGE ========================= */

  @Effect()
  updateUserLanguage$ = this.actions$.pipe(
    ofType(SessionActionType.UPDATE_USER_LANGUAGE),
    switchMap((action: UpdateUserLanguage) =>
      this.authService.updateUserLang(action.payload).pipe(
        map((response) => new UpdateUserLanguageSuccess(response.data)),
        catchError((error) => of(new UpdateUserLanguageFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  updateUserLanguageSuccess$ = this.actions$.pipe(
    ofType(SessionActionType.UPDATE_USER_LANGUAGE_SUCCESS),
    tap((action: UpdateUserLanguageSuccess) => {
      this.notificationService.success(this.translationService.translate('AUTH.LANGUAGE_UPDATED'));
    })
  );

  @Effect({ dispatch: false })
  updateUserLanguageFail$ = this.actions$.pipe(
    ofType(SessionActionType.UPDATE_USER_LANGUAGE_FAIL),
    tap((action: UpdateUserLanguageFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorForbidden) {
        this.logout(true);
      } else if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );

  /* ========================= UPDATE_DEFAULT_USER_LOCATION =================================== */
  @Effect()
  updateDefaultUserLocation$ = this.actions$.pipe(
    ofType(SessionActionType.UPDATE_USER_DEFAULT_LOCATION),
    switchMap((action: UpdateUserDefaultLocation) =>
      this.authService.updateUserDefaultLocation(action.payload).pipe(
        map((response) => new UpdateUserDefaultLocationSuccess(response)),
        catchError((error) => of(new UpdateUserDefaultLocationFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  updateDefaultUserLocationSuccess$ = this.actions$.pipe(
    ofType(SessionActionType.UPDATE_USER_DEFAULT_LOCATION_SUCCESS),
    tap((action: UpdateUserDefaultLocationSuccess) => {
      this.notificationService.success(this.translationService.translate('AUTH.DEFAULT_USER_LOCATION_UPDATED'));
    })
  );

  @Effect({ dispatch: false })
  updateDefaultUserLocationFail$ = this.actions$.pipe(
    ofType(SessionActionType.UPDATE_USER_DEFAULT_LOCATION_FAIL),
    tap((action: UpdateUserDefaultLocationFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(this.translationService.translate('AUTH.USER_LOCATION_NOT_FOUND'));
      } else if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );

  /* ========================= UPDATE_DEFAULT_USER_COST_CENTER =================================== */
  @Effect()
  updateDefaultUserCostCenter$ = this.actions$.pipe(
    ofType(SessionActionType.UPDATE_USER_DEFAULT_COST_CENTER),
    switchMap((action: UpdateUserDefaultCostCenter) =>
      this.authService.updateUserDefaultCostCenter(action.payload).pipe(
        map((response) => new UpdateUserDefaultCostCenterSuccess(response)),
        catchError((error) => of(new UpdateUserDefaultCostCenterFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  updateDefaultUserCostCenterSuccess$ = this.actions$.pipe(
    ofType(SessionActionType.UPDATE_USER_DEFAULT_COST_CENTER_SUCCESS),
    tap((action: UpdateUserDefaultCostCenterSuccess) => {
      this.notificationService.success(this.translationService.translate('AUTH.DEFAULT_USER_COST_CENTER_UPDATED'));
    })
  );

  @Effect({ dispatch: false })
  updateDefaultUserCostCenterFail$ = this.actions$.pipe(
    ofType(SessionActionType.UPDATE_USER_DEFAULT_COST_CENTER_FAIL),
    tap((action: UpdateUserDefaultCostCenterFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(this.translationService.translate('AUTH.USER_COST_CENTER_NOT_FOUND'));
      } else if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );
  /* ========================= UPDATE_DEFAULT_USER_CASH_ACCOUNT =================================== */
  @Effect()
  updateDefaultUserCashAccount$ = this.actions$.pipe(
    ofType(SessionActionType.UPDATE_USER_DEFAULT_CASH_ACCOUNT),
    switchMap((action: UpdateUserDefaultCashAccount) =>
      this.authService.updateUserDefaultCashAccount(action.payload).pipe(
        map((response) => new UpdateUserDefaultCashAccountSuccess(response)),
        catchError((error) => of(new UpdateUserDefaultCashAccountFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  updateDefaultUserCashAccountSuccess$ = this.actions$.pipe(
    ofType(SessionActionType.UPDATE_USER_DEFAULT_CASH_ACCOUNT_SUCCESS),
    tap((action: UpdateUserDefaultCashAccountSuccess) => {
      this.notificationService.success(this.translationService.translate('AUTH.DEFAULT_USER_CASH_ACCOUNT_UPDATED'));
    })
  );

  @Effect({ dispatch: false })
  updateDefaultUserCashAccountFail$ = this.actions$.pipe(
    ofType(SessionActionType.UPDATE_USER_DEFAULT_CASH_ACCOUNT_FAIL),
    tap((action: UpdateUserDefaultCashAccountFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(this.translationService.translate('AUTH.USER_CASH_ACCOUNT_NOT_FOUND'));
      } else if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );
  /* ========================= UPDATE_DEFAULT_USER_ELECTRONIC_ACCOUNT =================================== */
  @Effect()
  updateDefaultUserElectronicAccount$ = this.actions$.pipe(
    ofType(SessionActionType.UPDATE_USER_DEFAULT_ELECTRONIC_ACCOUNT),
    switchMap((action: UpdateUserDefaultElectronicAccount) =>
      this.authService.updateUserDefaultElectronicAccount(action.payload).pipe(
        map((response) => new UpdateUserDefaultElectronicAccountSuccess(response)),
        catchError((error) => of(new UpdateUserDefaultElectronicAccountFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  updateDefaultUserElectronicAccountSuccess$ = this.actions$.pipe(
    ofType(SessionActionType.UPDATE_USER_DEFAULT_ELECTRONIC_ACCOUNT_SUCCESS),
    tap((action: UpdateUserDefaultElectronicAccountSuccess) => {
      this.notificationService.success(
        this.translationService.translate('AUTH.DEFAULT_USER_ELECTRONIC_ACCOUNT_UPDATED')
      );
    })
  );

  @Effect({ dispatch: false })
  updateDefaultUserElectronicAccountFail$ = this.actions$.pipe(
    ofType(SessionActionType.UPDATE_USER_DEFAULT_ELECTRONIC_ACCOUNT_FAIL),
    tap((action: UpdateUserDefaultElectronicAccountFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(this.translationService.translate('AUTH.USER_ELECTRONIC_ACCOUNT_NOT_FOUND'));
      } else if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );

  /* ========================= UPDATE_DEFAULT_USER_POS_DEVICE =================================== */
  @Effect()
  updateDefaultUserPosDevice$ = this.actions$.pipe(
    ofType(SessionActionType.UPDATE_USER_DEFAULT_POS_DEVICE),
    switchMap((action: UpdateUserDefaultPosDevice) =>
      this.authService.updateUserDefaultPosDevice(action.payload).pipe(
        map((response) => new UpdateUserDefaultPosDeviceSuccess(response)),
        catchError((error) => of(new UpdateUserDefaultPosDeviceFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  updateDefaultUserPosDeviceSuccess$ = this.actions$.pipe(
    ofType(SessionActionType.UPDATE_USER_DEFAULT_POS_DEVICE_SUCCESS),
    tap((action: UpdateUserDefaultPosDeviceSuccess) => {
      this.notificationService.success(this.translationService.translate('AUTH.DEFAULT_USER_POS_DEVICE_UPDATED'));
    })
  );

  @Effect({ dispatch: false })
  updateDefaultUserPosDeviceFail$ = this.actions$.pipe(
    ofType(SessionActionType.UPDATE_USER_DEFAULT_POS_DEVICE_FAIL),
    tap((action: UpdateUserDefaultPosDeviceFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(this.translationService.translate('AUTH.USER_POS_DEVICE_NOT_FOUND'));
      } else if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );

  /* ========================= GET_API_KEY =================================== */
  @Effect()
  getApiKey$ = this.actions$.pipe(
    ofType(SessionActionType.GET_API_KEY),
    switchMap((action: GetApiKey) =>
      this.authService.getApiKey().pipe(
        map((response) => new GetApiKeySuccess(response)),
        catchError((error) => of(new GetApiKeyFail(error)))
      )
    )
  );

  /* ========================= UPDATE_API_KEY =================================== */
  @Effect()
  updateApiKey$ = this.actions$.pipe(
    ofType(SessionActionType.UPDATE_API_KEY),
    switchMap((action: UpdateApiKey) =>
      this.authService.updateApiKey().pipe(
        map((response) => new UpdateApiKeySuccess(response)),
        catchError((error) => of(new UpdateApiKeyFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  updateApiKeySuccess$ = this.actions$.pipe(
    ofType(SessionActionType.UPDATE_API_KEY_SUCCESS),
    tap((action: UpdateApiKeySuccess) => {
      this.notificationService.success(this.translationService.translate('AUTH.API_KEY_UPDATED'));
    })
  );

  @Effect({ dispatch: false })
  updateApiKeyFail$ = this.actions$.pipe(
    ofType(SessionActionType.UPDATE_API_KEY_FAIL),
    tap((action: UpdateApiKeyFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(this.translationService.translate('AUTH.API_KEY_NOT_FOUND'));
      } else if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );

  /* ========================= Helpers ========================= */

  /**
   * Log user out and redirects to the login page if needed.
   * @param gotoLogin Indicates whether the user will be redirected to the login page, default is true.
   */
  logout(gotoLogin: boolean = true) {
    this.storageService.deleteJwt();
    if (gotoLogin) {
      this.coreStore$.dispatch(new fromCoreStore.Go({ path: ['/login'] }));
    }
  }

  /** Redirects the user to the home page to start using the app. */
  goToHome() {
    this.coreStore$.dispatch(new fromCoreStore.Go({ path: ['/'] }));
  }
}
