<div class="card-header mb-1 pt-1">
  <h4 class="card-title">
    {{ 'SECURITY.USERS.USER_PERMISSIONS' | translate }}
    <span style="font-size: small;"> {{ selectedClaimsSummary }}</span>
  </h4>
</div>
<ng-container *ngFor="let group of groups">
  <div class="card-header mb-1">
    <h4 class="card-title">
      <ui-switch
        class="no-outline"
        checkedTextColor="#FFFF"
        uncheckedTextColor="#FFFF"
        defaultBgColor="#009DA0"
        defaultBoColor="none"
        [(ngModel)]="group.isSelected"
        (change)="onGroupSelectedChange(group, $event)"
      >
      </ui-switch>
      <span>{{ group.group.name | localize: group.group.nameEn | async }}</span>
      <span style="font-size: small;"> {{ getGroupSelectedClaimsSummary(group) }}</span>
    </h4>
  </div>
  <div class="card-content">
    <div class="px-5">
      <div class="row d-flex flex-row">
        <div class="form-group p-1 m-1" *ngFor="let claim of group.claims">
          <ui-switch
            class="no-outline"
            [checkedLabel]="claim.claim.name | localize: claim.claim.nameEn | async"
            [uncheckedLabel]="claim.claim.name | localize: claim.claim.nameEn | async"
            checkedTextColor="#FFFF"
            uncheckedTextColor="#FFFF"
            defaultBgColor="#009DA0"
            defaultBoColor="none"
            [(ngModel)]="claim.isSelected"
            (change)="onClaimSelectedChange(claim, group, $event)"
          >
          </ui-switch>
        </div>
      </div>
    </div>
  </div>
</ng-container>
