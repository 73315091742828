import { Injectable } from '@angular/core';

import { of } from 'rxjs';
import { switchMap, map, catchError } from 'rxjs/operators';
import { Effect, ofType, Actions } from '@ngrx/effects';

import { LookupsService } from 'lookups/services';
import {
  EducationLevelActionType,
  GetAllEducationLevelsFail,
  GetAllEducationLevelsSuccess,
} from 'lookups/store/actions';

@Injectable()
export class EducationLevelEffects {
  constructor(private actions$: Actions, private lookupsService: LookupsService) {}

  /* ========================= GET_ALL_EDUCATION_LEVELS =================================== */
  @Effect()
  getAllEducationLevels$ = this.actions$.pipe(
    ofType(EducationLevelActionType.GET_ALL_EDUCATION_LEVELS),
    switchMap(() =>
      this.lookupsService.getAllEducationLevels().pipe(
        map((response) => new GetAllEducationLevelsSuccess(response)),
        catchError((error) => of(new GetAllEducationLevelsFail(error)))
      )
    )
  );
}
