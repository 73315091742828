import { Component, OnInit } from '@angular/core';

import { Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';

import * as fromStatisticsStore from 'statistics/store';
import { Claims } from 'security/models';
import { StatisticsData } from 'statistics/models';
import { TranslationService } from 'shared';

@Component({
  selector: 'app-system-statistics',
  templateUrl: './system-statistics.component.html',
  styles: [],
})
export class SystemStatisticsComponent implements OnInit {
  /**
   * The loaded statistics.
   */
  statistics$: Observable<StatisticsData>;

  /**
   * The system supported user claims.
   */
  Claims = Claims;

  /**
   * Gets or sets the current selected language.
   */
  get currentLang() {
    return this.translationService.language;
  }

  /**
   * Gets or sets the ngb-dropdown or ngb-tooltip position based on the current user display language.
   */
  get ngbPosition(): string {
    return this.currentLang === 'ar' ? 'bottom-right' : 'bottom-left';
  }

  /**
   * @param statisticsStore$ The statistics-module store.
   * @param translationService: The translation service.
   */
  constructor(
    private statisticsStore$: Store<fromStatisticsStore.StatisticsMainState>,
    private translationService: TranslationService
  ) {}

  ngOnInit(): void {
    this.init();
  }

  /**
   * Initialize component data.
   */
  init() {
    this.loadStatistics();

    /** Load data. */
    this.statistics$ = this.statisticsStore$.pipe(select(fromStatisticsStore.getStatistics));
  }

  /**
   * Loads the statistics.
   */
  loadStatistics() {
    this.statisticsStore$.dispatch(new fromStatisticsStore.LoadStatistics());
  }
}
