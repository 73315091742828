import { createSelector } from '@ngrx/store';

import { selectLookupsState } from 'lookups/store/selectors/feature.selectors';
import { LookupsState, CustomerCategoryState } from 'lookups/store/states';

/**
 * Gets the customer category state.
 */
const selectCustomerCategories = createSelector(selectLookupsState, (state: LookupsState) => state.customerCategories);

/**
 * Gets the list of loaded customer categories.
 */
export const getCustomerCategories = createSelector(
  selectCustomerCategories,
  (state: CustomerCategoryState) => state.data
);

/**
 * Gets the error that occurred in the state.
 */
export const getCustomerCategoriesError = createSelector(
  selectCustomerCategories,
  (state: CustomerCategoryState) => state.error
);

/**
 * Determines if there is a running load process.
 */
export const getCustomerCategoriesIsLoading = createSelector(
  selectCustomerCategories,
  (state: CustomerCategoryState) => state.isLoading
);

/**
 * Determines if the last load process has been ended successfully.
 */
export const getCustomerCategoriesIsLoaded = createSelector(
  selectCustomerCategories,
  (state: CustomerCategoryState) => state.isLoaded
);
