/**
 * Represents the system supported national identifier types.
 */
export enum NationalIdentifierTypes {
  /**
   * The national ID type of national identifier types.
   */
  NATIONAL_ID = 'NATIONAL_ID',

  /**
   * The resident identity type of national identifier types.
   */
  RESIDENT_IDENTITY = 'RESIDENT_IDENTITY',

  /**
   * The border number of national identifier types.
   */
  BORDER_NUMBER = 'BORDER_NUMBER',

  /**
   * The passport of national identifier types.
   */
  PASSPORT = 'PASSPORT',

  /**
   * The visa of national identifier types.
   */
  VISA = 'VISA',
}
