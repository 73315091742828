/**
 * Represents a sick leave type.
 */
export class SickLeaveType {
  /**
   * Gets or sets the id of the sick leave type.
   */
  id: number;

  /**
   * Gets or sets the name of the sick leave type.
   */
  name: string;

  /**
   * Gets or sets the English name of the sick leave type.
   */
  nameEn: string;

  /**
   * Gets or sets the key of the sick leave type.
   */
  key: string;
}
