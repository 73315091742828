import { createSelector } from '@ngrx/store';

import { SectorsState, AppSearchState } from 'app-search/store/states';
import { selectAppSearchState } from 'app-search/store/selectors/feature.selectors';

/**
 * Gets the sectors state.
 */
const selectSectorState = createSelector(selectAppSearchState, (state: AppSearchState) => state.sectors);

/**
 * Gets the list of loaded sectors.
 */
export const getSectors = createSelector(selectSectorState, (state: SectorsState) => state.data);

/**
 * Gets the sectors pagination info.
 */
export const getSectorsPaginationInfo = createSelector(
  selectSectorState,
  (state: SectorsState) => state.paginationInfo
);

/**
 * Gets the error that occurred in the state.
 */
export const getSectorsError = createSelector(selectSectorState, (state: SectorsState) => state.error);

/**
 * Determines if there is a running search process.
 */
export const getSectorsSearching = createSelector(selectSectorState, (state: SectorsState) => state.isSearching);

/**
 * Determines if the last search process has been ended successfully.
 */
export const getSectorsSearchCompleted = createSelector(
  selectSectorState,
  (state: SectorsState) => state.isSearchCompleted
);
