import { ReportsService } from 'reports/services/reports.service';
import { StoresReportsService } from 'reports/services/stores-reports.service';
import { PurchasesReportsService } from 'reports/services/purchases-reports.service';
import { SalesReportsService } from 'reports/services/sales-reports.service';
import { FinancesReportsService } from 'reports/services/finances-report.service';

export * from 'reports/services/reports.service';
export * from 'reports/services/stores-reports.service';
export * from 'reports/services/purchases-reports.service';
export * from 'reports/services/sales-reports.service';
export * from 'reports/services/finances-report.service';

/**
 * The set of services registered on this module.
 */
export const SERVICES: any[] = [
  ReportsService,
  StoresReportsService,
  PurchasesReportsService,
  SalesReportsService,
  FinancesReportsService,
];
