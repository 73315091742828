import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { AppHttpResponse } from 'shared/models';
import { Report } from 'reports/models';

/**
 * The reports services.
 */
@Injectable()
export class ReportsService {
  /**
   * The relative route for the reports.
   *
   * No leading or trailing slashes required.
   */
  private reportsApi = 'reporting/reports';

  constructor(private http: HttpClient) {}

  /**
   * Gets all reports belong to the current logged-in user.
   */
  public getMyReports(): Observable<AppHttpResponse<Report[]>> {
    return this.http.get<AppHttpResponse<Report[]>>(`${this.reportsApi}/my-reports`);
  }

  /**
   * Deletes the report with the given id.
   * @param reportId The id of the report.
   */
  public delete(reportId: string): Observable<AppHttpResponse<Report>> {
    return this.http.delete<AppHttpResponse<Report>>(`${this.reportsApi}/my-reports/${reportId}`);
  }
}
