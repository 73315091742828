import { createSelector } from '@ngrx/store';

import { selectLookupsState } from 'lookups/store/selectors/feature.selectors';
import { LookupsState,TimeZoneState } from 'lookups/store/states';

/**
 * Gets the timeZone state.
 */
const selectTimeZones = createSelector(selectLookupsState, (state: LookupsState) => state.timeZones);

/**
 * Gets the list of loaded timeZones.
 */
export const getTimeZones = createSelector(selectTimeZones, (state: TimeZoneState) => state.data);

/**
 * Gets the error that occurred in the state.
 */
export const getTimeZonesError = createSelector(
  selectTimeZones,
  (state: TimeZoneState) => state.error
);

/**
 * Determines if there is a running load process.
 */
export const getTimeZonesIsLoading = createSelector(
  selectTimeZones,
  (state: TimeZoneState) => state.isLoading
);

/**
 * Determines if the last load process has been ended successfully.
 */
export const getTimeZonesIsLoaded = createSelector(
  selectTimeZones,
  (state: TimeZoneState) => state.isLoaded
);
