<div class="row">
  <div class="col-md-12">
    <!-- journal asset details starts -->
    <div class="card-header mb-3">
      <h4 class="card-title">{{ 'FINANCES.JOURNALS.JOURNAL_DATA.ASSETS_DETAILS' | translate }}</h4>
    </div>
    <div class="card-content">
      <div class="px-3">
        <div class="row">
          <div class="form-group col-md-6">
            <label for="assetCategoryId">{{ 'FINANCES.JOURNALS.JOURNAL_DATA.ASSET_TYPE' | translate }}</label>
            <input
              type="text"
              id="assetCategoryId"
              class="form-control round"
              [value]="asset.assetCategory?.name | localize: asset.assetCategory?.nameEn | async"
              readonly
            />
          </div>
          <div class="form-group col-md-6">
            <label for="assetYearsOfUseId">{{
              'FINANCES.JOURNALS.JOURNAL_DATA.ASSET_YEARS_OF_USES' | translate
            }}</label>
            <input
              type="text"
              id="assetYearsOfUseId"
              class="form-control round"
              [value]="asset.assetYearsOfUse.value"
              readonly
            />
          </div>
          <div class="form-group col-md-6">
            <label for="description">{{ 'FINANCES.JOURNALS.JOURNAL_DATA.ASSET_DESCRIPTION' | translate }}</label>
            <input type="text" id="description" class="form-control round" [value]="asset.description" readonly />
          </div>
          <div class="form-group col-md-6">
            <label for="tradeMark">{{ 'FINANCES.JOURNALS.JOURNAL_DATA.TRADE_MARK' | translate }}</label>
            <input type="text" id="tradeMark" class="form-control round" [value]="asset.tradeMark" readonly />
          </div>
          <div class="form-group col-md-6">
            <label for="barcode">{{ 'FINANCES.JOURNALS.JOURNAL_DATA.BARCODE' | translate }}</label>
            <input type="text" id="barcode" class="form-control round" [value]="asset.barcode" readonly />
          </div>
          <div class="form-group col-md-6">
            <label for="bookValue">{{ 'FINANCES.JOURNALS.JOURNAL_DATA.BOOK_VALUE' | translate }}</label>
            <input type="text" id="bookValue" class="form-control round" [value]="asset.bookValue" readonly />
          </div>
          <div class="form-group col-md-6">
            <label for="depreciationStartValue">{{
              'FINANCES.JOURNALS.JOURNAL_DATA.DEPRECIATION_VALUE' | translate
            }}</label>
            <input
              type="text"
              id="depreciationStartValue"
              class="form-control round"
              [value]="asset.depreciationStartValue"
              readonly
            />
          </div>
          <div class="form-group col-md-6">
            <label for="locationId">{{ 'FINANCES.JOURNALS.JOURNAL_DATA.LOCATION' | translate }}</label>
            <input
              type="text"
              id="locationId"
              class="form-control round"
              [value]="asset.location.name | localize: asset.location.nameEn | async"
              readonly
            />
          </div>
          <div class="form-group col-md-6">
            <label for="projectId">{{ 'FINANCES.JOURNALS.JOURNAL_DATA.PROJECT' | translate }}</label>
            <input
              type="text"
              id="projectId"
              class="form-control round"
              [value]="asset.project?.description | localize: asset.project?.descriptionEn | async"
              readonly
            />
          </div>
        </div>
      </div>
      <!-- journal asset details ends -->
    </div>
  </div>
</div>
