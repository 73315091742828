import { createSelector } from '@ngrx/store';

import { SalesState, PendingPosInvoicesState } from 'sales/store/states';
import { selectSalesState } from 'sales/store/selectors/feature.selectors';

/**
 * Gets the pending point of sale invoices state.
 */
const SelectPendingPosInvoicesState = createSelector(selectSalesState, (state: SalesState) => state.pendingPosInvoices);

/**
 * Gets the list of loaded point of sale invoices.
 */
export const getPreparedPointOfSaleInvoices = createSelector(
  SelectPendingPosInvoicesState,
  (state: PendingPosInvoicesState) => state.data
);

/**
 * Gets the error that occurred in the state.
 */
export const getPendingPosInvoicesError = createSelector(
  SelectPendingPosInvoicesState,
  (state: PendingPosInvoicesState) => state.error
);

/**
 * Gets the selected pending point of sale invoice.
 */
export const getSelectedPendingPointOfSaleInvoice = createSelector(
  SelectPendingPosInvoicesState,
  (state: PendingPosInvoicesState) => state.selectedPendingPointOfSaleInvoice
);

/**
 * Determines if there is a running prepare process.
 */
export const getPointOfSaleInvoicePreparing = createSelector(
  SelectPendingPosInvoicesState,
  (state: PendingPosInvoicesState) => state.isPreparing
);

/**
 * Determines if the preparing process has been ended successfully.
 */
export const getPointOfSaleInvoicePrepareCompleted = createSelector(
  SelectPendingPosInvoicesState,
  (state: PendingPosInvoicesState) => state.isPrepareCompleted
);

/**
 * Determines if there is a updating process.
 */
export const getPointOfSaleInvoicePrepareUpdating = createSelector(
  SelectPendingPosInvoicesState,
  (state: PendingPosInvoicesState) => state.isUpdating
);

/**
 * Determines if the updating process has been ended successfully.
 */
export const getPointOfSaleInvoicePrepareUpdateCompleted = createSelector(
  SelectPendingPosInvoicesState,
  (state: PendingPosInvoicesState) => state.isUpdateCompleted
);
