import { ProductClass } from 'stores/models';
import { PaginationInfo } from 'shared/models';

/**
 * Represents the product classes state.
 */
export class ProductClassState {
  /**
   * The list of product classes.
   */
  public data: ProductClass[];

  /**
   * The list of product classes for sales screen.
   */
  public productClassesForSalesScreen: ProductClass[];

  /**
   * The pagination info for the product classes.
   */
  public paginationInfo: PaginationInfo;

  /**
   * An error that may resulting during processing some actions.
   */
  public error: any;

  /**
   * Determines if there is a running search process.
   */
  public isSearching: boolean;

  /**
   * Determines if the last search process has been ended successfully.
   */
  public isSearchCompleted: boolean;

  /**
   * Determines if there is a running search product classes for sales screen process.
   */
  public isProductClassesForSalesScreenSearching: boolean;

  /**
   * Determines if the last search product classes for sales screen process has been ended successfully.
   */
  public isProductClassesForSalesScreenSearchCompleted: boolean;

  /**
   * The current selected product class.
   */
  public selectedProductClass: ProductClass;

  /**
   * Determines if there is a running find-product-class process.
   */
  public isFinding: boolean;

  /**
   * Determines if the last find-product-class process has been ended successfully.
   */
  public isFindCompleted: boolean;

  /**
   * Determines if there is a running create process.
   */
  public isCreating: boolean;

  /**
   * Determines if the last create process has been ended successfully.
   */
  public isCreateCompleted: boolean;

  /**
   * Determines if there is a running update process.
   */
  public isUpdating: boolean;

  /**
   * Determines if the last update process has been ended successfully.
   */
  public isUpdateCompleted: boolean;

  /**
   * Determines if there is a running delete process.
   */
  public isDeleting: boolean;

  /**
   * Determines if the last delete process has been ended successfully.
   */
  public isDeleteCompleted: boolean;
}
