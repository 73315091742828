import { createSelector } from '@ngrx/store';

import { selectLookupsState } from 'lookups/store/selectors/feature.selectors';
import { LookupsState, BankAccountLogTypeState } from 'lookups/store/states';

/**
 * Gets the bank account log type state.
 */
const selectBankAccountLogTypes = createSelector(
  selectLookupsState,
  (state: LookupsState) => state.bankAccountLogTypes
);

/**
 * Gets the list of loaded bank account log types.
 */
export const getBankAccountLogTypes = createSelector(
  selectBankAccountLogTypes,
  (state: BankAccountLogTypeState) => state.data
);

/**
 * Gets the error that occurred in the state.
 */
export const getBankAccountLogTypesError = createSelector(
  selectBankAccountLogTypes,
  (state: BankAccountLogTypeState) => state.error
);

/**
 * Determines if there is a running load process.
 */
export const getBankAccountLogTypesIsLoading = createSelector(
  selectBankAccountLogTypes,
  (state: BankAccountLogTypeState) => state.isLoading
);

/**
 * Determines if the last load process has been ended successfully.
 */
export const getBankAccountLogTypesIsLoaded = createSelector(
  selectBankAccountLogTypes,
  (state: BankAccountLogTypeState) => state.isLoaded
);
