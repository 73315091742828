import { Injectable } from '@angular/core';

import { switchMap, map, catchError, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { Effect, ofType, Actions } from '@ngrx/effects';

import { NotificationService, TranslationService } from 'shared/services';
import { StatusCode } from 'shared/models';
import { SalesReportsService } from 'reports/services';
import {
  SalesReportsActionType,
  GetCustomerSummaryReport,
  GetCustomerSummaryReportFail,
  GetCustomerSummaryReportReceived,
  GetCustomerSummaryReportSuccess,
  GetCustomersDebtsReportReceived,
  GetCustomersDebtsReportFail,
  GetCustomersDebtsReportSuccess,
  GetCustomersPaymentsReport,
  GetCustomersPaymentsReportReceived,
  GetCustomersPaymentsReportFail,
  GetCustomersPaymentsReportSuccess,
  GetCustomersPointsReport,
  GetCustomersPointsReportReceived,
  GetCustomersPointsReportFail,
  GetCustomersPointsReportSuccess,
  GetGrossProfitReport,
  GetGrossProfitReportFail,
  GetGrossProfitReportReceived,
  GetGrossProfitReportSuccess,
  GetMaintenanceStageInvoiceReport,
  GetMaintenanceStageInvoiceReportFail,
  GetMaintenanceStageInvoiceReportReceived,
  GetMaintenanceStageInvoiceReportSuccess,
  GetTopReturnedSalesProductsReport,
  GetTopReturnedSalesProductsReportFail,
  GetTopReturnedSalesProductsReportReceived,
  GetTopReturnedSalesProductsReportSuccess,
  GetNetSalesReportFail,
  GetNetSalesReportReceived,
  GetNetSalesReportSuccess,
  GetProductSalesReport,
  GetProductSalesReportFail,
  GetProductSalesReportReceived,
  GetProductSalesReportSuccess,
  GetProductSalesReturnsReport,
  GetProductSalesReturnsReportFail,
  GetProductSalesReturnsReportReceived,
  GetProductSalesReturnsReportSuccess,
  GetNetSalesReport,
  GetProductClassesSalesReport,
  GetProductClassesSalesReportReceived,
  GetProductClassesSalesReportFail,
  GetProductClassesSalesReportSuccess,
  GetProductsSalesReport,
  GetProductsSalesReportReceived,
  GetProductsSalesReportFail,
  GetProductsSalesReportSuccess,
  GetProductsSalesReturnsReport,
  GetProductsSalesReturnsReportReceived,
  GetProductsSalesReturnsReportFail,
  GetProductsSalesReturnsReportSuccess,
  GetSalesInvoicesReport,
  GetSalesInvoicesReportReceived,
  GetSalesInvoicesReportFail,
  GetSalesInvoicesReportSuccess,
  GetSalesReturnsReport,
  GetSalesReturnsReportReceived,
  GetSalesReturnsReportFail,
  GetSalesReturnsReportSuccess,
  GetSalesSummaryReport,
  GetSalesSummaryReportReceived,
  GetSalesSummaryReportFail,
  GetSalesSummaryReportSuccess,
  GetShiftSummaryReport,
  GetShiftSummaryReportReceived,
  GetShiftSummaryReportFail,
  GetShiftSummaryReportSuccess,
  GetTopCustomersReport,
  GetTopCustomersReportReceived,
  GetTopCustomersReportFail,
  GetTopCustomersReportSuccess,
  GetTopSellingProductsReport,
  GetTopSellingProductsReportReceived,
  GetTopSellingProductsReportFail,
  GetTopSellingProductsReportSuccess,
  GetWorstCustomersReport,
  GetWorstCustomersReportReceived,
  GetWorstCustomersReportFail,
  GetWorstCustomersReportSuccess,
  GetWorstReturnedSalesProductsReport,
  GetWorstReturnedSalesProductsReportReceived,
  GetWorstReturnedSalesProductsReportFail,
  GetWorstReturnedSalesProductsReportSuccess,
  GetWorstSellingProductsReport,
  GetWorstSellingProductsReportReceived,
  GetWorstSellingProductsReportFail,
  GetWorstSellingProductsReportSuccess,
  GetSalesPersonSalesReport,
  GetSalesPersonSalesReportReceived,
  GetSalesPersonSalesReportFail,
  GetSalesPersonSalesReportSuccess,
} from 'reports/store/actions';

@Injectable()
export class SalesReportsEffects {
  constructor(
    private actions$: Actions,
    private reportsService: SalesReportsService,
    private notificationService: NotificationService,
    private translationService: TranslationService
  ) {}

  /* ========================= GET_CUSTOMER_SUMMARY_REPORT =================================== */
  @Effect()
  customerSummary$ = this.actions$.pipe(
    ofType(SalesReportsActionType.GET_CUSTOMER_SUMMARY_REPORT),
    switchMap(({ payload }: GetCustomerSummaryReport) =>
      this.reportsService.getCustomerSummaryReport(payload.customerId, payload.fromDate, payload.toDate).pipe(
        map((response) => new GetCustomerSummaryReportReceived(response)),
        catchError((error) => of(new GetCustomerSummaryReportFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  customerSummaryReceived$ = this.actions$.pipe(
    ofType(SalesReportsActionType.GET_CUSTOMER_SUMMARY_REPORT_RECEIVED),
    tap(({ payload: { data } }: GetCustomerSummaryReportReceived) => {
      this.notificationService.info(data);
    })
  );

  @Effect({ dispatch: false })
  customerSummarySuccess$ = this.actions$.pipe(
    ofType(SalesReportsActionType.GET_CUSTOMER_SUMMARY_REPORT_SUCCESS),
    tap((action: GetCustomerSummaryReportSuccess) => {
      this.notificationService.success(
        this.translationService.translate('REPORTS.NOTIFICATION_MESSAGE.REPORT_IS_READY')
      );
    })
  );

  @Effect({ dispatch: false })
  customerSummaryFail$ = this.actions$.pipe(
    ofType(SalesReportsActionType.GET_CUSTOMER_SUMMARY_REPORT_FAIL),
    tap((action: GetCustomerSummaryReportFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );

  /* ========================= GET_CUSTOMERS_DEBTS_REPORT =================================== */
  @Effect()
  customersDebts$ = this.actions$.pipe(
    ofType(SalesReportsActionType.GET_CUSTOMERS_DEBTS_REPORT),
    switchMap(() =>
      this.reportsService.getCustomersDebtsReport().pipe(
        map((response) => new GetCustomersDebtsReportReceived(response)),
        catchError((error) => of(new GetCustomersDebtsReportFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  customersDebtsReceived$ = this.actions$.pipe(
    ofType(SalesReportsActionType.GET_CUSTOMERS_DEBTS_REPORT_RECEIVED),
    tap(({ payload: { data } }: GetCustomersDebtsReportReceived) => {
      this.notificationService.info(data);
    })
  );

  @Effect({ dispatch: false })
  customersDebtsSuccess$ = this.actions$.pipe(
    ofType(SalesReportsActionType.GET_CUSTOMERS_DEBTS_REPORT_SUCCESS),
    tap((action: GetCustomersDebtsReportSuccess) => {
      this.notificationService.success(
        this.translationService.translate('REPORTS.NOTIFICATION_MESSAGE.REPORT_IS_READY')
      );
    })
  );

  @Effect({ dispatch: false })
  customersDebtsFail$ = this.actions$.pipe(
    ofType(SalesReportsActionType.GET_CUSTOMERS_DEBTS_REPORT_FAIL),
    tap((action: GetCustomersDebtsReportFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );

  /* ========================= GET_CUSTOMERS_PAYMENTS_REPORT =================================== */
  @Effect()
  customersPayments$ = this.actions$.pipe(
    ofType(SalesReportsActionType.GET_CUSTOMERS_PAYMENTS_REPORT),
    switchMap(({ payload }: GetCustomersPaymentsReport) =>
      this.reportsService
        .getCustomersPaymentsReport(payload.customers, payload.customerLogTypes, payload.fromDate, payload.toDate)
        .pipe(
          map((response) => new GetCustomersPaymentsReportReceived(response)),
          catchError((error) => of(new GetCustomersPaymentsReportFail(error)))
        )
    )
  );

  @Effect({ dispatch: false })
  customersPaymentsReceived$ = this.actions$.pipe(
    ofType(SalesReportsActionType.GET_CUSTOMERS_PAYMENTS_REPORT_RECEIVED),
    tap(({ payload: { data } }: GetCustomersPaymentsReportReceived) => {
      this.notificationService.info(data);
    })
  );

  @Effect({ dispatch: false })
  customersPaymentsSuccess$ = this.actions$.pipe(
    ofType(SalesReportsActionType.GET_CUSTOMERS_PAYMENTS_REPORT_SUCCESS),
    tap((action: GetCustomersPaymentsReportSuccess) => {
      this.notificationService.success(
        this.translationService.translate('REPORTS.NOTIFICATION_MESSAGE.REPORT_IS_READY')
      );
    })
  );

  @Effect({ dispatch: false })
  customersPaymentsFail$ = this.actions$.pipe(
    ofType(SalesReportsActionType.GET_CUSTOMERS_PAYMENTS_REPORT_FAIL),
    tap((action: GetCustomersPaymentsReportFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );

  /* ========================= GET_CUSTOMERS_POINTS_REPORT =================================== */
  @Effect()
  customersPoints$ = this.actions$.pipe(
    ofType(SalesReportsActionType.GET_CUSTOMERS_POINTS_REPORT),
    switchMap(({ payload }: GetCustomersPointsReport) =>
      this.reportsService.getCustomersPointsReport(payload.customers).pipe(
        map((response) => new GetCustomersPointsReportReceived(response)),
        catchError((error) => of(new GetCustomersPointsReportFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  customersPointsReceived$ = this.actions$.pipe(
    ofType(SalesReportsActionType.GET_CUSTOMERS_POINTS_REPORT_RECEIVED),
    tap(({ payload: { data } }: GetCustomersPointsReportReceived) => {
      this.notificationService.info(data);
    })
  );

  @Effect({ dispatch: false })
  customersPointsSuccess$ = this.actions$.pipe(
    ofType(SalesReportsActionType.GET_CUSTOMERS_POINTS_REPORT_SUCCESS),
    tap((action: GetCustomersPointsReportSuccess) => {
      this.notificationService.success(
        this.translationService.translate('REPORTS.NOTIFICATION_MESSAGE.REPORT_IS_READY')
      );
    })
  );

  @Effect({ dispatch: false })
  customersPointsFail$ = this.actions$.pipe(
    ofType(SalesReportsActionType.GET_CUSTOMERS_POINTS_REPORT_FAIL),
    tap((action: GetCustomersPointsReportFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );

  /* ========================= GET_GROSS_PROFIT_REPORT =================================== */
  @Effect()
  grossProfit$ = this.actions$.pipe(
    ofType(SalesReportsActionType.GET_GROSS_PROFIT_REPORT),
    switchMap(({ payload }: GetGrossProfitReport) =>
      this.reportsService.getTotalProfitsReport(payload.locations, payload.fromDate, payload.toDate).pipe(
        map((response) => new GetGrossProfitReportReceived(response)),
        catchError((error) => of(new GetGrossProfitReportFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  grossProfitReceived$ = this.actions$.pipe(
    ofType(SalesReportsActionType.GET_GROSS_PROFIT_REPORT_RECEIVED),
    tap(({ payload: { data } }: GetGrossProfitReportReceived) => {
      this.notificationService.info(data);
    })
  );

  @Effect({ dispatch: false })
  grossProfitSuccess$ = this.actions$.pipe(
    ofType(SalesReportsActionType.GET_GROSS_PROFIT_REPORT_SUCCESS),
    tap((action: GetGrossProfitReportSuccess) => {
      this.notificationService.success(
        this.translationService.translate('REPORTS.NOTIFICATION_MESSAGE.REPORT_IS_READY')
      );
    })
  );

  @Effect({ dispatch: false })
  grossProfitFail$ = this.actions$.pipe(
    ofType(SalesReportsActionType.GET_GROSS_PROFIT_REPORT_FAIL),
    tap((action: GetGrossProfitReportFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );

  /* ========================= GET_MAINTENANCE_STAGE_INVOICE_REPORT =================================== */
  @Effect()
  maintenanceStageInvoice$ = this.actions$.pipe(
    ofType(SalesReportsActionType.GET_MAINTENANCE_STAGE_INVOICE_REPORT),
    switchMap(({ payload }: GetMaintenanceStageInvoiceReport) =>
      this.reportsService
        .getMaintenanceStageInvoicesReport(
          payload.maintenanceStages,
          payload.locations,
          payload.fromDate,
          payload.toDate,
          payload.displayProductsInReport
        )
        .pipe(
          map((response) => new GetMaintenanceStageInvoiceReportReceived(response)),
          catchError((error) => of(new GetMaintenanceStageInvoiceReportFail(error)))
        )
    )
  );

  @Effect({ dispatch: false })
  maintenanceStageInvoiceReceived$ = this.actions$.pipe(
    ofType(SalesReportsActionType.GET_MAINTENANCE_STAGE_INVOICE_REPORT_RECEIVED),
    tap(({ payload: { data } }: GetMaintenanceStageInvoiceReportReceived) => {
      this.notificationService.info(data);
    })
  );

  @Effect({ dispatch: false })
  maintenanceStageInvoiceSuccess$ = this.actions$.pipe(
    ofType(SalesReportsActionType.GET_MAINTENANCE_STAGE_INVOICE_REPORT_SUCCESS),
    tap((action: GetMaintenanceStageInvoiceReportSuccess) => {
      this.notificationService.success(
        this.translationService.translate('REPORTS.NOTIFICATION_MESSAGE.REPORT_IS_READY')
      );
    })
  );

  @Effect({ dispatch: false })
  maintenanceStageInvoiceFail$ = this.actions$.pipe(
    ofType(SalesReportsActionType.GET_MAINTENANCE_STAGE_INVOICE_REPORT_FAIL),
    tap((action: GetMaintenanceStageInvoiceReportFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );

  /* ========================= GET_TOP_RETURNED_SALES_PRODUCTS_REPORT =================================== */
  @Effect()
  topReturnedSalesProducts$ = this.actions$.pipe(
    ofType(SalesReportsActionType.GET_TOP_RETURNED_SALES_PRODUCTS_REPORT),
    switchMap(({ payload }: GetTopReturnedSalesProductsReport) =>
      this.reportsService
        .getTopReturnedSalesProductsReport(payload.locations, payload.fromDate, payload.toDate, payload.count)
        .pipe(
          map((response) => new GetTopReturnedSalesProductsReportReceived(response)),
          catchError((error) => of(new GetTopReturnedSalesProductsReportFail(error)))
        )
    )
  );

  @Effect({ dispatch: false })
  topReturnedSalesProductsReceived$ = this.actions$.pipe(
    ofType(SalesReportsActionType.GET_TOP_RETURNED_SALES_PRODUCTS_REPORT_RECEIVED),
    tap(({ payload: { data } }: GetTopReturnedSalesProductsReportReceived) => {
      this.notificationService.info(data);
    })
  );

  @Effect({ dispatch: false })
  topReturnedSalesProductsSuccess$ = this.actions$.pipe(
    ofType(SalesReportsActionType.GET_TOP_RETURNED_SALES_PRODUCTS_REPORT_SUCCESS),
    tap((action: GetTopReturnedSalesProductsReportSuccess) => {
      this.notificationService.success(
        this.translationService.translate('REPORTS.NOTIFICATION_MESSAGE.REPORT_IS_READY')
      );
    })
  );

  @Effect({ dispatch: false })
  topReturnedSalesProductsFail$ = this.actions$.pipe(
    ofType(SalesReportsActionType.GET_TOP_RETURNED_SALES_PRODUCTS_REPORT_FAIL),
    tap((action: GetTopReturnedSalesProductsReportFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );

  /* ========================= GET_NET_SALES_REPORT =================================== */
  @Effect()
  netSales$ = this.actions$.pipe(
    ofType(SalesReportsActionType.GET_NET_SALES_REPORT),
    switchMap(({ payload }: GetNetSalesReport) =>
      this.reportsService
        .getNetSalesReport(
          payload.locations,
          payload.customers,
          payload.saleInvoiceTypes,
          payload.saleInvoiceOrderTypes,
          payload.fromDate,
          payload.toDate,
          payload.displayProducts
        )
        .pipe(
          map((response) => new GetNetSalesReportReceived(response)),
          catchError((error) => of(new GetNetSalesReportFail(error)))
        )
    )
  );

  @Effect({ dispatch: false })
  netSalesReceived$ = this.actions$.pipe(
    ofType(SalesReportsActionType.GET_NET_SALES_REPORT_RECEIVED),
    tap(({ payload: { data } }: GetNetSalesReportReceived) => {
      this.notificationService.info(data);
    })
  );

  @Effect({ dispatch: false })
  netSalesSuccess$ = this.actions$.pipe(
    ofType(SalesReportsActionType.GET_NET_SALES_REPORT_SUCCESS),
    tap((action: GetNetSalesReportSuccess) => {
      this.notificationService.success(
        this.translationService.translate('REPORTS.NOTIFICATION_MESSAGE.REPORT_IS_READY')
      );
    })
  );

  @Effect({ dispatch: false })
  netSalesFail$ = this.actions$.pipe(
    ofType(SalesReportsActionType.GET_NET_SALES_REPORT_FAIL),
    tap((action: GetNetSalesReportFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );

  /* ========================= GET_PRODUCT_SALES_REPORT =================================== */
  @Effect()
  productSales$ = this.actions$.pipe(
    ofType(SalesReportsActionType.GET_PRODUCT_SALES_REPORT),
    switchMap(({ payload }: GetProductSalesReport) =>
      this.reportsService
        .getProductSalesReport(
          payload.productId,
          payload.orderTypes,
          payload.customers,
          payload.locations,
          payload.fromDate,
          payload.toDate
        )
        .pipe(
          map((response) => new GetProductSalesReportReceived(response)),
          catchError((error) => of(new GetProductSalesReportFail(error)))
        )
    )
  );

  @Effect({ dispatch: false })
  productSalesReceived$ = this.actions$.pipe(
    ofType(SalesReportsActionType.GET_PRODUCT_SALES_REPORT_RECEIVED),
    tap(({ payload: { data } }: GetProductSalesReportReceived) => {
      this.notificationService.info(data);
    })
  );

  @Effect({ dispatch: false })
  productSalesSuccess$ = this.actions$.pipe(
    ofType(SalesReportsActionType.GET_PRODUCT_SALES_REPORT_SUCCESS),
    tap((action: GetProductSalesReportSuccess) => {
      this.notificationService.success(
        this.translationService.translate('REPORTS.NOTIFICATION_MESSAGE.REPORT_IS_READY')
      );
    })
  );

  @Effect({ dispatch: false })
  productSalesFail$ = this.actions$.pipe(
    ofType(SalesReportsActionType.GET_PRODUCT_SALES_REPORT_FAIL),
    tap((action: GetProductSalesReportFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );

  /* ========================= GET_PRODUCT_SALES_RETURNS_REPORT =================================== */
  @Effect()
  productSalesReturns$ = this.actions$.pipe(
    ofType(SalesReportsActionType.GET_PRODUCT_SALES_RETURNS_REPORT),
    switchMap(({ payload }: GetProductSalesReturnsReport) =>
      this.reportsService
        .getProductSalesReturnsReport(payload.productId, payload.locations, payload.fromDate, payload.toDate)
        .pipe(
          map((response) => new GetProductSalesReturnsReportReceived(response)),
          catchError((error) => of(new GetProductSalesReturnsReportFail(error)))
        )
    )
  );

  @Effect({ dispatch: false })
  productSalesReturnsReceived$ = this.actions$.pipe(
    ofType(SalesReportsActionType.GET_PRODUCT_SALES_RETURNS_REPORT_RECEIVED),
    tap(({ payload: { data } }: GetProductSalesReturnsReportReceived) => {
      this.notificationService.info(data);
    })
  );

  @Effect({ dispatch: false })
  productSalesReturnsSuccess$ = this.actions$.pipe(
    ofType(SalesReportsActionType.GET_PRODUCT_SALES_RETURNS_REPORT_SUCCESS),
    tap((action: GetProductSalesReturnsReportSuccess) => {
      this.notificationService.success(
        this.translationService.translate('REPORTS.NOTIFICATION_MESSAGE.REPORT_IS_READY')
      );
    })
  );

  @Effect({ dispatch: false })
  productSalesReturnsFail$ = this.actions$.pipe(
    ofType(SalesReportsActionType.GET_PRODUCT_SALES_RETURNS_REPORT_FAIL),
    tap((action: GetProductSalesReturnsReportFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );

  /* ========================= GET_PRODUCTS_CLASSES_SALES_REPORT =================================== */
  @Effect()
  productsClassesSales$ = this.actions$.pipe(
    ofType(SalesReportsActionType.GET_PRODUCT_CLASSES_SALES_REPORT),
    switchMap(({ payload }: GetProductClassesSalesReport) =>
      this.reportsService
        .getProductClassesSalesReport(
          payload.locations,
          payload.productClasses,
          payload.users,
          payload.fromDate,
          payload.toDate
        )
        .pipe(
          map((response) => new GetProductClassesSalesReportReceived(response)),
          catchError((error) => of(new GetProductClassesSalesReportFail(error)))
        )
    )
  );

  @Effect({ dispatch: false })
  productsClassesSalesReceived$ = this.actions$.pipe(
    ofType(SalesReportsActionType.GET_PRODUCT_CLASSES_SALES_REPORT_RECEIVED),
    tap(({ payload: { data } }: GetProductClassesSalesReportReceived) => {
      this.notificationService.info(data);
    })
  );

  @Effect({ dispatch: false })
  productsClassesSalesSuccess$ = this.actions$.pipe(
    ofType(SalesReportsActionType.GET_PRODUCT_CLASSES_SALES_REPORT_SUCCESS),
    tap((action: GetProductClassesSalesReportSuccess) => {
      this.notificationService.success(
        this.translationService.translate('REPORTS.NOTIFICATION_MESSAGE.REPORT_IS_READY')
      );
    })
  );

  @Effect({ dispatch: false })
  productsClassesSalesFail$ = this.actions$.pipe(
    ofType(SalesReportsActionType.GET_PRODUCT_CLASSES_SALES_REPORT_FAIL),
    tap((action: GetProductClassesSalesReportFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );

  /* ========================= GET_PRODUCTS_SALES_REPORT =================================== */
  @Effect()
  productsSales$ = this.actions$.pipe(
    ofType(SalesReportsActionType.GET_PRODUCTS_SALES_REPORT),
    switchMap(({ payload }: GetProductsSalesReport) =>
      this.reportsService
        .getProductsSalesReport(
          payload.products,
          payload.productClasses,
          payload.orderTypes,
          payload.customers,
          payload.locations,
          payload.users,
          payload.fromDate,
          payload.toDate
        )
        .pipe(
          map((response) => new GetProductsSalesReportReceived(response)),
          catchError((error) => of(new GetProductsSalesReportFail(error)))
        )
    )
  );

  @Effect({ dispatch: false })
  productsSalesReceived$ = this.actions$.pipe(
    ofType(SalesReportsActionType.GET_PRODUCTS_SALES_REPORT_RECEIVED),
    tap(({ payload: { data } }: GetProductsSalesReportReceived) => {
      this.notificationService.info(data);
    })
  );

  @Effect({ dispatch: false })
  productsSalesSuccess$ = this.actions$.pipe(
    ofType(SalesReportsActionType.GET_PRODUCTS_SALES_REPORT_SUCCESS),
    tap((action: GetProductsSalesReportSuccess) => {
      this.notificationService.success(
        this.translationService.translate('REPORTS.NOTIFICATION_MESSAGE.REPORT_IS_READY')
      );
    })
  );

  @Effect({ dispatch: false })
  productsSalesFail$ = this.actions$.pipe(
    ofType(SalesReportsActionType.GET_PRODUCTS_SALES_REPORT_FAIL),
    tap((action: GetProductsSalesReportFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );

  /* ========================= GET_PRODUCTS_SALES_RETURNS_REPORT =================================== */
  @Effect()
  productsSalesReturns$ = this.actions$.pipe(
    ofType(SalesReportsActionType.GET_PRODUCTS_SALES_RETURNS_REPORT),
    switchMap(({ payload }: GetProductsSalesReturnsReport) =>
      this.reportsService
        .getProductsSalesReturnsReport(
          payload.products,
          payload.productClasses,
          payload.locations,
          payload.users,
          payload.fromDate,
          payload.toDate
        )
        .pipe(
          map((response) => new GetProductsSalesReturnsReportReceived(response)),
          catchError((error) => of(new GetProductsSalesReturnsReportFail(error)))
        )
    )
  );

  @Effect({ dispatch: false })
  productsSalesReturnsReceived$ = this.actions$.pipe(
    ofType(SalesReportsActionType.GET_PRODUCTS_SALES_RETURNS_REPORT_RECEIVED),
    tap(({ payload: { data } }: GetProductsSalesReturnsReportReceived) => {
      this.notificationService.info(data);
    })
  );

  @Effect({ dispatch: false })
  productsSalesReturnsSuccess$ = this.actions$.pipe(
    ofType(SalesReportsActionType.GET_PRODUCTS_SALES_RETURNS_REPORT_SUCCESS),
    tap((action: GetProductsSalesReturnsReportSuccess) => {
      this.notificationService.success(
        this.translationService.translate('REPORTS.NOTIFICATION_MESSAGE.REPORT_IS_READY')
      );
    })
  );

  @Effect({ dispatch: false })
  productsSalesReturnsFail$ = this.actions$.pipe(
    ofType(SalesReportsActionType.GET_PRODUCTS_SALES_RETURNS_REPORT_FAIL),
    tap((action: GetProductsSalesReturnsReportFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );

  /* ========================= GET_SALES_INVOICES_REPORT =================================== */
  @Effect()
  sales$ = this.actions$.pipe(
    ofType(SalesReportsActionType.GET_SALES_INVOICES_REPORT),
    switchMap(({ payload }: GetSalesInvoicesReport) =>
      this.reportsService
        .getSalesInvoicesReport(
          payload.locations,
          payload.customers,
          payload.saleInvoiceTypes,
          payload.saleInvoiceOrderTypes,
          payload.fromDate,
          payload.toDate,
          payload.displayProducts
        )
        .pipe(
          map((response) => new GetSalesInvoicesReportReceived(response)),
          catchError((error) => of(new GetSalesInvoicesReportFail(error)))
        )
    )
  );

  @Effect({ dispatch: false })
  salesReceived$ = this.actions$.pipe(
    ofType(SalesReportsActionType.GET_SALES_INVOICES_REPORT_RECEIVED),
    tap(({ payload: { data } }: GetSalesInvoicesReportReceived) => {
      this.notificationService.info(data);
    })
  );

  @Effect({ dispatch: false })
  salesSuccess$ = this.actions$.pipe(
    ofType(SalesReportsActionType.GET_SALES_INVOICES_REPORT_SUCCESS),
    tap((action: GetSalesInvoicesReportSuccess) => {
      this.notificationService.success(
        this.translationService.translate('REPORTS.NOTIFICATION_MESSAGE.REPORT_IS_READY')
      );
    })
  );

  @Effect({ dispatch: false })
  salesFail$ = this.actions$.pipe(
    ofType(SalesReportsActionType.GET_SALES_INVOICES_REPORT_FAIL),
    tap((action: GetSalesInvoicesReportFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );

  /* ========================= GET_SALES_RETURNS_REPORT =================================== */
  @Effect()
  salesReturns$ = this.actions$.pipe(
    ofType(SalesReportsActionType.GET_SALES_RETURNS_REPORT),
    switchMap(({ payload }: GetSalesReturnsReport) =>
      this.reportsService
        .getSalesReturnsReport(
          payload.locations,
          payload.customers,
          payload.fromDate,
          payload.toDate,
          payload.displayProducts
        )
        .pipe(
          map((response) => new GetSalesReturnsReportReceived(response)),
          catchError((error) => of(new GetSalesReturnsReportFail(error)))
        )
    )
  );

  @Effect({ dispatch: false })
  salesReturnsReceived$ = this.actions$.pipe(
    ofType(SalesReportsActionType.GET_SALES_RETURNS_REPORT_RECEIVED),
    tap(({ payload: { data } }: GetSalesReturnsReportReceived) => {
      this.notificationService.info(data);
    })
  );

  @Effect({ dispatch: false })
  salesReturnsSuccess$ = this.actions$.pipe(
    ofType(SalesReportsActionType.GET_SALES_RETURNS_REPORT_SUCCESS),
    tap((action: GetSalesReturnsReportSuccess) => {
      this.notificationService.success(
        this.translationService.translate('REPORTS.NOTIFICATION_MESSAGE.REPORT_IS_READY')
      );
    })
  );

  @Effect({ dispatch: false })
  salesReturnsFail$ = this.actions$.pipe(
    ofType(SalesReportsActionType.GET_SALES_RETURNS_REPORT_FAIL),
    tap((action: GetSalesReturnsReportFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );

  /* ========================= GET_SALES_SUMMARY_REPORT =================================== */
  @Effect()
  salesSummary$ = this.actions$.pipe(
    ofType(SalesReportsActionType.GET_SALES_SUMMARY_REPORT),
    switchMap(({ payload }: GetSalesSummaryReport) =>
      this.reportsService
        .getSalesSummaryReport(payload.locations, payload.customers, payload.types, payload.fromDate, payload.toDate)
        .pipe(
          map((response) => new GetSalesSummaryReportReceived(response)),
          catchError((error) => of(new GetSalesSummaryReportFail(error)))
        )
    )
  );

  @Effect({ dispatch: false })
  salesSummaryReceived$ = this.actions$.pipe(
    ofType(SalesReportsActionType.GET_SALES_SUMMARY_REPORT_RECEIVED),
    tap(({ payload: { data } }: GetSalesSummaryReportReceived) => {
      this.notificationService.info(data);
    })
  );

  @Effect({ dispatch: false })
  salesSummarySuccess$ = this.actions$.pipe(
    ofType(SalesReportsActionType.GET_SALES_SUMMARY_REPORT_SUCCESS),
    tap((action: GetSalesSummaryReportSuccess) => {
      this.notificationService.success(
        this.translationService.translate('REPORTS.NOTIFICATION_MESSAGE.REPORT_IS_READY')
      );
    })
  );

  @Effect({ dispatch: false })
  salesSummaryFail$ = this.actions$.pipe(
    ofType(SalesReportsActionType.GET_SALES_SUMMARY_REPORT_FAIL),
    tap((action: GetSalesSummaryReportFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );

  /* ========================= GET_SHIFT_SUMMARY_REPORT =================================== */
  @Effect()
  shift$ = this.actions$.pipe(
    ofType(SalesReportsActionType.GET_SHIFT_SUMMARY_REPORT),
    switchMap(({ payload }: GetShiftSummaryReport) =>
      this.reportsService.getShiftSummaryReport(payload.shiftId, payload.paperSize).pipe(
        map((response) => new GetShiftSummaryReportReceived(response)),
        catchError((error) => of(new GetShiftSummaryReportFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  shiftReceived$ = this.actions$.pipe(
    ofType(SalesReportsActionType.GET_SHIFT_SUMMARY_REPORT_RECEIVED),
    tap(({ payload: { data } }: GetShiftSummaryReportReceived) => {
      this.notificationService.info(data);
    })
  );

  @Effect({ dispatch: false })
  shiftSuccess$ = this.actions$.pipe(
    ofType(SalesReportsActionType.GET_SHIFT_SUMMARY_REPORT_SUCCESS),
    tap((action: GetShiftSummaryReportSuccess) => {
      this.notificationService.success(
        this.translationService.translate('REPORTS.NOTIFICATION_MESSAGE.REPORT_IS_READY')
      );
    })
  );

  @Effect({ dispatch: false })
  shiftFail$ = this.actions$.pipe(
    ofType(SalesReportsActionType.GET_SHIFT_SUMMARY_REPORT_FAIL),
    tap((action: GetShiftSummaryReportFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );

  /* ========================= GET_TOP_CUSTOMERS_REPORT =================================== */
  @Effect()
  topCustomers$ = this.actions$.pipe(
    ofType(SalesReportsActionType.GET_TOP_CUSTOMERS_REPORT),
    switchMap(({ payload }: GetTopCustomersReport) =>
      this.reportsService
        .getTopCustomersReport(payload.count, payload.locations, payload.fromDate, payload.toDate)
        .pipe(
          map((response) => new GetTopCustomersReportReceived(response)),
          catchError((error) => of(new GetTopCustomersReportFail(error)))
        )
    )
  );

  @Effect({ dispatch: false })
  topCustomersReceived$ = this.actions$.pipe(
    ofType(SalesReportsActionType.GET_TOP_CUSTOMERS_REPORT_RECEIVED),
    tap(({ payload: { data } }: GetTopCustomersReportReceived) => {
      this.notificationService.info(data);
    })
  );

  @Effect({ dispatch: false })
  topCustomersSuccess$ = this.actions$.pipe(
    ofType(SalesReportsActionType.GET_TOP_CUSTOMERS_REPORT_SUCCESS),
    tap((action: GetTopCustomersReportSuccess) => {
      this.notificationService.success(
        this.translationService.translate('REPORTS.NOTIFICATION_MESSAGE.REPORT_IS_READY')
      );
    })
  );

  @Effect({ dispatch: false })
  topCustomersFail$ = this.actions$.pipe(
    ofType(SalesReportsActionType.GET_TOP_CUSTOMERS_REPORT_FAIL),
    tap((action: GetTopCustomersReportFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );

  /* ========================= GET_TOP_SELLING_PRODUCTS_REPORT =================================== */
  @Effect()
  topSellingProducts$ = this.actions$.pipe(
    ofType(SalesReportsActionType.GET_TOP_SELLING_PRODUCTS_REPORT),
    switchMap(({ payload }: GetTopSellingProductsReport) =>
      this.reportsService
        .getTopSellingProductsReport(payload.fromDate, payload.toDate, payload.count, payload.locations)
        .pipe(
          map((response) => new GetTopSellingProductsReportReceived(response)),
          catchError((error) => of(new GetTopSellingProductsReportFail(error)))
        )
    )
  );

  @Effect({ dispatch: false })
  topSellingProductsReceived$ = this.actions$.pipe(
    ofType(SalesReportsActionType.GET_TOP_SELLING_PRODUCTS_REPORT_RECEIVED),
    tap(({ payload: { data } }: GetTopSellingProductsReportReceived) => {
      this.notificationService.info(data);
    })
  );

  @Effect({ dispatch: false })
  topSellingProductsSuccess$ = this.actions$.pipe(
    ofType(SalesReportsActionType.GET_TOP_SELLING_PRODUCTS_REPORT_SUCCESS),
    tap((action: GetTopSellingProductsReportSuccess) => {
      this.notificationService.success(
        this.translationService.translate('REPORTS.NOTIFICATION_MESSAGE.REPORT_IS_READY')
      );
    })
  );

  @Effect({ dispatch: false })
  topSellingProductsFail$ = this.actions$.pipe(
    ofType(SalesReportsActionType.GET_TOP_SELLING_PRODUCTS_REPORT_FAIL),
    tap((action: GetTopSellingProductsReportFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );

  /* ========================= GET_WORST_CUSTOMERS_REPORT =================================== */
  @Effect()
  worstCustomers$ = this.actions$.pipe(
    ofType(SalesReportsActionType.GET_WORST_CUSTOMERS_REPORT),
    switchMap(({ payload }: GetWorstCustomersReport) =>
      this.reportsService
        .getWorstCustomersReport(payload.count, payload.locations, payload.fromDate, payload.toDate)
        .pipe(
          map((response) => new GetWorstCustomersReportReceived(response)),
          catchError((error) => of(new GetWorstCustomersReportFail(error)))
        )
    )
  );

  @Effect({ dispatch: false })
  worstCustomersReceived$ = this.actions$.pipe(
    ofType(SalesReportsActionType.GET_WORST_CUSTOMERS_REPORT_RECEIVED),
    tap(({ payload: { data } }: GetWorstCustomersReportReceived) => {
      this.notificationService.info(data);
    })
  );

  @Effect({ dispatch: false })
  worstCustomersSuccess$ = this.actions$.pipe(
    ofType(SalesReportsActionType.GET_WORST_CUSTOMERS_REPORT_SUCCESS),
    tap((action: GetWorstCustomersReportSuccess) => {
      this.notificationService.success(
        this.translationService.translate('REPORTS.NOTIFICATION_MESSAGE.REPORT_IS_READY')
      );
    })
  );

  @Effect({ dispatch: false })
  worstCustomersFail$ = this.actions$.pipe(
    ofType(SalesReportsActionType.GET_WORST_CUSTOMERS_REPORT_FAIL),
    tap((action: GetWorstCustomersReportFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );

  /* ========================= GET_WORST_RETURNED_SALES_PRODUCTS_REPORT =================================== */
  @Effect()
  worstReturnedSalesProducts$ = this.actions$.pipe(
    ofType(SalesReportsActionType.GET_WORST_RETURNED_SALES_PRODUCTS_REPORT),
    switchMap(({ payload }: GetWorstReturnedSalesProductsReport) =>
      this.reportsService
        .getWorstReturnedSalesProductsReport(payload.fromDate, payload.toDate, payload.count, payload.locations)
        .pipe(
          map((response) => new GetWorstReturnedSalesProductsReportReceived(response)),
          catchError((error) => of(new GetWorstReturnedSalesProductsReportFail(error)))
        )
    )
  );

  @Effect({ dispatch: false })
  worstReturnedSalesProductsReceived$ = this.actions$.pipe(
    ofType(SalesReportsActionType.GET_WORST_RETURNED_SALES_PRODUCTS_REPORT_RECEIVED),
    tap(({ payload: { data } }: GetWorstReturnedSalesProductsReportReceived) => {
      this.notificationService.info(data);
    })
  );

  @Effect({ dispatch: false })
  worstReturnedSalesProductsSuccess$ = this.actions$.pipe(
    ofType(SalesReportsActionType.GET_WORST_RETURNED_SALES_PRODUCTS_REPORT_SUCCESS),
    tap((action: GetWorstReturnedSalesProductsReportSuccess) => {
      this.notificationService.success(
        this.translationService.translate('REPORTS.NOTIFICATION_MESSAGE.REPORT_IS_READY')
      );
    })
  );

  @Effect({ dispatch: false })
  worstReturnedSalesProductsFail$ = this.actions$.pipe(
    ofType(SalesReportsActionType.GET_WORST_RETURNED_SALES_PRODUCTS_REPORT_FAIL),
    tap((action: GetWorstReturnedSalesProductsReportFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );

  /* ========================= GET_WORST_SELLING_PRODUCTS_REPORT =================================== */
  @Effect()
  worstSellingProducts$ = this.actions$.pipe(
    ofType(SalesReportsActionType.GET_WORST_SELLING_PRODUCTS_REPORT),
    switchMap(({ payload }: GetWorstSellingProductsReport) =>
      this.reportsService
        .getWorstSellingProductsReport(payload.fromDate, payload.toDate, payload.count, payload.locations)
        .pipe(
          map((response) => new GetWorstSellingProductsReportReceived(response)),
          catchError((error) => of(new GetWorstSellingProductsReportFail(error)))
        )
    )
  );

  @Effect({ dispatch: false })
  worstSellingProductsReceived$ = this.actions$.pipe(
    ofType(SalesReportsActionType.GET_WORST_SELLING_PRODUCTS_REPORT_RECEIVED),
    tap(({ payload: { data } }: GetWorstSellingProductsReportReceived) => {
      this.notificationService.info(data);
    })
  );

  @Effect({ dispatch: false })
  worstSellingProductsSuccess$ = this.actions$.pipe(
    ofType(SalesReportsActionType.GET_WORST_SELLING_PRODUCTS_REPORT_SUCCESS),
    tap((action: GetWorstSellingProductsReportSuccess) => {
      this.notificationService.success(
        this.translationService.translate('REPORTS.NOTIFICATION_MESSAGE.REPORT_IS_READY')
      );
    })
  );

  @Effect({ dispatch: false })
  worstSellingProductsFail$ = this.actions$.pipe(
    ofType(SalesReportsActionType.GET_WORST_SELLING_PRODUCTS_REPORT_FAIL),
    tap((action: GetWorstSellingProductsReportFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );

  /* ========================= GET_SALES_PERSON_SALES_REPORT =================================== */
  @Effect()
  salesPerson$ = this.actions$.pipe(
    ofType(SalesReportsActionType.GET_SALES_PERSON_SALES_REPORT),
    switchMap(({ payload }: GetSalesPersonSalesReport) =>
      this.reportsService.getSalesPersonSalesReport(payload.employeeId, payload.fromDate, payload.toDate).pipe(
        map((response) => new GetSalesPersonSalesReportReceived(response)),
        catchError((error) => of(new GetSalesPersonSalesReportFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  salesPersonReceived$ = this.actions$.pipe(
    ofType(SalesReportsActionType.GET_SALES_PERSON_SALES_REPORT_RECEIVED),
    tap(({ payload: { data } }: GetSalesPersonSalesReportReceived) => {
      this.notificationService.info(data);
    })
  );

  @Effect({ dispatch: false })
  salesPersonSuccess$ = this.actions$.pipe(
    ofType(SalesReportsActionType.GET_SALES_PERSON_REPORT_SUCCESS),
    tap((action: GetSalesPersonSalesReportSuccess) => {
      this.notificationService.success(
        this.translationService.translate('REPORTS.NOTIFICATION_MESSAGE.REPORT_IS_READY')
      );
    })
  );

  @Effect({ dispatch: false })
  salesPersonFail$ = this.actions$.pipe(
    ofType(SalesReportsActionType.GET_SALES_PERSON_SALES_REPORT_FAIL),
    tap((action: GetSalesPersonSalesReportFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );
}
