import { Action } from '@ngrx/store';

import { Gender } from 'lookups/models';
import { AppHttpResponse } from 'shared';

/**
 * The Lookups-Module store gender action types.
 */
export enum GenderActionType {
  GET_ALL_GENDERS = '[Lookups] [Gender] Get All Genders',
  GET_ALL_GENDERS_FAIL = '[Lookups] [Gender] Get All Genders Fail',
  GET_ALL_GENDERS_SUCCESS = '[Lookups] [Gender] Get All Genders Success',
}

/**
 * Represents a store gender action.
 */
export class GetAllGenders implements Action {
  /**
   * The type of the action.
   */
  readonly type = GenderActionType.GET_ALL_GENDERS;

  /**
   * Fires a new gender action.
   */
  constructor() {}
}

/**
 * Represents a store gender fail action.
 */
export class GetAllGendersFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = GenderActionType.GET_ALL_GENDERS_FAIL;

  /**
   * Fires a new gender fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store gender success action.
 */
export class GetAllGendersSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = GenderActionType.GET_ALL_GENDERS_SUCCESS;

  /**
   * Fires a gender success action.
   * @param payload An object contains the list of genders.
   */
  constructor(public payload: AppHttpResponse<Gender[]>) {}
}

/**
 * Lookups-module gender action types.
 */
export type GenderActions = GetAllGenders | GetAllGendersFail | GetAllGendersSuccess;
