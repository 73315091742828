import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';

import { AppHttpResponse } from 'shared';
import { CreateBoardInput, UpdateBoardInput, Board } from 'sales/models';

/**
 * The boards services includes functionality to create, search, findById, update and delete for an board.
 */
@Injectable()
export class BoardsService {
  /**
   * The relative route for the boards.
   *
   * No leading or trailing slashes required.
   */
  private boardsApi = 'sales/board';

  constructor(private http: HttpClient) {}

  /**
   * Creates a new board from the provided data.
   * @param data The new board data.
   */
  public create(data: CreateBoardInput): Observable<AppHttpResponse<Board>> {
    return this.http.post<AppHttpResponse<Board>>(`${this.boardsApi}`, data);
  }

  /**
   * Searches in the boards by description.
   * @param description The description of the board.
   * @param page The current pagination page number.
   * @param pageSize The maximum number of boards allowed per one pagination page.
   */
  public searchInPos(description: string, page: number, pageSize: number): Observable<AppHttpResponse<Board[]>> {
    const params = new HttpParams()
      .set('description', description)
      .set('page', page.toString())
      .set('pageSize', pageSize.toString());
    return this.http.get<AppHttpResponse<Board[]>>(`${this.boardsApi}/boards-in-pos`, { params });
  }

  /**
   * Searches in the boards by description.
   * @param description The description of the board.
   * @param locations The locations of the board.
   * @param page The current pagination page number.
   * @param pageSize The maximum number of boards allowed per one pagination page.
   */
  public search(
    description: string,
    locations: number[],
    page: number,
    pageSize: number
  ): Observable<AppHttpResponse<Board[]>> {
    const params = new HttpParams()
      .set('description', description)
      .set('locations', locations.join(','))
      .set('page', page.toString())
      .set('pageSize', pageSize.toString());
    return this.http.get<AppHttpResponse<Board[]>>(`${this.boardsApi}`, { params });
  }

  /**
   * Finds the board with the given id.
   * @param id The id of the board.
   */
  public findById(id: number): Observable<AppHttpResponse<Board>> {
    return this.http.get<AppHttpResponse<Board>>(`${this.boardsApi}/${id}`);
  }

  /**
   * Updates an existing board data using the provided data.
   * @param data The updated board data.
   */
  public update(data: UpdateBoardInput): Observable<AppHttpResponse<Board>> {
    return this.http.put<AppHttpResponse<Board>>(`${this.boardsApi}`, data);
  }

  /**
   * Deletes the board by given id.
   * @param id The id of the board.
   */
  public delete(id: number): Observable<AppHttpResponse<Board>> {
    return this.http.delete<AppHttpResponse<Board>>(`${this.boardsApi}/${id}`);
  }
}
