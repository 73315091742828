import { createSelector } from '@ngrx/store';

import { AppSearchState, CustomersState } from 'app-search/store/states';
import { selectAppSearchState } from 'app-search/store/selectors/feature.selectors';

/**
 * Gets the customers state.
 */
const selectCustomersState = createSelector(selectAppSearchState, (state: AppSearchState) => state.customers);

/**
 * Gets the list of loaded customers.
 */
export const getCustomers = createSelector(selectCustomersState, (state: CustomersState) => state.data);

/**
 * Gets the customers pagination info.
 */
export const getCustomersPaginationInfo = createSelector(
  selectCustomersState,
  (state: CustomersState) => state.paginationInfo
);

/**
 * Gets the error that occurred in the state.
 */
export const getCustomersError = createSelector(selectCustomersState, (state: CustomersState) => state.error);

/**
 * Determines if there is a running search process.
 */
export const getCustomersSearching = createSelector(selectCustomersState, (state: CustomersState) => state.isSearching);

/**
 * Determines if the last search process has been ended successfully.
 */
export const getCustomersSearchCompleted = createSelector(
  selectCustomersState,
  (state: CustomersState) => state.isSearchCompleted
);
