<input
  type="file"
  hidden
  #fileInput
  [id]="id"
  (change)="onSelectedFileChanged($event.target.files[0])"
  [disabled]="isDisabled"
/>

<div class="file-upload">
  <div class="preview-wrapper">
    <div class="blank" *ngIf="!file">
      <i
        class="fa fa-folder-open toggle c-secondary"
        (click)="openBrowse(fileInput)"
        [ngbTooltip]="'SHARED.UPLOAD_FILE' | translate"
      ></i>
    </div>
    <div class="preview" *ngIf="previewUrl" [ngbTooltip]="file?.name">
      <img class="preview-img" *ngIf="isImage" [src]="previewUrl" (click)="openBrowse(fileInput)" />
      <i class="preview-file fa fa-file-pdf-o" *ngIf="isPdf" (click)="openBrowse(fileInput)"></i>
      <i class="preview-file fa fa-file-word-o" *ngIf="isMSWord" (click)="openBrowse(fileInput)"></i>
      <i class="preview-file fa fa-file-excel-o" *ngIf="isMSExcel" (click)="openBrowse(fileInput)"></i>
      <i class="preview-file fa fa-file-text-o" *ngIf="isText" (click)="openBrowse(fileInput)"></i>
      <i class="preview-file fa fa-file-archive-o" *ngIf="isArchive" (click)="openBrowse(fileInput)"></i>
      <i class="preview-file fa fa-file" *ngIf="isUnknownType" (click)="openBrowse(fileInput)"></i>
    </div>
  </div>
  <i
    class="fa fa-times-circle remove c-danger"
    *ngIf="previewUrl"
    (click)="file = null; previewUrl = null"
    [ngbTooltip]="'SHARED.DELETE_FILE' | translate"
  ></i>
</div>
