import { Injectable } from '@angular/core';

import { of } from 'rxjs';
import { switchMap, map, catchError } from 'rxjs/operators';
import { Effect, ofType, Actions } from '@ngrx/effects';

import { StatisticsService } from 'statistics/services';
import {
  StatisticsActionType,
  LoadStatisticsFail,
  LoadStatisticsSuccess,
  LoadStatisticsChartsFail,
  LoadStatisticsChartsSuccess,
  LoadStatisticsCharts,
  LoadStatistics,
} from 'statistics/store/actions';

@Injectable()
export class StatisticsEffects {
  constructor(private actions$: Actions, private statisticsService: StatisticsService) {}

  /* ========================= LOAD_STATISTICS =================================== */
  @Effect()
  get$ = this.actions$.pipe(
    ofType(StatisticsActionType.LOAD_STATISTICS),
    switchMap(({ payload }: LoadStatistics) => {
      return this.statisticsService
        .get(
          payload?.day,
          payload?.month,
          payload?.year,
          payload?.isDay,
          payload?.isWeek,
          payload?.isMonth,
          payload?.locations
        )
        .pipe(
          map((response) => new LoadStatisticsSuccess(response)),
          catchError((error) => of(new LoadStatisticsFail(error)))
        );
    })
  );

  /* ========================= LOAD_STATISTICS_CHARTS =================================== */
  @Effect()
  getStatisticsCharts$ = this.actions$.pipe(
    ofType(StatisticsActionType.LOAD_STATISTICS_CHARTS),
    switchMap(({ payload }: LoadStatisticsCharts) => {
      return this.statisticsService
        .getStatisticsCharts(
          payload?.day,
          payload?.month,
          payload?.year,
          payload?.isDay,
          payload?.isWeek,
          payload?.isMonth,
          payload?.locations
        )
        .pipe(
          map((response) => new LoadStatisticsChartsSuccess(response)),
          catchError((error) => of(new LoadStatisticsChartsFail(error)))
        );
    })
  );
}
