import { Action } from '@ngrx/store';

import { VariantCategory } from 'lookups/models';
import { AppHttpResponse } from 'shared';

/**
 * The Lookups-Module store variant category action types.
 */
export enum VariantCategoryActionType {
  GET_ALL_VARIANT_CATEGORIES = '[Lookups] [VariantCategory] Get All Variant Categories',
  GET_ALL_VARIANT_CATEGORIES_FAIL = '[Lookups] [VariantCategory] Get All Variant Categories Fail',
  GET_ALL_VARIANT_CATEGORIES_SUCCESS = '[Lookups] [VariantCategory] Get All Variant Categories Success',
}

/**
 * Represents a store variant category action.
 */
export class GetAllVariantCategories implements Action {
  /**
   * The type of the action.
   */
  readonly type = VariantCategoryActionType.GET_ALL_VARIANT_CATEGORIES;

  /**
   * Fires a new variant category action.
   */
  constructor() {}
}

/**
 * Represents a store variant category fail action.
 */
export class GetAllVariantCategoriesFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = VariantCategoryActionType.GET_ALL_VARIANT_CATEGORIES_FAIL;

  /**
   * Fires a new variant category fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store variant category success action.
 */
export class GetAllVariantCategoriesSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = VariantCategoryActionType.GET_ALL_VARIANT_CATEGORIES_SUCCESS;

  /**
   * Fires a variant category success action.
   * @param payload An object contains the list of  variant categories.
   */
  constructor(public payload: AppHttpResponse<VariantCategory[]>) {}
}

/**
 * Lookups-module variant category action types.
 */
export type VariantCategoryActions =
  | GetAllVariantCategories
  | GetAllVariantCategoriesFail
  | GetAllVariantCategoriesSuccess;
