<ng-container [formGroup]="payrollGroup">
  <th scope="row">{{ index + 1 }}</th>
  <td class="fit-width" *ngIf="!isEmployee">
    {{ payrollGroup?.value?.employee?.employeeAssignmentDetail.personNumber }}
  </td>
  <td class="fit-width" *ngIf="!isEmployee">
    {{
      payrollGroup?.value?.employee?.employeePersonalDetail.fullName
        | localize: payrollGroup?.value?.employee?.employeePersonalDetail.fullNameEn
        | async
    }}
  </td>
  <td class="fit-width" *ngIf="!isEmployee">
    {{
      payrollGroup?.value?.employee?.employeeAssignmentDetail.position.name
        | localize: payrollGroup?.value?.employee?.employeeAssignmentDetail.position.nameEn
        | async
    }}
  </td>
  <td class="fit-width" *ngIf="!isEmployee">
    {{
      payrollGroup?.value?.employee?.employeeAssignmentDetail.department.name
        | localize: payrollGroup?.value?.employee?.employeeAssignmentDetail.department.nameEn
        | async
    }}
  </td>
  <td class="fit-width" *ngIf="!isEmployee">
    {{
      payrollGroup?.value?.employee?.employeeAssignmentDetail.location.name
        | localize: payrollGroup?.value?.employee?.employeeAssignmentDetail.location.nameEn
        | async
    }}
  </td>
  <td class="fit-width">
    {{ payrollGroup?.value?.net | toDecimal }}
  </td>
  <td class="fit-width">
    <span
      >{{ payrollGroup?.value?.salary | toDecimal }}
      <a
        *ngIf="payrollGroup?.value?.payrollRecurringElements.length"
        class="info px-1"
        (click)="
          selectedPayrollElement = payrollGroup?.value?.payrollRecurringElements;
          payrollRecurringElementsListVisibility = true
        "
        [ngbTooltip]="
          'FINANCES.HR.PAID_PAYROLLS.PAID_PAYROLLS_DATA.PAYROLL_RECURRING_ELEMENTS_INFO_DISPLAY' | translate
        "
      >
        <i class="fa fa-info font-medium-3"></i> </a
    ></span>
    <span class="text-danger d-block" *ngIf="payrollGroup?.value?.workDays">
      ({{ payrollGroup?.value?.workDays }})
      {{ 'FINANCES.HR.PAID_PAYROLLS.PAID_PAYROLLS_DATA.DAY' | translate }}
    </span>
  </td>
  <td class="fit-width">
    {{ payrollGroup?.value?.salaryForGosi | toDecimal }}
    <a
      *ngIf="payrollGroup?.value?.payrollGosiPayrollElements.length"
      class="info px-2"
      (click)="
        selectedPayrollGosiPayrollElements = payrollGroup?.value?.payrollGosiPayrollElements;
        gosiPayrollElementsListVisibility = true
      "
      [ngbTooltip]="'FINANCES.HR.PAID_PAYROLLS.PAID_PAYROLLS_DATA.GOSI_PAYROLL_ELEMENTS_INFO_DISPLAY' | translate"
    >
      <i class="fa fa-info font-medium-3 mr-2"></i>
    </a>
  </td>
  <td class="fit-width">
    {{ payrollGroup?.value?.employeeGosi | toDecimal }}
  </td>
  <td class="fit-width">
    {{ payrollGroup?.value?.employerGosi | toDecimal }}
  </td>
  <td class="fit-width">
    {{ payrollGroup?.value?.employeeSponsor?.name | localize: payrollGroup?.value?.employeeSponsor?.nameEn | async }}
  </td>
  <td class="fit-width">
    <span>{{ payrollGroup?.value?.loanSettlement | toDecimal }}</span>
    <a
      *ngIf="payrollGroup?.value?.payrollLoanSettlements.length"
      class="info px-2"
      (click)="
        selectedPayrollLoanSettlements = payrollGroup?.value?.payrollLoanSettlements;
        payrollLoanSettlementsListVisibility = true
      "
      [ngbTooltip]="'FINANCES.HR.PAID_PAYROLLS.PAID_PAYROLLS_DATA.LOANS_INFO_DISPLAY' | translate"
    >
      <i class="fa fa-info font-medium-3 mr-2"></i>
    </a>
  </td>
  <td class="fit-width">
    <span>{{ payrollGroup?.value?.otherEarnings | toDecimal }}</span>
    <a
      *ngIf="payrollGroup?.value?.payrollOtherEarnings.length"
      class="info px-2"
      (click)="
        selectedPayrollOtherEarnings = payrollGroup?.value?.payrollOtherEarnings;
        payrollOtherEarningsListVisibility = true
      "
      [ngbTooltip]="'FINANCES.HR.PAID_PAYROLLS.PAID_PAYROLLS_DATA.OTHER_EARNINGS_INFO_DISPLAY' | translate"
    >
      <i class="fa fa-info font-medium-3 mr-2"></i>
    </a>
  </td>
  <td class="fit-width">
    <span>{{ payrollGroup?.value?.deductions | toDecimal }}</span>
    <a
      *ngIf="payrollGroup?.value?.payrollDeductions.length"
      class="info px-2"
      (click)="
        selectedPayrollDeductions = payrollGroup?.value?.payrollDeductions; payrollDeductionsListVisibility = true
      "
      [ngbTooltip]="'FINANCES.HR.PAID_PAYROLLS.PAID_PAYROLLS_DATA.DEDUCTIONS_INFO_DISPLAY' | translate"
    >
      <i class="fa fa-info font-medium-3 mr-2"></i>
    </a>
  </td>
  <td class="fit-width">
    <span>{{ payrollGroup?.value?.overtime | toDecimal }}</span>
    <a
      *ngIf="payrollGroup?.value?.payrollOvertimes.length"
      class="info px-2"
      (click)="selectedPayrollOvertimes = payrollGroup?.value?.payrollOvertimes; payrollOvertimesListVisibility = true"
      [ngbTooltip]="'FINANCES.HR.PAID_PAYROLLS.PAID_PAYROLLS_DATA.OVER_TIMES_INFO_DISPLAY' | translate"
    >
      <i class="fa fa-info font-medium-3 mr-2"></i>
    </a>
  </td>
  <td class="fit-width">
    <a
      *ngIf="payrollGroup?.value?.payrollLocationCosts.length"
      class="info px-2"
      (click)="
        selectedPayrollLocationCosts = payrollGroup?.value?.payrollLocationCosts;
        payrollLocationsCostsListVisibility = true
      "
      [ngbTooltip]="'FINANCES.HR.PAID_PAYROLLS.PAID_PAYROLLS_DATA.LOCATIONS_COSTS_INFO_DISPLAY' | translate"
    >
      <i class="fa fa-info font-medium-3 mr-2"></i>
    </a>
  </td>
  <td class="fit-width">
    <a
      *ngIf="payrollGroup?.value?.payrollProjectCosts.length"
      class="info px-2"
      (click)="
        selectedPayrollProjectCosts = payrollGroup?.value?.payrollProjectCosts;
        payrollProjectsCostsListVisibility = true
      "
      [ngbTooltip]="'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.PROJECTS_COSTS_INFO_DISPLAY' | translate"
    >
      <i class="fa fa-info font-medium-3 mr-2"></i>
    </a>
  </td>
  <td class="fit-width">
    <a
      *ngIf="payrollGroup?.value?.payrollProvisions.length"
      class="info px-2"
      (click)="
        selectedPayrollProvisions = payrollGroup?.value?.payrollProvisions; payrollProvisionsListVisibility = true
      "
      [ngbTooltip]="'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.PROVISIONS_INFO_DISPLAY' | translate"
    >
      <i class="fa fa-info font-medium-3 mr-2"></i>
    </a>
  </td>
  <td class="fit-width">
    <a
      *ngIf="payrollGroup?.value?.payrollUnpaidLeaves.length"
      class="info px-2"
      (click)="
        selectedPayrollUnpaidLeaves = payrollGroup?.value?.payrollUnpaidLeaves; payrollUnpaidLeavesListVisibility = true
      "
      [ngbTooltip]="'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.UNPAID_LEAVES_INFO_DISPLAY' | translate"
    >
      <i class="fa fa-info font-medium-3 mr-2"></i>
    </a>
  </td>
  <td class="min-width-13" *ngIf="!inReview">
    <div class="input-group round">
      <input
        class="form-control"
        formControlName="transactionDate"
        [placeholder]="'SHARED.SEARCH.DATE_PLACEHOLDER' | translate"
        placement="bottom"
        ngbDatepicker
        #transactionDate="ngbDatepicker"
        (closed)="dropDownIsOpened.emit(false)"
      />
      <div class="input-group-append">
        <span
          class="input-group-text gradient-light-blue-indigo"
          style="cursor: pointer;"
          (click)="dropDownIsOpened.emit(true); transactionDate.toggle()"
        >
          <i class="fa fa-calendar"></i>
        </span>
      </div>
    </div>
  </td>
  <td class="fit-width" *ngIf="inReview">
    <span [ngbTooltip]="payrollGroup?.value?.transactionDate | date: 'time'">
      {{ payrollGroup?.value?.transactionDate | date }}
    </span>
  </td>
  <td class="min-width-20" *ngIf="!inReview">
    <textarea
      autocomplete="off"
      class="form-control round"
      [placeholder]="'FINANCES.HR.PAID_PAYROLLS.PAID_PAYROLLS_DATA.NOTES_PLACEHOLDER' | translate"
      rows="1"
      minlength="1"
      maxlength="100"
      formControlName="notes"
    ></textarea>
  </td>
  <td *ngIf="inReview">{{ payrollGroup?.value?.notes }}</td>
  <td class="fit-width">
    <a
      *ngIf="!inReview"
      class="info px-2"
      (click)="openModal(addAttachmentsModalRef)"
      [ngbTooltip]="'SHARED.ADD_ATTACHMENTS' | translate"
    >
      <i class="fa fa-paperclip font-medium-3 mr-2"></i>
      <span *ngIf="attachmentsForm.controls.length">{{ attachmentsForm.controls.length }}</span>
    </a>
    <a
      class="info px-2"
      (click)="selectedPayroll = payrollGroup.value; payrollInfoVisibility = true"
      [ngbTooltip]="'FINANCES.HR.PAID_PAYROLLS.PAID_PAYROLLS_DATA.INFO_DISPLAY' | translate"
    >
      <i class="fa fa-info font-medium-3 mr-2"></i>
    </a>
    <a
      *ngIf="([Claims.CREATE_EMPLOYEE] | authorize | async) && !isEmployee"
      class="info px-2"
      [routerLink]="['/hr/employees/files', payrollGroup.value?.employee.id]"
      fragment="payrolls"
      [ngbTooltip]="'HR.EMPLOYEES.EMPLOYEE_DATA.EMPLOYEE_FILE' | translate"
    >
      <i class="fa fa-folder font-medium-3 mr-2"></i>
    </a>
    <a class="danger px-2" [ngbTooltip]="'SHARED.CONFIRM.DELETE' | translate" (click)="remove()">
      <i class="fa fa-close font-medium-3 mr-2"></i>
    </a>
  </td>
</ng-container>

<!-- add attachments modal starts -->
<ng-template #addAttachmentsModalRef [formGroup]="payrollGroup" let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title text-info" id="modal-basic-title">
      {{ 'FINANCES.HR.PAID_PAYROLLS.PAID_PAYROLLS_DATA.ATTACHMENTS' | translate }}
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="d()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="card-content">
        <div class="px-3">
          <div class="row">
            <div class="form-group col-md-12">
              <!-- request attachments starts -->
              <div class="card-content">
                <div class="px-3">
                  <app-files-upload formArrayName="attachments" [filesForm]="attachmentsForm"></app-files-upload>
                </div>
              </div>
              <!-- request attachments ends -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="modal-actions">
      <button type="button" class="btn btn-raised btn-primary round" (click)="c()" ngbAutoFocus>
        <i class="fa fa-times"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.CLOSE' | translate }}</span>
      </button>
    </div>
  </div>
</ng-template>
<!-- add attachments modal ends -->

<!-- payroll info starts -->
<app-payroll-info
  [payroll]="selectedPayroll"
  [inReview]="inReview"
  [(visible)]="payrollInfoVisibility"
></app-payroll-info>
<!-- payroll info ends -->

<!-- payroll recurring element modal starts -->
<app-payroll-recurring-elements-info
  [payrollRecurringElements]="selectedPayrollElement"
  [(visible)]="payrollRecurringElementsListVisibility"
></app-payroll-recurring-elements-info>
<!-- payroll recurring element modal ends -->

<!-- gosi payroll modal starts -->
<app-payroll-gosi-payroll-elements-info
  [payrollGosiPayrollElements]="selectedPayrollGosiPayrollElements"
  [(visible)]="gosiPayrollElementsListVisibility"
></app-payroll-gosi-payroll-elements-info>
<!-- gosi payroll modal ends -->

<!-- payroll loan settlement modal starts -->
<app-payroll-loan-settlements-info
  [payrollLoanSettlements]="selectedPayrollLoanSettlements"
  [(visible)]="payrollLoanSettlementsListVisibility"
></app-payroll-loan-settlements-info>
<!-- payroll modal ends -->

<!-- payroll other earning modal starts -->
<app-payroll-other-earnings-info
  [payrollOtherEarnings]="selectedPayrollOtherEarnings"
  [(visible)]="payrollOtherEarningsListVisibility"
></app-payroll-other-earnings-info>
<!-- payroll other earning modal ends -->

<!-- payroll deduction modal starts -->
<app-payroll-deductions-info
  [payrollDeductions]="selectedPayrollDeductions"
  [(visible)]="payrollDeductionsListVisibility"
></app-payroll-deductions-info>
<!-- payroll deduction modal ends -->

<!-- payroll overtimes modal starts -->
<app-payroll-overtimes-info
  [payrollOvertimes]="selectedPayrollOvertimes"
  [(visible)]="payrollOvertimesListVisibility"
></app-payroll-overtimes-info>
<!-- payroll overtimes modal ends -->

<!-- payroll locations costs modal starts -->
<app-payroll-locations-costs-info
  [payrollLocationCosts]="selectedPayrollLocationCosts"
  [(visible)]="payrollLocationsCostsListVisibility"
></app-payroll-locations-costs-info>
<!-- payroll locations costs modal ends -->

<!-- payroll element formulas modal starts -->
<payroll-element-formulas
  [payrollElement]="selectedPayrollElement"
  [payrollElementTypeKey]="payrollElementKey"
  [(visible)]="payrollElementFormulasListVisibility"
>
</payroll-element-formulas>
<!-- payroll element formulas modal ends -->

<!-- payroll projects costs modal starts -->
<app-payroll-projects-costs-info
  [payrollProjectCosts]="selectedPayrollProjectCosts"
  [(visible)]="payrollProjectsCostsListVisibility"
></app-payroll-projects-costs-info>
<!-- payroll projects costs modal ends -->

<!-- payroll provisions modal starts -->
<app-payroll-provisions-info
  [payrollProvisions]="selectedPayrollProvisions"
  [(visible)]="payrollProvisionsListVisibility"
></app-payroll-provisions-info>
<!-- payroll provisions modal ends -->

<!-- payroll unpaid leaves modal starts -->
<app-payroll-unpaid-leaves-info
  [payrollUnpaidLeaves]="selectedPayrollUnpaidLeaves"
  [(visible)]="payrollProvisionsListVisibility"
></app-payroll-unpaid-leaves-info>
<!-- payroll unpaid leaves modal ends -->
