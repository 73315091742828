import { GosiRegType } from 'lookups/models';

/**
 * Represents the gosi reg types state.
 */
export class GosiRegTypeState {
  /**
   * The list of gosi reg types.
   */
  public data: GosiRegType[];

  /**
   * An error that may resulting during processing some actions.
   */
  public error: any;

  /**
   * Determines if there is a running load process.
   */
  public isLoading: boolean;

  /**
   * Determines if the last load process has been ended successfully.
   */
  public isLoaded: boolean;
}
