import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { FormGroup, FormControl } from '@angular/forms';

import { Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { tap } from 'rxjs/operators';

import { NotificationService, PageInfo, PaginationInfo, TranslationService } from 'shared';
import * as fromFinancesStore from 'finances/store';
import { PettyCash, PettyCashRefund } from 'finances/models';
import { Claims } from 'security/models';

/**
 * The view petty cash refund component pages.
 */
enum PAGES {
  details = 'details',
  journal = 'journal',
}

@Component({
  selector: 'app-petty-cash-refunds-list',
  templateUrl: './petty-cash-refunds-list.component.html',
  styles: [],
})
export class PettyCashRefundsListComponent implements OnInit {
  /**
   * Gets or sets the information about the current page.
   */
  pageInfo: PageInfo = {
    title: 'FINANCES.PETTY_CASH_REFUND.PETTY_CASH_REFUND',
    icon: 'fa fa-get-pocket',
  };

  /**
   * The system supported user claims.
   */
  Claims = Claims;

  /**
   * The list of petty cash refund.
   */
  pettyCashRefunds$: Observable<PettyCashRefund[]>;

  /**
   * The list of selected petty cash.
   */
  pettyCash: PettyCash[] = [];

  /**
   * The pagination info.
   */
  paginationInfo$: Observable<PaginationInfo>;

  /**
   * Indicates whether there is a search process is running or not.
   */
  isSearching$: Observable<boolean>;

  /**
   * The initial `fromDate` value for search.
   */
  initialFromDate = new Date();

  /**
   * The initial `toDate` value for search.
   * It's the current day of month.
   */
  initialToDate = new Date();

  /**
   * The search form.
   */
  searchForm: FormGroup;

  /**
   * Shows or hide the petty cash list.
   */
  pettyCashListVisibility = false;

  /**
   * Gets or sets the currently selected petty cash refund for view details.
   */
  selectedPettyCashRefund: PettyCashRefund;

  /**
   * Gets or sets the selected page.
   * @default 'details'
   */
  activePage: PAGES = PAGES.details;

  /**
   *
   * @param modalService The modal service.
   * @param route The activated route.
   * @param locationService The location service.
   * @param financesStore$ The finances store.
   * @param notificationService The  notification service.
   * @param translationService The translation service.
   */
  constructor(
    private modalService: NgbModal,
    private route: ActivatedRoute,
    private locationService: Location,
    private financesStore$: Store<fromFinancesStore.FinancesState>,
    private notificationService: NotificationService,
    private translationService: TranslationService
  ) {}

  ngOnInit(): void {
    this.init();
  }

  /**
   * Initialize component data.
   */
  init() {
    this.initForm();

    /**
     * Load data.
     */
    let isManualSearchTriggeredBeforeForPettyCashRefunds = false;
    this.pettyCashRefunds$ = this.financesStore$.pipe(
      select(fromFinancesStore.getPettyCashRefund),
      tap((pettyCashRefunds) => {
        if (!isManualSearchTriggeredBeforeForPettyCashRefunds && !pettyCashRefunds.length) {
          isManualSearchTriggeredBeforeForPettyCashRefunds = true;
          this.search();
        }
      })
    );

    this.paginationInfo$ = this.financesStore$.pipe(select(fromFinancesStore.getPettyCashRefundPaginationInfo));
    this.isSearching$ = this.financesStore$.pipe(select(fromFinancesStore.getPettyCashRefundSearching));

    /** Select the user desired page. */
    this.activePage = PAGES[this.route.snapshot.fragment] ?? this.activePage;
    this.selectedPageChanged(this.activePage);
  }

  /**
   * Updates the browser url according to the selected page.
   * @param page The newly selected page.
   */
  selectedPageChanged(page: PAGES) {
    this.locationService.replaceState(`${this.locationService.path()}#${PAGES[page]}`);
  }

  /**
   * Initialize form and add validators.
   */
  initForm() {
    this.searchForm = new FormGroup({
      pettyCash: new FormControl([]),
      fromDate: new FormControl({
        year: this.initialFromDate.getFullYear(),
        month: this.initialFromDate.getMonth() + 1,
        day: this.initialFromDate.getDate(),
      }),
      toDate: new FormControl({
        year: this.initialToDate.getFullYear(),
        month: this.initialToDate.getMonth() + 1,
        day: this.initialToDate.getDate(),
      }),
      page: new FormControl(1),
    });
  }

  /**
   * Handles search parameters change.
   */
  search(event?: KeyboardEvent) {
    if (event && event.key === 'Enter') {
      event.preventDefault();
      return;
    }

    this.applyFiltersAndSearch();
  }

  /**
   * Handles pagination page-changed event.
   * @param page The current selected page number.
   */
  pageChanged(page: number) {
    /** Update pagination page. */
    this.searchForm.patchValue({ page });

    this.applyFiltersAndSearch(page);
  }

  /**
   * Applies the search filters and dispatches a search action.
   * @param page The current page number, default to `1`.
   */
  applyFiltersAndSearch(page: number = 1) {
    if (!this.searchForm.get('fromDate')?.value || !this.searchForm.get('toDate')?.value) {
      return this.notificationService.warning(
        this.translationService.translate('FINANCES.PETTY_CASH_REFUND.NOTIFICATION_MESSAGE.DATE_SELECT')
      );
    }

    const fromDate = new Date(
      this.searchForm.get('fromDate').value.year,
      this.searchForm.get('fromDate').value.month - 1,
      this.searchForm.get('fromDate').value.day
    );
    fromDate.setHours(0, 0, 0, 0);

    const toDate = new Date(
      this.searchForm.get('toDate').value.year,
      this.searchForm.get('toDate').value.month - 1,
      this.searchForm.get('toDate').value.day
    );
    toDate.setHours(23, 59, 59, 0);
    this.financesStore$.dispatch(
      new fromFinancesStore.SearchPettyCashRefund({
        pettyCash: this.searchForm.get('pettyCash').value,
        fromDate,
        toDate,
        page,
      })
    );
  }

  /**
   * Enables trackBy feature for *ngFor utility to track items
   * depending on a comparer to reduce DOM change.
   * If the item is already exist then no HTML will change, only bounded values.
   * @param index The index of the item.
   * @param pettyCashRefund The item to determine if it was changed or not.
   */
  trackItems(index: number, pettyCashRefund: PettyCashRefund) {
    return pettyCashRefund ? pettyCashRefund.id : undefined;
  }

  /**
   * Opens the modal of the given templateRef.
   * @param modalRef The modal templateRef to be opened.
   */
  openModal(modalRef) {
    this.modalService.open(modalRef, { size: 'lg' });
  }

  /**
   * Adds the newly selected petty cash the petty cash search list.
   * @param pettyCash The list of newly selected petty cash to be added.
   */
  selectPettyCash(pettyCash: PettyCash[]) {
    const selectedIds: number[] = this.searchForm.get('pettyCash').value;
    this.pettyCash = [...this.pettyCash.filter((petty) => selectedIds.includes(petty.id)), ...pettyCash];
    this.searchForm.patchValue({ pettyCash: this.pettyCash.map((petty) => petty.id) });
  }
}
