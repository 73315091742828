<div class="row mb-1">
  <div class="col-md-auto">
    <button
      type="button"
      class="btn btn-sm btn-raised btn-primary round"
      [ngbTooltip]="'FINANCES.COST_CENTERS.COST_CENTER_DATA.ADD_COST_CENTER' | translate"
      placement="top-right"
      (click)="addCostCenter()"
    >
      <i class="fa fa-plus"></i>
    </button>
    <button
      type="button"
      class="btn btn-sm btn-raised btn-danger round mx-2"
      [ngbTooltip]="'FINANCES.COST_CENTERS.COST_CENTER_DATA.DELETE_COST_CENTERS' | translate"
      placement="left"
      (click)="openDeleteModal()"
      [disabled]="formArray.controls.length == 0"
    >
      <i class="fa fa-trash"></i>
    </button>
  </div>
</div>
<div [formGroup]="formArray.parent" class="row">
  <div class="col-md-12">
    <div class="table-responsive">
      <table class="table text-center">
        <thead>
          <tr>
            <th scope="col">
              <i class="fa fa-list-ol"></i>
            </th>
            <th scope="col">
              <i class="icon-vector mr-1"></i>
              {{ 'FINANCES.COST_CENTERS.COST_CENTER_DATA.COST_CENTER' | translate }}
            </th>
            <th scope="col">{{ 'SHARED.TOOLS' | translate }}</th>
          </tr>
        </thead>
        <tbody [formArrayName]="formArrayName">
          <tr [formGroup]="formGroup" *ngFor="let formGroup of formArray.controls; let index = index">
            <th scope="row">{{ index + 1 }}</th>
            <td>
              {{ formGroup.value.costCenter.description | localize: formGroup.value.costCenter.descriptionEn | async }}
            </td>
            <td>
              <a
                class="danger p-0"
                [ngbTooltip]="'SHARED.CONFIRM.DELETE' | translate"
                (click)="removeCostCenter(index)"
              >
                <i class="fa fa-close font-medium-3 mr-2"></i>
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>

<!-- cost centers search starts -->
<app-cost-centers-search
  [onlyActive]="true"
  [closeOnSelect]="false"
  (costCenters)="selectCostCenter($event)"
  [(visible)]="costCentersListVisibility"
>
</app-cost-centers-search>
<!-- cost centers search ends -->

<!-- confirm delete all invoices modal starts -->
<ng-template #deleteModalRef let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title text-danger" id="modal-basic-title">
      {{ 'FINANCES.COST_CENTERS.CONFIRM_DELETE_ALL_COST_CENTERS_TITLE' | translate }}
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="d()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p class="text-danger">
      {{ 'FINANCES.COST_CENTERS.CONFIRM_DELETE_ALL_COST_CENTERS_MESSAGE' | translate }}
    </p>
  </div>
  <div class="modal-footer">
    <div class="modal-actions">
      <button type="button" class="btn btn-raised btn-danger round" (click)="c(); resetCostCenters()" ngbAutofocus>
        <i class="fa fa-check"></i>
        <span class="mx-1"> {{ 'SHARED.CONFIRM.YES' | translate }} </span>
      </button>
      <button type="button" class="btn btn-outline-dark round" (click)="c()">
        <i class="fa fa-times"></i>
        <span class="mx-1"> {{ 'SHARED.CONFIRM.CANCEL' | translate }} </span>
      </button>
    </div>
  </div>
</ng-template>
<!-- confirm modal ends -->
