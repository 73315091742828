<!-- journal accrual payroll details starts -->
<div class="row">
  <div class="col-md-12">
    <!-- accrual payroll details starts -->
    <div class="card-header mb-3 pt-0">
      <h4 class="card-title">{{ 'FINANCES.JOURNALS.JOURNAL_DATA.ACCRUAL_PAYROLL_DATA' | translate }}</h4>
    </div>
    <div class="card-content">
      <div class="px-3">
        <div class="row">
          <div class="form-group col-md-6">
            <label for="transactionDate">{{ 'FINANCES.JOURNALS.JOURNAL_DATA.JOURNAL_DATE' | translate }}</label>
            <input
              type="text"
              id="transactionDate"
              class="form-control round"
              [value]="accrualPayroll.transactionDate | date: 'datetime'"
              readonly
            />
          </div>
          <div class="form-group col-md-6">
            <label for="user">{{ 'FINANCES.JOURNALS.JOURNAL_DATA.USER_CREATE' | translate }}</label>
            <input type="text" id="user" class="form-control round" [value]="accrualPayroll.user.name" readonly />
          </div>
          <div class="form-group col-md-12">
            <label for="notes">{{ 'FINANCES.JOURNALS.JOURNAL_DATA.NOTES' | translate }}</label>
            <textarea
              id="notes"
              class="form-control round"
              [innerText]="accrualPayroll.notes"
              [placeholder]="'FINANCES.JOURNALS.JOURNAL_DATA.NOTES_NOT_FOUND' | translate"
              readonly
            ></textarea>
          </div>
        </div>
      </div>
    </div>
    <!-- accrual payroll details ends -->

    <!-- accrual payrolls details starts -->
    <div class="card-header mb-3 pt-1">
      <h4 class="card-title">{{ 'FINANCES.JOURNALS.JOURNAL_DATA.PAYROLLS' | translate }}</h4>
    </div>
    <div class="card-content">
      <div class="px-3">
        <div class="row">
          <div class="col-md-12">
            <div class="table-responsive">
              <table class="table text-center">
                <thead>
                  <tr>
                    <th class="fit-width" scope="col"><i class="fa fa-list-ol"></i></th>
                    <th class="fit-width" scope="col">
                      {{ 'FINANCES.HR.PAID_PAYROLLS.PAID_PAYROLLS_DATA.EMPLOYEE_NUMBER' | translate }}
                    </th>
                    <th class="fit-width" scope="col">
                      {{ 'FINANCES.HR.PAID_PAYROLLS.PAID_PAYROLLS_DATA.EMPLOYEE' | translate }}
                    </th>
                    <th class="fit-width" scope="col">
                      {{ 'FINANCES.HR.PAID_PAYROLLS.PAID_PAYROLLS_DATA.POSITION' | translate }}
                    </th>
                    <th class="fit-width" scope="col">
                      {{ 'FINANCES.HR.PAID_PAYROLLS.PAID_PAYROLLS_DATA.DEPARTMENT' | translate }}
                    </th>
                    <th class="fit-width" scope="col">
                      {{ 'FINANCES.HR.PAID_PAYROLLS.PAID_PAYROLLS_DATA.LOCATION' | translate }}
                    </th>

                    <th class="fit-width" scope="col">
                      {{ 'FINANCES.HR.PAID_PAYROLLS.PAID_PAYROLLS_DATA.NET' | translate }}
                    </th>

                    <th class="fit-width" scope="col">
                      {{ 'FINANCES.HR.PAID_PAYROLLS.PAID_PAYROLLS_DATA.EMPLOYEE_GOSI' | translate }}
                    </th>
                    <th class="fit-width" scope="col">
                      {{ 'FINANCES.HR.PAID_PAYROLLS.PAID_PAYROLLS_DATA.EMPLOYER_GOSI' | translate }}
                    </th>
                    <th class="fit-width" scope="col">
                      {{ 'FINANCES.HR.PAID_PAYROLLS.PAID_PAYROLLS_DATA.LOAN_SETTLEMENTS' | translate }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let accrualPayrollPayroll of accrualPayroll.accrualPayrollPayrolls; let i = index">
                    <th class="fit-width" scope="row">{{ i + 1 }}</th>
                    <th class="fit-width" scope="row">
                      {{ accrualPayrollPayroll.payroll.employee.employeeAssignmentDetail.personNumber }}
                    </th>
                    <td class="fit-width">
                      {{
                        accrualPayrollPayroll.payroll.employee.employeePersonalDetail.fullName
                          | localize: accrualPayrollPayroll.payroll.employee.employeePersonalDetail.fullNameEn
                          | async
                      }}
                    </td>
                    <td class="fit-width">
                      {{
                        accrualPayrollPayroll.payroll.employee.employeeAssignmentDetail.position.name
                          | localize: accrualPayrollPayroll.payroll.employee.employeeAssignmentDetail.position.nameEn
                          | async
                      }}
                    </td>
                    <td class="fit-width">
                      {{
                        accrualPayrollPayroll.payroll.employee.employeeAssignmentDetail.department?.name
                          | localize
                            : accrualPayrollPayroll.payroll.employee.employeeAssignmentDetail.department?.nameEn
                          | async
                      }}
                    </td>
                    <td class="fit-width">
                      {{
                        accrualPayrollPayroll.payroll.employee.employeeAssignmentDetail.location?.name
                          | localize: accrualPayrollPayroll.payroll.employee.employeeAssignmentDetail.location?.nameEn
                          | async
                      }}
                    </td>
                    <td class="fit-width">
                      <span
                        [ngClass]="{
                          'badge-danger': accrualPayrollPayroll.payroll.net < 0
                        }"
                      >
                        {{ accrualPayrollPayroll.payroll.net | toDecimal }}
                      </span>
                    </td>
                    <td class="fit-width">{{ accrualPayrollPayroll.payroll.employeeGosi | toDecimal }}</td>
                    <td class="fit-width">{{ accrualPayrollPayroll.payroll.employerGosi | toDecimal }}</td>
                    <td class="fit-width">{{ accrualPayrollPayroll.payroll.loanSettlement | toDecimal }}</td>
                  </tr>
                </tbody>
                <tfoot class="tfoot-light">
                  <tr>
                    <td [attr.colspan]="10">
                      <i class="fa fa-bookmark c-primary"></i>
                      <span>
                        {{ accrualPayroll.accrualPayrollPayrolls?.length | toNumber }}
                        {{ 'FINANCES.JOURNALS.JOURNAL_DATA.ACCRUAL_PAYROLL' | translate }}
                      </span>
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- accrual payrolls details ends -->
  </div>
  <!-- journal accrual payroll details ends -->
</div>
