import { Action } from '@ngrx/store';

import { ContractType } from 'lookups/models';
import { AppHttpResponse } from 'shared';

/**
 * The Lookups-Module store contract type action types.
 */
export enum ContractTypeActionType {
  GET_ALL_CONTRACT_TYPES = '[Lookups] [ContractType] Get All Contract Types',
  GET_ALL_CONTRACT_TYPES_FAIL = '[Lookups] [ContractType] Get All Contract Types Fail',
  GET_ALL_CONTRACT_TYPES_SUCCESS = '[Lookups] [ContractType] Get All Contract Types Success',
}

/**
 * Represents a store contract type action.
 */
export class GetAllContractTypes implements Action {
  /**
   * The type of the action.
   */
  readonly type = ContractTypeActionType.GET_ALL_CONTRACT_TYPES;

  /**
   * Fires a new contract type action.
   */
  constructor() {}
}

/**
 * Represents a store contract type fail action.
 */
export class GetAllContractTypesFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = ContractTypeActionType.GET_ALL_CONTRACT_TYPES_FAIL;

  /**
   * Fires a new contract type fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store contract type success action.
 */
export class GetAllContractTypesSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = ContractTypeActionType.GET_ALL_CONTRACT_TYPES_SUCCESS;

  /**
   * Fires a contract type success action.
   * @param payload An object contains the list of contract types.
   */
  constructor(public payload: AppHttpResponse<ContractType[]>) {}
}

/**
 * Lookups-module contract type action types.
 */
export type ContractTypeActions = GetAllContractTypes | GetAllContractTypesFail | GetAllContractTypesSuccess;
