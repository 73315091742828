import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { AppSearchRoutingModule } from 'app-search/app-search-routing.module';

import { SharedModule } from 'shared';
import * as fromStore from 'app-search/store';
import * as fromServices from 'app-search/services';
import * as fromComponents from 'app-search/components';

@NgModule({
  declarations: [...fromComponents.COMPONENTS],
  imports: [
    CommonModule,
    SharedModule,
    AppSearchRoutingModule,
    StoreModule.forFeature('app-search', fromStore.reducers),
    EffectsModule.forFeature(fromStore.effects),
  ],
  exports: [
    fromComponents.CustomersSearchComponent,
    fromComponents.EmployeesSearchComponent,
    fromComponents.DepartmentsSearchComponent,
    fromComponents.LocationsSearchComponent,
    fromComponents.BankAccountsSearchComponent,
    fromComponents.BanksSearchComponent,
    fromComponents.OrganizationUsersSearchComponent,
    fromComponents.VendorsSearchComponent,
    fromComponents.PosDevicesSearchComponent,
    fromComponents.ProjectsSearchComponent,
    fromComponents.ProjectSubTasksSearchComponent,
    fromComponents.SalesPersonsSearchComponent,
    fromComponents.GovServicesSearchComponent,
  ],
  providers: [...fromServices.SERVICES],
})
export class AppSearchModule {}
