import { UsersListComponent } from 'security/components/users/users-list/users-list.component';
import { CreateUserComponent } from 'security/components/users/create-user/create-user.component';
import { UpdateUserComponent } from 'security/components/users/update-user/update-user.component';
import { UpdateProfileComponent } from 'security/components/users/update-profile/update-profile.component';
import { OrganizationUsersListComponent } from 'security/components/users/organization-users-list/organization-users-list.component';
import { UserClaimsFormComponent } from 'security/components/users/user-claims-form/user-claims-form.component';
// tslint:disable-next-line: max-line-length
import { UpdateOrganizationUserClaimsComponent } from 'security/components/users/update-organization-user-claims/update-organization-user-claims.component';
import { UpdateUserLocationComponent } from 'security/components/users/update-user-location/update-user-location.component';
import { UpdateUserBankAccountComponent } from 'security/components/users/update-user-bank-account/update-user-bank-account.component';
import { UpdateUserCostCenterComponent } from 'security/components/users/update-user-cost-center/update-user-cost-center.component';
import { ResetUserCredentialsComponent } from 'security/components/users/reset-user-credentials/reset-user-credentials.component';
import { OrganizationUsersSearchComponent } from 'security/components/users/organization-users-search/organization-users-search.component';
import { CreateOrganizationUserComponent } from 'security/components/users/create-organization-user/create-organization-user.component';

export * from 'security/components/users/users-list/users-list.component';
export * from 'security/components/users/create-user/create-user.component';
export * from 'security/components/users/update-user/update-user.component';
export * from 'security/components/users/update-profile/update-profile.component';
export * from 'security/components/users/organization-users-list/organization-users-list.component';
export * from 'security/components/users/user-claims-form/user-claims-form.component';
export * from 'security/components/users/update-organization-user-claims/update-organization-user-claims.component';
export * from 'security/components/users/update-user-location/update-user-location.component';
export * from 'security/components/users/update-user-bank-account/update-user-bank-account.component';
export * from 'security/components/users/update-user-cost-center/update-user-cost-center.component';
export * from 'security/components/users/reset-user-credentials/reset-user-credentials.component';
export * from 'security/components/users/organization-users-search/organization-users-search.component';
export * from 'security/components/users/create-organization-user/create-organization-user.component';

/**
 * The set of components registered on this module.
 */
export const COMPONENTS: any[] = [
  UsersListComponent,
  CreateUserComponent,
  UpdateUserComponent,
  UpdateProfileComponent,
  OrganizationUsersListComponent,
  UserClaimsFormComponent,
  UpdateOrganizationUserClaimsComponent,
  UpdateUserLocationComponent,
  UpdateUserBankAccountComponent,
  UpdateUserCostCenterComponent,
  ResetUserCredentialsComponent,
  OrganizationUsersSearchComponent,
  CreateOrganizationUserComponent,
];
