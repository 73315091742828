import { Action } from '@ngrx/store';

import { ProjectStatus } from 'lookups/models';
import { AppHttpResponse } from 'shared';

/**
 * The Lookups-Module store  project statuses action types.
 */
export enum ProjectStatusActionType {
  GET_ALL_PROJECT_STATUSES = '[Lookups] [ProjectStatus] Get All Project Statuses',
  GET_ALL_PROJECT_STATUSES_FAIL = '[Lookups] [ProjectStatus] Get All Project Statuses Fail',
  GET_ALL_PROJECT_STATUSES_SUCCESS = '[Lookups] [ProjectStatus] Get All Project Statuses Success',
}

/**
 * Represents a store  project status action.
 */
export class GetAllProjectStatuses implements Action {
  /**
   * The type of the action.
   */
  readonly type = ProjectStatusActionType.GET_ALL_PROJECT_STATUSES;

  /**
   * Fires a new  project status action.
   */
  constructor() {}
}

/**
 * Represents a store  project status fail action.
 */
export class GetAllProjectStatusesFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = ProjectStatusActionType.GET_ALL_PROJECT_STATUSES_FAIL;

  /**
   * Fires a new  project status fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store  project status success action.
 */
export class GetAllProjectStatusesSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = ProjectStatusActionType.GET_ALL_PROJECT_STATUSES_SUCCESS;

  /**
   * Fires a  project status success action.
   * @param payload An object contains the list of  Cities.
   */
  constructor(public payload: AppHttpResponse<ProjectStatus[]>) {}
}

/**
 * Lookups-module  project status action types.
 */
export type ProjectStatusActions = GetAllProjectStatuses | GetAllProjectStatusesFail | GetAllProjectStatusesSuccess;
