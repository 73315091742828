import { Component, Input } from '@angular/core';

import { VendorLog } from 'purchases/models';

@Component({
  selector: 'app-journal-vendor-log-details',
  templateUrl: './journal-vendor-log-details.component.html',
  styles: [],
})
export class JournalVendorLogDetailsComponent {
  /**
   * Sets the current journal vendor log details.
   */
  @Input() vendorLog: VendorLog;
}
