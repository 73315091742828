import { CustomersState } from 'app-search/store/states/customers.state';
import { UsersState } from 'app-search/store/states/users.state';
import { VendorsState } from 'app-search/store/states/vendors.state';
import { PosDevicesState } from 'app-search/store/states/pos-devices.state';
import { EmployeesState } from 'app-search/store/states/employees.state';
import { LocationsState } from 'app-search/store/states/locations.state';
import { DepartmentsState } from 'app-search/store/states/departments.state';
import { BankAccountState } from 'app-search/store/states/bank-account.state';
import { BanksState } from 'app-search/store/states/banks.state';
import { SubTasksState } from 'app-search/store/states/sub-tasks.state';
import { ProjectsState } from 'app-search/store/states/projects.state';
import { GovServicesState } from 'app-search/store/states/gov-services.state';
import { RegionsState } from 'app-search/store/states/regions.state';
import { SectorsState } from 'app-search/store/states/sectors.state';

/**
 * Represents the AppSearch-Module state.
 */
export class AppSearchState {
  /**
   * Represents the customers state.
   */
  public customers: CustomersState;

  /**
   * Represents the users state.
   */
  public users: UsersState;

  /**
   * Represents the vendors state.
   */
  public vendors: VendorsState;

  /**
   * Represents the pos devices state.
   */
  public posDevices: PosDevicesState;

  /**
   * Represents the employees state.
   */
  public employees: EmployeesState;

  /**
   * Represents the locations state.
   */
  public locations: LocationsState;

  /**
   * Represents the departments state.
   */
  public departments: DepartmentsState;

  /**
   * Represents the bank accounts state.
   */
  public bankAccounts: BankAccountState;

  /**
   * Represents the banks state.
   */
  public banks: BanksState;

  /**
   * Represents the sub tasks state.
   */
  public subTasks: SubTasksState;

  /**
   * Represents the projects state.
   */
  public projects: ProjectsState;

  /**
   * Represents the gov services state.
   */
  public govServices: GovServicesState;

  /**
   * Represents the regions state.
   */
  public regions: RegionsState;

  /**
   * Represents the sectors state.
   */
  public sectors: SectorsState;
}
