import { createSelector } from '@ngrx/store';

import { SalesState, ParkedSaleInvoicesState } from 'sales/store/states';
import { selectSalesState } from 'sales/store/selectors/feature.selectors';

/**
 * Gets the parked sale invoices state.
 */
const selectParkedSaleInvoicesState = createSelector(selectSalesState, (state: SalesState) => state.parkedSaleInvoices);

/**
 * Gets the list of parked sale invoices.
 */
export const getParkedSaleInvoices = createSelector(
  selectParkedSaleInvoicesState,
  (state: ParkedSaleInvoicesState) => state.data
);

/**
 * Gets the count of the list of parked sale invoices.
 */
export const getParkedSaleInvoicesCount = createSelector(
  selectParkedSaleInvoicesState,
  (state: ParkedSaleInvoicesState) => state.data.length
);
