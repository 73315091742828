import { createSelector } from '@ngrx/store';

import { ReportsState, FinancesReportsState } from 'reports/store/states';
import { selectReportsState } from 'reports/store/selectors/feature.selectors';

/* Selectors */

/**
 * Gets the finances reports state.s
 */
const selectFinancesReportsState = createSelector(selectReportsState, (state: ReportsState) => state.finances);

/**
 * Gets the loaded report.
 */
export const getAccountAnalysisReport = createSelector(
  selectFinancesReportsState,
  (state: FinancesReportsState) => state.accountAnalysis
);

/**
 * Gets the loaded report.
 */
export const getBalanceSheetReport = createSelector(
  selectFinancesReportsState,
  (state: FinancesReportsState) => state.balanceSheet
);

/**
 * Gets the loaded report.
 */
export const getBankAccountSummaryReport = createSelector(
  selectFinancesReportsState,
  (state: FinancesReportsState) => state.bankAccountSummary
);

/**
 * Gets the loaded report.
 */
export const getIncomeStatementReport = createSelector(
  selectFinancesReportsState,
  (state: FinancesReportsState) => state.incomeStatement
);

/**
 * Gets the loaded report.
 */
export const getTaxesReport = createSelector(selectFinancesReportsState, (state: FinancesReportsState) => state.taxes);

/**
 * Gets the loaded report.
 */
export const getJournalsReport = createSelector(
  selectFinancesReportsState,
  (state: FinancesReportsState) => state.journals
);

/**
 * Gets the loaded report.
 */
export const getTrialBalanceReport = createSelector(
  selectFinancesReportsState,
  (state: FinancesReportsState) => state.trialBalance
);

/**
 * Gets the error that occurred in the state.
 */
export const getFinancesReportsError = createSelector(
  selectFinancesReportsState,
  (state: FinancesReportsState) => state.error
);

/**
 * Determines if there is a running load process.
 */
export const getAccountAnalysisReportLoading = createSelector(
  selectFinancesReportsState,
  (state: FinancesReportsState) => state.isLoadingAccountAnalysis
);

/**
 * Determines if the last load process has been ended successfully.
 */
export const getAccountAnalysisReportLoadCompleted = createSelector(
  selectFinancesReportsState,
  (state: FinancesReportsState) => state.isAccountAnalysisLoadCompleted
);

/**
 * Determines if there is a running load process.
 */
export const getBalanceSheetReportLoading = createSelector(
  selectFinancesReportsState,
  (state: FinancesReportsState) => state.isLoadingBalanceSheet
);

/**
 * Determines if the last load process has been ended successfully.
 */
export const getBalanceSheetReportLoadCompleted = createSelector(
  selectFinancesReportsState,
  (state: FinancesReportsState) => state.isBalanceSheetLoadCompleted
);

/**
 * Determines if there is a running load process.
 */
export const getBankAccountSummaryReportLoading = createSelector(
  selectFinancesReportsState,
  (state: FinancesReportsState) => state.isLoadingBankAccountSummary
);

/**
 * Determines if the last load process has been ended successfully.
 */
export const getBankAccountSummaryReportLoadCompleted = createSelector(
  selectFinancesReportsState,
  (state: FinancesReportsState) => state.isBankAccountSummaryLoadCompleted
);

/**
 * Determines if there is a running load process.
 */
export const getJournalsReportLoading = createSelector(
  selectFinancesReportsState,
  (state: FinancesReportsState) => state.isLoadingJournals
);

/**
 * Determines if the last load process has been ended successfully.
 */
export const getJournalsReportLoadCompleted = createSelector(
  selectFinancesReportsState,
  (state: FinancesReportsState) => state.isJournalsLoadCompleted
);

/**
 * Determines if there is a running load process.
 */
export const getTaxesReportLoading = createSelector(
  selectFinancesReportsState,
  (state: FinancesReportsState) => state.isLoadingTaxes
);

/**
 * Determines if the last load process has been ended successfully.
 */
export const getTaxesReportLoadCompleted = createSelector(
  selectFinancesReportsState,
  (state: FinancesReportsState) => state.isTaxesLoadCompleted
);

/**
 * Determines if there is a running load process.
 */
export const getTrialBalanceReportLoading = createSelector(
  selectFinancesReportsState,
  (state: FinancesReportsState) => state.isLoadingTrialBalance
);

/**
 * Determines if the last load process has been ended successfully.
 */
export const getTrialBalanceReportLoadCompleted = createSelector(
  selectFinancesReportsState,
  (state: FinancesReportsState) => state.isTrialBalanceLoadCompleted
);

/**
 * Determines if there is a running load process.
 */
export const getIncomeStatementReportLoading = createSelector(
  selectFinancesReportsState,
  (state: FinancesReportsState) => state.isLoadingIncomeStatement
);

/**
 * Determines if the last load process has been ended successfully.
 */
export const getIncomeStatementReportLoadCompleted = createSelector(
  selectFinancesReportsState,
  (state: FinancesReportsState) => state.isIncomeStatementLoadCompleted
);
