import { Component, OnInit, ViewChild, ElementRef, OnDestroy, Input } from '@angular/core';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { flatMap } from 'lodash';

import * as fromSecurityStore from 'security/store';
import { ClaimGroup, User, UserClaimFormItem, UserClaimGroupFormItem } from 'security/models';

@Component({
  selector: 'app-update-organization-user-claims',
  templateUrl: './update-organization-user-claims.component.html',
  styles: [],
})
export class UpdateOrganizationUserClaimsComponent implements OnInit, OnDestroy {
  /**
   * The confirm modal template reference.
   */
  @ViewChild('confirmModalRef') confirmModalRef: ElementRef<any>;

  /**
   * Gets or sets the id of the current edited user.
   */
  @Input() userId: number;

  /**
   * The current edited user.
   */
  user$: Observable<User>;

  /**
   * The list of all claim groups available for organization user.
   */
  claimGroups: ClaimGroup[] = [];

  /**
   * The list of user allowed claim ids.
   */
  userClaimIds: number[] = [];

  /**
   * Indicates whether there is a update-user process is running or not.
   */
  isUpdating$: Observable<boolean>;

  /**
   * The claim groups model.
   * Uses `[(ngModel)]` binding.
   */
  claimGroupsModel: UserClaimGroupFormItem[] = [];

  /**
   * The set of subscriptions on this components,
   * these subscriptions must be unsubscribed before this component got destroyed.
   */
  subscriptions = new Subscription();

  /**
   * @param modalService The modal service.
   * @param securityStore$ The Security-module store.
   */
  constructor(private modalService: NgbModal, private securityStore$: Store<fromSecurityStore.SecurityState>) {}

  ngOnInit(): void {
    this.init();
  }

  /**
   * Destroy component data
   */
  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  /**
   * Initialize component data.
   */
  init() {
    /* Load the selected user. */
    this.user$ = this.securityStore$.pipe(
      select(fromSecurityStore.getSelectedUser),
      tap((user) => {
        if (user) {
          this.userClaimIds = user.userClaims?.map((claim) => claim.claimId) ?? [];
          this.updateClaimGroups();
        }
      })
    );
    /**
     * Load data.
     */
    this.securityStore$.dispatch(new fromSecurityStore.GetCurrentLoggedInUserClaimsGroups());

    this.subscriptions.add(
      this.securityStore$
        .select(fromSecurityStore.getCurrentLoggedInUserClaimGroups)
        .pipe(
          tap((groups) => {
            this.claimGroups = groups;
            this.updateClaimGroups();
          })
        )
        .subscribe()
    );

    /**
     * Set data.
     */
    this.isUpdating$ = this.securityStore$.pipe(select(fromSecurityStore.getSelectedUserUpdating));
  }

  /**
   * Updates the list of claim groups based on the selected user type.
   */
  updateClaimGroups() {
    this.claimGroupsModel = this.claimGroups.map((group) => {
      const claims: UserClaimFormItem[] = group.claims.map((claim) => {
        return {
          claim,
          isSelected: this.userClaimIds.includes(claim.id),
        };
      });

      return {
        group,
        isSelected: claims.filter((claim) => claim.isSelected).length === claims.length,
        claims,
      };
    });
  }

  /**
   * Submits the form.
   */
  submit() {
    this.openModal(this.confirmModalRef);
  }

  /**
   * Confirms the form submit.
   */
  confirm() {
    this.securityStore$.dispatch(
      new fromSecurityStore.UpdateOrganizationUserClaims({
        id: this.userId,
        /**
         * Map claim groups model to Array(number) of claim ids.
         */
        claims: flatMap(this.claimGroupsModel, (group) => group.claims)
          .filter((claim) => claim.isSelected)
          .map((claim) => claim.claim.id),
      })
    );
  }

  /**
   * Opens the modal of the given templateRef.
   * @param modalRef The modal templateRef to be opened.
   */
  openModal(modalRef) {
    this.modalService.open(modalRef);
  }
}
