import { Action } from '@ngrx/store';

import { CustomerAsset, UpdateCustomerAssetInput, CreateCustomerAssetInput } from 'sales/models';
import { AppHttpResponse } from 'shared';

/**
 * The Sales-Module store customerAssets action types.
 */
export enum CustomerAssetsActionType {
  SEARCH_CUSTOMERS_ASSETS = '[Sales] [CustomerAssets] Search Customer Assets',
  SEARCH_CUSTOMERS_ASSETS_FAIL = '[Sales] [CustomerAssets] Search Customer Assets Fail',
  SEARCH_CUSTOMERS_ASSETS_SUCCESS = '[Sales] [CustomerAssets] Search Customer Assets Success',

  FIND_CUSTOMER_ASSET = '[Sales] [CustomerAssets] Find Customer Asset',
  FIND_CUSTOMER_ASSET_FAIL = '[Sales] [CustomerAssets] Find Customer Asset Fail',
  FIND_CUSTOMER_ASSET_SUCCESS = '[Sales] [CustomerAssets] Find Customer Asset Success',

  CREATE_CUSTOMER_ASSET = '[Sales] [CustomerAssets] Create Customer Asset',
  CREATE_CUSTOMER_ASSET_FAIL = '[Sales] [CustomerAssets] Create Customer Asset Fail',
  CREATE_CUSTOMER_ASSET_SUCCESS = '[Sales] [CustomerAssets] Create Customer Asset Success',

  UPDATE_CUSTOMER_ASSET = '[Sales] [CustomerAssets] Update Customer Asset',
  UPDATE_CUSTOMER_ASSET_FAIL = '[Sales] [CustomerAssets] Update Customer Asset Fail',
  UPDATE_CUSTOMER_ASSET_SUCCESS = '[Sales] [CustomerAssets] Update Customer Asset Success',

  DELETE_CUSTOMER_ASSET = '[Sales] [CustomerAssets] Delete Customer Asset',
  DELETE_CUSTOMER_ASSET_FAIL = '[Sales] [CustomerAssets] Delete Customer Asset Fail',
  DELETE_CUSTOMER_ASSET_SUCCESS = '[Sales] [CustomerAssets] Delete Customer Asset Success',
}

/**
 * Represents a store customer assets search action.
 */
export class SearchCustomersAssets implements Action {
  /**
   * The type of the action.
   */
  readonly type = CustomerAssetsActionType.SEARCH_CUSTOMERS_ASSETS;

  /**
   * Fires a new customer assets search action.
   * @param payload the search parameters,
   * if omitted, all customer assets will be loaded.
   */
  constructor(
    public payload?: {
      customers: number[];
      page: number;
    }
  ) {}
}

/**
 * Represents a store customer assets search-fail action.
 */
export class SearchCustomersAssetsFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = CustomerAssetsActionType.SEARCH_CUSTOMERS_ASSETS_FAIL;

  /**
   * Fires a new customer assets search-fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store search-success action.
 */
export class SearchCustomersAssetsSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = CustomerAssetsActionType.SEARCH_CUSTOMERS_ASSETS_SUCCESS;

  /**
   * Fires a new customer assets search-success action.
   * @param payload An object contains the list of customer assets that matches the search criteria
   * in addition to the pagination info.
   */
  constructor(public payload: AppHttpResponse<CustomerAsset[]>) {}
}

/**
 * Represents a store find-customerAsset action.
 */
export class FindCustomerAsset implements Action {
  /**
   * The type of the action.
   */
  readonly type = CustomerAssetsActionType.FIND_CUSTOMER_ASSET;

  /**
   * Fires a new find customer asset action.
   * @param payload the id of the customerAsset.
   */
  constructor(public payload: number) {}
}

/**
 * Represents a store find-customer asset-fail action.
 */
export class FindCustomerAssetFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = CustomerAssetsActionType.FIND_CUSTOMER_ASSET_FAIL;

  /**
   * Fires a new find-customer asset-fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store find customer asset-success action.
 */
export class FindCustomerAssetSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = CustomerAssetsActionType.FIND_CUSTOMER_ASSET_SUCCESS;

  /**
   * Fires a new find-customer asset-success action.
   * @param payload The loaded customer asset.
   */
  constructor(public payload: AppHttpResponse<CustomerAsset>) {}
}

/**
 * Represents a store customer asset create action.
 */
export class CreateCustomerAsset implements Action {
  /** The type of the action. */
  readonly type = CustomerAssetsActionType.CREATE_CUSTOMER_ASSET;

  /**
   * Fires a new customer asset create action.
   * @param payload the new customer asset data-model.
   */
  constructor(public payload: CreateCustomerAssetInput) {}
}

/**
 * Represents a store customer asset create-fail action.
 */
export class CreateCustomerAssetFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = CustomerAssetsActionType.CREATE_CUSTOMER_ASSET_FAIL;

  /**
   * Fires a new customer asset create-fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store customer asset create-success action.
 */
export class CreateCustomerAssetSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = CustomerAssetsActionType.CREATE_CUSTOMER_ASSET_SUCCESS;

  /**
   * Fires a new customer asset create-success action.
   * @param payload The created customerAsset.
   */
  constructor(public payload: AppHttpResponse<CustomerAsset>) {}
}

/**
 * Represents a store customer asset delete action.
 */
export class DeleteCustomerAsset implements Action {
  /**
   * The type of the action.
   */
  readonly type = CustomerAssetsActionType.DELETE_CUSTOMER_ASSET;

  /**
   * Fires a new customer asset delete action.
   * @param payload The customer asset's id.
   */
  constructor(public payload: number) {}
}

/**
 * Represents a store customer asset delete-fail action.
 */
export class DeleteCustomerAssetFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = CustomerAssetsActionType.DELETE_CUSTOMER_ASSET_FAIL;

  /**
   * Fires a new customer asset delete-fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store customer asset delete-success action.
 */
export class DeleteCustomerAssetSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = CustomerAssetsActionType.DELETE_CUSTOMER_ASSET_SUCCESS;

  /**
   * Fires a new customer asset delete-success action.
   * @param payload The deleted customer asset.
   */
  constructor(public payload: AppHttpResponse<CustomerAsset>) {}
}

/**
 * Represents a store customer asset update action.
 */
export class UpdateCustomerAsset implements Action {
  /**
   * The type of the action.
   */
  readonly type = CustomerAssetsActionType.UPDATE_CUSTOMER_ASSET;

  /**
   * Fires a new customer asset update action.
   * @param payload The customer asset's updated data.
   */
  constructor(public payload: UpdateCustomerAssetInput) {}
}

/**
 * Represents a store customer asset update-fail action.
 */
export class UpdateCustomerAssetFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = CustomerAssetsActionType.UPDATE_CUSTOMER_ASSET_FAIL;

  /**
   * Fires a new customer asset update-fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store customer asset update-success action.
 */
export class UpdateCustomerAssetSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = CustomerAssetsActionType.UPDATE_CUSTOMER_ASSET_SUCCESS;

  /**
   * Fires a new customer asset update-success action.
   * @param payload The updated customerAsset.
   */
  constructor(public payload: AppHttpResponse<CustomerAsset>) {}
}

/**
 * Sales-module customer assets action types.
 */
export type CustomerAssetsActions =
  | SearchCustomersAssets
  | SearchCustomersAssetsFail
  | SearchCustomersAssetsSuccess
  | FindCustomerAsset
  | FindCustomerAssetFail
  | FindCustomerAssetSuccess
  | CreateCustomerAsset
  | CreateCustomerAssetFail
  | CreateCustomerAssetSuccess
  | UpdateCustomerAsset
  | UpdateCustomerAssetFail
  | UpdateCustomerAssetSuccess
  | DeleteCustomerAsset
  | DeleteCustomerAssetFail
  | DeleteCustomerAssetSuccess;
