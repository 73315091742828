import { Action } from '@ngrx/store';

import { OutgoingStockType } from 'lookups/models';
import { AppHttpResponse } from 'shared';

/**
 * The Lookups-Module store outgoing stock types action types.
 */
export enum OutgoingStockTypeActionType {
  GET_ALL_OUTGOING_STOCK_TYPES = '[Lookups] [OutgoingStockType] Get All Outgoing Stock Types',
  GET_ALL_OUTGOING_STOCK_TYPES_FAIL = '[Lookups] [OutgoingStockType] Get All Outgoing Stock Types Fail',
  GET_ALL_OUTGOING_STOCK_TYPES_SUCCESS = '[Lookups] [OutgoingStockType] Get All Outgoing Stock Types Success',
}

/**
 * Represents a store outgoing stock type action.
 */
export class GetAllOutgoingStockTypes implements Action {
  /**
   * The type of the action.
   */
  readonly type = OutgoingStockTypeActionType.GET_ALL_OUTGOING_STOCK_TYPES;

  /**
   * Fires a new outgoing stock type action.
   */
  constructor() {}
}

/**
 * Represents a store outgoing stock type fail action.
 */

export class GetAllOutgoingStockTypesFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = OutgoingStockTypeActionType.GET_ALL_OUTGOING_STOCK_TYPES_FAIL;

  /**
   * Fires a new outgoing stock type fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store outgoing stock type success action.
 */
export class GetAllOutgoingStockTypesSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = OutgoingStockTypeActionType.GET_ALL_OUTGOING_STOCK_TYPES_SUCCESS;

  /**
   * Fires a outgoing stock type success action.
   * @param payload An object contains the list of outgoing stock types.
   */
  constructor(public payload: AppHttpResponse<OutgoingStockType[]>) {}
}

/**
 * Lookups-module outgoing stock type action types.
 */
export type OutgoingStockTypeActions =
  | GetAllOutgoingStockTypes
  | GetAllOutgoingStockTypesFail
  | GetAllOutgoingStockTypesSuccess;
