import { createSelector } from '@ngrx/store';

import { RegionsState, AppSearchState } from 'app-search/store/states';
import { selectAppSearchState } from 'app-search/store/selectors/feature.selectors';

/**
 * Gets the regions state.
 */
const selectRegionState = createSelector(selectAppSearchState, (state: AppSearchState) => state.regions);

/**
 * Gets the list of loaded regions.
 */
export const getRegions = createSelector(selectRegionState, (state: RegionsState) => state.data);

/**
 * Gets the regions pagination info.
 */
export const getRegionsPaginationInfo = createSelector(
  selectRegionState,
  (state: RegionsState) => state.paginationInfo
);

/**
 * Gets the error that occurred in the state.
 */
export const getRegionsError = createSelector(selectRegionState, (state: RegionsState) => state.error);

/**
 * Determines if there is a running search process.
 */
export const getRegionsSearching = createSelector(selectRegionState, (state: RegionsState) => state.isSearching);

/**
 * Determines if the last search process has been ended successfully.
 */
export const getRegionsSearchCompleted = createSelector(
  selectRegionState,
  (state: RegionsState) => state.isSearchCompleted
);
