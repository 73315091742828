<!-- loan pages start -->
<div class="card">
  <div class="card-content">
    <div class="px-3">
      <ul
        class="nav-tabs"
        ngbNav
        #nav="ngbNav"
        [(activeId)]="activePage"
        (activeIdChange)="selectedPageChanged($event)"
        [destroyOnHide]="false"
      >
        <li ngbNavItem="loans">
          <a ngbNavLink>
            <i class="fa fa-minus-square-o" [class.success]="activePage == 'loans'"></i>
            {{ 'FINANCES.HR.LOANS_DATA_TITLE' | translate }}
          </a>
          <ng-template ngbNavContent>
            <app-loans></app-loans>
          </ng-template>
        </li>
        <li ngbNavItem="settlements">
          <a ngbNavLink>
            <i class="fa fa-calendar-minus-o" [class.success]="activePage == 'settlements'"></i>
            {{ 'FINANCES.HR.LOAN_SETTLEMENTS_DATA_TITLE' | translate }}
          </a>
          <ng-template ngbNavContent>
            <app-loan-settlements></app-loan-settlements>
          </ng-template>
        </li>
      </ul>
      <div [ngbNavOutlet]="nav" class="mt-2"></div>
    </div>
  </div>
</div>
<!-- loan pages end -->
