import { CreateBankComponent } from 'finances/components/banks/create-bank/create-bank.component';
import { UpdateBankComponent } from 'finances/components/banks/update-bank/update-bank.component';
import { BanksListComponent } from 'finances/components/banks/banks-list/banks-list.component';
import { BanksSearchComponent } from 'finances/components/banks/banks-search/banks-search.component';

export * from 'finances/components/banks/create-bank/create-bank.component';
export * from 'finances/components/banks/update-bank/update-bank.component';
export * from 'finances/components/banks/banks-list/banks-list.component';
export * from 'finances/components/banks/banks-search/banks-search.component';

/**
 * The set of components registered on this sub-module.
 */
export const COMPONENTS: any[] = [CreateBankComponent, UpdateBankComponent, BanksListComponent, BanksSearchComponent];
