import { createSelector } from '@ngrx/store';

import { selectLookupsState } from 'lookups/store/selectors/feature.selectors';
import { LookupsState, ProjectResourceTypeState } from 'lookups/store/states';

/**
 * Gets the project resource types state.
 */
const selectProjectResourceTypes = createSelector(
  selectLookupsState,
  (state: LookupsState) => state.projectResourceTypes
);

/**
 * Gets the list of loaded project resource types.
 */
export const getProjectResourceTypes = createSelector(
  selectProjectResourceTypes,
  (state: ProjectResourceTypeState) => state.data
);

/**
 * Gets the error that occurred in the state.
 */
export const getProjectResourceTypesError = createSelector(
  selectProjectResourceTypes,
  (state: ProjectResourceTypeState) => state.error
);

/**
 * Determines if there is a running load process.
 */
export const getProjectResourceTypesIsLoading = createSelector(
  selectProjectResourceTypes,
  (state: ProjectResourceTypeState) => state.isLoading
);

/**
 * Determines if the last load process has been ended successfully.
 */
export const getProjectResourceTypesIsLoaded = createSelector(
  selectProjectResourceTypes,
  (state: ProjectResourceTypeState) => state.isLoaded
);
