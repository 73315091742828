import { AssetType } from 'lookups/models';

/**
 * Represents the AssetTypes state.
 */
export class AssetTypeState {
  /**
   * The list of AssetTypes.
   */
  public data: AssetType[];

  /**
   * An error that may resulting during processing some actions.
   */
  public error: any;

  /**
   * Determines if there is a running load process.
   */
  public isLoading: boolean;

  /**
   * Determines if the last load process has been ended successfully.
   */
  public isLoaded: boolean;
}
