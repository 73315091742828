import { Component, OnInit, ViewChild, ElementRef, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms';

import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Store, select } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';

import { NotificationService, NotificationMessage, CustomValidators, TranslationService, APP_CONSTANTS } from 'shared';
import * as fromSalesStore from 'sales/store';
import * as fromLookupsStore from 'lookups/store';
import * as fromAuthStore from 'auth/store';
import { City, CustomerCategories, CustomerCategory } from 'lookups/models';
import { Location } from 'stores/models';
import { CreateCustomerAssetInput, CreateCustomerEngagementInput } from 'sales/models';

/**
 * The customer pages.
 */
enum PAGES {
  details = 'details',
  assets = 'assets',
  engagements = 'engagements',
}

@Component({
  selector: 'app-create-customer-in-modal',
  templateUrl: './create-customer-in-modal.component.html',
  styles: [],
})
export class CreateCustomerInModalComponent implements OnInit, OnDestroy {
  /**
   * The confirm modal template reference.
   */
  @ViewChild('confirmModalRef') confirmModalRef: ElementRef<any>;

  /**
   * The modal template reference.
   */
  @ViewChild('modalRef') modalRef: ElementRef<any>;

  /**
   * Indicates whether the modal will automatically close after select the first customer.
   *
   * Be default it will not be closed.
   */
  @Input() closeOnSelect = false;

  /**
   * Indicates whether the customers displayed on the list should be all active.
   *
   * Be default it will not be closed.
   */
  @Input() onlyActive = false;

  /**
   * Sets the visibility of this component modal.
   *
   * Handles visibility change in two-data-binding approach.
   */
  @Input() set visible(isShown: boolean) {
    if (isShown && this.modalRef) {
      this.openModal(this.modalRef, 'lg');
    } else {
      this.closeModal();
    }

    this.visibleChange.emit(isShown);
  }

  /**
   * Outputs a value that indicates whether the modal is shown or not.
   *
   * Handles visibility change in two-data-binding approach.
   */
  @Output() visibleChange = new EventEmitter<boolean>();

  /**
   * Gets or sets the selected page.
   * @default 'details'
   */
  activePage: PAGES = PAGES.details;

  /**
   * The ngb-modal reference to use it when modal needed to be closed.
   */
  modal: NgbModalRef;

  /**
   * The list of active locations.
   */
  locations: Location[] = [];

  /**
   * Shows or hide the locations list.
   */
  locationsListVisibility = false;

  /**
   * Gets or sets the phone pattern.
   */
  public phonePattern = APP_CONSTANTS.phone.pattern;

  /**
   * Gets or sets the phone mask.
   */
  public phoneMask = APP_CONSTANTS.phone.mask;

  /**
   * The list of customer Categories.
   */
  customerCategories$: Observable<CustomerCategory[]>;

  /**
   * The list of cities.
   */
  cities$: Observable<City[]>;

  /**
   * Gets the customer category organization id.
   */
  customerCategoryOrganizationId: number;

  /**
   * The create customer form.
   */
  form: FormGroup;

  /**
   * The set of subscriptions on this components,
   * these subscriptions must be unsubscribed before this component got destroyed.
   */
  subscriptions = new Subscription();

  /**
   * Indicates whether there is a create customer process is running or not.
   */
  isCreating$: Observable<boolean>;

  /**
   * Gets the assets form-array.
   */
  get assetsForm(): FormArray {
    return this.form?.controls.assets as FormArray;
  }

  /**
   * Gets the customer assets form-array.
   */
  get engagements(): FormArray {
    return this.form?.controls.engagements as FormArray;
  }

  /**
   * @param modalService The modal service.
   * @param notificationService The notification service.
   * @param translationService: The translation service.
   * @param salesStore$ The sales-module store.
   * @param lookupsStore$ The lookups-module store.
   * @param authStore$ the auth-store module.
   */
  constructor(
    private modalService: NgbModal,
    private notificationService: NotificationService,
    private translationService: TranslationService,
    private salesStore$: Store<fromSalesStore.SalesState>,
    private lookupsStore$: Store<fromLookupsStore.LookupsState>,
    private authStore$: Store<fromAuthStore.AuthState>
  ) {}

  ngOnInit(): void {
    this.init();
  }

  /**
   * Destroy component data
   */
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  /**
   * Initialize component data.
   */
  init() {
    this.initForm();

    /**
     * Set data.
     */
    this.isCreating$ = this.salesStore$.pipe(select(fromSalesStore.getSelectedCustomerCreating));

    let isManualSearchTriggeredBeforeForCategories = false;
    this.customerCategories$ = this.lookupsStore$.pipe(
      select(fromLookupsStore.getCustomerCategories),

      /*
       * Get the 'ORGANIZATION' customer category organization id.
       */
      tap((customerCategories) => {
        if (!isManualSearchTriggeredBeforeForCategories && !customerCategories.length) {
          isManualSearchTriggeredBeforeForCategories = true;
          this.lookupsStore$.dispatch(new fromLookupsStore.GetAllCustomerCategory());
        } else if (customerCategories?.length) {
          this.customerCategoryOrganizationId = customerCategories.find(
            (customerCategory) => customerCategory.key === CustomerCategories.ORGANIZATION
          )?.id;
        }
      })
    );

    let isManualSearchTriggeredBeforeForCities = false;
    this.cities$ = this.lookupsStore$.pipe(
      select(fromLookupsStore.getCities),
      tap((cities) => {
        if (!isManualSearchTriggeredBeforeForCities && !cities.length) {
          isManualSearchTriggeredBeforeForCities = true;
          this.lookupsStore$.dispatch(new fromLookupsStore.GetAllCities());
        }
      })
    );

    /* Subscribe to selected location to select it whenever it changed. */
    this.subscriptions.add(
      this.authStore$
        .pipe(
          select(fromAuthStore.getSessionUserDefaultLocation),
          tap((location) => {
            if (location) {
              this.selectLocation([location]);
            }
          })
        )
        .subscribe()
    );
  }

  /**
   * Initialize form and add validators.
   */
  initForm() {
    const customerCategoryIdFormControl = new FormControl(null, Validators.required);

    this.form = new FormGroup({
      name: new FormControl('', [Validators.required, Validators.minLength(1), Validators.maxLength(50)]),
      nameEn: new FormControl('', [Validators.required, Validators.minLength(1), Validators.maxLength(50)]),
      cityId: new FormControl(null, Validators.required),
      locationId: new FormControl(null),
      customerCategoryId: customerCategoryIdFormControl,
      vatNumber: new FormControl('', [CustomValidators.number, Validators.minLength(0), Validators.maxLength(50)]),
      commercialRegistrationNo: new FormControl('', [Validators.minLength(0), Validators.maxLength(10)]),
      buildingNo: new FormControl('', [Validators.minLength(0), Validators.maxLength(50)]),
      streetName: new FormControl('', [Validators.minLength(0), Validators.maxLength(50)]),
      district: new FormControl('', [Validators.minLength(0), Validators.maxLength(50)]),
      country: new FormControl('', [Validators.minLength(0), Validators.maxLength(50)]),
      postalCode: new FormControl('', [Validators.minLength(0), Validators.maxLength(50)]),
      additionalNo: new FormControl('', [Validators.minLength(0), Validators.maxLength(50)]),
      otherBuyerId: new FormControl('', [Validators.minLength(0), Validators.maxLength(50)]),
      moreInfo: new FormControl('', [Validators.minLength(0), Validators.maxLength(200)]),
      representorName: new FormControl('', [Validators.minLength(0), Validators.maxLength(50)]),
      representorMobile: new FormControl('', [Validators.required, Validators.minLength(1), Validators.maxLength(11)]),
      representorPhone: new FormControl('', [Validators.minLength(0), Validators.maxLength(11)]),
      representorEmail: new FormControl('', [Validators.email]),
      assets: new FormArray([]),
      engagements: new FormArray([]),
    });

    /**
     * Dynamically set form validations based on the selected customer category.
     */
    this.subscriptions.add(customerCategoryIdFormControl.valueChanges.subscribe(() => this.setValidations()));
  }

  /**
   * Submits the form.
   */
  submit() {
    if (this.form.invalid) {
      const errorMessage = new NotificationMessage();

      if (this.form.get('name').invalid) {
        errorMessage.title = this.translationService.translate(
          'SALES.CUSTOMERS.CUSTOMER_DATA_VALIDATION.CUSTOMER_NAME_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate('SALES.CUSTOMERS.CUSTOMER_DATA_VALIDATION.CUSTOMER_NAME_IS_REQUIRED'),
          this.translationService.translate('SALES.CUSTOMERS.CUSTOMER_DATA_VALIDATION.CUSTOMER_NAME_LENGTH_ERROR'),
        ];
      } else if (this.form.get('nameEn').invalid) {
        errorMessage.title = this.translationService.translate(
          'SALES.CUSTOMERS.CUSTOMER_DATA_VALIDATION.CUSTOMER_ENGLISH_NAME_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate('SALES.CUSTOMERS.CUSTOMER_DATA_VALIDATION.CUSTOMER_NAME_EN_IS_REQUIRED'),
          this.translationService.translate('SALES.CUSTOMERS.CUSTOMER_DATA_VALIDATION.CUSTOMER_NAME_EN_LENGTH_ERROR'),
        ];
      } else if (this.form.get('customerCategoryId').invalid) {
        errorMessage.title = this.translationService.translate(
          'SALES.CUSTOMERS.CUSTOMER_DATA_VALIDATION.CUSTOMER_TYPE_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate('SALES.CUSTOMERS.CUSTOMER_DATA_VALIDATION.CUSTOMER_TYPE_IS_REQUIRED'),
        ];
      } else if (this.form.get('commercialRegistrationNo').invalid) {
        errorMessage.title = this.translationService.translate(
          'SALES.CUSTOMERS.CUSTOMER_DATA_VALIDATION.COMMERCIAL_REGISTRY_NUMBER_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate(
            'SALES.CUSTOMERS.CUSTOMER_DATA_VALIDATION.COMMERCIAL_REGISTRY_NUMBER_IS_REQUIRED'
          ),
          this.translationService.translate(
            'SALES.CUSTOMERS.CUSTOMER_DATA_VALIDATION.COMMERCIAL_REGISTRY_NUMBER_LENGTH_ERROR'
          ),
        ];
      } else if (this.form.get('vatNumber').invalid) {
        errorMessage.title = this.translationService.translate(
          'SALES.CUSTOMERS.CUSTOMER_DATA_VALIDATION.VAT_NUMBER_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate('SALES.CUSTOMERS.CUSTOMER_DATA_VALIDATION.VAT_NUMBER_LENGTH_ERROR'),
        ];
      } else if (this.form.get('cityId').invalid) {
        errorMessage.title = this.translationService.translate(
          'SALES.CUSTOMERS.CUSTOMER_DATA_VALIDATION.CITY_NAME_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate('SALES.CUSTOMERS.CUSTOMER_DATA_VALIDATION.CITY_NAME_IS_REQUIRED'),
        ];
      } else if (this.form.get('buildingNo').invalid) {
        errorMessage.title = this.translationService.translate(
          'SALES.CUSTOMERS.CUSTOMER_DATA_VALIDATION.BUILDING_NUMBER_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate('SALES.CUSTOMERS.CUSTOMER_DATA_VALIDATION.BUILDING_NUMBER_IS_REQUIRED'),
          this.translationService.translate('SALES.CUSTOMERS.CUSTOMER_DATA_VALIDATION.BUILDING_NUMBER_LENGTH_ERROR'),
        ];
      } else if (this.form.get('streetName').invalid) {
        errorMessage.title = this.translationService.translate(
          'SALES.CUSTOMERS.CUSTOMER_DATA_VALIDATION.STREET_NAME_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate('SALES.CUSTOMERS.CUSTOMER_DATA_VALIDATION.STREET_NAME_IS_REQUIRED'),
          this.translationService.translate('SALES.CUSTOMERS.CUSTOMER_DATA_VALIDATION.STREET_NAME_LENGTH_ERROR'),
        ];
      } else if (this.form.get('district').invalid) {
        errorMessage.title = this.translationService.translate(
          'SALES.CUSTOMERS.CUSTOMER_DATA_VALIDATION.DISTRICT_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate('SALES.CUSTOMERS.CUSTOMER_DATA_VALIDATION.DISTRICT_IS_REQUIRED'),
          this.translationService.translate('SALES.CUSTOMERS.CUSTOMER_DATA_VALIDATION.DISTRICT_LENGTH_ERROR'),
        ];
      } else if (this.form.get('country').invalid) {
        errorMessage.title = this.translationService.translate(
          'SALES.CUSTOMERS.CUSTOMER_DATA_VALIDATION.COUNTRY_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate('SALES.CUSTOMERS.CUSTOMER_DATA_VALIDATION.COUNTRY_IS_REQUIRED'),
          this.translationService.translate('SALES.CUSTOMERS.CUSTOMER_DATA_VALIDATION.COUNTRY_LENGTH_ERROR'),
        ];
      } else if (this.form.get('postalCode').invalid) {
        errorMessage.title = this.translationService.translate(
          'SALES.CUSTOMERS.CUSTOMER_DATA_VALIDATION.POSTAL_CODE_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate('SALES.CUSTOMERS.CUSTOMER_DATA_VALIDATION.POSTAL_CODE_IS_REQUIRED'),
          this.translationService.translate('SALES.CUSTOMERS.CUSTOMER_DATA_VALIDATION.POSTAL_CODE_LENGTH_ERROR'),
        ];
      } else if (this.form.get('additionalNo').invalid) {
        errorMessage.title = this.translationService.translate(
          'SALES.CUSTOMERS.CUSTOMER_DATA_VALIDATION.ADDITIONAL_NUMBER_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate('SALES.CUSTOMERS.CUSTOMER_DATA_VALIDATION.ADDITIONAL_NUMBER_IS_REQUIRED'),
          this.translationService.translate('SALES.CUSTOMERS.CUSTOMER_DATA_VALIDATION.ADDITIONAL_NUMBER_LENGTH_ERROR'),
        ];
      } else if (this.form.get('otherBuyerId').invalid) {
        errorMessage.title = this.translationService.translate(
          'SALES.CUSTOMERS.CUSTOMER_DATA_VALIDATION.OTHER_BUYER_ID_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate('SALES.CUSTOMERS.CUSTOMER_DATA_VALIDATION.OTHER_BUYER_ID_LENGTH_ERROR'),
        ];
      } else if (this.form.get('moreInfo').invalid) {
        errorMessage.title = this.translationService.translate(
          'SALES.CUSTOMERS.CUSTOMER_DATA_VALIDATION.EXTRA_DATA_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate('SALES.CUSTOMERS.CUSTOMER_DATA_VALIDATION.EXTRA_DATA_LENGTH_ERROR'),
        ];
      } else if (this.form.get('representorName').invalid) {
        errorMessage.title = this.translationService.translate(
          'SALES.CUSTOMERS.CUSTOMER_DATA_VALIDATION.RESPONSIBLE_NAME_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate('SALES.CUSTOMERS.CUSTOMER_DATA_VALIDATION.RESPONSIBLE_NAME_IS_REQUIRED'),
          this.translationService.translate('SALES.CUSTOMERS.CUSTOMER_DATA_VALIDATION.RESPONSIBLE_NAME_LENGTH_ERROR'),
        ];
      } else if (this.form.get('representorMobile').invalid) {
        errorMessage.title = this.translationService.translate(
          'SALES.CUSTOMERS.CUSTOMER_DATA_VALIDATION.RESPONSIBLE_MOBILE_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate('SALES.CUSTOMERS.CUSTOMER_DATA_VALIDATION.RESPONSIBLE_MOBILE_IS_REQUIRED'),
          this.translationService.translate('SALES.CUSTOMERS.CUSTOMER_DATA_VALIDATION.RESPONSIBLE_MOBILE_LENGTH_ERROR'),
        ];
      } else if (this.form.get('representorPhone').invalid) {
        errorMessage.title = this.translationService.translate(
          'SALES.CUSTOMERS.CUSTOMER_DATA_VALIDATION.RESPONSIBLE_PHONE_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate('SALES.CUSTOMERS.CUSTOMER_DATA_VALIDATION.RESPONSIBLE_PHONE_LENGTH_ERROR'),
        ];
      } else if (this.form.get('representorEmail').invalid) {
        errorMessage.title = this.translationService.translate(
          'SALES.CUSTOMERS.CUSTOMER_DATA_VALIDATION.RESPONSIBLE_EMAIL_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate('SALES.CUSTOMERS.CUSTOMER_DATA_VALIDATION.RESPONSIBLE_EMAIL_VALUE_ERROR'),
        ];
      }

      this.form.markAllAsTouched();
      return this.notificationService.warningWithTitle(errorMessage);
    } else {
      this.openConfirmModal(this.confirmModalRef);
    }
  }

  /**
   * Sets the conditional validation for the form based on the selected customer category.
   */
  setValidations() {
    const customerCategoryId = parseInt(this.form.controls.customerCategoryId.value ?? '0');
    /**
     * Check if the customer category is organization.
     */
    if (customerCategoryId === this.customerCategoryOrganizationId) {
      this.form.controls.commercialRegistrationNo.setValidators([
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(10),
      ]);
      this.form.controls.vatNumber.setValidators([
        CustomValidators.number,
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(50),
      ]);
      this.form.controls.representorName.setValidators([
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(50),
      ]);
      this.form.controls.buildingNo.setValidators([
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(50),
      ]);
      this.form.controls.streetName.setValidators([
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(50),
      ]);
      this.form.controls.district.setValidators([
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(50),
      ]);
      this.form.controls.country.setValidators([
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(50),
      ]);
      this.form.controls.postalCode.setValidators([
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(50),
      ]);
      this.form.controls.additionalNo.setValidators([
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(50),
      ]);
    } else {
      this.form.controls.commercialRegistrationNo.setValidators([Validators.minLength(0), Validators.maxLength(10)]);
      this.form.controls.vatNumber.setValidators([
        CustomValidators.number,
        Validators.minLength(0),
        Validators.maxLength(50),
      ]);
      this.form.controls.representorName.setValidators([Validators.minLength(0), Validators.maxLength(50)]);
      this.form.controls.buildingNo.setValidators([Validators.minLength(0), Validators.maxLength(50)]);
      this.form.controls.streetName.setValidators([Validators.minLength(0), Validators.maxLength(50)]);
      this.form.controls.district.setValidators([Validators.minLength(0), Validators.maxLength(50)]);
      this.form.controls.country.setValidators([Validators.minLength(0), Validators.maxLength(50)]);
      this.form.controls.postalCode.setValidators([Validators.minLength(0), Validators.maxLength(50)]);
      this.form.controls.additionalNo.setValidators([Validators.minLength(0), Validators.maxLength(50)]);
    }
    this.form.controls.commercialRegistrationNo.updateValueAndValidity();
    this.form.controls.vatNumber.updateValueAndValidity();
    this.form.controls.representorName.updateValueAndValidity();
    this.form.controls.buildingNo.updateValueAndValidity();
    this.form.controls.streetName.updateValueAndValidity();
    this.form.controls.district.updateValueAndValidity();
    this.form.controls.country.updateValueAndValidity();
    this.form.controls.postalCode.updateValueAndValidity();
    this.form.controls.additionalNo.updateValueAndValidity();
  }

  /**
   * Confirms the form submit.
   */
  confirm() {
    /**
     * The list of assets in the lines form.
     */
    const customerAssets = this.assetsForm.value.map((item: CreateCustomerAssetInput) => {
      const customerAsset: CreateCustomerAssetInput = {
        customerId: item.customerId,
        description: item.description,
        type: item.type,
        serialNumber: item.serialNumber,
        attachments: item.attachments.filter((file) => !!file),
      };

      return customerAsset;
    });
    const customerEngagements = this.engagements.value.map((item: CreateCustomerEngagementInput) => {
      const customerEngagement: CreateCustomerEngagementInput = {
        customerEngagementTypeId: item.customerEngagementTypeId,
        value: item.value,
        notes: item.notes,
      };

      return customerEngagement;
    });

    const { vatNumber, representorPhone, representorMobile, commercialRegistrationNo, ...rest } = this.form.value;
    this.salesStore$.dispatch(
      new fromSalesStore.CreateCustomer({
        ...rest,
        locationId: this.form.value.locationId,
        representorPhone: representorPhone?.toString(),
        representorMobile: representorMobile?.toString(),
        vatNumber: vatNumber?.toString(),
        commercialRegistrationNo: commercialRegistrationNo?.toString(),
        customerAssets,
        customerEngagements,
      })
    );
  }

  /**
   * Opens the modal of the given templateRef.
   * @param modalRef The modal templateRef to be opened.
   */
  openConfirmModal(modalRef) {
    this.modalService.open(modalRef);
  }

  /**
   * Opens the modal of the given templateRef.
   * @param modalRef The modal templateRef to be opened.
   * @param size An optional modal size, default sm.
   */
  openModal(modalRef, size = 'lg') {
    /**
     * Open the modal & listen for user press ESC key.
     */
    this.modal = this.modalService.open(modalRef, { size });
    this.modal.result.then(null, () => this.closeModal());
  }

  /**
   * Closes the currently opened modal.
   */
  closeModal() {
    this.modal?.dismiss();
    this.visibleChange.emit(false);
  }

  /**
   * Selects the newly selected location from the locations search list.
   * @param locations The list of newly selected locations to select the only one in the list.
   */
  selectLocation([location]: Location[]) {
    if (location) {
      this.locations = [location];
      this.form.patchValue({ locationId: location.id });
    }
  }
}
