<div [formGroup]="formArray.parent" class="row">
  <div class="col-md-12">
    <button
      type="button"
      class="btn btn-sm btn-raised btn-primary round"
      [ngbTooltip]="'STORES.PRODUCTS.PRODUCT_DATA.ADD_PRODUCT_INGREDIENT' | translate"
      (click)="ProductsListVisibility = true"
    >
      <i class="fa fa-plus"></i>
    </button>
    <button
      type="button"
      class="btn btn-sm btn-raised btn-danger round mx-2"
      [ngbTooltip]="'STORES.PRODUCTS.PRODUCT_DATA.DELETE_PRODUCT_INGREDIENT' | translate"
      (click)="resetIngredients()"
    >
      <i class="fa fa-trash"></i>
    </button>
  </div>
  <div class="col-md-12">
    <div class="table-responsive">
      <table class="table table-sm table-bordered text-center">
        <thead>
          <tr>
            <th scope="col"><i class="fa fa-list-ol"></i></th>
            <th scope="col">
              {{ 'STORES.PRODUCTS.PRODUCT_DATA.PRODUCT_INGREDIENT_ID' | translate }}
            </th>
            <th scope="col">
              {{ 'STORES.PRODUCTS.PRODUCT_DATA.PRODUCT_INGREDIENT_QUANTITY' | translate }}
            </th>
            <th scope="col">
              {{ 'STORES.PRODUCTS.PRODUCT_DATA.PRODUCT_INGREDIENT_MEASURE_UNIT' | translate }}
            </th>
            <th scope="col">{{ 'SHARED.TOOLS' | translate }}</th>
          </tr>
        </thead>
        <tbody [formArrayName]="formArrayName">
          <tr
            app-sub-product-ingredients-form-item
            *ngFor="let item of formArray.controls; let i = index"
            [formGroup]="item"
            [index]="i"
            (removeChange)="removeIngredient(i)"
          ></tr>
        </tbody>
        <tfoot class="tfoot-light">
          <tr>
            <td [attr.colspan]="5">
              <i class="fa fa-bookmark c-primary"></i>
              <span>
                {{ formArray.controls.length | toNumber }}
                {{ 'STORES.PRODUCTS.PRODUCT_DATA.PRODUCT_INGREDIENTS_COUNT' | translate }}
              </span>
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  </div>
</div>

<!-- product classes search starts -->
<app-products-search [closeOnSelect]="false" [(visible)]="ProductsListVisibility" (products)="selectProduct($event)">
</app-products-search>
<!-- product classes search ends -->
