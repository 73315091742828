import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';

import { AppHttpResponse } from 'shared';
import { SaleReturn } from 'sales/models';
import { CreateSaleReturnInput } from 'sales/models';

/**
 * The sale returns services includes functionality to create, search and findById a sale returns.
 */
@Injectable()
export class SaleReturnsService {
  /**
   * The relative route for the customers.
   *
   * No leading or trailing slashes required.
   */
  private saleReturnsApi = 'sales/returns';

  constructor(private http: HttpClient) {}

  /**
   * Creates a new customer from the provided data.
   * @param data The new sale returns data.
   */
  public create(data: CreateSaleReturnInput): Observable<AppHttpResponse<SaleReturn>> {
    const formData: any = new FormData();
    formData.append('saleInvoiceId', data.saleInvoiceId);
    formData.append('locationId', data.locationId);
    formData.append('isRefundForReturns', data.isRefundForReturns);
    formData.append('bankAccountId', data.bankAccountId);
    formData.append('bankFee', data.bankFee);
    formData.append('bankFeeTaxValue', data.bankFeeTaxValue);
    formData.append('transactionDate', data.transactionDate?.toISOString());
    formData.append('notes', data.notes);

    /**
     * Append products to the form data.
     */
    for (let index = 0; index < data.products.length; index++) {
      formData.append(`products[${index}][saleInvoiceProductId]`, data.products[index].saleInvoiceProductId);
      formData.append(`products[${index}][productId]`, data.products[index].productId);
      formData.append(`products[${index}][subProductId]`, data.products[index].subProductId);
      formData.append(`products[${index}][projectSubTaskId]`, data.products[index].projectSubTaskId);
      formData.append(`products[${index}][description]`, data.products[index].description);
      formData.append(`products[${index}][quantity]`, data.products[index].quantity);
      formData.append(`products[${index}][unitOfMeasureId]`, data.products[index].unitOfMeasureId);
      formData.append(`products[${index}][value]`, data.products[index].value);
      formData.append(`products[${index}][discount]`, data.products[index].discount);
      formData.append(`products[${index}][discountPercent]`, data.products[index].discountPercent);
      formData.append(`products[${index}][tax]`, data.products[index].tax);
    }

    /**
     * Append journal lines to the form data.
     */
    for (let index = 0; index < data.lines.length; index++) {
      formData.append(`lines[${index}][accountId]`, data.lines[index].accountId);
      formData.append(`lines[${index}][credit]`, data.lines[index].credit);
      formData.append(`lines[${index}][debit]`, data.lines[index].debit);
      formData.append(`lines[${index}][costCenterId]`, data.lines[index].costCenterId);
      formData.append(`lines[${index}][notes]`, data.lines[index].notes);
    }

    /**
     * Append attachments to the form data.
     */
    data.attachments.forEach((attachment) => formData.append(`attachments[]`, attachment, attachment.name));

    return this.http.post<AppHttpResponse<SaleReturn>>(`${this.saleReturnsApi}`, formData);
  }

  /**
   * Searches in the sale returns by
   * @param locations The locations of the sale returns.
   * @param fromDate The fromDate of the sale returns.
   * @param toDate The toDate of the sale returns.
   * @param page The current pagination page number.
   * @param pageSize The maximum number of sale returns allowed per one pagination page.
   */
  public search(
    customers: number[],
    locations: number[],
    saleReturnNum: number,
    invoiceNum: number,
    fromDate: Date,
    toDate: Date,
    page: number,
    pageSize: number
  ): Observable<AppHttpResponse<SaleReturn[]>> {
    const params = new HttpParams()
      .set('customers', customers.join(','))
      .set('locations', locations.join(','))
      .set('saleReturnNum', saleReturnNum?.toString() ?? '')
      .set('invoiceNum', invoiceNum?.toString() ?? '')
      .set('fromDate', fromDate?.toISOString())
      .set('toDate', toDate?.toISOString())
      .set('page', page.toString())
      .set('pageSize', pageSize.toString());
    return this.http.get<AppHttpResponse<SaleReturn[]>>(`${this.saleReturnsApi}`, { params });
  }

  /**
   * Finds the sale returns with the given id.
   * @param id The id of the sale returns.
   */
  public findById(id: number): Observable<AppHttpResponse<SaleReturn>> {
    return this.http.get<AppHttpResponse<SaleReturn>>(`${this.saleReturnsApi}/${id}`);
  }
}
