import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subscription } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { tap } from 'rxjs/operators';

import * as fromLookupsStore from 'lookups/store';
import { ProjectResourceType } from 'lookups/models';
import { ProjectPlanningResourceFormItem } from 'projects-management/models';

@Component({
  selector: 'app-project-planning-resources',
  templateUrl: './project-planning-resources.component.html',
  styles: [],
})
export class ProjectPlanningResourcesComponent implements OnInit, OnDestroy {
  /**
   * The confirm modal template reference.
   */
  @ViewChild('confirmModalRef') confirmModalRef: ElementRef<any>;

  /**
   * The confirm modal template reference.
   */
  @ViewChild('deleteModalRef') deleteModalRef: ElementRef<any>;

  /**
   * Sets the project planning-resources form-array.
   */
  @Input() formArray: FormArray;

  /**
   * Sets the name form-array in its parent form.
   * @default 'projectPlanningResources'
   */
  @Input() formArrayName = 'projectPlanningResources';

  /**
   * Adds a list of new project planning resources form items to the project planning resources form.
   * @param items The list of project planning resources form items to be added to the project planning resources form.
   */
  @Input() set newProjectPlanningResources(items: ProjectPlanningResourceFormItem[]) {
    if (!items?.length) {
      return;
    }

    items.forEach((item) => this.createProjectPlanningResourceFormGroup(item));
  }

  /**
   * Sets a value indicates whether if the user should specify a project planning resource or not.
   * @default false
   */
  @Input() isProjectPlanningResourceRequired = false;

  /**
   * Indicates whether if we can add project planning resource or not.
   */
  @Input() allowAddProjectPlanningResource = true;

  /**
   * Indicates whether if we can clear projectPlanning resources or not.
   * @default `true`.
   */
  @Input() allowClearProjectPlanningResources = true;

  /**
   * Shows or hides the bank accounts list.
   */
  projectPlanningResourcesListVisibility = false;

  /**
   * The list of project status.
   */
  projectResourceTypes$: Observable<ProjectResourceType[]>;

  /**
   * The set of subscriptions on this components,
   * these subscriptions must be unsubscribed before this component got destroyed.
   */
  subscriptions = new Subscription();

  /**
   * @param modalService The modal service.
   * @param lookupsStore$ the lookups-store module.
   */
  constructor(private modalService: NgbModal, private lookupsStore$: Store<fromLookupsStore.LookupsState>) {}

  ngOnInit(): void {
    this.init();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  /**
   * Initialize component data.
   */
  init() {
    /**
     * Set data.
     */
    let isManualSearchTriggeredBeforeForProjectResourceTypes = false;
    this.projectResourceTypes$ = this.lookupsStore$.pipe(
      select(fromLookupsStore.getProjectResourceTypes),
      tap((projectResourceTypes) => {
        if (!isManualSearchTriggeredBeforeForProjectResourceTypes && !projectResourceTypes.length) {
          isManualSearchTriggeredBeforeForProjectResourceTypes = true;
          this.lookupsStore$.dispatch(new fromLookupsStore.GetAllProjectResourceTypes());
        }
      })
    );
  }

  /**
   * Creates & adds a new project-planning-resource form-group with validations.
   */
  addProjectPlanningResource() {
    this.openModal(this.confirmModalRef);
  }

  /**
   * Open confirm delete all project planning resources modal .
   */
  openDeleteModal() {
    this.openModal(this.deleteModalRef);
  }

  /**
   * Opens the modal of the given templateRef.
   * @param modalRef The modal templateRef to be opened.
   */
  openModal(modalRef) {
    this.modalService.open(modalRef);
  }

  /**
   * Remove the project planning-resource at the given index from the project planning-resources form.
   * @param index The index of the project planning-resource form group.
   */
  removeProjectPlanningResource(index: number) {
    this.formArray.removeAt(index);
  }

  /**
   * Remove all of the list of project-planning-resources.
   *
   * It only adds one project-planning-resource for quick start.
   */
  resetProjectPlanningResources() {
    this.formArray.clear();
  }

  /**
   * Handles the project planning resource select event.
   * @param projectPlanningResource The newly selected project planning resource.
   */
  selectProjectPlanningResource([projectPlanningResource]: ProjectResourceType[]) {
    if (projectPlanningResource) {
      this.createProjectPlanningResourceFormGroup(
        this.createProjectPlanningResourceFormItemFromProjectPlanningResource(projectPlanningResource)
      );
    }
  }

  /**
   * Creates and returns a new project planning resource form item from a given project planning resource.
   * @param projectPlanningResource The project planning resource to be used to create the project planning resource form item.
   */
  createProjectPlanningResourceFormItemFromProjectPlanningResource(
    projectPlanningResource: ProjectResourceType
  ): ProjectPlanningResourceFormItem {
    return {
      projectPlanningResourceId: projectPlanningResource.id,
      projectPlanningResource,
    };
  }

  /**
   * Create a new project planning resource form group from the provided project planning resource form item.
   * @param projectPlanningResource The project planning resource form item that contains data about the new project planning resource.
   */
  createProjectPlanningResourceFormGroup(projectPlanningResource: ProjectPlanningResourceFormItem) {
    if (this.isProjectPlanningResourceExists(projectPlanningResource.projectPlanningResource.id)) {
      return;
    }

    const formGroup = new FormGroup({
      projectPlanningResourceId: new FormControl(projectPlanningResource?.projectPlanningResourceId ?? null),
      projectPlanningResource: new FormControl(projectPlanningResource?.projectPlanningResource),
    });
    this.formArray.push(formGroup);
  }

  /**
   * Indicates whether if the given project planning resource id is already exists in the form array or not.
   * @param projectPlanningResourceId The id of the project planning resource.
   * @returns `boolean`.
   */
  isProjectPlanningResourceExists(projectPlanningResourceId: number): boolean {
    return (this.formArray.value as ProjectPlanningResourceFormItem[]).some(
      (projectPlanningResourceItem) =>
        projectPlanningResourceItem.projectPlanningResource.id === projectPlanningResourceId
    );
  }
}
