<!-- page header starts -->
<app-page-header [info]="pageInfo"></app-page-header>
<!-- page header ends -->

<!-- petty cash list starts -->
<div class="row">
  <div class="col-md-12">
    <div class="card">
      <div class="card-header mb-3">
        <h4 class="card-title">{{ 'SHARED.SEARCH.SEARCH_TOOLS' | translate }}</h4>
      </div>
      <div class="card-content">
        <div class="px-3">
          <div class="row">
            <div class="col-md-12">
              <form class="row" [formGroup]="searchForm" (ngSubmit)="search()">
                <div class="form-group col">
                  <label for="description">{{
                    'FINANCES.PETTY_CASH.PETTY_CASH_DATA.PETTY_CASH_DESCRIPTION' | translate
                  }}</label>
                  <input
                    id="description"
                    type="text"
                    class="form-control round"
                    [placeholder]="
                      'FINANCES.PETTY_CASH.PETTY_CASH_DATA.PETTY_CASH_DESCRIPTION_SEARCH_PLACEHOLDER' | translate
                    "
                    formControlName="description"
                    (input)="search($event)"
                  />
                </div>
                <div class="form-group col">
                  <label for="types">{{ 'FINANCES.PETTY_CASH.PETTY_CASH_DATA.PETTY_CASH_TYPE' | translate }}</label>
                  <ng-select
                    class="round"
                    formControlName="types"
                    [multiple]="true"
                    [closeOnSelect]="false"
                    labelForId="types"
                    searchable="true"
                    [placeholder]="'FINANCES.PETTY_CASH.PETTY_CASH_DATA.PETTY_CASH_TYPE_PLACEHOLDER' | translate"
                    [notFoundText]="'SHARED.SEARCH.SEARCH_NOT_FOUND' | translate"
                  >
                    <ng-option *ngFor="let pettyCashType of pettyCashTypes$ | async" [value]="pettyCashType.id">
                      {{ pettyCashType.name | localize: pettyCashType.nameEn | async }}
                    </ng-option>
                  </ng-select>
                </div>
                <div class="form-group col">
                  <label for="specifications">{{
                    'FINANCES.PETTY_CASH.PETTY_CASH_DATA.PETTY_CASH_SPECIFICATION' | translate
                  }}</label>
                  <ng-select
                    class="round"
                    formControlName="specifications"
                    [multiple]="true"
                    [closeOnSelect]="false"
                    labelForId="specifications"
                    searchable="true"
                    [placeholder]="
                      'FINANCES.PETTY_CASH.PETTY_CASH_DATA.PETTY_CASH_SPECIFICATION_PLACEHOLDER' | translate
                    "
                    [notFoundText]="'SHARED.SEARCH.SEARCH_NOT_FOUND' | translate"
                  >
                    <ng-option
                      *ngFor="let pettyCashSpecification of pettyCashSpecifications$ | async"
                      [value]="pettyCashSpecification.id"
                    >
                      {{ pettyCashSpecification.name | localize: pettyCashSpecification.nameEn | async }}
                    </ng-option>
                  </ng-select>
                </div>
                <div class="col-md-auto form-group d-flex">
                  <button
                    type="submit"
                    class="btn btn-raised btn-primary align-self-end round mx-1 mb-0"
                    [disabled]="isSearching$ | async"
                  >
                    <i class="fa fa-search"></i>
                  </button>
                  <a
                    *ngIf="[Claims.CREATE_PETTY_CASH] | authorize | async"
                    routerLink="/finances/petty-cash/create"
                    class="btn btn-raised btn-success align-self-end round mx-1 mb-0"
                  >
                    <i class="fa fa-plus"></i>
                    <span class="mx-1">{{ 'SHARED.CONFIRM.ADD' | translate }}</span>
                  </a>
                  <app-export-tools
                    *ngIf="(pettyCash$ | async)?.length"
                    class="d-flex align-self-end mx-1"
                    targetElementId="petty-cash-table-export-target-element"
                    [outputFileName]="'SHARED.EXPORT_TABLES.PETTY_CASH_TABLE' | translate"
                  ></app-export-tools>
                </div>
              </form>
            </div>
            <div class="col-md-12" *ngIf="(pettyCash$ | async)?.length; else noItems">
              <div class="row">
                <div class="col-md-12 alert alert-light text-center" role="alert">
                  {{ 'SHARED.SEARCH.SEARCH_RESULTS' | translate }} ({{ (paginationInfo$ | async).total | toNumber }})
                  {{ 'FINANCES.PETTY_CASH.PETTY_CASH_DATA.PETTY_CASH' | translate }}
                </div>
                <div class="col-md-12">
                  <div class="table-responsive">
                    <table class="table text-center" id="petty-cash-table-export-target-element">
                      <thead>
                        <tr>
                          <th scope="col">
                            <i class="fa fa-barcode"></i
                            ><span class="mx-1">{{
                              'FINANCES.PETTY_CASH.PETTY_CASH_DATA.PETTY_CASH_CODE' | translate
                            }}</span>
                          </th>
                          <th scope="col">
                            {{ 'FINANCES.PETTY_CASH.PETTY_CASH_DATA.PETTY_CASH_DESCRIPTION' | translate }}
                          </th>
                          <th scope="col">{{ 'FINANCES.PETTY_CASH.PETTY_CASH_DATA.PETTY_CASH_TYPE' | translate }}</th>
                          <th scope="col">{{ 'FINANCES.PETTY_CASH.PETTY_CASH_DATA.PETTY_CASH_VALUE' | translate }}</th>
                          <th scope="col">{{ 'FINANCES.PETTY_CASH.PETTY_CASH_DATA.MAX_PAYMENT_VALUE' | translate }}</th>
                          <th scope="col">
                            {{ 'FINANCES.PETTY_CASH.PETTY_CASH_DATA.PETTY_CASH_SPECIFICATION' | translate }}
                          </th>
                          <th scope="col">{{ 'FINANCES.PETTY_CASH.PETTY_CASH_DATA.PETTY_CASH_DATE' | translate }}</th>
                          <th scope="col">{{ 'SHARED.SEARCH.CREATED_AT' | translate }}</th>
                          <th scope="col">{{ 'FINANCES.PETTY_CASH.PETTY_CASH_DATA.STATUS' | translate }}</th>
                          <th scope="col">{{ 'SHARED.TOOLS' | translate }}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let petty of pettyCash$ | async; trackBy: trackItems; let i = index">
                          <th scope="row">{{ petty.id }}</th>
                          <td>{{ petty.description }}</td>
                          <td>{{ petty.pettyCashType.name | localize: petty.pettyCashType.nameEn | async }}</td>
                          <td>{{ petty.value | toDecimal }}</td>
                          <td>{{ petty.maxAllowedPaymentValue | toDecimal }}</td>
                          <td>
                            {{
                              petty.pettyCashSpecification.name | localize: petty.pettyCashSpecification.nameEn | async
                            }}
                          </td>
                          <td>
                            <span [ngbTooltip]="petty.transactionDate | date: 'time'">
                              {{ petty.transactionDate | date }}
                            </span>
                          </td>
                          <td>
                            <span [ngbTooltip]="petty.createdAt | date: 'time'">
                              {{ petty.createdAt | date }}
                            </span>
                          </td>
                          <td>
                            <app-status
                              [status]="petty.isActive"
                              [activeText]="'FINANCES.PETTY_CASH.PETTY_CASH_DATA.ACTIVE' | translate"
                              [notActiveText]="'FINANCES.PETTY_CASH.PETTY_CASH_DATA.NOT_ACTIVE' | translate"
                            >
                            </app-status>
                          </td>
                          <td>
                            <a
                              *ngIf="[Claims.UPDATE_PETTY_CASH] | authorize | async"
                              class="info p-0"
                              [routerLink]="['/finances/petty-cash/update', petty.id]"
                              [ngbTooltip]="'FINANCES.PETTY_CASH.PETTY_CASH_DATA.UPDATE_PETTY_CASH' | translate"
                            >
                              <i class="fa fa-edit font-medium-3 mr-2"></i>
                            </a>
                            <a
                              *ngIf="([Claims.BLOCK_PETTY_CASH] | authorize | async) && petty.isActive"
                              class="warning p-0"
                              (click)="selectedPettyCash = petty; openModal(blockModalRef)"
                              [ngbTooltip]="'FINANCES.PETTY_CASH.PETTY_CASH_DATA.BLOCK' | translate"
                            >
                              <i class="fa fa-power-off font-medium-3 mr-2"></i>
                            </a>
                            <a
                              *ngIf="([Claims.BLOCK_PETTY_CASH] | authorize | async) && !petty.isActive"
                              class="success p-0"
                              (click)="selectedPettyCash = petty; openModal(activateModalRef)"
                              [ngbTooltip]="'FINANCES.PETTY_CASH.PETTY_CASH_DATA.ACTIVATE' | translate"
                            >
                              <i class="fa fa-check font-medium-3 mr-2"></i>
                            </a>
                            <a
                              *ngIf="[Claims.DELETE_PETTY_CASH] | authorize | async"
                              class="danger p-0"
                              [ngbTooltip]="'SHARED.CONFIRM.DELETE' | translate"
                              (click)="selectedPettyCash = petty; openModal(deleteModalRef)"
                            >
                              <i class="fa fa-close font-medium-3 mr-2"></i>
                            </a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div class="d-flex justify-content-between p-2">
                      <ngb-pagination
                        *ngIf="paginationInfo$ | async; let pagination"
                        maxSize="5"
                        [collectionSize]="pagination.total"
                        [pageSize]="pagination.pageSize"
                        [page]="pagination.page"
                        (pageChange)="pageChanged($event)"
                        boundaryLinks="true"
                      >
                        <ng-template ngbPaginationFirst>{{
                          'SHARED.PAGINATION.PAGINATION_FIRST' | translate
                        }}</ng-template>
                        <ng-template ngbPaginationFirst>{{
                          'SHARED.PAGINATION.PAGINATION_PREVIOUS' | translate
                        }}</ng-template>
                        <ng-template ngbPaginationFirst>{{
                          'SHARED.PAGINATION.PAGINATION_NEXT' | translate
                        }}</ng-template>
                        <ng-template ngbPaginationFirst>{{
                          'SHARED.PAGINATION.PAGINATION_LAST' | translate
                        }}</ng-template>
                      </ngb-pagination>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- petty cash list ends -->

<!-- no items template starts -->
<ng-template #noItems>
  <div class="col-md-12 alert alert-warning mt-3 text-center" role="alert">
    {{ 'FINANCES.PETTY_CASH.SEARCH_PETTY_CASH_NOT_FOUND' | translate }}
    <a
      *ngIf="[Claims.CREATE_PETTY_CASH] | authorize | async"
      routerLink="/finances/petty-cash/create"
      class="btn btn-raised btn-success round"
    >
      <i class="fa fa-plus"></i>
      <span class="mx-1">{{ 'SHARED.CONFIRM.ADD' | translate }}</span>
    </a>
  </div>
</ng-template>
<!-- no items template ends -->

<!-- block petty cash modal starts -->
<ng-template #blockModalRef let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title text-warning" id="modal-basic-title">
      {{ 'FINANCES.PETTY_CASH.CONFIRM_BLOCK_PETTY_CASH_TITLE' | translate }}
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="d()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-12">
        <p class="text-warning">{{ 'FINANCES.PETTY_CASH.CONFIRM_BLOCK_PETTY_CASH_MESSAGE' | translate }}</p>
      </div>
      <div class="form-group col-12">
        <label for="selectedPettyCash.description">{{
          'FINANCES.PETTY_CASH.PETTY_CASH_DATA.PETTY_CASH_DESCRIPTION' | translate
        }}</label>
        <input
          type="text"
          id="selectedPettyCash.description"
          class="form-control round"
          [placeholder]="'FINANCES.PETTY_CASH.PETTY_CASH_DATA.PETTY_CASH_DESCRIPTION_PLACEHOLDER' | translate"
          readonly
          [value]="selectedPettyCash.description"
        />
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="modal-actions">
      <button
        type="button"
        class="btn btn-raised btn-warning round"
        (click)="c(); block(selectedPettyCash.id)"
        ngbAutofocus
        [disabled]="isBlocking$ | async"
      >
        <i class="fa fa-check"></i>
        <span class="mx-1">{{ 'FINANCES.PETTY_CASH.PETTY_CASH_DATA.BLOCK' | translate }}</span>
      </button>
      <button type="button" class="btn btn-outline-dark round" (click)="c()">
        <i class="fa fa-times"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.CANCEL' | translate }}</span>
      </button>
    </div>
  </div>
</ng-template>
<!-- block petty cash modal ends -->

<!-- activate petty cash modal starts -->
<ng-template #activateModalRef let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
      {{ 'FINANCES.PETTY_CASH.CONFIRM_ACTIVE_PETTY_CASH_TITLE' | translate }}
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="d()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-12">
        <p class="text-success">{{ 'FINANCES.PETTY_CASH.CONFIRM_ACTIVE_PETTY_CASH_MESSAGE' | translate }}</p>
      </div>
      <div class="form-group col-12">
        <label for="selectedPettyCash.description">{{
          'FINANCES.PETTY_CASH.PETTY_CASH_DATA.PETTY_CASH_DESCRIPTION' | translate
        }}</label>
        <input
          type="text"
          id="selectedPettyCash.description"
          class="form-control round"
          [placeholder]="'FINANCES.PETTY_CASH.PETTY_CASH_DATA.PETTY_CASH_DESCRIPTION_PLACEHOLDER' | translate"
          readonly
          [value]="selectedPettyCash.description"
        />
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="modal-actions">
      <button
        type="button"
        class="btn btn-raised btn-success round"
        (click)="c(); activate(selectedPettyCash.id)"
        ngbAutofocus
        [disabled]="isActivating$ | async"
      >
        <i class="fa fa-check"></i>
        <span class="mx-1">{{ 'FINANCES.PETTY_CASH.PETTY_CASH_DATA.ACTIVATE' | translate }}</span>
      </button>
      <button type="button" class="btn btn-outline-dark round" (click)="c()">
        <i class="fa fa-times"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.CANCEL' | translate }}</span>
      </button>
    </div>
  </div>
</ng-template>
<!-- activate petty cash modal ends -->

<!-- delete petty cash modal starts -->
<ng-template #deleteModalRef let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title text-danger" id="modal-basic-title">
      {{ 'FINANCES.PETTY_CASH.CONFIRM_DELETE_PETTY_CASH_TITLE' | translate }}
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="d()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-12">
        <p class="text-danger">{{ 'FINANCES.PETTY_CASH.CONFIRM_DELETE_PETTY_CASH_MESSAGE' | translate }}</p>
      </div>
      <div class="form-group col-12">
        <label for="selectedPettyCash.description">{{
          'FINANCES.PETTY_CASH.PETTY_CASH_DATA.PETTY_CASH_DESCRIPTION' | translate
        }}</label>
        <input
          type="text"
          id="selectedPettyCash.description"
          class="form-control round"
          [placeholder]="'FINANCES.PETTY_CASH.PETTY_CASH_DATA.PETTY_CASH_DESCRIPTION_PLACEHOLDER' | translate"
          readonly
          [value]="selectedPettyCash.description"
        />
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="modal-actions">
      <button
        type="button"
        class="btn btn-raised btn-danger round"
        (click)="c(); delete(selectedPettyCash.id)"
        ngbAutofocus
        [disabled]="isDeleting$ | async"
      >
        <i class="fa fa-check"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.DELETE' | translate }}</span>
      </button>
      <button type="button" class="btn btn-outline-dark round" (click)="c()">
        <i class="fa fa-times"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.CANCEL' | translate }}</span>
      </button>
    </div>
  </div>
</ng-template>
<!-- delete petty cash modal ends -->
