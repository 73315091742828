<!-- sales invoice details starts -->
<div class="row">
  <div class="col-md-12">
    <!-- customer details starts -->
    <div class="card-header mb-3 pt-0">
      <h4 class="card-title">{{ 'SALES.INVOICES.INVOICE_DATA' | translate }}</h4>
    </div>
    <div class="card-content">
      <div class="px-3">
        <div class="row">
          <div class="form-group col-md-6">
            <label for="location">{{ 'SALES.INVOICES.SALE_INVOICE_DATA.SALE_INVOICE_LOCATION' | translate }}</label>
            <input
              type="text"
              id="location"
              class="form-control round"
              [value]="invoice.location.name | localize: invoice.location.nameEn | async"
              readonly
            />
          </div>
          <div class="form-group col-md-6">
            <label for="employee">{{ 'SALES.INVOICES.SALE_INVOICE_DATA.SALES_PERSON' | translate }}</label>
            <input
              type="text"
              id="employee"
              class="form-control round"
              [value]="
                invoice.employee?.employeePersonalDetail.fullName
                  | localize: invoice.employee?.employeePersonalDetail.fullName
                  | async
              "
              readonly
            />
          </div>
          <div
            class="form-group"
            [ngClass]="invoice.customerEngagementId || invoice.customerAssetId ? 'col-md-6' : 'col-md-12'"
          >
            <label for="customer">{{ 'SALES.INVOICES.SALE_INVOICE_DATA.CUSTOMER' | translate }}</label>
            <input
              type="text"
              id="customer"
              class="form-control round"
              [value]="invoice.customer.name | localize: invoice.customer.nameEn | async"
              readonly
            />
          </div>
          <div class="form-group col-md-6" *ngIf="invoice.customerEngagementId">
            <label for="engagement">{{
              'SALES.INVOICES.SALE_INVOICE_DATA.CUSTOMER_ENGAGEMENT_TYPE' | translate
            }}</label>
            <div class="form-control round" readonly>
              <i class="fa fa-link c-primary"></i>
              <span class="mx-1">{{
                invoice.customerEngagement?.customerEngagementType?.name
                  | localize: invoice.customerEngagement?.customerEngagementType.nameEn
                  | async
              }}</span>
              -
              <span class="text-danger" style="text-decoration: line-through;">{{
                invoice.customerEngagement?.value | toDecimal
              }}</span>
              - <span>({{ invoice.customerEngagement?.remainingValue | toDecimal }})</span>
            </div>
          </div>
          <div class="form-group col-md-6" *ngIf="invoice.customerAssetId">
            <label for="description">{{ 'SALES.INVOICES.SALE_INVOICE_DATA.CUSTOMER_ASSET_TYPE' | translate }}</label>
            <input
              type="text"
              class="form-control round"
              id="description"
              [value]="invoice.customerAsset.description"
              readonly
            />
          </div>
          <div class="form-group col-md-6">
            <label for="invoice.saleInvoiceType.name">{{
              'SALES.INVOICES.SALE_INVOICE_DATA.INVOICE_TYPE' | translate
            }}</label>
            <input
              type="text"
              id="invoice.saleInvoiceType.name"
              class="form-control round"
              [value]="invoice.saleInvoiceType.name | localize: invoice.saleInvoiceType.nameEn | async"
              readonly
            />
          </div>
          <div class="form-group col-md-6" *ngIf="invoice.locationId">
            <label for="invoice.orderNum">{{ 'SALES.INVOICES.SALE_INVOICE_DATA.ORDER_NUMBER' | translate }}</label>
            <input
              type="text"
              class="form-control round"
              id="invoice.orderNum"
              [value]="invoice.orderNum | toNumber"
              readonly
            />
          </div>
          <div class="form-group col-md-6" *ngIf="invoice.saleInvoiceOrderType">
            <label for="invoice.saleInvoiceOrderType.name">{{
              'SALES.INVOICES.SALE_INVOICE_DATA.SALE_INVOICE_ORDER_TYPE' | translate
            }}</label>
            <input
              id="invoice.saleInvoiceOrderType.name"
              type="text"
              class="form-control round"
              [value]="invoice.saleInvoiceOrderType.name | localize: invoice.saleInvoiceOrderType.nameEn | async"
              readonly
            />
          </div>
          <div class="form-group col-md-6" *ngIf="invoice.boardId">
            <label for="invoice.board.description">{{
              'SALES.INVOICES.SALE_INVOICE_DATA.BOARD_NUMBER' | translate
            }}</label>
            <input
              id="invoice.board.description"
              type="text"
              class="form-control round"
              [value]="invoice.board.description"
              readonly
            />
          </div>
          <div class="form-group col-md-6" *ngIf="invoice.maintenanceStage">
            <label for="invoice.maintenanceStage.name">{{
              'SALES.INVOICES.SALE_INVOICE_DATA.MAINTENANCE_STAGE' | translate
            }}</label>
            <input
              id="invoice.maintenanceStage.name"
              type="text"
              class="form-control round"
              [value]="invoice.maintenanceStage.name | localize: invoice.maintenanceStage.nameEn | async"
              readonly
            />
          </div>
          <div class="form-group col-md-6">
            <label for="transactionDate">{{ 'SALES.INVOICES.SALE_INVOICE_DATA.INVOICE_DATE' | translate }}</label>
            <input
              type="text"
              id="transactionDate"
              class="form-control round"
              [value]="invoice.transactionDate | date: 'datetime'"
              readonly
            />
          </div>
          <div class="form-group col-md-6">
            <label for="dateOfSupply">{{ 'SALES.INVOICES.SALE_INVOICE_DATA.SUPPLY_DATE' | translate }}</label>
            <input
              type="text"
              id="dateOfSupply"
              class="form-control round"
              [value]="invoice.dateOfSupply | date: 'datetime'"
              readonly
            />
          </div>
          <div class="form-group col-md-6">
            <label for="createdAt">{{ 'SHARED.SEARCH.CREATED_AT' | translate }}</label>
            <input
              type="text"
              id="createdAt"
              class="form-control round"
              [value]="invoice.createdAt | date: 'datetime'"
              readonly
            />
          </div>
          <div class="form-group col-md-6">
            <label for="user">{{ 'SALES.INVOICES.SALE_INVOICE_DATA.USER_CREATE' | translate }}</label>
            <input type="text" id="user" class="form-control round" [value]="invoice.user.name" readonly />
          </div>
          <div class="form-group col-md-12" *ngIf="invoice.maintenanceItemDescription">
            <label for="maintenanceItemDescription">{{
              'SALES.INVOICES.SALE_INVOICE_DATA.MAINTENANCE_ITEM_DESCRIPTION' | translate
            }}</label>
            <textarea
              id="maintenanceItemDescription"
              class="form-control round"
              [innerText]="invoice.maintenanceItemDescription"
              readonly
            ></textarea>
          </div>
          <div class="form-group col-md-12" *ngIf="invoice.maintenanceExpectedTime">
            <label for="maintenanceExpectedTime">{{
              'SALES.INVOICES.SALE_INVOICE_DATA.MAINTENANCE_EXPECTED_TIME' | translate
            }}</label>
            <textarea
              id="maintenanceExpectedTime"
              class="form-control round"
              [innerText]="invoice.maintenanceExpectedTime"
              readonly
            ></textarea>
          </div>
          <div class="form-group col-md-12">
            <label for="notes">{{ 'SALES.INVOICES.SALE_INVOICE_DATA.NOTES' | translate }}</label>
            <textarea
              id="notes"
              class="form-control round"
              [innerText]="invoice.notes"
              [placeholder]="'SALES.INVOICES.SALE_INVOICE_DATA.NOTES_NOT_FOUND' | translate"
              readonly
            ></textarea>
          </div>
        </div>
      </div>
    </div>
    <!-- customer details ends -->

    <!-- products details starts -->
    <div class="card-header mb-3 pt-1">
      <h4 class="card-title">{{ 'SALES.INVOICES.SALE_INVOICE_DATA.PRODUCTS' | translate }}</h4>
    </div>
    <div class="card-content">
      <div class="px-3">
        <div class="row">
          <div class="col-md-12">
            <div class="table-responsive">
              <table class="table text-center">
                <thead>
                  <tr>
                    <th scope="col"><i class="fa fa-list-ol"></i></th>
                    <th scope="col">
                      <i class="fa fa-map-signs"></i>
                      {{ 'SALES.INVOICES.SALE_INVOICE_DATA.PRODUCT_TYPE' | translate }}
                    </th>
                    <th scope="col">
                      <i class="fa fa-barcode"></i
                      ><span class="mx-1">{{ 'STORES.PRODUCTS.PRODUCT_DATA.ITEM_CODE' | translate }}</span>
                    </th>
                    <th scope="col">
                      <i class="fa fa-comments"></i>
                      {{ 'SALES.INVOICES.SALE_INVOICE_DATA.PRODUCT_DESCRIPTION' | translate }}
                    </th>
                    <th scope="col">
                      <i class="fa fa-list-ol"></i>
                      {{ 'SALES.INVOICES.SALE_INVOICE_DATA.PRODUCT_QUANTITIES' | translate }}
                    </th>
                    <th scope="col">
                      <i class="fa fa-balance-scale"></i>
                      {{ 'SALES.INVOICES.SALE_INVOICE_DATA.PRODUCT_UNIT_OF_MEASURE' | translate }}
                    </th>
                    <th scope="col">
                      <i class="fa fa-usd"></i>
                      {{ 'SALES.INVOICES.SALE_INVOICE_DATA.PRODUCT_VALUE' | translate }}
                    </th>
                    <th scope="col">
                      <i class="fa fa-usd"></i>
                      {{ 'SALES.INVOICES.SALE_INVOICE_DATA.PRODUCT_TOTAL' | translate }}
                    </th>
                    <th scope="col">
                      <i class="fa fa-minus-circle"></i>
                      {{ 'SALES.INVOICES.SALE_INVOICE_DATA.NET_DISCOUNT' | translate }}
                    </th>
                    <th scope="col">
                      <i class="fa fa-usd"></i>
                      {{ 'SALES.INVOICES.SALE_INVOICE_DATA.TOTAL_PRODUCT_TAX' | translate }}
                    </th>
                    <th scope="col">
                      <i class="fa fa-percent"></i>
                      {{ 'SALES.INVOICES.SALE_INVOICE_DATA.TAX' | translate }}
                    </th>
                    <th scope="col">
                      <i class="fa fa-usd"></i>
                      {{ 'SALES.INVOICES.SALE_INVOICE_DATA.TAX_AMOUNT' | translate }}
                    </th>
                    <th scope="col">
                      <i class="fa fa-usd"></i>
                      {{ 'SALES.INVOICES.SALE_INVOICE_DATA.NET_TOTAL' | translate }}
                    </th>
                    <th scope="col">
                      <i class="fa fa-list-ol"></i>
                      {{ 'SALES.INVOICES.SALE_INVOICE_DATA.RETURNED_QUANTITY' | translate }}
                    </th>
                    <th scope="col">
                      {{ 'PURCHASES.REQUESTS.REQUEST_DATA.PROJECT_SUB_TASK' | translate }}
                    </th>
                    <th scope="col">
                      <i class="fa fa-pencil"></i>
                      {{ 'SALES.INVOICES.SALE_INVOICE_DATA.NOTES' | translate }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let product of invoice.saleInvoiceProducts; let i = index">
                    <th scope="row">{{ i + 1 }}</th>
                    <td>
                      <span class="c-success" *ngIf="product.product && !product.product?.isService">{{
                        'SALES.INVOICES.SALE_INVOICE_DATA.PRODUCT_NOT_HAS_SERVICE' | translate
                      }}</span>
                      <span class="c-success" *ngIf="product.product && product.product?.isService">{{
                        'SALES.INVOICES.SALE_INVOICE_DATA.SERVICE_PRODUCT' | translate
                      }}</span>
                      <span class="c-primary" *ngIf="!product.product">{{
                        'SALES.INVOICES.SALE_INVOICE_DATA.PRODUCT_NOT_STORABLE' | translate
                      }}</span>
                    </td>
                    <td>
                      {{ product.product?.itemCode }}
                    </td>
                    <td>
                      {{
                        (product.product?.description | localize: product.product?.descriptionEn | async) ||
                          product.description
                      }}
                      <div *ngIf="product.subProduct" class="mt-1 d-block">
                        <app-sub-product-info [subProduct]="product.subProduct"></app-sub-product-info>
                      </div>
                    </td>
                    <td>{{ product.quantity | toDecimal }}</td>
                    <td>{{ product.unitOfMeasure?.name | localize: product.unitOfMeasure?.nameEn | async }}</td>
                    <td>{{ product.value | toDecimal }}</td>
                    <td>{{ product.total | toDecimal }}</td>
                    <td>{{ product.netDiscount | toDecimal }}</td>
                    <td>{{ product.taxableAmount | toDecimal }}</td>
                    <td>
                      <span class="mx-1">{{ product.tax | toDecimal }}</span>
                      <span><i class="fa fa-percent"></i></span>
                    </td>
                    <td>{{ product.taxAmount | toDecimal }}</td>
                    <td>{{ product.netTotal | toDecimal }}</td>
                    <td>{{ product.returnedQuantity }}</td>
                    <td>
                      <span *ngIf="product.projectSubTask">
                        {{ product.projectSubTask?.name | localize: product.projectSubTask?.nameEn | async }}
                      </span>
                      <span *ngIf="product.projectSubTask" class="d-block">
                        ({{
                          product.projectSubTask?.project.description
                            | localize: product.projectSubTask?.project.descriptionEn
                            | async
                        }})
                      </span>
                    </td>
                    <td>{{ product.notes }}</td>
                  </tr>
                </tbody>
                <tfoot class="tfoot-light">
                  <tr>
                    <td [attr.colspan]="6">
                      <i class="fa fa-bookmark c-primary"></i>
                      <span>
                        {{ invoice.saleInvoiceProducts?.length | toNumber }}
                        {{ 'SALES.INVOICES.SALE_INVOICE_DATA.PRODUCT' | translate }}</span
                      >
                    </td>
                    <td [attr.colspan]="5">
                      <i class="fa fa-bookmark c-primary"></i>
                      <span>
                        {{ productsQuantity(invoice.saleInvoiceProducts) | toDecimal }}
                        {{ 'SALES.INVOICES.SALE_INVOICE_DATA.QUANTITY' | translate }}</span
                      >
                    </td>
                    <td [attr.colspan]="5">
                      <i class="fa fa-bookmark c-primary"></i>
                      <span>
                        {{ invoice.total + invoice.tax | toDecimal }}
                        {{ 'SALES.INVOICES.SALE_INVOICE_DATA.TOTAL' | translate }}
                      </span>
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- products details ends -->

    <!-- invoice summary starts -->
    <div class="card-header mb-3 pt-1">
      <h4 class="card-title">{{ 'SALES.INVOICES.SALE_INVOICE_DATA.INVOICE_SUMMARY' | translate }}</h4>
    </div>
    <div class="card-content">
      <div class="px-3">
        <div class="row">
          <div class="form-group col-md-4">
            <label for="totalWithoutVat">{{ 'SALES.INVOICES.SALE_INVOICE_DATA.TOTAL_EXCLUDING' | translate }}</label>
            <input
              type="text"
              id="totalWithoutVat"
              class="form-control round"
              [value]="invoice.total | toDecimal"
              readonly
            />
          </div>
          <div class="form-group col-md-4">
            <label for="discount">{{ 'SALES.INVOICES.SALE_INVOICE_DATA.THE_DISCOUNT' | translate }}</label>
            <input
              type="text"
              id="discount"
              class="form-control round"
              [value]="invoice.discount | toDecimal"
              readonly
            />
          </div>
          <div class="form-group col-md-4">
            <label for="totalTaxableAmount">{{
              'SALES.INVOICES.SALE_INVOICE_DATA.TOTAL_TAXABLE_AMOUNT_VAT' | translate
            }}</label>
            <input
              type="text"
              id="totalTaxableAmount"
              class="form-control round"
              [value]="invoice.taxableAmount | toDecimal"
              readonly
            />
          </div>
          <div class="form-group col-md-4">
            <label for="tax">{{ 'SALES.INVOICES.SALE_INVOICE_DATA.TOTAL_VAT' | translate }}</label>
            <input type="text" id="tax" class="form-control round" [value]="invoice.tax | toDecimal" readonly />
          </div>
          <div class="form-group col-md-4">
            <label for="net">{{ 'SALES.INVOICES.SALE_INVOICE_DATA.TOTAL_AMOUNT_DUE' | translate }}</label>
            <input type="text" id="net" class="form-control round" [value]="invoice.net | toDecimal" readonly />
          </div>
        </div>
      </div>
      <!-- invoice summary ends -->
    </div>
    <!-- sales invoice details ends -->
  </div>
</div>
<!-- sales invoice details ends -->
