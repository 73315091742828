<ng-container [formGroup]="assetGroup">
  <th scope="row">{{ index + 1 }}</th>
  <td class="min-width-20">
    <textarea
      name="description"
      autocomplete="off"
      class="form-control round"
      [placeholder]="'SALES.CUSTOMER_ASSETS.CUSTOMER_ASSET_DATA.CUSTOMER_ASSET_DESCRIPTION_PLACEHOLDER' | translate"
      maxlength="200"
      formControlName="description"
      rows="1"
    ></textarea>
  </td>
  <td class="min-width-9">
    <input
      type="text"
      id="type"
      class="form-control round"
      [placeholder]="'SALES.CUSTOMER_ASSETS.CUSTOMER_ASSET_DATA.CUSTOMER_ASSET_TYPE_PLACEHOLDER' | translate"
      formControlName="type"
    />
  </td>
  <td class="min-width-9">
    <input
      type="text"
      id="serialNumber"
      name="serialNumber"
      class="form-control round"
      [placeholder]="'SALES.CUSTOMER_ASSETS.CUSTOMER_ASSET_DATA.CUSTOMER_SERIAL_NUMBER_PLACEHOLDER' | translate"
      formControlName="serialNumber"
    />
  </td>
  <td>
    <a class="info p-0" (click)="openModal(addAttachmentsModalRef)" ngbTooltip="">
      <i class="fa fa-paperclip font-medium-3 mr-2"></i>
      <span *ngIf="attachmentsForm.controls.length">{{ attachmentsForm.controls.length }}</span>
    </a>
  </td>
  <td>
    <a class="danger p-0" [ngbTooltip]="'SHARED.SEARCH.DELETE' | translate" (click)="remove()">
      <i class="fa fa-close font-medium-3 mr-2"></i>
    </a>
  </td>
</ng-container>

<!-- add attachments modal starts -->
<ng-template #addAttachmentsModalRef [formGroup]="assetGroup" let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title text-info" id="modal-basic-title">
      {{ 'SALES.CUSTOMER_ASSETS.CUSTOMER_ASSET_DATA.ATTACHMENTS' | translate }}
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="d()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="form-group col-md-12">
        <!-- request attachments starts -->
        <app-files-upload formArrayName="attachments" [filesForm]="attachmentsForm"></app-files-upload>
        <!-- request attachments ends -->
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="modal-actions">
      <button type="button" class="btn btn-raised btn-primary round" (click)="c()" ngbAutoFocus>
        <i class="fa fa-times"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.CLOSE' | translate }}</span>
      </button>
    </div>
  </div>
</ng-template>
<!-- add attachments modal ends -->
