import { Component, Input } from '@angular/core';

import { ExcludedAsset } from 'assets/models';

@Component({
  selector: 'app-journal-excluded-asset-details',
  templateUrl: './journal-excluded-asset-details.component.html',
  styles: [],
})
export class JournalExcludedAssetDetailsComponent {
  /**
   * Sets the current journal excluded asset details.
   */
  @Input() excludedAsset: ExcludedAsset;
}
