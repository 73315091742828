import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from 'shared';
import { AuthRoutingModule } from 'auth/auth-routing.module';

import * as fromComponents from 'auth/components';
import * as fromServices from 'auth/services';
import * as fromGuards from 'auth/guards';
import * as fromPipes from 'auth/pipes';

/* Ngrx */
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromStore from 'auth/store';
import { AppSearchModule } from 'app-search';

/**
 * Contains authorization services, components, directives and pipes.
 * This module should imported in the core-module using `.forRoot()` static method.
 */
@NgModule({
  declarations: [...fromComponents.COMPONENTS, ...fromPipes.PIPES],
  imports: [
    CommonModule,
    SharedModule,
    AppSearchModule,
    AuthRoutingModule,
    StoreModule.forFeature('auth', fromStore.reducers),
    EffectsModule.forFeature(fromStore.effects),
  ],
  exports: [...fromPipes.PIPES, fromComponents.UserPreferencesComponent],
})
export class AuthModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: AuthModule,
      providers: [...fromServices.SERVICES, ...fromGuards.GUARDS],
    };
  }
}
