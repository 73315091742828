import { Injectable } from '@angular/core';

import { of } from 'rxjs';
import { switchMap, map, catchError } from 'rxjs/operators';
import { Effect, ofType, Actions } from '@ngrx/effects';

import { LookupsService } from 'lookups/services';
import {
  PayrollElementClassificationActionType,
  GetAllPayrollElementClassificationsFail,
  GetAllPayrollElementClassificationsSuccess,
} from 'lookups/store/actions';

@Injectable()
export class PayrollElementClassificationEffects {
  constructor(private actions$: Actions, private lookupsService: LookupsService) {}

  /* ========================= GET_ALL_PAYROLL_ELEMENT_CLASSIFICATIONS =================================== */
  @Effect()
  getAllPayrollElementClassifications$ = this.actions$.pipe(
    ofType(PayrollElementClassificationActionType.GET_ALL_PAYROLL_ELEMENT_CLASSIFICATIONS),
    switchMap(() =>
      this.lookupsService.getAllPayrollElementClassifications().pipe(
        map((response) => new GetAllPayrollElementClassificationsSuccess(response)),
        catchError((error) => of(new GetAllPayrollElementClassificationsFail(error)))
      )
    )
  );
}
