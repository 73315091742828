/**
 * The set of system supported employee-payment-methods.
 */
export enum EmployeePaymentMethods {
  /**
   * The cash method of employee payments.
   */
  CASH = 'CASH',

  /**
   * The bank transfer method of employee payments.
   */
  BANK_TRANSFER = 'BANK_TRANSFER',
}
