import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';

import * as fromFinancesStore from 'finances/store';
import { PageInfo, TranslationService } from 'shared';
import { Claims } from 'security/models';
import {
  BankAccountLog,
  Journal,
  PayrollPaymentRefund,
  PettyCashRefund,
  PettyCashRefundRequest,
} from 'finances/models';
import { PurchaseInvoice, PurchaseReturn, VendorLog } from 'purchases/models';
import { CustomerLog, SaleInvoice, SaleReturn } from 'sales/models';
import { IncomingStock, OutgoingStock, TransferStock } from 'stocks/models';
import { Asset, AssetDepreciation, AssetSaleInvoice, ExcludedAsset } from 'assets/models';
import { AccrualPayroll, EmployeeLoan, PayrollPayment, GovServiceRequest } from 'hr/models';

/**
 * The view journal component pages.
 */
enum PAGES {
  details = 'details',
  'vendor-log' = 'vendor-log',
  'purchase-invoice' = 'purchase-invoice',
  'customer-log' = 'customer-log',
  'purchase-return' = 'purchase-return',
  'sale-invoice' = 'sale-invoice',
  'sale-return' = 'sale-return',
  'transfer-stock' = 'transfer-stock',
  'incoming-stock' = 'incoming-stock',
  'outgoing-stock' = 'outgoing-stock',
  'petty-cash-refund' = 'petty-cash-refund',
  'petty-cash-refund-request' = 'petty-cash-refund-request',
  'asset' = 'asset',
  'excluded-asset' = 'excluded-asset',
  'asset-depreciation' = 'asset-depreciation',
  'asset-sale-invoice' = 'asset-sale-invoice',
  'bank-account-log' = 'bank-account-log',
  'accrual-payroll' = 'accrual-payroll',
  'payroll-payment' = 'payroll-payment',
  'employee-loan' = 'employee-loan',
  'payroll-payment-refund' = 'payroll-payment-refund',
  'governmental-service-request' = 'governmental-service-request',
}

@Component({
  selector: 'app-view-journal-transaction',
  templateUrl: './view-journal-transaction.component.html',
  styles: [],
})
export class ViewJournalTransactionComponent implements OnInit {
  /**
   * Gets or sets the information about the current page.
   */
  pageInfo: PageInfo = {
    title: 'FINANCES.JOURNALS.JOURNAL_DATA.DISPLAY_JOURNAL',
    icon: 'fa fa-eye',
  };

  /**
   * The system supported user claims.
   */
  Claims = Claims;

  /**
   * Gets or sets the id of the current viewed journal.
   */
  journalId: number;

  /**
   * The current viewed journal.
   */
  journal$: Observable<Journal>;

  /**
   * get the vendor log.
   */
  vendorLog$: Observable<VendorLog>;

  /**
   * get the purchase invoice.
   */
  purchaseInvoice$: Observable<PurchaseInvoice>;

  /**
   * get the purchase return.
   */
  purchaseReturn$: Observable<PurchaseReturn>;

  /**
   * get the customer log.
   */
  customerLog$: Observable<CustomerLog>;

  /**
   * get the sale invoice.
   */
  saleInvoice$: Observable<SaleInvoice>;

  /**
   * get the sale return.
   */
  saleReturn$: Observable<SaleReturn>;

  /**
   * get the transfer stock.
   */
  transferStock$: Observable<TransferStock>;

  /**
   * get the incoming stock.
   */
  incomingStock$: Observable<IncomingStock>;

  /**
   * get the outgoing stock.
   */
  outgoingStock$: Observable<OutgoingStock>;

  /**
   * get the petty cash refund.
   */
  pettyCashRefund$: Observable<PettyCashRefund>;

  /**
   * get the petty cash refund request.
   */
  pettyCashRefundRequest$: Observable<PettyCashRefundRequest>;

  /**
   * get the asset.
   */
  asset$: Observable<Asset>;

  /**
   * get the bank account log.
   */
  bankAccountLog$: Observable<BankAccountLog>;

  /**
   * get the asset depreciation.
   */
  assetDepreciation$: Observable<AssetDepreciation>;

  /**
   * get the asset sale invoice.
   */
  assetSaleInvoice$: Observable<AssetSaleInvoice>;

  /**
   * get the excluded asset.
   */
  excludedAsset$: Observable<ExcludedAsset>;

  /**
   * get the accrual payroll.
   */
  accrualPayroll$: Observable<AccrualPayroll>;

  /**
   * get the payroll payment refund.
   */
  payrollPaymentRefund$: Observable<PayrollPaymentRefund>;

  /**
   * get the gov service request.
   */
  govServiceRequest$: Observable<GovServiceRequest>;

  /**
   * get the payroll payment.
   */
  payrollPayment$: Observable<PayrollPayment>;

  /**
   * get the employee loan.
   */
  employeeLoan$: Observable<EmployeeLoan>;

  /**
   * Gets or sets the selected page.
   * @default 'details'
   */
  activePage: PAGES = PAGES.details;

  constructor(
    private route: ActivatedRoute,
    private locationService: Location,
    private financesStore$: Store<fromFinancesStore.FinancesState>,
    private translationService: TranslationService
  ) {}

  ngOnInit(): void {
    this.init();
  }

  /**
   * Initialize component data.
   */
  init() {
    /* Load the selected journal. */
    this.journalId = +this.route.snapshot.params.journalId;
    this.financesStore$.dispatch(new fromFinancesStore.FindJournal(this.journalId));

    /* Load the selected journal. */
    this.journal$ = this.financesStore$.pipe(
      select(fromFinancesStore.getSelectedJournal),
      tap((journal) => {
        if (journal) {
          /* Update page info. */
          this.pageInfo = {
            ...this.pageInfo,
            subTitle: this.translationService.translate('FINANCES.JOURNALS.JOURNAL_DATA.JOURNAL_NUMBER', {
              journalNumber: journal.journalNum,
            }),
          };

          const association = journal.journalAssociations[0];
          if (!association) {
            return;
          } else {
            if (association.vendorLogId) {
              this.vendorLog$ = this.financesStore$.pipe(select(fromFinancesStore.getJournalAssociationVendorLogData));
              this.financesStore$.dispatch(
                new fromFinancesStore.FindJournalAssociationVendorLog(association.vendorLogId)
              );
            }
            if (association.purchaseInvoiceId) {
              this.purchaseInvoice$ = this.financesStore$.pipe(
                select(fromFinancesStore.getJournalAssociationPurchaseInvoiceData)
              );
              this.financesStore$.dispatch(
                new fromFinancesStore.FindJournalAssociationPurchaseInvoice(association.purchaseInvoiceId)
              );
            }
            if (association.purchaseReturnId) {
              this.purchaseReturn$ = this.financesStore$.pipe(
                select(fromFinancesStore.getJournalAssociationPurchaseReturnData)
              );
              this.financesStore$.dispatch(
                new fromFinancesStore.FindJournalAssociationPurchaseReturn(association.purchaseReturnId)
              );
            }
            if (association.customerLogId) {
              this.customerLog$ = this.financesStore$.pipe(
                select(fromFinancesStore.getJournalAssociationCustomerLogData)
              );
              this.financesStore$.dispatch(
                new fromFinancesStore.FindJournalAssociationCustomerLog(association.customerLogId)
              );
            }
            if (association.saleInvoiceId) {
              this.saleInvoice$ = this.financesStore$.pipe(
                select(fromFinancesStore.getJournalAssociationSaleInvoiceData)
              );
              this.financesStore$.dispatch(
                new fromFinancesStore.FindJournalAssociationSaleInvoice(association.saleInvoiceId)
              );
            }
            if (association.saleReturnId) {
              this.saleReturn$ = this.financesStore$.pipe(
                select(fromFinancesStore.getJournalAssociationSaleReturnData)
              );
              this.financesStore$.dispatch(
                new fromFinancesStore.FindJournalAssociationSaleReturn(association.saleReturnId)
              );
            }
            if (association.transferStockId) {
              this.transferStock$ = this.financesStore$.pipe(
                select(fromFinancesStore.getJournalAssociationTransferStockData)
              );
              this.financesStore$.dispatch(
                new fromFinancesStore.FindJournalAssociationTransferStock(association.transferStockId)
              );
            }
            if (association.incomingStockId) {
              this.incomingStock$ = this.financesStore$.pipe(
                select(fromFinancesStore.getJournalAssociationIncomingStockData)
              );
              this.financesStore$.dispatch(
                new fromFinancesStore.FindJournalAssociationIncomingStock(association.incomingStockId)
              );
            }
            if (association.outgoingStockId) {
              this.outgoingStock$ = this.financesStore$.pipe(
                select(fromFinancesStore.getJournalAssociationOutgoingStockData)
              );
              this.financesStore$.dispatch(
                new fromFinancesStore.FindJournalAssociationOutgoingStock(association.outgoingStockId)
              );
            }
            if (association.pettyCashRefundId) {
              this.pettyCashRefund$ = this.financesStore$.pipe(
                select(fromFinancesStore.getJournalAssociationPettyCashRefundData)
              );
              this.financesStore$.dispatch(
                new fromFinancesStore.FindJournalAssociationPettyCashRefund(association.pettyCashRefundId)
              );
            }
            if (association.pettyCashRefundRequestId) {
              this.pettyCashRefundRequest$ = this.financesStore$.pipe(
                select(fromFinancesStore.getJournalAssociationPettyCashRefundRequestData)
              );
              this.financesStore$.dispatch(
                new fromFinancesStore.FindJournalAssociationPettyCashRefundRequest(association.pettyCashRefundRequestId)
              );
            }
            if (association.assetId) {
              this.asset$ = this.financesStore$.pipe(select(fromFinancesStore.getJournalAssociationAssetData));
              this.financesStore$.dispatch(new fromFinancesStore.FindJournalAssociationAsset(association.assetId));
            }
            if (association.bankAccountLogId) {
              this.bankAccountLog$ = this.financesStore$.pipe(
                select(fromFinancesStore.getJournalAssociationBankAccountLogData)
              );
              this.financesStore$.dispatch(
                new fromFinancesStore.FindJournalAssociationBankAccountLog(association.bankAccountLogId)
              );
            }
            if (association.assetDepreciationId) {
              this.assetDepreciation$ = this.financesStore$.pipe(
                select(fromFinancesStore.getJournalAssociationAssetDepreciationData)
              );
              this.financesStore$.dispatch(
                new fromFinancesStore.FindJournalAssociationAssetDepreciation(association.assetDepreciationId)
              );
            }
            if (association.assetSaleInvoiceId) {
              this.assetSaleInvoice$ = this.financesStore$.pipe(
                select(fromFinancesStore.getJournalAssociationAssetSaleInvoiceData)
              );
              this.financesStore$.dispatch(
                new fromFinancesStore.FindJournalAssociationAssetSaleInvoice(association.assetSaleInvoiceId)
              );
            }
            if (association.excludedAssetId) {
              this.excludedAsset$ = this.financesStore$.pipe(
                select(fromFinancesStore.getJournalAssociationExcludedAssetData)
              );
              this.financesStore$.dispatch(
                new fromFinancesStore.FindJournalAssociationExcludedAsset(association.excludedAssetId)
              );
            }
            if (association.accrualPayrollId) {
              this.accrualPayroll$ = this.financesStore$.pipe(
                select(fromFinancesStore.getJournalAssociationAccrualPayrollData)
              );
              this.financesStore$.dispatch(
                new fromFinancesStore.FindJournalAssociationAccrualPayroll(association.accrualPayrollId)
              );
            }
            if (association.payrollPaymentId) {
              this.payrollPayment$ = this.financesStore$.pipe(
                select(fromFinancesStore.getJournalAssociationPayrollPaymentData)
              );
              this.financesStore$.dispatch(
                new fromFinancesStore.FindJournalAssociationPayrollPayment(association.payrollPaymentId)
              );
            }
            if (association.employeeLoanId) {
              this.employeeLoan$ = this.financesStore$.pipe(
                select(fromFinancesStore.getJournalAssociationEmployeeLoanData)
              );
              this.financesStore$.dispatch(
                new fromFinancesStore.FindJournalAssociationEmployeeLoan(association.employeeLoanId)
              );
            }
            if (association.payrollPaymentRefundId) {
              this.payrollPaymentRefund$ = this.financesStore$.pipe(
                select(fromFinancesStore.getJournalAssociationPayrollPaymentRefundData)
              );
              this.financesStore$.dispatch(
                new fromFinancesStore.FindJournalAssociationPayrollPaymentRefund(association.payrollPaymentRefundId)
              );
            }
            if (association.govServiceRequestId) {
              this.govServiceRequest$ = this.financesStore$.pipe(
                select(fromFinancesStore.getJournalAssociationGovServiceRequestsData)
              );
              this.financesStore$.dispatch(
                new fromFinancesStore.FindJournalAssociationGovServiceRequest(association.govServiceRequestId)
              );
            }
          }
        }
      })
    );

    /** Select the user desired page. */
    this.activePage = PAGES[this.route.snapshot.fragment] ?? this.activePage;
    this.selectedPageChanged(this.activePage);
  }

  /**
   * Updates the browser url according to the selected page.
   * @param page The newly selected page.
   */
  selectedPageChanged(page: PAGES) {
    this.locationService.replaceState(`${this.locationService.path()}#${PAGES[page]}`);
  }
}
