import { PurchaseRequestReceiveTypeActionType, PurchaseRequestReceiveTypeActions } from 'lookups/store/actions';
import { PurchaseRequestReceiveTypeState } from 'lookups/store/states';

/**
 * The initial state from which the state starts.
 */
const initialState: PurchaseRequestReceiveTypeState = {
  data: [],
  error: null,
  isLoaded: false,
  isLoading: false,
};

/**
 * The reducer function that is called in each action dispatch against the state.
 * @param state The current state.
 * @param action The action that will affect the state.
 */
export function purchaseRequestReceiveTypeReducer(
  state: PurchaseRequestReceiveTypeState = initialState,
  action: PurchaseRequestReceiveTypeActions
): PurchaseRequestReceiveTypeState {
  switch (action.type) {
    case PurchaseRequestReceiveTypeActionType.GET_ALL_PURCHASE_REQUEST_RECEIVE_TYPE: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case PurchaseRequestReceiveTypeActionType.GET_ALL_PURCHASE_REQUEST_RECEIVE_TYPE_FAIL: {
      return {
        ...state,
        error: action.payload,
        isLoading: false,
        isLoaded: false,
      };
    }

    case PurchaseRequestReceiveTypeActionType.GET_ALL_PURCHASE_REQUEST_RECEIVE_TYPE_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        isLoading: false,
        isLoaded: true,
      };
    }

    default:
      return state;
  }
}
