import { NotificationService } from 'shared/services/notification.service';
import { StorageService } from 'shared/services/storage.service';
import { TranslationService } from 'shared/services/translation.service';
import { PrintService } from 'shared/services/print.service';
import { ExportToolsService } from 'shared/services/export-tools.service';

export * from 'shared/services/notification.service';
export * from 'shared/services/storage.service';
export * from 'shared/services/translation.service';
export * from 'shared/services/print.service';
export * from 'shared/services/export-tools.service';

/**
 * The set of services registered on this module.
 */
export const SERVICES: any[] = [
  NotificationService,
  StorageService,
  TranslationService,
  PrintService,
  ExportToolsService,
];
