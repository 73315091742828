import { UsersActionType, UsersActions } from 'app-search/store/actions';
import { UsersState } from 'app-search/store/states';

/**
 * The initial state from which the state starts.
 */
const initialState: UsersState = {
  data: [],
  paginationInfo: {
    total: 0,
    page: 1,
    pageSize: 0,
    count: 0,
  },
  error: null,
  isGetOrganizationUsersCompleted: false,
  isGettingOrganizationUsers: false,
};

/**
 * The reducer function that is called in each action dispatch against the state.
 * @param state The current state.
 * @param action The action that will affect the state.
 */
export function usersReducer(state: UsersState = initialState, action: UsersActions): UsersState {
  switch (action.type) {
    //#region GET_ORGANIZATION_USERS

    case UsersActionType.GET_ORGANIZATION_USERS: {
      return {
        ...state,
        isGetOrganizationUsersCompleted: false,
        isGettingOrganizationUsers: true,
        error: null,
      };
    }

    case UsersActionType.GET_ORGANIZATION_USERS_FAIL: {
      return {
        ...state,
        error: action.payload,
        isGetOrganizationUsersCompleted: false,
        isGettingOrganizationUsers: false,
      };
    }

    case UsersActionType.GET_ORGANIZATION_USERS_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        paginationInfo: {
          ...action.payload.meta,
        },
        error: null,
        isGetOrganizationUsersCompleted: true,
        isGettingOrganizationUsers: false,
      };
    }

    // #end region GET_ORGANIZATION_USERS

    default:
      return state;
  }
}
