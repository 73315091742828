import { FinancesReportsActionType, FinancesReportsActions } from 'reports/store/actions';
import { FinancesReportsState } from 'reports/store/states';

/**
 * The initial state from which the state starts.
 */
const initialState: FinancesReportsState = {
  accountAnalysis: null,
  balanceSheet: null,
  bankAccountSummary: null,
  incomeStatement: null,
  journals: null,
  taxes: null,
  trialBalance: null,
  error: null,
  isLoadingAccountAnalysis: false,
  isAccountAnalysisLoadCompleted: false,
  isLoadingBalanceSheet: false,
  isBalanceSheetLoadCompleted: false,
  isLoadingBankAccountSummary: false,
  isBankAccountSummaryLoadCompleted: false,
  isLoadingIncomeStatement: false,
  isIncomeStatementLoadCompleted: false,
  isLoadingJournals: false,
  isJournalsLoadCompleted: false,
  isLoadingTaxes: false,
  isTaxesLoadCompleted: false,
  isLoadingTrialBalance: false,
  isTrialBalanceLoadCompleted: false,
};

/**
 * The reducer function that is called in each action dispatch against the state.
 * @param state The current state.
 * @param action The action that will affect the state.
 */
export function financesReportsReducer(
  state: FinancesReportsState = initialState,
  action: FinancesReportsActions
): FinancesReportsState {
  switch (action.type) {
    //#region GET_ACCOUNT_ANALYSIS_REPORT

    case FinancesReportsActionType.GET_ACCOUNT_ANALYSIS_REPORT: {
      return {
        ...state,
        accountAnalysis: null,
        isLoadingAccountAnalysis: true,
        isAccountAnalysisLoadCompleted: false,
        error: null,
      };
    }

    case FinancesReportsActionType.GET_ACCOUNT_ANALYSIS_REPORT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isLoadingAccountAnalysis: false,
        isAccountAnalysisLoadCompleted: false,
      };
    }

    case FinancesReportsActionType.GET_ACCOUNT_ANALYSIS_REPORT_RECEIVED: {
      return {
        ...state,
        error: null,
        isLoadingAccountAnalysis: false,
        isAccountAnalysisLoadCompleted: true,
      };
    }

    case FinancesReportsActionType.GET_ACCOUNT_ANALYSIS_REPORT_SUCCESS: {
      return {
        ...state,
        accountAnalysis: action.payload.data,
        error: null,
        isLoadingAccountAnalysis: false,
        isAccountAnalysisLoadCompleted: true,
      };
    }

    case FinancesReportsActionType.ACCOUNT_ANALYSIS_REPORT_VIEW: {
      return {
        ...state,
        accountAnalysis: action.payload,
        error: null,
        isLoadingAccountAnalysis: false,
        isAccountAnalysisLoadCompleted: true,
      };
    }

    //#endregion ACCOUNT_ANALYSIS

    //#region GET_BALANCE_SHEET_REPORT

    case FinancesReportsActionType.GET_BALANCE_SHEET_REPORT: {
      return {
        ...state,
        balanceSheet: null,
        isLoadingBalanceSheet: true,
        isBalanceSheetLoadCompleted: false,
        error: null,
      };
    }

    case FinancesReportsActionType.GET_BALANCE_SHEET_REPORT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isLoadingBalanceSheet: false,
        isBalanceSheetLoadCompleted: false,
      };
    }

    case FinancesReportsActionType.GET_BALANCE_SHEET_REPORT_RECEIVED: {
      return {
        ...state,
        error: null,
        isLoadingBalanceSheet: false,
        isBalanceSheetLoadCompleted: true,
      };
    }

    case FinancesReportsActionType.GET_BALANCE_SHEET_REPORT_SUCCESS: {
      return {
        ...state,
        balanceSheet: action.payload.data,
        error: null,
        isLoadingBalanceSheet: false,
        isBalanceSheetLoadCompleted: true,
      };
    }

    case FinancesReportsActionType.BALANCE_SHEET_REPORT_VIEW: {
      return {
        ...state,
        balanceSheet: action.payload,
        error: null,
        isLoadingBalanceSheet: false,
        isBalanceSheetLoadCompleted: true,
      };
    }

    //#endregion BALANCE_SHEET

    //#region GET_BANK_ACCOUNT_SUMMARY_REPORT

    case FinancesReportsActionType.GET_BANK_ACCOUNT_SUMMARY_REPORT: {
      return {
        ...state,
        bankAccountSummary: null,
        isLoadingBankAccountSummary: true,
        isBankAccountSummaryLoadCompleted: false,
        error: null,
      };
    }

    case FinancesReportsActionType.GET_BANK_ACCOUNT_SUMMARY_REPORT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isLoadingBankAccountSummary: false,
        isBankAccountSummaryLoadCompleted: false,
      };
    }

    case FinancesReportsActionType.GET_BANK_ACCOUNT_SUMMARY_REPORT_RECEIVED: {
      return {
        ...state,
        error: null,
        isLoadingBankAccountSummary: false,
        isBankAccountSummaryLoadCompleted: true,
      };
    }

    case FinancesReportsActionType.GET_BANK_ACCOUNT_SUMMARY_REPORT_SUCCESS: {
      return {
        ...state,
        bankAccountSummary: action.payload.data,
        error: null,
        isLoadingBankAccountSummary: false,
        isBankAccountSummaryLoadCompleted: true,
      };
    }

    case FinancesReportsActionType.BANK_ACCOUNT_SUMMARY_REPORT_VIEW: {
      return {
        ...state,
        bankAccountSummary: action.payload,
        error: null,
        isLoadingBankAccountSummary: false,
        isBankAccountSummaryLoadCompleted: true,
      };
    }

    //#endregion BANK_ACCOUNT_SUMMARY

    //#region GET_INCOME_STATEMENT_REPORT

    case FinancesReportsActionType.GET_INCOME_STATEMENT_REPORT: {
      return {
        ...state,
        incomeStatement: null,
        isLoadingIncomeStatement: true,
        isIncomeStatementLoadCompleted: false,
        error: null,
      };
    }

    case FinancesReportsActionType.GET_INCOME_STATEMENT_REPORT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isLoadingIncomeStatement: false,
        isIncomeStatementLoadCompleted: false,
      };
    }

    case FinancesReportsActionType.GET_INCOME_STATEMENT_REPORT_RECEIVED: {
      return {
        ...state,
        error: null,
        isLoadingIncomeStatement: false,
        isIncomeStatementLoadCompleted: true,
      };
    }

    case FinancesReportsActionType.GET_INCOME_STATEMENT_REPORT_SUCCESS: {
      return {
        ...state,
        incomeStatement: action.payload.data,
        error: null,
        isLoadingIncomeStatement: false,
        isIncomeStatementLoadCompleted: true,
      };
    }

    case FinancesReportsActionType.INCOME_STATEMENT_REPORT_VIEW: {
      return {
        ...state,
        incomeStatement: action.payload,
        error: null,
        isLoadingIncomeStatement: false,
        isIncomeStatementLoadCompleted: true,
      };
    }

    //#endregion INCOME_STATEMENT

    //#region GET_JOURNALS_REPORT

    case FinancesReportsActionType.GET_JOURNALS_REPORT: {
      return {
        ...state,
        journals: null,
        isLoadingJournals: true,
        isJournalsLoadCompleted: false,
        error: null,
      };
    }

    case FinancesReportsActionType.GET_JOURNALS_REPORT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isLoadingJournals: false,
        isJournalsLoadCompleted: false,
      };
    }

    case FinancesReportsActionType.GET_JOURNALS_REPORT_RECEIVED: {
      return {
        ...state,
        error: null,
        isLoadingJournals: false,
        isJournalsLoadCompleted: true,
      };
    }

    case FinancesReportsActionType.GET_JOURNALS_REPORT_SUCCESS: {
      return {
        ...state,
        journals: action.payload.data,
        error: null,
        isLoadingJournals: false,
        isJournalsLoadCompleted: true,
      };
    }

    case FinancesReportsActionType.JOURNALS_REPORT_VIEW: {
      return {
        ...state,
        journals: action.payload,
        error: null,
        isLoadingJournals: false,
        isJournalsLoadCompleted: true,
      };
    }

    //#endregion JOURNALS

    //#region GET_TAXES_REPORT

    case FinancesReportsActionType.GET_TAXES_REPORT: {
      return {
        ...state,
        taxes: null,
        isLoadingTaxes: true,
        isTaxesLoadCompleted: false,
        error: null,
      };
    }

    case FinancesReportsActionType.GET_TAXES_REPORT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isLoadingTaxes: false,
        isTaxesLoadCompleted: false,
      };
    }

    case FinancesReportsActionType.GET_TAXES_REPORT_RECEIVED: {
      return {
        ...state,
        error: null,
        isLoadingTaxes: false,
        isTaxesLoadCompleted: true,
      };
    }

    case FinancesReportsActionType.GET_TAXES_REPORT_SUCCESS: {
      return {
        ...state,
        taxes: action.payload.data,
        error: null,
        isLoadingTaxes: false,
        isTaxesLoadCompleted: true,
      };
    }

    case FinancesReportsActionType.TAXES_REPORT_VIEW: {
      return {
        ...state,
        taxes: action.payload,
        error: null,
        isLoadingTaxes: false,
        isTaxesLoadCompleted: true,
      };
    }

    //#endregion TAXES

    //#region GET_TRIAL_BALANCE_REPORT

    case FinancesReportsActionType.GET_TRIAL_BALANCE_REPORT: {
      return {
        ...state,
        trialBalance: null,
        isLoadingTrialBalance: true,
        isTrialBalanceLoadCompleted: false,
        error: null,
      };
    }

    case FinancesReportsActionType.GET_TRIAL_BALANCE_REPORT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isLoadingTrialBalance: false,
        isTrialBalanceLoadCompleted: false,
      };
    }

    case FinancesReportsActionType.GET_TRIAL_BALANCE_REPORT_RECEIVED: {
      return {
        ...state,
        error: null,
        isLoadingTrialBalance: false,
        isTrialBalanceLoadCompleted: true,
      };
    }

    case FinancesReportsActionType.GET_TRIAL_BALANCE_REPORT_SUCCESS: {
      return {
        ...state,
        trialBalance: action.payload.data,
        error: null,
        isLoadingTrialBalance: false,
        isTrialBalanceLoadCompleted: true,
      };
    }

    case FinancesReportsActionType.TRIAL_BALANCE_REPORT_VIEW: {
      return {
        ...state,
        trialBalance: action.payload,
        error: null,
        isLoadingTrialBalance: false,
        isTrialBalanceLoadCompleted: true,
      };
    }

    //#endregion TRIAL_BALANCE

    default:
      return state;
  }
}
