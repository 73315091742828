import { Action } from '@ngrx/store';

import { VendorLogType } from 'lookups/models';
import { AppHttpResponse } from 'shared';

/**
 * The Lookups-Module store vendor log type action types.
 */
export enum VendorLogTypeActionType {
  GET_ALL_VENDOR_LOG_TYPE = '[Lookups] [VendorLogType] Get All Vendor Log Types',
  GET_ALL_VENDOR_LOG_TYPE_FAIL = '[Lookups] [VendorLogType] Get All Vendor Log Types Fail',
  GET_ALL_VENDOR_LOG_TYPE_SUCCESS = '[Lookups] [VendorLogType] Get All Vendor Log Types Success',
}

/**
 * Represents a store vendor log type action.
 */
export class GetAllVendorLogType implements Action {
  /**
   * The type of the action.
   */
  readonly type = VendorLogTypeActionType.GET_ALL_VENDOR_LOG_TYPE;

  /**
   * Fires a new vendor log type action.
   */
  constructor() {}
}

/**
 * Represents a store vendor log type fail action.
 */
export class GetAllVendorLogTypeFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = VendorLogTypeActionType.GET_ALL_VENDOR_LOG_TYPE_FAIL;

  /**
   * Fires a new vendor log type fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store vendor log type success action.
 */
export class GetAllVendorLogTypeSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = VendorLogTypeActionType.GET_ALL_VENDOR_LOG_TYPE_SUCCESS;

  /**
   * Fires a vendor log type success action.
   * @param payload An object contains the list of vendor log types.
   */
  constructor(public payload: AppHttpResponse<VendorLogType[]>) {}
}

/**
 * Lookups-module vendor log type action types.
 */
export type VendorLogTypeActions = GetAllVendorLogType | GetAllVendorLogTypeFail | GetAllVendorLogTypeSuccess;
