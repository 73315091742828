import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';

import { Observable } from 'rxjs';

import { AppHttpResponse } from 'shared';
import {
  SaleInvoice,
  CreatePointOfSaleInvoiceInput,
  CreateOpeningBalanceInvoiceInput,
  CreateRegularSalesInvoiceInput,
  CreateMaintenanceInvoiceInput,
} from 'sales/models';
import { MaintenanceStages } from 'lookups';

/**
 * The sale invoices services includes functionality to create, createPayment, search and findById a sales invoice.
 */
@Injectable()
export class SaleInvoicesService {
  /**
   * The relative route for the sales invoices.
   * No leading or trailing slashes required.
   */
  private saleInvoicesApi = 'sales/invoices';

  constructor(private http: HttpClient, private sanitizer: DomSanitizer) {}

  /**
   * Creates a new sale invoice from the provided data.
   * @param data The new sales invoice data.
   */
  public createRegular(data: CreateRegularSalesInvoiceInput): Observable<AppHttpResponse<SaleInvoice>> {
    const formData: any = new FormData();
    formData.append('locationId', data.locationId);
    formData.append('employeeId', data.employeeId);
    formData.append('customerId', data.customerId);
    formData.append('quotationId', data.quotationId);
    formData.append('customerEngagementId', data.customerEngagementId);
    formData.append('transactionDate', data.transactionDate?.toISOString());
    formData.append('dateOfSupply', data.dateOfSupply?.toISOString());
    formData.append('notes', data.notes);

    /**
     * Append products to the form data.
     */
    for (let index = 0; index < data.products.length; index++) {
      formData.append(`products[${index}][productId]`, data.products[index].productId);
      formData.append(`products[${index}][subProductId]`, data.products[index].subProductId);
      formData.append(`products[${index}][description]`, data.products[index].description);
      formData.append(`products[${index}][quantity]`, data.products[index].quantity);
      formData.append(`products[${index}][unitOfMeasureId]`, data.products[index].unitOfMeasureId);
      formData.append(`products[${index}][projectSubTaskId]`, data.products[index].projectSubTaskId);
      formData.append(`products[${index}][value]`, data.products[index].value);
      formData.append(`products[${index}][discount]`, data.products[index].discount);
      formData.append(`products[${index}][discountPercent]`, data.products[index].discountPercent);
      formData.append(`products[${index}][tax]`, data.products[index].tax);
      formData.append(`products[${index}][notes]`, data.products[index].notes);
    }

    /**
     * Append journal lines to the form data.
     */
    for (let index = 0; index < data.lines.length; index++) {
      formData.append(`lines[${index}][accountId]`, data.lines[index].accountId);
      formData.append(`lines[${index}][credit]`, data.lines[index].credit);
      formData.append(`lines[${index}][debit]`, data.lines[index].debit);
      formData.append(`lines[${index}][costCenterId]`, data.lines[index].costCenterId);
      formData.append(`lines[${index}][notes]`, data.lines[index].notes);
    }

    /**
     * Append attachments to the form data.
     */
    data.attachments.forEach(
      (attachment) => attachment && formData.append(`attachments[]`, attachment, attachment?.name)
    );

    return this.http.post<AppHttpResponse<SaleInvoice>>(`${this.saleInvoicesApi}/regular`, formData);
  }

  /**
   * Creates a new opening balance invoice from the provided data.
   * @param data The new opening balance invoice data.
   */
  public createOpeningBalance(data: CreateOpeningBalanceInvoiceInput): Observable<AppHttpResponse<SaleInvoice>> {
    const formData: any = new FormData();
    formData.append('locationId', data.locationId);
    formData.append('customerId', data.customerId);
    formData.append('transactionDate', data.transactionDate?.toISOString());
    formData.append('dateOfSupply', data.dateOfSupply?.toISOString());
    formData.append('notes', data.notes);

    /**
     * Append products to the form data.
     */
    for (let index = 0; index < data.products.length; index++) {
      formData.append(`products[${index}][productId]`, data.products[index].productId);
      formData.append(`products[${index}][subProductId]`, data.products[index].subProductId);
      formData.append(`products[${index}][description]`, data.products[index].description);
      formData.append(`products[${index}][quantity]`, data.products[index].quantity);
      formData.append(`products[${index}][unitOfMeasureId]`, data.products[index].unitOfMeasureId);
      formData.append(`products[${index}][value]`, data.products[index].value);
      formData.append(`products[${index}][discount]`, data.products[index].discount);
      formData.append(`products[${index}][discountPercent]`, data.products[index].discountPercent);
      formData.append(`products[${index}][tax]`, data.products[index].tax);
      formData.append(`products[${index}][notes]`, data.products[index].notes);
    }

    /**
     * Append attachments to the form data.
     */
    data.attachments.forEach(
      (attachment) => attachment && formData.append(`attachments[]`, attachment, attachment?.name)
    );

    return this.http.post<AppHttpResponse<SaleInvoice>>(`${this.saleInvoicesApi}/opening-balance`, formData);
  }

  /**
   * Creates a new point of sale invoice from the provided data.
   * @param data The new point of sale invoice data.
   */
  public createFromPOS(data: CreatePointOfSaleInvoiceInput): Observable<AppHttpResponse<SaleInvoice>> {
    const formData: any = new FormData();
    formData.append('customerId', data.customerId);
    formData.append('saleInvoiceOrderTypeId', data.saleInvoiceOrderTypeId);
    formData.append('boardId', data.boardId);

    /**
     * Append products to the form data.
     */
    for (let index = 0; index < data.products.length; index++) {
      formData.append(`products[${index}][productId]`, data.products[index].productId);
      formData.append(`products[${index}][description]`, data.products[index].description);
      formData.append(`products[${index}][isPointsExchange]`, data.products[index].isPointsExchange);
      formData.append(`products[${index}][subProductId]`, data.products[index].subProductId);
      formData.append(`products[${index}][quantity]`, data.products[index].quantity);
      formData.append(`products[${index}][unitOfMeasureId]`, data.products[index].unitOfMeasureId);
      formData.append(`products[${index}][discount]`, data.products[index].discount);
      formData.append(`products[${index}][discountPercent]`, data.products[index].discountPercent);
      formData.append(`products[${index}][value]`, data.products[index].value);
      formData.append(`products[${index}][tax]`, data.products[index].tax);
      formData.append(`products[${index}][locationId]`, data.products[index].locationId);
      formData.append(`products[${index}][notes]`, data.products[index].notes);
    }

    /**
     * Append payments to the form data.
     */
    for (let index = 0; index < data.payments.length; index++) {
      formData.append(`payments[${index}][isCash]`, data.payments[index].isCash);
      formData.append(`payments[${index}][isElectronic]`, data.payments[index].isElectronic);
      formData.append(`payments[${index}][value]`, data.payments[index].value);
    }

    return this.http.post<AppHttpResponse<any>>(`${this.saleInvoicesApi}/point-of-sale`, formData);
  }

  /**
   * Creates a new maintenance invoice from the provided data.
   * @param data The new maintenance invoice data.
   */
  public createMaintenanceInvoice(data: CreateMaintenanceInvoiceInput): Observable<AppHttpResponse<SaleInvoice>> {
    const formData: any = new FormData();
    formData.append('locationId', data.locationId);
    formData.append('customerId', data.customerId);
    formData.append('customerAssetId', data.customerAssetId);
    formData.append('maintenanceItemDescription', data.maintenanceItemDescription);
    formData.append('maintenanceExpectedTime', data.maintenanceExpectedTime);
    formData.append('notes', data.notes);
    formData.append('transactionDate', data.transactionDate?.toISOString());
    formData.append('dateOfSupply', data.dateOfSupply?.toISOString());

    /**
     * Append products to the form data.
     */
    for (let index = 0; index < data.products.length; index++) {
      formData.append(`products[${index}][productId]`, data.products[index].productId);
      formData.append(`products[${index}][subProductId]`, data.products[index].subProductId);
      formData.append(`products[${index}][description]`, data.products[index].description);
      formData.append(`products[${index}][quantity]`, data.products[index].quantity);
      formData.append(`products[${index}][unitOfMeasureId]`, data.products[index].unitOfMeasureId);
      formData.append(`products[${index}][value]`, data.products[index].value);
      formData.append(`products[${index}][discount]`, data.products[index].discount);
      formData.append(`products[${index}][discountPercent]`, data.products[index].discountPercent);
      formData.append(`products[${index}][tax]`, data.products[index].tax);
      formData.append(`products[${index}][notes]`, data.products[index].notes);
    }

    /**
     * Append journal lines to the form data.
     */
    for (let index = 0; index < data.lines.length; index++) {
      formData.append(`lines[${index}][accountId]`, data.lines[index].accountId);
      formData.append(`lines[${index}][credit]`, data.lines[index].credit);
      formData.append(`lines[${index}][debit]`, data.lines[index].debit);
      formData.append(`lines[${index}][costCenterId]`, data.lines[index].costCenterId);
      formData.append(`lines[${index}][notes]`, data.lines[index].notes);
    }

    /**
     * Append attachments to the form data.
     */
    data.attachments.forEach(
      (attachment) => attachment && formData.append(`attachments[]`, attachment, attachment?.name)
    );

    return this.http.post<AppHttpResponse<SaleInvoice>>(`${this.saleInvoicesApi}/maintenance`, formData);
  }

  /**
   * Searches in the sale invoices by name.
   * @param invoiceNum The invoiceNum of the sales invoice.
   * @param orderNum The orderNum of the sales invoice.
   * @param customers The customers of the sales invoice.
   * @param locations The locations of the sales invoice.
   * @param saleInvoiceTypes The saleInvoiceTypes of the sales invoice.
   * @param saleInvoiceOrderTypes The saleInvoiceOrderTypes of the sales invoice.
   * @param fromDate The fromDate of the sales invoice.
   * @param toDate The toDate of the sales invoice.
   * @param page The current pagination page number.
   * @param pageSize The maximum number of sales invoices allowed per one pagination page.
   */
  public search(
    invoiceNum: number,
    orderNum: number,
    customers: number[],
    locations: number[],
    saleInvoiceTypes: number[],
    saleInvoiceOrderTypes: number[],
    fromDate: Date,
    toDate: Date,
    page: number,
    pageSize: number
  ): Observable<AppHttpResponse<SaleInvoice[]>> {
    const params = new HttpParams()
      .set('invoiceNum', invoiceNum?.toString() ?? '')
      .set('orderNum', orderNum?.toString() ?? '')
      .set('customers', customers.join(','))
      .set('locations', locations.join(','))
      .set('saleInvoiceTypes', saleInvoiceTypes?.join(','))
      .set('saleInvoiceOrderTypes', saleInvoiceOrderTypes?.join(','))
      .set('fromDate', fromDate?.toISOString())
      .set('toDate', toDate?.toISOString())
      .set('page', page.toString())
      .set('pageSize', pageSize.toString());
    return this.http.get<AppHttpResponse<SaleInvoice[]>>(`${this.saleInvoicesApi}`, { params });
  }

  /**
   * Searches in the unpaid sale invoices using the provided params.
   * @param invoiceNum The invoiceNum of the sales invoice.
   * @param locations The locations of the sales invoice.
   * @param customers The customers of the sales invoice.
   * @param page The current pagination page number.
   * @param pageSize The maximum number of sales invoices allowed per one pagination page.
   */
  public searchUnpaid(
    invoiceNum: number,
    locations: number[],
    customers: number[],
    page: number,
    pageSize: number
  ): Observable<AppHttpResponse<SaleInvoice[]>> {
    const params = new HttpParams()
      .set('invoiceNum', invoiceNum?.toString() ?? '')
      .set('locations', locations.join(','))
      .set('customers', customers.join(','))
      .set('page', page.toString())
      .set('pageSize', pageSize.toString());
    return this.http.get<AppHttpResponse<SaleInvoice[]>>(`${this.saleInvoicesApi}/unpaid`, { params });
  }

  /**
   * Finds the sale invoice with the given id.
   * @param id The id of the sales invoice.
   */
  public findById(id: number): Observable<AppHttpResponse<SaleInvoice>> {
    return this.http.get<AppHttpResponse<SaleInvoice>>(`${this.saleInvoicesApi}/${id}`);
  }

  /**
   * Change maintenance stage by given id.
   * @param id The id of the sales invoice.
   */
  public changeMaintenanceStage(id: number, stage: MaintenanceStages): Observable<AppHttpResponse<SaleInvoice>> {
    return this.http.put<AppHttpResponse<SaleInvoice>>(
      `${this.saleInvoicesApi}/change-maintenance-stage/${id}/${stage}`,
      null
    );
  }
}
