import { PaginationInfo } from 'shared/models';
import { Lead, LeadAttachment, LeadNote, Quotation } from 'crm/models';

/**
 * Represents the leads state.
 */
export class LeadsState {
  /**
   * The list of leads.
   */
  public data: Lead[];

  /**
   * The pagination info for the leads.
   */
  public paginationInfo: PaginationInfo;

  /**
   * An error that may resulting during processing some actions.
   */
  public error: any;

  /**
   * Determines if there is a running search process.
   */
  public isSearching: boolean;

  /**
   * Determines if the last search process has been ended successfully.
   */
  public isSearchCompleted: boolean;

  /**
   * The current selected lead.
   */
  public selectedLead: Lead;

  /**
   * The current selected lead note.
   */
  public selectedLeadNote: LeadNote;

  /**
   * The current selected lead attachment.
   */
  public selectedLeadAttachment: LeadAttachment;

  /**
   * Determines if there is a running find-lead process.
   */
  public isFinding: boolean;

  /**
   * Determines if the last find-lead process has been ended successfully.
   */
  public isFindCompleted: boolean;

  /**
   * Determines if there is a running create process.
   */
  public isCreating: boolean;

  /**
   * Determines if the last create process has been ended successfully.
   */
  public isCreateCompleted: boolean;

  /**
   * Determines if there is a running create lead note process.
   */
  public isLeadNoteCreating: boolean;

  /**
   * Determines if the last create lead note process has been ended successfully.
   */
  public isLeadNoteCreateCompleted: boolean;

  /**
   * Determines if there is a running create lead attachments process.
   */
  public isLeadAttachmentsCreating: boolean;

  /**
   * Determines if the last create lead attachments  process has been ended successfully.
   */
  public isLeadAttachmentsCreateCompleted: boolean;

  /**
   * Determines if there is a running update process.
   */
  public isUpdating: boolean;

  /**
   * Determines if the last update process has been ended successfully.
   */
  public isUpdateCompleted: boolean;

  /**
   * Determines if there is a running delete process.
   */
  public isDeleting: boolean;

  /**
   * Determines if the last delete process has been ended successfully.
   */
  public isDeleteCompleted: boolean;

  /**
   * Determines if there is a running delete lead note process.
   */
  public isLeadNoteDeleting: boolean;

  /**
   * Determines if the last delete lead note process has been ended successfully.
   */
  public isLeadNoteDeleteCompleted: boolean;

  /**
   * Determines if there is a running delete lead attachment process.
   */
  public isLeadAttachmentDeleting: boolean;

  /**
   * Determines if the last delete lead attachment process has been ended successfully.
   */
  public isLeadAttachmentDeleteCompleted: boolean;

  /**
   * Determines if there is a running update process.
   */
  public isLeadStatusMarkAsNotHandledYetUpdating: boolean;

  /**
   * Determines if the last update process has been ended successfully.
   */
  public isLeadStatusMarkAsNotHandledYetUpdateCompleted: boolean;

  /**
   * Determines if there is a running update process.
   */
  public isLeadStatusMarkAsLostUpdating: boolean;

  /**
   * Determines if the last update process has been ended successfully.
   */
  public isLeadStatusMarkAsLostUpdateCompleted: boolean;

  /**
   * Determines if there is a running update process.
   */
  public isLeadStatusMarkAsOpportunityUpdating: boolean;

  /**
   * Determines if the last update process has been ended successfully.
   */
  public isLeadStatusMarkAsOpportunityUpdateCompleted: boolean;
}
