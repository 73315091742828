import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { AppHttpResponse } from 'shared';
import { Location } from 'stores/models';
import { ApiKey, User } from 'security/models';
import { BankAccount, CostCenter } from 'finances/models';
import { UserLoginResult, UpdateUserProfileInfoInput, UpdateUserProfileCredentialsInput } from 'auth/models';

/**
 * Provides functionality for login or re-authenticating a user.
 */
@Injectable()
export class AuthService {
  /**
   * The relative route for the auth.
   *
   * No leading or trailing slashes required.
   */
  private authApi = 'security/auth';

  /**
   * The relative route for the user profile.
   *
   * No leading or trailing slashes required.
   */
  private userProfileApi = 'security/users/profile';

  /**
   * The relative route for the user profile.
   *
   * No leading or trailing slashes required.
   */
  private apiKeyApi = 'security/users/api-key';

  constructor(private http: HttpClient) {}

  /**
   * Sends a login request to the server with the given credentials.
   * @param username The username for the user.
   * @param password The password for the user.
   */
  public login(username: string, password: string): Observable<AppHttpResponse<UserLoginResult>> {
    return this.http.post<AppHttpResponse<UserLoginResult>>(`${this.authApi}/login`, { username, password });
  }

  /**
   * Sends a refresh json web token request for the current logged in user's jwt.
   */
  public refreshToken(): Observable<AppHttpResponse<UserLoginResult>> {
    return this.http.post<AppHttpResponse<UserLoginResult>>(`${this.authApi}/refresh-jwt`, null);
  }

  /** Returns the current logged-in user's profile data. */
  public getUserProfileData(): Observable<AppHttpResponse<User>> {
    return this.http.get<AppHttpResponse<User>>(this.userProfileApi);
  }

  /**
   * Gets the list of user locations.
   */
  public getUserLocations(): Observable<AppHttpResponse<Location[]>> {
    return this.http.get<AppHttpResponse<Location[]>>(`${this.userProfileApi}/my-locations`);
  }

  /**
   * Gets the list of user bank accounts.
   * @param id The id of the user.
   */
  public getUserBankAccounts(): Observable<AppHttpResponse<BankAccount[]>> {
    return this.http.get<AppHttpResponse<BankAccount[]>>(`${this.userProfileApi}/my-bank-accounts`);
  }

  /**
   * Gets the list of user cost centers.
   * @param id The id of the user.
   */
  public getUserCostCenters(): Observable<AppHttpResponse<CostCenter[]>> {
    return this.http.get<AppHttpResponse<CostCenter[]>>(`${this.userProfileApi}/my-cost-centers`);
  }

  /**
   * Updates the current logged-in user's profile info based on the provided data-model.
   * @param data The data-model to update the user profile.
   */
  public updateUserProfileInfo(data: UpdateUserProfileInfoInput): Observable<AppHttpResponse<User>> {
    return this.http.put<AppHttpResponse<User>>(`${this.userProfileApi}/info`, data);
  }

  /**
   * Updates the current logged-in user's language.
   */
  public updateUserLang(lang: string): Observable<AppHttpResponse<User>> {
    return this.http.put<AppHttpResponse<User>>(`${this.userProfileApi}/language/${lang}`, null);
  }

  /**
   * Updates the current logged-in user's credentials based on the provided data-model.
   * @param data The data-model to update the user's credentials.
   */
  public updateUserProfileCredentials(data: UpdateUserProfileCredentialsInput): Observable<AppHttpResponse<User>> {
    return this.http.put<AppHttpResponse<User>>(`${this.userProfileApi}/credentials`, data);
  }

  /**
   * Updates the default location of user to satisfying the given criteria.
   * @param id The id of the user location.
   */
  public updateUserDefaultLocation(id: number): Observable<AppHttpResponse<User>> {
    return this.http.put<AppHttpResponse<User>>(`${this.userProfileApi}/default-location/${id}`, null);
  }

  /**
   * Updates the default cost center to satisfying the given criteria.
   * @param id The id of the user cost center.
   */
  public updateUserDefaultCostCenter(id: number): Observable<AppHttpResponse<User>> {
    return this.http.put<AppHttpResponse<User>>(`${this.userProfileApi}/default-cost-center/${id}`, null);
  }

  /**
   * Updates the default cash of user to satisfying the given criteria.
   * @param id The id of the user cash.
   */
  public updateUserDefaultCashAccount(id: number): Observable<AppHttpResponse<User>> {
    return this.http.put<AppHttpResponse<User>>(`${this.userProfileApi}/default-cash-bank-account/${id}`, null);
  }

  /**
   * Updates the default electronic of user to satisfying the given criteria.
   * @param id The id of the user electronic.
   */
  public updateUserDefaultElectronicAccount(id: number): Observable<AppHttpResponse<User>> {
    return this.http.put<AppHttpResponse<User>>(`${this.userProfileApi}/default-electronic-bank-account/${id}`, null);
  }

  /**
   * Updates the default pos devices of user to satisfying the given criteria.
   * @param id The id of the user pos device.
   */
  public updateUserDefaultPosDevice(id: number): Observable<AppHttpResponse<User>> {
    return this.http.put<AppHttpResponse<User>>(`${this.userProfileApi}/default-pos-device/${id}`, null);
  }

  /**
   * Gets the api key.
   */
  public getApiKey(): Observable<AppHttpResponse<ApiKey>> {
    return this.http.get<AppHttpResponse<ApiKey>>(`${this.apiKeyApi}`);
  }

  /**
   * Updates an api key.
   */
  public updateApiKey(): Observable<AppHttpResponse<ApiKey>> {
    return this.http.put<AppHttpResponse<ApiKey>>(`${this.apiKeyApi}`, null);
  }
}
