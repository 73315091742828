<!-- resource details starts -->
<div class="row">
  <div class="col-md-12">
    <div class="card-header mb-3 pt-0">
      <h4 class="card-title">{{ 'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.OUTGOING_STOCK_DETAILS' | translate }}</h4>
    </div>
    <div class="card-content">
      <div class="px-3">
        <div class="row">
          <ng-container>
            <div class="form-group col-md-6">
              <label for="resource.outgoingStock.outgoingStockType.name">{{
                'STOCKS.OUTGOING_STOCKS.OUTGOING_STOCK_DATA.OUTGOING_STOCK_TYPE' | translate
              }}</label>
              <input
                type="text"
                id="resource.outgoingStock.outgoingStockType.name"
                class="form-control round"
                [value]="
                  resource.outgoingStock.outgoingStockType.name
                    | localize: resource.outgoingStock.outgoingStockType.nameEn
                    | async
                "
                readonly
              />
            </div>
            <div *ngIf="resource.outgoingStock.saleInvoice?.invoiceNum" class="form-group col-md-6">
              <label for="resource.outgoingStock.saleInvoice.invoiceNum">{{
                'STOCKS.OUTGOING_STOCKS.OUTGOING_STOCK_DATA.SALE_INVOICE' | translate
              }}</label>
              <input
                type="text"
                id="resource.outgoingStock.saleInvoice.invoiceNum"
                class="form-control round"
                [value]="resource.outgoingStock.saleInvoice?.invoiceNum"
                readonly
              />
            </div>
            <div class="form-group col-md-6">
              <label for="resource.outgoingStock.location.name">{{
                'STOCKS.OUTGOING_STOCKS.OUTGOING_STOCK_DATA.STOCK_LOCATION' | translate
              }}</label>
              <input
                type="text"
                id="resource.outgoingStock.location.name"
                class="form-control round"
                [value]="
                  resource.outgoingStock.location.name | localize: resource.outgoingStock.location.nameEn | async
                "
                readonly
              />
            </div>
            <div class="form-group col-md-6">
              <label for="resource.outgoingStock.transactionDate">{{
                'STOCKS.OUTGOING_STOCKS.OUTGOING_STOCK_DATA.OUTGOING_STOCK_DATE' | translate
              }}</label>
              <input
                type="text"
                id="resource.outgoingStock.transactionDate"
                class="form-control round"
                [value]="resource.outgoingStock.transactionDate | date: 'datetime'"
                readonly
              />
            </div>
            <div class="form-group col-md-6">
              <label for="resource.outgoingStock.createdAt">{{ 'SHARED.SEARCH.CREATED_AT' | translate }}</label>
              <input
                type="text"
                id="resource.outgoingStock.createdAt"
                class="form-control round"
                [value]="resource.outgoingStock.createdAt | date: 'datetime'"
                readonly
              />
            </div>
            <div class="form-group col-md-12">
              <label for="resource.outgoingStock.user.name">
                {{ 'STOCKS.OUTGOING_STOCKS.OUTGOING_STOCK_DATA.USER_CREATE' | translate }}</label
              >
              <input
                type="text"
                id="resource.outgoingStock.user.name"
                class="form-control round"
                [value]="resource.outgoingStock.user.name"
                readonly
              />
            </div>
            <div class="form-group col-md-12">
              <label for="resource.outgoingStock.notes">{{
                'STOCKS.OUTGOING_STOCKS.OUTGOING_STOCK_DATA.NOTES' | translate
              }}</label>
              <textarea
                id="resource.outgoingStock.notes"
                class="form-control round"
                [innerText]="resource.outgoingStock.notes"
                [placeholder]="'STOCKS.OUTGOING_STOCKS.OUTGOING_STOCK_DATA.NOTES_NOT_FOUND_PLACEHOLDER' | translate"
                readonly
              ></textarea>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
    <!-- customer details ends -->

    <!-- products details starts -->
    <div class="card-header mb-3 pt-2">
      <h4 class="card-title">{{ 'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.PRODUCTS' | translate }}</h4>
    </div>
    <div class="card-content">
      <div class="px-3">
        <div class="row">
          <div class="col-md-12">
            <div class="table-responsive">
              <table class="table text-center">
                <thead>
                  <tr>
                    <th scope="col"><i class="fa fa-list-ol"></i></th>
                    <th scope="col">
                      <i class="fa fa-comments"></i>
                      {{ 'STOCKS.OUTGOING_STOCKS.OUTGOING_STOCK_DATA.PRODUCT' | translate }}
                    </th>
                    <th scope="col">
                      <i class="fa fa-list-ol"></i>
                      {{ 'STOCKS.OUTGOING_STOCKS.OUTGOING_STOCK_DATA.PRODUCT_QUANTITY' | translate }}
                    </th>
                    <th scope="col">
                      <i class="fa fa-usd"></i>
                      {{ 'STOCKS.OUTGOING_STOCKS.OUTGOING_STOCK_DATA.PRODUCT_VALUE' | translate }}
                    </th>
                    <th scope="col">
                      <i class="fa fa-usd"></i>
                      {{ 'STOCKS.OUTGOING_STOCKS.OUTGOING_STOCK_DATA.PRODUCT_TOTAL' | translate }}
                    </th>
                    <th scope="col">
                      {{ 'STOCKS.OUTGOING_STOCKS.OUTGOING_STOCK_DATA.PROJECT_SUB_TASK' | translate }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let projectResourceLine of projectResourceOutgoingStockLines; let i = index">
                    <th scope="row">{{ i + 1 }}</th>
                    <td>
                      {{
                        (projectResourceLine.outgoingStockProduct.product?.description
                          | localize: projectResourceLine.outgoingStockProduct.product?.descriptionEn
                          | async) || projectResourceLine.outgoingStockProduct.description
                      }}
                      <div *ngIf="projectResourceLine.outgoingStockProduct.subProduct" class="mt-1 d-block">
                        <app-sub-product-info
                          [subProduct]="projectResourceLine.outgoingStockProduct.subProduct"
                        ></app-sub-product-info>
                      </div>
                    </td>
                    <td>{{ projectResourceLine.outgoingStockProduct?.quantity | toDecimal }}</td>
                    <td>{{ projectResourceLine.outgoingStockProduct?.value | toDecimal }}</td>
                    <td>{{ projectResourceLine.outgoingStockProduct?.total | toDecimal }}</td>
                    <td>
                      {{
                        projectResourceLine.outgoingStockProduct?.projectSubTask?.name
                          | localize: projectResourceLine.outgoingStockProduct?.projectSubTask?.nameEn
                          | async
                      }}
                      <span class="d-block">
                        ({{
                          projectResourceLine.outgoingStockProduct?.projectSubTask?.project.description
                            | localize: projectResourceLine.outgoingStockProduct?.projectSubTask?.project.descriptionEn
                            | async
                        }})
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- products details ends -->
  </div>
</div>
<!-- resource details ends -->
