import { Action } from '@ngrx/store';

import { AppHttpResponse } from 'shared';
import { PosDevice } from 'sales/models';

/**
 * The stores-module store pos device action types.
 */
export enum PosDeviceActionType {
  SEARCH_POS_DEVICES = '[AppSearch] [POSDevice] Search POS Device',
  SEARCH_POS_DEVICES_FAIL = '[AppSearch] [POSDevice] Search POS Device Fail',
  SEARCH_POS_DEVICES_SUCCESS = '[AppSearch] [POSDevice] Search POS Device Success',
}

/**
 * Represents a store Pos device search action.
 */
export class SearchPosDevices implements Action {
  /**
   * The type of the action.
   */
  readonly type = PosDeviceActionType.SEARCH_POS_DEVICES;

  /**
   * Fires a new pos device search action.
   * @param payload the search parameters,
   * if omitted, all pos device will be loaded.
   */
  constructor(
    public payload: {
      name: string;
      locations: number[];
      page: number;
    }
  ) {}
}

/**
 * Represents a store pos device search fail action.
 */
export class SearchPosDevicesFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = PosDeviceActionType.SEARCH_POS_DEVICES_FAIL;

  /**
   * Fires a new pos device search fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store search success action.
 */
export class SearchPosDevicesSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = PosDeviceActionType.SEARCH_POS_DEVICES_SUCCESS;

  /**
   * Fires a new pos device search success action.
   * @param payload An object contains the list of pos device that matches the search criteria
   * in addition to the pagination info.
   */
  constructor(public payload: AppHttpResponse<PosDevice[]>) {}
}

/**
 * stores-module pos device action types.
 */
export type PosDeviceActions = SearchPosDevices | SearchPosDevicesFail | SearchPosDevicesSuccess;
