import { Action } from '@ngrx/store';

import { SaleInvoice } from 'sales/models';
import { AppHttpResponse } from 'shared';

/**
 * The Sales-Module store orders delivery action types.
 */
export enum OrdersDeliveryActionType {
  SEARCH_ORDERS_DELIVERY = '[Sales] [SaleInvoices] Search Orders Delivery',
  SEARCH_ORDERS_DELIVERY_FAIL = '[Sales] [SaleInvoices] Search Orders Delivery Fail',
  SEARCH_ORDERS_DELIVERY_SUCCESS = '[Sales] [SaleInvoices] Search Orders Delivery Success',

  DELIVERY_ORDER = '[Sales] [SaleInvoices] Delivery order',
  DELIVERY_ORDER_FAIL = '[Sales] [SaleInvoices] Delivery order Fail',
  DELIVERY_ORDER_SUCCESS = '[Sales] [SaleInvoices] Delivery order Success',
}

/**
 * Represents a store orders delivery  action.
 */
export class SearchOrdersDelivery implements Action {
  /**
   * The type of the action.
   */
  readonly type = OrdersDeliveryActionType.SEARCH_ORDERS_DELIVERY;

  /**
   * Fires a new orders delivery of action.
   * @param payload the search parameters,
   * if omitted, all orders delivery will be loaded.
   */
  constructor(
    public payload: {
      locations: number[];
      page: number;
    }
  ) {}
}

/**
 * Represents a store orders deliver-fail action.
 */
export class SearchOrdersDeliveryFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = OrdersDeliveryActionType.SEARCH_ORDERS_DELIVERY_FAIL;

  /**
   * Fires a new orders delivery-fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store orders delivery-success action.
 */
export class SearchOrdersDeliverySuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = OrdersDeliveryActionType.SEARCH_ORDERS_DELIVERY_SUCCESS;

  /**
   * Fires a new orders delivery-success action.
   * @param payload An object contains the list of orders delivery that matches the prepare criteria
   */
  constructor(public payload: AppHttpResponse<SaleInvoice[]>) {}
}

/**
 * Represents a store order delivery action.
 */
export class DeliveryOrder implements Action {
  /**
   * The type of the action.
   */
  readonly type = OrdersDeliveryActionType.DELIVERY_ORDER;

  /**
   * Fires a new delivery  action.
   * @param payload The order's id.
   */
  constructor(public payload: number) {}
}

/**
 * Represents a store order delivery-fail action.
 */
export class DeliveryOrderFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = OrdersDeliveryActionType.DELIVERY_ORDER_FAIL;

  /**
   * Fires a order delivery-fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store order delivery-success action.
 */
export class DeliveryOrderSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = OrdersDeliveryActionType.DELIVERY_ORDER_SUCCESS;

  /**
   * Fires order delivery-success action.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Sales-module orders delivery action types.
 */
export type OrdersDeliveryActions =
  | SearchOrdersDelivery
  | SearchOrdersDeliverySuccess
  | SearchOrdersDeliveryFail
  | DeliveryOrder
  | DeliveryOrderSuccess
  | DeliveryOrderFail;
