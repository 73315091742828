import { findIndex, remove } from 'lodash';

import { RegionsActionType, RegionsActions } from 'stores/store/actions';
import { RegionsState } from 'stores/store/states';

/**
 * The initial state from which the state starts.
 */
const initialState: RegionsState = {
  data: [],
  paginationInfo: {
    total: 0,
    page: 1,
    pageSize: 0,
    count: 0,
  },
  error: null,
  isSearching: false,
  isSearchCompleted: false,
  selectedRegion: null,
  isFinding: false,
  isFindCompleted: false,
  isCreating: false,
  isCreateCompleted: false,
  isUpdating: false,
  isUpdateCompleted: false,
  isDeleting: false,
  isDeleteCompleted: false,
};

/**
 * The reducer function that is called in each action dispatch against the state.
 * @param state The current state.
 * @param action The action that will affect the state.
 */
export function regionsReducer(state: RegionsState = initialState, action: RegionsActions): RegionsState {
  switch (action.type) {
    //#region SEARCH_REGIONS

    case RegionsActionType.SEARCH_REGIONS: {
      return {
        ...state,
        isSearching: true,
        isSearchCompleted: false,
        error: null,
      };
    }

    case RegionsActionType.SEARCH_REGIONS_FAIL: {
      return {
        ...state,
        error: action.payload,
        isSearching: false,
        isSearchCompleted: false,
      };
    }

    case RegionsActionType.SEARCH_REGIONS_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        paginationInfo: {
          ...action.payload.meta,
        },
        error: null,
        isSearching: false,
        isSearchCompleted: true,
      };
    }

    //#endregion SEARCH_REGIONS

    //#region FIND_REGION

    case RegionsActionType.FIND_REGION: {
      return {
        ...state,
        selectedRegion: null,
        error: null,
        isFinding: true,
        isFindCompleted: false,
      };
    }

    case RegionsActionType.FIND_REGION_FAIL: {
      return {
        ...state,
        error: action.payload,
        isFinding: false,
        isFindCompleted: false,
      };
    }
    case RegionsActionType.FIND_REGION_SUCCESS: {
      let region = [...state.data];
      const regionIndex = findIndex(region, (region) => region.id === action.payload.data.id);

      /* If region was found, update it. */
      if (regionIndex >= 0) {
        region[regionIndex] = action.payload.data;
      } else {
        /* else, insert it to the beginning of the array. */
        region = [action.payload.data, ...region];
      }

      return {
        ...state,
        data: region,
        selectedRegion: action.payload.data,
        error: null,
        isFinding: false,
        isFindCompleted: true,
      };
    }

    //#endregion FIND_REGION

    //#region CREATE_REGION

    case RegionsActionType.CREATE_REGION: {
      return {
        ...state,
        isCreating: true,
        isCreateCompleted: false,
        error: null,
      };
    }

    case RegionsActionType.CREATE_REGION_FAIL: {
      return {
        ...state,
        error: action.payload,
        isCreating: false,
        isCreateCompleted: false,
      };
    }

    case RegionsActionType.CREATE_REGION_SUCCESS: {
      return {
        ...state,
        data: [action.payload.data, ...state.data],
        selectedRegion: action.payload.data,
        error: null,
        isCreating: false,
        isCreateCompleted: true,
      };
    }

    //#endregion CREATE_REGION

    //#region UPDATE_REGION

    case RegionsActionType.UPDATE_REGION: {
      return {
        ...state,
        isUpdating: true,
        isUpdateCompleted: false,
        error: null,
      };
    }

    case RegionsActionType.UPDATE_REGION_FAIL: {
      return {
        ...state,
        error: action.payload,
        isUpdating: false,
        isUpdateCompleted: false,
      };
    }

    case RegionsActionType.UPDATE_REGION_SUCCESS: {
      let region = [...state.data];
      const regionIndex = findIndex(region, (region) => region.id === action.payload.data.id);

      /* If region was found, update it. */
      if (regionIndex >= 0) {
        region[regionIndex] = action.payload.data;
      } else {
        /* else, insert it to the beginning of the array. */
        region = [action.payload.data, ...region];
      }

      return {
        ...state,
        data: region,
        selectedRegion: action.payload.data,
        error: null,
        isUpdating: false,
        isUpdateCompleted: true,
      };
    }

    //#endregion UPDATE_REGION

    //#region DELETE_REGION

    case RegionsActionType.DELETE_REGION: {
      return {
        ...state,
        isDeleting: true,
        isDeleteCompleted: false,
        error: null,
      };
    }

    case RegionsActionType.DELETE_REGION_FAIL: {
      return {
        ...state,
        error: action.payload,
        isDeleting: false,
        isDeleteCompleted: false,
      };
    }

    case RegionsActionType.DELETE_REGION_SUCCESS: {
      const region = [...state.data];

      /* Remove the deleted region from the array. */
      remove(region, (region) => region.id === action.payload.data.id);

      return {
        ...state,
        data: region,
        selectedRegion: action.payload.data,
        error: null,
        isDeleting: false,
        isDeleteCompleted: true,
      };
    }

    //#endregion DELETE_CURRENCY_EXCHANGE_RATE

    default:
      return state;
  }
}
