import { createSelector } from '@ngrx/store';

import { selectLookupsState } from 'lookups/store/selectors/feature.selectors';
import { LookupsState, LoanPaymentTypeState } from 'lookups/store/states';

/**
 * Gets the loan payment type state.
 */
const selectLoanPaymentTypes = createSelector(selectLookupsState, (state: LookupsState) => state.loanPaymentTypes);

/**
 * Gets the list of loaded loan payment types.
 */
export const getLoanPaymentTypes = createSelector(selectLoanPaymentTypes, (state: LoanPaymentTypeState) => state.data);

/**
 * Gets the error that occurred in the state.
 */
export const getLoanPaymentTypesError = createSelector(
  selectLoanPaymentTypes,
  (state: LoanPaymentTypeState) => state.error
);

/**
 * Determines if there is a running load process.
 */
export const getLoanPaymentTypesIsLoading = createSelector(
  selectLoanPaymentTypes,
  (state: LoanPaymentTypeState) => state.isLoading
);

/**
 * Determines if the last load process has been ended successfully.
 */
export const getLoanPaymentTypesIsLoaded = createSelector(
  selectLoanPaymentTypes,
  (state: LoanPaymentTypeState) => state.isLoaded
);
