import { Component, ViewChild, ElementRef, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Store, select } from '@ngrx/store';

import * as fromAuthStore from 'auth/store';
import { User } from 'security/models';

/**
 * The customer pages.
 */
enum PAGES {
  'user-locations' = 'user-locations',
  'user-cost-centers' = 'user-cost-centers',
  'user-cash-accounts' = 'user-cash-accounts',
  'user-electronic-accounts' = 'user-electronic-accounts',
  'user-POS-devices' = 'user-POS-devices',
}

@Component({
  selector: 'app-user-preferences',
  templateUrl: './user-preferences.component.html',
  styles: [],
})
export class UserPreferencesComponent implements OnInit {
  /**
   * The modal template reference.
   */
  @ViewChild('modalRef') modalRef: ElementRef<any>;

  /**
   * Sets the visibility of this component modal.
   *
   * Handles visibility change in two-data-binding approach.
   */
  @Input() set visible(isShown: boolean) {
    if (isShown && this.modalRef) {
      this.openModal(this.modalRef, 'lg');
    } else {
      this.closeModal();
    }

    this.visibleChange.emit(isShown);
  }

  /**
   * Outputs a value that indicates whether the modal is shown or not.
   *
   * Handles visibility change in two-data-binding approach.
   */
  @Output() visibleChange = new EventEmitter<boolean>();

  /**
   * Gets or sets the selected page.
   * @default 'user-locations'
   */
  activePage: PAGES = PAGES['user-locations'];

  /**
   * Gets or sets the current edited user.
   */
  currentUser$: Observable<User>;

  /**
   * The ngb-modal reference to use it when modal needed to be closed.
   */
  modal: NgbModalRef;

  /**
   * @param authStore$ The auth-store$.
   * @param modalService The modal service.
   */
  constructor(private authStore$: Store<fromAuthStore.AuthState>, private modalService: NgbModal) {}

  ngOnInit(): void {
    this.init();
  }

  /**
   * Initialize component data.
   */
  init() {
    /** Load current logged in user data. */
    this.currentUser$ = this.authStore$.pipe(select(fromAuthStore.getSessionUser));
  }

  /**
   * Opens the modal of the given templateRef.
   * @param modalRef The modal templateRef to be opened.
   * @param size An optional modal size, default sm.
   */
  openModal(modalRef, size = 'lg') {
    /**
     * Open the modal & listen for user press ESC key.
     */
    this.modal = this.modalService.open(modalRef, { size });
    this.modal.result.then(null, () => this.closeModal());
  }

  /**
   * Closes the currently opened modal.
   */
  closeModal() {
    this.modal?.dismiss();
    this.visibleChange.emit(false);
  }
}
