<!-- page header starts -->
<app-page-header [info]="pageInfo"></app-page-header>
<!-- page header ends -->

<!-- project file pages start -->
<div class="card">
  <div class="card-content">
    <div class="px-3 pt-3" *ngIf="project$ | async; let project">
      <ul
        class="nav-tabs"
        ngbNav
        #nav="ngbNav"
        [(activeId)]="activePage"
        (activeIdChange)="selectedPageChanged($event)"
        [destroyOnHide]="false"
      >
        <li *ngIf="[Claims.UPDATE_PROJECT] | authorize | async" ngbNavItem="info">
          <a ngbNavLink>
            <i class="fa fa-id-badge" [class.success]="activePage == 'info'"></i>
            {{ 'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.PROJECT_DATA_TITLE' | translate }}
          </a>
          <ng-template ngbNavContent>
            <app-project-info [project]="project" [projectStatuses]="projectStatuses$ | async"></app-project-info>
          </ng-template>
        </li>
        <li *ngIf="[Claims.UPDATE_PROJECT] | authorize | async" ngbNavItem="plan">
          <a ngbNavLink>
            <i class="fa fa-american-sign-language-interpreting" [class.success]="activePage == 'plan'"></i>
            {{ 'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.PROJECT_PLAN' | translate }}
          </a>
          <ng-template ngbNavContent>
            <app-project-plan [projectId]="project.id"></app-project-plan>
          </ng-template>
        </li>
        <li *ngIf="[Claims.UPDATE_PROJECT] | authorize | async" ngbNavItem="planning-resources">
          <a ngbNavLink>
            <i class="fa fa-building-o" [class.success]="activePage == 'planning-resources'"></i>
            {{ 'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.PLANNING_RESOURCES' | translate }}
          </a>
          <ng-template ngbNavContent>
            <app-update-project-planning-resources
              [projectId]="project.id"
              [projectPlanningResources]="project.projectPlanningResourceTypes"
            ></app-update-project-planning-resources>
          </ng-template>
        </li>
        <li *ngIf="[Claims.UPDATE_PROJECT] | authorize | async" ngbNavItem="resources">
          <a ngbNavLink>
            <i class="fa fa-industry" [class.success]="activePage == 'resources'"></i>
            {{ 'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.RESOURCES' | translate }}
          </a>
          <ng-template ngbNavContent>
            <app-project-resources [projectId]="project.id"></app-project-resources>
          </ng-template>
        </li>
        <li *ngIf="[Claims.UPDATE_PROJECT] | authorize | async" ngbNavItem="revenues">
          <a ngbNavLink>
            <i class="fa fa-percent" [class.success]="activePage == 'revenues'"></i>
            {{ 'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.REVENUES' | translate }}
          </a>
          <ng-template ngbNavContent>
            <app-project-revenues [projectId]="project.id"></app-project-revenues>
          </ng-template>
        </li>
        <li *ngIf="[Claims.UPDATE_PROJECT] | authorize | async" ngbNavItem="project-status-log">
          <a ngbNavLink>
            <i class="fa fa-leaf" [class.success]="activePage == 'project-status-log'"></i>
            {{ 'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.STATUS_LOG' | translate }}
          </a>
          <ng-template ngbNavContent>
            <app-project-status-log
              [projectId]="project.id"
              [projectStatuses]="projectStatuses$ | async"
            ></app-project-status-log>
          </ng-template>
        </li>
      </ul>
      <div [ngbNavOutlet]="nav" class="mt-2"></div>
    </div>
  </div>
</div>
<!-- project file pages end -->
