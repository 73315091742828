import { Action } from '@ngrx/store';

import { Customer } from 'sales/models';
import { AppHttpResponse } from 'shared';

/**
 * The App-Search-Module store customers action types.
 */
export enum CustomersActionType {
  SEARCH_CUSTOMERS = '[AppSearch] [Customers] Search Customers',
  SEARCH_CUSTOMERS_FAIL = '[AppSearch] [Customers] Search Customers Fail',
  SEARCH_CUSTOMERS_SUCCESS = '[AppSearch] [Customers] Search Customers Success',
}

/**
 * Represents a store customers search action.
 */
export class SearchCustomers implements Action {
  /**
   * The type of the action.
   */
  readonly type = CustomersActionType.SEARCH_CUSTOMERS;

  /**
   * Fires a new customers search action.
   * @param payload the search parameters,
   * if omitted, all customers will be loaded.
   */
  constructor(public payload?: { name: string; page: number }) {}
}

/**
 * Represents a store customers search-fail action.
 */
export class SearchCustomersFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = CustomersActionType.SEARCH_CUSTOMERS_FAIL;

  /**
   * Fires a new customers search-fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store search-success action.
 */
export class SearchCustomersSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = CustomersActionType.SEARCH_CUSTOMERS_SUCCESS;

  /**
   * Fires a new customers search-success action.
   * @param payload An object contains the list of customers that matches the search criteria
   * in addition to the pagination info.
   */
  constructor(public payload: AppHttpResponse<Customer[]>) {}
}
/**
 * App-Search-module customers action types.
 */
export type CustomersActions = SearchCustomers | SearchCustomersFail | SearchCustomersSuccess;
