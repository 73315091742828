import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-dockable-status',
  templateUrl: './dockable-status.component.html',
  styles: [],
})
export class DockableStatusComponent {
  /**
   * The status flag.
   */
  @Input() status: boolean;

  /**
   * Indicates whether the component should be centered to its parent.
   */
  @Input() isCentered: boolean;

  /**
   * Active status text.
   */
  @Input() activeText: string;

  /**
   * Not-Active status text.
   */
  @Input() notActiveText: string;
}
