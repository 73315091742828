<!-- update user form starts -->
<div class="row" *ngIf="user$ | async; let user">
  <div class="col-md-12">
    <div class="card">
      <div class="card-content">
        <!-- user claims starts -->
        <app-user-claims-form [groups]="claimGroupsModel"></app-user-claims-form>
        <!-- user claims ends -->

        <div class="form-group col-12 d-flex justify-content-between mt-4">
          <button
            type="button"
            class="btn btn-raised btn-primary round"
            (click)="submit()"
            [disabled]="isUpdating$ | async"
          >
            <i class="fa fa-check"></i>
            <span class="mx-1">{{ 'SHARED.CONFIRM.SAVE' | translate }}</span>
          </button>
          <a class="btn btn-dark round" routerLink="/security/organization-users">
            <i class="fa fa-undo"></i>
            <span class="mx-1">{{ 'SECURITY.USERS.USER_DATA.USERS_RETURN' | translate }}</span>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- update user form ends -->

<!-- confirm modal starts -->
<ng-template #confirmModalRef let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title text-primary" id="modal-basic-title">
      {{ 'SECURITY.USERS.CONFIRM_UPDATE_USER_PERMISSIONS_TITLE' | translate }}
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="d()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p class="text-primary">{{ 'SECURITY.USERS.CONFIRM_UPDATE_USER_PERMISSIONS_MESSAGE' | translate }}</p>
  </div>
  <div class="modal-footer">
    <div class="modal-actions">
      <button type="button" class="btn btn-raised btn-primary round" (click)="c(); confirm()" ngbAutofocus>
        <i class="fa fa-check"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.SAVE' | translate }}</span>
      </button>
      <button type="button" class="btn btn-outline-dark round" (click)="c()">
        <i class="fa fa-times"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.CANCEL' | translate }}</span>
      </button>
    </div>
  </div>
</ng-template>
<!-- confirm modal ends -->
