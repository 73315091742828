import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';

import { AppHttpResponse } from 'shared';
import { CreateBankInput, UpdateBankInput, Bank } from 'finances/models';

/**
 * The banks services includes functionality to create, search, findById, update and delete for an bank.
 */
@Injectable()
export class BanksService {
  /**
   * The relative route for the banks.
   *
   * No leading or trailing slashes required.
   */
  private banksApi = 'finances/banks';

  constructor(private http: HttpClient) {}

  /**
   * Creates a new bank from the provided data.
   * @param data The new bank data.
   */
  public create(data: CreateBankInput): Observable<AppHttpResponse<Bank>> {
    return this.http.post<AppHttpResponse<Bank>>(`${this.banksApi}`, data);
  }

  /**
   * Searches in the banks by name.
   * @param name The name of the bank.
   * @param page The current pagination page number.
   * @param pageSize The maximum number of banks allowed per one pagination page.
   */
  public search(name: string, page: number, pageSize: number): Observable<AppHttpResponse<Bank[]>> {
    const params = new HttpParams().set('name', name).set('page', page.toString()).set('pageSize', pageSize.toString());
    return this.http.get<AppHttpResponse<Bank[]>>(`${this.banksApi}`, { params });
  }

  /**
   * Finds the bank with the given id.
   * @param id The id of the bank.
   */
  public findById(id: number): Observable<AppHttpResponse<Bank>> {
    return this.http.get<AppHttpResponse<Bank>>(`${this.banksApi}/${id}`);
  }

  /**
   * Updates an existing bank data using the provided data.
   * @param data The updated bank data.
   */
  public update(data: UpdateBankInput): Observable<AppHttpResponse<Bank>> {
    return this.http.put<AppHttpResponse<Bank>>(`${this.banksApi}`, data);
  }

  /**
   * Deletes the bank by given id.
   * @param id The id of the bank.
   */
  public delete(id: number): Observable<AppHttpResponse<Bank>> {
    return this.http.delete<AppHttpResponse<Bank>>(`${this.banksApi}/${id}`);
  }
}
