import { Action } from '@ngrx/store';

import { ProductClass, CreateProductClassInput, UpdateProductClassInput } from 'stores/models';
import { AppHttpResponse } from 'shared';

/**
 * The Stores-Module store product classes action types.
 */
export enum ProductClassesActionType {
  SEARCH_PRODUCT_CLASSES = '[Stores] [ProductClasses] Search Product Classes',
  SEARCH_PRODUCT_CLASSES_FAIL = '[Stores] [ProductClasses] Search Product Classes Fail',
  SEARCH_PRODUCT_CLASSES_SUCCESS = '[Stores] [ProductClasses] Search Product Classes Success',
  SEARCH_PRODUCT_CLASSES_FOR_SALES_SCREEN = '[Stores] [Products] Search Product classes for sales screen',
  SEARCH_PRODUCT_CLASSES_FOR_SALES_SCREEN_FAIL = '[Stores] [Products] Search Product classes for sales screen Fail',
  SEARCH_PRODUCT_CLASSES_FOR_SALES_SCREEN_SUCCESS = '[Stores] [Products] Search Product classes for sales screen Success',
  SEARCH_PRODUCT_CLASSES_WITHOUT_PAGINATION = '[Stores] [ProductClasses] Search Product Classes Without Pagination',
  FIND_PRODUCT_CLASS = '[Stores] [ProductClasses] Find Product Class',
  FIND_PRODUCT_CLASS_FAIL = '[Stores] [ProductClasses] Find Product Class Fail',
  FIND_PRODUCT_CLASS_SUCCESS = '[Stores] [ProductClasses] Find Product Class Success',

  CREATE_PRODUCT_CLASS = '[Stores] [ProductClasses] Create Product Class',
  CREATE_PRODUCT_CLASS_FAIL = '[Stores] [ProductClasses] Create Product Class Fail',
  CREATE_PRODUCT_CLASS_SUCCESS = '[Stores] [ProductClasses] Create Product Class Success',

  UPDATE_PRODUCT_CLASS = '[Stores] [ProductClasses] Update Product Class',
  UPDATE_PRODUCT_CLASS_FAIL = '[Stores] [ProductClasses] Update Product Class Fail',
  UPDATE_PRODUCT_CLASS_SUCCESS = '[Stores] [ProductClasses] Update Product Class Success',

  DELETE_PRODUCT_CLASS = '[Stores] [ProductClasses] Delete Product Class',
  DELETE_PRODUCT_CLASS_FAIL = '[Stores] [ProductClasses] Delete Product Class Fail',
  DELETE_PRODUCT_CLASS_SUCCESS = '[Stores] [ProductClasses] Delete Product Class Success',

  UPDATE_PRODUCT_CLASS_PHOTO = '[Stores] [ProductClasses] Update Product Class Photo',
  DELETE_PRODUCT_CLASS_PHOTO = '[Stores] [ProductClasses] Delete Product Class Photo',
}

/**
 * Represents a store product classes search action.
 */
export class SearchProductClasses implements Action {
  /**
   * The type of the action.
   */
  readonly type = ProductClassesActionType.SEARCH_PRODUCT_CLASSES;

  /**
   * Fires a new product classes search action.
   * @param payload the search parameters,
   * if omitted, all product classes will be loaded.
   */
  constructor(public payload?: { name: string; locations: number[]; page: number }) {}
}

/**
 * Represents a store product classes search-fail action.
 */
export class SearchProductClassesFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = ProductClassesActionType.SEARCH_PRODUCT_CLASSES_FAIL;

  /**
   * Fires a new product classes search-fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store search-success action.
 */
export class SearchProductClassesSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = ProductClassesActionType.SEARCH_PRODUCT_CLASSES_SUCCESS;

  /**
   * Fires a new product classes search-success action.
   * @param payload An object contains the list of product classes that matches the search criteria
   * in addition to the pagination info.
   */
  constructor(public payload: AppHttpResponse<ProductClass[]>) {}
}

/**
 * Represents a store product classes for sales screen search action.
 */
export class SearchProductClassesForSalesScreen implements Action {
  /**
   * The type of the action.
   */
  readonly type = ProductClassesActionType.SEARCH_PRODUCT_CLASSES_FOR_SALES_SCREEN;

  /**
   * Fires a new product classes for sales screen search action.
   * if omitted, all product classes for sales screen will be loaded.
   */
  constructor(public payload?: number[]) {}
}

/**
 * Represents a store product classes for sales screen search-fail action.
 */
export class SearchProductClassesForSalesScreenFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = ProductClassesActionType.SEARCH_PRODUCT_CLASSES_FOR_SALES_SCREEN_FAIL;

  /**
   * Fires a new product classes for sales screen search-fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store search-success action.
 */
export class SearchProductClassesForSalesScreenSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = ProductClassesActionType.SEARCH_PRODUCT_CLASSES_FOR_SALES_SCREEN_SUCCESS;

  /**
   * Fires a new product classes for sales screen search-success action.
   * @param payload An object contains the list of product classes for sales screen that matches the search criteria
   * in addition to the pagination info.
   */
  constructor(public payload: AppHttpResponse<ProductClass[]>) {}
}

/**
 * Represents a store product classes without pagination search action.
 */
export class SearchProductClassesWithoutPagination implements Action {
  /**
   * The type of the action.
   */
  readonly type = ProductClassesActionType.SEARCH_PRODUCT_CLASSES_WITHOUT_PAGINATION;

  /**
   * Fires a new product classes without pagination search action.
   * @param payload the search parameters,
   * if omitted, all product classes will be loaded.
   */
  constructor(public payload?: { name: string; locations: number[] }) {}
}

/**
 * Represents a store find-product-class action.
 */
export class FindProductClass implements Action {
  /**
   * The type of the action.
   */
  readonly type = ProductClassesActionType.FIND_PRODUCT_CLASS;

  /**
   * Fires a new find product class action.
   * @param payload the id of the product class.
   */
  constructor(public payload: number) {}
}

/**
 * Represents a store find-product-class-fail action.
 */
export class FindProductClassFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = ProductClassesActionType.FIND_PRODUCT_CLASS_FAIL;

  /**
   * Fires a new find-product-class-fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store find product-class-success action.
 */
export class FindProductClassSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = ProductClassesActionType.FIND_PRODUCT_CLASS_SUCCESS;

  /**
   * Fires a new find-product-class-success action.
   * @param payload The loaded product class.
   */
  constructor(public payload: AppHttpResponse<ProductClass>) {}
}

/**
 * Represents a store product class create action.
 */
export class CreateProductClass implements Action {
  /** The type of the action. */
  readonly type = ProductClassesActionType.CREATE_PRODUCT_CLASS;

  /**
   * Fires a new product class create action.
   * @param payload the new product class data-model.
   */
  constructor(public payload: CreateProductClassInput) {}
}

/**
 * Represents a store product-class create-fail action.
 */
export class CreateProductClassFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = ProductClassesActionType.CREATE_PRODUCT_CLASS_FAIL;

  /**
   * Fires a new product-class create-fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store product-class create-success action.
 */
export class CreateProductClassSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = ProductClassesActionType.CREATE_PRODUCT_CLASS_SUCCESS;

  /**
   * Fires a new product-class create-success action.
   * @param payload The created product class.
   */
  constructor(public payload: AppHttpResponse<ProductClass>) {}
}

/**
 * Represents a store product class update action.
 */
export class UpdateProductClass implements Action {
  /**
   * The type of the action.
   */
  readonly type = ProductClassesActionType.UPDATE_PRODUCT_CLASS;

  /**
   * Fires a new product class update action.
   * @param payload The product-class's updated data.
   */
  constructor(public payload: UpdateProductClassInput) {}
}

/**
 * Represents a store product-class update-fail action.
 */
export class UpdateProductClassFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = ProductClassesActionType.UPDATE_PRODUCT_CLASS_FAIL;

  /**
   * Fires a new product-class update-fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store product-class update-success action.
 */
export class UpdateProductClassSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = ProductClassesActionType.UPDATE_PRODUCT_CLASS_SUCCESS;

  /**
   * Fires a new product-class update-success action.
   * @param payload The updated product-class.
   */
  constructor(public payload: AppHttpResponse<ProductClass>) {}
}

/**
 * Represents a store product-class delete action.
 */
export class DeleteProductClass implements Action {
  /**
   * The type of the action.
   */
  readonly type = ProductClassesActionType.DELETE_PRODUCT_CLASS;

  /**
   * Fires a new product-class delete action.
   * @param payload The product-class's id.
   */
  constructor(public payload: number) {}
}

/**
 * Represents a store product-class delete-fail action.
 */
export class DeleteProductClassFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = ProductClassesActionType.DELETE_PRODUCT_CLASS_FAIL;

  /**
   * Fires a new product-class delete-fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store product-class delete-success action.
 */
export class DeleteProductClassSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = ProductClassesActionType.DELETE_PRODUCT_CLASS_SUCCESS;

  /**
   * Fires a new product-class delete-success action.
   * @param payload The deleted product class.
   */
  constructor(public payload: AppHttpResponse<ProductClass>) {}
}

/**
 * Represents a store product class update photo action.
 */
export class UpdateProductClassPhoto implements Action {
  /**
   * The type of the action.
   */
  readonly type = ProductClassesActionType.UPDATE_PRODUCT_CLASS_PHOTO;

  /**
   * Fires a new product class update photo action.
   * @param payload The product class's id and the new photo.
   */
  constructor(public payload: { id: number; photo: File }) {}
}

/**
 * Represents a store product class delete photo action.
 */
export class DeleteProductClassPhoto implements Action {
  /**
   * The type of the action.
   */
  readonly type = ProductClassesActionType.DELETE_PRODUCT_CLASS_PHOTO;

  /**
   * Fires a new product class delete photo action.
   * @param payload The product class's id.
   */
  constructor(public payload: number) {}
}

/**
 * Stores-Module product classes action types.
 */
export type ProductClassesActions =
  | SearchProductClasses
  | SearchProductClassesFail
  | SearchProductClassesSuccess
  | SearchProductClassesForSalesScreen
  | SearchProductClassesForSalesScreenFail
  | SearchProductClassesForSalesScreenSuccess
  | SearchProductClassesWithoutPagination
  | FindProductClass
  | FindProductClassFail
  | FindProductClassSuccess
  | CreateProductClass
  | CreateProductClassFail
  | CreateProductClassSuccess
  | UpdateProductClass
  | UpdateProductClassFail
  | UpdateProductClassSuccess
  | DeleteProductClass
  | DeleteProductClassFail
  | DeleteProductClassSuccess
  | UpdateProductClassPhoto
  | DeleteProductClassPhoto;
