import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';

import { AppHttpResponse } from 'shared';
import { GovService } from 'hr/models';

/**
 * The gov services services includes functionality to create, search, findById, update and delete for an gov services.
 */
@Injectable()
export class GovServicesService {
  /**
   * The relative route for the gov services.
   *
   * No leading or trailing slashes required.
   */
  private govServicesApi = 'hr/gov-services';

  constructor(private http: HttpClient) {}

  /**
   * Searches in the services by name.
   * @param name The name of the gov service.
   * @param page The current pagination page number.
   * @param pageSize The maximum number of services allowed per one pagination page.
   */
  public searchServices(name: string, page: number, pageSize: number): Observable<AppHttpResponse<GovService[]>> {
    const params = new HttpParams().set('name', name).set('page', page.toString()).set('pageSize', pageSize.toString());
    return this.http.get<AppHttpResponse<GovService[]>>(`${this.govServicesApi}`, { params });
  }
}
