import { Location } from 'stores/models';
import { PaginationInfo } from 'shared/models';

/**
 * Represents the locations state.
 */
export class LocationState {
  /**
   * The list of locations.
   */
  public data: Location[];

  /**
   * The list of organization locations.
   */
  public organizationLocationsData: Location[];

  /**
   * The pagination info for the locations.
   */
  public paginationInfo: PaginationInfo;

  /**
   * The pagination info for the organization locations.
   */
  public organizationLocationsPaginationInfo: PaginationInfo;

  /**
   * An error that may resulting during processing some actions.
   */
  public error: any;

  /**
   * Determines if there is a running search process.
   */
  public isSearching: boolean;

  /**
   * Determines if the last search process has been ended successfully.
   */
  public isSearchCompleted: boolean;

  /**
   * Determines if there is a running organization locations search process.
   */
  public isOrganizationLocationsSearching: boolean;

  /**
   * Determines if the last organization locations search process has been ended successfully.
   */
  public isOrganizationLocationsSearchCompleted: boolean;

  /**
   * The current selected location.
   */
  public selectedLocation: Location;

  /**
   * Determines if there is a running find-location process.
   */
  public isFinding: boolean;

  /**
   * Determines if the last find-location process has been ended successfully.
   */
  public isFindCompleted: boolean;

  /**
   * Determines if there is a running create process.
   */
  public isCreating: boolean;

  /**
   * Determines if the last create process has been ended successfully.
   */
  public isCreateCompleted: boolean;

  /**
   * Determines if there is a running update process.
   */
  public isUpdating: boolean;

  /**
   * Determines if the last update process has been ended successfully.
   */
  public isUpdateCompleted: boolean;

  /**
   * Determines if there is a running delete process.
   */
  public isDeleting: boolean;

  /**
   * Determines if the last delete process has been ended successfully.
   */
  public isDeleteCompleted: boolean;
}
