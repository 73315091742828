import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import * as fromContainers from 'core/containers';
import * as fromComponents from 'core/components';
import * as fromAuthModule from 'auth';
import { environment } from '../../environments/environment';

const routes: Routes = [
  {
    path: 'login',
    component: fromContainers.ContentLayoutComponent,
    children: [
      {
        path: '',
        component: fromAuthModule.LoginComponent,
      },
    ],
  },
  {
    path: 'loading',
    component: fromComponents.SplashScreenComponent,
  },
  {
    path: 'not-found',
    component: fromContainers.ContentLayoutComponent,
    children: [
      {
        path: '',
        component: fromComponents.NotFoundComponent,
      },
    ],
  },
  {
    path: 'forbidden',
    component: fromContainers.ContentLayoutComponent,
    children: [
      {
        path: '',
        component: fromComponents.ForbiddenComponent,
      },
    ],
  },
  {
    path: '',
    canActivate: [fromAuthModule.AuthGuard],
    component: fromContainers.FullLayoutComponent,
    children: [
      {
        path: 'dashboard',
        component: fromComponents.DashboardComponent,
      },
      {
        path: 'security',
        loadChildren: () => import('security').then((m) => m.SecurityModule),
      },
      {
        path: 'assets',
        loadChildren: () => import('assets').then((m) => m.AssetsModule),
      },
      {
        path: 'stores',
        loadChildren: () => import('stores').then((m) => m.StoresModule),
      },
      {
        path: 'stocks',
        loadChildren: () => import('stocks').then((m) => m.StocksModule),
      },
      {
        path: 'organizations',
        loadChildren: () => import('organizations').then((m) => m.OrganizationsModule),
      },
      {
        path: 'settings',
        loadChildren: () => import('settings').then((m) => m.SettingsModule),
      },
      {
        path: 'crm',
        loadChildren: () => import('crm').then((m) => m.CRMModule),
      },
      {
        path: 'sales',
        loadChildren: () => import('sales').then((m) => m.SalesModule),
      },
      {
        path: 'purchases',
        loadChildren: () => import('purchases').then((m) => m.PurchasesModule),
      },
      {
        path: 'hr',
        loadChildren: () => import('hr').then((m) => m.HRModule),
      },
      {
        path: 'finances',
        loadChildren: () => import('finances').then((m) => m.FinancesModule),
      },
      {
        path: 'reports',
        loadChildren: () => import('reports').then((m) => m.ReportsModule),
      },
      {
        path: 'projects-management',
        loadChildren: () => import('projects-management').then((m) => m.ProjectsManagementModule),
      },
      ...(environment.allowDeveloperMode
        ? [
            {
              path: 'developers',
              loadChildren: () => import('developers').then((m) => m.DevelopersModule),
            },
          ]
        : []),
      {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full',
      },
    ],
  },
  {
    path: '**',
    redirectTo: 'not-found',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class CoreRoutingModule {}
