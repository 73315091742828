<!-- page header starts -->
<app-page-header [info]="pageInfo"></app-page-header>
<!-- page header ends -->

<!-- update currency exchange rate form starts -->
<div class="row">
  <div class="col-md-12">
    <form [formGroup]="form" (ngSubmit)="submit()" *ngIf="currencyExchangeRate$ | async; let currencyExchangeRate">
      <div class="card">
        <div class="card-header mb-3">
          <h4 class="card-title">
            {{ 'FINANCES.CURRENCY_EXCHANGE_RATE.CURRENCY_EXCHANGE_RATE_DATA_TITLE' | translate }}
          </h4>
        </div>
        <div class="card-content">
          <div class="px-3">
            <div class="row">
              <div class="form-group col-md-6">
                <label for="primaryCurrencyId">{{
                  'FINANCES.CURRENCY_EXCHANGE_RATE.CURRENCY_EXCHANGE_RATE_DATA.PRIMARY_CURRENCY' | translate
                }}</label>
                <ng-select
                  class="round"
                  formControlName="primaryCurrencyId"
                  labelForId="primaryCurrencyId"
                  searchable="true"
                  [placeholder]="
                    'FINANCES.CURRENCY_EXCHANGE_RATE.CURRENCY_EXCHANGE_RATE_DATA.PRIMARY_CURRENCY_PLACEHOLDER'
                      | translate
                  "
                  [notFoundText]="'SHARED.SEARCH.SEARCH_NOT_FOUND' | translate"
                  (open)="primaryCurrencyListVisibility = true"
                >
                  <ng-option *ngFor="let currency of primaryCurrencies" [value]="currency.id">
                    {{ currency.name | localize: currency.nameEn | async }}
                  </ng-option>
                </ng-select>
              </div>
              <div class="form-group col-md-6">
                <label for="secondaryCurrencyId">{{
                  'FINANCES.CURRENCY_EXCHANGE_RATE.CURRENCY_EXCHANGE_RATE_DATA.SECONDARY_CURRENCY' | translate
                }}</label>
                <ng-select
                  class="round"
                  formControlName="secondaryCurrencyId"
                  labelForId="secondaryCurrencyId"
                  searchable="true"
                  [placeholder]="
                    'FINANCES.CURRENCY_EXCHANGE_RATE.CURRENCY_EXCHANGE_RATE_DATA.SECONDARY_CURRENCY_PLACEHOLDER'
                      | translate
                  "
                  [notFoundText]="'SHARED.SEARCH.SEARCH_NOT_FOUND' | translate"
                  (open)="secondaryCurrencyListVisibility = true"
                >
                  <ng-option *ngFor="let currency of secondaryCurrencies" [value]="currency.id">
                    {{ currency.name | localize: currency.nameEn | async }}
                  </ng-option>
                </ng-select>
              </div>
              <div class="form-group col-md-4">
                <label for="rate">{{
                  'FINANCES.CURRENCY_EXCHANGE_RATE.CURRENCY_EXCHANGE_RATE_DATA.RATE' | translate
                }}</label>
                <input
                  type="text"
                  [mask]="DECIMAL_MASK"
                  thousandSeparator=","
                  minLength="0"
                  id="rate"
                  class="form-control round"
                  [placeholder]="
                    'FINANCES.CURRENCY_EXCHANGE_RATE.CURRENCY_EXCHANGE_RATE_DATA.RATE_PLACEHOLDER' | translate
                  "
                  formControlName="rate"
                />
              </div>
              <div class="col-md-8">
                <div class="row">
                  <div class="form-group col-12 col-sm-6 mb-0">
                    <label for="startDate">{{
                      'FINANCES.CURRENCY_EXCHANGE_RATE.CURRENCY_EXCHANGE_RATE_DATA.START_DATE' | translate
                    }}</label>
                    <div class="input-group round">
                      <input
                        class="form-control"
                        id="startDate"
                        name="startDate"
                        formControlName="startDate"
                        [placeholder]="'SHARED.SEARCH.DATE_PLACEHOLDER' | translate"
                        ngbDatepicker
                        #fromDate="ngbDatepicker"
                      />
                      <div class="input-group-append">
                        <span
                          class="input-group-text gradient-light-blue-indigo"
                          style="cursor: pointer;"
                          (click)="fromDate.toggle()"
                        >
                          <i class="fa fa-calendar"></i>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="form-group col-12 col-sm-6 mb-0">
                    <label for="endDate">{{
                      'FINANCES.CURRENCY_EXCHANGE_RATE.CURRENCY_EXCHANGE_RATE_DATA.END_DATE' | translate
                    }}</label>
                    <div class="input-group round">
                      <input
                        class="form-control"
                        id="endDate"
                        name="endDate"
                        formControlName="endDate"
                        [placeholder]="'SHARED.SEARCH.DATE_PLACEHOLDER' | translate"
                        ngbDatepicker
                        #toDate="ngbDatepicker"
                      />
                      <div class="input-group-append">
                        <span
                          class="input-group-text gradient-light-blue-indigo"
                          style="cursor: pointer;"
                          (click)="toDate.toggle()"
                        >
                          <i class="fa fa-calendar"></i>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="form-group col-12 d-flex justify-content-between flex-column flex-sm-row mt-2">
                <button type="submit" class="btn btn-raised btn-success round" [disabled]="isUpdating$ | async">
                  <i class="fa fa-check"></i>
                  <span class="mx-1">{{ 'SHARED.CONFIRM.SAVE' | translate }}</span>
                </button>
                <a class="btn btn-dark round" routerLink="/finances/currency-exchange-rates">
                  <i class="fa fa-undo"></i>
                  <span class="mx-1">{{
                    'FINANCES.CURRENCY_EXCHANGE_RATE.CURRENCY_EXCHANGE_RATE_DATA.CURRENCY_EXCHANGE_RATE_RETURN'
                      | translate
                  }}</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
<!-- update currency exchange rate form ends -->

<!-- confirm modal starts -->
<ng-template #confirmModalRef let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
      {{ 'FINANCES.CURRENCY_EXCHANGE_RATE.CONFIRM_UPDATE_CURRENCY_EXCHANGE_RATE_TITLE' | translate }}
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="d()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p class="text-success">
      {{ 'FINANCES.CURRENCY_EXCHANGE_RATE.CONFIRM_UPDATE_CURRENCY_EXCHANGE_RATE_MESSAGE' | translate }}
    </p>
  </div>
  <div class="modal-footer">
    <div class="modal-actions">
      <button type="button" class="btn btn-raised btn-success round" (click)="c(); confirm()" ngbAutofocus>
        <i class="fa fa-check"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.SAVE' | translate }}</span>
      </button>
      <button type="button" class="btn btn-outline-dark round" (click)="c()">
        <i class="fa fa-times"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.CANCEL' | translate }}</span>
      </button>
    </div>
  </div>
</ng-template>
<!-- confirm modal ends -->

<!-- primary currencies search starts -->
<app-currencies-search
  closeOnSelect="true"
  [(visible)]="primaryCurrencyListVisibility"
  (currencies)="selectPrimaryCurrency($event)"
>
</app-currencies-search>
<!-- primary currencies search ends -->

<!-- secondary currencies search starts -->
<app-currencies-search
  closeOnSelect="true"
  [(visible)]="secondaryCurrencyListVisibility"
  (currencies)="selectSecondaryCurrency($event)"
>
</app-currencies-search>
<!-- secondary currencies search ends -->
