import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms';

import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import * as fromStoresStore from 'stores/store';
import { PageInfo, NotificationService, NotificationMessage, TranslationService, CustomValidators } from 'shared';
import { ProductClass, Location, LocationFormItem, ProductClassLocation } from 'stores/models';

@Component({
  selector: 'app-update-product-class',
  templateUrl: './update-product-class.component.html',
  styles: [],
})
export class UpdateProductClassComponent implements OnInit, OnDestroy {
  /**
   * The confirm modal template reference.
   */
  @ViewChild('confirmModalRef') confirmModalRef: ElementRef<any>;

  /**
   * The confirm delete photo modal template reference.
   */
  @ViewChild('confirmDeletePhotoModalRef') confirmDeletePhotoModalRef: ElementRef<any>;

  /**
   * The confirm update photo modal template reference.
   */
  @ViewChild('confirmUpdatePhotoModalRef') confirmUpdatePhotoModalRef: ElementRef<any>;

  /**
   * Gets or sets the information about the current page.
   */
  pageInfo: PageInfo = {
    title: 'STORES.PRODUCT_CLASSES.CONFIRM_UPDATE_PRODUCT_CLASS_TITLE',
    icon: 'fa fa-edit',
  };

  /**
   * Gets or sets the id of the current edited product class.
   */
  productClassId: number;

  /**
   * The list of active locations.
   */
  locations: Location[] = [];

  /**
   * The current edited product class.
   */
  productClass$: Observable<ProductClass>;

  /**
   * The name of the current product class.
   */
  productClassName: string;

  /**
   * Indicates whether there is a update-location process is running or not.
   */
  isUpdating$: Observable<boolean>;

  /**
   * The create location form.
   */
  form: FormGroup;

  /**
   * The update product class photo form.
   */
  updatePhotoForm: FormGroup;

  /**
   * The set of subscriptions on this components,
   * these subscriptions must be unsubscribed before this component got destroyed.
   */
  subscriptions = new Subscription();

  /**
   * Gets or sets the current selected language.
   */
  get currentLang() {
    return this.translationService.language;
  }

  /**
   * Gets the locations form-array.
   */
  get locationsForm(): FormArray {
    return this.form?.controls.locations as FormArray;
  }

  /**
   * Sets the initial locations should be added to the locations form.
   * @param locations An object contains `count` of initial blank locations.
   * @param locations A single service details to be added to the form.
   * @param locations A list of locations details to be added to the form.
   */
  initialLocationsSubject: BehaviorSubject<
    { count: number } | LocationFormItem | LocationFormItem[]
  > = new BehaviorSubject(undefined);

  /**
   * Used to add new location form items to the locations form.
   */
  newLocations: LocationFormItem[];

  /**
   * @param route The activate rout service.
   * @param modalService The modal service.
   * @param notificationService The notification service.
   * @param translationService The translation service.
   * @param storesStore$ The stores-store module.
   */
  constructor(
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private notificationService: NotificationService,
    private translationService: TranslationService,
    private storesStore$: Store<fromStoresStore.StoresState>
  ) {}

  ngOnInit(): void {
    this.init();
  }

  /**
   * Destroy component data
   */
  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  /**
   * Initialize component data.
   */
  init() {
    this.initForm();

    /** Subscribe to route to get the product class id whenever it changed. */
    const routeSubscription = this.route.paramMap
      .pipe(
        tap((params) => {
          this.productClassId = +params.get('productClassId');
          this.storesStore$.dispatch(new fromStoresStore.FindProductClass(this.productClassId));
        })
      )
      .subscribe();

    this.subscriptions.add(routeSubscription);

    /* Load the selected product class. */
    this.productClass$ = this.storesStore$.pipe(
      select(fromStoresStore.getSelectedProductClass),
      tap((productClass) => {
        if (productClass) {
          this.form.patchValue({ ...productClass });

          this.locationsForm.clear();

          this.newLocations = productClass.productClassLocations.map((location: ProductClassLocation) => ({
            locationId: location.locationId,
            location: location.location,
          }));

          this.productClassName = this.currentLang === 'en' ? productClass.nameEn : productClass.name;

          /* Update page info. */
          this.pageInfo = {
            ...this.pageInfo,
            subTitle: `(${this.productClassName})`,
          };

          this.form.markAsPristine();
        }
      })
    );

    /**
     * Set data.
     */
    this.isUpdating$ = this.storesStore$.pipe(select(fromStoresStore.getSelectedProductClassUpdating));
  }

  /**
   * Initialize form and add validators.
   */
  initForm() {
    this.form = new FormGroup({
      name: new FormControl('', [Validators.required, Validators.minLength(1), Validators.maxLength(50)]),
      nameEn: new FormControl('', [Validators.required, Validators.minLength(1), Validators.maxLength(50)]),
      locations: new FormArray([], CustomValidators.arrayItems(1)),
      showProductClassInSalesScreen: new FormControl(false),
    });
    this.updatePhotoForm = new FormGroup({
      photo: new FormControl(null),
    });
  }

  /**
   * Submits the form.
   */
  submit() {
    if (this.form.invalid) {
      const errorMessage = new NotificationMessage();

      if (this.form.get('name').invalid) {
        (errorMessage.title = this.translationService.translate(
          'STORES.PRODUCT_CLASSES.PRODUCT_CLASS_DATA_VALIDATION.ARABIC_PRODUCT_CLASS_NAME_ERROR'
        )),
          (errorMessage.body = [
            this.translationService.translate(
              'STORES.PRODUCT_CLASSES.PRODUCT_CLASS_DATA_VALIDATION.PRODUCT_CLASS_NAME_IS_REQUIRED'
            ),
            this.translationService.translate(
              'STORES.PRODUCT_CLASSES.PRODUCT_CLASS_DATA_VALIDATION.PRODUCT_CLASS_NAME_LENGTH_ERROR'
            ),
          ]);
      } else if (this.form.get('nameEn').invalid) {
        (errorMessage.title = this.translationService.translate(
          'STORES.PRODUCT_CLASSES.PRODUCT_CLASS_DATA_VALIDATION.ENGLISH_PRODUCT_CLASS_NAME_ERROR'
        )),
          (errorMessage.body = [
            this.translationService.translate(
              'STORES.PRODUCT_CLASSES.PRODUCT_CLASS_DATA_VALIDATION.PRODUCT_CLASS_NAME_IS_REQUIRED'
            ),
            this.translationService.translate(
              'STORES.PRODUCT_CLASSES.PRODUCT_CLASS_DATA_VALIDATION.PRODUCT_CLASS_NAME_LENGTH_ERROR'
            ),
          ]);
      } else if (this.form.get('locations').invalid) {
        /**
         * Check if lines count = 0.
         */
        if (!this.locationsForm.controls.length) {
          errorMessage.title = this.translationService.translate(
            'STORES.PRODUCT_CLASSES.PRODUCT_CLASS_DATA_VALIDATION.LOCATIONS_ERROR'
          );
          errorMessage.body = [
            this.translationService.translate(
              'STORES.PRODUCT_CLASSES.PRODUCT_CLASS_DATA_VALIDATION.LOCATIONS_LENGTH_ERROR'
            ),
          ];
        } else {
          /**
           * Check if some of locations has errors.
           */
          for (let index = 0; index < this.locationsForm.controls.length; index++) {
            const location = this.locationsForm.controls[index];

            if (location.valid) {
              continue;
            }

            errorMessage.title = this.translationService.translate(
              'STORES.PRODUCT_CLASSES.PRODUCT_CLASS_DATA_VALIDATION.LOCATIONS_NUMBER_ERROR',
              {
                locationNumber: index + 1,
              }
            );
            errorMessage.body = [];

            if (location.get('locationId').invalid) {
              errorMessage.body.push(
                this.translationService.translate(
                  'STORES.PRODUCT_CLASSES.PRODUCT_CLASS_DATA_VALIDATION.LOCATIONS_IS_REQUIRED'
                )
              );
            }

            break;
          }
        }
      }

      this.form.markAllAsTouched();
      return this.notificationService.warningWithTitle(errorMessage);
    } else {
      this.openModal(this.confirmModalRef);
    }
  }

  /**
   * Confirms the form submit.
   */
  confirm() {
    /**
     * The list of locations in the locations form.
     */

    const locations = this.locationsForm.value.map((item: LocationFormItem) => item.locationId);

    this.storesStore$.dispatch(
      new fromStoresStore.UpdateProductClass({
        id: this.productClassId,
        ...this.form.value,
        locations: locations,
        photoFilename: this.form.value.photoFilename ?? null,
      })
    );
  }

  /**
   * Opens the modal of the given templateRef.
   * @param modalRef The modal templateRef to be opened.
   */
  openModal(modalRef) {
    this.modalService.open(modalRef);
  }

  /**
   * Deletes the current product class photo.
   */
  deletePhoto() {
    this.openModal(this.confirmDeletePhotoModalRef);
  }

  /**
   * Updates the current product class photo.
   */
  updatePhoto() {
    this.openModal(this.confirmUpdatePhotoModalRef);
  }

  /**
   * Confirms & deletes the current product class photo.
   */
  deletePhotoConfirm() {
    this.storesStore$.dispatch(new fromStoresStore.DeleteProductClassPhoto(this.productClassId));
  }

  /**
   * Confirms updates the current product class photo.
   */
  updatePhotoConfirm() {
    this.storesStore$.dispatch(
      new fromStoresStore.UpdateProductClassPhoto({ id: this.productClassId, photo: this.updatePhotoForm.value.photo })
    );
    this.updatePhotoForm.reset();
  }
}
