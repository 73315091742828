import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { FormArray, FormGroup, FormControl } from '@angular/forms';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { PaidPayrollFormItem } from 'finances/models';
import { Payroll } from 'hr/models';

@Component({
  selector: 'app-paid-payrolls-form',
  templateUrl: './paid-payrolls-form.component.html',
  styles: [],
})
export class PaidPayrollsFormComponent {
  /**
   * The confirm modal template reference.
   */
  @ViewChild('deleteModalRef') deleteModalRef: ElementRef<any>;

  /**
   * Sets the paid payrolls form-array.
   */
  @Input() formArray: FormArray;

  /**
   * Sets the name form-array in its parent form.
   * @default 'payrolls'
   */
  @Input() formArrayName = 'payrolls';

  /**
   * Adds a list of new paid payroll form items to the paid payrolls form.
   * @param items The list of paid payroll form items to be added to the paid payrolls form.
   */
  @Input() set newPaidPayrolls(items: PaidPayrollFormItem[]) {
    if (!items?.length) {
      return;
    }

    items.forEach((item) => this.createPaidPayrollFormGroup(item));
  }

  /**
   * Sets a value indicates whether if the user should specify a paid payroll or not.
   * @default false
   */
  @Input() isPaidPayrollRequired = false;

  /**
   * Shows or hides the paid payrolls list.
   */
  paidPayrollsListVisibility = false;

  /**
   * Indicates whether if we can add paid payroll or not.
   */
  @Input() allowAddPaidPayroll = true;

  /**
   * Indicates whether if we can clear paid payrolls or not.
   * @default `true`.
   */
  @Input() allowClearPaidPayrolls = true;

  /**
   * @param modalService The modal service.
   */
  constructor(private modalService: NgbModal) {}

  /**
   * Creates & adds a new paid payroll  form-group with validations.
   * @param paidPayroll The paid payroll details to be bounded to the paid payroll, If omitted a blank paid payroll will be created.
   */
  addPaidPayroll() {
    this.paidPayrollsListVisibility = true;
  }

  /**
   * Open confirm delete all paid payrolls modal .
   */
  openDeleteModal() {
    this.openModal(this.deleteModalRef);
  }

  /**
   * Opens the modal of the given templateRef.
   * @param modalRef The modal templateRef to be opened.
   */
  openModal(modalRef) {
    this.modalService.open(modalRef);
  }

  /**
   * Remove the paid payrolls at the given index from the paid payrolls form.
   * @param index The index of the paid payrolls form group.
   */
  removePaidPayroll(index: number) {
    this.formArray.removeAt(index);
  }

  /**
   * Remove all of the list of paid payrolls.
   *
   * It only adds one paid payrolls for quick start.
   */
  resetPaidPayrolls() {
    this.formArray.clear();
  }

  /**
   * Handles the payrolls select event.
   * @param payrolls The newly selected payrolls.
   */
  selectPaidPayroll(payrolls: Payroll[]) {
    if (payrolls) {
      payrolls.map((payroll) =>
        this.createPaidPayrollFormGroup(this.createPaidPayrollFormItemFromPaidPayroll(payroll))
      );
    }
  }

  /**
   * Creates and returns a new paid payroll form item from a given paid payroll.
   * @param payroll The paid payroll to be used to create the paid payroll form item.
   */
  createPaidPayrollFormItemFromPaidPayroll(payroll: Payroll): PaidPayrollFormItem {
    return {
      paidPayrollId: payroll?.id,
      paidPayroll: payroll,
    };
  }

  /**
   * Create a new paid payroll form group from the provided paid payroll form item.
   * @param paidPayroll The paid payroll form item that contains data about the new paid payroll.
   */
  createPaidPayrollFormGroup(paidPayroll: PaidPayrollFormItem) {
    if (this.isPaidPayrollExists(paidPayroll.paidPayroll?.id)) {
      return;
    }

    const formGroup = new FormGroup({
      paidPayrollId: new FormControl(paidPayroll?.paidPayrollId ?? null),
      paidPayroll: new FormControl(paidPayroll?.paidPayroll),
    });
    this.formArray.push(formGroup);
  }

  /**
   * Indicates whether if the given paid payroll id is already exists in the form array or not.
   * @param paidPayrollId The id of the paid payroll.
   * @returns `boolean`.
   */
  isPaidPayrollExists(paidPayrollId: number): boolean {
    return (this.formArray.value as PaidPayrollFormItem[]).some(
      (paidPayrollItem) => paidPayrollItem.paidPayroll?.id === paidPayrollId
    );
  }
}
