import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';

import { AppHttpResponse } from 'shared';
import { SaleInvoice } from 'sales/models';

/**
 * The orders delivery services includes functionality to prepare and delivery a orders.
 */
@Injectable()
export class OrdersDeliveryService {
  /**
   * The relative route for the orders delivery.
   * No leading or trailing slashes required.
   */
  private ordersDeliveryApi = 'sales/invoices';

  constructor(private http: HttpClient) {}

  /**
   * Searching in the orders delivery.
   * @param locations The locations of the sales invoice.
   */
  public searchOrdersDelivery(locations: number[]): Observable<AppHttpResponse<SaleInvoice[]>> {
    const params = new HttpParams().set('locations', locations.join(','));
    return this.http.get<AppHttpResponse<SaleInvoice[]>>(`${this.ordersDeliveryApi}/pending-pos-invoices`, {
      params,
    });
  }

  /**
   * Delivery the orders with the given id.
   * @param id The id of the order.
   */
  public deliveryOrders(id: number): Observable<AppHttpResponse<SaleInvoice>> {
    return this.http.put<AppHttpResponse<SaleInvoice>>(`${this.ordersDeliveryApi}/update-pos-order-stage/${id}`, null);
  }
}
