<!-- page header starts -->
<app-page-header [info]="pageInfo"></app-page-header>
<!-- page header ends -->

<!-- customers list starts -->
<div class="row">
  <div class="col-md-12">
    <div class="card">
      <div class="card-header mb-3">
        <h4 class="card-title">{{ 'SHARED.SEARCH.SEARCH_TOOLS' | translate }}</h4>
      </div>
      <div class="card-content">
        <div class="px-3">
          <div class="row">
            <div class="col-md-12">
              <form class="row" [formGroup]="searchForm">
                <div class="form-group col">
                  <input
                    type="text"
                    class="form-control round"
                    [placeholder]="'SALES.CUSTOMERS.CUSTOMER_DATA.CUSTOMER_NAME_SEARCH_PLACEHOLDER' | translate"
                    formControlName="name"
                    (input)="search($event)"
                  />
                </div>
                <div class="col-md-auto form-group d-flex">
                  <button
                    type="button"
                    class="btn btn-raised btn-primary align-self-end round mx-1 mb-0"
                    (click)="initForm(); search()"
                  >
                    <i class="fa fa-refresh"></i>
                  </button>
                  <a
                    *ngIf="[Claims.CREATE_CUSTOMER] | authorize | async"
                    routerLink="/sales/customers/create"
                    class="btn btn-raised btn-success align-self-end round mx-1 mb-0"
                  >
                    <i class="fa fa-plus"></i>
                    <span class="mx-1">{{ 'SHARED.CONFIRM.ADD' | translate }}</span>
                  </a>
                  <app-export-tools
                    *ngIf="(customers$ | async)?.length"
                    class="d-flex align-self-end mx-1"
                    targetElementId="customers-table-export-target-element"
                    [outputFileName]="'SHARED.EXPORT_TABLES.CUSTOMERS_TABLE' | translate"
                  ></app-export-tools>
                </div>
              </form>
            </div>
            <div class="col-md-12" *ngIf="(customers$ | async)?.length; else noItems">
              <div class="row">
                <div class="col-md-12 alert alert-light text-center" role="alert">
                  {{ 'SHARED.SEARCH.SEARCH_RESULTS' | translate }} ({{ (paginationInfo$ | async).total | toNumber }})
                  {{ 'SALES.CUSTOMERS.CUSTOMER_DATA.CLIENT' | translate }}
                </div>
                <div class="col-md-12">
                  <div class="table-responsive">
                    <table class="table text-center" id="customers-table-export-target-element">
                      <thead>
                        <tr>
                          <th scope="col">
                            <i class="fa fa-barcode"></i
                            ><span class="mx-1">{{ 'SALES.CUSTOMERS.CUSTOMER_DATA.CUSTOMER_CODE' | translate }}</span>
                          </th>
                          <th scope="col">{{ 'SALES.CUSTOMERS.CUSTOMER_DATA.CUSTOMER' | translate }}</th>
                          <th scope="col">{{ 'SALES.CUSTOMERS.CUSTOMER_DATA.CUSTOMER_TYPE' | translate }}</th>
                          <th scope="col">{{ 'SALES.CUSTOMERS.CUSTOMER_DATA.CUSTOMER_POINTS' | translate }}</th>
                          <th scope="col">{{ 'SALES.CUSTOMERS.CUSTOMER_DATA.RESPONSIBLE' | translate }}</th>
                          <th scope="col"><i class="fa fa-phone"></i></th>
                          <th scope="col">{{ 'SALES.CUSTOMERS.CUSTOMER_DATA.STATUS' | translate }}</th>
                          <th scope="col">{{ 'SHARED.TOOLS' | translate }}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let customer of customers$ | async; trackBy: trackItems">
                          <th scope="row">{{ customer.code }}</th>
                          <td>{{ customer.name | localize: customer.nameEn | async }}</td>
                          <td>
                            {{ customer.customerCategory.name | localize: customer.customerCategory.nameEn | async }}
                          </td>
                          <td>
                            {{ customer.points | toNumber }}
                          </td>
                          <td>{{ customer.representorName }}</td>
                          <td style="white-space: pre-line; direction: initial;">{{ extractContacts(customer) }}</td>
                          <td>
                            <app-status
                              [status]="customer.isActive"
                              [activeText]="'SALES.CUSTOMERS.CUSTOMER_DATA.EFFECTIVE' | translate"
                              [notActiveText]="'SALES.CUSTOMERS.CUSTOMER_DATA.USER_NOT_ACTIVE' | translate"
                            >
                            </app-status>
                          </td>
                          <td>
                            <a
                              *ngIf="
                                [
                                  Claims.UPDATE_CUSTOMER,
                                  Claims.CREATE_CUSTOMER_ENGAGEMENT,
                                  Claims.UPDATE_CUSTOMER_ENGAGEMENT,
                                  Claims.DELETE_CUSTOMER_ENGAGEMENT,
                                  Claims.CREATE_CUSTOMER_PAYMENT
                                ]
                                  | authorize
                                  | async
                              "
                              class="info p-0"
                              [routerLink]="['/sales/customers/files', customer.id]"
                              [ngbTooltip]="'SALES.CUSTOMERS.CUSTOMER_DATA.CUSTOMER_FILE' | translate"
                            >
                              <i class="fa fa-folder font-medium-3 mr-2"></i>
                            </a>
                            <a
                              *ngIf="([Claims.BLOCK_CUSTOMER] | authorize | async) && customer.isActive"
                              class="warning p-0"
                              (click)="selectedCustomer = customer; openModal(blockModalRef)"
                              [ngbTooltip]="'SALES.CUSTOMERS.CUSTOMER_DATA.NOT_ACTIVATE' | translate"
                            >
                              <i class="fa fa-power-off font-medium-3 mr-2"></i>
                            </a>
                            <a
                              *ngIf="([Claims.ACTIVATE_CUSTOMER] | authorize | async) && !customer.isActive"
                              class="success p-0"
                              (click)="selectedCustomer = customer; openModal(activateModalRef)"
                              [ngbTooltip]="'SALES.CUSTOMERS.CUSTOMER_DATA.ACTIVATE' | translate"
                            >
                              <i class="fa fa-check font-medium-3 mr-2"></i>
                            </a>
                            <a
                              *ngIf="[Claims.DELETE_CUSTOMER] | authorize | async"
                              class="danger p-0"
                              [ngbTooltip]="'SALES.CUSTOMERS.CUSTOMER_DATA.DELETE' | translate"
                              (click)="selectedCustomer = customer; openModal(deleteModalRef)"
                            >
                              <i class="fa fa-close font-medium-3 mr-2"></i>
                            </a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div class="d-flex justify-content-between p-2">
                      <ngb-pagination
                        *ngIf="paginationInfo$ | async; let pagination"
                        maxSize="5"
                        [collectionSize]="pagination.total"
                        [pageSize]="pagination.pageSize"
                        [page]="pagination.page"
                        (pageChange)="pageChanged($event)"
                        boundaryLinks="true"
                      >
                        <ng-template ngbPaginationFirst>{{
                          'SHARED.PAGINATION.PAGINATION_FIRST' | translate
                        }}</ng-template>
                        <ng-template ngbPaginationPrevious>{{
                          'SHARED.PAGINATION.PAGINATION_PREVIOUS' | translate
                        }}</ng-template>
                        <ng-template ngbPaginationNext>{{
                          'SHARED.PAGINATION.PAGINATION_NEXT' | translate
                        }}</ng-template>
                        <ng-template ngbPaginationLast>{{
                          'SHARED.PAGINATION.PAGINATION_LAST' | translate
                        }}</ng-template>
                      </ngb-pagination>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- customers list ends -->

<!-- no items template starts -->
<ng-template #noItems>
  <div class="col-md-12 alert alert-warning mt-3 text-center" role="alert">
    {{ 'SALES.CUSTOMERS.SEARCH_CUSTOMERS_NOT_FOUND' | translate }}
    <a
      *ngIf="[Claims.CREATE_CUSTOMER] | authorize | async"
      routerLink="/sales/customers/create"
      class="btn btn-raised btn-success round"
    >
      <i class="fa fa-plus"></i>
      <span class="mx-1">{{ 'SHARED.CONFIRM.ADD' | translate }}</span>
    </a>
  </div>
</ng-template>
<!-- no items template ends -->

<!-- block customer modal starts -->
<ng-template #blockModalRef let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title text-warning" id="modal-basic-title">
      {{ 'SALES.CUSTOMERS.CONFIRM_BLOCK_CUSTOMER_TITLE' | translate }}
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="d()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-12">
        <p class="text-warning">{{ 'SALES.CUSTOMERS.CONFIRM_BLOCK_CUSTOMER_MESSAGE' | translate }}</p>
      </div>
      <div class="form-group col-12">
        <label for="selectedCustomer.name">{{ 'SALES.CUSTOMERS.CUSTOMER_DATA.CUSTOMER_NAME' | translate }}</label>
        <input
          type="text"
          id="selectedCustomer.name"
          class="form-control round"
          [placeholder]="'SALES.CUSTOMERS.CUSTOMER_DATA.CUSTOMER_NAME_PLACEHOLDER' | translate"
          readonly
          [value]="selectedCustomer.name | localize: selectedCustomer.nameEn | async"
        />
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="modal-actions">
      <button
        type="button"
        class="btn btn-raised btn-warning round"
        (click)="c(); block(selectedCustomer.id)"
        ngbAutofocus
      >
        <i class="fa fa-check"></i>
        <span class="mx-1">{{ 'SALES.CUSTOMERS.CUSTOMER_DATA.NOT_ACTIVATE' | translate }}</span>
      </button>
      <button type="button" class="btn btn-outline-dark round" (click)="c()">
        <i class="fa fa-times"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.CANCEL' | translate }}</span>
      </button>
    </div>
  </div>
</ng-template>
<!-- block customer modal ends -->

<!-- activate customer modal starts -->
<ng-template #activateModalRef let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
      {{ 'SALES.CUSTOMERS.CONFIRM_ACTIVATE_CUSTOMER_TITLE' | translate }}
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="d()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-12">
        <p class="text-success">{{ 'SALES.CUSTOMERS.CONFIRM_ACTIVATE_CUSTOMER_MESSAGE' | translate }}</p>
      </div>
      <div class="form-group col-12">
        <label for="selectedCustomer.name">{{ 'SALES.CUSTOMERS.CUSTOMER_DATA.CUSTOMER_NAME' | translate }}</label>
        <input
          type="text"
          id="selectedCustomer.name"
          class="form-control round"
          [placeholder]="'SALES.CUSTOMERS.CUSTOMER_DATA.CUSTOMER_NAME_PLACEHOLDER' | translate"
          readonly
          [value]="selectedCustomer.name | localize: selectedCustomer.nameEn | async"
        />
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="modal-actions">
      <button
        type="button"
        class="btn btn-raised btn-success round"
        (click)="c(); activate(selectedCustomer.id)"
        ngbAutofocus
      >
        <i class="fa fa-check"></i>
        <span class="mx-1">{{ 'SALES.CUSTOMERS.CUSTOMER_DATA.ACTIVATE' | translate }}</span>
      </button>
      <button type="button" class="btn btn-outline-dark round" (click)="c()">
        <i class="fa fa-times"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.CANCEL' | translate }}</span>
      </button>
    </div>
  </div>
</ng-template>
<!-- activate customer modal ends -->

<!-- delete customer modal starts -->
<ng-template #deleteModalRef let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title text-danger" id="modal-basic-title">
      {{ 'SALES.CUSTOMERS.CONFIRM_DELETE_CUSTOMER_TITLE' | translate }}
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="d()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-12">
        <p class="text-danger">{{ 'SALES.CUSTOMERS.CONFIRM_DELETE_CUSTOMER_MESSAGE' | translate }}</p>
      </div>
      <div class="form-group col-12">
        <label for="selectedCustomer.name">{{ 'SALES.CUSTOMERS.CUSTOMER_DATA.CUSTOMER_NAME' | translate }}</label>
        <input
          type="text"
          id="selectedCustomer.name"
          class="form-control round"
          [placeholder]="'SALES.CUSTOMERS.CUSTOMER_DATA.CUSTOMER_NAME_PLACEHOLDER' | translate"
          readonly
          [value]="selectedCustomer.name | localize: selectedCustomer.nameEn | async"
        />
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="modal-actions">
      <button
        type="button"
        class="btn btn-raised btn-danger round"
        (click)="c(); delete(selectedCustomer.id)"
        ngbAutofocus
      >
        <i class="fa fa-check"></i>
        <span class="mx-1">{{ 'SALES.CUSTOMERS.CUSTOMER_DATA.DELETE' | translate }}</span>
      </button>
      <button type="button" class="btn btn-outline-dark round" (click)="c()">
        <i class="fa fa-times"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.CANCEL' | translate }}</span>
      </button>
    </div>
  </div>
</ng-template>
<!-- delete customer modal ends -->
