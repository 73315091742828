import { Action } from '@ngrx/store';

import { Report } from 'reports/models';
import { AppHttpResponse } from 'shared';

/**
 * The Reports-Module user reports action types.
 */
export enum UserReportsActionType {
  GET_MY_REPORTS = '[Reports] [User Reports] Get My Reports',
  GET_MY_REPORTS_FAIL = '[Reports] [User Reports] Get My Reports Fail',
  GET_MY_REPORTS_SUCCESS = '[Reports] [User Reports] Get My Reports Success',

  DELETE_USER_REPORT = '[Reports] [User Reports] Delete User Report',
  DELETE_USER_REPORT_FAIL = '[Reports] [User Reports] Delete User Report Fail',
  DELETE_USER_REPORT_SUCCESS = '[Reports] [User Reports] Delete User Report Success',
}

/**
 * Represents a get user reports action.
 */
export class GetMyReports implements Action {
  /**
   * The type of the action.
   */
  readonly type = UserReportsActionType.GET_MY_REPORTS;

  /**
   * Fires a new get user reports action.
   */
  constructor() {}
}

/**
 * Represents a get user reports fail action.
 */
export class GetMyReportsFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = UserReportsActionType.GET_MY_REPORTS_FAIL;

  /**
   * Fires a new get user reports fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a get user reports success action.
 */
export class GetMyReportsSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = UserReportsActionType.GET_MY_REPORTS_SUCCESS;

  /**
   * Fires a get user reports success action.
   * @param payload The loaded reports.
   */
  constructor(public payload: AppHttpResponse<Report[]>) {}
}

/**
 * Represents a delete user report action.
 */
export class DeleteUserReport implements Action {
  /**
   * The type of the action.
   */
  readonly type = UserReportsActionType.DELETE_USER_REPORT;

  /**
   * Fires a new delete user report action.
   */
  constructor(public payload: string) {}
}

/**
 * Represents a delete user report fail action.
 */
export class DeleteUserReportFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = UserReportsActionType.DELETE_USER_REPORT_FAIL;

  /**
   * Fires a new delete user report fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a delete user report success action.
 */
export class DeleteUserReportSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = UserReportsActionType.DELETE_USER_REPORT_SUCCESS;

  /**
   * Fires a delete user report success action.
   * @param payload The loaded reports.
   */
  constructor(public payload: AppHttpResponse<Report>) {}
}

/**
 * Reports-Module user reports action types.
 */
export type UserReportsActions =
  | GetMyReports
  | GetMyReportsFail
  | GetMyReportsSuccess
  | DeleteUserReport
  | DeleteUserReportFail
  | DeleteUserReportSuccess;
