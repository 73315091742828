import { Component, OnInit, ViewChild, ElementRef, OnDestroy, Input } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { flatMap } from 'lodash';

import * as fromSecurityStore from 'security/store';
import * as fromAuthStore from 'auth/store';
import { NotificationService, NotificationMessage, TranslationService, APP_CONSTANTS } from 'shared';
import { Organization } from 'organizations/models';
import { ClaimGroup, User, UserClaimFormItem, UserClaimGroupFormItem } from 'security/models';

@Component({
  selector: 'app-update-user',
  templateUrl: './update-user.component.html',
  styles: [],
})
export class UpdateUserComponent implements OnInit, OnDestroy {
  /**
   * The confirm modal template reference.
   */
  @ViewChild('confirmModalRef') confirmModalRef: ElementRef<any>;

  /**
   * Gets or sets the id of the current edited user.
   */
  @Input() userId: number;

  /**
   * The current edited user.
   */
  user$: Observable<User>;

  /**
   * The current logged in user.
   */
  currentLoggedInUser: User;

  /**
   * The list of active organizations.
   */
  organizations: Organization[] = [];

  /**
   * The list of all claim groups.
   */
  claimGroups: ClaimGroup[] = [];

  /**
   * The list of user allowed claim ids.
   */
  userClaimIds: number[] = [];

  /**
   * Indicates whether there is a update-user process is running or not.
   */
  isUpdating$: Observable<boolean>;

  /**
   * The create user form.
   */
  form: FormGroup;

  /**
   * The claim groups model.
   * Uses `[(ngModel)]` binding.
   */
  claimGroupsModel: UserClaimGroupFormItem[] = [];

  /**
   * Add mask to phone and mobile number.
   */
  public phonePattern = APP_CONSTANTS.phone.pattern;
  public phoneMask = APP_CONSTANTS.phone.mask;

  /**
   * Shows or hide the organizations list.
   */
  organizationsListVisibility = false;

  /**
   * The set of subscriptions on this components,
   * these subscriptions must be unsubscribed before this component got destroyed.
   */
  subscriptions = new Subscription();

  /**
   * Indicates wether the selected user is a service provider or not.
   */
  get isServiceProvider(): boolean {
    return this.form.value.userType === 'SERVICE_PROVIDER';
  }

  /**
   * @param modalService The modal service.
   * @param notificationService The notification service.
   * @param translationService The translation service.
   * @param securityStore$ The Security-module store.
   */
  constructor(
    private modalService: NgbModal,
    private notificationService: NotificationService,
    private translationService: TranslationService,
    private securityStore$: Store<fromSecurityStore.SecurityState>,
    private authStore$: Store<fromAuthStore.AuthState>
  ) {}

  ngOnInit(): void {
    this.init();
  }

  /**
   * Destroy component data
   */
  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  /**
   * Initialize component data.
   */
  init() {
    this.initForm();

    /* Load the selected user. */
    this.user$ = this.securityStore$.pipe(
      select(fromSecurityStore.getSelectedUser),
      tap((user) => {
        if (user) {
          this.form.patchValue({
            userType: user.organizationId ? 'REGULAR_USER' : 'SERVICE_PROVIDER',
            organizationId: user.organizationId,
          });

          this.userClaimIds = user.userClaims?.map((claim) => claim.claimId) ?? [];

          this.setValidations();
          this.form.markAsPristine();

          if (user.organization) {
            this.organizations = [user.organization];
          } else {
            this.organizations = [];
          }
        }
      })
    );

    /** Load current logged in user data. */
    this.subscriptions.add(
      this.authStore$
        .pipe(
          select(fromAuthStore.getSessionUser),
          tap((user: User) => {
            if (user) {
              this.currentLoggedInUser = user;
            }
          })
        )
        .subscribe()
    );

    /**
     * Load data.
     */
    this.securityStore$.dispatch(new fromSecurityStore.GetClaimsGroups());

    this.subscriptions.add(
      this.securityStore$
        .select(fromSecurityStore.getClaimGroups)
        .pipe(
          tap((groups) => {
            if (groups) {
              this.claimGroups = groups;
              this.updateClaimGroups();
            }
          })
        )
        .subscribe()
    );

    /**
     * Set data.
     */
    this.isUpdating$ = this.securityStore$.pipe(select(fromSecurityStore.getSelectedUserUpdating));
  }

  /**
   * Initialize form and add validators.
   */
  initForm() {
    const userTypeFormControl = new FormControl('SERVICE_PROVIDER', Validators.required);

    this.form = new FormGroup({
      userType: userTypeFormControl,
      organizationId: new FormControl(null),
    });

    /**
     * Dynamically set form validations based on the selected user type.
     */
    this.subscriptions.add(userTypeFormControl.valueChanges.subscribe(() => this.setValidations()));

    /**
     * Initialize validations.
     */
    this.setValidations();
  }

  /**
   * Sets the conditional validation for the form based on the selected user type.
   */
  setValidations() {
    const userType: string = this.form.controls.userType.value || 'SERVICE_PROVIDER';

    /**
     * Check if the user type is service provider.
     */
    if (userType === 'SERVICE_PROVIDER') {
      this.form.controls.organizationId.disable();
      this.form.controls.organizationId.clearValidators();
      this.form.controls.organizationId.setValue(null);
    } else {
      this.form.controls.organizationId.enable();
      this.form.controls.organizationId.setValidators(Validators.required);
    }

    this.form.controls.organizationId.updateValueAndValidity();
    this.updateClaimGroups();
  }

  /**
   * Updates the list of claim groups based on the selected user type.
   */
  updateClaimGroups() {
    this.claimGroupsModel = this.claimGroups
      .filter((group) => (this.isServiceProvider ? group.isSystemClaimGroup : !group.isSystemClaimGroup))
      .map((group) => {
        const claims: UserClaimFormItem[] = group.claims.map((claim) => {
          return {
            claim,
            isSelected: this.userClaimIds.includes(claim.id),
          };
        });

        return {
          group,
          isSelected: claims.filter((claim) => claim.isSelected).length === claims.length,
          claims,
        };
      });
  }

  /**
   * Submits the form.
   */
  submit() {
    if (this.form.invalid) {
      const errorMessage = new NotificationMessage();

      if (this.form.get('organizationId').invalid) {
        errorMessage.title = this.translationService.translate(
          'SECURITY.USERS.USER_DATA_VALIDATION.ORGANIZATION_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate('SECURITY.USERS.USER_DATA_VALIDATION.ORGANIZATION_IS_REQUIRED'),
        ];
      }

      this.form.markAllAsTouched();
      return this.notificationService.warningWithTitle(errorMessage);
    } else {
      this.openModal(this.confirmModalRef);
    }
  }

  /**
   * Confirms the form submit.
   */
  confirm() {
    this.securityStore$.dispatch(
      new fromSecurityStore.UpdateUser({
        id: this.userId,
        ...this.form.value,
        /**
         * Map claim groups model to Array(number) of claim ids.
         */
        claims: flatMap(this.claimGroupsModel, (group) => group.claims)
          .filter((claim) => claim.isSelected)
          .map((claim) => claim.claim.id),
        locations: [],
        bankAccounts: [],
      })
    );
  }

  /**
   * Selects the newly selected organization from the organizations search list.
   * @param organizations The list of newly selected organizations to select the only one in the list.
   */
  selectOrganization([organization]: Organization[]) {
    if (organization) {
      this.organizations = [organization];
      this.form.patchValue({ organizationId: organization.id });
    }
  }

  /**
   * Opens the modal of the given templateRef.
   * @param modalRef The modal templateRef to be opened.
   */
  openModal(modalRef) {
    this.modalService.open(modalRef);
  }
}
