import { Action } from '@ngrx/store';

import { CancelShiftInput, CreateShiftInput, Shift } from 'sales/models';
import { AppHttpResponse } from 'shared';

/**
 * The Sales-Module store shifts action types.
 */
export enum ShiftsActionType {
  SEARCH_MY_SHIFTS = '[Sales] [Shifts] Search My Shifts',
  SEARCH_MY_SHIFTS_FAIL = '[Sales] [Shifts] Search My Shifts Fail',
  SEARCH_MY_SHIFTS_SUCCESS = '[Sales] [Shifts] Search My Shifts Success',

  SEARCH_SHIFTS = '[Sales] [Shifts] Search Shifts',
  SEARCH_SHIFTS_FAIL = '[Sales] [Shifts] Search Shifts Fail',
  SEARCH_SHIFTS_SUCCESS = '[Sales] [Shifts] Search Shifts Success',

  FIND_SHIFT = '[Sales] [Shifts] Find Shift',
  FIND_SHIFT_FAIL = '[Sales] [Shifts] Find Shift Fail',
  FIND_SHIFT_SUCCESS = '[Sales] [Shifts] Find Shift Success',

  START_SHIFT = '[Sales] [Shifts] Start Shift',
  START_SHIFT_FAIL = '[Sales] [Shifts] Start Shift Fail',
  START_SHIFT_SUCCESS = '[Sales] [Shifts] Start Shift Success',

  START_SHIFT_FOR_CASHER = '[Sales] [Shifts] Start Shift For Casher',
  START_SHIFT_FOR_CASHER_FAIL = '[Sales] [Shifts] Start Shift For Casher Fail',
  START_SHIFT_FOR_CASHER_SUCCESS = '[Sales] [Shifts] Start Shift For Casher Success',

  CANCEL_MY_SHIFT = '[Sales] [Shifts] Cancel My Shift',
  CANCEL_MY_SHIFT_FAIL = '[Sales] [Shifts] Cancel My Shift Fail',
  CANCEL_MY_SHIFT_SUCCESS = '[Sales] [Shifts] Cancel My Shift Success',

  END_SHIFT = '[Sales] [Shifts] Cancel Shift',
  END_SHIFT_FAIL = '[Sales] [Shifts] Cancel Shift Fail',
  END_SHIFT_SUCCESS = '[Sales] [Shifts] Cancel Shift Success',

  DELETE_SHIFT = '[Sales] [Shifts] Delete Shift',
  DELETE_SHIFT_FAIL = '[Sales] [Shifts] Delete Shift Fail',
  DELETE_SHIFT_SUCCESS = '[Sales] [Shifts] Delete Shift Success',
}

/**
 * Represents a store shift Start action.
 */
export class StartShift implements Action {
  /** The type of the action. */
  readonly type = ShiftsActionType.START_SHIFT;

  /**
   * Fires a new shift Start action.
   */
  constructor() {}
}

/**
 * Represents a store shift Start-fail action.
 */
export class StartShiftFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = ShiftsActionType.START_SHIFT_FAIL;

  /**
   * Fires a new shift Start-fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store shift Start-success action.
 */
export class StartShiftSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = ShiftsActionType.START_SHIFT_SUCCESS;

  /**
   * Fires a new shift Start-success action.
   * @param payload The Start shift.
   */
  constructor(public payload: AppHttpResponse<Shift>) {}
}

/**
 * Represents a store shift Start for casher action.
 */
export class StartShiftForCasher implements Action {
  /** The type of the action. */
  readonly type = ShiftsActionType.START_SHIFT_FOR_CASHER;

  /**
   * Fires a new shift Start action.
   * * @param payload The user's shift id and the new shift.
   */
  constructor(public payload: CreateShiftInput) {}
}

/**
 * Represents a store shift start-for-casher-fail action.
 */
export class StartShiftForCasherFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = ShiftsActionType.START_SHIFT_FOR_CASHER_FAIL;

  /**
   * Fires a new shift Start-fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store shift start-for-casher-success action.
 */
export class StartShiftForCasherSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = ShiftsActionType.START_SHIFT_FOR_CASHER_SUCCESS;

  /**
   * Fires a new shift start-for-casher-success action.
   * @param payload The start-for-casher.
   */
  constructor(public payload: AppHttpResponse<Shift>) {}
}

/**
 * Represents a store shifts search action.
 */
export class SearchMyShifts implements Action {
  /**
   * The type of the action.
   */
  readonly type = ShiftsActionType.SEARCH_MY_SHIFTS;

  /**
   * Fires a new shifts search action.
   * @param payload the search parameters,
   * if omitted, all shifts will be loaded.
   */
  constructor(
    public payload?: {
      fromDate: Date;
      toDate: Date;
      page: number;
    }
  ) {}
}

/**
 * Represents a store shifts search-fail action.
 */
export class SearchMyShiftsFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = ShiftsActionType.SEARCH_MY_SHIFTS_FAIL;

  /**
   * Fires a new shifts search-fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store search-success action.
 */
export class SearchMyShiftsSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = ShiftsActionType.SEARCH_MY_SHIFTS_SUCCESS;

  /**
   * Fires a new shifts search-success action.
   * @param payload An object contains the list of shifts that matches the search criteria
   * in addition to the pagination info.
   */
  constructor(public payload: AppHttpResponse<Shift[]>) {}
}

/**
 * Represents a store shifts search action.
 */
export class SearchShifts implements Action {
  /**
   * The type of the action.
   */
  readonly type = ShiftsActionType.SEARCH_SHIFTS;

  /**
   * Fires a new shifts search action.
   * @param payload the search parameters,
   * if omitted, all shifts will be loaded.
   */
  constructor(public payload?: { users: number[]; locations: number[]; fromDate: Date; toDate: Date; page: number }) {}
}

/**
 * Represents a store shifts search-fail action.
 */
export class SearchShiftsFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = ShiftsActionType.SEARCH_SHIFTS_FAIL;

  /**
   * Fires a new shifts search-fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store search-success action.
 */
export class SearchShiftsSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = ShiftsActionType.SEARCH_SHIFTS_SUCCESS;

  /**
   * Fires a new shifts search-success action.
   * @param payload An object contains the list of shifts that matches the search criteria
   * in addition to the pagination info.
   */
  constructor(public payload: AppHttpResponse<Shift[]>) {}
}

/**
 * Represents a store find-shift action.
 */
export class FindShift implements Action {
  /**
   * The type of the action.
   */
  readonly type = ShiftsActionType.FIND_SHIFT;

  /**
   * Fires a new find shift action.
   * @param payload the id of the shift.
   */
  constructor(public payload: number) {}
}

/**
 * Represents a store find-shift-fail action.
 */
export class FindShiftFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = ShiftsActionType.FIND_SHIFT_FAIL;

  /**
   * Fires a new find-shift-fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store find shift-success action.
 */
export class FindShiftSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = ShiftsActionType.FIND_SHIFT_SUCCESS;

  /**
   * Fires a new find-shift-success action.
   * @param payload The loaded shift.
   */
  constructor(public payload: AppHttpResponse<Shift>) {}
}

/**
 * Represents a store shift cancel action.
 */
export class CancelMyShift implements Action {
  /**
   * The type of the action.
   */
  readonly type = ShiftsActionType.CANCEL_MY_SHIFT;

  /**
   * Fires a new shift cancel action.
   */
  constructor(public payload: CancelShiftInput) {}
}

/**
 * Represents a store shift cancel-fail action.
 */
export class CancelMyShiftFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = ShiftsActionType.CANCEL_MY_SHIFT_FAIL;

  /**
   * Fires a new shift cancel-fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store shift cancel-success action.
 */
export class CancelMyShiftSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = ShiftsActionType.CANCEL_MY_SHIFT_SUCCESS;

  /**
   * Fires a new shift cancel-success action.
   * @param payload The canceled shift.
   */
  constructor(public payload: AppHttpResponse<Shift>) {}
}

/**
 * Represents a store shift cancel action.
 */
export class CancelShift implements Action {
  /**
   * The type of the action.
   */
  readonly type = ShiftsActionType.END_SHIFT;

  /**
   * Fires a new shift cancel action.
   */
  constructor(public payload: CancelShiftInput) {}
}

/**
 * Represents a store shift cancel-fail action.
 */
export class CancelShiftFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = ShiftsActionType.END_SHIFT_FAIL;

  /**
   * Fires a new shift cancel-fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store shift cancel-success action.
 */
export class CancelShiftSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = ShiftsActionType.END_SHIFT_SUCCESS;

  /**
   * Fires a new shift cancel-success action.
   * @param payload The canceled shift.
   */
  constructor(public payload: AppHttpResponse<Shift>) {}
}

/**
 * Represents a store shift delete action.
 */
export class DeleteShift implements Action {
  /**
   * The type of the action.
   */
  readonly type = ShiftsActionType.DELETE_SHIFT;

  /**
   * Fires a new shift delete action.
   * @param payload The shift's id.
   */
  constructor(public payload: number) {}
}

/**
 * Represents a store shift delete-fail action.
 */
export class DeleteShiftFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = ShiftsActionType.DELETE_SHIFT_FAIL;

  /**
   * Fires a new shift delete-fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store shift delete-success action.
 */
export class DeleteShiftSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = ShiftsActionType.DELETE_SHIFT_SUCCESS;

  /**
   * Fires a new shift delete-success action.
   * @param payload The deleted shift.
   */
  constructor(public payload: AppHttpResponse<Shift>) {}
}

/**
 * Sales-module shifts action types.
 */
export type ShiftsActions =
  | StartShift
  | StartShiftFail
  | StartShiftSuccess
  | StartShiftForCasher
  | StartShiftForCasherFail
  | StartShiftForCasherSuccess
  | SearchMyShifts
  | SearchMyShiftsFail
  | SearchMyShiftsSuccess
  | SearchShifts
  | SearchShiftsFail
  | SearchShiftsSuccess
  | FindShift
  | FindShiftFail
  | FindShiftSuccess
  | CancelMyShift
  | CancelMyShiftFail
  | CancelMyShiftSuccess
  | CancelShift
  | CancelShiftFail
  | CancelShiftSuccess
  | DeleteShift
  | DeleteShiftFail
  | DeleteShiftSuccess;
