import { createSelector } from '@ngrx/store';

import { ProjectsState, ProjectsManagementState } from 'projects-management/store/states';
import { selectProjectsManagementState } from 'projects-management/store/selectors/feature.selectors';

/**
 * Gets the projects state.
 */
const selectProjectState = createSelector(
  selectProjectsManagementState,
  (state: ProjectsManagementState) => state.projects
);

/**
 * Gets the list of loaded projects.
 */
export const getProjects = createSelector(selectProjectState, (state: ProjectsState) => state.data);

/**
 * Gets the list of loaded project logs.
 */
export const getProjectLogs = createSelector(selectProjectState, (state: ProjectsState) => state.projectLogsData);

/**
 * Gets the list of loaded project revenues.
 */
export const getProjectRevenues = createSelector(
  selectProjectState,
  (state: ProjectsState) => state.projectRevenuesData
);

/**
 * Gets the list of loaded project main tasks.
 */
export const getProjectMainTasks = createSelector(
  selectProjectState,
  (state: ProjectsState) => state.projectMainTasksData
);

/**
 * Gets the list of loaded project sub tasks.
 */
export const getProjectSubTasks = createSelector(
  selectProjectState,
  (state: ProjectsState) => state.projectSubTasksData
);

/**
 * Gets the list of loaded project resources.
 */
export const getProjectResources = createSelector(
  selectProjectState,
  (state: ProjectsState) => state.projectResourcesData
);

/**
 * Gets the list of loaded project status logs.
 */
export const getProjectStatusLogs = createSelector(
  selectProjectState,
  (state: ProjectsState) => state.projectStatusLogsData
);

/**
 * Gets the projects pagination info.
 */
export const getProjectsPaginationInfo = createSelector(
  selectProjectState,
  (state: ProjectsState) => state.paginationInfo
);

/**
 * Gets the project logs pagination info.
 */
export const getProjectLogsPaginationInfo = createSelector(
  selectProjectState,
  (state: ProjectsState) => state.projectLogsPaginationInfo
);

/**
 * Gets the project revenues pagination info.
 */
export const getProjectRevenuesPaginationInfo = createSelector(
  selectProjectState,
  (state: ProjectsState) => state.projectRevenuesPaginationInfo
);

/**
 * Gets the project main tasks pagination info.
 */
export const getProjectMainTasksPaginationInfo = createSelector(
  selectProjectState,
  (state: ProjectsState) => state.projectMainTasksPaginationInfo
);

/**
 * Gets the project sub tasks pagination info.
 */
export const getProjectSubTasksPaginationInfo = createSelector(
  selectProjectState,
  (state: ProjectsState) => state.projectSubTasksPaginationInfo
);

/**
 * Gets the project resources pagination info.
 */
export const getProjectResourcesPaginationInfo = createSelector(
  selectProjectState,
  (state: ProjectsState) => state.projectResourcesPaginationInfo
);

/**
 * Gets the project status logs pagination info.
 */
export const getProjectStatusLogsPaginationInfo = createSelector(
  selectProjectState,
  (state: ProjectsState) => state.projectStatusLogsPaginationInfo
);

/**
 * Gets the error that occurred in the state.
 */
export const getProjectsError = createSelector(selectProjectState, (state: ProjectsState) => state.error);

/**
 * Determines if there is a running search process.
 */
export const getProjectsSearching = createSelector(selectProjectState, (state: ProjectsState) => state.isSearching);

/**
 * Determines if the last search process has been ended successfully.
 */
export const getProjectsSearchCompleted = createSelector(
  selectProjectState,
  (state: ProjectsState) => state.isSearchCompleted
);

/**
 * Determines if there is a running search project main tasks process.
 */
export const getProjectMainTasksSearching = createSelector(
  selectProjectState,
  (state: ProjectsState) => state.isProjectMainTasksSearching
);

/**
 * Determines if the last search project main tasks process has been ended successfully.
 */
export const getProjectMainTasksSearchCompleted = createSelector(
  selectProjectState,
  (state: ProjectsState) => state.isProjectMainTasksSearchCompleted
);

/**
 * Determines if there is a running search project sub tasks process.
 */
export const getProjectSubTasksSearching = createSelector(
  selectProjectState,
  (state: ProjectsState) => state.isProjectSubTasksSearching
);

/**
 * Determines if the last search project sub tasks process has been ended successfully.
 */
export const getProjectSubTasksSearchCompleted = createSelector(
  selectProjectState,
  (state: ProjectsState) => state.isProjectSubTasksSearchCompleted
);

/**
 * Determines if there is a running project log search process.
 */
export const getProjectLogsSearching = createSelector(
  selectProjectState,
  (state: ProjectsState) => state.isProjectLogsSearching
);

/**
 * Determines if the last project log search process has been ended successfully.
 */
export const getProjectLogsSearchCompleted = createSelector(
  selectProjectState,
  (state: ProjectsState) => state.isProjectLogsSearchCompleted
);

/**
 * Determines if there is a running project revenue search process.
 */
export const getProjectRevenuesSearching = createSelector(
  selectProjectState,
  (state: ProjectsState) => state.isProjectRevenuesSearching
);

/**
 * Determines if the last project revenue search process has been ended successfully.
 */
export const getProjectRevenuesSearchCompleted = createSelector(
  selectProjectState,
  (state: ProjectsState) => state.isProjectRevenuesSearchCompleted
);

/**
 * Determines if there is a running project resources search process.
 */
export const getProjectResourcesSearching = createSelector(
  selectProjectState,
  (state: ProjectsState) => state.isProjectResourcesSearching
);

/**
 * Determines if the last project resources search process has been ended successfully.
 */
export const getProjectResourcesSearchCompleted = createSelector(
  selectProjectState,
  (state: ProjectsState) => state.isProjectResourcesSearchCompleted
);

/**
 * Determines if there is a running search-project-status-logs process.
 */
export const getProjectStatusLogsSearching = createSelector(
  selectProjectState,
  (state: ProjectsState) => state.isSearchingProjectStatusLogs
);

/**
 * Determines if the last search-project-status-logs process has been ended successfully.
 */
export const getProjectStatusLogsSearchCompleted = createSelector(
  selectProjectState,
  (state: ProjectsState) => state.isSearchProjectStatusLogsCompleted
);

/**
 * Gets the selected project.
 */
export const getSelectedProject = createSelector(selectProjectState, (state: ProjectsState) => state.selectedProject);

/**
 * Gets the selected project status log.
 */
export const getSelectedProjectStatusLog = createSelector(
  selectProjectState,
  (state: ProjectsState) => state.selectedProjectStatusLog
);

/**
 * Gets the selected project revenue.
 */
export const getSelectedProjectRevenue = createSelector(
  selectProjectState,
  (state: ProjectsState) => state.selectedProjectRevenue
);

/**
 * Gets the selected project resource.
 */
export const getSelectedProjectResource = createSelector(
  selectProjectState,
  (state: ProjectsState) => state.selectedProjectResource
);

/**
 * Determines if there is a running find project process.
 */
export const getProjectsFinding = createSelector(selectProjectState, (state: ProjectsState) => state.isFinding);

/**
 * Determines if the last find project process has been ended successfully.
 */
export const getProjectsFindCompleted = createSelector(
  selectProjectState,
  (state: ProjectsState) => state.isFindCompleted
);

/**
 * Determines if there is a running find project revenue process.
 */
export const getProjectRevenueFinding = createSelector(
  selectProjectState,
  (state: ProjectsState) => state.isProjectRevenueFinding
);

/**
 * Determines if the last find project revenue process has been ended successfully.
 */
export const getProjectRevenueFindCompleted = createSelector(
  selectProjectState,
  (state: ProjectsState) => state.isProjectRevenueFindCompleted
);

/**
 * Determines if there is a running find project resource process.
 */
export const getProjectResourceFinding = createSelector(
  selectProjectState,
  (state: ProjectsState) => state.isProjectResourceFinding
);

/**
 * Determines if the last find project resource process has been ended successfully.
 */
export const getProjectResourceFindCompleted = createSelector(
  selectProjectState,
  (state: ProjectsState) => state.isProjectResourceFindCompleted
);

/**
 * Determines if there is a running create process.
 */
export const getSelectedProjectCreating = createSelector(
  selectProjectState,
  (state: ProjectsState) => state.isCreating
);

/**
 * Determines if the last create process has been ended successfully.
 */
export const getSelectedProjectCreateCompleted = createSelector(
  selectProjectState,
  (state: ProjectsState) => state.isCreateCompleted
);

/**
 * Determines if there is a running project main task create process.
 */
export const getSelectedProjectMainTaskCreating = createSelector(
  selectProjectState,
  (state: ProjectsState) => state.isProjectMainTaskCreating
);

/**
 * Determines if the last project main task create process has been ended successfully.
 */
export const getSelectedProjectMainTaskCreateCompleted = createSelector(
  selectProjectState,
  (state: ProjectsState) => state.isProjectMainTaskCreateCompleted
);

/**
 * Determines if there is a running project sub task create process.
 */
export const getSelectedProjectSubTaskCreating = createSelector(
  selectProjectState,
  (state: ProjectsState) => state.isProjectSubTaskCreating
);

/**
 * Determines if the last project sub task create process has been ended successfully.
 */
export const getSelectedProjectSubTaskCreateCompleted = createSelector(
  selectProjectState,
  (state: ProjectsState) => state.isProjectSubTaskCreateCompleted
);

/**
 * Determines if there is a running create project status log process.
 */
export const getSelectedProjectStatusLogCreating = createSelector(
  selectProjectState,
  (state: ProjectsState) => state.isProjectStatusLogCreating
);

/**
 * Determines if the last create project status log process has been ended successfully.
 */
export const getSelectedProjectStatusLogCreateCompleted = createSelector(
  selectProjectState,
  (state: ProjectsState) => state.isProjectStatusLogCreateCompleted
);

/**
 * Determines if there is a running update process.
 */
export const getSelectedProjectUpdating = createSelector(
  selectProjectState,
  (state: ProjectsState) => state.isUpdating
);

/**
 * Determines if the last update process has been ended successfully.
 */
export const getSelectedProjectUpdateCompleted = createSelector(
  selectProjectState,
  (state: ProjectsState) => state.isUpdateCompleted
);

/**
 * Determines if there is a running project main task update process.
 */
export const getSelectedProjectMainTaskUpdating = createSelector(
  selectProjectState,
  (state: ProjectsState) => state.isProjectMainTaskUpdating
);

/**
 * Determines if the last project main task update process has been ended successfully.
 */
export const getSelectedProjectMainTaskUpdateCompleted = createSelector(
  selectProjectState,
  (state: ProjectsState) => state.isProjectMainTaskUpdateCompleted
);

/**
 * Determines if there is a running project sub task update process.
 */
export const getSelectedProjectSubTaskUpdating = createSelector(
  selectProjectState,
  (state: ProjectsState) => state.isProjectSubTaskUpdating
);

/**
 * Determines if the last project sub task update process has been ended successfully.
 */
export const getSelectedProjectSubTaskUpdateCompleted = createSelector(
  selectProjectState,
  (state: ProjectsState) => state.isProjectSubTaskUpdateCompleted
);

/**
 * Determines if there is a running delete process.
 */
export const getSelectedProjectDeleting = createSelector(
  selectProjectState,
  (state: ProjectsState) => state.isDeleting
);

/**
 * Determines if the last delete process has been ended successfully.
 */
export const getSelectedProjectDeleteCompleted = createSelector(
  selectProjectState,
  (state: ProjectsState) => state.isDeleteCompleted
);

/**
 * Determines if there is a running project main task delete process.
 */
export const getSelectedProjectMainTaskDeleting = createSelector(
  selectProjectState,
  (state: ProjectsState) => state.isProjectMainTaskDeleting
);

/**
 * Determines if the last project main task delete process has been ended successfully.
 */
export const getSelectedProjectMainTaskDeleteCompleted = createSelector(
  selectProjectState,
  (state: ProjectsState) => state.isProjectMainTaskDeleteCompleted
);

/**
 * Determines if there is a running project sub task delete process.
 */
export const getSelectedProjectSubTaskDeleting = createSelector(
  selectProjectState,
  (state: ProjectsState) => state.isProjectSubTaskDeleting
);

/**
 * Determines if the last project sub task delete process has been ended successfully.
 */
export const getSelectedProjectSubTaskDeleteCompleted = createSelector(
  selectProjectState,
  (state: ProjectsState) => state.isProjectSubTaskDeleteCompleted
);

/**
 * Determines if there is a running create project planning resource process.
 */
export const getSelectedProjectPlanningResourceCreating = createSelector(
  selectProjectState,
  (state: ProjectsState) => state.isProjectPlanningResourceCreating
);

/**
 * Determines if the last create project planning resource process has been ended successfully.
 */
export const getSelectedProjectPlanningResourceCreateCompleted = createSelector(
  selectProjectState,
  (state: ProjectsState) => state.isProjectPlanningResourceCreateCompleted
);

/**
 * Determines if there is a running delete project planning resource process.
 */
export const getSelectedProjectPlanningResourceDeleting = createSelector(
  selectProjectState,
  (state: ProjectsState) => state.isProjectPlanningResourceDeleting
);

/**
 * Determines if the last delete project planning resource process has been ended successfully.
 */
export const getSelectedProjectPlanningResourceDeleteCompleted = createSelector(
  selectProjectState,
  (state: ProjectsState) => state.isProjectPlanningResourceDeleteCompleted
);
