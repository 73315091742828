import { createSelector } from '@ngrx/store';

import { selectLookupsState } from 'lookups/store/selectors/feature.selectors';
import { LookupsState, CityState } from 'lookups/store/states';

/**
 * Gets the city state.
 */
const selectCities = createSelector(selectLookupsState, (state: LookupsState) => state.cities);

/**
 * Gets the list of loaded cities.
 */
export const getCities = createSelector(selectCities, (state: CityState) => state.data);

/**
 * Gets the error that occurred in the state.
 */
export const getCitiesError = createSelector(selectCities, (state: CityState) => state.error);

/**
 * Determines if there is a running load process.
 */
export const getCitiesIsLoading = createSelector(selectCities, (state: CityState) => state.isLoading);

/**
 * Determines if the last load process has been ended successfully.
 */
export const getCitiesIsLoaded = createSelector(selectCities, (state: CityState) => state.isLoaded);
