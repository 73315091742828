import { createSelector } from '@ngrx/store';

import { UnitsOfMeasureState, StoresState } from 'stores/store/states';
import { selectStoresState } from 'stores/store/selectors/feature.selectors';

/**
 * Gets the units of measure state.
 */
const selectUnitsOfMeasureState = createSelector(selectStoresState, (state: StoresState) => state.unitsOfMeasure);

/**
 * Gets the list of loaded units of measure.
 */
export const getUnitsOfMeasure = createSelector(selectUnitsOfMeasureState, (state: UnitsOfMeasureState) => state.data);

/**
 * Gets the units of measure pagination info.
 */
export const getUnitsOfMeasurePaginationInfo = createSelector(
  selectUnitsOfMeasureState,
  (state: UnitsOfMeasureState) => state.paginationInfo
);

/**
 * Gets the error that occurred in the state.
 */
export const getUnitsOfMeasureError = createSelector(
  selectUnitsOfMeasureState,
  (state: UnitsOfMeasureState) => state.error
);

/**
 * Determines if there is a running search process.
 */
export const getUnitsOfMeasureSearching = createSelector(
  selectUnitsOfMeasureState,
  (state: UnitsOfMeasureState) => state.isSearching
);

/**
 * Determines if the last search process has been ended successfully.
 */
export const getUnitsOfMeasureSearchCompleted = createSelector(
  selectUnitsOfMeasureState,
  (state: UnitsOfMeasureState) => state.isSearchCompleted
);

/**
 * Determines if there is a running get all process.
 */
export const getUnitsOfMeasureGettingAll = createSelector(
  selectUnitsOfMeasureState,
  (state: UnitsOfMeasureState) => state.isGettingAll
);

/**
 * Determines if the last get all process has been ended successfully.
 */
export const getUnitsOfMeasureGetAllCompleted = createSelector(
  selectUnitsOfMeasureState,
  (state: UnitsOfMeasureState) => state.isGetAllCompleted
);

/**
 * Gets the selected unit of measure.
 */
export const getSelectedUnitOfMeasure = createSelector(
  selectUnitsOfMeasureState,
  (state: UnitsOfMeasureState) => state.selectedUnitOfMeasure
);

/**
 * Determines if there is a running find unit of measure process.
 */
export const getUnitOfMeasureFinding = createSelector(
  selectUnitsOfMeasureState,
  (state: UnitsOfMeasureState) => state.isFinding
);

/**
 * Determines if the last find unit of measure process has been ended successfully.
 */
export const getUnitOfMeasureFindCompleted = createSelector(
  selectUnitsOfMeasureState,
  (state: UnitsOfMeasureState) => state.isFindCompleted
);

/**
 * Determines if there is a running create process.
 */
export const getSelectedUnitOfMeasureCreating = createSelector(
  selectUnitsOfMeasureState,
  (state: UnitsOfMeasureState) => state.isCreating
);

/**
 * Determines if the last create process has been ended successfully.
 */
export const getSelectedUnitOfMeasureCreateCompleted = createSelector(
  selectUnitsOfMeasureState,
  (state: UnitsOfMeasureState) => state.isCreateCompleted
);

/**
 * Determines if there is a running update process.
 */
export const getSelectedUnitOfMeasureUpdating = createSelector(
  selectUnitsOfMeasureState,
  (state: UnitsOfMeasureState) => state.isUpdating
);

/**
 * Determines if the last update process has been ended successfully.
 */
export const getSelectedUnitOfMeasureUpdateCompleted = createSelector(
  selectUnitsOfMeasureState,
  (state: UnitsOfMeasureState) => state.isUpdateCompleted
);

/**
 * Determines if there is a running delete process.
 */
export const getSelectedUnitOfMeasureDeleting = createSelector(
  selectUnitsOfMeasureState,
  (state: UnitsOfMeasureState) => state.isDeleting
);

/**
 * Determines if the last delete process has been ended successfully.
 */
export const getSelectedUnitOfMeasureDeleteCompleted = createSelector(
  selectUnitsOfMeasureState,
  (state: UnitsOfMeasureState) => state.isDeleteCompleted
);
