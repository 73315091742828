<div class="row">
  <div class="col-md-12">
    <!-- journal depreciation asset details starts -->
    <div class="card-header mb-3">
      <h4 class="card-title">{{ 'FINANCES.JOURNALS.JOURNAL_DATA.ASSET_DEPRECIATION_DETAILS' | translate }}</h4>
    </div>
    <div class="card-content">
      <div class="px-3">
        <div class="row">
          <div class="form-group col-md-6">
            <label for="bookValueBefore">{{
              'FINANCES.JOURNALS.JOURNAL_DATA.BOOK_VALUE_BEFORE_DEPRECIATION' | translate
            }}</label>
            <input
              type="text"
              id="bookValueBefore"
              class="form-control round"
              [value]="assetDepreciation.bookValueBefore | toDecimal"
              readonly
            />
          </div>
          <div class="form-group col-md-6">
            <label for="bookValueAfter">{{
              'FINANCES.JOURNALS.JOURNAL_DATA.BOOK_VALUE_AFTER_DEPRECIATION' | translate
            }}</label>
            <input
              type="text"
              id="bookValueAfter"
              class="form-control round"
              [value]="assetDepreciation.bookValueAfter | toDecimal"
              readonly
            />
          </div>
          <div class="form-group col-md-6">
            <label for="depreciationValue">{{ 'FINANCES.JOURNALS.JOURNAL_DATA.DEPRECIATION_VALUE' | translate }}</label>
            <input
              type="text"
              id="depreciationValue"
              class="form-control round"
              [value]="assetDepreciation.depreciationValue | toDecimal"
              readonly
            />
          </div>
          <div class="form-group col-md-6">
            <label for="assetId">{{ 'FINANCES.JOURNALS.JOURNAL_DATA.ASSET_DESCRIPTION' | translate }}</label>
            <input
              type="text"
              id="assetId"
              class="form-control round"
              [value]="assetDepreciation.asset?.description"
              readonly
            />
          </div>
          <div class="form-group col-md-4">
            <label for="transactionDate">{{ 'FINANCES.JOURNALS.JOURNAL_DATA.JOURNAL_DATE' | translate }}</label>
            <input
              type="text"
              id="transactionDate"
              class="form-control round"
              [value]="assetDepreciation.transactionDate | date: 'datetime'"
              readonly
            />
          </div>
          <div class="form-group col-md-3">
            <label for="createdAt">{{ 'SHARED.SEARCH.CREATED_AT' | translate }}</label>
            <input
              type="text"
              id="createdAt"
              class="form-control round"
              [value]="assetDepreciation.createdAt | date: 'datetime'"
              readonly
            />
          </div>
          <div class="form-group col-md-6">
            <label for="organizationId">{{ 'FINANCES.JOURNALS.JOURNAL_DATA.ORGANIZATION_NAME' | translate }}</label>
            <input
              type="text"
              id="organizationId"
              class="form-control round"
              [value]="assetDepreciation.organization?.name"
              readonly
            />
          </div>
        </div>
      </div>
      <!-- journal depreciation asset details ends -->
    </div>
  </div>
</div>
