import { Injectable } from '@angular/core';

import { of } from 'rxjs';
import { switchMap, map, catchError } from 'rxjs/operators';
import { Effect, ofType, Actions } from '@ngrx/effects';

import { LookupsService } from 'lookups/services';
import {
  ContractDurationActionType,
  GetAllContractDurationsFail,
  GetAllContractDurationsSuccess,
} from 'lookups/store/actions';

@Injectable()
export class ContractDurationEffects {
  constructor(private actions$: Actions, private lookupsService: LookupsService) {}

  /* ========================= GET_ALL_CONTRACT_DURATIONS =================================== */
  @Effect()
  getAllContractDurations$ = this.actions$.pipe(
    ofType(ContractDurationActionType.GET_ALL_CONTRACT_DURATIONS),
    switchMap(() =>
      this.lookupsService.getAllContractDurations().pipe(
        map((response) => new GetAllContractDurationsSuccess(response)),
        catchError((error) => of(new GetAllContractDurationsFail(error)))
      )
    )
  );
}
