import { Component, OnInit, ViewChild, ElementRef, Input, OnDestroy } from '@angular/core';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { skip, tap } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';

import * as fromSecurityStore from 'security/store';
import * as fromAuthStore from 'auth/store';
import { User } from 'security/models';
import { FormArray, FormGroup } from '@angular/forms';
import { Location, LocationFormItem } from 'stores/models';

@Component({
  selector: 'app-update-user-location',
  templateUrl: './update-user-location.component.html',
  styles: [],
})
export class UpdateUserLocationComponent implements OnInit, OnDestroy {
  /**
   * The locations confirm modal template reference.
   */
  @ViewChild('locationsConfirmModalRef') locationsConfirmModalRef: ElementRef<any>;

  /**
   * Gets or sets the id of the current edited user.
   */
  @Input() userId: number;

  /**
   * The current logged in user.
   */
  currentLoggedInUser: User;

  /**
   * The list of user locations.
   */
  locations$: Observable<Location[]>;

  /**
   * Indicates whether there is a update user process is running or not.
   */
  isUpdating$: Observable<boolean>;

  /**
   * The update user form.
   */
  form: FormGroup;

  /**
   * Sets the initial locations should be added to the locations form.
   * @param locations An object contains `count` of initial blank locations.
   * @param locations A single service details to be added to the form.
   * @param locations A list of locations details to be added to the form.
   */
  initialLocationsSubject: BehaviorSubject<
    { count: number } | LocationFormItem | LocationFormItem[]
  > = new BehaviorSubject(undefined);

  /**
   * Gets the locations form-array.
   */
  get locationsForm(): FormArray {
    return this.form?.controls.locations as FormArray;
  }

  /**
   * Used to add new location form items to the locations form.
   */
  newLocations: LocationFormItem[];

  /**
   * The set of subscriptions on this components,
   * these subscriptions must be unsubscribed before this component got destroyed.
   */
  subscriptions = new Subscription();

  /**
   * @param modalService The modal service.
   * @param securityStore$ The Security-module store.
   * @param authStore$ The Auth-module store.
   */
  constructor(
    private modalService: NgbModal,
    private securityStore$: Store<fromSecurityStore.SecurityState>,
    private authStore$: Store<fromAuthStore.AuthState>
  ) {}

  ngOnInit(): void {
    this.init();
  }

  /**
   * Unsubscribes for component data.
   */
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  /**
   * Initialize component data.
   */
  init() {
    this.initForm();

    /* Load the selected user locations. */
    this.locations$ = this.securityStore$.pipe(
      select(fromSecurityStore.getSelectedUserLocations),
      tap((userLocations) => {
        if (userLocations) {
          this.form.patchValue(userLocations);
          this.locationsForm.clear();
          this.newLocations = userLocations.map((location: Location) => ({
            locationId: location.id,
            location,
          }));
        }
      })
    );

    /**
     * Load data.
     */
    this.securityStore$.dispatch(new fromSecurityStore.GetUserLocations(this.userId));

    this.isUpdating$ = this.securityStore$.pipe(select(fromSecurityStore.getSelectedUserLocationUpdating));

    /**
     * rest form on creation complete
     */
    this.subscriptions.add(
      this.securityStore$
        .pipe(
          select(fromSecurityStore.getSelectedUserUpdateCompleted),
          skip(1),
          tap((updated) => {
            if (updated) {
              this.initForm();
              this.closeModal();
            }
          })
        )
        .subscribe()
    );

    /** Load current logged in user data. */
    this.subscriptions.add(
      this.authStore$
        .pipe(
          select(fromAuthStore.getSessionUser),
          tap((user: User) => {
            if (user) {
              this.currentLoggedInUser = user;
            }
          })
        )
        .subscribe()
    );
  }

  initForm() {
    this.form = new FormGroup({
      locations: new FormArray([]),
    });
  }

  /**
   * Submits the locations form.
   */
  submit() {
    this.openModal(this.locationsConfirmModalRef);
  }

  /**
   * Confirms the the locations form submit.
   */
  confirm() {
    /**
     * The list of locations in the locations form.
     */
    const locations = this.locationsForm.value.map((item: LocationFormItem) => item.locationId);

    this.securityStore$.dispatch(
      new fromSecurityStore.UpdateUserLocation({
        id: this.userId,
        locations: locations,
      })
    );
  }

  /**
   * Opens the modal of the given templateRef.
   * @param modalRef The modal templateRef to be opened.
   */
  openModal(modalRef) {
    this.modalService.open(modalRef);
  }

  /**
   * Closes the currently opened modal.
   */
  closeModal() {
    this.modalService.dismissAll();
  }
}
