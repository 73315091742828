import { ActionReducerMap } from '@ngrx/store';
import { routerReducer } from '@ngrx/router-store';

import { AppState } from 'core/store/states';
import { appStatusReducer } from 'core/store/reducers/app-status.reducer';

/* for Custom Serializer */
export * from 'core/store/reducers/router.reducer';

export const reducers: ActionReducerMap<AppState> = {
  router: routerReducer,
  status: appStatusReducer,
};
