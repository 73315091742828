import { findIndex, remove } from 'lodash';

import { CostCentersActionType, CostCentersActions } from 'finances/store/actions';
import { CostCentersState } from 'finances/store/states';

/**
 * The initial state from which the state starts.
 */
const initialState: CostCentersState = {
  data: [],
  generalCostCenterData: null,
  paginationInfo: {
    total: 0,
    page: 1,
    pageSize: 0,
    count: 0,
  },
  error: null,
  isSearching: false,
  isSearchCompleted: false,
  isGettingGeneralCostCenter: false,
  isGetGeneralCostCenterCompleted: false,
  selectedCostCenter: null,
  isFinding: false,
  isFindCompleted: false,
  isCreating: false,
  isCreateCompleted: false,
  isUpdating: false,
  isUpdateCompleted: false,
  isDeleting: false,
  isDeleteCompleted: false,
};

/**
 * The reducer function that is called in each action dispatch against the state.
 * @param state The current state.
 * @param action The action that will affect the state.
 */
export function costCentersReducer(
  state: CostCentersState = initialState,
  action: CostCentersActions
): CostCentersState {
  switch (action.type) {
    //#region SEARCH_COST_CENTERS

    case CostCentersActionType.SEARCH_COST_CENTERS: {
      return {
        ...state,
        isSearching: true,
        isSearchCompleted: false,
        error: null,
      };
    }

    case CostCentersActionType.SEARCH_COST_CENTERS_FAIL: {
      return {
        ...state,
        error: action.payload,
        isSearching: false,
        isSearchCompleted: false,
      };
    }

    case CostCentersActionType.SEARCH_COST_CENTERS_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        paginationInfo: {
          ...action.payload.meta,
        },
        error: null,
        isSearching: false,
        isSearchCompleted: true,
      };
    }

    //#endregion SEARCH_COST_CENTERS

    //#region GET_GENERAL_COST_CENTER

    case CostCentersActionType.GET_GENERAL_COST_CENTER: {
      return {
        ...state,
        isGettingGeneralCostCenter: true,
        isGetGeneralCostCenterCompleted: false,
        error: null,
      };
    }

    case CostCentersActionType.GET_GENERAL_COST_CENTER_FAIL: {
      return {
        ...state,
        error: action.payload,
        isGettingGeneralCostCenter: false,
        isGetGeneralCostCenterCompleted: false,
      };
    }

    case CostCentersActionType.GET_GENERAL_COST_CENTER_SUCCESS: {
      return {
        ...state,
        generalCostCenterData: action.payload.data,
        error: null,
        isGettingGeneralCostCenter: false,
        isGetGeneralCostCenterCompleted: true,
      };
    }

    //#endregion GET_GENERAL_COST_CENTER

    //#region FIND_COST_CENTER

    case CostCentersActionType.FIND_COST_CENTER: {
      return {
        ...state,
        selectedCostCenter: null,
        error: null,
        isFinding: true,
        isFindCompleted: false,
      };
    }

    case CostCentersActionType.FIND_COST_CENTER_FAIL: {
      return {
        ...state,
        error: action.payload,
        isFinding: false,
        isFindCompleted: false,
      };
    }

    case CostCentersActionType.FIND_COST_CENTER_SUCCESS: {
      let costCenters = [...state.data];
      const costCenterIndex = findIndex(costCenters, (costCenter) => costCenter.id === action.payload.data.id);

      /* If cost center was found, update it. */
      if (costCenterIndex >= 0) {
        costCenters[costCenterIndex] = action.payload.data;
      } else {
        /* else, insert it to the beginning of the array. */
        costCenters = [action.payload.data, ...costCenters];
      }

      return {
        ...state,
        data: costCenters,
        selectedCostCenter: action.payload.data,
        error: null,
        isFinding: false,
        isFindCompleted: true,
      };
    }

    //#endregion FIND_COST_CENTER

    //#region CREATE_COST_CENTER

    case CostCentersActionType.CREATE_COST_CENTER: {
      return {
        ...state,
        isCreating: true,
        isCreateCompleted: false,
        error: null,
      };
    }

    case CostCentersActionType.CREATE_COST_CENTER_FAIL: {
      return {
        ...state,
        error: action.payload,
        isCreating: false,
        isCreateCompleted: false,
      };
    }

    case CostCentersActionType.CREATE_COST_CENTER_SUCCESS: {
      let costCenters = [...state.data];
      const costCenterIndex = findIndex(costCenters, (costCenter) => costCenter.id === action.payload.data.id);

      /* If cost center was found, update it. */
      if (costCenterIndex >= 0) {
        costCenters[costCenterIndex] = action.payload.data;
      } else {
        /* else, insert it to the beginning of the array. */
        costCenters = [action.payload.data, ...costCenters];
      }
      return {
        ...state,
        data: costCenters,
        selectedCostCenter: action.payload.data,
        error: null,
        isCreating: false,
        isCreateCompleted: true,
      };
    }

    //#endregion CREATE_COST_CENTER

    //#region UPDATE_COST_CENTER

    case CostCentersActionType.UPDATE_COST_CENTER: {
      return {
        ...state,
        isUpdating: true,
        isUpdateCompleted: false,
        error: null,
      };
    }

    case CostCentersActionType.UPDATE_COST_CENTER_FAIL: {
      return {
        ...state,
        error: action.payload,
        isUpdating: false,
        isUpdateCompleted: false,
      };
    }

    case CostCentersActionType.UPDATE_COST_CENTER_SUCCESS: {
      let costCenters = [...state.data];
      const costCenterIndex = findIndex(costCenters, (costCenter) => costCenter.id === action.payload.data.id);

      /* If cost center was found, update it. */
      if (costCenterIndex >= 0) {
        costCenters[costCenterIndex] = action.payload.data;
      } else {
        /* else, insert it to the beginning of the array. */
        costCenters = [action.payload.data, ...costCenters];
      }

      return {
        ...state,
        data: costCenters,
        selectedCostCenter: action.payload.data,
        error: null,
        isUpdating: false,
        isUpdateCompleted: true,
      };
    }

    //#endregion UPDATE_COST_CENTER

    //#region DELETE_COST_CENTER

    case CostCentersActionType.DELETE_COST_CENTER: {
      return {
        ...state,
        isDeleting: true,
        isDeleteCompleted: false,
        error: null,
      };
    }

    case CostCentersActionType.DELETE_COST_CENTER_FAIL: {
      return {
        ...state,
        error: action.payload,
        isDeleting: false,
        isDeleteCompleted: false,
      };
    }

    case CostCentersActionType.DELETE_COST_CENTER_SUCCESS: {
      const costCenters = [...state.data];

      /* Remove the deleted cost center from the array. */
      remove(costCenters, (costCenter) => costCenter.id === action.payload.data.id);

      return {
        ...state,
        data: costCenters,
        selectedCostCenter: action.payload.data,
        error: null,
        isDeleting: false,
        isDeleteCompleted: true,
      };
    }

    //#endregion DELETE_COST_CENTER

    default:
      return state;
  }
}
