import { createSelector } from '@ngrx/store';

import { FinanceConfigurationsState, FinancesState } from 'finances/store/states';
import { selectFinancesState } from 'finances/store/selectors/feature.selectors';

/**
 * Gets the finance configurations state.
 */
const selectFinanceConfigurationsState = createSelector(
  selectFinancesState,
  (state: FinancesState) => state.financeConfigurations
);

/**
 * Gets the list of loaded employee provision links.
 */
export const getEmployeeProvisionLinks = createSelector(
  selectFinanceConfigurationsState,
  (state: FinanceConfigurationsState) => state.employeeProvisionLinks
);

/**
 * Gets the list of loaded gov services.
 */
export const getGovServices = createSelector(
  selectFinanceConfigurationsState,
  (state: FinanceConfigurationsState) => state.govServices
);

/**
 * Gets the error that occurred in the state.
 */
export const getEmployeeProvisionLinksError = createSelector(
  selectFinanceConfigurationsState,
  (state: FinanceConfigurationsState) => state.error
);

/**
 * Gets the error that occurred in the state.
 */
export const getGovServicesError = createSelector(
  selectFinanceConfigurationsState,
  (state: FinanceConfigurationsState) => state.error
);

/**
 * Determines if there is a running get process.
 */
export const getEmployeeProvisionLinksGetting = createSelector(
  selectFinanceConfigurationsState,
  (state: FinanceConfigurationsState) => state.isEmployeeProvisionLinksGetting
);

/**
 * Determines if the last get process has been ended successfully.
 */
export const getEmployeeProvisionLinksCompleted = createSelector(
  selectFinanceConfigurationsState,
  (state: FinanceConfigurationsState) => state.isEmployeeProvisionLinksGetCompleted
);

/**
 * Determines if there is a running update process.
 */
export const getSelectedEmployeeProvisionLinksUpdating = createSelector(
  selectFinanceConfigurationsState,
  (state: FinanceConfigurationsState) => state.isEmployeeProvisionLinksUpdating
);

/**
 * Determines if the last update process has been ended successfully.
 */
export const getSelectedEmployeeProvisionLinksUpdateCompleted = createSelector(
  selectFinanceConfigurationsState,
  (state: FinanceConfigurationsState) => state.isEmployeeProvisionLinksUpdateCompleted
);

/**
 * Determines if there is a running get process.
 */
export const getGovServicesGetting = createSelector(
  selectFinanceConfigurationsState,
  (state: FinanceConfigurationsState) => state.isGovServicesGetting
);

/**
 * Determines if the last get process has been ended successfully.
 */
export const getGovServicesCompleted = createSelector(
  selectFinanceConfigurationsState,
  (state: FinanceConfigurationsState) => state.isGovServicesGetCompleted
);

/**
 * Determines if there is a running update process.
 */
export const getSelectedGovServicesUpdating = createSelector(
  selectFinanceConfigurationsState,
  (state: FinanceConfigurationsState) => state.isGovServicesUpdating
);

/**
 * Determines if the last update process has been ended successfully.
 */
export const getSelectedGovServicesUpdateCompleted = createSelector(
  selectFinanceConfigurationsState,
  (state: FinanceConfigurationsState) => state.isGovServicesUpdateCompleted
);
