import { createSelector } from '@ngrx/store';

import { selectLookupsState } from 'lookups/store/selectors/feature.selectors';
import { LookupsState, ProjectRevenueTypeState } from 'lookups/store/states';

/**
 * Gets the project revenue types state.
 */
const selectProjectRevenueTypes = createSelector(
  selectLookupsState,
  (state: LookupsState) => state.projectRevenueTypes
);

/**
 * Gets the list of loaded project revenue types.
 */
export const getProjectRevenueTypes = createSelector(
  selectProjectRevenueTypes,
  (state: ProjectRevenueTypeState) => state.data
);

/**
 * Gets the error that occurred in the state.
 */
export const getProjectRevenueTypesError = createSelector(
  selectProjectRevenueTypes,
  (state: ProjectRevenueTypeState) => state.error
);

/**
 * Determines if there is a running load process.
 */
export const getProjectRevenueTypesIsLoading = createSelector(
  selectProjectRevenueTypes,
  (state: ProjectRevenueTypeState) => state.isLoading
);

/**
 * Determines if the last load process has been ended successfully.
 */
export const getProjectRevenueTypesIsLoaded = createSelector(
  selectProjectRevenueTypes,
  (state: ProjectRevenueTypeState) => state.isLoaded
);
