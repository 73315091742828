import { Injectable } from '@angular/core';

import { of } from 'rxjs';
import { switchMap, map, catchError } from 'rxjs/operators';
import { Effect, ofType, Actions } from '@ngrx/effects';

import { LookupsService } from 'lookups/services';
import { WorkTypeActionType, GetAllWorkTypesFail, GetAllWorkTypesSuccess } from 'lookups/store/actions';

@Injectable()
export class WorkTypeEffects {
  constructor(private actions$: Actions, private lookupsService: LookupsService) {}

  /* ========================= GET_ALL_WORK_TYPES =================================== */
  @Effect()
  getAllWorkTypes$ = this.actions$.pipe(
    ofType(WorkTypeActionType.GET_ALL_WORK_TYPES),
    switchMap(() =>
      this.lookupsService.getAllWorkTypes().pipe(
        map((response) => new GetAllWorkTypesSuccess(response)),
        catchError((error) => of(new GetAllWorkTypesFail(error)))
      )
    )
  );
}
