import { createSelector } from '@ngrx/store';

import { PosDevicesState, SalesState } from 'sales/store/states';
import { selectSalesState } from 'sales/store/selectors/feature.selectors';

/**
 * Gets the pos device state.
 */
const selectPosDevicesState = createSelector(selectSalesState, (state: SalesState) => state.posDevices);

/**
 * Gets the list of loaded pos device.
 */
export const getPosDevices = createSelector(selectPosDevicesState, (state: PosDevicesState) => state.data);

/**
 * Gets the pos device pagination info.
 */
export const getPosDevicesPaginationInfo = createSelector(
  selectPosDevicesState,
  (state: PosDevicesState) => state.paginationInfo
);

/**
 * Gets the error that occurred in the state.
 */
export const getPosDevicesError = createSelector(selectPosDevicesState, (state: PosDevicesState) => state.error);

/**
 * Determines if there is a running search process.
 */
export const getPosDevicesSearching = createSelector(
  selectPosDevicesState,
  (state: PosDevicesState) => state.isSearching
);

/**
 * Determines if the last search process has been ended successfully.
 */
export const getPosDevicesSearchCompleted = createSelector(
  selectPosDevicesState,
  (state: PosDevicesState) => state.isSearchCompleted
);

/**
 * Gets the selected pos device.
 */
export const getSelectedPosDevice = createSelector(
  selectPosDevicesState,
  (state: PosDevicesState) => state.selectedPosDevice
);

/**
 * Determines if there is a running find pos device process.
 */
export const getPosDeviceFinding = createSelector(selectPosDevicesState, (state: PosDevicesState) => state.isFinding);

/**
 * Determines if the last find pos device process has been ended successfully.
 */
export const getPosDeviceFindCompleted = createSelector(
  selectPosDevicesState,
  (state: PosDevicesState) => state.isFindCompleted
);

/**
 * Determines if there is a running create process.
 */
export const getSelectedPosDeviceCreating = createSelector(
  selectPosDevicesState,
  (state: PosDevicesState) => state.isCreating
);

/**
 * Determines if the last create process has been ended successfully.
 */
export const getSelectedPosDeviceCreateCompleted = createSelector(
  selectPosDevicesState,
  (state: PosDevicesState) => state.isCreateCompleted
);

/**
 * Determines if there is a running update process.
 */
export const getSelectedPosDeviceUpdating = createSelector(
  selectPosDevicesState,
  (state: PosDevicesState) => state.isUpdating
);

/**
 * Determines if the last update process has been ended successfully.
 */
export const getSelectedPosDeviceUpdateCompleted = createSelector(
  selectPosDevicesState,
  (state: PosDevicesState) => state.isUpdateCompleted
);

/**
 * Determines if there is a running delete process.
 */
export const getSelectedPosDeviceDeleting = createSelector(
  selectPosDevicesState,
  (state: PosDevicesState) => state.isDeleting
);

/**
 * Determines if the last delete process has been ended successfully.
 */
export const getSelectedPosDeviceDeleteCompleted = createSelector(
  selectPosDevicesState,
  (state: PosDevicesState) => state.isDeleteCompleted
);
