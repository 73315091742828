import { Action } from '@ngrx/store';

import { Nationality } from 'lookups/models';
import { AppHttpResponse } from 'shared';

/**
 * The Lookups-Module store  nationality action types.
 */
export enum NationalityActionType {
  GET_ALL_NATIONALITY = '[Lookups] [Nationality] Get All Nationalities',
  GET_ALL_NATIONALITY_FAIL = '[Lookups] [Nationality] Get All Nationalities Fail',
  GET_ALL_NATIONALITY_SUCCESS = '[Lookups] [Nationality] Get All Nationalities Success',
}

/**
 * Represents a store  nationality action.
 */
export class GetAllNationality implements Action {
  /**
   * The type of the action.
   */
  readonly type = NationalityActionType.GET_ALL_NATIONALITY;

  /**
   * Fires a new  nationality action.
   */
  constructor() {}
}

/**
 * Represents a store  nationality fail action.
 */
export class GetAllNationalityFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = NationalityActionType.GET_ALL_NATIONALITY_FAIL;

  /**
   * Fires a new  nationality fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store  nationality success action.
 */
export class GetAllNationalitySuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = NationalityActionType.GET_ALL_NATIONALITY_SUCCESS;

  /**
   * Fires a  nationality success action.
   * @param payload An object contains the list of  nationalities.
   */
  constructor(public payload: AppHttpResponse<Nationality[]>) {}
}

/**
 * Lookups-module  nationality action types.
 */
export type NationalityActions = GetAllNationality | GetAllNationalityFail | GetAllNationalitySuccess;
