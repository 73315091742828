import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';

import * as fromLookupsStore from 'lookups/store';
import { CustomerEngagementType } from 'lookups/models';
import { APP_CONSTANTS, CustomValidators } from 'shared';

@Component({
  selector: 'app-customer-engagements-form',
  templateUrl: './customer-engagements-form.component.html',
  styles: [],
})
export class CustomerEngagementsFormComponent implements OnInit {
  /**
   * The confirm modal template reference.
   */
  @ViewChild('deleteModalRef') deleteModalRef: ElementRef<any>;

  /**
   * The decimal mask.
   */
  readonly DECIMAL_MASK = APP_CONSTANTS.numeric.decimal.mask;

  /**
   * Sets the engagements form array.
   */
  @Input() engagementsForm: FormArray;

  /**
   * The set of customer engagement types.
   */
  engagementTypes$: Observable<CustomerEngagementType[]>;

  /**
   * Indicates that a drop-down field in the form is opened and therefore the table height should be expanded.
   */
  isDropDownOpened: boolean;

  /**
   * @param modalService The modal service.
   * @param lookupsStore$ The lookups-module store.
   */
  constructor(private modalService: NgbModal, private lookupsStore$: Store<fromLookupsStore.LookupsState>) {}

  ngOnInit(): void {
    this.init();
  }

  /**
   * Initialize component data.
   */
  init() {
    /** Loads data. */

    let isManualSearchTriggeredBeforeForTypes = false;
    this.engagementTypes$ = this.lookupsStore$.pipe(
      select(fromLookupsStore.getCustomerEngagementTypes),
      tap((types) => {
        if (!isManualSearchTriggeredBeforeForTypes && !types.length) {
          isManualSearchTriggeredBeforeForTypes = true;
          this.lookupsStore$.dispatch(new fromLookupsStore.GetAllCustomerEngagementType());
        }
      })
    );
  }

  /**
   * Adds a new engagement group to the engagements form array.
   */
  add(): void {
    const engagementFormGroup = new FormGroup({
      customerEngagementTypeId: new FormControl(null, Validators.required),
      value: new FormControl('', [Validators.required, CustomValidators.gte(0)]),
      notes: new FormControl('', [Validators.minLength(0), Validators.maxLength(200)]),
    });

    this.engagementsForm.push(engagementFormGroup);
  }

  /**
   * Opens the modal of the given templateRef.
   * @param modalRef The modal templateRef to be opened.
   */
  openModal(modalRef) {
    this.modalService.open(modalRef);
  }

  /**
   * Open confirm delete all engagements modal .
   */
  openDeleteModal() {
    this.openModal(this.deleteModalRef);
  }

  /**
   * Remove the customer engagements at the given index from the list.
   * @param index The index of the customer engagements to be removed.
   */
  remove(index: number) {
    this.engagementsForm.removeAt(index);
  }

  /**
   * Remove all of the list of customer engagements.
   */
  reset() {
    this.engagementsForm.clear();
  }
}
