import { Injectable } from '@angular/core';

import { of } from 'rxjs';
import { switchMap, map, catchError, tap } from 'rxjs/operators';
import { Effect, ofType, Actions } from '@ngrx/effects';

import { NotificationService, StatusCode, PAGINATION, TranslationService } from 'shared';
import { SectorsService } from 'stores/services';
import {
  CreateSector,
  CreateSectorFail,
  CreateSectorSuccess,
  DeleteSector,
  DeleteSectorFail,
  DeleteSectorSuccess,
  FindSector,
  FindSectorFail,
  FindSectorSuccess,
  SearchSectors,
  SearchSectorsFail,
  SearchSectorsSuccess,
  SectorsActionType,
  UpdateSector,
  UpdateSectorFail,
  UpdateSectorSuccess,
} from 'stores/store/actions';

@Injectable()
export class SectorsEffects {
  constructor(
    private actions$: Actions,
    private sectorsService: SectorsService,
    private notificationService: NotificationService,
    private translationService: TranslationService
  ) {}

  /* ========================= SEARCH_SECTORS =================================== */
  @Effect()
  search$ = this.actions$.pipe(
    ofType(SectorsActionType.SEARCH_SECTORS),
    switchMap(({ payload }: SearchSectors) =>
      this.sectorsService.search(payload.name, payload.page, PAGINATION.Sectors).pipe(
        map((response) => new SearchSectorsSuccess(response)),
        catchError((error) => of(new SearchSectorsFail(error)))
      )
    )
  );

  /* ========================= FIND_SECTOR =================================== */
  @Effect()
  find$ = this.actions$.pipe(
    ofType(SectorsActionType.FIND_SECTOR),
    switchMap((action: FindSector) =>
      this.sectorsService.findById(action.payload).pipe(
        map((response) => new FindSectorSuccess(response)),
        catchError((error) => of(new FindSectorFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  findFail$ = this.actions$.pipe(
    ofType(SectorsActionType.FIND_SECTOR_FAIL),
    tap((action: FindSectorFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(this.translationService.translate('STORES.SECTORS.SECTOR_NOT_FOUND'));
      }
    })
  );

  /* ========================= CREATE_SECTOR =================================== */
  @Effect()
  create$ = this.actions$.pipe(
    ofType(SectorsActionType.CREATE_SECTOR),
    switchMap((action: CreateSector) =>
      this.sectorsService.create(action.payload).pipe(
        map((response) => new CreateSectorSuccess(response)),
        catchError((error) => of(new CreateSectorFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  createSuccess$ = this.actions$.pipe(
    ofType(SectorsActionType.CREATE_SECTOR_SUCCESS),
    tap((action: CreateSectorSuccess) => {
      this.notificationService.success(this.translationService.translate('STORES.SECTORS.SECTOR_ADDED'));
    })
  );

  @Effect({ dispatch: false })
  createFail$ = this.actions$.pipe(
    ofType(SectorsActionType.CREATE_SECTOR_FAIL),
    tap((action: CreateSectorFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );

  /* ========================= UPDATE_SECTOR =================================== */
  @Effect()
  update$ = this.actions$.pipe(
    ofType(SectorsActionType.UPDATE_SECTOR),
    switchMap((action: UpdateSector) =>
      this.sectorsService.update(action.payload).pipe(
        map((response) => new UpdateSectorSuccess(response)),
        catchError((error) => of(new UpdateSectorFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  updateSuccess$ = this.actions$.pipe(
    ofType(SectorsActionType.UPDATE_SECTOR_SUCCESS),
    tap((action: UpdateSectorSuccess) => {
      this.notificationService.success(this.translationService.translate('STORES.SECTORS.SECTOR_UPDATED'));
    })
  );

  @Effect({ dispatch: false })
  updateFail$ = this.actions$.pipe(
    ofType(SectorsActionType.UPDATE_SECTOR_FAIL),
    tap((action: UpdateSectorFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(this.translationService.translate('STORES.SECTORS.SECTOR_NOT_FOUND'));
      } else if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );

  /* ========================= DELETE_SECTOR =================================== */
  @Effect()
  delete$ = this.actions$.pipe(
    ofType(SectorsActionType.DELETE_SECTOR),
    switchMap((action: DeleteSector) =>
      this.sectorsService.delete(action.payload).pipe(
        map((response) => new DeleteSectorSuccess(response)),
        catchError((error) => of(new DeleteSectorFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  deleteSuccess$ = this.actions$.pipe(
    ofType(SectorsActionType.DELETE_SECTOR_SUCCESS),
    tap((action: DeleteSectorSuccess) => {
      this.notificationService.success(this.translationService.translate('STORES.SECTORS.SECTOR_DELETED'));
    })
  );

  @Effect({ dispatch: false })
  deleteFail$ = this.actions$.pipe(
    ofType(SectorsActionType.DELETE_SECTOR_FAIL),
    tap((action: DeleteSectorFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(this.translationService.translate('STORES.SECTORS.SECTOR_NOT_FOUND'));
      } else if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );
}
