import { createSelector } from '@ngrx/store';

import { BoardsState, SalesState } from 'sales/store/states';
import { selectSalesState } from 'sales/store/selectors/feature.selectors';

/**
 * Gets the Boards state.
 */
const selectBoardState = createSelector(selectSalesState, (state: SalesState) => state.boards);

/**
 * Gets the list of loaded boards.
 */
export const getBoards = createSelector(selectBoardState, (state: BoardsState) => state.data);

/**
 * Gets the boards pagination info.
 */
export const getBoardsPaginationInfo = createSelector(selectBoardState, (state: BoardsState) => state.paginationInfo);

/**
 * Gets the error that occurred in the state.
 */
export const getBoardsError = createSelector(selectBoardState, (state: BoardsState) => state.error);

/**
 * Determines if there is a running search process.
 */
export const getBoardsSearching = createSelector(selectBoardState, (state: BoardsState) => state.isSearching);

/**
 * Determines if the last search process has been ended successfully.
 */
export const getBoardsSearchCompleted = createSelector(
  selectBoardState,
  (state: BoardsState) => state.isSearchCompleted
);

/**
 * Determines if there is a running search in pos process.
 */
export const getBoardsSearchingInPos = createSelector(selectBoardState, (state: BoardsState) => state.isSearchingInPos);

/**
 * Determines if the last search in pos process has been ended successfully.
 */
export const getBoardsSearchInPosCompleted = createSelector(
  selectBoardState,
  (state: BoardsState) => state.isSearchInPosCompleted
);

/**
 * Gets the selected board.
 */
export const getSelectedBoard = createSelector(selectBoardState, (state: BoardsState) => state.selectedBoard);

/**
 * Determines if there is a running find board process.
 */
export const getBoardsFinding = createSelector(selectBoardState, (state: BoardsState) => state.isFinding);

/**
 * Determines if the last find board process has been ended successfully.
 */
export const getBoardsFindCompleted = createSelector(selectBoardState, (state: BoardsState) => state.isFindCompleted);

/**
 * Determines if there is a running create process.
 */
export const getSelectedBoardCreating = createSelector(selectBoardState, (state: BoardsState) => state.isCreating);

/**
 * Determines if the last create process has been ended successfully.
 */
export const getSelectedBoardCreateCompleted = createSelector(
  selectBoardState,
  (state: BoardsState) => state.isCreateCompleted
);

/**
 * Determines if there is a running update process.
 */
export const getSelectedBoardUpdating = createSelector(selectBoardState, (state: BoardsState) => state.isUpdating);

/**
 * Determines if the last update process has been ended successfully.
 */
export const getSelectedBoardUpdateCompleted = createSelector(
  selectBoardState,
  (state: BoardsState) => state.isUpdateCompleted
);

/**
 * Determines if there is a running delete process.
 */
export const getSelectedBoardDeleting = createSelector(selectBoardState, (state: BoardsState) => state.isDeleting);

/**
 * Determines if the last delete process has been ended successfully.
 */
export const getSelectedBoardDeleteCompleted = createSelector(
  selectBoardState,
  (state: BoardsState) => state.isDeleteCompleted
);
