import { Action } from '@ngrx/store';

import { SaleInvoice } from 'sales/models';
import { UpdatePosInvoiceInput } from 'sales/models/invoices/update-pos-invoice.model';
import { AppHttpResponse } from 'shared';

/**
 * The Sales-Module store pending pos invoices action types.
 */
export enum PendingPosInvoicesActionType {
  PREPARE_POINT_OF_SALE_INVOICES = '[Sales] [SaleInvoices] Prepare Point Of Sale Invoices',
  PREPARE_POINT_OF_SALE_INVOICES_FAIL = '[Sales] [SaleInvoices] Prepare Point Of Sale Invoices Fail',
  PREPARE_POINT_OF_SALE_INVOICES_SUCCESS = '[Sales] [SaleInvoices] Prepare Point Of Sale Invoices Success',

  UPDATE_PREPARE_POINT_OF_SALE_INVOICE = '[Sales] [SaleInvoices] Update Prepare Point Of Sale Invoice',
  UPDATE_PREPARE_POINT_OF_SALE_INVOICE_FAIL = '[Sales] [SaleInvoices] Update Prepare Point Of Sale Invoice Fail',
  UPDATE_PREPARE_POINT_OF_SALE_INVOICE_SUCCESS = '[Sales] [SaleInvoices] Update Prepare Point Of Sale Invoice Success',
}

/**
 * Represents a store pending pos invoices  prepare  action.
 */
export class PreparePointOfSaleInvoices implements Action {
  /**
   * The type of the action.
   */
  readonly type = PendingPosInvoicesActionType.PREPARE_POINT_OF_SALE_INVOICES;

  /**
   * Fires a new point of sale invoices of prepare action.
   * @param payload the prepare parameters,
   * if omitted, all point of sale invoices will be loaded.
   */
  constructor(
    public payload: {
      locations: number[];
      productClasses: number[];
      page: number;
    }
  ) {}
}

/**
 * Represents a store point of sale invoices prepare-fail action.
 */
export class PreparePointOfSaleInvoicesFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = PendingPosInvoicesActionType.PREPARE_POINT_OF_SALE_INVOICES_FAIL;

  /**
   * Fires a new point of sale invoices prepare-fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store point of sale invoices prepare-success action.
 */
export class PreparePointOfSaleInvoicesSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = PendingPosInvoicesActionType.PREPARE_POINT_OF_SALE_INVOICES_SUCCESS;

  /**
   * Fires a new point of sale invoices prepare-success action.
   * @param payload An object contains the list of point of sale invoices that matches the prepare criteria
   */
  constructor(public payload: AppHttpResponse<SaleInvoice[]>) {}
}

/**
 * Represents a store point of sale invoice update action.
 */
export class UpdatePreparePointOfSaleInvoice implements Action {
  /**
   * The type of the action.
   */
  readonly type = PendingPosInvoicesActionType.UPDATE_PREPARE_POINT_OF_SALE_INVOICE;

  /**
   * Fires a new update  action.
   * @param payload The point of sale invoice's id.
   */
  constructor(public payload: UpdatePosInvoiceInput) {}
}

/**
 * Represents a store point of sale invoice update-fail action.
 */
export class UpdatePreparePointOfSaleInvoiceFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = PendingPosInvoicesActionType.UPDATE_PREPARE_POINT_OF_SALE_INVOICE_FAIL;

  /**
   * Fires a point of sale invoice update-fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store point of sale invoice update-success action.
 */
export class UpdatePreparePointOfSaleInvoiceSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = PendingPosInvoicesActionType.UPDATE_PREPARE_POINT_OF_SALE_INVOICE_SUCCESS;

  /**
   * Fires point of sale invoice update-success action.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Sales-module sale invoices action types.
 */
export type PendingPosInvoicesActions =
  | PreparePointOfSaleInvoices
  | PreparePointOfSaleInvoicesSuccess
  | PreparePointOfSaleInvoicesFail
  | UpdatePreparePointOfSaleInvoice
  | UpdatePreparePointOfSaleInvoiceSuccess
  | UpdatePreparePointOfSaleInvoiceFail;
