import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';

import { Observable, Subscription } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { skip, tap } from 'rxjs/operators';

import * as fromStoresStore from 'stores/store';
import * as fromSettingsStore from 'settings/store';
import { APP_CONSTANTS, CustomValidators } from 'shared';
import { Product, ProductsFormItem, ProductsFormPOSUtil, UnitOfMeasure } from 'stores';
import { Claims } from 'security';
import { Tax } from 'settings';
import { TouchKeyboardConfig } from 'touch-keyboard';

@Component({
  selector: 'app-point-of-sale-invoice-products-form',
  templateUrl: './point-of-sale-invoice-products-form.component.html',
})
export class PointOfSaleInvoiceProductsFormComponent implements OnInit, OnDestroy {
  /**
   * Sets the products form-array.
   */
  @Input() formArray: FormArray;

  /**
   * Sets the name form-array in its parent form.
   * @default 'products'
   */
  @Input() formArrayName = 'products';

  /**
   * Adds a list of new product s to the products form.
   * @param products The list of product s to be added to the products form.
   */
  @Input() set newProducts(products: Product[]) {
    if (!products?.length) {
      return;
    }

    products.forEach((product) => this.createProductFormGroup(this.createProductFormItemFromProduct(product)));
  }

  /**
   * Gets the id of selected location .
   */
  @Input() selectedLocationId: number;

  /**
   * Adds a list of new product form items to the products form.
   * @param items The list of product form items to be added to the products form.
   */
  @Input() set newProductItems(items: ProductsFormItem[]) {
    if (!items?.length) {
      return;
    }

    items.forEach((item) => this.createProductFormGroup(item));
  }

  /**
   * Gets or sets the touch keyboard configurations.
   */
  @Input() touchKeyboardConfig;

  /**
   * Allow update config value from child component.
   */
  @Output() touchKeyboardConfigChange = new EventEmitter<TouchKeyboardConfig>();

  /**
   * The decimal mask.
   */
  readonly DECIMAL_MASK = APP_CONSTANTS.numeric.decimal.mask;

  /**
   * Indicates that a drop-down field in the form is opened and therefore the table height should be expanded.
   */
  isDropDownOpened: boolean;

  /**
   * Gets the list of taxes.
   */
  taxes$: Observable<Tax[]>;

  /**
   * Gets all units of measure.
   */
  unitsOfMeasure: UnitOfMeasure[] = [];

  /**
   * The system supported user claims.
   */
  Claims = Claims;

  /**
   * The set of subscriptions on this components,
   * these subscriptions must be unsubscribed before this component got destroyed.
   */
  subscriptions = new Subscription();

  /**
   * Gets  the products total without discount and tax.
   */
  get productsTotalWithoutDiscountAndTax(): number {
    return ProductsFormPOSUtil.productsTotal(this.formArray.controls.map((item) => item.value));
  }

  /**
   * Gets the sum of selected products taxes.
   */
  get productsTax(): number {
    return ProductsFormPOSUtil.productsTax(this.formArray.controls.map((item) => item.value));
  }

  /**
   * Gets the sum of discount values.
   */
  get productsDiscount(): number {
    return ProductsFormPOSUtil.productsDiscount(this.formArray.controls.map((item) => item.value));
  }

  /**
   * Gets the total quantity of selected products.
   */
  get productsQuantity(): number {
    return ProductsFormPOSUtil.productsQuantity(this.formArray.controls.map((item) => item.value));
  }

  /**
   * Gets net value of the invoice.
   */
  get invoiceNet(): number {
    return ProductsFormPOSUtil.invoiceNet(this.formArray.controls.map((item) => item.value));
  }

  /**
   * @param settingsStore$ settings-module store.
   * @param storesStore$ stores-module store.
   */
  constructor(
    private settingsStore$: Store<fromSettingsStore.SettingsState>,
    private storesStore$: Store<fromStoresStore.StoresState>
  ) {}

  ngOnInit(): void {
    this.init();
  }

  /**
   * Destroy component data
   */
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  /**
   * Initialize component data.
   */
  init() {
    /**
     * Load data.
     */
    let isManualSearchTriggeredBeforeForTaxes = false;
    this.taxes$ = this.settingsStore$.pipe(
      select(fromSettingsStore.getTaxes),
      tap((taxes) => {
        if (!isManualSearchTriggeredBeforeForTaxes && !taxes.length) {
          isManualSearchTriggeredBeforeForTaxes = true;
          this.settingsStore$.dispatch(new fromSettingsStore.SearchTaxes({ name: '', page: 1 }));
        }
      })
    );

    /**
     * Get all units of measure.
     */
    this.subscriptions.add(
      this.storesStore$
        .pipe(
          select(fromStoresStore.getUnitsOfMeasure),
          skip(1),
          tap((units) => {
            this.unitsOfMeasure = units;
          })
        )
        .subscribe()
    );
    this.storesStore$.dispatch(new fromStoresStore.GetAllUnitsOfMeasure());
  }

  /**
   * Creates and returns a new product form item from a given product.
   * If the product was omitted a non-storable product will be created.
   * @param product The product to be used to create the product form item.
   */
  createProductFormItemFromProduct(product?: Product): ProductsFormItem {
    let valueWithTax = 0;
    if (product.hasSubProducts) {
      valueWithTax = product.subProducts?.[0]?.salePrice ?? 0;
    } else {
      valueWithTax = product.salePrice;
    }

    return {
      isStorable: product && !product?.isService,
      isService: product?.isService,
      isNonStorable: !product,
      product,
      description: product ? null : '',
      unitOfMeasureId: product?.unitOfMeasureId ?? null,
      subProductId: product.hasSubProducts ? product.subProducts?.[0]?.id : null,
      quantity: 1,
      value: valueWithTax,
      tax: product?.tax ?? null,
      notes: product?.notes ?? '',
      serialNumber: product?.hasSerialNumber ? '' : null,
    };
  }

  /**
   * Create a new product form group from the provided product form item.
   * @param item The product form item that contains data about the new product.
   */
  createProductFormGroup(item: ProductsFormItem) {
    let existingProductFormGroup: FormGroup;
    if (item.product && !item.product.hasSerialNumber) {
      existingProductFormGroup = this.formArray.controls.find((ctrl) => {
        const value = ctrl.value as ProductsFormItem;
        return (
          (value.isStorable || value.isService) &&
          value.product?.id === item.product?.id &&
          !value.product.hasSubProducts
        );
      }) as FormGroup;
    }

    if ((item.isStorable || item.isService) && existingProductFormGroup) {
      existingProductFormGroup.patchValue({ quantity: (existingProductFormGroup.value.quantity || 0) + 1 });
      return;
    }
    const subProductIdFormControl = new FormControl(
      item.subProductId,
      item.product?.hasSubProducts ? [Validators.required] : []
    );

    const productSalesDiscount = item.product.productSalesDiscounts?.find(
      (discount) => discount.locationId === this.selectedLocationId
    );

    const unitOfMeasureIdFormControl = new FormControl(item.unitOfMeasureId, Validators.required);

    const formGroup = new FormGroup({
      isStorable: new FormControl(item.isStorable ?? false),
      isService: new FormControl(item.isService ?? false),
      isNonStorable: new FormControl(item.isNonStorable ?? false),
      isPointsExchange: new FormControl(item.isPointsExchange ?? false),
      product: new FormControl(item.product),
      description: new FormControl(
        item.description,
        item.isNonStorable ? [Validators.required, Validators.minLength(1), Validators.maxLength(100)] : []
      ),
      subProductId: subProductIdFormControl,
      quantity: new FormControl(item.quantity, [Validators.required, CustomValidators.gt(0)]),
      unitOfMeasureId: unitOfMeasureIdFormControl,
      discount: new FormControl(productSalesDiscount?.discount ?? 0, CustomValidators.gte(0)),
      discountPercent: new FormControl(productSalesDiscount?.discountPercent ?? 0, CustomValidators.range([0, 100])),
      value: new FormControl(item.value, [Validators.required, CustomValidators.gte(0)]),
      tax: new FormControl(item.tax, [Validators.required]),
      notes: new FormControl(item.notes ?? '', [Validators.minLength(0), Validators.maxLength(200)]),
      serialNumber: new FormControl(
        item.serialNumber,
        item.product?.hasSerialNumber ? [Validators.required, Validators.minLength(1), Validators.maxLength(50)] : []
      ),
    });

    /**
     * Only if the product had sub-products.
     */
    if (item.product?.hasSubProducts) {
      this.subscriptions.add(
        subProductIdFormControl.valueChanges.subscribe(() => this.onSubProductIdChange(formGroup))
      );
    }

    /**
     * Only if the product is a db-product.
     */
    if (item.product) {
      this.subscriptions.add(
        unitOfMeasureIdFormControl.valueChanges.subscribe(() => this.onUnitOfMeasureIdChange(formGroup))
      );
    }

    this.formArray.push(formGroup);

    /**
     * Scroll to the last added product.
     * Register the new product quantity as the target for the touch keyboard.
     */
    setTimeout(() => {
      const productRow = document.getElementById(`product-row-${this.formArray.length}`);
      productRow.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });

      const productQuantityInput = document.getElementById(`product-${this.formArray.length}-quantity`);
      this.touchKeyboardConfigChange.emit({
        ...this.touchKeyboardConfig,
        activeInput: productQuantityInput as HTMLInputElement,
      });
    }, 0);
  }

  /**
   * Remove the product at the given index from the products form.
   * @param index The index of the product form group.
   */
  removeProduct(index: number) {
    this.formArray.removeAt(index);
  }

  /**
   * Returns the total value of the given product item.
   * @param item The product item.
   */
  calculateProductTotal(item: ProductsFormItem): number {
    return ProductsFormPOSUtil.invoiceNet([
      {
        quantity: item.quantity,
        tax: item.tax,
        value: item.value,
        discount: item.discount,
        discountPercent: item.discountPercent,
        unitOfMeasureId: undefined,
      },
    ]);
  }

  /**
   * Handles the change in the selected sub product.
   * @param formGroup The product form-group.
   */
  onSubProductIdChange(formGroup: FormGroup): void {
    const product: Product = formGroup.value.product;
    const subProductId: number = formGroup.get('subProductId').value;
    let value = 0;
    if (subProductId) {
      const subProduct = product.subProducts.find((item) => item.id === subProductId);
      if (product.hasSubProducts) {
        value = subProduct?.salePrice ?? 0;
      } else {
        value = product.salePrice;
      }
    }

    /**
     * Update product value.
     */
    formGroup.patchValue({ value });

    /**
     * Update value to match the selected unit of measure.
     */
    this.onUnitOfMeasureIdChange(formGroup);
  }

  /**
   * Handles the change in the selected UOM.
   * @param formGroup The product form-group.
   */
  onUnitOfMeasureIdChange(formGroup: FormGroup): void {
    const product: Product = formGroup.value.product;
    const subProductId: number = formGroup.get('subProductId').value;
    const unitOfMeasureId: number = formGroup.get('unitOfMeasureId').value;

    let value = 0;
    if (subProductId) {
      const subProduct = product.subProducts.find((item) => item.id === subProductId);

      if (product.hasSubProducts) {
        value = subProduct?.salePrice ?? 0;
      } else {
        value = product.salePrice;
      }
    } else {
      value = product.salePrice;
    }
    /**
     * Calculate unit of measure rate.
     */
    if (product && product.unitOfMeasureId !== unitOfMeasureId) {
      /**
       * The unit of measure rate.
       */
      const uomRate =
        product.productUnitOfMeasureRates?.find((rate) => rate.unitOfMeasureId === unitOfMeasureId)?.rate ?? 1;

      value = value * uomRate;
    }

    /**
     * Update product value.
     */
    formGroup.patchValue({ value });
  }

  /**
   * Returns the list of suitable units related to the product.
   * @param product The product if exists.
   * @returns A suitable list of units related to the product.
   */
  getProductUnitsOfMeasure(product?: Product): UnitOfMeasure[] {
    const units: UnitOfMeasure[] = [];

    if (!product) {
      units.push(...this.unitsOfMeasure);
    } else {
      units.push(product.unitOfMeasure, ...product.productUnitOfMeasureRates.map((item) => item.unitOfMeasure));
    }

    return units;
  }
}
