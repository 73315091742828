import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';

import { AppHttpResponse } from 'shared';
import { Location, UpdateLocationInput, CreateLocationInput } from 'stores/models';

/**
 * The locations services includes functionality to search, findById, create,  update and delete a location.
 */
@Injectable()
export class LocationsService {
  /**
   * The relative route for the locations.
   *
   * No leading or trailing slashes required.
   */
  private locationsApi = 'stores/locations';

  constructor(private http: HttpClient) {}

  /**
   * Searches in the locations by name.
   * @param name The name of the location.
   * @param regions The regions of the location.
   * @param sectors The sectors of the location.
   * @param locationTypes The location types of the location.
   * @param page The current pagination page number.
   * @param pageSize The maximum number of locations allowed per one pagination page.
   */
  public search(
    name: string,
    regions: number[],
    sectors: number[],
    locationTypes: number[],
    page: number,
    pageSize: number
  ): Observable<AppHttpResponse<Location[]>> {
    const params = new HttpParams()
      .set('name', name)
      .set('regions', regions.join(','))
      .set('sectors', sectors.join(','))
      .set('locationTypes', locationTypes.join(','))
      .set('page', page.toString())
      .set('pageSize', pageSize.toString());
    return this.http.get<AppHttpResponse<Location[]>>(`${this.locationsApi}`, { params });
  }

  /**
   * Searches in the locations by name.
   * @param name The name of the location.
   * @param regions The regions of the location.
   * @param sectors The sectors of the location.
   * @param locationTypes The location types of the location.
   * @param page The current pagination page number.
   * @param pageSize The maximum number of locations allowed per one pagination page.
   */
  public organizationLocationsSearch(
    name: string,
    regions: number[],
    sectors: number[],
    locationTypes: number[],
    page: number,
    pageSize: number
  ): Observable<AppHttpResponse<Location[]>> {
    const params = new HttpParams()
      .set('name', name)
      .set('regions', regions.join(','))
      .set('sectors', sectors.join(','))
      .set('locationTypes', locationTypes.join(','))
      .set('page', page.toString())
      .set('pageSize', pageSize.toString());
    return this.http.get<AppHttpResponse<Location[]>>(`${this.locationsApi}/organization-locations`, { params });
  }

  /**
   * Finds the location with the given id.
   * @param id The id of the location.
   */
  public findById(id: number): Observable<AppHttpResponse<Location>> {
    return this.http.get<AppHttpResponse<Location>>(`${this.locationsApi}/${id}`);
  }

  /**
   * Creates a new location from the provided data.
   * @param data The new location data.
   */
  public create(data: CreateLocationInput): Observable<AppHttpResponse<Location>> {
    const formData: any = new FormData();
    formData.append('code', data.code);
    formData.append('name', data.name);
    formData.append('nameEn', data.nameEn);
    formData.append('regionId', data.regionId);
    formData.append('sectorId', data.sectorId);
    formData.append('locationTypeId', data.locationTypeId);
    formData.append('costCenterId', data.costCenterId);
    formData.append('representorName', data.representorName);
    formData.append('representorMobile', data.representorMobile);
    formData.append('buildingNo', data.buildingNo);
    formData.append('streetName', data.streetName);
    formData.append('district', data.district);
    formData.append('cityId', data.cityId);
    formData.append('country', data.country);
    formData.append('postalCode', data.postalCode);
    formData.append('additionalNo', data.additionalNo);
    formData.append('otherSellerId', data.otherSellerId);
    formData.append('saleInvoicePrintPaperSizeId', data.saleInvoicePrintPaperSizeId);
    formData.append('saleReturnPrintPaperSizeId', data.saleReturnPrintPaperSizeId);
    formData.append('showLogoInPrintSaleInvoices', data.showLogoInPrintSaleInvoices);
    formData.append('showDisplayNameInPrintSaleInvoices', data.showDisplayNameInPrintSaleInvoices);
    formData.append('showNotesInPrintSaleInvoices', data.showNotesInPrintSaleInvoices);
    formData.append('showProductNotesInPrintSaleInvoices', data.showProductNotesInPrintSaleInvoices);
    formData.append('showProductItemCodeInPrintSaleInvoices', data.showProductItemCodeInPrintSaleInvoices);
    formData.append('showLogoInPrintSalesReturn', data.showLogoInPrintSalesReturn);
    formData.append('showDisplayNameInPrintSalesReturn', data.showDisplayNameInPrintSalesReturn);
    formData.append('showLogoInPrintPurchaseInvoices', data.showLogoInPrintPurchaseInvoices);
    formData.append('showDisplayNameInPrintPurchaseInvoices', data.showDisplayNameInPrintPurchaseInvoices);
    formData.append('showNotesInPrintPurchaseInvoices', data.showNotesInPrintPurchaseInvoices);
    formData.append('showProductNotesInPrintPurchaseInvoices', data.showProductNotesInPrintPurchaseInvoices);
    formData.append('showProductItemCodeInPrintPurchaseInvoices', data.showProductItemCodeInPrintPurchaseInvoices);
    formData.append('showLogoInPrintPurchasesReturn', data.showLogoInPrintPurchasesReturn);
    formData.append('showDisplayNameInPrintPurchasesReturn', data.showDisplayNameInPrintPurchasesReturn);
    formData.append('allowSaleProductsWithoutStockFromPOS', data.allowSaleProductsWithoutStockFromPOS);
    formData.append('showInvoiceHeader', data.showInvoiceHeader);
    formData.append('showInvoiceFooter', data.showInvoiceFooter);
    formData.append('headerHeight', data.headerHeight);
    formData.append('footerHeight', data.footerHeight);
    formData.append('enableStockLogs', data.enableStockLogs);
    formData.append('autoCreateOutgoingStockForSaleInvoices', data.autoCreateOutgoingStockForSaleInvoices);
    formData.append('posDescription', data.posDescription);
    formData.append('printPaperSizeId', data.printPaperSizeId);
    formData.append('isAutoDelivery', data.isAutoDelivery);
    formData.append(
      'automaticallyShowPrintDialogForNewSaleInvoice',
      data.automaticallyShowPrintDialogForNewSaleInvoice
    );
    formData.append('primaryLogoText', data.primaryLogoText);
    formData.append('secondaryLogoText', data.secondaryLogoText);
    formData.append('footerText', data.footerText);
    formData.append('orgName', data.orgName);
    formData.append('vatNumber', data.vatNumber);

    if (data.primaryLogo) {
      formData.append('primaryLogo', data.primaryLogo, data.primaryLogo.name);
    }
    if (data.secondaryLogo) {
      formData.append('secondaryLogo', data.secondaryLogo, data.secondaryLogo.name);
    }

    if (data.invoiceHeaderFilename) {
      formData.append('invoiceHeaderFilename', data.invoiceHeaderFilename, data.invoiceHeaderFilename.name);
    }
    if (data.invoiceFooterFilename) {
      formData.append('invoiceFooterFilename', data.invoiceFooterFilename, data.invoiceFooterFilename.name);
    }

    return this.http.post<AppHttpResponse<Location>>(`${this.locationsApi}`, formData);
  }

  /**
   * Updates an existing location data using the provided data.
   * @param data The updated location data.
   */
  public update(data: UpdateLocationInput): Observable<AppHttpResponse<Location>> {
    return this.http.put<AppHttpResponse<Location>>(`${this.locationsApi}`, data);
  }

  /**
   * Deletes the location by given id.
   * @param id The id of the location.
   */
  public delete(id: number): Observable<AppHttpResponse<Location>> {
    return this.http.delete<AppHttpResponse<Location>>(`${this.locationsApi}/${id}`);
  }

  /**
   * Updates an existing location's primary logo.
   * @param id The id of the location.
   * @param primaryLogo The new location's primary logo.
   */
  public updatePrimaryLogo(id: number, primaryLogo: File): Observable<AppHttpResponse<Location>> {
    const formData: any = new FormData();
    formData.append('primaryLogo', primaryLogo, primaryLogo.name);

    return this.http.put<AppHttpResponse<Location>>(`${this.locationsApi}/primary-logo/${id}`, formData);
  }

  /**
   * Updates an existing location's image header.
   * @param id The id of the location.
   * @param invoiceHeaderFilename The new location's image header.
   */
  public updateImageHeader(id: number, invoiceHeaderFilename: File): Observable<AppHttpResponse<Location>> {
    const formData: any = new FormData();
    formData.append('invoiceHeaderFilename', invoiceHeaderFilename, invoiceHeaderFilename.name);

    return this.http.put<AppHttpResponse<Location>>(`${this.locationsApi}/invoice-header-image/${id}`, formData);
  }

  /**
   * Updates an existing location's image footer.
   * @param id The id of the location.
   * @param invoiceFooterFilename The new location's image footer.
   */
  public updateImageFooter(id: number, invoiceFooterFilename: File): Observable<AppHttpResponse<Location>> {
    const formData: any = new FormData();
    formData.append('invoiceFooterFilename', invoiceFooterFilename, invoiceFooterFilename.name);

    return this.http.put<AppHttpResponse<Location>>(`${this.locationsApi}/invoice-footer-image/${id}`, formData);
  }

  /**
   * Deletes the location primary logo by given location id.
   * @param id The id of the location.
   */
  public deletePrimaryLogo(id: number): Observable<AppHttpResponse<Location>> {
    return this.http.delete<AppHttpResponse<Location>>(`${this.locationsApi}/primary-logo/${id}`);
  }

  /**
   * Deletes the location image header by given location id.
   * @param id The id of the location.
   */
  public deleteImageHeader(id: number): Observable<AppHttpResponse<Location>> {
    return this.http.delete<AppHttpResponse<Location>>(`${this.locationsApi}/invoice-header-image/${id}`);
  }

  /**
   * Deletes the location image footer by given location id.
   * @param id The id of the location.
   */
  public deleteImageFooter(id: number): Observable<AppHttpResponse<Location>> {
    return this.http.delete<AppHttpResponse<Location>>(`${this.locationsApi}/invoice-footer-image/${id}`);
  }

  /**
   * Updates an existing location's secondary logo.
   * @param id The id of the location.
   * @param secondaryLogo The new location's secondary logo.
   */
  public updateSecondaryLogo(id: number, secondaryLogo: File): Observable<AppHttpResponse<Location>> {
    const formData: any = new FormData();
    formData.append('secondaryLogo', secondaryLogo, secondaryLogo.name);

    return this.http.put<AppHttpResponse<Location>>(`${this.locationsApi}/secondary-logo/${id}`, formData);
  }

  /**
   * Deletes the location secondary logo by given location id.
   * @param id The id of the location.
   */
  public deleteSecondaryLogo(id: number): Observable<AppHttpResponse<Location>> {
    return this.http.delete<AppHttpResponse<Location>>(`${this.locationsApi}/secondary-logo/${id}`);
  }
}
