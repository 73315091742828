import { findIndex, remove } from 'lodash';

import { UnitOfMeasureClassesActionType, UnitOfMeasureClassesActions } from 'stores/store/actions';
import { UnitOfMeasureClassesState } from 'stores/store/states';

/**
 * The initial state from which the state starts.
 */
const initialState: UnitOfMeasureClassesState = {
  data: [],
  paginationInfo: {
    total: 0,
    page: 1,
    pageSize: 0,
    count: 0,
  },
  error: null,
  isSearching: false,
  isSearchCompleted: false,
  selectedUnitOfMeasureClass: null,
  isFinding: false,
  isFindCompleted: false,
  isCreating: false,
  isCreateCompleted: false,
  isUpdating: false,
  isUpdateCompleted: false,
  isDeleting: false,
  isDeleteCompleted: false,
};

/**
 * The reducer function that is called in each action dispatch against the state.
 * @param state The current state.
 * @param action The action that will affect the state.
 */
export function unitOfMeasureClassesReducer(
  state: UnitOfMeasureClassesState = initialState,
  action: UnitOfMeasureClassesActions
): UnitOfMeasureClassesState {
  switch (action.type) {
    //#region SEARCH_UNIT_OF_MEASURE_CLASSES

    case UnitOfMeasureClassesActionType.SEARCH_UNIT_OF_MEASURE_CLASSES: {
      return {
        ...state,
        isSearching: true,
        isSearchCompleted: false,
        error: null,
      };
    }

    case UnitOfMeasureClassesActionType.SEARCH_UNIT_OF_MEASURE_CLASSES_FAIL: {
      return {
        ...state,
        error: action.payload,
        isSearching: false,
        isSearchCompleted: false,
      };
    }

    case UnitOfMeasureClassesActionType.SEARCH_UNIT_OF_MEASURE_CLASSES_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        paginationInfo: {
          ...action.payload.meta,
        },
        error: null,
        isSearching: false,
        isSearchCompleted: true,
      };
    }

    //#endregion SEARCH_UNIT_OF_MEASURE_CLASSES

    //#region FIND_UNIT_OF_MEASURE_CLASS

    case UnitOfMeasureClassesActionType.FIND_UNIT_OF_MEASURE_CLASS: {
      return {
        ...state,
        selectedUnitOfMeasureClass: null,
        error: null,
        isFinding: true,
        isFindCompleted: false,
      };
    }

    case UnitOfMeasureClassesActionType.FIND_UNIT_OF_MEASURE_CLASS_FAIL: {
      return {
        ...state,
        error: action.payload,
        isFinding: false,
        isFindCompleted: false,
      };
    }
    case UnitOfMeasureClassesActionType.FIND_UNIT_OF_MEASURE_CLASS_SUCCESS: {
      let unitsOfMeasureClass = [...state.data];
      const unitOfMeasureClassIndex = findIndex(
        unitsOfMeasureClass,
        (unitOfMeasureClass) => unitOfMeasureClass.id === action.payload.data.id
      );

      /* If unit of measure class was found, update it. */
      if (unitOfMeasureClassIndex >= 0) {
        unitsOfMeasureClass[unitOfMeasureClassIndex] = action.payload.data;
      } else {
        /* else, insert it to the beginning of the array. */
        unitsOfMeasureClass = [action.payload.data, ...unitsOfMeasureClass];
      }

      return {
        ...state,
        data: unitsOfMeasureClass,
        selectedUnitOfMeasureClass: action.payload.data,
        error: null,
        isFinding: false,
        isFindCompleted: true,
      };
    }

    //#endregion FIND_UNIT_OF_MEASURE_CLASS

    //#region CREATE_UNIT_OF_MEASURE_CLASS

    case UnitOfMeasureClassesActionType.CREATE_UNIT_OF_MEASURE_CLASS: {
      return {
        ...state,
        isCreating: true,
        isCreateCompleted: false,
        error: null,
      };
    }

    case UnitOfMeasureClassesActionType.CREATE_UNIT_OF_MEASURE_CLASS_FAIL: {
      return {
        ...state,
        error: action.payload,
        isCreating: false,
        isCreateCompleted: false,
      };
    }

    case UnitOfMeasureClassesActionType.CREATE_UNIT_OF_MEASURE_CLASS_SUCCESS: {
      return {
        ...state,
        data: [action.payload.data, ...state.data],
        selectedUnitOfMeasureClass: action.payload.data,
        error: null,
        isCreating: false,
        isCreateCompleted: true,
      };
    }

    //#endregion CREATE_UNIT_OF_MEASURE_CLASS

    //#region UPDATE_UNIT_OF_MEASURE_CLASS

    case UnitOfMeasureClassesActionType.UPDATE_UNIT_OF_MEASURE_CLASS: {
      return {
        ...state,
        isUpdating: true,
        isUpdateCompleted: false,
        error: null,
      };
    }

    case UnitOfMeasureClassesActionType.UPDATE_UNIT_OF_MEASURE_CLASS_FAIL: {
      return {
        ...state,
        error: action.payload,
        isUpdating: false,
        isUpdateCompleted: false,
      };
    }

    case UnitOfMeasureClassesActionType.UPDATE_UNIT_OF_MEASURE_CLASS_SUCCESS: {
      let unitsOfMeasureClass = [...state.data];
      const unitOfMeasureClassIndex = findIndex(
        unitsOfMeasureClass,
        (unitOfMeasureClass) => unitOfMeasureClass.id === action.payload.data.id
      );

      /* If unit of measure class was found, update it. */
      if (unitOfMeasureClassIndex >= 0) {
        unitsOfMeasureClass[unitOfMeasureClassIndex] = action.payload.data;
      } else {
        /* else, insert it to the beginning of the array. */
        unitsOfMeasureClass = [action.payload.data, ...unitsOfMeasureClass];
      }

      return {
        ...state,
        data: unitsOfMeasureClass,
        selectedUnitOfMeasureClass: action.payload.data,
        error: null,
        isUpdating: false,
        isUpdateCompleted: true,
      };
    }

    //#endregion UPDATE_UNIT_OF_MEASURE_CLASS

    //#region DELETE_UNIT_OF_MEASURE_CLASS

    case UnitOfMeasureClassesActionType.DELETE_UNIT_OF_MEASURE_CLASS: {
      return {
        ...state,
        isDeleting: true,
        isDeleteCompleted: false,
        error: null,
      };
    }

    case UnitOfMeasureClassesActionType.DELETE_UNIT_OF_MEASURE_CLASS_FAIL: {
      return {
        ...state,
        error: action.payload,
        isDeleting: false,
        isDeleteCompleted: false,
      };
    }

    case UnitOfMeasureClassesActionType.DELETE_UNIT_OF_MEASURE_CLASS_SUCCESS: {
      const unitsOfMeasureClass = [...state.data];

      /* Remove the deleted unit of measure class from the array. */
      remove(unitsOfMeasureClass, (unitOfMeasureClass) => unitOfMeasureClass.id === action.payload.data.id);

      return {
        ...state,
        data: unitsOfMeasureClass,
        selectedUnitOfMeasureClass: action.payload.data,
        error: null,
        isDeleting: false,
        isDeleteCompleted: true,
      };
    }

    //#endregion DELETE_CURRENCY_EXCHANGE_RATE

    default:
      return state;
  }
}
