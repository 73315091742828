import { Injectable } from '@angular/core';

import { of } from 'rxjs';
import { switchMap, map, catchError } from 'rxjs/operators';
import { Effect, ofType, Actions } from '@ngrx/effects';

import { LookupsService } from 'lookups/services';
import {
  VariantCategoryActionType,
  GetAllVariantCategoriesFail,
  GetAllVariantCategoriesSuccess,
} from 'lookups/store/actions';

@Injectable()
export class VariantCategoryEffects {
  constructor(private actions$: Actions, private lookupsService: LookupsService) {}

  /* ========================= GET_ALL_VARIANT_CATEGORIES =================================== */
  @Effect()
  getAllVariantCategories$ = this.actions$.pipe(
    ofType(VariantCategoryActionType.GET_ALL_VARIANT_CATEGORIES),
    switchMap(() =>
      this.lookupsService.getAllVariantCategories().pipe(
        map((response) => new GetAllVariantCategoriesSuccess(response)),
        catchError((error) => of(new GetAllVariantCategoriesFail(error)))
      )
    )
  );
}
