import { Injectable } from '@angular/core';

import { of } from 'rxjs';
import { switchMap, map, catchError, debounceTime } from 'rxjs/operators';
import { Effect, ofType, Actions } from '@ngrx/effects';

import { PAGINATION } from 'shared';
import { EmployeesService } from 'app-search/services';
import {
  EmployeesActionType,
  SearchEmployees,
  SearchEmployeesFail,
  SearchEmployeesSuccess,
  SearchSalesPersons,
  SearchSalesPersonsFail,
  SearchSalesPersonsSuccess,
} from 'app-search/store/actions';

@Injectable()
export class EmployeesEffects {
  constructor(private actions$: Actions, private employeesService: EmployeesService) {}

  /* ========================= SEARCH_EMPLOYEES =================================== */
  @Effect()
  search$ = this.actions$.pipe(
    ofType(EmployeesActionType.SEARCH_EMPLOYEES),
    debounceTime(300),
    switchMap(({ payload }: SearchEmployees) =>
      this.employeesService.search(payload?.name ?? '', payload.locations, payload.page, PAGINATION.Employees).pipe(
        map((response) => new SearchEmployeesSuccess(response)),
        catchError((error) => of(new SearchEmployeesFail(error)))
      )
    )
  );

  /* ========================= SEARCH_SALES_PERSONS =================================== */
  @Effect()
  salesPersonsSearch$ = this.actions$.pipe(
    ofType(EmployeesActionType.SEARCH_SALES_PERSONS),
    debounceTime(300),
    switchMap(({ payload }: SearchSalesPersons) =>
      this.employeesService
        .salesPersonSearch(payload?.name ?? '', payload.locations, payload.page, PAGINATION.SalesPersons)
        .pipe(
          map((response) => new SearchSalesPersonsSuccess(response)),
          catchError((error) => of(new SearchSalesPersonsFail(error)))
        )
    )
  );
}
