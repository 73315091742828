import { Action } from '@ngrx/store';

import { Religion } from 'lookups/models';
import { AppHttpResponse } from 'shared';

/**
 * The Lookups-Module store religion action types.
 */
export enum ReligionActionType {
  GET_ALL_RELIGIONS = '[Lookups] [Religion] Get All Religions',
  GET_ALL_RELIGIONS_FAIL = '[Lookups] [Religion] Get All Religions Fail',
  GET_ALL_RELIGIONS_SUCCESS = '[Lookups] [Religion] Get All Religions Success',
}

/**
 * Represents a store religion action.
 */
export class GetAllReligions implements Action {
  /**
   * The type of the action.
   */
  readonly type = ReligionActionType.GET_ALL_RELIGIONS;

  /**
   * Fires a new religion action.
   */
  constructor() {}
}

/**
 * Represents a store religion fail action.
 */
export class GetAllReligionsFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = ReligionActionType.GET_ALL_RELIGIONS_FAIL;

  /**
   * Fires a new religion fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store religion success action.
 */
export class GetAllReligionsSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = ReligionActionType.GET_ALL_RELIGIONS_SUCCESS;

  /**
   * Fires a religion success action.
   * @param payload An object contains the list of religions.
   */
  constructor(public payload: AppHttpResponse<Religion[]>) {}
}

/**
 * Lookups-module religion action types.
 */
export type ReligionActions = GetAllReligions | GetAllReligionsFail | GetAllReligionsSuccess;
