import { Action } from '@ngrx/store';

import { AppHttpResponse } from 'shared';
import { Report } from 'reports/models';

/**
 * The Printing-Module prints action types.
 */
export enum PrintsActionType {
  GET_INCOMING_STOCK_PRINT = '[Printing] [Prints] Get Incoming Stock Print',
  GET_INCOMING_STOCK_PRINT_FAIL = '[Printing] [Prints] Get Incoming Stock Print Fail',
  GET_INCOMING_STOCK_PRINT_RECEIVED = '[Printing] [Prints] Get Incoming Stock Print Received',
  GET_INCOMING_STOCK_PRINT_SUCCESS = '[Printing] [Prints] Get Incoming Stock Print Success',
  INCOMING_STOCK_PRINT_VIEW = '[Printing] [Prints] Incoming Stock Print View',

  GET_OUTGOING_STOCK_PRINT = '[Printing] [Prints] Get Outgoing Stock Print',
  GET_OUTGOING_STOCK_PRINT_FAIL = '[Printing] [Prints] Get Outgoing Stock Print Fail',
  GET_OUTGOING_STOCK_PRINT_RECEIVED = '[Printing] [Prints] Get Outgoing Stock Print Received',
  GET_OUTGOING_STOCK_PRINT_SUCCESS = '[Printing] [Prints] Get Outgoing Stock Print Success',
  OUTGOING_STOCK_PRINT_VIEW = '[Printing] [Prints] Outgoing Stock Print View',

  GET_TRANSFER_STOCK_PRINT = '[Printing] [Prints] Get Transfer Stock Print',
  GET_TRANSFER_STOCK_PRINT_FAIL = '[Printing] [Prints] Get Transfer Stock Print Fail',
  GET_TRANSFER_STOCK_PRINT_RECEIVED = '[Printing] [Prints] Get Transfer Stock Print Received',
  GET_TRANSFER_STOCK_PRINT_SUCCESS = '[Printing] [Prints] Get Transfer Stock Print Success',
  TRANSFER_STOCK_PRINT_VIEW = '[Printing] [Prints] Transfer Stock Print View',

  GET_PURCHASE_REQUEST_PRINT = '[Printing] [Prints] Get Purchase Request Print',
  GET_PURCHASE_REQUEST_PRINT_FAIL = '[Printing] [Prints] Get Purchase Request Print Fail',
  GET_PURCHASE_REQUEST_PRINT_RECEIVED = '[Printing] [Prints] Get Purchase Request Print Received',
  GET_PURCHASE_REQUEST_PRINT_SUCCESS = '[Printing] [Prints] Get Purchase Request Print Success',
  PURCHASE_REQUEST_PRINT_VIEW = '[Printing] [Prints] Purchase Request Print View',

  GET_PURCHASE_ORDER_PRINT = '[Printing] [Prints] Get Purchase Order Print',
  GET_PURCHASE_ORDER_PRINT_FAIL = '[Printing] [Prints] Get Purchase Order Print Fail',
  GET_PURCHASE_ORDER_PRINT_RECEIVED = '[Printing] [Prints] Get Purchase Order Print Received',
  GET_PURCHASE_ORDER_PRINT_SUCCESS = '[Printing] [Prints] Get Purchase Order Print Success',
  PURCHASE_ORDER_PRINT_VIEW = '[Printing] [Prints] Purchase Order Print View',

  GET_PURCHASE_INVOICE_PRINT = '[Printing] [Prints] Get Purchase Invoice Print',
  GET_PURCHASE_INVOICE_PRINT_FAIL = '[Printing] [Prints] Get Purchase Invoice Print Fail',
  GET_PURCHASE_INVOICE_PRINT_RECEIVED = '[Printing] [Prints] Get Purchase Invoice Print Received',
  GET_PURCHASE_INVOICE_PRINT_SUCCESS = '[Printing] [Prints] Get Purchase Invoice Print Success',
  PURCHASE_INVOICE_PRINT_VIEW = '[Printing] [Prints] Purchase Invoice Print View',

  GET_PURCHASE_RETURN_PRINT = '[Printing] [Prints] Get Purchase Return Print',
  GET_PURCHASE_RETURN_PRINT_FAIL = '[Printing] [Prints] Get Purchase Return Print Fail',
  GET_PURCHASE_RETURN_PRINT_RECEIVED = '[Printing] [Prints] Get Purchase Return Print Received',
  GET_PURCHASE_RETURN_PRINT_SUCCESS = '[Printing] [Prints] Get Purchase Return Print Success',
  PURCHASE_RETURN_PRINT_VIEW = '[Printing] [Prints] Purchase Return Print View',

  GET_SALE_INVOICE_PRINT = '[Printing] [Prints] Get Sale Invoice Print',
  GET_SALE_INVOICE_PRINT_FAIL = '[Printing] [Prints] Get Sale Invoice Print Fail',
  GET_SALE_INVOICE_PRINT_RECEIVED = '[Printing] [Prints] Get Sale Invoice Print Received',
  GET_SALE_INVOICE_PRINT_SUCCESS = '[Printing] [Prints] Get Sale Invoice Print Success',
  SALE_INVOICE_PRINT_VIEW = '[Printing] [Prints] Sale Invoice Print View',

  GET_SALE_RETURN_PRINT = '[Printing] [Prints] Get Sale Return Print',
  GET_SALE_RETURN_PRINT_FAIL = '[Printing] [Prints] Get Sale Return Print Fail',
  GET_SALE_RETURN_PRINT_RECEIVED = '[Printing] [Prints] Get Sale Return Print Received',
  GET_SALE_RETURN_PRINT_SUCCESS = '[Printing] [Prints] Get Sale Return Print Success',
  SALE_RETURN_PRINT_VIEW = '[Printing] [Prints] Sale Return Print View',

  GET_SALES_QUOTATION_PRINT = '[Printing] [Prints] Get Sales Quotation Print',
  GET_SALES_QUOTATION_PRINT_FAIL = '[Printing] [Prints] Get Sales Quotation Print Fail',
  GET_SALES_QUOTATION_PRINT_RECEIVED = '[Printing] [Prints] Get Sales Quotation Print Received',
  GET_SALES_QUOTATION_PRINT_SUCCESS = '[Printing] [Prints] Get Sales Quotation Print Success',
  SALES_QUOTATION_PRINT_VIEW = '[Printing] [Prints] Sales Quotation Print View',

  GET_JOURNAL_PRINT = '[Printing] [Prints] Get Journal Print',
  GET_JOURNAL_PRINT_FAIL = '[Printing] [Prints] Get Journal Print Fail',
  GET_JOURNAL_PRINT_RECEIVED = '[Printing] [Prints] Get Journal Print Received',
  GET_JOURNAL_PRINT_SUCCESS = '[Printing] [Prints] Get Journal Print Success',
  JOURNAL_PRINT_VIEW = '[Printing] [Prints] Journal Print View',
}

/**
 * Represents a incoming stock print action.
 */
export class GetIncomingStockPrint implements Action {
  /**
   * The type of the action.
   */
  readonly type = PrintsActionType.GET_INCOMING_STOCK_PRINT;

  /**
   * Fires a new incoming stock print action.
   */
  constructor(
    public payload: {
      incomingStockId: number;
      paperSize: string;
    }
  ) {}
}

/**
 * Represents a incoming stock print fail action.
 */
export class GetIncomingStockPrintFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = PrintsActionType.GET_INCOMING_STOCK_PRINT_FAIL;

  /**
   * Fires a new incoming stock print fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a incoming stock print received action.
 */
export class GetIncomingStockPrintReceived implements Action {
  /**
   * The type of the action.
   */
  readonly type = PrintsActionType.GET_INCOMING_STOCK_PRINT_RECEIVED;

  /**
   * Fires a incoming stock print received action.
   * @param payload Received acknowledgment.
   */
  constructor(public payload: AppHttpResponse<string>) {}
}

/**
 * Represents a incoming stock print success action.
 */
export class GetIncomingStockPrintSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = PrintsActionType.GET_INCOMING_STOCK_PRINT_SUCCESS;

  /**
   * Fires a incoming stock print success action.
   * @param payload The loaded report.
   */
  constructor(public payload: AppHttpResponse<Report>) {}
}

/**
 * Represents a incoming stock print view action.
 */
export class IncomingStockPrintView implements Action {
  /**
   * The type of the action.
   */
  readonly type = PrintsActionType.INCOMING_STOCK_PRINT_VIEW;

  /**
   * Fires a incoming stock print view action.
   * @param payload The report to be loaded.
   */
  constructor(public payload: Report) {}
}

/**
 * Represents a outgoing stock print action.
 */
export class GetOutgoingStockPrint implements Action {
  /**
   * The type of the action.
   */
  readonly type = PrintsActionType.GET_OUTGOING_STOCK_PRINT;

  /**
   * Fires a new outgoing stock print action.
   */
  constructor(
    public payload: {
      outgoingStockId: number;
      paperSize: string;
    }
  ) {}
}

/**
 * Represents a outgoing stock print fail action.
 */
export class GetOutgoingStockPrintFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = PrintsActionType.GET_OUTGOING_STOCK_PRINT_FAIL;

  /**
   * Fires a new outgoing stock print fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a outgoing stock print received action.
 */
export class GetOutgoingStockPrintReceived implements Action {
  /**
   * The type of the action.
   */
  readonly type = PrintsActionType.GET_OUTGOING_STOCK_PRINT_RECEIVED;

  /**
   * Fires a outgoing stock print received action.
   * @param payload Received acknowledgment.
   */
  constructor(public payload: AppHttpResponse<string>) {}
}

/**
 * Represents a outgoing stock print success action.
 */
export class GetOutgoingStockPrintSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = PrintsActionType.GET_OUTGOING_STOCK_PRINT_SUCCESS;

  /**
   * Fires a outgoing stock print success action.
   * @param payload The loaded report.
   */
  constructor(public payload: AppHttpResponse<Report>) {}
}

/**
 * Represents a outgoing stock print view action.
 */
export class OutgoingStockPrintView implements Action {
  /**
   * The type of the action.
   */
  readonly type = PrintsActionType.OUTGOING_STOCK_PRINT_VIEW;

  /**
   * Fires a outgoing stock print view action.
   * @param payload The report to be loaded.
   */
  constructor(public payload: Report) {}
}

/**
 * Represents a transfer stock print action.
 */
export class GetTransferStockPrint implements Action {
  /**
   * The type of the action.
   */
  readonly type = PrintsActionType.GET_TRANSFER_STOCK_PRINT;

  /**
   * Fires a new transfer stock print action.
   */
  constructor(
    public payload: {
      transferStockId: number;
      paperSize: string;
    }
  ) {}
}

/**
 * Represents a transfer stock print fail action.
 */
export class GetTransferStockPrintFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = PrintsActionType.GET_TRANSFER_STOCK_PRINT_FAIL;

  /**
   * Fires a new transfer stock print fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a transfer stock print received action.
 */
export class GetTransferStockPrintReceived implements Action {
  /**
   * The type of the action.
   */
  readonly type = PrintsActionType.GET_TRANSFER_STOCK_PRINT_RECEIVED;

  /**
   * Fires a transfer stock print received action.
   * @param payload Received acknowledgment.
   */
  constructor(public payload: AppHttpResponse<string>) {}
}

/**
 * Represents a transfer stock print success action.
 */
export class GetTransferStockPrintSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = PrintsActionType.GET_TRANSFER_STOCK_PRINT_SUCCESS;

  /**
   * Fires a transfer stock print success action.
   * @param payload The loaded report.
   */
  constructor(public payload: AppHttpResponse<Report>) {}
}

/**
 * Represents a transfer stock print view action.
 */
export class TransferStockPrintView implements Action {
  /**
   * The type of the action.
   */
  readonly type = PrintsActionType.TRANSFER_STOCK_PRINT_VIEW;

  /**
   * Fires a transfer stock print view action.
   * @param payload The report to be loaded.
   */
  constructor(public payload: Report) {}
}

/**
 * Represents a purchase request print action.
 */
export class GetPurchaseRequestPrint implements Action {
  /**
   * The type of the action.
   */
  readonly type = PrintsActionType.GET_PURCHASE_REQUEST_PRINT;

  /**
   * Fires a new purchase request print action.
   */
  constructor(
    public payload: {
      purchaseRequestId: number;
      paperSize: string;
    }
  ) {}
}

/**
 * Represents a purchase request print fail action.
 */
export class GetPurchaseRequestPrintFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = PrintsActionType.GET_PURCHASE_REQUEST_PRINT_FAIL;

  /**
   * Fires a new purchase request print fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a purchase request print received action.
 */
export class GetPurchaseRequestPrintReceived implements Action {
  /**
   * The type of the action.
   */
  readonly type = PrintsActionType.GET_PURCHASE_REQUEST_PRINT_RECEIVED;

  /**
   * Fires a purchase request print received action.
   * @param payload Received acknowledgment.
   */
  constructor(public payload: AppHttpResponse<string>) {}
}

/**
 * Represents a purchase request print success action.
 */
export class GetPurchaseRequestPrintSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = PrintsActionType.GET_PURCHASE_REQUEST_PRINT_SUCCESS;

  /**
   * Fires a purchase request print success action.
   * @param payload The loaded report.
   */
  constructor(public payload: AppHttpResponse<Report>) {}
}

/**
 * Represents a purchase request print view action.
 */
export class PurchaseRequestPrintView implements Action {
  /**
   * The type of the action.
   */
  readonly type = PrintsActionType.PURCHASE_REQUEST_PRINT_VIEW;

  /**
   * Fires a purchase request print view action.
   * @param payload The report to be loaded.
   */
  constructor(public payload: Report) {}
}

/**
 * Represents a purchase order print action.
 */
export class GetPurchaseOrderPrint implements Action {
  /**
   * The type of the action.
   */
  readonly type = PrintsActionType.GET_PURCHASE_ORDER_PRINT;

  /**
   * Fires a new purchase order print action.
   */
  constructor(
    public payload: {
      purchaseOrderId: number;
      paperSize: string;
    }
  ) {}
}

/**
 * Represents a purchase order print fail action.
 */
export class GetPurchaseOrderPrintFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = PrintsActionType.GET_PURCHASE_ORDER_PRINT_FAIL;

  /**
   * Fires a new purchase order print fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a purchase order print received action.
 */
export class GetPurchaseOrderPrintReceived implements Action {
  /**
   * The type of the action.
   */
  readonly type = PrintsActionType.GET_PURCHASE_ORDER_PRINT_RECEIVED;

  /**
   * Fires a purchase order print received action.
   * @param payload Received acknowledgment.
   */
  constructor(public payload: AppHttpResponse<string>) {}
}

/**
 * Represents a purchase order print success action.
 */
export class GetPurchaseOrderPrintSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = PrintsActionType.GET_PURCHASE_ORDER_PRINT_SUCCESS;

  /**
   * Fires a purchase order print success action.
   * @param payload The loaded report.
   */
  constructor(public payload: AppHttpResponse<Report>) {}
}

/**
 * Represents a purchase order print view action.
 */
export class PurchaseOrderPrintView implements Action {
  /**
   * The type of the action.
   */
  readonly type = PrintsActionType.PURCHASE_ORDER_PRINT_VIEW;

  /**
   * Fires a purchase order print view action.
   * @param payload The report to be loaded.
   */
  constructor(public payload: Report) {}
}

/**
 * Represents a purchase invoice print action.
 */
export class GetPurchaseInvoicePrint implements Action {
  /**
   * The type of the action.
   */
  readonly type = PrintsActionType.GET_PURCHASE_INVOICE_PRINT;

  /**
   * Fires a new purchase invoice print action.
   */
  constructor(
    public payload: {
      purchaseInvoiceId: number;
      paperSize: string;
    }
  ) {}
}

/**
 * Represents a purchase invoice print fail action.
 */
export class GetPurchaseInvoicePrintFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = PrintsActionType.GET_PURCHASE_INVOICE_PRINT_FAIL;

  /**
   * Fires a new purchase invoice print fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a purchase invoice print received action.
 */
export class GetPurchaseInvoicePrintReceived implements Action {
  /**
   * The type of the action.
   */
  readonly type = PrintsActionType.GET_PURCHASE_INVOICE_PRINT_RECEIVED;

  /**
   * Fires a purchase invoice print received action.
   * @param payload Received acknowledgment.
   */
  constructor(public payload: AppHttpResponse<string>) {}
}

/**
 * Represents a purchase invoice print success action.
 */
export class GetPurchaseInvoicePrintSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = PrintsActionType.GET_PURCHASE_INVOICE_PRINT_SUCCESS;

  /**
   * Fires a purchase invoice print success action.
   * @param payload The loaded report.
   */
  constructor(public payload: AppHttpResponse<Report>) {}
}

/**
 * Represents a purchase invoice print view action.
 */
export class PurchaseInvoicePrintView implements Action {
  /**
   * The type of the action.
   */
  readonly type = PrintsActionType.PURCHASE_INVOICE_PRINT_VIEW;

  /**
   * Fires a purchase invoice print view action.
   * @param payload The report to be loaded.
   */
  constructor(public payload: Report) {}
}

/**
 * Represents a purchase return print action.
 */
export class GetPurchaseReturnPrint implements Action {
  /**
   * The type of the action.
   */
  readonly type = PrintsActionType.GET_PURCHASE_RETURN_PRINT;

  /**
   * Fires a new purchase return print action.
   */
  constructor(
    public payload: {
      purchaseReturnId: number;
      paperSize: string;
    }
  ) {}
}

/**
 * Represents a purchase return print fail action.
 */
export class GetPurchaseReturnPrintFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = PrintsActionType.GET_PURCHASE_RETURN_PRINT_FAIL;

  /**
   * Fires a new purchase return print fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a purchase return print received action.
 */
export class GetPurchaseReturnPrintReceived implements Action {
  /**
   * The type of the action.
   */
  readonly type = PrintsActionType.GET_PURCHASE_RETURN_PRINT_RECEIVED;

  /**
   * Fires a purchase return print received action.
   * @param payload Received acknowledgment.
   */
  constructor(public payload: AppHttpResponse<string>) {}
}

/**
 * Represents a purchase return print success action.
 */
export class GetPurchaseReturnPrintSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = PrintsActionType.GET_PURCHASE_RETURN_PRINT_SUCCESS;

  /**
   * Fires a purchase return print success action.
   * @param payload The loaded report.
   */
  constructor(public payload: AppHttpResponse<Report>) {}
}

/**
 * Represents a purchase return print view action.
 */
export class PurchaseReturnPrintView implements Action {
  /**
   * The type of the action.
   */
  readonly type = PrintsActionType.PURCHASE_RETURN_PRINT_VIEW;

  /**
   * Fires a purchase return print view action.
   * @param payload The report to be loaded.
   */
  constructor(public payload: Report) {}
}

/**
 * Represents a sale invoice print action.
 */
export class GetSaleInvoicePrint implements Action {
  /**
   * The type of the action.
   */
  readonly type = PrintsActionType.GET_SALE_INVOICE_PRINT;

  /**
   * Fires a new sale invoice print action.
   */
  constructor(
    public payload: {
      saleInvoiceId: number;
      paperSize?: string;
    }
  ) {}
}

/**
 * Represents a sale invoice print fail action.
 */
export class GetSaleInvoicePrintFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = PrintsActionType.GET_SALE_INVOICE_PRINT_FAIL;

  /**
   * Fires a new sale invoice print fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a sale invoice print received action.
 */
export class GetSaleInvoicePrintReceived implements Action {
  /**
   * The type of the action.
   */
  readonly type = PrintsActionType.GET_SALE_INVOICE_PRINT_RECEIVED;

  /**
   * Fires a sale invoice print received action.
   * @param payload Received acknowledgment.
   */
  constructor(public payload: AppHttpResponse<string>) {}
}

/**
 * Represents a sale invoice print success action.
 */
export class GetSaleInvoicePrintSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = PrintsActionType.GET_SALE_INVOICE_PRINT_SUCCESS;

  /**
   * Fires a sale invoice print success action.
   * @param payload The loaded report.
   */
  constructor(public payload: AppHttpResponse<Report>) {}
}

/**
 * Represents a sale invoice print view action.
 */
export class SaleInvoicePrintView implements Action {
  /**
   * The type of the action.
   */
  readonly type = PrintsActionType.SALE_INVOICE_PRINT_VIEW;

  /**
   * Fires a sale invoice print view action.
   * @param payload The report to be loaded.
   */
  constructor(public payload: Report) {}
}

/**
 * Represents a sale return print action.
 */
export class GetSaleReturnPrint implements Action {
  /**
   * The type of the action.
   */
  readonly type = PrintsActionType.GET_SALE_RETURN_PRINT;

  /**
   * Fires a new sale return print action.
   */
  constructor(
    public payload: {
      saleReturnId: number;
      paperSize?: string;
    }
  ) {}
}

/**
 * Represents a sale return print fail action.
 */
export class GetSaleReturnPrintFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = PrintsActionType.GET_SALE_RETURN_PRINT_FAIL;

  /**
   * Fires a new sale return print fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a sale return print received action.
 */
export class GetSaleReturnPrintReceived implements Action {
  /**
   * The type of the action.
   */
  readonly type = PrintsActionType.GET_SALE_RETURN_PRINT_RECEIVED;

  /**
   * Fires a sale return print received action.
   * @param payload Received acknowledgment.
   */
  constructor(public payload: AppHttpResponse<string>) {}
}

/**
 * Represents a sale return print success action.
 */
export class GetSaleReturnPrintSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = PrintsActionType.GET_SALE_RETURN_PRINT_SUCCESS;

  /**
   * Fires a sale return print success action.
   * @param payload The loaded report.
   */
  constructor(public payload: AppHttpResponse<Report>) {}
}

/**
 * Represents a sale return print view action.
 */
export class SaleReturnPrintView implements Action {
  /**
   * The type of the action.
   */
  readonly type = PrintsActionType.SALE_RETURN_PRINT_VIEW;

  /**
   * Fires a sale return print view action.
   * @param payload The report to be loaded.
   */
  constructor(public payload: Report) {}
}

/**
 * Represents a sales quotation print action.
 */
export class GetSalesQuotationPrint implements Action {
  /**
   * The type of the action.
   */
  readonly type = PrintsActionType.GET_SALES_QUOTATION_PRINT;

  /**
   * Fires a new sales quotation print action.
   */
  constructor(
    public payload: {
      salesQuotationId: number;
      paperSize: string;
    }
  ) {}
}

/**
 * Represents a sales quotation print fail action.
 */
export class GetSalesQuotationPrintFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = PrintsActionType.GET_SALES_QUOTATION_PRINT_FAIL;

  /**
   * Fires a new sales quotation print fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a sales quotation print received action.
 */
export class GetSalesQuotationPrintReceived implements Action {
  /**
   * The type of the action.
   */
  readonly type = PrintsActionType.GET_SALES_QUOTATION_PRINT_RECEIVED;

  /**
   * Fires a sales quotation print received action.
   * @param payload Received acknowledgment.
   */
  constructor(public payload: AppHttpResponse<string>) {}
}

/**
 * Represents a sales quotation print success action.
 */
export class GetSalesQuotationPrintSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = PrintsActionType.GET_SALES_QUOTATION_PRINT_SUCCESS;

  /**
   * Fires a sales quotation print success action.
   * @param payload The loaded report.
   */
  constructor(public payload: AppHttpResponse<Report>) {}
}

/**
 * Represents a sales quotation print view action.
 */
export class SalesQuotationPrintView implements Action {
  /**
   * The type of the action.
   */
  readonly type = PrintsActionType.SALES_QUOTATION_PRINT_VIEW;

  /**
   * Fires a sales quotation print view action.
   * @param payload The report to be loaded.
   */
  constructor(public payload: Report) {}
}

/**
 * Represents a journal print action.
 */
export class GetJournalPrint implements Action {
  /**
   * The type of the action.
   */
  readonly type = PrintsActionType.GET_JOURNAL_PRINT;

  /**
   * Fires a new journal print action.
   */
  constructor(
    public payload: {
      journalId: number;
      paperSize: string;
    }
  ) {}
}

/**
 * Represents a journal print fail action.
 */
export class GetJournalPrintFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = PrintsActionType.GET_JOURNAL_PRINT_FAIL;

  /**
   * Fires a new journal print fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a journal print received action.
 */
export class GetJournalPrintReceived implements Action {
  /**
   * The type of the action.
   */
  readonly type = PrintsActionType.GET_JOURNAL_PRINT_RECEIVED;

  /**
   * Fires a journal print received action.
   * @param payload Received acknowledgment.
   */
  constructor(public payload: AppHttpResponse<string>) {}
}

/**
 * Represents a journal print success action.
 */
export class GetJournalPrintSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = PrintsActionType.GET_JOURNAL_PRINT_SUCCESS;

  /**
   * Fires a journal print success action.
   * @param payload The loaded report.
   */
  constructor(public payload: AppHttpResponse<Report>) {}
}

/**
 * Represents a journal  print view action.
 */
export class JournalPrintView implements Action {
  /**
   * The type of the action.
   */
  readonly type = PrintsActionType.JOURNAL_PRINT_VIEW;

  /**
   * Fires a journal print view action.
   * @param payload The report to be loaded.
   */
  constructor(public payload: Report) {}
}

/**
 * Printing-Module prints action types.
 */
export type PrintsActions =
  | GetIncomingStockPrint
  | GetIncomingStockPrintFail
  | GetIncomingStockPrintReceived
  | GetIncomingStockPrintSuccess
  | IncomingStockPrintView
  | GetOutgoingStockPrint
  | GetOutgoingStockPrintFail
  | GetOutgoingStockPrintReceived
  | GetOutgoingStockPrintSuccess
  | OutgoingStockPrintView
  | GetTransferStockPrint
  | GetTransferStockPrintFail
  | GetTransferStockPrintReceived
  | GetTransferStockPrintSuccess
  | TransferStockPrintView
  | GetPurchaseRequestPrint
  | GetPurchaseRequestPrintFail
  | GetPurchaseRequestPrintReceived
  | GetPurchaseRequestPrintSuccess
  | PurchaseRequestPrintView
  | GetPurchaseOrderPrint
  | GetPurchaseOrderPrintFail
  | GetPurchaseOrderPrintReceived
  | GetPurchaseOrderPrintSuccess
  | PurchaseOrderPrintView
  | GetPurchaseInvoicePrint
  | GetPurchaseInvoicePrintFail
  | GetPurchaseInvoicePrintReceived
  | GetPurchaseInvoicePrintSuccess
  | PurchaseInvoicePrintView
  | GetPurchaseReturnPrint
  | GetPurchaseReturnPrintFail
  | GetPurchaseReturnPrintReceived
  | GetPurchaseReturnPrintSuccess
  | PurchaseReturnPrintView
  | GetSaleInvoicePrint
  | GetSaleInvoicePrintFail
  | GetSaleInvoicePrintReceived
  | GetSaleInvoicePrintSuccess
  | SaleInvoicePrintView
  | GetSaleReturnPrint
  | GetSaleReturnPrintFail
  | GetSaleReturnPrintReceived
  | GetSaleReturnPrintSuccess
  | SaleReturnPrintView
  | GetSalesQuotationPrint
  | GetSalesQuotationPrintFail
  | GetSalesQuotationPrintReceived
  | GetSalesQuotationPrintSuccess
  | SalesQuotationPrintView
  | GetJournalPrint
  | GetJournalPrintFail
  | GetJournalPrintReceived
  | GetJournalPrintSuccess
  | JournalPrintView;
