import { Injectable } from '@angular/core';

import { of } from 'rxjs';
import { switchMap, map, catchError } from 'rxjs/operators';
import { Effect, ofType, Actions } from '@ngrx/effects';

import { LookupsService } from 'lookups/services';
import { SickLeaveTypeActionType, GetAllSickLeaveTypesFail, GetAllSickLeaveTypesSuccess } from 'lookups/store/actions';

@Injectable()
export class SickLeaveTypeEffects {
  constructor(private actions$: Actions, private lookupsService: LookupsService) {}

  /* ========================= GET_ALL_SICK_LEAVE_TYPES =================================== */
  @Effect()
  getAllSickLeaveTypes$ = this.actions$.pipe(
    ofType(SickLeaveTypeActionType.GET_ALL_SICK_LEAVE_TYPES),
    switchMap(() =>
      this.lookupsService.getAllSickLeaveTypes().pipe(
        map((response) => new GetAllSickLeaveTypesSuccess(response)),
        catchError((error) => of(new GetAllSickLeaveTypesFail(error)))
      )
    )
  );
}
