import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';

import { Observable } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { PageInfo, PaginationInfo } from 'shared';
import * as fromFinancesStore from 'finances/store';
import { Bank } from 'finances/models';
import { Claims } from 'security/models';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-banks-list',
  templateUrl: './banks-list.component.html',
  styles: [],
})
export class BanksListComponent implements OnInit {
  /**
   * The confirm modal template reference.
   */
  @ViewChild('deleteModalRef') deleteModalRef: ElementRef<any>;

  /**
   * Gets or sets the information about the current page.
   */
  pageInfo: PageInfo = {
    title: 'FINANCES.BANKS.BANK_DATA.BANKS',
    icon: 'fa fa-bank',
  };

  /**
   * The system supported user claims.
   */
  Claims = Claims;

  /**
   * Indicates whether there is a delete-account process is running or not.
   */
  isDeleting$: Observable<boolean>;

  /**
   * The list of banks.
   */
  banks$: Observable<Bank[]>;

  /**
   * The pagination info.
   */
  paginationInfo$: Observable<PaginationInfo>;

  /**
   * The search form.
   */
  searchForm: FormGroup;

  /**
   * Gets or sets the currently selected bank.
   */
  selectedBank: Bank;

  /**
   * @param modalService The modal service.
   * @param financesStore$ The finances store.
   */
  constructor(private modalService: NgbModal, private financesStore$: Store<fromFinancesStore.FinancesState>) {}

  ngOnInit(): void {
    this.init();
  }

  /**
   * Initialize component data.
   */
  init() {
    this.initForm();

    /**
     * Load data.
     */
    this.isDeleting$ = this.financesStore$.pipe(select(fromFinancesStore.getSelectedBankDeleting));

    let isManualSearchTriggeredBeforeForBanks = false;
    this.banks$ = this.financesStore$.pipe(
      select(fromFinancesStore.getBanks),
      tap((banks) => {
        if (!isManualSearchTriggeredBeforeForBanks && !banks.length) {
          isManualSearchTriggeredBeforeForBanks = true;
          this.search();
        }
      })
    );

    this.paginationInfo$ = this.financesStore$.pipe(select(fromFinancesStore.getBanksPaginationInfo));
  }

  /**
   * Initialize form and add validators.
   */
  initForm() {
    this.searchForm = new FormGroup({
      name: new FormControl(''),
      page: new FormControl(1),
    });
  }

  /**
   * Handles search parameters change.
   */
  search(event?: KeyboardEvent) {
    if (event && event.key === 'Enter') {
      event.preventDefault();
      return;
    }

    this.applyFiltersAndSearch();
  }

  /**
   * Handles pagination page-changed event.
   * @param page The current selected page number.
   */
  pageChanged(page: number) {
    /** Update pagination page. */
    this.searchForm.patchValue({ page });

    this.applyFiltersAndSearch(page);
  }

  /**
   * Enables trackBy feature for *ngFor utility to track elements
   * depending on a comparer to reduce DOM change.
   * If the element is already exist then no HTML will change, only bounded values.
   * @param element The element to determine if it was changed or not.
   */
  trackElements(element: Bank) {
    return element ? element.id : undefined;
  }

  applyFiltersAndSearch(page: number = 1) {
    const { name } = this.searchForm.value;
    this.financesStore$.dispatch(
      new fromFinancesStore.SearchBanks({
        name,
        page,
      })
    );
  }

  /**
   * Deletes the bank with the given id.
   * @param bankId The id of the bank to be deleted.
   */
  delete(bankId: number) {
    this.financesStore$.dispatch(new fromFinancesStore.DeleteBank(bankId));
  }

  /**
   * Opens the modal of the given templateRef.
   * @param modalRef The modal templateRef to be opened.
   */
  openModal(modalRef) {
    this.modalService.open(modalRef);
  }
}
