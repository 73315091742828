import { OrganizationState } from 'organizations/store/states/organization.state';

/**
 * Represents the organizations-module state.
 */
export class OrganizationsState {
  /**
   * Represents the organizations state.
   */
  public organizations: OrganizationState;
}
