import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';

import { Observable, Subscription } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { orderBy } from 'lodash';

import * as fromLookupsStore from 'lookups/store';
import { PaginationInfo } from 'shared';
import {
  Employee,
  EmployeePayroll,
  Payroll,
  PayrollDeduction,
  PayrollElement,
  PayrollGovService,
  PayrollLoanSettlement,
  PayrollLocationCost,
  PayrollOtherEarning,
  PayrollOvertime,
  PayrollProjectCost,
  PayrollProvision,
  PayrollUnpaidLeave,
} from 'hr/models';
import { Claims } from 'security/models';
import { PayrollStatus, PayrollStatuses } from 'lookups/models';
import { tap } from 'rxjs/operators';
import { ProjectResourceLine } from 'projects-management/models/projects/project-resource-line.model';

@Component({
  selector: 'app-payrolls-list',
  templateUrl: './payrolls-list.component.html',
  styles: [],
})
export class PayrollsListComponent implements OnInit, OnDestroy {
  /**
   * The create modal template reference.
   */
  @ViewChild('infoPayrollModalRef') infoPayrollModalRef: ElementRef<any>;

  /**
   * The delete modal template reference.
   */
  @ViewChild('deleteModalRef') deleteModalRef: ElementRef<any>;

  /**
   * Sets the employee id.
   */
  @Input() employeeId: number;

  /**
   * Sets the target element id.
   */
  @Input() targetElementId: string;

  /**
   * Sets the employee.
   */
  @Input() employee: Employee;

  /**
   * Sets the payrolls.
   */
  @Input() set payrolls(value: Payroll[]) {
    if (value) {
      this.payrollData = orderBy(value, (item) => item.employee?.employeeAssignmentDetail.personNumber);
    } else {
      this.payrollData = [];
    }
  }

  /**
   * Sets the payrolls.
   */
  @Input() set projectResourceLines(value: ProjectResourceLine[]) {
    if (value) {
      value.map((item) => this.payrollData.push(item.payroll));
    } else {
      this.payrollData = [];
    }
  }

  /**
   * Sets the pagination info.
   */
  @Input() paginationInfo$: Observable<PaginationInfo>;

  /**
   * Sets the is deleting.
   */
  @Input() isDeleting: boolean = false;

  /**
   * Sets the is employee management.
   */
  @Input() isEmployeeManagement: false;

  /**
   * Sets the display employee file.
   */
  @Input() displayEmployeeFile: false;

  /**
   * Sets the display delete button.
   */
  @Input() displayDeleteButton: true;

  /**
   * Handles payroll id change in two-data-binding approach.
   */
  @Output() payrollId = new EventEmitter<number>();

  /**
   * Sets the employee.
   */
  @Input() deleteEmployeePayroll;

  /**
   * Gets or sets the currently selected employee payroll for delete.
   */
  selectedEmployeePayroll: EmployeePayroll;

  /**
   * Shows or hide the payroll projects costs list modal.
   */
  payrollProjectsCostsListVisibility = false;

  /**
   * Shows or hide the payroll provisions list modal.
   */
  payrollProvisionsListVisibility = false;

  /**
   * Shows or hide the payroll unpaid leaves list modal.
   */
  payrollUnpaidLeavesListVisibility = false;

  /**
   * Shows or hide the payroll gov services list modal.
   */
  payrollGovServicesListVisibility = false;

  /**
   * The set of system supported payroll statuses.
   */
  payrollStatuses$: Observable<PayrollStatus[]>;

  /**
   * The list of payroll status.
   */
  payrollStatus = PayrollStatuses;

  /**
   * Gets or sets the currently selected payroll for delete.
   */
  selectedPayroll: Payroll;

  /**
   * Gets or sets the list of payroll.
   */
  payrollData = [];

  /**
   * Gets the list of selected payroll element for current payroll element.
   */
  selectedPayrollElement: PayrollElement[];

  /**
   * Gets the list of selected payroll loan settlement for current payroll element.
   */
  selectedPayrollLoanSettlements: PayrollLoanSettlement[];

  /**
   * Gets the list of selected gosi payroll for current payroll element.
   */
  selectedPayrollGosiPayrollElements: PayrollElement[];

  /**
   * Gets the list of selected payroll Other earnings for current payroll element.
   */
  selectedPayrollOtherEarnings: PayrollOtherEarning[];

  /**
   * Gets the list of selected payroll deductions for current payroll element.
   */
  selectedPayrollDeductions: PayrollDeduction[];

  /**
   * Gets the list of selected payroll overtimes for current payroll element.
   */
  selectedPayrollOvertimes: PayrollOvertime[];

  /**
   * Gets the list of selected payroll locations costs for current payroll element.
   */
  selectedPayrollLocationCosts: PayrollLocationCost[];

  /**
   * Gets the list of selected payroll projects costs for current payroll.
   */
  selectedPayrollProjectCosts: PayrollProjectCost[];

  /**
   * Gets the list of selected payroll provisions for current payroll.
   */
  selectedPayrollProvisions: PayrollProvision[];

  /**
   * Gets the list of selected payroll unpaid leaves for current payroll.
   */
  selectedPayrollUnpaidLeaves: PayrollUnpaidLeave[];

  /**
   * Gets the list of selected payroll gov services for current payroll.
   */
  selectedPayrollGovServices: PayrollGovService[];

  /**
   * Gets the payroll element key for current payroll element.
   */
  payrollElementKey: string;

  /**
   * Shows or hide the payroll element formulas list.
   */
  payrollElementFormulasListVisibility = false;

  /**
   * Shows or hide the payroll gosi payroll elements list modal.
   */
  gosiPayrollElementsListVisibility = false;

  /**
   * Shows or hide the payroll recurring elements list modal.
   */
  payrollRecurringElementsListVisibility = false;

  /**
   * Shows or hide the payroll loan settlements list modal.
   */
  payrollLoanSettlementsListVisibility = false;

  /**
   * Shows or hide the payroll other earnings list modal.
   */
  payrollOtherEarningsListVisibility = false;

  /**
   * Shows or hide the payroll deductions list modal.
   */
  payrollDeductionsListVisibility = false;

  /**
   * Shows or hide the payroll over time list modal.
   */
  payrollOvertimesListVisibility = false;

  /**
   * Shows or hide the payroll locations costs list modal.
   */
  payrollLocationsCostsListVisibility = false;

  /**
   * Shows or hide the payroll info modal.
   */
  payrollInfoVisibility = false;

  /**
   * Shows or hide the management payroll info modal.
   */
  managementPayrollInfoVisibility = false;

  /**
   * The system supported user claims.
   */
  Claims = Claims;
  /**
   * The ngb-modal reference to use it when modal needed to be closed.
   */
  modal: NgbModalRef;

  /**
   * The set of subscriptions on this components,
   * these subscriptions must be unsubscribed before this component got destroyed.
   */
  subscriptions = new Subscription();

  /**
   * @param modalService The modal service.
   * @param hrStore$ The hr-store module.
   * @param lookupsStore$ The lookups-store module.
   */
  constructor(private modalService: NgbModal, private lookupsStore$: Store<fromLookupsStore.LookupsState>) {}

  ngOnInit(): void {
    this.init();
  }

  /**
   * Destroy component data
   */
  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  /**
   * Initialize component data.
   */
  init() {
    /**
     * Load data.
     */
    let isManualSearchTriggeredBeforeForPayrollStatuses = false;
    this.payrollStatuses$ = this.lookupsStore$.pipe(
      select(fromLookupsStore.getPayrollStatuses),
      tap((payrollStatuses) => {
        if (!isManualSearchTriggeredBeforeForPayrollStatuses && !payrollStatuses.length) {
          isManualSearchTriggeredBeforeForPayrollStatuses = true;
          this.lookupsStore$.dispatch(new fromLookupsStore.GetAllPayrollStatuses());
        }
      })
    );
  }

  /**
   * Handles pagination page-changed event.
   * @param page The current selected page number.
   */
  pageChanged(page: number) {
    /** Update pagination page. */
  }

  /**
   * Enables trackBy feature for *ngFor utility to track items
   * depending on a comparer to reduce DOM change.
   * If the item is already exist then no HTML will change, only bounded values.
   * @param index The index of the item.
   * @param employeePayroll The item to determine if it was changed or not.
   */
  trackItems(index: number, employeePayroll: EmployeePayroll) {
    return employeePayroll ? employeePayroll.id : undefined;
  }

  /**
   * Deletes the employee payroll with the given id.
   * @param employeePayrollId The id of the employee payroll to be deleted.
   */
  deletePayroll(employeePayrollId: number) {
    this.payrollId.emit(employeePayrollId);
  }

  /**
   * Opens the modal of the given templateRef.
   * @param modalRef The modal templateRef to be opened.
   */
  openModal(modalRef) {
    this.modalService.open(modalRef);
  }

  /**
   * Opens the modal of the given templateRef.
   * @param modalRef The modal templateRef to be opened.
   */
  openInfoModal(modalRef) {
    /**
     * Open the modal & listen for user press ESC key.
     */
    this.modal = this.modalService.open(modalRef, { size: 'xl' });
    this.modal.result.then(null, () => this.closeModal());
  }

  /**
   * Closes the currently opened modal.
   */
  closeModal() {
    this.modalService.dismissAll();
  }
}
