<!-- page header starts -->
<app-page-header [info]="pageInfo"></app-page-header>
<!-- page header ends -->

<!-- view journal starts -->
<div class="card">
  <div class="card-content">
    <div class="px-3 pt-3" *ngIf="journal$ | async; let journal">
      <div class="row">
        <div class="col-md-12">
          <ul
            class="nav-tabs"
            ngbNav
            #nav="ngbNav"
            [(activeId)]="activePage"
            (activeIdChange)="selectedPageChanged($event)"
            [destroyOnHide]="false"
          >
            <li ngbNavItem="details">
              <a ngbNavLink>
                <i class="fa fa-file-text" [class.success]="activePage == 'details'"></i>
                {{ 'FINANCES.JOURNALS.JOURNALS_LINES_DATA' | translate }}
              </a>
              <ng-template ngbNavContent>
                <app-view-journal [journal]="journal"></app-view-journal>
              </ng-template>
            </li>
            <li *ngIf="bankAccountLog$ | async" ngbNavItem="bank-account-log">
              <a ngbNavLink>
                <i class="fa fa-id-badge" [class.success]="activePage == 'bank-account-log'"></i>
                {{ 'FINANCES.JOURNALS.JOURNAL_DATA.BANK_ACCOUNT_DATA' | translate }}
              </a>
              <ng-template ngbNavContent>
                <app-journal-bank-account-log-details
                  [bankAccountLog]="bankAccountLog$ | async"
                ></app-journal-bank-account-log-details>
              </ng-template>
            </li>
            <li *ngIf="vendorLog$ | async" ngbNavItem="vendor-log">
              <a ngbNavLink>
                <i class="fa fa-file-text" [class.success]="activePage == 'vendor-log'"></i>
                {{ 'FINANCES.JOURNALS.JOURNAL_DATA.VENDOR_DATA' | translate }}
              </a>
              <ng-template ngbNavContent>
                <app-journal-vendor-log-details [vendorLog]="vendorLog$ | async"></app-journal-vendor-log-details>
              </ng-template>
            </li>
            <li *ngIf="purchaseInvoice$ | async" ngbNavItem="purchase-invoice">
              <a ngbNavLink>
                <i class="fa fa-usd" [class.success]="activePage == 'purchase-invoice'"></i>
                {{ 'FINANCES.JOURNALS.JOURNAL_DATA.PURCHASE_INVOICE_DATA' | translate }}
              </a>
              <ng-template ngbNavContent>
                <app-journal-purchase-invoice-details [purchaseInvoice]="purchaseInvoice$ | async">
                </app-journal-purchase-invoice-details>
              </ng-template>
            </li>
            <li *ngIf="purchaseReturn$ | async" ngbNavItem="purchase-return">
              <a ngbNavLink>
                <i class="fa fa-calendar" [class.success]="activePage == 'purchase-return'"></i>
                {{ 'FINANCES.JOURNALS.JOURNAL_DATA.PURCHASE_RETURN_DATA' | translate }}
              </a>
              <ng-template ngbNavContent>
                <app-journal-purchase-return-details [purchaseReturn]="purchaseReturn$ | async">
                </app-journal-purchase-return-details>
              </ng-template>
            </li>
            <li *ngIf="customerLog$ | async" ngbNavItem="customer-log">
              <a ngbNavLink>
                <i class="fa fa-calendar" [class.success]="activePage == 'customer-log'"></i>
                {{ 'FINANCES.JOURNALS.JOURNAL_DATA.CUSTOMER_DATA' | translate }}
              </a>
              <ng-template ngbNavContent>
                <app-journal-customer-log-details [customerLog]="customerLog$ | async">
                </app-journal-customer-log-details>
              </ng-template>
            </li>
            <li *ngIf="saleInvoice$ | async" ngbNavItem="sale-invoice">
              <a ngbNavLink>
                <i class="fa fa-calendar" [class.success]="activePage == 'sale-invoice'"></i>
                {{ 'FINANCES.JOURNALS.JOURNAL_DATA.SALES_INVOICES_DATA' | translate }}
              </a>
              <ng-template ngbNavContent>
                <app-journal-sale-invoice-details [invoice]="saleInvoice$ | async"> </app-journal-sale-invoice-details>
              </ng-template>
            </li>
            <li *ngIf="saleReturn$ | async" ngbNavItem="sale-return">
              <a ngbNavLink>
                <i class="fa fa-calendar" [class.success]="activePage == 'sale-return'"></i>
                {{ 'FINANCES.JOURNALS.JOURNAL_DATA.SALES_RETURN_DATA' | translate }}
              </a>
              <ng-template ngbNavContent>
                <app-journal-sale-return-details [saleReturn]="saleReturn$ | async"> </app-journal-sale-return-details>
              </ng-template>
            </li>
            <li *ngIf="transferStock$ | async" ngbNavItem="transfer-stock">
              <a ngbNavLink>
                <i class="fa fa-calendar" [class.success]="activePage == 'transfer-stock'"></i>
                {{ 'FINANCES.JOURNALS.JOURNAL_DATA.TRANSFER_STOCK_DATA' | translate }}
              </a>
              <ng-template ngbNavContent>
                <app-journal-transfer-stock-details [transferStock]="transferStock$ | async">
                </app-journal-transfer-stock-details>
              </ng-template>
            </li>
            <li *ngIf="incomingStock$ | async" ngbNavItem="incoming-stock">
              <a ngbNavLink>
                <i class="fa fa-calendar" [class.success]="activePage == 'incoming-stock'"></i>
                {{ 'FINANCES.JOURNALS.JOURNAL_DATA.INCOMING_STOCK_DATA' | translate }}
              </a>
              <ng-template ngbNavContent>
                <app-journal-incoming-stock-details [incomingStock]="incomingStock$ | async">
                </app-journal-incoming-stock-details>
              </ng-template>
            </li>
            <li *ngIf="outgoingStock$ | async" ngbNavItem="outgoing-stock">
              <a ngbNavLink>
                <i class="fa fa-calendar" [class.success]="activePage == 'outgoing-stock'"></i>
                {{ 'FINANCES.JOURNALS.JOURNAL_DATA.OUTGOING_STOCK_DATA' | translate }}
              </a>
              <ng-template ngbNavContent>
                <app-journal-outgoing-stock-details [outgoingStock]="outgoingStock$ | async">
                </app-journal-outgoing-stock-details>
              </ng-template>
            </li>
            <li *ngIf="pettyCashRefund$ | async" ngbNavItem="petty-cash-refund">
              <a ngbNavLink>
                <i class="fa fa-calendar" [class.success]="activePage == 'petty-cash-refund'"></i>
                {{ 'FINANCES.JOURNALS.JOURNAL_DATA.PETTY_CASH_REFUND_DATA' | translate }}
              </a>
              <ng-template ngbNavContent>
                <app-journal-petty-cash-refund-details [pettyCashRefund]="pettyCashRefund$ | async">
                </app-journal-petty-cash-refund-details>
              </ng-template>
            </li>
            <li *ngIf="pettyCashRefundRequest$ | async" ngbNavItem="petty-cash-refund-request">
              <a ngbNavLink>
                <i class="fa fa-calendar" [class.success]="activePage == 'petty-cash-refund-request'"></i>
                {{ 'FINANCES.JOURNALS.JOURNAL_DATA.PETTY_CASH_REFUND_REQUESTS_DATA' | translate }}
              </a>
              <ng-template ngbNavContent>
                <app-journal-petty-cash-refund-request-details
                  [pettyCashRefundRequest]="pettyCashRefundRequest$ | async"
                >
                </app-journal-petty-cash-refund-request-details>
              </ng-template>
            </li>
            <li *ngIf="asset$ | async" ngbNavItem="asset">
              <a ngbNavLink>
                <i class="fa fa-calendar" [class.success]="activePage == 'asset'"></i>
                {{ 'FINANCES.JOURNALS.JOURNAL_DATA.ASSETS_DETAILS' | translate }}
              </a>
              <ng-template ngbNavContent>
                <app-journal-asset-details [asset]="asset$ | async"> </app-journal-asset-details>
              </ng-template>
            </li>
            <li *ngIf="excludedAsset$ | async" ngbNavItem="excluded-asset">
              <a ngbNavLink>
                <i class="fa fa-calendar" [class.success]="activePage == 'excluded-asset'"></i>
                {{ 'FINANCES.JOURNALS.JOURNAL_DATA.EXCLUDED_ASSETS_DETAILS' | translate }}
              </a>
              <ng-template ngbNavContent>
                <app-journal-excluded-asset-details [excludedAsset]="excludedAsset$ | async">
                </app-journal-excluded-asset-details>
              </ng-template>
            </li>
            <li *ngIf="assetDepreciation$ | async" ngbNavItem="asset-depreciation">
              <a ngbNavLink>
                <i class="fa fa-calendar" [class.success]="activePage == 'asset-depreciation'"></i>
                {{ 'FINANCES.JOURNALS.JOURNAL_DATA.ASSET_DEPRECIATION_DETAILS' | translate }}
              </a>
              <ng-template ngbNavContent>
                <app-journal-asset-depreciation-details [assetDepreciation]="assetDepreciation$ | async">
                </app-journal-asset-depreciation-details>
              </ng-template>
            </li>
            <li *ngIf="assetSaleInvoice$ | async" ngbNavItem="asset-sale-invoice">
              <a ngbNavLink>
                <i class="fa fa-calendar" [class.success]="activePage == 'asset-sale-invoice'"></i>
                {{ 'FINANCES.JOURNALS.JOURNAL_DATA.ASSET_SALE_INVOICE_DETAILS' | translate }}
              </a>
              <ng-template ngbNavContent>
                <app-journal-asset-sale-invoice-details [assetSaleInvoice]="assetSaleInvoice$ | async">
                </app-journal-asset-sale-invoice-details>
              </ng-template>
            </li>
            <li *ngIf="accrualPayroll$ | async" ngbNavItem="accrual-payroll">
              <a ngbNavLink>
                <i class="fa fa-calendar" [class.success]="activePage == 'accrual-payroll'"></i>
                {{ 'FINANCES.JOURNALS.JOURNAL_DATA.ACCRUAL_PAYROLL_DATA' | translate }}
              </a>
              <ng-template ngbNavContent>
                <app-journal-accrual-payroll-details [accrualPayroll]="accrualPayroll$ | async">
                </app-journal-accrual-payroll-details>
              </ng-template>
            </li>
            <li *ngIf="payrollPayment$ | async" ngbNavItem="payroll-payment">
              <a ngbNavLink>
                <i class="fa fa-calendar" [class.success]="activePage == 'payroll-payment'"></i>
                {{ 'FINANCES.JOURNALS.JOURNAL_DATA.PAYROLL_PAYMENT_DATA' | translate }}
              </a>
              <ng-template ngbNavContent>
                <app-journal-payroll-payment-details [payrollPayment]="payrollPayment$ | async">
                </app-journal-payroll-payment-details>
              </ng-template>
            </li>
            <li *ngIf="employeeLoan$ | async" ngbNavItem="employee-loan">
              <a ngbNavLink>
                <i class="fa fa-calendar" [class.success]="activePage == 'employee-loan'"></i>
                {{ 'FINANCES.JOURNALS.JOURNAL_DATA.LOAN_DATA' | translate }}
              </a>
              <ng-template ngbNavContent>
                <app-journal-employee-loan-details [employeeLoan]="employeeLoan$ | async">
                </app-journal-employee-loan-details>
              </ng-template>
            </li>
            <li *ngIf="payrollPaymentRefund$ | async" ngbNavItem="payroll-payment-refund">
              <a ngbNavLink>
                <i class="fa fa-calendar" [class.success]="activePage == 'payroll-payment-refund'"></i>
                {{ 'FINANCES.JOURNALS.JOURNAL_DATA.PAYROLL_PAYMENT_REFUNDS_DATA' | translate }}
              </a>
              <ng-template ngbNavContent>
                <app-journal-payroll-payment-refund-details [payrollPaymentRefund]="payrollPaymentRefund$ | async">
                </app-journal-payroll-payment-refund-details>
              </ng-template>
            </li>
            <li *ngIf="govServiceRequest$ | async" ngbNavItem="governmental-service-request">
              <a ngbNavLink>
                <i class="fa fa-calendar" [class.success]="activePage == 'governmental-service-request'"></i>
                {{ 'FINANCES.JOURNALS.JOURNAL_DATA.THE_GOV_SERVICE_REQUEST_DATA' | translate }}
              </a>
              <ng-template ngbNavContent>
                <app-journal-gov-service-request-details [govServiceRequest]="govServiceRequest$ | async">
                </app-journal-gov-service-request-details>
              </ng-template>
            </li>
          </ul>
          <div [ngbNavOutlet]="nav" class="mt-2"></div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- view journal ends -->
