import { Action } from '@ngrx/store';

import { AppHttpResponse } from 'shared';
import { CreatePosPrinterAdjustmentInput, PosPrinterAdjustment, UpdatePosPrinterAdjustmentInput } from 'sales/models';

/**
 * The stores-module store pos adjustment printer action types.
 */
export enum PosPrinterAdjustmentActionType {
  SEARCH_KITCHEN_PRINTERS = '[Sales] [POSPrinterAdjustment] Search Kitchen Printer',
  SEARCH_KITCHEN_PRINTERS_FAIL = '[Sales] [POSPrinterAdjustment] Search Kitchen Printer Fail',
  SEARCH_KITCHEN_PRINTERS_SUCCESS = '[Sales] [POSPrinterAdjustment] Search Kitchen Printer Success',

  FIND_KITCHEN_PRINTER = '[Sales] [POSPrinterAdjustment] Find Kitchen Printer',
  FIND_KITCHEN_PRINTER_FAIL = '[Sales] [POSPrinterAdjustment] Find Kitchen Printer Fail',
  FIND_KITCHEN_PRINTER_SUCCESS = '[Sales] [POSPrinterAdjustment] Find Kitchen Printer Success',

  CREATE_KITCHEN_PRINTER = '[Sales] [POSPrinterAdjustment] Create Kitchen Printer',
  CREATE_KITCHEN_PRINTER_FAIL = '[Sales] [POSPrinterAdjustment] Create Kitchen Printer Fail',
  CREATE_KITCHEN_PRINTER_SUCCESS = '[Sales] [POSPrinterAdjustment] Create Kitchen Printer Success',

  UPDATE_KITCHEN_PRINTER = '[Sales] [POSPrinterAdjustment] Update Kitchen Printer',
  UPDATE_KITCHEN_PRINTER_FAIL = '[Sales] [POSPrinterAdjustment] Update Kitchen Printer Fail',
  UPDATE_KITCHEN_PRINTER_SUCCESS = '[Sales] [POSPrinterAdjustment] Update Kitchen Printer Success',

  DELETE_KITCHEN_PRINTER = '[Sales] [POSPrinterAdjustment] Delete Kitchen Printer',
  DELETE_KITCHEN_PRINTER_FAIL = '[Sales] [POSPrinterAdjustment] Delete Kitchen Printer Fail',
  DELETE_KITCHEN_PRINTER_SUCCESS = '[Sales] [POSPrinterAdjustment] Delete Kitchen Printer Success',

  SEARCH_CASHER_PRINTERS = '[Sales] [POSPrinterAdjustment] Search Casher Printer',
  SEARCH_CASHER_PRINTERS_FAIL = '[Sales] [POSPrinterAdjustment] Search Casher Printer Fail',
  SEARCH_CASHER_PRINTERS_SUCCESS = '[Sales] [POSPrinterAdjustment] Search Casher Printer Success',

  FIND_CASHER_PRINTER = '[Sales] [POSPrinterAdjustment] Find Casher Printer',
  FIND_CASHER_PRINTER_FAIL = '[Sales] [POSPrinterAdjustment] Find Casher Printer Fail',
  FIND_CASHER_PRINTER_SUCCESS = '[Sales] [POSPrinterAdjustment] Find Casher Printer Success',

  CREATE_CASHER_PRINTER = '[Sales] [POSPrinterAdjustment] Create Casher Printer',
  CREATE_CASHER_PRINTER_FAIL = '[Sales] [POSPrinterAdjustment] Create Casher Printer Fail',
  CREATE_CASHER_PRINTER_SUCCESS = '[Sales] [POSPrinterAdjustment] Create Casher Printer Success',

  UPDATE_CASHER_PRINTER = '[Sales] [POSPrinterAdjustment] Update Casher Printer',
  UPDATE_CASHER_PRINTER_FAIL = '[Sales] [POSPrinterAdjustment] Update Casher Printer Fail',
  UPDATE_CASHER_PRINTER_SUCCESS = '[Sales] [POSPrinterAdjustment] Update Casher Printer Success',

  DELETE_CASHER_PRINTER = '[Sales] [POSPrinterAdjustment] Delete Casher Printer',
  DELETE_CASHER_PRINTER_FAIL = '[Sales] [POSPrinterAdjustment] Delete Casher Printer Fail',
  DELETE_CASHER_PRINTER_SUCCESS = '[Sales] [POSPrinterAdjustment] Delete Casher Printer Success',

  SEARCH_ORDER_DELIVERY_PRINTERS = '[Sales] [POSPrinterAdjustment] Search Order Delivery Printer',
  SEARCH_ORDER_DELIVERY_PRINTERS_FAIL = '[Sales] [POSPrinterAdjustment] Search Order Delivery Printer Fail',
  SEARCH_ORDER_DELIVERY_PRINTERS_SUCCESS = '[Sales] [POSPrinterAdjustment] Search Order Delivery Printer Success',

  FIND_ORDER_DELIVERY_PRINTER = '[Sales] [POSPrinterAdjustment] Find Order Delivery Printer',
  FIND_ORDER_DELIVERY_PRINTER_FAIL = '[Sales] [POSPrinterAdjustment] Find Order Delivery Printer Fail',
  FIND_ORDER_DELIVERY_PRINTER_SUCCESS = '[Sales] [POSPrinterAdjustment] Find Order Delivery Printer Success',

  CREATE_ORDER_DELIVERY_PRINTER = '[Sales] [POSPrinterAdjustment] Create Order Delivery Printer',
  CREATE_ORDER_DELIVERY_PRINTER_FAIL = '[Sales] [POSPrinterAdjustment] Create Order Delivery Printer Fail',
  CREATE_ORDER_DELIVERY_PRINTER_SUCCESS = '[Sales] [POSPrinterAdjustment] Create Order Delivery Printer Success',

  UPDATE_ORDER_DELIVERY_PRINTER = '[Sales] [POSPrinterAdjustment] Update Order Delivery Printer',
  UPDATE_ORDER_DELIVERY_PRINTER_FAIL = '[Sales] [POSPrinterAdjustment] Update Order Delivery Printer Fail',
  UPDATE_ORDER_DELIVERY_PRINTER_SUCCESS = '[Sales] [POSPrinterAdjustment] Update Order Delivery Printer Success',

  DELETE_ORDER_DELIVERY_PRINTER = '[Sales] [POSPrinterAdjustment] Delete Order Delivery Printer',
  DELETE_ORDER_DELIVERY_PRINTER_FAIL = '[Sales] [POSPrinterAdjustment] Delete Order Delivery Printer Fail',
  DELETE_ORDER_DELIVERY_PRINTER_SUCCESS = '[Sales] [POSPrinterAdjustment] Delete Order Delivery Printer Success',
}

/**
 * Represents a store kitchen printers search action.
 */
export class SearchKitchenPrinters implements Action {
  /**
   * The type of the action.
   */
  readonly type = PosPrinterAdjustmentActionType.SEARCH_KITCHEN_PRINTERS;

  /**
   * Fires a new kitchen printer search action.
   * @param payload the search parameters,
   * if omitted, all kitchen printer will be loaded.
   */
  constructor(
    public payload: {
      productClasses: number[];
      posPrinters: number[];
      posDevices: number[];
      locations: number[];
      page: number;
    }
  ) {}
}

/**
 * Represents a store kitchen printer search fail action.
 */
export class SearchKitchenPrintersFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = PosPrinterAdjustmentActionType.SEARCH_KITCHEN_PRINTERS_FAIL;

  /**
   * Fires a new kitchen printer search fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store search success action.
 */
export class SearchKitchenPrintersSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = PosPrinterAdjustmentActionType.SEARCH_KITCHEN_PRINTERS_SUCCESS;

  /**
   * Fires a new kitchen printer search success action.
   * @param payload An object contains the list of kitchen printer that matches the search criteria
   * in addition to the pagination info.
   */
  constructor(public payload: AppHttpResponse<PosPrinterAdjustment[]>) {}
}

/**
 * Represents a store casher printers search action.
 */
export class SearchCasherPrinters implements Action {
  /**
   * The type of the action.
   */
  readonly type = PosPrinterAdjustmentActionType.SEARCH_CASHER_PRINTERS;

  /**
   * Fires a new casher printer search action.
   * @param payload the search parameters,
   * if omitted, all casher printer will be loaded.
   */
  constructor(
    public payload: {
      posPrinters: number[];
      posDevices: number[];
      locations: number[];
      page: number;
    }
  ) {}
}

/**
 * Represents a store casher printer search fail action.
 */
export class SearchCasherPrintersFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = PosPrinterAdjustmentActionType.SEARCH_CASHER_PRINTERS_FAIL;

  /**
   * Fires a new casher printer search fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store search success action.
 */
export class SearchCasherPrintersSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = PosPrinterAdjustmentActionType.SEARCH_CASHER_PRINTERS_SUCCESS;

  /**
   * Fires a new casher printer search success action.
   * @param payload An object contains the list of casher printer that matches the search criteria
   * in addition to the pagination info.
   */
  constructor(public payload: AppHttpResponse<PosPrinterAdjustment[]>) {}
}

/**
 * Represents a store order delivery printers search action.
 */
export class SearchOrderDeliveryPrinters implements Action {
  /**
   * The type of the action.
   */
  readonly type = PosPrinterAdjustmentActionType.SEARCH_ORDER_DELIVERY_PRINTERS;

  /**
   * Fires a new order delivery printer search action.
   * @param payload the search parameters,
   * if omitted, all order delivery printer will be loaded.
   */
  constructor(
    public payload: {
      orderTypes: number[];
      posPrinters: number[];
      posDevices: number[];
      locations: number[];
      page: number;
    }
  ) {}
}

/**
 * Represents a store order delivery printer search fail action.
 */
export class SearchOrderDeliveryPrintersFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = PosPrinterAdjustmentActionType.SEARCH_ORDER_DELIVERY_PRINTERS_FAIL;

  /**
   * Fires a new order delivery printer search fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store search success action.
 */
export class SearchOrderDeliveryPrintersSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = PosPrinterAdjustmentActionType.SEARCH_ORDER_DELIVERY_PRINTERS_SUCCESS;

  /**
   * Fires a new order delivery printer search success action.
   * @param payload An object contains the list of order delivery printer that matches the search criteria
   * in addition to the pagination info.
   */
  constructor(public payload: AppHttpResponse<PosPrinterAdjustment[]>) {}
}

/**
 * Represents a store find kitchen printer action.
 */
export class FindKitchenPrinter implements Action {
  /**
   * The type of the action.
   */
  readonly type = PosPrinterAdjustmentActionType.FIND_KITCHEN_PRINTER;

  /**
   * Fires a new find kitchen printer action.
   * @param payload the id of the kitchen printer.
   */
  constructor(public payload: number) {}
}

/**
 * Represents a store find kitchen printer fail action.
 */
export class FindKitchenPrinterFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = PosPrinterAdjustmentActionType.FIND_KITCHEN_PRINTER_FAIL;

  /**
   * Fires a new find kitchen printer fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store find kitchen printer success action.
 */
export class FindKitchenPrinterSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = PosPrinterAdjustmentActionType.FIND_KITCHEN_PRINTER_SUCCESS;

  /**
   * Fires a new find kitchen printer success action.
   * @param payload The loaded kitchen printer.
   */
  constructor(public payload: AppHttpResponse<PosPrinterAdjustment>) {}
}

/**
 * Represents a store find casher printer action.
 */
export class FindCasherPrinter implements Action {
  /**
   * The type of the action.
   */
  readonly type = PosPrinterAdjustmentActionType.FIND_CASHER_PRINTER;

  /**
   * Fires a new find casher printer action.
   * @param payload the id of the casher printer.
   */
  constructor(public payload: number) {}
}

/**
 * Represents a store find casher printer fail action.
 */
export class FindCasherPrinterFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = PosPrinterAdjustmentActionType.FIND_CASHER_PRINTER_FAIL;

  /**
   * Fires a new find casher printer fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store find casher printer success action.
 */
export class FindCasherPrinterSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = PosPrinterAdjustmentActionType.FIND_CASHER_PRINTER_SUCCESS;

  /**
   * Fires a new find casher printer success action.
   * @param payload The loaded casher printer.
   */
  constructor(public payload: AppHttpResponse<PosPrinterAdjustment>) {}
}

/**
 * Represents a store find order delivery printer action.
 */
export class FindOrderDeliveryPrinter implements Action {
  /**
   * The type of the action.
   */
  readonly type = PosPrinterAdjustmentActionType.FIND_ORDER_DELIVERY_PRINTER;

  /**
   * Fires a new find order delivery printer action.
   * @param payload the id of the order delivery printer.
   */
  constructor(public payload: number) {}
}

/**
 * Represents a store find order delivery printer fail action.
 */
export class FindOrderDeliveryPrinterFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = PosPrinterAdjustmentActionType.FIND_ORDER_DELIVERY_PRINTER_FAIL;

  /**
   * Fires a new find order delivery printer fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store find order delivery printer success action.
 */
export class FindOrderDeliveryPrinterSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = PosPrinterAdjustmentActionType.FIND_ORDER_DELIVERY_PRINTER_SUCCESS;

  /**
   * Fires a new find order delivery printer success action.
   * @param payload The loaded order delivery printer.
   */
  constructor(public payload: AppHttpResponse<PosPrinterAdjustment>) {}
}

/**
 * Represents a store kitchen printer create action.
 */
export class CreateKitchenPrinter implements Action {
  /** The type of the action. */
  readonly type = PosPrinterAdjustmentActionType.CREATE_KITCHEN_PRINTER;

  /**
   * Fires a new kitchen printer create action.
   * @param payload the new kitchen printer data-model.
   */
  constructor(public payload: CreatePosPrinterAdjustmentInput) {}
}

/**
 * Represents a store kitchen printer create fail action.
 */
export class CreateKitchenPrinterFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = PosPrinterAdjustmentActionType.CREATE_KITCHEN_PRINTER_FAIL;

  /**
   * Fires a new kitchen printer create fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store kitchen printer create success action.
 */
export class CreateKitchenPrinterSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = PosPrinterAdjustmentActionType.CREATE_KITCHEN_PRINTER_SUCCESS;

  /**
   * Fires a new kitchen printer create success action.
   * @param payload The created kitchen printer.
   */
  constructor(public payload: AppHttpResponse<PosPrinterAdjustment>) {}
}

/**
 * Represents a store casher printer create action.
 */
export class CreateCasherPrinter implements Action {
  /** The type of the action. */
  readonly type = PosPrinterAdjustmentActionType.CREATE_CASHER_PRINTER;

  /**
   * Fires a new casher printer create action.
   * @param payload the new casher printer data-model.
   */
  constructor(public payload: CreatePosPrinterAdjustmentInput) {}
}

/**
 * Represents a store casher printer create fail action.
 */
export class CreateCasherPrinterFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = PosPrinterAdjustmentActionType.CREATE_CASHER_PRINTER_FAIL;

  /**
   * Fires a new casher printer create fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store casher printer create success action.
 */
export class CreateCasherPrinterSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = PosPrinterAdjustmentActionType.CREATE_CASHER_PRINTER_SUCCESS;

  /**
   * Fires a new casher printer create success action.
   * @param payload The created casher printer.
   */
  constructor(public payload: AppHttpResponse<PosPrinterAdjustment>) {}
}

/**
 * Represents a store order delivery printer create action.
 */
export class CreateOrderDeliveryPrinter implements Action {
  /** The type of the action. */
  readonly type = PosPrinterAdjustmentActionType.CREATE_ORDER_DELIVERY_PRINTER;

  /**
   * Fires a new order delivery printer create action.
   * @param payload the new order delivery printer data-model.
   */
  constructor(public payload: CreatePosPrinterAdjustmentInput) {}
}

/**
 * Represents a store order delivery printer create fail action.
 */
export class CreateOrderDeliveryPrinterFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = PosPrinterAdjustmentActionType.CREATE_ORDER_DELIVERY_PRINTER_FAIL;

  /**
   * Fires a new order delivery printer create fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store order delivery printer create success action.
 */
export class CreateOrderDeliveryPrinterSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = PosPrinterAdjustmentActionType.CREATE_ORDER_DELIVERY_PRINTER_SUCCESS;

  /**
   * Fires a new order delivery printer create success action.
   * @param payload The created order delivery printer.
   */
  constructor(public payload: AppHttpResponse<PosPrinterAdjustment>) {}
}

/**
 * Represents a store kitchen printer update action.
 */
export class UpdateKitchenPrinter implements Action {
  /**
   * The type of the action.
   */
  readonly type = PosPrinterAdjustmentActionType.UPDATE_KITCHEN_PRINTER;

  /**
   * Fires a new kitchen printer update action.
   * @param payload The  kitchen printer updated data.
   */
  constructor(public payload: UpdatePosPrinterAdjustmentInput) {}
}

/**
 * Represents a store kitchen printer update fail action.
 */
export class UpdateKitchenPrinterFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = PosPrinterAdjustmentActionType.UPDATE_KITCHEN_PRINTER_FAIL;

  /**
   * Fires a new kitchen printer update fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store kitchen printer update success action.
 */
export class UpdateKitchenPrinterSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = PosPrinterAdjustmentActionType.UPDATE_KITCHEN_PRINTER_SUCCESS;

  /**
   * Fires a new kitchen printer update-success action.
   * @param payload The updated kitchen printer.
   */
  constructor(public payload: AppHttpResponse<PosPrinterAdjustment>) {}
}

/**
 * Represents a store casher printer update action.
 */
export class UpdateCasherPrinter implements Action {
  /**
   * The type of the action.
   */
  readonly type = PosPrinterAdjustmentActionType.UPDATE_CASHER_PRINTER;

  /**
   * Fires a new casher printer update action.
   * @param payload The  casher printer updated data.
   */
  constructor(public payload: UpdatePosPrinterAdjustmentInput) {}
}

/**
 * Represents a store casher printer update fail action.
 */
export class UpdateCasherPrinterFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = PosPrinterAdjustmentActionType.UPDATE_CASHER_PRINTER_FAIL;

  /**
   * Fires a new casher printer update fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store casher printer update success action.
 */
export class UpdateCasherPrinterSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = PosPrinterAdjustmentActionType.UPDATE_CASHER_PRINTER_SUCCESS;

  /**
   * Fires a new casher printer update-success action.
   * @param payload The updated casher printer.
   */
  constructor(public payload: AppHttpResponse<PosPrinterAdjustment>) {}
}

/**
 * Represents a store order delivery printer update action.
 */
export class UpdateOrderDeliveryPrinter implements Action {
  /**
   * The type of the action.
   */
  readonly type = PosPrinterAdjustmentActionType.UPDATE_ORDER_DELIVERY_PRINTER;

  /**
   * Fires a new order delivery printer update action.
   * @param payload The  order delivery printer updated data.
   */
  constructor(public payload: UpdatePosPrinterAdjustmentInput) {}
}

/**
 * Represents a store order delivery printer update fail action.
 */
export class UpdateOrderDeliveryPrinterFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = PosPrinterAdjustmentActionType.UPDATE_ORDER_DELIVERY_PRINTER_FAIL;

  /**
   * Fires a new order delivery printer update fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store order delivery printer update success action.
 */
export class UpdateOrderDeliveryPrinterSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = PosPrinterAdjustmentActionType.UPDATE_ORDER_DELIVERY_PRINTER_SUCCESS;

  /**
   * Fires a new order delivery printer update-success action.
   * @param payload The updated order delivery printer.
   */
  constructor(public payload: AppHttpResponse<PosPrinterAdjustment>) {}
}

/**
 * Represents a store kitchen printer delete action.
 */
export class DeleteKitchenPrinter implements Action {
  /**
   * The type of the action.
   */
  readonly type = PosPrinterAdjustmentActionType.DELETE_KITCHEN_PRINTER;

  /**
   * Fires a new kitchen printer delete action.
   * @param payload The kitchen printer id.
   */
  constructor(public payload: number) {}
}

/**
 * Represents a store kitchen printer delete fail action.
 */
export class DeleteKitchenPrinterFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = PosPrinterAdjustmentActionType.DELETE_KITCHEN_PRINTER_FAIL;

  /**
   * Fires a new kitchen printer delete fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store kitchen printer delete success action.
 */
export class DeleteKitchenPrinterSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = PosPrinterAdjustmentActionType.DELETE_KITCHEN_PRINTER_SUCCESS;

  /**
   * Fires a new kitchen printer delete success action.
   * @param payload The deleted kitchen printer.
   */
  constructor(public payload: AppHttpResponse<PosPrinterAdjustment>) {}
}

/**
 * Represents a store casher printer delete action.
 */
export class DeleteCasherPrinter implements Action {
  /**
   * The type of the action.
   */
  readonly type = PosPrinterAdjustmentActionType.DELETE_CASHER_PRINTER;

  /**
   * Fires a new casher printer delete action.
   * @param payload The casher printer id.
   */
  constructor(public payload: number) {}
}

/**
 * Represents a store casher printer delete fail action.
 */
export class DeleteCasherPrinterFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = PosPrinterAdjustmentActionType.DELETE_CASHER_PRINTER_FAIL;

  /**
   * Fires a new casher printer delete fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store casher printer delete success action.
 */
export class DeleteCasherPrinterSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = PosPrinterAdjustmentActionType.DELETE_CASHER_PRINTER_SUCCESS;

  /**
   * Fires a new casher printer delete success action.
   * @param payload The deleted casher printer.
   */
  constructor(public payload: AppHttpResponse<PosPrinterAdjustment>) {}
}

/**
 * Represents a store order delivery printer delete action.
 */
export class DeleteOrderDeliveryPrinter implements Action {
  /**
   * The type of the action.
   */
  readonly type = PosPrinterAdjustmentActionType.DELETE_ORDER_DELIVERY_PRINTER;

  /**
   * Fires a new order delivery printer delete action.
   * @param payload The order delivery printer id.
   */
  constructor(public payload: number) {}
}

/**
 * Represents a store order delivery printer delete fail action.
 */
export class DeleteOrderDeliveryPrinterFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = PosPrinterAdjustmentActionType.DELETE_ORDER_DELIVERY_PRINTER_FAIL;

  /**
   * Fires a new order delivery printer delete fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store order delivery printer delete success action.
 */
export class DeleteOrderDeliveryPrinterSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = PosPrinterAdjustmentActionType.DELETE_ORDER_DELIVERY_PRINTER_SUCCESS;

  /**
   * Fires a new order delivery printer delete success action.
   * @param payload The deleted order delivery printer.
   */
  constructor(public payload: AppHttpResponse<PosPrinterAdjustment>) {}
}

/**
 * stores-module pos adjustment printer action types.
 */
export type PosPrinterAdjustmentActions =
  | SearchKitchenPrinters
  | SearchKitchenPrintersFail
  | SearchKitchenPrintersSuccess
  | FindKitchenPrinter
  | FindKitchenPrinterFail
  | FindKitchenPrinterSuccess
  | CreateKitchenPrinter
  | CreateKitchenPrinterFail
  | CreateKitchenPrinterSuccess
  | UpdateKitchenPrinter
  | UpdateKitchenPrinterFail
  | UpdateKitchenPrinterSuccess
  | DeleteKitchenPrinter
  | DeleteKitchenPrinterFail
  | DeleteKitchenPrinterSuccess
  | SearchCasherPrinters
  | SearchCasherPrintersFail
  | SearchCasherPrintersSuccess
  | FindCasherPrinter
  | FindCasherPrinterFail
  | FindCasherPrinterSuccess
  | CreateCasherPrinter
  | CreateCasherPrinterFail
  | CreateCasherPrinterSuccess
  | UpdateCasherPrinter
  | UpdateCasherPrinterFail
  | UpdateCasherPrinterSuccess
  | DeleteCasherPrinter
  | DeleteCasherPrinterFail
  | DeleteCasherPrinterSuccess
  | SearchOrderDeliveryPrinters
  | SearchOrderDeliveryPrintersFail
  | SearchOrderDeliveryPrintersSuccess
  | FindOrderDeliveryPrinter
  | FindOrderDeliveryPrinterFail
  | FindOrderDeliveryPrinterSuccess
  | CreateOrderDeliveryPrinter
  | CreateOrderDeliveryPrinterFail
  | CreateOrderDeliveryPrinterSuccess
  | UpdateOrderDeliveryPrinter
  | UpdateOrderDeliveryPrinterFail
  | UpdateOrderDeliveryPrinterSuccess
  | DeleteOrderDeliveryPrinter
  | DeleteOrderDeliveryPrinterFail
  | DeleteOrderDeliveryPrinterSuccess;
