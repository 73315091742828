import { findIndex, remove } from 'lodash';

import { PosPrinterAdjustmentActionType, PosPrinterAdjustmentActions } from 'sales/store/actions';
import { PosPrinterAdjustmentsState } from 'sales/store/states';

/**
 * The initial state from which the state starts.
 */
const initialState: PosPrinterAdjustmentsState = {
  kitchenPrinterData: [],
  casherPrinterData: [],
  orderDeliveryPrinterData: [],
  kitchenPrinterPaginationInfo: {
    total: 0,
    page: 1,
    pageSize: 0,
    count: 0,
  },
  casherPrinterPaginationInfo: {
    total: 0,
    page: 1,
    pageSize: 0,
    count: 0,
  },
  orderDeliveryPrinterPaginationInfo: {
    total: 0,
    page: 1,
    pageSize: 0,
    count: 0,
  },
  error: null,
  isKitchenPrintersSearching: false,
  isKitchenPrintersSearchCompleted: false,
  selectedKitchenPrinter: null,
  isKitchenPrinterFinding: false,
  isKitchenPrinterFindCompleted: false,
  isKitchenPrinterCreating: false,
  isKitchenPrinterCreateCompleted: false,
  isKitchenPrinterUpdating: false,
  isKitchenPrinterUpdateCompleted: false,
  isKitchenPrinterDeleting: false,
  isKitchenPrinterDeleteCompleted: false,
  isCasherPrintersSearching: false,
  isCasherPrintersSearchCompleted: false,
  selectedCasherPrinter: null,
  isCasherPrinterFinding: false,
  isCasherPrinterFindCompleted: false,
  isCasherPrinterCreating: false,
  isCasherPrinterCreateCompleted: false,
  isCasherPrinterUpdating: false,
  isCasherPrinterUpdateCompleted: false,
  isCasherPrinterDeleting: false,
  isCasherPrinterDeleteCompleted: false,
  isOrderDeliveryPrintersSearching: false,
  isOrderDeliveryPrintersSearchCompleted: false,
  selectedOrderDeliveryPrinter: null,
  isOrderDeliveryPrinterFinding: false,
  isOrderDeliveryPrinterFindCompleted: false,
  isOrderDeliveryPrinterCreating: false,
  isOrderDeliveryPrinterCreateCompleted: false,
  isOrderDeliveryPrinterUpdating: false,
  isOrderDeliveryPrinterUpdateCompleted: false,
  isOrderDeliveryPrinterDeleting: false,
  isOrderDeliveryPrinterDeleteCompleted: false,
};

/**
 * The reducer function that is called in each action dispatch against the state.
 * @param state The current state.
 * @param action The action that will affect the state.
 */
export function PosPrinterAdjustmentsReducer(
  state: PosPrinterAdjustmentsState = initialState,
  action: PosPrinterAdjustmentActions
): PosPrinterAdjustmentsState {
  switch (action.type) {
    //#region SEARCH_KITCHEN_PRINTER

    case PosPrinterAdjustmentActionType.SEARCH_KITCHEN_PRINTERS: {
      return {
        ...state,
        error: null,
        isKitchenPrintersSearching: true,
        isKitchenPrintersSearchCompleted: false,
      };
    }

    case PosPrinterAdjustmentActionType.SEARCH_KITCHEN_PRINTERS_FAIL: {
      return {
        ...state,
        error: action.payload,
        isKitchenPrintersSearching: false,
        isKitchenPrintersSearchCompleted: false,
      };
    }

    case PosPrinterAdjustmentActionType.SEARCH_KITCHEN_PRINTERS_SUCCESS: {
      return {
        ...state,
        kitchenPrinterData: action.payload.data,
        kitchenPrinterPaginationInfo: {
          ...action.payload.meta,
        },
        error: null,
        isKitchenPrintersSearching: false,
        isKitchenPrintersSearchCompleted: true,
      };
    }

    //#endregion SEARCH_KITCHEN_PRINTER

    //#region SEARCH_CASHER_PRINTER

    case PosPrinterAdjustmentActionType.SEARCH_CASHER_PRINTERS: {
      return {
        ...state,
        error: null,
        isCasherPrintersSearching: true,
        isCasherPrintersSearchCompleted: false,
      };
    }

    case PosPrinterAdjustmentActionType.SEARCH_CASHER_PRINTERS_FAIL: {
      return {
        ...state,
        error: action.payload,
        isCasherPrintersSearching: false,
        isCasherPrintersSearchCompleted: false,
      };
    }

    case PosPrinterAdjustmentActionType.SEARCH_CASHER_PRINTERS_SUCCESS: {
      return {
        ...state,
        casherPrinterData: action.payload.data,
        casherPrinterPaginationInfo: {
          ...action.payload.meta,
        },
        error: null,
        isCasherPrintersSearching: false,
        isCasherPrintersSearchCompleted: true,
      };
    }

    //#endregion SEARCH_CASHER_PRINTER

    //#region SEARCH_ORDER_DELIVERY_PRINTER

    case PosPrinterAdjustmentActionType.SEARCH_ORDER_DELIVERY_PRINTERS: {
      return {
        ...state,
        error: null,
        isOrderDeliveryPrintersSearching: true,
        isOrderDeliveryPrintersSearchCompleted: false,
      };
    }

    case PosPrinterAdjustmentActionType.SEARCH_ORDER_DELIVERY_PRINTERS_FAIL: {
      return {
        ...state,
        error: action.payload,
        isOrderDeliveryPrintersSearching: false,
        isOrderDeliveryPrintersSearchCompleted: false,
      };
    }

    case PosPrinterAdjustmentActionType.SEARCH_ORDER_DELIVERY_PRINTERS_SUCCESS: {
      return {
        ...state,
        orderDeliveryPrinterData: action.payload.data,
        orderDeliveryPrinterPaginationInfo: {
          ...action.payload.meta,
        },
        error: null,
        isOrderDeliveryPrintersSearching: false,
        isOrderDeliveryPrintersSearchCompleted: true,
      };
    }

    //#endregion SEARCH_ORDER_DELIVERY_PRINTER

    //#region FIND_KITCHEN_PRINTER

    case PosPrinterAdjustmentActionType.FIND_KITCHEN_PRINTER: {
      return {
        ...state,
        selectedKitchenPrinter: null,
        error: null,
        isKitchenPrinterFinding: true,
        isKitchenPrinterFindCompleted: false,
      };
    }

    case PosPrinterAdjustmentActionType.FIND_KITCHEN_PRINTER_FAIL: {
      return {
        ...state,
        error: action.payload,
        isKitchenPrinterFinding: false,
        isKitchenPrinterFindCompleted: false,
      };
    }
    case PosPrinterAdjustmentActionType.FIND_KITCHEN_PRINTER_SUCCESS: {
      let kitchenPrinters = [...state.kitchenPrinterData];
      const kitchenPrinterIndex = findIndex(
        kitchenPrinters,
        (kitchenPrinter) => kitchenPrinter.id === action.payload.data.id
      );

      /* If kitchen printers was found, update it. */
      if (kitchenPrinterIndex >= 0) {
        kitchenPrinters[kitchenPrinterIndex] = action.payload.data;
      } else {
        /* else, insert it to the beginning of the array. */
        kitchenPrinters = [action.payload.data, ...kitchenPrinters];
      }

      return {
        ...state,
        kitchenPrinterData: kitchenPrinters,
        selectedKitchenPrinter: action.payload.data,
        error: null,
        isKitchenPrinterFinding: false,
        isKitchenPrinterFindCompleted: true,
      };
    }

    //#endregion FIND_KITCHEN_PRINTER

    //#region FIND_CASHER_PRINTER
    case PosPrinterAdjustmentActionType.FIND_CASHER_PRINTER: {
      return {
        ...state,
        selectedCasherPrinter: null,
        error: null,
        isCasherPrinterFinding: true,
        isCasherPrinterFindCompleted: false,
      };
    }

    case PosPrinterAdjustmentActionType.FIND_CASHER_PRINTER_FAIL: {
      return {
        ...state,
        error: action.payload,
        isCasherPrinterFinding: false,
        isCasherPrinterFindCompleted: false,
      };
    }
    case PosPrinterAdjustmentActionType.FIND_CASHER_PRINTER_SUCCESS: {
      let casherPrinters = [...state.casherPrinterData];
      const casherPrinterIndex = findIndex(
        casherPrinters,
        (casherPrinter) => casherPrinter.id === action.payload.data.id
      );

      /* If casher printers was found, update it. */
      if (casherPrinterIndex >= 0) {
        casherPrinters[casherPrinterIndex] = action.payload.data;
      } else {
        /* else, insert it to the beginning of the array. */
        casherPrinters = [action.payload.data, ...casherPrinters];
      }

      return {
        ...state,
        casherPrinterData: casherPrinters,
        selectedCasherPrinter: action.payload.data,
        error: null,
        isCasherPrinterFinding: false,
        isCasherPrinterFindCompleted: true,
      };
    }

    //#endregion FIND_CASHER_PRINTER

    //#region FIND_ORDER_DELIVERY_PRINTER
    case PosPrinterAdjustmentActionType.FIND_ORDER_DELIVERY_PRINTER: {
      return {
        ...state,
        selectedOrderDeliveryPrinter: null,
        error: null,
        isOrderDeliveryPrinterFinding: true,
        isOrderDeliveryPrinterFindCompleted: false,
      };
    }

    case PosPrinterAdjustmentActionType.FIND_ORDER_DELIVERY_PRINTER_FAIL: {
      return {
        ...state,
        error: action.payload,
        isOrderDeliveryPrinterFinding: false,
        isOrderDeliveryPrinterFindCompleted: false,
      };
    }
    case PosPrinterAdjustmentActionType.FIND_ORDER_DELIVERY_PRINTER_SUCCESS: {
      let orderDeliveryPrinters = [...state.orderDeliveryPrinterData];
      const orderDeliveryPrinterIndex = findIndex(
        orderDeliveryPrinters,
        (orderDeliveryPrinter) => orderDeliveryPrinter.id === action.payload.data.id
      );

      /* If orderDelivery printers was found, update it. */
      if (orderDeliveryPrinterIndex >= 0) {
        orderDeliveryPrinters[orderDeliveryPrinterIndex] = action.payload.data;
      } else {
        /* else, insert it to the beginning of the array. */
        orderDeliveryPrinters = [action.payload.data, ...orderDeliveryPrinters];
      }

      return {
        ...state,
        orderDeliveryPrinterData: orderDeliveryPrinters,
        selectedOrderDeliveryPrinter: action.payload.data,
        error: null,
        isOrderDeliveryPrinterFinding: false,
        isOrderDeliveryPrinterFindCompleted: true,
      };
    }
    //#endregion FIND_ORDER_DELIVERY_PRINTER

    //#region CREATE_KITCHEN_PRINTER
    case PosPrinterAdjustmentActionType.CREATE_KITCHEN_PRINTER: {
      return {
        ...state,
        error: null,
        isKitchenPrinterCreating: true,
        isKitchenPrinterCreateCompleted: false,
      };
    }

    case PosPrinterAdjustmentActionType.CREATE_KITCHEN_PRINTER_FAIL: {
      return {
        ...state,
        error: action.payload,
        isKitchenPrinterCreating: false,
        isKitchenPrinterCreateCompleted: false,
      };
    }

    case PosPrinterAdjustmentActionType.CREATE_KITCHEN_PRINTER_SUCCESS: {
      return {
        ...state,
        kitchenPrinterData: [action.payload.data, ...state.kitchenPrinterData],
        selectedKitchenPrinter: action.payload.data,
        error: null,
        isKitchenPrinterCreating: false,
        isKitchenPrinterCreateCompleted: true,
      };
    }

    //#endregion CREATE_KITCHEN_PRINTER

    //#region CREATE_CASHER_PRINTER
    case PosPrinterAdjustmentActionType.CREATE_CASHER_PRINTER: {
      return {
        ...state,
        error: null,
        isCasherPrinterCreating: true,
        isCasherPrinterCreateCompleted: false,
      };
    }

    case PosPrinterAdjustmentActionType.CREATE_CASHER_PRINTER_FAIL: {
      return {
        ...state,
        error: action.payload,
        isCasherPrinterCreating: false,
        isCasherPrinterCreateCompleted: false,
      };
    }

    case PosPrinterAdjustmentActionType.CREATE_CASHER_PRINTER_SUCCESS: {
      return {
        ...state,
        casherPrinterData: [action.payload.data, ...state.casherPrinterData],
        selectedCasherPrinter: action.payload.data,
        error: null,
        isCasherPrinterCreating: false,
        isCasherPrinterCreateCompleted: true,
      };
    }

    //#endregion CREATE_CASHER_PRINTER

    //#region CREATE_ORDER_DELIVERY_PRINTER
    case PosPrinterAdjustmentActionType.CREATE_ORDER_DELIVERY_PRINTER: {
      return {
        ...state,
        error: null,
        isOrderDeliveryPrinterCreating: true,
        isOrderDeliveryPrinterCreateCompleted: false,
      };
    }

    case PosPrinterAdjustmentActionType.CREATE_ORDER_DELIVERY_PRINTER_FAIL: {
      return {
        ...state,
        error: action.payload,
        isOrderDeliveryPrinterCreating: false,
        isOrderDeliveryPrinterCreateCompleted: false,
      };
    }

    case PosPrinterAdjustmentActionType.CREATE_ORDER_DELIVERY_PRINTER_SUCCESS: {
      return {
        ...state,
        orderDeliveryPrinterData: [action.payload.data, ...state.orderDeliveryPrinterData],
        selectedOrderDeliveryPrinter: action.payload.data,
        error: null,
        isOrderDeliveryPrinterCreating: false,
        isOrderDeliveryPrinterCreateCompleted: true,
      };
    }

    //#endregion CREATE_ORDER_DELIVERY_PRINTER

    //#region UPDATE_KITCHEN_PRINTER
    case PosPrinterAdjustmentActionType.UPDATE_KITCHEN_PRINTER: {
      return {
        ...state,
        error: null,
        isKitchenPrinterUpdating: true,
        isKitchenPrinterUpdateCompleted: false,
      };
    }

    case PosPrinterAdjustmentActionType.UPDATE_KITCHEN_PRINTER_FAIL: {
      return {
        ...state,
        error: action.payload,
        isKitchenPrinterUpdating: false,
        isKitchenPrinterUpdateCompleted: false,
      };
    }

    case PosPrinterAdjustmentActionType.UPDATE_KITCHEN_PRINTER_SUCCESS: {
      let kitchenPrinters = [...state.kitchenPrinterData];
      const kitchenPrinterIndex = findIndex(
        kitchenPrinters,
        (kitchenPrinter) => kitchenPrinter.id === action.payload.data.id
      );

      /* If kitchen printers was found, update it. */
      if (kitchenPrinterIndex >= 0) {
        kitchenPrinters[kitchenPrinterIndex] = action.payload.data;
      } else {
        /* else, insert it to the beginning of the array. */
        kitchenPrinters = [action.payload.data, ...kitchenPrinters];
      }

      return {
        ...state,
        kitchenPrinterData: kitchenPrinters,
        selectedKitchenPrinter: action.payload.data,
        error: null,
        isKitchenPrinterUpdating: false,
        isKitchenPrinterUpdateCompleted: true,
      };
    }

    //#endregion UPDATE_KITCHEN_PRINTER

    //#region UPDATE_CASHER_PRINTER
    case PosPrinterAdjustmentActionType.UPDATE_CASHER_PRINTER: {
      return {
        ...state,
        error: null,
        isCasherPrinterUpdating: true,
        isCasherPrinterUpdateCompleted: false,
      };
    }

    case PosPrinterAdjustmentActionType.UPDATE_CASHER_PRINTER_FAIL: {
      return {
        ...state,
        error: action.payload,
        isCasherPrinterUpdating: false,
        isCasherPrinterUpdateCompleted: false,
      };
    }

    case PosPrinterAdjustmentActionType.UPDATE_CASHER_PRINTER_SUCCESS: {
      let casherPrinters = [...state.casherPrinterData];
      const casherPrinterIndex = findIndex(
        casherPrinters,
        (casherPrinter) => casherPrinter.id === action.payload.data.id
      );

      /* If casher printers was found, update it. */
      if (casherPrinterIndex >= 0) {
        casherPrinters[casherPrinterIndex] = action.payload.data;
      } else {
        /* else, insert it to the beginning of the array. */
        casherPrinters = [action.payload.data, ...casherPrinters];
      }

      return {
        ...state,
        casherPrinterData: casherPrinters,
        selectedCasherPrinter: action.payload.data,
        error: null,
        isCasherPrinterUpdating: false,
        isCasherPrinterUpdateCompleted: true,
      };
    }

    //#endregion UPDATE_CASHER_PRINTER

    //#region UPDATE_ORDER_DELIVERY_PRINTER
    case PosPrinterAdjustmentActionType.UPDATE_ORDER_DELIVERY_PRINTER: {
      return {
        ...state,
        error: null,
        isOrderDeliveryPrinterUpdating: true,
        isOrderDeliveryPrinterUpdateCompleted: false,
      };
    }

    case PosPrinterAdjustmentActionType.UPDATE_ORDER_DELIVERY_PRINTER_FAIL: {
      return {
        ...state,
        error: action.payload,
        isOrderDeliveryPrinterUpdating: false,
        isOrderDeliveryPrinterUpdateCompleted: false,
      };
    }

    case PosPrinterAdjustmentActionType.UPDATE_ORDER_DELIVERY_PRINTER_SUCCESS: {
      let orderDeliveryPrinters = [...state.orderDeliveryPrinterData];
      const orderDeliveryPrinterIndex = findIndex(
        orderDeliveryPrinters,
        (orderDeliveryPrinter) => orderDeliveryPrinter.id === action.payload.data.id
      );

      /* If orderDelivery printers was found, update it. */
      if (orderDeliveryPrinterIndex >= 0) {
        orderDeliveryPrinters[orderDeliveryPrinterIndex] = action.payload.data;
      } else {
        /* else, insert it to the beginning of the array. */
        orderDeliveryPrinters = [action.payload.data, ...orderDeliveryPrinters];
      }

      return {
        ...state,
        orderDeliveryPrinterData: orderDeliveryPrinters,
        selectedOrderDeliveryPrinter: action.payload.data,
        error: null,
        isOrderDeliveryPrinterUpdating: false,
        isOrderDeliveryPrinterUpdateCompleted: true,
      };
    }

    //#endregion UPDATE_ORDER_DELIVERY_PRINTER

    //#region DELETE_KITCHEN_PRINTER

    case PosPrinterAdjustmentActionType.DELETE_KITCHEN_PRINTER: {
      return {
        ...state,
        error: null,
        isKitchenPrinterDeleting: true,
        isKitchenPrinterDeleteCompleted: false,
      };
    }

    case PosPrinterAdjustmentActionType.DELETE_KITCHEN_PRINTER_FAIL: {
      return {
        ...state,
        error: action.payload,
        isKitchenPrinterDeleting: false,
        isKitchenPrinterDeleteCompleted: false,
      };
    }

    case PosPrinterAdjustmentActionType.DELETE_KITCHEN_PRINTER_SUCCESS: {
      const kitchenPrinters = [...state.kitchenPrinterData];

      /* Remove the deleted pos printer from the array. */
      remove(kitchenPrinters, (kitchenPrinter) => kitchenPrinter.id === action.payload.data.id);

      return {
        ...state,
        kitchenPrinterData: kitchenPrinters,
        selectedKitchenPrinter: action.payload.data,
        error: null,
        isKitchenPrinterDeleting: false,
        isKitchenPrinterDeleteCompleted: true,
      };
    }

    //#endregion DELETE_KITCHEN_PRINTER

    //#region DELETE_CASHER_PRINTER

    case PosPrinterAdjustmentActionType.DELETE_CASHER_PRINTER: {
      return {
        ...state,
        error: null,
        isCasherPrinterDeleting: true,
        isCasherPrinterDeleteCompleted: false,
      };
    }

    case PosPrinterAdjustmentActionType.DELETE_CASHER_PRINTER_FAIL: {
      return {
        ...state,
        error: action.payload,
        isCasherPrinterDeleting: false,
        isCasherPrinterDeleteCompleted: false,
      };
    }

    case PosPrinterAdjustmentActionType.DELETE_CASHER_PRINTER_SUCCESS: {
      const casherPrinters = [...state.casherPrinterData];

      /* Remove the deleted pos printer from the array. */
      remove(casherPrinters, (casherPrinter) => casherPrinter.id === action.payload.data.id);

      return {
        ...state,
        casherPrinterData: casherPrinters,
        selectedCasherPrinter: action.payload.data,
        error: null,
        isCasherPrinterDeleting: false,
        isCasherPrinterDeleteCompleted: true,
      };
    }

    //#endregion DELETE_CASHER_PRINTER

    //#region DELETE_ORDER_DELIVERY_PRINTER

    case PosPrinterAdjustmentActionType.DELETE_ORDER_DELIVERY_PRINTER: {
      return {
        ...state,
        error: null,
        isOrderDeliveryPrinterDeleting: true,
        isOrderDeliveryPrinterDeleteCompleted: false,
      };
    }

    case PosPrinterAdjustmentActionType.DELETE_ORDER_DELIVERY_PRINTER_FAIL: {
      return {
        ...state,
        error: action.payload,
        isOrderDeliveryPrinterDeleting: false,
        isOrderDeliveryPrinterDeleteCompleted: false,
      };
    }

    case PosPrinterAdjustmentActionType.DELETE_ORDER_DELIVERY_PRINTER_SUCCESS: {
      const orderDeliveryPrinters = [...state.orderDeliveryPrinterData];

      /* Remove the deleted pos printer from the array. */
      remove(orderDeliveryPrinters, (orderDeliveryPrinter) => orderDeliveryPrinter.id === action.payload.data.id);

      return {
        ...state,
        orderDeliveryPrinterData: orderDeliveryPrinters,
        selectedOrderDeliveryPrinter: action.payload.data,
        error: null,
        isOrderDeliveryPrinterDeleting: false,
        isOrderDeliveryPrinterDeleteCompleted: true,
      };
    }

    //#endregion DELETE_ORDER_DELIVERY_PRINTER

    default:
      return state;
  }
}
