import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';

import { Observable, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Store, select } from '@ngrx/store';

import * as fromSalesStore from 'sales/store';
import * as fromLookupsStore from 'lookups/store';
import * as fromAuthStore from 'auth/store';
import { NotificationService, PaginationInfo, TranslationService } from 'shared';
import { SaleInvoiceOrderType, SaleInvoiceType } from 'lookups';
import { SaleInvoice, Customer } from 'sales/models';
import { Location } from 'stores/models';

/**
 * A sale invoices search form wrapped in modal.
 */
@Component({
  selector: 'app-sale-invoices-search',
  templateUrl: './sale-invoices-search.component.html',
  styles: [],
})
export class SaleInvoicesSearchComponent implements OnInit, OnDestroy {
  /**
   * The modal template reference.
   */
  @ViewChild('modalRef') modalRef: ElementRef<any>;

  /**
   * Indicates whether the modal will automatically close after select the first invoice.
   *
   * Be default it will not be closed.
   */
  @Input() closeOnSelect = false;

  /**
   * Indicates whether the invoices displayed on the list should be all active.
   *
   * Be default it will not be closed.
   */
  @Input() onlyActive = false;

  /**
   * Sets the visibility of this component modal.
   *
   * Handles visibility change in two-data-binding approach.
   */
  @Input() set visible(isShown: boolean) {
    if (isShown && this.modalRef) {
      this.openModal(this.modalRef);
    } else {
      this.closeModal();
    }

    this.visibleChange.emit(isShown);
  }

  /**
   * Outputs a value that indicates whether the modal is shown or not.
   *
   * Handles visibility change in two-data-binding approach.
   */
  @Output() visibleChange = new EventEmitter<boolean>();

  /** Outputs the selected invoices. */
  @Output() invoices = new EventEmitter<SaleInvoice[]>();

  /**
   * The list of invoices.
   */
  invoices$: Observable<SaleInvoice[]>;

  /**
   * The list of invoices.
   */
  invoicesList: SaleInvoice[];

  /**
   * The list of selected customers.
   */
  customers: Customer[] = [];

  /**
   * The list of selected sale invoice types.
   */
  saleInvoiceTypes$: Observable<SaleInvoiceType[]>;

  /**
   * The list of selected sale invoice order types.
   */
  saleInvoiceOrderTypes$: Observable<SaleInvoiceOrderType[]>;

  /**
   * The pagination info.
   */
  paginationInfo$: Observable<PaginationInfo>;

  /**
   * Indicates whether there is a search process is running or not.
   */
  isSearching$: Observable<boolean>;

  /**
   * The search form.
   */
  searchForm: FormGroup;

  /**
   * The set of subscriptions on this components,
   * these subscriptions must be unsubscribed before this component got destroyed.
   */
  subscriptions = new Subscription();

  /**
   * Shows or hide the locations list.
   */
  locationsListVisibility = false;

  /**
   * The list of selected locations.
   */
  locations: Location[] = [];

  /**
   * Shows or hide the customers list.
   */
  customersListVisibility = false;

  /** The initial `fromDate` value for search. */
  initialFromDate = new Date();

  /** The initial `toDate` value for search.
   * It's the current day of month.
   */
  initialToDate = new Date();

  /**
   * The ngb-modal reference to use it when modal needed to be closed.
   */
  modal: NgbModalRef;

  /**
   *
   * @param modalService The modal service.
   * @param notificationService The notification service.
   * @param translationService: The translation service.
   * @param salesStore$ The sales-module store.
   * @param lookupsStore$ The lookups-module store.
   * @param authStore$ the auth-store module.
   */
  constructor(
    private modalService: NgbModal,
    private notificationService: NotificationService,
    private translationService: TranslationService,
    private salesStore$: Store<fromSalesStore.SalesState>,
    private lookupsStore$: Store<fromLookupsStore.LookupsState>,
    private authStore$: Store<fromAuthStore.AuthState>
  ) {}

  ngOnInit(): void {
    this.init();
  }

  /**
   * Destroy component data
   */
  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  /**
   * Initialize component data.
   */
  init() {
    this.initForm();

    /**
     * Loads data.
     */
    this.isSearching$ = this.salesStore$.pipe(select(fromSalesStore.getSaleInvoicesSearching));

    let isManualSearchTriggeredBeforeForInvoiceTypes = false;
    this.saleInvoiceTypes$ = this.lookupsStore$.pipe(
      select(fromLookupsStore.getSaleInvoiceTypes),
      tap((invoiceTypes) => {
        if (!isManualSearchTriggeredBeforeForInvoiceTypes && !invoiceTypes.length) {
          isManualSearchTriggeredBeforeForInvoiceTypes = true;
          this.lookupsStore$.dispatch(new fromLookupsStore.GetAllSaleInvoiceType());
        }
      })
    );

    let isManualSearchTriggeredBeforeForInvoiceOrderTypes = false;
    this.saleInvoiceOrderTypes$ = this.lookupsStore$.pipe(
      select(fromLookupsStore.getSaleInvoiceOrderTypes),
      tap((invoiceOrderTypes) => {
        if (!isManualSearchTriggeredBeforeForInvoiceOrderTypes && !invoiceOrderTypes.length) {
          isManualSearchTriggeredBeforeForInvoiceOrderTypes = true;
          this.lookupsStore$.dispatch(new fromLookupsStore.GetAllSaleInvoiceOrderType());
        }
      })
    );

    let isManualSearchTriggeredBeforeForInvoices = false;
    this.invoices$ = this.salesStore$.pipe(
      select(fromSalesStore.getSaleInvoices),
      tap((invoices) => {
        if (invoices) {
          this.invoicesList = invoices;
        }
        if (!isManualSearchTriggeredBeforeForInvoices && !invoices.length) {
          isManualSearchTriggeredBeforeForInvoices = true;
          this.search();
        }
      })
    );

    /* Subscribe to selected location to select it whenever it changed. */
    this.subscriptions.add(
      this.authStore$
        .pipe(
          select(fromAuthStore.getSessionUserDefaultLocation),
          tap((location) => {
            if (location && !this.searchForm.value.locations.includes(location.id)) {
              this.selectLocations([location]);
            }
          })
        )
        .subscribe()
    );

    this.paginationInfo$ = this.salesStore$.pipe(select(fromSalesStore.getSaleInvoicesPaginationInfo));
  }

  /**
   * Initialize form and add validators.
   */
  initForm() {
    this.searchForm = new FormGroup({
      customers: new FormControl([]),
      locations: new FormControl([]),
      saleInvoiceTypes: new FormControl([]),
      saleInvoiceOrderTypes: new FormControl([]),
      fromDate: new FormControl({
        year: this.initialFromDate.getFullYear(),
        month: this.initialFromDate.getMonth() + 1,
        day: this.initialFromDate.getDate(),
      }),
      toDate: new FormControl({
        year: this.initialToDate.getFullYear(),
        month: this.initialToDate.getMonth() + 1,
        day: this.initialToDate.getDate(),
      }),
      invoiceNum: new FormControl(null),
      orderNum: new FormControl(null),
      page: new FormControl(1),
    });
  }

  /**
   * Applies the search filters and dispatches a search action.
   * @param page The current page number, default to `1`.
   */
  applyFiltersAndSearch(page: number = 1) {
    if (!this.searchForm.get('fromDate')?.value || !this.searchForm.get('toDate')?.value) {
      return this.notificationService.warning(
        this.translationService.translate('SALES.INVOICES.NOTIFICATION_MESSAGE.SELECT_DATE')
      );
    }

    const {
      invoiceNum,
      orderNum,
      customers,
      locations,
      saleInvoiceTypes,
      saleInvoiceOrderTypes,
      fromDate,
      toDate,
      ...rest
    } = this.searchForm.value;
    const fromDateFormatted = new Date(fromDate.year, fromDate.month - 1, fromDate.day);
    fromDateFormatted.setHours(0, 0, 0, 0);

    const toDateFormatted = new Date(toDate.year, toDate.month - 1, toDate.day);
    toDateFormatted.setHours(23, 59, 59, 0);

    this.salesStore$.dispatch(
      new fromSalesStore.SearchSaleInvoices({
        invoiceNum,
        orderNum,
        customers,
        locations,
        saleInvoiceTypes,
        saleInvoiceOrderTypes,
        fromDate: fromDateFormatted,
        toDate: toDateFormatted,
        page,
      })
    );
  }

  /**
   * Handles search parameters change.
   */
  search(event?: KeyboardEvent) {
    if (event && event.key === 'Enter') {
      event.preventDefault();
      return;
    }

    this.applyFiltersAndSearch();
  }

  /**
   * Handles pagination page-changed event.
   * @param page The current selected page number.
   */
  pageChanged(page: number) {
    /** Update pagination page. */
    this.searchForm.patchValue({ page });

    this.applyFiltersAndSearch(page);
  }

  /**
   * Enables trackBy feature for *ngFor utility to track items
   * depending on a comparer to reduce DOM change.
   * If the item is already exist then no HTML will change, only bounded values.
   * @param index The index of the item.
   * @param invoice The item to determine if it was changed or not.
   */
  trackItems(index: number, invoice: SaleInvoice) {
    return invoice ? invoice.id : undefined;
  }

  /**
   * Handles invoice selected event.
   * @param invoices The list of invoices to notify selected.
   */
  selectInvoice(invoices: SaleInvoice[]) {
    this.notificationService.successInstant(
      this.translationService.translate('SALES.INVOICES.NOTIFICATION_MESSAGE.SALES_INVOICE_SELECTED')
    );
    this.invoices.emit(invoices);
    if (this.closeOnSelect) {
      this.closeModal();
    }
  }

  /**
   * Opens the modal of the given templateRef.
   * @param modalRef The modal templateRef to be opened.
   */
  openModal(modalRef) {
    /**
     * Open the modal & listen for user press ESC key.
     */
    this.modal = this.modalService.open(modalRef, { size: 'xl' });
    this.modal.result.then(null, () => this.closeModal());
  }

  /**
   * Closes the currently opened modal.
   */
  closeModal() {
    this.modal?.dismiss();
    this.invoices.emit([]);
    this.visibleChange.emit(false);
  }

  /**
   * Adds the newly selected customers the customers search list.
   * @param customers The list of newly selected customers to be added.
   */
  selectCustomers(customers: Customer[]) {
    const selectedIds: number[] = this.searchForm.get('customers').value;
    this.customers = [...this.customers.filter((customer) => selectedIds.includes(customer.id)), ...customers];
    this.searchForm.patchValue({ customers: this.customers.map((customer) => customer.id) });
  }

  /**
   * Adds the newly selected locations the locations search list.
   * @param locations The list of newly selected locations to be added.
   */
  selectLocations(locations: Location[]) {
    const selectedIds: number[] = this.searchForm.get('locations').value;
    this.locations = [...this.locations.filter((location) => selectedIds.includes(location.id)), ...locations];
    this.searchForm.patchValue({ locations: this.locations.map((location) => location.id) });
  }
}
