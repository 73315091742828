import { QuotationStatus } from 'lookups/models';

/**
 * Represents the quotation status state.
 */
export class QuotationStatusState {
  /**
   * The list of quotation status.
   */
  public data: QuotationStatus[];

  /**
   * An error that may resulting during processing some actions.
   */
  public error: any;

  /**
   * Determines if there is a running load process.
   */
  public isLoading: boolean;

  /**
   * Determines if the last load process has been ended successfully.
   */
  public isLoaded: boolean;
}
