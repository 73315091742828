<!-- pos device list starts -->
<div class="row">
  <div class="col-md-12">
    <div class="px-3">
      <div class="row">
        <div class="col-md-12">
          <div class="row">
            <div class="col-md-12">
              <div class="table-responsive">
                <table class="table text-center">
                  <thead>
                    <tr>
                      <th scope="col">
                        {{ 'AUTH.AUTH_DATA.POS_DEVICE_NAME' | translate }}
                      </th>
                      <th scope="col">
                        {{ 'AUTH.AUTH_DATA.LOCATION_NAME' | translate }}
                      </th>
                      <th scope="col">
                        {{ 'AUTH.AUTH_DATA.IS_DEFAULT' | translate }}
                      </th>
                      <th scope="col">{{ 'SHARED.TOOLS' | translate }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td colspan="3" *ngIf="!userDefaultPosDevice">
                        {{ 'AUTH.AUTH_DATA.NO_DEFAULT_POS_DEVICE' | translate }}
                      </td>
                      <ng-container *ngIf="userDefaultPosDevice">
                        <td>{{ userDefaultPosDevice.name | localize: userDefaultPosDevice.nameEn | async }}</td>
                        <td>
                          {{
                            userDefaultPosDevice.location.name | localize: userDefaultPosDevice.location.nameEn | async
                          }}
                        </td>
                        <td>
                          <app-status
                            [status]="true"
                            [activeText]="'AUTH.AUTH_DATA.POS_DEVICE_DEFAULT_VALUE' | translate"
                          >
                          </app-status>
                        </td>
                      </ng-container>

                      <td>
                        <a
                          class="primary p-0"
                          (click)="posDevicesListVisibility = true"
                          [ngbTooltip]="'AUTH.UPDATE_DEFAULT_POS_DEVICE' | translate"
                        >
                          <i class="fa fa-edit font-medium-3 mr-2"></i>
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- pos device list ends -->

<!-- no items template starts -->
<ng-template #noItems>
  <div class="col-md-12 alert alert-warning mt-3 text-center" role="alert">
    {{ 'AUTH.SEARCH_POS_DEVICE_WITHOUT_ADD_NOT_FOUND' | translate }}
  </div>
</ng-template>
<!-- no items template ends -->

<!-- pos devices search starts -->
<pos-devices-search onlyActive="true" (posDevices)="selectPosDevice($event)" [(visible)]="posDevicesListVisibility">
</pos-devices-search>
<!-- pos devices search ends -->
