import { Action } from '@ngrx/store';

import { AppHttpResponse } from 'shared';
import {
  Project,
  CreateProjectInput,
  UpdateProjectInput,
  ProjectStatusLog,
  ProjectStatusLogInput,
  UpdateProjectPlanningResource,
  ProjectPlanningResourceFormItem,
  CreateProjectMainTaskInput,
  CreateProjectSubTaskInput,
  UpdateProjectMainTaskInput,
  UpdateProjectSubTaskInput,
  ProjectLog,
  ProjectMainTaskResponse,
  ProjectSubTaskResponse,
  ProjectResource,
  ProjectMainTask,
  ProjectSubTask,
  ProjectRevenue,
} from 'projects-management/models';

/**
 * The projects-management-module store projects action types.
 */
export enum ProjectsActionType {
  SEARCH_PROJECTS = '[ProjectsManagement] [Projects] Search Projects',
  SEARCH_PROJECTS_FAIL = '[ProjectsManagement] [Projects] Search Projects Fail',
  SEARCH_PROJECTS_SUCCESS = '[ProjectsManagement] [Projects] Search Projects Success',

  SEARCH_MAIN_TASKS = '[ProjectsManagement] [Projects] Search Main Tasks',
  SEARCH_MAIN_TASKS_FAIL = '[ProjectsManagement] [Projects] Search Main Tasks Fail',
  SEARCH_MAIN_TASKS_SUCCESS = '[ProjectsManagement] [Projects] Search Main Tasks Success',

  SEARCH_SUB_TASKS = '[ProjectsManagement] [Projects] Search Sub Tasks',
  SEARCH_SUB_TASKS_FAIL = '[ProjectsManagement] [Projects] Search Sub Tasks Fail',
  SEARCH_SUB_TASKS_SUCCESS = '[ProjectsManagement] [Projects] Search Sub Tasks Success',

  SEARCH_PROJECT_LOGS = '[ProjectsManagement] [Projects] Search Project Logs',
  SEARCH_PROJECT_LOGS_FAIL = '[ProjectsManagement] [Projects] Search Project Logs Fail',
  SEARCH_PROJECT_LOGS_SUCCESS = '[ProjectsManagement] [Projects] Search Project Logs Success',

  SEARCH_PROJECT_REVENUES = '[ProjectsManagement] [Projects] Search Project Revenues',
  SEARCH_PROJECT_REVENUES_FAIL = '[ProjectsManagement] [Projects] Search Project Revenues Fail',
  SEARCH_PROJECT_REVENUES_SUCCESS = '[ProjectsManagement] [Projects] Search Project Revenues Success',

  SEARCH_PROJECT_RESOURCES = '[ProjectsManagement] [Projects] Search Project Resources',
  SEARCH_PROJECT_RESOURCES_FAIL = '[ProjectsManagement] [Projects] Search Project Resources Fail',
  SEARCH_PROJECT_RESOURCES_SUCCESS = '[ProjectsManagement] [Projects] Search Project Resources Success',

  SEARCH_PROJECT_STATUS_LOGS = '[ProjectsManagement] [Projects] Search Project Status Logs',
  SEARCH_PROJECT_STATUS_LOGS_FAIL = '[ProjectsManagement] [Projects] Search Project Status Logs Fail',
  SEARCH_PROJECT_STATUS_LOGS_SUCCESS = '[ProjectsManagement] [Projects] Search Project Status Logs Success',

  FIND_PROJECT = '[ProjectsManagement] [Projects] Find Project',
  FIND_PROJECT_FAIL = '[ProjectsManagement] [Projects] Find Project Fail',
  FIND_PROJECT_SUCCESS = '[ProjectsManagement] [Projects] Find Project Success',

  FIND_PROJECT_RESOURCE = '[ProjectsManagement] [Projects] Find Project Resource',
  FIND_PROJECT_RESOURCE_FAIL = '[ProjectsManagement] [Projects] Find Project Resource Fail',
  FIND_PROJECT_RESOURCE_SUCCESS = '[ProjectsManagement] [Projects] Find Project Resource Success',

  FIND_PROJECT_REVENUE = '[ProjectsManagement] [Projects] Find Project Revenue',
  FIND_PROJECT_REVENUE_FAIL = '[ProjectsManagement] [Projects] Find Project Revenue Fail',
  FIND_PROJECT_REVENUE_SUCCESS = '[ProjectsManagement] [Projects] Find Project Revenue Success',
  CREATE_PROJECT = '[ProjectsManagement] [Projects] Create Project',
  CREATE_PROJECT_FAIL = '[ProjectsManagement] [Projects] Create Project Fail',
  CREATE_PROJECT_SUCCESS = '[ProjectsManagement] [Projects] Create Project Success',

  CREATE_PROJECT_MAIN_TASK = '[ProjectsManagement] [Projects] Create Project Main Task',
  CREATE_PROJECT_MAIN_TASK_FAIL = '[ProjectsManagement] [Projects] Create Project Main Task Fail',
  CREATE_PROJECT_MAIN_TASK_SUCCESS = '[ProjectsManagement] [Projects] Create Project Main Task Success',

  CREATE_PROJECT_SUB_TASK = '[ProjectsManagement] [Projects] Create Project Sub Task',
  CREATE_PROJECT_SUB_TASK_FAIL = '[ProjectsManagement] [Projects] Create Project Sub Task Fail',
  CREATE_PROJECT_SUB_TASK_SUCCESS = '[ProjectsManagement] [Projects] Create Project Sub Task Success',

  CREATE_PROJECT_STATUS_LOG = '[ProjectsManagement] [Projects] Create Project Status Log',
  CREATE_PROJECT_STATUS_LOG_FAIL = '[ProjectsManagement] [Projects] Create Project Status Log Fail',
  CREATE_PROJECT_STATUS_LOG_SUCCESS = '[ProjectsManagement] [Projects] Create Project Status Log Success',

  UPDATE_PROJECT = '[ProjectsManagement] [Projects] Update Project',
  UPDATE_PROJECT_FAIL = '[ProjectsManagement] [Projects] Update Project Fail',
  UPDATE_PROJECT_SUCCESS = '[ProjectsManagement] [Projects] Update Project Success',

  UPDATE_PROJECT_MAIN_TASK = '[ProjectsManagement] [Projects] Update Project Main Task',
  UPDATE_PROJECT_MAIN_TASK_FAIL = '[ProjectsManagement] [Projects] Update Project Main Task Fail',
  UPDATE_PROJECT_MAIN_TASK_SUCCESS = '[ProjectsManagement] [Projects] Update Project Main Task Success',

  UPDATE_PROJECT_SUB_TASK = '[ProjectsManagement] [Projects] Update Project Sub Task',
  UPDATE_PROJECT_SUB_TASK_FAIL = '[ProjectsManagement] [Projects] Update Project Sub Task Fail',
  UPDATE_PROJECT_SUB_TASK_SUCCESS = '[ProjectsManagement] [Projects] Update Project Sub Task Success',

  DELETE_PROJECT = '[ProjectsManagement] [Projects] Delete Project',
  DELETE_PROJECT_FAIL = '[ProjectsManagement] [Projects] Delete Project Fail',
  DELETE_PROJECT_SUCCESS = '[ProjectsManagement] [Projects] Delete Project Success',

  DELETE_PROJECT_MAIN_TASK = '[ProjectsManagement] [Projects] Delete Project Main Task',
  DELETE_PROJECT_MAIN_TASK_FAIL = '[ProjectsManagement] [Projects] Delete Project Main Task Fail',
  DELETE_PROJECT_MAIN_TASK_SUCCESS = '[ProjectsManagement] [Projects] Delete Project Main Task Success',

  DELETE_PROJECT_SUB_TASK = '[ProjectsManagement] [Projects] Delete Project Sub Task',
  DELETE_PROJECT_SUB_TASK_FAIL = '[ProjectsManagement] [Projects] Delete Project Sub Task Fail',
  DELETE_PROJECT_SUB_TASK_SUCCESS = '[ProjectsManagement] [Projects] Delete Project Sub Task Success',

  CREATE_PROJECT_PLANNING_RESOURCE = '[ProjectsManagement] [Projects] Create Project Planning Resource',
  CREATE_PROJECT_PLANNING_RESOURCE_FAIL = '[ProjectsManagement] [Projects] Create Project Planning Resource Fail',
  CREATE_PROJECT_PLANNING_RESOURCE_SUCCESS = '[ProjectsManagement] [Projects] Create Project Planning Resource Success',

  DELETE_PROJECT_PLANNING_RESOURCE = '[ProjectsManagement] [Projects] Delete Project Planning Resource',
  DELETE_PROJECT_PLANNING_RESOURCE_FAIL = '[ProjectsManagement] [Projects] Delete Project Planning Resource Fail',
  DELETE_PROJECT_PLANNING_RESOURCE_SUCCESS = '[ProjectsManagement] [Projects] Delete Project Planning Resource Success',
}

/**
 * Represents a store projects search action.
 */
export class SearchProjects implements Action {
  /**
   * The type of the action.
   */
  readonly type = ProjectsActionType.SEARCH_PROJECTS;

  /**
   * Fires a new projects search action.
   * @param payload the search parameters,
   * if omitted, all projects will be loaded.
   */
  constructor(
    public payload?: {
      description: string;
      locations: number[];
      projectStatuses: number[];
      customers: number[];
      page: number;
    }
  ) {}
}

/**
 * Represents a store projects search fail action.
 */
export class SearchProjectsFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = ProjectsActionType.SEARCH_PROJECTS_FAIL;

  /**
   * Fires a new Projects search fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store search success action.
 */
export class SearchProjectsSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = ProjectsActionType.SEARCH_PROJECTS_SUCCESS;

  /**
   * Fires a new projects search success action.
   * @param payload An object contains the list of projects that matches the search criteria
   * in addition to the pagination info.
   */
  constructor(public payload: AppHttpResponse<Project[]>) {}
}

/**
 * Represents a store main tasks search action.
 */
export class SearchMainTasks implements Action {
  /**
   * The type of the action.
   */
  readonly type = ProjectsActionType.SEARCH_MAIN_TASKS;

  /**
   * Fires a new main tasks search action.
   * @param payload the search parameters,
   * if omitted, all main tasks will be loaded.
   */
  constructor(
    public payload?: {
      description: string;
      projects: number[];
      page: number;
    }
  ) {}
}

/**
 * Represents a store main tasks search fail action.
 */
export class SearchMainTasksFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = ProjectsActionType.SEARCH_MAIN_TASKS_FAIL;

  /**
   * Fires a new main tasks search fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store search success action.
 */
export class SearchMainTasksSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = ProjectsActionType.SEARCH_MAIN_TASKS_SUCCESS;

  /**
   * Fires a new main tasks search success action.
   * @param payload An object contains the list of main tasks that matches the search criteria
   * in addition to the pagination info.
   */
  constructor(public payload: AppHttpResponse<ProjectMainTask[]>) {}
}

/**
 * Represents a store sub tasks search action.
 */
export class SearchSubTasks implements Action {
  /**
   * The type of the action.
   */
  readonly type = ProjectsActionType.SEARCH_SUB_TASKS;

  /**
   * Fires a new sub tasks search action.
   * @param payload the search parameters,
   * if omitted, all sub tasks will be loaded.
   */
  constructor(
    public payload?: {
      description: string;
      projects: number[];
      page: number;
    }
  ) {}
}

/**
 * Represents a store sub tasks search fail action.
 */
export class SearchSubTasksFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = ProjectsActionType.SEARCH_SUB_TASKS_FAIL;

  /**
   * Fires a new sub tasks search fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store search success action.
 */
export class SearchSubTasksSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = ProjectsActionType.SEARCH_SUB_TASKS_SUCCESS;

  /**
   * Fires a new sub tasks search success action.
   * @param payload An object contains the list of sub tasks that matches the search criteria
   * in addition to the pagination info.
   */
  constructor(public payload: AppHttpResponse<ProjectSubTask[]>) {}
}

/**
 * Represents a store project logs search action.
 */
export class SearchProjectLogs implements Action {
  /**
   * The type of the action.
   */
  readonly type = ProjectsActionType.SEARCH_PROJECT_LOGS;

  /**
   * Fires a new project logs search action.
   * @param payload the search parameters,
   * if omitted, all project logs will be loaded.
   */
  constructor(
    public payload?: {
      projectId: number;
      projectLogTypes: [];
      fromDate: Date;
      toDate: Date;
      page: number;
    }
  ) {}
}

/**
 * Represents a store project logs search fail action.
 */
export class SearchProjectLogsFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = ProjectsActionType.SEARCH_PROJECT_LOGS_FAIL;

  /**
   * Fires a new project logs search fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store search success action.
 */
export class SearchProjectLogsSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = ProjectsActionType.SEARCH_PROJECT_LOGS_SUCCESS;

  /**
   * Fires a new project logs search success action.
   * @param payload An object contains the list of projects that matches the search criteria
   * in addition to the pagination info.
   */
  constructor(public payload: AppHttpResponse<ProjectLog[]>) {}
}

/**
 * Represents a store project revenues search action.
 */
export class SearchProjectRevenues implements Action {
  /**
   * The type of the action.
   */
  readonly type = ProjectsActionType.SEARCH_PROJECT_REVENUES;

  /**
   * Fires a new project revenues search action.
   * @param payload the search parameters,
   * if omitted, all project revenues will be loaded.
   */
  constructor(
    public payload?: {
      projectId: number;
      projectRevenueTypes: [];
      fromDate: Date;
      toDate: Date;
      page: number;
    }
  ) {}
}

/**
 * Represents a store project revenues search fail action.
 */
export class SearchProjectRevenuesFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = ProjectsActionType.SEARCH_PROJECT_REVENUES_FAIL;

  /**
   * Fires a new project revenues search fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store search success action.
 */
export class SearchProjectRevenuesSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = ProjectsActionType.SEARCH_PROJECT_REVENUES_SUCCESS;

  /**
   * Fires a new project revenues search success action.
   * @param payload An object contains the list of projects that matches the search criteria
   * in addition to the pagination info.
   */
  constructor(public payload: AppHttpResponse<ProjectRevenue[]>) {}
}

/**
 * Represents a store project resources search action.
 */
export class SearchProjectResources implements Action {
  /**
   * The type of the action.
   */
  readonly type = ProjectsActionType.SEARCH_PROJECT_RESOURCES;

  /**
   * Fires a new project resources search action.
   * @param payload the search parameters,
   * if omitted, all project resources will be loaded.
   */
  constructor(
    public payload?: {
      projectId: number;
      projectMainTasks: [];
      projectSubTasks: [];
      projectResourceTypes: [];
      fromDate: Date;
      toDate: Date;
      page: number;
    }
  ) {}
}

/**
 * Represents a store project resources search fail action.
 */
export class SearchProjectResourcesFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = ProjectsActionType.SEARCH_PROJECT_RESOURCES_FAIL;

  /**
   * Fires a new project resources search fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store search success action.
 */
export class SearchProjectResourcesSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = ProjectsActionType.SEARCH_PROJECT_RESOURCES_SUCCESS;

  /**
   * Fires a new project resources search success action.
   * @param payload An object contains the list of projects that matches the search criteria
   * in addition to the pagination info.
   */
  constructor(public payload: AppHttpResponse<ProjectResource[]>) {}
}

/**
 * Represents a store project status logs search action.
 */
export class SearchProjectStatusLogs implements Action {
  /**
   * The type of the action.
   */
  readonly type = ProjectsActionType.SEARCH_PROJECT_STATUS_LOGS;

  /**
   * Fires a new project status logs search action.
   * @param payload the search parameters,
   * if omitted, all project status logs will be loaded.
   */
  constructor(
    public payload?: {
      projectId: number;
      fromDate: Date;
      toDate: Date;
      page: number;
    }
  ) {}
}

/**
 * Represents a store project status logs search-fail action.
 */
export class SearchProjectStatusLogsFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = ProjectsActionType.SEARCH_PROJECT_STATUS_LOGS_FAIL;

  /**
   * Fires a new project status logs search-fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store search-success action.
 */
export class SearchProjectStatusLogsSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = ProjectsActionType.SEARCH_PROJECT_STATUS_LOGS_SUCCESS;

  /**
   * Fires a new project status logs search-success action.
   * @param payload An object contains the list of project status logs that matches the search criteria
   * in addition to the pagination info.
   */
  constructor(public payload: AppHttpResponse<ProjectStatusLog[]>) {}
}

/**
 * Represents a store find project action.
 */
export class FindProject implements Action {
  /**
   * The type of the action.
   */
  readonly type = ProjectsActionType.FIND_PROJECT;

  /**
   * Fires a new find project action.
   * @param payload the id of the project.
   */
  constructor(public payload: number) {}
}

/**
 * Represents a store find project fail action.
 */
export class FindProjectFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = ProjectsActionType.FIND_PROJECT_FAIL;

  /**
   * Fires a new find project fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store find project success action.
 */
export class FindProjectSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = ProjectsActionType.FIND_PROJECT_SUCCESS;

  /**
   * Fires a new find project success action.
   * @param payload The loaded project.
   */
  constructor(public payload: AppHttpResponse<Project>) {}
}

/**
 * Represents a store find project revenue action.
 */
export class FindProjectRevenue implements Action {
  /**
   * The type of the action.
   */
  readonly type = ProjectsActionType.FIND_PROJECT_REVENUE;

  /**
   * Fires a new find project revenue action.
   * @param payload the id of the project revenue.
   */
  constructor(public payload: number) {}
}

/**
 * Represents a store find project revenue fail action.
 */
export class FindProjectRevenueFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = ProjectsActionType.FIND_PROJECT_REVENUE_FAIL;

  /**
   * Fires a new find project revenue fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store find project revenue success action.
 */
export class FindProjectRevenueSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = ProjectsActionType.FIND_PROJECT_REVENUE_SUCCESS;

  /**
   * Fires a new find project revenue success action.
   * @param payload The loaded project revenue.
   */
  constructor(public payload: AppHttpResponse<ProjectRevenue>) {}
}

/**
 * Represents a store find project resource action.
 */
export class FindProjectResource implements Action {
  /**
   * The type of the action.
   */
  readonly type = ProjectsActionType.FIND_PROJECT_RESOURCE;

  /**
   * Fires a new find project resource action.
   * @param payload the id of the project resource.
   */
  constructor(public payload: number) {}
}

/**
 * Represents a store find project resource fail action.
 */
export class FindProjectResourceFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = ProjectsActionType.FIND_PROJECT_RESOURCE_FAIL;

  /**
   * Fires a new find project resource fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store find project resource success action.
 */
export class FindProjectResourceSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = ProjectsActionType.FIND_PROJECT_RESOURCE_SUCCESS;

  /**
   * Fires a new find project resource success action.
   * @param payload The loaded project resource.
   */
  constructor(public payload: AppHttpResponse<ProjectResource>) {}
}

/**
 * Represents a store project create action.
 */
export class CreateProject implements Action {
  /** The type of the action. */
  readonly type = ProjectsActionType.CREATE_PROJECT;

  /**
   * Fires a new project create action.
   * @param payload the new project data-model.
   */
  constructor(public payload: CreateProjectInput) {}
}

/**
 * Represents a store project create fail action.
 */
export class CreateProjectFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = ProjectsActionType.CREATE_PROJECT_FAIL;

  /**
   * Fires a new project create fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store project create success action.
 */
export class CreateProjectSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = ProjectsActionType.CREATE_PROJECT_SUCCESS;

  /**
   * Fires a new project create success action.
   * @param payload The created project.
   */
  constructor(public payload: AppHttpResponse<Project>) {}
}

/**
 * Represents a store project main task create action.
 */
export class CreateProjectMainTask implements Action {
  /** The type of the action. */
  readonly type = ProjectsActionType.CREATE_PROJECT_MAIN_TASK;

  /**
   * Fires a new project main task create action.
   * @param payload the new project main task data-model.
   */
  constructor(public payload: CreateProjectMainTaskInput) {}
}

/**
 * Represents a store project main task create fail action.
 */
export class CreateProjectMainTaskFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = ProjectsActionType.CREATE_PROJECT_MAIN_TASK_FAIL;

  /**
   * Fires a new project main task create fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store project main task create success action.
 */
export class CreateProjectMainTaskSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = ProjectsActionType.CREATE_PROJECT_MAIN_TASK_SUCCESS;

  /**
   * Fires a new project main task create success action.
   * @param payload The created project main task.
   */
  constructor(public payload: AppHttpResponse<ProjectMainTaskResponse>) {}
}

/**
 * Represents a store project sub task create action.
 */
export class CreateProjectSubTask implements Action {
  /** The type of the action. */
  readonly type = ProjectsActionType.CREATE_PROJECT_SUB_TASK;

  /**
   * Fires a new project sub task create action.
   * @param payload the new project sub task data-model.
   */
  constructor(public payload: CreateProjectSubTaskInput) {}
}

/**
 * Represents a store project sub task create fail action.
 */
export class CreateProjectSubTaskFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = ProjectsActionType.CREATE_PROJECT_SUB_TASK_FAIL;

  /**
   * Fires a new project sub task create fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store project sub task create success action.
 */
export class CreateProjectSubTaskSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = ProjectsActionType.CREATE_PROJECT_SUB_TASK_SUCCESS;

  /**
   * Fires a new project sub task create success action.
   * @param payload The created project sub task.
   */
  constructor(public payload: AppHttpResponse<ProjectSubTaskResponse>) {}
}

/**
 * Represents a store project status log create action.
 */
export class CreateProjectStatusLog implements Action {
  /** The type of the action. */
  readonly type = ProjectsActionType.CREATE_PROJECT_STATUS_LOG;

  /**
   * Fires a new project status log create action.
   * @param payload the new project status log data-model.
   */
  constructor(public payload: ProjectStatusLogInput) {}
}

/**
 * Represents a store project status log create-fail action.
 */
export class CreateProjectStatusLogFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = ProjectsActionType.CREATE_PROJECT_STATUS_LOG_FAIL;

  /**
   * Fires a new project status log create-fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store project status log create-success action.
 */
export class CreateProjectStatusLogSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = ProjectsActionType.CREATE_PROJECT_STATUS_LOG_SUCCESS;

  /**
   * Fires a new project status log create-success action.
   * @param payload The created project status log.
   */
  constructor(public payload: AppHttpResponse<ProjectStatusLog>) {}
}

/**
 * Represents a store project update action.
 */
export class UpdateProject implements Action {
  /**
   * The type of the action.
   */
  readonly type = ProjectsActionType.UPDATE_PROJECT;

  /**
   * Fires a new project update action.
   * @param payload The project's updated data.
   */
  constructor(public payload: UpdateProjectInput) {}
}

/**
 * Represents a store project update fail action.
 */
export class UpdateProjectFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = ProjectsActionType.UPDATE_PROJECT_FAIL;

  /**
   * Fires a new project update fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store project update success action.
 */
export class UpdateProjectSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = ProjectsActionType.UPDATE_PROJECT_SUCCESS;

  /**
   * Fires a new project update-success action.
   * @param payload The updated project.
   */
  constructor(public payload: AppHttpResponse<Project>) {}
}

/**
 * Represents a store project main task update action.
 */
export class UpdateProjectMainTask implements Action {
  /**
   * The type of the action.
   */
  readonly type = ProjectsActionType.UPDATE_PROJECT_MAIN_TASK;

  /**
   * Fires a new project main task update action.
   * @param payload The project main task's updated data.
   */
  constructor(public payload: UpdateProjectMainTaskInput) {}
}

/**
 * Represents a store project main task update fail action.
 */
export class UpdateProjectMainTaskFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = ProjectsActionType.UPDATE_PROJECT_MAIN_TASK_FAIL;

  /**
   * Fires a new project main task update fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store project main task update success action.
 */
export class UpdateProjectMainTaskSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = ProjectsActionType.UPDATE_PROJECT_MAIN_TASK_SUCCESS;

  /**
   * Fires a new project main task update-success action.
   * @param payload The main task updated project.
   */
  constructor(public payload: AppHttpResponse<ProjectMainTaskResponse>) {}
}

/**
 * Represents a store project sub task update action.
 */
export class UpdateProjectSubTask implements Action {
  /**
   * The type of the action.
   */
  readonly type = ProjectsActionType.UPDATE_PROJECT_SUB_TASK;

  /**
   * Fires a new project sub task update action.
   * @param payload The project sub task's updated data.
   */
  constructor(public payload: UpdateProjectSubTaskInput) {}
}

/**
 * Represents a store project sub task update fail action.
 */
export class UpdateProjectSubTaskFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = ProjectsActionType.UPDATE_PROJECT_SUB_TASK_FAIL;

  /**
   * Fires a new project sub task update fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store project sub task update success action.
 */
export class UpdateProjectSubTaskSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = ProjectsActionType.UPDATE_PROJECT_SUB_TASK_SUCCESS;

  /**
   * Fires a new project sub task update-success action.
   * @param payload The sub task updated project.
   */
  constructor(public payload: AppHttpResponse<ProjectSubTaskResponse>) {}
}

/**
 * Represents a store project delete action.
 */
export class DeleteProject implements Action {
  /**
   * The type of the action.
   */
  readonly type = ProjectsActionType.DELETE_PROJECT;

  /**
   * Fires a new project delete action.
   * @param payload The project's id.
   */
  constructor(public payload: number) {}
}

/**
 * Represents a store projects delete fail action.
 */
export class DeleteProjectFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = ProjectsActionType.DELETE_PROJECT_FAIL;

  /**
   * Fires a new project delete fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store project delete success action.
 */
export class DeleteProjectSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = ProjectsActionType.DELETE_PROJECT_SUCCESS;

  /**
   * Fires a new project delete success action.
   * @param payload The deleted project.
   */

  constructor(public payload: AppHttpResponse<Project>) {}
}

/**
 * Represents a store project main task delete action.
 */
export class DeleteProjectMainTask implements Action {
  /**
   * The type of the action.
   */
  readonly type = ProjectsActionType.DELETE_PROJECT_MAIN_TASK;

  /**
   * Fires a new project main task delete action.
   * @param payload The project main task's id.
   */
  constructor(public payload: number) {}
}

/**
 * Represents a store project main task delete fail action.
 */
export class DeleteProjectMainTaskFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = ProjectsActionType.DELETE_PROJECT_MAIN_TASK_FAIL;

  /**
   * Fires a new project main task delete fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store project main task delete success action.
 */
export class DeleteProjectMainTaskSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = ProjectsActionType.DELETE_PROJECT_MAIN_TASK_SUCCESS;

  /**
   * Fires a new project main task delete success action.
   * @param payload The main task deleted project.
   */

  constructor(public payload: AppHttpResponse<ProjectMainTaskResponse>) {}
}

/**
 * Represents a store project sub task delete action.
 */
export class DeleteProjectSubTask implements Action {
  /**
   * The type of the action.
   */
  readonly type = ProjectsActionType.DELETE_PROJECT_SUB_TASK;

  /**
   * Fires a new project sub task delete action.
   * @param payload The project sub task's id.
   */
  constructor(public payload: number) {}
}

/**
 * Represents a store project sub task delete fail action.
 */
export class DeleteProjectSubTaskFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = ProjectsActionType.DELETE_PROJECT_SUB_TASK_FAIL;

  /**
   * Fires a new project sub task delete fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store project sub task delete success action.
 */
export class DeleteProjectSubTaskSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = ProjectsActionType.DELETE_PROJECT_SUB_TASK_SUCCESS;

  /**
   * Fires a new project sub task delete success action.
   * @param payload The sub task deleted project.
   */

  constructor(public payload: AppHttpResponse<ProjectSubTaskResponse>) {}
}

/**
 * Represents a store project planning resource create action.
 */
export class CreateProjectPlanningResource implements Action {
  /** The type of the action. */
  readonly type = ProjectsActionType.CREATE_PROJECT_PLANNING_RESOURCE;

  /**
   * Fires a new project planning resource create action.
   * @param payload the new project planning resource data-model.
   */
  constructor(public payload: UpdateProjectPlanningResource) {}
}

/**
 * Represents a store project planning resource create-fail action.
 */
export class CreateProjectPlanningResourceFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = ProjectsActionType.CREATE_PROJECT_PLANNING_RESOURCE_FAIL;

  /**
   * Fires a new project planning resource create-fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store project planning resource create-success action.
 */
export class CreateProjectPlanningResourceSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = ProjectsActionType.CREATE_PROJECT_PLANNING_RESOURCE_SUCCESS;

  /**
   * Fires a new project planning resource create-success action.
   * @param payload The created project.
   */
  constructor(public payload: AppHttpResponse<ProjectPlanningResourceFormItem>) {}
}

/**
 * Represents a store project planning resource delete action.
 */
export class DeleteProjectPlanningResource implements Action {
  /**
   * The type of the action.
   */
  readonly type = ProjectsActionType.DELETE_PROJECT_PLANNING_RESOURCE;

  /**
   * Fires a new project planning resource delete action.
   * @param payload The project planning resource's id.
   */
  constructor(public payload: number) {}
}

/**
 * Represents a store project planning resource delete-fail action.
 */
export class DeleteProjectPlanningResourceFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = ProjectsActionType.DELETE_PROJECT_PLANNING_RESOURCE_FAIL;

  /**
   * Fires a new project planning resource delete-fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store project planning resource delete-success action.
 */
export class DeleteProjectPlanningResourceSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = ProjectsActionType.DELETE_PROJECT_PLANNING_RESOURCE_SUCCESS;

  /**
   * Fires a new project planning resource delete-success action.
   * @param payload The deleted project planning resource.
   */
  constructor(public payload: AppHttpResponse<ProjectPlanningResourceFormItem>) {}
}

/**
 * projects-management-module projects action types.
 */
export type ProjectsActions =
  | SearchMainTasks
  | SearchMainTasksFail
  | SearchMainTasksSuccess
  | SearchSubTasks
  | SearchSubTasksFail
  | SearchSubTasksSuccess
  | SearchProjects
  | SearchProjectsFail
  | SearchProjectsSuccess
  | SearchProjectRevenues
  | SearchProjectRevenuesFail
  | SearchProjectRevenuesSuccess
  | SearchProjectLogs
  | SearchProjectLogsFail
  | SearchProjectLogsSuccess
  | SearchProjectResources
  | SearchProjectResourcesFail
  | SearchProjectResourcesSuccess
  | SearchProjectStatusLogs
  | SearchProjectStatusLogsFail
  | SearchProjectStatusLogsSuccess
  | FindProjectRevenue
  | FindProjectRevenueFail
  | FindProjectRevenueSuccess
  | FindProject
  | FindProjectFail
  | FindProjectSuccess
  | FindProjectResource
  | FindProjectResourceFail
  | FindProjectResourceSuccess
  | CreateProject
  | CreateProjectFail
  | CreateProjectSuccess
  | CreateProjectMainTask
  | CreateProjectMainTaskFail
  | CreateProjectMainTaskSuccess
  | CreateProjectSubTask
  | CreateProjectSubTaskFail
  | CreateProjectSubTaskSuccess
  | CreateProjectStatusLog
  | CreateProjectStatusLogFail
  | CreateProjectStatusLogSuccess
  | UpdateProject
  | UpdateProjectFail
  | UpdateProjectSuccess
  | UpdateProjectMainTask
  | UpdateProjectMainTaskFail
  | UpdateProjectMainTaskSuccess
  | UpdateProjectSubTask
  | UpdateProjectSubTaskFail
  | UpdateProjectSubTaskSuccess
  | DeleteProject
  | DeleteProjectFail
  | DeleteProjectSuccess
  | DeleteProjectMainTask
  | DeleteProjectMainTaskFail
  | DeleteProjectMainTaskSuccess
  | DeleteProjectSubTask
  | DeleteProjectSubTaskFail
  | DeleteProjectSubTaskSuccess
  | CreateProjectPlanningResource
  | CreateProjectPlanningResourceFail
  | CreateProjectPlanningResourceSuccess
  | DeleteProjectPlanningResource
  | DeleteProjectPlanningResourceFail
  | DeleteProjectPlanningResourceSuccess
  | FindProjectResource
  | FindProjectResourceFail
  | FindProjectResourceSuccess;
