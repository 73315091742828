import { Component, OnDestroy, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';

import { Observable, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';

import * as fromProjectsManagementStore from 'projects-management/store';
import { PageInfo, TranslationService } from 'shared';
import { Claims } from 'security/models';
import { ProjectResource, ProjectResourceLine } from 'projects-management/models';
import { ProjectResourceTypeSources } from 'lookups/models';

/**
 * The project file pages.
 */
enum PAGES {
  resource = 'resource',
  payrolls = 'payrolls',
  'outgoing-stock-details' = 'outgoing-stock-details',
  'asset-details' = 'asset-details',
  'purchase-return-details' = 'purchase-return-details',
  'purchase-invoice-details' = 'purchase-invoice-details',
}

@Component({
  selector: 'app-project-resources-file',
  templateUrl: './project-resources-file.component.html',
  styles: [],
})
export class ProjectResourcesFileComponent implements OnInit, OnDestroy {
  /**
   * Gets or sets the information about the current page.
   */
  pageInfo: PageInfo = {
    title: 'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.PROJECT_RESOURCE_DISPLAY',
    icon: 'fa fa-eye',
  };

  /**
   * The system supported user claims.
   */
  Claims = Claims;

  /**
   * Gets or sets the id of the current edited project resource.
   */
  projectResourceId: number;

  /**
   * The list of project resource.
   */
  projectResource$: Observable<ProjectResource>;

  /**
   * The list of project resource Lines.
   */
  projectResourceLines$: Observable<ProjectResourceLine[]>;

  /**
   * The list of project resource purchase invoice Lines.
   */
  projectResourcePurchaseInvoiceLines$: Observable<ProjectResourceLine[]>;

  /**
   * The list of project resource purchase return Lines.
   */
  projectResourcePurchaseReturnLines$: Observable<ProjectResourceLine[]>;

  /**
   * The list of project resource asset Lines.
   */
  projectResourceAssetDepreciationLines$: Observable<ProjectResourceLine[]>;

  /**
   * The list of project resource outgoing stock Lines.
   */
  projectResourceOutgoingStockLines$: Observable<ProjectResourceLine[]>;

  /**
   * The list of project resource payroll Lines.
   */
  projectResourcePayrollLines$: Observable<ProjectResourceLine[]>;

  /**
   * Represents the system supported project resource type sources.
   */
  projectResourceTypeSources = ProjectResourceTypeSources;

  /**
   * Gets or sets the selected page.
   * @default 'resource'
   */

  activePage: PAGES = PAGES.resource;

  /**
   * The set of subscriptions on this components,
   * these subscriptions must be unsubscribed before this component got destroyed.
   */
  subscriptions = new Subscription();

  /**
   * Gets or sets the current selected language.
   */
  get currentLang() {
    return this.translationService.language;
  }

  /**
   * @param locationService The location service.
   * @param route The activated route.
   * @param translationService The translation service.
   * @param projectsManagementStore$ The project management store.
   */
  constructor(
    private locationService: Location,
    private route: ActivatedRoute,
    private translationService: TranslationService,
    private projectsManagementStore$: Store<fromProjectsManagementStore.ProjectsManagementState>
  ) {}

  ngOnInit(): void {
    this.init();
  }

  /**
   * Destroy component data
   */
  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  /**
   * Initialize component data.
   */
  init() {
    /* Load the selected project resource. */
    this.projectResourceId = +this.route.snapshot.params.projectResourceId;
    this.projectsManagementStore$.dispatch(new fromProjectsManagementStore.FindProjectResource(this.projectResourceId));

    this.projectResource$ = this.projectsManagementStore$.pipe(
      select(fromProjectsManagementStore.getSelectedProjectResource),
      tap((projectResource) => {
        if (projectResource) {
          if (projectResource.outgoingStockId) {
            this.projectResourceOutgoingStockLines$ = this.projectsManagementStore$.pipe(
              select(fromProjectsManagementStore.getProjectResourceOutgoingStockLines)
            );
            this.projectsManagementStore$.dispatch(
              new fromProjectsManagementStore.FindProjectResourceOutgoingStockLines(this.projectResourceId)
            );
          } else if (
            projectResource.projectResourceTypeSource.key === this.projectResourceTypeSources.ASSET_DEPRECIATION
          ) {
            this.projectResourceAssetDepreciationLines$ = this.projectsManagementStore$.pipe(
              select(fromProjectsManagementStore.getProjectResourceAssetDepreciationLines)
            );
            this.projectsManagementStore$.dispatch(
              new fromProjectsManagementStore.FindProjectResourceAssetDepreciationLines(this.projectResourceId)
            );
          } else if (projectResource.purchaseInvoiceId) {
            this.projectResourcePurchaseInvoiceLines$ = this.projectsManagementStore$.pipe(
              select(fromProjectsManagementStore.getProjectResourcePurchaseInvoiceLines)
            );
            this.projectsManagementStore$.dispatch(
              new fromProjectsManagementStore.FindProjectResourcePurchaseInvoiceLines(this.projectResourceId)
            );
          } else if (projectResource.purchaseReturnId) {
            this.projectResourcePurchaseReturnLines$ = this.projectsManagementStore$.pipe(
              select(fromProjectsManagementStore.getProjectResourcePurchaseReturnLines)
            );
            this.projectsManagementStore$.dispatch(
              new fromProjectsManagementStore.FindProjectResourcePurchaseReturnLines(this.projectResourceId)
            );
          } else if (projectResource.projectResourceTypeSource.key === this.projectResourceTypeSources.PAYROLL) {
            this.projectResourcePayrollLines$ = this.projectsManagementStore$.pipe(
              select(fromProjectsManagementStore.getProjectResourcePayrollLines)
            );
            this.projectsManagementStore$.dispatch(
              new fromProjectsManagementStore.FindProjectResourcePayrollLines(this.projectResourceId)
            );
          }
        }
      })
    );

    /** Select the user desired page. */
    this.activePage = PAGES[this.route.snapshot.fragment] ?? this.activePage;
    this.selectedPageChanged(this.activePage);
  }

  /**
   * Updates the browser url according to the selected page.
   * @param page The newly selected page.
   */
  selectedPageChanged(page: PAGES) {
    this.locationService.replaceState(`${this.locationService.path()}#${PAGES[page]}`);
  }
}
