import { createSelector } from '@ngrx/store';

import { selectLookupsState } from 'lookups/store/selectors/feature.selectors';
import { LookupsState, PayrollElementTypeState } from 'lookups/store/states';

/**
 * Gets the payroll element type state.
 */
const selectPayrollElementTypes = createSelector(
  selectLookupsState,
  (state: LookupsState) => state.payrollElementTypes
);

/**
 * Gets the list of loaded payroll element types.
 */
export const getPayrollElementTypes = createSelector(
  selectPayrollElementTypes,
  (state: PayrollElementTypeState) => state.data
);

/**
 * Gets the error that occurred in the state.
 */
export const getPayrollElementTypesError = createSelector(
  selectPayrollElementTypes,
  (state: PayrollElementTypeState) => state.error
);

/**
 * Determines if there is a running load process.
 */
export const getPayrollElementTypesIsLoading = createSelector(
  selectPayrollElementTypes,
  (state: PayrollElementTypeState) => state.isLoading
);

/**
 * Determines if the last load process has been ended successfully.
 */
export const getPayrollElementTypesIsLoaded = createSelector(
  selectPayrollElementTypes,
  (state: PayrollElementTypeState) => state.isLoaded
);
