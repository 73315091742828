<!-- pay payrolls starts -->
<div class="card">
  <div class="card-content">
    <div class="px-3 pt-3">
      <form [formGroup]="form" (ngSubmit)="submit()">
        <div class="row">
          <div class="col-md-12">
            <div class="card">
              <div class="card-header mb-3 pt-0">
                <h4 class="card-title">
                  <span>{{ 'FINANCES.HR.PAID_PAYROLLS.EMPLOYEES_PAYROLL_PAYMENT_REFUND_TITLE' | translate }}</span>
                </h4>
              </div>
              <div class="card-content">
                <div class="px-3">
                  <div class="row">
                    <div class="form-group col-md-6">
                      <label for="paymentMethodId">
                        {{ 'FINANCES.HR.PAID_PAYROLLS.PAID_PAYROLLS_DATA.PAYMENT_REFUND_METHOD_TYPE' | translate }}
                      </label>
                      <ng-select
                        class="round"
                        formControlName="paymentMethodId"
                        labelForId="paymentMethodId"
                        searchable="true"
                        [placeholder]="
                          'FINANCES.HR.PAID_PAYROLLS.PAID_PAYROLLS_DATA.PAYMENT_REFUND_METHOD_TYPE_PLACEHOLDER'
                            | translate
                        "
                        [notFoundText]="'SHARED.SEARCH.SEARCH_NOT_FOUND' | translate"
                      >
                        <ng-option
                          *ngFor="let paymentMethodType of employeePaymentMethods$ | async"
                          [value]="paymentMethodType.id"
                        >
                          {{ paymentMethodType.name | localize: paymentMethodType.nameEn | async }}
                        </ng-option>
                      </ng-select>
                    </div>
                    <div class="form-group col-md-6" *ngIf="this.form?.value?.paymentMethodId">
                      <label
                        for="bankAccountId"
                        *ngIf="this.form?.value?.paymentMethodId === employeePaymentMethodIdForBankTransfer"
                      >
                        {{ 'FINANCES.HR.PAID_PAYROLLS.PAID_PAYROLLS_DATA.BANK_ACCOUNT' | translate }}
                      </label>
                      <label
                        for="bankAccountId"
                        *ngIf="this.form?.value?.paymentMethodId === employeePaymentMethodIdForCash"
                      >
                        {{ 'FINANCES.HR.PAID_PAYROLLS.PAID_PAYROLLS_DATA.CASH_ACCOUNT' | translate }}
                      </label>
                      <ng-select
                        class="round"
                        formControlName="bankAccountId"
                        labelForId="bankAccountId"
                        searchable="true"
                        [placeholder]="
                          'FINANCES.HR.PAID_PAYROLLS.PAID_PAYROLLS_DATA.BANK_ACCOUNT_PLACEHOLDER' | translate
                        "
                        [notFoundText]="'SHARED.SEARCH.SEARCH_NOT_FOUND' | translate"
                        (open)="bankAccountsListVisibility = true"
                      >
                        <ng-option *ngFor="let bankAccount of bankAccounts" [value]="bankAccount.id">
                          {{ bankAccount.bank.name | localize: bankAccount.bank.nameEn | async }}-
                          {{ bankAccount.name | localize: bankAccount.nameEn | async }}
                        </ng-option>
                      </ng-select>
                    </div>
                    <div
                      class="form-group col-md-6"
                      *ngIf="this.form?.value?.paymentMethodId === employeePaymentMethodIdForBankTransfer"
                    >
                      <label for="bankFee">{{
                        'FINANCES.HR.PAID_PAYROLLS.PAID_PAYROLLS_DATA.BANK_FEE_VALUE' | translate
                      }}</label>
                      <input
                        type="text"
                        id="bankFee"
                        [mask]="DECIMAL_MASK"
                        thousandSeparator=","
                        minLength="0"
                        class="form-control round"
                        [placeholder]="
                          'FINANCES.HR.PAID_PAYROLLS.PAID_PAYROLLS_DATA.BANK_FEE_VALUE_PLACEHOLDER' | translate
                        "
                        formControlName="bankFee"
                      />
                    </div>
                    <div
                      class="form-group col-md-6"
                      *ngIf="this.form?.value?.paymentMethodId === employeePaymentMethodIdForBankTransfer"
                    >
                      <label for="bankFeeTaxValue">{{
                        'FINANCES.HR.PAID_PAYROLLS.PAID_PAYROLLS_DATA.BANK_FEE_TAX_VALUE' | translate
                      }}</label>
                      <input
                        type="text"
                        id="bankFeeTaxValue"
                        [mask]="DECIMAL_MASK"
                        thousandSeparator=","
                        minLength="0"
                        class="form-control round"
                        [placeholder]="
                          'FINANCES.HR.PAID_PAYROLLS.PAID_PAYROLLS_DATA.BANK_FEE_TAX_VALUE_PLACEHOLDER' | translate
                        "
                        formControlName="bankFeeTaxValue"
                      />
                    </div>
                    <div class="form-group col-md-6">
                      <label for="transactionDate">{{
                        'FINANCES.HR.PAID_PAYROLLS.PAID_PAYROLLS_DATA.TRANSACTION_DATE' | translate
                      }}</label>
                      <div class="input-group round">
                        <input
                          class="form-control"
                          id="transactionDate"
                          name="transactionDate"
                          formControlName="transactionDate"
                          [placeholder]="'SHARED.SEARCH.DATE_PLACEHOLDER' | translate"
                          ngbDatepicker
                          #transactionDate="ngbDatepicker"
                        />
                        <div class="input-group-append">
                          <span
                            class="input-group-text gradient-light-blue-indigo"
                            style="cursor: pointer;"
                            (click)="transactionDate.toggle()"
                          >
                            <i class="fa fa-calendar"></i>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="form-group col-md-12">
                      <label for="notes">{{ 'FINANCES.HR.PAID_PAYROLLS.PAID_PAYROLLS_DATA.NOTES' | translate }}</label>
                      <textarea
                        id="notes"
                        class="form-control round"
                        [placeholder]="'FINANCES.HR.PAID_PAYROLLS.PAID_PAYROLLS_DATA.NOTES_PLACEHOLDER' | translate"
                        rows="1"
                        minlength="0"
                        maxlength="200"
                        formControlName="notes"
                      ></textarea>
                    </div>
                    <div class="card">
                      <div class="card-header mb-3 pt-0">
                        <h4 class="card-title">
                          {{ 'FINANCES.HR.PAID_PAYROLLS.PAID_PAYROLLS_DATA.PAYROLL_FORM_TITLE' | translate }}
                        </h4>
                      </div>
                      <div class="card-content">
                        <!-- paid payrolls details starts -->
                        <div class="px-3">
                          <div class="row">
                            <div class="col-md-12">
                              <app-paid-payrolls-form
                                [formArray]="paidPayrollsForm"
                                [isPaidPayrollRequired]="true"
                              ></app-paid-payrolls-form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <!-- pay payrolls summary starts -->
                    <div class="col-md-12">
                      <div class="card-header mb-4 pt-4">
                        <h4 class="card-title">
                          {{ 'FINANCES.HR.PAID_PAYROLLS.PAID_PAYROLLS_DATA.PREPARE_PAYROLLS_SUMMARY' | translate }}
                        </h4>
                      </div>
                      <div class="card-content">
                        <div class="px-3">
                          <div class="row">
                            <div class="col-md-12">
                              <div class="row">
                                <div class="form-group col-md-4">
                                  <label for="payrollsNet">{{
                                    'FINANCES.HR.PAID_PAYROLLS.PAID_PAYROLLS_DATA.PAYROLLS_TOTAL' | translate
                                  }}</label>
                                  <input
                                    type="text"
                                    id="payrollsNet"
                                    name="payrollsNet"
                                    class="form-control round"
                                    [value]="payrollsNet | toDecimal"
                                    readonly
                                  />
                                </div>
                                <div class="form-group col-md-4">
                                  <label for="bankFee">{{
                                    'FINANCES.HR.PAID_PAYROLLS.PAID_PAYROLLS_DATA.BANK_FEE_VALUE' | translate
                                  }}</label>
                                  <input
                                    type="text"
                                    id="bankFee"
                                    name="bankFee"
                                    class="form-control round"
                                    [value]="bankFee | toDecimal"
                                    readonly
                                  />
                                </div>
                                <div class="form-group col-md-4">
                                  <label for="bankFeeTaxValue">{{
                                    'FINANCES.HR.PAID_PAYROLLS.PAID_PAYROLLS_DATA.BANK_FEE_TAX_VALUE' | translate
                                  }}</label>
                                  <input
                                    type="text"
                                    id="bankFeeTaxValue"
                                    name="bankFeeTaxValue"
                                    class="form-control round"
                                    [value]="bankFeeTaxValue | toDecimal"
                                    readonly
                                  />
                                </div>
                                <div class="form-group col-md-4">
                                  <label for="payTotal" class="font-weight-bold" style="color: #212529;">
                                    <i class="fa fa-star"></i>
                                    <span class="mx-1">{{
                                      'FINANCES.HR.PAID_PAYROLLS.PAID_PAYROLLS_DATA.PAYMENT_REFUND_TOTAL' | translate
                                    }}</span>
                                  </label>
                                  <input
                                    type="text"
                                    id="payTotal"
                                    name="payTotal"
                                    class="form-control round"
                                    [value]="paymentNet | toDecimal"
                                    readonly
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- pay payrolls summary ends -->

                    <div class="col-md-12">
                      <div class="card">
                        <!--  attachments starts -->
                        <div class="card-header mb-3 pt-1">
                          <h4 class="card-title">{{ 'ASSETS.ASSET_DATA.ATTACHMENTS' | translate }}</h4>
                        </div>
                        <div class="card-content">
                          <div class="px-3">
                            <app-files-upload
                              formArrayName="attachments"
                              [filesForm]="attachmentsForm"
                            ></app-files-upload>
                          </div>
                        </div>
                        <!--  attachments ends -->
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="row">
                        <div class="form-group col-12 d-flex justify-content-between flex-column flex-sm-row mt-2">
                          <button
                            type="submit"
                            class="btn btn-raised btn-primary round"
                            [disabled]="isCreating$ | async"
                          >
                            <i class="fa fa-check"></i>
                            <span class="mx-1">{{ 'SHARED.CONFIRM.PAYROLL_PAYMENT_REFUNDS' | translate }}</span>
                          </button>
                          <a class="btn btn-dark round" routerLink="/finances/hr" fragment="paid-payrolls">
                            <i class="fa fa-undo"></i>
                            <span class="mx-1">{{ 'FINANCES.HR.PAID_PAYROLLS.PAID_PAYROLLS_RETURN' | translate }}</span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
<!-- pay payrolls ends -->

<!-- confirm modal starts -->
<ng-template #confirmModalRef let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title text-primary" id="modal-basic-title">
      {{ 'FINANCES.HR.PAID_PAYROLLS.CONFIRM_PREPARE_PAYROLL_PAYMENT_TITLE' | translate }}
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="d()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p class="text-primary">
      {{ 'FINANCES.HR.PAID_PAYROLLS.CONFIRM_PREPARE_PAYROLL_PAYMENT_REFUND_MESSAGE' | translate }}
    </p>
  </div>
  <div class="modal-footer">
    <div class="modal-actions">
      <button type="button" class="btn btn-raised btn-primary round" (click)="c(); confirm()" ngbAutofocus>
        <i class="fa fa-check"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.PAYROLL_PAYMENT_REFUNDS' | translate }}</span>
      </button>
      <button type="button" class="btn btn-outline-dark round" (click)="c()">
        <i class="fa fa-times"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.CANCEL' | translate }}</span>
      </button>
    </div>
  </div>
</ng-template>
<!-- confirm modal ends -->

<!-- bank accounts search starts -->
<bank-accounts-search
  onlyActive="true"
  closeOnSelect="true"
  (bankAccounts)="selectBankAccount($event)"
  [(visible)]="bankAccountsListVisibility"
>
</bank-accounts-search>
<!-- bank accounts search ends -->
