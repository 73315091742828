import { createSelector } from '@ngrx/store';

import { selectLookupsState } from 'lookups/store/selectors/feature.selectors';
import { LookupsState, PosPrinterAdjustmentTypeState } from 'lookups/store/states';

/**
 * Gets the pos printer adjustment type state.
 */
const selectPosPrinterAdjustmentTypes = createSelector(
  selectLookupsState,
  (state: LookupsState) => state.posPrinterAdjustmentTypes
);

/**
 * Gets the list of loaded pos printer adjustment types.
 */
export const getPosPrinterAdjustmentTypes = createSelector(
  selectPosPrinterAdjustmentTypes,
  (state: PosPrinterAdjustmentTypeState) => state.data
);

/**
 * Gets the error that occurred in the state.
 */
export const getPosPrinterAdjustmentTypesError = createSelector(
  selectPosPrinterAdjustmentTypes,
  (state: PosPrinterAdjustmentTypeState) => state.error
);

/**
 * Determines if there is a running load process.
 */
export const getPosPrinterAdjustmentTypesIsLoading = createSelector(
  selectPosPrinterAdjustmentTypes,
  (state: PosPrinterAdjustmentTypeState) => state.isLoading
);

/**
 * Determines if the last load process has been ended successfully.
 */
export const getPosPrinterAdjustmentTypesIsLoaded = createSelector(
  selectPosPrinterAdjustmentTypes,
  (state: PosPrinterAdjustmentTypeState) => state.isLoaded
);
