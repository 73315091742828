<!-- cash account list starts -->
<div class="row">
  <div class="col-md-12">
    <div class="px-3">
      <div class="row">
        <div class="col-md-12 alert alert-light text-center" role="alert">
          ({{ (userCashAccounts$ | async)?.length | toNumber }})
          {{ 'AUTH.AUTH_DATA.CASH_ACCOUNTS_COUNT' | translate }}
        </div>
        <div class="col-md-12" *ngIf="(userCashAccounts$ | async)?.length; else noItems">
          <div class="row">
            <div class="col-md-12">
              <div class="table-responsive">
                <table class="table text-center">
                  <thead>
                    <tr>
                      <th scope="col"><i class="fa fa-list-ol"></i></th>
                      <th scope="col">
                        {{ 'AUTH.AUTH_DATA.CASH_ACCOUNT_CODE' | translate }}
                      </th>
                      <th scope="col">
                        {{ 'AUTH.AUTH_DATA.CASH_ACCOUNT_DESCRIPTION' | translate }}
                      </th>
                      <th scope="col">
                        {{ 'AUTH.AUTH_DATA.IS_DEFAULT' | translate }}
                      </th>
                      <th scope="col">{{ 'SHARED.TOOLS' | translate }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let cashAccount of userCashAccounts$ | async; trackBy: trackItems; let i = index">
                      <th scope="row">{{ i + 1 }}</th>
                      <td>{{ cashAccount.accountNumber }}</td>
                      <td>{{ cashAccount.name | localize: cashAccount.nameEn | async }}</td>
                      <td>
                        <app-status
                          [status]="true"
                          [activeText]="'AUTH.AUTH_DATA.CASH_ACCOUNT_DEFAULT_VALUE' | translate"
                          *ngIf="userDefaultCashBankAccountId === cashAccount.id"
                        >
                        </app-status>
                      </td>
                      <td>
                        <a
                          *ngIf="userDefaultCashBankAccountId !== cashAccount.id"
                          class="primary p-0"
                          (click)="selectDefaultCashAccount(cashAccount.id)"
                          [ngbTooltip]="'AUTH.SET_AS_DEFAULT_CASH_ACCOUNT' | translate"
                        >
                          <i class="fa fa-arrow-circle-down font-medium-3 mr-2"></i>
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- cash account list ends -->

<!-- no items template starts -->
<ng-template #noItems>
  <div class="col-md-12 alert alert-warning mt-3 text-center" role="alert">
    {{ 'AUTH.SEARCH_CASH_ACCOUNT_WITHOUT_ADD_NOT_FOUND' | translate }}
  </div>
</ng-template>
<!-- no items template ends -->
