import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import * as fromComponents from 'organizations/components';
import { Claims } from 'security/models';

const routes: Routes = [
  {
    path: 'create',
    component: fromComponents.CreateOrganizationComponent,
    data: { claims: [Claims.CREATE_ORGANIZATION] },
  },
  {
    path: 'update/:organizationId',
    component: fromComponents.UpdateOrganizationComponent,
    data: { claims: [Claims.UPDATE_ORGANIZATION] },
  },
  {
    path: '',
    component: fromComponents.OrganizationsListComponent,
    data: {
      claims: [
        Claims.CREATE_ORGANIZATION,
        Claims.UPDATE_ORGANIZATION,
        Claims.DELETE_ORGANIZATION,
        Claims.BLOCK_ORGANIZATION,
        Claims.ACTIVATE_ORGANIZATION,
      ],
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class OrganizationsRoutingModule {}
