<div class="row" *ngIf="filesForm && formArrayName" [formGroup]="filesForm.parent">
  <div class="col-md-12">
    <button
      type="button"
      class="btn btn-sm btn-raised btn-primary round"
      [ngbTooltip]="'SHARED.ADD_ATTACHMENTS' | translate"
      placement="top-right"
      (click)="add()"
    >
      <i class="fa fa-plus"></i>
    </button>
    <button
      type="button"
      class="btn btn-sm btn-raised btn-danger round mx-2"
      [ngbTooltip]="'SHARED.DELETE_ALL_ATTACHMENTS' | translate"
      (click)="reset()"
    >
      <i class="fa fa-trash"></i>
    </button>
  </div>
  <ng-container [formArrayName]="formArrayName">
    <div class="col-md-auto mb-2" *ngFor="let file of filesForm.controls">
      <app-file-upload [formControl]="file"></app-file-upload>
    </div>
  </ng-container>
</div>
