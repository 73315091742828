import { createSelector } from '@ngrx/store';

import { selectLookupsState } from 'lookups/store/selectors/feature.selectors';
import { LookupsState, LeadStatusState } from 'lookups/store/states';

/**
 * Gets the lead statuses state.
 */
const selectLeadStatuses = createSelector(selectLookupsState, (state: LookupsState) => state.leadStatuses);

/**
 * Gets the list of loaded lead statuses.
 */
export const getLeadStatuses = createSelector(selectLeadStatuses, (state: LeadStatusState) => state.data);

/**
 * Gets the error that occurred in the state.
 */
export const getLeadStatusesError = createSelector(selectLeadStatuses, (state: LeadStatusState) => state.error);

/**
 * Determines if there is a running load process.
 */
export const getLeadStatusesIsLoading = createSelector(selectLeadStatuses, (state: LeadStatusState) => state.isLoading);

/**
 * Determines if the last load process has been ended successfully.
 */
export const getLeadStatusesIsLoaded = createSelector(selectLeadStatuses, (state: LeadStatusState) => state.isLoaded);
