import { EmployeesSearchComponent } from 'app-search/components/employees/employees-search/employees-search.component';
import { SalesPersonsSearchComponent } from 'app-search/components/employees/sales-persons-search/sales-persons-search.component';

export * from 'app-search/components/employees/employees-search/employees-search.component';
export * from 'app-search/components/employees/sales-persons-search/sales-persons-search.component';

/**
 * The set of components registered on this sub-module.
 */
export const COMPONENTS: any[] = [EmployeesSearchComponent, SalesPersonsSearchComponent];
