<!-- bank account info form starts -->
<div class="row">
  <div class="col-md-12">
    <form [formGroup]="form" (ngSubmit)="submit()">
      <!-- bank account info starts -->
      <div class="card-header mb-3 pt-0">
        <h4 class="card-title">{{ 'FINANCES.BANK_ACCOUNTS.BANK_ACCOUNT_DATA_TITLE' | translate }}</h4>
      </div>
      <div class="card-content">
        <div class="px-3">
          <div class="row">
            <div class="form-group col-md-6">
              <label for="name">{{
                'FINANCES.BANK_ACCOUNTS.BANK_ACCOUNT_DATA.ARABIC_BANK_ACCOUNT_NAME' | translate
              }}</label>
              <input
                type="text"
                id="name"
                class="form-control round"
                [placeholder]="
                  'FINANCES.BANK_ACCOUNTS.BANK_ACCOUNT_DATA.ARABIC_BANK_ACCOUNT_NAME_PLACEHOLDER' | translate
                "
                maxlength="50"
                formControlName="name"
              />
            </div>
            <div class="form-group col-md-6">
              <label for="nameEn">{{
                'FINANCES.BANK_ACCOUNTS.BANK_ACCOUNT_DATA.ENGLISH_BANK_ACCOUNT_NAME' | translate
              }}</label>
              <input
                type="text"
                id="nameEn"
                class="form-control round"
                [placeholder]="
                  'FINANCES.BANK_ACCOUNTS.BANK_ACCOUNT_DATA.ENGLISH_BANK_ACCOUNT_NAME_PLACEHOLDER' | translate
                "
                maxlength="50"
                formControlName="nameEn"
              />
            </div>
            <div class="form-group col-md-6">
              <label for="bankId">{{ 'FINANCES.BANK_ACCOUNTS.BANK_ACCOUNT_DATA.BANK' | translate }}</label>
              <ng-select
                class="round"
                formControlName="bankId"
                labelForId="bankId"
                searchable="true"
                [placeholder]="'FINANCES.BANK_ACCOUNTS.BANK_ACCOUNT_DATA.BANK_PLACEHOLDER' | translate"
                [notFoundText]="'SHARED.SEARCH.SEARCH_NOT_FOUND' | translate"
                (open)="banksListVisibility = true"
              >
                <ng-option *ngFor="let bank of banks" [value]="bank.id">
                  {{ bank.name | localize: bank.nameEn | async }}
                </ng-option>
              </ng-select>
            </div>
            <div class="form-group col-md-6">
              <label for="accountNumber">{{
                'FINANCES.BANK_ACCOUNTS.BANK_ACCOUNT_DATA.ACCOUNT_NUMBER' | translate
              }}</label>
              <input
                type="text"
                id="accountNumber"
                maxlength="50"
                minlength="1"
                class="form-control round"
                [placeholder]="'FINANCES.BANK_ACCOUNTS.BANK_ACCOUNT_DATA.ACCOUNT_NUMBER_PLACEHOLDER' | translate"
                formControlName="accountNumber"
              />
            </div>
            <div class="form-group col-md-6">
              <label for="iban">{{ 'FINANCES.BANK_ACCOUNTS.BANK_ACCOUNT_DATA.ACCOUNT_NUMBER_IBAN' | translate }}</label>
              <input
                type="text"
                [patterns]="ibanPattern"
                mask="{{ ibanMask }}"
                id="iban"
                class="form-control round"
                [placeholder]="'FINANCES.BANK_ACCOUNTS.BANK_ACCOUNT_DATA.ACCOUNT_NUMBER_IBAN_PLACEHOLDER' | translate"
                formControlName="iban"
                [validation]="false"
              />
            </div>
            <div class="form-group col-md-6">
              <label for="currencyId">{{
                'FINANCES.BANK_ACCOUNTS.BANK_ACCOUNT_DATA.BANK_ACCOUNT_CURRENCY' | translate
              }}</label>
              <ng-select
                class="round"
                formControlName="currencyId"
                labelForId="currencyId"
                searchable="true"
                [placeholder]="'FINANCES.BANK_ACCOUNTS.BANK_ACCOUNT_DATA.BANK_ACCOUNT_CURRENCY_PLACEHOLDER' | translate"
                [notFoundText]="'SHARED.SEARCH.SEARCH_NOT_FOUND' | translate"
                (open)="currencyListVisibility = true"
              >
                <ng-option *ngFor="let currency of currencies" [value]="currency.id">
                  {{ currency.name | localize: currency.nameEn | async }}
                </ng-option>
              </ng-select>
            </div>
          </div>
          <div class="row">
            <div class="form-group col-12 d-flex justify-content-between flex-column flex-sm-row mt-2">
              <button type="submit" class="btn btn-raised btn-success round" [disabled]="isUpdating$ | async">
                <i class="fa fa-check"></i>
                <span class="mx-1">{{ 'SHARED.CONFIRM.SAVE' | translate }}</span>
              </button>
              <button class="btn btn-outline-dark round" *ngIf="form.dirty" (click)="discardChanges()">
                <i class="fa fa-undo"></i>
                <span class="mx-1">{{ 'FINANCES.BANK_ACCOUNTS.BANK_ACCOUNT_DATA.CANCEL_UPDATES' | translate }}</span>
              </button>
              <button class="btn btn-dark round" routerLink="/finances/bank-accounts">
                <i class="fa fa-undo"></i>
                <span class="mx-1">{{
                  'FINANCES.BANK_ACCOUNTS.BANK_ACCOUNT_DATA.BANK_ACCOUNTS_RETURN' | translate
                }}</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- bank account info ends -->
    </form>
  </div>
</div>
<!-- bank account info form ends -->

<!-- confirm modal starts -->
<ng-template #confirmModalRef let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
      {{ 'FINANCES.BANK_ACCOUNTS.CONFIRM_UPDATE_BANK_ACCOUNT_TITLE' | translate }}
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="d()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p class="text-success">{{ 'FINANCES.BANK_ACCOUNTS.CONFIRM_UPDATE_BANK_ACCOUNT_MESSAGE' | translate }}</p>
  </div>
  <div class="modal-footer">
    <div class="modal-actions">
      <button type="button" class="btn btn-raised btn-success round" (click)="c(); confirm()" ngbAutofocus>
        <i class="fa fa-check"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.SAVE' | translate }}</span>
      </button>
      <button type="button" class="btn btn-outline-dark round" (click)="c()">
        <i class="fa fa-times"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.CANCEL' | translate }}</span>
      </button>
    </div>
  </div>
</ng-template>
<!-- confirm modal ends -->

<!-- banks search starts -->
<app-banks-search onlyActive="true" closeOnSelect="true" [(visible)]="banksListVisibility" (banks)="selectBank($event)">
</app-banks-search>
<!-- banks search ends -->

<!-- currencies search starts -->
<app-currencies-search closeOnSelect="true" [(visible)]="currencyListVisibility" (currencies)="selectCurrency($event)">
</app-currencies-search>
<!-- currencies search ends -->
