import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';

import { Observable, Subscription } from 'rxjs';
import { skip, tap } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import * as fromSalesStore from 'sales/store';
import * as fromAuthStore from 'auth/store';
import { PageInfo, PrintService } from 'shared';
import { SaleInvoice } from 'sales/models';
import { Location } from 'stores/models';
import { Claims } from 'security/models';
import { SaleInvoiceOrderTypes } from 'lookups';

@Component({
  selector: 'app-orders-delivery-list',
  templateUrl: './orders-delivery-list.component.html',
  styles: [],
})
export class OrdersDeliveryListComponent implements OnInit, OnDestroy {
  /**
   * Gets or sets the information about the current page.
   */
  pageInfo: PageInfo = {
    title: 'SALES.ORDERS_DELIVERY.ORDERS_DELIVERY_DATA.ORDERS_DELIVERY',
    icon: 'fa fa-truck',
  };

  /**
   * The system supported user claims.
   */
  Claims = Claims;

  /**
   * Represents the system supported sale invoice order types.
   */
  saleInvoiceOrderType = SaleInvoiceOrderTypes;

  /**
   * Shows or hide the location list.
   */
  locationsListVisibility = false;

  /**
   * The list of selected locations.
   */
  locations: Location[] = [];

  /**
   * The list of sale invoices.
   */
  saleInvoices$: Observable<SaleInvoice[]>;

  /**
   * Indicates whether there is a search process is running or not.
   */
  isSearching$: Observable<boolean>;

  /**
   * Indicates whether there is a delivery process is running or not.
   */
  isDelivering$: Observable<boolean>;

  /**
   * The search form.
   */
  searchForm: FormGroup;

  /**
   * Gets or sets the currently selected sale invoice for delivery.
   */
  selectedSaleInvoice: SaleInvoice;

  /**
   * The set of subscriptions on this components,
   * these subscriptions must be unsubscribed before this component got destroyed.
   */
  subscriptions = new Subscription();

  /**
   * @param modalService The modal service.
   * @param salesStore$ The sales-module store.
   * @param printService The print service.
   * @param authStore$ the auth-store module.
   */
  constructor(
    private modalService: NgbModal,
    private salesStore$: Store<fromSalesStore.SalesState>,
    private printService: PrintService,
    private authStore$: Store<fromAuthStore.AuthState>
  ) {}

  ngOnInit(): void {
    this.init();
  }

  /**
   * Destroy component data
   */
  ngOnDestroy() {
    clearInterval(this.setIntervalConst);
    this.subscriptions.unsubscribe();
  }

  /**
   * Initialize component data.
   */
  init() {
    this.saleInvoices$ = this.salesStore$.pipe(select(fromSalesStore.getOrdersDelivery));
    this.isSearching$ = this.salesStore$.pipe(select(fromSalesStore.getOrderDeliverySearching));
    this.isDelivering$ = this.salesStore$.pipe(select(fromSalesStore.getOrderDelivering));

    this.initForm();
    this.search();

    /**
     * rest on order delivery completed
     */
    this.subscriptions.add(
      this.salesStore$
        .pipe(
          select(fromSalesStore.getOrderDeliveryCompleted),
          skip(1),
          tap((delivered) => {
            if (delivered) {
              this.search();
            }
          })
        )
        .subscribe()
    );

    /* Subscribe to selected location to select it whenever it changed. */
    this.subscriptions.add(
      this.authStore$
        .pipe(
          select(fromAuthStore.getSessionUserDefaultLocation),
          tap((location) => {
            if (location && !this.searchForm.value.locations.includes(location.id)) {
              this.selectLocations([location]);
            }
          })
        )
        .subscribe()
    );
  }

  /**
   * Initialize form and add validators.
   */
  initForm() {
    this.searchForm = new FormGroup({
      locations: new FormControl([]),
      page: new FormControl(1),
    });
  }

  /**
   * get searched order every 30 second.
   */
  setIntervalConst: ReturnType<typeof setInterval> = setInterval(() => {
    this.search();
  }, 30000);

  /**
   * Handles search parameters change.
   */
  search(event?: KeyboardEvent) {
    if (event && event.key === 'Enter') {
      event.preventDefault();
      return;
    }
    this.applyFiltersAndSearch();
  }

  /**
   * Applies the search filters and dispatches a search action.
   * @param page The current page number, default to `1`.
   */
  applyFiltersAndSearch(page: number = 1) {
    this.salesStore$.dispatch(
      new fromSalesStore.SearchOrdersDelivery({
        locations: this.searchForm.get('locations').value,
        page,
      })
    );
  }

  /**
   * Enables trackBy feature for *ngFor utility to track items
   * depending on a comparer to reduce DOM change.
   * If the item is already exist then no HTML will change, only bounded values.
   * @param index The index of the item.
   * @param saleInvoice The item to determine if it was changed or not.
   */
  trackItems(index: number, saleInvoice: SaleInvoice) {
    return saleInvoice ? saleInvoice.id : undefined;
  }

  /**
   * Opens the modal of the given templateRef.
   * @param modalRef The modal templateRef to be opened.
   */
  openModal(modalRef) {
    this.modalService.open(modalRef);
  }

  /**
   * Adds the newly selected locations from the locations search list.
   * @param locations The list of newly selected cost centers to be added.
   */
  selectLocations(locations: Location[]) {
    const selectedIds: number[] = this.searchForm.get('locations').value;
    this.locations = [...this.locations.filter((location) => selectedIds.includes(location.id)), ...locations];
    this.searchForm.patchValue({ locations: this.locations.map((location) => location.id) });
  }

  /**
   * Delivery the orders with the given id.
   * @param id The id of the order to be delivered.
   */
  delivery(id: number) {
    this.salesStore$.dispatch(new fromSalesStore.DeliveryOrder(id));
  }

  /**
   * Prints the current selected order delivery.
   */
  print() {
    const subscription = this.printService
      .printOrderDeliveryById(this.selectedSaleInvoice.id)
      .subscribe(() => subscription.unsubscribe());
  }
}
