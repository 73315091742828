import { SidebarToggleDirective } from 'core/directives/sidebar-toggle.directive';
import { SidebarAnchorToggleDirective } from 'core/directives/sidebar-anchor-toggle.directive';
import { SidebarLinkDirective } from 'core/directives/sidebar-link.directive';
import { SidebarListDirective } from 'core/directives/sidebar-list.directive';
import { SidebarDirective } from 'core/directives/sidebar.directive';

export * from 'core/directives/sidebar-toggle.directive';
export * from 'core/directives/sidebar-anchor-toggle.directive';
export * from 'core/directives/sidebar-link.directive';
export * from 'core/directives/sidebar-list.directive';
export * from 'core/directives/sidebar.directive';

/**
 * The set of directives registered on this module.
 */
export const DIRECTIVES: any[] = [
  SidebarToggleDirective,
  SidebarAnchorToggleDirective,
  SidebarLinkDirective,
  SidebarListDirective,
  SidebarDirective,
];
