<!-- page header starts -->
<app-page-header [info]="pageInfo"></app-page-header>
<!-- page header ends -->

<!-- product stock query list starts -->
<div class="row">
  <div class="col-md-12">
    <div class="card">
      <div class="card-header mb-3">
        <h4 class="card-title">{{ 'SHARED.SEARCH.SEARCH_TOOLS' | translate }}</h4>
      </div>
      <div class="card-content">
        <div class="px-3">
          <div class="row">
            <div class="col-md-12">
              <form class="row" [formGroup]="searchForm" (ngSubmit)="search()">
                <div class="form-group col">
                  <input
                    type="text"
                    class="form-control round"
                    [placeholder]="'STORES.PRODUCTS.PRODUCT_DATA.PRODUCT_DESCRIPTION_SEARCH_PLACEHOLDER' | translate"
                    formControlName="productId"
                    min="0"
                    tabindex="0"
                    ngbAutofocus
                  />
                </div>
                <div class="col-md-auto form-group d-flex">
                  <button type="submit" class="btn btn-raised btn-primary align-self-end round mx-1 mb-0">
                    <i class="fa fa-search"></i>
                  </button>
                  <button
                    type="button"
                    class="btn btn-raised btn-primary round align-self-end round mx-1 mb-0"
                    (click)="searchForStorableProducts = true; ProductListVisibility = true"
                  >
                    {{ 'STORES.PRODUCTS.PRODUCT_DATA.PRODUCT_SEARCH' | translate }}
                  </button>
                  <app-export-tools
                    *nIf="(productStock$ | async)?.length"
                    class="d-flex align-self-end mx-1"
                    targetElementId="product-stock-table-export-target-element"
                    [outputFileName]="'SHARED.EXPORT_TABLES.PRODUCT_STOCK_TABLE' | translate"
                  ></app-export-tools>
                </div>
              </form>
            </div>
            <div class="col-md-12" *ngIf="productStock$ | async; else noStockItems">
              <div class="row" *ngIf="productStock$ | async; let productStock">
                <div class="col-md-12">
                  <div class="table-responsive">
                    <table class="table text-center" id="product-stock-table-export-target-element">
                      <thead>
                        <tr>
                          <th scope="col">{{ 'STORES.PRODUCTS.PRODUCT_DATA.THE_PRODUCT' | translate }}</th>
                          <th scope="col">{{ 'STORES.PRODUCTS.PRODUCT_DATA.PRODUCT_TOTAL' | translate }}</th>
                          <th scope="col">{{ 'STORES.PRODUCTS.PRODUCT_DATA.UNIT_OF_MEASURE' | translate }}</th>
                          <th scope="col">{{ 'STORES.PRODUCTS.PRODUCT_DATA.VALUE_TOTAL' | translate }}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngIf="productStock$ | async; let productStock">
                          <th>
                            {{
                              productStock.product.description | localize: productStock.product.descriptionEn | async
                            }}
                          </th>
                          <td>{{ getProductTotalStock(productStock.stocks) | toDecimal }}</td>
                          <td>
                            {{
                              productStock.product.unitOfMeasure.name
                                | localize: productStock.product.unitOfMeasure.nameEn
                                | async
                            }}
                          </td>
                          <th>{{ getProductTotalStockValue(productStock.stocks) | toDecimal }}</th>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div class="col-md-12 alert alert-light text-center" role="alert">
                  <span class="font-weight-bold">{{ 'STORES.PRODUCTS.PRODUCT_DATA.BALANCE' | translate }}</span>
                </div>
                <div class="col-md-12">
                  <div class="table-responsive">
                    <table class="table text-center">
                      <thead>
                        <tr>
                          <th scope="col">{{ 'STORES.PRODUCTS.PRODUCT_DATA.LOCATION_ADDRESS' | translate }}</th>
                          <th *ngIf="productStock.product.hasSubProducts" scope="col">
                            {{ 'STORES.PRODUCTS.PRODUCT_DATA.THE_SUB_PRODUCT' | translate }}
                          </th>
                          <th scope="col">{{ 'STORES.PRODUCTS.PRODUCT_DATA.QUANTITY' | translate }}</th>
                          <th scope="col">{{ 'STORES.PRODUCTS.PRODUCT_DATA.UNIT_OF_MEASURE' | translate }}</th>
                          <th scope="col">{{ 'STORES.PRODUCTS.PRODUCT_DATA.VALUE' | translate }}</th>
                          <th scope="col">{{ 'STORES.PRODUCTS.PRODUCT_DATA.VALUE_TOTAL' | translate }}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let stock of productStock.stocks; trackBy: trackItems">
                          <th scope="row">{{ stock.location.name | localize: stock.location.nameEn | async }}</th>
                          <td *ngIf="productStock.product.hasSubProducts">
                            <div *ngIf="stock.subProduct" class="mt-1 d-block">
                              <app-sub-product-info [subProduct]="stock.subProduct"></app-sub-product-info>
                            </div>
                          </td>
                          <td>{{ stock.quantity | toDecimal }}</td>
                          <td>
                            {{
                              productStock.product.unitOfMeasure.name
                                | localize: productStock.product.unitOfMeasure.nameEn
                                | async
                            }}
                          </td>
                          <td>{{ stock.value | toDecimal }}</td>
                          <td>{{ stock.total | toDecimal }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- product stock query list ends -->

<!-- no items template starts -->
<ng-template #noStockItems>
  <div class="col-md-12 alert alert-warning mt-3 text-center" role="alert">
    {{ 'STORES.PRODUCTS.NO_SEARCH_RESULT' | translate }}
  </div>
</ng-template>
<!-- no items template ends -->

<!-- products search starts -->
<app-products-search
  [closeOnSelect]="true"
  [showProductStockQuery]="true"
  (products)="selectProduct($event)"
  [(visible)]="ProductListVisibility"
  [(searchForStorableProducts)]="searchForStorableProducts"
>
</app-products-search>
<!-- products search ends -->
