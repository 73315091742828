import { PosDeviceActionType, PosDeviceActions } from 'app-search/store/actions';
import { PosDevicesState } from 'app-search/store/states';

/**
 * The initial state from which the state starts.
 */
const initialState: PosDevicesState = {
  data: [],
  paginationInfo: {
    total: 0,
    page: 1,
    pageSize: 0,
    count: 0,
  },
  error: null,
  isSearching: false,
  isSearchCompleted: false,
};

/**
 * The reducer function that is called in each action dispatch against the state.
 * @param state The current state.
 * @param action The action that will affect the state.
 */
export function posDevicesReducer(state: PosDevicesState = initialState, action: PosDeviceActions): PosDevicesState {
  switch (action.type) {
    //#region SEARCH_POS_DEVICE

    case PosDeviceActionType.SEARCH_POS_DEVICES: {
      return {
        ...state,
        isSearching: true,
        isSearchCompleted: false,
        error: null,
      };
    }

    case PosDeviceActionType.SEARCH_POS_DEVICES_FAIL: {
      return {
        ...state,
        error: action.payload,
        isSearching: false,
        isSearchCompleted: false,
      };
    }

    case PosDeviceActionType.SEARCH_POS_DEVICES_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        paginationInfo: {
          ...action.payload.meta,
        },
        error: null,
        isSearching: false,
        isSearchCompleted: true,
      };
    }

    //#endregion SEARCH_POS_DEVICE

    default:
      return state;
  }
}
