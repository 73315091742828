import { Component, Input } from '@angular/core';

import { Decimal } from 'decimal.js';

import { SaleInvoice, SaleInvoiceProduct } from 'sales/models';

@Component({
  selector: 'app-sale-invoice-details',
  templateUrl: './sale-invoice-details.component.html',
  styles: [],
})
export class SaleInvoiceDetailsComponent {
  /**
   * Sets the current viewed invoice.
   */
  @Input() invoice: SaleInvoice;

  /**
   * Gets the total quantity of request products.
   */
  productsQuantity(products: SaleInvoiceProduct[]): number {
    return Decimal.sum(0, ...products.map((product) => product.quantity ?? 0)).toNumber();
  }
}
