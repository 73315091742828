import { Action } from '@ngrx/store';

import { AppHttpResponse } from 'shared';
import { FinancialPeriod } from 'finances/models';

/**
 * The finances-module store financial periods action types.
 */
export enum FinancialPeriodsActionType {
  SEARCH_FINANCIAL_PERIODS = '[Finances] [FinancialPeriod] Search financial periods',
  SEARCH_FINANCIAL_PERIODS_FAIL = '[Finances] [FinancialPeriod] Search financial periods Fail',
  SEARCH_FINANCIAL_PERIODS_SUCCESS = '[Finances] [FinancialPeriod] Search financial periods Success',

  OPEN_FINANCIAL_PERIOD = '[Finances] [FinancialPeriod] Open financial period',
  OPEN_FINANCIAL_PERIOD_FAIL = '[Finances] [FinancialPeriod] Open financial period Fail',
  OPEN_FINANCIAL_PERIOD_SUCCESS = '[Finances] [FinancialPeriod] Open financial period Success',

  PAUSE_FINANCIAL_PERIOD = '[Finances] [FinancialPeriod] Pause financial period',
  PAUSE_FINANCIAL_PERIOD_FAIL = '[Finances] [FinancialPeriod] Pause financial period Fail',
  PAUSE_FINANCIAL_PERIOD_SUCCESS = '[Finances] [FinancialPeriod] Pause financial period Success',

  CLOSE_FINANCIAL_PERIOD = '[Finances] [FinancialPeriod] Close financial period',
  CLOSE_FINANCIAL_PERIOD_FAIL = '[Finances] [FinancialPeriod] Close financial period Fail',
  CLOSE_FINANCIAL_PERIOD_SUCCESS = '[Finances] [FinancialPeriod] Close financial period Success',
}

/**
 * Represents a store financial periods search action.
 */
export class SearchFinancialPeriods implements Action {
  /**
   * The type of the action.
   */
  readonly type = FinancialPeriodsActionType.SEARCH_FINANCIAL_PERIODS;

  /**
   * Fires a new financial periods search action.
   * @param payload the search parameters,
   * if omitted, all financial periods will be loaded.
   */
  constructor(
    public payload?: {
      fromDate: Date;
      toDate: Date;
      page: number;
    }
  ) {}
}

/**
 * Represents a store financial periods search fail action.
 */
export class SearchFinancialPeriodsFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = FinancialPeriodsActionType.SEARCH_FINANCIAL_PERIODS_FAIL;

  /**
   * Fires a new financial periods search fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store search success action.
 */
export class SearchFinancialPeriodsSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = FinancialPeriodsActionType.SEARCH_FINANCIAL_PERIODS_SUCCESS;

  /**
   * Fires a new financial periods search success action.
   * @param payload An object contains the list of financial periods that matches the search criteria
   * in addition to the pagination info.
   */
  constructor(public payload: AppHttpResponse<FinancialPeriod[]>) {}
}

/**
 * Represents a store financial period open action.
 */
export class OpenFinancialPeriod implements Action {
  /**
   * The type of the action.
   */
  readonly type = FinancialPeriodsActionType.OPEN_FINANCIAL_PERIOD;

  /**
   * Fires a new financial period open action.
   * @param payload The financial period's id.
   */
  constructor(public payload: number) {}
}

/**
 * Represents a store financial periods open fail action.
 */
export class OpenFinancialPeriodFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = FinancialPeriodsActionType.OPEN_FINANCIAL_PERIOD_FAIL;

  /**
   * Fires a new financial period open fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store financial period open success action.
 */
export class OpenFinancialPeriodSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = FinancialPeriodsActionType.OPEN_FINANCIAL_PERIOD_SUCCESS;

  /**
   * Fires a new financial period open success action.
   * @param payload The opened financial period.
   */
  constructor(public payload: AppHttpResponse<FinancialPeriod>) {}
}

/**
 * Represents a store financial period pause action.
 */
export class PauseFinancialPeriod implements Action {
  /**
   * The type of the action.
   */
  readonly type = FinancialPeriodsActionType.PAUSE_FINANCIAL_PERIOD;

  /**
   * Fires a new financial period pause action.
   * @param payload The financial period's id.
   */
  constructor(public payload: number) {}
}

/**
 * Represents a store financial periods pause fail action.
 */
export class PauseFinancialPeriodFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = FinancialPeriodsActionType.PAUSE_FINANCIAL_PERIOD_FAIL;

  /**
   * Fires a new financial period pause fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store financial period pause success action.
 */
export class PauseFinancialPeriodSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = FinancialPeriodsActionType.PAUSE_FINANCIAL_PERIOD_SUCCESS;

  /**
   * Fires a new financial period pause success action.
   * @param payload The paused financial period.
   */
  constructor(public payload: AppHttpResponse<FinancialPeriod>) {}
}
/**
 * Represents a store financial period close action.
 */
export class CloseFinancialPeriod implements Action {
  /**
   * The type of the action.
   */
  readonly type = FinancialPeriodsActionType.CLOSE_FINANCIAL_PERIOD;

  /**
   * Fires a new financial period close action.
   * @param payload The financial period's id.
   */
  constructor(public payload: number) {}
}

/**
 * Represents a store financial periods close fail action.
 */
export class CloseFinancialPeriodFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = FinancialPeriodsActionType.CLOSE_FINANCIAL_PERIOD_FAIL;

  /**
   * Fires a new financial period close fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store financial period close success action.
 */
export class CloseFinancialPeriodSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = FinancialPeriodsActionType.CLOSE_FINANCIAL_PERIOD_SUCCESS;

  /**
   * Fires a new financial period close success action.
   * @param payload The closed financial period.
   */
  constructor(public payload: AppHttpResponse<FinancialPeriod>) {}
}

/**
 * finances-module financial periods action types.
 */
export type FinancialPeriodsActions =
  | SearchFinancialPeriods
  | SearchFinancialPeriodsFail
  | SearchFinancialPeriodsSuccess
  | OpenFinancialPeriod
  | OpenFinancialPeriodFail
  | OpenFinancialPeriodSuccess
  | PauseFinancialPeriod
  | PauseFinancialPeriodFail
  | PauseFinancialPeriodSuccess
  | CloseFinancialPeriod
  | CloseFinancialPeriodFail
  | CloseFinancialPeriodSuccess;
