import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';

import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { PageInfo, PaginationInfo } from 'shared';
import * as fromProjectsManagementStore from 'projects-management/store';
import * as fromLookupsStore from 'lookups/store';
import { Project } from 'projects-management/models';
import { Claims } from 'security/models';
import { Customer } from 'sales/models';
import { Location } from 'stores/models';
import { ProjectStatus } from 'lookups';

@Component({
  selector: 'app-projects-list',
  templateUrl: './projects-list.component.html',
  styles: [],
})
export class ProjectsListComponent implements OnInit {
  /**
   * The confirm modal template reference.
   */
  @ViewChild('deleteModalRef') deleteModalRef: ElementRef<any>;

  /**
   * Gets or sets the information about the current page.
   */
  pageInfo: PageInfo = {
    title: 'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.PROJECTS',
    icon: 'fa fa-cogs',
  };

  /**
   * The system supported user claims.
   */
  Claims = Claims;

  /**
   * Indicates whether there is a search process is running or not.
   */
  isSearching$: Observable<boolean>;

  /**
   * Indicates whether there is a delete-account process is running or not.
   */
  isDeleting$: Observable<boolean>;

  /**
   * The list of projects.
   */
  projects$: Observable<Project[]>;

  /**
   * The set of system supported project statuses.
   */
  projectStatuses$: Observable<ProjectStatus[]>;

  /**
   * The pagination info.
   */
  paginationInfo$: Observable<PaginationInfo>;

  /**
   * The list of selected customers.
   */
  customers: Customer[] = [];

  /**
   * Shows or hide the customers list.
   */
  customersListVisibility = false;

  /**
   * Shows or hide the locations list.
   */
  locationsListVisibility = false;

  /**
   * The list of selected locations.
   */
  locations: Location[] = [];

  /**
   * The search form.
   */
  searchForm: FormGroup;

  /**
   * Gets or sets the currently selected project.
   */
  selectedProject: Project;

  /**
   * @param modalService The modal service.
   * @param projectsManagementStore$ The projects-management store.
   * @param lookupsStore$ the lookups-store module.
   */
  constructor(
    private modalService: NgbModal,
    private projectsManagementStore$: Store<fromProjectsManagementStore.ProjectsManagementState>,
    private lookupsStore$: Store<fromLookupsStore.LookupsState>
  ) {}

  ngOnInit(): void {
    this.init();
  }

  /**
   * Initialize component data.
   */
  init() {
    this.initForm();

    /**
     * Load data.
     */
    this.isDeleting$ = this.projectsManagementStore$.pipe(
      select(fromProjectsManagementStore.getSelectedProjectDeleting)
    );

    this.isSearching$ = this.projectsManagementStore$.pipe(select(fromProjectsManagementStore.getProjectsSearching));

    let isManualSearchTriggeredBeforeForProjects = false;
    this.projects$ = this.projectsManagementStore$.pipe(
      select(fromProjectsManagementStore.getProjects),
      tap((projects) => {
        if (!isManualSearchTriggeredBeforeForProjects && !projects.length) {
          isManualSearchTriggeredBeforeForProjects = true;
          this.search();
        }
      })
    );

    let isManualSearchTriggeredBeforeForProjectStatuses = false;
    this.projectStatuses$ = this.lookupsStore$.pipe(
      select(fromLookupsStore.getProjectStatuses),
      tap((projectStatuses) => {
        if (!isManualSearchTriggeredBeforeForProjectStatuses && !projectStatuses.length) {
          isManualSearchTriggeredBeforeForProjectStatuses = true;
          this.lookupsStore$.dispatch(new fromLookupsStore.GetAllProjectStatuses());
        }
      })
    );

    this.paginationInfo$ = this.projectsManagementStore$.pipe(
      select(fromProjectsManagementStore.getProjectsPaginationInfo)
    );
  }

  /**
   * Initialize form and add validators.
   */
  initForm() {
    this.searchForm = new FormGroup({
      description: new FormControl(''),
      locations: new FormControl([]),
      projectStatuses: new FormControl([]),
      customers: new FormControl([]),
      page: new FormControl(1),
    });
  }

  /**
   * Handles search parameters change.
   */
  search(event?: KeyboardEvent) {
    if (event && event.key === 'Enter') {
      event.preventDefault();
      return;
    }

    this.applyFiltersAndSearch();
  }

  /**
   * Handles pagination page-changed event.
   * @param page The current selected page number.
   */
  pageChanged(page: number) {
    /** Update pagination page. */
    this.searchForm.patchValue({ page });

    this.applyFiltersAndSearch(page);
  }

  /**
   * Enables trackBy feature for *ngFor utility to track elements
   * depending on a comparer to reduce DOM change.
   * If the element is already exist then no HTML will change, only bounded values.
   * @param element The element to determine if it was changed or not.
   */
  trackElements(element: Project) {
    return element ? element.id : undefined;
  }

  /**
   * Applies the search filters and dispatches a search action.
   * @param page The current page number, default to `1`.
   */
  applyFiltersAndSearch(page: number = 1) {
    const { description, locations, projectStatuses, customers } = this.searchForm.value;
    this.projectsManagementStore$.dispatch(
      new fromProjectsManagementStore.SearchProjects({
        description,
        locations,
        projectStatuses,
        customers,
        page,
      })
    );
  }

  /**
   * Deletes the project with the given id.
   * @param projectId The id of the project to be deleted.
   */
  delete(projectId: number) {
    this.projectsManagementStore$.dispatch(new fromProjectsManagementStore.DeleteProject(projectId));
  }

  /**
   * Adds the newly selected customers the customers search list.
   * @param customers The list of newly selected customers to be added.
   */
  selectCustomers(customers: Customer[]) {
    const selectedIds: number[] = this.searchForm.get('customers').value;
    this.customers = [...this.customers.filter((customer) => selectedIds.includes(customer.id)), ...customers];
    this.searchForm.patchValue({ customers: this.customers.map((customer) => customer.id) });
  }

  /**
   * Opens the modal of the given templateRef.
   * @param modalRef The modal templateRef to be opened.
   */
  openModal(modalRef) {
    this.modalService.open(modalRef);
  }

  /**
   * Adds the newly selected locations the locations search list.
   * @param locations The list of newly selected locations to be added.
   */
  selectLocations(locations: Location[]) {
    const selectedIds: number[] = this.searchForm.get('locations').value;
    this.locations = [...this.locations.filter((location) => selectedIds.includes(location.id)), ...locations];
    this.searchForm.patchValue({ locations: this.locations.map((location) => location.id) });
  }
}
