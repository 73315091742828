/**
 * The set of system supported loan payment types.
 */
export enum LoanPaymentTypes {
  /**
   * The cash of the loan payment types.
   */
  CASH = 'CASH',

  /**
   * The bank of the loan payment types.
   */
  BANK = 'BANK',
}
