<!-- page header starts -->
<app-page-header [info]="pageInfo"></app-page-header>
<!-- page header ends -->

<!-- create customer payment by advance payment form starts -->
<div class="row">
  <div class="col-md-12">
    <form [formGroup]="form" (ngSubmit)="submit()">
      <div class="card px-3 pt-3">
        <div class="card-header mb-3">
          <h4 class="card-title">{{ 'SALES.CUSTOMERS.ADVANCE_PAYMENTS_DETAILS' | translate }}</h4>
        </div>
        <!-- payments details starts -->
        <div class="card-content">
          <div class="px-3">
            <div class="row">
              <div class="form-group col-md-6">
                <label for="customerLogId">{{
                  'SALES.CUSTOMERS.CUSTOMER_DATA.ADVANCE_PAYMENT_SELECT' | translate
                }}</label>
                <ng-select
                  class="round"
                  formControlName="customerLogId"
                  labelForId="customerLogId"
                  searchable="true"
                  [placeholder]="'SALES.CUSTOMERS.CUSTOMER_DATA.ADVANCE_PAYMENT_SELECT_PLACEHOLDER' | translate"
                  [notFoundText]="'SHARED.SEARCH.SEARCH_NOT_FOUND' | translate"
                  (open)="advancePaymentsListVisibility = true"
                >
                  <ng-option *ngFor="let advancePayment of advancePayments" [value]="advancePayment.id">
                    {{ advancePayment.customerLogType.name | localize: advancePayment.customerLogType.nameEn | async }}
                    -
                    <span class="text-danger" style="text-decoration: line-through;">{{
                      advancePayment.value | toDecimal
                    }}</span>
                    - <span>({{ advancePayment.advancePaymentBalance | toDecimal }})</span>
                  </ng-option>
                </ng-select>
              </div>
              <div class="form-group col-md-6">
                <label for="transactionDate">{{
                  'SALES.CUSTOMERS.CUSTOMER_DATA.CUSTOMER_ADVANCE_PAYMENT_DATE' | translate
                }}</label>
                <div class="input-group round">
                  <input
                    class="form-control"
                    id="transactionDate"
                    name="transactionDate"
                    formControlName="transactionDate"
                    [placeholder]="'SHARED.SEARCH.DATE_PLACEHOLDER' | translate"
                    ngbDatepicker
                    #transactionDate="ngbDatepicker"
                  />
                  <div class="input-group-append">
                    <span
                      class="input-group-text gradient-light-blue-indigo"
                      style="cursor: pointer;"
                      (click)="transactionDate.toggle()"
                    >
                      <i class="fa fa-calendar"></i>
                    </span>
                  </div>
                </div>
              </div>
              <div class="form-group col-md-12">
                <label for="notes">{{ 'SALES.CUSTOMERS.CUSTOMER_DATA.NOTES' | translate }}</label>
                <textarea
                  id="notes"
                  class="form-control round"
                  [placeholder]="'SALES.CUSTOMERS.CUSTOMER_DATA.NOTES_PLACEHOLDER' | translate"
                  rows="1"
                  minlength="0"
                  maxlength="200"
                  formControlName="notes"
                ></textarea>
              </div>
            </div>
          </div>
        </div>
        <!-- payments details ends -->

        <!-- invoices details starts -->
        <div class="card-header mb-3 pt-1">
          <h4 class="card-title">
            {{ 'SALES.CUSTOMERS.CUSTOMER_DATA.INVOICES_PAYMENT' | translate }}
          </h4>
        </div>
        <div class="card-content">
          <div class="px-3">
            <app-customer-payments-form
              [formArray]="paymentsForm"
              formArrayName="payments"
              [newInvoices]="newInvoices"
              [exclusiveCustomerId]="customerId"
            ></app-customer-payments-form>
          </div>
        </div>
        <!-- invoices details ends -->

        <!-- payments summary starts -->
        <div class="card-header mb-3 pt-3">
          <h4 class="card-title">{{ 'SALES.CUSTOMERS.CUSTOMER_DATA.PAYMENT_TITLE' | translate }}</h4>
        </div>
        <div class="card-content">
          <div class="px-3">
            <div class="row">
              <div class="col-md-12">
                <div class="row">
                  <div class="form-group col-md-6">
                    <label for="paymentsCount">{{ 'SALES.CUSTOMERS.CUSTOMER_DATA.INVOICES_COUNT' | translate }}</label>
                    <input
                      type="text"
                      id="paymentsCount"
                      name="paymentsCount"
                      class="form-control round"
                      [value]="paymentsForm?.length || 0"
                      readonly
                    />
                  </div>
                  <div class="form-group col-md-6">
                    <label for="paymentsTotal" class="font-weight-bold" style="color: #212529;">
                      <i class="fa fa-star"></i>
                      <span class="mx-1">{{ 'SALES.CUSTOMERS.CUSTOMER_DATA.PAYMENT_TOTAL' | translate }}</span>
                    </label>
                    <input
                      type="text"
                      id="paymentsTotal"
                      name="paymentsTotal"
                      class="form-control round"
                      [value]="(paymentsTotal | toDecimal) || '0.0'"
                      readonly
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- payments summary ends -->

        <!-- payments attachments starts -->
        <div class="card-header mb-3 pt-1">
          <h4 class="card-title">{{ 'SALES.CUSTOMERS.CUSTOMER_DATA.ATTACHMENTS' | translate }}</h4>
        </div>
        <div class="card-content">
          <div class="px-3">
            <app-files-upload formArrayName="attachments" [filesForm]="attachmentsForm"></app-files-upload>
          </div>
        </div>
        <!-- payments attachments ends -->

        <!-- payment tools starts -->
        <div class="row">
          <div class="form-group col-12 d-flex justify-content-between flex-column flex-sm-row mt-2">
            <button type="submit" class="btn btn-raised btn-success round" [disabled]="isCreating$ | async">
              <i class="fa fa-check"></i>
              <span class="mx-1">{{ 'SHARED.CONFIRM.SAVE' | translate }}</span>
            </button>
            <button
              type="button"
              class="btn btn-raised btn-danger round"
              (click)="openResetModal()"
              [disabled]="isCreating$ | async"
            >
              <i class="fa fa-trash"></i>
              <span class="mx-1">{{ 'SALES.CUSTOMERS.RESET_DATA' | translate }}</span>
            </button>
            <a class="btn btn-dark round" [routerLink]="['/sales/customers/files', customerId]">
              <i class="fa fa-undo"></i>
              <span class="mx-1">{{ 'SALES.CUSTOMERS.CUSTOMER_DATA.CUSTOMER_FILE_RETURN' | translate }}</span>
            </a>
          </div>
        </div>
        <!-- payment tools ends -->
      </div>
    </form>
  </div>
</div>
<!-- create customer payment by advance payment form ends -->

<!-- confirm modal starts -->
<ng-template #confirmModalRef let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
      {{ 'SALES.CUSTOMERS.CONFIRM_CREATE_PAYMENT_BY_ADVANCE_PAYMENT_TITLE' | translate }}
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="d()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p class="text-success">{{ 'SALES.CUSTOMERS.CONFIRM_CREATE_PAYMENT_BY_ADVANCE_PAYMENT_MESSAGE' | translate }}</p>
  </div>
  <div class="modal-footer">
    <div class="modal-actions">
      <button type="button" class="btn btn-raised btn-success round" (click)="c(); confirm()" ngbAutofocus>
        <i class="fa fa-check"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.SAVE' | translate }}</span>
      </button>
      <button type="button" class="btn btn-outline-dark round" (click)="c()">
        <i class="fa fa-times"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.CANCEL' | translate }}</span>
      </button>
    </div>
  </div>
</ng-template>
<!-- confirm modal ends -->

<!-- confirm reset modal starts -->
<ng-template #resetModalRef let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title text-danger" id="modal-basic-title">
      {{ 'SALES.CUSTOMERS.CONFIRM_RESET_PAYMENT_TITLE' | translate }}
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="d()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p class="text-danger">{{ 'SALES.CUSTOMERS.CONFIRM_RESET_PAYMENT_MESSAGE' | translate }}</p>
  </div>
  <div class="modal-footer">
    <div class="modal-actions">
      <button type="button" class="btn btn-raised btn-danger round" (click)="c(); resetForm()" ngbAutofocus>
        <i class="fa fa-check"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.YES' | translate }}</span>
      </button>
      <button type="button" class="btn btn-outline-dark round" (click)="c()">
        <i class="fa fa-times"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.CANCEL' | translate }}</span>
      </button>
    </div>
  </div>
</ng-template>
<!-- confirm reset modal ends -->

<!-- advance payments search starts -->
<app-customer-advance-payments-search
  onlyActive="true"
  closeOnSelect="true"
  (advancePayments)="selectAdvancePayment($event)"
  [(visible)]="advancePaymentsListVisibility"
>
</app-customer-advance-payments-search>
<!-- advance payments search ends -->
