import { Component, ViewChild, ElementRef, Input, Output, EventEmitter } from '@angular/core';

import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

import { PayrollLog } from 'hr/models';

@Component({
  selector: 'app-payroll-logs-info',
  templateUrl: './payroll-logs-info.component.html',
  styles: [],
})
export class PayrollLogsInfoComponent {
  /**
   * The modal template reference.
   */
  @ViewChild('modalRef') modalRef: ElementRef<any>;

  /**
   * The list of payroll logs.
   */
  @Input() payrollLogs: PayrollLog[];

  /**
   * Sets the visibility of this component modal.
   * Handles visibility change in two-data-binding approach.
   */
  @Input() set visible(isShown: boolean) {
    if (isShown && this.modalRef) {
      this.openModal(this.modalRef);
    } else {
      this.closeModal();
    }

    this.visibleChange.emit(isShown);
  }

  /**
   * Outputs a value that indicates whether the modal is shown or not.
   *
   * Handles visibility change in two-data-binding approach.
   */
  @Output() visibleChange = new EventEmitter<boolean>();

  /**
   * The ngb-modal reference to use it when modal needed to be closed.
   */
  modal: NgbModalRef;

  /**
   * @param modalService The modal service.
   */
  constructor(private modalService: NgbModal) {}

  /**
   * Initialize component data.
   */
  init() {}

  /**
   * Enables trackBy feature for *ngFor utility to track items
   * depending on a comparer to reduce DOM change.
   * If the item is already exist then no HTML will change, only bounded values.
   * @param index The index of the item.
   * @param log The item to determine if it was changed or not.
   */
  trackItems(index: number, log: PayrollLog) {
    return log ? log.id : undefined;
  }

  /**
   * Opens the modal of the given templateRef.
   * @param modalRef The modal templateRef to be opened.
   */
  openModal(modalRef) {
    /**
     * Open the modal & listen for user press ESC key.
     */
    this.modal = this.modalService.open(modalRef, { size: 'lg' });
    this.modal.result.then(null, () => this.closeModal());
  }

  /**
   * Closes the currently opened modal.
   */
  closeModal() {
    this.modal?.dismiss();
    this.visibleChange.emit(false);
  }
}
