import { Component, Input } from '@angular/core';

import { Journal } from 'finances/models';

@Component({
  selector: 'app-journal-details',
  templateUrl: './journal-details.component.html',
  styles: [],
})
export class JournalDetailsComponent {
  /**
   * Sets the current viewed journal.
   */
  @Input() journal: Journal;
}
