import { Action } from '@ngrx/store';

import { AssignmentStatus } from 'lookups/models';
import { AppHttpResponse } from 'shared';

/**
 * The Lookups-Module store assignment status action types.
 */
export enum AssignmentStatusActionType {
  GET_ALL_ASSIGNMENT_STATUSES = '[Lookups] [AssignmentStatus] Get All Assignment Statuses',
  GET_ALL_ASSIGNMENT_STATUSES_FAIL = '[Lookups] [AssignmentStatus] Get All Assignment Statuses Fail',
  GET_ALL_ASSIGNMENT_STATUSES_SUCCESS = '[Lookups] [AssignmentStatus] Get All Assignment Statuses Success',
}

/**
 * Represents a store assignment status action.
 */
export class GetAllAssignmentStatuses implements Action {
  /**
   * The type of the action.
   */
  readonly type = AssignmentStatusActionType.GET_ALL_ASSIGNMENT_STATUSES;

  /**
   * Fires a new assignment status action.
   */
  constructor() {}
}

/**
 * Represents a store assignment status fail action.
 */
export class GetAllAssignmentStatusesFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = AssignmentStatusActionType.GET_ALL_ASSIGNMENT_STATUSES_FAIL;

  /**
   * Fires a new assignment status fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store assignment status success action.
 */
export class GetAllAssignmentStatusesSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = AssignmentStatusActionType.GET_ALL_ASSIGNMENT_STATUSES_SUCCESS;

  /**
   * Fires a assignment status success action.
   * @param payload An object contains the list of assignment statuses.
   */
  constructor(public payload: AppHttpResponse<AssignmentStatus[]>) {}
}

/**
 * Lookups-module assignment status action types.
 */
export type AssignmentStatusActions =
  | GetAllAssignmentStatuses
  | GetAllAssignmentStatusesFail
  | GetAllAssignmentStatusesSuccess;
