import { Component, OnInit, OnDestroy } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';

import { Observable, Subscription } from 'rxjs';
import { tap, skip } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import * as fromSalesStore from 'sales/store';
import * as fromLookupsStore from 'lookups/store';
import * as fromPrintingStore from 'printing/store';
import { PageInfo, PrintService, TranslationService, NotificationService } from 'shared';
import { MaintenanceStages, PrintPaperSize } from 'lookups';
import { Claims } from 'security/models';
import { SaleInvoice, SaleInvoiceAttachment } from 'sales/models';

/**
 * The view sale invoice component pages.
 */
enum PAGES {
  details = 'details',
  'edit-history' = 'edit-history',
  'payment-history' = 'payment-history',
  attachments = 'attachments',
  journal = 'journal',
}

@Component({
  selector: 'app-view-sale-invoice',
  templateUrl: './view-sale-invoice.component.html',
  styles: [],
})
export class ViewSaleInvoiceComponent implements OnInit, OnDestroy {
  /**
   * Gets or sets the information about the current page.
   */
  pageInfo: PageInfo = {
    title: 'SALES.INVOICES.SALE_INVOICE_DATA.DISPLAY_SALES_INVOICE',
    icon: 'fa fa-eye',
  };

  /**
   * The system supported user claims.
   */
  Claims = Claims;

  /**
   * Gets or sets the id of the current viewed invoice.
   */
  invoiceId: number;

  /**
   * The current viewed invoice.
   */
  invoice$: Observable<SaleInvoice>;

  /**
   * Represents the system supported maintenance stages.
   */
  InvoiceMaintenanceStages = MaintenanceStages;

  /**
   * The set of system supported print paper sizes.
   */
  printPaperSizes$: Observable<PrintPaperSize[]>;

  /**
   * Gets or sets the selected page.
   * @default 'details'
   */
  activePage: PAGES = PAGES.details;

  /**
   * The set of subscriptions on this components,
   * these subscriptions must be unsubscribed before this component got destroyed.
   */
  subscriptions = new Subscription();

  constructor(
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private locationService: Location,
    private notificationService: NotificationService,
    private printService: PrintService,
    private translationService: TranslationService,
    private lookupsStore$: Store<fromLookupsStore.LookupsState>,
    private printingStore$: Store<fromPrintingStore.PrintingState>,
    private salesStore$: Store<fromSalesStore.SalesState>
  ) {}

  ngOnInit(): void {
    this.init();
  }

  /**
   * Destroy component data
   */
  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  /**
   * Initialize component data.
   */
  init() {
    /* Load the selected invoice. */
    this.invoiceId = +this.route.snapshot.params.invoiceId;
    this.salesStore$.dispatch(new fromSalesStore.FindSaleInvoice(this.invoiceId));

    let isManualSearchTriggeredBeforeForPrintPaperSizes = false;
    this.printPaperSizes$ = this.lookupsStore$.pipe(
      select(fromLookupsStore.getPrintPaperSizes),
      tap((printPaperSizes) => {
        if (!isManualSearchTriggeredBeforeForPrintPaperSizes && !printPaperSizes.length) {
          isManualSearchTriggeredBeforeForPrintPaperSizes = true;
          this.lookupsStore$.dispatch(new fromLookupsStore.GetAllPrintPaperSize());
        }
      })
    );

    /**
     * Print the transaction once it is ready.
     */
    this.subscriptions.add(
      this.printingStore$
        .pipe(
          select(fromPrintingStore.getSaleInvoicePrint),
          skip(1),
          tap((report) => {
            if (report) {
              this.printService.printPDF(report.pdfUrl, (error, request) => {
                this.notificationService.error(this.translationService.translate('REPORTS.FAILED_TO_PRINT'));
              });
            }
          })
        )
        .subscribe()
    );

    this.invoice$ = this.salesStore$.pipe(
      select(fromSalesStore.getSelectedSaleInvoice),
      skip(1),
      tap((invoice) => {
        if (invoice) {
          /* Update page info. */
          this.pageInfo = {
            ...this.pageInfo,
            subTitle: this.translationService.translate('SALES.INVOICES.SALE_INVOICE_DATA.SALE_INVOICE_NUMBER', {
              invoiceNumber: invoice.invoiceNum,
            }),
          };
        }
      })
    );

    /** Select the user desired page. */
    this.activePage = PAGES[this.route.snapshot.fragment] ?? this.activePage;
    this.selectedPageChanged(this.activePage);
  }

  /**
   * Updates the browser url according to the selected page.
   * @param page The newly selected page.
   */
  selectedPageChanged(page: PAGES) {
    this.locationService.replaceState(`${this.locationService.path()}#${PAGES[page]}`);
  }

  /**
   * Opens the modal of the given templateRef.
   * @param modalRef The modal templateRef to be opened.
   */
  openModal(modalRef) {
    this.modalService.open(modalRef);
  }

  /**
   * Maps and returns the set of sales invoice attachments into a set of urls.
   * @param attachments The sales invoice attachments to be mapped.
   */
  getAttachmentsUrls(attachments: SaleInvoiceAttachment[]): string[] {
    return attachments ? attachments.map((attachment) => attachment.url) : [];
  }

  /**
   * Changes the invoice maintenance stage.
   * @param stage The stage to be changed to.
   */
  changeMaintenanceStage(stage: MaintenanceStages): void {
    this.salesStore$.dispatch(new fromSalesStore.ChangeSaleInvoiceMaintenanceStage({ id: this.invoiceId, stage }));
  }

  /**
   * Prints the current displayed transaction.
   * @param paperSize The paper size to be printed.
   */
  print(paperSize: string) {
    this.printingStore$.dispatch(
      new fromPrintingStore.GetSaleInvoicePrint({
        saleInvoiceId: this.invoiceId,
        paperSize,
      })
    );
  }
}
