import { createSelector } from '@ngrx/store';

import { SettingsState, TaxesState } from 'settings/store/states';
import { selectSettingsState } from 'settings/store/selectors/feature.selectors';

/**
 * Gets the taxes state.
 */
const selectTaxState = createSelector(selectSettingsState, (state: SettingsState) => state.taxes);

/**
 * Gets the list of loaded taxes.
 */
export const getTaxes = createSelector(selectTaxState, (state: TaxesState) => state.data);

/**
 * Gets the taxes pagination info.
 */
export const getTaxesPaginationInfo = createSelector(selectTaxState, (state: TaxesState) => state.paginationInfo);

/**
 * Gets the error that occurred in the state.
 */
export const getTaxesError = createSelector(selectTaxState, (state: TaxesState) => state.error);

/**
 * Determines if there is a running search process.
 */
export const getTaxesSearching = createSelector(selectTaxState, (state: TaxesState) => state.isSearching);

/**
 * Determines if the last search process has been ended successfully.
 */
export const getTaxesSearchCompleted = createSelector(selectTaxState, (state: TaxesState) => state.isSearchCompleted);

/**
 * Gets the selected tax.
 */
export const getSelectedTax = createSelector(selectTaxState, (state: TaxesState) => state.selectedTax);

/**
 * Determines if there is a running find-tax process.
 */
export const getTaxesFinding = createSelector(selectTaxState, (state: TaxesState) => state.isFinding);

/**
 * Determines if the last find-tax process has been ended successfully.
 */
export const getTaxesFindCompleted = createSelector(selectTaxState, (state: TaxesState) => state.isFindCompleted);

/**
 * Gets the default tax.
 */
export const getDefaultTax = createSelector(selectTaxState, (state: TaxesState) => state.defaultTax);

/**
 * Determines if there is a running find-default-tax process.
 */
export const getDefaultTaxFinding = createSelector(selectTaxState, (state: TaxesState) => state.isDefaultTaxFinding);

/**
 * Determines if the last find-default-tax process has been ended successfully.
 */
export const getDefaultTaxFindCompleted = createSelector(
  selectTaxState,
  (state: TaxesState) => state.isDefaultTaxFindCompleted
);

/**
 * Determines if there is a running create process.
 */
export const getSelectedTaxCreating = createSelector(selectTaxState, (state: TaxesState) => state.isCreating);

/**
 * Determines if the last create process has been ended successfully.
 */
export const getSelectedTaxCreateCompleted = createSelector(
  selectTaxState,
  (state: TaxesState) => state.isCreateCompleted
);

/**
 * Determines if there is a running update process.
 */
export const getSelectedTaxUpdating = createSelector(selectTaxState, (state: TaxesState) => state.isUpdating);

/**
 * Determines if the last update process has been ended successfully.
 */
export const getSelectedTaxUpdateCompleted = createSelector(
  selectTaxState,
  (state: TaxesState) => state.isUpdateCompleted
);

/**
 * Determines if there is a running delete process.
 */
export const getSelectedTaxDeleting = createSelector(selectTaxState, (state: TaxesState) => state.isDeleting);

/**
 * Determines if the last delete process has been ended successfully.
 */
export const getSelectedTaxDeleteCompleted = createSelector(
  selectTaxState,
  (state: TaxesState) => state.isDeleteCompleted
);
