import { Action } from '@ngrx/store';

import { PrimaryAccount } from 'lookups/models';
import { AppHttpResponse } from 'shared';

/**
 * The Lookups-Module store primary account action types.
 */
export enum PrimaryAccountsActionType {
  GET_ALL_PRIMARY_ACCOUNT = '[Lookups] [PrimaryAccount] Get All Primary Accounts',
  GET_ALL_PRIMARY_ACCOUNT_FAIL = '[Lookups] [PrimaryAccount] Get All Primary Accounts Fail',
  GET_ALL_PRIMARY_ACCOUNT_SUCCESS = '[Lookups] [PrimaryAccount] Get All Primary Accounts Success',
}

/**
 * Represents a store primary account action.
 */
export class GetAllPrimaryAccount implements Action {
  /**
   * The type of the action.
   */
  readonly type = PrimaryAccountsActionType.GET_ALL_PRIMARY_ACCOUNT;

  /**
   * Fires a new primary account action.
   */
  constructor() {}
}

/**
 * Represents a store primary account fail action.
 */
export class GetAllPrimaryAccountFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = PrimaryAccountsActionType.GET_ALL_PRIMARY_ACCOUNT_FAIL;

  /**
   * Fires a new primary account fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store primary account success action.
 */
export class GetAllPrimaryAccountSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = PrimaryAccountsActionType.GET_ALL_PRIMARY_ACCOUNT_SUCCESS;

  /**
   * Fires a primary account success action.
   * @param payload An object contains the list of primary accounts.
   */
  constructor(public payload: AppHttpResponse<PrimaryAccount[]>) {}
}

/**
 * Lookups-module primary account action types.
 */
export type PrimaryAccountActions = GetAllPrimaryAccount | GetAllPrimaryAccountFail | GetAllPrimaryAccountSuccess;
