import { Action } from '@ngrx/store';

import { ProjectResourceType } from 'lookups/models';
import { AppHttpResponse } from 'shared';

/**
 * The Lookups-Module store  project resource types action types.
 */
export enum ProjectResourceTypeActionType {
  GET_ALL_PROJECT_RESOURCE_TYPES = '[Lookups] [ProjectResourceType] Get All Project Resource Types',
  GET_ALL_PROJECT_RESOURCE_TYPES_FAIL = '[Lookups] [ProjectResourceType] Get All Project Resource Types Fail',
  GET_ALL_PROJECT_RESOURCE_TYPES_SUCCESS = '[Lookups] [ProjectResourceType] Get All Project Resource Types Success',
}

/**
 * Represents a store  project resource type action.
 */
export class GetAllProjectResourceTypes implements Action {
  /**
   * The type of the action.
   */
  readonly type = ProjectResourceTypeActionType.GET_ALL_PROJECT_RESOURCE_TYPES;

  /**
   * Fires a new  project resource type action.
   */
  constructor() {}
}

/**
 * Represents a store  project resource type fail action.
 */
export class GetAllProjectResourceTypesFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = ProjectResourceTypeActionType.GET_ALL_PROJECT_RESOURCE_TYPES_FAIL;

  /**
   * Fires a new  project resource type fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store  project resource type success action.
 */
export class GetAllProjectResourceTypesSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = ProjectResourceTypeActionType.GET_ALL_PROJECT_RESOURCE_TYPES_SUCCESS;

  /**
   * Fires a  project resource type success action.
   * @param payload An object contains the list of  Cities.
   */
  constructor(public payload: AppHttpResponse<ProjectResourceType[]>) {}
}

/**
 * Lookups-module  project resource type action types.
 */
export type ProjectResourceTypeActions =
  | GetAllProjectResourceTypes
  | GetAllProjectResourceTypesFail
  | GetAllProjectResourceTypesSuccess;
