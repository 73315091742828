import { findIndex, remove } from 'lodash';

import { FinancialPeriodsActionType, FinancialPeriodsActions } from 'finances/store/actions';
import { FinancialPeriodsState } from 'finances/store/states';

/**
 * The initial state from which the state starts.
 */
const initialState: FinancialPeriodsState = {
  data: [],
  paginationInfo: {
    total: 0,
    page: 1,
    pageSize: 0,
    count: 0,
  },
  error: null,
  isSearching: false,
  isSearchCompleted: false,
  selectedFinancialPeriod: null,
  isOpening: false,
  isOpenCompleted: false,
  isPausing: false,
  isPauseCompleted: false,
  isClosing: false,
  isCloseCompleted: false,
};

/**
 * The reducer function that is called in each action dispatch against the state.
 * @param state The current state.
 * @param action The action that will affect the state.
 */
export function financialPeriodsReducer(
  state: FinancialPeriodsState = initialState,
  action: FinancialPeriodsActions
): FinancialPeriodsState {
  switch (action.type) {
    //#region SEARCH_FINANCIAL_PERIODS

    case FinancialPeriodsActionType.SEARCH_FINANCIAL_PERIODS: {
      return {
        ...state,
        isSearching: true,
        isSearchCompleted: false,
        error: null,
      };
    }

    case FinancialPeriodsActionType.SEARCH_FINANCIAL_PERIODS_FAIL: {
      return {
        ...state,
        error: action.payload,
        isSearching: false,
        isSearchCompleted: false,
      };
    }

    case FinancialPeriodsActionType.SEARCH_FINANCIAL_PERIODS_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        paginationInfo: {
          ...action.payload.meta,
        },
        error: null,
        isSearching: false,
        isSearchCompleted: true,
      };
    }

    //#endregion SEARCH_FINANCIAL_PERIODS

    //#region OPEN_FINANCIAL_PERIOD

    case FinancialPeriodsActionType.OPEN_FINANCIAL_PERIOD: {
      return {
        ...state,
        isOpening: true,
        isOpenCompleted: false,
        error: null,
      };
    }

    case FinancialPeriodsActionType.OPEN_FINANCIAL_PERIOD_FAIL: {
      return {
        ...state,
        error: action.payload,
        isOpening: false,
        isOpenCompleted: false,
      };
    }

    case FinancialPeriodsActionType.OPEN_FINANCIAL_PERIOD_SUCCESS: {
      let periods = [...state.data];
      const periodIndex = findIndex(periods, (period) => period.id === action.payload.data.id);

      /* If period was found, update it. */
      if (periodIndex >= 0) {
        periods[periodIndex] = action.payload.data;
      } else {
        /* else, insert it to the beginning of the array. */
        periods = [action.payload.data, ...periods];
      }

      return {
        ...state,
        data: periods,
        selectedFinancialPeriod: action.payload.data,
        error: null,
        isOpening: false,
        isOpenCompleted: true,
      };
    }

    //#endregion OPEN_FINANCIAL_PERIOD

    //#region PAUSE_FINANCIAL_PERIOD

    case FinancialPeriodsActionType.PAUSE_FINANCIAL_PERIOD: {
      return {
        ...state,
        isPausing: true,
        isPauseCompleted: false,
        error: null,
      };
    }

    case FinancialPeriodsActionType.PAUSE_FINANCIAL_PERIOD_FAIL: {
      return {
        ...state,
        error: action.payload,
        isPausing: false,
        isPauseCompleted: false,
      };
    }

    case FinancialPeriodsActionType.PAUSE_FINANCIAL_PERIOD_SUCCESS: {
      let periods = [...state.data];
      const periodIndex = findIndex(periods, (period) => period.id === action.payload.data.id);

      /* If period was found, update it. */
      if (periodIndex >= 0) {
        periods[periodIndex] = action.payload.data;
      } else {
        /* else, insert it to the beginning of the array. */
        periods = [action.payload.data, ...periods];
      }

      return {
        ...state,
        data: periods,
        selectedFinancialPeriod: action.payload.data,
        error: null,
        isPausing: false,
        isPauseCompleted: true,
      };
    }

    //#endregion PAUSE_FINANCIAL_PERIOD

    //#region CLOSE_FINANCIAL_PERIOD

    case FinancialPeriodsActionType.CLOSE_FINANCIAL_PERIOD: {
      return {
        ...state,
        isClosing: true,
        isCloseCompleted: false,
        error: null,
      };
    }

    case FinancialPeriodsActionType.CLOSE_FINANCIAL_PERIOD_FAIL: {
      return {
        ...state,
        error: action.payload,
        isClosing: false,
        isCloseCompleted: false,
      };
    }

    case FinancialPeriodsActionType.CLOSE_FINANCIAL_PERIOD_SUCCESS: {
      let periods = [...state.data];
      const periodIndex = findIndex(periods, (period) => period.id === action.payload.data.id);

      /* If period was found, update it. */
      if (periodIndex >= 0) {
        periods[periodIndex] = action.payload.data;
      } else {
        /* else, insert it to the beginning of the array. */
        periods = [action.payload.data, ...periods];
      }

      return {
        ...state,
        data: periods,
        selectedFinancialPeriod: action.payload.data,
        error: null,
        isClosing: false,
        isCloseCompleted: true,
      };
    }

    //#endregion CLOSE_FINANCIAL_PERIOD

    default:
      return state;
  }
}
