<!-- page header starts -->
<app-page-header [info]="pageInfo"></app-page-header>
<!-- page header ends -->

<!-- update order-delivery device form starts -->
<div class="row">
  <div class="col-md-12">
    <form
      [formGroup]="form"
      (ngSubmit)="submit()"
      *ngIf="orderDeliveryPrinters$ | async; let &quot;order-deliveryPrinter&quot;"
    >
      <div class="card">
        <div class="card-header mb-3">
          <h4 class="card-title">
            {{ 'SALES.ORDER_DELIVERY_PRINTERS.ORDER_DELIVERY_PRINTER_DATA_TITLE' | translate }}
          </h4>
        </div>
        <div class="card-content">
          <div class="px-3">
            <div class="row">
              <div class="form-group col-md-12">
                <label for="posPrinterId">{{ 'SALES.ORDER_DELIVERY_PRINTERS.THE_PRINTER_DEVICE' | translate }}</label>
                <ng-select
                  class="round"
                  formControlName="posPrinterId"
                  labelForId="posPrinterId"
                  searchable="true"
                  [placeholder]="'SALES.ORDER_DELIVERY_PRINTERS.THE_PRINTER_DEVICE_PLACEHOLDER' | translate"
                  [notFoundText]="'SHARED.SEARCH.SEARCH_NOT_FOUND' | translate"
                  (open)="posPrintersListVisibility = true"
                >
                  <ng-option *ngFor="let posPrinter of posPrinters" [value]="posPrinter.id">
                    {{ posPrinter.name }}
                  </ng-option>
                </ng-select>
              </div>
              <div class="form-group col-md-12">
                <div class="card">
                  <div class="card-header mb-3 pt-0">
                    <h4 class="card-title">
                      {{ 'SALES.ORDER_DELIVERY_PRINTERS.ORDER_TYPE_FORM_TITLE' | translate }}
                    </h4>
                  </div>
                  <div class="card-content">
                    <div class="px-3">
                      <div class="row">
                        <div class="col-md-12">
                          <app-order-types-form
                            [formArray]="orderTypesForm"
                            [isOrderTypeRequired]="true"
                            [newOrderTypes]="newOrderTypes"
                          ></app-order-types-form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="form-group col-12 d-flex justify-content-between flex-column flex-sm-row mt-2">
                <button type="submit" class="btn btn-raised btn-success round" [disabled]="isUpdating$ | async">
                  <i class="fa fa-check"></i>
                  <span class="mx-1">{{ 'SHARED.CONFIRM.SAVE' | translate }}</span>
                </button>
                <a class="btn btn-dark round" routerLink="/sales/pos-home" fragment="order-delivery-printers">
                  <i class="fa fa-undo"></i>
                  <span class="mx-1">{{
                    'SALES.ORDER_DELIVERY_PRINTERS.ORDER_DELIVERY_PRINTER_RETURN' | translate
                  }}</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
<!-- update order delivery printer form ends -->

<!-- confirm modal starts -->
<ng-template #confirmModalRef let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
      {{ 'SALES.ORDER_DELIVERY_PRINTERS.CONFIRM_UPDATE_ORDER_DELIVERY_PRINTER_TITLE' | translate }}
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="d()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p class="text-success">
      {{ 'SALES.ORDER_DELIVERY_PRINTERS.CONFIRM_UPDATE_ORDER_DELIVERY_PRINTER_MESSAGE' | translate }}
    </p>
  </div>
  <div class="modal-footer">
    <div class="modal-actions">
      <button type="button" class="btn btn-raised btn-success round" (click)="c(); confirm()" ngbAutofocus>
        <i class="fa fa-check"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.SAVE' | translate }}</span>
      </button>
      <button type="button" class="btn btn-outline-dark round" (click)="c()">
        <i class="fa fa-times"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.CANCEL' | translate }}</span>
      </button>
    </div>
  </div>
</ng-template>
<!-- confirm modal ends -->

<!-- pos device classes search starts -->
<app-pos-printers-search
  [closeOnSelect]="true"
  (posPrinter)="selectPosPrinter($event)"
  [(visible)]="posPrintersListVisibility"
>
</app-pos-printers-search>
<!-- pos device classes search ends -->
