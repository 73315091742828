<!-- page header starts -->
<app-page-header [info]="pageInfo"></app-page-header>
<!-- page header ends -->

<!-- customers payments list starts -->
<div class="row">
  <div class="col-md-12">
    <div class="card">
      <div class="card-header mb-3">
        <h4 class="card-title">{{ 'SHARED.SEARCH.SEARCH_TOOLS' | translate }}</h4>
      </div>
      <div class="card-content">
        <div class="px-3">
          <div class="row">
            <div class="col-md-12">
              <form class="row" [formGroup]="searchForm" (ngSubmit)="search()">
                <div class="form-group col-md-6">
                  <label for="customers">{{ 'SALES.CUSTOMERS.CUSTOMER_DATA.CUSTOMERS' | translate }}</label>
                  <ng-select
                    class="round"
                    formControlName="customers"
                    [multiple]="true"
                    [closeOnSelect]="false"
                    labelForId="customers"
                    searchable="true"
                    [placeholder]="'SALES.CUSTOMERS.CUSTOMER_DATA.CUSTOMERS_PLACEHOLDER' | translate"
                    [notFoundText]="'SHARED.SEARCH.SEARCH_NOT_FOUND' | translate"
                    (open)="customersListVisibility = true"
                  >
                    <ng-option *ngFor="let customer of customers" [value]="customer.id">
                      {{ customer.name | localize: customer.nameEn | async }}
                    </ng-option>
                  </ng-select>
                </div>
                <div class="form-group col-md-6">
                  <label for="customerLogTypes">{{
                    'SALES.CUSTOMERS.CUSTOMER_DATA.CUSTOMER_LOG_TYPE' | translate
                  }}</label>
                  <ng-select
                    class="round"
                    formControlName="customerLogTypes"
                    [multiple]="true"
                    [closeOnSelect]="false"
                    labelForId="customerLogTypes"
                    searchable="true"
                    [placeholder]="'SALES.CUSTOMERS.CUSTOMER_DATA.CUSTOMER_LOG_TYPE_PLACEHOLDER' | translate"
                    [notFoundText]="'SHARED.SEARCH.SEARCH_NOT_FOUND' | translate"
                  >
                    <ng-option *ngFor="let type of customerLogTypes$ | async" [value]="type.id">
                      {{ type.name | localize: type.nameEn | async }}
                    </ng-option>
                  </ng-select>
                </div>
                <div class="col-md-6">
                  <div class="row">
                    <div class="form-group col-12 col-sm-6 mb-0">
                      <label for="fromDate">{{ 'SHARED.SEARCH.FROM_DATE' | translate }}</label>
                      <div class="input-group round">
                        <input
                          class="form-control"
                          id="fromDate"
                          name="fromDate"
                          formControlName="fromDate"
                          [placeholder]="'SHARED.SEARCH.DATE_PLACEHOLDER' | translate"
                          ngbDatepicker
                          #fromDate="ngbDatepicker"
                        />
                        <div class="input-group-append">
                          <span
                            class="input-group-text gradient-light-blue-indigo"
                            style="cursor: pointer;"
                            (click)="fromDate.toggle()"
                          >
                            <i class="fa fa-calendar"></i>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="form-group col-12 col-sm-6 mb-0">
                      <label for="toDate">{{ 'SHARED.SEARCH.TO_DATE' | translate }}</label>
                      <div class="input-group round">
                        <input
                          class="form-control"
                          id="toDate"
                          name="toDate"
                          formControlName="toDate"
                          [placeholder]="'SHARED.SEARCH.DATE_PLACEHOLDER' | translate"
                          ngbDatepicker
                          #toDate="ngbDatepicker"
                        />
                        <div class="input-group-append">
                          <span
                            class="input-group-text gradient-light-blue-indigo"
                            style="cursor: pointer;"
                            (click)="toDate.toggle()"
                          >
                            <i class="fa fa-calendar"></i>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-auto form-group d-flex">
                  <button
                    type="submit"
                    class="btn btn-raised btn-primary round align-self-end mx-1 mb-0"
                    [disabled]="isSearching$ | async"
                  >
                    <i class="fa fa-search"></i>
                  </button>
                  <a
                    *ngIf="[Claims.CREATE_CUSTOMER_PAYMENT] | authorize | async"
                    routerLink="/sales/customers/payments/create"
                    class="btn btn-raised btn-success align-self-end round mx-1 mb-0"
                  >
                    <i class="fa fa-plus"></i>
                    <span class="mx-1">{{ 'SHARED.CONFIRM.ADD' | translate }}</span>
                  </a>
                  <app-export-tools
                    *ngIf="(payments$ | async)?.length"
                    class="d-flex align-self-end mx-1"
                    targetElementId="customer-payments-table-export-target-element"
                    [outputFileName]="'SHARED.EXPORT_TABLES.CUSTOMER_PAYMENTS_TABLE' | translate"
                  ></app-export-tools>
                </div>
              </form>
            </div>
            <div class="col-md-12" *ngIf="(payments$ | async)?.length; else noItems">
              <div class="row">
                <div class="col-md-12 alert alert-light text-center" role="alert">
                  {{ 'SHARED.SEARCH.SEARCH_RESULTS' | translate }} ({{ (paginationInfo$ | async).total | toNumber }})
                  {{ 'SALES.CUSTOMERS.CUSTOMER_DATA.PAYMENT_CUSTOMERS_LOG' | translate }}
                </div>
                <div class="col-md-12">
                  <div class="table-responsive">
                    <table class="table text-center" id="customer-payments-table-export-target-element">
                      <thead>
                        <tr>
                          <th scope="col"><i class="fa fa-list-ol"></i></th>
                          <th scope="col">{{ 'SALES.CUSTOMERS.CUSTOMER_DATA.CUSTOMER' | translate }}</th>
                          <th scope="col">{{ 'SALES.CUSTOMERS.CUSTOMER_DATA.PAYMENT_VALUE' | translate }}</th>
                          <th scope="col">{{ 'SALES.CUSTOMERS.CUSTOMER_DATA.CUSTOMER_LOG_TYPE' | translate }}</th>
                          <th scope="col">
                            {{ 'SALES.CUSTOMERS.CUSTOMER_DATA.CUSTOMER_TRANSACTION_DATE' | translate }}
                          </th>
                          <th scope="col">{{ 'SHARED.SEARCH.CREATED_AT' | translate }}</th>
                          <th scope="col">{{ 'SALES.CUSTOMERS.CUSTOMER_DATA.USER' | translate }}</th>
                          <th scope="col">{{ 'SHARED.TOOLS' | translate }}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let log of payments$ | async; let i = index; trackBy: trackItems">
                          <th scope="row">{{ i + 1 }}</th>
                          <td>{{ log.customer.name | localize: log.customer.nameEn | async }}</td>
                          <td>
                            <span class="badge bg-success round">
                              {{ log.value | toDecimal }}
                            </span>
                          </td>
                          <td>
                            {{ log.customerLogType?.name | localize: log.customerLogType?.nameEn | async }}
                            <span *ngIf="log.bankId"> - {{ log.bank?.name | localize: log.bank?.nameEn | async }}</span>
                          </td>
                          <td>
                            <span [ngbTooltip]="log.transactionDate | date: 'time'">
                              {{ log.transactionDate | date }}
                            </span>
                          </td>
                          <td>
                            <span [ngbTooltip]="log.createdAt | date: 'time'">
                              {{ log.createdAt | date }}
                            </span>
                          </td>
                          <td>{{ log.user.name }}</td>
                          <td>
                            <a
                              class="info p-0"
                              [ngbTooltip]="'SALES.CUSTOMERS.CUSTOMER_DATA.DISPLAY_DETAILS' | translate"
                              (click)="view(log)"
                            >
                              <i class="fa fa-eye font-medium-3 mr-2"></i>
                            </a>
                            <a
                              *ngIf="[Claims.UPDATE_CUSTOMER, Claims.CREATE_CUSTOMER_PAYMENT] | authorize | async"
                              class="info p-0"
                              [routerLink]="['/sales/customers/files', log.customerId]"
                              [ngbTooltip]="'SALES.CUSTOMERS.CUSTOMER_DATA.CUSTOMER_FILE' | translate"
                            >
                              <i class="fa fa-folder font-medium-3 mr-2"></i>
                            </a>
                            <a
                              class="info p-0"
                              [ngbTooltip]="'SALES.CUSTOMERS.CUSTOMER_DATA.DISPLAY_JOURNAL_LINE' | translate"
                              (click)="selectedPayment = log; openModalJournal(viewJournalModalRef)"
                            >
                              <i class="fa fa-calendar font-medium-3 mr-2"></i>
                            </a>
                            <a
                              *ngIf="log.customerLogAttachments?.length"
                              class="info p-0"
                              (click)="selectedPayment = log; openModal(viewAttachmentsModalRef)"
                              [ngbTooltip]="'SALES.CUSTOMERS.CUSTOMER_DATA.DISPLAY_ATTACHMENTS' | translate"
                            >
                              <i class="fa fa-paperclip font-medium-3 mr-2"></i>
                            </a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div class="d-flex justify-content-between p-2">
                      <ngb-pagination
                        *ngIf="paginationInfo$ | async; let pagination"
                        maxSize="5"
                        [collectionSize]="pagination.total"
                        [pageSize]="pagination.pageSize"
                        [page]="pagination.page"
                        (pageChange)="pageChanged($event)"
                        boundaryLinks="true"
                      >
                        <ng-template ngbPaginationFirst>{{
                          'SHARED.PAGINATION.PAGINATION_FIRST' | translate
                        }}</ng-template>
                        <ng-template ngbPaginationPrevious>{{
                          'SHARED.PAGINATION.PAGINATION_PREVIOUS' | translate
                        }}</ng-template>
                        <ng-template ngbPaginationNext>{{
                          'SHARED.PAGINATION.PAGINATION_NEXT' | translate
                        }}</ng-template>
                        <ng-template ngbPaginationLast>{{
                          'SHARED.PAGINATION.PAGINATION_LAST' | translate
                        }}</ng-template>
                      </ngb-pagination>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- customers payments ends -->

<!-- no items template starts -->
<ng-template #noItems>
  <div class="col-md-12 alert alert-warning text-center mt-2" role="alert">
    {{ 'SALES.CUSTOMERS.SEARCH_PAYMENTS_LOG_NOT_FOUND' | translate }}
    <a
      *ngIf="[Claims.CREATE_CUSTOMER_PAYMENT] | authorize | async"
      routerLink="/sales/customers/payments/create"
      class="btn btn-raised btn-success round"
    >
      <i class="fa fa-plus"></i>
      <span class="mx-1">{{ 'SHARED.CONFIRM.ADD' | translate }}</span>
    </a>
  </div>
</ng-template>
<!-- no items template ends -->

<!-- view modal starts -->
<ng-template #viewModalRef let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title text-info" id="modal-basic-title">
      {{ 'SALES.CUSTOMERS.DISPLAY_CUSTOMER_PAYMENT_DATA' | translate }}
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="d()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-md-12">
        <div class="table-responsive">
          <table class="table text-center">
            <thead>
              <tr>
                <th scope="col">{{ 'SALES.CUSTOMERS.CUSTOMER_DATA.CUSTOMER' | translate }}</th>
                <th scope="col">{{ 'SALES.CUSTOMERS.CUSTOMER_DATA.PAYMENT_TOTAL' | translate }}</th>
                <th scope="col">{{ 'SALES.CUSTOMERS.CUSTOMER_DATA.CUSTOMER_LOG_TYPE' | translate }}</th>
                <th scope="col">{{ 'SALES.CUSTOMERS.CUSTOMER_DATA.INVOICES_PAYMENT_COUNT' | translate }}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row">
                  {{ selectedPayment.customer.name | localize: selectedPayment.customer.nameEn | async }}
                </th>
                <td>
                  <span class="badge bg-success round">
                    {{ selectedPayment.value | toDecimal }}
                  </span>
                </td>
                <td>
                  {{
                    selectedPayment.customerLogType?.name | localize: selectedPayment.customerLogType?.nameEn | async
                  }}
                  <span *ngIf="selectedPayment.bankAccountId">
                    -
                    {{
                      selectedPayment.bankAccount?.name | localize: selectedPayment.bankAccount?.nameEn | async
                    }}</span
                  >
                </td>
                <td>{{ selectedPayment.saleInvoicePayments.length }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="col-md-12 mt-2">
        <div class="table-responsive">
          <table class="table text-center">
            <thead>
              <tr>
                <th scope="col">
                  <i class="fa fa-barcode"></i
                  ><span class="mx-1">{{ 'SALES.CUSTOMERS.CUSTOMER_DATA.INVOICE_NUMBER' | translate }}</span>
                </th>
                <th scope="col">{{ 'SALES.CUSTOMERS.CUSTOMER_DATA.PAYMENT_VALUE' | translate }}</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let invoicePayment of selectedPayment.saleInvoicePayments">
                <th scope="row">
                  {{ invoicePayment.saleInvoice.invoiceNum }}
                </th>
                <td>
                  <span class="badge bg-success round">
                    {{ invoicePayment.value | toDecimal }}
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="modal-actions">
      <button type="button" class="btn btn-outline-dark round" (click)="c()" ngbAutofocus>
        <i class="fa fa-times"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.CLOSE' | translate }}</span>
      </button>
    </div>
  </div>
</ng-template>
<!-- view modal ends -->

<!-- view customer log journal modal starts -->
<ng-template #viewJournalModalRef let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title text-info" id="modal-basic-title">
      {{ 'SALES.CUSTOMERS.CUSTOMER_DATA.DISPLAY_CUSTOMER_JOURNAL_LINE' | translate }}
    </h4>

    <button type="button" class="close" aria-label="Close" (click)="d()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <app-journal-details [journal]="selectedPayment.journalAssociations[0]?.journal"> </app-journal-details>
  </div>
  <div class="modal-footer">
    <div class="modal-actions">
      <button type="button" class="btn btn-outline-dark round" (click)="c()">
        <i class="fa fa-times"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.CANCEL' | translate }}</span>
      </button>
    </div>
  </div>
</ng-template>
<!-- view customer log journal modal ends -->

<!-- customers search starts -->
<app-customers-search [(visible)]="customersListVisibility" (customers)="selectCustomers($event)">
</app-customers-search>
<!-- customers search ends -->

<!-- view attachments modal starts -->
<ng-template #viewAttachmentsModalRef let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title text-info" id="modal-basic-title">
      {{ 'SALES.CUSTOMERS.CUSTOMER_DATA.ATTACHMENTS' | translate }}
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="d()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="card-content">
        <div class="px-3">
          <div class="row">
            <div class="form-group col-md-12">
              <!-- customer log attachments starts -->
              <div class="card-content">
                <div class="px-3">
                  <app-files-viewer
                    [files]="getAttachmentsUrls(selectedPayment.customerLogAttachments)"
                  ></app-files-viewer>
                </div>
              </div>
              <!-- customer log attachments ends -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="modal-actions">
      <button type="button" class="btn btn-raised btn-primary round" (click)="c()" ngbAutoFocus>
        <i class="fa fa-times"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.CLOSE' | translate }}</span>
      </button>
    </div>
  </div>
</ng-template>
<!-- view attachments modal ends -->
