import { findIndex, remove } from 'lodash';

import { PettyCashActionType, PettyCashActions } from 'finances/store/actions';
import { PettyCashState } from 'finances/store/states';

/**
 * The initial state from which the state starts.
 */
const initialState: PettyCashState = {
  data: [],
  paginationInfo: {
    total: 0,
    page: 1,
    pageSize: 0,
    count: 0,
  },
  error: null,
  isSearching: false,
  isSearchCompleted: false,
  selectedPettyCash: null,
  isFinding: false,
  isFindCompleted: false,
  isCreating: false,
  isCreateCompleted: false,
  isUpdating: false,
  isUpdateCompleted: false,
  isBlocking: false,
  isBlockCompleted: false,
  isActivating: false,
  isActivateCompleted: false,
  isDeleting: false,
  isDeleteCompleted: false,
};

/**
 * The reducer function that is called in each action dispatch against the state.
 * @param state The current state.
 * @param action The action that will affect the state.
 */
export function pettyCashReducer(state: PettyCashState = initialState, action: PettyCashActions): PettyCashState {
  switch (action.type) {
    //#region SEARCH_PETTY_Cash

    case PettyCashActionType.SEARCH_PETTY_CASH: {
      return {
        ...state,
        isSearching: true,
        isSearchCompleted: false,
        error: null,
      };
    }

    case PettyCashActionType.SEARCH_PETTY_CASH_FAIL: {
      return {
        ...state,
        error: action.payload,
        isSearching: false,
        isSearchCompleted: false,
      };
    }

    case PettyCashActionType.SEARCH_PETTY_CASH_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        paginationInfo: {
          ...action.payload.meta,
        },
        error: null,
        isSearching: false,
        isSearchCompleted: true,
      };
    }

    //#endregion SEARCH_PETTY_Cash

    //#region FIND_PETTY_CASH

    case PettyCashActionType.FIND_PETTY_CASH: {
      return {
        ...state,
        error: null,
        selectedPettyCash: null,
        isFinding: true,
        isFindCompleted: false,
      };
    }

    case PettyCashActionType.FIND_PETTY_CASH_FAIL: {
      return {
        ...state,
        error: action.payload,
        isFinding: false,
        isFindCompleted: false,
      };
    }

    case PettyCashActionType.FIND_PETTY_CASH_SUCCESS: {
      let pettyCashList = [...state.data];
      const pettyCashIndex = findIndex(pettyCashList, (pettyCash) => pettyCash.id === action.payload.data.id);

      /* If petty cash was found, update it. */
      if (pettyCashIndex >= 0) {
        pettyCashList[pettyCashIndex] = action.payload.data;
      } else {
        /* else, insert it to the beginning of the array. */
        pettyCashList = [action.payload.data, ...pettyCashList];
      }

      return {
        ...state,
        data: pettyCashList,
        selectedPettyCash: action.payload.data,
        error: null,
        isFinding: false,
        isFindCompleted: true,
      };
    }

    //#endregion FIND_PETTY_CASH

    //#region CREATE_PETTY_CASH

    case PettyCashActionType.CREATE_PETTY_CASH: {
      return {
        ...state,
        isCreating: true,
        isCreateCompleted: false,
        error: null,
      };
    }

    case PettyCashActionType.CREATE_PETTY_CASH_FAIL: {
      return {
        ...state,
        error: action.payload,
        isCreating: false,
        isCreateCompleted: false,
      };
    }

    case PettyCashActionType.CREATE_PETTY_CASH_SUCCESS: {
      return {
        ...state,
        data: [action.payload.data, ...state.data],
        selectedPettyCash: action.payload.data,
        error: null,
        isCreating: false,
        isCreateCompleted: true,
      };
    }

    //#endregion CREATE_PETTY_CASH

    //#region UPDATE_PETTY_CASH

    case PettyCashActionType.UPDATE_PETTY_CASH: {
      return {
        ...state,
        isUpdating: true,
        isUpdateCompleted: false,
        error: null,
      };
    }

    case PettyCashActionType.UPDATE_PETTY_CASH_FAIL: {
      return {
        ...state,
        error: action.payload,
        isUpdating: false,
        isUpdateCompleted: false,
      };
    }

    case PettyCashActionType.UPDATE_PETTY_CASH_SUCCESS: {
      let pettyCashList = [...state.data];
      const pettyCashIndex = findIndex(pettyCashList, (pettyCash) => pettyCash.id === action.payload.data.id);

      /* If petty cash was found, update it. */
      if (pettyCashIndex >= 0) {
        pettyCashList[pettyCashIndex] = action.payload.data;
      } else {
        /* else, insert it to the beginning of the array. */
        pettyCashList = [action.payload.data, ...pettyCashList];
      }

      return {
        ...state,
        data: pettyCashList,
        selectedPettyCash: action.payload.data,
        error: null,
        isUpdating: false,
        isUpdateCompleted: true,
      };
    }

    //#endregion UPDATE_PETTY_CASH

    //#region BLOCK_PETTY_CASH

    case PettyCashActionType.BLOCK_PETTY_CASH: {
      return {
        ...state,
        isBlocking: true,
        isBlockCompleted: false,
        error: null,
      };
    }

    case PettyCashActionType.BLOCK_PETTY_CASH_FAIL: {
      return {
        ...state,
        error: action.payload,
        isBlocking: false,
        isBlockCompleted: false,
      };
    }

    case PettyCashActionType.BLOCK_PETTY_CASH_SUCCESS: {
      let pettyCashList = [...state.data];
      const pettyCashIndex = findIndex(pettyCashList, (pettyCash) => pettyCash.id === action.payload.data.id);

      /* If petty cash was found, update it. */
      if (pettyCashIndex >= 0) {
        pettyCashList[pettyCashIndex] = action.payload.data;
      } else {
        /* else, insert it to the beginning of the array. */
        pettyCashList = [action.payload.data, ...pettyCashList];
      }

      return {
        ...state,
        data: pettyCashList,
        selectedPettyCash: action.payload.data,
        error: null,
        isBlocking: false,
        isBlockCompleted: true,
      };
    }

    //#endregion BLOCK_PETTY_CASH

    //#region ACTIVATE_PETTY_CASH

    case PettyCashActionType.ACTIVATE_PETTY_CASH: {
      return {
        ...state,
        isActivating: true,
        isActivateCompleted: false,
        error: null,
      };
    }

    case PettyCashActionType.ACTIVATE_PETTY_CASH_FAIL: {
      return {
        ...state,
        error: action.payload,
        isActivating: false,
        isActivateCompleted: false,
      };
    }

    case PettyCashActionType.ACTIVATE_PETTY_CASH_SUCCESS: {
      let pettyCashList = [...state.data];
      const pettyCashIndex = findIndex(pettyCashList, (pettyCash) => pettyCash.id === action.payload.data.id);

      /* If petty cash was found, update it. */
      if (pettyCashIndex >= 0) {
        pettyCashList[pettyCashIndex] = action.payload.data;
      } else {
        /* else, insert it to the beginning of the array. */
        pettyCashList = [action.payload.data, ...pettyCashList];
      }

      return {
        ...state,
        data: pettyCashList,
        selectedPettyCash: action.payload.data,
        error: null,
        isActivating: false,
        isActivateCompleted: true,
      };
    }

    //#endregion ACTIVATE_PETTY_CASH

    //#region DELETE_PETTY_CASH

    case PettyCashActionType.DELETE_PETTY_CASH: {
      return {
        ...state,
        isDeleting: true,
        isDeleteCompleted: false,
        error: null,
      };
    }

    case PettyCashActionType.DELETE_PETTY_CASH_FAIL: {
      return {
        ...state,
        error: action.payload,
        isDeleting: false,
        isDeleteCompleted: false,
      };
    }

    case PettyCashActionType.DELETE_PETTY_CASH_SUCCESS: {
      const prettyCash = [...state.data];

      /* Remove the deleted petty cash from the array. */
      remove(prettyCash, (pettyCash) => pettyCash.id === action.payload.data.id);

      return {
        ...state,
        data: prettyCash,
        selectedPettyCash: action.payload.data,
        error: null,
        isDeleting: false,
        isDeleteCompleted: true,
      };
    }

    //#endregion DELETE_PETTY_CASH

    default:
      return state;
  }
}
