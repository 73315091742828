import { createSelector } from '@ngrx/store';

import { JournalAssociationState, FinancesState } from 'finances/store/states';
import { selectFinancesState } from 'finances/store/selectors/feature.selectors';

/**
 * Gets the journals state.
 */
const selectJournalAssociationState = createSelector(
  selectFinancesState,
  (state: FinancesState) => state.journalAssociation
);

/**
 * Gets the vendor log.
 */
export const getJournalAssociationVendorLogData = createSelector(
  selectJournalAssociationState,
  (state: JournalAssociationState) => state.vendorLogData
);

/**
 * Gets the asset.
 */
export const getJournalAssociationAssetData = createSelector(
  selectJournalAssociationState,
  (state: JournalAssociationState) => state.assetData
);

/**
 * Gets the sale return.
 */
export const getJournalAssociationSaleReturnData = createSelector(
  selectJournalAssociationState,
  (state: JournalAssociationState) => state.saleReturnData
);

/**
 * Gets the customer log.
 */
export const getJournalAssociationCustomerLogData = createSelector(
  selectJournalAssociationState,
  (state: JournalAssociationState) => state.customerLogData
);

/**
 * Gets the sale invoice.
 */
export const getJournalAssociationSaleInvoiceData = createSelector(
  selectJournalAssociationState,
  (state: JournalAssociationState) => state.saleInvoiceData
);

/**
 * Gets the employee loan.
 */
export const getJournalAssociationEmployeeLoanData = createSelector(
  selectJournalAssociationState,
  (state: JournalAssociationState) => state.employeeLoanData
);

/**
 * Gets the excluded asset.
 */
export const getJournalAssociationExcludedAssetData = createSelector(
  selectJournalAssociationState,
  (state: JournalAssociationState) => state.excludedAssetData
);

/**
 * Gets the incoming stock.
 */
export const getJournalAssociationIncomingStockData = createSelector(
  selectJournalAssociationState,
  (state: JournalAssociationState) => state.incomingStockData
);

/**
 * Gets the outgoing stock.
 */
export const getJournalAssociationOutgoingStockData = createSelector(
  selectJournalAssociationState,
  (state: JournalAssociationState) => state.outgoingStockData
);

/**
 * Gets the transfer stock.
 */
export const getJournalAssociationTransferStockData = createSelector(
  selectJournalAssociationState,
  (state: JournalAssociationState) => state.transferStockData
);

/**
 * Gets the accrual payroll.
 */
export const getJournalAssociationAccrualPayrollData = createSelector(
  selectJournalAssociationState,
  (state: JournalAssociationState) => state.accrualPayrollData
);

/**
 * Gets the payroll payment refund.
 */
export const getJournalAssociationPayrollPaymentRefundData = createSelector(
  selectJournalAssociationState,
  (state: JournalAssociationState) => state.payrollPaymentRefundData
);

/**
 * Gets the gov service requests.
 */
export const getJournalAssociationGovServiceRequestsData = createSelector(
  selectJournalAssociationState,
  (state: JournalAssociationState) => state.govServiceRequestsData
);

/**
 * Gets the bank account log.
 */
export const getJournalAssociationBankAccountLogData = createSelector(
  selectJournalAssociationState,
  (state: JournalAssociationState) => state.bankAccountLogData
);

/**
 * Gets the payroll Payment.
 */
export const getJournalAssociationPayrollPaymentData = createSelector(
  selectJournalAssociationState,
  (state: JournalAssociationState) => state.payrollPaymentData
);

/**
 * Gets the purchase Return.
 */
export const getJournalAssociationPurchaseReturnData = createSelector(
  selectJournalAssociationState,
  (state: JournalAssociationState) => state.purchaseReturnData
);

/**
 * Gets the petty cash refund.
 */
export const getJournalAssociationPettyCashRefundData = createSelector(
  selectJournalAssociationState,
  (state: JournalAssociationState) => state.pettyCashRefundData
);

/**
 * Gets the purchase invoice.
 */
export const getJournalAssociationPurchaseInvoiceData = createSelector(
  selectJournalAssociationState,
  (state: JournalAssociationState) => state.purchaseInvoiceData
);

/**
 * Gets the asset sale invoice.
 */
export const getJournalAssociationAssetSaleInvoiceData = createSelector(
  selectJournalAssociationState,
  (state: JournalAssociationState) => state.assetSaleInvoiceData
);

/**
 * Gets the asset depreciation.
 */
export const getJournalAssociationAssetDepreciationData = createSelector(
  selectJournalAssociationState,
  (state: JournalAssociationState) => state.assetDepreciationData
);

/**
 * Gets the petty cash refund request.
 */
export const getJournalAssociationPettyCashRefundRequestData = createSelector(
  selectJournalAssociationState,
  (state: JournalAssociationState) => state.pettyCashRefundRequestData
);

/**
 * Gets the error that occurred in the state.
 */
export const getJournalAssociationJournalAssociationError = createSelector(
  selectJournalAssociationState,
  (state: JournalAssociationState) => state.error
);
/**
 * Determines if there is a running find journal process.
 */
export const getJournalAssociationVendorLogFinding = createSelector(
  selectJournalAssociationState,
  (state: JournalAssociationState) => state.isFindingVendorLog
);

/**
 * Determines if the last find journal process has been ended successfully.
 */
export const getJournalAssociationVendorLogFindCompleted = createSelector(
  selectJournalAssociationState,
  (state: JournalAssociationState) => state.isFindVendorLogCompleted
);

/**
 * Determines if there is a running find accrual payroll process.
 */
export const getJournalAssociationAccrualPayrollFinding = createSelector(
  selectJournalAssociationState,
  (state: JournalAssociationState) => state.isFindingAccrualPayroll
);

/**
 * Determines if the last find accrual payroll process has been ended successfully.
 */
export const getJournalAssociationAccrualPayrollFindCompleted = createSelector(
  selectJournalAssociationState,
  (state: JournalAssociationState) => state.isFindAccrualPayrollCompleted
);

/**
 * Determines if there is a running find payroll payment refund process.
 */
export const getJournalAssociationPayrollPaymentRefundFinding = createSelector(
  selectJournalAssociationState,
  (state: JournalAssociationState) => state.isFindingPayrollPaymentRefund
);

/**
 * Determines if the last find payroll payment refund process has been ended successfully.
 */
export const getJournalAssociationPayrollPaymentRefundFindCompleted = createSelector(
  selectJournalAssociationState,
  (state: JournalAssociationState) => state.isFindPayrollPaymentRefundCompleted
);

/**
 * Determines if there is a running find gov service request process.
 */
export const getJournalAssociationGovServiceRequestFinding = createSelector(
  selectJournalAssociationState,
  (state: JournalAssociationState) => state.isFindingGovServiceRequest
);

/**
 * Determines if the last find gov service request process has been ended successfully.
 */
export const getJournalAssociationGovServiceRequestFindCompleted = createSelector(
  selectJournalAssociationState,
  (state: JournalAssociationState) => state.isFindGovServiceRequestCompleted
);

/**
 * Determines if there is a running find asset process.
 */
export const getJournalAssociationAssetFinding = createSelector(
  selectJournalAssociationState,
  (state: JournalAssociationState) => state.isFindingAsset
);

/**
 * Determines if the last find asset process has been ended successfully.
 */
export const getJournalAssociationAssetFindCompleted = createSelector(
  selectJournalAssociationState,
  (state: JournalAssociationState) => state.isFindAssetCompleted
);

/**
 * Determines if there is a running find asset depreciation process.
 */
export const getJournalAssociationAssetDepreciationFinding = createSelector(
  selectJournalAssociationState,
  (state: JournalAssociationState) => state.isFindingAssetDepreciation
);

/**
 * Determines if the last find asset depreciation process has been ended successfully.
 */
export const getJournalAssociationAssetDepreciationFindCompleted = createSelector(
  selectJournalAssociationState,
  (state: JournalAssociationState) => state.isFindAssetDepreciationCompleted
);

/**
 * Determines if there is a running find asset sale invoice process.
 */
export const getJournalAssociationAssetSaleInvoiceFinding = createSelector(
  selectJournalAssociationState,
  (state: JournalAssociationState) => state.isFindingAssetSaleInvoice
);

/**
 * Determines if the last find asset sale invoice process has been ended successfully.
 */
export const getJournalAssociationAssetSaleInvoiceFindCompleted = createSelector(
  selectJournalAssociationState,
  (state: JournalAssociationState) => state.isFindAssetSaleInvoiceCompleted
);

/**
 * Determines if there is a running find bank account log process.
 */
export const getJournalAssociationBankAccountLogFinding = createSelector(
  selectJournalAssociationState,
  (state: JournalAssociationState) => state.isFindingBankAccountLog
);

/**
 * Determines if the last find bank account log sale invoice process has been ended successfully.
 */
export const getJournalAssociationBankAccountLogFindCompleted = createSelector(
  selectJournalAssociationState,
  (state: JournalAssociationState) => state.isFindBankAccountLogCompleted
);

/**
 * Determines if there is a running find customer log process.
 */
export const getJournalAssociationCustomerLogFinding = createSelector(
  selectJournalAssociationState,
  (state: JournalAssociationState) => state.isFindingCustomerLog
);

/**
 * Determines if the last find customer log process has been ended successfully.
 */
export const getJournalAssociationCustomerLogFindCompleted = createSelector(
  selectJournalAssociationState,
  (state: JournalAssociationState) => state.isFindCustomerLogCompleted
);

/**
 * Determines if there is a running find employee loan process.
 */
export const getJournalAssociationEmployeeLoanFinding = createSelector(
  selectJournalAssociationState,
  (state: JournalAssociationState) => state.isFindingEmployeeLoan
);

/**
 * Determines if the last find employee loan process has been ended successfully.
 */
export const getJournalAssociationEmployeeLoanFindCompleted = createSelector(
  selectJournalAssociationState,
  (state: JournalAssociationState) => state.isFindEmployeeLoanCompleted
);

/**
 * Determines if there is a running find excluded asset process.
 */
export const getJournalAssociationExcludedAssetFinding = createSelector(
  selectJournalAssociationState,
  (state: JournalAssociationState) => state.isFindingExcludedAsset
);

/**
 * Determines if the last find excluded asset process has been ended successfully.
 */
export const getJournalAssociationExcludedAssetFindCompleted = createSelector(
  selectJournalAssociationState,
  (state: JournalAssociationState) => state.isFindExcludedAssetCompleted
);

/**
 * Determines if there is a running find incoming stock process.
 */
export const getJournalAssociationIncomingStockFinding = createSelector(
  selectJournalAssociationState,
  (state: JournalAssociationState) => state.isFindingIncomingStock
);

/**
 * Determines if the last find incoming stock process has been ended successfully.
 */
export const getJournalAssociationIncomingStockFindCompleted = createSelector(
  selectJournalAssociationState,
  (state: JournalAssociationState) => state.isFindIncomingStockCompleted
);

/**
 * Determines if there is a running find outgoing stock process.
 */
export const getJournalAssociationOutgoingStockFinding = createSelector(
  selectJournalAssociationState,
  (state: JournalAssociationState) => state.isFindingOutgoingStock
);

/**
 * Determines if the last find outgoing stock process has been ended successfully.
 */
export const getJournalAssociationOutgoingStockFindCompleted = createSelector(
  selectJournalAssociationState,
  (state: JournalAssociationState) => state.isFindOutgoingStockCompleted
);

/**
 * Determines if there is a running find payroll payment process.
 */
export const getJournalAssociationPayrollPaymentFinding = createSelector(
  selectJournalAssociationState,
  (state: JournalAssociationState) => state.isFindingPayrollPayment
);

/**
 * Determines if the last find payroll payment process has been ended successfully.
 */
export const getJournalAssociationPayrollPaymentFindCompleted = createSelector(
  selectJournalAssociationState,
  (state: JournalAssociationState) => state.isFindPayrollPaymentCompleted
);

/**
 * Determines if there is a running find petty cash refund process.
 */
export const getJournalAssociationPettyCashRefundFinding = createSelector(
  selectJournalAssociationState,
  (state: JournalAssociationState) => state.isFindingPettyCashRefund
);

/**
 * Determines if the last find petty cash refund process has been ended successfully.
 */
export const getJournalAssociationPettyCashRefundFindCompleted = createSelector(
  selectJournalAssociationState,
  (state: JournalAssociationState) => state.isFindPettyCashRefundCompleted
);

/**
 * Determines if there is a running find petty cash refund request process.
 */
export const getJournalAssociationPettyCashRefundRequestFinding = createSelector(
  selectJournalAssociationState,
  (state: JournalAssociationState) => state.isFindingPettyCashRefundRequest
);

/**
 * Determines if the last find petty cash refund request process has been ended successfully.
 */
export const getJournalAssociationPettyCashRefundRequestFindCompleted = createSelector(
  selectJournalAssociationState,
  (state: JournalAssociationState) => state.isFindPettyCashRefundRequestCompleted
);

/**
 * Determines if there is a running find purchase invoice process.
 */
export const getJournalAssociationPurchaseInvoiceFinding = createSelector(
  selectJournalAssociationState,
  (state: JournalAssociationState) => state.isFindingPurchaseInvoice
);

/**
 * Determines if the last find purchase invoice  process has been ended successfully.
 */
export const getJournalAssociationPurchaseInvoiceFindCompleted = createSelector(
  selectJournalAssociationState,
  (state: JournalAssociationState) => state.isFindPurchaseInvoiceCompleted
);

/**
 * Determines if there is a running find purchase return process.
 */
export const getJournalAssociationPurchaseReturnFinding = createSelector(
  selectJournalAssociationState,
  (state: JournalAssociationState) => state.isFindingPurchaseReturn
);

/**
 * Determines if the last find purchase return  process has been ended successfully.
 */
export const getJournalAssociationPurchaseReturnFindCompleted = createSelector(
  selectJournalAssociationState,
  (state: JournalAssociationState) => state.isFindPurchaseReturnCompleted
);

/**
 * Determines if there is a running find sale invoice process.
 */
export const getJournalAssociationSaleInvoiceFinding = createSelector(
  selectJournalAssociationState,
  (state: JournalAssociationState) => state.isFindingSaleInvoice
);

/**
 * Determines if the last find sale invoice  process has been ended successfully.
 */
export const getJournalAssociationSaleInvoiceFindCompleted = createSelector(
  selectJournalAssociationState,
  (state: JournalAssociationState) => state.isFindSaleInvoiceCompleted
);

/**
 * Determines if there is a running find sale return process.
 */
export const getJournalAssociationSaleReturnFinding = createSelector(
  selectJournalAssociationState,
  (state: JournalAssociationState) => state.isFindingSaleReturn
);

/**
 * Determines if the last find sale return  process has been ended successfully.
 */
export const getJournalAssociationSaleReturnFindCompleted = createSelector(
  selectJournalAssociationState,
  (state: JournalAssociationState) => state.isFindSaleReturnCompleted
);

/**
 * Determines if there is a running find transfer stock process.
 */
export const getJournalAssociationTransferStockFinding = createSelector(
  selectJournalAssociationState,
  (state: JournalAssociationState) => state.isFindingTransferStock
);

/**
 * Determines if the last find transfer stock  process has been ended successfully.
 */
export const getJournalAssociationTransferStockFindCompleted = createSelector(
  selectJournalAssociationState,
  (state: JournalAssociationState) => state.isFindTransferStockCompleted
);
