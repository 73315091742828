import { Action } from '@ngrx/store';

import { GosiRegType } from 'lookups/models';
import { AppHttpResponse } from 'shared';

/**
 * The Lookups-Module store gosi reg type action types.
 */
export enum GosiRegTypeActionType {
  GET_ALL_GOSI_REG_TYPES = '[Lookups] [GosiRegType] Get All Gosi Reg Types',
  GET_ALL_GOSI_REG_TYPES_FAIL = '[Lookups] [GosiRegType] Get All Gosi Reg Types Fail',
  GET_ALL_GOSI_REG_TYPES_SUCCESS = '[Lookups] [GosiRegType] Get All Gosi Reg Types Success',
}

/**
 * Represents a store gosi reg type action.
 */
export class GetAllGosiRegTypes implements Action {
  /**
   * The type of the action.
   */
  readonly type = GosiRegTypeActionType.GET_ALL_GOSI_REG_TYPES;

  /**
   * Fires a new gosi reg type action.
   */
  constructor() {}
}

/**
 * Represents a store gosi reg type fail action.
 */
export class GetAllGosiRegTypesFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = GosiRegTypeActionType.GET_ALL_GOSI_REG_TYPES_FAIL;

  /**
   * Fires a new gosi reg type fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store gosi reg type success action.
 */
export class GetAllGosiRegTypesSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = GosiRegTypeActionType.GET_ALL_GOSI_REG_TYPES_SUCCESS;

  /**
   * Fires a gosi reg type success action.
   * @param payload An object contains the list of gosi reg types.
   */
  constructor(public payload: AppHttpResponse<GosiRegType[]>) {}
}

/**
 * Lookups-module gosi reg type action types.
 */
export type GosiRegTypeActions = GetAllGosiRegTypes | GetAllGosiRegTypesFail | GetAllGosiRegTypesSuccess;
