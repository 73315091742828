import { CreateVariantComponent } from 'stores/components/variants/create-variant/create-variant.component';
import { UpdateVariantComponent } from 'stores/components/variants/update-variant/update-variant.component';
import { VariantsListComponent } from 'stores/components/variants/variants-list/variants-list.component';
import { VariantsSearchComponent } from 'stores/components/variants/variants-search/variants-search.component';

export * from 'stores/components/variants/create-variant/create-variant.component';
export * from 'stores/components/variants/update-variant/update-variant.component';
export * from 'stores/components/variants/variants-list/variants-list.component';
export * from 'stores/components/variants/variants-search/variants-search.component';

/**
 * The set of components registered on this sub-module.
 */
export const COMPONENTS: any[] = [
  CreateVariantComponent,
  UpdateVariantComponent,
  VariantsListComponent,
  VariantsSearchComponent,
];
