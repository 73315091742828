import { QuotationStatusActionType, QuotationStatusActions } from 'lookups/store/actions';
import { QuotationStatusState } from 'lookups/store/states';

/**
 * The initial state from which the state starts.
 */
const initialState: QuotationStatusState = {
  data: [],
  error: null,
  isLoaded: false,
  isLoading: false,
};

/**
 * The reducer function that is called in each action dispatch against the state.
 * @param state The current state.
 * @param action The action that will affect the state.
 */
export function quotationStatusReducer(
  state: QuotationStatusState = initialState,
  action: QuotationStatusActions
): QuotationStatusState {
  switch (action.type) {
    case QuotationStatusActionType.GET_ALL_QUOTATION_STATUSES: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case QuotationStatusActionType.GET_ALL_QUOTATION_STATUSES_FAIL: {
      return {
        ...state,
        error: action.payload,
        isLoading: false,
        isLoaded: false,
      };
    }

    case QuotationStatusActionType.GET_ALL_QUOTATION_STATUSES_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        isLoading: false,
        isLoaded: true,
      };
    }

    default:
      return state;
  }
}
