import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import { Observable, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { PageInfo, NotificationService, NotificationMessage, TranslationService } from 'shared';
import * as fromStoresStore from 'stores/store';
import { Sector } from 'stores/models';

@Component({
  selector: 'app-update-sector',
  templateUrl: './update-sector.component.html',
  styles: [],
})
export class UpdateSectorComponent implements OnInit, OnDestroy {
  /**
   * The confirm modal template reference.
   */
  @ViewChild('confirmModalRef') confirmModalRef: ElementRef<any>;

  /**
   * Gets or sets the information about the current page.
   */
  pageInfo: PageInfo = {
    title: 'STORES.SECTORS.UPDATE_SECTOR_DATA_TITLE',
    icon: 'fa fa-edit',
  };

  /**
   * Gets or sets the id of the current edited sector.
   */
  sectorId: number;

  /**
   * The current edited sector.
   */
  sector$: Observable<Sector>;

  /**
   * Indicates whether there is a update sector process is running or not.
   */
  isUpdating$: Observable<boolean>;

  /**
   * The name of the current sector.
   */
  sectorName: string;

  /**
   * The update sector form.
   */
  form: FormGroup;

  /**
   * The set of subscriptions on this components,
   * these subscriptions must be unsubscribed before this component got destroyed.
   */
  subscriptions = new Subscription();

  /**
   * Gets or sets the current selected language.
   */
  get currentLang() {
    return this.translationService.language;
  }

  /**
   * @param route The activated route.
   * @param modalService The modal service.
   * @param notificationService The notification service.
   * @param storesStore$  The stores store.
   * @param translationService The translation service.
   */
  constructor(
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private notificationService: NotificationService,
    private storesStore$: Store<fromStoresStore.StoresState>,
    private translationService: TranslationService
  ) {}

  ngOnInit(): void {
    this.init();
  }

  /**
   * Destroy component data
   */
  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  /**
   * Initialize component data.
   */
  init() {
    this.initForm();

    /** Subscribe to route to get the element id whenever it changed. */
    const routeSubscription = this.route.paramMap
      .pipe(
        tap((params) => {
          this.sectorId = +params.get('sectorId');
          this.storesStore$.dispatch(new fromStoresStore.FindSector(this.sectorId));
        })
      )
      .subscribe();
    this.subscriptions.add(routeSubscription);

    /* Load the selected sector. */
    this.sector$ = this.storesStore$.pipe(
      select(fromStoresStore.getSelectedSector),
      tap((sector) => {
        if (sector) {
          this.form.setValue({
            name: sector.name,
            nameEn: sector.nameEn,
          });

          this.sectorName = this.currentLang === 'en' ? sector.nameEn : sector.name;

          /* Update page info. */
          this.pageInfo = {
            ...this.pageInfo,
            subTitle: `(${this.sectorName})`,
          };

          this.form.markAsPristine();
        }
      })
    );

    /**
     * Load data.
     */
    this.isUpdating$ = this.storesStore$.pipe(select(fromStoresStore.getSelectedSectorUpdating));
  }

  /**
   * Initialize form and add validators.
   */
  initForm() {
    this.form = new FormGroup({
      name: new FormControl('', [Validators.required]),
      nameEn: new FormControl('', [Validators.required]),
    });
  }

  /**
   * Submits the form.
   */
  submit() {
    if (this.form.invalid) {
      const errorMessage = new NotificationMessage();

      if (this.form.get('name').invalid) {
        errorMessage.title = this.translationService.translate(
          'STORES.SECTORS.SECTOR_DATA_VALIDATION.NAME_ARABIC_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate('STORES.SECTORS.SECTOR_DATA_VALIDATION.NAME_IS_REQUIRED'),
        ];
      } else if (this.form.get('nameEn').invalid) {
        errorMessage.title = this.translationService.translate(
          'STORES.SECTORS.SECTOR_DATA_VALIDATION.NAME_ENGLISH_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate('STORES.SECTORS.SECTOR_DATA_VALIDATION.NAME_IS_REQUIRED'),
        ];
      }
      this.form.markAllAsTouched();
      return this.notificationService.warningWithTitle(errorMessage);
    } else {
      this.openModal(this.confirmModalRef);
    }
  }

  /**
   * Confirms the form submit.
   */
  confirm() {
    this.storesStore$.dispatch(
      new fromStoresStore.UpdateSector({
        sectorId: this.sectorId,
        ...this.form.value,
      })
    );
  }

  /**
   * Opens the modal of the given templateRef.
   * @param modalRef The modal templateRef to be opened.
   */
  openModal(modalRef) {
    this.modalService.open(modalRef);
  }
}
