import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';

import { PageInfo } from 'shared';

/**
 * The finance configurations pages.
 */
enum PAGES {
  'employees-provision' = 'employees-provision',
  'governmental-services' = 'governmental-services',
}

@Component({
  selector: 'app-finance-configurations',
  templateUrl: './finance-configurations.component.html',
  styles: [],
})
export class FinanceConfigurationsComponent implements OnInit {
  /**
   * Gets or sets the information about the current page.
   */
  pageInfo: PageInfo = {
    title: 'FINANCES.FINANCE_CONFIGURATIONS.FINANCE_CONFIGURATIONS',
    icon: 'fa fa-cogs',
  };

  /**
   * Gets or sets the selected page.
   * @default 'employees-provision'
   */
  activePage: PAGES = PAGES['employees-provision'];

  /**
   * @param route The activated route.
   * @param locationService The location service.
   */
  constructor(private route: ActivatedRoute, private locationService: Location) {}

  ngOnInit(): void {
    this.init();
  }

  /**
   * Initialize component data.
   */
  init() {
    /**
     * Load data.
     */
    /** Select the user desired page. */
    this.activePage = PAGES[this.route.snapshot.fragment] ?? this.activePage;
    this.selectedPageChanged(this.activePage);
  }

  /**
   * Updates the browser url according to the selected page.
   * @param page The newly selected page.
   */
  selectedPageChanged(page: PAGES) {
    this.locationService.replaceState(`${this.locationService.path()}#${PAGES[page]}`);
  }
}
