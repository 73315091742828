import { createSelector } from '@ngrx/store';

import { selectLookupsState } from 'lookups/store/selectors/feature.selectors';
import { LookupsState, AnnualLeaveState } from 'lookups/store/states';

/**
 * Gets the annual leave state.
 */
const selectAnnualLeaves = createSelector(selectLookupsState, (state: LookupsState) => state.annualLeaves);

/**
 * Gets the list of loaded annual leaves.
 */
export const getAnnualLeaves = createSelector(selectAnnualLeaves, (state: AnnualLeaveState) => state.data);

/**
 * Gets the error that occurred in the state.
 */
export const getAnnualLeavesError = createSelector(selectAnnualLeaves, (state: AnnualLeaveState) => state.error);

/**
 * Determines if there is a running load process.
 */
export const getAnnualLeavesIsLoading = createSelector(
  selectAnnualLeaves,
  (state: AnnualLeaveState) => state.isLoading
);

/**
 * Determines if the last load process has been ended successfully.
 */
export const getAnnualLeavesIsLoaded = createSelector(selectAnnualLeaves, (state: AnnualLeaveState) => state.isLoaded);
