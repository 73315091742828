<ng-template #modalRef let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title text-primary" id="modal-basic-title">
      {{ 'AUTH.CONFIRM_UPDATE_USER_PREFERENCES_TITLE' | translate }}
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="d(); closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <!-- create user preferences form starts -->
    <div class="row">
      <div class="col-md-12">
        <form>
          <div class="row">
            <div class="col-md-12">
              <ul class="nav-tabs" ngbNav #nav="ngbNav" [(activeId)]="activePage" [destroyOnHide]="false">
                <ng-container *ngIf="(currentUser$ | async)?.organizationId">
                  <li ngbNavItem="user-locations">
                    <a ngbNavLink>
                      <i class="icon-location-pin" [class.success]="activePage == 'user-locations'"></i>
                      {{ 'AUTH.USER_LOCATIONS_PAGE_TITLE' | translate }}
                    </a>

                    <!-- update user locations form starts -->
                    <ng-template ngbNavContent>
                      <div class="row">
                        <div class="col-md-12">
                          <div class="card-content">
                            <div class="px-3">
                              <app-user-locations-list></app-user-locations-list>
                            </div>
                          </div>
                        </div>
                      </div>
                    </ng-template>
                    <!-- update user location form ends -->
                  </li>
                  <li ngbNavItem="user-cost-centers">
                    <a ngbNavLink>
                      <i class="icon-vector" [class.success]="activePage == 'user-cost-centers'"></i>
                      {{ 'AUTH.USER_COST_CENTERS_PAGE_TITLE' | translate }}
                    </a>

                    <!-- update user cost centers form starts -->
                    <ng-template ngbNavContent>
                      <div class="row">
                        <div class="col-md-12">
                          <div class="card-content">
                            <div class="px-3">
                              <app-user-cost-centers-list></app-user-cost-centers-list>
                            </div>
                          </div>
                        </div>
                      </div>
                    </ng-template>

                    <!-- update user cost centers form ends -->
                  </li>
                  <li ngbNavItem="user-cash-accounts">
                    <a ngbNavLink>
                      <i class="fa fa-money" [class.success]="activePage == 'user-cash-accounts'"></i>
                      {{ 'AUTH.USER_CASH_ACCOUNTS_PAGE_TITLE' | translate }}
                    </a>

                    <!-- update user cash accounts form starts -->
                    <ng-template ngbNavContent>
                      <div class="row">
                        <div class="col-md-12">
                          <div class="card-content">
                            <div class="px-3">
                              <app-user-cash-accounts-list></app-user-cash-accounts-list>
                            </div>
                          </div>
                        </div>
                      </div>
                    </ng-template>

                    <!-- update user cash accounts form ends -->
                  </li>
                  <li ngbNavItem="user-electronic-accounts">
                    <a ngbNavLink>
                      <i class="fa fa-cc-visa" [class.success]="activePage == 'user-electronic-accounts'"></i>
                      {{ 'AUTH.USER_ELECTRONIC_ACCOUNTS_PAGE_TITLE' | translate }}
                    </a>

                    <!-- update user electronic accounts form starts -->
                    <ng-template ngbNavContent>
                      <div class="row">
                        <div class="col-md-12">
                          <div class="card-content">
                            <div class="px-3">
                              <app-user-electronic-accounts-list></app-user-electronic-accounts-list>
                            </div>
                          </div>
                        </div>
                      </div>
                    </ng-template>

                    <!-- update user electronic accounts form ends -->
                  </li>
                  <li ngbNavItem="user-POS-devices">
                    <a ngbNavLink>
                      <i class="fa fa-desktop" [class.success]="activePage == 'user-POS-devices'"></i>
                      {{ 'AUTH.USER_POS_DEVICES_PAGE_TITLE' | translate }}
                    </a>

                    <!-- update user pos devices form starts -->
                    <ng-template ngbNavContent>
                      <div class="row">
                        <div class="col-md-12">
                          <div class="card-content">
                            <div class="px-3">
                              <app-user-pos-devices-list></app-user-pos-devices-list>
                            </div>
                          </div>
                        </div>
                      </div>
                    </ng-template>

                    <!-- update user pos devices form ends -->
                  </li>
                </ng-container>
              </ul>
              <div [ngbNavOutlet]="nav" class="mt-2"></div>
            </div>
          </div>
        </form>
      </div>
    </div>
    <!-- create user form ends -->
  </div>
</ng-template>
