<!-- project active plan starts -->
<div class="row">
  <div class="col-md-12">
    <div class="card m-0">
      <div class="card-content">
        <div class="px-3">
          <div class="row">
            <div class="col-md-12" *ngIf="projectObj; else noItems; let project">
              <ul class="projectPlanList" *ngIf="project.id">
                <li>
                  <i class="px-2 fa fa-window-minimize"></i>
                  {{ 'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.PROJECT_PLAN' | translate }}
                  <span class="font-small-1"> ({{ project.projectMainTasks.length }})</span>
                  <a
                    class="add btn btn-raised btn-primary btn-sm align-self-end round mx-2 mb-0"
                    (click)="openModal(createProjectMainTaskModalRef)"
                  >
                    <i class="fa fa-plus"></i>
                    <span class="mx-1">{{ 'SHARED.CONFIRM.ADD' | translate }}</span>
                  </a>
                  <ul>
                    <li *ngFor="let mainTask of project.projectMainTasks; let indexMain = index" [value]="mainTask.id">
                      <a
                        (click)="isMainCollapsed[indexMain] = !isMainCollapsed[indexMain]"
                        [attr.aria-expanded]="!isMainCollapsed[indexMain]"
                        aria-controls="collapseButton"
                      >
                        <i
                          class="px-2"
                          [ngClass]="isMainCollapsed[indexMain] ? 'fa fa-plus' : 'fa fa-window-minimize'"
                        ></i>
                      </a>
                      <a (click)="selectedProjectMainTask = mainTask; openModal(updateProjectMainTaskModalRef)">
                        <span>({{ indexMain + 1 }}) </span>
                        <span>{{ mainTask.name | localize: mainTask.nameEn | async }} </span>
                        <span class="font-small-1"> ({{ mainTask.projectSubTasks.length }})</span>
                      </a>
                      <a
                        class="add btn btn-raised btn-primary btn-sm align-self-end round mx-2 mb-0"
                        (click)="selectedProjectMainTask = mainTask; openModal(createProjectSubTaskModalRef)"
                      >
                        <i class="fa fa-plus"></i>
                        <span class="mx-1">{{ 'SHARED.CONFIRM.ADD' | translate }}</span>
                      </a>
                      <ul #collapse="ngbCollapse" [(ngbCollapse)]="isMainCollapsed[indexMain]">
                        <li *ngFor="let subTask of mainTask.projectSubTasks; let index = index" [value]="subTask.id">
                          <a (click)="selectedProjectSubTask = subTask; openModal(updateProjectSubTaskModalRef)">
                            <span>({{ indexMain + 1 }}-{{ index + 1 }}) </span>
                            <span>{{ subTask.name | localize: subTask.nameEn | async }} </span>
                          </a>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- project active plan list ends -->

<!-- no items template starts -->
<ng-template #noItems>
  <div class="col-md-12 alert alert-warning mt-3 text-center" role="alert">
    {{ 'PROJECTS_MANAGEMENT.PROJECTS.SEARCH_PROJECT_PLAN_WITHOUT_ADD_NOT_FOUND' | translate }}
  </div>
</ng-template>
<!-- no items template ends -->

<!-- create project main task modal starts -->
<ng-template #createProjectMainTaskModalRef let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
      {{ 'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.ADD_MAIN_TASK' | translate }}
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="d()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-md-12">
        <app-project-main-task
          [projectMainTaskForm]="projectMainTaskForm"
          [projectMainTask]="null"
        ></app-project-main-task>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="modal-actions">
      <button
        [disabled]="isProjectMainTaskCreating$ | async"
        (click)="submitCreateProjectMainTask()"
        type="button"
        class="btn btn-raised btn-success round"
        ngbAutofocus
      >
        <i class="fa fa-check"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.SAVE' | translate }}</span>
      </button>
      <button type="button" class="btn btn-outline-dark round" (click)="c()">
        <i class="fa fa-times"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.CANCEL' | translate }}</span>
      </button>
    </div>
  </div>
</ng-template>
<!-- create project main task modal ends -->

<!-- update project main task modal starts -->
<ng-template #updateProjectMainTaskModalRef let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
      {{ 'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.UPDATE_MAIN_TASK' | translate }}
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="d()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-md-12">
        <app-project-main-task
          [isUpdated]="true"
          [projectMainTaskForm]="projectMainTaskForm"
          [projectMainTask]="selectedProjectMainTask"
        ></app-project-main-task>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="modal-actions">
      <button
        [disabled]="(isProjectMainTaskDeleting$ | async) || (isProjectMainTaskUpdating$ | async)"
        (click)="submitUpdateProjectMainTask()"
        type="button"
        class="btn btn-raised btn-success round"
        ngbAutofocus
      >
        <i class="fa fa-check"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.SAVE' | translate }}</span>
      </button>
      <button
        [disabled]="(isProjectMainTaskDeleting$ | async) || (isProjectMainTaskUpdating$ | async)"
        (click)="deleteProjectMainTask(selectedProjectMainTask.id)"
        type="button"
        class="btn btn-raised btn-danger round"
        ngbAutofocus
      >
        <i class="fa fa-check"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.DELETE' | translate }}</span>
      </button>
      <button type="button" class="btn btn-outline-dark round" (click)="c()">
        <i class="fa fa-times"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.CANCEL' | translate }}</span>
      </button>
    </div>
  </div>
</ng-template>
<!-- update project main task modal ends -->

<!-- create project sub task modal starts -->
<ng-template #createProjectSubTaskModalRef let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
      {{ 'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.ADD_SUB_TASK' | translate }}
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="d()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-md-12">
        <app-project-sub-task [projectSubTaskForm]="projectSubTaskForm" [projectSubTask]="null"></app-project-sub-task>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="modal-actions">
      <button
        [disabled]="isProjectSubTaskCreating$ | async"
        (click)="submitCreateProjectSubTask()"
        type="button"
        class="btn btn-raised btn-success round"
        ngbAutofocus
      >
        <i class="fa fa-check"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.SAVE' | translate }}</span>
      </button>
      <button type="button" class="btn btn-outline-dark round" (click)="c()">
        <i class="fa fa-times"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.CANCEL' | translate }}</span>
      </button>
    </div>
  </div>
</ng-template>
<!-- create project sub task modal ends -->

<!-- update project sub task modal starts -->
<ng-template #updateProjectSubTaskModalRef let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
      {{ 'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.UPDATE_SUB_TASK' | translate }}
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="d()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-md-12">
        <app-project-sub-task
          [isUpdated]="true"
          [projectSubTaskForm]="projectSubTaskForm"
          [projectSubTask]="selectedProjectSubTask"
        ></app-project-sub-task>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="modal-actions">
      <button
        [disabled]="(isProjectSubTaskDeleting$ | async) || (isProjectSubTaskUpdating$ | async)"
        (click)="submitUpdateProjectSubTask()"
        type="button"
        class="btn btn-raised btn-success round"
        ngbAutofocus
      >
        <i class="fa fa-check"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.SAVE' | translate }}</span>
      </button>
      <button
        type="button"
        class="btn btn-raised btn-danger round"
        [disabled]="(isProjectSubTaskDeleting$ | async) || (isProjectSubTaskUpdating$ | async)"
        (click)="deleteProjectSubTask(selectedProjectSubTask.id)"
        ngbAutofocus
      >
        <i class="fa fa-check"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.DELETE' | translate }}</span>
      </button>
      <button type="button" class="btn btn-outline-dark round" (click)="c()">
        <i class="fa fa-times"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.CANCEL' | translate }}</span>
      </button>
    </div>
  </div>
</ng-template>
<!-- update project sub task modal ends -->
