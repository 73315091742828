import { Component, OnInit, ViewChild, ElementRef, OnDestroy, Input } from '@angular/core';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { skip, tap } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';

import * as fromSecurityStore from 'security/store';
import { NotificationMessage, NotificationService, TranslationService } from 'shared';
import { User } from 'security/models';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Claims } from 'security/models';

@Component({
  selector: 'app-reset-user-credentials',
  templateUrl: './reset-user-credentials.component.html',
  styles: [],
})
export class ResetUserCredentialsComponent implements OnInit, OnDestroy {
  /**
   * The credentials confirm modal template reference.
   */
  @ViewChild('credentialsConfirmModalRef') credentialsConfirmModalRef: ElementRef<any>;

  /**
   * The modal template reference.
   */
  @ViewChild('modalRef') modalRef: ElementRef<any>;

  /**
   * Gets or sets the id of the current edited user.
   */
  @Input() userId: number;

  /**
   * The system supported user claims.
   */
  Claims = Claims;

  /**
   * The current edited user.
   */
  user$: Observable<User>;

  /**
   * Indicates whether there is a create customer process is running or not.
   */
  isUpdating$: Observable<boolean>;

  /**
   * The update user credentials form.
   */
  credentialsForm: FormGroup;

  /**
   * The update user credentials form.
   */
  currentLoggedInUserPasswordForm: FormGroup;

  /**
   * The set of subscriptions on this components,
   * these subscriptions must be unsubscribed before this component got destroyed.
   */
  subscriptions = new Subscription();

  /**
   * @param modalService The modal service.
   * @param notificationService The notification service.
   * @param translationService The translation service.
   * @param securityStore$ The Security-module store.
   */
  constructor(
    private modalService: NgbModal,
    private notificationService: NotificationService,
    private translationService: TranslationService,
    private securityStore$: Store<fromSecurityStore.SecurityState>
  ) {}

  ngOnInit(): void {
    this.init();
  }

  /**
   * Unsubscribes for component data.
   */
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  /**
   * Initialize component data.
   */
  init() {
    this.initForm();

    /* Load the selected user. */
    this.user$ = this.securityStore$.pipe(select(fromSecurityStore.getSelectedUser));

    this.isUpdating$ = this.securityStore$.pipe(select(fromSecurityStore.getSelectedUserUpdating));

    /**
     * rest form on creation complete
     */
    this.subscriptions.add(
      this.securityStore$
        .pipe(
          select(fromSecurityStore.getSelectedUserUpdateCompleted),
          skip(1),
          tap((updated) => {
            if (updated) {
              this.initForm();
              this.closeModal();
              this.currentLoggedInUserPasswordForm.markAsUntouched();
            }
          })
        )
        .subscribe()
    );
  }

  initForm() {
    this.credentialsForm = new FormGroup({
      newUsername: new FormControl('', [Validators.required, Validators.minLength(4), Validators.maxLength(50)]),
      newPassword: new FormControl('', [Validators.required, Validators.minLength(8), Validators.maxLength(50)]),
    });
    this.currentLoggedInUserPasswordForm = new FormGroup({
      currentLoggedInUserPassword: new FormControl('', [
        Validators.required,
        Validators.minLength(8),
        Validators.maxLength(50),
      ]),
    });
  }
  /**
   * Submits the credentials form.
   */
  submitCredentials() {
    if (this.credentialsForm.invalid) {
      const errorMessage = new NotificationMessage();

      if (this.credentialsForm.get('newUsername').invalid) {
        errorMessage.title = this.translationService.translate('SECURITY.USERS.USER_DATA_VALIDATION.USERNAME_ERROR');
        errorMessage.body = [
          this.translationService.translate('SECURITY.USERS.USER_DATA_VALIDATION.USERNAME_IS_REQUIRED'),
          this.translationService.translate('SECURITY.USERS.USER_DATA_VALIDATION.USERNAME_LENGTH_ERROR'),
        ];
      } else if (this.credentialsForm.get('newPassword').invalid) {
        errorMessage.title = this.translationService.translate(
          'SECURITY.USERS.USER_DATA_VALIDATION.NEW_PASSWORD_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate('SECURITY.USERS.USER_DATA_VALIDATION.NEW_PASSWORD_IS_REQUIRED'),
          this.translationService.translate('SECURITY.USERS.USER_DATA_VALIDATION.NEW_PASSWORD_LENGTH_ERROR'),
        ];
      }

      this.credentialsForm.markAllAsTouched();
      return this.notificationService.warningWithTitle(errorMessage);
    } else {
      this.openModal(this.credentialsConfirmModalRef);
    }
  }

  /**
   * Confirms the the credentials form submit.
   */
  confirmCredentials() {
    if (this.currentLoggedInUserPasswordForm.invalid) {
      const errorMessage = new NotificationMessage();

      if (this.currentLoggedInUserPasswordForm.get('currentLoggedInUserPassword').invalid) {
        errorMessage.title = this.translationService.translate(
          'SECURITY.USERS.USER_DATA_VALIDATION.LOGGED_USER_PASSWORD_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate('SECURITY.USERS.USER_DATA_VALIDATION.LOGGED_USER_PASSWORD_IS_REQUIRED'),
          this.translationService.translate('SECURITY.USERS.USER_DATA_VALIDATION.LOGGED_USER_PASSWORD_LENGTH_ERROR'),
        ];
      }

      return this.notificationService.warningWithTitle(errorMessage);
    } else {
      this.securityStore$.dispatch(
        new fromSecurityStore.ResetUsersCredentials({
          id: this.userId,
          ...this.credentialsForm.value,
          ...this.currentLoggedInUserPasswordForm.value,
        })
      );
    }
  }

  /**
   * Opens the modal of the given templateRef.
   * @param modalRef The modal templateRef to be opened.
   */
  openModal(modalRef) {
    this.modalService.open(modalRef);
  }

  /**
   * Closes the currently opened modal.
   */
  closeModal() {
    this.modalService.dismissAll();
  }
}
