import { Injectable } from '@angular/core';

import { of } from 'rxjs';
import { switchMap, map, catchError, tap, debounceTime } from 'rxjs/operators';
import { Effect, ofType, Actions } from '@ngrx/effects';

import { NotificationService, StatusCode, PAGINATION, TranslationService } from 'shared';
import { AccountsService } from 'finances/services';
import {
  AccountsActionType,
  CreateAccount,
  CreateAccountFail,
  CreateAccountSuccess,
  CreateSecondaryAccount,
  CreateSecondaryAccountFail,
  CreateSecondaryAccountSuccess,
  DeleteAccount,
  DeleteAccountFail,
  DeleteAccountSuccess,
  FindAccount,
  FindAccountFail,
  FindAccountSuccess,
  SearchAccounts,
  SearchAccountsFail,
  SearchAccountsSuccess,
  SearchRegularAccounts,
  UpdateAccount,
  UpdateAccountFail,
  UpdateAccountSuccess,
  UpdateSecondaryAccount,
  UpdateSecondaryAccountFail,
  UpdateSecondaryAccountSuccess,
} from 'finances/store/actions';

@Injectable()
export class AccountsEffects {
  constructor(
    private actions$: Actions,
    private accountsService: AccountsService,
    private notificationService: NotificationService,
    private translationService: TranslationService
  ) {}

  /* ========================= SEARCH_ACCOUNTS =================================== */
  @Effect()
  search$ = this.actions$.pipe(
    ofType(AccountsActionType.SEARCH_ACCOUNTS),
    debounceTime(300),
    switchMap(({ payload }: SearchAccounts) =>
      this.accountsService.search(payload?.description ?? '', payload.page, PAGINATION.Accounts).pipe(
        map((response) => new SearchAccountsSuccess(response)),
        catchError((error) => of(new SearchAccountsFail(error)))
      )
    )
  );

  /* ========================= SEARCH_REGULAR_ACCOUNTS =================================== */
  @Effect()
  nonParentAccountsSearch$ = this.actions$.pipe(
    ofType(AccountsActionType.SEARCH_REGULAR_ACCOUNTS),
    debounceTime(300),
    switchMap(({ payload }: SearchRegularAccounts) =>
      this.accountsService
        .searchRegularAccounts(
          payload?.description ?? '',
          payload?.primaryAccountKey ?? '',
          payload.page,
          PAGINATION.Accounts
        )
        .pipe(
          map((response) => new SearchAccountsSuccess(response)),
          catchError((error) => of(new SearchAccountsFail(error)))
        )
    )
  );

  /* ========================= FIND_ACCOUNT =================================== */
  @Effect()
  find$ = this.actions$.pipe(
    ofType(AccountsActionType.FIND_ACCOUNT),
    switchMap((action: FindAccount) =>
      this.accountsService.findById(action.payload).pipe(
        map((response) => new FindAccountSuccess(response)),
        catchError((error) => of(new FindAccountFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  findFail$ = this.actions$.pipe(
    ofType(AccountsActionType.FIND_ACCOUNT_FAIL),
    tap((action: FindAccountFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(this.translationService.translate('FINANCES.ACCOUNTS.ACCOUNT_NOT_FOUND'));
      }
    })
  );

  /* ========================= CREATE_ACCOUNT =================================== */
  @Effect()
  create$ = this.actions$.pipe(
    ofType(AccountsActionType.CREATE_ACCOUNT),
    switchMap((action: CreateAccount) =>
      this.accountsService.create(action.payload).pipe(
        map((response) => new CreateAccountSuccess(response)),
        catchError((error) => of(new CreateAccountFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  createSuccess$ = this.actions$.pipe(
    ofType(AccountsActionType.CREATE_ACCOUNT_SUCCESS),
    tap((action: CreateAccountSuccess) => {
      this.notificationService.success(this.translationService.translate('FINANCES.ACCOUNTS.ACCOUNT_ADDED'));
    })
  );

  @Effect({ dispatch: false })
  createFail$ = this.actions$.pipe(
    ofType(AccountsActionType.CREATE_ACCOUNT_FAIL),
    tap((action: CreateAccountFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );

  /* ========================= CREATE_SECONDARY_ACCOUNT =================================== */
  @Effect()
  createSecondaryAccount$ = this.actions$.pipe(
    ofType(AccountsActionType.CREATE_SECONDARY_ACCOUNT),
    switchMap((action: CreateSecondaryAccount) =>
      this.accountsService.createSecondaryAccount(action.payload).pipe(
        map((response) => new CreateSecondaryAccountSuccess(response)),
        catchError((error) => of(new CreateSecondaryAccountFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  createSecondaryAccountSuccess$ = this.actions$.pipe(
    ofType(AccountsActionType.CREATE_SECONDARY_ACCOUNT_SUCCESS),
    tap((action: CreateSecondaryAccountSuccess) => {
      this.notificationService.success(this.translationService.translate('FINANCES.ACCOUNTS.SECONDARY_ACCOUNT_ADDED'));
    })
  );

  @Effect({ dispatch: false })
  createSecondaryAccountFail$ = this.actions$.pipe(
    ofType(AccountsActionType.CREATE_SECONDARY_ACCOUNT_FAIL),
    tap((action: CreateSecondaryAccountFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );

  /* ========================= UPDATE_ACCOUNT =================================== */
  @Effect()
  update$ = this.actions$.pipe(
    ofType(AccountsActionType.UPDATE_ACCOUNT),
    switchMap((action: UpdateAccount) =>
      this.accountsService.update(action.payload).pipe(
        map((response) => new UpdateAccountSuccess(response)),
        catchError((error) => of(new UpdateAccountFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  updateSuccess$ = this.actions$.pipe(
    ofType(AccountsActionType.UPDATE_ACCOUNT_SUCCESS),
    tap((action: UpdateAccountSuccess) => {
      this.notificationService.success(this.translationService.translate('FINANCES.ACCOUNTS.ACCOUNT_UPDATED'));
    })
  );

  @Effect({ dispatch: false })
  updateFail$ = this.actions$.pipe(
    ofType(AccountsActionType.UPDATE_ACCOUNT_FAIL),
    tap((action: UpdateAccountFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(this.translationService.translate('FINANCES.ACCOUNTS.ACCOUNT_NOT_FOUND'));
      } else if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );

  /* ========================= UPDATE_SECONDARY_ACCOUNT =================================== */
  @Effect()
  updateSecondaryAccount$ = this.actions$.pipe(
    ofType(AccountsActionType.UPDATE_SECONDARY_ACCOUNT),
    switchMap((action: UpdateSecondaryAccount) =>
      this.accountsService.updateSecondaryAccount(action.payload).pipe(
        map((response) => new UpdateSecondaryAccountSuccess(response)),
        catchError((error) => of(new UpdateSecondaryAccountFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  updateSecondaryAccountSuccess$ = this.actions$.pipe(
    ofType(AccountsActionType.UPDATE_SECONDARY_ACCOUNT_SUCCESS),
    tap((action: UpdateSecondaryAccountSuccess) => {
      this.notificationService.success(
        this.translationService.translate('FINANCES.ACCOUNTS.SECONDARY_ACCOUNT_UPDATED')
      );
    })
  );

  @Effect({ dispatch: false })
  updateSecondaryAccountFail$ = this.actions$.pipe(
    ofType(AccountsActionType.UPDATE_SECONDARY_ACCOUNT_FAIL),
    tap((action: UpdateSecondaryAccountFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate('FINANCES.ACCOUNTS.SECONDARY_ACCOUNT_NOT_FOUND')
        );
      } else if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );

  /* ========================= DELETE_ACCOUNT =================================== */
  @Effect()
  delete$ = this.actions$.pipe(
    ofType(AccountsActionType.DELETE_ACCOUNT),
    switchMap((action: DeleteAccount) =>
      this.accountsService.delete(action.payload).pipe(
        map((response) => new DeleteAccountSuccess(response)),
        catchError((error) => of(new DeleteAccountFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  deleteSuccess$ = this.actions$.pipe(
    ofType(AccountsActionType.DELETE_ACCOUNT_SUCCESS),
    tap((action: DeleteAccountSuccess) => {
      this.notificationService.success(this.translationService.translate('FINANCES.ACCOUNTS.ACCOUNT_DELETED'));
    })
  );

  @Effect({ dispatch: false })
  deleteFail$ = this.actions$.pipe(
    ofType(AccountsActionType.DELETE_ACCOUNT_FAIL),
    tap((action: DeleteAccountFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(this.translationService.translate('FINANCES.ACCOUNTS.ACCOUNT_NOT_FOUND'));
      } else if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );
}
