import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';

import { Observable } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store, select } from '@ngrx/store';
import { tap } from 'rxjs/operators';

import { NotificationService, PageInfo, PaginationInfo, TranslationService } from 'shared';
import * as fromFinancesStore from 'finances/store';
import { Claims } from 'security/models';
import { FinancialPeriod } from 'finances/models';

@Component({
  selector: 'app-financial-periods-list',
  templateUrl: './financial-periods-list.component.html',
  styles: [],
})
export class FinancialPeriodsListComponent implements OnInit {
  /**
   * The close modal template reference.
   */
  @ViewChild('closeModalRef') closeModalRef: ElementRef<any>;

  /**
   * The create modal template reference.
   */
  @ViewChild('createModalRef') createModalRef: ElementRef<any>;

  /**
   * Gets or sets the information about the current page.
   */
  pageInfo: PageInfo = {
    title: 'FINANCES.FINANCIAL_PERIODS.FINANCIAL_PERIODS_TITLE',
    icon: 'fa fa-calendar',
  };

  /**
   * The system supported user claims.
   */
  Claims = Claims;

  /**
   * The list of financial periods.
   */
  financialPeriods$: Observable<FinancialPeriod[]>;

  /**
   * The pagination info.
   */
  paginationInfo$: Observable<PaginationInfo>;

  /**
   * Indicates whether there is a search process is running or not.
   */
  isSearching$: Observable<boolean>;

  /**
   * Indicates whether there is a open period process is running or not.
   */
  isOpening$: Observable<boolean>;

  /**
   * Indicates whether there is a pause period process is running or not.
   */
  isPausing$: Observable<boolean>;

  /**
   * Indicates whether there is a close period process is running or not.
   */
  isClosing$: Observable<boolean>;

  /**
   * The search form.
   */
  searchForm: FormGroup;

  /**
   * Gets or sets the currently selected period for open ,pause and close.
   */
  selectedPeriod: FinancialPeriod;

  /** The initial `fromDate` value for search. */
  initialFromDate = new Date();

  /** The initial `toDate` value for search.
   * It's the current day of month.
   */
  initialToDate = new Date();

  /**
   * @param notificationService The  notification service.
   * @param modalService The modal service.
   * @param translationService The translation service.
   * @param financesStore$ The finances-module store.
   */
  constructor(
    private modalService: NgbModal,
    private financesStore$: Store<fromFinancesStore.FinancesState>,
    private translationService: TranslationService,
    private notificationService: NotificationService
  ) {}

  ngOnInit(): void {
    this.init();
  }

  /**
   * Initialize component data.
   */
  init() {
    this.initForm();

    /**
     * Load data.
     */
    this.isOpening$ = this.financesStore$.pipe(select(fromFinancesStore.getSelectedFinancialPeriodOpening));
    this.isClosing$ = this.financesStore$.pipe(select(fromFinancesStore.getSelectedFinancialPeriodPausing));
    this.isPausing$ = this.financesStore$.pipe(select(fromFinancesStore.getSelectedFinancialPeriodClosing));
    this.isSearching$ = this.financesStore$.pipe(select(fromFinancesStore.getFinancialPeriodsSearching));

    let isManualSearchTriggeredBeforeForFinancialPeriods = false;
    this.financialPeriods$ = this.financesStore$.pipe(
      select(fromFinancesStore.getFinancialPeriods),
      tap((financialPeriods) => {
        if (!isManualSearchTriggeredBeforeForFinancialPeriods && !financialPeriods.length) {
          isManualSearchTriggeredBeforeForFinancialPeriods = true;
          this.search();
        }
      })
    );

    this.paginationInfo$ = this.financesStore$.pipe(select(fromFinancesStore.getFinancialPeriodsPaginationInfo));
  }

  /**
   * Initialize form and add validators.
   */
  initForm() {
    /* Set the `fromDate` to the first day of the current month.  */
    this.initialFromDate.setMonth(this.initialFromDate.getMonth(), 1);

    this.searchForm = new FormGroup({
      fromDate: new FormControl({
        year: this.initialFromDate.getFullYear(),
        month: 1,
        day: 1,
      }),
      toDate: new FormControl({
        year: this.initialToDate.getFullYear(),
        month: 12,
        day: 31,
      }),
      page: new FormControl(1),
    });
  }

  /**
   * Handles search parameters change.
   */
  search(event?: KeyboardEvent) {
    if (event && event.key === 'Enter') {
      event.preventDefault();
      return;
    }

    this.applyFiltersAndSearch();
  }

  /**
   * Handles pagination page-changed event.
   * @param page The current selected page number.
   */
  pageChanged(page: number) {
    /** Update pagination page. */
    this.searchForm.patchValue({ page });

    this.applyFiltersAndSearch(page);
  }

  applyFiltersAndSearch(page: number = 1) {
    if (!this.searchForm.get('fromDate')?.value || !this.searchForm.get('toDate')?.value) {
      return this.notificationService.warning(
        this.translationService.translate('FINANCES.FINANCIAL_PERIODS.NOTIFICATION_MESSAGE.DATE_SELECT')
      );
    }

    const { fromDate, toDate } = this.searchForm.value;

    const fromDateFormatted = new Date(fromDate.year, fromDate.month - 1, fromDate.day);
    fromDateFormatted.setHours(0, 0, 0, 0);

    const toDateFormatted = new Date(toDate.year, toDate.month - 1, toDate.day);
    toDateFormatted.setHours(23, 59, 59, 0);

    this.financesStore$.dispatch(
      new fromFinancesStore.SearchFinancialPeriods({
        fromDate: fromDateFormatted,
        toDate: toDateFormatted,
        page,
      })
    );
  }

  /**
   * Enables trackBy feature for *ngFor utility to track items
   * depending on a comparer to reduce DOM change.
   * If the item is already exist then no HTML will change, only bounded values.
   * @param index The index of the item.
   * @param period The item to determine if it was changed or not.
   */
  trackItems(index: number, period: FinancialPeriod) {
    return period ? period.id : undefined;
  }

  /**
   * OPens the period with the given id.
   * @param period The id of the period to be opened.
   */
  open(id: number) {
    this.financesStore$.dispatch(new fromFinancesStore.OpenFinancialPeriod(id));
  }
  /**
   * Pauses the period with the given id.
   * @param period The id of the period to be paused.
   */
  pause(id: number) {
    this.financesStore$.dispatch(new fromFinancesStore.PauseFinancialPeriod(id));
  }
  /**
   * Closes the period with the given id.
   * @param period The id of the period to be closed.
   */
  close(id: number) {
    this.financesStore$.dispatch(new fromFinancesStore.CloseFinancialPeriod(id));
  }

  /**
   * Opens the modal of the given templateRef.
   * @param modalRef The modal templateRef to be opened.
   */
  openModal(modalRef) {
    this.modalService.open(modalRef);
  }

  /**
   * Closes the currently opened modal.
   */
  closeModal() {
    this.modalService.dismissAll();
  }
}
