<!-- revenue details starts -->
<div class="row">
  <div class="col-md-12">
    <div class="card-header mb-3 pt-0">
      <h4 class="card-title">
        {{ 'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.INVOICE_DETAILS' | translate }} ({{
          'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.SALES_RETURN' | translate
        }})
      </h4>
    </div>
    <div class="card-content">
      <div class="px-3">
        <div class="row">
          <ng-container>
            <div class="form-group col-md-6">
              <label for="location">{{ 'SALES.INVOICES.SALE_INVOICE_DATA.SALE_INVOICE_LOCATION' | translate }}</label>
              <input
                type="text"
                id="location"
                class="form-control round"
                [value]="revenue.project.location.name | localize: revenue.project.location.nameEn | async"
                readonly
              />
            </div>
            <div class="form-group col-md-6">
              <label for="customer">{{ 'SALES.INVOICES.SALE_INVOICE_DATA.CUSTOMER' | translate }}</label>
              <input
                type="text"
                id="customer"
                class="form-control round"
                [value]="revenue.project.customer.name | localize: revenue.project.customer.nameEn | async"
                readonly
              />
            </div>
            <div class="form-group col-md-6">
              <label for="transactionDate">{{ 'SALES.INVOICES.SALE_INVOICE_DATA.INVOICE_DATE' | translate }}</label>
              <input
                type="text"
                id="transactionDate"
                class="form-control round"
                [value]="revenue.saleReturn.transactionDate | date: 'datetime'"
                readonly
              />
            </div>
            <div class="form-group col-md-6">
              <label for="createdAt">{{ 'SHARED.SEARCH.CREATED_AT' | translate }}</label>
              <input
                type="text"
                id="createdAt"
                class="form-control round"
                [value]="revenue.saleReturn.createdAt | date: 'datetime'"
                readonly
              />
            </div>
            <div class="form-group col-md-12">
              <label for="user">{{ 'SALES.INVOICES.SALE_INVOICE_DATA.USER_CREATE' | translate }}</label>
              <input type="text" id="user" class="form-control round" [value]="revenue.saleReturn.user.name" readonly />
            </div>
            <div class="form-group col-md-12">
              <label for="notes">{{ 'SALES.INVOICES.SALE_INVOICE_DATA.NOTES' | translate }}</label>
              <textarea
                id="notes"
                class="form-control round"
                [innerText]="revenue.saleReturn.notes"
                [placeholder]="'SALES.INVOICES.SALE_INVOICE_DATA.NOTES_NOT_FOUND' | translate"
                readonly
              ></textarea>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
    <!-- customer details ends -->

    <!-- products details starts -->
    <div class="card-header mb-3 pt-2">
      <h4 class="card-title">{{ 'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.PRODUCTS' | translate }}</h4>
    </div>
    <div class="card-content">
      <div class="px-3">
        <div class="row">
          <div class="col-md-12">
            <div class="table-responsive">
              <table class="table text-center">
                <thead>
                  <tr>
                    <th scope="col" class="fit-width"><i class="fa fa-list-ol"></i></th>

                    <th scope="col" class="fit-width">
                      <i class="fa fa-comments"></i>
                      {{ 'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.PRODUCT_DESCRIPTION' | translate }}
                    </th>
                    <th scope="col" class="fit-width">
                      <i class="fa fa-list-ol"></i>
                      {{ 'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.PRODUCT_QUANTITY' | translate }}
                    </th>

                    <th scope="col" class="fit-width">
                      <i class="fa fa-usd"></i>
                      {{ 'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.PRODUCT_VALUE' | translate }}
                    </th>
                    <th scope="col" class="fit-width">
                      <i class="fa fa-usd"></i>
                      {{ 'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.PRODUCT_TOTAL' | translate }}
                    </th>
                    <th scope="col" class="fit-width">
                      <i class="fa fa-percent"></i>
                      {{ 'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.TAX' | translate }}
                    </th>
                    <th scope="col" class="fit-width">
                      {{ 'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.PROJECT_SUB_TASK' | translate }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let projectRevenueLine of revenue.projectRevenueLines; let i = index">
                    <th scope="row">{{ i + 1 }}</th>
                    <td>
                      {{
                        (projectRevenueLine.saleReturnProduct.product?.description
                          | localize: projectRevenueLine.saleReturnProduct.product?.descriptionEn
                          | async) || projectRevenueLine.saleReturnProduct.description
                      }}
                      <div *ngIf="projectRevenueLine.saleReturnProduct.subProduct" class="mt-1 d-block">
                        <app-sub-product-info
                          [subProduct]="projectRevenueLine.saleReturnProduct.subProduct"
                        ></app-sub-product-info>
                      </div>
                    </td>
                    <td>{{ projectRevenueLine.saleReturnProduct.quantity | toDecimal }}</td>
                    <td>{{ projectRevenueLine.saleReturnProduct.value | toDecimal }}</td>
                    <td>{{ projectRevenueLine.saleReturnProduct.total | toDecimal }}</td>
                    <td>{{ projectRevenueLine.saleReturnProduct.tax | toDecimal }}</td>
                    <td>
                      {{
                        projectRevenueLine.saleReturnProduct.projectSubTask?.name
                          | localize: projectRevenueLine.saleReturnProduct.projectSubTask?.nameEn
                          | async
                      }}
                      <span class="d-block">
                        ({{
                          projectRevenueLine.saleReturnProduct.projectSubTask?.project.description
                            | localize: projectRevenueLine.saleReturnProduct.projectSubTask?.project.descriptionEn
                            | async
                        }})
                      </span>
                    </td>
                  </tr>
                </tbody>
                <tfoot></tfoot>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- products details ends -->
  </div>
</div>
<!-- revenue details ends -->
