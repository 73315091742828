import { createSelector } from '@ngrx/store';

import { BanksState, AppSearchState } from 'app-search/store/states';
import { selectAppSearchState } from 'app-search/store/selectors/feature.selectors';

/**
 * Gets the banks state.
 */
const selectBankState = createSelector(selectAppSearchState, (state: AppSearchState) => state.banks);

/**
 * Gets the list of loaded banks.
 */
export const getBanks = createSelector(selectBankState, (state: BanksState) => state.data);

/**
 * Gets the banks pagination info.
 */
export const getBanksPaginationInfo = createSelector(selectBankState, (state: BanksState) => state.paginationInfo);

/**
 * Gets the error that occurred in the state.
 */
export const getBanksError = createSelector(selectBankState, (state: BanksState) => state.error);

/**
 * Determines if there is a running search process.
 */
export const getBanksSearching = createSelector(selectBankState, (state: BanksState) => state.isSearching);

/**
 * Determines if the last search process has been ended successfully.
 */
export const getBanksSearchCompleted = createSelector(selectBankState, (state: BanksState) => state.isSearchCompleted);
