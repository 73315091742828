import * as fromCustomers from 'sales/components/customers';
import * as fromInvoices from 'sales/components/invoices';
import * as fromCustomerEngagements from 'sales/components/customer-engagements';
import * as fromCustomerAssets from 'sales/components/customer-assets';
import * as fromSaleReturns from 'sales/components/returns';
import * as fromShifts from 'sales/components/shifts';
import * as fromPendingPosInvoices from 'sales/components/pending-pos-invoices';
import * as fromOrdersDelivery from 'sales/components/orders-delivery';
import * as fromBoards from 'sales/components/boards';
import * as fromPosDevices from 'sales/components/pos-devices';
import * as fromPosPrinters from 'sales/components/pos-printers';
import * as fromKitchenPrinters from 'sales/components/kitchen-printers';
import * as fromCasherPrinters from 'sales/components/casher-printers';
import * as fromOrderDeliveryPrinters from 'sales/components/order-delivery-printers';

export * from 'sales/components/customers';
export * from 'sales/components/invoices';
export * from 'sales/components/customer-engagements';
export * from 'sales/components/customer-assets';
export * from 'sales/components/returns';
export * from 'sales/components/shifts';
export * from 'sales/components/pending-pos-invoices';
export * from 'sales/components/orders-delivery';
export * from 'sales/components/boards';
export * from 'sales/components/pos-devices';
export * from 'sales/components/pos-printers';
export * from 'sales/components/kitchen-printers';
export * from 'sales/components/casher-printers';
export * from 'sales/components/order-delivery-printers';

/**
 * The set of components registered on this module.
 */
export const COMPONENTS: any[] = [
  ...fromCustomers.COMPONENTS,
  ...fromInvoices.COMPONENTS,
  ...fromCustomerEngagements.COMPONENTS,
  ...fromCustomerAssets.COMPONENTS,
  ...fromSaleReturns.COMPONENTS,
  ...fromShifts.COMPONENTS,
  ...fromPendingPosInvoices.COMPONENTS,
  ...fromOrdersDelivery.COMPONENTS,
  ...fromBoards.COMPONENTS,
  ...fromPosDevices.COMPONENTS,
  ...fromPosPrinters.COMPONENTS,
  ...fromKitchenPrinters.COMPONENTS,
  ...fromCasherPrinters.COMPONENTS,
  ...fromOrderDeliveryPrinters.COMPONENTS,
];
