import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';

import { Observable } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { tap } from 'rxjs/operators';

import { NotificationService, PageInfo, PaginationInfo, TranslationService } from 'shared';
import * as fromFinancesStore from 'finances/store';
import * as fromLookupsStore from 'lookups/store';
import { Journal } from 'finances/models';
import { JournalType } from 'lookups/models';
import { Claims } from 'security/models';

@Component({
  selector: 'app-journals-list',
  templateUrl: './journals-list.component.html',
  styles: [],
})
export class JournalsListComponent implements OnInit {
  /**
   * Gets or sets the information about the current page.
   */
  pageInfo: PageInfo = {
    title: 'FINANCES.JOURNALS.JOURNALS_LINES',
    icon: 'fa fa-calendar',
  };
  /**
   * The system supported user claims.
   */
  Claims = Claims;

  /**
   * The list of journals.
   */
  journals$: Observable<Journal[]>;

  /**
   * The pagination info.
   */
  paginationInfo$: Observable<PaginationInfo>;

  /**
   * Indicates whether there is a search process is running or not.
   */
  isSearching$: Observable<boolean>;

  /**
   * The list of order types.
   */
  journalTypes$: Observable<JournalType[]>;

  /**
   * The search form.
   */
  searchForm: FormGroup;

  /**
   * Shows or hide the accounts list.
   */
  accountsListVisibility = false;

  /** The initial `fromDate` value for search. */
  initialFromDate = new Date();

  /** The initial `toDate` value for search.
   * It's the current day of month.
   */
  initialToDate = new Date();

  /**
   * @param financesStore$ The finance store.
   * @param lookupsStore$ The lookups store.
   * @param notificationService The  notification service.
   * @param translationService The translation service.
   */
  constructor(
    private financesStore$: Store<fromFinancesStore.FinancesState>,
    private lookupsStore$: Store<fromLookupsStore.LookupsState>,
    private notificationService: NotificationService,
    private translationService: TranslationService
  ) {}

  ngOnInit(): void {
    this.init();
  }

  /**
   * Initialize component data.
   */
  init() {
    this.initForm();

    /**
     * Load data.
     */
    this.isSearching$ = this.financesStore$.pipe(select(fromFinancesStore.getJournalsSearching));

    let isManualSearchTriggeredBeforeForJournals = false;
    this.journals$ = this.financesStore$.pipe(
      select(fromFinancesStore.getJournals),
      tap((journals) => {
        if (!isManualSearchTriggeredBeforeForJournals && !journals.length) {
          isManualSearchTriggeredBeforeForJournals = true;
          this.search();
        }
      })
    );

    let isManualSearchTriggeredBeforeForJournalTypes = false;
    this.journalTypes$ = this.lookupsStore$.pipe(
      select(fromLookupsStore.getJournalTypes),
      tap((journalTypes) => {
        if (!isManualSearchTriggeredBeforeForJournalTypes && !journalTypes.length) {
          isManualSearchTriggeredBeforeForJournalTypes = true;
          this.lookupsStore$.dispatch(new fromLookupsStore.GetAllJournalType());
        }
      })
    );

    this.paginationInfo$ = this.financesStore$.pipe(select(fromFinancesStore.getJournalsPaginationInfo));
  }

  /**
   * Initialize form and add validators.
   */
  initForm() {

    this.searchForm = new FormGroup({
      journalTypes: new FormControl([]),
      journalNum: new FormControl(''),
      fromDate: new FormControl({
        year: this.initialFromDate.getFullYear(),
        month: this.initialFromDate.getMonth() + 1,
        day: this.initialFromDate.getDate(),
      }),
      toDate: new FormControl({
        year: this.initialToDate.getFullYear(),
        month: this.initialToDate.getMonth() + 1,
        day: this.initialToDate.getDate(),
      }),
      page: new FormControl(1),
    });
  }

  /**
   * Handles search parameters change.
   */
  search(event?: KeyboardEvent) {
    if (event && event.key === 'Enter') {
      event.preventDefault();
      return;
    }

    this.applyFiltersAndSearch();
  }

  /**
   * Handles pagination page-changed event.
   * @param page The current selected page number.
   */
  pageChanged(page: number) {
    /** Update pagination page. */
    this.searchForm.patchValue({ page });

    this.applyFiltersAndSearch(page);
  }

  applyFiltersAndSearch(page: number = 1) {
    if (!this.searchForm.get('fromDate')?.value || !this.searchForm.get('toDate')?.value) {
      return this.notificationService.warning(
        this.translationService.translate('FINANCES.JOURNALS.NOTIFICATION_MESSAGE.DATE_SELECT')
      );
    }

    const { fromDate, toDate, journalNum, journalTypes } = this.searchForm.value;

    const fromDateFormatted = new Date(fromDate.year, fromDate.month - 1, fromDate.day);
    fromDateFormatted.setHours(0, 0, 0, 0);

    const toDateFormatted = new Date(toDate.year, toDate.month - 1, toDate.day);
    toDateFormatted.setHours(23, 59, 59, 0);

    this.financesStore$.dispatch(
      new fromFinancesStore.SearchJournals({
        journalTypes,
        journalNum,
        fromDate: fromDateFormatted,
        toDate: toDateFormatted,
        page,
      })
    );
  }

  /**
   * Enables trackBy feature for *ngFor utility to track items
   * depending on a comparer to reduce DOM change.
   * If the item is already exist then no HTML will change, only bounded values.
   * @param index The index of the item.
   * @param journal The item to determine if it was changed or not.
   */
  trackItems(index: number, journal: Journal) {
    return journal ? journal.id : undefined;
  }
}
