import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';

import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

import { NotificationService, PaginationInfo, TranslationService } from 'shared';
import * as fromAppSearchStore from 'app-search/store';
import { GovService } from 'hr/models';

@Component({
  selector: 'gov-services-search',
  templateUrl: './gov-services-search.component.html',
  styles: [],
})
export class GovServicesSearchComponent implements OnInit {
  /**
   * Indicates whether the modal will automatically close after select the first gov service.
   *
   * Be default it will not be closed.
   */
  @Input() closeOnSelect = false;

  /**
   * Sets the visibility of this component modal.
   *
   * Handles visibility change in two-data-binding approach.
   */
  @Input() set visible(isShown: boolean) {
    if (isShown && this.modalRef) {
      this.openModal(this.modalRef);
    } else {
      this.closeModal();
    }

    this.visibleChange.emit(isShown);
  }

  /**
   * Outputs a value that indicates whether the modal is shown or not.
   *
   * Handles visibility change in two-data-binding approach.
   */
  @Output() visibleChange = new EventEmitter<boolean>();

  /** Outputs the selected gov services. */
  @Output() govServices = new EventEmitter<GovService[]>();

  /**
   * The modal template reference.
   */
  @ViewChild('modalRef') modalRef: ElementRef<any>;

  /**
   * Indicates whether there is a search process is running or not.
   */
  isSearching$: Observable<boolean>;

  /**
   * The list of gov services.
   */
  govServices$: Observable<GovService[]>;

  /**
   * The list of gov services.
   */
  govServicesList: GovService[];

  /**
   * The pagination info.
   */
  paginationInfo$: Observable<PaginationInfo>;

  /**
   * The search form.
   */
  searchForm: FormGroup;

  /**
   * The ngb-modal reference to use it when modal needed to be closed.
   */
  modal: NgbModalRef;

  constructor(
    private modalService: NgbModal,
    private notificationService: NotificationService,
    private appSearchStore$: Store<fromAppSearchStore.AppSearchState>,
    private translationService: TranslationService
  ) {}

  ngOnInit(): void {
    this.init();
  }

  /**
   * Initialize component data.
   */
  init() {
    this.initForm();

    /**
     * Load data.
     */
    this.isSearching$ = this.appSearchStore$.pipe(select(fromAppSearchStore.getGovServicesSearching));

    let isManualSearchTriggeredBeforeGovServices = false;
    this.govServices$ = this.appSearchStore$.pipe(
      select(fromAppSearchStore.getGovServices),
      tap((govServices) => {
        if (govServices) {
          this.govServicesList = govServices;
        }
        if (!isManualSearchTriggeredBeforeGovServices && !govServices.length) {
          isManualSearchTriggeredBeforeGovServices = true;
          this.search();
        }
      })
    );

    this.paginationInfo$ = this.appSearchStore$.pipe(select(fromAppSearchStore.getGovServicesPaginationInfo));
  }

  /**
   * Initialize form and add validators.
   */
  initForm() {
    this.searchForm = new FormGroup({
      name: new FormControl(''),
      page: new FormControl(1),
    });
  }

  /**
   * Handles search parameters change.
   */
  search(event?: KeyboardEvent) {
    if (event && event.key === 'Enter') {
      event.preventDefault();
      return;
    }

    this.applyFiltersAndSearch();
  }

  /**
   * Handles pagination page-changed event.
   * @param page The current selected page number.
   */
  pageChanged(page: number) {
    /** Update pagination page. */
    this.searchForm.patchValue({ page });

    this.applyFiltersAndSearch(page);
  }

  /**
   * Enables trackBy feature for *ngFor utility to track items
   * depending on a comparer to reduce DOM change.
   * If the item is already exist then no HTML will change, only bounded values.
   * @param item The item to determine if it was changed or not.
   */
  trackItems(item: GovService) {
    return item ? item.id : undefined;
  }

  /**
   * Handles gov service selected event.
   * @param govServices The list of gov services to notify selected.
   */
  selectGovServices(govServices: GovService[]) {
    this.notificationService.successInstant(
      this.translationService.translate('HR.GOV_SERVICES.NOTIFICATION_MESSAGE.GOV_SERVICE_SELECTED')
    );
    this.govServices.emit(govServices);
    if (this.closeOnSelect) {
      this.closeModal();
    }
  }

  /**
   * Applies the search filters and dispatches a search action.
   * @param page The current page number, default to `1`.
   */
  applyFiltersAndSearch(page: number = 1) {
    const { name } = this.searchForm.value;

    this.appSearchStore$.dispatch(
      new fromAppSearchStore.SearchGovServices({
        name,
        page,
      })
    );
  }

  /**
   * Opens the modal of the given templateRef.
   * @param modalRef The modal templateRef to be opened.
   */
  openModal(modalRef) {
    /**
     * Open the modal & listen for user press ESC key.
     */
    this.modal = this.modalService.open(modalRef, { size: 'lg' });
    this.modal.result.then(null, () => this.closeModal());
  }

  /**
   * Closes the currently opened modal.
   */
  closeModal() {
    this.modal?.dismiss();
    this.govServices.emit([]);
    this.visibleChange.emit(false);
  }
}
