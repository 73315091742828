import { ActionReducerMap } from '@ngrx/store';
import { FinancesState } from 'finances/store/states';
import { costCentersReducer } from 'finances/store/reducers/cost-centers.reducer';
import { pettyCashReducer } from 'finances/store/reducers/petty-cash.reducer';
import { pettyCashRefundRequestsReducer } from 'finances/store/reducers/petty-cash-refund-requests.reducer';
import { pettyCashRefundReducer } from 'finances/store/reducers/petty-cash-refunds.reducer';
import { pettyCashReductionReducer } from 'finances/store/reducers/petty-cash-reductions.reducer';
import { accountsReducer } from 'finances/store/reducers/accounts.reducer';
import { banksReducer } from 'finances/store/reducers/banks.reducer';
import { systemAccountsReducer } from 'finances/store/reducers/system-accounts.reducer';
import { journalsReducer } from 'finances/store/reducers/journals.reducer';
import { bankAccountsReducer } from 'finances/store/reducers/bank-accounts.reducer';
import { pettyCashRefundRequestApprovalsReducer } from 'finances/store/reducers/petty-cash-refund-request-approvals.reducer';
import { financialPeriodsReducer } from 'finances/store/reducers/financial-periods.reducer';
import { currenciesReducer } from 'finances/store/reducers/currencies.reducer';
import { currencyExchangeRateReducer } from 'finances/store/reducers/currency-exchange-rate.reducer';
import { hrReducer } from 'finances/store/reducers/hr.reducer';
import { journalAssociationReducer } from 'finances/store/reducers/journal-association.reducer';
import { financeConfigurationsReducer } from 'finances/store/reducers/finance-configurations.reducer';

/**
 * The finances-Module store reducers.
 */
export const reducers: ActionReducerMap<FinancesState> = {
  costCenters: costCentersReducer,
  pettyCash: pettyCashReducer,
  pettyCashRefundRequests: pettyCashRefundRequestsReducer,
  pettyCashRefund: pettyCashRefundReducer,
  pettyCashReduction: pettyCashReductionReducer,
  accounts: accountsReducer,
  banks: banksReducer,
  systemAccounts: systemAccountsReducer,
  journals: journalsReducer,
  bankAccounts: bankAccountsReducer,
  pettyCashRefundRequestApprovals: pettyCashRefundRequestApprovalsReducer,
  financialPeriods: financialPeriodsReducer,
  currencies: currenciesReducer,
  currencyExchangeRates: currencyExchangeRateReducer,
  hr: hrReducer,
  journalAssociation: journalAssociationReducer,
  financeConfigurations: financeConfigurationsReducer,
};
