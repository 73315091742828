import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import { Subscription, Observable } from 'rxjs';
import { tap, skip } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { PageInfo, NotificationService, NotificationMessage, TranslationService, APP_CONSTANTS } from 'shared';
import * as fromFinancesStore from 'finances/store';
import { Bank, Currency } from 'finances/models';

@Component({
  selector: 'app-create-bank-account',
  templateUrl: './create-bank-account.component.html',
  styles: [],
})
export class CreateBankAccountComponent implements OnInit, OnDestroy {
  /**
   * The confirm modal template reference.
   */
  @ViewChild('confirmModalRef') confirmModalRef: ElementRef<any>;

  /**
   * Gets or sets the information about the current page.
   */
  pageInfo: PageInfo = {
    title: 'FINANCES.BANK_ACCOUNTS.CREATE_BANK_ACCOUNT_PAGE_TITLE',
    icon: 'fa fa-user-plus',
  };

  /**
   * The list of selected bank.
   */
  banks: Bank[] = [];

  /**
   * Shows or hide the currency list in search.
   */
  currencyListVisibility = false;

  /**
   * The list of selected currencies.
   */
  currencies: Currency[] = [];

  /**
   * Shows or hide the banks list.
   */
  banksListVisibility = false;

  /**
   * Indicates whether there is a create-bank account process is running or not.
   */
  isCreating$: Observable<boolean>;

  /**
   * The format of the `IBAN`
   */
  public ibanMask = APP_CONSTANTS.iban.mask;

  /**
   * The pattern of the `IBAN`
   */
  public ibanPattern = APP_CONSTANTS.iban.pattern;

  /**
   * The create bank account form.
   */
  form: FormGroup;

  /**
   * The set of subscriptions on this components,
   * these subscriptions must be unsubscribed before this component got destroyed.
   */
  subscriptions = new Subscription();

  /**
   * @param modalService The modal service.
   * @param notificationService The notification service.
   * @param financesStore$ The finances store.
   * @param translationService The translation service.
   */
  constructor(
    private modalService: NgbModal,
    private notificationService: NotificationService,
    private financesStore$: Store<fromFinancesStore.FinancesState>,
    private translationService: TranslationService
  ) {}

  ngOnInit(): void {
    this.init();
  }

  /**
   * Destroy component data
   */
  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  /**
   * Initialize component data.
   */
  init() {
    this.initForm();

    /**
     * Load data.
     */
    this.isCreating$ = this.financesStore$.pipe(select(fromFinancesStore.getSelectedBankAccountCreating));

    /**
     * By default select the primary currency.
     */
    let isManualSearchTriggeredBeforeForPrimaryCurrency = false;
    this.subscriptions.add(
      this.financesStore$
        .pipe(
          select(fromFinancesStore.getPrimaryCurrency),
          tap((primaryCurrency) => {
            if (!isManualSearchTriggeredBeforeForPrimaryCurrency && !primaryCurrency) {
              isManualSearchTriggeredBeforeForPrimaryCurrency = true;
              this.financesStore$.dispatch(new fromFinancesStore.FindPrimaryCurrency());
            } else {
              this.currencies = [primaryCurrency];
              this.form.patchValue({ currencyId: primaryCurrency.id });
            }
          })
        )
        .subscribe()
    );
  }

  /**
   * Initialize form and add validators.
   */
  initForm() {
    this.form = new FormGroup({
      name: new FormControl('', [Validators.required, Validators.minLength(1), Validators.maxLength(50)]),
      nameEn: new FormControl('', [Validators.required, Validators.minLength(1), Validators.maxLength(50)]),
      bankId: new FormControl(null, Validators.required),
      accountNumber: new FormControl('', [Validators.required, Validators.minLength(1), Validators.maxLength(50)]),
      iban: new FormControl('', [Validators.minLength(0), Validators.maxLength(34)]),
      currencyId: new FormControl(null, [Validators.required]),
    });
  }

  /**
   * Submits the form.
   */
  submit() {
    if (this.form.invalid) {
      const errorMessage = new NotificationMessage();

      if (this.form.get('name').invalid) {
        errorMessage.title = this.translationService.translate(
          'FINANCES.BANK_ACCOUNTS.BANK_ACCOUNT_DATA_VALIDATION.ARABIC_BANK_ACCOUNT_NAME_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate(
            'FINANCES.BANK_ACCOUNTS.BANK_ACCOUNT_DATA_VALIDATION.BANK_ACCOUNT_NAME_IS_REQUIRED'
          ),
          this.translationService.translate(
            'FINANCES.BANK_ACCOUNTS.BANK_ACCOUNT_DATA_VALIDATION.BANK_ACCOUNT_NAME_LENGTH_ERROR'
          ),
        ];
      } else if (this.form.get('nameEn').invalid) {
        errorMessage.title = this.translationService.translate(
          'FINANCES.BANK_ACCOUNTS.BANK_ACCOUNT_DATA_VALIDATION.ENGLISH_BANK_ACCOUNT_NAME_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate(
            'FINANCES.BANK_ACCOUNTS.BANK_ACCOUNT_DATA_VALIDATION.BANK_ACCOUNT_NAME_IS_REQUIRED'
          ),
          this.translationService.translate(
            'FINANCES.BANK_ACCOUNTS.BANK_ACCOUNT_DATA_VALIDATION.BANK_ACCOUNT_NAME_LENGTH_ERROR'
          ),
        ];
      } else if (this.form.get('bankId').invalid) {
        errorMessage.title = this.translationService.translate(
          'FINANCES.BANK_ACCOUNTS.BANK_ACCOUNT_DATA_VALIDATION.BANK_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate('FINANCES.BANK_ACCOUNTS.BANK_ACCOUNT_DATA_VALIDATION.BANK_IS_REQUIRED'),
        ];
      } else if (this.form.get('accountNumber').invalid) {
        errorMessage.title = this.translationService.translate(
          'FINANCES.BANK_ACCOUNTS.BANK_ACCOUNT_DATA_VALIDATION.BANK_ACCOUNT_NUMBER_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate(
            'FINANCES.BANK_ACCOUNTS.BANK_ACCOUNT_DATA_VALIDATION.BANK_ACCOUNT_NUMBER_IS_REQUIRED'
          ),
          this.translationService.translate(
            'FINANCES.BANK_ACCOUNTS.BANK_ACCOUNT_DATA_VALIDATION.BANK_ACCOUNT_NUMBER_LENGTH_ERROR'
          ),
        ];
      } else if (this.form.get('iban').invalid) {
        errorMessage.title = this.translationService.translate(
          'FINANCES.BANK_ACCOUNTS.BANK_ACCOUNT_DATA_VALIDATION.ACCOUNT_NUMBER_IBAN_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate(
            'FINANCES.BANK_ACCOUNTS.BANK_ACCOUNT_DATA_VALIDATION.ACCOUNT_NUMBER_IBAN_LENGTH_ERROR'
          ),
        ];
      } else if (this.form.get('currencyId').invalid) {
        errorMessage.title = this.translationService.translate(
          'FINANCES.BANK_ACCOUNTS.BANK_ACCOUNT_DATA_VALIDATION.CURRENCY_ACCOUNT_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate(
            'FINANCES.BANK_ACCOUNTS.BANK_ACCOUNT_DATA_VALIDATION.CURRENCY_ACCOUNT_IS_REQUIRED'
          ),
        ];
      }

      this.form.markAllAsTouched();
      return this.notificationService.warningWithTitle(errorMessage);
    } else {
      this.openModal(this.confirmModalRef);
    }
  }

  /**
   * Confirms the form submit.
   */
  confirm() {
    this.financesStore$.dispatch(
      new fromFinancesStore.CreateBankAccount({
        ...this.form.value,
        currencyId: this.form.value.currencyId,
      })
    );
  }

  /**
   * Opens the modal of the given templateRef.
   * @param modalRef The modal templateRef to be opened.
   */
  openModal(modalRef) {
    this.modalService.open(modalRef);
  }

  /**
   * Selects the newly selected bank from the banks search list.
   * @param banks The list of newly selected banks to select the only one in the list.
   */
  selectBank([bank]: Bank[]) {
    if (bank) {
      this.banks = [bank];
      this.form.patchValue({ bankId: bank.id });
    }
  }

  /**
   * Adds the newly selected currency the currency search list.
   * @param currency The list of newly selected currency to be added.
   */
  selectCurrency([currency]: Currency[]) {
    if (currency) {
      this.currencies = [currency];
      this.form.patchValue({ currencyId: currency.id });
    }
  }
}
