import { Report } from 'reports/models';

/**
 * Represents the user reports state.
 */
export class UserReportsState {
  /**
   * The loaded reports.
   */
  public data: Report[];

  /**
   * An error that may resulting during processing some actions.
   */
  public error: any;

  /**
   * Determines if there is a running load process.
   */
  public isLoading: boolean;

  /**
   * Determines if the last load process has been ended successfully.
   */
  public isLoadCompleted: boolean;

  /**
   * Determines if there is a running delete process.
   */
  public isDeleting: boolean;

  /**
   * Determines if the last delete process has been ended successfully.
   */
  public isDeleteCompleted: boolean;
}
