import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import { Observable, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import {
  PageInfo,
  NotificationService,
  NotificationMessage,
  TranslationService,
  CustomValidators,
  APP_CONSTANTS,
} from 'shared';
import * as fromFinancesStore from 'finances/store';
import { Currency, CurrencyExchangeRate } from 'finances/models';

@Component({
  selector: 'app-update-currency-exchange-rate',
  templateUrl: './update-currency-exchange-rate.component.html',
  styles: [],
})
export class UpdateCurrencyExchangeRateComponent implements OnInit, OnDestroy {
  /**
   * The confirm modal template reference.
   */
  @ViewChild('confirmModalRef') confirmModalRef: ElementRef<any>;

  /**
   * The decimal mask.
   */
  readonly DECIMAL_MASK = APP_CONSTANTS.numeric.decimal.mask;

  /**
   * Gets or sets the information about the current page.
   */
  pageInfo: PageInfo = {
    title: 'FINANCES.CURRENCY_EXCHANGE_RATE.UPDATE_CURRENCY_EXCHANGE_RATE_PAGE_TITLE',
    icon: 'fa fa-edit',
  };

  /**
   * Shows or hide the currency list in search.
   */
  primaryCurrencyListVisibility = false;

  /**
   * Shows or hide the currency list in search.
   */
  secondaryCurrencyListVisibility = false;

  /**
   * The list of selected currencies.
   */
  primaryCurrencies: Currency[] = [];

  /**
   * The list of selected currencies.
   */
  secondaryCurrencies: Currency[] = [];

  /**
   * Gets or sets the id of the current edited currency exchange rate.
   */
  currencyExchangeRateId: number;

  /**
   * The current edited currency exchange rate.
   */
  currencyExchangeRate$: Observable<CurrencyExchangeRate>;

  /**
   * Indicates whether there is a update currency exchange rate process is running or not.
   */
  isUpdating$: Observable<boolean>;

  /**
   * The update currency form.
   */
  form: FormGroup;

  /** The initial `fromDate` value for search. */
  initialFromDate = new Date();

  /** The initial `toDate` value for search.
   * It's the current day of month.
   */
  initialToDate = new Date();

  /**
   * The set of subscriptions on this components,
   * these subscriptions must be unsubscribed before this component got destroyed.
   */
  subscriptions = new Subscription();

  /**
   * @param route The activated route.
   * @param modalService The modal service.
   * @param notificationService The notification service.
   * @param financesStore$ The finances store.
   * @param translationService The translation service.
   */
  constructor(
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private notificationService: NotificationService,
    private financesStore$: Store<fromFinancesStore.FinancesState>,
    private translationService: TranslationService
  ) {}

  ngOnInit(): void {
    this.init();
  }

  /**
   * Destroy component data
   */
  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  /**
   * Initialize component data.
   */
  init() {
    this.initForm();

    /** Subscribe to route to get the element id whenever it changed. */
    const routeSubscription = this.route.paramMap
      .pipe(
        tap((params) => {
          this.currencyExchangeRateId = +params.get('currencyExchangeRateId');
          this.financesStore$.dispatch(new fromFinancesStore.FindCurrencyExchangeRate(this.currencyExchangeRateId));
        })
      )
      .subscribe();
    this.subscriptions.add(routeSubscription);

    /* Load the selected currency exchange rate. */
    this.currencyExchangeRate$ = this.financesStore$.pipe(
      select(fromFinancesStore.getSelectedCurrencyExchangeRate),
      tap((currencyExchangeRate) => {
        if (currencyExchangeRate) {
          this.primaryCurrencies = [currencyExchangeRate.primaryCurrency];
          this.secondaryCurrencies = [currencyExchangeRate.secondaryCurrency];

          const startDate = new Date(currencyExchangeRate.startDate);
          const endDate = new Date(currencyExchangeRate.endDate);

          this.form.patchValue({
            ...currencyExchangeRate,
            startDate: {
              year: startDate.getFullYear(),
              month: startDate.getMonth() + 1,
              day: startDate.getDate(),
            },
            endDate: {
              year: endDate.getFullYear(),
              month: endDate.getMonth() + 1,
              day: endDate.getDate(),
            },
          });
          this.form.markAsPristine();
        }
      })
    );

    /**
     * Load data.
     */
    this.isUpdating$ = this.financesStore$.pipe(select(fromFinancesStore.getSelectedCurrencyExchangeRateUpdating));
  }

  /**
   * Initialize form and add validators.
   */
  initForm() {
    /* Set the `fromDate` to the first day of the current month.  */
    this.initialFromDate.setMonth(this.initialFromDate.getMonth(), 1);

    this.form = new FormGroup({
      primaryCurrencyId: new FormControl(null, Validators.required),
      secondaryCurrencyId: new FormControl(null, Validators.required),
      rate: new FormControl('', [Validators.required, CustomValidators.gt(0)]),
      startDate: new FormControl(
        {
          year: this.initialFromDate.getFullYear(),
          month: this.initialFromDate.getMonth() + 1,
          day: this.initialFromDate.getDate(),
        },
        [Validators.required]
      ),
      endDate: new FormControl(
        {
          year: this.initialToDate.getFullYear(),
          month: this.initialToDate.getMonth() + 1,
          day: this.initialToDate.getDate(),
        },
        [Validators.required]
      ),
    });
  }

  /**
   * Submits the form.
   */
  submit() {
    if (this.form.invalid) {
      const errorMessage = new NotificationMessage();

      if (this.form.get('primaryCurrencyId').invalid) {
        errorMessage.title = this.translationService.translate(
          'FINANCES.CURRENCY_EXCHANGE_RATE.CURRENCY_EXCHANGE_RATE_DATA_VALIDATION.PRIMARY_CURRENCY_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate(
            'FINANCES.CURRENCY_EXCHANGE_RATE.CURRENCY_EXCHANGE_RATE_DATA_VALIDATION.PRIMARY_CURRENCY_IS_REQUIRED'
          ),
          this.translationService.translate(
            'FINANCES.CURRENCY_EXCHANGE_RATE.CURRENCY_EXCHANGE_RATE_DATA_VALIDATION.PRIMARY_CURRENCY_LENGTH_ERROR'
          ),
        ];
      } else if (this.form.get('secondaryCurrencyId').invalid) {
        errorMessage.title = this.translationService.translate(
          'FINANCES.CURRENCY_EXCHANGE_RATE.CURRENCY_EXCHANGE_RATE_DATA_VALIDATION.SECONDARY_CURRENCY_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate(
            'FINANCES.CURRENCY_EXCHANGE_RATE.CURRENCY_EXCHANGE_RATE_DATA_VALIDATION.SECONDARY_CURRENCY_IS_REQUIRED'
          ),
          this.translationService.translate(
            'FINANCES.CURRENCY_EXCHANGE_RATE.CURRENCY_EXCHANGE_RATE_DATA_VALIDATION.SECONDARY_CURRENCY_LENGTH_ERROR'
          ),
        ];
      } else if (this.form.get('rate').invalid) {
        errorMessage.title = this.translationService.translate(
          'FINANCES.CURRENCY_EXCHANGE_RATE.CURRENCY_EXCHANGE_RATE_DATA_VALIDATION.RATE_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate(
            'FINANCES.CURRENCY_EXCHANGE_RATE.CURRENCY_EXCHANGE_RATE_DATA_VALIDATION.RATE_IS_REQUIRED'
          ),
          this.translationService.translate(
            'FINANCES.CURRENCY_EXCHANGE_RATE.CURRENCY_EXCHANGE_RATE_DATA_VALIDATION.RATE_LENGTH_ERROR'
          ),
        ];
      } else if (this.form.get('startDate').invalid) {
        errorMessage.title = this.translationService.translate(
          'FINANCES.CURRENCY_EXCHANGE_RATE.CURRENCY_EXCHANGE_RATE_DATA_VALIDATION.START_DATE_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate(
            'FINANCES.CURRENCY_EXCHANGE_RATE.CURRENCY_EXCHANGE_RATE_DATA_VALIDATION.START_DATE_IS_REQUIRED'
          ),
        ];
      } else if (this.form.get('endDate').invalid) {
        errorMessage.title = this.translationService.translate(
          'FINANCES.CURRENCY_EXCHANGE_RATE.CURRENCY_EXCHANGE_RATE_DATA_VALIDATION.END_DATE_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate(
            'FINANCES.CURRENCY_EXCHANGE_RATE.CURRENCY_EXCHANGE_RATE_DATA_VALIDATION.END_DATE_IS_REQUIRED'
          ),
        ];
      }

      this.form.markAllAsTouched();
      return this.notificationService.warningWithTitle(errorMessage);
    } else {
      this.openModal(this.confirmModalRef);
    }
  }

  /**
   * Confirms the form submit.
   */
  confirm() {
    const { primaryCurrencyId, secondaryCurrencyId, rate, startDate, endDate } = this.form.value;

    const startDateFormatted = new Date(startDate.year, startDate.month - 1, startDate.day);
    startDateFormatted.setHours(0, 0, 0, 0);

    const endDateFormatted = new Date(endDate.year, endDate.month - 1, endDate.day);
    endDateFormatted.setHours(23, 59, 59, 0);

    this.financesStore$.dispatch(
      new fromFinancesStore.UpdateCurrencyExchangeRate({
        id: this.currencyExchangeRateId,
        primaryCurrencyId,
        secondaryCurrencyId,
        rate,
        startDate: startDateFormatted,
        endDate: endDateFormatted,
      })
    );
  }

  /**
   * Opens the modal of the given templateRef.
   * @param modalRef The modal templateRef to be opened.
   */
  openModal(modalRef) {
    this.modalService.open(modalRef);
  }

  /**
   * Adds the newly selected primaryCurrency the primaryCurrency search list.
   * @param primaryCurrency The list of newly selected primaryCurrency to be added.
   */
  selectPrimaryCurrency([primaryCurrency]: Currency[]) {
    if (primaryCurrency) {
      this.primaryCurrencies = [primaryCurrency];
      this.form.patchValue({ primaryCurrencyId: primaryCurrency.id });
    }
  }
  /**
   * Adds the newly selected secondaryCurrency the secondaryCurrency search list.
   * @param secondaryCurrency The list of newly selected secondaryCurrency to be added.
   */
  selectSecondaryCurrency([secondaryCurrency]: Currency[]) {
    if (secondaryCurrency) {
      this.secondaryCurrencies = [secondaryCurrency];
      this.form.patchValue({ secondaryCurrencyId: secondaryCurrency.id });
    }
  }
}
