<!-- projects costs modal starts -->
<ng-template #modalRef let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title text-primary" id="modal-basic-title">
      {{ 'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.PROJECTS_COSTS' | translate }}
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="d(); closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="col-md-12">
      <div class="table-responsive">
        <table class="table table-sm text-center">
          <thead>
            <tr>
              <th scope="col" class="fit-width"><i class="fa fa-list-ol"></i></th>
              <th scope="col" class="fit-width">
                {{ 'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.PROJECT' | translate }}
              </th>
              <th scope="col" class="fit-width">
                {{ 'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.DAYS_COUNT' | translate }}
              </th>
              <th scope="col" class="fit-width">
                {{ 'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.NET' | translate }}
              </th>
              <th scope="col" class="fit-width">
                {{ 'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.SALARY' | translate }}
              </th>
              <th scope="col" class="fit-width">
                {{ 'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.OTHER_EARNINGS' | translate }}
              </th>
              <th scope="col" class="fit-width">
                {{ 'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.DEDUCTIONS' | translate }}
              </th>
              <th scope="col" class="fit-width">
                {{ 'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.OVERTIMES' | translate }}
              </th>
              <th scope="col" class="fit-width">
                {{ 'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.EMPLOYEE_GOSI' | translate }}
              </th>
              <th scope="col" class="fit-width">
                {{ 'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.EMPLOYER_GOSI' | translate }}
              </th>
              <th scope="col" class="fit-width">
                {{ 'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.PROVISIONS' | translate }}
              </th>
              <th scope="col" class="fit-width">
                {{ 'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.GOV_SERVICES' | translate }}
              </th>
              <th scope="col" class="fit-width">
                {{ 'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.UNPAID_LEAVES' | translate }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let payrollProjectCost of payrollProjectCosts; trackBy: trackItems; let i = index">
              <th scope="row">{{ i + 1 }}</th>
              <td class="fit-width">
                {{
                  payrollProjectCost.project.description | localize: payrollProjectCost.project.descriptionEn | async
                }}
              </td>
              <td>{{ payrollProjectCost.daysCount | toNumber }}</td>
              <td>
                <span
                  [ngClass]="{
                    'text-danger': payrollProjectCost.net < 0
                  }"
                >
                  {{ payrollProjectCost.net | toDecimal }}
                </span>
              </td>
              <td>{{ payrollProjectCost.grossSalary | toDecimal }}</td>
              <td>{{ payrollProjectCost.otherEarnings | toDecimal }}</td>
              <td>{{ payrollProjectCost.deductions | toDecimal }}</td>
              <td>{{ payrollProjectCost.overtimes | toDecimal }}</td>
              <td>{{ payrollProjectCost.employeeGosi | toDecimal }}</td>
              <td>{{ payrollProjectCost.employerGosi | toDecimal }}</td>
              <td class="fit-width">
                <a
                  *ngIf="payrollProjectCost.payrollProjectCostProvisions.length"
                  class="info px-2"
                  (click)="
                    selectedPayrollProjectProvisions = payrollProjectCost.payrollProjectCostProvisions;
                    payrollProjectProvisionsListVisibility = true
                  "
                  [ngbTooltip]="'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.PROVISIONS_INFO_DISPLAY' | translate"
                >
                  <i class="fa fa-info font-medium-3 mr-2"></i>
                </a>
              </td>
              <td class="fit-width">
                <a
                  *ngIf="payrollProjectCost.payrollProjectCostGovServices.length"
                  class="info px-2"
                  (click)="
                    selectedPayrollProjectGovServices = payrollProjectCost.payrollProjectCostGovServices;
                    payrollProjectGovServicesListVisibility = true
                  "
                  [ngbTooltip]="
                    'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.GOV_SERVICES_INFO_DISPLAY' | translate
                  "
                >
                  <i class="fa fa-info font-medium-3 mr-2"></i>
                </a>
              </td>
              <td class="fit-width">
                <a
                  *ngIf="payrollProjectCost.payrollProjectUnpaidLeaves.length"
                  class="info px-2"
                  (click)="
                    selectedPayrollProjectUnpaidLeaves = payrollProjectCost.payrollProjectUnpaidLeaves;
                    payrollProjectUnpaidLeavesListVisibility = true
                  "
                  [ngbTooltip]="
                    'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.UNPAID_LEAVES_INFO_DISPLAY' | translate
                  "
                >
                  <i class="fa fa-info font-medium-3 mr-2"></i>
                </a>
              </td>
            </tr>
          </tbody>
          <tfoot class="tfoot-light">
            <tr>
              <td [attr.colspan]="13">
                <i class="fa fa-bookmark c-primary"></i>
                <span>
                  {{ payrollProjectCosts.length | toNumber }}
                  {{ 'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.PROJECT_COST' | translate }}
                </span>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="modal-actions">
      <button type="button" class="btn btn-outline-dark round" (click)="c(); closeModal()">
        <i class="fa fa-times"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.CLOSE' | translate }}</span>
      </button>
    </div>
  </div>
</ng-template>
<!-- projects costs modal ends -->

<!-- no items template starts -->
<ng-template #noItems>
  <div class="col-md-12 alert alert-warning mt-3 text-center" role="alert">
    {{ 'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.PROJECTS_COSTS_NOT_FOUND' | translate }}
  </div>
</ng-template>
<!-- no items template ends -->

<!-- payroll provisions modal starts -->
<app-payroll-provisions-info
  [payrollProvisions]="selectedPayrollProjectProvisions"
  [(visible)]="payrollProjectProvisionsListVisibility"
></app-payroll-provisions-info>
<!-- payroll provisions modal ends -->

<!-- payroll gov-services modal starts -->
<app-payroll-gov-services-info
  [payrollGovServices]="selectedPayrollProjectGovServices"
  [(visible)]="payrollProjectGovServicesListVisibility"
></app-payroll-gov-services-info>
<!-- payroll gov-services modal ends -->

<!-- payroll unpaid leaves modal starts -->
<app-payroll-unpaid-leaves-info
  [payrollUnpaidLeaves]="selectedPayrollProjectUnpaidLeaves"
  [(visible)]="payrollProjectUnpaidLeavesListVisibility"
></app-payroll-unpaid-leaves-info>
<!-- payroll unpaid leaves modal ends -->
