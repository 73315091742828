import { ProjectResourcesActionType, ProjectResourcesActions } from 'projects-management/store/actions';
import { ProjectResourcesState } from 'projects-management/store/states';

/**
 * The initial state from which the state starts.
 */
const initialState: ProjectResourcesState = {
  data: [],
  purchaseReturnData: [],
  purchaseInvoiceData: [],
  outgoingStockData: [],
  assetDepreciationData: [],
  payrollData: [],
  paginationInfo: {
    total: 0,
    page: 1,
    pageSize: 0,
    count: 0,
  },
  error: null,
  isFinding: false,
  isFindCompleted: false,
  isFindingPayroll: false,
  isFindPayrollCompleted: false,
  isFindingAssetDepreciation: false,
  isFindAssetDepreciationCompleted: false,
  isFindingOutgoingStock: false,
  isFindOutgoingStockCompleted: false,
  isFindingPurchaseReturn: false,
  isFindPurchaseReturnCompleted: false,
  isFindingPurchaseInvoice: false,
  isFindPurchaseInvoiceCompleted: false,
};

/**
 * The reducer function that is called in each action dispatch against the state.
 * @param state The current state.
 * @param action The action that will affect the state.
 */
export function projectResourcesReducer(
  state: ProjectResourcesState = initialState,
  action: ProjectResourcesActions
): ProjectResourcesState {
  switch (action.type) {
    //#region FIND_PROJECT_RESOURCE_LINES

    case ProjectResourcesActionType.FIND_PROJECT_RESOURCE_LINES: {
      return {
        ...state,
        data: null,
        error: null,
        isFinding: true,
        isFindCompleted: false,
      };
    }

    case ProjectResourcesActionType.FIND_PROJECT_RESOURCE_LINES_FAIL: {
      return {
        ...state,
        error: action.payload,
        isFinding: false,
        isFindCompleted: false,
      };
    }

    case ProjectResourcesActionType.FIND_PROJECT_RESOURCE_LINES_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        error: null,
        isFinding: false,
        isFindCompleted: true,
      };
    }

    //#endregion FIND_PROJECT_RESOURCE_LINES

    //#region FIND_PROJECT_RESOURCE_PURCHASE_INVOICE_LINES

    case ProjectResourcesActionType.FIND_PROJECT_RESOURCE_PURCHASE_INVOICE_LINES: {
      return {
        ...state,
        purchaseInvoiceData: null,
        error: null,
        isFindingPurchaseInvoice: true,
        isFindPurchaseInvoiceCompleted: false,
      };
    }

    case ProjectResourcesActionType.FIND_PROJECT_RESOURCE_PURCHASE_INVOICE_LINES_FAIL: {
      return {
        ...state,
        error: action.payload,
        isFindingPurchaseInvoice: false,
        isFindPurchaseInvoiceCompleted: false,
      };
    }

    case ProjectResourcesActionType.FIND_PROJECT_RESOURCE_PURCHASE_INVOICE_LINES_SUCCESS: {
      return {
        ...state,
        purchaseInvoiceData: action.payload.data,
        error: null,
        isFindingPurchaseInvoice: false,
        isFindPurchaseInvoiceCompleted: true,
      };
    }

    //#endregion FIND_PROJECT_RESOURCE_PURCHASE_INVOICE_LINES

    //#region FIND_PROJECT_RESOURCE_PURCHASE_RETURN_LINES

    case ProjectResourcesActionType.FIND_PROJECT_RESOURCE_PURCHASE_RETURN_LINES: {
      return {
        ...state,
        purchaseReturnData: null,
        error: null,
        isFindingPurchaseReturn: true,
        isFindPurchaseReturnCompleted: false,
      };
    }

    case ProjectResourcesActionType.FIND_PROJECT_RESOURCE_PURCHASE_RETURN_LINES_FAIL: {
      return {
        ...state,
        error: action.payload,
        isFindingPurchaseReturn: false,
        isFindPurchaseReturnCompleted: false,
      };
    }

    case ProjectResourcesActionType.FIND_PROJECT_RESOURCE_PURCHASE_RETURN_LINES_SUCCESS: {
      return {
        ...state,
        purchaseReturnData: action.payload.data,
        error: null,
        isFindingPurchaseReturn: false,
        isFindPurchaseReturnCompleted: true,
      };
    }

    //#endregion FIND_PROJECT_RESOURCE_PURCHASE_RETURN_LINES

    //#region FIND_PROJECT_RESOURCE_OUTGOING_STOCK_LINES

    case ProjectResourcesActionType.FIND_PROJECT_RESOURCE_OUTGOING_STOCK_LINES: {
      return {
        ...state,
        error: null,
        outgoingStockData: null,
        isFindingOutgoingStock: true,
        isFindOutgoingStockCompleted: false,
      };
    }

    case ProjectResourcesActionType.FIND_PROJECT_RESOURCE_OUTGOING_STOCK_LINES_FAIL: {
      return {
        ...state,
        error: action.payload,
        isFindingOutgoingStock: false,
        isFindOutgoingStockCompleted: false,
      };
    }

    case ProjectResourcesActionType.FIND_PROJECT_RESOURCE_OUTGOING_STOCK_LINES_SUCCESS: {
      return {
        ...state,
        outgoingStockData: action.payload.data,
        error: null,
        isFindingOutgoingStock: false,
        isFindOutgoingStockCompleted: true,
      };
    }

    //#endregion FIND_PROJECT_RESOURCE_OUTGOING_STOCK_LINES

    //#region FIND_PROJECT_RESOURCE_ASSET_LINES

    case ProjectResourcesActionType.FIND_PROJECT_RESOURCE_ASSET_DEPRECIATION_LINES: {
      return {
        ...state,
        assetDepreciationData: null,
        error: null,
        isFindingAssetDepreciation: true,
        isFindAssetDepreciationCompleted: false,
      };
    }

    case ProjectResourcesActionType.FIND_PROJECT_RESOURCE_ASSET_DEPRECIATION_LINES_FAIL: {
      return {
        ...state,
        error: action.payload,
        isFindingAssetDepreciation: false,
        isFindAssetDepreciationCompleted: false,
      };
    }

    case ProjectResourcesActionType.FIND_PROJECT_RESOURCE_ASSET_DEPRECIATION_LINES_SUCCESS: {
      return {
        ...state,
        assetDepreciationData: action.payload.data,
        error: null,
        isFindingAssetDepreciation: false,
        isFindAssetDepreciationCompleted: true,
      };
    }

    //#endregion FIND_PROJECT_RESOURCE_ASSET_LINES

    //#region FIND_PROJECT_RESOURCE_PAYROLL_LINES

    case ProjectResourcesActionType.FIND_PROJECT_RESOURCE_PAYROLL_LINES: {
      return {
        ...state,
        payrollData: null,
        error: null,
        isFindingPayroll: true,
        isFindPayrollCompleted: false,
      };
    }

    case ProjectResourcesActionType.FIND_PROJECT_RESOURCE_PAYROLL_LINES_FAIL: {
      return {
        ...state,
        error: action.payload,
        isFindingPayroll: false,
        isFindPayrollCompleted: false,
      };
    }

    case ProjectResourcesActionType.FIND_PROJECT_RESOURCE_PAYROLL_LINES_SUCCESS: {
      return {
        ...state,
        payrollData: action.payload.data,
        error: null,
        isFindingPayroll: false,
        isFindPayrollCompleted: true,
      };
    }

    //#endregion FIND_PROJECT_RESOURCE_PAYROLL_LINES

    default:
      return state;
  }
}
