import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';

import { Observable, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

import * as fromStoresStore from 'stores/store';
import * as fromAuthStore from 'auth/store';
import { NotificationService, PaginationInfo, TranslationService } from 'shared';
import { ProductClass } from 'stores/models';
import { Location } from 'stores/models';

/**
 * locations search form wrapped in modal.
 */
@Component({
  selector: 'app-product-classes-search',
  templateUrl: './product-classes-search.component.html',
  styles: [],
})
export class ProductClassesSearchComponent implements OnInit, OnDestroy {
  /**
   * The modal template reference.
   */
  @ViewChild('modalRef') modalRef: ElementRef<any>;

  /**
   * Indicates whether the modal will automatically close after select the first product Class.
   *
   * Be default it will not be closed.
   */
  @Input() closeOnSelect = false;

  /**
   * Indicates whether the product classes displayed on the list should be all active.
   *
   * Be default it will not be closed.
   */
  @Input() onlyActive = false;

  /**
   * Sets the visibility of this component modal.
   *
   * Handles visibility change in two-data-binding approach.
   */
  @Input() set visible(isShown: boolean) {
    if (isShown && this.modalRef) {
      this.openModal(this.modalRef);
    } else {
      this.closeModal();
    }

    this.visibleChange.emit(isShown);
  }

  /**
   * Outputs a value that indicates whether the modal is shown or not.
   *
   * Handles visibility change in two-data-binding approach.
   */
  @Output() visibleChange = new EventEmitter<boolean>();

  /** Outputs the selected product classes. */
  @Output() productClasses = new EventEmitter<ProductClass[]>();

  /**
   * Shows or hide the locations list.
   */
  locationsListVisibility = false;

  /**
   * The list of selected locations.
   */
  locations: Location[] = [];

  /**
   * Shows or hide the create product class modal.
   */
  createProductClassModalVisibility = false;

  /**
   * The list of product classes.
   */
  productClasses$: Observable<ProductClass[]>;

  /**
   * The list of product classes.
   */
  productClassesList: ProductClass[];

  /**
   * Indicates whether there is a search process is running or not.
   */
  isSearching$: Observable<boolean>;

  /**
   * The pagination info.
   */
  paginationInfo$: Observable<PaginationInfo>;

  /**
   * The search form.
   */
  searchForm: FormGroup;

  /**
   * The ngb-modal reference to use it when modal needed to be closed.
   */
  modal: NgbModalRef;

  /**
   * The set of subscriptions on this components,
   * these subscriptions must be unsubscribed before this component got destroyed.
   */
  subscriptions = new Subscription();

  /**
   * @param modalService The modal service.
   * @param notificationService The notification service.
   * @param translationService The translation service.
   * @param storesStore$ The stores-store module.
   * @param authStore$ The auth-store module.
   */
  constructor(
    private modalService: NgbModal,
    private notificationService: NotificationService,
    private translationService: TranslationService,
    private storesStore$: Store<fromStoresStore.StoresState>,
    private authStore$: Store<fromAuthStore.AuthState>
  ) {}

  ngOnInit(): void {
    this.init();
  }

  /**
   * Destroy component data
   */
  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  /**
   * Initialize component data.
   */
  init() {
    this.initForm();

    /**
     * Load data.
     */
    this.isSearching$ = this.storesStore$.pipe(select(fromStoresStore.getProductClassesSearching));

    let isManualSearchTriggeredBeforeForClasses = false;
    this.productClasses$ = this.storesStore$.pipe(
      select(fromStoresStore.getProductClasses),
      tap((productClasses) => {
        if (productClasses) {
          this.productClassesList = productClasses;
        }
        if (!isManualSearchTriggeredBeforeForClasses && !productClasses.length) {
          isManualSearchTriggeredBeforeForClasses = true;
          this.search();
        }
      })
    );

    /* Subscribe to selected location to select it whenever it changed. */
    this.subscriptions.add(
      this.authStore$
        .pipe(
          select(fromAuthStore.getSessionUserDefaultLocation),
          tap((location) => {
            if (location && !this.searchForm.value.locations.includes(location.id)) {
              this.selectLocations([location]);
            }
          })
        )
        .subscribe()
    );

    this.paginationInfo$ = this.storesStore$.pipe(select(fromStoresStore.getProductClassesPaginationInfo));
  }

  /**
   * Initialize form and add validators.
   */
  initForm() {
    this.searchForm = new FormGroup({
      name: new FormControl(''),
      locations: new FormControl([]),
      page: new FormControl(1),
    });
  }

  /**
   * Handles search parameters change.
   */
  search(event?: KeyboardEvent) {
    if (event && event.key === 'Enter') {
      event.preventDefault();
      return;
    }

    this.storesStore$.dispatch(
      new fromStoresStore.SearchProductClasses({
        name: this.searchForm.get('name').value,
        locations: this.searchForm.get('locations').value,
        page: 1,
      })
    );
  }

  /**
   * Handles pagination page-changed event.
   * @param page The current selected page number.
   */
  pageChanged(page: number) {
    /** Update pagination page. */
    this.searchForm.patchValue({ page });

    /** Get selected page items. */
    this.storesStore$.dispatch(
      new fromStoresStore.SearchProductClasses({
        name: this.searchForm.get('name').value,
        locations: this.searchForm.get('locations').value,
        page: this.searchForm.get('page').value,
      })
    );
  }

  /**
   * Enables trackBy feature for *ngFor utility to track items
   * depending on a comparer to reduce DOM change.
   * If the item is already exist then no HTML will change, only bounded values.
   * @param index The index of the item.
   * @param productClass The item to determine if it was changed or not.
   */
  trackItems(index: number, productClass: ProductClass) {
    return productClass ? productClass.id : undefined;
  }

  /**
   * Opens the modal of the given templateRef.
   * @param modalRef The modal templateRef to be opened.
   */
  openModal(modalRef) {
    /**
     * Open the modal & listen for user press ESC key.
     */
    this.modal = this.modalService.open(modalRef, { size: 'lg' });
    this.modal.result.then(null, () => this.closeModal());
  }

  /**
   * Closes the currently opened modal.
   */
  closeModal() {
    this.modal?.dismiss();
    this.productClasses.emit([]);
    this.visibleChange.emit(false);
  }

  /**
   * Handles product class selected event.
   * @param productClasses The list of productClasses to notify selected.
   */
  selectProductClass(productClasses: ProductClass[]) {
    this.notificationService.successInstant(
      this.translationService.translate('STORES.PRODUCT_CLASSES.NOTIFICATION_MESSAGE.PRODUCT_CLASS_SELECTED')
    );
    this.productClasses.emit(productClasses);
    if (this.closeOnSelect) {
      this.closeModal();
    }
  }

  /**
   * Adds the newly selected locations the locations search list.
   * @param locations The list of newly selected locations to be added.
   */
  selectLocations(locations: Location[]) {
    const selectedIds: number[] = this.searchForm.get('locations').value;
    this.locations = [...this.locations.filter((location) => selectedIds.includes(location.id)), ...locations];
    this.searchForm.patchValue({ locations: this.locations.map((location) => location.id) });
  }
}
