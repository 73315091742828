import { PurchasesReportsActionType, PurchasesReportsActions } from 'reports/store/actions';
import { PurchasesReportsState } from 'reports/store/states';

/**
 * The initial state from which the state starts.
 */
const initialState: PurchasesReportsState = {
  mostReturnedPurchaseProducts: null,
  purchaseInvoices: null,
  purchaseOrders: null,
  purchaseRequests: null,
  purchaseReturns: null,
  topPurchasedProducts: null,
  vendorSummary: null,
  vendorsDebits: null,
  worstPurchaseProducts: null,
  worstReturnedPurchaseProducts: null,
  error: null,
  isLoadingMostReturnedPurchaseProducts: false,
  isMostReturnedPurchaseProductsLoadCompleted: false,
  isLoadingPurchaseInvoices: false,
  isPurchaseInvoicesLoadCompleted: false,
  isLoadingPurchaseOrders: false,
  isPurchaseOrdersLoadCompleted: false,
  isLoadingPurchaseRequests: false,
  isPurchaseRequestsLoadCompleted: false,
  isLoadingPurchaseReturns: false,
  isPurchaseReturnsLoadCompleted: false,
  isLoadingTopPurchasedProducts: false,
  isTopPurchasedProductsLoadCompleted: false,
  isLoadingVendorSummary: false,
  isVendorSummaryLoadCompleted: false,
  isLoadingVendorsDebts: false,
  isVendorsDebtsLoadCompleted: false,
  isLoadingWorstPurchaseProducts: false,
  isWorstPurchaseProductsLoadCompleted: false,
  isLoadingWorstReturnedPurchaseProducts: false,
  isWorstReturnedPurchaseProductsLoadCompleted: false,
};

/**
 * The reducer function that is called in each action dispatch against the state.
 * @param state The current state.
 * @param action The action that will affect the state.
 */
export function purchasesReportsReducer(
  state: PurchasesReportsState = initialState,
  action: PurchasesReportsActions
): PurchasesReportsState {
  switch (action.type) {
    //#region GET_TOP_RETURNED_PURCHASE_PRODUCTS_REPORT

    case PurchasesReportsActionType.GET_TOP_RETURNED_PURCHASE_PRODUCTS_REPORT: {
      return {
        ...state,
        mostReturnedPurchaseProducts: null,
        isLoadingMostReturnedPurchaseProducts: true,
        isMostReturnedPurchaseProductsLoadCompleted: false,
        error: null,
      };
    }

    case PurchasesReportsActionType.GET_TOP_RETURNED_PURCHASE_PRODUCTS_REPORT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isLoadingMostReturnedPurchaseProducts: false,
        isMostReturnedPurchaseProductsLoadCompleted: false,
      };
    }

    case PurchasesReportsActionType.GET_TOP_RETURNED_PURCHASE_PRODUCTS_REPORT_RECEIVED: {
      return {
        ...state,
        error: null,
        isLoadingMostReturnedPurchaseProducts: false,
        isMostReturnedPurchaseProductsLoadCompleted: true,
      };
    }

    case PurchasesReportsActionType.GET_TOP_RETURNED_PURCHASE_PRODUCTS_REPORT_SUCCESS: {
      return {
        ...state,
        mostReturnedPurchaseProducts: action.payload.data,
        error: null,
        isLoadingMostReturnedPurchaseProducts: false,
        isMostReturnedPurchaseProductsLoadCompleted: true,
      };
    }

    case PurchasesReportsActionType.TOP_RETURNED_PURCHASE_PRODUCTS_REPORT_VIEW: {
      return {
        ...state,
        mostReturnedPurchaseProducts: action.payload,
        error: null,
        isLoadingMostReturnedPurchaseProducts: false,
        isMostReturnedPurchaseProductsLoadCompleted: true,
      };
    }

    //#endregion MOST_RETURNED_PURCHASE_PRODUCTS

    //#region GET_PURCHASE_INVOICES_REPORT

    case PurchasesReportsActionType.GET_PURCHASE_INVOICES_REPORT: {
      return {
        ...state,
        purchaseInvoices: null,
        isLoadingPurchaseInvoices: true,
        isPurchaseInvoicesLoadCompleted: false,
        error: null,
      };
    }

    case PurchasesReportsActionType.GET_PURCHASE_INVOICES_REPORT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isLoadingPurchaseInvoices: false,
        isPurchaseInvoicesLoadCompleted: false,
      };
    }

    case PurchasesReportsActionType.GET_PURCHASE_INVOICES_REPORT_RECEIVED: {
      return {
        ...state,
        error: null,
        isLoadingPurchaseInvoices: false,
        isPurchaseInvoicesLoadCompleted: true,
      };
    }

    case PurchasesReportsActionType.GET_PURCHASE_INVOICES_REPORT_SUCCESS: {
      return {
        ...state,
        purchaseInvoices: action.payload.data,
        error: null,
        isLoadingPurchaseInvoices: false,
        isPurchaseInvoicesLoadCompleted: true,
      };
    }

    case PurchasesReportsActionType.PURCHASE_INVOICES_REPORT_VIEW: {
      return {
        ...state,
        purchaseInvoices: action.payload,
        error: null,
        isLoadingPurchaseInvoices: false,
        isPurchaseInvoicesLoadCompleted: true,
      };
    }

    //#endregion PURCHASE_INVOICES

    //#region GET_PURCHASE_ORDERS_REPORT

    case PurchasesReportsActionType.GET_PURCHASE_ORDERS_REPORT: {
      return {
        ...state,
        purchaseOrders: null,
        isLoadingPurchaseOrders: true,
        isPurchaseOrdersLoadCompleted: false,
        error: null,
      };
    }

    case PurchasesReportsActionType.GET_PURCHASE_ORDERS_REPORT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isLoadingPurchaseOrders: false,
        isPurchaseOrdersLoadCompleted: false,
      };
    }

    case PurchasesReportsActionType.GET_PURCHASE_ORDERS_REPORT_RECEIVED: {
      return {
        ...state,
        error: null,
        isLoadingPurchaseOrders: false,
        isPurchaseOrdersLoadCompleted: true,
      };
    }

    case PurchasesReportsActionType.GET_PURCHASE_ORDERS_REPORT_SUCCESS: {
      return {
        ...state,
        purchaseOrders: action.payload.data,
        error: null,
        isLoadingPurchaseOrders: false,
        isPurchaseOrdersLoadCompleted: true,
      };
    }

    case PurchasesReportsActionType.PURCHASE_ORDERS_REPORT_VIEW: {
      return {
        ...state,
        purchaseOrders: action.payload,
        error: null,
        isLoadingPurchaseOrders: false,
        isPurchaseOrdersLoadCompleted: true,
      };
    }

    //#endregion PURCHASE_ORDERS

    //#region GET_PURCHASE_REQUESTS_REPORT

    case PurchasesReportsActionType.GET_PURCHASE_REQUESTS_REPORT: {
      return {
        ...state,
        purchaseRequests: null,
        isLoadingPurchaseRequests: true,
        isPurchaseRequestsLoadCompleted: false,
        error: null,
      };
    }

    case PurchasesReportsActionType.GET_PURCHASE_REQUESTS_REPORT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isLoadingPurchaseRequests: false,
        isPurchaseRequestsLoadCompleted: false,
      };
    }

    case PurchasesReportsActionType.GET_PURCHASE_REQUESTS_REPORT_RECEIVED: {
      return {
        ...state,
        error: null,
        isLoadingPurchaseRequests: false,
        isPurchaseRequestsLoadCompleted: true,
      };
    }

    case PurchasesReportsActionType.GET_PURCHASE_REQUESTS_REPORT_SUCCESS: {
      return {
        ...state,
        purchaseRequests: action.payload.data,
        error: null,
        isLoadingPurchaseRequests: false,
        isPurchaseRequestsLoadCompleted: true,
      };
    }

    case PurchasesReportsActionType.PURCHASE_REQUESTS_REPORT_VIEW: {
      return {
        ...state,
        purchaseRequests: action.payload,
        error: null,
        isLoadingPurchaseRequests: false,
        isPurchaseRequestsLoadCompleted: true,
      };
    }

    //#endregion PURCHASE_REQUESTS

    //#region GET_PURCHASE_RETURNS_REPORT

    case PurchasesReportsActionType.GET_PURCHASE_RETURNS_REPORT: {
      return {
        ...state,
        purchaseReturns: null,
        isLoadingPurchaseReturns: true,
        isPurchaseReturnsLoadCompleted: false,
        error: null,
      };
    }

    case PurchasesReportsActionType.GET_PURCHASE_RETURNS_REPORT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isLoadingPurchaseReturns: false,
        isPurchaseReturnsLoadCompleted: false,
      };
    }

    case PurchasesReportsActionType.GET_PURCHASE_RETURNS_REPORT_RECEIVED: {
      return {
        ...state,
        error: null,
        isLoadingPurchaseReturns: false,
        isPurchaseReturnsLoadCompleted: true,
      };
    }

    case PurchasesReportsActionType.GET_PURCHASE_RETURNS_REPORT_SUCCESS: {
      return {
        ...state,
        purchaseReturns: action.payload.data,
        error: null,
        isLoadingPurchaseReturns: false,
        isPurchaseReturnsLoadCompleted: true,
      };
    }

    case PurchasesReportsActionType.PURCHASE_RETURNS_REPORT_VIEW: {
      return {
        ...state,
        purchaseReturns: action.payload,
        error: null,
        isLoadingPurchaseReturns: false,
        isPurchaseReturnsLoadCompleted: true,
      };
    }

    //#endregion PURCHASE_RETURNS

    //#region GET_TOP_PURCHASED_PRODUCTS_REPORT

    case PurchasesReportsActionType.GET_TOP_PURCHASED_PRODUCTS_REPORT: {
      return {
        ...state,
        topPurchasedProducts: null,
        isLoadingTopPurchasedProducts: true,
        isTopPurchasedProductsLoadCompleted: false,
        error: null,
      };
    }

    case PurchasesReportsActionType.GET_TOP_PURCHASED_PRODUCTS_REPORT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isLoadingTopPurchasedProducts: false,
        isTopPurchasedProductsLoadCompleted: false,
      };
    }

    case PurchasesReportsActionType.GET_TOP_PURCHASED_PRODUCTS_REPORT_RECEIVED: {
      return {
        ...state,
        error: null,
        isLoadingTopPurchasedProducts: false,
        isTopPurchasedProductsLoadCompleted: true,
      };
    }

    case PurchasesReportsActionType.GET_TOP_PURCHASED_PRODUCTS_REPORT_SUCCESS: {
      return {
        ...state,
        topPurchasedProducts: action.payload.data,
        error: null,
        isLoadingTopPurchasedProducts: false,
        isTopPurchasedProductsLoadCompleted: true,
      };
    }

    case PurchasesReportsActionType.TOP_PURCHASED_PRODUCTS_REPORT_VIEW: {
      return {
        ...state,
        topPurchasedProducts: action.payload,
        error: null,
        isLoadingTopPurchasedProducts: false,
        isTopPurchasedProductsLoadCompleted: true,
      };
    }

    //#endregion TOP_PURCHASED_PRODUCTS

    //#region GET_VENDOR_SUMMARY_REPORT

    case PurchasesReportsActionType.GET_VENDOR_SUMMARY_REPORT: {
      return {
        ...state,
        vendorSummary: null,
        isLoadingVendorSummary: true,
        isVendorSummaryLoadCompleted: false,
        error: null,
      };
    }

    case PurchasesReportsActionType.GET_VENDOR_SUMMARY_REPORT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isLoadingVendorSummary: false,
        isVendorSummaryLoadCompleted: false,
      };
    }

    case PurchasesReportsActionType.GET_VENDOR_SUMMARY_REPORT_RECEIVED: {
      return {
        ...state,
        error: null,
        isLoadingVendorSummary: false,
        isVendorSummaryLoadCompleted: true,
      };
    }

    case PurchasesReportsActionType.GET_VENDOR_SUMMARY_REPORT_SUCCESS: {
      return {
        ...state,
        vendorSummary: action.payload.data,
        error: null,
        isLoadingVendorSummary: false,
        isVendorSummaryLoadCompleted: true,
      };
    }

    case PurchasesReportsActionType.VENDOR_SUMMARY_REPORT_VIEW: {
      return {
        ...state,
        vendorSummary: action.payload,
        error: null,
        isLoadingVendorSummary: false,
        isVendorSummaryLoadCompleted: true,
      };
    }

    //#endregion VENDOR_SUMMARY

    //#region GET_VENDORS_DEBTS_REPORT

    case PurchasesReportsActionType.GET_VENDORS_DEBTS_REPORT: {
      return {
        ...state,
        vendorsDebits: null,
        isLoadingVendorsDebts: true,
        isVendorsDebtsLoadCompleted: false,
        error: null,
      };
    }

    case PurchasesReportsActionType.GET_VENDORS_DEBTS_REPORT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isLoadingVendorsDebts: false,
        isVendorsDebtsLoadCompleted: false,
      };
    }

    case PurchasesReportsActionType.GET_VENDORS_DEBTS_REPORT_RECEIVED: {
      return {
        ...state,
        error: null,
        isLoadingVendorsDebts: false,
        isVendorsDebtsLoadCompleted: true,
      };
    }

    case PurchasesReportsActionType.GET_VENDORS_DEBTS_REPORT_SUCCESS: {
      return {
        ...state,
        vendorsDebits: action.payload.data,
        error: null,
        isLoadingVendorsDebts: false,
        isVendorsDebtsLoadCompleted: true,
      };
    }

    case PurchasesReportsActionType.VENDORS_DEBTS_REPORT_VIEW: {
      return {
        ...state,
        vendorsDebits: action.payload,
        error: null,
        isLoadingVendorsDebts: false,
        isVendorsDebtsLoadCompleted: true,
      };
    }

    //#endregion VENDORS_DEBTS

    //#region GET_WORST_PURCHASED_PRODUCTS_REPORT

    case PurchasesReportsActionType.GET_WORST_PURCHASED_PRODUCTS_REPORT: {
      return {
        ...state,
        worstPurchaseProducts: null,
        isLoadingWorstPurchaseProducts: true,
        isWorstPurchaseProductsLoadCompleted: false,
        error: null,
      };
    }

    case PurchasesReportsActionType.GET_WORST_PURCHASED_PRODUCTS_REPORT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isLoadingWorstPurchaseProducts: false,
        isWorstPurchaseProductsLoadCompleted: false,
      };
    }

    case PurchasesReportsActionType.GET_WORST_PURCHASED_PRODUCTS_REPORT_RECEIVED: {
      return {
        ...state,
        error: null,
        isLoadingWorstPurchaseProducts: false,
        isWorstPurchaseProductsLoadCompleted: true,
      };
    }

    case PurchasesReportsActionType.GET_WORST_PURCHASED_PRODUCTS_REPORT_SUCCESS: {
      return {
        ...state,
        worstPurchaseProducts: action.payload.data,
        error: null,
        isLoadingWorstPurchaseProducts: false,
        isWorstPurchaseProductsLoadCompleted: true,
      };
    }

    case PurchasesReportsActionType.WORST_PURCHASED_PRODUCTS_REPORT_VIEW: {
      return {
        ...state,
        worstPurchaseProducts: action.payload,
        error: null,
        isLoadingWorstPurchaseProducts: false,
        isWorstPurchaseProductsLoadCompleted: true,
      };
    }

    //#endregion WORST_PURCHASED_PRODUCTS

    //#region GET_WORST_RETURNED_PURCHASE_PRODUCTS_REPORT

    case PurchasesReportsActionType.GET_WORST_RETURNED_PURCHASE_PRODUCTS_REPORT: {
      return {
        ...state,
        worstReturnedPurchaseProducts: null,
        isLoadingWorstReturnedPurchaseProducts: true,
        isWorstReturnedPurchaseProductsLoadCompleted: false,
        error: null,
      };
    }

    case PurchasesReportsActionType.GET_WORST_RETURNED_PURCHASE_PRODUCTS_REPORT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isLoadingWorstReturnedPurchaseProducts: false,
        isWorstReturnedPurchaseProductsLoadCompleted: false,
      };
    }

    case PurchasesReportsActionType.GET_WORST_RETURNED_PURCHASE_PRODUCTS_REPORT_RECEIVED: {
      return {
        ...state,
        error: null,
        isLoadingWorstReturnedPurchaseProducts: false,
        isWorstReturnedPurchaseProductsLoadCompleted: true,
      };
    }

    case PurchasesReportsActionType.GET_WORST_RETURNED_PURCHASE_PRODUCTS_REPORT_SUCCESS: {
      return {
        ...state,
        worstReturnedPurchaseProducts: action.payload.data,
        error: null,
        isLoadingWorstReturnedPurchaseProducts: false,
        isWorstReturnedPurchaseProductsLoadCompleted: true,
      };
    }

    case PurchasesReportsActionType.WORST_RETURNED_PURCHASE_PRODUCTS_REPORT_VIEW: {
      return {
        ...state,
        worstReturnedPurchaseProducts: action.payload,
        error: null,
        isLoadingWorstReturnedPurchaseProducts: false,
        isWorstReturnedPurchaseProductsLoadCompleted: true,
      };
    }

    //#endregion WORST_RETURNED_PURCHASE_PRODUCTS

    default:
      return state;
  }
}
