<!-- customers search modal starts -->
<ng-template #modalRef let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title text-primary" id="modal-basic-title">{{ 'SALES.CUSTOMERS.CUSTOMERS_LIST' | translate }}</h4>
    <button type="button" class="close" aria-label="Close" (click)="d(); closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <!-- customers list starts -->
    <div class="row">
      <div class="col-md-12">
        <form class="row" [formGroup]="searchForm">
          <div class="form-group col">
            <input
              type="text"
              class="form-control round"
              [placeholder]="'SALES.CUSTOMERS.CUSTOMER_DATA.CUSTOMER_NAME_SEARCH_PLACEHOLDER' | translate"
              formControlName="name"
              tabindex="0"
              (input)="search($event)"
              ngbAutofocus
            />
          </div>
          <div class="form-group col-md-auto">
            <button type="button" class="btn btn-raised btn-primary round" (click)="initForm(); search()">
              <i class="fa fa-refresh"></i>
            </button>
          </div>
        </form>
      </div>
      <div class="col-md-12" *ngIf="(customers$ | async)?.length; else noItems">
        <div class="row">
          <div class="col-md-12 alert alert-light text-center" role="alert">
            {{ 'SHARED.SEARCH.SEARCH_RESULTS' | translate }} ({{ (paginationInfo$ | async).total | toNumber }})
            {{ 'SALES.CUSTOMERS.CUSTOMER_DATA.CLIENT' | translate }}
          </div>
          <div class="col-md-12">
            <div class="table-responsive">
              <table class="table text-center">
                <thead>
                  <tr>
                    <th class="fit-width" *ngIf="!closeOnSelect">
                      <a
                        class="primary p-0"
                        (click)="selectCustomer(customersList)"
                        [ngbTooltip]="'SHARED.SEARCH.SELECT_ALL' | translate"
                      >
                        <i class="fa fa-check-circle font-medium-3"></i>
                      </a>
                    </th>
                    <th class="fit-width" *ngIf="closeOnSelect">
                      <i class="fa fa-gear font-medium-3"></i>
                    </th>
                    <th scope="col">
                      <i class="fa fa-barcode"></i
                      ><span class="mx-1">{{ 'SALES.CUSTOMERS.CUSTOMER_DATA.CUSTOMER_CODE' | translate }}</span>
                    </th>
                    <th scope="col">{{ 'SALES.CUSTOMERS.CUSTOMER_DATA.CUSTOMER' | translate }}</th>
                    <th scope="col">{{ 'SALES.CUSTOMERS.CUSTOMER_DATA.CUSTOMER_TYPE' | translate }}</th>
                    <th scope="col">{{ 'SALES.CUSTOMERS.CUSTOMER_DATA.RESPONSIBLE' | translate }}</th>
                    <th scope="col"><i class="fa fa-phone"></i></th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let customer of customers$ | async; trackBy: trackItems">
                    <td>
                      <a
                        class="primary p-0"
                        (click)="selectCustomer([customer])"
                        [ngbTooltip]="'SALES.CUSTOMERS.CUSTOMER_DATA.SELECT_CUSTOMER' | translate"
                      >
                        <i class="fa fa-check-circle-o font-medium-3"></i>
                      </a>
                    </td>
                    <th scope="row">{{ customer.code }}</th>
                    <td>{{ customer.name | localize: customer.nameEn | async }}</td>
                    <td>{{ customer.customerCategory.name | localize: customer.customerCategory.nameEn | async }}</td>
                    <td>{{ customer.representorName }}</td>
                    <td style="white-space: pre-line; direction: initial;">
                      {{ extractContacts(customer) }}
                    </td>
                  </tr>
                </tbody>
              </table>
              <div class="d-flex justify-content-between p-2">
                <ngb-pagination
                  *ngIf="paginationInfo$ | async; let pagination"
                  maxSize="5"
                  [collectionSize]="pagination.total"
                  [pageSize]="pagination.pageSize"
                  [page]="pagination.page"
                  (pageChange)="pageChanged($event)"
                  boundaryLinks="true"
                >
                  <ng-template ngbPaginationFirst>{{ 'SHARED.PAGINATION.PAGINATION_FIRST' | translate }}</ng-template>
                  <ng-template ngbPaginationPrevious>{{
                    'SHARED.PAGINATION.PAGINATION_PREVIOUS' | translate
                  }}</ng-template>
                  <ng-template ngbPaginationNext>{{ 'SHARED.PAGINATION.PAGINATION_NEXT' | translate }}</ng-template>
                  <ng-template ngbPaginationLast>{{ 'SHARED.PAGINATION.PAGINATION_LAST' | translate }}</ng-template>
                </ngb-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- customers list ends -->
  </div>
  <div class="modal-footer">
    <div class="modal-actions">
      <button type="button" class="btn btn-outline-dark round" (click)="c(); closeModal()">
        <i class="fa fa-times"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.CLOSE' | translate }}</span>
      </button>
    </div>
  </div>
</ng-template>
<!-- customers search modal ends -->

<!-- no items template starts -->
<ng-template #noItems>
  <div class="col-md-12 alert alert-warning mt-3 text-center" role="alert">
    {{ 'SALES.CUSTOMERS.SEARCH_CUSTOMERS_WITHOUT_ADD_NOT_FOUND' | translate }}
  </div>
</ng-template>
<!-- no items template ends -->
