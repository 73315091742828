import { Action } from '@ngrx/store';

import { CustomerEngagementType } from 'lookups/models';
import { AppHttpResponse } from 'shared';

/**
 * The Lookups-Module store  customer engagement type action types.
 */
export enum CustomerEngagementTypeActionType {
  GET_ALL_CUSTOMER_ENGAGEMENT_TYPE = '[Lookups] [CustomerEngagementType] Get All Customer Engagement Types',
  GET_ALL_CUSTOMER_ENGAGEMENT_TYPE_FAIL = '[Lookups] [CustomerEngagementType] Get All Customer Engagement Types Fail',
  GET_ALL_CUSTOMER_ENGAGEMENT_TYPE_SUCCESS = '[Lookups] [CustomerEngagementType] Get All Customer Engagement Types Success',
}

/**
 * Represents a store  customer engagement type action.
 */
export class GetAllCustomerEngagementType implements Action {
  /**
   * The type of the action.
   */
  readonly type = CustomerEngagementTypeActionType.GET_ALL_CUSTOMER_ENGAGEMENT_TYPE;

  /**
   * Fires a new  customer engagement type action.
   */
  constructor() {}
}

/**
 * Represents a store  customer engagement type fail action.
 */
export class GetAllCustomerEngagementTypeFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = CustomerEngagementTypeActionType.GET_ALL_CUSTOMER_ENGAGEMENT_TYPE_FAIL;

  /**
   * Fires a new  customer engagement type fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store  customer engagement type success action.
 */
export class GetAllCustomerEngagementTypeSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = CustomerEngagementTypeActionType.GET_ALL_CUSTOMER_ENGAGEMENT_TYPE_SUCCESS;

  /**
   * Fires a  customer engagement type success action.
   * @param payload An object contains the list of  customer engagement types.
   */
  constructor(public payload: AppHttpResponse<CustomerEngagementType[]>) {}
}

/**
 * Lookups-module  customer engagement type action types.
 */
export type CustomerEngagementTypeActions =
  | GetAllCustomerEngagementType
  | GetAllCustomerEngagementTypeFail
  | GetAllCustomerEngagementTypeSuccess;
