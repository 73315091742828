<div class="row">
  <div class="col-md-12">
    <!-- journal details starts -->
    <div class="card-header mb-3">
      <h4 class="card-title">{{ 'FINANCES.JOURNALS.JOURNAL_DATA.JOURNAL_DATA' | translate }}</h4>
    </div>
    <div class="card-content">
      <div class="px-3">
        <div class="row">
          <div class="form-group col-md-6">
            <label for="journalNum">{{ 'FINANCES.JOURNALS.JOURNAL_DATA.JOURNAL_NUMBER_LABEL' | translate }}</label>
            <input type="text" id="journalNum" class="form-control round" [value]="journal.journalNum" readonly />
          </div>
          <div class="form-group col-md-6">
            <label for="journalType">{{ 'FINANCES.JOURNALS.JOURNAL_DATA.JOURNAL_TYPE' | translate }}</label>
            <input
              type="text"
              id="journalType"
              class="form-control round"
              [value]="journal.journalType.name | localize: journal.journalType.nameEn | async"
              readonly
            />
          </div>
          <div class="form-group col-md-3">
            <label for="transactionDate">{{ 'FINANCES.JOURNALS.JOURNAL_DATA.JOURNAL_DATE' | translate }}</label>
            <input
              type="text"
              id="transactionDate"
              class="form-control round"
              [value]="journal.transactionDate | date: 'datetime'"
              readonly
            />
          </div>
          <div class="form-group col-md-3">
            <label for="createdAt">{{ 'SHARED.SEARCH.CREATED_AT' | translate }}</label>
            <input
              type="text"
              id="createdAt"
              class="form-control round"
              [value]="journal.createdAt | date: 'datetime'"
              readonly
            />
          </div>
          <div class="form-group col-md-6">
            <label for="user">{{ 'FINANCES.JOURNALS.JOURNAL_DATA.USER_CREATE' | translate }}</label>
            <input type="text" id="user" class="form-control round" [value]="journal.user.name" readonly />
          </div>
        </div>
      </div>
    </div>
    <!-- journal details ends -->

    <!-- journal lines details starts -->
    <div class="card-header mb-3 pt-2">
      <h4 class="card-title">{{ 'FINANCES.JOURNALS.JOURNAL_DATA.JOURNAL_LINES' | translate }}</h4>
    </div>
    <div class="card-content">
      <div class="px-3">
        <div class="row">
          <div class="col-md-12">
            <div class="table-responsive">
              <table class="table text-center">
                <thead>
                  <tr>
                    <th scope="col"><i class="fa fa-list-ol"></i></th>
                    <th scope="col">
                      <i class="fa fa-map-signs"></i>
                      {{ 'FINANCES.JOURNALS.JOURNAL_DATA.ACCOUNT' | translate }}
                    </th>
                    <th scope="col">
                      <i class="fa fa-usd"></i>
                      {{ 'FINANCES.JOURNALS.JOURNAL_DATA.DEBIT' | translate }}
                    </th>
                    <th scope="col">
                      <i class="fa fa-usd"></i>
                      {{ 'FINANCES.JOURNALS.JOURNAL_DATA.CREDIT' | translate }}
                    </th>
                    <th scope="col">
                      {{ 'FINANCES.JOURNALS.JOURNAL_DATA.COST_CENTER' | translate }}
                    </th>
                    <th scope="col">
                      <i class="fa fa-pencil"></i>
                      {{ 'FINANCES.JOURNALS.JOURNAL_DATA.NOTES' | translate }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let line of journal.journalLines; let i = index">
                    <th scope="row">{{ i + 1 }}</th>
                    <td>{{ line.account.description }}</td>
                    <td>{{ line.debit | toDecimal }}</td>
                    <td>{{ line.credit | toDecimal }}</td>
                    <td>{{ line.costCenter.description | localize: line.costCenter.descriptionEn | async }}</td>
                    <td style="white-space: pre-line;">{{ line.notes }}</td>
                  </tr>
                </tbody>
                <tfoot class="tfoot-light">
                  <tr>
                    <td [attr.colspan]="6">
                      <i class="fa fa-bookmark c-primary"></i>
                      <span>
                        {{ journal.journalLines.length }}
                        {{ 'FINANCES.JOURNALS.JOURNAL_DATA.JOURNAL_LINE' | translate }}</span
                      >
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
          <div class="form-group col-12 d-flex justify-content-between flex-column flex-sm-row mt-2">
            <button type="button" class="btn btn-raised btn-secondary round" ngbDropdown display="dynamic">
              <i class="fa fa-print"></i>
              <span class="mx-1" id="dropdownPrintPaperSizes" ngbDropdownToggle>
                {{ 'SHARED.CONFIRM.PRINT' | translate }}
              </span>
              <div ngbDropdownMenu aria-labelledby="dropdownPrintPaperSizes" class="text-center">
                <ng-container *ngFor="let printPaperSize of printPaperSizes$ | async">
                  <button class="dropdown-item py-1" (click)="print(printPaperSize.key)">
                    <i class="fa fa-print"></i>
                    <span class="mx-1"> {{ printPaperSize.name | localize: printPaperSize.nameEn | async }}</span>
                  </button>
                </ng-container>
              </div>
            </button>
            <button class="btn btn btn-raised btn-dark round" routerLink="/finances/journals">
              <i class="fa fa-undo"></i>
              <span class="mx-1">{{ 'FINANCES.JOURNALS.JOURNAL_DATA.JOURNALS_RETURNS' | translate }}</span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- journal lines details ends -->
  </div>
</div>
