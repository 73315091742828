import { Component, Input, OnDestroy, OnInit } from '@angular/core';

import { Observable, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';

import * as fromAuthStore from 'auth/store';
import { PosDevice } from 'sales/models';

@Component({
  selector: 'app-user-pos-devices-list',
  templateUrl: './user-pos-devices-list.component.html',
  styles: [],
})
export class UserPosDevicesListComponent implements OnInit, OnDestroy {
  /**
   * Shows or hide the pos devices list.
   */
  posDevicesListVisibility = false;

  /**
   * The default pos device of user.
   */
  userDefaultPosDevice: PosDevice;

  /**
   * The set of subscriptions on this components,
   * these subscriptions must be unsubscribed before this component got destroyed.
   */
  subscriptions = new Subscription();

  /**
   * @param authStore$ The auth-store.
   */
  constructor(private authStore$: Store<fromAuthStore.AuthState>) {}

  ngOnInit(): void {
    this.init();
  }

  /**
   * Destroy component data
   */
  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  /**
   * Initialize component data.
   */
  init() {
    /** Load current logged in user data. */
    this.subscriptions.add(
      this.authStore$
        .pipe(
          select(fromAuthStore.getSessionUserDefaultPosDevices),
          tap((userDefaultPosDevice) => {
            this.userDefaultPosDevice = userDefaultPosDevice;
          })
        )
        .subscribe()
    );
  }

  /**
   * Selects the newly selected pos device from the pos devices search list.
   * @param posDevice The list of newly selected pos devices to select the only one in the list.
   */
  selectPosDevice([posDevice]: PosDevice[]) {
    if (location) {
      this.userDefaultPosDevice = posDevice;
    }
  }
}
