import { Directive, HostListener, Inject } from '@angular/core';

import { SidebarLinkDirective } from 'core/directives/sidebar-link.directive';

@Directive({
  selector: '[appSidebarAnchorToggle]',
})
export class SidebarAnchorToggleDirective {
  protected navLink: SidebarLinkDirective;

  constructor(@Inject(SidebarLinkDirective) navLink: SidebarLinkDirective) {
    this.navLink = navLink;
  }

  @HostListener('click', ['$event'])
  onClick() {
    this.navLink.toggle();
  }
}
