import { BankAccountLogType } from 'lookups/models';

/**
 * Represents the bank account log types state.
 */
export class BankAccountLogTypeState {
  /**
   * The list of bank account log types.
   */
  public data: BankAccountLogType[];

  /**
   * An error that may resulting during processing some actions.
   */
  public error: any;

  /**
   * Determines if there is a running load process.
   */
  public isLoading: boolean;

  /**
   * Determines if the last load process has been ended successfully.
   */
  public isLoaded: boolean;
}
