import { User } from 'security/models';

/**
 * Represents a customer engagement value change log.
 */
export class CustomerEngagementValueChangeLog {
  /**
   * Gets or sets the id of the change log.
   */
  id: number;

  /**
   * Gets or sets the original engagement value before changing.
   */
  fromValue: number;

  /**
   * Gets or sets the newly changed engagement value.
   */
  toValue: number;

  /**
   * Gets or sets the id of the user created this log.
   */
  userId: number;

  /**
   * Gets or sets the user created this log.
   */
  user?: User;

  /**
   * Gets or sets the date-time on which the engagement value changed.
   */
  createdAt: Date;
}
