<!-- page header starts -->
<app-page-header [info]="pageInfo"></app-page-header>
<!-- page header ends -->

<!-- create journal form starts -->
<div class="row">
  <div class="col-md-12">
    <form [formGroup]="form" (ngSubmit)="submit()">
      <div class="card px-3 pt-3">
        <!-- journal starts -->
        <div class="card-header mb-3 pt-1 mt-3">
          <h4 class="card-title">{{ 'FINANCES.JOURNALS.JOURNALS_LINES' | translate }}</h4>
        </div>
        <!-- journal details starts -->
        <div class="card-content">
          <div class="px-3">
            <div class="row">
              <div class="form-group col-md-4">
                <label for="transactionDate">{{ 'FINANCES.JOURNALS.JOURNAL_DATA.JOURNAL_DATE' | translate }}</label>
                <div class="input-group round">
                  <input
                    class="form-control"
                    id="transactionDate"
                    name="transactionDate"
                    formControlName="transactionDate"
                    [placeholder]="'SHARED.SEARCH.DATE_PLACEHOLDER' | translate"
                    ngbDatepicker
                    #transactionDate="ngbDatepicker"
                  />
                  <div class="input-group-append">
                    <span
                      class="input-group-text gradient-light-blue-indigo"
                      style="cursor: pointer;"
                      (click)="transactionDate.toggle()"
                    >
                      <i class="fa fa-calendar"></i>
                    </span>
                  </div>
                </div>
              </div>
              <div class="form-group col-md-8">
                <label for="notes">{{ 'FINANCES.JOURNALS.JOURNAL_DATA.NOTES' | translate }}</label>
                <textarea
                  id="notes"
                  class="form-control round"
                  [placeholder]="'FINANCES.JOURNALS.JOURNAL_DATA.NOTES_PLACEHOLDER' | translate"
                  rows="1"
                  minlength="0"
                  maxlength="200"
                  formControlName="notes"
                ></textarea>
              </div>
            </div>
          </div>
        </div>
        <!-- journal details ends -->

        <!-- journal lines details starts -->
        <div class="card-header mb-3 pt-1">
          <h4 class="card-title">{{ 'SHARED.JOURNAL_DATA.JOURNAL_LINES' | translate }}</h4>
        </div>
        <div class="card-content">
          <div class="px-3">
            <app-journal-line-form
              [journalLinesForm]="journalLinesForm"
              [initialJournalLines]="initialJournalLinesSubject.asObservable() | async"
            ></app-journal-line-form>
          </div>

          <div class="row">
            <div class="form-group col-12 d-flex justify-content-between flex-column flex-sm-row mt-2">
              <button type="submit" class="btn btn-raised btn-success round" [disabled]="isCreating$ | async">
                <i class="fa fa-check"></i>
                <span class="mx-1">{{ 'SHARED.CONFIRM.SAVE' | translate }}</span>
              </button>
              <button class="btn btn-dark round" routerLink="/finances/journals">
                <i class="fa fa-undo"></i>
                <span class="mx-1">{{ 'FINANCES.JOURNALS.JOURNAL_DATA.JOURNALS_RETURNS' | translate }}</span>
              </button>
            </div>
          </div>
        </div>
        <!-- journal ends -->
      </div>
    </form>
  </div>
</div>
<!-- create journal form ends -->

<!-- confirm modal starts -->
<ng-template #confirmModalRef let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
      {{ 'FINANCES.JOURNALS.CONFIRM_CREATE_JOURNAL_TITLE' | translate }}
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="d()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p class="text-success">{{ 'FINANCES.JOURNALS.CONFIRM_CREATE_JOURNAL_MESSAGE' | translate }}</p>
  </div>
  <div class="modal-footer">
    <div class="modal-actions">
      <button type="button" class="btn btn-raised btn-success round" (click)="c(); confirm()" ngbAutofocus>
        <i class="fa fa-check"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.SAVE' | translate }}</span>
      </button>
      <button type="button" class="btn btn-outline-dark round" (click)="c()">
        <i class="fa fa-times"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.CANCEL' | translate }}</span>
      </button>
    </div>
  </div>
</ng-template>
<!-- confirm modal ends -->
