import { createSelector } from '@ngrx/store';

import { ClaimsState, SecurityState } from 'security/store/states';
import { selectSecurityState } from 'security/store/selectors/feature.selectors';

/* Selectors */

/**
 * Gets the claims state.
 */
const selectClaimsState = createSelector(selectSecurityState, (state: SecurityState) => state.claims);

/**
 * Gets the list of loaded claims groups.
 */
export const getClaimGroups = createSelector(selectClaimsState, (state: ClaimsState) => state.claimGroups);

/**
 * Gets the list of loaded current logged-in user claims groups.
 */
export const getCurrentLoggedInUserClaimGroups = createSelector(
  selectClaimsState,
  (state: ClaimsState) => state.currentLoggedInUserClaimGroups
);

/**
 * Gets the list of loaded service providers claims groups.
 */
export const getServiceProvidersClaimGroups = createSelector(selectClaimsState, (state: ClaimsState) =>
  state.claimGroups.filter((group) => group.isSystemClaimGroup)
);

/**
 * Gets the list of loaded organization users claims groups.
 */
export const getOrganizationUsersClaimGroups = createSelector(selectClaimsState, (state: ClaimsState) =>
  state.claimGroups.filter((group) => !group.isSystemClaimGroup)
);

/**
 * Gets the claims pagination info.
 */
export const getClaimsPaginationInfo = createSelector(selectClaimsState, (state: ClaimsState) => state.paginationInfo);

/**
 * Gets the error that occurred in the state.
 */
export const getClaimsError = createSelector(selectClaimsState, (state: ClaimsState) => state.error);

/**
 * Determines if the last process has been ended successfully.
 */
export const getClaimsGroupsCompleted = createSelector(
  selectClaimsState,
  (state: ClaimsState) => state.isGetClaimsGroupsCompleted
);

/**
 * Determines if there is a running process.
 */
export const gettingClaimsGroups = createSelector(
  selectClaimsState,
  (state: ClaimsState) => state.isGettingClaimsGroups
);

/**
 * Determines if the last current logged-in user claims groups process has been ended successfully.
 */
export const getCurrentLoggedInUserClaimsGroupsCompleted = createSelector(
  selectClaimsState,
  (state: ClaimsState) => state.isGetCurrentLoggedInUserClaimsGroupsCompleted
);

/**
 * Determines if there is a running process current logged-in user claims groups.
 */
export const gettingCurrentLoggedInUserClaimsGroups = createSelector(
  selectClaimsState,
  (state: ClaimsState) => state.isGettingClaimsGroups
);
