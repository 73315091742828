import { PaginationInfo } from 'shared/models';
import { Employee } from 'hr/models';

/**
 * Represents the employees state.
 */
export class EmployeesState {
  /**
   * The list of employees.
   */
  public data: Employee[];

  /**
   * The list of sales persons.
   */
  public salesPersonsData: Employee[];

  /**
   * The pagination info for the employees.
   */
  public paginationInfo: PaginationInfo;

  /**
   * The pagination info for the sales persons.
   */
  public salesPersonsPaginationInfo: PaginationInfo;

  /**
   * An error that may resulting during processing some actions.
   */
  public error: any;

  /**
   * Determines if there is a running search process.
   */
  isSearching: boolean;

  /**
   * Determines if the last search process has been ended successfully.
   */
  isSearchCompleted: boolean;

  /**
   * Determines if there is a running search process.
   */
  isSalesPersonsSearching: boolean;

  /**
   * Determines if the last search process has been ended successfully.
   */
  isSalesPersonsSearchCompleted: boolean;
}
