import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';

import { AppHttpResponse } from 'shared';
import { PurchaseInvoice, PurchaseReturn, VendorLog } from 'purchases/models';
import { CustomerLog, SaleInvoice, SaleReturn } from 'sales/models';
import { IncomingStock, OutgoingStock, TransferStock } from 'stocks/models';
import { BankAccountLog, PayrollPaymentRefund, PettyCashRefund, PettyCashRefundRequest } from 'finances/models';
import { Asset, AssetDepreciation, AssetSaleInvoice, ExcludedAsset } from 'assets/models';
import { AccrualPayroll, EmployeeLoan, PayrollPayment, GovServiceRequest } from 'hr/models';

/**
 * The journal association services includes functionality to findById for an vendor log,purchase invoice.
 */
@Injectable()
export class JournalAssociationService {
  /**
   * The relative route for the journal association.
   *
   * No leading or trailing slashes required.
   */
  private journalAssociationApi = 'finances/journals/associations';

  constructor(private http: HttpClient) {}

  /**
   * Finds the journal association vendor log with the given id.
   * @param id The id of the vendor log.
   */
  public findJournalAssociationVendorLog(id: number): Observable<AppHttpResponse<VendorLog>> {
    return this.http.get<AppHttpResponse<VendorLog>>(`${this.journalAssociationApi}/vendor-logs/${id}`);
  }

  /**
   * Finds the journal association purchase invoice with the given id.
   * @param id The id of the purchase invoice.
   */
  public findJournalAssociationPurchaseInvoice(id: number): Observable<AppHttpResponse<PurchaseInvoice>> {
    return this.http.get<AppHttpResponse<PurchaseInvoice>>(`${this.journalAssociationApi}/purchase-invoices/${id}`);
  }

  /**
   * Finds the journal association purchase return with the given id.
   * @param id The id of the purchase return.
   */
  public findJournalAssociationPurchaseReturn(id: number): Observable<AppHttpResponse<PurchaseReturn>> {
    return this.http.get<AppHttpResponse<PurchaseReturn>>(`${this.journalAssociationApi}/purchase-returns/${id}`);
  }

  /**
   * Finds the journal association customer log with the given id.
   * @param id The id of the customer log.
   */
  public findJournalAssociationCustomerLog(id: number): Observable<AppHttpResponse<CustomerLog>> {
    return this.http.get<AppHttpResponse<CustomerLog>>(`${this.journalAssociationApi}/customer-logs/${id}`);
  }

  /**
   * Finds the journal association sale invoice with the given id.
   * @param id The id of the sale invoice.
   */
  public findJournalAssociationSaleInvoice(id: number): Observable<AppHttpResponse<SaleInvoice>> {
    return this.http.get<AppHttpResponse<SaleInvoice>>(`${this.journalAssociationApi}/sale-invoices/${id}`);
  }

  /**
   * Finds the journal association sale return with the given id.
   * @param id The id of the sale return.
   */
  public findJournalAssociationSaleReturn(id: number): Observable<AppHttpResponse<SaleReturn>> {
    return this.http.get<AppHttpResponse<SaleReturn>>(`${this.journalAssociationApi}/sale-returns/${id}`);
  }

  /**
   * Finds the journal association transfer stock with the given id.
   * @param id The id of the transfer stock.
   */
  public findJournalAssociationTransferStock(id: number): Observable<AppHttpResponse<TransferStock>> {
    return this.http.get<AppHttpResponse<TransferStock>>(`${this.journalAssociationApi}/transfer-stocks/${id}`);
  }

  /**
   * Finds the journal association incoming stock with the given id.
   * @param id The id of the incoming stock.
   */
  public findJournalAssociationIncomingStock(id: number): Observable<AppHttpResponse<IncomingStock>> {
    return this.http.get<AppHttpResponse<IncomingStock>>(`${this.journalAssociationApi}/incoming-stocks/${id}`);
  }

  /**
   * Finds the journal association outgoing stock with the given id.
   * @param id The id of the outgoing stock.
   */
  public findJournalAssociationOutgoingStock(id: number): Observable<AppHttpResponse<OutgoingStock>> {
    return this.http.get<AppHttpResponse<OutgoingStock>>(`${this.journalAssociationApi}/outgoing-stocks/${id}`);
  }

  /**
   * Finds the journal association petty cash refund with the given id.
   * @param id The id of the petty cash refund.
   */
  public findJournalAssociationPettyCashRefund(id: number): Observable<AppHttpResponse<PettyCashRefund>> {
    return this.http.get<AppHttpResponse<PettyCashRefund>>(`${this.journalAssociationApi}/petty-cash-refunds/${id}`);
  }

  /**
   * Finds the journal association petty cash refund request with the given id.
   * @param id The id of the petty cash refund request.
   */
  public findJournalAssociationPettyCashRefundRequest(id: number): Observable<AppHttpResponse<PettyCashRefundRequest>> {
    return this.http.get<AppHttpResponse<PettyCashRefundRequest>>(
      `${this.journalAssociationApi}/petty-cash-refund-requests/${id}`
    );
  }

  /**
   * Finds the journal association asset with the given id.
   * @param id The id of the asset.
   */
  public findJournalAssociationAsset(id: number): Observable<AppHttpResponse<Asset>> {
    return this.http.get<AppHttpResponse<Asset>>(`${this.journalAssociationApi}/assets/${id}`);
  }

  /**
   * Finds the journal association bank account log with the given id.
   * @param id The id of the bank account log.
   */
  public findJournalAssociationBankAccountLog(id: number): Observable<AppHttpResponse<BankAccountLog>> {
    return this.http.get<AppHttpResponse<BankAccountLog>>(`${this.journalAssociationApi}/bank-account-logs/${id}`);
  }

  /**
   * Finds the journal association asset depreciation with the given id.
   * @param id The id of the asset depreciation.
   */
  public findJournalAssociationAssetDepreciation(id: number): Observable<AppHttpResponse<AssetDepreciation>> {
    return this.http.get<AppHttpResponse<AssetDepreciation>>(`${this.journalAssociationApi}/asset-depreciations/${id}`);
  }

  /**
   * Finds the journal association asset sale invoice with the given id.
   * @param id The id of the asset sale invoice.
   */
  public findJournalAssociationAssetSaleInvoice(id: number): Observable<AppHttpResponse<AssetSaleInvoice>> {
    return this.http.get<AppHttpResponse<AssetSaleInvoice>>(`${this.journalAssociationApi}/asset-sale-invoices/${id}`);
  }

  /**
   * Finds the journal association excluded asset with the given id.
   * @param id The id of the excluded asset.
   */
  public findJournalAssociationExcludedAsset(id: number): Observable<AppHttpResponse<ExcludedAsset>> {
    return this.http.get<AppHttpResponse<ExcludedAsset>>(`${this.journalAssociationApi}/excluded-assets/${id}`);
  }

  /**
   * Finds the journal association accrual payroll with the given id.
   * @param id The id of the accrual payroll.
   */
  public findJournalAssociationAccrualPayroll(id: number): Observable<AppHttpResponse<AccrualPayroll>> {
    return this.http.get<AppHttpResponse<AccrualPayroll>>(`${this.journalAssociationApi}/accrual-payrolls/${id}`);
  }

  /**
   * Finds the journal association payroll payment refund with the given id.
   * @param id The id of the payroll payment refund.
   */
  public findJournalAssociationPayrollPaymentRefund(id: number): Observable<AppHttpResponse<PayrollPaymentRefund>> {
    return this.http.get<AppHttpResponse<PayrollPaymentRefund>>(
      `${this.journalAssociationApi}/payroll-payment-refunds/${id}`
    );
  }

  /**
   * Finds the journal association gov service request with the given id.
   * @param id The id of the gov service request.
   */
  public findJournalAssociationGovServiceRequest(id: number): Observable<AppHttpResponse<GovServiceRequest>> {
    return this.http.get<AppHttpResponse<GovServiceRequest>>(
      `${this.journalAssociationApi}/gov-service-requests/${id}`
    );
  }

  /**
   * Finds the journal association payroll payment with the given id.
   * @param id The id of the payroll payment.
   */
  public findJournalAssociationPayrollPayment(id: number): Observable<AppHttpResponse<PayrollPayment>> {
    return this.http.get<AppHttpResponse<PayrollPayment>>(`${this.journalAssociationApi}/payroll-payments/${id}`);
  }

  /**
   * Finds the journal association employee loan with the given id.
   * @param id The id of the employee loan.
   */
  public findJournalAssociationEmployeeLoan(id: number): Observable<AppHttpResponse<EmployeeLoan>> {
    return this.http.get<AppHttpResponse<EmployeeLoan>>(`${this.journalAssociationApi}/employee-loans/${id}`);
  }
}
