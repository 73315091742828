import { createSelector } from '@ngrx/store';

import { UnitOfMeasureClassesState, StoresState } from 'stores/store/states';
import { selectStoresState } from 'stores/store/selectors/feature.selectors';

/**
 * Gets the unit of measure classes state.
 */
const selectUnitOfMeasureClassesState = createSelector(
  selectStoresState,
  (state: StoresState) => state.unitOfMeasureClasses
);

/**
 * Gets the list of loaded unit of measure classes.
 */
export const getUnitOfMeasureClasses = createSelector(
  selectUnitOfMeasureClassesState,
  (state: UnitOfMeasureClassesState) => state.data
);

/**
 * Gets the unit of measure classes pagination info.
 */
export const getUnitOfMeasureClassesPaginationInfo = createSelector(
  selectUnitOfMeasureClassesState,
  (state: UnitOfMeasureClassesState) => state.paginationInfo
);

/**
 * Gets the error that occurred in the state.
 */
export const getUnitOfMeasureClassesError = createSelector(
  selectUnitOfMeasureClassesState,
  (state: UnitOfMeasureClassesState) => state.error
);

/**
 * Determines if there is a running search process.
 */
export const getUnitOfMeasureClassesSearching = createSelector(
  selectUnitOfMeasureClassesState,
  (state: UnitOfMeasureClassesState) => state.isSearching
);

/**
 * Determines if the last search process has been ended successfully.
 */
export const getUnitOfMeasureClassesSearchCompleted = createSelector(
  selectUnitOfMeasureClassesState,
  (state: UnitOfMeasureClassesState) => state.isSearchCompleted
);

/**
 * Gets the selected unit of measure class.
 */
export const getSelectedUnitOfMeasureClass = createSelector(
  selectUnitOfMeasureClassesState,
  (state: UnitOfMeasureClassesState) => state.selectedUnitOfMeasureClass
);

/**
 * Determines if there is a running find unit of measure class process.
 */
export const getUnitOfMeasureClassFinding = createSelector(
  selectUnitOfMeasureClassesState,
  (state: UnitOfMeasureClassesState) => state.isFinding
);

/**
 * Determines if the last find unit of measure class process has been ended successfully.
 */
export const getUnitOfMeasureClassFindCompleted = createSelector(
  selectUnitOfMeasureClassesState,
  (state: UnitOfMeasureClassesState) => state.isFindCompleted
);

/**
 * Determines if there is a running create process.
 */
export const getSelectedUnitOfMeasureClassCreating = createSelector(
  selectUnitOfMeasureClassesState,
  (state: UnitOfMeasureClassesState) => state.isCreating
);

/**
 * Determines if the last create process has been ended successfully.
 */
export const getSelectedUnitOfMeasureClassCreateCompleted = createSelector(
  selectUnitOfMeasureClassesState,
  (state: UnitOfMeasureClassesState) => state.isCreateCompleted
);

/**
 * Determines if there is a running update process.
 */
export const getSelectedUnitOfMeasureClassUpdating = createSelector(
  selectUnitOfMeasureClassesState,
  (state: UnitOfMeasureClassesState) => state.isUpdating
);

/**
 * Determines if the last update process has been ended successfully.
 */
export const getSelectedUnitOfMeasureClassUpdateCompleted = createSelector(
  selectUnitOfMeasureClassesState,
  (state: UnitOfMeasureClassesState) => state.isUpdateCompleted
);

/**
 * Determines if there is a running delete process.
 */
export const getSelectedUnitOfMeasureClassDeleting = createSelector(
  selectUnitOfMeasureClassesState,
  (state: UnitOfMeasureClassesState) => state.isDeleting
);

/**
 * Determines if the last delete process has been ended successfully.
 */
export const getSelectedUnitOfMeasureClassDeleteCompleted = createSelector(
  selectUnitOfMeasureClassesState,
  (state: UnitOfMeasureClassesState) => state.isDeleteCompleted
);
