import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { ProjectSubTask } from 'projects-management/models';
import { DateUtil } from 'shared';

@Component({
  selector: 'app-project-sub-task',
  templateUrl: './project-sub-task.component.html',
  styles: [],
})
export class ProjectSubTaskComponent {
  /**
   * Sets the project sub task form-group.
   */
  @Input() projectSubTaskForm: FormGroup;

  /**
   * Sets the project sub task values.
   */
  @Input() isUpdated: boolean;

  /**
   * Gets the cost value.
   */
  cost: number;

  /**
   * Gets the remaining budget value.
   */
  remainingBudget: number;

  /**
   * Sets the currently edited project sub task.
   */
  @Input() set projectSubTask(value: ProjectSubTask) {
    this.projectSubTaskObj = value;

    /** Update form data. */
    if (value) {
      const startDate = DateUtil.toDatePickerDate(value.startDate);
      const endDate = DateUtil.toDatePickerDate(value.endDate);
      this.cost = value.cost;
      this.remainingBudget = value.remainingBudget;

      this.projectSubTaskForm.patchValue({ ...value, startDate, endDate });
    } else {
      this.projectSubTaskForm.reset();
    }
  }

  /**
   * Gets or sets the currently edited project sub task.
   */
  projectSubTaskObj: ProjectSubTask;
}
