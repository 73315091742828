import { Component, Input } from '@angular/core';

import { PayrollPaymentRefund } from 'finances/models';

@Component({
  selector: 'app-journal-payroll-payment-refund-details',
  templateUrl: './journal-payroll-payment-refund-details.component.html',
  styles: [],
})
export class JournalPayrollPaymentRefundDetailsComponent {
  /**
   * Sets the current viewed journal payroll payment refund.
   */
  @Input() payrollPaymentRefund: PayrollPaymentRefund;
}
