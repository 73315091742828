import { CreateCostCenterComponent } from 'finances/components/cost-centers/create-cost-center/create-cost-center.component';
import { CreateCostCenterInModalComponent } from 'finances/components/cost-centers/create-cost-center-in-modal/create-cost-center-in-modal.component';
import { UpdateCostCenterComponent } from 'finances/components/cost-centers/update-cost-center/update-cost-center.component';
import { CostCenterListComponent } from 'finances/components/cost-centers/cost-center-list/cost-center-list.component';
import { CostCentersSearchComponent } from 'finances/components/cost-centers/cost-centers-search/cost-centers-search.component';
import { CostCentersFormComponent } from 'finances/components/cost-centers/cost-centers-form/cost-centers-form.component';

export * from 'finances/components/cost-centers/create-cost-center/create-cost-center.component';
export * from 'finances/components/cost-centers/create-cost-center-in-modal/create-cost-center-in-modal.component';
export * from 'finances/components/cost-centers/update-cost-center/update-cost-center.component';
export * from 'finances/components/cost-centers/cost-center-list/cost-center-list.component';
export * from 'finances/components/cost-centers/cost-centers-search/cost-centers-search.component';
export * from 'finances/components/cost-centers/cost-centers-form/cost-centers-form.component';

/**
 * The set of components registered on this sub-module.
 */
export const COMPONENTS: any[] = [
  CreateCostCenterComponent,
  CreateCostCenterInModalComponent,
  UpdateCostCenterComponent,
  CostCenterListComponent,
  CostCentersSearchComponent,
  CostCentersFormComponent,
];
