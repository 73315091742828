<!-- paid payrolls list starts -->
<div class="row">
  <div class="col-md-12">
    <div class="card">
      <div class="card-content">
        <div class="px-3">
          <div class="row">
            <div class="col-md-12">
              <form class="row" [formGroup]="searchForm" (ngSubmit)="searchPayrolls()">
                <div class="form-group col-md-4">
                  <label for="employees">{{
                    'FINANCES.HR.PAID_PAYROLLS.PAID_PAYROLLS_DATA.EMPLOYEES' | translate
                  }}</label>
                  <ng-select
                    class="round"
                    formControlName="employees"
                    [multiple]="true"
                    [closeOnSelect]="false"
                    labelForId="employees"
                    searchable="true"
                    [placeholder]="'FINANCES.HR.PAID_PAYROLLS.PAID_PAYROLLS_DATA.EMPLOYEES_PLACEHOLDER' | translate"
                    [notFoundText]="'SHARED.SEARCH.SEARCH_NOT_FOUND' | translate"
                    (open)="employeesListVisibility = true"
                  >
                    <ng-option *ngFor="let employee of employees" [value]="employee.id">
                      {{
                        employee.employeePersonalDetail.fullName
                          | localize: employee.employeePersonalDetail.fullNameEn
                          | async
                      }}
                    </ng-option>
                  </ng-select>
                </div>
                <div class="form-group col-md-4">
                  <label for="departments">{{
                    'FINANCES.HR.PAID_PAYROLLS.PAID_PAYROLLS_DATA.DEPARTMENTS' | translate
                  }}</label>
                  <ng-select
                    class="round"
                    formControlName="departments"
                    [multiple]="true"
                    [closeOnSelect]="false"
                    labelForId="departments"
                    searchable="true"
                    [placeholder]="'FINANCES.HR.PAID_PAYROLLS.PAID_PAYROLLS_DATA.DEPARTMENTS_PLACEHOLDER' | translate"
                    [notFoundText]="'SHARED.SEARCH.SEARCH_NOT_FOUND' | translate"
                    (open)="departmentsListVisibility = true"
                  >
                    <ng-option *ngFor="let department of departments" [value]="department.id">
                      {{ department.name | localize: department.nameEn | async }}
                    </ng-option>
                  </ng-select>
                </div>
                <div class="form-group col-md-4">
                  <label for="locations">{{
                    'FINANCES.HR.PAID_PAYROLLS.PAID_PAYROLLS_DATA.LOCATIONS' | translate
                  }}</label>
                  <ng-select
                    class="round"
                    formControlName="locations"
                    [multiple]="true"
                    [closeOnSelect]="false"
                    labelForId="locations"
                    searchable="true"
                    [placeholder]="'FINANCES.HR.PAID_PAYROLLS.PAID_PAYROLLS_DATA.LOCATIONS_PLACEHOLDER' | translate"
                    [notFoundText]="'SHARED.SEARCH.SEARCH_NOT_FOUND' | translate"
                    (open)="locationsListVisibility = true"
                  >
                    <ng-option *ngFor="let location of locations" [value]="location.id">
                      {{ location.name | localize: location.nameEn | async }}
                    </ng-option>
                  </ng-select>
                </div>
                <div class="col-md-8">
                  <div class="row">
                    <div class="form-group col-12 col-sm-6 mb-0">
                      <label for="fromDate">{{ 'SHARED.SEARCH.FROM_DATE' | translate }}</label>
                      <div class="input-group round">
                        <input
                          class="form-control"
                          id="fromDate"
                          name="fromDate"
                          formControlName="fromDate"
                          [placeholder]="'SHARED.SEARCH.DATE_PLACEHOLDER' | translate"
                          ngbDatepicker
                          #fromDate="ngbDatepicker"
                        />
                        <div class="input-group-append">
                          <span
                            class="input-group-text gradient-light-blue-indigo"
                            style="cursor: pointer;"
                            (click)="fromDate.toggle()"
                          >
                            <i class="fa fa-calendar"></i>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="form-group col-12 col-sm-6 mb-0">
                      <label for="toDate">{{ 'SHARED.SEARCH.TO_DATE' | translate }}</label>
                      <div class="input-group round">
                        <input
                          class="form-control"
                          id="toDate"
                          name="toDate"
                          formControlName="toDate"
                          [placeholder]="'SHARED.SEARCH.DATE_PLACEHOLDER' | translate"
                          ngbDatepicker
                          #toDate="ngbDatepicker"
                        />
                        <div class="input-group-append">
                          <span
                            class="input-group-text gradient-light-blue-indigo"
                            style="cursor: pointer;"
                            (click)="toDate.toggle()"
                          >
                            <i class="fa fa-calendar"></i>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-auto form-group d-flex">
                  <button
                    type="submit"
                    class="btn btn-raised btn-primary align-self-end round mx-1 mb-0"
                    [disabled]="isSearching$ | async"
                  >
                    <i class="fa fa-search"></i>
                  </button>
                  <app-export-tools
                    *ngIf="(payrolls$ | async)?.length"
                    class="d-flex align-self-end mx-1"
                    targetElementId="payrolls-table-export-target-element"
                    [outputFileName]="'SHARED.EXPORT_TABLES.PAYROLLS_TABLE' | translate"
                  ></app-export-tools>
                </div>
              </form>
            </div>
            <div class="col-md-12">
              <app-payrolls-list
                [payrolls]="payrolls$ | async"
                [targetElementId]="'payrolls-table-export-target-element'"
                [paginationInfo$]="paginationInfo$"
                [isEmployeeManagement]="true"
              ></app-payrolls-list>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- paid payrolls list ends -->

<!-- employees search starts -->
<employees-search [(visible)]="employeesListVisibility" (employees)="selectEmployees($event)"> </employees-search>
<!-- employees search ends -->

<!-- departments search starts -->
<departments-search [(visible)]="departmentsListVisibility" (departments)="selectDepartments($event)">
</departments-search>
<!-- departments search ends -->

<!-- locations search starts -->
<locations-search [(visible)]="locationsListVisibility" (locations)="selectLocations($event)"> </locations-search>
<!-- locations search ends -->
