import { createSelector } from '@ngrx/store';

import { selectLookupsState } from 'lookups/store/selectors/feature.selectors';
import { LookupsState, PayrollElementClassificationState } from 'lookups/store/states';

/**
 * Gets the payroll element classification state.
 */
const selectPayrollElementClassifications = createSelector(
  selectLookupsState,
  (state: LookupsState) => state.payrollElementClassifications
);

/**
 * Gets the list of loaded payroll element classifications.
 */
export const getPayrollElementClassifications = createSelector(
  selectPayrollElementClassifications,
  (state: PayrollElementClassificationState) => state.data
);

/**
 * Gets the error that occurred in the state.
 */
export const getPayrollElementClassificationsError = createSelector(
  selectPayrollElementClassifications,
  (state: PayrollElementClassificationState) => state.error
);

/**
 * Determines if there is a running load process.
 */
export const getPayrollElementClassificationsIsLoading = createSelector(
  selectPayrollElementClassifications,
  (state: PayrollElementClassificationState) => state.isLoading
);

/**
 * Determines if the last load process has been ended successfully.
 */
export const getPayrollElementClassificationsIsLoaded = createSelector(
  selectPayrollElementClassifications,
  (state: PayrollElementClassificationState) => state.isLoaded
);
