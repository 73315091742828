import { Variant } from 'stores/models';
import { PaginationInfo } from 'shared/models';

/**
 * Represents the variants state.
 */
export class VariantState {
  /**
   * The list of variants.
   */
  public data: Variant[];

  /**
   * The pagination info for the variants.
   */
  public paginationInfo: PaginationInfo;

  /**
   * An error that may resulting during processing some actions.
   */
  public error: any;

  /**
   * Determines if there is a running search process.
   */
  public isSearching: boolean;

  /**
   * Determines if the last search process has been ended successfully.
   */
  public isSearchCompleted: boolean;

  /**
   * The current selected variant.
   */
  public selectedVariant: Variant;

  /**
   * Determines if there is a running find-variant process.
   */
  public isFinding: boolean;

  /**
   * Determines if the last find-variant process has been ended successfully.
   */
  public isFindCompleted: boolean;

  /**
   * Determines if there is a running create process.
   */
  public isCreating: boolean;

  /**
   * Determines if the last create process has been ended successfully.
   */
  public isCreateCompleted: boolean;

  /**
   * Determines if there is a running update process.
   */
  public isUpdating: boolean;

  /**
   * Determines if the last update process has been ended successfully.
   */
  public isUpdateCompleted: boolean;

  /**
   * Determines if there is a running delete process.
   */
  public isDeleting: boolean;

  /**
   * Determines if the last delete process has been ended successfully.
   */
  public isDeleteCompleted: boolean;
}
