import { createSelector } from '@ngrx/store';

import { CRMState, QuotationsState } from 'crm/store/states';
import { selectCRMState } from 'crm/store/selectors/feature.selectors';

/**
 * Gets the quotations state.
 */
const selectQuotationsState = createSelector(selectCRMState, (state: CRMState) => state.quotations);

/**
 * Gets the list of loaded quotations.
 */
export const getQuotations = createSelector(selectQuotationsState, (state: QuotationsState) => state.data);

/**
 * Gets the quotations pagination info.
 */
export const getQuotationsPaginationInfo = createSelector(
  selectQuotationsState,
  (state: QuotationsState) => state.paginationInfo
);

/**
 * Gets the error that occurred in the state.
 */
export const getQuotationsError = createSelector(selectQuotationsState, (state: QuotationsState) => state.error);

/**
 * Determines if there is a running search process.
 */
export const getQuotationsSearching = createSelector(
  selectQuotationsState,
  (state: QuotationsState) => state.isSearching
);

/**
 * Determines if the last search process has been ended successfully.
 */
export const getQuotationsSearchCompleted = createSelector(
  selectQuotationsState,
  (state: QuotationsState) => state.isSearchCompleted
);

/**
 * Gets the selected quotation.
 */
export const getSelectedQuotation = createSelector(
  selectQuotationsState,
  (state: QuotationsState) => state.selectedQuotation
);

/**
 * Determines if there is a running find-quotation process.
 */
export const getQuotationsFinding = createSelector(selectQuotationsState, (state: QuotationsState) => state.isFinding);

/**
 * Determines if the last find-quotation process has been ended successfully.
 */
export const getQuotationsFindCompleted = createSelector(
  selectQuotationsState,
  (state: QuotationsState) => state.isFindCompleted
);

/**
 * Determines if there is a running create process.
 */
export const getSelectedQuotationCreating = createSelector(
  selectQuotationsState,
  (state: QuotationsState) => state.isCreating
);

/**
 * Determines if the last create process has been ended successfully.
 */
export const getSelectedQuotationCreateCompleted = createSelector(
  selectQuotationsState,
  (state: QuotationsState) => state.isCreateCompleted
);

/**
 * Determines if there is a running reject process.
 */
export const getQuotationRejecting = createSelector(
  selectQuotationsState,
  (state: QuotationsState) => state.isRejecting
);

/**
 * Determines if the last cancel process has been ended successfully.
 */
export const getQuotationRejectCompleted = createSelector(
  selectQuotationsState,
  (state: QuotationsState) => state.isRejectCompleted
);
