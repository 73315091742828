import { Action } from '@ngrx/store';

import { AppHttpResponse } from 'shared';
import { ProjectResourceLine } from 'projects-management/models';

/**
 * The projects-management-module store project resources action types.
 */
export enum ProjectResourcesActionType {
  FIND_PROJECT_RESOURCE_LINES = '[ProjectsManagement] [ProjectResources] Find Project Resource Lines',
  FIND_PROJECT_RESOURCE_LINES_FAIL = '[ProjectsManagement] [ProjectResources] Find Project Resource Lines Fail',
  FIND_PROJECT_RESOURCE_LINES_SUCCESS = '[ProjectsManagement] [ProjectResources] Find Project Resource Lines Success',

  FIND_PROJECT_RESOURCE_PURCHASE_RETURN_LINES = '[ProjectsManagement] [ProjectResources] Find Project Resource Purchase Return Lines',
  FIND_PROJECT_RESOURCE_PURCHASE_RETURN_LINES_FAIL = '[ProjectsManagement] [ProjectResources] Find Project Resource Purchase Return Lines Fail',
  FIND_PROJECT_RESOURCE_PURCHASE_RETURN_LINES_SUCCESS = '[ProjectsManagement] [ProjectResources] Find Project Resource Purchase Return Lines Success',

  FIND_PROJECT_RESOURCE_PURCHASE_INVOICE_LINES = '[ProjectsManagement] [ProjectResources] Find Project Resource Purchase Invoice Lines',
  FIND_PROJECT_RESOURCE_PURCHASE_INVOICE_LINES_FAIL = '[ProjectsManagement] [ProjectResources] Find Project Resource Purchase Invoice Lines Fail',
  FIND_PROJECT_RESOURCE_PURCHASE_INVOICE_LINES_SUCCESS = '[ProjectsManagement] [ProjectResources] Find Project Resource Purchase Invoice Lines Success',

  FIND_PROJECT_RESOURCE_OUTGOING_STOCK_LINES = '[ProjectsManagement] [ProjectResources] Find Project Resource Outgoing Stock Lines',
  FIND_PROJECT_RESOURCE_OUTGOING_STOCK_LINES_FAIL = '[ProjectsManagement] [ProjectResources] Find Project Resource Outgoing Stock Lines Fail',
  FIND_PROJECT_RESOURCE_OUTGOING_STOCK_LINES_SUCCESS = '[ProjectsManagement] [ProjectResources] Find Project Resource Outgoing Stock Lines Success',

  FIND_PROJECT_RESOURCE_ASSET_DEPRECIATION_LINES = '[ProjectsManagement] [ProjectResources] Find Project Resource Asset Depreciation Lines',
  FIND_PROJECT_RESOURCE_ASSET_DEPRECIATION_LINES_FAIL = '[ProjectsManagement] [ProjectResources] Find Project Resource Asset Depreciation Lines Fail',
  FIND_PROJECT_RESOURCE_ASSET_DEPRECIATION_LINES_SUCCESS = '[ProjectsManagement] [ProjectResources] Find Project Resource Asset Depreciation Lines Success',

  FIND_PROJECT_RESOURCE_PAYROLL_LINES = '[ProjectsManagement] [ProjectResources] Find Project Resource Payroll Lines',
  FIND_PROJECT_RESOURCE_PAYROLL_LINES_FAIL = '[ProjectsManagement] [ProjectResources] Find Project Resource Payroll Lines Fail',
  FIND_PROJECT_RESOURCE_PAYROLL_LINES_SUCCESS = '[ProjectsManagement] [ProjectResources] Find Project Resource Payroll Lines Success',
}

/**
 * Represents a store find project resource lines action.
 */
export class FindProjectResourceLines implements Action {
  /**
   * The type of the action.
   */
  readonly type = ProjectResourcesActionType.FIND_PROJECT_RESOURCE_LINES;

  /**
   * Fires a new find project action.
   * @param payload the id of the project.
   */
  constructor(public payload: number) {}
}

/**
 * Represents a store find project resource lines fail action.
 */
export class FindProjectResourceLinesFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = ProjectResourcesActionType.FIND_PROJECT_RESOURCE_LINES_FAIL;

  /**
   * Fires a new find project fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store find project resource lines success action.
 */
export class FindProjectResourceLinesSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = ProjectResourcesActionType.FIND_PROJECT_RESOURCE_LINES_SUCCESS;

  /**
   * Fires a new find project resource lines success action.
   * @param payload The loaded project resource lines.
   */
  constructor(public payload: AppHttpResponse<ProjectResourceLine[]>) {}
}

/**
 * Represents a store find project resource purchase return lines action.
 */
export class FindProjectResourcePurchaseReturnLines implements Action {
  /**
   * The type of the action.
   */
  readonly type = ProjectResourcesActionType.FIND_PROJECT_RESOURCE_PURCHASE_RETURN_LINES;

  /**
   * Fires a new find project resource purchase return lines action.
   * @param payload the id of the project resource purchase return lines.
   */
  constructor(public payload: number) {}
}

/**
 * Represents a store find project resource purchase return lines fail action.
 */
export class FindProjectResourcePurchaseReturnLinesFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = ProjectResourcesActionType.FIND_PROJECT_RESOURCE_PURCHASE_RETURN_LINES_FAIL;

  /**
   * Fires a new find project resource purchase return lines fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store find project resource purchase return lines success action.
 */
export class FindProjectResourcePurchaseReturnLinesSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = ProjectResourcesActionType.FIND_PROJECT_RESOURCE_PURCHASE_RETURN_LINES_SUCCESS;

  /**
   * Fires a new find project resource purchase return lines success action.
   * @param payload The loaded project resource purchase return lines.
   */
  constructor(public payload: AppHttpResponse<ProjectResourceLine[]>) {}
}

/**
 * Represents a store find project resource purchase invoice lines action.
 */
export class FindProjectResourcePurchaseInvoiceLines implements Action {
  /**
   * The type of the action.
   */
  readonly type = ProjectResourcesActionType.FIND_PROJECT_RESOURCE_PURCHASE_INVOICE_LINES;

  /**
   * Fires a new find project resource purchase invoice lines action.
   * @param payload the id of the project resource purchase invoice lines.
   */
  constructor(public payload: number) {}
}

/**
 * Represents a store find project resource purchase invoice lines fail action.
 */
export class FindProjectResourcePurchaseInvoiceLinesFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = ProjectResourcesActionType.FIND_PROJECT_RESOURCE_PURCHASE_INVOICE_LINES_FAIL;

  /**
   * Fires a new find project resource purchase invoice lines fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store find project resource purchase invoice lines success action.
 */
export class FindProjectResourcePurchaseInvoiceLinesSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = ProjectResourcesActionType.FIND_PROJECT_RESOURCE_PURCHASE_INVOICE_LINES_SUCCESS;

  /**
   * Fires a new find project resource purchase invoice lines success action.
   * @param payload The loaded project resource purchase invoice lines.
   */
  constructor(public payload: AppHttpResponse<ProjectResourceLine[]>) {}
}

/**
 * Represents a store find project resource outgoing stock lines action.
 */
export class FindProjectResourceOutgoingStockLines implements Action {
  /**
   * The type of the action.
   */
  readonly type = ProjectResourcesActionType.FIND_PROJECT_RESOURCE_OUTGOING_STOCK_LINES;

  /**
   * Fires a new find project resource outgoing stock lines action.
   * @param payload the id of the project resource outgoing stock lines.
   */
  constructor(public payload: number) {}
}

/**
 * Represents a store find project resource outgoing stock lines fail action.
 */
export class FindProjectResourceOutgoingStockLinesFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = ProjectResourcesActionType.FIND_PROJECT_RESOURCE_OUTGOING_STOCK_LINES_FAIL;

  /**
   * Fires a new find project resource outgoing stock lines fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store find project resource outgoing stock lines success action.
 */
export class FindProjectResourceOutgoingStockLinesSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = ProjectResourcesActionType.FIND_PROJECT_RESOURCE_OUTGOING_STOCK_LINES_SUCCESS;

  /**
   * Fires a new find project resource outgoing stock lines success action.
   * @param payload The loaded project resource outgoing stock lines.
   */
  constructor(public payload: AppHttpResponse<ProjectResourceLine[]>) {}
}

/**
 * Represents a store find project resource asset lines action.
 */
export class FindProjectResourceAssetDepreciationLines implements Action {
  /**
   * The type of the action.
   */
  readonly type = ProjectResourcesActionType.FIND_PROJECT_RESOURCE_ASSET_DEPRECIATION_LINES;

  /**
   * Fires a new find project resource asset lines action.
   * @param payload the id of the project resource asset lines.
   */
  constructor(public payload: number) {}
}

/**
 * Represents a store find project resource asset lines fail action.
 */
export class FindProjectResourceAssetDepreciationLinesFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = ProjectResourcesActionType.FIND_PROJECT_RESOURCE_ASSET_DEPRECIATION_LINES_FAIL;

  /**
   * Fires a new find project resource asset lines fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store find project resource asset lines success action.
 */
export class FindProjectResourceAssetDepreciationLinesSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = ProjectResourcesActionType.FIND_PROJECT_RESOURCE_ASSET_DEPRECIATION_LINES_SUCCESS;

  /**
   * Fires a new find project resource asset lines success action.
   * @param payload The loaded project resource asset lines.
   */
  constructor(public payload: AppHttpResponse<ProjectResourceLine[]>) {}
}

/**
 * Represents a store find project resource payroll lines action.
 */
export class FindProjectResourcePayrollLines implements Action {
  /**
   * The type of the action.
   */
  readonly type = ProjectResourcesActionType.FIND_PROJECT_RESOURCE_PAYROLL_LINES;

  /**
   * Fires a new find project resource payroll lines action.
   * @param payload the id of the project resource payroll lines.
   */
  constructor(public payload: number) {}
}

/**
 * Represents a store find project resource payroll lines fail action.
 */
export class FindProjectResourcePayrollLinesFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = ProjectResourcesActionType.FIND_PROJECT_RESOURCE_PAYROLL_LINES_FAIL;

  /**
   * Fires a new find project resource payroll lines fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store find project resource payroll lines success action.
 */
export class FindProjectResourcePayrollLinesSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = ProjectResourcesActionType.FIND_PROJECT_RESOURCE_PAYROLL_LINES_SUCCESS;

  /**
   * Fires a new find project resource payroll lines success action.
   * @param payload The loaded project resource payroll lines.
   */
  constructor(public payload: AppHttpResponse<ProjectResourceLine[]>) {}
}

/**
 * projects-management-module project resources action types.
 */
export type ProjectResourcesActions =
  | FindProjectResourceLines
  | FindProjectResourceLinesFail
  | FindProjectResourceLinesSuccess
  | FindProjectResourcePurchaseReturnLines
  | FindProjectResourcePurchaseReturnLinesFail
  | FindProjectResourcePurchaseReturnLinesSuccess
  | FindProjectResourcePurchaseInvoiceLines
  | FindProjectResourcePurchaseInvoiceLinesFail
  | FindProjectResourcePurchaseInvoiceLinesSuccess
  | FindProjectResourceAssetDepreciationLines
  | FindProjectResourceAssetDepreciationLinesFail
  | FindProjectResourceAssetDepreciationLinesSuccess
  | FindProjectResourceOutgoingStockLines
  | FindProjectResourceOutgoingStockLinesFail
  | FindProjectResourceOutgoingStockLinesSuccess
  | FindProjectResourcePayrollLines
  | FindProjectResourcePayrollLinesFail
  | FindProjectResourcePayrollLinesSuccess;
