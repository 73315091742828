import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';

import { AppHttpResponse } from 'shared';
import {
  CreateBankAccountInput,
  BankAccount,
  UpdateBankAccountInput,
  BankAccountLog,
  CreateBankAccountDepositByEmployeeInput,
  CreateBankAccountChequeDepositInput,
  CreateBankAccountByWithdrawFromAccountInput,
  CreateBankAccountMoneyTransferInput,
  CreateBankAccountCashReceiptInput,
  CreateBankAccountSettlementDeedInput,
} from 'finances/models';

/**
 * The bank account services includes functionality to create, search, findById ,update ,activate ,block and delete for a bank account.
 */
@Injectable()
export class BankAccountsService {
  /**
   * The relative route for the banks.
   *
   * No leading or trailing slashes required.
   */
  private bankAccountsApi = 'finances/banks/accounts';
  constructor(private http: HttpClient) {}

  /**
   * Creates a new bank account from the provided data.
   * @param data The new bank account data.
   */
  public create(data: CreateBankAccountInput): Observable<AppHttpResponse<BankAccount>> {
    return this.http.post<AppHttpResponse<BankAccount>>(`${this.bankAccountsApi}`, data);
  }

  /**
   * Searches in the bank accounts by name ,accountNumber ,iban and banks.
   * @param name The name of the bank account.
   * @param banks The banks of the account.
   * @param page The current pagination page number.
   * @param pageSize The maximum number of bank accounts allowed per one pagination page.
   */
  public search(
    name: string,
    banks: number[],
    page: number,
    pageSize: number
  ): Observable<AppHttpResponse<BankAccount[]>> {
    const params = new HttpParams()
      .set('name', name)
      .set('banks', banks.join(','))
      .set('page', page.toString())
      .set('pageSize', pageSize.toString());
    return this.http.get<AppHttpResponse<BankAccount[]>>(`${this.bankAccountsApi}`, { params });
  }

  /**
   * Searches in the bank account log by fromDate and toDate.
   * @param bankAccountId The id of the bank account of the bank account log.
   * @param fromDate The fromDate of the bank account log.
   * @param toDate The toDate of the bank account log.
   * @param page The current pagination page number.
   * @param pageSize The maximum number of bank account log allowed per one pagination page.
   */
  public searchBankAccountLogs(
    bankAccountId: number,
    fromDate: Date,
    toDate: Date,
    page: number,
    pageSize: number
  ): Observable<AppHttpResponse<BankAccountLog[]>> {
    const params = new HttpParams()
      .set('bankAccountId', bankAccountId.toString())
      .set('fromDate', fromDate?.toISOString())
      .set('toDate', toDate?.toISOString())
      .set('page', page.toString())
      .set('pageSize', pageSize.toString());
    return this.http.get<AppHttpResponse<BankAccountLog[]>>(`${this.bankAccountsApi}/logs`, { params });
  }

  /**
   * Finds the bank account with the given id.
   * @param id The id of the bank account.
   */
  public findById(id: number): Observable<AppHttpResponse<BankAccount>> {
    return this.http.get<AppHttpResponse<BankAccount>>(`${this.bankAccountsApi}/${id}`);
  }

  /**
   * Updates an existing bank account data using the provided data.
   * @param data The updated bank account data.
   */
  public update(data: UpdateBankAccountInput): Observable<AppHttpResponse<BankAccount>> {
    return this.http.put<AppHttpResponse<BankAccount>>(`${this.bankAccountsApi}`, data);
  }

  /**
   * Activates the bank account by given id.
   * @param id The id of the bank account.
   */
  public activate(id: number): Observable<AppHttpResponse<BankAccount>> {
    return this.http.put<AppHttpResponse<BankAccount>>(`${this.bankAccountsApi}/activate/${id}`, null);
  }

  /**
   * Blocks the bank account by given id.
   * @param id The id of the bank account.
   */
  public block(id: number): Observable<AppHttpResponse<BankAccount>> {
    return this.http.put<AppHttpResponse<BankAccount>>(`${this.bankAccountsApi}/block/${id}`, null);
  }

  /**
   * Deletes the bank account by given id.
   * @param id The id of the bank account.
   */
  public delete(id: number): Observable<AppHttpResponse<BankAccount>> {
    return this.http.delete<AppHttpResponse<BankAccount>>(`${this.bankAccountsApi}/${id}`);
  }

  /**
   * Creates a new bank account log by cheque deposit from the provided data.
   * @param data The new bank account log data.
   */
  public createBankAccountLog(data: CreateBankAccountChequeDepositInput): Observable<AppHttpResponse<BankAccountLog>> {
    const formData: any = new FormData();
    formData.append('notes', data.notes);
    formData.append('value', data.value);
    formData.append('extraDetails', data.extraDetails);
    formData.append('transactionDate', data.transactionDate?.toISOString());
    formData.append('bankAccountId', data.bankAccountId);

    /**
     * Append journal lines to the form data.
     */
    for (let index = 0; index < data.lines.length; index++) {
      formData.append(`lines[${index}][accountId]`, data.lines[index].accountId);
      formData.append(`lines[${index}][credit]`, data.lines[index].credit);
      formData.append(`lines[${index}][debit]`, data.lines[index].debit);
      formData.append(`lines[${index}][costCenterId]`, data.lines[index].costCenterId);
      formData.append(`lines[${index}][notes]`, data.lines[index].notes);
    }

    /**
     * Append attachments to the form data.
     */
    data.attachments.forEach((attachment) => formData.append(`attachments[]`, attachment, attachment.name));

    return this.http.post<AppHttpResponse<BankAccountLog>>(`${this.bankAccountsApi}/logs/cheque-deposit`, formData);
  }

  /**
   * Creates a new bank account log deposit by employee from the provided data.
   * @param data The new bank account log data.
   */
  public createBankAccountDepositByEmployee(
    data: CreateBankAccountDepositByEmployeeInput
  ): Observable<AppHttpResponse<BankAccountLog>> {
    const formData: any = new FormData();
    formData.append('notes', data.notes);
    formData.append('value', data.value);
    formData.append('extraDetails', data.extraDetails);
    formData.append('transactionDate', data.transactionDate?.toISOString());
    formData.append('bankAccountId', data.bankAccountId);

    /**
     * Append journal lines to the form data.
     */
    for (let index = 0; index < data.lines.length; index++) {
      formData.append(`lines[${index}][accountId]`, data.lines[index].accountId);
      formData.append(`lines[${index}][credit]`, data.lines[index].credit);
      formData.append(`lines[${index}][debit]`, data.lines[index].debit);
      formData.append(`lines[${index}][costCenterId]`, data.lines[index].costCenterId);
      formData.append(`lines[${index}][notes]`, data.lines[index].notes);
    }

    /**
     * Append attachments to the form data.
     */
    data.attachments.forEach((attachment) => formData.append(`attachments[]`, attachment, attachment.name));

    return this.http.post<AppHttpResponse<BankAccountLog>>(
      `${this.bankAccountsApi}/logs/deposit-by-employee`,
      formData
    );
  }

  /**
   * Creates a new bank account by withdraw from account the provided data.
   * @param data The new bank account log data.
   */
  public createBankAccountByWithdrawFromAccount(
    data: CreateBankAccountByWithdrawFromAccountInput
  ): Observable<AppHttpResponse<BankAccountLog>> {
    const formData: any = new FormData();
    formData.append('notes', data.notes);
    formData.append('value', data.value);
    formData.append('extraDetails', data.extraDetails);
    formData.append('transactionDate', data.transactionDate?.toISOString());
    formData.append('bankAccountId', data.bankAccountId);

    /**
     * Append journal lines to the form data.
     */
    for (let index = 0; index < data.lines.length; index++) {
      formData.append(`lines[${index}][accountId]`, data.lines[index].accountId);
      formData.append(`lines[${index}][credit]`, data.lines[index].credit);
      formData.append(`lines[${index}][debit]`, data.lines[index].debit);
      formData.append(`lines[${index}][costCenterId]`, data.lines[index].costCenterId);
      formData.append(`lines[${index}][notes]`, data.lines[index].notes);
    }

    /**
     * Append attachments to the form data.
     */
    data.attachments.forEach((attachment) => formData.append(`attachments[]`, attachment, attachment.name));

    return this.http.post<AppHttpResponse<BankAccountLog>>(
      `${this.bankAccountsApi}/logs/withdraw-from-bankAccount`,
      formData
    );
  }

  /**
   * Creates a new bank account money transfer from the provided data.
   * @param data The new bank account log data.
   */
  public createBankAccountMoneyTransfer(
    data: CreateBankAccountMoneyTransferInput
  ): Observable<AppHttpResponse<BankAccountLog>> {
    return this.http.post<AppHttpResponse<BankAccountLog>>(`${this.bankAccountsApi}/logs/create-money-transfer`, data);
  }

  /**
   * Creates a new bank account cash receipt from the provided data.
   * @param data The new bank account log data.
   */
  public createBankAccountCashReceipt(
    data: CreateBankAccountCashReceiptInput
  ): Observable<AppHttpResponse<BankAccountLog>> {
    return this.http.post<AppHttpResponse<BankAccountLog>>(`${this.bankAccountsApi}/logs/create-cash-receipt`, data);
  }

  /**
   * Creates a new bank account settlement deed from the provided data.
   * @param data The new bank account log data.
   */
  public createBankAccountSettlementDeed(
    data: CreateBankAccountSettlementDeedInput
  ): Observable<AppHttpResponse<BankAccountLog>> {
    return this.http.post<AppHttpResponse<BankAccountLog>>(`${this.bankAccountsApi}/logs/create-settlement-deed`, data);
  }
}
