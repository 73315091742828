<div [formGroup]="employeesForm.parent" class="row">
  <div class="col-md-12">
    <div class="table-responsive">
      <table class="table text-center">
        <thead>
          <tr>
            <th scope="col"><i class="fa fa-list-ol"></i></th>
            <th scope="col">
              {{ 'HR.GOV_SERVICE_REQUESTS.GOV_SERVICE_REQUEST_DATA.EMPLOYEE_NUMBER' | translate }}
            </th>
            <th scope="col">
              {{ 'HR.GOV_SERVICE_REQUESTS.GOV_SERVICE_REQUEST_DATA.EMPLOYEE_NAME' | translate }}
            </th>
            <th scope="col">
              {{ 'HR.GOV_SERVICE_REQUESTS.GOV_SERVICE_REQUEST_DATA.FROM_DATE' | translate }}
            </th>
            <th scope="col">
              {{ 'HR.GOV_SERVICE_REQUESTS.GOV_SERVICE_REQUEST_DATA.TO_DATE' | translate }}
            </th>
            <th scope="col">
              {{ 'HR.GOV_SERVICE_REQUESTS.GOV_SERVICE_REQUEST_DATA.SERVICE_VALUE' | translate }}
            </th>
            <th scope="col">
              {{ 'HR.GOV_SERVICE_REQUESTS.GOV_SERVICE_REQUEST_DATA.PENALTY_VALUE' | translate }}
            </th>
            <th scope="col">
              {{ 'HR.GOV_SERVICE_REQUESTS.GOV_SERVICE_REQUEST_DATA.NOTES' | translate }}
            </th>
          </tr>
        </thead>
        <tbody [formArrayName]="formArrayName">
          <tr [formGroup]="formGroup" *ngFor="let formGroup of employeesForm.controls; let index = index">
            <th scope="row">{{ index + 1 }}</th>
            <td>
              {{ formGroup.value.employee.employeeAssignmentDetail.personNumber }}
            </td>
            <td class="min-width-15">
              {{
                formGroup.value.employee.employeePersonalDetail.fullName
                  | localize: formGroup.value.employee.employeePersonalDetail.fullNameEn
                  | async
              }}
            </td>
            <td class="fit-width">{{ toDateOnly(formGroup.value.fromDate) }}</td>
            <td class="fit-width">{{ toDateOnly(formGroup.value.toDate) }}</td>
            <td class="min-width-10">
              <input
                type="text"
                [mask]="DECIMAL_MASK"
                thousandSeparator=","
                id="serviceValue"
                class="form-control round"
                [placeholder]="'HR.GOV_SERVICE_REQUESTS.GOV_SERVICE_REQUEST_DATA.SERVICE_VALUE' | translate"
                formControlName="serviceValue"
              />
            </td>
            <td class="min-width-10">
              <input
                type="text"
                [mask]="DECIMAL_MASK"
                thousandSeparator=","
                id="penaltyValue"
                class="form-control round"
                [placeholder]="'HR.GOV_SERVICE_REQUESTS.GOV_SERVICE_REQUEST_DATA.PENALTY_VALUE' | translate"
                formControlName="penaltyValue"
              />
            </td>
            <td class="min-width-10">
              <textarea
                type="text"
                autocomplete="off"
                class="form-control round"
                [placeholder]="'HR.GOV_SERVICE_REQUESTS.GOV_SERVICE_REQUEST_DATA.NOTES_PLACEHOLDER' | translate"
                [maxlength]="200"
                formControlName="notes"
                rows="1"
              >
              </textarea>
            </td>
          </tr>
        </tbody>
        <tfoot class="tfoot-light">
          <tr>
            <td colspan="9">
              <i class="fa fa-bookmark c-primary"></i>
              <span>
                {{ employeesForm?.length | toNumber }}
                {{ 'HR.GOV_SERVICE_REQUESTS.GOV_SERVICE_REQUEST_DATA.EMPLOYEE' | translate }}
              </span>
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  </div>
</div>
