import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';

import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

import { NotificationService, PaginationInfo, TranslationService } from 'shared';
import * as fromStoresStore from 'stores/store';
import * as fromLookupsStore from 'lookups/store';
import { Variant } from 'stores/models';
import { VariantCategory } from 'lookups/models';

/**
 * A variants search form wrapped in modal.
 */
@Component({
  selector: 'app-variants-search',
  templateUrl: './variants-search.component.html',
  styles: [],
})
export class VariantsSearchComponent implements OnInit {
  /**
   * The modal template reference.
   */
  @ViewChild('modalRef') modalRef: ElementRef<any>;

  /**
   * Indicates whether the modal will automatically close after select the first variant.
   *
   * Be default it will not be closed.
   */
  @Input() closeOnSelect = false;

  /**
   * Indicates whether the variants displayed on the list should be all active.
   *
   * Be default it will not be closed.
   */
  @Input() onlyActive = false;

  /**
   * Sets the visibility of this component modal.
   *
   * Handles visibility change in two-data-binding approach.
   */
  @Input() set visible(isShown: boolean) {
    if (isShown && this.modalRef) {
      this.openModal(this.modalRef);
    } else {
      this.closeModal();
    }

    this.visibleChange.emit(isShown);
  }

  /**
   * Outputs a value that indicates whether the modal is shown or not.
   *
   * Handles visibility change in two-data-binding approach.
   */
  @Output() visibleChange = new EventEmitter<boolean>();

  /** Outputs the selected variants. */
  @Output() variants = new EventEmitter<Variant[]>();

  /**
   * The list of variants.
   */
  variants$: Observable<Variant[]>;

  /**
   * The list of variants.
   */
  variantsList: Variant[];

  /**
   * The list of variant category.
   */
  variantCategories$: Observable<VariantCategory[]>;

  /**
   * The pagination info.
   */
  paginationInfo$: Observable<PaginationInfo>;

  /**
   * Indicates whether there is a search process is running or not.
   */
  isSearching$: Observable<boolean>;

  /**
   * The search form.
   */
  searchForm: FormGroup;

  /**
   * The ngb-modal reference to use it when modal needed to be closed.
   */
  modal: NgbModalRef;

  /**
   * @param modalService The modal service.
   * @param notificationService The notification service.
   * @param translationService The translation service.
   * @param storesStore$ The stores-store module.
   * @param lookupsStore$ The lookups-store module.
   */
  constructor(
    private modalService: NgbModal,
    private notificationService: NotificationService,
    private translationService: TranslationService,
    private storesStore$: Store<fromStoresStore.StoresState>,
    private lookupsStore$: Store<fromLookupsStore.LookupsState>
  ) {}

  ngOnInit(): void {
    this.init();
  }

  /**
   * Initialize component data.
   */
  init() {
    this.initForm();

    /**
     * Load data.
     */
    this.isSearching$ = this.storesStore$.pipe(select(fromStoresStore.getVariantsSearching));

    let isManualSearchTriggeredBeforeForVariants = false;
    this.variants$ = this.storesStore$.pipe(
      select(fromStoresStore.getVariants),
      tap((variants) => {
        if (variants) {
          this.variantsList = variants;
        }
        if (!isManualSearchTriggeredBeforeForVariants && !variants.length) {
          isManualSearchTriggeredBeforeForVariants = true;
          this.search();
        }
      })
    );

    this.paginationInfo$ = this.storesStore$.pipe(select(fromStoresStore.getVariantsPaginationInfo));

    let isManualSearchTriggeredBeforeForCategories = false;
    this.variantCategories$ = this.lookupsStore$.pipe(
      select(fromLookupsStore.getVariantCategories),
      tap((categories) => {
        if (!isManualSearchTriggeredBeforeForCategories && !categories.length) {
          isManualSearchTriggeredBeforeForCategories = true;
          this.lookupsStore$.dispatch(new fromLookupsStore.GetAllVariantCategories());
        }
      })
    );
  }

  /**
   * Initialize form and add validators.
   */
  initForm() {
    this.searchForm = new FormGroup({
      name: new FormControl(''),
      categories: new FormControl([]),
      page: new FormControl(1),
    });
  }

  /**
   * Handles search parameters change.
   */
  search(event?: KeyboardEvent) {
    if (event && event.key === 'Enter') {
      event.preventDefault();
      return;
    }

    this.storesStore$.dispatch(
      new fromStoresStore.SearchVariants({
        name: this.searchForm.get('name').value,
        categories: this.searchForm.get('categories').value,
        page: 1,
      })
    );
  }

  /**
   * Handles pagination page-changed event.
   * @param page The current selected page number.
   */
  pageChanged(page: number) {
    /** Update pagination page. */
    this.searchForm.patchValue({ page });

    /** Get selected page items. */
    this.storesStore$.dispatch(
      new fromStoresStore.SearchVariants({
        name: this.searchForm.get('name').value,
        categories: this.searchForm.get('categories').value,
        page: this.searchForm.get('page').value,
      })
    );
  }

  /**
   * Enables trackBy feature for *ngFor utility to track items
   * depending on a comparer to reduce DOM change.
   * If the item is already exist then no HTML will change, only bounded values.
   * @param index The index of the item.
   * @param location The item to determine if it was changed or not.
   */
  trackItems(index: number, variant: Variant) {
    return variant ? variant.id : undefined;
  }

  /**
   * Handles variant selected event.
   * @param variants The list of variants to notify selected.
   */
  selectVariant(variants: Variant[]) {
    this.notificationService.successInstant(
      this.translationService.translate('STORES.PRODUCT_VARIANTS.NOTIFICATION_MESSAGE.VARIANT_SELECTED')
    );
    this.variants.emit(variants);
    if (this.closeOnSelect) {
      this.closeModal();
    }
  }

  /**
   * Opens the modal of the given templateRef.
   * @param modalRef The modal templateRef to be opened.
   */
  openModal(modalRef) {
    /**
     * Open the modal & listen for user press ESC key.
     */
    this.modal = this.modalService.open(modalRef, { size: 'lg' });
    this.modal.result.then(null, () => this.closeModal());
  }

  /**
   * Closes the currently opened modal.
   */
  closeModal() {
    this.modal?.dismiss();
    this.variants.emit([]);
    this.visibleChange.emit(false);
  }
}
