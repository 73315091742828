import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';

import * as fromServices from 'websocket/services';

/**
 * Contains the Websocket implementation.
 * This module should imported in the core-module using `.forRoot()` static method.
 */
@NgModule({
  declarations: [],
  imports: [CommonModule],
  exports: [],
})
export class WebSocketModule {
  /**
   * Register all of services belong to shared module into the module that calls this `forRoot()` method.
   */
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: WebSocketModule,
      providers: [...fromServices.SERVICES],
    };
  }
}
