import { createSelector } from '@ngrx/store';

import { ReportsState, StoresReportsState } from 'reports/store/states';
import { selectReportsState } from 'reports/store/selectors/feature.selectors';

/* Selectors */

/**
 * Gets the stores reports state.
 */
const selectStoresReportsState = createSelector(selectReportsState, (state: ReportsState) => state.stores);

/**
 * Gets the loaded report.
 */
export const getProductsListReport = createSelector(
  selectStoresReportsState,
  (state: StoresReportsState) => state.productsList
);

/**
 * Gets the loaded report.
 */
export const getProductMovementReport = createSelector(
  selectStoresReportsState,
  (state: StoresReportsState) => state.productMovement
);

/**
 * Gets the loaded report.
 */
export const getIncomingStockReport = createSelector(
  selectStoresReportsState,
  (state: StoresReportsState) => state.incomingStock
);

/**
 * Gets the loaded report.
 */
export const getOutgoingStockReport = createSelector(
  selectStoresReportsState,
  (state: StoresReportsState) => state.outgoingStock
);

/**
 * Gets the loaded report.
 */
export const getTransferStockReport = createSelector(
  selectStoresReportsState,
  (state: StoresReportsState) => state.transferStock
);

/**
 * Gets the loaded report.
 */
export const getStockInventoryReport = createSelector(
  selectStoresReportsState,
  (state: StoresReportsState) => state.stockInventory
);

/**
 * Gets the loaded report.
 */
export const getStockMonitoringReport = createSelector(
  selectStoresReportsState,
  (state: StoresReportsState) => state.stockMonitoring
);

/**
 * Gets the loaded report.
 */
export const getUnhandledSalesStockReport = createSelector(
  selectStoresReportsState,
  (state: StoresReportsState) => state.unhandledSalesStock
);

/**
 * Gets the error that occurred in the state.
 */
export const getStoresReportsError = createSelector(
  selectStoresReportsState,
  (state: StoresReportsState) => state.error
);

/**
 * Determines if there is a running load process.
 */
export const getProductsListReportLoading = createSelector(
  selectStoresReportsState,
  (state: StoresReportsState) => state.isLoadingProductsList
);

/**
 * Determines if the last load process has been ended successfully.
 */
export const getProductsListReportLoadCompleted = createSelector(
  selectStoresReportsState,
  (state: StoresReportsState) => state.isProductsListLoadCompleted
);

/**
 * Determines if there is a running load process.
 */
export const getProductMovementReportLoading = createSelector(
  selectStoresReportsState,
  (state: StoresReportsState) => state.isLoadingProductMovement
);

/**
 * Determines if the last load process has been ended successfully.
 */
export const getProductMovementReportLoadCompleted = createSelector(
  selectStoresReportsState,
  (state: StoresReportsState) => state.isProductMovementLoadCompleted
);

/**
 * Determines if there is a running load process.
 */
export const getIncomingStockReportLoading = createSelector(
  selectStoresReportsState,
  (state: StoresReportsState) => state.isLoadingIncomingStock
);

/**
 * Determines if the last load process has been ended successfully.
 */
export const getIncomingStockReportLoadCompleted = createSelector(
  selectStoresReportsState,
  (state: StoresReportsState) => state.isIncomingStockLoadCompleted
);

/**
 * Determines if there is a running load process.
 */
export const getOutgoingStockReportLoading = createSelector(
  selectStoresReportsState,
  (state: StoresReportsState) => state.isLoadingOutgoingStock
);

/**
 * Determines if the last load process has been ended successfully.
 */
export const getOutgoingStockReportLoadCompleted = createSelector(
  selectStoresReportsState,
  (state: StoresReportsState) => state.isOutgoingStockLoadCompleted
);

/**
 * Determines if there is a running load process.
 */
export const getTransferStockReportLoading = createSelector(
  selectStoresReportsState,
  (state: StoresReportsState) => state.isLoadingTransferStock
);

/**
 * Determines if the last load process has been ended successfully.
 */
export const getTransferStockReportLoadCompleted = createSelector(
  selectStoresReportsState,
  (state: StoresReportsState) => state.isTransferStockLoadCompleted
);

/**
 * Determines if there is a running load process.
 */
export const getStockInventoryReportLoading = createSelector(
  selectStoresReportsState,
  (state: StoresReportsState) => state.isLoadingStockInventory
);

/**
 * Determines if the last load process has been ended successfully.
 */
export const getStockInventoryReportLoadCompleted = createSelector(
  selectStoresReportsState,
  (state: StoresReportsState) => state.isStockInventoryLoadCompleted
);

/**
 * Determines if there is a running load process.
 */
export const getStockMonitoringReportLoading = createSelector(
  selectStoresReportsState,
  (state: StoresReportsState) => state.isLoadingStockMonitoring
);

/**
 * Determines if the last load process has been ended successfully.
 */
export const getStockMonitoringReportLoadCompleted = createSelector(
  selectStoresReportsState,
  (state: StoresReportsState) => state.isStockMonitoringLoadCompleted
);

/**
 * Determines if there is a running load process.
 */
export const getUnhandledSalesStockReportLoading = createSelector(
  selectStoresReportsState,
  (state: StoresReportsState) => state.isLoadingUnhandledSalesStock
);

/**
 * Determines if the last load process has been ended successfully.
 */
export const getUnhandledSalesStockReportLoadCompleted = createSelector(
  selectStoresReportsState,
  (state: StoresReportsState) => state.isUnhandledSalesStockLoadCompleted
);
