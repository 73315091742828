<!-- project revenues list starts -->
<div class="row">
  <div class="col-md-12">
    <div class="card-content">
      <div class="px-3">
        <div class="row">
          <div class="col-md-12">
            <form class="row" [formGroup]="searchForm" (ngSubmit)="search()">
              <div class="form-group col">
                <label for="projectRevenueTypes">
                  {{ 'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.PROJECT_REVENUE_TYPES' | translate }}
                </label>
                <ng-select
                  class="round"
                  formControlName="projectRevenueTypes"
                  labelForId="projectRevenueTypes"
                  searchable="true"
                  autocomplete="off"
                  [multiple]="true"
                  [placeholder]="
                    'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.PROJECT_REVENUE_TYPES_PLACEHOLDER' | translate
                  "
                  [notFoundText]="'SHARED.SEARCH.SEARCH_NOT_FOUND' | translate"
                >
                  <ng-option
                    *ngFor="let projectRevenueType of projectRevenueTypes$ | async"
                    [value]="projectRevenueType?.id"
                  >
                    {{ projectRevenueType?.name | localize: projectRevenueType?.nameEn | async }}
                  </ng-option>
                </ng-select>
              </div>
              <div class="form-group col mb-0">
                <label for="fromDate">{{ 'SHARED.SEARCH.FROM_DATE' | translate }}</label>
                <div class="input-group round">
                  <input
                    class="form-control"
                    id="fromDate"
                    name="fromDate"
                    formControlName="fromDate"
                    [placeholder]="'SHARED.SEARCH.DATE_PLACEHOLDER' | translate"
                    ngbDatepicker
                    #fromDate="ngbDatepicker"
                  />
                  <div class="input-group-append">
                    <span
                      class="input-group-text gradient-light-blue-indigo"
                      style="cursor: pointer;"
                      (click)="fromDate.toggle()"
                    >
                      <i class="fa fa-calendar"></i>
                    </span>
                  </div>
                </div>
              </div>
              <div class="form-group col mb-0">
                <label for="toDate">{{ 'SHARED.SEARCH.TO_DATE' | translate }}</label>
                <div class="input-group round">
                  <input
                    class="form-control"
                    id="toDate"
                    name="toDate"
                    formControlName="toDate"
                    [placeholder]="'SHARED.SEARCH.DATE_PLACEHOLDER' | translate"
                    ngbDatepicker
                    #toDate="ngbDatepicker"
                  />
                  <div class="input-group-append">
                    <span
                      class="input-group-text gradient-light-blue-indigo"
                      style="cursor: pointer;"
                      (click)="toDate.toggle()"
                    >
                      <i class="fa fa-calendar"></i>
                    </span>
                  </div>
                </div>
              </div>
              <div class="col-md-auto form-group d-flex">
                <button
                  type="submit"
                  class="btn btn-raised btn-primary align-self-end round mx-1 mb-0"
                  [disabled]="isSearching$ | async"
                >
                  <i class="fa fa-search"></i>
                </button>
                <app-export-tools
                  *ngIf="(projectRevenues$ | async)?.length"
                  class="d-flex align-self-end mx-1"
                  targetElementId="project-plan-revenues-table-export-target-element"
                  [outputFileName]="'SHARED.EXPORT_TABLES.PROJECT_REVENUES_TABLE' | translate"
                ></app-export-tools>
              </div>
            </form>
          </div>
          <div class="col-md-12" *ngIf="(projectRevenues$ | async)?.length; else noItems">
            <div class="row">
              <div class="col-md-12 alert alert-light text-center" role="alert">
                {{ 'SHARED.SEARCH.SEARCH_RESULTS' | translate }} ({{ (projectRevenues$ | async).length }})
                {{ 'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.PROJECT_REVENUE' | translate }}
              </div>
              <div class="col-md-12">
                <div class="table-responsive">
                  <table class="table text-center" id="project-plan-revenues-table-export-target-element">
                    <thead>
                      <tr>
                        <th scope="col"><i class="fa fa-list-ol"></i></th>
                        <th scope="col">
                          {{ 'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.TRANSACTION_NUM' | translate }}
                        </th>
                        <th scope="col">
                          {{ 'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.PROJECT_REVENUE_TYPE' | translate }}
                        </th>
                        <th scope="col">
                          {{ 'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.VALUE_REVENUE' | translate }}
                        </th>
                        <th scope="col">
                          {{ 'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.CREATED_AT' | translate }}
                        </th>
                        <th scope="col">
                          {{ 'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.USER' | translate }}
                        </th>
                        <th scope="col">
                          {{ 'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.TOOLS' | translate }}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let projectRevenue of projectRevenues$ | async; trackBy: trackItems; let i = index">
                        <th scope="row">{{ i + 1 }}</th>
                        <th>
                          <span *ngIf="projectRevenue.saleInvoice">
                            {{ projectRevenue.saleInvoice.invoiceNum }}
                          </span>
                          <span *ngIf="projectRevenue.saleReturn">
                            {{ projectRevenue.saleReturn.saleReturnNum }}
                          </span>
                        </th>
                        <td>
                          {{
                            projectRevenue.projectRevenueType.name
                              | localize: projectRevenue.projectRevenueType.nameEn
                              | async
                          }}
                        </td>
                        <td>
                          {{ projectRevenue.value | toDecimal }}
                        </td>
                        <td>
                          <span [ngbTooltip]="projectRevenue.createdAt | date: 'time'">
                            {{ projectRevenue.createdAt | date }}
                          </span>
                        </td>
                        <td>{{ projectRevenue.user.name }}</td>
                        <td>
                          <a
                            class="info p-0"
                            [routerLink]="['/projects-management/projects/revenues/view/', projectRevenue.id]"
                            [ngbTooltip]="'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.DISPLAY_REVENUE' | translate"
                          >
                            <i class="fa fa-eye font-medium-3 mr-2"></i>
                          </a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- project revenues list ends -->

<!-- no items template starts -->
<ng-template #noItems>
  <div class="col-md-12 alert alert-warning mt-3 text-center" role="alert">
    {{ 'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.SEARCH_PROJECT_REVENUES_RESULTS_NOT_FOUND' | translate }}
  </div>
</ng-template>
<!-- no items template ends -->
