import * as fromOrganizationSettings from 'settings/components/organization-settings';
import * as fromTaxes from 'settings/components/taxes';

export * from 'settings/components/organization-settings';
export * from 'settings/components/taxes';

/**
 * The set of components registered on this module.
 */
export const COMPONENTS: any[] = [...fromOrganizationSettings.COMPONENTS, ...fromTaxes.COMPONENTS];
