import { createSelector } from '@ngrx/store';

import { selectLookupsState } from 'lookups/store/selectors/feature.selectors';
import { LookupsState, ContractTypeState } from 'lookups/store/states';

/**
 * Gets the contract type state.
 */
const selectContractTypes = createSelector(selectLookupsState, (state: LookupsState) => state.contractTypes);

/**
 * Gets the list of loaded contract types.
 */
export const getContractTypes = createSelector(selectContractTypes, (state: ContractTypeState) => state.data);

/**
 * Gets the error that occurred in the state.
 */
export const getContractTypesError = createSelector(selectContractTypes, (state: ContractTypeState) => state.error);

/**
 * Determines if there is a running load process.
 */
export const getContractTypesIsLoading = createSelector(
  selectContractTypes,
  (state: ContractTypeState) => state.isLoading
);

/**
 * Determines if the last load process has been ended successfully.
 */
export const getContractTypesIsLoaded = createSelector(
  selectContractTypes,
  (state: ContractTypeState) => state.isLoaded
);
