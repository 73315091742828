import { ProjectFileComponent } from 'projects-management/containers/projects/project-file/project-file.component';
import { ProjectResourcesFileComponent } from 'projects-management/containers/projects/project-resources-file/project-resources-file.component';
import { ViewRevenueComponent } from 'projects-management/containers/projects/view-revenue/view-revenue.component';

export * from 'projects-management/containers/projects/project-file/project-file.component';
export * from 'projects-management/containers/projects/view-revenue/view-revenue.component';
export * from 'projects-management/containers/projects/project-resources-file/project-resources-file.component';

/**
 * The set of containers registered on this sub-module.
 */
export const CONTAINERS: any[] = [ProjectFileComponent, ProjectResourcesFileComponent, ViewRevenueComponent];
