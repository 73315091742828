import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';

import { AppHttpResponse } from 'shared';
import { CreatePettyCashInput, UpdatePettyCashInput, PettyCash } from 'finances/models';

/**
 * The petty cash services includes functionality to create, search, findById, update, block, activate and delete a petty cash.
 */

@Injectable()
export class PettyCashService {
  /**
   * The relative route for the petty cash.
   *
   * No leading or trailing slashes required.
   */
  private pettyCashApi = 'finances/pettyCash';
  constructor(private http: HttpClient) {}

  /**
   * Creates a new petty cash from the provided data.
   * @param data The new petty cash data.
   */
  public create(data: CreatePettyCashInput): Observable<AppHttpResponse<PettyCash>> {
    return this.http.post<AppHttpResponse<PettyCash>>(`${this.pettyCashApi}`, data);
  }

  /**
   * Searches in the petty cash by description or type or specification.
   * @param description The description of the petty cash.
   * @param types The types of the petty cash.
   * @param specifications The specifications of the petty cash.
   * @param page The current pagination page number.
   * @param pageSize The maximum number of petty cash allowed per one pagination page.
   */
  public search(
    description: string,
    types: number[],
    specifications: number[],
    page: number,
    pageSize: number
  ): Observable<AppHttpResponse<PettyCash[]>> {
    const params = new HttpParams()
      .set('description', description)
      .set('types', types.join(','))
      .set('specifications', specifications.join(','))
      .set('page', page.toString())
      .set('pageSize', pageSize.toString());
    return this.http.get<AppHttpResponse<PettyCash[]>>(`${this.pettyCashApi}`, { params });
  }

  /**
   * Finds the petty cash with the given id.
   * @param id The id of the petty cash.
   */
  public findById(id: number): Observable<AppHttpResponse<PettyCash>> {
    return this.http.get<AppHttpResponse<PettyCash>>(`${this.pettyCashApi}/${id}`);
  }

  /**
   * Updates an existing petty cash data using the provided data.
   * @param data The updated petty cash data.
   */
  public update(data: UpdatePettyCashInput): Observable<AppHttpResponse<PettyCash>> {
    return this.http.put<AppHttpResponse<PettyCash>>(`${this.pettyCashApi}`, data);
  }

  /**
   * Activates the petty cash by given id.
   * @param id The id of the petty cash.
   */
  public activate(id: number): Observable<AppHttpResponse<PettyCash>> {
    return this.http.put<AppHttpResponse<PettyCash>>(`${this.pettyCashApi}/activate/${id}`, null);
  }

  /**
   * Blocks the petty cash by given id.
   * @param id The id of the petty cash.
   */
  public block(id: number): Observable<AppHttpResponse<PettyCash>> {
    return this.http.put<AppHttpResponse<PettyCash>>(`${this.pettyCashApi}/block/${id}`, null);
  }

  /**
   * Deletes the petty cash by given id.
   * @param id The id of the petty cash.
   */
  public delete(id: number): Observable<AppHttpResponse<PettyCash>> {
    return this.http.delete<AppHttpResponse<PettyCash>>(`${this.pettyCashApi}/${id}`);
  }
}
