import { Injectable } from '@angular/core';

import { switchMap, map, catchError, tap, debounceTime } from 'rxjs/operators';
import { of } from 'rxjs';
import { Effect, ofType, Actions } from '@ngrx/effects';

import { NotificationService, StatusCode, PAGINATION, TranslationService } from 'shared';
import { OrganizationsService } from 'organizations/services';
import {
  OrganizationsActionType,
  SearchOrganizations,
  SearchOrganizationsFail,
  SearchOrganizationsSuccess,
  CreateOrganization,
  CreateOrganizationSuccess,
  CreateOrganizationFail,
  UpdateOrganization,
  UpdateOrganizationSuccess,
  UpdateOrganizationFail,
  BlockOrganization,
  BlockOrganizationSuccess,
  BlockOrganizationFail,
  ActivateOrganization,
  ActivateOrganizationSuccess,
  ActivateOrganizationFail,
  DeleteOrganization,
  DeleteOrganizationSuccess,
  DeleteOrganizationFail,
  FindOrganization,
  FindOrganizationSuccess,
  FindOrganizationFail,
} from 'organizations/store/actions';

@Injectable()
export class OrganizationsEffects {
  constructor(
    private actions$: Actions,
    private organizationsService: OrganizationsService,
    private notificationService: NotificationService,
    private translationService: TranslationService
  ) {}

  /* ========================= SEARCH_ORGANIZATIONS =================================== */
  @Effect()
  search$ = this.actions$.pipe(
    ofType(OrganizationsActionType.SEARCH_ORGANIZATIONS),
    debounceTime(300),
    switchMap(({ payload }: SearchOrganizations) =>
      this.organizationsService.search(payload?.name ?? '', payload.page, PAGINATION.Organizations).pipe(
        map((response) => new SearchOrganizationsSuccess(response)),
        catchError((error) => of(new SearchOrganizationsFail(error)))
      )
    )
  );

  /* ========================= FIND_ORGANIZATION =================================== */
  @Effect()
  find$ = this.actions$.pipe(
    ofType(OrganizationsActionType.FIND_ORGANIZATION),
    switchMap((action: FindOrganization) =>
      this.organizationsService.findById(action.payload).pipe(
        map((response) => new FindOrganizationSuccess(response)),
        catchError((error) => of(new FindOrganizationFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  findFail$ = this.actions$.pipe(
    ofType(OrganizationsActionType.FIND_ORGANIZATION_FAIL),
    tap((action: FindOrganizationFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(this.translationService.translate('ORGANIZATIONS.ORGANIZATION_NOT_FOUND'));
      }
    })
  );

  /* ========================= CREATE_ORGANIZATION =================================== */
  @Effect()
  create$ = this.actions$.pipe(
    ofType(OrganizationsActionType.CREATE_ORGANIZATION),
    switchMap((action: CreateOrganization) =>
      this.organizationsService.create(action.payload).pipe(
        map((response) => new CreateOrganizationSuccess(response)),
        catchError((error) => of(new CreateOrganizationFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  createSuccess$ = this.actions$.pipe(
    ofType(OrganizationsActionType.CREATE_ORGANIZATION_SUCCESS),
    tap((action: CreateOrganizationSuccess) => {
      this.notificationService.success(this.translationService.translate('ORGANIZATIONS.ORGANIZATION_ADDED'));
    })
  );

  @Effect({ dispatch: false })
  createFail$ = this.actions$.pipe(
    ofType(OrganizationsActionType.CREATE_ORGANIZATION_FAIL),
    tap((action: CreateOrganizationFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );

  /* ========================= UPDATE_ORGANIZATION =================================== */
  @Effect()
  update$ = this.actions$.pipe(
    ofType(OrganizationsActionType.UPDATE_ORGANIZATION),
    switchMap((action: UpdateOrganization) =>
      this.organizationsService.update(action.payload).pipe(
        map((response) => new UpdateOrganizationSuccess(response)),
        catchError((error) => of(new UpdateOrganizationFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  updateSuccess$ = this.actions$.pipe(
    ofType(OrganizationsActionType.UPDATE_ORGANIZATION_SUCCESS),
    tap((action: UpdateOrganizationSuccess) => {
      this.notificationService.success(this.translationService.translate('ORGANIZATIONS.ORGANIZATION_UPDATED'));
    })
  );

  @Effect({ dispatch: false })
  updateFail$ = this.actions$.pipe(
    ofType(OrganizationsActionType.UPDATE_ORGANIZATION_FAIL),
    tap((action: UpdateOrganizationFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(this.translationService.translate('ORGANIZATIONS.ORGANIZATION_NOT_FOUND'));
      } else if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );

  /* ========================= BLOCK_ORGANIZATION =================================== */
  @Effect()
  block$ = this.actions$.pipe(
    ofType(OrganizationsActionType.BLOCK_ORGANIZATION),
    switchMap((action: BlockOrganization) =>
      this.organizationsService.block(action.payload).pipe(
        map((response) => new BlockOrganizationSuccess(response)),
        catchError((error) => of(new BlockOrganizationFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  blockSuccess$ = this.actions$.pipe(
    ofType(OrganizationsActionType.BLOCK_ORGANIZATION_SUCCESS),
    tap((action: BlockOrganizationSuccess) => {
      this.notificationService.success(this.translationService.translate('ORGANIZATIONS.ORGANIZATION_BLOCKED'));
    })
  );

  @Effect({ dispatch: false })
  blockFail$ = this.actions$.pipe(
    ofType(OrganizationsActionType.BLOCK_ORGANIZATION_FAIL),
    tap((action: BlockOrganizationFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(this.translationService.translate('ORGANIZATIONS.ORGANIZATION_NOT_FOUND'));
      } else if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );

  /* ========================= ACTIVATE_ORGANIZATION =================================== */
  @Effect()
  activate$ = this.actions$.pipe(
    ofType(OrganizationsActionType.ACTIVATE_ORGANIZATION),
    switchMap((action: ActivateOrganization) =>
      this.organizationsService.activate(action.payload).pipe(
        map((response) => new ActivateOrganizationSuccess(response)),
        catchError((error) => of(new ActivateOrganizationFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  activateSuccess$ = this.actions$.pipe(
    ofType(OrganizationsActionType.ACTIVATE_ORGANIZATION_SUCCESS),
    tap((action: ActivateOrganizationSuccess) => {
      this.notificationService.success(this.translationService.translate('ORGANIZATIONS.ORGANIZATION_ACTIVATED'));
    })
  );

  @Effect({ dispatch: false })
  activateFail$ = this.actions$.pipe(
    ofType(OrganizationsActionType.ACTIVATE_ORGANIZATION_FAIL),
    tap((action: ActivateOrganizationFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(this.translationService.translate('ORGANIZATIONS.ORGANIZATION_NOT_FOUND'));
      } else if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );

  /* ========================= DELETE_ORGANIZATION =================================== */
  @Effect()
  delete$ = this.actions$.pipe(
    ofType(OrganizationsActionType.DELETE_ORGANIZATION),
    switchMap((action: DeleteOrganization) =>
      this.organizationsService.delete(action.payload).pipe(
        map((response) => new DeleteOrganizationSuccess(response)),
        catchError((error) => of(new DeleteOrganizationFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  deleteSuccess$ = this.actions$.pipe(
    ofType(OrganizationsActionType.DELETE_ORGANIZATION_SUCCESS),
    tap((action: DeleteOrganizationSuccess) => {
      this.notificationService.success(this.translationService.translate('ORGANIZATIONS.ORGANIZATION_DELETED'));
    })
  );

  @Effect({ dispatch: false })
  deleteFail$ = this.actions$.pipe(
    ofType(OrganizationsActionType.DELETE_ORGANIZATION_FAIL),
    tap((action: DeleteOrganizationFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(this.translationService.translate('ORGANIZATIONS.ORGANIZATION_NOT_FOUND'));
      } else if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );
}
