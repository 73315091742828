import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, FormArray, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

import { Observable, Subscription } from 'rxjs';
import { skip, tap } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { uniq } from 'lodash';
import { Decimal } from 'decimal.js';

import * as fromSalesStore from 'sales/store';
import { CustomValidators, NotificationMessage, NotificationService, PageInfo, TranslationService } from 'shared';
import { CreateCustomerPaymentInput, CustomerLog, CustomerPaymentFormItem } from 'sales/models';
import { CanComponentDeactivate } from 'auth';

@Component({
  selector: 'app-create-payment-by-advance-payment',
  templateUrl: './create-payment-by-advance-payment.component.html',
  styles: [],
})
export class CreatePaymentByAdvancePaymentComponent implements OnInit, OnDestroy, CanComponentDeactivate {
  /**
   * The confirm modal template reference.
   */
  @ViewChild('confirmModalRef') confirmModalRef: ElementRef<any>;

  /**
   * The reset modal template reference.
   */
  @ViewChild('resetModalRef') resetModalRef: ElementRef<any>;

  /**
   * Gets or sets the information about the current page.
   */
  pageInfo: PageInfo = {
    title: 'SALES.CUSTOMERS.CREATE_ADVANCE_PAYMENT_TITLE',
    icon: 'fa fa-plus',
  };

  /**
   * Shows or hide the advance payments list.
   */
  advancePaymentsListVisibility = false;

  /**
   * The list of active advancePayments.
   */
  advancePayments: CustomerLog[] = [];

  /**
   * Indicates whether there is a create payment process is running or not.
   */
  isCreating$: Observable<boolean>;

  /**
   * The create customer payments form.
   */
  form: FormGroup;

  /**
   * The id of the customer to only show his invoices.
   */
  customerId: number;

  /**
   * The name of the current customer.
   */
  customerName: string;

  /**
   * The set of subscriptions on this components,
   * these subscriptions must be unsubscribed before this component got destroyed.
   */
  subscriptions = new Subscription();

  /**
   * Used to add new sale invoice form items to the sale invoices form.
   */
  newInvoices: CustomerPaymentFormItem[];

  /**
   * Gets the attachments form-array.
   */
  get attachmentsForm(): FormArray {
    return this.form?.controls.attachments as FormArray;
  }

  /**
   * Gets the sale invoices form-array.
   */
  get paymentsForm(): FormArray {
    return this.form?.controls.payments as FormArray;
  }

  /**
   * Gets the sum of selected sale invoices values.
   */
  get paymentsTotal(): number {
    return Decimal.sum(0, ...this.paymentsForm?.controls.map((payment) => payment.value.value ?? 0)).toNumber();
  }

  /**
   * Gets or sets the current selected language.
   */
  get currentLang() {
    return this.translationService.language;
  }

  /**
   * @param modalService The modal service.
   * @param route The activated route.
   * @param notificationService the notification service.
   * @param translationService the translation service.
   * @param salesStore$ the sales-store module.
   */
  constructor(
    private modalService: NgbModal,
    private route: ActivatedRoute,
    private notificationService: NotificationService,
    private translationService: TranslationService,
    private salesStore$: Store<fromSalesStore.SalesState>
  ) {}

  ngOnInit(): void {
    this.init();
  }

  /**
   * Destroy component data
   */
  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  /**
   * Initialize component data.
   */
  init() {
    this.initForm();

    /* Load the data. */
    this.isCreating$ = this.salesStore$.pipe(select(fromSalesStore.getCustomerPaymentByAdvancePaymentCreateCompleted));

    /**
     * Fetch query params params.
     */
    if (this.route.snapshot.queryParamMap.has('customerId')) {
      this.customerId = +this.route.snapshot.queryParams.customerId;

      /**
       * Select the customer name.
       */
      this.subscriptions.add(
        this.salesStore$
          .pipe(
            select(fromSalesStore.getSelectedCustomer),
            tap((customer) => {
              if (customer) {
                this.customerName = this.currentLang === 'en' ? customer.nameEn : customer.name;

                /* Update page info. */
                this.pageInfo = {
                  ...this.pageInfo,
                  subTitle: `(${this.customerName})`,
                };
              }
            })
          )
          .subscribe()
      );
    }

    /**
     * Reset form controls when new customer payment is created.
     */
    this.subscriptions.add(
      this.salesStore$
        .pipe(
          select(fromSalesStore.getCustomerPaymentByAdvancePaymentCreateCompleted),
          skip(1),
          tap((isCreated) => {
            if (isCreated) {
              this.initForm();
            }
          })
        )
        .subscribe()
    );
  }

  /**
   * Initialize forms and add validators.
   */
  initForm() {
    const customerLogIdFormControl = new FormControl(null, Validators.required);
    const paymentsForm = new FormArray([], CustomValidators.arrayItems(1));

    this.form = new FormGroup({
      customerLogId: customerLogIdFormControl,
      transactionDate: new FormControl(null),
      notes: new FormControl('', [Validators.minLength(0), Validators.maxLength(200)]),
      payments: paymentsForm,
      attachments: new FormArray([]),
    });

    this.addBlankAttachment();
  }

  /**
   * Adds a blank attachment form-control to the attachments form for quick start..
   */
  addBlankAttachment() {
    this.attachmentsForm.push(new FormControl());
  }

  /**
   * Opens the modal of the given templateRef.
   * @param modalRef The modal templateRef to be opened.
   */
  openModal(modalRef) {
    this.modalService.open(modalRef);
  }

  /**
   * Open confirm reset invoice modal .
   */
  openResetModal() {
    this.openModal(this.resetModalRef);
  }

  /**
   * Resets all form data.
   *
   * It only adds one product for quick start.
   */
  resetForm() {
    this.form.reset({ customerLogId: null, notes: '', transactionDate: {} });
    this.paymentsForm.clear();
    this.attachmentsForm.clear();
    this.addBlankAttachment();
  }

  /**
   * Submits the form.
   */
  submit() {
    let errorMessage = new NotificationMessage();

    if (this.form.invalid) {
      if (this.form.get('customerLogId').invalid) {
        errorMessage.title = this.translationService.translate(
          'SALES.CUSTOMERS.CUSTOMER_DATA_VALIDATION.ADVANCE_PAYMENT_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate('SALES.CUSTOMERS.CUSTOMER_DATA_VALIDATION.ADVANCE_PAYMENT_IS_REQUIRED'),
        ];
      } else if (this.form.get('notes').invalid) {
        errorMessage.title = this.translationService.translate('SALES.CUSTOMERS.CUSTOMER_DATA_VALIDATION.NOTES_ERROR');
        errorMessage.body = [
          this.translationService.translate('SALES.CUSTOMERS.CUSTOMER_DATA_VALIDATION.NOTES_LENGTH_ERROR'),
        ];
      } else if (this.form.get('payments').invalid) {
        /**
         * Check if some of payments has errors.
         */
        if (this.paymentsForm.controls.length > 0) {
          for (const paymentFormGroup of this.paymentsForm.controls) {
            if (paymentFormGroup.valid) {
              continue;
            }

            errorMessage.title = this.translationService.translate(
              'SALES.CUSTOMERS.CUSTOMER_DATA_VALIDATION.PURCHASE_INVOICE_NUMBER_ERROR',
              { purchaseInvoiceNumber: (paymentFormGroup.value as CustomerPaymentFormItem).invoice.invoiceNum }
            );
            errorMessage.body = [];

            if (paymentFormGroup.get('value').invalid) {
              errorMessage.body.push(
                this.translationService.translate('SALES.CUSTOMERS.CUSTOMER_DATA_VALIDATION.PAYMENT_VALUE_ERROR')
              );
              errorMessage.body.push(
                this.translationService.translate('SALES.CUSTOMERS.CUSTOMER_DATA_VALIDATION.PAYMENT_VALUE_IS_REQUIRED'),
                this.translationService.translate('SALES.CUSTOMERS.CUSTOMER_DATA_VALIDATION.PAYMENT_VALUE_LENGTH_ERROR')
              );
            }

            break;
          }
        } else {
          errorMessage.title = this.translationService.translate(
            'SALES.CUSTOMERS.CUSTOMER_DATA_VALIDATION.INVOICE_COUNT_ERROR'
          );
          errorMessage.body = [
            this.translationService.translate('SALES.CUSTOMERS.CUSTOMER_DATA_VALIDATION.INVOICE_COUNT_LENGTH_ERROR'),
          ];
        }
      }

      this.form.markAllAsTouched();
      return this.notificationService.warningWithTitle(errorMessage);
    }

    errorMessage = new NotificationMessage();

    const payments = this.paymentsForm.value as CustomerPaymentFormItem[];
    if (uniq(payments.map((payment) => payment.invoice.customerId)).length !== 1) {
      errorMessage.title = this.translationService.translate('SALES.CUSTOMERS.CUSTOMER_DATA_VALIDATION.INVOICES_ERROR');
      errorMessage.body = [
        this.translationService.translate('SALES.CUSTOMERS.CUSTOMER_DATA_VALIDATION.INVOICES_VALID_ERROR'),
      ];

      this.form.markAllAsTouched();
      return this.notificationService.warningWithTitle(errorMessage);
    }

    this.openModal(this.confirmModalRef);
  }

  /**
   * Confirms the form submit.
   */
  confirm() {
    let fromDateFormatted;
    const { transactionDate } = this.form.value;

    if (transactionDate) {
      fromDateFormatted = new Date(transactionDate.year, transactionDate.month - 1, transactionDate.day);
      fromDateFormatted.setHours(new Date().getHours(), new Date().getMinutes());
    }

    /**
     * The list of payments in the payments form.
     */
    const payments = this.paymentsForm.value.map((item: CustomerPaymentFormItem) => {
      const inputPayment: CreateCustomerPaymentInput = {
        saleInvoiceId: item.invoice.id,
        value: item.value,
      };

      return inputPayment;
    });

    this.salesStore$.dispatch(
      new fromSalesStore.CreateCustomerPaymentByAdvancePayment({
        customerId: this.customerId,
        customerLogId: this.form.value.customerLogId,
        notes: this.form.value.notes,
        transactionDate: fromDateFormatted,
        payments,
        attachments: this.attachmentsForm.value.filter((file) => !!file),
      })
    );
  }

  /**
   * Confirms leaving the page before routing.
   */
  confirmDeactivate() {
    if (this.form.dirty === true) {
      /**
       * Message to warn the user before leaving the detailed form.
       */
      return confirm(this.translationService.translate('SHARED.CONFIRM.LEAVING'));
    }
    return true;
  }

  /**
   * Selects the newly selected advancePayment from the advancePayments search list.
   * @param advancePayments The list of newly selected advancePayments to select the only one in the list.
   */
  selectAdvancePayment([advancePayment]: CustomerLog[]) {
    if (advancePayment) {
      this.advancePayments = [advancePayment];
      this.form.patchValue({ customerLogId: advancePayment.id });
    }
  }
}
