<!-- petty cash refund requests list starts -->
<ng-template #modalRef let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title text-primary" id="modal-basic-title">
      {{ 'FINANCES.PETTY_CASH_REFUND_REQUESTS.PETTY_CASH_REFUND_REQUESTS' | translate }}
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="d(); closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <!-- petty cash list starts -->
  <div class="modal-body">
    <div class="row">
      <div class="col-md-12">
        <form class="row" [formGroup]="searchForm" (ngSubmit)="search()">
          <div class="form-group col">
            <label for="pettyCash">{{
              'FINANCES.PETTY_CASH_REFUND_REQUESTS.PETTY_CASH_REFUND_REQUEST_DATA.PETTY_CASH' | translate
            }}</label>
            <ng-select
              class="round"
              formControlName="pettyCash"
              [multiple]="true"
              [closeOnSelect]="false"
              labelForId="pettyCash"
              searchable="true"
              [placeholder]="
                'FINANCES.PETTY_CASH_REFUND_REQUESTS.PETTY_CASH_REFUND_REQUEST_DATA.PETTY_CASH_PLACEHOLDER' | translate
              "
              [notFoundText]="'SHARED.SEARCH.SEARCH_NOT_FOUND' | translate"
              (open)="pettyCashListVisibility = true"
            >
              <ng-option *ngFor="let petty of pettyCash" [value]="petty.id">
                {{ petty.description }}
              </ng-option>
            </ng-select>
          </div>
          <div class="form-group col">
            <div class="row">
              <div class="form-group col-12 col-sm-6 mb-0">
                <label for="fromDate">{{ 'SHARED.SEARCH.FROM_DATE' | translate }}</label>
                <div class="input-group round">
                  <input
                    class="form-control"
                    id="fromDate"
                    name="fromDate"
                    formControlName="fromDate"
                    [placeholder]="'SHARED.SEARCH.DATE_PLACEHOLDER' | translate"
                    ngbDatepicker
                    #fromDate="ngbDatepicker"
                  />
                  <div class="input-group-append">
                    <span
                      class="input-group-text gradient-light-blue-indigo"
                      style="cursor: pointer;"
                      (click)="fromDate.toggle()"
                    >
                      <i class="fa fa-calendar"></i>
                    </span>
                  </div>
                </div>
              </div>
              <div class="form-group col-12 col-sm-6 mb-0">
                <label for="toDate">{{ 'SHARED.SEARCH.TO_DATE' | translate }}</label>
                <div class="input-group round">
                  <input
                    class="form-control"
                    id="toDate"
                    name="toDate"
                    formControlName="toDate"
                    [placeholder]="'SHARED.SEARCH.DATE_PLACEHOLDER' | translate"
                    ngbDatepicker
                    #toDate="ngbDatepicker"
                  />
                  <div class="input-group-append">
                    <span
                      class="input-group-text gradient-light-blue-indigo"
                      style="cursor: pointer;"
                      (click)="toDate.toggle()"
                    >
                      <i class="fa fa-calendar"></i>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-auto form-group d-flex">
            <button
              type="submit"
              class="btn btn-raised btn-primary align-self-end round mx-1 mb-0"
              [disabled]="isSearching$ | async"
            >
              <i class="fa fa-search"></i>
            </button>
          </div>
        </form>
      </div>
      <div class="col-md-12" *ngIf="(pettyCashRefundRequests$ | async)?.length; else noItems">
        <div class="row">
          <div class="col-md-12 alert alert-light text-center" role="alert">
            {{ 'SHARED.SEARCH.SEARCH_RESULTS' | translate }} ({{ (paginationInfo$ | async).total | toNumber }})
            {{ 'FINANCES.PETTY_CASH_REFUND_REQUESTS.PETTY_CASH_REFUND_REQUEST_DATA.REFUND_REQUEST' | translate }}
          </div>
          <div class="col-md-12">
            <div class="table-responsive">
              <table class="table text-center">
                <thead>
                  <tr>
                    <th class="fit-width" *ngIf="!closeOnSelect">
                      <a
                        class="primary p-0"
                        (click)="selectPettyCashRefundRequest(pettyCashRefundRequestsList)"
                        [ngbTooltip]="'SHARED.SEARCH.SELECT_ALL' | translate"
                      >
                        <i class="fa fa-check-circle font-medium-3"></i>
                      </a>
                    </th>
                    <th class="fit-width" *ngIf="closeOnSelect">
                      <i class="fa fa-gear font-medium-3"></i>
                    </th>
                    <th scope="col"><i class="fa fa-list-ol"></i></th>
                    <th scope="col">
                      {{ 'FINANCES.PETTY_CASH_REFUND_REQUESTS.PETTY_CASH_REFUND_REQUEST_DATA.PETTY_CASH' | translate }}
                    </th>
                    <th scope="col">
                      <i class="fa fa-usd"></i
                      ><span class="mx-1">{{
                        'FINANCES.PETTY_CASH_REFUND_REQUESTS.PETTY_CASH_REFUND_REQUEST_DATA.REFUNDS_TOTAL' | translate
                      }}</span>
                    </th>
                    <th scope="col">
                      {{
                        'FINANCES.PETTY_CASH_REFUND_REQUESTS.PETTY_CASH_REFUND_REQUEST_DATA.PETTY_CASH_REFUND_REQUEST_DATE'
                          | translate
                      }}
                    </th>
                    <th scope="col">{{ 'SHARED.SEARCH.CREATED_AT' | translate }}</th>
                    <th scope="col">
                      {{ 'FINANCES.PETTY_CASH_REFUND_REQUESTS.PETTY_CASH_REFUND_REQUEST_DATA.USER' | translate }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let request of pettyCashRefundRequests$ | async; trackBy: trackItems; let i = index">
                    <td>
                      <a
                        class="primary p-0"
                        (click)="selectPettyCashRefundRequest([request])"
                        [ngbTooltip]="
                          'FINANCES.PETTY_CASH_REFUND_REQUESTS.PETTY_CASH_REFUND_REQUEST_DATA.SELECT_PETTY_CASH_REFUND_REQUEST'
                            | translate
                        "
                      >
                        <i class="fa fa-check-circle-o font-medium-3"></i>
                      </a>
                    </td>
                    <th scope="row">{{ i + 1 }}</th>
                    <td>{{ request.pettyCash.description }}</td>
                    <td>{{ request.value | toDecimal }}</td>
                    <td>
                      <span [ngbTooltip]="request.transactionDate | date: 'time'">
                        {{ request.transactionDate | date }}
                      </span>
                    </td>
                    <td>
                      <span [ngbTooltip]="request.createdAt | date: 'time'">
                        {{ request.createdAt | date }}
                      </span>
                    </td>
                    <td>{{ request.user.name }}</td>
                  </tr>
                </tbody>
              </table>
              <div class="d-flex justify-content-between p-2">
                <ngb-pagination
                  *ngIf="paginationInfo$ | async; let pagination"
                  maxSize="5"
                  [collectionSize]="pagination.total"
                  [pageSize]="pagination.pageSize"
                  [page]="pagination.page"
                  (pageChange)="pageChanged($event)"
                  boundaryLinks="true"
                >
                  <ng-template ngbPaginationFirst>{{ 'SHARED.PAGINATION.PAGINATION_FIRST' | translate }}</ng-template>
                  <ng-template ngbPaginationFirst>{{
                    'SHARED.PAGINATION.PAGINATION_PREVIOUS' | translate
                  }}</ng-template>
                  <ng-template ngbPaginationFirst>{{ 'SHARED.PAGINATION.PAGINATION_NEXT' | translate }}</ng-template>
                  <ng-template ngbPaginationFirst>{{ 'SHARED.PAGINATION.PAGINATION_LAST' | translate }}</ng-template>
                </ngb-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <div class="modal-actions">
      <button type="button" class="btn btn-outline-dark round" (click)="c(); closeModal()">
        <i class="fa fa-times"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.CLOSE' | translate }}</span>
      </button>
    </div>
  </div>
</ng-template>
<!-- petty cash refund requests list ends -->

<!-- no items template starts -->
<ng-template #noItems>
  <div class="col-md-12 alert alert-warning mt-3 text-center" role="alert">
    {{ 'FINANCES.PETTY_CASH_REFUND_REQUESTS.SEARCH_PETTY_CASH_REFUND_REQUEST_NOT_FOUND' | translate }}
    <a
      *ngIf="[Claims.CREATE_PETTY_CASH_REFUND_REQUEST] | authorize | async"
      routerLink="/finances/petty-cash-refund-requests/create"
      class="btn btn-raised btn-success round"
    >
      <i class="fa fa-plus"></i>
      <span class="mx-1">{{ 'SHARED.CONFIRM.ADD' | translate }}</span>
    </a>
  </div>
</ng-template>
<!-- no items template ends -->

<!-- petty cash search starts -->
<app-petty-cash-search [(visible)]="pettyCashListVisibility" (pettyCash)="selectPettyCash($event)">
</app-petty-cash-search>
<!-- petty cash search ends -->
