<!-- page header starts -->
<app-page-header [info]="pageInfo"></app-page-header>
<!-- page header ends -->

<!-- accounts list starts -->
<div class="row">
  <div class="col-md-12">
    <div class="card">
      <div class="card-header mb-3">
        <h4 class="card-title">{{ 'SHARED.SEARCH.SEARCH_TOOLS' | translate }}</h4>
      </div>
      <div class="card-content">
        <div class="px-3">
          <div class="row">
            <div class="col-md-12">
              <form class="row" [formGroup]="searchForm">
                <div class="form-group col">
                  <input
                    type="text"
                    class="form-control round"
                    [placeholder]="'FINANCES.ACCOUNTS.ACCOUNT_DATA.ACCOUNT_CODE_SEARCH_PLACEHOLDER' | translate"
                    formControlName="description"
                    (input)="search($event)"
                  />
                </div>
                <div class="col-md-auto form-group d-flex">
                  <button
                    type="button"
                    class="btn btn-raised btn-primary align-self-end round mx-1 mb-0"
                    (click)="initForm(); search()"
                  >
                    <i class="fa fa-refresh"></i>
                  </button>
                  <a
                    *ngIf="[Claims.CREATE_ACCOUNT] | authorize | async"
                    routerLink="/finances/accounts/create"
                    class="btn btn-raised btn-success align-self-end round mx-1 mb-0"
                  >
                    <i class="fa fa-plus"></i>
                    <span class="mx-1">{{ 'SHARED.CONFIRM.ADD_ACCOUNT' | translate }}</span>
                  </a>
                  <a
                    *ngIf="[Claims.CREATE_ACCOUNT] | authorize | async"
                    routerLink="/finances/accounts/create-secondary-account"
                    class="btn btn-raised btn-success align-self-end round mx-1 mb-0"
                  >
                    <i class="fa fa-plus"></i>
                    <span class="mx-1">{{ 'SHARED.CONFIRM.ADD_SECONDARY_ACCOUNT' | translate }}</span>
                  </a>
                  <app-export-tools
                    *ngIf="(accounts$ | async)?.length"
                    class="d-flex align-self-end mx-1"
                    targetElementId="accounts-table-export-target-element"
                    [outputFileName]="'SHARED.EXPORT_TABLES.ACCOUNTS_TABLE' | translate"
                  ></app-export-tools>
                </div>
              </form>
            </div>
            <div class="col-md-12" *ngIf="(accounts$ | async)?.length; else noItems">
              <div class="row">
                <div class="col-md-12 alert alert-light text-center" role="alert">
                  {{ 'SHARED.SEARCH.SEARCH_RESULTS' | translate }} ({{ (paginationInfo$ | async).total | toNumber }})
                  {{ 'FINANCES.ACCOUNTS.ACCOUNT_DATA.ACCOUNT' | translate }}
                </div>
                <div class="col-md-12">
                  <div class="table-responsive">
                    <table class="table text-center" id="accounts-table-export-target-element">
                      <thead>
                        <tr>
                          <th scope="col"><i class="fa fa-list-ol"></i></th>
                          <th scope="col">{{ 'FINANCES.ACCOUNTS.ACCOUNT_DATA.ACCOUNT_CODE' | translate }}</th>
                          <th scope="col">{{ 'FINANCES.ACCOUNTS.ACCOUNT_DATA.ACCOUNT_DESCRIPTION' | translate }}</th>
                          <th scope="col">{{ 'FINANCES.ACCOUNTS.ACCOUNT_DATA.PRIMARY_ACCOUNT' | translate }}</th>
                          <th scope="col">{{ 'FINANCES.ACCOUNTS.ACCOUNT_DATA.IS_SECONDARY_ACCOUNT' | translate }}</th>
                          <th scope="col">{{ 'FINANCES.ACCOUNTS.ACCOUNT_DATA.SECONDARY_ACCOUNT' | translate }}</th>
                          <th scope="col">{{ 'SHARED.TOOLS' | translate }}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let account of accounts$ | async; trackBy: trackElements; let i = index">
                          <th scope="row">{{ i + 1 }}</th>
                          <td>{{ account.code }}</td>
                          <td>{{ account.description }}</td>
                          <td>{{ account.primaryAccount.name | localize: account.primaryAccount.nameEn | async }}</td>
                          <td>
                            <app-status
                              [status]="account.isSecondaryAccount"
                              [activeText]="'FINANCES.ACCOUNTS.ACCOUNT_DATA.SECONDARY_ACCOUNT_TITLE' | translate"
                              *ngIf="account.isSecondaryAccount"
                            >
                            </app-status>
                          </td>
                          <td>{{ account.secondaryAccount?.description }}</td>
                          <td>
                            <ng-container *ngIf="([Claims.UPDATE_ACCOUNT] | authorize | async) && !account.isReadOnly">
                              <a
                                *ngIf="account.secondaryAccountId"
                                class="info p-0"
                                [routerLink]="['/finances/accounts/update', account.id]"
                                [ngbTooltip]="
                                  'FINANCES.ACCOUNTS.ACCOUNT_DATA.ACCOUNT_DATA.UPDATE_ACCOUNT_DATA' | translate
                                "
                              >
                                <i class="fa fa-edit font-medium-3 mr-2"></i>
                              </a>
                              <a
                                *ngIf="!account.secondaryAccountId"
                                class="info p-0"
                                [routerLink]="['/finances/accounts/update-secondary-account', account.id]"
                                [ngbTooltip]="'FINANCES.ACCOUNTS.UPDATE_SECONDARY_ACCOUNT_DATA' | translate"
                              >
                                <i class="fa fa-edit font-medium-3 mr-2"></i>
                              </a>
                            </ng-container>
                            <a
                              *ngIf="([Claims.DELETE_ACCOUNT] | authorize | async) && !account.isReadOnly"
                              class="danger p-0"
                              [ngbTooltip]="'SHARED.CONFIRM.DELETE' | translate"
                              (click)="selectedAccount = account; openModal(deleteModalRef)"
                            >
                              <i class="fa fa-close font-medium-3 mr-2"></i>
                            </a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div class="d-flex justify-content-between p-2">
                      <ngb-pagination
                        *ngIf="paginationInfo$ | async; let pagination"
                        maxSize="5"
                        [collectionSize]="pagination.total"
                        [pageSize]="pagination.pageSize"
                        [page]="pagination.page"
                        (pageChange)="pageChanged($event)"
                        boundaryLinks="true"
                      >
                        <ng-template ngbPaginationFirst>{{
                          'SHARED.PAGINATION.PAGINATION_FIRST' | translate
                        }}</ng-template>
                        <ng-template ngbPaginationFirst>{{
                          'SHARED.PAGINATION.PAGINATION_PREVIOUS' | translate
                        }}</ng-template>
                        <ng-template ngbPaginationFirst>{{
                          'SHARED.PAGINATION.PAGINATION_NEXT' | translate
                        }}</ng-template>
                        <ng-template ngbPaginationFirst>{{
                          'SHARED.PAGINATION.PAGINATION_LAST' | translate
                        }}</ng-template>
                      </ngb-pagination>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- accounts list ends -->

<!-- no items template starts -->
<ng-template #noItems>
  <div class="col-md-12 alert alert-warning mt-3 text-center" role="alert">
    {{ 'FINANCES.ACCOUNTS.SEARCH_ACCOUNTS_NOT_FOUND' | translate }}
    <a
      *ngIf="[Claims.CREATE_ACCOUNT] | authorize | async"
      routerLink="/finances/accounts/create"
      class="btn btn-raised btn-success round"
    >
      <i class="fa fa-plus"></i>
      <span class="mx-1">{{ 'SHARED.CONFIRM.ADD' | translate }}</span>
    </a>
  </div>
</ng-template>
<!-- no items template ends -->

<!-- delete account modal starts -->
<ng-template #deleteModalRef let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title text-danger" id="modal-basic-title">
      {{ 'FINANCES.ACCOUNTS.CONFIRM_DELETE_ACCOUNT_TITLE' | translate }}
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="d()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-12">
        <p class="text-danger">{{ 'FINANCES.ACCOUNTS.CONFIRM_DELETE_ACCOUNT_MESSAGE' | translate }}</p>
      </div>
      <div class="form-group col-6">
        <label for="selectedAccount.code">{{ 'FINANCES.ACCOUNTS.ACCOUNT_DATA.ACCOUNT_CODE' | translate }}</label>
        <input
          type="text"
          id="selectedAccount.code"
          class="form-control round"
          [placeholder]="'FINANCES.ACCOUNTS.ACCOUNT_DATA.ACCOUNT_CODE_PLACEHOLDER' | translate"
          readonly
          [value]="selectedAccount.code"
        />
      </div>
      <div class="form-group col-6">
        <label for="selectedAccount.description">{{
          'FINANCES.ACCOUNTS.ACCOUNT_DATA.ACCOUNT_DESCRIPTION' | translate
        }}</label>
        <input
          type="text"
          id="selectedAccount.description"
          class="form-control round"
          [placeholder]="'FINANCES.ACCOUNTS.ACCOUNT_DATA.ACCOUNT_DESCRIPTION_PLACEHOLDER' | translate"
          readonly
          [value]="selectedAccount.description"
        />
      </div>
      <div class="form-group col-6">
        <label for="selectedAccount.primaryAccount.name">{{
          'FINANCES.ACCOUNTS.ACCOUNT_DATA.PRIMARY_ACCOUNT' | translate
        }}</label>
        <input
          type="text"
          id="selectedAccount.primaryAccount.name"
          class="form-control round"
          [placeholder]="'FINANCES.ACCOUNTS.ACCOUNT_DATA.PRIMARY_ACCOUNT_PLACEHOLDER' | translate"
          readonly
          [value]="selectedAccount.primaryAccount.name | localize: selectedAccount.primaryAccount.nameEn | async"
        />
      </div>
      <div class="form-group col-6" *ngIf="selectedAccount.secondaryAccount">
        <label for="selectedAccount.secondaryAccount.description">{{
          'FINANCES.ACCOUNTS.ACCOUNT_DATA.SECONDARY_ACCOUNT' | translate
        }}</label>
        <input
          type="text"
          id="selectedAccount.secondaryAccount.description"
          class="form-control round"
          [placeholder]="'FINANCES.ACCOUNTS.ACCOUNT_DATA.SECONDARY_ACCOUNT_PLACEHOLDER' | translate"
          readonly
          [value]="selectedAccount.secondaryAccount.description"
        />
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="modal-actions">
      <button
        type="button"
        class="btn btn-raised btn-danger round"
        (click)="c(); delete(selectedAccount.id)"
        ngbAutofocus
        [disabled]="isDeleting$ | async"
      >
        <i class="fa fa-check"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.DELETE' | translate }}</span>
      </button>
      <button type="button" class="btn btn-outline-dark round" (click)="c()">
        <i class="fa fa-times"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.CANCEL' | translate }}</span>
      </button>
    </div>
  </div>
</ng-template>
<!-- delete account modal ends -->
