import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';

import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

import { NotificationService, PaginationInfo, TranslationService } from 'shared';
import * as fromFinancesStore from 'finances/store';
import * as fromLookupsStore from 'lookups/store';
import { Payroll } from 'finances/models';
import { Location } from 'stores/models';
import { Department, Employee } from 'hr/models';

@Component({
  selector: 'app-paid-payrolls-search',
  templateUrl: './paid-payrolls-search.component.html',
  styles: [],
})
export class PaidPayrollsSearchComponent implements OnInit {
  /**
   * The modal template reference.
   */
  @ViewChild('modalRef') modalRef: ElementRef<any>;

  /**
   * Indicates whether the modal will automatically close after select the first product.
   *
   * Be default it will not be closed.
   */
  @Input() closeOnSelect = false;

  /**
   * Indicates whether the products displayed on the list should be all active.
   *
   * Be default it will not be closed.
   */
  @Input() onlyActive = false;

  /**
   * The list of selected employees.
   */
  employees: Employee[] = [];

  /**
   * The list of selected departments.
   */
  departments: Department[] = [];

  /**
   * The list of selected locations.
   */
  locations: Location[] = [];

  /**
   * Shows or hide the employees list.
   */
  employeesListVisibility = false;

  /**
   * Shows or hide the departments list.
   */
  departmentsListVisibility = false;

  /**
   * Shows or hide the locations list.
   */
  locationsListVisibility = false;

  /**
   * Sets the visibility of this component modal.
   *
   * Handles visibility change in two-data-binding approach.
   */
  @Input() set visible(isShown: boolean) {
    if (isShown && this.modalRef) {
      this.openModal(this.modalRef);
    } else {
      this.closeModal();
    }

    this.visibleChange.emit(isShown);
  }

  /**
   * Outputs a value that indicates whether the modal is shown or not.
   *
   * Handles visibility change in two-data-binding approach.
   */
  @Output() visibleChange = new EventEmitter<boolean>();

  /** Outputs the selected paid payroll. */
  @Output() paidPayrolls = new EventEmitter<Payroll[]>();

  /**
   * The list of paid payroll.
   */
  paidPayrolls$: Observable<Payroll[]>;

  /**
   * The list of paid payroll.
   */
  paidPayrollsList: Payroll[];

  /**
   * The initial `fromDate` value for search.
   */
  initialFromDate = new Date();

  /**
   * The initial `toDate` value for search.
   * It's the current day of month.
   */
  initialToDate = new Date();

  /**
   * The pagination info.
   */
  paginationInfo$: Observable<PaginationInfo>;

  /**
   * Indicates whether there is a search process is running or not.
   */
  isSearching$: Observable<boolean>;

  /**
   * The search form.
   */
  searchForm: FormGroup;

  /**
   * The ngb-modal reference to use it when modal needed to be closed.
   */
  modal: NgbModalRef;

  /**
   * @param modalService The modal service.
   * @param notificationService The notification service.
   * @param financesStore$ The finances store.
   * @param lookupsStore$ The lookups store.
   * @param translationService The translation service.
   */
  constructor(
    private modalService: NgbModal,
    private notificationService: NotificationService,
    private financesStore$: Store<fromFinancesStore.FinancesState>,
    private lookupsStore$: Store<fromLookupsStore.LookupsState>,
    private translationService: TranslationService
  ) {}

  ngOnInit(): void {
    this.init();
  }

  /**
   * Initialize component data.
   */
  init() {
    this.initForm();

    /**
     * Load data.
     */
    this.isSearching$ = this.financesStore$.pipe(select(fromFinancesStore.getPaidPayrollsSearching));

    let isManualSearchTriggeredBeforeForPaidPayroll = false;
    this.paidPayrolls$ = this.financesStore$.pipe(
      select(fromFinancesStore.getPaidPayrolls),
      tap((paidPayroll) => {
        if (paidPayroll) {
          this.paidPayrollsList = paidPayroll;
        }
        if (!isManualSearchTriggeredBeforeForPaidPayroll && !paidPayroll.length) {
          isManualSearchTriggeredBeforeForPaidPayroll = true;
          this.searchPayrolls();
        }
      })
    );

    this.paginationInfo$ = this.financesStore$.pipe(select(fromFinancesStore.getPaidPayrollsPaginationInfo));
  }

  /**
   * Initialize form and add validators.
   */
  initForm() {
    this.searchForm = new FormGroup({
      employees: new FormControl([]),
      departments: new FormControl([]),
      locations: new FormControl([]),
      fromDate: new FormControl({
        year: this.initialFromDate.getFullYear(),
        month: this.initialFromDate.getMonth() + 1,
        day: this.initialFromDate.getDate(),
      }),
      toDate: new FormControl({
        year: this.initialToDate.getFullYear(),
        month: this.initialToDate.getMonth() + 1,
        day: this.initialToDate.getDate(),
      }),
      page: new FormControl(1),
    });
  }

  /**
   * Handles search parameters change.
   */
  searchPayrolls(event?: KeyboardEvent) {
    if (event && event.key === 'Enter') {
      event.preventDefault();
      return;
    }

    this.applyFiltersAndSearch();
  }

  /**
   * Handles pagination page-changed event.
   * @param page The current selected page number.
   */
  pageChanged(page: number) {
    /** Update pagination page. */
    this.searchForm.patchValue({ page });

    this.applyFiltersAndSearch(page);
  }

  /**
   * Applies the search filters and dispatches a search action.
   * @param page The current page number, default to `1`.
   */
  applyFiltersAndSearch(page: number = 1) {
    if (!this.searchForm.get('fromDate')?.value || !this.searchForm.get('toDate')?.value) {
      return this.notificationService.warning(
        this.translationService.translate('HR.EMPLOYEES_MANAGEMENT.NOTIFICATION_MESSAGE.SELECT_DATE')
      );
    }

    const { employees, departments, locations, fromDate, toDate } = this.searchForm.value;
    const fromDateFormatted = new Date(fromDate.year, fromDate.month - 1, fromDate.day);
    fromDateFormatted.setHours(0, 0, 0, 0);

    const toDateFormatted = new Date(toDate.year, toDate.month - 1, toDate.day);
    toDateFormatted.setHours(23, 59, 59, 0);

    this.financesStore$.dispatch(
      new fromFinancesStore.SearchPaidPayrolls({
        employees,
        departments,
        locations,
        fromDate: fromDateFormatted,
        toDate: toDateFormatted,
        page,
      })
    );
  }

  /**
   * Enables trackBy feature for *ngFor utility to track items
   * depending on a comparer to reduce DOM change.
   * If the item is already exist then no HTML will change, only bounded values.
   * @param index The index of the item.
   * @param paidPayroll The item to determine if it was changed or not.
   */
  trackItems(index: number, paidPayroll: Payroll) {
    return paidPayroll ? paidPayroll.id : undefined;
  }

  /**
   * Handles paid payroll selected event.
   * @param paid payroll The list of paid payroll to notify selected.
   */
  selectPaidPayroll(paidPayroll: Payroll[]) {
    this.notificationService.successInstant(
      this.translationService.translate('FINANCES.HR.PAID_PAYROLLS.PAID_PAYROLLS_DATA.PAID_PAYROLL_SELECTED')
    );
    this.paidPayrolls.emit(paidPayroll);
    if (this.closeOnSelect) {
      this.closeModal();
    }
  }

  /**
   * Adds the newly selected employees the employees search list.
   * @param employees The list of newly selected employees to be added.
   */
  selectEmployees(employees: Employee[]) {
    const selectedIds: number[] = this.searchForm.get('employees').value;
    this.employees = [...this.employees.filter((employee) => selectedIds.includes(employee.id)), ...employees];
    this.searchForm.patchValue({ employees: this.employees.map((employee) => employee.id) });
  }

  /**
   * Adds the newly selected departments the departments search list.
   * @param departments The list of newly selected departments to be added.
   */
  selectDepartments(departments: Department[]) {
    const selectedIds: number[] = this.searchForm.get('departments').value;
    this.departments = [
      ...this.departments.filter((department) => selectedIds.includes(department.id)),
      ...departments,
    ];
    this.searchForm.patchValue({ departments: this.departments.map((department) => department.id) });
  }

  /**
   * Adds the newly selected locations the locations search list.
   * @param locations The list of newly selected locations to be added.
   */
  selectLocations(locations: Location[]) {
    const selectedIds: number[] = this.searchForm.get('locations').value;
    this.locations = [...this.locations.filter((location) => selectedIds.includes(location.id)), ...locations];
    this.searchForm.patchValue({ locations: this.locations.map((location) => location.id) });
  }

  /**
   * Opens the modal of the given templateRef.
   * @param modalRef The modal templateRef to be opened.
   */
  openModal(modalRef) {
    this.modal = this.modalService.open(modalRef, { size: 'xl' });
    this.modal.result.then(null, () => this.closeModal());
  }

  /**
   * Closes the currently opened modal.
   */
  closeModal() {
    this.modal?.dismiss();
    this.paidPayrolls.emit([]);
    this.visibleChange.emit(false);
  }
}
