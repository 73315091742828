import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';

import { Observable } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

import * as fromFinancesStore from 'finances/store';
import { Location } from 'stores/models';
import { NotificationService, PaginationInfo, TranslationService } from 'shared';
import { Department, Employee, EmployeeLoanSettlement } from 'hr/models';

@Component({
  selector: 'app-loan-settlements',
  templateUrl: './loan-settlements.component.html',
  styles: [],
})
export class LoanSettlementsComponent implements OnInit {
  /**
   * The create modal template reference.
   */
  @ViewChild('infoEmployeeLoanSettlementModalRef') infoEmployeeLoanSettlementModalRef: ElementRef<any>;

  /**
   * The list of loan settlements.
   */
  settlements$: Observable<EmployeeLoanSettlement[]>;

  /**
   * Indicates whether there is a search process is running or not.
   */
  isSearching$: Observable<boolean>;

  /**
   * The pagination info.
   */
  paginationInfo$: Observable<PaginationInfo>;

  /**
   * The ngb-modal reference to use it when modal needed to be closed.
   */
  modal: NgbModalRef;

  /**
   * Gets or sets the currently selected loan for view attachments.
   */
  selectedLoanSettlement: EmployeeLoanSettlement;

  /**
   * The list of selected employees.
   */
  employees: Employee[] = [];

  /**
   * The list of selected departments.
   */
  departments: Department[] = [];

  /**
   * The list of selected locations.
   */
  locations: Location[] = [];

  /**
   * Shows or hide the employees list.
   */
  employeesListVisibility = false;

  /**
   * Shows or hide the departments list.
   */
  departmentsListVisibility = false;

  /**
   * Shows or hide the locations list.
   */
  locationsListVisibility = false;

  /**
   * The search form.
   */
  searchForm: FormGroup;

  /** The initial `fromDate` value for search. */
  initialFromDate = new Date();

  /** The initial `toDate` value for search.
   * It's the current day of month.
   */
  initialToDate = new Date();

  /**
   * @param financeStore$ The finance-store.
   * @param translationService The translation service.
   * @param notificationService The notification service.
   * @param modalService The modal service.
   */
  constructor(
    private financeStore$: Store<fromFinancesStore.HrState>,
    private translationService: TranslationService,
    private notificationService: NotificationService,
    private modalService: NgbModal
  ) {}

  ngOnInit(): void {
    this.init();
  }

  /**
   * Initialize component data.
   */
  init() {
    /** Initialize form. */
    this.initForm();

    /** Loads data. */
    this.isSearching$ = this.financeStore$.pipe(select(fromFinancesStore.getEmployeeLoanSettlementsSearching));
    this.paginationInfo$ = this.financeStore$.pipe(select(fromFinancesStore.getEmployeeLoanSettlementsPaginationInfo));
    this.settlements$ = this.financeStore$.pipe(select(fromFinancesStore.getEmployeeLoanSettlements));
  }

  /**
   * Initialize form and add validators.
   */
  initForm() {
    this.searchForm = new FormGroup({
      employees: new FormControl([]),
      departments: new FormControl([]),
      locations: new FormControl([]),
      fromDate: new FormControl({
        year: this.initialFromDate.getFullYear(),
        month: this.initialFromDate.getMonth() + 1,
        day: this.initialFromDate.getDate(),
      }),
      toDate: new FormControl({
        year: this.initialToDate.getFullYear(),
        month: this.initialToDate.getMonth() + 1,
        day: this.initialToDate.getDate(),
      }),
      page: new FormControl(1),
    });
  }

  /**
   * Handles search parameters change.
   */
  search(event?: KeyboardEvent) {
    if (event && event.key === 'Enter') {
      event.preventDefault();
      return;
    }

    this.applyFiltersAndSearch();
  }

  /**
   * Handles pagination page-changed event.
   * @param page The current selected page number.
   */
  pageChanged(page: number) {
    /** Update pagination page. */
    this.searchForm.patchValue({ page });

    this.applyFiltersAndSearch(page);
  }

  /**
   * Applies the search filters and dispatches a search action.
   * @param page The current page number, default to `1`.
   */
  applyFiltersAndSearch(page: number = 1) {
    if (!this.searchForm.get('fromDate')?.value || !this.searchForm.get('toDate')?.value) {
      return this.notificationService.warning(
        this.translationService.translate('HR.EMPLOYEES.NOTIFICATION_MESSAGE.SELECT_DATE')
      );
    }

    const { employees, departments, locations, fromDate, toDate } = this.searchForm.value;
    const fromDateFormatted = new Date(fromDate.year, fromDate.month - 1, fromDate.day);
    fromDateFormatted.setHours(0, 0, 0, 0);

    const toDateFormatted = new Date(toDate.year, toDate.month - 1, toDate.day);
    toDateFormatted.setHours(23, 59, 59, 0);

    this.financeStore$.dispatch(
      new fromFinancesStore.SearchEmployeeLoanSettlements({
        employees,
        departments,
        locations,
        fromDate: fromDateFormatted,
        toDate: toDateFormatted,
        page,
      })
    );
  }

  /**
   * Enables trackBy feature for *ngFor utility to track items
   * depending on a comparer to reduce DOM change.
   * If the item is already exist then no HTML will change, only bounded values.
   * @param index The index of the item.
   * @param loanSettlements The item to determine if it was changed or not.
   */
  trackItems(index: number, loanSettlements: EmployeeLoanSettlement) {
    return loanSettlements ? loanSettlements.id : undefined;
  }

  /**
   * Opens the modal of the given templateRef.
   * @param modalRef The modal templateRef to be opened.
   */
  openModal(modalRef) {
    this.modalService.open(modalRef);
  }

  /**
   * Adds the newly selected employees the employees search list.
   * @param employees The list of newly selected employees to be added.
   */
  selectEmployees(employees: Employee[]) {
    const selectedIds: number[] = this.searchForm.get('employees').value;
    this.employees = [...this.employees.filter((employee) => selectedIds.includes(employee.id)), ...employees];
    this.searchForm.patchValue({ employees: this.employees.map((employee) => employee.id) });
  }

  /**
   * Adds the newly selected departments the departments search list.
   * @param departments The list of newly selected departments to be added.
   */
  selectDepartments(departments: Department[]) {
    const selectedIds: number[] = this.searchForm.get('departments').value;
    this.departments = [
      ...this.departments.filter((department) => selectedIds.includes(department.id)),
      ...departments,
    ];
    this.searchForm.patchValue({ departments: this.departments.map((department) => department.id) });
  }

  /**
   * Adds the newly selected locations the locations search list.
   * @param locations The list of newly selected locations to be added.
   */
  selectLocations(locations: Location[]) {
    const selectedIds: number[] = this.searchForm.get('locations').value;
    this.locations = [...this.locations.filter((location) => selectedIds.includes(location.id)), ...locations];
    this.searchForm.patchValue({ locations: this.locations.map((location) => location.id) });
  }

  /**
   * Opens the modal of the given templateRef.
   * @param modalRef The modal templateRef to be opened.
   */
  openInfoModal(modalRef) {
    /**
     * Open the modal & listen for user press ESC key.
     */
    this.modal = this.modalService.open(modalRef, { size: 'xl' });
    this.modal.result.then(null, () => this.closeModal());
  }

  /**
   * Closes the currently opened modal.
   */
  closeModal() {
    this.modalService.dismissAll();
  }
}
