import { createSelector } from '@ngrx/store';

import { selectLookupsState } from 'lookups/store/selectors/feature.selectors';
import { LookupsState, PurchaseRequestReceiveTypeState } from 'lookups/store/states';

/**
 * Gets the purchaseRequestReceiveType state.
 */
const selectPurchaseRequestReceiveTypes = createSelector(
  selectLookupsState,
  (state: LookupsState) => state.purchaseRequestReceiveTypes
);

/**
 * Gets the list of loaded purchaseRequestReceiveTypes.
 */
export const getPurchaseRequestReceiveTypes = createSelector(
  selectPurchaseRequestReceiveTypes,
  (state: PurchaseRequestReceiveTypeState) => state.data
);

/**
 * Gets the error that occurred in the state.
 */
export const getPurchaseRequestReceiveTypesError = createSelector(
  selectPurchaseRequestReceiveTypes,
  (state: PurchaseRequestReceiveTypeState) => state.error
);

/**
 * Determines if there is a running load process.
 */
export const getPurchaseRequestReceiveTypesIsLoading = createSelector(
  selectPurchaseRequestReceiveTypes,
  (state: PurchaseRequestReceiveTypeState) => state.isLoading
);

/**
 * Determines if the last load process has been ended successfully.
 */
export const getPurchaseRequestReceiveTypesIsLoaded = createSelector(
  selectPurchaseRequestReceiveTypes,
  (state: PurchaseRequestReceiveTypeState) => state.isLoaded
);
