import { Component, Input } from '@angular/core';

import { CustomerLog } from 'sales/models';

@Component({
  selector: 'app-journal-customer-log-details',
  templateUrl: './journal-customer-log-details.component.html',
  styles: [],
})
export class JournalCustomerLogDetailsComponent {
  /**
   * Sets the current journal customer log details.
   */
  @Input() customerLog: CustomerLog;
}
