import { SessionState } from 'auth/store/states/session.state';

/**
 * Represents the Authentication-Module state.
 */
export class AuthState {
  /**
   * Represents the currently logged in user's session.
   */
  public session: SessionState;
}
