import { Action } from '@ngrx/store';

import { AppHttpResponse } from 'shared';
import { CreateRegionInput, Region, UpdateRegionInput } from 'stores/models';

/**
 * The stores-module store region action types.
 */
export enum RegionsActionType {
  SEARCH_REGIONS = '[Stores] [Region] Search Regions',
  SEARCH_REGIONS_FAIL = '[Stores] [Region] Search Regions Fail',
  SEARCH_REGIONS_SUCCESS = '[Stores] [Region] Search Regions Success',

  FIND_REGION = '[Stores] [Region] Find Region',
  FIND_REGION_FAIL = '[Stores] [Region] Find Region Fail',
  FIND_REGION_SUCCESS = '[Stores] [Region] Find Region Success',

  CREATE_REGION = '[Stores] [Region] Create Region',
  CREATE_REGION_FAIL = '[Stores] [Region] Create Region Fail',
  CREATE_REGION_SUCCESS = '[Stores] [Region] Create Region Success',

  UPDATE_REGION = '[Stores] [Region] Update Region',
  UPDATE_REGION_FAIL = '[Stores] [Region] Update Region Fail',
  UPDATE_REGION_SUCCESS = '[Stores] [Region] Update Region Success',

  DELETE_REGION = '[Stores] [Region] Delete Region',
  DELETE_REGION_FAIL = '[Stores] [Region] Delete Region Fail',
  DELETE_REGION_SUCCESS = '[Stores] [Region] Delete Region Success',
}

/**
 * Represents a store Regions search action.
 */
export class SearchRegions implements Action {
  /**
   * The type of the action.
   */
  readonly type = RegionsActionType.SEARCH_REGIONS;

  /**
   * Fires a new regions search action.
   * @param payload the search parameters,
   * if omitted, all regions will be loaded.
   */
  constructor(
    public payload: {
      name: string;
      page: number;
    }
  ) {}
}

/**
 * Represents a store regions search fail action.
 */
export class SearchRegionsFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = RegionsActionType.SEARCH_REGIONS_FAIL;

  /**
   * Fires a new regions search fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store search success action.
 */
export class SearchRegionsSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = RegionsActionType.SEARCH_REGIONS_SUCCESS;

  /**
   * Fires a new regions search success action.
   * @param payload An object contains the list of regions that matches the search criteria
   * in addition to the pagination info.
   */
  constructor(public payload: AppHttpResponse<Region[]>) {}
}

/**
 * Represents a store find region action.
 */
export class FindRegion implements Action {
  /**
   * The type of the action.
   */
  readonly type = RegionsActionType.FIND_REGION;

  /**
   * Fires a new find region action.
   * @param payload the id of the region.
   */
  constructor(public payload: number) {}
}

/**
 * Represents a store find region fail action.
 */
export class FindRegionFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = RegionsActionType.FIND_REGION_FAIL;

  /**
   * Fires a new find region fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store find region success action.
 */
export class FindRegionSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = RegionsActionType.FIND_REGION_SUCCESS;

  /**
   * Fires a new find regions success action.
   * @param payload The loaded region.
   */
  constructor(public payload: AppHttpResponse<Region>) {}
}

/**
 * Represents a store region create action.
 */
export class CreateRegion implements Action {
  /** The type of the action. */
  readonly type = RegionsActionType.CREATE_REGION;

  /**
   * Fires a new region create action.
   * @param payload the new region data-model.
   */
  constructor(public payload: CreateRegionInput) {}
}

/**
 * Represents a store region create fail action.
 */
export class CreateRegionFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = RegionsActionType.CREATE_REGION_FAIL;

  /**
   * Fires a new region create fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store region create success action.
 */
export class CreateRegionSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = RegionsActionType.CREATE_REGION_SUCCESS;

  /**
   * Fires a new region create success action.
   * @param payload The created region.
   */
  constructor(public payload: AppHttpResponse<Region>) {}
}

/**
 * Represents a store region update action.
 */
export class UpdateRegion implements Action {
  /**
   * The type of the action.
   */
  readonly type = RegionsActionType.UPDATE_REGION;

  /**
   * Fires a new region update action.
   * @param payload The  region updated data.
   */
  constructor(public payload: UpdateRegionInput) {}
}

/**
 * Represents a store region update fail action.
 */
export class UpdateRegionFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = RegionsActionType.UPDATE_REGION_FAIL;

  /**
   * Fires a new region update fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store region update success action.
 */
export class UpdateRegionSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = RegionsActionType.UPDATE_REGION_SUCCESS;

  /**
   * Fires a new region update-success action.
   * @param payload The updated region.
   */
  constructor(public payload: AppHttpResponse<Region>) {}
}

/**
 * Represents a store region delete action.
 */
export class DeleteRegion implements Action {
  /**
   * The type of the action.
   */
  readonly type = RegionsActionType.DELETE_REGION;

  /**
   * Fires a new region delete action.
   * @param payload The region id.
   */
  constructor(public payload: number) {}
}

/**
 * Represents a store region delete fail action.
 */
export class DeleteRegionFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = RegionsActionType.DELETE_REGION_FAIL;

  /**
   * Fires a new region delete fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store region delete success action.
 */
export class DeleteRegionSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = RegionsActionType.DELETE_REGION_SUCCESS;

  /**
   * Fires a new region delete success action.
   * @param payload The deleted region.
   */
  constructor(public payload: AppHttpResponse<Region>) {}
}

/**
 * stores-module regions action types.
 */
export type RegionsActions =
  | SearchRegions
  | SearchRegionsFail
  | SearchRegionsSuccess
  | FindRegion
  | FindRegionFail
  | FindRegionSuccess
  | CreateRegion
  | CreateRegionFail
  | CreateRegionSuccess
  | UpdateRegion
  | UpdateRegionFail
  | UpdateRegionSuccess
  | DeleteRegion
  | DeleteRegionFail
  | DeleteRegionSuccess;
