import { Injectable } from '@angular/core';

import { of } from 'rxjs';
import { switchMap, map, catchError, tap } from 'rxjs/operators';
import { Effect, ofType, Actions } from '@ngrx/effects';

import { NotificationService, StatusCode, PAGINATION, TranslationService } from 'shared';
import { JournalsService } from 'finances/services/journals.service';
import {
  JournalsActionType,
  SearchJournals,
  SearchJournalsFail,
  SearchJournalsSuccess,
  FindJournal,
  FindJournalFail,
  FindJournalSuccess,
  CreateJournal,
  CreateJournalFail,
  CreateJournalSuccess,
} from 'finances/store/actions';

@Injectable()
export class JournalsEffects {
  constructor(
    private actions$: Actions,
    private journalsService: JournalsService,
    private notificationService: NotificationService,
    private translationService: TranslationService
  ) {}

  /* ========================= SEARCH_JOURNALS =================================== */
  @Effect()
  search$ = this.actions$.pipe(
    ofType(JournalsActionType.SEARCH_JOURNALS),
    switchMap(({ payload }: SearchJournals) =>
      this.journalsService
        .search(
          payload.journalTypes,
          payload.journalNum,
          payload.fromDate,
          payload.toDate,
          payload.page,
          PAGINATION.Journals
        )
        .pipe(
          map((response) => new SearchJournalsSuccess(response)),
          catchError((error) => of(new SearchJournalsFail(error)))
        )
    )
  );

  /* ========================= FIND_JOURNAL =================================== */
  @Effect()
  find$ = this.actions$.pipe(
    ofType(JournalsActionType.FIND_JOURNAL),
    switchMap((action: FindJournal) =>
      this.journalsService.findById(action.payload).pipe(
        map((response) => new FindJournalSuccess(response)),
        catchError((error) => of(new FindJournalFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  findFail$ = this.actions$.pipe(
    ofType(JournalsActionType.FIND_JOURNAL_FAIL),
    tap((action: FindJournalFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(this.translationService.translate('FINANCES.JOURNALS.JOURNAL_NOT_FOUND'));
      }
    })
  );

  /* ========================= CREATE_JOURNAL =================================== */
  @Effect()
  create$ = this.actions$.pipe(
    ofType(JournalsActionType.CREATE_JOURNAL),
    switchMap((action: CreateJournal) =>
      this.journalsService.create(action.payload).pipe(
        map((response) => new CreateJournalSuccess(response)),
        catchError((error) => of(new CreateJournalFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  createSuccess$ = this.actions$.pipe(
    ofType(JournalsActionType.CREATE_JOURNAL_SUCCESS),
    tap((action: CreateJournalSuccess) => {
      this.notificationService.success(this.translationService.translate('FINANCES.JOURNALS.JOURNAL_ADDED'));
    })
  );

  @Effect({ dispatch: false })
  createFail$ = this.actions$.pipe(
    ofType(JournalsActionType.CREATE_JOURNAL_FAIL),
    tap((action: CreateJournalFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );
}
