import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';

import { AppHttpResponse } from 'shared';
import { Sector } from 'stores/models';

/**
 * The sectors services includes functionality to search for an sector.
 */
@Injectable()
export class SectorsService {
  /**
   * The relative route for the sectors.
   *
   * No leading or trailing slashes required.
   */
  private sectorsApi = 'stores/sectors';

  constructor(private http: HttpClient) {}

  /**
   * Searches in the sectors by name.
   * @param name The name of the sector.
   * @param page The current pagination page number.
   * @param pageSize The maximum number of sectors allowed per one pagination page.
   */
  public search(name: string, page: number, pageSize: number): Observable<AppHttpResponse<Sector[]>> {
    const params = new HttpParams().set('name', name).set('page', page.toString()).set('pageSize', pageSize.toString());
    return this.http.get<AppHttpResponse<Sector[]>>(`${this.sectorsApi}`, { params });
  }
}
