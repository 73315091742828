import { Action } from '@ngrx/store';

import { AssetCategory } from 'lookups/models';
import { AppHttpResponse } from 'shared';

/**
 * The Lookups-Module store  asset category action types.
 */
export enum AssetCategoryActionType {
  GET_ALL_ASSET_CATEGORY = '[Lookups] [AssetCategory] Get All Asset Categories',
  GET_ALL_ASSET_CATEGORY_FAIL = '[Lookups] [AssetCategory] Get All Asset Categories Fail',
  GET_ALL_ASSET_CATEGORY_SUCCESS = '[Lookups] [AssetCategory] Get All Asset Categories Success',
}

/**
 * Represents a store  asset category action.
 */
export class GetAllAssetCategory implements Action {
  /**
   * The type of the action.
   */
  readonly type = AssetCategoryActionType.GET_ALL_ASSET_CATEGORY;

  /**
   * Fires a new  asset category action.
   */
  constructor() {}
}

/**
 * Represents a store  asset category fail action.
 */
export class GetAllAssetCategoryFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = AssetCategoryActionType.GET_ALL_ASSET_CATEGORY_FAIL;

  /**
   * Fires a new  asset category fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store  asset category success action.
 */
export class GetAllAssetCategorySuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = AssetCategoryActionType.GET_ALL_ASSET_CATEGORY_SUCCESS;

  /**
   * Fires a  asset category success action.
   * @param payload An object contains the list of  asset categories.
   */
  constructor(public payload: AppHttpResponse<AssetCategory[]>) {}
}

/**
 * Lookups-module  asset category action types.
 */
export type AssetCategoryActions = GetAllAssetCategory | GetAllAssetCategoryFail | GetAllAssetCategorySuccess;
