import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';

import { AppHttpResponse } from 'shared/models';

/**
 * The stores reports services.
 */
@Injectable()
export class StoresReportsService {
  /**
   * The relative route for the stores reports.
   *
   * No leading or trailing slashes required.
   */
  private storesReportsApi = 'reporting/stores';

  constructor(private http: HttpClient) {}

  /**
   * Gets the products matching the provided query params.
   * @param classes The array of the  ids of product classes.
   * @param searchByPurchasePrice If the products will be filtered against their purchase price.
   * @param minPurchasePrice The minimum purchase price value, this will effect if the `searchByPurchasePrice` is `true`.
   * @param maxPurchasePrice The maximum purchase price value, this will effect if the `searchByPurchasePrice` is `true`.
   * @param searchBySalePrice If the products will be filtered against their purchase price.
   * @param minSalePrice The minimum purchase price value, this will effect if the `searchBySalePrice` is `true`.
   * @param maxSalePrice The maximum purchase price value, this will effect if the `searchBySalePrice` is `true`.
   */
  public getProductsListReport(
    classes: number[],
    locations: number[],
    searchByPurchasePrice: boolean,
    minPurchasePrice: number,
    maxPurchasePrice: number,
    searchBySalePrice: boolean,
    minSalePrice: number,
    maxSalePrice: number
  ): Observable<AppHttpResponse<string>> {
    const params = new HttpParams()
      .set('classes', classes.join(','))
      .set('locations', locations.join(','))
      .set('searchByPurchasePrice', searchByPurchasePrice.toString())
      .set('minPurchasePrice', minPurchasePrice.toString())
      .set('maxPurchasePrice', maxPurchasePrice.toString())
      .set('searchBySalePrice', searchBySalePrice.toString())
      .set('minSalePrice', minSalePrice.toString())
      .set('maxSalePrice', maxSalePrice.toString());

    return this.http.get<AppHttpResponse<string>>(`${this.storesReportsApi}/products-list-report`, { params });
  }

  /**
   * Gets the product movement matching the provided query params.
   * @param locations The locations of the product transactions.
   * @param productId The product id of the product transactions.
   * @param fromDate The fromDate of the product transactions.
   * @param toDate The toDate of the product transactions.
   */
  public getProductMovementReport(
    productId: number,
    locations: number[],
    fromDate: Date,
    toDate: Date,
    timeSerial: boolean,
    descriptive: boolean
  ): Observable<AppHttpResponse<string>> {
    const params = new HttpParams()
      .set('productId', productId.toString())
      .set('locations', locations.join(','))
      .set('fromDate', fromDate?.toISOString())
      .set('toDate', toDate?.toISOString())
      .set('timeSerial', timeSerial.toString())
      .set('descriptive', descriptive.toString());

    return this.http.get<AppHttpResponse<string>>(`${this.storesReportsApi}/product-movement-report`, { params });
  }

  /**
   * Gets the incoming stock matching the provided query params.
   * @param locations The array of the ids of locations.
   * @param types The types of the incoming stock.
   * @param fromDate The fromDate of the incoming stock.
   * @param toDate The toDate of the incoming stock.
   */
  public getIncomingStockReport(
    locations: number[],
    types: number[],
    displayProducts: boolean,
    fromDate: Date,
    toDate: Date
  ): Observable<AppHttpResponse<string>> {
    const params = new HttpParams()
      .set('locations', locations.join(','))
      .set('types', types.join(','))
      .set('displayProducts', displayProducts.toString())
      .set('fromDate', fromDate?.toISOString())
      .set('toDate', toDate?.toISOString());

    return this.http.get<AppHttpResponse<string>>(`${this.storesReportsApi}/incoming-stock-report`, { params });
  }

  /**
   * Gets the outgoing stock matching the provided query params.
   * @param locations The array of the ids of locations.
   * @param types The types of the outgoing stock.
   * @param fromDate The fromDate of the outgoing stock.
   * @param toDate The toDate of the outgoing stock.
   */
  public getOutgoingStockReport(
    locations: number[],
    types: number[],
    displayProducts: boolean,
    fromDate: Date,
    toDate: Date
  ): Observable<AppHttpResponse<string>> {
    const params = new HttpParams()
      .set('locations', locations.join(','))
      .set('types', types.join(','))
      .set('displayProducts', displayProducts.toString())
      .set('fromDate', fromDate?.toISOString())
      .set('toDate', toDate?.toISOString());

    return this.http.get<AppHttpResponse<string>>(`${this.storesReportsApi}/outgoing-stock-report`, { params });
  }

  /**
   * Gets the transfer stocks matching the provided query params.
   * @param fromLocations The locations of the transfer stock that transfer from.
   * @param toLocations The locations of the transfer stock that transfer to.
   * @param fromDate The fromDate of the transfer stock.
   * @param toDate The toDate of the transfer stock.
   */
  public getTransferStockReport(
    fromLocations: number[],
    toLocations: number[],
    displayProducts: boolean,
    fromDate: Date,
    toDate: Date
  ): Observable<AppHttpResponse<string>> {
    const params = new HttpParams()
      .set('fromLocations', fromLocations.join(','))
      .set('toLocations', toLocations.join(','))
      .set('displayProducts', displayProducts.toString())
      .set('fromDate', fromDate?.toISOString())
      .set('toDate', toDate?.toISOString());

    return this.http.get<AppHttpResponse<string>>(`${this.storesReportsApi}/transfer-stock-report`, { params });
  }

  /**
   * Gets the stock monitoring matching the provided query params.
   * @param locationId The location of the stock that require for monitoring.
   * @param products The product ids of the stock that require for monitoring.
   * @param fromDate The fromDate of the stock that require for monitoring.
   * @param toDate The toDate of the stock that require for monitoring.
   */
  public getStockMonitoringReport(
    locationId: number,
    products: number[],
    fromDate: Date,
    toDate: Date
  ): Observable<AppHttpResponse<string>> {
    const params = new HttpParams()
      .set('locationId', locationId.toString())
      .set('products', products.join(','))
      .set('fromDate', fromDate?.toISOString())
      .set('toDate', toDate?.toISOString());

    return this.http.get<AppHttpResponse<string>>(`${this.storesReportsApi}/stock-monitoring-report`, { params });
  }

  /**
   * Gets the unhandled sales stock matching the provided query params.
   * @param locationId The location of the stock that require for unhandled sales.
   * @param products The product ids of the stock that require for unhandled sales.
   * @param fromDate The fromDate of the stock that require for unhandled sales.
   * @param toDate The toDate of the stock that require for unhandled sales.
   */
  public getUnhandledSalesStockReport(
    locationId: number,
    products: number[],
    fromDate: Date,
    toDate: Date
  ): Observable<AppHttpResponse<string>> {
    const params = new HttpParams()
      .set('locationId', locationId.toString())
      .set('products', products.join(','))
      .set('fromDate', fromDate?.toISOString())
      .set('toDate', toDate?.toISOString());

    return this.http.get<AppHttpResponse<string>>(`${this.storesReportsApi}/unhandled-sales-stock-report`, { params });
  }

  /**
   * Gets the stock inventory matching the provided query params.
   * @param locationId The location of the stock that require for inventory.
   * @param productClasses The product classes of the stock that require for inventory.
   * @param products The products of the stock that require for inventory.
   */
  public getStockInventoryReport(
    locationId: number,
    classes: number[],
    products: number[],
    productsWithAllStocks: boolean,
    productsWithStock: boolean,
    productsWithNoStock: boolean,
    productsWithNegativeStock: boolean
  ): Observable<AppHttpResponse<string>> {
    const params = new HttpParams()
      .set('locationId', locationId.toString())
      .set('classes', classes.join(','))
      .set('products', products.join(','))
      .set('productsWithAllStocks', productsWithAllStocks.toString())
      .set('productsWithStock', productsWithStock.toString())
      .set('productsWithNoStock', productsWithNoStock.toString())
      .set('productsWithNegativeStock', productsWithNegativeStock.toString());

    return this.http.get<AppHttpResponse<string>>(`${this.storesReportsApi}/stock-inventory-report`, { params });
  }
}
