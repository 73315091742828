import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';

import { AppHttpResponse } from 'shared';
import { CreatePosPrinterAdjustmentInput, PosPrinterAdjustment, UpdatePosPrinterAdjustmentInput } from 'sales/models';

/**
 * The pos printer adjustment services includes functionality to create, search, findById, update and delete for a pos printer.
 */
@Injectable()
export class PosPrinterAdjustmentService {
  /**
   * The relative route for the pos device .
   *
   * No leading or trailing slashes required.
   */
  private PosPrinterAdjustmentApi = 'sales/pos-printers/adjustments';

  constructor(private http: HttpClient) {}
  /**
   * Creates a new kitchen printer from the provided data.
   * @param data The new kitchen printer data.
   */
  public createKitchenPrinter(
    data: CreatePosPrinterAdjustmentInput
  ): Observable<AppHttpResponse<PosPrinterAdjustment>> {
    return this.http.post<AppHttpResponse<PosPrinterAdjustment>>(`${this.PosPrinterAdjustmentApi}`, data);
  }

  /**
   * Searches in the kitchen printer by product classes , pos printers, pos devices and locations.
   * @param productClasses of the kitchen printer.
   * @param pos printers of the kitchen printer
   * @param pos devices of the kitchen printer.
   * @param locations of the kitchen printer.
   * @param page The current pagination page number.
   * @param pageSize The maximum number of pos printer allowed per one pagination page.
   */
  public searchKitchenPrinter(
    productClasses: number[],
    posPrinters: number[],
    posDevices: number[],
    locations: number[],
    page: number,
    pageSize: number
  ): Observable<AppHttpResponse<PosPrinterAdjustment[]>> {
    const params = new HttpParams()
      .set('productClasses', productClasses.join(','))
      .set('posPrinters', posPrinters.join(','))
      .set('posDevices', posDevices.join(','))
      .set('locations', locations.join(','))
      .set('page', page.toString())
      .set('pageSize', pageSize.toString());
    return this.http.get<AppHttpResponse<PosPrinterAdjustment[]>>(`${this.PosPrinterAdjustmentApi}/kitchen-printers`, {
      params,
    });
  }

  /**
   * Finds the kitchen printer with the given id.
   * @param id The id of the kitchen printer.
   */
  public findKitchenPrinterById(id: number): Observable<AppHttpResponse<PosPrinterAdjustment>> {
    return this.http.get<AppHttpResponse<PosPrinterAdjustment>>(`${this.PosPrinterAdjustmentApi}/${id}`);
  }

  /**
   * Updates an existing kitchen printer data using the provided data.
   * @param data The updated kitchen printer data.
   */
  public updateKitchenPrinter(
    data: UpdatePosPrinterAdjustmentInput
  ): Observable<AppHttpResponse<PosPrinterAdjustment>> {
    return this.http.put<AppHttpResponse<PosPrinterAdjustment>>(`${this.PosPrinterAdjustmentApi}`, data);
  }

  /**
   * Deletes the kitchen printer by given id.
   * @param id The id of the kitchen printer.
   */
  public deleteKitchenPrinter(id: number): Observable<AppHttpResponse<PosPrinterAdjustment>> {
    return this.http.delete<AppHttpResponse<PosPrinterAdjustment>>(`${this.PosPrinterAdjustmentApi}/${id}`);
  }

  /**
   * Creates a new casher printer from the provided data.
   * @param data The new casher printer data.
   */
  public createCasherPrinter(data: CreatePosPrinterAdjustmentInput): Observable<AppHttpResponse<PosPrinterAdjustment>> {
    return this.http.post<AppHttpResponse<PosPrinterAdjustment>>(`${this.PosPrinterAdjustmentApi}`, data);
  }

  /**
   * Searches in the casher printer by pos printers , pos devices and locations.
   * @param pos printers of the casher printer
   * @param pos devices of the casher printer.
   * @param locations of the casher printer.
   * @param page The current pagination page number.
   * @param pageSize The maximum number of pos printer allowed per one pagination page.
   */
  public searchCasherPrinter(
    posPrinters: number[],
    posDevices: number[],
    locations: number[],
    page: number,
    pageSize: number
  ): Observable<AppHttpResponse<PosPrinterAdjustment[]>> {
    const params = new HttpParams()
      .set('posPrinters', posPrinters.join(','))
      .set('posDevices', posDevices.join(','))
      .set('locations', locations.join(','))
      .set('page', page.toString())
      .set('pageSize', pageSize.toString());
    return this.http.get<AppHttpResponse<PosPrinterAdjustment[]>>(`${this.PosPrinterAdjustmentApi}/casher-printers`, {
      params,
    });
  }

  /**
   * Finds the casher printer with the given id.
   * @param id The id of the casher printer.
   */
  public findCasherPrinterById(id: number): Observable<AppHttpResponse<PosPrinterAdjustment>> {
    return this.http.get<AppHttpResponse<PosPrinterAdjustment>>(`${this.PosPrinterAdjustmentApi}/${id}`);
  }

  /**
   * Updates an existing casher printer data using the provided data.
   * @param data The updated casher printer data.
   */
  public updateCasherPrinter(data: UpdatePosPrinterAdjustmentInput): Observable<AppHttpResponse<PosPrinterAdjustment>> {
    return this.http.put<AppHttpResponse<PosPrinterAdjustment>>(`${this.PosPrinterAdjustmentApi}`, data);
  }

  /**
   * Deletes the casher printer by given id.
   * @param id The id of the casher printer.
   */
  public deleteCasherPrinter(id: number): Observable<AppHttpResponse<PosPrinterAdjustment>> {
    return this.http.delete<AppHttpResponse<PosPrinterAdjustment>>(`${this.PosPrinterAdjustmentApi}/${id}`);
  }

  /**
   * Creates a new order delivery printer from the provided data.
   * @param data The new order delivery printer data.
   */
  public createOrderDeliveryPrinter(
    data: CreatePosPrinterAdjustmentInput
  ): Observable<AppHttpResponse<PosPrinterAdjustment>> {
    return this.http.post<AppHttpResponse<PosPrinterAdjustment>>(`${this.PosPrinterAdjustmentApi}`, data);
  }

  /**
   * Searches in the order delivery printer by order types , pos printers and pos devices and locations.
   * @param orderTypes of the order delivery printer.
   *  @param pos printers of the order delivery printer
   * @param pos devices of the order delivery printer.
   * @param locations of the order delivery printer.
   * @param page The current pagination page number.
   * @param pageSize The maximum number of pos printer allowed per one pagination page.
   */
  public searchOrderDeliveryPrinter(
    orderTypes: number[],
    posPrinters: number[],
    posDevices: number[],
    locations: number[],
    page: number,
    pageSize: number
  ): Observable<AppHttpResponse<PosPrinterAdjustment[]>> {
    const params = new HttpParams()
      .set('orderTypes', orderTypes.join(','))
      .set('posPrinters', posPrinters.join(','))
      .set('posDevices', posDevices.join(','))
      .set('locations', locations.join(','))
      .set('page', page.toString())
      .set('pageSize', pageSize.toString());
    return this.http.get<AppHttpResponse<PosPrinterAdjustment[]>>(
      `${this.PosPrinterAdjustmentApi}/order-delivery-printers`,
      { params }
    );
  }

  /**
   * Finds the order delivery printer with the given id.
   * @param id The id of the order delivery printer.
   */
  public findOrderDeliveryPrinterById(id: number): Observable<AppHttpResponse<PosPrinterAdjustment>> {
    return this.http.get<AppHttpResponse<PosPrinterAdjustment>>(`${this.PosPrinterAdjustmentApi}/${id}`);
  }

  /**
   * Updates an existing order delivery printer data using the provided data.
   * @param data The updated order delivery printer data.
   */
  public updateOrderDeliveryPrinter(
    data: UpdatePosPrinterAdjustmentInput
  ): Observable<AppHttpResponse<PosPrinterAdjustment>> {
    return this.http.put<AppHttpResponse<PosPrinterAdjustment>>(`${this.PosPrinterAdjustmentApi}`, data);
  }

  /**
   * Deletes the order delivery printer by given id.
   * @param id The id of the order delivery printer.
   */
  public deleteOrderDeliveryPrinter(id: number): Observable<AppHttpResponse<PosPrinterAdjustment>> {
    return this.http.delete<AppHttpResponse<PosPrinterAdjustment>>(`${this.PosPrinterAdjustmentApi}/${id}`);
  }
}
