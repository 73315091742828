import { CustomerAssetsComponent } from 'sales/components/customer-assets/customer-assets/customer-assets.component';
import { CustomerAssetsFormComponent } from 'sales/components/customer-assets/customer-assets-form/customer-assets-form.component';
import { CustomerAssetFormItemComponent } from 'sales/components/customer-assets/customer-assets-form-item/customer-asset-form-item.component';
import { CustomerAssetsListComponent } from 'sales/components/customer-assets/customer-assets-list/customer-assets-list.component';

export * from 'sales/components/customer-assets/customer-assets/customer-assets.component';
export * from 'sales/components/customer-assets/customer-assets-form/customer-assets-form.component';
export * from 'sales/components/customer-assets/customer-assets-form-item/customer-asset-form-item.component';
export * from 'sales/components/customer-assets/customer-assets-list/customer-assets-list.component';

/**
 * The set of components registered on this sub-module.
 */
export const COMPONENTS: any[] = [
  CustomerAssetsComponent,
  CustomerAssetsFormComponent,
  CustomerAssetFormItemComponent,
  CustomerAssetsListComponent,
];
