import { Action } from '@ngrx/store';

import { AppHttpResponse } from 'shared';
import {
  BankAccount,
  BankAccountLog,
  CreateBankAccountInput,
  CreateBankAccountChequeDepositInput,
  UpdateBankAccountInput,
  CreateBankAccountDepositByEmployeeInput,
  CreateBankAccountByWithdrawFromAccountInput,
  CreateBankAccountMoneyTransferInput,
  CreateBankAccountCashReceiptInput,
  CreateBankAccountSettlementDeedInput,
} from 'finances/models';

/**
 * The finances-module store bank accounts action types.
 */
export enum BankAccountsActionType {
  SEARCH_BANK_ACCOUNTS = '[Finances] [BankAccounts] Search Bank Accounts',
  SEARCH_BANK_ACCOUNTS_FAIL = '[Finances] [BankAccounts] Search Bank Accounts Fail',
  SEARCH_BANK_ACCOUNTS_SUCCESS = '[Finances] [BankAccounts] Search Bank Accounts Success',

  SEARCH_BANK_ACCOUNT_LOGS = '[Finances] [BankAccounts] Search Bank Account Logs',
  SEARCH_BANK_ACCOUNT_LOGS_FAIL = '[Finances] [BankAccounts] Search Bank Account Logs Fail',
  SEARCH_BANK_ACCOUNT_LOGS_SUCCESS = '[Finances] [BankAccounts] Search Bank Account Logs Success',

  FIND_BANK_ACCOUNT = '[Finances] [BankAccounts] Find Bank Account',
  FIND_BANK_ACCOUNT_FAIL = '[Finances] [BankAccounts] Find Bank Account Fail',
  FIND_BANK_ACCOUNT_SUCCESS = '[Finances] [BankAccounts] Find Bank Account Success',

  CREATE_BANK_ACCOUNT = '[Finances] [BankAccounts] Create Bank Account',
  CREATE_BANK_ACCOUNT_FAIL = '[Finances] [BankAccounts] Create Bank Account Fail',
  CREATE_BANK_ACCOUNT_SUCCESS = '[Finances] [BankAccounts] Create Bank Account Success',

  CREATE_BANK_ACCOUNT_LOG = '[Finances] [BankAccounts] Create Bank Account Log',
  CREATE_BANK_ACCOUNT_LOG_FAIL = '[Finances] [BankAccounts] Create Bank Account Log Fail',
  CREATE_BANK_ACCOUNT_LOG_SUCCESS = '[Finances] [BankAccounts] Create Bank Account Log Success',

  CREATE_BANK_ACCOUNT_LOG_DEPOSIT_BY_EMPLOYEE = '[Finances] [BankAccounts] Create Bank Account Log Deposit By Employee ',
  CREATE_BANK_ACCOUNT_LOG_DEPOSIT_BY_EMPLOYEE_FAIL = '[Finances] [BankAccounts] Create Bank Account Log  Deposit By Employee Fail',
  CREATE_BANK_ACCOUNT_LOG_DEPOSIT_BY_EMPLOYEE_SUCCESS = '[Finances] [BankAccounts] Create Bank Account Log Deposit By Employee Success',

  CREATE_BANK_ACCOUNT_LOG_BY_WITHDRAW_FROM_ACCOUNT = '[Finances] [BankAccounts] Create Bank Account Log By Withdraw From Account',
  CREATE_BANK_ACCOUNT_LOG_BY_WITHDRAW_FROM_ACCOUNT_FAIL = '[Finances] [BankAccounts] Create Bank Account Log By Withdraw From Account Fail',
  CREATE_BANK_ACCOUNT_LOG_BY_WITHDRAW_FROM_ACCOUNT_SUCCESS = '[Finances] [BankAccounts] Create Bank Account Log By Withdraw From Account Success',

  CREATE_BANK_ACCOUNT_LOG_MONEY_TRANSFER = '[Finances] [BankAccounts] Create Bank Account Log Money Transfer',
  CREATE_BANK_ACCOUNT_LOG_MONEY_TRANSFER_FAIL = '[Finances] [BankAccounts] Create Bank Account Log Money Transfer Fail',
  CREATE_BANK_ACCOUNT_LOG_MONEY_TRANSFER_SUCCESS = '[Finances] [BankAccounts] Create Bank Account Log Money Transfer Success',

  CREATE_BANK_ACCOUNT_LOG_CASH_RECEIPT = '[Finances] [BankAccounts] Create Bank Account Log Cash Receipt',
  CREATE_BANK_ACCOUNT_LOG_CASH_RECEIPT_FAIL = '[Finances] [BankAccounts] Create Bank Account Log Cash Receipt Fail',
  CREATE_BANK_ACCOUNT_LOG_CASH_RECEIPT_SUCCESS = '[Finances] [BankAccounts] Create Bank Account Log Cash Receipt Success',

  CREATE_BANK_ACCOUNT_LOG_SETTLEMENT_DEED = '[Finances] [BankAccounts] Create Bank Account Log Settlement deed',
  CREATE_BANK_ACCOUNT_LOG_SETTLEMENT_DEED_FAIL = '[Finances] [BankAccounts] Create Bank Account Log Settlement deed Fail',
  CREATE_BANK_ACCOUNT_LOG_SETTLEMENT_DEED_SUCCESS = '[Finances] [BankAccounts] Create Bank Account Log Settlement deed Success',

  UPDATE_BANK_ACCOUNT = '[Finances] [BankAccounts] Update Bank Account',
  UPDATE_BANK_ACCOUNT_FAIL = '[Finances] [BankAccounts] Update Bank Account Fail',
  UPDATE_BANK_ACCOUNT_SUCCESS = '[Finances] [BankAccounts] Update Bank Account Success',

  BLOCK_BANK_ACCOUNT = '[Finances] [BankAccounts] Block Bank Account',
  BLOCK_BANK_ACCOUNT_FAIL = '[Finances] [BankAccounts] Block Bank Account Fail',
  BLOCK_BANK_ACCOUNT_SUCCESS = '[Finances] [BankAccounts] Block Bank Account Success',

  ACTIVATE_BANK_ACCOUNT = '[Finances] [BankAccounts] Activate Bank Account',
  ACTIVATE_BANK_ACCOUNT_FAIL = '[Finances] [BankAccounts] Activate Bank Account Fail',
  ACTIVATE_BANK_ACCOUNT_SUCCESS = '[Finances] [BankAccounts] Activate Bank Account Success',

  DELETE_BANK_ACCOUNT = '[Finances] [BankAccounts] Delete Bank Account',
  DELETE_BANK_ACCOUNT_FAIL = '[Finances] [BankAccounts] Delete Bank Account Fail',
  DELETE_BANK_ACCOUNT_SUCCESS = '[Finances] [BankAccounts] Delete Bank Account Success',
}

/**
 * Represents a store bank accounts search action.
 */
export class SearchBankAccounts implements Action {
  /**
   * The type of the action.
   */
  readonly type = BankAccountsActionType.SEARCH_BANK_ACCOUNTS;

  /**
   * Fires a new bank accounts search action.
   * @param payload the search parameters,
   * if omitted, all bank accounts will be loaded.
   */
  constructor(
    public payload?: {
      name: string;
      banks: number[];
      page: number;
    }
  ) {}
}

/**
 * Represents a store bank accounts search-fail action.
 */
export class SearchBankAccountsFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = BankAccountsActionType.SEARCH_BANK_ACCOUNTS_FAIL;

  /**
   * Fires a new bank accounts search-fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store search-success action.
 */
export class SearchBankAccountsSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = BankAccountsActionType.SEARCH_BANK_ACCOUNTS_SUCCESS;

  /**
   * Fires a new bank accounts search-success action.
   * @param payload An object contains the list of bank accounts that matches the search criteria
   * in addition to the pagination info.
   */
  constructor(public payload: AppHttpResponse<BankAccount[]>) {}
}

/**
 * Represents a store bank account logs search action.
 */
export class SearchBankAccountLogs implements Action {
  /**
   * The type of the action.
   */
  readonly type = BankAccountsActionType.SEARCH_BANK_ACCOUNT_LOGS;

  /**
   * Fires a new bank account logs search action.
   * @param payload the search parameters,
   * if omitted, all bank account logs will be loaded.
   */
  constructor(
    public payload?: {
      bankAccountId: number;
      fromDate: Date;
      toDate: Date;
      page: number;
    }
  ) {}
}

/**
 * Represents a store bank account logs search-fail action.
 */
export class SearchBankAccountLogsFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = BankAccountsActionType.SEARCH_BANK_ACCOUNT_LOGS_FAIL;

  /**
   * Fires a new bank account logs search-fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store search-success action.
 */
export class SearchBankAccountLogsSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = BankAccountsActionType.SEARCH_BANK_ACCOUNT_LOGS_SUCCESS;

  /**
   * Fires a new bank account logs search-success action.
   * @param payload An object contains the list of bank account logs that matches the search criteria
   * in addition to the pagination info.
   */
  constructor(public payload: AppHttpResponse<{ logs: BankAccountLog[]; bankAccount: BankAccount }>) {}
}

/**
 * Represents a store find-bank-account action.
 */
export class FindBankAccounts implements Action {
  /**
   * The type of the action.
   */
  readonly type = BankAccountsActionType.FIND_BANK_ACCOUNT;

  /**
   * Fires a new find bank account action.
   * @param payload the id of the bank account.
   */
  constructor(public payload: number) {}
}

/**
 * Represents a store find-bank account-fail action.
 */
export class FindBankAccountsFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = BankAccountsActionType.FIND_BANK_ACCOUNT_FAIL;

  /**
   * Fires a new find-bank account-fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store find bank account-success action.
 */
export class FindBankAccountsSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = BankAccountsActionType.FIND_BANK_ACCOUNT_SUCCESS;

  /**
   * Fires a new find-bank account-success action.
   * @param payload The loaded bank account.
   */
  constructor(public payload: AppHttpResponse<BankAccount>) {}
}

/**
 * Represents a store bank account create action.
 */
export class CreateBankAccount implements Action {
  /** The type of the action. */
  readonly type = BankAccountsActionType.CREATE_BANK_ACCOUNT;

  /**
   * Fires a new bank account create action.
   * @param payload the new bank account data-model.
   */
  constructor(public payload: CreateBankAccountInput) {}
}

/**
 * Represents a store bank account create-fail action.
 */
export class CreateBankAccountFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = BankAccountsActionType.CREATE_BANK_ACCOUNT_FAIL;

  /**
   * Fires a new bank account create-fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store bank account create-success action.
 */
export class CreateBankAccountSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = BankAccountsActionType.CREATE_BANK_ACCOUNT_SUCCESS;

  /**
   * Fires a new bank account create-success action.
   * @param payload The created bank account.
   */
  constructor(public payload: AppHttpResponse<BankAccount>) {}
}

/**
 * Represents a store bank account log create action.
 */
export class CreateBankAccountLog implements Action {
  /** The type of the action. */
  readonly type = BankAccountsActionType.CREATE_BANK_ACCOUNT_LOG;

  /**
   * Fires a new bank account log create action.
   * @param payload the new bank account log data-model.
   */
  constructor(public payload: CreateBankAccountChequeDepositInput) {}
}

/**
 * Represents a store bank account log create-fail action.
 */
export class CreateBankAccountLogFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = BankAccountsActionType.CREATE_BANK_ACCOUNT_LOG_FAIL;

  /**
   * Fires a new bank account log create-fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store bank account log create-success action.
 */
export class CreateBankAccountLogSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = BankAccountsActionType.CREATE_BANK_ACCOUNT_LOG_SUCCESS;

  /**
   * Fires a new bank account log create-success action.
   * @param payload The created bank account log.
   */
  constructor(public payload: AppHttpResponse<BankAccountLog>) {}
}

/**
 * Represents a store bank account deposit by employee create action.
 */
export class CreateBankAccountLogDepositByEmployee implements Action {
  /** The type of the action. */
  readonly type = BankAccountsActionType.CREATE_BANK_ACCOUNT_LOG_DEPOSIT_BY_EMPLOYEE;

  /**
   * Fires a new bank account log  deposit by employee create action.
   * @param payload the new bank account deposit by employee data-model.
   */
  constructor(public payload: CreateBankAccountDepositByEmployeeInput) {}
}

/**
 * Represents a store bank account log deposit by employee create-fail action.
 */
export class CreateBankAccountLogDepositByEmployeeFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = BankAccountsActionType.CREATE_BANK_ACCOUNT_LOG_DEPOSIT_BY_EMPLOYEE_FAIL;

  /**
   * Fires a new bank account log deposit by employee create-fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store bank account log deposit by employee create-success action.
 */
export class CreateBankAccountLogDepositByEmployeeSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = BankAccountsActionType.CREATE_BANK_ACCOUNT_LOG_DEPOSIT_BY_EMPLOYEE_SUCCESS;

  /**
   * Fires a new bank account log create-success action.
   * @param payload The created bank account log.
   */
  constructor(public payload: AppHttpResponse<BankAccountLog>) {}
}

/**
 * Represents a store bank account by withdraw from account create action.
 */
export class CreateBankAccountLogByWithdrawFromAccount implements Action {
  /** The type of the action. */
  readonly type = BankAccountsActionType.CREATE_BANK_ACCOUNT_LOG_BY_WITHDRAW_FROM_ACCOUNT;

  /**
   * Fires a new bank account by withdraw from account create action.
   * @param payload the new bank account by withdraw from account data-model.
   */
  constructor(public payload: CreateBankAccountByWithdrawFromAccountInput) {}
}

/**
 * Represents a store bank account by withdraw from account create-fail action.
 */
export class CreateBankAccountLogByWithdrawFromAccountFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = BankAccountsActionType.CREATE_BANK_ACCOUNT_LOG_BY_WITHDRAW_FROM_ACCOUNT_FAIL;

  /**
   * Fires a new bank account by withdraw from account create-fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store bank account by withdraw from account create-success action.
 */
export class CreateBankAccountLogByWithdrawFromAccountSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = BankAccountsActionType.CREATE_BANK_ACCOUNT_LOG_BY_WITHDRAW_FROM_ACCOUNT_SUCCESS;

  /**
   * Fires a new bank account log create-success action.
   * @param payload The created bank account log.
   */
  constructor(public payload: AppHttpResponse<BankAccountLog>) {}
}

/**
 * Represents a store bank account update action.
 */
export class UpdateBankAccount implements Action {
  /**
   * The type of the action.
   */
  readonly type = BankAccountsActionType.UPDATE_BANK_ACCOUNT;

  /**
   * Fires a new bank account update action.
   * @param payload The bank account's updated data.
   */
  constructor(public payload: UpdateBankAccountInput) {}
}

/**
 * Represents a store bank account update-fail action.
 */
export class UpdateBankAccountFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = BankAccountsActionType.UPDATE_BANK_ACCOUNT_FAIL;

  /**
   * Fires a new bank account update-fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store bank account update-success action.
 */
export class UpdateBankAccountSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = BankAccountsActionType.UPDATE_BANK_ACCOUNT_SUCCESS;

  /**
   * Fires a new bank account update-success action.
   * @param payload The updated bank account.
   */
  constructor(public payload: AppHttpResponse<BankAccount>) {}
}

/**
 * Represents a store bank account block action.
 */
export class BlockBankAccount implements Action {
  /**
   * The type of the action.
   */
  readonly type = BankAccountsActionType.BLOCK_BANK_ACCOUNT;

  /**
   * Fires a new bank account block action.
   * @param payload The bank account's id.
   */
  constructor(public payload: number) {}
}

/**
 * Represents a store bank account block-fail action.
 */
export class BlockBankAccountFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = BankAccountsActionType.BLOCK_BANK_ACCOUNT_FAIL;

  /**
   * Fires a new bank account block-fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store bank account block-success action.
 */
export class BlockBankAccountSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = BankAccountsActionType.BLOCK_BANK_ACCOUNT_SUCCESS;

  /**
   * Fires a new bank account block-success action.
   * @param payload The updated bank account.
   */
  constructor(public payload: AppHttpResponse<BankAccount>) {}
}

/**
 * Represents a store bank account activate action.
 */
export class ActivateBankAccount implements Action {
  /**
   * The type of the action.
   */
  readonly type = BankAccountsActionType.ACTIVATE_BANK_ACCOUNT;

  /**
   * Fires a new bank account activate action.
   * @param payload The bank account's id.
   */
  constructor(public payload: number) {}
}

/**
 * Represents a store bank account activate-fail action.
 */
export class ActivateBankAccountFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = BankAccountsActionType.ACTIVATE_BANK_ACCOUNT_FAIL;

  /**
   * Fires a new bank account activate-fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store bank account activate-success action.
 */
export class ActivateBankAccountSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = BankAccountsActionType.ACTIVATE_BANK_ACCOUNT_SUCCESS;

  /**
   * Fires a new bank account activate-success action.
   * @param payload The updated bank account.
   */
  constructor(public payload: AppHttpResponse<BankAccount>) {}
}

/**
 * Represents a store bank account delete action.
 */
export class DeleteBankAccount implements Action {
  /**
   * The type of the action.
   */
  readonly type = BankAccountsActionType.DELETE_BANK_ACCOUNT;

  /**
   * Fires a new bank account delete action.
   * @param payload The bank account's id.
   */
  constructor(public payload: number) {}
}

/**
 * Represents a store bank account delete-fail action.
 */
export class DeleteBankAccountFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = BankAccountsActionType.DELETE_BANK_ACCOUNT_FAIL;

  /**
   * Fires a new bank account delete-fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store bank account delete-success action.
 */
export class DeleteBankAccountSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = BankAccountsActionType.DELETE_BANK_ACCOUNT_SUCCESS;

  /**
   * Fires a new bank account delete-success action.
   * @param payload The deleted bank account.
   */
  constructor(public payload: AppHttpResponse<BankAccount>) {}
}

/**
 * Represents a store bank account log money transfer create action.
 */
export class CreateBankAccountLogMoneyTransfer implements Action {
  /** The type of the action. */
  readonly type = BankAccountsActionType.CREATE_BANK_ACCOUNT_LOG_MONEY_TRANSFER;

  /**
   * Fires a new bank account money transfer create action.
   * @param payload the new bank account money transfer data-model.
   */
  constructor(public payload: CreateBankAccountMoneyTransferInput) {}
}

/**
 * Represents a store bank account log money transfer create-fail action.
 */
export class CreateBankAccountLogMoneyTransferFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = BankAccountsActionType.CREATE_BANK_ACCOUNT_LOG_MONEY_TRANSFER_FAIL;

  /**
   * Fires a new bank account log money transfer create-fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store bank account log money transfer create-success action.
 */
export class CreateBankAccountLogMoneyTransferSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = BankAccountsActionType.CREATE_BANK_ACCOUNT_LOG_MONEY_TRANSFER_SUCCESS;

  /**
   * Fires a new bank account log money transfer create-success action.
   * @param payload The created bank account log.
   */
  constructor(public payload: AppHttpResponse<BankAccountLog>) {}
}

/**
 * Represents a store bank account log cash receipt create action.
 */
 export class CreateBankAccountLogCashReceipt implements Action {
  /** The type of the action. */
  readonly type = BankAccountsActionType.CREATE_BANK_ACCOUNT_LOG_CASH_RECEIPT;

  /**
   * Fires a new bank account cash receipt create action.
   * @param payload the new bank account cash receipt data-model.
   */
  constructor(public payload: CreateBankAccountCashReceiptInput) {}
}

/**
 * Represents a store bank account log cash receipt create-fail action.
 */
export class CreateBankAccountLogCashReceiptFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = BankAccountsActionType.CREATE_BANK_ACCOUNT_LOG_CASH_RECEIPT_FAIL;

  /**
   * Fires a new bank account log cash receipt create-fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store bank account log cash receipt create-success action.
 */
export class CreateBankAccountLogCashReceiptSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = BankAccountsActionType.CREATE_BANK_ACCOUNT_LOG_CASH_RECEIPT_SUCCESS;

  /**
   * Fires a new bank account log cash receipt create-success action.
   * @param payload The created bank account log.
   */
  constructor(public payload: AppHttpResponse<BankAccountLog>) {}
}

/**
 * Represents a store bank account log settlement deed create action.
 */
export class CreateBankAccountLogSettlementDeed implements Action {
  /** The type of the action. */
  readonly type = BankAccountsActionType.CREATE_BANK_ACCOUNT_LOG_SETTLEMENT_DEED;

  /**
   * Fires a new bank account settlement deed create action.
   * @param payload the new bank account settlement deed data-model.
   */
  constructor(public payload: CreateBankAccountSettlementDeedInput) {}
}

/**
 * Represents a store bank account log settlement deed create-fail action.
 */
export class CreateBankAccountLogSettlementDeedFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = BankAccountsActionType.CREATE_BANK_ACCOUNT_LOG_SETTLEMENT_DEED_FAIL;

  /**
   * Fires a new bank account log settlement deed create-fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store bank account log settlement deed create-success action.
 */
export class CreateBankAccountLogSettlementDeedSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = BankAccountsActionType.CREATE_BANK_ACCOUNT_LOG_SETTLEMENT_DEED_SUCCESS;

  /**
   * Fires a new bank account log settlement deed create-success action.
   * @param payload The created bank account log.
   */
  constructor(public payload: AppHttpResponse<BankAccountLog>) {}
}

/**
 * finances-module bank accounts action types.
 */
export type BankAccountsActions =
  | SearchBankAccounts
  | SearchBankAccountsFail
  | SearchBankAccountsSuccess
  | SearchBankAccountLogs
  | SearchBankAccountLogsFail
  | SearchBankAccountLogsSuccess
  | FindBankAccounts
  | FindBankAccountsFail
  | FindBankAccountsSuccess
  | CreateBankAccount
  | CreateBankAccountFail
  | CreateBankAccountSuccess
  | CreateBankAccountLog
  | CreateBankAccountLogFail
  | CreateBankAccountLogSuccess
  | CreateBankAccountLogDepositByEmployee
  | CreateBankAccountLogDepositByEmployeeFail
  | CreateBankAccountLogDepositByEmployeeSuccess
  | CreateBankAccountLogByWithdrawFromAccount
  | CreateBankAccountLogByWithdrawFromAccountFail
  | CreateBankAccountLogByWithdrawFromAccountSuccess
  | CreateBankAccountLogMoneyTransfer
  | CreateBankAccountLogMoneyTransferFail
  | CreateBankAccountLogMoneyTransferSuccess
  | CreateBankAccountLogCashReceipt
  | CreateBankAccountLogCashReceiptFail
  | CreateBankAccountLogCashReceiptSuccess
  | CreateBankAccountLogSettlementDeed
  | CreateBankAccountLogSettlementDeedFail
  | CreateBankAccountLogSettlementDeedSuccess
  | UpdateBankAccount
  | UpdateBankAccountFail
  | UpdateBankAccountSuccess
  | BlockBankAccount
  | BlockBankAccountFail
  | BlockBankAccountSuccess
  | ActivateBankAccount
  | ActivateBankAccountFail
  | ActivateBankAccountSuccess
  | DeleteBankAccount
  | DeleteBankAccountFail
  | DeleteBankAccountSuccess;
