<!-- Navbar (Header) Starts -->
<nav class="header-navbar navbar navbar-expand-lg navbar-light bg-faded">
  <div class="container-fluid">
    <div class="navbar-header" [ngClass]="{'removePadding': isSidebarHidden,
    'addPadding': !isSidebarHidden
  }">
      <button type="button" class="navbar-toggle float-left d-lg-none" data-toggle="collapse">
        <span class="sr-only">Toggle navigation</span>
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
      </button>
      <button type="button" class="navbar-toggle d-none d-md-block  d-lg-block" data-toggle="collapse" (click)="toggleSidebar()">
        <i class="ft-more-vertical"></i>
      </button>
      <span class="d-lg-none navbar-right navbar-collapse-toggle">
        <a
          class="open-navbar-container"
          (click)="isCollapsed = !isCollapsed"
          [attr.aria-expanded]="!isCollapsed"
          aria-controls="navbarSupportedContent"
        >
          <i class="ft-more-vertical"></i>
        </a>
      </span>
    </div>
    <div class="navbar-container">
      <div class="collapse navbar-collapse" id="navbarSupportedContent" [ngbCollapse]="isCollapsed">
        <ul class="navbar-nav">
          <li class="nav-item mr-2 d-none d-lg-block" *ngIf="organizationSettings$ | async; let organizationSetting">
            <img
              *ngIf="organizationSetting?.logoUrl"
              [src]="organizationSetting?.logoUrl"
              class="organization-logo item-img"
            />
          </li>
          <li
            *ngIf="false"
            class="nav-item mr-2 mt-1 d-none d-lg-block darkThemeToggleButton"
            [ngbTooltip]="
              isDarkMode ? ('CORE.NAVBAR.BRIGHTNESS_MODE' | translate) : ('CORE.NAVBAR.DARK_MODE' | translate)
            "
          >
            <a
              class="nav-toggle d-none d-sm-none d-md-none d-lg-block"
              id="sidebarToggle"
              href="javascript:;"
              (click)="(true)"
            >
              <i
                class="font-medium-3 blue-grey darken-4 toggle-icon"
                [ngClass]="{ 'ft-toggle-right': isDarkMode, 'ft-toggle-left': !isDarkMode }"
              ></i>
            </a>
          </li>
          <li class="nav-item  d-none d-lg-block">
            <a
              href="javascript:;"
              class="nav-link"
              id="navbar-fullscreen"
              appToggleFullscreen
              (click)="toggleFullScreen()"

            >
              <i class="{{ fullScreenModeClass }} font-medium-3 blue-grey darken-4"></i>
              <p class="d-none">fullscreen</p>
            </a>
          </li>
          <!-- <li *ngIf="false" class="nav-item mr-2 d-none d-lg-block">
            <a href="javascript:;" class="nav-link">
              <span class="fa-stack has-badge notifications" data-count="10">
                <i class="fa fa-bell-o font-medium-3 blue-grey darken-4"></i>
                <p class="d-none">Notifications</p>
              </span>
            </a>
          </li> -->
          <li  class="nav-item darkThemeToggleButton"
          [ngbTooltip]="
            isDarkMode ? ('CORE.NAVBAR.BRIGHTNESS_MODE' | translate) : ('CORE.NAVBAR.DARK_MODE' | translate)
          " >
            <a
            href="javascript:;"
            class="nav-link"
            id="navbar-DarkModeToggle"

            (click)="applyLayoutStyle()"
          >
            <i class="{{ layoutModeClass }} font-medium-3  darken-4"></i>

</a>





          </li>
          <li class="nav-item" >


              <a class="nav-link position-relative" dir="ltr" (click)="changeLanguage('en')"  *ngIf="currentLang === 'ar'">
                <small  class="" style="vertical-align: top;margin: 0 3px;display: inline-block;
                font-size: 1.1rem;">En</small>
                <i
                  class="font-medium-4 fa fa-globe "

                ></i>


              </a>
              <a class="nav-link position-relative" dir="rtl"  (click)="changeLanguage('ar')" *ngIf="currentLang === 'en'">
                <small class="" style="vertical-align: top;margin: 0 3px;display: inline-block;
                font-size: 1.1rem;">Ar</small>
                <i
                  class="font-medium-4  fa fa-globe"

                ></i>

              </a>


            <!-- <a class="nav-link position-relative" id="dropdownLang" ngbDropdownToggle>
              <i
                class="flag-icon font-medium-3 blue-grey darken-4"
                [ngClass]="{ 'flag-icon-sa': currentLang === 'ar', 'flag-icon-us': currentLang === 'en' }"
              ></i>
              <p class="d-none">Language</p>
            </a>
            <div ngbDropdownMenu aria-labelledby="dropdownLang" class="dropdownLang text-left">
              <div *ngFor="let language of languages$ | async">
                <a
                  class="dropdown-item py-1"
                  (click)="changeLanguage(language.key)"
                  *ngIf="currentLang !== language.key"
                >
                  <span class="flag-icon flag-icon-sa font-medium-3" *ngIf="language.key === 'ar'"></span>
                  <span class="flag-icon flag-icon-us font-medium-3" *ngIf="language.key === 'en'"></span>
                  <span class="mx-1">{{ language.name | localize: language.nameEn | async }}</span>
                </a>
              </div>
            </div> -->
          </li>
          <li class="nav-item" ngbDropdown display="dynamic" [placement]="ngbDropdownPosition">
            <a class="nav-link position-relative" id="dropdownBasic3" ngbDropdownToggle>
              <i class="fa fa-user-o font-medium-3  darken-4"></i>
              <small class="font-weight-bold">
                {{ 'CORE.DASHBOARD.WELCOME' | translate }}
                <span>{{ currentUser.name }}</span>
              </small>
              <p class="d-none">User Settings</p>
            </a>
            <div ngbDropdownMenu aria-labelledby="dropdownBasic3" class="text-left">
              <a class="dropdown-item py-1" routerLink="/security/my-profile">
                <i class="fa fa-edit mr-2"></i>
                <span>{{ 'CORE.NAVBAR.MY_ACCOUNT' | translate }}</span>
              </a>
              <a class="dropdown-item py-1" routerLink="/reports/my-reports">
                <i class="fa fa-file-text-o mr-2"></i>
                <span>{{ 'CORE.NAVBAR.MY_REPORTS' | translate }}</span>
              </a>
              <a class="dropdown-item py-1" (click)="userPreferencesModalVisibility = true">
                <i class="fa fa-cog mr-2"></i>
                <span>{{ 'CORE.NAVBAR.PREFERENCES' | translate }}</span>
              </a>
              <div class="dropdown-divider"></div>
              <a class="dropdown-item" routerLink="/login" (click)="logout()">
                <i class="fa fa-power-off mr-2"></i>
                <span>{{ 'CORE.NAVBAR.LOGOUT' | translate }}</span>
              </a>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</nav>
<!-- Navbar (Header) Ends -->

<!-- user preferences Starts -->
<app-user-preferences [(visible)]="userPreferencesModalVisibility"></app-user-preferences>
<!-- user preferences ends -->
