<!-- page header starts -->
<app-page-header [info]="pageInfo"></app-page-header>
<!-- page header ends -->

<!-- financial periods list starts -->
<div class="row">
  <div class="col-md-12">
    <div class="card">
      <div class="card-header mb-3">
        <h4 class="card-title">{{ 'SHARED.SEARCH.SEARCH_TOOLS' | translate }}</h4>
      </div>
      <div class="card-content">
        <div class="px-3">
          <div class="row">
            <div class="col-md-12">
              <form class="row" [formGroup]="searchForm" (ngSubmit)="search()">
                <div class="col">
                  <label for="fromDate">{{ 'SHARED.SEARCH.FROM_DATE' | translate }}</label>
                  <div class="input-group round">
                    <input
                      class="form-control"
                      id="fromDate"
                      name="fromDate"
                      formControlName="fromDate"
                      [placeholder]="'SHARED.SEARCH.DATE_PLACEHOLDER' | translate"
                      ngbDatepicker
                      #fromDate="ngbDatepicker"
                    />
                    <div class="input-group-append">
                      <span
                        class="input-group-text gradient-light-blue-indigo"
                        style="cursor: pointer;"
                        (click)="fromDate.toggle()"
                      >
                        <i class="fa fa-calendar"></i>
                      </span>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <label for="toDate">{{ 'SHARED.SEARCH.TO_DATE' | translate }}</label>
                  <div class="input-group round">
                    <input
                      class="form-control"
                      id="toDate"
                      name="toDate"
                      formControlName="toDate"
                      [placeholder]="'SHARED.SEARCH.DATE_PLACEHOLDER' | translate"
                      ngbDatepicker
                      #toDate="ngbDatepicker"
                    />
                    <div class="input-group-append">
                      <span
                        class="input-group-text gradient-light-blue-indigo"
                        style="cursor: pointer;"
                        (click)="toDate.toggle()"
                      >
                        <i class="fa fa-calendar"></i>
                      </span>
                    </div>
                  </div>
                </div>
                <div class="col-md-auto form-group d-flex">
                  <button
                    type="submit"
                    class="btn btn-raised btn-primary align-self-end round mx-1 mb-0"
                    [disabled]="isSearching$ | async"
                  >
                    <i class="fa fa-search"></i>
                  </button>
                  <app-export-tools
                    *ngIf="(financialPeriods$ | async)?.length"
                    class="d-flex align-self-end mx-1"
                    targetElementId="financial-periods-table-export-target-element"
                    [outputFileName]="'SHARED.EXPORT_TABLES.FINANCIAL_PERIODS_TABLE' | translate"
                  ></app-export-tools>
                </div>
              </form>
            </div>
            <div class="col-md-12" *ngIf="(financialPeriods$ | async)?.length; else noItems">
              <div class="row">
                <div class="col-md-12 alert alert-light text-center" role="alert">
                  {{ 'SHARED.SEARCH.SEARCH_RESULTS' | translate }} ({{ (paginationInfo$ | async).total | toNumber }})
                  {{ 'FINANCES.FINANCIAL_PERIODS.FINANCIAL_PERIOD_DATA.FINANCIAL_PERIOD' | translate }}
                </div>
                <div class="col-md-12">
                  <div class="table-responsive">
                    <table class="table text-center" id="financial-periods-table-export-target-element">
                      <thead>
                        <tr>
                          <th scope="col"><i class="fa fa-list-ol"></i></th>
                          <th scope="col">
                            {{ 'FINANCES.FINANCIAL_PERIODS.FINANCIAL_PERIOD_DATA.FINANCIAL_PERIOD_NAME' | translate }}
                          </th>
                          <th scope="col">
                            {{ 'FINANCES.FINANCIAL_PERIODS.FINANCIAL_PERIOD_DATA.FINANCIAL_PERIOD_TYPE' | translate }}
                          </th>
                          <th scope="col">
                            {{ 'FINANCES.FINANCIAL_PERIODS.FINANCIAL_PERIOD_DATA.STARTED_AT' | translate }}
                          </th>
                          <th scope="col">
                            {{ 'FINANCES.FINANCIAL_PERIODS.FINANCIAL_PERIOD_DATA.ENDED_AT' | translate }}
                          </th>
                          <th scope="col">
                            {{ 'FINANCES.FINANCIAL_PERIODS.FINANCIAL_PERIOD_DATA.OPEN' | translate }}
                          </th>
                          <th scope="col">
                            {{ 'FINANCES.FINANCIAL_PERIODS.FINANCIAL_PERIOD_DATA.PAUSED' | translate }}
                          </th>
                          <th scope="col">
                            {{ 'FINANCES.FINANCIAL_PERIODS.FINANCIAL_PERIOD_DATA.CLOSED' | translate }}
                          </th>
                          <th scope="col">{{ 'SHARED.TOOLS' | translate }}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let period of financialPeriods$ | async; trackBy: trackItems; let i = index">
                          <th scope="row">{{ i + 1 }}</th>
                          <td>{{ period.name | localize: period.nameEn | async }}</td>
                          <td>
                            {{ period.financialPeriodType.name | localize: period.financialPeriodType.nameEn | async }}
                          </td>
                          <td>
                            <span [ngbTooltip]="period.startDate | date: 'time'">
                              {{ period.startDate | date }}
                            </span>
                          </td>
                          <td>
                            <span [ngbTooltip]="period.endDate | date: 'time'">
                              {{ period.endDate | date }}
                            </span>
                          </td>
                          <td>
                            <app-status
                              *ngIf="period.isOpened"
                              [status]="period.isOpened"
                              [activeText]="'FINANCES.FINANCIAL_PERIODS.FINANCIAL_PERIOD_DATA.OPEN' | translate"
                            >
                            </app-status>
                          </td>
                          <td>
                            <app-status
                              *ngIf="period.isPaused"
                              [status]="!period.isPaused"
                              [notActiveText]="'FINANCES.FINANCIAL_PERIODS.FINANCIAL_PERIOD_DATA.PAUSED' | translate"
                            >
                            </app-status>
                          </td>
                          <td>
                            <app-status
                              *ngIf="period.isClosed"
                              [status]="!period.isClosed"
                              [notActiveText]="'FINANCES.FINANCIAL_PERIODS.FINANCIAL_PERIOD_DATA.CLOSED' | translate"
                            >
                            </app-status>
                          </td>
                          <td>
                            <a
                              *ngIf="
                                ([Claims.OPEN_FINANCIAL_PERIOD] | authorize | async) &&
                                !period.isOpened &&
                                !period.isClosed
                              "
                              class="success p-0"
                              (click)="selectedPeriod = period; openModal(openPeriodModalRef)"
                              [ngbTooltip]="'FINANCES.FINANCIAL_PERIODS.FINANCIAL_PERIOD_DATA.OPEN_PERIOD' | translate"
                            >
                              <i class="fa fa-check font-medium-3 mr-2"></i>
                            </a>
                            <a
                              class="warning p-0"
                              (click)="selectedPeriod = period; openModal(pausePeriodModalRef)"
                              [ngbTooltip]="'FINANCES.FINANCIAL_PERIODS.FINANCIAL_PERIOD_DATA.PAUSE_PERIOD' | translate"
                              *ngIf="
                                ([Claims.PAUSE_FINANCIAL_PERIOD] | authorize | async) &&
                                !period.isPaused &&
                                !period.isClosed &&
                                period.isOpened
                              "
                            >
                              <i class="fa fa-pause font-medium-3 mr-2"></i>
                            </a>
                            <a
                              class="danger p-0"
                              (click)="selectedPeriod = period; openModal(closePeriodModalRef)"
                              [ngbTooltip]="'FINANCES.FINANCIAL_PERIODS.FINANCIAL_PERIOD_DATA.CLOSE_PERIOD' | translate"
                              *ngIf="([Claims.CLOSE_FINANCIAL_PERIOD] | authorize | async) && !period.isClosed"
                            >
                              <i class="fa fa-close font-medium-3 mr-2"></i>
                            </a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div class="d-flex justify-content-between p-2">
                      <ngb-pagination
                        *ngIf="paginationInfo$ | async; let pagination"
                        maxSize="5"
                        [collectionSize]="pagination.total"
                        [pageSize]="pagination.pageSize"
                        [page]="pagination.page"
                        (pageChange)="pageChanged($event)"
                        boundaryLinks="true"
                      >
                        <ng-template ngbPaginationFirst>{{
                          'SHARED.PAGINATION.PAGINATION_FIRST' | translate
                        }}</ng-template>
                        <ng-template ngbPaginationPrevious>{{
                          'SHARED.PAGINATION.PAGINATION_PREVIOUS' | translate
                        }}</ng-template>
                        <ng-template ngbPaginationNext>{{
                          'SHARED.PAGINATION.PAGINATION_NEXT' | translate
                        }}</ng-template>
                        <ng-template ngbPaginationLast>{{
                          'SHARED.PAGINATION.PAGINATION_LAST' | translate
                        }}</ng-template>
                      </ngb-pagination>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- financial periods list ends -->

<!-- no items template starts -->
<ng-template #noItems>
  <div class="col-md-12 alert alert-warning mt-3 text-center" role="alert">
    {{ 'FINANCES.FINANCIAL_PERIODS.SEARCH_FINANCIAL_PERIODS_WITHOUT_ADD_NOT_FOUND' | translate }}
  </div>
</ng-template>
<!-- no items template ends -->

<!-- open period modal starts -->
<ng-template #openPeriodModalRef let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
      {{ 'FINANCES.FINANCIAL_PERIODS.CONFIRM_OPEN_PERIOD_TITLE' | translate }}
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="d()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-12">
        <p class="gray p-0">{{ 'FINANCES.FINANCIAL_PERIODS.CONFIRM_OPEN_PERIOD_MESSAGE' | translate }}</p>
      </div>
      <div class="form-group col-12">
        <label for="selectedPeriod.name">{{
          'FINANCES.FINANCIAL_PERIODS.FINANCIAL_PERIOD_DATA.FINANCIAL_PERIOD_NAME' | translate
        }}</label>
        <input
          type="text"
          id="selectedPeriod.name"
          class="form-control round"
          [placeholder]="
            'FINANCES.FINANCIAL_PERIODS.FINANCIAL_PERIOD_DATA.FINANCIAL_PERIOD_NAME_PLACEHOLDER' | translate
          "
          readonly
          [value]="selectedPeriod.name | localize: selectedPeriod.nameEn | async"
        />
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="modal-actions">
      <button
        type="button"
        class="btn btn-raised btn-success round"
        (click)="c(); open(selectedPeriod.id)"
        ngbAutofocus
        [disabled]="isOpening$ | async"
      >
        <i class="fa fa-check"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.OPEN' | translate }}</span>
      </button>
      <button type="button" class="btn btn-outline-dark round" (click)="c()">
        <i class="fa fa-times"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.CANCEL' | translate }}</span>
      </button>
    </div>
  </div>
</ng-template>
<!-- open period modal ends -->

<!-- pause period modal starts -->
<ng-template #pausePeriodModalRef let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title gray" id="modal-basic-title">
      {{ 'FINANCES.FINANCIAL_PERIODS.CONFIRM_PAUSE_PERIOD_TITLE' | translate }}
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="d()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-12">
        <p class="gray p-0">{{ 'FINANCES.FINANCIAL_PERIODS.CONFIRM_PAUSE_PERIOD_MESSAGE' | translate }}</p>
      </div>
      <div class="form-group col-12">
        <label for="selectedPeriod.name">{{
          'FINANCES.FINANCIAL_PERIODS.FINANCIAL_PERIOD_DATA.FINANCIAL_PERIOD_NAME' | translate
        }}</label>
        <input
          type="text"
          id="selectedPeriod.name"
          class="form-control round"
          [placeholder]="
            'FINANCES.FINANCIAL_PERIODS.FINANCIAL_PERIOD_DATA.FINANCIAL_PERIOD_NAME_PLACEHOLDER' | translate
          "
          readonly
          [value]="selectedPeriod.name | localize: selectedPeriod.nameEn | async"
        />
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="modal-actions">
      <button
        type="button"
        class="btn btn-raised btn-warning round"
        (click)="c(); pause(selectedPeriod.id)"
        ngbAutofocus
        [disabled]="isPausing$ | async"
      >
        <i class="fa fa-check"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.BLOCK' | translate }}</span>
      </button>
      <button type="button" class="btn btn-outline-dark round" (click)="c()">
        <i class="fa fa-times"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.CANCEL' | translate }}</span>
      </button>
    </div>
  </div>
</ng-template>
<!-- pause period modal ends -->

<!-- close period modal starts -->
<ng-template #closePeriodModalRef let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title text-warning" id="modal-basic-title">
      {{ 'FINANCES.FINANCIAL_PERIODS.CONFIRM_CLOSE_PERIOD_TITLE' | translate }}
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="d()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-12">
        <p class="gray p-0">{{ 'FINANCES.FINANCIAL_PERIODS.CONFIRM_CLOSE_PERIOD_MESSAGE' | translate }}</p>
      </div>
      <div class="form-group col-12">
        <label for="selectedPeriod.name">{{
          'FINANCES.FINANCIAL_PERIODS.FINANCIAL_PERIOD_DATA.FINANCIAL_PERIOD_NAME' | translate
        }}</label>
        <input
          type="text"
          id="selectedPeriod.name"
          class="form-control round"
          [placeholder]="
            'FINANCES.FINANCIAL_PERIODS.FINANCIAL_PERIOD_DATA.FINANCIAL_PERIOD_NAME_PLACEHOLDER' | translate
          "
          readonly
          [value]="selectedPeriod.name | localize: selectedPeriod.nameEn | async"
        />
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="modal-actions">
      <button
        type="button"
        class="btn btn-raised btn-warning round"
        (click)="c(); close(selectedPeriod.id)"
        ngbAutofocus
        [disabled]="isClosing$ | async"
      >
        <i class="fa fa-check"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.CLOSE' | translate }}</span>
      </button>
      <button type="button" class="btn btn-outline-dark round" (click)="c()">
        <i class="fa fa-times"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.CANCEL' | translate }}</span>
      </button>
    </div>
  </div>
</ng-template>
<!-- close period modal ends -->
