import { VendorLogType } from 'lookups/models';

/**
 * Represents the vendor payment log types state.
 */
export class VendorPaymentLogTypeState {
  /**
   * The list of vendor log types.
   */
  public data: VendorLogType[];

  /**
   * The list of vendor log types including advance payments.
   */
  public allData: VendorLogType[];

  /**
   * An error that may resulting during processing some actions.
   */
  public error: any;

  /**
   * Determines if there is a running load process.
   */
  public isLoading: boolean;

  /**
   * Determines if the last load process has been ended successfully.
   */
  public isLoaded: boolean;
}
