import { createSelector } from '@ngrx/store';

import { selectLookupsState } from 'lookups/store/selectors/feature.selectors';
import { LookupsState, PettyCashSpecificationState } from 'lookups/store/states';

/**
 * Gets the pettyCashSpecification state.
 */
const selectPettyCashSpecifications = createSelector(
  selectLookupsState,
  (state: LookupsState) => state.pettyCashSpecifications
);

/**
 * Gets the list of loaded pettyCashSpecifications.
 */
export const getPettyCashSpecifications = createSelector(
  selectPettyCashSpecifications,
  (state: PettyCashSpecificationState) => state.data
);

/**
 * Gets the error that occurred in the state.
 */
export const getPettyCashSpecificationsError = createSelector(
  selectPettyCashSpecifications,
  (state: PettyCashSpecificationState) => state.error
);

/**
 * Determines if there is a running load process.
 */
export const getPettyCashSpecificationsIsLoading = createSelector(
  selectPettyCashSpecifications,
  (state: PettyCashSpecificationState) => state.isLoading
);

/**
 * Determines if the last load process has been ended successfully.
 */
export const getPettyCashSpecificationsIsLoaded = createSelector(
  selectPettyCashSpecifications,
  (state: PettyCashSpecificationState) => state.isLoaded
);
