<!-- customer summary list starts -->
<div class="row">
  <div class="col-md-12">
    <div class="card-header mb-3 pt-0">
      <h4 class="card-title">{{ 'SHARED.SEARCH.SEARCH_TOOLS' | translate }}</h4>
    </div>
    <div class="card-content">
      <div class="px-3">
        <div class="row">
          <div class="col-md-12">
            <form class="row" [formGroup]="searchForm" (ngSubmit)="search()">
              <div class="col-md-12">
                <div class="row">
                  <div class="form-group col-md-5 col-sm-6 mb-0">
                    <label for="fromDate">{{ 'SHARED.SEARCH.FROM_DATE' | translate }}</label>
                    <div class="input-group round">
                      <input
                        class="form-control"
                        id="fromDate"
                        name="fromDate"
                        formControlName="fromDate"
                        [placeholder]="'SHARED.SEARCH.DATE_PLACEHOLDER' | translate"
                        ngbDatepicker
                        #fromDate="ngbDatepicker"
                      />
                      <div class="input-group-append">
                        <span
                          class="input-group-text gradient-light-blue-indigo"
                          style="cursor: pointer;"
                          (click)="fromDate.toggle()"
                        >
                          <i class="fa fa-calendar"></i>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="form-group col-md-5 col-sm-6 mb-0">
                    <label for="toDate">{{ 'SHARED.SEARCH.TO_DATE' | translate }}</label>
                    <div class="input-group round">
                      <input
                        class="form-control"
                        id="toDate"
                        name="toDate"
                        formControlName="toDate"
                        [placeholder]="'SHARED.SEARCH.DATE_PLACEHOLDER' | translate"
                        ngbDatepicker
                        #toDate="ngbDatepicker"
                      />
                      <div class="input-group-append">
                        <span
                          class="input-group-text gradient-light-blue-indigo"
                          style="cursor: pointer;"
                          (click)="toDate.toggle()"
                        >
                          <i class="fa fa-calendar"></i>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-auto form-group d-flex">
                    <button
                      type="submit"
                      class="btn btn-raised btn-primary align-self-end round mx-1 mb-0"
                      [disabled]="isSearching$ | async"
                    >
                      <i class="fa fa-search"></i>
                    </button>
                    <app-export-tools
                      *ngIf="(logs$ | async)?.length"
                      class="d-flex align-self-end mx-1"
                      targetElementId="logs-table-export-target-element"
                      [outputFileName]="'SHARED.EXPORT_TABLES.LOGS_TABLE' | translate"
                    ></app-export-tools>
                  </div>
                </div>
              </div>

              <div class="col-md-auto">
                <div class="form-group row mb-0">
                  <a
                    *ngIf="[Claims.CREATE_CUSTOMER_PAYMENT] | authorize | async"
                    routerLink="/sales/customers/payments/create"
                    [queryParams]="{ customerId: customer.id }"
                    class="btn btn-raised btn-success round mx-1 align-self-center col-md-auto"
                  >
                    <i class="fa fa-plus"></i>
                    <span class="mx-1">{{ 'SALES.CUSTOMERS.CREATE_PAYMENT_TITLE' | translate }}</span>
                  </a>
                  <a
                    *ngIf="[Claims.CREATE_CUSTOMER_PAYMENT] | authorize | async"
                    routerLink="/sales/customers/payment-by-advance-payment/create"
                    [queryParams]="{ customerId: customer.id }"
                    class="btn btn-raised btn-success round mx-1 align-self-center col-md-auto"
                  >
                    <i class="fa fa-plus"></i>
                    <span class="mx-1">{{ 'SALES.CUSTOMERS.ADVANCE_PAYMENT' | translate }}</span>
                  </a>
                  <a
                    *ngIf="[Claims.CREATE_CUSTOMER_PAYMENT] | authorize | async"
                    routerLink="/sales/customers/advance-payment/create"
                    [queryParams]="{ customerId: customer.id }"
                    class="btn btn-raised btn-success round mx-1 align-self-center col-md-auto"
                  >
                    <i class="fa fa-plus"></i>
                    <span class="mx-1">{{ 'SALES.CUSTOMERS.CUSTOMER_DATA.ADVANCE_PAYMENT' | translate }}</span>
                  </a>
                  <a
                    *ngIf="[Claims.CREATE_CUSTOMER_PAYMENT] | authorize | async"
                    routerLink="/sales/customers/refund/create"
                    [queryParams]="{ customerId: customer.id }"
                    class="btn btn-raised btn-success round mx-1 align-self-center col-md-auto"
                  >
                    <i class="fa fa-plus"></i>
                    <span class="mx-1">{{ 'SALES.CUSTOMERS.CUSTOMER_DATA.REFUND' | translate }}</span>
                  </a>
                </div>
              </div>
            </form>
          </div>
          <ng-container *ngIf="logs$ | async; let logs">
            <div class="col-md-12" *ngIf="logs.length; else noItems">
              <div class="row">
                <div class="col-md-12 alert alert-light text-center" role="alert">
                  {{ 'SHARED.SEARCH.SEARCH_RESULTS' | translate }} ({{ (paginationInfo$ | async).total | toNumber }})
                  {{ 'SALES.CUSTOMERS.PAYMENT' | translate }}
                </div>
                <div class="col-md-12">
                  <div class="table-responsive">
                    <table class="table text-center" id="logs-table-export-target-element">
                      <thead>
                        <tr>
                          <th scope="col"><i class="fa fa-list-ol"></i><span class="mx-1"></span></th>
                          <th scope="col">{{ 'SALES.CUSTOMERS.CUSTOMER_DATA.PAYMENT' | translate }}</th>
                          <th scope="col">
                            <i class="fa fa-barcode"></i
                            ><span class="mx-1">{{ 'SALES.CUSTOMERS.CUSTOMER_DATA.INVOICE_NUMBER' | translate }}</span>
                          </th>
                          <th scope="col">
                            {{ 'SALES.CUSTOMERS.CUSTOMER_DATA.DEBIT' | translate }}
                          </th>
                          <th scope="col">
                            {{ 'SALES.CUSTOMERS.CUSTOMER_DATA.CREDIT' | translate }}
                          </th>
                          <th scope="col">{{ 'SALES.CUSTOMERS.CUSTOMER_DATA.BALANCE_AFTER' | translate }}</th>
                          <th scope="col">{{ 'SALES.CUSTOMERS.CUSTOMER_DATA.NOTES' | translate }}</th>
                          <th scope="col">{{ 'SALES.CUSTOMERS.CUSTOMER_DATA.CUSTOMER_SUMMARY_DATE' | translate }}</th>
                          <th scope="col">{{ 'SHARED.SEARCH.CREATED_AT' | translate }}</th>
                          <th scope="col">{{ 'SALES.CUSTOMERS.CUSTOMER_DATA.USER' | translate }}</th>
                          <th scope="col">{{ 'SHARED.TOOLS' | translate }}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let log of logs$ | async; trackBy: trackItems; let i = index">
                          <th scope="row">{{ i + 1 }}</th>
                          <td class="font-weight-bold" *ngIf="!log.extraDetails?.length; else withExtraDetails">
                            {{ log.customerLogType?.name | localize: log.customerLogType.nameEn | async }}
                            <span *ngIf="log.bankAccountId">
                              - {{ log.bankAccount?.name | localize: log.bankAccount?.nameEn | async }}</span
                            >
                          </td>
                          <ng-template #withExtraDetails>
                            <td class="font-weight-bold">
                              <span>
                                {{ log.customerLogType?.name | localize: log.customerLogType.nameEn | async }}
                                <span *ngIf="log.bankAccountId">
                                  - {{ log.bankAccount?.name | localize: log.bankAccount?.nameEn | async }}</span
                                >
                              </span>
                              <br />
                              <span> - {{ log.extraDetails }}</span>
                            </td>
                          </ng-template>
                          <td class="font-weight-bold">{{ log.saleInvoice?.invoiceNum }}</td>
                          <ng-container *ngIf="log.advancePaymentBalance !== null; else logValueTemp">
                            <td></td>
                            <td>
                              <span class="text-danger text-decoration-line-through">
                                {{ log.value | toDecimal }}
                              </span>
                              <span class="d-block">{{ log.advancePaymentBalance | toDecimal }}</span>
                            </td>
                          </ng-container>
                          <ng-template #logValueTemp>
                            <ng-container *ngIf="log.customerLogType.isDebit || log.customerLogType.isCredit">
                              <td>
                                <span *ngIf="log.customerLogType.isDebit">{{ log.value | toDecimal }}</span>
                              </td>
                              <td>
                                <span *ngIf="log.customerLogType.isCredit">{{ log.value | toDecimal }}</span>
                              </td>
                            </ng-container>
                            <ng-container *ngIf="!log.customerLogType.isDebit && !log.customerLogType.isCredit">
                              <td class="font-italic text-decoration-underline" colspan="2">
                                {{ log.value | toDecimal }}
                              </td>
                            </ng-container>
                          </ng-template>
                          <td>{{ log.balanceAfter | toDecimal }}</td>
                          <td>{{ log.notes }}</td>
                          <td class="fit-width">
                            <span [ngbTooltip]="log.transactionDate | date: 'time'">
                              {{ log.transactionDate | date }}
                            </span>
                          </td>
                          <td class="fit-width">
                            <span [ngbTooltip]="log.createdAt | date: 'time'">
                              {{ log.createdAt | date }}
                            </span>
                          </td>
                          <td>{{ log.user.name }}</td>
                          <td>
                            <a
                              *ngIf="log.saleInvoiceId"
                              class="info p-0"
                              [routerLink]="['/sales/invoices/view', log.saleInvoiceId]"
                              [ngbTooltip]="'SALES.CUSTOMERS.CUSTOMER_DATA.DISPLAY_INVOICE' | translate"
                            >
                              <i class="fa fa-eye font-medium-3 mr-2"></i>
                            </a>
                            <a
                              *ngIf="log.saleReturnId"
                              class="info p-0"
                              [routerLink]="['/sales/returns/view', log.saleReturnId]"
                              [ngbTooltip]="'SALES.CUSTOMERS.CUSTOMER_DATA.DISPLAY_SALES_RETURNS' | translate"
                            >
                              <i class="fa fa-eye font-medium-3 mr-2"></i>
                            </a>
                            <a
                              *ngIf="log.customerLogAttachments?.length"
                              class="info p-0"
                              (click)="selectedCustomerLog = log; openModal(viewAttachmentsModalRef)"
                              [ngbTooltip]="'SALES.CUSTOMERS.CUSTOMER_DATA.DISPLAY_ATTACHMENTS' | translate"
                            >
                              <i class="fa fa-paperclip font-medium-3 mr-2"></i>
                            </a>
                            <a
                              *ngIf="log.saleInvoicePayments.length"
                              class="info p-0"
                              [ngbTooltip]="'SALES.CUSTOMERS.CUSTOMER_DATA.DISPLAY_PAYMENT_INVOICES' | translate"
                              (click)="selectedCustomerLog = log; openModal(viewPaymentModalRef)"
                            >
                              <i class="fa fa-dollar font-medium-3 mr-2"></i>
                            </a>
                            <a
                              *ngIf="log.customerLogType.key !== 'PAYMENT_BY_ADVANCE_PAYMENT'"
                              class="info p-0"
                              [ngbTooltip]="'SALES.CUSTOMERS.CUSTOMER_DATA.DISPLAY_JOURNAL_LINE' | translate"
                              (click)="selectedCustomerLog = log; openModal(viewJournalModalRef)"
                            >
                              <i class="fa fa-calendar font-medium-3 mr-2"></i>
                            </a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div class="d-flex justify-content-between p-2">
                      <ngb-pagination
                        *ngIf="paginationInfo$ | async; let pagination"
                        maxSize="5"
                        [collectionSize]="pagination.total"
                        [pageSize]="pagination.pageSize"
                        [page]="pagination.page"
                        (pageChange)="pageChanged($event)"
                        boundaryLinks="true"
                      >
                        <ng-template ngbPaginationFirst>{{
                          'SHARED.PAGINATION.PAGINATION_FIRST' | translate
                        }}</ng-template>
                        <ng-template ngbPaginationPrevious>{{
                          'SHARED.PAGINATION.PAGINATION_PREVIOUS' | translate
                        }}</ng-template>
                        <ng-template ngbPaginationNext>{{
                          'SHARED.PAGINATION.PAGINATION_NEXT' | translate
                        }}</ng-template>
                        <ng-template ngbPaginationLast>{{
                          'SHARED.PAGINATION.PAGINATION_LAST' | translate
                        }}</ng-template>
                      </ngb-pagination>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- customer summary list ends -->

<!-- no items template starts -->
<ng-template #noItems>
  <div class="col-md-12 alert alert-warning mt-3 text-center" role="alert">
    {{ 'SALES.CUSTOMERS.SEARCH_CUSTOMER_ACCOUNT_NOT_FOUND' | translate }}
  </div>
</ng-template>
<!-- no items template ends -->

<!-- view payment customer log modal starts -->
<ng-template #viewPaymentModalRef let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title text-info" id="modal-basic-title">
      {{ 'SALES.CUSTOMERS.DISPLAY_PAYMENT_DATA' | translate }}
    </h4>

    <button type="button" class="close" aria-label="Close" (click)="d()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-md-12">
        <div class="table-responsive">
          <table class="table text-center">
            <thead>
              <tr>
                <th scope="col">{{ 'SALES.CUSTOMERS.CUSTOMER_DATA.PAYMENT_TOTAL' | translate }}</th>
                <th scope="col">{{ 'SALES.CUSTOMERS.CUSTOMER_DATA.CUSTOMER_LOG_TYPE' | translate }}</th>
                <th scope="col">{{ 'SALES.CUSTOMERS.CUSTOMER_DATA.INVOICES_PAYMENT_COUNT' | translate }}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row">
                  <span class="badge bg-success round">
                    {{ selectedCustomerLog.value | toDecimal }}
                  </span>
                </th>
                <td>
                  {{
                    selectedCustomerLog.customerLogType?.name
                      | localize: selectedCustomerLog.customerLogType.nameEn
                      | async
                  }}
                  <span *ngIf="selectedCustomerLog.bankAccountId">
                    -
                    {{
                      selectedCustomerLog.bankAccount?.name | localize: selectedCustomerLog.bankAccount?.nameEn | async
                    }}</span
                  >
                </td>
                <td>{{ selectedCustomerLog.saleInvoicePayments.length }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="col-md-12 mt-2">
        <div class="table-responsive">
          <table class="table text-center">
            <thead>
              <tr>
                <th scope="col">
                  <i class="fa fa-barcode"></i
                  ><span class="mx-1">{{ 'SALES.CUSTOMERS.CUSTOMER_DATA.INVOICE_NUMBER' | translate }}</span>
                </th>
                <th scope="col">{{ 'SALES.CUSTOMERS.CUSTOMER_DATA.PAYMENT_VALUE' | translate }}</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let invoicePayment of selectedCustomerLog.saleInvoicePayments">
                <th scope="row">
                  {{ invoicePayment.saleInvoice.invoiceNum }}
                </th>
                <td>
                  <span class="badge bg-success round">
                    {{ invoicePayment.value | toDecimal }}
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="modal-actions">
      <button *ngIf="false" type="button" class="btn btn-outline-dark round" (click)="c()">
        <i class="fa fa-times"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.CANCEL' | translate }}</span>
      </button>
    </div>
  </div>
</ng-template>
<!-- view payment customer log modal ends -->

<!-- view customer log journal modal starts -->
<ng-template #viewJournalModalRef let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title text-info" id="modal-basic-title">
      {{ 'SALES.CUSTOMERS.CUSTOMER_DATA.DISPLAY_CUSTOMER_JOURNAL_LINE' | translate }}
    </h4>

    <button type="button" class="close" aria-label="Close" (click)="d()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <app-journal-details [journal]="selectedCustomerLog.journalAssociations[0]?.journal"> </app-journal-details>
  </div>
  <div class="modal-footer">
    <div class="modal-actions">
      <button *ngIf="false" type="button" class="btn btn-outline-dark round" (click)="c()">
        <i class="fa fa-times"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.CANCEL' | translate }}</span>
      </button>
    </div>
  </div>
</ng-template>
<!-- view customer log journal modal ends -->

<!-- view attachments modal starts -->
<ng-template #viewAttachmentsModalRef let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title text-info" id="modal-basic-title">
      {{ 'SALES.CUSTOMERS.CUSTOMER_DATA.ATTACHMENTS' | translate }}
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="d()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="card-content">
        <div class="px-3">
          <div class="row">
            <div class="form-group col-md-12">
              <!-- customer summary attachments starts -->
              <div class="card-content">
                <div class="px-3">
                  <app-files-viewer
                    [files]="getAttachmentsUrls(selectedCustomerLog.customerLogAttachments)"
                  ></app-files-viewer>
                </div>
              </div>
              <!-- customer summary attachments ends -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="modal-actions">
      <button type="button" class="btn btn-raised btn-primary round" (click)="c()" ngbAutoFocus>
        <i class="fa fa-times"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.CLOSE' | translate }}</span>
      </button>
    </div>
  </div>
</ng-template>
<!-- view attachments modal ends -->

<!-- customer summary ends -->
