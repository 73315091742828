<!-- page header starts -->
<app-page-header [info]="pageInfo"></app-page-header>
<!-- page header ends -->

<!-- petty cash refund requests list starts -->
<div class="row">
  <div class="col-md-12">
    <div class="card">
      <div class="card-header mb-3">
        <h4 class="card-title">{{ 'SHARED.SEARCH.SEARCH_TOOLS' | translate }}</h4>
      </div>
      <div class="card-content">
        <div class="px-3">
          <div class="row">
            <div class="col-md-12">
              <form class="row" [formGroup]="searchForm" (ngSubmit)="search()">
                <div class="form-group col">
                  <label for="pettyCashRefundRequests">{{
                    'FINANCES.PETTY_CASH_REFUND_REQUEST_APPROVALS.PETTY_CASH_REFUND_REQUEST_APPROVAL_DATA.PETTY_CASH_REFUND_REQUESTS'
                      | translate
                  }}</label>
                  <ng-select
                    class="round"
                    formControlName="pettyCashRefundRequests"
                    [multiple]="true"
                    [closeOnSelect]="false"
                    labelForId="pettyCashRefundRequests"
                    searchable="true"
                    [placeholder]="
                      'FINANCES.PETTY_CASH_REFUND_REQUEST_APPROVALS.PETTY_CASH_REFUND_REQUEST_APPROVAL_DATA.PETTY_CASH_REFUND_REQUESTS_PLACEHOLDER'
                        | translate
                    "
                    [notFoundText]="'SHARED.SEARCH.SEARCH_NOT_FOUND' | translate"
                    (open)="pettyCashRefundRequestListVisibility = true"
                  >
                    <ng-option *ngFor="let request of pettyCashRefundRequest" [value]="request.id">
                      {{ request.pettyCash.description }}
                    </ng-option>
                  </ng-select>
                </div>
                <div class="col">
                  <div class="row">
                    <div class="form-group col-12 col-sm-6 mb-0">
                      <label for="fromDate"> {{ 'SHARED.SEARCH.FROM_DATE' | translate }} </label>
                      <div class="input-group round">
                        <input
                          class="form-control"
                          id="fromDate"
                          name="fromDate"
                          formControlName="fromDate"
                          [placeholder]="'SHARED.SEARCH.DATE_PLACEHOLDER' | translate"
                          ngbDatepicker
                          #fromDate="ngbDatepicker"
                        />
                        <div class="input-group-append">
                          <span
                            class="input-group-text gradient-light-blue-indigo"
                            style="cursor: pointer;"
                            (click)="fromDate.toggle()"
                          >
                            <i class="fa fa-calendar"></i>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="form-group col-12 col-sm-6 mb-0">
                      <label for="toDate"> {{ 'SHARED.SEARCH.TO_DATE' | translate }} </label>
                      <div class="input-group round">
                        <input
                          class="form-control"
                          id="toDate"
                          name="toDate"
                          formControlName="toDate"
                          [placeholder]="'SHARED.SEARCH.DATE_PLACEHOLDER' | translate"
                          ngbDatepicker
                          #toDate="ngbDatepicker"
                        />
                        <div class="input-group-append">
                          <span
                            class="input-group-text gradient-light-blue-indigo"
                            style="cursor: pointer;"
                            (click)="toDate.toggle()"
                          >
                            <i class="fa fa-calendar"></i>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-auto form-group d-flex">
                  <button
                    type="submit"
                    class="btn btn-raised btn-primary align-self-end round mx-1 mb-0"
                    [disabled]="isSearching$ | async"
                  >
                    <i class="fa fa-search"></i>
                  </button>
                  <a
                    *ngIf="[Claims.APPROVE_PETTY_CASH_REFUND_REQUEST] | authorize | async"
                    routerLink="/finances/petty-cash-refund-request-approvals/create"
                    class="btn btn-raised btn-success align-self-end round mx-1 mb-0"
                  >
                    <i class="fa fa-plus"></i>
                    <span class="mx-1">{{ 'SHARED.CONFIRM.ADD' | translate }}</span>
                  </a>
                  <app-export-tools
                    *ngIf="(pettyCashRefundRequestApprovals$ | async)?.length"
                    class="d-flex align-self-end mx-1"
                    targetElementId="petty-cash-refund-request-approvals-table-export-target-element"
                    [outputFileName]="'SHARED.EXPORT_TABLES.PETTY_CASH_REFUND_REQUEST_APPROVALS_TABLE' | translate"
                  ></app-export-tools>
                </div>
              </form>
            </div>
            <div class="col-md-12" *ngIf="(pettyCashRefundRequestApprovals$ | async)?.length; else noItems">
              <div class="row">
                <div class="col-md-12 alert alert-light text-center" role="alert">
                  {{ 'SHARED.SEARCH.SEARCH_RESULTS' | translate }} ({{ (paginationInfo$ | async).total | toNumber }})
                  {{
                    'FINANCES.PETTY_CASH_REFUND_REQUEST_APPROVALS.PETTY_CASH_REFUND_REQUEST_APPROVAL_DATA.PETTY_CASH_REFUND_REQUEST_APPROVAL'
                      | translate
                  }}
                </div>
                <div class="col-md-12">
                  <div class="table-responsive">
                    <table
                      class="table text-center"
                      id="petty-cash-refund-request-approvals-table-export-target-element"
                    >
                      <thead>
                        <tr>
                          <th scope="col"><i class="fa fa-list-ol"></i></th>
                          <th scope="col">
                            {{
                              'FINANCES.PETTY_CASH_REFUND_REQUEST_APPROVALS.PETTY_CASH_REFUND_REQUEST_APPROVAL_DATA.PETTY_CASH'
                                | translate
                            }}
                          </th>
                          <th scope="col">
                            <i class="fa fa-usd"></i
                            ><span class="mx-1">{{
                              'FINANCES.PETTY_CASH_REFUND_REQUESTS.PETTY_CASH_REFUND_REQUEST_DATA.REFUNDS_TOTAL'
                                | translate
                            }}</span>
                          </th>
                          <th scope="col">
                            {{
                              'FINANCES.PETTY_CASH_REFUND_REQUEST_APPROVALS.PETTY_CASH_REFUND_REQUEST_APPROVAL_DATA.PETTY_CASH_REFUND_REQUEST_APPROVAL_DATE'
                                | translate
                            }}
                          </th>
                          <th scope="col">
                            {{
                              'FINANCES.PETTY_CASH_REFUND_REQUEST_APPROVALS.PETTY_CASH_REFUND_REQUEST_APPROVAL_DATA.PETTY_CASH_REFUND_REQUEST_DATE'
                                | translate
                            }}
                          </th>
                          <th scope="col">
                            {{
                              'FINANCES.PETTY_CASH_REFUND_REQUEST_APPROVALS.PETTY_CASH_REFUND_REQUEST_APPROVAL_DATA.USER'
                                | translate
                            }}
                          </th>
                          <th scope="col">{{ 'SHARED.TOOLS' | translate }}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          *ngFor="
                            let requestApproval of pettyCashRefundRequestApprovals$ | async;
                            trackBy: trackItems;
                            let i = index
                          "
                        >
                          <th scope="row">{{ i + 1 }}</th>
                          <td>{{ requestApproval.pettyCashRefundRequest.pettyCash.description }}</td>
                          <td>{{ requestApproval.value | toDecimal }}</td>
                          <td>
                            <span [ngbTooltip]="requestApproval.transactionDate | date: 'time'">
                              {{ requestApproval.transactionDate | date }}
                            </span>
                          </td>
                          <td>
                            <span [ngbTooltip]="requestApproval.createdAt | date: 'time'">
                              {{ requestApproval.createdAt | date }}
                            </span>
                          </td>
                          <td>{{ requestApproval.user.name }}</td>
                          <td>
                            <a
                              *ngIf="[Claims.APPROVE_PETTY_CASH_REFUND_REQUEST] | authorize | async"
                              class="info p-0"
                              [routerLink]="['/finances/petty-cash-refund-request-approvals/view', requestApproval.id]"
                              [ngbTooltip]="
                                'FINANCES.PETTY_CASH_REFUND_REQUEST_APPROVALS.PETTY_CASH_REFUND_REQUEST_APPROVAL_DATA.DISPLAY_DETAILS_REFUND_REQUEST_APPROVAL'
                                  | translate
                              "
                            >
                              <i class="fa fa-eye font-medium-3 mr-2"></i>
                            </a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div class="d-flex justify-content-between p-2">
                      <ngb-pagination
                        *ngIf="paginationInfo$ | async; let pagination"
                        maxSize="5"
                        [collectionSize]="pagination.total"
                        [pageSize]="pagination.pageSize"
                        [page]="pagination.page"
                        (pageChange)="pageChanged($event)"
                        boundaryLinks="true"
                      >
                        <ng-template ngbPaginationFirst>{{
                          'SHARED.PAGINATION.PAGINATION_FIRST' | translate
                        }}</ng-template>
                        <ng-template ngbPaginationFirst>{{
                          'SHARED.PAGINATION.PAGINATION_PREVIOUS' | translate
                        }}</ng-template>
                        <ng-template ngbPaginationFirst>{{
                          'SHARED.PAGINATION.PAGINATION_NEXT' | translate
                        }}</ng-template>
                        <ng-template ngbPaginationFirst>{{
                          'SHARED.PAGINATION.PAGINATION_LAST' | translate
                        }}</ng-template>
                      </ngb-pagination>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- petty cash refund requests list ends -->

<!-- no items template starts -->
<ng-template #noItems>
  <div class="col-md-12 alert alert-warning mt-3 text-center" role="alert">
    {{ 'FINANCES.PETTY_CASH_REFUND_REQUEST_APPROVALS.SEARCH_PETTY_CASH_REFUND_REQUEST_APPROVAL_NOT_FOUND' | translate }}
    <a
      *ngIf="[Claims.APPROVE_PETTY_CASH_REFUND_REQUEST] | authorize | async"
      routerLink="/finances/petty-cash-refund-request-approvals/create"
      class="btn btn-raised btn-success round"
    >
      <i class="fa fa-plus"></i>
      <span class="mx-1">{{ 'SHARED.CONFIRM.ADD' | translate }}</span>
    </a>
  </div>
</ng-template>
<!-- no items template ends -->

<!-- petty cash search starts -->
<app-petty-cash-refund-requests-search
  [(visible)]="pettyCashRefundRequestListVisibility"
  (pettyCashRefundRequest)="selectPettyCashRefundRequest($event)"
>
</app-petty-cash-refund-requests-search>
<!-- petty cash search ends -->
