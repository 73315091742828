/**
 * The set of system supported customer log types.
 */
export enum CustomerLogTypes {
  /**
   * The sale invoice log type.
   */
  SALE_INVOICE = 'SALE_INVOICE',

  /**
   * The sale invoice as a opening balance log type.
   */
  OPENING_BALANCE = 'OPENING_BALANCE',

  /**
   * The maintenance invoice type.
   */
  MAINTENANCE_INVOICE = 'MAINTENANCE_INVOICE',

  /**
   * The sale invoice as a point of sale invoice log type.
   */
  POINT_OF_SALE_INVOICE = 'POINT_OF_SALE_INVOICE',

  /**
   * The sale invoice as a return from sales log type.
   */
  RETURNED_SALES = 'RETURNED_SALES',

  /**
   * The  sale invoice as a return from point of sale log type.
   */
  REFUND_RETURNS_FROM_POINT_OF_SALE = 'REFUND_RETURNS_FROM_POINT_OF_SALE',

  /**
   * The sale invoice as a payment cash log type.
   */
  PAYMENT_CASH = 'PAYMENT_CASH',

  /**
   * The  sale invoice as a payment cheque log type.
   */
  PAYMENT_CHEQUE = 'PAYMENT_CHEQUE',

  /**
   * The  sale invoice as a payment bank transfer log type.
   */
  PAYMENT_BANK_TRANSFER = 'PAYMENT_BANK_TRANSFER',

  /**
   * The sale invoice as a payment by employee log type.
   */
  PAYMENT_BY_EMPLOYEE = 'PAYMENT_BY_EMPLOYEE',

  /**
   * The sale invoice as a payment by owner log type.
   */
  PAYMENT_BY_OWNER = 'PAYMENT_BY_OWNER',

  /**
   * The sale invoice as electronic payment log type.
   */
  ELECTRONIC_PAYMENT = 'ELECTRONIC_PAYMENT',

  /**
   * The  sale invoice as a payment cash log type.
   */
  ADVANCE_PAYMENT_CASH = 'ADVANCE_PAYMENT_CASH',

  /**
   * The  sale invoice as a payment cheque log type.
   */
  ADVANCE_PAYMENT_CHEQUE = 'ADVANCE_PAYMENT_CHEQUE',

  /**
   * The  sale invoice as a payment bank transfer log type.
   */

  ADVANCE_PAYMENT_BANK_TRANSFER = 'ADVANCE_PAYMENT_BANK_TRANSFER',

  /**
   * The  sale invoice as a payment electronic log type.
   */

  ADVANCE_PAYMENT_ELECTRONIC = 'ADVANCE_PAYMENT_ELECTRONIC',
}
