import { Component, Input } from '@angular/core';

import { ProjectResource, ProjectResourceLine } from 'projects-management/models';

@Component({
  selector: 'app-project-resource-outgoing-stock-details',
  templateUrl: './project-resource-outgoing-stock-details.component.html',
  styles: [],
})
export class ProjectResourceOutgoingStockDetailsComponent {
  /**
   * Sets the current viewed resource.
   */
  @Input() resource: ProjectResource;

  /**
   * The list of project resource lines.
   */
  @Input() projectResourceOutgoingStockLines: ProjectResourceLine[];
}
