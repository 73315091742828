import { createSelector } from '@ngrx/store';

import { selectLookupsState } from 'lookups/store/selectors/feature.selectors';
import { LookupsState, NationalityState } from 'lookups/store/states';

/**
 * Gets the nationality state.
 */
const selectNationalities = createSelector(selectLookupsState, (state: LookupsState) => state.nationalities);

/**
 * Gets the list of loaded nationalities.
 */
export const getNationalities = createSelector(selectNationalities, (state: NationalityState) => state.data);

/**
 * Gets the error that occurred in the state.
 */
export const getNationalitiesError = createSelector(selectNationalities, (state: NationalityState) => state.error);

/**
 * Determines if there is a running load process.
 */
export const getNationalitiesIsLoading = createSelector(
  selectNationalities,
  (state: NationalityState) => state.isLoading
);

/**
 * Determines if the last load process has been ended successfully.
 */
export const getNationalitiesIsLoaded = createSelector(
  selectNationalities,
  (state: NationalityState) => state.isLoaded
);
