import { SickLeaveType } from 'lookups/models';

/**
 * Represents the sick leave types state.
 */
export class SickLeaveTypeState {
  /**
   * The list of sick leave types.
   */
  public data: SickLeaveType[];

  /**
   * An error that may resulting during processing some actions.
   */
  public error: any;

  /**
   * Determines if there is a running load process.
   */
  public isLoading: boolean;

  /**
   * Determines if the last load process has been ended successfully.
   */
  public isLoaded: boolean;
}
