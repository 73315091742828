import { CreateCurrencyExchangeRateComponent } from 'finances/components/currency-exchange-rates/create-currency-exchange-rate/create-currency-exchange-rate.component';
import { UpdateCurrencyExchangeRateComponent } from 'finances/components/currency-exchange-rates/update-currency-exchange-rate/update-currency-exchange-rate.component';
import { CurrencyExchangeRatesListComponent } from 'finances/components/currency-exchange-rates/currency-exchange-rates-list/currency-exchange-rates-list.component';
import { CurrencyExchangeRatesSearchComponent } from 'finances/components/currency-exchange-rates/currency-exchange-rates-search/currency-exchange-rates-search.component';

export * from 'finances/components/currency-exchange-rates/create-currency-exchange-rate/create-currency-exchange-rate.component';
export * from 'finances/components/currency-exchange-rates/update-currency-exchange-rate/update-currency-exchange-rate.component';
export * from 'finances/components/currency-exchange-rates/currency-exchange-rates-list/currency-exchange-rates-list.component';
export * from 'finances/components/currency-exchange-rates/currency-exchange-rates-search/currency-exchange-rates-search.component';

/**
 * The set of components registered on this sub-module.
 */
export const COMPONENTS: any[] = [
  CreateCurrencyExchangeRateComponent,
  UpdateCurrencyExchangeRateComponent,
  CurrencyExchangeRatesListComponent,
  CurrencyExchangeRatesSearchComponent,
];
