import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subscription } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { skip, tap } from 'rxjs/operators';

import {
  PageInfo,
  PaginationInfo,
  NotificationService,
  NotificationMessage,
  TranslationService,
  APP_CONSTANTS,
} from 'shared';
import { Customer, CustomerEngagement } from 'sales/models';
import { CustomerEngagementType } from 'lookups/models';
import * as fromSalesStore from 'sales/store';
import * as fromLookupsStore from 'lookups/store';
import { Claims } from 'security/models';

@Component({
  selector: 'app-customer-engagements-list',
  templateUrl: './customer-engagements-list.component.html',
  styles: [],
})
export class CustomerEngagementsListComponent implements OnInit, OnDestroy {
  /**
   * The create modal template reference.
   */
  @ViewChild('createModalRef') createModalRef: ElementRef<any>;

  /**
   * The update modal template reference.
   */
  @ViewChild('updateModalRef') updateModalRef: ElementRef<any>;

  /**
   * The delete modal template reference.
   */
  @ViewChild('deleteModalRef') deleteModalRef: ElementRef<any>;

  /**
   * The decimal mask.
   */
  readonly DECIMAL_MASK = APP_CONSTANTS.numeric.decimal.mask;

  /**
   * Gets or sets the information about the current page.
   */
  pageInfo: PageInfo = {
    title: 'SALES.CUSTOMER_ENGAGEMENTS.CUSTOMERS_ENGAGEMENT_PAGE_TITLE',
    icon: 'icon-pin',
  };

  /**
   * The system supported user claims.
   */
  Claims = Claims;

  /**
   * The list of customer engagements.
   */
  customerEngagements$: Observable<CustomerEngagement[]>;

  /**
   * The list of selected customers.
   */
  customers: Customer[] = [];

  /**
   * The list of customer engagement types.
   */
  customerEngagementTypes$: Observable<CustomerEngagementType[]>;

  /**
   * The pagination info.
   */
  paginationInfo$: Observable<PaginationInfo>;

  /**
   * Indicates whether there is a search process is running or not.
   */
  isSearching$: Observable<boolean>;

  /**
   * The list of current selected customer's engagements.
   */
  engagements: CustomerEngagement[] = [];

  /**
   * The search form.
   */
  searchForm: FormGroup;

  /**
   * The create customer engagement form.
   */
  createForm: FormGroup;

  /**
   * The update customer engagement form.
   */
  updateForm: FormGroup;

  /**
   * The delete customer engagement form.
   */
  deleteForm: FormGroup;

  /**
   * Indicates whether there is a create customer engagement process is running or not.
   */
  isCustomerEngagementCreating$: Observable<boolean>;

  /**
   * Indicates whether there is a update customer engagement process is running or not.
   */
  isCustomerEngagementUpdating$: Observable<boolean>;

  /**
   * Indicates whether there is a delete customer engagement process is running or not.
   */
  isCustomerEngagementDeleting$: Observable<boolean>;

  /**
   * Gets or sets the currently selected customer engagements for delete.
   */
  selectedCustomerEngagement: CustomerEngagement;

  /**
   * Shows or hide the customers list in search.
   */
  customerListVisibility = false;

  /**
   * Shows or hide the customers list.
   */
  customersListVisibility = false;

  /**
   * The set of subscriptions on this components,
   * these subscriptions must be unsubscribed before this component got destroyed.
   */
  subscriptions = new Subscription();

  /**
   * Gets or sets the current selected language.
   */
  get currentLang() {
    return this.translationService.language;
  }

  /**
   * @param modalService The modal service.
   * @param notificationService The notification service.
   * @param translationService: The translation service.
   * @param salesStore$ The sales-module store.
   * @param lookupsStore$ The lookups-module store.
   */
  constructor(
    private modalService: NgbModal,
    private notificationService: NotificationService,
    private translationService: TranslationService,
    private salesStore$: Store<fromSalesStore.SalesState>,
    private lookupsStore$: Store<fromLookupsStore.LookupsState>
  ) {}

  ngOnInit(): void {
    this.init();
  }

  /**
   * Destroy component data
   */
  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  /**
   * Initialize component data.
   */
  init() {
    this.initForm();
    /**
     * reset on customer engagement creation complete
     */
    this.subscriptions.add(
      this.salesStore$
        .pipe(
          select(fromSalesStore.getSelectedCustomerEngagementCreateCompleted),
          skip(1),
          tap((created) => {
            if (created) {
              this.initForm();
              this.createForm.markAsUntouched();
              this.closeModal();
            }
          })
        )
        .subscribe()
    );

    /**
     * reset on customer engagement update completed
     */
    this.subscriptions.add(
      this.salesStore$
        .pipe(
          select(fromSalesStore.getSelectedCustomerEngagementUpdateCompleted),
          skip(1),
          tap((updated) => {
            if (updated) {
              this.initForm();
              this.updateForm.markAsUntouched();
              this.closeModal();
            }
          })
        )
        .subscribe()
    );

    /**
     * reset on customer engagement delete completed
     */
    this.subscriptions.add(
      this.salesStore$
        .pipe(
          select(fromSalesStore.getSelectedCustomerEngagementDeleteCompleted),
          skip(1),
          tap((deleted) => {
            if (deleted) {
              this.initForm();
              this.deleteForm.markAsUntouched();
              this.closeModal();
            }
          })
        )
        .subscribe()
    );

    /**
     * Load data.
     */
    this.isCustomerEngagementCreating$ = this.salesStore$.pipe(
      select(fromSalesStore.getSelectedCustomerEngagementCreating)
    );
    this.isCustomerEngagementUpdating$ = this.salesStore$.pipe(
      select(fromSalesStore.getSelectedCustomerEngagementUpdating)
    );
    this.isCustomerEngagementDeleting$ = this.salesStore$.pipe(
      select(fromSalesStore.getSelectedCustomerEngagementDeleting)
    );
    this.isSearching$ = this.salesStore$.pipe(select(fromSalesStore.getCustomerEngagementsSearching));

    let isManualSearchTriggeredBeforeForEngagements = false;
    this.customerEngagements$ = this.salesStore$.pipe(
      select(fromSalesStore.getCustomerEngagements),
      tap((engagements) => {
        if (!isManualSearchTriggeredBeforeForEngagements && !engagements.length) {
          isManualSearchTriggeredBeforeForEngagements = true;
          this.search();
        }
      })
    );

    let isManualSearchTriggeredBeforeForTypes = false;
    this.customerEngagementTypes$ = this.lookupsStore$.pipe(
      select(fromLookupsStore.getCustomerEngagementTypes),
      tap((types) => {
        if (!isManualSearchTriggeredBeforeForTypes && !types.length) {
          isManualSearchTriggeredBeforeForTypes = true;
          this.lookupsStore$.dispatch(new fromLookupsStore.GetAllCustomerEngagementType());
        }
      })
    );

    this.paginationInfo$ = this.salesStore$.pipe(select(fromSalesStore.getCustomerEngagementsPaginationInfo));
  }

  /**
   * Initialize form and add validators.
   */
  initForm() {
    this.searchForm = new FormGroup({
      customers: new FormControl([]),
      customerEngagementTypes: new FormControl([]),
      page: new FormControl(1),
    });

    this.createForm = new FormGroup({
      customerId: new FormControl(null, Validators.required),
      typeId: new FormControl(null, Validators.required),
      value: new FormControl('', [Validators.required, Validators.minLength(0)]),
      notes: new FormControl('', [Validators.minLength(0), Validators.maxLength(200)]),
    });

    this.updateForm = new FormGroup({
      id: new FormControl(null, Validators.required),
      customerName: new FormControl(null),
      typeName: new FormControl(null),
      value: new FormControl('', [Validators.required, Validators.minLength(0)]),
      notes: new FormControl('', [Validators.minLength(0), Validators.maxLength(200)]),
      isActive: new FormControl(false, Validators.required),
    });

    this.deleteForm = new FormGroup({
      id: new FormControl(null, Validators.required),
      customerName: new FormControl(null),
      typeName: new FormControl(null),
      value: new FormControl(''),
      notes: new FormControl(''),
      isActive: new FormControl({ value: false, disabled: true }),
    });

    /**
     * Dynamically set create-form validations based on the selected value.
     */
    this.subscriptions.add(this.createForm.controls.value.valueChanges.subscribe(() => this.setCreateValidations()));

    /**
     * Dynamically set update-form validations based on the selected value.
     */
    this.subscriptions.add(this.updateForm.controls.value.valueChanges.subscribe(() => this.setUpdateValidations()));
  }

  /**
   * Sets the conditional validation for the create-form based on the selected values.
   */
  setCreateValidations() {
    const value = parseInt(this.createForm.get('value').value ?? '0');

    if (value > 0) {
    } else if (value === 0) {
    }
  }

  /**
   * Sets the conditional validation for the update-form based on the selected values.
   */
  setUpdateValidations() {
    const value = parseInt(this.updateForm.get('value').value ?? '0');

    if (value > 0) {
    } else if (value === 0) {
    }
  }

  /**
   * Handles search parameters change.
   */
  search(event?: KeyboardEvent) {
    if (event && event.key === 'Enter') {
      event.preventDefault();
      return;
    }

    this.applyFiltersAndSearch();
  }

  /**
   * Applies the search filters and dispatches a search action.
   * @param page The current page number, default to `1`.
   */
  applyFiltersAndSearch(page: number = 1) {
    const { ...rest } = this.searchForm.value;

    this.salesStore$.dispatch(
      new fromSalesStore.SearchCustomerEngagements({
        ...rest,
        page,
      })
    );
  }

  /**
   * Handles pagination page-changed event.
   * @param page The current selected page number.
   */
  pageChanged(page: number) {
    /** Update pagination page. */
    this.searchForm.patchValue({ page });

    /** Get selected page items. */
    this.applyFiltersAndSearch();
  }

  /**
   * Enables trackBy feature for *ngFor utility to track items
   * depending on a comparer to reduce DOM change.
   * If the item is already exist then no HTML will change, only bounded values.
   * @param index The index of the item.
   * @param customer engagement The item to determine if it was changed or not.
   */
  trackItems(index: number, customerEngagement: CustomerEngagement) {
    return customerEngagement ? customerEngagement.id : undefined;
  }

  /**
   * Shows create new engagement modal.
   */
  create() {
    this.createForm.reset({ notes: '' });
    this.openModal(this.createModalRef);
  }

  /**
   * Creates a new customer engagement only if the filled data passed validations.
   */
  confirmCreate() {
    if (this.createForm.invalid) {
      const errorMessage = new NotificationMessage();

      if (this.createForm.get('customerId').invalid) {
        errorMessage.title = this.translationService.translate(
          'SALES.CUSTOMER_ENGAGEMENTS.CUSTOMER_ENGAGEMENT_DATA_VALIDATION.CUSTOMER_NAME_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate(
            'SALES.CUSTOMER_ENGAGEMENTS.CUSTOMER_ENGAGEMENT_DATA_VALIDATION.CUSTOMER_NAME_IS_REQUIRED'
          ),
        ];
      } else if (this.createForm.get('typeId').invalid) {
        errorMessage.title = this.translationService.translate(
          'SALES.CUSTOMER_ENGAGEMENTS.CUSTOMER_ENGAGEMENT_DATA_VALIDATION.CUSTOMER_ENGAGEMENT_TYPE_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate(
            'SALES.CUSTOMER_ENGAGEMENTS.CUSTOMER_ENGAGEMENT_DATA_VALIDATION.CUSTOMER_ENGAGEMENT_TYPE_IS_REQUIRED'
          ),
        ];
      } else if (this.createForm.get('value').invalid) {
        errorMessage.title = this.translationService.translate(
          'SALES.CUSTOMER_ENGAGEMENTS.CUSTOMER_ENGAGEMENT_DATA_VALIDATION.CUSTOMER_ENGAGEMENT_VALUE_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate(
            'SALES.CUSTOMER_ENGAGEMENTS.CUSTOMER_ENGAGEMENT_DATA_VALIDATION.CUSTOMER_ENGAGEMENT_VALUE_LENGTH_ERROR'
          ),
        ];
      } else if (this.createForm.get('notes').invalid) {
        errorMessage.title = this.translationService.translate(
          'SALES.CUSTOMER_ENGAGEMENTS.CUSTOMER_ENGAGEMENT_DATA_VALIDATION.NOTES_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate(
            'SALES.CUSTOMER_ENGAGEMENTS.CUSTOMER_ENGAGEMENT_DATA_VALIDATION.NOTES_LENGTH_ERROR'
          ),
        ];
      }

      this.createForm.markAllAsTouched();
      return this.notificationService.warningWithTitle(errorMessage);
    } else {
      const { customerId, typeId, value, notes } = this.createForm.value;
      this.salesStore$.dispatch(
        new fromSalesStore.CreateCustomerEngagement({
          customerId,
          customerEngagementTypeId: typeId,
          value,
          notes,
        })
      );
    }
  }

  /**
   * Shows an update engagement modal for the given engagement.
   * @param engagement The engagement to be updated.
   */
  update(engagement: CustomerEngagement) {
    this.updateForm.reset({
      ...engagement,
      customerName: this.currentLang === 'en' ? engagement.customer.nameEn : engagement.customer.name,
      typeName:
        this.currentLang === 'en' ? engagement.customerEngagementType.nameEn : engagement.customerEngagementType.name,
    });
    this.openModal(this.updateModalRef);
  }

  /**
   * Updates the currently edited engagement only if the filled data passed validations.
   */
  confirmUpdate() {
    if (this.updateForm.invalid) {
      const errorMessage = new NotificationMessage();

      if (this.updateForm.get('id').invalid) {
        errorMessage.title = this.translationService.translate(
          'SALES.CUSTOMER_ENGAGEMENTS.CUSTOMER_ENGAGEMENT_DATA_VALIDATION.CUSTOMER_ENGAGEMENT_ERROR'
        );
        errorMessage.body = [];
      } else if (this.updateForm.get('value').invalid) {
        errorMessage.title = this.translationService.translate(
          'SALES.CUSTOMER_ENGAGEMENTS.CUSTOMER_ENGAGEMENT_DATA_VALIDATION.CUSTOMER_ENGAGEMENT_VALUE_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate(
            'SALES.CUSTOMER_ENGAGEMENTS.CUSTOMER_ENGAGEMENT_DATA_VALIDATION.CUSTOMER_ENGAGEMENT_VALUE_LENGTH_ERROR'
          ),
        ];
      } else if (this.updateForm.get('notes').invalid) {
        errorMessage.title = this.translationService.translate(
          'SALES.CUSTOMER_ENGAGEMENTS.CUSTOMER_ENGAGEMENT_DATA_VALIDATION.NOTES_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate(
            'SALES.CUSTOMER_ENGAGEMENTS.CUSTOMER_ENGAGEMENT_DATA_VALIDATION.NOTES_LENGTH_ERROR'
          ),
        ];
      }

      this.updateForm.markAllAsTouched();
      return this.notificationService.warningWithTitle(errorMessage);
    } else {
      this.salesStore$.dispatch(new fromSalesStore.UpdateCustomerEngagement(this.updateForm.value));
    }
  }

  /**
   * Shows an delete engagement modal for the given engagement.
   * @param engagement The engagement to be deleted.
   */
  delete(engagement: CustomerEngagement) {
    this.deleteForm.reset({
      ...engagement,
      customerName: this.currentLang === 'en' ? engagement.customer.nameEn : engagement.customer.name,
      typeName:
        this.currentLang === 'en' ? engagement.customerEngagementType.nameEn : engagement.customerEngagementType.name,
      notes: engagement.notes,
    });
    this.openModal(this.deleteModalRef);
  }

  /**
   * Deletes the currently selected engagement.
   */
  confirmDelete() {
    if (this.deleteForm.invalid) {
      this.deleteForm.markAllAsTouched();
      return this.notificationService.warning(
        this.translationService.translate(
          'SALES.CUSTOMER_ENGAGEMENTS.CUSTOMER_ENGAGEMENT_DATA_VALIDATION.CUSTOMER_ENGAGEMENT_ERROR'
        )
      );
    } else {
      this.salesStore$.dispatch(new fromSalesStore.DeleteCustomerEngagement(this.deleteForm.value?.id));
    }
  }

  /**
   * Opens the modal of the given templateRef.
   * @param modalRef The modal templateRef to be opened.
   */
  openModal(modalRef) {
    this.modalService.open(modalRef);
  }

  /**
   * Closes the currently opened modal.
   */
  closeModal() {
    this.modalService.dismissAll();
  }

  /**
   * Adds the newly selected customers the customers search list.
   * @param customers The list of newly selected customers to be added.
   */
  selectCustomers(customers: Customer[]) {
    const selectedIds: number[] = this.searchForm.get('customers').value;
    this.customers = [...this.customers.filter((customer) => selectedIds.includes(customer.id)), ...customers];
    this.searchForm.patchValue({ customers: this.customers.map((customer) => customer.id) });
  }

  /**
   * Selects the newly selected customer from the customers search list.
   * @param customers The list of newly selected customers to select the only one in the list.
   */
  selectCustomer([customer]: Customer[]) {
    if (customer) {
      this.customers = [customer];
      this.createForm.patchValue({ customerId: customer.id });
    }
  }
}
