<div class="row mb-1">
  <div class="col-md-auto">
    <button
      *ngIf="allowAddProductUnitOfMeasureRate"
      type="button"
      class="btn btn-sm btn-raised btn-primary round"
      [ngbTooltip]="'STORES.PRODUCTS.PRODUCT_DATA.CREATE_PRODUCT_UNIT_OF_MEASURE_RATE' | translate"
      placement="top-right"
      (click)="addProductUnitOfMeasureRate()"
    >
      <i class="fa fa-plus"></i>
    </button>
    <button
      *ngIf="allowClearProductUnitOfMeasureRates"
      type="button"
      class="btn btn-sm btn-raised btn-danger round mx-2"
      [ngbTooltip]="'STORES.PRODUCTS.PRODUCT_DATA.DELETE_PRODUCT_UNIT_OF_MEASURE_RATE' | translate"
      placement="left"
      (click)="openDeleteModal()"
      [disabled]="productUnitOfMeasureRatesForm.controls.length == 0"
    >
      <i class="fa fa-trash"></i>
    </button>
  </div>
</div>

<div [formGroup]="productUnitOfMeasureRatesForm.parent" class="row">
  <div class="col-md-12">
    <div class="table-responsive">
      <table class="table text-center">
        <thead>
          <tr>
            <th scope="col"><i class="fa fa-list-ol"></i></th>
            <th scope="col">{{ 'STORES.PRODUCTS.PRODUCT_DATA.UNIT_OF_MEASURE' | translate }}</th>
            <th scope="col">{{ 'STORES.PRODUCTS.PRODUCT_DATA.RATE' | translate }}</th>
            <th scope="col">{{ 'SHARED.TOOLS' | translate }}</th>
          </tr>
        </thead>
        <tbody formArrayName="productUnitOfMeasureRates">
          <tr
            [formGroup]="formGroup"
            *ngFor="let formGroup of productUnitOfMeasureRatesForm.controls; let index = index"
          >
            <th scope="row">{{ index + 1 }}</th>
            <td>
              {{ formGroup.value.unitOfMeasure.name | localize: formGroup.value.unitOfMeasure.nameEn | async }}
            </td>
            <td>
              <input
                type="text"
                [mask]="DECIMAL_MASK"
                thousandSeparator=","
                minLength="0"
                id="rate"
                class="form-control round"
                [placeholder]="'STORES.PRODUCTS.PRODUCT_DATA.RATE_PLACEHOLDER' | translate"
                formControlName="rate"
              />
            </td>
            <td>
              <a
                class="danger p-0"
                [ngbTooltip]="'SHARED.CONFIRM.DELETE' | translate"
                (click)="removeProductUnitOfMeasureRate(index)"
              >
                <i class="fa fa-close font-medium-3 mr-2"></i>
              </a>
            </td>
          </tr>
        </tbody>
        <tfoot class="tfoot-light">
          <tr>
            <td colspan="5">
              <i class="fa fa-bookmark c-primary"></i>
              <span>
                {{ productUnitOfMeasureRatesForm.length | toNumber }}
                {{ 'STORES.PRODUCTS.PRODUCT_DATA.PRODUCT_UNIT_OF_MEASURE_RATE' | translate }}
              </span>
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  </div>
</div>

<!-- confirm delete all product unit of measure rate modal starts -->
<ng-template #deleteModalRef let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title text-danger" id="modal-basic-title">
      {{ 'STORES.PRODUCTS.CONFIRM_DELETE_ALL_UNIT_OF_MEASURE_RATE_TITLE' | translate }}
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="d()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p class="text-danger">{{ 'STORES.PRODUCTS.CONFIRM_DELETE_ALL_UNIT_OF_MEASURE_RATE_MESSAGE' | translate }}</p>
  </div>
  <div class="modal-footer">
    <div class="modal-actions">
      <button
        type="button"
        class="btn btn-raised btn-danger round"
        (click)="c(); resetProductUnitOfMeasureRates()"
        ngbAutofocus
      >
        <i class="fa fa-check"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.YES' | translate }}</span>
      </button>
      <button type="button" class="btn btn-outline-dark round" (click)="c()">
        <i class="fa fa-times"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.CANCEL' | translate }}</span>
      </button>
    </div>
  </div>
</ng-template>
<!-- confirm delete all product unit of measure rates modal ends -->

<!-- unit of measure search starts -->
<app-units-of-measure-search
  closeOnSelect="true"
  [(visible)]="unitsOfMeasureListVisibility"
  (unitsOfMeasure)="selectUnitOfMeasure($event)"
>
</app-units-of-measure-search>
<!-- unit of measure search ends -->
