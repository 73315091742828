import { PurchaseOrderTypeActionType, PurchaseOrderTypeActions } from 'lookups/store/actions';
import { PurchaseOrderTypeState } from 'lookups/store/states';

/**
 * The initial state from which the state starts.
 */
const initialState: PurchaseOrderTypeState = {
  data: [],
  error: null,
  isLoaded: false,
  isLoading: false,
};

/**
 * The reducer function that is called in each action dispatch against the state.
 * @param state The current state.
 * @param action The action that will affect the state.
 */
export function purchaseOrderTypeReducer(
  state: PurchaseOrderTypeState = initialState,
  action: PurchaseOrderTypeActions
): PurchaseOrderTypeState {
  switch (action.type) {
    case PurchaseOrderTypeActionType.GET_ALL_PURCHASE_ORDER_TYPE: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case PurchaseOrderTypeActionType.GET_ALL_PURCHASE_ORDER_TYPE_FAIL: {
      return {
        ...state,
        error: action.payload,
        isLoading: false,
        isLoaded: false,
      };
    }

    case PurchaseOrderTypeActionType.GET_ALL_PURCHASE_ORDER_TYPE_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        isLoading: false,
        isLoaded: true,
      };
    }

    default:
      return state;
  }
}
