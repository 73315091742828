<div class="row">
  <div class="col-md-12">
    <!-- journal transfer stock details starts -->
    <div class="card-header mb-3 pt-0">
      <h4 class="card-title">{{ 'FINANCES.JOURNALS.JOURNAL_DATA.TRANSFER_STOCK_DATA' | translate }}</h4>
    </div>
    <div class="card-content">
      <div class="px-3">
        <div class="row">
          <div class="form-group col-md-6">
            <label for="fromLocation">{{ 'FINANCES.JOURNALS.JOURNAL_DATA.FORM_LOCATION' | translate }}</label>
            <input
              type="text"
              id="fromLocation"
              class="form-control round"
              [value]="transferStock.fromLocation.name | localize: transferStock.fromLocation.nameEn | async"
              readonly
            />
          </div>
          <div class="form-group col-md-6">
            <label for="toLocation">{{ 'FINANCES.JOURNALS.JOURNAL_DATA.TO_LOCATION' | translate }}</label>
            <input
              type="text"
              id="toLocation"
              class="form-control round"
              [value]="transferStock.toLocation.name | localize: transferStock.toLocation.nameEn | async"
              readonly
            />
          </div>
          <div class="form-group col-md-3">
            <label for="transferStock.transactionDate">{{
              'FINANCES.JOURNALS.JOURNAL_DATA.JOURNAL_DATE' | translate
            }}</label>
            <input
              type="text"
              id="transferStock.transactionDate"
              class="form-control round"
              [value]="transferStock.transactionDate | date: 'datetime'"
              readonly
            />
          </div>
          <div class="form-group col-md-3">
            <label for="transferStock.createdAt">{{ 'SHARED.SEARCH.CREATED_AT' | translate }}</label>
            <input
              type="text"
              id="transferStock.createdAt"
              class="form-control round"
              [value]="transferStock.createdAt | date: 'datetime'"
              readonly
            />
          </div>
          <div class="form-group col-md-6">
            <label for="user">{{ 'FINANCES.JOURNALS.JOURNAL_DATA.USER_CREATE' | translate }}</label>
            <input type="text" id="user" class="form-control round" [value]="transferStock.user.name" readonly />
          </div>
          <div class="form-group col-md-12">
            <label for="notes">{{ 'FINANCES.JOURNALS.JOURNAL_DATA.NOTES' | translate }}</label>
            <textarea
              id="notes"
              class="form-control round"
              [innerText]="transferStock.notes"
              [placeholder]="'FINANCES.JOURNALS.JOURNAL_DATA.NOTES_NOT_FOUND' | translate"
              readonly
            ></textarea>
          </div>
        </div>
      </div>
    </div>
    <!-- journal transfer stock details ends -->

    <!-- products details starts -->
    <div class="card-header mb-3 pt-2">
      <h4 class="card-title">{{ 'FINANCES.JOURNALS.JOURNAL_DATA.PRODUCTS' | translate }}</h4>
    </div>
    <div class="card-content">
      <div class="px-3">
        <div class="row">
          <div class="col-md-12">
            <div class="table-responsive">
              <table class="table text-center">
                <thead>
                  <tr>
                    <th scope="col"><i class="fa fa-list-ol"></i></th>
                    <th scope="col">
                      <i class="fa fa-comments"></i>
                      {{ 'FINANCES.JOURNALS.JOURNAL_DATA.PRODUCT_DESCRIPTION' | translate }}
                    </th>
                    <th scope="col">
                      <i class="fa fa-list-ol"></i>
                      {{ 'FINANCES.JOURNALS.JOURNAL_DATA.PRODUCT_QUANTITY' | translate }}
                    </th>
                    <th scope="col">
                      <i class="fa fa-balance-scale"></i>
                      {{ 'FINANCES.JOURNALS.JOURNAL_DATA.PRODUCT_UNIT_OF_MEASURE' | translate }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let product of transferStock.transferStockProducts; let i = index">
                    <th scope="row">{{ i + 1 }}</th>
                    <td>
                      {{
                        (product.product?.description | localize: product.product?.descriptionEn | async) ||
                          product.description
                      }}
                      <div *ngIf="product.subProduct" class="mt-1 d-block">
                        <app-sub-product-info [subProduct]="product.subProduct"></app-sub-product-info>
                      </div>
                    </td>
                    <td>{{ product.quantity | toDecimal }}</td>
                    <td>{{ product.unitOfMeasure.name | localize: product.unitOfMeasure.nameEn | async }}</td>
                  </tr>
                </tbody>
                <tfoot class="tfoot-light">
                  <tr>
                    <td [attr.colspan]="2">
                      <i class="fa fa-bookmark c-primary"></i>
                      <span>
                        {{ transferStock.transferStockProducts.length | toNumber }}
                        {{ 'FINANCES.JOURNALS.JOURNAL_DATA.PRODUCT' | translate }}</span
                      >
                    </td>
                    <td [attr.colspan]="2">
                      <i class="fa fa-bookmark c-primary"></i>
                      <span>
                        {{ productsQuantity(transferStock.transferStockProducts) | toDecimal }}
                        {{ 'FINANCES.JOURNALS.JOURNAL_DATA.QUANTITY' | translate }}</span
                      >
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- products details ends -->
  </div>
</div>
