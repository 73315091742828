import { Injectable } from '@angular/core';

import { of } from 'rxjs';
import { switchMap, map, catchError } from 'rxjs/operators';
import { Effect, ofType, Actions } from '@ngrx/effects';

import { LookupsService } from 'lookups/services';
import {
  CustomerLogTypeActionType,
  GetAllCustomerLogTypeFail,
  GetAllCustomerLogTypeSuccess,
} from 'lookups/store/actions';

@Injectable()
export class CustomerLogTypeEffects {
  constructor(private actions$: Actions, private lookupsService: LookupsService) {}

  /* ========================= GET_ALL_CUSTOMER_LOG_TYPE =================================== */
  @Effect()
  getAllCustomerLogTypes$ = this.actions$.pipe(
    ofType(CustomerLogTypeActionType.GET_ALL_CUSTOMER_LOG_TYPE),
    switchMap(() =>
      this.lookupsService.getAllCustomerLogTypes().pipe(
        map((response) => new GetAllCustomerLogTypeSuccess(response)),
        catchError((error) => of(new GetAllCustomerLogTypeFail(error)))
      )
    )
  );
}
