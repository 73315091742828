import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';

import { AppHttpResponse } from 'shared';
import { PosDevice } from 'sales/models';

/**
 * The pos device services includes functionality to create, search, findById, update and delete for a pos device.
 */
@Injectable()
export class PosDevicesService {
  /**
   * The relative route for the pos device .
   *
   * No leading or trailing slashes required.
   */
  private PosDeviceApi = 'sales/pos-devices';

  constructor(private http: HttpClient) {}

  /**
   * Searches in the pos device by name and locations.
   * @param name of the pos device name.
   * @param locations of the pos device locations.
   * @param page The current pagination page number.
   * @param pageSize The maximum number of pos device allowed per one pagination page.
   */
  public search(
    name: string,
    locations: number[],
    page: number,
    pageSize: number
  ): Observable<AppHttpResponse<PosDevice[]>> {
    const params = new HttpParams()
      .set('name', name)
      .set('locations', locations.join(','))
      .set('page', page.toString())
      .set('pageSize', pageSize.toString());
    return this.http.get<AppHttpResponse<PosDevice[]>>(`${this.PosDeviceApi}`, { params });
  }
}
