import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import * as fromServices from 'lookups/services';
import * as fromStore from 'lookups/store';

/**
 * The lookups-module that includes all system lookups entities.
 * @example Currency.
 * @summary This module should be imported to the root-module so that it is available to all modules.
 */
@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('lookups', fromStore.reducers),
    EffectsModule.forFeature(fromStore.effects),
  ],
  providers: [...fromServices.SERVICES],
})
export class LookupsModule {
  /**
   * Register all of services belong to lookup module into the module that calls this `forRoot()` method.
   */
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: LookupsModule,
      providers: [...fromServices.SERVICES],
    };
  }
}
