import { PaginationInfo } from 'shared/models';
import { PettyCash } from 'finances/models';

/**
 * Represents the petty Cash state.
 */
export class PettyCashState {
  /**
   * The list of petty Cash.
   */
  public data: PettyCash[];

  /**
   * The pagination info for the petty Cash.
   */
  public paginationInfo: PaginationInfo;

  /**
   * An error that may resulting during processing some actions.
   */
  public error: any;

  /**
   * Determines if there is a running search process.
   */
  public isSearching: boolean;

  /**
   * Determines if the last search process has been ended successfully.
   */
  public isSearchCompleted: boolean;

  /**
   * The current selected petty cash.
   */
  public selectedPettyCash: PettyCash;

  /**
   * Determines if there is a running find-petty cash process.
   */
  isFinding: boolean;

  /**
   * Determines if the last find-petty cash process has been ended successfully.
   */
  isFindCompleted: boolean;

  /**
   * Determines if there is a running create process.
   */
  public isCreating: boolean;

  /**
   * Determines if the last create process has been ended successfully.
   */
  public isCreateCompleted: boolean;

  /**
   * Determines if there is a running update process.
   */
  isUpdating: boolean;

  /**
   * Determines if the last update process has been ended successfully.
   */
  isUpdateCompleted: boolean;

  /**
   * Determines if there is a running activate process.
   */
  isActivating: boolean;

  /**
   * Determines if the last activate  process has been ended successfully.
   */
  isActivateCompleted: boolean;

  /**
   * Determines if there is a running block process.
   */
  isBlocking: boolean;

  /**
   * Determines if the last block  process has been ended successfully.
   */
  isBlockCompleted: boolean;

  /**
   * Determines if there is a running delete process.
   */
  isDeleting: boolean;

  /**
   * Determines if the last delete process has been ended successfully.
   */
  isDeleteCompleted: boolean;
}
