import { Component, Input } from '@angular/core';

import { ProjectRevenue } from 'projects-management/models';

@Component({
  selector: 'app-project-revenue-invoice-details',
  templateUrl: './project-revenue-invoice-details.component.html',
  styles: [],
})
export class ProjectRevenueInvoiceDetailsComponent {
  /**
   * Sets the current viewed revenue.
   */
  @Input() revenue: ProjectRevenue;
}
