import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { AppHttpResponse } from 'shared';
import { ProjectResourceLine } from 'projects-management/models';

/**
 * The projects services includes functionality to findById for an project resource.
 */
@Injectable()
export class ProjectResourcesService {
  /**
   * The relative route for the project resources.
   *
   * No leading or trailing slashes required.
   */
  private projectsApi = 'projects-management/projects';
  private projectResourceLinesApi = 'project-resource-lines';

  constructor(private http: HttpClient) {}

  /**
   * Finds the project resource with the given id.
   * @param projectResourceId The id of the project resource.
   */
  public findById(projectResourceId: number): Observable<AppHttpResponse<ProjectResourceLine[]>> {
    return this.http.get<AppHttpResponse<ProjectResourceLine[]>>(
      `${this.projectsApi}/${this.projectResourceLinesApi}/${projectResourceId}`
    );
  }

  /**
   * Finds the project resource payroll lines with the given id.
   * @param projectResourcePayrollId The id of the project resource.
   */
  public findProjectResourcePayrollLinesById(
    projectResourcePayrollId: number
  ): Observable<AppHttpResponse<ProjectResourceLine[]>> {
    return this.http.get<AppHttpResponse<ProjectResourceLine[]>>(
      `${this.projectsApi}/${this.projectResourceLinesApi}/payrolls/${projectResourcePayrollId}`
    );
  }

  /**
   * Finds the project resource purchase return lines with the given id.
   * @param projectResourcePurchaseReturnId The id of the project resource.
   */
  public findProjectResourcePurchaseReturnLinesById(
    projectResourcePurchaseReturnId: number
  ): Observable<AppHttpResponse<ProjectResourceLine[]>> {
    return this.http.get<AppHttpResponse<ProjectResourceLine[]>>(
      `${this.projectsApi}/${this.projectResourceLinesApi}/purchase-returns/${projectResourcePurchaseReturnId}`
    );
  }

  /**
   * Finds the project resource purchase invoice lines with the given id.
   * @param projectResourcePurchaseInvoiceId The id of the project resource.
   */
  public findProjectResourcePurchaseInvoiceLinesById(
    projectResourcePurchaseInvoiceId: number
  ): Observable<AppHttpResponse<ProjectResourceLine[]>> {
    return this.http.get<AppHttpResponse<ProjectResourceLine[]>>(
      `${this.projectsApi}/${this.projectResourceLinesApi}/purchase-invoices/${projectResourcePurchaseInvoiceId}`
    );
  }

  /**
   * Finds the project resource outgoing stock lines with the given id.
   * @param projectResourceOutgoingStockId The id of the project resource.
   */
  public findProjectResourceOutgoingStockLinesById(
    projectResourceOutgoingStockId: number
  ): Observable<AppHttpResponse<ProjectResourceLine[]>> {
    return this.http.get<AppHttpResponse<ProjectResourceLine[]>>(
      `${this.projectsApi}/${this.projectResourceLinesApi}/outgoing-stocks/${projectResourceOutgoingStockId}`
    );
  }

  /**
   * Finds the project resource asset depreciation lines with the given id.
   * @param projectResourceAssetId The id of the project resource.
   */
  public findProjectResourceAssetDepreciationLinesById(
    projectResourceAssetId: number
  ): Observable<AppHttpResponse<ProjectResourceLine[]>> {
    return this.http.get<AppHttpResponse<ProjectResourceLine[]>>(
      `${this.projectsApi}/${this.projectResourceLinesApi}/asset-depreciations/${projectResourceAssetId}`
    );
  }
}
