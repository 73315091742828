import { Action } from '@ngrx/store';

import { AssetType } from 'lookups/models';
import { AppHttpResponse } from 'shared';

/**
 * The Lookups-Module store  asset type action types.
 */
export enum AssetTypeActionType {
  GET_ALL_ASSET_TYPE = '[Lookups] [AssetType] Get All Asset Types',
  GET_ALL_ASSET_TYPE_FAIL = '[Lookups] [AssetType] Get All Asset Types Fail',
  GET_ALL_ASSET_TYPE_SUCCESS = '[Lookups] [AssetType] Get All Asset Types Success',
}

/**
 * Represents a store  asset type action.
 */
export class GetAllAssetType implements Action {
  /**
   * The type of the action.
   */
  readonly type = AssetTypeActionType.GET_ALL_ASSET_TYPE;

  /**
   * Fires a new  asset type action.
   */
  constructor() {}
}

/**
 * Represents a store  asset type fail action.
 */
export class GetAllAssetTypeFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = AssetTypeActionType.GET_ALL_ASSET_TYPE_FAIL;

  /**
   * Fires a new  asset type fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store  asset type success action.
 */
export class GetAllAssetTypeSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = AssetTypeActionType.GET_ALL_ASSET_TYPE_SUCCESS;

  /**
   * Fires a  asset type success action.
   * @param payload An object contains the list of  asset Types.
   */
  constructor(public payload: AppHttpResponse<AssetType[]>) {}
}

/**
 * Lookups-module  asset type action types.
 */
export type AssetTypeActions = GetAllAssetType | GetAllAssetTypeFail | GetAllAssetTypeSuccess;
