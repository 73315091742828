<!-- page header starts -->
<app-page-header [info]="pageInfo"></app-page-header>
<!-- page header ends -->
<div class="row">
  <div class="col-md-12">
    <form [formGroup]="form" (ngSubmit)="submit()" *ngIf="request$ | async; let request">
      <div class="card">
        <div class="card-header mb-3">
          <h4 class="card-title">
            {{ 'FINANCES.PETTY_CASH_REFUND_REQUESTS.PETTY_CASH_REFUND_REQUEST_DATA_TITLE' | translate }}
          </h4>
        </div>
        <!-- petty cash refund request details starts -->
        <div class="card-content">
          <div class="px-3 pt-3">
            <div class="row">
              <div class="col-md-12">
                <div class="px-3">
                  <div class="row">
                    <div class="form-group col-md-3">
                      <label for="pettyCashId">{{
                        'FINANCES.PETTY_CASH_REFUND_REQUESTS.PETTY_CASH_REFUND_REQUEST_DATA.PETTY_CASH' | translate
                      }}</label>
                      <input
                        type="text"
                        id="pettyCashId"
                        class="form-control round"
                        [value]="request.pettyCash.description"
                        readonly
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <label for="transactionDate">{{
                        'FINANCES.PETTY_CASH_REFUND_REQUESTS.PETTY_CASH_REFUND_REQUEST_DATA.PETTY_CASH_REFUND_REQUEST_DATE'
                          | translate
                      }}</label>
                      <div class="input-group round">
                        <input
                          class="form-control"
                          id="transactionDate"
                          name="transactionDate"
                          formControlName="transactionDate"
                          [placeholder]="'SHARED.SEARCH.DATE_PLACEHOLDER' | translate"
                          ngbDatepicker
                          #transactionDate="ngbDatepicker"
                        />
                        <div class="input-group-append">
                          <span
                            class="input-group-text gradient-light-blue-indigo"
                            style="cursor: pointer;"
                            (click)="transactionDate.toggle()"
                          >
                            <i class="fa fa-calendar"></i>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="form-group col-md-3">
                      <label for="createdAt">{{ 'SHARED.SEARCH.CREATED_AT' | translate }}</label>
                      <input
                        type="text"
                        id="createdAt"
                        class="form-control round"
                        [value]="request.createdAt | date: 'date'"
                        readonly
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <label for="user">{{
                        'FINANCES.PETTY_CASH_REFUND_REQUESTS.PETTY_CASH_REFUND_REQUEST_DATA.USER' | translate
                      }}</label>
                      <input type="text" id="user" class="form-control round" [value]="request.user.name" readonly />
                    </div>
                    <div class="form-group col-md-12">
                      <label for="notes">{{
                        'FINANCES.PETTY_CASH_REFUND_REQUESTS.PETTY_CASH_REFUND_REQUEST_DATA.NOTES' | translate
                      }}</label>
                      <textarea
                        id="notes"
                        class="form-control round"
                        [placeholder]="
                          'FINANCES.PETTY_CASH_REFUND_REQUESTS.PETTY_CASH_REFUND_REQUEST_DATA.NOTES_NOT_FOUND'
                            | translate
                        "
                        rows="1"
                        minlength="0"
                        maxlength="200"
                        formControlName="notes"
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- petty cash refund request lines details starts -->
        <div class="card-header mb-3 pt-2">
          <h4 class="card-title">
            {{
              'FINANCES.PETTY_CASH_REFUND_REQUESTS.PETTY_CASH_REFUND_REQUEST_DATA.PETTY_CASH_REFUND_REQUESTS'
                | translate
            }}
          </h4>
        </div>
        <div class="card-content">
          <div class="px-3">
            <app-petty-cash-refund-requests-form
              [linesForm]="linesForm"
              [types]="types$ | async"
              [newPettyCashRefundRequests]="newPettyCashRefundRequests"
              [debitNotificationPettyCashRefundRequestTypeId]="debitNotificationPettyCashRefundRequestTypeId"
              [taxes]="taxes$ | async"
              [defaultTax]="defaultTax | async"
            ></app-petty-cash-refund-requests-form>
          </div>
          <!-- petty cash refund request lines details ends -->

          <!-- request summary starts -->
          <div class="card-header mb-3 pt-3">
            <h4 class="card-title">
              {{
                'FINANCES.PETTY_CASH_REFUND_REQUESTS.PETTY_CASH_REFUND_REQUEST_DATA.PETTY_CASH_REFUND_ACCOUNT'
                  | translate
              }}
            </h4>
          </div>
          <div class="card-content">
            <div class="px-3">
              <div class="row">
                <div class="col-md-12">
                  <div class="row">
                    <div class="form-group col-md-4">
                      <label for="requestLinesTotal">{{
                        'FINANCES.PETTY_CASH_REFUND_REQUESTS.PETTY_CASH_REFUND_REQUEST_DATA.REFUNDS_TOTAL' | translate
                      }}</label>
                      <input
                        type="text"
                        id="requestLinesTotal"
                        name="requestLinesTotal"
                        class="form-control round"
                        [value]="(requestLinesTotal | toDecimal) || '0.0'"
                        readonly
                      />
                    </div>
                    <div class="form-group col-md-4">
                      <label for="tax">{{
                        'FINANCES.PETTY_CASH_REFUND_REQUESTS.PETTY_CASH_REFUND_REQUEST_DATA.TAX_TOTAL' | translate
                      }}</label>
                      <input
                        type="text"
                        id="tax"
                        name="tax"
                        class="form-control round"
                        [value]="(requestLinesTax | toDecimal) || '0.0'"
                        readonly
                      />
                    </div>
                    <div class="form-group col-md-4">
                      <label for="requestTotal" class="font-weight-bold" style="color: #212529;">
                        <i class="fa fa-star"></i>
                        <span class="mx-1">{{
                          'FINANCES.PETTY_CASH_REFUND_REQUESTS.PETTY_CASH_REFUND_REQUEST_DATA.PETTY_CASH_REFUND_TOTAL'
                            | translate
                        }}</span>
                      </label>
                      <input
                        type="text"
                        id="requestTotal"
                        name="requestTotal"
                        class="form-control round"
                        [value]="(requestTotal | toDecimal) || '0.0'"
                        readonly
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div class="form-group col-12 d-flex justify-content-between flex-column flex-sm-row mt-2">
                      <button
                        *ngIf="
                          [Claims.CREATE_PETTY_CASH_REFUND_REQUEST, Claims.APPROVE_PETTY_CASH_REFUND_REQUEST]
                            | authorize
                            | async
                        "
                        type="submit"
                        class="btn btn-raised btn-success round"
                        [disabled]="isUpdating$ | async"
                      >
                        <i class="fa fa-check"></i>
                        <span class="mx-1">{{ 'SHARED.CONFIRM.SAVE' | translate }}</span>
                      </button>
                      <a class="btn btn-dark round" routerLink="/finances/petty-cash-refund-requests">
                        <i class="fa fa-undo"></i>
                        <span class="mx-1">{{
                          'FINANCES.PETTY_CASH_REFUND_REQUESTS.PETTY_CASH_REFUND_REQUEST_DATA.PETTY_CASH_REFUND_REQUESTS_RETURN'
                            | translate
                        }}</span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- petty cash refund request details ends -->
      </div>
    </form>
  </div>
</div>
<!-- view attachments modal starts -->
<ng-template #viewAttachmentsModalRef let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title text-info" id="modal-basic-title">
      {{ 'FINANCES.PETTY_CASH_REFUND_REQUESTS.PETTY_CASH_REFUND_REQUEST_DATA.ATTACHMENTS' | translate }}
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="d()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="card-content">
        <div class="px-3">
          <div class="row">
            <div class="form-group col-md-12">
              <!-- request attachments starts -->
              <div class="card-content">
                <div class="px-3">
                  <app-files-viewer
                    *ngIf="selectedLine.pettyCashRefundRequestLineAttachments?.length; else noAttachments"
                    [files]="getAttachmentsUrls(selectedLine.pettyCashRefundRequestLineAttachments)"
                  ></app-files-viewer>
                </div>
              </div>
              <!-- request attachments ends -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="modal-actions">
      <button type="button" class="btn btn-raised btn-primary round" (click)="c()" ngbAutoFocus>
        <i class="fa fa-times"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.CLOSE' | translate }}</span>
      </button>
    </div>
  </div>
</ng-template>
<!-- view attachments modal ends -->

<!-- confirm modal starts -->
<ng-template #confirmModalRef let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
      {{ 'FINANCES.PETTY_CASH_REFUND_REQUESTS.UPDATE_PETTY_CASH_REFUND_REQUEST_PAGE_TITLE' | translate }}
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="d()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p class="text-success">
      {{ 'FINANCES.PETTY_CASH_REFUND_REQUESTS.CONFIRM_UPDATE_PETTY_CASH_REFUND_REQUEST_MESSAGE' | translate }}
    </p>
  </div>
  <div class="modal-footer">
    <div class="modal-actions">
      <button type="button" class="btn btn-raised btn-success round" (click)="c(); confirm()" ngbAutofocus>
        <i class="fa fa-check"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.SAVE' | translate }}</span>
      </button>
      <button type="button" class="btn btn-outline-dark round" (click)="c()">
        <i class="fa fa-times"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.CANCEL' | translate }}</span>
      </button>
    </div>
  </div>
</ng-template>
<!-- confirm modal ends -->

<!-- no attachments template starts -->
<ng-template #noAttachments>
  <div class="col-md-12 alert alert-warning mt-3 text-center" role="alert">
    {{ 'FINANCES.PETTY_CASH_REFUND_REQUESTS.PETTY_CASH_REFUND_REQUEST_DATA.ATTACHMENTS_NOT_FOUND' | translate }}
  </div>
</ng-template>
<!-- no attachments template ends -->
