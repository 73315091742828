/**
 * The set of system supported asset types.
 */
export enum AssetTypes {
  /**
   * The fixed asset type of asset types.
   */
  FIXED_ASSETS = 'FIXED_ASSETS',

  /**
   * The intangible asset type of asset types.
   */
  INTANGIBLE_ASSETS = 'INTANGIBLE_ASSETS',
}
