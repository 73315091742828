import { findIndex, remove } from 'lodash';

import { VariantsActions, VariantsActionType } from 'stores/store/actions';
import { VariantState } from 'stores/store/states';

/**
 * The initial state from which the state starts.
 */
const initialState: VariantState = {
  data: [],
  paginationInfo: {
    total: 0,
    page: 1,
    pageSize: 0,
    count: 0,
  },
  error: null,
  isSearching: false,
  isSearchCompleted: false,
  selectedVariant: null,
  isFinding: false,
  isFindCompleted: false,
  isCreating: false,
  isCreateCompleted: false,
  isUpdating: false,
  isUpdateCompleted: false,
  isDeleting: false,
  isDeleteCompleted: false,
};

/**
 * The reducer function that is called in each action dispatch against the state.
 * @param state The current state.
 * @param action The action that will affect the state.
 */
export function variantsReducer(state: VariantState = initialState, action: VariantsActions): VariantState {
  switch (action.type) {
    //#region SEARCH_VARIANTS

    case VariantsActionType.SEARCH_VARIANTS: {
      return {
        ...state,
        isSearching: true,
        isSearchCompleted: false,
        error: null,
      };
    }

    case VariantsActionType.SEARCH_VARIANTS_FAIL: {
      return {
        ...state,
        error: action.payload,
        isSearching: false,
        isSearchCompleted: false,
      };
    }

    case VariantsActionType.SEARCH_VARIANTS_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        paginationInfo: {
          ...action.payload.meta,
        },
        error: null,
        isSearching: false,
        isSearchCompleted: true,
      };
    }

    //#endregion SEARCH_VARIANTS

    //#region FIND_VARIANT

    case VariantsActionType.FIND_VARIANT: {
      return {
        ...state,
        selectedVariant: null,
        error: null,
        isFinding: true,
        isFindCompleted: false,
      };
    }

    case VariantsActionType.FIND_VARIANT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isFinding: false,
        isFindCompleted: false,
      };
    }

    case VariantsActionType.FIND_VARIANT_SUCCESS: {
      let variants = [...state.data];
      const variantIndex = findIndex(variants, (variant) => variant.id === action.payload.data.id);

      /* If variant was found, update it. */
      if (variantIndex >= 0) {
        variants[variantIndex] = action.payload.data;
      } else {
        /* else, insert it to the beginning of the array. */
        variants = [action.payload.data, ...variants];
      }

      return {
        ...state,
        data: variants,
        selectedVariant: action.payload.data,
        error: null,
        isFinding: false,
        isFindCompleted: true,
      };
    }

    //#endregion FIND_VARIANT

    //#region CREATE_VARIANT

    case VariantsActionType.CREATE_VARIANT: {
      return {
        ...state,
        isCreating: true,
        isCreateCompleted: false,
        error: null,
      };
    }

    case VariantsActionType.CREATE_VARIANT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isCreating: false,
        isCreateCompleted: false,
      };
    }

    case VariantsActionType.CREATE_VARIANT_SUCCESS: {
      return {
        ...state,
        data: [action.payload.data, ...state.data],
        selectedVariant: action.payload.data,
        error: null,
        isCreating: false,
        isCreateCompleted: true,
      };
    }

    //#endregion CREATE_VARIANT

    //#region UPDATE_VARIANT

    case VariantsActionType.UPDATE_VARIANT: {
      return {
        ...state,
        isUpdating: true,
        isUpdateCompleted: false,
        error: null,
      };
    }

    case VariantsActionType.UPDATE_VARIANT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isUpdating: false,
        isUpdateCompleted: false,
      };
    }

    case VariantsActionType.UPDATE_VARIANT_SUCCESS: {
      let variants = [...state.data];
      const variantIndex = findIndex(variants, (variant) => variant.id === action.payload.data.id);

      /* If variant was found, update it. */
      if (variantIndex >= 0) {
        variants[variantIndex] = action.payload.data;
      } else {
        /* else, insert it to the beginning of the array. */
        variants = [action.payload.data, ...variants];
      }

      return {
        ...state,
        data: variants,
        selectedVariant: action.payload.data,
        error: null,
        isUpdating: false,
        isUpdateCompleted: true,
      };
    }

    //#endregion UPDATE_VARIANT

    //#region DELETE_VARIANT

    case VariantsActionType.DELETE_VARIANT: {
      return {
        ...state,
        isDeleting: true,
        isDeleteCompleted: false,
        error: null,
      };
    }

    case VariantsActionType.DELETE_VARIANT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isDeleting: false,
        isDeleteCompleted: false,
      };
    }

    case VariantsActionType.DELETE_VARIANT_SUCCESS: {
      const variants = [...state.data];

      /* Remove the deleted variant from the array. */
      remove(variants, (variant) => variant.id === action.payload.data.id);

      return {
        ...state,
        data: variants,
        selectedVariant: action.payload.data,
        error: null,
        isDeleting: false,
        isDeleteCompleted: true,
      };
    }

    //#endregion DELETE_VARIANT

    default:
      return state;
  }
}
