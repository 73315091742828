import { findIndex, remove } from 'lodash';

import { BoardsActionType, BoardsActions } from 'sales/store/actions';
import { BoardsState } from 'sales/store/states';

/**
 * The initial state from which the state starts.
 */
const initialState: BoardsState = {
  data: [],
  paginationInfo: {
    total: 0,
    page: 1,
    pageSize: 0,
    count: 0,
  },
  error: null,
  isSearching: false,
  isSearchCompleted: false,
  isSearchingInPos: false,
  isSearchInPosCompleted: false,
  selectedBoard: null,
  isFinding: false,
  isFindCompleted: false,
  isCreating: false,
  isCreateCompleted: false,
  isUpdating: false,
  isUpdateCompleted: false,
  isDeleting: false,
  isDeleteCompleted: false,
};

/**
 * The reducer function that is called in each action dispatch against the state.
 * @param state The current state.
 * @param action The action that will affect the state.
 */
export function boardsReducer(state: BoardsState = initialState, action: BoardsActions): BoardsState {
  switch (action.type) {
    //#region SEARCH_BOARDS

    case BoardsActionType.SEARCH_BOARDS: {
      return {
        ...state,
        error: null,
        isSearching: true,
        isSearchCompleted: false,
      };
    }

    case BoardsActionType.SEARCH_BOARDS_FAIL: {
      return {
        ...state,
        error: action.payload,
        isSearching: false,
        isSearchCompleted: false,
      };
    }

    case BoardsActionType.SEARCH_BOARDS_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        paginationInfo: {
          ...action.payload.meta,
        },
        error: null,
        isSearching: false,
        isSearchCompleted: true,
      };
    }
    // #end region SEARCH_BOARDS

    //#region SEARCH_BOARDS_IN_POS

    case BoardsActionType.SEARCH_BOARDS_IN_POS: {
      return {
        ...state,
        error: null,
        isSearchingInPos: true,
        isSearchInPosCompleted: false,
      };
    }

    case BoardsActionType.SEARCH_BOARDS_IN_POS_FAIL: {
      return {
        ...state,
        error: action.payload,
        isSearchingInPos: false,
        isSearchInPosCompleted: false,
      };
    }

    case BoardsActionType.SEARCH_BOARDS_IN_POS_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        paginationInfo: {
          ...action.payload.meta,
        },
        error: null,
        isSearchingInPos: false,
        isSearchInPosCompleted: true,
      };
    }

    // #end region SEARCH_BOARDS_IN_POS

    //#region FIND_BOARD

    case BoardsActionType.FIND_BOARD: {
      return {
        ...state,
        selectedBoard: null,
        error: null,
        isFinding: true,
        isFindCompleted: false,
      };
    }

    case BoardsActionType.FIND_BOARD_FAIL: {
      return {
        ...state,
        error: action.payload,
        isFinding: false,
        isFindCompleted: false,
      };
    }

    case BoardsActionType.FIND_BOARD_SUCCESS: {
      let boards = [...state.data];
      const boardIndex = findIndex(boards, (board) => board.id === action.payload.data.id);

      /* If board was found, update it. */
      if (boardIndex >= 0) {
        boards[boardIndex] = action.payload.data;
      } else {
        /* else, insert it to the beginning of the array. */
        boards = [action.payload.data, ...boards];
      }

      return {
        ...state,
        data: boards,
        selectedBoard: action.payload.data,
        error: null,
        isFinding: false,
        isFindCompleted: true,
      };
    }

    //#endregion FIND_BOARD

    //#region CREATE_BOARD

    case BoardsActionType.CREATE_BOARD: {
      return {
        ...state,
        error: null,
        isCreating: true,
        isCreateCompleted: false,
      };
    }

    case BoardsActionType.CREATE_BOARD_FAIL: {
      return {
        ...state,
        error: action.payload,
        isCreating: false,
        isCreateCompleted: false,
      };
    }

    case BoardsActionType.CREATE_BOARD_SUCCESS: {
      return {
        ...state,
        data: [action.payload.data, ...state.data],
        selectedBoard: action.payload.data,
        error: null,
        isCreating: false,
        isCreateCompleted: true,
      };
    }

    // #end region CREATE_BOARD

    //#region UPDATE_BOARD

    case BoardsActionType.UPDATE_BOARD: {
      return {
        ...state,
        error: null,
        isUpdating: true,
        isUpdateCompleted: false,
      };
    }

    case BoardsActionType.UPDATE_BOARD_FAIL: {
      return {
        ...state,
        error: action.payload,
        isUpdating: false,
        isUpdateCompleted: false,
      };
    }

    case BoardsActionType.UPDATE_BOARD_SUCCESS: {
      let boards = [...state.data];
      const boardIndex = findIndex(boards, (board) => board.id === action.payload.data.id);

      /* If board was found, update it. */
      if (boardIndex >= 0) {
        boards[boardIndex] = action.payload.data;
      } else {
        /* else, insert it to the beginning of the array. */
        boards = [action.payload.data, ...boards];
      }

      return {
        ...state,
        data: boards,
        selectedBoard: action.payload.data,
        error: null,
        isUpdating: false,
        isUpdateCompleted: true,
      };
    }

    //#endregion UPDATE_BOARD

    //#region DELETE_BOARD

    case BoardsActionType.DELETE_BOARD: {
      return {
        ...state,
        error: null,
        isDeleting: true,
        isDeleteCompleted: false,
      };
    }

    case BoardsActionType.DELETE_BOARD_FAIL: {
      return {
        ...state,
        error: action.payload,
        isDeleting: false,
        isDeleteCompleted: false,
      };
    }

    case BoardsActionType.DELETE_BOARD_SUCCESS: {
      const boards = [...state.data];

      /* Remove the deleted board from the array. */
      remove(boards, (Board) => Board.id === action.payload.data.id);

      return {
        ...state,
        data: boards,
        selectedBoard: action.payload.data,
        error: null,
        isDeleting: false,
        isDeleteCompleted: true,
      };
    }

    //#endregion DELETE_BOARD

    default:
      return state;
  }
}
