import { UserReportsEffects } from 'reports/store/effects/user-reports.effects';
import { StoresReportsEffects } from 'reports/store/effects/stores.effects';
import { FinancesReportsEffects } from 'reports/store/effects/finances.effects';
import { PurchasesReportsEffects } from 'reports/store/effects/purchases.effects';
import { SalesReportsEffects } from 'reports/store/effects/sales.effects';

/**
 * The Reports-Module store effects.
 */
export const effects: any[] = [
  UserReportsEffects,
  StoresReportsEffects,
  FinancesReportsEffects,
  PurchasesReportsEffects,
  SalesReportsEffects,
];
