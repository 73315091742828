import { Directive, ElementRef, Input, HostListener } from '@angular/core';

import { TouchKeyboardConfig } from 'touch-keyboard/models';

/**
 * A directive that marks an html input as a target for a touch keyboard.
 * @example <input type="text" appTouchKeyboardTarget touchKeyboardConfig="config" />
 */
@Directive({
  selector: '[appTouchKeyboardTarget]',
})
export class TouchKeyboardTargetDirective {
  /**
   *
   * @param elementRef A reference to the HTML host element.
   */
  constructor(private elementRef: ElementRef<HTMLInputElement>) {}

  /**
   * Sets the configurations of the touch keyboard that the current html input is assigned to.
   */
  @Input() touchKeyboardConfig: TouchKeyboardConfig;

  /**
   * Sets the current HTML input as the touch keyboard target.
   */
  @HostListener('focus') onFocus(): void {
    this.touchKeyboardConfig.activeInput = this.elementRef.nativeElement;
  }
}
