import { Action } from '@ngrx/store';

import { SickLeaveType } from 'lookups/models';
import { AppHttpResponse } from 'shared';

/**
 * The Lookups-Module store  sick leave type action types.
 */
export enum SickLeaveTypeActionType {
  GET_ALL_SICK_LEAVE_TYPES = '[Lookups] [SickLeaveType] Get All Sick Leave Types',
  GET_ALL_SICK_LEAVE_TYPES_FAIL = '[Lookups] [SickLeaveType] Get All Sick Leave Types Fail',
  GET_ALL_SICK_LEAVE_TYPES_SUCCESS = '[Lookups] [SickLeaveType] Get All Sick Leave Types Success',
}

/**
 * Represents a store sick leave type action.
 */
export class GetAllSickLeaveTypes implements Action {
  /**
   * The type of the action.
   */
  readonly type = SickLeaveTypeActionType.GET_ALL_SICK_LEAVE_TYPES;

  /**
   * Fires a new sick leave type action.
   */
  constructor() {}
}

/**
 * Represents a store sick leave type fail action.
 */
export class GetAllSickLeaveTypesFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = SickLeaveTypeActionType.GET_ALL_SICK_LEAVE_TYPES_FAIL;

  /**
   * Fires a new sick leave type fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store sick leave type success action.
 */
export class GetAllSickLeaveTypesSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = SickLeaveTypeActionType.GET_ALL_SICK_LEAVE_TYPES_SUCCESS;

  /**
   * Fires a sick leave type success action.
   * @param payload An object contains the list of sick leave types.
   */
  constructor(public payload: AppHttpResponse<SickLeaveType[]>) {}
}

/**
 * Lookups-module sick leave type action types.
 */
export type SickLeaveTypeActions = GetAllSickLeaveTypes | GetAllSickLeaveTypesFail | GetAllSickLeaveTypesSuccess;
