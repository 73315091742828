import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { skip, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Store, select } from '@ngrx/store';

import * as fromSalesStore from 'sales/store';
import { PageInfo, TranslationService } from 'shared';
import { Customer } from 'sales/models';
import { Claims } from 'security/models';

/**
 * The customer file pages.
 */
enum PAGES {
  info = 'info',
  'summary' = 'summary',
  'engagements' = 'engagements',
  'assets' = 'assets',
  'invoices' = 'invoices',
  'unpaid-invoices' = 'unpaid-invoices',
}

@Component({
  selector: 'app-customer-file',
  templateUrl: './customer-file.component.html',
  styles: [],
})
export class CustomerFileComponent implements OnInit {
  /**
   * Gets or sets the information about the current page.
   */
  pageInfo: PageInfo = {
    title: 'SALES.CUSTOMERS.CUSTOMER_DATA.CUSTOMER_FILE',
    icon: 'fa fa-folder-open',
  };

  /**
   * Gets or sets the id of the current edited customer.
   */
  customerId: number;

  /**
   * The system supported user claims.
   */
  Claims = Claims;

  /**
   * The current edited customer.
   */
  customer$: Observable<Customer>;

  /**
   * The name of the current customer.
   */
  customerName: string;

  /**
   * Gets or sets the selected page.
   * @default 'info'
   */
  activePage: PAGES = PAGES.info;

  /**
   * Gets or sets the current selected language.
   */
  get currentLang() {
    return this.translationService.language;
  }

  /**
   * @param locationService The location service.
   * @param route The activated route.
   * @param salesStore$ sales-module store.
   * @param translationService The translation service.
   */
  constructor(
    private locationService: Location,
    private route: ActivatedRoute,
    private salesStore$: Store<fromSalesStore.SalesState>,
    private translationService: TranslationService
  ) {}

  ngOnInit(): void {
    this.init();
  }

  /**
   * Initialize component data.
   */
  init() {
    /* Load the selected customer. */
    this.customerId = +this.route.snapshot.params.customerId;
    this.salesStore$.dispatch(new fromSalesStore.FindCustomer(this.customerId));

    this.customer$ = this.salesStore$.pipe(
      select(fromSalesStore.getSelectedCustomer),
      skip(1),
      tap((customer) => {
        if (customer) {
          this.customerName = this.currentLang === 'en' ? customer.nameEn : customer.name;

          /* Update page info. */
          this.pageInfo = {
            ...this.pageInfo,
            subTitle: `(${this.customerName})`,
          };
        }
      })
    );

    /** Select the user desired page. */
    this.activePage = PAGES[this.route.snapshot.fragment] ?? this.activePage;
    this.selectedPageChanged(this.activePage);
  }

  /**
   * Updates the browser url according to the selected page.
   * @param page The newly selected page.
   */
  selectedPageChanged(page: PAGES) {
    this.locationService.replaceState(`${this.locationService.path()}#${PAGES[page]}`);
  }
}
