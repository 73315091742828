import { createSelector } from '@ngrx/store';

import { StoresState, LocationState } from 'stores/store/states';
import { selectStoresState } from 'stores/store/selectors/feature.selectors';

/**
 * Gets the locations state.
 */
const selectLocationState = createSelector(selectStoresState, (state: StoresState) => state.locations);

/**
 * Gets the list of loaded locations.
 */
export const getLocations = createSelector(selectLocationState, (state: LocationState) => state.data);

/**
 * Gets the list of loaded organization locations.
 */
export const getOrganizationLocations = createSelector(
  selectLocationState,
  (state: LocationState) => state.organizationLocationsData
);

/**
 * Gets the locations pagination info.
 */
export const getLocationsPaginationInfo = createSelector(
  selectLocationState,
  (state: LocationState) => state.paginationInfo
);

/**
 * Gets the organization locations pagination info.
 */
export const getOrganizationLocationsPaginationInfo = createSelector(
  selectLocationState,
  (state: LocationState) => state.organizationLocationsPaginationInfo
);

/**
 * Gets the error that occurred in the state.
 */
export const getLocationsError = createSelector(selectLocationState, (state: LocationState) => state.error);

/**
 * Determines if there is a running search process.
 */
export const getLocationsSearching = createSelector(selectLocationState, (state: LocationState) => state.isSearching);

/**
 * Determines if the last search process has been ended successfully.
 */
export const getLocationsSearchCompleted = createSelector(
  selectLocationState,
  (state: LocationState) => state.isSearchCompleted
);

/**
 * Determines if there is a running organization locations search process.
 */
export const getOrganizationLocationsSearching = createSelector(
  selectLocationState,
  (state: LocationState) => state.isOrganizationLocationsSearching
);

/**
 * Determines if the last organization locations search process has been ended successfully.
 */
export const getOrganizationLocationsSearchCompleted = createSelector(
  selectLocationState,
  (state: LocationState) => state.isOrganizationLocationsSearchCompleted
);

/**
 * Gets the selected location.
 */
export const getSelectedLocation = createSelector(
  selectLocationState,
  (state: LocationState) => state.selectedLocation
);

/**
 * Determines if there is a running find-location process.
 */
export const getLocationsFinding = createSelector(selectLocationState, (state: LocationState) => state.isFinding);

/**
 * Determines if the last find-location process has been ended successfully.
 */
export const getLocationsFindCompleted = createSelector(
  selectLocationState,
  (state: LocationState) => state.isFindCompleted
);

/**
 * Determines if there is a running create process.
 */
export const getSelectedLocationCreating = createSelector(
  selectLocationState,
  (state: LocationState) => state.isCreating
);

/**
 * Determines if the last create process has been ended successfully.
 */
export const getSelectedLocationCreateCompleted = createSelector(
  selectLocationState,
  (state: LocationState) => state.isCreateCompleted
);

/**
 * Determines if there is a running update process.
 */
export const getSelectedLocationUpdating = createSelector(
  selectLocationState,
  (state: LocationState) => state.isUpdating
);

/**
 * Determines if the last update process has been ended successfully.
 */
export const getSelectedLocationUpdateCompleted = createSelector(
  selectLocationState,
  (state: LocationState) => state.isUpdateCompleted
);

/**
 * Determines if there is a running delete process.
 */
export const getSelectedLocationDeleting = createSelector(
  selectLocationState,
  (state: LocationState) => state.isDeleting
);

/**
 * Determines if the last delete process has been ended successfully.
 */
export const getSelectedLocationDeleteCompleted = createSelector(
  selectLocationState,
  (state: LocationState) => state.isDeleteCompleted
);
