/**
 * Represents a vendor payment log type.
 */
export class VendorPaymentLogType {
  /**
   * Gets or sets the id of the vendor payment log type.
   */
  id: number;

  /**
   * Gets or sets the name of the vendor payment log type.
   */
  name: string;

  /**
   * Gets or sets the English name of the vendor payment log type.
   */
  nameEn: string;

  /**
   * Gets or sets the key of the vendor payment log type.
   */
  key: string;
}
