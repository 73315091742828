import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, FormArray, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { skip, tap } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { uniq } from 'lodash';
import { Decimal } from 'decimal.js';

import * as fromSalesStore from 'sales/store';
import * as fromFinancesStore from 'finances/store';
import * as fromLookupsStore from 'lookups/store';
import { CustomValidators, NotificationMessage, NotificationService, PageInfo, TranslationService } from 'shared';
import { CreateJournalLineInput, JournalLineFormItem, Account, BankAccount } from 'finances/models';
import { CustomerLogType, CustomerLogTypes } from 'lookups/models';
import { CreateCustomerPaymentInput, CustomerPaymentFormItem } from 'sales/models';
import { CanComponentDeactivate } from 'auth';

/**
 * The payment pages.
 */
enum PAGES {
  details = 'details',
  journal = 'journal',
}

@Component({
  selector: 'app-create-customer-payments',
  templateUrl: './create-customer-payments.component.html',
  styles: [],
})
export class CreateCustomerPaymentsComponent implements OnInit, OnDestroy, CanComponentDeactivate {
  /**
   * The confirm modal template reference.
   */
  @ViewChild('confirmModalRef') confirmModalRef: ElementRef<any>;

  /**
   * The reset modal template reference.
   */
  @ViewChild('resetModalRef') resetModalRef: ElementRef<any>;

  /**
   * Gets or sets the information about the current page.
   */
  pageInfo: PageInfo = {
    title: 'SALES.CUSTOMERS.CUSTOMER_DATA.CREATE_CUSTOMER_PAYMENT_PAGE_TITLE',
    icon: 'fa fa-plus',
  };

  /**
   * Gets or sets the selected page.
   * @default 'details'
   */
  activePage: PAGES = PAGES.details;

  /**
   * The name of the current customer.
   */
  customerName: string;

  /**
   * The list of system supported customer payment log types.
   */
  customerLogTypes$: Observable<CustomerLogType[]>;

  /**
   * The list of bank accounts.
   */
  bankAccounts: BankAccount[] = [];

  /**
   * Indicates whether there is a create payment process is running or not.
   */
  isCreating$: Observable<boolean>;

  /**
   * Shows or hide the bank accounts list.
   */
  bankAccountsListVisibility = false;

  /**
   * Gets the cash in banks account.
   */
  cashInBanksAccount: Account;

  /**
   * Gets the cash in hand account.
   */
  cashInHandAccount: Account;

  /**
   * Gets the trade debtors account.
   */
  tradeDebtorsAccount: Account;

  /**
   * Gets the checks under collection account.
   */
  checksUnderCollectionAccount: Account;

  /**
   * Gets the receivable staff account.
   */
  receivableStaffAccount: Account;

  /**
   * Gets the related  parties account.
   */
  relatedPartiesAccount: Account;

  /**
   * Gets or sets the id of the customer log type for `PAYMENT_CASH`.
   */
  customerLogTypeIdForPaymentCash: number;

  /**
   * Gets or sets the id of the customer log type for `PAYMENT_CHEQUE`.
   */
  customerLogTypeIdForPaymentCheque: number;

  /**
   * Gets or sets the id of the customer log type for `PAYMENT_BANK_TRANSFER`.
   */
  customerLogTypeIdForPaymentBankTransfer: number;

  /**
   * Gets or sets the id of the customer log type for `PAYMENT_BY_EMPLOYEE`.
   */
  customerLogTypeIdForPaymentByEmployee: number;

  /**
   * Gets or sets the id of the customer log type for `PAYMENT_BY_OWNER`.
   */
  customerLogTypeIdForPaymentByOwner: number;

  /**
   * Gets or sets the id of the customer log type for `ELECTRONIC_PAYMENT`.
   */
  customerLogTypeIdForElectronicPayment: number;

  /**
   * The create customer payments form.
   */
  form: FormGroup;

  /**
   * The id of the customer to only show his invoices.
   */
  exclusiveCustomerId: number;

  /**
   * The id of the invoice to be added to the form array automatically.
   */
  exclusiveInvoiceId: number;

  /**
   * The set of subscriptions on this components,
   * these subscriptions must be unsubscribed before this component got destroyed.
   */
  subscriptions = new Subscription();

  /**
   * Used to add new sale invoice form items to the sale invoices form.
   */
  newInvoices: CustomerPaymentFormItem[];

  /**
   * Sets the initial journal lines should be added to the journals form.
   * @param journal lines An object contains `count` of initial blank journal lines.
   * @param journal lines A single journal line details to be added to the form.
   * @param journal lines A list of journal lines details to be added to the form.
   */
  initialJournalLinesSubject: BehaviorSubject<
    { count: number } | JournalLineFormItem | JournalLineFormItem[]
  > = new BehaviorSubject(undefined);

  /**
   * Gets the attachments form-array.
   */
  get attachmentsForm(): FormArray {
    return this.form?.controls.attachments as FormArray;
  }

  /**
   * Gets the journal-lines form-array.
   */
  get journalLinesForm(): FormArray {
    return this.form?.controls.lines as FormArray;
  }

  /**
   * Gets the sale invoices form-array.
   */
  get paymentsForm(): FormArray {
    return this.form?.controls.payments as FormArray;
  }

  /**
   * Gets the sum of selected sale invoices values.
   */
  get paymentsTotal(): number {
    return Decimal.sum(0, ...this.paymentsForm?.controls.map((payment) => payment.value.value ?? 0)).toNumber();
  }

  /**
   * Gets or sets the current selected language.
   */
  get currentLang() {
    return this.translationService.language;
  }

  /**
   * @param modalService The modal service.
   * @param route The activated route.
   * @param notificationService the notification service.
   * @param translationService the translation service.
   * @param salesStore$ the sales-store module.
   * @param financesStore$ the finances-store module.
   * @param lookupsStore$ the lookups-store module.
   */
  constructor(
    private modalService: NgbModal,
    private route: ActivatedRoute,
    private notificationService: NotificationService,
    private translationService: TranslationService,
    private salesStore$: Store<fromSalesStore.SalesState>,
    private financesStore$: Store<fromFinancesStore.FinancesState>,
    private lookupsStore$: Store<fromLookupsStore.LookupsState>
  ) {}

  ngOnInit(): void {
    this.init();
  }

  /**
   * Destroy component data
   */
  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  /**
   * Initialize component data.
   */
  init() {
    this.initForm();
    this.initJournalData();

    /**
     * Reset form controls when new customer payment is created.
     */
    this.subscriptions.add(
      this.salesStore$
        .pipe(
          select(fromSalesStore.getCustomerPaymentsCreateCompleted),
          skip(1),
          tap((isCreated) => {
            if (isCreated) {
              this.initForm();
            }
          })
        )
        .subscribe()
    );

    /**
     * Fetch query params params.
     */
    if (this.route.snapshot.queryParamMap.has('customerId')) {
      this.exclusiveCustomerId = +this.route.snapshot.queryParams.customerId;

      /**
       * Select the customer name.
       */
      this.subscriptions.add(
        this.salesStore$
          .pipe(
            select(fromSalesStore.getSelectedCustomer),
            tap((customer) => {
              if (customer) {
                this.customerName = this.currentLang === 'en' ? customer.nameEn : customer.name;

                /* Update page info. */
                this.pageInfo = {
                  ...this.pageInfo,
                  subTitle: `(${this.customerName})`,
                };
              }
            })
          )
          .subscribe()
      );
    }

    if (this.route.snapshot.queryParamMap.has('invoiceId')) {
      this.exclusiveInvoiceId = +this.route.snapshot.queryParams.invoiceId;
      this.salesStore$.dispatch(new fromSalesStore.FindSaleInvoice(this.exclusiveInvoiceId));

      this.subscriptions.add(
        this.salesStore$
          .pipe(
            select(fromSalesStore.getSelectedSaleInvoice),
            tap((invoice) => {
              if (invoice) {
                this.newInvoices = [{ invoice, value: invoice.remaining }];

                /* Update page info. */
                this.pageInfo = {
                  ...this.pageInfo,
                  subTitle: 'SALES.CUSTOMERS.CUSTOMER_DATA.SALE_INVOICE_NUMBER',
                  subTitleParams: {
                    invoiceNumber: invoice.invoiceNum,
                  },
                  subTitleNeedsTranslation: true,
                };
              }
            })
          )
          .subscribe()
      );
    }

    /* Load the data. */
    this.isCreating$ = this.salesStore$.pipe(select(fromSalesStore.getCustomerPaymentsCreating));

    let isManualSearchTriggeredBeforeForCategories = false;
    this.customerLogTypes$ = this.lookupsStore$.pipe(
      select(fromLookupsStore.getCustomerPaymentLogTypes),
      tap((customerLogTypes) => {
        if (!isManualSearchTriggeredBeforeForCategories && !customerLogTypes.length) {
          isManualSearchTriggeredBeforeForCategories = true;
          this.lookupsStore$.dispatch(new fromLookupsStore.GetCustomerPaymentLogTypes());
        } else if (customerLogTypes?.length) {
          this.customerLogTypeIdForPaymentBankTransfer = customerLogTypes?.find(
            (customerLogType) => customerLogType.key === CustomerLogTypes.PAYMENT_BANK_TRANSFER
          )?.id;

          this.customerLogTypeIdForPaymentCheque = customerLogTypes?.find(
            (customerLogType) => customerLogType.key === CustomerLogTypes.PAYMENT_CHEQUE
          )?.id;

          this.customerLogTypeIdForPaymentCash = customerLogTypes?.find(
            (customerLogType) => customerLogType.key === CustomerLogTypes.PAYMENT_CASH
          )?.id;

          this.customerLogTypeIdForPaymentByEmployee = customerLogTypes?.find(
            (customerLogType) => customerLogType.key === CustomerLogTypes.PAYMENT_BY_EMPLOYEE
          )?.id;

          this.customerLogTypeIdForPaymentByOwner = customerLogTypes?.find(
            (customerLogType) => customerLogType.key === CustomerLogTypes.PAYMENT_BY_OWNER
          )?.id;
          this.customerLogTypeIdForElectronicPayment = customerLogTypes?.find(
            (customerLogType) => customerLogType.key === CustomerLogTypes.ELECTRONIC_PAYMENT
          )?.id;
        }
      })
    );
  }

  /**
   * Initialize forms and add validators.
   */
  initForm() {
    const customerLogTypeIdFormControl = new FormControl(null, Validators.required);
    const paymentsForm = new FormArray([], CustomValidators.arrayItems(1));

    this.form = new FormGroup({
      customerLogTypeId: customerLogTypeIdFormControl,
      bankAccountId: new FormControl(null),
      transactionDate: new FormControl(null),
      notes: new FormControl('', [Validators.minLength(0), Validators.maxLength(200)]),
      extraDetails: new FormControl(''),
      payments: paymentsForm,
      attachments: new FormArray([]),
      lines: new FormArray([], CustomValidators.arrayItems(2)),
    });

    this.addBlankAttachment();
    this.setBlankJournalsCount();

    /**
     * Subscription when selected customer log type and payments form changes.
     */
    this.subscriptions.add(
      customerLogTypeIdFormControl.valueChanges.subscribe(() => {
        this.onCustomerLogTypeIdOrPaymentsFormValuesChange();
        this.onCustomerLogTypeChange();
      })
    );

    this.subscriptions.add(
      paymentsForm.valueChanges.subscribe(() => {
        this.onCustomerLogTypeIdOrPaymentsFormValuesChange();
      })
    );
  }

  /**
   * Initialize the journal data.
   */
  initJournalData() {
    /**
     * Fetch system accounts then fill the journal line form with its lines.
     */

    let isManualSearchTriggeredBeforeForCashInBanksAccount = false;
    this.subscriptions.add(
      /**
       * Cash In Banks Account.
       */
      this.financesStore$
        .pipe(
          select(fromFinancesStore.getCashInBanksAccount),
          tap((account) => {
            this.cashInBanksAccount = account;
            if (account) {
              this.onCustomerLogTypeIdOrPaymentsFormValuesChange();
            } else if (!account && !isManualSearchTriggeredBeforeForCashInBanksAccount) {
              isManualSearchTriggeredBeforeForCashInBanksAccount = true;
              this.financesStore$.dispatch(new fromFinancesStore.FindCashInBanksAccount());
            }
          })
        )
        .subscribe()
    );

    let isManualSearchTriggeredBeforeForCashInHandAccount = false;
    this.subscriptions.add(
      /**
       * Cash In Hand Account.
       */
      this.financesStore$
        .pipe(
          select(fromFinancesStore.getCashInHandAccount),
          tap((account) => {
            this.cashInHandAccount = account;
            if (account) {
              this.onCustomerLogTypeIdOrPaymentsFormValuesChange();
            } else if (!account && !isManualSearchTriggeredBeforeForCashInHandAccount) {
              isManualSearchTriggeredBeforeForCashInHandAccount = true;
              this.financesStore$.dispatch(new fromFinancesStore.FindCashInHandAccount());
            }
          })
        )
        .subscribe()
    );

    let isManualSearchTriggeredBeforeForTradeDebtorsAccount = false;
    this.subscriptions.add(
      /**
       * Account trade debtors Account.
       */
      this.financesStore$
        .pipe(
          select(fromFinancesStore.getTradeDebtorsAccount),
          tap((account) => {
            this.tradeDebtorsAccount = account;
            if (account) {
              this.onCustomerLogTypeIdOrPaymentsFormValuesChange();
            } else if (!account && !isManualSearchTriggeredBeforeForTradeDebtorsAccount) {
              isManualSearchTriggeredBeforeForTradeDebtorsAccount = true;
              this.financesStore$.dispatch(new fromFinancesStore.FindTradeDebtorsAccount());
            }
          })
        )
        .subscribe()
    );

    let isManualSearchTriggeredBeforeForChecksUnderCollectionAccount = false;
    this.subscriptions.add(
      /**
       * Account checks under collection Account.
       */
      this.financesStore$
        .pipe(
          select(fromFinancesStore.getChecksUnderCollectionAccount),
          tap((account) => {
            this.checksUnderCollectionAccount = account;
            if (account) {
              this.onCustomerLogTypeIdOrPaymentsFormValuesChange();
            } else if (!account && !isManualSearchTriggeredBeforeForChecksUnderCollectionAccount) {
              isManualSearchTriggeredBeforeForChecksUnderCollectionAccount = true;
              this.financesStore$.dispatch(new fromFinancesStore.FindChecksUnderCollectionAccount());
            }
          })
        )
        .subscribe()
    );

    let isManualSearchTriggeredBeforeForReceivablesStaffAccount = false;
    this.subscriptions.add(
      /**
       * Account checks receivable staff Account.
       */
      this.financesStore$
        .pipe(
          select(fromFinancesStore.getReceivablesStaffAccount),
          tap((account) => {
            this.receivableStaffAccount = account;
            if (account) {
              this.onCustomerLogTypeIdOrPaymentsFormValuesChange();
            } else if (!account && !isManualSearchTriggeredBeforeForReceivablesStaffAccount) {
              isManualSearchTriggeredBeforeForReceivablesStaffAccount = true;
              this.financesStore$.dispatch(new fromFinancesStore.FindReceivablesStaffAccount());
            }
          })
        )
        .subscribe()
    );

    let isManualSearchTriggeredBeforeForRelatedPartiesAccount = false;
    this.subscriptions.add(
      /**
       * Account checks related parties Account.
       */
      this.financesStore$
        .pipe(
          select(fromFinancesStore.getRelatedPartiesAccount),
          tap((account) => {
            this.relatedPartiesAccount = account;
            if (account) {
              this.onCustomerLogTypeIdOrPaymentsFormValuesChange();
            } else if (!account && !isManualSearchTriggeredBeforeForRelatedPartiesAccount) {
              isManualSearchTriggeredBeforeForRelatedPartiesAccount = true;
              this.financesStore$.dispatch(new fromFinancesStore.FindRelatedPartiesAccount());
            }
          })
        )
        .subscribe()
    );
  }

  /**
   * Adds a blank attachment form-control to the attachments form for quick start..
   */
  addBlankAttachment() {
    this.attachmentsForm.push(new FormControl());
  }

  /**
   * Sets the count of the journal lines should be added to the journals form for quick start..
   */
  setBlankJournalsCount() {
    this.initialJournalLinesSubject.next({ count: 0 });
  }

  /**
   * Resets journal lines data.
   */
  resetJournalLines() {
    this.journalLinesForm.clear();
  }

  /**
   * Handles the changes on selected customer log type id and the payments form.
   */
  onCustomerLogTypeIdOrPaymentsFormValuesChange() {
    /**
     * The id of the current selected customer log type.
     */
    const customerLogTypeId = parseInt(this.form.controls.customerLogTypeId.value ?? '0');

    /**
     * Set Journal Lines based on the selected customer log type.
     */
    this.resetJournalLines();

    /**
     * The list of journal lines to be added to the form.
     */
    const lines: JournalLineFormItem[] = [];

    if (customerLogTypeId === this.customerLogTypeIdForPaymentBankTransfer && this.cashInBanksAccount) {
      /**
       * Add line for bank transfer payment.
       */
      lines.push({
        accountId: this.cashInBanksAccount.id,
        account: this.cashInBanksAccount,
        costCenterId: null,
        credit: 0,
        debit: this.paymentsTotal,
        notes: '',
        disableAccountChange: true,
        disableDebitAndCreditValueChange: true,
      });
    } else if (customerLogTypeId === this.customerLogTypeIdForPaymentCheque && this.checksUnderCollectionAccount) {
      /**
       * Add line for payment cheque.
       */
      lines.push({
        accountId: this.checksUnderCollectionAccount.id,
        account: this.checksUnderCollectionAccount,
        costCenterId: null,
        credit: 0,
        debit: this.paymentsTotal,
        notes: '',
        disableAccountChange: true,
        disableDebitAndCreditValueChange: true,
      });
    } else if (customerLogTypeId === this.customerLogTypeIdForElectronicPayment && this.cashInBanksAccount) {
      /**
       * Add line for  electronic payment.
       */
      lines.push({
        accountId: this.cashInBanksAccount.id,
        account: this.cashInBanksAccount,
        costCenterId: null,
        credit: 0,
        debit: this.paymentsTotal,
        notes: '',
        disableAccountChange: true,
        disableDebitAndCreditValueChange: true,
      });
    } else if (customerLogTypeId === this.customerLogTypeIdForPaymentByEmployee && this.receivableStaffAccount) {
      /**
       * Add line for payment by employee.
       */
      lines.push({
        accountId: this.receivableStaffAccount.id,
        account: this.receivableStaffAccount,
        costCenterId: null,
        credit: 0,
        debit: this.paymentsTotal,
        notes: '',
        disableAccountChange: true,
        disableDebitAndCreditValueChange: true,
      });
    } else if (customerLogTypeId === this.customerLogTypeIdForPaymentByOwner && this.relatedPartiesAccount) {
      /**
       * Add line for payment by owner.
       */
      lines.push({
        accountId: this.relatedPartiesAccount.id,
        account: this.relatedPartiesAccount,
        costCenterId: null,
        credit: 0,
        debit: this.paymentsTotal,
        notes: '',
        disableAccountChange: true,
        disableDebitAndCreditValueChange: true,
      });
    } else if (customerLogTypeId === this.customerLogTypeIdForPaymentCash && this.cashInHandAccount) {
      /**
       * Add line for payment cash.
       */
      lines.push({
        accountId: this.cashInHandAccount.id,
        account: this.cashInHandAccount,
        costCenterId: null,
        credit: 0,
        debit: this.paymentsTotal,
        notes: '',
        disableAccountChange: true,
        disableDebitAndCreditValueChange: true,
      });
    }

    /**
     * Add line for trade debtors account.
     */
    if (this.tradeDebtorsAccount) {
      lines.push({
        accountId: this.tradeDebtorsAccount.id,
        account: this.tradeDebtorsAccount,
        costCenterId: null,
        credit: this.paymentsTotal,
        debit: 0,
        notes: '',
        disableAccountChange: true,
        disableDebitAndCreditValueChange: true,
      });
    }

    this.initialJournalLinesSubject.next(lines);
  }

  /**
   * And Sets the conditional validation for the form based on the selected customer log type id.
   */
  onCustomerLogTypeChange() {
    /**
     * Set validation first.
     */

    /**
     * The id of the current selected customer log type.
     */
    const customerLogTypeId = parseInt(this.form.controls.customerLogTypeId.value ?? '0');

    /**
     * Check if the customer log type is payment cheque & payment by owner & payment by employee.
     */
    if (
      customerLogTypeId === this.customerLogTypeIdForPaymentBankTransfer ||
      customerLogTypeId === this.customerLogTypeIdForElectronicPayment ||
      customerLogTypeId === this.customerLogTypeIdForPaymentCash
    ) {
      this.form.controls.bankAccountId.setValidators(Validators.required);
    } else {
      this.form.controls.bankAccountId.clearValidators();
      this.form.controls.bankAccountId.setValue(null);
    }
    this.form.controls.bankAccountId.setValue(null);
    this.form.controls.bankAccountId.updateValueAndValidity();

    if (customerLogTypeId === this.customerLogTypeIdForPaymentCheque) {
      this.form.controls.extraDetails.setValidators([
        Validators.minLength(1),
        Validators.maxLength(100),
        Validators.required,
      ]);
    } else if (
      customerLogTypeId === this.customerLogTypeIdForPaymentByEmployee ||
      customerLogTypeId === this.customerLogTypeIdForPaymentByOwner
    ) {
      this.form.controls.extraDetails.setValidators([Validators.minLength(1), Validators.maxLength(100)]);
    } else {
      this.form.controls.extraDetails.clearValidators();
    }

    this.form.controls.extraDetails.setValue('');
    this.form.controls.extraDetails.updateValueAndValidity();
  }

  /**
   * Opens the modal of the given templateRef.
   * @param modalRef The modal templateRef to be opened.
   */
  openModal(modalRef) {
    this.modalService.open(modalRef);
  }

  /**
   * Open confirm reset invoice modal .
   */
  openResetModal() {
    this.openModal(this.resetModalRef);
  }

  /**
   * Resets all form data.
   *
   * It only adds one product for quick start.
   */
  resetForm() {
    this.form.reset({ customerLogTypeId: null, extraDetails: '', notes: '', transactionDate: {}, bankAccountId: null });
    this.paymentsForm.clear();
    this.attachmentsForm.clear();
    this.addBlankAttachment();
    this.resetJournalLines();
  }

  /**
   * Submits the form.
   */
  submit() {
    let errorMessage = new NotificationMessage();
    const customerLogTypeId = parseInt(this.form.controls.customerLogTypeId.value ?? '0');

    if (this.form.invalid) {
      if (this.form.get('customerLogTypeId').invalid) {
        errorMessage.title = this.translationService.translate(
          'SALES.CUSTOMERS.CUSTOMER_DATA_VALIDATION.PAYMENT_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate('SALES.CUSTOMERS.CUSTOMER_DATA_VALIDATION.PAYMENT_IS_REQUIRED'),
        ];
        this.activePage = PAGES.details;
      } else if (this.form.get('bankAccountId').invalid) {
        if (customerLogTypeId === this.customerLogTypeIdForPaymentCash) {
          errorMessage.title = this.translationService.translate(
            'SALES.CUSTOMERS.CUSTOMER_DATA_VALIDATION.CASH_ACCOUNT_ERROR'
          );
          errorMessage.body = [
            this.translationService.translate('SALES.CUSTOMERS.CUSTOMER_DATA_VALIDATION.CASH_ACCOUNT_IS_REQUIRED'),
          ];
        } else {
          errorMessage.title = this.translationService.translate(
            'SALES.CUSTOMERS.CUSTOMER_DATA_VALIDATION.BANK_ACCOUNT_ERROR'
          );
          errorMessage.body = [
            this.translationService.translate('SALES.CUSTOMERS.CUSTOMER_DATA_VALIDATION.BANK_ACCOUNT_IS_REQUIRED'),
          ];
        }
        this.activePage = PAGES.details;
      } else if (this.form.get('extraDetails').invalid) {
        errorMessage.title = this.translationService.translate(
          'SALES.CUSTOMERS.CUSTOMER_DATA_VALIDATION.EXTRA_DETAILS_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate('SALES.CUSTOMERS.CUSTOMER_DATA_VALIDATION.EXTRA_DETAILS_IS_REQUIRED'),
        ];
        this.activePage = PAGES.details;
      } else if (this.form.get('notes').invalid) {
        errorMessage.title = this.translationService.translate('SALES.CUSTOMERS.CUSTOMER_DATA_VALIDATION.NOTES_ERROR');
        errorMessage.body = [
          this.translationService.translate('SALES.CUSTOMERS.CUSTOMER_DATA_VALIDATION.NOTES_LENGTH_ERROR'),
        ];
        this.activePage = PAGES.details;
      } else if (this.form.get('payments').invalid) {
        /**
         * Check if some of payments has errors.
         */
        if (this.paymentsForm.controls.length > 0) {
          for (const paymentFormGroup of this.paymentsForm.controls) {
            if (paymentFormGroup.valid) {
              continue;
            }

            errorMessage.title = this.translationService.translate(
              'SALES.CUSTOMERS.CUSTOMER_DATA_VALIDATION.PURCHASE_INVOICE_NUMBER_ERROR',
              { purchaseInvoiceNumber: (paymentFormGroup.value as CustomerPaymentFormItem).invoice.invoiceNum }
            );
            errorMessage.body = [];

            if (paymentFormGroup.get('value').invalid) {
              errorMessage.body.push(
                this.translationService.translate('SALES.CUSTOMERS.CUSTOMER_DATA_VALIDATION.PAYMENT_VALUE_ERROR')
              );
              errorMessage.body.push(
                this.translationService.translate('SALES.CUSTOMERS.CUSTOMER_DATA_VALIDATION.PAYMENT_VALUE_IS_REQUIRED'),
                this.translationService.translate('SALES.CUSTOMERS.CUSTOMER_DATA_VALIDATION.PAYMENT_VALUE_LENGTH_ERROR')
              );
            }

            break;
          }
        } else {
          errorMessage.title = this.translationService.translate(
            'SALES.CUSTOMERS.CUSTOMER_DATA_VALIDATION.INVOICE_COUNT_ERROR'
          );
          errorMessage.body = [
            this.translationService.translate('SALES.CUSTOMERS.CUSTOMER_DATA_VALIDATION.INVOICE_COUNT_LENGTH_ERROR'),
          ];
        }

        this.activePage = PAGES.details;
      } else if (this.form.get('lines').invalid) {
        /**
         * Check if journal lines count < 2.
         */
        if (this.journalLinesForm.controls.length < 2) {
          errorMessage.title = this.translationService.translate(
            'SALES.CUSTOMERS.CUSTOMER_DATA_VALIDATION.JOURNALS_LINES_ERROR'
          );
          errorMessage.body = [
            this.translationService.translate('SALES.CUSTOMERS.CUSTOMER_DATA_VALIDATION.JOURNALS_LINES_COUNT_ERROR'),
          ];
        } else {
          /**
           * Check if some of journals has errors.
           */
          for (let index = 0; index < this.journalLinesForm.controls.length; index++) {
            const journal = this.journalLinesForm.controls[index];

            if (journal.valid) {
              continue;
            }

            errorMessage.title = this.translationService.translate(
              'SALES.CUSTOMERS.CUSTOMER_DATA_VALIDATION.JOURNAL_LINE_NUMBER_ERROR',
              {
                journalNumber: index + 1,
              }
            );
            errorMessage.body = [];

            if (journal.get('accountId').invalid) {
              errorMessage.body.push(
                this.translationService.translate('SALES.CUSTOMERS.CUSTOMER_DATA_VALIDATION.ACCOUNT_ERROR')
              );
              errorMessage.body.push(
                this.translationService.translate('SALES.CUSTOMERS.CUSTOMER_DATA_VALIDATION.ACCOUNT_IS_REQUIRED')
              );
            }

            if (journal.get('credit').invalid) {
              errorMessage.body.push(
                this.translationService.translate('SALES.CUSTOMERS.CUSTOMER_DATA_VALIDATION.CREDIT_IS_REQUIRED')
              );
            }

            if (journal.get('debit').invalid) {
              errorMessage.body.push(
                this.translationService.translate('SALES.CUSTOMERS.CUSTOMER_DATA_VALIDATION.DEBIT_IS_REQUIRED')
              );
            }

            if (journal.get('costCenterId').invalid) {
              errorMessage.body.push(
                this.translationService.translate('SALES.CUSTOMERS.CUSTOMER_DATA_VALIDATION.COST_CENTER_ERROR')
              );
              errorMessage.body.push(
                this.translationService.translate('SALES.CUSTOMERS.CUSTOMER_DATA_VALIDATION.COST_CENTER_IS_REQUIRED')
              );
            }

            if (journal.get('notes').invalid) {
              errorMessage.body.push(
                this.translationService.translate('SALES.CUSTOMERS.CUSTOMER_DATA_VALIDATION.NOTES_ERROR')
              );
              errorMessage.body.push(
                this.translationService.translate('SALES.CUSTOMERS.CUSTOMER_DATA_VALIDATION.NOTES_IS_REQUIRED')
              );
              errorMessage.body.push(
                this.translationService.translate(
                  'SALES.CUSTOMERS.CUSTOMER_DATA_VALIDATION.NOTES_REQUIRED_LENGTH_ERROR'
                )
              );
            }

            break;
          }
        }

        this.activePage = PAGES.journal;
      }

      this.form.markAllAsTouched();
      return this.notificationService.warningWithTitle(errorMessage);
    }

    errorMessage = new NotificationMessage();
    const lines: JournalLineFormItem[] = this.journalLinesForm.value;

    if (
      lines.some((line) => line.debit === 0 && line.credit === 0) ||
      lines.some((line) => line.debit !== 0 && line.credit !== 0)
    ) {
      errorMessage.title = this.translationService.translate(
        'SALES.CUSTOMERS.CUSTOMER_DATA_VALIDATION.JOURNALS_LINES_TITLE_ERROR'
      );
      errorMessage.body = [
        this.translationService.translate('SALES.CUSTOMERS.CUSTOMER_DATA_VALIDATION.DEBIT_CREDIT_ERROR'),
        this.translationService.translate('SALES.CUSTOMERS.CUSTOMER_DATA_VALIDATION.JOURNALS_LINES_TYPE_ERROR'),
      ];
      this.activePage = PAGES.journal;

      this.form.markAllAsTouched();
      return this.notificationService.warningWithTitle(errorMessage);
    } else if (
      !Decimal.sum(0, ...lines.filter((line) => !!line.debit).map((line) => line.debit)).eq(
        Decimal.sum(0, ...lines.filter((line) => !!line.credit).map((line) => line.credit))
      )
    ) {
      errorMessage.title = this.translationService.translate(
        'SALES.CUSTOMERS.CUSTOMER_DATA_VALIDATION.JOURNALS_LINES_TOTAL_ERROR'
      );
      errorMessage.body = [
        this.translationService.translate('SALES.CUSTOMERS.CUSTOMER_DATA_VALIDATION.JOURNALS_LINES_TOTAL_VALUE_ERROR'),
      ];
      this.activePage = PAGES.journal;

      this.form.markAllAsTouched();
      return this.notificationService.warningWithTitle(errorMessage);
    }

    const payments = this.paymentsForm.value as CustomerPaymentFormItem[];
    if (uniq(payments.map((payment) => payment.invoice.customerId)).length !== 1) {
      errorMessage.title = this.translationService.translate('SALES.CUSTOMERS.CUSTOMER_DATA_VALIDATION.INVOICES_ERROR');
      errorMessage.body = [
        this.translationService.translate('SALES.CUSTOMERS.CUSTOMER_DATA_VALIDATION.INVOICES_VALID_ERROR'),
      ];
      this.activePage = PAGES.details;

      this.form.markAllAsTouched();
      return this.notificationService.warningWithTitle(errorMessage);
    }

    this.openModal(this.confirmModalRef);
  }

  /**
   * Confirms the form submit.
   */
  confirm() {
    let fromDateFormatted;
    const { transactionDate } = this.form.value;

    if (transactionDate) {
      fromDateFormatted = new Date(transactionDate.year, transactionDate.month - 1, transactionDate.day);
      fromDateFormatted.setHours(new Date().getHours(), new Date().getMinutes());
    }

    /**
     * The list of payments in the payments form.
     */
    const payments = this.paymentsForm.value.map((item: CustomerPaymentFormItem) => {
      const inputPayment: CreateCustomerPaymentInput = {
        saleInvoiceId: item.invoice.id,
        value: item.value,
      };

      return inputPayment;
    });

    /**
     * The list of lines in the lines form.
     */
    const lines = this.journalLinesForm.value
      .filter((line: JournalLineFormItem) => line.credit > 0 || line.debit > 0)
      .map((line) => {
        const inputLine: CreateJournalLineInput = {
          accountId: line.accountId,
          debit: line.debit,
          credit: line.credit,
          costCenterId: line.costCenterId,
          notes: line.notes,
        };

        return inputLine;
      });

    this.salesStore$.dispatch(
      new fromSalesStore.CreateCustomerPayment({
        customerLogTypeId: this.form.value.customerLogTypeId,
        bankAccountId: this.form.value.bankAccountId,
        extraDetails: this.form.value.extraDetails,
        notes: this.form.value.notes,
        transactionDate: fromDateFormatted,
        payments,
        lines,
        attachments: this.attachmentsForm.value.filter((file) => !!file),
      })
    );
  }

  /**
   * Selects the newly selected bank account from the bank accounts search list.
   * @param bankAccounts The list of newly selected bank accounts to select the only one in the list.
   */
  selectBankAccount([bankAccount]: BankAccount[]) {
    if (bankAccount) {
      this.bankAccounts = [bankAccount];
      this.form.patchValue({ bankAccountId: bankAccount.id });
    }
  }

  /**
   * Confirms leaving the page before routing.
   */
  confirmDeactivate() {
    if (this.form.dirty === true) {
      /**
       * Message to warn the user before leaving the detailed form.
       */
      return confirm(this.translationService.translate('SHARED.CONFIRM.LEAVING'));
    }
    return true;
  }
}
