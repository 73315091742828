import { Injectable } from '@angular/core';

import { of } from 'rxjs';
import { switchMap, map, catchError, tap } from 'rxjs/operators';
import { Effect, ofType, Actions } from '@ngrx/effects';

import { NotificationService, StatusCode, PAGINATION, TranslationService } from 'shared';
import { PettyCashRefundRequestsService } from 'finances/services';
import {
  PettyCashRefundRequestsActionType,
  SearchPettyCashRefundRequests,
  SearchPettyCashRefundRequestsSuccess,
  SearchPettyCashRefundRequestsFail,
  FindPettyCashRefundRequestSuccess,
  FindPettyCashRefundRequestFail,
  FindPettyCashRefundRequest,
  CreatePettyCashRefundRequest,
  CreatePettyCashRefundRequestSuccess,
  CreatePettyCashRefundRequestFail,
  UpdatePettyCashRefundRequest,
  UpdatePettyCashRefundRequestFail,
  UpdatePettyCashRefundRequestSuccess,
} from 'finances/store/actions';

@Injectable()
export class PettyCashRefundRequestsEffects {
  constructor(
    private actions$: Actions,
    private pettyCashRefundRequestsService: PettyCashRefundRequestsService,
    private notificationService: NotificationService,
    private translationService: TranslationService
  ) {}

  /* ========================= SEARCH_PETTY_CASH_REFUND_REQUESTS =================================== */
  @Effect()
  search$ = this.actions$.pipe(
    ofType(PettyCashRefundRequestsActionType.SEARCH_PETTY_CASH_REFUND_REQUESTS),
    switchMap(({ payload }: SearchPettyCashRefundRequests) =>
      this.pettyCashRefundRequestsService
        .search(payload?.pettyCash, payload.fromDate, payload.toDate, payload.page, PAGINATION.PettyCashRefundRequests)
        .pipe(
          map((response) => new SearchPettyCashRefundRequestsSuccess(response)),
          catchError((error) => of(new SearchPettyCashRefundRequestsFail(error)))
        )
    )
  );

  /* ========================= FIND_PETTY_CASH_REFUND_REQUEST =================================== */
  @Effect()
  find$ = this.actions$.pipe(
    ofType(PettyCashRefundRequestsActionType.FIND_PETTY_CASH_REFUND_REQUEST),
    switchMap((action: FindPettyCashRefundRequest) =>
      this.pettyCashRefundRequestsService.findById(action.payload).pipe(
        map((response) => new FindPettyCashRefundRequestSuccess(response)),
        catchError((error) => of(new FindPettyCashRefundRequestFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  findFail$ = this.actions$.pipe(
    ofType(PettyCashRefundRequestsActionType.FIND_PETTY_CASH_REFUND_REQUEST_FAIL),
    tap((action: FindPettyCashRefundRequestFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate('FINANCES.PETTY_CASH_REFUND_REQUESTS.PETTY_CASH_REFUND_REQUEST_NOT_FOUND')
        );
      }
    })
  );

  /* ========================= CREATE_PETTY_CASH_REFUND_REQUEST =================================== */
  @Effect()
  create$ = this.actions$.pipe(
    ofType(PettyCashRefundRequestsActionType.CREATE_PETTY_CASH_REFUND_REQUEST),
    switchMap((action: CreatePettyCashRefundRequest) =>
      this.pettyCashRefundRequestsService.create(action.payload).pipe(
        map((response) => new CreatePettyCashRefundRequestSuccess(response)),
        catchError((error) => of(new CreatePettyCashRefundRequestFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  createSuccess$ = this.actions$.pipe(
    ofType(PettyCashRefundRequestsActionType.CREATE_PETTY_CASH_REFUND_REQUEST_SUCCESS),
    tap((action: CreatePettyCashRefundRequestSuccess) => {
      this.notificationService.success(
        this.translationService.translate('FINANCES.PETTY_CASH_REFUND_REQUESTS.PETTY_CASH_REFUND_REQUEST_ADDED', {
          pettyCashNumber: action.payload.data.id,
        })
      );
    })
  );

  @Effect({ dispatch: false })
  createFail$ = this.actions$.pipe(
    ofType(PettyCashRefundRequestsActionType.CREATE_PETTY_CASH_REFUND_REQUEST_FAIL),
    tap((action: CreatePettyCashRefundRequestFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );

  /* ========================= UPDATE_PETTY_CASH_REFUND_REQUEST =================================== */
  @Effect()
  update$ = this.actions$.pipe(
    ofType(PettyCashRefundRequestsActionType.UPDATE_PETTY_CASH_REFUND_REQUEST),
    switchMap((action: UpdatePettyCashRefundRequest) =>
      this.pettyCashRefundRequestsService.update(action.payload).pipe(
        map((response) => new UpdatePettyCashRefundRequestSuccess(response)),
        catchError((error) => of(new UpdatePettyCashRefundRequestFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  updateSuccess$ = this.actions$.pipe(
    ofType(PettyCashRefundRequestsActionType.UPDATE_PETTY_CASH_REFUND_REQUEST_SUCCESS),
    tap((action: UpdatePettyCashRefundRequestSuccess) => {
      this.notificationService.success(
        this.translationService.translate('FINANCES.PETTY_CASH_REFUND_REQUESTS.PETTY_CASH_REFUND_REQUEST_UPDATED')
      );
    })
  );

  @Effect({ dispatch: false })
  updateFail$ = this.actions$.pipe(
    ofType(PettyCashRefundRequestsActionType.UPDATE_PETTY_CASH_REFUND_REQUEST_FAIL),
    tap((action: UpdatePettyCashRefundRequestFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );
}
