import { ProbationPeriodActionType, ProbationPeriodActions } from 'lookups/store/actions';
import { ProbationPeriodState } from 'lookups/store/states';

/**
 * The initial state from which the state starts.
 */
const initialState: ProbationPeriodState = {
  data: [],
  error: null,
  isLoaded: false,
  isLoading: false,
};

/**
 * The reducer function that is called in each action dispatch against the state.
 * @param state The current state.
 * @param action The action that will affect the state.
 */
export function ProbationPeriodReducer(
  state: ProbationPeriodState = initialState,
  action: ProbationPeriodActions
): ProbationPeriodState {
  switch (action.type) {
    case ProbationPeriodActionType.GET_ALL_PROBATION_PERIODS: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case ProbationPeriodActionType.GET_ALL_PROBATION_PERIODS_FAIL: {
      return {
        ...state,
        error: action.payload,
        isLoading: false,
        isLoaded: false,
      };
    }

    case ProbationPeriodActionType.GET_ALL_PROBATION_PERIODS_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        isLoading: false,
        isLoaded: true,
      };
    }

    default:
      return state;
  }
}
