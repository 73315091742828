import { CustomersListComponent } from 'sales/components/customers/customers-list/customers-list.component';
import { CustomersSearchComponent } from 'sales/components/customers/customers-search/customers-search.component';
import { CreateCustomerComponent } from 'sales/components/customers/create-customer/create-customer.component';
import { CreateCustomerInModalComponent } from 'sales/components/customers/create-customer-in-modal/create-customer-in-modal.component';
import { CreateCustomerInPointOfSaleComponent } from 'sales/components/customers/create-customer-in-point-of-sale/create-customer-in-point-of-sale.component';
import { CustomerInfoComponent } from 'sales/components/customers/customer-info/customer-info.component';
import { CustomerInvoicesComponent } from 'sales/components/customers/customer-invoices/customer-invoices.component';
import { CustomerSummaryComponent } from 'sales/components/customers/customer-summary/customer-summary.component';
import { CreateCustomerPaymentsComponent } from 'sales/components/customers/create-customer-payments/create-customer-payments.component';
import { CreateCustomerAdvancePaymentComponent } from 'sales/components/customers/create-customer-advance-payment/create-customer-advance-payment.component';
import { CustomersPaymentsListComponent } from 'sales/components/customers/customers-payments-list/customers-payments-list.component';
import { CustomerPaymentsFormComponent } from 'sales/components/customers/customer-payments-form/customer-payments-form.component';
import { CustomerUnpaidInvoicesComponent } from 'sales/components/customers/customer-unpaid-invoices/customer-unpaid-invoices.component';
import { CreatePaymentByAdvancePaymentComponent } from 'sales/components/customers/create-payment-by-advance-payment/create-payment-by-advance-payment.component';
import { CustomerAdvancePaymentsSearchComponent } from 'sales/components/customers/customer-advance-payments-search/customer-advance-payments-search.component';
import { CreateCustomerRefundComponent } from 'sales/components/customers/create-customer-refund/create-customer-refund.component';

export * from 'sales/components/customers/customers-list/customers-list.component';
export * from 'sales/components/customers/customers-search/customers-search.component';
export * from 'sales/components/customers/create-customer/create-customer.component';
export * from 'sales/components/customers/create-customer-in-modal/create-customer-in-modal.component';
export * from 'sales/components/customers/create-customer-in-point-of-sale/create-customer-in-point-of-sale.component';
export * from 'sales/components/customers/customer-info/customer-info.component';
export * from 'sales/components/customers/customer-invoices/customer-invoices.component';
export * from 'sales/components/customers/customer-summary/customer-summary.component';
export * from 'sales/components/customers/create-customer-payments/create-customer-payments.component';
export * from 'sales/components/customers/create-customer-advance-payment/create-customer-advance-payment.component';
export * from 'sales/components/customers/customers-payments-list/customers-payments-list.component';
export * from 'sales/components/customers/customer-payments-form/customer-payments-form.component';
export * from 'sales/components/customers/customer-unpaid-invoices/customer-unpaid-invoices.component';
export * from 'sales/components/customers/create-payment-by-advance-payment/create-payment-by-advance-payment.component';
export * from 'sales/components/customers/customer-advance-payments-search/customer-advance-payments-search.component';
export * from 'sales/components/customers/create-customer-refund/create-customer-refund.component';

/**
 * The set of components registered on this sub-module.
 */
export const COMPONENTS: any[] = [
  CustomersListComponent,
  CustomersSearchComponent,
  CreateCustomerComponent,
  CreateCustomerInModalComponent,
  CreateCustomerInPointOfSaleComponent,
  CustomerInfoComponent,
  CustomerInvoicesComponent,
  CustomerSummaryComponent,
  CreateCustomerPaymentsComponent,
  CreateCustomerAdvancePaymentComponent,
  CustomersPaymentsListComponent,
  CustomerPaymentsFormComponent,
  CustomerUnpaidInvoicesComponent,
  CreatePaymentByAdvancePaymentComponent,
  CustomerAdvancePaymentsSearchComponent,
  CreateCustomerRefundComponent,
];
