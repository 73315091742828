// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { EnvironmentConfig } from './environment-config.model';

export const environment: EnvironmentConfig = {
  allowDeveloperMode: false,
  production: false,
  apiUrl: 'http://localhost:3000',
  reportingServiceApiUrl: 'http://localhost:3001',
  websocketUrl: 'ws://localhost:3002',
  accessTokenExpiration: 30,
  softwareSettings: {
    organization: {
      name: 'Organization',
      siteUrl: 'http://www.organization.com',
    },
    app: {
      name: 'Soultech ERP',
      version: '1.0.0',
      logoUrl: '/assets/img/logo.png',
      iconUrl:'/assets/img/icon.png',
      logoText: 'Soultech ERP',
      formats: {
        datetime: {
          date: 'yyyy-MM-dd',
          time: 'hh:mm a',
          datetime: 'yyyy-MM-dd hh:mm a',
        },
        currency: {
          code: 'SR',
          symbol: 'symbol',
          digitsFormat: '1.2-2',
          locale: 'ar-SA',
        },
        phone: {
          mask: '000-0000-0000||000-000-0000',
          pattern: { 0: { pattern: new RegExp('[0-9]') } },
        },
        iban: {
          mask: 'CCCC CCCC CCCC CCCC CCCC CCCC CCCC CCCC CC',
          pattern: { C: { pattern: new RegExp('[A-Z0-9]') } },
        },
        numeric: {
          decimal: {
            mask: 'separator',
            format: '1.2-20',
          },
          integer: {
            mask: 'separator.0',
            format: '1.0-0',
          },
        },
      },
    },
  },

  /**
   * The layout variants.
   * @example 'layout-light', 'layout-dark' or 'layout-transparent'.
   */
  layoutVariants: ['layout-transparent'],

  /**
   * The layout background color.
   * @example 'bg-glass-1', 'bg-glass-2', 'bg-glass-3', 'bg-glass-4', 'bg-hibiscus', 'bg-purple-pizzaz',
   * 'bg-blue-lagoon', 'bg-electric-viloet', 'bg-protage' or 'bg-tundora'.
   */
  layoutBgColor: 'bg-glass-4',

  /**
   * The sidebar background color.
   * @example 'pomegranate', 'king-yna', 'ibiza-sunset', 'flickr', 'purple-bliss', 'man-of-steel' or 'purple-love'.
   */
  sidebarBgColor: 'transparent',

  /**
   * The sidebar background image url.
   */
  sidebarBgImgUrl: null,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
