import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';

import { Observable, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { PaginationInfo } from 'shared';
import * as fromSalesStore from 'sales/store';
import * as fromAuthStore from 'auth/store';
import { PosDevice } from 'sales/models';
import { Location } from 'stores/models';
import { Claims } from 'security/models';
@Component({
  selector: 'app-pos-devices-list',
  templateUrl: './pos-devices-list.component.html',
  styles: [],
})
export class PosDevicesListComponent implements OnInit, OnDestroy {
  /**
   * The system supported user claims.
   */
  claims = Claims;

  /**
   * Indicates whether there is a delete-pos-device process is running or not.
   */
  isDeleting$: Observable<boolean>;

  /**
   * The pagination info.
   */
  paginationInfo$: Observable<PaginationInfo>;

  /**
   * Indicates whether there is a search process is running or not.
   */
  isSearching$: Observable<boolean>;

  /**
   * The list of pos devices.
   */
  posDevices$: Observable<PosDevice[]>;

  /**
   * Shows or hide the pos devices list.
   */
  locationsListVisibility = false;

  /**
   * The list of selected pos device.
   */
  locations: Location[] = [];

  /**
   * The search form.
   */
  searchForm: FormGroup;

  /**
   * The set of subscriptions on this components,
   * these subscriptions must be unsubscribed before this component got destroyed.
   */
  subscriptions = new Subscription();

  /**
   * Gets or sets the currently selected pos device for delete.
   */
  selectedPosDevice: PosDevice;

  /**
   * @param modalService The modal service.
   * @param salesStore$ The stores store.
   * @param authStore$ the auth-store module.
   */
  constructor(
    private modalService: NgbModal,
    private salesStore$: Store<fromSalesStore.SalesState>,
    private authStore$: Store<fromAuthStore.AuthState>
  ) {}
  ngOnInit(): void {
    this.init();
  }

  /**
   * Destroy component data
   */
  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  /**
   * Initialize component data.
   */
  init() {
    this.initForm();

    /* Subscribe to selected location to select it whenever it changed. */
    this.subscriptions.add(
      this.authStore$
        .pipe(
          select(fromAuthStore.getSessionUserDefaultLocation),
          tap((location) => {
            if (location && !this.searchForm.value.locations.includes(location.id)) {
              this.selectLocations([location]);
            }
          })
        )
        .subscribe()
    );

    /**
     * Load data.
     */
    this.isDeleting$ = this.salesStore$.pipe(select(fromSalesStore.getSelectedPosDeviceDeleting));
    this.isSearching$ = this.salesStore$.pipe(select(fromSalesStore.getPosDevicesSearching));

    let isManualSearchTriggeredBeforeForPosDevices = false;
    this.posDevices$ = this.salesStore$.pipe(
      select(fromSalesStore.getPosDevices),
      tap((posDevices) => {
        if (!isManualSearchTriggeredBeforeForPosDevices && !posDevices.length) {
          isManualSearchTriggeredBeforeForPosDevices = true;
          this.search();
        }
      })
    );

    this.paginationInfo$ = this.salesStore$.pipe(select(fromSalesStore.getPosDevicesPaginationInfo));
  }

  /**
   * Initialize form and add validators.
   */
  initForm() {
    this.searchForm = new FormGroup({
      name: new FormControl(''),
      locations: new FormControl([]),
      page: new FormControl(1),
    });
  }

  /**
   * Handles search parameters change.
   */
  search(event?: KeyboardEvent) {
    if (event && event.key === 'Enter') {
      event.preventDefault();
      return;
    }

    this.applyFiltersAndSearch();
  }

  /**
   * Handles pagination page-changed event.
   * @param page The current selected page number.
   */
  pageChanged(page: number) {
    /** Update pagination page. */
    this.searchForm.patchValue({ page });

    this.applyFiltersAndSearch(page);
  }

  /**
   * Enables trackBy feature for *ngFor utility to track elements
   * depending on a comparer to reduce DOM change.
   * If the element is already exist then no HTML will change, only bounded values.
   * @param element The element to determine if it was changed or not.
   */
  trackElements(element: PosDevice) {
    return element ? element.id : undefined;
  }

  applyFiltersAndSearch(page: number = 1) {
    this.salesStore$.dispatch(
      new fromSalesStore.SearchPosDevices({
        name: this.searchForm.get('name').value,
        locations: this.searchForm.get('locations').value,
        page,
      })
    );
  }

  /**
   * Deletes the pos device with the given id.
   * @param posDeviceId The id of the pos device to be deleted.
   */
  delete(posDeviceId: number) {
    this.salesStore$.dispatch(new fromSalesStore.DeletePosDevice(posDeviceId));
  }

  /**
   * Opens the modal of the given templateRef.
   * @param modalRef The modal templateRef to be opened.
   */
  openModal(modalRef) {
    this.modalService.open(modalRef);
  }

  /**
   * Adds the newly selected locations search list.
   * @param locations The list of newly selected locations to be added.
   */
  selectLocations(locations: Location[]) {
    const selectedIds: number[] = this.searchForm.get('locations').value;
    this.locations = [...this.locations.filter((location) => selectedIds.includes(location.id)), ...locations];
    this.searchForm.patchValue({
      locations: this.locations.map((location) => location.id),
    });
  }
}
