import { createSelector } from '@ngrx/store';

import { selectLookupsState } from 'lookups/store/selectors/feature.selectors';
import { LookupsState, VendorPaymentLogTypeState } from 'lookups/store/states';

/**
 * Gets the vendor payment log type state.
 */
const selectVendorPaymentLogTypes = createSelector(
  selectLookupsState,
  (state: LookupsState) => state.vendorPaymentLogTypes
);

/**
 * Gets the list of loaded vendor payment log types.
 */
export const getVendorPaymentLogTypes = createSelector(
  selectVendorPaymentLogTypes,
  (state: VendorPaymentLogTypeState) => state.data
);

/**
 * Gets the list of loaded vendor payment log types.
 */
export const getAllVendorPaymentLogTypes = createSelector(
  selectVendorPaymentLogTypes,
  (state: VendorPaymentLogTypeState) => state.allData
);

/**
 * Gets the error that occurred in the state.
 */
export const getVendorPaymentLogTypesError = createSelector(
  selectVendorPaymentLogTypes,
  (state: VendorPaymentLogTypeState) => state.error
);

/**
 * Determines if there is a running load process.
 */
export const getVendorPaymentLogTypesIsLoading = createSelector(
  selectVendorPaymentLogTypes,
  (state: VendorPaymentLogTypeState) => state.isLoading
);

/**
 * Determines if the last load process has been ended successfully.
 */
export const getVendorPaymentLogTypesIsLoaded = createSelector(
  selectVendorPaymentLogTypes,
  (state: VendorPaymentLogTypeState) => state.isLoaded
);
