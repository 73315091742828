import { Action } from '@ngrx/store';

import { AppHttpResponse } from 'shared';
import { PettyCash, CreatePettyCashInput, UpdatePettyCashInput } from 'finances/models';

/**
 * The finances-module store petty Cash action types.
 */
export enum PettyCashActionType {
  SEARCH_PETTY_CASH = '[Finances] [PettyCash] Search Petty Cash',
  SEARCH_PETTY_CASH_FAIL = '[Finances] [PettyCash] Search Petty Cash Fail',
  SEARCH_PETTY_CASH_SUCCESS = '[Finances] [PettyCash] Search Petty Cash Success',

  FIND_PETTY_CASH = '[Finances] [PettyCash] Find Petty Cash',
  FIND_PETTY_CASH_FAIL = '[Finances] [PettyCash] Find Petty Cash Fail',
  FIND_PETTY_CASH_SUCCESS = '[Finances] [PettyCash] Find Petty Cash Success',

  CREATE_PETTY_CASH = '[Finances] [PettyCash] Create Petty Cash',
  CREATE_PETTY_CASH_FAIL = '[Finances] [PettyCash] Create Petty Cash Fail',
  CREATE_PETTY_CASH_SUCCESS = '[Finances] [PettyCash] Create Petty Cash Success',

  UPDATE_PETTY_CASH = '[Finances] [PettyCash] Update Petty Cash',
  UPDATE_PETTY_CASH_FAIL = '[Finances] [PettyCash] Update Petty Cash Fail',
  UPDATE_PETTY_CASH_SUCCESS = '[Finances] [PettyCash] Update Petty Cash Success',

  BLOCK_PETTY_CASH = '[Finances] [PettyCash] Block Petty Cash',
  BLOCK_PETTY_CASH_FAIL = '[Finances] [PettyCash] Block Petty Cash Fail',
  BLOCK_PETTY_CASH_SUCCESS = '[Finances] [PettyCash] Block Petty Cash Success',

  ACTIVATE_PETTY_CASH = '[Finances] [PettyCash] Activate Petty Cash',
  ACTIVATE_PETTY_CASH_FAIL = '[Finances] [PettyCash] Activate Petty Cash Fail',
  ACTIVATE_PETTY_CASH_SUCCESS = '[Finances] [PettyCash] Activate Petty Cash Success',

  DELETE_PETTY_CASH = '[Finances] [PettyCash] Delete Petty Cash',
  DELETE_PETTY_CASH_FAIL = '[Finances] [PettyCash] Delete Petty Cash Fail',
  DELETE_PETTY_CASH_SUCCESS = '[Finances] [PettyCash] Delete Petty Cash Success',
}

/**
 * Represents a store petty Cash search action.
 */
export class SearchPettyCash implements Action {
  /**
   * The type of the action.
   */
  readonly type = PettyCashActionType.SEARCH_PETTY_CASH;

  /**
   * Fires a new petty Cash search action.
   * @param payload the search parameters,
   * if omitted, all petty Cash will be loaded.
   */
  constructor(
    public payload?: {
      description: string;
      types: number[];
      specifications: number[];
      page: number;
    }
  ) {}
}

/**
 * Represents a store petty Cash search-fail action.
 */
export class SearchPettyCashFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = PettyCashActionType.SEARCH_PETTY_CASH_FAIL;

  /**
   * Fires a new petty Cash search-fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store search-success action.
 */
export class SearchPettyCashSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = PettyCashActionType.SEARCH_PETTY_CASH_SUCCESS;

  /**
   * Fires a new petty Cash search-success action.
   * @param payload An object contains the list of petty Cash that matches the search criteria
   * in addition to the pagination info.
   */
  constructor(public payload: AppHttpResponse<PettyCash[]>) {}
}

/**
 * Represents a store find-petty-cash action.
 */
export class FindPettyCash implements Action {
  /**
   * The type of the action.
   */
  readonly type = PettyCashActionType.FIND_PETTY_CASH;

  /**
   * Fires a new Find Petty Cash action.
   * @param payload the id of the Petty Cash.
   */
  constructor(public payload: number) {}
}

/**
 * Represents a store find-petty-cash-fail action.
 */
export class FindPettyCashFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = PettyCashActionType.FIND_PETTY_CASH_FAIL;

  /**
   * Fires a new find-petty-cash-fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store Find-petty-cash-success action.
 */
export class FindPettyCashSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = PettyCashActionType.FIND_PETTY_CASH_SUCCESS;

  /**
   * Fires a new find-petty-cash-success action.
   * @param payload The loaded petty-cash.
   */
  constructor(public payload: AppHttpResponse<PettyCash>) {}
}

/**
 * Represents a store petty cash create action.
 */
export class CreatePettyCash implements Action {
  /** The type of the action. */
  readonly type = PettyCashActionType.CREATE_PETTY_CASH;

  /**
   * Fires a new petty cash create action.
   * @param payload the new petty cash data-model.
   */
  constructor(public payload: CreatePettyCashInput) {}
}

/**
 * Represents a store petty cash create-fail action.
 */
export class CreatePettyCashFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = PettyCashActionType.CREATE_PETTY_CASH_FAIL;

  /**
   * Fires a new petty cash create-fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store petty cash create-success action.
 */
export class CreatePettyCashSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = PettyCashActionType.CREATE_PETTY_CASH_SUCCESS;

  /**
   * Fires a new petty cash create-success action.
   * @param payload The created petty cash.
   */
  constructor(public payload: AppHttpResponse<PettyCash>) {}
}

/**
 * Represents a store petty cash update action.
 */
export class UpdatePettyCash implements Action {
  /**
   * The type of the action.
   */
  readonly type = PettyCashActionType.UPDATE_PETTY_CASH;

  /**
   * Fires a new petty cash update action.
   * @param payload The petty-cash's updated data.
   */
  constructor(public payload: UpdatePettyCashInput) {}
}

/**
 * Represents a store petty cash update-fail action.
 */
export class UpdatePettyCashFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = PettyCashActionType.UPDATE_PETTY_CASH_FAIL;

  /**
   * Fires a new petty cash update-fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store petty cash update-success action.
 */
export class UpdatePettyCashSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = PettyCashActionType.UPDATE_PETTY_CASH_SUCCESS;

  /**
   * Fires a new petty cash update-success action.
   * @param payload The updated petty cash.
   */
  constructor(public payload: AppHttpResponse<PettyCash>) {}
}

/**
 * Represents a store petty cash block action.
 */
export class BlockPettyCash implements Action {
  /**
   * The type of the action.
   */
  readonly type = PettyCashActionType.BLOCK_PETTY_CASH;

  /**
   * Fires a new petty cash block action.
   * @param payload The petty-cash's id.
   */
  constructor(public payload: number) {}
}

/**
 * Represents a store petty-cash block-fail action.
 */
export class BlockPettyCashFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = PettyCashActionType.BLOCK_PETTY_CASH_FAIL;

  /**
   * Fires a new petty cash block-fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store petty-cash block-success action.
 */
export class BlockPettyCashSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = PettyCashActionType.BLOCK_PETTY_CASH_SUCCESS;

  /**
   * Fires a new petty-cash block-success action.
   * @param payload The updated petty cash.
   */
  constructor(public payload: AppHttpResponse<PettyCash>) {}
}

/**
 * Represents a store petty cash activate action.
 */
export class ActivatePettyCash implements Action {
  /**
   * The type of the action.
   */
  readonly type = PettyCashActionType.ACTIVATE_PETTY_CASH;

  /**
   * Fires a new petty cash activate action.
   * @param payload The petty cash's id.
   */
  constructor(public payload: number) {}
}

/**
 * Represents a store petty cash activate-fail action.
 */
export class ActivatePettyCashFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = PettyCashActionType.ACTIVATE_PETTY_CASH_FAIL;

  /**
   * Fires a new petty cash activate-fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store petty cash activate-success action.
 */
export class ActivatePettyCashSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = PettyCashActionType.ACTIVATE_PETTY_CASH_SUCCESS;

  /**
   * Fires a new petty cash activate-success action.
   * @param payload The updated petty cash.
   */
  constructor(public payload: AppHttpResponse<PettyCash>) {}
}

/**
 * Represents a store petty cash delete action.
 */
export class DeletePettyCash implements Action {
  /**
   * The type of the action.
   */
  readonly type = PettyCashActionType.DELETE_PETTY_CASH;

  /**
   * Fires a new petty cash delete action.
   * @param payload The petty-cash's id.
   */
  constructor(public payload: number) {}
}

/**
 * Represents a store petty cash delete-fail action.
 */
export class DeletePettyCashFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = PettyCashActionType.DELETE_PETTY_CASH_FAIL;

  /**
   * Fires a new petty cash delete-fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store petty cash delete-success action.
 */
export class DeletePettyCashSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = PettyCashActionType.DELETE_PETTY_CASH_SUCCESS;

  /**
   * Fires a new petty cash delete-success action.
   * @param payload The deleted petty cash.
   */
  constructor(public payload: AppHttpResponse<PettyCash>) {}
}

/**
 * Finances-module petty Cash action types.
 */
export type PettyCashActions =
  | SearchPettyCash
  | SearchPettyCashFail
  | SearchPettyCashSuccess
  | FindPettyCash
  | FindPettyCashFail
  | FindPettyCashSuccess
  | CreatePettyCash
  | CreatePettyCashFail
  | CreatePettyCashSuccess
  | UpdatePettyCash
  | UpdatePettyCashFail
  | UpdatePettyCashSuccess
  | BlockPettyCash
  | BlockPettyCashFail
  | BlockPettyCashSuccess
  | ActivatePettyCash
  | ActivatePettyCashFail
  | ActivatePettyCashSuccess
  | DeletePettyCash
  | DeletePettyCashFail
  | DeletePettyCashSuccess;
