import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import { Observable, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import {
  PageInfo,
  NotificationService,
  NotificationMessage,
  TranslationService,
  CustomValidators,
  APP_CONSTANTS,
} from 'shared';
import * as fromFinancesStore from 'finances/store';
import { Currency } from 'finances/models';

@Component({
  selector: 'app-create-currency-exchange-rate',
  templateUrl: './create-currency-exchange-rate.component.html',
  styles: [],
})
export class CreateCurrencyExchangeRateComponent implements OnInit, OnDestroy {
  /**
   * The confirm modal template reference.
   */
  @ViewChild('confirmModalRef') confirmModalRef: ElementRef<any>;

  /**
   * The decimal mask.
   */
  readonly DECIMAL_MASK = APP_CONSTANTS.numeric.decimal.mask;

  /**
   * Gets or sets the information about the current page.
   */
  pageInfo: PageInfo = {
    title: 'FINANCES.CURRENCY_EXCHANGE_RATE.CREATE_CURRENCY_EXCHANGE_RATE_PAGE_TITLE',
    icon: 'fa fa-plus',
  };

  /**
   * Indicates whether there is a create currency process is running or not.
   */
  isCreating$: Observable<boolean>;

  /**
   * The create currency form.
   */
  form: FormGroup;

  /**
   * Shows or hide the currency list in search.
   */
  secondaryCurrencyListVisibility = false;

  /**
   * The primary currency.
   */
  primaryCurrency: Currency;

  /**
   * The list of selected currencies.
   */
  secondaryCurrencies: Currency[] = [];

  /** The initial `fromDate` value for search. */
  initialFromDate = new Date();

  /** The initial `toDate` value for search.
   * It's the current day of month.
   */
  initialToDate = new Date();

  /**
   * The set of subscriptions on this components,
   * these subscriptions must be unsubscribed before this component got destroyed.
   */
  subscriptions = new Subscription();

  /**
   * @param modalService The modal service.
   * @param notificationService The notification service.
   * @param financesStore$ The finances store.
   * @param translationService The translation service.
   */
  constructor(
    private modalService: NgbModal,
    private notificationService: NotificationService,
    private financesStore$: Store<fromFinancesStore.FinancesState>,
    private translationService: TranslationService
  ) {}

  ngOnInit(): void {
    this.init();
  }

  /**
   * Destroy component data
   */
  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  /**
   * Initialize component data.
   */
  init() {
    this.initForm();

    /**
     * Load data.
     */
    this.isCreating$ = this.financesStore$.pipe(select(fromFinancesStore.getSelectedCurrencyExchangeRateCreating));

    let isManualSearchTriggeredBeforeForPrimaryCurrency = false;
    this.subscriptions.add(
      this.financesStore$
        .pipe(
          select(fromFinancesStore.getPrimaryCurrency),
          tap((primaryCurrency) => {
            this.primaryCurrency = primaryCurrency;
            if (!isManualSearchTriggeredBeforeForPrimaryCurrency && !primaryCurrency) {
              isManualSearchTriggeredBeforeForPrimaryCurrency = true;
              this.financesStore$.dispatch(new fromFinancesStore.FindPrimaryCurrency());
            } else {
              this.form.patchValue({ primaryCurrencyId: primaryCurrency.id });
            }
          })
        )
        .subscribe()
    );
  }

  /**
   * Initialize form and add validators.
   */
  initForm() {
    /* Set the `fromDate` to the first day of the current month.  */
    this.initialFromDate.setMonth(this.initialFromDate.getMonth(), 1);

    this.form = new FormGroup({
      primaryCurrencyId: new FormControl(null),
      secondaryCurrencyId: new FormControl(null, [Validators.required]),
      rate: new FormControl('', [Validators.required, CustomValidators.gt(0)]),
      startDate: new FormControl(
        {
          year: this.initialFromDate.getFullYear(),
          month: this.initialFromDate.getMonth() + 1,
          day: this.initialFromDate.getDate(),
        },
        Validators.required
      ),
      endDate: new FormControl(
        {
          year: this.initialToDate.getFullYear(),
          month: this.initialToDate.getMonth() + 1,
          day: this.initialToDate.getDate(),
        },
        Validators.required
      ),
    });
  }

  /**
   * Submits the form.
   */
  submit() {
    if (this.form.invalid) {
      const errorMessage = new NotificationMessage();

      if (this.form.get('primaryCurrencyId').invalid) {
        errorMessage.title = this.translationService.translate(
          'FINANCES.CURRENCY_EXCHANGE_RATE.CURRENCY_EXCHANGE_RATE_DATA_VALIDATION.PRIMARY_CURRENCY_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate(
            'FINANCES.CURRENCY_EXCHANGE_RATE.CURRENCY_EXCHANGE_RATE_DATA_VALIDATION.PRIMARY_CURRENCY_IS_REQUIRED'
          ),
        ];
      } else if (this.form.get('secondaryCurrencyId').invalid) {
        errorMessage.title = this.translationService.translate(
          'FINANCES.CURRENCY_EXCHANGE_RATE.CURRENCY_EXCHANGE_RATE_DATA_VALIDATION.SECONDARY_CURRENCY_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate(
            'FINANCES.CURRENCY_EXCHANGE_RATE.CURRENCY_EXCHANGE_RATE_DATA_VALIDATION.SECONDARY_CURRENCY_IS_REQUIRED'
          ),
        ];
      } else if (this.form.get('rate').invalid) {
        errorMessage.title = this.translationService.translate(
          'FINANCES.CURRENCY_EXCHANGE_RATE.CURRENCY_EXCHANGE_RATE_DATA_VALIDATION.RATE_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate(
            'FINANCES.CURRENCY_EXCHANGE_RATE.CURRENCY_EXCHANGE_RATE_DATA_VALIDATION.RATE_IS_REQUIRED'
          ),
          this.translationService.translate(
            'FINANCES.CURRENCY_EXCHANGE_RATE.CURRENCY_EXCHANGE_RATE_DATA_VALIDATION.RATE_LENGTH_ERROR'
          ),
        ];
      } else if (this.form.get('startDate').invalid) {
        errorMessage.title = this.translationService.translate(
          'FINANCES.CURRENCY_EXCHANGE_RATE.CURRENCY_EXCHANGE_RATE_DATA_VALIDATION.START_DATE_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate(
            'FINANCES.CURRENCY_EXCHANGE_RATE.CURRENCY_EXCHANGE_RATE_DATA_VALIDATION.START_DATE_IS_REQUIRED'
          ),
        ];
      } else if (this.form.get('endDate').invalid) {
        errorMessage.title = this.translationService.translate(
          'FINANCES.CURRENCY_EXCHANGE_RATE.CURRENCY_EXCHANGE_RATE_DATA_VALIDATION.END_DATE_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate(
            'FINANCES.CURRENCY_EXCHANGE_RATE.CURRENCY_EXCHANGE_RATE_DATA_VALIDATION.END_DATE_IS_REQUIRED'
          ),
        ];
      }

      this.form.markAllAsTouched();
      return this.notificationService.warningWithTitle(errorMessage);
    } else {
      this.openModal(this.confirmModalRef);
    }
  }

  /**
   * Confirms the form submit.
   */
  confirm() {
    const { primaryCurrencyId, secondaryCurrencyId, rate, startDate, endDate } = this.form.value;

    const startDateFormatted = new Date(startDate.year, startDate.month - 1, startDate.day);
    startDateFormatted.setHours(0, 0, 0, 0);

    const endDateFormatted = new Date(endDate.year, endDate.month - 1, endDate.day);
    endDateFormatted.setHours(23, 59, 59, 0);

    this.financesStore$.dispatch(
      new fromFinancesStore.CreateCurrencyExchangeRate({
        primaryCurrencyId,
        secondaryCurrencyId,
        rate,
        startDate: startDateFormatted,
        endDate: endDateFormatted,
      })
    );
  }

  /**
   * Opens the modal of the given templateRef.
   * @param modalRef The modal templateRef to be opened.
   */
  openModal(modalRef) {
    this.modalService.open(modalRef);
  }

  /**
   * Adds the newly selected secondaryCurrency the secondaryCurrency search list.
   * @param secondaryCurrency The list of newly selected secondaryCurrency to be added.
   */
  selectSecondaryCurrency([secondaryCurrency]: Currency[]) {
    if (secondaryCurrency) {
      this.secondaryCurrencies = [secondaryCurrency];
      this.form.patchValue({ secondaryCurrencyId: secondaryCurrency.id });
    }
  }
}
