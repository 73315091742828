import { GovServiceRequestPaymentMethod } from 'lookups/models';

/**
 * Represents the gov service request payment methods state.
 */
export class GovServiceRequestPaymentMethodsState {
  /**
   * The list of gov service request payment methods.
   */
  public data: GovServiceRequestPaymentMethod[];

  /**
   * An error that may resulting during processing some actions.
   */
  public error: any;

  /**
   * Determines if there is a running load process.
   */
  public isLoading: boolean;

  /**
   * Determines if the last load process has been ended successfully.
   */
  public isLoaded: boolean;
}
