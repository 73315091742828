import { Injectable } from '@angular/core';

import { switchMap, map, catchError, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { Effect, ofType, Actions } from '@ngrx/effects';

import { NotificationService, TranslationService } from 'shared/services';
import { StatusCode } from 'shared/models';
import { PrintsService } from 'printing/services';
import {
  GetIncomingStockPrint,
  GetIncomingStockPrintFail,
  GetIncomingStockPrintReceived,
  GetIncomingStockPrintSuccess,
  GetOutgoingStockPrint,
  GetOutgoingStockPrintFail,
  GetOutgoingStockPrintReceived,
  GetOutgoingStockPrintSuccess,
  GetPurchaseInvoicePrint,
  GetPurchaseInvoicePrintFail,
  GetPurchaseInvoicePrintReceived,
  GetPurchaseInvoicePrintSuccess,
  GetPurchaseOrderPrint,
  GetPurchaseOrderPrintFail,
  GetPurchaseOrderPrintReceived,
  GetPurchaseOrderPrintSuccess,
  GetPurchaseRequestPrint,
  GetPurchaseRequestPrintFail,
  GetPurchaseRequestPrintReceived,
  GetPurchaseRequestPrintSuccess,
  GetPurchaseReturnPrint,
  GetPurchaseReturnPrintFail,
  GetPurchaseReturnPrintReceived,
  GetPurchaseReturnPrintSuccess,
  GetSaleInvoicePrint,
  GetSaleInvoicePrintFail,
  GetSaleInvoicePrintReceived,
  GetSaleInvoicePrintSuccess,
  GetSaleReturnPrint,
  GetSaleReturnPrintFail,
  GetSaleReturnPrintReceived,
  GetSaleReturnPrintSuccess,
  GetSalesQuotationPrint,
  GetSalesQuotationPrintFail,
  GetSalesQuotationPrintReceived,
  GetSalesQuotationPrintSuccess,
  GetJournalPrint,
  GetJournalPrintFail,
  GetJournalPrintReceived,
  GetJournalPrintSuccess,
  GetTransferStockPrint,
  GetTransferStockPrintFail,
  GetTransferStockPrintReceived,
  GetTransferStockPrintSuccess,
  PrintsActionType,
} from 'printing/store/actions';

@Injectable()
export class PrintsEffects {
  constructor(
    private actions$: Actions,
    private printsService: PrintsService,
    private notificationService: NotificationService,
    private translationService: TranslationService
  ) {}

  /* ========================= GET_INCOMING_STOCK_PRINT =================================== */
  @Effect()
  incomingStockPrint$ = this.actions$.pipe(
    ofType(PrintsActionType.GET_INCOMING_STOCK_PRINT),
    switchMap(({ payload }: GetIncomingStockPrint) =>
      this.printsService.getIncomingStockPrint(payload.incomingStockId, payload.paperSize).pipe(
        map((response) => new GetIncomingStockPrintReceived(response)),
        catchError((error) => of(new GetIncomingStockPrintFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  incomingStockPrintReceived$ = this.actions$.pipe(
    ofType(PrintsActionType.GET_INCOMING_STOCK_PRINT_RECEIVED),
    tap(({ payload: { data } }: GetIncomingStockPrintReceived) => {
      this.notificationService.info(data);
    })
  );

  @Effect({ dispatch: false })
  incomingStockPrintSuccess$ = this.actions$.pipe(
    ofType(PrintsActionType.GET_INCOMING_STOCK_PRINT_SUCCESS),
    tap((action: GetIncomingStockPrintSuccess) => {
      this.notificationService.success(
        this.translationService.translate('PRINTING.NOTIFICATION_MESSAGE.PRINT_IS_READY')
      );
    })
  );

  @Effect({ dispatch: false })
  incomingStockPrintFail$ = this.actions$.pipe(
    ofType(PrintsActionType.GET_INCOMING_STOCK_PRINT_FAIL),
    tap((action: GetIncomingStockPrintFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );

  /* ========================= GET_OUTGOING_STOCK_PRINT =================================== */
  @Effect()
  outgoingStockPrint$ = this.actions$.pipe(
    ofType(PrintsActionType.GET_OUTGOING_STOCK_PRINT),
    switchMap(({ payload }: GetOutgoingStockPrint) =>
      this.printsService.getOutgoingStockPrint(payload.outgoingStockId, payload.paperSize).pipe(
        map((response) => new GetOutgoingStockPrintReceived(response)),
        catchError((error) => of(new GetOutgoingStockPrintFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  outgoingStockPrintReceived$ = this.actions$.pipe(
    ofType(PrintsActionType.GET_OUTGOING_STOCK_PRINT_RECEIVED),
    tap(({ payload: { data } }: GetOutgoingStockPrintReceived) => {
      this.notificationService.info(data);
    })
  );

  @Effect({ dispatch: false })
  outgoingStockPrintSuccess$ = this.actions$.pipe(
    ofType(PrintsActionType.GET_OUTGOING_STOCK_PRINT_SUCCESS),
    tap((action: GetOutgoingStockPrintSuccess) => {
      this.notificationService.success(
        this.translationService.translate('PRINTING.NOTIFICATION_MESSAGE.PRINT_IS_READY')
      );
    })
  );

  @Effect({ dispatch: false })
  outgoingStockPrintFail$ = this.actions$.pipe(
    ofType(PrintsActionType.GET_OUTGOING_STOCK_PRINT_FAIL),
    tap((action: GetOutgoingStockPrintFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );

  /* ========================= GET_TRANSFER_STOCK_PRINT =================================== */
  @Effect()
  transferStockPrint$ = this.actions$.pipe(
    ofType(PrintsActionType.GET_TRANSFER_STOCK_PRINT),
    switchMap(({ payload }: GetTransferStockPrint) =>
      this.printsService.getTransferStockPrint(payload.transferStockId, payload.paperSize).pipe(
        map((response) => new GetTransferStockPrintReceived(response)),
        catchError((error) => of(new GetTransferStockPrintFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  transferStockPrintReceived$ = this.actions$.pipe(
    ofType(PrintsActionType.GET_TRANSFER_STOCK_PRINT_RECEIVED),
    tap(({ payload: { data } }: GetTransferStockPrintReceived) => {
      this.notificationService.info(data);
    })
  );

  @Effect({ dispatch: false })
  transferStockPrintSuccess$ = this.actions$.pipe(
    ofType(PrintsActionType.GET_TRANSFER_STOCK_PRINT_SUCCESS),
    tap((action: GetTransferStockPrintSuccess) => {
      this.notificationService.success(
        this.translationService.translate('PRINTING.NOTIFICATION_MESSAGE.PRINT_IS_READY')
      );
    })
  );

  @Effect({ dispatch: false })
  transferStockPrintFail$ = this.actions$.pipe(
    ofType(PrintsActionType.GET_TRANSFER_STOCK_PRINT_FAIL),
    tap((action: GetTransferStockPrintFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );

  /* ========================= GET_PURCHASE_REQUEST_PRINT =================================== */
  @Effect()
  purchaseRequestPrint$ = this.actions$.pipe(
    ofType(PrintsActionType.GET_PURCHASE_REQUEST_PRINT),
    switchMap(({ payload }: GetPurchaseRequestPrint) =>
      this.printsService.getPurchaseRequestPrint(payload.purchaseRequestId, payload.paperSize).pipe(
        map((response) => new GetPurchaseRequestPrintReceived(response)),
        catchError((error) => of(new GetPurchaseRequestPrintFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  purchaseRequestPrintReceived$ = this.actions$.pipe(
    ofType(PrintsActionType.GET_PURCHASE_REQUEST_PRINT_RECEIVED),
    tap(({ payload: { data } }: GetPurchaseRequestPrintReceived) => {
      this.notificationService.info(data);
    })
  );

  @Effect({ dispatch: false })
  purchaseRequestPrintSuccess$ = this.actions$.pipe(
    ofType(PrintsActionType.GET_PURCHASE_REQUEST_PRINT_SUCCESS),
    tap((action: GetPurchaseRequestPrintSuccess) => {
      this.notificationService.success(
        this.translationService.translate('PRINTING.NOTIFICATION_MESSAGE.PRINT_IS_READY')
      );
    })
  );

  @Effect({ dispatch: false })
  purchaseRequestPrintFail$ = this.actions$.pipe(
    ofType(PrintsActionType.GET_PURCHASE_REQUEST_PRINT_FAIL),
    tap((action: GetPurchaseRequestPrintFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );

  /* ========================= GET_PURCHASE_ORDER_PRINT =================================== */
  @Effect()
  purchaseOrderPrint$ = this.actions$.pipe(
    ofType(PrintsActionType.GET_PURCHASE_ORDER_PRINT),
    switchMap(({ payload }: GetPurchaseOrderPrint) =>
      this.printsService.getPurchaseOrderPrint(payload.purchaseOrderId, payload.paperSize).pipe(
        map((response) => new GetPurchaseOrderPrintReceived(response)),
        catchError((error) => of(new GetPurchaseOrderPrintFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  purchaseOrderPrintReceived$ = this.actions$.pipe(
    ofType(PrintsActionType.GET_PURCHASE_ORDER_PRINT_RECEIVED),
    tap(({ payload: { data } }: GetPurchaseOrderPrintReceived) => {
      this.notificationService.info(data);
    })
  );

  @Effect({ dispatch: false })
  purchaseOrderPrintSuccess$ = this.actions$.pipe(
    ofType(PrintsActionType.GET_PURCHASE_ORDER_PRINT_SUCCESS),
    tap((action: GetPurchaseOrderPrintSuccess) => {
      this.notificationService.success(
        this.translationService.translate('PRINTING.NOTIFICATION_MESSAGE.PRINT_IS_READY')
      );
    })
  );

  @Effect({ dispatch: false })
  purchaseOrderPrintFail$ = this.actions$.pipe(
    ofType(PrintsActionType.GET_PURCHASE_ORDER_PRINT_FAIL),
    tap((action: GetPurchaseOrderPrintFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );

  /* ========================= GET_PURCHASE_INVOICE_PRINT =================================== */
  @Effect()
  purchaseInvoicePrint$ = this.actions$.pipe(
    ofType(PrintsActionType.GET_PURCHASE_INVOICE_PRINT),
    switchMap(({ payload }: GetPurchaseInvoicePrint) =>
      this.printsService.getPurchaseInvoicePrint(payload.purchaseInvoiceId, payload.paperSize).pipe(
        map((response) => new GetPurchaseInvoicePrintReceived(response)),
        catchError((error) => of(new GetPurchaseInvoicePrintFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  purchaseInvoicePrintReceived$ = this.actions$.pipe(
    ofType(PrintsActionType.GET_PURCHASE_INVOICE_PRINT_RECEIVED),
    tap(({ payload: { data } }: GetPurchaseInvoicePrintReceived) => {
      this.notificationService.info(data);
    })
  );

  @Effect({ dispatch: false })
  purchaseInvoicePrintSuccess$ = this.actions$.pipe(
    ofType(PrintsActionType.GET_PURCHASE_INVOICE_PRINT_SUCCESS),
    tap((action: GetPurchaseInvoicePrintSuccess) => {
      this.notificationService.success(
        this.translationService.translate('PRINTING.NOTIFICATION_MESSAGE.PRINT_IS_READY')
      );
    })
  );

  @Effect({ dispatch: false })
  purchaseInvoicePrintFail$ = this.actions$.pipe(
    ofType(PrintsActionType.GET_PURCHASE_INVOICE_PRINT_FAIL),
    tap((action: GetPurchaseInvoicePrintFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );

  /* ========================= GET_PURCHASE_RETURN_PRINT =================================== */
  @Effect()
  purchaseReturnPrint$ = this.actions$.pipe(
    ofType(PrintsActionType.GET_PURCHASE_RETURN_PRINT),
    switchMap(({ payload }: GetPurchaseReturnPrint) =>
      this.printsService.getPurchaseReturnPrint(payload.purchaseReturnId, payload.paperSize).pipe(
        map((response) => new GetPurchaseReturnPrintReceived(response)),
        catchError((error) => of(new GetPurchaseReturnPrintFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  purchaseReturnPrintReceived$ = this.actions$.pipe(
    ofType(PrintsActionType.GET_PURCHASE_RETURN_PRINT_RECEIVED),
    tap(({ payload: { data } }: GetPurchaseReturnPrintReceived) => {
      this.notificationService.info(data);
    })
  );

  @Effect({ dispatch: false })
  purchaseReturnPrintSuccess$ = this.actions$.pipe(
    ofType(PrintsActionType.GET_PURCHASE_RETURN_PRINT_SUCCESS),
    tap((action: GetPurchaseReturnPrintSuccess) => {
      this.notificationService.success(
        this.translationService.translate('PRINTING.NOTIFICATION_MESSAGE.PRINT_IS_READY')
      );
    })
  );

  @Effect({ dispatch: false })
  purchaseReturnPrintFail$ = this.actions$.pipe(
    ofType(PrintsActionType.GET_PURCHASE_RETURN_PRINT_FAIL),
    tap((action: GetPurchaseReturnPrintFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );

  /* ========================= GET_SALE_INVOICE_PRINT =================================== */
  @Effect()
  saleInvoicePrint$ = this.actions$.pipe(
    ofType(PrintsActionType.GET_SALE_INVOICE_PRINT),
    switchMap(({ payload }: GetSaleInvoicePrint) =>
      this.printsService.getSaleInvoicePrint(payload.saleInvoiceId, payload.paperSize).pipe(
        map((response) => new GetSaleInvoicePrintReceived(response)),
        catchError((error) => of(new GetSaleInvoicePrintFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  saleInvoicePrintReceived$ = this.actions$.pipe(
    ofType(PrintsActionType.GET_SALE_INVOICE_PRINT_RECEIVED),
    tap(({ payload: { data } }: GetSaleInvoicePrintReceived) => {
      this.notificationService.info(data);
    })
  );

  @Effect({ dispatch: false })
  saleInvoicePrintSuccess$ = this.actions$.pipe(
    ofType(PrintsActionType.GET_SALE_INVOICE_PRINT_SUCCESS),
    tap((action: GetSaleInvoicePrintSuccess) => {
      this.notificationService.success(
        this.translationService.translate('PRINTING.NOTIFICATION_MESSAGE.PRINT_IS_READY')
      );
    })
  );

  @Effect({ dispatch: false })
  saleInvoicePrintFail$ = this.actions$.pipe(
    ofType(PrintsActionType.GET_SALE_INVOICE_PRINT_FAIL),
    tap((action: GetSaleInvoicePrintFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );

  /* ========================= GET_SALE_RETURN_PRINT =================================== */
  @Effect()
  saleReturnPrint$ = this.actions$.pipe(
    ofType(PrintsActionType.GET_SALE_RETURN_PRINT),
    switchMap(({ payload }: GetSaleReturnPrint) =>
      this.printsService.getSaleReturnPrint(payload.saleReturnId, payload.paperSize).pipe(
        map((response) => new GetSaleReturnPrintReceived(response)),
        catchError((error) => of(new GetSaleReturnPrintFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  saleReturnPrintReceived$ = this.actions$.pipe(
    ofType(PrintsActionType.GET_SALE_RETURN_PRINT_RECEIVED),
    tap(({ payload: { data } }: GetSaleReturnPrintReceived) => {
      this.notificationService.info(data);
    })
  );

  @Effect({ dispatch: false })
  saleReturnPrintSuccess$ = this.actions$.pipe(
    ofType(PrintsActionType.GET_SALE_RETURN_PRINT_SUCCESS),
    tap((action: GetSaleReturnPrintSuccess) => {
      this.notificationService.success(
        this.translationService.translate('PRINTING.NOTIFICATION_MESSAGE.PRINT_IS_READY')
      );
    })
  );

  @Effect({ dispatch: false })
  saleReturnPrintFail$ = this.actions$.pipe(
    ofType(PrintsActionType.GET_SALE_RETURN_PRINT_FAIL),
    tap((action: GetSaleReturnPrintFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );

  /* ========================= GET_SALES_QUOTATION_PRINT =================================== */
  @Effect()
  salesQuotationPrint$ = this.actions$.pipe(
    ofType(PrintsActionType.GET_SALES_QUOTATION_PRINT),
    switchMap(({ payload }: GetSalesQuotationPrint) =>
      this.printsService.getSalesQuotationPrint(payload.salesQuotationId, payload.paperSize).pipe(
        map((response) => new GetSalesQuotationPrintReceived(response)),
        catchError((error) => of(new GetSalesQuotationPrintFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  salesQuotationPrintReceived$ = this.actions$.pipe(
    ofType(PrintsActionType.GET_SALES_QUOTATION_PRINT_RECEIVED),
    tap(({ payload: { data } }: GetSalesQuotationPrintReceived) => {
      this.notificationService.info(data);
    })
  );

  @Effect({ dispatch: false })
  salesQuotationPrintSuccess$ = this.actions$.pipe(
    ofType(PrintsActionType.GET_SALES_QUOTATION_PRINT_SUCCESS),
    tap((action: GetSalesQuotationPrintSuccess) => {
      this.notificationService.success(
        this.translationService.translate('PRINTING.NOTIFICATION_MESSAGE.PRINT_IS_READY')
      );
    })
  );

  @Effect({ dispatch: false })
  salesQuotationPrintFail$ = this.actions$.pipe(
    ofType(PrintsActionType.GET_SALES_QUOTATION_PRINT_FAIL),
    tap((action: GetSalesQuotationPrintFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );

  /* ========================= GET_JOURNAL_PRINT =================================== */
  @Effect()
  journalPrint$ = this.actions$.pipe(
    ofType(PrintsActionType.GET_JOURNAL_PRINT),
    switchMap(({ payload }: GetJournalPrint) =>
      this.printsService.getJournalPrint(payload.journalId, payload.paperSize).pipe(
        map((response) => new GetJournalPrintReceived(response)),
        catchError((error) => of(new GetJournalPrintFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  journalPrintReceived$ = this.actions$.pipe(
    ofType(PrintsActionType.GET_JOURNAL_PRINT_RECEIVED),
    tap(({ payload: { data } }: GetJournalPrintReceived) => {
      this.notificationService.info(data);
    })
  );

  @Effect({ dispatch: false })
  journalPrintSuccess$ = this.actions$.pipe(
    ofType(PrintsActionType.GET_JOURNAL_PRINT_SUCCESS),
    tap((action: GetJournalPrintSuccess) => {
      this.notificationService.success(
        this.translationService.translate('PRINTING.NOTIFICATION_MESSAGE.PRINT_IS_READY')
      );
    })
  );

  @Effect({ dispatch: false })
  journalPrintFail$ = this.actions$.pipe(
    ofType(PrintsActionType.GET_JOURNAL_PRINT_FAIL),
    tap((action: GetJournalPrintFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );
}
