import { Injectable } from '@angular/core';

import { of } from 'rxjs';
import { switchMap, map, catchError, debounceTime } from 'rxjs/operators';
import { Effect, ofType, Actions } from '@ngrx/effects';

import { PAGINATION } from 'shared';
import { DepartmentsService } from 'app-search/services';
import {
  DepartmentsActionType,
  SearchDepartments,
  SearchDepartmentsFail,
  SearchDepartmentsSuccess,
} from 'app-search/store/actions';

@Injectable()
export class DepartmentsEffects {
  constructor(private actions$: Actions, private departmentsService: DepartmentsService) {}

  /* ========================= SEARCH_DEPARTMENTS =================================== */
  @Effect()
  search$ = this.actions$.pipe(
    ofType(DepartmentsActionType.SEARCH_DEPARTMENTS),
    debounceTime(300),
    switchMap(({ payload }: SearchDepartments) =>
      this.departmentsService
        .search(
          payload?.name ?? '',
          payload.departmentTypes,
          payload.departmentCategories,
          payload.page,
          PAGINATION.Departments
        )
        .pipe(
          map((response) => new SearchDepartmentsSuccess(response)),
          catchError((error) => of(new SearchDepartmentsFail(error)))
        )
    )
  );
}
