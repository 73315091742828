/**
 * The set of system supported pos printer adjustment types.
 */
export enum PosPrinterAdjustmentTypes {
  /**
   * The kitchen printer of pos printer adjustment type.
   */
  KITCHEN_PRINTER = 'KITCHEN_PRINTER',

  /**
   * The cashier printer of pos printer adjustment type.
   */
  CASHIER_PRINTER = 'CASHIER_PRINTER',

  /**
   * The order delivery printer of pos printer adjustment type.
   */
  ORDER_DELIVERY_PRINTER = 'ORDER_DELIVERY_PRINTER',
}
