import { Action } from '@ngrx/store';

import { QuotationStatus } from 'lookups/models';
import { AppHttpResponse } from 'shared';

/**
 * The Lookups-Module store quotation status action types.
 */
export enum QuotationStatusActionType {
  GET_ALL_QUOTATION_STATUSES = '[Lookups] [QuotationStatus] Get All quotation Statuses',
  GET_ALL_QUOTATION_STATUSES_FAIL = '[Lookups] [QuotationStatus] Get All quotation Statuses Fail',
  GET_ALL_QUOTATION_STATUSES_SUCCESS = '[Lookups] [QuotationStatus] Get All quotation Statuses Success',
}

/**
 * Represents a store  quotation status action.
 */
export class GetAllQuotationStatuses implements Action {
  /**
   * The type of the action.
   */
  readonly type = QuotationStatusActionType.GET_ALL_QUOTATION_STATUSES;

  /**
   * Fires a new  quotation status action.
   */
  constructor() {}
}

/**
 * Represents a store  quotation status fail action.
 */
export class GetAllQuotationStatusesFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = QuotationStatusActionType.GET_ALL_QUOTATION_STATUSES_FAIL;

  /**
   * Fires a new  quotation status fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store  quotation status success action.
 */
export class GetAllQuotationStatusesSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = QuotationStatusActionType.GET_ALL_QUOTATION_STATUSES_SUCCESS;

  /**
   * Fires a  quotation status success action.
   * @param payload An object contains the list of  quotation statuses.
   */
  constructor(public payload: AppHttpResponse<QuotationStatus[]>) {}
}

/**
 * Lookups-module  quotation status action types.
 */
export type QuotationStatusActions =
  | GetAllQuotationStatuses
  | GetAllQuotationStatusesFail
  | GetAllQuotationStatusesSuccess;
