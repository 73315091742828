import { Action } from '@ngrx/store';

import { AppHttpResponse } from 'shared';
import { GovService } from 'hr/models';

/**
 * The AppSearch-Module store gov services action types.
 */
export enum GovServicesActionType {
  SEARCH_GOV_SERVICES = '[AppSearch] [GovServices] Search Gov Services',
  SEARCH_GOV_SERVICES_FAIL = '[AppSearch] [GovServices] Search Gov Services Fail',
  SEARCH_GOV_SERVICES_SUCCESS = '[AppSearch] [GovServices] Search Gov Services Success',
}

/**
 * Represents a store gov services search action.
 */
export class SearchGovServices implements Action {
  /**
   * The type of the action.
   */
  readonly type = GovServicesActionType.SEARCH_GOV_SERVICES;

  /**
   * Fires a new services search action.
   * @param payload the search parameters,
   * if omitted, all gov services will be loaded.
   */
  constructor(public payload?: { name: string; page: number }) {}
}

/**
 * Represents a store gov services  search fail action.
 */
export class SearchGovServicesFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = GovServicesActionType.SEARCH_GOV_SERVICES_FAIL;

  /**
   * Fires a new gov services search fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store search success action.
 */
export class SearchGovServicesSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = GovServicesActionType.SEARCH_GOV_SERVICES_SUCCESS;

  /**
   * Fires a new gov services search success action.
   * @param payload An object contains the list of gov services that matches the search criteria
   * in addition to the pagination info.
   */
  constructor(public payload: AppHttpResponse<GovService[]>) {}
}

/**
 * AppSearch-Module gov services action types.
 */
export type GovServicesActions = SearchGovServices | SearchGovServicesFail | SearchGovServicesSuccess;
