import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-approval-status',
  templateUrl: './approval-status.component.html',
})
export class ApprovalStatusComponent {
  /**
   * The status flag.
   */
  @Input() status: boolean;

  /**
   * Approved status text.
   */
  @Input() approvedText: string;

  /**
   * Not-Approved status text.
   */
  @Input() notApprovedText: string;
}
