import { Component, Input } from '@angular/core';

import { Asset } from 'assets/models';

@Component({
  selector: 'app-journal-asset-details',
  templateUrl: './journal-asset-details.component.html',
  styles: [],
})
export class JournalAssetDetailsComponent {
  /**
   * Sets the current journal asset details.
   */
  @Input() asset: Asset;
}
