<!-- project  planning resources list starts -->
<div class="row">
  <div class="col-md-12">
    <div class="card-content">
      <div class="px-3">
        <div class="row">
          <div class="col-md-12">
            <form class="row">
              <div class="col-md-auto form-group d-flex px-0">
                <a class="btn btn-raised btn-success align-self-end round mx-0 mb-0" (click)="create()">
                  <i class="fa fa-plus"></i>
                  <span class="mx-1">{{ 'PROJECTS_MANAGEMENT.PROJECTS.ADD_RESOURCE' | translate }}</span>
                </a>
                <app-export-tools
                  *ngIf="projectPlanningResources?.length"
                  class="d-flex align-self-end mx-1"
                  targetElementId="resources-table-export-target-element"
                  [outputFileName]="'SHARED.EXPORT_TABLES.RESOURCES_TABLE' | translate"
                ></app-export-tools>
              </div>
            </form>
          </div>
          <div class="table-responsive">
            <table
              class="table text-center"
              *ngIf="projectPlanningResources?.length; else noItems"
              id="resources-table-export-target-element"
            >
              <thead>
                <tr>
                  <th scope="col">
                    <i class="fa fa-list-ol"></i>
                  </th>
                  <th scope="col">
                    <i class="fa fa-map-signs mr-1"></i>
                    {{ 'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.RESOURCE_TYPE' | translate }}
                  </th>
                  <th scope="col">{{ 'SHARED.TOOLS' | translate }}</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let resource of projectPlanningResources; let i = index">
                  <th scope="row">{{ i + 1 }}</th>
                  <td>
                    {{ resource.projectResourceType.name | localize: resource.projectResourceType.nameEn | async }}
                  </td>
                  <td scope="col">
                    <a
                      class="danger p-0"
                      [ngbTooltip]="'SHARED.CONFIRM.DELETE' | translate"
                      (click)="deleteResource(resource?.id)"
                    >
                      <i class="fa fa-close font-medium-3 mr-2"></i>
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- project  planning resources list -->

<!-- no items template starts -->
<ng-template #noItems>
  <div class="col-md-12 alert alert-warning mt-3 text-center" role="alert">
    {{ 'PROJECTS_MANAGEMENT.PROJECTS.RESOURCES_NOT_FOUND' | translate }}
    <button type="button" class="btn btn-raised btn-success round" (click)="create()">
      <i class="fa fa-plus"></i>
      <span class="mx-1">{{ 'PROJECTS_MANAGEMENT.PROJECTS.ADD_RESOURCE' | translate }}</span>
    </button>
  </div>
</ng-template>
<!-- no items template ends -->

<!-- project  planning resources ends -->

<!-- create project  planning resources modal starts -->
<ng-template #createModalRef let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
      {{ 'PROJECTS_MANAGEMENT.PROJECTS.ADD_RESOURCE' | translate }}
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="d()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-md-12">
        <form [formGroup]="createForm" (ngSubmit)="submit()">
          <div class="px-3">
            <div class="row">
              <div class="form-group col-md-12">
                <label for="resourceTypeId">{{
                  'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.RESOURCE_TYPE' | translate
                }}</label>
                <ng-select
                  class="round"
                  formControlName="resourceTypeId"
                  labelForId="resourceTypeId"
                  searchable="true"
                  [placeholder]="'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.RESOURCE_TYPE_PLACEHOLDER' | translate"
                  [notFoundText]="'SHARED.SEARCH.SEARCH_NOT_FOUND' | translate"
                >
                  <ng-option *ngFor="let resourceType of resourceTypes$ | async" [value]="resourceType.id">
                    {{ resourceType.name | localize: resourceType.nameEn | async }}
                  </ng-option>
                </ng-select>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="modal-actions">
      <button
        type="button"
        class="btn btn-raised btn-success round"
        [disabled]="isCreating$ | async"
        (click)="submit()"
        ngbAutofocus
      >
        <i class="fa fa-check"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.SAVE' | translate }}</span>
      </button>
      <button type="button" class="btn btn-outline-dark round" (click)="c()">
        <i class="fa fa-times"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.CANCEL' | translate }}</span>
      </button>
    </div>
  </div>
</ng-template>
<!-- create project  planning resources modal ends -->

<!-- delete confirm modal starts -->
<ng-template #deleteConfirmModalRef let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title text-danger" id="modal-basic-title">
      {{ 'PROJECTS_MANAGEMENT.PROJECTS.CONFIRM_DELETE_RESOURCE_DETAILS_TITLE' | translate }}
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="d()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p class="text-danger">{{ 'PROJECTS_MANAGEMENT.PROJECTS.CONFIRM_DELETE_RESOURCE_DETAILS_MESSAGE' | translate }}</p>
  </div>
  <div class="modal-footer">
    <div class="modal-actions">
      <button
        type="button"
        class="btn btn-raised btn-danger round"
        (click)="c(); deleteConfirm()"
        ngbAutofocus
        [disabled]="isDeleting$ | async"
      >
        <i class="fa fa-check"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.DELETE' | translate }}</span>
      </button>
      <button type="button" class="btn btn-outline-dark round" (click)="c()">
        <i class="fa fa-times"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.CANCEL' | translate }}</span>
      </button>
    </div>
  </div>
</ng-template>
<!-- delete confirm modal starts -->
