import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';

import { AppHttpResponse } from 'shared';
import { FinancialPeriod } from 'finances/models/financial-periods';

/**
 * The financial periods services includes functionality to search , open, pause and close for a financial period.
 */
@Injectable()
export class FinancialPeriodsService {
  /**
   * The relative route for the financial periods.
   *
   * No leading or trailing slashes required.
   */
  private financialPeriodsApi = 'finances/financial-periods';

  constructor(private http: HttpClient) {}

  /**
   * Searches in financial periods.
   * @param startDate The startDate of the financial period.
   * @param endDate The toDate of the financial period.
   * @param page The current pagination page number.
   * @param pageSize The maximum number of financial periods allowed per one pagination page.
   */
  public search(
    fromDate: Date,
    toDate: Date,
    page: number,
    pageSize: number
  ): Observable<AppHttpResponse<FinancialPeriod[]>> {
    const params = new HttpParams()
      .set('fromDate', fromDate.toISOString())
      .set('toDate', toDate.toISOString())
      .set('page', page.toString())
      .set('pageSize', pageSize.toString());

    return this.http.get<AppHttpResponse<FinancialPeriod[]>>(`${this.financialPeriodsApi}`, { params });
  }

  /**
   * Opens the financial period by given id.
   * @param id The id of the financial period.
   */
  public open(id: number): Observable<AppHttpResponse<FinancialPeriod>> {
    return this.http.put<AppHttpResponse<FinancialPeriod>>(`${this.financialPeriodsApi}/open/${id}`, null);
  }

  /**
   * Pauses the financial period by given id.
   * @param id The id of the financial period.
   */
  public pause(id: number): Observable<AppHttpResponse<FinancialPeriod>> {
    return this.http.put<AppHttpResponse<FinancialPeriod>>(`${this.financialPeriodsApi}/pause/${id}`, null);
  }
  /**
   * Closes the financial period by given id.
   * @param id The id of the financial period.
   */
  public close(id: number): Observable<AppHttpResponse<FinancialPeriod>> {
    return this.http.put<AppHttpResponse<FinancialPeriod>>(`${this.financialPeriodsApi}/close/${id}`, null);
  }
}
