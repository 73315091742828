import { Action } from '@ngrx/store';

import {
  Lead,
  CreateLeadInput,
  UpdateLeadInput,
  LeadNote,
  AddLeadNoteInput,
  AddLeadAttachmentsInput,
  LeadAttachment,
} from 'crm/models';
import { AppHttpResponse } from 'shared';

/**
 * The CRM-Module store leads action types.
 */
export enum LeadsActionType {
  SEARCH_LEADS = '[CRM] [Leads] Search Leads',
  SEARCH_LEADS_FAIL = '[CRM] [Leads] Search Leads Fail',
  SEARCH_LEADS_SUCCESS = '[CRM] [Leads] Search Leads Success',

  FIND_LEAD = '[CRM] [Leads] Find Lead',
  FIND_LEAD_FAIL = '[CRM] [Leads] Find Lead Fail',
  FIND_LEAD_SUCCESS = '[CRM] [Leads] Find Lead Success',

  CREATE_LEAD = '[CRM] [Leads] Create Lead',
  CREATE_LEAD_FAIL = '[CRM] [Leads] Create Lead Fail',
  CREATE_LEAD_SUCCESS = '[CRM] [Leads] Create Lead Success',

  CREATE_LEAD_NOTE = '[CRM] [Leads] Create Lead Note',
  CREATE_LEAD_NOTE_FAIL = '[CRM] [Leads] Create Lead Note Fail',
  CREATE_LEAD_NOTE_SUCCESS = '[CRM] [Leads] Create Lead Note Success',

  CREATE_LEAD_ATTACHMENTS = '[CRM] [Leads] Create Lead Attachments',
  CREATE_LEAD_ATTACHMENTS_FAIL = '[CRM] [Leads] Create Lead Attachments Fail',
  CREATE_LEAD_ATTACHMENTS_SUCCESS = '[CRM] [Leads] Create Lead Attachments Success',

  UPDATE_LEAD = '[CRM] [Leads] Update Lead',
  UPDATE_LEAD_FAIL = '[CRM] [Leads] Update Lead Fail',
  UPDATE_LEAD_SUCCESS = '[CRM] [Leads] Update Lead Success',

  DELETE_LEAD = '[CRM] [Leads] Delete Lead',
  DELETE_LEAD_FAIL = '[CRM] [Leads] Delete Lead Fail',
  DELETE_LEAD_SUCCESS = '[CRM] [Leads] Delete Lead Success',

  DELETE_LEAD_NOTE = '[CRM] [Leads] Delete Lead Note',
  DELETE_LEAD_NOTE_FAIL = '[CRM] [Leads] Delete Lead Note Fail',
  DELETE_LEAD_NOTE_SUCCESS = '[CRM] [Leads] Delete Lead Note Success',

  DELETE_LEAD_ATTACHMENT = '[CRM] [Leads] Delete Lead Attachment',
  DELETE_LEAD_ATTACHMENT_FAIL = '[CRM] [Leads] Delete Lead Attachment Fail',
  DELETE_LEAD_ATTACHMENT_SUCCESS = '[CRM] [Leads] Delete Lead Attachment Success',

  UPDATE_LEAD_STATUS_MARK_AS_NOT_HANDLED_YET = '[CRM] [Leads] Update Lead Status Mark As Not Handled Yet',
  UPDATE_LEAD_STATUS_MARK_AS_NOT_HANDLED_YET_FAIL = '[CRM] [Leads] Update Lead Status Mark As Not Handled Yet Fail',
  UPDATE_LEAD_STATUS_MARK_AS_NOT_HANDLED_YET_SUCCESS = '[CRM] [Leads] Update Lead Status Mark As Not Handled Yet Success',

  UPDATE_LEAD_STATUS_MARK_AS_LOST = '[CRM] [Leads] Update Lead Status Mark As Lost',
  UPDATE_LEAD_STATUS_MARK_AS_LOST_FAIL = '[CRM] [Leads] Update Lead Status Mark As Lost Fail',
  UPDATE_LEAD_STATUS_MARK_AS_LOST_SUCCESS = '[CRM] [Leads] Update Lead Status Mark As Lost Success',

  UPDATE_LEAD_STATUS_MARK_AS_OPPORTUNITY = '[CRM] [Leads] Update Lead Status Mark As Opportunity',
  UPDATE_LEAD_STATUS_MARK_AS_OPPORTUNITY_FAIL = '[CRM] [Leads] Update Lead Status Mark As Opportunity Fail',
  UPDATE_LEAD_STATUS_MARK_AS_OPPORTUNITY_SUCCESS = '[CRM] [Leads] Update Lead Status Mark As Opportunity Success',
}

/**
 * Represents a store leads search action.
 */
export class SearchLeads implements Action {
  /**
   * The type of the action.
   */
  readonly type = LeadsActionType.SEARCH_LEADS;

  /**
   * Fires a new leads search action.
   * @param payload the search parameters,
   * if omitted, all leads will be loaded.
   */
  constructor(public payload?: { name: string; page: number }) {}
}

/**
 * Represents a store leads search-fail action.
 */
export class SearchLeadsFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = LeadsActionType.SEARCH_LEADS_FAIL;

  /**
   * Fires a new leads search-fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store search-success action.
 */
export class SearchLeadsSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = LeadsActionType.SEARCH_LEADS_SUCCESS;

  /**
   * Fires a new leads search-success action.
   * @param payload An object contains the list of leads that matches the search criteria
   * in addition to the pagination info.
   */
  constructor(public payload: AppHttpResponse<Lead[]>) {}
}

/**
 * Represents a store find-lead action.
 */
export class FindLead implements Action {
  /**
   * The type of the action.
   */
  readonly type = LeadsActionType.FIND_LEAD;

  /**
   * Fires a new find lead action.
   * @param payload the id of the lead.
   */
  constructor(public payload: number) {}
}

/**
 * Represents a store find-lead-fail action.
 */
export class FindLeadFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = LeadsActionType.FIND_LEAD_FAIL;

  /**
   * Fires a new find-lead-fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store find lead-success action.
 */
export class FindLeadSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = LeadsActionType.FIND_LEAD_SUCCESS;

  /**
   * Fires a new find-lead-success action.
   * @param payload The loaded lead.
   */
  constructor(public payload: AppHttpResponse<Lead>) {}
}

/**
 * Represents a store lead create action.
 */
export class CreateLead implements Action {
  /** The type of the action. */
  readonly type = LeadsActionType.CREATE_LEAD;

  /**
   * Fires a new lead create action.
   * @param payload the new lead data-model.
   */
  constructor(public payload: CreateLeadInput) {}
}

/**
 * Represents a store lead create-fail action.
 */
export class CreateLeadFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = LeadsActionType.CREATE_LEAD_FAIL;

  /**
   * Fires a new lead create-fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store lead create-success action.
 */
export class CreateLeadSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = LeadsActionType.CREATE_LEAD_SUCCESS;

  /**
   * Fires a new lead create-success action.
   * @param payload The created lead.
   */
  constructor(public payload: AppHttpResponse<Lead>) {}
}

/**
 * Represents a store lead note create action.
 */
export class CreateLeadNote implements Action {
  /** The type of the action. */
  readonly type = LeadsActionType.CREATE_LEAD_NOTE;

  /**
   * Fires a new lead note create action.
   * @param payload the new lead note data-model.
   */
  constructor(public payload: AddLeadNoteInput) {}
}

/**
 * Represents a store lead note create-fail action.
 */
export class CreateLeadNoteFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = LeadsActionType.CREATE_LEAD_NOTE_FAIL;

  /**
   * Fires a new lead note create-fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store lead note create-success action.
 */
export class CreateLeadNoteSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = LeadsActionType.CREATE_LEAD_NOTE_SUCCESS;

  /**
   * Fires a new lead note create-success action.
   * @param payload The created lead.
   */
  constructor(public payload: AppHttpResponse<LeadNote>) {}
}

/**
 * Represents a store lead attachments create action.
 */
export class CreateLeadAttachments implements Action {
  /** The type of the action. */
  readonly type = LeadsActionType.CREATE_LEAD_ATTACHMENTS;

  /**
   * Fires a new lead attachments create action.
   * @param payload the new lead Attachments data-model.
   */
  constructor(public payload: AddLeadAttachmentsInput) {}
}

/**
 * Represents a store lead attachments create-fail action.
 */
export class CreateLeadAttachmentsFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = LeadsActionType.CREATE_LEAD_ATTACHMENTS_FAIL;

  /**
   * Fires a new lead attachments create-fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store lead attachments create-success action.
 */
export class CreateLeadAttachmentsSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = LeadsActionType.CREATE_LEAD_ATTACHMENTS_SUCCESS;

  /**
   * Fires a new lead attachments create-success action.
   * @param payload The created lead.
   */
  constructor(public payload: AppHttpResponse<LeadAttachment[]>) {}
}

/**
 * Represents a store lead update action.
 */
export class UpdateLead implements Action {
  /**
   * The type of the action.
   */
  readonly type = LeadsActionType.UPDATE_LEAD;

  /**
   * Fires a new lead update action.
   * @param payload The lead's updated data.
   */
  constructor(public payload: UpdateLeadInput) {}
}

/**
 * Represents a store lead update-fail action.
 */
export class UpdateLeadFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = LeadsActionType.UPDATE_LEAD_FAIL;

  /**
   * Fires a new lead update-fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store lead update-success action.
 */
export class UpdateLeadSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = LeadsActionType.UPDATE_LEAD_SUCCESS;

  /**
   * Fires a new lead update-success action.
   * @param payload The updated lead.
   */
  constructor(public payload: AppHttpResponse<Lead>) {}
}

/**
 * Represents a store lead delete action.
 */
export class DeleteLead implements Action {
  /**
   * The type of the action.
   */
  readonly type = LeadsActionType.DELETE_LEAD;

  /**
   * Fires a new lead delete action.
   * @param payload The lead's id.
   */
  constructor(public payload: number) {}
}

/**
 * Represents a store lead delete-fail action.
 */
export class DeleteLeadFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = LeadsActionType.DELETE_LEAD_FAIL;

  /**
   * Fires a new lead delete-fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store lead delete-success action.
 */
export class DeleteLeadSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = LeadsActionType.DELETE_LEAD_SUCCESS;

  /**
   * Fires a new lead delete-success action.
   * @param payload The deleted lead.
   */
  constructor(public payload: AppHttpResponse<Lead>) {}
}

/**
 * Represents a store lead note delete action.
 */
export class DeleteLeadNote implements Action {
  /**
   * The type of the action.
   */
  readonly type = LeadsActionType.DELETE_LEAD_NOTE;

  /**
   * Fires a new lead note delete action.
   * @param payload The lead note's id.
   */
  constructor(public payload: number) {}
}

/**
 * Represents a store lead note delete-fail action.
 */
export class DeleteLeadNoteFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = LeadsActionType.DELETE_LEAD_NOTE_FAIL;

  /**
   * Fires a new lead note delete-fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store lead note delete-success action.
 */
export class DeleteLeadNoteSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = LeadsActionType.DELETE_LEAD_NOTE_SUCCESS;

  /**
   * Fires a new lead note delete-success action.
   * @param payload The deleted lead note.
   */
  constructor(public payload: AppHttpResponse<LeadNote>) {}
}

/**
 * Represents a store lead attachment delete action.
 */
export class DeleteLeadAttachment implements Action {
  /**
   * The type of the action.
   */
  readonly type = LeadsActionType.DELETE_LEAD_ATTACHMENT;

  /**
   * Fires a new lead attachment note delete action.
   * @param payload The lead attachment's id.
   */
  constructor(public payload: number) {}
}

/**
 * Represents a store lead attachment delete-fail action.
 */
export class DeleteLeadAttachmentFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = LeadsActionType.DELETE_LEAD_ATTACHMENT_FAIL;

  /**
   * Fires a new lead attachment delete-fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store lead attachment delete-success action.
 */
export class DeleteLeadAttachmentSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = LeadsActionType.DELETE_LEAD_ATTACHMENT_SUCCESS;

  /**
   * Fires a new lead attachment attachment delete-success action.
   * @param payload The deleted lead attachment.
   */
  constructor(public payload: AppHttpResponse<LeadAttachment>) {}
}

/**
 * Represents a store lead status mark as not handled yet update action.
 */
export class UpdateLeadStatusMarkAsNotHandledYet implements Action {
  /**
   * The type of the action.
   */
  readonly type = LeadsActionType.UPDATE_LEAD_STATUS_MARK_AS_NOT_HANDLED_YET;

  /**
   * Fires a new lead status mark as not handled yet update action.
   * @param payload The lead status mark as not handled yet updated data.
   */
  constructor(public payload: number) {}
}

/**
 * Represents a store lead status mark as not handled yet update-fail action.
 */
export class UpdateLeadStatusMarkAsNotHandledYetFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = LeadsActionType.UPDATE_LEAD_STATUS_MARK_AS_NOT_HANDLED_YET_FAIL;

  /**
   * Fires a new lead status mark as not handled yet update-fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store lead status mark as not handled yet update-success action.
 */
export class UpdateLeadStatusMarkAsNotHandledYetSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = LeadsActionType.UPDATE_LEAD_STATUS_MARK_AS_NOT_HANDLED_YET_SUCCESS;

  /**
   * Fires a new lead status mark as not handled yet update-success action.
   * @param payload The updated lead status mark as not handled yet.
   */
  constructor(public payload: AppHttpResponse<Lead>) {}
}

/**
 * Represents a store lead status mark as lost update action.
 */
export class UpdateLeadStatusMarkAsLost implements Action {
  /**
   * The type of the action.
   */
  readonly type = LeadsActionType.UPDATE_LEAD_STATUS_MARK_AS_LOST;

  /**
   * Fires a new lead status mark as lost update action.
   * @param payload The lead status mark as lost updated data.
   */
  constructor(public payload: number) {}
}

/**
 * Represents a store lead status mark as lost update-fail action.
 */
export class UpdateLeadStatusMarkAsLostFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = LeadsActionType.UPDATE_LEAD_STATUS_MARK_AS_LOST_FAIL;

  /**
   * Fires a new lead status mark as lost update-fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store lead status mark as lost update-success action.
 */
export class UpdateLeadStatusMarkAsLostSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = LeadsActionType.UPDATE_LEAD_STATUS_MARK_AS_LOST_SUCCESS;

  /**
   * Fires a new lead status mark as lost update-success action.
   * @param payload The updated lead status mark as lost.
   */
  constructor(public payload: AppHttpResponse<Lead>) {}
}

/**
 * Represents a store lead status mark as opportunity update action.
 */
export class UpdateLeadStatusMarkAsOpportunity implements Action {
  /**
   * The type of the action.
   */
  readonly type = LeadsActionType.UPDATE_LEAD_STATUS_MARK_AS_OPPORTUNITY;

  /**
   * Fires a new lead status mark as opportunity update action.
   * @param payload The lead status mark as opportunity updated data.
   */
  constructor(public payload: number) {}
}

/**
 * Represents a store lead status mark as opportunity update-fail action.
 */
export class UpdateLeadStatusMarkAsOpportunityFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = LeadsActionType.UPDATE_LEAD_STATUS_MARK_AS_OPPORTUNITY_FAIL;

  /**
   * Fires a new lead status mark as opportunity update-fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store lead status mark as opportunity update-success action.
 */
export class UpdateLeadStatusMarkAsOpportunitySuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = LeadsActionType.UPDATE_LEAD_STATUS_MARK_AS_OPPORTUNITY_SUCCESS;

  /**
   * Fires a new lead status mark as opportunity update-success action.
   * @param payload The updated lead status mark as opportunity.
   */
  constructor(public payload: AppHttpResponse<Lead>) {}
}

/**
 * CRM-module leads action types.
 */
export type LeadsActions =
  | SearchLeads
  | SearchLeadsFail
  | SearchLeadsSuccess
  | FindLead
  | FindLeadFail
  | FindLeadSuccess
  | CreateLead
  | CreateLeadFail
  | CreateLeadSuccess
  | CreateLeadNote
  | CreateLeadNoteFail
  | CreateLeadNoteSuccess
  | CreateLeadAttachments
  | CreateLeadAttachmentsFail
  | CreateLeadAttachmentsSuccess
  | UpdateLead
  | UpdateLeadFail
  | UpdateLeadSuccess
  | DeleteLead
  | DeleteLeadFail
  | DeleteLeadSuccess
  | DeleteLeadNote
  | DeleteLeadNoteFail
  | DeleteLeadNoteSuccess
  | DeleteLeadAttachment
  | DeleteLeadAttachmentFail
  | DeleteLeadAttachmentSuccess
  | UpdateLeadStatusMarkAsNotHandledYet
  | UpdateLeadStatusMarkAsNotHandledYetFail
  | UpdateLeadStatusMarkAsNotHandledYetSuccess
  | UpdateLeadStatusMarkAsLost
  | UpdateLeadStatusMarkAsLostFail
  | UpdateLeadStatusMarkAsLostSuccess
  | UpdateLeadStatusMarkAsOpportunity
  | UpdateLeadStatusMarkAsOpportunityFail
  | UpdateLeadStatusMarkAsOpportunitySuccess;
