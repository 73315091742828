import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChartsModule } from 'ng2-charts';

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { SharedModule } from 'shared';
import { StoresModule } from 'stores';

import * as fromServices from 'statistics/services';
import * as fromStore from 'statistics/store';
import * as fromComponents from 'statistics/components';

@NgModule({
  declarations: [...fromComponents.COMPONENTS],

  imports: [
    CommonModule,
    SharedModule,
    ChartsModule,
    StoresModule,
    StoreModule.forFeature('statistics', fromStore.reducers),
    EffectsModule.forFeature(fromStore.effects),
  ],
  exports: [fromComponents.StatisticsComponent, fromComponents.SystemStatisticsComponent],
  providers: [...fromServices.SERVICES],
})
export class StatisticsModule {}
