<!-- project plan pages start -->
<div class="card">
  <div class="card-content">
    <div class="px-3 pt-3">
      <ul
        class="nav-tabs"
        ngbNav
        #nav="ngbNav"
        [(activeId)]="activePage"
        (activeIdChange)="selectedPageChanged($event)"
        [destroyOnHide]="false"
      >
        <li ngbNavItem="plan">
          <a ngbNavLink>
            <i class="fa fa-id-badge" [class.success]="activePage == 'plan'"></i>
            {{ 'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.PLAN_TITLE' | translate }}
          </a>
          <ng-template ngbNavContent>
            <app-project-plans-list [projectId]="projectId"></app-project-plans-list>
          </ng-template>
        </li>
        <li ngbNavItem="project-log">
          <a ngbNavLink>
            <i class="fa fa-building-o" [class.success]="activePage == 'project-log'"></i>
            {{ 'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.CHANGE_LOGS' | translate }}
          </a>
          <ng-template ngbNavContent>
            <app-project-logs [projectId]="projectId"></app-project-logs>
          </ng-template>
        </li>
      </ul>
      <div [ngbNavOutlet]="nav" class="mt-2"></div>
    </div>
  </div>
</div>
<!--project plan end -->
