import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import { Observable, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { PageInfo, NotificationService, NotificationMessage, TranslationService } from 'shared';
import * as fromSalesStore from 'sales/store';
import { Board } from 'sales/models';
import { Location } from 'stores/models';

@Component({
  selector: 'app-update-board',
  templateUrl: './update-board.component.html',
  styles: [],
})
export class UpdateBoardComponent implements OnInit, OnDestroy {
  /**
   * The confirm modal template reference.
   */
  @ViewChild('confirmModalRef') confirmModalRef: ElementRef<any>;

  /**
   * Gets or sets the information about the current page.
   */
  pageInfo: PageInfo = {
    title: 'SALES.BOARDS.UPDATE_BOARD_TITLE',
    icon: 'fa fa-edit',
  };

  /**
   * Gets or sets the id of the current edited board.
   */
  boardId: number;

  /**
   * The current edited board.
   */
  board$: Observable<Board>;

  /**
   * The list of active locations.
   */
  locations: Location[] = [];

  /**
   * Shows or hide the locations list.
   */
  locationsListVisibility = false;

  /**
   * Indicates whether there is a update cost center process is running or not.
   */
  isUpdating$: Observable<boolean>;

  /**
   * The update petty cash form.
   */
  form: FormGroup;

  /**
   * The set of subscriptions on this components,
   * these subscriptions must be unsubscribed before this component got destroyed.
   */
  subscriptions = new Subscription();

  /**
   * @param route The activated route.
   * @param modalService The modal service.
   * @param notificationService The notification service.
   * @param salesStore$ The sales store.
   * @param translationService The translation service.
   */
  constructor(
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private notificationService: NotificationService,
    private salesStore$: Store<fromSalesStore.SalesState>,
    private translationService: TranslationService
  ) {}

  ngOnInit(): void {
    this.init();
  }

  /**
   * Destroy component data
   */
  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  /**
   * Initialize component data.
   */
  init() {
    this.initForm();

    /** Subscribe to route to get the element id whenever it changed. */
    const routeSubscription = this.route.paramMap
      .pipe(
        tap((params) => {
          this.boardId = +params.get('boardId');
          this.salesStore$.dispatch(new fromSalesStore.FindBoard(this.boardId));
        })
      )
      .subscribe();
    this.subscriptions.add(routeSubscription);

    /* Load the selected board. */
    this.board$ = this.salesStore$.pipe(
      select(fromSalesStore.getSelectedBoard),
      tap((board) => {
        if (board) {
          this.pageInfo = {
            ...this.pageInfo,
            subTitle: `(${board.description})`,
          };
          this.locations = [board.location];
          this.form.patchValue({ ...board });
          this.form.markAsPristine();
        }
      })
    );

    /**
     * Load data.
     */
    this.isUpdating$ = this.salesStore$.pipe(select(fromSalesStore.getSelectedBoardUpdating));
  }

  /**
   * Initialize form and add validators.
   */
  initForm() {
    this.form = new FormGroup({
      description: new FormControl('', [Validators.required, Validators.minLength(1), Validators.maxLength(100)]),
      locationId: new FormControl(null, [Validators.required]),
    });
  }

  /**
   * Submits the form.
   */
  submit() {
    if (this.form.invalid) {
      const errorMessage = new NotificationMessage();

      if (this.form.get('description').invalid) {
        errorMessage.title = this.translationService.translate('SALES.BOARDS.BOARD_DATA_VALIDATION.DESCRIPTION_ERROR');
        errorMessage.body = [
          this.translationService.translate('SALES.BOARDS.BOARD_DATA_VALIDATION.DESCRIPTION_IS_REQUIRED'),
          this.translationService.translate('SALES.BOARDS.BOARD_DATA_VALIDATION.DESCRIPTION_LENGTH_ERROR'),
        ];
      } else if (this.form.get('locationId').invalid) {
        errorMessage.title = this.translationService.translate('SALES.BOARDS.BOARD_DATA_VALIDATION.LOCATION_ERROR');
        errorMessage.body = [
          this.translationService.translate('SALES.BOARDS.BOARD_DATA_VALIDATION.LOCATION_IS_REQUIRED'),
        ];
      }

      this.form.markAllAsTouched();
      return this.notificationService.warningWithTitle(errorMessage);
    } else {
      this.openModal(this.confirmModalRef);
    }
  }

  /**
   * Confirms the form submit.
   */
  confirm() {
    this.salesStore$.dispatch(
      new fromSalesStore.UpdateBoard({
        id: this.boardId,
        ...this.form.value,
      })
    );
  }

  /**
   * Selects the newly selected location from the locations search list.
   * @param locations The list of newly selected locations to select the only one in the list.
   */
  selectLocation([location]: Location[]) {
    if (location) {
      this.locations = [location];
      this.form.patchValue({ locationId: location.id });
    }
  }

  /**
   * Opens the modal of the given templateRef.
   * @param modalRef The modal templateRef to be opened.
   */
  openModal(modalRef) {
    this.modalService.open(modalRef);
  }
}
