import { Injectable } from '@angular/core';

import { of } from 'rxjs';
import { switchMap, map, catchError } from 'rxjs/operators';
import { Effect, ofType, Actions } from '@ngrx/effects';

import { LookupsService } from 'lookups/services';
import {
  OtherEarningTypeActionType,
  GetAllOtherEarningTypesFail,
  GetAllOtherEarningTypesSuccess,
} from 'lookups/store/actions';

@Injectable()
export class OtherEarningTypeEffects {
  constructor(private actions$: Actions, private lookupsService: LookupsService) {}

  /* ========================= GET_ALL_OTHER_EARNING_TYPE =================================== */
  @Effect()
  getAllOtherEarningTypes$ = this.actions$.pipe(
    ofType(OtherEarningTypeActionType.GET_ALL_OTHER_EARNING_TYPES),
    switchMap(() =>
      this.lookupsService.getAllOtherEarningTypes().pipe(
        map((response) => new GetAllOtherEarningTypesSuccess(response)),
        catchError((error) => of(new GetAllOtherEarningTypesFail(error)))
      )
    )
  );
}
