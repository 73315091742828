<!-- page header starts -->
<app-page-header [info]="pageInfo"></app-page-header>
<!-- page header ends -->

<!-- organization starts -->

<div class="card">
  <div class="card-content">
    <div class="px-3 pt-3">
      <form [formGroup]="form" (ngSubmit)="submit()" *ngIf="organizationSettings$ | async; let organizationSetting">
        <div class="row">
          <div class="col-md-12">
            <!-- update report setting form starts -->
            <div class="row">
              <div class="col-md-12">
                <!-- organization details starts -->
                <div class="card mb-1">
                  <div class="card-header mb-3 pt-0">
                    <h4 class="card-title">
                      <i class="fa fa-info-circle"></i>
                      <span class="mx-1">{{ 'SETTINGS.ORGANIZATION_SETTINGS.ORGANIZATION_TITLE' | translate }}</span>
                    </h4>
                  </div>
                  <div class="card-content">
                    <div class="px-3">
                      <div class="row">
                        <div class="form-group col-md-12">
                          <label for="reportDisplayName">{{
                            'SETTINGS.ORGANIZATION_SETTINGS.ORGANIZATION_DATA.REPORT_ORGANIZATION_NAME' | translate
                          }}</label>
                          <textarea
                            id="reportDisplayName"
                            class="form-control round"
                            formControlName="reportDisplayName"
                            [placeholder]="
                              'SETTINGS.ORGANIZATION_SETTINGS.ORGANIZATION_DATA.REPORT_ORGANIZATION_NAME_PLACEHOLDER'
                                | translate
                            "
                          ></textarea>
                        </div>
                        <div class="form-group col-md-12">
                          <label for="timeZoneId">{{
                            'SETTINGS.ORGANIZATION_SETTINGS.ORGANIZATION_DATA.TIME_ZONE' | translate
                          }}</label>
                          <ng-select
                            class="round"
                            formControlName="timeZoneId"
                            labelForId="timeZoneId"
                            searchable="true"
                            [closeOnSelect]="true"
                            [placeholder]="
                              'SETTINGS.ORGANIZATION_SETTINGS.ORGANIZATION_DATA.TIME_ZONE_PLACEHOLDER' | translate
                            "
                            [notFoundText]="'SHARED.SEARCH.SEARCH_NOT_FOUND' | translate"
                          >
                            <ng-option *ngFor="let timeZone of timeZones$ | async" [value]="timeZone.id">
                              {{ timeZone.name | localize: timeZone.nameEn | async }}
                            </ng-option>
                          </ng-select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card">
                  <div class="card-header mb-3 pt-0">
                    <h4 class="card-title">
                      <i class="fa fa-cube"></i>
                      <span class="mx-1">{{
                        'SETTINGS.ORGANIZATION_SETTINGS.ORGANIZATION_BRANCHES_SETTINGS' | translate
                      }}</span>
                    </h4>
                  </div>
                  <div class="card-content">
                    <div class="px-3">
                      <div class="row">
                        <div class="form-group col-md-6">
                          <label for="workDayStartsAt">{{
                            'SETTINGS.ORGANIZATION_SETTINGS.ORGANIZATION_DATA.WORK_DAY_STARTS_AT' | translate
                          }}</label>
                          <div class="input-group round">
                            <ngb-timepicker [meridian]="true" formControlName="workDayStartsAt"></ngb-timepicker>
                          </div>
                        </div>
                        <div class="form-group col-md-6">
                          <label for="workDayEndsAt">{{
                            'SETTINGS.ORGANIZATION_SETTINGS.ORGANIZATION_DATA.WORK_DAY_ENDS_AT' | translate
                          }}</label>
                          <div class="input-group round">
                            <ngb-timepicker [meridian]="true" formControlName="workDayEndsAt"></ngb-timepicker>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card">
                  <div class="card-header mb-3 pt-0">
                    <h4 class="card-title">
                      <i class="fa fa-line-chart"></i>
                      <span class="mx-1">{{
                        'SETTINGS.ORGANIZATION_SETTINGS.POINTS_OF_SALE_SETTINGS' | translate
                      }}</span>
                    </h4>
                  </div>
                  <div class="card-content">
                    <div class="px-3">
                      <div class="row">
                        <div class="form-group col-md-12">
                          <input type="checkbox" formControlName="isPointsProgramActivated" />
                          <span class="ml-2 mt-0 pt-0">{{
                            'SETTINGS.ORGANIZATION_SETTINGS.ORGANIZATION_DATA.IS_POINTS_PROGRAM_ACTIVATED' | translate
                          }}</span>
                        </div>
                        <ng-container *ngIf="form.value.isPointsProgramActivated">
                          <div class="form-group col-md-6">
                            <label for="pointsProgramGainRequiredValue">{{
                              'SETTINGS.ORGANIZATION_SETTINGS.ORGANIZATION_DATA.POINTS_PROGRAM_GAIN_REQUIRED_VALUE'
                                | translate
                            }}</label>
                            <input
                              type="text"
                              [mask]="DECIMAL_MASK"
                              thousandSeparator=","
                              minLength="0"
                              id="pointsProgramGainRequiredValue"
                              class="form-control round"
                              [placeholder]="
                                'SETTINGS.ORGANIZATION_SETTINGS.ORGANIZATION_DATA.POINTS_PROGRAM_GAIN_REQUIRED_VALUE_PLACEHOLDER'
                                  | translate
                              "
                              formControlName="pointsProgramGainRequiredValue"
                            />
                          </div>
                          <div class="form-group col-md-6">
                            <label for="pointsProgramGainPoints">{{
                              'SETTINGS.ORGANIZATION_SETTINGS.ORGANIZATION_DATA.POINTS_PROGRAM_GAIN_POINTS' | translate
                            }}</label>
                            <input
                              type="text"
                              [mask]="DECIMAL_MASK"
                              thousandSeparator=","
                              minLength="0"
                              id="pointsProgramGainPoints"
                              class="form-control round"
                              [placeholder]="
                                'SETTINGS.ORGANIZATION_SETTINGS.ORGANIZATION_DATA.POINTS_PROGRAM_GAIN_POINTS_PLACEHOLDER'
                                  | translate
                              "
                              formControlName="pointsProgramGainPoints"
                            />
                          </div>
                          <div class="form-group col-md-6">
                            <label for="pointsProgramExchangePoints">{{
                              'SETTINGS.ORGANIZATION_SETTINGS.ORGANIZATION_DATA.POINTS_PROGRAM_EXCHANGE_POINTS'
                                | translate
                            }}</label>
                            <input
                              type="text"
                              [mask]="DECIMAL_MASK"
                              thousandSeparator=","
                              minLength="0"
                              id="pointsProgramExchangePoints"
                              class="form-control round"
                              [placeholder]="
                                'SETTINGS.ORGANIZATION_SETTINGS.ORGANIZATION_DATA.POINTS_PROGRAM_EXCHANGE_POINTS_PLACEHOLDER'
                                  | translate
                              "
                              formControlName="pointsProgramExchangePoints"
                            />
                          </div>
                          <div class="form-group col-md-6">
                            <label for="pointsProgramExchangeValue">{{
                              'SETTINGS.ORGANIZATION_SETTINGS.ORGANIZATION_DATA.POINTS_PROGRAM_EXCHANGE_VALUE'
                                | translate
                            }}</label>
                            <input
                              type="text"
                              [mask]="DECIMAL_MASK"
                              thousandSeparator=","
                              minLength="0"
                              id="pointsProgramExchangeValue"
                              class="form-control round"
                              [placeholder]="
                                'SETTINGS.ORGANIZATION_SETTINGS.ORGANIZATION_DATA.POINTS_PROGRAM_EXCHANGE_VALUE_PLACEHOLDER'
                                  | translate
                              "
                              formControlName="pointsProgramExchangeValue"
                            />
                          </div>
                        </ng-container>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card">
                  <div class="card-header mb-3 pt-0">
                    <h4 class="card-title">
                      <i class="fa fa-file-text"></i>
                      <span class="mx-1">{{ 'SETTINGS.ORGANIZATION_SETTINGS.REPORTS_TITLE' | translate }}</span>
                    </h4>
                  </div>
                  <div class="card-content">
                    <div class="px-3">
                      <div class="row">
                        <div class="form-group col-md-12">
                          <input type="checkbox" formControlName="showDisplayNameInReport" />
                          <span class="ml-2 mt-0 pt-0">{{
                            'SETTINGS.ORGANIZATION_SETTINGS.ORGANIZATION_DATA.SHOW_ORGANIZATION_NAME' | translate
                          }}</span>
                        </div>
                        <div class="form-group col-md-12">
                          <input type="checkbox" formControlName="showLogoInReport" />
                          <span class="ml-2 mt-0 pt-0">{{
                            'SETTINGS.ORGANIZATION_SETTINGS.ORGANIZATION_DATA.SHOW_ORGANIZATION_LOGO' | translate
                          }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card">
                  <div class="card-header mb-3 pt-0">
                    <h4 class="card-title">
                      <i class="fa fa-file-image-o"></i>
                      <span class="mx-1">{{
                        'SETTINGS.ORGANIZATION_SETTINGS.ORGANIZATION_SETTINGS_LOGO_TITLE' | translate
                      }}</span>
                    </h4>
                  </div>
                  <div class="card-content">
                    <div *ngIf="organizationSetting.logoUrl" class="form-group col-md-12">
                      <app-file-viewer
                        [url]="organizationSetting.logoUrl"
                        [allowDelete]="true"
                        [allowUpdate]="true"
                        (delete)="deleteLogo(confirmDeleteLogoModalRef)"
                        (update)="updateLogo(confirmDeleteLogoModalRef)"
                      ></app-file-viewer>
                    </div>
                    <div *ngIf="!organizationSetting.logoUrl" class="form-group col-md-12">
                      <button
                        type="button"
                        class="btn btn-raised btn-success my-1 round"
                        (click)="updateLogo(confirmUpdateLogoModalRef)"
                        [disabled]="isOrganizationSettingsUpdating$ | async"
                      >
                        <i class="fa fa-edit font-medium-3"></i>
                        <span class="mx-1">{{
                          'SETTINGS.ORGANIZATION_SETTINGS.ORGANIZATION_DATA.ORGANIZATION_LOGO' | translate
                        }}</span>
                      </button>
                    </div>
                  </div>
                </div>
                <div class="card">
                  <div class="card-header mb-3 pt-0">
                    <h4 class="card-title">
                      <i class="fa fa-image"></i>
                      <span class="mx-1">{{
                        'SETTINGS.ORGANIZATION_SETTINGS.ORGANIZATION_SETTINGS_IMAGES_TITLE' | translate
                      }}</span>
                    </h4>
                  </div>
                  <div class="card-content">
                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-group col-md-12">
                          <div class="row">
                            <div class="col-md-auto">
                              <input type="checkbox" formControlName="showInvoiceHeader" />
                              <span class="ml-2 mt-0 pt-0">{{
                                'SETTINGS.ORGANIZATION_SETTINGS.ORGANIZATION_DATA.SHOW_INVOICE_HEADER' | translate
                              }}</span>
                            </div>
                            <div class="form-group col-md-auto" *ngIf="form.value.showInvoiceHeader">
                              <ng-select
                                class="round"
                                formControlName="headerHeight"
                                labelForId="headerHeight"
                                searchable="false"
                                [placeholder]="
                                  'SETTINGS.ORGANIZATION_SETTINGS.ORGANIZATION_DATA.HEADER_HEIGHT_PLACEHOLDER'
                                    | translate
                                "
                              >
                                <ng-option *ngFor="let item of headerHeights" [value]="item">
                                  {{ item }}
                                </ng-option>
                              </ng-select>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="organizationSetting.invoiceHeaderUrl" class="form-group col-md-6 mt-3">
                          <app-file-viewer
                            [url]="organizationSetting.invoiceHeaderUrl"
                            [allowDelete]="true"
                            [allowUpdate]="true"
                            (delete)="deleteLogo(confirmDeleteInvoiceHeaderImageModalRef)"
                            (update)="updateLogo(confirmUpdateImageHeaderRef)"
                          ></app-file-viewer>
                        </div>
                        <div *ngIf="!organizationSetting.invoiceHeaderUrl" class="form-group col-md-6">
                          <button
                            type="button"
                            class="btn btn-raised btn-success my-1 round"
                            (click)="updateLogo(confirmUpdateImageHeaderRef)"
                          >
                            <i class="fa fa-edit font-medium-3"></i>
                            <span class="mx-1">{{
                              'STORES.LOCATIONS.CONFIRM_ADD_IMAGE_HEADER_TITLE' | translate
                            }}</span>
                          </button>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group col-md-12">
                          <div class="row">
                            <div class="col-md-auto">
                              <input type="checkbox" formControlName="showInvoiceFooter" />
                              <span class="ml-2 mt-0 pt-0">{{
                                'SETTINGS.ORGANIZATION_SETTINGS.ORGANIZATION_DATA.SHOW_INVOICE_FOOTER' | translate
                              }}</span>
                            </div>
                            <div class="form-group col-md-auto" *ngIf="form.value.showInvoiceFooter">
                              <ng-select
                                class="round"
                                formControlName="footerHeight"
                                labelForId="footerHeight"
                                searchable="false"
                                [placeholder]="
                                  'SETTINGS.ORGANIZATION_SETTINGS.ORGANIZATION_DATA.FOOTER_HEIGHT_PLACEHOLDER'
                                    | translate
                                "
                              >
                                <ng-option *ngFor="let item of footerHeights" [value]="item">
                                  {{ item }}
                                </ng-option>
                              </ng-select>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="organizationSetting.invoiceFooterUrl" class="form-group col-md-6 mt-3">
                          <app-file-viewer
                            [url]="organizationSetting.invoiceFooterUrl"
                            [allowDelete]="true"
                            [allowUpdate]="true"
                            (delete)="deleteLogo(confirmDeleteInvoiceFooterImageModalRef)"
                            (update)="updateLogo(confirmUpdateImageFooterRef)"
                          ></app-file-viewer>
                        </div>
                        <div *ngIf="!organizationSetting.invoiceFooterUrl" class="form-group col-md-6">
                          <button
                            type="button"
                            class="btn btn-raised btn-success my-1 round"
                            (click)="updateLogo(confirmUpdateImageFooterRef)"
                          >
                            <i class="fa fa-edit font-medium-3"></i>
                            <span class="mx-1">{{
                              'STORES.LOCATIONS.CONFIRM_ADD_IMAGE_FOOTER_TITLE' | translate
                            }}</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group col-12 d-flex justify-content-between mt-2">
              <button
                *ngIf="[Claims.UPDATE_ORGANIZATION_SETTING] | authorize | async"
                type="submit"
                class="btn btn-raised btn-success round"
                [disabled]="isOrganizationSettingsUpdating$ | async"
              >
                <i class="fa fa-check"></i>
                <span class="mx-1">{{ 'SHARED.CONFIRM.SAVE' | translate }}</span>
              </button>
              <a class="btn btn-dark round" routerLink="/">
                <i class="fa fa-undo"></i>
                <span class="mx-1">{{
                  'SETTINGS.ORGANIZATION_SETTINGS.ORGANIZATION_DATA.HOME_RETURN' | translate
                }}</span>
              </a>
              <!-- organization details ends -->
            </div>
            <!-- update report setting ends -->
          </div>
        </div>
      </form>
    </div>
  </div>
</div>

<!-- confirm modal starts -->
<ng-template #confirmModalRef let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
      {{ 'SETTINGS.ORGANIZATION_SETTINGS.CONFIRM_UPDATE_ORGANIZATION_TITLE' | translate }}
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="d()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p class="text-success">{{ 'SETTINGS.ORGANIZATION_SETTINGS.CONFIRM_UPDATE_ORGANIZATION_MESSAGE' | translate }}</p>
  </div>
  <div class="modal-footer">
    <div class="modal-actions">
      <button type="button" class="btn btn-raised btn-success round" (click)="c(); confirm()" ngbAutofocus>
        <i class="fa fa-check"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.SAVE' | translate }}</span>
      </button>
      <button type="button" class="btn btn-outline-dark round" (click)="c()">
        <i class="fa fa-times"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.CANCEL' | translate }}</span>
      </button>
    </div>
  </div>
</ng-template>
<!-- confirm modal ends -->

<!-- confirm update logo modal starts -->
<ng-template #confirmUpdateLogoModalRef let-c="close" let-d="dismiss">
  <form [formGroup]="updateLogoForm">
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">
        {{ 'SETTINGS.ORGANIZATION_SETTINGS.ORGANIZATION_DATA.CONFIRM_UPDATE_ORGANIZATION_LOG_TITLE' | translate }}
      </h4>
      <button type="button" class="close" aria-label="Close" (click)="d()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="row">
        <div class="form-group col-md-12">
          <app-file-upload formControlName="logo"></app-file-upload>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <div class="modal-actions">
        <button
          type="button"
          class="btn btn-raised btn-success round"
          (click)="c(); updateLogoConfirm()"
          [disabled]="isOrganizationSettingsUpdating$ | async"
          ngbAutofocus
        >
          <i class="fa fa-edit"></i>
          <span class="mx-1"> {{ 'SHARED.CONFIRM.SAVE' | translate }} </span>
        </button>
        <button type="button" class="btn btn-outline-dark round" (click)="c()">
          <i class="fa fa-times"></i>
          <span class="mx-1"> {{ 'SHARED.CONFIRM.CANCEL' | translate }} </span>
        </button>
      </div>
    </div>
  </form>
</ng-template>
<!-- confirm update logo modal ends -->

<!-- confirm delete logo modal starts -->
<ng-template #confirmDeleteLogoModalRef let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title text-warning" id="modal-basic-title">
      {{ 'SETTINGS.ORGANIZATION_SETTINGS.ORGANIZATION_DATA.CONFIRM_DELETE_PRODUCT_IMAGE_TITLE' | translate }}
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="d()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p class="text-warning">
      {{ 'SETTINGS.ORGANIZATION_SETTINGS.ORGANIZATION_DATA.CONFIRM_DELETE_PRODUCT_IMAGE_MESSAGE' | translate }}
    </p>
  </div>
  <div class="modal-footer">
    <div class="modal-actions">
      <button type="button" class="btn btn-raised btn-warning round" (click)="c(); deleteLogoConfirm()" ngbAutofocus>
        <i class="fa fa-trash"></i>
        <span class="mx-1"> {{ 'SHARED.CONFIRM.DELETE' | translate }} </span>
      </button>
      <button type="button" class="btn btn-outline-dark round" (click)="c()">
        <i class="fa fa-times"></i>
        <span class="mx-1"> {{ 'SHARED.CONFIRM.CANCEL' | translate }} </span>
      </button>
    </div>
  </div>
</ng-template>
<!-- confirm delete logo modal ends -->

<!-- confirm update image header modal starts -->
<ng-template #confirmUpdateImageHeaderRef let-c="close" let-d="dismiss">
  <form [formGroup]="updateHeaderForm">
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">
        {{ 'STORES.LOCATIONS.CONFIRM_ADD_IMAGE_HEADER_TITLE' | translate }}
      </h4>
      <button type="button" class="close" aria-label="Close" (click)="d()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="row">
        <div class="form-group col-md-12">
          <app-file-upload formControlName="invoiceHeaderFilename"></app-file-upload>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <div class="modal-actions">
        <button
          type="button"
          class="btn btn-raised btn-success round"
          (click)="c(); updateImageHeaderConfirm()"
          ngbAutofocus
        >
          <i class="fa fa-edit"></i>
          <span class="mx-1"> {{ 'SHARED.CONFIRM.SAVE' | translate }} </span>
        </button>
        <button type="button" class="btn btn-outline-dark round" (click)="c()">
          <i class="fa fa-times"></i>
          <span class="mx-1"> {{ 'SHARED.CONFIRM.CANCEL' | translate }} </span>
        </button>
      </div>
    </div>
  </form>
</ng-template>
<!-- confirm update image header modal ends -->

<!-- confirm delete invoice header image modal starts -->
<ng-template #confirmDeleteInvoiceHeaderImageModalRef let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title text-warning" id="modal-basic-title">
      {{ 'SETTINGS.ORGANIZATION_SETTINGS.ORGANIZATION_DATA.CONFIRM_DELETE_INVOICE_HEADER_IMAGE_TITLE' | translate }}
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="d()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p class="text-warning">
      {{ 'SETTINGS.ORGANIZATION_SETTINGS.ORGANIZATION_DATA.CONFIRM_DELETE_INVOICE_HEADER_IMAGE_MESSAGE' | translate }}
    </p>
  </div>
  <div class="modal-footer">
    <div class="modal-actions">
      <button
        type="button"
        class="btn btn-raised btn-warning round"
        (click)="c(); deleteImageHeaderConfirm()"
        ngbAutofocus
      >
        <i class="fa fa-trash"></i>
        <span class="mx-1"> {{ 'SHARED.CONFIRM.DELETE' | translate }} </span>
      </button>
      <button type="button" class="btn btn-outline-dark round" (click)="c()">
        <i class="fa fa-times"></i>
        <span class="mx-1"> {{ 'SHARED.CONFIRM.CANCEL' | translate }} </span>
      </button>
    </div>
  </div>
</ng-template>
<!-- confirm delete invoice header image modal ends -->

<!-- confirm update image footer modal starts -->
<ng-template #confirmUpdateImageFooterRef let-c="close" let-d="dismiss">
  <form [formGroup]="updateFooterForm">
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">
        {{ 'STORES.LOCATIONS.CONFIRM_ADD_IMAGE_FOOTER_TITLE' | translate }}
      </h4>
      <button type="button" class="close" aria-label="Close" (click)="d()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="row">
        <div class="form-group col-md-12">
          <app-file-upload formControlName="invoiceFooterFilename"></app-file-upload>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <div class="modal-actions">
        <button
          type="button"
          class="btn btn-raised btn-success round"
          (click)="c(); updateImageFooterConfirm()"
          ngbAutofocus
        >
          <i class="fa fa-edit"></i>
          <span class="mx-1"> {{ 'SHARED.CONFIRM.SAVE' | translate }} </span>
        </button>
        <button type="button" class="btn btn-outline-dark round" (click)="c()">
          <i class="fa fa-times"></i>
          <span class="mx-1"> {{ 'SHARED.CONFIRM.CANCEL' | translate }} </span>
        </button>
      </div>
    </div>
  </form>
</ng-template>
<!-- confirm update image footer modal ends -->

<!-- confirm delete invoice footer image modal starts -->
<ng-template #confirmDeleteInvoiceFooterImageModalRef let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title text-warning" id="modal-basic-title">
      {{ 'SETTINGS.ORGANIZATION_SETTINGS.ORGANIZATION_DATA.CONFIRM_DELETE_INVOICE_FOOTER_IMAGE_TITLE' | translate }}
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="d()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p class="text-warning">
      {{ 'SETTINGS.ORGANIZATION_SETTINGS.ORGANIZATION_DATA.CONFIRM_DELETE_INVOICE_FOOTER_IMAGE_MESSAGE' | translate }}
    </p>
  </div>
  <div class="modal-footer">
    <div class="modal-actions">
      <button
        type="button"
        class="btn btn-raised btn-warning round"
        (click)="c(); deleteImageFooterConfirm()"
        ngbAutofocus
      >
        <i class="fa fa-trash"></i>
        <span class="mx-1"> {{ 'SHARED.CONFIRM.DELETE' | translate }} </span>
      </button>
      <button type="button" class="btn btn-outline-dark round" (click)="c()">
        <i class="fa fa-times"></i>
        <span class="mx-1"> {{ 'SHARED.CONFIRM.CANCEL' | translate }} </span>
      </button>
    </div>
  </div>
</ng-template>
<!-- confirm delete invoice footer image modal ends -->
