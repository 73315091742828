import { Action } from '@ngrx/store';

import { PurchaseRequestReceiveType } from 'lookups/models';
import { AppHttpResponse } from 'shared';

/**
 * The Lookups-Module store  purchase request receive type action types.
 */
export enum PurchaseRequestReceiveTypeActionType {
  GET_ALL_PURCHASE_REQUEST_RECEIVE_TYPE = '[Lookups] [PurchaseRequestReceiveType] Get All Purchase Request Receive Types',
  GET_ALL_PURCHASE_REQUEST_RECEIVE_TYPE_FAIL = '[Lookups] [PurchaseRequestReceiveType] Get All Purchase Request Receive Types Fail',
  GET_ALL_PURCHASE_REQUEST_RECEIVE_TYPE_SUCCESS = '[Lookups] [PurchaseRequestReceiveType] Get All Purchase Request Receive Types Success',
}

/**
 * Represents a store  purchase request receive type action.
 */
export class GetAllPurchaseRequestReceiveType implements Action {
  /**
   * The type of the action.
   */
  readonly type = PurchaseRequestReceiveTypeActionType.GET_ALL_PURCHASE_REQUEST_RECEIVE_TYPE;

  /**
   * Fires a new  purchase request receive type action.
   */
  constructor() {}
}

/**
 * Represents a store  purchase request receive type fail action.
 */
export class GetAllPurchaseRequestReceiveTypeFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = PurchaseRequestReceiveTypeActionType.GET_ALL_PURCHASE_REQUEST_RECEIVE_TYPE_FAIL;

  /**
   * Fires a new  purchase request receive type fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store  purchase request receive type success action.
 */
export class GetAllPurchaseRequestReceiveTypeSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = PurchaseRequestReceiveTypeActionType.GET_ALL_PURCHASE_REQUEST_RECEIVE_TYPE_SUCCESS;

  /**
   * Fires a  purchase request receive type success action.
   * @param payload An object contains the list of  purchase request receive types.
   */
  constructor(public payload: AppHttpResponse<PurchaseRequestReceiveType[]>) {}
}

/**
 * Lookups-module  purchase request receive type action types.
 */
export type PurchaseRequestReceiveTypeActions =
  | GetAllPurchaseRequestReceiveType
  | GetAllPurchaseRequestReceiveTypeFail
  | GetAllPurchaseRequestReceiveTypeSuccess;
