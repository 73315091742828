import { Injectable } from '@angular/core';

import { of } from 'rxjs';
import { switchMap, map, catchError, tap, debounceTime } from 'rxjs/operators';
import { Effect, ofType, Actions } from '@ngrx/effects';

import { NotificationService, StatusCode, PAGINATION, TranslationService } from 'shared';
import { VariantsService } from 'stores/services';
import {
  VariantsActionType,
  SearchVariants,
  SearchVariantsFail,
  SearchVariantsSuccess,
  CreateVariant,
  CreateVariantSuccess,
  CreateVariantFail,
  UpdateVariant,
  UpdateVariantSuccess,
  UpdateVariantFail,
  DeleteVariant,
  DeleteVariantSuccess,
  DeleteVariantFail,
  FindVariant,
  FindVariantSuccess,
  FindVariantFail,
} from 'stores/store/actions';

@Injectable()
export class VariantsEffects {
  constructor(
    private actions$: Actions,
    private variantsService: VariantsService,
    private notificationService: NotificationService,
    private translationService: TranslationService
  ) {}

  /* ========================= SEARCH_VARIANTS =================================== */
  @Effect()
  search$ = this.actions$.pipe(
    ofType(VariantsActionType.SEARCH_VARIANTS),
    debounceTime(300),
    switchMap(({ payload }: SearchVariants) =>
      this.variantsService.search(payload?.name ?? '', payload.categories, payload.page, PAGINATION.Variants).pipe(
        map((response) => new SearchVariantsSuccess(response)),
        catchError((error) => of(new SearchVariantsFail(error)))
      )
    )
  );

  /* ========================= FIND_VARIANT =================================== */
  @Effect()
  find$ = this.actions$.pipe(
    ofType(VariantsActionType.FIND_VARIANT),
    switchMap((action: FindVariant) =>
      this.variantsService.findById(action.payload).pipe(
        map((response) => new FindVariantSuccess(response)),
        catchError((error) => of(new FindVariantFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  findFail$ = this.actions$.pipe(
    ofType(VariantsActionType.FIND_VARIANT_FAIL),
    tap((action: FindVariantFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate('STORES.PRODUCT_VARIANTS.PRODUCT_VARIANT_NOT_FOUND')
        );
      }
    })
  );

  /* ========================= CREATE_VARIANT =================================== */
  @Effect()
  create$ = this.actions$.pipe(
    ofType(VariantsActionType.CREATE_VARIANT),
    switchMap((action: CreateVariant) =>
      this.variantsService.create(action.payload).pipe(
        map((response) => new CreateVariantSuccess(response)),
        catchError((error) => of(new CreateVariantFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  createSuccess$ = this.actions$.pipe(
    ofType(VariantsActionType.CREATE_VARIANT_SUCCESS),
    tap((action: CreateVariantSuccess) => {
      this.notificationService.success(
        this.translationService.translate('STORES.PRODUCT_VARIANTS.PRODUCT_VARIANT_ADDED')
      );
    })
  );

  @Effect({ dispatch: false })
  createFail$ = this.actions$.pipe(
    ofType(VariantsActionType.CREATE_VARIANT_FAIL),
    tap((action: CreateVariantFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );

  /* ========================= UPDATE_VARIANT =================================== */
  @Effect()
  update$ = this.actions$.pipe(
    ofType(VariantsActionType.UPDATE_VARIANT),
    switchMap((action: UpdateVariant) =>
      this.variantsService.update(action.payload).pipe(
        map((response) => new UpdateVariantSuccess(response)),
        catchError((error) => of(new UpdateVariantFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  updateSuccess$ = this.actions$.pipe(
    ofType(VariantsActionType.UPDATE_VARIANT_SUCCESS),
    tap((action: UpdateVariantSuccess) => {
      this.notificationService.success(
        this.translationService.translate('STORES.PRODUCT_VARIANTS.PRODUCT_VARIANT_UPDATED')
      );
    })
  );

  @Effect({ dispatch: false })
  updateFail$ = this.actions$.pipe(
    ofType(VariantsActionType.UPDATE_VARIANT_FAIL),
    tap((action: UpdateVariantFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate('STORES.PRODUCT_VARIANTS.PRODUCT_VARIANT_NOT_FOUND')
        );
      } else if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );

  /* ========================= DELETE_VARIANT =================================== */
  @Effect()
  delete$ = this.actions$.pipe(
    ofType(VariantsActionType.DELETE_VARIANT),
    switchMap((action: DeleteVariant) =>
      this.variantsService.delete(action.payload).pipe(
        map((response) => new DeleteVariantSuccess(response)),
        catchError((error) => of(new DeleteVariantFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  deleteSuccess$ = this.actions$.pipe(
    ofType(VariantsActionType.DELETE_VARIANT_SUCCESS),
    tap((action: DeleteVariantSuccess) => {
      this.notificationService.success(
        this.translationService.translate('STORES.PRODUCT_VARIANTS.PRODUCT_VARIANT_DELETED')
      );
    })
  );

  @Effect({ dispatch: false })
  deleteFail$ = this.actions$.pipe(
    ofType(VariantsActionType.DELETE_VARIANT_FAIL),
    tap((action: DeleteVariantFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate('STORES.PRODUCT_VARIANTS.PRODUCT_VARIANT_NOT_FOUND')
        );
      } else if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );
}
