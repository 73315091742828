import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { AppHttpResponse } from 'shared';
import { ClaimGroup } from 'security/models';

/**
 * The claims services includes functionality to get all claims groups.
 */
@Injectable()
export class ClaimsService {
  /**
   * The relative route for the claims.
   *
   * No leading or trailing slashes required.
   */
  private claimsApi = 'security/claims';

  constructor(private http: HttpClient) {}

  /**
   * Gets all claims groups registered on the system.
   */
  public getClaimsGroups(): Observable<AppHttpResponse<ClaimGroup[]>> {
    return this.http.get<AppHttpResponse<ClaimGroup[]>>(`${this.claimsApi}/groups`);
  }

  /**
   * Gets claims groups for current logged-in user.
   */
  public getCurrentLoggedInUserClaimsGroups(): Observable<AppHttpResponse<ClaimGroup[]>> {
    return this.http.get<AppHttpResponse<ClaimGroup[]>>(`${this.claimsApi}/logged-in-user/groups`);
  }
}
