import { Action } from '@ngrx/store';

import { AppHttpResponse } from 'shared';
import {
  User,
  CreateUserInput,
  UpdateUserInput,
  UpdateUserClaimsInput,
  UpdateUserLocationInput,
  UpdateUserBankAccountInput,
  ResetUsersCredentialsInput,
  CreateOrganizationUserInput,
  UpdateUserCostCenterInput,
} from 'security/models';

import { Location } from 'stores/models';
import { BankAccount, CostCenter } from 'finances/models';

/**
 * The security-module store users action types.
 */
export enum UsersActionType {
  SEARCH_USERS = '[Security] [Users] Search Users',
  SEARCH_USERS_FAIL = '[Security] [Users] Search Users Fail',
  SEARCH_USERS_SUCCESS = '[Security] [Users] Search Users Success',

  GET_ORGANIZATION_USERS = '[Security] [Users] Get Organization Users',
  GET_ORGANIZATION_USERS_FAIL = '[Security] [Users] Get Organization Users Fail',
  GET_ORGANIZATION_USERS_SUCCESS = '[Security] [Users] Get Organization Users Success',

  FIND_USER = '[Security] [Users] Find User',
  FIND_USER_FAIL = '[Security] [Users] Find User Fail',
  FIND_USER_SUCCESS = '[Security] [Users] Find User Success',

  CREATE_USER = '[Security] [Users] Create User',
  CREATE_USER_FAIL = '[Security] [Users] Create User Fail',
  CREATE_USER_SUCCESS = '[Security] [Users] Create User Success',

  UPDATE_USER = '[Security] [Users] Update User',
  UPDATE_USER_FAIL = '[Security] [Users] Update User Fail',
  UPDATE_USER_SUCCESS = '[Security] [Users] Update User Success',

  BLOCK_USER = '[Security] [Users] Block User',
  BLOCK_ORGANIZATION_USER = '[Security] [Users] Block Organization User',
  BLOCK_USER_FAIL = '[Security] [Users] Block User Fail',
  BLOCK_USER_SUCCESS = '[Security] [Users] Block User Success',

  ACTIVATE_USER = '[Security] [Users] Activate User',
  ACTIVATE_ORGANIZATION_USER = '[Security] [Users] Activate Organization User',
  ACTIVATE_USER_FAIL = '[Security] [Users] Activate User Fail',
  ACTIVATE_USER_SUCCESS = '[Security] [Users] Activate User Success',

  UPDATE_ORGANIZATION_USER_CLAIMS = '[Security] [Users] Update Organization User Claims',
  UPDATE_ORGANIZATION_USER_CLAIMS_FAIL = '[Security] [Users] Update Organization User Claims Fail',
  UPDATE_ORGANIZATION_USER_CLAIMS_SUCCESS = '[Security] [Users] Update Organization User Claims Success',

  UPDATE_USER_POINTS_OF_SALES = '[Security] [Users] Update User Point Of Sale',
  UPDATE_USER_POINTS_OF_SALES_FAIL = '[Security] [Users] Update User Point Of Sale Fail',
  UPDATE_USER_POINTS_OF_SALES_SUCCESS = '[Security] [Users] Update User Point Of Sale Success',

  UPDATE_USER_LOCATIONS = '[Security] [Users] Update User Location',
  UPDATE_USER_LOCATIONS_FAIL = '[Security] [Users] Update User Location Fail',
  UPDATE_USER_LOCATIONS_SUCCESS = '[Security] [Users] Update User Location Success',

  UPDATE_USER_BANK_ACCOUNTS = '[Security] [Users] Update User Bank Account',
  UPDATE_USER_BANK_ACCOUNTS_FAIL = '[Security] [Users] Update User Bank Account Fail',
  UPDATE_USER_BANK_ACCOUNTS_SUCCESS = '[Security] [Users] Update User Bank Account Success',

  UPDATE_USER_COST_CENTERS = '[Security] [Users] Update User Cost Center',
  UPDATE_USER_COST_CENTERS_FAIL = '[Security] [Users] Update User Cost Center Fail',
  UPDATE_USER_COST_CENTERS_SUCCESS = '[Security] [Users] Update User Cost Center Success',

  RESET_USERS_CREDENTIALS = '[Security] [Users] Reset Users Credentials',
  RESET_USERS_CREDENTIALS_FAIL = '[Security] [Users] Reset Users Credentials Fail',
  RESET_USERS_CREDENTIALS_SUCCESS = '[Security] [Users] Reset Users Credentials Success',

  CREATE_ORGANIZATION_USER = '[Security] [Users] Create organization User',
  CREATE_ORGANIZATION_USER_FAIL = '[Security] [Users] Create organization User Fail',
  CREATE_ORGANIZATION_USER_SUCCESS = '[Security] [Users] Create organization User Success',

  GET_USER_LOCATIONS = '[Security] [Users] Get User Locations',
  GET_USER_LOCATIONS_FAIL = '[Security] [Users] Get User Locations Fail',
  GET_USER_LOCATIONS_SUCCESS = '[Security] [Users] Get User Locations Success',

  GET_USER_BANK_ACCOUNTS = '[Security] [Users] Get User Bank Accounts',
  GET_USER_BANK_ACCOUNTS_FAIL = '[Security] [Users] Get User Bank Accounts Fail',
  GET_USER_BANK_ACCOUNTS_SUCCESS = '[Security] [Users] Get User Bank Accounts Success',

  GET_USER_COST_CENTERS = '[Security] [Users] Get User Cost Centers',
  GET_USER_COST_CENTERS_FAIL = '[Security] [Users] Get User Cost Centers Fail',
  GET_USER_COST_CENTERS_SUCCESS = '[Security] [Users] Get User Cost Centers Success',
}

/**
 * Represents a store users search action.
 */
export class SearchUsers implements Action {
  /**
   * The type of the action.
   */
  readonly type = UsersActionType.SEARCH_USERS;

  /**
   * Fires a new users search action.
   * @param payload the search parameters,
   * if omitted, all users will be loaded.
   */
  constructor(public payload?: { name: string; page: number }) {}
}

/**
 * Represents a store users search-fail action.
 */
export class SearchUsersFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = UsersActionType.SEARCH_USERS_FAIL;

  /**
   * Fires a new users search-fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store search-success action.
 */
export class SearchUsersSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = UsersActionType.SEARCH_USERS_SUCCESS;

  /**
   * Fires a new users search-success action.
   * @param payload An object contains the list of users that matches the search criteria
   * in addition to the pagination info.
   */
  constructor(public payload: AppHttpResponse<User[]>) {}
}

/**
 * Represents a store get-organization-users action.
 */
export class GetOrganizationUsers implements Action {
  /**
   * The type of the action.
   */
  readonly type = UsersActionType.GET_ORGANIZATION_USERS;

  /**
   * Fires a new  organization users action.
   * @param payload the search parameters,
   * if omitted, all users will be loaded.
   */
  constructor(public payload?: { name: string; page: number }) {}
}

/**
 * Represents a store users get-organization-users-fail action.
 */
export class GetOrganizationUsersFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = UsersActionType.GET_ORGANIZATION_USERS_FAIL;

  /**
   * Fires a new get-organization-users-fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store get-organization-users-success action.
 */
export class GetOrganizationUsersSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = UsersActionType.GET_ORGANIZATION_USERS_SUCCESS;

  /**
   * Fires a new get-organization-users-success action.
   * @param payload An object contains the list of users that matches the search criteria
   * in addition to the pagination info.
   */
  constructor(public payload: AppHttpResponse<User[]>) {}
}

/**
 * Represents a store find-user action.
 */
export class FindUser implements Action {
  /**
   * The type of the action.
   */
  readonly type = UsersActionType.FIND_USER;

  /**
   * Fires a new find user action.
   * @param payload the id of the user.
   */
  constructor(public payload: number) {}
}

/**
 * Represents a store find-user-fail action.
 */
export class FindUserFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = UsersActionType.FIND_USER_FAIL;

  /**
   * Fires a new find-user-fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store find user-success action.
 */
export class FindUserSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = UsersActionType.FIND_USER_SUCCESS;

  /**
   * Fires a new find-user-success action.
   * @param payload The loaded user.
   */
  constructor(public payload: User) {}
}

/**
 * Represents a store user create action.
 */
export class CreateUser implements Action {
  /** The type of the action. */
  readonly type = UsersActionType.CREATE_USER;

  /**
   * Fires a new user create action.
   * @param payload the new user data-model.
   */
  constructor(public payload: CreateUserInput) {}
}

/**
 * Represents a store user create-fail action.
 */
export class CreateUserFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = UsersActionType.CREATE_USER_FAIL;

  /**
   * Fires a new user create-fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store user create-success action.
 */
export class CreateUserSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = UsersActionType.CREATE_USER_SUCCESS;

  /**
   * Fires a new user create-success action.
   * @param payload The created user.
   */
  constructor(public payload: AppHttpResponse<User>) {}
}

/**
 * Represents a store user update action.
 */
export class UpdateUser implements Action {
  /**
   * The type of the action.
   */
  readonly type = UsersActionType.UPDATE_USER;

  /**
   * Fires a new user update action.
   * @param payload The user's updated data.
   */
  constructor(public payload: UpdateUserInput) {}
}

/**
 * Represents a store user update-fail action.
 */
export class UpdateUserFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = UsersActionType.UPDATE_USER_FAIL;

  /**
   * Fires a new user update-fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store user update-success action.
 */
export class UpdateUserSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = UsersActionType.UPDATE_USER_SUCCESS;

  /**
   * Fires a new user update-success action.
   * @param payload The updated user.
   */
  constructor(public payload: AppHttpResponse<User>) {}
}

/**
 * Represents a store user block action.
 */
export class BlockUser implements Action {
  /**
   * The type of the action.
   */
  readonly type = UsersActionType.BLOCK_USER;

  /**
   * Fires a new user block action.
   * @param payload The user's id.
   */
  constructor(public payload: number) {}
}

/**
 * Represents a store organization user block action.
 */
export class BlockOrganizationUser implements Action {
  /**
   * The type of the action.
   */
  readonly type = UsersActionType.BLOCK_ORGANIZATION_USER;

  /**
   * Fires a new organization user block action.
   * @param payload The user's id.
   */
  constructor(public payload: number) {}
}

/**
 * Represents a store user block-fail action.
 */
export class BlockUserFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = UsersActionType.BLOCK_USER_FAIL;

  /**
   * Fires a new user block-fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store user block-success action.
 */
export class BlockUserSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = UsersActionType.BLOCK_USER_SUCCESS;

  /**
   * Fires a new user block-success action.
   * @param payload The updated user.
   */
  constructor(public payload: User) {}
}

/**
 * Represents a store user activate action.
 */
export class ActivateUser implements Action {
  /**
   * The type of the action.
   */
  readonly type = UsersActionType.ACTIVATE_USER;

  /**
   * Fires a new user activate action.
   * @param payload The user's id.
   */
  constructor(public payload: number) {}
}

/**
 * Represents a store organization user activate action.
 */
export class ActivateOrganizationUser implements Action {
  /**
   * The type of the action.
   */
  readonly type = UsersActionType.ACTIVATE_ORGANIZATION_USER;

  /**
   * Fires a new organization user activate action.
   * @param payload The user's id.
   */
  constructor(public payload: number) {}
}

/**
 * Represents a store user activate-fail action.
 */
export class ActivateUserFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = UsersActionType.ACTIVATE_USER_FAIL;

  /**
   * Fires a new user activate-fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store user activate-success action.
 */
export class ActivateUserSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = UsersActionType.ACTIVATE_USER_SUCCESS;

  /**
   * Fires a new user activate-success action.
   * @param payload The updated user.
   */
  constructor(public payload: User) {}
}

/**
 * Represents a store organization user claims update action.
 */
export class UpdateOrganizationUserClaims implements Action {
  /**
   * The type of the action.
   */
  readonly type = UsersActionType.UPDATE_ORGANIZATION_USER_CLAIMS;

  /**
   * Fires a new user claims update action.
   * @param payload The user claim's updated data.
   */
  constructor(public payload: UpdateUserClaimsInput) {}
}

/**
 * Represents a store user update-fail action.
 */
export class UpdateOrganizationUserClaimsFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = UsersActionType.UPDATE_ORGANIZATION_USER_CLAIMS_FAIL;

  /**
   * Fires a new user claims update-fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store user claims update-success action.
 */
export class UpdateOrganizationUserClaimsSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = UsersActionType.UPDATE_ORGANIZATION_USER_CLAIMS_SUCCESS;

  /**
   * Fires a new user claims update-success action.
   * @param payload The updated user.
   */
  constructor(public payload: AppHttpResponse<User>) {}
}

/**
 * Represents a store organization user locations update action.
 */
export class UpdateUserLocation implements Action {
  /**
   * The type of the action.
   */
  readonly type = UsersActionType.UPDATE_USER_LOCATIONS;

  /**
   * Fires a new user location update action.
   * @param payload The user locations updated data.
   */
  constructor(public payload: UpdateUserLocationInput) {}
}

/**
 * Represents a store user update-fail action.
 */
export class UpdateUserLocationFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = UsersActionType.UPDATE_USER_LOCATIONS_FAIL;

  /**
   * Fires a new user location update-fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store user location update-success action.
 */
export class UpdateUserLocationSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = UsersActionType.UPDATE_USER_LOCATIONS_SUCCESS;

  /**
   * Fires a new user locations update-success action.
   * @param payload The updated user.
   */
  constructor(public payload: AppHttpResponse<User>) {}
}

/**
 * Represents a store organization user bank accounts update action.
 */
export class UpdateUserBankAccount implements Action {
  /**
   * The type of the action.
   */
  readonly type = UsersActionType.UPDATE_USER_BANK_ACCOUNTS;

  /**
   * Fires a new user bank accounts update action.
   * @param payload The user bank accounts updated data.
   */
  constructor(public payload: UpdateUserBankAccountInput) {}
}

/**
 * Represents a store user update-fail action.
 */
export class UpdateUserBankAccountFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = UsersActionType.UPDATE_USER_BANK_ACCOUNTS_FAIL;

  /**
   * Fires a new user bank account update-fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store user bank account update-success action.
 */
export class UpdateUserBankAccountSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = UsersActionType.UPDATE_USER_BANK_ACCOUNTS_SUCCESS;

  /**
   * Fires a new user bank accounts update-success action.
   * @param payload The updated user.
   */
  constructor(public payload: AppHttpResponse<User>) {}
}

/**
 * Represents a store organization user cost centers update action.
 */
export class UpdateUserCostCenter implements Action {
  /**
   * The type of the action.
   */
  readonly type = UsersActionType.UPDATE_USER_COST_CENTERS;

  /**
   * Fires a new user cost centers update action.
   * @param payload The user cost centers updated data.
   */
  constructor(public payload: UpdateUserCostCenterInput) {}
}

/**
 * Represents a store user update-fail action.
 */
export class UpdateUserCostCenterFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = UsersActionType.UPDATE_USER_COST_CENTERS_FAIL;

  /**
   * Fires a new user cost center update-fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store user cost center update-success action.
 */
export class UpdateUserCostCenterSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = UsersActionType.UPDATE_USER_COST_CENTERS_SUCCESS;

  /**
   * Fires a new user cost centers update-success action.
   * @param payload The updated user.
   */
  constructor(public payload: AppHttpResponse<User>) {}
}

/**
 * Represents a store reset users credentials action.
 */
export class ResetUsersCredentials implements Action {
  /**
   * The type of the action.
   */
  readonly type = UsersActionType.RESET_USERS_CREDENTIALS;

  /**
   * Fires a new user reset credential action.
   * @param payload The user's updated data.
   */
  constructor(public payload: ResetUsersCredentialsInput) {}
}

/**
 * Represents a store reset users credentials fail action.
 */
export class ResetUsersCredentialsFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = UsersActionType.RESET_USERS_CREDENTIALS_FAIL;

  /**
   * Fires a new user reset credentail fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store reset users credentials success action.
 */
export class ResetUsersCredentialsSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = UsersActionType.RESET_USERS_CREDENTIALS_SUCCESS;

  /**
   * Fires a new user reset credentials success action.
   * @param payload The updated user.
   */
  constructor(public payload: AppHttpResponse<User>) {}
}

/**
 * Represents a store organization user create action.
 */
export class CreateOrganizationUser implements Action {
  /** The type of the action. */
  readonly type = UsersActionType.CREATE_ORGANIZATION_USER;

  /**
   * Fires a new organization user create action.
   * @param payload the new organization user data-model.
   */
  constructor(public payload: CreateOrganizationUserInput) {}
}

/**
 * Represents a store organization  user create-fail action.
 */
export class CreateOrganizationUserFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = UsersActionType.CREATE_ORGANIZATION_USER_FAIL;

  /**
   * Fires a new Organization user create-fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store organization user create-success action.
 */
export class CreateOrganizationUserSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = UsersActionType.CREATE_ORGANIZATION_USER_SUCCESS;

  /**
   * Fires a new organization user create-success action.
   * @param payload The created organization user.
   */
  constructor(public payload: AppHttpResponse<User>) {}
}

/**
 * Represents a store get-user-locations action.
 */
export class GetUserLocations implements Action {
  /**
   * The type of the action.
   */
  readonly type = UsersActionType.GET_USER_LOCATIONS;

  /**
   * Fires a new user locations action.
   */
  constructor(public payload: number) {}
}

/**
 * Represents a store get-user-locations-fail action.
 */
export class GetUserLocationsFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = UsersActionType.GET_USER_LOCATIONS_FAIL;

  /**
   * Fires a new get-user-locations-fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store get-user-locations-success action.
 */
export class GetUserLocationsSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = UsersActionType.GET_USER_LOCATIONS_SUCCESS;

  /**
   * Fires a new get-user-locations-success action.
   * @param payload An object contains the list of users that matches the search criteria.
   */
  constructor(public payload: AppHttpResponse<Location[]>) {}
}

/**
 * Represents a store get-user-bank-accounts action.
 */
export class GetUserBankAccounts implements Action {
  /**
   * The type of the action.
   */
  readonly type = UsersActionType.GET_USER_BANK_ACCOUNTS;

  /**
   * Fires a new user bank accounts action.
   */
  constructor(public payload: number) {}
}

/**
 * Represents a store get-user-bank-accounts-fail action.
 */
export class GetUserBankAccountsFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = UsersActionType.GET_USER_BANK_ACCOUNTS_FAIL;

  /**
   * Fires a new get-user-bank-accounts-fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store get-user-bank-accounts-success action.
 */
export class GetUserBankAccountsSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = UsersActionType.GET_USER_BANK_ACCOUNTS_SUCCESS;

  /**
   * Fires a new get-user-bank-accounts-success action.
   * @param payload An object contains the list of users that matches the search criteria.
   */
  constructor(public payload: AppHttpResponse<BankAccount[]>) {}
}

/**
 * Represents a store get-user-cost-centers action.
 */
export class GetUserCostCenters implements Action {
  /**
   * The type of the action.
   */
  readonly type = UsersActionType.GET_USER_COST_CENTERS;

  /**
   * Fires a new user cost centers action.
   */
  constructor(public payload: number) {}
}

/**
 * Represents a store get-user-cost-centers--fail action.
 */
export class GetUserCostCentersFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = UsersActionType.GET_USER_COST_CENTERS_FAIL;

  /**
   * Fires a new get-user-cost-centers-fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store get-user-cost-centers-success action.
 */
export class GetUserCostCentersSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = UsersActionType.GET_USER_COST_CENTERS_SUCCESS;

  /**
   * Fires a new get-user-cost-centers-success action.
   * @param payload An object contains the list of users that matches the search criteria.
   */
  constructor(public payload: AppHttpResponse<CostCenter[]>) {}
}

/**
 * Security-module users action types.
 */
export type UsersActions =
  | SearchUsers
  | SearchUsersFail
  | SearchUsersSuccess
  | GetOrganizationUsers
  | GetOrganizationUsersFail
  | GetOrganizationUsersSuccess
  | GetUserBankAccounts
  | GetUserBankAccountsFail
  | GetUserBankAccountsSuccess
  | GetUserCostCenters
  | GetUserCostCentersFail
  | GetUserCostCentersSuccess
  | GetUserLocations
  | GetUserLocationsFail
  | GetUserLocationsSuccess
  | FindUser
  | FindUserFail
  | FindUserSuccess
  | CreateUser
  | CreateUserFail
  | CreateUserSuccess
  | UpdateUser
  | UpdateUserFail
  | UpdateUserSuccess
  | BlockUser
  | BlockOrganizationUser
  | BlockUserFail
  | BlockUserSuccess
  | ActivateUser
  | ActivateOrganizationUser
  | ActivateUserFail
  | ActivateUserSuccess
  | UpdateOrganizationUserClaims
  | UpdateOrganizationUserClaimsFail
  | UpdateOrganizationUserClaimsSuccess
  | UpdateUserLocation
  | UpdateUserLocationFail
  | UpdateUserLocationSuccess
  | UpdateUserBankAccount
  | UpdateUserBankAccountFail
  | UpdateUserBankAccountSuccess
  | UpdateUserCostCenter
  | UpdateUserCostCenterFail
  | UpdateUserCostCenterSuccess
  | ResetUsersCredentials
  | ResetUsersCredentialsFail
  | ResetUsersCredentialsSuccess
  | CreateOrganizationUser
  | CreateOrganizationUserFail
  | CreateOrganizationUserSuccess;
