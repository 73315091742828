import { PaginationInfo } from 'shared/models';
import { PettyCashReduction } from 'finances/models';

/**
 * Represents the petty cash reduction state.
 */
export class PettyCashReductionState {
  /**
   * The list of petty cash reduction.
   */
  public data: PettyCashReduction[];

  /**
   * The pagination info for the petty cash reduction.
   */
  public paginationInfo: PaginationInfo;

  /**
   * An error that may resulting during processing some actions.
   */
  public error: any;

  /**
   * Determines if there is a running search process.
   */
  public isSearching: boolean;

  /**
   * Determines if the last search process has been ended successfully.
   */
  public isSearchCompleted: boolean;

  /**
   * The current selected petty cash reduction.
   */
  public selectedPettyCashReduction: PettyCashReduction;

  /**
   * Determines if there is a running find petty cash reduction process.
   */
  isFinding: boolean;

  /**
   * Determines if the last find petty cash reduction process has been ended successfully.
   */
  isFindCompleted: boolean;

  /**
   * Determines if there is a running create process.
   */
  public isCreating: boolean;

  /**
   * Determines if the last create process has been ended successfully.
   */
  public isCreateCompleted: boolean;
}
