import { findIndex, remove } from 'lodash';

import { CustomerEngagementsActionType, CustomerEngagementsActions } from 'sales/store/actions';
import { CustomerEngagementsState } from 'sales/store/states';

/**
 * The initial state from which the state starts.
 */
const initialState: CustomerEngagementsState = {
  data: [],
  paginationInfo: {
    total: 0,
    page: 1,
    pageSize: 0,
    count: 0,
  },
  error: null,
  isSearching: false,
  isSearchCompleted: false,
  isFinding: false,
  isFindCompleted: false,
  selectedCustomerEngagement: null,
  isCreating: false,
  isCreateCompleted: false,
  isUpdating: false,
  isUpdateCompleted: false,
  isDeleting: false,
  isDeleteCompleted: false,
  isGetting: false,
  isGetCompleted: false,
};

/**
 * The reducer function that is called in each action dispatch against the state.
 * @param state The current state.
 * @param action The action that will affect the state.
 */
export function customerEngagementsReducer(
  state: CustomerEngagementsState = initialState,
  action: CustomerEngagementsActions
): CustomerEngagementsState {
  switch (action.type) {
    //#region SEARCH_CUSTOMER_ENGAGEMENTS

    case CustomerEngagementsActionType.SEARCH_CUSTOMER_ENGAGEMENTS: {
      return {
        ...state,
        error: null,
        isSearching: true,
        isSearchCompleted: false,
      };
    }

    case CustomerEngagementsActionType.SEARCH_CUSTOMER_ENGAGEMENTS_FAIL: {
      return {
        ...state,
        error: action.payload,
        isSearching: false,
        isSearchCompleted: false,
      };
    }

    case CustomerEngagementsActionType.SEARCH_CUSTOMER_ENGAGEMENTS_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        paginationInfo: {
          ...action.payload.meta,
        },
        error: null,
        isSearching: false,
        isSearchCompleted: true,
      };
    }

    //#endregion SEARCH_CUSTOMER_ENGAGEMENTS

    //#region FIND_CUSTOMER_ENGAGEMENT

    case CustomerEngagementsActionType.FIND_CUSTOMER_ENGAGEMENT: {
      return {
        ...state,
        selectedCustomerEngagement: null,
        error: null,
        isFinding: true,
        isFindCompleted: false,
      };
    }

    case CustomerEngagementsActionType.FIND_CUSTOMER_ENGAGEMENT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isFinding: false,
        isFindCompleted: false,
      };
    }

    case CustomerEngagementsActionType.FIND_CUSTOMER_ENGAGEMENT_SUCCESS: {
      let customerEngagements = [...state.data];
      const customerEngagementIndex = findIndex(
        customerEngagements,
        (customerEngagement) => customerEngagement.id === action.payload.data.id
      );

      /* If customer engagement was found, update it. */
      if (customerEngagementIndex >= 0) {
        customerEngagements[customerEngagementIndex] = action.payload.data;
      } else {
        /* else, insert it to the beginning of the array. */
        customerEngagements = [action.payload.data, ...customerEngagements];
      }

      return {
        ...state,
        data: customerEngagements,
        selectedCustomerEngagement: action.payload.data,
        error: null,
        isFinding: false,
        isFindCompleted: true,
      };
    }

    //#endregion FIND_CUSTOMER_ENGAGEMENT

    //#region CREATE_CUSTOMER_ENGAGEMENT

    case CustomerEngagementsActionType.CREATE_CUSTOMER_ENGAGEMENT: {
      return {
        ...state,
        error: null,
        isCreating: true,
        isCreateCompleted: false,
      };
    }

    case CustomerEngagementsActionType.CREATE_CUSTOMER_ENGAGEMENT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isCreating: false,
        isCreateCompleted: false,
      };
    }

    case CustomerEngagementsActionType.CREATE_CUSTOMER_ENGAGEMENT_SUCCESS: {
      return {
        ...state,
        data: [action.payload.data, ...state.data],
        selectedCustomerEngagement: action.payload.data,
        error: null,
        isCreating: false,
        isCreateCompleted: true,
      };
    }

    //#endregion CREATE_CUSTOMER_ENGAGEMENT

    //#region UPDATE_CUSTOMER_ENGAGEMENT

    case CustomerEngagementsActionType.UPDATE_CUSTOMER_ENGAGEMENT: {
      return {
        ...state,
        error: null,
        isUpdating: true,
        isUpdateCompleted: false,
      };
    }

    case CustomerEngagementsActionType.UPDATE_CUSTOMER_ENGAGEMENT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isUpdating: false,
        isUpdateCompleted: false,
      };
    }

    case CustomerEngagementsActionType.UPDATE_CUSTOMER_ENGAGEMENT_SUCCESS: {
      let customerEngagements = [...state.data];
      const customerEngagementIndex = findIndex(
        customerEngagements,
        (customerEngagement) => customerEngagement.id === action.payload.data.id
      );

      /* If customer was found, update it. */
      if (customerEngagementIndex >= 0) {
        customerEngagements[customerEngagementIndex] = action.payload.data;
      } else {
        /* else, insert it to the beginning of the array. */
        customerEngagements = [action.payload.data, ...customerEngagements];
      }

      return {
        ...state,
        data: customerEngagements,
        selectedCustomerEngagement: action.payload.data,
        error: null,
        isUpdating: false,
        isUpdateCompleted: true,
      };
    }

    //#endregion UPDATE_CUSTOMER_ENGAGEMENT

    //#region DELETE_CUSTOMER_ENGAGEMENT

    case CustomerEngagementsActionType.DELETE_CUSTOMER_ENGAGEMENT: {
      return {
        ...state,
        error: null,
        isDeleting: true,
        isDeleteCompleted: false,
      };
    }

    case CustomerEngagementsActionType.DELETE_CUSTOMER_ENGAGEMENT_FAIL: {
      return {
        ...state,
        error: action.payload.data,
        isDeleting: false,
        isDeleteCompleted: false,
      };
    }

    case CustomerEngagementsActionType.DELETE_CUSTOMER_ENGAGEMENT_SUCCESS: {
      const customerEngagements = [...state.data];

      /* Remove the deleted customer engagement from the array. */
      remove(customerEngagements, (customerEngagement) => customerEngagement.id === action.payload.data.id);

      return {
        ...state,
        data: customerEngagements,
        selectedCustomerEngagement: action.payload.data,
        error: null,
        isDeleting: false,
        isDeleteCompleted: true,
      };
    }

    //#endregion DELETE_CUSTOMER_ENGAGEMENT

    default:
      return state;
  }
}
