import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { Claims } from 'security/models';
import * as fromComponents from 'settings/components';

const routes: Routes = [
  {
    path: 'taxes/create',
    component: fromComponents.CreateTaxComponent,
    data: { claims: [Claims.CREATE_TAX] },
  },
  {
    path: 'taxes/update/:taxId',
    component: fromComponents.UpdateTaxComponent,
    data: { claims: [Claims.UPDATE_TAX] },
  },
  {
    path: 'taxes',
    component: fromComponents.TaxesListComponent,
    data: { claims: [Claims.CREATE_TAX, Claims.UPDATE_TAX, Claims.DELETE_TAX] },
  },
  {
    path: 'organization-settings',
    component: fromComponents.OrganizationSettingsComponent,
    data: { claims: [Claims.UPDATE_ORGANIZATION_SETTING] },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SettingsRoutingModule {}
