import { createSelector } from '@ngrx/store';

import { SalesState, OrdersDeliveryState } from 'sales/store/states';
import { selectSalesState } from 'sales/store/selectors/feature.selectors';

/**
 * Gets the orders delivery state.
 */
const SelectOrdersDeliveryState = createSelector(selectSalesState, (state: SalesState) => state.ordersDelivery);

/**
 * Gets the list of loaded orders delivery.
 */
export const getOrdersDelivery = createSelector(SelectOrdersDeliveryState, (state: OrdersDeliveryState) => state.data);

/**
 * Gets the error that occurred in the state.
 */
export const getOrdersDeliveryError = createSelector(
  SelectOrdersDeliveryState,
  (state: OrdersDeliveryState) => state.error
);

/**
 * Gets the selected order delivery.
 */
export const getSelectedOrderDelivery = createSelector(
  SelectOrdersDeliveryState,
  (state: OrdersDeliveryState) => state.selectedOrderDelivery
);

/**
 * Determines if there is a running search process.
 */
export const getOrderDeliverySearching = createSelector(
  SelectOrdersDeliveryState,
  (state: OrdersDeliveryState) => state.isSearching
);

/**
 * Determines if the search process has been ended successfully.
 */
export const getOrderDeliverySearchCompleted = createSelector(
  SelectOrdersDeliveryState,
  (state: OrdersDeliveryState) => state.isSearchCompleted
);

/**
 * Determines if there is delivery process.
 */
export const getOrderDelivering = createSelector(
  SelectOrdersDeliveryState,
  (state: OrdersDeliveryState) => state.isDelivering
);

/**
 * Determines if the delivery process has been ended successfully.
 */
export const getOrderDeliveryCompleted = createSelector(
  SelectOrdersDeliveryState,
  (state: OrdersDeliveryState) => state.isDeliveryCompleted
);
