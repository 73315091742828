import { Action } from '@ngrx/store';

import { LeadStatus } from 'lookups/models';
import { AppHttpResponse } from 'shared';

/**
 * The Lookups-Module store  lead status action types.
 */
export enum LeadStatusActionType {
  GET_ALL_LEAD_STATUSES = '[Lookups] [LeadStatus] Get All Lead Statuses',
  GET_ALL_LEAD_STATUSES_FAIL = '[Lookups] [LeadStatus] Get All Lead Statuses Fail',
  GET_ALL_LEAD_STATUSES_SUCCESS = '[Lookups] [LeadStatus] Get All Lead Statuses Success',
}

/**
 * Represents a store  leadStatus action.
 */
export class GetAllLeadStatuses implements Action {
  /**
   * The type of the action.
   */
  readonly type = LeadStatusActionType.GET_ALL_LEAD_STATUSES;

  /**
   * Fires a new  leadStatus action.
   */
  constructor() {}
}

/**
 * Represents a store  leadStatus fail action.
 */
export class GetAllLeadStatusesFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = LeadStatusActionType.GET_ALL_LEAD_STATUSES_FAIL;

  /**
   * Fires a new  leadStatus fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store  leadStatus success action.
 */
export class GetAllLeadStatusesSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = LeadStatusActionType.GET_ALL_LEAD_STATUSES_SUCCESS;

  /**
   * Fires a  leadStatus success action.
   * @param payload An object contains the list of  LeadStatuses.
   */
  constructor(public payload: AppHttpResponse<LeadStatus[]>) {}
}

/**
 * Lookups-module  leadStatus action types.
 */
export type LeadStatusActions = GetAllLeadStatuses | GetAllLeadStatusesFail | GetAllLeadStatusesSuccess;
