import { OrdersMonitoringComponent } from 'sales/components/pending-pos-invoices/orders-monitoring/orders-monitoring.component';


export * from  'sales/components/pending-pos-invoices/orders-monitoring/orders-monitoring.component';

/**
 * The set of components registered on this sub-module.
 */
export const COMPONENTS: any[] = [
  OrdersMonitoringComponent
];
