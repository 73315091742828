import { Action } from '@ngrx/store';

import { AppHttpResponse } from 'shared';
import { CreatePosPrinterInput, PosPrinter, UpdatePosPrinterInput } from 'sales/models';

/**
 * The stores-module store pos printer action types.
 */
export enum PosPrinterActionType {
  SEARCH_POS_PRINTERS = '[Sales] [POSPrinter] Search POS Printer',
  SEARCH_POS_PRINTERS_FAIL = '[Sales] [POSPrinter] Search POS Printer Fail',
  SEARCH_POS_PRINTERS_SUCCESS = '[Sales] [POSPrinter] Search POS Printer Success',

  FIND_POS_PRINTER = '[Sales] [POSPrinter] Find POS Printer',
  FIND_POS_PRINTER_FAIL = '[Sales] [POSPrinter] Find POS Printer Fail',
  FIND_POS_PRINTER_SUCCESS = '[Sales] [POSPrinter] Find POS Printer Success',

  CREATE_POS_PRINTER = '[Sales] [POSPrinter] Create POS Printer',
  CREATE_POS_PRINTER_FAIL = '[Sales] [POSPrinter] Create POS Printer Fail',
  CREATE_POS_PRINTER_SUCCESS = '[Sales] [POSPrinter] Create POS Printer Success',

  UPDATE_POS_PRINTER = '[Sales] [POSPrinter] Update POS Printer',
  UPDATE_POS_PRINTER_FAIL = '[Sales] [POSPrinter] Update POS Printer Fail',
  UPDATE_POS_PRINTER_SUCCESS = '[Sales] [POSPrinter] Update POS Printer Success',

  DELETE_POS_PRINTER = '[Sales] [POSPrinter] Delete POS Printer',
  DELETE_POS_PRINTER_FAIL = '[Sales] [POSPrinter] Delete POS Printer Fail',
  DELETE_POS_PRINTER_SUCCESS = '[Sales] [POSPrinter] Delete POS Printer Success',
}

/**
 * Represents a store Pos printer search action.
 */
export class SearchPosPrinters implements Action {
  /**
   * The type of the action.
   */
  readonly type = PosPrinterActionType.SEARCH_POS_PRINTERS;

  /**
   * Fires a new pos printer search action.
   * @param payload the search parameters,
   * if omitted, all pos printer will be loaded.
   */
  constructor(
    public payload: {
      name: string;
      posDevices: number[];
      locations: number[];
      page: number;
    }
  ) {}
}

/**
 * Represents a store pos printer search fail action.
 */
export class SearchPosPrintersFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = PosPrinterActionType.SEARCH_POS_PRINTERS_FAIL;

  /**
   * Fires a new pos printer search fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store search success action.
 */
export class SearchPosPrintersSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = PosPrinterActionType.SEARCH_POS_PRINTERS_SUCCESS;

  /**
   * Fires a new pos printer search success action.
   * @param payload An object contains the list of pos printer that matches the search criteria
   * in addition to the pagination info.
   */
  constructor(public payload: AppHttpResponse<PosPrinter[]>) {}
}

/**
 * Represents a store find pos printer action.
 */
export class FindPosPrinter implements Action {
  /**
   * The type of the action.
   */
  readonly type = PosPrinterActionType.FIND_POS_PRINTER;

  /**
   * Fires a new find pos printer action.
   * @param payload the id of the pos printer.
   */
  constructor(public payload: number) {}
}

/**
 * Represents a store find pos printer fail action.
 */
export class FindPosPrinterFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = PosPrinterActionType.FIND_POS_PRINTER_FAIL;

  /**
   * Fires a new find pos printer fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store find pos printer success action.
 */
export class FindPosPrinterSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = PosPrinterActionType.FIND_POS_PRINTER_SUCCESS;

  /**
   * Fires a new find pos printer success action.
   * @param payload The loaded pos printer.
   */
  constructor(public payload: AppHttpResponse<PosPrinter>) {}
}

/**
 * Represents a store pos printer create action.
 */
export class CreatePosPrinter implements Action {
  /** The type of the action. */
  readonly type = PosPrinterActionType.CREATE_POS_PRINTER;

  /**
   * Fires a new pos printer create action.
   * @param payload the new pos printer data-model.
   */
  constructor(public payload: CreatePosPrinterInput) {}
}

/**
 * Represents a store pos printer create fail action.
 */
export class CreatePosPrinterFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = PosPrinterActionType.CREATE_POS_PRINTER_FAIL;

  /**
   * Fires a new pos printer create fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store pos printer create success action.
 */
export class CreatePosPrinterSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = PosPrinterActionType.CREATE_POS_PRINTER_SUCCESS;

  /**
   * Fires a new pos printer create success action.
   * @param payload The created pos printer.
   */
  constructor(public payload: AppHttpResponse<PosPrinter>) {}
}

/**
 * Represents a store pos printer update action.
 */
export class UpdatePosPrinter implements Action {
  /**
   * The type of the action.
   */
  readonly type = PosPrinterActionType.UPDATE_POS_PRINTER;

  /**
   * Fires a new pos printer update action.
   * @param payload The  pos printer updated data.
   */
  constructor(public payload: UpdatePosPrinterInput) {}
}

/**
 * Represents a store pos printer update fail action.
 */
export class UpdatePosPrinterFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = PosPrinterActionType.UPDATE_POS_PRINTER_FAIL;

  /**
   * Fires a new pos printer update fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store pos printer update success action.
 */
export class UpdatePosPrinterSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = PosPrinterActionType.UPDATE_POS_PRINTER_SUCCESS;

  /**
   * Fires a new pos printer update-success action.
   * @param payload The updated pos printer.
   */
  constructor(public payload: AppHttpResponse<PosPrinter>) {}
}

/**
 * Represents a store pos printer delete action.
 */
export class DeletePosPrinter implements Action {
  /**
   * The type of the action.
   */
  readonly type = PosPrinterActionType.DELETE_POS_PRINTER;

  /**
   * Fires a new pos printer delete action.
   * @param payload The pos printer id.
   */
  constructor(public payload: number) {}
}

/**
 * Represents a store pos printer delete fail action.
 */
export class DeletePosPrinterFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = PosPrinterActionType.DELETE_POS_PRINTER_FAIL;

  /**
   * Fires a new pos printer delete fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store pos printer delete success action.
 */
export class DeletePosPrinterSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = PosPrinterActionType.DELETE_POS_PRINTER_SUCCESS;

  /**
   * Fires a new pos printer delete success action.
   * @param payload The deleted pos printer.
   */
  constructor(public payload: AppHttpResponse<PosPrinter>) {}
}

/**
 * stores-module pos printer action types.
 */
export type PosPrinterActions =
  | SearchPosPrinters
  | SearchPosPrintersFail
  | SearchPosPrintersSuccess
  | FindPosPrinter
  | FindPosPrinterFail
  | FindPosPrinterSuccess
  | CreatePosPrinter
  | CreatePosPrinterFail
  | CreatePosPrinterSuccess
  | UpdatePosPrinter
  | UpdatePosPrinterFail
  | UpdatePosPrinterSuccess
  | DeletePosPrinter
  | DeletePosPrinterFail
  | DeletePosPrinterSuccess;
