import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';

import { Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { tap } from 'rxjs/operators';

import { PageInfo, PaginationInfo } from 'shared';
import * as fromFinancesStore from 'finances/store';
import * as fromLookupsStore from 'lookups/store';
import { PettyCashType, PettyCashSpecification } from 'lookups/models';
import { PettyCash } from 'finances/models';
import { Claims } from 'security/models';

@Component({
  selector: 'app-petty-cash-list',
  templateUrl: './petty-cash-list.component.html',
  styles: [],
})
export class PettyCashListComponent implements OnInit {
  /**
   * Gets or sets the information about the current page.
   */
  pageInfo: PageInfo = {
    title: 'FINANCES.PETTY_CASH.PETTY_CASH',
    icon: 'icon-wallet',
  };

  /**
   * The system supported user claims.
   */
  Claims = Claims;

  /**
   * The list of petty Cash.
   */
  pettyCash$: Observable<PettyCash[]>;

  /**
   * The list of petty cash types.
   */
  pettyCashTypes$: Observable<PettyCashType[]>;

  /**
   * The list of petty cash specifications.
   */
  pettyCashSpecifications$: Observable<PettyCashSpecification[]>;

  /**
   * The pagination info.
   */
  paginationInfo$: Observable<PaginationInfo>;

  /**
   * Indicates whether there is a search process is running or not.
   */
  isSearching$: Observable<boolean>;

  /**
   * Indicates whether there is a block-petty-cash process is running or not.
   */
  isBlocking$: Observable<boolean>;

  /**
   * Indicates whether there is a activate-petty-cash  process is running or not.
   */
  isActivating$: Observable<boolean>;

  /**
   * Indicates whether there is a delete-petty-cash  process is running or not.
   */
  isDeleting$: Observable<boolean>;

  /**
   * The search form.
   */
  searchForm: FormGroup;

  /**
   * Gets or sets the currently selected petty cash for block, activate and delete.
   */
  selectedPettyCash: PettyCash;

  /**
   *
   * @param modalService The modal service.
   * @param financesStore$ The finances store.
   * @param lookupsStore$ The lookups store.
   */
  constructor(
    private modalService: NgbModal,
    private financesStore$: Store<fromFinancesStore.FinancesState>,
    private lookupsStore$: Store<fromLookupsStore.LookupsState>
  ) {}

  ngOnInit(): void {
    this.init();
  }

  /**
   * Initialize component data.
   */
  init() {
    this.initForm();

    /**
     * Load data.
     */

    this.isActivating$ = this.financesStore$.pipe(select(fromFinancesStore.getSelectedPettyCashActivating));
    this.isBlocking$ = this.financesStore$.pipe(select(fromFinancesStore.getSelectedPettyCashBlocking));
    this.isDeleting$ = this.financesStore$.pipe(select(fromFinancesStore.getSelectedPettyCashDeleting));
    this.isSearching$ = this.financesStore$.pipe(select(fromFinancesStore.getPettyCashSearching));

    let isManualSearchTriggeredBeforeForPettyCash = false;
    this.pettyCash$ = this.financesStore$.pipe(
      select(fromFinancesStore.getPettyCash),
      tap((pettyCash) => {
        if (!isManualSearchTriggeredBeforeForPettyCash && !pettyCash.length) {
          isManualSearchTriggeredBeforeForPettyCash = true;
          this.search();
        }
      })
    );

    let isManualSearchTriggeredBeforeForPettyCashTypes = false;
    this.pettyCashTypes$ = this.lookupsStore$.pipe(
      select(fromLookupsStore.getPettyCashTypes),
      tap((pettyCashTypes) => {
        if (!isManualSearchTriggeredBeforeForPettyCashTypes && !pettyCashTypes.length) {
          isManualSearchTriggeredBeforeForPettyCashTypes = true;
          this.lookupsStore$.dispatch(new fromLookupsStore.GetAllPettyCashType());
        }
      })
    );

    let isManualSearchTriggeredBeforeForPettyCashSpecifications = false;
    this.pettyCashSpecifications$ = this.lookupsStore$.pipe(
      select(fromLookupsStore.getPettyCashSpecifications),
      tap((pettyCashSpecifications) => {
        if (!isManualSearchTriggeredBeforeForPettyCashSpecifications && !pettyCashSpecifications.length) {
          isManualSearchTriggeredBeforeForPettyCashSpecifications = true;
          this.lookupsStore$.dispatch(new fromLookupsStore.GetAllPettyCashSpecification());
        }
      })
    );

    this.paginationInfo$ = this.financesStore$.pipe(select(fromFinancesStore.getPettyCashPaginationInfo));
  }

  /**
   * Initialize form and add validators.
   */
  initForm() {
    this.searchForm = new FormGroup({
      description: new FormControl(''),
      types: new FormControl([]),
      specifications: new FormControl([]),
      page: new FormControl(1),
    });
  }

  /**
   * Handles search parameters change.
   */
  search(event?: KeyboardEvent) {
    if (event && event.key === 'Enter') {
      event.preventDefault();
      return;
    }

    this.applyFiltersAndSearch();
  }

  /**
   * Handles pagination page-changed event.
   * @param page The current selected page number.
   */
  pageChanged(page: number) {
    /** Update pagination page. */
    this.searchForm.patchValue({ page });

    this.applyFiltersAndSearch(page);
  }

  /**
   * Applies the search filters and dispatches a search action.
   * @param page The current page number, default to `1`.
   */
  applyFiltersAndSearch(page: number = 1) {
    this.financesStore$.dispatch(
      new fromFinancesStore.SearchPettyCash({
        description: this.searchForm.get('description').value,
        types: this.searchForm.get('types').value,
        specifications: this.searchForm.get('specifications').value,
        page,
      })
    );
  }

  /**
   * Enables trackBy feature for *ngFor utility to track items
   * depending on a comparer to reduce DOM change.
   * If the item is already exist then no HTML will change, only bounded values.
   * @param index The index of the item.
   * @param pettyCash The item to determine if it was changed or not.
   */
  trackItems(index: number, pettyCash: PettyCash) {
    return pettyCash ? pettyCash.id : undefined;
  }

  /**
   * Blocks the pettyCash with the given id.
   * @param petty cash The id of the petty cash to be blocked.
   */
  block(id: number) {
    this.financesStore$.dispatch(new fromFinancesStore.BlockPettyCash(id));
  }

  /**
   * Activates the petty cash with the given id.
   * @param petty cash The id of the petty cash to be activated.
   */
  activate(id: number) {
    this.financesStore$.dispatch(new fromFinancesStore.ActivatePettyCash(id));
  }

  /**
   * Deletes the petty cash with the given id.
   * @param petty cash The id of the petty cash to be deleted.
   */
  delete(id: number) {
    this.financesStore$.dispatch(new fromFinancesStore.DeletePettyCash(id));
  }

  /**
   * Opens the modal of the given templateRef.
   * @param modalRef The modal templateRef to be opened.
   */
  openModal(modalRef) {
    this.modalService.open(modalRef);
  }
}
