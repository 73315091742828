<!-- page header starts -->
<app-page-header [info]="pageInfo"></app-page-header>
<!-- page header ends -->

<!-- project resource file pages start -->
<div class="card">
  <div class="card-content">
    <div class="px-3 pt-3" *ngIf="projectResource$ | async; let projectResource">
      <ul
        class="nav-tabs"
        ngbNav
        #nav="ngbNav"
        [(activeId)]="activePage"
        (activeIdChange)="selectedPageChanged($event)"
        [destroyOnHide]="false"
      >
        <li ngbNavItem="resource">
          <a ngbNavLink>
            <i class="fa fa-file-text" [class.success]="activePage == 'resource'"></i>
            {{ 'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.PROJECT_RESOURCE_TITLE' | translate }}
          </a>
          <ng-template ngbNavContent>
            <app-view-project-resource [projectResource]="projectResource"></app-view-project-resource>
          </ng-template>
        </li>
        <li
          *ngIf="
            (projectResourcePayrollLines$ | async) &&
            projectResource.projectResourceTypeSource.key === projectResourceTypeSources.PAYROLL
          "
          ngbNavItem="payrolls"
        >
          <a ngbNavLink>
            <i class="fa fa-id-badge" [class.success]="activePage == 'payrolls'"></i>
            {{ 'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.PAYROLLS_DATA_TITLE' | translate }}
          </a>
          <ng-template ngbNavContent>
            <app-project-resource-payrolls
              [projectResourceLines$]="projectResourcePayrollLines$"
            ></app-project-resource-payrolls>
          </ng-template>
        </li>
        <li
          ngbNavItem="outgoing-stock-details"
          *ngIf="(projectResourceOutgoingStockLines$ | async) && projectResource.outgoingStockId"
        >
          <a ngbNavLink>
            <i class="fa fa-file-text" [class.success]="activePage == 'outgoing-stock-details'"></i>
            {{ 'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.OUTGOING_STOCK_DETAILS' | translate }}
          </a>
          <ng-template ngbNavContent>
            <app-project-resource-outgoing-stock-details
              [resource]="projectResource"
              [projectResourceOutgoingStockLines]="projectResourceOutgoingStockLines$ | async"
            ></app-project-resource-outgoing-stock-details>
          </ng-template>
        </li>
        <li
          ngbNavItem="asset-details"
          *ngIf="
            (projectResourceAssetDepreciationLines$ | async) &&
            projectResource.projectResourceTypeSource.key === projectResourceTypeSources.ASSET_DEPRECIATION
          "
        >
          <a ngbNavLink>
            <i class="fa fa-free-code-camp" [class.success]="activePage == 'asset-details'"></i>
            {{ 'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.ASSET_DETAILS' | translate }}
          </a>
          <ng-template ngbNavContent>
            <app-project-resource-asset-depreciation-details
              [projectResourceAssetDepreciationLines$]="projectResourceAssetDepreciationLines$"
            ></app-project-resource-asset-depreciation-details>
          </ng-template>
        </li>
        <li
          ngbNavItem="purchase-invoice-details"
          *ngIf="(projectResourcePurchaseInvoiceLines$ | async) && projectResource.purchaseInvoiceId"
        >
          <a ngbNavLink>
            <i class="fa fa-free-code-camp" [class.success]="activePage == 'purchase-invoice-details'"></i>
            {{ 'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.PURCHASE_INVOICE_DETAILS' | translate }}
          </a>
          <ng-template ngbNavContent>
            <app-project-resource-purchase-invoice-details
              [resource]="projectResource"
              [projectResourcePurchaseInvoiceLines]="projectResourcePurchaseInvoiceLines$ | async"
            ></app-project-resource-purchase-invoice-details>
          </ng-template>
        </li>
        <li
          ngbNavItem="purchase-return-details"
          *ngIf="(projectResourcePurchaseReturnLines$ | async) && projectResource.purchaseReturnId"
        >
          <a ngbNavLink>
            <i class="fa fa-free-code-camp" [class.success]="activePage == 'purchase-return-details'"></i>
            {{ 'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.PURCHASE_RETURN_DETAILS' | translate }}
          </a>
          <ng-template ngbNavContent>
            <app-project-resource-purchase-return-details
              [resource]="projectResource"
              [projectResourcePurchaseReturnLines]="projectResourcePurchaseReturnLines$ | async"
            ></app-project-resource-purchase-return-details>
          </ng-template>
        </li>
      </ul>
      <div [ngbNavOutlet]="nav" class="mt-2"></div>
    </div>
  </div>
</div>
<!-- project resource file pages end -->
