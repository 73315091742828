import { Component, OnInit, ViewChild, ElementRef, Input, OnDestroy } from '@angular/core';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { skip, tap } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';

import * as fromSecurityStore from 'security/store';
import * as fromAuthStore from 'auth/store';
import { User } from 'security/models';
import { FormArray, FormGroup } from '@angular/forms';
import { BankAccount, BankAccountFormItem } from 'finances/models';

@Component({
  selector: 'app-update-user-bank-account',
  templateUrl: './update-user-bank-account.component.html',
  styles: [],
})
export class UpdateUserBankAccountComponent implements OnInit, OnDestroy {
  /**
   * The bank accounts confirm modal template reference.
   */
  @ViewChild('bankAccountsConfirmModalRef') bankAccountsConfirmModalRef: ElementRef<any>;

  /**
   * Gets or sets the id of the current edited user.
   */
  @Input() userId: number;

  /**
   * The current logged in user.
   */
  currentLoggedInUser: User;

  /**
   * The list of user bank accounts.
   */
  bankAccounts$: Observable<BankAccount[]>;

  /**
   * Indicates whether there is a update user process is running or not.
   */
  isUpdating$: Observable<boolean>;

  /**
   * The update user form.
   */
  form: FormGroup;

  /**
   * Sets the initial bankAccounts should be added to the bankAccounts form.
   * @param bankAccounts An object contains `count` of initial blank bankAccounts.
   * @param bankAccounts A single service details to be added to the form.
   * @param bankAccounts A list of bankAccounts details to be added to the form.
   */
  initialBankAccountsSubject: BehaviorSubject<
    { count: number } | BankAccountFormItem | BankAccountFormItem[]
  > = new BehaviorSubject(undefined);

  /**
   * Gets the bankAccounts form-array.
   */
  get bankAccountsForm(): FormArray {
    return this.form?.controls.bankAccounts as FormArray;
  }

  /**
   * Used to add new bank account form items to the bank accounts form.
   */
  newBankAccounts: BankAccountFormItem[];

  /**
   * The set of subscriptions on this components,
   * these subscriptions must be unsubscribed before this component got destroyed.
   */
  subscriptions = new Subscription();

  /**
   * @param modalService The modal service.
   * @param securityStore$ The Security-module store.
   * @param authStore$ The Auth-module store.
   */
  constructor(
    private modalService: NgbModal,
    private securityStore$: Store<fromSecurityStore.SecurityState>,
    private authStore$: Store<fromAuthStore.AuthState>
  ) {}

  ngOnInit(): void {
    this.init();
  }

  /**
   * Unsubscribes for component data.
   */
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  /**
   * Initialize component data.
   */
  init() {
    this.initForm();

    /* Load the selected user bank accounts. */
    this.bankAccounts$ = this.securityStore$.pipe(
      select(fromSecurityStore.getSelectedUserBankAccounts),

      tap((userBankAccounts) => {
        if (userBankAccounts) {
          this.form.patchValue(userBankAccounts);
          this.bankAccountsForm.clear();
          this.newBankAccounts = userBankAccounts.map((bankAccount: BankAccount) => ({
            bankAccountId: bankAccount.id,
            bankAccount,
          }));
        }
      })
    );

    /**
     * Load data.
     */
    this.securityStore$.dispatch(new fromSecurityStore.GetUserBankAccounts(this.userId));

    this.isUpdating$ = this.securityStore$.pipe(select(fromSecurityStore.getSelectedUserBankAccountUpdating));

    /**
     * rest form on creation complete
     */
    this.subscriptions.add(
      this.securityStore$
        .pipe(
          select(fromSecurityStore.getSelectedUserUpdateCompleted),
          skip(1),
          tap((updated) => {
            if (updated) {
              this.initForm();
              this.closeModal();
            }
          })
        )
        .subscribe()
    );

    /** Load current logged in user data. */
    this.subscriptions.add(
      this.authStore$
        .pipe(
          select(fromAuthStore.getSessionUser),
          tap((user: User) => {
            if (user) {
              this.currentLoggedInUser = user;
            }
          })
        )
        .subscribe()
    );
  }

  initForm() {
    this.form = new FormGroup({
      bankAccounts: new FormArray([]),
    });
  }

  /**
   * Submits the bank accounts form.
   */
  submit() {
    this.openModal(this.bankAccountsConfirmModalRef);
  }

  /**
   * Confirms the the bank accounts form submit.
   */
  confirm() {
    /**
     * The list of bank accounts in the bank accounts form.
     */
    const bankAccounts = this.bankAccountsForm.value.map((item: BankAccountFormItem) => item.bankAccountId);

    this.securityStore$.dispatch(
      new fromSecurityStore.UpdateUserBankAccount({
        id: this.userId,
        bankAccounts,
      })
    );
  }

  /**
   * Opens the modal of the given templateRef.
   * @param modalRef The modal templateRef to be opened.
   */
  openModal(modalRef) {
    this.modalService.open(modalRef);
  }

  /**
   * Closes the currently opened modal.
   */
  closeModal() {
    this.modalService.dismissAll();
  }
}
