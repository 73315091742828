import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

import { APP_CONSTANTS } from 'shared/models';

/**
 * Adds custom date-time formats.
 *
 * @example `today | date` will return `2020-07-16`
 *
 * @example `today | date:'time'` will return `11:00 AM`
 *
 * @example `today | date:'datetime'` will return `2020-07-16 11:00 AM`
 */
@Pipe({
  name: 'date',
})
export class DateTimePipe extends DatePipe implements PipeTransform {
  transform(value: any, ...args: any[]): any {
    if (value) {
      if (args?.includes('time')) {
        return super.transform(value, APP_CONSTANTS.datetime.time);
      } else if (args?.includes('datetime')) {
        return super.transform(value, APP_CONSTANTS.datetime.datetime);
      }

      return super.transform(value, APP_CONSTANTS.datetime.date);
    }

    return null;
  }
}
