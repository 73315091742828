import { CreateCasherPrinterComponent } from 'sales/components/casher-printers/create-casher-printer/create-casher-printer.component';
import { UpdateCasherPrinterComponent } from 'sales/components/casher-printers/update-casher-printer/update-casher-printer.component';
import { CasherPrintersListComponent } from 'sales/components/casher-printers/casher-printers-list/casher-printers-list.component';
import { CasherPrintersSearchComponent } from 'sales/components/casher-printers/casher-printers-search/casher-printers-search.component';

export * from 'sales/components/casher-printers/create-casher-printer/create-casher-printer.component';
export * from 'sales/components/casher-printers/update-casher-printer/update-casher-printer.component';
export * from 'sales/components/casher-printers/casher-printers-list/casher-printers-list.component';
export * from 'sales/components/casher-printers/casher-printers-search/casher-printers-search.component';

/**
 * The set of components registered on this sub-module.
 */
export const COMPONENTS: any[] = [
  CreateCasherPrinterComponent,
  UpdateCasherPrinterComponent,
  CasherPrintersListComponent,
  CasherPrintersSearchComponent,
];
