import { createSelector } from '@ngrx/store';

import { selectLookupsState } from 'lookups/store/selectors/feature.selectors';
import { LookupsState, EmployeePaymentMethodState } from 'lookups/store/states';

/**
 * Gets the employee payment method state.
 */
const selectEmployeePaymentMethods = createSelector(
  selectLookupsState,
  (state: LookupsState) => state.employeePaymentMethods
);

/**
 * Gets the list of loaded employee payment methods.
 */
export const getEmployeePaymentMethods = createSelector(
  selectEmployeePaymentMethods,
  (state: EmployeePaymentMethodState) => state.data
);

/**
 * Gets the error that occurred in the state.
 */
export const getEmployeePaymentMethodsError = createSelector(
  selectEmployeePaymentMethods,
  (state: EmployeePaymentMethodState) => state.error
);

/**
 * Determines if there is a running load process.
 */
export const getEmployeePaymentMethodsIsLoading = createSelector(
  selectEmployeePaymentMethods,
  (state: EmployeePaymentMethodState) => state.isLoading
);

/**
 * Determines if the last load process has been ended successfully.
 */
export const getEmployeePaymentMethodsIsLoaded = createSelector(
  selectEmployeePaymentMethods,
  (state: EmployeePaymentMethodState) => state.isLoaded
);
