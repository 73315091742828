import { ActionReducerMap } from '@ngrx/store';

import { usersReducer } from 'security/store/reducers/users.reducer';
import { claimsReducer } from 'security/store/reducers/claims.reducer';
import { SecurityState } from 'security/store/states';

/**
 * The Security-Module store reducers.
 */
export const reducers: ActionReducerMap<SecurityState> = {
  users: usersReducer,
  claims: claimsReducer,
};
