import { Report } from 'reports/models';

/**
 * Represents the purchases reports state.
 */
export class PurchasesReportsState {
  /**
   * The loaded report.
   */
  public mostReturnedPurchaseProducts: Report;

  /**
   * The loaded report.
   */
  public purchaseInvoices: Report;

  /**
   * The loaded report.
   */
  public purchaseOrders: Report;

  /**
   * loaded report.
   */
  public purchaseRequests: Report;

  /**
   * The loaded report.
   */
  public purchaseReturns: Report;

  /**
   * The loaded report.
   */
  public topPurchasedProducts: Report;

  /**
   * The loaded report.
   */
  public vendorSummary: Report;

  /**
   * The loaded report.
   */
  public vendorsDebits: Report;

  /**
   * The loaded report.
   */
  public worstPurchaseProducts: Report;

  /**
   * The loaded report.
   */
  public worstReturnedPurchaseProducts: Report;

  /**
   * An error that may resulting during processing some actions.
   */
  public error: any;

  /**
   * Determines if there is a running load process.
   */
  public isLoadingMostReturnedPurchaseProducts: boolean;

  /**
   * Determines if the last load process has been ended successfully.
   */
  public isMostReturnedPurchaseProductsLoadCompleted: boolean;

  /**
   * Determines if there is a running load process.
   */
  public isLoadingPurchaseInvoices: boolean;

  /**
   * Determines if the last load process has been ended successfully.
   */
  public isPurchaseInvoicesLoadCompleted: boolean;

  /**
   * Determines if there is a running load process.
   */
  public isLoadingPurchaseOrders: boolean;

  /**
   * Determines if the last load process has been ended successfully.
   */
  public isPurchaseOrdersLoadCompleted: boolean;

  /**
   * Determines if there is a running load process.
   */
  public isLoadingPurchaseReturns: boolean;

  /**
   * Determines if the last load process has been ended successfully.
   */
  public isPurchaseReturnsLoadCompleted: boolean;

  /**
   * Determines if there is a running load process.
   */
  public isLoadingTopPurchasedProducts: boolean;

  /**
   * Determines if the last load process has been ended successfully.
   */
  public isTopPurchasedProductsLoadCompleted: boolean;

  /**
   * Determines if there is a running load process.
   */
  public isLoadingVendorSummary: boolean;

  /**
   * Determines if the last load process has been ended successfully.
   */
  public isVendorSummaryLoadCompleted: boolean;

  /**
   * Determines if there is a running load process.
   */
  public isLoadingPurchaseRequests: boolean;

  /**
   * Determines if the last load process has been ended successfully.
   */
  public isPurchaseRequestsLoadCompleted: boolean;

  /**
   * Determines if there is a running load process.
   */
  public isLoadingVendorsDebts: boolean;

  /**
   * Determines if the last load process has been ended successfully.
   */
  public isVendorsDebtsLoadCompleted: boolean;

  /**
   * Determines if there is a running load process.
   */
  public isLoadingWorstPurchaseProducts: boolean;

  /**
   * Determines if the last load process has been ended successfully.
   */
  public isWorstPurchaseProductsLoadCompleted: boolean;

  /**
   * Determines if there is a running load process.
   */
  public isLoadingWorstReturnedPurchaseProducts: boolean;

  /**
   * Determines if the last load process has been ended successfully.
   */
  public isWorstReturnedPurchaseProductsLoadCompleted: boolean;
}
