import { Injectable } from '@angular/core';

import { of } from 'rxjs';
import { switchMap, map, catchError, debounceTime } from 'rxjs/operators';
import { Effect, ofType, Actions } from '@ngrx/effects';

import { PAGINATION } from 'shared';
import { ProjectsService } from 'app-search/services';
import {
  ProjectsActionType,
  SearchProjects,
  SearchProjectsFail,
  SearchProjectsSuccess,
} from 'app-search/store/actions';

@Injectable()
export class ProjectsEffects {
  constructor(private actions$: Actions, private projectsService: ProjectsService) {}

  /* ========================= SEARCH_PROJECTS =================================== */
  @Effect()
  search$ = this.actions$.pipe(
    ofType(ProjectsActionType.SEARCH_PROJECTS),
    debounceTime(300),
    switchMap(({ payload }: SearchProjects) =>
      this.projectsService
        .search(
          payload?.description ?? '',
          payload.locations,
          payload.projectStatuses,
          payload.customers,
          payload.page,
          PAGINATION.Projects
        )
        .pipe(
          map((response) => new SearchProjectsSuccess(response)),
          catchError((error) => of(new SearchProjectsFail(error)))
        )
    )
  );
}
