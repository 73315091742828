import { Action } from '@ngrx/store';

import { MaintenanceStage } from 'lookups/models';
import { AppHttpResponse } from 'shared';

/**
 * The Lookups-Module store maintenance stage action types.
 */
export enum MaintenanceStageActionType {
  GET_ALL_MAINTENANCE_STAGES = '[Lookups] [MaintenanceStage] Get All Maintenance Stages',
  GET_ALL_MAINTENANCE_STAGES_FAIL = '[Lookups] [MaintenanceStage] Get All Maintenance Stages Fail',
  GET_ALL_MAINTENANCE_STAGES_SUCCESS = '[Lookups] [MaintenanceStage] Get All Maintenance Stages Success',
}

/**
 * Represents a store maintenance stage action.
 */
export class GetAllMaintenanceStage implements Action {
  /**
   * The type of the action.
   */
  readonly type = MaintenanceStageActionType.GET_ALL_MAINTENANCE_STAGES;

  /**
   * Fires a new maintenance stage action.
   */
  constructor() {}
}

/**
 * Represents a store maintenance stage fail action.
 */
export class GetAllMaintenanceStageFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = MaintenanceStageActionType.GET_ALL_MAINTENANCE_STAGES_FAIL;

  /**
   * Fires a new maintenance stage fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store maintenance stage success action.
 */
export class GetAllMaintenanceStageSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = MaintenanceStageActionType.GET_ALL_MAINTENANCE_STAGES_SUCCESS;

  /**
   * Fires a maintenance stage success action.
   * @param payload An object contains the list of maintenance stages.
   */
  constructor(public payload: AppHttpResponse<MaintenanceStage[]>) {}
}

/**
 * Lookups-module maintenance stage action types.
 */
export type MaintenanceStageActions =
  | GetAllMaintenanceStage
  | GetAllMaintenanceStageFail
  | GetAllMaintenanceStageSuccess;
