<!-- page header starts -->
<app-page-header [info]="pageInfo"></app-page-header>
<!-- page header ends -->

<!-- journals list starts -->
<div class="row">
  <div class="col-md-12">
    <div class="card">
      <div class="card-header mb-3">
        <h4 class="card-title">{{ 'SHARED.SEARCH.SEARCH_TOOLS' | translate }}</h4>
      </div>
      <div class="card-content">
        <div class="px-3">
          <div class="row">
            <div class="col-md-12">
              <form class="row" [formGroup]="searchForm" (ngSubmit)="search()">
                <div class="form-group col">
                  <label for="journalTypes">{{ 'FINANCES.JOURNALS.JOURNAL_DATA.JOURNAL_TYPE' | translate }}</label>
                  <ng-select
                    class="round"
                    formControlName="journalTypes"
                    [multiple]="true"
                    [closeOnSelect]="false"
                    labelForId="journalTypes"
                    searchable="true"
                    [placeholder]="'FINANCES.JOURNALS.JOURNAL_DATA.JOURNAL_TYPE_PLACEHOLDER' | translate"
                    [notFoundText]="'SHARED.SEARCH.SEARCH_NOT_FOUND' | translate"
                  >
                    <ng-option *ngFor="let type of journalTypes$ | async" [value]="type.id">
                      {{ type.name | localize: type.nameEn | async }}
                    </ng-option>
                  </ng-select>
                </div>
                <div class="form-group col">
                  <label for="journalNum">{{
                    'FINANCES.JOURNALS.JOURNAL_DATA.JOURNAL_NUMBER_LABEL' | translate
                  }}</label>
                  <input
                    type="number"
                    id="journalNum"
                    class="form-control round"
                    [placeholder]="'FINANCES.JOURNALS.JOURNAL_DATA.JOURNAL_NUMBER_PLACEHOLDER' | translate"
                    formControlName="journalNum"
                  />
                </div>
                <div class="col">
                  <div class="row">
                    <div class="form-group col-12 col-sm-6 mb-0">
                      <label for="fromDate">{{ 'SHARED.SEARCH.FROM_DATE' | translate }}</label>
                      <div class="input-group round">
                        <input
                          class="form-control"
                          id="fromDate"
                          name="fromDate"
                          formControlName="fromDate"
                          [placeholder]="'SHARED.SEARCH.DATE_PLACEHOLDER' | translate"
                          ngbDatepicker
                          #fromDate="ngbDatepicker"
                        />
                        <div class="input-group-append">
                          <span
                            class="input-group-text gradient-light-blue-indigo"
                            style="cursor: pointer;"
                            (click)="fromDate.toggle()"
                          >
                            <i class="fa fa-calendar"></i>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="form-group col-12 col-sm-6 mb-0">
                      <label for="toDate">{{ 'SHARED.SEARCH.TO_DATE' | translate }}</label>
                      <div class="input-group round">
                        <input
                          class="form-control"
                          id="toDate"
                          name="toDate"
                          formControlName="toDate"
                          [placeholder]="'SHARED.SEARCH.DATE_PLACEHOLDER' | translate"
                          ngbDatepicker
                          #toDate="ngbDatepicker"
                        />
                        <div class="input-group-append">
                          <span
                            class="input-group-text gradient-light-blue-indigo"
                            style="cursor: pointer;"
                            (click)="toDate.toggle()"
                          >
                            <i class="fa fa-calendar"></i>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-auto form-group d-flex">
                  <button
                    type="submit"
                    class="btn btn-raised btn-primary round align-self-end mx-1 mb-0"
                    [disabled]="isSearching$ | async"
                  >
                    <i class="fa fa-search"></i>
                  </button>
                  <a
                    *ngIf="[Claims.CREATE_JOURNAL] | authorize | async"
                    routerLink="/finances/journals/create"
                    class="btn btn-raised btn-success round mx-1 align-self-end mb-0"
                  >
                    <i class="fa fa-plus"></i>
                    <span class="mx-1">{{ 'SHARED.CONFIRM.ADD' | translate }}</span>
                  </a>
                  <app-export-tools
                    *ngIf="(journals$ | async)?.length"
                    class="d-flex align-self-end mx-1"
                    targetElementId="journals-table-export-target-element"
                    [outputFileName]="'SHARED.EXPORT_TABLES.JOURNALS_TABLE' | translate"
                  ></app-export-tools>
                </div>
              </form>
            </div>
            <div class="col-md-12" *ngIf="(journals$ | async)?.length; else noItems">
              <div class="row">
                <div class="col-md-12 alert alert-light text-center" role="alert">
                  {{ 'SHARED.SEARCH.SEARCH_RESULTS' | translate }} ({{ (paginationInfo$ | async).total | toNumber }})
                  {{ 'FINANCES.JOURNALS.JOURNAL_DATA.JOURNAL' | translate }}
                </div>
                <div class="col-md-12">
                  <div class="table-responsive">
                    <table class="table text-center" id="journals-table-export-target-element">
                      <thead>
                        <tr>
                          <th scope="col">
                            <i class="fa fa-barcode"></i
                            ><span class="mx-1">{{
                              'FINANCES.JOURNALS.JOURNAL_DATA.JOURNAL_NUMBER_LABEL' | translate
                            }}</span>
                          </th>
                          <th scope="col">{{ 'FINANCES.JOURNALS.JOURNAL_DATA.JOURNAL_TYPE' | translate }}</th>
                          <th scope="col">{{ 'FINANCES.JOURNALS.JOURNAL_DATA.JOURNAL_DATE' | translate }}</th>
                          <th scope="col">{{ 'SHARED.SEARCH.CREATED_AT' | translate }}</th>
                          <th scope="col">{{ 'FINANCES.JOURNALS.JOURNAL_DATA.USER' | translate }}</th>
                          <th scope="col">{{ 'SHARED.TOOLS' | translate }}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let journal of journals$ | async; trackBy: trackItems; let i = index">
                          <td>{{ journal.journalNum }}</td>
                          <td>{{ journal.journalType.name | localize: journal.journalType.nameEn | async }}</td>
                          <td>
                            <span [ngbTooltip]="journal.transactionDate | date: 'time'">
                              {{ journal.transactionDate | date }}
                            </span>
                          </td>
                          <td>
                            <span [ngbTooltip]="journal.createdAt | date: 'time'">
                              {{ journal.createdAt | date }}
                            </span>
                          </td>
                          <td>{{ journal.user.name }}</td>
                          <td>
                            <a
                              *ngIf="[Claims.CREATE_JOURNAL] | authorize | async"
                              class="info p-0"
                              [routerLink]="['/finances/journals/view', journal.id]"
                              [ngbTooltip]="'FINANCES.JOURNALS.JOURNAL_DATA.DISPLAY_JOURNAL' | translate"
                            >
                              <i class="fa fa-eye font-medium-3 mr-2"></i>
                            </a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div class="d-flex justify-content-between p-2">
                      <ngb-pagination
                        *ngIf="paginationInfo$ | async; let pagination"
                        maxSize="5"
                        [collectionSize]="pagination.total"
                        [pageSize]="pagination.pageSize"
                        [page]="pagination.page"
                        (pageChange)="pageChanged($event)"
                        boundaryLinks="true"
                      >
                        <ng-template ngbPaginationFirst>{{
                          'SHARED.PAGINATION.PAGINATION_FIRST' | translate
                        }}</ng-template>
                        <ng-template ngbPaginationFirst>{{
                          'SHARED.PAGINATION.PAGINATION_PREVIOUS' | translate
                        }}</ng-template>
                        <ng-template ngbPaginationFirst>{{
                          'SHARED.PAGINATION.PAGINATION_NEXT' | translate
                        }}</ng-template>
                        <ng-template ngbPaginationFirst>{{
                          'SHARED.PAGINATION.PAGINATION_LAST' | translate
                        }}</ng-template>
                      </ngb-pagination>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- journals ends -->

<!-- no items template starts -->
<ng-template #noItems>
  <div class="col-md-12 alert alert-warning text-center mt-2" role="alert">
    {{ 'FINANCES.JOURNALS.JOURNAL_DATA.SEARCH_JOURNAL_NOT_FOUND' | translate }}
    <a
      *ngIf="[Claims.CREATE_JOURNAL] | authorize | async"
      routerLink="/finances/journals/create"
      class="btn btn-raised btn-success round"
    >
      <i class="fa fa-plus"></i>
      <span class="mx-1">{{ 'SHARED.CONFIRM.ADD' | translate }}</span>
    </a>
  </div>
</ng-template>
<!-- no items template ends -->
