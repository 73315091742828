import { createSelector } from '@ngrx/store';

import { selectLookupsState } from 'lookups/store/selectors/feature.selectors';
import { LookupsState, PaymentDurationState } from 'lookups/store/states';

/**
 * Gets the paymentDuration state.
 */
const selectPaymentDurations = createSelector(selectLookupsState, (state: LookupsState) => state.paymentDurations);

/**
 * Gets the list of loaded paymentDurations.
 */
export const getPaymentDurations = createSelector(selectPaymentDurations, (state: PaymentDurationState) => state.data);

/**
 * Gets the error that occurred in the state.
 */
export const getPaymentDurationsError = createSelector(
  selectPaymentDurations,
  (state: PaymentDurationState) => state.error
);

/**
 * Determines if there is a running load process.
 */
export const getPaymentDurationsIsLoading = createSelector(
  selectPaymentDurations,
  (state: PaymentDurationState) => state.isLoading
);

/**
 * Determines if the last load process has been ended successfully.
 */
export const getPaymentDurationsIsLoaded = createSelector(
  selectPaymentDurations,
  (state: PaymentDurationState) => state.isLoaded
);
