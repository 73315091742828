import { Action } from '@ngrx/store';

import { ContractDuration } from 'lookups/models';
import { AppHttpResponse } from 'shared';

/**
 * The Lookups-Module store  contract duration action types.
 */
export enum ContractDurationActionType {
  GET_ALL_CONTRACT_DURATIONS = '[Lookups] [ContractDuration] Get All Contract Durations',
  GET_ALL_CONTRACT_DURATIONS_FAIL = '[Lookups] [ContractDuration] Get All Contract Durations Fail',
  GET_ALL_CONTRACT_DURATIONS_SUCCESS = '[Lookups] [ContractDuration] Get All Contract Durations Success',
}

/**
 * Represents a store contract duration action.
 */
export class GetAllContractDurations implements Action {
  /**
   * The type of the action.
   */
  readonly type = ContractDurationActionType.GET_ALL_CONTRACT_DURATIONS;

  /**
   * Fires a new contract duration action.
   */
  constructor() {}
}

/**
 * Represents a store contract duration fail action.
 */
export class GetAllContractDurationsFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = ContractDurationActionType.GET_ALL_CONTRACT_DURATIONS_FAIL;

  /**
   * Fires a new contract duration fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store contract duration success action.
 */
export class GetAllContractDurationsSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = ContractDurationActionType.GET_ALL_CONTRACT_DURATIONS_SUCCESS;

  /**
   * Fires a contract duration success action.
   * @param payload An object contains the list of contract durations.
   */
  constructor(public payload: AppHttpResponse<ContractDuration[]>) {}
}

/**
 * Lookups-module contract duration action types.
 */
export type ContractDurationActions =
  | GetAllContractDurations
  | GetAllContractDurationsFail
  | GetAllContractDurationsSuccess;
