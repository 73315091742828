import { Injectable } from '@angular/core';

import { of } from 'rxjs';
import { switchMap, map, catchError } from 'rxjs/operators';
import { Effect, ofType, Actions } from '@ngrx/effects';

import { LookupsService } from 'lookups/services';
import {
  CustomerPaymentLogTypeActionType,
  GetAllCustomerPaymentLogTypesFail,
  GetAllCustomerPaymentLogTypesSuccess,
  GetCustomerPaymentLogTypesFail,
  GetCustomerPaymentLogTypesSuccess,
} from 'lookups/store/actions';

@Injectable()
export class CustomerPaymentLogTypeEffects {
  constructor(private actions$: Actions, private lookupsService: LookupsService) {}

  /* ========================= GET_CUSTOMER_PAYMENT_LOG_TYPES =================================== */
  @Effect()
  getCustomerPaymentLogTypes$ = this.actions$.pipe(
    ofType(CustomerPaymentLogTypeActionType.GET_CUSTOMER_PAYMENT_LOG_TYPES),
    switchMap(() =>
      this.lookupsService.getCustomerPaymentLogTypes().pipe(
        map((response) => new GetCustomerPaymentLogTypesSuccess(response)),
        catchError((error) => of(new GetCustomerPaymentLogTypesFail(error)))
      )
    )
  );

  /* ========================= GET_ALL_CUSTOMER_PAYMENT_LOG_TYPES =================================== */
  @Effect()
  getAllCustomerPaymentLogTypes$ = this.actions$.pipe(
    ofType(CustomerPaymentLogTypeActionType.GET_ALL_CUSTOMER_PAYMENT_LOG_TYPES),
    switchMap(() =>
      this.lookupsService.getAllCustomerPaymentLogTypes().pipe(
        map((response) => new GetAllCustomerPaymentLogTypesSuccess(response)),
        catchError((error) => of(new GetAllCustomerPaymentLogTypesFail(error)))
      )
    )
  );
}
