<!-- customer invoices starts -->

<!-- invoices list starts -->
<div class="row">
  <div class="col-md-12">
    <div class="card-header mb-3 pt-0">
      <h4 class="card-title">{{ 'SHARED.SEARCH.SEARCH_TOOLS' | translate }}</h4>
    </div>
    <div class="card-content">
      <div class="px-3">
        <div class="row">
          <div class="col-md-12">
            <form class="row" [formGroup]="searchForm" (ngSubmit)="search()">
              <div class="form-group col-md-3">
                <label for="locations">{{ 'SALES.CUSTOMERS.CUSTOMER_DATA.LOCATIONS' | translate }}</label>
                <ng-select
                  class="round"
                  formControlName="locations"
                  [multiple]="true"
                  [closeOnSelect]="false"
                  labelForId="locations"
                  searchable="true"
                  [placeholder]="'SALES.CUSTOMERS.CUSTOMER_DATA.LOCATIONS_PLACEHOLDER' | translate"
                  [notFoundText]="'SHARED.SEARCH.SEARCH_NOT_FOUND' | translate"
                  (open)="locationsListVisibility = true"
                >
                  <ng-option *ngFor="let location of locations" [value]="location.id">
                    {{ location.name | localize: location.nameEn | async }}
                  </ng-option>
                </ng-select>
              </div>
              <div class="form-group col-md-3">
                <label for="invoiceNum">{{ 'SALES.CUSTOMERS.CUSTOMER_DATA.INVOICE_NUMBER' | translate }}</label>
                <input
                  type="number"
                  id="invoiceNum"
                  name="invoiceNum"
                  class="form-control round"
                  [placeholder]="'SALES.CUSTOMERS.CUSTOMER_DATA.INVOICE_NUMBER_PLACEHOLDER' | translate"
                  formControlName="invoiceNum"
                />
              </div>
              <div class="form-group col-md-3">
                <label for="saleInvoiceTypes">{{
                  'SALES.INVOICES.SALE_INVOICE_DATA.SALE_INVOICE_TYPE' | translate
                }}</label>
                <ng-select
                  class="round"
                  formControlName="saleInvoiceTypes"
                  [multiple]="true"
                  [closeOnSelect]="false"
                  labelForId="saleInvoiceTypes"
                  searchable="true"
                  [placeholder]="'SALES.INVOICES.SALE_INVOICE_DATA.SALE_INVOICE_TYPE_PLACEHOLDER' | translate"
                  [notFoundText]="'SHARED.SEARCH.SEARCH_NOT_FOUND' | translate"
                >
                  <ng-option *ngFor="let saleInvoiceType of saleInvoiceTypes$ | async" [value]="saleInvoiceType.id">
                    {{ saleInvoiceType.name | localize: saleInvoiceType.nameEn | async }}
                  </ng-option>
                </ng-select>
              </div>
              <div class="form-group col-md-3">
                <label for="orderNum">{{ 'SALES.CUSTOMERS.CUSTOMER_DATA.ORDER_NUMBER' | translate }}</label>
                <input
                  type="number"
                  id="orderNum"
                  name="orderNum"
                  class="form-control round"
                  [placeholder]="'SALES.CUSTOMERS.CUSTOMER_DATA.ORDER_NUMBER_PLACEHOLDER' | translate"
                  formControlName="orderNum"
                />
              </div>
              <div class="form-group col-md-3">
                <label for="saleInvoiceOrderTypes">{{
                  'SALES.INVOICES.SALE_INVOICE_DATA.SALE_INVOICE_ORDER_TYPE' | translate
                }}</label>
                <ng-select
                  class="round"
                  formControlName="saleInvoiceOrderTypes"
                  [multiple]="true"
                  [closeOnSelect]="false"
                  labelForId="saleInvoiceOrderTypes"
                  searchable="true"
                  [placeholder]="'SALES.INVOICES.SALE_INVOICE_DATA.SALE_INVOICE_ORDER_TYPE_PLACEHOLDER' | translate"
                  [notFoundText]="'SHARED.SEARCH.SEARCH_NOT_FOUND' | translate"
                >
                  <ng-option
                    *ngFor="let saleInvoiceOrderType of saleInvoiceOrderTypes$ | async"
                    [value]="saleInvoiceOrderType.id"
                  >
                    {{ saleInvoiceOrderType.name | localize: saleInvoiceOrderType.nameEn | async }}
                  </ng-option>
                </ng-select>
              </div>
              <div class="col-md-6">
                <div class="row">
                  <div class="form-group col-12 col-sm-6 mb-0">
                    <label for="fromDate">{{ 'SHARED.SEARCH.FROM_DATE' | translate }}</label>
                    <div class="input-group round">
                      <input
                        class="form-control"
                        id="fromDate"
                        name="fromDate"
                        formControlName="fromDate"
                        [placeholder]="'SHARED.SEARCH.DATE_PLACEHOLDER' | translate"
                        ngbDatepicker
                        #fromDate="ngbDatepicker"
                      />
                      <div class="input-group-append">
                        <span
                          class="input-group-text gradient-light-blue-indigo"
                          style="cursor: pointer;"
                          (click)="fromDate.toggle()"
                        >
                          <i class="fa fa-calendar"></i>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="form-group col-12 col-sm-6 mb-0">
                    <label for="toDate">{{ 'SHARED.SEARCH.TO_DATE' | translate }}</label>
                    <div class="input-group round">
                      <input
                        class="form-control"
                        id="toDate"
                        name="toDate"
                        formControlName="toDate"
                        [placeholder]="'SHARED.SEARCH.DATE_PLACEHOLDER' | translate"
                        ngbDatepicker
                        #toDate="ngbDatepicker"
                      />
                      <div class="input-group-append">
                        <span
                          class="input-group-text gradient-light-blue-indigo"
                          style="cursor: pointer;"
                          (click)="toDate.toggle()"
                        >
                          <i class="fa fa-calendar"></i>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-md-auto">
                <div class="col-md-auto form-group d-flex">
                  <button
                    type="submit"
                    class="btn btn-raised btn-primary align-self-end round mx-1 mb-0"
                    [disabled]="isSearching$ | async"
                  >
                    <i class="fa fa-search"></i>
                  </button>
                  <app-export-tools
                    *ngIf="(invoices$ | async)?.length"
                    class="d-flex align-self-end mx-1"
                    targetElementId="customer-invoices-table-export-target-element"
                    [outputFileName]="'SHARED.EXPORT_TABLES.CUSTOMER_INVOICES_TABLE' | translate"
                  ></app-export-tools>
                  <a
                    *ngIf="[Claims.CREATE_CUSTOMER_OPENING_BALANCE_INVOICE] | authorize | async"
                    routerLink="/sales/invoices/create-opening-balance-invoice"
                    class="btn btn-raised btn-success round mx-1 align-self-end col-md-auto mb-0"
                  >
                    <i class="fa fa-plus"></i>
                    <span class="mx-1">{{ 'SALES.CUSTOMERS.ADD_PREVIOUS_BALANCE' | translate }}</span>
                  </a>
                  <a
                    *ngIf="[Claims.CREATE_POINT_OF_SALE_INVOICE] | authorize | async"
                    routerLink="/sales/invoices/create-point-of-sale-invoice"
                    class="btn btn-raised btn-success round mx-1 align-self-end col-md-auto mb-0"
                  >
                    <i class="fa fa-plus"></i>
                    <span class="mx-1">{{ 'SALES.CUSTOMERS.ADD_FROM_POINT_OF_SALE' | translate }}</span>
                  </a>
                  <a
                    *ngIf="[Claims.CREATE_SALE_INVOICE] | authorize | async"
                    routerLink="/sales/invoices/create-regular-invoice"
                    class="btn btn-raised btn-success round mx-1 align-self-end col-md-auto mb-0"
                  >
                    <i class="fa fa-plus"></i>
                    <span class="mx-1">{{ 'SHARED.CONFIRM.ADD' | translate }}</span>
                  </a>
                </div>
              </div>
            </form>
          </div>
          <ng-container *ngIf="invoices$ | async; let invoices">
            <div class="col-md-12" *ngIf="invoices.length; else noItems">
              <div class="row">
                <div class="col-md-12 alert alert-light text-center" role="alert">
                  {{ 'SHARED.SEARCH.SEARCH_RESULTS' | translate }} ({{ (paginationInfo$ | async).total | toNumber }})
                  {{ 'SALES.CUSTOMERS.SALES_INVOICE' | translate }}
                </div>
                <div class="col-md-12">
                  <div class="table-responsive">
                    <table class="table text-center" id="customer-invoices-table-export-target-element">
                      <thead>
                        <tr>
                          <th scope="col">
                            <i class="fa fa-barcode"></i
                            ><span class="mx-1">{{ 'SALES.CUSTOMERS.CUSTOMER_DATA.INVOICE_NUMBER' | translate }}</span>
                          </th>
                          <th scope="col">{{ 'SALES.INVOICES.SALE_INVOICE_DATA.SALE_INVOICE_TYPE' | translate }}</th>
                          <th scope="col">{{ 'SALES.INVOICES.SALE_INVOICE_DATA.ORDER_NUMBER' | translate }}</th>
                          <th scope="col">
                            {{ 'SALES.INVOICES.SALE_INVOICE_DATA.SALE_INVOICE_ORDER_TYPE' | translate }}
                          </th>
                          <th scope="col">
                            {{ 'SALES.CUSTOMERS.CUSTOMER_DATA.LOCATION' | translate }}
                          </th>
                          <th scope="col">{{ 'SALES.CUSTOMERS.CUSTOMER_DATA.VALUE' | translate }}</th>
                          <th scope="col">{{ 'SHARED.SEARCH.CREATED_AT' | translate }}</th>
                          <th scope="col">{{ 'SALES.CUSTOMERS.CUSTOMER_DATA.USER' | translate }}</th>
                          <th scope="col">{{ 'SHARED.TOOLS' | translate }}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let invoice of invoices; trackBy: trackItems">
                          <th scope="row">{{ invoice.invoiceNum }}</th>
                          <td>
                            {{ invoice.saleInvoiceType.name | localize: invoice.saleInvoiceType.nameEn | async }}
                          </td>
                          <td>{{ invoice.orderNum }}</td>
                          <td>
                            {{
                              invoice.saleInvoiceOrderType?.name
                                | localize: invoice.saleInvoiceOrderType?.nameEn
                                | async
                            }}
                          </td>
                          <td>
                            {{ invoice.location.name | localize: invoice.location.nameEn | async }}
                          </td>
                          <td>
                            <span class="badge round bg-success">
                              {{ invoice.total | toDecimal }}
                            </span>
                          </td>
                          <td>
                            <span [ngbTooltip]="invoice.createdAt | date: 'time'">
                              {{ invoice.createdAt | date }}
                            </span>
                          </td>
                          <td>{{ invoice.user.name }}</td>

                          <td>
                            <a
                              *ngIf="
                                [
                                  Claims.CREATE_SALE_INVOICE,
                                  Claims.CREATE_MAINTENANCE_SALE_INVOICE,
                                  Claims.CREATE_CUSTOMER_OPENING_BALANCE_INVOICE,
                                  Claims.CREATE_POINT_OF_SALE_INVOICE
                                ]
                                  | authorize
                                  | async
                              "
                              class="info p-0"
                              [routerLink]="['/sales/invoices/view', invoice.id]"
                              [ngbTooltip]="'SALES.CUSTOMERS.CUSTOMER_DATA.DISPLAY_INVOICE' | translate"
                            >
                              <i class="fa fa-eye font-medium-3 mr-2"></i>
                            </a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div class="d-flex justify-content-between p-2">
                      <ngb-pagination
                        *ngIf="paginationInfo$ | async; let pagination"
                        maxSize="5"
                        [collectionSize]="pagination.total"
                        [pageSize]="pagination.pageSize"
                        [page]="pagination.page"
                        (pageChange)="pageChanged($event)"
                        boundaryLinks="true"
                      >
                        <ng-template ngbPaginationFirst>{{
                          'SHARED.PAGINATION.PAGINATION_FIRST' | translate
                        }}</ng-template>
                        <ng-template ngbPaginationPrevious>{{
                          'SHARED.PAGINATION.PAGINATION_PREVIOUS' | translate
                        }}</ng-template>
                        <ng-template ngbPaginationNext>{{
                          'SHARED.PAGINATION.PAGINATION_NEXT' | translate
                        }}</ng-template>
                        <ng-template ngbPaginationLast>{{
                          'SHARED.PAGINATION.PAGINATION_LAST' | translate
                        }}</ng-template>
                      </ngb-pagination>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- invoices list ends -->

<!-- no items template starts -->
<ng-template #noItems>
  <div class="col-md-12 alert alert-warning mt-3 text-center" role="alert">
    {{ 'SALES.CUSTOMERS.SEARCH_NOT_FOUND' | translate }}
    <a
      *ngIf="[Claims.CREATE_SALE_INVOICE] | authorize | async"
      routerLink="/sales/invoices/create-regular-invoice"
      class="btn btn-raised btn-success round"
    >
      <i class="fa fa-plus"></i>
      <span class="mx-1">{{ 'SHARED.CONFIRM.ADD' | translate }}</span>
    </a>
  </div>
</ng-template>
<!-- no items template ends -->

<!-- locations search starts -->
<app-locations-search [(visible)]="locationsListVisibility" (locations)="selectLocations($event)">
</app-locations-search>
<!-- locations search ends -->

<!-- customer invoices ends -->
