import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { AppHttpResponse } from 'shared';
import {
  AssetCategory,
  AssetType,
  AssetYearsOfUse,
  AssetExclusionReason,
  IdentityType,
  Nationality,
  PaymentDuration,
  PettyCashSpecification,
  PettyCashType,
  CustomerEngagementType,
  PurchaseRequestType,
  PurchaseRequestReceiveType,
  PurchaseOrderType,
  PurchaseOrderReceiveType,
  CustomerCategory,
  PrimaryAccount,
  OutgoingStockType,
  PettyCashRefundRequestType,
  City,
  IncomingStockType,
  JournalType,
  CustomerLogType,
  VendorLogType,
  LocationType,
  DepartmentType,
  DepartmentCategory,
  BankAccountLogType,
  SaleInvoiceType,
  PurchaseInvoiceType,
  MaintenanceStage,
  VariantCategory,
  SaleInvoiceOrderType,
  TimeZone,
  PrintPaperSize,
  Country,
  PhoneType,
  EmailType,
  MeridiemIndicator,
  PersonTitle,
  Gender,
  WorkType,
  ContractType,
  ContractDuration,
  ProbationPeriod,
  NoticePeriod,
  NationalIdentifierType,
  MaritalStatus,
  Religion,
  EducationLevel,
  VisaType,
  DependentType,
  LeadStatus,
  ManagerialLevel,
  PositionType,
  AssignmentStatus,
  EmployeePaymentMethod,
  PayrollElementClassification,
  PayrollElementType,
  OvertimeRate,
  QuotationStatus,
  PayrollStatus,
  AnnualLeave,
  SickLeaveType,
  LoanSettlementType,
  OtherEarningType,
  DeductionType,
  PayrollElementNature,
  EmployeeDeductionType,
  LeaveType,
  GosiRegType,
  GosiCoverageType,
  EosReason,
  AnnualLeaveLogType,
  ProjectStatus,
  ProjectResourceType,
  ProjectLogType,
  ProjectRevenueType,
  PosPrinterAdjustmentType,
  LoanPaymentType,
  ProjectResourceTypeSource,
  GovServiceRequestStatus,
  GovServiceRequestPaymentMethod,
} from 'lookups/models';

/**
 * Provides functionality for get all lookups.
 */
@Injectable()
export class LookupsService {
  /**
   * The relative route for the lookups.
   *
   * No leading or trailing slashes required.
   */
  private lookupsApi = 'lookups';

  constructor(private http: HttpClient) {}

  /** Returns all asset categories. */
  public getAllAssetCategories(): Observable<AppHttpResponse<AssetCategory[]>> {
    return this.http.get<AppHttpResponse<AssetCategory[]>>(`${this.lookupsApi}/asset-categories`);
  }

  /** Returns all asset types. */
  public getAllAssetTypes(): Observable<AppHttpResponse<AssetType[]>> {
    return this.http.get<AppHttpResponse<AssetType[]>>(`${this.lookupsApi}/asset-types`);
  }

  /** Returns all asset years of use. */
  public getAllAssetYearsOfUse(): Observable<AppHttpResponse<AssetYearsOfUse[]>> {
    return this.http.get<AppHttpResponse<AssetYearsOfUse[]>>(`${this.lookupsApi}/asset-years-of-use`);
  }

  /** Returns all asset years of use by asset category id.
   * @param assetCategoryId The id of asset category.
   */
  public getAllAssetYearsOfUseByAssetCategoryId(
    assetCategoryId: number
  ): Observable<AppHttpResponse<AssetYearsOfUse[]>> {
    return this.http.get<AppHttpResponse<AssetYearsOfUse[]>>(
      `${this.lookupsApi}/asset-years-of-use/${assetCategoryId}`
    );
  }

  /** Returns all asset exclusion reasons. */
  public getAllAssetExclusionReasons(): Observable<AppHttpResponse<AssetExclusionReason[]>> {
    return this.http.get<AppHttpResponse<AssetExclusionReason[]>>(`${this.lookupsApi}/asset-exclusion-reasons`);
  }

  /** Returns all identity types. */
  public getAllIdentityTypes(): Observable<AppHttpResponse<IdentityType[]>> {
    return this.http.get<AppHttpResponse<IdentityType[]>>(`${this.lookupsApi}/identity-types`);
  }

  /** Returns all nationalities. */
  public getAllNationalities(): Observable<AppHttpResponse<Nationality[]>> {
    return this.http.get<AppHttpResponse<Nationality[]>>(`${this.lookupsApi}/nationalities`);
  }

  /** Returns all payment durations. */
  public getAllPaymentDurations(): Observable<AppHttpResponse<PaymentDuration[]>> {
    return this.http.get<AppHttpResponse<PaymentDuration[]>>(`${this.lookupsApi}/payment-durations`);
  }

  /** Returns all petty cash specifications. */
  public getAllPettyCashSpecifications(): Observable<AppHttpResponse<PettyCashSpecification[]>> {
    return this.http.get<AppHttpResponse<PettyCashSpecification[]>>(`${this.lookupsApi}/pettyCash-specifications`);
  }

  /** Returns all petty cash types. */
  public getAllPettyCashTypes(): Observable<AppHttpResponse<PettyCashType[]>> {
    return this.http.get<AppHttpResponse<PettyCashType[]>>(`${this.lookupsApi}/pettyCash-types`);
  }

  /** Returns all customer engagement types. */
  public getAllCustomerEngagementTypes(): Observable<AppHttpResponse<CustomerEngagementType[]>> {
    return this.http.get<AppHttpResponse<CustomerEngagementType[]>>(`${this.lookupsApi}/customer-engagement-types`);
  }

  /** Returns all purchase request types. */
  public getAllPurchaseRequestTypes(): Observable<AppHttpResponse<PurchaseRequestType[]>> {
    return this.http.get<AppHttpResponse<PurchaseRequestType[]>>(`${this.lookupsApi}/purchase-request-types`);
  }

  /** Returns all purchase request receive types. */
  public getAllPurchaseRequestReceiveTypes(): Observable<AppHttpResponse<PurchaseRequestReceiveType[]>> {
    return this.http.get<AppHttpResponse<PurchaseRequestReceiveType[]>>(
      `${this.lookupsApi}/purchase-request-receive-types`
    );
  }

  /** Returns all purchase order types. */
  public getAllPurchaseOrderTypes(): Observable<AppHttpResponse<PurchaseOrderType[]>> {
    return this.http.get<AppHttpResponse<PurchaseOrderType[]>>(`${this.lookupsApi}/purchase-order-types`);
  }

  /** Returns all purchase order receive types. */
  public getAllPurchaseOrderReceiveTypes(): Observable<AppHttpResponse<PurchaseOrderReceiveType[]>> {
    return this.http.get<AppHttpResponse<PurchaseOrderReceiveType[]>>(
      `${this.lookupsApi}/purchase-order-receive-types`
    );
  }

  /**
   * Returns all customer categories.
   */
  public getAllCustomerCategories(): Observable<AppHttpResponse<CustomerCategory[]>> {
    return this.http.get<AppHttpResponse<CustomerCategory[]>>(`${this.lookupsApi}/customer-categories`);
  }

  /**
   * Returns all primary accounts.
   */
  public getAllPrimaryAccounts(): Observable<AppHttpResponse<PrimaryAccount[]>> {
    return this.http.get<AppHttpResponse<PrimaryAccount[]>>(`${this.lookupsApi}/primary-accounts`);
  }

  /** Returns all outgoing stock types. */
  public getAllOutgoingStockTypes(): Observable<AppHttpResponse<OutgoingStockType[]>> {
    return this.http.get<AppHttpResponse<OutgoingStockType[]>>(`${this.lookupsApi}/outgoing-stock-types`);
  }

  /** Returns all incoming stock types. */
  public getAllIncomingStockTypes(): Observable<AppHttpResponse<IncomingStockType[]>> {
    return this.http.get<AppHttpResponse<IncomingStockType[]>>(`${this.lookupsApi}/incoming-stock-types`);
  }

  /** Returns all petty cash refund request types. */
  public getAllPettyCashRefundRequestTypes(): Observable<AppHttpResponse<PettyCashRefundRequestType[]>> {
    return this.http.get<AppHttpResponse<PettyCashRefundRequestType[]>>(
      `${this.lookupsApi}/pettyCash-refund-request-types`
    );
  }

  /** Returns all cities. */
  public getAllCities(): Observable<AppHttpResponse<City[]>> {
    return this.http.get<AppHttpResponse<City[]>>(`${this.lookupsApi}/cities`);
  }

  /** Returns all journal types. */
  public getAllJournalTypes(): Observable<AppHttpResponse<JournalType[]>> {
    return this.http.get<AppHttpResponse<JournalType[]>>(`${this.lookupsApi}/journal-types`);
  }

  /** Returns all customer log types. */
  public getAllCustomerLogTypes(): Observable<AppHttpResponse<CustomerLogType[]>> {
    return this.http.get<AppHttpResponse<CustomerLogType[]>>(`${this.lookupsApi}/customer-log-types`);
  }

  /** Returns all vendor log types. */
  public getAllVendorLogTypes(): Observable<AppHttpResponse<VendorLogType[]>> {
    return this.http.get<AppHttpResponse<VendorLogType[]>>(`${this.lookupsApi}/vendor-log-types`);
  }

  /** Returns customer payment log types. */
  public getCustomerPaymentLogTypes(): Observable<AppHttpResponse<CustomerLogType[]>> {
    return this.http.get<AppHttpResponse<CustomerLogType[]>>(`${this.lookupsApi}/customer-payment-log-types`);
  }

  /** Returns all customer payment log types including advance payments. */
  public getAllCustomerPaymentLogTypes(): Observable<AppHttpResponse<CustomerLogType[]>> {
    return this.http.get<AppHttpResponse<CustomerLogType[]>>(`${this.lookupsApi}/all-customer-payment-log-types`);
  }

  /** Returns all customer advance payment log types. */
  public getAllCustomerAdvancePaymentLogTypes(): Observable<AppHttpResponse<CustomerLogType[]>> {
    return this.http.get<AppHttpResponse<CustomerLogType[]>>(`${this.lookupsApi}/customer-advance-payment-log-types`);
  }

  /** Returns all vendor advance payment log types. */
  public getAllVendorAdvancePaymentLogTypes(): Observable<AppHttpResponse<VendorLogType[]>> {
    return this.http.get<AppHttpResponse<VendorLogType[]>>(`${this.lookupsApi}/vendor-advance-payment-log-types`);
  }

  /** Returns vendor payment log types. */
  public getVendorPaymentLogTypes(): Observable<AppHttpResponse<VendorLogType[]>> {
    return this.http.get<AppHttpResponse<VendorLogType[]>>(`${this.lookupsApi}/vendor-payment-log-types`);
  }

  /** Returns all vendor payment log types including advance payments. */
  public getAllVendorPaymentLogTypes(): Observable<AppHttpResponse<VendorLogType[]>> {
    return this.http.get<AppHttpResponse<VendorLogType[]>>(`${this.lookupsApi}/all-vendor-payment-log-types`);
  }

  /** Returns all location types. */
  public getAllLocationTypes(): Observable<AppHttpResponse<LocationType[]>> {
    return this.http.get<AppHttpResponse<LocationType[]>>(`${this.lookupsApi}/location-types`);
  }

  /** Returns all bank account log types. */
  public getAllBankAccountLogTypes(): Observable<AppHttpResponse<BankAccountLogType[]>> {
    return this.http.get<AppHttpResponse<BankAccountLogType[]>>(`${this.lookupsApi}/bank-account-log-types`);
  }

  /** Returns all maintenance stages. */
  public getAllMaintenanceStages(): Observable<AppHttpResponse<MaintenanceStage[]>> {
    return this.http.get<AppHttpResponse<MaintenanceStage[]>>(`${this.lookupsApi}/maintenance-stages`);
  }

  /** Returns all sale invoice types. */
  public getAllSaleInvoiceTypes(): Observable<AppHttpResponse<SaleInvoiceType[]>> {
    return this.http.get<AppHttpResponse<SaleInvoiceType[]>>(`${this.lookupsApi}/sale-invoice-types`);
  }

  /** Returns all sale invoice order types. */
  public getAllSaleInvoiceOrderTypes(): Observable<AppHttpResponse<SaleInvoiceOrderType[]>> {
    return this.http.get<AppHttpResponse<SaleInvoiceOrderType[]>>(`${this.lookupsApi}/sale-invoice-order-types`);
  }

  /** Returns all purchase invoice types. */
  public getAllPurchaseInvoiceTypes(): Observable<AppHttpResponse<PurchaseInvoiceType[]>> {
    return this.http.get<AppHttpResponse<PurchaseInvoiceType[]>>(`${this.lookupsApi}/purchase-invoice-types`);
  }

  /** Returns all variant categories. */
  public getAllVariantCategories(): Observable<AppHttpResponse<VariantCategory[]>> {
    return this.http.get<AppHttpResponse<VariantCategory[]>>(`${this.lookupsApi}/variant-categories`);
  }

  /** Returns all time zones. */
  public getAllTimeZones(): Observable<AppHttpResponse<TimeZone[]>> {
    return this.http.get<AppHttpResponse<TimeZone[]>>(`${this.lookupsApi}/time-zones`);
  }

  /** Returns all print paper sizes. */
  public getAllPrintPaperSizes(): Observable<AppHttpResponse<PrintPaperSize[]>> {
    return this.http.get<AppHttpResponse<PrintPaperSize[]>>(`${this.lookupsApi}/print-paper-sizes`);
  }

  /** Returns all national identifier types. */
  public getAllNationalIdentifierTypes(): Observable<AppHttpResponse<NationalIdentifierType[]>> {
    return this.http.get<AppHttpResponse<NationalIdentifierType[]>>(`${this.lookupsApi}/national-identifier-types`);
  }

  /** Returns all marital statuses. */
  public getAllMaritalStatuses(): Observable<AppHttpResponse<MaritalStatus[]>> {
    return this.http.get<AppHttpResponse<MaritalStatus[]>>(`${this.lookupsApi}/marital-statuses`);
  }

  /** Returns all religions. */
  public getAllReligions(): Observable<AppHttpResponse<Religion[]>> {
    return this.http.get<AppHttpResponse<Religion[]>>(`${this.lookupsApi}/religions`);
  }

  /** Returns all education levels. */
  public getAllEducationLevels(): Observable<AppHttpResponse<EducationLevel[]>> {
    return this.http.get<AppHttpResponse<EducationLevel[]>>(`${this.lookupsApi}/education-levels`);
  }

  /** Returns all visa types. */
  public getAllVisaTypes(): Observable<AppHttpResponse<VisaType[]>> {
    return this.http.get<AppHttpResponse<VisaType[]>>(`${this.lookupsApi}/visa-types`);
  }

  /** Returns all dependent types. */
  public getAllDependentTypes(): Observable<AppHttpResponse<DependentType[]>> {
    return this.http.get<AppHttpResponse<DependentType[]>>(`${this.lookupsApi}/dependent-types`);
  }

  /** Returns all work types. */
  public getAllWorkTypes(): Observable<AppHttpResponse<WorkType[]>> {
    return this.http.get<AppHttpResponse<WorkType[]>>(`${this.lookupsApi}/work-types`);
  }

  /** Returns all contract types. */
  public getAllContractTypes(): Observable<AppHttpResponse<ContractType[]>> {
    return this.http.get<AppHttpResponse<ContractType[]>>(`${this.lookupsApi}/contract-types`);
  }

  /** Returns all contract durations. */
  public getAllContractDurations(): Observable<AppHttpResponse<ContractDuration[]>> {
    return this.http.get<AppHttpResponse<ContractDuration[]>>(`${this.lookupsApi}/contract-durations`);
  }

  /** Returns all probation periods. */
  public getAllProbationPeriods(): Observable<AppHttpResponse<ProbationPeriod[]>> {
    return this.http.get<AppHttpResponse<ProbationPeriod[]>>(`${this.lookupsApi}/probation-periods`);
  }

  /** Returns all Notice Periods. */
  public getAllNoticePeriods(): Observable<AppHttpResponse<NoticePeriod[]>> {
    return this.http.get<AppHttpResponse<NoticePeriod[]>>(`${this.lookupsApi}/notice-periods`);
  }

  /** Returns all countries. */
  public getAllCountries(): Observable<AppHttpResponse<Country[]>> {
    return this.http.get<AppHttpResponse<Country[]>>(`${this.lookupsApi}/countries`);
  }

  /** Returns all phone types. */
  public getAllPhoneTypes(): Observable<AppHttpResponse<PhoneType[]>> {
    return this.http.get<AppHttpResponse<PhoneType[]>>(`${this.lookupsApi}/phone-types`);
  }

  /** Returns all email types. */
  public getAllEmailTypes(): Observable<AppHttpResponse<EmailType[]>> {
    return this.http.get<AppHttpResponse<EmailType[]>>(`${this.lookupsApi}/email-types`);
  }

  /** Returns all meridiem indicators. */
  public getAllMeridiemIndicators(): Observable<AppHttpResponse<MeridiemIndicator[]>> {
    return this.http.get<AppHttpResponse<MeridiemIndicator[]>>(`${this.lookupsApi}/meridiem-indicators`);
  }

  /** Returns all person titles. */
  public getAllPersonTitles(): Observable<AppHttpResponse<PersonTitle[]>> {
    return this.http.get<AppHttpResponse<PersonTitle[]>>(`${this.lookupsApi}/person-titles`);
  }

  /** Returns all genders. */
  public getAllGenders(): Observable<AppHttpResponse<Gender[]>> {
    return this.http.get<AppHttpResponse<Gender[]>>(`${this.lookupsApi}/genders`);
  }

  /** Returns all Lead statuses . */
  public getAllLeadStatuses(): Observable<AppHttpResponse<LeadStatus[]>> {
    return this.http.get<AppHttpResponse<LeadStatus[]>>(`${this.lookupsApi}/lead-statuses`);
  }

  /** Returns all department types. */
  public getAllDepartmentTypes(): Observable<AppHttpResponse<DepartmentType[]>> {
    return this.http.get<AppHttpResponse<DepartmentType[]>>(`${this.lookupsApi}/department-types`);
  }

  /** Returns all department categories. */
  public getAllDepartmentCategories(): Observable<AppHttpResponse<DepartmentCategory[]>> {
    return this.http.get<AppHttpResponse<DepartmentCategory[]>>(`${this.lookupsApi}/department-categories`);
  }

  /** Returns all managerial levels. */
  public getAllManagerialLevels(): Observable<AppHttpResponse<ManagerialLevel[]>> {
    return this.http.get<AppHttpResponse<ManagerialLevel[]>>(`${this.lookupsApi}/managerial-levels`);
  }

  /** Returns all position types. */
  public getAllPositionTypes(): Observable<AppHttpResponse<PositionType[]>> {
    return this.http.get<AppHttpResponse<PositionType[]>>(`${this.lookupsApi}/position-types`);
  }

  /** Returns all assignment statuses. */
  public getAllAssignmentStatuses(): Observable<AppHttpResponse<AssignmentStatus[]>> {
    return this.http.get<AppHttpResponse<AssignmentStatus[]>>(`${this.lookupsApi}/assignment-statuses`);
  }

  /** Returns all employee payment methods. */
  public getAllEmployeePaymentMethods(): Observable<AppHttpResponse<EmployeePaymentMethod[]>> {
    return this.http.get<AppHttpResponse<EmployeePaymentMethod[]>>(`${this.lookupsApi}/employee-payment-methods`);
  }

  /** Returns all payroll element classifications. */
  public getAllPayrollElementClassifications(): Observable<AppHttpResponse<PayrollElementClassification[]>> {
    return this.http.get<AppHttpResponse<PayrollElementClassification[]>>(
      `${this.lookupsApi}/payroll-element-classifications`
    );
  }

  /** Returns all payroll element types. */
  public getAllPayrollElementTypes(): Observable<AppHttpResponse<PayrollElementType[]>> {
    return this.http.get<AppHttpResponse<PayrollElementType[]>>(`${this.lookupsApi}/payroll-element-types`);
  }

  /** Returns all overtime rates. */
  public getAllOvertimeRates(): Observable<AppHttpResponse<OvertimeRate[]>> {
    return this.http.get<AppHttpResponse<OvertimeRate[]>>(`${this.lookupsApi}/overtime-rates`);
  }

  /** Returns all quotation statuses. */
  public getAllQuotationStatuses(): Observable<AppHttpResponse<QuotationStatus[]>> {
    return this.http.get<AppHttpResponse<QuotationStatus[]>>(`${this.lookupsApi}/quotation-statuses`);
  }

  /** Returns all annual leaves. */
  public getAllAnnualLeaves(): Observable<AppHttpResponse<AnnualLeave[]>> {
    return this.http.get<AppHttpResponse<AnnualLeave[]>>(`${this.lookupsApi}/annual-leaves`);
  }

  /** Returns all sick leave types. */
  public getAllSickLeaveTypes(): Observable<AppHttpResponse<SickLeaveType[]>> {
    return this.http.get<AppHttpResponse<SickLeaveType[]>>(`${this.lookupsApi}/sick-leave-types`);
  }

  /** Returns all loan settlement types. */
  public getAllLoanSettlementTypes(): Observable<AppHttpResponse<LoanSettlementType[]>> {
    return this.http.get<AppHttpResponse<LoanSettlementType[]>>(`${this.lookupsApi}/loan-settlement-types`);
  }

  /** Returns all other earning types. */
  public getAllOtherEarningTypes(): Observable<AppHttpResponse<OtherEarningType[]>> {
    return this.http.get<AppHttpResponse<OtherEarningType[]>>(`${this.lookupsApi}/other-earning-types`);
  }

  /** Returns all deduction types. */
  public getAllDeductionTypes(): Observable<AppHttpResponse<DeductionType[]>> {
    return this.http.get<AppHttpResponse<DeductionType[]>>(`${this.lookupsApi}/deduction-types`);
  }

  /** Returns payroll element natures. */
  public getAllPayrollElementNatures(): Observable<AppHttpResponse<PayrollElementNature[]>> {
    return this.http.get<AppHttpResponse<PayrollElementNature[]>>(`${this.lookupsApi}/payroll-element-natures`);
  }

  /** Returns employee deduction types. */
  public getAllEmployeeDeductionTypes(): Observable<AppHttpResponse<EmployeeDeductionType[]>> {
    return this.http.get<AppHttpResponse<EmployeeDeductionType[]>>(`${this.lookupsApi}/employee-deduction-types`);
  }

  /** Returns all leave types. */
  public getAllLeaveTypes(): Observable<AppHttpResponse<LeaveType[]>> {
    return this.http.get<AppHttpResponse<LeaveType[]>>(`${this.lookupsApi}/leave-types`);
  }

  /** Returns all gosi reg types. */
  public getAllGosiRegTypes(): Observable<AppHttpResponse<GosiRegType[]>> {
    return this.http.get<AppHttpResponse<GosiRegType[]>>(`${this.lookupsApi}/gosi-reg-types`);
  }

  /** Returns all gosi coverage types. */
  public getAllGosiCoverageTypes(): Observable<AppHttpResponse<GosiCoverageType[]>> {
    return this.http.get<AppHttpResponse<GosiCoverageType[]>>(`${this.lookupsApi}/gosi-coverage-types`);
  }

  /** Returns all end of service reason. */
  public getAllEosReason(): Observable<AppHttpResponse<EosReason[]>> {
    return this.http.get<AppHttpResponse<EosReason[]>>(`${this.lookupsApi}/end-of-service-reasons`);
  }

  /** Returns all annual leave log types. */
  public getAllAnnualLeaveLogTypes(): Observable<AppHttpResponse<AnnualLeaveLogType[]>> {
    return this.http.get<AppHttpResponse<AnnualLeaveLogType[]>>(`${this.lookupsApi}/annual-leave-log-types`);
  }

  /** Returns all payroll statuses. */
  public getAllPayrollStatuses(): Observable<AppHttpResponse<PayrollStatus[]>> {
    return this.http.get<AppHttpResponse<PayrollStatus[]>>(`${this.lookupsApi}/payroll-statuses`);
  }

  /** Returns all pos printer adjustment types. */
  public getAllPosPrinterAdjustmentTypes(): Observable<AppHttpResponse<PosPrinterAdjustmentType[]>> {
    return this.http.get<AppHttpResponse<PosPrinterAdjustmentType[]>>(
      `${this.lookupsApi}/pos-printer-adjustment-types`
    );
  }

  /** Returns all loan payment types. */
  public getAllLoanPaymentTypes(): Observable<AppHttpResponse<LoanPaymentType[]>> {
    return this.http.get<AppHttpResponse<LoanPaymentType[]>>(`${this.lookupsApi}/loan-payment-types`);
  }

  /** Returns all project resource types. */
  public getAllProjectResourceTypes(): Observable<AppHttpResponse<ProjectResourceType[]>> {
    return this.http.get<AppHttpResponse<ProjectResourceType[]>>(`${this.lookupsApi}/project-resource-types`);
  }

  /** Returns all project resource type sources. */
  public getAllProjectResourceTypeSources(): Observable<AppHttpResponse<ProjectResourceTypeSource[]>> {
    return this.http.get<AppHttpResponse<ProjectResourceTypeSource[]>>(
      `${this.lookupsApi}/project-resource-type-sources`
    );
  }

  /** Returns all project statuses. */
  public getAllProjectStatuses(): Observable<AppHttpResponse<ProjectStatus[]>> {
    return this.http.get<AppHttpResponse<ProjectStatus[]>>(`${this.lookupsApi}/project-statuses`);
  }

  /** Returns all project log types. */
  public getAllProjectLogTypes(): Observable<AppHttpResponse<ProjectLogType[]>> {
    return this.http.get<AppHttpResponse<ProjectLogType[]>>(`${this.lookupsApi}/project-log-types`);
  }

  /** Returns all project revenue types. */
  public getAllProjectRevenueTypes(): Observable<AppHttpResponse<ProjectRevenueType[]>> {
    return this.http.get<AppHttpResponse<ProjectRevenueType[]>>(`${this.lookupsApi}/project-revenue-types`);
  }

  /**
   * Returns all gov service request statuses.
   */
  public getAllGovServiceRequestStatuses(): Observable<AppHttpResponse<GovServiceRequestStatus[]>> {
    return this.http.get<AppHttpResponse<GovServiceRequestStatus[]>>(`${this.lookupsApi}/gov-service-request-statuses`);
  }

  /**
   * Returns all gov service request payment methods.
   */
  public getAllGovServiceRequestPaymentMethods(): Observable<AppHttpResponse<GovServiceRequestPaymentMethod[]>> {
    return this.http.get<AppHttpResponse<GovServiceRequestPaymentMethod[]>>(
      `${this.lookupsApi}/gov-service-request-payment-methods`
    );
  }
}
