import { cloneDeep, findIndex, remove } from 'lodash';

import { ProductsActionType, ProductsActions } from 'stores/store/actions';
import { ProductState } from 'stores/store/states';

/**
 * The initial state from which the state starts.
 */
const initialState: ProductState = {
  data: [],
  discountData: 0,
  productSalesDiscountsData: [],
  productsForSalesScreen: [],
  paginationInfo: {
    total: 0,
    page: 1,
    pageSize: 0,
    count: 0,
  },
  paginationProductSalesDiscountsInfo: {
    total: 0,
    page: 1,
    pageSize: 0,
    count: 0,
  },
  error: null,
  isSearching: false,
  isSearchCompleted: false,
  isProductSalesDiscountsSearching: false,
  isProductSalesDiscountsSearchCompleted: false,
  isProductsForSalesScreenSearching: false,
  isProductsForSalesScreenSearchCompleted: false,
  selectedProduct: null,
  selectedProductSalesDiscount: null,
  isFinding: false,
  isFindCompleted: false,
  selectedProductByCodeOrBarcode: null,
  isFindingByCodeOrBarcode: false,
  isFindByCodeOrBarcodeCompleted: false,
  isCreating: false,
  isCreateCompleted: false,
  isUpdating: false,
  isUpdateCompleted: false,
  isDeleting: false,
  isDeleteCompleted: false,
  productStock: null,
  isProductStockLoaded: false,
  isProductStockLoading: false,
  isProductDiscountsCreating: false,
  isProductDiscountsCreateCompleted: false,
  isProductSalesDiscountUpdating: false,
  isProductSalesDiscountUpdateCompleted: false,
  isProductSalesDiscountDeleting: false,
  isProductSalesDiscountDeleteCompleted: false,
  isProductUnitOfMeasureRateUpdating: false,
  isProductUnitOfMeasureRateUpdateCompleted: false,
  isProductUnitOfMeasureRateDeleting: false,
  isProductUnitOfMeasureRateDeleteCompleted: false,
};

/**
 * The reducer function that is called in each action dispatch against the state.
 * @param state The current state.
 * @param action The action that will affect the state.
 */
export function productsReducer(state: ProductState = initialState, action: ProductsActions): ProductState {
  switch (action.type) {
    //#region SEARCH_PRODUCTS

    case ProductsActionType.SEARCH_PRODUCTS:
    case ProductsActionType.SEARCH_PRODUCTS_WITHOUT_PAGINATION: {
      return {
        ...state,
        isSearching: true,
        isSearchCompleted: false,
        error: null,
      };
    }

    case ProductsActionType.SEARCH_PRODUCTS_FAIL: {
      return {
        ...state,
        error: action.payload,
        isSearching: false,
        isSearchCompleted: false,
      };
    }

    case ProductsActionType.SEARCH_PRODUCTS_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        paginationInfo: {
          ...action.payload.meta,
        },
        error: null,
        isSearching: false,
        isSearchCompleted: true,
      };
    }

    //#endregion SEARCH_PRODUCTS

    //#region SEARCH_PRODUCT_SALES_DISCOUNTS

    case ProductsActionType.SEARCH_PRODUCT_SALES_DISCOUNTS: {
      return {
        ...state,
        isProductSalesDiscountsSearching: true,
        isProductSalesDiscountsSearchCompleted: false,
        error: null,
      };
    }

    case ProductsActionType.SEARCH_PRODUCT_SALES_DISCOUNTS_FAIL: {
      return {
        ...state,
        error: action.payload,
        isProductSalesDiscountsSearching: false,
        isProductSalesDiscountsSearchCompleted: false,
      };
    }

    case ProductsActionType.SEARCH_PRODUCT_SALES_DISCOUNTS_SUCCESS: {
      return {
        ...state,
        productSalesDiscountsData: action.payload.data,
        paginationProductSalesDiscountsInfo: {
          ...action.payload.meta,
        },
        error: null,
        isProductSalesDiscountsSearching: false,
        isProductSalesDiscountsSearchCompleted: true,
      };
    }

    //#endregion SEARCH_PRODUCT_SALES_DISCOUNTS

    //#region SEARCH_PRODUCTS_FOR_SALES_SCREEN

    case ProductsActionType.SEARCH_PRODUCTS_FOR_SALES_SCREEN: {
      return {
        ...state,
        isProductsForSalesScreenSearching: true,
        isProductsForSalesScreenSearchCompleted: false,
        error: null,
      };
    }

    case ProductsActionType.SEARCH_PRODUCTS_FOR_SALES_SCREEN_FAIL: {
      return {
        ...state,
        error: action.payload,
        isProductsForSalesScreenSearching: false,
        isProductsForSalesScreenSearchCompleted: false,
      };
    }

    case ProductsActionType.SEARCH_PRODUCTS_FOR_SALES_SCREEN_SUCCESS: {
      return {
        ...state,
        productsForSalesScreen: action.payload.data,
        error: null,
        isProductsForSalesScreenSearching: false,
        isProductsForSalesScreenSearchCompleted: true,
      };
    }

    //#endregion SEARCH_PRODUCTS_FOR_SALES_SCREEN

    //#region FIND_PRODUCT

    case ProductsActionType.FIND_PRODUCT: {
      return {
        ...state,
        selectedProduct: null,
        error: null,
        isFinding: true,
        isFindCompleted: false,
      };
    }

    case ProductsActionType.FIND_PRODUCT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isFinding: false,
        isFindCompleted: false,
      };
    }

    case ProductsActionType.FIND_PRODUCT_SUCCESS: {
      let products = [...state.data];
      const productIndex = findIndex(products, (product) => product.id === action.payload.data.id);

      /* If product was found, update it. */
      if (productIndex >= 0) {
        products[productIndex] = action.payload.data;
      } else {
        /* else, insert it to the beginning of the array. */
        products = [action.payload.data, ...products];
      }

      return {
        ...state,
        data: products,
        selectedProduct: action.payload.data,
        error: null,
        isFinding: false,
        isFindCompleted: true,
      };
    }

    //#endregion FIND_PRODUCT

    //#region FIND_PRODUCT_BY_CODE_OR_BARCODE

    case ProductsActionType.FIND_PRODUCT_BY_CODE_OR_BARCODE: {
      return {
        ...state,
        isFindingByCodeOrBarcode: true,
        isFindByCodeOrBarcodeCompleted: false,
        error: null,
      };
    }

    case ProductsActionType.FIND_PRODUCT_BY_CODE_OR_BARCODE_FAIL: {
      return {
        ...state,
        error: action.payload,
        isFindingByCodeOrBarcode: false,
        isFindByCodeOrBarcodeCompleted: false,
      };
    }

    case ProductsActionType.FIND_PRODUCT_BY_CODE_OR_BARCODE_SUCCESS: {
      let products = [...state.data];
      const productIndex = findIndex(products, (product) => product.id === action.payload.data.id);

      /* If product was found, update it. */
      if (productIndex >= 0) {
        products[productIndex] = action.payload.data;
      } else {
        /* else, insert it to the beginning of the array. */
        products = [action.payload.data, ...products];
      }

      return {
        ...state,
        data: products,
        selectedProductByCodeOrBarcode: action.payload.data,
        error: null,
        isFindingByCodeOrBarcode: false,
        isFindByCodeOrBarcodeCompleted: true,
      };
    }

    //#endregion FIND_PRODUCT_BY_CODE_OR_BARCODE

    //#region CREATE_PRODUCT

    case ProductsActionType.CREATE_PRODUCT: {
      return {
        ...state,
        isCreating: true,
        isCreateCompleted: false,
        error: null,
      };
    }

    case ProductsActionType.CREATE_PRODUCT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isCreating: false,
        isCreateCompleted: false,
      };
    }

    case ProductsActionType.CREATE_PRODUCT_SUCCESS: {
      return {
        ...state,
        data: [action.payload.data, ...state.data],
        selectedProduct: action.payload.data,
        error: null,
        isCreating: false,
        isCreateCompleted: true,
      };
    }

    //#endregion CREATE_PRODUCT

    //#region UPDATE_PRODUCT

    case ProductsActionType.UPDATE_PRODUCT:
    case ProductsActionType.UPDATE_PRODUCT_PHOTO:
    case ProductsActionType.DELETE_PRODUCT_PHOTO: {
      return {
        ...state,
        isUpdating: true,
        isUpdateCompleted: false,
        error: null,
      };
    }

    case ProductsActionType.UPDATE_PRODUCT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isUpdating: false,
        isUpdateCompleted: false,
      };
    }

    case ProductsActionType.UPDATE_PRODUCT_SUCCESS: {
      let products = [...state.data];
      const productIndex = findIndex(products, (product) => product.id === action.payload.data.id);

      /* If product was found, update it. */
      if (productIndex >= 0) {
        products[productIndex] = action.payload.data;
      } else {
        /* else, insert it to the beginning of the array. */
        products = [action.payload.data, ...products];
      }

      return {
        ...state,
        data: products,
        selectedProduct: action.payload.data,
        error: null,
        isUpdating: false,
        isUpdateCompleted: true,
      };
    }

    //#endregion UPDATE_PRODUCT

    //#region DELETE_PRODUCT

    case ProductsActionType.DELETE_PRODUCT: {
      return {
        ...state,
        isDeleting: true,
        isDeleteCompleted: false,
        error: null,
      };
    }

    case ProductsActionType.DELETE_PRODUCT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isDeleting: false,
        isDeleteCompleted: false,
      };
    }

    case ProductsActionType.DELETE_PRODUCT_SUCCESS: {
      const products = [...state.data];

      /* Remove the deleted product from the array. */
      remove(products, (product) => product.id === action.payload.data.id);

      return {
        ...state,
        data: products,
        selectedProduct: action.payload.data,
        error: null,
        isDeleting: false,
        isDeleteCompleted: true,
      };
    }
    //#endregion DELETE_PRODUCT

    //#region GET_PRODUCT_STOCKS_BY_PRODUCT_ID:

    case ProductsActionType.GET_PRODUCT_STOCKS_BY_PRODUCT_ID: {
      return {
        ...state,
        isProductStockLoading: true,
      };
    }

    case ProductsActionType.GET_PRODUCT_STOCKS_BY_PRODUCT_ID_FAIL: {
      return {
        ...state,
        error: action.payload,
        productStock: null,
        isProductStockLoading: false,
        isProductStockLoaded: false,
      };
    }

    case ProductsActionType.GET_PRODUCT_STOCKS_BY_PRODUCT_ID_SUCCESS: {
      return {
        ...state,
        productStock: action.payload.data,
        error: null,
        isProductStockLoading: false,
        isProductStockLoaded: true,
      };
    }
    //#endregion GET_PRODUCT_STOCKS_BY_PRODUCT_ID:

    //#region CREATE_PRODUCT_DISCOUNTS
    case ProductsActionType.CREATE_PRODUCT_DISCOUNTS: {
      return {
        ...state,
        isProductDiscountsCreating: true,
        isProductDiscountsCreateCompleted: false,
        error: null,
      };
    }

    case ProductsActionType.CREATE_PRODUCT_DISCOUNTS_FAIL: {
      return {
        ...state,
        error: action.payload,
        isProductDiscountsCreating: false,
        isProductDiscountsCreateCompleted: false,
      };
    }

    case ProductsActionType.CREATE_PRODUCT_DISCOUNTS_SUCCESS: {
      return {
        ...state,
        discountData: action.payload.data,
        error: null,
        isProductDiscountsCreating: false,
        isProductDiscountsCreateCompleted: true,
      };
    }

    //#endregion CREATE_PRODUCT_DISCOUNTS

    //#region UPDATE_PRODUCT_SALES_DISCOUNT

    case ProductsActionType.UPDATE_PRODUCT_SALES_DISCOUNT: {
      return {
        ...state,
        isProductSalesDiscountUpdating: true,
        isProductSalesDiscountUpdateCompleted: false,
        error: null,
      };
    }

    case ProductsActionType.UPDATE_PRODUCT_SALES_DISCOUNT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isProductSalesDiscountUpdating: false,
        isProductSalesDiscountUpdateCompleted: false,
      };
    }

    case ProductsActionType.UPDATE_PRODUCT_SALES_DISCOUNT_SUCCESS: {
      const selectedProduct = cloneDeep(state.selectedProduct);
      selectedProduct.productSalesDiscounts.push(action.payload.data);
      return {
        ...state,
        selectedProduct,
        error: null,
        isProductSalesDiscountUpdating: false,
        isProductSalesDiscountUpdateCompleted: true,
      };
    }

    //#endregion UPDATE_PRODUCT_SALES_DISCOUNT

    //#region DELETE_PRODUCT_SALES_DISCOUNT

    case ProductsActionType.DELETE_PRODUCT_SALES_DISCOUNT: {
      return {
        ...state,
        isProductSalesDiscountDeleting: true,
        isProductSalesDiscountDeleteCompleted: false,
        error: null,
      };
    }

    case ProductsActionType.DELETE_PRODUCT_SALES_DISCOUNT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isProductSalesDiscountDeleting: false,
        isProductSalesDiscountDeleteCompleted: false,
      };
    }

    case ProductsActionType.DELETE_PRODUCT_SALES_DISCOUNT_SUCCESS: {
      const selectedProduct = cloneDeep(state.selectedProduct);
      const productsDiscounts = [...state.selectedProduct.productSalesDiscounts];

      /* Remove the deleted product sales discounts from the array. */
      remove(productsDiscounts, (productsDiscount) => productsDiscount.id === action.payload.data.id);
      selectedProduct.productSalesDiscounts = productsDiscounts;
      return {
        ...state,
        selectedProduct,
        error: null,
        isProductSalesDiscountDeleting: false,
        isProductSalesDiscountDeleteCompleted: true,
      };
    }

    //#endregion DELETE_PRODUCT_SALES_DISCOUNT

    //#region UPDATE_PRODUCT_UNIT_OF_MEASURE_RATE

    case ProductsActionType.UPDATE_PRODUCT_UNIT_OF_MEASURE_RATE: {
      return {
        ...state,
        isProductUnitOfMeasureRateUpdating: true,
        isProductUnitOfMeasureRateUpdateCompleted: false,
        error: null,
      };
    }

    case ProductsActionType.UPDATE_PRODUCT_UNIT_OF_MEASURE_RATE_FAIL: {
      return {
        ...state,
        error: action.payload,
        isProductUnitOfMeasureRateUpdating: false,
        isProductUnitOfMeasureRateUpdateCompleted: false,
      };
    }

    case ProductsActionType.UPDATE_PRODUCT_UNIT_OF_MEASURE_RATE_SUCCESS: {
      const selectedProduct = cloneDeep(state.selectedProduct);
      selectedProduct.productUnitOfMeasureRates.push(action.payload.data);
      return {
        ...state,
        selectedProduct,
        error: null,
        isProductUnitOfMeasureRateUpdating: false,
        isProductUnitOfMeasureRateUpdateCompleted: true,
      };
    }

    //#endregion UPDATE_PRODUCT_UNIT_OF_MEASURE_RATE

    //#region DELETE_PRODUCT_UNIT_OF_MEASURE_RATE

    case ProductsActionType.DELETE_PRODUCT_UNIT_OF_MEASURE_RATE: {
      return {
        ...state,
        isProductUnitOfMeasureRateDeleting: true,
        isProductUnitOfMeasureRateDeleteCompleted: false,
        error: null,
      };
    }

    case ProductsActionType.DELETE_PRODUCT_UNIT_OF_MEASURE_RATE_FAIL: {
      return {
        ...state,
        error: action.payload,
        isProductUnitOfMeasureRateDeleting: false,
        isProductUnitOfMeasureRateDeleteCompleted: false,
      };
    }

    case ProductsActionType.DELETE_PRODUCT_UNIT_OF_MEASURE_RATE_SUCCESS: {
      const selectedProduct = cloneDeep(state.selectedProduct);
      const productsUnitOfMeasureRates = [...state.selectedProduct.productUnitOfMeasureRates];

      /* Remove the deleted product sales discounts from the array. */
      remove(productsUnitOfMeasureRates, (productsDiscount) => productsDiscount.id === action.payload.data.id);
      selectedProduct.productUnitOfMeasureRates = productsUnitOfMeasureRates;
      return {
        ...state,
        selectedProduct,
        error: null,
        isProductUnitOfMeasureRateDeleting: false,
        isProductUnitOfMeasureRateDeleteCompleted: true,
      };
    }

    //#endregion DELETE_PRODUCT_UNIT_OF_MEASURE_RATE

    default:
      return state;
  }
}
