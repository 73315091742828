import { createSelector } from '@ngrx/store';

import { selectLookupsState } from 'lookups/store/selectors/feature.selectors';
import { LookupsState, ProbationPeriodState } from 'lookups/store/states';

/**
 * Gets the probation period state.
 */
const selectProbationPeriods = createSelector(selectLookupsState, (state: LookupsState) => state.probationPeriods);

/**
 * Gets the list of loaded probation periods.
 */
export const getProbationPeriods = createSelector(selectProbationPeriods, (state: ProbationPeriodState) => state.data);

/**
 * Gets the error that occurred in the state.
 */
export const getProbationPeriodsError = createSelector(
  selectProbationPeriods,
  (state: ProbationPeriodState) => state.error
);

/**
 * Determines if there is a running load process.
 */
export const getProbationPeriodsIsLoading = createSelector(
  selectProbationPeriods,
  (state: ProbationPeriodState) => state.isLoading
);

/**
 * Determines if the last load process has been ended successfully.
 */
export const getProbationPeriodsIsLoaded = createSelector(
  selectProbationPeriods,
  (state: ProbationPeriodState) => state.isLoaded
);
