import { Component, OnDestroy, OnInit } from '@angular/core';

import { Subscription } from 'rxjs';
import { takeWhile, tap } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';

import * as fromAuthStore from 'auth/store';
import { TranslationService } from 'shared';

import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-full-layout',
  templateUrl: './full-layout.component.html',
})
export class FullLayoutComponent implements OnInit, OnDestroy {
  /**
   * Gets or sets the environment configurations.
   */
  environmentConfig = environment;

  /**
   * Indicates whether the sidebar is hidden or not.
   */
  isSidebarHidden: boolean;

  /**
   * Indicates whether the wrapper is hidden or not.
   */
  isCollapsed = false;

  /**
   * Indicates whether the sidebar should be small or not.
   */
  isSidebarSmall = false;

  /**
   * Indicates whether the sidebar should be larger or not.
   */
  isSidebarLarge = false;

  /**
   * The set of subscriptions on this components,
   * these subscriptions must be unsubscribed before this component got destroyed.
   */
  subscriptions = new Subscription();

  /**
   * Gets the ui direction according to the current user display language write direction.
   */
  get uiDirection(): string {
    return this.translationService.language === 'ar' ? 'rtl' : 'ltr';
  }

  /**
   * @param modalConfigThe modal service.
   * @param authStore$ The auth-Module store.
   * @param translationService The translation service.
   */

  constructor(
    private modalConfig: NgbModalConfig,
    private authStore$: Store<fromAuthStore.AuthState>,
    private translationService: TranslationService
  ) {}

  ngOnInit(): void {
    this.init();
  }

  /**
   * Destroy component data
   */
  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  /**
   * Initialize component data.
   */
  init() {
    this.subscriptions.add(
      this.authStore$
        .pipe(
          select(fromAuthStore.getSessionUserLanguage),
          takeWhile((language) => !!language),
          tap((language) => {
            /**
             * Set the current language for translation service.
             */
            this.translationService.setLanguage(language.key);
            this.setModalConfigs(language.key);
          })
        )
        .subscribe()
    );
  }

  /**
   *
   * @param isHidden Indicates whether if the sidebar is should be hidden or not.
   */
  toggleHideSidebar(isHidden: boolean): void {
    setTimeout(() => {
      this.isSidebarHidden = isHidden;
    }, 0);
  }

  /**
   * Sets the global modal configurations since this is the root component.
   * @param lang The language to determine modal ui direction.
   */
  setModalConfigs(lang: string) {
    lang === 'ar' ? (this.modalConfig.windowClass = 'rtl') : (this.modalConfig.windowClass = 'ltr');
  }
}
