import { Component, Input } from '@angular/core';
import { FormArray, FormGroup, FormControl, Validators } from '@angular/forms';

import { CustomValidators } from 'shared';
import { SubProduct } from 'stores/models';

@Component({
  selector: 'app-sub-products-form',
  templateUrl: './sub-products-form.component.html',
  styles: [],
})
export class SubProductsFormComponent {
  /**
   * Sets the sub-products form-array.
   */
  @Input() formArray: FormArray;

  /**
   * Sets the name form-array in its parent form.
   * @default 'subProducts'
   */
  @Input() formArrayName = 'subProducts';

  /**
   * Sets the initial sub-products.
   */
  @Input() set initialSubProducts(subProducts: SubProduct[]) {
    if (!subProducts?.length) {
      return;
    }

    subProducts.forEach((subProduct) => {
      this.addOldSubProduct(subProduct);
    });
  }

  /**
   * Indicates whether the current product's sub-products showed have ingredients or not.
   */
  @Input() set subProductHasIngredients(value: boolean) {
    this.isProductHasIngredients = value;
    this.formArray.controls.forEach((formGroup: FormGroup) => {
      const ingredientsForm = this.getIngredientsForm(formGroup);
      ingredientsForm.clearValidators();
      ingredientsForm.setValidators(this.isProductHasIngredients ? [CustomValidators.arrayItems(1)] : []);
      ingredientsForm.updateValueAndValidity();
    });
  }

  /**
   * Indicates whether the current product's sub-products showed have ingredients or not.
   */
  isProductHasIngredients: boolean;

  /**
   * Creates & adds a new sub-product form-group with validations.
   */
  addSubProduct() {
    const subProductFormGroup = new FormGroup({
      subProductVariants: new FormControl([], CustomValidators.arrayItems(1)),
      variantsList: new FormControl([]),
      purchasePrice: new FormControl(0, [CustomValidators.gte(0)]),
      salePrice: new FormControl(0, [CustomValidators.gte(0)]),
      subProductIngredients: new FormArray([], this.isProductHasIngredients ? [CustomValidators.arrayItems(1)] : []),
      isNew: new FormControl(true),
    });

    this.formArray.push(subProductFormGroup);
  }

  /**
   * Creates & adds a new sub-product form-group for an existing sub-product with validations.
   * @param subProduct The sub-product to be added to the form-array.
   */
  addOldSubProduct(subProduct: SubProduct) {
    const subProductFormGroup = new FormGroup({
      id: new FormControl(subProduct.id),
      subProductVariants: new FormControl(
        subProduct.subProductVariants.map((subProductVariant) => subProductVariant.variantId),
        CustomValidators.arrayItems(1)
      ),
      variantsList: new FormControl(
        subProduct.subProductVariants.map((subProductVariant) => subProductVariant.variant)
      ),
      purchasePrice: new FormControl(subProduct.purchasePrice, [CustomValidators.gte(0)]),
      salePrice: new FormControl(subProduct.salePrice, [CustomValidators.gte(0)]),
      subProductIngredients: new FormArray(
        subProduct.subProductIngredients.map(
          (productIngredient) =>
            new FormGroup({
              ingredientId: new FormControl(productIngredient.subProductIngredientId),
              subProductIngredient: new FormControl(productIngredient.subProductIngredient),
              quantity: new FormControl(productIngredient.quantity, [Validators.required, CustomValidators.gt(0)]),
            })
        ),
        !!subProduct.subProductIngredients.length ? [CustomValidators.arrayItems(1)] : []
      ),
      isNew: new FormControl(false),
    });

    this.formArray.push(subProductFormGroup);
  }

  /**
   * Remove the sub-product at the given index from the list.
   * @param index The index of the sub-product to be removed.
   */
  removeSubProduct(index: number) {
    this.formArray.removeAt(index);
  }

  /**
   * Remove all of the sub-products.
   */
  resetSubProducts() {
    this.formArray.clear();
  }

  /**
   * Returns the sub product ingredients form-array for the given sub product form-group.
   * @param formGroup The sub product form group.
   */
  getIngredientsForm(formGroup: FormGroup): FormArray {
    return formGroup.controls.subProductIngredients as FormArray;
  }
}
