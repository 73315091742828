<!-- page header starts -->
<app-page-header [info]="pageInfo"></app-page-header>
<!-- page header ends -->

<!-- create petty cash refund request form starts -->
<div class="row">
  <div class="col-md-12">
    <form [formGroup]="form" (ngSubmit)="submit()">
      <div class="card">
        <div class="card-header mb-3">
          <h4 class="card-title">
            {{ 'FINANCES.PETTY_CASH_REFUND_REQUESTS.PETTY_CASH_REFUND_REQUEST_DATA_TITLE' | translate }}
          </h4>
        </div>
        <div class="card-content">
          <div class="px-3">
            <div class="row">
              <div class="form-group col-md-12">
                <label for="pettyCashId">{{
                  'FINANCES.PETTY_CASH_REFUND_REQUESTS.PETTY_CASH_REFUND_REQUEST_DATA.PETTY_CASH' | translate
                }}</label>
                <ng-select
                  class="round"
                  formControlName="pettyCashId"
                  labelForId="pettyCashId"
                  searchable="true"
                  [placeholder]="
                    'FINANCES.PETTY_CASH_REFUND_REQUESTS.PETTY_CASH_REFUND_REQUEST_DATA.PETTY_CASH_PLACEHOLDER'
                      | translate
                  "
                  [placeholder]="
                    'FINANCES.PETTY_CASH_REFUND_REQUESTS.PETTY_CASH_REFUND_REQUEST_DATA.PETTY_CASH_PLACEHOLDER'
                      | translate
                  "
                  [notFoundText]="'SHARED.SEARCH.SEARCH_NOT_FOUND' | translate"
                  (open)="pettyCashListVisibility = true"
                >
                  <ng-option *ngFor="let petty of pettyCash" [value]="petty.id">
                    <span>{{ petty.description }}</span>
                    <i class="fa fa-usd c-primary mx-1"></i>
                    <span class="mx-1">{{ petty.value | toDecimal }}</span>
                    <i class="fa fa-lock c-primary mx-1"></i>
                    <span class="mx-1">{{ petty.maxAllowedPaymentValue | toDecimal }}</span>
                  </ng-option>
                </ng-select>
              </div>
              <div class="form-group col-md-12">
                <label for="notes">{{
                  'FINANCES.PETTY_CASH_REFUND_REQUESTS.PETTY_CASH_REFUND_REQUEST_DATA.NOTES' | translate
                }}</label>
                <textarea
                  id="notes"
                  class="form-control round"
                  [placeholder]="
                    'FINANCES.PETTY_CASH_REFUND_REQUESTS.PETTY_CASH_REFUND_REQUEST_DATA.NOTES_PLACEHOLDER' | translate
                  "
                  minlength="0"
                  maxlength="200"
                  formControlName="notes"
                ></textarea>
              </div>
            </div>
          </div>
        </div>
        <!-- lines details starts -->
        <div class="card-header mb-3 pt-1">
          <h4 class="card-title">
            {{
              'FINANCES.PETTY_CASH_REFUND_REQUESTS.PETTY_CASH_REFUND_REQUEST_DATA.PETTY_CASH_REFUND_REQUESTS'
                | translate
            }}
          </h4>
        </div>
        <div class="card-content">
          <div class="px-3">
            <app-petty-cash-refund-requests-form
              [linesForm]="linesForm"
              [initialLines]="initialLinesSubject.asObservable() | async"
              [types]="types$ | async"
              [debitNotificationPettyCashRefundRequestTypeId]="debitNotificationPettyCashRefundRequestTypeId"
              [taxes]="taxes$ | async"
              [defaultTax]="defaultTax | async"
            ></app-petty-cash-refund-requests-form>
          </div>
        </div>
        <!-- request summary starts -->
        <div class="card-header mb-3 pt-3">
          <h4 class="card-title">
            {{
              'FINANCES.PETTY_CASH_REFUND_REQUESTS.PETTY_CASH_REFUND_REQUEST_DATA.PETTY_CASH_REFUND_ACCOUNT' | translate
            }}
          </h4>
        </div>
        <div class="card-content">
          <div class="px-3">
            <div class="row">
              <div class="col-md-12">
                <div class="row">
                  <div class="form-group col-md-4">
                    <label for="requestLinesTotal">{{
                      'FINANCES.PETTY_CASH_REFUND_REQUESTS.PETTY_CASH_REFUND_REQUEST_DATA.REFUNDS_TOTAL' | translate
                    }}</label>
                    <input
                      type="text"
                      id="requestLinesTotal"
                      name="requestLinesTotal"
                      class="form-control round"
                      [value]="(requestLinesTotal | toDecimal) || '0.0'"
                      readonly
                    />
                  </div>
                  <div class="form-group col-md-4">
                    <label for="tax">{{
                      'FINANCES.PETTY_CASH_REFUND_REQUESTS.PETTY_CASH_REFUND_REQUEST_DATA.TAX_TOTAL' | translate
                    }}</label>
                    <input
                      type="text"
                      id="tax"
                      name="tax"
                      class="form-control round"
                      [value]="(requestLinesTax | toDecimal) || '0.0'"
                      readonly
                    />
                  </div>
                  <div class="form-group col-md-4">
                    <label for="requestTotal" class="font-weight-bold" style="color: #212529;">
                      <i class="fa fa-star"></i>
                      <span class="mx-1">{{
                        'FINANCES.PETTY_CASH_REFUND_REQUESTS.PETTY_CASH_REFUND_REQUEST_DATA.PETTY_CASH_REFUND_TOTAL'
                          | translate
                      }}</span>
                    </label>
                    <input
                      type="text"
                      id="requestTotal"
                      name="requestTotal"
                      class="form-control round"
                      [value]="(requestTotal | toDecimal) || '0.0'"
                      readonly
                    />
                  </div>
                </div>

                <div class="row">
                  <div class="form-group col-12 d-flex justify-content-between flex-column flex-sm-row mt-2">
                    <button type="submit" class="btn btn-raised btn-success round" [disabled]="isCreating$ | async">
                      <i class="fa fa-check"></i>
                      <span class="mx-1">{{ 'SHARED.CONFIRM.SAVE' | translate }}</span>
                    </button>
                    <a class="btn btn-dark round" routerLink="/finances/petty-cash-refund-requests">
                      <i class="fa fa-undo"></i>
                      <span class="mx-1">{{
                        'FINANCES.PETTY_CASH_REFUND_REQUESTS.PETTY_CASH_REFUND_REQUEST_DATA.PETTY_CASH_REFUND_REQUESTS_RETURN'
                          | translate
                      }}</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- request summary ends -->
    </form>
  </div>
</div>

<!-- create petty cash refund request form ends -->

<!-- confirm modal starts -->
<ng-template #confirmModalRef let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
      {{ 'FINANCES.PETTY_CASH_REFUND_REQUESTS.CONFIRM_CREATE_PETTY_CASH_REFUND_REQUEST_TITLE' | translate }}
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="d()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p class="text-success">
      {{ 'FINANCES.PETTY_CASH_REFUND_REQUESTS.CONFIRM_CREATE_PETTY_CASH_REFUND_REQUEST_MESSAGE' | translate }}
    </p>
  </div>
  <div class="modal-footer">
    <div class="modal-actions">
      <button type="button" class="btn btn-raised btn-success round" (click)="c(); confirm()" ngbAutofocus>
        <i class="fa fa-check"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.SAVE' | translate }}</span>
      </button>
      <button type="button" class="btn btn-outline-dark round" (click)="c()">
        <i class="fa fa-times"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.CANCEL' | translate }}</span>
      </button>
    </div>
  </div>
</ng-template>
<!-- confirm modal ends -->

<!-- petty cash search starts -->
<app-petty-cash-search
  [(visible)]="pettyCashListVisibility"
  [closeOnSelect]="true"
  (pettyCash)="selectPettyCash($event)"
>
</app-petty-cash-search>
<!-- petty cash search ends -->
