import { Action } from '@ngrx/store';

import { SaleReturn, CreateSaleReturnInput } from 'sales/models';
import { AppHttpResponse } from 'shared';

/**
 * The Sales-Module store salesReturns action types.
 */
export enum SaleReturnsActionType {
  SEARCH_SALE_RETURNS = '[Sales] [SaleReturn] Search Sale Returns',
  SEARCH_SALE_RETURNS_FAIL = '[Sales] [SaleReturn] Search Sale Returns Fail',
  SEARCH_SALE_RETURNS_SUCCESS = '[Sales] [SaleReturn] Search Sale Returns Success',

  FIND_SALE_RETURN = '[Sales] [SaleReturn] Find Sale Return',
  FIND_SALE_RETURN_FAIL = '[Sales] [SaleReturn] Find Sale Return Fail',
  FIND_SALE_RETURN_SUCCESS = '[Sales] [SaleReturn] Find Sale Return Success',

  CREATE_SALE_RETURN = '[Sales] [SaleReturn] Create Sale Return',
  CREATE_SALE_RETURN_FAIL = '[Sales] [SaleReturn] Create Sale Return Fail',
  CREATE_SALE_RETURN_SUCCESS = '[Sales] [SaleReturn] Create Sale Return Success',
}

/**
 * Represents a store salesReturns search action.
 */
export class SearchSaleReturns implements Action {
  /**
   * The type of the action.
   */
  readonly type = SaleReturnsActionType.SEARCH_SALE_RETURNS;

  /**
   * Fires a new salesReturns search action.
   * @param payload the search parameters,
   * if omitted, all salesReturns will be loaded.
   */
  constructor(
    public payload?: {
      customers: number[];
      locations: number[];
      saleReturnNum: number;
      invoiceNum: number;
      fromDate: Date;
      toDate: Date;
      page: number;
    }
  ) {}
}

/**
 * Represents a store salesReturns search-fail action.
 */
export class SearchSaleReturnsFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = SaleReturnsActionType.SEARCH_SALE_RETURNS_FAIL;

  /**
   * Fires a new salesReturns search-fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store search-success action.
 */
export class SearchSaleReturnsSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = SaleReturnsActionType.SEARCH_SALE_RETURNS_SUCCESS;

  /**
   * Fires a new salesReturns search-success action.
   * @param payload An object contains the list of salesReturns that matches the search criteria
   * in addition to the pagination info.
   */
  constructor(public payload: AppHttpResponse<SaleReturn[]>) {}
}

/**
 * Represents a store find-salesReturn action.
 */
export class FindSaleReturn implements Action {
  /**
   * The type of the action.
   */
  readonly type = SaleReturnsActionType.FIND_SALE_RETURN;

  /**
   * Fires a new find salesReturn action.
   * @param payload the id of the salesReturn.
   */
  constructor(public payload: number) {}
}

/**
 * Represents a store find-salesReturn-fail action.
 */
export class FindSaleReturnFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = SaleReturnsActionType.FIND_SALE_RETURN_FAIL;

  /**
   * Fires a new find-salesReturn-fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store find salesReturn-success action.
 */
export class FindSaleReturnSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = SaleReturnsActionType.FIND_SALE_RETURN_SUCCESS;

  /**
   * Fires a new find-salesReturn-success action.
   * @param payload The loaded salesReturn.
   */
  constructor(public payload: AppHttpResponse<SaleReturn>) {}
}

/**
 * Represents a store salesReturn create action.
 */
export class CreateSaleReturn implements Action {
  /** The type of the action. */
  readonly type = SaleReturnsActionType.CREATE_SALE_RETURN;

  /**
   * Fires a new salesReturn create action.
   * @param payload the new salesReturn data-model.
   */
  constructor(public payload: CreateSaleReturnInput) {}
}

/**
 * Represents a store salesReturn create-fail action.
 */
export class CreateSaleReturnFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = SaleReturnsActionType.CREATE_SALE_RETURN_FAIL;

  /**
   * Fires a new salesReturn create-fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store salesReturn create-success action.
 */
export class CreateSaleReturnSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = SaleReturnsActionType.CREATE_SALE_RETURN_SUCCESS;

  /**
   * Fires a new saleReturns create-success action.
   * @param payload The created salesReturns.
   */
  constructor(public payload: AppHttpResponse<SaleReturn>) {}
}

/**
 * Sales-module salesReturns action types.
 */
export type SaleReturnsActions =
  | SearchSaleReturns
  | SearchSaleReturnsFail
  | SearchSaleReturnsSuccess
  | FindSaleReturn
  | FindSaleReturnFail
  | FindSaleReturnSuccess
  | CreateSaleReturn
  | CreateSaleReturnFail
  | CreateSaleReturnSuccess;
