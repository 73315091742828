import { createSelector } from '@ngrx/store';

import { selectLookupsState } from 'lookups/store/selectors/feature.selectors';
import { LookupsState, CustomerEngagementTypeState } from 'lookups/store/states';

/**
 * Gets the customerEngagementType state.
 */
const selectCustomerEngagementTypes = createSelector(
  selectLookupsState,
  (state: LookupsState) => state.customerEngagementTypes
);

/**
 * Gets the list of loaded customerEngagementTypes.
 */
export const getCustomerEngagementTypes = createSelector(
  selectCustomerEngagementTypes,
  (state: CustomerEngagementTypeState) => state.data
);

/**
 * Gets the error that occurred in the state.
 */
export const getCustomerEngagementTypesError = createSelector(
  selectCustomerEngagementTypes,
  (state: CustomerEngagementTypeState) => state.error
);

/**
 * Determines if there is a running load process.
 */
export const getCustomerEngagementTypesIsLoading = createSelector(
  selectCustomerEngagementTypes,
  (state: CustomerEngagementTypeState) => state.isLoading
);

/**
 * Determines if the last load process has been ended successfully.
 */
export const getCustomerEngagementTypesIsLoaded = createSelector(
  selectCustomerEngagementTypes,
  (state: CustomerEngagementTypeState) => state.isLoaded
);
