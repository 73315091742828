import { Action } from '@ngrx/store';

import { Report } from 'reports/models';
import { AppHttpResponse } from 'shared';

/**
 * The Reports-Module stores reports action types.
 */
export enum StoresReportsActionType {
  GET_PRODUCTS_LIST_REPORT = '[Reports] [Stores] Get Products List Report',
  GET_PRODUCTS_LIST_REPORT_FAIL = '[Reports] [Stores] Get Products List Report Fail',
  GET_PRODUCTS_LIST_REPORT_RECEIVED = '[Reports] [Stores] Get Products List Report Received',
  GET_PRODUCTS_LIST_REPORT_SUCCESS = '[Reports] [Stores] Get Products List Report Success',
  PRODUCTS_LIST_REPORT_VIEW = '[Reports] [Stores] Products List Report View',

  GET_PRODUCT_MOVEMENT_REPORT = '[Reports] [Stores] Get Product Movement Report',
  GET_PRODUCT_MOVEMENT_REPORT_FAIL = '[Reports] [Stores] Get Product Movement Report Fail',
  GET_PRODUCT_MOVEMENT_REPORT_RECEIVED = '[Reports] [Stores] Get Product Movement Report Received',
  GET_PRODUCT_MOVEMENT_REPORT_SUCCESS = '[Reports] [Stores] Get Product Movement Report Success',
  PRODUCT_MOVEMENT_REPORT_VIEW = '[Reports] [Stores] Product Movement Report View',

  GET_INCOMING_STOCK_REPORT = '[Reports] [Stores] Get Incoming Stock Report',
  GET_INCOMING_STOCK_REPORT_FAIL = '[Reports] [Stores] Get Incoming Stock Report Fail',
  GET_INCOMING_STOCK_REPORT_RECEIVED = '[Reports] [Stores] Get Incoming Stock Report Received',
  GET_INCOMING_STOCK_REPORT_SUCCESS = '[Reports] [Stores] Get Incoming Stock Report Success',
  INCOMING_STOCK_REPORT_VIEW = '[Reports] [Stores] Incoming Stock Report View',

  GET_OUTGOING_STOCK_REPORT = '[Reports] [Stores] Get Outgoing Stock Report',
  GET_OUTGOING_STOCK_REPORT_FAIL = '[Reports] [Stores] Get Outgoing Stock Report Fail',
  GET_OUTGOING_STOCK_REPORT_RECEIVED = '[Reports] [Stores] Get Outgoing Stock Report Received',
  GET_OUTGOING_STOCK_REPORT_SUCCESS = '[Reports] [Stores] Get Outgoing Stock Report Success',
  OUTGOING_STOCK_REPORT_VIEW = '[Reports] [Stores] Outgoing Stock Report View',

  GET_TRANSFER_STOCK_REPORT = '[Reports] [Stores] Get Transfer Stock Report',
  GET_TRANSFER_STOCK_REPORT_FAIL = '[Reports] [Stores] Get Transfer Stock Report Fail',
  GET_TRANSFER_STOCK_REPORT_RECEIVED = '[Reports] [Stores] Get Transfer Stock Report Received',
  GET_TRANSFER_STOCK_REPORT_SUCCESS = '[Reports] [Stores] Get Transfer Stock Report Success',
  TRANSFER_STOCK_REPORT_VIEW = '[Reports] [Stores] Transfer Stock Report View',

  GET_STOCK_INVENTORY_REPORT = '[Reports] [Stores] Get Stock Inventory Report',
  GET_STOCK_INVENTORY_REPORT_FAIL = '[Reports] [Stores] Get Stock Inventory Report Fail',
  GET_STOCK_INVENTORY_REPORT_RECEIVED = '[Reports] [Stores] Get Stock Inventory Report Received',
  GET_STOCK_INVENTORY_REPORT_SUCCESS = '[Reports] [Stores] Get Stock Inventory Report Success',
  STOCK_INVENTORY_REPORT_VIEW = '[Reports] [Stores] Stock Inventory Report View',

  GET_STOCK_MONITORING_REPORT = '[Reports] [Stores] Get Stock Monitoring Report',
  GET_STOCK_MONITORING_REPORT_FAIL = '[Reports] [Stores] Get Stock Monitoring Report Fail',
  GET_STOCK_MONITORING_REPORT_RECEIVED = '[Reports] [Stores] Get Stock Monitoring Report Received',
  GET_STOCK_MONITORING_REPORT_SUCCESS = '[Reports] [Stores] Get Stock Monitoring Report Success',
  STOCK_MONITORING_REPORT_VIEW = '[Reports] [Stores] Stock Monitoring Report View',

  GET_UNHANDLED_SALES_STOCK_REPORT = '[Reports] [Stores] Get Stock Unhandled Report',
  GET_UNHANDLED_SALES_STOCK_REPORT_FAIL = '[Reports] [Stores] Get Stock Unhandled Report Fail',
  GET_UNHANDLED_SALES_STOCK_REPORT_RECEIVED = '[Reports] [Stores] Get Stock Unhandled Report Received',
  GET_UNHANDLED_SALES_STOCK_REPORT_SUCCESS = '[Reports] [Stores] Get Stock Unhandled Report Success',
  UNHANDLED_SALES_STOCK_REPORT_VIEW = '[Reports] [Stores] Stock Unhandled Report View',
}

/**
 * Represents a products list report action.
 */
export class GetProductsListReport implements Action {
  /**
   * The type of the action.
   */
  readonly type = StoresReportsActionType.GET_PRODUCTS_LIST_REPORT;

  /**
   * Fires a new products list report action.
   */
  constructor(
    public payload: {
      classes: number[];
      locations: number[];
      searchByPurchasePrice: boolean;
      minPurchasePrice: number;
      maxPurchasePrice: number;
      searchBySalePrice: boolean;
      minSalePrice: number;
      maxSalePrice: number;
    }
  ) {}
}

/**
 * Represents a products list report fail action.
 */
export class GetProductsListReportFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = StoresReportsActionType.GET_PRODUCTS_LIST_REPORT_FAIL;

  /**
   * Fires a new products list report fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a products list report received action.
 */
export class GetProductsListReportReceived implements Action {
  /**
   * The type of the action.
   */
  readonly type = StoresReportsActionType.GET_PRODUCTS_LIST_REPORT_RECEIVED;

  /**
   * Fires a products list report received action.
   * @param payload Received acknowledgment.
   */
  constructor(public payload: AppHttpResponse<string>) {}
}

/**
 * Represents a products list report success action.
 */
export class GetProductsListReportSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = StoresReportsActionType.GET_PRODUCTS_LIST_REPORT_SUCCESS;

  /**
   * Fires a products list report success action.
   * @param payload The loaded report.
   */
  constructor(public payload: AppHttpResponse<Report>) {}
}

/**
 * Represents a products list report view action.
 */
export class ProductsListReportView implements Action {
  /**
   * The type of the action.
   */
  readonly type = StoresReportsActionType.PRODUCTS_LIST_REPORT_VIEW;

  /**
   * Fires a products list report view action.
   * @param payload The report to be loaded.
   */
  constructor(public payload: Report) {}
}

/**
 * Represents a product movement report action.
 */
export class GetProductMovementReport implements Action {
  /**
   * The type of the action.
   */
  readonly type = StoresReportsActionType.GET_PRODUCT_MOVEMENT_REPORT;

  /**
   * Fires a new product movement report action.
   */
  constructor(
    public payload: {
      productId: number;
      locations: number[];
      fromDate: Date;
      toDate: Date;
      timeSerial: boolean;
      descriptive: boolean;
    }
  ) {}
}

/**
 * Represents a product movement report fail action.
 */
export class GetProductMovementReportFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = StoresReportsActionType.GET_PRODUCT_MOVEMENT_REPORT_FAIL;

  /**
   * Fires a new product movement report fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a product movement report received action.
 */
export class GetProductMovementReportReceived implements Action {
  /**
   * The type of the action.
   */
  readonly type = StoresReportsActionType.GET_PRODUCT_MOVEMENT_REPORT_RECEIVED;

  /**
   * Fires a product movement report received action.
   * @param payload Received acknowledgment.
   */
  constructor(public payload: AppHttpResponse<string>) {}
}

/**
 * Represents a product movement report success action.
 */
export class GetProductMovementReportSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = StoresReportsActionType.GET_PRODUCT_MOVEMENT_REPORT_SUCCESS;

  /**
   * Fires a product movement report success action.
   * @param payload The loaded report.
   */
  constructor(public payload: AppHttpResponse<Report>) {}
}

/**
 * Represents a product movement report view action.
 */
export class ProductMovementReportView implements Action {
  /**
   * The type of the action.
   */
  readonly type = StoresReportsActionType.PRODUCT_MOVEMENT_REPORT_VIEW;

  /**
   * Fires a product movement report view action.
   * @param payload The report to be loaded.
   */
  constructor(public payload: Report) {}
}

/**
 * Represents a incoming stock report action.
 */
export class GetIncomingStockReport implements Action {
  /**
   * The type of the action.
   */
  readonly type = StoresReportsActionType.GET_INCOMING_STOCK_REPORT;

  /**
   * Fires a new incoming stock report action.
   */
  constructor(
    public payload: {
      locations: number[];
      types: number[];
      displayProducts: boolean;
      fromDate: Date;
      toDate: Date;
    }
  ) {}
}

/**
 * Represents a incoming stock report fail action.
 */
export class GetIncomingStockReportFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = StoresReportsActionType.GET_INCOMING_STOCK_REPORT_FAIL;

  /**
   * Fires a new incoming stock report fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a incoming stock report received action.
 */
export class GetIncomingStockReportReceived implements Action {
  /**
   * The type of the action.
   */
  readonly type = StoresReportsActionType.GET_INCOMING_STOCK_REPORT_RECEIVED;

  /**
   * Fires a incoming stock report received action.
   * @param payload Received acknowledgment.
   */
  constructor(public payload: AppHttpResponse<string>) {}
}

/**
 * Represents a incoming stock report success action.
 */
export class GetIncomingStockReportSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = StoresReportsActionType.GET_INCOMING_STOCK_REPORT_SUCCESS;

  /**
   * Fires a incoming stock report success action.
   * @param payload The loaded report.
   */
  constructor(public payload: AppHttpResponse<Report>) {}
}

/**
 * Represents a incoming stock report view action.
 */
export class IncomingStockReportView implements Action {
  /**
   * The type of the action.
   */
  readonly type = StoresReportsActionType.INCOMING_STOCK_REPORT_VIEW;

  /**
   * Fires a incoming stock report view action.
   * @param payload The report to be loaded.
   */
  constructor(public payload: Report) {}
}

/**
 * Represents a outgoing stock report action.
 */
export class GetOutgoingStockReport implements Action {
  /**
   * The type of the action.
   */
  readonly type = StoresReportsActionType.GET_OUTGOING_STOCK_REPORT;

  /**
   * Fires a new outgoing stock report action.
   */
  constructor(
    public payload: {
      locations: number[];
      types: number[];
      displayProducts: boolean;
      fromDate: Date;
      toDate: Date;
    }
  ) {}
}

/**
 * Represents a outgoing stock report fail action.
 */
export class GetOutgoingStockReportFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = StoresReportsActionType.GET_OUTGOING_STOCK_REPORT_FAIL;

  /**
   * Fires a new outgoing stock report fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a outgoing stock report received action.
 */
export class GetOutgoingStockReportReceived implements Action {
  /**
   * The type of the action.
   */
  readonly type = StoresReportsActionType.GET_OUTGOING_STOCK_REPORT_RECEIVED;

  /**
   * Fires a outgoing stock report received action.
   * @param payload Received acknowledgment.
   */
  constructor(public payload: AppHttpResponse<string>) {}
}

/**
 * Represents a outgoing stock report success action.
 */
export class GetOutgoingStockReportSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = StoresReportsActionType.GET_OUTGOING_STOCK_REPORT_SUCCESS;

  /**
   * Fires a outgoing stock report success action.
   * @param payload The loaded report.
   */
  constructor(public payload: AppHttpResponse<Report>) {}
}

/**
 * Represents a outgoing stock report view action.
 */
export class OutgoingStockReportView implements Action {
  /**
   * The type of the action.
   */
  readonly type = StoresReportsActionType.OUTGOING_STOCK_REPORT_VIEW;

  /**
   * Fires a outgoing stock report view action.
   * @param payload The report to be loaded.
   */
  constructor(public payload: Report) {}
}

/**
 * Represents a transfer stock report action.
 */
export class GetTransferStockReport implements Action {
  /**
   * The type of the action.
   */
  readonly type = StoresReportsActionType.GET_TRANSFER_STOCK_REPORT;

  /**
   * Fires a new transfer stock report action.
   */
  constructor(
    public payload: {
      fromLocations: number[];
      toLocations: number[];
      displayProducts: boolean;
      fromDate: Date;
      toDate: Date;
    }
  ) {}
}

/**
 * Represents a transfer stock report fail action.
 */
export class GetTransferStockReportFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = StoresReportsActionType.GET_TRANSFER_STOCK_REPORT_FAIL;

  /**
   * Fires a new transfer stock report fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a transfer stock report received action.
 */
export class GetTransferStockReportReceived implements Action {
  /**
   * The type of the action.
   */
  readonly type = StoresReportsActionType.GET_TRANSFER_STOCK_REPORT_RECEIVED;

  /**
   * Fires a transfer stock report received action.
   * @param payload Received acknowledgment.
   */
  constructor(public payload: AppHttpResponse<string>) {}
}

/**
 * Represents a transfer stock report success action.
 */
export class GetTransferStockReportSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = StoresReportsActionType.GET_TRANSFER_STOCK_REPORT_SUCCESS;

  /**
   * Fires a transfer stock report success action.
   * @param payload The loaded report.
   */
  constructor(public payload: AppHttpResponse<Report>) {}
}

/**
 * Represents a transfer stock report view action.
 */
export class TransferStockReportView implements Action {
  /**
   * The type of the action.
   */
  readonly type = StoresReportsActionType.TRANSFER_STOCK_REPORT_VIEW;

  /**
   * Fires a transfer stock report view action.
   * @param payload The report to be loaded.
   */
  constructor(public payload: Report) {}
}

/**
 * Represents a stock inventory report action.
 */
export class GetStockInventoryReport implements Action {
  /**
   * The type of the action.
   */
  readonly type = StoresReportsActionType.GET_STOCK_INVENTORY_REPORT;

  /**
   * Fires a new stock inventory report action.
   */
  constructor(
    public payload: {
      locationId: number;
      classes: number[];
      products: number[];
      productsWithAllStocks: boolean;
      productsWithStock: boolean;
      productsWithNoStock: boolean;
      productsWithNegativeStock: boolean;
    }
  ) {}
}

/**
 * Represents a stock inventory report fail action.
 */
export class GetStockInventoryReportFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = StoresReportsActionType.GET_STOCK_INVENTORY_REPORT_FAIL;

  /**
   * Fires a new stock inventory report fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a stock inventory report received action.
 */
export class GetStockInventoryReportReceived implements Action {
  /**
   * The type of the action.
   */
  readonly type = StoresReportsActionType.GET_STOCK_INVENTORY_REPORT_RECEIVED;

  /**
   * Fires a stock inventory report received action.
   * @param payload Received acknowledgment.
   */
  constructor(public payload: AppHttpResponse<string>) {}
}

/**
 * Represents a stock inventory report success action.
 */
export class GetStockInventoryReportSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = StoresReportsActionType.GET_STOCK_INVENTORY_REPORT_SUCCESS;

  /**
   * Fires a stock inventory report success action.
   * @param payload The loaded report.
   */
  constructor(public payload: AppHttpResponse<Report>) {}
}

/**
 * Represents a stock inventory report view action.
 */
export class StockInventoryReportView implements Action {
  /**
   * The type of the action.
   */
  readonly type = StoresReportsActionType.STOCK_INVENTORY_REPORT_VIEW;

  /**
   * Fires a stock inventory report view action.
   * @param payload The report to be loaded.
   */
  constructor(public payload: Report) {}
}

/**
 * Represents a stock monitoring report action.
 */
export class GetStockMonitoringReport implements Action {
  /**
   * The type of the action.
   */
  readonly type = StoresReportsActionType.GET_STOCK_MONITORING_REPORT;

  /**
   * Fires a new stock monitoring report action.
   */
  constructor(
    public payload: {
      locationId: number;
      products: number[];
      fromDate: Date;
      toDate: Date;
    }
  ) {}
}

/**
 * Represents a stock monitoring report fail action.
 */
export class GetStockMonitoringReportFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = StoresReportsActionType.GET_STOCK_MONITORING_REPORT_FAIL;

  /**
   * Fires a new stock monitoring report fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a stock monitoring report received action.
 */
export class GetStockMonitoringReportReceived implements Action {
  /**
   * The type of the action.
   */
  readonly type = StoresReportsActionType.GET_STOCK_MONITORING_REPORT_RECEIVED;

  /**
   * Fires a stock monitoring report received action.
   * @param payload Received acknowledgment.
   */
  constructor(public payload: AppHttpResponse<string>) {}
}

/**
 * Represents a stock monitoring report success action.
 */
export class GetStockMonitoringReportSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = StoresReportsActionType.GET_STOCK_MONITORING_REPORT_SUCCESS;

  /**
   * Fires a stock monitoring report success action.
   * @param payload The loaded report.
   */
  constructor(public payload: AppHttpResponse<Report>) {}
}

/**
 * Represents a stock monitoring report view action.
 */
export class StockMonitoringReportView implements Action {
  /**
   * The type of the action.
   */
  readonly type = StoresReportsActionType.STOCK_MONITORING_REPORT_VIEW;

  /**
   * Fires a stock monitoring report view action.
   * @param payload The report to be loaded.
   */
  constructor(public payload: Report) {}
}

/**
 * Represents a unhandled sales stock report action.
 */
export class GetUnhandledSalesStockReport implements Action {
  /**
   * The type of the action.
   */
  readonly type = StoresReportsActionType.GET_UNHANDLED_SALES_STOCK_REPORT;

  /**
   * Fires a new unhandled sales stock report action.
   */
  constructor(
    public payload: {
      locationId: number;
      products: number[];
      fromDate: Date;
      toDate: Date;
    }
  ) {}
}

/**
 * Represents a unhandled sales stock report fail action.
 */
export class GetUnhandledSalesStockReportFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = StoresReportsActionType.GET_UNHANDLED_SALES_STOCK_REPORT_FAIL;

  /**
   * Fires a new unhandled sales stock report fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a unhandled sales stock report received action.
 */
export class GetUnhandledSalesStockReportReceived implements Action {
  /**
   * The type of the action.
   */
  readonly type = StoresReportsActionType.GET_UNHANDLED_SALES_STOCK_REPORT_RECEIVED;

  /**
   * Fires a unhandled sales stock report received action.
   * @param payload Received acknowledgment.
   */
  constructor(public payload: AppHttpResponse<string>) {}
}

/**
 * Represents a unhandled sales stock report success action.
 */
export class GetUnhandledSalesStockReportSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = StoresReportsActionType.GET_UNHANDLED_SALES_STOCK_REPORT_SUCCESS;

  /**
   * Fires a unhandled sales stock report success action.
   * @param payload The loaded report.
   */
  constructor(public payload: AppHttpResponse<Report>) {}
}

/**
 * Represents a unhandled sales stock report view action.
 */
export class UnhandledSalesStockReportView implements Action {
  /**
   * The type of the action.
   */
  readonly type = StoresReportsActionType.UNHANDLED_SALES_STOCK_REPORT_VIEW;

  /**
   * Fires a unhandled sales stock report view action.
   * @param payload The report to be loaded.
   */
  constructor(public payload: Report) {}
}

/**
 * Reports-Module stores reports action types.
 */
export type StoresReportsActions =
  | GetProductsListReport
  | GetProductsListReportFail
  | GetProductsListReportReceived
  | GetProductsListReportSuccess
  | ProductsListReportView
  | GetProductMovementReport
  | GetProductMovementReportFail
  | GetProductMovementReportReceived
  | GetProductMovementReportSuccess
  | ProductMovementReportView
  | GetIncomingStockReport
  | GetIncomingStockReportFail
  | GetIncomingStockReportReceived
  | GetIncomingStockReportSuccess
  | IncomingStockReportView
  | GetOutgoingStockReport
  | GetOutgoingStockReportFail
  | GetOutgoingStockReportReceived
  | GetOutgoingStockReportSuccess
  | OutgoingStockReportView
  | GetTransferStockReport
  | GetTransferStockReportFail
  | GetTransferStockReportReceived
  | GetTransferStockReportSuccess
  | TransferStockReportView
  | GetStockInventoryReport
  | GetStockInventoryReportFail
  | GetStockInventoryReportReceived
  | GetStockInventoryReportSuccess
  | StockInventoryReportView
  | GetStockMonitoringReport
  | GetStockMonitoringReportFail
  | GetStockMonitoringReportReceived
  | GetStockMonitoringReportSuccess
  | StockMonitoringReportView
  | GetUnhandledSalesStockReport
  | GetUnhandledSalesStockReportFail
  | GetUnhandledSalesStockReportReceived
  | GetUnhandledSalesStockReportSuccess
  | UnhandledSalesStockReportView;
