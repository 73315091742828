import { ProjectStatus } from 'lookups/models';

/**
 * Represents the project statuses state.
 */
export class ProjectStatusState {
  /**
   * The list of project statuses.
   */
  public data: ProjectStatus[];

  /**
   * An error that may resulting during processing some actions.
   */
  public error: any;

  /**
   * Determines if there is a running load process.
   */
  public isLoading: boolean;

  /**
   * Determines if the last load process has been ended successfully.
   */
  public isLoaded: boolean;
}
