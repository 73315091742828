import { PageHeaderComponent } from 'shared/components/page-header/page-header.component';
import { StatusComponent } from 'shared/components/status/status.component';
import { ApprovalStatusComponent } from 'shared/components/approval-status/approval-status.component';
import { DockableStatusComponent } from 'shared/components/dockable-status/dockable-status.component';
import { FileUploadComponent } from 'shared/components/file-upload/file-upload.component';
import { FilesUploadComponent } from 'shared/components/files-upload/files-upload.component';
import { FileViewerComponent } from 'shared/components/file-viewer/file-viewer.component';
import { FilesViewerComponent } from 'shared/components/files-viewer/files-viewer.component';
import { ProductAvailableStocksComponent } from 'shared/components/product-available-stocks/product-available-stocks.component';
import { DatePickerComponent } from 'shared/components/date-picker/date-picker.component';
import { ExportToolsComponent } from 'shared/components/export-tools/export-tools.component';
import { SubProductInfoComponent } from 'shared/components/sub-product-info/sub-product-info.component';
import { PayrollsListComponent } from 'shared/components/payrolls-list/payrolls-list.component';
import { PayrollRecurringElementsInfoComponent } from 'shared/components/payroll-recurring-elements-info/payroll-recurring-elements-info.component';
import { PayrollDeductionsInfoComponent } from 'shared/components/payroll-deductions-info/payroll-deductions-info.component';
import { PayrollOtherEarningsInfoComponent } from 'shared/components/payroll-other-earnings-info/payroll-other-earnings-info.component';
import { PayrollOvertimesInfoComponent } from 'shared/components/payroll-overtimes-info/payroll-overtimes-info.component';
import { PayrollLoanSettlementsInfoComponent } from 'shared/components/payroll-loan-settlements-info/payroll-loan-settlements-info.component';
import { PayrollLogsInfoComponent } from 'shared/components/payroll-logs-info/payroll-logs-info.component';
import { PayrollGosiPayrollElementsInfoComponent } from 'shared/components/payroll-gosi-payroll-elements-info/payroll-gosi-payroll-elements-info.component';
import { PayrollInfoComponent } from 'shared/components/payroll-info/payroll-info.component';
import { PayrollLocationsCostsInfoComponent } from 'shared/components/payroll-locations-costs-info/payroll-locations-costs-info.component';
import { PayrollProjectsCostsInfoComponent } from 'shared/components/payroll-projects-costs-info/payroll-projects-costs-info.component';
import { PayrollElementFormulasComponent } from 'shared/components/payroll-element-formulas/payroll-element-formulas.component';
import { PayrollProvisionsInfoComponent } from 'shared/components/payroll-provisions-info/payroll-provisions-info.component';
import { PayrollGovServicesInfoComponent } from 'shared/components/payroll-gov-services-info/payroll-gov-services-info.component';
import { PayrollUnpaidLeavesInfoComponent } from 'shared/components/payroll-unpaid-leaves-info/payroll-unpaid-leaves-info.component';

export * from 'shared/components/page-header/page-header.component';
export * from 'shared/components/status/status.component';
export * from 'shared/components/approval-status/approval-status.component';
export * from 'shared/components/dockable-status/dockable-status.component';
export * from 'shared/components/file-upload/file-upload.component';
export * from 'shared/components/files-upload/files-upload.component';
export * from 'shared/components/file-viewer/file-viewer.component';
export * from 'shared/components/files-viewer/files-viewer.component';
export * from 'shared/components/product-available-stocks/product-available-stocks.component';
export * from 'shared/components/date-picker/date-picker.component';
export * from 'shared/components/export-tools/export-tools.component';
export * from 'shared/components/sub-product-info/sub-product-info.component';
export * from 'shared/components/payrolls-list/payrolls-list.component';
export * from 'shared/components/payroll-recurring-elements-info/payroll-recurring-elements-info.component';
export * from 'shared/components/payroll-deductions-info/payroll-deductions-info.component';
export * from 'shared/components/payroll-other-earnings-info/payroll-other-earnings-info.component';
export * from 'shared/components/payroll-overtimes-info/payroll-overtimes-info.component';
export * from 'shared/components/payroll-loan-settlements-info/payroll-loan-settlements-info.component';
export * from 'shared/components/payroll-logs-info/payroll-logs-info.component';
export * from 'shared/components/payroll-gosi-payroll-elements-info/payroll-gosi-payroll-elements-info.component';
export * from 'shared/components/payroll-info/payroll-info.component';
export * from 'shared/components/payroll-locations-costs-info/payroll-locations-costs-info.component';
export * from 'shared/components/payroll-projects-costs-info/payroll-projects-costs-info.component';
export * from 'shared/components/payroll-element-formulas/payroll-element-formulas.component';
export * from 'shared/components/payroll-provisions-info/payroll-provisions-info.component';
export * from 'shared/components/payroll-gov-services-info/payroll-gov-services-info.component';
export * from 'shared/components/payroll-unpaid-leaves-info/payroll-unpaid-leaves-info.component';

/**
 * The set of components registered on this module.
 */
export const COMPONENTS: any[] = [
  PageHeaderComponent,
  StatusComponent,
  ApprovalStatusComponent,
  DockableStatusComponent,
  FileUploadComponent,
  FilesUploadComponent,
  FileViewerComponent,
  FilesViewerComponent,
  ProductAvailableStocksComponent,
  DatePickerComponent,
  ExportToolsComponent,
  SubProductInfoComponent,
  PayrollsListComponent,
  PayrollRecurringElementsInfoComponent,
  PayrollOtherEarningsInfoComponent,
  PayrollDeductionsInfoComponent,
  PayrollOvertimesInfoComponent,
  PayrollLoanSettlementsInfoComponent,
  PayrollLogsInfoComponent,
  PayrollGosiPayrollElementsInfoComponent,
  PayrollInfoComponent,
  PayrollLocationsCostsInfoComponent,
  PayrollProjectsCostsInfoComponent,
  PayrollElementFormulasComponent,
  PayrollProvisionsInfoComponent,
  PayrollGovServicesInfoComponent,
  PayrollUnpaidLeavesInfoComponent,
];
