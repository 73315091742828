import { AppStatusState } from 'core/store/states';
import { AppActionType, AppActions } from 'core/store/actions';

/**
 * The initial state from which the state starts.
 */
const initialState: AppStatusState = {
  isLoading: false,
  loadingCounter: 0,
  page: {},
};

/**
 * The reducer function that is called in each action dispatch against the state.
 * @param state The current state.
 * @param action The action that will affect the state.
 */
export function appStatusReducer(state: AppStatusState = initialState, action: AppActions): AppStatusState {
  switch (action.type) {
    case AppActionType.APP_START_LOAD: {
      const loadingCounter = state.loadingCounter + 1;
      return {
        ...state,
        loadingCounter,
        isLoading: true,
      };
    }

    case AppActionType.APP_END_LOAD: {
      /* check if the loader should be hidden. */
      const loadingCounter = state.loadingCounter - 1;
      const isLoading = loadingCounter !== 0;
      return {
        ...state,
        loadingCounter,
        isLoading,
      };
    }

    case AppActionType.CHANGE_PAGE_INFO: {
      return {
        ...state,
        page: {
          ...action.payload,
        },
      };
    }

    default:
      return state;
  }
}
