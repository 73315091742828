import { AnnualLeaveLogType } from 'lookups/models';

/**
 * Represents the annual leave log types state.
 */
export class AnnualLeaveLogTypeState {
  /**
   * The list of annual leave log types.
   */
  public data: AnnualLeaveLogType[];

  /**
   * An error that may resulting during processing some actions.
   */
  public error: any;

  /**
   * Determines if there is a running load process.
   */
  public isLoading: boolean;

  /**
   * Determines if the last load process has been ended successfully.
   */
  public isLoaded: boolean;
}
