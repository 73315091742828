import { Action } from '@ngrx/store';

import { AppHttpResponse } from 'shared';
import { BankAccount } from 'finances/models';

/**
 * The AppSearch-module store bank accounts action types.
 */
export enum BankAccountsActionType {
  SEARCH_BANK_ACCOUNTS = '[AppSearch] [BankAccounts] Search Bank Accounts',
  SEARCH_BANK_ACCOUNTS_FAIL = '[AppSearch] [BankAccounts] Search Bank Accounts Fail',
  SEARCH_BANK_ACCOUNTS_SUCCESS = '[AppSearch] [BankAccounts] Search Bank Accounts Success',
}

/**
 * Represents a store bank accounts search action.
 */
export class SearchBankAccounts implements Action {
  /**
   * The type of the action.
   */
  readonly type = BankAccountsActionType.SEARCH_BANK_ACCOUNTS;

  /**
   * Fires a new bank accounts search action.
   * @param payload the search parameters,
   * if omitted, all bank accounts will be loaded.
   */
  constructor(
    public payload?: {
      name: string;
      banks: number[];
      page: number;
    }
  ) {}
}

/**
 * Represents a store bank accounts search-fail action.
 */
export class SearchBankAccountsFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = BankAccountsActionType.SEARCH_BANK_ACCOUNTS_FAIL;

  /**
   * Fires a new bank accounts search-fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store search-success action.
 */
export class SearchBankAccountsSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = BankAccountsActionType.SEARCH_BANK_ACCOUNTS_SUCCESS;

  /**
   * Fires a new bank accounts search-success action.
   * @param payload An object contains the list of bank accounts that matches the search criteria
   * in addition to the pagination info.
   */
  constructor(public payload: AppHttpResponse<BankAccount[]>) {}
}

/**
 * finances-module bank accounts action types.
 */
export type BankAccountsActions = SearchBankAccounts | SearchBankAccountsFail | SearchBankAccountsSuccess;
