import { createSelector } from '@ngrx/store';

import { selectLookupsState } from 'lookups/store/selectors/feature.selectors';
import { LookupsState, PurchaseRequestTypeState } from 'lookups/store/states';

/**
 * Gets the purchaseRequestType state.
 */
const selectPurchaseRequestTypes = createSelector(
  selectLookupsState,
  (state: LookupsState) => state.purchaseRequestTypes
);

/**
 * Gets the list of loaded purchaseRequestTypes.
 */
export const getPurchaseRequestTypes = createSelector(
  selectPurchaseRequestTypes,
  (state: PurchaseRequestTypeState) => state.data
);

/**
 * Gets the error that occurred in the state.
 */
export const getPurchaseRequestTypesError = createSelector(
  selectPurchaseRequestTypes,
  (state: PurchaseRequestTypeState) => state.error
);

/**
 * Determines if there is a running load process.
 */
export const getPurchaseRequestTypesIsLoading = createSelector(
  selectPurchaseRequestTypes,
  (state: PurchaseRequestTypeState) => state.isLoading
);

/**
 * Determines if the last load process has been ended successfully.
 */
export const getPurchaseRequestTypesIsLoaded = createSelector(
  selectPurchaseRequestTypes,
  (state: PurchaseRequestTypeState) => state.isLoaded
);
