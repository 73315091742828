import { findIndex } from 'lodash';

import { PettyCashRefundRequestsActions, PettyCashRefundRequestsActionType } from 'finances/store/actions';
import { PettyCashRefundRequestsState } from 'finances/store/states';

/**
 * The initial state from which the state starts.
 */
const initialState: PettyCashRefundRequestsState = {
  data: [],
  paginationInfo: {
    total: 0,
    page: 1,
    pageSize: 0,
    count: 0,
  },
  error: null,
  isSearching: false,
  isSearchCompleted: false,
  selectedPettyCashRefundRequest: null,
  isFinding: false,
  isFindCompleted: false,
  isCreating: false,
  isCreateCompleted: false,
  isUpdating: false,
  isUpdateCompleted: false,
};

/**
 * The reducer function that is called in each action dispatch against the state.
 * @param state The current state.
 * @param action The action that will affect the state.
 */
export function pettyCashRefundRequestsReducer(
  state: PettyCashRefundRequestsState = initialState,
  action: PettyCashRefundRequestsActions
): PettyCashRefundRequestsState {
  switch (action.type) {
    //#region SEARCH_PETTY_CASH_REFUND_REQUESTS

    case PettyCashRefundRequestsActionType.SEARCH_PETTY_CASH_REFUND_REQUESTS: {
      return {
        ...state,
        isSearching: true,
        isSearchCompleted: false,
        error: null,
      };
    }

    case PettyCashRefundRequestsActionType.SEARCH_PETTY_CASH_REFUND_REQUESTS_FAIL: {
      return {
        ...state,
        error: action.payload,
        isSearching: false,
        isSearchCompleted: false,
      };
    }

    case PettyCashRefundRequestsActionType.SEARCH_PETTY_CASH_REFUND_REQUESTS_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        paginationInfo: {
          ...action.payload.meta,
        },
        error: null,
        isSearching: false,
        isSearchCompleted: true,
      };
    }

    //#endregion SEARCH_PETTY_CASH_REFUND_REQUESTS

    //#region FIND_PETTY_CASH_REFUND_REQUEST

    case PettyCashRefundRequestsActionType.FIND_PETTY_CASH_REFUND_REQUEST: {
      return {
        ...state,
        selectedPettyCashRefundRequest: null,
        error: null,
        isFinding: true,
        isFindCompleted: false,
      };
    }

    case PettyCashRefundRequestsActionType.FIND_PETTY_CASH_REFUND_REQUEST_FAIL: {
      return {
        ...state,
        error: action.payload,
        isFinding: false,
        isFindCompleted: false,
      };
    }

    case PettyCashRefundRequestsActionType.FIND_PETTY_CASH_REFUND_REQUEST_SUCCESS: {
      let pettyCashRefundRequestsList = [...state.data];
      const pettyCashRefundRequestIndex = findIndex(
        pettyCashRefundRequestsList,
        (pettyCashRefundRequest) => pettyCashRefundRequest.id === action.payload.data.id
      );

      /* If petty cash refund request was found, update it. */
      if (pettyCashRefundRequestIndex >= 0) {
        pettyCashRefundRequestsList[pettyCashRefundRequestIndex] = action.payload.data;
      } else {
        /* else, insert it to the beginning of the array. */
        pettyCashRefundRequestsList = [action.payload.data, ...pettyCashRefundRequestsList];
      }

      return {
        ...state,
        data: pettyCashRefundRequestsList,
        selectedPettyCashRefundRequest: action.payload.data,
        error: null,
        isFinding: false,
        isFindCompleted: true,
      };
    }

    //#endregion FIND_PETTY_CASH_REFUND_REQUEST

    //#region CREATE_PETTY_CASH_REFUND_REQUEST

    case PettyCashRefundRequestsActionType.CREATE_PETTY_CASH_REFUND_REQUEST: {
      return {
        ...state,
        isCreating: true,
        isCreateCompleted: false,
        error: null,
      };
    }

    case PettyCashRefundRequestsActionType.CREATE_PETTY_CASH_REFUND_REQUEST_FAIL: {
      return {
        ...state,
        error: action.payload,
        isCreating: false,
        isCreateCompleted: false,
      };
    }

    case PettyCashRefundRequestsActionType.CREATE_PETTY_CASH_REFUND_REQUEST_SUCCESS: {
      return {
        ...state,
        data: [action.payload.data, ...state.data],
        selectedPettyCashRefundRequest: action.payload.data,
        error: null,
        isCreating: false,
        isCreateCompleted: true,
      };
    }

    //#endregion CREATE_PETTY_CASH_REFUND_REQUEST

    //#region UPDATE_PETTY_CASH_REFUND_REQUEST

    case PettyCashRefundRequestsActionType.UPDATE_PETTY_CASH_REFUND_REQUEST: {
      return {
        ...state,
        isUpdating: true,
        isUpdateCompleted: false,
        error: null,
      };
    }

    case PettyCashRefundRequestsActionType.UPDATE_PETTY_CASH_REFUND_REQUEST_FAIL: {
      return {
        ...state,
        error: action.payload,
        isUpdating: false,
        isUpdateCompleted: false,
      };
    }

    case PettyCashRefundRequestsActionType.UPDATE_PETTY_CASH_REFUND_REQUEST_SUCCESS: {
      let pettyCashRefundRequests = [...state.data];

      const pettyCashRefundRequestIndex = findIndex(
        pettyCashRefundRequests,
        (pettyCashRefundRequest) => pettyCashRefundRequest.id === action.payload.data.id
      );

      /* If cost center was found, update it. */
      if (pettyCashRefundRequestIndex >= 0) {
        pettyCashRefundRequests[pettyCashRefundRequestIndex] = action.payload.data;
      } else {
        /* else, insert it to the beginning of the array. */
        pettyCashRefundRequests = [action.payload.data, ...pettyCashRefundRequests];
      }

      return {
        ...state,
        data: pettyCashRefundRequests,
        selectedPettyCashRefundRequest: action.payload.data,
        error: null,
        isUpdating: false,
        isUpdateCompleted: true,
      };
    }

    //#endregion UPDATE_PETTY_CASH_REFUND_REQUEST

    default:
      return state;
  }
}
