<!-- page header starts -->
<app-page-header [info]="pageInfo"></app-page-header>
<!-- page header ends -->

<!-- create user form starts -->
<form [formGroup]="form" (ngSubmit)="submit()">
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-header mb-3">
          <h4 class="card-title">{{ 'SECURITY.USERS.USER_DATA_TITLE' | translate }}</h4>
        </div>
        <div class="card-content">
          <div class="px-3">
            <div class="row">
              <div class="form-group col-md-6">
                <label for="userType">{{ 'SECURITY.USERS.USER_DATA.USER_TYPE' | translate }}</label>
                <div class="custom-control custom-radio d-flex">
                  <div>
                    <input
                      type="radio"
                      id="SERVICE_PROVIDER"
                      name="userType"
                      class="custom-control-input pointer"
                      value="SERVICE_PROVIDER"
                      formControlName="userType"
                    />
                    <label class="custom-control-label pointer" for="SERVICE_PROVIDER">{{
                      'SECURITY.USERS.USER_DATA.SERVICE_PROVIDER' | translate
                    }}</label>
                  </div>
                  <div class="mx-5">
                    <input
                      type="radio"
                      id="REGULAR_USER"
                      name="userType"
                      class="custom-control-input pointer"
                      value="REGULAR_USER"
                      formControlName="userType"
                    />
                    <label class="custom-control-label pointer" for="REGULAR_USER">{{
                      'SECURITY.USERS.USER_DATA.REGULAR_USER' | translate
                    }}</label>
                  </div>
                </div>
              </div>
              <div class="form-group col-md-6">
                <label for="name">{{ 'SECURITY.USERS.USER_DATA.USER_NAME' | translate }}</label>
                <input
                  type="text"
                  id="name"
                  class="form-control round"
                  [placeholder]="'SECURITY.USERS.USER_DATA.USER_NAME_PLACEHOLDER' | translate"
                  maxlength="50"
                  formControlName="name"
                />
              </div>

              <ng-container *ngIf="!isServiceProvider">
                <div class="form-group col-md-12">
                  <label for="organizationId">{{
                    'SECURITY.USERS.USER_DATA.USER_ORGANIZATION_NAME' | translate
                  }}</label>
                  <ng-select
                    class="round"
                    formControlName="organizationId"
                    labelForId="organizationId"
                    searchable="true"
                    [placeholder]="'SECURITY.USERS.USER_DATA.USER_ORGANIZATION_NAME_PLACEHOLDER' | translate"
                    [notFoundText]="'SHARED.SEARCH.SEARCH_NOT_FOUND' | translate"
                    (open)="organizationsListVisibility = true"
                  >
                    <ng-option *ngFor="let organization of organizations" [value]="organization.id">
                      {{ organization.name }}
                    </ng-option>
                  </ng-select>
                </div>
              </ng-container>

              <div class="form-group col-md-6">
                <label for="username">{{ 'SECURITY.USERS.USER_DATA.USERNAME' | translate }}</label>
                <input
                  type="text"
                  id="username"
                  class="form-control round"
                  [placeholder]="'SECURITY.USERS.USER_DATA.USERNAME_PLACEHOLDER' | translate"
                  maxlength="50"
                  formControlName="username"
                />
              </div>
              <div class="form-group col-md-6">
                <label for="password">{{ 'SECURITY.USERS.USER_DATA.PASSWORD' | translate }}</label>
                <input
                  type="password"
                  id="password"
                  class="form-control round"
                  [placeholder]="'SECURITY.USERS.USER_DATA.PASSWORD_PLACEHOLDER' | translate"
                  maxlength="50"
                  formControlName="password"
                />
              </div>
            </div>
          </div>
        </div>

        <!-- user claims starts -->
        <app-user-claims-form [groups]="claimGroupsModel"></app-user-claims-form>
        <!-- user claims ends -->

        <div class="form-group col-12 d-flex justify-content-between mt-4">
          <button type="submit" class="btn btn-raised btn-success round" [disabled]="isCreating$ | async">
            <i class="fa fa-check"></i>
            <span class="mx-1">{{ 'SHARED.CONFIRM.SAVE' | translate }}</span>
          </button>
          <a class="btn btn-dark round" routerLink="/security/users">
            <i class="fa fa-undo"></i>
            <span class="mx-1">{{ 'SECURITY.USERS.USER_DATA.USERS_RETURN' | translate }}</span>
          </a>
        </div>
      </div>
    </div>
  </div>
</form>
<!-- create user form ends -->

<!-- confirm modal starts -->
<ng-template #confirmModalRef let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
      {{ 'SECURITY.USERS.CONFIRM_CREATE_USER_TITLE' | translate }}
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="d()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p class="text-success">{{ 'SECURITY.USERS.CONFIRM_CREATE_USER_MESSAGE' | translate }}</p>
  </div>
  <div class="modal-footer">
    <div class="modal-actions">
      <button type="button" class="btn btn-raised btn-success round" (click)="c(); confirm()" ngbAutofocus>
        <i class="fa fa-check"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.SAVE' | translate }}</span>
      </button>
      <button type="button" class="btn btn-outline-dark round" (click)="c()">
        <i class="fa fa-times"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.CANCEL' | translate }}</span>
      </button>
    </div>
  </div>
</ng-template>
<!-- confirm modal ends -->

<!-- organizations search starts -->
<app-organizations-search
  onlyActive="true"
  closeOnSelect="true"
  (organizations)="selectOrganization($event)"
  [(visible)]="organizationsListVisibility"
>
</app-organizations-search>
<!-- organizations search ends -->
