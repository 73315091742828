<!-- loans list starts -->
<div class="row">
  <div class="col-md-12">
    <div class="card-content">
      <div class="px-3">
        <div class="row">
          <div class="col-md-12">
            <form class="row" [formGroup]="searchForm" (ngSubmit)="search()">
              <div class="form-group col-md-4">
                <label for="employees">{{ 'FINANCES.HR.EMPLOYEES' | translate }}</label>
                <ng-select
                  class="round"
                  formControlName="employees"
                  [multiple]="true"
                  [closeOnSelect]="false"
                  labelForId="employees"
                  searchable="true"
                  [placeholder]="'FINANCES.HR.EMPLOYEES_PLACEHOLDER' | translate"
                  [notFoundText]="'SHARED.SEARCH.SEARCH_NOT_FOUND' | translate"
                  (open)="employeesListVisibility = true"
                >
                  <ng-option *ngFor="let employee of employees" [value]="employee.id">
                    {{
                      employee.employeePersonalDetail.fullName
                        | localize: employee.employeePersonalDetail.fullNameEn
                        | async
                    }}
                  </ng-option>
                </ng-select>
              </div>
              <div class="form-group col-md-4">
                <label for="departments">{{ 'FINANCES.HR.DEPARTMENTS' | translate }}</label>
                <ng-select
                  class="round"
                  formControlName="departments"
                  [multiple]="true"
                  [closeOnSelect]="false"
                  labelForId="departments"
                  searchable="true"
                  [placeholder]="'FINANCES.HR.DEPARTMENTS_PLACEHOLDER' | translate"
                  [notFoundText]="'SHARED.SEARCH.SEARCH_NOT_FOUND' | translate"
                  (open)="departmentsListVisibility = true"
                >
                  <ng-option *ngFor="let department of departments" [value]="department.id">
                    {{ department.name | localize: department.nameEn | async }}
                  </ng-option>
                </ng-select>
              </div>
              <div class="form-group col-md-4">
                <label for="locations">{{ 'FINANCES.HR.LOCATIONS' | translate }}</label>
                <ng-select
                  class="round"
                  formControlName="locations"
                  [multiple]="true"
                  [closeOnSelect]="false"
                  labelForId="locations"
                  searchable="true"
                  [placeholder]="'FINANCES.HR.LOCATIONS_PLACEHOLDER' | translate"
                  [notFoundText]="'SHARED.SEARCH.SEARCH_NOT_FOUND' | translate"
                  (open)="locationsListVisibility = true"
                >
                  <ng-option *ngFor="let location of locations" [value]="location.id">
                    {{ location.name | localize: location.nameEn | async }}
                  </ng-option>
                </ng-select>
              </div>
              <div class="form-group col-md-4">
                <label for="loanPaymentTypes">{{ 'FINANCES.HR.LOANS_TYPE' | translate }}</label>
                <ng-select
                  class="round"
                  formControlName="loanPaymentTypes"
                  [multiple]="true"
                  [closeOnSelect]="false"
                  labelForId="loanPaymentTypes"
                  searchable="true"
                  [placeholder]="'FINANCES.HR.LOANS_TYPE_PLACEHOLDER' | translate"
                  [notFoundText]="'SHARED.SEARCH.SEARCH_NOT_FOUND' | translate"
                >
                  <ng-option *ngFor="let loanPaymentType of loanPaymentTypes$ | async" [value]="loanPaymentType.id">
                    {{ loanPaymentType.name | localize: loanPaymentType.nameEn | async }}
                  </ng-option>
                </ng-select>
              </div>
              <div class="form-group col-md-4">
                <label for="fromDate">{{ 'SHARED.SEARCH.FROM_DATE' | translate }}</label>
                <div class="input-group round">
                  <input
                    class="form-control"
                    id="fromDate"
                    name="fromDate"
                    formControlName="fromDate"
                    [placeholder]="'SHARED.SEARCH.DATE_PLACEHOLDER' | translate"
                    ngbDatepicker
                    #fromDate="ngbDatepicker"
                  />
                  <div class="input-group-append">
                    <span
                      class="input-group-text gradient-light-blue-indigo"
                      style="cursor: pointer;"
                      (click)="fromDate.toggle()"
                    >
                      <i class="fa fa-calendar"></i>
                    </span>
                  </div>
                </div>
              </div>
              <div class="form-group col-md-4">
                <label for="toDate">{{ 'SHARED.SEARCH.TO_DATE' | translate }}</label>
                <div class="input-group round">
                  <input
                    class="form-control"
                    id="toDate"
                    name="toDate"
                    formControlName="toDate"
                    [placeholder]="'SHARED.SEARCH.DATE_PLACEHOLDER' | translate"
                    ngbDatepicker
                    #toDate="ngbDatepicker"
                  />
                  <div class="input-group-append">
                    <span
                      class="input-group-text gradient-light-blue-indigo"
                      style="cursor: pointer;"
                      (click)="toDate.toggle()"
                    >
                      <i class="fa fa-calendar"></i>
                    </span>
                  </div>
                </div>
              </div>
              <div class="col-md-auto form-group d-flex">
                <button
                  type="submit"
                  class="btn btn-raised btn-primary align-self-end round mx-1 mb-0"
                  [disabled]="isSearching$ | async"
                >
                  <i class="fa fa-search"></i>
                </button>
                <a class="btn btn-raised btn-success align-self-end round mx-1 mb-0" (click)="create()">
                  <i class="fa fa-plus"></i>
                  <span class="mx-1">{{ 'SHARED.CONFIRM.ADD' | translate }}</span>
                </a>
                <app-export-tools
                  *ngIf="(loans$ | async)?.length"
                  class="d-flex align-self-end mx-1"
                  targetElementId="loans-table-export-target-element"
                  [outputFileName]="'SHARED.EXPORT_TABLES.LOANS_TABLE' | translate"
                ></app-export-tools>
              </div>
            </form>
          </div>
          <div class="col-md-12" *ngIf="(loans$ | async)?.length; else noItems">
            <div class="row">
              <div class="col-md-12 alert alert-light text-center" role="alert">
                {{ 'SHARED.SEARCH.SEARCH_RESULTS' | translate }} ({{ (paginationInfo$ | async).total | toNumber }})
                {{ 'FINANCES.HR.LOAN' | translate }}
              </div>
              <div class="table-responsive">
                <table class="table text-center" id="loans-table-export-target-element">
                  <thead>
                    <tr>
                      <th scope="col"><i class="fa fa-list-ol"></i></th>
                      <th scope="col">
                        {{ 'FINANCES.HR.PERSON_NUMBER' | translate }}
                      </th>
                      <th scope="col">
                        {{ 'FINANCES.HR.EMPLOYEE' | translate }}
                      </th>
                      <th scope="col">
                        {{ 'FINANCES.HR.POSITION' | translate }}
                      </th>
                      <th scope="col">
                        {{ 'FINANCES.HR.DEPARTMENT' | translate }}
                      </th>
                      <th scope="col">
                        {{ 'FINANCES.HR.LOCATION' | translate }}
                      </th>
                      <th scope="col">
                        {{ 'FINANCES.HR.LOAN_TYPE' | translate }}
                      </th>
                      <th scope="col">{{ 'FINANCES.HR.VALUE' | translate }}</th>
                      <th scope="col">
                        {{ 'FINANCES.HR.SETTLEMENT_VALUE' | translate }}
                      </th>
                      <th scope="col">{{ 'FINANCES.HR.PAID' | translate }}</th>
                      <th scope="col">
                        {{ 'FINANCES.HR.REMAINING' | translate }}
                      </th>
                      <th scope="col">
                        {{ 'FINANCES.HR.SETTLEMENT_START_DATE' | translate }}
                      </th>
                      <th scope="col">
                        {{ 'FINANCES.HR.TRANSACTION_DATE' | translate }}
                      </th>
                      <th scope="col">
                        {{ 'FINANCES.HR.COUNTING_STATUS' | translate }}
                      </th>
                      <th scope="col">{{ 'SHARED.TOOLS' | translate }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let loan of loans$ | async; trackBy: trackItems; let i = index">
                      <th scope="row">{{ i + 1 }}</th>
                      <td>{{ loan.employee.employeeAssignmentDetail.personNumber | toNumber }}</td>
                      <td class="fit-width">
                        {{
                          loan.employee.employeePersonalDetail.fullName
                            | localize: loan.employee.employeePersonalDetail.fullNameEn
                            | async
                        }}
                      </td>
                      <td class="fit-width">
                        {{
                          loan.employee.employeeAssignmentDetail.position.name
                            | localize: loan.employee.employeeAssignmentDetail.position.nameEn
                            | async
                        }}
                      </td>
                      <td class="fit-width">
                        {{
                          loan.employee.employeeAssignmentDetail.department?.name
                            | localize: loan.employee.employeeAssignmentDetail.department?.nameEn
                            | async
                        }}
                      </td>
                      <td class="fit-width">
                        {{
                          loan.employee.employeeAssignmentDetail.location?.name
                            | localize: loan.employee.employeeAssignmentDetail.location?.nameEn
                            | async
                        }}
                      </td>
                      <td class="fit-width">
                        {{ loan.loanPaymentType?.name | localize: loan.loanPaymentType?.nameEn | async }}
                      </td>
                      <td>
                        <span class="badge bg-info round">
                          {{ loan.value | toDecimal }}
                        </span>
                      </td>
                      <td>
                        <span class="badge bg-info round">
                          {{ loan.settlementValue | toDecimal }}
                        </span>
                      </td>
                      <td>
                        <span class="badge bg-warning round">
                          {{ loan.paid | toDecimal }}
                        </span>
                      </td>
                      <td>
                        <span class="badge bg-danger round">
                          {{ loan.remaining | toDecimal }}
                        </span>
                      </td>
                      <td class="fit-width">
                        <span [ngbTooltip]="loan.settlementStartDate | date: 'time'">
                          {{ loan.settlementStartDate | date }}
                        </span>
                      </td>
                      <td class="fit-width">
                        <span [ngbTooltip]="loan.transactionDate | date: 'time'">
                          {{ loan.transactionDate | date }}
                        </span>
                      </td>
                      <td>
                        <app-status
                          [status]="loan.enableSettlement"
                          [activeText]="'FINANCES.HR.ENABLED_SETTLEMENT' | translate"
                          [notActiveText]="'FINANCES.HR.NOT_ENABLED_SETTLEMENT' | translate"
                        >
                        </app-status>
                      </td>
                      <td class="fit-width">
                        <a
                          class="info p-0"
                          (click)="selectedLoan = loan; openInfoModal(infoEmployeeLoanModalRef)"
                          [ngbTooltip]="'FINANCES.HR.INFO_DISPLAY' | translate"
                        >
                          <i class="fa fa-info font-medium-3 mr-2"></i>
                        </a>
                        <a
                          class="info p-0"
                          (click)="update(loan)"
                          [ngbTooltip]="
                            'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.UPDATE_LOAN_DATA' | translate
                          "
                        >
                          <i class="fa fa-edit font-medium-3 mr-2"></i>
                        </a>
                        <a
                          class="info p-0"
                          [ngbTooltip]="'FINANCES.BANK_ACCOUNTS.BANK_ACCOUNT_DATA.JOURNAL_LINE_DISPLAY' | translate"
                          (click)="selectedLoan = loan; openModal(viewJournalModalRef)"
                        >
                          <i class="fa fa-calendar font-medium-3 mr-2"></i>
                        </a>
                        <a
                          *ngIf="[Claims.CREATE_EMPLOYEE] | authorize | async"
                          class="info p-0"
                          [routerLink]="['/hr/employees/files', loan.employeeId]"
                          fragment="loans"
                          [ngbTooltip]="'HR.EMPLOYEES.EMPLOYEE_DATA.EMPLOYEE_FILE' | translate"
                        >
                          <i class="fa fa-folder font-medium-3 mr-2"></i>
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div class="d-flex justify-content-between p-2">
                  <ngb-pagination
                    *ngIf="paginationInfo$ | async; let pagination"
                    maxSize="5"
                    [collectionSize]="pagination.total"
                    [pageSize]="pagination.pageSize"
                    [page]="pagination.page"
                    (pageChange)="pageChanged($event)"
                    boundaryLinks="true"
                  >
                    <ng-template ngbPaginationFirst>{{ 'SHARED.PAGINATION.PAGINATION_FIRST' | translate }}</ng-template>
                    <ng-template ngbPaginationPrevious>{{
                      'SHARED.PAGINATION.PAGINATION_PREVIOUS' | translate
                    }}</ng-template>
                    <ng-template ngbPaginationNext>{{ 'SHARED.PAGINATION.PAGINATION_NEXT' | translate }}</ng-template>
                    <ng-template ngbPaginationLast>{{ 'SHARED.PAGINATION.PAGINATION_LAST' | translate }}</ng-template>
                  </ngb-pagination>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- loans list ends -->

<!-- no items template starts -->
<ng-template #noItems>
  <div class="col-md-12 alert alert-warning mt-3 text-center" role="alert">
    {{ 'HR.EMPLOYEES.LOANS_NOT_FOUND' | translate }}
    <button type="button" class="btn btn-raised btn-success round" (click)="create()">
      <i class="fa fa-plus"></i>
      <span class="mx-1">{{ 'SHARED.CONFIRM.ADD' | translate }}</span>
    </button>
  </div>
</ng-template>
<!-- no items template ends -->

<!-- create loans modal starts -->
<ng-template #createModalRef let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
      {{ 'HR.EMPLOYEES.ADD_LOAN' | translate }}
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="d()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-md-12">
        <form [formGroup]="createForm" (ngSubmit)="submit()">
          <div class="row">
            <div class="form-group col-md-6">
              <label for="employeeId">{{ 'FINANCES.HR.EMPLOYEE' | translate }}</label>
              <ng-select
                class="round"
                formControlName="employeeId"
                labelForId="employeeId"
                searchable="true"
                [placeholder]="'FINANCES.HR.EMPLOYEE_PLACEHOLDER' | translate"
                [notFoundText]="'SHARED.SEARCH.SEARCH_NOT_FOUND' | translate"
                (open)="employeeListVisibility = true"
              >
                <ng-option *ngFor="let employee of employees" [value]="employee.id">
                  {{
                    employee.employeePersonalDetail.fullName
                      | localize: employee.employeePersonalDetail.fullNameEn
                      | async
                  }}
                </ng-option>
              </ng-select>
            </div>
            <div class="form-group col-md-6">
              <label for="loanPaymentTypeId">{{ 'FINANCES.HR.LOAN_TYPE' | translate }}</label>
              <ng-select
                class="round"
                formControlName="loanPaymentTypeId"
                labelForId="loanPaymentTypeId"
                searchable="true"
                [placeholder]="'FINANCES.HR.LOAN_TYPE_PLACEHOLDER' | translate"
                [notFoundText]="'SHARED.SEARCH.SEARCH_NOT_FOUND' | translate"
              >
                <ng-option *ngFor="let loanPaymentType of loanPaymentTypes$ | async" [value]="loanPaymentType.id">
                  {{ loanPaymentType.name | localize: loanPaymentType.nameEn | async }}
                </ng-option>
              </ng-select>
            </div>
            <div
              *ngIf="this.createForm?.value?.loanPaymentTypeId === loanPaymentTypeIdForBank"
              class="form-group col-md-6"
            >
              <label for="bankAccountId">
                {{ 'FINANCES.HR.BANK_ACCOUNT' | translate }}
              </label>
              <ng-select
                class="round"
                formControlName="bankAccountId"
                labelForId="bankAccountId"
                searchable="true"
                [placeholder]="'FINANCES.HR.BANK_ACCOUNT_PLACEHOLDER' | translate"
                [notFoundText]="'SHARED.SEARCH.SEARCH_NOT_FOUND' | translate"
                (open)="bankAccountsListVisibility = true"
              >
                <ng-option *ngFor="let bankAccount of bankAccounts" [value]="bankAccount.id">
                  {{ bankAccount.bank.name | localize: bankAccount.bank.nameEn | async }}-
                  {{ bankAccount.name | localize: bankAccount.nameEn | async }}
                </ng-option>
              </ng-select>
            </div>
            <div class="form-group col-md-6">
              <label for="value">{{ 'FINANCES.HR.LOAN_VALUE' | translate }}</label>
              <input
                type="text"
                [mask]="DECIMAL_MASK"
                thousandSeparator=","
                id="value"
                class="form-control round"
                [placeholder]="'FINANCES.HR.LOAN_VALUE_PLACEHOLDER' | translate"
                formControlName="value"
              />
            </div>
            <div
              class="form-group col-md-6"
              *ngIf="this.createForm?.value?.loanPaymentTypeId === loanPaymentTypeIdForBank"
            >
              <label for="bankFee">{{ 'FINANCES.HR.BANK_FEE_VALUE' | translate }}</label>
              <input
                type="text"
                id="bankFee"
                [mask]="DECIMAL_MASK"
                thousandSeparator=","
                minLength="0"
                class="form-control round"
                [placeholder]="'FINANCES.HR.BANK_FEE_VALUE_PLACEHOLDER' | translate"
                formControlName="bankFee"
              />
            </div>
            <div
              class="form-group col-md-6"
              *ngIf="this.createForm?.value?.loanPaymentTypeId === loanPaymentTypeIdForBank"
            >
              <label for="bankFeeTaxValue">{{ 'FINANCES.HR.BANK_FEE_TAX_VALUE' | translate }}</label>
              <input
                type="text"
                id="bankFeeTaxValue"
                [mask]="DECIMAL_MASK"
                thousandSeparator=","
                minLength="0"
                class="form-control round"
                [placeholder]="'FINANCES.HR.BANK_FEE_TAX_VALUE_PLACEHOLDER' | translate"
                formControlName="bankFeeTaxValue"
              />
            </div>
            <div
              class="form-group col-md-6"
              *ngIf="this.createForm?.value?.loanPaymentTypeId === loanPaymentTypeIdForCash"
            >
              <label for="bankAccountId">{{ 'FINANCES.HR.CASH_PAYMENT_ACCOUNT' | translate }}</label>
              <ng-select
                class="round"
                formControlName="bankAccountId"
                labelForId="bankAccountId"
                searchable="true"
                [placeholder]="'FINANCES.HR.CASH_PAYMENT_ACCOUNT_PLACEHOLDER' | translate"
                [notFoundText]="'SHARED.SEARCH.SEARCH_NOT_FOUND' | translate"
                (open)="bankAccountsListVisibility = true"
              >
                <ng-option *ngFor="let bankAccount of bankAccounts" [value]="bankAccount.id">
                  {{ bankAccount.bank.name | localize: bankAccount.bank.nameEn | async }} -
                  {{ bankAccount.name | localize: bankAccount.nameEn | async }}
                </ng-option>
              </ng-select>
            </div>
            <div class="form-group col-md-6">
              <label for="settlementValue">{{ 'FINANCES.HR.SETTLEMENT_VALUE_INPUT' | translate }}</label>
              <input
                type="text"
                [mask]="DECIMAL_MASK"
                thousandSeparator=","
                id="settlementValue"
                class="form-control round"
                [placeholder]="'FINANCES.HR.SETTLEMENT_VALUE_INPUT_PLACEHOLDER' | translate"
                formControlName="settlementValue"
              />
            </div>
            <div class="form-group col-md-6">
              <label for="transactionDate">{{ 'FINANCES.HR.TRANSACTION_DATE' | translate }}</label>
              <div class="input-group round">
                <input
                  class="form-control"
                  id="transactionDate"
                  name="transactionDate"
                  formControlName="transactionDate"
                  [placeholder]="'SHARED.SEARCH.DATE_PLACEHOLDER' | translate"
                  ngbDatepicker
                  #transactionDate="ngbDatepicker"
                />
                <div class="input-group-append">
                  <span
                    class="input-group-text gradient-light-blue-indigo"
                    style="cursor: pointer;"
                    (click)="transactionDate.toggle()"
                  >
                    <i class="fa fa-calendar"></i>
                  </span>
                </div>
              </div>
            </div>
            <div class="form-group col-md-6">
              <label for="settlementStartDate">{{ 'FINANCES.HR.SETTLEMENT_START_DATE' | translate }}</label>
              <div class="input-group round">
                <input
                  class="form-control"
                  id="settlementStartDate"
                  name="settlementStartDate"
                  formControlName="settlementStartDate"
                  [placeholder]="'SHARED.SEARCH.DATE_PLACEHOLDER' | translate"
                  ngbDatepicker
                  #settlementStartDate="ngbDatepicker"
                />
                <div class="input-group-append">
                  <span
                    class="input-group-text gradient-light-blue-indigo"
                    style="cursor: pointer;"
                    (click)="settlementStartDate.toggle()"
                  >
                    <i class="fa fa-calendar"></i>
                  </span>
                </div>
              </div>
            </div>
            <div class="form-group col-md-6">
              <input type="checkbox" class="cursor-pointer" formControlName="enableSettlement" />
              <span class="mx-2 mt-0 pt-0">{{ 'FINANCES.HR.ENABLE_SETTLEMENT' | translate }}</span>
            </div>
            <div class="form-group col-md-12">
              <label for="notes">{{ 'FINANCES.HR.NOTES' | translate }}</label>
              <textarea
                id="notes"
                class="form-control round"
                [placeholder]="'FINANCES.HR.NOTES_PLACEHOLDER' | translate"
                rows="2"
                minlength="0"
                maxlength="500"
                formControlName="notes"
              ></textarea>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="modal-actions">
      <button
        type="button"
        class="btn btn-raised btn-success round"
        [disabled]="isCreating$ | async"
        (click)="submit()"
        ngbAutofocus
      >
        <i class="fa fa-check"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.SAVE' | translate }}</span>
      </button>
      <button type="button" class="btn btn-outline-dark round" (click)="c()">
        <i class="fa fa-times"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.CANCEL' | translate }}</span>
      </button>
    </div>
  </div>
</ng-template>
<!-- create loans modal ends -->

<!-- update loan modal starts -->
<ng-template #updateModalRef let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
      {{ 'FINANCES.HR.UPDATE_LOAN_DATA' | translate }}
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="d()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-md-12">
        <form [formGroup]="updateForm" (ngSubmit)="confirmUpdate()">
          <div class="row">
            <div class="form-group col-6">
              <label for="value">{{ 'FINANCES.HR.LOAN_VALUE' | translate }}</label>
              <input
                type="text"
                id="value"
                class="form-control round"
                readonly
                [value]="selectedLoan.value | toDecimal"
              />
            </div>
            <div class="form-group col-md-6">
              <label for="settlementValue">{{ 'FINANCES.HR.SETTLEMENT_VALUE_INPUT' | translate }}</label>
              <input
                type="text"
                [mask]="DECIMAL_MASK"
                thousandSeparator=","
                id="settlementValue"
                class="form-control round"
                [placeholder]="'FINANCES.HR.SETTLEMENT_VALUE_INPUT_PLACEHOLDER' | translate"
                formControlName="settlementValue"
              />
            </div>
            <div class="form-group col-md-6">
              <label for="transactionDate">{{ 'FINANCES.HR.TRANSACTION_DATE' | translate }}</label>
              <input
                type="text"
                id="transactionDate"
                class="form-control round"
                readonly
                [value]="selectedLoan.transactionDate | date: 'datetime'"
              />
            </div>
            <div class="form-group col-md-6">
              <label for="settlementStartDate">{{ 'FINANCES.HR.SETTLEMENT_START_DATE' | translate }}</label>
              <div class="input-group round">
                <input
                  class="form-control"
                  id="settlementStartDate"
                  name="settlementStartDate"
                  formControlName="settlementStartDate"
                  [placeholder]="'SHARED.SEARCH.DATE_PLACEHOLDER' | translate"
                  ngbDatepicker
                  #settlementStartDate="ngbDatepicker"
                />
                <div class="input-group-append">
                  <span
                    class="input-group-text gradient-light-blue-indigo"
                    style="cursor: pointer;"
                    (click)="settlementStartDate.toggle()"
                  >
                    <i class="fa fa-calendar"></i>
                  </span>
                </div>
              </div>
            </div>
            <div class="form-group col-md-6">
              <input type="checkbox" class="cursor-pointer" formControlName="enableSettlement" />
              <span class="mx-2 mt-0 pt-0">{{ 'FINANCES.HR.ENABLE_SETTLEMENT' | translate }}</span>
            </div>
            <div class="form-group col-md-12">
              <label for="notes">{{ 'FINANCES.HR.NOTES' | translate }}</label>
              <textarea
                id="notes"
                class="form-control round"
                [innerText]="selectedLoan.notes"
                [placeholder]="'FINANCES.HR.NOTES_PLACEHOLDER' | translate"
                readonly
              ></textarea>
            </div>
            <div class="form-group col-md-12" *ngIf="selectedLoan.loanUpdateLogs?.length">
              <div class="col-md-12 alert alert-light text-center" role="alert">
                ({{ selectedLoan.loanUpdateLogs?.length | toNumber }})
                {{ 'FINANCES.HR.LOGS' | translate }}
              </div>
              <div class="col-md-12">
                <div class="table-responsive">
                  <table class="table text-center" id="loans-table-export-target-element">
                    <thead>
                      <tr>
                        <th scope="col"><i class="fa fa-list-ol"></i></th>
                        <th scope="col">
                          {{ 'FINANCES.HR.CHANGE_TYPE' | translate }}
                        </th>
                        <th scope="col">
                          {{ 'FINANCES.HR.FROM' | translate }}
                        </th>
                        <th scope="col">
                          {{ 'FINANCES.HR.TO' | translate }}
                        </th>
                        <th scope="col">
                          {{ 'FINANCES.HR.UPDATED_AT' | translate }}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let loanUpdateLog of selectedLoan.loanUpdateLogs; let i = index">
                        <th scope="row">{{ i + 1 }}</th>
                        <td>
                          {{
                            loanUpdateLog.loanUpdateLogType.name
                              | localize: loanUpdateLog.loanUpdateLogType.nameEn
                              | async
                          }}
                        </td>
                        <td>
                          <span
                            *ngIf="loanUpdateLog.fromSettlementStartDate"
                            [ngbTooltip]="loanUpdateLog.fromSettlementStartDate | date: 'time'"
                          >
                            {{ loanUpdateLog.fromSettlementStartDate | date }}
                          </span>
                          <span *ngIf="loanUpdateLog.fromSettlementValue">
                            {{ loanUpdateLog.fromSettlementValue | toDecimal }}
                          </span>
                        </td>
                        <td>
                          <span
                            *ngIf="loanUpdateLog.toSettlementStartDate"
                            [ngbTooltip]="loanUpdateLog.toSettlementStartDate | date: 'time'"
                          >
                            {{ loanUpdateLog.toSettlementStartDate | date }}
                          </span>
                          <span *ngIf="loanUpdateLog.toSettlementValue">
                            {{ loanUpdateLog.toSettlementValue | toDecimal }}
                          </span>
                        </td>
                        <td>
                          <span [ngbTooltip]="loanUpdateLog.updatedAt | date: 'time'">
                            {{ loanUpdateLog.updatedAt | date }}
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <!-- A hidden submit button to allow user submit form with Enter -->
            <button type="submit" hidden></button>
          </div>
        </form>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="modal-actions">
      <button
        type="button"
        class="btn btn-raised btn-success round"
        [disabled]="isUpdating$ | async"
        (click)="confirmUpdate()"
        ngbAutofocus
      >
        <i class="fa fa-check"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.SAVE' | translate }}</span>
      </button>
      <button type="button" class="btn btn-outline-dark round" (click)="c()">
        <i class="fa fa-times"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.CANCEL' | translate }}</span>
      </button>
    </div>
  </div>
</ng-template>
<!-- update loan modal ends -->

<!-- no attachments template starts -->
<ng-template #noAttachments>
  <div class="col-md-12 alert alert-warning mt-3 text-center" role="alert">
    {{ 'FINANCES.HR.ATTACHMENTS_NOT_FOUND' | translate }}
  </div>
</ng-template>
<!-- no attachments template ends -->

<!-- info loan modal starts -->
<ng-template #infoEmployeeLoanModalRef let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title text-primary" id="modal-basic-title">
      {{ 'FINANCES.HR.INFO_EMPLOYEE_LOAN_TITLE' | translate }}
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="d()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-12">
        <div class="row">
          <div class="form-group col-6">
            <label for="employeeNumber">{{ 'FINANCES.HR.EMPLOYEE_NUMBER' | translate }}</label>
            <input
              type="text"
              id="employeeNumber"
              class="form-control round"
              readonly
              [value]="selectedLoan.employee.employeeAssignmentDetail.personNumber"
            />
          </div>
          <div class="form-group col-6">
            <label for="employee">{{ 'FINANCES.HR.EMPLOYEE' | translate }}</label>
            <input
              type="text"
              id="employee"
              class="form-control round"
              readonly
              [value]="
                selectedLoan.employee.employeePersonalDetail.fullName
                  | localize: selectedLoan.employee.employeePersonalDetail.fullNameEn
                  | async
              "
            />
          </div>
          <div class="form-group col-6">
            <label for="position">{{ 'FINANCES.HR.POSITION' | translate }}</label>
            <input
              type="text"
              id="position"
              class="form-control round"
              readonly
              [value]="
                selectedLoan.employee.employeeAssignmentDetail.position.name
                  | localize: selectedLoan.employee.employeeAssignmentDetail.position.nameEn
                  | async
              "
            />
          </div>
          <div class="form-group col-6">
            <label for="department">{{ 'FINANCES.HR.DEPARTMENT' | translate }}</label>
            <input
              type="text"
              id="department"
              class="form-control round"
              readonly
              [value]="
                selectedLoan.employee.employeeAssignmentDetail.department?.name
                  | localize: selectedLoan.employee.employeeAssignmentDetail.department?.nameEn
                  | async
              "
            />
          </div>
          <div class="form-group col-6">
            <label for="location">{{ 'FINANCES.HR.LOCATION' | translate }}</label>
            <input
              type="text"
              id="location"
              class="form-control round"
              readonly
              [value]="
                selectedLoan.employee.employeeAssignmentDetail.location?.name
                  | localize: selectedLoan.employee.employeeAssignmentDetail.location?.nameEn
                  | async
              "
            />
          </div>
          <div class="form-group col-6">
            <label for="value">{{ 'FINANCES.HR.VALUE' | translate }}</label>
            <input
              type="text"
              id="value"
              class="form-control round"
              readonly
              [value]="selectedLoan.value | toDecimal"
            />
          </div>
          <div class="form-group col-6">
            <label for="settlementValue">{{ 'FINANCES.HR.SETTLEMENT_VALUE' | translate }}</label>
            <input
              type="text"
              id="settlementValue"
              class="form-control round"
              readonly
              [value]="selectedLoan.settlementValue | toDecimal"
            />
          </div>
          <div class="form-group col-6">
            <label for="paid">{{ 'FINANCES.HR.PAID' | translate }}</label>
            <input type="text" id="paid" class="form-control round" readonly [value]="selectedLoan.paid | toDecimal" />
          </div>
          <div class="form-group col-6">
            <label for="remaining">{{ 'FINANCES.HR.REMAINING' | translate }}</label>
            <input
              type="text"
              id="remaining"
              class="form-control round"
              readonly
              [value]="selectedLoan.remaining | toDecimal"
            />
          </div>
          <div class="form-group col-md-6">
            <label for="loanPaymentTypeId">{{ 'FINANCES.HR.LOAN_TYPE' | translate }}</label>
            <input
              type="text"
              class="form-control round"
              id="loanPaymentTypeId"
              readonly
              [value]="selectedLoan.loanPaymentType.name | localize: selectedLoan.loanPaymentType.nameEn | async"
            />
          </div>
          <div class="form-group col-md-6">
            <label for="bankAccountId">
              {{ 'FINANCES.HR.BANK_ACCOUNT' | translate }}
            </label>
            <input
              type="text"
              class="form-control round"
              id="loanPaymentTypeId"
              readonly
              [value]="selectedLoan.bankAccount.bank.name | localize: selectedLoan.bankAccount.bank.nameEn | async"
            />
          </div>
          <div class="form-group col-6">
            <label for="settlementStartDate">{{ 'FINANCES.HR.SETTLEMENT_START_DATE' | translate }}</label>
            <input
              type="text"
              id="settlementStartDate"
              class="form-control round"
              readonly
              [value]="selectedLoan.settlementStartDate | date: 'datetime'"
            />
          </div>
          <div class="form-group col-6">
            <label for="transactionDate">{{ 'FINANCES.HR.TRANSACTION_DATE' | translate }}</label>
            <input
              type="text"
              id="transactionDate"
              class="form-control round"
              readonly
              [value]="selectedLoan.transactionDate | date: 'datetime'"
            />
          </div>
          <div class="form-group col-6">
            <label for="createdAt">{{ 'SHARED.SEARCH.CREATED_AT' | translate }}</label>
            <input
              type="text"
              id="createdAt"
              class="form-control round"
              readonly
              [value]="selectedLoan.createdAt | date: 'datetime'"
            />
          </div>
          <div class="form-group col-12">
            <label for="user">{{ 'FINANCES.HR.USER' | translate }}</label>
            <input type="text" id="user" class="form-control round" readonly [value]="selectedLoan.user.name" />
          </div>
          <div class="form-group col-md-12">
            <label for="notes">{{ 'FINANCES.HR.NOTES' | translate }}</label>
            <textarea id="notes" class="form-control round" [innerText]="selectedLoan.notes" readonly></textarea>
          </div>
          <div class="form-group col-md-12">
            <label>{{ 'FINANCES.HR.ATTACHMENTS' | translate }}</label>
            <app-files-viewer
              *ngIf="selectedLoan.employeeLoanAttachments?.length; else noAttachments"
              [files]="getAttachmentsUrls(selectedLoan.employeeLoanAttachments)"
            ></app-files-viewer>
          </div>
          <div class="form-group col-md-12" *ngIf="selectedLoan.loanUpdateLogs?.length">
            <div class="col-md-12 alert alert-light text-center" role="alert">
              ({{ selectedLoan.loanUpdateLogs?.length | toNumber }})
              {{ 'FINANCES.HR.LOGS' | translate }}
            </div>
            <div class="col-md-12">
              <div class="table-responsive">
                <table class="table text-center">
                  <thead>
                    <tr>
                      <th scope="col"><i class="fa fa-list-ol"></i></th>
                      <th scope="col">
                        {{ 'FINANCES.HR.CHANGE_TYPE' | translate }}
                      </th>
                      <th scope="col">
                        {{ 'FINANCES.HR.FROM' | translate }}
                      </th>
                      <th scope="col">
                        {{ 'FINANCES.HR.TO' | translate }}
                      </th>
                      <th scope="col">
                        {{ 'FINANCES.HR.UPDATED_AT' | translate }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let loanUpdateLog of selectedLoan.loanUpdateLogs; let i = index">
                      <th scope="row">{{ i + 1 }}</th>
                      <td>
                        {{
                          loanUpdateLog.loanUpdateLogType.name
                            | localize: loanUpdateLog.loanUpdateLogType.nameEn
                            | async
                        }}
                      </td>
                      <td>
                        <span
                          *ngIf="loanUpdateLog.fromSettlementStartDate"
                          [ngbTooltip]="loanUpdateLog.fromSettlementStartDate | date: 'time'"
                        >
                          {{ loanUpdateLog.fromSettlementStartDate | date }}
                        </span>
                        <span *ngIf="loanUpdateLog.fromSettlementValue">
                          {{ loanUpdateLog.fromSettlementValue | toDecimal }}
                        </span>
                      </td>
                      <td>
                        <span
                          *ngIf="loanUpdateLog.toSettlementStartDate"
                          [ngbTooltip]="loanUpdateLog.toSettlementStartDate | date: 'time'"
                        >
                          {{ loanUpdateLog.toSettlementStartDate | date }}
                        </span>
                        <span *ngIf="loanUpdateLog.toSettlementValue">
                          {{ loanUpdateLog.toSettlementValue | toDecimal }}
                        </span>
                      </td>
                      <td>
                        <span [ngbTooltip]="loanUpdateLog.updatedAt | date: 'time'">
                          {{ loanUpdateLog.updatedAt | date }}
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="modal-actions">
      <button type="button" class="btn btn-outline-dark round" (click)="c()">
        <i class="fa fa-times"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.CANCEL' | translate }}</span>
      </button>
    </div>
  </div>
</ng-template>
<!-- info loan modal ends -->

<!-- view loan journal modal starts -->
<ng-template #viewJournalModalRef let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title text-info" id="modal-basic-title">
      {{ 'FINANCES.HR.LOAN_JOURNAL_LINE_DISPLAY' | translate }}
    </h4>

    <button type="button" class="close" aria-label="Close" (click)="d()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <app-journal-details [journal]="selectedLoan.journalAssociations[0]?.journal"> </app-journal-details>
  </div>
  <div class="modal-footer">
    <div class="modal-actions">
      <button *ngIf="false" type="button" class="btn btn-outline-dark round" (click)="c()">
        <i class="fa fa-times"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.CANCEL' | translate }}</span>
      </button>
    </div>
  </div>
</ng-template>
<!-- view loan journal modal ends -->

<!-- employees search starts -->
<employees-search [(visible)]="employeesListVisibility" (employees)="selectEmployees($event)"> </employees-search>
<!-- employees search ends -->

<!-- departments search starts -->
<departments-search [(visible)]="departmentsListVisibility" (departments)="selectDepartments($event)">
</departments-search>
<!-- departments search ends -->

<!-- locations search starts -->
<locations-search [(visible)]="locationsListVisibility" (locations)="selectLocations($event)"> </locations-search>
<!-- locations search ends -->

<!-- employees search starts -->
<employees-search
  onlyActive="true"
  closeOnSelect="true"
  (employees)="selectEmployee($event)"
  [(visible)]="employeeListVisibility"
>
</employees-search>
<!-- employees search ends-->

<!-- banks search starts -->
<app-bank-accounts-search
  onlyActive="true"
  closeOnSelect="true"
  [(visible)]="bankAccountsListVisibility"
  (bankAccounts)="selectBankAccount($event)"
>
</app-bank-accounts-search>
<!-- banks search ends -->
