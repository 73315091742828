import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { Location as AngularLocation } from '@angular/common';
import { ActivatedRoute } from '@angular/router';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription, BehaviorSubject, Observable } from 'rxjs';
import { skip, tap } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { Decimal } from 'decimal.js';

import * as fromSettingsStore from 'settings/store';
import * as fromPrintingStore from 'printing/store';
import * as fromSalesStore from 'sales/store';
import * as fromAuthStore from 'auth/store';
import * as fromFinancesStore from 'finances/store';
import {
  PageInfo,
  NotificationMessage,
  CustomValidators,
  NotificationService,
  TranslationService,
  PrintService,
} from 'shared';
import { Customer, CreateSalesInvoiceProductInput, SaleInvoice, CustomerAsset } from 'sales/models';
import { Tax } from 'settings/models';
import { Location, ProductsFormItem } from 'stores/models';
import { Account, CreateJournalLineInput, JournalLineFormItem } from 'finances/models';
import { CanComponentDeactivate } from 'auth/models';
import { ProductsFormUtil } from 'stores';

/**
 * The maintenance invoice pages.
 */
enum PAGES {
  details = 'details',
  journal = 'journal',
}

@Component({
  selector: 'app-create-maintenance-invoice',
  templateUrl: './create-maintenance-invoice.component.html',
  styles: [],
})
export class CreateMaintenanceInvoiceComponent implements OnInit, OnDestroy, CanComponentDeactivate {
  /**
   * The confirm maintenance invoice modal template reference.
   */
  @ViewChild('confirmMaintenanceInvoiceModalRef') confirmMaintenanceInvoiceModalRef: ElementRef<any>;

  /**
   * The notes modal template reference.
   */
  @ViewChild('notesModalRef') notesModalRef: ElementRef<any>;

  /**
   * The reset modal template reference.
   */
  @ViewChild('resetModalRef') resetModalRef: ElementRef<any>;

  /**
   * Gets or sets the information about the current page.
   */
  pageInfo: PageInfo = {
    title: 'SALES.INVOICES.CREATE_MAINTENANCE_INVOICE_PAGE_TITLE',
    icon: 'fa fa-cart-plus',
  };

  /**
   * Gets or sets the selected page.
   * @default 'details'
   */
  activePage: PAGES = PAGES.details;

  /**
   * Sets the id of the current invoice.
   */
  invoiceId: number;

  /**
   * Shows or hide the locations list.
   */
  locationsListVisibility = false;

  /**
   * The list of active locations.
   */
  locations: Location[] = [];

  /**
   * The list of active customers.
   */
  customers: Customer[] = [];

  /**
   * The list of taxes.
   */
  taxes$: Observable<Tax[]>;

  /**
   * The list of current selected customer's assets.
   */
  assets: CustomerAsset[] = [];

  /**
   * Gets the trade debtors of the account.
   */
  tradeDebtorsAccount: Account;

  /**
   * Gets the main activities sales revenue of the account.
   */
  mainActivitiesSalesRevenueAccount: Account;

  /**
   * Gets the value added tax  of the account.
   */
  valueAddedTaxCreditAccount: Account;

  /**
   * Gets the sales -invoice.
   */
  salesInvoice: Observable<SaleInvoice>;

  /**
   * The create maintenance invoice form.
   */
  form: FormGroup;

  /**
   * Indicates whether there is a create-invoice process is running or not.
   */
  isCreating$: Observable<boolean>;

  /**
   * Add new product form items to the products form.
   */
  newProducts: ProductsFormItem[];

  /**
   * Sets the initial journal lines should be added to the journals form.
   * @param journal lines An object contains `count` of initial blank journal lines.
   * @param journal lines A single journal line details to be added to the form.
   * @param journal lines A list of journal lines details to be added to the form.
   */

  initialJournalLinesSubject: BehaviorSubject<
    { count: number } | JournalLineFormItem | JournalLineFormItem[]
  > = new BehaviorSubject(undefined);

  /**
   * Shows or hide the customers list.
   */
  customersListVisibility = false;

  /**
   * The set of subscriptions on this components,
   * these subscriptions must be unsubscribed before this component got destroyed.
   */
  subscriptions = new Subscription();

  /**
   * Gets the products form-array.
   */
  get productsForm(): FormArray {
    return this.form?.controls.products as FormArray;
  }

  /**
   * Gets the attachments form-array.
   */
  get attachmentsForm(): FormArray {
    return this.form?.controls.attachments as FormArray;
  }

  /**
   * Gets the journal-lines form-array.
   */
  get journalLinesForm(): FormArray {
    return this.form?.controls.lines as FormArray;
  }

  /**
   * Gets the products total without discount and tax.
   */
  get productsTotalWithoutDiscountAndTax(): number {
    return ProductsFormUtil.productsTotalWithoutDiscountAndTax(this.productsForm.controls.map((item) => item.value));
  }

  /**
   * Gets the total value of the products items after applying discounts and quantities for each product, without tax.
   */
  get productsTotalWithoutTax(): number {
    return ProductsFormUtil.productsTotalWithoutTax(this.productsForm.controls.map((item) => item.value));
  }

  /**
   * Gets the sum of discount values.
   */
  get productsDiscount(): number {
    return ProductsFormUtil.productsDiscount(this.productsForm.controls.map((item) => item.value));
  }

  /**
   * Gets the sum of selected products taxes.
   */
  get productsTax(): number {
    return ProductsFormUtil.productsTax(this.productsForm.controls.map((item) => item.value));
  }

  /**
   * Gets net value of the invoice.
   */
  get invoiceNet(): number {
    return ProductsFormUtil.invoiceNet(this.productsForm.controls.map((item) => item.value));
  }

  constructor(
    private modalService: NgbModal,
    private notificationService: NotificationService,
    private locationService: AngularLocation,
    private translationService: TranslationService,
    private printService: PrintService,
    private route: ActivatedRoute,
    private printingStore$: Store<fromPrintingStore.PrintingState>,
    private salesStore$: Store<fromSalesStore.SalesState>,
    private financesStore$: Store<fromFinancesStore.FinancesState>,
    private settingsStore$: Store<fromSettingsStore.SettingsState>,
    private authStore$: Store<fromAuthStore.AuthState>
  ) {}

  ngOnInit(): void {
    this.init();
  }

  /**
   * Destroy component data
   */
  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  /**
   * Initialize component data.
   */
  init() {
    this.initForm();
    this.initJournalData();

    /**
     * Print the transaction once it is ready.
     */
    this.subscriptions.add(
      this.printingStore$
        .pipe(
          select(fromPrintingStore.getSaleInvoicePrint),
          skip(1),
          tap((report) => {
            if (report) {
              this.printService.printPDF(report.pdfUrl, (error, request) => {
                this.notificationService.error(this.translationService.translate('REPORTS.FAILED_TO_PRINT'));
              });
            }
          })
        )
        .subscribe()
    );

    /**
     * Subscribe to the selected sale-invoice created to print the newly created invoice once its created.
     */
    this.subscriptions.add(
      this.salesStore$
        .pipe(
          select(fromSalesStore.getSelectedSaleInvoice),
          skip(1),
          tap((invoice) => {
            if (invoice) {
              this.print(invoice.id);
              this.form.disable();
              this.invoiceId = invoice.id;
            }
          })
        )
        .subscribe()
    );

    /**
     * reset form on creation complete
     */
    this.subscriptions.add(
      this.salesStore$
        .pipe(
          select(fromSalesStore.getSelectedSaleInvoiceCreateCompleted),
          skip(1),
          tap((created) => {
            if (created) {
              this.form.markAsUntouched();
              this.form.disable();
            }
          })
        )
        .subscribe()
    );

    /* Subscribe to selected location to select it whenever it changed. */
    this.subscriptions.add(
      this.authStore$
        .pipe(
          select(fromAuthStore.getSessionUserDefaultLocation),
          tap((location) => {
            if (location) {
              this.selectLocation([location]);
            }
          })
        )
        .subscribe()
    );

    /**
     * Load data.
     */
    this.isCreating$ = this.salesStore$.pipe(select(fromSalesStore.getSelectedSaleInvoiceCreating));

    let isManualSearchTriggeredBeforeForTaxes = false;
    this.taxes$ = this.settingsStore$.pipe(
      select(fromSettingsStore.getTaxes),
      tap((taxes) => {
        if (!isManualSearchTriggeredBeforeForTaxes && !taxes.length) {
          isManualSearchTriggeredBeforeForTaxes = true;
          this.settingsStore$.dispatch(new fromSettingsStore.SearchTaxes({ name: '', page: 1 }));
        }
      })
    );

    /** Select the user desired page. */
    this.activePage = PAGES[this.route.snapshot.fragment] ?? this.activePage;
    this.selectedPageChanged(this.activePage);
  }

  /**
   * Initialize form and add validators.
   */
  initForm() {
    /*
     * Maintenance sale invoice form
     */
    this.form = new FormGroup({
      locationId: new FormControl(null, Validators.required),
      customerId: new FormControl(null, Validators.required),
      customerAssetId: new FormControl(null, Validators.required),
      transactionDate: new FormControl(null),
      dateOfSupply: new FormControl(null),
      maintenanceItemDescription: new FormControl('', [
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(200),
      ]),
      maintenanceExpectedTime: new FormControl('', [
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(100),
      ]),
      products: new FormArray([], CustomValidators.arrayItems(1)),
      lines: new FormArray([], CustomValidators.arrayItems(2)),
      attachments: new FormArray([]),
      notes: new FormControl('', [Validators.minLength(0), Validators.maxLength(200)]),
    });
    this.addBlankAttachment();
    this.setBlankJournalsCount();

    /**
     * Enable or disable asset select when selected customer changes.
     */
    this.subscriptions.add(this.form.controls.customerId.valueChanges.subscribe(() => this.onSelectedCustomerChange()));

    /**
     * Subscription when products form changes.
     */
    this.subscriptions.add(this.productsForm.valueChanges.subscribe(() => this.onProductsChange()));
  }

  /**
   * Initialize the journal data.
   */
  initJournalData() {
    /**
     * Fetch system accounts then fill the journal line form with its lines.
     */

    let isManualSearchTriggeredBeforeForTradeDebtorsAccount = false;
    this.subscriptions.add(
      /**
       * Trade debtors Account.
       */
      this.financesStore$
        .pipe(
          select(fromFinancesStore.getTradeDebtorsAccount),
          tap((account) => {
            this.tradeDebtorsAccount = account;

            if (account) {
              this.onProductsChange();
            } else if (!account && !isManualSearchTriggeredBeforeForTradeDebtorsAccount) {
              isManualSearchTriggeredBeforeForTradeDebtorsAccount = true;
              this.financesStore$.dispatch(new fromFinancesStore.FindTradeDebtorsAccount());
            }
          })
        )
        .subscribe()
    );

    let isManualSearchTriggeredBeforeForMainActivitiesSalesRevenueAccount = false;
    this.subscriptions.add(
      /**
       * Main activities sales revenue Account.
       */
      this.financesStore$
        .pipe(
          select(fromFinancesStore.getMainActivitiesSalesRevenueAccount),
          tap((account) => {
            this.mainActivitiesSalesRevenueAccount = account;
            if (account) {
              this.onProductsChange();
            } else if (!account && !isManualSearchTriggeredBeforeForMainActivitiesSalesRevenueAccount) {
              isManualSearchTriggeredBeforeForMainActivitiesSalesRevenueAccount = true;
              this.financesStore$.dispatch(new fromFinancesStore.FindMainActivitiesSalesRevenueAccount());
            }
          })
        )
        .subscribe()
    );

    let isManualSearchTriggeredBeforeForValueAddedTaxCreditAccount = false;
    this.subscriptions.add(
      /**
       * Value added tax credit Account.
       */
      this.financesStore$
        .pipe(
          select(fromFinancesStore.getValueAddedTaxCreditAccount),
          tap((account) => {
            this.valueAddedTaxCreditAccount = account;
            if (account) {
              this.onProductsChange();
            } else if (!account && !isManualSearchTriggeredBeforeForValueAddedTaxCreditAccount) {
              isManualSearchTriggeredBeforeForValueAddedTaxCreditAccount = true;
              this.financesStore$.dispatch(new fromFinancesStore.FindValueAddedTaxCreditAccount());
            }
          })
        )
        .subscribe()
    );
  }

  /**
   * Handles the change of the products list.
   */
  onProductsChange() {
    /**
     * Set Journal Lines based on the selected purchase invoice type.
     */
    this.resetJournalLines();

    /**
     * The list of journal lines to be added to the form.
     */
    const lines: JournalLineFormItem[] = [];

    /**
     * Add line for Trade Debtors Account.
     */
    if (this.tradeDebtorsAccount) {
      lines.push({
        accountId: this.tradeDebtorsAccount.id,
        account: this.tradeDebtorsAccount,
        costCenterId: null,
        credit: 0,
        debit: this.invoiceNet,
        notes: '',
        disableAccountChange: true,
        disableDebitAndCreditValueChange: true,
      });
    }

    /**
     * Add line for Main Activities Sales Revenue Account.
     */
    if (this.mainActivitiesSalesRevenueAccount) {
      lines.push({
        accountId: this.mainActivitiesSalesRevenueAccount.id,
        account: this.mainActivitiesSalesRevenueAccount,
        costCenterId: null,
        credit: this.productsTotalWithoutTax,
        debit: 0,
        notes: '',
        disableAccountChange: true,
        disableDebitAndCreditValueChange: true,
      });
    }

    /**
     * Add line for Value Added Tax (credit) Account.
     */
    if (this.valueAddedTaxCreditAccount && this.productsTax > 0) {
      lines.push({
        accountId: this.valueAddedTaxCreditAccount.id,
        account: this.valueAddedTaxCreditAccount,
        costCenterId: null,
        credit: this.productsTax,
        debit: 0,
        notes: '',
        disableAccountChange: true,
        disableDebitAndCreditValueChange: true,
      });
    }

    this.initialJournalLinesSubject.next(lines);
  }

  /**
   * Submits the form.
   */
  submit() {
    /**
     * maintenance invoice form.
     */
    const errorMessage = new NotificationMessage();

    if (this.form.invalid) {
      if (this.form.get('locationId').invalid) {
        errorMessage.title = this.translationService.translate(
          'SALES.INVOICES.SALE_INVOICE_DATA_VALIDATION.SALE_INVOICE_LOCATION_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate(
            'SALES.INVOICES.SALE_INVOICE_DATA_VALIDATION.SALE_INVOICE_LOCATION_IS_REQUIRED'
          ),
        ];
        this.activePage = PAGES.details;
      } else if (this.form.get('customerId').invalid) {
        errorMessage.title = this.translationService.translate(
          'SALES.INVOICES.SALE_INVOICE_DATA_VALIDATION.CUSTOMER_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate('SALES.INVOICES.SALE_INVOICE_DATA_VALIDATION.CUSTOMER_IS_REQUIRED'),
        ];
        this.activePage = PAGES.details;
      } else if (this.form.get('customerAssetId').invalid) {
        errorMessage.title = this.translationService.translate(
          'SALES.INVOICES.SALE_INVOICE_DATA_VALIDATION.CUSTOMER_ASSET_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate('SALES.INVOICES.SALE_INVOICE_DATA_VALIDATION.CUSTOMER_ASSET_IS_REQUIRED'),
        ];
        this.activePage = PAGES.details;
      } else if (this.form.get('maintenanceItemDescription').invalid) {
        errorMessage.title = this.translationService.translate(
          'SALES.INVOICES.SALE_INVOICE_DATA_VALIDATION.MAINTENANCE_ITEM_DESCRIPTION_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate(
            'SALES.INVOICES.SALE_INVOICE_DATA_VALIDATION.MAINTENANCE_ITEM_DESCRIPTION_IS_REQUIRED'
          ),
          this.translationService.translate(
            'SALES.INVOICES.SALE_INVOICE_DATA_VALIDATION.MAINTENANCE_ITEM_DESCRIPTION_LENGTH_ERROR'
          ),
        ];
        this.activePage = PAGES.details;
      } else if (this.form.get('maintenanceExpectedTime').invalid) {
        errorMessage.title = this.translationService.translate(
          'SALES.INVOICES.SALE_INVOICE_DATA_VALIDATION.MAINTENANCE_EXPECTED_TIME_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate(
            'SALES.INVOICES.SALE_INVOICE_DATA_VALIDATION.MAINTENANCE_EXPECTED_TIME_IS_REQUIRED'
          ),
          this.translationService.translate(
            'SALES.INVOICES.SALE_INVOICE_DATA_VALIDATION.MAINTENANCE_EXPECTED_TIME_LENGTH_ERROR'
          ),
        ];
        this.activePage = PAGES.details;
      } else if (this.form.get('products').invalid) {
        /**
         * Check if products count = 0.
         */
        if (!this.productsForm.controls.length) {
          errorMessage.title = this.translationService.translate(
            'SALES.INVOICES.SALE_INVOICE_DATA_VALIDATION.PRODUCTS_ERROR'
          );
          errorMessage.body = [
            this.translationService.translate('SALES.INVOICES.SALE_INVOICE_DATA_VALIDATION.PRODUCTS_LENGTH_ERROR'),
          ];
        } else {
          /**
           * Check if some of products has errors.
           */
          for (let index = 0; index < this.productsForm.controls.length; index++) {
            const item = this.productsForm.controls[index];

            if (item.valid) {
              continue;
            }

            errorMessage.title = this.translationService.translate(
              'SALES.INVOICES.SALE_INVOICE_DATA_VALIDATION.PRODUCT_NUMBER_ERROR',
              { productNumber: index + 1 }
            );
            errorMessage.body = [];

            if (item.get('description').invalid) {
              errorMessage.body.push(
                this.translationService.translate('SALES.INVOICES.SALE_INVOICE_DATA_VALIDATION.DESCRIPTION_IS_REQUIRED')
              );
            }

            if (item.get('subProductId').invalid) {
              errorMessage.body.push(
                this.translationService.translate('SALES.INVOICES.SALE_INVOICE_DATA_VALIDATION.SUB_PRODUCT_ERROR')
              );
            }

            if (item.get('quantity').invalid) {
              errorMessage.body.push(
                this.translationService.translate('SALES.INVOICES.SALE_INVOICE_DATA_VALIDATION.PRODUCT_QUANTITY_ERROR')
              );
            }

            if (item.get('unitOfMeasureId').invalid) {
              errorMessage.body.push(
                this.translationService.translate(
                  'SALES.INVOICES.SALE_INVOICE_DATA_VALIDATION.UNIT_OF_MEASURE_IS_REQUIRED'
                )
              );
            }

            if (item.get('value').invalid) {
              errorMessage.body.push(
                this.translationService.translate('SALES.INVOICES.SALE_INVOICE_DATA_VALIDATION.PRODUCT_VALUE_ERROR')
              );
            }

            if (item.get('tax').invalid) {
              errorMessage.body.push(
                this.translationService.translate('SALES.INVOICES.SALE_INVOICE_DATA_VALIDATION.TAX_ERROR')
              );
            }

            if (item.get('discount').invalid) {
              errorMessage.title = this.translationService.translate(
                'SALES.INVOICES.SALE_INVOICE_DATA_VALIDATION.DISCOUNT_INPUT_ERROR'
              );
              errorMessage.body = [
                this.translationService.translate(
                  'SALES.INVOICES.SALE_INVOICE_DATA_VALIDATION.DISCOUNT_INPUT_LENGTH_ERROR'
                ),
              ];
            }

            if (item.get('discountPercent').invalid) {
              errorMessage.title = this.translationService.translate(
                'SALES.INVOICES.SALE_INVOICE_DATA_VALIDATION.DISCOUNT_PERCENT_INPUT_ERROR'
              );
              errorMessage.body = [
                this.translationService.translate(
                  'SALES.INVOICES.SALE_INVOICE_DATA_VALIDATION.DISCOUNT_PERCENT_INPUT_LENGTH_ERROR'
                ),
              ];
            }

            break;
          }
        }

        this.activePage = PAGES.details;
      }

      const products: ProductsFormItem[] = this.productsForm.value;

      if (products.some((product) => new Decimal(ProductsFormUtil.productValueAfterDiscount(product)).lt(0))) {
        errorMessage.title = this.translationService.translate(
          'SALES.INVOICES.SALE_INVOICE_DATA_VALIDATION.DISCOUNT_VALUE_TITLE_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate('SALES.INVOICES.SALE_INVOICE_DATA_VALIDATION.DISCOUNT_IS_MORE_THAN_VALUE'),
        ];

        this.form.markAllAsTouched();
        return this.notificationService.warningWithTitle(errorMessage);
      } else if (this.form.get('lines').invalid) {
        /**
         * Check if journal lines count < 2.
         */
        if (this.journalLinesForm.controls.length < 2) {
          errorMessage.title = this.translationService.translate(
            'SALES.INVOICES.SALE_INVOICE_DATA_VALIDATION.JOURNALS_LINES_ERROR'
          );
          errorMessage.body = [
            this.translationService.translate('SALES.INVOICES.SALE_INVOICE_DATA_VALIDATION.JOURNALS_LINES_COUNT_ERROR'),
          ];
        } else {
          /**
           * Check if some of journals has errors.
           */
          for (let index = 0; index < this.journalLinesForm.controls.length; index++) {
            const journal = this.journalLinesForm.controls[index];

            if (journal.valid) {
              continue;
            }

            errorMessage.title = this.translationService.translate(
              'SALES.INVOICES.SALE_INVOICE_DATA_VALIDATION.JOURNAL_LINE_NUMBER_ERROR',
              {
                journalNumber: index + 1,
              }
            );
            errorMessage.body = [];

            if (journal.get('accountId').invalid) {
              errorMessage.body.push(
                this.translationService.translate('SALES.INVOICES.SALE_INVOICE_DATA_VALIDATION.ACCOUNT_ERROR')
              );
              errorMessage.body.push(
                this.translationService.translate('SALES.INVOICES.SALE_INVOICE_DATA_VALIDATION.ACCOUNT_IS_REQUIRED')
              );
            }

            if (journal.get('credit').invalid) {
              errorMessage.body.push(
                this.translationService.translate('SALES.INVOICES.SALE_INVOICE_DATA_VALIDATION.CREDIT_IS_REQUIRED')
              );
            }

            if (journal.get('debit').invalid) {
              errorMessage.body.push(
                this.translationService.translate('SALES.INVOICES.SALE_INVOICE_DATA_VALIDATION.DEBIT_IS_REQUIRED')
              );
            }

            if (journal.get('costCenterId').invalid) {
              errorMessage.body.push(
                this.translationService.translate('SALES.INVOICES.SALE_INVOICE_DATA_VALIDATION.COST_CENTER_ERROR')
              );
              errorMessage.body.push(
                this.translationService.translate('SALES.INVOICES.SALE_INVOICE_DATA_VALIDATION.COST_CENTER_IS_REQUIRED')
              );
            }

            if (journal.get('notes').invalid) {
              errorMessage.body.push(
                this.translationService.translate('SALES.INVOICES.SALE_INVOICE_DATA_VALIDATION.NOTES_ERROR')
              );
              errorMessage.body.push(
                this.translationService.translate('SALES.INVOICES.SALE_INVOICE_DATA_VALIDATION.NOTES_IS_REQUIRED')
              );
              errorMessage.body.push(
                this.translationService.translate('SALES.INVOICES.SALE_INVOICE_DATA_VALIDATION.NOTES_LENGTH_ERROR')
              );
            }

            break;
          }
        }

        this.activePage = PAGES.journal;
      } else if (this.form.get('notes').invalid) {
        errorMessage.title = this.translationService.translate(
          'SALES.INVOICES.SALE_INVOICE_DATA_VALIDATION.NOTES_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate('SALES.INVOICES.SALE_INVOICE_DATA_VALIDATION.NOTES_LENGTH_FROM_ZERO_ERROR'),
        ];
        this.activePage = PAGES.details;
      }

      this.form.markAllAsTouched();
      return this.notificationService.warningWithTitle(errorMessage);
    }

    const lines: CreateJournalLineInput[] = this.journalLinesForm.value;

    if (
      lines.some(
        (line) =>
          line.debit === 0 &&
          line.credit === 0 &&
          line.accountId !== this.valueAddedTaxCreditAccount?.id &&
          this.invoiceNet !== 0
      ) ||
      lines.some((line) => line.debit !== 0 && line.credit !== 0)
    ) {
      errorMessage.title = this.translationService.translate(
        'SALES.INVOICES.SALE_INVOICE_DATA_VALIDATION.JOURNALS_LINES_TITLE_ERROR'
      );
      errorMessage.body = [
        this.translationService.translate('SALES.INVOICES.SALE_INVOICE_DATA_VALIDATION.DEBIT_CREDIT_ERROR'),
        this.translationService.translate('SALES.INVOICES.SALE_INVOICE_DATA_VALIDATION.JOURNALS_LINES_TYPE_ERROR'),
      ];
      this.activePage = PAGES.journal;

      this.form.markAllAsTouched();
      return this.notificationService.warningWithTitle(errorMessage);
    } else if (
      !Decimal.sum(0, ...lines.filter((line) => !!line.debit).map((line) => line.debit)).eq(
        Decimal.sum(0, ...lines.filter((line) => !!line.credit).map((line) => line.credit))
      )
    ) {
      errorMessage.title = this.translationService.translate(
        'SALES.INVOICES.SALE_INVOICE_DATA_VALIDATION.JOURNALS_LINES_TOTAL_ERROR'
      );
      errorMessage.body = [
        this.translationService.translate(
          'SALES.INVOICES.SALE_INVOICE_DATA_VALIDATION.JOURNALS_LINES_TOTAL_VALUE_ERROR'
        ),
      ];
      this.activePage = PAGES.journal;

      this.form.markAllAsTouched();
      return this.notificationService.warningWithTitle(errorMessage);
    }

    this.openModal(this.confirmMaintenanceInvoiceModalRef);
  }

  /**
   * Confirms the form submit.
   */
  confirm() {
    let fromDateFormatted;
    let dateOfSupplyFormatted;

    const {
      locationId,
      customerId,
      customerAssetId,
      maintenanceItemDescription,
      maintenanceExpectedTime,
      transactionDate,
      dateOfSupply,
      notes,
    } = this.form.value;

    if (transactionDate) {
      fromDateFormatted = new Date(transactionDate.year, transactionDate.month - 1, transactionDate.day);
      fromDateFormatted.setHours(new Date().getHours(), new Date().getMinutes());
    }
    if (dateOfSupply) {
      dateOfSupplyFormatted = new Date(dateOfSupply.year, dateOfSupply.month - 1, dateOfSupply.day);
      dateOfSupplyFormatted.setHours(new Date().getHours(), new Date().getMinutes());
    }

    /**
     * The list of products in the products form.
     */
    const products = this.productsForm.value.map((item: ProductsFormItem) => {
      const inputProduct: CreateSalesInvoiceProductInput = {
        productId: item.isStorable || item.isService ? item.product.id : null,
        subProductId: item.subProductId,
        description: item.isStorable || item.isService ? null : item.description,
        quantity: item.quantity,
        unitOfMeasureId: item.unitOfMeasureId,
        value: item.value,
        discount: item.discount,
        discountPercent: item.discountPercent,
        tax: item.tax,
        notes: item.notes,
        locationId: item.isStorable || item.isService ? item.locationId : null,
      };

      return inputProduct;
    });
    /**
     * The list of lines in the lines form.
     */
    const lines = this.journalLinesForm.value
      .filter((line: JournalLineFormItem) => line.credit >= 0 || line.debit >= 0)
      .map((line) => {
        const inputLine: CreateJournalLineInput = {
          accountId: line.accountId,
          debit: line.debit,
          credit: line.credit,
          costCenterId: line.costCenterId,
          notes: line.notes,
        };

        return inputLine;
      });

    this.salesStore$.dispatch(
      new fromSalesStore.CreateMaintenanceInvoice({
        locationId,
        customerId,
        customerAssetId,
        maintenanceItemDescription,
        maintenanceExpectedTime,
        transactionDate: fromDateFormatted,
        dateOfSupply: dateOfSupplyFormatted,
        products,
        lines,
        notes,
        attachments: this.attachmentsForm.value.filter((file) => !!file),
      })
    );
  }

  /**
   * Open the notes popup.
   */
  openNotes() {
    this.openModal(this.notesModalRef);
  }

  /**
   * Opens the modal of the given templateRef.
   * @param modalRef The modal templateRef to be opened.
   */
  openModal(modalRef) {
    this.modalService.open(modalRef);
  }

  /**
   * Selects the newly selected customer from the customers search list.
   * @param customers The list of newly selected customers to select the only one in the list.
   */
  selectCustomer([customer]: Customer[]) {
    if (customer) {
      this.customers = [customer];
      this.form.patchValue({ customerId: customer.id });

      /**
       * This will be replaced by a list of assets coming from the server.
       */
      this.assets = [...customer.customerAssets];

      this.form.controls.customerAssetId.reset();
    }

    this.onSelectedCustomerChange();
  }

  /**
   * Format the given asset in a suitable format.
   * @param asset The asset to be formatted.
   */
  formatAsset(asset: CustomerAsset): string {
    let result = asset.description;
    if (asset.type) {
      result += ` - ${asset.type}`;
    }
    if (asset.serialNumber) {
      result += ` - ${asset.serialNumber}`;
    }
    return result;
  }

  /**
   * On selected customer change event.
   */
  onSelectedCustomerChange() {
    if (this.form.controls.customerId.value) {
      this.form.controls.customerAssetId.enable();
    } else {
      this.form.controls.customerAssetId.disable();
      this.form.controls.customerAssetId.setValue(null);
    }
  }

  /**
   * Sets the count of the journal lines should be added to the journals form for quick start..
   */
  setBlankJournalsCount() {
    this.initialJournalLinesSubject.next({ count: 0 });
  }

  /**
   * Resets journal lines data.
   */
  resetJournalLines() {
    this.journalLinesForm.clear();
  }

  /**
   * Open confirm reset invoice modal .
   */
  openResetModal() {
    this.openModal(this.resetModalRef);
  }

  /**
   * Resets all form data.
   * It only adds one service for quick start.
   */
  resetForm() {
    this.form.enable();
    this.form.reset({ notes: '' });
    this.productsForm.clear();
    this.attachmentsForm.clear();
    this.addBlankAttachment();
    this.resetJournalLines();
  }

  /**
   * Adds a blank attachment form-control to the attachments form for quick start..
   */
  addBlankAttachment() {
    this.attachmentsForm.push(new FormControl());
  }

  /**
   * Updates the browser url according to the selected page.
   * @param page The newly selected page.
   */
  selectedPageChanged(page: PAGES) {
    this.locationService.replaceState(`${this.locationService.path()}#${PAGES[page]}`);
  }

  /**
   * Confirms leaving the page before routing.
   */
  confirmDeactivate() {
    if (this.form.dirty === true) {
      /**
       * Message to warn the user before leaving the detailed form.
       */
      return confirm(this.translationService.translate('SHARED.CONFIRM.LEAVING'));
    }
    return true;
  }

  /**
   * Prints the current displayed sale invoice.
   */
  print(invoiceId: number): void {
    this.printingStore$.dispatch(
      new fromPrintingStore.GetSaleInvoicePrint({
        saleInvoiceId: invoiceId,
      })
    );
  }

  /**
   * Selects the newly selected location from the locations search list.
   * @param locations The list of newly selected locations to select the only one in the list.
   */
  selectLocation([location]: Location[]) {
    if (location) {
      this.locations = [location];
      this.form.patchValue({ locationId: location.id });
    }
  }
}
