import { StatisticsState } from 'statistics/store/states/statistics.state';

/**
 * Represents the Statistics-Module state.
 */
export class StatisticsMainState {
  /**
   * Represents the statistics state.
   */
  public statistics: StatisticsState;
}
