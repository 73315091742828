import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { PageInfo, NotificationService, NotificationMessage, TranslationService } from 'shared';
import * as fromSettingsStore from 'settings/store';
import { Tax } from 'settings/models';

@Component({
  selector: 'app-update-tax',
  templateUrl: './update-tax.component.html',
  styles: [],
})
export class UpdateTaxComponent implements OnInit {
  /**
   * The confirm modal template reference.
   */
  @ViewChild('confirmModalRef') confirmModalRef: ElementRef<any>;

  /**
   * Gets or sets the information about the current page.
   */
  pageInfo: PageInfo = {
    title: 'SETTINGS.TAXES.CONFIRM_UPDATE_TAX_TITLE',
    icon: 'fa fa-edit',
  };

  /**
   * Indicates whether there is a update-tax process is running or not.
   */
  isUpdating$: Observable<boolean>;

  /**
   * Gets or sets the id of the current edited tax.
   */
  taxId: number;

  /**
   * The current edited tax.
   */
  tax$: Observable<Tax>;

  /**
   * The name of the current tax.
   */
  taxName: string;

  /**
   * The create tax form.
   */
  form: FormGroup;

  /**
   * Gets or sets the current selected language.
   */
  get currentLang() {
    return this.translationService.language;
  }

  /**
   * @param route The activated route.
   * @param modalService The modal service.
   * @param notificationService The notification service.
   * @param translationService The translation service.
   * @param settingsStore$ Settings-module store.
   */
  constructor(
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private notificationService: NotificationService,
    private translationService: TranslationService,
    private settingsStore$: Store<fromSettingsStore.SettingsState>
  ) {}

  ngOnInit(): void {
    this.init();
  }

  /**
   * Initialize component data.
   */
  init() {
    this.initForm();

    /**
     * Load data.
     */
    this.isUpdating$ = this.settingsStore$.pipe(select(fromSettingsStore.getSelectedTaxUpdating));

    /* Load the selected tax. */
    this.taxId = +this.route.snapshot.params.taxId;

    this.settingsStore$.dispatch(new fromSettingsStore.FindTax(this.taxId));

    /* Load the selected tax. */
    this.tax$ = this.settingsStore$.pipe(
      select(fromSettingsStore.getSelectedTax),
      tap((tax) => {
        if (tax) {
          this.form.patchValue(tax);

          this.taxName = this.currentLang === 'en' ? tax.nameEn : tax.name;

          /* Update page info. */
          this.pageInfo = {
            ...this.pageInfo,
            subTitle: `(${this.taxName})`,
          };

          this.form.markAsPristine();
        }
      })
    );
  }

  /**
   * Initialize form and add validators.
   */
  initForm() {
    this.form = new FormGroup({
      id: new FormControl('', Validators.required),
      name: new FormControl('', [Validators.required, Validators.minLength(1), Validators.maxLength(50)]),
      nameEn: new FormControl('', [Validators.required, Validators.minLength(1), Validators.maxLength(50)]),
      value: new FormControl('', [Validators.required, Validators.min(0), Validators.max(100)]),
      isDefault: new FormControl(false),
    });
  }

  /**
   * Submits the form.
   */
  submit() {
    if (this.form.invalid) {
      const errorMessage = new NotificationMessage();
      if (this.form.get('name').invalid) {
        errorMessage.title = this.translationService.translate('SETTINGS.TAXES.TAX_DATA_VALIDATIONS.TAX_NAME_ERROR');
        errorMessage.body = [
          this.translationService.translate('SETTINGS.TAXES.TAX_DATA_VALIDATIONS.TAX_NAME_IS_REQUIRED'),
          this.translationService.translate('SETTINGS.TAXES.TAX_DATA_VALIDATIONS.TAX_NAME_LENGTH_ERROR'),
        ];
      } else if (this.form.get('nameEn').invalid) {
        errorMessage.title = this.translationService.translate(
          'SETTINGS.TAXES.TAX_DATA_VALIDATIONS.TAX_ENGLISH_NAME_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate('SETTINGS.TAXES.TAX_DATA_VALIDATIONS.TAX_ENGLISH_NAME_IS_REQUIRED'),
          this.translationService.translate('SETTINGS.TAXES.TAX_DATA_VALIDATIONS.TAX_ENGLISH_NAME_LENGTH_ERROR'),
        ];
      } else if (this.form.get('value').invalid) {
        errorMessage.title = this.translationService.translate('SETTINGS.TAXES.TAX_DATA_VALIDATIONS.TAX_VALUE_ERROR');
        errorMessage.body = [
          this.translationService.translate('SETTINGS.TAXES.TAX_DATA_VALIDATIONS.TAX_VALUE_IS_REQUIRED'),
          this.translationService.translate('SETTINGS.TAXES.TAX_DATA_VALIDATIONS.TAX_VALUE_LENGTH_ERROR'),
        ];
      }
      this.form.markAllAsTouched();
      return this.notificationService.warningWithTitle(errorMessage);
    } else {
      this.openModal(this.confirmModalRef);
    }
  }

  /**
   * Confirms the form submit.
   */
  confirm() {
    this.settingsStore$.dispatch(new fromSettingsStore.UpdateTax(this.form.value));
  }

  /**
   * Opens the modal of the given templateRef.
   * @param modalRef The modal templateRef to be opened.
   */
  openModal(modalRef) {
    this.modalService.open(modalRef);
  }
}
