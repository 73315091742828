/**
 * The system supported incoming stock types.
 */
export enum IncomingStockTypes {
  DIRECT = 'DIRECT',
  BY_PURCHASE_ORDER = 'BY_PURCHASE_ORDER',
  BY_PETTY_CASH = 'BY_PETTY_CASH',
  VARIETY_RETURNS = 'VARIETY_RETURNS',
  DIRECT_BY_PURCHASE_INVOICE = 'DIRECT_BY_PURCHASE_INVOICE',
}
