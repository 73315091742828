import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subscription } from 'rxjs';
import { skip, tap } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { flatMap } from 'lodash';

import * as fromSecurityStore from 'security/store';
import { PageInfo, NotificationService, NotificationMessage, TranslationService, APP_CONSTANTS } from 'shared';
import { Organization } from 'organizations/models';
import { ClaimGroup, UserClaimGroupFormItem } from 'security/models';

@Component({
  selector: 'app-create-user',
  templateUrl: './create-user.component.html',
  styles: [],
})
export class CreateUserComponent implements OnInit, OnDestroy {
  /**
   * The confirm modal template reference.
   */
  @ViewChild('confirmModalRef') confirmModalRef: ElementRef<any>;

  /**
   * Gets or sets the information about the current page.
   */
  pageInfo: PageInfo = {
    title: 'SECURITY.USERS.CREATE_USER_PAGE_TITLE',
    icon: 'fa fa-user-plus',
  };

  /**
   * The list of active organizations.
   */
  organizations: Organization[] = [];

  /**
   * The list of all claim groups.
   */
  claimGroups: ClaimGroup[] = [];

  /**
   * Indicates whether there is a create-user process is running or not.
   */
  isCreating$: Observable<boolean>;

  /**
   * The create user form.
   */
  form: FormGroup;

  /**
   * The claim groups model.
   * Uses `[(ngModel)]` binding.
   */
  claimGroupsModel: UserClaimGroupFormItem[] = [];

  /**
   * The set of subscriptions on this components,
   * these subscriptions must be unsubscribed before this component got destroyed.
   */
  subscriptions = new Subscription();

  /**
   * Shows or hide the organizations list.
   */
  organizationsListVisibility = false;

  /**
   * Add mask to phone and mobile number.
   */
  public phonePattern = APP_CONSTANTS.phone.pattern;
  public phoneMask = APP_CONSTANTS.phone.mask;

  /**
   * Indicates wether the selected user is a service provider or not.
   */
  get isServiceProvider(): boolean {
    return this.form.value.userType === 'SERVICE_PROVIDER';
  }

  /**
   * @param modalService The modal service.
   * @param notificationService The notification service.
   * @param translationService The translation service.
   * @param securityStore$ The Security-module store.
   */
  constructor(
    private modalService: NgbModal,
    private notificationService: NotificationService,
    private translationService: TranslationService,
    private securityStore$: Store<fromSecurityStore.SecurityState>
  ) {}

  ngOnInit(): void {
    this.init();
  }

  /**
   * Destroy component data
   */
  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  /**
   * Initialize component data.
   */
  init() {
    this.initForm();

    /**
     * Load data.
     */
    this.securityStore$.dispatch(new fromSecurityStore.GetClaimsGroups());

    this.subscriptions.add(
      this.securityStore$
        .select(fromSecurityStore.getClaimGroups)
        .pipe(
          tap((groups) => {
            this.claimGroups = groups;
            this.updateClaimGroups();
          })
        )
        .subscribe()
    );

    /**
     * Set data.
     */
    this.isCreating$ = this.securityStore$.pipe(select(fromSecurityStore.getSelectedUserCreating));

    /**
     * reset form on creation complete
     */
    this.subscriptions.add(
      this.securityStore$
        .pipe(
          select(fromSecurityStore.getSelectedUserCreateCompleted),
          skip(1),
          tap((created) => {
            if (created) {
              this.initForm();
              this.form.markAsUntouched();
            }
          })
        )
        .subscribe()
    );
  }

  /**
   * Initialize form and add validators.
   */
  initForm() {
    const userTypeFormControl = new FormControl('SERVICE_PROVIDER', Validators.required);

    this.form = new FormGroup({
      userType: userTypeFormControl,
      name: new FormControl('', [Validators.required, Validators.minLength(1), Validators.maxLength(50)]),
      organizationId: new FormControl(null),
      username: new FormControl('', [Validators.required, Validators.minLength(4), Validators.maxLength(50)]),
      password: new FormControl('', [Validators.required, Validators.minLength(8), Validators.maxLength(50)]),
    });

    /**
     * Dynamically set form validations based on the selected user type.
     */
    this.subscriptions.add(userTypeFormControl.valueChanges.subscribe(() => this.setValidations()));

    /**
     * Initialize validations.
     */
    this.setValidations();
  }

  /**
   * Sets the conditional validation for the form based on the selected role.
   */
  setValidations() {
    const userType: string = this.form.controls.userType.value || 'SERVICE_PROVIDER';

    /**
     * Check if the user type is service provider.
     */
    if (userType === 'SERVICE_PROVIDER') {
      this.form.controls.organizationId.disable();
      this.form.controls.organizationId.clearValidators();
      this.form.controls.organizationId.setValue(null);
    } else {
      this.form.controls.organizationId.enable();
      this.form.controls.organizationId.setValidators(Validators.required);
    }

    this.form.controls.organizationId.updateValueAndValidity();
    this.updateClaimGroups();
  }

  /**
   * Updates the list of claim groups based on the selected user type.
   */
  updateClaimGroups() {
    this.claimGroupsModel = this.claimGroups
      .filter((group) => (this.isServiceProvider ? group.isSystemClaimGroup : !group.isSystemClaimGroup))
      .map((group) => {
        return {
          group,
          isSelected: false,
          claims: group.claims.map((claim) => {
            return {
              claim,
              isSelected: false,
            };
          }),
        };
      });
  }

  /**
   * Submits the form.
   */
  submit() {
    if (this.form.invalid) {
      const errorMessage = new NotificationMessage();

      if (this.form.get('userType').invalid) {
        errorMessage.title = this.translationService.translate('SECURITY.USERS.USER_DATA_VALIDATION.USER_TYPE_ERROR');
        errorMessage.body = [
          this.translationService.translate('SECURITY.USERS.USER_DATA_VALIDATION.USER_TYPE_IS_REQUIRED'),
        ];
      } else if (this.form.get('name').invalid) {
        errorMessage.title = this.translationService.translate('SECURITY.USERS.USER_DATA_VALIDATION.USER_NAME_ERROR');
        errorMessage.body = [
          this.translationService.translate('SECURITY.USERS.USER_DATA_VALIDATION.USER_NAME_IS_REQUIRED'),
          this.translationService.translate('SECURITY.USERS.USER_DATA_VALIDATION.USER_NAME_LENGTH_ERROR'),
        ];
      } else if (this.form.get('organizationId').invalid) {
        errorMessage.title = this.translationService.translate(
          'SECURITY.USERS.USER_DATA_VALIDATION.ORGANIZATION_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate('SECURITY.USERS.USER_DATA_VALIDATION.ORGANIZATION_IS_REQUIRED'),
        ];
      } else if (this.form.get('username').invalid) {
        errorMessage.title = this.translationService.translate('SECURITY.USERS.USER_DATA_VALIDATION.USERNAME_ERROR');
        errorMessage.body = [
          this.translationService.translate('SECURITY.USERS.USER_DATA_VALIDATION.USERNAME_IS_REQUIRED'),
          this.translationService.translate('SECURITY.USERS.USER_DATA_VALIDATION.USERNAME_LENGTH_ERROR'),
        ];
      } else if (this.form.get('password').invalid) {
        errorMessage.title = this.translationService.translate('SECURITY.USERS.USER_DATA_VALIDATION.PASSWORD_ERROR');
        errorMessage.body = [
          this.translationService.translate('SECURITY.USERS.USER_DATA_VALIDATION.PASSWORD_IS_REQUIRED'),
          this.translationService.translate('SECURITY.USERS.USER_DATA_VALIDATION.PASSWORD_LENGTH_ERROR'),
        ];
      }

      this.form.markAllAsTouched();
      return this.notificationService.warningWithTitle(errorMessage);
    } else {
      this.openModal(this.confirmModalRef);
    }
  }

  /**
   * Confirms the form submit.
   */
  confirm() {
    this.securityStore$.dispatch(
      new fromSecurityStore.CreateUser({
        ...this.form.value,
        /**
         * Map claim groups model to Array(number) of claim ids.
         */
        claims: flatMap(this.claimGroupsModel, (group) => group.claims)
          .filter((claim) => claim.isSelected)
          .map((claim) => claim.claim.id),
        locations: [],
        bankAccounts: [],
      })
    );
  }

  /**
   * Selects the newly selected organization from the organizations search list.
   * @param organizations The list of newly selected organizations to select the only one in the list.
   */
  selectOrganization([organization]: Organization[]) {
    if (organization) {
      this.organizations = [organization];
      this.form.patchValue({ organizationId: organization.id });
    }
  }

  /**
   * Opens the modal of the given templateRef.
   * @param modalRef The modal templateRef to be opened.
   */
  openModal(modalRef) {
    this.modalService.open(modalRef);
  }
}
