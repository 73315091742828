<!-- customer engagements starts -->

<!-- engagements list starts -->
<div class="row">
  <div class="col-md-12">
    <div class="card-header mb-3 pt-0">
      <h4 class="card-title">{{ 'SHARED.SEARCH.SEARCH_TOOLS' | translate }}</h4>
    </div>
    <div class="card-content">
      <div class="px-3">
        <div class="row">
          <div class="col-md-12">
            <form class="row">
              <div class="col-md-auto form-group d-flex">
                <button type="button" class="btn btn-raised btn-primary align-self-end round mx-1 mb-0" (click)="search()">
                  <i class="fa fa-refresh"></i>
                </button>
                <a
                  *ngIf="[Claims.CREATE_CUSTOMER_ENGAGEMENT] | authorize | async"
                  class="btn btn-raised btn-success align-self-end round mx-1 mb-0"
                  (click)="create()"
                >
                  <i class="fa fa-plus"></i>
                  <span class="mx-1">{{ 'SHARED.CONFIRM.ADD' | translate }}</span>
                </a>
                <app-export-tools
                *ngIf="(engagements$ | async)?.length"
                class="d-flex align-self-end mx-1"
                targetElementId="customer-engagements-table-export-target-element"
                [outputFileName]="'SHARED.EXPORT_TABLES.CUSTOMER_ENGAGEMENTS_TABLE' | translate"
              ></app-export-tools>
              </div>
            </form>
          </div>
          <div class="col-md-12" *ngIf="(engagements$ | async)?.length; else noItems">
            <div class="row">
              <div class="col-md-12 alert alert-light text-center" role="alert">
                {{ 'SHARED.SEARCH.SEARCH_RESULTS' | translate }} ({{ (engagements$ | async).length }})
                {{ 'SALES.CUSTOMER_ENGAGEMENTS.CUSTOMER_ENGAGEMENT' | translate }}
              </div>
              <div class="col-md-12">
                <div class="table-responsive">
                  <table class="table text-center" id="customer-engagements-table-export-target-element">
                    <thead>
                      <tr>
                        <th scope="col"><i class="fa fa-list-ol"></i></th>
                        <th scope="col">
                          {{
                            'SALES.CUSTOMER_ENGAGEMENTS.CUSTOMER_ENGAGEMENT_DATA.CUSTOMER_ENGAGEMENT_TYPE' | translate
                          }}
                        </th>
                        <th scope="col">
                          {{
                            'SALES.CUSTOMER_ENGAGEMENTS.CUSTOMER_ENGAGEMENT_DATA.CUSTOMER_ENGAGEMENT_VALUE' | translate
                          }}
                        </th>
                        <th scope="col">
                          {{
                            'SALES.CUSTOMER_ENGAGEMENTS.CUSTOMER_ENGAGEMENT_DATA.CUSTOMER_ENGAGEMENT_USED_VALUE'
                              | translate
                          }}
                        </th>
                        <th scope="col">
                          {{
                            'SALES.CUSTOMER_ENGAGEMENTS.CUSTOMER_ENGAGEMENT_DATA.CUSTOMER_ENGAGEMENT_REMAINING_VALUE'
                              | translate
                          }}
                        </th>
                        <th scope="col">
                          {{ 'SALES.CUSTOMER_ENGAGEMENTS.CUSTOMER_ENGAGEMENT_DATA.NOTES' | translate }}
                        </th>
                        <th scope="col">
                          {{
                            'SALES.CUSTOMER_ENGAGEMENTS.CUSTOMER_ENGAGEMENT_DATA.CUSTOMER_ENGAGEMENT_STATUS' | translate
                          }}
                        </th>
                        <th scope="col">{{ 'SHARED.TOOLS' | translate }}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let engagement of engagements$ | async; trackBy: trackItems; let i = index">
                        <th scope="row">{{ i + 1 }}</th>
                        <td>
                          {{
                            engagement.customerEngagementType?.name
                              | localize: engagement.customerEngagementType?.nameEn
                              | async
                          }}
                        </td>
                        <td>{{ engagement.value | toNumber }}</td>
                        <td>
                          <span class="badge bg-warning round">
                            {{ engagement.usedValue | toDecimal }}
                          </span>
                        </td>
                        <td>
                          <span class="badge bg-danger round">
                            {{ engagement.remainingValue | toDecimal }}
                          </span>
                        </td>
                        <td>{{ engagement.notes }}</td>
                        <td>
                          <app-status
                            [status]="engagement.isActive"
                            [activeText]="'SALES.CUSTOMER_ENGAGEMENTS.CUSTOMER_ENGAGEMENT_DATA.ACTIVE' | translate"
                            [notActiveText]="
                              'SALES.CUSTOMER_ENGAGEMENTS.CUSTOMER_ENGAGEMENT_DATA.NOT_ACTIVE' | translate
                            "
                          >
                          </app-status>
                        </td>
                        <td>
                          <a
                            *ngIf="[Claims.UPDATE_CUSTOMER_ENGAGEMENT] | authorize | async"
                            class="info p-0"
                            [ngbTooltip]="
                              'SALES.CUSTOMER_ENGAGEMENTS.CUSTOMER_ENGAGEMENT_DATA.ENGAGEMENT_UPDATE' | translate
                            "
                            (click)="update(engagement)"
                          >
                            <i class="fa fa-edit font-medium-3 mr-2"></i>
                          </a>
                          <a
                            *ngIf="[Claims.DELETE_CUSTOMER_ENGAGEMENT] | authorize | async"
                            class="danger p-0"
                            [ngbTooltip]="'SHARED.CONFIRM.DELETE' | translate"
                            (click)="delete(engagement)"
                          >
                            <i class="fa fa-trash font-medium-3 mr-2"></i>
                          </a>
                          <a
                            *ngIf="
                              ([Claims.UPDATE_CUSTOMER_ENGAGEMENT] | authorize | async) &&
                              engagement.customerEngagementValueChangeLogs?.length
                            "
                            class="info p-0"
                            [ngbTooltip]="
                              'SALES.CUSTOMER_ENGAGEMENTS.CUSTOMER_ENGAGEMENT_DATA.ENGAGEMENT_VALUE_UPDATE' | translate
                            "
                            (click)="viewValueChangeLogs(engagement.customerEngagementValueChangeLogs)"
                          >
                            <i class="fa fa-history font-medium-3 mr-2"></i>
                          </a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- engagements list ends -->

<!-- no items template starts -->
<ng-template #noItems>
  <div class="col-md-12 alert alert-warning mt-3 text-center" role="alert">
    {{ 'SALES.CUSTOMER_ENGAGEMENTS.SEARCH_RESULTS_NOT_FOUND' | translate }}
    <button
      *ngIf="[Claims.CREATE_CUSTOMER_ENGAGEMENT] | authorize | async"
      class="btn btn-raised btn-success round"
      (click)="create()"
    >
      <i class="fa fa-plus"></i>
      <span class="mx-1">{{ 'SHARED.CONFIRM.ADD' | translate }}</span>
    </button>
  </div>
</ng-template>
<!-- no items template ends -->

<!-- customer engagements ends -->

<!-- create engagement modal starts -->
<ng-template #createModalRef let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
      {{ 'SALES.CUSTOMER_ENGAGEMENTS.CREATE_CUSTOMER_ENGAGEMENT' | translate }}
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="d()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-md-12">
        <form [formGroup]="createForm" (ngSubmit)="confirmCreate()">
          <div>
            <h4>{{ 'SALES.CUSTOMER_ENGAGEMENTS.NEW_CUSTOMER_ENGAGEMENT_DATA' | translate }}</h4>
          </div>
          <div class="row">
            <div class="form-group col-md-12">
              <label for="typeId">{{
                'SALES.CUSTOMER_ENGAGEMENTS.CUSTOMER_ENGAGEMENT_DATA.CUSTOMER_ENGAGEMENT_TYPE' | translate
              }}</label>
              <ng-select
                class="round"
                formControlName="typeId"
                labelForId="typeId"
                searchable="true"
                [placeholder]="
                  'SALES.CUSTOMER_ENGAGEMENTS.CUSTOMER_ENGAGEMENT_DATA.CUSTOMER_ENGAGEMENT_TYPE_PLACEHOLDER' | translate
                "
                [notFoundText]="'SHARED.SEARCH.SEARCH_NOT_FOUND' | translate"
              >
                <ng-option *ngFor="let type of engagementTypes$ | async" [value]="type.id">
                  {{ type.name | localize: type.nameEn | async }}
                </ng-option>
              </ng-select>
            </div>
            <div class="form-group col-md-12">
              <label for="value">{{
                'SALES.CUSTOMER_ENGAGEMENTS.CUSTOMER_ENGAGEMENT_DATA.CUSTOMER_ENGAGEMENT_VALUE' | translate
              }}</label>
              <input
                type="text"
                [mask]="DECIMAL_MASK"
                thousandSeparator=","
                minLength="0"
                id="value"
                class="form-control round"
                [placeholder]="
                  'SALES.CUSTOMER_ENGAGEMENTS.CUSTOMER_ENGAGEMENT_DATA.CUSTOMER_ENGAGEMENT_VALUE_PLACEHOLDER'
                    | translate
                "
                formControlName="value"
              />
            </div>
            <div class="form-group col-md-12">
              <label for="notes">{{ 'SALES.CUSTOMER_ENGAGEMENTS.CUSTOMER_ENGAGEMENT_DATA.NOTES' | translate }}</label>
              <textarea
                id="notes"
                class="form-control round"
                [placeholder]="'SALES.CUSTOMER_ENGAGEMENTS.CUSTOMER_ENGAGEMENT_DATA.NOTES_PLACEHOLDER' | translate"
                minlength="0"
                maxlength="200"
                formControlName="notes"
              ></textarea>
            </div>
            <!-- A hidden submit button to allow user submit form with Enter -->
            <button type="submit" hidden></button>
          </div>
        </form>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="modal-actions">
      <button
        type="button"
        class="btn btn-raised btn-success round"
        [disabled]="isCustomerEngagementCreating$ | async"
        (click)="confirmCreate()"
        ngbAutofocus
      >
        <i class="fa fa-check"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.SAVE' | translate }}</span>
      </button>
      <button type="button" class="btn btn-outline-dark round" (click)="c()">
        <i class="fa fa-times"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.CANCEL' | translate }}</span>
      </button>
    </div>
  </div>
</ng-template>
<!-- create engagement modal ends -->

<!-- update engagement modal starts -->
<ng-template #updateModalRef let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title text-primary" id="modal-basic-title">
      {{ 'SALES.CUSTOMER_ENGAGEMENTS.UPDATE_CUSTOMER_ENGAGEMENT_DATA' | translate }}
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="d()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-md-12">
        <form [formGroup]="updateForm" (ngSubmit)="confirmUpdate()">
          <div>
            <h4>{{ 'SALES.CUSTOMER_ENGAGEMENTS.CUSTOMER_ENGAGEMENT_DATA_TITLE' | translate }}</h4>
          </div>
          <div class="row">
            <div class="form-group col-md-12">
              <label for="typeName">{{
                'SALES.CUSTOMER_ENGAGEMENTS.CUSTOMER_ENGAGEMENT_DATA.CUSTOMER_ENGAGEMENT_TYPE' | translate
              }}</label>
              <input type="text" id="typeName" class="form-control round" readonly formControlName="typeName" />
            </div>
            <div class="form-group col-md-12">
              <label for="value">{{
                'SALES.CUSTOMER_ENGAGEMENTS.CUSTOMER_ENGAGEMENT_DATA.CUSTOMER_ENGAGEMENT_VALUE' | translate
              }}</label>
              <input
                type="text"
                [mask]="DECIMAL_MASK"
                thousandSeparator=","
                minLength="0"
                id="value"
                class="form-control round"
                [placeholder]="
                  'SALES.CUSTOMER_ENGAGEMENTS.CUSTOMER_ENGAGEMENT_DATA.CUSTOMER_ENGAGEMENT_VALUE_PLACEHOLDER'
                    | translate
                "
                formControlName="value"
              />
            </div>
            <div class="form-group col-md-12">
              <label for="notes">{{ 'SALES.CUSTOMER_ENGAGEMENTS.CUSTOMER_ENGAGEMENT_DATA.NOTES' | translate }}</label>
              <textarea
                id="notes"
                class="form-control round"
                [placeholder]="'SALES.CUSTOMER_ENGAGEMENTS.CUSTOMER_ENGAGEMENT_DATA.NOTES_PLACEHOLDER' | translate"
                minlength="0"
                maxlength="200"
                formControlName="notes"
              ></textarea>
            </div>
            <div class="form-group col-md-12">
              <ui-switch
                class="no-outline"
                [checkedLabel]="'SALES.CUSTOMER_ENGAGEMENTS.CUSTOMER_ENGAGEMENT_DATA.ACTIVATED' | translate"
                checkedTextColor="#FFFF"
                [uncheckedLabel]="'SALES.CUSTOMER_ENGAGEMENTS.CUSTOMER_ENGAGEMENT_DATA.BLOCKED' | translate"
                uncheckedTextColor="#FFFF"
                defaultBgColor="#dc3545"
                defaultBoColor="none"
                formControlName="isActive"
              ></ui-switch>
            </div>
            <!-- A hidden submit button to allow user submit form with Enter -->
            <button type="submit" hidden></button>
          </div>
        </form>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="modal-actions">
      <button
        type="button"
        class="btn btn-raised btn-primary round"
        [disabled]="isCustomerEngagementUpdating$ | async"
        (click)="confirmUpdate()"
        ngbAutofocus
      >
        <i class="fa fa-check"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.SAVE' | translate }}</span>
      </button>
      <button type="button" class="btn btn-outline-dark round" (click)="c()">
        <i class="fa fa-times"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.CANCEL' | translate }}</span>
      </button>
    </div>
  </div>
</ng-template>
<!-- update engagement modal ends -->

<!-- delete engagement modal starts -->
<ng-template #deleteModalRef let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title text-danger" id="modal-basic-title">
      {{ 'SALES.CUSTOMER_ENGAGEMENTS.DELETE_CUSTOMER_ENGAGEMENT' | translate }}
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="d()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-md-12">
        <form [formGroup]="deleteForm" (ngSubmit)="confirmDelete()">
          <div>
            <h4>{{ 'SALES.CUSTOMER_ENGAGEMENTS.CUSTOMER_ENGAGEMENT_DATA_TITLE' | translate }}</h4>
          </div>
          <div class="row">
            <div class="form-group col-md-12">
              <label for="typeName">{{
                'SALES.CUSTOMER_ENGAGEMENTS.CUSTOMER_ENGAGEMENT_DATA.CUSTOMER_ENGAGEMENT_TYPE' | translate
              }}</label>
              <input type="text" id="typeName" class="form-control round" readonly formControlName="typeName" />
            </div>
            <div class="form-group col-md-12">
              <label for="value">{{
                'SALES.CUSTOMER_ENGAGEMENTS.CUSTOMER_ENGAGEMENT_DATA.CUSTOMER_ENGAGEMENT_VALUE' | translate
              }}</label>
              <input
                type="text"
                [mask]="DECIMAL_MASK"
                thousandSeparator=","
                minLength="0"
                id="value"
                class="form-control round"
                readonly
                formControlName="value"
              />
            </div>
            <div class="form-group col-md-12">
              <label for="notes">{{ 'SALES.CUSTOMER_ENGAGEMENTS.CUSTOMER_ENGAGEMENT_DATA.NOTES' | translate }}</label>
              <textarea id="notes" class="form-control round" readonly formControlName="notes"></textarea>
            </div>
            <div class="form-group col-md-12">
              <ui-switch
                class="no-outline"
                [checkedLabel]="'SALES.CUSTOMER_ENGAGEMENTS.CUSTOMER_ENGAGEMENT_DATA.ACTIVATED' | translate"
                checkedTextColor="#FFFF"
                [uncheckedLabel]="'SALES.CUSTOMER_ENGAGEMENTS.CUSTOMER_ENGAGEMENT_DATA.BLOCKED' | translate"
                uncheckedTextColor="#FFFF"
                defaultBgColor="#dc3545"
                defaultBoColor="none"
                formControlName="isActive"
              ></ui-switch>
            </div>
            <!-- A hidden submit button to allow user submit form with Enter -->
            <button type="submit" hidden></button>
          </div>
        </form>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="modal-actions">
      <button
        type="button"
        class="btn btn-raised btn-danger round"
        [disabled]="isCustomerEngagementDeleting$ | async"
        (click)="confirmDelete()"
        ngbAutofocus
      >
        <i class="fa fat-trash"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.DELETE' | translate }}</span>
      </button>
      <button type="button" class="btn btn-outline-dark round" (click)="c()">
        <i class="fa fa-times"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.CANCEL' | translate }}</span>
      </button>
    </div>
  </div>
</ng-template>
<!-- delete engagement modal ends -->

<!-- view engagement value change logs modal starts -->
<ng-template #valueChangeLogsModalRef let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title text-primary" id="modal-basic-title">
      {{ 'SALES.CUSTOMER_ENGAGEMENTS.DISPLAY_CUSTOMER_ENGAGEMENT_DATA' | translate }}
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="d()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-md-12 alert alert-light text-center" role="alert">
        ({{ customerEngagementValueChangeLogs.length }})
        {{ 'SALES.CUSTOMER_ENGAGEMENTS.UPDATE_ENGAGEMENT_DATA_VALUE' | translate }}
      </div>
      <div class="col-md-12">
        <div class="table-responsive">
          <table class="table text-center">
            <thead>
              <tr>
                <th scope="col"><i class="fa fa-list-ol"></i></th>
                <th scope="col">{{ 'SALES.CUSTOMER_ENGAGEMENTS.CUSTOMER_ENGAGEMENT_DATA.FROM_VALUE' | translate }}</th>
                <th scope="col">{{ 'SALES.CUSTOMER_ENGAGEMENTS.CUSTOMER_ENGAGEMENT_DATA.TO_VALUE' | translate }}</th>
                <th scope="col">
                  {{ 'SALES.CUSTOMER_ENGAGEMENTS.CUSTOMER_ENGAGEMENT_DATA.UPDATED_DATE' | translate }}
                </th>
                <th scope="col">{{ 'SALES.CUSTOMER_ENGAGEMENTS.CUSTOMER_ENGAGEMENT_DATA.USER' | translate }}</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let log of customerEngagementValueChangeLogs; let i = index">
                <th scope="row">{{ i + 1 }}</th>
                <td>{{ log.fromValue | toNumber }}</td>
                <td>{{ log.toValue | toNumber }}</td>
                <td>
                  <span [ngbTooltip]="log.createdAt | date: 'time'">
                    {{ log.createdAt | date }}
                  </span>
                </td>
                <td>{{ log.user.name }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="modal-actions">
      <button type="button" class="btn btn-outline-dark round" (click)="c()" ngbAutofocus>
        <i class="fa fa-times"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.CANCEL' | translate }}</span>
      </button>
    </div>
  </div>
</ng-template>
<!-- view engagement value change logs modal ends -->
