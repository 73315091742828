import { Injectable } from '@angular/core';

import { of } from 'rxjs';
import { switchMap, map, catchError } from 'rxjs/operators';
import { Effect, ofType, Actions } from '@ngrx/effects';

import { LookupsService } from 'lookups/services';
import {
  ProjectLogTypeActionType,
  GetAllProjectLogTypesFail,
  GetAllProjectLogTypesSuccess,
} from 'lookups/store/actions';

@Injectable()
export class ProjectLogTypeEffects {
  constructor(private actions$: Actions, private lookupsService: LookupsService) {}

  /* ========================= GET_ALL_PROJECT_LOG_TYPES =================================== */
  @Effect()
  getAllProjectLogTypes$ = this.actions$.pipe(
    ofType(ProjectLogTypeActionType.GET_ALL_PROJECT_LOG_TYPES),
    switchMap(() =>
      this.lookupsService.getAllProjectLogTypes().pipe(
        map((response) => new GetAllProjectLogTypesSuccess(response)),
        catchError((error) => of(new GetAllProjectLogTypesFail(error)))
      )
    )
  );
}
