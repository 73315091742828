import { ProjectsActionType, ProjectsActions } from 'app-search/store/actions';
import { ProjectsState } from 'app-search/store/states';

/**
 * The initial state from which the state starts.
 */
const initialState: ProjectsState = {
  data: [],
  paginationInfo: {
    total: 0,
    page: 1,
    pageSize: 0,
    count: 0,
  },
  error: null,
  isSearching: false,
  isSearchCompleted: false,
};

/**
 * The reducer function that is called in each action dispatch against the state.
 * @param state The current state.
 * @param action The action that will affect the state.
 */
export function projectsReducer(state: ProjectsState = initialState, action: ProjectsActions): ProjectsState {
  switch (action.type) {
    //#region SEARCH_PROJECTS

    case ProjectsActionType.SEARCH_PROJECTS: {
      return {
        ...state,
        isSearching: true,
        isSearchCompleted: false,
        error: null,
      };
    }

    case ProjectsActionType.SEARCH_PROJECTS_FAIL: {
      return {
        ...state,
        error: action.payload,
        isSearching: false,
        isSearchCompleted: false,
      };
    }

    case ProjectsActionType.SEARCH_PROJECTS_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        paginationInfo: {
          ...action.payload.meta,
        },
        error: null,
        isSearching: false,
        isSearchCompleted: true,
      };
    }

    //#endregion SEARCH_PROJECTS

    default:
      return state;
  }
}
