import * as fromCustomers from 'sales/containers/customers';
import * as fromPOS from 'sales/containers/pos';
import * as fromInvoices from 'sales/containers/invoices';
import * as fromSaleReturns from 'sales/containers/returns';

export * from 'sales/containers/customers';
export * from 'sales/containers/pos';
export * from 'sales/containers/invoices';
export * from 'sales/containers/returns';

/**
 * The set of containers registered on this module.
 */
export const CONTAINERS: any[] = [
  ...fromCustomers.CONTAINERS,
  ...fromPOS.CONTAINERS,
  ...fromInvoices.CONTAINERS,
  ...fromSaleReturns.CONTAINERS,
];
