<div class="row mb-1">
  <div class="col-md-auto">
    <button
      type="button"
      class="btn btn-sm btn-raised btn-primary round"
      [ngbTooltip]="'SALES.CUSTOMERS.ADD_SALE_INVOICE' | translate"
      placement="top-right"
      (click)="addInvoice()"
    >
      <i class="fa fa-plus"></i>
    </button>
    <button
      type="button"
      class="btn btn-md btn-raised btn-danger round mx-2"
      [ngbTooltip]="'SALES.CUSTOMERS.DELETE_ALL_INVOICES' | translate"
      (click)="openDeleteModal()"
      [disabled]="formArray.controls.length == 0"
    >
      <i class="fa fa-trash"></i>
    </button>
  </div>
</div>
<div [formGroup]="formArray.parent" class="row">
  <div class="col-12">
    <div class="table-responsive">
      <table class="table text-center">
        <thead>
          <tr>
            <th scope="col">
              <i class="fa fa-barcode"></i
              ><span class="mx-1">{{ 'SALES.CUSTOMERS.CUSTOMER_DATA.INVOICE_NUMBER' | translate }}</span>
            </th>
            <th scope="col">{{ 'SALES.CUSTOMERS.CUSTOMER_DATA.CUSTOMER' | translate }}</th>
            <th scope="col">{{ 'SALES.CUSTOMERS.CUSTOMER_DATA.INVOICE_VALUE' | translate }}</th>
            <th scope="col">{{ 'SALES.CUSTOMERS.CUSTOMER_DATA.PAID' | translate }}</th>
            <th scope="col">{{ 'SALES.CUSTOMERS.CUSTOMER_DATA.REMAINING' | translate }}</th>
            <th scope="col">
              <i class="fa fa-usd"></i>
              <span class="mx-1">{{ 'SALES.CUSTOMERS.CUSTOMER_DATA.PAYMENT_VALUE' | translate }}</span>
            </th>
            <th scope="col">{{ 'SHARED.TOOLS' | translate }}</th>
          </tr>
        </thead>
        <tbody [formArrayName]="formArrayName">
          <tr [formGroup]="formGroup" *ngFor="let formGroup of formArray.controls; let index = index">
            <th scope="row">
              <i class="fa fa-hashtag c-primary"></i>
              <span class="mx-1">{{ formGroup.value.invoice.invoiceNum }}</span>
              <span class="mx-1">({{ formGroup.value.invoice.transactionDate | date: 'dd/MM/yyyy' }})</span>
            </th>
            <td>
              {{ formGroup.value.invoice.customer.name | localize: formGroup.value.invoice.customer.nameEn | async }}
            </td>
            <td>
              <span class="badge bg-success round">
                {{ formGroup.value.invoice.net | toDecimal }}
              </span>
            </td>
            <td>
              <span class="badge bg-warning round">
                {{ formGroup.value.invoice.paid | toDecimal }}
              </span>
            </td>
            <td>
              <span class="badge bg-danger round">
                {{ formGroup.value.invoice.remaining | toDecimal }}
              </span>
            </td>
            <td class="min-width-9">
              <input
                type="text"
                [mask]="DECIMAL_MASK"
                thousandSeparator=","
                name="value"
                autocomplete="off"
                class="form-control round"
                placeholder="0"
                formControlName="value"
              />
            </td>
            <td>
              <a class="danger p-0" [ngbTooltip]="'SHARED.CONFIRM.DELETE' | translate" (click)="removeInvoice(index)">
                <i class="fa fa-close font-medium-3 mr-2"></i>
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>

<!-- unpaid sale invoices search starts -->
<app-unpaid-sale-invoices-search
  [closeOnSelect]="false"
  (invoices)="selectInvoices($event)"
  [(visible)]="invoicesListVisibility"
  [exclusiveCustomerId]="exclusiveCustomerId"
>
</app-unpaid-sale-invoices-search>
<!-- unpaid sale invoices search ends -->

<!-- confirm delete all invoices modal starts -->
<ng-template #deleteModalRef let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title text-danger" id="modal-basic-title">
      {{ 'SALES.CUSTOMERS.DELETE_ALL_INVOICES' | translate }}
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="d()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p class="text-danger">{{ 'SALES.CUSTOMERS.CONFIRM_DELETE_ALL_INVOICES_MESSAGE' | translate }}</p>
  </div>
  <div class="modal-footer">
    <div class="modal-actions">
      <button type="button" class="btn btn-raised btn-danger round" (click)="c(); resetInvoices()" ngbAutofocus>
        <i class="fa fa-check"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.YES' | translate }}</span>
      </button>
      <button type="button" class="btn btn-outline-dark round" (click)="c()">
        <i class="fa fa-times"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.CANCEL' | translate }}</span>
      </button>
    </div>
  </div>
</ng-template>
<!-- confirm modal ends -->
