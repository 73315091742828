import { createSelector } from '@ngrx/store';

import { selectLookupsState } from 'lookups/store/selectors/feature.selectors';
import { LookupsState, AssetExclusionReasonState } from 'lookups/store/states';

/**
 * Gets the assetExclusionReason state.
 */
const selectAssetExclusionReasons = createSelector(
  selectLookupsState,
  (state: LookupsState) => state.assetExclusionReasons
);

/**
 * Gets the list of loaded assetExclusionReasons.
 */
export const getAssetExclusionReasons = createSelector(
  selectAssetExclusionReasons,
  (state: AssetExclusionReasonState) => state.data
);

/**
 * Gets the error that occurred in the state.
 */
export const getAssetExclusionReasonsError = createSelector(
  selectAssetExclusionReasons,
  (state: AssetExclusionReasonState) => state.error
);

/**
 * Determines if there is a running load process.
 */
export const getAssetExclusionReasonsIsLoading = createSelector(
  selectAssetExclusionReasons,
  (state: AssetExclusionReasonState) => state.isLoading
);

/**
 * Determines if the last load process has been ended successfully.
 */
export const getAssetExclusionReasonsIsLoaded = createSelector(
  selectAssetExclusionReasons,
  (state: AssetExclusionReasonState) => state.isLoaded
);
