import { Injectable } from '@angular/core';

import { Effect, ofType, Actions } from '@ngrx/effects';
import { switchMap, map, catchError, tap } from 'rxjs/operators';
import { of } from 'rxjs';

import { NotificationService, StatusCode, TranslationService } from 'shared';
import { OrdersDeliveryService } from 'sales/services';
import {
  OrdersDeliveryActionType,
  SearchOrdersDelivery,
  SearchOrdersDeliveryFail,
  SearchOrdersDeliverySuccess,
  DeliveryOrder,
  DeliveryOrderFail,
  DeliveryOrderSuccess,
} from 'sales/store/actions';

@Injectable()
export class OrdersDeliveryEffects {
  constructor(
    private actions$: Actions,
    private ordersDeliveryService: OrdersDeliveryService,
    private notificationService: NotificationService,
    private translationService: TranslationService
  ) {}

  /* ========================= SEARCH_ORDERS_DELIVERY =================================== */
  @Effect()
  searchOrdersDelivery$ = this.actions$.pipe(
    ofType(OrdersDeliveryActionType.SEARCH_ORDERS_DELIVERY),
    switchMap(({ payload }: SearchOrdersDelivery) => {
      return this.ordersDeliveryService.searchOrdersDelivery(payload.locations).pipe(
        map((response) => new SearchOrdersDeliverySuccess(response)),
        catchError((error) => of(new SearchOrdersDeliveryFail(error)))
      );
    })
  );

  /* ========================= DELIVERING_ORDER =================================== */
  @Effect()
  delivery$ = this.actions$.pipe(
    ofType(OrdersDeliveryActionType.DELIVERY_ORDER),
    switchMap((action: DeliveryOrder) =>
      this.ordersDeliveryService.deliveryOrders(action.payload).pipe(
        map((response) => new DeliveryOrderSuccess(response)),
        catchError((error) => of(new DeliveryOrderFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  deliverySuccess$ = this.actions$.pipe(
    ofType(OrdersDeliveryActionType.DELIVERY_ORDER_SUCCESS),
    tap((action: DeliveryOrderSuccess) => {
      this.notificationService.success(this.translationService.translate('SALES.ORDERS_DELIVERY.ORDER_DELIVERED'));
    })
  );

  @Effect({ dispatch: false })
  deliveryFail$ = this.actions$.pipe(
    ofType(OrdersDeliveryActionType.DELIVERY_ORDER_FAIL),
    tap((action: DeliveryOrderFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(this.translationService.translate('SALES.ORDERS_DELIVERY.ORDER_NOT_FOUND'));
      } else if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );
}
