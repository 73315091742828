import { CreateSaleReturnComponent } from 'sales/components/returns/create-sale-return/create-sale-return.component';
import { SaleReturnsDetailComponent } from 'sales/components/returns/sale-return-details/sale-return-details.component';
import { SaleReturnsListComponent } from 'sales/components/returns/sale-returns-list/sale-returns-list.component';

export * from 'sales/components/returns/create-sale-return/create-sale-return.component';
export * from 'sales/components/returns/sale-return-details/sale-return-details.component';
export * from 'sales/components/returns/sale-returns-list/sale-returns-list.component';

/**
 * The set of components registered on this sub-module.
 */
export const COMPONENTS: any[] = [CreateSaleReturnComponent, SaleReturnsDetailComponent, SaleReturnsListComponent];
