import { Injectable } from '@angular/core';

import { of } from 'rxjs';
import { switchMap, map, catchError } from 'rxjs/operators';
import { Effect, ofType, Actions } from '@ngrx/effects';

import { LookupsService } from 'lookups/services';
import { ReligionActionType, GetAllReligionsFail, GetAllReligionsSuccess } from 'lookups/store/actions';

@Injectable()
export class ReligionEffects {
  constructor(private actions$: Actions, private lookupsService: LookupsService) {}

  /* ========================= GET_ALL_RELIGIONS =================================== */
  @Effect()
  getAllReligions$ = this.actions$.pipe(
    ofType(ReligionActionType.GET_ALL_RELIGIONS),
    switchMap(() =>
      this.lookupsService.getAllReligions().pipe(
        map((response) => new GetAllReligionsSuccess(response)),
        catchError((error) => of(new GetAllReligionsFail(error)))
      )
    )
  );
}
