<!-- page header starts -->
<app-page-header [info]="pageInfo"></app-page-header>
<!-- page header ends -->

<!-- create account form starts -->
<div class="row">
  <div class="col-md-12">
    <form [formGroup]="form" (ngSubmit)="submit()">
      <div class="card">
        <div class="card-header mb-3">
          <h4 class="card-title">{{ 'FINANCES.ACCOUNTS.ACCOUNT_DATA_TITLE' | translate }}</h4>
        </div>
        <div class="card-content">
          <div class="px-3">
            <div class="row">
              <div class="form-group col-md-6">
                <label for="code">{{ 'FINANCES.ACCOUNTS.ACCOUNT_DATA.ACCOUNT_CODE' | translate }}</label>
                <input
                  type="text"
                  id="code"
                  class="form-control round"
                  [placeholder]="'FINANCES.ACCOUNTS.ACCOUNT_DATA.ACCOUNT_CODE_PLACEHOLDER' | translate"
                  maxlength="50"
                  formControlName="code"
                />
              </div>
              <div class="form-group col-md-6">
                <label for="description">{{ 'FINANCES.ACCOUNTS.ACCOUNT_DATA.ACCOUNT_DESCRIPTION' | translate }}</label>
                <input
                  type="text"
                  id="description"
                  class="form-control round"
                  [placeholder]="'FINANCES.ACCOUNTS.ACCOUNT_DATA.ACCOUNT_DESCRIPTION_PLACEHOLDER' | translate"
                  maxlength="100"
                  formControlName="description"
                />
              </div>
              <div class="form-group col-md-6">
                <label for="primaryAccountId">{{ 'FINANCES.ACCOUNTS.ACCOUNT_DATA.PRIMARY_ACCOUNT' | translate }}</label>
                <ng-select
                  class="round"
                  formControlName="primaryAccountId"
                  labelForId="primaryAccountId"
                  searchable="true"
                  [placeholder]="'FINANCES.ACCOUNTS.ACCOUNT_DATA.PRIMARY_ACCOUNT_SELECT_PLACEHOLDER' | translate"
                  [notFoundText]="'SHARED.SEARCH.SEARCH_NOT_FOUND' | translate"
                >
                  <ng-option *ngFor="let primaryAccount of primaryAccounts$ | async" [value]="primaryAccount.id">
                    {{ primaryAccount?.name | localize: primaryAccount?.nameEn | async }}
                  </ng-option>
                </ng-select>
              </div>
              <div class="form-group col-md-6">
                <label for="secondaryAccountId">{{
                  'FINANCES.ACCOUNTS.ACCOUNT_DATA.SECONDARY_ACCOUNT' | translate
                }}</label>
                <ng-select
                  class="round"
                  formControlName="secondaryAccountId"
                  labelForId="secondaryAccountId"
                  searchable="true"
                  [placeholder]="'FINANCES.ACCOUNTS.ACCOUNT_DATA.SECONDARY_ACCOUNT_PLACEHOLDER' | translate"
                  [notFoundText]="'SHARED.SEARCH.SEARCH_NOT_FOUND' | translate"
                  (open)="secondaryAccountsListVisibility = true"
                >
                  <ng-option *ngFor="let secondaryAccount of secondaryAccounts" [value]="secondaryAccount.id">
                    {{ secondaryAccount.description }}
                  </ng-option>
                </ng-select>
              </div>
              <div class="form-group col-12 d-flex justify-content-between mt-2">
                <button type="submit" class="btn btn-raised btn-success round" [disabled]="isCreating$ | async">
                  <i class="fa fa-check"></i>
                  <span class="mx-1">{{ 'SHARED.CONFIRM.SAVE' | translate }}</span>
                </button>
                <a class="btn btn-dark round" routerLink="/finances/accounts">
                  <i class="fa fa-undo"></i>
                  <span class="mx-1">{{ 'FINANCES.ACCOUNTS.ACCOUNT_DATA.ACCOUNTS_RETURN' | translate }}</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
<!-- create account form ends -->

<!-- confirm modal starts -->
<ng-template #confirmModalRef let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
      {{ 'FINANCES.ACCOUNTS.CONFIRM_CREATE_ACCOUNT_TITLE' | translate }}
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="d()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p class="text-success">{{ 'FINANCES.ACCOUNTS.CONFIRM_CREATE_ACCOUNT_MESSAGE' | translate }}</p>
  </div>
  <div class="modal-footer">
    <div class="modal-actions">
      <button type="button" class="btn btn-raised btn-success round" (click)="c(); confirm()" ngbAutofocus>
        <i class="fa fa-check"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.SAVE' | translate }}</span>
      </button>
      <button type="button" class="btn btn-outline-dark round" (click)="c()">
        <i class="fa fa-times"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.CANCEL' | translate }}</span>
      </button>
    </div>
  </div>
</ng-template>
<!-- confirm modal ends -->

<!-- accounts search starts -->
<app-accounts-search
  onlyActive="true"
  closeOnSelect="true"
  (accounts)="selectSecondaryAccount($event)"
  [(visible)]="secondaryAccountsListVisibility"
>
</app-accounts-search>
<!-- accounts search ends -->
