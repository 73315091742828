import { createSelector } from '@ngrx/store';

import { selectLookupsState } from 'lookups/store/selectors/feature.selectors';
import { LookupsState, GovServiceRequestStatusesState } from 'lookups/store/states';

/**
 * Gets the gov service request statuses state.
 */
const selectGovServiceRequestStatuses = createSelector(
  selectLookupsState,
  (state: LookupsState) => state.govServiceRequestStatuses
);

/**
 * Gets the list of loaded gov service request statuses.
 */
export const getGovServiceRequestStatuses = createSelector(
  selectGovServiceRequestStatuses,
  (state: GovServiceRequestStatusesState) => state.data
);

/**
 * Gets the error that occurred in the state.
 */
export const getGovServiceRequestStatusesError = createSelector(
  selectGovServiceRequestStatuses,
  (state: GovServiceRequestStatusesState) => state.error
);

/**
 * Determines if there is a running load process.
 */
export const getGovServiceRequestStatusesIsLoading = createSelector(
  selectGovServiceRequestStatuses,
  (state: GovServiceRequestStatusesState) => state.isLoading
);

/**
 * Determines if the last load process has been ended successfully.
 */
export const getGovServiceRequestStatusesIsLoaded = createSelector(
  selectGovServiceRequestStatuses,
  (state: GovServiceRequestStatusesState) => state.isLoaded
);
