import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';

import { AppHttpResponse } from 'shared/models';

/**
 * The prints services.
 */
@Injectable()
export class PrintsService {
  /**
   * The relative route for the stores reports.
   *
   * No leading or trailing slashes required.
   */
  private storesReportsApi = 'reporting/stores';

  /**
   * The relative route for the purchases reports.
   *
   * No leading or trailing slashes required.
   */
  private purchasesReportsApi = 'reporting/purchases';

  /**
   * The relative route for the sales reports.
   *
   * No leading or trailing slashes required.
   */
  private salesReportsApi = 'reporting/sales';

  /**
   * The relative route for the crm reports.
   *
   * No leading or trailing slashes required.
   */
  private crmReportsApi = 'reporting/crm';

  /**
   * The relative route for the finances reports.
   *
   * No leading or trailing slashes required.
   */
  private financesReportsApi = 'reporting/finances';

  constructor(private http: HttpClient) {}

  /**
   * Gets the transaction matching the provided query params.
   * @param incomingStockId The id of the transaction.
   * @param paperSize The paper size.
   */
  public getIncomingStockPrint(incomingStockId: number, paperSize: string): Observable<AppHttpResponse<string>> {
    const params = new HttpParams().set('incomingStockId', incomingStockId.toString()).set('paperSize', paperSize);

    return this.http.get<AppHttpResponse<string>>(`${this.storesReportsApi}/incoming-stock-print`, { params });
  }

  /**
   * Gets the transaction matching the provided query params.
   * @param outgoingStockId The id of the transaction.
   * @param paperSize The paper size.
   */
  public getOutgoingStockPrint(outgoingStockId: number, paperSize: string): Observable<AppHttpResponse<string>> {
    const params = new HttpParams().set('outgoingStockId', outgoingStockId.toString()).set('paperSize', paperSize);

    return this.http.get<AppHttpResponse<string>>(`${this.storesReportsApi}/outgoing-stock-print`, { params });
  }

  /**
   * Gets the transaction matching the provided query params.
   * @param transferStockId The id of the transaction.
   * @param paperSize The paper size.
   */
  public getTransferStockPrint(transferStockId: number, paperSize: string): Observable<AppHttpResponse<string>> {
    const params = new HttpParams().set('transferStockId', transferStockId.toString()).set('paperSize', paperSize);

    return this.http.get<AppHttpResponse<string>>(`${this.storesReportsApi}/transfer-stock-print`, { params });
  }

  /**
   * Gets the transaction matching the provided query params.
   * @param purchaseRequestId The id of the transaction.
   * @param paperSize The paper size.
   */
  public getPurchaseRequestPrint(purchaseRequestId: number, paperSize: string): Observable<AppHttpResponse<string>> {
    const params = new HttpParams().set('purchaseRequestId', purchaseRequestId.toString()).set('paperSize', paperSize);

    return this.http.get<AppHttpResponse<string>>(`${this.purchasesReportsApi}/purchase-request-print`, { params });
  }

  /**
   * Gets the transaction matching the provided query params.
   * @param purchaseOrderId The id of the transaction.
   * @param paperSize The paper size.
   */
  public getPurchaseOrderPrint(purchaseOrderId: number, paperSize: string): Observable<AppHttpResponse<string>> {
    const params = new HttpParams().set('purchaseOrderId', purchaseOrderId.toString()).set('paperSize', paperSize);

    return this.http.get<AppHttpResponse<string>>(`${this.purchasesReportsApi}/purchase-order-print`, { params });
  }

  /**
   * Gets the transaction matching the provided query params.
   * @param purchaseInvoiceId The id of the transaction.
   * @param paperSize The paper size.
   */
  public getPurchaseInvoicePrint(purchaseInvoiceId: number, paperSize: string): Observable<AppHttpResponse<string>> {
    const params = new HttpParams().set('purchaseInvoiceId', purchaseInvoiceId.toString()).set('paperSize', paperSize);

    return this.http.get<AppHttpResponse<string>>(`${this.purchasesReportsApi}/purchase-invoice-print`, { params });
  }

  /**
   * Gets the transaction matching the provided query params.
   * @param purchaseReturnId The id of the transaction.
   * @param paperSize The paper size.
   */
  public getPurchaseReturnPrint(purchaseReturnId: number, paperSize: string): Observable<AppHttpResponse<string>> {
    const params = new HttpParams().set('purchaseReturnId', purchaseReturnId.toString()).set('paperSize', paperSize);

    return this.http.get<AppHttpResponse<string>>(`${this.purchasesReportsApi}/purchase-return-print`, { params });
  }

  /**
   * Gets the transaction matching the provided query params.
   * @param saleInvoiceId The id of the transaction.
   * @param paperSize The paper size.
   */
  public getSaleInvoicePrint(saleInvoiceId: number, paperSize: string): Observable<AppHttpResponse<string>> {
    const params = new HttpParams().set('saleInvoiceId', saleInvoiceId.toString()).set('paperSize', paperSize ?? '');

    return this.http.get<AppHttpResponse<string>>(`${this.salesReportsApi}/sale-invoice-print`, { params });
  }

  /**
   * Gets the transaction matching the provided query params.
   * @param saleReturnId The id of the transaction.
   * @param paperSize The paper size.
   */
  public getSaleReturnPrint(saleReturnId: number, paperSize: string): Observable<AppHttpResponse<string>> {
    const params = new HttpParams().set('saleReturnId', saleReturnId.toString()).set('paperSize', paperSize ?? '');

    return this.http.get<AppHttpResponse<string>>(`${this.salesReportsApi}/sale-return-print`, { params });
  }

  /**
   * Gets the transaction matching the provided query params.
   * @param salesQuotationId The id of the transaction.
   * @param paperSize The paper size.
   */
  public getSalesQuotationPrint(salesQuotationId: number, paperSize: string): Observable<AppHttpResponse<string>> {
    const params = new HttpParams().set('salesQuotationId', salesQuotationId.toString()).set('paperSize', paperSize);

    return this.http.get<AppHttpResponse<string>>(`${this.crmReportsApi}/sales-quotation-print`, { params });
  }

  /**
   * Gets the transaction matching the provided query params.
   * @param journalId The id of the transaction.
   * @param paperSize The paper size.
   */
  public getJournalPrint(journalId: number, paperSize: string): Observable<AppHttpResponse<string>> {
    const params = new HttpParams().set('journalId', journalId.toString()).set('paperSize', paperSize);

    return this.http.get<AppHttpResponse<string>>(`${this.financesReportsApi}/journal-print`, { params });
  }
}
