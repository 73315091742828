import { Injectable } from '@angular/core';

import { of } from 'rxjs';
import { switchMap, map, catchError } from 'rxjs/operators';
import { Effect, ofType, Actions } from '@ngrx/effects';

import { LookupsService } from 'lookups/services';
import {
  GovServiceRequestStatusActionType,
  GetAllGovServiceRequestStatusesFail,
  GetAllGovServiceRequestStatusesSuccess,
} from 'lookups/store/actions';

@Injectable()
export class GovServiceRequestStatusEffects {
  constructor(private actions$: Actions, private lookupsService: LookupsService) {}

  /* ========================= GET_ALL_GOV_SERVICE_REQUEST_STATUSES =================================== */
  @Effect()
  getAllGovServiceRequestStatuses$ = this.actions$.pipe(
    ofType(GovServiceRequestStatusActionType.GET_ALL_GOV_SERVICE_REQUEST_STATUSES),
    switchMap(() =>
      this.lookupsService.getAllGovServiceRequestStatuses().pipe(
        map((response) => new GetAllGovServiceRequestStatusesSuccess(response)),
        catchError((error) => of(new GetAllGovServiceRequestStatusesFail(error)))
      )
    )
  );
}
