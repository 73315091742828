import { findIndex, remove } from 'lodash';

import { BankAccountsActionType, BankAccountsActions } from 'finances/store/actions';
import { BankAccountState } from 'finances/store/states';

/**
 * The initial state from which the state starts.
 */
const initialState: BankAccountState = {
  bankAccounts: [],
  bankAccountLogsData: [],
  bankAccountLogsDepositByEmployeeData: [],
  bankAccountLogsByWithdrawFromAccountData: [],
  bankAccountLogsMoneyTransferData: [],
  bankAccountLogsCashReceiptData: [],
  bankAccountLogsSettlementDeedData: [],
  paginationInfo: {
    total: 0,
    page: 1,
    pageSize: 0,
    count: 0,
  },
  bankAccountLogsPaginationInfo: {
    total: 0,
    page: 1,
    pageSize: 0,
    count: 0,
  },
  error: null,
  isSearching: false,
  isSearchCompleted: false,
  isSearchingBankAccountLogs: false,
  isSearchBankAccountLogsCompleted: false,
  selectedBankAccount: null,
  selectedBankAccountLog: null,
  selectedBankAccountLogDepositByEmployee: null,
  selectedBankAccountByWithdrawFromAccount: null,
  selectedBankAccountLogMoneyTransfer: null,
  selectedBankAccountLogCashReceipt: null,
  selectedBankAccountLogSettlementDeed: null,
  isFinding: false,
  isFindCompleted: false,
  isCreating: false,
  isCreateCompleted: false,
  isBankAccountLogCreating: false,
  isBankAccountLogCreateCompleted: false,
  isBankAccountLogDepositByEmployeeCreating: false,
  isBankAccountLogDepositByEmployeeCreateCompleted: false,
  isBankAccountLogByWithdrawFromAccountCreating: false,
  isBankAccountLogByWithdrawFromAccountCreateCompleted: false,
  isBankAccountLogMoneyTransferCreating: false,
  isBankAccountLogMoneyTransferCreateCompleted: false,
  isBankAccountLogCashReceiptCreating: false,
  isBankAccountLogCashReceiptCreateCompleted: false,
  isBankAccountLogSettlementDeedCreating: false,
  isBankAccountLogSettlementDeedCreateCompleted: false,
  isUpdating: false,
  isUpdateCompleted: false,
  isBlocking: false,
  isBlockCompleted: false,
  isActivating: false,
  isActivateCompleted: false,
  isDeleting: false,
  isDeleteCompleted: false,
};

/**
 * The reducer function that is called in each action dispatch against the state.
 * @param state The current state.
 * @param action The action that will affect the state.
 */
export function bankAccountsReducer(
  state: BankAccountState = initialState,
  action: BankAccountsActions
): BankAccountState {
  switch (action.type) {
    //#region SEARCH_BANK_ACCOUNTS

    case BankAccountsActionType.SEARCH_BANK_ACCOUNTS: {
      return {
        ...state,
        isSearching: true,
        isSearchCompleted: false,
        error: null,
      };
    }

    case BankAccountsActionType.SEARCH_BANK_ACCOUNTS_FAIL: {
      return {
        ...state,
        error: action.payload,
        isSearching: false,
        isSearchCompleted: false,
      };
    }

    case BankAccountsActionType.SEARCH_BANK_ACCOUNTS_SUCCESS: {
      return {
        ...state,
        bankAccounts: action.payload.data,
        paginationInfo: {
          ...action.payload.meta,
        },
        error: null,
        isSearching: false,
        isSearchCompleted: true,
      };
    }

    //#endregion SEARCH_BANK_ACCOUNTS

    //#region SEARCH_BANK_ACCOUNT_LOGS

    case BankAccountsActionType.SEARCH_BANK_ACCOUNT_LOGS: {
      return {
        ...state,
        isSearchingBankAccountLogs: true,
        isSearchBankAccountLogsCompleted: false,
        error: null,
      };
    }

    case BankAccountsActionType.SEARCH_BANK_ACCOUNT_LOGS_FAIL: {
      return {
        ...state,
        error: action.payload,
        isSearchingBankAccountLogs: false,
        isSearchBankAccountLogsCompleted: false,
      };
    }

    case BankAccountsActionType.SEARCH_BANK_ACCOUNT_LOGS_SUCCESS: {
      return {
        ...state,
        bankAccountLogsData: action.payload.data.logs,
        bankAccountLogsPaginationInfo: {
          ...action.payload.meta,
        },
        error: null,
        isSearchingBankAccountLogs: false,
        isSearchBankAccountLogsCompleted: true,
        selectedBankAccount: action.payload.data.bankAccount,
      };
    }

    //#endregion SEARCH_BANK_ACCOUNT_LOGS

    //#region FIND_BANK_ACCOUNT

    case BankAccountsActionType.FIND_BANK_ACCOUNT: {
      return {
        ...state,
        selectedBankAccount: null,
        error: null,
        isFinding: true,
        isFindCompleted: false,
      };
    }

    case BankAccountsActionType.FIND_BANK_ACCOUNT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isFinding: false,
        isFindCompleted: false,
      };
    }

    case BankAccountsActionType.FIND_BANK_ACCOUNT_SUCCESS: {
      let bankAccounts = [...state.bankAccounts];
      const bankAccountIndex = findIndex(bankAccounts, (bankAccount) => bankAccount.id === action.payload.data.id);

      /* If bank account was found, update it. */
      if (bankAccountIndex >= 0) {
        bankAccounts[bankAccountIndex] = action.payload.data;
      } else {
        /* else, insert it to the beginning of the array. */
        bankAccounts = [action.payload.data, ...bankAccounts];
      }

      return {
        ...state,
        bankAccounts,
        selectedBankAccount: action.payload.data,
        error: null,
        isFinding: false,
        isFindCompleted: true,
      };
    }

    //#endregion FIND_BANK_ACCOUNT

    //#region CREATE_BANK_ACCOUNT

    case BankAccountsActionType.CREATE_BANK_ACCOUNT: {
      return {
        ...state,
        isCreating: true,
        isCreateCompleted: false,
        error: null,
      };
    }

    case BankAccountsActionType.CREATE_BANK_ACCOUNT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isCreating: false,
        isCreateCompleted: false,
      };
    }

    case BankAccountsActionType.CREATE_BANK_ACCOUNT_SUCCESS: {
      return {
        ...state,
        bankAccounts: [action.payload.data, ...state.bankAccounts],
        selectedBankAccount: action.payload.data,
        error: null,
        isCreating: false,
        isCreateCompleted: true,
      };
    }

    //#endregion CREATE_BANK_ACCOUNT

    //#region CREATE_BANK_ACCOUNT_LOG

    case BankAccountsActionType.CREATE_BANK_ACCOUNT_LOG: {
      return {
        ...state,
        isBankAccountLogCreating: true,
        isBankAccountLogCreateCompleted: false,
        error: null,
      };
    }

    case BankAccountsActionType.CREATE_BANK_ACCOUNT_LOG_FAIL: {
      return {
        ...state,
        error: action.payload,
        isBankAccountLogCreating: false,
        isBankAccountLogCreateCompleted: false,
      };
    }

    case BankAccountsActionType.CREATE_BANK_ACCOUNT_LOG_SUCCESS: {
      let bankAccountLogs = [...state.bankAccountLogsData];
      const bankAccountLogIndex = findIndex(
        bankAccountLogs,
        (bankAccountLog) => bankAccountLog.id === action.payload.data.id
      );

      /* If bank account was found, update it. */

      if (bankAccountLogIndex >= 0) {
        bankAccountLogs[bankAccountLogIndex] = action.payload.data;
      } else {
        /* else, insert it to the beginning of the array. */
        bankAccountLogs = [...bankAccountLogs, action.payload.data];
      }

      return {
        ...state,
        bankAccountLogsData: bankAccountLogs,
        selectedBankAccountLog: action.payload.data,
        error: null,
        isBankAccountLogCreating: false,
        isBankAccountLogCreateCompleted: true,
      };
    }

    //#endregion CREATE_BANK_ACCOUNT_LOG

    //#region CREATE_BANK_ACCOUNT_LOG_DEPOSIT_BY_EMPLOYEE

    case BankAccountsActionType.CREATE_BANK_ACCOUNT_LOG_DEPOSIT_BY_EMPLOYEE: {
      return {
        ...state,
        isBankAccountLogDepositByEmployeeCreating: true,
        isBankAccountLogDepositByEmployeeCreateCompleted: false,
        error: null,
      };
    }

    case BankAccountsActionType.CREATE_BANK_ACCOUNT_LOG_DEPOSIT_BY_EMPLOYEE_FAIL: {
      return {
        ...state,
        error: action.payload,
        isBankAccountLogDepositByEmployeeCreating: false,
        isBankAccountLogDepositByEmployeeCreateCompleted: false,
      };
    }

    case BankAccountsActionType.CREATE_BANK_ACCOUNT_LOG_DEPOSIT_BY_EMPLOYEE_SUCCESS: {
      let bankAccountLogsDepositByEmployee = [...state.bankAccountLogsDepositByEmployeeData];
      const bankAccountLogIndex = findIndex(
        bankAccountLogsDepositByEmployee,
        (bankAccountLog) => bankAccountLog.id === action.payload.data.id
      );

      /* If bank account was found, update it. */

      if (bankAccountLogIndex >= 0) {
        bankAccountLogsDepositByEmployee[bankAccountLogIndex] = action.payload.data;
      } else {
        /* else, insert it to the beginning of the array. */
        bankAccountLogsDepositByEmployee = [...bankAccountLogsDepositByEmployee, action.payload.data];
      }

      return {
        ...state,
        bankAccountLogsDepositByEmployeeData: bankAccountLogsDepositByEmployee,
        selectedBankAccountLogDepositByEmployee: action.payload.data,
        error: null,
        isBankAccountLogDepositByEmployeeCreating: false,
        isBankAccountLogDepositByEmployeeCreateCompleted: true,
      };
    }

    //#endregion CREATE_BANK_ACCOUNT_LOG_DEPOSIT_BY_EMPLOYEE

    //#region CREATE_BANK_ACCOUNT_BY_WITHDRAW_FROM_ACCOUNT

    case BankAccountsActionType.CREATE_BANK_ACCOUNT_LOG_BY_WITHDRAW_FROM_ACCOUNT: {
      return {
        ...state,
        isBankAccountLogByWithdrawFromAccountCreating: true,
        isBankAccountLogByWithdrawFromAccountCreateCompleted: false,
        error: null,
      };
    }

    case BankAccountsActionType.CREATE_BANK_ACCOUNT_LOG_BY_WITHDRAW_FROM_ACCOUNT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isBankAccountLogByWithdrawFromAccountCreating: false,
        isBankAccountLogByWithdrawFromAccountCreateCompleted: false,
      };
    }

    case BankAccountsActionType.CREATE_BANK_ACCOUNT_LOG_BY_WITHDRAW_FROM_ACCOUNT_SUCCESS: {
      let bankAccountLogsByWithdrawFromAccount = [...state.bankAccountLogsByWithdrawFromAccountData];
      const bankAccountLogIndex = findIndex(
        bankAccountLogsByWithdrawFromAccount,
        (bankAccountLog) => bankAccountLog.id === action.payload.data.id
      );

      /* If bank account was found, update it. */

      if (bankAccountLogIndex >= 0) {
        bankAccountLogsByWithdrawFromAccount[bankAccountLogIndex] = action.payload.data;
      } else {
        /* else, insert it to the beginning of the array. */
        bankAccountLogsByWithdrawFromAccount = [...bankAccountLogsByWithdrawFromAccount, action.payload.data];
      }

      return {
        ...state,
        bankAccountLogsByWithdrawFromAccountData: bankAccountLogsByWithdrawFromAccount,
        selectedBankAccountByWithdrawFromAccount: action.payload.data,
        error: null,
        isBankAccountLogByWithdrawFromAccountCreating: false,
        isBankAccountLogByWithdrawFromAccountCreateCompleted: true,
      };
    }

    //#endregion CREATE_BANK_ACCOUNT_BY_WITHDRAW_FROM_ACCOUNT

    //#region UPDATE_BANK_ACCOUNT

    case BankAccountsActionType.UPDATE_BANK_ACCOUNT: {
      return {
        ...state,
        isUpdating: true,
        isUpdateCompleted: false,
        error: null,
      };
    }

    case BankAccountsActionType.UPDATE_BANK_ACCOUNT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isUpdating: false,
        isUpdateCompleted: false,
      };
    }

    case BankAccountsActionType.UPDATE_BANK_ACCOUNT_SUCCESS: {
      let bankAccounts = [...state.bankAccounts];
      const bankAccountIndex = findIndex(bankAccounts, (bankAccount) => bankAccount.id === action.payload.data.id);

      /* If bank account was found, update it. */
      if (bankAccountIndex >= 0) {
        bankAccounts[bankAccountIndex] = action.payload.data;
      } else {
        /* else, insert it to the beginning of the array. */
        bankAccounts = [action.payload.data, ...bankAccounts];
      }

      return {
        ...state,
        bankAccounts,
        selectedBankAccount: action.payload.data,
        error: null,
        isUpdating: false,
        isUpdateCompleted: true,
      };
    }

    //#endregion UPDATE_BANK_ACCOUNT

    //#region BLOCK_BANK_ACCOUNT

    case BankAccountsActionType.BLOCK_BANK_ACCOUNT: {
      return {
        ...state,
        isBlocking: true,
        isBlockCompleted: false,
        error: null,
      };
    }

    case BankAccountsActionType.BLOCK_BANK_ACCOUNT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isBlocking: false,
        isBlockCompleted: false,
      };
    }

    case BankAccountsActionType.BLOCK_BANK_ACCOUNT_SUCCESS: {
      let bankAccounts = [...state.bankAccounts];
      const bankAccountIndex = findIndex(bankAccounts, (bankAccount) => bankAccount.id === action.payload.data.id);

      /* If bank account was found, update it. */
      if (bankAccountIndex >= 0) {
        bankAccounts[bankAccountIndex] = action.payload.data;
      } else {
        /* else, insert it to the beginning of the array. */
        bankAccounts = [action.payload.data, ...bankAccounts];
      }

      return {
        ...state,
        bankAccounts,
        selectedBankAccount: action.payload.data,
        error: null,
        isBlocking: false,
        isBlockCompleted: true,
      };
    }

    //#endregion BLOCK_BANK_ACCOUNT

    //#region ACTIVATE_BANK_ACCOUNT

    case BankAccountsActionType.ACTIVATE_BANK_ACCOUNT: {
      return {
        ...state,
        isActivating: true,
        isActivateCompleted: false,
        error: null,
      };
    }

    case BankAccountsActionType.ACTIVATE_BANK_ACCOUNT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isActivating: false,
        isActivateCompleted: false,
      };
    }

    case BankAccountsActionType.ACTIVATE_BANK_ACCOUNT_SUCCESS: {
      let bankAccounts = [...state.bankAccounts];
      const bankAccountIndex = findIndex(bankAccounts, (bankAccount) => bankAccount.id === action.payload.data.id);

      /* If bank account was found, update it. */
      if (bankAccountIndex >= 0) {
        bankAccounts[bankAccountIndex] = action.payload.data;
      } else {
        /* else, insert it to the beginning of the array. */
        bankAccounts = [action.payload.data, ...bankAccounts];
      }

      return {
        ...state,
        bankAccounts,
        selectedBankAccount: action.payload.data,
        error: null,
        isActivating: false,
        isActivateCompleted: true,
      };
    }

    //#endregion ACTIVATE_BANK_ACCOUNT

    //#region DELETE_BANK_ACCOUNT

    case BankAccountsActionType.DELETE_BANK_ACCOUNT: {
      return {
        ...state,
        isDeleting: true,
        isDeleteCompleted: false,
        error: null,
      };
    }

    case BankAccountsActionType.DELETE_BANK_ACCOUNT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isDeleting: false,
        isDeleteCompleted: false,
      };
    }

    case BankAccountsActionType.DELETE_BANK_ACCOUNT_SUCCESS: {
      const bankAccounts = [...state.bankAccounts];

      /* Remove the deleted bank account from the array. */
      remove(bankAccounts, (bankAccount) => bankAccount.id === action.payload.data.id);

      return {
        ...state,
        bankAccounts,
        selectedBankAccount: action.payload.data,
        error: null,
        isDeleting: false,
        isDeleteCompleted: true,
      };
    }

    //#endregion DELETE_BANK_ACCOUNT

    //#region CREATE_BANK_ACCOUNT_LOG_MONEY_TRANSFER

    case BankAccountsActionType.CREATE_BANK_ACCOUNT_LOG_MONEY_TRANSFER: {
      return {
        ...state,
        isBankAccountLogMoneyTransferCreating: true,
        isBankAccountLogMoneyTransferCreateCompleted: false,
        error: null,
      };
    }

    case BankAccountsActionType.CREATE_BANK_ACCOUNT_LOG_MONEY_TRANSFER_FAIL: {
      return {
        ...state,
        error: action.payload,
        isBankAccountLogMoneyTransferCreating: false,
        isBankAccountLogMoneyTransferCreateCompleted: false,
      };
    }

    case BankAccountsActionType.CREATE_BANK_ACCOUNT_LOG_MONEY_TRANSFER_SUCCESS: {
      let bankAccountLogsMoneyTransfer = [...state.bankAccountLogsMoneyTransferData];
      const bankAccountLogIndex = findIndex(
        bankAccountLogsMoneyTransfer,
        (bankAccountLog) => bankAccountLog.id === action.payload.data.id
      );

      /* If bank account was found, update it. */

      if (bankAccountLogIndex >= 0) {
        bankAccountLogsMoneyTransfer[bankAccountLogIndex] = action.payload.data;
      } else {
        /* else, insert it to the beginning of the array. */
        bankAccountLogsMoneyTransfer = [...bankAccountLogsMoneyTransfer, action.payload.data];
      }

      return {
        ...state,
        bankAccountLogsMoneyTransferData: bankAccountLogsMoneyTransfer,
        selectedBankAccountLogMoneyTransfer: action.payload.data,
        error: null,
        isBankAccountLogMoneyTransferCreating: false,
        isBankAccountLogMoneyTransferCreateCompleted: true,
      };
    }

    //#endregion CREATE_BANK_ACCOUNT_LOG_MONEY_TRANSFER

    //#region CREATE_BANK_ACCOUNT_LOG_CASH_RECEIPT

    case BankAccountsActionType.CREATE_BANK_ACCOUNT_LOG_CASH_RECEIPT: {
      return {
        ...state,
        isBankAccountLogCashReceiptCreating: true,
        isBankAccountLogCashReceiptCreateCompleted: false,
        error: null,
      };
    }

    case BankAccountsActionType.CREATE_BANK_ACCOUNT_LOG_CASH_RECEIPT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isBankAccountLogCashReceiptCreating: false,
        isBankAccountLogCashReceiptCreateCompleted: false,
      };
    }

    case BankAccountsActionType.CREATE_BANK_ACCOUNT_LOG_CASH_RECEIPT_SUCCESS: {
      let bankAccountLogsCashReceipt = [...state.bankAccountLogsCashReceiptData];
      const bankAccountLogIndex = findIndex(
        bankAccountLogsCashReceipt,
        (bankAccountLog) => bankAccountLog.id === action.payload.data.id
      );

      /* If bank account was found, update it. */

      if (bankAccountLogIndex >= 0) {
        bankAccountLogsCashReceipt[bankAccountLogIndex] = action.payload.data;
      } else {
        /* else, insert it to the beginning of the array. */
        bankAccountLogsCashReceipt = [...bankAccountLogsCashReceipt, action.payload.data];
      }

      return {
        ...state,
        bankAccountLogsCashReceiptData: bankAccountLogsCashReceipt,
        selectedBankAccountLogCashReceipt: action.payload.data,
        error: null,
        isBankAccountLogCashReceiptCreating: false,
        isBankAccountLogCashReceiptCreateCompleted: true,
      };
    }

    //#endregion CREATE_BANK_ACCOUNT_LOG_CASH_RECEIPT

    //#region CREATE_BANK_ACCOUNT_LOG_SETTLEMENT_DEED

    case BankAccountsActionType.CREATE_BANK_ACCOUNT_LOG_SETTLEMENT_DEED: {
      return {
        ...state,
        isBankAccountLogSettlementDeedCreating: true,
        isBankAccountLogSettlementDeedCreateCompleted: false,
        error: null,
      };
    }

    case BankAccountsActionType.CREATE_BANK_ACCOUNT_LOG_SETTLEMENT_DEED_FAIL: {
      return {
        ...state,
        error: action.payload,
        isBankAccountLogSettlementDeedCreating: false,
        isBankAccountLogSettlementDeedCreateCompleted: false,
      };
    }

    case BankAccountsActionType.CREATE_BANK_ACCOUNT_LOG_SETTLEMENT_DEED_SUCCESS: {
      let bankAccountLogsSettlementDeed = [...state.bankAccountLogsSettlementDeedData];
      const bankAccountLogIndex = findIndex(
        bankAccountLogsSettlementDeed,
        (bankAccountLog) => bankAccountLog.id === action.payload.data.id
      );

      /* If bank account was found, update it. */

      if (bankAccountLogIndex >= 0) {
        bankAccountLogsSettlementDeed[bankAccountLogIndex] = action.payload.data;
      } else {
        /* else, insert it to the beginning of the array. */
        bankAccountLogsSettlementDeed = [...bankAccountLogsSettlementDeed, action.payload.data];
      }

      return {
        ...state,
        bankAccountLogsSettlementDeedData: bankAccountLogsSettlementDeed,
        selectedBankAccountLogSettlementDeed: action.payload.data,
        error: null,
        isBankAccountLogSettlementDeedCreating: false,
        isBankAccountLogSettlementDeedCreateCompleted: true,
      };
    }

    //#endregion CREATE_BANK_ACCOUNT_LOG_SETTLEMENT_DEED

    default:
      return state;
  }
}
