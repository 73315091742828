import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';

import { AppHttpResponse } from 'shared';
import { Department } from 'hr/models';

/**
 * The departments services includes functionality to search for an department.
 */
@Injectable()
export class DepartmentsService {
  /**
   * The relative route for the departments.
   *
   * No leading or trailing slashes required.
   */
  private departmentsApi = 'hr/departments';

  constructor(private http: HttpClient) {}

  /**
   * Searches in the departments by name.
   * @param name The name of the department.
   * @param departmentTypes The types of the location.
   * @param departmentCategories The categories of the location.
   * @param page The current pagination page number.
   * @param pageSize The maximum number of departments allowed per one pagination page.
   */
  public search(
    name: string,
    departmentTypes: number[],
    departmentCategories: number[],
    page: number,
    pageSize: number
  ): Observable<AppHttpResponse<Department[]>> {
    const params = new HttpParams()
      .set('name', name)
      .set('departmentTypes', departmentTypes.join(','))
      .set('departmentCategories', departmentCategories.join(','))
      .set('page', page.toString())
      .set('pageSize', pageSize.toString());
    return this.http.get<AppHttpResponse<Department[]>>(`${this.departmentsApi}`, { params });
  }
}
