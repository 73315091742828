<!-- customer info form starts -->
<div class="row">
  <div class="col-md-12">
    <form [formGroup]="form" (ngSubmit)="submit()">
      <!-- customer info starts -->
      <div class="card-header mb-3 pt-0">
        <h4 class="card-title">{{ 'SALES.CUSTOMERS.CUSTOMER_DATA_TITLE' | translate }}</h4>
      </div>
      <div class="card-content">
        <div class="px-3">
          <div class="row">
            <div class="form-group col-md-6">
              <label for="name">{{ 'SALES.CUSTOMERS.CUSTOMER_DATA.CUSTOMER_NAME' | translate }}</label>
              <input
                type="text"
                id="name"
                class="form-control round"
                [placeholder]="'SALES.CUSTOMERS.CUSTOMER_DATA.CUSTOMER_NAME_PLACEHOLDER' | translate"
                maxlength="50"
                formControlName="name"
              />
            </div>
            <div class="form-group col-md-6">
              <label for="nameEn">{{ 'SALES.CUSTOMERS.CUSTOMER_DATA.CUSTOMER_ENGLISH_NAME' | translate }}</label>
              <input
                type="text"
                id="nameEn"
                class="form-control round"
                [placeholder]="'SALES.CUSTOMERS.CUSTOMER_DATA.CUSTOMER_ENGLISH_NAME_PLACEHOLDER' | translate"
                maxlength="50"
                formControlName="nameEn"
              />
            </div>
            <div class="form-group col-md-6">
              <label for="customerCategoryId">{{ 'SALES.CUSTOMERS.CUSTOMER_DATA.CUSTOMER_TYPE' | translate }}</label>
              <ng-select
                class="round"
                formControlName="customerCategoryId"
                labelForId="customerCategoryId"
                [placeholder]="'SALES.CUSTOMERS.CUSTOMER_DATA.CUSTOMER_TYPE_PLACEHOLDER' | translate"
                [notFoundText]="'SHARED.SEARCH.SEARCH_NOT_FOUND' | translate"
              >
                <ng-option *ngFor="let category of customerCategories$ | async" [value]="category.id">
                  {{ category.name | localize: category.nameEn | async }}
                </ng-option>
              </ng-select>
            </div>
            <div class="form-group col-md-6">
              <label for="points">{{ 'SALES.CUSTOMERS.CUSTOMER_DATA.CUSTOMER_POINTS' | translate }}</label>
              <input
                type="text"
                id="points"
                class="form-control round"
                [placeholder]="'SALES.CUSTOMERS.CUSTOMER_DATA.CUSTOMER_POINTS_PLACEHOLDER' | translate"
                [value]="customerObj.points | toNumber"
                readonly
              />
            </div>
            <div class="form-group col-md-6">
              <label for="commercialRegistrationNo">{{
                'SALES.CUSTOMERS.CUSTOMER_DATA.COMMERCIAL_REGISTRY_NUMBER' | translate
              }}</label>
              <input
                type="text"
                id="commercialRegistrationNo"
                class="form-control round"
                [placeholder]="'SALES.CUSTOMERS.CUSTOMER_DATA.COMMERCIAL_REGISTRY_NUMBER_PLACEHOLDER' | translate"
                formControlName="commercialRegistrationNo"
                maxLength="10"
              />
            </div>
            <div class="form-group col-md-6">
              <label for="vatNumber">{{ 'SALES.CUSTOMERS.CUSTOMER_DATA.VAT_NUMBER' | translate }}</label>
              <input
                type="text"
                id="vatNumber"
                class="form-control round"
                [placeholder]="'SALES.CUSTOMERS.CUSTOMER_DATA.VAT_NUMBER_PLACEHOLDER' | translate"
                formControlName="vatNumber"
                maxLength="50"
              />
            </div>
            <div class="form-group col-md-6">
              <label for="select-id">{{ 'SALES.CUSTOMERS.CUSTOMER_DATA.CITY_NAME' | translate }}</label>
              <ng-select
                class="round"
                formControlName="cityId"
                labelForId="select-id"
                searchable="true"
                autocomplete="off"
                [placeholder]="'SALES.CUSTOMERS.CUSTOMER_DATA.CITY_NAME_PLACEHOLDER' | translate"
                [notFoundText]="'SHARED.SEARCH.SEARCH_NOT_FOUND' | translate"
              >
                <ng-option *ngFor="let city of cities$ | async" [value]="city.id">
                  {{ city.name | localize: city.nameEn | async }}
                </ng-option>
              </ng-select>
            </div>
            <div class="form-group col-md-6">
              <label for="locationId">{{ 'SALES.CUSTOMERS.CUSTOMER_DATA.LOCATION' | translate }}</label>
              <ng-select
                class="round"
                formControlName="locationId"
                labelForId="locationId"
                searchable="true"
                [placeholder]="'SALES.CUSTOMERS.CUSTOMER_DATA.LOCATION_PLACEHOLDER' | translate"
                [notFoundText]="'SHARED.SEARCH.SEARCH_NOT_FOUND' | translate"
                (open)="locationsListVisibility = true"
              >
                <ng-option *ngFor="let location of locations" [value]="location?.id">
                  {{ location?.name | localize: location?.nameEn | async }}
                </ng-option>
              </ng-select>
            </div>
            <div
              *ngIf="this.form?.value?.customerCategoryId === customerCategoryOrganizationId"
              class="form-group col-md-6"
            >
              <label for="buildingNo">{{ 'SALES.CUSTOMERS.CUSTOMER_DATA.BUILDING_NUMBER' | translate }}</label>
              <input
                type="text"
                id="buildingNo"
                class="form-control round"
                [placeholder]="'SALES.CUSTOMERS.CUSTOMER_DATA.BUILDING_NUMBER_PLACEHOLDER' | translate"
                maxlength="50"
                formControlName="buildingNo"
              />
            </div>
            <div
              *ngIf="this.form?.value?.customerCategoryId === customerCategoryOrganizationId"
              class="form-group col-md-6"
            >
              <label for="streetName">{{ 'SALES.CUSTOMERS.CUSTOMER_DATA.STREET_NAME' | translate }}</label>
              <input
                type="text"
                id="streetName"
                class="form-control round"
                [placeholder]="'SALES.CUSTOMERS.CUSTOMER_DATA.STREET_NAME_PLACEHOLDER' | translate"
                maxlength="50"
                formControlName="streetName"
              />
            </div>
            <div
              *ngIf="this.form?.value?.customerCategoryId === customerCategoryOrganizationId"
              class="form-group col-md-6"
            >
              <label for="district">{{ 'SALES.CUSTOMERS.CUSTOMER_DATA.DISTRICT' | translate }}</label>
              <input
                type="text"
                id="district"
                class="form-control round"
                [placeholder]="'SALES.CUSTOMERS.CUSTOMER_DATA.DISTRICT_PLACEHOLDER' | translate"
                maxlength="50"
                formControlName="district"
              />
            </div>
            <div
              *ngIf="this.form?.value?.customerCategoryId === customerCategoryOrganizationId"
              class="form-group col-md-6"
            >
              <label for="country">{{ 'SALES.CUSTOMERS.CUSTOMER_DATA.COUNTRY' | translate }}</label>
              <input
                type="text"
                id="country"
                class="form-control round"
                autocomplete="off"
                [placeholder]="'SALES.CUSTOMERS.CUSTOMER_DATA.COUNTRY_PLACEHOLDER' | translate"
                maxlength="50"
                formControlName="country"
              />
            </div>
            <div
              *ngIf="this.form?.value?.customerCategoryId === customerCategoryOrganizationId"
              class="form-group col-md-6"
            >
              <label for="postalCode">{{ 'SALES.CUSTOMERS.CUSTOMER_DATA.POSTAL_CODE' | translate }}</label>
              <input
                type="text"
                id="postalCode"
                class="form-control round"
                [placeholder]="'SALES.CUSTOMERS.CUSTOMER_DATA.POSTAL_CODE_PLACEHOLDER' | translate"
                maxlength="50"
                formControlName="postalCode"
              />
            </div>
            <div
              *ngIf="this.form?.value?.customerCategoryId === customerCategoryOrganizationId"
              class="form-group col-md-6"
            >
              <label for="additionalNo">{{ 'SALES.CUSTOMERS.CUSTOMER_DATA.ADDITIONAL_NUMBER' | translate }}</label>
              <input
                type="text"
                id="additionalNo"
                class="form-control round"
                [placeholder]="'SALES.CUSTOMERS.CUSTOMER_DATA.ADDITIONAL_NUMBER_PLACEHOLDER' | translate"
                maxlength="50"
                formControlName="additionalNo"
              />
            </div>
            <div
              *ngIf="this.form?.value?.customerCategoryId === customerCategoryOrganizationId"
              class="form-group"
              [ngClass]="
                this.form?.value?.customerCategoryId === customerCategoryOrganizationId ? 'col-md-12' : 'col-md-6'
              "
            >
              <label for="otherBuyerId">{{ 'SALES.CUSTOMERS.CUSTOMER_DATA.OTHER_BUYER_ID' | translate }}</label>
              <input
                type="text"
                id="otherBuyerId"
                class="form-control round"
                [placeholder]="'SALES.CUSTOMERS.CUSTOMER_DATA.OTHER_BUYER_ID_PLACEHOLDER' | translate"
                maxlength="50"
                formControlName="otherBuyerId"
              />
            </div>
            <div class="form-group col-md-12">
              <label for="moreInfo">{{ 'SALES.CUSTOMERS.CUSTOMER_DATA.EXTRA_DATA' | translate }}</label>
              <textarea
                id="moreInfo"
                class="form-control round"
                [placeholder]="'SALES.CUSTOMERS.CUSTOMER_DATA.EXTRA_DATA_PLACEHOLDER' | translate"
                maxlength="200"
                formControlName="moreInfo"
              >
              </textarea>
            </div>
          </div>
        </div>
      </div>
      <!-- customer info ends -->

      <!-- representor info starts -->
      <div class="card-header mb-3 pt-1">
        <h4 class="card-title">{{ 'SALES.CUSTOMERS.CUSTOMER_DATA.RESPONSIBLE_DATA' | translate }}</h4>
      </div>
      <div class="card-content">
        <div class="px-3">
          <div class="row">
            <div class="form-group col-md-6">
              <label for="representorName">{{ 'SALES.CUSTOMERS.CUSTOMER_DATA.RESPONSIBLE_NAME' | translate }}</label>
              <input
                type="text"
                id="representorName"
                class="form-control round"
                [placeholder]="'SALES.CUSTOMERS.CUSTOMER_DATA.RESPONSIBLE_NAME_PLACEHOLDER' | translate"
                maxlength="50"
                formControlName="representorName"
              />
            </div>
            <div class="form-group col-md-6">
              <label for="representorMobile">{{
                'SALES.CUSTOMERS.CUSTOMER_DATA.RESPONSIBLE_MOBILE' | translate
              }}</label>
              <input
                type="text"
                [patterns]="phonePattern"
                mask="{{ phoneMask }}"
                id="representorMobile"
                class="form-control round"
                [placeholder]="'SALES.CUSTOMERS.CUSTOMER_DATA.RESPONSIBLE_MOBILE_PLACEHOLDER' | translate"
                formControlName="representorMobile"
              />
            </div>
            <div class="form-group col-md-6">
              <label for="representorPhone">{{ 'SALES.CUSTOMERS.CUSTOMER_DATA.RESPONSIBLE_PHONE' | translate }}</label>
              <input
                type="text"
                [patterns]="phonePattern"
                mask="{{ phoneMask }}"
                id="representorPhone"
                class="form-control round"
                [placeholder]="'SALES.CUSTOMERS.CUSTOMER_DATA.RESPONSIBLE_PHONE_PLACEHOLDER' | translate"
                formControlName="representorPhone"
              />
            </div>
            <div class="form-group col-md-6">
              <label for="representorEmail">{{ 'SALES.CUSTOMERS.CUSTOMER_DATA.RESPONSIBLE_EMAIL' | translate }}</label>
              <input
                type="email"
                id="representorEmail"
                class="form-control round"
                [placeholder]="'SALES.CUSTOMERS.CUSTOMER_DATA.RESPONSIBLE_EMAIL_PLACEHOLDER' | translate"
                formControlName="representorEmail"
              />
            </div>
          </div>

          <div class="row">
            <div class="form-group col-12 d-flex justify-content-between flex-column flex-sm-row mt-2">
              <button type="submit" [disabled]="isUpdating$ | async" class="btn btn-raised btn-success round">
                <i class="fa fa-check"></i>
                <span class="mx-1">{{ 'SHARED.CONFIRM.SAVE' | translate }}</span>
              </button>
              <button class="btn btn-outline-dark round" *ngIf="form.dirty" (click)="discardChanges()">
                <i class="fa fa-undo"></i>
                <span class="mx-1">{{ 'SALES.CUSTOMERS.CUSTOMER_DATA.CANCEL_UPDATES' | translate }}</span>
              </button>
              <button class="btn btn-dark round" routerLink="/sales/customers">
                <i class="fa fa-undo"></i>
                <span class="mx-1">{{ 'SALES.CUSTOMERS.CUSTOMER_DATA.CUSTOMERS_RETURN' | translate }}</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- representor info ends -->
    </form>
  </div>
</div>
<!-- customer info form ends -->

<!-- confirm modal starts -->
<ng-template #confirmModalRef let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
      {{ 'SALES.CUSTOMERS.CONFIRM_UPDATE_PAYMENT_TITLE' | translate }}
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="d()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p class="text-success">{{ 'SALES.CUSTOMERS.CONFIRM_UPDATE_PAYMENT_MESSAGE' | translate }}</p>
  </div>
  <div class="modal-footer">
    <div class="modal-actions">
      <button type="button" class="btn btn-raised btn-success round" (click)="c(); confirm()" ngbAutofocus>
        <i class="fa fa-check"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.SAVE' | translate }}</span>
      </button>
      <button type="button" class="btn btn-outline-dark round" (click)="c()">
        <i class="fa fa-times"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.CANCEL' | translate }}</span>
      </button>
    </div>
  </div>
</ng-template>
<!-- confirm modal ends -->

<!-- locations search starts -->
<app-locations-search
  onlyActive="true"
  closeOnSelect="true"
  (locations)="selectLocation($event)"
  [(visible)]="locationsListVisibility"
>
</app-locations-search>
<!-- locations search ends -->
