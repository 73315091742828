import { OrganizationSetting } from 'settings/models';

/**
 * Represents the organization settings state.
 */
export class OrganizationSettingsState {
  /**
   * The organization settings.
   */
  public organizationSettings: OrganizationSetting;

  /**
   * An error that may resulting during processing some actions.
   */
  public error: any;

  /**
   * Determines if there is a running get organization settings process.
   */
  public isGettingOrganizationSettings: boolean;

  /**
   * Determines if the last get organization settings process has been ended successfully.
   */
  public isGetOrganizationSettingsCompleted: boolean;

  /**
   * Determines if there is a running update organization settings process.
   */
  public isOrganizationSettingsUpdating: boolean;

  /**
   * Determines if the last update organization settings process has been ended successfully.
   */
  public isUpdateOrganizationSettingsCompleted: boolean;
}
