import { createSelector } from '@ngrx/store';

import { selectLookupsState } from 'lookups/store/selectors/feature.selectors';
import { LookupsState, ProjectResourceTypeSourceState } from 'lookups/store/states';

/**
 * Gets the project resource type sources state.
 */
const selectProjectResourceTypeSources = createSelector(
  selectLookupsState,
  (state: LookupsState) => state.projectResourceTypeSources
);

/**
 * Gets the list of loaded project resource type sources.
 */
export const getProjectResourceTypeSources = createSelector(
  selectProjectResourceTypeSources,
  (state: ProjectResourceTypeSourceState) => state.data
);

/**
 * Gets the error that occurred in the state.
 */
export const getProjectResourceTypeSourcesError = createSelector(
  selectProjectResourceTypeSources,
  (state: ProjectResourceTypeSourceState) => state.error
);

/**
 * Determines if there is a running load process.
 */
export const getProjectResourceTypeSourcesIsLoading = createSelector(
  selectProjectResourceTypeSources,
  (state: ProjectResourceTypeSourceState) => state.isLoading
);

/**
 * Determines if the last load process has been ended successfully.
 */
export const getProjectResourceTypeSourcesIsLoaded = createSelector(
  selectProjectResourceTypeSources,
  (state: ProjectResourceTypeSourceState) => state.isLoaded
);
