import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';

import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import * as fromLookupsStore from 'lookups/store';
import * as fromFinancesStore from 'finances/store';
import { GovService, GovServiceRequest } from 'hr/models';
import { NotificationService, PaginationInfo, TranslationService } from 'shared';
import { GovServiceRequestStatus } from 'lookups';

@Component({
  selector: 'gov-service-requests-list',
  templateUrl: './gov-service-requests-list.component.html',
  styles: [],
})
export class GovServiceRequestsListComponent implements OnInit {
  /**
   * Indicates whether there is a search process is running or not.
   */
  isSearching$: Observable<boolean>;

  /**
   * The list of gov service request statuses.
   */
  requestStatuses$: Observable<GovServiceRequestStatus[]>;

  /**
   * The list of gov service requests.
   */
  govServiceRequests$: Observable<GovServiceRequest[]>;

  /**
   * Shows or hide the gov services list.
   */
  govServicesListVisibility = false;

  /**
   * The list of selected gov services.
   */
  govServices: GovService[] = [];

  /**
   * The pagination info.
   */
  paginationInfo$: Observable<PaginationInfo>;

  /**
   * The search form.
   */
  searchForm: FormGroup;

  /**
   * The initial `fromDate` value for search.
   */
  initialFromDate = new Date();

  /** The initial `toDate` value for search.
   * It's the current day of month.
   */
  initialToDate = new Date();

  constructor(
    private modalService: NgbModal,
    private lookupsStore$: Store<fromLookupsStore.LookupsState>,
    private financesStore$: Store<fromFinancesStore.FinancesState>,
    private notificationService: NotificationService,
    private translationService: TranslationService
  ) {}

  ngOnInit(): void {
    this.init();
  }

  /**
   * Initialize component data.
   */
  init() {
    this.initForm();

    /**
     * Load data.
     */
    this.isSearching$ = this.financesStore$.pipe(select(fromFinancesStore.getGovServiceRequestsSearching));

    let isManualSearchTriggeredBeforeForRequestStatuses = false;
    this.requestStatuses$ = this.lookupsStore$.pipe(
      select(fromLookupsStore.getGovServiceRequestStatuses),
      tap((status) => {
        if (!isManualSearchTriggeredBeforeForRequestStatuses && !status.length) {
          isManualSearchTriggeredBeforeForRequestStatuses = true;
          this.lookupsStore$.dispatch(new fromLookupsStore.GetAllGovServiceRequestStatuses());
        }
      })
    );

    let isManualSearchTriggeredBeforeForGovServiceRequests = false;
    this.govServiceRequests$ = this.financesStore$.pipe(
      select(fromFinancesStore.getGovServiceRequests),
      tap((govServiceRequest) => {
        if (!isManualSearchTriggeredBeforeForGovServiceRequests && !govServiceRequest.length) {
          isManualSearchTriggeredBeforeForGovServiceRequests = true;
          this.search();
        }
      })
    );

    this.paginationInfo$ = this.financesStore$.pipe(select(fromFinancesStore.getGovServiceRequestsPaginationInfo));
  }

  /**
   * Initialize form and add validators.
   */
  initForm() {
    this.searchForm = new FormGroup({
      govServices: new FormControl([]),
      statuses: new FormControl([]),
      fromDate: new FormControl({
        year: this.initialFromDate.getFullYear(),
        month: this.initialFromDate.getMonth() + 1,
        day: this.initialFromDate.getDate(),
      }),
      toDate: new FormControl({
        year: this.initialToDate.getFullYear(),
        month: this.initialToDate.getMonth() + 1,
        day: this.initialToDate.getDate(),
      }),
      page: new FormControl(1),
    });
  }

  /**
   * Handles search parameters change.
   */
  search(event?: KeyboardEvent) {
    if (event && event.key === 'Enter') {
      event.preventDefault();
      return;
    }

    this.applyFiltersAndSearch();
  }

  /**
   * Handles pagination page-changed event.
   * @param page The current selected page number.
   */
  pageChanged(page: number) {
    /** Update pagination page. */
    this.searchForm.patchValue({ page });

    this.applyFiltersAndSearch(page);
  }

  /**
   * Enables trackBy feature for *ngFor utility to track elements
   * depending on a comparer to reduce DOM change.
   * If the element is already exist then no HTML will change, only bounded values.
   * @param element The element to determine if it was changed or not.
   */
  trackElements(element: GovServiceRequest) {
    return element ? element.id : undefined;
  }

  /**
   * Applies the search filters and dispatches a search action.
   * @param page The current page number, default to `1`.
   */
  applyFiltersAndSearch(page: number = 1) {
    if (!this.searchForm.get('fromDate')?.value || !this.searchForm.get('toDate')?.value) {
      return this.notificationService.warning(
        this.translationService.translate('HR.GOV_SERVICE_REQUESTS.NOTIFICATION_MESSAGE.DATE_SELECT')
      );
    }

    const { govServices, statuses, fromDate, toDate } = this.searchForm.value;

    const fromDateFormatted = new Date(fromDate.year, fromDate.month - 1, fromDate.day);
    fromDateFormatted.setHours(0, 0, 0, 0);

    const toDateFormatted = new Date(toDate.year, toDate.month - 1, toDate.day);
    toDateFormatted.setHours(23, 59, 59, 0);

    this.financesStore$.dispatch(
      new fromFinancesStore.SearchGovServiceRequests({
        govServices,
        statuses,
        fromDate: fromDateFormatted,
        toDate: toDateFormatted,
        page,
      })
    );
  }

  /**
   * Adds the newly selected gov services the gov services search list.
   * @param govServices The list of newly selected govServices to be added.
   */
  selectGovServices(govServices: GovService[]) {
    const selectedIds: number[] = this.searchForm.get('govServices').value;
    this.govServices = [
      ...this.govServices.filter((govService) => selectedIds.includes(govService.id)),
      ...govServices,
    ];
    this.searchForm.patchValue({ govServices: this.govServices.map((govService) => govService.id) });
  }
}
