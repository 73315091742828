import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { Location as AngularLocation } from '@angular/common';

import { Subscription, Observable, BehaviorSubject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import * as fromStoresStore from 'stores/store';
import * as fromSettingsStore from 'settings/store';
import {
  PageInfo,
  NotificationService,
  NotificationMessage,
  CustomValidators,
  TranslationService,
  APP_CONSTANTS,
} from 'shared';
import { CanComponentDeactivate } from 'auth/models';
import { Tax } from 'settings/models';
import {
  ProductClass,
  UnitOfMeasure,
  SubProductsFormItem,
  SubProductInput,
  ProductIngredientsFormItem,
  ProductIngredientInput,
  LocationFormItem,
  CreateProductSalesDiscountInput,
  ProductSalesDiscountItem,
  CreateProductUnitOfMeasureRateInput,
  ProductUnitOfMeasureRateItem,
} from 'stores/models';

/**
 * The product component pages.
 */
enum PAGES {
  'product-details' = 'product-details',
  'unit-of-measure-rates' = 'unit-of-measure-rates',
  'other-details' = 'other-details',
  'sales-discounts' = 'sales-discounts',
}

@Component({
  selector: 'app-create-product',
  templateUrl: './create-product.component.html',
  styles: [],
})
export class CreateProductComponent implements OnInit, OnDestroy, CanComponentDeactivate {
  /**
   * The confirm modal template reference.
   */
  @ViewChild('confirmModalRef') confirmModalRef: ElementRef<any>;

  /**
   * The decimal mask.
   */
  readonly DECIMAL_MASK = APP_CONSTANTS.numeric.decimal.mask;

  /**
   * Gets or sets the information about the current page.
   */
  pageInfo: PageInfo = {
    title: 'STORES.PRODUCTS.ADD_PRODUCT_PAGE_TITLE',
    icon: 'fa fa-plus',
  };

  /**
   * Shows or hide the product classes list.
   */
  productClassesListVisibility = false;

  /**
   * Shows or hide the product unit of measure list.
   */
  productUnitsOfMeasureListVisibility = false;

  /**
   * The list of active product classes.
   */
  productClasses: ProductClass[] = [];

  /**
   * The list of active product unit of measure.
   */
  productUnitsOfMeasure: UnitOfMeasure[] = [];

  /**
   * The list of taxes.
   */
  taxes$: Observable<Tax[]>;

  /**
   * The list of active locations.
   */
  locations: Location[] = [];

  /**
   * Indicates whether there is a create-product process is running or not.
   */
  isCreating$: Observable<boolean>;

  /**
   * The create product form.
   */
  form: FormGroup;

  /**
   * The set of subscriptions on this components,
   * these subscriptions must be unsubscribed before this component got destroyed.
   */
  subscriptions = new Subscription();

  /**
   * Gets or sets the selected page.
   * @default 'product-details'
   */
  activePage: PAGES = PAGES['product-details'];

  /**
   * Sets the initial product sales discount lines should be added to the product sales discounts form.
   * @param productSalesDiscount lines An object contains `count` of initial blank product sales discount lines.
   * @param productSalesDiscount lines A single product sales discount line details to be added to the form.
   * @param productSalesDiscount lines A list of product sales discount lines details to be added to the form.
   */
  initialProductSalesDiscountLinesSubject: BehaviorSubject<
    { count: number } | ProductSalesDiscountItem | ProductSalesDiscountItem[]
  > = new BehaviorSubject(undefined);

  /**
   * Sets the initial product unit of measure rate lines should be added to the product unit of measure rates form.
   * @param productUnitOfMeasureRate lines An object contains `count` of initial blank product unit of measure rate lines.
   * @param productUnitOfMeasureRate lines A single product unit of measure rate line details to be added to the form.
   * @param productUnitOfMeasureRate lines A list of product unit of measure rate lines details to be added to the form.
   */
  initialProductUnitOfMeasureRateLinesSubject: BehaviorSubject<
    { count: number } | ProductUnitOfMeasureRateItem | ProductUnitOfMeasureRateItem[]
  > = new BehaviorSubject(undefined);

  /**
   * Gets the product variants form-array.
   */
  get subProductsForm(): FormArray {
    return this.form?.controls.subProducts as FormArray;
  }

  /**
   * Gets the product variants form-array.
   */
  get ingredientsForm(): FormArray {
    return this.form?.controls.ingredients as FormArray;
  }

  /**
   * Gets the locations form-array.
   */
  get locationsForm(): FormArray {
    return this.form?.controls.locations as FormArray;
  }

  /**
   * Gets the product sales discount form-array.
   */
  get productSalesDiscountsForm(): FormArray {
    return this.form?.controls.productSalesDiscounts as FormArray;
  }

  /**
   * Gets the product unit of measure rate form-array.
   */
  get productUnitOfMeasureRatesForm(): FormArray {
    return this.form?.controls.productUnitOfMeasureRates as FormArray;
  }

  /**
   * @param modalService The modal service.
   * @param notificationService The notification service.
   * @param translationService The translation service.
   * @param settingsStore$ the  settings-store module.
   * @param storesStore$ The stores-store module.
   * @param locationService The location service.
   */
  constructor(
    private modalService: NgbModal,
    private notificationService: NotificationService,
    private translationService: TranslationService,
    private settingsStore$: Store<fromSettingsStore.SettingsState>,
    private storesStore$: Store<fromStoresStore.StoresState>,
    private locationService: AngularLocation
  ) {}

  ngOnInit(): void {
    this.init();
  }

  /**
   * Destroy component data
   */
  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  /**
   * Initialize component data.
   */
  init() {
    this.initForm();

    /**
     * Load data.
     */
    this.isCreating$ = this.storesStore$.pipe(select(fromStoresStore.getSelectedProductCreating));

    let isManualSearchTriggeredBeforeForTaxes = false;
    this.taxes$ = this.settingsStore$.pipe(
      select(fromSettingsStore.getTaxes),
      tap((taxes) => {
        if (!isManualSearchTriggeredBeforeForTaxes && !taxes.length) {
          isManualSearchTriggeredBeforeForTaxes = true;
          this.settingsStore$.dispatch(new fromSettingsStore.SearchTaxes({ name: '', page: 1 }));
        }
      })
    );
  }

  /**
   * Initialize form and add validators.
   */
  initForm() {
    const hasIngredientsFormControl = new FormControl(false);
    const hasSubProductsFormControl = new FormControl(false);
    const subProductHasIngredientsFormControl = new FormControl(false);
    const subProductsForm = new FormArray([], CustomValidators.arrayItems(1));
    const ingredientsForm = new FormArray([], CustomValidators.arrayItems(1));

    this.form = new FormGroup({
      description: new FormControl('', [Validators.required, Validators.minLength(1), Validators.maxLength(100)]),
      descriptionEn: new FormControl('', [Validators.required, Validators.minLength(1), Validators.maxLength(100)]),
      barcode: new FormControl('', [Validators.minLength(0), Validators.maxLength(50)]),
      itemCode: new FormControl('', [Validators.required, Validators.minLength(0), Validators.maxLength(50)]),
      purchasePrice: new FormControl(0, CustomValidators.gte(0)),
      salePrice: new FormControl(0, CustomValidators.gte(0)),
      tax: new FormControl(null, [Validators.required, CustomValidators.range([0, 100])]),
      hasIngredients: hasIngredientsFormControl,
      hasSubProducts: hasSubProductsFormControl,
      subProductHasIngredients: subProductHasIngredientsFormControl,
      showProductInSalesScreen: new FormControl(false),
      subProducts: subProductsForm,
      ingredients: ingredientsForm,
      hasExpireDate: new FormControl(false),
      isService: new FormControl(false),
      hasSerialNumber: new FormControl(false),
      productClassId: new FormControl(null, Validators.required),
      unitOfMeasureId: new FormControl(null, Validators.required),
      locations: new FormArray([], CustomValidators.arrayItems(1)),
      productSalesDiscounts: new FormArray([]),
      productUnitOfMeasureRates: new FormArray([]),
      photo: new FormControl(null),
    });

    /**
     * Enable or disable the values that depends on has ingredients when value changes.
     * Enable or disable the values that depends on has sub products when value changes.
     * Enable or disable the values that depends on has sub product ingredients when value changes.
     */
    this.subscriptions.add(hasIngredientsFormControl.valueChanges.subscribe(() => this.onHasIngredientsChange()));
    this.subscriptions.add(hasSubProductsFormControl.valueChanges.subscribe(() => this.onHasSubProductsChange()));
    this.subscriptions.add(
      subProductHasIngredientsFormControl.valueChanges.subscribe(() => this.onHasSubProductIngredientsChange())
    );

    /**
     * Initialize on has ingredients change.
     * Initialize on has sub products change.
     */
    this.onHasIngredientsChange();
    this.onHasSubProductsChange();
    this.setBlankProductSalesDiscountsCount();
  }

  /**
   * Submits the form.
   */
  submit() {
    const errorMessage = new NotificationMessage();

    if (this.form.invalid) {
      if (this.form.get('description').invalid) {
        errorMessage.title = this.translationService.translate(
          'STORES.PRODUCTS.PRODUCT_DATA_VALIDATION.ARABIC_PRODUCT_DESCRIPTION_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate('STORES.PRODUCTS.PRODUCT_DATA_VALIDATION.PRODUCT_DESCRIPTION_IS_REQUIRED'),
          this.translationService.translate('STORES.PRODUCTS.PRODUCT_DATA_VALIDATION.PRODUCT_DESCRIPTION_LENGTH_ERROR'),
        ];
        this.activePage = PAGES['product-details'];
      } else if (this.form.get('descriptionEn').invalid) {
        errorMessage.title = this.translationService.translate(
          'STORES.PRODUCTS.PRODUCT_DATA_VALIDATION.ENGLISH_PRODUCT_DESCRIPTION_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate('STORES.PRODUCTS.PRODUCT_DATA_VALIDATION.PRODUCT_DESCRIPTION_IS_REQUIRED'),
          this.translationService.translate('STORES.PRODUCTS.PRODUCT_DATA_VALIDATION.PRODUCT_DESCRIPTION_LENGTH_ERROR'),
        ];
        this.activePage = PAGES['product-details'];
      } else if (this.form.get('barcode').invalid) {
        errorMessage.title = this.translationService.translate(
          'STORES.PRODUCTS.PRODUCT_DATA_VALIDATION.PRODUCT_BARCODE_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate('STORES.PRODUCTS.PRODUCT_DATA_VALIDATION.PRODUCT_BARCODE_LENGTH_ERROR'),
        ];
        this.activePage = PAGES['product-details'];
      } else if (this.form.get('itemCode').invalid) {
        errorMessage.title = this.translationService.translate(
          'STORES.PRODUCTS.PRODUCT_DATA_VALIDATION.PRODUCT_ITEM_CODE_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate('STORES.PRODUCTS.PRODUCT_DATA_VALIDATION.PRODUCT_ITEM_CODE_IS_REQUIRED'),
          this.translationService.translate('STORES.PRODUCTS.PRODUCT_DATA_VALIDATION.PRODUCT_ITEM_CODE_LENGTH_ERROR'),
        ];
        this.activePage = PAGES['product-details'];
      } else if (this.form.get('productClassId').invalid) {
        errorMessage.title = this.translationService.translate(
          'STORES.PRODUCTS.PRODUCT_DATA_VALIDATION.PRODUCT_CLASS_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate('STORES.PRODUCTS.PRODUCT_DATA_VALIDATION.PRODUCT_CLASS_IS_REQUIRED'),
        ];
        this.activePage = PAGES['product-details'];
      } else if (this.form.get('tax').invalid) {
        errorMessage.title = this.translationService.translate(
          'STORES.PRODUCTS.PRODUCT_DATA_VALIDATION.PRODUCT_TAX_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate('STORES.PRODUCTS.PRODUCT_DATA_VALIDATION.PRODUCT_TAX_IS_REQUIRED'),
          this.translationService.translate('STORES.PRODUCTS.PRODUCT_DATA_VALIDATION.PRODUCT_TAX_LENGTH_ERROR'),
        ];
        this.activePage = PAGES['product-details'];
      } else if (this.form.get('unitOfMeasureId').invalid) {
        errorMessage.title = this.translationService.translate(
          'STORES.PRODUCTS.PRODUCT_DATA_VALIDATION.UNIT_OF_MEASURE_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate('STORES.PRODUCTS.PRODUCT_DATA_VALIDATION.UNIT_OF_MEASURE_IS_REQUIRED'),
        ];
        this.activePage = PAGES['product-details'];
      } else if (this.form.get('purchasePrice').invalid) {
        errorMessage.title = this.translationService.translate(
          'STORES.PRODUCTS.PRODUCT_DATA_VALIDATION.PRODUCT_PURCHASE_PRICE_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate(
            'STORES.PRODUCTS.PRODUCT_DATA_VALIDATION.PRODUCT_PURCHASE_PRICE_IS_REQUIRED'
          ),
          this.translationService.translate(
            'STORES.PRODUCTS.PRODUCT_DATA_VALIDATION.PRODUCT_PURCHASE_PRICE_LENGTH_ERROR'
          ),
        ];
        this.activePage = PAGES['product-details'];
      } else if (this.form.get('salePrice').invalid) {
        errorMessage.title = this.translationService.translate(
          'STORES.PRODUCTS.PRODUCT_DATA_VALIDATION.PRODUCT_SALE_PRICE_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate('STORES.PRODUCTS.PRODUCT_DATA_VALIDATION.PRODUCT_SALE_PRICE_LENGTH_ERROR'),
        ];
        this.activePage = PAGES['product-details'];
      } else if (this.form.get('subProducts').invalid) {
        /**
         * Check if subProducts count = 0.
         */
        if (!this.subProductsForm.controls.length) {
          errorMessage.title = this.translationService.translate(
            'STORES.PRODUCTS.PRODUCT_DATA_VALIDATION.SUB_PRODUCTS_ERROR'
          );
          errorMessage.body = [
            this.translationService.translate('STORES.PRODUCTS.PRODUCT_DATA_VALIDATION.SUB_PRODUCTS_COUNT_ERROR'),
          ];
        } else {
          /**
           * Check if some of subProduct has errors.
           */
          for (let index = 0; index < this.subProductsForm.controls.length; index++) {
            const subProductFormItem = this.subProductsForm.controls[index] as FormArray;

            if (subProductFormItem.valid) {
              continue;
            }

            errorMessage.title = this.translationService.translate(
              'STORES.PRODUCTS.PRODUCT_DATA_VALIDATION.SUB_PRODUCT_NUMBER',
              {
                productVariant: index + 1,
              }
            );
            errorMessage.body = [];

            if (subProductFormItem.get('subProductVariants').invalid) {
              errorMessage.body.push(
                this.translationService.translate(
                  'STORES.PRODUCTS.PRODUCT_DATA_VALIDATION.PRODUCT_VARIANTS_IS_REQUIRED'
                )
              );
            }

            if (subProductFormItem.get('purchasePrice').invalid) {
              errorMessage.body.push(
                this.translationService.translate(
                  'STORES.PRODUCTS.PRODUCT_DATA_VALIDATION.PRODUCT_PURCHASE_PRICE_LENGTH_ERROR'
                )
              );
            }

            if (subProductFormItem.get('salePrice').invalid) {
              errorMessage.body.push(
                this.translationService.translate(
                  'STORES.PRODUCTS.PRODUCT_DATA_VALIDATION.PRODUCT_SALE_PRICE_LENGTH_ERROR'
                )
              );
            }

            if (subProductFormItem.get('ingredients').invalid) {
              errorMessage.body.push(
                this.translationService.translate('STORES.PRODUCTS.PRODUCT_DATA_VALIDATION.PRODUCT_INGREDIENT_ERROR')
              );

              /**
               * Check if some of product ingredient has errors.
               */
              const key = 'controls';
              const ingredients = subProductFormItem.get('ingredients')[key];
              for (let ingredientsIndex = 0; ingredientsIndex < ingredients.length; ingredientsIndex++) {
                const productIngredient = ingredients[ingredientsIndex];

                if (productIngredient.valid) {
                  continue;
                }

                errorMessage.title = this.translationService.translate(
                  'STORES.PRODUCTS.PRODUCT_DATA_VALIDATION.PRODUCT_INGREDIENT',
                  { productIngredient: ingredientsIndex + 1 }
                );
                errorMessage.body = [];

                if (productIngredient.get('ingredientId').invalid) {
                  errorMessage.body.push(
                    this.translationService.translate(
                      'STORES.PRODUCTS.PRODUCT_DATA_VALIDATION.INGREDIENT_PRODUCT_ERROR'
                    ),
                    this.translationService.translate(
                      'STORES.PRODUCTS.PRODUCT_DATA_VALIDATION.INGREDIENT_PRODUCT_IS_REQUIRED'
                    )
                  );
                }

                if (productIngredient.get('quantity').invalid) {
                  errorMessage.body.push(
                    this.translationService.translate('STORES.PRODUCTS.PRODUCT_DATA_VALIDATION.QUANTITY_IS_REQUIRED')
                  );
                }

                break;
              }
            }
            this.activePage = PAGES['product-details'];

            break;
          }
        }
        this.activePage = PAGES['product-details'];
      } else if (this.form.get('ingredients').invalid) {
        /**
         * Check if ingredients count = 0.
         */
        if (!this.ingredientsForm.controls.length) {
          errorMessage.title = this.translationService.translate(
            'STORES.PRODUCTS.PRODUCT_DATA_VALIDATION.PRODUCT_INGREDIENT_ERROR'
          );
          errorMessage.body = [
            this.translationService.translate(
              'STORES.PRODUCTS.PRODUCT_DATA_VALIDATION.PRODUCT_INGREDIENTS_COUNT_ERROR'
            ),
          ];
        } else {
          /**
           * Check if some of ingredients has errors.
           */
          for (let index = 0; index < this.ingredientsForm.controls.length; index++) {
            const ingredientsFormItem = this.ingredientsForm.controls[index] as FormArray;

            if (ingredientsFormItem.valid) {
              continue;
            }

            errorMessage.title = this.translationService.translate(
              'STORES.PRODUCTS.PRODUCT_DATA_VALIDATION.PRODUCT_INGREDIENT',
              {
                productIngredient: index + 1,
              }
            );
            errorMessage.body = [];

            if (ingredientsFormItem.get('ingredientId').invalid) {
              errorMessage.body.push(
                this.translationService.translate('STORES.PRODUCTS.PRODUCT_DATA_VALIDATION.INGREDIENT_PRODUCT_ERROR'),
                this.translationService.translate(
                  'STORES.PRODUCTS.PRODUCT_DATA_VALIDATION.INGREDIENT_PRODUCT_IS_REQUIRED'
                )
              );
            }
            if (ingredientsFormItem.get('quantity').invalid) {
              errorMessage.body.push(
                this.translationService.translate('STORES.PRODUCTS.PRODUCT_DATA_VALIDATION.QUANTITY_IS_REQUIRED')
              );
            }
          }
        }
      } else if (this.form.get('locations').invalid) {
        /**
         * Check if lines count = 0.
         */
        if (!this.locationsForm.controls.length) {
          errorMessage.title = this.translationService.translate(
            'STORES.PRODUCTS.PRODUCT_DATA_VALIDATION.LOCATIONS_ERROR'
          );
          errorMessage.body = [
            this.translationService.translate('STORES.PRODUCTS.PRODUCT_DATA_VALIDATION.LOCATIONS_LENGTH_ERROR'),
          ];
        } else {
          /**
           * Check if some of locations has errors.
           */
          for (let index = 0; index < this.locationsForm.controls.length; index++) {
            const location = this.locationsForm.controls[index];

            if (location.valid) {
              continue;
            }

            errorMessage.title = this.translationService.translate(
              'STORES.PRODUCTS.PRODUCT_DATA_VALIDATION.LOCATIONS_NUMBER_ERROR',
              {
                locationNumber: index + 1,
              }
            );
            errorMessage.body = [];

            if (location.get('locationId').invalid) {
              errorMessage.body.push(
                this.translationService.translate('STORES.PRODUCTS.PRODUCT_DATA_VALIDATION.LOCATIONS_IS_REQUIRED')
              );
            }
          }
          this.activePage = PAGES['sales-discounts'];
        }
      } else if (this.form.get('productSalesDiscounts').invalid) {
        /**
         * Check if lines count > 0.
         */
        if (this.productSalesDiscountsForm.controls.length > 0) {
          /**
           * Check if some of product sales discounts has errors.
           */
          for (let index = 0; index < this.productSalesDiscountsForm.controls.length; index++) {
            const productSalesDiscount = this.productSalesDiscountsForm.controls[index];

            if (productSalesDiscount.valid) {
              continue;
            }

            errorMessage.title = this.translationService.translate(
              'STORES.PRODUCTS.PRODUCT_DATA_VALIDATION.DISCOUNTS_NUMBER_ERROR',
              {
                productSalesDiscountNumber: index + 1,
              }
            );
            errorMessage.body = [];

            if (productSalesDiscount.get('locationId').invalid) {
              errorMessage.body.push(
                this.translationService.translate('STORES.PRODUCTS.PRODUCT_DATA_VALIDATION.LOCATIONS_IS_REQUIRED')
              );
            }

            if (productSalesDiscount.get('discount').invalid) {
              errorMessage.body.push(
                this.translationService.translate('STORES.PRODUCTS.PRODUCT_DATA_VALIDATION.DISCOUNT_LENGTH_ERROR')
              );
            }

            if (productSalesDiscount.get('discountPercent').invalid) {
              errorMessage.body.push(
                this.translationService.translate(
                  'STORES.PRODUCTS.PRODUCT_DATA_VALIDATION.PRODUCT_DISCOUNT_PERCENT_ERROR'
                )
              );
            }
          }
          this.activePage = PAGES['sales-discounts'];
        }
      } else if (this.form.get('productUnitOfMeasureRates').invalid) {
        /**
         * Check if lines count > 0.
         */
        if (this.productUnitOfMeasureRatesForm.controls.length > 0) {
          /**
           * Check if some of product unit of measure rates has errors.
           */
          for (let index = 0; index < this.productUnitOfMeasureRatesForm.controls.length; index++) {
            const productUnitOfMeasureRate = this.productUnitOfMeasureRatesForm.controls[index];

            if (productUnitOfMeasureRate.valid) {
              continue;
            }

            errorMessage.title = this.translationService.translate(
              'STORES.PRODUCTS.PRODUCT_DATA_VALIDATION.UNIT_OF_MEASURE_RATE_NUMBER_ERROR',
              {
                productUnitOfMeasureRateNumber: index + 1,
              }
            );
            errorMessage.body = [];

            if (productUnitOfMeasureRate.get('unitOfMeasureId').invalid) {
              errorMessage.body.push(
                this.translationService.translate(
                  'STORES.PRODUCTS.PRODUCT_DATA_VALIDATION.UNIT_OF_MEASURE_RATE_IS_REQUIRED'
                )
              );
            }

            if (productUnitOfMeasureRate.get('rate').invalid) {
              errorMessage.body.push(
                this.translationService.translate('STORES.PRODUCTS.PRODUCT_DATA_VALIDATION.RATE_IS_REQUIRED')
              );
            }
          }
          this.activePage = PAGES['unit-of-measure-rates'];
        }
      }
      this.form.markAllAsTouched();
      return this.notificationService.warningWithTitle(errorMessage);
    } else {
      this.openModal(this.confirmModalRef);
    }
  }

  /**
   * Confirms the form submit.
   */
  confirm() {
    /**
     * The list of sub products in the lines form.
     */
    const subProductsItems: SubProductsFormItem[] = this.form.value.hasSubProducts ? this.form.value.subProducts : [];
    const subProducts: SubProductInput[] = subProductsItems.map((item) => {
      const subProductIngredientsItems: ProductIngredientsFormItem[] = this.form.value.subProductHasIngredients
        ? item.subProductIngredients
        : [];

      const subProductIngredients: ProductIngredientInput[] = subProductIngredientsItems.map((ingredientItem) => {
        const ingredientInput: ProductIngredientInput = {
          ingredientId: ingredientItem.ingredientId,
          quantity: ingredientItem.quantity,
        };

        return ingredientInput;
      });

      const subProduct: SubProductInput = {
        subProductVariants: item.subProductVariants,
        purchasePrice: item.purchasePrice,
        salePrice: item.salePrice,
        subProductIngredients,
      };

      return subProduct;
    });

    /**
     * The list of product ingredients in the lines form.
     */

    const productIngredientsItems: ProductIngredientsFormItem[] = this.form.value.hasIngredients
      ? this.form.value.ingredients
      : [];

    const productIngredients: ProductIngredientInput[] = productIngredientsItems.map((ingredientItem) => {
      const ingredientInput: ProductIngredientInput = {
        ingredientId: ingredientItem.ingredientId,
        quantity: ingredientItem.quantity,
      };

      return ingredientInput;
    });

    /**
     * The list of locations in the locations form.
     */

    const locations = this.locationsForm.value.map((item: LocationFormItem) => item.locationId);

    /**
     * The list of product sales discounts in the lines form.
     */
    const productSalesDiscounts = this.productSalesDiscountsForm.value.map((item: ProductSalesDiscountItem) => {
      const productSalesDiscount: CreateProductSalesDiscountInput = {
        locationId: item.locationId,
        discount: item.discount,
        discountPercent: item.discountPercent,
      };

      return productSalesDiscount;
    });

    /**
     * The list of product unit of measure rates in the lines form.
     */
    const productUnitOfMeasureRates = this.productUnitOfMeasureRatesForm.value.map(
      (item: ProductUnitOfMeasureRateItem) => {
        const productUnitOfMeasureRate: CreateProductUnitOfMeasureRateInput = {
          unitOfMeasureId: item.unitOfMeasureId,
          rate: item.rate,
        };

        return productUnitOfMeasureRate;
      }
    );

    this.storesStore$.dispatch(
      new fromStoresStore.CreateProduct({
        ...this.form.value,

        isService:
          this.form.value.hasIngredients || this.form.value.subProductHasIngredients ? true : this.form.value.isService,
        photo: this.form.value.photo ?? null,
        purchasePrice: this.form.value.purchasePrice ?? 0,
        salePrice: this.form.value.salePrice ?? 0,
        hasIngredients: this.form.value.hasIngredients ?? false,
        productIngredients,
        subProducts,
        locations,
        productSalesDiscounts,
        productUnitOfMeasureRates,
      })
    );
  }

  /**
   * Opens the modal of the given templateRef.
   * @param modalRef The modal templateRef to be opened.
   */
  openModal(modalRef) {
    this.modalService.open(modalRef);
  }

  /**
   * Selects the newly selected product class from the product classes search list.
   * @param productClasses The list of newly selected product classes to select the only one in the list.
   */
  selectProductClass([productClass]: ProductClass[]) {
    if (productClass) {
      this.productClasses = [productClass];
      this.form.patchValue({ productClassId: productClass.id });
    }
  }

  /**
   * Selects the newly selected product unit of measure from the product unit of measures search list.
   * @param productUnitsOfMeasure The list of newly selected unit of measures to select the only one in the list.
   */
  selectProductUnitsOfMeasure([productUnitOfMeasure]: UnitOfMeasure[]) {
    if (productUnitOfMeasure) {
      this.productUnitsOfMeasure = [productUnitOfMeasure];
      this.form.patchValue({ unitOfMeasureId: productUnitOfMeasure.id });
    }
  }

  /**
   * Handles the `hasIngredients` change event.
   */
  onHasIngredientsChange() {
    if (this.form.controls.hasIngredients.value) {
      this.ingredientsForm.setValidators([Validators.required, CustomValidators.arrayItems(1)]);
      this.form.controls.isService.setValue(true);
      this.form.controls.isService.disable();
    } else {
      this.ingredientsForm.clearValidators();
      this.form.controls.isService.setValue(false);
      this.form.controls.isService.enable();
    }

    this.form.controls.isService.updateValueAndValidity();
    this.ingredientsForm.clear();
    this.ingredientsForm.updateValueAndValidity();
  }

  /**
   * Handles the `hasSubProducts` change event.
   */
  onHasSubProductsChange() {
    if (this.form.controls.hasSubProducts.value) {
      this.subProductsForm.setValidators([Validators.required, CustomValidators.arrayItems(1)]);
      this.form.controls.hasIngredients.setValue(false);
      this.form.controls.hasIngredients.disable();
    } else {
      this.subProductsForm.clearValidators();
      this.form.controls.subProductHasIngredients.setValue(false);
      this.form.controls.hasIngredients.enable();
      this.onHasSubProductIngredientsChange();
    }

    this.subProductsForm.clear();
    this.subProductsForm.updateValueAndValidity();
  }

  /**
   * Handles the `subProductsHasIngredients` change event.
   */
  onHasSubProductIngredientsChange() {
    if (this.form.controls.subProductHasIngredients.value) {
      this.form.controls.isService.setValue(true);
      this.form.controls.isService.disable();
    } else {
      this.form.controls.isService.setValue(false);
      this.form.controls.isService.enable();
    }
    this.form.controls.isService.updateValueAndValidity();
  }

  /**
   * Sets the count of the product sales discount lines should be added to the product sales discounts form for quick start..
   */
  setBlankProductSalesDiscountsCount() {
    this.initialProductSalesDiscountLinesSubject.next({ count: 0 });
  }

  /**
   * Sets the count of the product unit of measure rate lines should be added to the product unit of measure rates form for quick start..
   */
  setBlankProductUnitOfMeasureRatesCount() {
    this.initialProductUnitOfMeasureRateLinesSubject.next({ count: 0 });
  }

  /**
   * Confirms leaving the page before routing..
   */
  confirmDeactivate() {
    if (this.form.dirty === true) {
      /**
       * Message to warn the user before leaving the detailed form.
       */
      return confirm(this.translationService.translate('SHARED.CONFIRM.LEAVING'));
    }
    return true;
  }

  /**
   * Updates the browser url according to the selected page.
   * @param page The newly selected page.
   */
  selectedPageChanged(page: PAGES) {
    this.locationService.replaceState(`${this.locationService.path()}#${PAGES[page]}`);
  }
}
