import { createSelector } from '@ngrx/store';

import { selectLookupsState } from 'lookups/store/selectors/feature.selectors';
import { LookupsState, PurchaseOrderTypeState } from 'lookups/store/states';

/**
 * Gets the purchaseOrderType state.
 */
const selectPurchaseOrderTypes = createSelector(selectLookupsState, (state: LookupsState) => state.purchaseOrderTypes);

/**
 * Gets the list of loaded purchaseOrderTypes.
 */
export const getPurchaseOrderTypes = createSelector(
  selectPurchaseOrderTypes,
  (state: PurchaseOrderTypeState) => state.data
);

/**
 * Gets the error that occurred in the state.
 */
export const getPurchaseOrderTypesError = createSelector(
  selectPurchaseOrderTypes,
  (state: PurchaseOrderTypeState) => state.error
);

/**
 * Determines if there is a running load process.
 */
export const getPurchaseOrderTypesIsLoading = createSelector(
  selectPurchaseOrderTypes,
  (state: PurchaseOrderTypeState) => state.isLoading
);

/**
 * Determines if the last load process has been ended successfully.
 */
export const getPurchaseOrderTypesIsLoaded = createSelector(
  selectPurchaseOrderTypes,
  (state: PurchaseOrderTypeState) => state.isLoaded
);
