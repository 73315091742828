import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanDeactivate } from '@angular/router';

import { Observable } from 'rxjs';

import { CanComponentDeactivate } from 'auth/models';

/**
 * Confirmation guard that makes sure that the user is need to navigate.
 */
@Injectable()
export class ConfirmationGuard implements CanDeactivate<CanComponentDeactivate> {
  canDeactivate(
    component: CanComponentDeactivate,
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
    nextState?: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    /**
     * the function will be called on the component, that's why we'll implement the function on the component.
     */
    return component.confirmDeactivate();
  }
}
