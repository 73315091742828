import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';

import { AppHttpResponse } from 'shared';
import { CreateCurrencyInput, Currency, UpdateCurrencyInput } from 'finances/models';

/**
 * The currencies services includes functionality to create, search, findById, findPrimaryCurrency, update and delete for a currency.
 */
@Injectable()
export class CurrenciesService {
  /**
   * The relative route for the currencies.
   *
   * No leading or trailing slashes required.
   */
  private currenciesApi = 'finances/currencies';

  constructor(private http: HttpClient) {}

  /**
   * Creates a new currency from the provided data.
   * @param data The new currency data.
   */
  public create(data: CreateCurrencyInput): Observable<AppHttpResponse<Currency>> {
    return this.http.post<AppHttpResponse<Currency>>(`${this.currenciesApi}`, data);
  }

  /**
   * Searches in the currencies by name.
   * @param name The name of the currency.
   * @param page The current pagination page number.
   * @param pageSize The maximum number of currencies allowed per one pagination page.
   */
  public search(name: string, page: number, pageSize: number): Observable<AppHttpResponse<Currency[]>> {
    const params = new HttpParams()
      .set('name', name)
      .set('page', page.toString())
      .set('pageSize', pageSize.toString());
    return this.http.get<AppHttpResponse<Currency[]>>(`${this.currenciesApi}`, { params });
  }

  /**
   * Finds the currency with the given id.
   * @param id The id of the currency.
   */
  public findById(id: number): Observable<AppHttpResponse<Currency>> {
    return this.http.get<AppHttpResponse<Currency>>(`${this.currenciesApi}/${id}`);
  }

  /**
   * Finds the primary currency.
   */
  public findPrimaryCurrency(): Observable<AppHttpResponse<Currency>> {
    return this.http.get<AppHttpResponse<Currency>>(`${this.currenciesApi}/primary`);
  }

  /**
   * Updates an existing currency data using the provided data.
   * @param data The updated currency data.
   */
  public update(data: UpdateCurrencyInput): Observable<AppHttpResponse<Currency>> {
    return this.http.put<AppHttpResponse<Currency>>(`${this.currenciesApi}`, data);
  }

  /**
   * Deletes the currency by given id.
   * @param id The id of the currency.
   */
  public delete(id: number): Observable<AppHttpResponse<Currency>> {
    return this.http.delete<AppHttpResponse<Currency>>(`${this.currenciesApi}/${id}`);
  }
}
