import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';

import { AppHttpResponse, PAGINATION } from 'shared';
import { User } from 'security/models';

/**
 * The users services includes functionality to get organization users.
 */
@Injectable()
export class UsersService {
  /**
   * The relative route for the users.
   *
   * No leading or trailing slashes required.
   */
  private usersApi = 'security/users';

  constructor(private http: HttpClient) {}

  /** Gets the list of users satisfying the given criteria & pagination options.
   * @param page The current pagination page number.
   * @param pageSize The maximum number of users allowed per one pagination page.
   */
  public getOrganizationUsers(name: string, page: number | any): Observable<AppHttpResponse<User[]>> {
    const params = new HttpParams().set('name', name).set('page', page).set('pageSize', PAGINATION.Users.toString());

    return this.http.get<AppHttpResponse<User[]>>(`${this.usersApi}/organization-users`, { params });
  }
}
