import { Injectable } from '@angular/core';

import { of } from 'rxjs';
import { switchMap, map, catchError } from 'rxjs/operators';
import { Effect, ofType, Actions } from '@ngrx/effects';

import { LookupsService } from 'lookups/services';
import {
  IncomingStockTypeActionType,
  GetAllIncomingStockTypesFail,
  GetAllIncomingStockTypesSuccess,
} from 'lookups/store/actions';

@Injectable()
export class IncomingStockTypeEffects {
  constructor(private actions$: Actions, private lookupsService: LookupsService) {}

  /* ========================= GET_ALL_INCOMING_STOCK_TYPES =================================== */
  @Effect()
  getAllIncomingStockTypes$ = this.actions$.pipe(
    ofType(IncomingStockTypeActionType.GET_ALL_INCOMING_STOCK_TYPES),
    switchMap(() =>
      this.lookupsService.getAllIncomingStockTypes().pipe(
        map((response) => new GetAllIncomingStockTypesSuccess(response)),
        catchError((error) => of(new GetAllIncomingStockTypesFail(error)))
      )
    )
  );
}
