import { AssignmentStatusActionType, AssignmentStatusActions } from 'lookups/store/actions';
import { AssignmentStatusState } from 'lookups/store/states';

/**
 * The initial state from which the state starts.
 */
const initialState: AssignmentStatusState = {
  data: [],
  error: null,
  isLoaded: false,
  isLoading: false,
};

/**
 * The reducer function that is called in each action dispatch against the state.
 * @param state The current state.
 * @param action The action that will affect the state.
 */
export function assignmentStatusReducer(
  state: AssignmentStatusState = initialState,
  action: AssignmentStatusActions
): AssignmentStatusState {
  switch (action.type) {
    case AssignmentStatusActionType.GET_ALL_ASSIGNMENT_STATUSES: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case AssignmentStatusActionType.GET_ALL_ASSIGNMENT_STATUSES_FAIL: {
      return {
        ...state,
        error: action.payload,
        isLoading: false,
        isLoaded: false,
      };
    }

    case AssignmentStatusActionType.GET_ALL_ASSIGNMENT_STATUSES_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        isLoading: false,
        isLoaded: true,
      };
    }

    default:
      return state;
  }
}
