import { createSelector } from '@ngrx/store';

import { ReportsState, SalesReportsState } from 'reports/store/states';
import { selectReportsState } from 'reports/store/selectors/feature.selectors';

/* Selectors */

/**
 * Gets the sales reports state.s
 */
const selectSalesReportsState = createSelector(selectReportsState, (state: ReportsState) => state.sales);

/**
 * Gets the loaded report.
 */
export const getCustomerSummaryReport = createSelector(
  selectSalesReportsState,
  (state: SalesReportsState) => state.customerSummary
);

/**
 * Gets the loaded report.
 */
export const getCustomersDebtsReport = createSelector(
  selectSalesReportsState,
  (state: SalesReportsState) => state.customersDebts
);

/**
 * Gets the loaded report.
 */
export const getSalesPersonReport = createSelector(
  selectSalesReportsState,
  (state: SalesReportsState) => state.salesPerson
);

/**
 * Gets the loaded report.
 */
export const getCustomersPaymentsReport = createSelector(
  selectSalesReportsState,
  (state: SalesReportsState) => state.customersPayments
);

/**
 * Gets the loaded report.
 */
export const getCustomersPointsReport = createSelector(
  selectSalesReportsState,
  (state: SalesReportsState) => state.customersPoints
);

/**
 * Gets the loaded report.
 */
export const getGrossProfitReport = createSelector(
  selectSalesReportsState,
  (state: SalesReportsState) => state.grossProfit
);

/**
 * Gets the loaded report.
 */
export const getMaintenanceStageInvoiceReport = createSelector(
  selectSalesReportsState,
  (state: SalesReportsState) => state.maintenanceStageInvoice
);

/**
 * Gets the loaded report.
 */
export const getTopReturnedSalesProductsReport = createSelector(
  selectSalesReportsState,
  (state: SalesReportsState) => state.topReturnedSalesProducts
);

/**
 * Gets the loaded report.
 */
export const getNetSalesReport = createSelector(selectSalesReportsState, (state: SalesReportsState) => state.netSales);

/**
 * Gets the loaded report.
 */
export const getProductSalesReport = createSelector(
  selectSalesReportsState,
  (state: SalesReportsState) => state.productSales
);

/**
 * Gets the loaded report.
 */
export const getProductSalesReturnsReport = createSelector(
  selectSalesReportsState,
  (state: SalesReportsState) => state.productSalesReturns
);

/**
 * Gets the loaded report.
 */
export const getProductClassesSalesReport = createSelector(
  selectSalesReportsState,
  (state: SalesReportsState) => state.productClassesSales
);

/**
 * Gets the loaded report.
 */
export const getProductsSalesReport = createSelector(
  selectSalesReportsState,
  (state: SalesReportsState) => state.productsSales
);

/**
 * Gets the loaded report.
 */
export const getProductsSalesReturnsReport = createSelector(
  selectSalesReportsState,
  (state: SalesReportsState) => state.productsSalesReturns
);

/**
 * Gets the loaded report.
 */
export const getSalesInvoicesReport = createSelector(
  selectSalesReportsState,
  (state: SalesReportsState) => state.salesInvoices
);

/**
 * Gets the loaded report.
 */
export const getSalesReturnsReport = createSelector(
  selectSalesReportsState,
  (state: SalesReportsState) => state.salesReturns
);

/**
 * Gets the loaded report.
 */
export const getSalesSummaryReport = createSelector(
  selectSalesReportsState,
  (state: SalesReportsState) => state.salesSummary
);

/**
 * Gets the loaded report.
 */
export const getShiftSummaryReport = createSelector(
  selectSalesReportsState,
  (state: SalesReportsState) => state.shiftSummary
);

/**
 * Gets the loaded report.
 */
export const getTopCustomersReport = createSelector(
  selectSalesReportsState,
  (state: SalesReportsState) => state.topCustomers
);

/**
 * Gets the loaded report.
 */
export const getTopSellingProductsReport = createSelector(
  selectSalesReportsState,
  (state: SalesReportsState) => state.topSellingProducts
);

/**
 * Gets the loaded report.
 */
export const getWorstCustomersReport = createSelector(
  selectSalesReportsState,
  (state: SalesReportsState) => state.worstCustomers
);

/**
 * Gets the loaded report.
 */
export const getWorstReturnedSalesProductsReport = createSelector(
  selectSalesReportsState,
  (state: SalesReportsState) => state.worstReturnedSalesProducts
);

/**
 * Gets the loaded report.
 */
export const getWorstSellingProductsReport = createSelector(
  selectSalesReportsState,
  (state: SalesReportsState) => state.worstSellingProducts
);

/**
 * Gets the error that occurred in the state.
 */
export const getSalesReportsError = createSelector(selectSalesReportsState, (state: SalesReportsState) => state.error);

/**
 * Determines if there is a running load process.
 */
export const getCustomerSummaryReportLoading = createSelector(
  selectSalesReportsState,
  (state: SalesReportsState) => state.isLoadingCustomerSummary
);

/**
 * Determines if the last load process has been ended successfully.
 */
export const getCustomerSummaryReportLoadCompleted = createSelector(
  selectSalesReportsState,
  (state: SalesReportsState) => state.isCustomerSummaryLoadCompleted
);

/**
 * Determines if there is a running load process.
 */
export const getCustomersDebtsReportLoading = createSelector(
  selectSalesReportsState,
  (state: SalesReportsState) => state.isLoadingCustomersDebts
);

/**
 * Determines if the last load process has been ended successfully.
 */
export const getCustomersDebtsReportLoadCompleted = createSelector(
  selectSalesReportsState,
  (state: SalesReportsState) => state.isCustomersDebtsLoadCompleted
);

/**
 * Determines if there is a running load process.
 */
export const getCustomersPaymentsReportLoading = createSelector(
  selectSalesReportsState,
  (state: SalesReportsState) => state.isLoadingCustomersPayments
);

/**
 * Determines if the last load process has been ended successfully.
 */
export const getCustomersPaymentsReportLoadCompleted = createSelector(
  selectSalesReportsState,
  (state: SalesReportsState) => state.isCustomersPaymentsLoadCompleted
);

/**
 * Determines if there is a running load process.
 */
export const getMaintenanceStageInvoiceReportLoading = createSelector(
  selectSalesReportsState,
  (state: SalesReportsState) => state.isLoadingMaintenanceStageInvoice
);

/**
 * Determines if the last load process has been ended successfully.
 */
export const getMaintenanceStageInvoiceReportLoadCompleted = createSelector(
  selectSalesReportsState,
  (state: SalesReportsState) => state.isMaintenanceStageInvoiceLoadCompleted
);

/**
 * Determines if there is a running load process.
 */
export const getGrossProfitReportLoading = createSelector(
  selectSalesReportsState,
  (state: SalesReportsState) => state.isLoadingGrossProfit
);

/**
 * Determines if the last load process has been ended successfully.
 */
export const getGrossProfitReportLoadCompleted = createSelector(
  selectSalesReportsState,
  (state: SalesReportsState) => state.isGrossProfitLoadCompleted
);

/**
 * Determines if there is a running load process.
 */
export const getTopReturnedSalesProductsReportLoading = createSelector(
  selectSalesReportsState,
  (state: SalesReportsState) => state.isLoadingTopReturnedSalesProducts
);

/**
 * Determines if the last load process has been ended successfully.
 */
export const getTopReturnedSalesProductsReportLoadCompleted = createSelector(
  selectSalesReportsState,
  (state: SalesReportsState) => state.isTopReturnedSalesProductsLoadCompleted
);

/**
 * Determines if there is a running load process.
 */
export const getCustomersPointsReportLoading = createSelector(
  selectSalesReportsState,
  (state: SalesReportsState) => state.isLoadingCustomersPoints
);

/**
 * Determines if the last load process has been ended successfully.
 */
export const getCustomersPointsReportLoadCompleted = createSelector(
  selectSalesReportsState,
  (state: SalesReportsState) => state.isCustomersPointsLoadCompleted
);

/**
 * Determines if there is a running load process.
 */
export const getNetSalesReportLoading = createSelector(
  selectSalesReportsState,
  (state: SalesReportsState) => state.isLoadingNetSales
);

/**
 * Determines if the last load process has been ended successfully.
 */
export const getNetSalesReportLoadCompleted = createSelector(
  selectSalesReportsState,
  (state: SalesReportsState) => state.isNetSalesLoadCompleted
);

/**
 * Determines if there is a running load process.
 */
export const getProductSalesReportLoading = createSelector(
  selectSalesReportsState,
  (state: SalesReportsState) => state.isLoadingProductSales
);

/**
 * Determines if the last load process has been ended successfully.
 */
export const getProductSalesReportLoadCompleted = createSelector(
  selectSalesReportsState,
  (state: SalesReportsState) => state.isProductSalesLoadCompleted
);

/**
 * Determines if there is a running load process.
 */
export const getProductSalesReturnsReportLoading = createSelector(
  selectSalesReportsState,
  (state: SalesReportsState) => state.isLoadingProductSalesReturns
);

/**
 * Determines if the last load process has been ended successfully.
 */
export const getProductSalesReturnsReportLoadCompleted = createSelector(
  selectSalesReportsState,
  (state: SalesReportsState) => state.isProductSalesReturnsLoadCompleted
);

/**
 * Determines if there is a running load process.
 */
export const getProductsClassesSalesReportLoading = createSelector(
  selectSalesReportsState,
  (state: SalesReportsState) => state.isLoadingProductClassesSales
);

/**
 * Determines if the last load process has been ended successfully.
 */
export const getProductsClassesSalesReportLoadCompleted = createSelector(
  selectSalesReportsState,
  (state: SalesReportsState) => state.isProductClassesSalesLoadCompleted
);

/**
 * Determines if there is a running load process.
 */
export const getProductsSalesReportLoading = createSelector(
  selectSalesReportsState,
  (state: SalesReportsState) => state.isLoadingProductsSales
);

/**
 * Determines if the last load process has been ended successfully.
 */
export const getProductsSalesReportLoadCompleted = createSelector(
  selectSalesReportsState,
  (state: SalesReportsState) => state.isProductsSalesLoadCompleted
);

/**
 * Determines if there is a running load process.
 */
export const getProductsSalesReturnsReportLoading = createSelector(
  selectSalesReportsState,
  (state: SalesReportsState) => state.isLoadingProductsSalesReturns
);

/**
 * Determines if the last load process has been ended successfully.
 */
export const getProductsSalesReturnsReportLoadCompleted = createSelector(
  selectSalesReportsState,
  (state: SalesReportsState) => state.isProductsSalesReturnsLoadCompleted
);

/**
 * Determines if there is a running load process.
 */
export const getSalesSummaryReportLoading = createSelector(
  selectSalesReportsState,
  (state: SalesReportsState) => state.isLoadingSalesSummary
);

/**
 * Determines if the last load process has been ended successfully.
 */
export const getSalesSummaryReportLoadCompleted = createSelector(
  selectSalesReportsState,
  (state: SalesReportsState) => state.isSalesSummaryLoadCompleted
);

/**
 * Determines if there is a running load process.
 */
export const getSalesReturnsReportLoading = createSelector(
  selectSalesReportsState,
  (state: SalesReportsState) => state.isLoadingSalesReturns
);

/**
 * Determines if the last load process has been ended successfully.
 */
export const getSalesReturnsReportLoadCompleted = createSelector(
  selectSalesReportsState,
  (state: SalesReportsState) => state.isSalesReturnsLoadCompleted
);

/**
 * Determines if there is a running load process.
 */
export const getShiftSummaryReportLoading = createSelector(
  selectSalesReportsState,
  (state: SalesReportsState) => state.isLoadingShiftSummary
);

/**
 * Determines if the last load process has been ended successfully.
 */
export const getShiftSummaryReportLoadCompleted = createSelector(
  selectSalesReportsState,
  (state: SalesReportsState) => state.isShiftSummaryLoadCompleted
);

/**
 * Determines if there is a running load process.
 */
export const getSalesInvoicesReportLoading = createSelector(
  selectSalesReportsState,
  (state: SalesReportsState) => state.isLoadingSalesInvoices
);

/**
 * Determines if the last load process has been ended successfully.
 */
export const getSalesInvoicesReportLoadCompleted = createSelector(
  selectSalesReportsState,
  (state: SalesReportsState) => state.isSalesInvoicesLoadCompleted
);

/**
 * Determines if there is a running load process.
 */
export const getTopCustomersReportLoading = createSelector(
  selectSalesReportsState,
  (state: SalesReportsState) => state.isLoadingTopCustomers
);

/**
 * Determines if the last load process has been ended successfully.
 */
export const getTopCustomersReportLoadCompleted = createSelector(
  selectSalesReportsState,
  (state: SalesReportsState) => state.isTopCustomersLoadCompleted
);

/**
 * Determines if there is a running load process.
 */
export const getTopSellingProductsReportLoading = createSelector(
  selectSalesReportsState,
  (state: SalesReportsState) => state.isLoadingTopSellingProducts
);

/**
 * Determines if the last load process has been ended successfully.
 */
export const getTopSellingProductsReportLoadCompleted = createSelector(
  selectSalesReportsState,
  (state: SalesReportsState) => state.isTopSellingProductsLoadCompleted
);

/**
 * Determines if there is a running load process.
 */
export const getWorstCustomersReportLoading = createSelector(
  selectSalesReportsState,
  (state: SalesReportsState) => state.isLoadingWorstCustomers
);

/**
 * Determines if the last load process has been ended successfully.
 */
export const getWorstCustomersReportLoadCompleted = createSelector(
  selectSalesReportsState,
  (state: SalesReportsState) => state.isWorstCustomersLoadCompleted
);

/**
 * Determines if there is a running load process.
 */
export const getWorstSellingProductsReportLoading = createSelector(
  selectSalesReportsState,
  (state: SalesReportsState) => state.isLoadingWorstSellingProducts
);

/**
 * Determines if the last load process has been ended successfully.
 */
export const getWorstSellingProductsReportLoadCompleted = createSelector(
  selectSalesReportsState,
  (state: SalesReportsState) => state.isWorstSellingProductsLoadCompleted
);

/**
 * Determines if there is a running load process.
 */
export const getWorstReturnedSalesProductsReportLoading = createSelector(
  selectSalesReportsState,
  (state: SalesReportsState) => state.isLoadingWorstReturnedSalesProducts
);

/**
 * Determines if the last load process has been ended successfully.
 */
export const getWorstReturnedSalesProductsReportLoadCompleted = createSelector(
  selectSalesReportsState,
  (state: SalesReportsState) => state.isWorstReturnedSalesProductsLoadCompleted
);

/**
 * Determines if there is a running load process.
 */
export const getSalesPersonReportLoading = createSelector(
  selectSalesReportsState,
  (state: SalesReportsState) => state.isLoadingSalesPerson
);

/**
 * Determines if the last load process has been ended successfully.
 */
export const getSalesPersonReportLoadCompleted = createSelector(
  selectSalesReportsState,
  (state: SalesReportsState) => state.isSalesPersonLoadCompleted
);
