<!-- organization locations search modal starts -->
<ng-template #modalRef let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title text-primary" id="modal-basic-title">{{ 'STORES.LOCATIONS.LOCATION_LIST' | translate }}</h4>
    <button type="button" class="close" aria-label="Close" (click)="d(); closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <!-- organization locations list starts -->
    <div class="row">
      <div class="col-md-12">
        <form class="row" [formGroup]="searchForm" (ngSubmit)="search()">
          <div class="form-group col">
            <label for="name">{{ 'STORES.LOCATIONS.LOCATION_DATA.LOCATION_NAME' | translate }}</label>
            <input
              type="text"
              id="name"
              class="form-control round"
              [placeholder]="'STORES.LOCATIONS.LOCATION_DATA.LOCATION_NAME_OR_CODE_PLACEHOLDER' | translate"
              formControlName="name"
              tabindex="0"
              (input)="search($event)"
              ngbAutofocus
            />
          </div>
          <div class="form-group col">
            <label for="locationTypes">{{ 'STORES.LOCATIONS.LOCATION_DATA.LOCATION_TYPE' | translate }}</label>
            <ng-select
              class="round"
              formControlName="locationTypes"
              [multiple]="true"
              [closeOnSelect]="false"
              labelForId="locationTypes"
              searchable="true"
              [placeholder]="'STORES.LOCATIONS.LOCATION_DATA.LOCATION_TYPE_PLACEHOLDER' | translate"
              [notFoundText]="'SHARED.SEARCH.SEARCH_NOT_FOUND' | translate"
            >
              <ng-option *ngFor="let locationType of locationTypes$ | async" [value]="locationType.id">
                {{ locationType.name | localize: locationType.nameEn | async }}
              </ng-option>
            </ng-select>
          </div>
          <div class="form-group col">
            <label for="regions">{{ 'STORES.LOCATIONS.LOCATION_DATA.THE_REGION' | translate }}</label>
            <ng-select
              class="round"
              formControlName="regions"
              [multiple]="true"
              [closeOnSelect]="false"
              labelForId="regions"
              searchable="true"
              [placeholder]="'STORES.LOCATIONS.LOCATION_DATA.THE_REGION_PLACEHOLDER' | translate"
              [notFoundText]="'SHARED.SEARCH.SEARCH_NOT_FOUND' | translate"
              (open)="regionsListVisibility = true"
            >
              <ng-option *ngFor="let region of regions" [value]="region.id">
                {{ region.name | localize: region.nameEn | async }}
              </ng-option>
            </ng-select>
          </div>
          <div class="form-group col">
            <label for="sectors">{{ 'STORES.LOCATIONS.LOCATION_DATA.THE_SECTOR' | translate }}</label>
            <ng-select
              class="round"
              formControlName="sectors"
              [multiple]="true"
              [closeOnSelect]="false"
              labelForId="sectors"
              searchable="true"
              [placeholder]="'STORES.LOCATIONS.LOCATION_DATA.THE_SECTOR_PLACEHOLDER' | translate"
              [notFoundText]="'SHARED.SEARCH.SEARCH_NOT_FOUND' | translate"
              (open)="sectorsListVisibility = true"
            >
              <ng-option *ngFor="let sector of sectors" [value]="sector.id">
                {{ sector.name | localize: sector.nameEn | async }}
              </ng-option>
            </ng-select>
          </div>
          <div class="col-md-auto form-group d-flex">
            <button
              type="submit"
              class="btn btn-raised btn-primary align-self-end round mx-1 mb-0"
              [disabled]="isSearching$ | async"
            >
              <i class="fa fa-search"></i>
            </button>
          </div>
        </form>
      </div>
      <div class="col-md-12" *ngIf="(locations$ | async)?.length; else noItems">
        <div class="row">
          <div class="col-md-12 alert alert-light text-center" role="alert">
            {{ 'SHARED.SEARCH.SEARCH_RESULTS' | translate }} ({{ (paginationInfo$ | async).total | toNumber }})
            {{ 'STORES.LOCATIONS.LOCATION_DATA.LOCATION' | translate }}
          </div>
          <div class="col-md-12">
            <div class="table-responsive">
              <table class="table text-center">
                <thead>
                  <tr>
                    <th class="fit-width" *ngIf="!closeOnSelect">
                      <a
                        class="primary p-0"
                        (click)="selectLocation(locationsList)"
                        [ngbTooltip]="'SHARED.SEARCH.SELECT_ALL' | translate"
                      >
                        <i class="fa fa-check-circle font-medium-3"></i>
                      </a>
                    </th>
                    <th class="fit-width" *ngIf="closeOnSelect">
                      <i class="fa fa-gear font-medium-3"></i>
                    </th>
                    <th scope="col"><i class="fa fa-list-ol"></i></th>
                    <th scope="col">{{ 'STORES.LOCATIONS.LOCATION_DATA.LOCATION_CODE' | translate }}</th>
                    <th scope="col">{{ 'STORES.LOCATIONS.LOCATION_DATA.LOCATION_NAME' | translate }}</th>
                    <th scope="col">{{ 'STORES.LOCATIONS.LOCATION_DATA.THE_REGION' | translate }}</th>
                    <th scope="col">{{ 'STORES.LOCATIONS.LOCATION_DATA.THE_SECTOR' | translate }}</th>
                    <th scope="col">{{ 'STORES.LOCATIONS.LOCATION_DATA.THE_COST_CENTER' | translate }}</th>
                    <th scope="col">{{ 'STORES.LOCATIONS.LOCATION_DATA.ADDRESS' | translate }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let location of locations$ | async; trackBy: trackItems; let i = index">
                    <td>
                      <a
                        class="primary p-0"
                        (click)="selectLocation([location])"
                        [ngbTooltip]="'STORES.LOCATIONS.LOCATION_DATA.TOOL_TIP_SELECT' | translate"
                      >
                        <i class="fa fa-check-circle-o font-medium-3"></i>
                      </a>
                    </td>
                    <th scope="row">{{ i + 1 }}</th>
                    <td>{{ location.code }}</td>
                    <td>{{ location.name | localize: location.nameEn | async }}</td>
                    <td>{{ location.region?.name | localize: location.region?.nameEn | async }}</td>
                    <td>{{ location.sector?.name | localize: location.sector?.nameEn | async }}</td>
                    <td>{{ location.costCenter.description | localize: location.costCenter.descriptionEn | async }}</td>
                    <td>
                      <span *ngIf="location.buildingNo"> {{ location.buildingNo }} - </span>
                      <span *ngIf="location.streetName"> {{ location.streetName }} - </span>
                      <span *ngIf="location.district"> {{ location.district }} - </span>
                      <span *ngIf="location.city">
                        {{ location.city.name | localize: location.city.nameEn | async }}
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div class="d-flex justify-content-between p-2">
                <ngb-pagination
                  *ngIf="paginationInfo$ | async; let pagination"
                  maxSize="5"
                  [collectionSize]="pagination.total"
                  [pageSize]="pagination.pageSize"
                  [page]="pagination.page"
                  (pageChange)="pageChanged($event)"
                  boundaryLinks="true"
                >
                  <ng-template ngbPaginationFirst>{{ 'SHARED.PAGINATION.PAGINATION_FIRST' | translate }}</ng-template>
                  <ng-template ngbPaginationPrevious>{{
                    'SHARED.PAGINATION.PAGINATION_PREVIOUS' | translate
                  }}</ng-template>
                  <ng-template ngbPaginationNext>{{ 'SHARED.PAGINATION.PAGINATION_NEXT' | translate }}</ng-template>
                  <ng-template ngbPaginationLast>{{ 'SHARED.PAGINATION.PAGINATION_LAST' | translate }}</ng-template>
                </ngb-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- organization locations list ends -->
  </div>
  <div class="modal-footer">
    <div class="modal-actions">
      <button type="button" class="btn btn-outline-dark round" (click)="c(); closeModal()">
        <i class="fa fa-times"></i>
        <span class="mx-1"> {{ 'SHARED.CONFIRM.CLOSE' | translate }} </span>
      </button>
    </div>
  </div>
</ng-template>
<!-- organization locations search modal ends -->

<!-- no items template starts -->
<ng-template #noItems>
  <div class="col-md-12 alert alert-warning mt-3 text-center" role="alert">
    {{ 'STORES.LOCATIONS.NO_SEARCH_RESULT_LOCATION' | translate }}
  </div>
</ng-template>
<!-- no items template ends -->

<!-- regions search starts -->
<app-regions-search (regions)="selectRegion($event)" [(visible)]="regionsListVisibility"> </app-regions-search>
<!-- regions search ends -->

<!-- sectors search starts -->
<app-sectors-search (sectors)="selectSector($event)" [(visible)]="sectorsListVisibility"> </app-sectors-search>
<!-- sectors search ends -->
