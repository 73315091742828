import { Injectable } from '@angular/core';

import { of } from 'rxjs';
import { switchMap, map, catchError } from 'rxjs/operators';
import { Effect, ofType, Actions } from '@ngrx/effects';

import { LookupsService } from 'lookups/services';
import {
  PurchaseRequestReceiveTypeActionType,
  GetAllPurchaseRequestReceiveTypeFail,
  GetAllPurchaseRequestReceiveTypeSuccess,
} from 'lookups/store/actions';

@Injectable()
export class PurchaseRequestReceiveTypeEffects {
  constructor(private actions$: Actions, private lookupsService: LookupsService) {}

  /* ========================= GET_ALL_PURCHASE_REQUEST_RECEIVE_TYPE =================================== */
  @Effect()
  getAllPurchaseRequestReceiveTypes$ = this.actions$.pipe(
    ofType(PurchaseRequestReceiveTypeActionType.GET_ALL_PURCHASE_REQUEST_RECEIVE_TYPE),
    switchMap(() =>
      this.lookupsService.getAllPurchaseRequestReceiveTypes().pipe(
        map((response) => new GetAllPurchaseRequestReceiveTypeSuccess(response)),
        catchError((error) => of(new GetAllPurchaseRequestReceiveTypeFail(error)))
      )
    )
  );
}
