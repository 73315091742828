import { createSelector } from '@ngrx/store';

import { selectLookupsState } from 'lookups/store/selectors/feature.selectors';
import { LookupsState, ManagerialLevelState } from 'lookups/store/states';

/**
 * Gets the managerial level state.
 */
const selectManagerialLevels = createSelector(selectLookupsState, (state: LookupsState) => state.managerialLevels);

/**
 * Gets the list of loaded managerial levels.
 */
export const getManagerialLevels = createSelector(selectManagerialLevels, (state: ManagerialLevelState) => state.data);

/**
 * Gets the error that occurred in the state.
 */
export const getManagerialLevelsError = createSelector(
  selectManagerialLevels,
  (state: ManagerialLevelState) => state.error
);

/**
 * Determines if there is a running load process.
 */
export const getManagerialLevelsIsLoading = createSelector(
  selectManagerialLevels,
  (state: ManagerialLevelState) => state.isLoading
);

/**
 * Determines if the last load process has been ended successfully.
 */
export const getManagerialLevelsIsLoaded = createSelector(
  selectManagerialLevels,
  (state: ManagerialLevelState) => state.isLoaded
);
