import { Injectable } from '@angular/core';

import { Effect, ofType, Actions } from '@ngrx/effects';
import { switchMap, map, catchError, tap, debounceTime } from 'rxjs/operators';
import { of } from 'rxjs';

import { NotificationService, StatusCode, PAGINATION, TranslationService } from 'shared';
import { CustomersService } from 'sales/services';
import {
  CustomersActionType,
  SearchCustomers,
  SearchCustomersFail,
  SearchCustomersSuccess,
  CreateCustomer,
  CreateCustomerSuccess,
  CreateCustomerFail,
  CreateCustomerPayment,
  CreateCustomerPaymentSuccess,
  CreateCustomerPaymentFail,
  UpdateCustomer,
  UpdateCustomerSuccess,
  UpdateCustomerFail,
  BlockCustomer,
  BlockCustomerSuccess,
  BlockCustomerFail,
  ActivateCustomer,
  ActivateCustomerSuccess,
  ActivateCustomerFail,
  DeleteCustomer,
  DeleteCustomerSuccess,
  DeleteCustomerFail,
  FindCustomer,
  FindCustomerSuccess,
  FindCustomerFail,
  SearchCustomerLogs,
  SearchCustomerLogsSuccess,
  SearchCustomerLogsFail,
  CancelCustomerLog,
  CancelCustomerLogFail,
  CancelCustomerLogSuccess,
  SearchCustomersPayments,
  SearchCustomersPaymentsSuccess,
  SearchCustomersPaymentsFail,
  SearchCustomerAdvancePayments,
  SearchCustomerAdvancePaymentsSuccess,
  SearchCustomerAdvancePaymentsFail,
  CreateCustomerAdvancePayment,
  CreateCustomerAdvancePaymentSuccess,
  CreateCustomerAdvancePaymentFail,
  CreateCustomerPaymentByAdvancePayment,
  CreateCustomerPaymentByAdvancePaymentSuccess,
  CreateCustomerPaymentByAdvancePaymentFail,
  CreateCustomerRefund,
  CreateCustomerRefundSuccess,
  CreateCustomerRefundFail,
} from 'sales/store/actions';

@Injectable()
export class CustomersEffects {
  constructor(
    private actions$: Actions,
    private customersService: CustomersService,
    private notificationService: NotificationService,
    private translationService: TranslationService
  ) {}

  /* ========================= SEARCH_CUSTOMERS =================================== */
  @Effect()
  search$ = this.actions$.pipe(
    ofType(CustomersActionType.SEARCH_CUSTOMERS),
    debounceTime(300),
    switchMap((action: SearchCustomers) =>
      this.customersService.search(action.payload?.name ?? '', action.payload.page, PAGINATION.Customers).pipe(
        map((response) => new SearchCustomersSuccess(response)),
        catchError((error) => of(new SearchCustomersFail(error)))
      )
    )
  );

  /* ========================= SEARCH_CUSTOMER_LOGS =================================== */
  @Effect()
  searchCustomerLogs$ = this.actions$.pipe(
    ofType(CustomersActionType.SEARCH_CUSTOMER_LOGS),
    switchMap(({ payload }: SearchCustomerLogs) => {
      return this.customersService
        .searchCustomerLogs(payload.customerId, payload.fromDate, payload.toDate, payload.page, PAGINATION.CustomerLog)
        .pipe(
          map((response: any) => new SearchCustomerLogsSuccess(response)),
          catchError((error) => of(new SearchCustomerLogsFail(error)))
        );
    })
  );

  /* ========================= SEARCH_CUSTOMERS_PAYMENTS =================================== */
  @Effect()
  searchCustomersPayments$ = this.actions$.pipe(
    ofType(CustomersActionType.SEARCH_CUSTOMERS_PAYMENTS),
    switchMap(({ payload }: SearchCustomersPayments) => {
      return this.customersService
        .searchCustomersPayments(
          payload.customers,
          payload.customerLogTypes,
          payload.fromDate,
          payload.toDate,
          payload.page,
          PAGINATION.CustomerLog
        )
        .pipe(
          map((response: any) => new SearchCustomersPaymentsSuccess(response)),
          catchError((error) => of(new SearchCustomersPaymentsFail(error)))
        );
    })
  );

  /* ========================= SEARCH_CUSTOMER_ADVANCE_PAYMENTS =================================== */
  @Effect()
  searchCustomerAdvancePayments$ = this.actions$.pipe(
    ofType(CustomersActionType.SEARCH_CUSTOMER_ADVANCE_PAYMENTS),
    switchMap(({ payload }: SearchCustomerAdvancePayments) => {
      return this.customersService
        .searchCustomerAdvancePayments(
          payload.customerId,
          payload.fromDate,
          payload.toDate,
          payload.page,
          PAGINATION.CustomerLog
        )
        .pipe(
          map((response: any) => new SearchCustomerAdvancePaymentsSuccess(response)),
          catchError((error) => of(new SearchCustomerAdvancePaymentsFail(error)))
        );
    })
  );

  /* ========================= FIND_CUSTOMER =================================== */
  @Effect()
  find$ = this.actions$.pipe(
    ofType(CustomersActionType.FIND_CUSTOMER),
    switchMap((action: FindCustomer) =>
      this.customersService.findById(action.payload).pipe(
        map((response) => new FindCustomerSuccess(response)),
        catchError((error) => of(new FindCustomerFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  findFail$ = this.actions$.pipe(
    ofType(CustomersActionType.FIND_CUSTOMER_FAIL),
    tap((action: FindCustomerFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(this.translationService.translate('SALES.CUSTOMERS.CUSTOMER_NOT_FOUND'));
      }
    })
  );

  /* ========================= CREATE_CUSTOMER =================================== */
  @Effect()
  create$ = this.actions$.pipe(
    ofType(CustomersActionType.CREATE_CUSTOMER),
    switchMap((action: CreateCustomer) =>
      this.customersService.create(action.payload).pipe(
        map((response) => new CreateCustomerSuccess(response)),
        catchError((error) => of(new CreateCustomerFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  createSuccess$ = this.actions$.pipe(
    ofType(CustomersActionType.CREATE_CUSTOMER_SUCCESS),
    tap((action: CreateCustomerSuccess) => {
      this.notificationService.success(this.translationService.translate('SALES.CUSTOMERS.CUSTOMER_ADDED'));
    })
  );

  @Effect({ dispatch: false })
  createFail$ = this.actions$.pipe(
    ofType(CustomersActionType.CREATE_CUSTOMER_FAIL),
    tap((action: CreateCustomerFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );

  /* ========================= CREATE_CUSTOMER_PAYMENT =================================== */
  @Effect()
  createPayment$ = this.actions$.pipe(
    ofType(CustomersActionType.CREATE_CUSTOMER_PAYMENTS),
    switchMap((action: CreateCustomerPayment) =>
      this.customersService.createPayment(action.payload).pipe(
        map((response) => new CreateCustomerPaymentSuccess(response)),
        catchError((error) => of(new CreateCustomerPaymentFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  createPaymentSuccess$ = this.actions$.pipe(
    ofType(CustomersActionType.CREATE_CUSTOMER_PAYMENTS_SUCCESS),
    tap((action: CreateCustomerPaymentSuccess) => {
      this.notificationService.success(
        this.translationService.translate('SALES.CUSTOMERS.CUSTOMER_PAYMENTS_ADDED', {
          paymentsNumber: action.payload.data.id,
        })
      );
    })
  );

  @Effect({ dispatch: false })
  createPaymentFail$ = this.actions$.pipe(
    ofType(CustomersActionType.CREATE_CUSTOMER_PAYMENTS_FAIL),
    tap((action: CreateCustomerPaymentFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.error(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );

  /* ========================= CREATE_CUSTOMER_ADVANCE_PAYMENT =================================== */
  @Effect()
  createAdvancePayment$ = this.actions$.pipe(
    ofType(CustomersActionType.CREATE_CUSTOMER_ADVANCE_PAYMENT),
    switchMap((action: CreateCustomerAdvancePayment) =>
      this.customersService.createAdvancePayment(action.payload).pipe(
        map((response) => new CreateCustomerAdvancePaymentSuccess(response)),
        catchError((error) => of(new CreateCustomerAdvancePaymentFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  createAdvancePaymentSuccess$ = this.actions$.pipe(
    ofType(CustomersActionType.CREATE_CUSTOMER_ADVANCE_PAYMENT_SUCCESS),
    tap((action: CreateCustomerAdvancePaymentSuccess) => {
      this.notificationService.success(
        this.translationService.translate('SALES.CUSTOMERS.CUSTOMER_ADVANCE_PAYMENT_ADDED', {
          advancePaymentNumber: action.payload.data.id,
        })
      );
    })
  );

  @Effect({ dispatch: false })
  createAdvancePaymentFail$ = this.actions$.pipe(
    ofType(CustomersActionType.CREATE_CUSTOMER_ADVANCE_PAYMENT_FAIL),
    tap((action: CreateCustomerAdvancePaymentFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.error(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );

  /* ========================= CREATE_CUSTOMER_PAYMENT_BY_ADVANCE_PAYMENT =================================== */
  @Effect()
  createPaymentByAdvancePayment$ = this.actions$.pipe(
    ofType(CustomersActionType.CREATE_CUSTOMER_PAYMENT_BY_ADVANCE_PAYMENT),
    switchMap((action: CreateCustomerPaymentByAdvancePayment) =>
      this.customersService.createPaymentByAdvancePayment(action.payload).pipe(
        map((response) => new CreateCustomerPaymentByAdvancePaymentSuccess(response)),
        catchError((error) => of(new CreateCustomerPaymentByAdvancePaymentFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  createPaymentByAdvancePaymentSuccess$ = this.actions$.pipe(
    ofType(CustomersActionType.CREATE_CUSTOMER_PAYMENT_BY_ADVANCE_PAYMENT_SUCCESS),
    tap((action: CreateCustomerPaymentByAdvancePaymentSuccess) => {
      this.notificationService.success(
        this.translationService.translate('SALES.CUSTOMERS.CUSTOMER_PAYMENT_BY_ADVANCE_PAYMENT_ADDED', {
          paymentByAdvancePaymentNumber: action.payload.data.id,
        })
      );
    })
  );

  @Effect({ dispatch: false })
  createPaymentByAdvancePaymentFail$ = this.actions$.pipe(
    ofType(CustomersActionType.CREATE_CUSTOMER_PAYMENT_BY_ADVANCE_PAYMENT_FAIL),
    tap((action: CreateCustomerPaymentByAdvancePaymentFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.error(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );

  /* ========================= CREATE_CUSTOMER_REFUND =================================== */
  @Effect()
  createRefund$ = this.actions$.pipe(
    ofType(CustomersActionType.CREATE_CUSTOMER_REFUND),
    switchMap((action: CreateCustomerRefund) =>
      this.customersService.createRefund(action.payload).pipe(
        map((response) => new CreateCustomerRefundSuccess(response)),
        catchError((error) => of(new CreateCustomerRefundFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  createRefundSuccess$ = this.actions$.pipe(
    ofType(CustomersActionType.CREATE_CUSTOMER_REFUND_SUCCESS),
    tap((action: CreateCustomerRefundSuccess) => {
      this.notificationService.success(this.translationService.translate('SALES.CUSTOMERS.CUSTOMER_REFUND_ADDED'));
    })
  );

  @Effect({ dispatch: false })
  createRefundFail$ = this.actions$.pipe(
    ofType(CustomersActionType.CREATE_CUSTOMER_REFUND_FAIL),
    tap((action: CreateCustomerRefundFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.error(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );

  /* ========================= UPDATE_CUSTOMER =================================== */
  @Effect()
  update$ = this.actions$.pipe(
    ofType(CustomersActionType.UPDATE_CUSTOMER),
    switchMap((action: UpdateCustomer) =>
      this.customersService.update(action.payload).pipe(
        map((response) => new UpdateCustomerSuccess(response)),
        catchError((error) => of(new UpdateCustomerFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  updateSuccess$ = this.actions$.pipe(
    ofType(CustomersActionType.UPDATE_CUSTOMER_SUCCESS),
    tap((action: UpdateCustomerSuccess) => {
      this.notificationService.success(this.translationService.translate('SALES.CUSTOMERS.CUSTOMER_UPDATED'));
    })
  );

  @Effect({ dispatch: false })
  updateFail$ = this.actions$.pipe(
    ofType(CustomersActionType.UPDATE_CUSTOMER_FAIL),
    tap((action: UpdateCustomerFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(this.translationService.translate('SALES.CUSTOMERS.CUSTOMER_NOT_FOUND'));
      } else if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );

  /* ========================= BLOCK_CUSTOMER =================================== */
  @Effect()
  block$ = this.actions$.pipe(
    ofType(CustomersActionType.BLOCK_CUSTOMER),
    switchMap((action: BlockCustomer) =>
      this.customersService.block(action.payload).pipe(
        map((response) => new BlockCustomerSuccess(response)),
        catchError((error) => of(new BlockCustomerFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  blockSuccess$ = this.actions$.pipe(
    ofType(CustomersActionType.BLOCK_CUSTOMER_SUCCESS),
    tap((action: BlockCustomerSuccess) => {
      this.notificationService.success(this.translationService.translate('SALES.CUSTOMERS.CUSTOMER_BLOCKED'));
    })
  );

  @Effect({ dispatch: false })
  blockFail$ = this.actions$.pipe(
    ofType(CustomersActionType.BLOCK_CUSTOMER_FAIL),
    tap((action: BlockCustomerFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(this.translationService.translate('SALES.CUSTOMERS.CUSTOMER_NOT_FOUND'));
      } else if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );

  /* ========================= ACTIVATE_CUSTOMER =================================== */
  @Effect()
  activate$ = this.actions$.pipe(
    ofType(CustomersActionType.ACTIVATE_CUSTOMER),
    switchMap((action: ActivateCustomer) =>
      this.customersService.activate(action.payload).pipe(
        map((response) => new ActivateCustomerSuccess(response)),
        catchError((error) => of(new ActivateCustomerFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  activateSuccess$ = this.actions$.pipe(
    ofType(CustomersActionType.ACTIVATE_CUSTOMER_SUCCESS),
    tap((action: ActivateCustomerSuccess) => {
      this.notificationService.success(this.translationService.translate('SALES.CUSTOMERS.CUSTOMER_ACTIVATED'));
    })
  );

  @Effect({ dispatch: false })
  activateFail$ = this.actions$.pipe(
    ofType(CustomersActionType.ACTIVATE_CUSTOMER_FAIL),
    tap((action: ActivateCustomerFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(this.translationService.translate('SALES.CUSTOMERS.CUSTOMER_NOT_FOUND'));
      } else if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );

  /* ========================= DELETE_CUSTOMER =================================== */
  @Effect()
  delete$ = this.actions$.pipe(
    ofType(CustomersActionType.DELETE_CUSTOMER),
    switchMap((action: DeleteCustomer) =>
      this.customersService.delete(action.payload).pipe(
        map((response) => new DeleteCustomerSuccess(response)),
        catchError((error) => of(new DeleteCustomerFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  deleteSuccess$ = this.actions$.pipe(
    ofType(CustomersActionType.DELETE_CUSTOMER_SUCCESS),
    tap((action: DeleteCustomerSuccess) => {
      this.notificationService.success(this.translationService.translate('SALES.CUSTOMERS.CUSTOMER_DELETED'));
    })
  );

  @Effect({ dispatch: false })
  deleteFail$ = this.actions$.pipe(
    ofType(CustomersActionType.DELETE_CUSTOMER_FAIL),
    tap((action: DeleteCustomerFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(this.translationService.translate('SALES.CUSTOMERS.CUSTOMER_NOT_FOUND'));
      } else if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );

  /* ========================= CANCEL_CUSTOMER_LOG =================================== */
  @Effect()
  cancel$ = this.actions$.pipe(
    ofType(CustomersActionType.CANCEL_CUSTOMER_LOG),
    switchMap((action: CancelCustomerLog) =>
      this.customersService.cancel(action.payload).pipe(
        map((response: any) => new CancelCustomerLogSuccess(response)),
        catchError((error) => of(new CancelCustomerLogFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  cancelSuccess$ = this.actions$.pipe(
    ofType(CustomersActionType.CANCEL_CUSTOMER_LOG_SUCCESS),
    tap((action: CancelCustomerLogSuccess) => {
      this.notificationService.success(this.translationService.translate('SALES.CUSTOMERS.CUSTOMER_LOG_CANCELED'));
    })
  );

  @Effect({ dispatch: false })
  cancelFail$ = this.actions$.pipe(
    ofType(CustomersActionType.CANCEL_CUSTOMER_LOG_FAIL),
    tap((action: CancelCustomerLogFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(this.translationService.translate('SALES.CUSTOMERS.CUSTOMER_LOG_NOT_FOUND'));
      } else if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );
}
