import { JournalsListComponent } from 'finances/components/journals/journals-list/journals-list.component';
import { CreateJournalComponent } from 'finances/components/journals/create-journal/create-journal.component';
import { JournalLineFormComponent } from 'finances/components/journals/journal-line-form/journal-line-form.component';
import { JournalLineFormItemComponent } from 'finances/components/journals/journal-line-form-item/journal-line-form-item.component';
import { ViewJournalComponent } from 'finances/components/journals/view-journal/view-journal.component';
import { JournalDetailsComponent } from 'finances/components/journals/journal-details/journal-details.component';
import { JournalPurchaseInvoiceDetailsComponent } from 'finances/components/journals/journal-purchase-invoice-details/journal-purchase-invoice-details.component';
import { JournalPurchaseReturnDetailsComponent } from 'finances/components/journals/journal-purchase-return-details/journal-purchase-return-details.component';
import { JournalSaleInvoiceDetailsComponent } from 'finances/components/journals/journal-sale-invoice-details/journal-sale-invoice-details.component';
import { JournalSaleReturnsDetailComponent } from 'finances/components/journals/journal-sale-return-details/journal-sale-return-details.component';
import { JournalTransferStockDetailsComponent } from 'finances/components/journals/journal-transfer-stock-details/journal-transfer-stock-details.component';
import { JournalOutgoingStockDetailsComponent } from 'finances/components/journals/journal-outgoing-stock-details/journal-outgoing-stock-details.component';
import { JournalIncomingStockDetailsComponent } from 'finances/components/journals/journal-incoming-stock-details/journal-incoming-stock-details.component';
import { JournalPettyCashRefundRequestDetailsComponent } from 'finances/components/journals/journal-petty-cash-refund-request-details/journal-petty-cash-refund-request-details.component';
import { JournalPettyCashRefundDetailsComponent } from 'finances/components/journals/journal-petty-cash-refund-details/journal-petty-cash-refund-details.component';
import { JournalCustomerLogDetailsComponent } from 'finances/components/journals/journal-customer-log-details/journal-customer-log-details.component';
import { JournalVendorLogDetailsComponent } from 'finances/components/journals/journal-vendor-log-details/journal-vendor-log-details.component';
import { JournalAssetDetailsComponent } from 'finances/components/journals/journal-asset-details/journal-asset-details.component';
import { JournalExcludedAssetDetailsComponent } from 'finances/components/journals/journal-excluded-asset-details/journal-excluded-asset-details.component';
import { JournalAssetDepreciationDetailsComponent } from 'finances/components/journals/journal-asset-depreciation-details/journal-asset-depreciation-details.component';
import { JournalAssetSaleInvoiceDetailsComponent } from 'finances/components/journals/journal-asset-sale-invoice-details/journal-asset-sale-invoice-details.component';
import { JournalBankAccountLogDetailsComponent } from 'finances/components/journals/journal-bank-account-log-details/journal-bank-account-log-details.component';
import { JournalPayrollPaymentDetailsComponent } from 'finances/components/journals/journal-payroll-payment-details/journal-payroll-payment-details.component';
import { JournalAccrualPayrollDetailsComponent } from 'finances/components/journals/journal-accrual-payroll-details/journal-accrual-payroll-details.component';
import { JournalEmployeeLoanDetailsComponent } from 'finances/components/journals/journal-employee-loan-details/journal-employee-loan-details.component';
import { JournalPayrollPaymentRefundDetailsComponent } from 'finances/components/journals/journal-payroll-payment-refund-details/journal-payroll-payment-refund-details.component';
import { JournalGovServiceRequestDetailsComponent } from 'finances/components/journals/journal-gov-service-request-details/journal-gov-service-request-details.component';

export * from 'finances/components/journals/journals-list/journals-list.component';
export * from 'finances/components/journals/create-journal/create-journal.component';
export * from 'finances/components/journals/journal-line-form/journal-line-form.component';
export * from 'finances/components/journals/journal-line-form-item/journal-line-form-item.component';
export * from 'finances/components/journals/view-journal/view-journal.component';
export * from 'finances/components/journals/journal-details/journal-details.component';
export * from 'finances/components/journals/journal-purchase-invoice-details/journal-purchase-invoice-details.component';
export * from 'finances/components/journals/journal-purchase-return-details/journal-purchase-return-details.component';
export * from 'finances/components/journals/journal-sale-invoice-details/journal-sale-invoice-details.component';
export * from 'finances/components/journals/journal-sale-return-details/journal-sale-return-details.component';
export * from 'finances/components/journals/journal-transfer-stock-details/journal-transfer-stock-details.component';
export * from 'finances/components/journals/journal-outgoing-stock-details/journal-outgoing-stock-details.component';
export * from 'finances/components/journals/journal-incoming-stock-details/journal-incoming-stock-details.component';
export * from 'finances/components/journals/journal-petty-cash-refund-request-details/journal-petty-cash-refund-request-details.component';
export * from 'finances/components/journals/journal-petty-cash-refund-details/journal-petty-cash-refund-details.component';
export * from 'finances/components/journals/journal-customer-log-details/journal-customer-log-details.component';
export * from 'finances/components/journals/journal-vendor-log-details/journal-vendor-log-details.component';
export * from 'finances/components/journals/journal-asset-details/journal-asset-details.component';
export * from 'finances/components/journals/journal-excluded-asset-details/journal-excluded-asset-details.component';
export * from 'finances/components/journals/journal-asset-depreciation-details/journal-asset-depreciation-details.component';
export * from 'finances/components/journals/journal-asset-sale-invoice-details/journal-asset-sale-invoice-details.component';
export * from 'finances/components/journals/journal-bank-account-log-details/journal-bank-account-log-details.component';
export * from 'finances/components/journals/journal-accrual-payroll-details/journal-accrual-payroll-details.component';
export * from 'finances/components/journals/journal-payroll-payment-details/journal-payroll-payment-details.component';
export * from 'finances/components/journals/journal-employee-loan-details/journal-employee-loan-details.component';
export * from 'finances/components/journals/journal-payroll-payment-refund-details/journal-payroll-payment-refund-details.component';
export * from 'finances/components/journals/journal-gov-service-request-details/journal-gov-service-request-details.component';

/**
 * The set of components registered on this sub-module.
 */
export const COMPONENTS: any[] = [
  JournalsListComponent,
  CreateJournalComponent,
  JournalLineFormComponent,
  JournalLineFormItemComponent,
  ViewJournalComponent,
  JournalDetailsComponent,
  JournalPurchaseInvoiceDetailsComponent,
  JournalPurchaseReturnDetailsComponent,
  JournalSaleInvoiceDetailsComponent,
  JournalSaleReturnsDetailComponent,
  JournalTransferStockDetailsComponent,
  JournalOutgoingStockDetailsComponent,
  JournalIncomingStockDetailsComponent,
  JournalPettyCashRefundRequestDetailsComponent,
  JournalPettyCashRefundDetailsComponent,
  JournalCustomerLogDetailsComponent,
  JournalVendorLogDetailsComponent,
  JournalAssetDetailsComponent,
  JournalExcludedAssetDetailsComponent,
  JournalAssetSaleInvoiceDetailsComponent,
  JournalAssetDepreciationDetailsComponent,
  JournalBankAccountLogDetailsComponent,
  JournalPayrollPaymentDetailsComponent,
  JournalAccrualPayrollDetailsComponent,
  JournalEmployeeLoanDetailsComponent,
  JournalPayrollPaymentRefundDetailsComponent,
  JournalGovServiceRequestDetailsComponent,
];
