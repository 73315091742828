import { createSelector } from '@ngrx/store';

import { selectLookupsState } from 'lookups/store/selectors/feature.selectors';
import { LookupsState, SaleInvoiceTypeState } from 'lookups/store/states';

/**
 * Gets the sale invoice type state.
 */
const selectSaleInvoiceTypes = createSelector(selectLookupsState, (state: LookupsState) => state.saleInvoiceTypes);

/**
 * Gets the list of loaded sale invoice types.
 */
export const getSaleInvoiceTypes = createSelector(selectSaleInvoiceTypes, (state: SaleInvoiceTypeState) => state.data);

/**
 * Gets the error that occurred in the state.
 */
export const getSaleInvoiceTypesError = createSelector(
  selectSaleInvoiceTypes,
  (state: SaleInvoiceTypeState) => state.error
);

/**
 * Determines if there is a running load process.
 */
export const getSaleInvoiceTypesIsLoading = createSelector(
  selectSaleInvoiceTypes,
  (state: SaleInvoiceTypeState) => state.isLoading
);

/**
 * Determines if the last load process has been ended successfully.
 */
export const getSaleInvoiceTypesIsLoaded = createSelector(
  selectSaleInvoiceTypes,
  (state: SaleInvoiceTypeState) => state.isLoaded
);
