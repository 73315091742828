import * as fromCustomers from 'app-search/components/customers';
import * as fromEmployees from 'app-search/components/employees';
import * as fromDepartments from 'app-search/components/departments';
import * as fromLocations from 'app-search/components/locations';
import * as fromBankAccounts from 'app-search/components/bank-accounts';
import * as fromBanks from 'app-search/components/banks';
import * as fromOrganizationUsers from 'app-search/components/organization-users';
import * as fromVendors from 'app-search/components/vendors';
import * as fromPosDevices from 'app-search/components/pos-devices';
import * as fromProjectSubTasks from 'app-search/components/sub-tasks';
import * as fromProjects from 'app-search/components/projects';
import * as fromGovServices from 'app-search/components/gov-services';
import * as fromRegions from 'app-search/components/regions';
import * as fromSectors from 'app-search/components/sectors';

export * from 'app-search/components/customers';
export * from 'app-search/components/employees';
export * from 'app-search/components/departments';
export * from 'app-search/components/locations';
export * from 'app-search/components/bank-accounts';
export * from 'app-search/components/banks';
export * from 'app-search/components/organization-users';
export * from 'app-search/components/vendors';
export * from 'app-search/components/pos-devices';
export * from 'app-search/components/sub-tasks';
export * from 'app-search/components/projects';
export * from 'app-search/components/gov-services';
export * from 'app-search/components/regions';
export * from 'app-search/components/sectors';

/**
 * The set of components registered on this module.
 */
export const COMPONENTS: any[] = [
  ...fromCustomers.COMPONENTS,
  ...fromEmployees.COMPONENTS,
  ...fromDepartments.COMPONENTS,
  ...fromLocations.COMPONENTS,
  ...fromBankAccounts.COMPONENTS,
  ...fromBanks.COMPONENTS,
  ...fromOrganizationUsers.COMPONENTS,
  ...fromVendors.COMPONENTS,
  ...fromPosDevices.COMPONENTS,
  ...fromProjectSubTasks.COMPONENTS,
  ...fromProjects.COMPONENTS,
  ...fromGovServices.COMPONENTS,
  ...fromRegions.COMPONENTS,
  ...fromSectors.COMPONENTS,
];
