<!-- Sidebar Header starts -->
<div class="sidebar-header">
  <div class="logo clearfix">
    <a [routerLink]="['/']" class="logo-text float-left">
      <div *ngIf="environmentConfig.softwareSettings.app.logoUrl" class="logo-img">

        <img  *ngIf="expanded" [src]="environmentConfig.softwareSettings.app.logoUrl" />
        <img  *ngIf="!expanded" [src]="environmentConfig.softwareSettings.app.iconUrl" />
        <!-- <span class="text erp-text">

                  {{ environmentConfig.softwareSettings.app.logoText }}

                </span> -->

      </div>

    </a>
    <a class="nav-toggle d-none d-sm-none d-md-none d-lg-block" id="sidebarToggle"   (click)="toggleSlideInOut()" href="javascript:;">
      <i #toggleIcon appSidebarToggle class="ft-toggle-right toggle-icon" data-toggle="expanded"></i>
    </a>
    <a class="nav-close d-block d-md-block d-lg-none d-xl-none" id="sidebarClose" href="javascript:;">
      <i class="ft-x"></i>
    </a>
  </div>
</div>
<!-- Sidebar Header Ends -->

<!-- Sidebar Content starts -->
<div class="sidebar-content" [perfectScrollbar]>
  <div class="nav-container">
    <ul class="navigation" appSidebarList>
      <!-- First level menu -->
      <!-- (click)="toggleSlideInOut()" -->
      <li
        appSidebarLink
        level="{{ depth + 1 }}"
        (toggleEmit)="handleToggle($event)"

        [routePath]="menuItem.route"
        [classes]="menuItem.class"
        [title]="menuItem.title"
        [parent]=""
        *ngFor="let menuItem of menuItems"
        [ngClass]="{
          'has-sub': menuItem.class === 'has-sub' ? true : false,
          open: activeTitles.includes(menuItem.title) && !navCollapsedOpen ? true : false,
          'nav-collapsed-open': navCollapsedOpen && activeTitles.includes(menuItem.title)
        }"
        [routerLinkActive]="menuItem.subMenu.length != 0 ? '' : 'active'"
        [routerLinkActiveOptions]="{ exact: true }"
      >
        <ng-container *ngIf="menuItem.claims | authorize | async">
          <a
            appSidebarAnchorToggle
            [routerLink]="menuItem.class === '' ? [menuItem.route] : null"
            *ngIf="!menuItem.isExternalLink; else externalLinkBlock"
          >
            <i [ngClass]="[menuItem.icon]"></i>
            <span class="menu-title">{{ menuItem.title | translate }}</span>
            <span *ngIf="menuItem.badge != ''" [ngClass]="[menuItem.badgeClass]">{{ menuItem.badge }}</span>
          </a>
          <ng-template #externalLinkBlock>
            <a [href]="[menuItem.route]" target="_blank">
              <i [ngClass]="[menuItem.icon]"></i>
              <span class="menu-title">{{ menuItem.title | translate }}</span>
              <span *ngIf="menuItem.badge != ''" [ngClass]="[menuItem.badgeClass]">{{ menuItem.badge }}</span>
            </a>
          </ng-template>
          <!-- Second level menu -->
          <ul
            class="menu-content"
            *ngIf="menuItem.subMenu.length > 0"
            [@slideInOut]="activeTitles.includes(menuItem.title) ? true : false"
          >
            <li
              appSidebarLink
              level="{{ depth + 2 }}"
              (toggleEmit)="handleToggle($event)"
              [routePath]="menuSubItem.route"
              [classes]="menuSubItem.class"
              [title]="menuSubItem.title"
              [parent]="menuItem.title"
              *ngFor="let menuSubItem of menuItem.subMenu"
              [routerLinkActive]="menuSubItem.subMenu.length > 0 ? '' : 'active'"
              [ngClass]="{
                'has-sub': menuSubItem.class === 'has-sub' ? true : false,
                open: activeTitles.includes(menuSubItem.title) && !navCollapsedOpen ? true : false,
                'nav-collapsed-open': navCollapsedOpen && activeTitles.includes(menuSubItem.title)
              }"
            >
              <ng-container *ngIf="menuSubItem.claims | authorize | async">
                <a
                  appSidebarAnchorToggle
                  [routerLink]="menuSubItem.subMenu.length > 0 ? null : [menuSubItem.route]"
                  *ngIf="!menuSubItem.isExternalLink; else externalSubLinkBlock"
                >
                  <i [ngClass]="[menuSubItem.icon]"></i>
                  <span class="menu-title">{{ menuSubItem.title | translate }}</span>
                  <span *ngIf="menuSubItem.badge != ''" [ngClass]="[menuSubItem.badgeClass]">{{
                    menuSubItem.badge
                  }}</span>
                </a>
                <ng-template #externalSubLinkBlock>
                  <a [href]="[menuSubItem.route]">
                    <i [ngClass]="[menuSubItem.icon]"></i>
                    <span class="menu-title">{{ menuSubItem.title | translate }}</span>
                    <span *ngIf="menuSubItem.badge != ''" [ngClass]="[menuSubItem.badgeClass]">{{
                      menuSubItem.badge
                    }}</span>
                  </a>
                </ng-template>
                <!-- Third level menu -->
                <ul
                  class="menu-content"
                  *ngIf="menuSubItem.subMenu.length > 0"
                  [@slideInOut]="activeTitles.includes(menuSubItem.title) ? true : false"
                >
                  <li
                    appSidebarLink
                    level="{{ depth + 3 }}"
                    [routePath]="menuSubsubItem.route"
                    [classes]="menuSubsubItem.class"
                    [title]="menuSubsubItem.title"
                    [parent]="menuSubItem.title"
                    *ngFor="let menuSubsubItem of menuSubItem.subMenu"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: true }"
                    [ngClass]="[menuSubsubItem.class]"
                  >
                    <ng-container *ngIf="menuSubsubItem.claims | authorize | async">
                      <a
                        appSidebarAnchorToggle
                        [routerLink]="[menuSubsubItem.route]"
                        *ngIf="!menuSubsubItem.isExternalLink; else externalSubSubLinkBlock"
                      >
                        <i [ngClass]="[menuSubsubItem.icon]"></i>
                        <span class="menu-title">{{ menuSubsubItem.title | translate }}</span>
                        <span *ngIf="menuSubsubItem.badge != ''" [ngClass]="[menuSubsubItem.badgeClass]">{{
                          menuSubsubItem.badge
                        }}</span>
                      </a>
                      <ng-template #externalSubSubLinkBlock>
                        <a [href]="[menuSubsubItem.route]">
                          <i [ngClass]="[menuSubsubItem.icon]"></i>
                          <span class="menu-title">{{ menuSubsubItem.title }}</span>
                          <span *ngIf="menuSubsubItem.badge != ''" [ngClass]="[menuSubsubItem.badgeClass]">{{
                            menuSubsubItem.badge
                          }}</span>
                        </a>
                      </ng-template>
                    </ng-container>
                  </li>
                </ul>
              </ng-container>
            </li>
          </ul>
        </ng-container>
      </li>
    </ul>
  </div>
</div>
<!-- Sidebar Content Ends -->
