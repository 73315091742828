import { createSelector } from '@ngrx/store';

import { selectLookupsState } from 'lookups/store/selectors/feature.selectors';
import { LookupsState, IdentityTypeState } from 'lookups/store/states';

/**
 * Gets the identityType state.
 */
const selectIdentityTypes = createSelector(selectLookupsState, (state: LookupsState) => state.identityTypes);

/**
 * Gets the list of loaded identityTypes.
 */
export const getIdentityTypes = createSelector(selectIdentityTypes, (state: IdentityTypeState) => state.data);

/**
 * Gets the error that occurred in the state.
 */
export const getIdentityTypesError = createSelector(selectIdentityTypes, (state: IdentityTypeState) => state.error);

/**
 * Determines if there is a running load process.
 */
export const getIdentityTypesIsLoading = createSelector(
  selectIdentityTypes,
  (state: IdentityTypeState) => state.isLoading
);

/**
 * Determines if the last load process has been ended successfully.
 */
export const getIdentityTypesIsLoaded = createSelector(
  selectIdentityTypes,
  (state: IdentityTypeState) => state.isLoaded
);
