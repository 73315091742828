import { MyShiftsListComponent } from 'sales/components/shifts/my-shifts-list/my-shifts-list.component';
import { ShiftsListComponent } from 'sales/components/shifts/shifts-list/shifts-list.component';
import { ShiftsSearchComponent } from 'sales/components/shifts/shifts-search/shifts-search.component';

export * from 'sales/components/shifts/my-shifts-list/my-shifts-list.component';
export * from 'sales/components/shifts/shifts-list/shifts-list.component';
export * from 'sales/components/shifts/shifts-search/shifts-search.component';

/**
 * The set of components registered on this sub-module.
 */
export const COMPONENTS: any[] = [MyShiftsListComponent, ShiftsListComponent, ShiftsSearchComponent];
