<ng-template #modalRef let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title text-primary" id="modal-basic-title">
      {{ 'FINANCES.COST_CENTERS.CONFIRM_CREATE_COST_CENTER_TITLE' | translate }}
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="d(); closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <!-- create cost center form starts -->
    <div class="row">
      <div class="col-md-12">
        <form [formGroup]="form" (ngSubmit)="submit()">
          <div class="card">
            <div class="card-header mb-3">
              <h4 class="card-title">{{ 'FINANCES.COST_CENTERS.COST_CENTER_DATA_TITLE' | translate }}</h4>
            </div>
            <div class="card-content">
              <div class="px-3">
                <div class="row">
                  <div class="form-group col-md-6">
                    <label for="code">{{
                      'FINANCES.COST_CENTERS.COST_CENTER_DATA.COST_CENTER_CODE' | translate
                    }}</label>
                    <input
                      type="text"
                      id="code"
                      class="form-control round"
                      [placeholder]="'FINANCES.COST_CENTERS.COST_CENTER_DATA.COST_CENTER_CODE_PLACEHOLDER' | translate"
                      maxlength="50"
                      formControlName="code"
                    />
                  </div>
                  <div class="form-group col-md-6">
                    <label for="description">{{
                      'FINANCES.COST_CENTERS.COST_CENTER_DATA.COST_CENTER_ARABIC_DESCRIPTION' | translate
                    }}</label>
                    <input
                      type="text"
                      id="description"
                      class="form-control round"
                      [placeholder]="
                        'FINANCES.COST_CENTERS.COST_CENTER_DATA.COST_CENTER_ARABIC_DESCRIPTION_PLACEHOLDER' | translate
                      "
                      maxlength="50"
                      formControlName="description"
                    />
                  </div>
                  <div class="form-group col-md-6">
                    <label for="descriptionEn">{{
                      'FINANCES.COST_CENTERS.COST_CENTER_DATA.COST_CENTER_ENGLISH_DESCRIPTION' | translate
                    }}</label>
                    <input
                      type="text"
                      id="descriptionEn"
                      class="form-control round"
                      [placeholder]="
                        'FINANCES.COST_CENTERS.COST_CENTER_DATA.COST_CENTER_ENGLISH_DESCRIPTION_PLACEHOLDER' | translate
                      "
                      maxlength="50"
                      formControlName="descriptionEn"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
    <!-- create cost center form ends -->
  </div>
  <div class="modal-footer">
    <div class="modal-actions">
      <button
        type="button"
        class="btn btn-raised btn-success round"
        (click)="submit()"
        ngbAutofocus
        [disabled]="isCreating$ | async"
      >
        <i class="fa fa-check"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.SAVE' | translate }} </span>
      </button>
      <button type="button" class="btn btn-outline-dark round" (click)="c(); closeModal()">
        <i class="fa fa-times"></i>
        <span class="mx-1"> {{ 'SHARED.CONFIRM.CANCEL' | translate }} </span>
      </button>
    </div>
  </div>
</ng-template>

<!-- confirm modal starts -->
<ng-template #confirmModalRef let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
      {{ 'FINANCES.COST_CENTERS.CONFIRM_CREATE_COST_CENTER_TITLE' | translate }}
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="d()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p class="text-success">{{ 'FINANCES.COST_CENTERS.CONFIRM_CREATE_COST_CENTER_MESSAGE' | translate }}</p>
  </div>
  <div class="modal-footer">
    <div class="modal-actions">
      <button type="button" class="btn btn-raised btn-success round" (click)="c(); confirm()" ngbAutofocus>
        <i class="fa fa-check"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.SAVE' | translate }}</span>
      </button>
      <button type="button" class="btn btn-outline-dark round" (click)="c()">
        <i class="fa fa-times"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.CANCEL' | translate }}</span>
      </button>
    </div>
  </div>
</ng-template>
<!-- confirm modal ends -->
