import { Action } from '@ngrx/store';

import { AppHttpResponse } from 'shared';
import { CustomerLogType } from 'lookups/models';

/**
 * The Lookups-Module store customer payment log type action types.
 */
export enum CustomerPaymentLogTypeActionType {
  GET_CUSTOMER_PAYMENT_LOG_TYPES = '[Lookups] [CustomerPaymentLogTypes] Get Customer Payment Log Types',
  GET_CUSTOMER_PAYMENT_LOG_TYPES_FAIL = '[Lookups] [CustomerPaymentLogTypes] Get Customer Payment Log Types Fail',
  GET_CUSTOMER_PAYMENT_LOG_TYPES_SUCCESS = '[Lookups] [CustomerPaymentLogTypes] Get Customer Payment Log Types Success',

  GET_ALL_CUSTOMER_PAYMENT_LOG_TYPES = '[Lookups] [CustomerPaymentLogTypes] Get All Customer Payment Log Types',
  GET_ALL_CUSTOMER_PAYMENT_LOG_TYPES_FAIL = '[Lookups] [CustomerPaymentLogTypes] Get All Customer Payment Log Types Fail',
  GET_ALL_CUSTOMER_PAYMENT_LOG_TYPES_SUCCESS = '[Lookups] [CustomerPaymentLogTypes] Get All Customer Payment Log Types Success',
}

/**
 * Represents a store customer payment log types action.
 */
export class GetCustomerPaymentLogTypes implements Action {
  /**
   * The type of the action.
   */
  readonly type = CustomerPaymentLogTypeActionType.GET_CUSTOMER_PAYMENT_LOG_TYPES;

  /**
   * Fires a new customer payment log types action.
   */
  constructor() {}
}

/**
 * Represents a store customer payment log types fail action.
 */
export class GetCustomerPaymentLogTypesFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = CustomerPaymentLogTypeActionType.GET_CUSTOMER_PAYMENT_LOG_TYPES_FAIL;

  /**
   * Fires a new customer payment log type fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store customer payment log types success action.
 */
export class GetCustomerPaymentLogTypesSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = CustomerPaymentLogTypeActionType.GET_CUSTOMER_PAYMENT_LOG_TYPES_SUCCESS;

  /**
   * Fires a customer payment log type success action.
   * @param payload An object contains the list of customer payment log types.
   */
  constructor(public payload: AppHttpResponse<CustomerLogType[]>) {}
}

/**
 * Represents a store customer payment log types action.
 */
export class GetAllCustomerPaymentLogTypes implements Action {
  /**
   * The type of the action.
   */
  readonly type = CustomerPaymentLogTypeActionType.GET_ALL_CUSTOMER_PAYMENT_LOG_TYPES;

  /**
   * Fires a new customer payment log types action.
   */
  constructor() {}
}

/**
 * Represents a store customer payment log types fail action.
 */
export class GetAllCustomerPaymentLogTypesFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = CustomerPaymentLogTypeActionType.GET_ALL_CUSTOMER_PAYMENT_LOG_TYPES_FAIL;

  /**
   * Fires a new customer payment log type fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store customer payment log types success action.
 */
export class GetAllCustomerPaymentLogTypesSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = CustomerPaymentLogTypeActionType.GET_ALL_CUSTOMER_PAYMENT_LOG_TYPES_SUCCESS;

  /**
   * Fires a customer payment log type success action.
   * @param payload An object contains the list of customer payment log types.
   */
  constructor(public payload: AppHttpResponse<CustomerLogType[]>) {}
}

/**
 * Lookups-module customer payment log types action types.
 */
export type CustomerPaymentLogTypeActions =
  | GetCustomerPaymentLogTypes
  | GetCustomerPaymentLogTypesFail
  | GetCustomerPaymentLogTypesSuccess
  | GetAllCustomerPaymentLogTypes
  | GetAllCustomerPaymentLogTypesFail
  | GetAllCustomerPaymentLogTypesSuccess;
