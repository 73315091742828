import { Injectable } from '@angular/core';

import { of } from 'rxjs';
import { switchMap, map, catchError } from 'rxjs/operators';
import { Effect, ofType, Actions } from '@ngrx/effects';

import { LookupsService } from 'lookups/services';
import {
  SaleInvoiceTypeActionType,
  GetAllSaleInvoiceTypeFail,
  GetAllSaleInvoiceTypeSuccess,
} from 'lookups/store/actions';

@Injectable()
export class SaleInvoiceTypeEffects {
  constructor(private actions$: Actions, private lookupsService: LookupsService) {}

  /* ========================= GET_ALL_SALE_INVOICE_TYPE =================================== */
  @Effect()
  getAllSaleInvoiceTypes$ = this.actions$.pipe(
    ofType(SaleInvoiceTypeActionType.GET_ALL_SALE_INVOICE_TYPES),
    switchMap(() =>
      this.lookupsService.getAllSaleInvoiceTypes().pipe(
        map((response) => new GetAllSaleInvoiceTypeSuccess(response)),
        catchError((error) => of(new GetAllSaleInvoiceTypeFail(error)))
      )
    )
  );
}
