import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';

import { AppHttpResponse } from 'shared';
import {
  CreateProjectInput,
  UpdateProjectInput,
  Project,
  ProjectStatusLog,
  ProjectStatusLogInput,
  UpdateProjectPlanningResource,
  ProjectPlanningResourceFormItem,
  CreateProjectMainTaskInput,
  CreateProjectSubTaskInput,
  UpdateProjectMainTaskInput,
  UpdateProjectSubTaskInput,
  ProjectSubTaskResponse,
  ProjectMainTaskResponse,
  ProjectLog,
  ProjectResource,
  ProjectMainTask,
  ProjectSubTask,
  ProjectRevenue,
} from 'projects-management/models';

/**
 * The projects services includes functionality to create, search, findById, update and delete for an project.
 */
@Injectable()
export class ProjectsService {
  /**
   * The relative route for the projects.
   *
   * No leading or trailing slashes required.
   */
  private projectsApi = 'projects-management/projects';

  constructor(private http: HttpClient) {}

  /**
   * Creates a new project from the provided data.
   * @param data The new project data.
   */
  public create(data: CreateProjectInput): Observable<AppHttpResponse<Project>> {
    return this.http.post<AppHttpResponse<Project>>(`${this.projectsApi}`, data);
  }

  /**
   * Searches in the projects by description,locations,projectStatuses and customers.
   * @param description The description of the project.
   * @param locations The locations of the project.
   * @param projectStatuses The projectStatuses of the project.
   * @param customers The customers of the project.
   * @param page The current pagination page number.
   * @param pageSize The maximum number of projects allowed per one pagination page.
   */
  public search(
    description: string,
    locations: number[],
    projectStatuses: number[],
    customers: number[],
    page: number,
    pageSize: number
  ): Observable<AppHttpResponse<Project[]>> {
    const params = new HttpParams()
      .set('description', description)
      .set('locations', locations.join(','))
      .set('projectStatuses', projectStatuses.join(','))
      .set('customers', customers.join(','))
      .set('page', page.toString())
      .set('pageSize', pageSize.toString());
    return this.http.get<AppHttpResponse<Project[]>>(`${this.projectsApi}`, { params });
  }

  /**
   * Searches in the main tasks by description and projects.
   * @param description The description of the main task.
   * @param projects The projects of the main task.
   * @param page The current pagination page number.
   * @param pageSize The maximum number of main tasks allowed per one pagination page.
   */
  public searchMainTasks(
    description: string,
    projects: number[],
    page: number,
    pageSize: number
  ): Observable<AppHttpResponse<ProjectMainTask[]>> {
    const params = new HttpParams()
      .set('description', description)
      .set('projects', projects.join(','))
      .set('page', page.toString())
      .set('pageSize', pageSize.toString());
    return this.http.get<AppHttpResponse<ProjectMainTask[]>>(`${this.projectsApi}/main-tasks`, { params });
  }

  /**
   * Searches in the sub tasks by description and projects.
   * @param description The description of the sub task.
   * @param projects The projects of the sub task.
   * @param page The current pagination page number.
   * @param pageSize The maximum number of sub tasks allowed per one pagination page.
   */
  public searchSubTasks(
    description: string,
    projects: number[],
    page: number,
    pageSize: number
  ): Observable<AppHttpResponse<ProjectSubTask[]>> {
    const params = new HttpParams()
      .set('description', description)
      .set('projects', projects.join(','))
      .set('page', page.toString())
      .set('pageSize', pageSize.toString());
    return this.http.get<AppHttpResponse<ProjectSubTask[]>>(`${this.projectsApi}/sub-tasks`, { params });
  }

  /**
   * Searches in the project status log by fromDate and toDate.
   * @param projectId The id of the project of the project log.
   * @param fromDate The fromDate of the project status log.
   * @param toDate The toDate of the project status log.
   * @param page The current pagination page number.
   * @param pageSize The maximum number of project status log allowed per one pagination page.
   */
  public searchProjectStatusLogs(
    projectId: number,
    fromDate: Date,
    toDate: Date,
    page: number,
    pageSize: number
  ): Observable<AppHttpResponse<ProjectStatusLog[]>> {
    const params = new HttpParams()
      .set('projectId', projectId?.toString())
      .set('fromDate', fromDate?.toISOString())
      .set('toDate', toDate?.toISOString())
      .set('page', page.toString())
      .set('pageSize', pageSize.toString());
    return this.http.get<AppHttpResponse<ProjectStatusLog[]>>(`${this.projectsApi}/logs`, { params });
  }

  /**
   * Searches in the project log by fromDate and toDate.
   * @param projectLogTypes The project log types of the project log.
   * @param fromDate The fromDate of the project log.
   * @param toDate The toDate of the project log.
   * @param page The current pagination page number.
   * @param pageSize The maximum number of project log allowed per one pagination page.
   */
  public searchProjectLogs(
    projectId: number,
    projectLogTypes: number[],
    fromDate: Date,
    toDate: Date,
    page: number,
    pageSize: number
  ): Observable<AppHttpResponse<ProjectLog[]>> {
    const params = new HttpParams()
      .set('projectId', projectId.toString())
      .set('projectLogTypes', projectLogTypes.join(','))
      .set('fromDate', fromDate?.toISOString())
      .set('toDate', toDate?.toISOString())
      .set('page', page.toString())
      .set('pageSize', pageSize.toString());
    return this.http.get<AppHttpResponse<ProjectLog[]>>(`${this.projectsApi}/project-plan/logs`, {
      params,
    });
  }

  /**
   * Searches in the project revenue by fromDate and toDate.
   * @param projectRevenueTypes The project revenue types of the project revenue.
   * @param fromDate The fromDate of the project revenue.
   * @param toDate The toDate of the project revenue.
   * @param page The current pagination page number.
   * @param pageSize The maximum number of project revenue allowed per one pagination page.
   */
  public searchProjectRevenues(
    projectId: number,
    projectRevenueTypes: number[],
    fromDate: Date,
    toDate: Date,
    page: number,
    pageSize: number
  ): Observable<AppHttpResponse<ProjectRevenue[]>> {
    const params = new HttpParams()
      .set('projectId', projectId.toString())
      .set('projectRevenueTypes', projectRevenueTypes.join(','))
      .set('fromDate', fromDate?.toISOString())
      .set('toDate', toDate?.toISOString())
      .set('page', page.toString())
      .set('pageSize', pageSize.toString());
    return this.http.get<AppHttpResponse<ProjectRevenue[]>>(`${this.projectsApi}/revenues`, {
      params,
    });
  }

  /**
   * Searches in the project resource by fromDate and toDate.
   * @param projectId The project id of the project resource.
   * @param projectMainTasks The project main tasks of the project resource.
   * @param projectSubTasks The project sub tasks of the project resource.
   * @param projectResourceTypes The project resource types of the project resource.
   * @param fromDate The fromDate of the project resource.
   * @param toDate The toDate of the project resource.
   * @param page The current pagination page number.
   * @param pageSize The maximum number of project resource allowed per one pagination page.
   */
  public searchProjectResources(
    projectId: number,
    projectMainTasks: number[],
    projectSubTasks: number[],
    projectResourceTypes: number[],
    fromDate: Date,
    toDate: Date,
    page: number,
    pageSize: number
  ): Observable<AppHttpResponse<ProjectResource[]>> {
    const params = new HttpParams()
      .set('projectId', projectId.toString())
      .set('projectMainTasks', projectMainTasks.join(','))
      .set('projectSubTasks', projectSubTasks.join(','))
      .set('projectResourceTypes', projectResourceTypes.join(','))
      .set('fromDate', fromDate?.toISOString())
      .set('toDate', toDate?.toISOString())
      .set('page', page.toString())
      .set('pageSize', pageSize.toString());
    return this.http.get<AppHttpResponse<ProjectResource[]>>(`${this.projectsApi}/project-resources`, {
      params,
    });
  }

  /**
   * Creates a new project status log by cheque deposit from the provided data.
   * @param data The new project status log data.
   */
  public createProjectStatusLog(data: ProjectStatusLogInput): Observable<AppHttpResponse<ProjectStatusLog>> {
    const formData: any = new FormData();
    formData.append('projectId', data.projectId);
    formData.append('projectStatusId', data.projectStatusId);
    formData.append('transactionDate', data.transactionDate?.toISOString());
    formData.append('notes', data.notes);

    /**
     * Append attachments to the form data.
     */
    data.attachments.forEach((attachment) => formData.append(`attachments[]`, attachment, attachment.name));

    return this.http.post<AppHttpResponse<ProjectStatusLog>>(`${this.projectsApi}/change-status`, formData);
  }

  /**
   * Finds the project with the given id.
   * @param projectId The id of the project.
   */
  public findById(projectId: number): Observable<AppHttpResponse<Project>> {
    return this.http.get<AppHttpResponse<Project>>(`${this.projectsApi}/${projectId}`);
  }

  /**
   * Finds the project revenue with the given id.
   * @param projectRevenueId The id of the project revenue.
   */
  public findProjectRevenueById(projectRevenueId: number): Observable<AppHttpResponse<ProjectRevenue>> {
    return this.http.get<AppHttpResponse<ProjectRevenue>>(`${this.projectsApi}/revenues/${projectRevenueId}`);
  }

  /**
   * Updates an existing project data using the provided data.
   * @param data The updated project data.
   */
  public update(data: UpdateProjectInput): Observable<AppHttpResponse<Project>> {
    return this.http.put<AppHttpResponse<Project>>(`${this.projectsApi}`, data);
  }

  /**
   * Updates an existing project main task data using the provided data.
   * @param data The updated project main task data.
   */
  public updateProjectMainTask(data: UpdateProjectMainTaskInput): Observable<AppHttpResponse<ProjectMainTaskResponse>> {
    return this.http.put<AppHttpResponse<ProjectMainTaskResponse>>(
      `${this.projectsApi}/project-plan/project-main-task`,
      data
    );
  }

  /**
   * Updates an existing project sub task data using the provided data.
   * @param data The updated project main task data.
   */
  public updateProjectSubTask(data: UpdateProjectSubTaskInput): Observable<AppHttpResponse<ProjectSubTaskResponse>> {
    return this.http.put<AppHttpResponse<ProjectSubTaskResponse>>(
      `${this.projectsApi}/project-plan/project-sub-task`,
      data
    );
  }

  /**
   * Deletes the project by given id.
   * @param projectId The id of the project.
   */
  public delete(projectId: number): Observable<AppHttpResponse<Project>> {
    return this.http.delete<AppHttpResponse<Project>>(`${this.projectsApi}/${projectId}`);
  }

  /**
   * Deletes the project main task by given id.
   * @param projectMainTaskId The id of the project.
   */
  public deleteProjectMainTask(projectMainTaskId: number): Observable<AppHttpResponse<ProjectMainTaskResponse>> {
    return this.http.delete<AppHttpResponse<ProjectMainTaskResponse>>(
      `${this.projectsApi}/project-plan/project-main-task/${projectMainTaskId}`
    );
  }

  /**
   * Deletes the project sub task by given id.
   * @param projectSubTaskId The id of the project.
   */
  public deleteProjectSubTask(projectSubTaskId: number): Observable<AppHttpResponse<ProjectSubTaskResponse>> {
    return this.http.delete<AppHttpResponse<ProjectSubTaskResponse>>(
      `${this.projectsApi}/project-plan/project-sub-task/${projectSubTaskId}`
    );
  }

  /**
   * Creates a new project planning resources from the provided data.
   * @param data The new project planning resources data.
   */
  public createProjectPlanningResource(
    data: UpdateProjectPlanningResource
  ): Observable<AppHttpResponse<ProjectPlanningResourceFormItem>> {
    return this.http.post<AppHttpResponse<ProjectPlanningResourceFormItem>>(
      `${this.projectsApi}/planning-resource-types`,
      data
    );
  }

  /**
   * Creates a new project main task from the provided data.
   * @param data The new project main data.
   */
  public createProjectMainTask(data: CreateProjectMainTaskInput): Observable<AppHttpResponse<ProjectMainTaskResponse>> {
    return this.http.post<AppHttpResponse<ProjectMainTaskResponse>>(
      `${this.projectsApi}/project-plan/project-main-task`,
      data
    );
  }

  /**
   * Creates a new project sub task from the provided data.
   * @param data The new project sub task data.
   */
  public createProjectSubTask(data: CreateProjectSubTaskInput): Observable<AppHttpResponse<ProjectSubTaskResponse>> {
    return this.http.post<AppHttpResponse<ProjectSubTaskResponse>>(
      `${this.projectsApi}/project-plan/project-sub-task`,
      data
    );
  }

  /**
   * Finds the project resource with the given id.
   * @param projectResourceId The id of the project resource.
   */
  public findProjectResourceById(projectResourceId: number): Observable<AppHttpResponse<ProjectResource>> {
    return this.http.get<AppHttpResponse<ProjectResource>>(
      `${this.projectsApi}/project-resources/${projectResourceId}`
    );
  }

  /**
   * Deletes the project planning resources by given id.
   * @param projectPlanningResourceId The id of the project planning resources.
   */
  public deleteProjectPlanningResource(
    projectPlanningResourceId: number
  ): Observable<AppHttpResponse<ProjectPlanningResourceFormItem>> {
    return this.http.delete<AppHttpResponse<ProjectPlanningResourceFormItem>>(
      `${this.projectsApi}/planning-resource-types/${projectPlanningResourceId}`
    );
  }
}
