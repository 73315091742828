import { createSelector } from '@ngrx/store';

import { StoresState, ProductClassState } from 'stores/store/states';
import { selectStoresState } from 'stores/store/selectors/feature.selectors';

/**
 * Gets the product classes state.
 */
const selectProductClassState = createSelector(selectStoresState, (state: StoresState) => state.productClasses);

/**
 * Gets the list of loaded product classes.
 */
export const getProductClasses = createSelector(selectProductClassState, (state: ProductClassState) => state.data);

/**
 * Gets the list of loaded product classes for sales screen.
 */
export const getProductClassesForSalesScreen = createSelector(
  selectProductClassState,
  (state: ProductClassState) => state.productClassesForSalesScreen
);

/**
 * Gets the product classes pagination info.
 */
export const getProductClassesPaginationInfo = createSelector(
  selectProductClassState,
  (state: ProductClassState) => state.paginationInfo
);

/**
 * Gets the error that occurred in the state.
 */
export const getProductClassesError = createSelector(
  selectProductClassState,
  (state: ProductClassState) => state.error
);

/**
 * Determines if there is a running search process.
 */
export const getProductClassesSearching = createSelector(
  selectProductClassState,
  (state: ProductClassState) => state.isSearching
);

/**
 * Determines if the last search process has been ended successfully.
 */
export const getProductClassesSearchCompleted = createSelector(
  selectProductClassState,
  (state: ProductClassState) => state.isSearchCompleted
);

/**
 * Determines if there is a running search product classes for sales screen process.
 */
export const getProductClassesForSalesScreenSearching = createSelector(
  selectProductClassState,
  (state: ProductClassState) => state.isProductClassesForSalesScreenSearching
);

/**
 * Determines if the last search product classes for sales screen process has been ended successfully.
 */
export const getProductClassesForSalesScreenSearchCompleted = createSelector(
  selectProductClassState,
  (state: ProductClassState) => state.isProductClassesForSalesScreenSearchCompleted
);

/**
 * Gets the selected product class.
 */
export const getSelectedProductClass = createSelector(
  selectProductClassState,
  (state: ProductClassState) => state.selectedProductClass
);

/**
 * Determines if there is a running find-product-class process.
 */
export const getProductClassesFinding = createSelector(
  selectProductClassState,
  (state: ProductClassState) => state.isFinding
);

/**
 * Determines if the last find-product-class process has been ended successfully.
 */
export const getProductClassesFindCompleted = createSelector(
  selectProductClassState,
  (state: ProductClassState) => state.isFindCompleted
);

/**
 * Determines if there is a running create process.
 */
export const getSelectedProductClassCreating = createSelector(
  selectProductClassState,
  (state: ProductClassState) => state.isCreating
);

/**
 * Determines if the last create process has been ended successfully.
 */
export const getSelectedProductClassCreateCompleted = createSelector(
  selectProductClassState,
  (state: ProductClassState) => state.isCreateCompleted
);

/**
 * Determines if there is a running update process.
 */
export const getSelectedProductClassUpdating = createSelector(
  selectProductClassState,
  (state: ProductClassState) => state.isUpdating
);

/**
 * Determines if the last update process has been ended successfully.
 */
export const getSelectedProductClassUpdateCompleted = createSelector(
  selectProductClassState,
  (state: ProductClassState) => state.isUpdateCompleted
);

/**
 * Determines if there is a running delete process.
 */
export const getSelectedProductClassDeleting = createSelector(
  selectProductClassState,
  (state: ProductClassState) => state.isDeleting
);

/**
 * Determines if the last delete process has been ended successfully.
 */
export const getSelectedProductClassDeleteCompleted = createSelector(
  selectProductClassState,
  (state: ProductClassState) => state.isDeleteCompleted
);
