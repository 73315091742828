import { Injectable } from '@angular/core';

import { of } from 'rxjs';
import { switchMap, map, catchError, debounceTime } from 'rxjs/operators';
import { Effect, ofType, Actions } from '@ngrx/effects';

import { PAGINATION } from 'shared';
import { BanksService } from 'app-search/services';
import { BanksActionType, SearchBanks, SearchBanksFail, SearchBanksSuccess } from 'app-search/store/actions';

@Injectable()
export class BanksEffects {
  constructor(private actions$: Actions, private BanksService: BanksService) {}

  /* ========================= SEARCH_BANKS =================================== */
  @Effect()
  search$ = this.actions$.pipe(
    ofType(BanksActionType.SEARCH_BANKS),
    debounceTime(300),
    switchMap(({ payload }: SearchBanks) =>
      this.BanksService.search(payload?.name ?? '', payload.page, PAGINATION.Banks).pipe(
        map((response) => new SearchBanksSuccess(response)),
        catchError((error) => of(new SearchBanksFail(error)))
      )
    )
  );
}
