<!-- page header starts -->
<app-page-header [info]="pageInfo"></app-page-header>
<!-- page header ends -->

<!-- update product class form starts -->
<div class="row">
  <div class="col-md-12">
    <form [formGroup]="form" (ngSubmit)="submit()" *ngIf="productClass$ | async; let productClass">
      <div class="card">
        <div class="card-header mb-3">
          <h4 class="card-title">{{ 'STORES.PRODUCT_CLASSES.PRODUCT_CLASS_TITLE' | translate }}</h4>
        </div>
        <div class="card-content">
          <div class="px-3">
            <div class="row">
              <div class="form-group col-md-6">
                <label for="name">{{
                  'STORES.PRODUCT_CLASSES.PRODUCT_CLASS_DATA.ARABIC_PRODUCT_CLASS_NAME' | translate
                }}</label>
                <input
                  type="text"
                  id="name"
                  class="form-control round"
                  [placeholder]="
                    'STORES.PRODUCT_CLASSES.PRODUCT_CLASS_DATA.ARABIC_PRODUCT_CLASS_NAME_PLACEHOLDER' | translate
                  "
                  maxlength="50"
                  formControlName="name"
                />
              </div>
              <div class="form-group col-md-6">
                <label for="nameEn">{{
                  'STORES.PRODUCT_CLASSES.PRODUCT_CLASS_DATA.ENGLISH_PRODUCT_CLASS_NAME' | translate
                }}</label>
                <input
                  type="text"
                  id="nameEn"
                  class="form-control round"
                  [placeholder]="
                    'STORES.PRODUCT_CLASSES.PRODUCT_CLASS_DATA.ENGLISH_PRODUCT_CLASS_NAME_PLACEHOLDER' | translate
                  "
                  maxlength="50"
                  formControlName="nameEn"
                />
              </div>
              <div class="form-group col-md-6">
                <input type="checkbox" formControlName="showProductClassInSalesScreen" />
                <span class="ml-2 mt-0 pt-0">{{
                  'STORES.PRODUCT_CLASSES.PRODUCT_CLASS_DATA.SHOW_PRODUCT_CLASS_IN_SALES_SCREEN' | translate
                }}</span>
              </div>
              <!-- locations details starts -->
              <div class="form-group col-md-12">
                <div class="card-header mb-3 pt-1">
                  <h4 class="card-title">
                    {{ 'STORES.PRODUCT_CLASSES.PRODUCT_CLASS_DATA.LOCATION_FORM_TITLE' | translate }}
                  </h4>
                </div>
                <div class="card-content">
                  <div class="px-3">
                    <app-locations-form
                      [formArray]="locationsForm"
                      [newLocations]="newLocations"
                      [isLocationRequired]="true"
                    ></app-locations-form>
                  </div>
                </div>
              </div>
              <!-- locations details ends -->
              <div *ngIf="productClass.photoUrl" class="form-group col-md-12">
                <app-file-viewer
                  [url]="productClass.photoUrl"
                  [allowDelete]="true"
                  [allowUpdate]="true"
                  (delete)="deletePhoto()"
                  (update)="updatePhoto()"
                ></app-file-viewer>
              </div>
              <div *ngIf="!productClass.photoUrl" class="form-group col-md-12">
                <button
                  type="button"
                  class="btn btn-raised btn-success my-1 round"
                  (click)="updatePhoto()"
                  [disabled]="isUpdating$ | async"
                >
                  <i class="fa fa-edit font-medium-3"></i>
                  <span class="mx-1">{{
                    'STORES.PRODUCT_CLASSES.PRODUCT_CLASS_DATA.PRODUCT_CLASS_IMAGE' | translate
                  }}</span>
                </button>
              </div>
            </div>

            <div class="row">
              <div class="form-group col-12 d-flex justify-content-between flex-column flex-sm-row mt-2">
                <button type="submit" class="btn btn-raised btn-success round" [disabled]="isUpdating$ | async">
                  <i class="fa fa-check"></i>
                  <span class="mx-1"> {{ 'SHARED.CONFIRM.SAVE' | translate }} </span>
                </button>
                <a class="btn btn-dark round" routerLink="/stores/product-classes">
                  <i class="fa fa-undo"></i>
                  <span class="mx-1"
                    >{{ 'STORES.PRODUCT_CLASSES.PRODUCT_CLASS_DATA.PRODUCTS_CLASSES_RETURN' | translate }}
                  </span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
<!-- update product class form ends -->

<!-- confirm modal starts -->
<ng-template #confirmModalRef let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
      {{ 'STORES.PRODUCT_CLASSES.CONFIRM_UPDATE_PRODUCT_CLASS_TITLE' | translate }}
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="d()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p class="text-success">{{ 'STORES.PRODUCT_CLASSES.CONFIRM_UPDATE_PRODUCT_CLASS_MESSAGE' | translate }}</p>
  </div>
  <div class="modal-footer">
    <div class="modal-actions">
      <button type="button" class="btn btn-raised btn-success round" (click)="c(); confirm()" ngbAutofocus>
        <i class="fa fa-check"></i>
        <span class="mx-1"> {{ 'SHARED.CONFIRM.SAVE' | translate }} </span>
      </button>
      <button type="button" class="btn btn-outline-dark round" (click)="c()">
        <i class="fa fa-times"></i>
        <span class="mx-1"> {{ 'SHARED.CONFIRM.CANCEL' | translate }} </span>
      </button>
    </div>
  </div>
</ng-template>
<!-- confirm modal ends -->

<!-- confirm delete photo modal starts -->
<ng-template #confirmDeletePhotoModalRef let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title text-warning" id="modal-basic-title">
      {{ 'STORES.PRODUCT_CLASSES.CONFIRM_DELETE_PRODUCT_CLASS_IMAGE_TITLE' | translate }}
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="d()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p class="text-warning">{{ 'STORES.PRODUCT_CLASSES.CONFIRM_DELETE_PRODUCT_CLASS_IMAGE_MESSAGE' | translate }}</p>
  </div>
  <div class="modal-footer">
    <div class="modal-actions">
      <button type="button" class="btn btn-raised btn-warning round" (click)="c(); deletePhotoConfirm()" ngbAutofocus>
        <i class="fa fa-trash"></i>
        <span class="mx-1"> {{ 'SHARED.CONFIRM.DELETE' | translate }} </span>
      </button>
      <button type="button" class="btn btn-outline-dark round" (click)="c()">
        <i class="fa fa-times"></i>
        <span class="mx-1"> {{ 'SHARED.CONFIRM.CANCEL' | translate }} </span>
      </button>
    </div>
  </div>
</ng-template>
<!-- confirm delete photo modal ends -->

<!-- confirm update photo modal starts -->
<ng-template #confirmUpdatePhotoModalRef let-c="close" let-d="dismiss">
  <form [formGroup]="updatePhotoForm">
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">
        {{ 'STORES.PRODUCT_CLASSES.CONFIRM_UPDATE_PRODUCT_CLASS_IMAGE_TITLE' | translate }}
      </h4>
      <button type="button" class="close" aria-label="Close" (click)="d()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="row">
        <div class="form-group col-md-12">
          <app-file-upload formControlName="photo"></app-file-upload>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <div class="modal-actions">
        <button
          type="button"
          class="btn btn-raised btn-success round"
          (click)="c(); updatePhotoConfirm()"
          [disabled]="isUpdating$ | async"
          ngbAutofocus
        >
          <i class="fa fa-edit"></i>
          <span class="mx-1"> {{ 'SHARED.CONFIRM.SAVE' | translate }} </span>
        </button>
        <button type="button" class="btn btn-outline-dark round" (click)="c()">
          <i class="fa fa-times"></i>
          <span class="mx-1"> {{ 'SHARED.CONFIRM.CANCEL' | translate }} </span>
        </button>
      </div>
    </div>
  </form>
</ng-template>
<!-- confirm update photo modal ends -->
