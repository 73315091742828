import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';

import { Observable, Subscription } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { tap } from 'rxjs/operators';

import * as fromFinancesStore from 'finances/store';
import * as fromLookupsStore from 'lookups/store';
import * as fromProjectsManagementStore from 'projects-management/store';
import { NotificationService, PaginationInfo, TranslationService } from 'shared';
import { ProjectResourceType, ProjectResourceTypeSource, ProjectResourceTypeSources } from 'lookups/models';
import { ProjectMainTask, ProjectResource, ProjectSubTask } from 'projects-management/models';

@Component({
  selector: 'app-project-resources',
  templateUrl: './project-resources.component.html',
  styles: [],
})
export class ProjectResourcesComponent implements OnInit, OnDestroy {
  /**
   * Sets the project id.
   */
  @Input() projectId: number;

  /**
   * Sets the project resource type source id for `PAYROLL.
   */
  projectResourceTypeSourceForPayrollId: number;

  /**
   * The list of project resource.
   */
  projectResources$: Observable<ProjectResource[]>;

  /**
   * Indicates whether there is a search process is running or not.
   */
  isSearching$: Observable<boolean>;

  /**
   * The pagination info.
   */
  paginationInfo$: Observable<PaginationInfo>;

  /**
   * Gets the list of selected project resource for current project resource.
   */
  selectedProjectResource: ProjectResource;
  /**
   * The search form.
   */
  searchForm: FormGroup;

  /**
   * Shows or hide the project main task list.
   */
  projectMainTaskListVisibility = false;

  /**
   * Shows or hide the project sub task list.
   */
  projectSubTaskListVisibility = false;

  /**
   * The list of selected project main tasks.
   */
  projectMainTasks: ProjectMainTask[] = [];

  /**
   * The list of selected project sub tasks.
   */
  projectSubTasks: ProjectSubTask[] = [];

  /**
   * The list of project resource types.
   */
  projectResourceTypes$: Observable<ProjectResourceType[]>;

  /**
   * The list of project resource type sources.
   */
  projectResourceTypeSources$: Observable<ProjectResourceTypeSource[]>;

  /**
   * Indicates whether there is a delete-payroll process is running or not.
   */
  isDeleting$: Observable<boolean>;

  /**
   * The ngb-modal reference to use it when modal needed to be closed.
   */
  modal: NgbModalRef;

  /** The initial `fromDate` value for search. */
  initialFromDate = new Date();

  /** The initial `toDate` value for search.
   * It's the current day of month.
   */
  initialToDate = new Date();

  /**
   * The set of subscriptions on this components,
   * these subscriptions must be unsubscribed before this component got destroyed.
   */
  subscriptions = new Subscription();

  /**
   * @param modalService The modal service.
   * @param projectsManagementStore$ The projects-management-store.
   * @param translationService The translation service.
   * @param notificationService The notification service.
   * @param lookupsStore$ the lookups-store module.
   */
  constructor(
    private modalService: NgbModal,
    private projectsManagementStore$: Store<fromProjectsManagementStore.ProjectsManagementState>,
    private translationService: TranslationService,
    private notificationService: NotificationService,
    private financesStore$: Store<fromFinancesStore.FinancesState>,
    private lookupsStore$: Store<fromLookupsStore.LookupsState>
  ) {}

  ngOnInit(): void {
    this.init();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  /**
   * Initialize component data.
   */
  init() {
    this.initForms();

    /**
     * Set data.
     */
    let isManualSearchTriggeredBeforeForProjectResourceTypes = false;
    this.projectResourceTypes$ = this.lookupsStore$.pipe(
      select(fromLookupsStore.getProjectResourceTypes),
      tap((projectResourceTypes) => {
        if (!isManualSearchTriggeredBeforeForProjectResourceTypes && !projectResourceTypes.length) {
          isManualSearchTriggeredBeforeForProjectResourceTypes = true;
          this.lookupsStore$.dispatch(new fromLookupsStore.GetAllProjectResourceTypes());
        }
      })
    );

    /**
     * Set data.
     */
    let isManualSearchTriggeredBeforeForProjectResourceTypeSources = false;
    this.subscriptions.add(
      this.lookupsStore$
        .pipe(
          select(fromLookupsStore.getProjectResourceTypeSources),
          tap((projectResourceTypeSources) => {
            if (!isManualSearchTriggeredBeforeForProjectResourceTypeSources && !projectResourceTypeSources.length) {
              isManualSearchTriggeredBeforeForProjectResourceTypeSources = true;
              this.lookupsStore$.dispatch(new fromLookupsStore.GetAllProjectResourceTypeSources());
            } else {
              this.projectResourceTypeSourceForPayrollId =
                projectResourceTypeSources?.find(
                  (projectResourceTypeSource) => projectResourceTypeSource.key === ProjectResourceTypeSources.PAYROLL
                )?.id ?? 0;
            }
          })
        )
        .subscribe()
    );

    this.isSearching$ = this.projectsManagementStore$.pipe(
      select(fromProjectsManagementStore.getProjectResourcesSearching)
    );
    this.paginationInfo$ = this.projectsManagementStore$.pipe(
      select(fromProjectsManagementStore.getProjectResourcesPaginationInfo)
    );
    this.projectResources$ = this.projectsManagementStore$.pipe(
      select(fromProjectsManagementStore.getProjectResources)
    );

    this.isDeleting$ = this.financesStore$.pipe(select(fromFinancesStore.getSelectedPayrollDeleting));

    this.search();
  }

  /**
   * Initialize form and add validators.
   */
  initForms() {
    this.searchForm = new FormGroup({
      projectMainTasks: new FormControl([]),
      projectSubTasks: new FormControl([]),
      projectResourceTypes: new FormControl([]),
      fromDate: new FormControl({
        year: this.initialFromDate.getFullYear(),
        month: this.initialFromDate.getMonth() + 1,
        day: this.initialFromDate.getDate(),
      }),
      toDate: new FormControl({
        year: this.initialToDate.getFullYear(),
        month: this.initialToDate.getMonth() + 1,
        day: this.initialToDate.getDate(),
      }),
      page: new FormControl(1),
    });
  }

  /**
   * Handles search parameters change.
   */
  search(event?: KeyboardEvent) {
    if (event && event.key === 'Enter') {
      event.preventDefault();
      return;
    }

    this.applyFiltersAndSearch();
  }

  /**
   * Handles pagination page-changed event.
   * @param page The current selected page number.
   */
  pageChanged(page: number) {
    /** Update pagination page. */
    this.searchForm.patchValue({ page });

    this.applyFiltersAndSearch(page);
  }

  /**
   * Applies the search filters and dispatches a search action.
   * @param page The current page number, default to `1`.
   */
  applyFiltersAndSearch(page: number = 1) {
    if (!this.searchForm.get('fromDate')?.value || !this.searchForm.get('toDate')?.value) {
      return this.notificationService.warning(
        this.translationService.translate('PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.NOTIFICATION_MESSAGE.SELECT_DATE')
      );
    }

    const { fromDate, toDate } = this.searchForm.value;
    const fromDateFormatted = new Date(fromDate.year, fromDate.month - 1, fromDate.day);
    fromDateFormatted.setHours(0, 0, 0, 0);

    const toDateFormatted = new Date(toDate.year, toDate.month - 1, toDate.day);
    toDateFormatted.setHours(23, 59, 59, 0);

    this.projectsManagementStore$.dispatch(
      new fromProjectsManagementStore.SearchProjectResources({
        projectId: this.projectId,
        projectMainTasks: this.searchForm.get('projectMainTasks').value,
        projectSubTasks: this.searchForm.get('projectSubTasks').value,
        projectResourceTypes: this.searchForm.get('projectResourceTypes').value,
        fromDate: fromDateFormatted,
        toDate: toDateFormatted,
        page,
      })
    );
  }

  /**
   * Adds the newly selected project main tasks the project main tasks search list.
   * @param project main tasks The list of newly selected project main tasks to be added.
   */
  selectMainTasks(projectMainTasks: ProjectMainTask[]) {
    const selectedIds: number[] = this.searchForm.get('projectMainTasks').value;
    this.projectMainTasks = [
      ...this.projectMainTasks.filter((projectMainTask) => selectedIds.includes(projectMainTask.id)),
      ...projectMainTasks,
    ];
    this.searchForm.patchValue({
      projectMainTasks: this.projectMainTasks.map((projectMainTask) => projectMainTask.id),
    });
  }

  /**
   * Adds the newly selected project sub tasks the project sub tasks search list.
   * @param project sub tasks The list of newly selected project sub tasks to be added.
   */
  selectSubTasks(projectSubTasks: ProjectSubTask[]) {
    const selectedIds: number[] = this.searchForm.get('projectSubTasks').value;
    this.projectSubTasks = [
      ...this.projectSubTasks.filter((projectSubTask) => selectedIds.includes(projectSubTask.id)),
      ...projectSubTasks,
    ];
    this.searchForm.patchValue({
      projectSubTasks: this.projectSubTasks.map((projectSubTask) => projectSubTask.id),
    });
  }

  /**
   * Enables trackBy feature for *ngFor utility to track items
   * depending on a comparer to reduce DOM change.
   * If the item is already exist then no HTML will change, only bounded values.
   * @param index The index of the item.
   * @param project resource The item to determine if it was changed or not.
   */
  trackItems(index: number, resource: ProjectResource) {
    return resource ? resource.id : undefined;
  }

  /**
   * Opens the modal of the given templateRef.
   * @param modalRef The modal templateRef to be opened.
   */
  openModal(modalRef) {
    /**
     * Open the modal & listen for user press ESC key.
     */
    this.modal = this.modalService.open(modalRef, { size: 'xl' });
    this.modal.result.then(null, () => this.closeModal());
  }

  /**
   * Closes the currently opened modal.
   */
  closeModal() {
    this.modalService.dismissAll();
  }
}
