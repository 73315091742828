import { Action } from '@ngrx/store';

import { PurchaseInvoiceType } from 'lookups/models';
import { AppHttpResponse } from 'shared';

/**
 * The Lookups-Module store purchase invoice type action types.
 */
export enum PurchaseInvoiceTypeActionType {
  GET_ALL_PURCHASE_INVOICE_TYPES = '[Lookups] [PurchaseInvoiceType] Get All Purchase Invoice Types',
  GET_ALL_PURCHASE_INVOICE_TYPES_FAIL = '[Lookups] [PurchaseInvoiceType] Get All Purchase Invoice Types Fail',
  GET_ALL_PURCHASE_INVOICE_TYPES_SUCCESS = '[Lookups] [PurchaseInvoiceType] Get All Purchase Invoice Types Success',
}

/**
 * Represents a store purchase invoice type action.
 */
export class GetAllPurchaseInvoiceType implements Action {
  /**
   * The type of the action.
   */
  readonly type = PurchaseInvoiceTypeActionType.GET_ALL_PURCHASE_INVOICE_TYPES;

  /**
   * Fires a new purchase invoice type action.
   */
  constructor() {}
}

/**
 * Represents a store purchase invoice type fail action.
 */
export class GetAllPurchaseInvoiceTypeFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = PurchaseInvoiceTypeActionType.GET_ALL_PURCHASE_INVOICE_TYPES_FAIL;

  /**
   * Fires a new purchase invoice type fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store purchase invoice type success action.
 */
export class GetAllPurchaseInvoiceTypeSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = PurchaseInvoiceTypeActionType.GET_ALL_PURCHASE_INVOICE_TYPES_SUCCESS;

  /**
   * Fires a purchase invoice type success action.
   * @param payload An object contains the list of purchase invoice types.
   */
  constructor(public payload: AppHttpResponse<PurchaseInvoiceType[]>) {}
}

/**
 * Lookups-module purchase invoice type action types.
 */
export type PurchaseInvoiceTypeActions =
  | GetAllPurchaseInvoiceType
  | GetAllPurchaseInvoiceTypeFail
  | GetAllPurchaseInvoiceTypeSuccess;
