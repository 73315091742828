import { CreatePosPrinterComponent } from 'sales/components/pos-printers/create-pos-printer/create-pos-printer.component';
import { UpdatePosPrinterComponent } from 'sales/components/pos-printers/update-pos-printer/update-pos-printer.component';
import { PosPrintersListComponent } from 'sales/components/pos-printers/pos-printers-list/pos-printers-list.component';
import { PosPrintersSearchComponent } from 'sales/components/pos-printers/pos-printers-search/pos-printers-search.component';

export * from 'sales/components/pos-printers/create-pos-printer/create-pos-printer.component';
export * from 'sales/components/pos-printers/update-pos-printer/update-pos-printer.component';
export * from 'sales/components/pos-printers/pos-printers-list/pos-printers-list.component';
export * from 'sales/components/pos-printers/pos-printers-search/pos-printers-search.component';

/**
 * The set of components registered on this sub-module.
 */
export const COMPONENTS: any[] = [
  CreatePosPrinterComponent,
  UpdatePosPrinterComponent,
  PosPrintersListComponent,
  PosPrintersSearchComponent,
];
