import { createSelector } from '@ngrx/store';

import { selectLookupsState } from 'lookups/store/selectors/feature.selectors';
import { LookupsState, DepartmentTypeState } from 'lookups/store/states';

/**
 * Gets the department type state.
 */
const selectDepartmentTypes = createSelector(selectLookupsState, (state: LookupsState) => state.departmentTypes);

/**
 * Gets the list of loaded department types.
 */
export const getDepartmentTypes = createSelector(selectDepartmentTypes, (state: DepartmentTypeState) => state.data);

/**
 * Gets the error that occurred in the state.
 */
export const getDepartmentTypesError = createSelector(
  selectDepartmentTypes,
  (state: DepartmentTypeState) => state.error
);

/**
 * Determines if there is a running load process.
 */
export const getDepartmentTypesIsLoading = createSelector(
  selectDepartmentTypes,
  (state: DepartmentTypeState) => state.isLoading
);

/**
 * Determines if the last load process has been ended successfully.
 */
export const getDepartmentTypesIsLoaded = createSelector(
  selectDepartmentTypes,
  (state: DepartmentTypeState) => state.isLoaded
);
