import { createSelector } from '@ngrx/store';

import { CurrencyExchangeRatesState, FinancesState } from 'finances/store/states';
import { selectFinancesState } from 'finances/store/selectors/feature.selectors';

/**
 * Gets the currency exchange rate state.
 */
const selectCurrencyExchangeRatesState = createSelector(
  selectFinancesState,
  (state: FinancesState) => state.currencyExchangeRates
);

/**
 * Gets the list of loaded currency exchange rates.
 */
export const getCurrencyExchangeRates = createSelector(
  selectCurrencyExchangeRatesState,
  (state: CurrencyExchangeRatesState) => state.data
);

/**
 * Gets the currency exchange rates pagination info.
 */
export const getCurrencyExchangeRatesPaginationInfo = createSelector(
  selectCurrencyExchangeRatesState,
  (state: CurrencyExchangeRatesState) => state.paginationInfo
);

/**
 * Gets the error that occurred in the state.
 */
export const getCurrencyExchangeRatesError = createSelector(
  selectCurrencyExchangeRatesState,
  (state: CurrencyExchangeRatesState) => state.error
);

/**
 * Determines if there is a running search process.
 */
export const getCurrencyExchangeRatesSearching = createSelector(
  selectCurrencyExchangeRatesState,
  (state: CurrencyExchangeRatesState) => state.isSearching
);

/**
 * Determines if the last search process has been ended successfully.
 */
export const getCurrencyExchangeRatesSearchCompleted = createSelector(
  selectCurrencyExchangeRatesState,
  (state: CurrencyExchangeRatesState) => state.isSearchCompleted
);

/**
 * Gets the selected currency exchange rate.
 */
export const getSelectedCurrencyExchangeRate = createSelector(
  selectCurrencyExchangeRatesState,
  (state: CurrencyExchangeRatesState) => state.selectedCurrencyExchangeRate
);

/**
 * Determines if there is a running find currency exchange rate process.
 */
export const getCurrencyExchangeRateFinding = createSelector(
  selectCurrencyExchangeRatesState,
  (state: CurrencyExchangeRatesState) => state.isFinding
);

/**
 * Determines if the last find currency exchange rate process has been ended successfully.
 */
export const getCurrencyExchangeRateFindCompleted = createSelector(
  selectCurrencyExchangeRatesState,
  (state: CurrencyExchangeRatesState) => state.isFindCompleted
);

/**
 * Determines if there is a running create process.
 */
export const getSelectedCurrencyExchangeRateCreating = createSelector(
  selectCurrencyExchangeRatesState,
  (state: CurrencyExchangeRatesState) => state.isCreating
);

/**
 * Determines if the last create process has been ended successfully.
 */
export const getSelectedCurrencyExchangeRateCreateCompleted = createSelector(
  selectCurrencyExchangeRatesState,
  (state: CurrencyExchangeRatesState) => state.isCreateCompleted
);

/**
 * Determines if there is a running update process.
 */
export const getSelectedCurrencyExchangeRateUpdating = createSelector(
  selectCurrencyExchangeRatesState,
  (state: CurrencyExchangeRatesState) => state.isUpdating
);

/**
 * Determines if the last update process has been ended successfully.
 */
export const getSelectedCurrencyExchangeRateUpdateCompleted = createSelector(
  selectCurrencyExchangeRatesState,
  (state: CurrencyExchangeRatesState) => state.isUpdateCompleted
);

/**
 * Determines if there is a running delete process.
 */
export const getSelectedCurrencyExchangeRateDeleting = createSelector(
  selectCurrencyExchangeRatesState,
  (state: CurrencyExchangeRatesState) => state.isDeleting
);

/**
 * Determines if the last delete process has been ended successfully.
 */
export const getSelectedCurrencyExchangeRateDeleteCompleted = createSelector(
  selectCurrencyExchangeRatesState,
  (state: CurrencyExchangeRatesState) => state.isDeleteCompleted
);
