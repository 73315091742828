import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { FormGroup, FormArray, FormControl, Validators } from '@angular/forms';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { Decimal } from 'decimal.js';

import { Observable, BehaviorSubject, Subscription } from 'rxjs';
import { skip, tap } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { PageInfo, NotificationService, NotificationMessage, CustomValidators, TranslationService } from 'shared';
import * as fromFinancesStore from 'finances/store';
import { CanComponentDeactivate } from 'auth/models';
import {
  CreateJournalLineInput,
  JournalLineFormItem,
  Account,
  PettyCashRefundRequest,
  PettyCashRefundRequestApprovalLineFormItem,
} from 'finances/models';

/**
 * The create petty cash refund request approval pages.
 */
enum PAGES {
  details = 'details',
  journal = 'journal',
}

@Component({
  selector: 'app-create-petty-cash-refund-requests-approval',
  templateUrl: './create-petty-cash-refund-requests-approval.component.html',
  styles: [],
})
export class CreatePettyCashRefundRequestsApprovalComponent implements OnInit, OnDestroy, CanComponentDeactivate {
  /**
   * The confirm modal template reference.
   */
  @ViewChild('confirmModalRef') confirmModalRef: ElementRef<any>;

  /**
   * Gets or sets the information about the current page.
   */
  pageInfo: PageInfo = {
    title: 'FINANCES.PETTY_CASH_REFUND_REQUEST_APPROVALS.CREATE_PETTY_CASH_REFUND_REQUEST_APPROVAL_PAGE_TITLE',
    icon: 'fa fa-user-plus',
  };

  /**
   * Gets or sets the selected page.
   * @default 'details'
   */
  activePage: PAGES = PAGES.details;

  /**
   * Indicates whether there is a create-request process is running or not.
   */
  isCreating$: Observable<boolean>;

  /**
   * Gets the petty cash of the account.
   */
  pettyCashAccount: Account;

  /**
   * Gets the value added tax debit of the account.
   */
  valueAddedTaxDebitAccount: Account;

  /**
   * The list of selected petty cash refund request.
   */
  pettyCashRefundRequests: PettyCashRefundRequest[] = [];

  /** The initial `toDate` value for search.
   * It's the current day of month.
   */
  initialToDate = new Date();

  /**
   * The create petty cash refund request form.
   */
  form: FormGroup;

  /**
   * Shows or hide the petty cash refund request list.
   */
  pettyCashRefundRequestListVisibility = false;

  /**
   * Used to add new refund request form items to the petty cash form.
   */
  newPettyCashRefundRequestApprovalLines: PettyCashRefundRequestApprovalLineFormItem[];

  /**
   * The set of subscriptions on this components,
   * these subscriptions must be unsubscribed before this component got destroyed.
   */
  subscriptions = new Subscription();

  /**
   * Sets the initial journal lines should be added to the journals form.
   * @param journal lines An object contains `count` of initial blank journal lines.
   * @param journal lines A single journal line details to be added to the form.
   * @param journal lines A list of journal lines details to be added to the form.
   */
  initialJournalLinesSubject: BehaviorSubject<
    { count: number } | JournalLineFormItem | JournalLineFormItem[]
  > = new BehaviorSubject(undefined);

  /**
   * Gets the petty cash refund request approval lines form-array.
   */
  get pettyCashRefundRequestApprovalLinesForm(): FormArray {
    return this.form?.controls.pettyCashRefundRequestApprovalLines as FormArray;
  }

  /**
   * Gets the journal-lines form-array.
   */
  get journalLinesForm(): FormArray {
    return this.form?.controls.lines as FormArray;
  }

  /**
   * Gets the attachments form-array.
   */
  get attachmentsForm(): FormArray {
    return this.form?.controls.attachments as FormArray;
  }

  /**
   * Gets the sum of selected request lines total taxes.
   */
  get requestApprovalLinesTax(): number {
    return Decimal.sum(
      0,
      ...(this.pettyCashRefundRequestApprovalLinesForm.controls.length
        ? this.pettyCashRefundRequestApprovalLinesForm.controls
            .map((control) => control.value)
            .map(
              (requestApprovalLine: PettyCashRefundRequestApprovalLineFormItem) =>
                Decimal.div(requestApprovalLine.pettyCashRefundRequestLine?.tax ?? 0, 100).mul(
                  requestApprovalLine.pettyCashRefundRequestLine?.value ?? 0
                ) ?? 0
            )
        : [0])
    ).toNumber();
  }

  /**
   * Gets the sum of selected request lines total values.
   */
  get requestApprovalLinesTotal(): number {
    return Decimal.sub(this.requestApprovalTotal, this.requestApprovalLinesTax).toNumber();
  }

  /**
   * Gets the sum of selected request total values.
   */
  get requestApprovalTotal(): number {
    return this.pettyCashRefundRequestApprovalLinesForm.controls
      .map((control) => control.value)
      .reduce((previousValue, requestApprovalLine: PettyCashRefundRequestApprovalLineFormItem) => {
        if (!requestApprovalLine?.pettyCashRefundRequestLine) {
          return 0;
        }
        return Decimal.sum(previousValue, requestApprovalLine.pettyCashRefundRequestLine.total).toNumber();
      }, 0);
  }

  /**
   * @param modalService The modal service.
   * @param notificationService The notification service.
   * @param locationService The location service.
   * @param route The activated route.
   * @param financesStore$ the finances-store module.
   * @param translationService The translation service.
   */
  constructor(
    private modalService: NgbModal,
    private notificationService: NotificationService,
    private locationService: Location,
    private route: ActivatedRoute,
    private financesStore$: Store<fromFinancesStore.FinancesState>,
    private translationService: TranslationService
  ) {}

  ngOnInit(): void {
    this.init();
  }

  /**
   * Destroy component data
   */
  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  /**
   * Initialize component data.
   */
  init() {
    this.initForm();
    this.initJournalData();

    /**
     * Load data.
     */
    this.isCreating$ = this.financesStore$.pipe(
      select(fromFinancesStore.getSelectedPettyCashRefundRequestApprovalCreating)
    );

    /**
     * Reset form controls when new petty cash refund request approval is created.
     */
    this.subscriptions.add(
      this.financesStore$
        .pipe(
          select(fromFinancesStore.getSelectedPettyCashRefundRequestApprovalCreateCompleted),
          skip(1),
          tap((isCreated) => {
            if (isCreated) {
              this.resetForm();
            }
          })
        )
        .subscribe()
    );

    /** Select the user desired page. */
    this.activePage = PAGES[this.route.snapshot.fragment] ?? this.activePage;
    this.selectedPageChanged(this.activePage);
  }

  /**
   * Initialize form and add validators.
   */
  initForm() {
    const pettyCashRefundRequestApprovalLinesForm = new FormArray([], CustomValidators.arrayItems(1));

    this.form = new FormGroup({
      pettyCashRefundRequestId: new FormControl(null, Validators.required),
      pettyCashRefundRequestVersion: new FormControl(null),
      transactionDate: new FormControl(null),
      notes: new FormControl('', [Validators.minLength(0), Validators.maxLength(200)]),
      pettyCashRefundRequestApprovalLines: pettyCashRefundRequestApprovalLinesForm,
      attachments: new FormArray([]),
      lines: new FormArray([], CustomValidators.arrayItems(1)),
    });

    /**
     * Dynamically update journal lines when petty cash refund request change.
     */
    this.subscriptions.add(
      pettyCashRefundRequestApprovalLinesForm.valueChanges.subscribe(() => this.onRefundRequestsChange())
    );

    this.addBlankAttachment();
    this.setBlankJournalsCount();
  }

  /**
   * Initialize the journal data.
   */
  /**
   * Initialize the journal data.
   */
  initJournalData() {
    /**
     * Fetch system accounts then fill the journal line form with its lines.
     */

    let isManualSearchTriggeredBeforeForValueAddedTaxDebitAccount = false;
    this.subscriptions.add(
      /**
       * Value added tax debit Account.
       */
      this.financesStore$
        .pipe(
          select(fromFinancesStore.getValueAddedTaxDebitAccount),
          tap((account) => {
            this.valueAddedTaxDebitAccount = account;
            if (account) {
              this.onRefundRequestsChange();
            } else if (!account && !isManualSearchTriggeredBeforeForValueAddedTaxDebitAccount) {
              isManualSearchTriggeredBeforeForValueAddedTaxDebitAccount = true;
              this.financesStore$.dispatch(new fromFinancesStore.FindValueAddedTaxDebitAccount());
            }
          })
        )
        .subscribe()
    );

    let isManualSearchTriggeredBeforeForPettyCashAccount = false;
    this.subscriptions.add(
      /**
       * Petty cash Account.
       */
      this.financesStore$
        .pipe(
          select(fromFinancesStore.getPettyCashAccount),
          tap((account) => {
            this.pettyCashAccount = account;
            if (account) {
              this.onRefundRequestsChange();
            } else if (!account && !isManualSearchTriggeredBeforeForPettyCashAccount) {
              isManualSearchTriggeredBeforeForPettyCashAccount = true;
              this.financesStore$.dispatch(new fromFinancesStore.FindPettyCashAccount());
            }
          })
        )
        .subscribe()
    );
  }

  /**
   * Updates the journal lines debit & credit values or even add or remove lines
   * as a response for the petty cash refund requests.
   */
  onRefundRequestsChange() {
    /**
     * Set Journal Lines.
     */
    this.resetJournalLines();

    /**
     * The list of journal lines to be added to the form.
     */
    const lines: JournalLineFormItem[] = [];

    /**
     * For each line in the refund request create a journal line that it's account
     * can be selected by the user.
     */
    this.pettyCashRefundRequestApprovalLinesForm.controls
      .map((control) => control.value)
      .forEach((refundRequestApprovalLine: PettyCashRefundRequestApprovalLineFormItem) => {
        if (!refundRequestApprovalLine?.pettyCashRefundRequestLine) {
          return;
        }

        lines.push({
          accountId: null,
          account: null,
          costCenterId: null,
          credit: 0,
          debit: Decimal.sub(
            refundRequestApprovalLine.pettyCashRefundRequestLine.total,
            Decimal.div(refundRequestApprovalLine.pettyCashRefundRequestLine.tax, 100).mul(
              refundRequestApprovalLine.pettyCashRefundRequestLine.value
            )
          ).toNumber(),

          notes: refundRequestApprovalLine.pettyCashRefundRequestLine.description,
          disableDebitAndCreditValueChange: true,
          disableNotesChange: true,
        });
      });

    /**
     * Add line for value added tax debit account.
     */
    if (this.valueAddedTaxDebitAccount) {
      lines.push({
        accountId: this.valueAddedTaxDebitAccount?.id,
        account: this.valueAddedTaxDebitAccount,
        costCenterId: null,
        credit: 0,
        debit: this.requestApprovalLinesTax,
        notes: '',
        disableAccountChange: true,
        disableDebitAndCreditValueChange: true,
      });
    }

    /**
     * Add line for petty cash Account.
     */
    if (this.pettyCashAccount) {
      lines.push({
        accountId: this.pettyCashAccount.id,
        account: this.pettyCashAccount,
        costCenterId: null,
        credit: this.requestApprovalTotal,
        debit: 0,
        notes: '',
        disableAccountChange: true,
        disableDebitAndCreditValueChange: true,
      });
    }

    this.initialJournalLinesSubject.next(lines);
  }

  /**
   * Submits the form.
   */
  submit() {
    const errorMessage = new NotificationMessage();

    if (this.form.invalid) {
      if (this.form.get('pettyCashRefundRequestId').invalid) {
        errorMessage.title = this.translationService.translate(
          'FINANCES.PETTY_CASH_REFUND_REQUEST_APPROVALS.PETTY_CASH_REFUND_REQUEST_APPROVAL_DATA_VALIDATION.PETTY_CASH_REFUND_REQUEST_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate(
            // tslint:disable-next-line: max-line-length
            'FINANCES.PETTY_CASH_REFUND_REQUEST_APPROVALS.PETTY_CASH_REFUND_REQUEST_APPROVAL_DATA_VALIDATION.PETTY_CASH_REFUND_REQUEST_IS_REQUIRED'
          ),
        ];
        this.activePage = PAGES.details;
      } else if (this.form.get('notes').invalid) {
        errorMessage.title = this.translationService.translate(
          'FINANCES.PETTY_CASH_REFUND_REQUEST_APPROVALS.PETTY_CASH_REFUND_REQUEST_APPROVAL_DATA_VALIDATION.NOTES_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate(
            'FINANCES.PETTY_CASH_REFUND_REQUEST_APPROVALS.PETTY_CASH_REFUND_REQUEST_APPROVAL_DATA_VALIDATION.NOTES_LENGTH_ERROR'
          ),
        ];
        this.activePage = PAGES.details;
      } else if (this.form.get('pettyCashRefundRequestApprovalLines').invalid) {
        /**
         * Check if requests count = 0.
         */
        if (!this.pettyCashRefundRequestApprovalLinesForm.controls.length) {
          errorMessage.title = this.translationService.translate(
            // tslint:disable-next-line: max-line-length
            'FINANCES.PETTY_CASH_REFUND_REQUEST_APPROVALS.PETTY_CASH_REFUND_REQUEST_APPROVAL_DATA_VALIDATION.PETTY_CASH_REFUND_REQUESTS_ERROR'
          );
          errorMessage.body = [
            this.translationService.translate(
              // tslint:disable-next-line: max-line-length
              'FINANCES.PETTY_CASH_REFUND_REQUEST_APPROVALS.PETTY_CASH_REFUND_REQUEST_APPROVAL_DATA_VALIDATION.PETTY_CASH_REFUND_REQUESTS_LENGTH_ERROR'
            ),
          ];
        }
      } else if (this.form.get('lines').invalid) {
        /**
         * Check if journal lines count < 2.
         */
        if (this.journalLinesForm.controls.length < 2) {
          errorMessage.title = this.translationService.translate(
            'FINANCES.PETTY_CASH_REFUND_REQUEST_APPROVALS.PETTY_CASH_REFUND_REQUEST_APPROVAL_DATA_VALIDATION.JOURNALS_LINES_ERROR'
          );
          errorMessage.body = [
            this.translationService.translate(
              'FINANCES.PETTY_CASH_REFUND_REQUEST_APPROVALS.PETTY_CASH_REFUND_REQUEST_APPROVAL_DATA_VALIDATION.JOURNALS_LINES_COUNT_ERROR'
            ),
          ];
        } else {
          /**
           * Check if some of journals has errors.
           */
          for (let index = 0; index < this.journalLinesForm.controls.length; index++) {
            const journal = this.journalLinesForm.controls[index];

            if (journal.valid) {
              continue;
            }

            errorMessage.title = this.translationService.translate(
              'FINANCES.PETTY_CASH_REFUND_REQUEST_APPROVALS.PETTY_CASH_REFUND_REQUEST_APPROVAL_DATA_VALIDATION.JOURNAL_LINE_NUMBER_ERROR',
              {
                journalNumber: index + 1,
              }
            );
            errorMessage.body = [];

            if (journal.get('accountId').invalid) {
              errorMessage.body.push(
                this.translationService.translate(
                  'FINANCES.PETTY_CASH_REFUND_REQUEST_APPROVALS.PETTY_CASH_REFUND_REQUEST_APPROVAL_DATA_VALIDATION.ACCOUNT_ERROR'
                )
              );
              errorMessage.body.push(
                this.translationService.translate(
                  'FINANCES.PETTY_CASH_REFUND_REQUEST_APPROVALS.PETTY_CASH_REFUND_REQUEST_APPROVAL_DATA_VALIDATION.ACCOUNT_IS_REQUIRED'
                )
              );
            }

            if (journal.get('credit').invalid) {
              errorMessage.body.push(
                this.translationService.translate(
                  'FINANCES.PETTY_CASH_REFUND_REQUEST_APPROVALS.PETTY_CASH_REFUND_REQUEST_APPROVAL_DATA_VALIDATION.CREDIT_IS_REQUIRED'
                )
              );
            }

            if (journal.get('debit').invalid) {
              errorMessage.body.push(
                this.translationService.translate(
                  'FINANCES.PETTY_CASH_REFUND_REQUEST_APPROVALS.PETTY_CASH_REFUND_REQUEST_APPROVAL_DATA_VALIDATION.DEBIT_IS_REQUIRED'
                )
              );
            }

            if (journal.get('costCenterId').invalid) {
              errorMessage.body.push(
                this.translationService.translate(
                  'FINANCES.PETTY_CASH_REFUND_REQUEST_APPROVALS.PETTY_CASH_REFUND_REQUEST_APPROVAL_DATA_VALIDATION.COST_CENTER_ERROR'
                )
              );
              errorMessage.body.push(
                this.translationService.translate(
                  'FINANCES.PETTY_CASH_REFUND_REQUEST_APPROVALS.PETTY_CASH_REFUND_REQUEST_APPROVAL_DATA_VALIDATION.COST_CENTER_IS_REQUIRED'
                )
              );
            }

            if (journal.get('notes').invalid) {
              errorMessage.body.push(
                this.translationService.translate(
                  'FINANCES.PETTY_CASH_REFUND_REQUEST_APPROVALS.PETTY_CASH_REFUND_REQUEST_APPROVAL_DATA_VALIDATION.NOTES_ERROR'
                )
              );
              errorMessage.body.push(
                this.translationService.translate(
                  'FINANCES.PETTY_CASH_REFUND_REQUEST_APPROVALS.PETTY_CASH_REFUND_REQUEST_APPROVAL_DATA_VALIDATION.NOTES_IS_REQUIRED'
                )
              );
              errorMessage.body.push(
                this.translationService.translate(
                  'FINANCES.PETTY_CASH_REFUND_REQUEST_APPROVALS.PETTY_CASH_REFUND_REQUEST_APPROVAL_DATA_VALIDATION.NOTES_LENGTH_ERROR'
                )
              );
            }

            break;
          }
        }

        this.activePage = PAGES.journal;
      }
      this.form.markAllAsTouched();
      return this.notificationService.warningWithTitle(errorMessage);
    }
    this.openModal(this.confirmModalRef);
  }

  /**
   * Adds a blank attachment form-control to the attachments form for quick start..
   */
  addBlankAttachment() {
    this.attachmentsForm.push(new FormControl());
  }

  /**
   * Sets the count of the journal lines should be added to the journals form for quick start..
   */
  setBlankJournalsCount() {
    this.initialJournalLinesSubject.next({ count: 0 });
  }

  /**
   * Resets journal lines data.
   */
  resetJournalLines() {
    this.journalLinesForm.clear();
  }

  /**
   * Confirms the form submit.
   */
  confirm() {
    let fromDateFormatted = null;
    const { transactionDate } = this.form.value;

    if (transactionDate) {
      fromDateFormatted = new Date(transactionDate.year, transactionDate.month - 1, transactionDate.day);
      fromDateFormatted.setHours(new Date().getHours(), new Date().getMinutes());
    }

    /**
     * The list of petty cash refund request in the petty cash refund request approval form.
     */
    const pettyCashRefundRequestApprovalLines: number[] = this.pettyCashRefundRequestApprovalLinesForm.value.map(
      (item: PettyCashRefundRequestApprovalLineFormItem) => item.pettyCashRefundRequestLine.id
    );

    /**
     * The list of lines in the lines form.
     */
    const journalLines = this.journalLinesForm.value
      .filter((line: JournalLineFormItem) => line.credit > 0 || line.debit > 0)
      .map((line) => {
        const inputLine: CreateJournalLineInput = {
          accountId: line.accountId,
          debit: line.debit,
          credit: line.credit,
          costCenterId: line.costCenterId,
          notes: line.notes,
        };

        return inputLine;
      });

    this.financesStore$.dispatch(
      new fromFinancesStore.CreatePettyCashRefundRequestApproval({
        pettyCashRefundRequestId: this.form.value.pettyCashRefundRequestId,
        pettyCashRefundRequestVersion: this.form.value.pettyCashRefundRequestVersion,
        notes: this.form.value.notes,
        pettyCashRefundRequestLines: pettyCashRefundRequestApprovalLines,
        transactionDate: fromDateFormatted,
        attachments: this.attachmentsForm.value.filter((file) => !!file),
        journalLines,
      })
    );
  }

  /**
   * Selects the newly selected request from the petty cash refund request search list.
   * @param pettyCashRefundRequest The list of newly selected petty cash refund request to select the only one in the list.
   */
  selectPettyCashRefundRequest([pettyCashRefundRequest]: PettyCashRefundRequest[]) {
    if (pettyCashRefundRequest) {
      this.pettyCashRefundRequests = [pettyCashRefundRequest];
      this.form.patchValue({
        pettyCashRefundRequestId: pettyCashRefundRequest.id,
        pettyCashRefundRequestVersion: pettyCashRefundRequest.version,
      });

      /**
       * Clear all products in the form.
       */
      this.pettyCashRefundRequestApprovalLinesForm.clear();

      /**
       * Add new Request lines to the form from the petty cash refund request.
       */
      this.newPettyCashRefundRequestApprovalLines = pettyCashRefundRequest.pettyCashRefundRequestLines
        .filter((refundRequestLine) => !refundRequestLine.isApproved)
        .map((refundRequestLine) => {
          const item: PettyCashRefundRequestApprovalLineFormItem = {
            pettyCashRefundRequestLine: refundRequestLine,
          };
          return item;
        });
    }
  }

  /**
   * Opens the modal of the given templateRef.
   * @param modalRef The modal templateRef to be opened.
   */
  openModal(modalRef) {
    this.modalService.open(modalRef);
  }

  /**
   * Resets all form data.
   */
  resetForm() {
    this.form.reset({
      notes: '',
    });

    this.pettyCashRefundRequestApprovalLinesForm.clear();
    this.attachmentsForm.clear();
    this.addBlankAttachment();
    this.resetJournalLines();
  }

  /**
   * Updates the browser url according to the selected page.
   * @param page The newly selected page.
   */
  selectedPageChanged(page: PAGES) {
    this.locationService.replaceState(`${this.locationService.path()}#${PAGES[page]}`);
  }

  /**
   * Confirms leaving the page before routing.
   */
  confirmDeactivate() {
    if (this.form.dirty === true) {
      /**
       * Message to warn the user before leaving the detailed form.
       */
      return confirm(this.translationService.translate('SHARED.CONFIRM.LEAVING'));
    }
    return true;
  }
}
