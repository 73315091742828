import { Component, Input } from '@angular/core';

import { ProjectResource, ProjectResourceLine } from 'projects-management/models';

@Component({
  selector: 'app-project-resource-purchase-return-details',
  templateUrl: './project-resource-purchase-return-details.component.html',
  styles: [],
})
export class ProjectResourcePurchaseReturnDetailsComponent {
  /**
   * Sets the current viewed resource.
   */
  @Input() resource: ProjectResource;

  /**
   * The list of project resource lines.
   */
  @Input() projectResourcePurchaseReturnLines: ProjectResourceLine[];
}
