import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';

import { Observable, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

import * as fromFinancesStore from 'finances/store';
import * as fromLookupsStore from 'lookups/store';
import { NotificationService, PaginationInfo, TranslationService } from 'shared';
import { Location } from 'stores/models';
import { Department, Employee, Payroll, PayrollAttachment } from 'hr/models';
import { PayrollStatus, PayrollStatuses } from 'lookups/models';

@Component({
  selector: 'payrolls-list',
  templateUrl: './payrolls-list.component.html',
  styles: [],
})
export class PayrollsListComponent implements OnInit, OnDestroy {
  /**
   * The list of selected employees.
   */
  employees: Employee[] = [];

  /**
   * The list of selected departments.
   */
  departments: Department[] = [];

  /**
   * The list of selected locations.
   */
  locations: Location[] = [];

  /**
   * The list of payroll status.
   */
  payrollStatus = PayrollStatuses;

  /**
   * Shows or hide the employees list.
   */
  employeesListVisibility = false;

  /**
   * Shows or hide the departments list.
   */
  departmentsListVisibility = false;

  /**
   * Shows or hide the locations list.
   */
  locationsListVisibility = false;

  /**
   * Gets or sets the currently selected payroll for delete.
   */
  selectedPayroll: Payroll;

  /**
   * The initial `fromDate` value for search.
   */
  initialFromDate = new Date();

  /**
   * The initial `toDate` value for search.
   * It's the current day of month.
   */
  initialToDate = new Date();

  /**
   * The list of payrolls.
   */
  payrolls$: Observable<Payroll[]>;

  /**
   * The pagination info.
   */
  paginationInfo$: Observable<PaginationInfo>;

  /**
   * Indicates whether there is a search process is running or not.
   */
  isSearching$: Observable<boolean>;

  /**
   * Indicates whether there is a delete-payroll process is running or not.
   */
  isDeleting$: Observable<boolean>;

  /**
   * Indicates whether there is a prepare-payroll process is running or not.
   */
  isPreparing$: Observable<boolean>;

  /**
   * The set of system supported payroll statuses.
   */
  payrollStatuses$: Observable<PayrollStatus[]>;

  /**
   * The search form.
   */
  searchForm: FormGroup;

  /**
   * The ngb-modal reference to use it when modal needed to be closed.
   */
  modal: NgbModalRef;

  /**
   * The set of subscriptions on this components,
   * these subscriptions must be unsubscribed before this component got destroyed.
   */
  subscriptions = new Subscription();

  /**
   * @param notificationService The notification service.
   * @param modalService The modal service.
   * @param translationService The translation service.
   * @param financesStore$ the finances-store module.
   * @param lookupsStore$ The lookups-store module.
   */
  constructor(
    private notificationService: NotificationService,
    private modalService: NgbModal,
    private translationService: TranslationService,
    private financesStore$: Store<fromFinancesStore.FinancesState>,
    private lookupsStore$: Store<fromLookupsStore.LookupsState>
  ) {}

  ngOnInit(): void {
    this.init();
  }

  /**
   * Destroy component data
   */
  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  /**
   * Initialize component data.
   */
  init() {
    this.initForm();

    /**
     * Load data.
     */
    this.isSearching$ = this.financesStore$.pipe(select(fromFinancesStore.getPayrollsSearching));
    this.isDeleting$ = this.financesStore$.pipe(select(fromFinancesStore.getSelectedPayrollDeleting));

    let isManualSearchTriggeredBeforeForPayrollStatuses = false;
    this.payrollStatuses$ = this.lookupsStore$.pipe(
      select(fromLookupsStore.getPayrollStatuses),
      tap((payrollStatuses) => {
        if (!isManualSearchTriggeredBeforeForPayrollStatuses && !payrollStatuses.length) {
          isManualSearchTriggeredBeforeForPayrollStatuses = true;
          this.lookupsStore$.dispatch(new fromLookupsStore.GetAllPayrollStatuses());
        }
      })
    );

    this.payrolls$ = this.financesStore$.pipe(select(fromFinancesStore.getPayrolls));

    this.paginationInfo$ = this.financesStore$.pipe(select(fromFinancesStore.getPayrollsPaginationInfo));
  }

  /**
   * Initialize form and add validators.
   */
  initForm() {
    this.searchForm = new FormGroup({
      employees: new FormControl([]),
      departments: new FormControl([]),
      locations: new FormControl([]),
      payrollStatuses: new FormControl([]),
      fromDate: new FormControl({
        year: this.initialFromDate.getFullYear(),
        month: this.initialFromDate.getMonth() + 1,
        day: this.initialFromDate.getDate(),
      }),
      toDate: new FormControl({
        year: this.initialToDate.getFullYear(),
        month: this.initialToDate.getMonth() + 1,
        day: this.initialToDate.getDate(),
      }),
      page: new FormControl(1),
    });
  }

  /**
   * Handles search parameters change.
   */
  searchPayrolls(event?: KeyboardEvent) {
    if (event && event.key === 'Enter') {
      event.preventDefault();
      return;
    }

    this.applyFiltersAndSearch();
  }

  /**
   * Handles pagination page-changed event.
   * @param page The current selected page number.
   */
  pageChanged(page: number) {
    /** Update pagination page. */
    this.searchForm.patchValue({ page });

    this.applyFiltersAndSearch(page);
  }

  /**
   * Applies the search filters and dispatches a search action.
   * @param page The current page number, default to `1`.
   */
  applyFiltersAndSearch(page: number = 1) {
    if (!this.searchForm.get('fromDate')?.value || !this.searchForm.get('toDate')?.value) {
      return this.notificationService.warning(
        this.translationService.translate('HR.EMPLOYEES_MANAGEMENT.NOTIFICATION_MESSAGE.SELECT_DATE')
      );
    }

    const { employees, departments, locations, payrollStatuses, fromDate, toDate } = this.searchForm.value;
    const fromDateFormatted = new Date(fromDate.year, fromDate.month - 1, fromDate.day);
    fromDateFormatted.setHours(0, 0, 0, 0);

    const toDateFormatted = new Date(toDate.year, toDate.month - 1, toDate.day);
    toDateFormatted.setHours(23, 59, 59, 0);

    this.financesStore$.dispatch(
      new fromFinancesStore.SearchPayrolls({
        employees,
        departments,
        locations,
        payrollStatuses,
        fromDate: fromDateFormatted,
        toDate: toDateFormatted,
        page,
      })
    );
  }

  /**
   * Maps and returns the set of payroll attachments into a set of urls.
   * @param attachments The payroll attachments to be mapped.
   */
  getAttachmentsUrls(attachments: PayrollAttachment[]): string[] {
    return attachments ? attachments.map((attachment) => attachment.url) : [];
  }

  /**
   * Adds the newly selected employees the employees search list.
   * @param employees The list of newly selected employees to be added.
   */
  selectEmployees(employees: Employee[]) {
    const selectedIds: number[] = this.searchForm.get('employees').value;
    this.employees = [...this.employees.filter((employee) => selectedIds.includes(employee.id)), ...employees];
    this.searchForm.patchValue({ employees: this.employees.map((employee) => employee.id) });
  }

  /**
   * Adds the newly selected departments the departments search list.
   * @param departments The list of newly selected departments to be added.
   */
  selectDepartments(departments: Department[]) {
    const selectedIds: number[] = this.searchForm.get('departments').value;
    this.departments = [
      ...this.departments.filter((department) => selectedIds.includes(department.id)),
      ...departments,
    ];
    this.searchForm.patchValue({ departments: this.departments.map((department) => department.id) });
  }

  /**
   * Adds the newly selected locations the locations search list.
   * @param locations The list of newly selected locations to be added.
   */
  selectLocations(locations: Location[]) {
    const selectedIds: number[] = this.searchForm.get('locations').value;
    this.locations = [...this.locations.filter((location) => selectedIds.includes(location.id)), ...locations];
    this.searchForm.patchValue({ locations: this.locations.map((location) => location.id) });
  }

  /**
   * Enables trackBy feature for *ngFor utility to track items
   * depending on a comparer to reduce DOM change.
   * If the item is already exist then no HTML will change, only bounded values.
   * @param index The index of the item.
   * @param payroll The item to determine if it was changed or not.
   */
  trackItems(index: number, payroll: Payroll) {
    return payroll ? payroll.id : undefined;
  }

  /**
   * Deletes the payroll with the given id.
   * @param payrollId The id of the payroll to be deleted.
   */
  deletePayroll(payrollId: number) {
    this.financesStore$.dispatch(new fromFinancesStore.DeletePayroll(payrollId));
  }

  /**
   * Opens the modal of the given templateRef.
   * @param modalRef The modal templateRef to be opened.
   */
  openModal(modalRef) {
    this.modalService.open(modalRef);
  }

  /**
   * Opens the modal of the given templateRef.
   * @param modalRef The modal templateRef to be opened.
   */
  openInfoModal(modalRef) {
    /**
     * Open the modal & listen for user press ESC key.
     */
    this.modal = this.modalService.open(modalRef, { size: 'xl' });
    this.modal.result.then(null, () => this.closeModal());
  }

  /**
   * Closes the currently opened modal.
   */
  closeModal() {
    this.modalService.dismissAll();
  }
}
