<!-- page header starts -->
<app-page-header [info]="pageInfo"></app-page-header>
<!-- page header ends -->

<!-- locations list starts -->
<div class="row">
  <div class="col-md-12">
    <div class="card">
      <div class="card-header mb-3">
        <h4 class="card-title">{{ 'SHARED.SEARCH.SEARCH_TOOLS' | translate }}</h4>
      </div>
      <div class="card-content">
        <div class="px-3">
          <div class="row">
            <div class="col-md-12">
              <form class="row" [formGroup]="searchForm" (ngSubmit)="search()">
                <div class="form-group col">
                  <label for="name">{{ 'STORES.LOCATIONS.LOCATION_DATA.LOCATION_NAME' | translate }}</label>
                  <input
                    id="name"
                    type="text"
                    class="form-control round"
                    [placeholder]="'STORES.LOCATIONS.LOCATION_DATA.LOCATION_NAME_OR_CODE_PLACEHOLDER' | translate"
                    formControlName="name"
                    (input)="search($event)"
                  />
                </div>
                <div class="form-group col">
                  <label for="locationTypes">{{ 'STORES.LOCATIONS.LOCATION_DATA.LOCATION_TYPE' | translate }}</label>
                  <ng-select
                    class="round"
                    formControlName="locationTypes"
                    [multiple]="true"
                    [closeOnSelect]="false"
                    labelForId="locationTypes"
                    searchable="true"
                    [placeholder]="'STORES.LOCATIONS.LOCATION_DATA.LOCATION_TYPE_PLACEHOLDER' | translate"
                    [notFoundText]="'SHARED.SEARCH.SEARCH_NOT_FOUND' | translate"
                  >
                    <ng-option *ngFor="let locationType of locationTypes$ | async" [value]="locationType.id">
                      {{ locationType.name | localize: locationType.nameEn | async }}
                    </ng-option>
                  </ng-select>
                </div>
                <div class="form-group col">
                  <label for="regions">{{ 'STORES.LOCATIONS.LOCATION_DATA.THE_REGION' | translate }}</label>
                  <ng-select
                    class="round"
                    formControlName="regions"
                    [multiple]="true"
                    [closeOnSelect]="false"
                    labelForId="regions"
                    searchable="true"
                    [placeholder]="'STORES.LOCATIONS.LOCATION_DATA.THE_REGION_PLACEHOLDER' | translate"
                    [notFoundText]="'SHARED.SEARCH.SEARCH_NOT_FOUND' | translate"
                    (open)="regionsListVisibility = true"
                  >
                    <ng-option *ngFor="let region of regions" [value]="region.id">
                      {{ region.name | localize: region.nameEn | async }}
                    </ng-option>
                  </ng-select>
                </div>
                <div class="form-group col">
                  <label for="sectors">{{ 'STORES.LOCATIONS.LOCATION_DATA.THE_SECTOR' | translate }}</label>
                  <ng-select
                    class="round"
                    formControlName="sectors"
                    [multiple]="true"
                    [closeOnSelect]="false"
                    labelForId="sectors"
                    searchable="true"
                    [placeholder]="'STORES.LOCATIONS.LOCATION_DATA.THE_SECTOR_PLACEHOLDER' | translate"
                    [notFoundText]="'SHARED.SEARCH.SEARCH_NOT_FOUND' | translate"
                    (open)="sectorsListVisibility = true"
                  >
                    <ng-option *ngFor="let sector of sectors" [value]="sector.id">
                      {{ sector.name | localize: sector.nameEn | async }}
                    </ng-option>
                  </ng-select>
                </div>
                <div class="col-md-auto form-group d-flex">
                  <button
                    type="submit"
                    class="btn btn-raised btn-primary align-self-end round mx-1 mb-0"
                    [disabled]="isSearching$ | async"
                  >
                    <i class="fa fa-search"></i>
                  </button>
                  <a
                    *ngIf="[Claims.CREATE_LOCATION] | authorize | async"
                    routerLink="/stores/locations/create"
                    class="btn btn-raised btn-success align-self-end round mx-1 mb-0"
                  >
                    <i class="fa fa-plus"></i>
                    <span class="mx-1"> {{ 'SHARED.CONFIRM.ADD' | translate }} </span>
                  </a>
                  <app-export-tools
                    *ngIf="(locations$ | async)?.length"
                    class="d-flex align-self-end mx-1"
                    targetElementId="locations-table-export-target-element"
                    [outputFileName]="'SHARED.EXPORT_TABLES.LOCATIONS_TABLE' | translate"
                  ></app-export-tools>
                </div>
              </form>
            </div>
            <div class="col-md-12" *ngIf="(locations$ | async)?.length; else noItems">
              <div class="row">
                <div class="col-md-12 alert alert-light text-center" role="alert">
                  {{ 'SHARED.SEARCH.SEARCH_RESULTS' | translate }} ({{ (paginationInfo$ | async).total | toNumber }})
                  {{ 'STORES.LOCATIONS.LOCATION_DATA.LOCATION' | translate }}
                </div>
                <div class="col-md-12">
                  <div class="table-responsive">
                    <table class="table text-center" id="locations-table-export-target-element">
                      <thead>
                        <tr>
                          <th scope="col"><i class="fa fa-list-ol"></i></th>
                          <th scope="col">{{ 'STORES.LOCATIONS.LOCATION_DATA.LOCATION_CODE' | translate }}</th>
                          <th scope="col">{{ 'STORES.LOCATIONS.LOCATION_DATA.LOCATION_NAME' | translate }}</th>
                          <th scope="col">{{ 'STORES.LOCATIONS.LOCATION_DATA.THE_REGION' | translate }}</th>
                          <th scope="col">{{ 'STORES.LOCATIONS.LOCATION_DATA.THE_SECTOR' | translate }}</th>
                          <th scope="col">{{ 'STORES.LOCATIONS.LOCATION_DATA.THE_COST_CENTER' | translate }}</th>
                          <th scope="col">{{ 'SHARED.TOOLS' | translate }}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let location of locations$ | async; trackBy: trackItems; let i = index">
                          <th scope="row">{{ i + 1 }}</th>
                          <td>{{ location.code }}</td>
                          <td>{{ location.name | localize: location.nameEn | async }}</td>
                          <td>{{ location.region?.name | localize: location.region?.nameEn | async }}</td>
                          <td>{{ location.sector?.name | localize: location.sector?.nameEn | async }}</td>
                          <td>
                            {{ location.costCenter.description | localize: location.costCenter.descriptionEn | async }}
                          </td>
                          <td>
                            <a
                              *ngIf="[Claims.UPDATE_LOCATION] | authorize | async"
                              class="info p-0"
                              [routerLink]="['/stores/locations/update', location.id]"
                              [ngbTooltip]="'STORES.LOCATIONS.LOCATION_DATA.TOOL_TIP_UPDATE' | translate"
                            >
                              <i class="fa fa-edit font-medium-3 mr-2"></i>
                            </a>
                            <a
                              *ngIf="[Claims.DELETE_LOCATION] | authorize | async"
                              class="danger p-0"
                              [ngbTooltip]="'STORES.LOCATIONS.LOCATION_DATA.TOOL_TIP_DELETE' | translate"
                              (click)="selectedLocation = location; openModal(deleteModalRef)"
                            >
                              <i class="fa fa-close font-medium-3 mr-2"></i>
                            </a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div class="d-flex justify-content-between p-2">
                      <ngb-pagination
                        *ngIf="paginationInfo$ | async; let pagination"
                        maxSize="5"
                        [collectionSize]="pagination.total"
                        [pageSize]="pagination.pageSize"
                        [page]="pagination.page"
                        (pageChange)="pageChanged($event)"
                        boundaryLinks="true"
                      >
                        <ng-template ngbPaginationFirst>{{
                          'SHARED.PAGINATION.PAGINATION_FIRST' | translate
                        }}</ng-template>
                        <ng-template ngbPaginationPrevious>{{
                          'SHARED.PAGINATION.PAGINATION_PREVIOUS' | translate
                        }}</ng-template>
                        <ng-template ngbPaginationNext>{{
                          'SHARED.PAGINATION.PAGINATION_NEXT' | translate
                        }}</ng-template>
                        <ng-template ngbPaginationLast>{{
                          'SHARED.PAGINATION.PAGINATION_LAST' | translate
                        }}</ng-template>
                      </ngb-pagination>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- locations list ends -->

<!-- no items template starts -->
<ng-template #noItems>
  <div class="col-md-12 alert alert-warning mt-3 text-center" role="alert">
    {{ 'STORES.LOCATIONS.SEARCH_RESULT_LOCATION_NOT_FOUND' | translate }}
    <a
      *ngIf="[Claims.CREATE_LOCATION] | authorize | async"
      routerLink="/stores/locations/create"
      class="btn btn-raised btn-success round"
    >
      <i class="fa fa-plus"></i>
      <span class="mx-1"> {{ 'SHARED.CONFIRM.ADD' | translate }} </span>
    </a>
  </div>
</ng-template>
<!-- no items template ends -->

<!-- delete location modal starts -->
<ng-template #deleteModalRef let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title text-danger" id="modal-basic-title">
      {{ 'STORES.LOCATIONS.CONFIRM_DELETE_LOCATION_TITLE' | translate }}
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="d()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-12">
        <p class="text-danger">{{ 'STORES.LOCATIONS.CONFIRM_DELETE_LOCATION_MESSAGE' | translate }}</p>
      </div>
      <div class="form-group col-12">
        <label for="selectedLocation.name">{{ 'STORES.LOCATIONS.LOCATION_DATA.LOCATION_NAME' | translate }}</label>
        <input
          type="text"
          id="selectedLocation.name"
          class="form-control round"
          [placeholder]="'STORES.LOCATIONS.LOCATION_DATA.LOCATION_NAME_PLACEHOLDER' | translate"
          readonly
          [value]="selectedLocation.name | localize: selectedLocation.nameEn | async"
        />
      </div>
      <div class="form-group col-12">
        <label for="selectedLocation.representorName">{{
          'STORES.LOCATIONS.LOCATION_DATA.RESPONSIBLE_NAME' | translate
        }}</label>
        <input
          type="text"
          id="selectedLocation.representorName"
          class="form-control round"
          [placeholder]="'STORES.LOCATIONS.LOCATION_DATA.RESPONSIBLE_NAME_PLACEHOLDER' | translate"
          readonly
          [value]="selectedLocation.representorName"
        />
      </div>
      <div class="form-group col-12">
        <label for="selectedLocation.representorMobile">{{
          'STORES.LOCATIONS.LOCATION_DATA.RESPONSIBLE_MOBILE' | translate
        }}</label>
        <input
          type="text"
          id="selectedLocation.representorMobile"
          class="form-control round"
          [placeholder]="'STORES.LOCATIONS.LOCATION_DATA.RESPONSIBLE_MOBILE_PLACEHOLDER' | translate"
          readonly
          [value]="selectedLocation.representorMobile"
        />
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="modal-actions">
      <button
        type="button"
        class="btn btn-raised btn-danger round"
        (click)="c(); delete(selectedLocation.id)"
        ngbAutofocus
        [disabled]="isDeleting$ | async"
      >
        <i class="fa fa-check"></i>
        <span class="mx-1"> {{ 'SHARED.CONFIRM.DELETE' | translate }} </span>
      </button>
      <button type="button" class="btn btn-outline-dark round" (click)="c()">
        <i class="fa fa-times"></i>
        <span class="mx-1"> {{ 'SHARED.CONFIRM.CANCEL' | translate }} </span>
      </button>
    </div>
  </div>
</ng-template>
<!-- delete location modal ends -->

<!-- regions search starts -->
<app-regions-search (regions)="selectRegion($event)" [(visible)]="regionsListVisibility"> </app-regions-search>
<!-- regions search ends -->

<!-- sectors search starts -->
<app-sectors-search (sectors)="selectSector($event)" [(visible)]="sectorsListVisibility"> </app-sectors-search>
<!-- sectors search ends -->
