import { Component, Input } from '@angular/core';

import { PettyCashRefundRequest } from 'finances/models';

@Component({
  selector: 'app-journal-petty-cash-refund-request-details',
  templateUrl: './journal-petty-cash-refund-request-details.component.html',
  styles: [],
})
export class JournalPettyCashRefundRequestDetailsComponent {
  /**
   * Sets the current viewed journal petty cash refund request.
   */
  @Input() pettyCashRefundRequest: PettyCashRefundRequest;
}
