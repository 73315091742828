import { Action } from '@ngrx/store';

import { DepartmentCategory } from 'lookups/models';
import { AppHttpResponse } from 'shared';

/**
 * The Lookups-Module store department category action types.
 */
export enum DepartmentCategoryActionType {
  GET_ALL_DEPARTMENT_CATEGORIES = '[Lookups] [DepartmentCategory] Get All Department Categories',
  GET_ALL_DEPARTMENT_CATEGORIES_FAIL = '[Lookups] [DepartmentCategory] Get All Department Categories Fail',
  GET_ALL_DEPARTMENT_CATEGORIES_SUCCESS = '[Lookups] [DepartmentCategory] Get All Department Categories Success',
}

/**
 * Represents a store department  type action.
 */
export class GetAllDepartmentCategory implements Action {
  /**
   * The type of the action.
   */
  readonly type = DepartmentCategoryActionType.GET_ALL_DEPARTMENT_CATEGORIES;

  /**
   * Fires a new department  type action.
   */
  constructor() {}
}

/**
 * Represents a store department  type fail action.
 */
export class GetAllDepartmentCategoryFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = DepartmentCategoryActionType.GET_ALL_DEPARTMENT_CATEGORIES_FAIL;

  /**
   * Fires a new Department  type fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store department  type success action.
 */
export class GetAllDepartmentCategorySuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = DepartmentCategoryActionType.GET_ALL_DEPARTMENT_CATEGORIES_SUCCESS;

  /**
   * Fires a department  type success action.
   * @param payload An object contains the list of department  categories.
   */
  constructor(public payload: AppHttpResponse<DepartmentCategory[]>) {}
}

/**
 * Lookups-module Department  type action categories.
 */
export type DepartmentCategoryActions =
  | GetAllDepartmentCategory
  | GetAllDepartmentCategoryFail
  | GetAllDepartmentCategorySuccess;
