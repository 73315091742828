import { ActionReducerMap } from '@ngrx/store';

import { StatisticsMainState } from 'statistics/store/states';
import { StatisticsReducer } from 'statistics/store/reducers/statistics.reducer';

/**
 * The Statistics-Module store reducers.
 */
export const reducers: ActionReducerMap<StatisticsMainState> = {
  statistics: StatisticsReducer,
};
