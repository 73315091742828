import { createSelector } from '@ngrx/store';

import { selectLookupsState } from 'lookups/store/selectors/feature.selectors';
import { LookupsState, VendorAdvancePaymentLogTypeState } from 'lookups/store/states';

/**
 * Gets the vendor advance payment log type state.
 */
const selectVendorAdvancePaymentLogTypes = createSelector(
  selectLookupsState,
  (state: LookupsState) => state.vendorAdvancePaymentLogTypes
);

/**
 * Gets the list of loaded vendor advance payment log types.
 */
export const getVendorAdvancePaymentLogTypes = createSelector(
  selectVendorAdvancePaymentLogTypes,
  (state: VendorAdvancePaymentLogTypeState) => state.data
);

/**
 * Gets the error that occurred in the state.
 */
export const getVendorAdvancePaymentLogTypesError = createSelector(
  selectVendorAdvancePaymentLogTypes,
  (state: VendorAdvancePaymentLogTypeState) => state.error
);

/**
 * Determines if there is a running load process.
 */
export const getVendorAdvancePaymentLogTypesIsLoading = createSelector(
  selectVendorAdvancePaymentLogTypes,
  (state: VendorAdvancePaymentLogTypeState) => state.isLoading
);

/**
 * Determines if the last load process has been ended successfully.
 */
export const getVendorAdvancePaymentLogTypesIsLoaded = createSelector(
  selectVendorAdvancePaymentLogTypes,
  (state: VendorAdvancePaymentLogTypeState) => state.isLoaded
);
