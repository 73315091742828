<!-- page header starts -->
<app-page-header [info]="pageInfo"></app-page-header>
<!-- page header ends -->

<!-- maintenance invoice pages start -->
<div class="card">
  <div class="card-content">
    <div class="px-3 pt-3">
      <form (ngSubmit)="submit()">
        <div class="row">
          <div class="col-md-12">
            <ul
              class="nav-tabs"
              ngbNav
              #nav="ngbNav"
              [(activeId)]="activePage"
              (activeIdChange)="selectedPageChanged($event)"
              [destroyOnHide]="false"
            >
              <li ngbNavItem="details">
                <a ngbNavLink>
                  <i class="fa fa-file-text" [class.success]="activePage == 'details'"></i>
                  {{ 'SALES.INVOICES.MAINTENANCE_INVOICE_DETAILS' | translate }}
                </a>

                <!-- create maintenance invoice starts -->
                <ng-template ngbNavContent>
                  <div class="row">
                    <div class="col-md-12">
                      <form [formGroup]="form">
                        <div class="card">
                          <div class="card-header mb-3">
                            <h4 class="card-title">
                              {{ 'SALES.INVOICES.SALE_INVOICE_DATA.INVOICE_DATA' | translate }}
                            </h4>
                          </div>
                          <!-- customer details starts -->
                          <div class="card-content">
                            <div class="px-3">
                              <div class="row">
                                <div class="form-group col-md-4">
                                  <label for="locationId">
                                    {{ 'SALES.INVOICES.SALE_INVOICE_DATA.SALE_INVOICE_LOCATION' | translate }}
                                  </label>
                                  <ng-select
                                    class="round"
                                    formControlName="locationId"
                                    labelForId="locationId"
                                    searchable="true"
                                    [placeholder]="
                                      'SALES.INVOICES.SALE_INVOICE_DATA.SALE_INVOICE_LOCATION_PLACEHOLDER' | translate
                                    "
                                    [notFoundText]="'SHARED.SEARCH.SEARCH_NOT_FOUND' | translate"
                                    (open)="locationsListVisibility = true"
                                  >
                                    <ng-option *ngFor="let location of locations" [value]="location.id">
                                      {{ location.name | localize: location.nameEn | async }}
                                    </ng-option>
                                  </ng-select>
                                </div>
                                <div class="form-group col-md-4">
                                  <label for="customerId">{{
                                    'SALES.INVOICES.SALE_INVOICE_DATA.CUSTOMER' | translate
                                  }}</label>
                                  <ng-select
                                    class="round"
                                    formControlName="customerId"
                                    labelForId="customerId"
                                    searchable="true"
                                    [placeholder]="'SALES.INVOICES.SALE_INVOICE_DATA.CUSTOMER_PLACEHOLDER' | translate"
                                    [notFoundText]="'SHARED.SEARCH.SEARCH_NOT_FOUND' | translate"
                                    (open)="customersListVisibility = true"
                                  >
                                    <ng-option *ngFor="let customer of customers" [value]="customer.id">
                                      {{ customer.name | localize: customer.nameEn | async }}
                                    </ng-option>
                                  </ng-select>
                                </div>
                                <div class="form-group col-md-4">
                                  <label for="customerAssetId">{{
                                    'SALES.INVOICES.SALE_INVOICE_DATA.CUSTOMER_ASSET_TYPE' | translate
                                  }}</label>
                                  <ng-select
                                    class="round"
                                    formControlName="customerAssetId"
                                    labelForId="customerAssetId"
                                    searchable="true"
                                    [placeholder]="
                                      'SALES.INVOICES.SALE_INVOICE_DATA.CUSTOMER_ASSET_TYPE_PLACEHOLDER' | translate
                                    "
                                    [notFoundText]="'SHARED.SEARCH.SEARCH_NOT_FOUND' | translate"
                                  >
                                    <ng-option *ngFor="let asset of assets" [value]="asset.id">
                                      {{ formatAsset(asset) }}
                                    </ng-option>
                                  </ng-select>
                                </div>
                                <div class="form-group col-md-6">
                                  <label for="transactionDate">{{
                                    'SALES.INVOICES.SALE_INVOICE_DATA.INVOICE_DATE' | translate
                                  }}</label>
                                  <div class="input-group round">
                                    <input
                                      class="form-control"
                                      id="transactionDate"
                                      name="transactionDate"
                                      formControlName="transactionDate"
                                      [placeholder]="'SHARED.SEARCH.DATE_PLACEHOLDER' | translate"
                                      ngbDatepicker
                                      #transactionDate="ngbDatepicker"
                                    />
                                    <div class="input-group-append">
                                      <span
                                        class="input-group-text gradient-light-blue-indigo"
                                        style="cursor: pointer;"
                                        (click)="transactionDate.toggle()"
                                      >
                                        <i class="fa fa-calendar"></i>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <div class="form-group col-md-6">
                                  <label for="dateOfSupply">{{
                                    'SALES.INVOICES.SALE_INVOICE_DATA.SUPPLY_DATE' | translate
                                  }}</label>
                                  <div class="input-group round">
                                    <input
                                      class="form-control"
                                      id="dateOfSupply"
                                      name="dateOfSupply"
                                      formControlName="dateOfSupply"
                                      [placeholder]="'SHARED.SEARCH.DATE_PLACEHOLDER' | translate"
                                      ngbDatepicker
                                      #dateOfSupply="ngbDatepicker"
                                    />
                                    <div class="input-group-append">
                                      <span
                                        class="input-group-text gradient-light-blue-indigo"
                                        style="cursor: pointer;"
                                        (click)="dateOfSupply.toggle()"
                                      >
                                        <i class="fa fa-calendar"></i>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <div class="form-group col-md-6">
                                  <label for="maintenanceItemDescription">{{
                                    'SALES.INVOICES.SALE_INVOICE_DATA.MAINTENANCE_ITEM_DESCRIPTION' | translate
                                  }}</label>
                                  <textarea
                                    id="maintenanceItemDescription"
                                    class="form-control round"
                                    [placeholder]="
                                      'SALES.INVOICES.SALE_INVOICE_DATA.MAINTENANCE_ITEM_DESCRIPTION_PLACEHOLDER'
                                        | translate
                                    "
                                    rows="1"
                                    minlength="0"
                                    maxlength="200"
                                    formControlName="maintenanceItemDescription"
                                  ></textarea>
                                </div>
                                <div class="form-group col-md-6">
                                  <label for="maintenanceExpectedTime">{{
                                    'SALES.INVOICES.SALE_INVOICE_DATA.MAINTENANCE_EXPECTED_TIME' | translate
                                  }}</label>
                                  <textarea
                                    id="maintenanceExpectedTime"
                                    class="form-control round"
                                    [placeholder]="
                                      'SALES.INVOICES.SALE_INVOICE_DATA.MAINTENANCE_EXPECTED_TIME_PLACEHOLDER'
                                        | translate
                                    "
                                    rows="1"
                                    minlength="0"
                                    maxlength="100"
                                    formControlName="maintenanceExpectedTime"
                                  ></textarea>
                                </div>
                              </div>
                            </div>
                          </div>
                          <!-- customer details ends -->

                          <!-- products details starts -->
                          <div class="card-header mb-3 pt-1">
                            <h4 class="card-title">{{ 'SALES.INVOICES.SALE_INVOICE_DATA.PRODUCTS' | translate }}</h4>
                          </div>
                          <div class="card-content">
                            <div class="px-3">
                              <app-products-form
                                [formArray]="productsForm"
                                formArrayName="products"
                                [newProducts]="newProducts"
                                [showProductTypeColumn]="true"
                                [showCodeColumn]="true"
                                [autoInsertSalePrice]="true"
                                [autoInsertDiscount]="true"
                                [validateAvailableStocks]="true"
                                [availableStockLocationId]="form.value.locationId"
                                [showSalePriceInProductsSearch]="true"
                                [showDiscountByValueColumn]="true"
                                [showDiscountByPercentColumn]="true"
                                [showNotesColumn]="true"
                                [isValueRequiredToBeMoreThanZero]="false"
                              ></app-products-form>
                            </div>
                          </div>
                          <!-- products details ends -->

                          <!-- invoice summary starts -->
                          <div class="card-header mb-3 pt-4">
                            <h4 class="card-title">
                              {{ 'SALES.INVOICES.SALE_INVOICE_DATA.INVOICE_SUMMARY' | translate }}
                            </h4>
                          </div>
                          <div class="card-content">
                            <div class="px-3">
                              <div class="row">
                                <div class="col-md-12">
                                  <div class="row">
                                    <div class="form-group col-md-4">
                                      <label for="productsTotal">{{
                                        'SALES.INVOICES.SALE_INVOICE_DATA.PRODUCTS_VALUE_TOTAL' | translate
                                      }}</label>
                                      <input
                                        type="text"
                                        id="productsTotal"
                                        name="productsTotal"
                                        class="form-control round"
                                        [value]="(productsTotalWithoutDiscountAndTax | toDecimal) || '0.0'"
                                        readonly
                                      />
                                    </div>
                                    <div class="form-group col-md-4">
                                      <label for="productsDiscount">{{
                                        'SALES.INVOICES.SALE_INVOICE_DATA.DISCOUNT_TOTAL' | translate
                                      }}</label>
                                      <input
                                        type="text"
                                        id="productsDiscount"
                                        name="productsDiscount"
                                        class="form-control round"
                                        [value]="(productsDiscount | toDecimal) || '0.0'"
                                        readonly
                                      />
                                    </div>
                                    <div class="form-group col-md-4">
                                      <label for="tax">{{
                                        'SALES.INVOICES.SALE_INVOICE_DATA.TAX_TOTAL' | translate
                                      }}</label>
                                      <input
                                        type="text"
                                        id="tax"
                                        name="tax"
                                        class="form-control round"
                                        [value]="(productsTax | toDecimal) || '0.0'"
                                        readonly
                                      />
                                    </div>
                                    <div class="form-group col-md-4">
                                      <label for="invoiceNet" class="font-weight-bold" style="color: #212529;">
                                        <i class="fa fa-star"></i>
                                        <span class="mx-1">{{
                                          'SALES.INVOICES.SALE_INVOICE_DATA.INVOICE_TOTAL' | translate
                                        }}</span>
                                      </label>
                                      <input
                                        type="text"
                                        id="invoiceNet"
                                        name="invoiceNet"
                                        class="form-control round"
                                        [value]="(invoiceNet | toDecimal) || '0.0'"
                                        readonly
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <!-- invoice summary ends -->

                          <!-- invoice attachments starts -->
                          <div class="card-header mb-3 pt-1">
                            <h4 class="card-title">{{ 'SALES.INVOICES.SALE_INVOICE_DATA.ATTACHMENTS' | translate }}</h4>
                          </div>
                          <div class="card-content">
                            <div class="px-3">
                              <app-files-upload
                                formArrayName="attachments"
                                [filesForm]="attachmentsForm"
                              ></app-files-upload>
                            </div>
                          </div>
                          <!-- invoice attachments ends -->
                        </div>
                      </form>
                    </div>
                  </div>
                </ng-template>
                <!-- create maintenance invoice ends -->
              </li>
              <li ngbNavItem="journal">
                <a ngbNavLink>
                  <i class="fa fa-calendar" [class.success]="activePage == 'journal'"></i>
                  {{ 'SHARED.JOURNAL_DATA.JOURNAL' | translate }}
                </a>

                <!-- create journal form starts -->
                <ng-template ngbNavContent>
                  <div class="row">
                    <div class="col-md-12">
                      <form [formGroup]="form">
                        <div class="card">
                          <!-- journal starts -->
                          <!-- journal lines details starts -->
                          <div class="card-header mb-3">
                            <h4 class="card-title">{{ 'SHARED.JOURNAL_DATA.JOURNAL_LINES' | translate }}</h4>
                          </div>
                          <div class="card-content">
                            <div class="px-3">
                              <app-journal-line-form
                                [journalLinesForm]="journalLinesForm"
                                [isNotesRequired]="false"
                                [allowAddLine]="false"
                                [allowClearLines]="false"
                                [allowRemoveLine]="false"
                                [initialJournalLines]="initialJournalLinesSubject.asObservable() | async"
                              ></app-journal-line-form>
                            </div>
                          </div>
                          <!-- journal ends -->
                        </div>
                      </form>
                    </div>
                  </div>
                </ng-template>
                <!-- create journal form ends -->
              </li>
            </ul>
            <div [ngbNavOutlet]="nav" class="mt-2"></div>
          </div>
        </div>

        <div class="row mt-2">
          <div class="form-group col-12 d-flex justify-content-between flex-column flex-sm-row mt-2">
            <button
              *ngIf="!invoiceId"
              type="submit"
              class="btn btn-raised btn-success round"
              [disabled]="isCreating$ | async"
            >
              <i class="fa fa-check"></i>
              <span class="mx-1">{{ 'SHARED.CONFIRM.SAVE' | translate }}</span>
            </button>
            <button *ngIf="!invoiceId" type="button" class="btn btn-raised btn-warning round" (click)="openNotes()">
              <i class="fa fa-pencil"></i>
              <span class="mx-1">{{ 'SALES.INVOICES.SALE_INVOICE_DATA.NOTES_TITLE' | translate }}</span>
            </button>
            <button
              type="button"
              class="btn btn-raised btn-success round"
              routerLink="/sales/customers/payments/create"
              [queryParams]="{ invoiceId: invoiceId }"
              *ngIf="invoiceId"
            >
              <i class="fa fa-plus"></i>
              <span class="mx-1">{{ 'SALES.INVOICES.ADD_PAYMENTS' | translate }}</span>
            </button>
            <button
              type="button"
              class="btn btn-raised btn-primary round"
              [routerLink]="['/sales/invoices/view', invoiceId]"
              *ngIf="invoiceId"
            >
              <i class="fa fa-eye"></i>
              <span class="mx-1">{{ 'SALES.INVOICES.SALE_INVOICE_DATA.DISPLAY_INVOICE' | translate }}</span>
            </button>
            <button
              type="button"
              class="btn btn-raised btn-success round"
              (click)="resetForm(); invoiceId = null"
              *ngIf="invoiceId"
            >
              <i class="fa fa-plus"></i>
              <span class="mx-1">{{ 'SALES.INVOICES.SALE_INVOICE_DATA.INVOICE_NEW' | translate }}</span>
            </button>
            <button
              *ngIf="!invoiceId"
              type="button"
              class="btn btn-raised btn-danger round"
              (click)="openResetModal()"
              [disabled]="isCreating$ | async"
            >
              <i class="fa fa-trash"></i>
              <span class="mx-1">{{ 'SALES.INVOICES.SALE_INVOICE_DATA.RESET_INVOICE' | translate }}</span>
            </button>
            <a class="btn btn-dark round" routerLink="/sales/invoices">
              <i class="fa fa-undo"></i>
              <span class="mx-1">{{ 'SALES.INVOICES.SALE_INVOICE_DATA.SALES_INVOICES_RETURN' | translate }}</span>
            </a>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
<!-- maintenance invoice pages end -->

<!-- confirm maintenance invoice modal starts -->
<ng-template #confirmMaintenanceInvoiceModalRef let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
      {{ 'SALES.INVOICES.CONFIRM_CREATE_MAINTENANCE_INVOICE_TITLE' | translate }}
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="d()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p class="text-success">{{ 'SALES.INVOICES.CONFIRM_CREATE_MAINTENANCE_INVOICE_MESSAGE' | translate }}</p>
  </div>
  <div class="modal-footer">
    <div class="modal-actions">
      <button type="button" class="btn btn-raised btn-success round" (click)="c(); confirm()" ngbAutofocus>
        <i class="fa fa-check"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.SAVE' | translate }}</span>
      </button>
      <button type="button" class="btn btn-outline-dark round" (click)="c()">
        <i class="fa fa-times"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.CANCEL' | translate }}</span>
      </button>
    </div>
  </div>
</ng-template>
<!-- confirm modal ends -->

<!-- notes modal starts -->
<ng-template #notesModalRef let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title text-warning" id="modal-basic-title">
      {{ 'SALES.INVOICES.SALE_INVOICE_DATA.REGISTER_NOTES' | translate }}
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="d()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form [formGroup]="form">
      <div class="row">
        <div class="form-group col-md-12">
          <label for="notes">{{ 'SALES.INVOICES.SALE_INVOICE_DATA.NOTES' | translate }}</label>
          <textarea
            id="notes"
            class="form-control round"
            [placeholder]="'SALES.INVOICES.SALE_INVOICE_DATA.NOTES_PLACEHOLDER' | translate"
            maxlength="200"
            rows="5"
            formControlName="notes"
            ngbAutofocus
          ></textarea>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <div class="modal-actions">
      <button type="button" class="btn btn-outline-dark round" (click)="c()">
        <i class="fa fa-times"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.CLOSE' | translate }}</span>
      </button>
    </div>
  </div>
</ng-template>
<!-- notes modal ends -->

<!-- confirm journal modal starts -->
<ng-template #confirmJournalModalRef let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
      {{ 'SALES.INVOICES.CONFIRM_CREATE_JOURNAL_TITLE' | translate }}
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="d()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p class="text-success">{{ 'SALES.INVOICES.CONFIRM_CREATE_JOURNAL_MESSAGE' | translate }}</p>
  </div>
  <div class="modal-footer">
    <div class="modal-actions">
      <button type="button" class="btn btn-raised btn-success round" (click)="c(); confirm()" ngbAutofocus>
        <i class="fa fa-check"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.SAVE' | translate }}</span>
      </button>
      <button type="button" class="btn btn-outline-dark round" (click)="c()">
        <i class="fa fa-times"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.CANCEL' | translate }}</span>
      </button>
    </div>
  </div>
</ng-template>
<!-- confirm modal ends -->

<!-- confirm reset invoice modal starts -->
<ng-template #resetModalRef let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title text-danger" id="modal-basic-title">
      {{ 'SALES.INVOICES.CONFIRM_RESET_SALE_INVOICE_TITLE' | translate }}
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="d()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p class="text-danger">{{ 'SALES.INVOICES.CONFIRM_RESET_SALE_INVOICE_MESSAGE' | translate }}</p>
  </div>
  <div class="modal-footer">
    <div class="modal-actions">
      <button type="button" class="btn btn-raised btn-danger round" (click)="c(); resetForm()" ngbAutofocus>
        <i class="fa fa-check"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.YES' | translate }}</span>
      </button>
      <button type="button" class="btn btn-outline-dark round" (click)="c()">
        <i class="fa fa-times"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.CANCEL' | translate }}</span>
      </button>
    </div>
  </div>
</ng-template>
<!-- confirm modal ends -->

<!-- locations search starts -->
<app-locations-search
  onlyActive="true"
  closeOnSelect="true"
  (locations)="selectLocation($event)"
  [(visible)]="locationsListVisibility"
>
</app-locations-search>
<!-- locations search ends -->

<!-- customers search starts -->
<app-customers-search
  onlyActive="true"
  closeOnSelect="true"
  (customers)="selectCustomer($event)"
  [(visible)]="customersListVisibility"
>
</app-customers-search>
<!-- customers search ends -->
