import { CreateKitchenPrinterComponent } from 'sales/components/kitchen-printers/create-kitchen-printer/create-kitchen-printer.component';
import { UpdateKitchenPrinterComponent } from 'sales/components/kitchen-printers/update-kitchen-printer/update-kitchen-printer.component';
import { KitchenPrintersListComponent } from 'sales/components/kitchen-printers/kitchen-printers-list/kitchen-printers-list.component';
import { KitchenPrintersSearchComponent } from 'sales/components/kitchen-printers/kitchen-printers-search/kitchen-printers-search.component';

export * from 'sales/components/kitchen-printers/create-kitchen-printer/create-kitchen-printer.component';
export * from 'sales/components/kitchen-printers/update-kitchen-printer/update-kitchen-printer.component';
export * from 'sales/components/kitchen-printers/kitchen-printers-list/kitchen-printers-list.component';
export * from 'sales/components/kitchen-printers/kitchen-printers-search/kitchen-printers-search.component';

/**
 * The set of components registered on this sub-module.
 */
export const COMPONENTS: any[] = [
  CreateKitchenPrinterComponent,
  UpdateKitchenPrinterComponent,
  KitchenPrintersListComponent,
  KitchenPrintersSearchComponent,
];
