import { Action } from '@ngrx/store';

import { Location } from 'stores/models';
import { AppHttpResponse } from 'shared';

/**
 * The AppSearch-Module store locations action types.
 */
export enum LocationsActionType {
  SEARCH_LOCATIONS = '[AppSearch] [Locations] Search Locations',
  SEARCH_LOCATIONS_FAIL = '[AppSearch] [Locations] Search Locations Fail',
  SEARCH_LOCATIONS_SUCCESS = '[AppSearch] [Locations] Search Locations Success',
}

/**
 * Represents a store locations search action.
 */
export class SearchLocations implements Action {
  /**
   * The type of the action.
   */
  readonly type = LocationsActionType.SEARCH_LOCATIONS;

  /**
   * Fires a new locations search action.
   * @param payload the search parameters,
   * if omitted, all locations will be loaded.
   */
  constructor(
    public payload?: { name: string; regions: number[]; sectors: number[]; locationTypes: number[]; page: number }
  ) {}
}

/**
 * Represents a store locations search-fail action.
 */
export class SearchLocationsFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = LocationsActionType.SEARCH_LOCATIONS_FAIL;

  /**
   * Fires a new locations search-fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store search-success action.
 */
export class SearchLocationsSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = LocationsActionType.SEARCH_LOCATIONS_SUCCESS;

  /**
   * Fires a new locations search-success action.
   * @param payload An object contains the list of locations that matches the search criteria
   * in addition to the pagination info.
   */
  constructor(public payload: AppHttpResponse<Location[]>) {}
}

/**
 * Stores-Module locations action types.
 */
export type LocationsActions = SearchLocations | SearchLocationsFail | SearchLocationsSuccess;
