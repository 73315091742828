import { createSelector } from '@ngrx/store';

import { EmployeesState, AppSearchState } from 'app-search/store/states';
import { selectAppSearchState } from 'app-search/store/selectors/feature.selectors';

/**
 * Gets the employees state.
 */
const selectEmployeesState = createSelector(selectAppSearchState, (state: AppSearchState) => state.employees);

/**
 * Gets the list of loaded employees.
 */
export const getEmployees = createSelector(selectEmployeesState, (state: EmployeesState) => state.data);

/**
 * Gets the list of loaded sales persons.
 */
export const getSalesPersons = createSelector(selectEmployeesState, (state: EmployeesState) => state.salesPersonsData);

/**
 * Gets the employees pagination info.
 */
export const getEmployeesPaginationInfo = createSelector(
  selectEmployeesState,
  (state: EmployeesState) => state.paginationInfo
);

/**
 * Gets the sales persons pagination info.
 */
export const getSalesPersonsPaginationInfo = createSelector(
  selectEmployeesState,
  (state: EmployeesState) => state.salesPersonsPaginationInfo
);

/**
 * Gets the error that occurred in the state.
 */
export const getEmployeesError = createSelector(selectEmployeesState, (state: EmployeesState) => state.error);

/**
 * Determines if there is a running search process.
 */
export const getEmployeesSearching = createSelector(selectEmployeesState, (state: EmployeesState) => state.isSearching);

/**
 * Determines if the last search process has been ended successfully.
 */
export const getEmployeesSearchCompleted = createSelector(
  selectEmployeesState,
  (state: EmployeesState) => state.isSearchCompleted
);

/**
 * Determines if there is a running search process.
 */
export const getSalesPersonsSearching = createSelector(
  selectEmployeesState,
  (state: EmployeesState) => state.isSalesPersonsSearching
);

/**
 * Determines if the last search process has been ended successfully.
 */
export const getSalesPersonsSearchCompleted = createSelector(
  selectEmployeesState,
  (state: EmployeesState) => state.isSalesPersonsSearchCompleted
);
