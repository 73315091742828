import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';

import { AppHttpResponse } from 'shared';
import {
  Lead,
  UpdateLeadInput,
  CreateLeadInput,
  LeadNote,
  LeadAttachment,
  AddLeadAttachmentsInput,
  AddLeadNoteInput,
} from 'crm/models';

/**
 * The leads services includes functionality to create, search,findById, update and delete for a lead ,
 * create payment and cancel log for a lead.
 */
@Injectable()
export class LeadsService {
  /**
   * The relative route for the leads.
   *
   * No leading or trailing slashes required.
   */
  private leadsApi = 'crm/leads';
  private leadsActionsApi = 'crm/leads/actions';

  constructor(private http: HttpClient) {}

  /**
   * Creates a new lead from the provided data.
   * @param data The new lead data.
   */
  public create(data: CreateLeadInput): Observable<AppHttpResponse<Lead>> {
    const formData: any = new FormData();
    formData.append('name', data.name);
    formData.append('probability', data.probability);
    formData.append('companyName', data.companyName);
    formData.append('address', data.address);
    formData.append('contact', data.contact);
    formData.append('phone', data.phone);
    formData.append('email', data.email);
    formData.append('mobile', data.mobile);
    formData.append('website', data.website);
    formData.append('source', data.source);
    formData.append('priority', data.priority);
    formData.append('tags', data.tags);
    formData.append('userId', data.userId);

    /**
     * Append attachments to the form data.
     */
    data.attachments.forEach(
      (attachment) => attachment && formData.append(`attachments[]`, attachment, attachment.name)
    );

    return this.http.post<AppHttpResponse<Lead>>(`${this.leadsApi}`, formData);
  }

  /**
   * Creates a new lead note from the provided data.
   * @param data The new lead note data.
   */
  public createLeadNote(data: AddLeadNoteInput): Observable<AppHttpResponse<LeadNote>> {
    return this.http.post<AppHttpResponse<LeadNote>>(`${this.leadsApi}/notes`, data);
  }

  /**
   * Creates a new lead attachment from the provided data.
   * @param data The new lead attachment data.
   */
  public createLeadAttachment(data: AddLeadAttachmentsInput): Observable<AppHttpResponse<LeadAttachment[]>> {
    const formData: any = new FormData();
    formData.append('leadId', data.leadId);

    /**
     * Append attachments to the form data.
     */
    data.attachments.forEach(
      (attachment) => attachment && formData.append(`attachments[]`, attachment, attachment.name)
    );
    return this.http.post<AppHttpResponse<LeadAttachment[]>>(`${this.leadsApi}/attachments`, formData);
  }

  /**
   * Searches in the leads by name.
   * @param name The name of the lead.
   * @param page The current pagination page number.
   * @param pageSize The maximum number of leads allowed per one pagination page.
   */
  public search(name: string, page: number, pageSize: number): Observable<AppHttpResponse<Lead[]>> {
    const params = new HttpParams().set('name', name).set('page', page.toString()).set('pageSize', pageSize.toString());
    return this.http.get<AppHttpResponse<Lead[]>>(`${this.leadsApi}`, { params });
  }

  /**
   * Finds the lead with the given id.
   * @param leadId The id of the lead.
   */
  public findById(leadId: number): Observable<AppHttpResponse<Lead>> {
    return this.http.get<AppHttpResponse<Lead>>(`${this.leadsApi}/${leadId}`);
  }

  /**
   * Updates an existing lead data using the provided data.
   * @param data The updated lead data.
   */
  public update(data: UpdateLeadInput): Observable<AppHttpResponse<Lead>> {
    return this.http.put<AppHttpResponse<Lead>>(`${this.leadsApi}`, data);
  }

  /**
   * Deletes the lead by given id.
   * @param leadId The id of the lead.
   */
  public delete(leadId: number): Observable<AppHttpResponse<Lead>> {
    return this.http.delete<AppHttpResponse<Lead>>(`${this.leadsApi}/${leadId}`);
  }

  /**
   * Deletes the lead note by given id.
   * @param noteId The id of the lead note.
   */
  public deleteLeadNote(noteId: number): Observable<AppHttpResponse<LeadNote>> {
    return this.http.delete<AppHttpResponse<LeadNote>>(`${this.leadsApi}/notes/${noteId}`);
  }

  /**
   * Deletes the lead attachment by given id.
   * @param attachmentId The id of the lead attachment .
   */
  public deleteLeadAttachment(attachmentId: number): Observable<AppHttpResponse<LeadAttachment>> {
    return this.http.delete<AppHttpResponse<LeadAttachment>>(`${this.leadsApi}/attachments/${attachmentId}`);
  }

  /**
   * Updates an existing lead status mark as not handled yet data using the provided data.
   * @param leadId  The id of lead data.
   */
  public updateLeadStatusMarkAsNotHandledYet(leadId: number): Observable<AppHttpResponse<Lead>> {
    return this.http.put<AppHttpResponse<Lead>>(`${this.leadsActionsApi}/mark-as-not-handled-yet/${leadId}`, null);
  }

  /**
   * Updates an existing lead status mark as lost data using the provided data.
   * @param leadId  The id of lead data.
   */
  public updateLeadStatusMarkAsLost(leadId: number): Observable<AppHttpResponse<Lead>> {
    return this.http.put<AppHttpResponse<Lead>>(`${this.leadsActionsApi}/mark-as-lost/${leadId}`, null);
  }

  /**
   * Updates an existing lead status mark as opportunity data using the provided data.
   * @param leadId  The id of lead data.
   */
  public updateLeadStatusMarkAsOpportunity(leadId: number): Observable<AppHttpResponse<Lead>> {
    return this.http.put<AppHttpResponse<Lead>>(`${this.leadsActionsApi}/mark-as-opportunity/${leadId}`, null);
  }
}
