import { createSelector } from '@ngrx/store';

import { selectLookupsState } from 'lookups/store/selectors/feature.selectors';
import { LookupsState, EducationLevelState } from 'lookups/store/states';

/**
 * Gets the education level state.
 */
const selectEducationLevels = createSelector(selectLookupsState, (state: LookupsState) => state.educationLevels);

/**
 * Gets the list of loaded education levels.
 */
export const getEducationLevels = createSelector(selectEducationLevels, (state: EducationLevelState) => state.data);

/**
 * Gets the error that occurred in the state.
 */
export const getEducationLevelsError = createSelector(
  selectEducationLevels,
  (state: EducationLevelState) => state.error
);

/**
 * Determines if there is a running load process.
 */
export const getEducationLevelsIsLoading = createSelector(
  selectEducationLevels,
  (state: EducationLevelState) => state.isLoading
);

/**
 * Determines if the last load process has been ended successfully.
 */
export const getEducationLevelsIsLoaded = createSelector(
  selectEducationLevels,
  (state: EducationLevelState) => state.isLoaded
);
