import { Action } from '@ngrx/store';

import { AppHttpResponse } from 'shared';
import { CustomerLogType } from 'lookups/models';

/**
 * The Lookups-Module store customer advance payment log type action types.
 */
export enum CustomerAdvancePaymentLogTypeActionType {
  GET_ALL_CUSTOMER_ADVANCE_PAYMENT_LOG_TYPES = '[Lookups] [CustomerAdvancePaymentLogType] Get All Customer Advance Payment Log Types',
  GET_ALL_CUSTOMER_ADVANCE_PAYMENT_LOG_TYPES_FAIL = '[Lookups] [CustomerAdvancePaymentLogType] Get All Customer Advance Payment Log Types Fail',
  GET_ALL_CUSTOMER_ADVANCE_PAYMENT_LOG_TYPES_SUCCESS = '[Lookups] [CustomerAdvancePaymentLogType] Get All Customer Advance Payment Log Types Success',
}

/**
 * Represents a store customer advance payment log type action.
 */
export class GetAllCustomerAdvancePaymentLogType implements Action {
  /**
   * The type of the action.
   */
  readonly type = CustomerAdvancePaymentLogTypeActionType.GET_ALL_CUSTOMER_ADVANCE_PAYMENT_LOG_TYPES;

  /**
   * Fires a new customer advance payment log type action.
   */
  constructor() {}
}

/**
 * Represents a store customer advance payment log type fail action.
 */
export class GetAllCustomerAdvancePaymentLogTypeFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = CustomerAdvancePaymentLogTypeActionType.GET_ALL_CUSTOMER_ADVANCE_PAYMENT_LOG_TYPES_FAIL;

  /**
   * Fires a new customer advance payment log type fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store customer advance payment log type success action.
 */
export class GetAllCustomerAdvancePaymentLogTypeSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = CustomerAdvancePaymentLogTypeActionType.GET_ALL_CUSTOMER_ADVANCE_PAYMENT_LOG_TYPES_SUCCESS;

  /**
   * Fires a customer advance payment log type success action.
   * @param payload An object contains the list of customer advance payment log types.
   */
  constructor(public payload: AppHttpResponse<CustomerLogType[]>) {}
}

/**
 * Lookups-module customer advance payment log type action types.
 */
export type CustomerAdvancePaymentLogTypeActions =
  | GetAllCustomerAdvancePaymentLogType
  | GetAllCustomerAdvancePaymentLogTypeFail
  | GetAllCustomerAdvancePaymentLogTypeSuccess;
