import { Action } from '@ngrx/store';

import { PayrollElementType } from 'lookups/models';
import { AppHttpResponse } from 'shared';

/**
 * The Lookups-Module store payroll element classification action types.
 */
export enum PayrollElementTypeActionType {
  GET_ALL_PAYROLL_ELEMENT_TYPES = '[Lookups] [PayrollElementType] Get All Payroll Element Types',
  GET_ALL_PAYROLL_ELEMENT_TYPES_FAIL = '[Lookups] [PayrollElementType] Get All Payroll Element Types Fail',
  GET_ALL_PAYROLL_ELEMENT_TYPES_SUCCESS = '[Lookups] [PayrollElementType] Get All Payroll Element Types Success',
}

/**
 * Represents a store payroll element classification action.
 */
export class GetAllPayrollElementTypes implements Action {
  /**
   * The type of the action.
   */
  readonly type = PayrollElementTypeActionType.GET_ALL_PAYROLL_ELEMENT_TYPES;

  /**
   * Fires a new payroll element classification action.
   */
  constructor() {}
}

/**
 * Represents a store payroll element classification fail action.
 */
export class GetAllPayrollElementTypesFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = PayrollElementTypeActionType.GET_ALL_PAYROLL_ELEMENT_TYPES_FAIL;

  /**
   * Fires a new payroll element classification fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store payroll element classification success action.
 */
export class GetAllPayrollElementTypesSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = PayrollElementTypeActionType.GET_ALL_PAYROLL_ELEMENT_TYPES_SUCCESS;

  /**
   * Fires a payroll element classification success action.
   * @param payload An object contains the list of payroll element classifications.
   */
  constructor(public payload: AppHttpResponse<PayrollElementType[]>) {}
}

/**
 * Lookups-module payroll element classification action types.
 */
export type PayrollElementTypeActions =
  | GetAllPayrollElementTypes
  | GetAllPayrollElementTypesFail
  | GetAllPayrollElementTypesSuccess;
