import { PaginationInfo } from 'shared/models';
import { BankAccount } from 'finances/models';

/**
 * Represents the bank accounts state.
 */
export class BankAccountState {
  /**
   * The list of bank accounts.
   */
  public bankAccounts: BankAccount[];

  /**
   * The pagination info for the bank accounts.
   */
  public paginationInfo: PaginationInfo;

  /**
   * An error that may resulting during processing some actions.
   */
  public error: any;

  /**
   * Determines if there is a running search process.
   */
  public isSearching: boolean;

  /**
   * Determines if the last search process has been ended successfully.
   */
  public isSearchCompleted: boolean;
}
