import { Action } from '@ngrx/store';

import { PosPrinterAdjustmentType } from 'lookups/models';
import { AppHttpResponse } from 'shared';

/**
 * The Lookups-Module store pos printer adjustment action types.
 */
export enum PosPrinterAdjustmentTypeActionType {
  GET_ALL_POS_PRINTER_ADJUSTMENT_TYPES = '[Lookups] [PosPrinterAdjustmentType] Get All Pos printer adjustment Types',
  GET_ALL_POS_PRINTER_ADJUSTMENT_TYPES_FAIL = '[Lookups] [PosPrinterAdjustmentType] Get All Pos printer adjustment Types Fail',
  GET_ALL_POS_PRINTER_ADJUSTMENT_TYPES_SUCCESS = '[Lookups] [PosPrinterAdjustmentType] Get All Pos printer adjustment Types Success',
}

/**
 * Represents a store pos printer adjustment action.
 */
export class GetAllPosPrinterAdjustmentTypes implements Action {
  /**
   * The type of the action.
   */
  readonly type = PosPrinterAdjustmentTypeActionType.GET_ALL_POS_PRINTER_ADJUSTMENT_TYPES;

  /**
   * Fires a new pos printer adjustment action.
   */
  constructor() {}
}

/**
 * Represents a store pos printer adjustment fail action.
 */
export class GetAllPosPrinterAdjustmentTypesFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = PosPrinterAdjustmentTypeActionType.GET_ALL_POS_PRINTER_ADJUSTMENT_TYPES_FAIL;

  /**
   * Fires a new pos printer adjustment fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store pos printer adjustment success action.
 */
export class GetAllPosPrinterAdjustmentTypesSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = PosPrinterAdjustmentTypeActionType.GET_ALL_POS_PRINTER_ADJUSTMENT_TYPES_SUCCESS;

  /**
   * Fires a pos printer adjustment success action.
   * @param payload An object contains the list of pos printer adjustments.
   */
  constructor(public payload: AppHttpResponse<PosPrinterAdjustmentType[]>) {}
}

/**
 * Lookups-module pos printer adjustment action types.
 */
export type PosPrinterAdjustmentTypeActions =
  | GetAllPosPrinterAdjustmentTypes
  | GetAllPosPrinterAdjustmentTypesFail
  | GetAllPosPrinterAdjustmentTypesSuccess;
