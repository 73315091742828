import { createSelector } from '@ngrx/store';

import { ReportsState, PurchasesReportsState } from 'reports/store/states';
import { selectReportsState } from 'reports/store/selectors/feature.selectors';

/* Selectors */

/**
 * Gets the purchases reports state.s
 */
const selectPurchasesReportsState = createSelector(selectReportsState, (state: ReportsState) => state.purchases);

/**
 * Gets the loaded report.
 */
export const getMostReturnedPurchaseProductsReport = createSelector(
  selectPurchasesReportsState,
  (state: PurchasesReportsState) => state.mostReturnedPurchaseProducts
);

/**
 * Gets the loaded report.
 */
export const getPurchaseInvoicesReport = createSelector(
  selectPurchasesReportsState,
  (state: PurchasesReportsState) => state.purchaseInvoices
);

/**
 * Gets the loaded report.
 */
export const getPurchaseOrdersReport = createSelector(
  selectPurchasesReportsState,
  (state: PurchasesReportsState) => state.purchaseOrders
);

/**
 * Gets the loaded report.
 */
export const getPurchaseRequestsReport = createSelector(
  selectPurchasesReportsState,
  (state: PurchasesReportsState) => state.purchaseRequests
);

/**
 * Gets the loaded report.
 */
export const getPurchaseReturnsReport = createSelector(
  selectPurchasesReportsState,
  (state: PurchasesReportsState) => state.purchaseReturns
);

/**
 * Gets the loaded report.
 */
export const getTopPurchaseProductsReport = createSelector(
  selectPurchasesReportsState,
  (state: PurchasesReportsState) => state.topPurchasedProducts
);

/**
 * Gets the loaded report.
 */
export const getVendorSummaryReport = createSelector(
  selectPurchasesReportsState,
  (state: PurchasesReportsState) => state.vendorSummary
);

/**
 * Gets the loaded report.
 */
export const getVendorsDebtsReport = createSelector(
  selectPurchasesReportsState,
  (state: PurchasesReportsState) => state.vendorsDebits
);

/**
 * Gets the loaded report.
 */
export const getWorstPurchaseProductsReport = createSelector(
  selectPurchasesReportsState,
  (state: PurchasesReportsState) => state.worstPurchaseProducts
);

/**
 * Gets the loaded report.
 */
export const getWorstReturnedPurchaseProductsReport = createSelector(
  selectPurchasesReportsState,
  (state: PurchasesReportsState) => state.worstReturnedPurchaseProducts
);

/**
 * Gets the error that occurred in the state.
 */
export const getPurchasesReportsError = createSelector(
  selectPurchasesReportsState,
  (state: PurchasesReportsState) => state.error
);

/**
 * Determines if there is a running load process.
 */
export const getMostReturnedPurchaseProductsReportLoading = createSelector(
  selectPurchasesReportsState,
  (state: PurchasesReportsState) => state.isLoadingMostReturnedPurchaseProducts
);

/**
 * Determines if the last load process has been ended successfully.
 */
export const getMostReturnedPurchaseProductsReportLoadCompleted = createSelector(
  selectPurchasesReportsState,
  (state: PurchasesReportsState) => state.isMostReturnedPurchaseProductsLoadCompleted
);

/**
 * Determines if there is a running load process.
 */
export const getPurchaseInvoicesReportLoading = createSelector(
  selectPurchasesReportsState,
  (state: PurchasesReportsState) => state.isLoadingPurchaseInvoices
);

/**
 * Determines if the last load process has been ended successfully.
 */
export const getPurchaseInvoicesReportLoadCompleted = createSelector(
  selectPurchasesReportsState,
  (state: PurchasesReportsState) => state.isPurchaseInvoicesLoadCompleted
);

/**
 * Determines if there is a running load process.
 */
export const getPurchaseOrdersReportLoading = createSelector(
  selectPurchasesReportsState,
  (state: PurchasesReportsState) => state.isLoadingPurchaseOrders
);

/**
 * Determines if the last load process has been ended successfully.
 */
export const getPurchaseOrdersReportLoadCompleted = createSelector(
  selectPurchasesReportsState,
  (state: PurchasesReportsState) => state.isPurchaseOrdersLoadCompleted
);

/**
 * Determines if there is a running load process.
 */
export const getTopPurchaseProductsReportLoading = createSelector(
  selectPurchasesReportsState,
  (state: PurchasesReportsState) => state.isLoadingTopPurchasedProducts
);

/**
 * Determines if the last load process has been ended successfully.
 */
export const getTopPurchaseProductsReportLoadCompleted = createSelector(
  selectPurchasesReportsState,
  (state: PurchasesReportsState) => state.isTopPurchasedProductsLoadCompleted
);

/**
 * Determines if there is a running load process.
 */
export const getPurchaseReturnsReportLoading = createSelector(
  selectPurchasesReportsState,
  (state: PurchasesReportsState) => state.isLoadingPurchaseReturns
);

/**
 * Determines if the last load process has been ended successfully.
 */
export const getPurchaseReturnsReportLoadCompleted = createSelector(
  selectPurchasesReportsState,
  (state: PurchasesReportsState) => state.isPurchaseReturnsLoadCompleted
);

/**
 * Determines if there is a running load process.
 */
export const getVendorSummaryReportLoading = createSelector(
  selectPurchasesReportsState,
  (state: PurchasesReportsState) => state.isLoadingVendorSummary
);

/**
 * Determines if the last load process has been ended successfully.
 */
export const getVendorSummaryReportLoadCompleted = createSelector(
  selectPurchasesReportsState,
  (state: PurchasesReportsState) => state.isVendorSummaryLoadCompleted
);

/**
 * Determines if there is a running load process.
 */
export const getPurchaseRequestsReportLoading = createSelector(
  selectPurchasesReportsState,
  (state: PurchasesReportsState) => state.isLoadingPurchaseRequests
);

/**
 * Determines if the last load process has been ended successfully.
 */
export const getPurchaseRequestsReportLoadCompleted = createSelector(
  selectPurchasesReportsState,
  (state: PurchasesReportsState) => state.isPurchaseRequestsLoadCompleted
);

/**
 * Determines if there is a running load process.
 */
export const getVendorsDebtsReportLoading = createSelector(
  selectPurchasesReportsState,
  (state: PurchasesReportsState) => state.isLoadingVendorsDebts
);

/**
 * Determines if the last load process has been ended successfully.
 */
export const getVendorsDebtsReportLoadCompleted = createSelector(
  selectPurchasesReportsState,
  (state: PurchasesReportsState) => state.isVendorsDebtsLoadCompleted
);

/**
 * Determines if there is a running load process.
 */
export const getWorstPurchaseProductsReportLoading = createSelector(
  selectPurchasesReportsState,
  (state: PurchasesReportsState) => state.isLoadingWorstPurchaseProducts
);

/**
 * Determines if the last load process has been ended successfully.
 */
export const getWorstPurchaseProductsReportLoadCompleted = createSelector(
  selectPurchasesReportsState,
  (state: PurchasesReportsState) => state.isWorstPurchaseProductsLoadCompleted
);

/**
 * Determines if there is a running load process.
 */
export const getWorstReturnedPurchaseProductsReportLoading = createSelector(
  selectPurchasesReportsState,
  (state: PurchasesReportsState) => state.isLoadingWorstReturnedPurchaseProducts
);

/**
 * Determines if the last load process has been ended successfully.
 */
export const getWorstReturnedPurchaseProductsReportLoadCompleted = createSelector(
  selectPurchasesReportsState,
  (state: PurchasesReportsState) => state.isWorstReturnedPurchaseProductsLoadCompleted
);
