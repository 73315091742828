import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';

import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { skip, tap } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Decimal } from 'decimal.js';

import { PageInfo, NotificationService, NotificationMessage, CustomValidators, TranslationService } from 'shared';
import * as fromFinancesStore from 'finances/store';
import * as fromLookupsStore from 'lookups/store';
import * as fromSettingsStore from 'settings/store';
import { PettyCashRefundRequestType, PettyCashRefundRequestTypes } from 'lookups/models';
import { Tax } from 'settings/models';
import { CanComponentDeactivate } from 'auth/models';
import {
  Account,
  CreatePettyCashRefundRequestLineInput,
  PettyCash,
  PettyCashRefundRequestLineFormItem,
} from 'finances/models';

/**
 * The petty cash refund pages.
 */
enum PAGES {
  details = 'details',
}

@Component({
  selector: 'app-create-petty-cash-refund-request',
  templateUrl: './create-petty-cash-refund-request.component.html',
  styles: [],
})
export class CreatePettyCashRefundRequestComponent implements OnInit, OnDestroy, CanComponentDeactivate {
  /**
   * The confirm modal template reference.
   */
  @ViewChild('confirmModalRef') confirmModalRef: ElementRef<any>;

  /**
   * Gets or sets the information about the current page.
   */
  pageInfo: PageInfo = {
    title: 'FINANCES.PETTY_CASH_REFUND_REQUESTS.CREATE_PETTY_CASH_REFUND_REQUEST_PAGE_TITLE',
    icon: 'fa fa-plus',
  };

  /**
   * Gets or sets the selected page.
   * @default 'details'
   */
  activePage: PAGES = PAGES.details;

  /**
   * Indicates whether there is a create-petty-cash-refund-request process is running or not.
   */
  isCreating$: Observable<boolean>;

  /**
   * The list of selected petty cash.
   */
  pettyCash: PettyCash[] = [];

  /**
   * The list of types.
   */
  types$: Observable<PettyCashRefundRequestType[]>;

  /**
   * Gets or sets the id of the debit notification refund request type.
   */
  debitNotificationPettyCashRefundRequestTypeId: number;

  /**
   * The list of taxes.
   */
  taxes$: Observable<Tax[]>;

  /**
   * The default tax on the system.
   */
  defaultTax: Observable<Tax>;

  /**
   * Gets the advance expenses petty cash account.
   */
  advanceExpensesPettyCashAccount: Account;

  /**
   * Gets the tax account.
   */
  taxAccount: Account;

  /**
   * The create petty cash refund request form.
   */
  form: FormGroup;

  /**
   * Shows or hide the petty cash list.
   */
  pettyCashListVisibility = false;

  /**
   * Sets the initial lines should be added to the lines form.
   * @param lines An object contains `count` of initial blank lines.
   * @param lines A single service details to be added to the form.
   * @param lines A list of lines details to be added to the form.
   */
  initialLinesSubject: BehaviorSubject<
    { count: number } | PettyCashRefundRequestLineFormItem | PettyCashRefundRequestLineFormItem[]
  > = new BehaviorSubject(undefined);

  /**
   * The set of subscriptions on this components,
   * these subscriptions must be unsubscribed before this component got destroyed.
   */
  subscriptions = new Subscription();

  /**
   * Gets the lines form-array.
   */
  get linesForm(): FormArray {
    return this.form?.controls.lines as FormArray;
  }

  /**
   * Gets the sum of selected request lines values.
   */
  get requestLinesTotal(): number {
    return Decimal.sum(0, ...this.linesForm?.controls.map((requestLine) => requestLine.value.value ?? 0)).toNumber();
  }

  /**
   * Gets the sum of selected request lines taxes.
   */
  get requestLinesTax(): number {
    return Decimal.sum(
      0,
      ...this.linesForm?.controls.map((requestLine) =>
        requestLine.value.value < 0 || requestLine.value.tax < 0 || requestLine.value.tax > 100
          ? 0
          : Decimal.div(requestLine.value.tax, 100).mul(requestLine.value.value).toNumber() ?? 0
      )
    ).toNumber();
  }

  /**
   * Gets net value of the request.
   */
  get requestTotal(): number {
    return Decimal.add(this.requestLinesTotal, this.requestLinesTax).toNumber();
  }

  /**
   * @param modalService The modal service.
   * @param route The activated route.
   * @param locationService The location service.
   * @param notificationService The notification service.
   * @param financesStore$ The finances store.
   * @param lookupsStore$ The lookups store.
   * @param settingsStore$ The settings store.
   * @param translationService The translation service.
   */
  constructor(
    private modalService: NgbModal,
    private route: ActivatedRoute,
    private locationService: Location,
    private notificationService: NotificationService,
    private financesStore$: Store<fromFinancesStore.FinancesState>,
    private lookupsStore$: Store<fromLookupsStore.LookupsState>,
    private settingsStore$: Store<fromSettingsStore.SettingsState>,
    private translationService: TranslationService
  ) {}

  ngOnInit(): void {
    this.init();
  }

  /**
   * Destroy component data
   */
  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  /**
   * Initialize component data.
   */
  init() {
    this.initForm();

    /**
     * Load data.
     */
    this.isCreating$ = this.financesStore$.pipe(select(fromFinancesStore.getSelectedPettyCashRefundRequestCreating));

    /**
     * Reset form controls when new petty cash refund request is created.
     */
    this.subscriptions.add(
      this.financesStore$
        .pipe(
          select(fromFinancesStore.getSelectedPettyCashRefundRequestCreateCompleted),
          skip(1),
          tap((isCreated) => {
            if (isCreated) {
              this.resetForm();
            }
          })
        )
        .subscribe()
    );

    this.types$ = this.lookupsStore$.pipe(
      select(fromLookupsStore.getPettyCashRefundRequestTypes),
      tap((types) => {
        this.debitNotificationPettyCashRefundRequestTypeId =
          types?.find((type) => type.key === PettyCashRefundRequestTypes.DEBIT_NOTIFICATION)?.id ?? 0;
      })
    );

    this.taxes$ = this.settingsStore$.pipe(select(fromSettingsStore.getTaxes));
    this.defaultTax = this.settingsStore$.pipe(select(fromSettingsStore.getDefaultTax));

    /** Load data. */
    this.lookupsStore$.dispatch(new fromLookupsStore.GetAllPettyCashRefundRequestType());
    this.settingsStore$.dispatch(new fromSettingsStore.SearchTaxes({ name: '', page: 1 }));
    this.settingsStore$.dispatch(new fromSettingsStore.FindDefaultTax());

    /** Select the user desired page. */
    this.activePage = PAGES[this.route.snapshot.fragment] ?? this.activePage;
    this.selectedPageChanged(this.activePage);
  }

  /**
   * Initialize form and add validators.
   */
  initForm() {
    const linesForm = new FormArray([], CustomValidators.arrayItems(1));

    this.form = new FormGroup({
      pettyCashId: new FormControl(null, Validators.required),
      notes: new FormControl('', [Validators.minLength(0), Validators.maxLength(200)]),
      lines: linesForm,
    });

    this.setBlankLinesCount();
  }

  /**
   * Submits the form.
   */
  submit() {
    const errorMessage = new NotificationMessage();

    if (this.form.invalid) {
      if (this.form.get('pettyCashId').invalid) {
        errorMessage.title = this.translationService.translate(
          'FINANCES.PETTY_CASH_REFUND_REQUESTS.PETTY_CASH_REFUND_REQUEST_DATA_VALIDATION.PETTY_CASH_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate(
            'FINANCES.PETTY_CASH_REFUND_REQUESTS.PETTY_CASH_REFUND_REQUEST_DATA_VALIDATION.PETTY_CASH_IS_REQUIRED'
          ),
        ];
        this.activePage = PAGES.details;
      } else if (this.form.get('notes').invalid) {
        errorMessage.title = this.translationService.translate(
          'FINANCES.PETTY_CASH_REFUND_REQUESTS.PETTY_CASH_REFUND_REQUEST_DATA_VALIDATION.NOTES_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate(
            'FINANCES.PETTY_CASH_REFUND_REQUESTS.PETTY_CASH_REFUND_REQUEST_DATA_VALIDATION.NOTES_IS_REQUIRED'
          ),
        ];
        this.activePage = PAGES.details;
      } else if (this.form.get('lines').invalid) {
        /**
         * Check if lines count = 0.
         */
        if (!this.linesForm.controls.length) {
          errorMessage.title = this.translationService.translate(
            'FINANCES.PETTY_CASH_REFUND_REQUESTS.PETTY_CASH_REFUND_REQUEST_DATA_VALIDATION.PETTY_CASH_REFUND_REQUESTS_ERROR'
          );
          errorMessage.body = [
            this.translationService.translate(
              'FINANCES.PETTY_CASH_REFUND_REQUESTS.PETTY_CASH_REFUND_REQUEST_DATA_VALIDATION.PETTY_CASH_REFUND_REQUESTS_LENGTH_ERROR'
            ),
          ];
        } else {
          /**
           * Check if some of lines has errors.
           */
          for (let index = 0; index < this.linesForm.controls.length; index++) {
            const line = this.linesForm.controls[index];

            if (line.valid) {
              continue;
            }

            errorMessage.title = this.translationService.translate(
              'FINANCES.PETTY_CASH_REFUND_REQUESTS.PETTY_CASH_REFUND_REQUEST_DATA_VALIDATION.REQUEST_NUMBER_ERROR',
              {
                requestNumber: index + 1,
              }
            );
            errorMessage.body = [];

            if (line.get('description').invalid) {
              errorMessage.body.push(
                this.translationService.translate(
                  'FINANCES.PETTY_CASH_REFUND_REQUESTS.PETTY_CASH_REFUND_REQUEST_DATA_VALIDATION.DESCRIPTION_IS_REQUIRED'
                )
              );
            }

            if (line.get('pettyCashRefundRequestTypeId').invalid) {
              errorMessage.body.push(
                this.translationService.translate(
                  'FINANCES.PETTY_CASH_REFUND_REQUESTS.PETTY_CASH_REFUND_REQUEST_DATA_VALIDATION.PETTY_CASH_REFUND_REQUEST_TYPE_IS_REQUIRED'
                )
              );
            }

            if (line.get('vendorName').invalid) {
              errorMessage.body.push(
                this.translationService.translate(
                  'FINANCES.PETTY_CASH_REFUND_REQUESTS.PETTY_CASH_REFUND_REQUEST_DATA_VALIDATION.VENDOR_IS_REQUIRED'
                )
              );
            }

            if (line.get('value').invalid) {
              errorMessage.body.push(
                this.translationService.translate(
                  'FINANCES.PETTY_CASH_REFUND_REQUESTS.PETTY_CASH_REFUND_REQUEST_DATA_VALIDATION.VALUE_LENGTH_ERROR'
                )
              );
            }

            if (line.get('tax').invalid) {
              errorMessage.body.push(
                this.translationService.translate(
                  'FINANCES.PETTY_CASH_REFUND_REQUESTS.PETTY_CASH_REFUND_REQUEST_DATA_VALIDATION.TAX_IS_REQUIRED'
                )
              );
            }

            if (line.get('invoiceDate').invalid) {
              errorMessage.body.push(
                this.translationService.translate(
                  'FINANCES.PETTY_CASH_REFUND_REQUESTS.PETTY_CASH_REFUND_REQUEST_DATA_VALIDATION.INVOICE_DATE_IS_REQUIRED'
                )
              );
            }

            break;
          }
        }
        this.activePage = PAGES.details;
      }

      this.form.markAllAsTouched();
      return this.notificationService.warningWithTitle(errorMessage);
    }

    this.openModal(this.confirmModalRef);
  }

  /**
   * Confirms the form submit.
   */
  confirm() {
    /**
     * The list of lines in the lines form.
     */

    const lines: CreatePettyCashRefundRequestLineInput[] = this.linesForm.value.map(
      (item: PettyCashRefundRequestLineFormItem) => {
        const line: CreatePettyCashRefundRequestLineInput = {
          description: item.description,
          pettyCashRefundRequestTypeId: item.pettyCashRefundRequestTypeId,
          vendorName: item.vendorName,
          value: item.value,
          tax: item.tax,
          invoiceDate: new Date(item.invoiceDate.year, item.invoiceDate.month - 1, item.invoiceDate.day),
          attachments: item.attachments.filter((file) => !!file),
        };
        return line;
      }
    );

    this.financesStore$.dispatch(
      new fromFinancesStore.CreatePettyCashRefundRequest({
        pettyCashId: this.form.value.pettyCashId,
        notes: this.form.value.notes,
        lines,
      })
    );
  }

  /**
   * Opens the modal of the given templateRef.
   * @param modalRef The modal templateRef to be opened.
   */
  openModal(modalRef) {
    this.modalService.open(modalRef);
  }

  /**
   * Selects the newly selected petty from the petty cash search list.
   * @param pettyCash The list of newly selected petty cash to select the only one in the list.
   */
  selectPettyCash([petty]: PettyCash[]) {
    if (petty) {
      this.pettyCash = [petty];
      this.form.patchValue({ pettyCashId: petty.id });
    }
  }

  /**
   * Sets the count of the petty-cash request lines should be added to the petty-cash request lines form for quick start..
   */
  setBlankLinesCount() {
    this.initialLinesSubject.next({ count: 0 });
  }

  /**
   * Resets all form data.
   *
   * It only adds one product for quick start.
   */
  resetForm() {
    this.initForm();
    this.form.markAsUntouched();
  }

  /**
   * Updates the browser url according to the selected page.
   * @param page The newly selected page.
   */
  selectedPageChanged(page: PAGES) {
    this.locationService.replaceState(`${this.locationService.path()}#${PAGES[page]}`);
  }

  /**
   * Confirms leaving the page before routing.
   */
  confirmDeactivate() {
    if (this.form.dirty === true) {
      /**
       * Message to warn the user before leaving the detailed form.
       */
      return confirm(this.translationService.translate('SHARED.CONFIRM.LEAVING'));
    }
    return true;
  }
}
