<div class="row mb-1">
  <div class="col-md-auto">
    <button
      *ngIf="allowAddOrderType"
      type="button"
      class="btn btn-sm btn-raised btn-primary round"
      [ngbTooltip]="'SALES.INVOICES.SALE_INVOICE_DATA.ADD_ORDER_TYPE' | translate"
      placement="top-right"
      (click)="addOrderType()"
    >
      <i class="fa fa-plus"></i>
    </button>
    <button
      *ngIf="allowClearOrderTypes"
      type="button"
      class="btn btn-sm btn-raised btn-danger round mx-2"
      [ngbTooltip]="'SALES.INVOICES.SALE_INVOICE_DATA.DELETE_ORDER_TYPE' | translate"
      placement="left"
      (click)="openDeleteModal()"
      [disabled]="formArray.controls.length == 0"
    >
      <i class="fa fa-trash"></i>
    </button>
  </div>
</div>
<div [formGroup]="formArray.parent" class="row">
  <div class="col-md-12">
    <div class="table-responsive">
      <table class="table text-center">
        <thead>
          <tr>
            <th scope="col">
              <i class="fa fa-list-ol"></i>
            </th>
            <th scope="col">
              <i class="fa fa-map-signs mr-1"></i>
              {{ 'SALES.INVOICES.SALE_INVOICE_DATA.ORDER_TYPE' | translate }}
            </th>
            <th scope="col">{{ 'SHARED.TOOLS' | translate }}</th>
          </tr>
        </thead>
        <tbody [formArrayName]="formArrayName">
          <tr [formGroup]="formGroup" *ngFor="let formGroup of formArray.controls; let index = index">
            <th scope="row">{{ index + 1 }}</th>
            <td>
              {{ formGroup.value.orderType.name | localize: formGroup.value.orderType.nameEn | async }}
            </td>
            <td>
              <a class="danger p-0" [ngbTooltip]="'SHARED.CONFIRM.DELETE' | translate" (click)="removeOrderType(index)">
                <i class="fa fa-close font-medium-3 mr-2"></i>
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>

<!-- order types search starts -->
<app-order-types-search
  [closeOnSelect]="false"
  (orderTypes)="selectOrderTypes($event)"
  [(visible)]="orderTypesListVisibility"
>
</app-order-types-search>
<!-- order types search ends -->

<!-- confirm delete all order types modal starts -->
<ng-template #deleteModalRef let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title text-danger" id="modal-basic-title">
      {{ 'SALES.INVOICES.SALE_INVOICE_DATA.CONFIRM_DELETE_ALL_ORDER_TYPES_TITLE' | translate }}
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="d()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p class="text-danger">
      {{ 'SALES.INVOICES.SALE_INVOICE_DATA.CONFIRM_DELETE_ALL_ORDER_TYPES_MESSAGE' | translate }}
    </p>
  </div>
  <div class="modal-footer">
    <div class="modal-actions">
      <button type="button" class="btn btn-raised btn-danger round" (click)="c(); resetOrderTypes()" ngbAutofocus>
        <i class="fa fa-check"></i>
        <span class="mx-1"> {{ 'SHARED.CONFIRM.YES' | translate }} </span>
      </button>
      <button type="button" class="btn btn-outline-dark round" (click)="c()">
        <i class="fa fa-times"></i>
        <span class="mx-1"> {{ 'SHARED.CONFIRM.CANCEL' | translate }} </span>
      </button>
    </div>
  </div>
</ng-template>
<!-- confirm modal ends -->
