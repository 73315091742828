import { Injectable } from '@angular/core';

import { of } from 'rxjs';
import { switchMap, map, catchError } from 'rxjs/operators';
import { Effect, ofType, Actions } from '@ngrx/effects';

import { LookupsService } from 'lookups/services';
import {
  GetAllNationalIdentifierTypesFail,
  GetAllNationalIdentifierTypesSuccess,
  NationalIdentifierTypeActionType,
} from 'lookups/store/actions';

@Injectable()
export class NationalIdentifierTypeEffects {
  constructor(private actions$: Actions, private lookupsService: LookupsService) {}

  /* ========================= GET_ALL_NATIONAL_IDENTIFIER_TYPES =================================== */
  @Effect()
  getAllNationalIdentifierTypes$ = this.actions$.pipe(
    ofType(NationalIdentifierTypeActionType.GET_ALL_NATIONAL_IDENTIFIER_TYPES),
    switchMap(() =>
      this.lookupsService.getAllNationalIdentifierTypes().pipe(
        map((response) => new GetAllNationalIdentifierTypesSuccess(response)),
        catchError((error) => of(new GetAllNationalIdentifierTypesFail(error)))
      )
    )
  );
}
