import { PurchaseInvoiceType } from 'lookups/models';

/**
 * Represents the purchase invoice types state.
 */
export class PurchaseInvoiceTypeState {
  /**
   * The list of purchase invoice types.
   */
  public data: PurchaseInvoiceType[];

  /**
   * An error that may resulting during processing some actions.
   */
  public error: any;

  /**
   * Determines if there is a running load process.
   */
  public isLoading: boolean;

  /**
   * Determines if the last load process has been ended successfully.
   */
  public isLoaded: boolean;
}
