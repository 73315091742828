import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';

import { AppHttpResponse } from 'shared';
import { CustomerEngagement, CreateCustomerEngagementInput, UpdateCustomerEngagementInput } from 'sales/models';

/**
 * The customer engagements services includes functionality to get customer engagements, findById,search, create, update and delete
 * a customer engagement.
 */
@Injectable()
export class CustomerEngagementsService {
  /**
   * The relative route for the customer engagements.
   *
   * No leading or trailing slashes required.
   */
  private customerEngagementsApi = 'sales/customers';

  constructor(private http: HttpClient) {}

  /**
   * Creates a new customer engagement from the provided data.
   * @param data The new customer engagement data.
   */
  public create(data: CreateCustomerEngagementInput): Observable<AppHttpResponse<CustomerEngagement>> {
    return this.http.post<AppHttpResponse<CustomerEngagement>>(`${this.customerEngagementsApi}/engagements`, data);
  }

  /**
   * Search the customer engagements.
   * @param customers The customers of the sales invoice.
   * @param customerEngagementTypes The engagementT types of the customer.
   * @param page The current pagination page number.
   * @param pageSize The maximum number of customer engagements allowed per one pagination page.
   */
  public search(
    customers: number[],
    customerEngagementTypes: number[],
    page: number,
    pageSize: number
  ): Observable<AppHttpResponse<CustomerEngagement[]>> {
    const params = new HttpParams()
      .set('customers', customers.join(','))
      .set('customerEngagementTypes', customerEngagementTypes.join(','))
      .set('page', page.toString())
      .set('pageSize', pageSize.toString());
    return this.http.get<AppHttpResponse<CustomerEngagement[]>>(`${this.customerEngagementsApi}/engagements`, {
      params,
    });
  }

  /**
   * Finds the customer engagement with the given id.
   * @param id The id of the customer engagement.
   */
  public findById(id: number): Observable<AppHttpResponse<CustomerEngagement>> {
    return this.http.get<AppHttpResponse<CustomerEngagement>>(`${this.customerEngagementsApi}/engagements/${id}`);
  }

  /**
   * Updates an existing customer engagement data using the provided data.
   * @param data The updated customer engagement data.
   */
  public update(data: UpdateCustomerEngagementInput): Observable<AppHttpResponse<CustomerEngagement>> {
    return this.http.put<AppHttpResponse<CustomerEngagement>>(`${this.customerEngagementsApi}/engagements`, data);
  }

  /**
   * Deletes the customer engagement by given id.
   * @param id The id of the customer engagement.
   */
  public delete(id: number): Observable<AppHttpResponse<CustomerEngagement>> {
    return this.http.delete<AppHttpResponse<CustomerEngagement>>(`${this.customerEngagementsApi}/engagements/${id}`);
  }
}
