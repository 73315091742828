import { ActionReducerMap } from '@ngrx/store';
import { taxesReducer } from 'settings/store/reducers/taxes.reducer';
import { SettingsState } from 'settings/store/states';
import { organizationSettingsReducer } from 'settings/store/reducers/organization-settings.reducer';

/**
 * The Taxes-Module store reducers.
 */
export const reducers: ActionReducerMap<SettingsState> = {
  taxes: taxesReducer,
  organizationSettings: organizationSettingsReducer,
};
