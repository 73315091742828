import { Component, Input } from '@angular/core';

import { Observable } from 'rxjs';

import { ProjectResourceLine } from 'projects-management/models';

@Component({
  selector: 'app-project-resource-asset-depreciation-details',
  templateUrl: './project-resource-asset-depreciation-details.component.html',
  styles: [],
})
export class ProjectResourceAssetDepreciationDetailsComponent {
  /**
   * The list of project resource lines.
   */
  @Input() projectResourceAssetDepreciationLines$: Observable<ProjectResourceLine[]>;
}
