import { Action } from '@ngrx/store';

import { SaleInvoiceType } from 'lookups/models';
import { AppHttpResponse } from 'shared';

/**
 * The Lookups-Module store sale invoice type action types.
 */
export enum SaleInvoiceTypeActionType {
  GET_ALL_SALE_INVOICE_TYPES = '[Lookups] [SaleInvoiceType] Get All Sale Invoice Types',
  GET_ALL_SALE_INVOICE_TYPES_FAIL = '[Lookups] [SaleInvoiceType] Get All Sale Invoice Types Fail',
  GET_ALL_SALE_INVOICE_TYPES_SUCCESS = '[Lookups] [SaleInvoiceType] Get All Sale Invoice Types Success',
}

/**
 * Represents a store sale invoice type action.
 */
export class GetAllSaleInvoiceType implements Action {
  /**
   * The type of the action.
   */
  readonly type = SaleInvoiceTypeActionType.GET_ALL_SALE_INVOICE_TYPES;

  /**
   * Fires a new sale invoice type action.
   */
  constructor() {}
}

/**
 * Represents a store sale invoice type fail action.
 */
export class GetAllSaleInvoiceTypeFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = SaleInvoiceTypeActionType.GET_ALL_SALE_INVOICE_TYPES_FAIL;

  /**
   * Fires a new sale invoice type fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store sale invoice type success action.
 */
export class GetAllSaleInvoiceTypeSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = SaleInvoiceTypeActionType.GET_ALL_SALE_INVOICE_TYPES_SUCCESS;

  /**
   * Fires a sale invoice type success action.
   * @param payload An object contains the list of sale invoice types.
   */
  constructor(public payload: AppHttpResponse<SaleInvoiceType[]>) {}
}

/**
 * Lookups-module sale invoice type action types.
 */
export type SaleInvoiceTypeActions = GetAllSaleInvoiceType | GetAllSaleInvoiceTypeFail | GetAllSaleInvoiceTypeSuccess;
