import { Language } from 'lookups/models';

/**
 * Represents the languages state.
 */
export class LanguagesState {
  /**
   * The list of languages.
   */
  public data: Language[];
}
