import { ActionReducerMap } from '@ngrx/store';

import { userReportsReducer } from 'reports/store/reducers/user-reports.reducer';
import { storesReportsReducer } from 'reports/store/reducers/stores.reducer';
import { financesReportsReducer } from 'reports/store/reducers/finances.reducer';
import { purchasesReportsReducer } from 'reports/store/reducers/purchases.reducer';
import { salesReportsReducer } from 'reports/store/reducers/sales.reducer';
import { ReportsState } from 'reports/store/states';

/**
 * The Reports-Module store reducers.
 */
export const reducers: ActionReducerMap<ReportsState> = {
  userReports: userReportsReducer,
  stores: storesReportsReducer,
  finances: financesReportsReducer,
  purchases: purchasesReportsReducer,
  sales: salesReportsReducer,
};
