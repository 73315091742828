import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import * as fromServices from 'printing/services';
import * as fromStore from 'printing/store';

/**
 * The printing-module that includes all system prints.
 * @summary This module should be imported to the root-module so that it is available to all modules.
 */
@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('printing', fromStore.reducers),
    EffectsModule.forFeature(fromStore.effects),
  ],
  providers: [...fromServices.SERVICES],
})
export class PrintingModule {}
