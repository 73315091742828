import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subscription } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { skip, tap } from 'rxjs/operators';

import { CustomerAsset, CustomerAssetAttachment } from 'sales/models';
import { Claims } from 'security/models';
import { NotificationMessage, NotificationService, TranslationService } from 'shared';
import * as fromSalesStore from 'sales/store';

@Component({
  selector: 'app-customer-assets',
  templateUrl: './customer-assets.component.html',
  styles: [],
})
export class CustomerAssetsComponent implements OnInit, OnDestroy {
  /**
   * The create modal template reference.
   */
  @ViewChild('createModalRef') createModalRef: ElementRef<any>;

  /**
   * The update modal template reference.
   */
  @ViewChild('updateModalRef') updateModalRef: ElementRef<any>;

  /**
   * The delete modal template reference.
   */
  @ViewChild('deleteModalRef') deleteModalRef: ElementRef<any>;

  /**
   * The view attachments modal template reference.
   */
  @ViewChild('viewAttachmentsModalRef') viewAttachmentsModalRef: ElementRef<any>;

  /**
   * Sets the currently customer id.
   */
  @Input() customerId: number;

  /**
   * The list of loaded customer assets.
   */
  assets$: Observable<CustomerAsset[]>;

  /**
   * The system supported user claims.
   */
  Claims = Claims;

  /**
   * The create customer asset form.
   */
  createForm: FormGroup;

  /**
   * The update customer asset form.
   */
  updateForm: FormGroup;

  /**
   * Indicates whether there is a create customer asset process is running or not.
   */
  isCustomerAssetCreating$: Observable<boolean>;

  /**
   * Indicates whether there is a update customer asset process is running or not.
   */
  isCustomerAssetUpdating$: Observable<boolean>;

  /**
   * Indicates whether there is a delete customer asset process is running or not.
   */
  isCustomerAssetDeleting$: Observable<boolean>;

  /**
   * The selected customer asset;
   */
  selectedAsset: CustomerAsset;

  /**
   * The set of subscriptions on this components,
   * these subscriptions must be unsubscribed before this component got destroyed.
   */
  subscriptions = new Subscription();

  /**
   * Gets the attachments form-array.
   */
  get attachmentsForm(): FormArray {
    return this.createForm?.controls.attachments as FormArray;
  }

  /**
   * @param modalService The modal service.
   * @param notificationService The notification service.
   * @param salesStore$ The sales-module store.
   * @param translationService: The translation service.
   */
  constructor(
    private modalService: NgbModal,
    private notificationService: NotificationService,
    private salesStore$: Store<fromSalesStore.SalesState>,
    private translationService: TranslationService
  ) {}

  ngOnInit(): void {
    this.init();
  }

  /**
   * Destroy component data
   */
  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  /**
   * Initialize component data.
   */
  init() {
    this.initForms();

    /**
     * reset on customer asset creation complete
     */
    this.subscriptions.add(
      this.salesStore$
        .pipe(
          select(fromSalesStore.getSelectedCustomerAssetCreateCompleted),
          skip(1),
          tap((created) => {
            if (created) {
              this.initForms();
              this.createForm.markAsUntouched();
            }
          })
        )
        .subscribe()
    );

    /**
     * reset on customer asset update completed
     */
    this.subscriptions.add(
      this.salesStore$
        .pipe(
          select(fromSalesStore.getSelectedCustomerAssetUpdateCompleted),
          skip(1),
          tap((updated) => {
            if (updated) {
              this.initForms();
              this.updateForm.markAsUntouched();
              this.closeModal();
            }
          })
        )
        .subscribe()
    );

    /**
     * reset on customer asset delete completed
     */
    this.subscriptions.add(
      this.salesStore$
        .pipe(
          select(fromSalesStore.getSelectedCustomerAssetDeleteCompleted),
          skip(1),
          tap((deleted) => {
            if (deleted) {
              this.initForms();
              this.closeModal();
            }
          })
        )
        .subscribe()
    );

    /**
     * Load data.
     */
    this.isCustomerAssetCreating$ = this.salesStore$.pipe(select(fromSalesStore.getSelectedCustomerAssetCreating));
    this.isCustomerAssetUpdating$ = this.salesStore$.pipe(select(fromSalesStore.getSelectedCustomerAssetUpdating));
    this.isCustomerAssetDeleting$ = this.salesStore$.pipe(select(fromSalesStore.getSelectedCustomerAssetDeleting));
    this.assets$ = this.salesStore$.pipe(select(fromSalesStore.getCustomerAssets));

    this.search();
  }

  /**
   * Initialize forms and add validators.
   */
  initForms() {
    this.createForm = new FormGroup({
      description: new FormControl('', [Validators.required, Validators.minLength(1), Validators.maxLength(200)]),
      type: new FormControl('', Validators.maxLength(100)),
      serialNumber: new FormControl('', Validators.maxLength(100)),
      attachments: new FormArray([]),
    });

    this.updateForm = new FormGroup({
      description: new FormControl('', [Validators.required, Validators.minLength(1), Validators.maxLength(200)]),
      type: new FormControl('', Validators.maxLength(100)),
      serialNumber: new FormControl('', Validators.maxLength(100)),
    });

    this.addBlankAttachment();
  }

  /**
   * Loads the current selected customer assets.
   */
  search() {
    this.salesStore$.dispatch(
      new fromSalesStore.SearchCustomersAssets({
        customers: [this.customerId],
        page: 1,
      })
    );
  }

  /**
   * Enables trackBy feature for *ngFor utility to track items
   * depending on a comparer to reduce DOM change.
   * If the item is already exist then no HTML will change, only bounded values.
   * @param index The index of the item.
   * @param asset The item to determine if it was changed or not.
   */
  trackItems(index: number, asset: CustomerAsset) {
    return asset ? asset.id : undefined;
  }

  /**
   * Shows create new asset modal.
   */
  create() {
    this.openModal(this.createModalRef);
  }

  /**
   * Creates a new customer asset only if the filled data passed validations.
   */
  confirmCreate() {
    if (this.createForm.invalid) {
      const errorMessage = new NotificationMessage();

      if (this.createForm.get('description').invalid) {
        errorMessage.title = this.translationService.translate(
          'SALES.CUSTOMER_ASSETS.CUSTOMER_ASSET_DATA_VALIDATION.CUSTOMER_ASSET_DESCRIPTION_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate(
            'SALES.CUSTOMER_ASSETS.CUSTOMER_ASSET_DATA_VALIDATION.CUSTOMER_ASSET_DESCRIPTION_IS_REQUIRED'
          ),
          this.translationService.translate(
            'SALES.CUSTOMER_ASSETS.CUSTOMER_ASSET_DATA_VALIDATION.CUSTOMER_ASSET_DESCRIPTION_LENGTH_ERROR'
          ),
        ];
      } else if (this.createForm.get('type').invalid) {
        errorMessage.title = this.translationService.translate(
          'SALES.CUSTOMER_ASSETS.CUSTOMER_ASSET_DATA_VALIDATION.CUSTOMER_ASSET_TYPE_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate(
            'SALES.CUSTOMER_ASSETS.CUSTOMER_ASSET_DATA_VALIDATION.CUSTOMER_ASSET_TYPE_LENGTH_ERROR'
          ),
        ];
      } else if (this.createForm.get('serialNumber').invalid) {
        errorMessage.title = this.translationService.translate(
          'SALES.CUSTOMER_ASSETS.CUSTOMER_ASSET_DATA_VALIDATION.CUSTOMER_SERIAL_NUMBER_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate(
            'SALES.CUSTOMER_ASSETS.CUSTOMER_ASSET_DATA_VALIDATION.CUSTOMER_SERIAL_NUMBER_LENGTH_ERROR'
          ),
        ];
      }

      this.createForm.markAllAsTouched();
      return this.notificationService.warningWithTitle(errorMessage);
    } else {
      const { description, serialNumber, type } = this.createForm.value;
      this.salesStore$.dispatch(
        new fromSalesStore.CreateCustomerAsset({
          description,
          serialNumber,
          customerId: this.customerId,
          type,
          attachments: this.attachmentsForm.value.filter((file) => !!file),
        })
      );
    }
  }

  /**
   * Shows an update asset modal for the given asset.
   * @param asset The asset to be updated.
   */
  update(asset: CustomerAsset) {
    this.updateForm.reset({
      ...asset,
    });
    this.selectedAsset = asset;
    this.openModal(this.updateModalRef);
  }

  /**
   * Updates the currently edited asset only if the filled data passed validations.
   */
  confirmUpdate() {
    if (this.updateForm.invalid) {
      const errorMessage = new NotificationMessage();

      if (this.updateForm.get('id').invalid) {
        errorMessage.title = this.translationService.translate(
          'SALES.CUSTOMER_ASSETS.CUSTOMER_ASSET_DATA_VALIDATION.CUSTOMER_ASSET_ERROR'
        );
        errorMessage.body = [];
      } else if (this.createForm.get('description').invalid) {
        errorMessage.title = this.translationService.translate(
          'SALES.CUSTOMER_ASSETS.CUSTOMER_ASSET_DATA_VALIDATION.CUSTOMER_ASSET_DESCRIPTION_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate(
            'SALES.CUSTOMER_ASSETS.CUSTOMER_ASSET_DATA_VALIDATION.CUSTOMER_ASSET_DESCRIPTION_IS_REQUIRED'
          ),
          this.translationService.translate(
            'SALES.CUSTOMER_ASSETS.CUSTOMER_ASSET_DATA_VALIDATION.CUSTOMER_ASSET_DESCRIPTION_LENGTH_ERROR'
          ),
        ];
      } else if (this.createForm.get('type').invalid) {
        errorMessage.title = this.translationService.translate(
          'SALES.CUSTOMER_ASSETS.CUSTOMER_ASSET_DATA_VALIDATION.CUSTOMER_ASSET_TYPE_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate(
            'SALES.CUSTOMER_ASSETS.CUSTOMER_ASSET_DATA_VALIDATION.CUSTOMER_ASSET_TYPE_LENGTH_ERROR'
          ),
        ];
      } else if (this.createForm.get('serialNumber').invalid) {
        errorMessage.title = this.translationService.translate(
          'SALES.CUSTOMER_ASSETS.CUSTOMER_ASSET_DATA_VALIDATION.CUSTOMER_SERIAL_NUMBER_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate(
            'SALES.CUSTOMER_ASSETS.CUSTOMER_ASSET_DATA_VALIDATION.CUSTOMER_SERIAL_NUMBER_LENGTH_ERROR'
          ),
        ];
      }

      this.updateForm.markAllAsTouched();
      return this.notificationService.warningWithTitle(errorMessage);
    } else {
      this.salesStore$.dispatch(
        new fromSalesStore.UpdateCustomerAsset({
          ...this.updateForm.value,
          id: this.selectedAsset.id,
        })
      );
    }
  }

  /**
   * Shows an delete asset modal for the given asset.
   * @param asset The asset to be deleted.
   */
  delete(asset: CustomerAsset) {
    this.selectedAsset = asset;
    this.openModal(this.deleteModalRef);
  }

  /**
   * Deletes the currently selected asset.
   */
  confirmDelete() {
    this.salesStore$.dispatch(new fromSalesStore.DeleteCustomerAsset(this.selectedAsset.id));
  }

  /**
   * Opens the modal of the given templateRef.
   * @param modalRef The modal templateRef to be opened.
   * @param modalSize An optional modal size, default is medium.
   */
  openModal(modalRef, modalSize?: string) {
    this.modalService.open(modalRef, { size: modalSize });
  }

  /**
   * Closes the currently opened modal.
   */
  closeModal() {
    this.modalService.dismissAll();
  }

  /**
   * Adds a blank attachment form-control to the attachments form for quick start..
   */
  addBlankAttachment() {
    this.attachmentsForm.push(new FormControl());
  }

  /**
   * Views the selected customer assets attachments.
   * @param asset The asset to view its attachments.
   */
  viewAttachments(asset: CustomerAsset) {
    this.selectedAsset = asset;
    this.openModal(this.viewAttachmentsModalRef);
  }

  /**
   * Maps and returns the set of customer asset attachments into a set of urls.
   * @param attachments The customer asset attachments to be mapped.
   */
  getAttachmentsUrls(attachments: CustomerAssetAttachment[]): string[] {
    return attachments ? attachments.map((attachment) => attachment.url) : [];
  }
}
