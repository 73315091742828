import { Injectable } from '@angular/core';

import { Effect, ofType, Actions } from '@ngrx/effects';
import { switchMap, map, catchError, tap } from 'rxjs/operators';
import { of } from 'rxjs';

import { NotificationService, StatusCode, TranslationService } from 'shared';
import { PendingPosInvoicesService } from 'sales/services';
import {
  PendingPosInvoicesActionType,
  PreparePointOfSaleInvoices,
  PreparePointOfSaleInvoicesFail,
  PreparePointOfSaleInvoicesSuccess,
  UpdatePreparePointOfSaleInvoice,
  UpdatePreparePointOfSaleInvoiceFail,
  UpdatePreparePointOfSaleInvoiceSuccess,
} from 'sales/store/actions';

@Injectable()
export class PendingPosInvoicesEffects {
  constructor(
    private actions$: Actions,
    private pendingPosInvoicesService: PendingPosInvoicesService,
    private notificationService: NotificationService,
    private translationService: TranslationService
  ) {}

  /* ========================= PREPARE_POINT_OF_SALE_INVOICES =================================== */
  @Effect()
  preparePointOfSaleInvoices$ = this.actions$.pipe(
    ofType(PendingPosInvoicesActionType.PREPARE_POINT_OF_SALE_INVOICES),
    switchMap(({ payload }: PreparePointOfSaleInvoices) => {
      return this.pendingPosInvoicesService.PreparePosInvoices(payload.locations, payload.productClasses).pipe(
        map((response) => new PreparePointOfSaleInvoicesSuccess(response)),
        catchError((error) => of(new PreparePointOfSaleInvoicesFail(error)))
      );
    })
  );

  /* ========================= UPDATING_POINT_OF_SALE_INVOICE =================================== */
  @Effect()
  update$ = this.actions$.pipe(
    ofType(PendingPosInvoicesActionType.UPDATE_PREPARE_POINT_OF_SALE_INVOICE),
    switchMap((action: UpdatePreparePointOfSaleInvoice) =>
      this.pendingPosInvoicesService.updatePendingPosInvoice(action.payload).pipe(
        map((response) => new UpdatePreparePointOfSaleInvoiceSuccess(response)),
        catchError((error) => of(new UpdatePreparePointOfSaleInvoiceFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  updateSuccess$ = this.actions$.pipe(
    ofType(PendingPosInvoicesActionType.UPDATE_PREPARE_POINT_OF_SALE_INVOICE_SUCCESS),
    tap((action: UpdatePreparePointOfSaleInvoiceSuccess) => {
      this.notificationService.success(
        this.translationService.translate('SALES.PENDING_POS_INVOICES.POS_INVOICES_UPDATED')
      );
    })
  );

  @Effect({ dispatch: false })
  updateFail$ = this.actions$.pipe(
    ofType(PendingPosInvoicesActionType.UPDATE_PREPARE_POINT_OF_SALE_INVOICE_FAIL),
    tap((action: UpdatePreparePointOfSaleInvoiceFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate('SALES.PENDING_POS_INVOICES.POS_INVOICES_NOT_FOUND')
        );
      } else if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );
}
