import { Injectable } from '@angular/core';

import { of } from 'rxjs';
import { switchMap, map, catchError } from 'rxjs/operators';
import { Effect, ofType, Actions } from '@ngrx/effects';

import { ClaimsService } from 'security/services';
import {
  ClaimsActionType,
  GetClaimsGroups,
  GetClaimsGroupsFail,
  GetClaimsGroupsSuccess,
  GetCurrentLoggedInUserClaimsGroups,
  GetCurrentLoggedInUserClaimsGroupsFail,
  GetCurrentLoggedInUserClaimsGroupsSuccess,
} from 'security/store/actions';

@Injectable()
export class ClaimsEffects {
  constructor(private actions$: Actions, private claimsService: ClaimsService) {}

  /* ========================= GET_CLAIMS_GROUPS =================================== */
  @Effect()
  GetClaimsGroups$ = this.actions$.pipe(
    ofType(ClaimsActionType.GET_CLAIMS_GROUPS),
    switchMap((action: GetClaimsGroups) =>
      this.claimsService.getClaimsGroups().pipe(
        map((response) => new GetClaimsGroupsSuccess(response)),
        catchError((error) => of(new GetClaimsGroupsFail(error)))
      )
    )
  );

  /* ========================= GET_CURRENT_LOGGED_IN_USER_CLAIMS_GROUPS =================================== */
  @Effect()
  GetCurrentLoggedInUserClaimsGroups$ = this.actions$.pipe(
    ofType(ClaimsActionType.GET_CURRENT_LOGGED_IN_USER_CLAIMS_GROUPS),
    switchMap((action: GetCurrentLoggedInUserClaimsGroups) =>
      this.claimsService.getCurrentLoggedInUserClaimsGroups().pipe(
        map((response) => new GetCurrentLoggedInUserClaimsGroupsSuccess(response)),
        catchError((error) => of(new GetCurrentLoggedInUserClaimsGroupsFail(error)))
      )
    )
  );
}
