import { CreateAccountComponent } from 'finances/components/accounts/create-account/create-account.component';
import { UpdateAccountComponent } from 'finances/components/accounts/update-account/update-account.component';
import { CreateSecondaryAccountComponent } from 'finances/components/accounts/create-secondary-account/create-secondary-account.component';
import { UpdateSecondaryAccountComponent } from 'finances/components/accounts/update-secondary-account/update-secondary-account.component';
import { AccountsListComponent } from 'finances/components/accounts/accounts-list/accounts-list.component';
import { AccountsSearchComponent } from 'finances/components/accounts/accounts-search/accounts-search.component';

export * from 'finances/components/accounts/create-account/create-account.component';
export * from 'finances/components/accounts/update-account/update-account.component';
export * from 'finances/components/accounts/accounts-list/accounts-list.component';
export * from 'finances/components/accounts/accounts-search/accounts-search.component';
export * from 'finances/components/accounts/create-secondary-account/create-secondary-account.component';
export * from 'finances/components/accounts/update-secondary-account/update-secondary-account.component';

/**
 * The set of components registered on this sub-module.
 */
export const COMPONENTS: any[] = [
  CreateAccountComponent,
  UpdateAccountComponent,
  AccountsListComponent,
  AccountsSearchComponent,
  CreateSecondaryAccountComponent,
  UpdateSecondaryAccountComponent,
];
