import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { SharedModule } from 'shared';
import { FinancesModule } from 'finances';
import { SettingsModule } from 'settings';
import { AppSearchModule } from 'app-search';
import { StoresRoutingModule } from 'stores/stores-routing.module';

import * as fromComponents from 'stores/components';
import * as fromServices from 'stores/services';
import * as fromStore from 'stores/store';

@NgModule({
  declarations: [...fromComponents.COMPONENTS],
  imports: [
    CommonModule,
    SharedModule,
    FinancesModule,
    SettingsModule,
    AppSearchModule,
    StoresRoutingModule,
    StoreModule.forFeature('stores', fromStore.reducers),
    EffectsModule.forFeature(fromStore.effects),
  ],
  exports: [
    fromComponents.ProductsFormComponent,
    fromComponents.LocationsSearchComponent,
    fromComponents.OrganizationLocationsSearchComponent,
    fromComponents.ProductsSearchComponent,
    fromComponents.ProductClassesSearchComponent,
    fromComponents.LocationsFormComponent,
    fromComponents.ProductClassesFormComponent,
  ],
  providers: [...fromServices.SERVICES],
})
export class StoresModule {}
