import { createSelector } from '@ngrx/store';

import { StatisticsMainState, StatisticsState } from 'statistics/store/states';
import { selectStatisticsMainState } from 'statistics/store/selectors/feature.selectors';

/**
 * Gets the statistics state.
 */
const selectStatisticsState = createSelector(
  selectStatisticsMainState,
  (state: StatisticsMainState) => state.statistics
);

/**
 * Gets the loaded statistics.
 */
export const getStatistics = createSelector(selectStatisticsState, (state: StatisticsState) => state.data);

/**
 * Gets the loaded statistics charts.
 */
export const getStatisticsCharts = createSelector(
  selectStatisticsState,
  (state: StatisticsState) => state.statisticsCharts
);

/**
 * Gets the error that occurred in the state.
 */
export const getStatisticsError = createSelector(selectStatisticsState, (state: StatisticsState) => state.error);

/**
 * Determines if there is a running load process.
 */
export const getStatisticsLoading = createSelector(selectStatisticsState, (state: StatisticsState) => state.isLoading);

/**
 * Determines if the last load process has been ended successfully.
 */
export const getStatisticsLoadCompleted = createSelector(
  selectStatisticsState,
  (state: StatisticsState) => state.isLoadCompleted
);

/**
 * Determines if there is a running load charts process.
 */
export const getStatisticsChartsLoading = createSelector(
  selectStatisticsState,
  (state: StatisticsState) => state.isChartsLoading
);

/**
 * Determines if the last load charts process has been ended successfully.
 */
export const getStatisticsChartsLoadCompleted = createSelector(
  selectStatisticsState,
  (state: StatisticsState) => state.isChartsLoadCompleted
);
