import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';

import { Observable } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { tap } from 'rxjs/operators';

import { PageInfo, PaginationInfo } from 'shared';
import * as fromSettingsStore from 'settings/store';
import { Claims } from 'security/models';
import { Tax } from 'settings/models';

@Component({
  selector: 'app-taxes-list',
  templateUrl: './taxes-list.component.html',
  styles: [],
})
export class TaxesListComponent implements OnInit {
  /**
   * Gets or sets the information about the current page.
   */
  pageInfo: PageInfo = {
    title: 'SETTINGS.TAXES.TAXES',
    icon: 'fa fa-money',
  };

  /**
   * The system supported user claims.
   */
  Claims = Claims;

  /**
   * The list of taxes.
   */
  taxes$: Observable<Tax[]>;

  /**
   * The pagination info.
   */
  paginationInfo$: Observable<PaginationInfo>;

  /**
   * The search form.
   */
  searchForm: FormGroup;

  /**
   * Gets or sets the currently selected tax for delete.
   */
  selectedTax: Tax;

  /**
   * Indicates whether there is a delete-tax process is running or not.
   */
  isDeleting$: Observable<boolean>;

  /**
   * @param modalService The modal service.
   * @param settingsStore$ Settings-module store.
   */
  constructor(private modalService: NgbModal, private settingsStore$: Store<fromSettingsStore.SettingsState>) {}

  ngOnInit(): void {
    this.init();
  }

  /**
   * Initialize component data.
   */
  init() {
    this.initForm();

    /**
     * Load data.
     */
    this.isDeleting$ = this.settingsStore$.pipe(select(fromSettingsStore.getSelectedTaxDeleting));

    let isManualSearchTriggeredBeforeForTaxes = false;
    this.taxes$ = this.settingsStore$.pipe(
      select(fromSettingsStore.getTaxes),
      tap((taxes) => {
        if (!isManualSearchTriggeredBeforeForTaxes && !taxes.length) {
          isManualSearchTriggeredBeforeForTaxes = true;
          this.applyFiltersAndSearch();
        }
      })
    );

    this.paginationInfo$ = this.settingsStore$.pipe(select(fromSettingsStore.getTaxesPaginationInfo));
  }

  /**
   * Initialize form and add validators.
   */
  initForm() {
    this.searchForm = new FormGroup({
      name: new FormControl(''),
      page: new FormControl(1),
    });
  }

  /**
   * Handles search parameters change.
   */
  search(event?: KeyboardEvent) {
    if (event && event.key === 'Enter') {
      event.preventDefault();
      return;
    }
    this.applyFiltersAndSearch();
  }

  /**
   * Handles pagination page-changed event.
   * @param page The current selected page number.
   */
  pageChanged(page: number) {
    /** Update pagination page. */
    this.searchForm.patchValue({ page });

    this.applyFiltersAndSearch(page);
  }

  applyFiltersAndSearch(page: number = 1) {
    this.settingsStore$.dispatch(
      new fromSettingsStore.SearchTaxes({
        name: this.searchForm.get('name').value,
        page,
      })
    );
  }

  /**
   * Enables trackBy feature for *ngFor utility to track items
   * depending on a comparer to reduce DOM change.
   * If the item is already exist then no HTML will change, only bounded values.
   * @param index The index of the item.
   * @param tax The item to determine if it was changed or not.
   */
  trackItems(index: number, tax: Tax) {
    return tax ? tax.id : undefined;
  }

  /**
   * Deletes the tax with the given id.
   * @param tax The id of the tax to be deleted.
   */
  delete(id: number) {
    this.settingsStore$.dispatch(new fromSettingsStore.DeleteTax(id));
  }

  /**
   * Opens the modal of the given templateRef.
   * @param modalRef The modal templateRef to be opened.
   */
  openModal(modalRef) {
    this.modalService.open(modalRef);
  }
}
