<div class="row">
  <div class="col-md-auto mb-2" *ngFor="let file of files; let i = index">
    <app-file-viewer
      [url]="file"
      [id]="ids ? ids[i] : undefined"
      [allowDelete]="allowDelete"
      (delete)="deleteFile($event)"
    ></app-file-viewer>
  </div>
</div>
