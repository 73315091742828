import { Injectable } from '@angular/core';

import { of } from 'rxjs';
import { switchMap, map, catchError } from 'rxjs/operators';
import { Effect, ofType, Actions } from '@ngrx/effects';

import { LookupsService } from 'lookups/services';
import { PositionTypeActionType, GetAllPositionTypeFail, GetAllPositionTypeSuccess } from 'lookups/store/actions';

@Injectable()
export class PositionTypeEffects {
  constructor(private actions$: Actions, private lookupsService: LookupsService) {}

  /* ========================= GET_ALL_POSITION_TYPE =================================== */
  @Effect()
  getAllPositionTypes$ = this.actions$.pipe(
    ofType(PositionTypeActionType.GET_ALL_POSITION_TYPE),
    switchMap(() =>
      this.lookupsService.getAllPositionTypes().pipe(
        map((response) => new GetAllPositionTypeSuccess(response)),
        catchError((error) => of(new GetAllPositionTypeFail(error)))
      )
    )
  );
}
