<ng-container [formGroup]="employeeProvisionLinkGroup">
  <th scope="row">{{ index + 1 }}</th>
  <td class="min-width-9">
    {{
      employeeProvisionLinkGroup?.value?.employeeProvisionLink?.name
        | localize: employeeProvisionLinkGroup?.value?.employeeProvisionLink?.nameEn
        | async
    }}
  </td>
  <td>
    <ng-select
      class="round"
      formControlName="accountId"
      labelForId="accountId"
      searchable="true"
      [placeholder]="'FINANCES.FINANCE_CONFIGURATIONS.FINANCE_CONFIGURATIONS_DATA.ACCOUNT_PLACEHOLDER' | translate"
      [notFoundText]="'SHARED.SEARCH.SEARCH_NOT_FOUND' | translate"
      (open)="accountsListVisibility = true"
    >
      <ng-option *ngFor="let account of accountsList" [value]="account.id">
        {{ account.description }}
      </ng-option>
    </ng-select>
  </td>
</ng-container>

<!-- accounts search starts -->
<app-accounts-search [closeOnSelect]="true" [(visible)]="accountsListVisibility" (accounts)="selectAccount($event)">
</app-accounts-search>
<!-- accounts search ends -->
