import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';

import { Observable, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { PageInfo, PaginationInfo } from 'shared';
import * as fromStoresStore from 'stores/store';
import { UnitOfMeasureClass } from 'stores/models';
import { Claims } from 'security/models';

@Component({
  selector: 'app-unit-of-measure-classes-list',
  templateUrl: './unit-of-measure-classes-list.component.html',
  styles: [],
})
export class UnitOfMeasureClassesListComponent implements OnInit, OnDestroy {
  /**
   * Gets or sets the information about the current page.
   */
  pageInfo: PageInfo = {
    title: 'STORES.UNIT_OF_MEASURE_CLASSES.UNIT_OF_MEASURE_CLASS_TITLE',
    icon: 'fa fa-sitemap',
  };

  /**
   * The system supported user claims.
   */
  Claims = Claims;

  /**
   * The list of unit of measure class .
   */
  unitOfMeasureClass$: Observable<UnitOfMeasureClass[]>;

  /**
   * Indicates whether there is a delete-unit-of-measure-class process is running or not.
   */
  isDeleting$: Observable<boolean>;

  /**
   * The pagination info.
   */
  paginationInfo$: Observable<PaginationInfo>;

  /**
   * The search form.
   */
  searchForm: FormGroup;

  /**
   * The set of subscriptions on this components,
   * these subscriptions must be unsubscribed before this component got destroyed.
   */
  subscriptions = new Subscription();

  /**
   * Gets or sets the currently selected unit of measure class for delete.
   */
  selectedUnitOfMeasureClass: UnitOfMeasureClass;

  /**
   * @param modalService The modal service.
   * @param storesStore$ The stores store.
   */
  constructor(private modalService: NgbModal, private storesStore$: Store<fromStoresStore.StoresState>) {}
  ngOnInit(): void {
    this.init();
  }

  /**
   * Destroy component data
   */
  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  /**
   * Initialize component data.
   */
  init() {
    this.initForm();

    /**
     * Load data.
     */
    this.isDeleting$ = this.storesStore$.pipe(select(fromStoresStore.getSelectedUnitOfMeasureClassDeleting));

    let isManualSearchTriggeredBeforeForClasses = false;
    this.unitOfMeasureClass$ = this.storesStore$.pipe(
      select(fromStoresStore.getUnitOfMeasureClasses),
      tap((classes) => {
        if (!isManualSearchTriggeredBeforeForClasses && !classes.length) {
          isManualSearchTriggeredBeforeForClasses = true;
          this.search();
        }
      })
    );

    this.paginationInfo$ = this.storesStore$.pipe(select(fromStoresStore.getUnitOfMeasureClassesPaginationInfo));
  }

  /**
   * Initialize form and add validators.
   */
  initForm() {
    this.searchForm = new FormGroup({
      name: new FormControl(''),
      page: new FormControl(1),
    });
  }

  /**
   * Handles search parameters change.
   */
  search(event?: KeyboardEvent) {
    if (event && event.key === 'Enter') {
      event.preventDefault();
      return;
    }

    this.applyFiltersAndSearch();
  }

  /**
   * Handles pagination page-changed event.
   * @param page The current selected page number.
   */
  pageChanged(page: number) {
    /** Update pagination page. */
    this.searchForm.patchValue({ page });

    this.applyFiltersAndSearch(page);
  }

  /**
   * Enables trackBy feature for *ngFor utility to track elements
   * depending on a comparer to reduce DOM change.
   * If the element is already exist then no HTML will change, only bounded values.
   * @param element The element to determine if it was changed or not.
   */
  trackElements(element: UnitOfMeasureClass) {
    return element ? element.id : undefined;
  }

  applyFiltersAndSearch(page: number = 1) {
    const { name } = this.searchForm.value;
    this.storesStore$.dispatch(
      new fromStoresStore.SearchUnitOfMeasureClasses({
        name,
        page,
      })
    );
  }

  /**
   * Deletes the unit of measure class with the given id.
   * @param unitOfMeasureClassId The id of the currency to be deleted.
   */
  delete(unitOfMeasureClassId: number) {
    this.storesStore$.dispatch(new fromStoresStore.DeleteUnitOfMeasureClass(unitOfMeasureClassId));
  }

  /**
   * Opens the modal of the given templateRef.
   * @param modalRef The modal templateRef to be opened.
   */
  openModal(modalRef) {
    this.modalService.open(modalRef);
  }
}
