import { Injectable } from '@angular/core';

import { of } from 'rxjs';
import { switchMap, map, catchError } from 'rxjs/operators';
import { Effect, ofType, Actions } from '@ngrx/effects';

import { LookupsService } from 'lookups/services';
import {
  CustomerCategoryActionType,
  GetAllCustomerCategoryFail,
  GetAllCustomerCategorySuccess,
} from 'lookups/store/actions';

@Injectable()
export class CustomerCategoryEffects {
  constructor(private actions$: Actions, private lookupsService: LookupsService) {}

  /* ========================= GET_ALL_CUSTOMER_CATEGORY =================================== */
  @Effect()
  getAllCustomerCategories$ = this.actions$.pipe(
    ofType(CustomerCategoryActionType.GET_ALL_CUSTOMER_CATEGORY),
    switchMap(() =>
      this.lookupsService.getAllCustomerCategories().pipe(
        map((response) => new GetAllCustomerCategorySuccess(response)),
        catchError((error) => of(new GetAllCustomerCategoryFail(error)))
      )
    )
  );
}
