import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

import { Observable, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';

import * as fromSalesStore from 'sales/store';
import * as fromAuthStore from 'auth/store';
import { SaleInvoice } from 'sales/models';
import { Claims } from 'security/models';
import { PaginationInfo } from 'shared/models';
import { Location } from 'stores/models';

@Component({
  selector: 'app-customer-unpaid-invoices',
  templateUrl: './customer-unpaid-invoices.component.html',
  styles: [],
})
export class CustomerUnpaidInvoicesComponent implements OnInit, OnDestroy {
  /**
   * Sets the id of the currently selected customer.
   */
  @Input() customerId: number;

  /**
   * The system supported user claims.
   */
  Claims = Claims;

  /**
   * Shows or hide the locations list.
   */
  locationsListVisibility = false;

  /**
   * The list of selected locations.
   */
  locations: Location[] = [];

  /**
   * The currently selected customer list of invoices.
   */
  invoices$: Observable<SaleInvoice[]>;

  /**
   * The pagination info.
   */
  paginationInfo$: Observable<PaginationInfo>;

  /**
   * Indicates whether there is a search process is running or not.
   */
  isSearching$: Observable<boolean>;

  /**
   * The search form.
   */
  searchForm: FormGroup;

  /**
   * The set of subscriptions on this components,
   * these subscriptions must be unsubscribed before this component got destroyed.
   */
  subscriptions = new Subscription();

  /**
   * @param salesStore$ The sales-module store.
   * @param authStore$ the auth-store module.
   */
  constructor(
    private salesStore$: Store<fromSalesStore.SalesState>,
    private authStore$: Store<fromAuthStore.AuthState>
  ) {}

  ngOnInit(): void {
    this.init();
  }

  /**
   * Destroy component data
   */
  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  /**
   * Initialize component data.
   */
  init() {
    this.initForm();

    /* Subscribe to selected location to select it whenever it changed. */
    this.subscriptions.add(
      this.authStore$
        .pipe(
          select(fromAuthStore.getSessionUserDefaultLocation),
          tap((location) => {
            if (location && !this.searchForm.value.locations.includes(location.id)) {
              this.selectLocations([location]);
            }
          })
        )
        .subscribe()
    );

    /**
     * Loads data.
     */
    this.isSearching$ = this.salesStore$.pipe(select(fromSalesStore.getUnpaidSaleInvoicesSearching));
    this.invoices$ = this.salesStore$.pipe(select(fromSalesStore.getUnpaidSaleInvoices));

    this.search();
  }

  /**
   * Initialize form and add validators.
   */
  initForm() {
    this.searchForm = new FormGroup({
      locations: new FormControl([]),
      invoiceNum: new FormControl(''),
      page: new FormControl(1),
    });
  }

  /**
   * Handles search parameters change.
   */
  search(event?: KeyboardEvent) {
    if (event && event.key === 'Enter') {
      event.preventDefault();
      return;
    }

    this.applyFiltersAndSearch();
  }

  /**
   * Handles pagination page-changed event.
   * @param page The current selected page number.
   */
  pageChanged(page: number) {
    /** Update pagination page. */
    this.searchForm.patchValue({ page });

    this.applyFiltersAndSearch(page);
  }

  /**
   * Applies the search filters and dispatches a search action.
   * @param page The current page number, default to `1`.
   */
  applyFiltersAndSearch(page: number = 1) {
    this.salesStore$.dispatch(
      new fromSalesStore.SearchUnpaidSaleInvoices({
        customers: [this.customerId],
        locations: this.searchForm.get('locations').value,
        invoiceNum: this.searchForm.get('invoiceNum').value,
        page,
      })
    );
  }

  /**
   * Enables trackBy feature for *ngFor utility to track items
   * depending on a comparer to reduce DOM change.
   * If the item is already exist then no HTML will change, only bounded values.
   * @param index The index of the item.
   * @param invoice The item to determine if it was changed or not.
   */
  trackItems(index: number, invoice: SaleInvoice) {
    return invoice ? invoice.id : undefined;
  }

  /**
   * Adds the newly selected locations the locations search list.
   * @param locations The list of newly selected locations to be added.
   */
  selectLocations(locations: Location[]) {
    const selectedIds: number[] = this.searchForm.get('locations').value;
    this.locations = [...this.locations.filter((location) => selectedIds.includes(location.id)), ...locations];
    this.searchForm.patchValue({ locations: this.locations.map((location) => location.id) });
  }
}
