import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import { Observable, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import * as fromLookupsStore from 'lookups/store';
import * as fromStoresStore from 'stores/store';
import { PageInfo, NotificationService, NotificationMessage, TranslationService } from 'shared';
import { Variant } from 'stores/models';
import { VariantCategory } from 'lookups/models';

@Component({
  selector: 'app-update-variant',
  templateUrl: './update-variant.component.html',
  styles: [],
})
export class UpdateVariantComponent implements OnInit, OnDestroy {
  /**
   * The confirm modal template reference.
   */
  @ViewChild('confirmModalRef') confirmModalRef: ElementRef<any>;

  /**
   * The confirm delete photo modal template reference.
   */
  @ViewChild('confirmDeletePhotoModalRef') confirmDeletePhotoModalRef: ElementRef<any>;

  /**
   * The confirm update photo modal template reference.
   */
  @ViewChild('confirmUpdatePhotoModalRef') confirmUpdatePhotoModalRef: ElementRef<any>;

  /**
   * Gets or sets the information about the current page.
   */
  pageInfo: PageInfo = {
    title: 'STORES.PRODUCT_VARIANTS.CONFIRM_UPDATE_PRODUCT_VARIANT_TITLE',
    icon: 'fa fa-edit',
  };

  /**
   * Gets or sets the id of the current edited product variant.
   */
  variantId: number;

  /**
   * The current edited variant.
   */
  variants$: Observable<Variant>;

  /**
   * The name of the current product variant.
   */
  productVariantName: string;

  /**
   * The list of variant category.
   */
  variantCategories$: Observable<VariantCategory[]>;

  /**
   * Indicates whether there is a update-location process is running or not.
   */
  isUpdating$: Observable<boolean>;

  /**
   * The update variant form.
   */
  form: FormGroup;

  /**
   * The set of subscriptions on this components,
   * these subscriptions must be unsubscribed before this component got destroyed.
   */
  subscriptions = new Subscription();

  /**
   * Gets or sets the current selected language.
   */
  get currentLang() {
    return this.translationService.language;
  }

  /**
   * @param route The activate rout service.
   * @param modalService The modal service.
   * @param notificationService The notification service.
   * @param translationService The translation service.
   * @param storesStore$ The stores-store module.
   * @param lookupsStore$ The lookup-store module.
   */
  constructor(
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private notificationService: NotificationService,
    private translationService: TranslationService,
    private storesStore$: Store<fromStoresStore.StoresState>,
    private lookupsStore$: Store<fromLookupsStore.LookupsState>
  ) {}

  ngOnInit(): void {
    this.init();
  }

  /**
   * Destroy component data
   */
  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  /**
   * Initialize component data.
   */
  init() {
    this.initForm();

    /** Subscribe to route to get the variant id whenever it changed. */
    const routeSubscription = this.route.paramMap
      .pipe(
        tap((params) => {
          this.variantId = +params.get('variantId');
          this.storesStore$.dispatch(new fromStoresStore.FindVariant(this.variantId));
        })
      )
      .subscribe();
    this.subscriptions.add(routeSubscription);

    /* Load the selected product variant. */
    this.variants$ = this.storesStore$.pipe(
      select(fromStoresStore.getSelectedVariant),
      tap((productVariant) => {
        if (productVariant) {
          this.form.setValue({
            name: productVariant.name,
            nameEn: productVariant.nameEn,
            variantCategoryId: productVariant.variantCategoryId,
          });

          this.productVariantName = this.currentLang === 'en' ? productVariant.nameEn : productVariant.name;

          /* Update page info. */
          this.pageInfo = {
            ...this.pageInfo,
            subTitle: `(${this.productVariantName})`,
          };

          this.form.markAsPristine();
        }
      })
    );

    /**
     * Load data.
     */
    this.isUpdating$ = this.storesStore$.pipe(select(fromStoresStore.getSelectedVariantUpdating));

    let isManualSearchTriggeredBeforeForCategories = false;
    this.variantCategories$ = this.lookupsStore$.pipe(
      select(fromLookupsStore.getVariantCategories),
      tap((categories) => {
        if (!isManualSearchTriggeredBeforeForCategories && !categories.length) {
          isManualSearchTriggeredBeforeForCategories = true;
          this.lookupsStore$.dispatch(new fromLookupsStore.GetAllVariantCategories());
        }
      })
    );
  }

  /**
   * Initialize form and add validators.
   */
  initForm() {
    this.form = new FormGroup({
      name: new FormControl('', [Validators.required, Validators.minLength(1), Validators.maxLength(50)]),
      nameEn: new FormControl('', [Validators.required, Validators.minLength(1), Validators.maxLength(50)]),
      variantCategoryId: new FormControl(null, Validators.required),
    });
  }

  /**
   * Submits the form.
   */
  submit() {
    if (this.form.invalid) {
      const errorMessage = new NotificationMessage();

      if (this.form.get('name').invalid) {
        errorMessage.title = this.translationService.translate(
          'STORES.PRODUCT_VARIANTS.PRODUCT_VARIANT_DATA_VALIDATION.ARABIC_PRODUCT_VARIANT_NAME_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate(
            'STORES.PRODUCT_VARIANTS.PRODUCT_VARIANT_DATA_VALIDATION.PRODUCT_VARIANT_NAME_IS_REQUIRED'
          ),
          this.translationService.translate(
            'STORES.PRODUCT_VARIANTS.PRODUCT_VARIANT_DATA_VALIDATION.PRODUCT_VARIANT_NAME_LENGTH_ERROR'
          ),
        ];
      } else if (this.form.get('nameEn').invalid) {
        errorMessage.title = this.translationService.translate(
          'STORES.PRODUCT_VARIANTS.PRODUCT_VARIANT_DATA_VALIDATION.ENGLISH_PRODUCT_VARIANT_NAME_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate(
            'STORES.PRODUCT_VARIANTS.PRODUCT_VARIANT_DATA_VALIDATION.PRODUCT_VARIANT_NAME_IS_REQUIRED'
          ),
          this.translationService.translate(
            'STORES.PRODUCT_VARIANTS.PRODUCT_VARIANT_DATA_VALIDATION.PRODUCT_VARIANT_NAME_LENGTH_ERROR'
          ),
        ];
      } else if (this.form.get('variantCategoryId').invalid) {
        errorMessage.title = this.translationService.translate(
          'STORES.PRODUCT_VARIANTS.PRODUCT_VARIANT_DATA_VALIDATION.VARIANT_CATEGORY_NAME_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate(
            'STORES.PRODUCT_VARIANTS.PRODUCT_VARIANT_DATA_VALIDATION.VARIANT_CATEGORY_NAME_IS_REQUIRED'
          ),
        ];
      }

      this.form.markAllAsTouched();
      return this.notificationService.warningWithTitle(errorMessage);
    } else {
      this.openModal(this.confirmModalRef);
    }
  }

  /**
   * Confirms the form submit.
   */
  confirm() {
    this.storesStore$.dispatch(
      new fromStoresStore.UpdateVariant({
        id: this.variantId,
        ...this.form.value,
      })
    );
  }

  /**
   * Opens the modal of the given templateRef.
   * @param modalRef The modal templateRef to be opened.
   */
  openModal(modalRef) {
    this.modalService.open(modalRef);
  }
}
