import { Action } from '@ngrx/store';

import { AppHttpResponse } from 'shared';
import { CreateUnitOfMeasureClassInput, UnitOfMeasureClass, UpdateUnitOfMeasureClassInput } from 'stores/models';

/**
 * The stores-module store unit of measure class action types.
 */
export enum UnitOfMeasureClassesActionType {
  SEARCH_UNIT_OF_MEASURE_CLASSES = '[Stores] [UnitOfMeasureClass] Search Unit Of Measure Classes',
  SEARCH_UNIT_OF_MEASURE_CLASSES_FAIL = '[Stores] [UnitOfMeasureClass] Search Unit Of Measure Classes Fail',
  SEARCH_UNIT_OF_MEASURE_CLASSES_SUCCESS = '[Stores] [UnitOfMeasureClass] Search Unit Of Measure Classes Success',

  FIND_UNIT_OF_MEASURE_CLASS = '[Stores] [UnitOfMeasureClass] Find Unit Of Measure Class',
  FIND_UNIT_OF_MEASURE_CLASS_FAIL = '[Stores] [UnitOfMeasureClass] Find Unit Of Measure Class Fail',
  FIND_UNIT_OF_MEASURE_CLASS_SUCCESS = '[Stores] [UnitOfMeasureClass] Find Unit Of Measure Class Success',

  CREATE_UNIT_OF_MEASURE_CLASS = '[Stores] [UnitOfMeasureClass] Create Unit Of Measure Class',
  CREATE_UNIT_OF_MEASURE_CLASS_FAIL = '[Stores] [UnitOfMeasureClass] Create Unit Of Measure Class Fail',
  CREATE_UNIT_OF_MEASURE_CLASS_SUCCESS = '[Stores] [UnitOfMeasureClass] Create Unit Of Measure Class Success',

  UPDATE_UNIT_OF_MEASURE_CLASS = '[Stores] [UnitOfMeasureClass] Update Unit Of Measure Class',
  UPDATE_UNIT_OF_MEASURE_CLASS_FAIL = '[Stores] [UnitOfMeasureClass] Update Unit Of Measure Class Fail',
  UPDATE_UNIT_OF_MEASURE_CLASS_SUCCESS = '[Stores] [UnitOfMeasureClass] Update Unit Of Measure Class Success',

  DELETE_UNIT_OF_MEASURE_CLASS = '[Stores] [UnitOfMeasureClass] Delete Unit Of Measure Class',
  DELETE_UNIT_OF_MEASURE_CLASS_FAIL = '[Stores] [UnitOfMeasureClass] Delete Unit Of Measure Class Fail',
  DELETE_UNIT_OF_MEASURE_CLASS_SUCCESS = '[Stores] [UnitOfMeasureClass] Delete Unit Of Measure Class Success',
}

/**
 * Represents a store Unit Of Measure Classes search action.
 */
export class SearchUnitOfMeasureClasses implements Action {
  /**
   * The type of the action.
   */
  readonly type = UnitOfMeasureClassesActionType.SEARCH_UNIT_OF_MEASURE_CLASSES;

  /**
   * Fires a new unit of measure classes search action.
   * @param payload the search parameters,
   * if omitted, all unit of measure classes will be loaded.
   */
  constructor(
    public payload: {
      name: string;
      page: number;
    }
  ) {}
}

/**
 * Represents a store unit of measure classes search fail action.
 */
export class SearchUnitOfMeasureClassesFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = UnitOfMeasureClassesActionType.SEARCH_UNIT_OF_MEASURE_CLASSES_FAIL;

  /**
   * Fires a new unit of measure classes search fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store search success action.
 */
export class SearchUnitOfMeasureClassesSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = UnitOfMeasureClassesActionType.SEARCH_UNIT_OF_MEASURE_CLASSES_SUCCESS;

  /**
   * Fires a new unit of measure classes search success action.
   * @param payload An object contains the list of unit of measure classes that matches the search criteria
   * in addition to the pagination info.
   */
  constructor(public payload: AppHttpResponse<UnitOfMeasureClass[]>) {}
}

/**
 * Represents a store find unit of measure class action.
 */
export class FindUnitOfMeasureClass implements Action {
  /**
   * The type of the action.
   */
  readonly type = UnitOfMeasureClassesActionType.FIND_UNIT_OF_MEASURE_CLASS;

  /**
   * Fires a new find unit of measure class action.
   * @param payload the id of the unit of measure class.
   */
  constructor(public payload: number) {}
}

/**
 * Represents a store find unit of measure class fail action.
 */
export class FindUnitOfMeasureClassFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = UnitOfMeasureClassesActionType.FIND_UNIT_OF_MEASURE_CLASS_FAIL;

  /**
   * Fires a new find unit of measure class fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store find unit of measure class success action.
 */
export class FindUnitOfMeasureClassSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = UnitOfMeasureClassesActionType.FIND_UNIT_OF_MEASURE_CLASS_SUCCESS;

  /**
   * Fires a new find unit of measure classes success action.
   * @param payload The loaded unit of measure class.
   */
  constructor(public payload: AppHttpResponse<UnitOfMeasureClass>) {}
}

/**
 * Represents a store unit of measure class create action.
 */
export class CreateUnitOfMeasureClass implements Action {
  /** The type of the action. */
  readonly type = UnitOfMeasureClassesActionType.CREATE_UNIT_OF_MEASURE_CLASS;

  /**
   * Fires a new unit of measure class create action.
   * @param payload the new unit of measure class data-model.
   */
  constructor(public payload: CreateUnitOfMeasureClassInput) {}
}

/**
 * Represents a store unit of measure class create fail action.
 */
export class CreateUnitOfMeasureClassFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = UnitOfMeasureClassesActionType.CREATE_UNIT_OF_MEASURE_CLASS_FAIL;

  /**
   * Fires a new unit of measure class create fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store unit of measure class create success action.
 */
export class CreateUnitOfMeasureClassSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = UnitOfMeasureClassesActionType.CREATE_UNIT_OF_MEASURE_CLASS_SUCCESS;

  /**
   * Fires a new unit of measure class create success action.
   * @param payload The created unit of measure class.
   */
  constructor(public payload: AppHttpResponse<UnitOfMeasureClass>) {}
}

/**
 * Represents a store unit of measure class update action.
 */
export class UpdateUnitOfMeasureClass implements Action {
  /**
   * The type of the action.
   */
  readonly type = UnitOfMeasureClassesActionType.UPDATE_UNIT_OF_MEASURE_CLASS;

  /**
   * Fires a new unit of measure class update action.
   * @param payload The  unit of measure class updated data.
   */
  constructor(public payload: UpdateUnitOfMeasureClassInput) {}
}

/**
 * Represents a store unit of measure class update fail action.
 */
export class UpdateUnitOfMeasureClassFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = UnitOfMeasureClassesActionType.UPDATE_UNIT_OF_MEASURE_CLASS_FAIL;

  /**
   * Fires a new unit of measure class update fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store unit of measure class update success action.
 */
export class UpdateUnitOfMeasureClassSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = UnitOfMeasureClassesActionType.UPDATE_UNIT_OF_MEASURE_CLASS_SUCCESS;

  /**
   * Fires a new unit of measure class update-success action.
   * @param payload The updated unit of measure class.
   */
  constructor(public payload: AppHttpResponse<UnitOfMeasureClass>) {}
}

/**
 * Represents a store unit of measure class delete action.
 */
export class DeleteUnitOfMeasureClass implements Action {
  /**
   * The type of the action.
   */
  readonly type = UnitOfMeasureClassesActionType.DELETE_UNIT_OF_MEASURE_CLASS;

  /**
   * Fires a new unit of measure class delete action.
   * @param payload The unit of measure class id.
   */
  constructor(public payload: number) {}
}

/**
 * Represents a store unit of measure class delete fail action.
 */
export class DeleteUnitOfMeasureClassFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = UnitOfMeasureClassesActionType.DELETE_UNIT_OF_MEASURE_CLASS_FAIL;

  /**
   * Fires a new unit of measure class delete fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store unit of measure class delete success action.
 */
export class DeleteUnitOfMeasureClassSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = UnitOfMeasureClassesActionType.DELETE_UNIT_OF_MEASURE_CLASS_SUCCESS;

  /**
   * Fires a new unit of measure class delete success action.
   * @param payload The deleted unit of measure class.
   */
  constructor(public payload: AppHttpResponse<UnitOfMeasureClass>) {}
}

/**
 * stores-module unit of measure classes action types.
 */
export type UnitOfMeasureClassesActions =
  | SearchUnitOfMeasureClasses
  | SearchUnitOfMeasureClassesFail
  | SearchUnitOfMeasureClassesSuccess
  | FindUnitOfMeasureClass
  | FindUnitOfMeasureClassFail
  | FindUnitOfMeasureClassSuccess
  | CreateUnitOfMeasureClass
  | CreateUnitOfMeasureClassFail
  | CreateUnitOfMeasureClassSuccess
  | UpdateUnitOfMeasureClass
  | UpdateUnitOfMeasureClassFail
  | UpdateUnitOfMeasureClassSuccess
  | DeleteUnitOfMeasureClass
  | DeleteUnitOfMeasureClassFail
  | DeleteUnitOfMeasureClassSuccess;
