import { Action } from '@ngrx/store';

import { PurchaseOrderType } from 'lookups/models';
import { AppHttpResponse } from 'shared';

/**
 * The Lookups-Module store  purchase order type action types.
 */
export enum PurchaseOrderTypeActionType {
  GET_ALL_PURCHASE_ORDER_TYPE = '[Lookups] [PurchaseOrderType] Get All Purchase Order Types',
  GET_ALL_PURCHASE_ORDER_TYPE_FAIL = '[Lookups] [PurchaseOrderType] Get All Purchase Order Types Fail',
  GET_ALL_PURCHASE_ORDER_TYPE_SUCCESS = '[Lookups] [PurchaseOrderType] Get All Purchase Order Types Success',
}

/**
 * Represents a store  purchase order type action.
 */
export class GetAllPurchaseOrderType implements Action {
  /**
   * The type of the action.
   */
  readonly type = PurchaseOrderTypeActionType.GET_ALL_PURCHASE_ORDER_TYPE;

  /**
   * Fires a new  purchase order type action.
   */
  constructor() {}
}

/**
 * Represents a store  purchase order type fail action.
 */
export class GetAllPurchaseOrderTypeFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = PurchaseOrderTypeActionType.GET_ALL_PURCHASE_ORDER_TYPE_FAIL;

  /**
   * Fires a new  purchase order type fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store  purchase order type success action.
 */
export class GetAllPurchaseOrderTypeSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = PurchaseOrderTypeActionType.GET_ALL_PURCHASE_ORDER_TYPE_SUCCESS;

  /**
   * Fires a  purchase order type success action.
   * @param payload An object contains the list of  purchase order types.
   */
  constructor(public payload: AppHttpResponse<PurchaseOrderType[]>) {}
}

/**
 * Lookups-module  purchase order type action types.
 */
export type PurchaseOrderTypeActions =
  | GetAllPurchaseOrderType
  | GetAllPurchaseOrderTypeFail
  | GetAllPurchaseOrderTypeSuccess;
