import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';

import { AppHttpResponse } from 'shared';
import { CustomerAsset, CreateCustomerAssetInput, UpdateCustomerAssetInput } from 'sales/models';

/**
 * The customer assets services includes functionality to get customer assets,search customers assets, findById,search,
 * create, update and delete a customer asset.
 */
@Injectable()
export class CustomerAssetsService {
  /**
   * The relative route for the customer assets.
   *
   * No leading or trailing slashes required.
   */
  private customerAssetsApi = 'sales/customers/assets';

  constructor(private http: HttpClient) {}

  /**
   * Creates a new customer asset from the provided data.
   * @param data The new customer asset data.
   */
  public create(data: CreateCustomerAssetInput): Observable<AppHttpResponse<CustomerAsset>> {
    const formData: any = new FormData();

    formData.append('customerId', data.customerId);
    formData.append('description', data.description);
    formData.append('type', data.type);
    formData.append('serialNumber', data.serialNumber);

    /**
     * Append attachments to the form data.
     */
    data.attachments.forEach(
      (attachment) => attachment && formData.append(`attachments[]`, attachment, attachment.name)
    );
    return this.http.post<AppHttpResponse<CustomerAsset>>(`${this.customerAssetsApi}`, formData);
  }

  /**
   * Finds the customer asset with the given id.
   * @param id The id of the customer asset.
   */
  public findById(id: number): Observable<AppHttpResponse<CustomerAsset>> {
    return this.http.get<AppHttpResponse<CustomerAsset>>(`${this.customerAssetsApi}/${id}`);
  }

  /**
   * Search the customers assets.
   * @param customers The customers for the assets.
   * @param page The current pagination page number.
   * @param pageSize The maximum number of customer assets allowed per one pagination page.
   */
  public searchCustomersAssets(
    customers: number[],
    page: number,
    pageSize: number
  ): Observable<AppHttpResponse<CustomerAsset[]>> {
    const params = new HttpParams()
      .set('customers', customers.join(','))
      .set('page', page.toString())
      .set('pageSize', pageSize.toString());
    return this.http.get<AppHttpResponse<CustomerAsset[]>>(`${this.customerAssetsApi}`, {
      params,
    });
  }

  /**
   * Updates an existing customer asset data using the provided data.
   * @param data The updated customer asset data.
   */
  public update(data: UpdateCustomerAssetInput): Observable<AppHttpResponse<CustomerAsset>> {
    return this.http.put<AppHttpResponse<CustomerAsset>>(`${this.customerAssetsApi}`, data);
  }

  /**
   * Deletes the customer asset by given id.
   * @param id The id of the customer asset.
   */
  public delete(id: number): Observable<AppHttpResponse<CustomerAsset>> {
    return this.http.delete<AppHttpResponse<CustomerAsset>>(`${this.customerAssetsApi}/${id}`);
  }
}
