import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';

import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

import * as fromStoresStore from 'stores/store';
import { PaginationInfo, NotificationService, TranslationService } from 'shared';
import { UnitOfMeasure, UnitOfMeasureClass } from 'stores/models';

/**
 * units of measure  search form wrapped in modal.
 */
@Component({
  selector: 'app-units-of-measure-search',
  templateUrl: './units-of-measure-search.component.html',
  styles: [],
})
export class UnitsOfMeasureSearchComponent implements OnInit {
  /**
   * The modal template reference.
   */
  @ViewChild('modalRef') modalRef: ElementRef<any>;

  /**
   * Indicates whether the modal will automatically close after select the first unit of measure.
   *
   * Be default it will not be closed.
   */
  @Input() closeOnSelect = false;

  /**
   * Indicates whether the units of measure displayed on the list should be all active.
   *
   * Be default it will not be closed.
   */
  @Input() onlyActive = false;

  /**
   * Sets the visibility of this component modal.
   *
   * Handles visibility change in two-data-binding approach.
   */
  @Input() set visible(isShown: boolean) {
    if (isShown && this.modalRef) {
      this.openModal(this.modalRef);
    } else {
      this.closeModal();
    }

    this.visibleChange.emit(isShown);
  }

  /**
   * Outputs a value that indicates whether the modal is shown or not.
   *
   * Handles visibility change in two-data-binding approach.
   */
  @Output() visibleChange = new EventEmitter<boolean>();

  /** Outputs the selected units of measure. */
  @Output() unitsOfMeasure = new EventEmitter<UnitOfMeasure[]>();

  /**
   * Shows or hide the unit of measure classes list.
   */
  unitOfMeasureClassesListVisibility = false;

  /**
   * The list of units of measure.
   */
  unitsOfMeasure$: Observable<UnitOfMeasure[]>;

  /**
   * The list of units of measure.
   */
  unitsOfMeasureList: UnitOfMeasure[];

  /**
   * The list of selected unit Of measure classes.
   */
  unitOfMeasureClasses: UnitOfMeasureClass[] = [];

  /**
   * The pagination info.
   */
  paginationInfo$: Observable<PaginationInfo>;

  /**
   * Indicates whether there is a search process is running or not.
   */
  isSearching$: Observable<boolean>;

  /**
   * The search form.
   */
  searchForm: FormGroup;

  /**
   * The ngb-modal reference to use it when modal needed to be closed.
   */
  modal: NgbModalRef;

  /**
   * @param modalService The modal service.
   * @param notificationService The notification service.
   * @param translationService The translation service.
   * @param storesStore$ The stores-store module.
   */
  constructor(
    private modalService: NgbModal,
    private notificationService: NotificationService,
    private translationService: TranslationService,
    private storesStore$: Store<fromStoresStore.StoresState>
  ) {}

  ngOnInit(): void {
    this.init();
  }

  /**
   * Initialize component data.
   */
  init() {
    this.initForm();

    /**
     * Load data.
     */
    this.isSearching$ = this.storesStore$.pipe(select(fromStoresStore.getUnitsOfMeasureSearching));

    let isManualSearchTriggeredBeforeForUnits = false;
    this.unitsOfMeasure$ = this.storesStore$.pipe(
      select(fromStoresStore.getUnitsOfMeasure),
      tap((units) => {
        if (units) {
          this.unitsOfMeasureList = units;
        }
        if (!isManualSearchTriggeredBeforeForUnits && !units.length) {
          isManualSearchTriggeredBeforeForUnits = true;
          this.search();
        }
      })
    );

    this.paginationInfo$ = this.storesStore$.pipe(select(fromStoresStore.getUnitsOfMeasurePaginationInfo));
  }

  /**
   * Initialize form and add validators.
   */
  initForm() {
    this.searchForm = new FormGroup({
      name: new FormControl(''),
      unitOfMeasureClasses: new FormControl([]),
      page: new FormControl(1),
    });
  }

  /**
   * Handles search parameters change.
   */
  search(event?: KeyboardEvent) {
    if (event && event.key === 'Enter') {
      event.preventDefault();
      return;
    }

    this.storesStore$.dispatch(
      new fromStoresStore.SearchUnitsOfMeasure({
        name: this.searchForm.get('name').value,
        unitOfMeasureClasses: this.searchForm.get('unitOfMeasureClasses').value,
        page: 1,
      })
    );
  }

  /**
   * Handles pagination page-changed event.
   * @param page The current selected page number.
   */
  pageChanged(page: number) {
    /** Update pagination page. */
    this.searchForm.patchValue({ page });

    /** Get selected page items. */
    this.storesStore$.dispatch(
      new fromStoresStore.SearchUnitsOfMeasure({
        name: this.searchForm.get('name').value,
        unitOfMeasureClasses: this.searchForm.get('unitOfMeasureClasses').value,
        page: this.searchForm.get('page').value,
      })
    );
  }

  /**
   * Enables trackBy feature for *ngFor utility to track items
   * depending on a comparer to reduce DOM change.
   * If the item is already exist then no HTML will change, only bounded values.
   * @param index The index of the item.
   * @param unit of measure The item to determine if it was changed or not.
   */
  trackItems(index: number, unitOfMeasure: UnitOfMeasure) {
    return unitOfMeasure ? unitOfMeasure.id : undefined;
  }

  /**
   * Opens the modal of the given templateRef.
   * @param modalRef The modal templateRef to be opened.
   */
  openModal(modalRef) {
    /**
     * Open the modal & listen for user press ESC key.
     */
    this.modal = this.modalService.open(modalRef, { size: 'lg' });
    this.modal.result.then(null, () => this.closeModal());
  }

  /**
   * Closes the currently opened modal.
   */
  closeModal() {
    this.modal?.dismiss();
    this.unitsOfMeasure.emit([]);
    this.visibleChange.emit(false);
  }

  /**
   * Handles unit of measure selected event.
   * @param units of measure The list of units of measure to notify selected.
   */
  selectUnitOfMeasure(unitsOfMeasure: UnitOfMeasure[]) {
    this.notificationService.successInstant(
      this.translationService.translate('STORES.UNITS_OF_MEASURE.UNIT_OF_MEASURE_SELECTED')
    );
    this.unitsOfMeasure.emit(unitsOfMeasure);
    if (this.closeOnSelect) {
      this.closeModal();
    }
  }

  /**
   * Adds the newly selected unit of measure class the unit of measure class search list.
   * @param unitOfMeasureClasses The list of newly selected unit of measure class to be added.
   */
  selectUnitOfMeasureClasses(unitOfMeasureClasses: UnitOfMeasureClass[]) {
    const selectedIds: number[] = this.searchForm.get('unitOfMeasureClasses').value;
    this.unitOfMeasureClasses = [
      ...this.unitOfMeasureClasses.filter((unitOfMeasureClass) => selectedIds.includes(unitOfMeasureClass.id)),
      ...unitOfMeasureClasses,
    ];
    this.searchForm.patchValue({
      unitOfMeasureClasses: this.unitOfMeasureClasses.map((unitOfMeasureClass) => unitOfMeasureClass.id),
    });
  }
}
