import { createSelector } from '@ngrx/store';

import { selectLookupsState } from 'lookups/store/selectors/feature.selectors';
import { LookupsState, AnnualLeaveLogTypeState } from 'lookups/store/states';

/**
 * Gets the annual leave log type state.
 */
const selectAnnualLeaveLogTypes = createSelector(
  selectLookupsState,
  (state: LookupsState) => state.annualLeaveLogTypes
);

/**
 * Gets the list of loaded annual leave log types.
 */
export const getAnnualLeaveLogTypes = createSelector(
  selectAnnualLeaveLogTypes,
  (state: AnnualLeaveLogTypeState) => state.data
);

/**
 * Gets the error that occurred in the state.
 */
export const getAnnualLeaveLogTypesError = createSelector(
  selectAnnualLeaveLogTypes,
  (state: AnnualLeaveLogTypeState) => state.error
);

/**
 * Determines if there is a running load process.
 */
export const getAnnualLeaveLogTypesIsLoading = createSelector(
  selectAnnualLeaveLogTypes,
  (state: AnnualLeaveLogTypeState) => state.isLoading
);

/**
 * Determines if the last load process has been ended successfully.
 */
export const getAnnualLeaveLogTypesIsLoaded = createSelector(
  selectAnnualLeaveLogTypes,
  (state: AnnualLeaveLogTypeState) => state.isLoaded
);
