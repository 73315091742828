import { PaginationInfo } from 'shared/models';
import { PettyCashRefund } from 'finances/models';

/**
 * Represents the petty cash refund state.
 */
export class PettyCashRefundState {
  /**
   * The list of petty cash refund.
   */
  public data: PettyCashRefund[];

  /**
   * The pagination info for the petty cash refund.
   */
  public paginationInfo: PaginationInfo;

  /**
   * An error that may resulting during processing some actions.
   */
  public error: any;

  /**
   * Determines if there is a running search process.
   */
  public isSearching: boolean;

  /**
   * Determines if the last search process has been ended successfully.
   */
  public isSearchCompleted: boolean;

  /**
   * The current selected petty cash refund.
   */
  public selectedPettyCashRefund: PettyCashRefund;

  /**
   * Determines if there is a running find petty cash refund process.
   */
  isFinding: boolean;

  /**
   * Determines if the last find petty cash refund process has been ended successfully.
   */
  isFindCompleted: boolean;

  /**
   * Determines if there is a running create process.
   */
  public isCreating: boolean;

  /**
   * Determines if the last create process has been ended successfully.
   */
  public isCreateCompleted: boolean;
}
