import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

import { Subscription } from 'rxjs';
import { skip, tap } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';

import * as fromStoresStore from 'stores/store';
import * as fromAuthStore from 'auth/store';
import { TranslationService } from 'shared';
import { ProductsFormPOSUtil } from 'stores/utils';
import { Product, ProductClass } from 'stores/models';

/**
 * The products search tabs.
 */
enum TABS {
  classes = 'classes',
  products = 'products',
}

@Component({
  selector: 'app-point-of-sale-invoice-products-search',
  templateUrl: './point-of-sale-invoice-products-search.component.html',
})
export class PointOfSaleInvoiceProductsSearchComponent implements OnInit, OnDestroy {
  /**
   * Outputs the selected products.
   */
  @Output() products = new EventEmitter<Product[]>();

  /**
   * The list of product classes for sales screen.
   */
  productClassesForSalesScreen: ProductClass[] = [];

  /**
   * The list of product classes.
   */
  productClasses: ProductClass[];

  /**
   * The list of products for sales screen.
   */
  productsForSalesScreen: Product[];

  /**
   * The list of products.
   */
  allProducts: Product[];

  /**
   * The value of product class input for sales screen.
   */
  productClassSearchValue: string;

  /**
   * The value of product input for sales screen.
   */
  productSearchValue: string;

  /**
   * Get default location.
   */
  defaultLocation: number[];

  /**
   * The search form.
   */
  searchForm: FormGroup;

  /**
   * Gets or sets the selected tab.
   * @default 'classes'
   */
  activePage: TABS = TABS.classes;

  /**
   * Shows or hides the products list.
   */
  productsListVisibility = false;

  /**
   * The set of subscriptions on this components,
   * these subscriptions must be unsubscribed before this component got destroyed.
   */
  subscriptions = new Subscription();

  /**
   * Gets or sets the current selected language.
   */
  get currentLang() {
    return this.translationService.language;
  }

  /**
   * @param authStore$ the auth-store module.
   * @param storesStore$ stores-module store.
   * @param translationService The translation service.
   */
  constructor(
    private authStore$: Store<fromAuthStore.AuthState>,
    private storesStore$: Store<fromStoresStore.StoresState>,
    private translationService: TranslationService
  ) {}

  ngOnInit(): void {
    this.init();
  }

  /**
   * Destroy component data
   */
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  /**
   * Initialize component data.
   */
  init() {
    this.initForm();

    /* Subscribe to selected location to select it whenever it changed. */
    this.subscriptions.add(
      this.authStore$
        .pipe(
          select(fromAuthStore.getSessionUserDefaultLocation),
          tap((location) => {
            this.defaultLocation = location ? [location.id] : [];
          })
        )
        .subscribe()
    );

    /**
     * Set data.
     */
    let isManualSearchTriggeredBeforeForProducts = false;
    this.subscriptions.add(
      this.storesStore$
        .pipe(
          select(fromStoresStore.getProductsForSalesScreen),
          tap((products) => {
            this.allProducts = products;
            this.productsForSalesScreen = this.allProducts;
            if (!isManualSearchTriggeredBeforeForProducts && !products.length) {
              isManualSearchTriggeredBeforeForProducts = true;
              this.storesStore$.dispatch(new fromStoresStore.SearchProductsForSalesScreen(this.defaultLocation));
            }
          })
        )
        .subscribe()
    );

    let isManualSearchTriggeredBeforeForClasses = false;
    this.subscriptions.add(
      this.storesStore$
        .pipe(
          select(fromStoresStore.getProductClassesForSalesScreen),
          tap((classes) => {
            this.productClasses = classes;
            this.productClassesForSalesScreen = this.productClasses;
            if (!isManualSearchTriggeredBeforeForClasses && !classes.length) {
              isManualSearchTriggeredBeforeForClasses = true;
              this.storesStore$.dispatch(new fromStoresStore.SearchProductClassesForSalesScreen(this.defaultLocation));
            }
          })
        )
        .subscribe()
    );

    /**
     * Listen for any search for product by code or barcode.
     */
    this.subscriptions.add(
      this.storesStore$
        .pipe(
          select(fromStoresStore.getSelectedProductByCodeOrBarcode),
          skip(1),
          tap((product) => {
            if (product) {
              this.selectProducts([product]);
            }
          })
        )
        .subscribe()
    );
  }

  /**
   * Initialize form and add validators.
   */
  initForm() {
    this.searchForm = new FormGroup({
      productClassName: new FormControl(''),
      productName: new FormControl(''),
    });
  }

  /**
   * Get value from the search input for product classes.
   */
  searchProductClassesInputValue(event?: KeyboardEvent, value?): void {
    if (event && event.key === 'Enter') {
      event.preventDefault();
      return;
    }

    this.productClassSearchValue = value;
    this.searchProductClasses();
  }

  /**
   * Handles the return of search for product classes.
   */
  searchProductClasses(): ProductClass[] {
    this.productClassesForSalesScreen = this.productClasses;
    if (this.productClassSearchValue) {
      this.productClassesForSalesScreen = this.productClassesForSalesScreen.filter((item) => {
        return (
          item.name.search(this.productClassSearchValue) !== -1 ||
          item.nameEn.search(this.productClassSearchValue) !== -1
        );
      });
    }
    return this.productClassesForSalesScreen;
  }

  /**
   * Updates and displays product classes for sales screen.
   */
  updateProductClasses() {
    let isManualSearchTriggeredBeforeForClasses = false;
    this.subscriptions.add(
      this.storesStore$
        .pipe(
          select(fromStoresStore.getProductClassesForSalesScreen),
          tap((classes) => {
            if (!isManualSearchTriggeredBeforeForClasses && classes.length) {
              isManualSearchTriggeredBeforeForClasses = true;
              this.storesStore$.dispatch(new fromStoresStore.SearchProductClassesForSalesScreen(this.defaultLocation));
              this.productClasses = classes;
              this.productClassesForSalesScreen = this.productClasses;
            }
          })
        )
        .subscribe()
    );
  }

  /**
   * Filters and displays products for the given product class id.
   * @param id The id of the selected product class.
   */
  selectProductClass(classId: number) {
    this.activePage = TABS.products;
    this.productsForSalesScreen = this.allProducts;
    if (classId) {
      this.productsForSalesScreen = this.productsForSalesScreen.filter((item) => item.productClassId === classId);
    }
    return this.productsForSalesScreen;
  }

  /**
   * Gets value from search input of products.
   */
  searchProductsInputValue(event?: KeyboardEvent, value?): void {
    if (event && event.key === 'Enter') {
      event.preventDefault();
      return;
    }
    this.productSearchValue = value;
    this.searchProducts();
  }

  /**
   * Handles the return of search for products.
   */
  searchProducts(): Product[] {
    this.productsForSalesScreen = this.allProducts;
    if (this.productSearchValue) {
      this.productsForSalesScreen = this.productsForSalesScreen.filter((product) => {
        return (
          product.description.search(this.productSearchValue) !== -1 ||
          product.descriptionEn.search(this.productSearchValue) !== -1 ||
          product.barcode.search(this.productSearchValue) !== -1 ||
          product.itemCode.search(this.productSearchValue) !== -1
        );
      });
    }
    return this.productsForSalesScreen;
  }

  /**
   * Updates and displays products for sales screen.
   */
  updateProducts() {
    let isManualSearchTriggeredBeforeForProducts = false;
    this.subscriptions.add(
      this.storesStore$
        .pipe(
          select(fromStoresStore.getProductsForSalesScreen),
          tap((products) => {
            this.productsForSalesScreen = products;
            this.productsForSalesScreen = this.allProducts;
            if (!isManualSearchTriggeredBeforeForProducts && products.length) {
              isManualSearchTriggeredBeforeForProducts = true;
              this.storesStore$.dispatch(new fromStoresStore.SearchProductsForSalesScreen(this.defaultLocation));
            }
          })
        )
        .subscribe()
    );
  }

  /**
   * Notify the parent component to add the selected products to the products form in the invoice.
   */
  selectProducts(products: Product[]): void {
    if (!products?.length) {
      return;
    }

    this.products.emit(products);
  }

  /**
   * Handles the search for product using product code event.
   * @param event The event that results from the barcode scan or code enter.
   */
  searchProductByCode(event: KeyboardEvent, value?) {
    if (event && event.key === 'Enter') {
      event.preventDefault();
      return;
    }
    this.productSearchValue = value;
    this.searchProducts();
  }

  /**
   * Enables trackBy feature for *ngFor utility to track items
   * depending on a comparer to reduce DOM change.
   * If the item is already exist then no HTML will change, only bounded values.
   * @param index The index of the item.
   * @param customer The item to determine if it was changed or not.
   */
  trackItems(index: number, item: ProductClass | Product) {
    return item ? item.id : undefined;
  }

  /**
   * Calculates the product sale price value including tax.
   * @param product The product to calculate the price value.
   * @returns The product sale price value including tax.
   */
  getProductPriceWithTax(product: Product): number {
    const productSalesDiscount = product.productSalesDiscounts?.find(
      (discount) => discount.locationId === this.defaultLocation?.[0]
    );

    return ProductsFormPOSUtil.invoiceNet([
      {
        quantity: 1,
        tax: product.tax,
        value: product.salePrice ?? 0,
        discount: productSalesDiscount?.discount ?? 0,
        discountPercent: productSalesDiscount?.discountPercent ?? 0,
        unitOfMeasureId: undefined,
      },
    ]);
  }
}
