import { Injectable } from '@angular/core';

import { of } from 'rxjs';
import { switchMap, map, catchError, tap } from 'rxjs/operators';
import { Effect, ofType, Actions } from '@ngrx/effects';

import { NotificationService, StatusCode, PAGINATION, TranslationService } from 'shared';
import { RegionsService } from 'stores/services';
import {
  CreateRegion,
  CreateRegionFail,
  CreateRegionSuccess,
  DeleteRegion,
  DeleteRegionFail,
  DeleteRegionSuccess,
  FindRegion,
  FindRegionFail,
  FindRegionSuccess,
  SearchRegions,
  SearchRegionsFail,
  SearchRegionsSuccess,
  RegionsActionType,
  UpdateRegion,
  UpdateRegionFail,
  UpdateRegionSuccess,
} from 'stores/store/actions';

@Injectable()
export class RegionsEffects {
  constructor(
    private actions$: Actions,
    private regionsService: RegionsService,
    private notificationService: NotificationService,
    private translationService: TranslationService
  ) {}

  /* ========================= SEARCH_REGIONS =================================== */
  @Effect()
  search$ = this.actions$.pipe(
    ofType(RegionsActionType.SEARCH_REGIONS),
    switchMap(({ payload }: SearchRegions) =>
      this.regionsService.search(payload.name, payload.page, PAGINATION.Regions).pipe(
        map((response) => new SearchRegionsSuccess(response)),
        catchError((error) => of(new SearchRegionsFail(error)))
      )
    )
  );

  /* ========================= FIND_REGION =================================== */
  @Effect()
  find$ = this.actions$.pipe(
    ofType(RegionsActionType.FIND_REGION),
    switchMap((action: FindRegion) =>
      this.regionsService.findById(action.payload).pipe(
        map((response) => new FindRegionSuccess(response)),
        catchError((error) => of(new FindRegionFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  findFail$ = this.actions$.pipe(
    ofType(RegionsActionType.FIND_REGION_FAIL),
    tap((action: FindRegionFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(this.translationService.translate('STORES.REGIONS.REGION_NOT_FOUND'));
      }
    })
  );

  /* ========================= CREATE_REGION =================================== */
  @Effect()
  create$ = this.actions$.pipe(
    ofType(RegionsActionType.CREATE_REGION),
    switchMap((action: CreateRegion) =>
      this.regionsService.create(action.payload).pipe(
        map((response) => new CreateRegionSuccess(response)),
        catchError((error) => of(new CreateRegionFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  createSuccess$ = this.actions$.pipe(
    ofType(RegionsActionType.CREATE_REGION_SUCCESS),
    tap((action: CreateRegionSuccess) => {
      this.notificationService.success(this.translationService.translate('STORES.REGIONS.REGION_ADDED'));
    })
  );

  @Effect({ dispatch: false })
  createFail$ = this.actions$.pipe(
    ofType(RegionsActionType.CREATE_REGION_FAIL),
    tap((action: CreateRegionFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );

  /* ========================= UPDATE_REGION =================================== */
  @Effect()
  update$ = this.actions$.pipe(
    ofType(RegionsActionType.UPDATE_REGION),
    switchMap((action: UpdateRegion) =>
      this.regionsService.update(action.payload).pipe(
        map((response) => new UpdateRegionSuccess(response)),
        catchError((error) => of(new UpdateRegionFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  updateSuccess$ = this.actions$.pipe(
    ofType(RegionsActionType.UPDATE_REGION_SUCCESS),
    tap((action: UpdateRegionSuccess) => {
      this.notificationService.success(this.translationService.translate('STORES.REGIONS.REGION_UPDATED'));
    })
  );

  @Effect({ dispatch: false })
  updateFail$ = this.actions$.pipe(
    ofType(RegionsActionType.UPDATE_REGION_FAIL),
    tap((action: UpdateRegionFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(this.translationService.translate('STORES.REGIONS.REGION_NOT_FOUND'));
      } else if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );

  /* ========================= DELETE_REGION =================================== */
  @Effect()
  delete$ = this.actions$.pipe(
    ofType(RegionsActionType.DELETE_REGION),
    switchMap((action: DeleteRegion) =>
      this.regionsService.delete(action.payload).pipe(
        map((response) => new DeleteRegionSuccess(response)),
        catchError((error) => of(new DeleteRegionFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  deleteSuccess$ = this.actions$.pipe(
    ofType(RegionsActionType.DELETE_REGION_SUCCESS),
    tap((action: DeleteRegionSuccess) => {
      this.notificationService.success(this.translationService.translate('STORES.REGIONS.REGION_DELETED'));
    })
  );

  @Effect({ dispatch: false })
  deleteFail$ = this.actions$.pipe(
    ofType(RegionsActionType.DELETE_REGION_FAIL),
    tap((action: DeleteRegionFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(this.translationService.translate('STORES.REGIONS.REGION_NOT_FOUND'));
      } else if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );
}
