/**
 * The set of events sent back from the websocket server as a response for actions sent from the application.
 */
export enum WebsocketEvent {
  //#region Reports

  //#region Stores Reports

  ProductsListReportReady = 'ProductsListReportReady',
  ProductMovementReportReady = 'ProductMovementReportReady',
  IncomingStockReportReady = 'IncomingStockReportReady',
  OutgoingStockReportReady = 'OutgoingStockReportReady',
  TransferStockReportReady = 'TransferStockReportReady',
  StockInventoryReportReady = 'StockInventoryReportReady',
  StockMonitoringReportReady = 'StockMonitoringReportReady',
  UnhandledSalesStockReportReady = 'UnhandledSalesStockReportReady',

  //#endregion Stores Reports

  //#region Finances Reports

  AccountAnalysisReportReady = 'AccountAnalysisReportReady',
  BalanceSheetReportReady = 'BalanceSheetReportReady',
  BankAccountSummaryReportReady = 'BankAccountSummaryReportReady',
  IncomeStatementReportReady = 'IncomeStatementReportReady',
  JournalsReportReady = 'JournalsReportReady',
  TaxesReportReady = 'TaxesReportReady',
  TrialBalanceReportReady = 'TrialBalanceReportReady',

  //#endregion Finances Reports

  //#region Purchases Reports

  PurchaseOrdersReportReady = 'PurchaseOrdersReportReady',
  PurchaseRequestsReportReady = 'PurchaseRequestsReportReady',
  TopPurchasedProductsReportReady = 'TopPurchasedProductsReportReady',
  WorstPurchasedProductsReportReady = 'WorstPurchasedProductsReportReady',
  PurchaseReturnsReportReady = 'PurchaseReturnsReportReady',
  TopReturnedPurchaseProductsReportReady = 'TopReturnedPurchaseProductsReportReady',
  WorstReturnedPurchaseProductsReportReady = 'WorstReturnedPurchaseProductsReportReady',
  VendorSummaryReportReady = 'VendorSummaryReportReady',
  VendorsDebtsReportReady = 'VendorsDebtsReportReady',
  PurchaseInvoicesReportReady = 'PurchaseInvoicesReportReady',

  //#endregion Purchases Reports

  //#region Sales Reports

  CustomerSummaryReportReady = 'CustomerSummaryReportReady',
  CustomersDebtsReportReady = 'CustomersDebtsReportReady',
  CustomersPaymentsReportReady = 'CustomersPaymentsReportReady',
  CustomersPointsReportReady = 'CustomersPointsReportReady',
  GrossProfitReportReady = 'GrossProfitReportReady',
  MaintenanceStageInvoiceReportReady = 'MaintenanceStageInvoiceReportReady',
  TopReturnedSalesProductsReportReady = 'TopReturnedSalesProductsReportReady',
  NetSalesReportReady = 'NetSalesReportReady',
  ProductSalesReportReady = 'ProductSalesReportReady',
  ProductSalesReturnsReportReady = 'ProductSalesReturnsReportReady',
  ProductClassesSalesReportReady = 'ProductClassesSalesReportReady',
  ProductsSalesReportReady = 'ProductsSalesReportReady',
  ProductsSalesReturnsReportReady = 'ProductsSalesReturnsReportReady',
  SalesPersonSalesReportReady = 'SalesPersonSalesReportReady',
  SalesInvoicesReportReady = 'SalesInvoicesReportReady',
  SalesReturnsReportReady = 'SalesReturnsReportReady',
  SalesSummaryReportReady = 'SalesSummaryReportReady',
  ShiftSummaryReportReady = 'ShiftSummaryReportReady',
  TopCustomersReportReady = 'TopCustomersReportReady',
  TopSellingProductsReportReady = 'TopSellingProductsReportReady',
  WorstCustomersReportReady = 'WorstCustomersReportReady',
  WorstReturnedSalesProductsReportReady = 'WorstReturnedSalesProductsReportReady',
  WorstSellingProductsReportReady = 'WorstSellingProductsReportReady',

  //#endregion Sales Reports

  //#endregion Reports

  //#region Printing

  //#region Prints

  IncomingStockPrintReady = 'IncomingStockPrintReady',
  OutgoingStockPrintReady = 'OutgoingStockPrintReady',
  TransferStockPrintReady = 'TransferStockPrintReady',
  PurchaseRequestPrintReady = 'PurchaseRequestPrintReady',
  PurchaseOrderPrintReady = 'PurchaseOrderPrintReady',
  PurchaseInvoicePrintReady = 'PurchaseInvoicePrintReady',
  PurchaseReturnPrintReady = 'PurchaseReturnPrintReady',
  SaleInvoicePrintReady = 'SaleInvoicePrintReady',
  SaleReturnPrintReady = 'SaleReturnPrintReady',
  SalesQuotationPrintReady = 'SalesQuotationPrintReady',
  JournalPrintReady = 'JournalPrintReady',

  //#endregion Prints

  //#endregion Printing
}
