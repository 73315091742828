<!-- page header starts -->
<app-page-header [info]="pageInfo"></app-page-header>
<!-- page header ends -->

<!-- shifts list starts -->
<div class="row">
  <div class="col-md-12">
    <div class="card">
      <div class="card-header mb-3">
        <h4 class="card-title">{{ 'SHARED.SEARCH.SEARCH_TOOLS' | translate }}</h4>
      </div>
      <div class="card-content">
        <div class="px-3">
          <div class="row">
            <div class="col-md-12">
              <form class="row" [formGroup]="searchForm" (ngSubmit)="search()">
                <div class="col">
                  <div class="row">
                    <div class="form-group col-12 col-sm-6 mb-0">
                      <label for="fromDate"> {{ 'SHARED.SEARCH.FROM_DATE' | translate }} </label>
                      <div class="input-group round">
                        <input
                          class="form-control"
                          id="fromDate"
                          name="fromDate"
                          formControlName="fromDate"
                          [placeholder]="'SHARED.SEARCH.DATE_PLACEHOLDER' | translate"
                          ngbDatepicker
                          #fromDate="ngbDatepicker"
                        />
                        <div class="input-group-append">
                          <span
                            class="input-group-text gradient-light-blue-indigo"
                            style="cursor: pointer;"
                            (click)="fromDate.toggle()"
                          >
                            <i class="fa fa-calendar"></i>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="form-group col-12 col-sm-6 mb-0">
                      <label for="toDate"> {{ 'SHARED.SEARCH.TO_DATE' | translate }} </label>
                      <div class="input-group round">
                        <input
                          class="form-control"
                          id="toDate"
                          name="toDate"
                          formControlName="toDate"
                          [placeholder]="'SHARED.SEARCH.DATE_PLACEHOLDER' | translate"
                          ngbDatepicker
                          #toDate="ngbDatepicker"
                        />
                        <div class="input-group-append">
                          <span
                            class="input-group-text gradient-light-blue-indigo"
                            style="cursor: pointer;"
                            (click)="toDate.toggle()"
                          >
                            <i class="fa fa-calendar"></i>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-auto form-group d-flex">
                  <button
                    type="submit"
                    class="btn btn-raised btn-primary align-self-end round mx-1 mb-0"
                    [disabled]="isSearching$ | async"
                  >
                    <i class="fa fa-search"></i>
                  </button>
                  <button
                    *ngIf="[Claims.START_NEW_SHIFT] | authorize | async"
                    type="button"
                    (click)="startShift()"
                    [disabled]="isShiftCreating$ | async"
                    class="btn btn-raised btn-success align-self-end round mx-1 mb-0"
                  >
                    <i class="fa fa-plus"></i>
                    <span class="mx-1">{{ 'SALES.SHIFTS.SHIFT_DATA.START_SHIFT' | translate }}</span>
                  </button>
                  <app-export-tools
                    *ngIf="(shifts$ | async)?.length"
                    class="d-flex align-self-end mx-1"
                    targetElementId="my-shifts-table-export-target-element"
                    [outputFileName]="'SHARED.EXPORT_TABLES.MY_SHIFTS_TABLE' | translate"
                  ></app-export-tools>
                </div>
              </form>
            </div>
            <div class="col-md-12" *ngIf="(shifts$ | async)?.length; else noItems">
              <div class="row">
                <div class="col-md-12 alert alert-light text-center" role="alert">
                  {{ 'SHARED.SEARCH.SEARCH_RESULTS' | translate }} ({{ (paginationInfo$ | async).total | toNumber }})
                  {{ 'SALES.SHIFTS.SHIFT_DATA.SHIFT' | translate }}
                </div>
                <div class="col-md-12">
                  <div class="table-responsive">
                    <table class="table text-center" id="my-shifts-table-export-target-element">
                      <thead>
                        <tr>
                          <th scope="col"><i class="fa fa-list-ol"></i></th>
                          <th scope="col">{{ 'SALES.SHIFTS.SHIFT_DATA.STARTED_AT' | translate }}</th>
                          <th scope="col">{{ 'SALES.SHIFTS.SHIFT_DATA.ENDED_AT' | translate }}</th>
                          <th scope="col">{{ 'SALES.SHIFTS.SHIFT_DATA.BRANCH' | translate }}</th>
                          <th scope="col">{{ 'SHARED.TOOLS' | translate }}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let shift of shifts$ | async; trackBy: trackItems; let i = index">
                          <th scope="row">{{ i + 1 }}</th>
                          <td>{{ shift.startedAt | date: 'datetime' }}</td>
                          <td>{{ shift.endedAt | date: 'datetime' }}</td>
                          <td>
                            {{ shift.location.name | localize: shift.location.nameEn | async }}
                          </td>
                          <td>
                            <a
                              *ngIf="!(isCancelling$ | async) && !shift.endedAt"
                              class="primary p-0"
                              [ngbTooltip]="'SALES.SHIFTS.SHIFT_DATA.END_SHIFT' | translate"
                              (click)="
                                selectedShift = shift; closeForm.setValue({ cashValue: null }); openModal(closeModalRef)
                              "
                            >
                              <i class="fa fa-check font-medium-3 mr-2"></i>
                            </a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div class="d-flex justify-content-between p-2">
                      <ngb-pagination
                        *ngIf="paginationInfo$ | async; let pagination"
                        maxSize="5"
                        [collectionSize]="pagination.total"
                        [pageSize]="pagination.pageSize"
                        [page]="pagination.page"
                        (pageChange)="pageChanged($event)"
                        boundaryLinks="true"
                      >
                        <ng-template ngbPaginationFirst>{{
                          'SHARED.PAGINATION.PAGINATION_FIRST' | translate
                        }}</ng-template>
                        <ng-template ngbPaginationPrevious>{{
                          'SHARED.PAGINATION.PAGINATION_PREVIOUS' | translate
                        }}</ng-template>
                        <ng-template ngbPaginationNext>{{
                          'SHARED.PAGINATION.PAGINATION_NEXT' | translate
                        }}</ng-template>
                        <ng-template ngbPaginationLast>{{
                          'SHARED.PAGINATION.PAGINATION_LAST' | translate
                        }}</ng-template>
                      </ngb-pagination>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- shifts list ends -->

<!-- create shift modal starts -->
<ng-template #createModalRef let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
      {{ 'SALES.SHIFTS.CONFIRM_CREATE_SHIFT_TITLE' | translate }}
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="d()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p class="text-success">{{ 'SALES.SHIFTS.CONFIRM_CREATE_SHIFT_MESSAGE' | translate }}</p>
  </div>
  <div class="modal-footer">
    <div class="modal-actions">
      <button
        type="button"
        class="btn btn-raised btn-success round"
        [disabled]="isShiftCreating$ | async"
        (click)="confirmCreate()"
        ngbAutofocus
      >
        <i class="fa fa-check"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.SAVE' | translate }}</span>
      </button>
      <button type="button" class="btn btn-outline-dark round" (click)="c()">
        <i class="fa fa-times"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.CANCEL' | translate }}</span>
      </button>
    </div>
  </div>
</ng-template>
<!-- create shift modal ends -->

<!-- no items template starts -->
<ng-template #noItems>
  <div class="col-md-12 alert alert-warning mt-3 text-center" role="alert">
    {{ 'SALES.SHIFTS.SEARCH_NOT_FOUND' | translate }}
    <button
      *ngIf="[Claims.START_NEW_SHIFT] | authorize | async"
      type="button"
      (click)="startShift()"
      [disabled]="isShiftCreating$ | async"
      class="btn btn-raised btn-success round mx-1"
    >
      <i class="fa fa-plus"></i>
      <span class="mx-1">{{ 'SALES.SHIFTS.SHIFT_DATA.START_SHIFT' | translate }}</span>
    </button>
  </div>
</ng-template>
<!-- no items template ends -->

<!-- close shift modal starts -->
<ng-template #closeModalRef let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title text-primary" id="modal-basic-title">
      {{ 'SALES.SHIFTS.CONFIRM_END_SHIFT_TITLE' | translate }}
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="d()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-md-12">
        <form [formGroup]="closeForm">
          <div class="row">
            <div class="form-group col-md-12">
              <label for="cashValue">{{ 'SALES.SHIFTS.CASH_VALUE' | translate }}</label>
              <input
                type="text"
                id="cashValue"
                [mask]="DECIMAL_MASK"
                thousandSeparator=","
                class="form-control round"
                [placeholder]="'SALES.SHIFTS.CASH_VALUE_PLACEHOLDER' | translate"
                formControlName="cashValue"
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="modal-actions">
      <button
        type="button"
        class="btn btn-raised btn-primary round"
        (click)="c(); close()"
        ngbAutofocus
        [disabled]="(isCancelling$ | async) || closeForm.invalid"
      >
        <i class="fa fa-check"></i>
        <span class="mx-1">{{ 'SALES.SHIFTS.SHIFT_DATA.END_SHIFT' | translate }}</span>
      </button>
      <button type="button" class="btn btn-outline-dark round" (click)="c()">
        <i class="fa fa-times"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.CANCEL' | translate }}</span>
      </button>
    </div>
  </div>
</ng-template>
<!-- close shift modal ends -->
