import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';
import { Socket } from '@socket.io.client';

import * as fromReportsStore from 'reports/store';
import * as fromPrintingStore from 'printing/store';
import { WebsocketEvent } from 'core/models';
import { Report } from 'reports/models';
import { AppHttpResponse, StatusCode } from 'shared/models';
import { NotificationService } from 'shared/services/notification.service';
import { TranslationService } from 'shared/services/translation.service';

/**
 * Contains the functionality for websocket events handling.
 */
@Injectable()
export class WebsocketEventsService {
  /**
   * @param store$ A reference to the state management store.
   */
  constructor(
    private store$: Store,
    private notificationService: NotificationService,
    private translationService: TranslationService
  ) {}

  /**
   * Setups the event handlers on the provided {@link Socket} instance.
   * @param socket The {@link Socket} instance.
   */
  setupHandlers(socket: Socket): void {
    /**
     * 1- We will exploit the fact of the state management dispatch actions
     *    that sends the dispatched actions to all registered reducers.
     * 2- Each module will setup its listeners independently.
     * 3- Finally, We acknowledge all events on one step
     *    to avoid repeating the acknowledgement in each event listener.
     */
    this.setReportsModuleHandlers(socket);
    this.setPrintingModuleHandlers(socket);

    socket.onAny((eventName, response: AppHttpResponse<any>, error, callback) => {
      /**
       * Acknowledge all events.
       */
      (callback ?? (() => {}))();

      /**
       * Handle global errors.
       */
      if (response?.statusCode === StatusCode.ClientErrorForbidden) {
        this.notificationService.errorWithTitle({
          title: this.translationService.translate('CORE.ERRORS.NOT_ALLOWED_TITLE'),
          body: [this.translationService.translate('CORE.ERRORS.NOT_ALLOWED')],
        });
      } else if (response?.statusCode === StatusCode.ServerErrorInternal) {
        this.notificationService.error(this.translationService.translate('CORE.ERRORS.SERVER_ERROR'));
      }
    });
  }

  /**
   * Setups the event handlers for the ReportsModule on the provided {@link Socket} instance.
   * @param socket The {@link Socket} instance.
   */
  private setReportsModuleHandlers(socket: Socket): void {
    //#region Stores

    socket.on(WebsocketEvent.ProductsListReportReady, (response: AppHttpResponse<Report>) => {
      if (response.statusCode === StatusCode.SuccessOK) {
        this.store$.dispatch(new fromReportsStore.GetProductsListReportSuccess(response));
      } else {
        this.store$.dispatch(new fromReportsStore.GetProductsListReportFail(response));
      }
    });

    socket.on(WebsocketEvent.ProductMovementReportReady, (response: AppHttpResponse<Report>) => {
      if (response.statusCode === StatusCode.SuccessOK) {
        this.store$.dispatch(new fromReportsStore.GetProductMovementReportSuccess(response));
      } else {
        this.store$.dispatch(new fromReportsStore.GetProductMovementReportFail(response));
      }
    });

    socket.on(WebsocketEvent.IncomingStockReportReady, (response: AppHttpResponse<Report>) => {
      if (response.statusCode === StatusCode.SuccessOK) {
        this.store$.dispatch(new fromReportsStore.GetIncomingStockReportSuccess(response));
      } else {
        this.store$.dispatch(new fromReportsStore.GetIncomingStockReportFail(response));
      }
    });

    socket.on(WebsocketEvent.OutgoingStockReportReady, (response: AppHttpResponse<Report>) => {
      if (response.statusCode === StatusCode.SuccessOK) {
        this.store$.dispatch(new fromReportsStore.GetOutgoingStockReportSuccess(response));
      } else {
        this.store$.dispatch(new fromReportsStore.GetOutgoingStockReportFail(response));
      }
    });

    socket.on(WebsocketEvent.TransferStockReportReady, (response: AppHttpResponse<Report>) => {
      if (response.statusCode === StatusCode.SuccessOK) {
        this.store$.dispatch(new fromReportsStore.GetTransferStockReportSuccess(response));
      } else {
        this.store$.dispatch(new fromReportsStore.GetTransferStockReportFail(response));
      }
    });

    socket.on(WebsocketEvent.StockInventoryReportReady, (response: AppHttpResponse<Report>) => {
      if (response.statusCode === StatusCode.SuccessOK) {
        this.store$.dispatch(new fromReportsStore.GetStockInventoryReportSuccess(response));
      } else {
        this.store$.dispatch(new fromReportsStore.GetStockInventoryReportFail(response));
      }
    });

    socket.on(WebsocketEvent.StockMonitoringReportReady, (response: AppHttpResponse<Report>) => {
      if (response.statusCode === StatusCode.SuccessOK) {
        this.store$.dispatch(new fromReportsStore.GetStockMonitoringReportSuccess(response));
      } else {
        this.store$.dispatch(new fromReportsStore.GetStockMonitoringReportFail(response));
      }
    });

    socket.on(WebsocketEvent.UnhandledSalesStockReportReady, (response: AppHttpResponse<Report>) => {
      if (response.statusCode === StatusCode.SuccessOK) {
        this.store$.dispatch(new fromReportsStore.GetUnhandledSalesStockReportSuccess(response));
      } else {
        this.store$.dispatch(new fromReportsStore.GetUnhandledSalesStockReportFail(response));
      }
    });

    //#endregion Stores

    //#region Finances

    socket.on(WebsocketEvent.AccountAnalysisReportReady, (response: AppHttpResponse<Report>) => {
      if (response.statusCode === StatusCode.SuccessOK) {
        this.store$.dispatch(new fromReportsStore.GetAccountAnalysisReportSuccess(response));
      } else {
        this.store$.dispatch(new fromReportsStore.GetAccountAnalysisReportFail(response));
      }
    });

    socket.on(WebsocketEvent.BalanceSheetReportReady, (response: AppHttpResponse<Report>) => {
      if (response.statusCode === StatusCode.SuccessOK) {
        this.store$.dispatch(new fromReportsStore.GetBalanceSheetReportSuccess(response));
      } else {
        this.store$.dispatch(new fromReportsStore.GetBalanceSheetReportFail(response));
      }
    });

    socket.on(WebsocketEvent.BankAccountSummaryReportReady, (response: AppHttpResponse<Report>) => {
      if (response.statusCode === StatusCode.SuccessOK) {
        this.store$.dispatch(new fromReportsStore.GetBankAccountSummaryReportSuccess(response));
      } else {
        this.store$.dispatch(new fromReportsStore.GetBankAccountSummaryReportFail(response));
      }
    });

    socket.on(WebsocketEvent.IncomeStatementReportReady, (response: AppHttpResponse<Report>) => {
      if (response.statusCode === StatusCode.SuccessOK) {
        this.store$.dispatch(new fromReportsStore.GetIncomeStatementReportSuccess(response));
      } else {
        this.store$.dispatch(new fromReportsStore.GetIncomeStatementReportFail(response));
      }
    });

    socket.on(WebsocketEvent.JournalsReportReady, (response: AppHttpResponse<Report>) => {
      if (response.statusCode === StatusCode.SuccessOK) {
        this.store$.dispatch(new fromReportsStore.GetJournalsReportSuccess(response));
      } else {
        this.store$.dispatch(new fromReportsStore.GetJournalsReportFail(response));
      }
    });

    socket.on(WebsocketEvent.TaxesReportReady, (response: AppHttpResponse<Report>) => {
      if (response.statusCode === StatusCode.SuccessOK) {
        this.store$.dispatch(new fromReportsStore.GetTaxesReportSuccess(response));
      } else {
        this.store$.dispatch(new fromReportsStore.GetTaxesReportFail(response));
      }
    });

    socket.on(WebsocketEvent.TrialBalanceReportReady, (response: AppHttpResponse<Report>) => {
      if (response.statusCode === StatusCode.SuccessOK) {
        this.store$.dispatch(new fromReportsStore.GetTrialBalanceReportSuccess(response));
      } else {
        this.store$.dispatch(new fromReportsStore.GetTrialBalanceReportFail(response));
      }
    });

    //#endregion Finances

    //#region purchases

    socket.on(WebsocketEvent.PurchaseOrdersReportReady, (response: AppHttpResponse<Report>) => {
      if (response.statusCode === StatusCode.SuccessOK) {
        this.store$.dispatch(new fromReportsStore.GetPurchaseOrdersReportSuccess(response));
      } else {
        this.store$.dispatch(new fromReportsStore.GetPurchaseOrdersReportFail(response));
      }
    });

    socket.on(WebsocketEvent.PurchaseRequestsReportReady, (response: AppHttpResponse<Report>) => {
      if (response.statusCode === StatusCode.SuccessOK) {
        this.store$.dispatch(new fromReportsStore.GetPurchaseRequestsReportSuccess(response));
      } else {
        this.store$.dispatch(new fromReportsStore.GetPurchaseRequestsReportFail(response));
      }
    });

    socket.on(WebsocketEvent.PurchaseInvoicesReportReady, (response: AppHttpResponse<Report>) => {
      if (response.statusCode === StatusCode.SuccessOK) {
        this.store$.dispatch(new fromReportsStore.GetPurchaseInvoicesReportSuccess(response));
      } else {
        this.store$.dispatch(new fromReportsStore.GetPurchaseInvoicesReportFail(response));
      }
    });

    socket.on(WebsocketEvent.TopPurchasedProductsReportReady, (response: AppHttpResponse<Report>) => {
      if (response.statusCode === StatusCode.SuccessOK) {
        this.store$.dispatch(new fromReportsStore.GetTopPurchasedProductsReportSuccess(response));
      } else {
        this.store$.dispatch(new fromReportsStore.GetTopPurchasedProductsReportFail(response));
      }
    });

    socket.on(WebsocketEvent.WorstPurchasedProductsReportReady, (response: AppHttpResponse<Report>) => {
      if (response.statusCode === StatusCode.SuccessOK) {
        this.store$.dispatch(new fromReportsStore.GetWorstPurchasedProductsReportSuccess(response));
      } else {
        this.store$.dispatch(new fromReportsStore.GetWorstPurchasedProductsReportFail(response));
      }
    });

    socket.on(WebsocketEvent.PurchaseReturnsReportReady, (response: AppHttpResponse<Report>) => {
      if (response.statusCode === StatusCode.SuccessOK) {
        this.store$.dispatch(new fromReportsStore.GetPurchaseReturnsReportSuccess(response));
      } else {
        this.store$.dispatch(new fromReportsStore.GetPurchaseReturnsReportFail(response));
      }
    });

    socket.on(WebsocketEvent.TopReturnedPurchaseProductsReportReady, (response: AppHttpResponse<Report>) => {
      if (response.statusCode === StatusCode.SuccessOK) {
        this.store$.dispatch(new fromReportsStore.GetTopReturnedPurchaseProductsReportSuccess(response));
      } else {
        this.store$.dispatch(new fromReportsStore.GetTopReturnedPurchaseProductsReportFail(response));
      }
    });

    socket.on(WebsocketEvent.WorstReturnedPurchaseProductsReportReady, (response: AppHttpResponse<Report>) => {
      if (response.statusCode === StatusCode.SuccessOK) {
        this.store$.dispatch(new fromReportsStore.GetWorstReturnedPurchaseProductsReportSuccess(response));
      } else {
        this.store$.dispatch(new fromReportsStore.GetWorstReturnedPurchaseProductsReportFail(response));
      }
    });

    socket.on(WebsocketEvent.VendorSummaryReportReady, (response: AppHttpResponse<Report>) => {
      if (response.statusCode === StatusCode.SuccessOK) {
        this.store$.dispatch(new fromReportsStore.GetVendorSummaryReportSuccess(response));
      } else {
        this.store$.dispatch(new fromReportsStore.GetVendorSummaryReportFail(response));
      }
    });

    socket.on(WebsocketEvent.VendorsDebtsReportReady, (response: AppHttpResponse<Report>) => {
      if (response.statusCode === StatusCode.SuccessOK) {
        this.store$.dispatch(new fromReportsStore.GetVendorsDebtsReportSuccess(response));
      } else {
        this.store$.dispatch(new fromReportsStore.GetVendorsDebtsReportFail(response));
      }
    });

    //#endregion Purchases

    //#region Sales

    socket.on(WebsocketEvent.CustomerSummaryReportReady, (response: AppHttpResponse<Report>) => {
      if (response.statusCode === StatusCode.SuccessOK) {
        this.store$.dispatch(new fromReportsStore.GetCustomerSummaryReportSuccess(response));
      } else {
        this.store$.dispatch(new fromReportsStore.GetCustomerSummaryReportFail(response));
      }
    });

    socket.on(WebsocketEvent.CustomersDebtsReportReady, (response: AppHttpResponse<Report>) => {
      if (response.statusCode === StatusCode.SuccessOK) {
        this.store$.dispatch(new fromReportsStore.GetCustomersDebtsReportSuccess(response));
      } else {
        this.store$.dispatch(new fromReportsStore.GetCustomersDebtsReportFail(response));
      }
    });

    socket.on(WebsocketEvent.CustomersPaymentsReportReady, (response: AppHttpResponse<Report>) => {
      if (response.statusCode === StatusCode.SuccessOK) {
        this.store$.dispatch(new fromReportsStore.GetCustomersPaymentsReportSuccess(response));
      } else {
        this.store$.dispatch(new fromReportsStore.GetCustomersPaymentsReportFail(response));
      }
    });

    socket.on(WebsocketEvent.CustomersPointsReportReady, (response: AppHttpResponse<Report>) => {
      if (response.statusCode === StatusCode.SuccessOK) {
        this.store$.dispatch(new fromReportsStore.GetCustomersPointsReportSuccess(response));
      } else {
        this.store$.dispatch(new fromReportsStore.GetCustomersPointsReportFail(response));
      }
    });

    socket.on(WebsocketEvent.GrossProfitReportReady, (response: AppHttpResponse<Report>) => {
      if (response.statusCode === StatusCode.SuccessOK) {
        this.store$.dispatch(new fromReportsStore.GetGrossProfitReportSuccess(response));
      } else {
        this.store$.dispatch(new fromReportsStore.GetGrossProfitReportFail(response));
      }
    });

    socket.on(WebsocketEvent.MaintenanceStageInvoiceReportReady, (response: AppHttpResponse<Report>) => {
      if (response.statusCode === StatusCode.SuccessOK) {
        this.store$.dispatch(new fromReportsStore.GetMaintenanceStageInvoiceReportSuccess(response));
      } else {
        this.store$.dispatch(new fromReportsStore.GetMaintenanceStageInvoiceReportFail(response));
      }
    });

    socket.on(WebsocketEvent.TopReturnedSalesProductsReportReady, (response: AppHttpResponse<Report>) => {
      if (response.statusCode === StatusCode.SuccessOK) {
        this.store$.dispatch(new fromReportsStore.GetTopReturnedSalesProductsReportSuccess(response));
      } else {
        this.store$.dispatch(new fromReportsStore.GetTopReturnedSalesProductsReportFail(response));
      }
    });

    socket.on(WebsocketEvent.NetSalesReportReady, (response: AppHttpResponse<Report>) => {
      if (response.statusCode === StatusCode.SuccessOK) {
        this.store$.dispatch(new fromReportsStore.GetNetSalesReportSuccess(response));
      } else {
        this.store$.dispatch(new fromReportsStore.GetNetSalesReportFail(response));
      }
    });

    socket.on(WebsocketEvent.ProductSalesReportReady, (response: AppHttpResponse<Report>) => {
      if (response.statusCode === StatusCode.SuccessOK) {
        this.store$.dispatch(new fromReportsStore.GetProductSalesReportSuccess(response));
      } else {
        this.store$.dispatch(new fromReportsStore.GetProductSalesReportFail(response));
      }
    });

    socket.on(WebsocketEvent.ProductSalesReturnsReportReady, (response: AppHttpResponse<Report>) => {
      if (response.statusCode === StatusCode.SuccessOK) {
        this.store$.dispatch(new fromReportsStore.GetProductSalesReturnsReportSuccess(response));
      } else {
        this.store$.dispatch(new fromReportsStore.GetProductSalesReturnsReportFail(response));
      }
    });

    socket.on(WebsocketEvent.ProductClassesSalesReportReady, (response: AppHttpResponse<Report>) => {
      if (response.statusCode === StatusCode.SuccessOK) {
        this.store$.dispatch(new fromReportsStore.GetProductClassesSalesReportSuccess(response));
      } else {
        this.store$.dispatch(new fromReportsStore.GetProductClassesSalesReportFail(response));
      }
    });

    socket.on(WebsocketEvent.ProductsSalesReportReady, (response: AppHttpResponse<Report>) => {
      if (response.statusCode === StatusCode.SuccessOK) {
        this.store$.dispatch(new fromReportsStore.GetProductsSalesReportSuccess(response));
      } else {
        this.store$.dispatch(new fromReportsStore.GetProductsSalesReportFail(response));
      }
    });

    socket.on(WebsocketEvent.ProductsSalesReturnsReportReady, (response: AppHttpResponse<Report>) => {
      if (response.statusCode === StatusCode.SuccessOK) {
        this.store$.dispatch(new fromReportsStore.GetProductsSalesReturnsReportSuccess(response));
      } else {
        this.store$.dispatch(new fromReportsStore.GetProductsSalesReturnsReportFail(response));
      }
    });

    socket.on(WebsocketEvent.SalesPersonSalesReportReady, (response: AppHttpResponse<Report>) => {
      if (response.statusCode === StatusCode.SuccessOK) {
        this.store$.dispatch(new fromReportsStore.GetSalesPersonSalesReportSuccess(response));
      } else {
        this.store$.dispatch(new fromReportsStore.GetSalesPersonSalesReportFail(response));
      }
    });

    socket.on(WebsocketEvent.SalesInvoicesReportReady, (response: AppHttpResponse<Report>) => {
      if (response.statusCode === StatusCode.SuccessOK) {
        this.store$.dispatch(new fromReportsStore.GetSalesInvoicesReportSuccess(response));
      } else {
        this.store$.dispatch(new fromReportsStore.GetSalesInvoicesReportFail(response));
      }
    });

    socket.on(WebsocketEvent.SalesReturnsReportReady, (response: AppHttpResponse<Report>) => {
      if (response.statusCode === StatusCode.SuccessOK) {
        this.store$.dispatch(new fromReportsStore.GetSalesReturnsReportSuccess(response));
      } else {
        this.store$.dispatch(new fromReportsStore.GetSalesReturnsReportFail(response));
      }
    });

    socket.on(WebsocketEvent.SalesSummaryReportReady, (response: AppHttpResponse<Report>) => {
      if (response.statusCode === StatusCode.SuccessOK) {
        this.store$.dispatch(new fromReportsStore.GetSalesSummaryReportSuccess(response));
      } else {
        this.store$.dispatch(new fromReportsStore.GetSalesSummaryReportFail(response));
      }
    });

    socket.on(WebsocketEvent.ShiftSummaryReportReady, (response: AppHttpResponse<Report>) => {
      if (response.statusCode === StatusCode.SuccessOK) {
        this.store$.dispatch(new fromReportsStore.GetShiftSummaryReportSuccess(response));
      } else {
        this.store$.dispatch(new fromReportsStore.GetShiftSummaryReportFail(response));
      }
    });

    socket.on(WebsocketEvent.TopCustomersReportReady, (response: AppHttpResponse<Report>) => {
      if (response.statusCode === StatusCode.SuccessOK) {
        this.store$.dispatch(new fromReportsStore.GetTopCustomersReportSuccess(response));
      } else {
        this.store$.dispatch(new fromReportsStore.GetTopCustomersReportFail(response));
      }
    });

    socket.on(WebsocketEvent.TopSellingProductsReportReady, (response: AppHttpResponse<Report>) => {
      if (response.statusCode === StatusCode.SuccessOK) {
        this.store$.dispatch(new fromReportsStore.GetTopSellingProductsReportSuccess(response));
      } else {
        this.store$.dispatch(new fromReportsStore.GetTopSellingProductsReportFail(response));
      }
    });

    socket.on(WebsocketEvent.WorstCustomersReportReady, (response: AppHttpResponse<Report>) => {
      if (response.statusCode === StatusCode.SuccessOK) {
        this.store$.dispatch(new fromReportsStore.GetWorstCustomersReportSuccess(response));
      } else {
        this.store$.dispatch(new fromReportsStore.GetWorstCustomersReportFail(response));
      }
    });

    socket.on(WebsocketEvent.WorstReturnedSalesProductsReportReady, (response: AppHttpResponse<Report>) => {
      if (response.statusCode === StatusCode.SuccessOK) {
        this.store$.dispatch(new fromReportsStore.GetWorstReturnedSalesProductsReportSuccess(response));
      } else {
        this.store$.dispatch(new fromReportsStore.GetWorstReturnedSalesProductsReportFail(response));
      }
    });

    socket.on(WebsocketEvent.WorstSellingProductsReportReady, (response: AppHttpResponse<Report>) => {
      if (response.statusCode === StatusCode.SuccessOK) {
        this.store$.dispatch(new fromReportsStore.GetWorstSellingProductsReportSuccess(response));
      } else {
        this.store$.dispatch(new fromReportsStore.GetWorstSellingProductsReportFail(response));
      }
    });

    //#endregion Sales
  }

  /**
   * Setups the event handlers for the PrintingModule on the provided {@link Socket} instance.
   * @param socket The {@link Socket} instance.
   */
  private setPrintingModuleHandlers(socket: Socket): void {
    //#region Prints

    socket.on(WebsocketEvent.IncomingStockPrintReady, (response: AppHttpResponse<Report>) => {
      if (response.statusCode === StatusCode.SuccessOK) {
        this.store$.dispatch(new fromPrintingStore.GetIncomingStockPrintSuccess(response));
      } else {
        this.store$.dispatch(new fromPrintingStore.GetIncomingStockPrintFail(response));
      }
    });

    socket.on(WebsocketEvent.OutgoingStockPrintReady, (response: AppHttpResponse<Report>) => {
      if (response.statusCode === StatusCode.SuccessOK) {
        this.store$.dispatch(new fromPrintingStore.GetOutgoingStockPrintSuccess(response));
      } else {
        this.store$.dispatch(new fromPrintingStore.GetOutgoingStockPrintFail(response));
      }
    });

    socket.on(WebsocketEvent.TransferStockPrintReady, (response: AppHttpResponse<Report>) => {
      if (response.statusCode === StatusCode.SuccessOK) {
        this.store$.dispatch(new fromPrintingStore.GetTransferStockPrintSuccess(response));
      } else {
        this.store$.dispatch(new fromPrintingStore.GetTransferStockPrintFail(response));
      }
    });

    socket.on(WebsocketEvent.PurchaseRequestPrintReady, (response: AppHttpResponse<Report>) => {
      if (response.statusCode === StatusCode.SuccessOK) {
        this.store$.dispatch(new fromPrintingStore.GetPurchaseRequestPrintSuccess(response));
      } else {
        this.store$.dispatch(new fromPrintingStore.GetPurchaseRequestPrintFail(response));
      }
    });

    socket.on(WebsocketEvent.PurchaseOrderPrintReady, (response: AppHttpResponse<Report>) => {
      if (response.statusCode === StatusCode.SuccessOK) {
        this.store$.dispatch(new fromPrintingStore.GetPurchaseOrderPrintSuccess(response));
      } else {
        this.store$.dispatch(new fromPrintingStore.GetPurchaseOrderPrintFail(response));
      }
    });

    socket.on(WebsocketEvent.PurchaseInvoicePrintReady, (response: AppHttpResponse<Report>) => {
      if (response.statusCode === StatusCode.SuccessOK) {
        this.store$.dispatch(new fromPrintingStore.GetPurchaseInvoicePrintSuccess(response));
      } else {
        this.store$.dispatch(new fromPrintingStore.GetPurchaseInvoicePrintFail(response));
      }
    });

    socket.on(WebsocketEvent.PurchaseReturnPrintReady, (response: AppHttpResponse<Report>) => {
      if (response.statusCode === StatusCode.SuccessOK) {
        this.store$.dispatch(new fromPrintingStore.GetPurchaseReturnPrintSuccess(response));
      } else {
        this.store$.dispatch(new fromPrintingStore.GetPurchaseReturnPrintFail(response));
      }
    });

    socket.on(WebsocketEvent.SaleInvoicePrintReady, (response: AppHttpResponse<Report>) => {
      if (response.statusCode === StatusCode.SuccessOK) {
        this.store$.dispatch(new fromPrintingStore.GetSaleInvoicePrintSuccess(response));
      } else {
        this.store$.dispatch(new fromPrintingStore.GetSaleInvoicePrintFail(response));
      }
    });

    socket.on(WebsocketEvent.SaleReturnPrintReady, (response: AppHttpResponse<Report>) => {
      if (response.statusCode === StatusCode.SuccessOK) {
        this.store$.dispatch(new fromPrintingStore.GetSaleReturnPrintSuccess(response));
      } else {
        this.store$.dispatch(new fromPrintingStore.GetSaleReturnPrintFail(response));
      }
    });

    socket.on(WebsocketEvent.SalesQuotationPrintReady, (response: AppHttpResponse<Report>) => {
      if (response.statusCode === StatusCode.SuccessOK) {
        this.store$.dispatch(new fromPrintingStore.GetSalesQuotationPrintSuccess(response));
      } else {
        this.store$.dispatch(new fromPrintingStore.GetSalesQuotationPrintFail(response));
      }
    });

    socket.on(WebsocketEvent.JournalPrintReady, (response: AppHttpResponse<Report>) => {
      if (response.statusCode === StatusCode.SuccessOK) {
        this.store$.dispatch(new fromPrintingStore.GetJournalPrintSuccess(response));
      } else {
        this.store$.dispatch(new fromPrintingStore.GetJournalPrintFail(response));
      }
    });

    //#endregion Prints
  }
}
