<!-- project info form starts -->
<div class="row">
  <div class="col-md-12">
    <form [formGroup]="form" (ngSubmit)="submit()">
      <div class="card-header mb-3 pt-0">
        <h4 class="card-title">{{ 'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA_TITLE' | translate }}</h4>
      </div>
      <div class="card-content">
        <div class="px-3">
          <div class="row">
            <div class="form-group col-md-6">
              <label for="code"> {{ 'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.CODE' | translate }}</label>
              <input
                type="text"
                id="code"
                class="form-control round"
                [placeholder]="'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.CODE_PLACEHOLDER' | translate"
                maxlength="50"
                formControlName="code"
              />
            </div>
            <div class="form-group col-md-6">
              <label for="description">
                {{ 'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.ARABIC_PROJECT_DESCRIPTION' | translate }}</label
              >
              <input
                type="text"
                id="description"
                class="form-control round"
                [placeholder]="
                  'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.ARABIC_PROJECT_DESCRIPTION_PLACEHOLDER' | translate
                "
                maxlength="200"
                formControlName="description"
              />
            </div>
            <div class="form-group col-md-6">
              <label for="descriptionEn">
                {{ 'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.ENGLISH_PROJECT_DESCRIPTION' | translate }}</label
              >
              <input
                type="text"
                id="descriptionEn"
                class="form-control round"
                [placeholder]="
                  'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.ENGLISH_PROJECT_DESCRIPTION_PLACEHOLDER' | translate
                "
                maxlength="200"
                formControlName="descriptionEn"
              />
            </div>
            <div class="form-group col-md-6">
              <label for="customerId">{{ 'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.CUSTOMER' | translate }}</label>
              <ng-select
                class="round"
                formControlName="customerId"
                labelForId="customerId"
                searchable="true"
                [placeholder]="'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.CUSTOMER_PLACEHOLDER' | translate"
                [notFoundText]="'SHARED.SEARCH.SEARCH_NOT_FOUND' | translate"
                (open)="customersListVisibility = true"
              >
                <ng-option *ngFor="let customer of customers" [value]="customer.id">
                  {{ customer.name | localize: customer.nameEn | async }}
                </ng-option>
              </ng-select>
            </div>
            <div class="form-group col-md-6">
              <label for="locationId">{{ 'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.LOCATION' | translate }}</label>
              <ng-select
                class="round"
                formControlName="locationId"
                labelForId="locationId"
                searchable="true"
                [placeholder]="'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.LOCATION_PLACEHOLDER' | translate"
                [notFoundText]="'SHARED.SEARCH.SEARCH_NOT_FOUND' | translate"
                (open)="locationsListVisibility = true"
              >
                <ng-option *ngFor="let location of locations" [value]="location.id">
                  {{ location.name | localize: location.nameEn | async }}
                </ng-option>
              </ng-select>
            </div>
            <div class="form-group col-md-6">
              <label for="projectStatusId">
                {{ 'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.STATUS' | translate }}
              </label>
              <ng-select
                class="round"
                formControlName="projectStatusId"
                labelForId="projectStatusId"
                searchable="true"
                autocomplete="off"
                [placeholder]="'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.STATUS_PLACEHOLDER' | translate"
                [notFoundText]="'SHARED.SEARCH.SEARCH_NOT_FOUND' | translate"
              >
                <ng-option *ngFor="let projectStatus of projectStatuses" [value]="projectStatus?.id">
                  {{ projectStatus?.name | localize: projectStatus?.nameEn | async }}
                </ng-option>
              </ng-select>
            </div>
            <div class="form-group col-md-6">
              <label for="startDate">
                {{ 'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.START_DATE' | translate }}
              </label>
              <div class="input-group round">
                <input
                  class="form-control"
                  id="startDate"
                  name="startDate"
                  formControlName="startDate"
                  [placeholder]="'SHARED.SEARCH.DATE_PLACEHOLDER' | translate"
                  ngbDatepicker
                  #startDate="ngbDatepicker"
                />
                <div class="input-group-append">
                  <span
                    class="input-group-text gradient-light-blue-indigo"
                    style="cursor: pointer;"
                    (click)="startDate.toggle()"
                  >
                    <i class="fa fa-calendar"></i>
                  </span>
                </div>
              </div>
            </div>
            <div class="form-group col-md-6">
              <label for="endDate">
                {{ 'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.END_DATE' | translate }}
              </label>
              <div class="input-group round">
                <input
                  class="form-control"
                  id="endDate"
                  name="endDate"
                  formControlName="endDate"
                  [placeholder]="'SHARED.SEARCH.DATE_PLACEHOLDER' | translate"
                  ngbDatepicker
                  #endDate="ngbDatepicker"
                />
                <div class="input-group-append">
                  <span
                    class="input-group-text gradient-light-blue-indigo"
                    style="cursor: pointer;"
                    (click)="endDate.toggle()"
                  >
                    <i class="fa fa-calendar"></i>
                  </span>
                </div>
              </div>
            </div>
            <div class="form-group col-md-6">
              <label for="laborBudget ">
                {{ 'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.LABOR_BUDGET' | translate }}</label
              >
              <input
                type="text"
                id="laborBudget"
                mask="separator.2"
                thousandSeparator=","
                minLength="0"
                class="form-control round"
                [placeholder]="'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.LABOR_BUDGET_PLACEHOLDER' | translate"
                formControlName="laborBudget"
              />
            </div>
            <div class="form-group col-md-6">
              <label for="equipmentBudget ">
                {{ 'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.EQUIPMENT_BUDGET' | translate }}</label
              >
              <input
                type="text"
                id="equipmentBudget"
                mask="separator.2"
                thousandSeparator=","
                minLength="0"
                class="form-control round"
                [placeholder]="'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.EQUIPMENT_BUDGET_PLACEHOLDER' | translate"
                formControlName="equipmentBudget"
              />
            </div>
            <div class="form-group col-md-6">
              <label for="laborOvertimeBudget ">
                {{ 'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.LABOR_OVERTIME_BUDGET' | translate }}</label
              >
              <input
                type="text"
                id="laborOvertimeBudget"
                mask="separator.2"
                thousandSeparator=","
                minLength="0"
                class="form-control round"
                [placeholder]="
                  'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.LABOR_OVERTIME_BUDGET_PLACEHOLDER' | translate
                "
                formControlName="laborOvertimeBudget"
              />
            </div>
            <div class="form-group col-md-6">
              <label for="nonLaborBudget">
                {{ 'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.NON_LABOR_BUDGET' | translate }}</label
              >
              <input
                type="text"
                id="nonLaborBudget"
                readonly
                class="form-control round"
                [placeholder]="'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.NON_LABOR_BUDGET_PLACEHOLDER' | translate"
                [value]="projectObj.nonLaborBudget | toDecimal"
              />
            </div>
            <div class="form-group col-md-6">
              <label for="budget"> {{ 'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.BUDGET' | translate }}</label>
              <input
                type="text"
                id="budget"
                readonly
                class="form-control round"
                [placeholder]="'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.BUDGET_PLACEHOLDER' | translate"
                [value]="projectObj.budget | toDecimal"
              />
            </div>
            <div class="form-group col-md-6">
              <label for="laborCost"> {{ 'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.LABOR_COST' | translate }}</label>
              <input
                type="text"
                id="laborCost"
                readonly
                class="form-control round"
                [placeholder]="'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.LABOR_COST_PLACEHOLDER' | translate"
                [value]="projectObj.laborCost | toDecimal"
              />
            </div>
            <div class="form-group col-md-6">
              <label for="equipmentCost">
                {{ 'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.EQUIPMENT_COST' | translate }}</label
              >
              <input
                type="text"
                id="equipmentCost"
                readonly
                class="form-control round"
                [placeholder]="'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.EQUIPMENT_COST_PLACEHOLDER' | translate"
                [value]="projectObj.equipmentCost | toDecimal"
              />
            </div>
            <div class="form-group col-md-6">
              <label for="laborOvertimeCost">
                {{ 'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.LABOR_OVERTIME_COST' | translate }}</label
              >
              <input
                type="text"
                id="laborOvertimeCost"
                readonly
                class="form-control round"
                [placeholder]="'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.LABOR_OVERTIME_COST_PLACEHOLDER' | translate"
                [value]="projectObj.laborOvertimeCost | toDecimal"
              />
            </div>
            <div class="form-group col-md-6">
              <label for="nonLaborCost">
                {{ 'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.NON_LABOR_COST' | translate }}</label
              >
              <input
                type="text"
                id="nonLaborCost"
                readonly
                class="form-control round"
                [placeholder]="'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.NON_LABOR_COST_PLACEHOLDER' | translate"
                [value]="projectObj.nonLaborCost | toDecimal"
              />
            </div>
            <div class="form-group col-md-6">
              <label for="cost"> {{ 'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.COST' | translate }}</label>
              <input
                type="text"
                id="cost"
                readonly
                class="form-control round"
                [placeholder]="'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.COST_PLACEHOLDER' | translate"
                [value]="projectObj.cost | toDecimal"
              />
            </div>
            <div class="form-group col-md-6">
              <label for="remainingBudget">
                {{ 'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.REMAINING_BUDGET' | translate }}</label
              >
              <input
                type="text"
                id="remainingBudget"
                readonly
                class="form-control round"
                [placeholder]="'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.REMAINING_BUDGET_PLACEHOLDER' | translate"
                [value]="projectObj.remainingBudget | toDecimal"
              />
            </div>
            <div class="form-group col-md-6">
              <label for="remainingLaborOvertimeBudget">
                {{ 'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.REMAINING_LABOR_OVERTIME_BUDGET' | translate }}</label
              >
              <input
                type="text"
                id="remainingLaborOvertimeBudget"
                readonly
                class="form-control round"
                [placeholder]="
                  'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.REMAINING_LABOR_OVERTIME_BUDGET_PLACEHOLDER' | translate
                "
                [value]="projectObj.remainingLaborOvertimeBudget | toDecimal"
              />
            </div>
            <div class="form-group col-md-6">
              <label for="revenueBudget">
                {{ 'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.REVENUE_BUDGET' | translate }}</label
              >
              <input
                type="text"
                id="revenueBudget"
                mask="separator.2"
                thousandSeparator=","
                minLength="0"
                class="form-control round"
                [placeholder]="'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.REVENUE_BUDGET_PLACEHOLDER' | translate"
                formControlName="revenueBudget"
              />
            </div>
            <div class="form-group col-md-6">
              <label for="revenue"> {{ 'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.REVENUE' | translate }}</label>
              <input
                type="text"
                id="revenue"
                readonly
                class="form-control round"
                [placeholder]="'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.REVENUE_PLACEHOLDER' | translate"
                [value]="projectObj.revenue | toDecimal"
              />
            </div>
            <div class="form-group col-md-6">
              <label for="remainingRevenue">
                {{ 'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.REMAINING_REVENUE_BUDGET' | translate }}</label
              >
              <input
                type="text"
                id="remainingRevenue"
                readonly
                class="form-control round"
                [placeholder]="
                  'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.REMAINING_REVENUE_BUDGET_PLACEHOLDER' | translate
                "
                [value]="projectObj.remainingRevenue | toDecimal"
              />
            </div>
            <div class="form-group col-md-auto">
              <input type="checkbox" class="cursor-pointer" formControlName="allowExceedBudget" />
              <span class="ml-2 mt-0 pt-0">{{
                'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.ALLOW_EXCEED_BUDGET' | translate
              }}</span>
            </div>
          </div>
          <div class="row">
            <div class="form-group col-12 d-flex justify-content-between flex-column flex-sm-row mt-2">
              <button type="submit" class="btn btn-raised btn-success round">
                <i class="fa fa-check"></i>
                <span class="mx-1">{{ 'SHARED.CONFIRM.SAVE' | translate }}</span>
              </button>
              <a class="btn btn-dark round" routerLink="/projects-management/projects">
                <i class="fa fa-undo"></i>
                <span class="mx-1">{{ 'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.PROJECTS_RETURN' | translate }}</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
<!-- project info form ends -->

<!-- confirm modal starts -->
<ng-template #confirmModalRef let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
      {{ 'PROJECTS_MANAGEMENT.PROJECTS.CONFIRM_UPDATE_PROJECT_DETAILS_TITLE' | translate }}
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="d()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p class="text-success">
      {{ 'PROJECTS_MANAGEMENT.PROJECTS.CONFIRM_UPDATE_PROJECT_DETAILS_MESSAGE' | translate }}
    </p>
  </div>
  <div class="modal-footer">
    <div class="modal-actions">
      <button type="button" class="btn btn-raised btn-success round" (click)="c(); confirm()" ngbAutofocus>
        <i class="fa fa-check"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.SAVE' | translate }}</span>
      </button>
      <button type="button" class="btn btn-outline-dark round" (click)="c()">
        <i class="fa fa-times"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.CANCEL' | translate }}</span>
      </button>
    </div>
  </div>
</ng-template>
<!-- confirm modal ends -->

<!-- customers search starts -->
<app-customers-search
  onlyActive="true"
  closeOnSelect="true"
  (customers)="selectCustomer($event)"
  [(visible)]="customersListVisibility"
>
</app-customers-search>
<!-- customers search ends -->

<locations-search
  onlyActive="true"
  closeOnSelect="true"
  (locations)="selectLocation($event)"
  [(visible)]="locationsListVisibility"
>
</locations-search>
<!-- locations search ends -->
