import { Action } from '@ngrx/store';

import { AppHttpResponse } from 'shared';
import { PettyCashReduction, CreatePettyCashReductionInput } from 'finances/models';

/**
 * The finances-module store petty Cash reduction action types.
 */
export enum PettyCashReductionActionType {
  SEARCH_PETTY_CASH_REDUCTION = '[Finances] [PettyCashReduction] Search Petty Cash Reduction',
  SEARCH_PETTY_CASH_REDUCTION_FAIL = '[Finances] [PettyCashReduction] Search Petty Cash Reduction Fail',
  SEARCH_PETTY_CASH_REDUCTION_SUCCESS = '[Finances] [PettyCashReduction] Search Petty Cash Reduction Success',

  FIND_PETTY_CASH_REDUCTION = '[Finances] [PettyCashReduction] Find Petty Cash Reduction',
  FIND_PETTY_CASH_REDUCTION_FAIL = '[Finances] [PettyCashReduction] Find Petty Cash Reduction Fail',
  FIND_PETTY_CASH_REDUCTION_SUCCESS = '[Finances] [PettyCashReduction] Find Petty Cash Reduction Success',

  CREATE_PETTY_CASH_REDUCTION = '[Finances] [PettyCashReduction] Create Petty Cash Reduction',
  CREATE_PETTY_CASH_REDUCTION_FAIL = '[Finances] [PettyCashReduction] Create Petty Cash Reduction Fail',
  CREATE_PETTY_CASH_REDUCTION_SUCCESS = '[Finances] [PettyCashReduction] Create Petty Cash Reduction Success',
}

/**
 * Represents a store petty cash reduction search action.
 */
export class SearchPettyCashReduction implements Action {
  /**
   * The type of the action.
   */
  readonly type = PettyCashReductionActionType.SEARCH_PETTY_CASH_REDUCTION;

  /**
   * Fires a new petty cash reduction search action.
   * @param payload the search parameters,
   * if omitted, all petty cash reduction will be loaded.
   */
  constructor(
    public payload?: {
      pettyCash: number[];
      fromDate: Date;
      toDate: Date;
      page: number;
    }
  ) {}
}

/**
 * Represents a store petty cash reduction search-fail action.
 */
export class SearchPettyCashReductionFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = PettyCashReductionActionType.SEARCH_PETTY_CASH_REDUCTION_FAIL;

  /**
   * Fires a new petty cash reduction search-fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store petty cash reduction search-success action.
 */
export class SearchPettyCashReductionSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = PettyCashReductionActionType.SEARCH_PETTY_CASH_REDUCTION_SUCCESS;

  /**
   * Fires a new petty cash reduction search-success action.
   * @param payload An object contains the list of petty cash reduction that matches the search criteria
   * in addition to the pagination info.
   */
  constructor(public payload: AppHttpResponse<PettyCashReduction[]>) {}
}

/**
 * Represents a store find-petty-cash-reduction action.
 */
export class FindPettyCashReduction implements Action {
  /**
   * The type of the action.
   */
  readonly type = PettyCashReductionActionType.FIND_PETTY_CASH_REDUCTION;

  /**
   * Fires a new Find petty cash reduction action.
   * @param payload the id of the petty cash reduction.
   */
  constructor(public payload: number) {}
}

/**
 * Represents a store find-petty-cash-reduction-fail action.
 */
export class FindPettyCashReductionFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = PettyCashReductionActionType.FIND_PETTY_CASH_REDUCTION_FAIL;

  /**
   * Fires a new find-petty-cash-reduction-fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store Find-petty-cash-reduction-success action.
 */
export class FindPettyCashReductionSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = PettyCashReductionActionType.FIND_PETTY_CASH_REDUCTION_SUCCESS;

  /**
   * Fires a new find-petty-cash-reduction-success action.
   * @param payload The loaded petty-cash-reduction.
   */
  constructor(public payload: AppHttpResponse<PettyCashReduction>) {}
}

/**
 * Represents a store petty cash reduction create action.
 */
export class CreatePettyCashReduction implements Action {
  /** The type of the action. */
  readonly type = PettyCashReductionActionType.CREATE_PETTY_CASH_REDUCTION;

  /**
   * Fires a new petty cash reduction create action.
   * @param payload the new petty cash reduction data-model.
   */
  constructor(public payload: CreatePettyCashReductionInput) {}
}

/**
 * Represents a store petty cash reduction create-fail action.
 */
export class CreatePettyCashReductionFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = PettyCashReductionActionType.CREATE_PETTY_CASH_REDUCTION_FAIL;

  /**
   * Fires a new petty cash reduction create-fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store petty cash reduction create-success action.
 */
export class CreatePettyCashReductionSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = PettyCashReductionActionType.CREATE_PETTY_CASH_REDUCTION_SUCCESS;

  /**
   * Fires a new petty cash reduction create-success action.
   * @param payload The created petty cash reduction.
   */
  constructor(public payload: AppHttpResponse<PettyCashReduction>) {}
}

/**
 * Finances-module petty cash reduction action types.
 */
export type PettyCashReductionActions =
  | SearchPettyCashReduction
  | SearchPettyCashReductionFail
  | SearchPettyCashReductionSuccess
  | FindPettyCashReduction
  | FindPettyCashReductionFail
  | FindPettyCashReductionSuccess
  | CreatePettyCashReduction
  | CreatePettyCashReductionFail
  | CreatePettyCashReductionSuccess;
