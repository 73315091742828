import { Component, OnDestroy, OnInit } from '@angular/core';

import { Observable, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import * as fromAuthStore from 'auth/store';
import { BankAccount } from 'finances/models';

@Component({
  selector: 'app-user-cash-accounts-list',
  templateUrl: './user-cash-accounts-list.component.html',
  styles: [],
})
export class UserCashAccountsListComponent implements OnInit, OnDestroy {
  /**
   * The current logged-in user's default cash bank account id.
   */
  userDefaultCashBankAccountId: number;

  /**
   * The list of user bank accounts.
   */
  userCashAccounts$: Observable<BankAccount[]>;

  /**
   * Indicates whether there is a update process is running or not.
   */
  isUpdating$: Observable<boolean>;

  /**
   * The set of subscriptions on this components,
   * these subscriptions must be unsubscribed before this component got destroyed.
   */
  subscriptions = new Subscription();

  /**
   * @param modalService The modal service.
   * @param authStore$ The auth-store.
   */
  constructor(private modalService: NgbModal, private authStore$: Store<fromAuthStore.AuthState>) {}

  ngOnInit(): void {
    this.init();
  }

  /**
   * Destroy component data
   */
  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  /**
   * Initialize component data.
   */
  init() {
    /**
     * Load data.
     */
    this.isUpdating$ = this.authStore$.pipe(select(fromAuthStore.getSessionUserDefaultCashAccountUpdating));
    this.userCashAccounts$ = this.authStore$.pipe(select(fromAuthStore.getUserBankAccounts));

    this.subscriptions.add(
      this.authStore$
        .pipe(
          select(fromAuthStore.getSessionUserDefaultCashBankAccount),
          tap((account) => {
            this.userDefaultCashBankAccountId = account?.id;
          })
        )
        .subscribe()
    );
  }

  /**
   * Sets default cash account for current logged-in user.
   */
  selectDefaultCashAccount(userCashAccountId: number) {
    this.authStore$.dispatch(new fromAuthStore.UpdateUserDefaultCashAccount(userCashAccountId));
  }

  /**
   * Enables trackBy feature for *ngFor utility to track elements
   * depending on a comparer to reduce DOM change.
   * If the element is already exist then no HTML will change, only bounded values.
   * @param userCashAccount The user cash account to determine if it was changed or not.
   */
  trackItems(userCashAccount: BankAccount) {
    return userCashAccount ? userCashAccount.id : undefined;
  }

  /**
   * Opens the modal of the given templateRef.
   * @param modalRef The modal templateRef to be opened.
   */
  openModal(modalRef) {
    this.modalService.open(modalRef);
  }
}
