import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { FormGroup, FormArray } from '@angular/forms';

import { Observable, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import * as fromFinancesStore from 'finances/store';
import { CustomValidators } from 'shared';
import { GovService, GovServiceItem, UpdateGovServiceInput } from 'finances/models';

@Component({
  selector: 'app-gov-services',
  templateUrl: './gov-services.component.html',
  styles: [],
})
export class GovServicesComponent implements OnInit, OnDestroy {
  /**
   * The confirm modal template reference.
   */
  @ViewChild('confirmModalRef') confirmModalRef: ElementRef<any>;

  /**
   * Indicates whether there is an update process is running or not.
   */
  isUpdating$: Observable<boolean>;

  /**
   * The list of gov services.
   */
  govServices$: Observable<GovService[]>;

  /**
   * The update gov services form.
   */
  form: FormGroup;

  /**
   * The set of subscriptions on this components,
   * these subscriptions must be unsubscribed before this component got destroyed.
   */
  subscriptions = new Subscription();

  /**
   * Used to add new gov service form items to the gov services form.
   */
  newGovServices: GovServiceItem[];

  /**
   * Gets the gov services form-array.
   */
  get govServicesForm(): FormArray {
    return this.form?.controls.govServices as FormArray;
  }

  /**
   * @param modalService The modal service.
   * @param financesStore$ The finances store.
   */
  constructor(private modalService: NgbModal, private financesStore$: Store<fromFinancesStore.FinancesState>) {}

  ngOnInit(): void {
    this.init();
  }

  /**
   * Destroy component data
   */
  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  /**
   * Initialize component data.
   */
  init() {
    this.initForm();

    /* Load the data. */

    this.subscriptions.add(
      this.financesStore$
        .pipe(
          select(fromFinancesStore.getGovServices),
          tap((services) => {
            this.resetGovServices();

            this.newGovServices = services.map((service: GovService) => ({
              govServiceId: service.id,
              govService: service,
              advanceAccountId: service.advanceAccountId,
              advanceAccount: service.advanceAccount,
              expenseAccountId: service.expenseAccountId,
              expenseAccount: service.expenseAccount,
            }));
          })
        )
        .subscribe()
    );
    this.financesStore$.dispatch(new fromFinancesStore.GetGovServices());

    this.isUpdating$ = this.financesStore$.pipe(select(fromFinancesStore.getSelectedGovServicesUpdating));
  }

  /**
   * Initialize forms and add validators.
   */
  initForm() {
    this.form = new FormGroup({
      govServices: new FormArray([], CustomValidators.arrayItems(1)),
    });
  }

  /**
   * Resets gov services data.
   */
  resetGovServices() {
    this.govServicesForm.clear();
  }

  /**
   * Opens the modal of the given templateRef.
   * @param modalRef The modal templateRef to be opened.
   */
  openModal(modalRef) {
    this.modalService.open(modalRef);
  }

  /**
   * Submits the form.
   */
  submit() {
    this.openModal(this.confirmModalRef);
  }

  /**
   * Confirms the form submit.
   */
  confirm() {
    /**
     * The list of gov services in the gov services form.
     */
    const govServices = this.govServicesForm.value.map((govService) => {
      const inputLink: UpdateGovServiceInput = {
        govServiceId: govService.govServiceId,
        advanceAccountId: govService.advanceAccountId,
        expenseAccountId: govService.expenseAccountId,
      };
      return inputLink;
    });

    /**
     * Dispatch update gov services action.
     */
    this.financesStore$.dispatch(
      new fromFinancesStore.UpdateGovServices({
        services: govServices,
      })
    );
  }
}
