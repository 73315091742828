import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';

import { Observable } from 'rxjs';
import { tap, skip } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';

import * as fromProjectsManagementStore from 'projects-management/store';
import * as fromLookupsStore from 'lookups/store';
import { PageInfo, TranslationService } from 'shared';
import { Claims } from 'security/models';
import { Project } from 'projects-management/models';
import { ProjectStatus } from 'lookups/models';

/**
 * The project file pages.
 */
enum PAGES {
  info = 'info',
  'planning-resources' = 'planning-resources',
  'resources' = 'resources',
  'plan' = 'plan',
  'active-plan' = 'active-plan',
  'project-status-log' = 'project-status-log',
  'revenues' = 'revenues',
}

@Component({
  selector: 'app-project-file',
  templateUrl: './project-file.component.html',
  styles: [],
})
export class ProjectFileComponent implements OnInit {
  /**
   * Gets or sets the information about the current page.
   */
  pageInfo: PageInfo = {
    title: 'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.PROJECT',
    icon: 'fa fa-folder-open',
  };

  /**
   * The system supported user claims.
   */
  Claims = Claims;

  /**
   * Gets or sets the id of the current edited project.
   */
  projectId: number;

  /**
   * The current edited project .
   */
  project$: Observable<Project>;

  /**
   * The name of the current project.
   */
  projectName: string;

  /**
   * The list of project status.
   */
  projectStatuses$: Observable<ProjectStatus[]>;

  /**
   * Gets or sets the selected page.
   * @default 'info'
   */

  activePage: PAGES = PAGES.info;

  /**
   * Gets or sets the current selected language.
   */
  get currentLang() {
    return this.translationService.language;
  }

  /**
   * @param locationService The location service.
   * @param route The activated route.
   * @param translationService The translation service.
   * @param projectsManagementStore$ The project management store.
   * @param lookupsStore$ the lookups-store module.
   */
  constructor(
    private locationService: Location,
    private route: ActivatedRoute,
    private translationService: TranslationService,
    private projectsManagementStore$: Store<fromProjectsManagementStore.ProjectsManagementState>,
    private lookupsStore$: Store<fromLookupsStore.LookupsState>
  ) {}

  ngOnInit(): void {
    this.init();
  }

  /**
   * Initialize component data.
   */
  init() {
    /* Load the selected project. */
    this.projectId = +this.route.snapshot.params.projectId;
    this.projectsManagementStore$.dispatch(new fromProjectsManagementStore.FindProject(this.projectId));

    this.project$ = this.projectsManagementStore$.pipe(
      select(fromProjectsManagementStore.getSelectedProject),
      skip(1),
      tap((project) => {
        if (project) {
          this.projectName = this.currentLang === 'en' ? project.descriptionEn : project.description;

          /* Update page info. */
          this.pageInfo = {
            ...this.pageInfo,
            subTitle: `(${this.projectName})`,
          };
        }
      })
    );

    let isManualSearchTriggeredBeforeForProjectStatuses = false;
    this.projectStatuses$ = this.lookupsStore$.pipe(
      select(fromLookupsStore.getProjectStatuses),
      tap((projectStatuses) => {
        if (!isManualSearchTriggeredBeforeForProjectStatuses && !projectStatuses.length) {
          isManualSearchTriggeredBeforeForProjectStatuses = true;
          this.lookupsStore$.dispatch(new fromLookupsStore.GetAllProjectStatuses());
        }
      })
    );

    /** Select the user desired page. */
    this.activePage = PAGES[this.route.snapshot.fragment] ?? this.activePage;
    this.selectedPageChanged(this.activePage);
  }

  /**
   * Updates the browser url according to the selected page.
   * @param page The newly selected page.
   */
  selectedPageChanged(page: PAGES) {
    this.locationService.replaceState(`${this.locationService.path()}#${PAGES[page]}`);
  }
}
