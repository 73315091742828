import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import { Observable, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { PageInfo, NotificationService, NotificationMessage, TranslationService } from 'shared';
import * as fromFinancesStore from 'finances/store';
import { CostCenter } from 'finances/models';

@Component({
  selector: 'app-update-cost-center',
  templateUrl: './update-cost-center.component.html',
  styles: [],
})
export class UpdateCostCenterComponent implements OnInit, OnDestroy {
  /**
   * The confirm modal template reference.
   */
  @ViewChild('confirmModalRef') confirmModalRef: ElementRef<any>;

  /**
   * Gets or sets the information about the current page.
   */
  pageInfo: PageInfo = {
    title: 'FINANCES.COST_CENTERS.UPDATE_COST_CENTER_PAGE_TITLE',
    icon: 'fa fa-edit',
  };

  /**
   * Gets or sets the id of the current edited cost center.
   */
  costCenterId: number;

  /**
   * Gets or sets the the current edited cost center name.
   */
  costCenterName: string;

  /**
   * The current edited cost center.
   */
  costCenter$: Observable<CostCenter>;

  /**
   * Indicates whether there is a update cost center process is running or not.
   */
  isUpdating$: Observable<boolean>;

  /**
   * The update petty cash form.
   */
  form: FormGroup;

  /**
   * The set of subscriptions on this components,
   * these subscriptions must be unsubscribed before this component got destroyed.
   */
  subscriptions = new Subscription();

  /**
   * Gets or sets the current selected language.
   */
  get currentLang() {
    return this.translationService.language;
  }

  /**
   * @param route The activated route.
   * @param modalService The modal service.
   * @param notificationService The notification service.
   * @param financesStore$ The finances store.
   * @param translationService The translation service.
   */
  constructor(
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private notificationService: NotificationService,
    private financesStore$: Store<fromFinancesStore.FinancesState>,
    private translationService: TranslationService
  ) {}

  ngOnInit(): void {
    this.init();
  }

  /**
   * Destroy component data
   */
  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  /**
   * Initialize component data.
   */
  init() {
    this.initForm();

    /** Subscribe to route to get the element id whenever it changed. */
    const routeSubscription = this.route.paramMap
      .pipe(
        tap((params) => {
          this.costCenterId = +params.get('costCenterId');
          this.financesStore$.dispatch(new fromFinancesStore.FindCostCenter(this.costCenterId));
        })
      )
      .subscribe();
    this.subscriptions.add(routeSubscription);

    /* Load the selected cost center. */
    this.costCenter$ = this.financesStore$.pipe(
      select(fromFinancesStore.getSelectedCostCenter),
      tap((costCenter) => {
        if (costCenter) {
          this.costCenterName = this.currentLang === 'en' ? costCenter.descriptionEn : costCenter.description;
          this.pageInfo = {
            ...this.pageInfo,
            subTitle: `(${this.costCenterName})`,
          };

          this.form.patchValue({ ...costCenter });
          this.form.markAsPristine();
        }
      })
    );

    /**
     * Load data.
     */
    this.isUpdating$ = this.financesStore$.pipe(select(fromFinancesStore.getSelectedCostCenterUpdating));
  }

  /**
   * Initialize form and add validators.
   */
  initForm() {
    this.form = new FormGroup({
      description: new FormControl('', [Validators.required, Validators.minLength(1), Validators.maxLength(50)]),
      descriptionEn: new FormControl('', [Validators.required, Validators.minLength(1), Validators.maxLength(50)]),
      code: new FormControl('', [Validators.required, Validators.minLength(1), Validators.maxLength(50)]),
    });
  }

  /**
   * Submits the form.
   */
  submit() {
    if (this.form.invalid) {
      const errorMessage = new NotificationMessage();

      if (this.form.get('code').invalid) {
        errorMessage.title = this.translationService.translate(
          'FINANCES.COST_CENTERS.COST_CENTER_DATA_VALIDATION.COST_CENTER_CODE_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate(
            'FINANCES.COST_CENTERS.COST_CENTER_DATA_VALIDATION.COST_CENTER_CODE_IS_REQUIRED'
          ),
          this.translationService.translate(
            'FINANCES.COST_CENTERS.COST_CENTER_DATA_VALIDATION.COST_CENTER_CODE_LENGTH_ERROR'
          ),
        ];
      } else if (this.form.get('description').invalid) {
        errorMessage.title = this.translationService.translate(
          'FINANCES.COST_CENTERS.COST_CENTER_DATA_VALIDATION.ARABIC_COST_CENTER_DESCRIPTION_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate(
            'FINANCES.COST_CENTERS.COST_CENTER_DATA_VALIDATION.COST_CENTER_DESCRIPTION_IS_REQUIRED'
          ),
          this.translationService.translate(
            'FINANCES.COST_CENTERS.COST_CENTER_DATA_VALIDATION.COST_CENTER_DESCRIPTION_LENGTH_ERROR'
          ),
        ];
      } else if (this.form.get('descriptionEn').invalid) {
        errorMessage.title = this.translationService.translate(
          'FINANCES.COST_CENTERS.COST_CENTER_DATA_VALIDATION.ENGLISH_COST_CENTER_DESCRIPTION_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate(
            'FINANCES.COST_CENTERS.COST_CENTER_DATA_VALIDATION.COST_CENTER_DESCRIPTION_IS_REQUIRED'
          ),
          this.translationService.translate(
            'FINANCES.COST_CENTERS.COST_CENTER_DATA_VALIDATION.COST_CENTER_DESCRIPTION_LENGTH_ERROR'
          ),
        ];
      }

      this.form.markAllAsTouched();
      return this.notificationService.warningWithTitle(errorMessage);
    } else {
      this.openModal(this.confirmModalRef);
    }
  }

  /**
   * Confirms the form submit.
   */
  confirm() {
    this.financesStore$.dispatch(
      new fromFinancesStore.UpdateCostCenter({
        id: this.costCenterId,
        ...this.form.value,
      })
    );
  }

  /**
   * Opens the modal of the given templateRef.
   * @param modalRef The modal templateRef to be opened.
   */
  openModal(modalRef) {
    this.modalService.open(modalRef);
  }
}
