import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormControl, FormArray } from '@angular/forms';

import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

import * as fromLookupsStore from 'lookups/store';
import * as fromStoresStore from 'stores/store';
import { PaginationInfo, NotificationService, TranslationService } from 'shared';
import { Location, Region, Sector } from 'stores/models';
import { LocationType } from 'lookups/models';

/**
 * organization locations search form wrapped in modal.
 */
@Component({
  selector: 'app-organization-locations-search',
  templateUrl: './organization-locations-search.component.html',
  styles: [],
})
export class OrganizationLocationsSearchComponent implements OnInit {
  /**
   * The modal template reference.
   */
  @ViewChild('modalRef') modalRef: ElementRef<any>;

  /**
   * Indicates whether the modal will automatically close after select the first location.
   *
   * Be default it will not be closed.
   */
  @Input() closeOnSelect = false;

  /**
   * Indicates whether the locations displayed on the list should be all active.
   *
   * Be default it will not be closed.
   */
  @Input() onlyActive = false;

  /**
   * Sets the visibility of this component modal.
   *
   * Handles visibility change in two-data-binding approach.
   */
  @Input() set visible(isShown: boolean) {
    if (isShown && this.modalRef) {
      this.openModal(this.modalRef);
    } else {
      this.closeModal();
    }

    this.visibleChange.emit(isShown);
  }

  /**
   * Sets the locations form-array.
   */
  @Input() locationsForm: FormArray;

  /**
   * Outputs a value that indicates whether the modal is shown or not.
   *
   * Handles visibility change in two-data-binding approach.
   */
  @Output() visibleChange = new EventEmitter<boolean>();

  /** Outputs the selected locations. */
  @Output() locations = new EventEmitter<Location[]>();

  /**
   * Shows or hide the regions list.
   */
  regionsListVisibility = false;

  /**
   * Shows or hide the sectors list.
   */
  sectorsListVisibility = false;

  /**
   * Shows or hide the create location modal.
   */
  createLocationModalVisibility = false;

  /**
   * The list of selected regions.
   */
  regions: Region[] = [];

  /**
   * The list of selected sectors.
   */
  sectors: Sector[] = [];

  /**
   * The list of locations.
   */
  locations$: Observable<Location[]>;

  /**
   * The list of locations.
   */
  locationsList: Location[];

  /**
   * The list of location types.
   */
  locationTypes$: Observable<LocationType[]>;

  /**
   * The pagination info.
   */
  paginationInfo$: Observable<PaginationInfo>;

  /**
   * Indicates whether there is a search process is running or not.
   */
  isSearching$: Observable<boolean>;

  /**
   * The search form.
   */
  searchForm: FormGroup;

  /**
   * The ngb-modal reference to use it when modal needed to be closed.
   */
  modal: NgbModalRef;

  /**
   * @param modalService The modal service.
   * @param notificationService The notification service.
   * @param translationService The translation service.
   * @param storesStore$ The stores-store module.
   * @param lookupsStore$ the lookups-store module.
   */
  constructor(
    private modalService: NgbModal,
    private notificationService: NotificationService,
    private translationService: TranslationService,
    private storesStore$: Store<fromStoresStore.StoresState>,
    private lookupsStore$: Store<fromLookupsStore.LookupsState>
  ) {}

  ngOnInit(): void {
    this.init();
  }

  /**
   * Initialize component data.
   */
  init() {
    this.initForm();

    /**
     * Load data.
     */
    let isManualSearchTriggeredBeforeForLocations = false;
    this.locations$ = this.storesStore$.pipe(
      select(fromStoresStore.getOrganizationLocations),
      tap((locations) => {
        if (locations) {
          this.locationsList = locations;
        }
        if (!isManualSearchTriggeredBeforeForLocations && !locations.length) {
          isManualSearchTriggeredBeforeForLocations = true;
          this.search();
        }
      })
    );

    let isManualSearchTriggeredBeforeForTypes = false;
    this.locationTypes$ = this.lookupsStore$.pipe(
      select(fromLookupsStore.getLocationTypes),
      tap((types) => {
        if (!isManualSearchTriggeredBeforeForTypes && !types.length) {
          isManualSearchTriggeredBeforeForTypes = true;
          this.lookupsStore$.dispatch(new fromLookupsStore.GetAllLocationType());
        }
      })
    );

    this.paginationInfo$ = this.storesStore$.pipe(select(fromStoresStore.getOrganizationLocationsPaginationInfo));
    this.isSearching$ = this.storesStore$.pipe(select(fromStoresStore.getOrganizationLocationsSearching));
  }

  /**
   * Initialize form and add validators.
   */
  initForm() {
    this.searchForm = new FormGroup({
      name: new FormControl(''),
      regions: new FormControl([]),
      sectors: new FormControl([]),
      locationTypes: new FormControl([]),
      page: new FormControl(1),
    });
  }

  /**
   * Handles search parameters change.
   */
  search(event?: KeyboardEvent) {
    if (event && event.key === 'Enter') {
      event.preventDefault();
      return;
    }

    this.storesStore$.dispatch(
      new fromStoresStore.SearchOrganizationLocations({
        name: this.searchForm.get('name').value,
        regions: this.searchForm.get('regions')?.value,
        sectors: this.searchForm.get('sectors')?.value,
        locationTypes: this.searchForm.get('locationTypes').value,
        page: 1,
      })
    );
  }

  /**
   * Handles pagination page-changed event.
   * @param page The current selected page number.
   */
  pageChanged(page: number) {
    /** Update pagination page. */
    this.searchForm.patchValue({ page });

    /** Get selected page items. */
    this.storesStore$.dispatch(
      new fromStoresStore.SearchOrganizationLocations({
        name: this.searchForm.get('name').value,
        regions: this.searchForm.get('regions')?.value,
        sectors: this.searchForm.get('sectors')?.value,
        locationTypes: this.searchForm.get('locationTypes').value,
        page: this.searchForm.get('page').value,
      })
    );
  }

  /**
   * Enables trackBy feature for *ngFor utility to track items
   * depending on a comparer to reduce DOM change.
   * If the item is already exist then no HTML will change, only bounded values.
   * @param index The index of the item.
   * @param location The item to determine if it was changed or not.
   */
  trackItems(index: number, location: Location) {
    return location ? location.id : undefined;
  }

  /**
   * Opens the modal of the given templateRef.
   * @param modalRef The modal templateRef to be opened.
   */
  openModal(modalRef) {
    /**
     * Open the modal & listen for user press ESC key.
     */
    this.modal = this.modalService.open(modalRef, { size: 'lg' });
    this.modal.result.then(null, () => this.closeModal());
  }

  /**
   * Closes the currently opened modal.
   */
  closeModal() {
    this.modal?.dismiss();
    this.locations.emit([]);
    this.visibleChange.emit(false);
  }

  /**
   * Handles location selected event.
   * @param locations The list of locations to notify selected.
   */
  selectLocation(locations: Location[]) {
    if (this.locationsForm) {
      for (let index = 0; index < this.locationsForm.length; index++) {
        if (this.locationsForm.value[index].locationId === locations[0].id) {
          if (this.closeOnSelect) {
            /**
             * Message to warn the user before selected location again.
             */
            this.notificationService.warning(
              this.translationService.translate('STORES.LOCATIONS.NOTIFICATION_MESSAGE.SELECTED_LOCATION_AGAIN')
            );
            this.closeModal();
            return;
          }
          return;
        }
      }
    }
    this.notificationService.successInstant(
      this.translationService.translate('STORES.LOCATIONS.NOTIFICATION_MESSAGE.LOCATION_SELECTED')
    );

    this.locations.emit(locations);
    if (this.closeOnSelect) {
      this.closeModal();
    }
  }

  /**
   * Adds the newly selected regions the regions search list.
   * @param regions The list of newly selected regions to be added.
   */
  selectRegion(regions: Region[]) {
    const selectedIds: number[] = this.searchForm.get('regions').value;
    this.regions = [...this.regions.filter((region) => selectedIds.includes(region.id)), ...regions];
    this.searchForm.patchValue({ regions: this.regions.map((region) => region.id) });
  }

  /**
   * Adds the newly selected sectors the sectors search list.
   * @param sectors The list of newly selected sectors to be added.
   */
  selectSector(sectors: Sector[]) {
    const selectedIds: number[] = this.searchForm.get('sectors').value;
    this.sectors = [...this.sectors.filter((sector) => selectedIds.includes(sector.id)), ...sectors];
    this.searchForm.patchValue({ sectors: this.sectors.map((sector) => sector.id) });
  }
}
