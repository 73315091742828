import { Component, Input } from '@angular/core';

import { ProjectRevenue } from 'projects-management/models';

@Component({
  selector: 'app-revenue-details',
  templateUrl: './revenue-details.component.html',
  styles: [],
})
export class RevenueDetailsComponent {
  /**
   * Sets the current viewed revenue.
   */
  @Input() revenue: ProjectRevenue;
}
