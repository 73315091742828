import { createSelector } from '@ngrx/store';

import { selectLookupsState } from 'lookups/store/selectors/feature.selectors';
import { LookupsState, AssetTypeState } from 'lookups/store/states';

/**
 * Gets the assetType state.
 */
const selectAssetTypes = createSelector(selectLookupsState, (state: LookupsState) => state.assetTypes);

/**
 * Gets the list of loaded assetTypes.
 */
export const getAssetTypes = createSelector(selectAssetTypes, (state: AssetTypeState) => state.data);

/**
 * Gets the error that occurred in the state.
 */
export const getAssetTypesError = createSelector(selectAssetTypes, (state: AssetTypeState) => state.error);

/**
 * Determines if there is a running load process.
 */
export const getAssetTypesIsLoading = createSelector(selectAssetTypes, (state: AssetTypeState) => state.isLoading);

/**
 * Determines if the last load process has been ended successfully.
 */
export const getAssetTypesIsLoaded = createSelector(selectAssetTypes, (state: AssetTypeState) => state.isLoaded);
