import { createSelector } from '@ngrx/store';

import { GovServicesState, AppSearchState } from 'app-search/store/states';
import { selectAppSearchState } from 'app-search/store/selectors/feature.selectors';

/**
 * Gets the gov relations state.
 */
const selectGovServicesState = createSelector(selectAppSearchState, (state: AppSearchState) => state.govServices);

/**
 * Gets the list of loaded services data.
 */
export const getGovServices = createSelector(selectGovServicesState, (state: GovServicesState) => state.data);

/**
 * Gets the services pagination info.
 */
export const getGovServicesPaginationInfo = createSelector(
  selectGovServicesState,
  (state: GovServicesState) => state.paginationInfo
);

/**
 * Gets the error that occurred in the state.
 */
export const getGovServicesError = createSelector(selectGovServicesState, (state: GovServicesState) => state.error);

/**
 * Determines if there is a running search process.
 */
export const getGovServicesSearching = createSelector(
  selectGovServicesState,
  (state: GovServicesState) => state.isSearching
);

/**
 * Determines if the last search process has been ended successfully.
 */
export const getGovServicesSearchCompleted = createSelector(
  selectGovServicesState,
  (state: GovServicesState) => state.isSearchCompleted
);
