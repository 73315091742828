import { createSelector } from '@ngrx/store';

import { StoresState, ProductState } from 'stores/store/states';
import { selectStoresState } from 'stores/store/selectors/feature.selectors';

/**
 * Gets the products state.
 */
const selectProductState = createSelector(selectStoresState, (state: StoresState) => state.products);

/**
 * Gets the list of loaded products.
 */
export const getProducts = createSelector(selectProductState, (state: ProductState) => state.data);

/**
 * Gets the list of loaded product sales discounts.
 */
export const getProductSalesDiscounts = createSelector(
  selectProductState,
  (state: ProductState) => state.productSalesDiscountsData
);

/**
 * Gets the products discount.
 */
export const getProductDiscounts = createSelector(selectProductState, (state: ProductState) => state.discountData);

/**
 * Gets the list of loaded products for sales screen.
 */
export const getProductsForSalesScreen = createSelector(
  selectProductState,
  (state: ProductState) => state.productsForSalesScreen
);

/**
 * Gets the product sales discounts pagination info.
 */
export const getProductSalesDiscountsPaginationInfo = createSelector(
  selectProductState,
  (state: ProductState) => state.paginationProductSalesDiscountsInfo
);

/**
 * Gets the products pagination info.
 */
export const getProductsPaginationInfo = createSelector(
  selectProductState,
  (state: ProductState) => state.paginationInfo
);

/**
 * Gets the error that occurred in the state.
 */
export const getProductsError = createSelector(selectProductState, (state: ProductState) => state.error);

/**
 * Determines if there is a running search process.
 */
export const getProductsSearching = createSelector(selectProductState, (state: ProductState) => state.isSearching);

/**
 * Determines if the last search process has been ended successfully.
 */
export const getProductsSearchCompleted = createSelector(
  selectProductState,
  (state: ProductState) => state.isSearchCompleted
);

/**
 * Determines if there is a running search product sales discounts process.
 */
export const getProductSalesDiscountsSearching = createSelector(
  selectProductState,
  (state: ProductState) => state.isProductSalesDiscountsSearching
);

/**
 * Determines if the last search product sales discounts process has been ended successfully.
 */
export const getProductSalesDiscountsSearchCompleted = createSelector(
  selectProductState,
  (state: ProductState) => state.isProductSalesDiscountsSearchCompleted
);

/**
 * Determines if there is a running search products for sales screen process.
 */
export const getProductsForSalesScreenSearching = createSelector(
  selectProductState,
  (state: ProductState) => state.isProductsForSalesScreenSearching
);

/**
 * Determines if the last search products for sales screen process has been ended successfully.
 */
export const getProductsForSalesScreenSearchCompleted = createSelector(
  selectProductState,
  (state: ProductState) => state.isProductsForSalesScreenSearchCompleted
);

/**
 * Gets the selected product.
 */
export const getSelectedProduct = createSelector(selectProductState, (state: ProductState) => state.selectedProduct);

/**
 * Gets the selected product sales discount.
 */
export const getSelectedProductSalesDiscount = createSelector(
  selectProductState,
  (state: ProductState) => state.selectedProductSalesDiscount
);

/**
 * Determines if there is a running find-product process.
 */
export const getProductsFinding = createSelector(selectProductState, (state: ProductState) => state.isFinding);

/**
 * Determines if the last find-product process has been ended successfully.
 */
export const getProductsFindCompleted = createSelector(
  selectProductState,
  (state: ProductState) => state.isFindCompleted
);

/**
 * Gets the selected product founded by code or barcode.
 */
export const getSelectedProductByCodeOrBarcode = createSelector(
  selectProductState,
  (state: ProductState) => state.selectedProductByCodeOrBarcode
);

/**
 * Determines if there is a running find-product-by-code-or-barcode process.
 */
export const getProductFindingByCodeOrBarcode = createSelector(
  selectProductState,
  (state: ProductState) => state.isFindingByCodeOrBarcode
);

/**
 * Determines if the last find-product-by-code-or-barcode process has been ended successfully.
 */
export const getProductFindByCodeOrBarcodeCompleted = createSelector(
  selectProductState,
  (state: ProductState) => state.isFindByCodeOrBarcodeCompleted
);

/**
 * Determines if there is a running create process.
 */
export const getSelectedProductCreating = createSelector(selectProductState, (state: ProductState) => state.isCreating);

/**
 * Determines if the last create process has been ended successfully.
 */
export const getSelectedProductCreateCompleted = createSelector(
  selectProductState,
  (state: ProductState) => state.isCreateCompleted
);

/**
 * Determines if there is a running update process.
 */
export const getSelectedProductUpdating = createSelector(selectProductState, (state: ProductState) => state.isUpdating);

/**
 * Determines if the last update process has been ended successfully.
 */
export const getSelectedProductUpdateCompleted = createSelector(
  selectProductState,
  (state: ProductState) => state.isUpdateCompleted
);

/**
 * Determines if there is a running product discounts create process.
 */
export const getSelectedProductDiscountsCreating = createSelector(
  selectProductState,
  (state: ProductState) => state.isProductDiscountsCreating
);

/**
 * Determines if the last product discounts create process has been ended successfully.
 */
export const getSelectedProductDiscountsCreateCompleted = createSelector(
  selectProductState,
  (state: ProductState) => state.isProductDiscountsCreateCompleted
);

/**
 * Determines if there is a running delete process.
 */
export const getSelectedProductDeleting = createSelector(selectProductState, (state: ProductState) => state.isDeleting);

/**
 * Determines if the last delete process has been ended successfully.
 */
export const getSelectedProductDeleteCompleted = createSelector(
  selectProductState,
  (state: ProductState) => state.isDeleteCompleted
);

/**
 * Gets the selected product stock.
 */
export const getSelectedProductStock = createSelector(selectProductState, (state: ProductState) => state.productStock);

/**
 * Determines if there is a running load process.
 */
export const getProductStockIsLoading = createSelector(
  selectProductState,
  (state: ProductState) => state.isProductStockLoading
);

/**
 * Determines if the last load process has been ended successfully.
 */
export const getProductStockIsLoaded = createSelector(
  selectProductState,
  (state: ProductState) => state.isProductStockLoaded
);

/**
 * Determines if there is a running product sales discount update process.
 */
export const getSelectedProductSalesDiscountUpdating = createSelector(
  selectProductState,
  (state: ProductState) => state.isProductSalesDiscountUpdating
);

/**
 * Determines if the last product sales discount update process has been ended successfully.
 */
export const getSelectedProductSalesDiscountUpdateCompleted = createSelector(
  selectProductState,
  (state: ProductState) => state.isProductSalesDiscountUpdateCompleted
);

/**
 * Determines if there is a running delete product sales discount process.
 */
export const getSelectedProductSalesDiscountDeleting = createSelector(
  selectProductState,
  (state: ProductState) => state.isProductSalesDiscountDeleting
);

/**
 * Determines if the last delete product sales discount process has been ended successfully.
 */
export const getSelectedProductSalesDiscountDeleteCompleted = createSelector(
  selectProductState,
  (state: ProductState) => state.isProductSalesDiscountDeleteCompleted
);

/**
 * Determines if there is a running product unit of measure rate update process.
 */
export const getSelectedProductUnitOfMeasureRateUpdating = createSelector(
  selectProductState,
  (state: ProductState) => state.isProductUnitOfMeasureRateUpdating
);

/**
 * Determines if the last product unit of measure rate update process has been ended successfully.
 */
export const getSelectedProductUnitOfMeasureRateUpdateCompleted = createSelector(
  selectProductState,
  (state: ProductState) => state.isProductUnitOfMeasureRateUpdateCompleted
);

/**
 * Determines if there is a running delete product unit of measure rate process.
 */
export const getSelectedProductUnitOfMeasureRateDeleting = createSelector(
  selectProductState,
  (state: ProductState) => state.isProductUnitOfMeasureRateDeleting
);

/**
 * Determines if the last delete product unit of measure rate process has been ended successfully.
 */
export const getSelectedProductUnitOfMeasureRateDeleteCompleted = createSelector(
  selectProductState,
  (state: ProductState) => state.isProductUnitOfMeasureRateDeleteCompleted
);
