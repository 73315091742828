<ng-container [formGroup]="govServicesGroup">
  <th scope="row">{{ index + 1 }}</th>
  <td class="min-width-9">
    {{ govServicesGroup?.value?.govService?.name | localize: govServicesGroup?.value?.govService?.nameEn | async }}
  </td>
  <td>
    <ng-select
      class="round"
      formControlName="advanceAccountId"
      labelForId="advanceAccountId"
      searchable="true"
      [placeholder]="
        'FINANCES.FINANCE_CONFIGURATIONS.FINANCE_CONFIGURATIONS_DATA.ADVANCE_ACCOUNT_PLACEHOLDER' | translate
      "
      [notFoundText]="'SHARED.SEARCH.SEARCH_NOT_FOUND' | translate"
      (open)="advanceAccountsListVisibility = true"
    >
      <ng-option *ngFor="let advanceAccount of advanceAccountsList" [value]="advanceAccount.id">
        {{ advanceAccount.description }}
      </ng-option>
    </ng-select>
  </td>
  <td>
    <ng-select
      class="round"
      formControlName="expenseAccountId"
      labelForId="expenseAccountId"
      searchable="true"
      [placeholder]="
        'FINANCES.FINANCE_CONFIGURATIONS.FINANCE_CONFIGURATIONS_DATA.EXPENSE_ACCOUNT_PLACEHOLDER' | translate
      "
      [notFoundText]="'SHARED.SEARCH.SEARCH_NOT_FOUND' | translate"
      (open)="expenseAccountsListVisibility = true"
    >
      <ng-option *ngFor="let expenseAccount of expenseAccountsList" [value]="expenseAccount.id">
        {{ expenseAccount.description }}
      </ng-option>
    </ng-select>
  </td>
</ng-container>

<!-- advance accounts search starts -->
<app-accounts-search
  [closeOnSelect]="true"
  [(visible)]="advanceAccountsListVisibility"
  (accounts)="selectAdvanceAccount($event)"
>
</app-accounts-search>
<!-- advance accounts search ends -->

<!-- expense accounts search starts -->
<app-accounts-search
  [closeOnSelect]="true"
  [(visible)]="expenseAccountsListVisibility"
  (accounts)="selectExpenseAccount($event)"
>
</app-accounts-search>
<!-- expense accounts search ends -->
