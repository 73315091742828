import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';

import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import * as fromStoresStore from 'stores/store';
import * as fromLookupsStore from 'lookups/store';
import { PageInfo, PaginationInfo } from 'shared';
import { Variant } from 'stores/models';
import { Claims } from 'security/models';
import { VariantCategory } from 'lookups/models';

@Component({
  selector: 'app-variants-list',
  templateUrl: './variants-list.component.html',
  styles: [],
})
export class VariantsListComponent implements OnInit {
  /**
   * Gets or sets the information about the current page.
   */
  pageInfo: PageInfo = {
    title: 'STORES.PRODUCT_VARIANTS.PRODUCTS_VARIANTS',
    icon: 'fa fa-snowflake-o',
  };

  /**
   * The system supported user claims.
   */
  Claims = Claims;

  /**
   * Gets or sets the currently selected variant for delete.
   */
  selectedVariant: Variant;

  /**
   * The list of variants.
   */
  variants$: Observable<Variant[]>;

  /**
   * The list of variant category.
   */
  variantCategories$: Observable<VariantCategory[]>;

  /**
   * The pagination info.
   */
  paginationInfo$: Observable<PaginationInfo>;

  /**
   * Indicates whether there is a search process is running or not.
   */
  isSearching$: Observable<boolean>;

  /**
   * Indicates whether there is a delete-variant process is running or not.
   */
  isDeleting$: Observable<boolean>;

  /**
   * The search form.
   */
  searchForm: FormGroup;

  /**
   * @param modalService The modal service.
   * @param storesStore$ The stores-store module.
   * @param lookupsStore$ The lookups-store module.
   */
  constructor(
    private modalService: NgbModal,
    private storesStore$: Store<fromStoresStore.StoresState>,
    private lookupsStore$: Store<fromLookupsStore.LookupsState>
  ) {}

  ngOnInit(): void {
    this.init();
  }

  /**
   * Initialize component data.
   */
  init() {
    this.initForm();

    /**
     * Load data.
     */
    this.isDeleting$ = this.storesStore$.pipe(select(fromStoresStore.getSelectedVariantDeleting));
    this.isSearching$ = this.storesStore$.pipe(select(fromStoresStore.getVariantsSearching));

    let isManualSearchTriggeredBeforeForVariants = false;
    this.variants$ = this.storesStore$.pipe(
      select(fromStoresStore.getVariants),
      tap((variants) => {
        if (!isManualSearchTriggeredBeforeForVariants && !variants.length) {
          isManualSearchTriggeredBeforeForVariants = true;
          this.search();
        }
      })
    );

    this.paginationInfo$ = this.storesStore$.pipe(select(fromStoresStore.getVariantsPaginationInfo));

    let isManualSearchTriggeredBeforeForCategories = false;
    this.variantCategories$ = this.lookupsStore$.pipe(
      select(fromLookupsStore.getVariantCategories),
      tap((categories) => {
        if (!isManualSearchTriggeredBeforeForCategories && !categories.length) {
          isManualSearchTriggeredBeforeForCategories = true;
          this.lookupsStore$.dispatch(new fromLookupsStore.GetAllVariantCategories());
        }
      })
    );
  }

  /**
   * Initialize form and add validators.
   */
  initForm() {
    this.searchForm = new FormGroup({
      name: new FormControl(''),
      categories: new FormControl([]),
      page: new FormControl(1),
    });
  }

  /**
   * Handles search parameters change.
   */
  search(event?: KeyboardEvent) {
    if (event && event.key === 'Enter') {
      event.preventDefault();
      return;
    }

    this.storesStore$.dispatch(
      new fromStoresStore.SearchVariants({
        name: this.searchForm.get('name').value,
        categories: this.searchForm.get('categories').value,
        page: 1,
      })
    );
  }

  /**
   * Handles pagination page-changed event.
   * @param page The current selected page number.
   */
  pageChanged(page: number) {
    /** Update pagination page. */
    this.searchForm.patchValue({ page });

    /** Get selected page items. */
    this.storesStore$.dispatch(
      new fromStoresStore.SearchVariants({
        name: this.searchForm.get('name').value,
        categories: this.searchForm.get('categories').value,
        page: this.searchForm.get('page').value,
      })
    );
  }

  /**
   * Enables trackBy feature for *ngFor utility to track items
   * depending on a comparer to reduce DOM change.
   * If the item is already exist then no HTML will change, only bounded values.
   * @param index The index of the item.
   * @param location The item to determine if it was changed or not.
   */
  trackItems(index: number, productVariant: Variant) {
    return productVariant ? productVariant.id : undefined;
  }

  /**
   * Deletes the variant with the given id.
   * @param id The id of the variant to be deleted.
   */
  delete(id: number) {
    this.storesStore$.dispatch(new fromStoresStore.DeleteVariant(id));
  }

  /**
   * Opens the modal of the given templateRef.
   * @param modalRef The modal templateRef to be opened.
   */
  openModal(modalRef) {
    this.modalService.open(modalRef);
  }
}
