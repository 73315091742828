import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import { Observable, Subscription } from 'rxjs';
import { skip, tap } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { PageInfo, NotificationService, NotificationMessage, TranslationService } from 'shared';
import * as fromFinancesStore from 'finances/store';

@Component({
  selector: 'app-create-cost-center',
  templateUrl: './create-cost-center.component.html',
  styles: [],
})
export class CreateCostCenterComponent implements OnInit, OnDestroy {
  /**
   * The confirm modal template reference.
   */
  @ViewChild('confirmModalRef') confirmModalRef: ElementRef<any>;

  /**
   * Gets or sets the information about the current page.
   */
  pageInfo: PageInfo = {
    title: 'FINANCES.COST_CENTERS.CREATE_COST_CENTER_PAGE_TITLE',
    icon: 'fa fa-plus',
  };

  /**
   * Indicates whether there is a create cost center process is running or not.
   */
  isCreating$: Observable<boolean>;

  /**
   * The create cost center form.
   */
  form: FormGroup;

  /**
   * The set of subscriptions on this components,
   * these subscriptions must be unsubscribed before this component got destroyed.
   */
  subscriptions = new Subscription();

  /**
   * @param modalService The modal service.
   * @param notificationService The notification service.
   * @param financesStore$ The finances store.
   * @param translationService The translation service.
   */
  constructor(
    private modalService: NgbModal,
    private notificationService: NotificationService,
    private financesStore$: Store<fromFinancesStore.FinancesState>,
    private translationService: TranslationService
  ) {}

  ngOnInit(): void {
    this.init();
  }

  /**
   * Destroy component data
   */
  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  /**
   * Initialize component data.
   */
  init() {
    this.initForm();

    /**
     * Reset form controls when new cost center is created.
     */
    this.subscriptions.add(
      this.financesStore$
        .pipe(
          select(fromFinancesStore.getSelectedCostCenterCreateCompleted),
          skip(1),
          tap((isCreated) => {
            if (isCreated) {
              this.initForm();
            }
          })
        )
        .subscribe()
    );

    /**
     * Load data.
     */
    this.isCreating$ = this.financesStore$.pipe(select(fromFinancesStore.getSelectedCostCenterCreating));
  }

  /**
   * Initialize form and add validators.
   */
  initForm() {
    this.form = new FormGroup({
      description: new FormControl('', [Validators.required, Validators.minLength(1), Validators.maxLength(50)]),
      descriptionEn: new FormControl('', [Validators.required, Validators.minLength(1), Validators.maxLength(50)]),
      code: new FormControl('', [Validators.required, Validators.minLength(1), Validators.maxLength(50)]),
    });
  }

  /**
   * Submits the form.
   */
  submit() {
    if (this.form.invalid) {
      const errorMessage = new NotificationMessage();

      if (this.form.get('code').invalid) {
        errorMessage.title = this.translationService.translate(
          'FINANCES.COST_CENTERS.COST_CENTER_DATA_VALIDATION.COST_CENTER_CODE_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate(
            'FINANCES.COST_CENTERS.COST_CENTER_DATA_VALIDATION.COST_CENTER_CODE_IS_REQUIRED'
          ),
          this.translationService.translate(
            'FINANCES.COST_CENTERS.COST_CENTER_DATA_VALIDATION.COST_CENTER_CODE_LENGTH_ERROR'
          ),
        ];
      } else if (this.form.get('description').invalid) {
        errorMessage.title = this.translationService.translate(
          'FINANCES.COST_CENTERS.COST_CENTER_DATA_VALIDATION.ARABIC_COST_CENTER_DESCRIPTION_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate(
            'FINANCES.COST_CENTERS.COST_CENTER_DATA_VALIDATION.COST_CENTER_DESCRIPTION_IS_REQUIRED'
          ),
          this.translationService.translate(
            'FINANCES.COST_CENTERS.COST_CENTER_DATA_VALIDATION.COST_CENTER_DESCRIPTION_LENGTH_ERROR'
          ),
        ];
      } else if (this.form.get('descriptionEn').invalid) {
        errorMessage.title = this.translationService.translate(
          'FINANCES.COST_CENTERS.COST_CENTER_DATA_VALIDATION.ENGLISH_COST_CENTER_DESCRIPTION_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate(
            'FINANCES.COST_CENTERS.COST_CENTER_DATA_VALIDATION.COST_CENTER_DESCRIPTION_IS_REQUIRED'
          ),
          this.translationService.translate(
            'FINANCES.COST_CENTERS.COST_CENTER_DATA_VALIDATION.COST_CENTER_DESCRIPTION_LENGTH_ERROR'
          ),
        ];
      }

      this.form.markAllAsTouched();
      return this.notificationService.warningWithTitle(errorMessage);
    } else {
      this.openModal(this.confirmModalRef);
    }
  }

  /**
   * Confirms the form submit.
   */
  confirm() {
    this.financesStore$.dispatch(
      new fromFinancesStore.CreateCostCenter({
        ...this.form.value,
      })
    );
  }

  /**
   * Opens the modal of the given templateRef.
   * @param modalRef The modal templateRef to be opened.
   */
  openModal(modalRef) {
    this.modalService.open(modalRef);
  }
}
