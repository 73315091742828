<!-- sale invoice payment history starts -->
<div class="row">
  <div class="col-md-12">
    <div class="card-header mb-3 pt-0">
      <h4 class="card-title">{{ 'SHARED.SEARCH.SEARCH_TOOLS' | translate }}</h4>
    </div>
    <div class="card-content">
      <div class="px-3">
        <div class="row">
          <div class="col-md-12">
            <form class="row">
              <div class="col-md-auto d-flex">
                <button
                  type="button"
                  class="btn btn-raised btn-primary round align-self-center mb-3"
                  (click)="search()"
                >
                  <i class="fa fa-refresh"></i>
                </button>
                <a
                  *ngIf="invoice.paid !== invoice.total && ([Claims.CREATE_CUSTOMER_PAYMENT] | authorize | async)"
                  routerLink="/sales/customers/payments/create"
                  [queryParams]="{ invoiceId: invoiceId }"
                  class="btn btn-raised btn-success round mx-1 align-self-center mb-3"
                >
                  <i class="fa fa-plus"></i>
                  <span class="mx-1">{{ 'SALES.INVOICES.ADD_PAYMENTS' | translate }}</span>
                </a>
              </div>
            </form>
          </div>

          <ng-container *ngIf="invoice.saleInvoicePayments.length">
            <div class="col-md-12 alert alert-light text-center" role="alert">
              ({{ invoice.saleInvoicePayments.length }})
              {{ 'SALES.INVOICES.PERVIOUS_PAYMENTS' | translate }}
            </div>
            <div class="col-md-12">
              <div class="table-responsive">
                <table class="table text-center">
                  <thead>
                    <tr>
                      <th scope="col"><i class="fa fa-list-ol"></i></th>
                      <th scope="col">{{ 'SALES.INVOICES.SALE_INVOICE_DATA.PAYMENT_VALUE' | translate }}</th>
                      <th scope="col">{{ 'SALES.INVOICES.SALE_INVOICE_DATA.PAYMENT_TYPE' | translate }}</th>
                      <th scope="col">{{ 'SALES.INVOICES.SALE_INVOICE_DATA.INVOICE_DATE' | translate }}</th>
                      <th scope="col">{{ 'SHARED.SEARCH.CREATED_AT' | translate }}</th>
                      <th scope="col">{{ 'SALES.INVOICES.SALE_INVOICE_DATA.USER' | translate }}</th>
                      <th scope="col">{{ 'SHARED.TOOLS' | translate }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let payment of invoice.saleInvoicePayments; let i = index; trackBy: trackItems">
                      <th scope="row">{{ i + 1 }}</th>
                      <td>
                        <span class="badge bg-success round">
                          {{ payment.value | toDecimal }}
                        </span>
                      </td>
                      <td>
                        {{
                          payment.customerLog.customerLogType.name
                            | localize: payment.customerLog.customerLogType.nameEn
                            | async
                        }}
                        <span *ngIf="payment.customerLog.bankId">
                          -
                          {{ payment.customerLog.bank.name | localize: payment.customerLog.bank.nameEn | async }}</span
                        >
                      </td>
                      <td>
                        <span [ngbTooltip]="payment.customerLog.transactionDate | date: 'time'">
                          {{ payment.customerLog.transactionDate | date }}
                        </span>
                      </td>
                      <td>
                        <span [ngbTooltip]="payment.customerLog.createdAt | date: 'time'">
                          {{ payment.customerLog.createdAt | date }}
                        </span>
                      </td>
                      <td>{{ payment.customerLog.user.name }}</td>
                      <td>
                        <a
                          class="info p-0"
                          [ngbTooltip]="'SALES.INVOICES.SALE_INVOICE_DATA.ATTACHMENTS_DISPLAY' | translate"
                          (click)="viewAttachments(payment)"
                        >
                          <i class="fa fa-paperclip font-medium-3 mr-2"></i>
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </ng-container>

          <div class="col-md-12 alert alert-light text-center" role="alert">
            {{ 'SALES.INVOICES.SALE_INVOICE_DATA.INVOICE_TOTAL_TITLE' | translate }}
          </div>
          <div class="col-md-12">
            <div class="table-responsive">
              <table class="table text-center">
                <thead>
                  <tr>
                    <th scope="col">{{ 'SALES.INVOICES.SALE_INVOICE_DATA.INVOICE_TOTAL' | translate }}</th>
                    <th scope="col">{{ 'SALES.INVOICES.SALE_INVOICE_DATA.PAID' | translate }}</th>
                    <th scope="col">{{ 'SALES.INVOICES.SALE_INVOICE_DATA.REMAINING' | translate }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">
                      <span class="badge bg-success round">
                        {{ invoice.net | toDecimal }}
                      </span>
                    </th>
                    <td>
                      <span class="badge bg-warning round">
                        {{ invoice.paid | toDecimal }}
                      </span>
                    </td>
                    <td>
                      <span class="badge bg-danger round">
                        {{ invoice.remaining | toDecimal }}
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- no items template starts -->
<ng-template #noItems>
  <div class="col-md-12 alert alert-warning mt-3 text-center" role="alert">
    {{ 'SALES.INVOICES.SEARCH_PAYMENT_NOT_FOUND' | translate }}
    <a
      class="btn btn-raised btn-success round"
      *ngIf="[Claims.CREATE_CUSTOMER_PAYMENT] | authorize | async"
      routerLink="/sales/Customers/payments/create"
      [queryParams]="{ invoiceId: invoiceId }"
    >
      <i class="fa fa-plus"></i>
      <span class="mx-1">{{ 'SALES.INVOICES.ADD_PAYMENTS' | translate }}</span>
    </a>
  </div>
</ng-template>
<!-- no items template ends -->

<!-- sale invoice payment history ends -->

<!-- view payment attachments modal starts -->
<ng-template #viewAttachmentsModalRef let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title text-primary" id="modal-basic-title">
      {{ 'SALES.INVOICES.ATTACHMENTS_PAYMENT' | translate }}
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="d()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-md-12">
        <app-files-viewer
          *ngIf="selectedPayment.customerLog.customerLogAttachments?.length; else noAttachments"
          [files]="getAttachmentsUrls(selectedPayment.customerLog.customerLogAttachments)"
        ></app-files-viewer>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="modal-actions">
      <button type="button" class="btn btn-outline-dark round" (click)="c()">
        <i class="fa fa-times"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.CANCEL' | translate }}</span>
      </button>
    </div>
  </div>
</ng-template>
<!-- view payment attachments modal ends -->

<!-- no attachments template starts -->
<ng-template #noAttachments>
  <div class="col-md-12 alert alert-warning mt-3 text-center" role="alert">
    {{ 'SALES.INVOICES.ATTACHMENTS_PAYMENT_NOT_FOUND' | translate }}
  </div>
</ng-template>
<!-- no attachments template ends -->
