import { findIndex } from 'lodash';
import { PendingPosInvoicesActions, PendingPosInvoicesActionType } from 'sales/store/actions';
import { PendingPosInvoicesState } from 'sales/store/states';

/**
 * The initial state from which the state starts.
 */
const initialState: PendingPosInvoicesState = {
  data: [],
  error: null,
  selectedPendingPointOfSaleInvoice: null,
  isPrepareCompleted: false,
  isPreparing: false,
  isUpdating: false,
  isUpdateCompleted: false,
};

/**
 * The reducer function that is called in each action dispatch against the state.
 * @param state The current state.
 * @param action The action that will affect the state.
 */
export function pendingPosInvoicesReducer(
  state: PendingPosInvoicesState = initialState,
  action: PendingPosInvoicesActions
): PendingPosInvoicesState {
  switch (action.type) {
    //#region PREPARE_POINT_OF_SALE_INVOICES

    case PendingPosInvoicesActionType.PREPARE_POINT_OF_SALE_INVOICES: {
      return {
        ...state,
        error: null,
        isPreparing: true,
        isPrepareCompleted: false,
      };
    }

    case PendingPosInvoicesActionType.PREPARE_POINT_OF_SALE_INVOICES_FAIL: {
      return {
        ...state,
        error: action.payload,
        isPreparing: false,
        isPrepareCompleted: false,
      };
    }

    case PendingPosInvoicesActionType.PREPARE_POINT_OF_SALE_INVOICES_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        error: null,
        isPreparing: false,
        isPrepareCompleted: true,
      };
    }

    //#endregion PREPARE_POINT_OF_SALE_INVOICES

    //#region UPDATE_POINT_OF_SALE_INVOICES

    case PendingPosInvoicesActionType.UPDATE_PREPARE_POINT_OF_SALE_INVOICE: {
      return {
        ...state,
        error: null,
        isUpdating: true,
        isUpdateCompleted: false,
      };
    }

    case PendingPosInvoicesActionType.UPDATE_PREPARE_POINT_OF_SALE_INVOICE_FAIL: {
      return {
        ...state,
        error: action.payload,
        isUpdating: false,
        isUpdateCompleted: false,
      };
    }

    case PendingPosInvoicesActionType.UPDATE_PREPARE_POINT_OF_SALE_INVOICE_SUCCESS: {
      let saleInvoices = [...state.data];
      const saleInvoiceIndex = findIndex(saleInvoices, (saleInvoice) => saleInvoice.id === action.payload.data.id);

      /* If organization was found, update it. */
      if (saleInvoiceIndex >= 0) {
        saleInvoices[saleInvoiceIndex] = action.payload.data;
      } else {
        /* else, insert it to the beginning of the array. */
        saleInvoices = [action.payload.data, ...saleInvoices];
      }

      return {
        ...state,
        data: saleInvoices,
        selectedPendingPointOfSaleInvoice: action.payload.data,
        error: null,
        isUpdating: false,
        isUpdateCompleted: true,
      };
    }

    //#endregion UPDATE_POINT_OF_SALE_INVOICES

    default:
      return state;
  }
}
