import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';

import { Observable, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

import * as fromSalesStore from 'sales/store';
import * as fromAuthStore from 'auth/store';
import { NotificationService, PaginationInfo, TranslationService } from 'shared';
import { Location } from 'stores/models';
import { SaleInvoice, Customer } from 'sales/models';

/**
 * A Unpaid sale invoices search form wrapped in modal.
 */
@Component({
  selector: 'app-unpaid-sale-invoices-search',
  templateUrl: './unpaid-sale-invoices-search.component.html',
  styles: [],
})
export class UnpaidSaleInvoicesSearchComponent implements OnInit, OnDestroy {
  /**
   * The modal template reference.
   */
  @ViewChild('modalRef') modalRef: ElementRef<any>;

  /**
   * Indicates whether the modal will automatically close after select the first unpaid invoice.
   *
   * Be default it will not be closed.
   */
  @Input() closeOnSelect = false;

  /**
   * Sets the exclusive customer id to only show his invoices.
   */
  @Input() exclusiveCustomerId: number;

  /**
   * Sets the visibility of this component modal.
   *
   * Handles visibility change in two-data-binding approach.
   */
  @Input() set visible(isShown: boolean) {
    if (isShown && this.modalRef) {
      this.openModal(this.modalRef);
    } else {
      this.closeModal();
    }

    this.visibleChange.emit(isShown);
  }

  /**
   * Outputs a value that indicates whether the modal is shown or not.
   *
   * Handles visibility change in two-data-binding approach.
   */
  @Output() visibleChange = new EventEmitter<boolean>();

  /** Outputs the selected unpaid invoices. */
  @Output() invoices = new EventEmitter<SaleInvoice[]>();

  /**
   * The list of unpaid invoices.
   */
  unpaidInvoices$: Observable<SaleInvoice[]>;

  /**
   * The list of unpaid invoices.
   */
  unpaidInvoicesList: SaleInvoice[];

  /**
   * Shows or hide the locations list.
   */
  locationsListVisibility = false;

  /**
   * The list of selected locations.
   */
  locations: Location[] = [];

  /**
   * The list of selected customers.
   */
  customers: Customer[] = [];

  /**
   * The pagination info.
   */
  paginationInfo$: Observable<PaginationInfo>;

  /**
   * Indicates whether there is a search process is running or not.
   */
  isSearching$: Observable<boolean>;

  /**
   * The search form.
   */
  searchForm: FormGroup;

  /**
   * The set of subscriptions on this components,
   * these subscriptions must be unsubscribed before this component got destroyed.
   */
  subscriptions = new Subscription();

  /**
   * Shows or hide the customers list.
   */
  customersListVisibility = false;

  /**
   * The ngb-modal reference to use it when modal needed to be closed.
   */
  modal: NgbModalRef;

  /**
   * @param modalService The modal service.
   * @param notificationService The notification service.
   * @param translationService: The translation service.
   * @param salesStore$ Sales-module store.
   * @param authStore$ the auth-store module.
   */
  constructor(
    private modalService: NgbModal,
    private notificationService: NotificationService,
    private translationService: TranslationService,
    private salesStore$: Store<fromSalesStore.SalesState>,
    private authStore$: Store<fromAuthStore.AuthState>
  ) {}

  ngOnInit(): void {
    this.init();
  }

  /**
   * Destroy component data
   */
  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  /**
   * Initialize component data.
   */
  init() {
    this.initForm();

    /**
     * Loads data.
     */
    this.isSearching$ = this.salesStore$.pipe(select(fromSalesStore.getUnpaidSaleInvoicesSearching));

    let isManualSearchTriggeredBeforeForUnpaidInvoices = false;
    this.unpaidInvoices$ = this.salesStore$.pipe(
      select(fromSalesStore.getUnpaidSaleInvoices),
      tap((unpaidInvoices) => {
        if (unpaidInvoices) {
          this.unpaidInvoicesList = unpaidInvoices;
        }
        if (!isManualSearchTriggeredBeforeForUnpaidInvoices && !unpaidInvoices.length) {
          isManualSearchTriggeredBeforeForUnpaidInvoices = true;
          this.search();
        }
      })
    );

    /* Subscribe to selected location to select it whenever it changed. */
    this.subscriptions.add(
      this.authStore$
        .pipe(
          select(fromAuthStore.getSessionUserDefaultLocation),
          tap((location) => {
            if (location && !this.searchForm.value.locations.includes(location.id)) {
              this.selectLocations([location]);
            }
          })
        )
        .subscribe()
    );

    this.paginationInfo$ = this.salesStore$.pipe(select(fromSalesStore.getUnpaidSaleInvoicesPaginationInfo));
  }

  /**
   * Initialize form and add validators.
   */
  initForm() {
    this.searchForm = new FormGroup({
      locations: new FormControl([]),
      customers: new FormControl([]),
      invoiceNum: new FormControl(''),
      page: new FormControl(1),
    });
  }

  /**
   * Handles search parameters change.
   */
  search(event?: KeyboardEvent) {
    if (event && event.key === 'Enter') {
      event.preventDefault();
      return;
    }

    this.applyFiltersAndSearch();
  }

  /**
   * Handles pagination page-changed event.
   * @param page The current selected page number.
   */
  pageChanged(page: number) {
    /** Update pagination page. */
    this.searchForm.patchValue({ page });

    this.applyFiltersAndSearch(page);
  }

  /**
   * Applies the search filters and dispatches a search action.
   * @param page The current page number, default to `1`.
   */
  applyFiltersAndSearch(page: number = 1) {
    this.salesStore$.dispatch(
      new fromSalesStore.SearchUnpaidSaleInvoices({
        locations: this.searchForm.get('locations').value,
        customers: this.exclusiveCustomerId ? [this.exclusiveCustomerId] : this.searchForm.get('customers').value,
        invoiceNum: this.searchForm.get('invoiceNum').value,
        page,
      })
    );
  }

  /**
   * Enables trackBy feature for *ngFor utility to track items
   * depending on a comparer to reduce DOM change.
   * If the item is already exist then no HTML will change, only bounded values.
   * @param index The index of the item.
   * @param unpaidInvoice The item to determine if it was changed or not.
   */
  trackItems(index: number, unpaidInvoice: SaleInvoice) {
    return unpaidInvoice ? unpaidInvoice.id : undefined;
  }

  /**
   * Handles unpaid invoice selected event.
   * @param unpaidInvoices The list of unpaid invoices to notify selected.
   */
  selectUnpaidInvoice(unpaidInvoices: SaleInvoice[]) {
    this.notificationService.successInstant(
      this.translationService.translate('SALES.INVOICES.NOTIFICATION_MESSAGE.INVOICE_SELECTED')
    );
    this.invoices.emit(unpaidInvoices);
    if (this.closeOnSelect) {
      this.closeModal();
    }
  }

  /**
   * Opens the modal of the given templateRef.
   * @param modalRef The modal templateRef to be opened.
   */
  openModal(modalRef) {
    /**
     * Open the modal & listen for user press ESC key.
     */
    this.modal = this.modalService.open(modalRef, { size: 'xl' });
    this.modal.result.then(null, () => this.closeModal());
  }

  /**
   * Closes the currently opened modal.
   */
  closeModal() {
    this.modal?.dismiss();
    this.invoices.emit([]);
    this.visibleChange.emit(false);
  }

  /**
   * Adds the newly selected locations the locations search list.
   * @param locations The list of newly selected locations to be added.
   */
  selectLocations(locations: Location[]) {
    const selectedIds: number[] = this.searchForm.get('locations').value;
    this.locations = [...this.locations.filter((location) => selectedIds.includes(location.id)), ...locations];
    this.searchForm.patchValue({ locations: this.locations.map((location) => location.id) });
  }

  /**
   * Adds the newly selected customers the customers search list.
   * @param customers The list of newly selected customers to be added.
   */
  selectCustomers(customers: Customer[]) {
    const selectedIds: number[] = this.searchForm.get('customers').value;
    this.customers = [...this.customers.filter((customer) => selectedIds.includes(customer.id)), ...customers];
    this.searchForm.patchValue({ customers: this.customers.map((customer) => customer.id) });
  }
}
