import { Injectable } from '@angular/core';

import { of } from 'rxjs';
import { switchMap, map, catchError } from 'rxjs/operators';
import { Effect, ofType, Actions } from '@ngrx/effects';

import { LookupsService } from 'lookups/services';
import { CountryActionType, GetAllCountriesFail, GetAllCountriesSuccess } from 'lookups/store/actions';

@Injectable()
export class CountryEffects {
  constructor(private actions$: Actions, private lookupsService: LookupsService) {}

  /* ========================= GET_ALL_COUNTRIES =================================== */
  @Effect()
  getAllCountries$ = this.actions$.pipe(
    ofType(CountryActionType.GET_ALL_COUNTRIES),
    switchMap(() =>
      this.lookupsService.getAllCountries().pipe(
        map((response) => new GetAllCountriesSuccess(response)),
        catchError((error) => of(new GetAllCountriesFail(error)))
      )
    )
  );
}
