import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import { Store, select } from '@ngrx/store';
import { Subscription, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { PageInfo, NotificationService, NotificationMessage, TranslationService } from 'shared';
import * as fromSalesStore from 'sales/store';
import * as fromAuthStore from 'auth/store';
import { Location } from 'stores/models';

@Component({
  selector: 'app-create-pos-device',
  templateUrl: './create-pos-device.component.html',
  styles: [],
})
export class CreatePosDeviceComponent implements OnInit, OnDestroy {
  /**
   * The confirm modal template reference.
   */
  @ViewChild('confirmModalRef') confirmModalRef: ElementRef<any>;

  /**
   * Gets or sets the information about the current page.
   */
  pageInfo: PageInfo = {
    title: 'SALES.POS_DEVICES.CREATE_POS_DEVICE_TITLE',
    icon: 'fa fa-plus',
  };

  /**
   * Indicates whether there is a create pos device process is running or not.
   */
  isCreating$: Observable<boolean>;

  /**
   * The create pos device form.
   */
  form: FormGroup;

  /**
   * The set of subscriptions on this components,
   * these subscriptions must be unsubscribed before this component got destroyed.
   */
  subscriptions = new Subscription();

  /**
   * Shows or hide the locations list.
   */
  locationsListVisibility = false;

  /**
   * The list of selected locations.
   */
  locations: Location[] = [];

  /**
   * @param modalService The modal service.
   * @param notificationService The notification service.
   * @param salesStore$  The stores store.
   * @param translationService The translation service.
   * @param authStore$ the auth-store module.
   */
  constructor(
    private modalService: NgbModal,
    private notificationService: NotificationService,
    private salesStore$: Store<fromSalesStore.SalesState>,
    private translationService: TranslationService,
    private authStore$: Store<fromAuthStore.AuthState>
  ) {}

  ngOnInit(): void {
    this.init();
  }

  /**
   * Destroy component data
   */
  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  /**
   * Initialize component data.
   */
  init() {
    this.initForm();

    /* Subscribe to selected location to select it whenever it changed. */
    this.subscriptions.add(
      this.authStore$
        .pipe(
          select(fromAuthStore.getSessionUserDefaultLocation),
          tap((location) => {
            if (location) {
              this.selectLocation([location]);
            }
          })
        )
        .subscribe()
    );

    /**
     * Load data.
     */
    this.isCreating$ = this.salesStore$.pipe(select(fromSalesStore.getSelectedPosDeviceCreating));
  }

  /**
   * Initialize form and add validators.
   */
  initForm() {
    this.form = new FormGroup({
      name: new FormControl('', [Validators.required, Validators.minLength(1), Validators.maxLength(50)]),
      nameEn: new FormControl('', [Validators.required, Validators.minLength(1), Validators.maxLength(50)]),
      locationId: new FormControl(null, [Validators.required]),
    });
  }

  /**
   * Submits the form.
   */
  submit() {
    if (this.form.invalid) {
      const errorMessage = new NotificationMessage();

      if (this.form.get('name').invalid) {
        errorMessage.title = this.translationService.translate(
          'SALES.POS_DEVICES.POS_DEVICE_DATA_VALIDATION.ARABIC_POS_DEVICE_NAME_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate('SALES.POS_DEVICES.POS_DEVICE_DATA_VALIDATION.POS_DEVICE_NAME_IS_REQUIRED'),
          this.translationService.translate(
            'SALES.POS_DEVICES.POS_DEVICE_DATA_VALIDATION.POS_DEVICE_NAME_LENGTH_ERROR'
          ),
        ];
      } else if (this.form.get('nameEn').invalid) {
        errorMessage.title = this.translationService.translate(
          'SALES.POS_DEVICES.POS_DEVICE_DATA_VALIDATION.ENGLISH_POS_DEVICE_NAME_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate('SALES.POS_DEVICES.POS_DEVICE_DATA_VALIDATION.POS_DEVICE_NAME_IS_REQUIRED'),
          this.translationService.translate(
            'SALES.POS_DEVICES.POS_DEVICE_DATA_VALIDATION.POS_DEVICE_NAME_LENGTH_ERROR'
          ),
        ];
      } else if (this.form.get('locationId').invalid) {
        errorMessage.title = this.translationService.translate(
          'SALES.POS_DEVICES.POS_DEVICE_DATA_VALIDATION.LOCATION_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate('SALES.POS_DEVICES.POS_DEVICE_DATA_VALIDATION.LOCATION_IS_REQUIRED'),
        ];
      }
      this.form.markAllAsTouched();
      return this.notificationService.warningWithTitle(errorMessage);
    } else {
      this.openModal(this.confirmModalRef);
    }
  }

  /**
   * Confirms the form submit.
   */
  confirm() {
    const { locationId, name, nameEn } = this.form.value;

    this.salesStore$.dispatch(
      new fromSalesStore.CreatePosDevice({
        name,
        nameEn,
        locationId,
      })
    );
  }

  /**
   * Opens the modal of the given templateRef.
   * @param modalRef The modal templateRef to be opened.
   */
  openModal(modalRef) {
    this.modalService.open(modalRef);
  }

  /**
   * Selects the newly selected pos device from the pos device search list.
   * @param location The list of newly selected pos device to select the only one in the list.
   */
  selectLocation([location]: Location[]) {
    if (location) {
      this.locations = [location];
      this.form.patchValue({ locationId: location.id });
    }
  }
}
