import { GovServiceRequestsListComponent } from 'finances/components/hr/gov-service-requests/gov-service-requests-list/gov-service-requests-list.component';
import { GovServiceRequestDetailsComponent } from 'finances/components/hr/gov-service-requests/gov-service-request-details/gov-service-request-details.component';
import { GovServiceRequestEmployeesFormComponent } from 'finances/components/hr/gov-service-requests/gov-service-request-employees-form/gov-service-request-employees-form.component';
import { CreatePayGovServiceRequestComponent } from 'finances/components/hr/gov-service-requests/create-pay-gov-service-request/create-pay-gov-service-request.component';

export * from 'finances/components/hr/gov-service-requests/gov-service-requests-list/gov-service-requests-list.component';
export * from 'finances/components/hr/gov-service-requests/gov-service-request-details/gov-service-request-details.component';
export * from 'finances/components/hr/gov-service-requests/gov-service-request-employees-form/gov-service-request-employees-form.component';
export * from 'finances/components/hr/gov-service-requests/create-pay-gov-service-request/create-pay-gov-service-request.component';

/**
 * The set of components registered on this sub-module.
 */
export const COMPONENTS: any[] = [
  GovServiceRequestsListComponent,
  GovServiceRequestDetailsComponent,
  GovServiceRequestEmployeesFormComponent,
  CreatePayGovServiceRequestComponent,
];
