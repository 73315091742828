import { createSelector } from '@ngrx/store';

import { selectLookupsState } from 'lookups/store/selectors/feature.selectors';
import { LookupsState, MaintenanceStageState } from 'lookups/store/states';

/**
 * Gets the maintenance stage state.
 */
const selectMaintenanceStages = createSelector(selectLookupsState, (state: LookupsState) => state.maintenanceStages);

/**
 * Gets the list of loaded maintenance stages.
 */
export const getMaintenanceStages = createSelector(
  selectMaintenanceStages,
  (state: MaintenanceStageState) => state.data
);

/**
 * Gets the error that occurred in the state.
 */
export const getMaintenanceStagesError = createSelector(
  selectMaintenanceStages,
  (state: MaintenanceStageState) => state.error
);

/**
 * Determines if there is a running load process.
 */
export const getMaintenanceStagesIsLoading = createSelector(
  selectMaintenanceStages,
  (state: MaintenanceStageState) => state.isLoading
);

/**
 * Determines if the last load process has been ended successfully.
 */
export const getMaintenanceStagesIsLoaded = createSelector(
  selectMaintenanceStages,
  (state: MaintenanceStageState) => state.isLoaded
);
