import { LeadsService } from 'crm/services/leads.service';
import { QuotationsService } from 'crm/services/quotations.service';

export * from 'crm/services/leads.service';
export * from 'crm/services/quotations.service';

/**
 * The set of services registered on this module.
 */
export const SERVICES: any[] = [LeadsService, QuotationsService];
