import { CreateSectorComponent } from 'stores/components/sectors/create-sector/create-sector.component';
import { UpdateSectorComponent } from 'stores/components/sectors/update-sector/update-sector.component';
import { SectorsListComponent } from 'stores/components/sectors/sectors-list/sectors-list.component';
import { SectorsSearchComponent } from 'stores/components/sectors/sectors-search/sectors-search.component';

export * from 'stores/components/sectors/create-sector/create-sector.component';
export * from 'stores/components/sectors/update-sector/update-sector.component';
export * from 'stores/components/sectors/sectors-list/sectors-list.component';
export * from 'stores/components/sectors/sectors-search/sectors-search.component';

/**
 * The set of components registered on this sub-module.
 */
export const COMPONENTS: any[] = [
  CreateSectorComponent,
  UpdateSectorComponent,
  SectorsListComponent,
  SectorsSearchComponent,
];
