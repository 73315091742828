import { ProductState } from 'stores/store/states/products.state';
import { LocationState } from 'stores/store/states/locations.state';
import { ProductClassState } from 'stores/store/states/product-classes.state';
import { UnitOfMeasureClassesState } from 'stores/store/states/unit-of-measure-classes.state';
import { UnitsOfMeasureState } from 'stores/store/states/units-of-measure.state';
import { VariantState } from 'stores/store/states/variants.state';
import { RegionsState } from 'stores/store/states/regions.state';
import { SectorsState } from 'stores/store/states/sectors.state';

/**
 * Represents the Stores-Module state.
 */
export class StoresState {
  /**
   * Represents the products state.
   */
  public products: ProductState;

  /**
   * Represents the locations state.
   */
  public locations: LocationState;

  /**
   * Represents the product classes state.
   */
  public productClasses: ProductClassState;

  /**
   * Represents the unit of measure class state.
   */
  public unitOfMeasureClasses: UnitOfMeasureClassesState;

  /**
   * Represents the unit of measure  state.
   */
  public unitsOfMeasure: UnitsOfMeasureState;

  /**
   * Represents the unit of measure rate state.
   */
  public variants: VariantState;

  /**
   * Represents the region state.
   */
  public regions: RegionsState;

  /**
   * Represents the sector state.
   */
  public sectors: SectorsState;
}
