import { createSelector } from '@ngrx/store';

import { ProjectResourcesState, ProjectsManagementState } from 'projects-management/store/states';
import { selectProjectsManagementState } from 'projects-management/store/selectors/feature.selectors';

/**
 * Gets the project resources state.
 */
const selectProjectResourceState = createSelector(
  selectProjectsManagementState,
  (state: ProjectsManagementState) => state.projectResources
);

/**
 * Gets the list of loaded project resources.
 */
export const getProjectResourceLines = createSelector(
  selectProjectResourceState,
  (state: ProjectResourcesState) => state.data
);

/**
 * Gets the list of loaded project resource purchase return lines .
 */
export const getProjectResourcePurchaseReturnLines = createSelector(
  selectProjectResourceState,
  (state: ProjectResourcesState) => state.purchaseReturnData
);

/**
 * Gets the list of loaded project resource purchase invoice lines .
 */
export const getProjectResourcePurchaseInvoiceLines = createSelector(
  selectProjectResourceState,
  (state: ProjectResourcesState) => state.purchaseInvoiceData
);

/**
 * Gets the list of loaded project resource asset depreciation lines .
 */
export const getProjectResourceAssetDepreciationLines = createSelector(
  selectProjectResourceState,
  (state: ProjectResourcesState) => state.assetDepreciationData
);

/**
 * Gets the list of loaded project resource outgoing stock lines .
 */
export const getProjectResourceOutgoingStockLines = createSelector(
  selectProjectResourceState,
  (state: ProjectResourcesState) => state.outgoingStockData
);

/**
 * Gets the list of loaded project resource payroll lines .
 */
export const getProjectResourcePayrollLines = createSelector(
  selectProjectResourceState,
  (state: ProjectResourcesState) => state.payrollData
);

/**
 * Gets the project resources pagination info.
 */
export const getProjectResourceLinesPaginationInfo = createSelector(
  selectProjectResourceState,
  (state: ProjectResourcesState) => state.paginationInfo
);

/**
 * Gets the error that occurred in the state.
 */
export const getProjectResourceLinesError = createSelector(
  selectProjectResourceState,
  (state: ProjectResourcesState) => state.error
);

/**
 * Determines if there is a running find project resource lines process.
 */
export const getProjectResourceLinesFinding = createSelector(
  selectProjectResourceState,
  (state: ProjectResourcesState) => state.isFinding
);

/**
 * Determines if the last find project resource lines process has been ended successfully.
 */
export const getProjectResourceLinesFindCompleted = createSelector(
  selectProjectResourceState,
  (state: ProjectResourcesState) => state.isFindCompleted
);

/**
 * Determines if there is a running find project resource purchase return lines process.
 */
export const getProjectResourcePurchaseReturnLinesFinding = createSelector(
  selectProjectResourceState,
  (state: ProjectResourcesState) => state.isFindingPurchaseReturn
);

/**
 * Determines if the last find project resource purchase return lines process has been ended successfully.
 */
export const getProjectResourcePurchaseReturnLinesFindCompleted = createSelector(
  selectProjectResourceState,
  (state: ProjectResourcesState) => state.isFindPurchaseReturnCompleted
);

/**
 * Determines if there is a running find project resource purchase invoice lines process.
 */
export const getProjectResourcePurchaseInvoiceLinesFinding = createSelector(
  selectProjectResourceState,
  (state: ProjectResourcesState) => state.isFindingPurchaseInvoice
);

/**
 * Determines if the last find project resource purchase invoice lines process has been ended successfully.
 */
export const getProjectResourcePurchaseInvoiceLinesFindCompleted = createSelector(
  selectProjectResourceState,
  (state: ProjectResourcesState) => state.isFindPurchaseInvoiceCompleted
);

/**
 * Determines if there is a running find project resource asset depreciation lines process.
 */
export const getProjectResourceAssetDepreciationLinesFinding = createSelector(
  selectProjectResourceState,
  (state: ProjectResourcesState) => state.isFindingAssetDepreciation
);

/**
 * Determines if the last find project resource asset depreciation lines process has been ended successfully.
 */
export const getProjectResourceAssetDepreciationLinesFindCompleted = createSelector(
  selectProjectResourceState,
  (state: ProjectResourcesState) => state.isFindAssetDepreciationCompleted
);

/**
 * Determines if there is a running find project resource outgoing stock lines process.
 */
export const getProjectResourceOutgoingStockLinesFinding = createSelector(
  selectProjectResourceState,
  (state: ProjectResourcesState) => state.isFindingOutgoingStock
);

/**
 * Determines if the last find project resource outgoing stock lines process has been ended successfully.
 */
export const getProjectResourceOutgoingStockLinesFindCompleted = createSelector(
  selectProjectResourceState,
  (state: ProjectResourcesState) => state.isFindOutgoingStockCompleted
);

/**
 * Determines if there is a running find project resource payroll lines process.
 */
export const getProjectResourcePayrollLinesFinding = createSelector(
  selectProjectResourceState,
  (state: ProjectResourcesState) => state.isFindingPayroll
);

/**
 * Determines if the last find project resource payroll lines process has been ended successfully.
 */
export const getProjectResourcePayrollLinesFindCompleted = createSelector(
  selectProjectResourceState,
  (state: ProjectResourcesState) => state.isFindPayrollCompleted
);
