<div [formGroup]="govServicesForm.parent" class="row">
  <div class="col-md-12">
    <div class="table-responsive">
      <table class="table text-center" [ngStyle]="{ 'padding-bottom': dropDownIsOpened ? '8rem' : 'unset' }">
        <thead>
          <tr>
            <th scope="col">
              <i class="fa fa-list-ol"></i>
            </th>
            <th scope="col">
              {{ 'FINANCES.FINANCE_CONFIGURATIONS.FINANCE_CONFIGURATIONS_DATA.THE_SERVICE' | translate }}
            </th>
            <th scope="col">
              {{ 'FINANCES.FINANCE_CONFIGURATIONS.FINANCE_CONFIGURATIONS_DATA.ADVANCE_ACCOUNT' | translate }}
            </th>
            <th scope="col">
              {{ 'FINANCES.FINANCE_CONFIGURATIONS.FINANCE_CONFIGURATIONS_DATA.EXPENSE_ACCOUNT' | translate }}
            </th>
          </tr>
        </thead>
        <tbody formArrayName="govServices">
          <tr
            app-gov-services-form-item
            *ngFor="let service of govServicesForm.controls; let i = index"
            [govServicesGroup]="service"
            [index]="i"
            (dropDownIsOpened)="dropDownIsOpened = $event"
          ></tr>
        </tbody>
        <tfoot class="tfoot-light">
          <tr>
            <td [attr.colspan]="9">
              <i class="fa fa-bookmark c-primary"></i>
              <span>
                {{ govServicesForm.controls.length | toNumber }}
                {{ 'FINANCES.FINANCE_CONFIGURATIONS.FINANCE_CONFIGURATIONS_DATA.SERVICE' | translate }}
              </span>
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  </div>
</div>
