import { CustomerCategoryActionType, CustomerCategoryActions } from 'lookups/store/actions';
import { CustomerCategoryState } from 'lookups/store/states';

/**
 * The initial state from which the state starts.
 */
const initialState: CustomerCategoryState = {
  data: [],
  error: null,
  isLoaded: false,
  isLoading: false,
};

/**
 * The reducer function that is called in each action dispatch against the state.
 * @param state The current state.
 * @param action The action that will affect the state.
 */
export function customerCategoryReducer(
  state: CustomerCategoryState = initialState,
  action: CustomerCategoryActions
): CustomerCategoryState {
  switch (action.type) {
    case CustomerCategoryActionType.GET_ALL_CUSTOMER_CATEGORY: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case CustomerCategoryActionType.GET_ALL_CUSTOMER_CATEGORY_FAIL: {
      return {
        ...state,
        error: action.payload,
        isLoading: false,
        isLoaded: false,
      };
    }

    case CustomerCategoryActionType.GET_ALL_CUSTOMER_CATEGORY_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        isLoading: false,
        isLoaded: true,
      };
    }

    default:
      return state;
  }
}
