<!-- update user bank accounts form starts -->
<div class="row">
  <div class="col-md-12">
    <div class="card">
      <div class="card-content">
        <div class="px-3">
          <form [formGroup]="form" *ngIf="bankAccounts$ | async; let bankAccount" (ngSubmit)="submit()">
            <div class="row">
              <!-- bank accounts details starts -->
              <div class="form-group col-md-12" *ngIf="currentLoggedInUser.organizationId">
                <div class="card-header mb-3 pt-1">
                  <h4 class="card-title">
                    {{ 'SECURITY.USERS.USER_DATA.BANK_ACCOUNT_FORM_TITLE' | translate }}
                  </h4>
                </div>
                <div class="card-content">
                  <div class="px-3">
                    <app-bank-accounts-form
                      [formArray]="bankAccountsForm"
                      [newBankAccounts]="newBankAccounts"
                    ></app-bank-accounts-form>
                  </div>
                </div>
              </div>
              <!-- bank accounts details ends -->
              <div class="form-group col-12 d-flex justify-content-between">
                <button type="submit" class="btn btn-raised btn-primary round">
                  <i class="fa fa-check"></i>
                  <span class="mx-1">{{ 'SHARED.CONFIRM.SAVE' | translate }}</span>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- update user bank accounts form ends -->

<!-- confirm modal starts -->
<ng-template #bankAccountsConfirmModalRef let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
      {{ 'SECURITY.USERS.CONFIRM_USER_BANK_ACCOUNTS_DATA_TITLE' | translate }}
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="d()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p class="text-success">{{ 'SECURITY.USERS.CONFIRM_USER_BANK_ACCOUNTS_DATA_MESSAGE' | translate }}</p>
  </div>
  <div class="modal-footer">
    <div class="modal-actions">
      <button type="button" class="btn btn-raised btn-success round" (click)="c(); confirm()" ngbAutofocus>
        <i class="fa fa-check"></i>
        <span class="mx-1"> {{ 'SHARED.CONFIRM.SAVE' | translate }} </span>
      </button>
      <button type="button" class="btn btn-outline-dark round" (click)="c()">
        <i class="fa fa-times"></i>
        <span class="mx-1"> {{ 'SHARED.CONFIRM.CANCEL' | translate }} </span>
      </button>
    </div>
  </div>
</ng-template>
<!-- confirm modal ends -->
