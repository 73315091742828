import { findIndex, remove } from 'lodash';

import { OrganizationsActionType, OrganizationsActions } from 'organizations/store/actions';
import { OrganizationState } from 'organizations/store/states';

/**
 * The initial state from which the state starts.
 */
const initialState: OrganizationState = {
  organizations: [],
  paginationInfo: {
    total: 0,
    page: 1,
    pageSize: 0,
    count: 0,
  },
  error: null,
  isSearching: false,
  isSearchCompleted: false,
  selectedOrganization: null,
  isFinding: false,
  isFindCompleted: false,
  isCreating: false,
  isCreateCompleted: false,
  isUpdating: false,
  isUpdateCompleted: false,
  isBlocking: false,
  isBlockCompleted: false,
  isActivating: false,
  isActivateCompleted: false,
  isDeleting: false,
  isDeleteCompleted: false,
};

/**
 * The reducer function that is called in each action dispatch against the state.
 * @param state The current state.
 * @param action The action that will affect the state.
 */
export function organizationsReducer(
  state: OrganizationState = initialState,
  action: OrganizationsActions
): OrganizationState {
  switch (action.type) {
    //#region SEARCH_ORGANIZATIONS

    case OrganizationsActionType.SEARCH_ORGANIZATIONS: {
      return {
        ...state,
        isSearching: true,
        isSearchCompleted: false,
        error: null,
      };
    }

    case OrganizationsActionType.SEARCH_ORGANIZATIONS_FAIL: {
      return {
        ...state,
        error: action.payload,
        isSearching: false,
        isSearchCompleted: false,
      };
    }

    case OrganizationsActionType.SEARCH_ORGANIZATIONS_SUCCESS: {
      return {
        ...state,
        organizations: action.payload.data,
        paginationInfo: {
          ...action.payload.meta,
        },
        error: null,
        isSearching: false,
        isSearchCompleted: true,
      };
    }

    //#endregion SEARCH_ORGANIZATIONS

    //#region FIND_ORGANIZATION

    case OrganizationsActionType.FIND_ORGANIZATION: {
      return {
        ...state,
        selectedOrganization: null,
        error: null,
        isFinding: true,
        isFindCompleted: false,
      };
    }

    case OrganizationsActionType.FIND_ORGANIZATION_FAIL: {
      return {
        ...state,
        error: action.payload,
        isFinding: false,
        isFindCompleted: false,
      };
    }

    case OrganizationsActionType.FIND_ORGANIZATION_SUCCESS: {
      let organizations = [...state.organizations];
      const organizationIndex = findIndex(organizations, (organization) => organization.id === action.payload.data.id);

      /* If organization was found, update it. */
      if (organizationIndex >= 0) {
        organizations[organizationIndex] = action.payload.data;
      } else {
        /* else, insert it to the beginning of the array. */
        organizations = [action.payload.data, ...organizations];
      }

      return {
        ...state,
        organizations,
        selectedOrganization: action.payload.data,
        error: null,
        isFinding: false,
        isFindCompleted: true,
      };
    }

    //#endregion FIND_ORGANIZATION

    //#region CREATE_ORGANIZATION

    case OrganizationsActionType.CREATE_ORGANIZATION: {
      return {
        ...state,
        isCreating: true,
        isCreateCompleted: false,
        error: null,
      };
    }

    case OrganizationsActionType.CREATE_ORGANIZATION_FAIL: {
      return {
        ...state,
        error: action.payload,
        isCreating: false,
        isCreateCompleted: false,
      };
    }

    case OrganizationsActionType.CREATE_ORGANIZATION_SUCCESS: {
      return {
        ...state,
        organizations: [action.payload.data, ...state.organizations],
        selectedOrganization: action.payload.data,
        error: null,
        isCreating: false,
        isCreateCompleted: true,
      };
    }

    //#endregion CREATE_ORGANIZATION

    //#region UPDATE_ORGANIZATION

    case OrganizationsActionType.UPDATE_ORGANIZATION: {
      return {
        ...state,
        isUpdating: true,
        isUpdateCompleted: false,
        error: null,
      };
    }

    case OrganizationsActionType.UPDATE_ORGANIZATION_FAIL: {
      return {
        ...state,
        error: action.payload,
        isUpdating: false,
        isUpdateCompleted: false,
      };
    }

    case OrganizationsActionType.UPDATE_ORGANIZATION_SUCCESS: {
      let organizations = [...state.organizations];
      const organizationIndex = findIndex(organizations, (organization) => organization.id === action.payload.data.id);

      /* If organization was found, update it. */
      if (organizationIndex >= 0) {
        organizations[organizationIndex] = action.payload.data;
      } else {
        /* else, insert it to the beginning of the array. */
        organizations = [action.payload.data, ...organizations];
      }

      return {
        ...state,
        organizations,
        selectedOrganization: action.payload.data,
        error: null,
        isUpdating: false,
        isUpdateCompleted: true,
      };
    }

    //#endregion UPDATE_ORGANIZATION

    //#region BLOCK_ORGANIZATION

    case OrganizationsActionType.BLOCK_ORGANIZATION: {
      return {
        ...state,
        isBlocking: true,
        isBlockCompleted: false,
        error: null,
      };
    }

    case OrganizationsActionType.BLOCK_ORGANIZATION_FAIL: {
      return {
        ...state,
        error: action.payload,
        isBlocking: false,
        isBlockCompleted: false,
      };
    }

    case OrganizationsActionType.BLOCK_ORGANIZATION_SUCCESS: {
      let organizations = [...state.organizations];
      const organizationIndex = findIndex(organizations, (organization) => organization.id === action.payload.data.id);

      /* If organization was found, update it. */
      if (organizationIndex >= 0) {
        organizations[organizationIndex] = action.payload.data;
      } else {
        /* else, insert it to the beginning of the array. */
        organizations = [action.payload.data, ...organizations];
      }

      return {
        ...state,
        organizations,
        selectedOrganization: action.payload.data,
        error: null,
        isBlocking: false,
        isBlockCompleted: true,
      };
    }

    //#endregion BLOCK_ORGANIZATION

    //#region ACTIVATE_ORGANIZATION

    case OrganizationsActionType.ACTIVATE_ORGANIZATION: {
      return {
        ...state,
        isActivating: true,
        isActivateCompleted: false,
        error: null,
      };
    }

    case OrganizationsActionType.ACTIVATE_ORGANIZATION_FAIL: {
      return {
        ...state,
        error: action.payload,
        isActivating: false,
        isActivateCompleted: false,
      };
    }

    case OrganizationsActionType.ACTIVATE_ORGANIZATION_SUCCESS: {
      let organizations = [...state.organizations];
      const organizationIndex = findIndex(organizations, (organization) => organization.id === action.payload.data.id);

      /* If organization was found, update it. */
      if (organizationIndex >= 0) {
        organizations[organizationIndex] = action.payload.data;
      } else {
        /* else, insert it to the beginning of the array. */
        organizations = [action.payload.data, ...organizations];
      }

      return {
        ...state,
        organizations,
        selectedOrganization: action.payload.data,
        error: null,
        isActivating: false,
        isActivateCompleted: true,
      };
    }

    //#endregion ACTIVATE_ORGANIZATION

    //#region DELETE_ORGANIZATION

    case OrganizationsActionType.DELETE_ORGANIZATION: {
      return {
        ...state,
        isDeleting: true,
        isDeleteCompleted: false,
        error: null,
      };
    }

    case OrganizationsActionType.DELETE_ORGANIZATION_FAIL: {
      return {
        ...state,
        error: action.payload,
        isDeleting: false,
        isDeleteCompleted: false,
      };
    }

    case OrganizationsActionType.DELETE_ORGANIZATION_SUCCESS: {
      const organizations = [...state.organizations];

      /* Remove the deleted organization from the array. */
      remove(organizations, (organization) => organization.id === action.payload.data.id);

      return {
        ...state,
        organizations,
        selectedOrganization: action.payload.data,
        error: null,
        isDeleting: false,
        isDeleteCompleted: true,
      };
    }

    //#endregion DELETE_ORGANIZATION

    default:
      return state;
  }
}
