import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-customer-assets-form',
  templateUrl: './customer-assets-form.component.html',
  styles: [],
})
export class CustomerAssetsFormComponent {
  /**
   * The confirm modal template reference.
   */
  @ViewChild('deleteModalRef') deleteModalRef: ElementRef<any>;

  /**
   * Sets the assets form-array.
   */
  @Input() assetsForm: FormArray;

  /**
   * @param modalService The modal service.
   */
  constructor(private modalService: NgbModal) {}

  /**
   * Creates & adds a new asset form-group with validations.
   */
  addAsset() {
    const assetFormGroup = new FormGroup({
      description: new FormControl('', [Validators.required, Validators.minLength(1), Validators.maxLength(200)]),
      type: new FormControl('', Validators.maxLength(100)),
      serialNumber: new FormControl('', Validators.maxLength(100)),
      attachments: new FormArray([]),
    });

    this.assetsForm.push(assetFormGroup);
  }

  /**
   * Remove the asset at the given index from the list.
   * @param index The index of the asset to be removed.
   */
  remove(index: number) {
    this.assetsForm.removeAt(index);
  }

  /**
   * Remove all of the list of assets.
   *
   * It only adds one asset for quick start.
   */
  resetAssets() {
    this.assetsForm.clear();
  }

  /**
   * Opens the modal of the given templateRef.
   * @param modalRef The modal templateRef to be opened.
   */
  openModal(modalRef) {
    this.modalService.open(modalRef);
  }

  /**
   * Open confirm delete all engagements modal .
   */
  openDeleteModal() {
    this.openModal(this.deleteModalRef);
  }
}
