import { Action } from '@ngrx/store';

import { PurchaseRequestType } from 'lookups/models';
import { AppHttpResponse } from 'shared';

/**
 * The Lookups-Module store  purchase request type action types.
 */
export enum PurchaseRequestTypeActionType {
  GET_ALL_PURCHASE_REQUEST_TYPE = '[Lookups] [PurchaseRequestType] Get All Purchase Request Types',
  GET_ALL_PURCHASE_REQUEST_TYPE_FAIL = '[Lookups] [PurchaseRequestType] Get All Purchase Request Types Fail',
  GET_ALL_PURCHASE_REQUEST_TYPE_SUCCESS = '[Lookups] [PurchaseRequestType] Get All Purchase Request Types Success',
}

/**
 * Represents a store  purchase request type action.
 */
export class GetAllPurchaseRequestType implements Action {
  /**
   * The type of the action.
   */
  readonly type = PurchaseRequestTypeActionType.GET_ALL_PURCHASE_REQUEST_TYPE;

  /**
   * Fires a new  purchase request type action.
   */
  constructor() {}
}

/**
 * Represents a store  purchase request type fail action.
 */
export class GetAllPurchaseRequestTypeFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = PurchaseRequestTypeActionType.GET_ALL_PURCHASE_REQUEST_TYPE_FAIL;

  /**
   * Fires a new  purchase request type fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store  purchase request type success action.
 */
export class GetAllPurchaseRequestTypeSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = PurchaseRequestTypeActionType.GET_ALL_PURCHASE_REQUEST_TYPE_SUCCESS;

  /**
   * Fires a  purchase request type success action.
   * @param payload An object contains the list of  purchase request types.
   */
  constructor(public payload: AppHttpResponse<PurchaseRequestType[]>) {}
}

/**
 * Lookups-module  purchase request type action types.
 */
export type PurchaseRequestTypeActions =
  | GetAllPurchaseRequestType
  | GetAllPurchaseRequestTypeFail
  | GetAllPurchaseRequestTypeSuccess;
