import { BanksActionType, BanksActions } from 'app-search/store/actions';
import { BanksState } from 'app-search/store/states';

/**
 * The initial state from which the state starts.
 */
const initialState: BanksState = {
  data: [],
  paginationInfo: {
    total: 0,
    page: 1,
    pageSize: 0,
    count: 0,
  },
  error: null,
  isSearching: false,
  isSearchCompleted: false,
};

/**
 * The reducer function that is called in each action dispatch against the state.
 * @param state The current state.
 * @param action The action that will affect the state.
 */
export function banksReducer(state: BanksState = initialState, action: BanksActions): BanksState {
  switch (action.type) {
    //#region SEARCH_BANKS

    case BanksActionType.SEARCH_BANKS: {
      return {
        ...state,
        isSearching: true,
        isSearchCompleted: false,
        error: null,
      };
    }

    case BanksActionType.SEARCH_BANKS_FAIL: {
      return {
        ...state,
        error: action.payload,
        isSearching: false,
        isSearchCompleted: false,
      };
    }

    case BanksActionType.SEARCH_BANKS_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        paginationInfo: {
          ...action.payload.meta,
        },
        error: null,
        isSearching: false,
        isSearchCompleted: true,
      };
    }

    // #end region SEARCH_BANKS

    default:
      return state;
  }
}
