import { FinanceConfigurationsState } from 'finances/store/states';
import { FinanceConfigurationsActionType, FinanceConfigurationsActions } from 'finances/store/actions';

/**
 * The initial state from which the state starts.
 */
const initialState: FinanceConfigurationsState = {
  employeeProvisionLinks: [],
  govServices: [],
  paginationInfo: {
    total: 0,
    page: 1,
    pageSize: 0,
    count: 0,
  },
  govServicesPaginationInfo: {
    total: 0,
    page: 1,
    pageSize: 0,
    count: 0,
  },
  error: null,
  isEmployeeProvisionLinksGetting: false,
  isEmployeeProvisionLinksGetCompleted: false,
  isEmployeeProvisionLinksUpdating: false,
  isEmployeeProvisionLinksUpdateCompleted: false,
  isGovServicesGetting: false,
  isGovServicesGetCompleted: false,
  isGovServicesUpdating: false,
  isGovServicesUpdateCompleted: false,
};

/**
 * The reducer function that is called in each action dispatch against the state.
 * @param state The current state.
 * @param action The action that will affect the state.
 */
export function financeConfigurationsReducer(
  state: FinanceConfigurationsState = initialState,
  action: FinanceConfigurationsActions
): FinanceConfigurationsState {
  switch (action.type) {
    //#region GET_EMPLOYEE_PROVISION_LINKS

    case FinanceConfigurationsActionType.GET_EMPLOYEE_PROVISION_LINKS: {
      return {
        ...state,
        isEmployeeProvisionLinksGetting: true,
        isEmployeeProvisionLinksGetCompleted: false,
        error: null,
      };
    }

    case FinanceConfigurationsActionType.GET_EMPLOYEE_PROVISION_LINKS_FAIL: {
      return {
        ...state,
        error: action.payload,
        isEmployeeProvisionLinksGetting: false,
        isEmployeeProvisionLinksGetCompleted: false,
      };
    }

    case FinanceConfigurationsActionType.GET_EMPLOYEE_PROVISION_LINKS_SUCCESS: {
      return {
        ...state,
        employeeProvisionLinks: action.payload.data,
        error: null,
        isEmployeeProvisionLinksGetting: false,
        isEmployeeProvisionLinksGetCompleted: true,
      };
    }

    //#endregion GET_EMPLOYEE_PROVISION_LINKS

    //#region UPDATE_PAYROLL_SCREEN_LINKS

    case FinanceConfigurationsActionType.UPDATE_EMPLOYEE_PROVISION_LINKS: {
      return {
        ...state,
        isEmployeeProvisionLinksUpdating: true,
        isEmployeeProvisionLinksUpdateCompleted: false,
        error: null,
      };
    }

    case FinanceConfigurationsActionType.UPDATE_EMPLOYEE_PROVISION_LINKS_FAIL: {
      return {
        ...state,
        error: action.payload,
        isEmployeeProvisionLinksUpdating: false,
        isEmployeeProvisionLinksUpdateCompleted: false,
      };
    }

    case FinanceConfigurationsActionType.UPDATE_EMPLOYEE_PROVISION_LINKS_SUCCESS: {
      return {
        ...state,
        employeeProvisionLinks: action.payload.data,
        error: null,
        isEmployeeProvisionLinksUpdating: false,
        isEmployeeProvisionLinksUpdateCompleted: true,
      };
    }

    //#endregion UPDATE_EMPLOYEE_PROVISION_LINKS

    //#region GET_GOV_SERVICES

    case FinanceConfigurationsActionType.GET_GOV_SERVICES: {
      return {
        ...state,
        isGovServicesGetting: true,
        isGovServicesGetCompleted: false,
        error: null,
      };
    }

    case FinanceConfigurationsActionType.GET_GOV_SERVICES_FAIL: {
      return {
        ...state,
        error: action.payload,
        isGovServicesGetting: false,
        isGovServicesGetCompleted: false,
      };
    }

    case FinanceConfigurationsActionType.GET_GOV_SERVICES_SUCCESS: {
      return {
        ...state,
        govServices: action.payload.data,
        error: null,
        isGovServicesGetting: false,
        isGovServicesGetCompleted: true,
      };
    }

    //#endregion GET_GOV_SERVICES

    //#region UPDATE_PAYROLL_SCREEN_LINKS

    case FinanceConfigurationsActionType.UPDATE_GOV_SERVICES: {
      return {
        ...state,
        isGovServicesUpdating: true,
        isGovServicesUpdateCompleted: false,
        error: null,
      };
    }

    case FinanceConfigurationsActionType.UPDATE_GOV_SERVICES_FAIL: {
      return {
        ...state,
        error: action.payload,
        isGovServicesUpdating: false,
        isGovServicesUpdateCompleted: false,
      };
    }

    case FinanceConfigurationsActionType.UPDATE_GOV_SERVICES_SUCCESS: {
      return {
        ...state,
        govServices: action.payload.data,
        error: null,
        isGovServicesUpdating: false,
        isGovServicesUpdateCompleted: true,
      };
    }

    //#endregion UPDATE_GOV_SERVICES

    default:
      return state;
  }
}
