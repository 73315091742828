import { Injectable } from '@angular/core';

import { of } from 'rxjs';
import { switchMap, map, catchError, debounceTime } from 'rxjs/operators';
import { Effect, ofType, Actions } from '@ngrx/effects';

import { PAGINATION } from 'shared';
import { GovServicesService } from 'app-search/services';
import {
  GovServicesActionType,
  SearchGovServices,
  SearchGovServicesFail,
  SearchGovServicesSuccess,
} from 'app-search/store/actions';

@Injectable()
export class GovServicesEffects {
  constructor(private actions$: Actions, private govServicesService: GovServicesService) {}

  /* ========================= SEARCH_GOV_SERVICES =================================== */
  @Effect()
  search$ = this.actions$.pipe(
    ofType(GovServicesActionType.SEARCH_GOV_SERVICES),
    debounceTime(300),
    switchMap(({ payload }: SearchGovServices) =>
      this.govServicesService.searchServices(payload?.name ?? '', payload.page, PAGINATION.Services).pipe(
        map((response) => new SearchGovServicesSuccess(response)),
        catchError((error) => of(new SearchGovServicesFail(error)))
      )
    )
  );
}
