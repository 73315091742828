<!-- update gov services form starts -->
<div class="card">
  <div class="card-content">
    <div class="px-3">
      <form [formGroup]="form" (ngSubmit)="submit()">
        <div class="row">
          <div class="col-md-12">
            <app-gov-services-form
              [govServicesForm]="govServicesForm"
              [initialGovServices]="newGovServices"
            ></app-gov-services-form>
          </div>
        </div>
        <!-- gov services tools starts -->
        <div class="row">
          <div class="form-group col-12 d-flex justify-content-between flex-column flex-sm-row mt-2">
            <button type="submit" class="btn btn-raised btn-success round" [disabled]="isUpdating$ | async">
              <i class="fa fa-check"></i>
              <span class="mx-1">{{ 'SHARED.CONFIRM.SAVE' | translate }}</span>
            </button>
          </div>
        </div>
        <!-- gov services tools ends -->
      </form>
    </div>
  </div>
</div>
<!-- update gov services form ends -->

<!-- confirm modal starts -->
<ng-template #confirmModalRef let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
      {{ 'FINANCES.FINANCE_CONFIGURATIONS.CONFIRM_UPDATE_GOV_SERVICES_TITLE' | translate }}
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="d()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p class="text-success">
      {{ 'FINANCES.FINANCE_CONFIGURATIONS.CONFIRM_UPDATE_GOV_SERVICES_MESSAGE' | translate }}
    </p>
  </div>
  <div class="modal-footer">
    <div class="modal-actions">
      <button type="button" class="btn btn-raised btn-success round" (click)="c(); confirm()" ngbAutofocus>
        <i class="fa fa-check"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.SAVE' | translate }}</span>
      </button>
      <button type="button" class="btn btn-outline-dark round" (click)="c()">
        <i class="fa fa-times"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.CANCEL' | translate }}</span>
      </button>
    </div>
  </div>
</ng-template>
<!-- confirm modal ends -->
