import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';

import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

import { NotificationService, PaginationInfo, TranslationService } from 'shared';
import * as fromProjectsManagementStore from 'projects-management/store';
import { ProjectMainTask } from 'projects-management/models';

@Component({
  selector: 'app-project-main-tasks-search',
  templateUrl: './project-main-tasks-search.component.html',
  styles: [],
})
export class ProjectMainTasksSearchComponent implements OnInit {
  /**
   * Indicates whether the modal will automatically close after select the first main task.
   *
   * Be default it will not be closed.
   */
  @Input() closeOnSelect = false;

  /**
   * Indicates whether the main tasks displayed on the list should be all active.
   *
   * Be default it will not be closed.
   */
  @Input() onlyActive = false;

  /**
   * Sets the project id.
   */
  @Input() projectId: number;

  /**
   * Sets the visibility of this component modal.
   *
   * Handles visibility change in two-data-binding approach.
   */
  @Input() set visible(isShown: boolean) {
    if (isShown && this.modalRef) {
      this.openModal(this.modalRef);
    } else {
      this.closeModal();
    }

    this.visibleChange.emit(isShown);
  }

  /**
   * Outputs a value that indicates whether the modal is shown or not.
   *
   * Handles visibility change in two-data-binding approach.
   */
  @Output() visibleChange = new EventEmitter<boolean>();

  /** Outputs the selected main tasks. */
  @Output() mainTasks = new EventEmitter<ProjectMainTask[]>();

  /**
   * The modal template reference.
   */
  @ViewChild('modalRef') modalRef: ElementRef<any>;

  /**
   * The list of main tasks.
   */
  mainTasks$: Observable<ProjectMainTask[]>;

  /**
   * The list of main tasks.
   */
  mainTasksList: ProjectMainTask[];

  /**
   * The pagination info.
   */
  paginationInfo$: Observable<PaginationInfo>;

  /**
   * Indicates whether there is a search process is running or not.
   */
  isSearching$: Observable<boolean>;

  /**
   * The search form.
   */
  searchForm: FormGroup;

  /**
   * The ngb-modal reference to use it when modal needed to be closed.
   */
  modal: NgbModalRef;

  /**
   * @param modalService The modal service.
   * @param notificationService The notification service.
   * @param projectsManagementStore$ The projectsManagement store.
   * @param translationService The translation service.
   */
  constructor(
    private modalService: NgbModal,
    private notificationService: NotificationService,
    private projectsManagementStore$: Store<fromProjectsManagementStore.ProjectsManagementState>,
    private translationService: TranslationService
  ) {}

  ngOnInit(): void {
    this.init();
  }

  /**
   * Initialize component data.
   */
  init() {
    this.initForm();

    /**
     * Load data.
     */
    this.isSearching$ = this.projectsManagementStore$.pipe(
      select(fromProjectsManagementStore.getProjectMainTasksSearching)
    );

    this.mainTasks$ = this.projectsManagementStore$.pipe(
      select(fromProjectsManagementStore.getProjectMainTasks),
      tap((mainTasks) => {
        if (mainTasks) {
          this.mainTasksList = mainTasks;
        }
      })
    );

    this.paginationInfo$ = this.projectsManagementStore$.pipe(
      select(fromProjectsManagementStore.getProjectMainTasksPaginationInfo)
    );

    this.search();
  }

  /**
   * Initialize form and add validators.
   */
  initForm() {
    this.searchForm = new FormGroup({
      description: new FormControl(''),
      projects: new FormControl([]),
      page: new FormControl(1),
    });
  }

  /**
   * Handles search parameters change.
   */
  search(event?: KeyboardEvent) {
    if (event && event.key === 'Enter') {
      event.preventDefault();
      return;
    }

    this.applyFiltersAndSearch();
  }

  /**
   * Handles pagination page-changed event.
   * @param page The current selected page number.
   */
  pageChanged(page: number) {
    /** Update pagination page. */
    this.searchForm.patchValue({ page });

    this.applyFiltersAndSearch(page);
  }

  /**
   * Enables trackBy feature for *ngFor utility to track items
   * depending on a comparer to reduce DOM change.
   * If the item is already exist then no HTML will change, only bounded values.
   * @param item The item to determine if it was changed or not.
   */
  trackItems(item: ProjectMainTask) {
    return item ? item.id : undefined;
  }

  /**
   * Handles main task selected event.
   * @param mainTasks The list of main tasks to notify selected.
   */
  selectMainTask(mainTasks: ProjectMainTask[]) {
    this.notificationService.successInstant(
      this.translationService.translate('PROJECTS_MANAGEMENT.PROJECTS.NOTIFICATION_MESSAGE.PROJECT_MAIN_TASK_SELECTED')
    );
    this.mainTasks.emit(mainTasks);
    if (this.closeOnSelect) {
      this.closeModal();
    }
  }

  /**
   * Applies the search filters and dispatches a search action.
   * @param page The current page number, default to `1`.
   */
  applyFiltersAndSearch(page: number = 1) {
    const { description, projects } = this.searchForm.value;

    this.projectsManagementStore$.dispatch(
      new fromProjectsManagementStore.SearchMainTasks({
        description,
        projects: [this.projectId],
        page,
      })
    );
  }

  /**
   * Opens the modal of the given templateRef.
   * @param modalRef The modal templateRef to be opened.
   */
  openModal(modalRef) {
    /**
     * Open the modal & listen for user press ESC key.
     */
    this.modal = this.modalService.open(modalRef, { size: 'xl' });
    this.modal.result.then(null, () => this.closeModal());
  }

  /**
   * Closes the currently opened modal.
   */
  closeModal() {
    this.modal?.dismiss();
    this.mainTasks.emit([]);
    this.visibleChange.emit(false);
  }
}
