import { Component, Input } from '@angular/core';

import { AccrualPayroll } from 'hr/models';

@Component({
  selector: 'app-journal-accrual-payroll-details',
  templateUrl: './journal-accrual-payroll-details.component.html',
  styles: [],
})
export class JournalAccrualPayrollDetailsComponent {
  /**
   * Sets the current viewed journal accrual payroll.
   */
  @Input() accrualPayroll: AccrualPayroll;
}
