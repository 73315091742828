import { LoansComponent } from 'finances/components/hr/loans/loans.component';
import { LoanViewComponent } from 'finances/components/hr/loan-view/loan-view.component';
import { LoanSettlementsComponent } from 'finances/components/hr/loan-settlements/loan-settlements.component';
import { PayrollsListComponent } from 'finances/components/hr/payrolls-list/payrolls-list.component';
import { CreatePayrollPaymentComponent } from 'finances/components/hr/create-payroll-payment/create-payroll-payment.component';
import { PayPayrollFormItemComponent } from 'finances/components/hr/pay-payroll-form-item/pay-payroll-form-item.component';
import { PayPayrollsFormComponent } from 'finances/components/hr/pay-payrolls-form/pay-payrolls-form.component';
import { PaidPayrollsListComponent } from 'finances/components/hr/paid-payrolls-list/paid-payrolls-list.component';
import { PayrollPaymentDetailsComponent } from 'finances/components/hr/payroll-payment-details/payroll-payment-details.component';
import { PayrollPaymentsListComponent } from 'finances/components/hr/payroll-payments-list/payroll-payments-list.component';
import { PayrollPaymentRefundsListComponent } from 'finances/components/hr/payroll-payment-refunds-list/payroll-payment-refunds-list.component';
import { PayrollPaymentRefundDetailsComponent } from 'finances/components/hr/payroll-payment-refund-details/payroll-payment-refund-details.component';
import { CreatePayrollPaymentRefundComponent } from 'finances/components/hr/create-payroll-payment-refund/create-payroll-payment-refund.component';
import { PaidPayrollsSearchComponent } from 'finances/components/hr/paid-payrolls-search/paid-payrolls-search.component';
import { PaidPayrollsFormComponent } from 'finances/components/hr/paid-payrolls-form/paid-payrolls-form.component';
import * as fromGovServices from 'finances/components/hr/gov-service-requests';

export * from 'finances/components/hr/loans/loans.component';
export * from 'finances/components/hr/loan-view/loan-view.component';
export * from 'finances/components/hr/loan-settlements/loan-settlements.component';
export * from 'finances/components/hr/payrolls-list/payrolls-list.component';
export * from 'finances/components/hr/create-payroll-payment/create-payroll-payment.component';
export * from 'finances/components/hr/pay-payroll-form-item/pay-payroll-form-item.component';
export * from 'finances/components/hr/pay-payrolls-form/pay-payrolls-form.component';
export * from 'finances/components/hr/paid-payrolls-list/paid-payrolls-list.component';
export * from 'finances/components/hr/payroll-payment-details/payroll-payment-details.component';
export * from 'finances/components/hr/payroll-payments-list/payroll-payments-list.component';
export * from 'finances/components/hr/payroll-payment-refunds-list/payroll-payment-refunds-list.component';
export * from 'finances/components/hr/payroll-payment-refund-details/payroll-payment-refund-details.component';
export * from 'finances/components/hr/create-payroll-payment-refund/create-payroll-payment-refund.component';
export * from 'finances/components/hr/paid-payrolls-search/paid-payrolls-search.component';
export * from 'finances/components/hr/paid-payrolls-form/paid-payrolls-form.component';
export * from 'finances/components/hr/gov-service-requests';

/**
 * The set of components registered on this sub-module.
 */
export const COMPONENTS: any[] = [
  LoansComponent,
  LoanViewComponent,
  LoanSettlementsComponent,
  PayrollsListComponent,
  CreatePayrollPaymentComponent,
  PayPayrollFormItemComponent,
  PayPayrollsFormComponent,
  PaidPayrollsListComponent,
  PayrollPaymentDetailsComponent,
  PayrollPaymentsListComponent,
  PayrollPaymentRefundsListComponent,
  PayrollPaymentRefundDetailsComponent,
  CreatePayrollPaymentRefundComponent,
  PaidPayrollsSearchComponent,
  PaidPayrollsFormComponent,
  ...fromGovServices.COMPONENTS,
];
