import { createSelector } from '@ngrx/store';

import { PrintingState, PrintsState } from 'printing/store/states';
import { selectPrintingState } from 'printing/store/selectors/feature.selectors';

/* Selectors */

/**
 * Gets the prints printing state.
 */
const selectPrintsState = createSelector(selectPrintingState, (state: PrintingState) => state.prints);

/**
 * Gets the loaded report.
 */
export const getIncomingStockPrint = createSelector(selectPrintsState, (state: PrintsState) => state.incomingStock);

/**
 * Gets the loaded report.
 */
export const getOutgoingStockPrint = createSelector(selectPrintsState, (state: PrintsState) => state.outgoingStock);

/**
 * Gets the loaded report.
 */
export const getTransferStockPrint = createSelector(selectPrintsState, (state: PrintsState) => state.transferStock);

/**
 * Gets the loaded report.
 */
export const getPurchaseRequestPrint = createSelector(selectPrintsState, (state: PrintsState) => state.purchaseRequest);

/**
 * Gets the loaded report.
 */
export const getPurchaseOrderPrint = createSelector(selectPrintsState, (state: PrintsState) => state.purchaseOrder);

/**
 * Gets the loaded report.
 */
export const getPurchaseInvoicePrint = createSelector(selectPrintsState, (state: PrintsState) => state.purchaseInvoice);

/**
 * Gets the loaded report.
 */
export const getPurchaseReturnPrint = createSelector(selectPrintsState, (state: PrintsState) => state.purchaseReturn);

/**
 * Gets the loaded report.
 */
export const getSaleInvoicePrint = createSelector(selectPrintsState, (state: PrintsState) => state.saleInvoice);

/**
 * Gets the loaded report.
 */
export const getSaleReturnPrint = createSelector(selectPrintsState, (state: PrintsState) => state.saleReturn);

/**
 * Gets the loaded report.
 */
export const getSalesQuotationPrint = createSelector(selectPrintsState, (state: PrintsState) => state.salesQuotation);

/**
 * Gets the loaded report.
 */
export const getJournalPrint = createSelector(selectPrintsState, (state: PrintsState) => state.journal);

/**
 * Gets the error that occurred in the state.
 */
export const getPrintsError = createSelector(selectPrintsState, (state: PrintsState) => state.error);

/**
 * Determines if there is a running load process.
 */
export const getIncomingStockPrintLoading = createSelector(
  selectPrintsState,
  (state: PrintsState) => state.isLoadingIncomingStock
);

/**
 * Determines if the last load process has been ended successfully.
 */
export const getIncomingStockPrintLoadCompleted = createSelector(
  selectPrintsState,
  (state: PrintsState) => state.isIncomingStockLoadCompleted
);

/**
 * Determines if there is a running load process.
 */
export const getOutgoingStockPrintLoading = createSelector(
  selectPrintsState,
  (state: PrintsState) => state.isLoadingOutgoingStock
);

/**
 * Determines if the last load process has been ended successfully.
 */
export const getOutgoingStockPrintLoadCompleted = createSelector(
  selectPrintsState,
  (state: PrintsState) => state.isOutgoingStockLoadCompleted
);

/**
 * Determines if there is a running load process.
 */
export const getTransferStockPrintLoading = createSelector(
  selectPrintsState,
  (state: PrintsState) => state.isLoadingTransferStock
);

/**
 * Determines if the last load process has been ended successfully.
 */
export const getTransferStockPrintLoadCompleted = createSelector(
  selectPrintsState,
  (state: PrintsState) => state.isTransferStockLoadCompleted
);

/**
 * Determines if there is a running load process.
 */
export const getPurchaseRequestPrintLoading = createSelector(
  selectPrintsState,
  (state: PrintsState) => state.isLoadingPurchaseRequest
);

/**
 * Determines if the last load process has been ended successfully.
 */
export const getPurchaseRequestPrintLoadCompleted = createSelector(
  selectPrintsState,
  (state: PrintsState) => state.isPurchaseRequestLoadCompleted
);

/**
 * Determines if there is a running load process.
 */
export const getPurchaseOrderPrintLoading = createSelector(
  selectPrintsState,
  (state: PrintsState) => state.isLoadingPurchaseOrder
);

/**
 * Determines if the last load process has been ended successfully.
 */
export const getPurchaseOrderPrintLoadCompleted = createSelector(
  selectPrintsState,
  (state: PrintsState) => state.isPurchaseOrderLoadCompleted
);

/**
 * Determines if there is a running load process.
 */
export const getPurchaseInvoicePrintLoading = createSelector(
  selectPrintsState,
  (state: PrintsState) => state.isLoadingPurchaseInvoice
);

/**
 * Determines if the last load process has been ended successfully.
 */
export const getPurchaseInvoicePrintLoadCompleted = createSelector(
  selectPrintsState,
  (state: PrintsState) => state.isPurchaseInvoiceLoadCompleted
);

/**
 * Determines if there is a running load process.
 */
export const getPurchaseReturnPrintLoading = createSelector(
  selectPrintsState,
  (state: PrintsState) => state.isLoadingPurchaseReturn
);

/**
 * Determines if the last load process has been ended successfully.
 */
export const getPurchaseReturnPrintLoadCompleted = createSelector(
  selectPrintsState,
  (state: PrintsState) => state.isPurchaseReturnLoadCompleted
);

/**
 * Determines if there is a running load process.
 */
export const getSaleInvoicePrintLoading = createSelector(
  selectPrintsState,
  (state: PrintsState) => state.isLoadingSaleInvoice
);

/**
 * Determines if the last load process has been ended successfully.
 */
export const getSaleInvoicePrintLoadCompleted = createSelector(
  selectPrintsState,
  (state: PrintsState) => state.isSaleInvoiceLoadCompleted
);

/**
 * Determines if there is a running load process.
 */
export const getSaleReturnPrintLoading = createSelector(
  selectPrintsState,
  (state: PrintsState) => state.isLoadingSaleReturn
);

/**
 * Determines if the last load process has been ended successfully.
 */
export const getSaleReturnPrintLoadCompleted = createSelector(
  selectPrintsState,
  (state: PrintsState) => state.isSaleReturnLoadCompleted
);

/**
 * Determines if there is a running load process.
 */
export const getSalesQuotationPrintLoading = createSelector(
  selectPrintsState,
  (state: PrintsState) => state.isLoadingSalesQuotation
);

/**
 * Determines if the last load process has been ended successfully.
 */
export const getSalesQuotationPrintLoadCompleted = createSelector(
  selectPrintsState,
  (state: PrintsState) => state.isSalesQuotationLoadCompleted
);

/**
 * Determines if there is a running load process.
 */
export const getJournalPrintLoading = createSelector(selectPrintsState, (state: PrintsState) => state.isLoadingJournal);

/**
 * Determines if the last load process has been ended successfully.
 */
export const getJournalPrintLoadCompleted = createSelector(
  selectPrintsState,
  (state: PrintsState) => state.isJournalLoadCompleted
);
