import { Component, Input } from '@angular/core';

import { Decimal } from 'decimal.js';

import { OutgoingStock, OutgoingStockProduct } from 'stocks/models';

@Component({
  selector: 'app-journal-outgoing-stock-details',
  templateUrl: './journal-outgoing-stock-details.component.html',
  styles: [],
})
export class JournalOutgoingStockDetailsComponent {
  /**
   * Sets the current viewed journal outgoing stock.
   */
  @Input() outgoingStock: OutgoingStock;

  /**
   * Gets the total quantity of Outgoing stock products.
   */
  productsQuantity(outgoingStockProducts: OutgoingStockProduct[]): number {
    return Decimal.sum(0, ...outgoingStockProducts.map((product) => product.quantity ?? 0)).toNumber();
  }
}
