import { RouterReducerState } from '@ngrx/router-store';

import { RouterStateUrl } from 'core/store/states/router-url.state';
import { AppStatusState } from 'core/store/states/app-status.state';

/**
 * Represents the app state.
 */
export class AppState {
  /**
   * The router state.
   */
  public router: RouterReducerState<RouterStateUrl>;

  /**
   * The state that contains information about app status.
   */
  public status: AppStatusState;
}
