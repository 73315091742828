import { Injectable } from '@angular/core';

import { of } from 'rxjs';
import { switchMap, map, catchError, debounceTime } from 'rxjs/operators';
import { Effect, ofType, Actions } from '@ngrx/effects';

import { PAGINATION } from 'shared';
import { RegionsService } from 'app-search/services';
import { RegionsActionType, SearchRegions, SearchRegionsFail, SearchRegionsSuccess } from 'app-search/store/actions';

@Injectable()
export class RegionsEffects {
  constructor(private actions$: Actions, private RegionsService: RegionsService) {}

  /* ========================= SEARCH_REGIONS =================================== */
  @Effect()
  search$ = this.actions$.pipe(
    ofType(RegionsActionType.SEARCH_REGIONS),
    debounceTime(300),
    switchMap(({ payload }: SearchRegions) =>
      this.RegionsService.search(payload?.name ?? '', payload.page, PAGINATION.Regions).pipe(
        map((response) => new SearchRegionsSuccess(response)),
        catchError((error) => of(new SearchRegionsFail(error)))
      )
    )
  );
}
