import { createSelector } from '@ngrx/store';

import { selectLookupsState } from 'lookups/store/selectors/feature.selectors';
import { LookupsState, VisaTypeState } from 'lookups/store/states';

/**
 * Gets the visa type state.
 */
const selectVisaTypes = createSelector(selectLookupsState, (state: LookupsState) => state.visaTypes);

/**
 * Gets the list of loaded visa types.
 */
export const getVisaTypes = createSelector(selectVisaTypes, (state: VisaTypeState) => state.data);

/**
 * Gets the error that occurred in the state.
 */
export const getVisaTypesError = createSelector(selectVisaTypes, (state: VisaTypeState) => state.error);

/**
 * Determines if there is a running load process.
 */
export const getVisaTypesIsLoading = createSelector(selectVisaTypes, (state: VisaTypeState) => state.isLoading);

/**
 * Determines if the last load process has been ended successfully.
 */
export const getVisaTypesIsLoaded = createSelector(selectVisaTypes, (state: VisaTypeState) => state.isLoaded);
