import { JournalTypeActionType, JournalTypeActions } from 'lookups/store/actions';
import { JournalTypeState } from 'lookups/store/states';

/**
 * The initial state from which the state starts.
 */
const initialState: JournalTypeState = {
  data: [],
  error: null,
  isLoaded: false,
  isLoading: false,
};

/**
 * The reducer function that is called in each action dispatch against the state.
 * @param state The current state.
 * @param action The action that will affect the state.
 */
export function journalTypeReducer(
  state: JournalTypeState = initialState,
  action:JournalTypeActions
): JournalTypeState {
  switch (action.type) {
    case JournalTypeActionType.GET_ALL_JOURNAL_TYPES: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case JournalTypeActionType.GET_ALL_JOURNAL_TYPES_FAIL: {
      return {
        ...state,
        error: action.payload,
        isLoading: false,
        isLoaded: false,
      };
    }

    case JournalTypeActionType.GET_ALL_JOURNAL_TYPES_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        isLoading: false,
        isLoaded: true,
      };
    }

    default:
      return state;
  }
}
