import { Injectable } from '@angular/core';
import { Effect, ofType, Actions } from '@ngrx/effects';

import { of } from 'rxjs';
import { switchMap, map, catchError, tap } from 'rxjs/operators';

import { NotificationService, StatusCode, TranslationService } from 'shared';
import { OrganizationSettingsService } from 'settings/services';
import {
  OrganizationSettingsActionType,
  GetOrganizationSettingsSuccess,
  GetOrganizationSettingsFail,
  UpdateOrganizationSettings,
  UpdateOrganizationSettingsSuccess,
  UpdateOrganizationSettingsFail,
  DeleteOrganizationSettingsLogo,
  UpdateOrganizationSettingsLogo,
  UpdateImageHeader,
  UpdateImageFooter,
} from 'settings/store/actions';
import { DeleteImageFooter, DeleteImageHeader } from 'stores/store';

@Injectable()
export class OrganizationSettingsEffects {
  constructor(
    private actions$: Actions,
    private organizationSettingsService: OrganizationSettingsService,
    private notificationService: NotificationService,
    private translationService: TranslationService
  ) {}

  /* ========================= GET_ORGANIZATION_SETTINGS =================================== */
  @Effect()
  getOrganizationSettings$ = this.actions$.pipe(
    ofType(OrganizationSettingsActionType.GET_ORGANIZATION_SETTINGS),
    switchMap(() =>
      this.organizationSettingsService.getOrganizationSettings().pipe(
        map((response) => new GetOrganizationSettingsSuccess(response)),
        catchError((error) => of(new GetOrganizationSettingsFail(error)))
      )
    )
  );

  /* ========================= UPDATE_ORGANIZATION_SETTINGS =================================== */
  @Effect()
  updateOrganizationSettings$ = this.actions$.pipe(
    ofType(OrganizationSettingsActionType.UPDATE_ORGANIZATION_SETTINGS),
    switchMap((action: UpdateOrganizationSettings) =>
      this.organizationSettingsService.updateOrganizationSettings(action.payload).pipe(
        map((response) => new UpdateOrganizationSettingsSuccess(response)),
        catchError((error) => of(new UpdateOrganizationSettingsFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  updateOrganizationSettingsSuccess$ = this.actions$.pipe(
    ofType(OrganizationSettingsActionType.UPDATE_ORGANIZATION_SETTINGS_SUCCESS),
    tap((action: UpdateOrganizationSettingsSuccess) => {
      this.notificationService.success(
        this.translationService.translate('SETTINGS.ORGANIZATION_SETTINGS.ORGANIZATION_SETTINGS_UPDATED')
      );
    })
  );

  @Effect({ dispatch: false })
  updateOrganizationSettingsFail$ = this.actions$.pipe(
    ofType(OrganizationSettingsActionType.UPDATE_ORGANIZATION_SETTINGS_FAIL),
    tap((action: UpdateOrganizationSettingsFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate('SETTINGS.ORGANIZATION_SETTINGS.ORGANIZATION_SETTINGS_NOT_FOUND')
        );
      } else if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );
  @Effect()
  updateLogo$ = this.actions$.pipe(
    ofType(OrganizationSettingsActionType.UPDATE_ORGANIZATION_SETTINGS_LOGO),
    switchMap((action: UpdateOrganizationSettingsLogo) =>
      this.organizationSettingsService.updateLogo(action.payload).pipe(
        map((response) => new UpdateOrganizationSettingsSuccess(response)),
        catchError((error) => of(new UpdateOrganizationSettingsFail(error)))
      )
    )
  );

  @Effect()
  updateImageHeader$ = this.actions$.pipe(
    ofType(OrganizationSettingsActionType.UPDATE_IMAGE_HEADER),
    switchMap((action: UpdateImageHeader) =>
      this.organizationSettingsService.updateImageHeader(action.payload.invoiceHeaderFilename).pipe(
        map((response) => new UpdateOrganizationSettingsSuccess(response)),
        catchError((error) => of(new UpdateOrganizationSettingsFail(error)))
      )
    )
  );

  @Effect()
  updateImageFooter$ = this.actions$.pipe(
    ofType(OrganizationSettingsActionType.UPDATE_IMAGE_FOOTER),
    switchMap((action: UpdateImageFooter) =>
      this.organizationSettingsService.updateImageFooter(action.payload.invoiceFooterFilename).pipe(
        map((response) => new UpdateOrganizationSettingsSuccess(response)),
        catchError((error) => of(new UpdateOrganizationSettingsFail(error)))
      )
    )
  );

  @Effect()
  deleteLogo$ = this.actions$.pipe(
    ofType(OrganizationSettingsActionType.DELETE_ORGANIZATION_SETTINGS_LOGO),
    switchMap((action: DeleteOrganizationSettingsLogo) =>
      this.organizationSettingsService.deleteLogo().pipe(
        map((response) => new UpdateOrganizationSettingsSuccess(response)),
        catchError((error) => of(new UpdateOrganizationSettingsFail(error)))
      )
    )
  );

  @Effect()
  deleteImageHeader$ = this.actions$.pipe(
    ofType(OrganizationSettingsActionType.DELETE_IMAGE_HEADER),
    switchMap((action: DeleteImageHeader) =>
      this.organizationSettingsService.deleteImageHeader().pipe(
        map((response) => new UpdateOrganizationSettingsSuccess(response)),
        catchError((error) => of(new UpdateOrganizationSettingsFail(error)))
      )
    )
  );

  @Effect()
  deleteImageFooter$ = this.actions$.pipe(
    ofType(OrganizationSettingsActionType.DELETE_IMAGE_FOOTER),
    switchMap((action: DeleteImageFooter) =>
      this.organizationSettingsService.deleteImageFooter().pipe(
        map((response) => new UpdateOrganizationSettingsSuccess(response)),
        catchError((error) => of(new UpdateOrganizationSettingsFail(error)))
      )
    )
  );
}
