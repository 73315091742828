import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';

import { AppHttpResponse } from 'shared';
import {
  CreateAccountInput,
  UpdateAccountInput,
  Account,
  CreateSecondaryAccountInput,
  UpdateSecondaryAccountInput,
} from 'finances/models';

/**
 * The accounts services includes functionality to create, search, findById, update and delete for an account.
 */
@Injectable()
export class AccountsService {
  /**
   * The relative route for the accounts.
   *
   * No leading or trailing slashes required.
   */
  private accountsApi = 'finances/accounts';
  private secondaryAccountsApi = 'finances/accounts/secondary';

  constructor(private http: HttpClient) {}

  /**
   * Creates a new account from the provided data.
   * @param data The new account data.
   */
  public create(data: CreateAccountInput): Observable<AppHttpResponse<Account>> {
    return this.http.post<AppHttpResponse<Account>>(`${this.accountsApi}`, data);
  }

  /**
   * Creates a new secondary account from the provided data.
   * @param data The new secondary account data.
   */
  public createSecondaryAccount(data: CreateSecondaryAccountInput): Observable<AppHttpResponse<Account>> {
    return this.http.post<AppHttpResponse<Account>>(`${this.secondaryAccountsApi}`, data);
  }

  /**
   * Searches in the accounts by description.
   * @param description The description of the account.
   * @param page The current pagination page number.
   * @param pageSize The maximum number of accounts allowed per one pagination page.
   */
  public search(description: string, page: number, pageSize: number): Observable<AppHttpResponse<Account[]>> {
    const params = new HttpParams()
      .set('description', description)
      .set('page', page.toString())
      .set('pageSize', pageSize.toString());
    return this.http.get<AppHttpResponse<Account[]>>(`${this.accountsApi}`, { params });
  }

  /**
   * Searches the regular accounts (non primary, non secondary) and find the set of accounts matching the provided query params.
   * @param description The description of the account.
   * @param primaryAccountKey The primaryAccountKey of the account.
   * @param page The current pagination page number.
   * @param pageSize The maximum number of accounts allowed per one pagination page.
   */
  public searchRegularAccounts(
    description: string,
    primaryAccountKey: string,
    page: number,
    pageSize: number
  ): Observable<AppHttpResponse<Account[]>> {
    const params = new HttpParams()
      .set('description', description)
      .set('primaryAccountKey', primaryAccountKey)
      .set('page', page.toString())
      .set('pageSize', pageSize.toString());
    return this.http.get<AppHttpResponse<Account[]>>(`${this.accountsApi}/regular`, { params });
  }

  /**
   * Finds the account with the given id.
   * @param id The id of the account.
   */
  public findById(id: number): Observable<AppHttpResponse<Account>> {
    return this.http.get<AppHttpResponse<Account>>(`${this.accountsApi}/${id}`);
  }

  /**
   * Updates an existing account data using the provided data.
   * @param data The updated account data.
   */
  public update(data: UpdateAccountInput): Observable<AppHttpResponse<Account>> {
    return this.http.put<AppHttpResponse<Account>>(`${this.accountsApi}`, data);
  }

  /**
   * Updates an existing secondary account data using the provided data.
   * @param data The updated secondary account data.
   */
  public updateSecondaryAccount(data: UpdateSecondaryAccountInput): Observable<AppHttpResponse<Account>> {
    return this.http.put<AppHttpResponse<Account>>(`${this.secondaryAccountsApi}`, data);
  }

  /**
   * Deletes the account by given id.
   * @param id The id of the account.
   */
  public delete(id: number): Observable<AppHttpResponse<Account>> {
    return this.http.delete<AppHttpResponse<Account>>(`${this.accountsApi}/${id}`);
  }
}
