import { Component, Input } from '@angular/core';

import { EmployeeLoan } from 'hr/models';

@Component({
  selector: 'app-journal-employee-loan-details',
  templateUrl: './journal-employee-loan-details.component.html',
  styles: [],
})
export class JournalEmployeeLoanDetailsComponent {
  /**
   * Sets the current viewed journal employee loan.
   */
  @Input() employeeLoan: EmployeeLoan;
}
