import { remove } from 'lodash';
import { v4 as uuid } from 'uuid';

import { ParkedSaleInvoicesActionType, ParkedSaleInvoicesActions } from 'sales/store/actions';
import { ParkedSaleInvoicesState } from 'sales/store/states';

/**
 * The initial state from which the state starts.
 */
const initialState: ParkedSaleInvoicesState = {
  data: [],
};

/**
 * The reducer function that is called in each action dispatch against the state.
 * @param state The current state.
 * @param action The action that will affect the state.
 */
export function parkedSaleInvoicesReducer(
  state: ParkedSaleInvoicesState = initialState,
  action: ParkedSaleInvoicesActions
): ParkedSaleInvoicesState {
  switch (action.type) {
    //#region ADD_PARKED_SALE_INVOICE

    case ParkedSaleInvoicesActionType.ADD_PARKED_SALE_INVOICE: {
      return {
        ...state,
        data: [{ id: uuid(), ...action.payload }, ...state.data],
      };
    }

    //#endregion ADD_PARKED_SALE_INVOICE

    //#region REMOVE_PARKED_SALE_INVOICE

    case ParkedSaleInvoicesActionType.REMOVE_PARKED_SALE_INVOICE: {
      const invoices = [...state.data];

      /* Remove the parked sale invoice from the array. */
      remove(invoices, (invoice) => invoice.id === action.payload);

      return {
        ...state,
        data: invoices,
      };
    }

    //#endregion REMOVE_PARKED_SALE_INVOICE

    default:
      return state;
  }
}
