import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormGroup, FormControl } from '@angular/forms';

import { GovService, GovServiceItem } from 'finances/models';

@Component({
  selector: 'app-gov-services-form',
  templateUrl: './gov-services-form.component.html',
  styles: [],
})
export class GovServicesFormComponent implements OnInit {
  /**
   * Sets the gov services form-array.
   */
  @Input() govServicesForm: FormArray;

  /**
   * Sets the initial gov service in the form.
   * @param  services An object contains `count` of initial blank gov services.
   * @param  services A single gov service service details to be added to the form.
   * @param  services A list of gov services details to be added to the form.
   */
  @Input() set initialGovServices(services: { count: number } | GovServiceItem | GovServiceItem[]) {
    if (Object.getOwnPropertyNames(services).includes('count')) {
      for (let index = 0; index < (services as { count: number }).count; index++) {
        this.addGovService();
      }
    } else if (services instanceof Array) {
      services.forEach((service) => this.addGovService(service));
    } else if (typeof services === 'object') {
      this.addGovService(services as GovServiceItem);
    }
  }

  /**
   * Gets or sets the initial select list gov service service
   * which will be passed to the child form-controls to allow render gov service service description.
   */
  initialSelectGovServices: GovService[] = [];

  /**
   * Indicates that a drop-down field in the form is opened and therefore the table height should be expanded.
   */
  dropDownIsOpened: boolean;

  ngOnInit() {
    this.init();
  }

  /**
   * Initialize component data.
   */
  init() {}

  /**
   * Creates & adds a new gov service service form-group with validations.
   * @param  service The gov service service details to be bounded to the gov service , If omitted a blank journal will be created.
   */
  addGovService(service?: GovServiceItem) {
    const group = new FormGroup({
      advanceAccountId: new FormControl(service?.advanceAccountId),
      advanceAccount: new FormControl(service?.advanceAccount),
      expenseAccountId: new FormControl(service?.expenseAccountId),
      expenseAccount: new FormControl(service?.expenseAccount),
      govServiceId: new FormControl(service?.govServiceId),
      govService: new FormControl(service?.govService),
    });

    this.govServicesForm.push(group);
  }
}
