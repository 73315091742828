import { CustomersActionType, CustomersActions } from 'app-search/store/actions';
import { CustomersState } from 'app-search/store/states';

/**
 * The initial state from which the state starts.
 */
const initialState: CustomersState = {
  data: [],
  paginationInfo: {
    total: 0,
    page: 1,
    pageSize: 0,
    count: 0,
  },
  error: null,
  isSearching: false,
  isSearchCompleted: false,
};

/**
 * The reducer function that is called in each action dispatch against the state.
 * @param state The current state.
 * @param action The action that will affect the state.
 */
export function customersReducer(state: CustomersState = initialState, action: CustomersActions): CustomersState {
  switch (action.type) {
    //#region SEARCH_CUSTOMERS

    case CustomersActionType.SEARCH_CUSTOMERS: {
      return {
        ...state,
        isSearching: true,
        isSearchCompleted: false,
        error: null,
      };
    }

    case CustomersActionType.SEARCH_CUSTOMERS_FAIL: {
      return {
        ...state,
        error: action.payload,
        isSearching: false,
        isSearchCompleted: false,
      };
    }

    case CustomersActionType.SEARCH_CUSTOMERS_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        paginationInfo: {
          ...action.payload.meta,
        },
        error: null,
        isSearching: false,
        isSearchCompleted: true,
      };
    }

    //#endregion SEARCH_CUSTOMERS

    default:
      return state;
  }
}
