<!--Statistics cards for user Starts-->
<div class="card">
  <div class="card-content">
    <div class="px-3 pt-3">
      <div class="row">
        <div class="col-md-12">
          <!-- <div class="card-header mb-4 p-2">
            <h4 class="card-title font-weight-bold">
              {{ 'CORE.DASHBOARD.WELCOME' | translate }}
              <span>{{ currentLoggedInUser.name }}</span>
            </h4>
          </div> -->
          <div [formGroup]="searchForm">
            <div class="row px-3 mb-4">
              <div class="col-md-auto">
                <div class="form-group row mb-0">
                  <button
                    [ngClass]="{
                      'active-day': isDayButton,
                      'filter-btn': !isDayButton,
                      'rounded-right': currentLang === 'ar',
                      'rounded-left': currentLang === 'en'
                    }"
                    (click)="applyFiltersAndSearch('isDay')"
                    type="button"
                    class="btn btn-active col-md-auto"
                  >
                    <span class="filter-name">
                      {{ 'CORE.DASHBOARD.DAY' | translate }}
                    </span>
                  </button>
                  <button
                    [ngClass]="{
                      'active-day': isWeekButton,
                      'filter-btn': !isWeekButton
                    }"
                    (click)="applyFiltersAndSearch('isWeek')"
                    type="button"
                    class="btn btn-active active-day col-md-auto"
                  >
                    <span class="filter-name">
                      {{ 'CORE.DASHBOARD.WEEK' | translate }}
                    </span>
                  </button>
                  <button
                    [ngClass]="{
                      'active-day': isMonthButton,
                      'filter-btn': !isMonthButton,
                      'rounded-left': currentLang === 'ar',
                      'rounded-right': currentLang === 'en'
                    }"
                    (click)="applyFiltersAndSearch('isMonth')"
                    type="button"
                    class="btn btn-active active-day col-md-auto"
                  >
                    <span class="filter-name">
                      {{ 'CORE.DASHBOARD.MONTH' | translate }}
                    </span>
                  </button>
                </div>
              </div>
              <div class="col-md-3">
                <div class="input-group round">
                  <input
                    class="form-control"
                    id="date"
                    name="date"
                    type="text"
                    formControlName="date"
                    [placeholder]="'SHARED.SEARCH.DATE_PLACEHOLDER' | translate"
                    ngbDatepicker
                    #date="ngbDatepicker"
                    (dateSelect)="submit()"
                    (keyup.enter)="submit()"
                  />
                  <div class="input-group-append">
                    <span
                      class="input-group-text gradient-light-blue-indigo"
                      style="cursor: pointer;"
                      (click)="date.toggle()"
                    >
                      <i class="fa fa-calendar"></i>
                    </span>
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <button type="button" (click)="searchLocations()" class="btn btn-raised btn-primary round mx-1">
                  <i class="fa fa-filter"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <ul
            class="nav-tabs"
            ngbNav
            #nav="ngbNav"
            [(activeId)]="activePage"
            (activeIdChange)="selectedPageChanged($event)"
            [destroyOnHide]="false"
          >
            <li ngbNavItem="statistics">
              <a ngbNavLink>
                <i class="fa fa-pie-chart" [class.success]="activePage == 'statistics'"></i>
                {{ 'CORE.DASHBOARD.STATISTICS' | translate }}
              </a>

              <!-- statistics starts -->
              <ng-template ngbNavContent>
                <div class="row">
                  <div class="col-md-12">
                    <div class="card">
                      <div class="card-content">
                        <div class="row d-flex flex-row justify-content-between">
                          <ng-container *ngFor="let statistic of statisticsCharts; let index = index">
                            <div class="col-md-3 p-3" *ngIf="index <= 15">
                              <div class="p-3 chart-border rounded">
                                <h3>{{ statistic.name | localize: statistic.nameEn | async }}</h3>
                                <h3 class="font-weight-bold">
                                  {{
                                    statistic.total.toString().includes('.')
                                      ? (statistic.total | toDecimal)
                                      : (statistic.total | toNumber)
                                  }}
                                </h3>
                                <div *ngFor="let chart of statistic.statistics">
                                  <canvas
                                    baseChart
                                    width="300"
                                    height="300"
                                    [datasets]="chart.counts"
                                    [labels]="chart.dates"
                                    [colors]="lineChartColors"
                                    [legend]="lineChartLegend"
                                    [chartType]="lineChartType"
                                    [options]="chartsOptions"
                                  >
                                  </canvas>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-12 p-3" *ngIf="index === 16">
                              <div class="p-3 chart-border rounded">
                                <h3>{{ statistic.name | localize: statistic.nameEn | async }}</h3>
                                <div *ngFor="let chart of statistic.statistics">
                                  <canvas
                                    baseChart
                                    [datasets]="chart.counts"
                                    [labels]="chart.dates"
                                    [colors]="lineChartColorsForHourlySales"
                                    [legend]="lineChartLegend"
                                    [chartType]="lineChartTypeForHourlySales"
                                    [options]="chartsOptions"
                                  >
                                  </canvas>
                                </div>
                              </div>
                            </div>

                            <div class="col-md-4 p-3" *ngIf="index > 16">
                              <div class="p-3 chart-border rounded">
                                <h3>{{ statistic.name | localize: statistic.nameEn | async }}</h3>
                                <div *ngFor="let chart of statistic.statistics">
                                  <canvas
                                    baseChart
                                    width="300"
                                    height="300"
                                    [datasets]="chart.counts"
                                    [labels]="chart.dates"
                                    [colors]="lineChartColorsForTop"
                                    [legend]="true"
                                    [chartType]="lineChartTypeForTop"
                                    [options]="pieChartsOptions"
                                  >
                                  </canvas>
                                </div>
                              </div>
                            </div>
                          </ng-container>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-template>
              <!-- statistics ends -->
            </li>
            <ng-container *ngIf="statistics$ | async; let statistics">
              <li ngbNavItem="sales">
                <a ngbNavLink>
                  <i class="fa fa-opencart" [class.success]="activePage == 'sales'"></i>
                  {{ 'CORE.DASHBOARD.SALES' | translate }}
                </a>

                <!-- sales starts -->
                <ng-template ngbNavContent>
                  <div class="row pointsOfSale">
                    <div class="col-md-12">
                      <div class="card">
                        <div class="card-content">
                          <div class="row text-left">
                            <div class="col-xl-4 col-lg-6 col-12">
                              <div class="card">
                                <div class="card-content">
                                  <div class="px-3 py-3 background">
                                    <div class="media">
                                      <div class="media-body text-left">
                                        <h3 class="mb-1">
                                          {{
                                            (statistics.customersEngagements ? statistics.customersEngagements : 0)
                                              | toDecimal
                                          }}
                                        </h3>
                                        <span>{{ 'CORE.DASHBOARD.CUSTOMERS_ENGAGEMENTS' | translate }}</span>
                                      </div>
                                      <div class="media-right align-self-center">
                                        <i class="icon-pin primary font-large-2 float-right"></i>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-xl-4 col-lg-6 col-12">
                              <div class="card">
                                <div class="card-content">
                                  <div class="px-3 py-3 background">
                                    <div class="media">
                                      <div class="media-body text-left">
                                        <h3 class="mb-1">
                                          {{ (statistics.assets ? statistics.assets : 0) | toNumber }}
                                        </h3>
                                        <span>{{ 'CORE.DASHBOARD.ASSETS' | translate }}</span>
                                      </div>
                                      <div class="media-right align-self-center">
                                        <i class="icon-shield primary font-large-2 float-right"></i>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-xl-4 col-lg-6 col-12">
                              <div class="card">
                                <div class="card-content">
                                  <div class="px-3 py-3 background">
                                    <div class="media">
                                      <div class="media-body text-left">
                                        <h3 class="mb-1">
                                          {{
                                            (statistics.saleInvoicesCount ? statistics.saleInvoicesCount : 0) | toNumber
                                          }}
                                        </h3>
                                        <span>{{ 'CORE.DASHBOARD.SALES_INVOICES' | translate }}</span>
                                      </div>
                                      <div class="media-right align-self-center">
                                        <i class="fa fa-opencart primary font-large-2 float-right"></i>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-xl-4 col-lg-6 col-12">
                              <div class="card">
                                <div class="card-content">
                                  <div class="px-3 py-3 background">
                                    <div class="media">
                                      <div class="media-body text-left">
                                        <h3 class="mb-1">
                                          {{
                                            (statistics.saleInvoicesAmount ? statistics.saleInvoicesAmount : 0)
                                              | toDecimal
                                          }}
                                        </h3>
                                        <span>{{ 'CORE.DASHBOARD.SALES_INVOICES_AMOUNT' | translate }}</span>
                                      </div>
                                      <div class="media-right align-self-center">
                                        <i class="fa fa-opencart primary font-large-2 float-right"></i>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-xl-4 col-lg-6 col-12">
                              <div class="card">
                                <div class="card-content">
                                  <div class="px-3 py-3 background">
                                    <div class="media">
                                      <div class="media-body text-left">
                                        <h3 class="mb-1">
                                          {{ (statistics.ordersCount ? statistics.ordersCount : 0) | toNumber }}
                                        </h3>
                                        <span>
                                          {{ 'CORE.DASHBOARD.POINT_OF_SALE_INVOICES_COUNT' | translate }}
                                        </span>
                                      </div>
                                      <div class="media-right align-self-center">
                                        <i class="icon-chart primary font-large-2 float-right"></i>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-xl-4 col-lg-6 col-12">
                              <div class="card">
                                <div class="card-content">
                                  <div class="px-3 py-3 background">
                                    <div class="media">
                                      <div class="media-body text-left">
                                        <h3 class="mb-1">
                                          {{ (statistics.ordersAmount ? statistics.ordersAmount : 0) | toDecimal }}
                                        </h3>
                                        <span>{{ 'CORE.DASHBOARD.POINT_OF_SALE_INVOICES_AMOUNT' | translate }}</span>
                                      </div>
                                      <div class="media-right align-self-center">
                                        <i class="icon-social-dropbox primary font-large-2 float-right"></i>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-xl-4 col-lg-6 col-12">
                              <div class="card">
                                <div class="card-content">
                                  <div class="px-3 py-3 background">
                                    <div class="media">
                                      <div class="media-body text-left">
                                        <h3 class="mb-1">
                                          {{
                                            (statistics.regularInvoicesCount ? statistics.regularInvoicesCount : 0)
                                              | toNumber
                                          }}
                                        </h3>
                                        <span>{{ 'CORE.DASHBOARD.REGULAR_INVOICES_COUNT' | translate }}</span>
                                      </div>
                                      <div class="media-right align-self-center">
                                        <i class="fa fa-cart-plus primary font-large-2 float-right"></i>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-xl-4 col-lg-6 col-12">
                              <div class="card">
                                <div class="card-content">
                                  <div class="px-3 py-3 background">
                                    <div class="media">
                                      <div class="media-body text-left">
                                        <h3 class="mb-1">
                                          {{
                                            (statistics.regularInvoicesAmount ? statistics.regularInvoicesAmount : 0)
                                              | toDecimal
                                          }}
                                        </h3>
                                        <span>{{ 'CORE.DASHBOARD.REGULAR_INVOICES_AMOUNT' | translate }}</span>
                                      </div>
                                      <div class="media-right align-self-center">
                                        <i class="fa fa-cart-plus primary font-large-2 float-right"></i>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-xl-4 col-lg-6 col-12">
                              <div class="card">
                                <div class="card-content">
                                  <div class="px-3 py-3 background">
                                    <div class="media">
                                      <div class="media-body text-left">
                                        <h3 class="mb-1">
                                          {{
                                            (statistics.maintenanceInvoicesCount
                                              ? statistics.maintenanceInvoicesCount
                                              : 0
                                            ) | toNumber
                                          }}
                                        </h3>
                                        <span>{{ 'CORE.DASHBOARD.MAINTENANCE_INVOICES_COUNT' | translate }}</span>
                                      </div>
                                      <div class="media-right align-self-center">
                                        <i class="fa fa-cart-plus primary font-large-2 float-right"></i>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-xl-4 col-lg-6 col-12">
                              <div class="card">
                                <div class="card-content">
                                  <div class="px-3 py-3 background">
                                    <div class="media">
                                      <div class="media-body text-left">
                                        <h3 class="mb-1">
                                          {{
                                            (statistics.maintenanceInvoicesAmount
                                              ? statistics.maintenanceInvoicesAmount
                                              : 0
                                            ) | toDecimal
                                          }}
                                        </h3>
                                        <span>{{ 'CORE.DASHBOARD.MAINTENANCE_INVOICES_AMOUNT' | translate }}</span>
                                      </div>
                                      <div class="media-right align-self-center">
                                        <i class="fa fa-cart-plus primary font-large-2 float-right"></i>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-xl-4 col-lg-6 col-12">
                              <div class="card">
                                <div class="card-content">
                                  <div class="px-3 py-3 background">
                                    <div class="media">
                                      <div class="media-body text-left">
                                        <h3 class="mb-1">
                                          {{
                                            (statistics.openingBalanceInvoicesCount
                                              ? statistics.openingBalanceInvoicesCount
                                              : 0
                                            ) | toNumber
                                          }}
                                        </h3>
                                        <span>{{ 'CORE.DASHBOARD.OPENING_INVOICES_COUNT' | translate }}</span>
                                      </div>
                                      <div class="media-right align-self-center">
                                        <i class="fa fa-cart-plus primary font-large-2 float-right"></i>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-xl-4 col-lg-6 col-12">
                              <div class="card">
                                <div class="card-content">
                                  <div class="px-3 py-3 background">
                                    <div class="media">
                                      <div class="media-body text-left">
                                        <h3 class="mb-1">
                                          {{
                                            (statistics.openingBalanceInvoicesAmount
                                              ? statistics.openingBalanceInvoicesAmount
                                              : 0
                                            ) | toDecimal
                                          }}
                                        </h3>
                                        <span>{{ 'CORE.DASHBOARD.OPENING_INVOICES_AMOUNT' | translate }}</span>
                                      </div>
                                      <div class="media-right align-self-center">
                                        <i class="fa fa-cart-plus primary font-large-2 float-right"></i>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-xl-4 col-lg-6 col-12">
                              <div class="card">
                                <div class="card-content">
                                  <div class="px-3 py-3 background">
                                    <div class="media">
                                      <div class="media-body text-left">
                                        <h3 class="mb-1">
                                          {{
                                            (statistics.saleReturnsCount ? statistics.saleReturnsCount : 0) | toNumber
                                          }}
                                        </h3>
                                        <span>{{ 'CORE.DASHBOARD.SALES_RETURNS' | translate }}</span>
                                      </div>
                                      <div class="media-right align-self-center">
                                        <i class="fa fa-cart-plus primary font-large-2 float-right"></i>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-xl-4 col-lg-6 col-12">
                              <div class="card">
                                <div class="card-content">
                                  <div class="px-3 py-3 background">
                                    <div class="media">
                                      <div class="media-body text-left">
                                        <h3 class="mb-1">
                                          {{
                                            (statistics.saleReturnsAmount ? statistics.saleReturnsAmount : 0)
                                              | toDecimal
                                          }}
                                        </h3>
                                        <span>{{ 'CORE.DASHBOARD.SALES_RETURNS_AMOUNT' | translate }}</span>
                                      </div>
                                      <div class="media-right align-self-center">
                                        <i class="fa fa-cart-plus primary font-large-2 float-right"></i>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-template>
                <!-- sales ends -->
              </li>
              <li ngbNavItem="points-of-sale">
                <a ngbNavLink>
                  <i class="fa fa-desktop" [class.success]="activePage == 'points-of-sale'"></i>
                  {{ 'CORE.DASHBOARD.POINT_OF_SALE' | translate }}
                </a>

                <!-- points of sale starts -->
                <ng-template ngbNavContent>
                  <div class="row pointsOfSale">
                    <div class="col-md-12">
                      <div class="card">
                        <div class="card-content">
                          <div class="row text-left">
                            <div class="col-xl-4 col-lg-6 col-12">
                              <div class="card">
                                <div class="card-content">
                                  <div class="px-3 py-3 background">
                                    <div class="media">
                                      <div class="media-body text-left">
                                        <h3 class="mb-1">
                                          {{ (statistics.ordersCount ? statistics.ordersCount : 0) | toNumber }}
                                        </h3>
                                        <span>
                                          {{ 'CORE.DASHBOARD.ORDERS_COUNT' | translate }}
                                        </span>
                                      </div>
                                      <div class="media-right align-self-center">
                                        <i class="icon-chart primary font-large-2 float-right"></i>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-xl-4 col-lg-6 col-12">
                              <div class="card">
                                <div class="card-content">
                                  <div class="px-3 py-3 background">
                                    <div class="media">
                                      <div class="media-body text-left">
                                        <h3 class="mb-1">
                                          {{ (statistics.ordersAmount ? statistics.ordersAmount : 0) | toDecimal }}
                                        </h3>
                                        <span>{{ 'CORE.DASHBOARD.ORDERS_AMOUNT' | translate }}</span>
                                      </div>
                                      <div class="media-right align-self-center">
                                        <i class="icon-social-dropbox primary font-large-2 float-right"></i>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-template>
                <!-- points of sale ends -->
              </li>
              <li ngbNavItem="purchases">
                <a ngbNavLink>
                  <i class="fa fa-cart-plus" [class.success]="activePage == 'purchases'"></i>
                  {{ 'CORE.DASHBOARD.PURCHASES' | translate }}
                </a>

                <!-- purchases starts -->
                <ng-template ngbNavContent>
                  <div class="row pointsOfSale">
                    <div class="col-md-12">
                      <div class="card">
                        <div class="card-content">
                          <div class="row text-left">
                            <div class="col-xl-4 col-lg-6 col-12">
                              <div class="card">
                                <div class="card-content">
                                  <div class="px-3 py-3 background">
                                    <div class="media">
                                      <div class="media-body text-left">
                                        <h3 class="mb-1">
                                          {{
                                            (statistics.purchaseRequestsCount ? statistics.purchaseRequestsCount : 0)
                                              | toNumber
                                          }}
                                        </h3>
                                        <span>
                                          {{ 'CORE.DASHBOARD.PURCHASES_REQUESTS' | translate }}
                                        </span>
                                      </div>
                                      <div class="media-right align-self-center">
                                        <i class="fa fa-bullhorn primary font-large-2 float-right"></i>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-xl-4 col-lg-6 col-12">
                              <div class="card">
                                <div class="card-content">
                                  <div class="px-3 py-3 background">
                                    <div class="media">
                                      <div class="media-body text-left">
                                        <h3 class="mb-1">
                                          {{
                                            (statistics.purchaseRequestsAmount ? statistics.purchaseRequestsAmount : 0)
                                              | toDecimal
                                          }}
                                        </h3>
                                        <span>
                                          {{ 'CORE.DASHBOARD.PURCHASES_REQUESTS_AMOUNT' | translate }}
                                        </span>
                                      </div>
                                      <div class="media-right align-self-center">
                                        <i class="fa fa-bullhorn primary font-large-2 float-right"></i>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-xl-4 col-lg-6 col-12">
                              <div class="card">
                                <div class="card-content">
                                  <div class="px-3 py-3 background">
                                    <div class="media">
                                      <div class="media-body text-left">
                                        <h3 class="mb-1">
                                          {{
                                            (statistics.purchaseOrdersCount ? statistics.purchaseOrdersCount : 0)
                                              | toNumber
                                          }}
                                        </h3>
                                        <span>
                                          {{ 'CORE.DASHBOARD.PURCHASES_ORDERS' | translate }}
                                        </span>
                                      </div>
                                      <div class="media-right align-self-center">
                                        <i class="fa fa-clipboard primary font-large-2 float-right"></i>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-xl-4 col-lg-6 col-12">
                              <div class="card">
                                <div class="card-content">
                                  <div class="px-3 py-3 background">
                                    <div class="media">
                                      <div class="media-body text-left">
                                        <h3 class="mb-1">
                                          {{
                                            (statistics.purchaseOrdersAmount ? statistics.purchaseOrdersAmount : 0)
                                              | toDecimal
                                          }}
                                        </h3>
                                        <span>
                                          {{ 'CORE.DASHBOARD.PURCHASES_ORDERS_AMOUNT' | translate }}
                                        </span>
                                      </div>
                                      <div class="media-right align-self-center">
                                        <i class="fa fa-clipboard primary font-large-2 float-right"></i>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-xl-4 col-lg-6 col-12">
                              <div class="card">
                                <div class="card-content">
                                  <div class="px-3 py-3 background">
                                    <div class="media">
                                      <div class="media-body text-left">
                                        <h3 class="mb-1">
                                          {{
                                            (statistics.purchaseReturnsCount ? statistics.purchaseReturnsCount : 0)
                                              | toNumber
                                          }}
                                        </h3>
                                        <span>
                                          {{ 'CORE.DASHBOARD.PURCHASE_RETURNS' | translate }}
                                        </span>
                                      </div>
                                      <div class="media-right align-self-center">
                                        <i class="fa fa-cart-plus primary font-large-2 float-right"></i>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-xl-4 col-lg-6 col-12">
                              <div class="card">
                                <div class="card-content">
                                  <div class="px-3 py-3 background">
                                    <div class="media">
                                      <div class="media-body text-left">
                                        <h3 class="mb-1">
                                          {{
                                            (statistics.purchaseReturnsAmount ? statistics.purchaseReturnsAmount : 0)
                                              | toDecimal
                                          }}
                                        </h3>
                                        <span>
                                          {{ 'CORE.DASHBOARD.PURCHASE_RETURNS_AMOUNT' | translate }}
                                        </span>
                                      </div>
                                      <div class="media-right align-self-center">
                                        <i class="fa fa-cart-plus primary font-large-2 float-right"></i>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-template>
                <!-- purchases ends -->
              </li>
              <li ngbNavItem="stores">
                <a ngbNavLink>
                  <i class="fa fa-cube" [class.success]="activePage == 'stores'"></i>
                  {{ 'CORE.DASHBOARD.STORES' | translate }}
                </a>

                <!-- stores starts -->
                <ng-template ngbNavContent>
                  <div class="row pointsOfSale">
                    <div class="col-md-12">
                      <div class="card">
                        <div class="card-content">
                          <div class="row text-left">
                            <div class="col-xl-4 col-lg-6 col-12">
                              <div class="card">
                                <div class="card-content">
                                  <div class="px-3 py-3 background">
                                    <div class="media">
                                      <div class="media-body text-left">
                                        <h3 class="mb-1">
                                          {{ (statistics.locations ? statistics.locations : 0) | toNumber }}
                                        </h3>
                                        <span>{{ 'CORE.DASHBOARD.LOCATIONS' | translate }}</span>
                                      </div>
                                      <div class="media-right align-self-center">
                                        <i class="icon-location-pin primary font-large-2 float-right"></i>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-xl-4 col-lg-6 col-12">
                              <div class="card">
                                <div class="card-content">
                                  <div class="px-3 py-3 background">
                                    <div class="media">
                                      <div class="media-body text-left">
                                        <h3 class="mb-1">
                                          {{ (statistics.productClasses ? statistics.productClasses : 0) | toNumber }}
                                        </h3>
                                        <span>
                                          {{ 'CORE.DASHBOARD.PRODUCT_CLASSES' | translate }}
                                        </span>
                                      </div>
                                      <div class="media-right align-self-center">
                                        <i class="icon-grid primary font-large-2 float-right"></i>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-xl-4 col-lg-6 col-12">
                              <div class="card">
                                <div class="card-content">
                                  <div class="px-3 py-3 background">
                                    <div class="media">
                                      <div class="media-body text-left">
                                        <h3 class="mb-1">
                                          {{ (statistics.products ? statistics.products : 0) | toNumber }}
                                        </h3>
                                        <span>
                                          {{ 'CORE.DASHBOARD.PRODUCTS' | translate }}
                                        </span>
                                      </div>
                                      <div class="media-right align-self-center">
                                        <i class="fa fa-cubes primary font-large-2 float-right"></i>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-xl-4 col-lg-6 col-12">
                              <div class="card">
                                <div class="card-content">
                                  <div class="px-3 py-3 background">
                                    <div class="media">
                                      <div class="media-body text-left">
                                        <h3 class="mb-1">
                                          {{ (statistics.incomingStocks ? statistics.incomingStocks : 0) | toNumber }}
                                        </h3>
                                        <span>
                                          {{ 'CORE.DASHBOARD.INCOMING_STOCKS' | translate }}
                                        </span>
                                      </div>
                                      <div class="media-right align-self-center">
                                        <i class="fa fa-cart-plus primary font-large-2 float-right"></i>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-xl-4 col-lg-6 col-12">
                              <div class="card">
                                <div class="card-content">
                                  <div class="px-3 py-3 background">
                                    <div class="media">
                                      <div class="media-body text-left">
                                        <h3 class="mb-1">
                                          {{ (statistics.transferStocks ? statistics.transferStocks : 0) | toNumber }}
                                        </h3>
                                        <span>
                                          {{ 'CORE.DASHBOARD.TRANSFER_STOCKS' | translate }}
                                        </span>
                                      </div>
                                      <div class="media-right align-self-center">
                                        <i class="fa fa-cart-plus primary font-large-2 float-right"></i>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-xl-4 col-lg-6 col-12">
                              <div class="card">
                                <div class="card-content">
                                  <div class="px-3 py-3 background">
                                    <div class="media">
                                      <div class="media-body text-left">
                                        <h3 class="mb-1">
                                          {{ (statistics.outgoingStocks ? statistics.outgoingStocks : 0) | toNumber }}
                                        </h3>
                                        <span>
                                          {{ 'CORE.DASHBOARD.OUTGOING_STOCKS' | translate }}
                                        </span>
                                      </div>
                                      <div class="media-right align-self-center">
                                        <i class="fa fa-cart-plus primary font-large-2 float-right"></i>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-template>
                <!-- stores ends -->
              </li>
              <li ngbNavItem="finances">
                <a ngbNavLink>
                  <i class="fa fa-usd" [class.success]="activePage == 'finances'"></i>
                  {{ 'CORE.DASHBOARD.FINANCES' | translate }}
                </a>

                <!-- finances starts -->
                <ng-template ngbNavContent>
                  <div class="row pointsOfSale">
                    <div class="col-md-12">
                      <div class="card">
                        <div class="card-content">
                          <div class="row text-left">
                            <div class="col-xl-4 col-lg-6 col-12">
                              <div class="card">
                                <div class="card-content">
                                  <div class="px-3 py-3 background">
                                    <div class="media">
                                      <div class="media-body text-left">
                                        <h3 class="mb-1">
                                          {{
                                            (statistics.purchaseInvoicesCount ? statistics.purchaseInvoicesCount : 0)
                                              | toNumber
                                          }}
                                        </h3>
                                        <span>{{ 'CORE.DASHBOARD.PURCHASES_INVOICES' | translate }}</span>
                                      </div>
                                      <div class="media-right align-self-center">
                                        <i class="fa fa-cart-plus primary font-large-2 float-right"></i>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-xl-4 col-lg-6 col-12">
                              <div class="card">
                                <div class="card-content">
                                  <div class="px-3 py-3 background">
                                    <div class="media">
                                      <div class="media-body text-left">
                                        <h3 class="mb-1">
                                          {{
                                            (statistics.purchaseInvoicesAmount ? statistics.purchaseInvoicesAmount : 0)
                                              | toDecimal
                                          }}
                                        </h3>
                                        <span>{{ 'CORE.DASHBOARD.PURCHASES_INVOICES_AMOUNT' | translate }}</span>
                                      </div>
                                      <div class="media-right align-self-center">
                                        <i class="fa fa-cart-plus primary font-large-2 float-right"></i>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-xl-4 col-lg-6 col-12">
                              <div class="card">
                                <div class="card-content">
                                  <div class="px-3 py-3 background">
                                    <div class="media">
                                      <div class="media-body text-left">
                                        <h3 class="mb-1">
                                          {{ (statistics.vendorPayments ? statistics.vendorPayments : 0) | toDecimal }}
                                        </h3>
                                        <span>{{ 'CORE.DASHBOARD.VENDORS_PAYMENTS' | translate }}</span>
                                      </div>
                                      <div class="media-right align-self-center">
                                        <i class="fa fa-usd primary font-large-2 float-right"></i>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-xl-4 col-lg-6 col-12">
                              <div class="card">
                                <div class="card-content">
                                  <div class="px-3 py-3 background">
                                    <div class="media">
                                      <div class="media-body text-left">
                                        <h3 class="mb-1">
                                          {{
                                            (statistics.customerPayments ? statistics.customerPayments : 0) | toDecimal
                                          }}
                                        </h3>
                                        <span>{{ 'CORE.DASHBOARD.CUSTOMER_PAYMENTS' | translate }}</span>
                                      </div>
                                      <div class="media-right align-self-center">
                                        <i class="fa fa-usd primary font-large-2 float-right"></i>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-xl-4 col-lg-6 col-12">
                              <div class="card">
                                <div class="card-content">
                                  <div class="px-3 py-3 background">
                                    <div class="media">
                                      <div class="media-body text-left">
                                        <h3 class="mb-1">
                                          {{ (statistics.costCenters ? statistics.costCenters : 0) | toNumber }}
                                        </h3>
                                        <span>
                                          {{ 'CORE.DASHBOARD.COST_CENTERS' | translate }}
                                        </span>
                                      </div>
                                      <div class="media-right align-self-center">
                                        <i class="icon-vector primary font-large-2 float-right"></i>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-template>
                <!-- finances ends -->
              </li>
              <li ngbNavItem="users">
                <a ngbNavLink>
                  <i class="fa fa-users" [class.success]="activePage == 'users'"></i>
                  {{ 'CORE.DASHBOARD.USERS' | translate }}
                </a>

                <!-- users starts -->
                <ng-template ngbNavContent>
                  <div class="row pointsOfSale">
                    <div class="col-md-12">
                      <div class="card">
                        <div class="card-content">
                          <div class="row text-left">
                            <div class="col-xl-4 col-lg-6 col-12">
                              <div class="card">
                                <div class="card-content">
                                  <div class="px-3 py-3 background">
                                    <div class="media">
                                      <div class="media-body text-left">
                                        <h3 class="mb-1">
                                          {{ (statistics.usersCount ? statistics.usersCount : 0) | toNumber }}
                                        </h3>
                                        <span>{{ 'CORE.DASHBOARD.ORGANIZATION_USERS_COUNT' | translate }}</span>
                                      </div>
                                      <div class="media-right align-self-center">
                                        <i class="fa fa-users primary font-large-2 float-right"></i>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-xl-4 col-lg-6 col-12">
                              <div class="card">
                                <div class="card-content">
                                  <div class="px-3 py-3 background">
                                    <div class="media">
                                      <div class="media-body text-left">
                                        <h3 class="mb-1">
                                          {{
                                            (statistics.activeOrgUsersCount ? statistics.activeOrgUsersCount : 0)
                                              | toNumber
                                          }}
                                        </h3>
                                        <span>{{ 'CORE.DASHBOARD.ACTIVE_USERS_COUNT' | translate }}</span>
                                      </div>
                                      <div class="media-right align-self-center">
                                        <i class="icon-user-following primary font-large-2 float-right"></i>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-xl-4 col-lg-6 col-12">
                              <div class="card">
                                <div class="card-content">
                                  <div class="px-3 py-3 background">
                                    <div class="media">
                                      <div class="media-body text-left">
                                        <h3 class="mb-1">
                                          {{
                                            (statistics.blockedOrgUsersCount ? statistics.blockedOrgUsersCount : 0)
                                              | toNumber
                                          }}
                                        </h3>
                                        <span>{{ 'CORE.DASHBOARD.BLOCKED_USERS_COUNT' | translate }}</span>
                                      </div>
                                      <div class="media-right align-self-center">
                                        <i class="icon-user-unfollow primary font-large-2 float-right"></i>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-template>
                <!-- users ends -->
              </li>
            </ng-container>
          </ul>
          <div [ngbNavOutlet]="nav"></div>
        </div>
      </div>
    </div>
  </div>
</div>
<!--Statistics cards for user End-->

<!-- search location modal starts -->
<ng-template #searchModalRef let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title text-primary" id="modal-basic-title">
      {{ 'CORE.DASHBOARD.LOCATIONS' | translate }}
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="d()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-md-12">
        <form [formGroup]="searchForm" (ngSubmit)="submit()">
          <div class="row">
            <div class="form-group col-md-12">
              <label for="locations">
                {{ 'CORE.DASHBOARD.DASHBOARD_DATA.LOCATIONS' | translate }}
              </label>
              <ng-select
                class="round"
                formControlName="locations"
                [multiple]="true"
                [closeOnSelect]="false"
                labelForId="locations"
                searchable="true"
                [placeholder]="'CORE.DASHBOARD.DASHBOARD_DATA.LOCATION_PLACEHOLDER' | translate"
                [notFoundText]="'SHARED.SEARCH.SEARCH_NOT_FOUND' | translate"
                (open)="locationsListVisibility = true"
              >
                <ng-option *ngFor="let location of locations" [value]="location.id">
                  {{ location.name | localize: location.nameEn | async }}
                </ng-option>
              </ng-select>
            </div>
            <!-- A hidden submit button to allow user submit form with Enter -->
            <button type="submit" hidden></button>
          </div>
        </form>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="modal-actions">
      <button type="button" class="btn btn-raised btn-primary round" (click)="submit()" ngbAutofocus>
        <i class="fa fa-check"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.SEARCH' | translate }}</span>
      </button>
      <button type="button" class="btn btn-outline-dark round" (click)="c()">
        <i class="fa fa-times"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.CANCEL' | translate }}</span>
      </button>
    </div>
  </div>
</ng-template>
<!-- search location modal ends -->

<!-- locations search starts -->
<app-locations-search [(visible)]="locationsListVisibility" (locations)="selectLocations($event)">
</app-locations-search>
<!-- locations search ends -->
