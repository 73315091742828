import { Action } from '@ngrx/store';

import { Vendor } from 'purchases/models';
import { AppHttpResponse } from 'shared';

/**
 * The App-Search-Module store vendors action types.
 */
export enum VendorsActionType {
  SEARCH_VENDORS = '[AppSearch] [Vendors] Search Vendors',
  SEARCH_VENDORS_FAIL = '[AppSearch] [Vendors] Search Vendors Fail',
  SEARCH_VENDORS_SUCCESS = '[AppSearch] [Vendors] Search Vendors Success',
}

/**
 * Represents a store vendors search action.
 */
export class SearchVendors implements Action {
  /**
   * The type of the action.
   */
  readonly type = VendorsActionType.SEARCH_VENDORS;

  /**
   * Fires a new vendors search action.
   * @param payload the search parameters,
   * if omitted, all vendors will be loaded.
   */
  constructor(public payload?: { name: string; page: number }) {}
}

/**
 * Represents a store vendors search-fail action.
 */
export class SearchVendorsFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = VendorsActionType.SEARCH_VENDORS_FAIL;

  /**
   * Fires a new vendors search-fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store search-success action.
 */
export class SearchVendorsSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = VendorsActionType.SEARCH_VENDORS_SUCCESS;

  /**
   * Fires a new vendors search-success action.
   * @param payload An object contains the list of vendors that matches the search criteria
   * in addition to the pagination info.
   */
  constructor(public payload: AppHttpResponse<Vendor[]>) {}
}
/**
 * App-Search-module vendors action types.
 */
export type VendorsActions = SearchVendors | SearchVendorsFail | SearchVendorsSuccess;
