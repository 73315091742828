import { Component, ElementRef, Input, ViewChild } from '@angular/core';

import { GovServiceRequest, GovServiceRequestEmployee } from 'hr/models';
import { orderBy } from 'lodash';

@Component({
  selector: 'app-journal-gov-service-request-details',
  templateUrl: './journal-gov-service-request-details.component.html',
  styles: [],
})
export class JournalGovServiceRequestDetailsComponent {
  /**
   * Sets the current viewed journal gov service request.
   */
  @Input() govServiceRequest: GovServiceRequest;

  /**
   * Get selected gov service request.
   */
  selectedGovServiceRequest: GovServiceRequest;

  /**
   * Orders the given list according to employee person number.
   * @param data The data to be ordered.
   * @returns The order list.
   */
  orderEmployees(data: GovServiceRequestEmployee[]): GovServiceRequestEmployee[] {
    return orderBy(data, (item) => item.employee?.employeeAssignmentDetail.personNumber);
  }
}
