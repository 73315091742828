import { Injectable } from '@angular/core';

import { of } from 'rxjs';
import { switchMap, map, catchError } from 'rxjs/operators';
import { Effect, ofType, Actions } from '@ngrx/effects';

import { LookupsService } from 'lookups/services';
import { AssetTypeActionType, GetAllAssetTypeFail, GetAllAssetTypeSuccess } from 'lookups/store/actions';

@Injectable()
export class AssetTypeEffects {
  constructor(private actions$: Actions, private lookupsService: LookupsService) {}

  /* ========================= GET_ALL_ASSET_TYPE =================================== */
  @Effect()
  getAllAssetTypes$ = this.actions$.pipe(
    ofType(AssetTypeActionType.GET_ALL_ASSET_TYPE),
    switchMap(() =>
      this.lookupsService.getAllAssetTypes().pipe(
        map((response) => new GetAllAssetTypeSuccess(response)),
        catchError((error) => of(new GetAllAssetTypeFail(error)))
      )
    )
  );
}
