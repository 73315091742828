import { AuthGuard } from 'auth/guards/auth-guard.service';
import { AuthorizeGuard } from 'auth/guards/authorize-guard.service';
import { ConfirmationGuard } from 'auth/guards/confirmation-guard.service';

export * from 'auth/guards/auth-guard.service';
export * from 'auth/guards/authorize-guard.service';
export * from 'auth/guards/confirmation-guard.service';

/**
 * The set of guards registered on this module.
 */
export const GUARDS: any[] = [AuthGuard, AuthorizeGuard,ConfirmationGuard];
