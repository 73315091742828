import { createSelector } from '@ngrx/store';

import { selectLookupsState } from 'lookups/store/selectors/feature.selectors';
import { LookupsState, DepartmentCategoryState } from 'lookups/store/states';

/**
 * Gets the department category state.
 */
const selectDepartmentCategories = createSelector(
  selectLookupsState,
  (state: LookupsState) => state.departmentCategories
);

/**
 * Gets the list of loaded department categories.
 */
export const getDepartmentCategories = createSelector(
  selectDepartmentCategories,
  (state: DepartmentCategoryState) => state.data
);

/**
 * Gets the error that occurred in the state.
 */
export const getDepartmentCategoriesError = createSelector(
  selectDepartmentCategories,
  (state: DepartmentCategoryState) => state.error
);

/**
 * Determines if there is a running load process.
 */
export const getDepartmentCategoriesIsLoading = createSelector(
  selectDepartmentCategories,
  (state: DepartmentCategoryState) => state.isLoading
);

/**
 * Determines if the last load process has been ended successfully.
 */
export const getDepartmentCategoriesIsLoaded = createSelector(
  selectDepartmentCategories,
  (state: DepartmentCategoryState) => state.isLoaded
);
