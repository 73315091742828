import { Report } from 'reports/models';

/**
 * Represents the sales reports state.
 */
export class SalesReportsState {
  /**
   * The loaded report.
   */
  public customerSummary: Report;

  /**
   * The loaded report.
   */
  public customersDebts: Report;

  /**
   * The loaded report.
   */
  public customersPayments: Report;

  /**
   * loaded report.
   */
  public customersPoints: Report;

  /**
   * The loaded report.
   */
  public grossProfit: Report;

  /**
   * The loaded report.
   */
  public maintenanceStageInvoice: Report;

  /**
   * The loaded report.
   */
  public topReturnedSalesProducts: Report;

  /**
   * The loaded report.
   */
  public netSales: Report;

  /**
   * The loaded report.
   */
  public productSales: Report;

  /**
   * The loaded report.
   */
  public productSalesReturns: Report;

  /**
   * The loaded report.
   */
  public productClassesSales: Report;

  /**
   * The loaded report.
   */
  public productsSales: Report;

  /**
   * The loaded report.
   */
  public productsSalesReturns: Report;

  /**
   * The loaded report.
   */
  public salesPerson: Report;

  /**
   * The loaded report.
   */
  public salesInvoices: Report;

  /**
   * The loaded report.
   */
  public salesReturns: Report;

  /**
   * The loaded report.
   */
  public salesSummary: Report;

  /**
   * The loaded report.
   */
  public shiftSummary: Report;

  /**
   * The loaded report.
   */
  public topCustomers: Report;

  /**
   * The loaded report.
   */
  public topSellingProducts: Report;

  /**
   * The loaded report.
   */
  public worstCustomers: Report;

  /**
   * The loaded report.
   */
  public worstReturnedSalesProducts: Report;

  /**
   * The loaded report.
   */
  public worstSellingProducts: Report;

  /**
   * An error that may resulting during processing some actions.
   */
  public error: any;

  /**
   * Determines if there is a running load process.
   */
  public isLoadingCustomerSummary: boolean;

  /**
   * Determines if the last load process has been ended successfully.
   */
  public isCustomerSummaryLoadCompleted: boolean;

  /**
   * Determines if there is a running load process.
   */
  public isLoadingCustomersDebts: boolean;

  /**
   * Determines if the last load process has been ended successfully.
   */
  public isCustomersDebtsLoadCompleted: boolean;

  /**
   * Determines if there is a running load process.
   */
  public isLoadingCustomersPayments: boolean;

  /**
   * Determines if the last load process has been ended successfully.
   */
  public isCustomersPaymentsLoadCompleted: boolean;

  /**
   * Determines if there is a running load process.
   */
  public isLoadingGrossProfit: boolean;

  /**
   * Determines if the last load process has been ended successfully.
   */
  public isGrossProfitLoadCompleted: boolean;

  /**
   * Determines if there is a running load process.
   */
  public isLoadingMaintenanceStageInvoice: boolean;

  /**
   * Determines if the last load process has been ended successfully.
   */
  public isMaintenanceStageInvoiceLoadCompleted: boolean;

  /**
   * Determines if there is a running load process.
   */
  public isLoadingTopReturnedSalesProducts: boolean;

  /**
   * Determines if the last load process has been ended successfully.
   */
  public isTopReturnedSalesProductsLoadCompleted: boolean;

  /**
   * Determines if there is a running load process.
   */
  public isLoadingCustomersPoints: boolean;

  /**
   * Determines if the last load process has been ended successfully.
   */
  public isCustomersPointsLoadCompleted: boolean;

  /**
   * Determines if there is a running load process.
   */
  public isLoadingNetSales: boolean;

  /**
   * Determines if the last load process has been ended successfully.
   */
  public isNetSalesLoadCompleted: boolean;

  /**
   * Determines if there is a running load process.
   */
  public isLoadingProductSales: boolean;

  /**
   * Determines if the last load process has been ended successfully.
   */
  public isProductSalesLoadCompleted: boolean;

  /**
   * Determines if there is a running load process.
   */
  public isLoadingProductSalesReturns: boolean;

  /**
   * Determines if the last load process has been ended successfully.
   */
  public isProductSalesReturnsLoadCompleted: boolean;

  /**
   * Determines if there is a running load process.
   */
  public isLoadingProductClassesSales: boolean;

  /**
   * Determines if the last load process has been ended successfully.
   */
  public isProductClassesSalesLoadCompleted: boolean;

  /**
   * Determines if there is a running load process.
   */
  public isLoadingProductsSales: boolean;

  /**
   * Determines if the last load process has been ended successfully.
   */
  public isProductsSalesLoadCompleted: boolean;

  /**
   * Determines if there is a running load process.
   */
  public isLoadingProductsSalesReturns: boolean;

  /**
   * Determines if the last load process has been ended successfully.
   */
  public isProductsSalesReturnsLoadCompleted: boolean;

  /**
   * Determines if there is a running load process.
   */
  public isLoadingSalesInvoices: boolean;

  /**
   * Determines if the last load process has been ended successfully.
   */
  public isSalesInvoicesLoadCompleted: boolean;

  /**
   * Determines if there is a running load process.
   */
  public isLoadingSalesReturns: boolean;

  /**
   * Determines if the last load process has been ended successfully.
   */
  public isSalesReturnsLoadCompleted: boolean;

  /**
   * Determines if there is a running load process.
   */
  public isLoadingSalesSummary: boolean;

  /**
   * Determines if the last load process has been ended successfully.
   */
  public isSalesSummaryLoadCompleted: boolean;

  /**
   * Determines if there is a running load process.
   */
  public isLoadingShiftSummary: boolean;

  /**
   * Determines if the last load process has been ended successfully.
   */
  public isShiftSummaryLoadCompleted: boolean;

  /**
   * Determines if there is a running load process.
   */
  public isLoadingTopCustomers: boolean;

  /**
   * Determines if the last load process has been ended successfully.
   */
  public isTopCustomersLoadCompleted: boolean;

  /**
   * Determines if there is a running load process.
   */
  public isLoadingTopSellingProducts: boolean;

  /**
   * Determines if the last load process has been ended successfully.
   */
  public isTopSellingProductsLoadCompleted: boolean;

  /**
   * Determines if there is a running load process.
   */
  public isLoadingWorstCustomers: boolean;

  /**
   * Determines if the last load process has been ended successfully.
   */
  public isWorstCustomersLoadCompleted: boolean;

  /**
   * Determines if there is a running load process.
   */
  public isLoadingWorstReturnedSalesProducts: boolean;

  /**
   * Determines if the last load process has been ended successfully.
   */
  public isWorstReturnedSalesProductsLoadCompleted: boolean;

  /**
   * Determines if there is a running load process.
   */
  public isLoadingWorstSellingProducts: boolean;

  /**
   * Determines if the last load process has been ended successfully.
   */
  public isWorstSellingProductsLoadCompleted: boolean;

  /**
   * Determines if there is a running load process.
   */
  public isLoadingSalesPerson: boolean;

  /**
   * Determines if the last load process has been ended successfully.
   */
  public isSalesPersonLoadCompleted: boolean;
}
