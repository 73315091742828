import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms';

import { Subscription, Observable } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import * as fromStoresStore from 'stores/store';
import { PageInfo, NotificationService, NotificationMessage, TranslationService, CustomValidators } from 'shared';
import { LocationFormItem } from 'stores/models';

@Component({
  selector: 'app-create-product-class',
  templateUrl: './create-product-class.component.html',
  styles: [],
})
export class CreateProductClassComponent implements OnInit, OnDestroy {
  /**
   * The confirm modal template reference.
   */
  @ViewChild('confirmModalRef') confirmModalRef: ElementRef<any>;

  /**
   * Gets or sets the information about the current page.
   */
  pageInfo: PageInfo = {
    title: 'STORES.PRODUCT_CLASSES.ADD_PRODUCT_CLASS_PAGE_TITLE',

    icon: 'fa fa-plus',
  };

  /**
   * The list of active locations.
   */
  locations: Location[] = [];

  /**
   * Gets the locations form-array.
   */
  get locationsForm(): FormArray {
    return this.form?.controls.locations as FormArray;
  }

  /**
   * Indicates whether there is a create-product-class process is running or not.
   */
  isCreating$: Observable<boolean>;

  /**
   * The create product class form.
   */
  form: FormGroup;

  /**
   * The set of subscriptions on this components,
   * these subscriptions must be unsubscribed before this component got destroyed.
   */
  subscriptions = new Subscription();

  /**
   * @param modalService The modal service.
   * @param notificationService The notification service.
   * @param translationService The translation service.
   * @param storesStore$ The stores-store module.
   */
  constructor(
    private modalService: NgbModal,
    private notificationService: NotificationService,
    private translationService: TranslationService,
    private storesStore$: Store<fromStoresStore.StoresState>
  ) {}

  ngOnInit(): void {
    this.init();
  }

  /**
   * Destroy component data
   */
  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  /**
   * Initialize component data.
   */
  init() {
    this.initForm();

    /**
     * Set data.
     */
    this.isCreating$ = this.storesStore$.pipe(select(fromStoresStore.getSelectedProductClassCreating));
  }

  /**
   * Initialize form and add validators.
   */
  initForm() {
    this.form = new FormGroup({
      name: new FormControl('', [Validators.required, Validators.minLength(1), Validators.maxLength(50)]),
      nameEn: new FormControl('', [Validators.required, Validators.minLength(1), Validators.maxLength(50)]),
      locations: new FormArray([], CustomValidators.arrayItems(1)),
      showProductClassInSalesScreen: new FormControl(false),
      photo: new FormControl(null),
    });
  }

  /**
   * Submits the form.
   */
  submit() {
    if (this.form.invalid) {
      const errorMessage = new NotificationMessage();

      if (this.form.get('name').invalid) {
        errorMessage.title = this.translationService.translate(
          'STORES.PRODUCT_CLASSES.PRODUCT_CLASS_DATA_VALIDATION.ARABIC_PRODUCT_CLASS_NAME_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate(
            'STORES.PRODUCT_CLASSES.PRODUCT_CLASS_DATA_VALIDATION.PRODUCT_CLASS_NAME_IS_REQUIRED'
          ),
          this.translationService.translate(
            'STORES.PRODUCT_CLASSES.PRODUCT_CLASS_DATA_VALIDATION.PRODUCT_CLASS_NAME_LENGTH_ERROR'
          ),
        ];
      } else if (this.form.get('nameEn').invalid) {
        errorMessage.title = this.translationService.translate(
          'STORES.PRODUCT_CLASSES.PRODUCT_CLASS_DATA_VALIDATION.ENGLISH_PRODUCT_CLASS_NAME_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate(
            'STORES.PRODUCT_CLASSES.PRODUCT_CLASS_DATA_VALIDATION.PRODUCT_CLASS_NAME_IS_REQUIRED'
          ),
          this.translationService.translate(
            'STORES.PRODUCT_CLASSES.PRODUCT_CLASS_DATA_VALIDATION.PRODUCT_CLASS_NAME_LENGTH_ERROR'
          ),
        ];
      } else if (this.form.get('locations').invalid) {
        /**
         * Check if lines count = 0.
         */
        if (!this.locationsForm.controls.length) {
          errorMessage.title = this.translationService.translate(
            'STORES.PRODUCT_CLASSES.PRODUCT_CLASS_DATA_VALIDATION.LOCATIONS_ERROR'
          );
          errorMessage.body = [
            this.translationService.translate(
              'STORES.PRODUCT_CLASSES.PRODUCT_CLASS_DATA_VALIDATION.LOCATIONS_LENGTH_ERROR'
            ),
          ];
        } else {
          /**
           * Check if some of locations has errors.
           */
          for (let index = 0; index < this.locationsForm.controls.length; index++) {
            const location = this.locationsForm.controls[index];

            if (location.valid) {
              continue;
            }

            errorMessage.title = this.translationService.translate(
              'STORES.PRODUCT_CLASSES.PRODUCT_CLASS_DATA_VALIDATION.LOCATIONS_NUMBER_ERROR',
              {
                locationNumber: index + 1,
              }
            );
            errorMessage.body = [];

            if (location.get('locationId').invalid) {
              errorMessage.body.push(
                this.translationService.translate(
                  'STORES.PRODUCT_CLASSES.PRODUCT_CLASS_DATA_VALIDATION.LOCATIONS_IS_REQUIRED'
                )
              );
            }

            break;
          }
        }
      }

      this.form.markAllAsTouched();
      return this.notificationService.warningWithTitle(errorMessage);
    } else {
      this.openModal(this.confirmModalRef);
    }
  }

  /**
   * Confirms the form submit.
   */
  confirm() {
    /**
     * The list of locations in the locations form.
     */

    const locations = this.locationsForm.value.map((item: LocationFormItem) => item.locationId);

    this.storesStore$.dispatch(
      new fromStoresStore.CreateProductClass({
        ...this.form.value,
        locations: locations,
        photo: this.form.value.photo ?? null,
      })
    );
  }

  /**
   * Opens the modal of the given templateRef.
   * @param modalRef The modal templateRef to be opened.
   */
  openModal(modalRef) {
    this.modalService.open(modalRef);
  }
}
