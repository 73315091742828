import { PaginationInfo } from 'shared/models';
import { Region } from 'stores/models';

/**
 * Represents the regions state.
 */
export class RegionsState {
  /**
   * The list of regions.
   */
  data: Region[];

  /**
   * The pagination info for the regions.
   */
  paginationInfo: PaginationInfo;

  /**
   * An error that may resulting during processing some actions.
   */
  error: any;

  /**
   * Determines if there is a running search process.
   */
  isSearching: boolean;

  /**
   * Determines if the last search process has been ended successfully.
   */
  isSearchCompleted: boolean;
}
