/**
 * The set of system supported gosi reg types.
 */
export enum GosiRegTypes {
  /**
   * The standard of gosi reg types.
   */
  STANDARD = 'STANDARD',

  /**
   * The not registered of gosi reg types.
   */
  NOT_REGISTERED = 'NOT_REGISTERED',

  /**
   * The covered by employee of gosi reg types.
   */
  COVERED_BY_EMPLOYER = 'COVERED_BY_EMPLOYER',
}
