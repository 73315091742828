import { CustomersEffects } from 'app-search/store/effects/customers.effects';
import { UsersEffects } from 'app-search/store/effects/users.effects';
import { VendorsEffects } from 'app-search/store/effects/vendors.effects';
import { PosDevicesEffects } from 'app-search/store/effects/pos-devices.effects';
import { EmployeesEffects } from 'app-search/store/effects/employees.effects';
import { LocationsEffects } from 'app-search/store/effects/location.effects';
import { DepartmentsEffects } from 'app-search/store/effects/departments.effects';
import { BankAccountsEffects } from 'app-search/store/effects/bank-accounts.effects';
import { BanksEffects } from 'app-search/store/effects/banks.effects';
import { SubTasksEffects } from 'app-search/store/effects/sub-tasks.effects';
import { ProjectsEffects } from 'app-search/store/effects/projects.effects';
import { GovServicesEffects } from 'app-search/store/effects/gov-services.effects';
import { RegionsEffects } from 'app-search/store/effects/regions.effects';
import { SectorsEffects } from 'app-search/store/effects/sectors.effects';

/**
 * The App-Search-Module store effects.
 */
export const effects: any[] = [
  CustomersEffects,
  UsersEffects,
  VendorsEffects,
  PosDevicesEffects,
  EmployeesEffects,
  LocationsEffects,
  DepartmentsEffects,
  BankAccountsEffects,
  BanksEffects,
  SubTasksEffects,
  ProjectsEffects,
  GovServicesEffects,
  RegionsEffects,
  SectorsEffects,
];
