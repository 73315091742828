import { Action } from '@ngrx/store';

import { City } from 'lookups/models';
import { AppHttpResponse } from 'shared';

/**
 * The Lookups-Module store  city action types.
 */
export enum CityActionType {
  GET_ALL_CITIES = '[Lookups] [City] Get All Cities',
  GET_ALL_CITIES_FAIL = '[Lookups] [City] Get All Cities Fail',
  GET_ALL_CITIES_SUCCESS = '[Lookups] [City] Get All Cities Success',
}

/**
 * Represents a store  city action.
 */
export class GetAllCities implements Action {
  /**
   * The type of the action.
   */
  readonly type = CityActionType.GET_ALL_CITIES;

  /**
   * Fires a new  city action.
   */
  constructor() {}
}

/**
 * Represents a store  city fail action.
 */
export class GetAllCitiesFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = CityActionType.GET_ALL_CITIES_FAIL;

  /**
   * Fires a new  city fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store  city success action.
 */
export class GetAllCitiesSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = CityActionType.GET_ALL_CITIES_SUCCESS;

  /**
   * Fires a  city success action.
   * @param payload An object contains the list of  Cities.
   */
  constructor(public payload: AppHttpResponse<City[]>) {}
}

/**
 * Lookups-module  city action types.
 */
export type CityActions = GetAllCities | GetAllCitiesFail | GetAllCitiesSuccess;
