<!-- page header starts -->
<app-page-header [info]="pageInfo"></app-page-header>
<!-- page header ends -->

<!-- gov service requests starts -->
<div class="card">
  <div class="card-content">
    <div class="px-3 pt-3">
      <form [formGroup]="form" (ngSubmit)="submit()" *ngIf="govServiceRequest$ | async; let govServiceRequest">
        <div class="row">
          <div class="col-md-12">
            <div class="card">
              <div class="card-header mb-3 pt-0">
                <h4 class="card-title">
                  <span>{{
                    'HR.GOV_SERVICE_REQUESTS.GOV_SERVICE_REQUEST_DATA.GOV_SERVICE_REQUEST_DATA' | translate
                  }}</span>
                </h4>
              </div>
              <div class="card-content">
                <div class="px-3">
                  <div class="row">
                    <div class="form-group col-md-6">
                      <label for="govServiceId">
                        {{ 'HR.GOV_SERVICE_REQUESTS.GOV_SERVICE_REQUEST_DATA.SERVICE' | translate }}
                      </label>
                      <input
                        type="text"
                        id="govServiceId"
                        class="form-control round"
                        [value]="form.value.govService.name | localize: form.value.govService.nameEn | async"
                        readonly
                      />
                    </div>
                    <div class="form-group col-md-6">
                      <label for="govServiceRequestPaymentMethodId">
                        {{ 'HR.GOV_SERVICE_REQUESTS.GOV_SERVICE_REQUEST_DATA.PAY_METHOD_TYPE' | translate }}
                      </label>
                      <ng-select
                        class="round"
                        formControlName="govServiceRequestPaymentMethodId"
                        labelForId="govServiceRequestPaymentMethodId"
                        searchable="true"
                        [placeholder]="
                          'HR.GOV_SERVICE_REQUESTS.GOV_SERVICE_REQUEST_DATA.PAY_METHOD_TYPE_PLACEHOLDER' | translate
                        "
                        [notFoundText]="'SHARED.SEARCH.SEARCH_NOT_FOUND' | translate"
                      >
                        <ng-option
                          *ngFor="let paymentMethodType of paymentMethods$ | async"
                          [value]="paymentMethodType.id"
                        >
                          {{ paymentMethodType.name | localize: paymentMethodType.nameEn | async }}
                        </ng-option>
                      </ng-select>
                    </div>
                    <div class="form-group col-md-6">
                      <label
                        for="bankAccountId"
                        *ngIf="this.form?.value?.govServiceRequestPaymentMethodId === paymentMethodIdForBankTransfer"
                      >
                        {{ 'HR.GOV_SERVICE_REQUESTS.GOV_SERVICE_REQUEST_DATA.BANK_ACCOUNT' | translate }}
                      </label>
                      <label
                        for="bankAccountId"
                        *ngIf="this.form?.value?.govServiceRequestPaymentMethodId === paymentMethodIdForCash"
                      >
                        {{ 'HR.GOV_SERVICE_REQUESTS.GOV_SERVICE_REQUEST_DATA.CASH_ACCOUNT' | translate }}
                      </label>
                      <ng-select
                        class="round"
                        formControlName="bankAccountId"
                        labelForId="bankAccountId"
                        searchable="true"
                        [placeholder]="
                          'HR.GOV_SERVICE_REQUESTS.GOV_SERVICE_REQUEST_DATA.BANK_ACCOUNT_PLACEHOLDER' | translate
                        "
                        [notFoundText]="'SHARED.SEARCH.SEARCH_NOT_FOUND' | translate"
                        (open)="bankAccountsListVisibility = true"
                      >
                        <ng-option *ngFor="let bankAccount of bankAccounts" [value]="bankAccount.id">
                          {{ bankAccount.bank.name | localize: bankAccount.bank.nameEn | async }}-
                          {{ bankAccount.name | localize: bankAccount.nameEn | async }}
                        </ng-option>
                      </ng-select>
                    </div>
                    <div
                      class="form-group col-md-6"
                      *ngIf="this.form?.value?.govServiceRequestPaymentMethodId === paymentMethodIdForBankTransfer"
                    >
                      <label for="bankFee">{{
                        'HR.GOV_SERVICE_REQUESTS.GOV_SERVICE_REQUEST_DATA.BANK_FEE_VALUE' | translate
                      }}</label>
                      <input
                        type="text"
                        id="bankFee"
                        [mask]="DECIMAL_MASK"
                        thousandSeparator=","
                        minLength="0"
                        class="form-control round"
                        [placeholder]="
                          'HR.GOV_SERVICE_REQUESTS.GOV_SERVICE_REQUEST_DATA.BANK_FEE_VALUE_PLACEHOLDER' | translate
                        "
                        formControlName="bankFee"
                      />
                    </div>
                    <div
                      class="form-group col-md-6"
                      *ngIf="this.form?.value?.govServiceRequestPaymentMethodId === paymentMethodIdForBankTransfer"
                    >
                      <label for="bankFeeTaxValue">{{
                        'HR.GOV_SERVICE_REQUESTS.GOV_SERVICE_REQUEST_DATA.BANK_FEE_TAX_VALUE' | translate
                      }}</label>
                      <input
                        type="text"
                        id="bankFeeTaxValue"
                        [mask]="DECIMAL_MASK"
                        thousandSeparator=","
                        minLength="0"
                        class="form-control round"
                        [placeholder]="
                          'HR.GOV_SERVICE_REQUESTS.GOV_SERVICE_REQUEST_DATA.BANK_FEE_TAX_VALUE_PLACEHOLDER' | translate
                        "
                        formControlName="bankFeeTaxValue"
                      />
                    </div>
                    <div class="form-group col-md-6">
                      <label for="costCenterId">
                        {{ 'HR.GOV_SERVICE_REQUESTS.GOV_SERVICE_REQUEST_DATA.COST_CENTER' | translate }}
                      </label>
                      <ng-select
                        class="round"
                        formControlName="costCenterId"
                        labelForId="costCenterId"
                        searchable="true"
                        [closeOnSelect]="true"
                        [placeholder]="
                          'HR.GOV_SERVICE_REQUESTS.GOV_SERVICE_REQUEST_DATA.COST_CENTER_PLACEHOLDER' | translate
                        "
                        [notFoundText]="'SHARED.SEARCH.SEARCH_NOT_FOUND' | translate"
                      >
                        <ng-option *ngFor="let costCenter of costCenters" [value]="costCenter.id">
                          {{ costCenter.description | localize: costCenter.descriptionEn | async }}
                        </ng-option>
                      </ng-select>
                    </div>
                    <div class="form-group col-md-6">
                      <label for="transactionDate">{{
                        'HR.GOV_SERVICE_REQUESTS.GOV_SERVICE_REQUEST_DATA.TRANSACTION_DATE' | translate
                      }}</label>
                      <div class="input-group round">
                        <input
                          class="form-control"
                          id="transactionDate"
                          name="transactionDate"
                          formControlName="transactionDate"
                          [placeholder]="'SHARED.SEARCH.DATE_PLACEHOLDER' | translate"
                          ngbDatepicker
                          #transactionDate="ngbDatepicker"
                        />
                        <div class="input-group-append">
                          <span
                            class="input-group-text gradient-light-blue-indigo"
                            style="cursor: pointer;"
                            (click)="transactionDate.toggle()"
                          >
                            <i class="fa fa-calendar"></i>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="form-group col-md-6">
                      <label for="notes">{{
                        'HR.GOV_SERVICE_REQUESTS.GOV_SERVICE_REQUEST_DATA.NOTES' | translate
                      }}</label>
                      <textarea
                        id="notes"
                        class="form-control round"
                        [placeholder]="'HR.GOV_SERVICE_REQUESTS.GOV_SERVICE_REQUEST_DATA.NOTES_PLACEHOLDER' | translate"
                        rows="1"
                        minlength="0"
                        maxlength="200"
                        formControlName="notes"
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div>

              <!-- gov service request employees starts -->
              <div class="card-header mb-3 pt-0">
                <h4 class="card-title">
                  {{ 'HR.GOV_SERVICE_REQUESTS.GOV_SERVICE_REQUEST_DATA.EMPLOYEES_TITLE' | translate }}
                </h4>
              </div>
              <div class="card-content">
                <div class="px-3">
                  <div class="row">
                    <div class="col-md-12">
                      <gov-service-request-employees-form
                        [employeesForm]="employeesForm"
                        [newEmployeeItems]="newEmployeeItems"
                      ></gov-service-request-employees-form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- gov service request employees ends -->

            <!-- pay summary starts -->
            <div class="card-header mb-4 pt-4">
              <h4 class="card-title">
                {{ 'HR.GOV_SERVICE_REQUESTS.GOV_SERVICE_REQUEST_DATA.REQUEST_SUMMARY' | translate }}
              </h4>
            </div>
            <div class="card-content">
              <div class="px-3">
                <div class="row">
                  <div class="col-md-12">
                    <div class="row">
                      <div class="form-group col-md-4">
                        <label for="serviceValueTotal">{{
                          'HR.GOV_SERVICE_REQUESTS.GOV_SERVICE_REQUEST_DATA.SERVICE_VALUE_TOTAL' | translate
                        }}</label>
                        <input
                          type="text"
                          id="serviceValueTotal"
                          name="serviceValueTotal"
                          class="form-control round"
                          [value]="serviceValueTotal | toDecimal"
                          readonly
                        />
                      </div>
                      <div class="form-group col-md-4">
                        <label for="penaltyValueTotal">{{
                          'HR.GOV_SERVICE_REQUESTS.GOV_SERVICE_REQUEST_DATA.PENALTY_VALUE_TOTAL' | translate
                        }}</label>
                        <input
                          type="text"
                          id="penaltyValueTotal"
                          name="penaltyValueTotal"
                          class="form-control round"
                          [value]="penaltyValueTotal | toDecimal"
                          readonly
                        />
                      </div>
                      <div class="form-group col-md-4">
                        <label for="requestNet">{{
                          'HR.GOV_SERVICE_REQUESTS.GOV_SERVICE_REQUEST_DATA.REQUEST_NET' | translate
                        }}</label>
                        <input
                          type="text"
                          id="requestNet"
                          name="requestNet"
                          class="form-control round"
                          [value]="requestNet | toDecimal"
                          readonly
                        />
                      </div>
                      <div class="form-group col-md-4">
                        <label for="bankFee">{{
                          'HR.GOV_SERVICE_REQUESTS.GOV_SERVICE_REQUEST_DATA.BANK_FEE_VALUE' | translate
                        }}</label>
                        <input
                          type="text"
                          id="bankFee"
                          name="bankFee"
                          class="form-control round"
                          [value]="bankFee | toDecimal"
                          readonly
                        />
                      </div>
                      <div class="form-group col-md-4">
                        <label for="bankFeeTaxValue">{{
                          'HR.GOV_SERVICE_REQUESTS.GOV_SERVICE_REQUEST_DATA.BANK_FEE_TAX_VALUE' | translate
                        }}</label>
                        <input
                          type="text"
                          id="bankFeeTaxValue"
                          name="bankFeeTaxValue"
                          class="form-control round"
                          [value]="bankFeeTaxValue | toDecimal"
                          readonly
                        />
                      </div>
                      <div class="form-group col-md-4">
                        <label for="payTotal" class="font-weight-bold" style="color: #212529;">
                          <i class="fa fa-star"></i>
                          <span class="mx-1">{{
                            'HR.GOV_SERVICE_REQUESTS.GOV_SERVICE_REQUEST_DATA.PAY_TOTAL' | translate
                          }}</span>
                        </label>
                        <input
                          type="text"
                          id="payTotal"
                          name="payTotal"
                          class="form-control round"
                          [value]="paymentNet | toDecimal"
                          readonly
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- pay summary ends -->
          </div>
          <div class="form-group col-md-12">
            <div class="card">
              <!--  attachments starts -->
              <div class="card-content">
                <div class="px-3">
                  <a (click)="selectedGovServiceRequest = govServiceRequest; openModal(viewAttachmentsModalRef)">
                    <i class="fa fa-paperclip"></i>
                    <span class="mx-1">{{
                      'FINANCES.HR.PAYROLL_PAYMENTS.PAYROLL_PAYMENTS_DATA.DISPLAY_ATTACHMENTS' | translate
                    }}</span>
                  </a>
                </div>
              </div>
              <!--  attachments ends -->
            </div>
          </div>
          <div class="col-md-12">
            <div class="card">
              <!--  attachments starts -->
              <div class="card-header mb-3 pt-1">
                <h4 class="card-title">{{ 'ASSETS.ASSET_DATA.ATTACHMENTS' | translate }}</h4>
              </div>
              <div class="card-content">
                <div class="px-3">
                  <app-files-upload formArrayName="attachments" [filesForm]="attachmentsForm"></app-files-upload>
                </div>
              </div>
              <!--  attachments ends -->
            </div>
          </div>
          <div class="col-md-12">
            <div class="row">
              <div class="form-group col-12 d-flex justify-content-between flex-column flex-sm-row mt-2">
                <div class="form-group col-12 d-flex justify-content-between mt-2">
                  <button type="submit" class="btn btn-raised btn-success round" [disabled]="isPaying$ | async">
                    <i class="fa fa-check"></i>
                    <span class="mx-1">{{ 'SHARED.CONFIRM.SAVE' | translate }}</span>
                  </button>
                  <a class="btn btn-dark round" routerLink="/finances/hr" fragment="governmental-service-requests">
                    <i class="fa fa-undo"></i>
                    <span class="mx-1">{{
                      'HR.GOV_SERVICE_REQUESTS.GOV_SERVICE_REQUEST_DATA.GOV_RELATIONS_RETURN' | translate
                    }}</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
<!-- gov service requests ends -->

<!-- confirm modal starts -->
<ng-template #confirmModalRef let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
      {{ 'HR.GOV_SERVICE_REQUESTS.GOV_SERVICE_REQUEST_DATA.CONFIRM_PAY_GOV_SERVICE_REQUEST_TITLE' | translate }}
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="d()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p class="text-success">
      {{ 'HR.GOV_SERVICE_REQUESTS.GOV_SERVICE_REQUEST_DATA.CONFIRM_PAY_GOV_SERVICE_REQUEST_MESSAGE' | translate }}
    </p>
  </div>
  <div class="modal-footer">
    <div class="modal-actions">
      <button type="button" class="btn btn-raised btn-success round" (click)="c(); confirm()" ngbAutofocus>
        <i class="fa fa-check"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.SAVE' | translate }}</span>
      </button>
      <button type="button" class="btn btn-outline-dark round" (click)="c()">
        <i class="fa fa-times"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.CANCEL' | translate }}</span>
      </button>
    </div>
  </div>
</ng-template>
<!-- confirm modal ends -->

<!-- view attachments modal starts -->
<ng-template #viewAttachmentsModalRef let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title text-info" id="modal-basic-title">
      {{ 'FINANCES.HR.PAYROLL_PAYMENTS.PAYROLL_PAYMENTS_DATA.ATTACHMENTS' | translate }}
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="d()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="card-content">
        <div class="px-3">
          <div class="row">
            <div class="form-group col-md-12">
              <!-- payroll payment attachments starts -->
              <div class="card-content">
                <div class="px-3">
                  <app-files-viewer
                    *ngIf="selectedGovServiceRequest.govServiceRequestAttachments?.length; else noAttachments"
                    [files]="getAttachmentsUrls(selectedGovServiceRequest.govServiceRequestAttachments)"
                  ></app-files-viewer>
                </div>
              </div>
              <!-- payroll payment attachments ends -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="modal-actions">
      <button type="button" class="btn btn-raised btn-primary round" (click)="c()" ngbAutoFocus>
        <i class="fa fa-times"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.CLOSE' | translate }}</span>
      </button>
    </div>
  </div>
</ng-template>
<!-- view attachments modal ends -->

<!-- no attachments template starts -->
<ng-template #noAttachments>
  <div class="col-md-12 alert alert-warning mt-3 text-center" role="alert">
    {{ 'FINANCES.HR.PAYROLL_PAYMENTS.PAYROLL_PAYMENTS_DATA.ATTACHMENTS_NOT_FOUND' | translate }}
  </div>
</ng-template>

<!-- bank accounts search starts -->
<bank-accounts-search
  onlyActive="true"
  closeOnSelect="true"
  (bankAccounts)="selectBankAccount($event)"
  [(visible)]="bankAccountsListVisibility"
>
</bank-accounts-search>
<!-- bank accounts search ends -->
