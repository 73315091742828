import { Component, Input } from '@angular/core';

import { ExportToolsService } from 'shared/services';

@Component({
  selector: 'app-export-tools',
  templateUrl: './export-tools.component.html',
  styles: [],
})
export class ExportToolsComponent {
  /**
   * Gets the full file name when this table exported.
   */
  get outputFullFileName(): string {
    const today = new Date();

    return `${this.outputFileName} - ${today.toLocaleDateString()} ${today.toLocaleTimeString()}`;
  }

  /**
   * Sets the id of the `HTMLElement` that will be exported.
   * @default 'export-target-element'
   */
  @Input() targetElementId = 'export-target-element';

  /**
   * Sets the output file name.
   * @default 'file'
   */
  @Input() outputFileName = 'file';

  /**
   * @param exportToolsService The export tools service.
   */

  constructor(private exportToolsService: ExportToolsService) {}

  /**
   * Exports the current displayed table into an Excel file.
   */
  exportToExcel() {
    this.exportToolsService.exportToExcel(document.getElementById(this.targetElementId), this.outputFullFileName);
  }
}
