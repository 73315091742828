import { createSelector } from '@ngrx/store';

import { SalesState, SaleInvoicesState } from 'sales/store/states';
import { selectSalesState } from 'sales/store/selectors/feature.selectors';

/**
 * Gets the saleInvoices state.
 */
const selectSaleInvoicesState = createSelector(selectSalesState, (state: SalesState) => state.saleInvoices);

/**
 * Gets the list of loaded saleInvoices.
 */
export const getSaleInvoices = createSelector(selectSaleInvoicesState, (state: SaleInvoicesState) => state.data);

/**
 * Gets the list of loaded unpaid saleInvoices.
 */
export const getUnpaidSaleInvoices = createSelector(
  selectSaleInvoicesState,
  (state: SaleInvoicesState) => state.UnpaidInvoicesData
);

/**
 * Gets the saleInvoices pagination info.
 */
export const getSaleInvoicesPaginationInfo = createSelector(
  selectSaleInvoicesState,
  (state: SaleInvoicesState) => state.paginationInfo
);

/**
 * Gets the saleInvoices pagination info.
 */
export const getUnpaidSaleInvoicesPaginationInfo = createSelector(
  selectSaleInvoicesState,
  (state: SaleInvoicesState) => state.unpaidInvoicesPaginationInfo
);

/**
 * Gets the error that occurred in the state.
 */
export const getSaleInvoicesError = createSelector(selectSaleInvoicesState, (state: SaleInvoicesState) => state.error);

/**
 * Determines if there is a running search process.
 */
export const getSaleInvoicesSearching = createSelector(
  selectSaleInvoicesState,
  (state: SaleInvoicesState) => state.isSearching
);

/**
 * Determines if the last search process has been ended successfully.
 */
export const getSaleInvoicesSearchCompleted = createSelector(
  selectSaleInvoicesState,
  (state: SaleInvoicesState) => state.isSearchCompleted
);

/**
 * Determines if there is a running search process.
 */
export const getUnpaidSaleInvoicesSearching = createSelector(
  selectSaleInvoicesState,
  (state: SaleInvoicesState) => state.isSearchingUnpaid
);

/**
 * Determines if the last search process has been ended successfully.
 */
export const getUnpaidSaleInvoicesSearchCompleted = createSelector(
  selectSaleInvoicesState,
  (state: SaleInvoicesState) => state.isSearchCompletedUnpaid
);

/**
 * Gets the selected saleInvoice.
 */
export const getSelectedSaleInvoice = createSelector(
  selectSaleInvoicesState,
  (state: SaleInvoicesState) => state.selectedSalesInvoice
);

/**
 * Determines if there is a running find-salesInvoice process.
 */
export const getSaleInvoicesFinding = createSelector(
  selectSaleInvoicesState,
  (state: SaleInvoicesState) => state.isFinding
);

/**
 * Determines if the last find-saleInvoice process has been ended successfully.
 */
export const getSaleInvoicesFindCompleted = createSelector(
  selectSaleInvoicesState,
  (state: SaleInvoicesState) => state.isFindCompleted
);

/**
 * Determines if there is a running create process.
 */
export const getSelectedSaleInvoiceCreating = createSelector(
  selectSaleInvoicesState,
  (state: SaleInvoicesState) => state.isCreating
);

/**
 * Determines if the last create process has been ended successfully.
 */
export const getSelectedSaleInvoiceCreateCompleted = createSelector(
  selectSaleInvoicesState,
  (state: SaleInvoicesState) => state.isCreateCompleted
);

/**
 * Determines if there is a running change maintenance stage process.
 */
export const getSelectedSaleInvoiceChangingMaintenanceStage = createSelector(
  selectSaleInvoicesState,
  (state: SaleInvoicesState) => state.isChangingMaintenanceStage
);

/**
 * Determines if the last change maintenance stage process has been ended successfully.
 */
export const getSelectedSaleInvoiceMaintenanceStageChangeCompleted = createSelector(
  selectSaleInvoicesState,
  (state: SaleInvoicesState) => state.isMaintenanceStageChangeCompleted
);

/**
 * Determines if the last create process has been ended successfully.
 */
export const getSelectedPointOfSaleInvoiceCreateCompleted = createSelector(
  selectSaleInvoicesState,
  (state: SaleInvoicesState) => state.isCreateCompleted
);
