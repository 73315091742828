import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { Claims } from 'security/models';
import * as fromComponents from 'stores/components';
import { ConfirmationGuard } from 'auth';

const routes: Routes = [
  // regions routing
  {
    path: 'regions/create',
    component: fromComponents.CreateRegionComponent,
    data: { claims: [Claims.CREATE_LOCATION] },
  },
  {
    path: 'regions/update/:regionId',
    component: fromComponents.UpdateRegionComponent,
    data: { claims: [Claims.UPDATE_LOCATION] },
  },
  {
    path: 'regions',
    component: fromComponents.RegionsListComponent,
    data: { claims: [Claims.CREATE_LOCATION, Claims.UPDATE_LOCATION, Claims.DELETE_LOCATION] },
  },

  // sectors routing
  {
    path: 'sectors/create',
    component: fromComponents.CreateSectorComponent,
    data: { claims: [Claims.CREATE_LOCATION] },
  },
  {
    path: 'sectors/update/:sectorId',
    component: fromComponents.UpdateSectorComponent,
    data: { claims: [Claims.UPDATE_LOCATION] },
  },
  {
    path: 'sectors',
    component: fromComponents.SectorsListComponent,
    data: { claims: [Claims.CREATE_LOCATION, Claims.UPDATE_LOCATION, Claims.DELETE_LOCATION] },
  },

  // locations routing
  {
    path: 'locations/create',
    component: fromComponents.CreateLocationComponent,
    data: { claims: [Claims.CREATE_LOCATION] },
    canDeactivate: [ConfirmationGuard],
  },
  {
    path: 'locations/update/:locationId',
    component: fromComponents.UpdateLocationComponent,
    data: { claims: [Claims.UPDATE_LOCATION] },
  },
  {
    path: 'locations',
    component: fromComponents.LocationsListComponent,
    data: { claims: [Claims.CREATE_LOCATION, Claims.UPDATE_LOCATION, Claims.DELETE_LOCATION] },
  },

  // products routing
  {
    path: 'products/create',
    component: fromComponents.CreateProductComponent,
    canDeactivate: [ConfirmationGuard],
    data: { claims: [Claims.CREATE_PRODUCT] },
  },
  {
    path: 'products/update/:productId',
    component: fromComponents.UpdateProductComponent,
    data: { claims: [Claims.UPDATE_PRODUCT] },
  },
  {
    path: 'discounts',
    component: fromComponents.ProductSalesDiscountsComponent,
    data: { claims: [Claims.UPDATE_PRODUCT] },
  },
  {
    path: 'products',
    component: fromComponents.ProductsListComponent,
    data: { claims: [Claims.CREATE_PRODUCT, Claims.UPDATE_PRODUCT, Claims.DELETE_PRODUCT] },
  },

  // product-stock-query routing
  {
    path: 'product-stock-query',
    component: fromComponents.ProductStockQueryComponent,
  },
  // variants routing
  {
    path: 'variants/create',
    component: fromComponents.CreateVariantComponent,
    data: { claims: [Claims.CREATE_VARIANT] },
  },
  {
    path: 'variants/update/:variantId',
    component: fromComponents.UpdateVariantComponent,
    data: { claims: [Claims.UPDATE_VARIANT] },
  },
  {
    path: 'variants',
    component: fromComponents.VariantsListComponent,
    data: { claims: [Claims.CREATE_VARIANT, Claims.UPDATE_VARIANT, Claims.DELETE_VARIANT] },
  },

  // product-classes routing
  {
    path: 'product-classes/create',
    component: fromComponents.CreateProductClassComponent,
    data: { claims: [Claims.CREATE_PRODUCT_CLASS] },
  },
  {
    path: 'product-classes/update/:productClassId',
    component: fromComponents.UpdateProductClassComponent,
    data: { claims: [Claims.UPDATE_PRODUCT_CLASS] },
  },
  {
    path: 'product-classes',
    component: fromComponents.ProductClassesListComponent,
    data: { claims: [Claims.CREATE_PRODUCT_CLASS, Claims.UPDATE_PRODUCT_CLASS, Claims.DELETE_PRODUCT_CLASS] },
  },

  // unit-of-measure-classes-routes
  {
    path: 'unit-of-measure-classes/create',
    component: fromComponents.CreateUnitOfMeasureClassComponent,
    data: { claims: [Claims.CREATE_UNIT_OF_MEASURE_CLASS] },
  },

  {
    path: 'unit-of-measure-classes/update/:unitOfMeasureClassId',
    component: fromComponents.UpdateUnitOfMeasureClassComponent,
    data: { claims: [Claims.UPDATE_UNIT_OF_MEASURE_CLASS] },
  },
  {
    path: 'unit-of-measure-classes',
    component: fromComponents.UnitOfMeasureClassesListComponent,
    data: {
      claims: [
        Claims.CREATE_UNIT_OF_MEASURE_CLASS,
        Claims.UPDATE_UNIT_OF_MEASURE_CLASS,
        Claims.DELETE_UNIT_OF_MEASURE_CLASS,
      ],
    },
  },
  // units-of-measure-routes
  {
    path: 'units-of-measure/create',
    component: fromComponents.CreateUnitOfMeasureComponent,
    data: { claims: [Claims.CREATE_UNIT_OF_MEASURE] },
  },
  {
    path: 'units-of-measure/update/:unitOfMeasureId',
    component: fromComponents.UpdateUnitOfMeasureComponent,
    data: { claims: [Claims.UPDATE_UNIT_OF_MEASURE] },
  },
  {
    path: 'units-of-measure',
    component: fromComponents.UnitsOfMeasureListComponent,
    data: {
      claims: [Claims.CREATE_UNIT_OF_MEASURE, Claims.UPDATE_UNIT_OF_MEASURE, Claims.DELETE_UNIT_OF_MEASURE],
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class StoresRoutingModule {}
