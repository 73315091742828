import { Injectable } from '@angular/core';

import { Effect, ofType, Actions } from '@ngrx/effects';
import { switchMap, map, catchError, tap } from 'rxjs/operators';
import { of } from 'rxjs';

import { NotificationService, StatusCode, PAGINATION, TranslationService } from 'shared';
import { ShiftsService } from 'sales/services';
import {
  ShiftsActionType,
  SearchMyShifts,
  SearchMyShiftsFail,
  SearchMyShiftsSuccess,
  SearchShifts,
  SearchShiftsFail,
  SearchShiftsSuccess,
  FindShift,
  FindShiftFail,
  FindShiftSuccess,
  StartShift,
  StartShiftFail,
  StartShiftSuccess,
  StartShiftForCasher,
  StartShiftForCasherFail,
  StartShiftForCasherSuccess,
  CancelMyShift,
  CancelMyShiftFail,
  CancelMyShiftSuccess,
  CancelShift,
  CancelShiftFail,
  CancelShiftSuccess,
  DeleteShift,
  DeleteShiftFail,
  DeleteShiftSuccess,
} from 'sales/store/actions';

@Injectable()
export class ShiftsEffects {
  constructor(
    private actions$: Actions,
    private shiftsService: ShiftsService,
    private notificationService: NotificationService,
    private translationService: TranslationService
  ) {}

  /* ========================= SEARCH_MY_SHIFTS =================================== */
  @Effect()
  search$ = this.actions$.pipe(
    ofType(ShiftsActionType.SEARCH_MY_SHIFTS),
    switchMap(({ payload }: SearchMyShifts) => {
      return this.shiftsService
        .searchMyShifts(payload.fromDate, payload.toDate, payload.page, PAGINATION.MyShifts)
        .pipe(
          map((response) => new SearchMyShiftsSuccess(response)),
          catchError((error) => of(new SearchMyShiftsFail(error)))
        );
    })
  );

  /* ========================= SEARCH_SHIFTS =================================== */
  @Effect()
  searchAllShifts$ = this.actions$.pipe(
    ofType(ShiftsActionType.SEARCH_SHIFTS),
    switchMap(({ payload }: SearchShifts) => {
      return this.shiftsService
        .searchAllShifts(
          payload.users,
          payload.locations,
          payload.fromDate,
          payload.toDate,
          payload.page,
          PAGINATION.AllShifts
        )
        .pipe(
          map((response) => new SearchShiftsSuccess(response)),
          catchError((error) => of(new SearchShiftsFail(error)))
        );
    })
  );

  /* ========================= FIND_SHIFT =================================== */
  @Effect()
  find$ = this.actions$.pipe(
    ofType(ShiftsActionType.FIND_SHIFT),
    switchMap((action: FindShift) =>
      this.shiftsService.findMyShiftById(action.payload).pipe(
        map((response) => new FindShiftSuccess(response)),
        catchError((error) => of(new FindShiftFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  findFail$ = this.actions$.pipe(
    ofType(ShiftsActionType.FIND_SHIFT_FAIL),
    tap((action: FindShiftFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(this.translationService.translate('SALES.SHIFTS.SHIFT_NOT_FOUND'));
      }
    })
  );

  /* ========================= START_SHIFT =================================== */
  @Effect()
  start$ = this.actions$.pipe(
    ofType(ShiftsActionType.START_SHIFT),
    switchMap((action: StartShift) =>
      this.shiftsService.startMyShift().pipe(
        map((response) => new StartShiftSuccess(response)),
        catchError((error) => of(new StartShiftFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  createSuccess$ = this.actions$.pipe(
    ofType(ShiftsActionType.START_SHIFT_SUCCESS),
    tap((action: StartShiftSuccess) => {
      this.notificationService.success(this.translationService.translate('SALES.SHIFTS.SHIFT_ADDED'));
    })
  );

  @Effect({ dispatch: false })
  createFail$ = this.actions$.pipe(
    ofType(ShiftsActionType.START_SHIFT_FAIL),
    tap((action: StartShiftFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );

  /* ========================= START_SHIFT_FOR_CASHER =================================== */
  @Effect()
  startShiftForCasher$ = this.actions$.pipe(
    ofType(ShiftsActionType.START_SHIFT_FOR_CASHER),
    switchMap((action: StartShiftForCasher) =>
      this.shiftsService.startShiftForCasher(action.payload).pipe(
        map((response) => new StartShiftForCasherSuccess(response)),
        catchError((error) => of(new StartShiftForCasherFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  startShiftForCasherSuccess$ = this.actions$.pipe(
    ofType(ShiftsActionType.START_SHIFT_FOR_CASHER_SUCCESS),
    tap((action: StartShiftForCasherSuccess) => {
      this.notificationService.success(this.translationService.translate('SALES.SHIFTS.SHIFT_ADDED'));
    })
  );

  @Effect({ dispatch: false })
  startShiftForCasherFail$ = this.actions$.pipe(
    ofType(ShiftsActionType.START_SHIFT_FOR_CASHER_FAIL),
    tap((action: StartShiftForCasherFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );

  /* ========================= CANCEL_MY_SHIFT =================================== */
  @Effect()
  cancelMyShift$ = this.actions$.pipe(
    ofType(ShiftsActionType.CANCEL_MY_SHIFT),
    switchMap((action: CancelMyShift) =>
      this.shiftsService.cancelMyShift(action.payload).pipe(
        map((response) => new CancelMyShiftSuccess(response)),
        catchError((error) => of(new CancelMyShiftFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  cancelMyShiftSuccess$ = this.actions$.pipe(
    ofType(ShiftsActionType.CANCEL_MY_SHIFT_SUCCESS),
    tap((action: CancelMyShiftSuccess) => {
      this.notificationService.success(this.translationService.translate('SALES.SHIFTS.SHIFT_CANCELED'));
    })
  );

  @Effect({ dispatch: false })
  cancelMyShiftFail$ = this.actions$.pipe(
    ofType(ShiftsActionType.CANCEL_MY_SHIFT_FAIL),
    tap((action: CancelMyShiftFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(this.translationService.translate('SALES.SHIFTS.SHIFT_NOT_FOUND'));
      } else if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );

  /* ========================= END_SHIFT =================================== */
  @Effect()
  cancelShift$ = this.actions$.pipe(
    ofType(ShiftsActionType.END_SHIFT),
    switchMap((action: CancelShift) =>
      this.shiftsService.cancelShift(action.payload).pipe(
        map((response) => new CancelShiftSuccess(response)),
        catchError((error) => of(new CancelShiftFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  cancelShiftSuccess$ = this.actions$.pipe(
    ofType(ShiftsActionType.END_SHIFT_SUCCESS),
    tap((action: CancelShiftSuccess) => {
      this.notificationService.success(this.translationService.translate('SALES.SHIFTS.SHIFT_CANCELED'));
    })
  );

  @Effect({ dispatch: false })
  cancelShiftFail$ = this.actions$.pipe(
    ofType(ShiftsActionType.END_SHIFT_FAIL),
    tap((action: CancelShiftFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(this.translationService.translate('SALES.SHIFTS.SHIFT_NOT_FOUND'));
      } else if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );

  /* ========================= DELETE_SHIFT =================================== */
  @Effect()
  delete$ = this.actions$.pipe(
    ofType(ShiftsActionType.DELETE_SHIFT),
    switchMap((action: DeleteShift) =>
      this.shiftsService.delete(action.payload).pipe(
        map((response) => new DeleteShiftSuccess(response)),
        catchError((error) => of(new DeleteShiftFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  deleteSuccess$ = this.actions$.pipe(
    ofType(ShiftsActionType.DELETE_SHIFT_SUCCESS),
    tap((action: DeleteShiftSuccess) => {
      this.notificationService.success(this.translationService.translate('SALES.SHIFTS.SHIFT_DELETED'));
    })
  );

  @Effect({ dispatch: false })
  deleteFail$ = this.actions$.pipe(
    ofType(ShiftsActionType.DELETE_SHIFT_FAIL),
    tap((action: DeleteShiftFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(this.translationService.translate('SALES.SHIFTS.SHIFT_NOT_FOUND'));
      } else if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );
}
