import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AppHttpResponse, PAGINATION } from 'shared';
import {
  CreateUserInput,
  UpdateUserInput,
  UpdateUserClaimsInput,
  User,
  ResetUsersCredentialsInput,
  CreateOrganizationUserInput,
  UpdateUserLocationInput,
  UpdateUserBankAccountInput,
  UpdateUserCostCenterInput,
} from 'security/models';
import { BankAccount, CostCenter } from 'finances/models';
import { Location } from 'stores/models';
import { PosDevice } from 'sales/models';

/**
 * The users services includes functionality to create, read, update, block ,activate users and update user claims.
 */
@Injectable()
export class UsersService {
  /**
   * The relative route for the users.
   *
   * No leading or trailing slashes required.
   */
  private usersApi = 'security/users';
  private userProfileApi = 'security/users/profile';

  constructor(private http: HttpClient) {}

  /**
   * Creates a new user from the provided data.
   * @param data The new user data.
   */
  public create(data: CreateUserInput): Observable<AppHttpResponse<User>> {
    return this.http.post<AppHttpResponse<User>>(`${this.usersApi}`, data);
  }

  /**
   * Creates a new organization user.
   * @param data The new organization user data.
   */
  public createOrganizationUser(data: CreateOrganizationUserInput): Observable<AppHttpResponse<User>> {
    return this.http.post<AppHttpResponse<User>>(`${this.usersApi}/organization-user`, data);
  }

  /**
   * Finds the user with the given id.
   * @param id The id of the user.
   */
  public findById(id: number): Observable<User> {
    return this.http.get<AppHttpResponse<User>>(`${this.usersApi}/${id}`).pipe(map((response) => response.data));
  }

  /** Gets the list of users satisfying the given criteria & pagination options.
   * @param name The name or a part of user name.
   * @param page The current pagination page number.
   * @param pageSize The maximum number of users allowed per one pagination page.
   */
  public search(name: string, page: number | any): Observable<AppHttpResponse<User[]>> {
    const params = new HttpParams().set('name', name).set('page', page).set('pageSize', PAGINATION.Users.toString());

    return this.http.get<AppHttpResponse<User[]>>(`${this.usersApi}`, { params });
  }

  /** Gets the list of users satisfying the given criteria & pagination options.
   * @param name The name of the user.
   * @param page The current pagination page number.
   * @param pageSize The maximum number of users allowed per one pagination page.
   */
  public getOrganizationUsers(name: string, page: number | any): Observable<AppHttpResponse<User[]>> {
    const params = new HttpParams().set('name', name).set('page', page).set('pageSize', PAGINATION.Users.toString());

    return this.http.get<AppHttpResponse<User[]>>(`${this.usersApi}/organization-users`, { params });
  }

  /**
   * Updates an existing user data using the provided data.
   * @param data The updated user data.
   */
  public update(data: UpdateUserInput): Observable<AppHttpResponse<User>> {
    return this.http.put<AppHttpResponse<User>>(`${this.usersApi}`, data);
  }

  /**
   * Updates an existing user claims using the provided data.
   * @param data The updated user claims.
   */
  public updateOrganizationUserClaims(data: UpdateUserClaimsInput): Observable<AppHttpResponse<User>> {
    return this.http.put<AppHttpResponse<User>>(`${this.usersApi}/organization-users/claims`, data);
  }

  /**
   * Activates the user with the given id.
   * @param id The id of the user.
   */
  public activate(id: number): Observable<User> {
    return this.http
      .put<AppHttpResponse<User>>(`${this.usersApi}/activate/${id}`, {})
      .pipe(map((response) => response.data));
  }

  /**
   * Activates the organization user with the given id.
   * @param id The id of the user.
   */
  public activateOrganizationUser(id: number): Observable<User> {
    return this.http
      .put<AppHttpResponse<User>>(`${this.usersApi}/organization-users/activate/${id}`, {})
      .pipe(map((response) => response.data));
  }

  /**
   * Block the user with the given id.
   * @param id The id of the user.
   */
  public block(id: number): Observable<User> {
    return this.http
      .put<AppHttpResponse<User>>(`${this.usersApi}/block/${id}`, {})
      .pipe(map((response) => response.data));
  }

  /**
   * Block the organization user with the given id.
   * @param id The id of the user.
   */
  public blockOrganizationUser(id: number): Observable<User> {
    return this.http
      .put<AppHttpResponse<User>>(`${this.usersApi}/organization-users/block/${id}`, {})
      .pipe(map((response) => response.data));
  }

  /**
   * reset user's credentials based on the provided data-model.
   * @param data The data-model to update the user's credentials.
   */
  public resetUsersCredentials(data: ResetUsersCredentialsInput): Observable<AppHttpResponse<User>> {
    return this.http.put<AppHttpResponse<User>>(`${this.usersApi}/reset-credentials`, data);
  }

  /**
   * update user's locations based on the provided data-model.
   * @param data The data-model to update the user's locations.
   */
  public updateUserLocation(data: UpdateUserLocationInput): Observable<AppHttpResponse<User>> {
    return this.http.put<AppHttpResponse<User>>(`${this.usersApi}/organization-users/locations`, data);
  }

  /**
   * update user's bank accounts based on the provided data-model.
   * @param data The data-model to update the user's bank accounts.
   */
  public updateUserBankAccount(data: UpdateUserBankAccountInput): Observable<AppHttpResponse<User>> {
    return this.http.put<AppHttpResponse<User>>(`${this.usersApi}/organization-users/bank-accounts`, data);
  }

  /**
   * update user's cost centers based on the provided data-model.
   * @param data The data-model to update the user's cost centers.
   */
  public updateUserCostCenter(data: UpdateUserCostCenterInput): Observable<AppHttpResponse<User>> {
    return this.http.put<AppHttpResponse<User>>(`${this.usersApi}/organization-users/cost-centers`, data);
  }

  /**
   * Gets the list of user locations satisfying the given criteria.
   * @param id The id of the user.
   */
  public getUserLocations(id: number): Observable<AppHttpResponse<Location[]>> {
    return this.http.get<AppHttpResponse<Location[]>>(`${this.usersApi}/user-locations/${id}`);
  }

  /**
   * Gets the list of user bank accounts satisfying the given criteria.
   * @param id The id of the user.
   */
  public getUserBankAccounts(id: number): Observable<AppHttpResponse<BankAccount[]>> {
    return this.http.get<AppHttpResponse<BankAccount[]>>(`${this.usersApi}/user-bank-accounts/${id}`);
  }

  /**
   * Gets the list of user cost centers satisfying the given criteria.
   * @param id The id of the user.
   */
  public getUserCostCenters(id: number): Observable<AppHttpResponse<CostCenter[]>> {
    return this.http.get<AppHttpResponse<CostCenter[]>>(`${this.usersApi}/user-cost-centers/${id}`);
  }

  /**
   * Gets the list of user pos devices satisfying the given criteria.
   * @param id The id of the user.
   */
  public getUserPosDevices(id: number): Observable<AppHttpResponse<PosDevice[]>> {
    return this.http.get<AppHttpResponse<PosDevice[]>>(`${this.usersApi}/user-pos-devices/${id}`);
  }
}
