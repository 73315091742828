import { createSelector } from '@ngrx/store';

import { selectLookupsState } from 'lookups/store/selectors/feature.selectors';
import { LookupsState, MeridiemIndicatorState } from 'lookups/store/states';

/**
 * Gets the meridiem indicator state.
 */
const selectMeridiemIndicators = createSelector(selectLookupsState, (state: LookupsState) => state.meridiemIndicators);

/**
 * Gets the list of loaded meridiemIndicators.
 */
export const getMeridiemIndicators = createSelector(
  selectMeridiemIndicators,
  (state: MeridiemIndicatorState) => state.data
);

/**
 * Gets the error that occurred in the state.
 */
export const getMeridiemIndicatorsError = createSelector(
  selectMeridiemIndicators,
  (state: MeridiemIndicatorState) => state.error
);

/**
 * Determines if there is a running load process.
 */
export const getMeridiemIndicatorsIsLoading = createSelector(
  selectMeridiemIndicators,
  (state: MeridiemIndicatorState) => state.isLoading
);

/**
 * Determines if the last load process has been ended successfully.
 */
export const getMeridiemIndicatorsIsLoaded = createSelector(
  selectMeridiemIndicators,
  (state: MeridiemIndicatorState) => state.isLoaded
);
