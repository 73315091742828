import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, OnDestroy } from '@angular/core';

import { Observable, Subscription } from 'rxjs';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Store, select } from '@ngrx/store';

import * as fromLookupsStore from 'lookups/store';
import { NotificationService, TranslationService } from 'shared';
import { SaleInvoiceOrderType } from 'lookups';
import { tap } from 'rxjs/operators';

/**
 * A order types search form wrapped in modal.
 */
@Component({
  selector: 'app-order-types-search',
  templateUrl: './order-types-search.component.html',
  styles: [],
})
export class OrderTypesSearchComponent implements OnInit, OnDestroy {
  /**
   * The modal template reference.
   */
  @ViewChild('modalRef') modalRef: ElementRef<any>;

  /**
   * Indicates whether the modal will automatically close after select the first invoice.
   * Be default it will not be closed.
   */
  @Input() closeOnSelect = false;

  /**
   * Indicates whether the order types displayed on the list should be all active.
   * Be default it will not be closed.
   */
  @Input() onlyActive = false;

  /**
   * Sets the visibility of this component modal.
   * Handles visibility change in two-data-binding approach.
   */
  @Input() set visible(isShown: boolean) {
    if (isShown && this.modalRef) {
      this.openModal(this.modalRef);
    } else {
      this.closeModal();
    }

    this.visibleChange.emit(isShown);
  }

  /**
   * Outputs a value that indicates whether the modal is shown or not.
   * Handles visibility change in two-data-binding approach.
   */
  @Output() visibleChange = new EventEmitter<boolean>();

  /** Outputs the selected invoices. */
  @Output() orderTypes = new EventEmitter<SaleInvoiceOrderType[]>();

  /**
   * The list of order types.
   */
  orderTypes$: Observable<SaleInvoiceOrderType[]>;

  /**
   * The list of order types.
   */
  orderTypesList: SaleInvoiceOrderType[];

  /**
   * The set of subscriptions on this components,
   * these subscriptions must be unsubscribed before this component got destroyed.
   */
  subscriptions = new Subscription();

  /**
   * The ngb-modal reference to use it when modal needed to be closed.
   */
  modal: NgbModalRef;

  /**
   *
   * @param modalService The modal service.
   * @param notificationService The notification service.
   * @param translationService: The translation service.
   * @param lookupsStore$ The lookups-module store.
   */
  constructor(
    private modalService: NgbModal,
    private notificationService: NotificationService,
    private translationService: TranslationService,
    private lookupsStore$: Store<fromLookupsStore.LookupsState>
  ) {}

  ngOnInit(): void {
    this.init();
  }

  /**
   * Destroy component data
   */
  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  /**
   * Initialize component data.
   */
  init() {
    this.initForm();

    /**
     * Loads data.
     */
    this.orderTypes$ = this.lookupsStore$.pipe(
      select(fromLookupsStore.getSaleInvoiceOrderTypes),
      tap((orderTypes) => {
        if (orderTypes) {
          this.orderTypesList = orderTypes;
        }
      })
    );

    this.lookupsStore$.dispatch(new fromLookupsStore.GetAllSaleInvoiceOrderType());
  }

  /**
   * Initialize form and add validators.
   */
  initForm() {}

  /**
   * Enables trackBy feature for *ngFor utility to track items
   * depending on a comparer to reduce DOM change.
   * If the item is already exist then no HTML will change, only bounded values.
   * @param index The index of the item.
   * @param orderType The item to determine if it was changed or not.
   */
  trackItems(index: number, orderType: SaleInvoiceOrderType) {
    return orderType ? orderType.id : undefined;
  }

  /**
   * Opens the modal of the given templateRef.
   * @param modalRef The modal templateRef to be opened.
   */
  openModal(modalRef) {
    /**
     * Open the modal & listen for user press ESC key.
     */
    this.modal = this.modalService.open(modalRef, { size: 'xl' });
    this.modal.result.then(null, () => this.closeModal());
  }

  /**
   * Handles orderTypes selected event.
   * @param orderTypes The list of order types to notify selected.
   */
  selectOrderType(orderTypes: SaleInvoiceOrderType[]) {
    this.notificationService.successInstant(
      this.translationService.translate('SALES.INVOICES.SALE_INVOICE_DATA.ORDER_TYPE_SELECTED')
    );
    this.orderTypes.emit(orderTypes);
    if (this.closeOnSelect) {
      this.closeModal();
    }
  }

  /**
   * Closes the currently opened modal.
   */
  closeModal() {
    this.modal?.dismiss();
    this.orderTypes.emit([]);
    this.visibleChange.emit(false);
  }
}
