import { createSelector } from '@ngrx/store';

import { selectLookupsState } from 'lookups/store/selectors/feature.selectors';
import { LookupsState, GosiCoverageTypeState } from 'lookups/store/states';

/**
 * Gets the gosi coverage type state.
 */
const selectGosiCoverageTypes = createSelector(selectLookupsState, (state: LookupsState) => state.gosiCoverageTypes);

/**
 * Gets the list of loaded gosi coverage types.
 */
export const getGosiCoverageTypes = createSelector(
  selectGosiCoverageTypes,
  (state: GosiCoverageTypeState) => state.data
);

/**
 * Gets the error that occurred in the state.
 */
export const getGosiCoverageTypesError = createSelector(
  selectGosiCoverageTypes,
  (state: GosiCoverageTypeState) => state.error
);

/**
 * Determines if there is a running load process.
 */
export const getGosiCoverageTypesIsLoading = createSelector(
  selectGosiCoverageTypes,
  (state: GosiCoverageTypeState) => state.isLoading
);

/**
 * Determines if the last load process has been ended successfully.
 */
export const getGosiCoverageTypesIsLoaded = createSelector(
  selectGosiCoverageTypes,
  (state: GosiCoverageTypeState) => state.isLoaded
);
