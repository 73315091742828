import { Action } from '@ngrx/store';

import { AppHttpResponse } from 'shared';
import { Board, CreateBoardInput, UpdateBoardInput } from 'sales/models';

/**
 * The sales-module store Boards action types.
 */
export enum BoardsActionType {
  SEARCH_BOARDS = '[Finances] [Boards] Search Board',
  SEARCH_BOARDS_FAIL = '[Finances] [Boards] Search Board Fail',
  SEARCH_BOARDS_SUCCESS = '[Finances] [Boards] Search Board Success',

  SEARCH_BOARDS_IN_POS = '[Finances] [Boards] Search Board In Pos',
  SEARCH_BOARDS_IN_POS_FAIL = '[Finances] [Boards] Search Board In Pos Fail',
  SEARCH_BOARDS_IN_POS_SUCCESS = '[Finances] [Boards] Search Board In Pos Success',

  FIND_BOARD = '[Finances] [Boards] Find Board',
  FIND_BOARD_FAIL = '[Finances] [Boards] Find Board Fail',
  FIND_BOARD_SUCCESS = '[Finances] [Boards] Find Board Success',

  CREATE_BOARD = '[Finances] [Boards] Create Board',
  CREATE_BOARD_FAIL = '[Finances] [Boards] Create Board Fail',
  CREATE_BOARD_SUCCESS = '[Finances] [Boards] Create Board Success',

  UPDATE_BOARD = '[Finances] [Boards] Update Board',
  UPDATE_BOARD_FAIL = '[Finances] [Boards] Update Board Fail',
  UPDATE_BOARD_SUCCESS = '[Finances] [Boards] Update Board Success',

  DELETE_BOARD = '[Finances] [Boards] Delete Board',
  DELETE_BOARD_FAIL = '[Finances] [Boards] Delete Board Fail',
  DELETE_BOARD_SUCCESS = '[Finances] [Boards] Delete Board Success',
}

/**
 * Represents a store Boards search action.
 */
export class SearchBoards implements Action {
  /**
   * The type of the action.
   */
  readonly type = BoardsActionType.SEARCH_BOARDS;

  /**
   * Fires a new boards search action.
   * @param payload the search parameters,
   * if omitted, all boards will be loaded.
   */
  constructor(public payload?: { description: string; locations: number[]; page: number }) {}
}

/**
 * Represents a store Boards search fail action.
 */
export class SearchBoardsFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = BoardsActionType.SEARCH_BOARDS_FAIL;

  /**
   * Fires a new Boards search fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store search success action.
 */
export class SearchBoardsSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = BoardsActionType.SEARCH_BOARDS_SUCCESS;

  /**
   * Fires a new boards search success action.
   * @param payload An object contains the list of boards that matches the search criteria
   * in addition to the pagination info.
   */
  constructor(public payload: AppHttpResponse<Board[]>) {}
}

/**
 * Represents a store Boards search in pos action.
 */
export class SearchBoardsInPos implements Action {
  /**
   * The type of the action.
   */
  readonly type = BoardsActionType.SEARCH_BOARDS_IN_POS;

  /**
   * Fires a new boards search in pos action.
   * @param payload the search parameters,
   * if omitted, all boards will be loaded.
   */
  constructor(public payload?: { description: string; page: number }) {}
}

/**
 * Represents a store Boards search in pos fail action.
 */
export class SearchBoardsInPosFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = BoardsActionType.SEARCH_BOARDS_IN_POS_FAIL;

  /**
   * Fires a new Boards search in pos fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store search in pos success action.
 */
export class SearchBoardsInPosSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = BoardsActionType.SEARCH_BOARDS_IN_POS_SUCCESS;

  /**
   * Fires a new boards search in pos success action.
   * @param payload An object contains the list of boards that matches the search criteria
   * in addition to the pagination info.
   */
  constructor(public payload: AppHttpResponse<Board[]>) {}
}

/**
 * Represents a store find board action.
 */
export class FindBoard implements Action {
  /**
   * The type of the action.
   */
  readonly type = BoardsActionType.FIND_BOARD;

  /**
   * Fires a new find board action.
   * @param payload the id of the board.
   */
  constructor(public payload: number) {}
}

/**
 * Represents a store find board fail action.
 */
export class FindBoardFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = BoardsActionType.FIND_BOARD_FAIL;

  /**
   * Fires a new find board fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store find board success action.
 */
export class FindBoardSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = BoardsActionType.FIND_BOARD_SUCCESS;

  /**
   * Fires a new find board success action.
   * @param payload The loaded board.
   */
  constructor(public payload: AppHttpResponse<Board>) {}
}

/**
 * Represents a store board create action.
 */
export class CreateBoard implements Action {
  /** The type of the action. */
  readonly type = BoardsActionType.CREATE_BOARD;

  /**
   * Fires a new board create action.
   * @param payload the new board data-model.
   */
  constructor(public payload: CreateBoardInput) {}
}

/**
 * Represents a store board create fail action.
 */
export class CreateBoardFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = BoardsActionType.CREATE_BOARD_FAIL;

  /**
   * Fires a new board create fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store board create success action.
 */
export class CreateBoardSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = BoardsActionType.CREATE_BOARD_SUCCESS;

  /**
   * Fires a new board create success action.
   * @param payload The created board.
   */
  constructor(public payload: AppHttpResponse<Board>) {}
}

/**
 * Represents a store board update action.
 */
export class UpdateBoard implements Action {
  /**
   * The type of the action.
   */
  readonly type = BoardsActionType.UPDATE_BOARD;

  /**
   * Fires a new board update action.
   * @param payload The board's updated data.
   */
  constructor(public payload: UpdateBoardInput) {}
}

/**
 * Represents a store board update fail action.
 */
export class UpdateBoardFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = BoardsActionType.UPDATE_BOARD_FAIL;

  /**
   * Fires a new board update fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store board update success action.
 */
export class UpdateBoardSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = BoardsActionType.UPDATE_BOARD_SUCCESS;

  /**
   * Fires a new board update-success action.
   * @param payload The updated board.
   */
  constructor(public payload: AppHttpResponse<Board>) {}
}

/**
 * Represents a store board delete action.
 */
export class DeleteBoard implements Action {
  /**
   * The type of the action.
   */
  readonly type = BoardsActionType.DELETE_BOARD;

  /**
   * Fires a new board delete action.
   * @param payload The board's id.
   */
  constructor(public payload: number) {}
}

/**
 * Represents a store boards delete fail action.
 */
export class DeleteBoardFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = BoardsActionType.DELETE_BOARD_FAIL;

  /**
   * Fires a new board delete fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store board delete success action.
 */
export class DeleteBoardSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = BoardsActionType.DELETE_BOARD_SUCCESS;

  /**
   * Fires a new board delete success action.
   * @param payload The deleted board.
   */

  constructor(public payload: AppHttpResponse<Board>) {}
}

/**
 * sales-module boards action types.
 */
export type BoardsActions =
  | SearchBoards
  | SearchBoardsFail
  | SearchBoardsSuccess
  | SearchBoardsInPos
  | SearchBoardsInPosFail
  | SearchBoardsInPosSuccess
  | FindBoard
  | FindBoardFail
  | FindBoardSuccess
  | CreateBoard
  | CreateBoardFail
  | CreateBoardSuccess
  | UpdateBoard
  | UpdateBoardFail
  | UpdateBoardSuccess
  | DeleteBoard
  | DeleteBoardFail
  | DeleteBoardSuccess;
