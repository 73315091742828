import { Injectable } from '@angular/core';

import { of } from 'rxjs';
import { switchMap, map, catchError } from 'rxjs/operators';
import { Effect, ofType, Actions } from '@ngrx/effects';

import { LookupsService } from 'lookups/services';
import {
  GetVendorPaymentLogTypesSuccess,
  GetVendorPaymentLogTypesFail,
  VendorPaymentLogTypeActionType,
  GetAllVendorPaymentLogTypesFail,
  GetAllVendorPaymentLogTypesSuccess,
} from 'lookups/store/actions';

@Injectable()
export class VendorPaymentLogTypeEffects {
  constructor(private actions$: Actions, private lookupsService: LookupsService) {}

  /* ========================= GET_VENDOR_PAYMENT_LOG_TYPE =================================== */
  @Effect()
  getVendorPaymentLogTypes$ = this.actions$.pipe(
    ofType(VendorPaymentLogTypeActionType.GET_VENDOR_PAYMENT_LOG_TYPES),
    switchMap(() =>
      this.lookupsService.getVendorPaymentLogTypes().pipe(
        map((response) => new GetVendorPaymentLogTypesSuccess(response)),
        catchError((error) => of(new GetVendorPaymentLogTypesFail(error)))
      )
    )
  );

  /* ========================= GET_ALL_VENDOR_PAYMENT_LOG_TYPE =================================== */
  @Effect()
  getAllVendorPaymentLogTypes$ = this.actions$.pipe(
    ofType(VendorPaymentLogTypeActionType.GET_ALL_VENDOR_PAYMENT_LOG_TYPES),
    switchMap(() =>
      this.lookupsService.getAllVendorPaymentLogTypes().pipe(
        map((response) => new GetAllVendorPaymentLogTypesSuccess(response)),
        catchError((error) => of(new GetAllVendorPaymentLogTypesFail(error)))
      )
    )
  );
}
