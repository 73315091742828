<!-- electronic account list starts -->
<div class="row">
  <div class="col-md-12">
    <div class="px-3">
      <div class="row">
        <div class="col-md-12 alert alert-light text-center" role="alert">
          ({{ (userElectronicAccounts$ | async)?.length | toNumber }})
          {{ 'AUTH.AUTH_DATA.ELECTRONIC_ACCOUNTS_COUNT' | translate }}
        </div>
        <div class="col-md-12" *ngIf="(userElectronicAccounts$ | async)?.length; else noItems">
          <div class="row">
            <div class="col-md-12">
              <div class="table-responsive">
                <table class="table text-center">
                  <thead>
                    <tr>
                      <th scope="col"><i class="fa fa-list-ol"></i></th>
                      <th scope="col">
                        {{ 'AUTH.AUTH_DATA.ELECTRONIC_ACCOUNT_CODE' | translate }}
                      </th>
                      <th scope="col">
                        {{ 'AUTH.AUTH_DATA.ELECTRONIC_ACCOUNT_DESCRIPTION' | translate }}
                      </th>
                      <th scope="col">
                        {{ 'AUTH.AUTH_DATA.IS_DEFAULT' | translate }}
                      </th>
                      <th scope="col">{{ 'SHARED.TOOLS' | translate }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      *ngFor="
                        let electronicAccount of userElectronicAccounts$ | async;
                        trackBy: trackItems;
                        let i = index
                      "
                    >
                      <th scope="row">{{ i + 1 }}</th>
                      <td>{{ electronicAccount.accountNumber }}</td>
                      <td>
                        {{ electronicAccount.name | localize: electronicAccount.name | async }}
                      </td>
                      <td>
                        <app-status
                          [status]="true"
                          [activeText]="'AUTH.AUTH_DATA.ELECTRONIC_ACCOUNT_DEFAULT_VALUE' | translate"
                          *ngIf="userDefaultElectronicBankAccountId === electronicAccount.id"
                        >
                        </app-status>
                      </td>
                      <td>
                        <a
                          *ngIf="userDefaultElectronicBankAccountId !== electronicAccount.id"
                          class="primary p-0"
                          (click)="selectDefaultElectronicAccount(electronicAccount.id)"
                          [ngbTooltip]="'AUTH.SET_AS_DEFAULT_ELECTRONIC_ACCOUNT' | translate"
                        >
                          <i class="fa fa-arrow-circle-down font-medium-3 mr-2"></i>
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- electronic account list ends -->

<!-- no items template starts -->
<ng-template #noItems>
  <div class="col-md-12 alert alert-warning mt-3 text-center" role="alert">
    {{ 'AUTH.SEARCH_ELECTRONIC_ACCOUNT_WITHOUT_ADD_NOT_FOUND' | translate }}
  </div>
</ng-template>
<!-- no items template ends -->
