import { Component, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, FormArray } from '@angular/forms';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: '[app-customer-asset-form-item]',
  templateUrl: './customer-asset-form-item.component.html',
  styles: [],
})
export class CustomerAssetFormItemComponent {
  /**
   * Sets the asset index in the assets-form.
   */
  @Input() index: number;

  /**
   * Sets the asset form-group.
   */
  @Input() assetGroup: FormGroup;

  /**
   * Emits a value when the user wants to remove current asset from the list.
   */
  @Output() removeChange = new EventEmitter<any>();

  /**
   * Gets the attachments as form-array.
   */
  get attachmentsForm(): FormArray {
    return this.assetGroup?.controls.attachments as FormArray;
  }

  /**
   * @param modalService The modal service.
   */
  constructor(private modalService: NgbModal) {}

  /**
   * Remove the asset from the list.
   */
  remove() {
    this.removeChange.emit(null);
  }

  /**
   * Adds a blank attachment form-control to the attachments form for quick start..
   */
  addBlankAttachment() {
    this.attachmentsForm.push(new FormControl());
  }

  /**
   * Opens the modal of the given templateRef.
   * @param modalRef The modal templateRef to be opened.
   */
  openModal(modalRef) {
    this.modalService.open(modalRef);
  }
}
