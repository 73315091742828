import { Action } from '@ngrx/store';

import { AppHttpResponse } from 'shared';
import { Bank, CreateBankInput, UpdateBankInput } from 'finances/models';

/**
 * The finances-module store Banks action types.
 */
export enum BanksActionType {
  SEARCH_BANKS = '[Finances] [Banks] Search Bank',
  SEARCH_BANKS_FAIL = '[Finances] [Banks] Search Bank Fail',
  SEARCH_BANKS_SUCCESS = '[Finances] [Banks] Search Bank Success',

  FIND_BANK = '[Finances] [Banks] Find Bank',
  FIND_BANK_FAIL = '[Finances] [Banks] Find Bank Fail',
  FIND_BANK_SUCCESS = '[Finances] [Banks] Find Bank Success',

  CREATE_BANK = '[Finances] [Banks] Create Bank',
  CREATE_BANK_FAIL = '[Finances] [Banks] Create Bank Fail',
  CREATE_BANK_SUCCESS = '[Finances] [Banks] Create Bank Success',

  UPDATE_BANK = '[Finances] [Banks] Update Bank',
  UPDATE_BANK_FAIL = '[Finances] [Banks] Update Bank Fail',
  UPDATE_BANK_SUCCESS = '[Finances] [Banks] Update Bank Success',

  DELETE_BANK = '[Finances] [Banks] Delete Bank',
  DELETE_BANK_FAIL = '[Finances] [Banks] Delete Bank Fail',
  DELETE_BANK_SUCCESS = '[Finances] [Banks] Delete Bank Success',
}

/**
 * Represents a store Banks search action.
 */
export class SearchBanks implements Action {
  /**
   * The type of the action.
   */
  readonly type = BanksActionType.SEARCH_BANKS;

  /**
   * Fires a new banks search action.
   * @param payload the search parameters,
   * if omitted, all banks will be loaded.
   */
  constructor(public payload?: { name: string; page: number }) {}
}

/**
 * Represents a store Banks search fail action.
 */
export class SearchBanksFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = BanksActionType.SEARCH_BANKS_FAIL;

  /**
   * Fires a new Banks search fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store search success action.
 */
export class SearchBanksSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = BanksActionType.SEARCH_BANKS_SUCCESS;

  /**
   * Fires a new banks search success action.
   * @param payload An object contains the list of banks that matches the search criteria
   * in addition to the pagination info.
   */
  constructor(public payload: AppHttpResponse<Bank[]>) {}
}

/**
 * Represents a store find bank action.
 */
export class FindBank implements Action {
  /**
   * The type of the action.
   */
  readonly type = BanksActionType.FIND_BANK;

  /**
   * Fires a new find bank action.
   * @param payload the id of the bank.
   */
  constructor(public payload: number) {}
}

/**
 * Represents a store find bank fail action.
 */
export class FindBankFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = BanksActionType.FIND_BANK_FAIL;

  /**
   * Fires a new find bank fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store find bank success action.
 */
export class FindBankSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = BanksActionType.FIND_BANK_SUCCESS;

  /**
   * Fires a new find bank success action.
   * @param payload The loaded bank.
   */
  constructor(public payload: AppHttpResponse<Bank>) {}
}

/**
 * Represents a store bank create action.
 */
export class CreateBank implements Action {
  /** The type of the action. */
  readonly type = BanksActionType.CREATE_BANK;

  /**
   * Fires a new bank create action.
   * @param payload the new bank data-model.
   */
  constructor(public payload: CreateBankInput) {}
}

/**
 * Represents a store bank create fail action.
 */
export class CreateBankFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = BanksActionType.CREATE_BANK_FAIL;

  /**
   * Fires a new bank create fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store bank create success action.
 */
export class CreateBankSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = BanksActionType.CREATE_BANK_SUCCESS;

  /**
   * Fires a new bank create success action.
   * @param payload The created bank.
   */
  constructor(public payload: AppHttpResponse<Bank>) {}
}

/**
 * Represents a store bank update action.
 */
export class UpdateBank implements Action {
  /**
   * The type of the action.
   */
  readonly type = BanksActionType.UPDATE_BANK;

  /**
   * Fires a new bank update action.
   * @param payload The bank's updated data.
   */
  constructor(public payload: UpdateBankInput) {}
}

/**
 * Represents a store bank update fail action.
 */
export class UpdateBankFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = BanksActionType.UPDATE_BANK_FAIL;

  /**
   * Fires a new bank update fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store bank update success action.
 */
export class UpdateBankSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = BanksActionType.UPDATE_BANK_SUCCESS;

  /**
   * Fires a new bank update-success action.
   * @param payload The updated bank.
   */
  constructor(public payload: AppHttpResponse<Bank>) {}
}

/**
 * Represents a store bank delete action.
 */
export class DeleteBank implements Action {
  /**
   * The type of the action.
   */
  readonly type = BanksActionType.DELETE_BANK;

  /**
   * Fires a new bank delete action.
   * @param payload The bank's id.
   */
  constructor(public payload: number) {}
}

/**
 * Represents a store banks delete fail action.
 */
export class DeleteBankFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = BanksActionType.DELETE_BANK_FAIL;

  /**
   * Fires a new bank delete fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store bank delete success action.
 */
export class DeleteBankSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = BanksActionType.DELETE_BANK_SUCCESS;

  /**
   * Fires a new bank delete success action.
   * @param payload The deleted bank.
   */

  constructor(public payload: AppHttpResponse<Bank>) {}
}

/**
 * finances-module banks action types.
 */
export type BanksActions =
  | SearchBanks
  | SearchBanksFail
  | SearchBanksSuccess
  | FindBank
  | FindBankFail
  | FindBankSuccess
  | CreateBank
  | CreateBankFail
  | CreateBankSuccess
  | UpdateBank
  | UpdateBankFail
  | UpdateBankSuccess
  | DeleteBank
  | DeleteBankFail
  | DeleteBankSuccess;
