import { Customer, CustomerLog } from 'sales/models';
import { PaginationInfo } from 'shared/models';

/**
 * Represents the customers state.
 */
export class CustomersState {
  /**
   * The list of customers.
   */
  public data: Customer[];

  /**
   * The list of customer logs.
   */
  public customerLogsData: CustomerLog[];

  /**
   * The list of customers payments.
   */
  public customersPaymentsData: CustomerLog[];

  /**
   * The list of customer advance payments.
   */
  public customerAdvancePaymentsData: CustomerLog[];

  /**
   * The pagination info for the customers.
   */
  public paginationInfo: PaginationInfo;

  /**
   * The pagination info for the customer logs.
   */
  public customerLogsPaginationInfo: PaginationInfo;

  /**
   * The pagination info for the customers payments.
   */
  public customersPaymentsPaginationInfo: PaginationInfo;

  /**
   * The pagination info for the customer advance payments.
   */
  public customerAdvancePaymentsPaginationInfo: PaginationInfo;

  /**
   * An error that may resulting during processing some actions.
   */
  public error: any;

  /**
   * Determines if there is a running search process.
   */
  public isSearching: boolean;

  /**
   * Determines if the last search process has been ended successfully.
   */
  public isSearchCompleted: boolean;

  /**
   * Determines if there is a running search-customer-logs process.
   */
  public isSearchingCustomerLogs: boolean;

  /**
   * Determines if the last search-customer-logs process has been ended successfully.
   */
  public isSearchCustomerLogsCompleted: boolean;

  /**
   * Determines if there is a running search-customers-payments process.
   */
  public isSearchingCustomersPayments: boolean;

  /**
   * Determines if the last search-customers-payments process has been ended successfully.
   */
  public isSearchCustomersPaymentsCompleted: boolean;

  /**
   * Determines if the last search-customer-advance-payments process has been ended successfully.
   */
  public isSearchCustomerAdvancePaymentsCompleted: boolean;

  /**
   * Determines if there is a running search-customer-advance-payments process.
   */
  public isSearchingCustomerAdvancePayments: boolean;

  /**
   * The current selected customer.
   */
  public selectedCustomer: Customer;

  /**
   * The current selected customer log.
   */
  public selectedCustomerLog: CustomerLog;

  /**
   * Determines if there is a running find-customer process.
   */
  public isFinding: boolean;

  /**
   * Determines if the last find-customer process has been ended successfully.
   */
  public isFindCompleted: boolean;

  /**
   * Determines if there is a running create process.
   */
  public isCreating: boolean;

  /**
   * Determines if the last create process has been ended successfully.
   */
  public isCreateCompleted: boolean;

  /**
   * Determines if there is a running create advance payment process.
   */
  public isCreatingAdvancePayment: boolean;

  /**
   * Determines if the last create advance payment process has been ended successfully.
   */
  public isCreateAdvancePaymentCompleted: boolean;

  /**
   * Determines if there is a running create Payment By advance payment process.
   */
  public isCreatingPaymentByAdvancePayment: boolean;

  /**
   * Determines if the last create Payment By advance payment process has been ended successfully.
   */
  public isCreatePaymentByAdvancePaymentCompleted: boolean;

  /**
   * Determines if there is a running create payment process.
   */
  public isCreatingPayments: boolean;

  /**
   * Determines if the last create payment process has been ended successfully.
   */
  public isCreatePaymentsCompleted: boolean;

  /**
   * Determines if there is a running create refund process.
   */
  public isRefundCreating: boolean;

  /**
   * Determines if the last create refund process has been ended successfully.
   */
  public isCreateRefundCompleted: boolean;

  /**
   * Determines if there is a running update process.
   */
  public isUpdating: boolean;

  /**
   * Determines if the last update process has been ended successfully.
   */
  public isUpdateCompleted: boolean;

  /**
   * Determines if there is a running block process.
   */
  public isBlocking: boolean;

  /**
   * Determines if the last block process has been ended successfully.
   */
  public isBlockCompleted: boolean;

  /**
   * Determines if there is a running activate process.
   */
  public isActivating: boolean;

  /**
   * Determines if the last activate process has been ended successfully.
   */
  public isActivateCompleted: boolean;

  /**
   * Determines if there is a running delete process.
   */
  public isDeleting: boolean;

  /**
   * Determines if the last delete process has been ended successfully.
   */
  public isDeleteCompleted: boolean;

  /**
   * Determines if there is a running cancel log process.
   */
  isCancelingLog: boolean;

  /**
   * Determines if the last cancel log process has been ended successfully.
   */
  isCancelLogCompleted: boolean;
}
