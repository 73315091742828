import { Action } from '@ngrx/store';

import { AppHttpResponse } from 'shared';
import { Region } from 'stores/models';

/**
 * The AppSearch-module store regions action types.
 */
export enum RegionsActionType {
  SEARCH_REGIONS = '[AppSearch] [Regions] Search Regions',
  SEARCH_REGIONS_FAIL = '[AppSearch] [Regions] Search Regions Fail',
  SEARCH_REGIONS_SUCCESS = '[AppSearch] [Regions] Search Regions Success',
}

/**
 * Represents a store regions search action.
 */
export class SearchRegions implements Action {
  /**
   * The type of the action.
   */
  readonly type = RegionsActionType.SEARCH_REGIONS;

  /**
   * Fires a new regions search action.
   * @param payload the search parameters,
   * if omitted, all regions will be loaded.
   */
  constructor(
    public payload?: {
      name: string;
      page: number;
    }
  ) {}
}

/**
 * Represents a store regions search-fail action.
 */
export class SearchRegionsFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = RegionsActionType.SEARCH_REGIONS_FAIL;

  /**
   * Fires a new regions search-fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store search-success action.
 */
export class SearchRegionsSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = RegionsActionType.SEARCH_REGIONS_SUCCESS;

  /**
   * Fires a new regions search-success action.
   * @param payload An object contains the list of regions that matches the search criteria
   * in addition to the pagination info.
   */
  constructor(public payload: AppHttpResponse<Region[]>) {}
}

/**
 * finances-module regions action types.
 */
export type RegionsActions = SearchRegions | SearchRegionsFail | SearchRegionsSuccess;
