import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { SharedModule } from 'shared';
import { FinancesRoutingModule } from 'finances/finances-routing.module';
import { AppSearchModule } from 'app-search';

import * as fromContainers from 'finances/containers';
import * as fromComponents from 'finances/components';
import * as fromServices from 'finances/services';
import * as fromStore from 'finances/store';

@NgModule({
  declarations: [...fromContainers.CONTAINERS, ...fromComponents.COMPONENTS],
  imports: [
    CommonModule,
    SharedModule,
    FinancesRoutingModule,
    AppSearchModule,
    StoreModule.forFeature('finances', fromStore.reducers),
    EffectsModule.forFeature(fromStore.effects),
  ],
  exports: [
    fromComponents.AccountsSearchComponent,
    fromComponents.CostCentersSearchComponent,
    fromComponents.JournalLineFormComponent,
    fromComponents.JournalLineFormItemComponent,
    fromComponents.JournalDetailsComponent,
    fromComponents.BanksSearchComponent,
    fromComponents.BankAccountsSearchComponent,
    fromComponents.CurrenciesSearchComponent,
    fromComponents.BankAccountsFormComponent,
    fromComponents.CostCentersFormComponent,
  ],
  providers: [...fromServices.SERVICES],
})
export class FinancesModule {}
