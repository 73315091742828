import { Injectable } from '@angular/core';

import { of } from 'rxjs';
import { switchMap, map, catchError } from 'rxjs/operators';
import { Effect, ofType, Actions } from '@ngrx/effects';

import { LookupsService } from 'lookups/services';
import {
  VendorAdvancePaymentLogTypeActionType,
  GetAllVendorAdvancePaymentLogTypeFail,
  GetAllVendorAdvancePaymentLogTypeSuccess,
} from 'lookups/store/actions';

@Injectable()
export class VendorAdvancePaymentLogTypeEffects {
  constructor(private actions$: Actions, private lookupsService: LookupsService) {}

  /* ========================= GET_ALL_VENDOR_ADVANCE_PAYMENT_LOG_TYPES =================================== */
  @Effect()
  GetAllVendorAdvancePaymentLogTypes$ = this.actions$.pipe(
    ofType(VendorAdvancePaymentLogTypeActionType.GET_ALL_VENDOR_ADVANCE_PAYMENT_LOG_TYPES),
    switchMap(() =>
      this.lookupsService.getAllVendorAdvancePaymentLogTypes().pipe(
        map((response) => new GetAllVendorAdvancePaymentLogTypeSuccess(response)),
        catchError((error) => of(new GetAllVendorAdvancePaymentLogTypeFail(error)))
      )
    )
  );
}
