import { BankAccountsListComponent } from 'finances/components/bank-accounts/bank-accounts-list/bank-accounts-list.component';
import { CreateBankAccountComponent } from 'finances/components/bank-accounts/create-bank-account/create-bank-account.component';
import { BankAccountInfoComponent } from 'finances/components/bank-accounts/bank-account-info/bank-account-info.component';
import { BankAccountSummaryComponent } from 'finances/components/bank-accounts/bank-account-summary/bank-account-summary.component';
import { BankAccountsSearchComponent } from 'finances/components/bank-accounts/bank-accounts-search/bank-accounts-search.component';
// tslint:disable-next-line: max-line-length
import { CreateBankAccountChequeDepositComponent } from 'finances/components/bank-accounts/create-bank-account-cheque-deposit/create-bank-account-cheque-deposit.component';
import { CreateBankAccountDepositByEmployeeComponent } from 'finances/components/bank-accounts/create-bank-account-deposit-by-employee/create-bank-account-deposit-by-employee.component';
import { CreateBankAccountWithdrawComponent } from 'finances/components/bank-accounts/create-bank-account-withdraw/create-bank-account-withdraw.component';
import { BankAccountsFormComponent } from 'finances/components/bank-accounts/bank-accounts-form/bank-accounts-form.component';
import { CreateBankAccountMoneyTransferComponent } from 'finances/components/bank-accounts/create-bank-account-money-transfer/create-bank-account-money-transfer.component';
import { CreateBankAccountCashReceiptComponent } from 'finances/components/bank-accounts/create-bank-account-cash-receipt/create-bank-account-cash-receipt.component';
import { CreateBankAccountSettlementDeedComponent } from 'finances/components/bank-accounts/create-bank-account-settlement-deed/create-bank-account-settlement-deed.component';

export * from 'finances/components/bank-accounts/bank-accounts-list/bank-accounts-list.component';
export * from 'finances/components/bank-accounts/create-bank-account/create-bank-account.component';
export * from 'finances/components/bank-accounts/bank-account-info/bank-account-info.component';
export * from 'finances/components/bank-accounts/bank-account-summary/bank-account-summary.component';
export * from 'finances/components/bank-accounts/bank-accounts-search/bank-accounts-search.component';
export * from 'finances/components/bank-accounts/create-bank-account-cheque-deposit/create-bank-account-cheque-deposit.component';
export * from 'finances/components/bank-accounts/create-bank-account-deposit-by-employee/create-bank-account-deposit-by-employee.component';
export * from 'finances/components/bank-accounts/create-bank-account-withdraw/create-bank-account-withdraw.component';
export * from 'finances/components/bank-accounts/bank-accounts-form/bank-accounts-form.component';
export * from 'finances/components/bank-accounts/create-bank-account-money-transfer/create-bank-account-money-transfer.component';
export * from 'finances/components/bank-accounts/create-bank-account-cash-receipt/create-bank-account-cash-receipt.component';
export * from 'finances/components/bank-accounts/create-bank-account-settlement-deed/create-bank-account-settlement-deed.component';

/**
 * The set of components registered on this sub-module.
 */
export const COMPONENTS: any[] = [
  BankAccountsListComponent,
  CreateBankAccountComponent,
  BankAccountInfoComponent,
  BankAccountSummaryComponent,
  BankAccountsSearchComponent,
  CreateBankAccountChequeDepositComponent,
  CreateBankAccountDepositByEmployeeComponent,
  CreateBankAccountWithdrawComponent,
  BankAccountsFormComponent,
  CreateBankAccountMoneyTransferComponent,
  CreateBankAccountCashReceiptComponent,
  CreateBankAccountSettlementDeedComponent,
];
