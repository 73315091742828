import { createSelector } from '@ngrx/store';

import { OrganizationsState, OrganizationState } from 'organizations/store/states';
import { selectOrganizationsState } from 'organizations/store/selectors/feature.selectors';

/* Selectors */

/**
 * Gets the organizations state.
 */
const selectOrganizationState = createSelector(
  selectOrganizationsState,
  (state: OrganizationsState) => state.organizations
);

/**
 * Gets the list of loaded organizations.
 */
export const getOrganizations = createSelector(
  selectOrganizationState,
  (state: OrganizationState) => state.organizations
);

/**
 * Gets the organizations pagination info.
 */
export const getOrganizationsPaginationInfo = createSelector(
  selectOrganizationState,
  (state: OrganizationState) => state.paginationInfo
);

/**
 * Gets the error that occurred in the state.
 */
export const getOrganizationsError = createSelector(selectOrganizationState, (state: OrganizationState) => state.error);

/**
 * Determines if there is a running search process.
 */
export const getOrganizationsSearching = createSelector(
  selectOrganizationState,
  (state: OrganizationState) => state.isSearching
);

/**
 * Determines if the last search process has been ended successfully.
 */
export const getOrganizationsSearchCompleted = createSelector(
  selectOrganizationState,
  (state: OrganizationState) => state.isSearchCompleted
);

/**
 * Gets the selected organization.
 */
export const getSelectedOrganization = createSelector(
  selectOrganizationState,
  (state: OrganizationState) => state.selectedOrganization
);

/**
 * Determines if there is a running find-organization process.
 */
export const getOrganizationsFinding = createSelector(
  selectOrganizationState,
  (state: OrganizationState) => state.isFinding
);

/**
 * Determines if the last find-organization process has been ended successfully.
 */
export const getOrganizationsFindCompleted = createSelector(
  selectOrganizationState,
  (state: OrganizationState) => state.isFindCompleted
);

/**
 * Determines if there is a running create process.
 */
export const getSelectedOrganizationCreating = createSelector(
  selectOrganizationState,
  (state: OrganizationState) => state.isCreating
);

/**
 * Determines if the last create process has been ended successfully.
 */
export const getSelectedOrganizationCreateCompleted = createSelector(
  selectOrganizationState,
  (state: OrganizationState) => state.isCreateCompleted
);

/**
 * Determines if there is a running update process.
 */
export const getSelectedOrganizationUpdating = createSelector(
  selectOrganizationState,
  (state: OrganizationState) => state.isUpdating
);

/**
 * Determines if the last update process has been ended successfully.
 */
export const getSelectedOrganizationUpdateCompleted = createSelector(
  selectOrganizationState,
  (state: OrganizationState) => state.isUpdateCompleted
);

/**
 * Determines if there is a running block process.
 */
export const getSelectedOrganizationBlocking = createSelector(
  selectOrganizationState,
  (state: OrganizationState) => state.isBlocking
);

/**
 * Determines if the last block process has been ended successfully.
 */
export const getSelectedOrganizationBlockCompleted = createSelector(
  selectOrganizationState,
  (state: OrganizationState) => state.isBlockCompleted
);

/**
 * Determines if there is a running activate process.
 */
export const getSelectedOrganizationActivating = createSelector(
  selectOrganizationState,
  (state: OrganizationState) => state.isActivating
);

/**
 * Determines if the last activate process has been ended successfully.
 */
export const getSelectedOrganizationActivateCompleted = createSelector(
  selectOrganizationState,
  (state: OrganizationState) => state.isActivateCompleted
);

/**
 * Determines if there is a running delete process.
 */
export const getSelectedOrganizationDeleting = createSelector(
  selectOrganizationState,
  (state: OrganizationState) => state.isDeleting
);

/**
 * Determines if the last delete process has been ended successfully.
 */
export const getSelectedOrganizationDeleteCompleted = createSelector(
  selectOrganizationState,
  (state: OrganizationState) => state.isDeleteCompleted
);
