import { Injectable } from '@angular/core';

import { of } from 'rxjs';
import { switchMap, map, catchError } from 'rxjs/operators';
import { Effect, ofType, Actions } from '@ngrx/effects';

import { UsersService } from 'app-search/services';
import {
  UsersActionType,
  GetOrganizationUsers,
  GetOrganizationUsersFail,
  GetOrganizationUsersSuccess,
} from 'app-search/store/actions';

@Injectable()
export class UsersEffects {
  constructor(private actions$: Actions, private usersService: UsersService) {}

  /* ========================= GET_ORGANIZATION_USERS =================================== */
  @Effect()
  getOrganizationUsers$ = this.actions$.pipe(
    ofType(UsersActionType.GET_ORGANIZATION_USERS),
    switchMap((action: GetOrganizationUsers) =>
      this.usersService.getOrganizationUsers(action.payload?.name ?? '', action.payload.page).pipe(
        map((response) => new GetOrganizationUsersSuccess(response)),
        catchError((error) => of(new GetOrganizationUsersFail(error)))
      )
    )
  );
}
