import { Action } from '@ngrx/store';

import { EmailType } from 'lookups/models';
import { AppHttpResponse } from 'shared';

/**
 * The Lookups-Module store email type action types.
 */
export enum EmailTypeActionType {
  GET_ALL_EMAIL_TYPES = '[Lookups] [EmailType] Get All Email Types',
  GET_ALL_EMAIL_TYPES_FAIL = '[Lookups] [EmailType] Get All Email Types Fail',
  GET_ALL_EMAIL_TYPES_SUCCESS = '[Lookups] [EmailType] Get All Email Types Success',
}

/**
 * Represents a store email type action.
 */
export class GetAllEmailTypes implements Action {
  /**
   * The type of the action.
   */
  readonly type = EmailTypeActionType.GET_ALL_EMAIL_TYPES;

  /**
   * Fires a new email type action.
   */
  constructor() {}
}

/**
 * Represents a store email type fail action.
 */
export class GetAllEmailTypesFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = EmailTypeActionType.GET_ALL_EMAIL_TYPES_FAIL;

  /**
   * Fires a new email type fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store email type success action.
 */
export class GetAllEmailTypesSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = EmailTypeActionType.GET_ALL_EMAIL_TYPES_SUCCESS;

  /**
   * Fires a email type success action.
   * @param payload An object contains the list of email types.
   */
  constructor(public payload: AppHttpResponse<EmailType[]>) {}
}

/**
 * Lookups-module email type action types.
 */
export type EmailTypeActions = GetAllEmailTypes | GetAllEmailTypesFail | GetAllEmailTypesSuccess;
