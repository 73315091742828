import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { FormGroup, FormArray } from '@angular/forms';

import { Observable, Subscription } from 'rxjs';
import { skip, tap } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import * as fromFinancesStore from 'finances/store';
import { CustomValidators } from 'shared';
import { EmployeeProvisionLink, EmployeeProvisionLinkItem, UpdateEmployeeProvisionLinkInput } from 'finances/models';

@Component({
  selector: 'app-employees-provision-links',
  templateUrl: './employees-provision-links.component.html',
  styles: [],
})
export class EmployeesProvisionLinksComponent implements OnInit, OnDestroy {
  /**
   * The confirm modal template reference.
   */
  @ViewChild('confirmModalRef') confirmModalRef: ElementRef<any>;

  /**
   * Indicates whether there is an update process is running or not.
   */
  isUpdating$: Observable<boolean>;

  /**
   * The list of employee provision links.
   */
  employeeProvisionLinks$: Observable<EmployeeProvisionLink[]>;

  /**
   * The update employee provision links form.
   */
  form: FormGroup;

  /**
   * The set of subscriptions on this components,
   * these subscriptions must be unsubscribed before this component got destroyed.
   */
  subscriptions = new Subscription();

  /**
   * Used to add new employee provision link form items to the employee provision links form.
   */
  newEmployeeProvisionLinks: EmployeeProvisionLinkItem[];

  /**
   * Gets the employee provision links form-array.
   */
  get employeeProvisionLinksForm(): FormArray {
    return this.form?.controls.employeeProvisionLinks as FormArray;
  }

  /**
   * @param modalService The modal service.
   * @param financesStore$ The finances store.
   */
  constructor(private modalService: NgbModal, private financesStore$: Store<fromFinancesStore.FinancesState>) {}

  ngOnInit(): void {
    this.init();
  }

  /**
   * Destroy component data
   */
  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  /**
   * Initialize component data.
   */
  init() {
    this.initForm();

    /* Load the data. */

    this.subscriptions.add(
      this.financesStore$
        .pipe(
          select(fromFinancesStore.getEmployeeProvisionLinks),
          tap((links) => {
            this.resetEmployeeProvisionLinks();

            this.newEmployeeProvisionLinks = links.map((link: EmployeeProvisionLink) => ({
              employeeProvisionLinkId: link.id,
              employeeProvisionLink: link,
              accountId: link.accountId,
              account: link.account,
            }));
          })
        )
        .subscribe()
    );
    this.financesStore$.dispatch(new fromFinancesStore.GetEmployeeProvisionLinks());

    this.isUpdating$ = this.financesStore$.pipe(select(fromFinancesStore.getSelectedEmployeeProvisionLinksUpdating));
  }

  /**
   * Initialize forms and add validators.
   */
  initForm() {
    this.form = new FormGroup({
      employeeProvisionLinks: new FormArray([], CustomValidators.arrayItems(1)),
    });
  }

  /**
   * Resets employee provision links data.
   */
  resetEmployeeProvisionLinks() {
    this.employeeProvisionLinksForm.clear();
  }

  /**
   * Opens the modal of the given templateRef.
   * @param modalRef The modal templateRef to be opened.
   */
  openModal(modalRef) {
    this.modalService.open(modalRef);
  }

  /**
   * Submits the form.
   */
  submit() {
    this.openModal(this.confirmModalRef);
  }

  /**
   * Confirms the form submit.
   */
  confirm() {
    /**
     * The list of employee provision links in the employee provision links form.
     */
    const employeeProvisionLinks = this.employeeProvisionLinksForm.value.map((employeeProvisionLink) => {
      const inputLink: UpdateEmployeeProvisionLinkInput = {
        employeeProvisionLinkId: employeeProvisionLink.employeeProvisionLinkId,
        accountId: employeeProvisionLink.accountId,
      };
      return inputLink;
    });

    /**
     * Dispatch update employee provision links action.
     */
    this.financesStore$.dispatch(
      new fromFinancesStore.UpdateEmployeeProvisionLinks({
        employeeProvisionLinks,
      })
    );
  }
}
