<!-- loan settlements list starts -->
<div class="row">
  <div class="col-md-12">
    <div class="card-content">
      <div class="px-3">
        <div class="row">
          <div class="col-md-12">
            <form class="row" [formGroup]="searchForm" (ngSubmit)="search()">
              <div class="col">
                <div class="row">
                  <div class="form-group col-md-4">
                    <label for="employees">{{ 'FINANCES.HR.EMPLOYEES' | translate }}</label>
                    <ng-select
                      class="round"
                      formControlName="employees"
                      [multiple]="true"
                      [closeOnSelect]="false"
                      labelForId="employees"
                      searchable="true"
                      [placeholder]="'FINANCES.HR.EMPLOYEES_PLACEHOLDER' | translate"
                      [notFoundText]="'SHARED.SEARCH.SEARCH_NOT_FOUND' | translate"
                      (open)="employeesListVisibility = true"
                    >
                      <ng-option *ngFor="let employee of employees" [value]="employee.id">
                        {{
                          employee.employeePersonalDetail.fullName
                            | localize: employee.employeePersonalDetail.fullNameEn
                            | async
                        }}
                      </ng-option>
                    </ng-select>
                  </div>
                  <div class="form-group col-md-4">
                    <label for="departments">{{ 'FINANCES.HR.DEPARTMENTS' | translate }}</label>
                    <ng-select
                      class="round"
                      formControlName="departments"
                      [multiple]="true"
                      [closeOnSelect]="false"
                      labelForId="departments"
                      searchable="true"
                      [placeholder]="'FINANCES.HR.DEPARTMENTS_PLACEHOLDER' | translate"
                      [notFoundText]="'SHARED.SEARCH.SEARCH_NOT_FOUND' | translate"
                      (open)="departmentsListVisibility = true"
                    >
                      <ng-option *ngFor="let department of departments" [value]="department.id">
                        {{ department.name | localize: department.nameEn | async }}
                      </ng-option>
                    </ng-select>
                  </div>
                  <div class="form-group col-md-4">
                    <label for="locations">{{ 'FINANCES.HR.LOCATIONS' | translate }}</label>
                    <ng-select
                      class="round"
                      formControlName="locations"
                      [multiple]="true"
                      [closeOnSelect]="false"
                      labelForId="locations"
                      searchable="true"
                      [placeholder]="'FINANCES.HR.LOCATIONS_PLACEHOLDER' | translate"
                      [notFoundText]="'SHARED.SEARCH.SEARCH_NOT_FOUND' | translate"
                      (open)="locationsListVisibility = true"
                    >
                      <ng-option *ngFor="let location of locations" [value]="location.id">
                        {{ location.name | localize: location.nameEn | async }}
                      </ng-option>
                    </ng-select>
                  </div>
                  <div class="col">
                    <div class="row">
                      <div class="form-group col mb-0">
                        <label for="fromDate">{{ 'SHARED.SEARCH.FROM_DATE' | translate }}</label>
                        <div class="input-group round">
                          <input
                            class="form-control"
                            id="fromDate"
                            name="fromDate"
                            formControlName="fromDate"
                            [placeholder]="'SHARED.SEARCH.DATE_PLACEHOLDER' | translate"
                            ngbDatepicker
                            #fromDate="ngbDatepicker"
                          />
                          <div class="input-group-append">
                            <span
                              class="input-group-text gradient-light-blue-indigo"
                              style="cursor: pointer;"
                              (click)="fromDate.toggle()"
                            >
                              <i class="fa fa-calendar"></i>
                            </span>
                          </div>
                        </div>
                      </div>
                      <div class="form-group col mb-0">
                        <label for="toDate">{{ 'SHARED.SEARCH.TO_DATE' | translate }}</label>
                        <div class="input-group round">
                          <input
                            class="form-control"
                            id="toDate"
                            name="toDate"
                            formControlName="toDate"
                            [placeholder]="'SHARED.SEARCH.DATE_PLACEHOLDER' | translate"
                            ngbDatepicker
                            #toDate="ngbDatepicker"
                          />
                          <div class="input-group-append">
                            <span
                              class="input-group-text gradient-light-blue-indigo"
                              style="cursor: pointer;"
                              (click)="toDate.toggle()"
                            >
                              <i class="fa fa-calendar"></i>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-auto form-group d-flex">
                    <button
                      type="submit"
                      class="btn btn-raised btn-primary align-self-end round mx-1 mb-0"
                      [disabled]="isSearching$ | async"
                    >
                      <i class="fa fa-search"></i>
                    </button>
                    <app-export-tools
                      *ngIf="(settlements$ | async)?.length"
                      class="d-flex align-self-end mx-1"
                      targetElementId="settlements-table-export-target-element"
                      [outputFileName]="'SHARED.EXPORT_TABLES.SETTLEMENTS_TABLE' | translate"
                    ></app-export-tools>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div class="col-md-12" *ngIf="(settlements$ | async)?.length; else noItems">
            <div class="row">
              <div class="col-md-12 alert alert-light text-center" role="alert">
                {{ 'SHARED.SEARCH.SEARCH_RESULTS' | translate }} ({{ (settlements$ | async).length | toNumber }})
                {{ 'HR.EMPLOYEES.EMPLOYEE_SICK_LEAVE_PLAN.SICK_LEAVE_PLAN' | translate }}
              </div>
              <div class="table-responsive">
                <table class="table text-center" id="settlements-table-export-target-element">
                  <thead>
                    <tr>
                      <th scope="col"><i class="fa fa-list-ol"></i></th>
                      <th scope="col">{{ 'FINANCES.HR.SETTLEMENT_VALUE' | translate }}</th>
                      <th scope="col">{{ 'FINANCES.HR.TRANSACTION_DATE' | translate }}</th>
                      <th scope="col">{{ 'SHARED.SEARCH.CREATED_AT' | translate }}</th>
                      <th scope="col">{{ 'FINANCES.HR.USER' | translate }}</th>
                      <th scope="col">
                        {{ 'FINANCES.HR.NOTES' | translate }}
                      </th>
                      <th scope="col">{{ 'SHARED.TOOLS' | translate }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let settlement of settlements$ | async; trackBy: trackItems; let i = index">
                      <th scope="row">{{ i + 1 }}</th>
                      <td>{{ settlement.value | toDecimal }}</td>
                      <td>
                        <span [ngbTooltip]="settlement.transactionDate | date: 'time'">
                          {{ settlement.transactionDate | date }}
                        </span>
                      </td>
                      <td>
                        <span [ngbTooltip]="settlement.createdAt | date: 'time'">
                          {{ settlement.createdAt | date }}
                        </span>
                      </td>
                      <td>{{ settlement.user.name }}</td>
                      <td scope="col">{{ settlement.notes }}</td>
                      <td class="fit-width">
                        <a
                          class="info p-0"
                          (click)="
                            selectedLoanSettlement = settlement; openInfoModal(infoEmployeeLoanSettlementModalRef)
                          "
                          [ngbTooltip]="'FINANCES.HR.INFO_DISPLAY' | translate"
                        >
                          <i class="fa fa-info font-medium-3 mr-2"></i>
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div class="d-flex justify-content-between p-2">
                  <ngb-pagination
                    *ngIf="paginationInfo$ | async; let pagination"
                    maxSize="5"
                    [collectionSize]="pagination.total"
                    [pageSize]="pagination.pageSize"
                    [page]="pagination.page"
                    (pageChange)="pageChanged($event)"
                    boundaryLinks="true"
                  >
                    <ng-template ngbPaginationFirst>{{ 'SHARED.PAGINATION.PAGINATION_FIRST' | translate }}</ng-template>
                    <ng-template ngbPaginationPrevious>{{
                      'SHARED.PAGINATION.PAGINATION_PREVIOUS' | translate
                    }}</ng-template>
                    <ng-template ngbPaginationNext>{{ 'SHARED.PAGINATION.PAGINATION_NEXT' | translate }}</ng-template>
                    <ng-template ngbPaginationLast>{{ 'SHARED.PAGINATION.PAGINATION_LAST' | translate }}</ng-template>
                  </ngb-pagination>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- loan settlements list ends -->

<!-- no items template starts -->
<ng-template #noItems>
  <div class="col-md-12 alert alert-warning mt-3 text-center" role="alert">
    {{ 'HR.EMPLOYEES.LOAN_SETTLEMENTS_NOT_FOUND' | translate }}
  </div>
</ng-template>
<!-- no items template ends -->

<!-- info loan settlement modal starts -->
<ng-template #infoEmployeeLoanSettlementModalRef let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title text-primary" id="modal-basic-title">
      {{ 'FINANCES.HR.INFO_EMPLOYEE_LOAN_SETTLEMENT_TITLE' | translate }}
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="d()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-12">
        <div class="row">
          <div class="form-group col-md-12" *ngIf="selectedLoanSettlement.employeeLoanSettlementLoans?.length">
            <div class="col-md-12 alert alert-light text-center" role="alert">
              ({{ selectedLoanSettlement.employeeLoanSettlementLoans?.length | toNumber }})
              {{ 'FINANCES.HR.LOANS' | translate }}
            </div>
            <div class="col-md-12">
              <div class="table-responsive">
                <table class="table text-center">
                  <thead>
                    <tr>
                      <th scope="col"><i class="fa fa-list-ol"></i></th>
                      <th scope="col">
                        {{ 'FINANCES.HR.LOAN_TYPE' | translate }}
                      </th>
                      <th scope="col">{{ 'FINANCES.HR.VALUE' | translate }}</th>
                      <th scope="col">
                        {{ 'FINANCES.HR.SETTLEMENT_VALUE' | translate }}
                      </th>
                      <th scope="col">{{ 'FINANCES.HR.PAID' | translate }}</th>
                      <th scope="col">
                        {{ 'FINANCES.HR.REMAINING' | translate }}
                      </th>
                      <th scope="col">
                        {{ 'FINANCES.HR.SETTLEMENT_START_DATE' | translate }}
                      </th>
                      <th scope="col">
                        {{ 'FINANCES.HR.TRANSACTION_DATE' | translate }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      *ngFor="
                        let loanSettlementLoan of selectedLoanSettlement.employeeLoanSettlementLoans;
                        let i = index
                      "
                    >
                      <th scope="row">{{ i + 1 }}</th>

                      <td class="fit-width">
                        {{
                          loanSettlementLoan.employeeLoan.loanPaymentType?.name
                            | localize: loanSettlementLoan.employeeLoan.loanPaymentType?.nameEn
                            | async
                        }}
                      </td>
                      <td>
                        <span class="badge bg-info round">
                          {{ loanSettlementLoan.employeeLoan.value | toDecimal }}
                        </span>
                      </td>
                      <td>
                        <span class="badge bg-info round">
                          {{ loanSettlementLoan.value | toDecimal }}
                        </span>
                      </td>
                      <td>
                        <span class="badge bg-warning round">
                          {{ loanSettlementLoan.employeeLoan.paid | toDecimal }}
                        </span>
                      </td>
                      <td>
                        <span class="badge bg-danger round">
                          {{ loanSettlementLoan.employeeLoan.remaining | toDecimal }}
                        </span>
                      </td>
                      <td class="fit-width">
                        <span [ngbTooltip]="loanSettlementLoan.employeeLoan.settlementStartDate | date: 'time'">
                          {{ loanSettlementLoan.employeeLoan.settlementStartDate | date }}
                        </span>
                      </td>
                      <td class="fit-width">
                        <span [ngbTooltip]="loanSettlementLoan.employeeLoan.transactionDate | date: 'time'">
                          {{ loanSettlementLoan.employeeLoan.transactionDate | date }}
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="modal-actions">
      <button type="button" class="btn btn-outline-dark round" (click)="c()">
        <i class="fa fa-times"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.CANCEL' | translate }}</span>
      </button>
    </div>
  </div>
</ng-template>
<!-- info loan settlement modal ends -->

<!-- employees search starts -->
<employees-search [(visible)]="employeesListVisibility" (employees)="selectEmployees($event)"> </employees-search>
<!-- employees search ends -->

<!-- departments search starts -->
<departments-search [(visible)]="departmentsListVisibility" (departments)="selectDepartments($event)">
</departments-search>
<!-- departments search ends -->

<!-- locations search starts -->
<locations-search [(visible)]="locationsListVisibility" (locations)="selectLocations($event)"> </locations-search>
<!-- locations search ends -->
