import { createSelector } from '@ngrx/store';

import { selectFinancesState } from 'finances/store/selectors/feature.selectors';
import { FinancesState, PettyCashRefundRequestsState } from 'finances/store/states';

/* Selectors */

/**
 * Gets the petty cash refund requests state.
 */
const selectPettyCashRefundRequestState = createSelector(
  selectFinancesState,
  (state: FinancesState) => state.pettyCashRefundRequests
);

/**
 * Gets the list of loaded petty cash refund requests.
 */
export const getPettyCashRefundRequests = createSelector(
  selectPettyCashRefundRequestState,
  (state: PettyCashRefundRequestsState) => state.data
);

/**
 * Gets the petty cash refund requests pagination info.
 */
export const getPettyCashRefundRequestsPaginationInfo = createSelector(
  selectPettyCashRefundRequestState,
  (state: PettyCashRefundRequestsState) => state.paginationInfo
);

/**
 * Gets the error that occurred in the state.
 */
export const getPettyCashRefundRequestsError = createSelector(
  selectPettyCashRefundRequestState,
  (state: PettyCashRefundRequestsState) => state.error
);

/**
 * Determines if there is a running search process.
 */
export const getPettyCashRefundRequestsSearching = createSelector(
  selectPettyCashRefundRequestState,
  (state: PettyCashRefundRequestsState) => state.isSearching
);

/**
 * Determines if the last search process has been ended successfully.
 */
export const getPettyCashRefundRequestsSearchCompleted = createSelector(
  selectPettyCashRefundRequestState,
  (state: PettyCashRefundRequestsState) => state.isSearchCompleted
);

/**
 * Gets the selected petty cash refund request.
 */
export const getSelectedPettyCashRefundRequest = createSelector(
  selectPettyCashRefundRequestState,
  (state: PettyCashRefundRequestsState) => state.selectedPettyCashRefundRequest
);

/**
 * Determines if there is a running find-petty-cash-refund-request process.
 */
export const getPettyCashRefundRequestFinding = createSelector(
  selectPettyCashRefundRequestState,
  (state: PettyCashRefundRequestsState) => state.isFinding
);

/**
 * Determines if the last find-petty-cash-refund-request process has been ended successfully.
 */
export const getPettyCashRefundRequestFindCompleted = createSelector(
  selectPettyCashRefundRequestState,
  (state: PettyCashRefundRequestsState) => state.isFindCompleted
);

/**
 * Determines if there is a running create process.
 */
export const getSelectedPettyCashRefundRequestCreating = createSelector(
  selectPettyCashRefundRequestState,
  (state: PettyCashRefundRequestsState) => state.isCreating
);

/**
 * Determines if the last create process has been ended successfully.
 */
export const getSelectedPettyCashRefundRequestCreateCompleted = createSelector(
  selectPettyCashRefundRequestState,
  (state: PettyCashRefundRequestsState) => state.isCreateCompleted
);

/**
 * Determines if there is a running update process.
 */
export const getSelectedPettyCashRefundRequestUpdating = createSelector(
  selectPettyCashRefundRequestState,
  (state: PettyCashRefundRequestsState) => state.isUpdating
);

/**
 * Determines if the last update process has been ended successfully.
 */
export const getSelectedPettyCashRefundRequestUpdateCompleted = createSelector(
  selectPettyCashRefundRequestState,
  (state: PettyCashRefundRequestsState) => state.isUpdateCompleted
);
