import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';

import { AppHttpResponse } from 'shared';
import { CreateTaxInput, Tax, UpdateTaxInput } from 'settings/models';

/**
 * The taxes services includes functionality to create, search, findById,find default tax, update and delete a tax.
 */

@Injectable()
export class TaxesService {
  /**
   * The relative route for the taxes.
   *
   * No leading or trailing slashes required.
   */
  private taxesApi = 'settings/taxes';

  constructor(private http: HttpClient) {}

  /**
   * Creates a new tax from the provided data.
   * @param data The new tax data.
   */
  public create(data: CreateTaxInput): Observable<AppHttpResponse<Tax>> {
    return this.http.post<AppHttpResponse<Tax>>(`${this.taxesApi}`, data);
  }

  /**
   * Searches in the taxes by name.
   * @param name The name of the tax.
   * @param page The current pagination page number.
   * @param pageSize The maximum number of taxes allowed per one pagination page.
   */
  public search(name: string, page: number, pageSize: number): Observable<AppHttpResponse<Tax[]>> {
    const params = new HttpParams().set('name', name).set('page', page.toString()).set('pageSize', pageSize.toString());
    return this.http.get<AppHttpResponse<Tax[]>>(`${this.taxesApi}`, { params });
  }

  /**
   * Finds the tax with the given id.
   * @param id The id of the tax.
   */
  public findById(id: number): Observable<AppHttpResponse<Tax>> {
    return this.http.get<AppHttpResponse<Tax>>(`${this.taxesApi}/${id}`);
  }

  /**
   * Finds the default tax.
   */
  public findDefaultTax(): Observable<AppHttpResponse<Tax>> {
    return this.http.get<AppHttpResponse<Tax>>(`${this.taxesApi}/default`);
  }

  /**
   * Updates an existing tax data using the provided data.
   * @param data The updated tax data.
   */
  public update(data: UpdateTaxInput): Observable<AppHttpResponse<Tax>> {
    return this.http.put<AppHttpResponse<Tax>>(`${this.taxesApi}`, data);
  }

  /**
   * Deletes the tax by given id.
   * @param id The id of the tax.
   */
  public delete(id: number): Observable<AppHttpResponse<Tax>> {
    return this.http.delete<AppHttpResponse<Tax>>(`${this.taxesApi}/${id}`);
  }
}
