import { Action } from '@ngrx/store';

import { AppHttpResponse } from 'shared';
import { Journal, CreateJournalInput } from 'finances/models';

/**
 * The finances-module store journals action types.
 */
export enum JournalsActionType {
  SEARCH_JOURNALS = '[Finances] [Journals] Search Journals',
  SEARCH_JOURNALS_FAIL = '[Finances] [Journals] Search Journals Fail',
  SEARCH_JOURNALS_SUCCESS = '[Finances] [Journals] Search Journals Success',

  FIND_JOURNAL = '[Finances] [Journals] Find Journal',
  FIND_JOURNAL_FAIL = '[Finances] [Journals] Find Journal Fail',
  FIND_JOURNAL_SUCCESS = '[Finances] [Journals] Find Journal Success',

  CREATE_JOURNAL = '[Finances] [Journals] Create Journal',
  CREATE_JOURNAL_FAIL = '[Finances] [Journals] Create Journal Fail',
  CREATE_JOURNAL_SUCCESS = '[Finances] [Journals] Create Journal Success',
}

/**
 * Represents a store journals search action.
 */
export class SearchJournals implements Action {
  /**
   * The type of the action.
   */
  readonly type = JournalsActionType.SEARCH_JOURNALS;

  /**
   * Fires a new journals search action.
   * @param payload the search parameters,
   * if omitted, all journals will be loaded.
   */
  constructor(
    public payload?: {
      journalTypes: number[];
      journalNum: number;
      fromDate: Date;
      toDate: Date;
      page: number;
    }
  ) {}
}

/**
 * Represents a store journals search fail action.
 */
export class SearchJournalsFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = JournalsActionType.SEARCH_JOURNALS_FAIL;

  /**
   * Fires a new journal search fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store search success action.
 */
export class SearchJournalsSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = JournalsActionType.SEARCH_JOURNALS_SUCCESS;

  /**
   * Fires a new journal search success action.
   * @param payload An object contains the list of journals that matches the search criteria
   * in addition to the pagination info.
   */
  constructor(public payload: AppHttpResponse<Journal[]>) {}
}

/**
 * Represents a store Find journal action.
 */
export class FindJournal implements Action {
  /**
   * The type of the action.
   */
  readonly type = JournalsActionType.FIND_JOURNAL;

  /**
   * Fires a new Find journal action.
   * @param payload the id of the journal.
   */
  constructor(public payload: number) {}
}

/**
 * Represents a store Find journal fail action.
 */
export class FindJournalFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = JournalsActionType.FIND_JOURNAL_FAIL;

  /**
   * Fires a new Find journal fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store Find journal success action.
 */
export class FindJournalSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = JournalsActionType.FIND_JOURNAL_SUCCESS;

  /**
   * Fires a new Find journal success action.
   * @param payload The loaded journal.
   */
  constructor(public payload: AppHttpResponse<Journal>) {}
}

/**
 * Represents a store journal create action.
 */
export class CreateJournal implements Action {
  /** The type of the action. */
  readonly type = JournalsActionType.CREATE_JOURNAL;

  /**
   * Fires a new journal create action.
   * @param payload the new journal data-model.
   */
  constructor(public payload: CreateJournalInput) {}
}

/**
 * Represents a store journal create fail action.
 */
export class CreateJournalFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = JournalsActionType.CREATE_JOURNAL_FAIL;

  /**
   * Fires a new journal create fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store journal create success action.
 */
export class CreateJournalSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = JournalsActionType.CREATE_JOURNAL_SUCCESS;

  /**
   * Fires a new journal create success action.
   * @param payload The created journal.
   */
  constructor(public payload: AppHttpResponse<Journal>) {}
}

/**
 * finances-module journals action types.
 */
export type journalsActions =
  | SearchJournals
  | SearchJournalsFail
  | SearchJournalsSuccess
  | FindJournal
  | FindJournalFail
  | FindJournalSuccess
  | CreateJournal
  | CreateJournalFail
  | CreateJournalSuccess;
