import { createSelector } from '@ngrx/store';

import { SalesState, CustomerAssetsState } from 'sales/store/states';
import { selectSalesState } from 'sales/store/selectors/feature.selectors';

/**
 * Gets the customer assets state.
 */
const selectCustomerAssetsState = createSelector(selectSalesState, (state: SalesState) => state.customerAssets);

/**
 * Gets the list of loaded customer assets.
 */
export const getCustomerAssets = createSelector(selectCustomerAssetsState, (state: CustomerAssetsState) => state.data);

/**
 * Gets the customer assets pagination info.
 */
export const getCustomerAssetsPaginationInfo = createSelector(
  selectCustomerAssetsState,
  (state: CustomerAssetsState) => state.paginationInfo
);

/**
 * Gets the error that occurred in the state.
 */
export const getCustomerAssetsError = createSelector(
  selectCustomerAssetsState,
  (state: CustomerAssetsState) => state.error
);

/**
 * Gets the selected customer assets.
 */
export const getSelectedCustomerAsset = createSelector(
  selectCustomerAssetsState,
  (state: CustomerAssetsState) => state.selectedCustomerAsset
);

/**
 * Determines if there is a running search process.
 */
export const getCustomerAssetsSearching = createSelector(
  selectCustomerAssetsState,
  (state: CustomerAssetsState) => state.isSearching
);

/**
 * Determines if the last search process has been ended successfully.
 */
export const getCustomersAssetsSearchCompleted = createSelector(
  selectCustomerAssetsState,
  (state: CustomerAssetsState) => state.isSearchCompleted
);

/**
 * Determines if there is a running find-customer-assets process.
 */
export const getCustomersAssetsFinding = createSelector(
  selectCustomerAssetsState,
  (state: CustomerAssetsState) => state.isFinding
);

/**
 * Determines if the last find-customer-assets process has been ended successfully.
 */
export const getCustomerAssetsFindCompleted = createSelector(
  selectCustomerAssetsState,
  (state: CustomerAssetsState) => state.isFindCompleted
);

/**
 * Determines if there is a running create process.
 */
export const getSelectedCustomerAssetCreating = createSelector(
  selectCustomerAssetsState,
  (state: CustomerAssetsState) => state.isCreating
);

/**
 * Determines if the last create process has been ended successfully.
 */
export const getSelectedCustomerAssetCreateCompleted = createSelector(
  selectCustomerAssetsState,
  (state: CustomerAssetsState) => state.isCreateCompleted
);

/**
 * Determines if there is a running update customer asset process.
 */
export const getSelectedCustomerAssetUpdating = createSelector(
  selectCustomerAssetsState,
  (state: CustomerAssetsState) => state.isUpdating
);

/**
 * Determines if the last update customer asset process has been ended successfully.
 */
export const getSelectedCustomerAssetUpdateCompleted = createSelector(
  selectCustomerAssetsState,
  (state: CustomerAssetsState) => state.isUpdateCompleted
);

/**
 * Determines if there is a running customer asset delete process.
 */
export const getSelectedCustomerAssetDeleting = createSelector(
  selectCustomerAssetsState,
  (state: CustomerAssetsState) => state.isDeleting
);

/**
 * Determines if the last delete customer asset process has been ended successfully.
 */
export const getSelectedCustomerAssetDeleteCompleted = createSelector(
  selectCustomerAssetsState,
  (state: CustomerAssetsState) => state.isDeleteCompleted
);

/**
 * Determines if there is a running get customer assets process.
 */
export const getCustomerAssetsGetting = createSelector(
  selectCustomerAssetsState,
  (state: CustomerAssetsState) => state.isGetting
);

/**
 * Determines if the last get customer assets process has been ended successfully.
 */
export const getCustomerAssetsGetCompleted = createSelector(
  selectCustomerAssetsState,
  (state: CustomerAssetsState) => state.isGetCompleted
);
