import { Component, Input } from '@angular/core';

import { AssetDepreciation } from 'assets/models';

@Component({
  selector: 'app-journal-asset-depreciation-details',
  templateUrl: './journal-asset-depreciation-details.component.html',
  styles: [],
})
export class JournalAssetDepreciationDetailsComponent {
  /**
   * Sets the current journal asset depreciation details.
   */
  @Input() assetDepreciation: AssetDepreciation;
}
