import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { Account } from 'finances/models';

@Component({
  selector: '[app-gov-services-form-item]',
  templateUrl: './gov-services-form-item.component.html',
  styles: [],
})
export class GovServicesFormItemComponent implements OnInit {
  /**
   * Sets the gov service index in the services-form.
   */
  @Input() index: number;

  /**
   * Sets the gov service form-group.
   */
  @Input() govServicesGroup: FormGroup;

  /**
   * Emits a value indicates that a drop-down field in the form is opened and therefore the table height should be expanded.
   */
  @Output() dropDownIsOpened = new EventEmitter<boolean>(false);

  /**
   * The list of selected advance accounts.
   */
  advanceAccounts: Account[] = [];

  /**
   * The list of selected expense accounts.
   */
  expenseAccounts: Account[] = [];

  /**
   * Shows or hide the accounts list.
   */
  advanceAccountsListVisibility = false;

  /**
   * Shows or hide the accounts list.
   */
  expenseAccountsListVisibility = false;

  /**
   * Returns the list of advance accounts that should be displayed in the accounts select list.
   */
  get advanceAccountsList(): Account[] {
    const list: Account[] = [];

    if (this.advanceAccounts?.length) {
      list.push(...this.advanceAccounts);
    }
    if (this.govServicesGroup?.value?.advanceAccount) {
      list.push(this.govServicesGroup.value.advanceAccount);
    }

    return list;
  }

  /**
   * Returns the list of expense accounts that should be displayed in the accounts select list.
   */
  get expenseAccountsList(): Account[] {
    const list: Account[] = [];

    if (this.expenseAccounts?.length) {
      list.push(...this.expenseAccounts);
    }
    if (this.govServicesGroup?.value?.expenseAccount) {
      list.push(this.govServicesGroup.value.expenseAccount);
    }

    return list;
  }

  /**
   * Destroy component data
   */
  ngOnInit(): void {}

  /**
   * Selects the newly selected account from the accounts search list.
   * @param accounts The list of newly selected account to select the only one in the list.
   */
  selectAdvanceAccount([account]: Account[]) {
    if (account) {
      this.advanceAccounts = [account];
      this.govServicesGroup.patchValue({ advanceAccountId: account.id });
    }
  }

  /**
   * Selects the newly selected account from the accounts search list.
   * @param accounts The list of newly selected account to select the only one in the list.
   */
  selectExpenseAccount([account]: Account[]) {
    if (account) {
      this.expenseAccounts = [account];
      this.govServicesGroup.patchValue({ expenseAccountId: account.id });
    }
  }
}
