import { PhoneType } from 'lookups/models';

/**
 * Represents the phone types state.
 */
export class PhoneTypeState {
  /**
   * The list of phone types.
   */
  public data: PhoneType[];

  /**
   * An error that may resulting during processing some actions.
   */
  public error: any;

  /**
   * Determines if there is a running load process.
   */
  public isLoading: boolean;

  /**
   * Determines if the last load process has been ended successfully.
   */
  public isLoaded: boolean;
}
