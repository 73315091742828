import { Action } from '@ngrx/store';

import { Quotation, CreateQuotationInput } from 'crm/models';
import { AppHttpResponse } from 'shared';

/**
 * The CRM-Module store quotations action types.
 */
export enum QuotationsActionType {
  SEARCH_QUOTATIONS = '[CRM] [Quotations] Search Quotations',
  SEARCH_QUOTATIONS_FAIL = '[CRM] [Quotations] Search Quotations Fail',
  SEARCH_QUOTATIONS_SUCCESS = '[CRM] [Quotations] Search Quotations Success',

  FIND_QUOTATION = '[CRM] [Quotations] Find quotation',
  FIND_QUOTATION_FAIL = '[CRM] [Quotations] Find quotation Fail',
  FIND_QUOTATION_SUCCESS = '[CRM] [Quotations] Find quotation Success',

  CREATE_QUOTATION = '[CRM] [Quotations] Create quotation',
  CREATE_QUOTATION_FAIL = '[CRM] [Quotations] Create quotation Fail',
  CREATE_QUOTATION_SUCCESS = '[CRM] [Quotations] Create quotation Success',

  REJECT_QUOTATION = '[CRM] [Quotations] Reject quotation',
  REJECT_QUOTATION_FAIL = '[CRM] [Quotations] Reject quotation Fail',
  REJECT_QUOTATION_SUCCESS = '[CRM] [Quotations] Reject quotation Success',
}

/**
 * Represents a store quotations search action.
 */
export class SearchQuotations implements Action {
  /**
   * The type of the action.
   */
  readonly type = QuotationsActionType.SEARCH_QUOTATIONS;

  /**
   * Fires a new quotations search action.
   * @param payload the search parameters,
   * if omitted, all quotations will be loaded.
   */
  constructor(
    public payload?: { leads: number[]; quotationStatuses: number[]; fromDate: Date; toDate: Date; page: number }
  ) {}
}

/**
 * Represents a store quotations search-fail action.
 */
export class SearchQuotationsFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = QuotationsActionType.SEARCH_QUOTATIONS_FAIL;

  /**
   * Fires a new quotations search-fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store search-success action.
 */
export class SearchQuotationsSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = QuotationsActionType.SEARCH_QUOTATIONS_SUCCESS;

  /**
   * Fires a new quotations search-success action.
   * @param payload An object contains the list of quotations that matches the search criteria
   * in addition to the pagination info.
   */
  constructor(public payload: AppHttpResponse<Quotation[]>) {}
}

/**
 * Represents a store find-quotation action.
 */
export class FindQuotation implements Action {
  /**
   * The type of the action.
   */
  readonly type = QuotationsActionType.FIND_QUOTATION;

  /**
   * Fires a new find quotation action.
   * @param payload the id of the quotation.
   */
  constructor(public payload: number) {}
}

/**
 * Represents a store find-quotation-fail action.
 */
export class FindQuotationFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = QuotationsActionType.FIND_QUOTATION_FAIL;

  /**
   * Fires a new find-quotation-fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store find quotation-success action.
 */
export class FindQuotationSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = QuotationsActionType.FIND_QUOTATION_SUCCESS;

  /**
   * Fires a new find-quotation-success action.
   * @param payload The loaded quotation.
   */
  constructor(public payload: AppHttpResponse<Quotation>) {}
}

/**
 * Represents a store quotation create action.
 */
export class CreateQuotation implements Action {
  /** The type of the action. */
  readonly type = QuotationsActionType.CREATE_QUOTATION;

  /**
   * Fires a new quotation create action.
   * @param payload the new quotation data-model.
   */
  constructor(public payload: CreateQuotationInput) {}
}

/**
 * Represents a store quotation create-fail action.
 */
export class CreateQuotationFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = QuotationsActionType.CREATE_QUOTATION_FAIL;

  /**
   * Fires a new quotation create-fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store quotation create-success action.
 */
export class CreateQuotationSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = QuotationsActionType.CREATE_QUOTATION_SUCCESS;

  /**
   * Fires a new quotation create-success action.
   * @param payload The created quotation.
   */
  constructor(public payload: AppHttpResponse<Quotation>) {}
}

/**
 * Represents a store quotation reject-fail action.
 */
export class RejectQuotationFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = QuotationsActionType.REJECT_QUOTATION_FAIL;

  /**
   * Fires a new quotation reject fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store quotation reject-success action.
 */
export class RejectQuotationSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = QuotationsActionType.REJECT_QUOTATION_SUCCESS;

  /**
   * Fires a new quotation reject-success action.
   * @param payload The reject quotation.
   */
  constructor(public payload: AppHttpResponse<Quotation>) {}
}

/**
 * Represents a store quotation reject action.
 */
export class RejectQuotation implements Action {
  /**
   * The type of the action.
   */
  readonly type = QuotationsActionType.REJECT_QUOTATION;

  /**
   * Fires a new quotation reject action.
   * @param payload The quotation reject's id.
   */
  constructor(public payload: number) {}
}

/**
 * CRM-module quotations action types.
 */
export type QuotationsActions =
  | SearchQuotations
  | SearchQuotationsFail
  | SearchQuotationsSuccess
  | FindQuotation
  | FindQuotationFail
  | FindQuotationSuccess
  | CreateQuotation
  | CreateQuotationFail
  | CreateQuotationSuccess
  | RejectQuotationFail
  | RejectQuotation
  | RejectQuotationSuccess;
