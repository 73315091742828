import { createSelector } from '@ngrx/store';

import { selectLookupsState } from 'lookups/store/selectors/feature.selectors';
import { LookupsState, PurchaseInvoiceTypeState } from 'lookups/store/states';

/**
 * Gets the purchase invoice type state.
 */
const selectPurchaseInvoiceTypes = createSelector(
  selectLookupsState,
  (state: LookupsState) => state.purchaseInvoiceTypes
);

/**
 * Gets the list of loaded purchase invoice types.
 */
export const getPurchaseInvoiceTypes = createSelector(
  selectPurchaseInvoiceTypes,
  (state: PurchaseInvoiceTypeState) => state.data
);

/**
 * Gets the error that occurred in the state.
 */
export const getPurchaseInvoiceTypesError = createSelector(
  selectPurchaseInvoiceTypes,
  (state: PurchaseInvoiceTypeState) => state.error
);

/**
 * Determines if there is a running load process.
 */
export const getPurchaseInvoiceTypesIsLoading = createSelector(
  selectPurchaseInvoiceTypes,
  (state: PurchaseInvoiceTypeState) => state.isLoading
);

/**
 * Determines if the last load process has been ended successfully.
 */
export const getPurchaseInvoiceTypesIsLoaded = createSelector(
  selectPurchaseInvoiceTypes,
  (state: PurchaseInvoiceTypeState) => state.isLoaded
);
