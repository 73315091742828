import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormControl, FormArray } from '@angular/forms';

import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

import { NotificationService, PaginationInfo, TranslationService } from 'shared';
import * as fromFinancesStore from 'finances/store';
import { CostCenter } from 'finances/models';
import { Claims } from 'security/models';

/**
 * A cost centers search form wrapped in modal.
 */
@Component({
  selector: 'app-cost-centers-search',
  templateUrl: './cost-centers-search.component.html',
  styles: [],
})
export class CostCentersSearchComponent implements OnInit {
  /**
   * Indicates whether the modal will automatically close after select the first cost center.
   *
   * Be default it will not be closed.
   */
  @Input() closeOnSelect = false;

  /**
   * Indicates whether the banks displayed on the list should be all active.
   *
   * Be default it will not be closed.
   */
  @Input() onlyActive = false;

  /**
   * Sets the visibility of this component modal.
   *
   * Handles visibility change in two-data-binding approach.
   */
  @Input() set visible(isShown: boolean) {
    if (isShown && this.modalRef) {
      this.openModal(this.modalRef);
    } else {
      this.closeModal();
    }

    this.visibleChange.emit(isShown);
  }

  /**
   * Sets the cost centers form-array.
   */
  @Input() costCentersForm: FormArray;

  /**
   * Outputs a value that indicates whether the modal is shown or not.
   *
   * Handles visibility change in two-data-binding approach.
   */
  @Output() visibleChange = new EventEmitter<boolean>();

  /** Outputs the selected cost centers. */
  @Output() costCenters = new EventEmitter<CostCenter[]>();

  /**
   * The modal template reference.
   */
  @ViewChild('modalRef') modalRef: ElementRef<any>;

  /**
   * Shows or hide the create cost center modal.
   */
  createCostCenterModalVisibility = false;

  /**
   * The list of cost centers.
   */
  costCenters$: Observable<CostCenter[]>;

  /**
   * The list of cost centers.
   */
  costCentersList: CostCenter[];

  /**
   * The pagination info.
   */
  paginationInfo$: Observable<PaginationInfo>;

  /**
   * The system supported user claims.
   */
  Claims = Claims;

  /**
   * The search form.
   */
  searchForm: FormGroup;

  /**
   * The ngb-modal reference to use it when modal needed to be closed.
   */
  modal: NgbModalRef;

  /**
   * @param modalService The modal service.
   * @param notificationService The notification service.
   * @param financesStore$ The finances store.
   * @param translationService The translation service.
   */
  constructor(
    private modalService: NgbModal,
    private notificationService: NotificationService,
    private financesStore$: Store<fromFinancesStore.FinancesState>,
    private translationService: TranslationService
  ) {}

  ngOnInit(): void {
    this.init();
  }

  /**
   * Initialize component data.
   */
  init() {
    this.initForm();

    let isManualSearchTriggeredBeforeCostCenters = false;
    this.costCenters$ = this.financesStore$.pipe(
      select(fromFinancesStore.getCostCenters),
      tap((costCenters) => {
        if (costCenters) {
          this.costCentersList = costCenters;
        }
        if (!isManualSearchTriggeredBeforeCostCenters && !costCenters.length) {
          isManualSearchTriggeredBeforeCostCenters = true;
          this.search();
        }
      })
    );

    this.paginationInfo$ = this.financesStore$.pipe(select(fromFinancesStore.getCostCentersPaginationInfo));
  }

  /**
   * Initialize form and add validators.
   */
  initForm() {
    this.searchForm = new FormGroup({
      description: new FormControl(''),
      page: new FormControl(1),
    });
  }

  /**
   * Handles search parameters change.
   */
  search(event?: KeyboardEvent) {
    if (event && event.key === 'Enter') {
      event.preventDefault();
      return;
    }

    this.applyFiltersAndSearch();
  }

  /**
   * Handles pagination page-changed event.
   * @param page The current selected page number.
   */
  pageChanged(page: number) {
    /** Update pagination page. */
    this.searchForm.patchValue({ page });

    this.applyFiltersAndSearch(page);
  }

  /**
   * Enables trackBy feature for *ngFor utility to track items
   * depending on a comparer to reduce DOM change.
   * If the item is already exist then no HTML will change, only bounded values.
   * @param item The item to determine if it was changed or not.
   */
  trackItems(item: CostCenter) {
    return item ? item.id : undefined;
  }

  /**
   * Handles cost center selected event.
   * @param costCenters The list of cost centers to notify selected.
   */
  selectCostCenter(costCenters: CostCenter[]) {
    if (this.costCentersForm) {
      for (let index = 0; index < this.costCentersForm.length; index++) {
        if (this.costCentersForm.value[index].costCenterId === costCenters[0].id) {
          if (this.closeOnSelect) {
            /**
             * Message to warn the user before selected cost center again.
             */
            this.notificationService.warning(
              this.translationService.translate('FINANCES.COST_CENTERS.NOTIFICATION_MESSAGE.SELECTED_COST_CENTER_AGAIN')
            );
            this.closeModal();
            return;
          }
          return;
        }
      }
    }

    this.notificationService.successInstant(
      this.translationService.translate('FINANCES.COST_CENTERS.NOTIFICATION_MESSAGE.COST_CENTER_SELECTED')
    );

    this.costCenters.emit(costCenters);
    if (this.closeOnSelect) {
      this.closeModal();
    }
  }

  applyFiltersAndSearch(page: number = 1) {
    const { description } = this.searchForm.value;
    this.financesStore$.dispatch(
      new fromFinancesStore.SearchCostCenters({
        description,
        page,
      })
    );
  }

  /**
   * Opens the modal of the given templateRef.
   * @param modalRef The modal templateRef to be opened.
   */
  openModal(modalRef) {
    /**
     * Open the modal & listen for user press ESC key.
     */
    this.modal = this.modalService.open(modalRef, { size: 'lg' });
    this.modal.result.then(null, () => this.closeModal());
  }

  /**
   * Closes the currently opened modal.
   */
  closeModal() {
    this.modal?.dismiss();
    this.costCenters.emit([]);
    this.visibleChange.emit(false);
  }
}
