import { createSelector } from '@ngrx/store';

import { selectLookupsState } from 'lookups/store/selectors/feature.selectors';
import { LookupsState, GovServiceRequestPaymentMethodsState } from 'lookups/store/states';

/**
 * Gets the gov service request payment methods state.
 */
const selectGovServiceRequestPaymentMethods = createSelector(
  selectLookupsState,
  (state: LookupsState) => state.govServiceRequestPaymentMethods
);

/**
 * Gets the list of loaded gov service request payment methods.
 */
export const getGovServiceRequestPaymentMethods = createSelector(
  selectGovServiceRequestPaymentMethods,
  (state: GovServiceRequestPaymentMethodsState) => state.data
);

/**
 * Gets the error that occurred in the state.
 */
export const getGovServiceRequestPaymentMethodsError = createSelector(
  selectGovServiceRequestPaymentMethods,
  (state: GovServiceRequestPaymentMethodsState) => state.error
);

/**
 * Determines if there is a running load process.
 */
export const getGovServiceRequestPaymentMethodsIsLoading = createSelector(
  selectGovServiceRequestPaymentMethods,
  (state: GovServiceRequestPaymentMethodsState) => state.isLoading
);

/**
 * Determines if the last load process has been ended successfully.
 */
export const getGovServiceRequestPaymentMethodsIsLoaded = createSelector(
  selectGovServiceRequestPaymentMethods,
  (state: GovServiceRequestPaymentMethodsState) => state.isLoaded
);
