import { AssetCategory } from 'lookups/models';

/**
 * Represents the AssetCategories state.
 */
export class AssetCategoryState {
  /**
   * The list of AssetCategories.
   */
  public data: AssetCategory[];

  /**
   * An error that may resulting during processing some actions.
   */
  public error: any;

  /**
   * Determines if there is a running load process.
   */
  public isLoading: boolean;

  /**
   * Determines if the last load process has been ended successfully.
   */
  public isLoaded: boolean;
}
