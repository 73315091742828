import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { SaleInvoice, CustomerPaymentFormItem } from 'sales/models';
import { APP_CONSTANTS, CustomValidators } from 'shared';

@Component({
  selector: 'app-customer-payments-form',
  templateUrl: './customer-payments-form.component.html',
  styles: [],
})
export class CustomerPaymentsFormComponent {
  /**
   * The confirm modal template reference.
   */
  @ViewChild('deleteModalRef') deleteModalRef: ElementRef<any>;

  /**
   * Sets the sale invoices form-array.
   */
  @Input() formArray: FormArray;

  /**
   * Sets the name form-array in its parent form.
   * @default 'payments'.
   */
  @Input() formArrayName = 'payments';

  /**
   * Adds a list of new sale invoice form items to the sale invoices form.
   * @param items The list of sale invoice form items to be added to the sale invoices form.
   */
  @Input() set newInvoices(items: CustomerPaymentFormItem[]) {
    if (!items?.length) {
      return;
    }

    items.forEach((item) => this.createInvoiceFormGroup(item));
  }

  /**
   * Sets the exclusive customer id to only show his invoices.
   */
  @Input() exclusiveCustomerId: number;

  /**
   * The decimal mask.
   */
  readonly DECIMAL_MASK = APP_CONSTANTS.numeric.decimal.mask;

  /**
   * Shows or hides the sale invoices list.
   */
  invoicesListVisibility = false;

  /**
   * @param modalService The modal service.
   */
  constructor(private modalService: NgbModal) {}

  /**
   * Creates and returns a new sale invoice form item from a given sale invoice.
   * @param saleInvoice The sale invoice to be used to create the sale invoice form item.
   */
  createInvoiceFormItemFromInvoice(saleInvoice: SaleInvoice): CustomerPaymentFormItem {
    return {
      invoice: saleInvoice,
      value: saleInvoice.remaining,
    };
  }

  /**
   * Create a new sale invoice form group from the provided sale invoice form item.
   * @param item The sale invoice form item that contains data about the new sale invoice.
   */
  createInvoiceFormGroup(item: CustomerPaymentFormItem) {
    if (this.isInvoiceExists(item.invoice.id)) {
      return;
    }

    const formGroup = new FormGroup({
      invoice: new FormControl(item.invoice),
      value: new FormControl(item.value, [Validators.required, CustomValidators.gt(0)]),
    });
    this.formArray.push(formGroup);
  }

  /**
   * Opens the invoices search modal to add a new invoice.
   */
  addInvoice() {
    this.invoicesListVisibility = true;
  }

  /**
   * Opens the modal of the given templateRef.
   * @param modalRef The modal templateRef to be opened.
   */
  openModal(modalRef) {
    this.modalService.open(modalRef);
  }

  /**
   * Open confirm delete all payments modal .
   */
  openDeleteModal() {
    this.openModal(this.deleteModalRef);
  }

  /**
   * Remove the payment at the given index from the payments form.
   * @param index The index of the payment form group.
   */
  removeInvoice(index: number) {
    this.formArray.removeAt(index);
  }

  /**
   * Remove all of the list of payments.
   */
  resetInvoices() {
    this.formArray.clear();
  }

  /**
   * Handles the invoices select event.
   * @param invoices The newly selected invoices.
   */
  selectInvoices(invoices: SaleInvoice[]) {
    if (invoices) {
      invoices.map((invoice) => this.createInvoiceFormGroup(this.createInvoiceFormItemFromInvoice(invoice)));
    }
  }

  /**
   * Indicates whether if the given invoice id is already exists in the form array or not.
   * @param invoiceId The id of the invoice.
   * @returns `boolean`.
   */
  isInvoiceExists(invoiceId: number): boolean {
    return (this.formArray.value as CustomerPaymentFormItem[]).some(
      (invoiceItem) => invoiceItem.invoice.id === invoiceId
    );
  }
}
