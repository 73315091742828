import { Action } from '@ngrx/store';

import { Location, CreateLocationInput, UpdateLocationInput } from 'stores/models';
import { AppHttpResponse } from 'shared';

/**
 * The Stores-Module store locations action types.
 */
export enum LocationsActionType {
  SEARCH_LOCATIONS = '[Stores] [Locations] Search Locations',
  SEARCH_LOCATIONS_FAIL = '[Stores] [Locations] Search Locations Fail',
  SEARCH_LOCATIONS_SUCCESS = '[Stores] [Locations] Search Locations Success',

  SEARCH_ORGANIZATION_LOCATIONS = '[Stores] [Locations] Search Organization Locations',
  SEARCH_ORGANIZATION_LOCATIONS_FAIL = '[Stores] [Locations] Search Organization Locations Fail',
  SEARCH_ORGANIZATION_LOCATIONS_SUCCESS = '[Stores] [Locations] Search Organization Locations Success',

  FIND_LOCATION = '[Stores] [Locations] Find Location',
  FIND_LOCATION_FAIL = '[Stores] [Locations] Find Location Fail',
  FIND_LOCATION_SUCCESS = '[Stores] [Locations] Find Location Success',

  CREATE_LOCATION = '[Stores] [Locations] Create Location',
  CREATE_LOCATION_FAIL = '[Stores] [Locations] Create Location Fail',
  CREATE_LOCATION_SUCCESS = '[Stores] [Locations] Create Location Success',

  UPDATE_LOCATION = '[Stores] [Locations] Update Location',
  UPDATE_LOCATION_FAIL = '[Stores] [Locations] Update Location Fail',
  UPDATE_LOCATION_SUCCESS = '[Stores] [Locations] Update Location Success',

  BLOCK_LOCATION = '[Stores] [Locations] Block Location',
  BLOCK_LOCATION_FAIL = '[Stores] [Locations] Block Location Fail',
  BLOCK_LOCATION_SUCCESS = '[Stores] [Locations] Block Location Success',

  ACTIVATE_LOCATION = '[Stores] [Locations] Activate Location',
  ACTIVATE_LOCATION_FAIL = '[Stores] [Locations] Activate Location Fail',
  ACTIVATE_LOCATION_SUCCESS = '[Stores] [Locations] Activate Location Success',

  DELETE_LOCATION = '[Stores] [Locations] Delete Location',
  DELETE_LOCATION_FAIL = '[Stores] [Locations] Delete Location Fail',
  DELETE_LOCATION_SUCCESS = '[Stores] [Locations] Delete Location Success',

  UPDATE_PRIMARY_LOGO = '[Stores] [Locations] Update Primary Logo',
  DELETE_PRIMARY_LOGO = '[Stores] [Locations] Delete Primary Logo',

  UPDATE_SECONDARY_LOGO = '[Stores] [Locations] Update Secondary Logo',
  DELETE_SECONDARY_LOGO = '[Stores] [Locations] Delete Secondary Logo',

  UPDATE_IMAGE_HEADER = '[Stores] [Locations] Update Image Header',
  DELETE_IMAGE_HEADER = '[Stores] [Locations] Delete Image Header',

  UPDATE_IMAGE_FOOTER = '[Stores] [Locations] Update Image Footer',
  DELETE_IMAGE_FOOTER = '[Stores] [Locations] Delete Image Footer',
}

/**
 * Represents a store locations search action.
 */
export class SearchLocations implements Action {
  /**
   * The type of the action.
   */
  readonly type = LocationsActionType.SEARCH_LOCATIONS;

  /**
   * Fires a new locations search action.
   * @param payload the search parameters,
   * if omitted, all locations will be loaded.
   */
  constructor(
    public payload?: { name: string; regions: number[]; sectors: number[]; locationTypes: number[]; page: number }
  ) {}
}

/**
 * Represents a store locations search-fail action.
 */
export class SearchLocationsFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = LocationsActionType.SEARCH_LOCATIONS_FAIL;

  /**
   * Fires a new locations search-fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store search-success action.
 */
export class SearchLocationsSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = LocationsActionType.SEARCH_LOCATIONS_SUCCESS;

  /**
   * Fires a new locations search-success action.
   * @param payload An object contains the list of locations that matches the search criteria
   * in addition to the pagination info.
   */
  constructor(public payload: AppHttpResponse<Location[]>) {}
}

/**
 * Represents a store organization locations search action.
 */
export class SearchOrganizationLocations implements Action {
  /**
   * The type of the action.
   */
  readonly type = LocationsActionType.SEARCH_ORGANIZATION_LOCATIONS;

  /**
   * Fires a new organization locations search action.
   * @param payload the search parameters,
   * if omitted, all organization locations will be loaded.
   */
  constructor(
    public payload?: { name: string; regions: number[]; sectors: number[]; locationTypes: number[]; page: number }
  ) {}
}

/**
 * Represents a store organization locations search-fail action.
 */
export class SearchOrganizationLocationsFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = LocationsActionType.SEARCH_ORGANIZATION_LOCATIONS_FAIL;

  /**
   * Fires a new organization locations search-fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store search-success action.
 */
export class SearchOrganizationLocationsSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = LocationsActionType.SEARCH_ORGANIZATION_LOCATIONS_SUCCESS;

  /**
   * Fires a new organization locations search-success action.
   * @param payload An object contains the list of organization locations that matches the search criteria
   * in addition to the pagination info.
   */
  constructor(public payload: AppHttpResponse<Location[]>) {}
}

/**
 * Represents a store find-location action.
 */
export class FindLocation implements Action {
  /**
   * The type of the action.
   */
  readonly type = LocationsActionType.FIND_LOCATION;

  /**
   * Fires a new find location action.
   * @param payload the id of the location.
   */
  constructor(public payload: number) {}
}

/**
 * Represents a store find-location-fail action.
 */
export class FindLocationFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = LocationsActionType.FIND_LOCATION_FAIL;

  /**
   * Fires a new find-location-fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store find location-success action.
 */
export class FindLocationSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = LocationsActionType.FIND_LOCATION_SUCCESS;

  /**
   * Fires a new find-location-success action.
   * @param payload The loaded location.
   */
  constructor(public payload: AppHttpResponse<Location>) {}
}

/**
 * Represents a store location create action.
 */
export class CreateLocation implements Action {
  /** The type of the action. */
  readonly type = LocationsActionType.CREATE_LOCATION;

  /**
   * Fires a new location create action.
   * @param payload the new location data-model.
   */
  constructor(public payload: CreateLocationInput) {}
}

/**
 * Represents a store location create-fail action.
 */
export class CreateLocationFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = LocationsActionType.CREATE_LOCATION_FAIL;

  /**
   * Fires a new location create-fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store location create-success action.
 */
export class CreateLocationSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = LocationsActionType.CREATE_LOCATION_SUCCESS;

  /**
   * Fires a new location create-success action.
   * @param payload The created location.
   */
  constructor(public payload: AppHttpResponse<Location>) {}
}

/**
 * Represents a store location update action.
 */
export class UpdateLocation implements Action {
  /**
   * The type of the action.
   */
  readonly type = LocationsActionType.UPDATE_LOCATION;

  /**
   * Fires a new location update action.
   * @param payload The location's updated data.
   */
  constructor(public payload: UpdateLocationInput) {}
}

/**
 * Represents a store location update-fail action.
 */
export class UpdateLocationFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = LocationsActionType.UPDATE_LOCATION_FAIL;

  /**
   * Fires a new location update-fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store location update-success action.
 */
export class UpdateLocationSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = LocationsActionType.UPDATE_LOCATION_SUCCESS;

  /**
   * Fires a new location update-success action.
   * @param payload The updated location.
   */
  constructor(public payload: AppHttpResponse<Location>) {}
}

/**
 * Represents a store location delete action.
 */
export class DeleteLocation implements Action {
  /**
   * The type of the action.
   */
  readonly type = LocationsActionType.DELETE_LOCATION;

  /**
   * Fires a new location delete action.
   * @param payload The location's id.
   */
  constructor(public payload: number) {}
}

/**
 * Represents a store location delete-fail action.
 */
export class DeleteLocationFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = LocationsActionType.DELETE_LOCATION_FAIL;

  /**
   * Fires a new location delete-fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store location delete-success action.
 */
export class DeleteLocationSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = LocationsActionType.DELETE_LOCATION_SUCCESS;

  /**
   * Fires a new location delete-success action.
   * @param payload The deleted location.
   */
  constructor(public payload: AppHttpResponse<Location>) {}
}

/**
 * Represents a store location update primary logo action.
 */
export class UpdatePrimaryLogo implements Action {
  /**
   * The type of the action.
   */
  readonly type = LocationsActionType.UPDATE_PRIMARY_LOGO;

  /**
   * Fires a new location update primary logo action.
   * @param payload The location's id and the new primary logo.
   */
  constructor(public payload: { id: number; primaryLogo: File }) {}
}

/**
 * Represents a store location delete primary logo action.
 */
export class DeletePrimaryLogo implements Action {
  /**
   * The type of the action.
   */
  readonly type = LocationsActionType.DELETE_PRIMARY_LOGO;

  /**
   * Fires a new location delete primary logo action.
   * @param payload The location's id.
   */
  constructor(public payload: number) {}
}

/**
 * Represents a store location update secondary logo action.
 */
export class UpdateSecondaryLogo implements Action {
  /**
   * The type of the action.
   */
  readonly type = LocationsActionType.UPDATE_SECONDARY_LOGO;

  /**
   * Fires a new location update secondary logo action.
   * @param payload The location's id and the new secondary logo.
   */
  constructor(public payload: { id: number; secondaryLogo: File }) {}
}

/**
 * Represents a store location update image header action.
 */
export class UpdateImageHeader implements Action {
  /**
   * The type of the action.
   */
  readonly type = LocationsActionType.UPDATE_IMAGE_HEADER;

  /**
   * Fires a new location update image header action.
   * @param payload The location's id and the new image header.
   */
  constructor(public payload: { id: number; invoiceHeaderFilename: File }) {}
}

/**
 * Represents a store location update image footer action.
 */
export class UpdateImageFooter implements Action {
  /**
   * The type of the action.
   */
  readonly type = LocationsActionType.UPDATE_IMAGE_FOOTER;

  /**
   * Fires a new location update image footer action.
   * @param payload The location's id and the new image footer.
   */
  constructor(public payload: { id: number; invoiceFooterFilename: File }) {}
}

/**
 * Represents a store location delete secondary logo action.
 */
export class DeleteSecondaryLogo implements Action {
  /**
   * The type of the action.
   */
  readonly type = LocationsActionType.DELETE_SECONDARY_LOGO;

  /**
   * Fires a new location delete secondary logo action.
   * @param payload The location's id.
   */
  constructor(public payload: number) {}
}

/**
 * Represents a store location delete image header action.
 */
export class DeleteImageHeader implements Action {
  /**
   * The type of the action.
   */
  readonly type = LocationsActionType.DELETE_IMAGE_HEADER;

  /**
   * Fires a new location delete image header action.
   * @param payload The location's id.
   */
  constructor(public payload: number) {}
}

/**
 * Represents a store location delete image footer action.
 */
export class DeleteImageFooter implements Action {
  /**
   * The type of the action.
   */
  readonly type = LocationsActionType.DELETE_IMAGE_FOOTER;

  /**
   * Fires a new location delete image footer action.
   * @param payload The location's id.
   */
  constructor(public payload: number) {}
}

/**
 * Stores-Module locations action types.
 */
export type LocationsActions =
  | SearchLocations
  | SearchLocationsFail
  | SearchLocationsSuccess
  | SearchOrganizationLocations
  | SearchOrganizationLocationsFail
  | SearchOrganizationLocationsSuccess
  | FindLocation
  | FindLocationFail
  | FindLocationSuccess
  | CreateLocation
  | CreateLocationFail
  | CreateLocationSuccess
  | UpdateLocation
  | UpdateLocationFail
  | UpdateLocationSuccess
  | DeleteLocation
  | DeleteLocationFail
  | DeleteLocationSuccess
  | UpdatePrimaryLogo
  | DeletePrimaryLogo
  | UpdateSecondaryLogo
  | DeleteSecondaryLogo
  | UpdateImageHeader
  | DeleteImageHeader
  | UpdateImageFooter
  | DeleteImageFooter;
