<!-- page header starts -->
<app-page-header [info]="pageInfo"></app-page-header>
<!-- page header ends -->

<!-- create project pages starts -->
<div class="card">
  <div class="card-content">
    <div class="px-3 pt-3">
      <form [formGroup]="form" (ngSubmit)="submit()">
        <div class="row">
          <div class="col-md-12">
            <ul
              class="nav-tabs"
              ngbNav
              #nav="ngbNav"
              [(activeId)]="activePage"
              (activeIdChange)="selectedPageChanged($event)"
              [destroyOnHide]="false"
            >
              <li ngbNavItem="project-details">
                <a ngbNavLink>
                  <i class="fa fa-id-badge" [class.success]="activePage == 'project-details'"></i>
                  {{ 'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.PROJECT_DATA_TITLE' | translate }}
                </a>
                <ng-template ngbNavContent>
                  <div class="card">
                    <div class="card-header mb-3 pt-0">
                      <h4 class="card-title">
                        <span>{{ 'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.PROJECT_DATA_TITLE' | translate }}</span>
                      </h4>
                    </div>
                    <div class="card-content">
                      <div class="px-3">
                        <app-project-details [projectDetailsForm]="projectDetails"></app-project-details>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </li>
              <li ngbNavItem="project-planning-resources">
                <a ngbNavLink>
                  <i class="fa fa-file-text" [class.success]="activePage == 'project-planning-resources'"></i>
                  {{ 'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.PROJECT_PLANNING_RESOURCES' | translate }}
                </a>
                <ng-template ngbNavContent>
                  <div class="card">
                    <div class="card-header mb-3 pt-0">
                      <h4 class="card-title">
                        <span>{{
                          'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.PROJECT_PLANNING_RESOURCES' | translate
                        }}</span>
                      </h4>
                    </div>
                    <div class="card-content">
                      <div class="px-3">
                        <app-project-planning-resources
                          [formArray]="projectPlanningResources"
                          [isProjectPlanningResourceRequired]="true"
                        ></app-project-planning-resources>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </li>
            </ul>
            <div [ngbNavOutlet]="nav" class="mt-2"></div>
          </div>
        </div>
        <div class="row">
          <div class="form-group col-12 d-flex justify-content-between flex-column flex-sm-row mt-2">
            <button type="submit" class="btn btn-raised btn-success round" [disabled]="isCreating$ | async">
              <i class="fa fa-check"></i>
              <span class="mx-1">{{ 'SHARED.CONFIRM.SAVE' | translate }}</span>
            </button>
            <a class="btn btn-dark round" routerLink="/projects-management/projects">
              <i class="fa fa-undo"></i>
              <span class="mx-1">{{ 'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.PROJECTS_RETURN' | translate }}</span>
            </a>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
<!-- create project pages ends -->

<!-- confirm modal starts -->
<ng-template #confirmModalRef let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
      {{ 'PROJECTS_MANAGEMENT.PROJECTS.CONFIRM_CREATE_PROJECT_TITLE' | translate }}
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="d()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p class="text-success">
      {{ 'PROJECTS_MANAGEMENT.PROJECTS.CONFIRM_CREATE_PROJECT_MESSAGE' | translate }}
    </p>
  </div>
  <div class="modal-footer">
    <div class="modal-actions">
      <button type="button" class="btn btn-raised btn-success round" (click)="c(); confirm()" ngbAutofocus>
        <i class="fa fa-check"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.SAVE' | translate }}</span>
      </button>
      <button type="button" class="btn btn-outline-dark round" (click)="c()">
        <i class="fa fa-times"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.CANCEL' | translate }}</span>
      </button>
    </div>
  </div>
</ng-template>
<!-- confirm modal ends -->
