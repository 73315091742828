import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';

import { AppHttpResponse } from 'shared';
import { UpdateOrganizationInput, Organization, CreateOrganizationInput } from 'organizations/models';

/**
 * The organizations services includes functionality to create, search, findById, update, delete, block and activate a organization.
 */
@Injectable()
export class OrganizationsService {
  /**
   * The relative route for the organizations.
   *
   * No leading or trailing slashes required.
   */
  private organizationsApi = 'organizations';

  constructor(private http: HttpClient) {}

  /**
   * Creates a new organization from the provided data.
   * @param data The new organization data.
   */
  public create(data: CreateOrganizationInput): Observable<AppHttpResponse<Organization>> {
    return this.http.post<AppHttpResponse<Organization>>(`${this.organizationsApi}`, data);
  }

  /**
   * Searches in the organizations by name.
   * @param name The name of the organization.
   * @param page The current pagination page number.
   * @param pageSize The maximum number of organizations allowed per one pagination page.
   */
  public search(name: string, page: number, pageSize: number): Observable<AppHttpResponse<Organization[]>> {
    const params = new HttpParams().set('name', name).set('page', page.toString()).set('pageSize', pageSize.toString());
    return this.http.get<AppHttpResponse<Organization[]>>(`${this.organizationsApi}`, { params });
  }

  /**
   * Finds the organization with the given id.
   * @param id The id of the organization.
   */
  public findById(id: number): Observable<AppHttpResponse<Organization>> {
    return this.http.get<AppHttpResponse<Organization>>(`${this.organizationsApi}/${id}`);
  }

  /**
   * Updates an existing organization data using the provided data.
   * @param data The updated organization data.
   */
  public update(data: UpdateOrganizationInput): Observable<AppHttpResponse<Organization>> {
    return this.http.put<AppHttpResponse<Organization>>(`${this.organizationsApi}`, data);
  }

  /**
   * Activates the organization by given id.
   * @param id The id of the organization.
   */
  public activate(id: number): Observable<AppHttpResponse<Organization>> {
    return this.http.put<AppHttpResponse<Organization>>(`${this.organizationsApi}/activate/${id}`, null);
  }

  /**
   * Blocks the organization by given id.
   * @param id The id of the organization.
   */
  public block(id: number): Observable<AppHttpResponse<Organization>> {
    return this.http.put<AppHttpResponse<Organization>>(`${this.organizationsApi}/block/${id}`, null);
  }

  /**
   * Deletes the organization by given id.
   * @param id The id of the organization.
   */
  public delete(id: number): Observable<AppHttpResponse<Organization>> {
    return this.http.delete<AppHttpResponse<Organization>>(`${this.organizationsApi}/${id}`);
  }
}
