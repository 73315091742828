import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';

import { AppHttpResponse } from 'shared';
import {
  CreateCurrencyExchangeRateInput,
  CurrencyExchangeRate,
  UpdateCurrencyExchangeRateInput,
} from 'finances/models';

/**
 * The currency exchange rate services includes functionality to create, search, findById, update and delete for a currency exchange rate.
 */
@Injectable()
export class CurrencyExchangeRatesService {
  /**
   * The relative route for the currency exchange rates.
   *
   * No leading or trailing slashes required.
   */
  private currencyExchangeRatesApi = 'finances/currencies/currency-exchange-rates';

  constructor(private http: HttpClient) {}

  /**
   * Creates a new currency exchange rate from the provided data.
   * @param data The new currency exchange rate data.
   */
  public create(data: CreateCurrencyExchangeRateInput): Observable<AppHttpResponse<CurrencyExchangeRate>> {
    return this.http.post<AppHttpResponse<CurrencyExchangeRate>>(`${this.currencyExchangeRatesApi}`, data);
  }

  /**
   * Searches in the currency exchange rate by fromDate and toDate.
   * @param primaryCurrencies The primaryCurrencies of the currency exchange rate.
   * @param secondaryCurrencies The primaryCurrencies of the currency exchange rate.
   * @param fromDate The fromDate of the currency exchange rate.
   * @param toDate The toDate of the currency exchange rate.
   * @param page The current pagination page number.
   * @param pageSize The maximum number of currency exchange rate allowed per one pagination page.
   */
  public search(
    primaryCurrencies: number[],
    secondaryCurrencies: number[],
    fromDate: Date,
    toDate: Date,
    page: number,
    pageSize: number
  ): Observable<AppHttpResponse<CurrencyExchangeRate[]>> {
    const params = new HttpParams()
      .set('primaryCurrencies', primaryCurrencies.join(','))
      .set('secondaryCurrencies', secondaryCurrencies.join(','))
      .set('fromDate', fromDate.toISOString())
      .set('toDate', toDate.toISOString())
      .set('page', page.toString())
      .set('pageSize', pageSize.toString());
    return this.http.get<AppHttpResponse<CurrencyExchangeRate[]>>(`${this.currencyExchangeRatesApi}`, { params });
  }

  /**
   * Finds the currency exchange rate with the given id.
   * @param id The id of the currency exchange rate.
   */
  public findById(id: number): Observable<AppHttpResponse<CurrencyExchangeRate>> {
    return this.http.get<AppHttpResponse<CurrencyExchangeRate>>(`${this.currencyExchangeRatesApi}/${id}`);
  }

  /**
   * Updates an existing currency exchange rate data using the provided data.
   * @param data The updated currency exchange rate data.
   */
  public update(data: UpdateCurrencyExchangeRateInput): Observable<AppHttpResponse<CurrencyExchangeRate>> {
    return this.http.put<AppHttpResponse<CurrencyExchangeRate>>(`${this.currencyExchangeRatesApi}`, data);
  }

  /**
   * Deletes the currency exchange rate by given id.
   * @param id The id of the currency exchange rate.
   */
  public delete(id: number): Observable<AppHttpResponse<CurrencyExchangeRate>> {
    return this.http.delete<AppHttpResponse<CurrencyExchangeRate>>(`${this.currencyExchangeRatesApi}/${id}`);
  }
}
