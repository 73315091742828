import { createSelector } from '@ngrx/store';

import { CRMState, LeadsState } from 'crm/store/states';
import { selectCRMState } from 'crm/store/selectors/feature.selectors';

/**
 * Gets the leads state.
 */
const selectLeadsState = createSelector(selectCRMState, (state: CRMState) => state.leads);

/**
 * Gets the list of loaded leads.
 */
export const getLeads = createSelector(selectLeadsState, (state: LeadsState) => state.data);

/**
 * Gets the leads pagination info.
 */
export const getLeadsPaginationInfo = createSelector(selectLeadsState, (state: LeadsState) => state.paginationInfo);

/**
 * Gets the error that occurred in the state.
 */
export const getLeadsError = createSelector(selectLeadsState, (state: LeadsState) => state.error);

/**
 * Determines if there is a running search process.
 */
export const getLeadsSearching = createSelector(selectLeadsState, (state: LeadsState) => state.isSearching);

/**
 * Determines if the last search process has been ended successfully.
 */
export const getLeadsSearchCompleted = createSelector(selectLeadsState, (state: LeadsState) => state.isSearchCompleted);

/**
 * Gets the selected lead.
 */
export const getSelectedLead = createSelector(selectLeadsState, (state: LeadsState) => state.selectedLead);

/**
 * Gets the selected lead note.
 */
export const getSelectedLeadNote = createSelector(selectLeadsState, (state: LeadsState) => state.selectedLeadNote);

/**
 * Gets the selected lead attachment.
 */
export const getSelectedLeadAttachment = createSelector(
  selectLeadsState,
  (state: LeadsState) => state.selectedLeadAttachment
);

/**
 * Determines if there is a running find-lead process.
 */
export const getLeadsFinding = createSelector(selectLeadsState, (state: LeadsState) => state.isFinding);

/**
 * Determines if the last find-lead process has been ended successfully.
 */
export const getLeadsFindCompleted = createSelector(selectLeadsState, (state: LeadsState) => state.isFindCompleted);

/**
 * Determines if there is a running create process.
 */
export const getSelectedLeadCreating = createSelector(selectLeadsState, (state: LeadsState) => state.isCreating);

/**
 * Determines if the last create process has been ended successfully.
 */
export const getSelectedLeadCreateCompleted = createSelector(
  selectLeadsState,
  (state: LeadsState) => state.isCreateCompleted
);

/**
 * Determines if there is a running create lead note process.
 */
export const getSelectedLeadNoteCreating = createSelector(
  selectLeadsState,
  (state: LeadsState) => state.isLeadNoteCreating
);

/**
 * Determines if the last create lead note process has been ended successfully.
 */
export const getSelectedLeadNoteCreateCompleted = createSelector(
  selectLeadsState,
  (state: LeadsState) => state.isLeadNoteCreateCompleted
);

/**
 * Determines if there is a running update process.
 */
export const getSelectedLeadUpdating = createSelector(selectLeadsState, (state: LeadsState) => state.isUpdating);

/**
 * Determines if the last update process has been ended successfully.
 */
export const getSelectedLeadUpdateCompleted = createSelector(
  selectLeadsState,
  (state: LeadsState) => state.isUpdateCompleted
);

/**
 * Determines if there is a running delete process.
 */
export const getSelectedLeadDeleting = createSelector(selectLeadsState, (state: LeadsState) => state.isDeleting);

/**
 * Determines if the last delete process has been ended successfully.
 */
export const getSelectedLeadDeleteCompleted = createSelector(
  selectLeadsState,
  (state: LeadsState) => state.isDeleteCompleted
);

/**
 * Determines if there is a running delete lead note process.
 */
export const getSelectedLeadAttachmentsCreating = createSelector(
  selectLeadsState,
  (state: LeadsState) => state.isLeadAttachmentsCreating
);

/**
 * Determines if the last delete lead attachment process has been ended successfully.
 */
export const getSelectedLeadAttachmentsCreateCompleted = createSelector(
  selectLeadsState,
  (state: LeadsState) => state.isLeadAttachmentsCreateCompleted
);

/**
 * Determines if there is a running delete lead attachment process.
 */
export const getSelectedLeadAttachmentDeleting = createSelector(
  selectLeadsState,
  (state: LeadsState) => state.isLeadAttachmentDeleting
);

/**
 * Determines if the last delete lead attachment process has been ended successfully.
 */
export const getSelectedLeadAttachmentDeleteCompleted = createSelector(
  selectLeadsState,
  (state: LeadsState) => state.isLeadAttachmentDeleteCompleted
);

/**
 * Determines if there is a running delete lead note process.
 */
export const getSelectedLeadNoteDeleting = createSelector(
  selectLeadsState,
  (state: LeadsState) => state.isLeadNoteDeleting
);

/**
 * Determines if the last delete lead note process has been ended successfully.
 */
export const getSelectedLeadNoteDeleteCompleted = createSelector(
  selectLeadsState,
  (state: LeadsState) => state.isLeadNoteDeleteCompleted
);

/**
 * Determines if there is a running update process.
 */
export const getSelectedLeadStatusMarkAsNotHandledYetUpdating = createSelector(
  selectLeadsState,
  (state: LeadsState) => state.isLeadStatusMarkAsNotHandledYetUpdating
);

/**
 * Determines if the last update process has been ended successfully.
 */
export const getSelectedLeadStatusMarkAsNotHandledYetUpdateCompleted = createSelector(
  selectLeadsState,
  (state: LeadsState) => state.isLeadStatusMarkAsNotHandledYetUpdateCompleted
);

/**
 * Determines if there is a running update process.
 */
export const getSelectedLeadStatusMarkAsLostUpdating = createSelector(
  selectLeadsState,
  (state: LeadsState) => state.isLeadStatusMarkAsLostUpdating
);

/**
 * Determines if the last update process has been ended successfully.
 */
export const getSelectedLeadStatusMarkAsLostUpdateCompleted = createSelector(
  selectLeadsState,
  (state: LeadsState) => state.isLeadStatusMarkAsLostUpdateCompleted
);

/**
 * Determines if there is a running update process.
 */
export const getSelectedLeadStatusMarkAsOpportunityUpdating = createSelector(
  selectLeadsState,
  (state: LeadsState) => state.isLeadStatusMarkAsOpportunityUpdating
);

/**
 * Determines if the last update process has been ended successfully.
 */
export const getSelectedLeadStatusMarkAsOpportunityUpdateCompleted = createSelector(
  selectLeadsState,
  (state: LeadsState) => state.isLeadStatusMarkAsOpportunityUpdateCompleted
);
