<!-- journal outgoing stock details starts -->
<div class="row">
  <div class="col-md-12">
    <div class="card-header mb-3 pt-0">
      <h4 class="card-title">{{ 'FINANCES.JOURNALS.JOURNAL_DATA.OUTGOING_STOCK_DATA' | translate }}</h4>
    </div>
    <div class="card-content">
      <div class="px-3">
        <div class="row">
          <div class="form-group col-md-6">
            <label for="outgoingStock.outgoingStockType.name">{{
              'FINANCES.JOURNALS.JOURNAL_DATA.OUTGOING_STOCK_TYPE' | translate
            }}</label>
            <input
              type="text"
              id="outgoingStock.outgoingStockType.name"
              class="form-control round"
              [value]="outgoingStock.outgoingStockType.name | localize: outgoingStock.outgoingStockType.nameEn | async"
              readonly
            />
          </div>
          <div class="form-group col-md-6">
            <label for="outgoingStock.saleInvoice.invoiceNum">{{
              'FINANCES.JOURNALS.JOURNAL_DATA.SALES_INVOICE' | translate
            }}</label>
            <input
              type="text"
              id="outgoingStock.saleInvoice.invoiceNum"
              class="form-control round"
              [value]="outgoingStock.saleInvoice?.invoiceNum"
              readonly
            />
          </div>
          <div class="form-group col-md-6">
            <label for="outgoingStock.location.name">{{
              'FINANCES.JOURNALS.JOURNAL_DATA.ADDRESS_LOCATION' | translate
            }}</label>
            <input
              type="text"
              id="outgoingStock.location.name"
              class="form-control round"
              [value]="outgoingStock.location.name | localize: outgoingStock.location.nameEn | async"
              readonly
            />
          </div>
          <div class="form-group col-md-6">
            <label for="outgoingStock.transactionDate">{{
              'FINANCES.JOURNALS.JOURNAL_DATA.JOURNAL_DATE' | translate
            }}</label>
            <input
              type="text"
              id="outgoingStock.transactionDate"
              class="form-control round"
              [value]="outgoingStock.transactionDate | date: 'datetime'"
              readonly
            />
          </div>
          <div class="form-group col-md-6">
            <label for="outgoingStock.createdAt">{{ 'SHARED.SEARCH.CREATED_AT' | translate }}</label>
            <input
              type="text"
              id="outgoingStock.createdAt"
              class="form-control round"
              [value]="outgoingStock.createdAt | date: 'datetime'"
              readonly
            />
          </div>
          <div class="form-group col-md-6">
            <label for="outgoingStock.user.name">{{ 'FINANCES.JOURNALS.JOURNAL_DATA.USER_CREATE' | translate }}</label>
            <input
              type="text"
              id="outgoingStock.user.name"
              class="form-control round"
              [value]="outgoingStock.user.name"
              readonly
            />
          </div>
          <div class="form-group col-md-12">
            <label for="outgoingStock.notes">{{ 'FINANCES.JOURNALS.JOURNAL_DATA.NOTES' | translate }}</label>
            <textarea
              id="outgoingStock.notes"
              class="form-control round"
              [innerText]="outgoingStock.notes"
              [placeholder]="'FINANCES.JOURNALS.JOURNAL_DATA.NOTES_NOT_FOUND' | translate"
              readonly
            ></textarea>
          </div>
        </div>
      </div>
    </div>

    <!--  outgoing stock products details starts -->
    <div class="card-header mb-3 pt-1">
      <h4 class="card-title">{{ 'FINANCES.JOURNALS.JOURNAL_DATA.PRODUCTS' | translate }}</h4>
    </div>
    <div class="card-content">
      <div class="px-3">
        <div class="row">
          <div class="col-md-12">
            <div class="table-responsive">
              <table class="table text-center">
                <thead>
                  <tr>
                    <th scope="col"><i class="fa fa-list-ol"></i></th>
                    <th scope="col">
                      <i class="fa fa-comments"></i>
                      {{ 'FINANCES.JOURNALS.JOURNAL_DATA.PRODUCT_DESCRIPTION' | translate }}
                    </th>
                    <th scope="col">
                      <i class="fa fa-list-ol"></i>
                      {{ 'FINANCES.JOURNALS.JOURNAL_DATA.PRODUCT_QUANTITY' | translate }}
                    </th>
                    <th scope="col">
                      <i class="fa fa-balance-scale"></i>
                      {{ 'FINANCES.JOURNALS.JOURNAL_DATA.PRODUCT_UNIT_OF_MEASURE' | translate }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let product of outgoingStock.outgoingStockProducts; let i = index">
                    <th scope="row">{{ i + 1 }}</th>
                    <td>
                      {{
                        (product.product?.description | localize: product.product?.descriptionEn | async) ||
                          product.description
                      }}
                      <div *ngIf="product.subProduct" class="mt-1 d-block">
                        <app-sub-product-info [subProduct]="product.subProduct"></app-sub-product-info>
                      </div>
                    </td>
                    <td>{{ product.quantity | toDecimal }}</td>
                    <td>{{ product.unitOfMeasure.name | localize: product.unitOfMeasure.nameEn | async }}</td>
                  </tr>
                </tbody>
                <tfoot class="tfoot-light">
                  <tr>
                    <td [attr.colspan]="2">
                      <i class="fa fa-bookmark c-primary"></i>
                      <span>
                        {{ outgoingStock.outgoingStockProducts?.length | toNumber }}
                        {{ 'FINANCES.JOURNALS.JOURNAL_DATA.PRODUCT' | translate }}
                      </span>
                    </td>
                    <td [attr.colspan]="2">
                      <i class="fa fa-bookmark c-primary"></i>
                      <span>
                        {{ productsQuantity(outgoingStock.outgoingStockProducts) | toDecimal }}
                        {{ 'FINANCES.JOURNALS.JOURNAL_DATA.QUANTITY' | translate }}</span
                      >
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- products details ends -->
  </div>
</div>
<!-- journal outgoing stock details ends -->
