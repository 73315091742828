import { findIndex, remove } from 'lodash';

import { SectorsActionType, SectorsActions } from 'stores/store/actions';
import { SectorsState } from 'stores/store/states';

/**
 * The initial state from which the state starts.
 */
const initialState: SectorsState = {
  data: [],
  paginationInfo: {
    total: 0,
    page: 1,
    pageSize: 0,
    count: 0,
  },
  error: null,
  isSearching: false,
  isSearchCompleted: false,
  selectedSector: null,
  isFinding: false,
  isFindCompleted: false,
  isCreating: false,
  isCreateCompleted: false,
  isUpdating: false,
  isUpdateCompleted: false,
  isDeleting: false,
  isDeleteCompleted: false,
};

/**
 * The reducer function that is called in each action dispatch against the state.
 * @param state The current state.
 * @param action The action that will affect the state.
 */
export function sectorsReducer(state: SectorsState = initialState, action: SectorsActions): SectorsState {
  switch (action.type) {
    //#sector SEARCH_SECTORS

    case SectorsActionType.SEARCH_SECTORS: {
      return {
        ...state,
        isSearching: true,
        isSearchCompleted: false,
        error: null,
      };
    }

    case SectorsActionType.SEARCH_SECTORS_FAIL: {
      return {
        ...state,
        error: action.payload,
        isSearching: false,
        isSearchCompleted: false,
      };
    }

    case SectorsActionType.SEARCH_SECTORS_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        paginationInfo: {
          ...action.payload.meta,
        },
        error: null,
        isSearching: false,
        isSearchCompleted: true,
      };
    }

    //#end sector SEARCH_SECTORS

    //#sector FIND_SECTOR

    case SectorsActionType.FIND_SECTOR: {
      return {
        ...state,
        selectedSector: null,
        error: null,
        isFinding: true,
        isFindCompleted: false,
      };
    }

    case SectorsActionType.FIND_SECTOR_FAIL: {
      return {
        ...state,
        error: action.payload,
        isFinding: false,
        isFindCompleted: false,
      };
    }
    case SectorsActionType.FIND_SECTOR_SUCCESS: {
      let sector = [...state.data];
      const sectorIndex = findIndex(sector, (sector) => sector.id === action.payload.data.id);

      /* If sector was found, update it. */
      if (sectorIndex >= 0) {
        sector[sectorIndex] = action.payload.data;
      } else {
        /* else, insert it to the beginning of the array. */
        sector = [action.payload.data, ...sector];
      }

      return {
        ...state,
        data: sector,
        selectedSector: action.payload.data,
        error: null,
        isFinding: false,
        isFindCompleted: true,
      };
    }

    //#end sector FIND_SECTOR

    //#sector CREATE_SECTOR

    case SectorsActionType.CREATE_SECTOR: {
      return {
        ...state,
        isCreating: true,
        isCreateCompleted: false,
        error: null,
      };
    }

    case SectorsActionType.CREATE_SECTOR_FAIL: {
      return {
        ...state,
        error: action.payload,
        isCreating: false,
        isCreateCompleted: false,
      };
    }

    case SectorsActionType.CREATE_SECTOR_SUCCESS: {
      return {
        ...state,
        data: [action.payload.data, ...state.data],
        selectedSector: action.payload.data,
        error: null,
        isCreating: false,
        isCreateCompleted: true,
      };
    }

    //#end sector CREATE_SECTOR

    //#sector UPDATE_SECTOR

    case SectorsActionType.UPDATE_SECTOR: {
      return {
        ...state,
        isUpdating: true,
        isUpdateCompleted: false,
        error: null,
      };
    }

    case SectorsActionType.UPDATE_SECTOR_FAIL: {
      return {
        ...state,
        error: action.payload,
        isUpdating: false,
        isUpdateCompleted: false,
      };
    }

    case SectorsActionType.UPDATE_SECTOR_SUCCESS: {
      let sector = [...state.data];
      const sectorIndex = findIndex(sector, (sector) => sector.id === action.payload.data.id);

      /* If sector was found, update it. */
      if (sectorIndex >= 0) {
        sector[sectorIndex] = action.payload.data;
      } else {
        /* else, insert it to the beginning of the array. */
        sector = [action.payload.data, ...sector];
      }

      return {
        ...state,
        data: sector,
        selectedSector: action.payload.data,
        error: null,
        isUpdating: false,
        isUpdateCompleted: true,
      };
    }

    //#end sector UPDATE_SECTOR

    //#sector DELETE_SECTOR

    case SectorsActionType.DELETE_SECTOR: {
      return {
        ...state,
        isDeleting: true,
        isDeleteCompleted: false,
        error: null,
      };
    }

    case SectorsActionType.DELETE_SECTOR_FAIL: {
      return {
        ...state,
        error: action.payload,
        isDeleting: false,
        isDeleteCompleted: false,
      };
    }

    case SectorsActionType.DELETE_SECTOR_SUCCESS: {
      const sector = [...state.data];

      /* Remove the deleted sector from the array. */
      remove(sector, (sector) => sector.id === action.payload.data.id);

      return {
        ...state,
        data: sector,
        selectedSector: action.payload.data,
        error: null,
        isDeleting: false,
        isDeleteCompleted: true,
      };
    }

    //#end sector DELETE_CURRENCY_EXCHANGE_RATE

    default:
      return state;
  }
}
