import { findIndex } from 'lodash';

import { PettyCashReductionActions, PettyCashReductionActionType } from 'finances/store/actions';
import { PettyCashReductionState } from 'finances/store/states';

/**
 * The initial state from which the state starts.
 */
const initialState: PettyCashReductionState = {
  data: [],
  paginationInfo: {
    total: 0,
    page: 1,
    pageSize: 0,
    count: 0,
  },
  error: null,
  isSearching: false,
  isSearchCompleted: false,
  selectedPettyCashReduction: null,
  isFinding: false,
  isFindCompleted: false,
  isCreating: false,
  isCreateCompleted: false,
};

/**
 * The reducer function that is called in each action dispatch against the state.
 * @param state The current state.
 * @param action The action that will affect the state.
 */
export function pettyCashReductionReducer(
  state: PettyCashReductionState = initialState,
  action: PettyCashReductionActions
): PettyCashReductionState {
  switch (action.type) {
    //#region SEARCH_PETTY_CASH_REDUCTION

    case PettyCashReductionActionType.SEARCH_PETTY_CASH_REDUCTION: {
      return {
        ...state,
        isSearching: true,
        isSearchCompleted: false,
        error: null,
      };
    }

    case PettyCashReductionActionType.SEARCH_PETTY_CASH_REDUCTION_FAIL: {
      return {
        ...state,
        error: action.payload,
        isSearching: false,
        isSearchCompleted: false,
      };
    }

    case PettyCashReductionActionType.SEARCH_PETTY_CASH_REDUCTION_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        paginationInfo: {
          ...action.payload.meta,
        },
        error: null,
        isSearching: false,
        isSearchCompleted: true,
      };
    }

    //#endregion SEARCH_PETTY_Cash

    //#region FIND_PETTY_CASH

    case PettyCashReductionActionType.FIND_PETTY_CASH_REDUCTION: {
      return {
        ...state,
        selectedPettyCashReduction: null,
        error: null,
        isFinding: true,
        isFindCompleted: false,
      };
    }

    case PettyCashReductionActionType.FIND_PETTY_CASH_REDUCTION_FAIL: {
      return {
        ...state,
        error: action.payload,
        isFinding: false,
        isFindCompleted: false,
      };
    }

    case PettyCashReductionActionType.FIND_PETTY_CASH_REDUCTION_SUCCESS: {
      let pettyCashReductionList = [...state.data];
      const pettyCashReductionIndex = findIndex(
        pettyCashReductionList,
        (pettyCashReduction) => pettyCashReduction.id === action.payload.data.id
      );

      /* If petty cash reduction was found, update it. */
      if (pettyCashReductionIndex >= 0) {
        pettyCashReductionList[pettyCashReductionIndex] = action.payload.data;
      } else {
        /* else, insert it to the beginning of the array. */
        pettyCashReductionList = [action.payload.data, ...pettyCashReductionList];
      }

      return {
        ...state,
        data: pettyCashReductionList,
        selectedPettyCashReduction: action.payload.data,
        error: null,
        isFinding: false,
        isFindCompleted: true,
      };
    }

    //#endregion FIND_PETTY_CASH_REDUCTION

    //#region CREATE_PETTY_CASH_REDUCTION

    case PettyCashReductionActionType.CREATE_PETTY_CASH_REDUCTION: {
      return {
        ...state,
        isCreating: true,
        isCreateCompleted: false,
        error: null,
      };
    }

    case PettyCashReductionActionType.CREATE_PETTY_CASH_REDUCTION_FAIL: {
      return {
        ...state,
        error: action.payload,
        isCreating: false,
        isCreateCompleted: false,
      };
    }

    case PettyCashReductionActionType.CREATE_PETTY_CASH_REDUCTION_SUCCESS: {
      return {
        ...state,
        data: [action.payload.data, ...state.data],
        selectedPettyCashReduction: action.payload.data,
        error: null,
        isCreating: false,
        isCreateCompleted: true,
      };
    }

    //#endregion CREATE_PETTY_CASH_Reduction

    default:
      return state;
  }
}
