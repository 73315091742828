<!-- page header starts -->
<app-page-header [info]="pageInfo"></app-page-header>
<!-- page header ends -->

<div class="card" *ngIf="payrollPayment$ | async; let payrollPayment">
  <!-- payroll payment details starts -->
  <div class="card-content">
    <div class="px-3 pt-3">
      <div class="row">
        <div class="col-md-12">
          <div class="row">
            <div class="col-md-12">
              <div class="card">
                <div class="card-content">
                  <div class="px-3">
                    <div class="row">
                      <div class="form-group col-md-6">
                        <label for="paymentMethodId">{{
                          'FINANCES.HR.PAID_PAYROLLS.PAID_PAYROLLS_DATA.PAID_WAY' | translate
                        }}</label>
                        <input
                          type="text"
                          id="paymentMethodId"
                          class="form-control round"
                          [value]="
                            payrollPayment.employeePaymentMethod.name
                              | localize: payrollPayment.employeePaymentMethod.nameEn
                              | async
                          "
                          readonly
                        />
                      </div>
                      <div class="form-group col-md-6">
                        <label for="value">{{
                          'FINANCES.HR.PAID_PAYROLLS.PAID_PAYROLLS_DATA.PAID_VALUE' | translate
                        }}</label>
                        <input
                          type="text"
                          id="value"
                          class="form-control round"
                          [value]="payrollPayment.value"
                          readonly
                        />
                      </div>
                      <div class="form-group col-md-6">
                        <label for="transactionDate">{{
                          'FINANCES.HR.PAID_PAYROLLS.PAID_PAYROLLS_DATA.TRANSACTION_DATE' | translate
                        }}</label>
                        <input
                          type="text"
                          id="transactionDate"
                          class="form-control round"
                          [value]="payrollPayment.transactionDate | date: 'date'"
                          readonly
                        />
                      </div>
                      <div class="form-group col-md-6">
                        <label for="createdAt">{{ 'SHARED.SEARCH.CREATED_AT' | translate }}</label>
                        <input
                          type="text"
                          id="createdAt"
                          class="form-control round"
                          [value]="payrollPayment.createdAt | date: 'date'"
                          readonly
                        />
                      </div>
                      <div class="form-group col-md-12">
                        <label for="user">{{ 'FINANCES.HR.PAID_PAYROLLS.PAID_PAYROLLS_DATA.USER' | translate }}</label>
                        <input
                          type="text"
                          id="user"
                          class="form-control round"
                          [value]="payrollPayment.user.name"
                          readonly
                        />
                      </div>
                      <div class="form-group col-md-12">
                        <label for="notes">{{
                          'FINANCES.HR.PAID_PAYROLLS.PAID_PAYROLLS_DATA.NOTES' | translate
                        }}</label>
                        <textarea
                          id="notes"
                          class="form-control round"
                          [innerText]="payrollPayment.notes"
                          [placeholder]="'FINANCES.HR.PAID_PAYROLLS.PAID_PAYROLLS_DATA.NOTES_NOT_FOUND' | translate"
                          readonly
                        ></textarea>
                      </div>
                      <div class="form-group col-md-12">
                        <a (click)="selectedPayrollPayment = payrollPayment; openModal(viewAttachmentsModalRef)">
                          <i class="fa fa-paperclip"></i>
                          <span class="mx-1">{{
                            'FINANCES.HR.PAYROLL_PAYMENTS.PAYROLL_PAYMENTS_DATA.DISPLAY_ATTACHMENTS' | translate
                          }}</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- payroll payment payrolls details starts -->
          <div class="card-header mb-3 pt-2">
            <h4 class="card-title">
              {{ 'FINANCES.HR.PAYROLL_PAYMENTS.PAYROLL_PAYMENTS_DATA.PAYROLLS' | translate }}
            </h4>
          </div>
          <div class="card-content">
            <div class="px-3">
              <div class="row">
                <div class="col-md-12">
                  <app-payrolls-list
                    [payrolls]="getPayrolls(payrollPayment.payrollPaymentPayrolls)"
                    [targetElementId]="'payrolls-table-export-target-element'"
                    [isEmployeeManagement]="true"
                  ></app-payrolls-list>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-2">
        <div class="form-group col-12 d-flex justify-content-end mt-2">
          <button class="btn btn-dark round" routerLink="/finances/hr" fragment="payroll-payments">
            <i class="fa fa-undo"></i>
            <span class="mx-1">{{ 'FINANCES.HR.PAYROLL_PAYMENTS.PAYROLL_PAYMENTS_RETURN' | translate }}</span>
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- payroll payment details ends -->
</div>

<!-- view attachments modal starts -->
<ng-template #viewAttachmentsModalRef let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title text-info" id="modal-basic-title">
      {{ 'FINANCES.HR.PAYROLL_PAYMENTS.PAYROLL_PAYMENTS_DATA.ATTACHMENTS' | translate }}
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="d()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="card-content">
        <div class="px-3">
          <div class="row">
            <div class="form-group col-md-12">
              <!-- payroll payment attachments starts -->
              <div class="card-content">
                <div class="px-3">
                  <app-files-viewer
                    *ngIf="selectedPayrollPayment.payrollPaymentAttachments?.length; else noAttachments"
                    [files]="getAttachmentsUrls(selectedPayrollPayment.payrollPaymentAttachments)"
                  ></app-files-viewer>
                </div>
              </div>
              <!-- payroll payment attachments ends -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="modal-actions">
      <button type="button" class="btn btn-raised btn-primary round" (click)="c()" ngbAutoFocus>
        <i class="fa fa-times"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.CLOSE' | translate }}</span>
      </button>
    </div>
  </div>
</ng-template>
<!-- view attachments modal ends -->

<!-- no attachments template starts -->
<ng-template #noAttachments>
  <div class="col-md-12 alert alert-warning mt-3 text-center" role="alert">
    {{ 'FINANCES.HR.PAYROLL_PAYMENTS.PAYROLL_PAYMENTS_DATA.ATTACHMENTS_NOT_FOUND' | translate }}
  </div>
</ng-template>
<!-- no attachments template ends -->
