<!-- page header starts -->
<app-page-header [info]="pageInfo"></app-page-header>
<!-- page header ends -->

<!-- view revenue starts -->
<div class="card">
  <div class="card-content">
    <div class="px-3 pt-5" *ngIf="revenue$ | async; let revenue">
      <div class="row">
        <div class="col-md-12">
          <ul
            class="nav-tabs"
            ngbNav
            #nav="ngbNav"
            [(activeId)]="activePage"
            (activeIdChange)="selectedPageChanged($event)"
            [destroyOnHide]="false"
          >
            <li ngbNavItem="revenue-details">
              <a ngbNavLink>
                <i class="fa fa-percent" [class.success]="activePage == 'revenue-details'"></i>
                {{ 'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.REVENUE_DETAILS' | translate }}
              </a>
              <ng-template ngbNavContent>
                <app-revenue-details [revenue]="revenue"></app-revenue-details>
              </ng-template>
            </li>
            <li ngbNavItem="invoice-details" *ngIf="revenue.saleInvoiceId">
              <a ngbNavLink>
                <i class="fa fa-file-text" [class.success]="activePage == 'invoice-details'"></i>
                {{ 'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.INVOICE_DETAILS' | translate }}
              </a>
              <ng-template ngbNavContent>
                <app-project-revenue-invoice-details [revenue]="revenue"></app-project-revenue-invoice-details>
              </ng-template>
            </li>
            <li ngbNavItem="sale-return-details" *ngIf="revenue.saleReturnId">
              <a ngbNavLink>
                <i class="fa fa-file-text" [class.success]="activePage == 'sale-return-details'"></i>
                {{ 'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.INVOICE_DETAILS' | translate }}
              </a>
              <ng-template ngbNavContent>
                <app-project-revenue-sale-return-details [revenue]="revenue"></app-project-revenue-sale-return-details>
              </ng-template>
            </li>
          </ul>
          <div [ngbNavOutlet]="nav" class="mt-2"></div>
        </div>
        <div class="form-group col-12 d-flex justify-content-end flex-column flex-sm-row mt-2">
          <button class="btn btn btn-raised btn-dark round" routerLink="/projects-management/projects">
            <i class="fa fa-undo"></i>
            <span class="mx-1">{{ 'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.PROJECTS_RETURN' | translate }}</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- view revenue ends -->
