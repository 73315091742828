import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import * as fromContainers from 'security/containers';
import * as fromComponents from 'security/components';
import { Claims } from 'security/models';

const routes: Routes = [
  {
    path: 'users/create',
    component: fromComponents.CreateUserComponent,
    data: { claims: [Claims.CREATE_USER] },
  },
  {
    path: 'users/update/:userId',
    component: fromContainers.UpdateUserComponent,
    data: { claims: [Claims.UPDATE_USER, Claims.RESET_USER_CREDENTIALS] },
  },
  {
    path: 'users',
    component: fromComponents.UsersListComponent,
    data: {
      claims: [Claims.CREATE_USER, Claims.UPDATE_USER, Claims.BLOCK_USER, Claims.ACTIVATE_USER],
    },
  },
  {
    path: 'my-profile',
    component: fromComponents.UpdateProfileComponent,
  },
  {
    path: 'organization-users/update-claims/:userId',
    component: fromComponents.UpdateOrganizationUserClaimsComponent,
    data: {
      claims: [Claims.UPDATE_ORGANIZATION_USER_CLAIMS],
    },
  },
  {
    path: 'organization-users/reset-user-credentials/:userId',
    component: fromComponents.ResetUserCredentialsComponent,
    data: {
      claims: [Claims.RESET_ORGANIZATION_USER_CREDENTIALS],
    },
  },
  {
    path: 'users/reset-user-credentials/:userId',
    component: fromComponents.ResetUserCredentialsComponent,
    data: {
      claims: [Claims.RESET_USER_CREDENTIALS],
    },
  },
  {
    path: 'organization-users',
    component: fromComponents.OrganizationUsersListComponent,
    data: {
      claims: [
        Claims.UPDATE_ORGANIZATION_USER_CLAIMS,
        Claims.ACTIVATE_ORGANIZATION_USER,
        Claims.BLOCK_ORGANIZATION_USER,
        Claims.CREATE_ORGANIZATION_USER,
      ],
    },
  },
  {
    path: 'organization-users/create',
    component: fromComponents.CreateOrganizationUserComponent,
    data: { claims: [Claims.CREATE_ORGANIZATION_USER] },
  },
  {
    path: 'organization-users/update-user/:userId',
    component: fromContainers.UpdateUserComponent,
    data: {
      claims: [Claims.RESET_ORGANIZATION_USER_CREDENTIALS, Claims.UPDATE_ORGANIZATION_USER_CLAIMS],
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SecurityRoutingModule {}
