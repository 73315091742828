import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';

import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subscription } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { skip, tap } from 'rxjs/operators';

import { Claims } from 'security/models';
import { NotificationMessage, NotificationService, PaginationInfo, TranslationService } from 'shared';
import * as fromProjectsManagementStore from 'projects-management/store';
import { ProjectStatus } from 'lookups/models';
import { ProjectStatusLog, ProjectStatusLogAttachment } from 'projects-management/models';

@Component({
  selector: 'app-project-status-log',
  templateUrl: './project-status-log.component.html',
  styles: [],
})
export class ProjectStatusLogComponent implements OnInit, OnDestroy {
  /**
   * The create modal template reference.
   */
  @ViewChild('createModalRef') createModalRef: ElementRef<any>;

  /**
   * The view attachments modal template reference.
   */
  @ViewChild('viewAttachmentsModalRef') viewAttachmentsModalRef: ElementRef<any>;

  /**
   * Sets the currently project id.
   */
  @Input() projectId: number;

  /**
   * The list of project status.
   */
  @Input() projectStatuses: ProjectStatus[] = [];

  /**
   * The list of loaded project log.
   */
  projectLogs$: Observable<ProjectStatusLog[]>;

  /**
   * The pagination info.
   */
  paginationInfo$: Observable<PaginationInfo>;

  /**
   * Indicates whether there is a search process is running or not.
   */
  isSearching$: Observable<boolean>;

  /**
   * The system supported user claims.
   */
  Claims = Claims;

  /**
   * The create project log form.
   */
  createForm: FormGroup;

  /**
   * The search form.
   */
  searchForm: FormGroup;

  /**
   * Indicates whether there is a create project log process is running or not.
   */
  isProjectLogCreating$: Observable<boolean>;

  /**
   * Gets or sets the currently selected project status log.
   */

  selectedProjectStatusLog: ProjectStatusLog;

  /**
   *  The initial `fromDate` value for search.
   */
  initialFromDate = new Date();

  /** The initial `toDate` value for search.
   * It's the current day of month.
   */
  initialToDate = new Date();

  /**
   * The set of subscriptions on this components,
   * these subscriptions must be unsubscribed before this component got destroyed.
   */
  subscriptions = new Subscription();

  /**
   * The ngb-modal reference to use it when modal needed to be closed.
   */
  modal: NgbModalRef;

  /**
   * Gets the attachments form-array.
   */
  get attachmentsForm(): FormArray {
    return this.createForm?.controls.attachments as FormArray;
  }

  /**
   * @param modalService The modal service.
   * @param notificationService The notification service.
   * @param translationService: The translation service.
   * @param projectsManagementStore$ the  projects-management-store module.
   */
  constructor(
    private modalService: NgbModal,
    private notificationService: NotificationService,
    private translationService: TranslationService,
    private projectsManagementStore$: Store<fromProjectsManagementStore.ProjectsManagementState>
  ) {}

  ngOnInit(): void {
    this.init();
  }

  /**
   * Destroy component data
   */
  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  /**
   * Initialize component data.
   */
  init() {
    this.initForms();

    /**
     * Loads data.
     */
    this.isSearching$ = this.projectsManagementStore$.pipe(
      select(fromProjectsManagementStore.getProjectStatusLogsSearching)
    );
    this.projectLogs$ = this.projectsManagementStore$.pipe(select(fromProjectsManagementStore.getProjectStatusLogs));
    this.paginationInfo$ = this.projectsManagementStore$.pipe(
      select(fromProjectsManagementStore.getProjectStatusLogsPaginationInfo)
    );
    this.isProjectLogCreating$ = this.projectsManagementStore$.pipe(
      select(fromProjectsManagementStore.getSelectedProjectStatusLogCreating)
    );
    this.search();

    /**
     * reset on project log creation complete
     */
    this.subscriptions.add(
      this.projectsManagementStore$
        .pipe(
          select(fromProjectsManagementStore.getSelectedProjectStatusLogCreateCompleted),
          skip(1),
          tap((created) => {
            if (created) {
              this.closeModal();
              this.initForms();
              this.createForm.markAsUntouched();
            }
          })
        )
        .subscribe()
    );
  }

  /**
   * Initialize forms and add validators.
   */
  initForms() {
    this.createForm = new FormGroup({
      projectStatusId: new FormControl(null, [Validators.required]),
      transactionDate: new FormControl(null),
      notes: new FormControl('', [Validators.minLength(0), Validators.maxLength(200)]),
      attachments: new FormArray([]),
    });

    this.searchForm = new FormGroup({
      fromDate: new FormControl({
        year: this.initialFromDate.getFullYear(),
        month: this.initialFromDate.getMonth() + 1,
        day: this.initialFromDate.getDate(),
      }),
      toDate: new FormControl({
        year: this.initialToDate.getFullYear(),
        month: this.initialToDate.getMonth() + 1,
        day: this.initialToDate.getDate(),
      }),
      page: new FormControl(1),
    });

    this.addBlankAttachment();
  }

  /**
   * Loads the current selected project log.
   */
  search() {
    this.applyFiltersAndSearch();
  }

  /**
   * Handles pagination page-changed event.
   * @param page The current selected page number.
   */
  pageChanged(page: number) {
    /** Update pagination page. */
    this.searchForm.patchValue({ page });

    this.applyFiltersAndSearch(page);
  }

  /**
   * Applies the search filters and dispatches a search action.
   * @param page The current page number, default to `1`.
   */
  applyFiltersAndSearch(page: number = 1) {
    if (!this.searchForm.get('fromDate')?.value || !this.searchForm.get('toDate')?.value) {
      return this.notificationService.warning(
        this.translationService.translate('FINANCES.BANK_ACCOUNTS.NOTIFICATION_MESSAGE.SELECT_DATE')
      );
    }

    const { fromDate, toDate } = this.searchForm.value;

    const fromDateFormatted = new Date(fromDate.year, fromDate.month - 1, fromDate.day);
    fromDateFormatted.setHours(0, 0, 0, 0);

    const toDateFormatted = new Date(toDate.year, toDate.month - 1, toDate.day);
    toDateFormatted.setHours(23, 59, 59, 0);

    this.projectsManagementStore$.dispatch(
      new fromProjectsManagementStore.SearchProjectStatusLogs({
        projectId: this.projectId,
        fromDate: fromDateFormatted,
        toDate: toDateFormatted,
        page,
      })
    );
  }

  /**
   * Enables trackBy feature for *ngFor utility to track items
   * depending on a comparer to reduce DOM change.
   * If the item is already exist then no HTML will change, only bounded values.
   * @param index The index of the item.
   * @param projectStatusLog The item to determine if it was changed or not.
   */
  trackItems(index: number, projectStatusLog: ProjectStatusLog) {
    return projectStatusLog ? projectStatusLog.id : undefined;
  }

  /**
   * Shows create new project status log modal.
   */
  create() {
    this.openModal(this.createModalRef);
  }

  /**
   * Creates a new project log only if the filled data passed validations.
   */
  confirmCreate() {
    if (this.createForm.invalid) {
      const errorMessage = new NotificationMessage();

      if (this.createForm.get('projectStatusId').invalid) {
        errorMessage.title = this.translationService.translate(
          'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA_VALIDATION.PROJECT_STATUS_LOG_TYPE_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate(
            'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA_VALIDATION.PROJECT_STATUS_LOG_TYPE_IS_REQUIRED'
          ),
        ];
      }
      this.createForm.markAllAsTouched();
      return this.notificationService.warningWithTitle(errorMessage);
    } else {
      const { projectStatusId, transactionDate, notes } = this.createForm.value;

      let fromDateFormatted;
      if (transactionDate) {
        fromDateFormatted = new Date(transactionDate.year, transactionDate.month - 1, transactionDate.day);
        fromDateFormatted.setHours(new Date().getHours(), new Date().getMinutes());
      }

      this.projectsManagementStore$.dispatch(
        new fromProjectsManagementStore.CreateProjectStatusLog({
          projectId: this.projectId,
          projectStatusId,
          transactionDate: fromDateFormatted,
          notes,
          attachments: this.attachmentsForm.value.filter((file) => !!file),
        })
      );
    }
  }

  /**
   * Opens the modal of the given templateRef.
   * @param modalRef The modal templateRef to be opened.
   * @param modalSize An optional modal size, default is medium.
   */
  openModal(modalRef, modalSize?: string) {
    /**
     * Open the modal & listen for user press ESC key.
     */
    this.modal = this.modalService.open(modalRef, { size: 'lg' });
    this.modal.result.then(null, () => this.closeModal());
  }

  /**
   * Closes the currently opened modal.
   */
  closeModal() {
    this.modalService.dismissAll();
  }

  /**
   * Adds a blank attachment form-control to the attachments form for quick start..
   */
  addBlankAttachment() {
    this.attachmentsForm.push(new FormControl());
  }

  /**
   * Views the selected project status log attachments.
   * @param projectStatusLog The project status log to view its attachments.
   */
  viewAttachments(projectStatusLog: ProjectStatusLog) {
    this.selectedProjectStatusLog = projectStatusLog;
    this.openModal(this.viewAttachmentsModalRef);
  }

  /**
   * Maps and returns the set of project Log attachments into a set of urls.
   * @param attachments The project Log attachments to be mapped.
   */
  getAttachmentsUrls(attachments: ProjectStatusLogAttachment[]): string[] {
    return attachments ? attachments.map((attachment) => attachment.url) : [];
  }
}
