import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';

import { Observable } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { tap } from 'rxjs/operators';

import { PageInfo, PaginationInfo } from 'shared';
import * as fromFinancesStore from 'finances/store';
import { Claims } from 'security/models';
import { Bank, BankAccount } from 'finances/models';

@Component({
  selector: 'app-bank-accounts-list',
  templateUrl: './bank-accounts-list.component.html',
  styles: [],
})
export class BankAccountsListComponent implements OnInit {
  /**
   * Gets or sets the information about the current page.
   */
  pageInfo: PageInfo = {
    title: 'FINANCES.BANK_ACCOUNTS.BANK_ACCOUNTS',
    icon: 'icon-notebook',
  };

  /**
   * The system supported user claims.
   */
  Claims = Claims;

  /**
   * The list of bank accounts.
   */
  bankAccounts$: Observable<BankAccount[]>;

  /**
   * The list of selected banks.
   */
  banks: Bank[] = [];

  /**
   * The pagination info.
   */
  paginationInfo$: Observable<PaginationInfo>;

  /**
   * Indicates whether there is a search process is running or not.
   */
  isSearching$: Observable<boolean>;

  /**
   * Indicates whether there is a block-bank-account process is running or not.
   */
  isBlocking$: Observable<boolean>;

  /**
   * Indicates whether there is a activate-bank-account process is running or not.
   */
  isActivating$: Observable<boolean>;

  /**
   * Indicates whether there is a delete-bank-account process is running or not.
   */
  isDeleting$: Observable<boolean>;

  /**
   * Shows or hide the banks list.
   */
  banksListVisibility = false;

  /**
   * The search form.
   */
  searchForm: FormGroup;

  /**
   * Gets or sets the currently selected bank-account for block, activate and delete.
   */
  selectedBankAccount: BankAccount;

  /**
   * @param modalService The modal service.
   * @param financesStore$ The finances store.
   */
  constructor(private modalService: NgbModal, private financesStore$: Store<fromFinancesStore.FinancesState>) {}

  ngOnInit(): void {
    this.init();
  }

  /**
   * Initialize component data.
   */
  init() {
    this.initForm();

    /**
     * Load data.
     */
    this.isDeleting$ = this.financesStore$.pipe(select(fromFinancesStore.getSelectedBankAccountDeleting));
    this.isActivating$ = this.financesStore$.pipe(select(fromFinancesStore.getSelectedBankAccountActivating));
    this.isBlocking$ = this.financesStore$.pipe(select(fromFinancesStore.getSelectedBankAccountBlocking));
    this.isSearching$ = this.financesStore$.pipe(select(fromFinancesStore.getBankAccountsSearching));

    let isManualSearchTriggeredBeforeForBankAccounts = false;
    this.bankAccounts$ = this.financesStore$.pipe(
      select(fromFinancesStore.getBankAccounts),
      tap((bankAccounts) => {
        if (!isManualSearchTriggeredBeforeForBankAccounts && !bankAccounts.length) {
          isManualSearchTriggeredBeforeForBankAccounts = true;
          this.search();
        }
      })
    );

    this.paginationInfo$ = this.financesStore$.pipe(select(fromFinancesStore.getBankAccountsPaginationInfo));
  }

  /**
   * Initialize form and add validators.
   */
  initForm() {
    this.searchForm = new FormGroup({
      name: new FormControl(''),
      banks: new FormControl([]),
      page: new FormControl(1),
    });
  }

  /**
   * Handles search parameters change.
   */
  search(event?: KeyboardEvent) {
    if (event && event.key === 'Enter') {
      event.preventDefault();
      return;
    }

    this.financesStore$.dispatch(
      new fromFinancesStore.SearchBankAccounts({
        name: this.searchForm.get('name').value,
        banks: this.searchForm.get('banks').value,
        page: 1,
      })
    );
  }

  /**
   * Handles pagination page-changed event.
   * @param page The current selected page number.
   */
  pageChanged(page: number) {
    /** Update pagination page. */
    this.searchForm.patchValue({ page });

    /** Get selected page items. */
    this.financesStore$.dispatch(
      new fromFinancesStore.SearchBankAccounts({
        name: this.searchForm.get('name').value,
        banks: this.searchForm.get('banks').value,
        page: this.searchForm.get('page').value,
      })
    );
  }

  /**
   * Enables trackBy feature for *ngFor utility to track items
   * depending on a comparer to reduce DOM change.
   * If the item is already exist then no HTML will change, only bounded values.
   * @param index The index of the item.
   * @param bankAccount The item to determine if it was changed or not.
   */
  trackItems(index: number, bankAccount: BankAccount) {
    return bankAccount ? bankAccount.id : undefined;
  }

  /**
   * Blocks the bank account with the given id.
   * @param id The id of the bank account to be blocked.
   */
  block(id: number) {
    this.financesStore$.dispatch(new fromFinancesStore.BlockBankAccount(id));
  }

  /**
   * Activates the  bank account with the given id.
   * @param id The id of the bank account to be activated.
   */
  activate(id: number) {
    this.financesStore$.dispatch(new fromFinancesStore.ActivateBankAccount(id));
  }

  /**
   * Deletes the bank account with the given id.
   * @param id The id of the bank account to be deleted.
   */
  delete(id: number) {
    this.financesStore$.dispatch(new fromFinancesStore.DeleteBankAccount(id));
  }

  /**
   * Opens the modal of the given templateRef.
   * @param modalRef The modal templateRef to be opened.
   */
  openModal(modalRef) {
    this.modalService.open(modalRef);
  }

  /**
   * Adds the newly selected banks the banks search list.
   * @param banks The list of newly selected banks to be added.
   */
  selectBanks(banks: Bank[]) {
    const selectedIds: number[] = this.searchForm.get('banks').value;
    this.banks = [...this.banks.filter((bank) => selectedIds.includes(bank.id)), ...banks];
    this.searchForm.patchValue({ banks: this.banks.map((bank) => bank.id) });
  }
}
