import { Injectable } from '@angular/core';

import { of } from 'rxjs';
import { switchMap, map, catchError, tap, debounceTime } from 'rxjs/operators';
import { Effect, ofType, Actions } from '@ngrx/effects';

import { NotificationService, StatusCode, PAGINATION, TranslationService } from 'shared';
import { ProductsService } from 'stores/services';
import {
  ProductsActionType,
  SearchProducts,
  SearchProductsFail,
  SearchProductsSuccess,
  SearchProductsForSalesScreen,
  SearchProductsForSalesScreenFail,
  SearchProductsForSalesScreenSuccess,
  SearchProductsWithoutPagination,
  CreateProduct,
  CreateProductSuccess,
  CreateProductFail,
  UpdateProduct,
  UpdateProductSuccess,
  UpdateProductFail,
  DeleteProduct,
  DeleteProductSuccess,
  DeleteProductFail,
  FindProduct,
  FindProductSuccess,
  FindProductFail,
  GetAllProductsStockByProductId,
  GetAllProductsStockByProductIdSuccess,
  GetAllProductsStockByProductIdFail,
  FindProductByCodeOrBarcode,
  FindProductByCodeOrBarcodeSuccess,
  FindProductByCodeOrBarcodeFail,
  DeleteProductPhoto,
  UpdateProductPhoto,
  CreateProductDiscount,
  CreateProductDiscountSuccess,
  CreateProductDiscountFail,
  SearchProductSalesDiscounts,
  SearchProductSalesDiscountsSuccess,
  SearchProductSalesDiscountsFail,
  DeleteProductSalesDiscount,
  DeleteProductSalesDiscountFail,
  DeleteProductSalesDiscountSuccess,
  UpdateProductSalesDiscountFail,
  UpdateProductSalesDiscount,
  UpdateProductSalesDiscountSuccess,
  DeleteProductUnitOfMeasureRate,
  DeleteProductUnitOfMeasureRateFail,
  DeleteProductUnitOfMeasureRateSuccess,
  UpdateProductUnitOfMeasureRateFail,
  UpdateProductUnitOfMeasureRate,
  UpdateProductUnitOfMeasureRateSuccess,
} from 'stores/store/actions';

@Injectable()
export class ProductsEffects {
  constructor(
    private actions$: Actions,
    private productsService: ProductsService,
    private notificationService: NotificationService,
    private translationService: TranslationService
  ) {}

  /* ========================= SEARCH_PRODUCTS =================================== */
  @Effect()
  search$ = this.actions$.pipe(
    ofType(ProductsActionType.SEARCH_PRODUCTS),
    debounceTime(300),
    switchMap(({ payload }: SearchProducts) =>
      this.productsService
        .search(
          payload.description,
          payload.classes,
          payload.locations,
          payload.searchForStorableProducts,
          payload.searchForServiceProducts,
          payload.searchForAllProductTypes,
          payload.page,
          PAGINATION.Products
        )
        .pipe(
          map((response) => new SearchProductsSuccess(response)),
          catchError((error) => of(new SearchProductsFail(error)))
        )
    )
  );

  /* ========================= SEARCH_PRODUCT_SALES_DISCOUNTS =================================== */
  @Effect()
  searchProductSalesDiscounts$ = this.actions$.pipe(
    ofType(ProductsActionType.SEARCH_PRODUCT_SALES_DISCOUNTS),
    debounceTime(300),
    switchMap(({ payload }: SearchProductSalesDiscounts) =>
      this.productsService
        .searchProductSalesDiscounts(
          payload.description,
          payload.classes,
          payload.locations,
          payload.page,
          PAGINATION.ProductSalesDiscounts
        )
        .pipe(
          map((response) => new SearchProductSalesDiscountsSuccess(response)),
          catchError((error) => of(new SearchProductSalesDiscountsFail(error)))
        )
    )
  );

  /* ========================= SEARCH_PRODUCTS_FOR_SALES_SCREEN =================================== */
  @Effect()
  searchProductsForSalesScreen$ = this.actions$.pipe(
    ofType(ProductsActionType.SEARCH_PRODUCTS_FOR_SALES_SCREEN),
    debounceTime(300),
    switchMap(({ payload }: SearchProductsForSalesScreen) =>
      this.productsService.searchProductsForSalesScreen(payload).pipe(
        map((response) => new SearchProductsForSalesScreenSuccess(response)),
        catchError((error) => of(new SearchProductsForSalesScreenFail(error)))
      )
    )
  );

  /* ========================= SEARCH_PRODUCTS_WITHOUT_PAGINATION =================================== */
  @Effect()
  searchWithoutPagination$ = this.actions$.pipe(
    ofType(ProductsActionType.SEARCH_PRODUCTS_WITHOUT_PAGINATION),
    debounceTime(300),
    switchMap(({ payload }: SearchProductsWithoutPagination) =>
      this.productsService
        .searchWithoutPagination(
          payload.description,
          payload.classes,
          payload.locations,
          payload.searchForStorableProducts,
          payload.searchForServiceProducts,
          payload.searchForAllProductTypes
        )
        .pipe(
          map((response) => new SearchProductsSuccess(response)),
          catchError((error) => of(new SearchProductsFail(error)))
        )
    )
  );

  /* ========================= FIND_PRODUCT =================================== */
  @Effect()
  find$ = this.actions$.pipe(
    ofType(ProductsActionType.FIND_PRODUCT),
    switchMap((action: FindProduct) =>
      this.productsService.findById(action.payload).pipe(
        map((response) => new FindProductSuccess(response)),
        catchError((error) => of(new FindProductFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  findFail$ = this.actions$.pipe(
    ofType(ProductsActionType.FIND_PRODUCT_FAIL),
    tap((action: FindProductFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(this.translationService.translate('STORES.PRODUCTS.PRODUCT_NOT_FOUND'));
      }
    })
  );

  /* ========================= FIND_PRODUCT_BY_CODE_OR_BARCODE =================================== */
  @Effect()
  findByCodeOrBarcode$ = this.actions$.pipe(
    ofType(ProductsActionType.FIND_PRODUCT_BY_CODE_OR_BARCODE),
    switchMap((action: FindProductByCodeOrBarcode) =>
      this.productsService.findByCodeOrBarcode(action.payload).pipe(
        map((response) => new FindProductByCodeOrBarcodeSuccess(response)),
        catchError((error) => of(new FindProductByCodeOrBarcodeFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  findByCodeOrBarcodeFail$ = this.actions$.pipe(
    ofType(ProductsActionType.FIND_PRODUCT_BY_CODE_OR_BARCODE_FAIL),
    tap((action: FindProductByCodeOrBarcodeFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(this.translationService.translate('STORES.PRODUCTS.PRODUCT_NOT_FOUND'));
      }
    })
  );

  /* ========================= CREATE_PRODUCT =================================== */
  @Effect()
  create$ = this.actions$.pipe(
    ofType(ProductsActionType.CREATE_PRODUCT),
    switchMap((action: CreateProduct) =>
      this.productsService.create(action.payload).pipe(
        map((response) => new CreateProductSuccess(response)),
        catchError((error) => of(new CreateProductFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  createSuccess$ = this.actions$.pipe(
    ofType(ProductsActionType.CREATE_PRODUCT_SUCCESS),
    tap((action: CreateProductSuccess) => {
      this.notificationService.success(this.translationService.translate('STORES.PRODUCTS.PRODUCT_ADDED'));
    })
  );

  @Effect({ dispatch: false })
  createFail$ = this.actions$.pipe(
    ofType(ProductsActionType.CREATE_PRODUCT_FAIL),
    tap((action: CreateProductFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );

  /* ========================= UPDATE_PRODUCT =================================== */
  @Effect()
  update$ = this.actions$.pipe(
    ofType(ProductsActionType.UPDATE_PRODUCT),
    switchMap((action: UpdateProduct) =>
      this.productsService.update(action.payload).pipe(
        map((response) => new UpdateProductSuccess(response)),
        catchError((error) => of(new UpdateProductFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  updateSuccess$ = this.actions$.pipe(
    ofType(ProductsActionType.UPDATE_PRODUCT_SUCCESS),
    tap((action: UpdateProductSuccess) => {
      this.notificationService.success(this.translationService.translate('STORES.PRODUCTS.PRODUCT_UPDATED'));
    })
  );

  @Effect({ dispatch: false })
  updateFail$ = this.actions$.pipe(
    ofType(ProductsActionType.UPDATE_PRODUCT_FAIL),
    tap((action: UpdateProductFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(this.translationService.translate('STORES.PRODUCTS.PRODUCT_NOT_FOUND'));
      } else if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );

  @Effect()
  updatePhoto$ = this.actions$.pipe(
    ofType(ProductsActionType.UPDATE_PRODUCT_PHOTO),
    switchMap((action: UpdateProductPhoto) =>
      this.productsService.updatePhoto(action.payload.id, action.payload.photo).pipe(
        map((response) => new UpdateProductSuccess(response)),
        catchError((error) => of(new UpdateProductFail(error)))
      )
    )
  );

  @Effect()
  deletePhoto$ = this.actions$.pipe(
    ofType(ProductsActionType.DELETE_PRODUCT_PHOTO),
    switchMap((action: DeleteProductPhoto) =>
      this.productsService.deletePhoto(action.payload).pipe(
        map((response) => new UpdateProductSuccess(response)),
        catchError((error) => of(new UpdateProductFail(error)))
      )
    )
  );

  /* ========================= CREATE_PRODUCT_DISCOUNTS =================================== */
  @Effect()
  createProductDiscount$ = this.actions$.pipe(
    ofType(ProductsActionType.CREATE_PRODUCT_DISCOUNTS),
    switchMap((action: CreateProductDiscount) =>
      this.productsService.createProductDiscount(action.payload).pipe(
        map((response) => new CreateProductDiscountSuccess(response)),
        catchError((error) => of(new CreateProductDiscountFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  createProductDiscountSuccess$ = this.actions$.pipe(
    ofType(ProductsActionType.CREATE_PRODUCT_DISCOUNTS_SUCCESS),
    tap((action: CreateProductDiscountSuccess) => {
      this.notificationService.success(
        this.translationService.translate('STORES.PRODUCTS.PRODUCT_DISCOUNT_CREATED', { count: action.payload.data })
      );
    })
  );

  @Effect({ dispatch: false })
  createProductDiscountFail$ = this.actions$.pipe(
    ofType(ProductsActionType.CREATE_PRODUCT_DISCOUNTS_FAIL),
    tap((action: CreateProductDiscountFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );

  /* ========================= DELETE_PRODUCT =================================== */
  @Effect()
  delete$ = this.actions$.pipe(
    ofType(ProductsActionType.DELETE_PRODUCT),
    switchMap((action: DeleteProduct) =>
      this.productsService.delete(action.payload).pipe(
        map((response) => new DeleteProductSuccess(response)),
        catchError((error) => of(new DeleteProductFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  deleteSuccess$ = this.actions$.pipe(
    ofType(ProductsActionType.DELETE_PRODUCT_SUCCESS),
    tap((action: DeleteProductSuccess) => {
      this.notificationService.success(this.translationService.translate('STORES.PRODUCTS.PRODUCT_DELETED'));
    })
  );

  @Effect({ dispatch: false })
  deleteFail$ = this.actions$.pipe(
    ofType(ProductsActionType.DELETE_PRODUCT_FAIL),
    tap((action: DeleteProductFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(this.translationService.translate('STORES.PRODUCTS.PRODUCT_NOT_FOUND'));
      } else if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );

  /* ========================= GET_PRODUCT-STOCKS_PRODUCT_ID =================================== */
  @Effect()
  getProductsStockByProductId$ = this.actions$.pipe(
    ofType(ProductsActionType.GET_PRODUCT_STOCKS_BY_PRODUCT_ID),
    switchMap((action: GetAllProductsStockByProductId) =>
      this.productsService.getAllIProductsStockByProductId(action.payload.productId).pipe(
        map((response) => new GetAllProductsStockByProductIdSuccess(response)),
        catchError((error) => of(new GetAllProductsStockByProductIdFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  getProductsStockByProductIdFail$ = this.actions$.pipe(
    ofType(ProductsActionType.GET_PRODUCT_STOCKS_BY_PRODUCT_ID_FAIL),
    tap((action: GetAllProductsStockByProductIdFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(this.translationService.translate('STORES.PRODUCTS.PRODUCT_NOT_FOUND'));
      }
    })
  );

  /* ========================= UPDATE_PRODUCT_SALES_DISCOUNT =================================== */
  @Effect()
  updateProductSalesDiscount$ = this.actions$.pipe(
    ofType(ProductsActionType.UPDATE_PRODUCT_SALES_DISCOUNT),
    switchMap((action: UpdateProductSalesDiscount) =>
      this.productsService.updateProductSalesDiscount(action.payload).pipe(
        map((response) => new UpdateProductSalesDiscountSuccess(response)),
        catchError((error) => of(new UpdateProductSalesDiscountFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  updateProductSalesDiscountSuccess$ = this.actions$.pipe(
    ofType(ProductsActionType.UPDATE_PRODUCT_SALES_DISCOUNT_SUCCESS),
    tap((action: UpdateProductSalesDiscountSuccess) => {
      this.notificationService.success(
        this.translationService.translate('STORES.PRODUCTS.PRODUCT_SALES_DISCOUNT_UPDATED')
      );
    })
  );

  @Effect({ dispatch: false })
  updateProductSalesDiscountFail$ = this.actions$.pipe(
    ofType(ProductsActionType.UPDATE_PRODUCT_SALES_DISCOUNT_FAIL),
    tap((action: UpdateProductSalesDiscountFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate('STORES.PRODUCTS.PRODUCT_SALES_DISCOUNT_NOT_FOUND')
        );
      } else if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );

  /* ========================= DELETE_PRODUCT_SALES_DISCOUNT =================================== */
  @Effect()
  deleteProductSalesDiscount$ = this.actions$.pipe(
    ofType(ProductsActionType.DELETE_PRODUCT_SALES_DISCOUNT),
    switchMap((action: DeleteProductSalesDiscount) =>
      this.productsService.deleteProductSalesDiscount(action.payload).pipe(
        map((response) => new DeleteProductSalesDiscountSuccess(response)),
        catchError((error) => of(new DeleteProductSalesDiscountFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  deleteProductSalesDiscountSuccess$ = this.actions$.pipe(
    ofType(ProductsActionType.DELETE_PRODUCT_SALES_DISCOUNT_SUCCESS),
    tap((action: DeleteProductSalesDiscountSuccess) => {
      this.notificationService.success(
        this.translationService.translate('STORES.PRODUCTS.PRODUCT_SALES_DISCOUNT_DELETED')
      );
    })
  );

  @Effect({ dispatch: false })
  deleteProductSalesDiscountFail$ = this.actions$.pipe(
    ofType(ProductsActionType.DELETE_PRODUCT_SALES_DISCOUNT_FAIL),
    tap((action: DeleteProductSalesDiscountFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate('STORES.PRODUCTS.PRODUCT_SALES_DISCOUNT_NOT_FOUND')
        );
      } else if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );

  /* ========================= UPDATE_PRODUCT_UNIT_OF_MEASURE_RATE =================================== */
  @Effect()
  updateProductUnitOfMeasureRate$ = this.actions$.pipe(
    ofType(ProductsActionType.UPDATE_PRODUCT_UNIT_OF_MEASURE_RATE),
    switchMap((action: UpdateProductUnitOfMeasureRate) =>
      this.productsService.updateProductUnitOfMeasureRate(action.payload).pipe(
        map((response) => new UpdateProductUnitOfMeasureRateSuccess(response)),
        catchError((error) => of(new UpdateProductUnitOfMeasureRateFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  updateProductUnitOfMeasureRateSuccess$ = this.actions$.pipe(
    ofType(ProductsActionType.UPDATE_PRODUCT_UNIT_OF_MEASURE_RATE_SUCCESS),
    tap((action: UpdateProductUnitOfMeasureRateSuccess) => {
      this.notificationService.success(
        this.translationService.translate('STORES.PRODUCTS.PRODUCT_UNIT_OF_MEASURE_RATE_UPDATED')
      );
    })
  );

  @Effect({ dispatch: false })
  updateProductUnitOfMeasureRateFail$ = this.actions$.pipe(
    ofType(ProductsActionType.UPDATE_PRODUCT_UNIT_OF_MEASURE_RATE_FAIL),
    tap((action: UpdateProductUnitOfMeasureRateFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate('STORES.PRODUCTS.PRODUCT_UNIT_OF_MEASURE_RATE_NOT_FOUND')
        );
      } else if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );

  /* ========================= DELETE_PRODUCT_UNIT_OF_MEASURE_RATE =================================== */
  @Effect()
  deleteProductUnitOfMeasureRate$ = this.actions$.pipe(
    ofType(ProductsActionType.DELETE_PRODUCT_UNIT_OF_MEASURE_RATE),
    switchMap((action: DeleteProductUnitOfMeasureRate) =>
      this.productsService.deleteProductUnitOfMeasureRate(action.payload).pipe(
        map((response) => new DeleteProductUnitOfMeasureRateSuccess(response)),
        catchError((error) => of(new DeleteProductUnitOfMeasureRateFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  deleteProductUnitOfMeasureRateSuccess$ = this.actions$.pipe(
    ofType(ProductsActionType.DELETE_PRODUCT_UNIT_OF_MEASURE_RATE_SUCCESS),
    tap((action: DeleteProductUnitOfMeasureRateSuccess) => {
      this.notificationService.success(
        this.translationService.translate('STORES.PRODUCTS.PRODUCT_UNIT_OF_MEASURE_RATE_DELETED')
      );
    })
  );

  @Effect({ dispatch: false })
  deleteProductUnitOfMeasureRateFail$ = this.actions$.pipe(
    ofType(ProductsActionType.DELETE_PRODUCT_UNIT_OF_MEASURE_RATE_FAIL),
    tap((action: DeleteProductUnitOfMeasureRateFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate('STORES.PRODUCTS.PRODUCT_UNIT_OF_MEASURE_RATE_NOT_FOUND')
        );
      } else if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );
}
