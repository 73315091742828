import { Injectable } from '@angular/core';

import { of } from 'rxjs';
import { switchMap, map, catchError } from 'rxjs/operators';
import { Effect, ofType, Actions } from '@ngrx/effects';

import { LookupsService } from 'lookups/services';
import { PrintPaperSizeActionType, GetAllPrintPaperSizeSuccess, GetAllPrintPaperSizeFail } from 'lookups/store/actions';

@Injectable()
export class PrintPaperSizeEffects {
  constructor(private actions$: Actions, private lookupsService: LookupsService) {}

  /* ========================= GET_ALL_PRINT_PAPER_SIZE =================================== */
  @Effect()
  getAllPrintPaperSizes$ = this.actions$.pipe(
    ofType(PrintPaperSizeActionType.GET_ALL_PRINT_PAPER_SIZE),
    switchMap(() =>
      this.lookupsService.getAllPrintPaperSizes().pipe(
        map((response) => new GetAllPrintPaperSizeSuccess(response)),
        catchError((error) => of(new GetAllPrintPaperSizeFail(error)))
      )
    )
  );
}
