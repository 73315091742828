<div [formGroup]="employeeProvisionLinksForm.parent" class="row">
  <div class="col-md-12">
    <div class="table-responsive">
      <table class="table text-center" [ngStyle]="{ 'padding-bottom': dropDownIsOpened ? '8rem' : 'unset' }">
        <thead>
          <tr>
            <th scope="col">
              <i class="fa fa-list-ol"></i>
            </th>
            <th scope="col">
              {{ 'FINANCES.FINANCE_CONFIGURATIONS.FINANCE_CONFIGURATIONS_DATA.EMPLOYEE_PROVISION_LINK' | translate }}
            </th>
            <th scope="col">
              {{ 'FINANCES.FINANCE_CONFIGURATIONS.FINANCE_CONFIGURATIONS_DATA.ACCOUNT' | translate }}
            </th>
          </tr>
        </thead>
        <tbody formArrayName="employeeProvisionLinks">
          <tr
            app-employees-provision-link-form-item
            *ngFor="let link of employeeProvisionLinksForm.controls; let i = index"
            [employeeProvisionLinkGroup]="link"
            [index]="i"
            (dropDownIsOpened)="dropDownIsOpened = $event"
          ></tr>
        </tbody>
        <tfoot class="tfoot-light">
          <tr>
            <td [attr.colspan]="9">
              <i class="fa fa-bookmark c-primary"></i>
              <span>
                {{ employeeProvisionLinksForm.controls.length | toNumber }}
                {{ 'FINANCES.FINANCE_CONFIGURATIONS.FINANCE_CONFIGURATIONS_DATA.PROVISION_LINK' | translate }}
              </span>
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  </div>
</div>
