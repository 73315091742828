import { Injectable } from '@angular/core';

import { Effect, ofType, Actions } from '@ngrx/effects';
import { switchMap, map, catchError, tap } from 'rxjs/operators';
import { of } from 'rxjs';

import { NotificationService, StatusCode, PAGINATION, TranslationService } from 'shared';
import { QuotationsService } from 'crm/services';
import {
  QuotationsActionType,
  SearchQuotations,
  SearchQuotationsFail,
  SearchQuotationsSuccess,
  CreateQuotation,
  CreateQuotationSuccess,
  CreateQuotationFail,
  FindQuotation,
  FindQuotationSuccess,
  FindQuotationFail,
  RejectQuotation,
  RejectQuotationSuccess,
  RejectQuotationFail,
} from 'crm/store/actions';

@Injectable()
export class QuotationsEffects {
  constructor(
    private actions$: Actions,
    private QuotationsService: QuotationsService,
    private notificationService: NotificationService,
    private translationService: TranslationService
  ) {}

  /* ========================= SEARCH_QUOTATIONS =================================== */
  @Effect()
  search$ = this.actions$.pipe(
    ofType(QuotationsActionType.SEARCH_QUOTATIONS),
    switchMap(({ payload }: SearchQuotations) => {
      return this.QuotationsService.search(
        payload.leads,
        payload.quotationStatuses,
        payload.fromDate,
        payload.toDate,
        payload.page,
        PAGINATION.Quotations
      ).pipe(
        map((response) => new SearchQuotationsSuccess(response)),
        catchError((error) => of(new SearchQuotationsFail(error)))
      );
    })
  );

  /* ========================= FIND_QUOTATION =================================== */
  @Effect()
  find$ = this.actions$.pipe(
    ofType(QuotationsActionType.FIND_QUOTATION),
    switchMap((action: FindQuotation) =>
      this.QuotationsService.findById(action.payload).pipe(
        map((response) => new FindQuotationSuccess(response)),
        catchError((error) => of(new FindQuotationFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  findFail$ = this.actions$.pipe(
    ofType(QuotationsActionType.FIND_QUOTATION_FAIL),
    tap((action: FindQuotationFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(this.translationService.translate('CRM.QUOTATIONS.QUOTATION_NOT_FOUND'));
      }
    })
  );

  /* ========================= CREATE_QUOTATION =================================== */
  @Effect()
  create$ = this.actions$.pipe(
    ofType(QuotationsActionType.CREATE_QUOTATION),
    switchMap((action: CreateQuotation) =>
      this.QuotationsService.create(action.payload).pipe(
        map((response) => new CreateQuotationSuccess(response)),
        catchError((error) => of(new CreateQuotationFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  createSuccess$ = this.actions$.pipe(
    ofType(QuotationsActionType.CREATE_QUOTATION_SUCCESS),
    tap((action: CreateQuotationSuccess) => {
      this.notificationService.success(this.translationService.translate('CRM.QUOTATIONS.QUOTATION_ADDED'));
    })
  );

  @Effect({ dispatch: false })
  createFail$ = this.actions$.pipe(
    ofType(QuotationsActionType.CREATE_QUOTATION_FAIL),
    tap((action: CreateQuotationFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );

  /* ========================= REJECT_QUOTATION =================================== */
  @Effect()
  rejectQuotation$ = this.actions$.pipe(
    ofType(QuotationsActionType.REJECT_QUOTATION),
    switchMap((action: RejectQuotation) =>
      this.QuotationsService.rejectQuotation(action.payload).pipe(
        map((response) => new RejectQuotationSuccess(response)),
        catchError((error) => of(new RejectQuotationFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  rejectQuotationSuccess$ = this.actions$.pipe(
    ofType(QuotationsActionType.REJECT_QUOTATION_SUCCESS),
    tap((action: RejectQuotationSuccess) => {
      this.notificationService.success(this.translationService.translate('CRM.QUOTATIONS.QUOTATION_REJECTED'));
    })
  );

  @Effect({ dispatch: false })
  rejectQuotationFail$ = this.actions$.pipe(
    ofType(QuotationsActionType.REJECT_QUOTATION_FAIL),
    tap((action: RejectQuotationFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(this.translationService.translate('CRM.QUOTATIONS.QUOTATION_NOT_FOUND'));
      } else if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );
}
