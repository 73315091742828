<div [formGroup]="formArray.parent" class="row">
  <div class="col-md-12">
    <button
      type="button"
      class="btn btn-sm btn-raised btn-primary round"
      [ngbTooltip]="'STORES.PRODUCTS.PRODUCT_DATA.ADD_SUB_PRODUCT' | translate"
      placement="top-right"
      (click)="addSubProduct()"
    >
      <i class="fa fa-plus"></i>
    </button>
    <button
      type="button"
      class="btn btn-sm btn-raised btn-danger round mx-2"
      [ngbTooltip]="'STORES.PRODUCTS.PRODUCT_DATA.DELETE_SUB_PRODUCTS' | translate"
      placement="left"
      (click)="resetSubProducts()"
    >
      <i class="fa fa-trash"></i>
    </button>
  </div>
  <div class="col-md-12">
    <div class="table-responsive">
      <table class="table text-center">
        <thead>
          <tr>
            <th scope="col">
              <i class="fa fa-list-ol"></i>
            </th>
            <th scope="col">
              {{ 'STORES.PRODUCTS.PRODUCT_DATA.PRODUCT_VARIANTS' | translate }}
            </th>
            <th scope="col">
              {{ 'STORES.PRODUCTS.PRODUCT_DATA.PURCHASE_PRICE' | translate }}
            </th>
            <th scope="col">
              {{ 'STORES.PRODUCTS.PRODUCT_DATA.SALE_PRICE' | translate }}
            </th>
            <th scope="col">{{ 'SHARED.TOOLS' | translate }}</th>
          </tr>
        </thead>
        <tbody formArrayName="subProducts">
          <ng-container *ngFor="let item of formArray.controls; let i = index">
            <tr app-sub-products-form-item [formGroup]="item" [index]="i" (removeChange)="removeSubProduct(i)"></tr>
            <tr>
              <td
                colspan="5"
                class="px-2"
                *ngIf="isProductHasIngredients"
                app-sub-product-ingredients-form
                [formArray]="getIngredientsForm(item)"
              ></td>
            </tr>
          </ng-container>
        </tbody>
        <tfoot class="tfoot-light">
          <tr>
            <td [attr.colspan]="9">
              <i class="fa fa-bookmark c-primary"></i>
              <span>
                {{ formArray.controls.length | toNumber }}
                {{ 'STORES.PRODUCTS.PRODUCT_DATA.SUB_PRODUCT' | translate }}
              </span>
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  </div>
</div>
