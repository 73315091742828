import { Action } from '@ngrx/store';

import { NoticePeriod } from 'lookups/models';
import { AppHttpResponse } from 'shared';

/**
 * The Lookups-Module store notice period action types.
 */
export enum NoticePeriodActionType {
  GET_ALL_NOTICE_PERIODS = '[Lookups] [NoticePeriod] Get All Notice Periods',
  GET_ALL_NOTICE_PERIODS_FAIL = '[Lookups] [NoticePeriod] Get All Notice Periods Fail',
  GET_ALL_NOTICE_PERIODS_SUCCESS = '[Lookups] [NoticePeriod] Get All Notice Periods Success',
}

/**
 * Represents a store notice period action.
 */
export class GetAllNoticePeriods implements Action {
  /**
   * The type of the action.
   */
  readonly type = NoticePeriodActionType.GET_ALL_NOTICE_PERIODS;

  /**
   * Fires a new notice period action.
   */
  constructor() {}
}

/**
 * Represents a store notice period fail action.
 */
export class GetAllNoticePeriodsFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = NoticePeriodActionType.GET_ALL_NOTICE_PERIODS_FAIL;

  /**
   * Fires a new notice period fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store notice period success action.
 */
export class GetAllNoticePeriodsSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = NoticePeriodActionType.GET_ALL_NOTICE_PERIODS_SUCCESS;

  /**
   * Fires a notice period success action.
   * @param payload An object contains the list of notice periods.
   */
  constructor(public payload: AppHttpResponse<NoticePeriod[]>) {}
}

/**
 * Lookups-module notice period action types.
 */
export type NoticePeriodActions = GetAllNoticePeriods | GetAllNoticePeriodsFail | GetAllNoticePeriodsSuccess;
