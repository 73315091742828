import { Injectable } from '@angular/core';

import { of } from 'rxjs';
import { switchMap, map, catchError, tap } from 'rxjs/operators';
import { Effect, ofType, Actions } from '@ngrx/effects';

import { NotificationService, StatusCode, PAGINATION, TranslationService } from 'shared';
import { FinancialPeriodsService } from 'finances/services';
import {
  SearchFinancialPeriods,
  SearchFinancialPeriodsFail,
  SearchFinancialPeriodsSuccess,
  OpenFinancialPeriod,
  OpenFinancialPeriodFail,
  OpenFinancialPeriodSuccess,
  PauseFinancialPeriod,
  PauseFinancialPeriodFail,
  PauseFinancialPeriodSuccess,
  CloseFinancialPeriod,
  CloseFinancialPeriodFail,
  CloseFinancialPeriodSuccess,
  FinancialPeriodsActionType,
} from 'finances/store/actions';

@Injectable()
export class FinancialPeriodsEffects {
  constructor(
    private actions$: Actions,
    private financialPeriodsService: FinancialPeriodsService,
    private notificationService: NotificationService,
    private translationService: TranslationService
  ) {}

  /* ========================= SEARCH_FINANCIAL_PERIODS =================================== */
  @Effect()
  search$ = this.actions$.pipe(
    ofType(FinancialPeriodsActionType.SEARCH_FINANCIAL_PERIODS),
    switchMap(({ payload }: SearchFinancialPeriods) =>
      this.financialPeriodsService
        .search(payload.fromDate, payload.toDate, payload.page, PAGINATION.FinancialPeriods)
        .pipe(
          map((response) => new SearchFinancialPeriodsSuccess(response)),
          catchError((error) => of(new SearchFinancialPeriodsFail(error)))
        )
    )
  );

  /* ========================= OPEN_FINANCIAL_PERIOD =================================== */
  @Effect()
  open$ = this.actions$.pipe(
    ofType(FinancialPeriodsActionType.OPEN_FINANCIAL_PERIOD),
    switchMap((action: OpenFinancialPeriod) =>
      this.financialPeriodsService.open(action.payload).pipe(
        map((response) => new OpenFinancialPeriodSuccess(response)),
        catchError((error) => of(new OpenFinancialPeriodFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  openSuccess$ = this.actions$.pipe(
    ofType(FinancialPeriodsActionType.OPEN_FINANCIAL_PERIOD_SUCCESS),
    tap((action: OpenFinancialPeriodSuccess) => {
      this.notificationService.success(
        this.translationService.translate('FINANCES.FINANCIAL_PERIODS.FINANCIAL_PERIOD_OPENED')
      );
    })
  );

  @Effect({ dispatch: false })
  openFail$ = this.actions$.pipe(
    ofType(FinancialPeriodsActionType.OPEN_FINANCIAL_PERIOD_FAIL),
    tap((action: OpenFinancialPeriodFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate('FINANCES.FINANCIAL_PERIODS.FINANCIAL_PERIOD_NOT_FOUND')
        );
      } else if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );

  /* ========================= PAUSE_FINANCIAL_PERIOD =================================== */
  @Effect()
  pause$ = this.actions$.pipe(
    ofType(FinancialPeriodsActionType.PAUSE_FINANCIAL_PERIOD),
    switchMap((action: PauseFinancialPeriod) =>
      this.financialPeriodsService.pause(action.payload).pipe(
        map((response) => new PauseFinancialPeriodSuccess(response)),
        catchError((error) => of(new PauseFinancialPeriodFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  pauseSuccess$ = this.actions$.pipe(
    ofType(FinancialPeriodsActionType.PAUSE_FINANCIAL_PERIOD_SUCCESS),
    tap((action: PauseFinancialPeriodSuccess) => {
      this.notificationService.success(
        this.translationService.translate('FINANCES.FINANCIAL_PERIODS.FINANCIAL_PERIOD_PAUSED')
      );
    })
  );

  @Effect({ dispatch: false })
  pauseFail$ = this.actions$.pipe(
    ofType(FinancialPeriodsActionType.PAUSE_FINANCIAL_PERIOD_FAIL),
    tap((action: PauseFinancialPeriodFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate('FINANCES.FINANCIAL_PERIODS.FINANCIAL_PERIOD_NOT_FOUND')
        );
      } else if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );

  /* ========================= CLOSE_FINANCIAL_PERIOD =================================== */
  @Effect()
  close$ = this.actions$.pipe(
    ofType(FinancialPeriodsActionType.CLOSE_FINANCIAL_PERIOD),
    switchMap((action: CloseFinancialPeriod) =>
      this.financialPeriodsService.close(action.payload).pipe(
        map((response) => new CloseFinancialPeriodSuccess(response)),
        catchError((error) => of(new CloseFinancialPeriodFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  closeSuccess$ = this.actions$.pipe(
    ofType(FinancialPeriodsActionType.CLOSE_FINANCIAL_PERIOD_SUCCESS),
    tap((action: CloseFinancialPeriodSuccess) => {
      this.notificationService.success(
        this.translationService.translate('FINANCES.FINANCIAL_PERIODS.FINANCIAL_PERIOD_CLOSED')
      );
    })
  );

  @Effect({ dispatch: false })
  closeFail$ = this.actions$.pipe(
    ofType(FinancialPeriodsActionType.CLOSE_FINANCIAL_PERIOD_FAIL),
    tap((action: CloseFinancialPeriodFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate('FINANCES.FINANCIAL_PERIODS.FINANCIAL_PERIOD_NOT_FOUND')
        );
      } else if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );
}
