import { Action } from '@ngrx/store';

import { AppHttpResponse } from 'shared';
import { PettyCashRefundRequestApproval, CreateRefundRequestApprovalInput } from 'finances/models';

/**
 * The finances-module store petty cash refund request Approvals action types.
 */
export enum PettyCashRefundRequestApprovalsActionType {
  SEARCH_PETTY_CASH_REFUND_REQUEST_APPROVALS = '[Finances] [PettyCashRefundRequestApprovals] Search Petty Cash Refund Request Approvals',
  SEARCH_PETTY_CASH_REFUND_REQUEST_APPROVALS_FAIL = '[Finances] [PettyCashRefundRequestApprovals] Search Petty Cash Refund Request Approvals Fail',
  SEARCH_PETTY_CASH_REFUND_REQUEST_APPROVALS_SUCCESS = '[Finances] [PettyCashRefundRequestApprovals] Search Petty Cash Refund Request Approvals Success',

  FIND_PETTY_CASH_REFUND_REQUEST_APPROVAL = '[Finances] [PettyCashRefundRequestApprovals] Find Petty Cash Refund Request Approval',
  FIND_PETTY_CASH_REFUND_REQUEST_APPROVAL_FAIL = '[Finances] [PettyCashRefundRequestApprovals] Find Petty Cash Refund Request Approval Fail',
  FIND_PETTY_CASH_REFUND_REQUEST_APPROVAL_SUCCESS = '[Finances] [PettyCashRefundRequestApprovals] Find Petty Cash Refund Request Approval Success',

  CREATE_PETTY_CASH_REFUND_REQUEST_APPROVAL = '[Finances] [PettyCashRefundRequestApprovals] Create Petty Cash Refund Request Approval',
  CREATE_PETTY_CASH_REFUND_REQUEST_APPROVAL_FAIL = '[Finances] [PettyCashRefundRequestApprovals] Create Petty Cash Refund Request Approval Fail',
  CREATE_PETTY_CASH_REFUND_REQUEST_APPROVAL_SUCCESS = '[Finances] [PettyCashRefundRequestApprovals] Create Petty Cash Refund Request Approval Success',
}

/**
 * Represents a store petty cash refund request approvals search action.
 */
export class SearchPettyCashRefundRequestApprovals implements Action {
  /**
   * The type of the action.
   */
  readonly type = PettyCashRefundRequestApprovalsActionType.SEARCH_PETTY_CASH_REFUND_REQUEST_APPROVALS;

  /**
   * Fires a new petty cash refund request approvals search action.
   * @param payload the search parameters,
   * if omitted, all petty cash refund request approvals will be loaded.
   */
  constructor(
    public payload?: {
      pettyCashRefundRequests: number[];
      fromDate: Date;
      toDate: Date;
      page: number;
    }
  ) {}
}

/**
 * Represents a store petty cash refund request approvals search-fail action.
 */
export class SearchPettyCashRefundRequestApprovalsFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = PettyCashRefundRequestApprovalsActionType.SEARCH_PETTY_CASH_REFUND_REQUEST_APPROVALS_FAIL;

  /**
   * Fires a new petty cash refund request approvals search-fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store Petty Cash Refund Request Approvals search-success action.
 */
export class SearchPettyCashRefundRequestApprovalsSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = PettyCashRefundRequestApprovalsActionType.SEARCH_PETTY_CASH_REFUND_REQUEST_APPROVALS_SUCCESS;

  /**
   * Fires a new petty cash refund request approvals search-success action.
   * @param payload An object contains the list of petty cash refund request approvals that matches the search criteria
   * in addition to the pagination info.
   */
  constructor(public payload: AppHttpResponse<PettyCashRefundRequestApproval[]>) {}
}

/**
 * Represents a store find-petty-cash-refund-request-approval action.
 */
export class FindPettyCashRefundRequestApproval implements Action {
  /**
   * The type of the action.
   */
  readonly type = PettyCashRefundRequestApprovalsActionType.FIND_PETTY_CASH_REFUND_REQUEST_APPROVAL;

  /**
   * Fires a new Find petty cash refund request approval action.
   * @param payload the id of the petty cash refund request approval.
   */
  constructor(public payload: number) {}
}

/**
 * Represents a store find-petty-cash-refund-request-approval-fail action.
 */
export class FindPettyCashRefundRequestApprovalFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = PettyCashRefundRequestApprovalsActionType.FIND_PETTY_CASH_REFUND_REQUEST_APPROVAL_FAIL;

  /**
   * Fires a new find-petty-cash-refund-request-approval-fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store Find-petty-cash-refund-request-approval-success action.
 */
export class FindPettyCashRefundRequestApprovalSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = PettyCashRefundRequestApprovalsActionType.FIND_PETTY_CASH_REFUND_REQUEST_APPROVAL_SUCCESS;

  /**
   * Fires a new find-petty-cash-refund-request-approval-success action.
   * @param payload The loaded petty-cash-refund-request-approval.
   */
  constructor(public payload: AppHttpResponse<PettyCashRefundRequestApproval>) {}
}

/**
 * Represents a store petty cash refund request approval create action.
 */
export class CreatePettyCashRefundRequestApproval implements Action {
  /** The type of the action. */
  readonly type = PettyCashRefundRequestApprovalsActionType.CREATE_PETTY_CASH_REFUND_REQUEST_APPROVAL;

  /**
   * Fires a new petty cash refund request approval create action.
   * @param payload the new petty cash refund request approval data-model.
   */
  constructor(public payload: CreateRefundRequestApprovalInput) {}
}

/**
 * Represents a store petty cash refund request approval create-fail action.
 */
export class CreatePettyCashRefundRequestApprovalFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = PettyCashRefundRequestApprovalsActionType.CREATE_PETTY_CASH_REFUND_REQUEST_APPROVAL_FAIL;

  /**
   * Fires a new petty cash refund request approval create-fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store petty cash refund request approval create-success action.
 */
export class CreatePettyCashRefundRequestApprovalSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = PettyCashRefundRequestApprovalsActionType.CREATE_PETTY_CASH_REFUND_REQUEST_APPROVAL_SUCCESS;

  /**
   * Fires a new petty cash refund request approval create-success action.
   * @param payload The created petty cash refund request approval.
   */
  constructor(public payload: AppHttpResponse<PettyCashRefundRequestApproval>) {}
}

/**
 * Finances-module petty cash refund request approvals action types.
 */
export type PettyCashRefundRequestApprovalsActions =
  | SearchPettyCashRefundRequestApprovals
  | SearchPettyCashRefundRequestApprovalsFail
  | SearchPettyCashRefundRequestApprovalsSuccess
  | FindPettyCashRefundRequestApproval
  | FindPettyCashRefundRequestApprovalFail
  | FindPettyCashRefundRequestApprovalSuccess
  | CreatePettyCashRefundRequestApproval
  | CreatePettyCashRefundRequestApprovalFail
  | CreatePettyCashRefundRequestApprovalSuccess;
