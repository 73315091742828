import { Action } from '@ngrx/store';

import { WorkType } from 'lookups/models';
import { AppHttpResponse } from 'shared';

/**
 * The Lookups-Module store work type action types.
 */
export enum WorkTypeActionType {
  GET_ALL_WORK_TYPES = '[Lookups] [WorkType] Get All Work Types',
  GET_ALL_WORK_TYPES_FAIL = '[Lookups] [WorkType] Get All Work Types Fail',
  GET_ALL_WORK_TYPES_SUCCESS = '[Lookups] [WorkType] Get All Work Types Success',
}

/**
 * Represents a store work type action.
 */
export class GetAllWorkTypes implements Action {
  /**
   * The type of the action.
   */
  readonly type = WorkTypeActionType.GET_ALL_WORK_TYPES;

  /**
   * Fires a new work type action.
   */
  constructor() {}
}

/**
 * Represents a store work type fail action.
 */
export class GetAllWorkTypesFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = WorkTypeActionType.GET_ALL_WORK_TYPES_FAIL;

  /**
   * Fires a new work type fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store work type success action.
 */
export class GetAllWorkTypesSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = WorkTypeActionType.GET_ALL_WORK_TYPES_SUCCESS;

  /**
   * Fires a work type success action.
   * @param payload An object contains the list of work types.
   */
  constructor(public payload: AppHttpResponse<WorkType[]>) {}
}

/**
 * Lookups-module work type action types.
 */
export type WorkTypeActions = GetAllWorkTypes | GetAllWorkTypesFail | GetAllWorkTypesSuccess;
