import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';

import { AppHttpResponse } from 'shared/models';

/**
 * The sales reports services.
 */
@Injectable()
export class SalesReportsService {
  /**
   * The relative route for the sales reports.
   *
   * No leading or trailing slashes required.
   */
  private salesReportsApi = 'reporting/sales';

  constructor(private http: HttpClient) {}

  /**
   * Gets the sales invoices report matching the provided query params.
   * @param locations The array of the ids of locations.
   * @param customers The array of the ids of customers.
   * @param types The array of the  ids of sale invoice type.
   * @param orderTypes The array of the  ids of sale invoice order type.
   * @param fromDate The fromDate of the sale invoice.
   * @param toDate The toDate of the sale invoice.
   * @param displayProducts of the report.
   */
  public getSalesInvoicesReport(
    locations: number[],
    customers: number[],
    saleInvoiceTypes: number[],
    saleInvoiceOrderTypes: number[],
    fromDate: Date,
    toDate: Date,
    displayProducts: boolean
  ): Observable<AppHttpResponse<string>> {
    const params = new HttpParams()
      .set('locations', locations?.join(','))
      .set('customers', customers?.join(','))
      .set('types', saleInvoiceTypes?.join(','))
      .set('orderTypes', saleInvoiceOrderTypes?.join(','))
      .set('fromDate', fromDate?.toISOString())
      .set('toDate', toDate?.toISOString())
      .set('displayProducts', displayProducts?.toString());

    return this.http.get<AppHttpResponse<string>>(`${this.salesReportsApi}/sales-invoices-report`, { params });
  }

  /**
   * Gets the sales report matching the provided query params.
   * @param locations The array of the  ids of locations.
   * @param customers The array of the  ids of customers.
   * @param types The array of the  ids of sale invoice type.
   * @param fromDate The fromDate of the sale invoice.
   * @param toDate The toDate of the sale invoice.
   */
  public getSalesSummaryReport(
    locations: number[],
    customers: number[],
    types: number[],
    fromDate: Date,
    toDate: Date
  ): Observable<AppHttpResponse<string>> {
    const params = new HttpParams()
      .set('locations', locations?.join(','))
      .set('customers', customers?.join(','))
      .set('types', types?.join(','))
      .set('fromDate', fromDate?.toISOString())
      .set('toDate', toDate?.toISOString());

    return this.http.get<AppHttpResponse<string>>(`${this.salesReportsApi}/sales-summary-report`, { params });
  }

  /**
   * Gets the sales returns matching the provided query params.
   * @param locations The array of the  ids of locations.
   * @param customers The array of the  ids of customers.
   * @param fromDate The fromDate of the sale request.
   * @param toDate The toDate of the sale request.
   * @param displayProducts of the report.
   */
  public getSalesReturnsReport(
    locations: number[],
    customers: number[],
    fromDate: Date,
    toDate: Date,
    displayProducts: boolean
  ): Observable<AppHttpResponse<string>> {
    const params = new HttpParams()
      .set('locations', locations?.join(','))
      .set('customers', customers?.join(','))
      .set('fromDate', fromDate?.toISOString())
      .set('toDate', toDate?.toISOString())
      .set('displayProducts', displayProducts?.toString());

    return this.http.get<AppHttpResponse<string>>(`${this.salesReportsApi}/sales-returns-report`, { params });
  }

  /**
   * Gets the top selling products report matching the provided query params.
   * @param fromDate The fromDate of the top selling product.
   * @param toDate The toDate of the top selling product.
   * @param count The count of the top selling product.
   * @param locations The array of the  ids of locations.
   */
  public getTopSellingProductsReport(
    fromDate: Date,
    toDate: Date,
    count: number,
    locations: number[]
  ): Observable<AppHttpResponse<string>> {
    const params = new HttpParams()
      .set('fromDate', fromDate?.toISOString())
      .set('toDate', toDate?.toISOString())
      .set('count', count?.toString())
      .set('locations', locations?.join(','));

    return this.http.get<AppHttpResponse<string>>(`${this.salesReportsApi}/top-selling-products-report`, { params });
  }

  /**
   * Gets the worst selling products report matching the provided query params.
   * @param fromDate The fromDate of the worst selling product.
   * @param toDate The toDate of the worst selling product.
   * @param count The count of the worst selling product.
   * @param locations The array of the  ids of locations.
   */
  public getWorstSellingProductsReport(
    fromDate: Date,
    toDate: Date,
    count: number,
    locations: number[]
  ): Observable<AppHttpResponse<string>> {
    const params = new HttpParams()
      .set('fromDate', fromDate?.toISOString())
      .set('toDate', toDate?.toISOString())
      .set('count', count?.toString())
      .set('locations', locations?.join(','));

    return this.http.get<AppHttpResponse<string>>(`${this.salesReportsApi}/worst-selling-products-report`, { params });
  }

  /**
   * Gets the most returned products report matching the provided query params.
   * @param locations The array of the  ids of locations.
   * @param fromDate The fromDate of the most returned product.
   * @param toDate The toDate of the most returned product.
   * @param count The count of the most returned product.
   */
  public getTopReturnedSalesProductsReport(
    locations: number[],
    fromDate: Date,
    toDate: Date,
    count: number
  ): Observable<AppHttpResponse<string>> {
    const params = new HttpParams()
      .set('fromDate', fromDate?.toISOString())
      .set('toDate', toDate?.toISOString())
      .set('count', count?.toString())
      .set('locations', locations?.join(','));

    return this.http.get<AppHttpResponse<string>>(`${this.salesReportsApi}/top-returned-sales-products-report`, {
      params,
    });
  }

  /**
   * Gets the worst returned products report matching the provided query params.
   * @param fromDate The fromDate of the worst returned product.
   * @param toDate The toDate of the worst returned product.
   * @param count The count of the worst returned product.
   * @param locations The array of the  ids of locations.
   */
  public getWorstReturnedSalesProductsReport(
    fromDate: Date,
    toDate: Date,
    count: number,
    locations: number[]
  ): Observable<AppHttpResponse<string>> {
    const params = new HttpParams()
      .set('fromDate', fromDate?.toISOString())
      .set('toDate', toDate?.toISOString())
      .set('count', count?.toString())
      .set('locations', locations?.join(','));

    return this.http.get<AppHttpResponse<string>>(`${this.salesReportsApi}/worst-returned-sales-products-report`, {
      params,
    });
  }

  /**
   * Gets the customers debits matching the provided query params.
   */
  public getCustomersDebtsReport(): Observable<AppHttpResponse<string>> {
    return this.http.get<AppHttpResponse<string>>(`${this.salesReportsApi}/customers-debts-report`);
  }

  /**
   * Gets the customer summary matching the provided query params.
   * @param customerId The id of the customer of the customer log.
   * @param fromDate The fromDate of the sale request.
   * @param toDate The toDate of the sale request.
   */
  public getCustomerSummaryReport(
    customerId: string,
    fromDate: Date,
    toDate: Date
  ): Observable<AppHttpResponse<string>> {
    const params = new HttpParams()
      .set('customerId', customerId)
      .set('fromDate', fromDate?.toISOString())
      .set('toDate', toDate?.toISOString());

    return this.http.get<AppHttpResponse<string>>(`${this.salesReportsApi}/customer-summary-report`, { params });
  }

  /**
   * Gets the top customers matching the provided query params.
   * @param count The count of the customers.
   * @param locations The array of the  ids of locations.
   * @param fromDate The fromDate of the request.
   * @param toDate The toDate of the request.
   */
  public getTopCustomersReport(
    count: number,
    locations: number[],
    fromDate: Date,
    toDate: Date
  ): Observable<AppHttpResponse<string>> {
    const params = new HttpParams()
      .set('count', count?.toString())
      .set('locations', locations?.join(','))
      .set('fromDate', fromDate?.toISOString())
      .set('toDate', toDate?.toISOString());

    return this.http.get<AppHttpResponse<string>>(`${this.salesReportsApi}/top-customers-report`, { params });
  }

  /**
   * Gets the worst customers matching the provided query params.
   * @param count The count of the customers.
   * @param locations The array of the  ids of locations.
   * @param fromDate The fromDate of the request.
   * @param toDate The toDate of the request.
   */
  public getWorstCustomersReport(
    count: number,
    locations: number[],
    fromDate: Date,
    toDate: Date
  ): Observable<AppHttpResponse<string>> {
    const params = new HttpParams()
      .set('count', count?.toString())
      .set('locations', locations?.join(','))
      .set('fromDate', fromDate?.toISOString())
      .set('toDate', toDate?.toISOString());

    return this.http.get<AppHttpResponse<string>>(`${this.salesReportsApi}/worst-customers-report`, { params });
  }

  /**
   * Gets the maintenance stage invoices report matching the provided query params.
   * @param maintenanceStageTypes The array of the  ids of sale invoice type.
   * @param locations The array of the  ids of locations.
   * @param fromDate The fromDate of the sale invoice.
   * @param toDate The toDate of the sale invoice.
   * @param displayProductsInReport of the report.
   */
  public getMaintenanceStageInvoicesReport(
    maintenanceStages: number[],
    locations: number[],
    fromDate: Date,
    toDate: Date,
    displayProductsInReport: boolean
  ): Observable<AppHttpResponse<string>> {
    const params = new HttpParams()
      .set('maintenanceStages', maintenanceStages?.join(','))
      .set('locations', locations?.join(','))
      .set('fromDate', fromDate?.toISOString())
      .set('toDate', toDate?.toISOString())
      .set('displayProductsInReport', displayProductsInReport?.toString());

    return this.http.get<AppHttpResponse<string>>(`${this.salesReportsApi}/maintenance-invoice-stages-report`, {
      params,
    });
  }

  /**
   * Gets the shift matching the provided query params.
   * @param shiftId The id of the shift.
   * @param paperSize The paper size.
   */
  public getShiftSummaryReport(shiftId: number, paperSize: string): Observable<AppHttpResponse<string>> {
    const params = new HttpParams().set('shiftId', shiftId.toString()).set('paperSize', paperSize);

    return this.http.get<AppHttpResponse<string>>(`${this.salesReportsApi}/shift-summary-report`, { params });
  }

  /**
   * Gets the customers payments matching the provided query params.
   * @param customers The array of the  ids of customers.
   * @param customerLogTypes The customerLogTypes of the customers payments request.
   * @param fromDate The fromDate of the customers payments request.
   * @param toDate The toDate of the customers payments request.
   */
  public getCustomersPaymentsReport(
    customers: number[],
    customerLogTypes: number[],
    fromDate: Date,
    toDate: Date
  ): Observable<AppHttpResponse<string>> {
    const params = new HttpParams()
      .set('paymentMethods', customerLogTypes?.join(','))
      .set('customers', customers?.join(','))
      .set('fromDate', fromDate?.toISOString())
      .set('toDate', toDate?.toISOString());

    return this.http.get<AppHttpResponse<string>>(`${this.salesReportsApi}/customers-payments-report`, { params });
  }

  /**
   * Gets the profits matching the provided query params.
   * @param fromDate The fromDate of the total profits.
   * @param toDate The toDate of the total profits.
   */
  public getTotalProfitsReport(locations: number[], fromDate: Date, toDate: Date): Observable<AppHttpResponse<string>> {
    const params = new HttpParams()
      .set('locations', locations?.join(','))
      .set('fromDate', fromDate?.toISOString())
      .set('toDate', toDate?.toISOString());

    return this.http.get<AppHttpResponse<string>>(`${this.salesReportsApi}/gross-profit-report`, { params });
  }

  /**
   * Gets the net sales matching the provided query params.
   * @param locations The array of the ids of locations.
   * @param customers The array of the ids of customers.
   * @param types The array of the  ids of sale invoice type.
   * @param orderTypes The array of the  ids of sale invoice order type.
   * @param fromDate The fromDate of the sale invoice.
   * @param toDate The toDate of the sale invoice.
   * @param displayProducts of the report.
   */
  public getNetSalesReport(
    locations: number[],
    customers: number[],
    saleInvoiceTypes: number[],
    saleInvoiceOrderTypes: number[],
    fromDate: Date,
    toDate: Date,
    displayProducts: boolean
  ): Observable<AppHttpResponse<string>> {
    const params = new HttpParams()
      .set('locations', locations?.join(','))
      .set('customers', customers?.join(','))
      .set('types', saleInvoiceTypes?.join(','))
      .set('orderTypes', saleInvoiceOrderTypes?.join(','))
      .set('fromDate', fromDate?.toISOString())
      .set('toDate', toDate?.toISOString())
      .set('displayProducts', displayProducts?.toString());

    return this.http.get<AppHttpResponse<string>>(`${this.salesReportsApi}/net-sales-report`, { params });
  }

  /**
   * Gets the product sales matching the provided query params.
   * @param productId The id of the product sales.
   * @param locations The array of the  ids of locations.
   * @param fromDate The fromDate of the product sales.
   * @param toDate The toDate of the product sales.
   */
  public getProductSalesReport(
    productId: string,
    orderTypes: number[],
    customers: number[],
    locations: number[],
    fromDate: Date,
    toDate: Date
  ): Observable<AppHttpResponse<string>> {
    const params = new HttpParams()
      .set('productId', productId)
      .set('orderTypes', orderTypes?.join(','))
      .set('customers', customers?.join(','))
      .set('locations', locations?.join(','))
      .set('fromDate', fromDate?.toISOString())
      .set('toDate', toDate?.toISOString());

    return this.http.get<AppHttpResponse<string>>(`${this.salesReportsApi}/product-sales-report`, { params });
  }

  /**
   * Gets the products sales matching the provided query params.
   * @param products The array of the ids of the products.
   * @param productClasses The array of the ids of the product classes.
   * @param locations The array of the ids of locations.
   * @param users The array of the ids of users.
   * @param fromDate The fromDate of the product sales.
   * @param toDate The toDate of the product sales.
   */
  public getProductsSalesReport(
    products: number[],
    productClasses: number[],
    orderTypes: number[],
    customers: number[],
    locations: number[],
    users: number[],
    fromDate: Date,
    toDate: Date
  ): Observable<AppHttpResponse<string>> {
    const params = new HttpParams()
      .set('products', products?.join(','))
      .set('classes', productClasses?.join(','))
      .set('orderTypes', orderTypes?.join(','))
      .set('customers', customers?.join(','))
      .set('locations', locations?.join(','))
      .set('users', users?.join(','))
      .set('fromDate', fromDate?.toISOString())
      .set('toDate', toDate?.toISOString());

    return this.http.get<AppHttpResponse<string>>(`${this.salesReportsApi}/products-sales-report`, { params });
  }

  /**
   * Gets the product sales returns matching the provided query params.
   * @param productId The id of the product sales returns.
   * @param locations The array of the  ids of locations.
   * @param fromDate The fromDate of the product sales returns.
   * @param toDate The toDate of the product sales returns.
   */
  public getProductSalesReturnsReport(
    productId: string,
    locations: number[],
    fromDate: Date,
    toDate: Date
  ): Observable<AppHttpResponse<string>> {
    const params = new HttpParams()
      .set('productId', productId)
      .set('locations', locations?.join(','))
      .set('fromDate', fromDate?.toISOString())
      .set('toDate', toDate?.toISOString());

    return this.http.get<AppHttpResponse<string>>(`${this.salesReportsApi}/product-sales-returns-report`, { params });
  }

  /**
   * Gets the products sales returns matching the provided query params.
   * @param products The array of the ids of the products.
   * @param productClasses The array of the ids of the product classes.
   * @param locations The array of the  ids of locations.
   * @param users The array of the ids of users.
   * @param fromDate The fromDate of the product sales returns.
   * @param toDate The toDate of the product sales returns.
   */
  public getProductsSalesReturnsReport(
    products: number[],
    productClasses: number[],
    locations: number[],
    users: number[],
    fromDate: Date,
    toDate: Date
  ): Observable<AppHttpResponse<string>> {
    const params = new HttpParams()
      .set('products', products?.join(','))
      .set('classes', productClasses?.join(','))
      .set('locations', locations?.join(','))
      .set('users', users?.join(','))
      .set('fromDate', fromDate?.toISOString())
      .set('toDate', toDate?.toISOString());

    return this.http.get<AppHttpResponse<string>>(`${this.salesReportsApi}/products-sales-returns-report`, { params });
  }

  /**
   * Gets the customers points report matching the provided query params.
   * @param customers The array of the  ids of customers.
   */
  public getCustomersPointsReport(customers: number[]): Observable<AppHttpResponse<string>> {
    const params = new HttpParams().set('customers', customers?.join(','));

    return this.http.get<AppHttpResponse<string>>(`${this.salesReportsApi}/customers-points-report`, { params });
  }

  /**
   * Gets the product classes sales report matching the provided query params.
   * @param locations The array of the locations.
   * @param classes The array of the ids of the product classes.
   * @param users The array of the ids of users.
   * @param fromDate The fromDate of the product classes sales.
   * @param toDate The toDate of the product classes sales.
   */
  public getProductClassesSalesReport(
    locations: number[],
    classes: number[],
    users: number[],
    fromDate: Date,
    toDate: Date
  ): Observable<AppHttpResponse<string>> {
    const params = new HttpParams()
      .set('locations', locations?.join(','))
      .set('classes', classes?.join(','))
      .set('users', users?.join(','))
      .set('fromDate', fromDate?.toISOString())
      .set('toDate', toDate?.toISOString());

    return this.http.get<AppHttpResponse<string>>(`${this.salesReportsApi}/product-classes-sales-report`, { params });
  }

  /**
   * Gets the sales persons sales report matching the provided query params.
   * @param employeeId The employeeId of the sales persons.
   * @param fromDate The fromDate of the sales persons.
   * @param toDate The toDate of the sales persons.
   */
  public getSalesPersonSalesReport(
    employeeId: string,
    fromDate: Date,
    toDate: Date
  ): Observable<AppHttpResponse<string>> {
    const params = new HttpParams()
      .set('employeeId', employeeId)
      .set('fromDate', fromDate?.toISOString())
      .set('toDate', toDate?.toISOString());

    return this.http.get<AppHttpResponse<string>>(`${this.salesReportsApi}/sales-person-sales-report`, { params });
  }
}
