import { Action } from '@ngrx/store';

import { LeaveType } from 'lookups/models';
import { AppHttpResponse } from 'shared';

/**
 * The Lookups-Module store leave type action types.
 */
export enum LeaveTypeActionType {
  GET_ALL_LEAVE_TYPES = '[Lookups] [LeaveType] Get All Leave Types',
  GET_ALL_LEAVE_TYPES_FAIL = '[Lookups] [LeaveType] Get All Leave Types Fail',
  GET_ALL_LEAVE_TYPES_SUCCESS = '[Lookups] [LeaveType] Get All Leave Types Success',
}

/**
 * Represents a store leave type action.
 */
export class GetAllLeaveType implements Action {
  /**
   * The type of the action.
   */
  readonly type = LeaveTypeActionType.GET_ALL_LEAVE_TYPES;

  /**
   * Fires a new leave type action.
   */
  constructor() {}
}

/**
 * Represents a store leave type fail action.
 */
export class GetAllLeaveTypeFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = LeaveTypeActionType.GET_ALL_LEAVE_TYPES_FAIL;

  /**
   * Fires a new leave type fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store leave type success action.
 */
export class GetAllLeaveTypeSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = LeaveTypeActionType.GET_ALL_LEAVE_TYPES_SUCCESS;

  /**
   * Fires a leave type success action.
   * @param payload An object contains the list of  leave Types.
   */
  constructor(public payload: AppHttpResponse<LeaveType[]>) {}
}

/**
 * Lookups-module leave type action types.
 */
export type LeaveTypeActions = GetAllLeaveType | GetAllLeaveTypeFail | GetAllLeaveTypeSuccess;
