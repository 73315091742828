import { PaginationInfo } from 'shared/models';
import { ClaimGroup } from 'security/models';

/**
 * Represents the claims state.
 */
export class ClaimsState {
  /**
   * The list of claims groups.
   */
  public claimGroups: ClaimGroup[];

  /**
   * The list of current logged-in user claims groups.
   */
  public currentLoggedInUserClaimGroups: ClaimGroup[];

  /**
   * The pagination info for the claims groups.
   */
  public paginationInfo: PaginationInfo;

  /**
   * An error that may resulting during processing some actions.
   */
  public error: any;

  /**
   * Determines if there is a running process.
   */
  public isGettingClaimsGroups: boolean;

  /**
   * Determines if the last process has been ended successfully.
   */
  public isGetClaimsGroupsCompleted: boolean;

  /**
   * Determines if there is a running current logged-in user claim groups process.
   */
  public isGettingCurrentLoggedInUserClaimsGroups: boolean;

  /**
   * Determines if the last current logged-in user claim groups process has been ended successfully.
   */
  public isGetCurrentLoggedInUserClaimsGroupsCompleted: boolean;
}
