import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import { Observable, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { PageInfo, NotificationService, NotificationMessage, TranslationService } from 'shared';
import * as fromFinancesStore from 'finances/store';
import { Currency } from 'finances/models';

@Component({
  selector: 'app-update-currency',
  templateUrl: './update-currency.component.html',
  styles: [],
})
export class UpdateCurrencyComponent implements OnInit, OnDestroy {
  /**
   * The confirm modal template reference.
   */
  @ViewChild('confirmModalRef') confirmModalRef: ElementRef<any>;

  /**
   * Gets or sets the information about the current page.
   */
  pageInfo: PageInfo = {
    title: 'FINANCES.CURRENCIES.UPDATE_CURRENCY_PAGE_TITLE',
    icon: 'fa fa-edit',
  };

  /**
   * Gets or sets the id of the current edited currency.
   */
  currencyId: number;

  /**
   * The current edited currency.
   */
  currency$: Observable<Currency>;

  /**
   * Indicates whether there is a update currency process is running or not.
   */
  isUpdating$: Observable<boolean>;

  /**
   * The update currency form.
   */
  form: FormGroup;

  /**
   * The name of the current currency.
   */
  currencyName: string;

  /**
   * The set of subscriptions on this components,
   * these subscriptions must be unsubscribed before this component got destroyed.
   */
  subscriptions = new Subscription();

  /**
   * Gets or sets the current selected language.
   */
  get currentLang() {
    return this.translationService.language;
  }

  /**
   * @param route The activated route.
   * @param modalService The modal service.
   * @param notificationService The notification service.
   * @param financesStore$ The finances store.
   * @param translationService The translation service.
   */
  constructor(
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private notificationService: NotificationService,
    private financesStore$: Store<fromFinancesStore.FinancesState>,
    private translationService: TranslationService
  ) {}

  ngOnInit(): void {
    this.init();
  }

  /**
   * Destroy component data
   */
  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  /**
   * Initialize component data.
   */
  init() {
    this.initForm();

    /** Subscribe to route to get the element id whenever it changed. */
    const routeSubscription = this.route.paramMap
      .pipe(
        tap((params) => {
          this.currencyId = +params.get('currencyId');
          this.financesStore$.dispatch(new fromFinancesStore.FindCurrency(this.currencyId));
        })
      )
      .subscribe();
    this.subscriptions.add(routeSubscription);

    /* Load the selected currency. */
    this.currency$ = this.financesStore$.pipe(
      select(fromFinancesStore.getSelectedCurrency),
      tap((currency) => {
        if (currency) {
          this.currencyName = this.currentLang === 'en' ? currency.nameEn : currency.name;

          /* Update page info. */
          this.pageInfo = {
            ...this.pageInfo,
            subTitle: `(${this.currencyName})`,
          };
          this.form.patchValue({ ...currency });
          this.form.markAsPristine();
        }
      })
    );

    /**
     * Load data.
     */
    this.isUpdating$ = this.financesStore$.pipe(select(fromFinancesStore.getSelectedCostCenterUpdating));
  }

  /**
   * Initialize form and add validators.
   */
  initForm() {
    this.form = new FormGroup({
      name: new FormControl('', [Validators.required, Validators.minLength(1), Validators.maxLength(50)]),
      nameEn: new FormControl('', [Validators.required, Validators.minLength(1), Validators.maxLength(50)]),
      isPrimary: new FormControl(false),
    });
  }

  /**
   * Submits the form.
   */
  submit() {
    if (this.form.invalid) {
      const errorMessage = new NotificationMessage();

      if (this.form.get('name').invalid) {
        errorMessage.title = this.translationService.translate(
          'FINANCES.CURRENCIES.CURRENCY_DATA_VALIDATION.ARABIC_CURRENCY_NAME_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate('FINANCES.CURRENCIES.CURRENCY_DATA_VALIDATION.CURRENCY_NAME_IS_REQUIRED'),
          this.translationService.translate('FINANCES.CURRENCIES.CURRENCY_DATA_VALIDATION.CURRENCY_NAME_LENGTH_ERROR'),
        ];
      } else if (this.form.get('nameEn').invalid) {
        errorMessage.title = this.translationService.translate(
          'FINANCES.CURRENCIES.CURRENCY_DATA_VALIDATION.ENGLISH_CURRENCY_NAME_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate('FINANCES.CURRENCIES.CURRENCY_DATA_VALIDATION.CURRENCY_NAME_IS_REQUIRED'),
          this.translationService.translate('FINANCES.CURRENCIES.CURRENCY_DATA_VALIDATION.CURRENCY_NAME_LENGTH_ERROR'),
        ];
      } else if (this.form.get('organizationId').invalid) {
        errorMessage.title = this.translationService.translate(
          'FINANCES.CURRENCIES.CURRENCY_DATA_VALIDATION.ORGANIZATION_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate('FINANCES.CURRENCIES.CURRENCY_DATA_VALIDATION.ORGANIZATION_IS_REQUIRED'),
        ];
      }

      this.form.markAllAsTouched();
      return this.notificationService.warningWithTitle(errorMessage);
    } else {
      this.openModal(this.confirmModalRef);
    }
  }

  /**
   * Confirms the form submit.
   */
  confirm() {
    this.financesStore$.dispatch(
      new fromFinancesStore.UpdateCurrency({
        id: this.currencyId,
        ...this.form.value,
      })
    );
  }

  /**
   * Opens the modal of the given templateRef.
   * @param modalRef The modal templateRef to be opened.
   */
  openModal(modalRef) {
    this.modalService.open(modalRef);
  }
}
