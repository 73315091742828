import { VendorAdvancePaymentLogTypeActionType, VendorAdvancePaymentLogTypeActions } from 'lookups/store/actions';
import { VendorAdvancePaymentLogTypeState } from 'lookups/store/states';

/**
 * The initial state from which the state starts.
 */
const initialState: VendorAdvancePaymentLogTypeState = {
  data: [],
  error: null,
  isLoaded: false,
  isLoading: false,
};

/**
 * The reducer function that is called in each action dispatch against the state.
 * @param state The current state.
 * @param action The action that will affect the state.
 */
export function vendorAdvancePaymentLogTypeReducer(
  state: VendorAdvancePaymentLogTypeState = initialState,
  action: VendorAdvancePaymentLogTypeActions
): VendorAdvancePaymentLogTypeState {
  switch (action.type) {
    case VendorAdvancePaymentLogTypeActionType.GET_ALL_VENDOR_ADVANCE_PAYMENT_LOG_TYPES: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case VendorAdvancePaymentLogTypeActionType.GET_ALL_VENDOR_ADVANCE_PAYMENT_LOG_TYPES_FAIL: {
      return {
        ...state,
        error: action.payload,
        isLoading: false,
        isLoaded: false,
      };
    }

    case VendorAdvancePaymentLogTypeActionType.GET_ALL_VENDOR_ADVANCE_PAYMENT_LOG_TYPES_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        isLoading: false,
        isLoaded: true,
      };
    }

    default:
      return state;
  }
}
