import { Injectable } from '@angular/core';

import { Effect, ofType, Actions } from '@ngrx/effects';
import { switchMap, map, catchError, tap, debounceTime } from 'rxjs/operators';
import { of } from 'rxjs';

import { NotificationService, StatusCode, PAGINATION, TranslationService } from 'shared';
import { LeadsService } from 'crm/services';
import {
  LeadsActionType,
  SearchLeads,
  SearchLeadsFail,
  SearchLeadsSuccess,
  CreateLead,
  CreateLeadSuccess,
  CreateLeadFail,
  UpdateLead,
  UpdateLeadSuccess,
  UpdateLeadFail,
  DeleteLead,
  DeleteLeadSuccess,
  DeleteLeadFail,
  FindLead,
  FindLeadSuccess,
  FindLeadFail,
  DeleteLeadNote,
  DeleteLeadNoteFail,
  DeleteLeadNoteSuccess,
  DeleteLeadAttachment,
  DeleteLeadAttachmentFail,
  DeleteLeadAttachmentSuccess,
  CreateLeadNote,
  CreateLeadNoteFail,
  CreateLeadNoteSuccess,
  CreateLeadAttachments,
  CreateLeadAttachmentsFail,
  CreateLeadAttachmentsSuccess,
  UpdateLeadStatusMarkAsNotHandledYetFail,
  UpdateLeadStatusMarkAsNotHandledYetSuccess,
  UpdateLeadStatusMarkAsNotHandledYet,
  UpdateLeadStatusMarkAsLostFail,
  UpdateLeadStatusMarkAsLostSuccess,
  UpdateLeadStatusMarkAsLost,
  UpdateLeadStatusMarkAsOpportunity,
  UpdateLeadStatusMarkAsOpportunitySuccess,
  UpdateLeadStatusMarkAsOpportunityFail,
} from 'crm/store/actions';

@Injectable()
export class LeadsEffects {
  constructor(
    private actions$: Actions,
    private leadsService: LeadsService,
    private notificationService: NotificationService,
    private translationService: TranslationService
  ) {}

  /* ========================= SEARCH_LEADS =================================== */
  @Effect()
  search$ = this.actions$.pipe(
    ofType(LeadsActionType.SEARCH_LEADS),
    debounceTime(300),
    switchMap((action: SearchLeads) =>
      this.leadsService.search(action.payload?.name ?? '', action.payload.page, PAGINATION.Leads).pipe(
        map((response) => new SearchLeadsSuccess(response)),
        catchError((error) => of(new SearchLeadsFail(error)))
      )
    )
  );

  /* ========================= FIND_LEAD =================================== */
  @Effect()
  find$ = this.actions$.pipe(
    ofType(LeadsActionType.FIND_LEAD),
    switchMap((action: FindLead) =>
      this.leadsService.findById(action.payload).pipe(
        map((response) => new FindLeadSuccess(response)),
        catchError((error) => of(new FindLeadFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  findFail$ = this.actions$.pipe(
    ofType(LeadsActionType.FIND_LEAD_FAIL),
    tap((action: FindLeadFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(this.translationService.translate('CRM.LEADS.LEAD_NOT_FOUND'));
      }
    })
  );

  /* ========================= CREATE_LEAD =================================== */
  @Effect()
  create$ = this.actions$.pipe(
    ofType(LeadsActionType.CREATE_LEAD),
    switchMap((action: CreateLead) =>
      this.leadsService.create(action.payload).pipe(
        map((response) => new CreateLeadSuccess(response)),
        catchError((error) => of(new CreateLeadFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  createSuccess$ = this.actions$.pipe(
    ofType(LeadsActionType.CREATE_LEAD_SUCCESS),
    tap((action: CreateLeadSuccess) => {
      this.notificationService.success(this.translationService.translate('CRM.LEADS.LEAD_ADDED'));
    })
  );

  @Effect({ dispatch: false })
  createFail$ = this.actions$.pipe(
    ofType(LeadsActionType.CREATE_LEAD_FAIL),
    tap((action: CreateLeadFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );

  /* ========================= CREATE_LEAD_NOTE =================================== */
  @Effect()
  createLeadNote$ = this.actions$.pipe(
    ofType(LeadsActionType.CREATE_LEAD_NOTE),
    switchMap((action: CreateLeadNote) =>
      this.leadsService.createLeadNote(action.payload).pipe(
        map((response) => new CreateLeadNoteSuccess(response)),
        catchError((error) => of(new CreateLeadNoteFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  createLeadNoteSuccess$ = this.actions$.pipe(
    ofType(LeadsActionType.CREATE_LEAD_NOTE_SUCCESS),
    tap((action: CreateLeadNoteSuccess) => {
      this.notificationService.success(this.translationService.translate('CRM.LEADS.LEAD_NOTE_ADDED'));
    })
  );

  @Effect({ dispatch: false })
  createLeadNoteFail$ = this.actions$.pipe(
    ofType(LeadsActionType.CREATE_LEAD_NOTE_FAIL),
    tap((action: CreateLeadNoteFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );

  /* ========================= CREATE_LEAD_ATTACHMENTS=================================== */
  @Effect()
  createLeadAttachments$ = this.actions$.pipe(
    ofType(LeadsActionType.CREATE_LEAD_ATTACHMENTS),
    switchMap((action: CreateLeadAttachments) =>
      this.leadsService.createLeadAttachment(action.payload).pipe(
        map((response) => new CreateLeadAttachmentsSuccess(response)),
        catchError((error) => of(new CreateLeadAttachmentsFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  createLeadAttachmentsSuccess$ = this.actions$.pipe(
    ofType(LeadsActionType.CREATE_LEAD_ATTACHMENTS_SUCCESS),
    tap((action: CreateLeadAttachmentsSuccess) => {
      this.notificationService.success(this.translationService.translate('CRM.LEADS.LEAD_ATTACHMENTS_ADDED'));
    })
  );

  @Effect({ dispatch: false })
  createLeadAttachmentsFail$ = this.actions$.pipe(
    ofType(LeadsActionType.CREATE_LEAD_ATTACHMENTS_FAIL),
    tap((action: CreateLeadAttachmentsFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );

  /* ========================= UPDATE_LEAD =================================== */
  @Effect()
  update$ = this.actions$.pipe(
    ofType(LeadsActionType.UPDATE_LEAD),
    switchMap((action: UpdateLead) =>
      this.leadsService.update(action.payload).pipe(
        map((response) => new UpdateLeadSuccess(response)),
        catchError((error) => of(new UpdateLeadFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  updateSuccess$ = this.actions$.pipe(
    ofType(LeadsActionType.UPDATE_LEAD_SUCCESS),
    tap((action: UpdateLeadSuccess) => {
      this.notificationService.success(this.translationService.translate('CRM.LEADS.LEAD_UPDATED'));
    })
  );

  @Effect({ dispatch: false })
  updateFail$ = this.actions$.pipe(
    ofType(LeadsActionType.UPDATE_LEAD_FAIL),
    tap((action: UpdateLeadFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(this.translationService.translate('CRM.LEADS.LEAD_NOT_FOUND'));
      } else if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );

  /* ========================= DELETE_LEAD =================================== */
  @Effect()
  delete$ = this.actions$.pipe(
    ofType(LeadsActionType.DELETE_LEAD),
    switchMap((action: DeleteLead) =>
      this.leadsService.delete(action.payload).pipe(
        map((response) => new DeleteLeadSuccess(response)),
        catchError((error) => of(new DeleteLeadFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  deleteSuccess$ = this.actions$.pipe(
    ofType(LeadsActionType.DELETE_LEAD_SUCCESS),
    tap((action: DeleteLeadSuccess) => {
      this.notificationService.success(this.translationService.translate('CRM.LEADS.LEAD_DELETED'));
    })
  );

  @Effect({ dispatch: false })
  deleteFail$ = this.actions$.pipe(
    ofType(LeadsActionType.DELETE_LEAD_FAIL),
    tap((action: DeleteLeadFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(this.translationService.translate('CRM.LEADS.LEAD_NOT_FOUND'));
      } else if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );

  /* ========================= DELETE_LEAD_NOTE =================================== */
  @Effect()
  deleteLeadNote$ = this.actions$.pipe(
    ofType(LeadsActionType.DELETE_LEAD_NOTE),
    switchMap((action: DeleteLeadNote) =>
      this.leadsService.deleteLeadNote(action.payload).pipe(
        map((response) => new DeleteLeadNoteSuccess(response)),
        catchError((error) => of(new DeleteLeadNoteFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  deleteLeadNoteSuccess$ = this.actions$.pipe(
    ofType(LeadsActionType.DELETE_LEAD_NOTE_SUCCESS),
    tap((action: DeleteLeadNoteSuccess) => {
      this.notificationService.success(this.translationService.translate('CRM.LEADS.LEAD_NOTE_DELETED'));
    })
  );

  @Effect({ dispatch: false })
  deleteLeadNoteFail$ = this.actions$.pipe(
    ofType(LeadsActionType.DELETE_LEAD_NOTE_FAIL),
    tap((action: DeleteLeadNoteFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(this.translationService.translate('CRM.LEADS.LEAD_NOTE_NOT_FOUND'));
      } else if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );

  /* ========================= DELETE_LEAD_ATTACHMENT=================================== */
  @Effect()
  deleteLeadAttachment$ = this.actions$.pipe(
    ofType(LeadsActionType.DELETE_LEAD_ATTACHMENT),
    switchMap((action: DeleteLeadAttachment) =>
      this.leadsService.deleteLeadAttachment(action.payload).pipe(
        map((response) => new DeleteLeadAttachmentSuccess(response)),
        catchError((error) => of(new DeleteLeadAttachmentFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  deleteLeadAttachmentSuccess$ = this.actions$.pipe(
    ofType(LeadsActionType.DELETE_LEAD_ATTACHMENT_SUCCESS),
    tap((action: DeleteLeadAttachmentSuccess) => {
      this.notificationService.success(this.translationService.translate('CRM.LEADS.LEAD_ATTACHMENT_DELETED'));
    })
  );

  @Effect({ dispatch: false })
  deleteLeadAttachmentFail$ = this.actions$.pipe(
    ofType(LeadsActionType.DELETE_LEAD_ATTACHMENT_FAIL),
    tap((action: DeleteLeadAttachmentFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(this.translationService.translate('CRM.LEADS.LEAD_ATTACHMENT_NOT_FOUND'));
      } else if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );

  /* ========================= UPDATE_LEAD_STATUS_MARK_AS_NOT_HANDLED_YET =================================== */
  @Effect()
  updateLeadStatusMarkAsNotHandledYet$ = this.actions$.pipe(
    ofType(LeadsActionType.UPDATE_LEAD_STATUS_MARK_AS_NOT_HANDLED_YET),
    switchMap((action: UpdateLeadStatusMarkAsNotHandledYet) =>
      this.leadsService.updateLeadStatusMarkAsNotHandledYet(action.payload).pipe(
        map((response) => new UpdateLeadStatusMarkAsNotHandledYetSuccess(response)),
        catchError((error) => of(new UpdateLeadStatusMarkAsNotHandledYetFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  updateLeadStatusMarkAsNotHandledYetSuccess$ = this.actions$.pipe(
    ofType(LeadsActionType.UPDATE_LEAD_STATUS_MARK_AS_NOT_HANDLED_YET_SUCCESS),
    tap((action: UpdateLeadStatusMarkAsNotHandledYetSuccess) => {
      this.notificationService.success(this.translationService.translate('CRM.LEADS.LEAD_STATUS_UPDATED'));
    })
  );

  @Effect({ dispatch: false })
  updateLeadStatusMarkAsNotHandledYetFail$ = this.actions$.pipe(
    ofType(LeadsActionType.UPDATE_LEAD_STATUS_MARK_AS_NOT_HANDLED_YET_FAIL),
    tap((action: UpdateLeadStatusMarkAsNotHandledYetFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(this.translationService.translate('CRM.LEADS.LEAD_NOT_FOUND'));
      } else if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );

  /* ========================= UPDATE_LEAD_STATUS_MARK_AS_LOST =================================== */
  @Effect()
  updateLeadStatusMarkAsLost$ = this.actions$.pipe(
    ofType(LeadsActionType.UPDATE_LEAD_STATUS_MARK_AS_LOST),
    switchMap((action: UpdateLeadStatusMarkAsLost) =>
      this.leadsService.updateLeadStatusMarkAsLost(action.payload).pipe(
        map((response) => new UpdateLeadStatusMarkAsLostSuccess(response)),
        catchError((error) => of(new UpdateLeadStatusMarkAsLostFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  updateLeadStatusMarkAsLostSuccess$ = this.actions$.pipe(
    ofType(LeadsActionType.UPDATE_LEAD_STATUS_MARK_AS_LOST_SUCCESS),
    tap((action: UpdateLeadStatusMarkAsLostSuccess) => {
      this.notificationService.success(this.translationService.translate('CRM.LEADS.LEAD_STATUS_UPDATED'));
    })
  );

  @Effect({ dispatch: false })
  updateLeadStatusMarkAsLostFail$ = this.actions$.pipe(
    ofType(LeadsActionType.UPDATE_LEAD_STATUS_MARK_AS_LOST_FAIL),
    tap((action: UpdateLeadStatusMarkAsLostFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(this.translationService.translate('CRM.LEADS.LEAD_NOT_FOUND'));
      } else if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );

  /* ========================= UPDATE_LEAD_STATUS_MARK_AS_OPPORTUNITY =================================== */
  @Effect()
  updateLeadStatusMarkAsOpportunity$ = this.actions$.pipe(
    ofType(LeadsActionType.UPDATE_LEAD_STATUS_MARK_AS_OPPORTUNITY),
    switchMap((action: UpdateLeadStatusMarkAsOpportunity) =>
      this.leadsService.updateLeadStatusMarkAsOpportunity(action.payload).pipe(
        map((response) => new UpdateLeadStatusMarkAsOpportunitySuccess(response)),
        catchError((error) => of(new UpdateLeadStatusMarkAsOpportunityFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  updateLeadStatusMarkAsOpportunitySuccess$ = this.actions$.pipe(
    ofType(LeadsActionType.UPDATE_LEAD_STATUS_MARK_AS_OPPORTUNITY_SUCCESS),
    tap((action: UpdateLeadStatusMarkAsOpportunitySuccess) => {
      this.notificationService.success(this.translationService.translate('CRM.LEADS.LEAD_STATUS_UPDATED'));
    })
  );

  @Effect({ dispatch: false })
  updateLeadStatusMarkAsOpportunityFail$ = this.actions$.pipe(
    ofType(LeadsActionType.UPDATE_LEAD_STATUS_MARK_AS_OPPORTUNITY_FAIL),
    tap((action: UpdateLeadStatusMarkAsOpportunityFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(this.translationService.translate('CRM.LEADS.LEAD_NOT_FOUND'));
      } else if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );
}
