import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';

import { Observable, Subscription } from 'rxjs';
import { Store, select } from '@ngrx/store';

import * as fromSalesStore from 'sales/store';
import * as fromAuthStore from 'auth/store';
import { PageInfo, PrintService } from 'shared';
import { SaleInvoice } from 'sales/models';
import { Claims } from 'security/models';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { skip, tap } from 'rxjs/operators';
import { ProductClass, Location } from 'stores/models';
import { SaleInvoiceOrderTypes } from 'lookups';

@Component({
  selector: 'app-orders-monitoring',
  templateUrl: './orders-monitoring.component.html',
  styles: [],
})
export class OrdersMonitoringComponent implements OnInit, OnDestroy {
  /**
   * Gets or sets the information about the current page.
   */
  pageInfo: PageInfo = {
    title: 'SALES.PENDING_POS_INVOICES.PENDING_POS_INVOICES_DATA.ORDERS_MONITORING',
    icon: 'fa fa-signal',
  };

  /**
   * The system supported user claims.
   */
  Claims = Claims;

  /**
   * Represents the system supported sale invoice order types.
   */
  saleInvoiceOrderType = SaleInvoiceOrderTypes;

  /**
   * Shows or hide the location list.
   */
  locationsListVisibility = false;

  /**
   * Shows or hide the product classes list.
   */
  classesListVisibility = false;

  disabled = false;

  /**
   * The list of products.
   */
  products: number[];

  /**
   * The list of selected employee.
   */
  locations: Location[] = [];

  /**
   * The list of active product classes.
   */
  classes: ProductClass[] = [];

  /**
   * The list of sale invoices.
   */
  saleInvoices$: Observable<SaleInvoice[]>;

  /**
   * Indicates whether there is a prepare process is running or not.
   */
  isPreparing$: Observable<boolean>;

  /**
   * Indicates whether there is a update process is running or not.
   */
  isUpdating$: Observable<boolean>;

  /**
   * The prepare form.
   */
  prepareForm: FormGroup;

  /**
   * Gets or sets the currently selected sale invoice for update.
   */
  selectedSaleInvoice: SaleInvoice;

  /**
   * The set of subscriptions on this components,
   * these subscriptions must be unsubscribed before this component got destroyed.
   */
  subscriptions = new Subscription();

  /**
   * @param modalService The modal service.
   * @param salesStore$ The sales-module store.
   * @param printService The print service.
   * @param authStore$ the auth-store module.
   */
  constructor(
    private modalService: NgbModal,
    private salesStore$: Store<fromSalesStore.SalesState>,
    private printService: PrintService,
    private authStore$: Store<fromAuthStore.AuthState>
  ) {}

  ngOnInit(): void {
    this.init();
  }

  /**
   * Destroy component data
   */
  ngOnDestroy() {
    clearInterval(this.setIntervalConst);
    this.subscriptions.unsubscribe();
  }

  /**
   * Initialize component data.
   */
  init() {
    this.saleInvoices$ = this.salesStore$.pipe(select(fromSalesStore.getPreparedPointOfSaleInvoices));
    this.isPreparing$ = this.salesStore$.pipe(select(fromSalesStore.getPointOfSaleInvoicePreparing));
    this.isUpdating$ = this.salesStore$.pipe(select(fromSalesStore.getPointOfSaleInvoicePrepareUpdating));

    this.initForm();
    this.prepare();

    /**
     * rest on customer asset update completed
     */
    this.subscriptions.add(
      this.salesStore$
        .pipe(
          select(fromSalesStore.getPointOfSaleInvoicePrepareUpdateCompleted),
          skip(1),
          tap((updated) => {
            if (updated) {
              this.prepare();
            }
          })
        )
        .subscribe()
    );

    /* Subscribe to selected location to select it whenever it changed. */
    this.subscriptions.add(
      this.authStore$
        .pipe(
          select(fromAuthStore.getSessionUserDefaultLocation),
          tap((location) => {
            if (location) {
              this.selectLocations([location]);
            }
          })
        )
        .subscribe()
    );
  }

  /**
   * Initialize form and add validators.
   */
  initForm() {
    this.prepareForm = new FormGroup({
      locations: new FormControl([]),
      productClasses: new FormControl([]),
      page: new FormControl(1),
    });
  }

  /**
   * get prepared pos invoice every 30 second.
   */
  setIntervalConst: ReturnType<typeof setInterval> = setInterval(() => {
    this.prepare();
  }, 30000);

  /**
   * Handles prepare parameters change.
   */
  prepare(event?: KeyboardEvent) {
    if (event && event.key === 'Enter') {
      event.preventDefault();
      return;
    }
    this.applyFiltersAndSearch();
  }

  /**
   * Applies the search filters and dispatches a search action.
   * @param page The current page number, default to `1`.
   */
  applyFiltersAndSearch(page: number = 1) {
    this.salesStore$.dispatch(
      new fromSalesStore.PreparePointOfSaleInvoices({
        locations: this.prepareForm.get('locations').value,
        productClasses: this.prepareForm.get('productClasses').value,
        page,
      })
    );
  }

  /**
   * Enables trackBy feature for *ngFor utility to track items
   * depending on a comparer to reduce DOM change.
   * If the item is already exist then no HTML will change, only bounded values.
   * @param index The index of the item.
   * @param saleInvoice The item to determine if it was changed or not.
   */
  trackItems(index: number, saleInvoice: SaleInvoice) {
    return saleInvoice ? saleInvoice.id : undefined;
  }

  /**
   * Opens the modal of the given templateRef.
   * @param modalRef The modal templateRef to be opened.
   */
  openModal(modalRef) {
    this.modalService.open(modalRef);
  }

  /**
   * Adds the newly selected locations the locations search list.
   * @param locations The list of newly selected locations to be added.
   */
  selectLocations(locations: Location[]) {
    const selectedIds: number[] = this.prepareForm.get('locations').value;
    this.locations = [...this.locations.filter((location) => selectedIds.includes(location.id)), ...locations];
    this.prepareForm.patchValue({ locations: this.locations.map((location) => location.id) });
  }

  /**
   * Adds the newly selected locations the product classes search list.
   * @param classes The list of newly selected classes to be added.
   */
  selectClasses(classes: ProductClass[]) {
    const selectedIds: number[] = this.prepareForm.get('productClasses').value;
    this.classes = [...this.classes.filter((productClass) => selectedIds.includes(productClass.id)), ...classes];
    this.products = selectedIds;
    this.prepareForm.patchValue({ productClasses: this.classes.map((productClass) => productClass.id) });
  }

  /**
   * Updates the sale invoice with the given id.
   * @param id The id of the sale invoice to be updated.
   */
  update() {
    this.salesStore$.dispatch(
      new fromSalesStore.UpdatePreparePointOfSaleInvoice({
        id: this.selectedSaleInvoice.id,
        productIds: this.selectedSaleInvoice.saleInvoiceProducts.map((product) => product.id),
      })
    );
  }

  /**
   * Prints the current selected pending pos invoice.
   */
  print() {
    const subscription = this.printService
      .printPendingPosInvoiceById(
        this.selectedSaleInvoice.id,
        this.selectedSaleInvoice.saleInvoiceProducts.map((product) => product.id)
      )
      .subscribe(() => subscription.unsubscribe());
  }
}
