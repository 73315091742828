import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { AppHttpResponse } from 'shared';
import { EmployeeProvisionLink, UpdateEmployeeProvisionLink, GovService, UpdateGovService } from 'finances/models';

/**
 * The configurations services includes functionality to get and update configurations.
 */

@Injectable()
export class FinanceConfigurationsService {
  /**
   * The relative route for the  finance configurations.
   *
   * No leading or trailing slashes required.
   */
  private employeeProvisionLinksApi = 'finances/employee-provision-links';

  private govServicesApi = 'finances/gov-services';

  constructor(private http: HttpClient) {}

  /** Get all employee provision links. */
  public getEmployeeProvisionLinks(): Observable<AppHttpResponse<EmployeeProvisionLink[]>> {
    return this.http.get<AppHttpResponse<EmployeeProvisionLink[]>>(`${this.employeeProvisionLinksApi}`);
  }

  /**
   * Updates existing employee provision links data using the provided data.
   * @param data The updated employee provision links data.
   */
  public updateEmployeeProvisionLinks(
    data: UpdateEmployeeProvisionLink
  ): Observable<AppHttpResponse<EmployeeProvisionLink[]>> {
    return this.http.put<AppHttpResponse<EmployeeProvisionLink[]>>(`${this.employeeProvisionLinksApi}`, data);
  }

  /** Get all gov services. */
  public getGovServices(): Observable<AppHttpResponse<GovService[]>> {
    return this.http.get<AppHttpResponse<GovService[]>>(`${this.govServicesApi}`);
  }

  /**
   * Updates existing gov services data using the provided data.
   * @param data The updated gov services data.
   */
  public updateGovServices(data: UpdateGovService): Observable<AppHttpResponse<GovService[]>> {
    return this.http.put<AppHttpResponse<GovService[]>>(`${this.govServicesApi}`, data);
  }
}
