import { Injectable } from '@angular/core';

import { of } from 'rxjs';
import { switchMap, map, catchError } from 'rxjs/operators';
import { Effect, ofType, Actions } from '@ngrx/effects';

import { LookupsService } from 'lookups/services';
import {
  ProjectResourceTypeActionType,
  GetAllProjectResourceTypesFail,
  GetAllProjectResourceTypesSuccess,
} from 'lookups/store/actions';

@Injectable()
export class ProjectResourceTypeEffects {
  constructor(private actions$: Actions, private lookupsService: LookupsService) {}

  /* ========================= GET_ALL_PROJECT_RESOURCE_TYPES =================================== */
  @Effect()
  getAllProjectResourceTypes$ = this.actions$.pipe(
    ofType(ProjectResourceTypeActionType.GET_ALL_PROJECT_RESOURCE_TYPES),
    switchMap(() =>
      this.lookupsService.getAllProjectResourceTypes().pipe(
        map((response) => new GetAllProjectResourceTypesSuccess(response)),
        catchError((error) => of(new GetAllProjectResourceTypesFail(error)))
      )
    )
  );
}
