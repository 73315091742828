import { Directive, HostListener } from '@angular/core';
import * as fullscreen from 'screenfull';

/**
 * A directive that toggles the fullscreen mode to its host.
 */
@Directive({
  selector: '[appToggleFullscreen]',
})
export class ToggleFullscreenDirective {
  @HostListener('click') onClick() {
    if (fullscreen.isEnabled) {
      fullscreen.toggle();
    }
  }
}
