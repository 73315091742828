<!-- product unit of measure rates list starts -->
<div class="row">
  <div class="col-md-12">
    <div class="card-content">
      <div class="px-3">
        <div class="row">
          <div class="col-md-12">
            <form class="row">
              <div class="form-group col-md-auto mb-0">
                <a class="btn btn-raised btn-success round mx-1" (click)="update()">
                  <i class="fa fa-plus"></i>
                  <span class="mx-1">{{ 'SHARED.CONFIRM.ADD' | translate }}</span>
                </a>
              </div>
            </form>
          </div>
          <div class="table-responsive">
            <table class="table text-center" *ngIf="productUnitOfMeasureRates?.length; else noItems">
              <thead>
                <tr>
                  <th scope="col"><i class="fa fa-list-ol"></i></th>
                  <th scope="col">
                    {{ 'STORES.PRODUCTS.PRODUCT_DATA.UNIT_OF_MEASURE' | translate }}
                  </th>
                  <th scope="col">
                    {{ 'STORES.PRODUCTS.PRODUCT_DATA.RATE' | translate }}
                  </th>
                  <th scope="col">{{ 'SHARED.TOOLS' | translate }}</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let productUnitOfMeasureRate of productUnitOfMeasureRates; let i = index">
                  <th scope="row">{{ i + 1 }}</th>
                  <td scope="col">
                    {{
                      productUnitOfMeasureRate.unitOfMeasure?.name
                        | localize: productUnitOfMeasureRate.unitOfMeasure?.nameEn
                        | async
                    }}
                  </td>
                  <td scope="col">
                    {{ productUnitOfMeasureRate.rate | toDecimal }}
                  </td>
                  <td scope="col">
                    <a
                      class="danger p-0"
                      [ngbTooltip]="'SHARED.CONFIRM.DELETE' | translate"
                      (click)="deleteProductUnitOfMeasureRate(productUnitOfMeasureRate?.id)"
                    >
                      <i class="fa fa-close font-medium-3 mr-2"></i>
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- product unit of measure rates list -->

<!-- no items template starts -->
<ng-template #noItems>
  <div class="col-md-12 alert alert-warning mt-3 text-center" role="alert">
    {{ 'STORES.PRODUCTS.PRODUCT_DATA.PRODUCT_UNIT_OF_MEASURE_RATES_NOT_FOUND' | translate }}
    <button type="button" class="btn btn-raised btn-success round" (click)="update()">
      <i class="fa fa-plus"></i>
      <span class="mx-1">{{ 'SHARED.CONFIRM.ADD' | translate }}</span>
    </button>
  </div>
</ng-template>
<!-- no items template ends -->

<!-- product unit of measure rates ends -->

<!-- update product unit of measure rates modal starts -->
<ng-template #updateModalRef let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
      {{ 'STORES.PRODUCTS.PRODUCT_DATA.ADD_PRODUCT_UNIT_OF_MEASURE_RATE' | translate }}
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="d()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-md-12">
        <form [formGroup]="updateForm" (ngSubmit)="submit()">
          <div class="px-3">
            <div class="row">
              <div class="form-group col-md-12">
                <label for="unitOfMeasureId">{{
                  'STORES.PRODUCTS.PRODUCT_DATA.FROM_UNIT_OF_MEASURE_RATE' | translate
                }}</label>
                <ng-select
                  class="round"
                  formControlName="unitOfMeasureId"
                  labelForId="unitOfMeasureId"
                  searchable="true"
                  [placeholder]="'STORES.PRODUCTS.PRODUCT_DATA.FROM_UNIT_OF_MEASURE_RATE_PLACEHOLDER' | translate"
                  [notFoundText]="'SHARED.SEARCH.SEARCH_NOT_FOUND' | translate"
                  (open)="unitsOfMeasureListVisibility = true"
                >
                  <ng-option *ngFor="let unitOfMeasure of unitsOfMeasure" [value]="unitOfMeasure.id">
                    {{ unitOfMeasure.name | localize: unitOfMeasure.nameEn | async }}
                  </ng-option>
                </ng-select>
              </div>

              <div class="form-group col-md-12">
                <label for="rate">{{ 'STORES.PRODUCTS.PRODUCT_DATA.RATE' | translate }}</label>
                <input
                  type="text"
                  [mask]="DECIMAL_MASK"
                  thousandSeparator=","
                  minLength="0"
                  id="rate"
                  class="form-control round"
                  [placeholder]="'STORES.UNIT_OF_MEASURE_RATES.RATE_PLACEHOLDER' | translate"
                  formControlName="rate"
                />
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="modal-actions">
      <button
        type="button"
        class="btn btn-raised btn-success round"
        [disabled]="isUpdating$ | async"
        (click)="submit()"
        ngbAutofocus
      >
        <i class="fa fa-check"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.SAVE' | translate }}</span>
      </button>
      <button type="button" class="btn btn-outline-dark round" (click)="c()">
        <i class="fa fa-times"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.CANCEL' | translate }}</span>
      </button>
    </div>
  </div>
</ng-template>
<!-- update product unit of measure rates modal ends -->

<!-- delete confirm modal starts -->
<ng-template #deleteConfirmModalRef let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title text-danger" id="modal-basic-title">
      {{ 'STORES.PRODUCTS.CONFIRM_DELETE_PRODUCT_UNIT_OF_MEASURE_RATE_TITLE' | translate }}
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="d()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p class="text-danger">{{ 'STORES.PRODUCTS.CONFIRM_DELETE_PRODUCT_SALES_DISCOUNT_MESSAGE' | translate }}</p>
  </div>
  <div class="modal-footer">
    <div class="modal-actions">
      <button
        type="button"
        class="btn btn-raised btn-danger round"
        (click)="c(); deleteConfirm()"
        ngbAutofocus
        [disabled]="isDeleting$ | async"
      >
        <i class="fa fa-check"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.DELETE' | translate }}</span>
      </button>
      <button type="button" class="btn btn-outline-dark round" (click)="c()">
        <i class="fa fa-times"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.CANCEL' | translate }}</span>
      </button>
    </div>
  </div>
</ng-template>
<!-- delete confirm modal starts -->

<!-- unit of measure search starts -->
<app-units-of-measure-search
  closeOnSelect="true"
  [(visible)]="unitsOfMeasureListVisibility"
  (unitsOfMeasure)="selectUnitOfMeasure($event)"
>
</app-units-of-measure-search>
<!-- unit of measure search ends -->
