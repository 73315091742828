import { Action } from '@ngrx/store';

import { AppHttpResponse } from 'shared';
import { User } from 'security/models';

/**
 * The security-module store users action types.
 */
export enum UsersActionType {
  GET_ORGANIZATION_USERS = '[AppSearch] [Users] Get Organization Users',
  GET_ORGANIZATION_USERS_FAIL = '[AppSearch] [Users] Get Organization Users Fail',
  GET_ORGANIZATION_USERS_SUCCESS = '[AppSearch] [Users] Get Organization Users Success',
}

/**
 * Represents a store get-organization-users action.
 */
export class GetOrganizationUsers implements Action {
  /**
   * The type of the action.
   */
  readonly type = UsersActionType.GET_ORGANIZATION_USERS;

  /**
   * Fires a new  organization users action.
   * @param payload the search parameters,
   * if omitted, all users will be loaded.
   */
  constructor(public payload?: { name: string; page: number }) {}
}

/**
 * Represents a store users get-organization-users-fail action.
 */
export class GetOrganizationUsersFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = UsersActionType.GET_ORGANIZATION_USERS_FAIL;

  /**
   * Fires a new get-organization-users-fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store get-organization-users-success action.
 */
export class GetOrganizationUsersSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = UsersActionType.GET_ORGANIZATION_USERS_SUCCESS;

  /**
   * Fires a new get-organization-users-success action.
   * @param payload An object contains the list of users that matches the search criteria
   * in addition to the pagination info.
   */
  constructor(public payload: AppHttpResponse<User[]>) {}
}

/**
 * Security-module users action types.
 */
export type UsersActions = GetOrganizationUsers | GetOrganizationUsersFail | GetOrganizationUsersSuccess;
