import { PaginationInfo } from 'shared/models';
import { BankAccount, BankAccountLog } from 'finances/models';

/**
 * Represents the bank accounts state.
 */
export class BankAccountState {
  /**
   * The list of bank accounts.
   */
  public bankAccounts: BankAccount[];

  /**
   * The list of bank account logs.
   */
  public bankAccountLogsData: BankAccountLog[];

  /**
   * The list of bank account logs deposit by employee.
   */
  public bankAccountLogsDepositByEmployeeData: BankAccountLog[];

  /**
   * The list of bank account logs by withdraw from account.
   */
  public bankAccountLogsByWithdrawFromAccountData: BankAccountLog[];

  /**
   * The list of bank account logs money transfer.
   */
  public bankAccountLogsMoneyTransferData: BankAccountLog[];

  /**
   * The list of bank account logs cash receipt.
   */
   public bankAccountLogsCashReceiptData: BankAccountLog[];

  /**
   * The list of bank account settlement deed.
   */
  public bankAccountLogsSettlementDeedData: BankAccountLog[];

  /**
   * The pagination info for the bank accounts.
   */
  public paginationInfo: PaginationInfo;

  /**
   * The pagination info for the bank account logs.
   */
  public bankAccountLogsPaginationInfo: PaginationInfo;

  /**
   * An error that may resulting during processing some actions.
   */
  public error: any;

  /**
   * Determines if there is a running search process.
   */
  public isSearching: boolean;

  /**
   * Determines if the last search process has been ended successfully.
   */
  public isSearchCompleted: boolean;

  /**
   * Determines if there is a running search-bank-account-logs process.
   */
  public isSearchingBankAccountLogs: boolean;

  /**
   * Determines if the last search-bank-account-logs process has been ended successfully.
   */
  public isSearchBankAccountLogsCompleted: boolean;

  /**
   * The current selected bank account.
   */
  public selectedBankAccount: BankAccount;

  /**
   * The current selected bank account log.
   */
  public selectedBankAccountLog: BankAccountLog;

  /**
   * The current selected bank account log deposit by employee.
   */
  public selectedBankAccountLogDepositByEmployee: BankAccountLog;

  /**
   * The current selected bank account by withdraw from account .
   */
  public selectedBankAccountByWithdrawFromAccount: BankAccountLog;

  /**
   * The current selected bank account money transfer.
   */
  public selectedBankAccountLogMoneyTransfer: BankAccountLog;

  /**
   * The current selected bank account cash receipt.
   */
  public selectedBankAccountLogCashReceipt: BankAccountLog;

  /**
   * The current selected bank account settlement deed.
   */
  public selectedBankAccountLogSettlementDeed: BankAccountLog;

  /**
   * Determines if there is a running find-bank account process.
   */
  public isFinding: boolean;

  /**
   * Determines if the last find-bank account process has been ended successfully.
   */
  public isFindCompleted: boolean;

  /**
   * Determines if there is a running create process.
   */
  public isCreating: boolean;

  /**
   * Determines if the last create process has been ended successfully.
   */
  public isCreateCompleted: boolean;

  /**
   * Determines if there is a running create bank account log deposit by employee process.
   */
  public isBankAccountLogDepositByEmployeeCreating: boolean;

  /**
   * Determines if the last create bank account log deposit by employee process has been ended successfully.
   */
  public isBankAccountLogDepositByEmployeeCreateCompleted: boolean;

  /**
   * Determines if there is a running create bank account log by withdraw from account process.
   */
  public isBankAccountLogByWithdrawFromAccountCreating: boolean;

  /**
   * Determines if the last create bank account log by withdraw from account process has been ended successfully.
   */
  public isBankAccountLogByWithdrawFromAccountCreateCompleted: boolean;

  /**
   * Determines if there is a running create bank account log process.
   */
  public isBankAccountLogCreating: boolean;

  /**
   * Determines if the last create bank account log process has been ended successfully.
   */
  public isBankAccountLogCreateCompleted: boolean;

  /**
   * Determines if there is a running create bank account money transfer process.
   */
  public isBankAccountLogMoneyTransferCreating: boolean;

  /**
   * Determines if the last create bank account money transfer process has been ended successfully.
   */
  public isBankAccountLogMoneyTransferCreateCompleted: boolean;

  /**
   * Determines if there is a running create bank account cash receipt process.
   */
  public isBankAccountLogCashReceiptCreating: boolean;

  /**
   * Determines if the last create bank account cash receipt process has been ended successfully.
   */
  public isBankAccountLogCashReceiptCreateCompleted: boolean;

  /**
   * Determines if there is a running create bank account settlement deed process.
   */
  public isBankAccountLogSettlementDeedCreating: boolean;

  /**
   * Determines if the last create bank account settlement deed process has been ended successfully.
   */
  public isBankAccountLogSettlementDeedCreateCompleted: boolean;

  /**
   * Determines if there is a running update process.
   */
  public isUpdating: boolean;

  /**
   * Determines if the last update process has been ended successfully.
   */
  public isUpdateCompleted: boolean;

  /**
   * Determines if there is a running block process.
   */
  public isBlocking: boolean;

  /**
   * Determines if the last block process has been ended successfully.
   */
  public isBlockCompleted: boolean;

  /**
   * Determines if there is a running activate process.
   */
  public isActivating: boolean;

  /**
   * Determines if the last activate process has been ended successfully.
   */
  public isActivateCompleted: boolean;

  /**
   * Determines if there is a running delete process.
   */
  public isDeleting: boolean;

  /**
   * Determines if the last delete process has been ended successfully.
   */
  public isDeleteCompleted: boolean;
}
