import { createSelector } from '@ngrx/store';

import { selectLookupsState } from 'lookups/store/selectors/feature.selectors';
import { LookupsState, CountryState } from 'lookups/store/states';

/**
 * Gets the country state.
 */
const selectCountries = createSelector(selectLookupsState, (state: LookupsState) => state.countries);

/**
 * Gets the list of loaded countries.
 */
export const getCountries = createSelector(selectCountries, (state: CountryState) => state.data);

/**
 * Gets the error that occurred in the state.
 */
export const getCountriesError = createSelector(selectCountries, (state: CountryState) => state.error);

/**
 * Determines if there is a running load process.
 */
export const getCountriesIsLoading = createSelector(selectCountries, (state: CountryState) => state.isLoading);

/**
 * Determines if the last load process has been ended successfully.
 */
export const getCountriesIsLoaded = createSelector(selectCountries, (state: CountryState) => state.isLoaded);
