import { createSelector } from '@ngrx/store';

import { selectLookupsState } from 'lookups/store/selectors/feature.selectors';
import { LookupsState, CustomerLogTypeState } from 'lookups/store/states';

/**
 * Gets the customer log type state.
 */
const selectCustomerLogTypes = createSelector(selectLookupsState, (state: LookupsState) => state.customerLogTypes);

/**
 * Gets the list of loaded customer log types.
 */
export const getCustomerLogTypes = createSelector(selectCustomerLogTypes, (state: CustomerLogTypeState) => state.data);

/**
 * Gets the error that occurred in the state.
 */
export const getCustomerLogTypesError = createSelector(
  selectCustomerLogTypes,
  (state: CustomerLogTypeState) => state.error
);

/**
 * Determines if there is a running load process.
 */
export const getCustomerLogTypesIsLoading = createSelector(
  selectCustomerLogTypes,
  (state: CustomerLogTypeState) => state.isLoading
);

/**
 * Determines if the last load process has been ended successfully.
 */
export const getCustomerLogTypesIsLoaded = createSelector(
  selectCustomerLogTypes,
  (state: CustomerLogTypeState) => state.isLoaded
);
