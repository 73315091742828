import { Action } from '@ngrx/store';

import { JournalType } from 'lookups/models';
import { AppHttpResponse } from 'shared';

/**
 * The Lookups-Module store journal  type action types.
 */
export enum JournalTypeActionType {
  GET_ALL_JOURNAL_TYPES = '[Lookups] [JournalType] Get All Journal Types',
  GET_ALL_JOURNAL_TYPES_FAIL = '[Lookups] [JournalType] Get All Journal Types Fail',
  GET_ALL_JOURNAL_TYPES_SUCCESS = '[Lookups] [JournalType] Get All Journal Types Success',
}

/**
 * Represents a store journal  type action.
 */
export class GetAllJournalType implements Action {
  /**
   * The type of the action.
   */
  readonly type = JournalTypeActionType.GET_ALL_JOURNAL_TYPES;

  /**
   * Fires a new journal  type action.
   */
  constructor() {}
}

/**
 * Represents a store journal  type fail action.
 */
export class GetAllJournalTypeFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = JournalTypeActionType.GET_ALL_JOURNAL_TYPES_FAIL;

  /**
   * Fires a new journal  type fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store journal  type success action.
 */
export class GetAllJournalTypeSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = JournalTypeActionType.GET_ALL_JOURNAL_TYPES_SUCCESS;

  /**
   * Fires a journal  type success action.
   * @param payload An object contains the list of journal  types.
   */
  constructor(public payload: AppHttpResponse<JournalType[]>) {}
}

/**
 * Lookups-module journal  type action types.
 */
export type JournalTypeActions = GetAllJournalType | GetAllJournalTypeFail | GetAllJournalTypeSuccess;
