import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';

import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

import { NotificationService, PaginationInfo, TranslationService } from 'shared';
import * as fromFinancesStore from 'finances/store';
import { Currency, CurrencyExchangeRate } from 'finances/models';
import { Claims } from 'security/models';

@Component({
  selector: 'app-currency-exchange-rates-search',
  templateUrl: './currency-exchange-rates-search.component.html',
  styles: [],
})
export class CurrencyExchangeRatesSearchComponent implements OnInit {
  /**
   * Indicates whether the modal will automatically close after select the first currency exchange rate.
   *
   * Be default it will not be closed.
   */
  @Input() closeOnSelect = false;

  /**
   * Sets the visibility of this component modal.
   *
   * Handles visibility change in two-data-binding approach.
   */
  @Input() set visible(isShown: boolean) {
    if (isShown && this.modalRef) {
      this.openModal(this.modalRef);
    } else {
      this.closeModal();
    }

    this.visibleChange.emit(isShown);
  }

  /**
   * Outputs a value that indicates whether the modal is shown or not.
   *
   * Handles visibility change in two-data-binding approach.
   */
  @Output() visibleChange = new EventEmitter<boolean>();

  /** Outputs the selected currency exchange rate. */
  @Output() currencyExchangeRate = new EventEmitter<CurrencyExchangeRate[]>();

  /**
   * The modal template reference.
   */
  @ViewChild('modalRef') modalRef: ElementRef<any>;

  /**
   * Shows or hide the currency list in search.
   */
  primaryCurrencyListVisibility = false;

  /**
   * Shows or hide the currency list in search.
   */
  secondaryCurrencyListVisibility = false;

  /**
   * The list of selected currencies.
   */
  primaryCurrencies: Currency[] = [];

  /**
   * The list of selected currencies.
   */
  secondaryCurrencies: Currency[] = [];

  /** The initial `fromDate` value for search. */
  initialFromDate = new Date();

  /** The initial `toDate` value for search.
   * It's the current day of month.
   */
  initialToDate = new Date();

  /**
   * The list of currency exchange rates.
   */
  currencyExchangeRates$: Observable<CurrencyExchangeRate[]>;

  /**
   * The list of currency exchange rates.
   */
  currencyExchangeRatesList: CurrencyExchangeRate[];

  /**
   * The pagination info.
   */
  paginationInfo$: Observable<PaginationInfo>;

  /**
   * Indicates whether there is a search process is running or not.
   */
  isSearching$: Observable<boolean>;

  /**
   * The system supported user claims.
   */
  Claims = Claims;

  /**
   * The search form.
   */
  searchForm: FormGroup;

  /**
   * The ngb-modal reference to use it when modal needed to be closed.
   */
  modal: NgbModalRef;

  /**
   * @param modalService The modal service.
   * @param notificationService The notification service.
   * @param financesStore$ The finances store.
   * @param translationService The translation service.
   */
  constructor(
    private modalService: NgbModal,
    private notificationService: NotificationService,
    private financesStore$: Store<fromFinancesStore.FinancesState>,
    private translationService: TranslationService
  ) {}

  ngOnInit(): void {
    this.init();
  }

  /**
   * Initialize component data.
   */
  init() {
    this.initForm();

    /**
     * Load data.
     */
    this.isSearching$ = this.financesStore$.pipe(select(fromFinancesStore.getCurrencyExchangeRatesSearching));

    let isManualSearchTriggeredBeforeCurrencyExchangeRates = false;
    this.currencyExchangeRates$ = this.financesStore$.pipe(
      select(fromFinancesStore.getCurrencyExchangeRates),
      tap((currencies) => {
        if (currencies) {
          this.currencyExchangeRatesList = currencies;
        }
        if (!isManualSearchTriggeredBeforeCurrencyExchangeRates && !currencies.length) {
          isManualSearchTriggeredBeforeCurrencyExchangeRates = true;
          this.search();
        }
      })
    );

    this.paginationInfo$ = this.financesStore$.pipe(select(fromFinancesStore.getCurrencyExchangeRatesPaginationInfo));
  }

  /**
   * Initialize form and add validators.
   */
  initForm() {
    this.searchForm = new FormGroup({
      primaryCurrencies: new FormControl([]),
      secondaryCurrencies: new FormControl([]),
      fromDate: new FormControl({
        year: this.initialFromDate.getFullYear(),
        month: this.initialFromDate.getMonth() + 1,
        day: this.initialFromDate.getDate(),
      }),
      toDate: new FormControl({
        year: this.initialToDate.getFullYear(),
        month: this.initialToDate.getMonth() + 1,
        day: this.initialToDate.getDate(),
      }),
      page: new FormControl(1),
    });
  }

  /**
   * Handles search parameters change.
   */
  search(event?: KeyboardEvent) {
    if (event && event.key === 'Enter') {
      event.preventDefault();
      return;
    }

    this.applyFiltersAndSearch();
  }

  /**
   * Handles pagination page-changed event.
   * @param page The current selected page number.
   */
  pageChanged(page: number) {
    /** Update pagination page. */
    this.searchForm.patchValue({ page });

    this.applyFiltersAndSearch(page);
  }

  /**
   * Enables trackBy feature for *ngFor utility to track items
   * depending on a comparer to reduce DOM change.
   * If the item is already exist then no HTML will change, only bounded values.
   * @param item The item to determine if it was changed or not.
   */
  trackItems(item: Currency) {
    return item ? item.id : undefined;
  }

  applyFiltersAndSearch(page: number = 1) {
    const { primaryCurrencies, secondaryCurrencies, fromDate, toDate } = this.searchForm.value;

    const fromDateFormatted = new Date(fromDate.year, fromDate.month - 1, fromDate.day);
    fromDateFormatted.setHours(0, 0, 0, 0);

    const toDateFormatted = new Date(toDate.year, toDate.month - 1, toDate.day);
    toDateFormatted.setHours(23, 59, 59, 0);

    this.financesStore$.dispatch(
      new fromFinancesStore.SearchCurrencyExchangeRates({
        primaryCurrencies,
        secondaryCurrencies,
        fromDate: fromDateFormatted,
        toDate: toDateFormatted,
        page,
      })
    );
  }

  /**
   * Opens the modal of the given templateRef.
   * @param modalRef The modal templateRef to be opened.
   */
  openModal(modalRef) {
    /**
     * Open the modal & listen for user press ESC key.
     */
    this.modal = this.modalService.open(modalRef, { size: 'lg' });
    this.modal.result.then(null, () => this.closeModal());
  }

  /**
   * Closes the currently opened modal.
   */
  closeModal() {
    this.modal?.dismiss();
    this.visibleChange.emit(false);
  }

  /**
   * Handles currency exchange rate selected event.
   * @param currencyExchangeRate The list of currency exchange rate to notify selected.
   */
  selectCurrencyExchangeRate(currencyExchangeRate: CurrencyExchangeRate[]) {
    this.notificationService.successInstant(
      this.translationService.translate(
        'FINANCES.CURRENCY_EXCHANGE_RATE.NOTIFICATION_MESSAGE.CURRENCY_EXCHANGE_RATE_SELECTED'
      )
    );
    this.currencyExchangeRate.emit(currencyExchangeRate);
    if (this.closeOnSelect) {
      this.closeModal();
    }
  }

  /**
   * Adds the newly selected primaryCurrencies the primaryCurrencies search list.
   * @param primaryCurrencies The list of newly selected primaryCurrencies to be added.
   */
  selectPrimaryCurrencies(primaryCurrencies: Currency[]) {
    const selectedIds: number[] = this.searchForm.get('primaryCurrencies').value;
    this.primaryCurrencies = [
      ...this.primaryCurrencies.filter((currency) => selectedIds.includes(currency.id)),
      ...primaryCurrencies,
    ];
    this.searchForm.patchValue({ primaryCurrencies: this.primaryCurrencies.map((currency) => currency.id) });
  }

  /**
   * Adds the newly selected secondaryCurrencies the secondaryCurrencies search list.
   * @param secondaryCurrencies The list of newly selected secondaryCurrencies to be added.
   */
  selectSecondaryCurrencies(secondaryCurrencies: Currency[]) {
    const selectedIds: number[] = this.searchForm.get('secondaryCurrencies').value;
    this.secondaryCurrencies = [
      ...this.secondaryCurrencies.filter((currency) => selectedIds.includes(currency.id)),
      ...secondaryCurrencies,
    ];
    this.searchForm.patchValue({ secondaryCurrencies: this.secondaryCurrencies.map((currency) => currency.id) });
  }
}
