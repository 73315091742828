import { Injectable } from '@angular/core';

import { ManagerOptions, SocketOptions, Socket } from '@socket.io.client';

import { StorageService } from 'shared/services/storage.service';
import { WebsocketEventsService } from 'websocket/services/websocket-events.service';

import { environment } from '../../../environments/environment';

/**
 * The io from global scope.
 *
 * @notes This variable is available in the global scope because of the import of socket.io-client.js in the index.html.
 */
declare var io:
  | ((opts?: Partial<ManagerOptions & SocketOptions>) => Socket)
  | ((uri: string, opts?: Partial<ManagerOptions & SocketOptions>) => Socket)
  | ((uri: string | Partial<ManagerOptions & SocketOptions>, opts?: Partial<ManagerOptions & SocketOptions>) => Socket);

/**
 * Contains the functionality for websocket.
 */
@Injectable()
export class WebsocketService {
  /**
   * A reference to the current instance of {@link Socket}.
   */
  private socket: Socket;

  constructor(private storageService: StorageService, private websocketEventsService: WebsocketEventsService) {}

  /**
   * Connects the websocket instance to the websocket server.
   */
  connect(): void {
    /**
     * Don't connect anonymous users [FOR NOW].
     */
    if (!this.storageService.isJwtExists()) {
      return;
    }

    /**
     * Close the connection if it was opened before.
     */
    if (this.socket?.active || this.socket?.connected) {
      this.disconnect();
    }

    this.socket = io(environment.websocketUrl, {
      extraHeaders: {
        Authorization: this.storageService.getJwt()?.token,
        'device-id': this.storageService.deviceId,
      },
      rejectUnauthorized: false,
      reconnectionDelay: environment.production ? 5000 : 30000,
      reconnectionDelayMax: 30000,
    });

    this.socket.on('connect', () => {});

    this.socket.on('connect_error', (error) => {
      if (environment.production) {
        console.clear();
      }
    });

    this.socket.io.on('reconnect_attempt', () => {});

    this.websocketEventsService.setupHandlers(this.socket);
  }

  /**
   * Disconnects the websocket instance from the websocket server.
   */
  disconnect(): void {
    /**
     * Close the connection if it was opened before.
     */
    if (this.socket?.active || this.socket?.connected) {
      this.socket.disconnect();
    }
  }
}
