import { Injectable } from '@angular/core';

import { of } from 'rxjs';
import { switchMap, map, catchError } from 'rxjs/operators';
import { Effect, ofType, Actions } from '@ngrx/effects';

import { LookupsService } from 'lookups/services';
import { EmailTypeActionType, GetAllEmailTypesFail, GetAllEmailTypesSuccess } from 'lookups/store/actions';

@Injectable()
export class EmailTypeEffects {
  constructor(private actions$: Actions, private lookupsService: LookupsService) {}

  /* ========================= GET_ALL_EMAIL_TYPE =================================== */
  @Effect()
  getAllEmailTypes$ = this.actions$.pipe(
    ofType(EmailTypeActionType.GET_ALL_EMAIL_TYPES),
    switchMap(() =>
      this.lookupsService.getAllEmailTypes().pipe(
        map((response) => new GetAllEmailTypesSuccess(response)),
        catchError((error) => of(new GetAllEmailTypesFail(error)))
      )
    )
  );
}
