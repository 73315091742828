import { Component, Renderer2, ElementRef, ViewChild, AfterViewInit } from '@angular/core';
import { customAnimations } from 'shared';
import { MenuItem, MENU_ITEMS } from 'core/models';

import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  animations: customAnimations,
})
export class SidebarComponent implements AfterViewInit {
  /**
   * The sidebar toggle-icon element reference.
   */
  @ViewChild('toggleIcon') toggleIcon: ElementRef;

  /**
   * Gets or sets the environment configurations.
   */
  environmentConfig = environment;

  /**
   * The sidebar menu items.
   */
  menuItems: MenuItem[] = MENU_ITEMS;

  depth: number;
  activeTitle: string;
  activeTitles: string[] = [];
  expanded: boolean;
  navCollapsedOpen = false;

  /**
   * @param renderer The Renderer2 service.
   */
  constructor(private renderer: Renderer2) {
    if (this.depth === undefined) {
      this.depth = 0;
      this.expanded = true;
    }
  }

  ngAfterViewInit() {
    /*
     * Initialize sidebar toggle icon state.
     */
    setTimeout(() => {
      this.expanded = true;
      this.renderer.removeClass(this.toggleIcon.nativeElement, 'ft-toggle-left');
      this.renderer.addClass(this.toggleIcon.nativeElement, 'ft-toggle-right');
      this.navCollapsedOpen = false;
    }, 0);
  }

  /**
   * Change sidebar collapse state.
   */
  toggleSlideInOut() {

    this.expanded = !this.expanded;


  }

  /**
   * Sets the active titles.
   * @param titles The active titles.
   */
  handleToggle(titles) {
   
    this.activeTitles = titles;
  }
}
