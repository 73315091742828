import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { FormArray, FormGroup, FormControl } from '@angular/forms';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SaleInvoiceOrderType } from 'lookups/models';
import { OrderTypeFormItem } from 'sales/models';

@Component({
  selector: 'app-order-types-form',
  templateUrl: './order-types-form.component.html',
  styles: [],
})
export class OrderTypesFormComponent {
  /**
   * The confirm modal template reference.
   */
  @ViewChild('deleteModalRef') deleteModalRef: ElementRef<any>;

  /**
   * Sets the order types form-array.
   */
  @Input() formArray: FormArray;

  /**
   * Sets the name form-array in its parent form.
   * @default 'orderTypes'
   */
  @Input() formArrayName = 'orderTypes';

  /**
   * Adds a list of new order type form items to the order type form.
   * @param items The list of order type form items to be added to the order type form.
   */
  @Input() set newOrderTypes(items: OrderTypeFormItem[]) {
    if (!items?.length) {
      return;
    }

    items.forEach((item) => this.createOrderTypeFormGroup(item));
  }

  /**
   * Sets a value indicates whether if the user should specify a order type or not.
   * @default false
   */
  @Input() isOrderTypeRequired = false;

  /**
   * Shows or hides the order type list.
   */
  orderTypesListVisibility = false;

  /**
   * Indicates whether if we can add order type or not.
   */
  @Input() allowAddOrderType = true;

  /**
   * Indicates whether if we can clear order type or not.
   * @default `true`.
   */
  @Input() allowClearOrderTypes = true;

  /**
   * @param modalService The modal service.
   */
  constructor(private modalService: NgbModal) {}

  /**
   * Creates & adds a new order type form-group with validations.
   * @param order type The order type details to be bounded to the order type, If omitted a blank order type will be created.
   */
  addOrderType() {
    this.orderTypesListVisibility = true;
  }

  /**
   * Open confirm delete all order type modal .
   */
  openDeleteModal() {
    this.openModal(this.deleteModalRef);
  }

  /**
   * Opens the modal of the given templateRef.
   * @param modalRef The modal templateRef to be opened.
   */
  openModal(modalRef) {
    this.modalService.open(modalRef);
  }

  /**
   * Remove the order type at the given index from the order type form.
   * @param index The index of the order type form group.
   */
  removeOrderType(index: number) {
    this.formArray.removeAt(index);
  }

  /**
   * Remove all of the list of order types.
   *
   * It only adds one order type for quick start.
   */
  resetOrderTypes() {
    this.formArray.clear();
  }

  /**
   * Handles the order type select event.
   * @param order type The newly selected order type.
   */
  selectOrderType(orderTypes: SaleInvoiceOrderType[]) {
    if (orderTypes) {
      orderTypes.map((orderType) =>
        this.createOrderTypeFormGroup(this.createOrderTypeFormItemFromOrderType(orderType))
      );
    }
  }

  /**
   * Creates and returns a new order type form item from a given order type.
   * @param order type The order type to be used to create the order type form item.
   */
  createOrderTypeFormItemFromOrderType(orderType: SaleInvoiceOrderType): OrderTypeFormItem {
    return {
      saleInvoiceOrderTypeId: orderType.id,
      orderType,
    };
  }

  /**
   * Create a new order type form group from the provided order type form item.
   * @param order type The order type form item that contains data about the new order type.
   */
  createOrderTypeFormGroup(orderType: OrderTypeFormItem) {
    if (this.isOrderTypeExists(orderType.orderType.id)) {
      return;
    }

    const formGroup = new FormGroup({
      saleInvoiceOrderTypeId: new FormControl(orderType?.saleInvoiceOrderTypeId ?? null),
      orderType: new FormControl(orderType?.orderType),
    });
    this.formArray.push(formGroup);
  }

  /**
   * Indicates whether if the given order type id is already exists in the form array or not.
   * @param saleInvoiceOrderTypeId The id of the order type.
   * @returns `boolean`.
   */
  isOrderTypeExists(saleInvoiceOrderTypeId: number): boolean {
    return (this.formArray.value as OrderTypeFormItem[]).some(
      (orderTypeItem) => orderTypeItem.orderType.id === saleInvoiceOrderTypeId
    );
  }

  /**
   * Handles the order type select event.
   * @param orderType The newly selected locations.
   */
  selectOrderTypes([orderType]: SaleInvoiceOrderType[]) {
    if (orderType) {
      this.createOrderTypeFormGroup(this.createOrderTypeFormItemFromOrderType(orderType));
    }
  }
}
