import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';

import { v4 as uuid } from 'uuid';

import { AppHttpResponse } from 'shared';
import {
  CreatePettyCashRefundRequestInput,
  PettyCashRefundRequest,
  UpdatePettyCashRefundRequestInput,
} from 'finances/models';

/**
 * The petty cash refund request services includes functionality to create, search and findById a petty cash refund request.
 */

@Injectable()
export class PettyCashRefundRequestsService {
  /**
   * The relative route for the petty cash refund requests.
   *
   * No leading or trailing slashes required.
   */
  private pettyCashRefundRequestsApi = 'finances/pettyCash/refunds/requests';

  constructor(private http: HttpClient) {}

  /**
   * Creates a new petty cash refund request from the provided data.
   * @param data The new petty cash refund request data.
   */
  public create(data: CreatePettyCashRefundRequestInput): Observable<AppHttpResponse<PettyCashRefundRequest>> {
    const formData: any = new FormData();
    formData.append('pettyCashId', data.pettyCashId);
    formData.append('notes', data.notes);

    /**
     * Append lines to the form data.
     */
    for (let index = 0; index < data.lines.length; index++) {
      /**
       * Gets the uuid for this line.
       * It's a workaround to upload multiple files per line.
       */
      const id = uuid();

      formData.append(`lines[${index}][uuid]`, id);
      formData.append(`lines[${index}][description]`, data.lines[index].description);
      formData.append(`lines[${index}][pettyCashRefundRequestTypeId]`, data.lines[index].pettyCashRefundRequestTypeId);
      formData.append(`lines[${index}][vendorName]`, data.lines[index].vendorName);
      formData.append(`lines[${index}][value]`, data.lines[index].value);
      formData.append(`lines[${index}][tax]`, data.lines[index].tax);
      formData.append(`lines[${index}][invoiceDate]`, data.lines[index].invoiceDate?.toISOString());

      /**
       * Append attachments to the form data.
       */
      data.lines[index].attachments.forEach(
        (attachment) => attachment && formData.append(id, attachment, attachment.name)
      );
    }
    return this.http.post<AppHttpResponse<PettyCashRefundRequest>>(`${this.pettyCashRefundRequestsApi}`, formData);
  }

  /**
   * Searches in the petty cash refund request by pettyCash or fromDate or toDate.
   * @param pettyCash The pettyCash of the petty cash refund request.
   * @param fromDate The fromDate of the petty cash refund request.
   * @param toDate The toDate of the petty cash refund request.
   * @param page The current pagination page number.
   * @param pageSize The maximum number of petty cash refund request allowed per one pagination page.
   */
  public search(
    pettyCash: number[],
    fromDate: Date,
    toDate: Date,
    page: number,
    pageSize: number
  ): Observable<AppHttpResponse<PettyCashRefundRequest[]>> {
    const params = new HttpParams()
      .set('pettyCash', pettyCash.join(','))
      .set('fromDate', fromDate.toISOString())
      .set('toDate', toDate.toISOString())
      .set('page', page.toString())
      .set('pageSize', pageSize.toString());
    return this.http.get<AppHttpResponse<PettyCashRefundRequest[]>>(`${this.pettyCashRefundRequestsApi}`, { params });
  }

  /**
   * Finds the petty cash refund request with the given id.
   * @param id The id of the petty cash refund request.
   */
  public findById(id: number): Observable<AppHttpResponse<PettyCashRefundRequest>> {
    return this.http.get<AppHttpResponse<PettyCashRefundRequest>>(`${this.pettyCashRefundRequestsApi}/${id}`);
  }

  /**
   * Update petty cash refund request from the provided data.
   * @param data The new petty cash refund request data.
   */
  public update(data: UpdatePettyCashRefundRequestInput): Observable<AppHttpResponse<PettyCashRefundRequest>> {
    const formData: any = new FormData();
    formData.append('pettyCashRefundRequestId', data.pettyCashRefundRequestId);
    formData.append('transactionDate', data.transactionDate);
    formData.append('notes', data.notes);

    /**
     * Append lines to the form data.
     */
    for (let index = 0; index < data.lines.length; index++) {
      /**
       * Gets the uuid for this line.
       * It's a workaround to upload multiple files per line.
       */
      const id = uuid();

      formData.append(`lines[${index}][uuid]`, id);
      formData.append(`lines[${index}][description]`, data.lines[index].description);
      formData.append(`lines[${index}][pettyCashRefundRequestTypeId]`, data.lines[index].pettyCashRefundRequestTypeId);
      formData.append(`lines[${index}][vendorName]`, data.lines[index].vendorName);
      formData.append(`lines[${index}][value]`, data.lines[index].value);
      formData.append(`lines[${index}][tax]`, data.lines[index].tax);
      formData.append(`lines[${index}][invoiceDate]`, data.lines[index].invoiceDate?.toISOString());

      /**
       * Append attachments to the form data.
       */
      data.lines[index].attachments.forEach(
        (attachment) => attachment && formData.append(id, attachment, attachment.name)
      );
    }
    return this.http.put<AppHttpResponse<PettyCashRefundRequest>>(`${this.pettyCashRefundRequestsApi}`, formData);
  }
}
