import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';

import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import * as fromSalesStore from 'sales/store';
import * as fromLookupsStore from 'lookups/store';
import { NotificationService, PageInfo, PaginationInfo, TranslationService } from 'shared';
import { CustomerLogType } from 'lookups/models';
import { Customer, CustomerLog, CustomerLogAttachment } from 'sales/models';
import { Claims } from 'security/models';

@Component({
  selector: 'app-customers-payments-list',
  templateUrl: './customers-payments-list.component.html',
  styles: [],
})
export class CustomersPaymentsListComponent implements OnInit {
  /**
   * The view modal template reference.
   */
  @ViewChild('viewModalRef') viewModalRef: ElementRef<any>;

  /**
   * The view journal modal template reference.
   */
  @ViewChild('viewJournalModalRef') viewJournalModalRef: ElementRef<any>;

  /**
   * The confirm view attachments modal template reference.
   */
  @ViewChild('viewAttachmentsModalRef') viewAttachmentsModalRef: ElementRef<any>;

  /**
   * Gets or sets the information about the current page.
   */
  pageInfo: PageInfo = {
    title: 'SALES.CUSTOMERS.CUSTOMER_DATA.CUSTOMERS_PAYMENT',
    icon: 'fa fa-usd',
  };

  /**
   * The system supported user claims.
   */
  Claims = Claims;

  /**
   * The list of payments.
   */
  payments$: Observable<CustomerLog[]>;

  /**
   * The current selected payment.
   */
  selectedPayment: CustomerLog;

  /**
   * The list of selected customers.
   */
  customers: Customer[] = [];

  /**
   * The list of customer payment types.
   */
  customerLogTypes$: Observable<CustomerLogType[]>;

  /**
   * The pagination info.
   */
  paginationInfo$: Observable<PaginationInfo>;

  /**
   * Indicates whether there is a search process is running or not.
   */
  isSearching$: Observable<boolean>;

  /**
   * The search form.
   */
  searchForm: FormGroup;

  /**
   * Shows or hide the customers list.
   */
  customersListVisibility = false;

  /** The initial `fromDate` value for search. */
  initialFromDate = new Date();

  /** The initial `toDate` value for search.
   * It's the current day of month.
   */
  initialToDate = new Date();

  /**
   * @param modalService The modal service.
   * @param notificationService The  notification service.
   * @param translationService The  translation service.
   * @param salesStore$ Sales-module store.
   * @param lookupsStore$: lookups-module store.
   */
  constructor(
    private modalService: NgbModal,
    private notificationService: NotificationService,
    private translationService: TranslationService,
    private salesStore$: Store<fromSalesStore.SalesState>,
    private lookupsStore$: Store<fromLookupsStore.LookupsState>
  ) {}

  ngOnInit(): void {
    this.init();
  }

  /**
   * Initialize component data.
   */
  init() {
    this.initForm();

    /** Loads data. */
    this.isSearching$ = this.salesStore$.pipe(select(fromSalesStore.getCustomersPaymentsSearching));

    let isManualSearchTriggeredBeforeForPayments = false;
    this.payments$ = this.salesStore$.pipe(
      select(fromSalesStore.getCustomersPayments),
      tap((payments) => {
        if (!isManualSearchTriggeredBeforeForPayments && !payments.length) {
          isManualSearchTriggeredBeforeForPayments = true;
          this.search();
        }
      })
    );

    let isManualSearchTriggeredBeforeForTypes = false;
    this.customerLogTypes$ = this.lookupsStore$.pipe(
      select(fromLookupsStore.getAllCustomerPaymentLogTypes),
      tap((types) => {
        if (!isManualSearchTriggeredBeforeForTypes && !types.length) {
          isManualSearchTriggeredBeforeForTypes = true;
          this.lookupsStore$.dispatch(new fromLookupsStore.GetAllCustomerPaymentLogTypes());
        }
      })
    );

    this.paginationInfo$ = this.salesStore$.pipe(select(fromSalesStore.getCustomersPaymentsPaginationInfo));
  }

  /**
   * Initialize form and add validators.
   */
  initForm() {
    this.searchForm = new FormGroup({
      customers: new FormControl([]),
      customerLogTypes: new FormControl([]),
      fromDate: new FormControl({
        year: this.initialFromDate.getFullYear(),
        month: this.initialFromDate.getMonth() + 1,
        day: this.initialFromDate.getDate(),
      }),
      toDate: new FormControl({
        year: this.initialToDate.getFullYear(),
        month: this.initialToDate.getMonth() + 1,
        day: this.initialToDate.getDate(),
      }),
      page: new FormControl(1),
    });
  }

  /**
   * Handles search parameters change.
   */
  search(event?: KeyboardEvent) {
    if (event && event.key === 'Enter') {
      event.preventDefault();
      return;
    }

    this.applyFiltersAndSearch();
  }

  /**
   * Handles pagination page-changed event.
   * @param page The current selected page number.
   */
  pageChanged(page: number) {
    /** Update pagination page. */
    this.searchForm.patchValue({ page });

    this.applyFiltersAndSearch(page);
  }

  /**
   * Applies the search filters and dispatches a search action.
   * @param page The current page number, default to `1`.
   */
  applyFiltersAndSearch(page: number = 1) {
    if (!this.searchForm.get('fromDate')?.value || !this.searchForm.get('toDate')?.value) {
      return this.notificationService.warning(
        this.translationService.translate('SALES.CUSTOMERS.NOTIFICATION_MESSAGE.SELECT_DATE')
      );
    }

    const fromDate = new Date(
      this.searchForm.get('fromDate').value.year,
      this.searchForm.get('fromDate').value.month - 1,
      this.searchForm.get('fromDate').value.day
    );
    fromDate.setHours(0, 0, 0, 0);

    const toDate = new Date(
      this.searchForm.get('toDate').value.year,
      this.searchForm.get('toDate').value.month - 1,
      this.searchForm.get('toDate').value.day
    );
    toDate.setHours(23, 59, 59, 0);

    this.salesStore$.dispatch(
      new fromSalesStore.SearchCustomersPayments({
        customers: this.searchForm.get('customers').value,
        customerLogTypes: this.searchForm.get('customerLogTypes').value,
        fromDate,
        toDate,
        page,
      })
    );
  }

  /**
   * Enables trackBy feature for *ngFor utility to track items
   * depending on a comparer to reduce DOM change.
   * If the item is already exist then no HTML will change, only bounded values.
   * @param index The index of the item.
   * @param log The item to determine if it was changed or not.
   */
  trackItems(index: number, log: CustomerLog) {
    return log ? log.id : undefined;
  }

  /**
   * Adds the newly selected customers the customers search list.
   * @param customers The list of newly selected customers to be added.
   */
  selectCustomers(customers: Customer[]) {
    const selectedIds: number[] = this.searchForm.get('customers').value;
    this.customers = [...this.customers.filter((customer) => selectedIds.includes(customer.id)), ...customers];
    this.searchForm.patchValue({ customers: this.customers.map((customer) => customer.id) });
  }

  /**
   * Views the selected payment details.
   * @param payment The payment to be viewed.
   */
  view(payment: CustomerLog) {
    this.selectedPayment = payment;
    this.openModal(this.viewModalRef);
  }

  /**
   * Maps and returns the set of customer log attachments into a set of urls.
   * @param attachments The customer log  attachments to be mapped.
   */
  getAttachmentsUrls(attachments: CustomerLogAttachment[]): string[] {
    return attachments ? attachments.map((attachment) => attachment.url) : [];
  }

  /**
   * Opens the modal of the given templateRef.
   * @param modalRef The modal templateRef to be opened.
   */
  openModal(modalRef) {
    this.modalService.open(modalRef, { size: 'xl' });
  }

  /**
   * Opens the modal of the given templateRef.
   * @param modalRef The modal templateRef to be opened.
   */
  openModalJournal(modalRef) {
    this.modalService.open(modalRef, { size: 'xl' });
  }
}
