import { Injectable } from '@angular/core';

import { of } from 'rxjs';
import { switchMap, map, catchError } from 'rxjs/operators';
import { Effect, ofType, Actions } from '@ngrx/effects';

import { LookupsService } from 'lookups/services';
import { IdentityTypeActionType, GetAllIdentityTypeFail, GetAllIdentityTypeSuccess } from 'lookups/store/actions';

@Injectable()
export class IdentityTypeEffects {
  constructor(private actions$: Actions, private lookupsService: LookupsService) {}

  /* ========================= GET_ALL_IDENTITY_TYPE =================================== */
  @Effect()
  getAllIdentityTypes$ = this.actions$.pipe(
    ofType(IdentityTypeActionType.GET_ALL_IDENTITY_TYPE),
    switchMap(() =>
      this.lookupsService.getAllIdentityTypes().pipe(
        map((response) => new GetAllIdentityTypeSuccess(response)),
        catchError((error) => of(new GetAllIdentityTypeFail(error)))
      )
    )
  );
}
