import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import { Observable, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { PageInfo, NotificationService, NotificationMessage, TranslationService } from 'shared';
import * as fromStoresStore from 'stores/store';
import { UnitOfMeasure, UnitOfMeasureClass } from 'stores/models';

@Component({
  selector: 'app-update-unit-of-measure',
  templateUrl: './update-unit-of-measure.component.html',
  styles: [],
})
export class UpdateUnitOfMeasureComponent implements OnInit, OnDestroy {
  /**
   * The confirm modal template reference.
   */
  @ViewChild('confirmModalRef') confirmModalRef: ElementRef<any>;

  /**
   * Gets or sets the information about the current page.
   */
  pageInfo: PageInfo = {
    title: 'STORES.UNITS_OF_MEASURE.UPDATE_UNIT_OF_MEASURE_TITLE',
    icon: 'fa fa-edit',
  };

  /**
   * Gets or sets the id of the current edited unit of measure.
   */
  unitOfMeasureId: number;

  /**
   * The current edited unit of measure.
   */
  unitOfMeasure$: Observable<UnitOfMeasure>;

  /**
   * Indicates whether there is a update unit of measure process is running or not.
   */
  isUpdating$: Observable<boolean>;

  /**
   * Shows or hide the unit of measure classes list.
   */
  unitOfMeasureClassesListVisibility = false;

  /**
   * The list of selected unit Of measure classes.
   */
  unitOfMeasureClasses: UnitOfMeasureClass[] = [];

  /**
   * The update unit of measure form.
   */
  form: FormGroup;

  /**
   * The name of the current unit of measure.
   */
  unitOfMeasureName: string;

  /**
   * The set of subscriptions on this components,
   * these subscriptions must be unsubscribed before this component got destroyed.
   */
  subscriptions = new Subscription();

  /**
   * Gets or sets the current selected language.
   */
  get currentLang() {
    return this.translationService.language;
  }

  /**
   * @param route The activated route.
   * @param modalService The modal service.
   * @param notificationService The notification service.
   * @param storesStore$  The stores store.
   * @param translationService The translation service.
   */
  constructor(
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private notificationService: NotificationService,
    private storesStore$: Store<fromStoresStore.StoresState>,
    private translationService: TranslationService
  ) {}

  ngOnInit(): void {
    this.init();
  }

  /**
   * Destroy component data
   */
  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  /**
   * Initialize component data.
   */
  init() {
    this.initForm();

    /** Subscribe to route to get the element id whenever it changed. */
    const routeSubscription = this.route.paramMap
      .pipe(
        tap((params) => {
          this.unitOfMeasureId = +params.get('unitOfMeasureId');
          this.storesStore$.dispatch(new fromStoresStore.FindUnitOfMeasure(this.unitOfMeasureId));
        })
      )
      .subscribe();
    this.subscriptions.add(routeSubscription);

    /* Load the selected unit of measure. */
    this.unitOfMeasure$ = this.storesStore$.pipe(
      select(fromStoresStore.getSelectedUnitOfMeasure),
      tap((unitOfMeasure) => {
        if (unitOfMeasure) {
          this.unitOfMeasureClasses = [unitOfMeasure.unitOfMeasureClass];
          this.form.patchValue({ ...unitOfMeasure });

          this.unitOfMeasureName = this.currentLang === 'en' ? unitOfMeasure.nameEn : unitOfMeasure.name;

          /* Update page info. */
          this.pageInfo = {
            ...this.pageInfo,
            subTitle: `(${this.unitOfMeasureName})`,
          };

          this.form.markAsPristine();
        }
      })
    );

    /**
     * Load data.
     */
    this.isUpdating$ = this.storesStore$.pipe(select(fromStoresStore.getSelectedUnitOfMeasureUpdating));
  }

  /**
   * Initialize form and add validators.
   */
  initForm() {
    this.form = new FormGroup({
      name: new FormControl('', [Validators.required]),
      nameEn: new FormControl('', [Validators.required]),
      unitOfMeasureClassId: new FormControl(null, [Validators.required]),
    });
  }

  /**
   * Submits the form.
   */
  submit() {
    if (this.form.invalid) {
      const errorMessage = new NotificationMessage();

      if (this.form.get('name').invalid) {
        errorMessage.title = this.translationService.translate(
          'STORES.UNITS_OF_MEASURE.UNIT_OF_MEASURE_DATA_VALIDATION.NAME_ARABIC_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate('STORES.UNITS_OF_MEASURE.UNIT_OF_MEASURE_DATA_VALIDATION.NAME_IS_REQUIRED'),
        ];
      } else if (this.form.get('nameEn').invalid) {
        errorMessage.title = this.translationService.translate(
          'STORES.UNITS_OF_MEASURE.UNIT_OF_MEASURE_DATA_VALIDATION.NAME_ENGLISH_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate('STORES.UNITS_OF_MEASURE.UNIT_OF_MEASURE_DATA_VALIDATION.NAME_IS_REQUIRED'),
        ];
      } else if (this.form.get('unitOfMeasureClassId').invalid) {
        errorMessage.title = this.translationService.translate(
          'STORES.UNITS_OF_MEASURE.UNIT_OF_MEASURE_DATA_VALIDATION.UNIT_OF_MEASURE_CLASS_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate(
            'STORES.UNITS_OF_MEASURE.UNIT_OF_MEASURE_DATA_VALIDATION.UNIT_OF_MEASURE_CLASS_IS_REQUIRED'
          ),
        ];
      }
      this.form.markAllAsTouched();
      return this.notificationService.warningWithTitle(errorMessage);
    } else {
      this.openModal(this.confirmModalRef);
    }
  }

  /**
   * Confirms the form submit.
   */
  confirm() {
    this.storesStore$.dispatch(
      new fromStoresStore.UpdateUnitOfMeasure({
        id: this.unitOfMeasureId,
        ...this.form.value,
        unitOfMeasureClassId: this.form.value.unitOfMeasureClassId,
      })
    );
  }

  /**
   * Opens the modal of the given templateRef.
   * @param modalRef The modal templateRef to be opened.
   */
  openModal(modalRef) {
    this.modalService.open(modalRef);
  }

  /**
   * Selects the newly selected unit of measure class from the unit of measure class search list.
   * @param unitOfMeasureClass The list of newly selected unit of measure class to select the only one in the list.
   */
  selectUnitOfMeasureClass([unitOfMeasureClass]: UnitOfMeasureClass[]) {
    if (unitOfMeasureClass) {
      this.unitOfMeasureClasses = [unitOfMeasureClass];
      this.form.patchValue({ unitOfMeasureClassId: unitOfMeasureClass.id });
    }
  }
}
