<!-- reset user credentials form starts -->
<div class="row">
  <div class="col-md-12">
    <div class="card">
      <div class="card-content">
        <div class="px-3">
          <form [formGroup]="credentialsForm" *ngIf="user$ | async; let user" (ngSubmit)="submitCredentials()">
            <div class="row">
              <div class="form-group col-md-6">
                <label for="newUsername">{{ 'SECURITY.USERS.USER_DATA.USERNAME' | translate }}</label>
                <input
                  type="text"
                  id="newUsername"
                  class="form-control round"
                  [placeholder]="'SECURITY.USERS.USER_DATA.USERNAME' | translate"
                  maxlength="50"
                  autocomplete="off"
                  formControlName="newUsername"
                />
              </div>
              <div class="form-group col-md-6">
                <label for="newPassword">{{ 'SECURITY.USERS.USER_DATA.NEW_PASSWORD' | translate }}</label>
                <input
                  type="password"
                  id="newPassword"
                  class="form-control round"
                  [placeholder]="'SECURITY.USERS.USER_DATA.NEW_PASSWORD' | translate"
                  maxlength="50"
                  autocomplete="off"
                  formControlName="newPassword"
                />
              </div>
              <div class="form-group col-12 d-flex justify-content-between">
                <button type="submit" class="btn btn-raised btn-primary round">
                  <i class="fa fa-check"></i>
                  <span class="mx-1">{{ 'SHARED.CONFIRM.SAVE' | translate }}</span>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- reset user credentials form ends -->

<!-- credentials current logged in user password modal starts -->
<ng-template #credentialsConfirmModalRef let-c="close" let-d="dismiss">
  <form [formGroup]="currentLoggedInUserPasswordForm">
    <div class="modal-header">
      <h4 class="modal-title text-primary" id="modal-basic-title">
        {{ 'SECURITY.USERS.ADD_PASSWORD_DATA_TITLE' | translate }}
      </h4>
    </div>

    <div class="modal-body">
      <div class="row">
        <div class="form-group col-md-12">
          <label for="currentLoggedInUserPassword">{{ 'SECURITY.USERS.USER_DATA.USER_PASSWORD' | translate }}</label>
          <input
            type="password"
            id="currentLoggedInUserPassword"
            class="form-control round"
            [placeholder]="'SECURITY.USERS.USER_DATA.USER_PASSWORD_PLACEHOLDER' | translate"
            autocomplete="off"
            formControlName="currentLoggedInUserPassword"
          />
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <div class="modal-actions">
        <button
          type="button"
          class="btn btn-raised btn-primary round"
          (click)="confirmCredentials()"
          ngbAutofocus
          [disabled]="isUpdating$ | async"
        >
          <i class="fa fa-check"></i>
          <span class="mx-1">{{ 'SHARED.CONFIRM.SAVE' | translate }}</span>
        </button>
        <button type="button" class="btn btn-outline-dark round" (click)="c()">
          <i class="fa fa-times"></i>
          <span class="mx-1">{{ 'SHARED.CONFIRM.CANCEL' | translate }}</span>
        </button>
      </div>
    </div>
  </form>
</ng-template>
<!-- credentials current logged in user password modal ends -->
