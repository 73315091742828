import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Location as AngularLocation } from '@angular/common';
import { ActivatedRoute } from '@angular/router';

import { Observable, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import {
  PageInfo,
  NotificationService,
  NotificationMessage,
  TranslationService,
  CustomValidators,
  APP_CONSTANTS,
} from 'shared';
import * as fromSettingsStore from 'settings/store';
import { OrganizationSetting } from 'settings/models';
import { Claims } from 'security/models';
import { City, TimeZone, PrintPaperSize } from 'lookups/models';
import * as fromLookupsStore from 'lookups/store';

/**
 * The customer pages.
 */
enum PAGES {
  details = 'details',
  settings = 'settings',
}

@Component({
  selector: 'app-organization-settings',
  templateUrl: './organization-settings.component.html',
  styles: [],
})
export class OrganizationSettingsComponent implements OnInit, OnDestroy {
  /**
   * The confirm modal template reference.
   */
  @ViewChild('confirmModalRef') confirmModalRef: ElementRef<any>;

  /**
   * The confirm delete logo modal template reference.
   */
  @ViewChild('confirmDeleteLogoModalRef') confirmDeleteLogoModalRef: ElementRef<any>;

  /**
   * The confirm update logo modal template reference.
   */
  @ViewChild('confirmUpdateLogoModalRef') confirmUpdateLogoModalRef: ElementRef<any>;

  /**
   * The confirm delete image footer modal template reference.
   */
  @ViewChild('confirmDeleteInvoiceFooterImageModalRef') confirmDeleteInvoiceFooterImageModalRef: ElementRef<any>;

  /**
   * The confirm update image footer modal template reference.
   */
  @ViewChild('confirmUpdateImageFooterRef') confirmUpdateImageFooterModalRef: ElementRef<any>;

  /**
   * The confirm delete image header modal template reference.
   */
  @ViewChild('confirmDeleteInvoiceHeaderImageModalRef') confirmDeleteInvoiceHeaderImageModalRef: ElementRef<any>;

  /**
   * The confirm update image header modal template reference.
   */
  @ViewChild('confirmUpdateImageHeaderRef') confirmUpdateImageHeaderModalRef: ElementRef<any>;

  /**
   * The decimal mask.
   */
  readonly DECIMAL_MASK = APP_CONSTANTS.numeric.decimal.mask;

  /**
   * Gets or sets the information about the current page.
   */
  pageInfo: PageInfo = {
    title: 'SETTINGS.ORGANIZATION_SETTINGS.ORGANIZATION_DATA_TITLE',
    icon: 'fa fa-cogs',
  };

  /**
   * Gets or sets the selected page.
   * @default 'details'
   */
  activePage: PAGES = PAGES.details;

  /**
   * The system supported user claims.
   */
  Claims = Claims;

  /**
   * The list of cities.
   */
  cities$: Observable<City[]>;

  /**
   * The set of system supported time zones.
   */
  timeZones$: Observable<TimeZone[]>;

  /**
   * The set of system supported print paper sizes.
   */
  printPaperSizes$: Observable<PrintPaperSize[]>;

  /**
   * The loaded organization settings.
   */
  organizationSettings$: Observable<OrganizationSetting>;

  /**
   * Get or set header heights.
   */
  headerHeights = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  /**
   * Get or set footer heights.
   */
  footerHeights = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  /**
   * Indicates whether there is a update-organization-settings process is running or not.
   */
  isOrganizationSettingsUpdating$: Observable<boolean>;

  /**
   * The update organization form.
   */
  form: FormGroup;

  /**
   * The update organization report logo form.
   */
  updateLogoForm: FormGroup;

  /**
   * Gets or sets the id of the current edited organization setting.
   */
  organizationSettingId: number;

  /**
   * The update header form.
   */
  updateHeaderForm: FormGroup;

  /**
   * The update footer form.
   */
  updateFooterForm: FormGroup;

  /**
   * The set of subscriptions on this components,
   * these subscriptions must be unsubscribed before this component got destroyed.
   */
  subscriptions = new Subscription();

  /**
   *
   * @param modalService The modal service.
   * @param settingsStore$ Settings-module store.
   * @param notificationService$ the notification service.
   * @param translationService$ the translation service.
   * @param locationService$ the location service.
   * @param lookupsStore$ The lookup-store module.
   */
  constructor(
    private modalService: NgbModal,
    private settingsStore$: Store<fromSettingsStore.SettingsState>,
    private notificationService: NotificationService,
    private translationService: TranslationService,
    private locationService: AngularLocation,
    private route: ActivatedRoute,
    private lookupsStore$: Store<fromLookupsStore.LookupsState>
  ) {}

  ngOnInit(): void {
    this.init();
  }

  /**
   * Destroy component data
   */
  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  /**
   * Initialize component data.
   */
  init() {
    this.initForm();

    /**
     * Load data.
     */
    this.isOrganizationSettingsUpdating$ = this.settingsStore$.pipe(
      select(fromSettingsStore.getSelectedOrganizationSettingsUpdating)
    );

    let isManualSearchTriggeredBeforeForCities = false;
    this.cities$ = this.lookupsStore$.pipe(
      select(fromLookupsStore.getCities),
      tap((cities) => {
        if (!isManualSearchTriggeredBeforeForCities && !cities.length) {
          isManualSearchTriggeredBeforeForCities = true;
          this.lookupsStore$.dispatch(new fromLookupsStore.GetAllCities());
        }
      })
    );

    let isManualSearchTriggeredBeforeForOrganizationSettings = false;
    this.organizationSettings$ = this.settingsStore$.pipe(
      select(fromSettingsStore.getOrganizationsSettings),
      tap((organization) => {
        if (!isManualSearchTriggeredBeforeForOrganizationSettings && !organization) {
          isManualSearchTriggeredBeforeForOrganizationSettings = true;
          this.settingsStore$.dispatch(new fromSettingsStore.GetOrganizationSettings());
        } else {
          const workDayStartsAtHour = organization.workDayStartsAt.substring(0, 2);
          const workDayStartsAtMinute = organization.workDayStartsAt.substring(3, 5);
          const workDayEndsAtHour = organization.workDayEndsAt.substring(0, 2);
          const workDayEndsAtMinute = organization.workDayEndsAt.substring(3, 5);

          /** The initial `WorkDayStartsAtr` value. */
          const initialWorkDayStartsAt = {
            hour: parseFloat(workDayStartsAtHour),
            minute: parseFloat(workDayStartsAtMinute),
            second: 0,
          };

          /** The initial `WorkDayEndsAtr` value. */
          const initialWorkDayEndsAt = {
            hour: parseFloat(workDayEndsAtHour),
            minute: parseFloat(workDayEndsAtMinute),
            second: 59,
          };
          this.form.patchValue({
            ...organization,
            workDayStartsAt: initialWorkDayStartsAt,
            workDayEndsAt: initialWorkDayEndsAt,
          });
        }
      })
    );

    let isManualSearchTriggeredBeforeForTimeZones = false;
    this.timeZones$ = this.lookupsStore$.pipe(
      select(fromLookupsStore.getTimeZones),
      tap((timeZones) => {
        if (!isManualSearchTriggeredBeforeForTimeZones && !timeZones.length) {
          isManualSearchTriggeredBeforeForTimeZones = true;
          this.lookupsStore$.dispatch(new fromLookupsStore.GetAllTimeZone());
        }
      })
    );

    let isManualSearchTriggeredBeforeForPrintPaperSizes = false;
    this.printPaperSizes$ = this.lookupsStore$.pipe(
      select(fromLookupsStore.getPrintPaperSizes),
      tap((printPaperSizes) => {
        if (!isManualSearchTriggeredBeforeForPrintPaperSizes && !printPaperSizes.length) {
          isManualSearchTriggeredBeforeForPrintPaperSizes = true;
          this.lookupsStore$.dispatch(new fromLookupsStore.GetAllPrintPaperSize());
        }
      })
    );

    /** Select the user desired page. */
    this.activePage = PAGES[this.route.snapshot.fragment] ?? this.activePage;
    this.selectedPageChanged(this.activePage);
  }

  /**
   * Initialize form and add validators.
   */
  initForm() {
    const showInvoiceHeaderFormControl = new FormControl(false);
    const showInvoiceFooterFormControl = new FormControl(false);

    this.form = new FormGroup({
      reportDisplayName: new FormControl('', [Validators.required, Validators.minLength(1), Validators.maxLength(100)]),
      timeZoneId: new FormControl(null, Validators.required),
      workDayStartsAt: new FormControl(''),
      workDayEndsAt: new FormControl(''),
      showDisplayNameInReport: new FormControl(null),
      isPointsProgramActivated: new FormControl(false),
      pointsProgramGainRequiredValue: new FormControl(0, [Validators.required, CustomValidators.gte(0)]),
      pointsProgramGainPoints: new FormControl(0, [Validators.required, CustomValidators.gte(0)]),
      pointsProgramExchangePoints: new FormControl(0, [Validators.required, CustomValidators.gte(0)]),
      pointsProgramExchangeValue: new FormControl(0, [Validators.required, CustomValidators.gte(0)]),
      showLogoInReport: new FormControl(false),
      logoFilename: new FormControl(null),
      showInvoiceHeader: showInvoiceHeaderFormControl,
      showInvoiceFooter: showInvoiceFooterFormControl,
      headerHeight: new FormControl(null),
      footerHeight: new FormControl(null),
    });
    this.updateLogoForm = new FormGroup({
      logo: new FormControl(null),
    });

    this.updateHeaderForm = new FormGroup({
      invoiceHeaderFilename: new FormControl(null),
    });

    this.updateFooterForm = new FormGroup({
      invoiceFooterFilename: new FormControl(null),
    });

    /**
     * Subscription when show invoice header  form changes.
     */
    this.subscriptions.add(showInvoiceHeaderFormControl.valueChanges.subscribe(() => this.onInvoiceHeaderChange()));

    /**
     * Subscription when show invoice footer  form changes.
     */
    this.subscriptions.add(showInvoiceFooterFormControl.valueChanges.subscribe(() => this.onInvoiceFooterChange()));
  }

  /**
   * Submits the form.
   */
  submit() {
    const errorMessage = new NotificationMessage();

    if (this.form.invalid) {
      if (this.form.get('timeZoneId').invalid) {
        errorMessage.title = this.translationService.translate(
          'SETTINGS.ORGANIZATION_SETTINGS.ORGANIZATION_DATA_VALIDATION.TIME_ZONE_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate(
            'SETTINGS.ORGANIZATION_SETTINGS.ORGANIZATION_DATA_VALIDATION.TIME_ZONE_IS_REQUIRED'
          ),
        ];
      } else if (this.form.get('reportDisplayName').invalid) {
        errorMessage.title = this.translationService.translate(
          'SETTINGS.ORGANIZATION_SETTINGS.ORGANIZATION_DATA_VALIDATION.REPORT_ORGANIZATION_NAME_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate(
            'SETTINGS.ORGANIZATION_SETTINGS.ORGANIZATION_DATA_VALIDATION.REPORT_ORGANIZATION_NAME_LENGTH_ERROR'
          ),
        ];
      } else if (this.form.get('pointsProgramGainRequiredValue').invalid) {
        errorMessage.title = this.translationService.translate(
          'SETTINGS.ORGANIZATION_SETTINGS.ORGANIZATION_DATA_VALIDATION.POINTS_PROGRAM_GAIN_REQUIRED_VALUE_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate(
            'SETTINGS.ORGANIZATION_SETTINGS.ORGANIZATION_DATA_VALIDATION.POINTS_PROGRAM_GAIN_REQUIRED_VALUE_IS_REQUIRED'
          ),
        ];
      } else if (this.form.get('pointsProgramGainPoints').invalid) {
        errorMessage.title = this.translationService.translate(
          'SETTINGS.ORGANIZATION_SETTINGS.ORGANIZATION_DATA_VALIDATION.POINTS_PROGRAM_GAIN_POINTS_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate(
            'SETTINGS.ORGANIZATION_SETTINGS.ORGANIZATION_DATA_VALIDATION.POINTS_PROGRAM_GAIN_POINTS_IS_REQUIRED'
          ),
        ];
      } else if (this.form.get('pointsProgramExchangePoints').invalid) {
        errorMessage.title = this.translationService.translate(
          'SETTINGS.ORGANIZATION_SETTINGS.ORGANIZATION_DATA_VALIDATION.POINTS_PROGRAM_EXCHANGE_POINTS_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate(
            'SETTINGS.ORGANIZATION_SETTINGS.ORGANIZATION_DATA_VALIDATION.POINTS_PROGRAM_EXCHANGE_POINTS_IS_REQUIRED'
          ),
        ];
      } else if (this.form.get('pointsProgramExchangeValue').invalid) {
        errorMessage.title = this.translationService.translate(
          'SETTINGS.ORGANIZATION_SETTINGS.ORGANIZATION_DATA_VALIDATION.POINTS_PROGRAM_EXCHANGE_VALUE_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate(
            'SETTINGS.ORGANIZATION_SETTINGS.ORGANIZATION_DATA_VALIDATION.POINTS_PROGRAM_EXCHANGE_VALUE_IS_REQUIRED'
          ),
        ];
      } else if (this.form.get('showDisplayNameInReport').invalid) {
        errorMessage.title = this.translationService.translate(
          'SETTINGS.ORGANIZATION_SETTINGS.ORGANIZATION_DATA_VALIDATION.SHOW_ORGANIZATION_NAME_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate(
            'SETTINGS.ORGANIZATION_SETTINGS.ORGANIZATION_DATA_VALIDATION.SHOW_ORGANIZATION_NAME_LENGTH_ERROR'
          ),
        ];
      } else if (this.form.get('headerHeight').invalid) {
        errorMessage.title = this.translationService.translate(
          'SETTINGS.ORGANIZATION_SETTINGS.ORGANIZATION_DATA_VALIDATION.HEADER_HEIGHT_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate(
            'SETTINGS.ORGANIZATION_SETTINGS.ORGANIZATION_DATA_VALIDATION.HEADER_HEIGHT_IS_REQUIRED'
          ),
        ];
      } else if (this.form.get('footerHeight').invalid) {
        errorMessage.title = this.translationService.translate(
          'SETTINGS.ORGANIZATION_SETTINGS.ORGANIZATION_DATA_VALIDATION.FOOTER_HEIGHT_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate(
            'SETTINGS.ORGANIZATION_SETTINGS.ORGANIZATION_DATA_VALIDATION.FOOTER_HEIGHT_IS_REQUIRED'
          ),
        ];
      }

      this.form.markAllAsTouched();
      return this.notificationService.warningWithTitle(errorMessage);
    } else {
      this.openModal(this.confirmModalRef);
    }
  }

  /**
   * Dynamically set the header hight when select show invoice header.
   */
  onInvoiceHeaderChange() {
    const showInvoiceHeader = this.form.controls.showInvoiceHeader.value;
    if (showInvoiceHeader === true) {
      this.form.controls.headerHeight.setValidators([Validators.required]);
    } else {
      this.form.controls.headerHeight.setValue(null);
      this.form.controls.headerHeight.clearValidators();
    }
    this.form.controls.headerHeight.updateValueAndValidity();
  }

  /**
   * Dynamically set the footer hight when select show invoice header.
   */
  onInvoiceFooterChange() {
    const showInvoiceFooter = this.form.controls.showInvoiceFooter.value;
    if (showInvoiceFooter === true) {
      this.form.controls.footerHeight.setValidators([Validators.required]);
    } else {
      this.form.controls.footerHeight.setValue(null);
      this.form.controls.footerHeight.clearValidators();
    }
    this.form.controls.footerHeight.updateValueAndValidity();
  }

  /**
   * Confirms the form submit.
   */
  confirm() {
    /**
     * Extracting time from date.
     */
    const workDayStartsAt = new Date(
      0,
      0,
      0,
      this.form.get('workDayStartsAt').value.hour,
      this.form.get('workDayStartsAt').value.minute,
      this.form.get('workDayStartsAt').value.second
    ).toLocaleTimeString('it-IT');

    const workDayEndsAt = new Date(
      0,
      0,
      0,
      this.form.get('workDayEndsAt').value.hour,
      this.form.get('workDayEndsAt').value.minute,
      this.form.get('workDayEndsAt').value.second
    ).toLocaleTimeString('it-IT');

    this.settingsStore$.dispatch(
      new fromSettingsStore.UpdateOrganizationSettings({
        ...this.form.value,
        headerHeight: this.form.value.showInvoiceHeader ? this.form.value.headerHeight : null,
        footerHeight: this.form.value.showInvoiceFooter ? this.form.value.footerHeight : null,
        workDayStartsAt,
        workDayEndsAt,
      })
    );
  }

  /**
   * Opens the modal of the given templateRef.
   * @param modalRef The modal templateRef to be opened.
   */
  openModal(modalRef) {
    this.modalService.open(modalRef);
  }

  /**
   * Updates the browser url according to the selected page.
   * @param page The newly selected page.
   */
  selectedPageChanged(page: PAGES) {
    this.locationService.replaceState(`${this.locationService.path()}#${PAGES[page]}`);
  }

  /**
   * Confirms updates the current invoice image header.
   */
  updateImageHeaderConfirm() {
    this.settingsStore$.dispatch(
      new fromSettingsStore.UpdateImageHeader({
        invoiceHeaderFilename: this.updateHeaderForm.value.invoiceHeaderFilename,
      })
    );
    this.updateHeaderForm.reset();
  }

  /**
   * Confirms updates the current invoice footer image.
   */
  updateImageFooterConfirm() {
    this.settingsStore$.dispatch(
      new fromSettingsStore.UpdateImageFooter({
        invoiceFooterFilename: this.updateFooterForm.value.invoiceFooterFilename,
      })
    );
    this.updateFooterForm.reset();
  }

  /**
   * Confirms & deletes the current Header image.
   */
  deleteImageHeaderConfirm() {
    this.settingsStore$.dispatch(new fromSettingsStore.DeleteImageHeader());
  }

  /**
   * Confirms & deletes the current footer image.
   */
  deleteImageFooterConfirm() {
    this.settingsStore$.dispatch(new fromSettingsStore.DeleteImageFooter());
  }

  /**
   * Deletes the current organization logo.
   */
  deleteLogo(modal) {
    this.openModal(modal);
  }

  /**
   * Updates the current organization logo.
   */
  updateLogo(modal) {
    this.openModal(modal);
  }

  /**
   * Confirms & deletes the current organization logo.
   */
  deleteLogoConfirm() {
    this.settingsStore$.dispatch(new fromSettingsStore.DeleteOrganizationSettingsLogo());
  }

  /**
   * Confirms updates the current organization logo.
   */
  updateLogoConfirm() {
    this.settingsStore$.dispatch(new fromSettingsStore.UpdateOrganizationSettingsLogo(this.updateLogoForm.value.logo));
    this.updateLogoForm.reset();
  }
}
