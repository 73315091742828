import { CustomerEngagement } from 'sales/models';
import { PaginationInfo } from 'shared/models';

/**
 * Represents the customer engagements state.
 */
export class CustomerEngagementsState {
  /**
   * The list of customer engagements.
   */
  public data: CustomerEngagement[];

  /**
   * The pagination info for the customer engagements.
   */
  public paginationInfo: PaginationInfo;

  /**
   * An error that may resulting during processing some actions.
   */
  public error: any;

  /**
   * Determines if there is a running search process.
   */
  public isSearching: boolean;

  /**
   * Determines if the last search process has been ended successfully.
   */
  public isSearchCompleted: boolean;

  /**
   * The current selected customer engagements.
   */
  public selectedCustomerEngagement: CustomerEngagement;

  /**
   * Determines if there is a running find-customer-engagement process.
   */
  public isFinding: boolean;

  /**
   * Determines if the last find-customer-engagement process has been ended successfully.
   */
  public isFindCompleted: boolean;

  /**
   * Determines if there is a running create customer engagement process.
   */
  public isCreating: boolean;

  /**
   * Determines if the last create customer engagement process has been ended successfully.
   */
  public isCreateCompleted: boolean;

  /**
   * Determines if there is a running update customer engagement process.
   */
  public isUpdating: boolean;

  /**
   * Determines if the last update customer engagement process has been ended successfully.
   */
  public isUpdateCompleted: boolean;

  /**
   * Determines if there is a running customer engagement delete process.
   */
  public isDeleting: boolean;

  /**
   * Determines if the last delete customer engagement process has been ended successfully.
   */
  public isDeleteCompleted: boolean;

  /**
   * Determines if there is a running get customer engagement process.
   */
  public isGetting: boolean;

  /**
   * Determines if the last get customer engagement process has been ended successfully.
   */
  public isGetCompleted: boolean;
}
