import { Injectable } from '@angular/core';

import { of } from 'rxjs';
import { switchMap, map, catchError, tap, debounceTime } from 'rxjs/operators';
import { Effect, ofType, Actions } from '@ngrx/effects';

import { NotificationService, StatusCode, TranslationService } from 'shared';
import { UsersService } from 'security/services';
import {
  UsersActionType,
  SearchUsers,
  SearchUsersFail,
  SearchUsersSuccess,
  GetOrganizationUsers,
  GetOrganizationUsersFail,
  GetOrganizationUsersSuccess,
  CreateUser,
  CreateUserSuccess,
  CreateUserFail,
  UpdateUser,
  UpdateUserSuccess,
  UpdateUserFail,
  BlockUser,
  BlockUserSuccess,
  BlockUserFail,
  ActivateUser,
  ActivateUserSuccess,
  ActivateUserFail,
  FindUser,
  FindUserSuccess,
  FindUserFail,
  UpdateOrganizationUserClaims,
  UpdateOrganizationUserClaimsFail,
  UpdateOrganizationUserClaimsSuccess,
  UpdateUserLocation,
  UpdateUserLocationFail,
  UpdateUserLocationSuccess,
  UpdateUserBankAccount,
  UpdateUserBankAccountFail,
  UpdateUserBankAccountSuccess,
  BlockOrganizationUser,
  ActivateOrganizationUser,
  ResetUsersCredentials,
  ResetUsersCredentialsSuccess,
  ResetUsersCredentialsFail,
  CreateOrganizationUser,
  CreateOrganizationUserSuccess,
  CreateOrganizationUserFail,
  GetUserLocations,
  GetUserLocationsFail,
  GetUserLocationsSuccess,
  GetUserBankAccounts,
  GetUserBankAccountsFail,
  GetUserBankAccountsSuccess,
  GetUserCostCenters,
  GetUserCostCentersSuccess,
  GetUserCostCentersFail,
  UpdateUserCostCenter,
  UpdateUserCostCenterSuccess,
  UpdateUserCostCenterFail,
} from 'security/store/actions';

@Injectable()
export class UsersEffects {
  constructor(
    private actions$: Actions,
    private usersService: UsersService,
    private notificationService: NotificationService,
    private translationService: TranslationService
  ) {}

  /* ========================= SEARCH_USERS =================================== */
  @Effect()
  search$ = this.actions$.pipe(
    ofType(UsersActionType.SEARCH_USERS),
    debounceTime(300),
    switchMap((action: SearchUsers) =>
      this.usersService.search(action.payload?.name ?? '', action.payload.page).pipe(
        map((response) => new SearchUsersSuccess(response)),
        catchError((error) => of(new SearchUsersFail(error)))
      )
    )
  );

  /* ========================= GET_ORGANIZATION_USERS =================================== */
  @Effect()
  getOrganizationUsers$ = this.actions$.pipe(
    ofType(UsersActionType.GET_ORGANIZATION_USERS),
    switchMap((action: GetOrganizationUsers) =>
      this.usersService.getOrganizationUsers(action.payload?.name ?? '', action.payload.page).pipe(
        map((response) => new GetOrganizationUsersSuccess(response)),
        catchError((error) => of(new GetOrganizationUsersFail(error)))
      )
    )
  );

  /* ========================= FIND_USER =================================== */
  @Effect()
  find$ = this.actions$.pipe(
    ofType(UsersActionType.FIND_USER),
    switchMap((action: FindUser) =>
      this.usersService.findById(action.payload).pipe(
        map((response) => new FindUserSuccess(response)),
        catchError((error) => of(new FindUserFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  findFail$ = this.actions$.pipe(
    ofType(UsersActionType.FIND_USER_FAIL),
    tap((action: FindUserFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(this.translationService.translate('SECURITY.USERS.USER_NOT_FOUND'));
      }
    })
  );

  /* ========================= CREATE_USER =================================== */
  @Effect()
  create$ = this.actions$.pipe(
    ofType(UsersActionType.CREATE_USER),
    switchMap((action: CreateUser) =>
      this.usersService.create(action.payload).pipe(
        map((response) => new CreateUserSuccess(response)),
        catchError((error) => of(new CreateUserFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  createSuccess$ = this.actions$.pipe(
    ofType(UsersActionType.CREATE_USER_SUCCESS),
    tap((action: CreateUserSuccess) => {
      this.notificationService.success(this.translationService.translate('SECURITY.USERS.USER_ADDED'));
    })
  );

  @Effect({ dispatch: false })
  createFail$ = this.actions$.pipe(
    ofType(UsersActionType.CREATE_USER_FAIL),
    tap((action: CreateUserFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );

  /* ========================= UPDATE_USER =================================== */
  @Effect()
  update$ = this.actions$.pipe(
    ofType(UsersActionType.UPDATE_USER),
    switchMap((action: UpdateUser) =>
      this.usersService.update(action.payload).pipe(
        map((response) => new UpdateUserSuccess(response)),
        catchError((error) => of(new UpdateUserFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  updateSuccess$ = this.actions$.pipe(
    ofType(UsersActionType.UPDATE_USER_SUCCESS),
    tap((action: UpdateUserSuccess) => {
      this.notificationService.success(this.translationService.translate('SECURITY.USERS.USER_UPDATED'));
    })
  );

  @Effect({ dispatch: false })
  updateFail$ = this.actions$.pipe(
    ofType(UsersActionType.UPDATE_USER_FAIL),
    tap((action: UpdateUserFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(this.translationService.translate('SECURITY.USERS.USER_NOT_FOUND'));
      } else if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );

  /* ========================= BLOCK_USER =================================== */
  @Effect()
  block$ = this.actions$.pipe(
    ofType(UsersActionType.BLOCK_USER),
    switchMap((action: BlockUser) =>
      this.usersService.block(action.payload).pipe(
        map((response) => new BlockUserSuccess(response)),
        catchError((error) => of(new BlockUserFail(error)))
      )
    )
  );

  @Effect()
  blockOrganizationUser$ = this.actions$.pipe(
    ofType(UsersActionType.BLOCK_ORGANIZATION_USER),
    switchMap((action: BlockOrganizationUser) =>
      this.usersService.blockOrganizationUser(action.payload).pipe(
        map((response) => new BlockUserSuccess(response)),
        catchError((error) => of(new BlockUserFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  blockSuccess$ = this.actions$.pipe(
    ofType(UsersActionType.BLOCK_USER_SUCCESS),
    tap((action: BlockUserSuccess) => {
      this.notificationService.success(this.translationService.translate('SECURITY.USERS.USER_BLOCKED'));
    })
  );

  @Effect({ dispatch: false })
  blockFail$ = this.actions$.pipe(
    ofType(UsersActionType.BLOCK_USER_FAIL),
    tap((action: BlockUserFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(this.translationService.translate('SECURITY.USERS.USER_NOT_FOUND'));
      } else if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );

  /* ========================= ACTIVATE_USER =================================== */
  @Effect()
  activate$ = this.actions$.pipe(
    ofType(UsersActionType.ACTIVATE_USER),
    switchMap((action: ActivateUser) =>
      this.usersService.activate(action.payload).pipe(
        map((response) => new ActivateUserSuccess(response)),
        catchError((error) => of(new ActivateUserFail(error)))
      )
    )
  );

  @Effect()
  activateOrganizationUser$ = this.actions$.pipe(
    ofType(UsersActionType.ACTIVATE_ORGANIZATION_USER),
    switchMap((action: ActivateOrganizationUser) =>
      this.usersService.activateOrganizationUser(action.payload).pipe(
        map((response) => new ActivateUserSuccess(response)),
        catchError((error) => of(new ActivateUserFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  activateSuccess$ = this.actions$.pipe(
    ofType(UsersActionType.ACTIVATE_USER_SUCCESS),
    tap((action: ActivateUserSuccess) => {
      this.notificationService.success(this.translationService.translate('SECURITY.USERS.USER_ACTIVATED'));
    })
  );

  @Effect({ dispatch: false })
  activateFail$ = this.actions$.pipe(
    ofType(UsersActionType.ACTIVATE_USER_FAIL),
    tap((action: ActivateUserFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(this.translationService.translate('SECURITY.USERS.USER_NOT_FOUND'));
      } else if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );

  /* ========================= UPDATE_USER_CLAIMS =================================== */
  @Effect()
  updateUserClaim$ = this.actions$.pipe(
    ofType(UsersActionType.UPDATE_ORGANIZATION_USER_CLAIMS),
    switchMap((action: UpdateOrganizationUserClaims) =>
      this.usersService.updateOrganizationUserClaims(action.payload).pipe(
        map((response) => new UpdateOrganizationUserClaimsSuccess(response)),
        catchError((error) => of(new UpdateOrganizationUserClaimsFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  updateUserClaimsSuccess$ = this.actions$.pipe(
    ofType(UsersActionType.UPDATE_ORGANIZATION_USER_CLAIMS_SUCCESS),
    tap((action: UpdateOrganizationUserClaimsSuccess) => {
      this.notificationService.success(this.translationService.translate('SECURITY.USERS.USER_UPDATED'));
    })
  );

  @Effect({ dispatch: false })
  updateUserClaimsFail$ = this.actions$.pipe(
    ofType(UsersActionType.UPDATE_ORGANIZATION_USER_CLAIMS_FAIL),
    tap((action: UpdateOrganizationUserClaimsFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(this.translationService.translate('SECURITY.USERS.USER_NOT_FOUND'));
      } else if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );

  /* ========================= UPDATE_LOCATIONS =================================== */
  @Effect()
  updateUserLocation$ = this.actions$.pipe(
    ofType(UsersActionType.UPDATE_USER_LOCATIONS),
    switchMap((action: UpdateUserLocation) =>
      this.usersService.updateUserLocation(action.payload).pipe(
        map((response) => new UpdateUserLocationSuccess(response)),
        catchError((error) => of(new UpdateUserLocationFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  updateUserLocationSuccess$ = this.actions$.pipe(
    ofType(UsersActionType.UPDATE_USER_LOCATIONS_SUCCESS),
    tap((action: UpdateUserLocationSuccess) => {
      this.notificationService.success(this.translationService.translate('SECURITY.USERS.LOCATIONS_UPDATED'));
    })
  );

  @Effect({ dispatch: false })
  updateUserLocationFail$ = this.actions$.pipe(
    ofType(UsersActionType.UPDATE_USER_LOCATIONS_FAIL),
    tap((action: UpdateUserLocationFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(this.translationService.translate('SECURITY.USERS.USER_NOT_FOUND'));
      } else if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );

  /* ========================= UPDATE_BANK_ACCOUNTS =================================== */
  @Effect()
  updateUserBankAccount$ = this.actions$.pipe(
    ofType(UsersActionType.UPDATE_USER_BANK_ACCOUNTS),
    switchMap((action: UpdateUserBankAccount) =>
      this.usersService.updateUserBankAccount(action.payload).pipe(
        map((response) => new UpdateUserBankAccountSuccess(response)),
        catchError((error) => of(new UpdateUserBankAccountFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  updateUserBankAccountSuccess$ = this.actions$.pipe(
    ofType(UsersActionType.UPDATE_USER_BANK_ACCOUNTS_SUCCESS),
    tap((action: UpdateUserBankAccountSuccess) => {
      this.notificationService.success(this.translationService.translate('SECURITY.USERS.BANKS_ACCOUNTS_UPDATED'));
    })
  );

  @Effect({ dispatch: false })
  updateUserBankAccountFail$ = this.actions$.pipe(
    ofType(UsersActionType.UPDATE_USER_BANK_ACCOUNTS_FAIL),
    tap((action: UpdateUserBankAccountFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(this.translationService.translate('SECURITY.USERS.USER_NOT_FOUND'));
      } else if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );

  /* ========================= UPDATE_COST_CENTERS =================================== */
  @Effect()
  updateUserCostCenter$ = this.actions$.pipe(
    ofType(UsersActionType.UPDATE_USER_COST_CENTERS),
    switchMap((action: UpdateUserCostCenter) =>
      this.usersService.updateUserCostCenter(action.payload).pipe(
        map((response) => new UpdateUserCostCenterSuccess(response)),
        catchError((error) => of(new UpdateUserCostCenterFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  updateUserCostCenterSuccess$ = this.actions$.pipe(
    ofType(UsersActionType.UPDATE_USER_COST_CENTERS_SUCCESS),
    tap((action: UpdateUserCostCenterSuccess) => {
      this.notificationService.success(this.translationService.translate('SECURITY.USERS.COST_CENTERS_UPDATED'));
    })
  );

  @Effect({ dispatch: false })
  updateUserCostCenterFail$ = this.actions$.pipe(
    ofType(UsersActionType.UPDATE_USER_COST_CENTERS_FAIL),
    tap((action: UpdateUserCostCenterFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(this.translationService.translate('SECURITY.USERS.USER_NOT_FOUND'));
      } else if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );

  /* ========================= RESET_USERS_CREDENTIALS =================================== */
  @Effect()
  resetUsersCredentials$ = this.actions$.pipe(
    ofType(UsersActionType.RESET_USERS_CREDENTIALS),
    switchMap((action: ResetUsersCredentials) =>
      this.usersService.resetUsersCredentials(action.payload).pipe(
        map((response) => new ResetUsersCredentialsSuccess(response)),
        catchError((error) => of(new ResetUsersCredentialsFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  resetUsersCredentialsSuccess$ = this.actions$.pipe(
    ofType(UsersActionType.RESET_USERS_CREDENTIALS_SUCCESS),
    tap((action: ResetUsersCredentialsSuccess) => {
      this.notificationService.success(this.translationService.translate('AUTH.USER_PROFILE_CREDENTIALS_UPDATED'));
    })
  );

  @Effect({ dispatch: false })
  resetUsersCredentialsFail$ = this.actions$.pipe(
    ofType(UsersActionType.RESET_USERS_CREDENTIALS_FAIL),
    tap((action: ResetUsersCredentialsFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(this.translationService.translate('SECURITY.USERS.USER_NOT_FOUND'));
      } else if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );

  /* ========================= CREATE_ORGANIZATION_USER =================================== */
  @Effect()
  createOrganizationUser$ = this.actions$.pipe(
    ofType(UsersActionType.CREATE_ORGANIZATION_USER),
    switchMap((action: CreateOrganizationUser) =>
      this.usersService.createOrganizationUser(action.payload).pipe(
        map((response) => new CreateOrganizationUserSuccess(response)),
        catchError((error) => of(new CreateOrganizationUserFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  createOrganizationSuccess$ = this.actions$.pipe(
    ofType(UsersActionType.CREATE_ORGANIZATION_USER_SUCCESS),
    tap((action: CreateOrganizationUserSuccess) => {
      this.notificationService.success(this.translationService.translate('SECURITY.USERS.USER_ADDED'));
    })
  );

  @Effect({ dispatch: false })
  createOrganizationFail$ = this.actions$.pipe(
    ofType(UsersActionType.CREATE_ORGANIZATION_USER_FAIL),
    tap((action: CreateOrganizationUserFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );

  /* ========================= GET_USER_LOCATIONS =================================== */
  @Effect()
  getUserLocations$ = this.actions$.pipe(
    ofType(UsersActionType.GET_USER_LOCATIONS),
    switchMap((action: GetUserLocations) =>
      this.usersService.getUserLocations(action.payload).pipe(
        map((response) => new GetUserLocationsSuccess(response)),
        catchError((error) => of(new GetUserLocationsFail(error)))
      )
    )
  );

  /* ========================= GET_USER_BANK_ACCOUNTS =================================== */
  @Effect()
  getUserBankAccounts$ = this.actions$.pipe(
    ofType(UsersActionType.GET_USER_BANK_ACCOUNTS),
    switchMap((action: GetUserBankAccounts) =>
      this.usersService.getUserBankAccounts(action.payload).pipe(
        map((response) => new GetUserBankAccountsSuccess(response)),
        catchError((error) => of(new GetUserBankAccountsFail(error)))
      )
    )
  );

  /* ========================= GET_USER_COST_CENTERS =================================== */
  @Effect()
  getUserCostCenters$ = this.actions$.pipe(
    ofType(UsersActionType.GET_USER_COST_CENTERS),
    switchMap((action: GetUserCostCenters) =>
      this.usersService.getUserCostCenters(action.payload).pipe(
        map((response) => new GetUserCostCentersSuccess(response)),
        catchError((error) => of(new GetUserCostCentersFail(error)))
      )
    )
  );
}
