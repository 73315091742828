import { PettyCashSpecificationActionType, PettyCashSpecificationActions } from 'lookups/store/actions';
import { PettyCashSpecificationState } from 'lookups/store/states';

/**
 * The initial state from which the state starts.
 */
const initialState: PettyCashSpecificationState = {
  data: [],
  error: null,
  isLoaded: false,
  isLoading: false,
};

/**
 * The reducer function that is called in each action dispatch against the state.
 * @param state The current state.
 * @param action The action that will affect the state.
 */
export function pettyCashSpecificationReducer(
  state: PettyCashSpecificationState = initialState,
  action: PettyCashSpecificationActions
): PettyCashSpecificationState {
  switch (action.type) {
    case PettyCashSpecificationActionType.GET_ALL_PETTY_CASH_SPECIFICATION: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case PettyCashSpecificationActionType.GET_ALL_PETTY_CASH_SPECIFICATION_FAIL: {
      return {
        ...state,
        error: action.payload,
        isLoading: false,
        isLoaded: false,
      };
    }

    case PettyCashSpecificationActionType.GET_ALL_PETTY_CASH_SPECIFICATION_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        isLoading: false,
        isLoaded: true,
      };
    }

    default:
      return state;
  }
}
