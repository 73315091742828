import { Injectable } from '@angular/core';

import { of } from 'rxjs';
import { switchMap, map, catchError } from 'rxjs/operators';
import { Effect, ofType, Actions } from '@ngrx/effects';

import { LookupsService } from 'lookups/services';
import {
  GovServiceRequestPaymentMethodActionType,
  GetAllGovServiceRequestPaymentMethodsFail,
  GetAllGovServiceRequestPaymentMethodsSuccess,
} from 'lookups/store/actions';

@Injectable()
export class GovServiceRequestPaymentMethodEffects {
  constructor(private actions$: Actions, private lookupsService: LookupsService) {}

  /* ========================= GET_ALL_GOV_SERVICE_REQUEST_PAYMENT_METHODS =================================== */
  @Effect()
  getAllGovServiceRequestPaymentMethods$ = this.actions$.pipe(
    ofType(GovServiceRequestPaymentMethodActionType.GET_ALL_GOV_SERVICE_REQUEST_PAYMENT_METHODS),
    switchMap(() =>
      this.lookupsService.getAllGovServiceRequestPaymentMethods().pipe(
        map((response) => new GetAllGovServiceRequestPaymentMethodsSuccess(response)),
        catchError((error) => of(new GetAllGovServiceRequestPaymentMethodsFail(error)))
      )
    )
  );
}
