import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';

import { AppHttpResponse } from 'shared';
import { CancelShiftInput, CreateShiftInput, Shift } from 'sales/models';

/**
 * The shifts services includes functionality to search my shifts, search all shifts, findById and cancel a shift.
 */
@Injectable()
export class ShiftsService {
  /**
   * The relative route for the shifts.
   * No leading or trailing slashes required.
   */
  private shiftsApi = 'sales/shifts';
  constructor(private http: HttpClient) {}

  /**
   * Starts a new shift from the provided data.
   */
  public startMyShift(): Observable<AppHttpResponse<Shift>> {
    return this.http.post<AppHttpResponse<Shift>>(`${this.shiftsApi}/start`, null);
  }

  /**
   * Starts shift for casher a new shift from the provided data.
   * @param data The new shift data.
   */
  public startShiftForCasher(data: CreateShiftInput): Observable<AppHttpResponse<Shift>> {
    return this.http.post<AppHttpResponse<Shift>>(`${this.shiftsApi}/start-for-casher`, data);
  }
  /**
   * Searches in the user's shifts by date.
   * @param fromDate The fromDate of the user's shift.
   * @param toDate The toDate of the user's shift.
   * @param page The current pagination page number.
   * @param pageSize The maximum number of user's shifts allowed per one pagination page.
   */
  public searchMyShifts(
    fromDate: Date,
    toDate: Date,
    page: number,
    pageSize: number
  ): Observable<AppHttpResponse<Shift[]>> {
    const params = new HttpParams()
      .set('fromDate', fromDate?.toISOString())
      .set('toDate', toDate?.toISOString())
      .set('page', page.toString())
      .set('pageSize', pageSize.toString());
    return this.http.get<AppHttpResponse<Shift[]>>(`${this.shiftsApi}/my-shifts`, { params });
  }

  /**
   * Searches in the all shifts by date.
   * @param users The users of the shift.
   * @param locations The locations of the shift.
   * @param fromDate The fromDate of the shift.
   * @param toDate The toDate of the shift.
   * @param page The current pagination page number.
   * @param pageSize The maximum number of shifts allowed per one pagination page.
   */
  public searchAllShifts(
    users: number[],
    locations: number[],
    fromDate: Date,
    toDate: Date,
    page: number,
    pageSize: number
  ): Observable<AppHttpResponse<Shift[]>> {
    const params = new HttpParams()
      .set('users', users.join(','))
      .set('locations', locations.join(','))
      .set('fromDate', fromDate?.toISOString())
      .set('toDate', toDate?.toISOString())
      .set('page', page.toString())
      .set('pageSize', pageSize.toString());
    return this.http.get<AppHttpResponse<Shift[]>>(`${this.shiftsApi}`, { params });
  }

  /**
   * Finds the shift with the given id.
   * @param id The id of the shift.
   */
  public findMyShiftById(id: number): Observable<AppHttpResponse<Shift>> {
    return this.http.get<AppHttpResponse<Shift>>(`${this.shiftsApi}/${id}`);
  }

  /**
   * Cancels the user's shift by given id.
   * @param data The cancel shift data.
   */
  public cancelMyShift(data: CancelShiftInput): Observable<AppHttpResponse<Shift>> {
    return this.http.put<AppHttpResponse<Shift>>(`${this.shiftsApi}/end`, data);
  }

  /**
   * Cancels the user's shift by given id.
   * @param data The cancel shift data.
   */
  public cancelShift(data: CancelShiftInput): Observable<AppHttpResponse<Shift>> {
    return this.http.put<AppHttpResponse<Shift>>(`${this.shiftsApi}/end-for-casher`, data);
  }

  /**
   * Deletes the shift by given id.
   * @param id The id of the shift.
   */
  public delete(id: number): Observable<AppHttpResponse<Shift>> {
    return this.http.delete<AppHttpResponse<Shift>>(`${this.shiftsApi}/${id}`);
  }
}
