import { Component } from '@angular/core';

import { TranslationService } from 'shared';

@Component({
  selector: 'app-content-layout',
  templateUrl: './content-layout.component.html',
})
export class ContentLayoutComponent {
  /**
   * Gets or sets the current selected language.
   */
  get currentLang() {
    return this.translationService.language;
  }

  /**
   * Gets the ui direction according to the current user display language write direction.
   */
  get uiDirection(): string {
    return this.translationService.language === 'ar' ? 'rtl' : 'ltr';
  }

  /**
   * @param translationService The translation service.
   */
  constructor(private translationService: TranslationService) {}
}
