import { Report } from 'reports/models';

/**
 * Represents the stores reports state.
 */
export class StoresReportsState {
  /**
   * The loaded report.
   */
  public productsList: Report;

  /**
   * The loaded report.
   */
  public productMovement: Report;

  /**
   * The loaded report.
   */
  public incomingStock: Report;

  /**
   * The loaded report.
   */
  public outgoingStock: Report;

  /**
   * The loaded report.
   */
  public transferStock: Report;

  /**
   * The loaded report.
   */
  public stockInventory: Report;

  /**
   * The loaded report.
   */
  public stockMonitoring: Report;

  /**
   * The loaded report.
   */
  public unhandledSalesStock: Report;

  /**
   * An error that may resulting during processing some actions.
   */
  public error: any;

  /**
   * Determines if there is a running load process.
   */
  public isLoadingProductsList: boolean;

  /**
   * Determines if the last load process has been ended successfully.
   */
  public isProductsListLoadCompleted: boolean;

  /**
   * Determines if there is a running load process.
   */
  public isLoadingProductMovement: boolean;

  /**
   * Determines if the last load process has been ended successfully.
   */
  public isProductMovementLoadCompleted: boolean;

  /**
   * Determines if there is a running load process.
   */
  public isLoadingIncomingStock: boolean;

  /**
   * Determines if the last load process has been ended successfully.
   */
  public isIncomingStockLoadCompleted: boolean;

  /**
   * Determines if there is a running load process.
   */
  public isLoadingOutgoingStock: boolean;

  /**
   * Determines if the last load process has been ended successfully.
   */
  public isOutgoingStockLoadCompleted: boolean;

  /**
   * Determines if there is a running load process.
   */
  public isLoadingTransferStock: boolean;

  /**
   * Determines if the last load process has been ended successfully.
   */
  public isTransferStockLoadCompleted: boolean;

  /**
   * Determines if there is a running load process.
   */
  public isLoadingStockInventory: boolean;

  /**
   * Determines if the last load process has been ended successfully.
   */
  public isStockInventoryLoadCompleted: boolean;

  /**
   * Determines if there is a running load process.
   */
  public isLoadingStockMonitoring: boolean;

  /**
   * Determines if the last load process has been ended successfully.
   */
  public isStockMonitoringLoadCompleted: boolean;

  /**
   * Determines if there is a running load process.
   */
  public isLoadingUnhandledSalesStock: boolean;

  /**
   * Determines if the last load process has been ended successfully.
   */
  public isUnhandledSalesStockLoadCompleted: boolean;
}
