import { findIndex, remove } from 'lodash';

import { PosPrinterActionType, PosPrinterActions } from 'sales/store/actions';
import { PosPrintersState } from 'sales/store/states';

/**
 * The initial state from which the state starts.
 */
const initialState: PosPrintersState = {
  data: [],
  paginationInfo: {
    total: 0,
    page: 1,
    pageSize: 0,
    count: 0,
  },
  error: null,
  isSearching: false,
  isSearchCompleted: false,
  selectedPosPrinter: null,
  isFinding: false,
  isFindCompleted: false,
  isCreating: false,
  isCreateCompleted: false,
  isUpdating: false,
  isUpdateCompleted: false,
  isDeleting: false,
  isDeleteCompleted: false,
};

/**
 * The reducer function that is called in each action dispatch against the state.
 * @param state The current state.
 * @param action The action that will affect the state.
 */
export function PosPrintersReducer(
  state: PosPrintersState = initialState,
  action: PosPrinterActions
): PosPrintersState {
  switch (action.type) {
    //#region SEARCH_POS_PRINTER

    case PosPrinterActionType.SEARCH_POS_PRINTERS: {
      return {
        ...state,
        error: null,
        isSearching: true,
        isSearchCompleted: false,
      };
    }

    case PosPrinterActionType.SEARCH_POS_PRINTERS_FAIL: {
      return {
        ...state,
        error: action.payload,
        isSearching: false,
        isSearchCompleted: false,
      };
    }

    case PosPrinterActionType.SEARCH_POS_PRINTERS_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        paginationInfo: {
          ...action.payload.meta,
        },
        error: null,
        isSearching: false,
        isSearchCompleted: true,
      };
    }

    //#endregion SEARCH_POS_PRINTER

    //#region FIND_POS_PRINTER

    case PosPrinterActionType.FIND_POS_PRINTER: {
      return {
        ...state,
        selectedPosPrinter: null,
        error: null,
        isFinding: true,
        isFindCompleted: false,
      };
    }

    case PosPrinterActionType.FIND_POS_PRINTER_FAIL: {
      return {
        ...state,
        error: action.payload,
        isFinding: false,
        isFindCompleted: false,
      };
    }
    case PosPrinterActionType.FIND_POS_PRINTER_SUCCESS: {
      let posPrinters = [...state.data];
      const PosPrinterIndex = findIndex(posPrinters, (posPrinter) => posPrinter.id === action.payload.data.id);

      /* If pos printer was found, update it. */
      if (PosPrinterIndex >= 0) {
        posPrinters[PosPrinterIndex] = action.payload.data;
      } else {
        /* else, insert it to the beginning of the array. */
        posPrinters = [action.payload.data, ...posPrinters];
      }

      return {
        ...state,
        data: posPrinters,
        selectedPosPrinter: action.payload.data,
        error: null,
        isFinding: false,
        isFindCompleted: true,
      };
    }

    //#endregion FIND_POS_PRINTER

    //#region CREATE_POS_PRINTER

    case PosPrinterActionType.CREATE_POS_PRINTER: {
      return {
        ...state,
        error: null,
        isCreating: true,
        isCreateCompleted: false,
      };
    }

    case PosPrinterActionType.CREATE_POS_PRINTER_FAIL: {
      return {
        ...state,
        error: action.payload,
        isCreating: false,
        isCreateCompleted: false,
      };
    }

    case PosPrinterActionType.CREATE_POS_PRINTER_SUCCESS: {
      return {
        ...state,
        data: [action.payload.data, ...state.data],
        selectedPosPrinter: action.payload.data,
        error: null,
        isCreating: false,
        isCreateCompleted: true,
      };
    }

    //#endregion CREATE_POS_PRINTER

    //#region UPDATE_POS_PRINTER

    case PosPrinterActionType.UPDATE_POS_PRINTER: {
      return {
        ...state,
        error: null,
        isUpdating: true,
        isUpdateCompleted: false,
      };
    }

    case PosPrinterActionType.UPDATE_POS_PRINTER_FAIL: {
      return {
        ...state,
        error: action.payload,
        isUpdating: false,
        isUpdateCompleted: false,
      };
    }

    case PosPrinterActionType.UPDATE_POS_PRINTER_SUCCESS: {
      let posPrinters = [...state.data];
      const posPrinterIndex = findIndex(posPrinters, (posPrinter) => posPrinter.id === action.payload.data.id);

      /* If pos printer was found, update it. */
      if (posPrinterIndex >= 0) {
        posPrinters[posPrinterIndex] = action.payload.data;
      } else {
        /* else, insert it to the beginning of the array. */
        posPrinters = [action.payload.data, ...posPrinters];
      }

      return {
        ...state,
        data: posPrinters,
        selectedPosPrinter: action.payload.data,
        error: null,
        isUpdating: false,
        isUpdateCompleted: true,
      };
    }

    //#endregion UPDATE_POS_PRINTER

    //#region DELETE_POS_PRINTER

    case PosPrinterActionType.DELETE_POS_PRINTER: {
      return {
        ...state,
        error: null,
        isDeleting: true,
        isDeleteCompleted: false,
      };
    }

    case PosPrinterActionType.DELETE_POS_PRINTER_FAIL: {
      return {
        ...state,
        error: action.payload,
        isDeleting: false,
        isDeleteCompleted: false,
      };
    }

    case PosPrinterActionType.DELETE_POS_PRINTER_SUCCESS: {
      const posPrinters = [...state.data];

      /* Remove the deleted pos printer from the array. */
      remove(posPrinters, (posPrinter) => posPrinter.id === action.payload.data.id);

      return {
        ...state,
        data: posPrinters,
        selectedPosPrinter: action.payload.data,
        error: null,
        isDeleting: false,
        isDeleteCompleted: true,
      };
    }

    //#endregion DELETE_POS_PRINTER

    default:
      return state;
  }
}
