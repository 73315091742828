import { Injectable } from '@angular/core';

import { Effect, ofType, Actions } from '@ngrx/effects';
import { switchMap, map, catchError, tap, debounceTime } from 'rxjs/operators';
import { of } from 'rxjs';

import { NotificationService, StatusCode, PAGINATION, TranslationService } from 'shared';
import { CustomerEngagementsService } from 'sales/services';
import {
  CustomerEngagementsActionType,
  CreateCustomerEngagement,
  CreateCustomerEngagementSuccess,
  CreateCustomerEngagementFail,
  DeleteCustomerEngagement,
  DeleteCustomerEngagementSuccess,
  DeleteCustomerEngagementFail,
  FindCustomerEngagement,
  FindCustomerEngagementSuccess,
  FindCustomerEngagementFail,
  UpdateCustomerEngagement,
  UpdateCustomerEngagementFail,
  UpdateCustomerEngagementSuccess,
  SearchCustomerEngagements,
  SearchCustomerEngagementsSuccess,
  SearchCustomerEngagementsFail,
} from 'sales/store/actions';

@Injectable()
export class CustomerEngagementsEffects {
  constructor(
    private actions$: Actions,
    private customerEngagementsService: CustomerEngagementsService,
    private notificationService: NotificationService,
    private translationService: TranslationService
  ) {}

  /* ========================= SEARCH_CUSTOMER_ENGAGEMENTS =================================== */
  @Effect()
  search$ = this.actions$.pipe(
    ofType(CustomerEngagementsActionType.SEARCH_CUSTOMER_ENGAGEMENTS),
    debounceTime(300),
    switchMap(({ payload }: SearchCustomerEngagements) => {
      return this.customerEngagementsService
        .search(payload.customers, payload.customerEngagementTypes, payload.page, PAGINATION.CustomerEngagements)
        .pipe(
          map((response) => new SearchCustomerEngagementsSuccess(response)),
          catchError((error) => of(new SearchCustomerEngagementsFail(error)))
        );
    })
  );

  /* ========================= FIND_CUSTOMER_ENGAGEMENT =================================== */
  @Effect()
  find$ = this.actions$.pipe(
    ofType(CustomerEngagementsActionType.FIND_CUSTOMER_ENGAGEMENT),
    switchMap((action: FindCustomerEngagement) =>
      this.customerEngagementsService.findById(action.payload).pipe(
        map((response) => new FindCustomerEngagementSuccess(response)),
        catchError((error) => of(new FindCustomerEngagementFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  findFail$ = this.actions$.pipe(
    ofType(CustomerEngagementsActionType.FIND_CUSTOMER_ENGAGEMENT_FAIL),
    tap((action: FindCustomerEngagementFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate('SALES.CUSTOMER_ENGAGEMENTS.CUSTOMER_ENGAGEMENT_NOT_FOUND')
        );
      }
    })
  );

  /* ========================= CREATE_CUSTOMER_ENGAGEMENT =================================== */
  @Effect()
  create$ = this.actions$.pipe(
    ofType(CustomerEngagementsActionType.CREATE_CUSTOMER_ENGAGEMENT),
    switchMap((action: CreateCustomerEngagement) =>
      this.customerEngagementsService.create(action.payload).pipe(
        map((response) => new CreateCustomerEngagementSuccess(response)),
        catchError((error) => of(new CreateCustomerEngagementFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  createSuccess$ = this.actions$.pipe(
    ofType(CustomerEngagementsActionType.CREATE_CUSTOMER_ENGAGEMENT_SUCCESS),
    tap((action: CreateCustomerEngagementSuccess) => {
      this.notificationService.success(
        this.translationService.translate('SALES.CUSTOMER_ENGAGEMENTS.CUSTOMER_ENGAGEMENT_ADDED')
      );
    })
  );

  @Effect({ dispatch: false })
  createFail$ = this.actions$.pipe(
    ofType(CustomerEngagementsActionType.CREATE_CUSTOMER_ENGAGEMENT_FAIL),
    tap((action: CreateCustomerEngagementFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );

  /* ========================= DELETE_CUSTOMER_ENGAGEMENT =================================== */
  @Effect()
  delete$ = this.actions$.pipe(
    ofType(CustomerEngagementsActionType.DELETE_CUSTOMER_ENGAGEMENT),
    switchMap((action: DeleteCustomerEngagement) =>
      this.customerEngagementsService.delete(action.payload).pipe(
        map((response) => new DeleteCustomerEngagementSuccess(response)),
        catchError((error) => of(new DeleteCustomerEngagementFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  deleteSuccess$ = this.actions$.pipe(
    ofType(CustomerEngagementsActionType.DELETE_CUSTOMER_ENGAGEMENT_SUCCESS),
    tap((action: DeleteCustomerEngagementSuccess) => {
      this.notificationService.success(
        this.translationService.translate('SALES.CUSTOMER_ENGAGEMENTS.CUSTOMER_ENGAGEMENT_DELETED')
      );
    })
  );

  @Effect({ dispatch: false })
  deleteFail$ = this.actions$.pipe(
    ofType(CustomerEngagementsActionType.DELETE_CUSTOMER_ENGAGEMENT_FAIL),
    tap((action: DeleteCustomerEngagementFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate('SALES.CUSTOMER_ENGAGEMENTS.CUSTOMER_ENGAGEMENT_NOT_FOUND')
        );
      } else if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );

  /* ========================= UPDATE_CUSTOMER_ENGAGEMENT =================================== */
  @Effect()
  update$ = this.actions$.pipe(
    ofType(CustomerEngagementsActionType.UPDATE_CUSTOMER_ENGAGEMENT),
    switchMap((action: UpdateCustomerEngagement) =>
      this.customerEngagementsService.update(action.payload).pipe(
        map((response) => new UpdateCustomerEngagementSuccess(response)),
        catchError((error) => of(new UpdateCustomerEngagementFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  updateSuccess$ = this.actions$.pipe(
    ofType(CustomerEngagementsActionType.UPDATE_CUSTOMER_ENGAGEMENT_SUCCESS),
    tap((action: UpdateCustomerEngagementSuccess) => {
      this.notificationService.success(
        this.translationService.translate('SALES.CUSTOMER_ENGAGEMENTS.CUSTOMER_ENGAGEMENT_UPDATED')
      );
    })
  );

  @Effect({ dispatch: false })
  updateFail$ = this.actions$.pipe(
    ofType(CustomerEngagementsActionType.UPDATE_CUSTOMER_ENGAGEMENT_FAIL),
    tap((action: UpdateCustomerEngagementFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(
          this.translationService.translate('SALES.CUSTOMER_ENGAGEMENTS.CUSTOMER_ENGAGEMENT_NOT_FOUND')
        );
      } else if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );
}
