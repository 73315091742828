import { createSelector } from '@ngrx/store';

import { selectFinancesState } from 'finances/store/selectors/feature.selectors';
import { FinancesState, HrState } from '../states';

/**
 * Gets the cost centers state.
 */
const selectHrState = createSelector(selectFinancesState, (state: FinancesState) => state.hr);

/**
 * Gets the list of loaded employee loans.
 */
export const getEmployeeLoans = createSelector(selectHrState, (state: HrState) => state.employeeLoansData);

/**
 * Gets the list of loaded employee loan settlements.
 */
export const getEmployeeLoanSettlements = createSelector(
  selectHrState,
  (state: HrState) => state.employeeLoanSettlementsData
);

/**
 * Gets the list of loaded payrolls.
 */
export const getPayrolls = createSelector(selectHrState, (state: HrState) => state.payrollsData);

/**
 * Gets the list of paid payrolls.
 */
export const getPaidPayrolls = createSelector(selectHrState, (state: HrState) => state.paidPayrollsData);

/**
 * Gets the list of prepared paid payrolls.
 */
export const getPreparedPaidPayrolls = createSelector(
  selectHrState,
  (state: HrState) => state.preparedPaidPayrollsData
);

/**
 * Gets the list of payroll payments.
 */
export const getPayrollPayments = createSelector(selectHrState, (state: HrState) => state.payrollPaymentsData);

/**
 * Gets the list of payroll payment refunds.
 */
export const getPayrollPaymentRefunds = createSelector(
  selectHrState,
  (state: HrState) => state.payrollPaymentRefundsData
);

/**
 * Gets selected payroll payment.
 */
export const getSelectedPayrollPayment = createSelector(
  selectHrState,
  (state: HrState) => state.selectedPayrollPayment
);

/**
 * Gets selected payroll payment refund.
 */
export const getSelectedPayrollPaymentRefund = createSelector(
  selectHrState,
  (state: HrState) => state.selectedPayrollPaymentRefund
);

/**
 * Gets the list of loaded gov service requests.
 */
export const getGovServiceRequests = createSelector(selectHrState, (state: HrState) => state.govServiceRequestsData);

/**
 * Gets the employee loans pagination info.
 */
export const getEmployeeLoansPaginationInfo = createSelector(
  selectHrState,
  (state: HrState) => state.employeeLoansPaginationInfo
);

/**
 * Gets the employee loan settlements pagination info.
 */
export const getEmployeeLoanSettlementsPaginationInfo = createSelector(
  selectHrState,
  (state: HrState) => state.employeeLoanSettlementsPaginationInfo
);

/**
 * Gets the payrolls pagination info.
 */
export const getPayrollsPaginationInfo = createSelector(
  selectHrState,
  (state: HrState) => state.payrollsPaginationInfo
);

/**
 * Gets the paid payrolls pagination info.
 */
export const getPaidPayrollsPaginationInfo = createSelector(
  selectHrState,
  (state: HrState) => state.paidPayrollsPaginationInfo
);

/**
 * Gets the payroll payments pagination info.
 */
export const getPayrollPaymentsPaginationInfo = createSelector(
  selectHrState,
  (state: HrState) => state.payrollPaymentsPaginationInfo
);

/**
 * Gets the payroll payment refunds pagination info.
 */
export const getPayrollPaymentRefundsPaginationInfo = createSelector(
  selectHrState,
  (state: HrState) => state.payrollPaymentRefundsPaginationInfo
);

/**
 * Gets the gov service requests pagination info.
 */
export const getGovServiceRequestsPaginationInfo = createSelector(
  selectHrState,
  (state: HrState) => state.govServiceRequestsPaginationInfo
);

/**
 * Determines if there is a running employee loans search process.
 */
export const getEmployeeLoansSearching = createSelector(
  selectHrState,
  (state: HrState) => state.isEmployeeLoansSearching
);

/**
 * Determines if the last employee loans search process has been ended successfully.
 */
export const getEmployeeLoansSearchCompleted = createSelector(
  selectHrState,
  (state: HrState) => state.isEmployeeLoansSearchCompleted
);

/**
 * Determines if there is a running employee loan settlements search process.
 */
export const getEmployeeLoanSettlementsSearching = createSelector(
  selectHrState,
  (state: HrState) => state.isEmployeeLoanSettlementsSearching
);

/**
 * Determines if the last employee loan settlements search process has been ended successfully.
 */
export const getEmployeeLoanSettlementsSearchCompleted = createSelector(
  selectHrState,
  (state: HrState) => state.isEmployeeLoanSettlementsSearchCompleted
);

/**
 * Determines if there is a running payrolls search process.
 */
export const getPayrollsSearching = createSelector(selectHrState, (state: HrState) => state.isPayrollsSearching);

/**
 * Determines if the last payrolls search process has been ended successfully.
 */
export const getPayrollsSearchCompleted = createSelector(
  selectHrState,
  (state: HrState) => state.isPayrollsSearchCompleted
);

/**
 * Determines if there is a running paid payrolls search process.
 */
export const getPaidPayrollsSearching = createSelector(
  selectHrState,
  (state: HrState) => state.isPaidPayrollsSearching
);

/**
 * Determines if the last paid payrolls search process has been ended successfully.
 */
export const getPaidPayrollsSearchCompleted = createSelector(
  selectHrState,
  (state: HrState) => state.isPaidPayrollsSearchCompleted
);

/**
 * Determines if there is a running payroll payments search process.
 */
export const getPayrollPaymentsSearching = createSelector(
  selectHrState,
  (state: HrState) => state.isPayrollPaymentsSearching
);

/**
 * Determines if the last payroll payments search process has been ended successfully.
 */
export const getPayrollPaymentsSearchCompleted = createSelector(
  selectHrState,
  (state: HrState) => state.isPayrollPaymentsSearchCompleted
);

/**
 * Determines if there is a running payroll payment refunds search process.
 */
export const getPayrollPaymentRefundsSearching = createSelector(
  selectHrState,
  (state: HrState) => state.isPayrollPaymentRefundsSearching
);

/**
 * Determines if the last payroll payment refunds search process has been ended successfully.
 */
export const getPayrollPaymentRefundsSearchCompleted = createSelector(
  selectHrState,
  (state: HrState) => state.isPayrollPaymentRefundsSearchCompleted
);

/**
 * Determines if there is a running gov service requests search process.
 */
export const getGovServiceRequestsSearching = createSelector(
  selectHrState,
  (state: HrState) => state.isGovServiceRequestsSearching
);

/**
 * Determines if the last gov service requests search process has been ended successfully.
 */
export const getGovServiceRequestsSearchCompleted = createSelector(
  selectHrState,
  (state: HrState) => state.isGovServiceRequestsSearchCompleted
);

/**
 * Determines if there is a running create employee loan process.
 */
export const getSelectedEmployeeLoanCreating = createSelector(
  selectHrState,
  (state: HrState) => state.isEmployeeLoanCreating
);

/**
 * Determines if the last create employee loan process has been ended successfully.
 */
export const getSelectedEmployeeLoanCreateCompleted = createSelector(
  selectHrState,
  (state: HrState) => state.isEmployeeLoanCreateCompleted
);

/**
 * Determines if there is a running pay payroll process.
 */
export const getSelectedPayrollPaying = createSelector(selectHrState, (state: HrState) => state.isPayingPayroll);

/**
 * Determines if the last pay payroll process has been ended successfully.
 */
export const getSelectedPayPayrollCompleted = createSelector(
  selectHrState,
  (state: HrState) => state.isPayPayrollCompleted
);

/**
 * Determines if there is a running create payroll payment process.
 */
export const getSelectedPayrollPaymentCreating = createSelector(
  selectHrState,
  (state: HrState) => state.isPayrollPaymentCreating
);

/**
 * Determines if the last create payroll payment process has been ended successfully.
 */
export const getSelectedPayrollPaymentCreateCompleted = createSelector(
  selectHrState,
  (state: HrState) => state.isPayrollPaymentCreateCompleted
);

/**
 * Determines if there is a running create payroll payment refund process.
 */
export const getSelectedPayrollPaymentRefundCreating = createSelector(
  selectHrState,
  (state: HrState) => state.isPayrollPaymentRefundCreating
);

/**
 * Determines if the last create payroll payment refund process has been ended successfully.
 */
export const getSelectedPayrollPaymentRefundCreateCompleted = createSelector(
  selectHrState,
  (state: HrState) => state.isPayrollPaymentRefundCreateCompleted
);

/**
 * Determines if there is a running update employee loan process.
 */
export const getSelectedEmployeeLoanUpdating = createSelector(
  selectHrState,
  (state: HrState) => state.isEmployeeLoanUpdating
);

/**
 * Determines if the last update employee loan process has been ended successfully.
 */
export const getSelectedEmployeeLoanUpdateCompleted = createSelector(
  selectHrState,
  (state: HrState) => state.isEmployeeLoanUpdateCompleted
);

/**
 * Determines if there is a running delete payroll process.
 */
export const getSelectedPayrollDeleting = createSelector(selectHrState, (state: HrState) => state.isPayrollDeleting);

/**
 * Determines if the last delete payroll process has been ended successfully.
 */
export const getSelectedPayrollDeleteCompleted = createSelector(
  selectHrState,
  (state: HrState) => state.isPayrollDeleteCompleted
);

/**
 * Determines if there is a running find payroll payment process.
 */
export const getPayrollPaymentFinding = createSelector(
  selectHrState,
  (state: HrState) => state.isPayrollPaymentFinding
);

/**
 * Determines if the last find payroll payment process has been ended successfully.
 */
export const getPayrollPaymentFindCompleted = createSelector(
  selectHrState,
  (state: HrState) => state.isPayrollPaymentFindCompleted
);

/**
 * Determines if there is a running find payroll payment refund process.
 */
export const getPayrollPaymentRefundFinding = createSelector(
  selectHrState,
  (state: HrState) => state.isPayrollPaymentRefundFinding
);

/**
 * Determines if the last find payroll payment refund process has been ended successfully.
 */
export const getPayrollPaymentRefundFindCompleted = createSelector(
  selectHrState,
  (state: HrState) => state.isPayrollPaymentRefundFindCompleted
);

/**
 * Determines if there is a running find gov service request process.
 */
export const getGovServiceRequestFinding = createSelector(
  selectHrState,
  (state: HrState) => state.isGovServiceRequestFinding
);

/**
 * Determines if the last find gov service request process has been ended successfully.
 */
export const getGovServiceRequestFindCompleted = createSelector(
  selectHrState,
  (state: HrState) => state.isGovServiceRequestFindCompleted
);

/**
 * Gets the selected gov service request.
 */
export const getSelectedGovServiceRequest = createSelector(
  selectHrState,
  (state: HrState) => state.selectedGovServiceRequest
);

/**
 * Determines if there is a running create pay gov service request process.
 */
export const getSelectedPayGovServiceRequestCreating = createSelector(
  selectHrState,
  (state: HrState) => state.isPayGovServiceRequestCreating
);

/**
 * Determines if the last create pay gov service request process has been ended successfully.
 */
export const getSelectedPayGovServiceRequestCreateCompleted = createSelector(
  selectHrState,
  (state: HrState) => state.isPayGovServiceRequestCreateCompleted
);
