<!-- product sales discounts list starts -->
<div class="row">
  <div class="col-md-12">
    <div class="card-content">
      <div class="px-3">
        <div class="row">
          <div class="col-md-12">
            <form class="row">
              <div class="form-group col-md-auto mb-0">
                <a class="btn btn-raised btn-success round mx-1" (click)="update()">
                  <i class="fa fa-plus"></i>
                  <span class="mx-1">{{ 'SHARED.CONFIRM.ADD' | translate }}</span>
                </a>
              </div>
            </form>
          </div>
          <div class="table-responsive">
            <table class="table text-center" *ngIf="productSalesDiscounts?.length; else noItems">
              <thead>
                <tr>
                  <th scope="col"><i class="fa fa-list-ol"></i></th>
                  <th scope="col">
                    {{ 'STORES.PRODUCTS.PRODUCT_DATA.LOCATION_INPUT' | translate }}
                  </th>
                  <th scope="col">
                    {{ 'STORES.PRODUCTS.PRODUCT_DATA.DISCOUNT' | translate }}
                  </th>
                  <th scope="col">
                    {{ 'STORES.PRODUCTS.PRODUCT_DATA.DISCOUNT_PERCENT' | translate }}
                  </th>
                  <th scope="col">{{ 'SHARED.TOOLS' | translate }}</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let productSalesDiscount of productSalesDiscounts; let i = index">
                  <th scope="row">{{ i + 1 }}</th>
                  <td scope="col">
                    {{ productSalesDiscount.location?.name | localize: productSalesDiscount.location?.nameEn | async }}
                  </td>
                  <td scope="col">
                    {{ productSalesDiscount.discount | toDecimal }}
                  </td>
                  <td scope="col">{{ productSalesDiscount.discountPercent | toDecimal }} %</td>
                  <td scope="col">
                    <a
                      class="danger p-0"
                      [ngbTooltip]="'SHARED.CONFIRM.DELETE' | translate"
                      (click)="deleteProductSalesDiscount(productSalesDiscount?.id)"
                    >
                      <i class="fa fa-close font-medium-3 mr-2"></i>
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- product sales discounts list -->

<!-- no items template starts -->
<ng-template #noItems>
  <div class="col-md-12 alert alert-warning mt-3 text-center" role="alert">
    {{ 'STORES.PRODUCTS.PRODUCT_DATA.PRODUCT_SALES_DISCOUNTS_NOT_FOUND' | translate }}
    <button type="button" class="btn btn-raised btn-success round" (click)="update()">
      <i class="fa fa-plus"></i>
      <span class="mx-1">{{ 'SHARED.CONFIRM.ADD' | translate }}</span>
    </button>
  </div>
</ng-template>
<!-- no items template ends -->

<!-- product sales discounts ends -->

<!-- update product sales discounts modal starts -->
<ng-template #updateModalRef let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
      {{ 'STORES.PRODUCTS.PRODUCT_DATA.ADD_PRODUCT_SALES_DISCOUNT' | translate }}
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="d()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-md-12">
        <form [formGroup]="updateForm" (ngSubmit)="submit()">
          <div class="px-3">
            <div class="row">
              <div class="form-group col-md-12">
                <label for="countryId">{{ 'STORES.PRODUCTS.PRODUCT_DATA.LOCATION_INPUT' | translate }}</label>
                <ng-select
                  class="round"
                  formControlName="locationId"
                  labelForId="locationId"
                  searchable="true"
                  [placeholder]="'STORES.PRODUCTS.PRODUCT_DATA.LOCATION_INPUT_PLACEHOLDER' | translate"
                  [notFoundText]="'SHARED.SEARCH.SEARCH_NOT_FOUND' | translate"
                  (open)="locationsListVisibility = true"
                >
                  <ng-option *ngFor="let location of locations" [value]="location.id">
                    {{ location.name | localize: location.nameEn | async }}
                  </ng-option>
                </ng-select>
              </div>
              <div class="form-group col-md-12">
                <label for="discount">{{ 'STORES.PRODUCTS.PRODUCT_DATA.DISCOUNT' | translate }}</label>
                <input
                  type="text"
                  [mask]="DECIMAL_MASK"
                  thousandSeparator=","
                  minLength="0"
                  id="discount"
                  class="form-control round"
                  [placeholder]="'STORES.PRODUCTS.PRODUCT_DATA.DISCOUNT_PLACEHOLDER' | translate"
                  formControlName="discount"
                />
              </div>
              <div class="form-group col-md-12">
                <label for="discountPercent">{{ 'STORES.PRODUCTS.PRODUCT_DATA.DISCOUNT_PERCENT' | translate }}</label>
                <input
                  type="text"
                  [mask]="DECIMAL_MASK"
                  thousandSeparator=","
                  minLength="0"
                  id="discountPercent"
                  class="form-control round"
                  [placeholder]="'STORES.PRODUCTS.PRODUCT_DATA.DISCOUNT_PERCENT_PLACEHOLDER' | translate"
                  formControlName="discountPercent"
                />
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="modal-actions">
      <button
        type="button"
        class="btn btn-raised btn-success round"
        [disabled]="isUpdating$ | async"
        (click)="submit()"
        ngbAutofocus
      >
        <i class="fa fa-check"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.SAVE' | translate }}</span>
      </button>
      <button type="button" class="btn btn-outline-dark round" (click)="c()">
        <i class="fa fa-times"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.CANCEL' | translate }}</span>
      </button>
    </div>
  </div>
</ng-template>
<!-- update product sales discounts modal ends -->

<!-- delete confirm modal starts -->
<ng-template #deleteConfirmModalRef let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title text-danger" id="modal-basic-title">
      {{ 'STORES.PRODUCTS.CONFIRM_DELETE_PRODUCT_SALES_DISCOUNT_TITLE' | translate }}
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="d()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p class="text-danger">{{ 'STORES.PRODUCTS.CONFIRM_DELETE_PRODUCT_SALES_DISCOUNT_MESSAGE' | translate }}</p>
  </div>
  <div class="modal-footer">
    <div class="modal-actions">
      <button
        type="button"
        class="btn btn-raised btn-danger round"
        (click)="c(); deleteConfirm()"
        ngbAutofocus
        [disabled]="isDeleting$ | async"
      >
        <i class="fa fa-check"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.DELETE' | translate }}</span>
      </button>
      <button type="button" class="btn btn-outline-dark round" (click)="c()">
        <i class="fa fa-times"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.CANCEL' | translate }}</span>
      </button>
    </div>
  </div>
</ng-template>
<!-- delete confirm modal starts -->

<!-- locations search starts -->
<app-locations-search
  onlyActive="true"
  closeOnSelect="true"
  (locations)="selectLocation($event)"
  [(visible)]="locationsListVisibility"
>
</app-locations-search>
<!-- locations search ends -->
