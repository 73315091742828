import { createSelector } from '@ngrx/store';

import { selectLookupsState } from 'lookups/store/selectors/feature.selectors';
import { LookupsState, PersonTitleState } from 'lookups/store/states';

/**
 * Gets the person title state.
 */
const selectPersonTitles = createSelector(selectLookupsState, (state: LookupsState) => state.personTitles);

/**
 * Gets the list of loaded person titles.
 */
export const getPersonTitles = createSelector(selectPersonTitles, (state: PersonTitleState) => state.data);

/**
 * Gets the error that occurred in the state.
 */
export const getPersonTitlesError = createSelector(selectPersonTitles, (state: PersonTitleState) => state.error);

/**
 * Determines if there is a running load process.
 */
export const getPersonTitlesIsLoading = createSelector(
  selectPersonTitles,
  (state: PersonTitleState) => state.isLoading
);

/**
 * Determines if the last load process has been ended successfully.
 */
export const getPersonTitlesIsLoaded = createSelector(selectPersonTitles, (state: PersonTitleState) => state.isLoaded);
