import { createSelector } from '@ngrx/store';

import { selectLookupsState } from 'lookups/store/selectors/feature.selectors';
import { LookupsState, ProjectLogTypeState } from 'lookups/store/states';

/**
 * Gets the project log types state.
 */
const selectProjectLogTypes = createSelector(selectLookupsState, (state: LookupsState) => state.projectLogTypes);

/**
 * Gets the list of loaded project log types.
 */
export const getProjectLogTypes = createSelector(selectProjectLogTypes, (state: ProjectLogTypeState) => state.data);

/**
 * Gets the error that occurred in the state.
 */
export const getProjectLogTypesError = createSelector(
  selectProjectLogTypes,
  (state: ProjectLogTypeState) => state.error
);

/**
 * Determines if there is a running load process.
 */
export const getProjectLogTypesIsLoading = createSelector(
  selectProjectLogTypes,
  (state: ProjectLogTypeState) => state.isLoading
);

/**
 * Determines if the last load process has been ended successfully.
 */
export const getProjectLogTypesIsLoaded = createSelector(
  selectProjectLogTypes,
  (state: ProjectLogTypeState) => state.isLoaded
);
