import { Action } from '@ngrx/store';

import { MaritalStatus } from 'lookups/models';
import { AppHttpResponse } from 'shared';

/**
 * The Lookups-Module store marital status action types.
 */
export enum MaritalStatusActionType {
  GET_ALL_MARITAL_STATUSES = '[Lookups] [MaritalStatus] Get All Marital Statuses',
  GET_ALL_MARITAL_STATUSES_FAIL = '[Lookups] [MaritalStatus] Get All Marital Statuses Fail',
  GET_ALL_MARITAL_STATUSES_SUCCESS = '[Lookups] [MaritalStatus] Get All Marital Statuses Success',
}

/**
 * Represents a store marital status action.
 */
export class GetAllMaritalStatuses implements Action {
  /**
   * The type of the action.
   */
  readonly type = MaritalStatusActionType.GET_ALL_MARITAL_STATUSES;

  /**
   * Fires a new marital status action.
   */
  constructor() {}
}

/**
 * Represents a store marital status fail action.
 */
export class GetAllMaritalStatusesFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = MaritalStatusActionType.GET_ALL_MARITAL_STATUSES_FAIL;

  /**
   * Fires a new marital status fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store marital status success action.
 */
export class GetAllMaritalStatusesSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = MaritalStatusActionType.GET_ALL_MARITAL_STATUSES_SUCCESS;

  /**
   * Fires a marital status success action.
   * @param payload An object contains the list of marital statuses.
   */
  constructor(public payload: AppHttpResponse<MaritalStatus[]>) {}
}

/**
 * Lookups-module marital status action types.
 */
export type MaritalStatusActions = GetAllMaritalStatuses | GetAllMaritalStatusesFail | GetAllMaritalStatusesSuccess;
