<div class="row mb-1">
  <div class="col-md-auto">
    <button
      *ngIf="allowAddProjectPlanningResource"
      type="button"
      class="btn btn-sm btn-raised btn-primary round"
      [ngbTooltip]="'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.ADD_RESOURCE_TYPE' | translate"
      placement="top-right"
      (click)="addProjectPlanningResource()"
    >
      <i class="fa fa-plus"></i>
    </button>
    <button
      *ngIf="allowClearProjectPlanningResources"
      type="button"
      class="btn btn-sm btn-raised btn-danger round mx-2"
      [ngbTooltip]="'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.DELETE_ALL_RESOURCE_TYPE' | translate"
      placement="left"
      (click)="openDeleteModal()"
      [disabled]="formArray.controls.length == 0"
    >
      <i class="fa fa-trash"></i>
    </button>
  </div>
</div>
<div [formGroup]="formArray.parent" class="row">
  <div class="col-md-12">
    <div class="table-responsive">
      <table class="table text-center">
        <thead>
          <tr>
            <th scope="col">
              <i class="fa fa-list-ol"></i>
            </th>
            <th scope="col">
              <i class="fa fa-map-signs mr-1"></i>
              {{ 'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.RESOURCE_TYPE' | translate }}
            </th>
            <th scope="col">{{ 'SHARED.TOOLS' | translate }}</th>
          </tr>
        </thead>
        <tbody [formArrayName]="formArrayName">
          <tr [formGroup]="formGroup" *ngFor="let formGroup of formArray.controls; let index = index">
            <th scope="row">{{ index + 1 }}</th>
            <td>
              {{
                formGroup.value.projectPlanningResource.name
                  | localize: formGroup.value.projectPlanningResource.nameEn
                  | async
              }}
            </td>
            <td>
              <a
                class="danger p-0"
                [ngbTooltip]="'SHARED.CONFIRM.DELETE' | translate"
                (click)="removeProjectPlanningResource(index)"
              >
                <i class="fa fa-close font-medium-3 mr-2"></i>
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>

<!-- confirm modal starts -->
<ng-template #confirmModalRef let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
      {{ 'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.ADD_NEW_RESOURCE_TYPE' | translate }}
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="d()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="col-md-12">
      <div class="table-responsive">
        <table class="table text-center" id="locations-table-export-target-element">
          <thead>
            <tr>
              <th scope="col"><i class="fa fa-list-ol"></i></th>
              <th scope="col">{{ 'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.RESOURCE_TYPE' | translate }}</th>
              <th scope="col">{{ 'SHARED.TOOLS' | translate }}</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let resourceType of projectResourceTypes$ | async; let i = index">
              <th scope="row">{{ i + 1 }}</th>
              <td>{{ resourceType.name | localize: resourceType.nameEn | async }}</td>

              <td>
                <a
                  class="primary p-0"
                  (click)="selectProjectPlanningResource([resourceType])"
                  [ngbTooltip]="'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.SELECT_RESOURCE' | translate"
                >
                  <i class="fa fa-arrow-circle-down font-medium-3 mr-2"></i>
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</ng-template>
<!-- confirm modal ends -->

<!-- confirm delete all invoices modal starts -->
<ng-template #deleteModalRef let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title text-danger" id="modal-basic-title">
      {{ 'PROJECTS_MANAGEMENT.PROJECTS.CONFIRM_DELETE_RESOURCE_TITLE' | translate }}
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="d()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p class="text-danger">
      {{ 'PROJECTS_MANAGEMENT.PROJECTS.CONFIRM_DELETE_RESOURCE_MESSAGE' | translate }}
    </p>
  </div>
  <div class="modal-footer">
    <div class="modal-actions">
      <button
        type="button"
        class="btn btn-raised btn-danger round"
        (click)="c(); resetProjectPlanningResources()"
        ngbAutofocus
      >
        <i class="fa fa-check"></i>
        <span class="mx-1"> {{ 'SHARED.CONFIRM.YES' | translate }} </span>
      </button>
      <button type="button" class="btn btn-outline-dark round" (click)="c()">
        <i class="fa fa-times"></i>
        <span class="mx-1"> {{ 'SHARED.CONFIRM.CANCEL' | translate }} </span>
      </button>
    </div>
  </div>
</ng-template>
<!-- confirm modal ends -->
