import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms';

import { Observable, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { PageInfo, NotificationService, NotificationMessage, TranslationService, CustomValidators } from 'shared';
import * as fromSalesStore from 'sales/store';
import { PosPrinter, PosPrinterAdjustment, PosPrinterAdjustmentProductClass } from 'sales/models';
import { ProductClassFormItem } from 'stores';

@Component({
  selector: 'app-update-kitchen-printer',
  templateUrl: './update-kitchen-printer.component.html',
  styles: [],
})
export class UpdateKitchenPrinterComponent implements OnInit, OnDestroy {
  /**
   * The confirm modal template reference.
   */
  @ViewChild('confirmModalRef') confirmModalRef: ElementRef<any>;

  /**
   * Gets or sets the information about the current page.
   */
  pageInfo: PageInfo = {
    title: 'SALES.KITCHEN_PRINTERS.UPDATE_KITCHEN_PRINTER_TITLE',
    icon: 'fa fa-edit',
  };

  /**
   * Gets or sets the id of the current edited kitchen printer.
   */
  kitchenPrinterId: number;

  /**
   * The current edited kitchen printer.
   */
  kitchenPrinters$: Observable<PosPrinterAdjustment>;

  /**
   * Indicates whether there is a update kitchen printer process is running or not.
   */
  isUpdating$: Observable<boolean>;

  /**
   * Shows or hide the pos printers list.
   */
  posPrintersListVisibility = false;

  /**
   * The list of selected pos printers.
   */
  posPrinters: PosPrinter[] = [];

  /**
   * The update kitchen printer form.
   */
  form: FormGroup;

  /**
   * Used to add new product class form items to the locations form.
   */
  newProductClasses: ProductClassFormItem[];

  /**
   * The set of subscriptions on this components,
   * these subscriptions must be unsubscribed before this component got destroyed.
   */
  subscriptions = new Subscription();

  /**
   * Gets the product class form-array.
   */
  get productClassesForm(): FormArray {
    return this.form?.controls.productClasses as FormArray;
  }

  /**
   * @param route The activated route.
   * @param modalService The modal service.
   * @param notificationService The notification service.
   * @param salesStore$  The stores store.
   * @param translationService The translation service.
   */
  constructor(
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private notificationService: NotificationService,
    private salesStore$: Store<fromSalesStore.SalesState>,
    private translationService: TranslationService
  ) {}

  ngOnInit(): void {
    this.init();
  }

  /**
   * Destroy component data
   */
  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  /**
   * Initialize component data.
   */
  init() {
    this.initForm();

    /** Subscribe to route to get the element id whenever it changed. */
    const routeSubscription = this.route.paramMap
      .pipe(
        tap((params) => {
          this.kitchenPrinterId = +params.get('kitchenPrinterId');
          this.salesStore$.dispatch(new fromSalesStore.FindKitchenPrinter(this.kitchenPrinterId));
        })
      )
      .subscribe();
    this.subscriptions.add(routeSubscription);

    /* Load the selected kitchen printer. */
    this.kitchenPrinters$ = this.salesStore$.pipe(
      select(fromSalesStore.getSelectedKitchenPrinter),
      tap((kitchenPrinter) => {
        if (kitchenPrinter) {
          this.posPrinters = [kitchenPrinter.posPrinter];
          this.form.patchValue({ ...kitchenPrinter });
          this.productClassesForm.clear();
          this.newProductClasses = kitchenPrinter.posPrinterAdjustmentProductClasses.map(
            (productClass: PosPrinterAdjustmentProductClass) => ({
              productClassId: productClass.productClassId,
              productClass: productClass.productClass,
            })
          );
          this.form.markAsPristine();
        }
      })
    );

    /**
     * Load data.
     */
    this.isUpdating$ = this.salesStore$.pipe(select(fromSalesStore.getSelectedKitchenPrinterUpdating));
  }

  /**
   * Initialize form and add validators.
   */
  initForm() {
    this.form = new FormGroup({
      productClasses: new FormArray([], CustomValidators.arrayItems(1)),
      posPrinterId: new FormControl(null, [Validators.required]),
    });
  }

  /**
   * Submits the form.
   */
  submit() {
    if (this.form.invalid) {
      const errorMessage = new NotificationMessage();

      if (this.form.get('posPrinterId').invalid) {
        errorMessage.title = this.translationService.translate(
          'SALES.KITCHEN_PRINTERS.KITCHEN_PRINTER_DATA_VALIDATION.KITCHEN_PRINTER_ID_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate(
            'SALES.KITCHEN_PRINTERS.KITCHEN_PRINTER_DATA_VALIDATION.KITCHEN_PRINTER_ID_IS_REQUIRED'
          ),
        ];
      } else if (this.form.get('productClasses').invalid) {
        /**
         * Check if lines count = 0.
         */
        if (!this.productClassesForm.controls.length) {
          errorMessage.title = this.translationService.translate(
            'SALES.KITCHEN_PRINTERS.KITCHEN_PRINTER_DATA_VALIDATION.PRODUCT_CLASSES_ERROR'
          );
          errorMessage.body = [
            this.translationService.translate(
              'SALES.KITCHEN_PRINTERS.KITCHEN_PRINTER_DATA_VALIDATION.PRODUCT_CLASSES_LENGTH_ERROR'
            ),
          ];
        } else {
          /**
           * Check if some of productClasses has errors.
           */
          for (let index = 0; index < this.productClassesForm.controls.length; index++) {
            const productClass = this.productClassesForm.controls[index];

            if (productClass.valid) {
              continue;
            }

            errorMessage.title = this.translationService.translate(
              'SALES.KITCHEN_PRINTERS.KITCHEN_PRINTER_DATA_VALIDATION.PRODUCT_CLASSES_NUMBER_ERROR',
              {
                productClassNumber: index + 1,
              }
            );
            errorMessage.body = [];

            if (productClass.get('productClassId').invalid) {
              errorMessage.body.push(
                this.translationService.translate(
                  'SALES.KITCHEN_PRINTERS.KITCHEN_PRINTER_DATA_VALIDATION.PRODUCT_CLASSES_IS_REQUIRED'
                )
              );
            }
          }
        }
      }
      this.form.markAllAsTouched();
      return this.notificationService.warningWithTitle(errorMessage);
    } else {
      this.openModal(this.confirmModalRef);
    }
  }

  /**
   * Confirms the form submit.
   */
  confirm() {
    const productClasses = this.productClassesForm.value.map((item: ProductClassFormItem) => item.productClassId);

    this.salesStore$.dispatch(
      new fromSalesStore.UpdateKitchenPrinter({
        posPrinterAdjustmentId: this.kitchenPrinterId,
        ...this.form.value,
        productClasses,
        orderTypes: [],
      })
    );
  }

  /**
   * Opens the modal of the given templateRef.
   * @param modalRef The modal templateRef to be opened.
   */
  openModal(modalRef) {
    this.modalService.open(modalRef);
  }

  /**
   * Selects the newly selected pos printers from the pos printers search list.
   * @param posPrinter The list of newly selected pos printers to select the only one in the list.
   */
  selectPosPrinter([posPrinter]: PosPrinter[]) {
    if (posPrinter) {
      this.posPrinters = [posPrinter];
      this.form.patchValue({ posPrinterId: posPrinter.id });
    }
  }
}
