import { CreateProductClassComponent } from 'stores/components/product-classes/create-product-class/create-product-class.component';
import { UpdateProductClassComponent } from 'stores/components/product-classes/update-product-class/update-product-class.component';
import { ProductClassesListComponent } from 'stores/components/product-classes/product-classes-list/product-classes-list.component';
import { ProductClassesSearchComponent } from 'stores/components/product-classes/product-classes-search/product-classes-search.component';
import { ProductClassesFormComponent } from 'stores/components/product-classes/product-classes-form/product-classes-form.component';

export * from 'stores/components/product-classes/create-product-class/create-product-class.component';
export * from 'stores/components/product-classes/update-product-class/update-product-class.component';
export * from 'stores/components/product-classes/product-classes-list/product-classes-list.component';
export * from 'stores/components/product-classes/product-classes-search/product-classes-search.component';
export * from 'stores/components/product-classes/product-classes-form/product-classes-form.component';

/**
 * The set of components registered on this sub-module.
 */
export const COMPONENTS: any[] = [
  CreateProductClassComponent,
  UpdateProductClassComponent,
  ProductClassesListComponent,
  ProductClassesSearchComponent,
  ProductClassesFormComponent,
];
