<ng-template #modalRef let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title text-primary" id="modal-basic-title">
      {{ 'FINANCES.PETTY_CASH.PETTY_CASH_LIST' | translate }}
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="d(); closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <!-- petty cash list starts -->
  <div class="modal-body">
    <div class="row">
      <div class="col-md-12">
        <form class="row" [formGroup]="searchForm" (ngSubmit)="search()">
          <div class="form-group col">
            <input
              id="description"
              type="text"
              class="form-control round"
              [placeholder]="
                'FINANCES.PETTY_CASH.PETTY_CASH_DATA.PETTY_CASH_DESCRIPTION_SEARCH_PLACEHOLDER' | translate
              "
              formControlName="description"
              (input)="search($event)"
            />
          </div>
          <div class="form-group col">
            <ng-select
              class="round"
              formControlName="types"
              [multiple]="true"
              [closeOnSelect]="false"
              labelForId="types"
              searchable="true"
              [placeholder]="'FINANCES.PETTY_CASH.PETTY_CASH_DATA.PETTY_CASH_TYPE_PLACEHOLDER' | translate"
              [notFoundText]="'SHARED.SEARCH.SEARCH_NOT_FOUND' | translate"
            >
              <ng-option *ngFor="let pettyCashType of pettyCashTypes$ | async" [value]="pettyCashType.id">
                {{ pettyCashType.name | localize: pettyCashType.nameEn | async }}
              </ng-option>
            </ng-select>
          </div>
          <div class="form-group col">
            <ng-select
              class="round"
              formControlName="specifications"
              [multiple]="true"
              [closeOnSelect]="false"
              labelForId="specifications"
              searchable="true"
              [placeholder]="'FINANCES.PETTY_CASH.PETTY_CASH_DATA.PETTY_CASH_SPECIFICATION_PLACEHOLDER' | translate"
              [notFoundText]="'SHARED.SEARCH.SEARCH_NOT_FOUND' | translate"
            >
              <ng-option
                *ngFor="let pettyCashSpecification of pettyCashSpecifications$ | async"
                [value]="pettyCashSpecification.id"
              >
                {{ pettyCashSpecification.name | localize: pettyCashSpecification.nameEn | async }}
              </ng-option>
            </ng-select>
          </div>
          <div class="form-group col-md-auto">
            <button type="submit" class="btn btn-raised btn-primary round" [disabled]="isSearching$ | async">
              <i class="fa fa-search"></i>
            </button>
          </div>
        </form>
      </div>
      <div class="col-md-12" *ngIf="(pettyCash$ | async)?.length; else noItems">
        <div class="row">
          <div class="col-md-12 alert alert-light text-center" role="alert">
            {{ 'SHARED.SEARCH.SEARCH_RESULTS' | translate }} ({{ (paginationInfo$ | async).total | toNumber }})
            {{ 'FINANCES.PETTY_CASH.PETTY_CASH_DATA.PETTY_CASH' | translate }}
          </div>
          <div class="col-md-12">
            <div class="table-responsive">
              <table class="table text-center">
                <thead>
                  <tr>
                    <th class="fit-width" *ngIf="!closeOnSelect">
                      <a
                        class="primary p-0"
                        (click)="selectPettyCash(pettyCashList)"
                        [ngbTooltip]="'SHARED.SEARCH.SELECT_ALL' | translate"
                      >
                        <i class="fa fa-check-circle font-medium-3"></i>
                      </a>
                    </th>
                    <th class="fit-width" *ngIf="closeOnSelect">
                      <i class="fa fa-gear font-medium-3"></i>
                    </th>
                    <th scope="col">
                      <i class="fa fa-barcode"></i
                      ><span class="mx-1">{{ 'FINANCES.PETTY_CASH.PETTY_CASH_DATA.PETTY_CASH_CODE' | translate }}</span>
                    </th>
                    <th scope="col">{{ 'FINANCES.PETTY_CASH.PETTY_CASH_DATA.PETTY_CASH_DESCRIPTION' | translate }}</th>
                    <th scope="col">{{ 'FINANCES.PETTY_CASH.PETTY_CASH_DATA.PETTY_CASH_TYPE' | translate }}</th>
                    <th scope="col">{{ 'FINANCES.PETTY_CASH.PETTY_CASH_DATA.PETTY_CASH_VALUE' | translate }}</th>
                    <th scope="col">{{ 'FINANCES.PETTY_CASH.PETTY_CASH_DATA.MAX_PAYMENT_VALUE' | translate }}</th>
                    <th scope="col">
                      {{ 'FINANCES.PETTY_CASH.PETTY_CASH_DATA.PETTY_CASH_SPECIFICATION' | translate }}
                    </th>
                    <th scope="col">{{ 'FINANCES.PETTY_CASH.PETTY_CASH_DATA.PETTY_CASH_DATE' | translate }}</th>
                    <th scope="col">{{ 'SHARED.SEARCH.CREATED_AT' | translate }}</th>
                    <th scope="col">{{ 'FINANCES.PETTY_CASH.PETTY_CASH_DATA.STATUS' | translate }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let petty of pettyCash$ | async; trackBy: trackItems; let i = index">
                    <td>
                      <a
                        class="primary p-0"
                        (click)="selectPettyCash([petty])"
                        [ngbTooltip]="'FINANCES.PETTY_CASH.PETTY_CASH_DATA.SELECT_PETTY_CASH' | translate"
                      >
                        <i class="fa fa-check-circle-o font-medium-3"></i>
                      </a>
                    </td>
                    <th scope="row">{{ petty.id }}</th>
                    <td>{{ petty.description }}</td>
                    <td>{{ petty.pettyCashType.name | localize: petty.pettyCashType.nameEn | async }}</td>
                    <td>{{ petty.value | toDecimal }}</td>
                    <td>{{ petty.maxAllowedPaymentValue | toDecimal }}</td>
                    <td>
                      {{ petty.pettyCashSpecification.name | localize: petty.pettyCashSpecification.nameEn | async }}
                    </td>
                    <td>
                      <span [ngbTooltip]="petty.transactionDate | date: 'time'">
                        {{ petty.transactionDate | date }}
                      </span>
                    </td>
                    <td>
                      <span [ngbTooltip]="petty.createdAt | date: 'time'">
                        {{ petty.createdAt | date }}
                      </span>
                    </td>
                    <td>
                      <app-status
                        [status]="petty.isActive"
                        [activeText]="'FINANCES.PETTY_CASH.PETTY_CASH_DATA.ACTIVE' | translate"
                        [notActiveText]="'FINANCES.PETTY_CASH.PETTY_CASH_DATA.NOT_ACTIVE' | translate"
                      >
                      </app-status>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div class="d-flex justify-content-between p-2">
                <ngb-pagination
                  *ngIf="paginationInfo$ | async; let pagination"
                  maxSize="5"
                  [collectionSize]="pagination.total"
                  [pageSize]="pagination.pageSize"
                  [page]="pagination.page"
                  (pageChange)="pageChanged($event)"
                  boundaryLinks="true"
                >
                  <ng-template ngbPaginationFirst>{{ 'SHARED.PAGINATION.PAGINATION_FIRST' | translate }}</ng-template>
                  <ng-template ngbPaginationFirst>{{
                    'SHARED.PAGINATION.PAGINATION_PREVIOUS' | translate
                  }}</ng-template>
                  <ng-template ngbPaginationFirst>{{ 'SHARED.PAGINATION.PAGINATION_NEXT' | translate }}</ng-template>
                  <ng-template ngbPaginationFirst>{{ 'SHARED.PAGINATION.PAGINATION_LAST' | translate }}</ng-template>
                </ngb-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <div class="modal-actions">
      <button type="button" class="btn btn-outline-dark round" (click)="c(); closeModal()">
        <i class="fa fa-times"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.CLOSE' | translate }}</span>
      </button>
    </div>
  </div>
</ng-template>
<!-- petty cash list ends -->

<!-- no items template starts -->
<ng-template #noItems>
  <div class="col-md-12 alert alert-warning mt-3 text-center" role="alert">
    {{ 'FINANCES.PETTY_CASH.SEARCH_PETTY_CASH_WITHOUT_ADD_NOT_FOUND' | translate }}
  </div>
</ng-template>
<!-- no items template ends -->
