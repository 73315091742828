import { Component } from '@angular/core';

import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
})
export class FooterComponent {
  /** Gets the software Settings. */
  softwareSettings = environment.softwareSettings;

  /** Gets the current year. */
  currentYear = new Date().getFullYear();
}
