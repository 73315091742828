import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { skip, tap } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { flatMap } from 'lodash';

import * as fromSecurityStore from 'security/store';
import * as fromAuthStore from 'auth/store';
import { PageInfo, NotificationService, NotificationMessage, TranslationService } from 'shared';
import { ClaimGroup, User, UserClaimGroupFormItem } from 'security/models';
import { LocationFormItem } from 'stores/models';
import { BankAccountFormItem, CostCenterFormItem } from 'finances/models';

@Component({
  selector: 'app-create-organization-user',
  templateUrl: './create-organization-user.component.html',
  styles: [],
})
export class CreateOrganizationUserComponent implements OnInit, OnDestroy {
  /**
   * The confirm modal template reference.
   */
  @ViewChild('confirmModalRef') confirmModalRef: ElementRef<any>;

  /**
   * Gets or sets the information about the current page.
   */
  pageInfo: PageInfo = {
    title: 'SECURITY.USERS.CREATE_USER_PAGE_TITLE',
    icon: 'fa fa-user-plus',
  };

  /**
   * The list of all claim groups.
   */
  claimGroups: ClaimGroup[] = [];

  /**
   * Indicates whether there is a create-organization-user process is running or not.
   */
  isCreating$: Observable<boolean>;

  /**
   * The create organization user form.
   */
  form: FormGroup;

  /**
   * The current logged in user.
   */
  currentLoggedInUser: User;

  /**
   * The claim groups model.
   * Uses `[(ngModel)]` binding.
   */
  claimGroupsModel: UserClaimGroupFormItem[] = [];

  /**
   * Sets the initial locations should be added to the locations form.
   * @param locations An object contains `count` of initial blank locations.
   * @param locations A single service details to be added to the form.
   * @param locations A list of locations details to be added to the form.
   */
  initialLocationsSubject: BehaviorSubject<
    { count: number } | LocationFormItem | LocationFormItem[]
  > = new BehaviorSubject(undefined);

  /**
   * Sets the initial cost-centers should be added to the cost-centers form.
   * @param costCenters An object contains `count` of initial blank cost-centers.
   * @param costCenters A single service details to be added to the form.
   * @param costCenters A list of cost-centers details to be added to the form.
   */
  initialCostCentersSubject: BehaviorSubject<
    { count: number } | CostCenterFormItem | CostCenterFormItem[]
  > = new BehaviorSubject(undefined);

  /**
   * Gets the locations form-array.
   */
  get locationsForm(): FormArray {
    return this.form?.controls.locations as FormArray;
  }

  /**
   * Gets the bank-accounts form-array.
   */
  get bankAccountsForm(): FormArray {
    return this.form?.controls.bankAccounts as FormArray;
  }

  /**
   * Gets the cost-centers form-array.
   */
  get costCentersForm(): FormArray {
    return this.form?.controls.costCenters as FormArray;
  }

  /**
   * The set of subscriptions on this components,
   * these subscriptions must be unsubscribed before this component got destroyed.
   */
  subscriptions = new Subscription();

  /**
   * @param modalService The modal service.
   * @param notificationService The notification service.
   * @param translationService The translation service.
   * @param securityStore$ The Security-module store.
   * @param authStore$ The Auth-module store.
   */
  constructor(
    private modalService: NgbModal,
    private notificationService: NotificationService,
    private translationService: TranslationService,
    private securityStore$: Store<fromSecurityStore.SecurityState>,
    private authStore$: Store<fromAuthStore.AuthState>
  ) {}

  ngOnInit(): void {
    this.init();
  }

  /**
   * Destroy component data
   */
  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  /**
   * Initialize component data.
   */
  init() {
    this.initForm();

    /**
     * Load data.
     */
    this.securityStore$.dispatch(new fromSecurityStore.GetCurrentLoggedInUserClaimsGroups());

    this.subscriptions.add(
      this.securityStore$
        .select(fromSecurityStore.getCurrentLoggedInUserClaimGroups)
        .pipe(
          tap((groups) => {
            this.claimGroupsModel = groups.map((group) => {
              return {
                group,
                isSelected: false,
                claims: group.claims.map((claim) => {
                  return {
                    claim,
                    isSelected: false,
                  };
                }),
              };
            });
          })
        )
        .subscribe()
    );

    /** Load current logged in user data. */
    this.subscriptions.add(
      this.authStore$
        .pipe(
          select(fromAuthStore.getSessionUser),
          tap((user: User) => {
            if (user) {
              this.currentLoggedInUser = user;
            }
          })
        )
        .subscribe()
    );

    /**
     * Set data.
     */
    this.isCreating$ = this.securityStore$.pipe(select(fromSecurityStore.getSelectedOrganizationUserCreating));

    /**
     * reset form on creation complete
     */
    this.subscriptions.add(
      this.securityStore$
        .pipe(
          select(fromSecurityStore.getSelectedOrganizationUser),
          skip(1),
          tap((created) => {
            if (created) {
              this.initForm();
              this.form.markAsUntouched();
            }
          })
        )
        .subscribe()
    );
  }

  /**
   * Initialize form and add validators.
   */
  initForm() {
    this.form = new FormGroup({
      name: new FormControl('', [Validators.required, Validators.minLength(1), Validators.maxLength(50)]),
      username: new FormControl('', [Validators.required, Validators.minLength(4), Validators.maxLength(50)]),
      password: new FormControl('', [Validators.required, Validators.minLength(8), Validators.maxLength(50)]),
      locations: new FormArray([]),
      bankAccounts: new FormArray([]),
      costCenters: new FormArray([]),
    });
  }

  /**
   * Submits the form.
   */
  submit() {
    if (this.form.invalid) {
      const errorMessage = new NotificationMessage();
      if (this.form.get('name').invalid) {
        errorMessage.title = this.translationService.translate('SECURITY.USERS.USER_DATA_VALIDATION.USER_NAME_ERROR');
        errorMessage.body = [
          this.translationService.translate('SECURITY.USERS.USER_DATA_VALIDATION.USER_NAME_IS_REQUIRED'),
          this.translationService.translate('SECURITY.USERS.USER_DATA_VALIDATION.USER_NAME_LENGTH_ERROR'),
        ];
      } else if (this.form.get('username').invalid) {
        errorMessage.title = this.translationService.translate('SECURITY.USERS.USER_DATA_VALIDATION.USERNAME_ERROR');
        errorMessage.body = [
          this.translationService.translate('SECURITY.USERS.USER_DATA_VALIDATION.USERNAME_IS_REQUIRED'),
          this.translationService.translate('SECURITY.USERS.USER_DATA_VALIDATION.USERNAME_LENGTH_ERROR'),
        ];
      } else if (this.form.get('password').invalid) {
        errorMessage.title = this.translationService.translate('SECURITY.USERS.USER_DATA_VALIDATION.PASSWORD_ERROR');
        errorMessage.body = [
          this.translationService.translate('SECURITY.USERS.USER_DATA_VALIDATION.PASSWORD_IS_REQUIRED'),
          this.translationService.translate('SECURITY.USERS.USER_DATA_VALIDATION.PASSWORD_LENGTH_ERROR'),
        ];
      }

      this.form.markAllAsTouched();
      return this.notificationService.warningWithTitle(errorMessage);
    } else {
      this.openModal(this.confirmModalRef);
    }
  }

  /**
   * Confirms the form submit.
   */
  confirm() {
    /**
     * The list of locations in the locations form.
     */

    const locations = this.locationsForm.value.map((item: LocationFormItem) => item.locationId);

    /**
     * The list of bank accounts in the bank accounts form.
     */

    const bankAccounts = this.bankAccountsForm.value.map((item: BankAccountFormItem) => item.bankAccountId);

    /**
     * The list of cost centers in the cost centers form.
     */

    const costCenters = this.costCentersForm.value.map((item: CostCenterFormItem) => item.costCenterId);

    this.securityStore$.dispatch(
      new fromSecurityStore.CreateOrganizationUser({
        ...this.form.value,
        locations: this.currentLoggedInUser.organizationId ? locations : [],
        bankAccounts: this.currentLoggedInUser.organizationId ? bankAccounts : [],
        costCenters: this.currentLoggedInUser.organizationId ? costCenters : [],

        /**
         * Map claim groups model to Array(number) of claim ids.
         */
        claims: flatMap(this.claimGroupsModel, (group) => group.claims)
          .filter((claim) => claim.isSelected)
          .map((claim) => claim.claim.id),
      })
    );
  }

  /**
   * Opens the modal of the given templateRef.
   * @param modalRef The modal templateRef to be opened.
   */
  openModal(modalRef) {
    this.modalService.open(modalRef);
  }
}
