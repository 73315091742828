/**
 * Represents a customer category.
 */
export class CustomerCategory {
  /**
   * Gets or sets the id of the customer category.
   */
  id: number;

  /**
   * Gets or sets the name of the customer category.
   */
  name: string;

  /**
   * Gets or sets the English name of the customer category.
   */
  nameEn: string;

  /**
   * Gets or sets the key of the customer category.
   */
  key: string;
}
