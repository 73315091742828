import { Action } from '@ngrx/store';

import { BankAccountLogType } from 'lookups/models';
import { AppHttpResponse } from 'shared';

/**
 * The Lookups-Module store  bank account log type action types.
 */
export enum BankAccountLogTypeActionType {
  GET_ALL_BANK_ACCOUNT_LOG_TYPES = '[Lookups] [BankAccountLogType] Get All Bank Account Log Types',
  GET_ALL_BANK_ACCOUNT_LOG_TYPES_FAIL = '[Lookups] [BankAccountLogType] Get All Bank Account Log Types Fail',
  GET_ALL_BANK_ACCOUNT_LOG_TYPES_SUCCESS = '[Lookups] [BankAccountLogType] Get All Bank Account Log Types Success',
}

/**
 * Represents a store bank account log type action.
 */
export class GetAllBankAccountLogTypes implements Action {
  /**
   * The type of the action.
   */
  readonly type = BankAccountLogTypeActionType.GET_ALL_BANK_ACCOUNT_LOG_TYPES;

  /**
   * Fires a new bank account log type action.
   */
  constructor() {}
}

/**
 * Represents a store bank account log type fail action.
 */
export class GetAllBankAccountLogTypesFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = BankAccountLogTypeActionType.GET_ALL_BANK_ACCOUNT_LOG_TYPES_FAIL;

  /**
   * Fires a new bank account log type fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store bank account log type success action.
 */
export class GetAllBankAccountLogTypesSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = BankAccountLogTypeActionType.GET_ALL_BANK_ACCOUNT_LOG_TYPES_SUCCESS;

  /**
   * Fires a bank account log type success action.
   * @param payload An object contains the list of bank account log types.
   */
  constructor(public payload: AppHttpResponse<BankAccountLogType[]>) {}
}

/**
 * Lookups-module bank account log type action types.
 */
export type BankAccountLogTypeActions =
  | GetAllBankAccountLogTypes
  | GetAllBankAccountLogTypesFail
  | GetAllBankAccountLogTypesSuccess;
