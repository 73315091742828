<!-- bank accounts search modal starts -->

<ng-template #modalRef let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title text-primary" id="modal-basic-title">
      {{ 'FINANCES.BANK_ACCOUNTS.BANK_ACCOUNTS_LIST' | translate }}
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="d(); closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <!-- banks list starts -->
    <div class="row">
      <div class="col-md-12">
        <form class="row" [formGroup]="searchForm" (ngSubmit)="search()">
          <div class="form-group col">
            <label for="name">{{ 'FINANCES.BANK_ACCOUNTS.BANK_ACCOUNT_DATA.ACCOUNT_NAME' | translate }}</label>
            <input
              type="text"
              id="name"
              class="form-control round"
              [placeholder]="'FINANCES.BANK_ACCOUNTS.BANK_ACCOUNT_DATA.ACCOUNT_IBAN_PLACEHOLDER' | translate"
              formControlName="name"
              (input)="search($event)"
            />
          </div>
          <div class="form-group col">
            <label for="banks">{{ 'FINANCES.BANK_ACCOUNTS.BANK_ACCOUNT_DATA.BANKS' | translate }}</label>
            <ng-select
              class="round"
              formControlName="banks"
              [multiple]="true"
              [closeOnSelect]="false"
              labelForId="banks"
              searchable="true"
              [placeholder]="'FINANCES.BANK_ACCOUNTS.BANK_ACCOUNT_DATA.BANKS_PLACEHOLDER' | translate"
              [notFoundText]="'SHARED.SEARCH.SEARCH_NOT_FOUND' | translate"
              (open)="banksListVisibility = true"
            >
              <ng-option *ngFor="let bank of banks" [value]="bank.id">
                {{ bank.name | localize: bank.nameEn | async }}
              </ng-option>
            </ng-select>
          </div>
          <div class="col-md-auto form-group d-flex">
            <button
              type="submit"
              class="btn btn-raised btn-primary align-self-end round mx-1 mb-0"
              [disabled]="isSearching$ | async"
            >
              <i class="fa fa-search"></i>
            </button>
          </div>
        </form>
      </div>
      <div class="col-md-12" *ngIf="(bankAccounts$ | async)?.length; else noItems">
        <div class="row">
          <div class="col-md-12 alert alert-light text-center" role="alert">
            {{ 'SHARED.SEARCH.SEARCH_RESULTS' | translate }} ({{ (paginationInfo$ | async).total | toNumber }})
            {{ 'FINANCES.BANK_ACCOUNTS.BANK_ACCOUNT_DATA.BANK_ACCOUNT' | translate }}
          </div>
          <div class="col-md-12">
            <div class="table-responsive">
              <table class="table text-center">
                <thead>
                  <tr>
                    <th scope="col"><i class="fa fa-list-ol"></i></th>
                    <th scope="col">{{ 'FINANCES.BANK_ACCOUNTS.BANK_ACCOUNT_DATA.ACCOUNT_NAME' | translate }}</th>
                    <th scope="col">{{ 'FINANCES.BANK_ACCOUNTS.BANK_ACCOUNT_DATA.ACCOUNT_NUMBER' | translate }}</th>
                    <th scope="col">{{ 'FINANCES.BANK_ACCOUNTS.BANK_ACCOUNT_DATA.BANK_NAME' | translate }}</th>
                    <th scope="col">
                      {{ 'FINANCES.BANK_ACCOUNTS.BANK_ACCOUNT_DATA.ACCOUNT_NUMBER_IBAN' | translate }}
                    </th>
                    <th scope="col">
                      {{ 'FINANCES.BANK_ACCOUNTS.BANK_ACCOUNT_DATA.BANK_ACCOUNT_CURRENCY' | translate }}
                    </th>
                    <th scope="col">{{ 'FINANCES.BANK_ACCOUNTS.BANK_ACCOUNT_DATA.STATUS' | translate }}</th>
                    <th class="fit-width" *ngIf="!closeOnSelect">
                      <a
                        class="primary p-0"
                        (click)="selectBankAccount(bankAccountsList)"
                        [ngbTooltip]="'SHARED.SEARCH.SELECT_ALL' | translate"
                      >
                        <i class="fa fa-check-circle font-medium-3"></i>
                      </a>
                    </th>
                    <th class="fit-width" *ngIf="closeOnSelect">
                      <i class="fa fa-gear font-medium-3"></i>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let bankAccount of bankAccounts$ | async; trackBy: trackItems; let i = index">
                    <td>
                      <a
                        class="primary p-0"
                        (click)="selectBankAccount([bankAccount])"
                        [ngbTooltip]="'FINANCES.BANK_ACCOUNTS.BANK_ACCOUNT_DATA.ACCOUNT_SELECT' | translate"
                      >
                        <i class="fa fa-check-circle-o font-medium-3"></i>
                      </a>
                    </td>
                    <th scope="row">{{ bankAccount.id }}</th>
                    <td>{{ bankAccount.name | localize: bankAccount.nameEn | async }}</td>
                    <td>{{ bankAccount.accountNumber }}</td>
                    <td>{{ bankAccount.bank?.name | localize: bankAccount.bank?.nameEn | async }}</td>
                    <td>{{ bankAccount.iban }}</td>
                    <td>{{ bankAccount.currency.name | localize: bankAccount.currency.nameEn | async }}</td>
                    <td>
                      <app-status
                        [status]="bankAccount.isActive"
                        [activeText]="'FINANCES.BANK_ACCOUNTS.BANK_ACCOUNT_DATA.F_ACTIVE' | translate"
                        [notActiveText]="'FINANCES.BANK_ACCOUNTS.BANK_ACCOUNT_DATA.F_BLOCK' | translate"
                      >
                      </app-status>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div class="d-flex justify-content-between p-2">
                <ngb-pagination
                  *ngIf="paginationInfo$ | async; let pagination"
                  maxSize="5"
                  [collectionSize]="pagination.total"
                  [pageSize]="pagination.pageSize"
                  [page]="pagination.page"
                  (pageChange)="pageChanged($event)"
                  boundaryLinks="true"
                >
                  <ng-template ngbPaginationFirst>{{ 'SHARED.PAGINATION.PAGINATION_FIRST' | translate }}</ng-template>
                  <ng-template ngbPaginationFirst>{{
                    'SHARED.PAGINATION.PAGINATION_PREVIOUS' | translate
                  }}</ng-template>
                  <ng-template ngbPaginationFirst>{{ 'SHARED.PAGINATION.PAGINATION_NEXT' | translate }}</ng-template>
                  <ng-template ngbPaginationFirst>{{ 'SHARED.PAGINATION.PAGINATION_LAST' | translate }}</ng-template>
                </ngb-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- bank accounts list ends -->
  </div>
  <div class="modal-footer">
    <div class="modal-actions">
      <button type="button" class="btn btn-outline-dark round" (click)="c(); closeModal()">
        <i class="fa fa-times"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.CLOSE' | translate }}</span>
      </button>
    </div>
  </div>
</ng-template>
<!-- bank accounts search modal ends -->

<!-- no items template starts -->
<ng-template #noItems>
  <div class="col-md-12 alert alert-warning mt-3 text-center" role="alert">
    {{ 'FINANCES.BANK_ACCOUNTS.SEARCH_BANK_ACCOUNT_WITHOUT_ADD_NOT_FOUND' | translate }}
  </div>
</ng-template>
<!-- no items template ends -->

<!-- banks search starts -->
<banks-search [(visible)]="banksListVisibility" (banks)="selectBanks($event)"> </banks-search>
<!-- banks search ends -->
