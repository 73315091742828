import { CostCentersState } from 'finances/store/states/cost-centers.state';
import { PettyCashState } from 'finances/store/states/petty-cash.state';
import { AccountsState } from 'finances/store/states/accounts.state';
import { BanksState } from 'finances/store/states/banks.state';
import { SystemAccountsState } from 'finances/store/states/system-accounts.state';
import { JournalsState } from 'finances/store/states/journals.state';
import { PettyCashRefundState } from 'finances/store/states/petty-cash-refund.state';
import { PettyCashReductionState } from 'finances/store/states/petty-cash-reduction.state';
import { PettyCashRefundRequestsState } from 'finances/store/states/petty-cash-refund-requests.state';
import { BankAccountState } from 'finances/store/states/bank-account.state';
import { PettyCashRefundRequestApprovalsState } from 'finances/store/states/petty-cash-refund-request-approvals.state';
import { FinancialPeriodsState } from 'finances/store/states/financial-periods.state';
import { CurrenciesState } from 'finances/store/states/currencies.state';
import { CurrencyExchangeRatesState } from 'finances/store/states/currency-exchange-rates.state';
import { HrState } from 'finances/store/states/hr.state';
import { JournalAssociationState } from 'finances/store/states/journal-association.state';
import { FinanceConfigurationsState } from 'finances/store/states/finance-configurations.state';

/**
 * Represents the finances-module state.
 */
export class FinancesState {
  /**
   * Represents the cost centers state.
   */
  public costCenters: CostCentersState;

  /**
   * Represents the petty-Cash state.
   */
  public pettyCash: PettyCashState;

  /**
   * Represents the petty-Cash-refund-request state.
   */
  public pettyCashRefundRequests: PettyCashRefundRequestsState;

  /**
   * Represents the petty-cash-refund state.
   */
  public pettyCashRefund: PettyCashRefundState;

  /**
   * Represents the petty-cash-reduction state.
   */
  public pettyCashReduction: PettyCashReductionState;

  /**
   * Represents the accounts state.
   */
  public accounts: AccountsState;

  /**
   * Represents the system accounts state.
   */
  public systemAccounts: SystemAccountsState;

  /**
   * Represents the journals state.
   */
  public journals: JournalsState;

  /**
   * Represents the banks state.
   */
  public banks: BanksState;

  /**
   * Represents the bank accounts state.
   */
  public bankAccounts: BankAccountState;

  /**
   * Represents the petty-Cash-refund-request-approvals state.
   */
  public pettyCashRefundRequestApprovals: PettyCashRefundRequestApprovalsState;

  /**
   * Represents the financial periods state.
   */
  public financialPeriods: FinancialPeriodsState;

  /**
   * Represents the currencies state.
   */
  public currencies: CurrenciesState;

  /**
   * Represents the currency exchange rates state.
   */
  public currencyExchangeRates: CurrencyExchangeRatesState;

  /**
   * Represents the hr state.
   */
  public hr: HrState;

  /**
   * Represents the journal association state.
   */
  public journalAssociation: JournalAssociationState;

  /**
   * Represents the finance configurations state.
   */
  public financeConfigurations: FinanceConfigurationsState;
}
