import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { Location as AngularLocation } from '@angular/common';
import { ActivatedRoute } from '@angular/router';

import { Observable, Subscription } from 'rxjs';
import { skip, tap } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import * as fromProjectsManagementStore from 'projects-management/store';
import { CustomValidators, NotificationMessage, NotificationService, PageInfo, TranslationService } from 'shared';
import { ProjectPlanningResourceFormItem } from 'projects-management/models';
import { CanComponentDeactivate } from 'auth/models';

/**
 * The create project component pages.
 */
enum PAGES {
  'project-details' = 'project-details',
  'project-planning-resources' = 'project-planning-resources',
}

@Component({
  selector: 'app-create-project',
  templateUrl: './create-project.component.html',
  styles: [],
})
export class CreateProjectComponent implements OnInit, OnDestroy, CanComponentDeactivate {
  /**
   * The confirm modal template reference.
   */
  @ViewChild('confirmModalRef') confirmModalRef: ElementRef<any>;

  /**
   * Gets or sets the information about the current page.
   */
  pageInfo: PageInfo = {
    title: 'PROJECTS_MANAGEMENT.PROJECTS.CREATE_PROJECT_PAGE_TITLE',
    icon: 'fa fa-plus',
  };

  /**
   * Gets or sets the selected page.
   * @default 'project-details'
   */
  activePage: PAGES = PAGES['project-details'];

  /**
   * The create project form.
   */
  form: FormGroup;

  /**
   * Indicates whether there is a create-project process is running or not.
   */
  isCreating$: Observable<boolean>;

  /**
   * The set of subscriptions on this components,
   * these subscriptions must be unsubscribed before this component got destroyed.
   */
  subscriptions = new Subscription();

  /**
   * Gets the project details form-group.
   */
  get projectDetails(): FormGroup {
    return this.form?.controls.projectDetails as FormGroup;
  }

  /**
   * Gets the project planning resources form-array.
   */
  get projectPlanningResources(): FormArray {
    return this.form?.controls.projectPlanningResources as FormArray;
  }

  /**
   * @param modalService The modal service.
   * @param locationService The location service.
   * @param route The Activated route service.
   * @param notificationService The notification service.
   * @param projectManagementsStore$ the  hr-store module.
   * @param translationService The translation service.
   */
  constructor(
    private modalService: NgbModal,
    private locationService: AngularLocation,
    private route: ActivatedRoute,
    private notificationService: NotificationService,
    private projectsManagementStore$: Store<fromProjectsManagementStore.ProjectsManagementState>,
    private translationService: TranslationService
  ) {}

  ngOnInit(): void {
    this.init();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  /**
   * Initialize component data.
   */
  init() {
    this.initForm();

    /**
     * Load data.
     */
    this.isCreating$ = this.projectsManagementStore$.pipe(
      select(fromProjectsManagementStore.getSelectedProjectCreating)
    );

    /**
     * Reset form controls when new project is created.
     */
    this.subscriptions.add(
      this.projectsManagementStore$
        .pipe(
          select(fromProjectsManagementStore.getSelectedProjectCreateCompleted),
          skip(1),
          tap((isCreated) => {
            if (isCreated) {
              this.resetForm();
            }
          })
        )
        .subscribe()
    );

    /** Select the user desired page. */
    this.activePage = PAGES[this.route.snapshot.fragment] ?? this.activePage;
    this.selectedPageChanged(this.activePage);
  }

  /**
   * Initialize component data.
   */
  initForm() {
    this.form = new FormGroup({
      projectDetails: new FormGroup({
        code: new FormControl('', [Validators.required, Validators.minLength(1), Validators.maxLength(50)]),
        description: new FormControl('', [Validators.minLength(0), Validators.maxLength(200)]),
        descriptionEn: new FormControl('', [Validators.minLength(0), Validators.maxLength(200)]),
        allowExceedBudget: new FormControl(false),
        customerId: new FormControl(null, Validators.required),
        laborBudget: new FormControl(null, Validators.required),
        laborOvertimeBudget: new FormControl(null, Validators.required),
        equipmentBudget: new FormControl(null, Validators.required),
        revenueBudget: new FormControl(null, Validators.required),
        projectStatusId: new FormControl(null, Validators.required),
        locationId: new FormControl(null, Validators.required),
        startDate: new FormControl(null, Validators.required),
        endDate: new FormControl(null, Validators.required),
      }),
      projectPlanningResources: new FormArray([], CustomValidators.arrayItems(1)),
    });
  }

  /**
   * Submits the form.
   */
  submit() {
    const errorMessage = new NotificationMessage();

    if (this.form.invalid) {
      if (this.projectDetails.get('code').invalid) {
        errorMessage.title = this.translationService.translate(
          'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA_VALIDATION.PROJECT_DETAILS_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate('PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA_VALIDATION.CODE_ERROR'),
          this.translationService.translate('PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA_VALIDATION.CODE_IS_REQUIRED'),
          this.translationService.translate('PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA_VALIDATION.CODE_LENGTH_ERROR'),
        ];
        this.activePage = PAGES['project-details'];
      } else if (this.projectDetails.get('description').invalid) {
        errorMessage.title = this.translationService.translate(
          'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA_VALIDATION.PROJECT_DETAILS_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate(
            'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA_VALIDATION.ARABIC_PROJECT_DESCRIPTION_ERROR'
          ),
          this.translationService.translate(
            'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA_VALIDATION.PROJECT_DESCRIPTION_LENGTH_ERROR'
          ),
        ];
        this.activePage = PAGES['project-details'];
      } else if (this.projectDetails.get('descriptionEn').invalid) {
        errorMessage.title = this.translationService.translate(
          'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA_VALIDATION.PROJECT_DETAILS_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate(
            'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA_VALIDATION.ENGLISH_PROJECT_DESCRIPTION_ERROR'
          ),
          this.translationService.translate(
            'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA_VALIDATION.PROJECT_DESCRIPTION_LENGTH_ERROR'
          ),
        ];
        this.activePage = PAGES['project-details'];
      } else if (this.projectDetails.get('customerId').invalid) {
        errorMessage.title = this.translationService.translate(
          'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA_VALIDATION.PROJECT_DETAILS_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate('PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA_VALIDATION.CUSTOMER_ERROR'),
          this.translationService.translate(
            'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA_VALIDATION.CUSTOMER_IS_REQUIRED'
          ),
        ];
        this.activePage = PAGES['project-details'];
      } else if (this.projectDetails.get('locationId').invalid) {
        errorMessage.title = this.translationService.translate(
          'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA_VALIDATION.PROJECT_DETAILS_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate('PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA_VALIDATION.LOCATION_ERROR'),
          this.translationService.translate(
            'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA_VALIDATION.LOCATION_IS_REQUIRED'
          ),
        ];
        this.activePage = PAGES['project-details'];
      } else if (this.projectDetails.get('projectStatusId').invalid) {
        errorMessage.title = this.translationService.translate(
          'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA_VALIDATION.PROJECT_DETAILS_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate('PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA_VALIDATION.STATUS_ERROR'),
          this.translationService.translate('PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA_VALIDATION.STATUS_IS_REQUIRED'),
        ];
        this.activePage = PAGES['project-details'];
      } else if (this.projectDetails.get('startDate').invalid) {
        errorMessage.title = this.translationService.translate(
          'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA_VALIDATION.PROJECT_DETAILS_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate('PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA_VALIDATION.START_DATE_ERROR'),
          this.translationService.translate(
            'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA_VALIDATION.START_DATE_IS_REQUIRED'
          ),
        ];
        this.activePage = PAGES['project-details'];
      } else if (this.projectDetails.get('endDate').invalid) {
        errorMessage.title = this.translationService.translate(
          'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA_VALIDATION.PROJECT_DETAILS_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate('PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA_VALIDATION.END_DATE_ERROR'),
          this.translationService.translate(
            'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA_VALIDATION.END_DATE_IS_REQUIRED'
          ),
        ];
        this.activePage = PAGES['project-details'];
      } else if (this.projectDetails.get('laborBudget').invalid) {
        errorMessage.title = this.translationService.translate(
          'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA_VALIDATION.LABOR_BUDGET_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate(
            'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA_VALIDATION.LABOR_BUDGET_IS_REQUIRED'
          ),
        ];
        this.activePage = PAGES['project-details'];
      } else if (this.projectDetails.get('laborOvertimeBudget').invalid) {
        errorMessage.title = this.translationService.translate(
          'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA_VALIDATION.LABOR_OVERTIME_BUDGET_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate(
            'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA_VALIDATION.LABOR_OVERTIME_BUDGET_IS_REQUIRED'
          ),
        ];
        this.activePage = PAGES['project-details'];
      } else if (this.projectDetails.get('equipmentBudget').invalid) {
        errorMessage.title = this.translationService.translate(
          'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA_VALIDATION.EQUIPMENT_BUDGET_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate(
            'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA_VALIDATION.EQUIPMENT_BUDGET_IS_REQUIRED'
          ),
        ];
        this.activePage = PAGES['project-details'];
      } else if (this.projectDetails.get('revenueBudget').invalid) {
        errorMessage.title = this.translationService.translate(
          'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA_VALIDATION.REVENUE_BUDGET_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate(
            'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA_VALIDATION.REVENUE_BUDGET_IS_REQUIRED'
          ),
        ];
        this.activePage = PAGES['project-details'];
      } else if (this.form.get('projectPlanningResources').invalid) {
        /**
         * Check if lines count = 0.
         */
        if (!this.projectPlanningResources.controls.length) {
          errorMessage.title = this.translationService.translate(
            'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA_VALIDATION.PROJECT_PLANNING_RESOURCES_ERROR'
          );
          errorMessage.body = [
            this.translationService.translate(
              'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA_VALIDATION.RESOURCE_LENGTH_ERROR'
            ),
          ];
          this.activePage = PAGES['project-planning-resources'];
        } else {
          /**
           * Check if some of resources has errors.
           */
          for (let index = 0; index < this.projectPlanningResources.controls.length; index++) {
            const resource = this.projectPlanningResources.controls[index];

            if (resource.valid) {
              continue;
            }

            errorMessage.title = this.translationService.translate(
              'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA_VALIDATION.RESOURCE_NUMBER_ERROR',
              {
                resourceNumber: index + 1,
              }
            );
            errorMessage.body = [];

            if (resource.get('projectPlanningResourceId').invalid) {
              errorMessage.body.push(
                this.translationService.translate(
                  'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA_VALIDATION.RESOURCES_IS_REQUIRED'
                )
              );
            }
          }
          this.activePage = PAGES['project-planning-resources'];
        }
      }

      this.form.markAllAsTouched();
      return this.notificationService.warningWithTitle(errorMessage);
    }

    this.openModal(this.confirmModalRef);
  }

  /**
   * Confirms the form submit.
   */
  confirm() {
    let startDateFormatted;
    let endDateFormatted;

    const { startDate, endDate } = this.projectDetails.value;
    if (startDate && startDate.year) {
      startDateFormatted = new Date(startDate.year, startDate.month - 1, startDate.day);
      startDateFormatted.setHours(new Date().getHours(), new Date().getMinutes());
      this.projectDetails.value.startDate = startDateFormatted;
    }

    if (endDate && endDate.year) {
      endDateFormatted = new Date(endDate.year, endDate.month - 1, endDate.day);
      endDateFormatted.setHours(new Date().getHours(), new Date().getMinutes());
      this.projectDetails.value.endDate = endDateFormatted;
    }

    /**
     * The list of project planning resources in the project planning resources form.
     */

    const projectPlanningResources = this.projectPlanningResources.value.map(
      (item: ProjectPlanningResourceFormItem) => item.projectPlanningResourceId
    );

    this.projectsManagementStore$.dispatch(
      new fromProjectsManagementStore.CreateProject({
        code: this.projectDetails.value.code,
        description: this.projectDetails.value.description,
        descriptionEn: this.projectDetails.value.descriptionEn,
        allowExceedBudget: this.projectDetails.value.allowExceedBudget,
        customerId: this.projectDetails.value.customerId,
        locationId: this.projectDetails.value.locationId,
        projectStatusId: this.projectDetails.value.projectStatusId,
        revenueBudget: this.projectDetails.value.revenueBudget,
        laborBudget: this.projectDetails.value.laborBudget,
        laborOvertimeBudget: this.projectDetails.value.laborOvertimeBudget,
        equipmentBudget: this.projectDetails.value.equipmentBudget,
        startDate: this.projectDetails.value.startDate,
        endDate: this.projectDetails.value.endDate,
        projectResourceTypes: projectPlanningResources,
      })
    );
  }

  /**
   * Updates the browser url according to the selected page.
   * @param page The newly selected page.
   */
  selectedPageChanged(page: PAGES) {
    this.locationService.replaceState(`${this.locationService.path()}#${PAGES[page]}`);
  }

  /**
   * Resets all form data.
   * It only adds one project for quick start.
   */
  resetForm() {
    this.initForm();
    this.form.markAsUntouched();
  }

  /**
   * Opens the modal of the given templateRef.
   * @param modalRef The modal templateRef to be opened.
   */
  openModal(modalRef) {
    this.modalService.open(modalRef);
  }

  /**
   * Confirms leaving the page before routing.
   */
  confirmDeactivate() {
    if (this.form.dirty === true) {
      /**
       * Message to warn the user before leaving the detailed form.
       */
      return confirm(this.translationService.translate('SHARED.CONFIRM.LEAVING'));
    }
    return true;
  }
}
