import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { Location as AngularLocation } from '@angular/common';
import { ActivatedRoute } from '@angular/router';

import { Subscription, BehaviorSubject, Observable } from 'rxjs';
import { skip, tap } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store, select } from '@ngrx/store';
import { Decimal } from 'decimal.js';
import { orderBy } from 'lodash';

import * as fromPrintingStore from 'printing/store';
import * as fromFinancesStore from 'finances/store';
import * as fromSalesStore from 'sales/store';
import * as fromAuthStore from 'auth/store';
import {
  PageInfo,
  NotificationMessage,
  CustomValidators,
  NotificationService,
  TranslationService,
  PrintService,
  APP_CONSTANTS,
} from 'shared';
import { CreateSaleReturnsProductInput, SaleInvoice } from 'sales/models';
import { Location, ProductsFormItem } from 'stores/models';
import { Account, BankAccount, CreateJournalLineInput, JournalLineFormItem } from 'finances/models';
import { CanComponentDeactivate } from 'auth/models';
import { ProductsFormPOSUtil, ProductsFormUtil } from 'stores';
import { SaleInvoiceTypes } from 'lookups/models';

/**
 * The sale returns pages.
 */
enum PAGES {
  details = 'details',
  journal = 'journal',
}

@Component({
  selector: 'app-create-sale-return',
  templateUrl: './create-sale-return.component.html',
  styles: [],
})
export class CreateSaleReturnComponent implements OnInit, OnDestroy, CanComponentDeactivate {
  /**
   * The confirm modal template reference.
   */
  @ViewChild('confirmModalRef') confirmModalRef: ElementRef<any>;

  /**
   * The decimal mask.
   */
  readonly DECIMAL_MASK = APP_CONSTANTS.numeric.decimal.mask;

  /**
   * Gets or sets the information about the current page.
   */
  pageInfo: PageInfo = {
    title: 'SALES.RETURNS.CREATE_SALE_RETURN_PAGE_TITLE',
    icon: 'fa fa-cart-plus',
  };

  /**
   * Sets the id of the sale return.
   */
  saleReturnId: number;

  /**
   * Gets or sets the selected page.
   * @default 'details'
   */
  activePage: PAGES = PAGES.details;

  /**
   * Shows or hide the locations list.
   */
  locationsListVisibility = false;

  /**
   * Shows or hide the sales invoices list.
   */
  salesInvoicesListVisibility = false;

  /**
   * Shows or hide the bank accounts list.
   */
  bankAccountsListVisibility = false;

  /**
   * Gets the trade debtors of the account.
   */
  tradeDebtorsAccount: Account;

  /**
   * Gets the main activities sales revenue of the account.
   */
  mainActivitiesSalesRevenueAccount: Account;

  /**
   * Gets the value added tax credit of the account.
   */
  valueAddedTaxCreditAccount: Account;

  /**
   * Gets the cash in banks account.
   */
  cashInBanksAccount: Account;

  /**
   * Gets the cash in hand account.
   */
  cashInHandAccount: Account;

  /**
   * Gets the bank expenses account.
   */
  bankExpensesAccount: Account;

  /**
   * Gets the value added tax debit account.
   */
  valueAddedTaxDebitAccount: Account;

  /**
   * Gets the currency rounding difference account.
   */
  currencyRoundingDifferenceAccount: Account;

  /**
   * Gets or sets a list of account ids for which the cost center id shouldn't be required in journal lines.
   */
  accountIdsNotRequireCostCenter: number[] = [];

  /**
   * The list of active sales invoices.
   */
  salesInvoices: SaleInvoice[] = [];

  /**
   * The list of active locations.
   */
  locations: Location[] = [];

  /**
   * The list of bank accounts.
   */
  bankAccounts: BankAccount[] = [];

  /**
   * The list of refund method.
   */
  refundMethods = [
    { key: 'CASH', name: 'نقدي', nameEn: 'Cash' },
    { key: 'BANK', name: 'بنكي', nameEn: 'Bank' },
  ];

  /**
   * Indicates whether there is a create-sale-returns process is running or not.
   */
  isCreating$: Observable<boolean>;

  /**
   * Used to add new product form items to the products form.
   */
  newProducts: ProductsFormItem[];

  /**
   * Sets the initial journal lines should be added to the journals form.
   * @param journal lines An object contains `count` of initial blank journal lines.
   * @param journal lines A single journal line details to be added to the form.
   * @param journal lines A list of journal lines details to be added to the form.
   */
  initialJournalLinesSubject: BehaviorSubject<
    { count: number } | JournalLineFormItem | JournalLineFormItem[]
  > = new BehaviorSubject(undefined);

  /**
   * The create sale returns form.
   */
  form: FormGroup;

  /**
   * The set of subscriptions on this components,
   * these subscriptions must be unsubscribed before this component got destroyed.
   */
  subscriptions = new Subscription();

  /**
   * Wether the sale invoice was created from a point of sale or not.
   * If `true` the net of the return should be rounded.
   */
  isPOSInvoice = false;

  /**
   * Gets the products form-array.
   */
  get productsForm(): FormArray {
    return this.form?.controls.products as FormArray;
  }

  /**
   * Gets the attachments form-array.
   */
  get attachmentsForm(): FormArray {
    return this.form?.controls.attachments as FormArray;
  }

  /**
   * Gets the journal-lines form-array.
   */

  get journalLinesForm(): FormArray {
    return this.form?.controls.lines as FormArray;
  }

  /**
   * Gets the products total without discount and tax.
   */
  get productsTotalWithoutDiscountAndTax(): number {
    return this.isPOSInvoice
      ? Decimal.sub(
          ProductsFormPOSUtil.productsTotal(this.productsForm.controls.map((item) => item.value)),
          ProductsFormPOSUtil.productsDiscount(this.productsForm.controls.map((item) => item.value))
        ).toNumber()
      : ProductsFormUtil.productsTotalWithoutDiscountAndTax(this.productsForm.controls.map((item) => item.value));
  }

  /**
   * Gets the sum of selected products taxes.
   */
  get productsTax(): number {
    return this.isPOSInvoice
      ? ProductsFormPOSUtil.productsTax(this.productsForm.controls.map((item) => item.value))
      : ProductsFormUtil.productsTax(this.productsForm.controls.map((item) => item.value));
  }

  /**
   * Gets net value of the sale return.
   */
  get saleReturnNet(): number {
    return this.isPOSInvoice
      ? ProductsFormPOSUtil.invoiceNet(this.productsForm.controls.map((item) => item.value))
      : ProductsFormUtil.invoiceNet(this.productsForm.controls.map((item) => item.value));
  }

  constructor(
    private modalService: NgbModal,
    private notificationService: NotificationService,
    private translationService: TranslationService,
    private printService: PrintService,
    private locationService: AngularLocation,
    private route: ActivatedRoute,
    private printingStore$: Store<fromPrintingStore.PrintingState>,
    private saleReturnsStore$: Store<fromSalesStore.SaleReturnsState>,
    private financesStore$: Store<fromFinancesStore.FinancesState>,
    private authStore$: Store<fromAuthStore.AuthState>
  ) {}

  ngOnInit(): void {
    this.init();
  }

  /**
   * Destroy component data
   */
  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  /**
   * Initialize component data.
   */
  init() {
    this.initForm();
    this.initJournalData();

    /**
     * Loads data.
     */
    this.isCreating$ = this.saleReturnsStore$.pipe(select(fromSalesStore.getSelectedSaleReturnCreating));

    /**
     * Print the transaction once it is ready.
     */
    this.subscriptions.add(
      this.printingStore$
        .pipe(
          select(fromPrintingStore.getSaleReturnPrint),
          skip(1),
          tap((report) => {
            if (report) {
              this.printService.printPDF(report.pdfUrl, (error, request) => {
                this.notificationService.error(this.translationService.translate('REPORTS.FAILED_TO_PRINT'));
              });
            }
          })
        )
        .subscribe()
    );

    /**
     * Gets the current created sale return id.
     */
    this.subscriptions.add(
      this.saleReturnsStore$
        .pipe(
          select(fromSalesStore.getSelectedSaleReturn),
          skip(1),
          tap((saleReturn) => {
            if (saleReturn) {
              this.print(saleReturn.id);

              this.saleReturnId = saleReturn.id;
            }
          })
        )
        .subscribe()
    );

    /**
     * Reset form controls when new sale returns is created.
     */
    this.subscriptions.add(
      this.saleReturnsStore$
        .pipe(
          select(fromSalesStore.getSelectedSaleReturnCreateCompleted),
          skip(1),
          tap((isCreated) => {
            if (isCreated) {
              this.resetForm();
            }
          })
        )
        .subscribe()
    );

    /* Subscribe to selected location to select it whenever it changed. */
    this.subscriptions.add(
      this.authStore$
        .pipe(
          select(fromAuthStore.getSessionUserDefaultLocation),
          tap((location) => {
            if (location) {
              this.selectLocation([location]);
            }
          })
        )
        .subscribe()
    );

    /** Select the user desired page. */
    this.activePage = PAGES[this.route.snapshot.fragment] ?? this.activePage;
    this.selectedPageChanged(this.activePage);
  }

  /**
   * Initialize form and add validators.
   */
  initForm() {
    const productsForm = new FormArray([], CustomValidators.arrayItems(1));
    const refundMethodKeyFormControl = new FormControl(null, Validators.required);
    const bankFeeFormControl = new FormControl(null);
    const bankFeeTaxValueFormControl = new FormControl(null);

    this.form = new FormGroup({
      saleInvoiceId: new FormControl(null, Validators.required),
      locationId: new FormControl(null, Validators.required),
      transactionDate: new FormControl(null),
      refundMethodKey: refundMethodKeyFormControl,
      isRefundForReturns: new FormControl(true),
      bankAccountId: new FormControl(null),
      bankFee: bankFeeFormControl,
      bankFeeTaxValue: bankFeeTaxValueFormControl,
      notes: new FormControl('', [Validators.minLength(0), Validators.maxLength(200)]),
      products: productsForm,
      lines: new FormArray([], CustomValidators.arrayItems(2)),
      attachments: new FormArray([]),
    });

    this.addBlankAttachment();
    this.setBlankJournalsCount();

    /**
     * Dynamically update journal lines when products change.
     */
    this.subscriptions.add(productsForm.valueChanges.subscribe(() => this.onProductsChange()));

    /**
     * Subscription when selected refund method form changes.
     */
    this.subscriptions.add(
      refundMethodKeyFormControl.valueChanges.subscribe(() => {
        this.onRefundMethodChange();
      })
    );

    this.subscriptions.add(bankFeeFormControl.valueChanges.subscribe(() => this.onProductsChange()));
    this.subscriptions.add(bankFeeTaxValueFormControl.valueChanges.subscribe(() => this.onProductsChange()));
  }

  /**
   * Initialize the journal data.
   */
  initJournalData() {
    /**
     * Fetch system accounts then fill the journal line form with its lines.
     */

    let isManualSearchTriggeredBeforeForValueAddedTaxCreditAccount = false;
    this.subscriptions.add(
      /**
       * Value added tax credit Account.
       */
      this.financesStore$
        .pipe(
          select(fromFinancesStore.getValueAddedTaxCreditAccount),
          tap((account) => {
            this.valueAddedTaxCreditAccount = account;
            if (account) {
              this.accountIdsNotRequireCostCenter = [account.id];
              this.onProductsChange();
            } else if (!account && !isManualSearchTriggeredBeforeForValueAddedTaxCreditAccount) {
              isManualSearchTriggeredBeforeForValueAddedTaxCreditAccount = true;
              this.financesStore$.dispatch(new fromFinancesStore.FindValueAddedTaxCreditAccount());
            }
          })
        )
        .subscribe()
    );

    let isManualSearchTriggeredBeforeForTradeDebtorsAccount = false;
    this.subscriptions.add(
      /**
       * Trade debtors Account.
       */
      this.financesStore$
        .pipe(
          select(fromFinancesStore.getTradeDebtorsAccount),
          tap((account) => {
            this.tradeDebtorsAccount = account;
            if (account) {
              this.onProductsChange();
            } else if (!account && !isManualSearchTriggeredBeforeForTradeDebtorsAccount) {
              isManualSearchTriggeredBeforeForTradeDebtorsAccount = true;
              this.financesStore$.dispatch(new fromFinancesStore.FindTradeDebtorsAccount());
            }
          })
        )
        .subscribe()
    );

    let isManualSearchTriggeredBeforeForMainActivitiesSalesRevenueAccount = false;
    this.subscriptions.add(
      /**
       * Main activities sales revenue Account.
       */
      this.financesStore$
        .pipe(
          select(fromFinancesStore.getMainActivitiesSalesRevenueAccount),
          tap((account) => {
            this.mainActivitiesSalesRevenueAccount = account;
            if (account) {
              this.onProductsChange();
            } else if (!account && !isManualSearchTriggeredBeforeForMainActivitiesSalesRevenueAccount) {
              isManualSearchTriggeredBeforeForMainActivitiesSalesRevenueAccount = true;
              this.financesStore$.dispatch(new fromFinancesStore.FindMainActivitiesSalesRevenueAccount());
            }
          })
        )
        .subscribe()
    );

    let isManualSearchTriggeredBeforeForCashInBanksAccount = false;
    this.subscriptions.add(
      /**
       * Cash In Banks Account.
       */
      this.financesStore$
        .pipe(
          select(fromFinancesStore.getCashInBanksAccount),
          tap((account) => {
            this.cashInBanksAccount = account;
            if (account) {
              this.onProductsChange();
            } else if (!account && !isManualSearchTriggeredBeforeForCashInBanksAccount) {
              isManualSearchTriggeredBeforeForCashInBanksAccount = true;
              this.financesStore$.dispatch(new fromFinancesStore.FindCashInBanksAccount());
            }
          })
        )
        .subscribe()
    );

    let isManualSearchTriggeredBeforeForCashInHandAccount = false;
    this.subscriptions.add(
      /**
       * Cash In Hand Account.
       */
      this.financesStore$
        .pipe(
          select(fromFinancesStore.getCashInHandAccount),
          tap((account) => {
            this.cashInHandAccount = account;
            if (account) {
              this.onProductsChange();
            } else if (!account && !isManualSearchTriggeredBeforeForCashInHandAccount) {
              isManualSearchTriggeredBeforeForCashInHandAccount = true;
              this.financesStore$.dispatch(new fromFinancesStore.FindCashInHandAccount());
            }
          })
        )
        .subscribe()
    );

    let isManualSearchTriggeredBeforeForBankExpensesAccount = false;
    this.subscriptions.add(
      /**
       * bank expenses Account.
       */
      this.financesStore$
        .pipe(
          select(fromFinancesStore.getBankExpensesAccount),
          tap((account) => {
            this.bankExpensesAccount = account;
            if (account) {
              this.onProductsChange();
            } else if (!account && !isManualSearchTriggeredBeforeForBankExpensesAccount) {
              isManualSearchTriggeredBeforeForBankExpensesAccount = true;
              this.financesStore$.dispatch(new fromFinancesStore.FindBankExpensesAccount());
            }
          })
        )
        .subscribe()
    );

    let isManualSearchTriggeredBeforeForValueAddedTaxDebitAccount = false;
    this.subscriptions.add(
      /**
       * value added tax debit Account.
       */
      this.financesStore$
        .pipe(
          select(fromFinancesStore.getValueAddedTaxDebitAccount),
          tap((account) => {
            this.valueAddedTaxDebitAccount = account;
            if (account) {
              this.onProductsChange();
            } else if (!account && !isManualSearchTriggeredBeforeForValueAddedTaxDebitAccount) {
              isManualSearchTriggeredBeforeForValueAddedTaxDebitAccount = true;
              this.financesStore$.dispatch(new fromFinancesStore.FindValueAddedTaxDebitAccount());
            }
          })
        )
        .subscribe()
    );

    let isManualSearchTriggeredBeforeForCurrencyRoundingDifferenceAccount = false;
    this.subscriptions.add(
      /**
       * Currency Rounding Difference Account.
       */
      this.financesStore$
        .pipe(
          select(fromFinancesStore.getCurrencyRoundingDifferenceAccount),
          tap((account) => {
            this.currencyRoundingDifferenceAccount = account;
            if (account) {
              this.onProductsChange();
            } else if (!account && !isManualSearchTriggeredBeforeForCurrencyRoundingDifferenceAccount) {
              isManualSearchTriggeredBeforeForCurrencyRoundingDifferenceAccount = true;
              this.financesStore$.dispatch(new fromFinancesStore.FindCurrencyRoundingDifferenceAccount());
            }
          })
        )
        .subscribe()
    );
  }

  /**
   * Submits the form.
   */
  submit() {
    const errorMessage = new NotificationMessage();

    if (this.form.invalid) {
      if (this.form.get('locationId').invalid) {
        errorMessage.title = this.translationService.translate(
          'SALES.RETURNS.SALE_RETURN_DATA_VALIDATION.LOCATION_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate('SALES.RETURNS.SALE_RETURN_DATA_VALIDATION.LOCATION_IS_REQUIRED'),
        ];
      } else if (this.form.get('saleInvoiceId').invalid) {
        errorMessage.title = this.translationService.translate(
          'SALES.RETURNS.SALE_RETURN_DATA_VALIDATION.SALE_INVOICE_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate('SALES.RETURNS.SALE_RETURN_DATA_VALIDATION.SALE_INVOICE_IS_REQUIRED'),
        ];
      } else if (this.form.get('refundMethodKey').invalid) {
        errorMessage.title = this.translationService.translate(
          'SALES.RETURNS.SALE_RETURN_DATA_VALIDATION.REFUND_METHOD_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate('SALES.RETURNS.SALE_RETURN_DATA_VALIDATION.REFUND_METHOD_IS_REQUIRED'),
        ];
      } else if (this.form.get('bankAccountId').invalid) {
        errorMessage.title = this.translationService.translate(
          'SALES.RETURNS.SALE_RETURN_DATA_VALIDATION.BANK_ACCOUNT_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate('SALES.RETURNS.SALE_RETURN_DATA_VALIDATION.BANK_ACCOUNT_IS_REQUIRED'),
        ];
      } else if (this.form.get('bankFee').invalid) {
        errorMessage.title = this.translationService.translate(
          'SALES.RETURNS.SALE_RETURN_DATA_VALIDATION.BANK_FEE_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate('SALES.RETURNS.SALE_RETURN_DATA_VALIDATION.BANK_FEE_LENGTH_ERROR'),
        ];
        this.activePage = PAGES.details;
      } else if (this.form.get('bankFeeTaxValue').invalid) {
        errorMessage.title = this.translationService.translate(
          'SALES.RETURNS.SALE_RETURN_DATA_VALIDATION.BANK_FEE_TAX_VALUE_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate(
            'SALES.RETURNS.SALE_RETURN_DATA_VALIDATION.BANK_FEE_TAX_VALUE_LENGTH_ERROR'
          ),
        ];
        this.activePage = PAGES.details;
      } else if (this.form.get('products').invalid) {
        /**
         * Check if products count = 0.
         */
        if (!this.productsForm.controls.length) {
          errorMessage.title = this.translationService.translate(
            'SALES.RETURNS.SALE_RETURN_DATA_VALIDATION.PRODUCTS_ERROR'
          );
          errorMessage.body = [
            this.translationService.translate('SALES.RETURNS.SALE_RETURN_DATA_VALIDATION.PRODUCTS_LENGTH_ERROR'),
          ];
        } else {
          /**
           * Check if some of products has errors.
           */
          for (let index = 0; index < this.productsForm.controls.length; index++) {
            const product = this.productsForm.controls[index];

            if (product.valid) {
              continue;
            }

            errorMessage.title = this.translationService.translate(
              'SALES.RETURNS.SALE_RETURN_DATA_VALIDATION.PRODUCT_NUMBER_ERROR',
              { productNumber: index + 1 }
            );
            errorMessage.body = [];

            if (product.get('productId')?.invalid) {
              errorMessage.body.push(
                this.translationService.translate('SALES.RETURNS.SALE_RETURN_DATA_VALIDATION.PRODUCT_IS_REQUIRED')
              );
            }

            if (product.get('description').invalid) {
              errorMessage.body.push(
                this.translationService.translate('SALES.RETURNS.SALE_RETURN_DATA_VALIDATION.DESCRIPTION_IS_REQUIRED')
              );
            }

            if (product.get('subProductId').invalid) {
              errorMessage.body.push(
                this.translationService.translate('SALES.RETURNS.SALE_RETURN_DATA_VALIDATION.SUB_PRODUCT_ERROR')
              );
            }

            if (product.get('quantity').invalid) {
              errorMessage.body.push(
                this.translationService.translate('SALES.RETURNS.SALE_RETURN_DATA_VALIDATION.PRODUCT_QUANTITY_ERROR')
              );
            }

            if (product.get('unitOfMeasureId').invalid) {
              errorMessage.body.push(
                this.translationService.translate(
                  'SALES.RETURNS.SALE_RETURN_DATA_VALIDATION.UNIT_OF_MEASURE_IS_REQUIRED'
                )
              );
            }

            if (product.get('value').invalid) {
              errorMessage.body.push(
                this.translationService.translate('SALES.RETURNS.SALE_RETURN_DATA_VALIDATION.PRODUCT_VALUE_ERROR')
              );
            }

            if (product.get('tax').invalid) {
              errorMessage.body.push(
                this.translationService.translate('SALES.RETURNS.SALE_RETURN_DATA_VALIDATION.TAX_ERROR')
              );
            }

            break;
          }
        }
      } else if (this.form.get('lines').invalid) {
        /**
         * Check if journal lines count < 2.
         */
        if (this.journalLinesForm.controls.length < 2) {
          errorMessage.title = this.translationService.translate(
            'SALES.RETURNS.SALE_RETURN_DATA_VALIDATION.JOURNALS_LINES_ERROR'
          );
          errorMessage.body = [
            this.translationService.translate('SALES.RETURNS.SALE_RETURN_DATA_VALIDATION.JOURNALS_LINES_COUNT_ERROR'),
          ];
        } else {
          /**
           * Check if some of journals has errors.
           */
          for (let index = 0; index < this.journalLinesForm.controls.length; index++) {
            const journal = this.journalLinesForm.controls[index];

            if (journal.valid) {
              continue;
            }

            errorMessage.title = this.translationService.translate(
              'SALES.RETURNS.SALE_RETURN_DATA_VALIDATION.JOURNAL_LINE_NUMBER_ERROR',
              {
                journalNumber: index + 1,
              }
            );
            errorMessage.body = [];

            if (journal.get('accountId').invalid) {
              errorMessage.body.push(
                this.translationService.translate('SALES.RETURNS.SALE_RETURN_DATA_VALIDATION.ACCOUNT_ERROR')
              );
              errorMessage.body.push(
                this.translationService.translate('SALES.RETURNS.SALE_RETURN_DATA_VALIDATION.ACCOUNT_IS_REQUIRED')
              );
            }

            if (journal.get('credit').invalid) {
              errorMessage.body.push(
                this.translationService.translate('SALES.RETURNS.SALE_RETURN_DATA_VALIDATION.CREDIT_IS_REQUIRED')
              );
            }

            if (journal.get('debit').invalid) {
              errorMessage.body.push(
                this.translationService.translate('SALES.RETURNS.SALE_RETURN_DATA_VALIDATION.DEBIT_IS_REQUIRED')
              );
            }

            if (journal.get('costCenterId').invalid) {
              errorMessage.body.push(
                this.translationService.translate('SALES.RETURNS.SALE_RETURN_DATA_VALIDATION.COST_CENTER_ERROR')
              );
              errorMessage.body.push(
                this.translationService.translate('SALES.RETURNS.SALE_RETURN_DATA_VALIDATION.COST_CENTER_IS_REQUIRED')
              );
            }

            if (journal.get('notes').invalid) {
              errorMessage.body.push(
                this.translationService.translate('SALES.RETURNS.SALE_RETURN_DATA_VALIDATION.NOTES_ERROR')
              );
              errorMessage.body.push(
                this.translationService.translate('SALES.RETURNS.SALE_RETURN_DATA_VALIDATION.NOTES_LENGTH_ERROR')
              );
            }

            break;
          }
        }

        this.activePage = PAGES.journal;
      }
      this.form.markAllAsTouched();
      return this.notificationService.warningWithTitle(errorMessage);
    }
    this.openModal(this.confirmModalRef);
  }

  /**
   * Confirms the form submit.
   */
  confirm() {
    let fromDateFormatted;
    const { transactionDate } = this.form.value;

    if (transactionDate) {
      fromDateFormatted = new Date(transactionDate.year, transactionDate.month - 1, transactionDate.day);
      fromDateFormatted.setHours(new Date().getHours(), new Date().getMinutes());
    }

    /**
     * The list of products in the products form.
     */
    const products = this.productsForm.value.map((item: ProductsFormItem) => {
      const inputProduct: CreateSaleReturnsProductInput = {
        productId: item.isStorable || item.isService ? item.product?.id : null,
        subProductId: item.subProductId,
        projectSubTaskId: item.projectSubTaskId,
        projectSubTask: item.projectSubTask,
        description: item.isStorable || item.isService ? null : item.description,
        quantity: item.quantity,
        unitOfMeasureId: item.unitOfMeasureId,
        value: item.value,
        discount: item.discount,
        discountPercent: item.discountPercent,
        tax: item.tax,
        notes: item.notes,
        saleInvoiceProductId: item.extraData.saleInvoiceProductId,
      };

      return inputProduct;
    });

    /**
     * The list of lines in the lines form.
     */
    const lines = this.journalLinesForm.value
      .filter((line: JournalLineFormItem) => line.credit >= 0 || line.debit >= 0)
      .map((line) => {
        const inputLine: CreateJournalLineInput = {
          accountId: line.accountId,
          debit: line.debit,
          credit: line.credit,
          costCenterId: line.costCenterId,
          notes: line.notes,
        };

        return inputLine;
      });

    this.saleReturnsStore$.dispatch(
      new fromSalesStore.CreateSaleReturn({
        locationId: this.form.value.locationId,
        saleInvoiceId: this.form.value.saleInvoiceId,
        transactionDate: fromDateFormatted,
        isRefundForReturns: this.form.value.isRefundForReturns,
        bankAccountId: this.form.value.bankAccountId,
        bankFee: this.form.value.bankFee,
        bankFeeTaxValue: this.form.value.bankFeeTaxValue,
        notes: this.form.value.notes,
        products,
        lines,
        attachments: this.attachmentsForm.value.filter((file) => !!file),
      })
    );
  }

  /**
   * Opens the modal of the given templateRef.
   * @param modalRef The modal templateRef to be opened.
   */
  openModal(modalRef) {
    this.modalService.open(modalRef);
  }

  /**
   * Resets all form data.
   */
  resetForm() {
    const locationId = this.form.value.locationId;
    const customerId = this.form.value.customerId;
    const bankAccountId = this.form.value.bankAccountId;
    const isRefundForReturns = this.form.value.isRefundForReturns;
    const refundMethodKey = this.form.value.refundMethodKey;

    this.form.reset({
      locationId,
      customerId,
      bankAccountId,
      notes: '',
      isRefundForReturns,
      refundMethodKey,
    });

    this.productsForm.clear();
    this.attachmentsForm.clear();
    this.journalLinesForm.clear();
    this.addBlankAttachment();
    this.resetJournalLines();
  }

  /**
   * Resets journal lines data.
   */
  resetJournalLines() {
    this.journalLinesForm.clear();
  }

  /**
   * Selects the newly selected location from the sales invoices search list.
   * @param salesInvoices The list of newly selected sales invoices  to select the only one in the list.
   */
  selectSalesInvoice([salesInvoice]: SaleInvoice[]) {
    if (salesInvoice) {
      this.salesInvoices = [salesInvoice];
      this.form.patchValue({ saleInvoiceId: salesInvoice.id });
      /**
       * Clear all products in the form.
       */
      this.productsForm.clear();

      this.isPOSInvoice = salesInvoice.saleInvoiceType.key === SaleInvoiceTypes.POINT_OF_SALE_INVOICE;

      /**
       * Add new products to the form from the sale invoices.
       */
      this.newProducts = salesInvoice.saleInvoiceProducts.map((saleInvoiceProduct) => {
        const item: ProductsFormItem = {
          isStorable: saleInvoiceProduct.product && !saleInvoiceProduct.product.isService,
          isService: saleInvoiceProduct.product && saleInvoiceProduct.product.isService,
          isNonStorable: !saleInvoiceProduct.productId,
          product: saleInvoiceProduct.product,
          subProductId: saleInvoiceProduct.subProductId,
          projectSubTaskId: saleInvoiceProduct.projectSubTaskId,
          projectSubTask: saleInvoiceProduct.projectSubTask,
          description: saleInvoiceProduct.description,
          quantity: Decimal.sub(saleInvoiceProduct.quantity, saleInvoiceProduct.returnedQuantity).toNumber(),
          unitOfMeasureId: saleInvoiceProduct.unitOfMeasureId,
          unitOfMeasure: saleInvoiceProduct.unitOfMeasure,
          value: this.isPOSInvoice
            ? saleInvoiceProduct.valueWithTax
            : Decimal.sub(saleInvoiceProduct.value, saleInvoiceProduct.totalDiscount).toNumber(),
          tax: saleInvoiceProduct.tax,
          discount: this.isPOSInvoice ? saleInvoiceProduct.discount : 0,
          discountPercent: this.isPOSInvoice ? saleInvoiceProduct.discountPercent : 0,
          extraData: {
            saleInvoiceProductId: saleInvoiceProduct.id,
          },
        };
        return item;
      });
    }
  }

  /**
   * Adds a blank attachment form-control to the attachments form for quick start..
   */
  addBlankAttachment() {
    this.attachmentsForm.push(new FormControl());
  }

  /**
   * Sets the count of the journal lines should be added to the journals form for quick start..
   */
  setBlankJournalsCount() {
    this.initialJournalLinesSubject.next({ count: 0 });
  }

  /**
   * Updates the browser url according to the selected page.
   * @param page The newly selected page.
   */
  selectedPageChanged(page: PAGES) {
    this.locationService.replaceState(`${this.locationService.path()}#${PAGES[page]}`);
  }

  /**
   * Selects the newly selected location from the locations search list.
   * @param locations The list of newly selected locations to select the only one in the list.
   */
  selectLocation([location]: Location[]) {
    if (location) {
      this.locations = [location];
      this.form.patchValue({ locationId: location.id });
    }
  }

  /**
   * Selects the newly selected bank account from the bank accounts search list.
   * @param bankAccounts The list of newly selected bank accounts to select the only one in the list.
   */
  selectBankAccount([bankAccount]: BankAccount[]) {
    if (bankAccount) {
      this.bankAccounts = [bankAccount];
      this.form.patchValue({ bankAccountId: bankAccount.id });
    }
  }

  /**
   * Updates the journal lines debit & credit values or even add or remove lines
   * as a response for the purchase returns products.
   */
  onProductsChange() {
    const PaymentOfRefundForReturns = this.form.controls.isRefundForReturns.value;
    const refundMethodKey = this.form.controls.refundMethodKey.value;
    const bankFee = new Decimal(this.form.controls.bankFee.value ?? 0).toNumber();
    const bankFeeTaxValue = new Decimal(this.form.controls.bankFeeTaxValue.value ?? 0).toNumber();

    const bankFeesAndTax = Decimal.sum(bankFee, bankFeeTaxValue).toNumber();

    /**
     * Set Journal Lines based on the selected purchase invoice type.
     */
    this.resetJournalLines();

    let totalDebits = new Decimal(0);
    let totalCredits = new Decimal(0);

    /**
     * The list of journal lines to be added to the form.
     */
    let lines: JournalLineFormItem[] = [];

    /**
     * Add line for main activities sales revenue Account.
     */
    if (this.mainActivitiesSalesRevenueAccount) {
      lines.push({
        accountId: this.mainActivitiesSalesRevenueAccount.id,
        account: this.mainActivitiesSalesRevenueAccount,
        costCenterId: null,
        credit: 0,
        debit: this.productsTotalWithoutDiscountAndTax,
        notes: '',
        disableAccountChange: true,
        disableDebitAndCreditValueChange: true,
      });

      totalDebits = totalDebits.add(this.productsTotalWithoutDiscountAndTax);
    }

    /**
     * Add line for value added tax credit Account.
     */
    if (this.valueAddedTaxCreditAccount && this.productsTax > 0) {
      lines.push({
        accountId: this.valueAddedTaxCreditAccount.id,
        account: this.valueAddedTaxCreditAccount,
        costCenterId: null,
        credit: 0,
        debit: this.productsTax,
        notes: '',
        disableAccountChange: true,
        disableDebitAndCreditValueChange: true,
      });

      totalDebits = totalDebits.add(this.productsTax);
    }

    /**
     * Add line for trade debtors Account.
     */
    if (this.tradeDebtorsAccount && !PaymentOfRefundForReturns) {
      lines.push({
        accountId: this.tradeDebtorsAccount.id,
        account: this.tradeDebtorsAccount,
        costCenterId: null,
        credit: this.saleReturnNet,
        debit: 0,
        notes: '',
        disableAccountChange: true,
        disableDebitAndCreditValueChange: true,
      });

      totalCredits = totalCredits.add(this.saleReturnNet);
    }

    /**
     * Add line for cash in banks Account.
     */
    if (
      this.cashInBanksAccount &&
      this.bankExpensesAccount &&
      this.valueAddedTaxDebitAccount &&
      PaymentOfRefundForReturns &&
      refundMethodKey === 'BANK'
    ) {
      /**
       * Add line for bank expenses account.
       */
      if (bankFee > 0) {
        lines.push({
          accountId: this.bankExpensesAccount.id,
          account: this.bankExpensesAccount,
          costCenterId: null,
          credit: 0,
          debit: bankFee,
          notes: '',
          disableAccountChange: true,
          disableDebitAndCreditValueChange: true,
        });

        totalDebits = totalDebits.add(bankFee);
      }

      /**
       * Add line for value added tex debit account.
       */
      if (bankFeeTaxValue > 0) {
        lines.push({
          accountId: this.valueAddedTaxDebitAccount.id,
          account: this.valueAddedTaxDebitAccount,
          costCenterId: null,
          credit: 0,
          debit: bankFeeTaxValue,
          notes: '',
          disableAccountChange: true,
          disableDebitAndCreditValueChange: true,
        });

        totalDebits = totalDebits.add(bankFeeTaxValue);
      }

      lines.push({
        accountId: this.cashInBanksAccount.id,
        account: this.cashInBanksAccount,
        costCenterId: null,
        credit: Decimal.add(this.saleReturnNet, bankFeesAndTax).toNumber(),
        debit: 0,
        notes: '',
        disableAccountChange: true,
        disableDebitAndCreditValueChange: true,
      });

      totalCredits = Decimal.sum(totalCredits, this.saleReturnNet, bankFeesAndTax);
    }

    /**
     * Add line for cash in hand Account.
     */
    if (this.cashInHandAccount && PaymentOfRefundForReturns && refundMethodKey === 'CASH') {
      lines.push({
        accountId: this.cashInHandAccount.id,
        account: this.cashInHandAccount,
        costCenterId: null,
        credit: this.saleReturnNet,
        debit: 0,
        notes: '',
        disableAccountChange: true,
        disableDebitAndCreditValueChange: true,
      });

      totalCredits = totalCredits.add(this.saleReturnNet);
    }

    /**
     * Adjusts the balance of total journal lines to be equal.
     */
    if (this.currencyRoundingDifferenceAccount && !totalDebits.equals(totalCredits)) {
      const isDebitNeed = totalCredits.gt(totalDebits);

      lines.push({
        accountId: this.currencyRoundingDifferenceAccount.id,
        account: this.currencyRoundingDifferenceAccount,
        costCenterId: null,
        credit: isDebitNeed ? 0 : totalDebits.sub(totalCredits).toNumber(),
        debit: isDebitNeed ? totalCredits.sub(totalDebits).toNumber() : 0,
        notes: '',
        disableAccountChange: true,
        disableDebitAndCreditValueChange: true,
      });
    }

    /**
     * Reorder invoice journal lines to get debits first.
     */
    lines = orderBy(lines, ['debit', 'credit'], ['desc', 'desc']);

    this.initialJournalLinesSubject.next(lines);
  }

  /**
   * Sets the conditional validation for the form based on the check value of is refund for returns.
   */
  onIsRefundForReturnsChange() {
    /**
     * The value of the is refund for returns.
     */
    const isRefundForReturns = this.form.value.isRefundForReturns;

    /**
     * Check if the is refund for returns true or false.
     */
    if (isRefundForReturns) {
      this.form.controls.refundMethodKey.setValidators([Validators.required]);
    } else {
      this.form.controls.refundMethodKey.clearValidators();
      this.form.controls.refundMethodKey.setValue(null);
    }

    this.form.controls.refundMethodKey.updateValueAndValidity();

    this.onProductsChange();
  }

  /**
   * Sets the conditional validation for the form based on the selected refund method key.
   */
  onRefundMethodChange() {
    /**
     * The key of the current selected refund method.
     */
    const refundMethodKey = this.form.controls.refundMethodKey.value;

    /**
     * Check if the refund method is cash or bank.
     */
    if (refundMethodKey === 'CASH' || refundMethodKey === 'BANK') {
      this.form.controls.bankAccountId.setValidators([Validators.required]);
    } else {
      this.form.controls.bankAccountId.clearValidators();
      this.form.controls.bankAccountId.setValue(null);

      this.form.controls.bankFee.clearValidators();
      this.form.controls.bankFee.setValue(null);

      this.form.controls.bankFeeTaxValue.clearValidators();
      this.form.controls.bankFeeTaxValue.setValue(null);
    }

    if (refundMethodKey === 'BANK') {
      this.form.controls.bankFee.setValidators([Validators.required, CustomValidators.gte(0)]);
      this.form.controls.bankFee.setValue(0);

      this.form.controls.bankFeeTaxValue.setValidators([Validators.required, CustomValidators.gte(0)]);
      this.form.controls.bankFeeTaxValue.setValue(0);
    }

    this.form.controls.bankAccountId.updateValueAndValidity();
    this.form.controls.bankFee.updateValueAndValidity();
    this.form.controls.bankFeeTaxValue.updateValueAndValidity();

    this.onProductsChange();
  }

  /**
   * Confirms leaving the page before routing.
   */
  confirmDeactivate() {
    if (this.form.dirty === true) {
      /**
       * Message to warn the user before leaving the detailed form.
       */
      return confirm(this.translationService.translate('SHARED.CONFIRM.LEAVING'));
    }
    return true;
  }

  /**
   * prints the current displayed sale return.
   */
  print(saleReturnId: number): void {
    this.printingStore$.dispatch(
      new fromPrintingStore.GetSaleReturnPrint({
        saleReturnId: saleReturnId,
      })
    );
  }
}
