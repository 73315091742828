/**
 * The set of system supported position types.
 */
export enum PositionTypes {
  /**
   * The position type.
   */
  SINGLE_INCUMBENT = 'SINGLE_INCUMBENT',
  SHARED_POSITION = 'SHARED_POSITION',
  POOLED_POSITION = 'POOLED_POSITION',
  NONE = 'NONE',
}
