import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';

import { AppHttpResponse } from 'shared';
import { CreateRefundRequestApprovalInput, PettyCashRefundRequestApproval } from 'finances/models';

/**
 * The petty cash refund request approvals services includes functionality to create, search and findById
 * a petty cash refund request approvals.
 */

@Injectable()
export class PettyCashRefundRequestApprovalsService {
  /**
   * The relative route for the petty cash refund request approvals.
   *
   * No leading or trailing slashes required.
   */
  private pettyCashRefundRequestApprovalsApi = 'finances/pettyCash/refunds/requests/approvals';

  constructor(private http: HttpClient) {}

  /**
   * Creates a new petty cash refund request approvals from the provided data.
   * @param data The new petty cash refund request approvals data.
   */
  public create(data: CreateRefundRequestApprovalInput): Observable<AppHttpResponse<PettyCashRefundRequestApproval>> {
    const formData: any = new FormData();
    formData.append('pettyCashRefundRequestId', data.pettyCashRefundRequestId);
    formData.append('pettyCashRefundRequestVersion', data.pettyCashRefundRequestVersion);
    formData.append('transactionDate', data.transactionDate?.toISOString());
    formData.append('notes', data.notes);

    /**
     * Append petty cash refund requests to the form data.
     */

    for (let index = 0; index < data.pettyCashRefundRequestLines.length; index++) {
      const pettyCashRefundRequestLinesArray = [];
      pettyCashRefundRequestLinesArray.push(data.pettyCashRefundRequestLines[index]);
      formData.append(`pettyCashRefundRequestLines[${index}]`, pettyCashRefundRequestLinesArray);
    }

    /**
     * Append journal lines to the form data.
     */
    for (let index = 0; index < data.journalLines.length; index++) {
      formData.append(`journalLines[${index}][accountId]`, data.journalLines[index].accountId);
      formData.append(`journalLines[${index}][credit]`, data.journalLines[index].credit);
      formData.append(`journalLines[${index}][debit]`, data.journalLines[index].debit);
      formData.append(`journalLines[${index}][costCenterId]`, data.journalLines[index].costCenterId);
      formData.append(`journalLines[${index}][notes]`, data.journalLines[index].notes);
    }

    /**
     * Append attachments to the form data.
     */
    data.attachments?.forEach((attachment) =>
      formData.append(`pettyCashRefundRequestLineAttachments[]`, attachment, attachment.name)
    );

    return this.http.post<AppHttpResponse<PettyCashRefundRequestApproval>>(
      `${this.pettyCashRefundRequestApprovalsApi}`,
      formData
    );
  }

  /**
   * Searches in the petty cash refund request approval by pettyCashRefundRequest or fromDate or toDate.
   * @param pettyCashRefundRequest The petty cash refund request.
   * @param fromDate The fromDate of the petty cash refund request approval.
   * @param toDate The toDate of the petty cash refund request approval.
   * @param page The current pagination page number.
   * @param pageSize The maximum number of petty cash refund request approval allowed per one pagination page.
   */
  public search(
    pettyCashRefundRequests: number[],
    fromDate: Date,
    toDate: Date,
    page: number,
    pageSize: number
  ): Observable<AppHttpResponse<PettyCashRefundRequestApproval[]>> {
    const params = new HttpParams()
      .set('pettyCashRefundRequests', pettyCashRefundRequests.join(','))
      .set('fromDate', fromDate.toISOString())
      .set('toDate', toDate.toISOString())
      .set('page', page.toString())
      .set('pageSize', pageSize.toString());
    return this.http.get<AppHttpResponse<PettyCashRefundRequestApproval[]>>(
      `${this.pettyCashRefundRequestApprovalsApi}`,
      {
        params,
      }
    );
  }

  /**
   * Finds the petty cash refund request approval with the given id.
   * @param id The id of the petty cash refund request.
   */
  public findById(id: number): Observable<AppHttpResponse<PettyCashRefundRequestApproval>> {
    return this.http.get<AppHttpResponse<PettyCashRefundRequestApproval>>(
      `${this.pettyCashRefundRequestApprovalsApi}/${id}`
    );
  }
}
