import { Component, Input, Output, EventEmitter, ElementRef, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { PettyCashRefundRequestLine, PettyCashRefundRequestLineAttachment } from 'finances/models';

@Component({
  selector: '[app-petty-cash-refund-request-approval-form-item]',
  templateUrl: './petty-cash-refund-request-approval-form-item.component.html',
  styles: [],
})
export class PettyCashRefundRequestApprovalFormItemComponent {
  /**
   * Sets the line index in the lines-form.
   */
  @Input() index: number;

  /**
   * Sets the line form-group.
   */
  @Input() lineGroup: FormGroup;

  /**
   * Emits a value when the user wants to remove current invoice from the list.
   */
  @Output() removeChange = new EventEmitter<any>();

  /**
   * The confirm view attachments modal template reference.
   */
  @ViewChild('viewAttachmentsModalRef') viewAttachmentsModalRef: ElementRef<any>;

  /**
   * Get selected petty cash refund requests approval.
   */
  selectedLine: PettyCashRefundRequestLine;

  /**
   * @param modalService The modal service.
   */
  constructor(private modalService: NgbModal) {}

  /**
   * Remove the line from the list.
   */
  remove() {
    this.removeChange.emit(null);
  }

  /**
   * Opens the modal of the given templateRef.
   * @param modalRef The modal templateRef to be opened.
   */
  openModal(modalRef) {
    this.modalService.open(modalRef);
  }

  /**
   * Maps and returns the set of petty cash refund request line attachments into a set of urls.
   * @param attachments The petty cash refund request line attachments to be mapped.
   */
  getAttachmentsUrls(attachments: PettyCashRefundRequestLineAttachment[]): string[] {
    return attachments ? attachments.map((attachment) => attachment.url) : [];
  }
}
