import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import { Observable, Subscription } from 'rxjs';
import { skip, tap } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { PageInfo, NotificationService, NotificationMessage, TranslationService } from 'shared';
import * as fromFinancesStore from 'finances/store';
import * as fromLookupsStore from 'lookups/store';
import { PrimaryAccount } from 'lookups/models';
import { Account } from 'finances/models';

@Component({
  selector: 'app-create-account',
  templateUrl: './create-account.component.html',
  styles: [],
})
export class CreateAccountComponent implements OnInit, OnDestroy {
  /**
   * The confirm modal template reference.
   */
  @ViewChild('confirmModalRef') confirmModalRef: ElementRef<any>;

  /**
   * Gets or sets the information about the current page.
   */
  pageInfo: PageInfo = {
    title: 'FINANCES.ACCOUNTS.CREATE_ACCOUNT_PAGE_TITLE',
    icon: 'fa fa-plus',
  };

  /**
   * The list of primary accounts.
   */
  primaryAccounts$: Observable<PrimaryAccount[]>;

  /**
   * The list of selected secondary accounts.
   */
  secondaryAccounts: Account[] = [];

  /**
   * Indicates whether there is a create account process is running or not.
   */
  isCreating$: Observable<boolean>;

  /**
   * The create account form.
   */
  form: FormGroup;

  /**
   * Shows or hide the secondary accounts list.
   */
  secondaryAccountsListVisibility = false;

  /**
   * The set of subscriptions on this components,
   * these subscriptions must be unsubscribed before this component got destroyed.
   */
  subscriptions = new Subscription();

  /**
   * @param modalService The modal service.
   * @param notificationService The notification service.
   * @param financesStore$ The finances store.
   * @param lookupsStore$ The lookups store.
   * @param translationService The translation service.
   */
  constructor(
    private modalService: NgbModal,
    private notificationService: NotificationService,
    private financesStore$: Store<fromFinancesStore.FinancesState>,
    private lookupsStore$: Store<fromLookupsStore.LookupsState>,
    private translationService: TranslationService
  ) {}

  ngOnInit(): void {
    this.init();
  }

  /**
   * Destroy component data
   */
  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  /**
   * Initialize component data.
   */
  init() {
    this.initForm();

    /**
     * Reset form controls when new account is created.
     */
    this.subscriptions.add(
      this.financesStore$
        .pipe(
          select(fromFinancesStore.getSelectedAccountCreateCompleted),
          skip(1),
          tap((isCreated) => {
            if (isCreated) {
              this.initForm();
            }
          })
        )
        .subscribe()
    );

    /**
     * Load data.
     */
    this.isCreating$ = this.financesStore$.pipe(select(fromFinancesStore.getSelectedAccountCreating));

    let isManualSearchTriggeredBeforeForPrimaryAccounts = false;
    this.primaryAccounts$ = this.lookupsStore$.pipe(
      select(fromLookupsStore.getPrimaryAccounts),
      tap((primaryAccounts) => {
        if (!isManualSearchTriggeredBeforeForPrimaryAccounts && !primaryAccounts.length) {
          isManualSearchTriggeredBeforeForPrimaryAccounts = true;
          this.lookupsStore$.dispatch(new fromLookupsStore.GetAllPrimaryAccount());
        }
      })
    );
  }

  /**
   * Initialize form and add validators.
   */
  initForm() {
    this.form = new FormGroup({
      code: new FormControl('', [Validators.required, Validators.minLength(1), Validators.maxLength(50)]),
      description: new FormControl('', [Validators.required, Validators.minLength(1), Validators.maxLength(100)]),
      primaryAccountId: new FormControl(null, Validators.required),
      secondaryAccountId: new FormControl(null, Validators.required),
    });
  }

  /**
   * Submits the form.
   */
  submit() {
    if (this.form.invalid) {
      const errorMessage = new NotificationMessage();

      if (this.form.get('code').invalid) {
        errorMessage.title = this.translationService.translate(
          'FINANCES.ACCOUNTS.ACCOUNT_DATA_VALIDATION.ACCOUNT_CODE_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate('FINANCES.ACCOUNTS.ACCOUNT_DATA_VALIDATION.ACCOUNT_CODE_IS_REQUIRED'),
          this.translationService.translate('FINANCES.ACCOUNTS.ACCOUNT_DATA_VALIDATION.ACCOUNT_CODE_LENGTH_ERROR'),
        ];
      } else if (this.form.get('description').invalid) {
        errorMessage.title = this.translationService.translate(
          'FINANCES.ACCOUNTS.ACCOUNT_DATA_VALIDATION.ACCOUNT_DESCRIPTION_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate(
            'FINANCES.ACCOUNTS.ACCOUNT_DATA_VALIDATION.ACCOUNT_DESCRIPTION_IS_REQUIRED'
          ),
          this.translationService.translate(
            'FINANCES.ACCOUNTS.ACCOUNT_DATA_VALIDATION.ACCOUNT_DESCRIPTION_LENGTH_ERROR'
          ),
        ];
      } else if (this.form.get('primaryAccountId').invalid) {
        errorMessage.title = this.translationService.translate(
          'FINANCES.ACCOUNTS.ACCOUNT_DATA_VALIDATION.PRIMARY_ACCOUNT_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate('FINANCES.ACCOUNTS.ACCOUNT_DATA_VALIDATION.PRIMARY_ACCOUNT_IS_REQUIRED'),
        ];
      } else if (this.form.get('secondaryAccountId').invalid) {
        errorMessage.title = this.translationService.translate(
          'FINANCES.ACCOUNTS.ACCOUNT_DATA_VALIDATION.SECONDARY_ACCOUNT_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate('FINANCES.ACCOUNTS.ACCOUNT_DATA_VALIDATION.SECONDARY_ACCOUNT_IS_REQUIRED'),
        ];
      }

      this.form.markAllAsTouched();
      return this.notificationService.warningWithTitle(errorMessage);
    } else {
      this.openModal(this.confirmModalRef);
    }
  }

  /**
   * Confirms the form submit.
   */
  confirm() {
    this.financesStore$.dispatch(
      new fromFinancesStore.CreateAccount({
        ...this.form.value,
      })
    );
  }

  /**
   * Opens the modal of the given templateRef.
   * @param modalRef The modal templateRef to be opened.
   */
  openModal(modalRef) {
    this.modalService.open(modalRef);
  }

  /**
   * Selects the newly selected secondary account from the accounts search list.
   * @param secondaryAccount The list of newly selected secondary account to select the only one in the list.
   */
  selectSecondaryAccount([secondaryAccount]: Account[]) {
    if (secondaryAccount) {
      this.secondaryAccounts = [secondaryAccount];
      this.form.patchValue({ secondaryAccountId: secondaryAccount.id });
    }
  }
}
