<div class="row">
  <div class="col-md-12">
    <!-- project resource details starts -->
    <div class="card-header mb-3">
      <h4 class="card-title">{{ 'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.RESOURCES' | translate }}</h4>
    </div>
    <div class="card-content">
      <div class="px-3">
        <div class="row">
          <div class="form-group col-md-6">
            <label for="description">{{ 'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.PROJECT_TITLE' | translate }}</label>
            <input
              type="text"
              id="description"
              class="form-control round"
              [value]="projectResource.project?.description | localize: projectResource.project?.descriptionEn | async"
              readonly
            />
          </div>
          <div class="form-group col-md-6">
            <label for="projectMainTaskId">{{
              'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.PROJECT_MAIN_TASK' | translate
            }}</label>
            <input
              type="text"
              id="projectMainTaskId"
              class="form-control round"
              [value]="
                projectResource.projectSubTask?.projectMainTask?.name
                  | localize: projectResource.projectSubTask?.projectMainTask?.nameEn
                  | async
              "
              readonly
            />
          </div>
          <div class="form-group col-md-6">
            <label for="projectSubTaskId">{{
              'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.PROJECT_SUB_TASK' | translate
            }}</label>
            <input
              type="text"
              id="projectSubTaskId"
              class="form-control round"
              [value]="projectResource.projectSubTask?.name | localize: projectResource.projectSubTask?.nameEn | async"
              readonly
            />
          </div>
          <div class="form-group col-md-6">
            <label for="projectResourceTypeId">{{
              'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.RESOURCE_TYPE' | translate
            }}</label>
            <input
              type="text"
              id="projectResourceTypeId"
              class="form-control round"
              value="{{
                projectResource.projectResourceType.name | localize: projectResource.projectResourceType.nameEn | async
              }} - {{
                projectResource.projectResourceTypeSource?.name
                  | localize: projectResource.projectResourceTypeSource?.nameEn
                  | async
              }}"
              readonly
            />
          </div>
          <div class="form-group col-md-6">
            <label for="value">{{ 'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.VALUE' | translate }}</label>
            <input
              type="text"
              id="value"
              class="form-control round"
              [value]="projectResource.value | toDecimal"
              readonly
            />
          </div>
          <div class="form-group col-md-6">
            <label for="transactionDate">{{
              'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.TRANSACTION_DATE' | translate
            }}</label>
            <input
              type="text"
              id="transactionDate"
              class="form-control round"
              [value]="projectResource.transactionDate | date: 'datetime'"
              readonly
            />
          </div>
          <div class="form-group col-md-6">
            <label for="createdAt">{{ 'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.CREATED_AT' | translate }}</label>
            <input
              type="text"
              id="createdAt"
              class="form-control round"
              [value]="projectResource.createdAt | date: 'datetime'"
              readonly
            />
          </div>
          <div class="form-group col-md-6">
            <label for="user">{{ 'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.USER' | translate }}</label>
            <input type="text" id="user" class="form-control round" [value]="projectResource.user.name" readonly />
          </div>
        </div>
      </div>
    </div>
    <!-- project resource details ends -->
  </div>
</div>

<div class="row mt-2">
  <div class="form-group col-12 d-flex justify-content-end flex-column flex-sm-row mt-2">
    <a class="btn btn-dark round" routerLink="/projects-management/projects">
      <i class="fa fa-undo"></i>
      <span class="mx-1">{{ 'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.PROJECTS_RETURN' | translate }}</span>
    </a>
  </div>
</div>
