import { Injectable } from '@angular/core';

import { Effect, ofType, Actions } from '@ngrx/effects';
import { switchMap, map, catchError, tap } from 'rxjs/operators';
import { of } from 'rxjs';

import { NotificationService, StatusCode, PAGINATION, TranslationService } from 'shared';
import { SaleReturnsService } from 'sales/services';
import {
  SearchSaleReturns,
  SearchSaleReturnsFail,
  SearchSaleReturnsSuccess,
  FindSaleReturn,
  FindSaleReturnFail,
  FindSaleReturnSuccess,
  CreateSaleReturn,
  CreateSaleReturnFail,
  CreateSaleReturnSuccess,
  SaleReturnsActionType,
} from 'sales/store/actions';

@Injectable()
export class SaleReturnsEffects {
  constructor(
    private actions$: Actions,
    private saleReturnsService: SaleReturnsService,
    private notificationService: NotificationService,
    private translationService: TranslationService
  ) {}

  /* ========================= SEARCH_SALE_RETURNS =================================== */
  @Effect()
  search$ = this.actions$.pipe(
    ofType(SaleReturnsActionType.SEARCH_SALE_RETURNS),
    switchMap(({ payload }: SearchSaleReturns) => {
      return this.saleReturnsService
        .search(
          payload.customers,
          payload.locations,
          payload.saleReturnNum,
          payload.invoiceNum,
          payload.fromDate,
          payload.toDate,
          payload.page,
          PAGINATION.SaleReturns
        )
        .pipe(
          map((response) => new SearchSaleReturnsSuccess(response)),
          catchError((error) => of(new SearchSaleReturnsFail(error)))
        );
    })
  );

  /* ========================= FIND_SALE_RETURNS =================================== */
  @Effect()
  find$ = this.actions$.pipe(
    ofType(SaleReturnsActionType.FIND_SALE_RETURN),
    switchMap((action: FindSaleReturn) =>
      this.saleReturnsService.findById(action.payload).pipe(
        map((response) => new FindSaleReturnSuccess(response)),
        catchError((error) => of(new FindSaleReturnFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  findFail$ = this.actions$.pipe(
    ofType(SaleReturnsActionType.FIND_SALE_RETURN_FAIL),
    tap((action: FindSaleReturnFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorNotFound) {
        this.notificationService.error(this.translationService.translate('SALES.RETURNS.SALE_RETURN_NOT_FOUND'));
      }
    })
  );

  /* ========================= CREATE_SALE_RETURNS =================================== */
  @Effect()
  create$ = this.actions$.pipe(
    ofType(SaleReturnsActionType.CREATE_SALE_RETURN),
    switchMap((action: CreateSaleReturn) =>
      this.saleReturnsService.create(action.payload).pipe(
        map((response) => new CreateSaleReturnSuccess(response)),
        catchError((error) => of(new CreateSaleReturnFail(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  createSuccess$ = this.actions$.pipe(
    ofType(SaleReturnsActionType.CREATE_SALE_RETURN_SUCCESS),
    tap((action: CreateSaleReturnSuccess) => {
      this.notificationService.success(
        this.translationService.translate('SALES.RETURNS.SALE_RETURN_ADDED', {
          returnNumber: action.payload.data.saleReturnNum,
        })
      );
    })
  );

  @Effect({ dispatch: false })
  createFail$ = this.actions$.pipe(
    ofType(SaleReturnsActionType.CREATE_SALE_RETURN_FAIL),
    tap((action: CreateSaleReturnFail) => {
      if (action.payload.statusCode === StatusCode.ClientErrorBadRequest) {
        this.notificationService.warning(...action.payload.errors?.map((error) => error.detail));
      }
    })
  );
}
