import { SalesReportsActionType, SalesReportsActions } from 'reports/store/actions';
import { SalesReportsState } from 'reports/store/states';

/**
 * The initial state from which the state starts.
 */
const initialState: SalesReportsState = {
  customerSummary: null,
  customersDebts: null,
  customersPayments: null,
  customersPoints: null,
  grossProfit: null,
  maintenanceStageInvoice: null,
  topReturnedSalesProducts: null,
  netSales: null,
  productSales: null,
  productSalesReturns: null,
  productClassesSales: null,
  productsSales: null,
  productsSalesReturns: null,
  salesInvoices: null,
  salesReturns: null,
  salesSummary: null,
  shiftSummary: null,
  topCustomers: null,
  topSellingProducts: null,
  worstCustomers: null,
  worstReturnedSalesProducts: null,
  worstSellingProducts: null,
  salesPerson: null,
  error: null,
  isLoadingCustomerSummary: false,
  isCustomerSummaryLoadCompleted: false,
  isLoadingCustomersDebts: false,
  isCustomersDebtsLoadCompleted: false,
  isLoadingCustomersPayments: false,
  isCustomersPaymentsLoadCompleted: false,
  isLoadingCustomersPoints: false,
  isCustomersPointsLoadCompleted: false,
  isLoadingGrossProfit: false,
  isGrossProfitLoadCompleted: false,
  isLoadingMaintenanceStageInvoice: false,
  isMaintenanceStageInvoiceLoadCompleted: false,
  isLoadingTopReturnedSalesProducts: false,
  isTopReturnedSalesProductsLoadCompleted: false,
  isLoadingNetSales: false,
  isNetSalesLoadCompleted: false,
  isLoadingProductSales: false,
  isProductSalesLoadCompleted: false,
  isLoadingProductSalesReturns: false,
  isProductSalesReturnsLoadCompleted: false,
  isLoadingProductClassesSales: false,
  isProductClassesSalesLoadCompleted: false,
  isLoadingProductsSales: false,
  isProductsSalesLoadCompleted: false,
  isLoadingProductsSalesReturns: false,
  isProductsSalesReturnsLoadCompleted: false,
  isLoadingSalesInvoices: false,
  isSalesInvoicesLoadCompleted: false,
  isLoadingSalesReturns: false,
  isSalesReturnsLoadCompleted: false,
  isLoadingSalesSummary: false,
  isSalesSummaryLoadCompleted: false,
  isLoadingShiftSummary: false,
  isShiftSummaryLoadCompleted: false,
  isLoadingTopCustomers: false,
  isTopCustomersLoadCompleted: false,
  isLoadingTopSellingProducts: false,
  isTopSellingProductsLoadCompleted: false,
  isLoadingWorstCustomers: false,
  isWorstCustomersLoadCompleted: false,
  isLoadingWorstReturnedSalesProducts: false,
  isWorstReturnedSalesProductsLoadCompleted: false,
  isLoadingWorstSellingProducts: false,
  isWorstSellingProductsLoadCompleted: false,
  isLoadingSalesPerson: false,
  isSalesPersonLoadCompleted: false,
};

/**
 * The reducer function that is called in each action dispatch against the state.
 * @param state The current state.
 * @param action The action that will affect the state.
 */
export function salesReportsReducer(
  state: SalesReportsState = initialState,
  action: SalesReportsActions
): SalesReportsState {
  switch (action.type) {
    //#region GET_CUSTOMER_SUMMARY_REPORT

    case SalesReportsActionType.GET_CUSTOMER_SUMMARY_REPORT: {
      return {
        ...state,
        customerSummary: null,
        isLoadingCustomerSummary: true,
        isCustomerSummaryLoadCompleted: false,
        error: null,
      };
    }

    case SalesReportsActionType.GET_CUSTOMER_SUMMARY_REPORT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isLoadingCustomerSummary: false,
        isCustomerSummaryLoadCompleted: false,
      };
    }

    case SalesReportsActionType.GET_CUSTOMER_SUMMARY_REPORT_RECEIVED: {
      return {
        ...state,
        error: null,
        isLoadingCustomerSummary: false,
        isCustomerSummaryLoadCompleted: true,
      };
    }

    case SalesReportsActionType.GET_CUSTOMER_SUMMARY_REPORT_SUCCESS: {
      return {
        ...state,
        customerSummary: action.payload.data,
        error: null,
        isLoadingCustomerSummary: false,
        isCustomerSummaryLoadCompleted: true,
      };
    }

    case SalesReportsActionType.CUSTOMER_SUMMARY_REPORT_VIEW: {
      return {
        ...state,
        customerSummary: action.payload,
        error: null,
        isLoadingCustomerSummary: false,
        isCustomerSummaryLoadCompleted: true,
      };
    }

    //#endregion CUSTOMER_SUMMARY

    //#region GET_CUSTOMERS_DEBTS_REPORT

    case SalesReportsActionType.GET_CUSTOMERS_DEBTS_REPORT: {
      return {
        ...state,
        customersDebts: null,
        isLoadingCustomersDebts: true,
        isCustomersDebtsLoadCompleted: false,
        error: null,
      };
    }

    case SalesReportsActionType.GET_CUSTOMERS_DEBTS_REPORT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isLoadingCustomersDebts: false,
        isCustomersDebtsLoadCompleted: false,
      };
    }

    case SalesReportsActionType.GET_CUSTOMERS_DEBTS_REPORT_RECEIVED: {
      return {
        ...state,
        error: null,
        isLoadingCustomersDebts: false,
        isCustomersDebtsLoadCompleted: true,
      };
    }

    case SalesReportsActionType.GET_CUSTOMERS_DEBTS_REPORT_SUCCESS: {
      return {
        ...state,
        customersDebts: action.payload.data,
        error: null,
        isLoadingCustomersDebts: false,
        isCustomersDebtsLoadCompleted: true,
      };
    }

    case SalesReportsActionType.CUSTOMERS_DEBTS_REPORT_VIEW: {
      return {
        ...state,
        customersDebts: action.payload,
        error: null,
        isLoadingCustomersDebts: false,
        isCustomersDebtsLoadCompleted: true,
      };
    }

    //#endregion CUSTOMERS_DEBTS

    //#region GET_CUSTOMERS_PAYMENTS_REPORT

    case SalesReportsActionType.GET_CUSTOMERS_PAYMENTS_REPORT: {
      return {
        ...state,
        customersPayments: null,
        isLoadingCustomersPayments: true,
        isCustomersPaymentsLoadCompleted: false,
        error: null,
      };
    }

    case SalesReportsActionType.GET_CUSTOMERS_PAYMENTS_REPORT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isLoadingCustomersPayments: false,
        isCustomersPaymentsLoadCompleted: false,
      };
    }

    case SalesReportsActionType.GET_CUSTOMERS_PAYMENTS_REPORT_RECEIVED: {
      return {
        ...state,
        error: null,
        isLoadingCustomersPayments: false,
        isCustomersPaymentsLoadCompleted: true,
      };
    }

    case SalesReportsActionType.GET_CUSTOMERS_PAYMENTS_REPORT_SUCCESS: {
      return {
        ...state,
        customersPayments: action.payload.data,
        error: null,
        isLoadingCustomersPayments: false,
        isCustomersPaymentsLoadCompleted: true,
      };
    }

    case SalesReportsActionType.CUSTOMERS_PAYMENTS_REPORT_VIEW: {
      return {
        ...state,
        customersPayments: action.payload,
        error: null,
        isLoadingCustomersPayments: false,
        isCustomersPaymentsLoadCompleted: true,
      };
    }

    //#endregion CUSTOMERS_PAYMENTS

    //#region GET_CUSTOMERS_POINTS_REPORT

    case SalesReportsActionType.GET_CUSTOMERS_POINTS_REPORT: {
      return {
        ...state,
        customersPoints: null,
        isLoadingCustomersPoints: true,
        isCustomersPointsLoadCompleted: false,
        error: null,
      };
    }

    case SalesReportsActionType.GET_CUSTOMERS_POINTS_REPORT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isLoadingCustomersPoints: false,
        isCustomersPointsLoadCompleted: false,
      };
    }

    case SalesReportsActionType.GET_CUSTOMERS_POINTS_REPORT_RECEIVED: {
      return {
        ...state,
        error: null,
        isLoadingCustomersPoints: false,
        isCustomersPointsLoadCompleted: true,
      };
    }

    case SalesReportsActionType.GET_CUSTOMERS_POINTS_REPORT_SUCCESS: {
      return {
        ...state,
        customersPoints: action.payload.data,
        error: null,
        isLoadingCustomersPoints: false,
        isCustomersPointsLoadCompleted: true,
      };
    }

    case SalesReportsActionType.CUSTOMERS_POINTS_REPORT_VIEW: {
      return {
        ...state,
        customersPoints: action.payload,
        error: null,
        isLoadingCustomersPoints: false,
        isCustomersPointsLoadCompleted: true,
      };
    }

    //#endregion CUSTOMERS_POINTS

    //#region GET_GROSS_PROFIT_REPORT

    case SalesReportsActionType.GET_GROSS_PROFIT_REPORT: {
      return {
        ...state,
        grossProfit: null,
        isLoadingGrossProfit: true,
        isGrossProfitLoadCompleted: false,
        error: null,
      };
    }

    case SalesReportsActionType.GET_GROSS_PROFIT_REPORT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isLoadingGrossProfit: false,
        isGrossProfitLoadCompleted: false,
      };
    }

    case SalesReportsActionType.GET_GROSS_PROFIT_REPORT_RECEIVED: {
      return {
        ...state,
        error: null,
        isLoadingGrossProfit: false,
        isGrossProfitLoadCompleted: true,
      };
    }

    case SalesReportsActionType.GET_GROSS_PROFIT_REPORT_SUCCESS: {
      return {
        ...state,
        grossProfit: action.payload.data,
        error: null,
        isLoadingGrossProfit: false,
        isGrossProfitLoadCompleted: true,
      };
    }

    case SalesReportsActionType.GROSS_PROFIT_REPORT_VIEW: {
      return {
        ...state,
        grossProfit: action.payload,
        error: null,
        isLoadingGrossProfit: false,
        isGrossProfitLoadCompleted: true,
      };
    }

    //#endregion GROSS_PROFIT

    //#region GET_MAINTENANCE_STAGE_INVOICE_REPORT

    case SalesReportsActionType.GET_MAINTENANCE_STAGE_INVOICE_REPORT: {
      return {
        ...state,
        maintenanceStageInvoice: null,
        isLoadingMaintenanceStageInvoice: true,
        isMaintenanceStageInvoiceLoadCompleted: false,
        error: null,
      };
    }

    case SalesReportsActionType.GET_MAINTENANCE_STAGE_INVOICE_REPORT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isLoadingMaintenanceStageInvoice: false,
        isMaintenanceStageInvoiceLoadCompleted: false,
      };
    }

    case SalesReportsActionType.GET_MAINTENANCE_STAGE_INVOICE_REPORT_RECEIVED: {
      return {
        ...state,
        error: null,
        isLoadingMaintenanceStageInvoice: false,
        isMaintenanceStageInvoiceLoadCompleted: true,
      };
    }

    case SalesReportsActionType.GET_MAINTENANCE_STAGE_INVOICE_REPORT_SUCCESS: {
      return {
        ...state,
        maintenanceStageInvoice: action.payload.data,
        error: null,
        isLoadingMaintenanceStageInvoice: false,
        isMaintenanceStageInvoiceLoadCompleted: true,
      };
    }

    case SalesReportsActionType.MAINTENANCE_STAGE_INVOICE_REPORT_VIEW: {
      return {
        ...state,
        maintenanceStageInvoice: action.payload,
        error: null,
        isLoadingMaintenanceStageInvoice: false,
        isMaintenanceStageInvoiceLoadCompleted: true,
      };
    }

    //#endregion MAINTENANCE_STAGE_INVOICE

    //#region GET_TOP_RETURNED_SALES_PRODUCTS_REPORT

    case SalesReportsActionType.GET_TOP_RETURNED_SALES_PRODUCTS_REPORT: {
      return {
        ...state,
        topReturnedSalesProducts: null,
        isLoadingTopReturnedSalesProducts: true,
        isTopReturnedSalesProductsLoadCompleted: false,
        error: null,
      };
    }

    case SalesReportsActionType.GET_TOP_RETURNED_SALES_PRODUCTS_REPORT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isLoadingTopReturnedSalesProducts: false,
        isTopReturnedSalesProductsLoadCompleted: false,
      };
    }

    case SalesReportsActionType.GET_TOP_RETURNED_SALES_PRODUCTS_REPORT_RECEIVED: {
      return {
        ...state,
        error: null,
        isLoadingTopReturnedSalesProducts: false,
        isTopReturnedSalesProductsLoadCompleted: true,
      };
    }

    case SalesReportsActionType.GET_TOP_RETURNED_SALES_PRODUCTS_REPORT_SUCCESS: {
      return {
        ...state,
        topReturnedSalesProducts: action.payload.data,
        error: null,
        isLoadingTopReturnedSalesProducts: false,
        isTopReturnedSalesProductsLoadCompleted: true,
      };
    }

    case SalesReportsActionType.TOP_RETURNED_SALES_PRODUCTS_REPORT_VIEW: {
      return {
        ...state,
        topReturnedSalesProducts: action.payload,
        error: null,
        isLoadingTopReturnedSalesProducts: false,
        isTopReturnedSalesProductsLoadCompleted: true,
      };
    }

    //#endregion TOP_RETURNED_SALES_PRODUCTS

    //#region GET_NET_SALES_REPORT

    case SalesReportsActionType.GET_NET_SALES_REPORT: {
      return {
        ...state,
        netSales: null,
        isLoadingNetSales: true,
        isNetSalesLoadCompleted: false,
        error: null,
      };
    }

    case SalesReportsActionType.GET_NET_SALES_REPORT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isLoadingNetSales: false,
        isNetSalesLoadCompleted: false,
      };
    }

    case SalesReportsActionType.GET_NET_SALES_REPORT_RECEIVED: {
      return {
        ...state,
        error: null,
        isLoadingNetSales: false,
        isNetSalesLoadCompleted: true,
      };
    }

    case SalesReportsActionType.GET_NET_SALES_REPORT_SUCCESS: {
      return {
        ...state,
        netSales: action.payload.data,
        error: null,
        isLoadingNetSales: false,
        isNetSalesLoadCompleted: true,
      };
    }

    case SalesReportsActionType.NET_SALES_REPORT_VIEW: {
      return {
        ...state,
        netSales: action.payload,
        error: null,
        isLoadingNetSales: false,
        isNetSalesLoadCompleted: true,
      };
    }

    //#endregion NET_SALES

    //#region GET_PRODUCT_SALES_REPORT

    case SalesReportsActionType.GET_PRODUCT_SALES_REPORT: {
      return {
        ...state,
        productSales: null,
        isLoadingProductSales: true,
        isProductSalesLoadCompleted: false,
        error: null,
      };
    }

    case SalesReportsActionType.GET_PRODUCT_SALES_REPORT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isLoadingProductSales: false,
        isProductSalesLoadCompleted: false,
      };
    }

    case SalesReportsActionType.GET_PRODUCT_SALES_REPORT_RECEIVED: {
      return {
        ...state,
        error: null,
        isLoadingProductSales: false,
        isProductSalesLoadCompleted: true,
      };
    }

    case SalesReportsActionType.GET_PRODUCT_SALES_REPORT_SUCCESS: {
      return {
        ...state,
        productSales: action.payload.data,
        error: null,
        isLoadingProductSales: false,
        isProductSalesLoadCompleted: true,
      };
    }

    case SalesReportsActionType.PRODUCT_SALES_REPORT_VIEW: {
      return {
        ...state,
        productSales: action.payload,
        error: null,
        isLoadingProductSales: false,
        isProductSalesLoadCompleted: true,
      };
    }

    //#endregion PRODUCT_SALES

    //#region GET_PRODUCT_SALES_RETURNS_REPORT

    case SalesReportsActionType.GET_PRODUCT_SALES_RETURNS_REPORT: {
      return {
        ...state,
        productSalesReturns: null,
        isLoadingProductSalesReturns: true,
        isProductSalesReturnsLoadCompleted: false,
        error: null,
      };
    }

    case SalesReportsActionType.GET_PRODUCT_SALES_RETURNS_REPORT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isLoadingProductSalesReturns: false,
        isProductSalesReturnsLoadCompleted: false,
      };
    }

    case SalesReportsActionType.GET_PRODUCT_SALES_RETURNS_REPORT_RECEIVED: {
      return {
        ...state,
        error: null,
        isLoadingProductSalesReturns: false,
        isProductSalesReturnsLoadCompleted: true,
      };
    }

    case SalesReportsActionType.GET_PRODUCT_SALES_RETURNS_REPORT_SUCCESS: {
      return {
        ...state,
        productSalesReturns: action.payload.data,
        error: null,
        isLoadingProductSalesReturns: false,
        isProductSalesReturnsLoadCompleted: true,
      };
    }

    case SalesReportsActionType.PRODUCT_SALES_RETURNS_REPORT_VIEW: {
      return {
        ...state,
        productSalesReturns: action.payload,
        error: null,
        isLoadingProductSalesReturns: false,
        isProductSalesReturnsLoadCompleted: true,
      };
    }

    //#endregion PRODUCT_SALES_RETURNS

    //#region GET_PRODUCTS_CLASSES_SALES_REPORT

    case SalesReportsActionType.GET_PRODUCT_CLASSES_SALES_REPORT: {
      return {
        ...state,
        productClassesSales: null,
        isLoadingProductClassesSales: true,
        isProductClassesSalesLoadCompleted: false,
        error: null,
      };
    }

    case SalesReportsActionType.GET_PRODUCT_CLASSES_SALES_REPORT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isLoadingProductClassesSales: false,
        isProductClassesSalesLoadCompleted: false,
      };
    }

    case SalesReportsActionType.GET_PRODUCT_CLASSES_SALES_REPORT_RECEIVED: {
      return {
        ...state,
        error: null,
        isLoadingProductClassesSales: false,
        isProductClassesSalesLoadCompleted: true,
      };
    }

    case SalesReportsActionType.GET_PRODUCT_CLASSES_SALES_REPORT_SUCCESS: {
      return {
        ...state,
        productClassesSales: action.payload.data,
        error: null,
        isLoadingProductClassesSales: false,
        isProductClassesSalesLoadCompleted: true,
      };
    }

    case SalesReportsActionType.PRODUCT_CLASSES_SALES_REPORT_VIEW: {
      return {
        ...state,
        productClassesSales: action.payload,
        error: null,
        isLoadingProductClassesSales: false,
        isProductClassesSalesLoadCompleted: true,
      };
    }

    //#endregion PRODUCTS_CLASSES_SALES

    //#region GET_PRODUCTS_SALES_REPORT

    case SalesReportsActionType.GET_PRODUCTS_SALES_REPORT: {
      return {
        ...state,
        productsSales: null,
        isLoadingProductsSales: true,
        isProductsSalesLoadCompleted: false,
        error: null,
      };
    }

    case SalesReportsActionType.GET_PRODUCTS_SALES_REPORT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isLoadingProductsSales: false,
        isProductsSalesLoadCompleted: false,
      };
    }

    case SalesReportsActionType.GET_PRODUCTS_SALES_REPORT_RECEIVED: {
      return {
        ...state,
        error: null,
        isLoadingProductsSales: false,
        isProductsSalesLoadCompleted: true,
      };
    }

    case SalesReportsActionType.GET_PRODUCTS_SALES_REPORT_SUCCESS: {
      return {
        ...state,
        productsSales: action.payload.data,
        error: null,
        isLoadingProductsSales: false,
        isProductsSalesLoadCompleted: true,
      };
    }

    case SalesReportsActionType.PRODUCTS_SALES_REPORT_VIEW: {
      return {
        ...state,
        productsSales: action.payload,
        error: null,
        isLoadingProductsSales: false,
        isProductsSalesLoadCompleted: true,
      };
    }

    //#endregion PRODUCTS_SALES

    //#region GET_PRODUCTS_SALES_RETURNS_REPORT

    case SalesReportsActionType.GET_PRODUCTS_SALES_RETURNS_REPORT: {
      return {
        ...state,
        productsSalesReturns: null,
        isLoadingProductsSalesReturns: true,
        isProductsSalesReturnsLoadCompleted: false,
        error: null,
      };
    }

    case SalesReportsActionType.GET_PRODUCTS_SALES_RETURNS_REPORT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isLoadingProductsSalesReturns: false,
        isProductsSalesReturnsLoadCompleted: false,
      };
    }

    case SalesReportsActionType.GET_PRODUCTS_SALES_RETURNS_REPORT_RECEIVED: {
      return {
        ...state,
        error: null,
        isLoadingProductsSalesReturns: false,
        isProductsSalesReturnsLoadCompleted: true,
      };
    }

    case SalesReportsActionType.GET_PRODUCTS_SALES_RETURNS_REPORT_SUCCESS: {
      return {
        ...state,
        productsSalesReturns: action.payload.data,
        error: null,
        isLoadingProductsSalesReturns: false,
        isProductsSalesReturnsLoadCompleted: true,
      };
    }

    case SalesReportsActionType.PRODUCTS_SALES_RETURNS_REPORT_VIEW: {
      return {
        ...state,
        productsSalesReturns: action.payload,
        error: null,
        isLoadingProductsSalesReturns: false,
        isProductsSalesReturnsLoadCompleted: true,
      };
    }

    //#endregion PRODUCTS_SALES_RETURNS

    //#region GET_SALES_INVOICES_REPORT

    case SalesReportsActionType.GET_SALES_INVOICES_REPORT: {
      return {
        ...state,
        salesInvoices: null,
        isLoadingSalesInvoices: true,
        isSalesInvoicesLoadCompleted: false,
        error: null,
      };
    }

    case SalesReportsActionType.GET_SALES_INVOICES_REPORT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isLoadingSalesInvoices: false,
        isSalesInvoicesLoadCompleted: false,
      };
    }

    case SalesReportsActionType.GET_SALES_INVOICES_REPORT_RECEIVED: {
      return {
        ...state,
        error: null,
        isLoadingSalesInvoices: false,
        isSalesInvoicesLoadCompleted: true,
      };
    }

    case SalesReportsActionType.GET_SALES_INVOICES_REPORT_SUCCESS: {
      return {
        ...state,
        salesInvoices: action.payload.data,
        error: null,
        isLoadingSalesInvoices: false,
        isSalesInvoicesLoadCompleted: true,
      };
    }

    case SalesReportsActionType.SALES_INVOICES_REPORT_VIEW: {
      return {
        ...state,
        salesInvoices: action.payload,
        error: null,
        isLoadingSalesInvoices: false,
        isSalesInvoicesLoadCompleted: true,
      };
    }

    //#endregion GET_SALES_INVOICES_REPORT

    //#region GET_SALES_RETURNS_REPORT

    case SalesReportsActionType.GET_SALES_RETURNS_REPORT: {
      return {
        ...state,
        salesReturns: null,
        isLoadingSalesReturns: true,
        isSalesReturnsLoadCompleted: false,
        error: null,
      };
    }

    case SalesReportsActionType.GET_SALES_RETURNS_REPORT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isLoadingSalesReturns: false,
        isSalesReturnsLoadCompleted: false,
      };
    }

    case SalesReportsActionType.GET_SALES_RETURNS_REPORT_RECEIVED: {
      return {
        ...state,
        error: null,
        isLoadingSalesReturns: false,
        isSalesReturnsLoadCompleted: true,
      };
    }

    case SalesReportsActionType.GET_SALES_RETURNS_REPORT_SUCCESS: {
      return {
        ...state,
        salesReturns: action.payload.data,
        error: null,
        isLoadingSalesReturns: false,
        isSalesReturnsLoadCompleted: true,
      };
    }

    case SalesReportsActionType.SALES_RETURNS_REPORT_VIEW: {
      return {
        ...state,
        salesReturns: action.payload,
        error: null,
        isLoadingSalesReturns: false,
        isSalesReturnsLoadCompleted: true,
      };
    }

    //#endregion SALES_RETURNS

    //#region GET_SALES_SUMMARY_REPORT

    case SalesReportsActionType.GET_SALES_SUMMARY_REPORT: {
      return {
        ...state,
        salesSummary: null,
        isLoadingSalesSummary: true,
        isSalesSummaryLoadCompleted: false,
        error: null,
      };
    }

    case SalesReportsActionType.GET_SALES_SUMMARY_REPORT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isLoadingSalesSummary: false,
        isSalesSummaryLoadCompleted: false,
      };
    }

    case SalesReportsActionType.GET_SALES_SUMMARY_REPORT_RECEIVED: {
      return {
        ...state,
        error: null,
        isLoadingSalesSummary: false,
        isSalesSummaryLoadCompleted: true,
      };
    }

    case SalesReportsActionType.GET_SALES_SUMMARY_REPORT_SUCCESS: {
      return {
        ...state,
        salesSummary: action.payload.data,
        error: null,
        isLoadingSalesSummary: false,
        isSalesSummaryLoadCompleted: true,
      };
    }

    case SalesReportsActionType.SALES_SUMMARY_REPORT_VIEW: {
      return {
        ...state,
        salesSummary: action.payload,
        error: null,
        isLoadingSalesSummary: false,
        isSalesSummaryLoadCompleted: true,
      };
    }

    //#endregion SALES_SUMMARY

    //#region GET_SHIFT_SUMMARY_REPORT

    case SalesReportsActionType.GET_SHIFT_SUMMARY_REPORT: {
      return {
        ...state,
        shiftSummary: null,
        isLoadingShiftSummary: true,
        isShiftSummaryLoadCompleted: false,
        error: null,
      };
    }

    case SalesReportsActionType.GET_SHIFT_SUMMARY_REPORT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isLoadingShiftSummary: false,
        isShiftSummaryLoadCompleted: false,
      };
    }

    case SalesReportsActionType.GET_SHIFT_SUMMARY_REPORT_RECEIVED: {
      return {
        ...state,
        error: null,
        isLoadingShiftSummary: false,
        isShiftSummaryLoadCompleted: true,
      };
    }

    case SalesReportsActionType.GET_SHIFT_SUMMARY_REPORT_SUCCESS: {
      return {
        ...state,
        shiftSummary: action.payload.data,
        error: null,
        isLoadingShiftSummary: false,
        isShiftSummaryLoadCompleted: true,
      };
    }

    case SalesReportsActionType.SHIFT_SUMMARY_REPORT_VIEW: {
      return {
        ...state,
        shiftSummary: action.payload,
        error: null,
        isLoadingShiftSummary: false,
        isShiftSummaryLoadCompleted: true,
      };
    }

    //#endregion SHIFT

    //#region GET_TOP_CUSTOMERS_REPORT

    case SalesReportsActionType.GET_TOP_CUSTOMERS_REPORT: {
      return {
        ...state,
        topCustomers: null,
        isLoadingTopCustomers: true,
        isTopCustomersLoadCompleted: false,
        error: null,
      };
    }

    case SalesReportsActionType.GET_TOP_CUSTOMERS_REPORT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isLoadingTopCustomers: false,
        isTopCustomersLoadCompleted: false,
      };
    }

    case SalesReportsActionType.GET_TOP_CUSTOMERS_REPORT_RECEIVED: {
      return {
        ...state,
        error: null,
        isLoadingTopCustomers: false,
        isTopCustomersLoadCompleted: true,
      };
    }

    case SalesReportsActionType.GET_TOP_CUSTOMERS_REPORT_SUCCESS: {
      return {
        ...state,
        topCustomers: action.payload.data,
        error: null,
        isLoadingTopCustomers: false,
        isTopCustomersLoadCompleted: true,
      };
    }

    case SalesReportsActionType.TOP_CUSTOMERS_REPORT_VIEW: {
      return {
        ...state,
        topCustomers: action.payload,
        error: null,
        isLoadingTopCustomers: false,
        isTopCustomersLoadCompleted: true,
      };
    }

    //#endregion TOP_CUSTOMERS

    //#region GET_TOP_SELLING_PRODUCTS_REPORT

    case SalesReportsActionType.GET_TOP_SELLING_PRODUCTS_REPORT: {
      return {
        ...state,
        topSellingProducts: null,
        isLoadingTopSellingProducts: true,
        isTopSellingProductsLoadCompleted: false,
        error: null,
      };
    }

    case SalesReportsActionType.GET_TOP_SELLING_PRODUCTS_REPORT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isLoadingTopSellingProducts: false,
        isTopSellingProductsLoadCompleted: false,
      };
    }

    case SalesReportsActionType.GET_TOP_SELLING_PRODUCTS_REPORT_RECEIVED: {
      return {
        ...state,
        error: null,
        isLoadingTopSellingProducts: false,
        isTopSellingProductsLoadCompleted: true,
      };
    }

    case SalesReportsActionType.GET_TOP_SELLING_PRODUCTS_REPORT_SUCCESS: {
      return {
        ...state,
        topSellingProducts: action.payload.data,
        error: null,
        isLoadingTopSellingProducts: false,
        isTopSellingProductsLoadCompleted: true,
      };
    }

    case SalesReportsActionType.TOP_SELLING_PRODUCTS_REPORT_VIEW: {
      return {
        ...state,
        topSellingProducts: action.payload,
        error: null,
        isLoadingTopSellingProducts: false,
        isTopSellingProductsLoadCompleted: true,
      };
    }

    //#endregion TOP_SELLING_PRODUCTS

    //#region GET_WORST_CUSTOMERS_REPORT

    case SalesReportsActionType.GET_WORST_CUSTOMERS_REPORT: {
      return {
        ...state,
        worstCustomers: null,
        isLoadingWorstCustomers: true,
        isWorstCustomersLoadCompleted: false,
        error: null,
      };
    }

    case SalesReportsActionType.GET_WORST_CUSTOMERS_REPORT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isLoadingWorstCustomers: false,
        isWorstCustomersLoadCompleted: false,
      };
    }

    case SalesReportsActionType.GET_WORST_CUSTOMERS_REPORT_RECEIVED: {
      return {
        ...state,
        error: null,
        isLoadingWorstCustomers: false,
        isWorstCustomersLoadCompleted: true,
      };
    }

    case SalesReportsActionType.GET_WORST_CUSTOMERS_REPORT_SUCCESS: {
      return {
        ...state,
        worstCustomers: action.payload.data,
        error: null,
        isLoadingWorstCustomers: false,
        isWorstCustomersLoadCompleted: true,
      };
    }

    case SalesReportsActionType.WORST_CUSTOMERS_REPORT_VIEW: {
      return {
        ...state,
        worstCustomers: action.payload,
        error: null,
        isLoadingWorstCustomers: false,
        isWorstCustomersLoadCompleted: true,
      };
    }

    //#endregion WORST_CUSTOMERS

    //#region GET_WORST_RETURNED_SALES_PRODUCTS_REPORT

    case SalesReportsActionType.GET_WORST_RETURNED_SALES_PRODUCTS_REPORT: {
      return {
        ...state,
        worstReturnedSalesProducts: null,
        isLoadingWorstReturnedSalesProducts: true,
        isWorstReturnedSalesProductsLoadCompleted: false,
        error: null,
      };
    }

    case SalesReportsActionType.GET_WORST_RETURNED_SALES_PRODUCTS_REPORT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isLoadingWorstReturnedSalesProducts: false,
        isWorstReturnedSalesProductsLoadCompleted: false,
      };
    }

    case SalesReportsActionType.GET_WORST_RETURNED_SALES_PRODUCTS_REPORT_RECEIVED: {
      return {
        ...state,
        error: null,
        isLoadingWorstReturnedSalesProducts: false,
        isWorstReturnedSalesProductsLoadCompleted: true,
      };
    }

    case SalesReportsActionType.GET_WORST_RETURNED_SALES_PRODUCTS_REPORT_SUCCESS: {
      return {
        ...state,
        worstReturnedSalesProducts: action.payload.data,
        error: null,
        isLoadingWorstReturnedSalesProducts: false,
        isWorstReturnedSalesProductsLoadCompleted: true,
      };
    }

    case SalesReportsActionType.WORST_RETURNED_SALES_PRODUCTS_REPORT_VIEW: {
      return {
        ...state,
        worstReturnedSalesProducts: action.payload,
        error: null,
        isLoadingWorstReturnedSalesProducts: false,
        isWorstReturnedSalesProductsLoadCompleted: true,
      };
    }

    //#endregion WORST_RETURNED_SALES_PRODUCTS

    //#region GET_WORST_SELLING_PRODUCTS_REPORT

    case SalesReportsActionType.GET_WORST_SELLING_PRODUCTS_REPORT: {
      return {
        ...state,
        worstSellingProducts: null,
        isLoadingWorstSellingProducts: true,
        isWorstSellingProductsLoadCompleted: false,
        error: null,
      };
    }

    case SalesReportsActionType.GET_WORST_SELLING_PRODUCTS_REPORT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isLoadingWorstSellingProducts: false,
        isWorstSellingProductsLoadCompleted: false,
      };
    }

    case SalesReportsActionType.GET_WORST_SELLING_PRODUCTS_REPORT_RECEIVED: {
      return {
        ...state,
        error: null,
        isLoadingWorstSellingProducts: false,
        isWorstSellingProductsLoadCompleted: true,
      };
    }

    case SalesReportsActionType.GET_WORST_SELLING_PRODUCTS_REPORT_SUCCESS: {
      return {
        ...state,
        worstSellingProducts: action.payload.data,
        error: null,
        isLoadingWorstSellingProducts: false,
        isWorstSellingProductsLoadCompleted: true,
      };
    }

    case SalesReportsActionType.WORST_SELLING_PRODUCTS_REPORT_VIEW: {
      return {
        ...state,
        worstSellingProducts: action.payload,
        error: null,
        isLoadingWorstSellingProducts: false,
        isWorstSellingProductsLoadCompleted: true,
      };
    }

    //#endregion WORST_SELLING_PRODUCTS

    //#region GET_SALES_PERSON_REPORT

    case SalesReportsActionType.GET_SALES_PERSON_SALES_REPORT: {
      return {
        ...state,
        salesPerson: null,
        isLoadingSalesPerson: true,
        isSalesPersonLoadCompleted: false,
        error: null,
      };
    }

    case SalesReportsActionType.GET_SALES_PERSON_SALES_REPORT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isLoadingSalesPerson: false,
        isSalesPersonLoadCompleted: false,
      };
    }

    case SalesReportsActionType.GET_SALES_PERSON_SALES_REPORT_RECEIVED: {
      return {
        ...state,
        error: null,
        isLoadingSalesPerson: false,
        isSalesPersonLoadCompleted: true,
      };
    }

    case SalesReportsActionType.GET_SALES_PERSON_REPORT_SUCCESS: {
      return {
        ...state,
        salesPerson: action.payload.data,
        error: null,
        isLoadingSalesPerson: false,
        isSalesPersonLoadCompleted: true,
      };
    }

    case SalesReportsActionType.SALES_PERSON_SALES_REPORT_VIEW: {
      return {
        ...state,
        salesPerson: action.payload,
        error: null,
        isLoadingSalesPerson: false,
        isSalesPersonLoadCompleted: true,
      };
    }

    //#endregion SALES_PERSON

    default:
      return state;
  }
}
