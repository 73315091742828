<!-- page header starts -->
<app-page-header [info]="pageInfo"></app-page-header>
<!-- page header ends -->

<!-- orders delivery list starts -->
<div class="row">
  <div class="col-md-12">
    <div class="card my-0 py-0">
      <div class="card-header mb-3">
        <h4 class="card-title">{{ 'SHARED.SEARCH.SEARCH_TOOLS' | translate }}</h4>
      </div>
      <div class="card-content">
        <div class="px-3">
          <div class="row">
            <div class="col-md-12">
              <form class="row" [formGroup]="searchForm" (ngSubmit)="search()">
                <div class="form-group col">
                  <label for="locations">{{
                    'SALES.ORDERS_DELIVERY.ORDERS_DELIVERY_DATA.LOCATIONS' | translate
                  }}</label>
                  <ng-select
                    class="round"
                    formControlName="locations"
                    [multiple]="true"
                    [closeOnSelect]="false"
                    labelForId="locations"
                    searchable="true"
                    [placeholder]="'SALES.ORDERS_DELIVERY.ORDERS_DELIVERY_DATA.LOCATIONS_PLACEHOLDER' | translate"
                    [notFoundText]="'SHARED.SEARCH.SEARCH_NOT_FOUND' | translate"
                    (open)="locationsListVisibility = true"
                  >
                    <ng-option *ngFor="let location of locations" [value]="location.id">
                      {{ location.name | localize: location.nameEn | async }}
                    </ng-option>
                  </ng-select>
                </div>
                <div class="col-md-auto form-group d-flex mt-3">
                  <button
                    type="submit"
                    class="btn btn-raised btn-primary align-self-end round mx-1 mb-0"
                    [disabled]="isSearching$ | async"
                  >
                    <i class="fa fa-search"></i>
                  </button>
                </div>
              </form>
            </div>
            <div class="col-md-12" *ngIf="(saleInvoices$ | async)?.length; else noItems">
              <div class="row d-flex flex-row justify-content-between">
                <div class="col-md-12">
                  <!--orders delivery cards Starts-->
                  <div class="row d-flex flex-row justify-content-between text-left mb-4">
                    <div class="col-md-3" *ngFor="let saleInvoice of saleInvoices$ | async; trackBy: trackItems">
                      <div class="card order-delivery-menu-item-card">
                        <div class="card-content">
                          <div class="col-md-12">
                            <div class="table-responsive-xl">
                              <p class="font-weight-bold text-center mt-2 mb-0">
                                {{ 'SALES.ORDERS_DELIVERY.ORDERS_DELIVERY_DATA.ORDER_NUMBER' | translate }}
                                <span> {{ saleInvoice.orderNum }}</span>
                              </p>
                              <p
                                *ngIf="
                                  saleInvoice.saleInvoiceOrderType.key === saleInvoiceOrderType.DINE_IN &&
                                  saleInvoice.board
                                "
                                class="text-center mb-0"
                              >
                                {{ 'SALES.ORDERS_DELIVERY.ORDERS_DELIVERY_DATA.BOARD_NUMBER' | translate }}
                                <span> {{ saleInvoice.board.description }}</span>
                              </p>
                              <p class="text-center">
                                {{ 'SALES.ORDERS_DELIVERY.ORDERS_DELIVERY_DATA.ORDER_TYPE' | translate }}
                                <span>
                                  {{
                                    saleInvoice.saleInvoiceOrderType.name
                                      | localize: saleInvoice.saleInvoiceOrderType.nameEn
                                      | async
                                  }}</span
                                >
                              </p>
                              <table class="table table-sm text-center table-tr">
                                <thead>
                                  <tr>
                                    <th scope="col-3">
                                      {{ 'SALES.ORDERS_DELIVERY.ORDERS_DELIVERY_DATA.QUANTITY' | translate }}
                                    </th>
                                    <th scope="col-8">
                                      {{ 'SALES.ORDERS_DELIVERY.ORDERS_DELIVERY_DATA.PRODUCT' | translate }}
                                    </th>
                                    <th scope="col-1">
                                      {{ 'SALES.ORDERS_DELIVERY.ORDERS_DELIVERY_DATA.STATUS' | translate }}
                                    </th>
                                  </tr>
                                </thead>
                                <tbody *ngFor="let saleInvoiceProduct of saleInvoice.saleInvoiceProducts">
                                  <tr *ngIf="saleInvoiceProduct.product">
                                    <th scope="col-3">{{ saleInvoiceProduct.quantity }}</th>
                                    <td scope="col-8">
                                      <span>{{
                                        saleInvoiceProduct.product.description
                                          | localize: saleInvoiceProduct.product.descriptionEn
                                          | async
                                      }}</span>
                                      <p class="mb-0" *ngIf="saleInvoiceProduct.product.hasSubProducts">
                                        (
                                        <span
                                          *ngFor="
                                            let subProductVariant of saleInvoiceProduct.product.subProducts[0]
                                              .subProductVariants;
                                            last as isLast
                                          "
                                          >{{
                                            subProductVariant.variant.name
                                              | localize: subProductVariant.variant.nameEn
                                              | async
                                          }}
                                          <span *ngIf="!isLast">-</span></span
                                        >
                                        )
                                      </p>
                                      <span>{{ saleInvoiceProduct.notes }}</span>
                                    </td>
                                    <td scope="col-8">
                                      <span
                                        *ngIf="!saleInvoiceProduct.isReadyForDelivery"
                                        [ngbTooltip]="
                                          'SALES.ORDERS_DELIVERY.ORDERS_DELIVERY_DATA.NOT_READY' | translate
                                        "
                                        ><i class="fa fa-fire warning"></i></span
                                      ><span
                                        *ngIf="saleInvoiceProduct.isReadyForDelivery"
                                        [ngbTooltip]="'SALES.ORDERS_DELIVERY.ORDERS_DELIVERY_DATA.READY' | translate"
                                        ><i class="fa fa-check success"></i
                                      ></span>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                            <div class="text-right mt-2 d-flex justify-content-between">
                              <button
                                type="button"
                                class="btn btn-raised btn-secondary round"
                                ngbAutofocus
                                (click)="selectedSaleInvoice = saleInvoice; print()"
                              >
                                <i class="fa fa-print"></i>
                                <span class="mx-1">{{ 'SHARED.CONFIRM.PRINT' | translate }}</span>
                              </button>
                              <button
                                *ngIf="[Claims.ORDERS_DELIVERING] | authorize | async"
                                type="button"
                                class="btn btn-raised btn-success round"
                                [disabled]="isDelivering$ | async"
                                ngbAutofocus
                                (click)="selectedSaleInvoice = saleInvoice; openModal(updateModalRef)"
                              >
                                <i class="fa fa-check"></i>
                                <span class="mx-1">{{
                                  'SALES.ORDERS_DELIVERY.ORDERS_DELIVERY_DATA.DELIVERY_ORDER' | translate
                                }}</span>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!--orders delivery cards End-->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- orders delivery list ends -->

<!-- no items template starts -->
<ng-template #noItems>
  <div class="col-md-12 alert alert-warning mt-3 text-center" role="alert">
    {{ 'SALES.ORDERS_DELIVERY.ORDERS_DELIVERY_NOT_FOUND' | translate }}
  </div>
</ng-template>
<!-- no items template ends -->

<!-- update order modal starts -->
<ng-template #updateModalRef let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
      {{ 'SALES.ORDERS_DELIVERY.CONFIRM_UPDATE_ORDER_DELIVERY_TITLE' | translate }}
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="d()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-12">
        <p class="text-success">{{ 'SALES.ORDERS_DELIVERY.CONFIRM_UPDATE_ORDER_DELIVERY_MESSAGE' | translate }}</p>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="modal-actions">
      <button
        type="button"
        class="btn btn-raised btn-success round"
        (click)="c(); delivery(selectedSaleInvoice.id)"
        ngbAutofocus
      >
        <i class="fa fa-check"></i>
        <span class="mx-1">{{ 'SALES.ORDERS_DELIVERY.ORDERS_DELIVERY_DATA.DELIVER_ORDER' | translate }}</span>
      </button>
      <button type="button" class="btn btn-outline-dark round" (click)="c()">
        <i class="fa fa-times"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.CANCEL' | translate }}</span>
      </button>
    </div>
  </div>
</ng-template>
<!-- update order modal ends -->

<!-- locations search starts -->
<app-locations-search [(visible)]="locationsListVisibility" (locations)="selectLocations($event)">
</app-locations-search>
<!-- locations search ends -->
