/**
 * The state that includes information about the current page.
 */
export class PageState {
  /**
   * Gets or sets the title of the page.
   */
  title?: string;

  /**
   * Gets or sets the sub title of the page.
   */
  subTitle?: string;

  /**
   * Gets or sets the icon of the page that will be displayed next to the title.
   */
  icon?: string;

  /**
   * Gets or sets a value indicates whether the page title on the browser will be changed too.
   */
  noChangeForBrowserTitle?: boolean;
}
