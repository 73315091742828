import { Action } from '@ngrx/store';

import { ProjectResourceTypeSource } from 'lookups/models';
import { AppHttpResponse } from 'shared';

/**
 * The Lookups-Module store  project resource type sources action types.
 */
export enum ProjectResourceTypeSourceActionType {
  GET_ALL_PROJECT_RESOURCE_TYPE_SOURCES = '[Lookups] [ProjectResourceTypeSource] Get All Project Resource Type Source',
  GET_ALL_PROJECT_RESOURCE_TYPE_SOURCES_FAIL = '[Lookups] [ProjectResourceTypeSource] Get All Project Resource Type Source Fail',
  GET_ALL_PROJECT_RESOURCE_TYPE_SOURCES_SUCCESS = '[Lookups] [ProjectResourceTypeSource] Get All Project Resource Type Source Success',
}

/**
 * Represents a store  project resource type source action.
 */
export class GetAllProjectResourceTypeSources implements Action {
  /**
   * The type of the action.
   */
  readonly type = ProjectResourceTypeSourceActionType.GET_ALL_PROJECT_RESOURCE_TYPE_SOURCES;

  /**
   * Fires a new  project resource type action.
   */
  constructor() {}
}

/**
 * Represents a store  project resource type fail action.
 */
export class GetAllProjectResourceTypeSourcesFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = ProjectResourceTypeSourceActionType.GET_ALL_PROJECT_RESOURCE_TYPE_SOURCES_FAIL;

  /**
   * Fires a new  project resource type fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store  project resource type success action.
 */
export class GetAllProjectResourceTypeSourcesSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = ProjectResourceTypeSourceActionType.GET_ALL_PROJECT_RESOURCE_TYPE_SOURCES_SUCCESS;

  /**
   * Fires a  project resource type success action.
   * @param payload An object contains the list of  Cities.
   */
  constructor(public payload: AppHttpResponse<ProjectResourceTypeSource[]>) {}
}

/**
 * Lookups-module  project resource type source action types.
 */
export type ProjectResourceTypeSourceActions =
  | GetAllProjectResourceTypeSources
  | GetAllProjectResourceTypeSourcesFail
  | GetAllProjectResourceTypeSourcesSuccess;
