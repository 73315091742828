<!-- sales invoices search modal starts -->
<ng-template #modalRef let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title text-primary" id="modal-basic-title">
      {{ 'SALES.INVOICES.SALES_INVOICES_LIST' | translate }}
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="d(); closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <!-- sales invoices list starts -->
    <div class="row">
      <div class="col-md-12">
        <form class="row" [formGroup]="searchForm" (ngSubmit)="search()">
          <div class="form-group col-md-4">
            <label for="locations">{{ 'SALES.INVOICES.SALE_INVOICE_DATA.SALE_INVOICE_LOCATIONS' | translate }}</label>
            <ng-select
              class="round"
              formControlName="locations"
              [multiple]="true"
              [closeOnSelect]="false"
              labelForId="locations"
              searchable="true"
              [placeholder]="'SALES.INVOICES.SALE_INVOICE_DATA.SALE_INVOICE_LOCATIONS_PLACEHOLDER' | translate"
              [notFoundText]="'SHARED.SEARCH.SEARCH_NOT_FOUND' | translate"
              (open)="locationsListVisibility = true"
            >
              <ng-option *ngFor="let location of locations" [value]="location.id">
                {{ location.name | localize: location.nameEn | async }}
              </ng-option>
            </ng-select>
          </div>
          <div class="form-group col-md-4">
            <label for="customers">{{ 'SALES.INVOICES.SALE_INVOICE_DATA.CUSTOMERS' | translate }}</label>
            <ng-select
              class="round"
              formControlName="customers"
              [multiple]="true"
              [closeOnSelect]="false"
              labelForId="customers"
              searchable="true"
              [placeholder]="'SALES.INVOICES.SALE_INVOICE_DATA.CUSTOMERS_PLACEHOLDER' | translate"
              [notFoundText]="'SHARED.SEARCH.SEARCH_NOT_FOUND' | translate"
              (open)="customersListVisibility = true"
            >
              <ng-option *ngFor="let customer of customers" [value]="customer.id">
                {{ customer.name | localize: customer.nameEn | async }}
              </ng-option>
            </ng-select>
          </div>
          <div class="form-group col-md-4">
            <label for="saleInvoiceTypes">{{ 'SALES.INVOICES.SALE_INVOICE_DATA.SALE_INVOICE_TYPE' | translate }}</label>
            <ng-select
              class="round"
              formControlName="saleInvoiceTypes"
              [multiple]="true"
              [closeOnSelect]="false"
              labelForId="saleInvoiceTypes"
              searchable="true"
              [placeholder]="'SALES.INVOICES.SALE_INVOICE_DATA.SALE_INVOICE_TYPE_PLACEHOLDER' | translate"
              [notFoundText]="'SHARED.SEARCH.SEARCH_NOT_FOUND' | translate"
            >
              <ng-option *ngFor="let saleInvoiceType of saleInvoiceTypes$ | async" [value]="saleInvoiceType.id">
                {{ saleInvoiceType.name | localize: saleInvoiceType.nameEn | async }}
              </ng-option>
            </ng-select>
          </div>
          <div class="form-group col-md-4">
            <label for="invoiceNum">{{ 'SALES.INVOICES.SALE_INVOICE_DATA.INVOICE_NUMBER' | translate }}</label>
            <input
              type="number"
              id="invoiceNum"
              name="invoiceNum"
              class="form-control round"
              [placeholder]="'SALES.INVOICES.SALE_INVOICE_DATA.INVOICE_NUMBER_PLACEHOLDER' | translate"
              formControlName="invoiceNum"
            />
          </div>
          <div class="form-group col-md-4">
            <label for="orderNum">{{ 'SALES.INVOICES.SALE_INVOICE_DATA.ORDER_NUMBER' | translate }}</label>
            <input
              type="number"
              id="orderNum"
              name="orderNum"
              class="form-control round"
              [placeholder]="'SALES.INVOICES.SALE_INVOICE_DATA.ORDER_NUMBER_PLACEHOLDER' | translate"
              formControlName="orderNum"
            />
          </div>
          <div class="form-group col-md-4">
            <label for="saleInvoiceOrderTypes">{{
              'SALES.INVOICES.SALE_INVOICE_DATA.SALE_INVOICE_ORDER_TYPE' | translate
            }}</label>
            <ng-select
              class="round"
              formControlName="saleInvoiceOrderTypes"
              [multiple]="true"
              [closeOnSelect]="false"
              labelForId="saleInvoiceOrderTypes"
              searchable="true"
              [placeholder]="'SALES.INVOICES.SALE_INVOICE_DATA.SALE_INVOICE_ORDER_TYPE_PLACEHOLDER' | translate"
              [notFoundText]="'SHARED.SEARCH.SEARCH_NOT_FOUND' | translate"
            >
              <ng-option
                *ngFor="let saleInvoiceOrderType of saleInvoiceOrderTypes$ | async"
                [value]="saleInvoiceOrderType.id"
              >
                {{ saleInvoiceOrderType.name | localize: saleInvoiceOrderType.nameEn | async }}
              </ng-option>
            </ng-select>
          </div>
          <div class="col-md-10">
            <div class="row">
              <div class="form-group col-12 col-sm-6 mb-0">
                <label for="fromDate">{{ 'SHARED.SEARCH.FROM_DATE' | translate }}</label>
                <div class="input-group round">
                  <input
                    class="form-control"
                    id="fromDate"
                    name="fromDate"
                    formControlName="fromDate"
                    [placeholder]="'SHARED.SEARCH.DATE_PLACEHOLDER' | translate"
                    ngbDatepicker
                    #fromDate="ngbDatepicker"
                  />
                  <div class="input-group-append">
                    <span
                      class="input-group-text gradient-light-blue-indigo"
                      style="cursor: pointer;"
                      (click)="fromDate.toggle()"
                    >
                      <i class="fa fa-calendar"></i>
                    </span>
                  </div>
                </div>
              </div>
              <div class="form-group col-12 col-sm-6 mb-0">
                <label for="toDate">{{ 'SHARED.SEARCH.TO_DATE' | translate }}</label>
                <div class="input-group round">
                  <input
                    class="form-control"
                    id="toDate"
                    name="toDate"
                    formControlName="toDate"
                    [placeholder]="'SHARED.SEARCH.DATE_PLACEHOLDER' | translate"
                    ngbDatepicker
                    #toDate="ngbDatepicker"
                  />
                  <div class="input-group-append">
                    <span
                      class="input-group-text gradient-light-blue-indigo"
                      style="cursor: pointer;"
                      (click)="toDate.toggle()"
                    >
                      <i class="fa fa-calendar"></i>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-auto d-flex">
            <button
              type="submit"
              class="btn btn-raised btn-primary round align-self-center mb-0"
              [disabled]="isSearching$ | async"
            >
              <i class="fa fa-search"></i>
            </button>
          </div>
        </form>
      </div>
      <div class="col-md-12" *ngIf="(invoices$ | async)?.length; else noItems">
        <div class="row">
          <div class="col-md-12 alert alert-light text-center" role="alert">
            {{ 'SHARED.SEARCH.SEARCH_RESULTS' | translate }} ({{ (paginationInfo$ | async).total | toNumber }})
            {{ 'SALES.INVOICES.SALES_INVOICE' | translate }}
          </div>
          <div class="col-md-12">
            <div class="table-responsive">
              <table class="table text-center">
                <thead>
                  <tr>
                    <th class="fit-width" *ngIf="!closeOnSelect">
                      <a
                        class="primary p-0"
                        (click)="selectInvoice(invoicesList)"
                        [ngbTooltip]="'SHARED.SEARCH.SELECT_ALL' | translate"
                      >
                        <i class="fa fa-check-circle font-medium-3"></i>
                      </a>
                    </th>
                    <th class="fit-width" *ngIf="closeOnSelect">
                      <i class="fa fa-gear font-medium-3"></i>
                    </th>
                    <th scope="col">
                      <i class="fa fa-barcode"></i
                      ><span class="mx-1">{{ 'SALES.INVOICES.SALE_INVOICE_DATA.INVOICE_NUMBER' | translate }}</span>
                    </th>
                    <th scope="col">{{ 'SALES.INVOICES.SALE_INVOICE_DATA.INVOICE_TYPE' | translate }}</th>
                    <th scope="col">{{ 'SALES.INVOICES.SALE_INVOICE_DATA.ORDER_NUMBER' | translate }}</th>
                    <th scope="col">{{ 'SALES.INVOICES.SALE_INVOICE_DATA.SALE_INVOICE_ORDER_TYPE' | translate }}</th>
                    <th scope="col">{{ 'SALES.INVOICES.SALE_INVOICE_DATA.SALE_INVOICE_LOCATION' | translate }}</th>
                    <th scope="col">{{ 'SALES.INVOICES.SALE_INVOICE_DATA.CUSTOMER' | translate }}</th>
                    <th scope="col">{{ 'SALES.INVOICES.SALE_INVOICE_DATA.PRODUCT_VALUE' | translate }}</th>
                    <th scope="col">{{ 'SALES.INVOICES.SALE_INVOICE_DATA.INVOICE_DATE' | translate }}</th>
                    <th scope="col">{{ 'SHARED.SEARCH.CREATED_AT' | translate }}</th>
                    <th scope="col">{{ 'SALES.INVOICES.SALE_INVOICE_DATA.USER' | translate }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let invoice of invoices$ | async; trackBy: trackItems">
                    <td>
                      <a
                        class="primary p-0"
                        (click)="selectInvoice([invoice])"
                        [ngbTooltip]="'SALES.INVOICES.SALE_INVOICE_DATA.INVOICE_SELECT' | translate"
                      >
                        <i class="fa fa-check-circle-o font-medium-3"></i>
                      </a>
                    </td>
                    <th scope="row">{{ invoice.invoiceNum }}</th>
                    <td>{{ invoice.saleInvoiceType.name | localize: invoice.saleInvoiceType.nameEn | async }}</td>
                    <td>{{ invoice.orderNum }}</td>
                    <td>
                      {{ invoice.saleInvoiceOrderType?.name | localize: invoice.saleInvoiceOrderType?.nameEn | async }}
                    </td>
                    <td>{{ invoice.location.name | localize: invoice.location.nameEn | async }}</td>
                    <td>{{ invoice.customer.name | localize: invoice.customer.nameEn | async }}</td>
                    <td>
                      <span class="badge round bg-success">
                        {{ invoice.net | toDecimal }}
                      </span>
                    </td>
                    <td>
                      <span [ngbTooltip]="invoice.transactionDate | date: 'time'">
                        {{ invoice.transactionDate | date }}
                      </span>
                    </td>
                    <td>
                      <span [ngbTooltip]="invoice.createdAt | date: 'time'">
                        {{ invoice.createdAt | date }}
                      </span>
                    </td>
                    <td>{{ invoice.user.name }}</td>
                  </tr>
                </tbody>
              </table>
              <div class="d-flex justify-content-between p-2">
                <ngb-pagination
                  *ngIf="paginationInfo$ | async; let pagination"
                  maxSize="5"
                  [collectionSize]="pagination.total"
                  [pageSize]="pagination.pageSize"
                  [page]="pagination.page"
                  (pageChange)="pageChanged($event)"
                  boundaryLinks="true"
                >
                  <ng-template ngbPaginationFirst>{{ 'SHARED.PAGINATION.PAGINATION_FIRST' | translate }}</ng-template>
                  <ng-template ngbPaginationPrevious>{{
                    'SHARED.PAGINATION.PAGINATION_PREVIOUS' | translate
                  }}</ng-template>
                  <ng-template ngbPaginationNext>{{ 'SHARED.PAGINATION.PAGINATION_NEXT' | translate }}</ng-template>
                  <ng-template ngbPaginationLast>{{ 'SHARED.PAGINATION.PAGINATION_LAST' | translate }}</ng-template>
                </ngb-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- sales invoices list ends -->
  </div>
  <div class="modal-footer">
    <div class="modal-actions">
      <button type="button" class="btn btn-outline-dark round" (click)="c(); closeModal()">
        <i class="fa fa-times"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.CLOSE' | translate }}</span>
      </button>
    </div>
  </div>
</ng-template>
<!-- sales invoices search modal ends -->

<!-- no items template starts -->
<ng-template #noItems>
  <div class="col-md-12 alert alert-warning mt-3 text-center" role="alert">
    {{ 'SALES.INVOICES.SEARCH_INVOICE_WITHOUT_ADD_NOT_FOUND' | translate }}
  </div>
</ng-template>
<!-- no items template ends -->

<!-- customers search starts -->
<app-customers-search onlyActive="true" (customers)="selectCustomers($event)" [(visible)]="customersListVisibility">
</app-customers-search>
<!-- customers search ends -->

<!-- locations search starts -->
<app-locations-search [(visible)]="locationsListVisibility" (locations)="selectLocations($event)">
</app-locations-search>
<!-- locations search ends -->
