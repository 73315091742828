<!-- page header starts -->
<app-page-header [info]="pageInfo"></app-page-header>
<!-- page header ends -->

<!-- customer assets list starts -->
<div class="row">
  <div class="col-md-12">
    <div class="card">
      <div class="card-header mb-3">
        <h4 class="card-title">{{ 'SHARED.SEARCH.SEARCH_TOOLS' | translate }}</h4>
      </div>
      <div class="card-content">
        <div class="px-3">
          <div class="row">
            <div class="col-md-12">
              <form class="row" [formGroup]="searchForm" (ngSubmit)="search()">
                <div class="form-group col">
                  <label for="customers">{{ 'SALES.CUSTOMER_ASSETS.CUSTOMER_ASSET_DATA.CUSTOMERS' | translate }}</label>
                  <ng-select
                    class="round"
                    formControlName="customers"
                    [multiple]="true"
                    [closeOnSelect]="false"
                    labelForId="customers"
                    searchable="true"
                    [placeholder]="'SALES.CUSTOMER_ASSETS.CUSTOMER_ASSET_DATA.CUSTOMERS_PLACEHOLDER' | translate"
                    [notFoundText]="'SHARED.SEARCH.SEARCH_NOT_FOUND' | translate"
                    (open)="customerListVisibility = true"
                  >
                    <ng-option *ngFor="let customer of customers" [value]="customer.id">
                      {{ customer.name | localize: customer.nameEn | async }}
                    </ng-option>
                  </ng-select>
                </div>
                <div class="col-md-auto form-group d-flex">
                  <button
                    type="submit"
                    class="btn btn-raised btn-primary align-self-end round mx-1 mb-0"
                    [disabled]="isSearching$ | async"
                  >
                    <i class="fa fa-search"></i>
                  </button>
                  <a
                    class="btn btn-raised btn-success align-self-end round mx-1 mb-0"
                    (click)="create()"
                    *ngIf="[Claims.UPDATE_CUSTOMER] | authorize | async"
                  >
                    <i class="fa fa-plus"></i>
                    <span class="mx-1">{{ 'SHARED.CONFIRM.ADD' | translate }}</span>
                  </a>
                  <app-export-tools
                    *ngIf="(customerAssets$ | async)?.length"
                    class="d-flex align-self-end mx-1"
                    targetElementId="customer-assets-table-export-target-element"
                    [outputFileName]="'SHARED.EXPORT_TABLES.CUSTOMER_ASSETS_TABLE' | translate"
                  ></app-export-tools>
                </div>
              </form>
            </div>
            <div class="col-md-12" *ngIf="(customerAssets$ | async)?.length; else noItems">
              <div class="row">
                <div class="col-md-12 alert alert-light text-center" role="alert">
                  {{ 'SHARED.SEARCH.SEARCH_RESULTS' | translate }} ({{ (paginationInfo$ | async).total | toNumber }})
                  {{ 'SALES.CUSTOMER_ASSETS.ASSET' | translate }}
                </div>
                <div class="table-responsive">
                  <table class="table text-center" id="customer-assets-table-export-target-element">
                    <thead>
                      <tr>
                        <th scope="col"><i class="fa fa-list-ol"></i></th>
                        <th scope="col">
                          {{ 'SALES.CUSTOMER_ASSETS.CUSTOMER_ASSET_DATA.CUSTOMER_ASSET_DESCRIPTION' | translate }}
                        </th>
                        <th scope="col">
                          {{ 'SALES.CUSTOMER_ASSETS.CUSTOMER_ASSET_DATA.CUSTOMER' | translate }}
                        </th>
                        <th scope="col">
                          {{ 'SALES.CUSTOMER_ASSETS.CUSTOMER_ASSET_DATA.CUSTOMER_ASSET_TYPE' | translate }}
                        </th>
                        <th scope="col">
                          {{ 'SALES.CUSTOMER_ASSETS.CUSTOMER_ASSET_DATA.CUSTOMER_SERIAL_NUMBER' | translate }}
                        </th>
                        <th scope="col">{{ 'SHARED.TOOLS' | translate }}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let asset of customerAssets$ | async; trackBy: trackItems">
                        <th scope="row">{{ asset.id }}</th>
                        <td>
                          {{ asset.description }}
                        </td>
                        <td>
                          {{ asset.customer?.name | localize: asset.customer?.nameEn | async }}
                        </td>
                        <td>
                          {{ asset.type }}
                        </td>
                        <td>
                          {{ asset.serialNumber }}
                        </td>
                        <td>
                          <a
                            *ngIf="[Claims.UPDATE_CUSTOMER] | authorize | async"
                            class="info p-0"
                            [ngbTooltip]="'SHARED.CONFIRM.EDIT' | translate"
                            (click)="update(asset)"
                          >
                            <i class="fa fa-edit font-medium-3 mr-2"></i>
                          </a>
                          <a
                            *ngIf="[Claims.UPDATE_CUSTOMER] | authorize | async"
                            class="danger p-0"
                            [ngbTooltip]="'SHARED.CONFIRM.DELETE' | translate"
                            (click)="delete(asset)"
                          >
                            <i class="fa fa-trash font-medium-3 mr-2"></i>
                          </a>
                          <a
                            class="info p-0"
                            [ngbTooltip]="'SALES.CUSTOMER_ASSETS.CUSTOMER_ASSET_DATA.ATTACHMENTS_DISPLAY' | translate"
                            *ngIf="asset.customerAssetAttachments.length"
                            (click)="viewAttachments(asset)"
                          >
                            <i class="fa fa-paperclip font-medium-3 mr-2"></i>
                          </a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div class="d-flex justify-content-between p-2">
                    <ngb-pagination
                      *ngIf="paginationInfo$ | async; let pagination"
                      maxSize="5"
                      [collectionSize]="pagination.total"
                      [pageSize]="pagination.pageSize"
                      [page]="pagination.page"
                      (pageChange)="pageChanged($event)"
                      boundaryLinks="true"
                    >
                      <ng-template ngbPaginationFirst>{{
                        'SHARED.PAGINATION.PAGINATION_FIRST' | translate
                      }}</ng-template>
                      <ng-template ngbPaginationPrevious>{{
                        'SHARED.PAGINATION.PAGINATION_PREVIOUS' | translate
                      }}</ng-template>
                      <ng-template ngbPaginationNext>{{ 'SHARED.PAGINATION.PAGINATION_NEXT' | translate }}</ng-template>
                      <ng-template ngbPaginationLast>{{ 'SHARED.PAGINATION.PAGINATION_LAST' | translate }}</ng-template>
                    </ngb-pagination>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- customer assets list ends -->

<!-- no items template starts -->
<ng-template #noItems>
  <div class="col-md-12 alert alert-warning mt-3 text-center" role="alert">
    {{ 'SALES.CUSTOMER_ASSETS.SEARCH_RESULTS_NOT_FOUND' | translate }}
    <button
      class="btn btn-raised btn-success round"
      (click)="create()"
      *ngIf="[Claims.UPDATE_CUSTOMER] | authorize | async"
    >
      <i class="fa fa-plus"></i>
      <span class="mx-1">{{ 'SHARED.CONFIRM.ADD' | translate }}</span>
    </button>
  </div>
</ng-template>
<!-- no items template ends -->

<!-- create asset modal starts -->
<ng-template #createModalRef let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
      {{ 'SALES.CUSTOMER_ASSETS.CREATE_CUSTOMER_ASSET' | translate }}
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="d()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-md-12">
        <form [formGroup]="createForm" (ngSubmit)="confirmCreate()">
          <div>
            <h4>{{ 'SALES.CUSTOMER_ASSETS.ASSET_DATA' | translate }}</h4>
          </div>
          <div class="row">
            <div class="form-group col-md-12">
              <label for="customerId">{{ 'SALES.CUSTOMER_ASSETS.CUSTOMER_ASSET_DATA.CUSTOMER' | translate }}</label>
              <ng-select
                class="round"
                formControlName="customerId"
                labelForId="customerId"
                searchable="true"
                [placeholder]="'SALES.CUSTOMER_ASSETS.CUSTOMER_ASSET_DATA.CUSTOMERS_PLACEHOLDER' | translate"
                [notFoundText]="'SHARED.SEARCH.SEARCH_NOT_FOUND' | translate"
                (open)="customersListVisibility = true"
              >
                <ng-option *ngFor="let customer of customers" [value]="customer.id">
                  {{ customer.name | localize: customer.nameEn | async }}
                </ng-option>
              </ng-select>
            </div>
            <div class="form-group col-md-12">
              <label for="description">
                {{ 'SALES.CUSTOMER_ASSETS.CUSTOMER_ASSET_DATA.CUSTOMER_ASSET_DESCRIPTION' | translate }}</label
              >
              <textarea
                id="description"
                class="form-control round"
                [placeholder]="
                  'SALES.CUSTOMER_ASSETS.CUSTOMER_ASSET_DATA.CUSTOMER_ASSET_DESCRIPTION_PLACEHOLDER' | translate
                "
                minlength="1"
                maxlength="200"
                formControlName="description"
                rows="1"
              ></textarea>
            </div>
            <div class="form-group col-md-12">
              <label for="type">
                {{ 'SALES.CUSTOMER_ASSETS.CUSTOMER_ASSET_DATA.CUSTOMER_ASSET_TYPE' | translate }}</label
              >
              <input
                type="text"
                id="type"
                name="type"
                class="form-control round"
                [placeholder]="'SALES.CUSTOMER_ASSETS.CUSTOMER_ASSET_DATA.CUSTOMER_ASSET_TYPE_PLACEHOLDER' | translate"
                formControlName="type"
              />
            </div>
            <div class="form-group col-md-12">
              <label for="serialNumber">
                {{ 'SALES.CUSTOMER_ASSETS.CUSTOMER_ASSET_DATA.CUSTOMER_SERIAL_NUMBER' | translate }}</label
              >
              <input
                type="text"
                id="serialNumber"
                name="serialNumber"
                class="form-control round"
                [placeholder]="
                  'SALES.CUSTOMER_ASSETS.CUSTOMER_ASSET_DATA.CUSTOMER_SERIAL_NUMBER_PLACEHOLDER' | translate
                "
                formControlName="serialNumber"
              />
            </div>
            <div class="form-group col-md-12">
              <label for="photo"> {{ 'SALES.CUSTOMER_ASSETS.CUSTOMER_ASSET_DATA.ATTACHMENTS' | translate }}</label>
              <app-files-upload formArrayName="attachments" [filesForm]="attachmentsForm"></app-files-upload>
            </div>
            <!-- A hidden submit button to allow user submit form with Enter -->
            <button type="submit" hidden></button>
          </div>
        </form>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="modal-actions">
      <button
        type="button"
        class="btn btn-raised btn-success round"
        [disabled]="isCustomerAssetCreating$ | async"
        (click)="confirmCreate()"
        ngbAutofocus
      >
        <i class="fa fa-check"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.SAVE' | translate }}</span>
      </button>
      <button type="button" class="btn btn-outline-dark round" (click)="c()">
        <i class="fa fa-times"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.CANCEL' | translate }}</span>
      </button>
    </div>
  </div>
</ng-template>
<!-- create asset modal ends -->

<!-- update asset modal starts -->
<ng-template #updateModalRef let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title text-primary" id="modal-basic-title">
      {{ 'SALES.CUSTOMER_ASSETS.UPDATE_CUSTOMER_ASSET_DATA' | translate }}
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="d()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-md-12">
        <form [formGroup]="updateForm" (ngSubmit)="confirmUpdate()">
          <div>
            <h4>{{ 'SALES.CUSTOMER_ASSETS.ASSET_DATA' | translate }}</h4>
          </div>
          <div class="row">
            <div class="form-group col-md-12">
              <label for="customerId">{{ 'SALES.CUSTOMER_ASSETS.CUSTOMER_ASSET_DATA.CUSTOMER' | translate }}</label>
              <ng-select
                class="round"
                formControlName="customerId"
                labelForId="customerId"
                searchable="true"
                [placeholder]="'SALES.CUSTOMER_ASSETS.CUSTOMER_ASSET_DATA.CUSTOMERS_PLACEHOLDER' | translate"
                [notFoundText]="'SHARED.SEARCH.SEARCH_NOT_FOUND' | translate"
                (open)="customersListVisibility = true"
              >
                <ng-option *ngFor="let customer of customers" [value]="customer.id">
                  {{ customer.name | localize: customer.nameEn | async }}
                </ng-option>
              </ng-select>
            </div>
            <div class="form-group col-md-12">
              <label for="description">
                {{ 'SALES.CUSTOMER_ASSETS.CUSTOMER_ASSET_DATA.CUSTOMER_ASSET_DESCRIPTION' | translate }}</label
              >
              <textarea
                id="description"
                class="form-control round"
                [placeholder]="
                  'SALES.CUSTOMER_ASSETS.CUSTOMER_ASSET_DATA.CUSTOMER_ASSET_DESCRIPTION_PLACEHOLDER' | translate
                "
                minlength="1"
                maxlength="200"
                formControlName="description"
                rows="1"
              ></textarea>
            </div>
            <div class="form-group col-md-12">
              <label for="type">
                {{ 'SALES.CUSTOMER_ASSETS.CUSTOMER_ASSET_DATA.CUSTOMER_ASSET_TYPE' | translate }}</label
              >
              <input
                type="text"
                id="type"
                name="type"
                class="form-control round"
                [placeholder]="'SALES.CUSTOMER_ASSETS.CUSTOMER_ASSET_DATA.CUSTOMER_ASSET_TYPE_PLACEHOLDER' | translate"
                formControlName="type"
              />
            </div>
            <div class="form-group col-md-12">
              <label for="serialNumber">
                {{ 'SALES.CUSTOMER_ASSETS.CUSTOMER_ASSET_DATA.CUSTOMER_SERIAL_NUMBER' | translate }}</label
              >
              <input
                type="text"
                id="serialNumber"
                name="serialNumber"
                class="form-control round"
                [placeholder]="
                  'SALES.CUSTOMER_ASSETS.CUSTOMER_ASSET_DATA.CUSTOMER_SERIAL_NUMBER_PLACEHOLDER' | translate
                "
                formControlName="serialNumber"
              />
            </div>
            <!-- A hidden submit button to allow user submit form with Enter -->
            <button type="submit" hidden></button>
          </div>
        </form>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="modal-actions">
      <button
        type="button"
        class="btn btn-raised btn-primary round"
        [disabled]="isCustomerAssetUpdating$ | async"
        (click)="confirmUpdate()"
        ngbAutofocus
      >
        <i class="fa fa-check"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.SAVE' | translate }}</span>
      </button>
      <button type="button" class="btn btn-outline-dark round" (click)="c()">
        <i class="fa fa-times"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.CANCEL' | translate }}</span>
      </button>
    </div>
  </div>
</ng-template>
<!-- update asset modal ends -->

<!-- delete asset modal starts -->
<ng-template #deleteModalRef let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title text-danger" id="modal-basic-title">
      {{ 'SALES.CUSTOMER_ASSETS.DELETE_CUSTOMER_ASSET' | translate }}
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="d()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-md-12">
        <div>
          <h4>{{ 'SALES.CUSTOMER_ASSETS.ASSET_DATA' | translate }}</h4>
        </div>
        <div class="row">
          <div class="form-group col-md-12">
            <label for="customerName">{{ 'SALES.CUSTOMER_ASSETS.CUSTOMER_ASSET_DATA.CUSTOMER' | translate }}</label>
            <input
              type="text"
              id="customerName"
              class="form-control round"
              readonly
              [value]="selectedAsset.customer.name | localize: selectedAsset.customer.nameEn | async"
            />
          </div>
          <div class="form-group col-md-12">
            <label for="description">
              {{ 'SALES.CUSTOMER_ASSETS.CUSTOMER_ASSET_DATA.CUSTOMER_ASSET_DESCRIPTION' | translate }}</label
            >
            <textarea
              id="description"
              class="form-control round"
              [placeholder]="
                'SALES.CUSTOMER_ASSETS.CUSTOMER_ASSET_DATA.CUSTOMER_ASSET_DESCRIPTION_PLACEHOLDER' | translate
              "
              readonly
              [value]="selectedAsset.description"
              rows="1"
            ></textarea>
          </div>
          <div class="form-group col-md-12">
            <label for="typeName">
              {{ 'SALES.CUSTOMER_ASSETS.CUSTOMER_ASSET_DATA.CUSTOMER_ASSET_TYPE' | translate }}</label
            >
            <input
              type="text"
              id="typeName"
              class="form-control round"
              [placeholder]="'SALES.CUSTOMER_ASSETS.CUSTOMER_ASSET_DATA.CUSTOMER_ASSET_TYPE_PLACEHOLDER' | translate"
              [value]="selectedAsset.type"
              readonly
            />
          </div>
          <div class="form-group col-md-12">
            <label for="serialNumber">
              {{ 'SALES.CUSTOMER_ASSETS.CUSTOMER_ASSET_DATA.CUSTOMER_SERIAL_NUMBER' | translate }}</label
            >
            <input
              type="text"
              id="serialNumber"
              class="form-control round"
              [placeholder]="'SALES.CUSTOMER_ASSETS.CUSTOMER_ASSET_DATA.CUSTOMER_SERIAL_NUMBER_PLACEHOLDER' | translate"
              [value]="selectedAsset.serialNumber"
              readonly
            />
          </div>
          <!-- A hidden submit button to allow user submit form with Enter -->
          <button type="submit" hidden></button>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="modal-actions">
      <button
        type="button"
        class="btn btn-raised btn-danger round"
        [disabled]="isCustomerAssetDeleting$ | async"
        (click)="confirmDelete()"
        ngbAutofocus
      >
        <i class="fa fat-trash"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.DELETE' | translate }}</span>
      </button>
      <button type="button" class="btn btn-outline-dark round" (click)="c()">
        <i class="fa fa-times"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.CANCEL' | translate }}</span>
      </button>
    </div>
  </div>
</ng-template>
<!-- delete asset modal ends -->

<!-- view customer assets attachments modal starts -->
<ng-template #viewAttachmentsModalRef let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title text-primary" id="modal-basic-title">
      {{ 'SALES.CUSTOMER_ASSETS.CUSTOMER_ASSET_DATA.ATTACHMENTS_ASSET' | translate }}
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="d()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-md-12">
        <app-files-viewer
          *ngIf="selectedAsset.customerAssetAttachments?.length; else noAttachments"
          [files]="getAttachmentsUrls(selectedAsset.customerAssetAttachments)"
        ></app-files-viewer>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="modal-actions">
      <button type="button" class="btn btn-outline-dark round" (click)="c()">
        <i class="fa fa-times"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.CANCEL' | translate }}</span>
      </button>
    </div>
  </div>
</ng-template>
<!-- view customer assets attachments modal ends -->

<!-- no attachments template starts -->
<ng-template #noAttachments>
  <div class="col-md-12 alert alert-warning mt-3 text-center" role="alert">
    {{ 'SALES.CUSTOMER_ASSETS.ATTACHMENTS_ASSET_NOT_FOUND' | translate }}
  </div>
</ng-template>
<!-- no attachments template ends -->

<!-- customers search starts -->
<app-customers-search [(visible)]="customerListVisibility" (customers)="selectCustomers($event)">
</app-customers-search>
<!-- customers search ends -->

<!-- customers search starts -->
<app-customers-search
  onlyActive="true"
  closeOnSelect="true"
  (customers)="selectCustomer($event)"
  [(visible)]="customersListVisibility"
>
</app-customers-search>
<!-- customers search ends -->
