import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormControl, FormArray } from '@angular/forms';

import { Observable, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

import * as fromAppSearchStore from 'app-search/store';
import * as fromAuthStore from 'auth/store';
import { PaginationInfo, NotificationService, TranslationService } from 'shared';
import { Employee } from 'hr/models';
import { Location } from 'stores/models';

/**
 * sales persons search form wrapped in modal.
 */
@Component({
  selector: 'sales-persons-search',
  templateUrl: './sales-persons-search.component.html',
  styles: [],
})
export class SalesPersonsSearchComponent implements OnInit {
  /**
   * The modal template reference.
   */
  @ViewChild('modalRef') modalRef: ElementRef<any>;

  /**
   * Indicates whether the modal will automatically close after select the first employee.
   *
   * Be default it will not be closed.
   */
  @Input() closeOnSelect = false;

  /**
   * Indicates whether the locations displayed on the list should be all active.
   *
   * Be default it will not be closed.
   */
  @Input() onlyActive = false;

  /**
   * Sets the visibility of this component modal.
   *
   * Handles visibility change in two-data-binding approach.
   */
  @Input() set visible(isShown: boolean) {
    if (isShown && this.modalRef) {
      this.openModal(this.modalRef);
    } else {
      this.closeModal();
    }

    this.visibleChange.emit(isShown);
  }

  /**
   * Sets the employees form-array.
   */
  @Input() employeesForm: FormArray;

  /**
   * Outputs a value that indicates whether the modal is shown or not.
   *
   * Handles visibility change in two-data-binding approach.
   */
  @Output() visibleChange = new EventEmitter<boolean>();

  /** Outputs the selected employees. */
  @Output() employees = new EventEmitter<Employee[]>();

  /**
   * Shows or hide the create employee modal.
   */
  createEmployeeModalVisibility = false;

  /**
   * The list of employees.
   */
  employees$: Observable<Employee[]>;

  /**
   * The list of employees.
   */
  employeesList: Employee[];

  /**
   * Shows or hide the locations list.
   */
  locationsListVisibility = false;

  /**
   * The list of selected locations.
   */
  locations: Location[] = [];

  /**
   * The pagination info.
   */
  paginationInfo$: Observable<PaginationInfo>;

  /**
   * Indicates whether there is a search process is running or not.
   */
  isSearching$: Observable<boolean>;

  /**
   * The search form.
   */
  searchForm: FormGroup;

  /**
   * The ngb-modal reference to use it when modal needed to be closed.
   */
  modal: NgbModalRef;

  /**
   * The set of subscriptions on this components,
   * these subscriptions must be unsubscribed before this component got destroyed.
   */
  subscriptions = new Subscription();

  /**
   * @param modalService The modal service.
   * @param notificationService The notification service.
   * @param translationService The translation service.
   * @param appSearchStore$ App-search-module store.
   * @param authStore$ the auth-store module.
   */
  constructor(
    private modalService: NgbModal,
    private notificationService: NotificationService,
    private translationService: TranslationService,
    private appSearchStore$: Store<fromAppSearchStore.AppSearchState>,
    private authStore$: Store<fromAuthStore.AuthState>
  ) {}

  ngOnInit(): void {
    this.init();
  }

  /**
   * Initialize component data.
   */
  init() {
    this.initForm();

    /**
     * Loads data.
     */
    this.isSearching$ = this.appSearchStore$.pipe(select(fromAppSearchStore.getSalesPersonsSearching));

    let isManualSearchTriggeredBeforeForSalesPersons = false;
    this.employees$ = this.appSearchStore$.pipe(
      select(fromAppSearchStore.getSalesPersons),
      tap((employees) => {
        if (employees) {
          this.employeesList = employees;
        }
        if (!isManualSearchTriggeredBeforeForSalesPersons && !employees.length) {
          isManualSearchTriggeredBeforeForSalesPersons = true;
          this.search();
        }
      })
    );

    /* Subscribe to selected location to select it whenever it changed. */
    this.subscriptions.add(
      this.authStore$
        .pipe(
          select(fromAuthStore.getSessionUserDefaultLocation),
          tap((location) => {
            if (location && !this.searchForm.value.locations.includes(location.id)) {
              this.selectLocations([location]);
            }
          })
        )
        .subscribe()
    );

    this.paginationInfo$ = this.appSearchStore$.pipe(select(fromAppSearchStore.getSalesPersonsPaginationInfo));

    this.search();
  }

  /**
   * Initialize form and add validators.
   */
  initForm() {
    this.searchForm = new FormGroup({
      name: new FormControl(''),
      locations: new FormControl([]),
      page: new FormControl(1),
    });
  }

  /**
   * Handles search parameters change.
   */
  search(event?: KeyboardEvent) {
    if (event && event.key === 'Enter') {
      event.preventDefault();
      return;
    }
    this.appSearchStore$.dispatch(
      new fromAppSearchStore.SearchSalesPersons({
        name: this.searchForm.get('name').value,
        locations: this.searchForm.get('locations').value,
        page: 1,
      })
    );
  }

  /**
   * Handles pagination page-changed event.
   * @param page The current selected page number.
   */
  pageChanged(page: number) {
    /** Update pagination page. */
    this.searchForm.patchValue({ page });

    /** Get selected page items. */
    this.appSearchStore$.dispatch(
      new fromAppSearchStore.SearchSalesPersons({
        name: this.searchForm.get('name').value,
        locations: this.searchForm.get('locations').value,
        page: this.searchForm.get('page').value,
      })
    );
  }

  /**
   * Enables trackBy feature for *ngFor utility to track items
   * depending on a comparer to reduce DOM change.
   * If the item is already exist then no HTML will change, only bounded values.
   * @param index The index of the item.
   * @param employee The item to determine if it was changed or not.
   */
  trackItems(index: number, employee: Employee) {
    return employee ? employee.id : undefined;
  }

  /**
   * Opens the modal of the given templateRef.
   * @param modalRef The modal templateRef to be opened.
   */
  openModal(modalRef) {
    /**
     * Open the modal & listen for user press ESC key.
     */
    this.modal = this.modalService.open(modalRef, { size: 'lg' });
    this.modal.result.then(null, () => this.closeModal());
  }

  /**
   * Closes the currently opened modal.
   */
  closeModal() {
    this.modal?.dismiss();
    this.employees.emit([]);
    this.visibleChange.emit(false);
  }

  /**
   * Handles location selected event.
   * @param employees The list of locations to notify selected.
   */
  selectEmployee(employees: Employee[]) {
    this.notificationService.successInstant(
      this.translationService.translate('HR.EMPLOYEES.NOTIFICATION_MESSAGE.EMPLOYEE_SELECTED')
    );

    this.employees.emit(employees);
    if (this.closeOnSelect) {
      this.closeModal();
    }
  }

  /**
   * Adds the newly selected locations the locations search list.
   * @param locations The list of newly selected locations to be added.
   */
  selectLocations(locations: Location[]) {
    const selectedIds: number[] = this.searchForm.get('locations').value;
    this.locations = [...this.locations.filter((location) => selectedIds.includes(location.id)), ...locations];
    this.searchForm.patchValue({ locations: this.locations.map((location) => location.id) });
  }

  /**
   * Extract the contacts from the given employee object.
   * @param employee The employee object.
   */
  extractContacts(employee: Employee): string {
    let contacts = employee.employeePhone.phone ?? '';

    contacts = employee.employeeEmail.email
      ? contacts
        ? `${contacts}\n${employee.employeeEmail.email}`
        : employee.employeeEmail.email
      : contacts;

    return contacts;
  }
}
