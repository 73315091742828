import { Action } from '@ngrx/store';

import {
  Product,
  CreateProductInput,
  UpdateProductInput,
  ProductStock,
  CreateProductDiscountInput,
  ProductSalesDiscount,
  UpdateProductSalesDiscountInput,
  ProductUnitOfMeasureRate,
  UpdateProductUnitOfMeasureRateInput,
} from 'stores/models';
import { AppHttpResponse } from 'shared';

/**
 * The Stores-Module store products action types.
 */
export enum ProductsActionType {
  SEARCH_PRODUCTS = '[Stores] [Products] Search Products',
  SEARCH_PRODUCTS_FAIL = '[Stores] [Products] Search Products Fail',
  SEARCH_PRODUCTS_SUCCESS = '[Stores] [Products] Search Products Success',

  SEARCH_PRODUCT_SALES_DISCOUNTS = '[Stores] [Products] Search Product Sales Discounts ',
  SEARCH_PRODUCT_SALES_DISCOUNTS_FAIL = '[Stores] [Products] Search Product Sales Discounts Fail',
  SEARCH_PRODUCT_SALES_DISCOUNTS_SUCCESS = '[Stores] [Products] Search Product Sales Discounts Success',

  SEARCH_PRODUCTS_FOR_SALES_SCREEN = '[Stores] [Products] Search Products for sales screen',
  SEARCH_PRODUCTS_FOR_SALES_SCREEN_FAIL = '[Stores] [Products] Search Products for sales screen Fail',
  SEARCH_PRODUCTS_FOR_SALES_SCREEN_SUCCESS = '[Stores] [Products] Search Products for sales screen Success',
  SEARCH_PRODUCTS_WITHOUT_PAGINATION = '[Stores] [Products] Search Products Without Pagination',

  FIND_PRODUCT = '[Stores] [Products] Find Product',
  FIND_PRODUCT_FAIL = '[Stores] [Products] Find Product Fail',
  FIND_PRODUCT_SUCCESS = '[Stores] [Products] Find Product Success',

  FIND_PRODUCT_BY_CODE_OR_BARCODE = '[Stores] [Products] Find Product By Code Or Barcode',
  FIND_PRODUCT_BY_CODE_OR_BARCODE_FAIL = '[Stores] [Products] Find Product By Code Or Barcode Fail',
  FIND_PRODUCT_BY_CODE_OR_BARCODE_SUCCESS = '[Stores] [Products] Find Product By Code Or Barcode Success',

  CREATE_PRODUCT = '[Stores] [Products] Create Product',
  CREATE_PRODUCT_FAIL = '[Stores] [Products] Create Product Fail',
  CREATE_PRODUCT_SUCCESS = '[Stores] [Products] Create Product Success',

  UPDATE_PRODUCT = '[Stores] [Products] Update Product',
  UPDATE_PRODUCT_FAIL = '[Stores] [Products] Update Product Fail',
  UPDATE_PRODUCT_SUCCESS = '[Stores] [Products] Update Product Success',

  BLOCK_PRODUCT = '[Stores] [Products] Block Product',
  BLOCK_PRODUCT_FAIL = '[Stores] [Products] Block Product Fail',
  BLOCK_PRODUCT_SUCCESS = '[Stores] [Products] Block Product Success',

  ACTIVATE_PRODUCT = '[Stores] [Products] Activate Product',
  ACTIVATE_PRODUCT_FAIL = '[Stores] [Products] Activate Product Fail',
  ACTIVATE_PRODUCT_SUCCESS = '[Stores] [Products] Activate Product Success',

  DELETE_PRODUCT = '[Stores] [Products] Delete Product',
  DELETE_PRODUCT_FAIL = '[Stores] [Products] Delete Product Fail',
  DELETE_PRODUCT_SUCCESS = '[Stores] [Products] Delete Product Success',

  GET_PRODUCT_STOCKS_BY_PRODUCT_ID = '[Stores] [Products] Get Product Stocks By Product Id',
  GET_PRODUCT_STOCKS_BY_PRODUCT_ID_FAIL = '[Stores] [Products] Get Products Stocks By Product Id Fail',
  GET_PRODUCT_STOCKS_BY_PRODUCT_ID_SUCCESS = '[Stores] [Products] Get Products Stocks By Product Id Success',

  UPDATE_PRODUCT_PHOTO = '[Stores] [Products] Update Product Photo',
  DELETE_PRODUCT_PHOTO = '[Stores] [Products] Delete Product Photo',

  CREATE_PRODUCT_DISCOUNTS = '[Stores] [Products] Create Product Discount',
  CREATE_PRODUCT_DISCOUNTS_FAIL = '[Stores] [Products] Create Product Discount Fail',
  CREATE_PRODUCT_DISCOUNTS_SUCCESS = '[Stores] [Products] Create Product Discount Success',

  UPDATE_PRODUCT_SALES_DISCOUNT = '[Stores] [Products] Update Product Sales Discount',
  UPDATE_PRODUCT_SALES_DISCOUNT_FAIL = '[Stores] [Products] Update Product Sales Discount Fail',
  UPDATE_PRODUCT_SALES_DISCOUNT_SUCCESS = '[Stores] [Products] Update Product Sales Discount Success',

  DELETE_PRODUCT_SALES_DISCOUNT = '[Stores] [Products] Delete Product Sales Discount',
  DELETE_PRODUCT_SALES_DISCOUNT_FAIL = '[Stores] [Products] Delete Product Sales Discount Fail',
  DELETE_PRODUCT_SALES_DISCOUNT_SUCCESS = '[Stores] [Products] Delete Product Sales Discount Success',

  UPDATE_PRODUCT_UNIT_OF_MEASURE_RATE = '[Stores] [Products] Update Product Unit of measure rate',
  UPDATE_PRODUCT_UNIT_OF_MEASURE_RATE_FAIL = '[Stores] [Products] Update Product Unit of measure rate Fail',
  UPDATE_PRODUCT_UNIT_OF_MEASURE_RATE_SUCCESS = '[Stores] [Products] Update Product Unit of measure rate Success',

  DELETE_PRODUCT_UNIT_OF_MEASURE_RATE = '[Stores] [Products] Delete Product Unit of measure rate',
  DELETE_PRODUCT_UNIT_OF_MEASURE_RATE_FAIL = '[Stores] [Products] Delete Product Unit of measure rate Fail',
  DELETE_PRODUCT_UNIT_OF_MEASURE_RATE_SUCCESS = '[Stores] [Products] Delete Product Unit of measure rate Success',
}

/**
 * Represents a store products search action.
 */
export class SearchProducts implements Action {
  /**
   * The type of the action.
   */
  readonly type = ProductsActionType.SEARCH_PRODUCTS;

  /**
   * Fires a new products search action.
   * @param payload the search parameters,
   * if omitted, all products will be loaded.
   */
  constructor(
    public payload?: {
      description: string;
      classes: number[];
      locations: number[];
      searchForStorableProducts: boolean;
      searchForServiceProducts: boolean;
      searchForAllProductTypes: boolean;
      page: number;
    }
  ) {}
}

/**
 * Represents a store products search-fail action.
 */
export class SearchProductsFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = ProductsActionType.SEARCH_PRODUCTS_FAIL;

  /**
   * Fires a new products search-fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store search-success action.
 */
export class SearchProductsSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = ProductsActionType.SEARCH_PRODUCTS_SUCCESS;

  /**
   * Fires a new products search-success action.
   * @param payload An object contains the list of products that matches the search criteria
   * in addition to the pagination info.
   */
  constructor(public payload: AppHttpResponse<Product[]>) {}
}

/**
 * Represents a store product sales discounts search action.
 */
export class SearchProductSalesDiscounts implements Action {
  /**
   * The type of the action.
   */
  readonly type = ProductsActionType.SEARCH_PRODUCT_SALES_DISCOUNTS;

  /**
   * Fires a new product sales discounts search action.
   * @param payload the search parameters,
   * if omitted, all products discounts will be loaded.
   */
  constructor(
    public payload?: {
      description: string;
      classes: number[];
      locations: number[];
      page: number;
    }
  ) {}
}

/**
 * Represents a store product sales discounts search-fail action.
 */
export class SearchProductSalesDiscountsFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = ProductsActionType.SEARCH_PRODUCT_SALES_DISCOUNTS_FAIL;

  /**
   * Fires a new product sales discounts search-fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store search-success action.
 */
export class SearchProductSalesDiscountsSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = ProductsActionType.SEARCH_PRODUCT_SALES_DISCOUNTS_SUCCESS;

  /**
   * Fires a new product sales discounts search-success action.
   * @param payload An object contains the list of product sales discounts that matches the search criteria
   * in addition to the pagination info.
   */
  constructor(public payload: AppHttpResponse<ProductSalesDiscount[]>) {}
}

/**
 * Represents a store products for sales screen search action.
 */
export class SearchProductsForSalesScreen implements Action {
  /**
   * The type of the action.
   */
  readonly type = ProductsActionType.SEARCH_PRODUCTS_FOR_SALES_SCREEN;

  /**
   * Fires a new products for sales screen search action.
   * if omitted, all products for sales screen will be loaded.
   */
  constructor(public payload?: number[]) {}
}

/**
 * Represents a store products for sales screen search-fail action.
 */
export class SearchProductsForSalesScreenFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = ProductsActionType.SEARCH_PRODUCTS_FOR_SALES_SCREEN_FAIL;

  /**
   * Fires a new products for sales screen search-fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store search-success action.
 */
export class SearchProductsForSalesScreenSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = ProductsActionType.SEARCH_PRODUCTS_FOR_SALES_SCREEN_SUCCESS;

  /**
   * Fires a new products for sales screen search-success action.
   * @param payload An object contains the list of products for sales screen that matches the search criteria
   * in addition to the pagination info.
   */
  constructor(public payload: AppHttpResponse<Product[]>) {}
}

/**
 * Represents a store products without pagination search action.
 */
export class SearchProductsWithoutPagination implements Action {
  /**
   * The type of the action.
   */
  readonly type = ProductsActionType.SEARCH_PRODUCTS_WITHOUT_PAGINATION;

  /**
   * Fires a new products without pagination search action.
   * @param payload the search parameters,
   * if omitted, all products will be loaded.
   */
  constructor(
    public payload?: {
      description: string;
      classes: number[];
      searchForStorableProducts: boolean;
      searchForServiceProducts: boolean;
      searchForAllProductTypes: boolean;
      locations: number[];
    }
  ) {}
}

/**
 * Represents a store find-product action.
 */
export class FindProduct implements Action {
  /**
   * The type of the action.
   */
  readonly type = ProductsActionType.FIND_PRODUCT;

  /**
   * Fires a new find product action.
   * @param payload the id of the product.
   */
  constructor(public payload: number) {}
}

/**
 * Represents a store find-product-fail action.
 */
export class FindProductFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = ProductsActionType.FIND_PRODUCT_FAIL;

  /**
   * Fires a new find-product-fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store find product-success action.
 */
export class FindProductSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = ProductsActionType.FIND_PRODUCT_SUCCESS;

  /**
   * Fires a new find-product-success action.
   * @param payload The loaded product.
   */
  constructor(public payload: AppHttpResponse<Product>) {}
}

/**
 * Represents a store find-product-by-code-or-barcode action.
 */
export class FindProductByCodeOrBarcode implements Action {
  /**
   * The type of the action.
   */
  readonly type = ProductsActionType.FIND_PRODUCT_BY_CODE_OR_BARCODE;

  /**
   * Fires a new find product action.
   * @param payload the id of the product.
   */
  constructor(public payload: number | string) {}
}

/**
 * Represents a store find-product-by-code-or-barcode-fail action.
 */
export class FindProductByCodeOrBarcodeFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = ProductsActionType.FIND_PRODUCT_BY_CODE_OR_BARCODE_FAIL;

  /**
   * Fires a new find-product-by-code-or-barcode-fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store find product-success action.
 */
export class FindProductByCodeOrBarcodeSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = ProductsActionType.FIND_PRODUCT_BY_CODE_OR_BARCODE_SUCCESS;

  /**
   * Fires a new find-product-by-code-or-barcode-success action.
   * @param payload The loaded product.
   */
  constructor(public payload: AppHttpResponse<Product>) {}
}

/**
 * Represents a store product create action.
 */
export class CreateProduct implements Action {
  /** The type of the action. */
  readonly type = ProductsActionType.CREATE_PRODUCT;

  /**
   * Fires a new product create action.
   * @param payload the new product data-model.
   */
  constructor(public payload: CreateProductInput) {}
}

/**
 * Represents a store product create-fail action.
 */
export class CreateProductFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = ProductsActionType.CREATE_PRODUCT_FAIL;

  /**
   * Fires a new product create-fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store product create-success action.
 */
export class CreateProductSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = ProductsActionType.CREATE_PRODUCT_SUCCESS;

  /**
   * Fires a new product create-success action.
   * @param payload The created product.
   */
  constructor(public payload: AppHttpResponse<Product>) {}
}

/**
 * Represents a store product update action.
 */
export class UpdateProduct implements Action {
  /**
   * The type of the action.
   */
  readonly type = ProductsActionType.UPDATE_PRODUCT;

  /**
   * Fires a new product update action.
   * @param payload The product's updated data.
   */
  constructor(public payload: UpdateProductInput) {}
}

/**
 * Represents a store product update-fail action.
 */
export class UpdateProductFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = ProductsActionType.UPDATE_PRODUCT_FAIL;

  /**
   * Fires a new product update-fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store product update-success action.
 */
export class UpdateProductSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = ProductsActionType.UPDATE_PRODUCT_SUCCESS;

  /**
   * Fires a new product update-success action.
   * @param payload The updated product.
   */
  constructor(public payload: AppHttpResponse<Product>) {}
}

/**
 * Represents a store product delete action.
 */
export class DeleteProduct implements Action {
  /**
   * The type of the action.
   */
  readonly type = ProductsActionType.DELETE_PRODUCT;

  /**
   * Fires a new product delete action.
   * @param payload The product's id.
   */
  constructor(public payload: number) {}
}

/**
 * Represents a store product delete-fail action.
 */
export class DeleteProductFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = ProductsActionType.DELETE_PRODUCT_FAIL;

  /**
   * Fires a new product delete-fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store product delete-success action.
 */
export class DeleteProductSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = ProductsActionType.DELETE_PRODUCT_SUCCESS;

  /**
   * Fires a new product delete-success action.
   * @param payload The deleted product.
   */
  constructor(public payload: AppHttpResponse<Product>) {}
}

/**
 * Represents a store product stock by product id action.
 */
export class GetAllProductsStockByProductId implements Action {
  /**
   * The type of the action.
   */
  readonly type = ProductsActionType.GET_PRODUCT_STOCKS_BY_PRODUCT_ID;

  /**
   * Fires a new products stock by product id action.
   */
  constructor(public payload?: { productId: number }) {}
}

/**
 * Represents a store products stock by product id fail action.
 */
export class GetAllProductsStockByProductIdFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = ProductsActionType.GET_PRODUCT_STOCKS_BY_PRODUCT_ID_FAIL;

  /**
   * Fires a new products stock by product id fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store products stock by product id success action.
 */
export class GetAllProductsStockByProductIdSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = ProductsActionType.GET_PRODUCT_STOCKS_BY_PRODUCT_ID_SUCCESS;

  /**
   * Fires a products stock by product id success action.
   * @param payload An object contains the products stock by product id.
   */
  constructor(public payload: AppHttpResponse<ProductStock>) {}
}

/**
 * Represents a store product update photo action.
 */
export class UpdateProductPhoto implements Action {
  /**
   * The type of the action.
   */
  readonly type = ProductsActionType.UPDATE_PRODUCT_PHOTO;

  /**
   * Fires a new product update photo action.
   * @param payload The product's id and the new photo.
   */
  constructor(public payload: { id: number; photo: File }) {}
}

/**
 * Represents a store product delete photo action.
 */
export class DeleteProductPhoto implements Action {
  /**
   * The type of the action.
   */
  readonly type = ProductsActionType.DELETE_PRODUCT_PHOTO;

  /**
   * Fires a new product delete photo action.
   * @param payload The product's id.
   */
  constructor(public payload: number) {}
}

/**
 * Represents a store product discounts create action.
 */
export class CreateProductDiscount implements Action {
  /**
   * The type of the action.
   */
  readonly type = ProductsActionType.CREATE_PRODUCT_DISCOUNTS;

  /**
   * Fires a new product discount create action.
   * @param payload The product's discounts created data.
   */
  constructor(public payload: CreateProductDiscountInput) {}
}

/**
 * Represents a store product discounts create-fail action.
 */
export class CreateProductDiscountFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = ProductsActionType.CREATE_PRODUCT_DISCOUNTS_FAIL;

  /**
   * Fires a new product discount create-fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store product discount create-success action.
 */
export class CreateProductDiscountSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = ProductsActionType.CREATE_PRODUCT_DISCOUNTS_SUCCESS;

  /**
   * Fires a new product discount create-success action.
   * @param payload The created product discount.
   */
  constructor(public payload: AppHttpResponse<number>) {}
}

/**
 * Represents a store product sales discount update action.
 */
export class UpdateProductSalesDiscount implements Action {
  /**
   * The type of the action.
   */
  readonly type = ProductsActionType.UPDATE_PRODUCT_SALES_DISCOUNT;

  /**
   * Fires a new product sales discount update action.
   * @param payload The product sales discount's updated data.
   */
  constructor(public payload: UpdateProductSalesDiscountInput) {}
}

/**
 * Represents a store product sales discount update-fail action.
 */
export class UpdateProductSalesDiscountFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = ProductsActionType.UPDATE_PRODUCT_SALES_DISCOUNT_FAIL;

  /**
   * Fires a new product sales discount update-fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store product sales discount update-success action.
 */
export class UpdateProductSalesDiscountSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = ProductsActionType.UPDATE_PRODUCT_SALES_DISCOUNT_SUCCESS;

  /**
   * Fires a new product sales discount update-success action.
   * @param payload The updated product sales discount.
   */
  constructor(public payload: AppHttpResponse<ProductSalesDiscount>) {}
}

/**
 * Represents a store product sales discount delete action.
 */
export class DeleteProductSalesDiscount implements Action {
  /**
   * The type of the action.
   */
  readonly type = ProductsActionType.DELETE_PRODUCT_SALES_DISCOUNT;

  /**
   * Fires a new product sales discount delete action.
   * @param payload The product sales discount's id.
   */
  constructor(public payload: number) {}
}

/**
 * Represents a store product sales discount delete-fail action.
 */
export class DeleteProductSalesDiscountFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = ProductsActionType.DELETE_PRODUCT_SALES_DISCOUNT_FAIL;

  /**
   * Fires a new product sales discount delete-fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store product sales discount delete-success action.
 */
export class DeleteProductSalesDiscountSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = ProductsActionType.DELETE_PRODUCT_SALES_DISCOUNT_SUCCESS;

  /**
   * Fires a new product sales discount delete-success action.
   * @param payload The deleted product sales discount.
   */
  constructor(public payload: AppHttpResponse<ProductSalesDiscount>) {}
}

/**
 * Represents a store product unit of measure rate update action.
 */
export class UpdateProductUnitOfMeasureRate implements Action {
  /**
   * The type of the action.
   */
  readonly type = ProductsActionType.UPDATE_PRODUCT_UNIT_OF_MEASURE_RATE;

  /**
   * Fires a new product unit of measure rate update action.
   * @param payload The product unit of measure rate's updated data.
   */
  constructor(public payload: UpdateProductUnitOfMeasureRateInput) {}
}

/**
 * Represents a store product unit of measure rate update-fail action.
 */
export class UpdateProductUnitOfMeasureRateFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = ProductsActionType.UPDATE_PRODUCT_UNIT_OF_MEASURE_RATE_FAIL;

  /**
   * Fires a new product unit of measure rate update-fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store product unit of measure rate update-success action.
 */
export class UpdateProductUnitOfMeasureRateSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = ProductsActionType.UPDATE_PRODUCT_UNIT_OF_MEASURE_RATE_SUCCESS;

  /**
   * Fires a new product unit of measure rate update-success action.
   * @param payload The updated product unit of measure rate.
   */
  constructor(public payload: AppHttpResponse<ProductUnitOfMeasureRate>) {}
}

/**
 * Represents a store product unit of measure rate delete action.
 */
export class DeleteProductUnitOfMeasureRate implements Action {
  /**
   * The type of the action.
   */
  readonly type = ProductsActionType.DELETE_PRODUCT_UNIT_OF_MEASURE_RATE;

  /**
   * Fires a new product unit of measure rate delete action.
   * @param payload The product unit of measure rate's id.
   */
  constructor(public payload: number) {}
}

/**
 * Represents a store product unit of measure rate delete-fail action.
 */
export class DeleteProductUnitOfMeasureRateFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = ProductsActionType.DELETE_PRODUCT_UNIT_OF_MEASURE_RATE_FAIL;

  /**
   * Fires a new product unit of measure rate delete-fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store product unit of measure rate delete-success action.
 */
export class DeleteProductUnitOfMeasureRateSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = ProductsActionType.DELETE_PRODUCT_UNIT_OF_MEASURE_RATE_SUCCESS;

  /**
   * Fires a new product unit of measure rate delete-success action.
   * @param payload The deleted product unit of measure rate.
   */
  constructor(public payload: AppHttpResponse<ProductUnitOfMeasureRate>) {}
}

/**
 * Stores-Module products action types.
 */
export type ProductsActions =
  | SearchProducts
  | SearchProductsFail
  | SearchProductsSuccess
  | SearchProductSalesDiscounts
  | SearchProductSalesDiscountsFail
  | SearchProductSalesDiscountsSuccess
  | SearchProductsForSalesScreen
  | SearchProductsForSalesScreenFail
  | SearchProductsForSalesScreenSuccess
  | SearchProductsWithoutPagination
  | FindProduct
  | FindProductFail
  | FindProductSuccess
  | FindProductByCodeOrBarcode
  | FindProductByCodeOrBarcodeFail
  | FindProductByCodeOrBarcodeSuccess
  | CreateProduct
  | CreateProductFail
  | CreateProductSuccess
  | UpdateProduct
  | UpdateProductFail
  | UpdateProductSuccess
  | DeleteProduct
  | DeleteProductFail
  | DeleteProductSuccess
  | GetAllProductsStockByProductId
  | GetAllProductsStockByProductIdFail
  | GetAllProductsStockByProductIdSuccess
  | UpdateProductPhoto
  | DeleteProductPhoto
  | CreateProductDiscount
  | CreateProductDiscountFail
  | CreateProductDiscountSuccess
  | UpdateProductSalesDiscount
  | UpdateProductSalesDiscountFail
  | UpdateProductSalesDiscountSuccess
  | DeleteProductSalesDiscount
  | DeleteProductSalesDiscountFail
  | DeleteProductSalesDiscountSuccess
  | UpdateProductUnitOfMeasureRate
  | UpdateProductUnitOfMeasureRateFail
  | UpdateProductUnitOfMeasureRateSuccess
  | DeleteProductUnitOfMeasureRate
  | DeleteProductUnitOfMeasureRateFail
  | DeleteProductUnitOfMeasureRateSuccess;
