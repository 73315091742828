<!-- logs modal starts -->
<ng-template #modalRef let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title text-primary" id="modal-basic-title">
      {{ 'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.PAYROLLS_LOGS' | translate }}
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="d(); closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="col-md-12">
      <div class="table-responsive">
        <table class="table table-sm text-center">
          <thead>
            <tr>
              <th scope="col"><i class="fa fa-list-ol"></i></th>
              <th scope="col">
                {{ 'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.PAYROLL_STATUS' | translate }}
              </th>
              <th scope="col">{{ 'SHARED.SEARCH.CREATED_AT' | translate }}</th>
              <th scope="col">
                {{ 'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.USER_TITLE' | translate }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let payrollLog of payrollLogs; let i = index">
              <th scope="row">{{ i + 1 }}</th>
              <td>{{ payrollLog.payrollStatus.name | localize: payrollLog.payrollStatus.nameEn | async }}</td>
              <td>
                <span [ngbTooltip]="payrollLog.createdAt | date: 'time'">
                  {{ payrollLog.createdAt | date }}
                </span>
              </td>
              <td>{{ payrollLog.user.name }}</td>
            </tr>
          </tbody>
          <tfoot class="tfoot-light">
            <tr>
              <td [attr.colspan]="4">
                <i class="fa fa-bookmark c-primary"></i>
                <span>
                  {{ payrollLogs.length | toNumber }}
                  {{ 'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.PAYROLLS_LOG' | translate }}
                </span>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="modal-actions">
      <button type="button" class="btn btn-outline-dark round" (click)="c(); closeModal()">
        <i class="fa fa-times"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.CLOSE' | translate }}</span>
      </button>
    </div>
  </div>
</ng-template>
<!-- logs modal ends -->

<!-- no items template starts -->
<ng-template #noItems>
  <div class="col-md-12 alert alert-warning mt-3 text-center" role="alert">
    {{ 'HR.EMPLOYEES_MANAGEMENT.EMPLOYEES_MANAGEMENT_DATA.PAYROLLS_LOGS_NOT_FOUND' | translate }}
  </div>
</ng-template>
<!-- no items template ends -->
