import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';

import { Observable } from 'rxjs';
import { skip, tap } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import * as fromProjectsManagementStore from 'projects-management/store';
import { PageInfo } from 'shared';
import { Claims } from 'security/models';
import { ProjectRevenue } from 'projects-management/models';

/**
 * The view revenue component pages.
 */
enum PAGES {
  'revenue-details' = 'revenue-details',
  'invoice-details' = 'invoice-details',
  'sale-return-details' = 'sale-return-details',
}

@Component({
  selector: 'app-view-revenue',
  templateUrl: './view-revenue.component.html',
  styles: [],
})
export class ViewRevenueComponent implements OnInit {
  /**
   * Gets or sets the information about the current page.
   */
  pageInfo: PageInfo = {
    title: 'PROJECTS_MANAGEMENT.PROJECTS.PROJECT_DATA.REVENUE_DETAILS',
    icon: 'fa fa-eye',
  };

  /**
   * The system supported user claims.
   */
  Claims = Claims;

  /**
   * Gets or sets the id of the current viewed revenue.
   */
  revenueId: number;

  /**
   * The current viewed revenue.
   */
  revenue$: Observable<ProjectRevenue>;

  /**
   * Gets or sets the selected page.
   * @default 'revenue-details'
   */
  activePage: PAGES = PAGES['revenue-details'];

  /**
   * @param modalService The modal service.
   * @param locationService The location service.
   * @param route The activated route.
   * @param projectsManagementStore$ The project management store.
   */
  constructor(
    private modalService: NgbModal,
    private locationService: Location,
    private route: ActivatedRoute,
    private projectsManagementStore$: Store<fromProjectsManagementStore.ProjectsManagementState>
  ) {}

  ngOnInit(): void {
    this.init();
  }

  /**
   * Initialize component data.
   */
  init() {
    /* Load the selected revenue. */
    this.revenueId = +this.route.snapshot.params.revenueId;
    this.projectsManagementStore$.dispatch(new fromProjectsManagementStore.FindProjectRevenue(this.revenueId));

    this.revenue$ = this.projectsManagementStore$.pipe(
      select(fromProjectsManagementStore.getSelectedProjectRevenue),
      skip(1),
      tap((revenue) => {
        if (revenue) {
          /* Update page info. */
          this.pageInfo = {
            ...this.pageInfo,
          };
        }
      })
    );

    /** Select the user desired page. */
    this.activePage = PAGES[this.route.snapshot.fragment] ?? this.activePage;
    this.selectedPageChanged(this.activePage);
  }

  /**
   * Updates the browser url according to the selected page.
   * @param page The newly selected page.
   */
  selectedPageChanged(page: PAGES) {
    this.locationService.replaceState(`${this.locationService.path()}#${PAGES[page]}`);
  }

  /**
   * Opens the modal of the given templateRef.
   * @param modalRef The modal templateRef to be opened.
   */
  openModal(modalRef) {
    this.modalService.open(modalRef);
  }
}
