<!-- page header starts -->
<app-page-header [info]="pageInfo"></app-page-header>
<!-- page header ends -->

<!-- bank account list starts -->
<div class="row">
  <div class="col-md-12">
    <div class="card">
      <div class="card-header mb-3">
        <h4 class="card-title">{{ 'SHARED.SEARCH.SEARCH_TOOLS' | translate }}</h4>
      </div>
      <div class="card-content">
        <div class="px-3">
          <div class="row">
            <div class="col-md-12">
              <form class="row" [formGroup]="searchForm" (ngSubmit)="search()">
                <div class="form-group col">
                  <label for="name">{{ 'FINANCES.BANK_ACCOUNTS.BANK_ACCOUNT_DATA.ACCOUNT_NAME' | translate }}</label>
                  <input
                    type="text"
                    id="name"
                    class="form-control round"
                    [placeholder]="'FINANCES.BANK_ACCOUNTS.BANK_ACCOUNT_DATA.ACCOUNT_IBAN_PLACEHOLDER' | translate"
                    formControlName="name"
                    (input)="search($event)"
                  />
                </div>
                <div class="form-group col">
                  <label for="banks">{{ 'FINANCES.BANK_ACCOUNTS.BANK_ACCOUNT_DATA.BANKS' | translate }}</label>
                  <ng-select
                    class="round"
                    formControlName="banks"
                    [multiple]="true"
                    [closeOnSelect]="false"
                    labelForId="banks"
                    searchable="true"
                    [placeholder]="'FINANCES.BANK_ACCOUNTS.BANK_ACCOUNT_DATA.BANKS_PLACEHOLDER' | translate"
                    [notFoundText]="'SHARED.SEARCH.SEARCH_NOT_FOUND' | translate"
                    (open)="banksListVisibility = true"
                  >
                    <ng-option *ngFor="let bank of banks" [value]="bank.id">
                      {{ bank.name | localize: bank.nameEn | async }}
                    </ng-option>
                  </ng-select>
                </div>
                <div class="col-md-auto form-group d-flex">
                  <button
                    type="submit"
                    class="btn btn-raised btn-primary align-self-end round mx-1 mb-0"
                    [disabled]="isSearching$ | async"
                  >
                    <i class="fa fa-search"></i>
                  </button>
                  <a
                    routerLink="/finances/bank-accounts/create"
                    class="btn btn-raised btn-success align-self-end round mx-1 mb-0"
                    *ngIf="[Claims.CREATE_BANK_ACCOUNT] | authorize | async"
                  >
                    <i class="fa fa-plus"></i>
                    <span class="mx-1">{{ 'SHARED.CONFIRM.ADD' | translate }}</span>
                  </a>
                  <app-export-tools
                    *ngIf="(bankAccounts$ | async)?.length"
                    class="d-flex align-self-end mx-1"
                    targetElementId="bank-accounts-table-export-target-element"
                    [outputFileName]="'SHARED.EXPORT_TABLES.BANK_ACCOUNTS_TABLE' | translate"
                  ></app-export-tools>
                </div>
              </form>
            </div>
            <div class="col-md-12" *ngIf="(bankAccounts$ | async)?.length; else noItems">
              <div class="row">
                <div class="col-md-12 alert alert-light text-center" role="alert">
                  {{ 'SHARED.SEARCH.SEARCH_RESULTS' | translate }} ({{ (paginationInfo$ | async).total | toNumber }})
                  {{ 'FINANCES.BANK_ACCOUNTS.BANK_ACCOUNT_DATA.BANK_ACCOUNT' | translate }}
                </div>
                <div class="col-md-12">
                  <div class="table-responsive">
                    <table class="table text-center" id="bank-accounts-table-export-target-element">
                      <thead>
                        <tr>
                          <th scope="col"><i class="fa fa-list-ol"></i></th>
                          <th scope="col">{{ 'FINANCES.BANK_ACCOUNTS.BANK_ACCOUNT_DATA.ACCOUNT_NAME' | translate }}</th>
                          <th scope="col">
                            {{ 'FINANCES.BANK_ACCOUNTS.BANK_ACCOUNT_DATA.ACCOUNT_NUMBER' | translate }}
                          </th>
                          <th scope="col">{{ 'FINANCES.BANK_ACCOUNTS.BANK_ACCOUNT_DATA.BANK_NAME' | translate }}</th>
                          <th scope="col">
                            {{ 'FINANCES.BANK_ACCOUNTS.BANK_ACCOUNT_DATA.ACCOUNT_NUMBER_IBAN' | translate }}
                          </th>
                          <th scope="col">
                            {{ 'FINANCES.BANK_ACCOUNTS.BANK_ACCOUNT_DATA.BANK_ACCOUNT_CURRENCY' | translate }}
                          </th>
                          <th scope="col">
                            {{ 'FINANCES.BANK_ACCOUNTS.BANK_ACCOUNT_DATA.ACCOUNT_BALANCE' | translate }}
                          </th>
                          <th scope="col">{{ 'FINANCES.BANK_ACCOUNTS.BANK_ACCOUNT_DATA.STATUS' | translate }}</th>
                          <th scope="col">{{ 'SHARED.TOOLS' | translate }}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let bankAccount of bankAccounts$ | async; trackBy: trackItems">
                          <th scope="row">{{ bankAccount.id }}</th>
                          <td>{{ bankAccount.name | localize: bankAccount.nameEn | async }}</td>
                          <td>{{ bankAccount.accountNumber }}</td>
                          <td>{{ bankAccount.bank?.name | localize: bankAccount.bank?.nameEn | async }}</td>
                          <td>{{ bankAccount.iban }}</td>
                          <td>{{ bankAccount.currency?.name | localize: bankAccount.currency?.nameEn | async }}</td>
                          <td>{{ bankAccount.balance | toDecimal }}</td>
                          <td>
                            <app-status
                              [status]="bankAccount.isActive"
                              [activeText]="'FINANCES.BANK_ACCOUNTS.BANK_ACCOUNT_DATA.F_ACTIVE' | translate"
                              [notActiveText]="'FINANCES.BANK_ACCOUNTS.BANK_ACCOUNT_DATA.F_BLOCK' | translate"
                            >
                            </app-status>
                          </td>
                          <td>
                            <a
                              *ngIf="[Claims.CREATE_BANK_ACCOUNT] | authorize | async"
                              class="info p-0"
                              [routerLink]="['/finances/bank-accounts/files', bankAccount.id]"
                              [ngbTooltip]="'FINANCES.BANK_ACCOUNTS.BANK_ACCOUNT_DATA.BANK_ACCOUNT_FILE' | translate"
                            >
                              <i class="fa fa-folder font-medium-3 mr-2"></i>
                            </a>
                            <a
                              *ngIf="([Claims.BLOCK_BANK_ACCOUNT] | authorize | async) && bankAccount.isActive"
                              class="warning p-0"
                              (click)="selectedBankAccount = bankAccount; openModal(blockModalRef)"
                              [ngbTooltip]="'FINANCES.BANK_ACCOUNTS.BANK_ACCOUNT_DATA.STOPPING' | translate"
                            >
                              <i class="fa fa-power-off font-medium-3 mr-2"></i>
                            </a>
                            <a
                              *ngIf="([Claims.ACTIVATE_BANK_ACCOUNT] | authorize | async) && !bankAccount.isActive"
                              class="success p-0"
                              (click)="selectedBankAccount = bankAccount; openModal(activateModalRef)"
                              [ngbTooltip]="'FINANCES.BANK_ACCOUNTS.BANK_ACCOUNT_DATA.ACTIVATE' | translate"
                            >
                              <i class="fa fa-check font-medium-3 mr-2"></i>
                            </a>
                            <a
                              *ngIf="[Claims.DELETE_BANK_ACCOUNT] | authorize | async"
                              class="danger p-0"
                              [ngbTooltip]="'SHARED.CONFIRM.DELETE' | translate"
                              (click)="selectedBankAccount = bankAccount; openModal(deleteModalRef)"
                            >
                              <i class="fa fa-close font-medium-3 mr-2"></i>
                            </a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div class="d-flex justify-content-between p-2">
                      <ngb-pagination
                        *ngIf="paginationInfo$ | async; let pagination"
                        maxSize="5"
                        [collectionSize]="pagination.total"
                        [pageSize]="pagination.pageSize"
                        [page]="pagination.page"
                        (pageChange)="pageChanged($event)"
                        boundaryLinks="true"
                      >
                        <ng-template ngbPaginationFirst>{{
                          'SHARED.PAGINATION.PAGINATION_FIRST' | translate
                        }}</ng-template>
                        <ng-template ngbPaginationFirst>{{
                          'SHARED.PAGINATION.PAGINATION_PREVIOUS' | translate
                        }}</ng-template>
                        <ng-template ngbPaginationFirst>{{
                          'SHARED.PAGINATION.PAGINATION_NEXT' | translate
                        }}</ng-template>
                        <ng-template ngbPaginationFirst>{{
                          'SHARED.PAGINATION.PAGINATION_LAST' | translate
                        }}</ng-template>
                      </ngb-pagination>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- bank account list ends -->

<!-- no items template starts -->
<ng-template #noItems>
  <div class="col-md-12 alert alert-warning mt-3 text-center" role="alert">
    {{ 'FINANCES.BANK_ACCOUNTS.SEARCH_BANK_ACCOUNT_NOT_FOUND' | translate }}
    <a
      routerLink="/finances/bank-accounts/create"
      class="btn btn-raised btn-success round"
      *ngIf="[Claims.CREATE_BANK_ACCOUNT] | authorize | async"
    >
      <i class="fa fa-plus"></i>
      <span class="mx-1">{{ 'SHARED.CONFIRM.ADD' | translate }}</span>
    </a>
  </div>
</ng-template>
<!-- no items template ends -->

<!-- block bank account modal starts -->
<ng-template #blockModalRef let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title text-warning" id="modal-basic-title">
      {{ 'FINANCES.BANK_ACCOUNTS.CONFIRM_STOP_BANK_ACCOUNT_TITLE' | translate }}
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="d()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-12">
        <p class="text-warning">{{ 'FINANCES.BANK_ACCOUNTS.CONFIRM_STOP_BANK_ACCOUNT_MESSAGE' | translate }}</p>
      </div>
      <div class="form-group col-12">
        <label for="selectedBankAccount.name">{{
          'FINANCES.BANK_ACCOUNTS.BANK_ACCOUNT_DATA.BANK_ACCOUNT_NAME' | translate
        }}</label>
        <input
          type="text"
          id="selectedBankAccount.name"
          class="form-control round"
          [placeholder]="'FINANCES.BANK_ACCOUNTS.BANK_ACCOUNT_DATA.BANK_ACCOUNT_NAME_PLACEHOLDER' | translate"
          readonly
          [value]="selectedBankAccount.name | localize: selectedBankAccount.nameEn | async"
        />
      </div>
      <div class="form-group col-12">
        <label for="selectedBankAccount.accountNumber">{{
          'FINANCES.BANK_ACCOUNTS.BANK_ACCOUNT_DATA.BANK_ACCOUNT_NUMBER' | translate
        }}</label>
        <input
          type="text"
          id="selectedBankAccount.accountNumber"
          class="form-control round"
          [placeholder]="'FINANCES.BANK_ACCOUNTS.BANK_ACCOUNT_DATA.BANK_ACCOUNT_NUMBER_PLACEHOLDER' | translate"
          readonly
          [value]="selectedBankAccount.accountNumber"
        />
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="modal-actions">
      <button
        type="button"
        class="btn btn-raised btn-warning round"
        (click)="c(); block(selectedBankAccount.id)"
        ngbAutofocus
        [disabled]="isBlocking$ | async"
      >
        <i class="fa fa-check"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.BLOCK' | translate }}</span>
      </button>
      <button type="button" class="btn btn-outline-dark round" (click)="c()">
        <i class="fa fa-times"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.CANCEL' | translate }}</span>
      </button>
    </div>
  </div>
</ng-template>
<!-- block bank account modal ends -->

<!-- activate bank account modal starts -->
<ng-template #activateModalRef let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
      {{ 'FINANCES.BANK_ACCOUNTS.CONFIRM_ACTIVE_BANK_ACCOUNT_TITLE' | translate }}
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="d()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-12">
        <p class="text-success">{{ 'FINANCES.BANK_ACCOUNTS.CONFIRM_ACTIVE_BANK_ACCOUNT_MESSAGE' | translate }}</p>
      </div>
      <div class="form-group col-12">
        <label for="selectedBankAccount.name">{{
          'FINANCES.BANK_ACCOUNTS.BANK_ACCOUNT_DATA.BANK_ACCOUNT_NAME' | translate
        }}</label>
        <input
          type="text"
          id="selectedBankAccount.name"
          class="form-control round"
          [placeholder]="'FINANCES.BANK_ACCOUNTS.BANK_ACCOUNT_DATA.BANK_ACCOUNT_NAME_PLACEHOLDER' | translate"
          readonly
          [value]="selectedBankAccount.name | localize: selectedBankAccount.nameEn | async"
        />
      </div>
      <div class="form-group col-12">
        <label for="selectedBankAccount.accountNumber">{{
          'FINANCES.BANK_ACCOUNTS.BANK_ACCOUNT_DATA.BANK_ACCOUNT_NUMBER' | translate
        }}</label>
        <input
          type="text"
          id="selectedBankAccount.accountNumber"
          class="form-control round"
          [placeholder]="'FINANCES.BANK_ACCOUNTS.BANK_ACCOUNT_DATA.BANK_ACCOUNT_NUMBER_PLACEHOLDER' | translate"
          readonly
          [value]="selectedBankAccount.accountNumber"
        />
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="modal-actions">
      <button
        type="button"
        class="btn btn-raised btn-success round"
        (click)="c(); activate(selectedBankAccount.id)"
        ngbAutofocus
        [disabled]="isActivating$ | async"
      >
        <i class="fa fa-check"></i>
        <span class="mx-1">{{ 'FINANCES.BANK_ACCOUNTS.BANK_ACCOUNT_DATA.ACTIVATE' | translate }}</span>
      </button>
      <button type="button" class="btn btn-outline-dark round" (click)="c()">
        <i class="fa fa-times"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.CANCEL' | translate }}</span>
      </button>
    </div>
  </div>
</ng-template>
<!-- activate bank account modal ends -->

<!-- delete bank account modal starts -->
<ng-template #deleteModalRef let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title text-danger" id="modal-basic-title">
      {{ 'FINANCES.BANK_ACCOUNTS.CONFIRM_DELETE_BANK_ACCOUNT_TITLE' | translate }}
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="d()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-12">
        <p class="text-danger">{{ 'FINANCES.BANK_ACCOUNTS.CONFIRM_DELETE_BANK_ACCOUNT_MESSAGE' | translate }}</p>
      </div>
      <div class="form-group col-12">
        <label for="selectedBankAccount.name">{{
          'FINANCES.BANK_ACCOUNTS.BANK_ACCOUNT_DATA.BANK_ACCOUNT_NAME' | translate
        }}</label>
        <input
          type="text"
          id="selectedBankAccount.name"
          class="form-control round"
          [placeholder]="'FINANCES.BANK_ACCOUNTS.BANK_ACCOUNT_DATA.BANK_ACCOUNT_NAME_PLACEHOLDER' | translate"
          readonly
          [value]="selectedBankAccount.name | localize: selectedBankAccount.nameEn | async"
        />
      </div>
      <div class="form-group col-12">
        <label for="selectedBankAccount.accountNumber">{{
          'FINANCES.BANK_ACCOUNTS.BANK_ACCOUNT_DATA.BANK_ACCOUNT_NUMBER' | translate
        }}</label>
        <input
          type="text"
          id="selectedBankAccount.accountNumber"
          class="form-control round"
          [placeholder]="'FINANCES.BANK_ACCOUNTS.BANK_ACCOUNT_DATA.BANK_ACCOUNT_NUMBER_PLACEHOLDER' | translate"
          readonly
          [value]="selectedBankAccount.accountNumber"
        />
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="modal-actions">
      <button
        type="button"
        class="btn btn-raised btn-danger round"
        (click)="c(); delete(selectedBankAccount.id)"
        ngbAutofocus
        [disabled]="isDeleting$ | async"
      >
        <i class="fa fa-check"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.DELETE' | translate }}</span>
      </button>
      <button type="button" class="btn btn-outline-dark round" (click)="c()">
        <i class="fa fa-times"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.CANCEL' | translate }}</span>
      </button>
    </div>
  </div>
</ng-template>
<!-- delete bank account modal ends -->

<!-- banks search starts -->
<app-banks-search [(visible)]="banksListVisibility" (banks)="selectBanks($event)"> </app-banks-search>
<!-- banks search ends -->
