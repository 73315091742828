<!-- payroll element formulas modal starts -->
<ng-template #modalRef let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title text-primary" id="modal-basic-title">
      {{ 'HR.PAYROLL_ELEMENTS.PAYROLL_ELEMENT_FORMULAS' | translate }}
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="d(); closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="col-12">
      <div class="px-3">
        <div class="row">
          <div class="form-group col-md-6">
            <label for="name"> {{ 'HR.PAYROLL_ELEMENTS.PAYROLL_ELEMENT_DATA.ARABIC_ELEMENT_NAME' | translate }}</label>
            <input
              type="text"
              id="name"
              disabled
              class="form-control round"
              [placeholder]="'HR.PAYROLL_ELEMENTS.PAYROLL_ELEMENT_DATA.ARABIC_ELEMENT_NAME_PLACEHOLDER' | translate"
              maxlength="50"
              [value]="payrollElement.name"
            />
          </div>
          <div class="form-group col-md-6">
            <label for="nameEn">{{
              'HR.PAYROLL_ELEMENTS.PAYROLL_ELEMENT_DATA.ENGLISH_ELEMENT_NAME' | translate
            }}</label>
            <input
              type="text"
              id="nameEn"
              disabled
              class="form-control round"
              [placeholder]="'HR.PAYROLL_ELEMENTS.PAYROLL_ELEMENT_DATA.ENGLISH_ELEMENT_NAME_PLACEHOLDER' | translate"
              maxlength="50"
              [value]="payrollElement.nameEn"
            />
          </div>
          <div class="form-group col-md-6">
            <label for="payrollElementClassification">{{
              'HR.PAYROLL_ELEMENTS.PAYROLL_ELEMENT_DATA.ELEMENT_CLASSIFICATION' | translate
            }}</label>
            <input
              type="text"
              id="nameEn"
              disabled
              class="form-control round"
              [placeholder]="'HR.PAYROLL_ELEMENTS.PAYROLL_ELEMENT_DATA.ELEMENT_CLASSIFICATIONS_PLACEHOLDER' | translate"
              maxlength="50"
              [value]="
                payrollElement.payrollElementClassification.name
                  | localize: payrollElement.payrollElementClassification.nameEn
                  | async
              "
            />
          </div>
          <div class="form-group col-md-6">
            <label for="payrollElementType">{{
              'HR.PAYROLL_ELEMENTS.PAYROLL_ELEMENT_DATA.ELEMENT_TYPE' | translate
            }}</label>
            <input
              type="text"
              id="nameEn"
              disabled
              class="form-control round"
              [placeholder]="'HR.PAYROLL_ELEMENTS.PAYROLL_ELEMENT_DATA.ELEMENT_TYPES_PLACEHOLDER' | translate"
              maxlength="50"
              [value]="
                payrollElement.payrollElementType.name | localize: payrollElement.payrollElementType.nameEn | async
              "
            />
          </div>
          <div class="form-group col-md-12">
            <label for="payrollElementNature">{{
              'HR.PAYROLL_ELEMENTS.PAYROLL_ELEMENT_DATA.PAYROLL_ELEMENT_NATURE' | translate
            }}</label>
            <input
              type="text"
              id="nameEn"
              disabled
              class="form-control round"
              [placeholder]="'HR.PAYROLL_ELEMENTS.PAYROLL_ELEMENT_DATA.PAYROLL_ELEMENT_NATURE_PLACEHOLDER' | translate"
              maxlength="50"
              [value]="
                payrollElement.payrollElementNature.name | localize: payrollElement.payrollElementNature.nameEn | async
              "
            />
          </div>
          <div class="form-group col-md-6">
            <label for="description">
              {{ 'HR.PAYROLL_ELEMENTS.PAYROLL_ELEMENT_DATA.ARABIC_DESCRIPTION_ELEMENT' | translate }}</label
            >
            <textarea
              id="description"
              class="form-control round"
              [placeholder]="
                'HR.PAYROLL_ELEMENTS.PAYROLL_ELEMENT_DATA.ARABIC_DESCRIPTION_ELEMENT_PLACEHOLDER' | translate
              "
              minlength="0"
              maxlength="200"
              disabled
              [value]="payrollElement.description"
              rows="3"
            ></textarea>
          </div>
          <div class="form-group col-md-6">
            <label for="descriptionEn">{{
              'HR.PAYROLL_ELEMENTS.PAYROLL_ELEMENT_DATA.ENGLISH_DESCRIPTION_ELEMENT' | translate
            }}</label>
            <textarea
              id="description"
              class="form-control round"
              [placeholder]="
                'HR.PAYROLL_ELEMENTS.PAYROLL_ELEMENT_DATA.ENGLISH_DESCRIPTION_ELEMENT_PLACEHOLDER' | translate
              "
              minlength="0"
              maxlength="200"
              disabled
              [value]="payrollElement.descriptionEn"
              rows="3"
            ></textarea>
          </div>
        </div>
      </div>
      <div
        *ngIf="payrollElementTypeKey === 'PERCENTAGE_FROM_ANOTHER_ELEMENTS'"
        class="col-md-12 alert alert-light text-center"
        role="alert"
      >
        {{ 'SHARED.SEARCH.SEARCH_RESULTS' | translate }} ({{
          payrollElement.payrollElementFormulas?.length | toNumber
        }})
        {{ 'HR.PAYROLL_ELEMENTS.PAYROLL_ELEMENT_DATA.FORMULA_ELEMENT' | translate }}
      </div>
      <div class="col-md-12" *ngIf="payrollElementTypeKey === 'PERCENTAGE_FROM_ANOTHER_ELEMENTS'">
        <div class="table-responsive">
          <table class="table text-center">
            <thead>
              <tr>
                <th scope="col"><i class="fa fa-list-ol"></i></th>
                <th scope="col">
                  {{ 'HR.PAYROLL_ELEMENTS.PAYROLL_ELEMENT_DATA.ELEMENT_NAME' | translate }}
                </th>
                <th scope="col">
                  {{ 'HR.PAYROLL_ELEMENTS.PAYROLL_ELEMENT_DATA.PERCENT' | translate }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                *ngFor="let elementFormula of payrollElement.payrollElementFormulas; trackBy: trackItems; let i = index"
              >
                <th scope="row">{{ i + 1 }}</th>
                <td>
                  {{
                    elementFormula.formulaPayrollElement.name
                      | localize: elementFormula.formulaPayrollElement.nameEn
                      | async
                  }}
                </td>
                <td>{{ elementFormula.percentage | toDecimal }} %</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="modal-actions">
      <button type="button" class="btn btn-outline-dark round" (click)="c(); closeModal()">
        <i class="fa fa-times"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.CLOSE' | translate }}</span>
      </button>
    </div>
  </div>
</ng-template>
<!-- payroll element formulas modal ends -->

<!-- no items template starts -->
<ng-template #noItems>
  <div class="col-md-12 alert alert-warning mt-3 text-center" role="alert">
    {{ 'HR.PAYROLL_ELEMENTS.PAYROLL_ELEMENT_FORMULAS_NOT_FOUND' | translate }}
  </div>
</ng-template>
<!-- no items template ends -->
