import { CreateCurrencyComponent } from 'finances/components/currencies/create-currency/create-currency.component'; 
import { UpdateCurrencyComponent } from 'finances/components/currencies/update-currency/update-currency.component';
import { CurrencyListComponent } from 'finances/components/currencies/currency-list/currency-list.component';
import { CurrenciesSearchComponent } from 'finances/components/currencies/currencies-search/currencies-search.component';

export * from 'finances/components/currencies/create-currency/create-currency.component'; 
export * from 'finances/components/currencies/update-currency/update-currency.component';
export * from 'finances/components/currencies/currency-list/currency-list.component';
export * from 'finances/components/currencies/currencies-search/currencies-search.component';

/**
 * The set of components registered on this sub-module.
 */
export const COMPONENTS: any[] = [
  CreateCurrencyComponent, 
  UpdateCurrencyComponent,
  CurrencyListComponent,
  CurrenciesSearchComponent,
];
