import { createSelector } from '@ngrx/store';

import { AppSearchState, VendorsState } from 'app-search/store/states';
import { selectAppSearchState } from 'app-search/store/selectors/feature.selectors';

/**
 * Gets the vendors state.
 */
const selectVendorsState = createSelector(selectAppSearchState, (state: AppSearchState) => state.vendors);

/**
 * Gets the list of loaded vendors.
 */
export const getVendors = createSelector(selectVendorsState, (state: VendorsState) => state.data);

/**
 * Gets the vendors pagination info.
 */
export const getVendorsPaginationInfo = createSelector(
  selectVendorsState,
  (state: VendorsState) => state.paginationInfo
);

/**
 * Gets the error that occurred in the state.
 */
export const getVendorsError = createSelector(selectVendorsState, (state: VendorsState) => state.error);

/**
 * Determines if there is a running search process.
 */
export const getVendorsSearching = createSelector(selectVendorsState, (state: VendorsState) => state.isSearching);

/**
 * Determines if the last search process has been ended successfully.
 */
export const getVendorsSearchCompleted = createSelector(
  selectVendorsState,
  (state: VendorsState) => state.isSearchCompleted
);
