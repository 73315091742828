<!-- page header starts -->
<app-page-header [info]="pageInfo"></app-page-header>
<!-- page header ends -->

<!-- create pos printer form starts -->
<div class="row">
  <div class="col-md-12">
    <form [formGroup]="form" (ngSubmit)="submit()">
      <div class="card">
        <div class="card-header mb-3">
          <h4 class="card-title">
            {{ 'SALES.POS_PRINTERS.POS_PRINTER_DATA_TITLE' | translate }}
          </h4>
        </div>
        <div class="card-content">
          <div class="px-3">
            <div class="row">
              <div class="form-group col-md-6">
                <label for="name">{{ 'SALES.POS_PRINTERS.POS_PRINTER_NAME' | translate }}</label>
                <input
                  type="text"
                  id="name"
                  class="form-control round"
                  [placeholder]="'SALES.POS_PRINTERS.POS_PRINTER_PLACEHOLDER' | translate"
                  maxlength="50"
                  formControlName="name"
                />
              </div>
              <div class="form-group col-md-6">
                <label for="ip">{{ 'SALES.POS_PRINTERS.POS_PRINTER_IP' | translate }}</label>
                <input
                  type="text"
                  id="ip"
                  class="form-control round"
                  [placeholder]="'SALES.POS_PRINTERS.POS_PRINTER_IP_PLACEHOLDER' | translate"
                  maxlength="50"
                  formControlName="ip"
                />
              </div>
              <div class="form-group col-md-12">
                <label for="posDeviceId">{{ 'SALES.POS_PRINTERS.THE_POS_DEVICE' | translate }}</label>
                <ng-select
                  class="round"
                  formControlName="posDeviceId"
                  [closeOnSelect]="false"
                  labelForId="posDeviceId"
                  searchable="true"
                  [placeholder]="'SALES.POS_PRINTERS.THE_POS_DEVICE_PLACEHOLDER' | translate"
                  [notFoundText]="'SHARED.SEARCH.SEARCH_NOT_FOUND' | translate"
                  (open)="posDevicesListVisibility = true"
                >
                  <ng-option *ngFor="let posDevice of posDevices" [value]="posDevice.id">
                    {{ posDevice.name | localize: posDevice.nameEn | async }}
                  </ng-option>
                </ng-select>
              </div>
            </div>

            <div class="row">
              <div class="form-group col-12 d-flex justify-content-between flex-column flex-sm-row mt-2">
                <button type="submit" class="btn btn-raised btn-success round" [disabled]="isCreating$ | async">
                  <i class="fa fa-check"></i>
                  <span class="mx-1">{{ 'SHARED.CONFIRM.SAVE' | translate }}</span>
                </button>
                <a class="btn btn-dark round" routerLink="/sales/pos-home" fragment="pos-printers">
                  <i class="fa fa-undo"></i>
                  <span class="mx-1">{{ 'SALES.POS_PRINTERS.POS_PRINTER_RETURN' | translate }}</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
<!-- create pos printer class form ends -->

<!-- confirm modal starts -->
<ng-template #confirmModalRef let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
      {{ 'SALES.POS_PRINTERS.CONFIRM_CREATE_POS_PRINTER_TITLE' | translate }}
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="d()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p class="text-success">
      {{ 'SALES.POS_PRINTERS.CONFIRM_CREATE_POS_PRINTER_MESSAGE' | translate }}
    </p>
  </div>
  <div class="modal-footer">
    <div class="modal-actions">
      <button type="button" class="btn btn-raised btn-success round" (click)="c(); confirm()" ngbAutofocus>
        <i class="fa fa-check"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.SAVE' | translate }}</span>
      </button>
      <button type="button" class="btn btn-outline-dark round" (click)="c()">
        <i class="fa fa-times"></i>
        <span class="mx-1">{{ 'SHARED.CONFIRM.CANCEL' | translate }}</span>
      </button>
    </div>
  </div>
</ng-template>
<!-- confirm modal ends -->

<!-- pos device classes search starts -->
<app-pos-devices-search
  [closeOnSelect]="true"
  (posDevice)="selectPosDevice($event)"
  [(visible)]="posDevicesListVisibility"
>
</app-pos-devices-search>
<!-- pos device classes search ends -->
