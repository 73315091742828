import { createSelector } from '@ngrx/store';

import { SectorsState, StoresState } from 'stores/store/states';
import { selectStoresState } from 'stores/store/selectors/feature.selectors';

/**
 * Gets the sectors state.
 */
const selectSectorsState = createSelector(selectStoresState, (state: StoresState) => state.sectors);

/**
 * Gets the list of loaded sectors.
 */
export const getSectors = createSelector(selectSectorsState, (state: SectorsState) => state.data);

/**
 * Gets the sectors pagination info.
 */
export const getSectorsPaginationInfo = createSelector(
  selectSectorsState,
  (state: SectorsState) => state.paginationInfo
);

/**
 * Gets the error that occurred in the state.
 */
export const getSectorsError = createSelector(selectSectorsState, (state: SectorsState) => state.error);

/**
 * Determines if there is a running search process.
 */
export const getSectorsSearching = createSelector(selectSectorsState, (state: SectorsState) => state.isSearching);

/**
 * Determines if the last search process has been ended successfully.
 */
export const getSectorsSearchCompleted = createSelector(
  selectSectorsState,
  (state: SectorsState) => state.isSearchCompleted
);

/**
 * Gets the selected sector.
 */
export const getSelectedSector = createSelector(selectSectorsState, (state: SectorsState) => state.selectedSector);

/**
 * Determines if there is a running find sector process.
 */
export const getSectorFinding = createSelector(selectSectorsState, (state: SectorsState) => state.isFinding);

/**
 * Determines if the last find sector process has been ended successfully.
 */
export const getSectorFindCompleted = createSelector(
  selectSectorsState,
  (state: SectorsState) => state.isFindCompleted
);

/**
 * Determines if there is a running create process.
 */
export const getSelectedSectorCreating = createSelector(selectSectorsState, (state: SectorsState) => state.isCreating);

/**
 * Determines if the last create process has been ended successfully.
 */
export const getSelectedSectorCreateCompleted = createSelector(
  selectSectorsState,
  (state: SectorsState) => state.isCreateCompleted
);

/**
 * Determines if there is a running update process.
 */
export const getSelectedSectorUpdating = createSelector(selectSectorsState, (state: SectorsState) => state.isUpdating);

/**
 * Determines if the last update process has been ended successfully.
 */
export const getSelectedSectorUpdateCompleted = createSelector(
  selectSectorsState,
  (state: SectorsState) => state.isUpdateCompleted
);

/**
 * Determines if there is a running delete process.
 */
export const getSelectedSectorDeleting = createSelector(selectSectorsState, (state: SectorsState) => state.isDeleting);

/**
 * Determines if the last delete process has been ended successfully.
 */
export const getSelectedSectorDeleteCompleted = createSelector(
  selectSectorsState,
  (state: SectorsState) => state.isDeleteCompleted
);
