<!-- customer not paid invoices starts -->

<!-- invoices list starts -->
<div class="row">
  <div class="col-md-12">
    <div class="card-header mb-3 pt-0">
      <h4 class="card-title">{{ 'SHARED.SEARCH.SEARCH_TOOLS' | translate }}</h4>
    </div>
    <div class="card-content">
      <div class="px-3">
        <div class="row">
          <div class="col-md-12">
            <form class="row" [formGroup]="searchForm" (ngSubmit)="search()">
              <div class="form-group col-md-4">
                <label for="locations">{{ 'SALES.CUSTOMERS.CUSTOMER_DATA.LOCATIONS' | translate }}</label>
                <ng-select
                  class="round"
                  formControlName="locations"
                  [multiple]="true"
                  [closeOnSelect]="false"
                  labelForId="locations"
                  searchable="true"
                  [placeholder]="'SALES.CUSTOMERS.CUSTOMER_DATA.LOCATIONS_PLACEHOLDER' | translate"
                  [notFoundText]="'SHARED.SEARCH.SEARCH_NOT_FOUND' | translate"
                  (open)="locationsListVisibility = true"
                >
                  <ng-option *ngFor="let location of locations" [value]="location.id">
                    {{ location.name | localize: location.nameEn | async }}
                  </ng-option>
                </ng-select>
              </div>
              <div class="form-group col-md-4">
                <label for="invoiceNum">{{ 'SALES.CUSTOMERS.CUSTOMER_DATA.INVOICE_NUMBER' | translate }}</label>
                <input
                  type="number"
                  id="invoiceNum"
                  name="invoiceNum"
                  class="form-control round"
                  [placeholder]="'SALES.CUSTOMERS.CUSTOMER_DATA.INVOICE_NUMBER_PLACEHOLDER' | translate"
                  formControlName="invoiceNum"
                />
              </div>
              <div class="col-md-auto form-group d-flex">
                <button
                  type="submit"
                  class="btn btn-raised btn-primary align-self-end round mx-1 mb-0"
                  [disabled]="isSearching$ | async"
                >
                  <i class="fa fa-search"></i>
                </button>
                <app-export-tools
                  *ngIf="(invoices$ | async)?.length"
                  class="d-flex align-self-end mx-1"
                  targetElementId="customer-unpaid-table-export-target-element"
                  [outputFileName]="'SHARED.EXPORT_TABLES.CUSTOMER_UNPAID_TABLE' | translate"
                ></app-export-tools>
              </div>
            </form>
          </div>
          <ng-container *ngIf="invoices$ | async; let invoices">
            <div class="col-md-12" *ngIf="invoices.length; else noItems">
              <div class="row">
                <div class="col-md-12 alert alert-light text-center" role="alert">
                  {{ 'SHARED.SEARCH.SEARCH_RESULTS' | translate }} ({{ invoices.length }})
                  {{ 'SALES.CUSTOMERS.CUSTOMER_DATA.UNPAID_INVOICE' | translate }}
                </div>
                <div class="col-md-12">
                  <div class="table-responsive">
                    <table class="table text-center" id="customer-unpaid-table-export-target-element">
                      <thead>
                        <tr>
                          <th scope="col">
                            <i class="fa fa-barcode"></i
                            ><span class="mx-1">{{ 'SALES.CUSTOMERS.CUSTOMER_DATA.INVOICE_NUMBER' | translate }}</span>
                          </th>
                          <th scope="col">
                            {{ 'SALES.CUSTOMERS.CUSTOMER_DATA.LOCATION' | translate }}
                          </th>
                          <th scope="col">{{ 'SALES.CUSTOMERS.CUSTOMER_DATA.INVOICE_TOTAL' | translate }}</th>
                          <th scope="col">{{ 'SALES.CUSTOMERS.CUSTOMER_DATA.PAID' | translate }}</th>
                          <th scope="col">{{ 'SALES.CUSTOMERS.CUSTOMER_DATA.REMAINING' | translate }}</th>
                          <th scope="col">{{ 'SALES.CUSTOMERS.CUSTOMER_DATA.INVOICE_DATE' | translate }}</th>
                          <th scope="col">{{ 'SHARED.SEARCH.CREATED_AT' | translate }}</th>
                          <th scope="col">{{ 'SALES.CUSTOMERS.CUSTOMER_DATA.USER' | translate }}</th>
                          <th scope="col">{{ 'SHARED.TOOLS' | translate }}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let invoice of invoices; trackBy: trackItems">
                          <th scope="row">{{ invoice.invoiceNum }}</th>
                          <td>
                            {{ invoice.location.name | localize: invoice.location.nameEn | async }}
                          </td>
                          <td>
                            <span class="badge bg-success round">
                              {{ invoice.net | toDecimal }}
                            </span>
                          </td>
                          <td>
                            <span class="badge bg-warning round">
                              {{ invoice.paid | toDecimal }}
                            </span>
                          </td>
                          <td>
                            <span class="badge bg-danger round">
                              {{ invoice.remaining | toDecimal }}
                            </span>
                          </td>
                          <td>
                            <span [ngbTooltip]="invoice.transactionDate | date: 'time'">
                              {{ invoice.transactionDate | date }}
                            </span>
                          </td>
                          <td>
                            <span [ngbTooltip]="invoice.createdAt | date: 'time'">
                              {{ invoice.createdAt | date }}
                            </span>
                          </td>
                          <td>{{ invoice.user.name }}</td>
                          <td>
                            <a
                              class="info p-0"
                              [routerLink]="['/sales/invoices/view', invoice.id]"
                              [ngbTooltip]="'SALES.CUSTOMERS.CUSTOMER_DATA.DISPLAY_INVOICE' | translate"
                            >
                              <i class="fa fa-eye font-medium-3 mr-2"></i>
                            </a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div class="d-flex justify-content-between p-2">
                      <ngb-pagination
                        *ngIf="paginationInfo$ | async; let pagination"
                        maxSize="5"
                        [collectionSize]="pagination.total"
                        [pageSize]="pagination.pageSize"
                        [page]="pagination.page"
                        (pageChange)="pageChanged($event)"
                        boundaryLinks="true"
                      >
                        <ng-template ngbPaginationFirst>{{
                          'SHARED.PAGINATION.PAGINATION_FIRST' | translate
                        }}</ng-template>
                        <ng-template ngbPaginationPrevious>{{
                          'SHARED.PAGINATION.PAGINATION_PREVIOUS' | translate
                        }}</ng-template>
                        <ng-template ngbPaginationNext>{{
                          'SHARED.PAGINATION.PAGINATION_NEXT' | translate
                        }}</ng-template>
                        <ng-template ngbPaginationLast>{{
                          'SHARED.PAGINATION.PAGINATION_LAST' | translate
                        }}</ng-template>
                      </ngb-pagination>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- invoices list ends -->

<!-- no items template starts -->
<ng-template #noItems>
  <div class="col-md-12 alert alert-warning mt-3 text-center" role="alert">
    {{ 'SALES.CUSTOMERS.SEARCH_UNPAID_INVOICES_NOT_FOUND' | translate }}
  </div>
</ng-template>
<!-- no items template ends -->

<!-- locations search starts -->
<app-locations-search [(visible)]="locationsListVisibility" (locations)="selectLocations($event)">
</app-locations-search>
<!-- locations search ends -->

<!-- customer not paid invoices ends -->
