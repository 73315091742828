import { createSelector } from '@ngrx/store';

import { FinancialPeriodsState, FinancesState } from 'finances/store/states';
import { selectFinancesState } from 'finances/store/selectors/feature.selectors';

/**
 * Gets the financial periods state.
 */
const selectFinancialPeriodState = createSelector(
  selectFinancesState,
  (state: FinancesState) => state.financialPeriods
);

/**
 * Gets the list of loaded financial periods.
 */
export const getFinancialPeriods = createSelector(
  selectFinancialPeriodState,
  (state: FinancialPeriodsState) => state.data
);

/**
 * Gets the financial periods pagination info.
 */
export const getFinancialPeriodsPaginationInfo = createSelector(
  selectFinancialPeriodState,
  (state: FinancialPeriodsState) => state.paginationInfo
);

/**
 * Gets the error that occurred in the state.
 */
export const getFinancialPeriodsError = createSelector(
  selectFinancialPeriodState,
  (state: FinancialPeriodsState) => state.error
);

/**
 * Determines if there is a running search process.
 */
export const getFinancialPeriodsSearching = createSelector(
  selectFinancialPeriodState,
  (state: FinancialPeriodsState) => state.isSearching
);

/**
 * Determines if the last search process has been ended successfully.
 */
export const getFinancialPeriodsSearchCompleted = createSelector(
  selectFinancialPeriodState,
  (state: FinancialPeriodsState) => state.isSearchCompleted
);

/**
 * Determines if there is a running open process.
 */
export const getSelectedFinancialPeriodOpening = createSelector(
  selectFinancialPeriodState,
  (state: FinancialPeriodsState) => state.isOpening
);

/**
 * Determines if the last open process has been ended successfully.
 */
export const getSelectedFinancialPeriodOpeningCompleted = createSelector(
  selectFinancialPeriodState,
  (state: FinancialPeriodsState) => state.isOpenCompleted
);

/**
 * Determines if there is a running pause process.
 */
export const getSelectedFinancialPeriodPausing = createSelector(
  selectFinancialPeriodState,
  (state: FinancialPeriodsState) => state.isPausing
);

/**
 * Determines if the last pause process has been ended successfully.
 */
export const getSelectedFinancialPeriodPausingCompleted = createSelector(
  selectFinancialPeriodState,
  (state: FinancialPeriodsState) => state.isPauseCompleted
);

/**
 * Determines if there is a running close process.
 */
export const getSelectedFinancialPeriodClosing = createSelector(
  selectFinancialPeriodState,
  (state: FinancialPeriodsState) => state.isClosing
);

/**
 * Determines if the last close process has been ended successfully.
 */
export const getSelectedFinancialPeriodClosingCompleted = createSelector(
  selectFinancialPeriodState,
  (state: FinancialPeriodsState) => state.isCloseCompleted
);
