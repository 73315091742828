import { NavigationExtras } from '@angular/router';

import { Action } from '@ngrx/store';

/**
 * The store router action types.
 */
export enum RouterActionType {
  NAVIGATION = '@ngrx/router-store/request',
  NAVIGATED = '@ngrx/router-store/navigated',
  NAVIGATION_CANCEL = '@ngrx/router-store/cancel',
  GO = '[Router] Go',
  BACK = '[Router] Back',
  FORWARD = '[Router] Forward',
}

/** Represents a store router navigation action. */
export class Navigation implements Action {
  /**
   * The type of the action.
   */
  readonly type = RouterActionType.NAVIGATION;

  /**
   * Fires a new router navigation action.
   * @param payload An object contains route path, query params and navigation extras.
   */
  constructor(
    public payload: {
      routerState: {
        path: any[];
        query?: object;
        extras?: NavigationExtras;
      };
      event: {
        id: number;
        url: string;
        urlAfterRedirect: string;
        state: {
          url: string;
          params: {};
          queryParams: {};
        };
      };
    }
  ) {}
}

/** Represents a store router navigated action. */
export class Navigated implements Action {
  /**
   * The type of the action.
   */
  readonly type = RouterActionType.NAVIGATED;

  /**
   * Fires a new router navigated action.
   * @param payload An object contains route path, query params and navigation extras.
   */
  constructor(
    public payload: {
      routerState: {
        path: any[];
        query?: object;
        extras?: NavigationExtras;
      };
      event: {
        id: number;
        url: string;
        urlAfterRedirect: string;
        state: {
          url: string;
          params: {};
          queryParams: {};
        };
      };
    }
  ) {}
}

/** Represents a store router navigation-cancel action. */
export class NavigationCancel implements Action {
  /**
   * The type of the action.
   */
  readonly type = RouterActionType.NAVIGATION_CANCEL;

  /**
   * Fires a new router navigation-cancel action.
   * @param payload An object contains route path, query params and navigation extras.
   */
  constructor(
    public payload: {
      routerState: {
        path: any[];
        query?: object;
        extras?: NavigationExtras;
      };
      event: {
        id: number;
        url: string;
        urlAfterRedirect: string;
        state: {
          url: string;
          params: {};
          queryParams: {};
        };
      };
    }
  ) {}
}

/** Represents a store router go action. */
export class Go implements Action {
  /**
   * The type of the action.
   */
  readonly type = RouterActionType.GO;

  /**
   * Fires a new router go action.
   * @param payload An object contains route path, query params and navigation extras.
   */
  constructor(
    public payload: {
      path: any[];
      query?: object;
      extras?: NavigationExtras;
    }
  ) {}
}

/** Represents a store router back action. */
export class Back implements Action {
  /**
   * The type of the action.
   */
  readonly type = RouterActionType.BACK;
}

/** Represents a store router forward action. */
export class Forward implements Action {
  /**
   * The type of the action.
   */
  readonly type = RouterActionType.FORWARD;
}

/**
 * Core-module Router action types.
 */
export type RouterActions = Navigation | Navigated | NavigationCancel | Go | Back | Forward;
