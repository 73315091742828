/**
 * The set of system supported contract types.
 */
export enum ContractTypes {
  /**
   * The contract type.
   */
  CONTINGENT_CONTRACT = 'CONTINGENT_CONTRACT',
  FIXED_CONTRACT = 'FIXED_CONTRACT',
  HOME_WORKER = 'HOME_WORKER',
  STUDENT = 'STUDENT',
  TASK_SPECIFIC_CONTRACT = 'TASK_SPECIFIC_CONTRACT',
  WORK_TRAINING = 'WORK_TRAINING',
  EXTERNAL = 'EXTERNAL',
}
