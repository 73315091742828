import { PrintsActionType, PrintsActions } from 'printing/store/actions';
import { PrintsState } from 'printing/store/states';

/**
 * The initial state from which the state starts.
 */
const initialState: PrintsState = {
  incomingStock: null,
  outgoingStock: null,
  transferStock: null,
  purchaseRequest: null,
  purchaseOrder: null,
  purchaseInvoice: null,
  purchaseReturn: null,
  saleInvoice: null,
  saleReturn: null,
  salesQuotation: null,
  journal: null,
  error: null,
  isLoadingIncomingStock: false,
  isIncomingStockLoadCompleted: false,
  isLoadingOutgoingStock: false,
  isOutgoingStockLoadCompleted: false,
  isLoadingTransferStock: false,
  isTransferStockLoadCompleted: false,
  isLoadingPurchaseRequest: false,
  isPurchaseRequestLoadCompleted: false,
  isLoadingPurchaseOrder: false,
  isPurchaseOrderLoadCompleted: false,
  isLoadingPurchaseInvoice: false,
  isPurchaseInvoiceLoadCompleted: false,
  isLoadingPurchaseReturn: false,
  isPurchaseReturnLoadCompleted: false,
  isLoadingSaleInvoice: false,
  isSaleInvoiceLoadCompleted: false,
  isLoadingSaleReturn: false,
  isSaleReturnLoadCompleted: false,
  isLoadingSalesQuotation: false,
  isSalesQuotationLoadCompleted: false,
  isLoadingJournal: false,
  isJournalLoadCompleted: false,
};

/**
 * The reducer function that is called in each action dispatch against the state.
 * @param state The current state.
 * @param action The action that will affect the state.
 */
export function printsReducer(state: PrintsState = initialState, action: PrintsActions): PrintsState {
  switch (action.type) {
    //#region GET_INCOMING_STOCK_PRINT

    case PrintsActionType.GET_INCOMING_STOCK_PRINT: {
      return {
        ...state,
        isLoadingIncomingStock: true,
        isIncomingStockLoadCompleted: false,
        error: null,
      };
    }

    case PrintsActionType.GET_INCOMING_STOCK_PRINT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isLoadingIncomingStock: false,
        isIncomingStockLoadCompleted: false,
      };
    }

    case PrintsActionType.GET_INCOMING_STOCK_PRINT_RECEIVED: {
      return {
        ...state,
        error: null,
        isLoadingIncomingStock: false,
        isIncomingStockLoadCompleted: true,
      };
    }

    case PrintsActionType.GET_INCOMING_STOCK_PRINT_SUCCESS: {
      return {
        ...state,
        incomingStock: action.payload.data,
        error: null,
        isLoadingIncomingStock: false,
        isIncomingStockLoadCompleted: true,
      };
    }

    case PrintsActionType.INCOMING_STOCK_PRINT_VIEW: {
      return {
        ...state,
        incomingStock: action.payload,
        error: null,
        isLoadingIncomingStock: false,
        isIncomingStockLoadCompleted: true,
      };
    }

    //#endregion INCOMING_STOCK

    //#region GET_OUTGOING_STOCK_PRINT

    case PrintsActionType.GET_OUTGOING_STOCK_PRINT: {
      return {
        ...state,
        isLoadingOutgoingStock: true,
        isOutgoingStockLoadCompleted: false,
        error: null,
      };
    }

    case PrintsActionType.GET_OUTGOING_STOCK_PRINT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isLoadingOutgoingStock: false,
        isOutgoingStockLoadCompleted: false,
      };
    }

    case PrintsActionType.GET_OUTGOING_STOCK_PRINT_RECEIVED: {
      return {
        ...state,
        error: null,
        isLoadingOutgoingStock: false,
        isOutgoingStockLoadCompleted: true,
      };
    }

    case PrintsActionType.GET_OUTGOING_STOCK_PRINT_SUCCESS: {
      return {
        ...state,
        outgoingStock: action.payload.data,
        error: null,
        isLoadingOutgoingStock: false,
        isOutgoingStockLoadCompleted: true,
      };
    }

    case PrintsActionType.OUTGOING_STOCK_PRINT_VIEW: {
      return {
        ...state,
        outgoingStock: action.payload,
        error: null,
        isLoadingOutgoingStock: false,
        isOutgoingStockLoadCompleted: true,
      };
    }

    //#endregion OUTGOING_STOCK

    //#region GET_TRANSFER_STOCK_PRINT

    case PrintsActionType.GET_TRANSFER_STOCK_PRINT: {
      return {
        ...state,
        isLoadingTransferStock: true,
        isTransferStockLoadCompleted: false,
        error: null,
      };
    }

    case PrintsActionType.GET_TRANSFER_STOCK_PRINT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isLoadingTransferStock: false,
        isTransferStockLoadCompleted: false,
      };
    }

    case PrintsActionType.GET_TRANSFER_STOCK_PRINT_RECEIVED: {
      return {
        ...state,
        error: null,
        isLoadingTransferStock: false,
        isTransferStockLoadCompleted: true,
      };
    }

    case PrintsActionType.GET_TRANSFER_STOCK_PRINT_SUCCESS: {
      return {
        ...state,
        transferStock: action.payload.data,
        error: null,
        isLoadingTransferStock: false,
        isTransferStockLoadCompleted: true,
      };
    }

    case PrintsActionType.TRANSFER_STOCK_PRINT_VIEW: {
      return {
        ...state,
        transferStock: action.payload,
        error: null,
        isLoadingTransferStock: false,
        isTransferStockLoadCompleted: true,
      };
    }

    //#endregion TRANSFER_STOCK

    //#region GET_PURCHASE_REQUEST_PRINT

    case PrintsActionType.GET_PURCHASE_REQUEST_PRINT: {
      return {
        ...state,
        isLoadingPurchaseRequest: true,
        isPurchaseRequestLoadCompleted: false,
        error: null,
      };
    }

    case PrintsActionType.GET_PURCHASE_REQUEST_PRINT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isLoadingPurchaseRequest: false,
        isPurchaseRequestLoadCompleted: false,
      };
    }

    case PrintsActionType.GET_PURCHASE_REQUEST_PRINT_RECEIVED: {
      return {
        ...state,
        error: null,
        isLoadingPurchaseRequest: false,
        isPurchaseRequestLoadCompleted: true,
      };
    }

    case PrintsActionType.GET_PURCHASE_REQUEST_PRINT_SUCCESS: {
      return {
        ...state,
        purchaseRequest: action.payload.data,
        error: null,
        isLoadingPurchaseRequest: false,
        isPurchaseRequestLoadCompleted: true,
      };
    }

    case PrintsActionType.PURCHASE_REQUEST_PRINT_VIEW: {
      return {
        ...state,
        purchaseRequest: action.payload,
        error: null,
        isLoadingPurchaseRequest: false,
        isPurchaseRequestLoadCompleted: true,
      };
    }

    //#endregion PURCHASE_REQUEST

    //#region GET_PURCHASE_ORDER_PRINT

    case PrintsActionType.GET_PURCHASE_ORDER_PRINT: {
      return {
        ...state,
        isLoadingPurchaseOrder: true,
        isPurchaseOrderLoadCompleted: false,
        error: null,
      };
    }

    case PrintsActionType.GET_PURCHASE_ORDER_PRINT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isLoadingPurchaseOrder: false,
        isPurchaseOrderLoadCompleted: false,
      };
    }

    case PrintsActionType.GET_PURCHASE_ORDER_PRINT_RECEIVED: {
      return {
        ...state,
        error: null,
        isLoadingPurchaseOrder: false,
        isPurchaseOrderLoadCompleted: true,
      };
    }

    case PrintsActionType.GET_PURCHASE_ORDER_PRINT_SUCCESS: {
      return {
        ...state,
        purchaseOrder: action.payload.data,
        error: null,
        isLoadingPurchaseOrder: false,
        isPurchaseOrderLoadCompleted: true,
      };
    }

    case PrintsActionType.PURCHASE_ORDER_PRINT_VIEW: {
      return {
        ...state,
        purchaseOrder: action.payload,
        error: null,
        isLoadingPurchaseOrder: false,
        isPurchaseOrderLoadCompleted: true,
      };
    }

    //#endregion PURCHASE_ORDER

    //#region GET_PURCHASE_INVOICE_PRINT

    case PrintsActionType.GET_PURCHASE_INVOICE_PRINT: {
      return {
        ...state,
        isLoadingPurchaseInvoice: true,
        isPurchaseInvoiceLoadCompleted: false,
        error: null,
      };
    }

    case PrintsActionType.GET_PURCHASE_INVOICE_PRINT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isLoadingPurchaseInvoice: false,
        isPurchaseInvoiceLoadCompleted: false,
      };
    }

    case PrintsActionType.GET_PURCHASE_INVOICE_PRINT_RECEIVED: {
      return {
        ...state,
        error: null,
        isLoadingPurchaseInvoice: false,
        isPurchaseInvoiceLoadCompleted: true,
      };
    }

    case PrintsActionType.GET_PURCHASE_INVOICE_PRINT_SUCCESS: {
      return {
        ...state,
        purchaseInvoice: action.payload.data,
        error: null,
        isLoadingPurchaseInvoice: false,
        isPurchaseInvoiceLoadCompleted: true,
      };
    }

    case PrintsActionType.PURCHASE_INVOICE_PRINT_VIEW: {
      return {
        ...state,
        purchaseInvoice: action.payload,
        error: null,
        isLoadingPurchaseInvoice: false,
        isPurchaseInvoiceLoadCompleted: true,
      };
    }

    //#endregion PURCHASE_INVOICE

    //#region GET_PURCHASE_RETURN_PRINT

    case PrintsActionType.GET_PURCHASE_RETURN_PRINT: {
      return {
        ...state,
        isLoadingPurchaseReturn: true,
        isPurchaseReturnLoadCompleted: false,
        error: null,
      };
    }

    case PrintsActionType.GET_PURCHASE_RETURN_PRINT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isLoadingPurchaseReturn: false,
        isPurchaseReturnLoadCompleted: false,
      };
    }

    case PrintsActionType.GET_PURCHASE_RETURN_PRINT_RECEIVED: {
      return {
        ...state,
        error: null,
        isLoadingPurchaseReturn: false,
        isPurchaseReturnLoadCompleted: true,
      };
    }

    case PrintsActionType.GET_PURCHASE_RETURN_PRINT_SUCCESS: {
      return {
        ...state,
        purchaseReturn: action.payload.data,
        error: null,
        isLoadingPurchaseReturn: false,
        isPurchaseReturnLoadCompleted: true,
      };
    }

    case PrintsActionType.PURCHASE_RETURN_PRINT_VIEW: {
      return {
        ...state,
        purchaseReturn: action.payload,
        error: null,
        isLoadingPurchaseReturn: false,
        isPurchaseReturnLoadCompleted: true,
      };
    }

    //#endregion PURCHASE_RETURN

    //#region GET_SALE_INVOICE_PRINT

    case PrintsActionType.GET_SALE_INVOICE_PRINT: {
      return {
        ...state,
        isLoadingSaleInvoice: true,
        isSaleInvoiceLoadCompleted: false,
        error: null,
      };
    }

    case PrintsActionType.GET_SALE_INVOICE_PRINT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isLoadingSaleInvoice: false,
        isSaleInvoiceLoadCompleted: false,
      };
    }

    case PrintsActionType.GET_SALE_INVOICE_PRINT_RECEIVED: {
      return {
        ...state,
        error: null,
        isLoadingSaleInvoice: false,
        isSaleInvoiceLoadCompleted: true,
      };
    }

    case PrintsActionType.GET_SALE_INVOICE_PRINT_SUCCESS: {
      return {
        ...state,
        saleInvoice: action.payload.data,
        error: null,
        isLoadingSaleInvoice: false,
        isSaleInvoiceLoadCompleted: true,
      };
    }

    case PrintsActionType.SALE_INVOICE_PRINT_VIEW: {
      return {
        ...state,
        saleInvoice: action.payload,
        error: null,
        isLoadingSaleInvoice: false,
        isSaleInvoiceLoadCompleted: true,
      };
    }

    //#endregion SALE_INVOICE

    //#region GET_SALE_RETURN_PRINT

    case PrintsActionType.GET_SALE_RETURN_PRINT: {
      return {
        ...state,
        isLoadingSaleReturn: true,
        isSaleReturnLoadCompleted: false,
        error: null,
      };
    }

    case PrintsActionType.GET_SALE_RETURN_PRINT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isLoadingSaleReturn: false,
        isSaleReturnLoadCompleted: false,
      };
    }

    case PrintsActionType.GET_SALE_RETURN_PRINT_RECEIVED: {
      return {
        ...state,
        error: null,
        isLoadingSaleReturn: false,
        isSaleReturnLoadCompleted: true,
      };
    }

    case PrintsActionType.GET_SALE_RETURN_PRINT_SUCCESS: {
      return {
        ...state,
        saleReturn: action.payload.data,
        error: null,
        isLoadingSaleReturn: false,
        isSaleReturnLoadCompleted: true,
      };
    }

    case PrintsActionType.SALE_RETURN_PRINT_VIEW: {
      return {
        ...state,
        saleReturn: action.payload,
        error: null,
        isLoadingSaleReturn: false,
        isSaleReturnLoadCompleted: true,
      };
    }

    //#endregion SALE_RETURN

    //#region GET_SALES_QUOTATION_PRINT

    case PrintsActionType.GET_SALES_QUOTATION_PRINT: {
      return {
        ...state,
        isLoadingSalesQuotation: true,
        isSalesQuotationLoadCompleted: false,
        error: null,
      };
    }

    case PrintsActionType.GET_SALES_QUOTATION_PRINT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isLoadingSalesQuotation: false,
        isSalesQuotationLoadCompleted: false,
      };
    }

    case PrintsActionType.GET_SALES_QUOTATION_PRINT_RECEIVED: {
      return {
        ...state,
        error: null,
        isLoadingSalesQuotation: false,
        isSalesQuotationLoadCompleted: true,
      };
    }

    case PrintsActionType.GET_SALES_QUOTATION_PRINT_SUCCESS: {
      return {
        ...state,
        salesQuotation: action.payload.data,
        error: null,
        isLoadingSalesQuotation: false,
        isSalesQuotationLoadCompleted: true,
      };
    }

    case PrintsActionType.SALES_QUOTATION_PRINT_VIEW: {
      return {
        ...state,
        salesQuotation: action.payload,
        error: null,
        isLoadingSalesQuotation: false,
        isSalesQuotationLoadCompleted: true,
      };
    }

    //#endregion SALES_QUOTATION

    //#region GET_JOURNAL_PRINT

    case PrintsActionType.GET_JOURNAL_PRINT: {
      return {
        ...state,
        isLoadingJournal: true,
        isJournalLoadCompleted: false,
        error: null,
      };
    }

    case PrintsActionType.GET_JOURNAL_PRINT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isLoadingJournal: false,
        isJournalLoadCompleted: false,
      };
    }

    case PrintsActionType.GET_JOURNAL_PRINT_RECEIVED: {
      return {
        ...state,
        error: null,
        isLoadingJournal: false,
        isJournalLoadCompleted: true,
      };
    }

    case PrintsActionType.GET_JOURNAL_PRINT_SUCCESS: {
      return {
        ...state,
        journal: action.payload.data,
        error: null,
        isLoadingJournal: false,
        isJournalLoadCompleted: true,
      };
    }

    case PrintsActionType.JOURNAL_PRINT_VIEW: {
      return {
        ...state,
        journal: action.payload,
        error: null,
        isLoadingJournal: false,
        isJournalLoadCompleted: true,
      };
    }

    //#endregion GET_JOURNAL_PRINT

    default:
      return state;
  }
}
