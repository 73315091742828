import { Component, Input } from '@angular/core';

import { PettyCashRefund } from 'finances/models';

@Component({
  selector: 'app-journal-petty-cash-refund-details',
  templateUrl: './journal-petty-cash-refund-details.component.html',
  styles: [],
})
export class JournalPettyCashRefundDetailsComponent {
  /**
   * Sets the current viewed journal petty cash refund.
   */
  @Input() pettyCashRefund: PettyCashRefund;
}
