import { Component, OnInit, ViewChild, ElementRef, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms';

import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Store, select } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { skip, tap } from 'rxjs/operators';

import { NotificationService, NotificationMessage, TranslationService, APP_CONSTANTS } from 'shared';
import * as fromSalesStore from 'sales/store';
import * as fromLookupsStore from 'lookups/store';
import * as fromAuthStore from 'auth/store';
import { City, CustomerCategories } from 'lookups/models';
import { Location } from 'stores/models';

@Component({
  selector: 'app-create-customer-in-point-of-sale',
  templateUrl: './create-customer-in-point-of-sale.component.html',
  styles: [],
})
export class CreateCustomerInPointOfSaleComponent implements OnInit, OnDestroy {
  /**
   * The confirm modal template reference.
   */
  @ViewChild('confirmModalRef') confirmModalRef: ElementRef<any>;

  /**
   * The modal template reference.
   */
  @ViewChild('modalRef') modalRef: ElementRef<any>;

  /**
   * Sets the visibility of this component modal.
   *
   * Handles visibility change in two-data-binding approach.
   */
  @Input() set visible(isShown: boolean) {
    if (isShown && this.modalRef) {
      this.openModal(this.modalRef);
    } else {
      this.closeModal();
    }

    this.visibleChange.emit(isShown);
  }

  /**
   * Outputs a value that indicates whether the modal is shown or not.
   *
   * Handles visibility change in two-data-binding approach.
   */
  @Output() visibleChange = new EventEmitter<boolean>();

  /**
   * The ngb-modal reference to use it when modal needed to be closed.
   */
  modal: NgbModalRef;

  /**
   * Gets or sets the phone pattern.
   */
  public phonePattern = APP_CONSTANTS.phone.pattern;

  /**
   * Gets or sets the phone mask.
   */
  public phoneMask = APP_CONSTANTS.phone.mask;

  /**
   * The list of cities.
   */
  cities$: Observable<City[]>;

  /**
   * The default location of user.
   */
  location: Location;

  /**
   * Gets the customer category individual id.
   */
  customerCategoryId: number;

  /**
   * Gets the city id.
   */
  cityId: number;

  /**
   * The create customer form.
   */
  form: FormGroup;

  /**
   * The set of subscriptions on this components,
   * these subscriptions must be unsubscribed before this component got destroyed.
   */
  subscriptions = new Subscription();

  /**
   * Indicates whether there is a create customer process is running or not.
   */
  isCreating$: Observable<boolean>;

  /**
   * @param modalService The modal service.
   * @param notificationService The notification service.
   * @param translationService: The translation service.
   * @param salesStore$ The sales-module store.
   * @param lookupsStore$ The lookups-module store.
   * @param authStore$ the auth-store module.
   */
  constructor(
    private modalService: NgbModal,
    private notificationService: NotificationService,
    private translationService: TranslationService,
    private salesStore$: Store<fromSalesStore.SalesState>,
    private lookupsStore$: Store<fromLookupsStore.LookupsState>,
    private authStore$: Store<fromAuthStore.AuthState>
  ) {}

  ngOnInit(): void {
    this.init();
  }

  /**
   * Destroy component data
   */
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  /**
   * Initialize component data.
   */
  init() {
    this.initForm();

    /**
     * rest form on creation complete
     */
    this.subscriptions.add(
      this.salesStore$
        .pipe(
          select(fromSalesStore.getSelectedCustomerCreateCompleted),
          skip(1),
          tap((created) => {
            if (created) {
              this.initForm();
              this.form.markAsUntouched();
            }
          })
        )
        .subscribe()
    );

    /**
     * Loads data.
     */
    this.isCreating$ = this.salesStore$.pipe(select(fromSalesStore.getSelectedCustomerCreating));

    let isManualSearchTriggeredBeforeForCities = false;
    this.cities$ = this.lookupsStore$.pipe(
      select(fromLookupsStore.getCities),
      tap((cities) => {
        if (!isManualSearchTriggeredBeforeForCities && !cities.length) {
          isManualSearchTriggeredBeforeForCities = true;
          this.lookupsStore$.dispatch(new fromLookupsStore.GetAllCities());
        } else if (cities?.length) {
          this.cityId = cities[0].id;
          this.initForm();
        }
      })
    );

    this.subscriptions.add(
      this.lookupsStore$
        .pipe(
          select(fromLookupsStore.getCustomerCategories),
          /*
           * Get the 'INDIVIDUAL' customer category individual id.
           */
          tap((customerCategories) => {
            if (customerCategories) {
              this.customerCategoryId = customerCategories?.find(
                (customerCategory) => customerCategory.key === CustomerCategories.INDIVIDUAL
              )?.id;
              this.initForm();
            }
          })
        )
        .subscribe()
    );

    /* Subscribe to selected location to select it whenever it changed. */
    this.subscriptions.add(
      this.authStore$
        .pipe(
          select(fromAuthStore.getSessionUserDefaultLocation),
          tap((location) => {
            if (location) {
              this.location = location;
            }
          })
        )
        .subscribe()
    );

    this.lookupsStore$.dispatch(new fromLookupsStore.GetAllCustomerCategory());
  }

  /**
   * Initialize form and add validators.
   */
  initForm() {
    this.form = new FormGroup({
      name: new FormControl('', [Validators.required, Validators.minLength(1), Validators.maxLength(50)]),
      nameEn: new FormControl(''),
      cityId: new FormControl(this.cityId, Validators.required),
      customerCategoryId: new FormControl(this.customerCategoryId),
      vatNumber: new FormControl(''),
      commercialRegistrationNo: new FormControl(''),
      buildingNo: new FormControl(''),
      streetName: new FormControl(''),
      district: new FormControl(''),
      country: new FormControl(''),
      postalCode: new FormControl(''),
      additionalNo: new FormControl(''),
      otherBuyerId: new FormControl(''),
      moreInfo: new FormControl(''),
      representorName: new FormControl(''),
      representorMobile: new FormControl('', [Validators.required, Validators.minLength(1), Validators.maxLength(11)]),
      representorPhone: new FormControl(''),
      representorEmail: new FormControl(''),
      assets: new FormArray([]),
      engagements: new FormArray([]),
    });
  }

  /**
   * Submits the form.
   */
  submit() {
    if (this.form.invalid) {
      const errorMessage = new NotificationMessage();

      if (this.form.get('name').invalid) {
        errorMessage.title = this.translationService.translate(
          'SALES.CUSTOMERS.CUSTOMER_DATA_VALIDATION.CUSTOMER_NAME_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate('SALES.CUSTOMERS.CUSTOMER_DATA_VALIDATION.CUSTOMER_NAME_IS_REQUIRED'),
          this.translationService.translate('SALES.CUSTOMERS.CUSTOMER_DATA_VALIDATION.CUSTOMER_NAME_LENGTH_ERROR'),
        ];
      } else if (this.form.get('cityId').invalid) {
        errorMessage.title = this.translationService.translate(
          'SALES.CUSTOMERS.CUSTOMER_DATA_VALIDATION.CITY_NAME_ERROR'
        );
        errorMessage.body = [
          this.translationService.translate('SALES.CUSTOMERS.CUSTOMER_DATA_VALIDATION.CITY_NAME_IS_REQUIRED'),
        ];
      } else if (this.form.get('representorMobile').invalid) {
        errorMessage.title = this.translationService.translate('SALES.CUSTOMERS.CUSTOMER_DATA_VALIDATION.MOBILE_ERROR');
        errorMessage.body = [
          this.translationService.translate('SALES.CUSTOMERS.CUSTOMER_DATA_VALIDATION.MOBILE_IS_REQUIRED'),
          this.translationService.translate('SALES.CUSTOMERS.CUSTOMER_DATA_VALIDATION.MOBILE_LENGTH_ERROR'),
        ];
      }

      this.form.markAllAsTouched();
      return this.notificationService.warningWithTitle(errorMessage);
    } else {
      this.openConfirmModal(this.confirmModalRef);
    }
  }

  /**
   * Confirms the form submit.
   */
  confirm() {
    this.salesStore$.dispatch(
      new fromSalesStore.CreateCustomer({
        ...this.form.value,
        nameEn: this.form.value.name,
        locationId: this.location.id,
      })
    );
  }

  /**
   * Opens the modal of the given templateRef.
   * @param modalRef The modal templateRef to be opened.
   */
  openConfirmModal(modalRef) {
    this.modalService.open(modalRef);
  }

  /**
   * Opens the modal of the given templateRef.
   * @param modalRef The modal templateRef to be opened.
   */
  openModal(modalRef) {
    /**
     * Open the modal & listen for user press ESC key.
     */
    this.modal = this.modalService.open(modalRef);
    this.modal.result.then(null, () => this.closeModal());
  }

  /**
   * Closes the currently opened modal.
   */
  closeModal() {
    this.modal?.dismiss();
    this.visibleChange.emit(false);
  }
}
