import { Action } from '@ngrx/store';

import { AppHttpResponse } from 'shared';
import { OrganizationSetting, UpdateOrganizationSettingInput } from 'settings/models';

/**
 * The organization-settings-module store settings action types.
 */
export enum OrganizationSettingsActionType {
  GET_ORGANIZATION_SETTINGS = '[Settings] [OrganizationSettings] Get Organization Settings',
  GET_ORGANIZATION_SETTINGS_FAIL = '[Settings] [OrganizationSettings] Get Organization Settings Fail',
  GET_ORGANIZATION_SETTINGS_SUCCESS = '[Settings] [OrganizationSettings] Get Organization Settings Success',

  UPDATE_ORGANIZATION_SETTINGS = '[Settings] [OrganizationSettings] Update Organization Settings',
  UPDATE_ORGANIZATION_SETTINGS_FAIL = '[Settings] [OrganizationSettings] Update Organization Settings Fail',
  UPDATE_ORGANIZATION_SETTINGS_SUCCESS = '[Settings] [OrganizationSettings] Update Organization Settings Success',

  UPDATE_ORGANIZATION_SETTINGS_LOGO = '[Settings] [OrganizationSettings] Update Organization Settings Logo',

  DELETE_ORGANIZATION_SETTINGS_LOGO = '[Settings] [OrganizationSettings] Delete Organization Settings Logo',

  UPDATE_IMAGE_HEADER = '[Settings] [OrganizationSettings] Update Organization Image Header',

  DELETE_IMAGE_HEADER = '[Settings] [OrganizationSettings] Delete Organization Image Header',

  UPDATE_IMAGE_FOOTER = '[Settings] [OrganizationSettings] Update Organization Image Footer',

  DELETE_IMAGE_FOOTER = '[Settings] [OrganizationSettings] Delete Organization Image Footer',
}

/**
 * Represents a store get organization settings action.
 */
export class GetOrganizationSettings implements Action {
  /**
   * The type of the action.
   */
  readonly type = OrganizationSettingsActionType.GET_ORGANIZATION_SETTINGS;

  /**
   * Fires a new get organization settings action.
   */
  constructor() {}
}

/**
 * Represents a store get organization settings-fail action.
 */
export class GetOrganizationSettingsFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = OrganizationSettingsActionType.GET_ORGANIZATION_SETTINGS_FAIL;

  /**
   * Fires a new get organization settings-fail action.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store get organization settings-success action.
 */
export class GetOrganizationSettingsSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = OrganizationSettingsActionType.GET_ORGANIZATION_SETTINGS_SUCCESS;

  /**
   * Fires a new get organization settings-success action.
   */
  constructor(public payload: AppHttpResponse<OrganizationSetting>) {}
}

/**
 * Represents a store organization settings update action.
 */
export class UpdateOrganizationSettings implements Action {
  /**
   * The type of the action.
   */
  readonly type = OrganizationSettingsActionType.UPDATE_ORGANIZATION_SETTINGS;

  /**
   * Fires a new organization settings update action.
   * @param payload The organization settings' updated data.
   */
  constructor(public payload: UpdateOrganizationSettingInput) {}
}

/**
 * Represents a store organization settings update-fail action.
 */
export class UpdateOrganizationSettingsFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = OrganizationSettingsActionType.UPDATE_ORGANIZATION_SETTINGS_FAIL;

  /**
   * Fires a new organization settings update-fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store organization settings update-success action.
 */
export class UpdateOrganizationSettingsSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = OrganizationSettingsActionType.UPDATE_ORGANIZATION_SETTINGS_SUCCESS;

  /**
   * Fires a new organization settings update-success action.
   * @param payload The updated organization settings.
   */
  constructor(public payload: AppHttpResponse<OrganizationSetting>) {}
}

/**
 * Represents a store organization settings update  update logo action.
 */
export class UpdateOrganizationSettingsLogo implements Action {
  /**
   * The type of the action.
   */
  readonly type = OrganizationSettingsActionType.UPDATE_ORGANIZATION_SETTINGS_LOGO;

  /**
   * Fires a new organization setting update logo action.
   * @param payload The organization's id and the new logo.
   */
  constructor(public payload: File) {}
}

/**
 * Represents a store organization settings update  update image header action.
 */
export class UpdateImageHeader implements Action {
  /**
   * The type of the action.
   */
  readonly type = OrganizationSettingsActionType.UPDATE_IMAGE_HEADER;

  /**
   * Fires a new organization setting update logo action.
   * @param payload The organization's id and the new logo.
   */
  constructor(public payload: { invoiceHeaderFilename: File }) {}
}

/**
 * Represents a store organization settings update  update image footer action.
 */
export class UpdateImageFooter implements Action {
  /**
   * The type of the action.
   */
  readonly type = OrganizationSettingsActionType.UPDATE_IMAGE_FOOTER;

  /**
   * Fires a new organization setting update logo action.
   * @param payload The organization's id and the new logo.
   */
  constructor(public payload: { invoiceFooterFilename: File }) {}
}

/**
 * Represents a store organization settings delete logo action.
 */
export class DeleteOrganizationSettingsLogo implements Action {
  /**
   * The type of the action.
   */
  readonly type = OrganizationSettingsActionType.DELETE_ORGANIZATION_SETTINGS_LOGO;
}

/**
 * Represents a store organization settings delete image header action.
 */
export class DeleteImageHeader implements Action {
  /**
   * The type of the action.
   */
  readonly type = OrganizationSettingsActionType.DELETE_IMAGE_HEADER;
}

/**
 * Represents a store organization settings delete image footer action.
 */
export class DeleteImageFooter implements Action {
  /**
   * The type of the action.
   */
  readonly type = OrganizationSettingsActionType.DELETE_IMAGE_FOOTER;
}

/**
 * organizations-module organizations action types.
 */
export type OrganizationSettingsActions =
  | GetOrganizationSettings
  | GetOrganizationSettingsFail
  | GetOrganizationSettingsSuccess
  | UpdateOrganizationSettings
  | UpdateOrganizationSettingsFail
  | UpdateOrganizationSettingsSuccess
  | UpdateOrganizationSettingsLogo
  | DeleteOrganizationSettingsLogo
  | UpdateImageHeader
  | DeleteImageHeader
  | UpdateImageFooter
  | DeleteImageFooter;
