import { Component, ElementRef, ViewChild, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Decimal } from 'decimal.js';

import { PageInfo, TranslationService } from 'shared';
import * as fromFinancesStore from 'finances/store';
import {
  PettyCashRefundRequest,
  PettyCashRefundRequestLine,
  PettyCashRefundRequestLineAttachment,
} from 'finances/models';
import { Claims } from 'security/models';

/**
 * The view petty cash refund requests component pages.
 */
enum PAGES {
  details = 'details',
}
@Component({
  selector: 'app-petty-cash-refund-request-details',
  templateUrl: './petty-cash-refund-request-details.component.html',
  styles: [],
})
export class PettyCashRefundRequestDetailsComponent implements OnInit {
  /**
   * The confirm view attachments modal template reference.
   */
  @ViewChild('viewAttachmentsModalRef') viewAttachmentsModalRef: ElementRef<any>;

  /**
   * Gets or sets the information about the current page.
   */
  pageInfo: PageInfo = {
    title: 'FINANCES.PETTY_CASH_REFUND_REQUESTS.PETTY_CASH_REFUND_DATA',
    icon: 'fa fa-money',
  };

  /**
   * The system supported user claims.
   */
  Claims = Claims;

  /**
   * Sets the request id.
   */
  requestId: number;
  /**
   * The lines total of petty cash refund requests.
   */
  linesTotal: number;
  /**
   * The lines tax of petty cash refund requests.
   */
  linesTax: number;
  /**
   * The current viewed request line.
   */
  request$: Observable<PettyCashRefundRequest>;
  /**
   * Get selected petty cash refund requests approval.
   */
  selectedLine: PettyCashRefundRequestLine;

  /**
   * Gets or sets the selected page.
   * @default 'details'
   */
  activePage: PAGES = PAGES.details;
  /**
   * @param modalService The modal service.
   * @param route The activated route.
   * @param locationService The location service.
   * @param financesStore$ The finances store.
   * @param translationService The translation service.
   */
  constructor(
    private modalService: NgbModal,
    private route: ActivatedRoute,
    private locationService: Location,
    private financesStore$: Store<fromFinancesStore.FinancesState>,
    private translationService: TranslationService
  ) {}

  ngOnInit(): void {
    this.init();
  }

  /**
   * Initialize component data.
   */
  init() {
    /* Load the selected request line. */
    this.requestId = +this.route.snapshot.params.requestId;

    /* Load the selected request line. */
    this.request$ = this.financesStore$.pipe(
      select(fromFinancesStore.getSelectedPettyCashRefundRequest),
      tap((request) => {
        if (request) {
          /* Update page info. */
          this.pageInfo = {
            ...this.pageInfo,
            subTitle: this.translationService.translate(
              'FINANCES.PETTY_CASH_REFUND_REQUESTS.PETTY_CASH_REFUND_REQUEST_DATA.REQUEST_NUMBER',
              {
                requestNumber: request.id,
              }
            ),
          };
        }
      })
    );

    this.financesStore$.dispatch(new fromFinancesStore.FindPettyCashRefundRequest(this.requestId));

    /** Select the user desired page. */
    this.activePage = PAGES[this.route.snapshot.fragment] ?? this.activePage;
    this.selectedPageChanged(this.activePage);
  }

  /**
   * Updates the browser url according to the selected page.
   * @param page The newly selected page.
   */
  selectedPageChanged(page: PAGES) {
    this.locationService.replaceState(`${this.locationService.path()}#${PAGES[page]}`);
  }

  /**
   * Gets the total request lines of petty cash refund requests.
   */
  requestLinesTotal(requestLines: PettyCashRefundRequestLine[]): number {
    return Decimal.sum(0, ...requestLines.map((requestLine) => requestLine.value ?? 0)).toNumber();
  }

  /**
   * Gets the request lines tax of petty cash refund requests.
   */
  requestLinesTax(requestLines: PettyCashRefundRequestLine[]): number {
    this.linesTax = Decimal.sum(
      0,
      ...requestLines.map((request) => Decimal.div(request.tax, 100).mul(request.value) ?? 0)
    ).toNumber();

    return this.linesTax;
  }

  /**
   * Gets the total request of petty cash refund requests.
   */
  requestTotal(): number {
    return Decimal.sum(this.linesTotal ?? 0, this.linesTax ?? 0).toNumber();
  }

  /**
   * Maps and returns the set of petty cash refund request line attachments into a set of urls.
   * @param attachments The petty cash refund request line attachments to be mapped.
   */
  getAttachmentsUrls(attachments: PettyCashRefundRequestLineAttachment[]): string[] {
    return attachments ? attachments.map((attachment) => attachment.url) : [];
  }
  /**
   * Opens the modal of the given templateRef.
   * @param modalRef The modal templateRef to be opened.
   */
  openModal(modalRef) {
    this.modalService.open(modalRef);
  }
}
