import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { tap } from 'rxjs/operators';

import * as fromLookupsStore from 'lookups/store';
import { Location } from 'stores/models';
import { Customer } from 'sales/models';
import { ProjectStatus } from 'lookups/models';

@Component({
  selector: 'app-project-details',
  templateUrl: './project-details.component.html',
  styles: [],
})
export class ProjectDetailsComponent implements OnInit {
  /**
   * Sets the project details form form-group.
   */
  @Input() projectDetailsForm: FormGroup;

  /**
   * The list of project status.
   */
  projectStatuses$: Observable<ProjectStatus[]>;

  /**
   * Shows or hide the location list.
   */
  locationsListVisibility = false;

  /**
   * Shows or hide the customers list.
   */
  customersListVisibility = false;

  /**
   * The list of active customers.
   */
  customers: Customer[] = [];

  /**
   * The list of active locations.
   */
  locations: Location[] = [];

  /**
   * @param lookupsStore$ the lookups-store module.
   */
  constructor(private lookupsStore$: Store<fromLookupsStore.LookupsState>) {}

  ngOnInit(): void {
    this.init();
  }

  /**
   * Initialize component data.
   */
  init() {
    let isManualSearchTriggeredBeforeForProjectStatuses = false;
    this.projectStatuses$ = this.lookupsStore$.pipe(
      select(fromLookupsStore.getProjectStatuses),
      tap((projectStatuses) => {
        if (!isManualSearchTriggeredBeforeForProjectStatuses && !projectStatuses.length) {
          isManualSearchTriggeredBeforeForProjectStatuses = true;
          this.lookupsStore$.dispatch(new fromLookupsStore.GetAllProjectStatuses());
        }
      })
    );
  }

  /**
   * Selects the newly selected customer from the customers search list.
   * @param customers The list of newly selected customers to select the only one in the list.
   */
  selectCustomer([customer]: Customer[]) {
    if (customer) {
      this.customers = [customer];
      this.projectDetailsForm.patchValue({ customerId: customer.id });
    }
  }

  /**
   * Selects the newly selected location from the locations search list.
   * @param locations The list of newly selected locations to select the only one in the list.
   */
  selectLocation([location]: Location[]) {
    if (location) {
      this.locations = [location];
      this.projectDetailsForm.patchValue({ locationId: location.id });
    }
  }
}
