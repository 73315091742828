<div class="row">
  <div class="col-md-12">
    <!-- journal incoming stock details starts -->
    <div class="card-header mb-3 pt-0">
      <h4 class="card-title">{{ 'FINANCES.JOURNALS.JOURNAL_DATA.INCOMING_STOCK_DATA' | translate }}</h4>
    </div>
    <div class="card-content">
      <div class="px-3">
        <div class="row">
          <div class="form-group col-md-4">
            <label for="location">{{ 'FINANCES.JOURNALS.JOURNAL_DATA.LOCATION' | translate }}</label>
            <input
              type="text"
              id="location"
              class="form-control round"
              [value]="incomingStock.location.name | localize: incomingStock.location.nameEn | async"
              readonly
            />
          </div>
          <div class="form-group col-md-4">
            <label for="incomingStockTypeId">{{
              'FINANCES.JOURNALS.JOURNAL_DATA.INCOMING_STOCK_TYPE' | translate
            }}</label>
            <input
              type="text"
              id="incomingStockTypeId"
              class="form-control round"
              [value]="incomingStock.incomingStockType.name | localize: incomingStock.incomingStockType.nameEn | async"
              readonly
            />
          </div>
          <div class="form-group col-md-4">
            <label for="orderNum">{{ 'FINANCES.JOURNALS.JOURNAL_DATA.ORDER_NUMBER' | translate }}</label>
            <input
              type="text"
              id="orderNum"
              class="form-control round"
              [value]="incomingStock.purchaseOrder?.orderNum"
              readonly
            />
          </div>
          <div class="form-group col-md-12">
            <label for="notes">{{ 'FINANCES.JOURNALS.JOURNAL_DATA.NOTES' | translate }}</label>
            <textarea
              id="notes"
              class="form-control round"
              [innerText]="incomingStock.notes"
              [placeholder]="'FINANCES.JOURNALS.JOURNAL_DATA.NOTES_NOT_FOUND' | translate"
              readonly
            ></textarea>
          </div>
          <div class="form-group col-md-3">
            <label for="transactionDate">{{ 'FINANCES.JOURNALS.JOURNAL_DATA.JOURNAL_DATE' | translate }}</label>
            <input
              type="text"
              id="transactionDate"
              class="form-control round"
              [value]="incomingStock.transactionDate | date: 'datetime'"
              readonly
            />
          </div>
          <div class="form-group col-md-3">
            <label for="createdAt">{{ 'SHARED.SEARCH.CREATED_AT' | translate }}</label>
            <input
              type="text"
              id="createdAt"
              class="form-control round"
              [value]="incomingStock.createdAt | date: 'datetime'"
              readonly
            />
          </div>
          <div class="form-group col-md-6">
            <label for="user">{{ 'FINANCES.JOURNALS.JOURNAL_DATA.USER_CREATE' | translate }}</label>
            <input type="text" id="user" class="form-control round" [value]="incomingStock.user.name" readonly />
          </div>
        </div>
      </div>
    </div>
    <!-- journal incoming stock details ends -->

    <!-- products details starts -->
    <div class="card-header mb-3 pt-2">
      <h4 class="card-title">{{ 'FINANCES.JOURNALS.JOURNAL_DATA.PRODUCTS' | translate }}</h4>
    </div>
    <div class="card-content">
      <div class="px-3">
        <div class="row">
          <div class="col-md-12">
            <div class="table-responsive">
              <table class="table text-center">
                <thead>
                  <tr>
                    <th scope="col"><i class="fa fa-list-ol"></i></th>
                    <th scope="col">
                      <i class="fa fa-map-signs"></i>
                      {{ 'FINANCES.JOURNALS.JOURNAL_DATA.PRODUCT_TYPE' | translate }}
                    </th>
                    <th scope="col">
                      <i class="fa fa-comments"></i>
                      {{ 'FINANCES.JOURNALS.JOURNAL_DATA.PRODUCT_DESCRIPTION' | translate }}
                    </th>
                    <th scope="col">
                      <i class="fa fa-list-ol"></i>
                      {{ 'FINANCES.JOURNALS.JOURNAL_DATA.PRODUCT_QUANTITY' | translate }}
                    </th>
                    <th scope="col">
                      <i class="fa fa-balance-scale"></i>
                      {{ 'FINANCES.JOURNALS.JOURNAL_DATA.PRODUCT_UNIT_OF_MEASURE' | translate }}
                    </th>
                    <th scope="col">
                      <i class="fa fa-usd"></i>
                      {{ 'FINANCES.JOURNALS.JOURNAL_DATA.PRODUCT_VALUE' | translate }}
                    </th>
                    <th scope="col">
                      <i class="fa fa-usd"></i>
                      {{ 'FINANCES.JOURNALS.JOURNAL_DATA.PRODUCT_TOTAL' | translate }}
                    </th>
                    <th scope="col">
                      <i class="fa fa-percent"></i>
                      {{ 'FINANCES.JOURNALS.JOURNAL_DATA.PRODUCT_TAX' | translate }}
                    </th>
                    <th scope="col">
                      <i class="fa fa-calendar"></i>
                      {{ 'FINANCES.JOURNALS.JOURNAL_DATA.EXPIRE_DATE' | translate }}
                    </th>
                    <th scope="col">
                      {{ 'FINANCES.JOURNALS.JOURNAL_DATA.NOTES' | translate }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let product of incomingStock.incomingStockProducts; let i = index">
                    <th scope="row">{{ i + 1 }}</th>
                    <td>
                      <span class="c-success" *ngIf="product.product && !product.product?.isService">{{
                        'FINANCES.JOURNALS.JOURNAL_DATA.PRODUCT_NOT_HAS_SERVICE' | translate
                      }}</span>
                      <span class="c-success" *ngIf="product.product && product.product?.isService">{{
                        'FINANCES.JOURNALS.JOURNAL_DATA.SERVICE_PRODUCT' | translate
                      }}</span>
                      <span class="c-primary" *ngIf="!product.product">{{
                        'FINANCES.JOURNALS.JOURNAL_DATA.PRODUCT_NOT_STORABLE' | translate
                      }}</span>
                    </td>
                    <td>
                      {{
                        (product.product?.description | localize: product.product?.descriptionEn | async) ||
                          product.description
                      }}
                      <div *ngIf="product.subProduct" class="mt-1 d-block">
                        <app-sub-product-info [subProduct]="product.subProduct"></app-sub-product-info>
                      </div>
                    </td>
                    <td>{{ product.quantity | toDecimal }}</td>
                    <td>{{ product.unitOfMeasure.name | localize: product.unitOfMeasure.nameEn | async }}</td>
                    <td>{{ product.value | toDecimal }}</td>
                    <td>{{ product.total | toDecimal }}</td>
                    <td>{{ product.tax | toDecimal }}</td>
                    <td>{{ product.expiresAt | date }}</td>
                    <td>{{ product.notes }}</td>
                  </tr>
                </tbody>
                <tfoot class="tfoot-light">
                  <tr>
                    <td [attr.colspan]="3">
                      <i class="fa fa-bookmark c-primary"></i>
                      <span>
                        {{ incomingStock.incomingStockProducts.length | toNumber }}
                        {{ 'FINANCES.JOURNALS.JOURNAL_DATA.PRODUCT' | translate }}
                      </span>
                    </td>
                    <td [attr.colspan]="2">
                      <i class="fa fa-bookmark c-primary"></i>
                      <span>
                        {{ productsQuantity(incomingStock.incomingStockProducts) | toDecimal }}
                        {{ 'FINANCES.JOURNALS.JOURNAL_DATA.QUANTITY' | translate }}
                      </span>
                    </td>
                    <td [attr.colspan]="3">
                      <i class="fa fa-bookmark c-primary"></i>
                      <span>
                        {{ incomingStock.total | toDecimal }}
                        {{ 'FINANCES.JOURNALS.JOURNAL_DATA.TOTAL' | translate }}
                      </span>
                    </td>
                    <td colspan="2"></td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- products details ends -->

    <!-- incoming summary starts -->
    <div class="card-header mb-3 pt-2">
      <h4 class="card-title">{{ 'FINANCES.JOURNALS.JOURNAL_DATA.INVOICE_SUMMARY' | translate }}</h4>
    </div>
    <div class="card-content">
      <div class="px-3">
        <div class="row">
          <div class="form-group col-md-12">
            <label for="orderTotal" class="font-weight-bold" style="color: #212529;">
              <i class="fa fa-star"></i>
              <span class="mx-1">{{ 'FINANCES.JOURNALS.JOURNAL_DATA.INVOICE_TOTAL' | translate }}</span>
            </label>
            <input
              type="text"
              id="orderTotal"
              class="form-control round"
              [value]="incomingStock.total | toDecimal"
              readonly
            />
          </div>
        </div>
      </div>
    </div>
    <!-- incoming summary ends -->
  </div>
</div>
