import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';

import { Observable, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { PageInfo, PaginationInfo } from 'shared';
import * as fromStoresStore from 'stores/store';
import { UnitOfMeasure, UnitOfMeasureClass } from 'stores/models';
import { Claims } from 'security/models';

@Component({
  selector: 'app-units-of-measure-list',
  templateUrl: './units-of-measure-list.component.html',
  styles: [],
})
export class UnitsOfMeasureListComponent implements OnInit, OnDestroy {
  /**
   * Gets or sets the information about the current page.
   */
  pageInfo: PageInfo = {
    title: 'STORES.UNITS_OF_MEASURE.UNIT_OF_MEASURE_TITLE',
    icon: 'fa fa-balance-scale',
  };

  /**
   * The system supported user claims.
   */
  claims = Claims;

  /**
   * Indicates whether there is a delete-unit-of-measure process is running or not.
   */
  isDeleting$: Observable<boolean>;

  /**
   * The pagination info.
   */
  paginationInfo$: Observable<PaginationInfo>;

  /**
   * Indicates whether there is a search process is running or not.
   */
  isSearching$: Observable<boolean>;

  /**
   * The list of units of measure.
   */
  unitsOfMeasure$: Observable<UnitOfMeasure[]>;

  /**
   * Shows or hide the unit of measure classes list.
   */
  unitOfMeasureClassesListVisibility = false;

  /**
   * The list of selected unit Of measure classes.
   */
  unitOfMeasureClasses: UnitOfMeasureClass[] = [];

  /**
   * The search form.
   */
  searchForm: FormGroup;

  /**
   * The set of subscriptions on this components,
   * these subscriptions must be unsubscribed before this component got destroyed.
   */
  subscriptions = new Subscription();

  /**
   * Gets or sets the currently selected unit of measure class for delete.
   */
  selectedUnitOfMeasure: UnitOfMeasure;

  /**
   * @param modalService The modal service.
   * @param storesStore$ The stores store.
   */
  constructor(private modalService: NgbModal, private storesStore$: Store<fromStoresStore.StoresState>) {}
  ngOnInit(): void {
    this.init();
  }

  /**
   * Destroy component data
   */
  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  /**
   * Initialize component data.
   */
  init() {
    this.initForm();

    /**
     * Load data.
     */
    this.isDeleting$ = this.storesStore$.pipe(select(fromStoresStore.getSelectedUnitOfMeasureDeleting));
    this.isSearching$ = this.storesStore$.pipe(select(fromStoresStore.getUnitsOfMeasureSearching));

    let isManualSearchTriggeredBeforeForUnits = false;
    this.unitsOfMeasure$ = this.storesStore$.pipe(
      select(fromStoresStore.getUnitsOfMeasure),
      tap((units) => {
        if (!isManualSearchTriggeredBeforeForUnits && !units.length) {
          isManualSearchTriggeredBeforeForUnits = true;
          this.search();
        }
      })
    );

    this.paginationInfo$ = this.storesStore$.pipe(select(fromStoresStore.getUnitsOfMeasurePaginationInfo));
  }

  /**
   * Initialize form and add validators.
   */
  initForm() {
    this.searchForm = new FormGroup({
      name: new FormControl(''),
      unitOfMeasureClasses: new FormControl([]),
      page: new FormControl(1),
    });
  }

  /**
   * Handles search parameters change.
   */
  search(event?: KeyboardEvent) {
    if (event && event.key === 'Enter') {
      event.preventDefault();
      return;
    }

    this.applyFiltersAndSearch();
  }

  /**
   * Handles pagination page-changed event.
   * @param page The current selected page number.
   */
  pageChanged(page: number) {
    /** Update pagination page. */
    this.searchForm.patchValue({ page });

    this.applyFiltersAndSearch(page);
  }

  /**
   * Enables trackBy feature for *ngFor utility to track elements
   * depending on a comparer to reduce DOM change.
   * If the element is already exist then no HTML will change, only bounded values.
   * @param element The element to determine if it was changed or not.
   */
  trackElements(element: UnitOfMeasure) {
    return element ? element.id : undefined;
  }

  applyFiltersAndSearch(page: number = 1) {
    this.storesStore$.dispatch(
      new fromStoresStore.SearchUnitsOfMeasure({
        name: this.searchForm.get('name').value,
        unitOfMeasureClasses: this.searchForm.get('unitOfMeasureClasses').value,
        page,
      })
    );
  }

  /**
   * Deletes the unit of measure class with the given id.
   * @param unitOfMeasureId The id of the unit of measure  to be deleted.
   */
  delete(unitOfMeasureId: number) {
    this.storesStore$.dispatch(new fromStoresStore.DeleteUnitOfMeasure(unitOfMeasureId));
  }

  /**
   * Opens the modal of the given templateRef.
   * @param modalRef The modal templateRef to be opened.
   */
  openModal(modalRef) {
    this.modalService.open(modalRef);
  }

  /**
   * Adds the newly selected unit of measure class the unit of measure class search list.
   * @param unitOfMeasureClasses The list of newly selected unit of measure class to be added.
   */
  selectUnitOfMeasureClasses(unitOfMeasureClasses: UnitOfMeasureClass[]) {
    const selectedIds: number[] = this.searchForm.get('unitOfMeasureClasses').value;
    this.unitOfMeasureClasses = [
      ...this.unitOfMeasureClasses.filter((unitOfMeasureClass) => selectedIds.includes(unitOfMeasureClass.id)),
      ...unitOfMeasureClasses,
    ];
    this.searchForm.patchValue({
      unitOfMeasureClasses: this.unitOfMeasureClasses.map((unitOfMeasureClass) => unitOfMeasureClass.id),
    });
  }
}
