import { findIndex, remove } from 'lodash';

import { ShiftsActionType, ShiftsActions } from 'sales/store/actions';
import { ShiftsState } from 'sales/store/states';

/**
 * The initial state from which the state starts.
 */
const initialState: ShiftsState = {
  data: [],
  paginationInfo: {
    total: 0,
    page: 1,
    pageSize: 0,
    count: 0,
  },
  error: null,
  isSearching: false,
  isSearchCompleted: false,
  isAllShiftsSearching: false,
  isAllShiftsSearchCompleted: false,
  selectedShift: null,
  isFinding: false,
  isFindCompleted: false,
  isStarting: false,
  isStartCompleted: false,
  isShiftForCasherStarting: false,
  isShiftForCasherStartCompleted: false,
  isCanceling: false,
  isCancelCompleted: false,
  isShiftCanceling: false,
  isShiftCancelCompleted: false,
  isShiftDeleting: false,
  isShiftDeleteCompleted: false,
};

/**
 * The reducer function that is called in each action dispatch against the state.
 * @param state The current state.
 * @param action The action that will affect the state.
 */
export function shiftsReducer(state: ShiftsState = initialState, action: ShiftsActions): ShiftsState {
  switch (action.type) {
    //#region SEARCH_MY_SHIFTS

    case ShiftsActionType.SEARCH_MY_SHIFTS: {
      return {
        ...state,
        error: null,
        isSearching: true,
        isSearchCompleted: false,
      };
    }

    case ShiftsActionType.SEARCH_MY_SHIFTS_FAIL: {
      return {
        ...state,
        error: action.payload,
        isSearching: false,
        isSearchCompleted: false,
      };
    }

    case ShiftsActionType.SEARCH_MY_SHIFTS_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        paginationInfo: {
          ...action.payload.meta,
        },
        error: null,
        isSearching: false,
        isSearchCompleted: true,
      };
    }

    //#endregion SEARCH_MY_SHIFTS

    //#region SEARCH_SHIFTS

    case ShiftsActionType.SEARCH_SHIFTS: {
      return {
        ...state,
        error: null,
        isAllShiftsSearching: true,
        isAllShiftsSearchCompleted: false,
      };
    }

    case ShiftsActionType.SEARCH_SHIFTS_FAIL: {
      return {
        ...state,
        error: action.payload,
        isAllShiftsSearching: false,
        isAllShiftsSearchCompleted: false,
      };
    }

    case ShiftsActionType.SEARCH_SHIFTS_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        paginationInfo: {
          ...action.payload.meta,
        },
        error: null,
        isAllShiftsSearching: false,
        isAllShiftsSearchCompleted: true,
      };
    }

    //#endregion SEARCH_MY_SHIFTS

    //#region FIND_SHIFT

    case ShiftsActionType.FIND_SHIFT: {
      return {
        ...state,
        selectedShift: null,
        error: null,
        isFinding: true,
        isFindCompleted: false,
      };
    }

    case ShiftsActionType.FIND_SHIFT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isFinding: false,
        isFindCompleted: false,
      };
    }

    case ShiftsActionType.FIND_SHIFT_SUCCESS: {
      let myShifts = [...state.data];
      const myShiftIndex = findIndex(myShifts, (myShift) => myShift.id === action.payload.data.id);

      /* If my shift was found, update it. */
      if (myShiftIndex >= 0) {
        myShifts[myShiftIndex] = action.payload.data;
      } else {
        /* else, insert it to the beginning of the array. */
        myShifts = [action.payload.data, ...myShifts];
      }

      return {
        ...state,
        data: myShifts,
        selectedShift: action.payload.data,
        error: null,
        isFinding: false,
        isFindCompleted: true,
      };
    }

    //#endregion FIND_SHIFT

    //#region START_SHIFT

    case ShiftsActionType.START_SHIFT: {
      return {
        ...state,
        error: null,
        isStarting: true,
        isStartCompleted: false,
      };
    }

    case ShiftsActionType.START_SHIFT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isStarting: false,
        isStartCompleted: false,
      };
    }

    case ShiftsActionType.START_SHIFT_SUCCESS: {
      return {
        ...state,
        data: [action.payload.data, ...state.data],
        selectedShift: action.payload.data,
        error: null,
        isStarting: false,
        isStartCompleted: true,
      };
    }

    //#endregion START_SHIFT

    //#region START_SHIFT_FOR_CASHER

    case ShiftsActionType.START_SHIFT_FOR_CASHER: {
      return {
        ...state,
        error: null,
        isShiftForCasherStarting: true,
        isShiftForCasherStartCompleted: false,
      };
    }

    case ShiftsActionType.START_SHIFT_FOR_CASHER_FAIL: {
      return {
        ...state,
        error: action.payload,
        isShiftForCasherStarting: false,
        isShiftForCasherStartCompleted: false,
      };
    }

    case ShiftsActionType.START_SHIFT_FOR_CASHER_SUCCESS: {
      return {
        ...state,
        data: [action.payload.data, ...state.data],
        selectedShift: action.payload.data,
        error: null,
        isShiftForCasherStarting: false,
        isShiftForCasherStartCompleted: true,
      };
    }

    //#endregion START_SHIFT_FOR_CASHER

    //#region CANCEL_MY_SHIFT

    case ShiftsActionType.CANCEL_MY_SHIFT: {
      return {
        ...state,
        error: null,
        isCanceling: true,
        isCancelCompleted: false,
      };
    }

    case ShiftsActionType.CANCEL_MY_SHIFT_FAIL: {
      return {
        ...state,
        error: action.payload.data,
        isCanceling: false,
        isCancelCompleted: false,
      };
    }

    case ShiftsActionType.CANCEL_MY_SHIFT_SUCCESS: {
      let myShifts = [...state.data];
      const myShiftIndex = findIndex(myShifts, (myShift) => myShift.id === action.payload.data.id);

      /* If myShift was found, update it. */
      if (myShiftIndex >= 0) {
        myShifts[myShiftIndex] = action.payload.data;
      } else {
        /* else, insert it to the beginning of the array. */
        myShifts = [action.payload.data, ...myShifts];
      }

      return {
        ...state,
        data: myShifts,
        selectedShift: action.payload.data,
        error: null,
        isCanceling: false,
        isCancelCompleted: true,
      };
    }

    //#endregion CANCEL_MY_SHIFT

    //#region END_SHIFT

    case ShiftsActionType.END_SHIFT: {
      return {
        ...state,
        error: null,
        isShiftCanceling: true,
        isShiftCancelCompleted: false,
      };
    }

    case ShiftsActionType.END_SHIFT_FAIL: {
      return {
        ...state,
        error: action.payload.data,
        isShiftCanceling: false,
        isShiftCancelCompleted: false,
      };
    }

    case ShiftsActionType.END_SHIFT_SUCCESS: {
      let shifts = [...state.data];
      const shiftIndex = findIndex(shifts, (shift) => shift.id === action.payload.data.id);

      /* If shift was found, update it. */
      if (shiftIndex >= 0) {
        shifts[shiftIndex] = action.payload.data;
      } else {
        /* else, insert it to the beginning of the array. */
        shifts = [action.payload.data, ...shifts];
      }

      return {
        ...state,
        data: shifts,
        selectedShift: action.payload.data,
        error: null,
        isShiftCanceling: false,
        isShiftCancelCompleted: true,
      };
    }

    //#endregion END_SHIFT

    //#region DELETE_SHIFT

    case ShiftsActionType.DELETE_SHIFT: {
      return {
        ...state,
        error: null,
        isShiftDeleting: true,
        isShiftDeleteCompleted: false,
      };
    }

    case ShiftsActionType.DELETE_SHIFT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isShiftDeleting: false,
        isShiftDeleteCompleted: false,
      };
    }

    case ShiftsActionType.DELETE_SHIFT_SUCCESS: {
      const shifts = [...state.data];

      /* Remove the deleted shift from the array. */
      remove(shifts, (shift) => shift.id === action.payload.data.id);

      return {
        ...state,
        data: shifts,
        selectedShift: action.payload.data,
        error: null,
        isShiftDeleting: false,
        isShiftDeleteCompleted: true,
      };
    }

    //#endregion DELETE_SHIFT

    default:
      return state;
  }
}
