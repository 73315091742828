<div [formGroup]="journalLinesForm.parent" class="row">
  <div class="col-md-12">
    <!-- <button
      type="button"
      class="btn btn-sm btn-raised btn-success round"
      [ngbTooltip]="'FINANCES.JOURNALS.JOURNAL_DATA.ADJUST_JOURNAL_BALANCE' | translate"
      placement="top-right"
      (click)="adjustJournalBalance()"
    >
      <i class="fa fa-balance-scale"></i>
    </button> -->
    <button
      *ngIf="allowAddLine"
      type="button"
      class="btn btn-sm btn-raised btn-primary round mx-2"
      [ngbTooltip]="'FINANCES.JOURNALS.JOURNAL_DATA.ADD_JOURNAL_LINE' | translate"
      placement="top-right"
      (click)="addJournalLine()"
    >
      <i class="fa fa-plus"></i>
    </button>
    <button
      *ngIf="allowClearLines"
      type="button"
      class="btn btn-sm btn-raised btn-danger round mx-2"
      [ngbTooltip]="'FINANCES.JOURNALS.JOURNAL_DATA.DELETE_ALL_JOURNALS' | translate"
      placement="left"
      (click)="resetJournalLines()"
    >
      <i class="fa fa-trash"></i>
    </button>
  </div>
  <div class="col-md-12">
    <div class="table-responsive">
      <table class="table text-center" [ngStyle]="{ 'padding-bottom': dropDownIsOpened ? '8rem' : 'unset' }">
        <thead>
          <tr>
            <th scope="col">
              <i class="fa fa-list-ol"></i>
            </th>
            <th scope="col">
              {{ 'FINANCES.JOURNALS.JOURNAL_DATA.ACCOUNT_DESCRIPTION' | translate }}
            </th>
            <th scope="col">
              {{ 'FINANCES.JOURNALS.JOURNAL_DATA.DEBIT' | translate }}
            </th>
            <th scope="col">
              {{ 'FINANCES.JOURNALS.JOURNAL_DATA.CREDIT' | translate }}
            </th>
            <th scope="col">
              {{ 'FINANCES.JOURNALS.JOURNAL_DATA.COST_CENTER' | translate }}
            </th>
            <th scope="col" *ngIf="showSaleInvoiceNumberColumn || showPurchaseInvoiceNumberColumn">
              <i class="fa fa-barcode"></i
              ><span class="mx-1">{{ 'FINANCES.JOURNALS.JOURNAL_DATA.INVOICE_NUMBER' | translate }}</span>
            </th>
            <th scope="col">
              {{ 'FINANCES.JOURNALS.JOURNAL_DATA.NOTES' | translate }}
            </th>
            <th *ngIf="allowRemoveLine" scope="col">{{ 'SHARED.TOOLS' | translate }}</th>
          </tr>
        </thead>
        <tbody formArrayName="lines">
          <tr
            app-journal-line-form-item
            *ngFor="let line of journalLinesForm.controls; let i = index"
            [journalLineGroup]="line"
            [isGeneralCostCenter]="isGeneralCostCenter"
            [generalCostCenterData]="generalCostCenterData"
            [allowRemoveLine]="allowRemoveLine"
            [showSaleInvoiceNumberColumn]="showSaleInvoiceNumberColumn"
            [showPurchaseInvoiceNumberColumn]="showPurchaseInvoiceNumberColumn"
            [defaultCostCenter]="defaultCostCenter"
            [index]="i"
            (removeChange)="removeJournalLine(i)"
            (dropDownIsOpened)="dropDownIsOpened = $event"
          ></tr>
        </tbody>
        <tfoot class="tfoot-light">
          <tr>
            <td [attr.colspan]="9">
              <i class="fa fa-bookmark c-primary"></i>
              <span>
                {{ journalLinesForm.controls.length | toNumber }}
                {{ 'FINANCES.JOURNALS.JOURNAL_DATA.JOURNAL_LINE' | translate }}
              </span>
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  </div>
</div>
