import { Action } from '@ngrx/store';

import { AppHttpResponse } from 'shared';

import { EmployeeProvisionLink, GovService, UpdateEmployeeProvisionLink, UpdateGovService } from 'finances/models';

/**
 * The finance configurations-module store action types.
 */
export enum FinanceConfigurationsActionType {
  GET_EMPLOYEE_PROVISION_LINKS = '[Finances] [Configurations] Get Employee Provision Links',
  GET_EMPLOYEE_PROVISION_LINKS_FAIL = '[Finances] [Configurations] Get Employee Provision Links Fail',
  GET_EMPLOYEE_PROVISION_LINKS_SUCCESS = '[Finances] [Configurations] Get Employee Provision Links Success',

  UPDATE_EMPLOYEE_PROVISION_LINKS = '[Finances] [Configurations] Update Employee Provision Links',
  UPDATE_EMPLOYEE_PROVISION_LINKS_FAIL = '[Finances] [Configurations] Update Employee Provision Links Fail',
  UPDATE_EMPLOYEE_PROVISION_LINKS_SUCCESS = '[Finances] [Configurations] Update Employee Provision Links Success',

  GET_GOV_SERVICES = '[Finances] [Configurations] Get Gov Services',
  GET_GOV_SERVICES_FAIL = '[Finances] [Configurations] Get Gov Services Fail',
  GET_GOV_SERVICES_SUCCESS = '[Finances] [Configurations] Get Gov Services Success',

  UPDATE_GOV_SERVICES = '[Finances] [Configurations] Update Gov Services',
  UPDATE_GOV_SERVICES_FAIL = '[Finances] [Configurations] Update Gov Services Fail',
  UPDATE_GOV_SERVICES_SUCCESS = '[Finances] [Configurations] Update Gov Services Success',
}

/**
 * Represents a store employee provision links get action.
 */
export class GetEmployeeProvisionLinks implements Action {
  /**
   * The type of the action.
   */
  readonly type = FinanceConfigurationsActionType.GET_EMPLOYEE_PROVISION_LINKS;

  /**
   * Fires a new employee provision links get action.
   * @param payload the get parameters,
   * if omitted, all employee provision links will be loaded.
   */
  constructor() {}
}

/**
 * Represents a store employee provision links get-fail action.
 */
export class GetEmployeeProvisionLinksFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = FinanceConfigurationsActionType.GET_EMPLOYEE_PROVISION_LINKS_FAIL;

  /**
   * Fires a new employee provision links get-fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store get-success action.
 */
export class GetEmployeeProvisionLinksSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = FinanceConfigurationsActionType.GET_EMPLOYEE_PROVISION_LINKS_SUCCESS;

  /**
   * Fires a new employee provision links get-success action.
   * @param payload An object contains the list of employee provision links that matches the get criteria
   * in addition to the pagination info.
   */
  constructor(public payload: AppHttpResponse<EmployeeProvisionLink[]>) {}
}

/**
 * Represents a store employee provision links update action.
 */
export class UpdateEmployeeProvisionLinks implements Action {
  /**
   * The type of the action.
   */
  readonly type = FinanceConfigurationsActionType.UPDATE_EMPLOYEE_PROVISION_LINKS;

  /**
   * Fires a new employee provision links update action.
   * @param payload The employee provision link's updated data.
   */
  constructor(public payload: UpdateEmployeeProvisionLink) {}
}

/**
 * Represents a store employee provision links update-fail action.
 */
export class UpdateEmployeeProvisionLinksFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = FinanceConfigurationsActionType.UPDATE_EMPLOYEE_PROVISION_LINKS_FAIL;

  /**
   * Fires a new employee provision links update-fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store employee provision links update-success action.
 */
export class UpdateEmployeeProvisionLinksSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = FinanceConfigurationsActionType.UPDATE_EMPLOYEE_PROVISION_LINKS_SUCCESS;

  /**
   * Fires a new employee provision links update-success action.
   * @param payload The updated employee provision links.
   */
  constructor(public payload: AppHttpResponse<EmployeeProvisionLink[]>) {}
}

/**
 * Represents a store gov services get action.
 */
export class GetGovServices implements Action {
  /**
   * The type of the action.
   */
  readonly type = FinanceConfigurationsActionType.GET_GOV_SERVICES;

  /**
   * Fires a new gov services get action.
   * @param payload the get parameters,
   * if omitted, all gov services will be loaded.
   */
  constructor() {}
}

/**
 * Represents a store gov services get-fail action.
 */
export class GetGovServicesFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = FinanceConfigurationsActionType.GET_GOV_SERVICES_FAIL;

  /**
   * Fires a new gov services get-fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store get-success action.
 */
export class GetGovServicesSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = FinanceConfigurationsActionType.GET_GOV_SERVICES_SUCCESS;

  /**
   * Fires a new gov services get-success action.
   * @param payload An object contains the list of gov services that matches the get criteria
   * in addition to the pagination info.
   */
  constructor(public payload: AppHttpResponse<GovService[]>) {}
}

/**
 * Represents a store gov services update action.
 */
export class UpdateGovServices implements Action {
  /**
   * The type of the action.
   */
  readonly type = FinanceConfigurationsActionType.UPDATE_GOV_SERVICES;

  /**
   * Fires a new gov services update action.
   * @param payload The employee provision link's updated data.
   */
  constructor(public payload: UpdateGovService) {}
}

/**
 * Represents a store gov services update-fail action.
 */
export class UpdateGovServicesFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = FinanceConfigurationsActionType.UPDATE_GOV_SERVICES_FAIL;

  /**
   * Fires a new gov services update-fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store gov services update-success action.
 */
export class UpdateGovServicesSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = FinanceConfigurationsActionType.UPDATE_GOV_SERVICES_SUCCESS;

  /**
   * Fires a new gov services update-success action.
   * @param payload The updated gov services.
   */
  constructor(public payload: AppHttpResponse<GovService[]>) {}
}

/**
 * finance configurations-module finance configurations action types.
 */
export type FinanceConfigurationsActions =
  | GetEmployeeProvisionLinks
  | GetEmployeeProvisionLinksFail
  | GetEmployeeProvisionLinksSuccess
  | UpdateEmployeeProvisionLinks
  | UpdateEmployeeProvisionLinksFail
  | UpdateEmployeeProvisionLinksSuccess
  | GetGovServices
  | GetGovServicesFail
  | GetGovServicesSuccess
  | UpdateGovServices
  | UpdateGovServicesFail
  | UpdateGovServicesSuccess;
