import { Component, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, FormArray } from '@angular/forms';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Decimal } from 'decimal.js';

import { PettyCashRefundRequestType } from 'lookups/models';
import { Tax } from 'settings/models';

@Component({
  selector: '[app-petty-cash-refund-request-form-item]',
  templateUrl: './petty-cash-refund-request-form-item.component.html',
  styles: [],
})
export class PettyCashRefundRequestFormItemComponent {
  /**
   * Sets the line index in the lines-form.
   */
  @Input() index: number;

  /**
   * Sets the line form-group.
   */
  @Input() lineGroup: FormGroup;

  /**
   * Sets the list of types.
   */
  @Input() types: PettyCashRefundRequestType[];

  /**
   * Sets the id of the debit notification refund request type.
   */
  @Input() debitNotificationPettyCashRefundRequestTypeId: number;

  /**
   * Sets the list of taxes.
   */
  @Input() taxes: Tax[];

  /**
   * Emits a value indicates that a drop-down field in the form is opened and therefore the table height should be expanded.
   */
  @Output() dropDownIsOpened = new EventEmitter<boolean>(false);

  /**
   * Emits a value when the user wants to remove current invoice from the list.
   */
  @Output() removeChange = new EventEmitter<any>();

  /**
   * Gets the attachments as form-array.
   */
  get attachmentsForm(): FormArray {
    return this.lineGroup?.controls.attachments as FormArray;
  }

  /**
   * Gets the net value of the current line.
   */
  get lineTotal(): number {
    return Decimal.add(
      this.lineGroup.value.value,
      Decimal.div(this.lineGroup.value.tax, 100).mul(this.lineGroup.value.value)
    ).toNumber();
  }

  /**
   * @param modalService The modal service.
   */
  constructor(private modalService: NgbModal) {}

  /**
   * Remove the line from the list.
   */
  remove() {
    this.removeChange.emit(null);
  }

  /**
   * Adds a blank attachment form-control to the attachments form for quick start..
   */
  addBlankAttachment() {
    this.attachmentsForm.push(new FormControl());
  }

  /**
   * Opens the modal of the given templateRef.
   * @param modalRef The modal templateRef to be opened.
   */
  openModal(modalRef) {
    this.modalService.open(modalRef);
  }
}
