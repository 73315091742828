import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subscription } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { tap } from 'rxjs/operators';

import * as fromSecurityStore from 'security/store';
import * as fromAuthStore from 'auth/store';
import { PageInfo, PaginationInfo } from 'shared';
import { User, Claims } from 'security/models';

@Component({
  selector: 'app-organization-users-list',
  templateUrl: './organization-users-list.component.html',
  styles: [],
})
export class OrganizationUsersListComponent implements OnInit, OnDestroy {
  /**
   * Gets or sets the information about the current page.
   */
  pageInfo: PageInfo = {
    title: 'SECURITY.USERS.USERS',
    icon: 'icon-people',
  };

  /**
   * The system supported user claims.
   */
  Claims = Claims;

  /**
   * The list of organization users.
   */
  organizationUsers$: Observable<User[]>;

  /**
   * Gets or sets the currently selected user for block, activate and delete.
   */
  selectedUser: User;

  /**
   * The current logged in user id.
   */
  currentLoggedInUserId: number;

  /**
   * Indicates whether there is a search process is running or not.
   */
  isSearching$: Observable<boolean>;

  /**
   * Indicates whether there is a block-user process is running or not.
   */
  isBlocking$: Observable<boolean>;

  /**
   * Indicates whether there is a activate-user process is running or not.
   */
  isActivating$: Observable<boolean>;

  /**
   * The pagination info.
   */
  paginationInfo$: Observable<PaginationInfo>;

  /**
   * The search form.
   */
  searchForm: FormGroup;

  /**
   * The set of subscriptions on this components,
   * these subscriptions must be unsubscribed before this component got destroyed.
   */
  subscriptions = new Subscription();

  /**
   * @param modalService The modal service.
   * @param securityStore$ Security-module store.
   * @param authStore$ The auth-module store.
   */
  constructor(
    private modalService: NgbModal,
    private securityStore$: Store<fromSecurityStore.SecurityState>,
    private authStore$: Store<fromAuthStore.AuthState>
  ) {}

  ngOnInit(): void {
    this.init();
  }

  /**
   * Destroy component data
   */
  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  /**
   * Initialize component data.
   */
  init() {
    this.initForm();

    /** Load current logged in user data. */
    this.subscriptions.add(
      this.authStore$
        .pipe(
          select(fromAuthStore.getSessionUser),
          tap((user: User) => {
            if (user) {
              this.currentLoggedInUserId = user.id;
            }
          })
        )
        .subscribe()
    );

    /**
     * Loads data.
     */

    this.isSearching$ = this.securityStore$.pipe(select(fromSecurityStore.getUsersSearching));
    this.isActivating$ = this.securityStore$.pipe(select(fromSecurityStore.getSelectedUserActivating));
    this.isBlocking$ = this.securityStore$.pipe(select(fromSecurityStore.getSelectedUserBlocking));
    this.organizationUsers$ = this.securityStore$.pipe(select(fromSecurityStore.getUsers));
    this.paginationInfo$ = this.securityStore$.pipe(select(fromSecurityStore.getUsersPaginationInfo));

    let isManualSearchTriggeredBeforeForUsers = false;
    this.organizationUsers$ = this.securityStore$.pipe(
      select(fromSecurityStore.getUsers),
      tap((users) => {
        if (!isManualSearchTriggeredBeforeForUsers && !users.length) {
          isManualSearchTriggeredBeforeForUsers = true;
          this.search();
        }
      })
    );
  }

  /**
   * Initialize form and add validators.
   */
  initForm() {
    this.searchForm = new FormGroup({
      name: new FormControl(''),
      page: new FormControl(1),
    });
  }

  /**
   * Handles search parameters change.
   */
  search(event?: KeyboardEvent) {
    if (event && event.key === 'Enter') {
      event.preventDefault();
      return;
    }
    this.securityStore$.dispatch(
      new fromSecurityStore.GetOrganizationUsers({
        name: this.searchForm.get('name').value,
        page: 1,
      })
    );
  }

  /**
   * Handles pagination page-changed event.
   * @param page The current selected page number.
   */
  pageChanged(page: number) {
    /** Update pagination page. */
    this.searchForm.patchValue({ page });

    /** Get selected page items. */
    this.securityStore$.dispatch(
      new fromSecurityStore.GetOrganizationUsers({
        name: this.searchForm.get('name').value,
        page: this.searchForm.get('page').value,
      })
    );
  }

  /**
   * Enables trackBy feature for *ngFor utility to track items
   * depending on a comparer to reduce DOM change.
   * If the item is already exist then no HTML will change, only bounded values.
   * @param index The index of the item.
   * @param user The item to determine if it was changed or not.
   */
  trackItems(index: number, user: User) {
    return user ? user.id : undefined;
  }

  /**
   * Opens the modal of the given templateRef.
   * @param modalRef The modal templateRef to be opened.
   */
  openModal(modalRef) {
    this.modalService.open(modalRef);
  }

  /**
   * Blocks the user with the given id.
   * @param user The id of the user to be blocked.
   */
  block(id: number) {
    this.securityStore$.dispatch(new fromSecurityStore.BlockOrganizationUser(id));
  }

  /**
   * Activates the user with the given id.
   * @param user The id of the user to be activated.
   */
  activate(id: number) {
    this.securityStore$.dispatch(new fromSecurityStore.ActivateOrganizationUser(id));
  }
}
