import { Action } from '@ngrx/store';

import { MaintenanceStages } from 'lookups';

import {
  SaleInvoice,
  CreateRegularSalesInvoiceInput,
  CreateOpeningBalanceInvoiceInput,
  CreatePointOfSaleInvoiceInput,
  CreateMaintenanceInvoiceInput,
} from 'sales/models';
import { AppHttpResponse } from 'shared';

/**
 * The Sales-Module store saleInvoices action types.
 */
export enum SaleInvoicesActionType {
  CREATE_REGULAR_SALE_INVOICE = '[Sales] [SaleInvoices] Create Regular Sales Invoice',
  CREATE_OPENING_BALANCE_INVOICE = '[Sales] [SaleInvoices] Create Opening Balance Invoice',
  CREATE_MAINTENANCE_INVOICE = '[Sales] [SaleInvoices] Create Maintenance Invoice',
  CREATE_POS_INVOICE = '[Sales] [SaleInvoices] Create Point Of Sale Invoice',
  CREATE_SALE_INVOICE_FAIL = '[Sales] [SaleInvoices] Create SalesInvoice Fail',
  CREATE_SALE_INVOICE_SUCCESS = '[Sales] [SaleInvoices] Create SalesInvoice Success',
  SEARCH_SALE_INVOICES = '[Sales] [SaleInvoices] Search SalesInvoices',
  SEARCH_SALE_INVOICES_FAIL = '[Sales] [SaleInvoices] Search SalesInvoices Fail',
  SEARCH_SALE_INVOICES_SUCCESS = '[Sales] [SaleInvoices] Search SalesInvoices Success',

  SEARCH_UNPAID_SALE_INVOICES = '[Sales] [SaleInvoices] Search Unpaid Sale Invoices',
  SEARCH_UNPAID_SALE_INVOICES_FAIL = '[Sales] [SaleInvoices] Search Unpaid Sale Invoices Fail',
  SEARCH_UNPAID_SALE_INVOICES_SUCCESS = '[Sales] [SaleInvoices] Search Unpaid Sale Invoices Success',

  FIND_SALE_INVOICE = '[Sales] [SaleInvoices] Find SalesInvoice',
  FIND_SALE_INVOICE_FAIL = '[Sales] [SaleInvoices] Find SalesInvoice Fail',
  FIND_SALE_INVOICE_SUCCESS = '[Sales] [SaleInvoices] Find SalesInvoice Success',

  CHANGE_SALE_INVOICE_MAINTENANCE_STAGE = '[Sales] [SaleInvoices] Change SalesInvoice Maintenance Stage',
  CHANGE_SALE_INVOICE_MAINTENANCE_STAGE_FAIL = '[Sales] [SaleInvoices] Change SalesInvoice Maintenance Stage Fail',
  CHANGE_SALE_INVOICE_MAINTENANCE_STAGE_SUCCESS = '[Sales] [SaleInvoices] Change SalesInvoice Maintenance Stage Success',
}

/**
 * Represents a store regular sale invoice create action.
 */
export class CreateRegularSaleInvoice implements Action {
  /** The type of the action. */
  readonly type = SaleInvoicesActionType.CREATE_REGULAR_SALE_INVOICE;

  /**
   * Fires a new regular sale invoice create action.
   * @param payload the new regular sale invoice data-model.
   */
  constructor(public payload: CreateRegularSalesInvoiceInput) {}
}

/**
 * Represents a store opening balance invoice create action.
 */
export class CreateOpeningBalanceInvoice implements Action {
  /** The type of the action. */
  readonly type = SaleInvoicesActionType.CREATE_OPENING_BALANCE_INVOICE;

  /**
   * Fires a new opening balance Invoice create action.
   * @param payload the new opening balance Invoice data-model.
   */
  constructor(public payload: CreateOpeningBalanceInvoiceInput) {}
}

/**
 * Represents a store maintenance invoice create action.
 */
export class CreateMaintenanceInvoice implements Action {
  /** The type of the action. */
  readonly type = SaleInvoicesActionType.CREATE_MAINTENANCE_INVOICE;

  /**
   * Fires a new maintenance invoice create action.
   * @param payload the new maintenance invoice data-model.
   */
  constructor(public payload: CreateMaintenanceInvoiceInput) {}
}

/**
 * Represents a store posInvoice create action.
 */
export class CreatePointOfSaleInvoice implements Action {
  /** The type of the action. */
  readonly type = SaleInvoicesActionType.CREATE_POS_INVOICE;

  /**
   * Fires a new posInvoice create action.
   * @param payload the new posInvoice data-model.
   */
  constructor(public payload: CreatePointOfSaleInvoiceInput) {}
}

/**
 * Represents a store saleInvoice create-fail action.
 */
export class CreateSaleInvoiceFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = SaleInvoicesActionType.CREATE_SALE_INVOICE_FAIL;

  /**
   * Fires a new saleInvoice create-fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store saleInvoice create-success action.
 */
export class CreateSaleInvoiceSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = SaleInvoicesActionType.CREATE_SALE_INVOICE_SUCCESS;

  /**
   * Fires a new saleInvoice create-success action.
   * @param payload The created saleInvoice.
   */
  constructor(public payload: AppHttpResponse<SaleInvoice>) {}
}

/**
 * Represents a store saleInvoices search action.
 */
export class SearchSaleInvoices implements Action {
  /**
   * The type of the action.
   */
  readonly type = SaleInvoicesActionType.SEARCH_SALE_INVOICES;

  /**
   * Fires a new saleInvoices search action.
   * @param payload the search parameters,
   * if omitted, all saleInvoices will be loaded.
   */
  constructor(
    public payload?: {
      invoiceNum: number;
      orderNum: number;
      customers: number[];
      locations: number[];
      saleInvoiceTypes: number[];
      saleInvoiceOrderTypes: number[];
      fromDate: Date;
      toDate: Date;
      page: number;
    }
  ) {}
}

/**
 * Represents a store saleInvoices search-fail action.
 */
export class SearchSaleInvoicesFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = SaleInvoicesActionType.SEARCH_SALE_INVOICES_FAIL;

  /**
   * Fires a new saleInvoices search-fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store search-success action.
 */
export class SearchSaleInvoicesSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = SaleInvoicesActionType.SEARCH_SALE_INVOICES_SUCCESS;

  /**
   * Fires a new saleInvoices search-success action.
   * @param payload An object contains the list of saleInvoices that matches the search criteria
   * in addition to the pagination info.
   */
  constructor(public payload: AppHttpResponse<SaleInvoice[]>) {}
}

/**
 * Represents a store unpaid sale invoices search action.
 */
export class SearchUnpaidSaleInvoices implements Action {
  /**
   * The type of the action.
   */
  readonly type = SaleInvoicesActionType.SEARCH_UNPAID_SALE_INVOICES;

  /**
   * Fires a new unpaid sale invoices search action.
   * @param payload the search parameters,
   * if omitted, all unpaid sale invoices will be loaded.
   */
  constructor(
    public payload?: {
      invoiceNum: number;
      locations: number[];
      customers: number[];
      page: number;
    }
  ) {}
}

/**
 * Represents a store unpaid sale invoices search-fail action.
 */
export class SearchUnpaidSaleInvoicesFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = SaleInvoicesActionType.SEARCH_UNPAID_SALE_INVOICES_FAIL;

  /**
   * Fires a new unpaid sale invoices search-fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store unpaid sale invoices search-success action.
 */
export class SearchUnpaidSaleInvoicesSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = SaleInvoicesActionType.SEARCH_UNPAID_SALE_INVOICES_SUCCESS;

  /**
   * Fires a new unpaid sale invoices search-success action.
   * @param payload An object contains the list of unpaid sale invoices that matches the search criteria
   * in addition to the pagination info.
   */
  constructor(public payload: AppHttpResponse<SaleInvoice[]>) {}
}

/**
 * Represents a store find-saleInvoice action.
 */
export class FindSaleInvoice implements Action {
  /**
   * The type of the action.
   */
  readonly type = SaleInvoicesActionType.FIND_SALE_INVOICE;

  /**
   * Fires a new find saleInvoice action.
   * @param payload the id of the saleInvoice.
   */
  constructor(public payload: number) {}
}

/**
 * Represents a store find-saleInvoice-fail action.
 */
export class FindSaleInvoiceFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = SaleInvoicesActionType.FIND_SALE_INVOICE_FAIL;

  /**
   * Fires a new find-saleInvoice-fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store find saleInvoice-success action.
 */
export class FindSaleInvoiceSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = SaleInvoicesActionType.FIND_SALE_INVOICE_SUCCESS;

  /**
   * Fires a new find-saleInvoice-success action.
   * @param payload The loaded saleInvoice.
   */
  constructor(public payload: AppHttpResponse<SaleInvoice>) {}
}

/**
 * Represents a store saleInvoice change maintenance stage action.
 */
export class ChangeSaleInvoiceMaintenanceStage implements Action {
  /**
   * The type of the action.
   */
  readonly type = SaleInvoicesActionType.CHANGE_SALE_INVOICE_MAINTENANCE_STAGE;

  /**
   * Fires a new saleInvoice change maintenance stage action.
   * @param payload The saleInvoice's id and new stage key.
   */
  constructor(public payload: { id: number; stage: MaintenanceStages }) {}
}

/**
 * Represents a store saleInvoice change-maintenance-stage-fail action.
 */
export class ChangeSaleInvoiceMaintenanceStageFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = SaleInvoicesActionType.CHANGE_SALE_INVOICE_MAINTENANCE_STAGE_FAIL;

  /**
   * Fires a new saleInvoice change-maintenance-stage-fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store saleInvoice change-maintenance-stage-success action.
 */
export class ChangeSaleInvoiceMaintenanceStageSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = SaleInvoicesActionType.CHANGE_SALE_INVOICE_MAINTENANCE_STAGE_SUCCESS;

  /**
   * Fires a new saleInvoice change-maintenance-stage-success action.
   * @param payload The updated saleInvoice.
   */
  constructor(public payload: AppHttpResponse<SaleInvoice>) {}
}

/**
 * Sales-module saleInvoices action types.
 */
export type SaleInvoicesActions =
  | CreateRegularSaleInvoice
  | CreateOpeningBalanceInvoice
  | CreateMaintenanceInvoice
  | CreatePointOfSaleInvoice
  | CreateSaleInvoiceFail
  | CreateSaleInvoiceSuccess
  | SearchSaleInvoices
  | SearchSaleInvoicesFail
  | SearchSaleInvoicesSuccess
  | SearchUnpaidSaleInvoices
  | SearchUnpaidSaleInvoicesFail
  | SearchUnpaidSaleInvoicesSuccess
  | FindSaleInvoice
  | FindSaleInvoiceFail
  | FindSaleInvoiceSuccess
  | ChangeSaleInvoiceMaintenanceStage
  | ChangeSaleInvoiceMaintenanceStageFail
  | ChangeSaleInvoiceMaintenanceStageSuccess;
