import { Action } from '@ngrx/store';

import { Report } from 'reports/models';
import { AppHttpResponse } from 'shared';

/**
 * The Reports-Module finances reports action types.
 */
export enum FinancesReportsActionType {
  GET_ACCOUNT_ANALYSIS_REPORT = '[Reports] [Finances] Get Account Analysis Report',
  GET_ACCOUNT_ANALYSIS_REPORT_FAIL = '[Reports] [Finances] Get Account Analysis Report Fail',
  GET_ACCOUNT_ANALYSIS_REPORT_RECEIVED = '[Reports] [Finances] Get Account Analysis Report Received',
  GET_ACCOUNT_ANALYSIS_REPORT_SUCCESS = '[Reports] [Finances] Get Account Analysis Report Success',
  ACCOUNT_ANALYSIS_REPORT_VIEW = '[Reports] [Finances] Get Account Analysis Report View',

  GET_BALANCE_SHEET_REPORT = '[Reports] [Finances] Get Balance Sheet Report',
  GET_BALANCE_SHEET_REPORT_FAIL = '[Reports] [Finances] Get Balance Sheet Report Fail',
  GET_BALANCE_SHEET_REPORT_RECEIVED = '[Reports] [Finances] Get Balance Sheet Report Received',
  GET_BALANCE_SHEET_REPORT_SUCCESS = '[Reports] [Finances] Get Balance Sheet Report Success',
  BALANCE_SHEET_REPORT_VIEW = '[Reports] [Finances] Get Balance Sheet Report View',

  GET_BANK_ACCOUNT_SUMMARY_REPORT = '[Reports] [Finances] Get Bank Account Summary Report',
  GET_BANK_ACCOUNT_SUMMARY_REPORT_FAIL = '[Reports] [Finances] Get Bank Account Summary Report Fail',
  GET_BANK_ACCOUNT_SUMMARY_REPORT_RECEIVED = '[Reports] [Finances] Get Bank Account Summary Report Received',
  GET_BANK_ACCOUNT_SUMMARY_REPORT_SUCCESS = '[Reports] [Finances] Get Bank Account Summary Report Success',
  BANK_ACCOUNT_SUMMARY_REPORT_VIEW = '[Reports] [Finances] Get Bank Account Summary Report View',

  GET_INCOME_STATEMENT_REPORT = '[Reports] [Finances] Get Income Statement Report',
  GET_INCOME_STATEMENT_REPORT_FAIL = '[Reports] [Finances] Get Income Statement Report Fail',
  GET_INCOME_STATEMENT_REPORT_RECEIVED = '[Reports] [Finances] Get Income Statement Report Received',
  GET_INCOME_STATEMENT_REPORT_SUCCESS = '[Reports] [Finances] Get Income Statement Report Success',
  INCOME_STATEMENT_REPORT_VIEW = '[Reports] [Finances] Get Income Statement Report View',

  GET_JOURNALS_REPORT = '[Reports] [Finances] Get Journals Report',
  GET_JOURNALS_REPORT_FAIL = '[Reports] [Finances] Get Journals Report Fail',
  GET_JOURNALS_REPORT_RECEIVED = '[Reports] [Finances] Get Journals Report Received',
  GET_JOURNALS_REPORT_SUCCESS = '[Reports] [Finances] Get Journals Report Success',
  JOURNALS_REPORT_VIEW = '[Reports] [Finances] Get Journals Report View',

  GET_TAXES_REPORT = '[Reports] [Finances] Get Taxes Report',
  GET_TAXES_REPORT_FAIL = '[Reports] [Finances] Get Taxes Report Fail',
  GET_TAXES_REPORT_RECEIVED = '[Reports] [Finances] Get Taxes Report Received',
  GET_TAXES_REPORT_SUCCESS = '[Reports] [Finances] Get Taxes Report Success',
  TAXES_REPORT_VIEW = '[Reports] [Finances] Get Taxes Report View',

  GET_TRIAL_BALANCE_REPORT = '[Reports] [Finances] Get Trial Balance Report',
  GET_TRIAL_BALANCE_REPORT_FAIL = '[Reports] [Finances] Get Trial Balance Report Fail',
  GET_TRIAL_BALANCE_REPORT_RECEIVED = '[Reports] [Finances] Get Trial Balance Report Received',
  GET_TRIAL_BALANCE_REPORT_SUCCESS = '[Reports] [Finances] Get Trial Balance Report Success',
  TRIAL_BALANCE_REPORT_VIEW = '[Reports] [Finances] Get Trial Balance Report View',
}

/**
 * Represents a store account analysis report load action.
 */
export class GetAccountAnalysisReport implements Action {
  /**
   * The type of the action.
   */
  readonly type = FinancesReportsActionType.GET_ACCOUNT_ANALYSIS_REPORT;

  /**
   * Fires a new account analysis report load action.
   * @param payload the search parameters,
   * if omitted, all account analysis report will be loaded.
   */
  constructor(
    public payload?: {
      accountId: number;
      costCenters: number[];
      fromDate: Date;
      toDate: Date;
    }
  ) {}
}

/**
 * Represents a store account analysis report load fail action.
 */
export class GetAccountAnalysisReportFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = FinancesReportsActionType.GET_ACCOUNT_ANALYSIS_REPORT_FAIL;

  /**
   * Fires a new account analysis report load fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents an account analysis  report received action.
 */
export class GetAccountAnalysisReportReceived implements Action {
  /**
   * The type of the action.
   */
  readonly type = FinancesReportsActionType.GET_ACCOUNT_ANALYSIS_REPORT_RECEIVED;

  /**
   * Fires an account analysis report received action.
   * @param payload Received acknowledgment.
   */
  constructor(public payload: AppHttpResponse<string>) {}
}
/**
 * Represents a store account analysis report get success action.
 */
export class GetAccountAnalysisReportSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = FinancesReportsActionType.GET_ACCOUNT_ANALYSIS_REPORT_SUCCESS;

  /**
   * Fires a account analysis report get success action.
   *  @param payload The loaded report.
   */
  constructor(public payload: AppHttpResponse<Report>) {}
}

/**
 * Represents a products list report view action.
 */
export class AccountAnalysisReportView implements Action {
  /**
   * The type of the action.
   */
  readonly type = FinancesReportsActionType.ACCOUNT_ANALYSIS_REPORT_VIEW;

  /**
   * Fires an account analysis report view action.
   * @param payload The report to be loaded.
   */
  constructor(public payload: Report) {}
}

/**
 * Represents a balance sheet report action.
 */
export class GetBalanceSheetReport implements Action {
  /**
   * The type of the action.
   */
  readonly type = FinancesReportsActionType.GET_BALANCE_SHEET_REPORT;

  /**
   * Fires a new balance sheet report action.
   */
  constructor() {}
}

/**
 * Represents a balance sheet report fail action.
 */
export class GetBalanceSheetReportFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = FinancesReportsActionType.GET_BALANCE_SHEET_REPORT_FAIL;

  /**
   * Fires a new balance sheet report fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a balance sheet report received action.
 */
export class GetBalanceSheetReportReceived implements Action {
  /**
   * The type of the action.
   */
  readonly type = FinancesReportsActionType.GET_BALANCE_SHEET_REPORT_RECEIVED;

  /**
   * Fires a balance sheet report received action.
   * @param payload Received acknowledgment.
   */
  constructor(public payload: AppHttpResponse<string>) {}
}

/**
 * Represents a balance sheet report success action.
 */
export class GetBalanceSheetReportSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = FinancesReportsActionType.GET_BALANCE_SHEET_REPORT_SUCCESS;

  /**
   * Fires a balance sheet report success action.
   * @param payload The loaded report.
   */
  constructor(public payload: AppHttpResponse<Report>) {}
}

/**
 * Represents a balance sheet report view action.
 */
export class BalanceSheetReportView implements Action {
  /**
   * The type of the action.
   */
  readonly type = FinancesReportsActionType.BALANCE_SHEET_REPORT_VIEW;

  /**
   * Fires a balance sheet report view action.
   * @param payload The report to be loaded.
   */
  constructor(public payload: Report) {}
}

/**
 * Represents a bank account summary report action.
 */
export class GetBankAccountSummaryReport implements Action {
  /**
   * The type of the action.
   */
  readonly type = FinancesReportsActionType.GET_BANK_ACCOUNT_SUMMARY_REPORT;

  /**
   * Fires a new bank account summary report action.
   */
  constructor(
    public payload: {
      bankAccountId: string;
      fromDate: Date;
      toDate: Date;
    }
  ) {}
}

/**
 * Represents a bank account summary report fail action.
 */
export class GetBankAccountSummaryReportFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = FinancesReportsActionType.GET_BANK_ACCOUNT_SUMMARY_REPORT_FAIL;

  /**
   * Fires a new bank account summary report fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a bank account summary report received action.
 */
export class GetBankAccountSummaryReportReceived implements Action {
  /**
   * The type of the action.
   */
  readonly type = FinancesReportsActionType.GET_BANK_ACCOUNT_SUMMARY_REPORT_RECEIVED;

  /**
   * Fires a bank account summary report received action.
   * @param payload Received acknowledgment.
   */
  constructor(public payload: AppHttpResponse<string>) {}
}

/**
 * Represents a bank account summary report success action.
 */
export class GetBankAccountSummaryReportSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = FinancesReportsActionType.GET_BANK_ACCOUNT_SUMMARY_REPORT_SUCCESS;

  /**
   * Fires a bank account summary report success action.
   * @param payload The loaded report.
   */
  constructor(public payload: AppHttpResponse<Report>) {}
}

/**
 * Represents a bank account summary report view action.
 */
export class BankAccountSummaryReportView implements Action {
  /**
   * The type of the action.
   */
  readonly type = FinancesReportsActionType.BANK_ACCOUNT_SUMMARY_REPORT_VIEW;

  /**
   * Fires a bank account summary report view action.
   * @param payload The report to be loaded.
   */
  constructor(public payload: Report) {}
}

/**
 * Represents a income statement report action.
 */
export class GetIncomeStatementReport implements Action {
  /**
   * The type of the action.
   */
  readonly type = FinancesReportsActionType.GET_INCOME_STATEMENT_REPORT;

  /**
   * Fires a new income statement report action.
   */
  constructor(
    public payload: {
      costCenters: number[];
      fromDate: Date;
      toDate: Date;
    }
  ) {}
}

/**
 * Represents a income statement report fail action.
 */
export class GetIncomeStatementReportFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = FinancesReportsActionType.GET_INCOME_STATEMENT_REPORT_FAIL;

  /**
   * Fires a new income statement report fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a income statement report received action.
 */
export class GetIncomeStatementReportReceived implements Action {
  /**
   * The type of the action.
   */
  readonly type = FinancesReportsActionType.GET_INCOME_STATEMENT_REPORT_RECEIVED;

  /**
   * Fires a income statement report received action.
   * @param payload Received acknowledgment.
   */
  constructor(public payload: AppHttpResponse<string>) {}
}

/**
 * Represents a income statement report success action.
 */
export class GetIncomeStatementReportSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = FinancesReportsActionType.GET_INCOME_STATEMENT_REPORT_SUCCESS;

  /**
   * Fires a income statement report success action.
   * @param payload The loaded report.
   */
  constructor(public payload: AppHttpResponse<Report>) {}
}

/**
 * Represents a income statement report view action.
 */
export class IncomeStatementReportView implements Action {
  /**
   * The type of the action.
   */
  readonly type = FinancesReportsActionType.INCOME_STATEMENT_REPORT_VIEW;

  /**
   * Fires a income statement report view action.
   * @param payload The report to be loaded.
   */
  constructor(public payload: Report) {}
}

/**
 * Represents a journals report action.
 */
export class GetJournalsReport implements Action {
  /**
   * The type of the action.
   */
  readonly type = FinancesReportsActionType.GET_JOURNALS_REPORT;

  /**
   * Fires a new journals report action.
   */
  constructor(
    public payload: {
      journalTypes: number[];
      fromDate: Date;
      toDate: Date;
    }
  ) {}
}

/**
 * Represents a journals report fail action.
 */
export class GetJournalsReportFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = FinancesReportsActionType.GET_JOURNALS_REPORT_FAIL;

  /**
   * Fires a new journals report fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a journals report received action.
 */
export class GetJournalsReportReceived implements Action {
  /**
   * The type of the action.
   */
  readonly type = FinancesReportsActionType.GET_JOURNALS_REPORT_RECEIVED;

  /**
   * Fires a journals report received action.
   * @param payload Received acknowledgment.
   */
  constructor(public payload: AppHttpResponse<string>) {}
}

/**
 * Represents a journals report success action.
 */
export class GetJournalsReportSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = FinancesReportsActionType.GET_JOURNALS_REPORT_SUCCESS;

  /**
   * Fires a journals report success action.
   * @param payload The loaded report.
   */
  constructor(public payload: AppHttpResponse<Report>) {}
}

/**
 * Represents a journals report view action.
 */
export class JournalsReportView implements Action {
  /**
   * The type of the action.
   */
  readonly type = FinancesReportsActionType.JOURNALS_REPORT_VIEW;

  /**
   * Fires a journals report view action.
   * @param payload The report to be loaded.
   */
  constructor(public payload: Report) {}
}

/**
 * Represents a taxes report action.
 */
export class GetTaxesReport implements Action {
  /**
   * The type of the action.
   */
  readonly type = FinancesReportsActionType.GET_TAXES_REPORT;

  /**
   * Fires a new taxes report action.
   */
  constructor(
    public payload: {
      costCenters: number[];
      fromDate: Date;
      toDate: Date;
    }
  ) {}
}

/**
 * Represents a taxes report fail action.
 */
export class GetTaxesReportFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = FinancesReportsActionType.GET_TAXES_REPORT_FAIL;

  /**
   * Fires a new taxes report fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a taxes report received action.
 */
export class GetTaxesReportReceived implements Action {
  /**
   * The type of the action.
   */
  readonly type = FinancesReportsActionType.GET_TAXES_REPORT_RECEIVED;

  /**
   * Fires a taxes report received action.
   * @param payload Received acknowledgment.
   */
  constructor(public payload: AppHttpResponse<string>) {}
}

/**
 * Represents a taxes report success action.
 */
export class GetTaxesReportSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = FinancesReportsActionType.GET_TAXES_REPORT_SUCCESS;

  /**
   * Fires a taxes report success action.
   * @param payload The loaded report.
   */
  constructor(public payload: AppHttpResponse<Report>) {}
}

/**
 * Represents a taxes report view action.
 */
export class TaxesReportView implements Action {
  /**
   * The type of the action.
   */
  readonly type = FinancesReportsActionType.TAXES_REPORT_VIEW;

  /**
   * Fires a taxes report view action.
   * @param payload The report to be loaded.
   */
  constructor(public payload: Report) {}
}

/**
 * Represents a trial balance report action.
 */
export class GetTrialBalanceReport implements Action {
  /**
   * The type of the action.
   */
  readonly type = FinancesReportsActionType.GET_TRIAL_BALANCE_REPORT;

  /**
   * Fires a new trial balance report action.
   */
  constructor(
    public payload: {
      accounts: number[];
      costCenters: number[];
      fromDate: Date;
      toDate: Date;
    }
  ) {}
}

/**
 * Represents a trial balance report fail action.
 */
export class GetTrialBalanceReportFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = FinancesReportsActionType.GET_TRIAL_BALANCE_REPORT_FAIL;

  /**
   * Fires a new trial balance report fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a trial balance report received action.
 */
export class GetTrialBalanceReportReceived implements Action {
  /**
   * The type of the action.
   */
  readonly type = FinancesReportsActionType.GET_TRIAL_BALANCE_REPORT_RECEIVED;

  /**
   * Fires a trial balance report received action.
   * @param payload Received acknowledgment.
   */
  constructor(public payload: AppHttpResponse<string>) {}
}

/**
 * Represents a trial balance report success action.
 */
export class GetTrialBalanceReportSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = FinancesReportsActionType.GET_TRIAL_BALANCE_REPORT_SUCCESS;

  /**
   * Fires a trial balance report success action.
   * @param payload The loaded report.
   */
  constructor(public payload: AppHttpResponse<Report>) {}
}

/**
 * Represents a trial balance report view action.
 */
export class TrialBalanceReportView implements Action {
  /**
   * The type of the action.
   */
  readonly type = FinancesReportsActionType.TRIAL_BALANCE_REPORT_VIEW;

  /**
   * Fires a trial balance report view action.
   * @param payload The report to be loaded.
   */
  constructor(public payload: Report) {}
}

/**
 * Reports-Module finances reports action types.
 */
export type FinancesReportsActions =
  | GetAccountAnalysisReport
  | GetAccountAnalysisReportFail
  | GetAccountAnalysisReportReceived
  | GetAccountAnalysisReportSuccess
  | AccountAnalysisReportView
  | GetBalanceSheetReport
  | GetBalanceSheetReportFail
  | GetBalanceSheetReportReceived
  | GetBalanceSheetReportSuccess
  | BalanceSheetReportView
  | GetBankAccountSummaryReport
  | GetBankAccountSummaryReportFail
  | GetBankAccountSummaryReportReceived
  | GetBankAccountSummaryReportSuccess
  | BankAccountSummaryReportView
  | GetIncomeStatementReport
  | GetIncomeStatementReportFail
  | GetIncomeStatementReportReceived
  | GetIncomeStatementReportSuccess
  | IncomeStatementReportView
  | GetJournalsReport
  | GetJournalsReportFail
  | GetJournalsReportReceived
  | GetJournalsReportSuccess
  | JournalsReportView
  | GetTaxesReport
  | GetTaxesReportFail
  | GetTaxesReportReceived
  | GetTaxesReportSuccess
  | TaxesReportView
  | GetTrialBalanceReport
  | GetTrialBalanceReportFail
  | GetTrialBalanceReportReceived
  | GetTrialBalanceReportSuccess
  | TrialBalanceReportView;
