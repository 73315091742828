import { Injectable } from '@angular/core';

import { of } from 'rxjs';
import { switchMap, map, catchError } from 'rxjs/operators';
import { Effect, ofType, Actions } from '@ngrx/effects';

import { LookupsService } from 'lookups/services';
import {
  PosPrinterAdjustmentTypeActionType,
  GetAllPosPrinterAdjustmentTypesFail,
  GetAllPosPrinterAdjustmentTypesSuccess,
} from 'lookups/store/actions';

@Injectable()
export class PosPrinterAdjustmentTypeEffects {
  constructor(private actions$: Actions, private lookupsService: LookupsService) {}

  /* ========================= GET_ALL_POS_PRINTER_ADJUSTMENT_TYPES =================================== */
  @Effect()
  getAllPosPrinterAdjustmentTypes$ = this.actions$.pipe(
    ofType(PosPrinterAdjustmentTypeActionType.GET_ALL_POS_PRINTER_ADJUSTMENT_TYPES),
    switchMap(() =>
      this.lookupsService.getAllPosPrinterAdjustmentTypes().pipe(
        map((response) => new GetAllPosPrinterAdjustmentTypesSuccess(response)),
        catchError((error) => of(new GetAllPosPrinterAdjustmentTypesFail(error)))
      )
    )
  );
}
