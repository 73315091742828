import { Action } from '@ngrx/store';

import { Variant, CreateVariantInput, UpdateVariantInput } from 'stores/models';
import { AppHttpResponse } from 'shared';

/**
 * The Stores-Module store variants action types.
 */
export enum VariantsActionType {
  SEARCH_VARIANTS = '[Stores] [Variants] Search Variants',
  SEARCH_VARIANTS_FAIL = '[Stores] [Variants] Search Variants Fail',
  SEARCH_VARIANTS_SUCCESS = '[Stores] [Variants] Search Variants Success',

  FIND_VARIANT = '[Stores] [Variants] Find Variant',
  FIND_VARIANT_FAIL = '[Stores] [Variants] Find Variant Fail',
  FIND_VARIANT_SUCCESS = '[Stores] [Variants] Find Variant Success',

  CREATE_VARIANT = '[Stores] [Variants] Create Variant',
  CREATE_VARIANT_FAIL = '[Stores] [Variants] Create Variant Fail',
  CREATE_VARIANT_SUCCESS = '[Stores] [Variants] Create Variant Success',

  UPDATE_VARIANT = '[Stores] [Variants] Update Variant',
  UPDATE_VARIANT_FAIL = '[Stores] [Variants] Update Variant Fail',
  UPDATE_VARIANT_SUCCESS = '[Stores] [Variants] Update Variant Success',

  DELETE_VARIANT = '[Stores] [Variants] Delete Variant',
  DELETE_VARIANT_FAIL = '[Stores] [Variants] Delete Variant Fail',
  DELETE_VARIANT_SUCCESS = '[Stores] [Variants] Delete Variant Success',
}

/**
 * Represents a store variants search action.
 */
export class SearchVariants implements Action {
  /**
   * The type of the action.
   */
  readonly type = VariantsActionType.SEARCH_VARIANTS;

  /**
   * Fires a new variants search action.
   * @param payload the search parameters,
   * if omitted, all variants will be loaded.
   */
  constructor(public payload?: { name: string; categories: number[]; page: number }) {}
}

/**
 * Represents a store variants search-fail action.
 */
export class SearchVariantsFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = VariantsActionType.SEARCH_VARIANTS_FAIL;

  /**
   * Fires a new variants search-fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store search-success action.
 */
export class SearchVariantsSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = VariantsActionType.SEARCH_VARIANTS_SUCCESS;

  /**
   * Fires a new variants search-success action.
   * @param payload An object contains the list of variants that matches the search criteria
   * in addition to the pagination info.
   */
  constructor(public payload: AppHttpResponse<Variant[]>) {}
}

/**
 * Represents a store find-variant action.
 */
export class FindVariant implements Action {
  /**
   * The type of the action.
   */
  readonly type = VariantsActionType.FIND_VARIANT;

  /**
   * Fires a new find variant action.
   * @param payload the id of the variant.
   */
  constructor(public payload: number) {}
}

/**
 * Represents a store find-variant-fail action.
 */
export class FindVariantFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = VariantsActionType.FIND_VARIANT_FAIL;

  /**
   * Fires a new find-variant-fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store find-variant action.
 */
export class FindVariantSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = VariantsActionType.FIND_VARIANT_SUCCESS;

  /**
   * Fires a new find-variant-success action.
   * @param payload The loaded variant.
   */
  constructor(public payload: AppHttpResponse<Variant>) {}
}

/**
 * Represents a store variant create action.
 */
export class CreateVariant implements Action {
  /** The type of the action. */
  readonly type = VariantsActionType.CREATE_VARIANT;

  /**
   * Fires a new variant create action.
   * @param payload the new variant data-model.
   */
  constructor(public payload: CreateVariantInput) {}
}

/**
 * Represents a store variant create-fail action.
 */
export class CreateVariantFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = VariantsActionType.CREATE_VARIANT_FAIL;

  /**
   * Fires a new variant create-fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store variant create-success action.
 */
export class CreateVariantSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = VariantsActionType.CREATE_VARIANT_SUCCESS;

  /**
   * Fires a new variant create-success action.
   * @param payload The created variant.
   */
  constructor(public payload: AppHttpResponse<Variant>) {}
}

/**
 * Represents a store variant update action.
 */
export class UpdateVariant implements Action {
  /**
   * The type of the action.
   */
  readonly type = VariantsActionType.UPDATE_VARIANT;

  /**
   * Fires a new variant update action.
   * @param payload The variant's updated data.
   */
  constructor(public payload: UpdateVariantInput) {}
}

/**
 * Represents a store variant update-fail action.
 */
export class UpdateVariantFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = VariantsActionType.UPDATE_VARIANT_FAIL;

  /**
   * Fires a new variant update-fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store variant update-success action.
 */
export class UpdateVariantSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = VariantsActionType.UPDATE_VARIANT_SUCCESS;

  /**
   * Fires a new variant update-success action.
   * @param payload The updated variant.
   */
  constructor(public payload: AppHttpResponse<Variant>) {}
}

/**
 * Represents a store variant delete action.
 */
export class DeleteVariant implements Action {
  /**
   * The type of the action.
   */
  readonly type = VariantsActionType.DELETE_VARIANT;

  /**
   * Fires a new variant delete action.
   * @param payload The variant's id.
   */
  constructor(public payload: number) {}
}

/**
 * Represents a store variant delete-fail action.
 */
export class DeleteVariantFail implements Action {
  /**
   * The type of the action.
   */
  readonly type = VariantsActionType.DELETE_VARIANT_FAIL;

  /**
   * Fires a new variant delete-fail action.
   * @param payload The error sent from the remote server with the response.
   */
  constructor(public payload: AppHttpResponse<any>) {}
}

/**
 * Represents a store variant delete-success action.
 */
export class DeleteVariantSuccess implements Action {
  /**
   * The type of the action.
   */
  readonly type = VariantsActionType.DELETE_VARIANT_SUCCESS;

  /**
   * Fires a new variant delete-success action.
   * @param payload The deleted variant.
   */
  constructor(public payload: AppHttpResponse<Variant>) {}
}

/**
 * Stores-Module variants action types.
 */
export type VariantsActions =
  | SearchVariants
  | SearchVariantsFail
  | SearchVariantsSuccess
  | FindVariant
  | FindVariantFail
  | FindVariantSuccess
  | CreateVariant
  | CreateVariantFail
  | CreateVariantSuccess
  | UpdateVariant
  | UpdateVariantFail
  | UpdateVariantSuccess
  | DeleteVariant
  | DeleteVariantFail
  | DeleteVariantSuccess;
